//Sooyoung Kim
//June 14, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, deleteAPICallGenerator, callBackGenerator, formatNumber, formatDate, formatDateTime, generateSID, confirmation, sliceFromArray, getFromTo, replaceRegex} from '../../../util/util';
import {Button, Card, CardHeader, CardBody, Col, Row, Modal, ModalHeader, ModalBody, UncontrolledTooltip} from 'reactstrap';
import React, {useReducer, useEffect, useCallback, useRef} from 'react';
import MyReactTable from '../../util/my-react-table';
import ReportFilter from '../report-filter';
import ReactExport from "react-export-excel";
import ReactStars from 'react-stars';
import {NavLink, useParams} from 'react-router-dom';
import moment from 'moment';
import {usePusher} from '../../pusher/pusher-context';
import '../report.css';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

let defaultfrom = getFromTo('from');
let defaultto = getFromTo('to');

//initialize the state
const initialState = {
    selectedAppraisalType:'',
    report:[],
    options:[],
    detailsPopUpOpen:false,
    weeklyReportPopUpOpen:false,
    existingWeeklyReportPopUpOpen:false,
    type:'orderCount',
    reportDetails:[],

    showLegend:false,
    showReportLoading:false,

    existingWeeklyReport:[],
    weeklyReportPeriod:'1m',
};

//reducer function that perform state update
const reducer = getReducer();


const AppraiserPerformance  = (props)=>{
    const controller = new AbortController();
    let {params} = useParams();
    const pusher = usePusher();
    const newsid = useRef(generateSID);

    let from = defaultfrom;
    let to = defaultto;
    let client = '';
    let openBy = '';
    let isRush = '';
    let isComplex = '';
    let stateName = '';
    let county = '';
    let city = '';
    let loanPurpose = '';
    let loanType = '';
    let entity = '';
    let filterAppraisalTypes = [];
    let appraiser = '';
    let appraiserName = '';

    if(params&&params!==''){
        params = params.split('&');
        if(params){
            for(let i=0;i<params.length;i++){
                let keyPair = params[i].split('=');
                switch(keyPair[0]){
                case 'from':
                    from = keyPair[1];
                    break;
                case 'to':
                    to = keyPair[1];
                    break;
                case 'client':
                    client = keyPair[1];
                    break;
                case 'openBy':
                    openBy = keyPair[1];
                    break;
                case 'rush':
                    isRush = keyPair[1];
                    break;
                case 'complex':
                    isComplex = keyPair[1];
                    break;
                case 'state':
                    stateName = keyPair[1];
                    break;
                case 'county':
                    county = keyPair[1];
                    break;
                case 'city':
                    city = keyPair[1];
                    break;
                case 'loanPurpose':
                    keyPair[1] = keyPair[1].replace(new RegExp('fslash','g'),'/');
                    loanPurpose = keyPair[1];
                    break;
                case 'loanType':
                    loanType = keyPair[1];
                    break;
                case 'entity':
                    entity = keyPair[1];
                    break;
                case 'appraisalTypes':
                    let appraisalTypes = replaceRegex(keyPair[1], '|');
                    filterAppraisalTypes = appraisalTypes;
                    break;
                case 'appraiser':
                    if(keyPair[1]&&keyPair[1]!==''){
                    let token = keyPair[1].split('|');
                    if(token.length>=2){
                        appraiser = token[0];
                        appraiserName = token[1];
                    }else{
                        appraiser = '';
                        appraiserName = '';
                    }
                    }else{
                    appraiser = '';
                    appraiserName = '';
                    }
                    break;
                default:
                    break;
                }
            }
        }
    }

    let newInitialState = Object.assign({}, initialState, {
      filterAppraisalTypes:filterAppraisalTypes,
      from:from,
      to:to,
      client:client,
      openBy:openBy,
      isRush: isRush,
      isComplex: isComplex,
      county:county,
      city:city,
      state:stateName,
      loanPurpose:loanPurpose,
      loanType:loanType,
      entity:entity,
      appraiser:appraiser,
      appraiserName:appraiserName,
    });

    const [state, dispatch] = useReducer(reducer,newInitialState);

    //wrapper function
    const setState = getSetStateFunction(dispatch);

    const apiCallBack = callBackGenerator(setState);
    const httpGet = getAPICallGenerator(props, {signal:controller.signal});
    const httpPost = postAPICallGenerator(props, {signal:controller.signal});
    const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

    //run only once when component is loaded
    useEffect(()=>{
      getExistingWeeklyReport();

      return ()=> controller.abort();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    //PUSHER
    const toggleReportLoading = useCallback(() => {
      setState({showReportLoading:!state.showReportLoading});
    }, [setState, state.showReportLoading]);

    const loadReportResponse = useCallback((ID) => {
      let callBack = apiCallBack([{state:'report', key:'data'}], toggleReportLoading);
      httpGet('report/query/retrieve/'+ID, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
    }, [apiCallBack, httpGet, toggleReportLoading]);

    const reportReady = useCallback((response) => {
      response = JSON.parse(response)
      let code = response.code;
      let queryID = response.data;
      let SID = response.SID;
      console.log(response);
      console.log(queryID);
      if(SID===newsid.current){
          if(code==='00'){
            console.log("here");
              loadReportResponse(queryID)
          }
      }
    }, [loadReportResponse]);


    useEffect(() => {
      // Subscribe to a channel and bind to events using the pusher instance
      const channel = pusher.subscribe('private-report');
      channel.bind('report-ready', (data) => {
        reportReady(data);
      });

      // channel.bind('pusher:subscription_succeeded', ()=>{
      //   getReport();
      // });
  
      // Clean up the subscription when the component is unmounted
      return () => {
        channel.unbind('report-ready');
        pusher.unsubscribe('private-report');
      };
    }, [pusher, reportReady]);

    //non API call but simpyl manage state
    const weeklyReportToggle = ()=>{
      setState({weeklyReportPopUpOpen:!state.weeklyReportPopUpOpen,existingWeeklyReportPopUpOpen:false});
    }

    const existingWeeklyReportToggle = ()=>{
      setState({existingWeeklyReportPopUpOpen:!state.existingWeeklyReportPopUpOpen,weeklyReportPopUpOpen:false});
    }

    const reportDetailsToggle = ()=>{
      setState({reportDetails:[],detailsPopUpOpen:!state.detailsPopUpOpen});
    }

    const toggleLegend = ()=>{
      setState({showLegend:!state.showLegend});
    }

    //API call
    const getReport = ()=>{
      let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
      let loanPurpose = replaceRegex(state.loanPurpose);
  
      let SID = generateSID();
      newsid.current = SID;

      let url = 'report/appraiserPerformance/SID='+SID+'&county='+state.county+'&client='+state.client+'&openBy='+state.openBy+'&appraiser='+state.appraiser+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&appraisalType='+appraisalType;
      httpGet(url, '', 'Oops, something went wrong and could not load the report. Please try again later.', apiCallBack([], toggleReportLoading));
    }
    
    const getReportDetails = (appraiserFk, type)=>{
      let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
      let loanPurpose = replaceRegex(state.loanPurpose);

      // for the cu score above 4
      if(type==='cu_score_4+cu_score_5'){
          let reportDetails = [];
          let url;

          let callBack = (response)=>{
            let code = response.data?response.data.code:undefined;
            if(code==='00'){
                for(let i=0;i<response.data.data.length;i++){
                reportDetails.push(response.data.data[i]);
                }
                console.log(reportDetails);
            }
          };

          callBack = callBack.bind(this);

          url = 'report/appraiserQualityMetrics/details/county='+state.county+'&client='+state.client+'&openBy='+state.openBy+'&appraiser='+appraiserFk+'&type=cu_score_4&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&appraisalType='+appraisalType;
          httpGet(url, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);

          callBack = (response)=>{
            let code = response.data?response.data.code:undefined;
            if(code==='00'){
                for(let i=0;i<response.data.data.length;i++){
                reportDetails.push(response.data.data[i]);
                }
                console.log(reportDetails);

                setState({reportDetails:reportDetails, type:'cu_score_4+cu_score_5'});
            }
          };

          callBack = callBack.bind(this);
          url = 'report/appraiserQualityMetrics/details/county='+state.county+'&client='+state.client+'&openBy='+state.openBy+'&appraiser='+appraiserFk+'&type=cu_score_5&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&appraisalType='+appraisalType;
          httpGet(url, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
      }
      else{
          let url = 'report/appraiserPerformance/details/county='+state.county+'&client='+state.client+'&openBy='+state.openBy+'&appraiser='+appraiserFk+'&type='+type+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&appraisalType='+appraisalType;
          let callBack = apiCallBack([{state:'type', value:type}, {state:'reportDetails', key:'data'}]);
          httpGet(url, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
      }
    }

    const getExistingWeeklyReport = ()=>{
        let callBack = apiCallBack([{state:'existingWeeklyReport', key:'data'}]);
        httpGet('report/weekly/get', '', 'Oops, something went wrong and could not load your weekly report1.', callBack);
    }

    const createNewWeeklyReport = ()=>{
      let configurations = [];
  
      configurations.push('period='+state.weeklyReportPeriod);
  
      if(state.client!==0){
        configurations.push('client='+state.client);
  
        let clientName = '';
  
        for(let i=0;i<state.clients.length;i++){
          if(state.clients[i].ID===state.client)
            clientName = state.clients[i].company;
        }
        configurations.push('clientName='+clientName);
      }
  
      if(state.appraiser!==0){
        let options = state.options.slice();
        let name = '';
  
        for(let i=0;i<options.length;i++){
          if(options[i].value===state.appraiser){
            let token = state.options[i].label.split('-');
            token.splice(token.length-1, 1);
  
            name = token.join('-');
            break;
          }
        }
  
        configurations.push('appraiser_fk='+state.appraiser);
        configurations.push('appraiserName='+name);
      }
  
      if(state.filterAppraisalTypes.length>0){
        configurations.push('appraisalType='+state.filterAppraisalTypes.join('|'));
      }
  
      if(state.isRush!=='')
        configurations.push('rush='+state.isRush);
  
      if(state.isComplex!=='')
        configurations.push('complex='+state.isComplex);
  
      if(state.state!=='')
        configurations.push('state='+state.state);
  
      if(state.county!=='')
        configurations.push('county='+state.county);
  
      if(state.city!=='')
        configurations.push('city='+state.city);
  
      if(state.loanPurpose!=='')
        configurations.push('loanPurpose='+state.loanPurpose);
  
      if(state.loanType!=='')
        configurations.push('loanType='+state.loanType);
  
      if(state.entity!=='')
        configurations.push('entity='+state.entity);
  
  
      let configuration = configurations.join('&');
  
      let parameters = [
        {
          field:'report',
          value: 'appraiserPerformance'
        },
        {
          field:'configuration',
          value:configuration
        }
      ]
      
      let callBack = apiCallBack([]);
      httpPost('report/weekly/create', parameters, 'Weekly report created.', 'Oops, something went wrong and could not create the new weekly report. Please try again later.', callBack);
    }
    
    const deleteWeeklyReport = (ID)=>{
        let list = sliceFromArray(state.existingWeeklyReport,'ID', ID);
        let callBack = apiCallBack([{state:'existingWeeklyReport', value:list}]);
        httpDelete('report/weekly/'+ID,'Weekly report deleted.','Oops, something went wrong and could not delete the weekly report. Please try again later.', callBack);
    }

    //render
    let newfrom, newto;
    if(state.from!=='')
        newfrom = moment(state.from);
    if(state.to!=='')
        newto = moment(state.to);

    let columnsDetails;

    switch(state.type){
        case 'complexFee':
        columnsDetails = [
            {
            id: 'referenceNumber',
            Header: 'Reference #',
            accessor: d => d.reference_num,
            Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'loanNum',
            Header: 'Loan #',
            accessor: d => d.loan_num,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'borrower',
            Header: 'Borrower',
            accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'property',
            Header: 'Property',
            accessor: d => d.property_street+' '+d.property_city+', '+d.property_state,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'complex',
            Header: 'Complex Fee',
            accessor: d => '$'+formatNumber(d.complex_fee_amount),
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'dateSubmitted',
            Header: 'Submitted',
            accessor: d => formatDate(d.datetime_submitted),
            headerStyle: {
                textAlign:'left'
            }
            },
        ];
        break;
        case 'orderCount':
        columnsDetails = [
            {
            id: 'referenceNumber',
            Header: 'Reference #',
            accessor: d => d.reference_num,
            Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'loanNum',
            Header: 'Loan #',
            accessor: d => d.loan_num,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'borrower',
            Header: 'Borrower',
            accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'property',
            Header: 'Property',
            accessor: d => d.property_street+' '+d.property_city+', '+d.property_state,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'dateSubmitted',
            Header: 'Submitted',
            accessor: d => formatDate(d.datetime_submitted),
            headerStyle: {
                textAlign:'left'
            }
            },
        ];
        break;
        case 'overallTAT':
            columnsDetails = [
            {
                id: 'referenceNumber',
                Header: 'Reference #',
                accessor: d => d.reference_num,
                Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
                headerStyle: {
                textAlign:'left'
                }
            },
            {
                id: 'loanNum',
                Header: 'Loan #',
                accessor: d => d.loan_num,
                headerStyle: {
                textAlign:'left'
                }
            },
            {
                id: 'borrower',
                Header: 'Borrower',
                accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
                headerStyle: {
                textAlign:'left'
                }
            },
            {
                id: 'property',
                Header: 'Property',
                accessor: d => d.property_street+' '+d.property_city+', '+d.property_state,
                headerStyle: {
                textAlign:'left'
                }
            },
            {
                id: 'dateSubmitted',
                Header: 'Submitted',
                accessor: d => formatDateTime(d.datetime_submitted),
                headerStyle: {
                textAlign:'left'
                }
            },
            {
                id: 'dateCompleted',
                Header: 'Completed',
                accessor: d => formatDateTime(d.datetime_completed),
                headerStyle: {
                textAlign:'left'
                }
            },
            {
                id: 'tat',
                Header: 'TAT',
                accessor: d => d.overall_tat,
                headerStyle: {
                textAlign:'left'
                }
            },
            ];
            break;
        case 'acceptingTAT':
        columnsDetails = [
            {
            id: 'referenceNumber',
            Header: 'Reference #',
            accessor: d => d.reference_num,
            Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'loanNum',
            Header: 'Loan #',
            accessor: d => d.loan_num,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'borrower',
            Header: 'Borrower',
            accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'property',
            Header: 'Property',
            accessor: d => d.property_street+' '+d.property_city+', '+d.property_state,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'dateAssigned',
            Header: 'Assigned',
            accessor: d => formatDateTime(d.datetime_assigned),
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'dateAccepted',
            Header: 'Accepted',
            accessor: d => formatDateTime(d.datetime_accepted),
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'tat',
            Header: 'TAT',
            accessor: d => d.accepting_tat,
            headerStyle: {
                textAlign:'left'
            }
            },
        ];
        break;
        case 'schedulingTAT':
        columnsDetails = [
            {
            id: 'referenceNumber',
            Header: 'Reference #',
            accessor: d => d.reference_num,
            Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'loanNum',
            Header: 'Loan #',
            accessor: d => d.loan_num,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'borrower',
            Header: 'Borrower',
            accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'property',
            Header: 'Property',
            accessor: d => d.property_street+' '+d.property_city+', '+d.property_state,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'dateAccepted',
            Header: 'Accepted',
            accessor: d => formatDateTime(d.datetime_accepted),
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'dateScheduled',
            Header: 'Date Scheduled',
            accessor: d => formatDateTime(d.datetime_scheduled),
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'tat',
            Header: 'TAT',
            accessor: d => d.scheduling_tat,
            headerStyle: {
                textAlign:'left'
            }
            },
        ];
        break;
        case 'reportingTAT':
        columnsDetails = [
            {
            id: 'referenceNumber',
            Header: 'Reference #',
            accessor: d => d.reference_num,
            Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'loanNum',
            Header: 'Loan #',
            accessor: d => d.loan_num,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'borrower',
            Header: 'Borrower',
            accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'property',
            Header: 'Property',
            accessor: d => d.property_street+' '+d.property_city+', '+d.property_state,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'dateInspectionCompleted',
            Header: 'Inspection Completed',
            accessor: d => formatDateTime(d.datetime_inspection_completed),
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'dateUploaded',
            Header: 'Appraisal Uploaded',
            accessor: d => formatDateTime(d.datetime_appraisal_uploaded),
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'tat',
            Header: 'TAT',
            accessor: d => d.reporting_tat,
            headerStyle: {
                textAlign:'left'
            }
            },
        ];
        break;
        case 'onTime':
        break;
        case 'clientOnTime':
        columnsDetails = [
            {
            id: 'referenceNumber',
            Header: 'Reference #',
            accessor: d => d.reference_num,
            Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'loanNum',
            Header: 'Loan #',
            accessor: d => d.loan_num,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'borrower',
            Header: 'Borrower',
            accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'property',
            Header: 'Property',
            accessor: d => d.property_street+' '+d.property_city+', '+d.property_state,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'dateDue',
            Header: 'Due Date',
            accessor: d => formatDate(d.datetime_due),
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'dateUploaded',
            Header: 'Appraisal Uploaded',
            accessor: d => formatDateTime(d.datetime_appraisal_uploaded),
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'onTime',
            Header: 'On Time',
            accessor: d => d.on_time,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'clientOnTime',
            Header: 'Client On Time',
            accessor: d => d.client_on_time,
            headerStyle: {
                textAlign:'left'
            }
            },
        ];
        break;
        case 'rebuttal':
        columnsDetails = [
            {
            id: 'referenceNumber',
            Header: 'Reference #',
            accessor: d => d.reference_num,
            Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'loanNum',
            Header: 'Loan #',
            accessor: d => d.loan_num,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'borrower',
            Header: 'Borrower',
            accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'property',
            Header: 'Property',
            accessor: d => d.property_street+' '+d.property_city+', '+d.property_state,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'dateSubmitted',
            Header: 'Submitted',
            accessor: d => formatDate(d.datetime_submitted),
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'rebuttal',
            Header: 'Rebuttal Count',
            accessor: d => d.rebuttal_count,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'condition',
            Header: 'Condition Count',
            accessor: d => d.condition_count,
            headerStyle: {
                textAlign:'left'
            }
            },
        ];
        break;
        case 'condition':
        columnsDetails = [
            {
            id: 'referenceNumber',
            Header: 'Reference #',
            accessor: d => d.reference_num,
            Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'loanNum',
            Header: 'Loan #',
            accessor: d => d.loan_num,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'borrower',
            Header: 'Borrower',
            accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'property',
            Header: 'Property',
            accessor: d => d.property_street+' '+d.property_city+', '+d.property_state,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'dateSubmitted',
            Header: 'Submitted',
            accessor: d => formatDate(d.datetime_submitted),
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'rebuttal',
            Header: 'Rebuttal Count',
            accessor: d => d.rebuttal_count,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'condition',
            Header: 'Condition Count',
            accessor: d => d.condition_count,
            headerStyle: {
                textAlign:'left'
            }
            },
        ];
        break;
        case 'avg_internal_condition_rate':
        case 'avg_external_condition_rate':
        columnsDetails = [
            {
            id: 'referenceNumber',
            Header: 'Reference #',
            accessor: d => d.reference_num,
            Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'totalCondition',
            Header: 'Total Condition',
            accessor: d => d.total_condition,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'loanNum',
            Header: 'Loan #',
            accessor: d => d.loan_num,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'borrower',
            Header: 'Borrower',
            accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'property',
            Header: 'Property',
            accessor: d => d.property_street+' '+d.property_city+', '+d.property_state,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'dateSubmitted',
            Header: 'Submitted',
            accessor: d => formatDate(d.datetime_submitted),
            headerStyle: {
                textAlign:'left'
            }
            },
        ];
        break;
        case 'cu_score_4+cu_score_5':
        columnsDetails = [
            {
            id: 'score',
            Header: 'Score',
            accessor: d => d.score,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'referenceNumber',
            Header: 'Reference #',
            accessor: d => d.reference_num,
            Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'loanNum',
            Header: 'Loan #',
            accessor: d => d.loan_num,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'borrower',
            Header: 'Borrower',
            accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'property',
            Header: 'Property',
            accessor: d => d.property_street+' '+d.property_city+', '+d.property_state,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'dateSubmitted',
            Header: 'Submitted',
            accessor: d => formatDate(d.datetime_submitted),
            headerStyle: {
                textAlign:'left'
            }
            },
        ];
        break;
        case 'service_rating':
        columnsDetails = [
            {
            id: 'referenceNumber',
            Header: 'Reference #',
            accessor: d => d.reference_num,
            Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'loanNum',
            Header: 'Loan #',
            accessor: d => d.loan_num,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'borrower',
            Header: 'Borrower',
            accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'property',
            Header: 'Property',
            accessor: d => d.property_street+' '+d.property_city+', '+d.property_state,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'serviceRating',
            Header: 'Service Rating',
            accessor: d => d.service_rating,
            Cell: props => <div><div>{props.row.original.service_rating}</div>
                            <div>{
                                props.row.original.items.map(
                                (item, index2)=>{
                                    return (
                                    <div style={{borderTop:'1px solid #d2d2d2',paddingTop:'10px', fontSize:'12px'}} key={index2}>
                                        <Row>
                                        <Col sm="2">
                                            {item.deduct_score.includes("-")&&<font color="red">{item.deduct_score}</font>}
                                            {!item.deduct_score.includes("-")&&<font color="green">{item.deduct_score}</font>}
                                        </Col>
                                        <Col sm="10">
                                            {item.description}<br/>
                                        </Col>
                                        </Row>
                                    </div>
                                    )
                                }
                                )
                            }</div></div>,
            headerStyle: {
                textAlign:'left'
            }
            },
        ];
        break;

        case 'quality_rating':
        columnsDetails = [
            {
            id: 'referenceNumber',
            Header: 'Reference #',
            accessor: d => d.reference_num,
            Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'loanNum',
            Header: 'Loan #',
            accessor: d => d.loan_num,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'borrower',
            Header: 'Borrower',
            accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'property',
            Header: 'Property',
            accessor: d => d.property_street+' '+d.property_city+', '+d.property_state,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'issue_category',
            Header: 'Category',
            accessor: d => d.issue_category,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'record_user',
            Header: 'Author',
            accessor: d => d.record_user,
            headerStyle: {
                textAlign:'left'
            }
            },
            {
            id: 'qualityRating',
            Header: 'Quality Rating',
            accessor: d => d.quality_rating,
            headerStyle: {
                textAlign:'left'
            }
            },
        ];
        break;
        default:
        break;
    }

    const columns = [
        {
        id: 'appraiserID',
        Header: 'Appraiser',
        accessor: d => d.ID,
        Cell: props => <NavLink target="_blank" to={"/appraiser/"+props.row.original.ID}>{props.row.original.name}</NavLink>,
        headerStyle: {
            textAlign:'left'
        }
        },
        {
        id: 'counties',
        Header: 'Counties',
        accessor: d => d.counties,
        headerStyle: {
            textAlign:'left'
        }
        },
        {
        id: 'serviceRating',
        Header: 'SR*',
        accessor: d => d.service_rating,
        Cell: props => props.row.original.service_rating===-1?'Not available': <NavLink to="#" onClick={()=>{reportDetailsToggle();getReportDetails(props.row.original.ID, 'service_rating')}}>{props.row.original.service_rating+'/5'}</NavLink>,
        headerStyle: {
            textAlign:'left'
        }
        },
        {
        id: 'qualityRating',
        Header: 'QR*',
        accessor: d => d.quality_rating,
        Cell: props => {
            return props.row.original.quality_rating===-1?'Not available': <NavLink to="#" onClick={()=>{reportDetailsToggle();getReportDetails(props.row.original.ID, 'quality_rating')}}>
            <div id={"recorded_issue"+props.row.original.ID}>
                <ReactStars
                    onChange={()=>{}}
                    value={parseInt(props.row.original.quality_rating,10)}
                    count={3}
                    edit={false}
                    half={true}
                    size={20}
                />
            </div>
                <UncontrolledTooltip delay={{ "show": 0, "hide": 0 }} placement="top" target={"recorded_issue"+props.row.original.ID}>
                {+props.row.original.quality_rating+'/3.00'}
                </UncontrolledTooltip>
            </NavLink>
        },
        headerStyle: {
            textAlign:'left'
        }
        },
        {
        id: 'fee',
        Header: 'Avg Fee',
        accessor: d => d.main_1004_avg_fee,
        Cell: props => {
            let main1004 = <div></div>;
            let main1073 = <div></div>;
            let main1025 = <div></div>;

            if(props.row.original.main_1004_avg_fee!==-1)
            main1004 = <div>${formatNumber(props.row.original.main_1004_avg_fee)}</div>;
            if(props.row.original.main_1073_avg_fee!==-1)
            main1073 = <div>${formatNumber(props.row.original.main_1073_avg_fee)}</div>;
            if(props.row.original.main_1025_avg_fee!==-1)
            main1025 = <div>${formatNumber(props.row.original.main_1025_avg_fee)}</div>;

            return <div id={"fee"+props.row.original.ID}>
            <b>1004</b> - <div className="display-inline">{main1004}</div><br/>
            <b>1073</b> - <div className="display-inline">{main1073}</div><br/>
            <b>1025</b> - <div className="display-inline">{main1025}</div>

            <UncontrolledTooltip delay={{ "show": 0, "hide": 0 }} placement="right" target={"fee"+props.row.original.ID}>
                {
                props.row.original.avg_fee.map(
                    (fee, index2)=>{
                    return <div key={index2} style={{fontSize:'9px'}}>
                        {fee.appraisal_type} - {fee.avg_fee}<br/><br/>
                    </div>
                    }
                )
                }
            </UncontrolledTooltip>
            </div>
        },
        headerStyle: {
            textAlign:'left'
        }
        },
        {
        id: 'overallTAT',
        Header: 'Overall TAT',
        accessor: d => d.overall_tat,
        Cell: props => props.row.original.overall_tat===-1?'Not available':<NavLink to="#" onClick={()=>{reportDetailsToggle();getReportDetails(props.row.original.ID, 'overallTAT')}}>{props.row.original.overall_tat+' bd'}</NavLink>,
        headerStyle: {
            textAlign:'left'
        }
        },
        {
        id: 'acceptingTAT',
        Header: 'Accepting TAT',
        accessor: d => d.accepting_tat,
        Cell: props => props.row.original.accepting_tat===-1?'Not available':<NavLink to="#" onClick={()=>{reportDetailsToggle();getReportDetails(props.row.original.ID, 'acceptingTAT')}}>{props.row.original.accepting_tat+' bh'}</NavLink>,
        headerStyle: {
            textAlign:'left'
        }
        },
        {
        id: 'schedulingTAT',
        Header: 'Scheduling TAT',
        accessor: d => d.scheduling_tat,
        Cell: props => props.row.original.scheduling_tat===-1?'Not available':<NavLink to="#" onClick={()=>{reportDetailsToggle();getReportDetails(props.row.original.ID, 'schedulingTAT')}}>{props.row.original.scheduling_tat+' bh'}</NavLink>,
        headerStyle: {
            textAlign:'left'
        }
        },
        {
        id: 'reportingTAT',
        Header: 'Reporting TAT',
        accessor: d => d.reporting_tat,
        Cell: props => props.row.original.reporting_tat===-1?'Not available':<NavLink to="#" onClick={()=>{reportDetailsToggle();getReportDetails(props.row.original.ID, 'reportingTAT')}}>{props.row.original.reporting_tat+' bh'}</NavLink>,
        headerStyle: {
            textAlign:'left'
        }
        },
        {
        id: 'rebuttal',
        Header: 'RR*',
        accessor: d => d.rebuttal,
        Cell: props => <NavLink to="#" onClick={()=>{reportDetailsToggle();getReportDetails(props.row.original.ID, 'rebuttal')}}>{props.row.original.rebuttal+'%'}</NavLink>,
        headerStyle: {
            textAlign:'left'
        }
        },
        {
        id: 'condition',
        Header: 'CR*',
        accessor: d => d.condition,
        Cell: props => <div><div>
                        <NavLink to="#" onClick={()=>{reportDetailsToggle();getReportDetails(props.row.original.ID, 'condition')}}>{props.row.original.condition+'%'}</NavLink>
                        </div>
                        <div style={{fontSize:'12px'}}>
                        Internal - <NavLink to="#" onClick={()=>{reportDetailsToggle();getReportDetails(props.row.original.ID, 'avg_internal_condition_rate')}}>{props.row.original.avg_internal_condition_rate+'%'}</NavLink>
                        </div>
                        <div style={{fontSize:'12px'}}>
                        External - <NavLink to="#" onClick={()=>{reportDetailsToggle();getReportDetails(props.row.original.ID, 'avg_external_condition_rate')}}>{props.row.original.avg_external_condition_rate+'%'}</NavLink>
                        </div></div>,
        headerStyle: {
            textAlign:'left'
        }
        },
        {
        id: 'cuScore45',
        Header: 'CU Score>=4',
        accessor: d => d.cu_score_4+d.cu_score_5,
        Cell: props => <NavLink to="#" onClick={()=>{reportDetailsToggle();getReportDetails(props.row.original.ID, 'cu_score_4+cu_score_5')}}>{props.row.original.cu_score_4+props.row.original.cu_score_5}</NavLink>,
        headerStyle: {
            textAlign:'left'
        }
        },
        {
        id: 'onTime',
        Header: 'OT*',
        accessor: d => d.on_time,
        Cell: props => <NavLink to="#" onClick={()=>{reportDetailsToggle();getReportDetails(props.row.original.ID, 'onTime')}}>{props.row.original.on_time+'%'}</NavLink>,
        headerStyle: {
            textAlign:'left'
        }
        },
        {
        id: 'clientOnTime',
        Header: 'Client OT*',
        accessor: d => d.client_on_time,
        Cell: props => <NavLink to="#" onClick={()=>{reportDetailsToggle();getReportDetails(props.row.original.ID, 'clientOnTime')}}>{props.row.original.client_on_time!=='Not available'?props.row.original.client_on_time+'%':props.row.original.client_on_time}</NavLink>,
        headerStyle: {
            textAlign:'left'
        }
        },
        {
        id: 'complex',
        Header: 'Has CF*',
        accessor: d => d.complex,
        Cell: props => <NavLink to="#" onClick={()=>{reportDetailsToggle();getReportDetails(props.row.original.ID, 'complexFee')}}>{props.row.original.complex + ' ('+(props.row.original.complex/props.row.original.order_count)*100+'%)'}</NavLink>,
        headerStyle: {
            textAlign:'left'
        }
        },
        {
        id: 'orderCount',
        Header: 'Order Count',
        accessor: d => d.order_count,
        Cell: props => <NavLink to="#" onClick={()=>{reportDetailsToggle();getReportDetails(props.row.original.ID, 'orderCount')}}>{props.row.original.order_count}</NavLink>,
        headerStyle: {
            textAlign:'left'
        }
        },
    ];

    let newReport = [];
    if(state.report.length > 0){
        newReport = state.report.map((report) => {
        let temp = Object.assign({}, report);
        if(temp.main_1004_avg_fee===-1)
            temp.main_1004_avg_fee = 0;
        if(temp.main_1073_avg_fee===-1)
            temp.main_1073_avg_fee = 0;
        if(temp.main_1025_avg_fee===-1)
            temp.main_1025_avg_fee= 0;

        temp.cu_score = temp.cu_score_4 + temp.cu_score_5;

        return temp;
        });
    }

    let reportFiltersConfig1 = [
      {id:'from',value:newfrom, updateFunc:(v)=>setState({from:v}), width:'2'},
      {id:'to',value:newto, updateFunc:(v)=>setState({to:v}), width:'2'},
      {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'4'},
      {id:'openBy',value:state.openBy, updateFunc:(v)=>setState({openBy:v}), width:'4'},
      {id:'appraiser',value:state.appraiser, updateFunc:(v)=>setState({appraiser:v}), width:'3'},
      {id:'rush',value:state.isRush, updateFunc:(v)=>setState({isRush:v}), width:'3'},
      {id:'complex',value:state.isComplex, updateFunc:(v)=>setState({isComplex:v}), width:'3'},
      {id:'state',value:state.state, updateFunc:(v)=>setState({state:v}), width:'3'},
      {id:'county',value:state.county, updateFunc:(v)=>setState({county:v}), width:'2'},
      {id:'city',value:state.city, updateFunc:(v)=>setState({city:v}), width:'2'},
      {id:'loanPurpose',value:state.loanPurpose, updateFunc:(v)=>setState({loanPurpose:v}), width:'2'},
      {id:'loanType',value:state.loanType, updateFunc:(v)=>setState({loanType:v}), width:'2'},
      {id:'entity',value:state.entity, updateFunc:(v)=>setState({entity:v}), width:'4'},
      {id:'appraisalType',value:state.filterAppraisalTypes, updateFunc:(v)=>setState({filterAppraisalTypes:v}), width:'4'},
      {id:'button',value:'Submit', updateFunc:getReport, width:'8', className:"align-right", color:"warning"},
    ];

    let reportFiltersConfig2 = [
      {id:'customSelect',value:state.weeklyReportPeriod, updateFunc:(v)=>setState({weeklyReportPeriod:v}), width:'2', label:'Trace Back Period', clearable:true, className:"red-color", options:[
        {label:"1 Week", value:"1w"},
        {label:"2 Weeks", value:"2w"},
        {label:"3 Weeks", value:"3w"},
        {label:"1 Months", value:"1m"},
        {label:"2 Months", value:"2m"},
        {label:"3 Months", value:"3m"},
        {label:"6 Months", value:"6m"},
      ]},
      {id:'from',value:newfrom, updateFunc:(v)=>setState({from:v}), width:'2'},
      {id:'to',value:newto, updateFunc:(v)=>setState({to:v}), width:'2'},
      {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'3'},
      {id:'openBy',value:state.openBy, updateFunc:(v)=>setState({openBy:v}), width:'3'},
      {id:'appraiser',value:state.appraiser, updateFunc:(v)=>setState({appraiser:v}), width:'3'},
      {id:'rush',value:state.isRush, updateFunc:(v)=>setState({isRush:v}), width:'3'},
      {id:'complex',value:state.isComplex, updateFunc:(v)=>setState({isComplex:v}), width:'3'},
      {id:'state',value:state.state, updateFunc:(v)=>setState({state:v}), width:'3'},
      {id:'county',value:state.county, updateFunc:(v)=>setState({county:v}), width:'3'},
      {id:'city',value:state.city, updateFunc:(v)=>setState({city:v}), width:'3'},
      {id:'loanPurpose',value:state.loanPurpose, updateFunc:(v)=>setState({loanPurpose:v}), width:'3'},
      {id:'loanType',value:state.loanType, updateFunc:(v)=>setState({loanType:v}), width:'3'},
      {id:'entity',value:state.entity, updateFunc:(v)=>setState({entity:v}), width:'4'},
      {id:'appraisalType',value:state.filterAppraisalTypes, updateFunc:(v)=>setState({filterAppraisalTypes:v}), width:'4'},
      {id:'button',value:'Submit', updateFunc:getReport, width:'4', className:"align-right", color:"warning"},
    ];

    return <div>
        <Modal className="my-modal" isOpen={state.showReportLoading} toggle={toggleReportLoading} >
          <ModalHeader hidden={true} toggle={toggleReportLoading}></ModalHeader>
          <ModalBody>
            <center>
              <i style={{fontSize:'55px'}} className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
              <br/><br/>
              Report Loading...<br/>
              Please wait, your report will be loaded once it is ready.
            </center>
            <br/><br/>
            <center>
              <Button color="info" onClick={toggleReportLoading}>Close</Button>
            </center>
          </ModalBody>
        </Modal>

        <Modal className="my-modal" isOpen={state.showLegend} toggle={toggleLegend} >
          <ModalHeader hidden={true} toggle={toggleLegend}></ModalHeader>
          <ModalBody>
            <center>
              <h5><i className="fa fa-question-circle"></i>&nbsp;Report Information</h5>
            </center>
            <br/>
            <font>
              <font color="red">*** If the action is taken by the user during non-business hours(Monday through Friday before 9:00 AM and after 5:00 PM and weekend), the system will record the action time using the earliest business hours(bh)/business day(bd) for the TAT calculation. *** </font><br/><br/>
              <b><font color="#FD7F20">Submitted</font> <i className="fa fa-arrow-right"></i> <font color="#006DB0">Completed:</font></b> TAT Calculation that includes <b>everything</b> into account from beginning to the end. It does not exclude the wait time for inspection to happen.<br/><br/>
              <b><font color="#FD7F20">Submitted</font> <i className="fa fa-arrow-right"></i> <font color="#006DB0">Assignment Accepted:</font></b> TAT Calculation that includes everything in between of <b>date submitted</b> and <b>date assignment accepted</b> by appraiser.<br/><br/>
              <b><font color="#FD7F20">Inspection Completed</font> <i className="fa fa-arrow-right"></i> <font color="#006DB0">Completed:</font></b> TAT Calculation that includes everything in between of <b>date inspection completed</b> and date completed when the report was <b>shipped</b>.<br/><br/>
              <b><font color="#FD7F20">Accepted</font> <i className="fa fa-arrow-right"></i> <font color="#006DB0">Inspection Date:</font></b> TAT Calculation that includes everything in between <b>date assignment accepted</b> by appraiser and <b>date of inspection</b>. The wait time for inspection to happen is included.<br/><br/>
              <b><font color="#FD7F20">Submitted</font> <i className="fa fa-arrow-right"></i> <font color="#F26627">Scheduled + Inspection Date</font> <i className="fa fa-arrow-right"></i> <font color="#006DB0"> Completed:</font></b> TAT Calculation that is similar to <b>Submitted - Completed TAT Calculation</b> except it <b>excludes the wait time</b> for inspection to happen.<br/><br/>
              *** All TAT exclude hold and waiting on contact period ***

            </font>
            <br/><br/>
            <center>
              <Button color="info" onClick={toggleLegend}>Close</Button>
            </center>
          </ModalBody>
        </Modal>
        <Card>
          <CardHeader className="header-color">
            <Row>
              <Col sm="6" className="align-left">
                <i className="fa fa-reorder"></i>&nbsp;Appraiser Performance Report
              </Col>
              <Col sm="6" className="align-right white-color">
                <NavLink to="#" onClick={toggleLegend}><i className="fa fa-question-circle"></i>&nbsp;Report Information</NavLink>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              <Col sm="8">
                <b>*You can setup a weekly report based on the parameters below by clicking the icon on the right.</b>
              </Col>
              <Col sm="4" className="align-right">
                <i className="fa fa-envelope-open-o cursor-pointer green-color" onClick={weeklyReportToggle}/>
              </Col>
            </Row>
            <ReportFilter {...props} configs={reportFiltersConfig1}/>
          </CardBody>
        </Card>
        <br/>

        <Card>
          <CardHeader className="header-color">
            <Row>
              <Col sm="8">
                <i className="fa fa-reorder"></i>&nbsp;Appraiser Performance Report
              </Col>
              <Col sm="4">
                <div className="align-right">
                  <ExcelFile element={<Button disabled={state.report.length<=0} color="warning">Download Excel</Button>}>
                    <ExcelSheet data={newReport} name="XLSX">
                        <ExcelColumn label="Appraiser" value="name"/>
                        <ExcelColumn label="Counties" value="counties"/>
                        <ExcelColumn label="Service Rating" value="service_rating"/>
                        <ExcelColumn label="Quality Rating" value="quality_rating"/>
                        <ExcelColumn label="Avg 1004 Fee" value="main_1004_avg_fee"/>
                        <ExcelColumn label="Avg 1025 Fee" value="main_1025_avg_fee"/>
                        <ExcelColumn label="Avg 1073 Fee" value="main_1073_avg_fee"/>
                        <ExcelColumn label="Overall TAT" value="overall_tat"/>
                        <ExcelColumn label="Accepting TAT" value="accepting_tat"/>
                        <ExcelColumn label="Scheduling TAT" value="scheduling_tat"/>
                        <ExcelColumn label="Reporting TAT" value="reporting_tat"/>
                        <ExcelColumn label="Rebuttal Rate" value="rebuttal"/>
                        <ExcelColumn label="Condition Rate" value="condition"/>
                        <ExcelColumn label="Internal Condition Rate" value="avg_internal_condition_rate"/>
                        <ExcelColumn label="External Condition Rate" value="avg_external_condition_rate"/>
                        <ExcelColumn label="CU Score>=4" value="cu_score"/>
                        <ExcelColumn label="On Time" value="on_time"/>
                        <ExcelColumn label="Client On Time" value="client_on_time"/>
                        <ExcelColumn label="Order Count" value="order_count"/>
                    </ExcelSheet>
                  </ExcelFile>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <div><font color="red">*</font>SR = Service Rating&nbsp;&nbsp;&nbsp;<font color="red">*</font>QR = Quality Rating&nbsp;&nbsp;&nbsp;<font color="red">*</font>OT = On Time&nbsp;&nbsp;&nbsp;<font color="red">*</font>CF = Complex Fee&nbsp;&nbsp;&nbsp;<font color="red">*</font>CR = Condition Rate&nbsp;&nbsp;&nbsp;<font color="red">*</font>RR = Rebuttal Rate</div>
            <MyReactTable columns={columns} data={state.report} className="table table-striped"/>
          </CardBody>
        </Card>

        <Modal className="my-modal-wide" isOpen={state.detailsPopUpOpen} toggle={reportDetailsToggle} >
          <ModalHeader hidden={true} toggle={reportDetailsToggle}></ModalHeader>
          <ModalBody>
            <center>
              <h5><i className="fa fa-user"></i> Report Details</h5>
            </center>
            <br/>
            <div className="large-scroll-container">
              <MyReactTable columns={columnsDetails} data={state.reportDetails} className="table table-striped"/>
            </div>
            <br/>
            <center>
              <Button color="info" onClick={reportDetailsToggle}>Close</Button>
            </center>
          </ModalBody>
        </Modal>

        <Modal className="my-modal-wide" isOpen={state.weeklyReportPopUpOpen} toggle={weeklyReportToggle} >
          <ModalHeader hidden={true} toggle={weeklyReportToggle}></ModalHeader>
          <ModalBody>
            <center>
              <h5><i className="fa fa-user"></i> Weekly Report</h5>
            </center>
            <br/>
            <Row>
              <Col sm="8">
                <label className="red-color">
                  Please review the weekly report details below
                </label>
              </Col>
              <Col sm="4" className="align-right">
                <NavLink to="#" onClick={(e)=>{e.preventDefault();existingWeeklyReportToggle()}}>View my existing weekly report</NavLink>
              </Col>
            </Row>
            <br/><br/><br/>
            <ReportFilter {...props} configs={reportFiltersConfig2}/>
            <br/><br/>
            <Row>
              <Col sm="8">
                <label>You will receive an email every Monday 8.00 PM PST by confirming this setup</label>
              </Col>
              <Col sm="4">
                <div className="align-right">
                  <Button color="warning" onClick={createNewWeeklyReport}>Confirm</Button>
                </div>
              </Col>
            </Row>
            <br/>
            <center>
              <Button color="info" onClick={weeklyReportToggle}>Close</Button>
            </center>
          </ModalBody>
        </Modal>

        <Modal className="my-modal-wide" isOpen={state.existingWeeklyReportPopUpOpen} toggle={existingWeeklyReportToggle} >
          <ModalHeader hidden={true} toggle={existingWeeklyReportToggle}></ModalHeader>
          <ModalBody>
            <center>
              <h5><i className="fa fa-user"></i> My Existing Weekly Report</h5>
            </center>
            <br/>
            <NavLink to="#" onClick={(e)=>{weeklyReportToggle()}}>Setup New Weekly Report</NavLink>
            <br/>

            <div className="large-scroll-container">
              {
                state.existingWeeklyReport.map(
                  (report, index)=>{
                    let tokens = report.configuration.split('&');

                    let period = '';
                    let appraiser = '';
                    let client = '';
                    let appraisalTypes = '';
                    let state = '', county = '', city = '', loanPurpose = '', loanType = '', isRush = '', isComplex = '', entity = '';

                    for(let i=0;i<tokens.length;i++){
                      let keyPair = tokens[i].split('=');

                      switch(keyPair[0]){
                        case 'period':
                          switch(keyPair[1]){
                            case '1w':
                              period = '1 Week';
                              break;
                            case '2w':
                              period = '2 Weeks';
                              break;
                            case '3w':
                              period = '3 Weeks';
                              break;
                            case '1m':
                              period = '1 Month';
                              break;
                            case '2m':
                              period = '2 Months';
                              break;
                            case '3m':
                              period = '3 Months';
                              break;
                            case '6m':
                              period = '6 Months';
                              break;
                            default:
                              break;
                          }
                          break;
                        case 'clientName':
                          client = keyPair[1];
                          break;
                        case 'appraiserName':
                          appraiser = keyPair[1];
                          break;
                        case 'loanPurpose':
                          loanPurpose = keyPair[1];
                          break;
                        case 'loanType':
                          loanType = keyPair[1];
                          break;
                        case 'rush':
                          isRush = keyPair[1];
                          break;
                        case 'complex':
                          isComplex = keyPair[1];
                          break;
                        case 'state':
                          state = keyPair[1];
                          break;
                        case 'city':
                          city = keyPair[1];
                          break;
                        case 'county':
                          county = keyPair[1];
                          break;
                        case 'entity':
                          entity = keyPair[1];
                          break;
                        case 'appraisalType':
                          let token = keyPair[1].split('|');
                          appraisalTypes = token.join(', ');
                          break;
                        default:
                          break;
                      }
                    }

                    return(
                      <div key={index}>
                        <div className="my-well">
                          <i className="fa fa-times red-color cursor-pointer" onClick={(e)=>{

                            confirmation(
                              ()=>{deleteWeeklyReport(report.ID)},
                              ()=>{},
                              'Delete Weekly Report?',
                              'Are you sure you want to delete this weekly report?');
                          }}/>
                          <Row>
                            <Col sm="4">
                              <label>Period</label><br/>
                              {period}
                            </Col>
                            <Col sm="4">
                              <label>Client</label><br/>
                              {client}
                            </Col>
                            <Col sm="4">
                              <label>Appraiser</label><br/>
                              {appraiser}
                            </Col>
                          </Row>

                          <Row>
                            <Col sm="2">
                              <label>Rush</label><br/>
                              {isRush}
                            </Col>

                            <Col sm="2">
                              <label>Complex</label><br/>
                              {isComplex}
                            </Col>

                            <Col sm="2">
                              <label>State</label><br/>
                              {state}
                            </Col>

                            <Col sm="2">
                              <label>County</label><br/>
                              {county}
                            </Col>

                            <Col sm="2">
                              <label>City</label><br/>
                              {city}
                            </Col>
                          </Row>

                          <Row>
                            <Col sm="2">
                              <label>Loan Purpose</label><br/>
                              {loanPurpose}
                            </Col>

                            <Col sm="2">
                              <label>Loan Type</label><br/>
                              {loanType}
                            </Col>

                            <Col sm="2">
                              <label>AE/LO/Loan Processor/Broker</label><br/>
                              {entity}
                            </Col>

                            <Col sm="5">
                              <label>Report Type</label><br/>
                              {appraisalTypes}
                            </Col>

                          </Row>
                        </div>
                        <br/>
                      </div>
                    );
                  }
                )
              }
            </div>
            <center>
              <Button color="info" onClick={existingWeeklyReportToggle}>Close</Button>
            </center>
          </ModalBody>
        </Modal>
    </div>;
}


export default AppraiserPerformance;
