//Author Sooyoung Kim
//Date July 3, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, showMessage} from '../../../util/util';
import {Card, CardHeader, CardBody} from 'reactstrap';
import moment from 'moment';
import {NavLink, useParams} from 'react-router-dom';
import MyTimeline from '../../util/my-timeline';
import MyTimelineElement from '../../util/my-timeline-element';
import ReportFilter from '../report-filter';
import React, {useReducer, useEffect} from 'react';


//initialize the state
let today = new Date();
let month = today.getMonth()+1;

if(month<10)
  month = '0'+month;
let defaultfrom = today.getFullYear()+'-'+month+'-01';
let defaultto = today.getFullYear()+'-'+month+'-'+today.getDate();

const initialState = {
  admins:[],
  timelines:[],
  map:[]
};

//reducer function that perform state update
const reducer = getReducer();

const AdminTimelineReport  = (props)=>{
  const controller = new AbortController();
  let {params} = useParams();

  let from = defaultfrom;
  let to = defaultto;
  let admin = '';

  if(params&&params!==''){
    params = params.split('&');
    if(params){
      for(let i=0;i<params.length;i++){
        let keyPair = params[i].split('=');
        switch(keyPair[0]){
        case 'from':
          from = keyPair[1];
          break;
        case 'to':
          to = keyPair[1];
          break;
        case 'user_fk':
          admin = keyPair[1];
          break;
        default:
          break;
        }
      }
    }
  }

  let newInitialState = Object.assign({}, initialState, {
      from:from,
      to:to,
      admin:admin,
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getAllAdmin();
    getReportDetails();
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const removeTimeline = (index)=>{
    let timelines = state.timelines.slice();
    timelines.splice(index,1);
    setState({timelines:timelines});
  }

  const getAdminName = (userFk)=>{
    let admins = state.admins.slice();

    for(let i=0;i<admins.length;i++){
      if(admins[i].user_fk ===userFk){
        return admins[i].first_name+' '+admins[i].last_name;
      }
    }
    return '';
  }

  //API call
  const getAllAdmin = ()=>{
    let callBack = apiCallBack([{state:'admins', key:'data'}]);
    httpGet('admin/get/status=activated', '', 'Oops, something went wrong and could not load admins. Please try again later.', callBack);
  }

  const getReportDetails = ()=>{
    if(state.admin===''){
      showMessage('warning','Please select the admin you wish to see the timeline.');
    }
    else{
      //check if existing data exist
      let timelines = state.timelines.slice();
      for(let i=0;i<timelines.length;i++){
        if(timelines[i].from===state.from && timelines[i].to ===state.to && timelines[i].admin===state.admin){
          timelines.splice(i, 1);
          break;
        }
      }

      let callBack = (response)=>{
        let code = response.data?response.data.code:undefined;
        if(code==='00'){
          let tmp = {
            from:state.from,
            to:state.to,
            admin:state.admin,
            timelines:response.data.data
          };

          timelines.push(tmp);
          setState({timelines:timelines});
        }
      };
      callBack = callBack.bind(this);
      httpGet('report/admin/timeline/from='+state.from+'&to='+state.to+'&user_fk='+state.admin, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
    }
  }

  //render
  let colors = ['#ffb3b3','#9999ff','#00cc00','#0066cc','#6600ff','#ff00ff','#660033','#99ccff','#996600','#669999','#f5d6eb'];

  let newfrom, newto;
  if(state.from!=='')
    newfrom = moment(state.from);
  if(state.to!=='')
    newto = moment(state.to);

  let options = [];
  if(state.admins && state.admins.length > 0){
    options = state.admins.map((admin)=>{
      return {label:admin.first_name+' '+admin.last_name, value:admin.user_fk};
    })
  }

  let reportFiltersConfig = [
    {id:'from',value:newfrom, updateFunc:(v)=>setState({from:v}), width:'2'},
    {id:'to',value:newto, updateFunc:(v)=>setState({to:v}), width:'2'},
    {id:'customSelect',value:state.admin, updateFunc:(v)=>setState({admin:v}), width:'4', label:'Admin', clearable:true, className:"", options:options},
    {id:'button',value:'Submit', updateFunc:getReportDetails, width:'4', className:"align-right", color:"warning"},
  ];

  return <div>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Admin Timeline Report
      </CardHeader>
      <CardBody>
        <i>
          <label>***Please note that you can submit the report multiple times to have multiple timeline side by side.</label><br/>
        </i>
        <ReportFilter {...props} configs={reportFiltersConfig}/>
        <div className="my-divider">&nbsp;</div>
        <br/>
        <div className="my-well background-gray horizontal-scroll-container" style={{height:'auto'}}>
          <div style={{textAlign:'center'}}>
            {
              state.timelines.map(
                (timeline, index)=>{
                  return (

                    <div className="display-inline padding" style={{verticalAlign:'top'}} key={index}>
                      <div className="my-well" style={{background:colors[index%colors.length], color:'#fff', width:'700px'}}>
                        <i className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeTimeline(index)}/> {getAdminName(timeline.admin)+' - '+timeline.from+' - '+timeline.to}
                      </div>
                      <MyTimeline>
                      {
                        timeline.timelines.map(
                          (activity, index2)=>{
                            let position = 'left';

                            if(index2%2===0)
                              position = 'right';
                            return (
                              <MyTimelineElement color={colors[index%colors.length]} style={{display:'inline-block',textAlign:'left'}} key={index2} position={position} date={activity.datetime_date} month={activity.datetime_month}>
                                <div className="vertical-timeline-element-title"><NavLink target="_blank" to={"/appraisal/"+activity.appraisal_fk}>{activity.reference_num+' - '+activity.appraisal_type}</NavLink></div>
                                {
                                  activity.events.map(
                                    (event, index3)=>{
                                      return <div key={index3} style={{marginTop:'1px'}}>
                                        {event}
                                      </div>
                                    }
                                  )
                                }
                              </MyTimelineElement>
                            )
                          }
                        )
                      }
                      </MyTimeline>
                    </div>
                  )
                })
              }
          </div>
        </div>
      </CardBody>
    </Card>
  </div>;
}


export default AdminTimelineReport;
