//Author June Leow
//Date Oct 9th, 2023
import Avatar from 'react-avatar';
import AppaisalActions from './appraisal-actions';
import FileStorage from '../file/file-storage';
import AppraiserPipeline from './appraiser-pipeline';
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, deleteAPICallGenerator, callBackGenerator, generateSID, confirmation, formatDateTime, stringToHexCode, sliceFromArray, showMessage} from '../../util/util';
import {Alert, Button, Modal, ModalHeader, ModalBody, UncontrolledTooltip, Input, Card, CardBody, CardHeader, Row, Col, Table } from 'reactstrap';
import Comment from '../comment/comment';
import MySelect from '../util/my-select';
import MyXEditable from '../util/my-xeditable';
import {NavLink} from 'react-router-dom';
import React, {useReducer, useEffect, useRef} from 'react';
import './appraisal.css';
//initialize the state
const initialState = {
  clients:[],
  clientBranches:[],
  openByClientBranches:[],
  appraisalTypes:[],
  subAppraisalTypes:[],
  loanTypes:[{key:'Conventional',value:'Conventional'},{key:'HELOC/2nd Mortgage',value:'HELOC/2nd Mortgage'},{key:'High Balanced',value:'High Balanced'},{key:'Jumbo <$1m',value:'Jumbo <$1m'},{key:'Jumbo $1m - $2m',value:'Jumbo $1m - $2m'},{key:'Jumbo >$2m',value:'Jumbo >$2m'},{key:'FHA',value:'FHA'},{key:'USDA',value:'USDA'},{key:'Private',value:'Private'},{key:'Other',value:'Other'}],
  loanPurposes:[{key:'Purchase',value:'Purchase'},{key:'Refinance',value:'Refinance'},{key:'R/T Refinance',value:'R/T Refinance'},{key:'C/O Refinance',value:'C/O Refinance'},{key:'Other',value:'Other'}],
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
  propertyTypes:[{key:'2 Units',value:'2 Units'},{key:'3 Units',value:'3 Units'},{key:'4 Units',value:'4 Units'},{key:'Co-Operative (Co-Op)',value:'Co-Operative (Co-Op)'},{key:'Low Rise Condo',value:'Low Rise Condo'},{key:'Mid-Rise Condo',value:'Mid-Rise Condo'},{key:'High Rise Condo',value:'High Rise Condo'},{key:'Detached Condo',value:'Detached Condo'},{key:'Manufactured Housing',value:'Manufactured Housing'},{key:'SFR - Detached',value:'SFR - Detached'},{key:'SFR - Attached',value:'SFR - Attached'},{key:'PUD - Detached',value:'PUD - Detached'},{key:'PUD - Attached',value:'PUD - Attached'},{key:'Other',value:'Other'}],
  clientEntityList:['AE','CSR','Borrower','Co-Borrower','Loan Officer','Loan Processor','Broker','Broker Company','Other','Email Notification Subscriber','Payment Contact'],

  priorities:[],
  availableAppraisalTypes:[],
  appraisalTypeOptions:[],
  appraisalTypeFilters:{
    '2 Units':['1004','1073','1075','2055'],
    '3 Units':['1004','1073','1075','2055'],
    '4 Units':['1004','1073','1075','2055'],
    'Low Rise Condo':['1004','1025','2055'],
    'Mid-Rise Condo':['1004','1025','2055'],
    'High Rise Condo':['1004','1025','2055'],
    'Detached Condo':['1004','1025','2055'],
    'Manufactured Housing':['1073','1075'],
    'SFR - Detached':['1073','1025','1075'],
    'SFR - Attached':['1073','1025','1075'],
    'PUD - Attached':['1073','1075'],
    'PUD - Detached':['1073','1075'],
  },
  standAlone:[
    'Rent Survey (1007)',
    'Operating Income Statement (216)',
    'Appraisal Update / Re-Cert (1004D)',
    'Appraisal Final Inspection (1004D / HUD 92051)',
    'Appraisal Disaster Inspection In & Out (1004D)',
    'Exterior (2075)',
    'Enhanced Desk Review',
    'Field Review'
  ],

  newClientEntityLabel:'AE',
  newClientEntityName:'',
  newClientEntityEmail:'',
  newClientEntityPhone:'',
  newClientEntityWorkPhone:'',
  newClientEntityMobilePhone:'',
  newClientEntityDropDownOpen:false,

  batchNewEntitySearchKeyword:'',
  batchNewEntitySearchClient:'',
  batchNewEntitySearchResults:[],

  batchNewEntity1Label:'AE',
  batchNewEntity1Name:'',
  batchNewEntity1Email:'',
  batchNewEntity1Phone:'',

  batchNewEntity2Label:'Broker',
  batchNewEntity2Name:'',
  batchNewEntity2Email:'',
  batchNewEntity2Phone:'',

  batchNewEntity3Label:'Loan Officer',
  batchNewEntity3Name:'',
  batchNewEntity3Email:'',
  batchNewEntity3Phone:'',

  batchNewEntity4Label:'Loan Processor',
  batchNewEntity4Name:'',
  batchNewEntity4Email:'',
  batchNewEntity4Phone:'',

  batchNewEntity5Label:'CSR',
  batchNewEntity5Name:'',
  batchNewEntity5Email:'',
  batchNewEntity5Phone:'',

  batchNewEntity6Label:'Broker Company',
  batchNewEntity6Name:'',
  batchNewEntity6Email:'',
  batchNewEntity6Phone:'',
};

//reducer function that perform state update
const reducer = getReducer();


const AppraisalDetails  = (props)=>{
  const controller = new AbortController();
  const [state, dispatch] = useReducer(reducer,initialState);
  const newsid = useRef(generateSID);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getAllClientProfiles();
    getClientBranches(props.appraisal.client_fk);
    getOpenByClientBranches(props.appraisal.open_by_client);
    getAppraisalTypes();
    getPriorities();
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    getClientBranches(props.appraisal.client_fk);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.appraisal.client_fk]);

  useEffect(()=>{
    getOpenByClientBranches(props.appraisal.open_by_client);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.appraisal.open_by_client]);

  useEffect(()=>{
    getAvailableAppraisalType(state.availableAppraisalTypes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state.availableAppraisalTypes]);
  
  let successMessage = 'Order information updated.';
  let failedMessage = 'Failed to update the value of this field, please try again later.';
  //non API call but simpyl manage state
  const selectEntity = (data)=>{
    for(let i=1;i<=data.length;i++){
      setState({['batchNewEntity'+i+'Label']:data[i-1].label,['batchNewEntity'+i+'Name']:data[i-1].name,['batchNewEntity'+i+'Email']:data[i-1].email,['batchNewEntity'+i+'Phone']:data[i-1].phone});
    }
  }

  const newClientEntityToggle = ()=>{
    setState({
      newClientEntityDropDownOpen: !state.newClientEntityDropDownOpen
    });
  }

  const batchEntityToggle = ()=>{
    setState({newClientEntityDropDownOpen:false,batchImportDropDownOpen:!state.batchImportDropDownOpen});
  }

  //generate a function that do the update on editable
  const generateUpdateFunction = (url, parameters, successMessage, failedMessage, parentCallBack=null)=>{
    
    return (newValue, callBack, errorCallBack)=>{
      //add in the new value into parameters
      parameters.push({field:'value',value:newValue});
      let promise = httpPut(url,parameters,successMessage, failedMessage, callBack,errorCallBack);
      promise.then(
        (response)=>{
          let field;
          let value;
          let ID;

          for(let i=0;i<parameters.length;i++){
            if(parameters[i].field==='field')
              field = parameters[i].value;
            else if(parameters[i].field==='value')
              value = parameters[i].value;
            else if(parameters[i].field==='ID')
              ID = parameters[i].value;
          }
          if(parentCallBack!==null){
            parentCallBack(field, value, ID);
          }
        }
      );
    }
  };

  const updatePropertyType = (propertyType, availableAppraisalTypes)=>{
    let filteredAppraisalTypes = [];

    for(let i=0;i<availableAppraisalTypes.length;i++){
      if(propertyTypeCheck(availableAppraisalTypes[i].appraisal_type,propertyType)===true){
        filteredAppraisalTypes.push(availableAppraisalTypes[i]);
      }
    }

    let appraisalTypeOptions = [];

    if(filteredAppraisalTypes.length>0){
      for(let i=0;i<filteredAppraisalTypes.length;i++){
        let tmp = {
          key: filteredAppraisalTypes[i].appraisal_type,
          value: filteredAppraisalTypes[i].appraisal_type
        }

        appraisalTypeOptions.push(tmp);
      }
    }

    setState({appraisalTypeOptions: appraisalTypeOptions});
  }

  const propertyTypeCheck = (appraisalType,propertyType)=>{
    let excludeTypes = state.appraisalTypeFilters[propertyType];
    for(let j=0;excludeTypes&&j<excludeTypes.length;j++){

      if(appraisalType.includes(excludeTypes[j])){
        if(appraisalType.includes('1004D'))
          return true;
        else
          return false;
      }
    }
    return true;
  }

  const standAlone = (appraisalType)=>{
    let appraisalTypes = state.standAlone.slice();

    for(let i=0;i<appraisalTypes.length;i++){
      if(appraisalTypes[i].includes(appraisalType))
        return true;
    }

    return false;
  }

  const getAvailableAppraisalType = (availableAppraisalTypes)=>{
    updatePropertyType(props.appraisal.property_type, availableAppraisalTypes);
    updateLoanType(props.appraisal.loan_type, availableAppraisalTypes);
  }

  const updateLoanType = (loanType, availableAppraisalTypes)=>{
    let filteredAppraisalTypes = [];

    let propertyType = props.appraisal.property_type;

    if(loanType==='FHA'){
      for(let i=0;i<availableAppraisalTypes.length;i++){
        if(propertyTypeCheck(availableAppraisalTypes[i].appraisal_type, propertyType)===false)
          continue;
        if(availableAppraisalTypes[i].appraisal_type.includes('FHA'))
          filteredAppraisalTypes.push(availableAppraisalTypes[i]);
      }
    }
    else if(loanType==='USDA'){
      for(let i=0;i<availableAppraisalTypes.length;i++){
        if(propertyTypeCheck(availableAppraisalTypes[i].appraisal_type, propertyType)===false)
          continue;
        if(availableAppraisalTypes[i].appraisal_type.includes('USDA'))
          filteredAppraisalTypes.push(availableAppraisalTypes[i]);
      }
    }
    else if(loanType==='Jumbo <$1m'){
      for(let i=0;i<availableAppraisalTypes.length;i++){
        if(propertyTypeCheck(availableAppraisalTypes[i].appraisal_type, propertyType)===false)
          continue;
        if(availableAppraisalTypes[i].appraisal_type.includes('<$1m')||standAlone(availableAppraisalTypes[i].appraisal_type))
          filteredAppraisalTypes.push(availableAppraisalTypes[i]);
      }
    }
    else if(loanType==='Jumbo $1m - $2m'){
      for(let i=0;i<availableAppraisalTypes.length;i++){
        if(propertyTypeCheck(availableAppraisalTypes[i].appraisal_type, propertyType)===false)
          continue;
        if(availableAppraisalTypes[i].appraisal_type.includes('$1m - $2m')||standAlone(availableAppraisalTypes[i].appraisal_type))
          filteredAppraisalTypes.push(availableAppraisalTypes[i]);
      }
    }
    else if(loanType==='Jumbo >$2m'){
      for(let i=0;i<availableAppraisalTypes.length;i++){
        if(propertyTypeCheck(availableAppraisalTypes[i].appraisal_type, state.property_type)===false)
          continue;
        if(availableAppraisalTypes[i].appraisal_type.includes('>$2m')||standAlone(availableAppraisalTypes[i].appraisal_type))
          filteredAppraisalTypes.push(availableAppraisalTypes[i]);
      }
    }
    else if(loanType==='Conventional'||loanType==='High Balanced'||loanType==='Private'){
      for(let i=0;i<availableAppraisalTypes.length;i++){
        if(propertyTypeCheck(availableAppraisalTypes[i].appraisal_type, propertyType)===false)
          continue;
        if(!availableAppraisalTypes[i].appraisal_type.includes('FHA')&&!availableAppraisalTypes[i].appraisal_type.includes('USDA')&&!availableAppraisalTypes[i].appraisal_type.includes('<$1m')&&!availableAppraisalTypes[i].appraisal_type.includes('$1m - $2m')&&!availableAppraisalTypes[i].appraisal_type.includes('>$2m'))
          filteredAppraisalTypes.push(availableAppraisalTypes[i]);
      }
    }
    else if(loanType==='Other'){
      let excludes = [
        '1004',
        '1073',
        '1025',
        'FHA',
        'USDA',
        'Jumbo'
      ];

      for(let i=0;i<availableAppraisalTypes.length;i++){
        if(propertyTypeCheck(availableAppraisalTypes[i].appraisal_type, propertyType)===false)
          continue;

        let exclude = false;
        for(let j=0;j<excludes.length;j++){
          if(availableAppraisalTypes[i].appraisal_type.includes(excludes[j])){
            //futher check if it's 1004D
            if(availableAppraisalTypes[i].appraisal_type.includes('1004D')){
              if(availableAppraisalTypes[i].appraisal_type.includes('FHA')||availableAppraisalTypes[i].appraisal_type.includes('USDA')){
                exclude = true;
                break;
              }
            }
            else{
              exclude = true;
              break;
            }
          }
        }

        if(exclude===false)
          filteredAppraisalTypes.push(availableAppraisalTypes[i]);
      }
    }
    else{
      filteredAppraisalTypes = availableAppraisalTypes.slice();
    }


    let appraisalTypeOptions = [];

    if(filteredAppraisalTypes.length>0){
      for(let i=0;i<filteredAppraisalTypes.length;i++){
        let tmp = {
          key: filteredAppraisalTypes[i].appraisal_type,
          value: filteredAppraisalTypes[i].appraisal_type
        }

        appraisalTypeOptions.push(tmp);
      }
    }

    if(loanType!=='')
      setState({appraisalTypeOptions: appraisalTypeOptions});
    else
      updatePropertyType(propertyType, availableAppraisalTypes);
  }

  const overrideUpdateAppraisalType = (url, parameters, callBack, errorCallBack, successMessage, failedMessage)=>{
    let tmp = {
      field:'override',
      value:'yes'
    };
    parameters.push(tmp);
    httpPut(url,parameters,successMessage, failedMessage,callBack,errorCallBack);
  }

  //generate a function that do the update on editable
  const generateUpdateFunctionWithCustomCallBack = (url, parameters, successMessage, failedMessage)=>{
    return (newValue, callBack, errorCallBack)=>{
      //add in the new value into parameters

      let newCallBack = (response)=>{
        let code = response.data?response.data.code:undefined;
        if(code!=='00'){

          if(code==='02'&&(state.roles.indexOf('admin_manager')!==-1||state.roles.indexOf('super_admin')!==-1||state.roles.indexOf('admin')!==-1)){
            confirmation(
              ()=>{overrideUpdateAppraisalType(url, parameters, callBack, errorCallBack, successMessage, failedMessage)},
              ()=>{},
              'Override and update report type?',
              'Update report type failed due to received payment, do you want to override it and change the report type? If you choose yes the system won\'t be making billing changes and it is up to you to adjust it.'
            );
          }
        }
      };

      parameters.push({field:'value',value:newValue});
      httpPut(url,parameters,successMessage,failedMessage,newCallBack,errorCallBack);
    }
  };

  const deleteClientEntity = (ID)=>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let appraisal = Object.assign({},props.appraisal,{entities:sliceFromArray(props.appraisal.entities,'ID',ID)});
        props.updateAppraisal(appraisal);
      }
    };

  
    httpDelete('appraisal/client/entity/'+ID,'Client entity deleted.','Oops, something went wrong and could not delete the client entity. Please try again later.', callBack);
  }

  const generateUpdateFunctionWithCustomCallBackForDueDate = (url, parameters, successMessage, failedMessage)=>{
    return (newValue, callBack, errorCallBack)=>{
      //add in the new value into parameters

      let newCallBack = (response)=>{
        let code = response.data?response.data.code:undefined;
        if(code==='00'){
          let newDueDate = response.data.data;
          let appraisal = Object.assign({}, props.appraisal, {datetime_due:newDueDate});
          props.updateAppraisal(appraisal);
        }
      };
      callBack = callBack.bind(this);

      parameters.push({field:'value',value:newValue});
      httpPut(url,parameters,successMessage,failedMessage,newCallBack,errorCallBack);
    }
  };

  const handleAsyncSelectChange = (key, selectedOption) => {
    let clientContactSearchResult = state.clientContactSearchResult.slice();

    for(let i=0;i<clientContactSearchResult.length;i++){
      if(clientContactSearchResult[i].name+' - '+clientContactSearchResult[i].email===selectedOption){
        setState({[key+'Name']:clientContactSearchResult[i].name,[key+'Email']:clientContactSearchResult[i].email,[key+'Phone']:clientContactSearchResult[i].phone});
        break;
      }
    }
  }

  const updateFieldCallBack = (field, value, ID)=>{
    if(field === 'priority'){
      props.updateAppraisal(Object.assign({}, props.appraisal, {priority:value}));
    }else if(field === 'property_type'){
      updatePropertyType(value, state.availableAppraisalTypes);
    }else if(field === 'loan_type'){
      updateLoanType(value, state.availableAppraisalTypes);
    }

    let appraisal = Object.assign({}, props.appraisal);

    if(field.includes("entity")){
      for(let i=0;i<appraisal.entities.length;i++){
        if(ID===appraisal.entities[i].ID){
          appraisal.entities[i][field] = value;
          props.updateAppraisal(appraisal);
          break;
        }
      }
    }else{
      appraisal[field] = value;
      props.updateAppraisal(appraisal);
    }
  }

  //API call
  const getClientBranches = (id)=>{
    let callBack = apiCallBack([{state:'clientBranches',key:'data'}]);
    httpGet('client/branch/get/'+id,'','Oops, something went wrong and could not retrieve client entities list.', callBack);
  }

  const getOpenByClientBranches = (id)=>{
    let callBack = apiCallBack([{state:'openByClientBranches',key:'data'}]);
    httpGet('client/branch/get/'+id,'','Oops, something went wrong and could not retrieve client entities list.', callBack);
  }

  const createNewBatchClientEntity = ()=>{
    let checkPassed = true;

    if((state.batchNewEntityEmail1!==''||state.batchNewEntityPhone1!=='')&&state.batchNewEntityName1==='')
      checkPassed = false;
    if((state.batchNewEntityEmail2!==''||state.batchNewEntityPhone2!=='')&&state.batchNewEntityName2==='')
      checkPassed = false;
    if((state.batchNewEntityEmail3!==''||state.batchNewEntityPhone3!=='')&&state.batchNewEntityName3==='')
      checkPassed = false;
    if((state.batchNewEntityEmail4!==''||state.batchNewEntityPhone4!=='')&&state.batchNewEntityName4==='')
      checkPassed = false;

    if(checkPassed){
      let parameters = [
        {
          field:'ID',
          value:props.appraisal.ID
        },
        {
          field:'label1',
          value:state.batchNewEntityLabel1
        },
        {
          field:'name1',
          value:state.batchNewEntityName1
        },
        {
          field:'email1',
          value:state.batchNewEntityEmail1
        },
        {
          field:'phone1',
          value:state.batchNewEntityPhone1
        },
        {
          field:'label2',
          value:state.batchNewEntityLabel2
        },
        {
          field:'name2',
          value:state.batchNewEntityName2
        },
        {
          field:'email2',
          value:state.batchNewEntityEmail2
        },
        {
          field:'phone2',
          value:state.batchNewEntityPhone2
        },
        {
          field:'label3',
          value:state.batchNewEntityLabel3
        },
        {
          field:'name3',
          value:state.batchNewEntityName3
        },
        {
          field:'email3',
          value:state.batchNewEntityEmail3
        },
        {
          field:'phone3',
          value:state.batchNewEntityPhone3
        },
        {
          field:'label4',
          value:state.batchNewEntityLabel4
        },
        {
          field:'name4',
          value:state.batchNewEntityName4
        },
        {
          field:'email4',
          value:state.batchNewEntityEmail4
        },
        {
          field:'phone4',
          value:state.batchNewEntityPhone4
        },
        {
          field:'label5',
          value:state.batchNewEntityLabel5
        },
        {
          field:'name5',
          value:state.batchNewEntityName5
        },
        {
          field:'email5',
          value:state.batchNewEntityEmail5
        },
        {
          field:'phone5',
          value:state.batchNewEntityPhone5
        },
        {
          field:'label6',
          value:state.batchNewEntityLabel6
        },
        {
          field:'name6',
          value:state.batchNewEntityName6
        },
        {
          field:'email6',
          value:state.batchNewEntityEmail6
        },
        {
          field:'phone6',
          value:state.batchNewEntityPhone6
        }
      ];

      let callBack = (response)=>{
        let code = response.data?response.data.code:undefined;

        if(code==='00'){
          let appraisal = Object.assign({}, props.appraisal);
          let entities = appraisal.entities.slice();
          entities.push(response.data.data);

          appraisal.entities = entities;

          props.updateAppraisal(appraisal);
          batchEntityToggle();
        }
      }

      httpPost('appraisal/client/entity/batch/create', parameters, 'Client entity created successfully.', 'Oops, something went wrong and could not create the client entity. Please try again later.', callBack);
    }
    else
      showMessage('error','Please fill in entity name.');
  }

  const createNewClientEntity = ()=>{
    if(state.newClientEntityName!==''){
      let parameters = [
        {
          field:'ID',
          value:props.appraisal.ID
        },
        {
          field:'label',
          value:state.newClientEntityLabel
        },
        {
          field:'name',
          value:state.newClientEntityName
        },
        {
          field:'email',
          value:state.newClientEntityEmail
        },
        {
          field:'phone',
          value:state.newClientEntityPhone
        },
        {
          field:'workPhone',
          value:state.newClientEntityWorkPhone
        },
        {
          field:'mobilePhone',
          value:state.newClientEntityMobilePhone
        }
      ];
  
      let callBack = (response)=>{
        props.getAppraisal(props.appraisal.ID);
        newClientEntityToggle();
      }
  
      httpPost('appraisal/client/entity/create',parameters,'Client entity created successfully.','Oops, something went wrong and could not create the client entity. Please try again later.',callBack);
    }
  }

  const getAppraisalTypes = ()=>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;

      if(code==='00'){
        let appraisalTypes = [];
        let subAppraisalTypes = [];
        for(let i=0;i<response.data.data.length;i++){
          let tmp = {
            key: response.data.data[i].appraisal_type,
            value: response.data.data[i].appraisal_type
          }

          if(response.data.data[i].sub_form==='yes')
            subAppraisalTypes.push(tmp);
          appraisalTypes.push(tmp);
        }

        setState({appraisalTypes:appraisalTypes,subAppraisalTypes:subAppraisalTypes,availableAppraisalTypes:response.data.data});
      }
    }
    httpGet('appraisalType/get','', 'Oops, something went wrong and could not load list of available report types. Please try again later.', callBack);
  }

  const batchNewEntitySearch = ()=>{
    if(state.batchNewEntitySearchKeyword!==''){
      let type = 'retail';

      if(state.appraisal.order_type==='Wholesale')
        type = 'wholesale';
      let callBack = ([{state:'batchNewEntitySearchResults', key:'data'}]);
      httpGet('client/contact/'+type+'/keyword='+state.batchNewEntitySearchKeyword+'&client_fk='+state.batchNewEntitySearchClient,'','Oops, something went wrong and could not search for client entity. Please try again later.', callBack);
    }
  }

  const getClientContacts = (keyword, label)=>{

    if(keyword&&keyword!==''){
      let SID = generateSID();
      newsid.current = SID;

      let type = 'retail';

      if(props.appraisal.order_type==='Wholesale')
        type = 'wholesale';

      let encodedKeyword = encodeURIComponent(keyword.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
      let callBack = (response)=>{
        if(SID===newsid.current){
          let code = response.data?response.data.code:undefined;

          if(code!=='00'){
            return [{}];
          }
          else{
            let options = [];

            let results = [];

          for(let i=0;i<response.data.data.length;i++){
            for(let j=0;j<response.data.data[i].contacts.length;j++){
              if(response.data.data[i].contacts[j].label===label&&response.data.data[i].contacts[j].name.toLowerCase().indexOf(keyword.toLowerCase())!==-1){
                results.push(response.data.data[i].contacts[j]);
              }
            }
          }

          setState({clientContactSearchResult:results});

            for(let i=0;i<results.length;i++){
              let tmp = {};
              tmp.label = results[i].name +' - '+results[i].email;
              tmp.value = results[i].name +' - '+results[i].email;

              options.push(tmp);
            }
            return options;
          }
        }
      };
      callBack = callBack.bind(this);

      let promise = httpGet('client/contact/'+type+'/keyword='+encodedKeyword+'&client_fk='+props.appraisal.open_by_client+'&client_branch_fk='+props.appraisal.open_by_client_branch_fk,'','Oops, something went wrong and could not search for client entity. Please try again later.',callBack);

      return promise;
    }
  }

  const getAllClientProfiles = ()=>{
    let callBack = apiCallBack([{state:'clients', key:'data'}]);
    httpGet('client/get/limit=-1&offset=-1', '', 'Oops, something went wrong and could not retrieve client profiles.', callBack);
  }

  const getPriorities = ()=>{
    let callBack = apiCallBack([{state:'priorities', key:'data'}]);
    httpGet('priority/get', '', 'Oops, something went wrong and could not retrieve list of priorities.', callBack);
  }

  let pdr;

  if(props.appraisal.pdr_fk){
    pdr = <div>
      <Alert color="warning" className="my-alert">
        This is a PDR order, click <NavLink target="_blank" to={"/property-data-report/"+props.appraisal.pdr_fk}>here</NavLink> to access the PDR form helper.
      </Alert>
    </div>;
  }

  let clientDropDown, clientDropDown2;
  let clientBranchDropDown, openByClientBranchDropDown;

  if(state.clients.length>0){
    clientDropDown = <MyXEditable
      type="select"
      value={props.appraisal.client_fk}
      opt={state.clients.map((client)=>{return {key:client.ID, value:client.company}})}
      updateFunc={
        generateUpdateFunction('appraisal/update',[{field:'field',value:'client_fk'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, (field, value)=>{props.getAppraisal(props.appraisal.ID)})
      }
    />;



    clientDropDown2 = <MyXEditable
    type="select"
    value={props.appraisal.open_by_client}
    opt={state.clients.map((client)=>{return {key:client.ID, value:client.company}})}
    updateFunc={
      generateUpdateFunction('appraisal/update',[{field:'field',value:'open_by_client'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, (field, value)=>{props.getAppraisal(props.appraisal.ID)})
    }
  />;
  }

  if(state.clientBranches.length>0){
    clientBranchDropDown = <MyXEditable
    type="select"
    value={props.appraisal.client_branch_fk}
    opt={state.clientBranches.map((client)=>{return {key:client.ID, value:client.branch_name}})}
    updateFunc={
      generateUpdateFunction('appraisal/update',[{field:'field',value:'client_branch_fk'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, (field, value)=>{props.getAppraisal(props.appraisal.ID)})
    }
  />;
  }

  if(state.openByClientBranches.length>0){
    openByClientBranchDropDown = <MyXEditable
      type="select"
      value={props.appraisal.open_by_client_branch_fk}
      opt={state.openByClientBranches.map((client)=>{return {key:client.ID, value:client.branch_name}})}
      updateFunc={
        generateUpdateFunction('appraisal/update',[{field:'field',value:'open_by_client_branch_fk'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, (field, value)=>{props.getAppraisal(props.appraisal.ID)})
      }
    />;
  }

  let fhaCaseNumber;
  let purchasePrice;

  if(props.appraisal.loan_type==='FHA')
  fhaCaseNumber = <Row>
    <Col sm="4">
      FHA Case Number
    </Col>
    <Col sm="8">
      <MyXEditable
        type="text"
        value={props.appraisal.fha_case_number}
        updateFunc={
          generateUpdateFunction('appraisal/update',[{field:'field',value:'fha_case_number'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
        }
      />
    </Col>
  </Row>;

  if(props.appraisal.loan_purpose==='Purchase')
    purchasePrice = <Row>
      <Col sm="4">
        Purchase Price
      </Col>
      <Col sm="8">
        <MyXEditable
          type="text"
          value={props.appraisal.selling_price}
          updateFunc={
            generateUpdateFunction('appraisal/update',[{field:'field',value:'selling_price'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
          }
        />
      </Col>
    </Row>;

  let workPhone;
  let mobilePhone;

  if(state.newClientEntityLabel==='Co-Borrower'||state.newClientEntityLabel==='Borrower'){
    workPhone = <div className="form-group">
      <span><label>Work Phone:</label></span>
      <Input type="text" className="form-control" value={state.newClientEntityWorkPhone} id="newClientEntityWorkPhone" placeholder="E.g xxx-xxxxxxx" onChange={(e)=>{setState({newClientEntityWorkPhone:e.target.value})}}></Input>
    </div>;

    mobilePhone = <div className="form-group">
      <span><label>Mobile Phone:</label></span>
      <Input type="text" className="form-control" value={state.newClientEntityMobilePhone} id="newClientEntityMobilePhone" placeholder="E.g xxx-xxxxxxx" onChange={(e)=>{setState({newClientEntityMobilePhone:e.target.value})}}></Input>
    </div>;
  }


  let entityNameWidget = <MySelect
    modal={true}
    type="async-select"
    value={state.newClientEntityName}
    onChange={(e)=>handleAsyncSelectChange('newClientEntity',e)}
    loadOptions={(keyword)=>getClientContacts(keyword, state.newClientEntityLabel, true)}
  />;
  if(state.newClientEntityLabel==='Borrower'||state.newClientEntityLabel==='Co-Borrower'||state.newClientEntityLabel==='Other'||state.newClientEntityLabel==='Payment Contact'||state.newClientEntityLabel==='Email Notification Subscriber'){
    entityNameWidget = <Input type="text" value={state.newClientEntityName} onChange={(e)=>setState({newClientEntityName:e.target.value})}/>
  }

  let textSubscription = <i className="fa fa-close red-color"></i>;

  if(!props.appraisal.appraiser_sms_subscription)
    textSubscription = null;
  else if(props.appraisal.appraiser_sms_subscription==='yes')
    textSubscription = <i className="fa fa-check green-color"></i>;


  let dueDateHistory;
  let expDateHistory;

  if(props.appraisal.datetime_due_history&&props.appraisal.datetime_due_history.length>0){
    dueDateHistory = <Row>
      <Col sm="6">
        Appraiser Due Date history:
      </Col>
      <Col sm="6">
        {
          props.appraisal.datetime_due_history.map(
            (history, index)=>{

              return(
                <div>
                  <strike>
                    {formatDateTime(history)}
                  </strike>
                </div>
              );
            }
          )
        }
      </Col>
    </Row>;
  }

  if(props.appraisal.datetime_expected_history&&props.appraisal.datetime_expected_history.length>0){
    expDateHistory = <Row>
      <Col sm="6">
        Client Due Date history:
      </Col>
      <Col sm="6">
        {
          props.appraisal.datetime_expected_history.map(
            (history, index)=>{

              return(
                <div>
                  <strike>
                    {formatDateTime(history)}
                  </strike>
                </div>
              );
            }
          )
        }
      </Col>
    </Row>;
  }

  let states;

  if(state.states.length>0)
    states =
      <MyXEditable
      type="select"
      value={props.appraisal.property_state}
      opt={state.states}
      updateFunc={
        generateUpdateFunction('appraisal/update',[{field:'field',value:'property_state'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
      }
    />;
  else
    states = null;

  let appraisalType;
  if(state.appraisalTypes.length>0 && state.appraisalTypeOptions.length>0){
    appraisalType =
      <MyXEditable
      type="select"
      value={props.appraisal.appraisal_type}
      opt={state.appraisalTypeOptions}
      updateFunc={
        generateUpdateFunctionWithCustomCallBack('appraisalType/update',[{field:'appraisal_fk',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
      }
    />;
  }
  else
    appraisalType = null;

  let loanTypes;

  if(state.loanTypes.length>0)
    loanTypes =
      <MyXEditable
        type="select"
        value={props.appraisal.loan_type}
        opt={state.loanTypes}
        updateFunc={
          generateUpdateFunction('appraisal/update',[{field:'field',value:'loan_type'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
        }
      />;
  else
    loanTypes = null;

  let loanPurposes;

  if(state.loanPurposes.length>0)
    loanPurposes =
      <MyXEditable
        type="select"
        value={props.appraisal.loan_purpose}
        opt={state.loanPurposes}
        updateFunc={
          generateUpdateFunction('appraisal/update',[{field:'field',value:'loan_purpose'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
        }
      />;
  else
    loanPurposes = null;

  let propertyTypes;

  if(state.propertyTypes.length>0)
    propertyTypes =
      <MyXEditable
        type="select"
        value={props.appraisal.property_type}
        opt={state.propertyTypes}
        updateFunc={
          generateUpdateFunction('appraisal/update',[{field:'field',value:'property_type'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
        }
      />;
  else
    propertyTypes = null;

  //loop to render client entities that ties to the appraisal
  let clientEntities;
  if(props.appraisal.entities&&props.appraisal.entities.length>0)
    clientEntities= props.appraisal.entities.map(
      (entity)=>{
        let workPhone;
        let mobilePhone;

        if(entity.entity_work_phone!==''){
          workPhone = <Row>
            <Col sm="4">
              {entity.entity_label} Work Phone
            </Col>
            <Col sm="8">
              <MyXEditable
                type="text"
                value={entity.entity_work_phone}
                updateFunc={
                  generateUpdateFunction('appraisal/client/entity/update',[{field:'field',value:'entity_work_phone'},{field:'ID',value:entity.ID}],successMessage, failedMessage, updateFieldCallBack)
                }
              />
            </Col>
          </Row>;
        }

        if(entity.entity_mobile_phone!==''){
          mobilePhone = <Row>
            <Col sm="4">
              {entity.entity_label} Mobile Phone
            </Col>
            <Col sm="8">
              <MyXEditable
                type="text"
                value={entity.entity_mobile_phone}
                updateFunc={
                  generateUpdateFunction('appraisal/client/entity/update',[{field:'field',value:'entity_mobile_phone'},{field:'ID',value:entity.ID}],successMessage, failedMessage, updateFieldCallBack)
                }
              />
            </Col>
          </Row>;
        }
        if(entity.entity_label!=='Appraiser')
          return(
            <div key={entity.ID}>
              <Row>
                <Col sm="4">
                  <i className="fa fa-trash red-color cursor-pointer" onClick={
                    ()=>{
                      confirmation(
                        ()=>{deleteClientEntity(entity.ID)},
                        ()=>{},
                        'Delete client entity?',
                        'Are you sure you want to delete this client entity?');
                    }
                  }></i>&nbsp;{entity.entity_label}
                </Col>
                <Col sm="8">
                  <div className="display-inline" style={{verticalAlign:'top'}}>
                    <Avatar size={23} style={{overflow:'hidden'}} textSizeRatio={1.5} color={"#"+stringToHexCode(entity.entity_email)} name={entity.entity_name}/>&nbsp;
                  </div>
                  <div className="display-inline">
                    <MyXEditable
                      type="text"
                      value={entity.entity_name}
                      updateFunc={
                        generateUpdateFunction('appraisal/client/entity/update',[{field:'field',value:'entity_name'},{field:'ID',value:entity.ID}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  {entity.entity_label} Email
                </Col>
                <Col sm="8">
                  <MyXEditable
                    type="text"
                    value={entity.entity_email}
                    updateFunc={
                      generateUpdateFunction('appraisal/client/entity/update',[{field:'field',value:'entity_email'},{field:'ID',value:entity.ID}],successMessage, failedMessage, updateFieldCallBack)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  {entity.entity_label} Phone
                </Col>
                <Col sm="8">
                  <MyXEditable
                    type="text"
                    value={entity.entity_phone}
                    updateFunc={
                      generateUpdateFunction('appraisal/client/entity/update',[{field:'field',value:'entity_phone'},{field:'ID',value:entity.ID}],successMessage, failedMessage, updateFieldCallBack)
                    }
                  />
                </Col>
              </Row>
              {workPhone}
              {mobilePhone}
              <br/>
            </div>
          );
        else
          return null;
      }
    );
  else
    clientEntities = null;

  let pdrSellerID, pdrLPAKey, pdrID;

  if(props.appraisal.appraisal_type && props.appraisal.appraisal_type==='ACE+ PDR'){
    pdrSellerID =
    <Row>
      <Col sm="4"><div className="display-inline">&nbsp;Seller ID</div></Col>
      <Col sm="8">
        <MyXEditable
          type="text"
          value={props.appraisal.pdr_seller_id}
          updateFunc={
            generateUpdateFunction('appraisal/update',[{field:'field',value:'pdr_seller_id'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
          }
        />
      </Col>
    </Row>
    pdrID =
    <Row>
      <Col sm="4"><div className="display-inline">&nbsp;PDR File Identifier (For PCR)</div></Col>
      <Col sm="8">
        <MyXEditable
          type="text"
          value={props.appraisal.pdr_id}
          updateFunc={
            generateUpdateFunction('appraisal/update',[{field:'field',value:'pdr_id'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
          }
        />
      </Col>
    </Row>
    pdrLPAKey = <Row>
      <Col sm="4"><div className="display-inline">&nbsp;LPA Key</div></Col>
      <Col sm="8">
        <MyXEditable
          type="text"
          value={props.appraisal.pdr_lpa_key}
          updateFunc={
            generateUpdateFunction('appraisal/update',[{field:'field',value:'pdr_lpa_key'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
          }
        />
      </Col>
    </Row>;
  }

  let clientNote;

  if(props.appraisal.client_general_note && props.appraisal.client_general_note!==''){
    clientNote = <div className="display-inline">
      <i className="fa fa-sticky-note cursor-pointer link-color" id="clientGeneralNote"/>
      <UncontrolledTooltip className="mytooltip" delay={{ "show": 0, "hide": 0 }} placement="right" target={"clientGeneralNote"}>
        {props.appraisal.client_general_note}
      </UncontrolledTooltip>
    </div>
  }

  let appraisalFileUI;
  if(props.appraisal&&props.appraisal.appraisal_type){
    appraisalFileUI = <div>
        <FileStorage {...props} appraisalFk={props.appraisal.ID}/>
    </div>
  }
  else { 
    appraisalFileUI  =
      <div>
        <div className="file-panel"></div>
        <div className="my-diviver"></div>
        <div className="align-right">
          <br/>
          <Button color="warning" className="cursor-pointer"><i className="fa fa-plus"></i> Upload File</Button>
        </div>
      </div>;
  }

  //render
  return <div>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-home"></i>&nbsp;{props.appraisal.reference_num} - {props.appraisal.property_street} {props.appraisal.property_city}, {props.appraisal.property_state} {props.appraisal.property_zip} - {props.appraisal.borrower_f_name} {props.appraisal.borrower_l_name}
      </CardHeader>
      <CardBody className="gray-background">
        <AppaisalActions appraisal={props.appraisal} {...props}/>
        {props.appraisal.potential_complex && props.appraisal.potential_complex==='yes'?<Alert color="danger" className="my-alert"><i className="fa fa-exclamation-circle orange-color"/> Potential Complex</Alert>:null}
        {pdr}
        {
          props.messages.map(
            (message, index)=>{
              if(message.link){
                return(<div className="display-inline" key={index}><a href={'/'+message.link+'/'+message.ID} target="_blank" rel="noreferrer"><Alert key={index} color={message.pop_up==='true'?"danger":"warning"} className={message.pop_up==='true'?"flashit my-alert":"my-alert"}>
                    <i className="fa fa-circle-info"/>&nbsp;{message.msg}
                  </Alert></a></div>);
              }

              return(<div className="display-inline" key={index}><Alert key={index} color={message.pop_up==='true'?"danger ":"warning"} className={message.pop_up==='true'?"flashit my-alert":"my-alert"}>
                  <i className="fa fa-circle-info"/>&nbsp;{message.msg}
                </Alert></div>);
            }
          )
        }

        <br/>
        <div className="my-divider"></div>
        <Row>
          <Col sm="6">
            <div className="my-well">
              <Row><Col sm="12"><b><i className="fa fa-user-circle"></i>&nbsp;Account Manager</b></Col></Row>
              <Row>
                <Col sm="4">
                  Name
                </Col>
                <Col sm="8">
                  {props.appraisal.account_manager}
                </Col>
              </Row>

              <br/>
              <Row><Col sm="12"><b><i className="fa fa-user"></i>&nbsp;Appraiser</b> <AppraiserPipeline {...props} appraisal={props.appraisal}/></Col></Row>

              <Row>
                <Col sm="4">
                  Name
                </Col>
                <Col sm="8">
                  <NavLink to={"/appraiser/"+props.appraisal.appraiser_fk}>{props.appraisal.appraiser}</NavLink>
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  License Type
                </Col>
                <Col sm="8">
                  {props.appraisal.appraiser_license_type}
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Email
                </Col>
                <Col sm="8">
                  {props.appraisal.appraiser_email}
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Office Phone
                </Col>
                <Col sm="8">
                  <a href={"tel:"+props.appraisal.appraiser_phone}>{props.appraisal.appraiser_phone}</a>
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Cell Phone
                </Col>
                <Col sm="8">
                  <a href={"tel:"+props.appraisal.appraiser_secondary_phone}>{props.appraisal.appraiser_secondary_phone}</a>
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Preffered Communication
                </Col>
                <Col sm="8">
                  {props.appraisal.appraiser_preferred_communication}
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Text
                </Col>
                <Col sm="8">
                  {textSubscription} {props.appraisal.appraiser_sms_subscription}
                </Col>
              </Row>

              <br/>
              <Row><Col sm="12"><b><i className="fa fa-user-circle"></i>&nbsp;Borrower</b></Col></Row>

              <Row>
                <Col sm="4">
                  First Name
                </Col>
                <Col sm="8">
                  <MyXEditable
                    type="text"
                    value={props.appraisal.borrower_f_name}
                    updateFunc={
                      generateUpdateFunction('appraisal/update',[{field:'field',value:'borrower_f_name'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Last Name
                </Col>
                <Col sm="8">
                  <MyXEditable
                    type="text"
                    value={props.appraisal.borrower_l_name}
                    updateFunc={
                      generateUpdateFunction('appraisal/update',[{field:'field',value:'borrower_l_name'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Home Phone
                </Col>
                <Col sm="8">
                  <MyXEditable
                    type="text"
                    value={props.appraisal.borrower_phone}
                    updateFunc={
                      generateUpdateFunction('appraisal/update',[{field:'field',value:'borrower_phone'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Work Phone
                </Col>
                <Col sm="8">
                  <MyXEditable
                    type="text"
                    value={props.appraisal.borrower_work_phone}
                    updateFunc={
                      generateUpdateFunction('appraisal/update',[{field:'field',value:'borrower_work_phone'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Mobile Phone
                </Col>
                <Col sm="8">
                  <MyXEditable
                    type="text"
                    value={props.appraisal.borrower_mobile_phone}
                    updateFunc={
                      generateUpdateFunction('appraisal/update',[{field:'field',value:'borrower_mobile_phone'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Email
                </Col>
                <Col sm="8">
                  <MyXEditable
                    type="text"
                    value={props.appraisal.borrower_email}
                    updateFunc={
                      generateUpdateFunction('appraisal/update',[{field:'field',value:'borrower_email'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
                    }
                  />
                </Col>
              </Row>
            </div>

            <br/>

            <div className="my-well">
              <Row><Col sm="12"><b><i className="fa fa-home"></i>&nbsp;Property Information</b></Col></Row>
              <Row>
                <Col sm="4">
                  Street
                </Col>
                <Col sm="8">
                  <MyXEditable
                    type="text"
                    value={props.appraisal.property_street}
                    updateFunc={
                      generateUpdateFunction('appraisal/update',[{field:'field',value:'property_street'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  County
                </Col>
                <Col sm="8">
                  <MyXEditable
                    type="text"
                    value={props.appraisal.property_county}
                    updateFunc={
                      generateUpdateFunction('appraisal/update',[{field:'field',value:'property_county'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  City
                </Col>
                <Col sm="8">
                  <MyXEditable
                    type="text"
                    value={props.appraisal.property_city}
                      updateFunc={
                        generateUpdateFunction('appraisal/update',[{field:'field',value:'property_city'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  State
                </Col>
                <Col sm="8">
                  {states}
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Zip
                </Col>
                <Col sm="8">
                  <MyXEditable
                    type="text"
                    value={props.appraisal.property_zip}
                    updateFunc={
                      generateUpdateFunction('appraisal/update',[{field:'field',value:'property_zip'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  APN
                </Col>
                <Col sm="8">
                  <MyXEditable
                    type="text"
                    value={props.appraisal.apn}
                      updateFunc={
                        generateUpdateFunction('appraisal/update',[{field:'field',value:'apn'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Occupancy
                </Col>
                <Col sm="8">
                  <MyXEditable
                    type="select"
                    value={props.appraisal.property_occupancy}
                    opt={[{key:'Owner-Occupied',value:'Owner-Occupied'},{key:'Tenant-Occupied',value:'Tenant-Occupied'},{key:'Vacant',value:'Vacant'}]}
                    updateFunc={
                      generateUpdateFunction('appraisal/update',[{field:'field',value:'property_occupancy'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Property Type
                </Col>
                <Col sm="8">
                  {propertyTypes}
                </Col>
              </Row>

              <Row>
                <Col sm="4">
                  Complex
                </Col>
                <Col sm="8">
                  <MyXEditable
                    type="select"
                    value={props.appraisal.complex}
                    opt={[{key:'Complex',value:'Complex'},{key:'Non-complex',value:'Non-complex'}]}
                    updateFunc={
                      generateUpdateFunction('appraisal/update',[{field:'field',value:'complex'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
                    }
                  />
                </Col>
              </Row>
            </div>

            <br/>

            <div className="my-well">
              <Row><Col sm="12"><b><i className="fa fa-home"></i>&nbsp;Property Access Contact</b></Col></Row>
              <Row>
                <Col sm="4">
                  Title
                </Col>
                <Col sm="8">
                  <MyXEditable
                    type="text"
                    value={props.appraisal.property_access_title}
                    updateFunc={
                      generateUpdateFunction('appraisal/update',[{field:'field',value:'property_access_title'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Name
                </Col>
                <Col sm="8">
                  <MyXEditable
                    type="text"
                    value={props.appraisal.property_access_name}
                    updateFunc={
                      generateUpdateFunction('appraisal/update',[{field:'field',value:'property_access_name'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Email
                </Col>
                <Col sm="8">
                  <MyXEditable
                    type="text"
                    value={props.appraisal.property_access_email}
                    updateFunc={
                      generateUpdateFunction('appraisal/update',[{field:'field',value:'property_access_email'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Work Phone #
                </Col>
                <Col sm="8">
                  <MyXEditable
                    type="text"
                    value={props.appraisal.property_access_work_phone}
                    updateFunc={
                      generateUpdateFunction('appraisal/update',[{field:'field',value:'property_access_work_phone'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Home Phone #
                </Col>
                <Col sm="8">
                  <MyXEditable
                    type="text"
                    value={props.appraisal.property_access_home_phone}
                    updateFunc={
                      generateUpdateFunction('appraisal/update',[{field:'field',value:'property_access_home_phone'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Mobile Phone #
                </Col>
                <Col sm="8">
                  <MyXEditable
                    type="text"
                    value={props.appraisal.property_access_mobile_phone}
                    updateFunc={
                      generateUpdateFunction('appraisal/update',[{field:'field',value:'property_access_mobile_phone'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
                    }
                  />
                </Col>
              </Row>

            </div>

            <br/>

            <div className="my-well">
              <Row>
                <Col sm="4"><div className="display-inline"><b><i className="fa fa-cogs"></i>&nbsp;Report Type</b></div></Col>
                <Col sm="8">{appraisalType}</Col>
              </Row>
              {pdrID}
              {pdrLPAKey}
              {pdrSellerID}
            </div>
            <br/>


            <div className="my-well">
              <Row><Col sm="12"><b><i className="fa fa-info-circle"></i>&nbsp;Loan Facts</b></Col></Row>
              <Row>
                <Col sm="4">
                  Loan Number
                </Col>
                <Col sm="8">
                  <MyXEditable
                    type="text"
                    value={props.appraisal.loan_num}
                    updateFunc={
                      generateUpdateFunction('appraisal/update',[{field:'field',value:'loan_num'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Appraised Value
                </Col>
                <Col sm="8">
                  <MyXEditable
                    type="number"
                    value={props.appraisal.appraised_value}
                    updateFunc={
                      generateUpdateFunction('appraisal/update',[{field:'field',value:'appraised_value'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
                    }
                  />
                </Col>
              </Row>

              <Row>
                <Col sm="4">
                  Loan Amount
                </Col>
                <Col sm="8">
                  <MyXEditable
                    type="number"
                    value={props.appraisal.loan_amount}
                    updateFunc={
                      generateUpdateFunction('appraisal/update',[{field:'field',value:'loan_amount'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
                    }
                  />
                </Col>
              </Row>

              <Row>
                <Col sm="4">
                  Anticipated Value
                </Col>
                <Col sm="8">
                  <MyXEditable
                    type="text"
                    value={props.appraisal.estimated_value}
                    updateFunc={
                      generateUpdateFunction('appraisal/update',[{field:'field',value:'estimated_value'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
                    }
                  />
                </Col>
              </Row>


              {purchasePrice}
              <Row>
                <Col sm="4">
                  Loan Type
                </Col>
                <Col sm="8">
                  {loanTypes}
                </Col>
              </Row>
              {fhaCaseNumber}
              <Row>
                <Col sm="4">
                  Loan Purpose
                </Col>
                <Col sm="8">
                  {loanPurposes}
                </Col>
              </Row>
            </div>
          </Col>
          <Col sm="6">
          <div className="my-well">
            <Row><Col sm="12"><b><i className=" fa fa-user-circle"></i>&nbsp;Lender</b></Col></Row>

            <Row>
              <Col sm="4" className={props.appraisal.order_type==='Wholesale' && props.appraisal.client_fk===props.appraisal.open_by_client?"bold flashit red-color":""}>
                Open By Client
              </Col>
              <Col sm="8">
                {clientDropDown2} <NavLink target="_blank" to={"/client/"+props.appraisal.open_by_client+"/status="}>View Profile</NavLink>
              </Col>
            </Row>
            <Row>
              <Col sm="4">
                Open By Client Branch
              </Col>
              <Col sm="8">
                {openByClientBranchDropDown}
              </Col>
            </Row>
            <br/>
            <Row>
              <Col sm="4">
                Close By Client {clientNote}
              </Col>
              <Col sm="8">
                {clientDropDown} <NavLink target="_blank" to={"/client/"+props.appraisal.client_fk+"/status="}>View Profile</NavLink>
              </Col>
            </Row>
            <Row>
              <Col sm="4">
                Close By Client Branch
              </Col>
              <Col sm="8">
                {clientBranchDropDown}
              </Col>
            </Row>

            <Row>
              <Col sm="4">
                Address
              </Col>
              <Col sm="8">
                {props.appraisal.client_address}
              </Col>
            </Row>
            <br/>
            <Row>
              <Col sm="4">
                Loan
              </Col>
              <Col sm="8">
                <MyXEditable
                  type="select"
                  value={props.appraisal.order_type}
                  opt={[{key:'Retail',value:'Retail'},{key:'Wholesale',value:'Wholesale'}]}
                  updateFunc={
                    generateUpdateFunction('appraisal/update',[{field:'field',value:'order_type'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
                  }
                />
              </Col>
            </Row>
          </div>



          <br/>
          <Row>
            <Col sm="12">
              <div className="my-well">
                <b><i className="fa fa-calendar"></i>&nbsp;Dates</b>
                <Row>
                  <Col sm="6">
                    TAT Reset <i className="fa fa-question cursor-pointer link-color" id="tatCutOffHelp"/>:
                    <UncontrolledTooltip className="mytooltip" delay={{ "show": 0, "hide": 0 }} placement="right" target={"tatCutOffHelp"}>
                      The date where TAT should be start calculating. Only use this when the TAT of the orders was affected by something out of our control.
                    </UncontrolledTooltip>
                  </Col>
                  <Col sm="6">
                    <MyXEditable
                      type="datetime"
                      value={props.appraisal.datetime_tat_cutoff}
                      updateFunc={
                        generateUpdateFunction('appraisal/update',[{field:'field',value:'datetime_tat_cutoff'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col sm="6">
                    Submitted:
                  </Col>
                  <Col sm="6">
                    {formatDateTime(props.appraisal.datetime_submitted)}
                  </Col>
                </Row>
                <Row>
                  <Col sm="6">
                    Assigned:
                  </Col>
                  <Col sm="6">
                    {formatDateTime(props.appraisal.datetime_assigned)}
                  </Col>
                </Row>
                <Row>
                  <Col sm="6">
                    Accepted:
                  </Col>
                  <Col sm="6">
                    {formatDateTime(props.appraisal.datetime_accepted)}
                  </Col>
                </Row>
                <Row>
                  <Col sm="6">
                    Inspection:
                  </Col>
                  <Col sm="6">
                    <MyXEditable
                      type="datetime"
                      value={props.appraisal.datetime_inspection}
                      updateFunc={
                        generateUpdateFunction('appraisal/update',[{field:'field',value:'datetime_inspection'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="6">
                    Inspection history:
                  </Col>
                  <Col sm="6">
                    {
                      props.appraisal.inspection_history.map(
                        (history, index)=>{
                          if(index===props.appraisal.inspection_history.length-1)
                            return null;
                          return(
                            <Row>
                              <Col sm="5">
                                {formatDateTime(history.datetime_inspection)}
                              </Col>
                              <Col sm="7">
                                <i>{history.comment}</i>
                              </Col>
                            </Row>
                          );
                        }
                      )
                    }
                  </Col>
                </Row>

                <Row>
                  <Col sm="6">
                    Appraiser Due Date (PST) <i className="fa fa-question cursor-pointer link-color" id="dueDateHelp"/>:
                    <UncontrolledTooltip className="mytooltip" delay={{ "show": 0, "hide": 0 }} placement="right" target={"dueDateHelp"}>
                      The due date stored is always in PST. Always enter the local time of the due date if you have to manually update. System will convert the local time to PST for you.
                    </UncontrolledTooltip>
                  </Col>
                  <Col sm="6">
                    <MyXEditable
                      type="datetime"
                      value={props.appraisal.datetime_due}
                      updateFunc={
                        generateUpdateFunctionWithCustomCallBackForDueDate('appraisal/update',[{field:'field',value:'datetime_due'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                {dueDateHistory}
                <Row>
                  <Col sm="6">
                    Client Due Date:
                  </Col>
                  <Col sm="6">
                    <MyXEditable
                      type="datetime"
                      value={props.appraisal.datetime_expected}
                      updateFunc={
                        generateUpdateFunction('appraisal/update',[{field:'field',value:'datetime_expected'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                {expDateHistory}
                <Row>
                  <Col sm="6">
                    Appraisal Effective:
                  </Col>
                  <Col sm="6">
                    <MyXEditable
                      type="datetime"
                      value={props.appraisal.datetime_appraisal_effective}
                      updateFunc={
                        generateUpdateFunction('appraisal/update',[{field:'field',value:'datetime_appraisal_effective'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>

                <br/><br/>
                <Row>
                  <Col sm="6">
                    Priority
                  </Col>
                  <Col sm="6">
                    <MyXEditable
                      type="select"
                      value={props.appraisal.priority}
                      opt={state.priorities.map((priority)=>{return {key:priority.priority, value:priority.priority}})}
                      updateFunc={
                        generateUpdateFunction('appraisal/update',[{field:'field',value:'priority'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="6">
                    Rush
                  </Col>
                  <Col sm="6">
                    <MyXEditable
                      type="select"
                      value={props.appraisal.rush}
                      opt={[{key:'Rush',value:'Rush'},{key:'Non-rush',value:'Non-rush'}]}
                      updateFunc={
                        generateUpdateFunction('appraisal/update',[{field:'field',value:'rush'},{field:'ID',value:props.appraisal.ID}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
              </div>

              <br/>
              <div className="cursor-pointer link-color display-inline" onClick={newClientEntityToggle}><b><i className="green-color fa fa-plus"></i>&nbsp;New Client Contact Information</b></div>
              <Modal className="my-modal" isOpen={state.newClientEntityDropDownOpen} toggle={newClientEntityToggle} >
                <ModalHeader hidden={true} toggle={newClientEntityToggle}></ModalHeader>
                <ModalBody>
                  <center>
                    <h5><i className="fa fa-comments"></i> New Client Contact Information</h5>
                  </center>
                  <br/>
                  <Row>
                    <Col sm="6">
                      <div className="cursor-pointer link-color display-inline" onClick={batchEntityToggle}><b><i className="green-color fa fa-plus"></i>&nbsp;Batch Import</b></div>
                    </Col>
                    <Col sm="6" className="align-right">
                      <div className="cursor-pointer link-color display-inline"><NavLink target="_blank" to={"/client/"+props.appraisal.open_by_client+'/tab=2'}><i className="fa fa-plus green-color"></i> Manage client contact</NavLink></div>
                    </Col>
                  </Row>
                  <div className="form-group">
                    <span><label>Label:</label></span>
                    <MySelect
                      modal={true}
                      type="select"
                      value={state.newClientEntityLabel}
                      onChange={(v)=>{setState({newClientEntityLabel:v})}}
                      options={state.clientEntityList.map((entity)=>{
                        return {label:entity, value:entity};
                      })}
                      />
                  </div>

                  <div className="form-group">
                    <span><label><i className="fa fa-times red-color cursor-pointer" onClick={(e)=>{setState({newClientEntityName:''});setState({newClientEntityEmail:''});setState({newClientEntityPhone:''});}}></i> Name:</label></span>
                    {entityNameWidget}
                  </div>

                  <div className="form-group">
                    <span><label>Email:</label></span>
                    <Input type="text" className="form-control" value={state.newClientEntityEmail} id="newClientEntityEmail" placeholder="E.g jsmith@gmail.com" onChange={(e)=>{setState({newClientEntityEmail:e.target.value})}}></Input>
                  </div>

                  <div className="form-group">
                    <span><label>Phone:</label></span>
                    <Input type="text" className="form-control" value={state.newClientEntityPhone} id="newClientEntityPhone" placeholder="E.g xxx-xxxxxxx" onChange={(e)=>{setState({newClientEntityPhone:e.target.value})}}></Input>
                  </div>
                  {workPhone}
                  {mobilePhone}
                  <br/>
                  <center>
                    <Button color="warning" onClick={createNewClientEntity}><i className="fa fa-check"></i>&nbsp;Add New Client Contact</Button>{' '}
                    <Button color="info" onClick={newClientEntityToggle}>Close</Button>
                  </center>
                </ModalBody>
              </Modal>
              <Modal className="my-modal-wide" isOpen={state.batchImportDropDownOpen} toggle={batchEntityToggle} >
                <ModalHeader hidden={true} toggle={batchEntityToggle}></ModalHeader>
                <ModalBody>
                  <center>
                    <h5><i className="fa fa-comments"></i> New Client Entity - Batch Import</h5>
                  </center>
                  <br/>
                  <br/>
                  <div className="align-right">
                    <div className="cursor-pointer link-color display-inline"><NavLink target="_blank" to={"/client/"+props.appraisal.open_by_client+'/tab=2'}><i className="fa fa-plus green-color"></i> Manage client contact</NavLink></div>
                  </div>
                  <Row>
                    <Col sm="5">
                      <MySelect
                        modal={true}
                        type="select"
                        value={state.batchNewEntitySearchClient}
                        onChange={(v)=>{setState({batchNewEntitySearchClient:v})}}
                        options={state.clients.map((client)=>{
                          return {label:client.value, value:client.key};
                        })}
                        />
                    </Col>
                    <Col sm="4">
                      <Input type="text" placeholder="Search with name/email" value={state.batchNewEntitySearchKeyword} onChange={(e)=>setState({batchNewEntitySearchKeyword:e.target.value})}/>
                    </Col>
                    <Col sm="3" className="align-right">
                      <Button color="warning" onClick={batchNewEntitySearch}>Search</Button>
                    </Col>
                  </Row>
                  <div className="medium-scroll-container">
                    <Table className="table table-striped">
                      <tbody>
                        {
                          state.batchNewEntitySearchResults.map(
                            (data, index)=>{
                              return (<tr className="cursor-pointer" key={index} onClick={(e)=>selectEntity(data.contacts)}><td>
                                <Row>
                                  {data.contacts.map(
                                    (contact, index2)=>{
                                      return <Col sm="3" key={index2}>
                                        <div>
                                          <div><label className="link-color">{contact.label}</label></div>
                                          {contact.name+' '+contact.email+' '+contact.phone}
                                        </div>
                                      </Col>
                                    }
                                  )}
                                </Row>
                              </td></tr>);
                            }
                          )
                        }
                      </tbody>
                    </Table>
                  </div>
                  <br/>

                  <Row>
                    <Col sm="2">
                      <div className="form-group">
                        <span><label>Label:</label></span>
                        <MySelect
                          modal={true}
                          type="select"
                          value={state.batchNewEntity1Label}
                          onChange={(v)=>{setState({batchNewEntity1Label:v})}}
                          options={state.clientEntityList.map((entity)=>{
                            return {label:entity, value:entity};
                          })}
                          />
                      </div>

                      <div className="form-group">
                        <span><label><i className="fa fa-times red-color cursor-pointer" onClick={(e)=>{setState({batchNewEntity1Name:''});setState({batchNewEntity1Email:''});setState({batchNewEntity1Phone:''});}}></i> Name:  </label></span>
                        <MySelect
                          modal={true}
                          type="async-select"
                          value={state.batchNewEntity1Name}
                          onChange={(e)=>handleAsyncSelectChange('batchNewEntity1', e)}
                          loadOptions={(keyword)=>getClientContacts(keyword, state.batchNewEntity1Label, true)}
                          />
                      </div>

                      <div className="form-group">
                        <span><label>Email:</label></span>
                        <Input type="text" className="form-control" value={state.batchNewEntity1Email} id="batchNewEntity1Email" placeholder="E.g jsmith@gmail.com" onChange={(e)=>{setState({batchNewEntity1Email:e.target.value})}}></Input>
                      </div>

                      <div className="form-group">
                        <span><label>Phone:</label></span>
                        <Input type="text" className="form-control" value={state.batchNewEntity1Phone} id="batchNewEntity1Phone" placeholder="E.g xxx-xxxxxxx" onChange={(e)=>{setState({batchNewEntity1Phone:e.target.value})}}></Input>
                      </div>
                    </Col>
                    <Col sm="2">
                      <div className="form-group">
                        <span><label>Label:</label></span>
                        <MySelect
                          modal={true}
                          type="select"
                          value={state.batchNewEntity2Label}
                          onChange={(v)=>{setState({batchNewEntity2Label:v})}}
                          options={state.clientEntityList.map((entity)=>{
                            return {label:entity, value:entity};
                          })}
                          />
                      </div>

                      <div className="form-group">
                        <span><label><i className="fa fa-times red-color cursor-pointer" onClick={(e)=>{setState({batchNewEntity2Name:''});setState({batchNewEntity2Email:''});setState({batchNewEntity2Phone:''});}}></i> Name:</label></span>
                        <MySelect
                          modal={true}
                          type="async-select"
                          value={state.batchNewEntity2Name}
                          onChange={(e)=>handleAsyncSelectChange('batchNewEntity2', e)}
                          loadOptions={(keyword)=>getClientContacts(keyword, state.batchNewEntity2Label, true)}
                          />
                      </div>

                      <div className="form-group">
                        <span><label>Email:</label></span>
                        <Input type="text" className="form-control" value={state.batchNewEntity2Email} id="batchNewEntity2Email" placeholder="E.g jsmith@gmail.com" onChange={(e)=>{setState({batchNewEntity2Email:e.target.value})}}></Input>
                      </div>

                      <div className="form-group">
                        <span><label>Phone:</label></span>
                        <Input type="text" className="form-control" value={state.batchNewEntity2Phone} id="batchNewEntity2Phone" placeholder="E.g xxx-xxxxxxx" onChange={(e)=>{setState({batchNewEntity2Phone:e.target.value})}}></Input>
                      </div>
                    </Col>
                    <Col sm="2">
                      <div className="form-group">
                        <span><label>Label:</label></span>
                        <MySelect
                          modal={true}
                          type="select"
                          value={state.batchNewEntity3Label}
                          onChange={(v)=>{setState({batchNewEntity3Label:v})}}
                          options={state.clientEntityList.map((entity)=>{
                            return {label:entity, value:entity};
                          })}
                          />
                      </div>

                      <div className="form-group">
                        <span><label><i className="fa fa-times red-color cursor-pointer" onClick={(e)=>{setState({batchNewEntity3Name:''});setState({batchNewEntity3Email:''});setState({batchNewEntity3Phone:''});}}></i> Name:</label></span>
                        <MySelect
                          modal={true}
                          type="async-select"
                          value={state.batchNewEntity3Name}
                          onChange={(e)=>handleAsyncSelectChange('batchNewEntity3', e)}
                          loadOptions={(keyword)=>getClientContacts(keyword, state.batchNewEntity3Label, true)}
                          />
                      </div>

                      <div className="form-group">
                        <span><label>Email:</label></span>
                        <Input type="text" className="form-control" value={state.batchNewEntity3Email} id="batchNewEntity3Email" placeholder="E.g jsmith@gmail.com" onChange={(e)=>{setState({batchNewEntity3Email:e.target.value})}}></Input>
                      </div>

                      <div className="form-group">
                        <span><label>Phone:</label></span>
                        <Input type="text" className="form-control" value={state.batchNewEntity3Phone} id="batchNewEntity3Phone" placeholder="E.g xxx-xxxxxxx" onChange={(e)=>{setState({batchNewEntity3Phone:e.target.value})}}></Input>
                      </div>
                    </Col>
                    <Col sm="2">
                      <div className="form-group">
                        <span><label>Label:</label></span>
                        <MySelect
                          modal={true}
                          type="select"
                          value={state.batchNewEntity4Label}
                          onChange={(v)=>{setState({batchNewEntity4Label:v})}}
                          options={state.clientEntityList.map((entity)=>{
                            return {label:entity, value:entity};
                          })}
                          />
                      </div>

                      <div className="form-group">
                        <span><label><i className="fa fa-times red-color cursor-pointer" onClick={(e)=>{setState({batchNewEntity4Name:''});setState({batchNewEntity4Email:''});setState({batchNewEntity4Phone:''});}}></i> Name:</label></span>
                        <MySelect
                          modal={true}
                          type="async-select"
                          value={state.batchNewEntity4Name}
                          onChange={(e)=>handleAsyncSelectChange('batchNewEntity4', e)}
                          loadOptions={(keyword)=>getClientContacts(keyword, state.batchNewEntity4Label, true)}
                          />
                      </div>

                      <div className="form-group">
                        <span><label>Email:</label></span>
                        <Input type="text" className="form-control" value={state.batchNewEntity4Email} id="batchNewEntity4Email" placeholder="E.g jsmith@gmail.com" onChange={(e)=>{setState({batchNewEntity4Email:e.target.value})}}></Input>
                      </div>

                      <div className="form-group">
                        <span><label>Phone:</label></span>
                        <Input type="text" className="form-control" value={state.batchNewEntity4Phone} id="batchNewEntity4Phone" placeholder="E.g xxx-xxxxxxx" onChange={(e)=>{setState({batchNewEntity4Phone:e.target.value})}}></Input>
                      </div>
                    </Col>
                    <Col sm="2">
                      <div className="form-group">
                        <span><label>Label:</label></span>
                        <MySelect
                          modal={true}
                          type="select"
                          value={state.batchNewEntity5Label}
                          onChange={(v)=>{setState({batchNewEntity5Label:v})}}
                          options={state.clientEntityList.map((entity)=>{
                            return {label:entity, value:entity};
                          })}
                          />
                      </div>

                      <div className="form-group">
                        <span><label><i className="fa fa-times red-color cursor-pointer" onClick={(e)=>{setState({batchNewEntity5Name:''});setState({batchNewEntity5Email:''});setState({batchNewEntity5Phone:''});}}></i> Name:</label></span>
                        <MySelect
                          modal={true}
                          type="async-select"
                          value={state.batchNewEntity5Name}
                          onChange={(e)=>handleAsyncSelectChange('batchNewEntity5', e)}
                          loadOptions={(keyword)=>getClientContacts(keyword, state.batchNewEntity5Label, true)}
                          />
                      </div>

                      <div className="form-group">
                        <span><label>Email:</label></span>
                        <Input type="text" className="form-control" value={state.batchNewEntity5Email} id="batchNewEntity5Email" placeholder="E.g jsmith@gmail.com" onChange={(e)=>{setState({batchNewEntity5Email:e.target.value})}}></Input>
                      </div>

                      <div className="form-group">
                        <span><label>Phone:</label></span>
                        <Input type="text" className="form-control" value={state.batchNewEntity5Phone} id="batchNewEntity5Phone" placeholder="E.g xxx-xxxxxxx" onChange={(e)=>{setState({batchNewEntity5Phone:e.target.value})}}></Input>
                      </div>
                    </Col>
                    <Col sm="2">
                      <div className="form-group">
                        <span><label>Label:</label></span>
                        <MySelect
                          modal={true}
                          type="select"
                          value={state.batchNewEntity6Label}
                          onChange={(v)=>{setState({batchNewEntity6Label:v})}}
                          options={state.clientEntityList.map((entity)=>{
                            return {label:entity, value:entity};
                          })}
                          />
                      </div>

                      <div className="form-group">
                        <span><label><i className="fa fa-times red-color cursor-pointer" onClick={(e)=>{setState({batchNewEntity6Name:''});setState({batchNewEntity6Email:''});setState({batchNewEntity6Phone:''});}}></i> Name:</label></span>
                        <MySelect
                          modal={true}
                          type="async-select"
                          value={state.batchNewEntity6Name}
                          onChange={(e)=>handleAsyncSelectChange('batchNewEntity6', e)}
                          loadOptions={(keyword)=>getClientContacts(keyword, state.batchNewEntity6Label, true)}
                          />
                      </div>

                      <div className="form-group">
                        <span><label>Email:</label></span>
                        <Input type="text" className="form-control" value={state.batchNewEntity6Email} id="batchNewEntity6Email" placeholder="E.g jsmith@gmail.com" onChange={(e)=>{setState({batchNewEntity6Email:e.target.value})}}></Input>
                      </div>

                      <div className="form-group">
                        <span><label>Phone:</label></span>
                        <Input type="text" className="form-control" value={state.batchNewEntity6Phone} id="batchNewEntity6Phone" placeholder="E.g xxx-xxxxxxx" onChange={(e)=>{setState({batchNewEntity6Phone:e.target.value})}}></Input>
                      </div>
                    </Col>
                  </Row>

                  <br/>
                  <center>
                    <Button color="warning" onClick={createNewBatchClientEntity}><i className="fa fa-check"></i>&nbsp;Add Client Contactss</Button>{' '}
                    <Button color="info" onClick={batchEntityToggle}>Close</Button>
                  </center>
                </ModalBody>
              </Modal>
            </Col>
          </Row>
          <div className="my-well">
            {clientEntities}
          </div>
          </Col>
        </Row>
        <br/>
        <Row>
          <Col sm="6">
            <div className="card" style={{minHeight:'300px'}}>
      				<div className="card-header header-color">
                <i className="fa fa-files-o"></i>&nbsp;Documents Storage
              </div>
              <div className="card-body" style={{height:'550px'}}>
                {appraisalFileUI}
              </div>
            </div>
          </Col>
          <Col sm="6">
            <div className="card" style={{minHeight:'300px'}}>
      				<div className="card-header header-color">
                <i className="fa fa-comments"></i>&nbsp;Communications Log
              </div>
              <div className="card-body" style={{height:'550px'}}>
              <Comment  entities={props.appraisal.entities} appraiser={props.appraisal.appraiser} appraiserFk={props.appraisal.appraiser_fk} appraisalFk={props.appraisal.ID} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
              </div>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  </div>;
}


export default AppraisalDetails;
