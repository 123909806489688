//Author Sooyoung Kim
//Date May 26, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, callBackGenerator, formatDateTime, formatDate, formatNumber, showMessage} from '../../util/util';
import {Alert, Card, CardHeader, CardBody, Col, Row, FormGroup, Label,Modal, ModalHeader, ModalBody, Button,Input, Table} from 'reactstrap';
import {usePresencePusher} from '../pusher/pusher-context';
import MySelect from '../util/my-select';
import {NavLink, useParams} from 'react-router-dom';
import ReactQuill from 'react-quill';
import React, {useReducer, useEffect, useRef} from 'react';
import './rebuttal.css';


//initialize the state
const initialState = {
  overallStatus:{
    review_count:0,
    condition_count:0,
    rebuttal_count:0,
    due_invoice_count:0
  },
  rebuttals:[],
  rebuttalPopUp: false,
  entities:[],
  selectedRecipient:-1,
  templates:[],
  ticketsToSend:[],
  filesToSend:[],
  rebuttalCommentToAppraiser:'',
  appraisalFiles:[],
  targetRecipients:[],
  statuses:[],
  presenceMembers:[],

  messages:[],
  templateName:'',
};

//reducer function that perform state update
const reducer = getReducer();


const Rebuttal  = (props)=>{
  const controller = new AbortController();
  const presencePusher = usePresencePusher ();
  const componentRef = useRef(null);
  const { id } = useParams();

  let newInitialState = Object.assign({}, initialState, {
    id:id,
    globalPipeline:localStorage.getItem('pipelinePreference')==='global',
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    initialize();
    getRebuttal();
    getTemplates();
    getAppraisalFiles();
    getAllRebuttalStatuses();

    if (componentRef.current && componentRef.current.parentElement) {
      componentRef.current.parentElement.scrollTop = 0;
    }

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if (presencePusher) {
      const presenceChannel = presencePusher.subscribe('presence-order');

      const handleSubscriptionSucceeded = (members) => {
        // Get the initial user list from presence channel
        let presenceMembers = [];
        members.each(
          function(member)
          {
            let ID = member.info.ID;
            let orderID = member.info.orderID;
            let myID = localStorage.getItem('userID');
            if(orderID===props.appraisalFk){
              if(ID!==myID){
                presenceMembers.push(member);
              }
            }
          }
        );
        console.log(presenceMembers)
        setState({presenceMembers:presenceMembers})
      };

      const handleMemberAdded = (member) => {
        let ID = member.info.ID;
        let orderID = member.info.orderID;
        let myID = localStorage.getItem('userID');
        if(orderID===props.appraisalFk){
          if(ID!==myID){
            let presenceMembers = state.presenceMembers.slice();
            presenceMembers.push(member);

            setState({presenceMembers:presenceMembers});
          }
        }
      };

      const handleMemberRemoved = (member) => {
        // Remove a user from the user list
        let ID = member.info.ID;
        let orderID = member.info.orderID;
        let myID = localStorage.getItem('userID');

        if(orderID===props.appraisalFk){
          if(ID!==myID){
            let presenceMembers = state.presenceMembers.slice();

            for(let i=0;i<presenceMembers.length;i++){
              if(presenceMembers[i].info.ID===member.info.ID){
                presenceMembers.splice(i, 1);
                setState({presenceMembers:presenceMembers});
                break;
              }
            }
          }
        }
      };

      presenceChannel.bind('pusher:subscription_succeeded', handleSubscriptionSucceeded);
      presenceChannel.bind('pusher:member_added', handleMemberAdded);
      presenceChannel.bind('pusher:member_removed', handleMemberRemoved);

      return () => {
        presenceChannel.unbind('pusher:subscription_succeeded', handleSubscriptionSucceeded);
        presenceChannel.unbind('pusher:member_added', handleMemberAdded);
        presenceChannel.unbind('pusher:member_removed', handleMemberRemoved);

        presenceChannel.unsubscribe();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presencePusher, props.appraisalFk]);

  const initialize = ()=>{
    let entities = [];
    for(let i=0;i<props.appraisal.entities.length;i++){
      let entity = props.appraisal.entities[i];
      entities.push(entity);
    }

    setState({entities:entities});
  }

  const modifyTicketsToSend = (ID)=>{
    let ticketsToSend = state.ticketsToSend.slice();
    let index = ticketsToSend.indexOf(ID);

    if(index!==-1)
      ticketsToSend.splice(index,1);
    else
      ticketsToSend.push(ID);

    setState({ticketsToSend:ticketsToSend});
  }

  const modifyFilesToSend = (ID)=>{
    let filesToSend = state.filesToSend.slice();
    let index = filesToSend.indexOf(ID);


    if(index!==-1)
      filesToSend.splice(index,1);
    else
      filesToSend.push(ID);

    setState({filesToSend:filesToSend});
  }

  const rebuttalPopUpToggle = ()=>{
    setState({rebuttalPopUp:!state.rebuttalPopUp});

    let tickets = [];
    for(let i=0;i<state.rebuttals.length;i++){
      if(state.rebuttals[i].status==='Submitted')
        tickets.push(state.rebuttals[i].ID);
    }

    setState({ticketsToSend:tickets});
  }

  const editSubjectFacts = (id, value)=>{
    let rebuttals = state.rebuttals.slice();

    for(let i=0;i<rebuttals.length;i++){
      if(rebuttals[i].ID===id){
        rebuttals[i].subject_facts = value;
        break;
      }
    }

    setState({rebuttals:rebuttals});
  }


  //add all entity associated with this appraisal to target recipients that will receive rebuttal notification
  const addAllToTargetRecipients = ()=>{
    let allRecipients = state.entities.slice();
    setState({targetRecipients:allRecipients});
  }

  //remove a recipient from target recipient that will receive rebuttal notification
  const removeFromTargetRecipients = (id)=>{
    for(let i =0;i<state.targetRecipients.length;i++){
      if(state.targetRecipients[i].ID===id){
        let newTargetRecipients = state.targetRecipients.slice();
        newTargetRecipients.splice(i,1);

        setState({targetRecipients:newTargetRecipients});
      }
    }
  }

  //on change function for the select drop down that will populate the text area
  const templatesOnChange = (id)=>{
    let template;

    for(let i=0;i<state.templates.length;i++){
      if(state.templates[i].ID===id){
        template = state.templates[i];
        break;
      }
    }

    if(template){
      setState({rebuttalCommentToAppraiser:template.template, templateName:id});
    }
  }

  //add the selected recipient to target recipient
  const addNewRecipients = (ID)=>{
    if(ID==='All')
      addAllToTargetRecipients();
    else{
      for(let i =0;i<state.targetRecipients.length;i++){
        if(state.targetRecipients[i].ID===ID)
          return;
      }
      let existingNewTargetRecipients = state.targetRecipients.slice();

      let targetEntity = null;
      for(let i=0;i<state.entities.length;i++){
        if(state.entities[i].ID===ID){
          targetEntity = state.entities[i];
          break;
        }
      }

      if(targetEntity){
        existingNewTargetRecipients.push(targetEntity);
        setState({targetRecipients:existingNewTargetRecipients});
      }
    }
  }

  //API call
  //get all the status of rebuttal for the drop down filter
  const getAllRebuttalStatuses = ()=>{
    let callBack = apiCallBack([{state:'statuses', key:'data'}]);
    httpGet('rebuttal/status/get', '', 'Oops, something went wrong and could not retrieve rebuttal statuses.', callBack);
  }

  //get all rebuttal tickets
  const getRebuttal = ()=>{
    let callBack = apiCallBack([{state:'rebuttals', key:'data'}]);
    httpGet('rebuttal/'+props.appraisalFk, '', 'Oops, something went wrong and could not load the rebuttal for this appraisal. Please try again later.', callBack);
  }

  //get templates
  const getTemplates = ()=>{
    let callBack = apiCallBack([{state:'templates', key:'data'}]);
    httpGet('template/get/'+props.appraisalFk, '', 'Oops, something went wrong and could not load your comment template.', callBack);
  }

  //retrieve a list of appraisal files that ties to a specific appraisal
  const getAppraisalFiles = ()=>{
    //Call Back
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        setState({appraisalFiles:response.data.data});

        for(let i=0;i<response.data.data.length;i++){
          if(response.data.data[i].rebuttal==='yes')
            modifyFilesToSend(response.data.data[i].ID);
        }
      }
    };
    callBack = callBack.bind(this);

    httpGet('file/get/'+props.appraisalFk, '', 'Oops, something went wrong and could not load appraisal files for this appraisal. Please try again later.', callBack);
  }

  const saveSubjectFacts = (id)=>{
    let subjectFacts;

    //locate the subject facts to save
    for(let i=0;i<state.rebuttals.length;i++){
      if(state.rebuttals[i].ID===id){
        subjectFacts = state.rebuttals[i].subject_facts;
      }
    }

    let parameters = [
      {
        field:'ID',
        value:id
      },
      {
        field:'field',
        value:'subject_facts'
      },
      {
        field:'value',
        value:subjectFacts
      }
    ];

    let callBack = ()=>{};
    httpPut('rebuttal/update', parameters, 'Subject facts saved successfully.', 'Oops, something went wrong and could not save this subject facts. Please try again later.', callBack);
  }

  const startRebuttal = ()=>{
    if(state.ticketsToSend.length>0){
      let targetRecipients =[];

      for(let i=0;i<state.targetRecipients.length;i++)
        targetRecipients.push(state.targetRecipients[i].ID);

      let parameters = [
        {
          field:'ID',
          value:props.appraisalFk
        },
        {
          field:'files',
          value:state.filesToSend
        },
        {
          field:'tickets',
          value:state.ticketsToSend
        },
        {
          field:'comment',
          value:state.rebuttalCommentToAppraiser
        },
        {
          field:'entities',
          value:targetRecipients
        }
      ];

      let callBack = (response)=>{
        let code= response.data.code;

        if(code==='00'){
          let rebuttals = state.rebuttals.slice();
          for(let i=0;i<state.ticketsToSend.length;i++){
            for(let j=0;j<rebuttals.length;j++){
              if(rebuttals[j].ID===state.ticketsToSend[i]){
                rebuttals[j].status = 'Started';
                break;
              }
            }
          }

          setState({rebuttals:rebuttals,ticketsToSend:[],filesToSend:[],rebuttalCommentToAppraiser:'',targetRecipients:[]});
          rebuttalPopUpToggle();
        }
      };
      callBack = callBack.bind(this);

      httpPost('rebuttal/start', parameters, 'Rebuttal started successfully.', 'Oops, something went wrong and could not start the rebuttal. Please try again later.', callBack);
    }
    else{
      showMessage('warning','Please select at least one rebuttal.');
    }
  }

  const updateRebuttalField = (id, field, value)=>{
    let parameters = [
      {
        field:'ID',
        value:id
      },
      {
        field:'field',
        value:field
      },
      {
        field:'value',
        value:value
      }
    ];

    let rebuttals = state.rebuttals.slice();
    for(let i=0;i<rebuttals.length;i++){
      if(rebuttals[i].ID===id){
        rebuttals[i][field] = value;
        break;
      }
    }

    let callBack = apiCallBack([{state:'rebuttals', value:rebuttals}]);
    httpPut('rebuttal/update', parameters, 'Rebuttal updated successfully.', 'Oops, something went wrong and could not updated the rebuttal. Please try again later.', callBack);
  }

  //render
  let appraisalFiles;

  if(state.appraisalFiles.length>0){
    appraisalFiles = state.appraisalFiles.map(
      (appraisalFile,index)=>{

        return(
          <div key={index}>
            <div className="display-inline">
              <FormGroup check>
                <Label check>
                  <Input type="checkbox" defaultChecked={appraisalFile.rebuttal_use==='yes'} onClick={()=>modifyFilesToSend(appraisalFile.ID)}/>&nbsp;
                </Label>
              </FormGroup>
            </div>
            {appraisalFile.name}
          </div>
        );
      }
    );
  }


  let rebuttals;
  let rebuttalTicketsToSend;

  if(state.rebuttals.length>0){
    rebuttalTicketsToSend = state.rebuttals.map(
      (ticket,index)=>{
        if(ticket.status!=='Submitted')
          return null;
        return(
          <div key={index}>
            <div className="display-inline">
              <FormGroup check>
                <Label check>
                  <Input type="checkbox" defaultChecked onClick={()=>modifyTicketsToSend(ticket.ID)}/>&nbsp;
                </Label>
              </FormGroup>
            </div>
            {ticket.status} - by {ticket.requester_name} @ {formatDateTime(ticket.datetime_created)}
          </div>
        );
      }
    );
    rebuttals = state.rebuttals.map(
      (ticket,index)=>{
        let comps;

        if(ticket.comps&&ticket.comps.length>0){
          comps = ticket.comps.map(
            (comp,index)=>{
              return(
                <div key={index}>
                  <b>{index+1}. {comp.street+' '+comp.city+', '+comp.state+' '+comp.zip}</b><br/>
                  <Row className="no-margin no-padding">
                    <Col sm="3" className="low-line-height">
                      <label>Site size</label><br/>
                      {formatNumber(comp.site_size)} sqft
                    </Col>
                    <Col sm="3" className="low-line-height">
                      <label>Living area</label><br/>
                      {formatNumber(comp.sq_footage)} sqft
                    </Col>
                    <Col sm="3" className="low-line-height">
                      <label>Age</label><br/>
                      {formatNumber(comp.age)}
                    </Col>
                    <Col sm="3" className="low-line-height">
                      <label>Status</label><br/>
                      {comp.status}
                    </Col>
                  </Row>
                  <Row className="no-margin no-padding">
                    <Col sm="3" className="low-line-height">
                      <label>Source data</label><br/>
                      {comp.source_data}
                    </Col>
                    <Col sm="3" className="low-line-height">
                      <label>APN MLS #</label><br/>
                      {comp.apn_mls_num}
                    </Col>
                    <Col sm="3" className="low-line-height">
                      <label>Sale price</label><br/>
                      {formatNumber(comp.sale_price)}
                    </Col>
                    <Col sm="3" className="low-line-height">
                      <label>Sale date</label><br/>
                      {formatDate(comp.sale_date)}
                    </Col>
                  </Row>
                  <Row className="no-margin no-padding">
                    <Col sm="12" className="low-line-height">
                      <label>Comment</label><br/>
                      <i>{comp.comment}</i>
                    </Col>
                  </Row>
                  <div className="my-divider"></div>
                </div>
              );
            }
          );
        }

        let icon;

        if(ticket.status==='Completed')
          icon = <i className="fa fa-check green-color"></i>
        else
          icon = <i className="fa fa-clock-o link-color"></i>

        return(
          <div className="ex-margin-bottom" key={ticket.ID}>
            <Card>
              <CardHeader className="gray-color">
                <Row>
                  <Col sm="8">
                    {icon}{" "+index+". "} - by {ticket.requester_name}
                  </Col>
                  <Col sm="4">
                    <div className="align-right">
                      <MySelect
                        type="select"
                        value={ticket.status}
                        onChange={(v)=>{updateRebuttalField(ticket.ID,'status',v)}}
                        options={state.statuses.map((status)=>{
                          return {label:status.name, value:status.name};
                        })}
                      />
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row className="ex-margin-bottom">
                  <Col sm="6" className="low-line-height">
                    <label>Submitted</label><br/>
                    {formatDateTime(ticket.datetime_created)}
                  </Col>
                  <Col sm="6" className="low-line-height">
                    <label>Started</label><br/>
                    {formatDateTime(ticket.datetime_started)}
                  </Col>
                </Row>
                <Row className="ex-margin-bottom">
                  <Col sm="6" className="low-line-height">
                    <label>Processed</label><br/>
                    {formatDateTime(ticket.datetime_processed)}
                  </Col>
                  <Col sm="6" className="low-line-height">
                    <label>Completed</label><br/>
                    {formatDateTime(ticket.datetime_completed)}
                  </Col>
                </Row>
                <Row className="ex-margin-bottom">
                  <Col sm="6" className="low-line-height">
                    <label>Requester Name</label><br/>
                    {ticket.requester_name}
                  </Col>
                  <Col sm="6" className="low-line-height">
                  </Col>
                </Row>
                <Row className="ex-margin-bottom">
                  <Col sm="12" className="low-line-height">
                    <label>Requester Email</label><br/>
                    {ticket.requester_email}
                  </Col>
                </Row>
                <div className="my-divider"></div>

                <b><i className="fa fa-home"></i> Comparables</b>
                <div className="large-scroll-container-no-min">
                  {comps}
                </div>

                <div className="my-divider"></div>
                <ReactQuill
                  modules={
                    {
                      toolbar: [
                          [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                          [{size: []}],
                          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                          [{'list': 'ordered'}, {'list': 'bullet'},
                           {'indent': '-1'}, {'indent': '+1'}],
                          ['link', 'image', 'video'],
                          ['clean']
                        ],
                        clipboard: {
                          // toggle to add extra line breaks when pasting HTML:
                          matchVisual: false,
                        },
                        imageResize: {
                        // parchment: Quill.import('parchment'),
                          modules: ['Resize', 'DisplaySize']
                        }
                    }
                  }
                  formats={
                    [
                      'header', 'font', 'size',
                      'bold', 'italic', 'underline', 'strike', 'blockquote',
                      'list', 'bullet', 'indent',
                      'link', 'image', 'video'
                    ]
                  }
                  value={ticket.subject_facts}
                  onChange={(value) => editSubjectFacts(ticket.ID,value)}
                />
                <Button color="info" onClick={()=>saveSubjectFacts(ticket.ID)} className="form-control"><i className="fa fa-edit"></i> Save Subject Facts</Button>
              </CardBody>
            </Card>
          </div>
        )
      }
    );
  }


  let rebuttalButton;

  if(state.rebuttals.length>0)
    rebuttalButton = <Button onClick={rebuttalPopUpToggle} color="warning"><i className="fa fa-power-off"></i> Begin Rebuttal</Button>
  else
    rebuttalButton = <Button color="info" disabled><i className="fa fa-power-off"></i> Begin Rebuttal</Button>

    let entities;
    if(state.entities){
      entities = state.entities.map(
        (entity, index)=>{
          return (
            <tr key={index} className="cursor-pointer" onClick={(e)=>addNewRecipients(entity.ID)}><td><b>{entity.entity_label}</b></td><td>{entity.entity_name} - {entity.entity_email}</td></tr>
          );
        }
      );
    }

  let targetRecipients;
  if(state.targetRecipients){
    targetRecipients = state.targetRecipients.map(
      (recipient)=>{
        return(
          <div style={{display:'inline-block'}} key={recipient.ID}>
            <div className="entity-container cursor-pointer" onClick={()=>{removeFromTargetRecipients(recipient.ID)}}>
              <i className="fa fa-minus link-color"></i>&nbsp;{recipient.entity_name}
            </div>&nbsp;
          </div>
        );
      }
    );
  }


  return <div ref={componentRef}>
    <Card  style={{borderTop:'0px'}}>
      <CardHeader className="header-color">
        <i className="fa fa-home"></i>&nbsp;{props.appraisal.reference_num} - {props.appraisal.property_street} {props.appraisal.property_city}, {props.appraisal.property_state} {props.appraisal.property_zip} - {props.appraisal.borrower_f_name} {props.appraisal.borrower_l_name}
      </CardHeader>
      <CardBody>
        {
          props.messages.map(
            (message, index)=>{
              if(message.link){
                return(<a key={index} href={'/'+message.link+'/'+message.ID} target="_blank" rel="noreferrer"><Alert key={index} color="warning" className="my-alert">
                    <i className="fa fa-information"></i> {message.msg}
                  </Alert></a>);
              }

              return(<Alert key={index} color="warning" className="my-alert">
                  <i className="fa fa-information"></i> {message.msg}
                </Alert>);
            }
          )
        }
      </CardBody>
    </Card>
    <br/>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-home"></i>&nbsp;Rebuttal - ({state.rebuttals.length} tickets)
      </CardHeader>
      <CardBody>
        <div className="align-right">
          <NavLink to={"/rebuttal/new/"+props.appraisalFk}><i className="fa fa-plus green-color"></i> Create new rebuttal</NavLink>
        </div>
        {rebuttals}
        <br/>
        <div className="align-right">
          {rebuttalButton}
        </div>
        <Modal className="my-modal" isOpen={state.rebuttalPopUp} toggle={rebuttalPopUpToggle} >
          <ModalHeader hidden={true} toggle={rebuttalPopUpToggle}></ModalHeader>
          <ModalBody>
            <center>
              <h5><i className="fa fa-cogs"></i> Begin Rebuttal</h5>
            </center>
            <br/>
            <label>Tag someone</label>

            <div className="small-scroll-container">
              <Table hover className="table">
                <tbody>
                  {entities}
                </tbody>
              </Table>
            </div>
            <hr className="margin-bottom"/>
            <div className="small-scroll-container">
              {targetRecipients}
            </div>
            <label>Rebuttal to send:</label>
            <br/>
            {rebuttalTicketsToSend}

            <div className="my-divider"></div>
            <label>Files to send along:</label>
            <br/>

            <div className="small-scroll-container">
              {appraisalFiles}
            </div>
            <div className="my-divider"></div>
            <label>Comment:</label>
            <br/>
            <Input type="textarea" className="form-control comment-textarea" value={state.rebuttalCommentToAppraiser} id="comment" placeholder="Say something..." style={{resize:'none'}} rows="5" onChange={(e)=>setState({rebuttalCommentToAppraiser:e.target.value})}></Input>
            <Row>
              <Col sm="6">
                <label>Template:</label>
              </Col>
              <Col sm="6" className="align-right">
                <a href="/setting">Manage my template</a>
              </Col>
            </Row>
            <MySelect
              type="select"
              selectIsClearable={true}
              value={state.templateName}
              onChange={(v)=>{templatesOnChange(v)}}
              options={state.templates.map((template)=>{
                return {label:template.name, value:template.ID};
              })}
            />
            <div className="my-diviver"></div>
            <center>
              <Button color="info" onClick={rebuttalPopUpToggle}>Close</Button>
              &nbsp;<Button color="warning" onClick={startRebuttal}>Start</Button>
            </center>
          </ModalBody>
        </Modal>
      </CardBody>
    </Card>
  </div>;
}

export default Rebuttal;
