//Author Sooyoung Kim
//Date Aug 31, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, deleteAPICallGenerator, callBackGenerator, confirmation, formatDateTime, sliceFromArray} from '../../util/util';
import {Col, Row, Nav, NavItem} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import {NavLink, useParams} from 'react-router-dom';

//initialize the state
const initialState = {
  pdfReports:[],
};

//reducer function that perform state update
const reducer = getReducer();


const PropertyDataReportPDF  = (props)=>{
  const controller = new AbortController();
  let {pdrFk} = useParams();

  let newInitialState = Object.assign({}, initialState, {pdrFk:pdrFk});
  const [state, dispatch] = useReducer(reducer, newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getPDFReport();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  //API call
  //delete file
  const deleteFile = (ID) => {
    let list = sliceFromArray(state.pdfReports,'ID', ID);
    let callBack = apiCallBack([{state:'pdfReports', value:list}]);
    httpDelete('propertyDataReport/pdf/'+ID,'','Oops, something went wrong and could not delete the file, please try again later.', callBack);
  }


  const getPDFReport = () => {
    let callBack = apiCallBack([{state:'pdfReports', key:'data'}]);
	  httpGet('propertyDataReport/pdf/'+state.pdrFk, '', 'Oops, something went wrong and could not load report for this PDR. Please try again later.', callBack);
  }

  //render
  return <div>
    <div>
      <Row>
        <Col sm="6">
          <NavLink to="/property-data-report">Back to PDR Pipeline</NavLink>
        </Col>
        <Col sm="6" className="align-right">
        </Col>
      </Row>
    </div>
    <Nav tabs>
      <NavItem>
        <NavLink className="nav-link" to={"/property-data-report/"+state.pdrFk}>ACE + PDR (Property Data Report)</NavLink>
      </NavItem>
      <NavItem>
        <NavLink className="nav-link" to={"/post-completion-report/"+state.pdrFk}>PCR (Post Completion Report)</NavLink>
      </NavItem>
      <NavItem>
        <NavLink className="nav-link active" to="#">Generated Report</NavLink>
      </NavItem>
      <NavItem>
        <a className="nav-link" href="https://www.smartdraw.com/" target="_blank" rel="noopener noreferrer">Floor Plan - Smart Draw</a>
      </NavItem>
      <NavItem>
        <a className="nav-link" href="https://www.cubi.casa/" target="_blank" rel="noopener noreferrer">Floor Plan - Cubi Casa</a>
      </NavItem>
    </Nav>
    {
      state.pdfReports.map(
        (pdf, index)=>{
          return (
            <div key={index} className="file-container">
                <div className="font-bold">
                <div className="cursor-pointer display-inline link-color"><i className="fa fa-trash red-color" onClick={
                  ()=>{
                    confirmation(
                      ()=>{deleteFile(pdf.ID)},
                      ()=>{},
                      'Delete file?',
                      'Are you sure you want to delete this file?');
                  }
                }></i>&nbsp;<b><NavLink to={pdf.presign_url} target="_blank">{pdf.name}</NavLink></b>
                <br/>
                <i>{formatDateTime(pdf.datetime_created)}</i>
              </div>
              </div>
            </div>
          )
        }
      )
    }
  </div>;
}


export default PropertyDataReportPDF;
