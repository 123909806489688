//Author Sooyoung Kim
//Date May 31, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, putAPICallGenerator, getSession} from '../../../util/util';
import {Button, Modal, ModalBody, ModalHeader, Row, Col} from 'reactstrap';
import {NavLink, useLocation } from 'react-router-dom';
import './bottom-bar.css';
import Tracker from '../../tracker/tracker';
import Shipping from '../../shipping/shipping';
import PopUpFile from '../../file/popup-file';
import React, {useReducer, useEffect} from 'react';

//initialize the state
const initialState = {
  announcementPopUp:[],
  announcementPopUpOpen:false
};

//reducer function that perform state update
const reducer = getReducer();

const BottomBar  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});

  const location = useLocation();

  useEffect(() => {}, [location]);

  //run only once when component is loaded
  useEffect(()=>{
    if(props.isLoggedIn)
      getAnnouncement();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const toggleAnnouncement = () =>{
    setState({announcementPopUpOpen:!state.announcementPopUpOpen});
  }

  //API call
  const getAnnouncement = () =>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        setState({announcementPopUp:response.data.data, announcementPopUpOpen:response.data.data.length>0});
      }
    };
    callBack = callBack.bind(this);

    httpGet('admin/announcement/'+getSession().userID, '', '', callBack);
  }

  const updateSeenIndex = () =>{ 
    let callBack = (response)=>{console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        toggleAnnouncement();
      }
    };
    callBack = callBack.bind(this);
    httpPut('announcement/seenIndex', [], '', '', callBack);
  }

  //render
  let url = window.location.href;
  let className = 'invisible';
  let className2 = 'invisible';
  if(url.includes('/appraisal/')||url.includes('/condition/')||url.includes('/rebuttal/')||url.includes('/assignment/')||url.includes('/billing/')||url.includes('/review/')||url.includes('/property-details/'))
    className='';
  if(url.includes('/appraisal/')||url.includes('/condition/')||url.includes('/rebuttal/')||url.includes('/assignment/')||url.includes('/billing/')||url.includes('/review/')||url.includes('/property-details/'))
    className2='';

  let newAnnouncement;
  let newAnnouncementCount = 0;
  if(state.announcementPopUp.length>0){
    newAnnouncement = state.announcementPopUp.map(
      (announcement)=>{

        return(
          <div key={announcement.ID}>
            <div className="card">
      				<div className="card-header header-color">
    						<Row>
                  <Col sm="6">
      							<b>{announcement.title}</b>
      						</Col>
      						<Col className="align-right">
      							<b>{announcement.creator}&nbsp;-&nbsp;{announcement.datetime_created}</b>
      						</Col>
                </Row>
      				</div>

      				<div className="card-body">
      					<div dangerouslySetInnerHTML={{__html:announcement.body}}/>
      				</div>
      			</div>
            <br/>
          </div>
        );
      }
    );

    newAnnouncementCount = state.announcementPopUp.length;
  }

  return <div className="bottom-bar-container">
    <Modal className="my-modal-medium" isOpen={state.announcementPopUpOpen} toggle={toggleAnnouncement} >
      <ModalHeader hidden={true} toggle={toggleAnnouncement}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-bullhorn"></i> New Announcement</h5>
        </center>
        <div>
          <center>
            Hello, we have <font color="red">{newAnnouncementCount}</font> new announcement(s). You can view them <b>below</b> or go to <NavLink to="/announcement"><b>Announcement</b></NavLink>.
          </center>
          <br/>
          <div className="ultra-large-scroll-container">{newAnnouncement}</div>
        </div>
        <br/>
        <center>
          <Button color="info" onClick={toggleAnnouncement}>Read Later</Button>&nbsp;
          <Button color="warning" onClick={(e)=>updateSeenIndex()}>Confirm</Button>
        </center>
      </ModalBody>
    </Modal>
    <div className={"bottom-bar-sub-container "+className2}><PopUpFile logout={props.logout} showLoading={props.showLoading} hideLoading={props.hideLoading}/></div>
    &nbsp;
    <div className={"bottom-bar-sub-container "+className}><Tracker logout={props.logout} showLoading={props.showLoading} hideLoading={props.hideLoading}/></div>
    &nbsp;
    <div className={"bottom-bar-sub-container "+className}><Shipping logout={props.logout} showLoading={props.showLoading} hideLoading={props.hideLoading}/></div>
    &nbsp;
  </div>;
}

export default BottomBar;
