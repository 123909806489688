//Author Sooyoung Kim
//Date June 9, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, deleteAPICallGenerator, callBackGenerator, formatNumber, formatDateTime, showMessage, sliceFromArray, getFromTo, replaceRegex} from '../../../util/util';
import {Card, CardHeader, CardBody, Button, Modal, ModalHeader, ModalBody, Table, Row, Col, NavItem, Nav, TabContent, TabPane, NavLink as NavLinkL } from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import MyReactTable from '../../util/my-react-table';
import FileSaver from 'file-saver';
import moment from 'moment';
import {NavLink} from 'react-router-dom';

import ReportFilter from '../report-filter';

let from = getFromTo('from');
let to = getFromTo('to');

//initialize the state
const initialState = {
  from:from,
  to:to,
  activeTab:'1',

  report:{
    total_order:0,
    avg_distance:-1,
    highest_tat:-1,
    highest_tat_bd:-1,
    avg_tat:-1,
    avg_tat_bd:-1,
    lowest_tat:-1,
    lowest_tat_bd:-1,
    states:[],
    appraisal_types:[],
    total_appraiser_used:0,
    avg_appraiser_used:0,
    lowest_appraiser_used:0,
    highest_appraiser_used:0,
    condition_more_than_three:0,
    avg_condition:0,
    no_condition:0,
    condition_rate:0,

  },

  loanPurpose:'',
  accountManagers:[],
  accountManager:'',
  loanType:'',
  isRush:'',
  isComplex:'',
  state:'',
  city:'',
  entity:'',
  selectedAppraisalType:'',
  filterAppraisalTypes:[],
  clientBranches:[],
  clientBranch:0,
  clients:[],
  client:0,
  openByClient:0,

  existingAutoReport:[],
  reportDetails:[],
  details:'',
  reportDetailsDropDown:false,
  name:'',
  emailRecipient:''
};

//reducer function that perform state update
const reducer = getReducer();


const ClientStatistic  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getExistingAutoReport();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const reportDetailsToggle = ()=>{
    setState({reportDetailsDropDown:!state.reportDetailsDropDown});
  }

  const selectReportDetails = (details, appraisalTypeFilter='', stateFilter='')=>{
    setState({details:details, reportDetailsDropDown:true, reportDetails:[]});

    let appraisalType= '';
    if(appraisalTypeFilter===''){
      appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    }
    else{
      appraisalType = replaceRegex(appraisalTypeFilter);
    }

    let loanPurpose = replaceRegex(state.loanPurpose);
    let stateName = '';

    if(stateFilter===''){
      stateName = state.state;
    }
    else {
      stateName = stateFilter;
    }

    let param = 'details='+details+'&accountManager='+state.accountManager+'&client='+state.client+'&openBy='+state.openByClient+'&clientBranch='+state.clientBranch+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+stateName+'&city='+state.city+'&appraisalType='+appraisalType;
    getReportDetails(param);
  }

  const activeTabToggle = (tab)=>{
    setState({activeTab:tab});
  }

  //API call
  const getReport = ()=>{
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);

    let url = 'report/client/statistic/accountManager='+state.accountManager+'&client='+state.client+'&openBy='+state.openByClient+'&clientBranch='+state.clientBranch+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&appraisalType='+appraisalType;
    let callBack = apiCallBack([{state:'report', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  const getReportDetails = (param)=>{
    let callBack = apiCallBack([{state:'reportDetails', key:'data'}]);
    httpGet('report/client/statistic/details/'+param, '',  'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  const getExistingAutoReport = ()=>{
    let callBack = apiCallBack([{state:'existingAutoReport', key:'data'}]);
    httpGet('report/client/statistic/existing', '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  const downloadReportPDF = ()=>{
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);

    let url = 'report/client/statistic/pdf/accountManager='+state.accountManager+'&client='+state.client+'&openBy='+state.openByClient+'&clientBranch='+state.clientBranch+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&appraisalType='+appraisalType;

    let callBack = (response)=>{
      let byteCharacters = atob(response.data.data);
      let byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      let data = new Blob([byteArray]);
      FileSaver.saveAs(data, 'client-statistic.pdf');
    };
    callBack = callBack.bind(this);

    httpGet(url, '', 'Oops, something went wrong and could not download the report. Please try again later.', callBack);
  }

  const deleteAutoReport = (ID)=>{
    let list = sliceFromArray(state.existingAutoReport,'ID', ID);
    let callBack = apiCallBack([{state:'existingAutoReport', value:list}]);
    httpDelete('report/client/statistic/'+ID,'','Oops, something went wrong and could not delete the report setup, please try again later.', callBack);
  }

  const setupNewAutoReport = ()=>{
    if(state.name===''){
      showMessage('error', 'Please fill in the name of this auto report setup.');
    }
    else if(state.emailRecipient===''){
      showMessage('error', 'Please fill in the email recipient of this auto report setup.');
    }
    else{
      let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
      let loanPurpose = replaceRegex(state.loanPurpose);

      let param = 'accountManager='+state.accountManager+'&client='+state.client+'&openBy='+state.openByClient+'&clientBranch='+state.clientBranch+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&appraisalType='+appraisalType;

      let parameters = [
        {
          field:'name',
          value:state.name
        },
        {
          field:'emailRecipient',
          value:state.emailRecipient
        },
        {
          field:'param',
          value:param
        }
      ];

      let callBack = (response)=>{
        let code = response.data?response.data.code:undefined;
        if(code==='00'){
          let existingAutoReport = state.existingAutoReport.slice();
          existingAutoReport.push(response.data.data);

          setState({existingAutoReport:existingAutoReport});
        }
      };
      callBack = callBack.bind(this);
      httpPost('report/client/statistic/create', parameters, 'Auto monthly report setup successfully.', 'Oops, something went wrong and could not setup the auto report. Please try again later.', callBack);
    }
  }

  //render
  let detailsConfig = [
    {
      id: 'referenceNum',
      Header:'Reference #',
      accessor: d => d.reference_num,
      Cell: props => {
        return <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>
          {props.row.original.reference_num}
      </NavLink>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'loanNum',
      Header:'Loan #',
      accessor: d => d.loan_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'address',
      Header:'Property',
      accessor: d => d.property_street+' '+d.property_city+', '+d.property_state+' '+d.property_zip,
      headerStyle: {
        textAlign:'left'
      }
    },
  ];

  if(state.details==='distance'){
    detailsConfig.splice(2, 0 , {
      id: 'appraiser',
      Header:'Appraiser',
      accessor: d => d.first_name+' '+d.last_name,
      headerStyle: {
        textAlign:'left'
      }
    });

    detailsConfig.push({
      id: 'distance',
      Header:'Distance',
      accessor: d => d.distance,
      headerStyle: {
        textAlign:'left'
      },
      Cell: props => {
        return <div>
          {formatNumber(props.row.original.distance)} miles
        </div>
      },
    })
  }
  else if(state.details==='tat'){
    detailsConfig.push({
      id: 'tatBd',
      Header:'Turnaround Business Days',
      accessor: d => d.tat_bd,
      headerStyle: {
        textAlign:'left'
      },
      Cell: props => {
        return <div>
          {props.row.original.tat_bd!=='-1'?formatNumber(props.row.original.tat_bd):'-'} d
        </div>
      },
    });

    detailsConfig.push({
      id: 'tat',
      Header:'Turnaround Calendar Days',
      accessor: d => d.tat,
      headerStyle: {
        textAlign:'left'
      },
      Cell: props => {
        return <div>
          {props.row.original.tat!=='-1'?formatNumber(props.row.original.tat):'-'} d
        </div>
      },
    })
  }
  else if(state.details==='appraiser'){
    detailsConfig.splice(2, 0 , {
      id: 'appraiser',
      Header:'Appraiser',
      accessor: d => d.first_name+' '+d.last_name,
      headerStyle: {
        textAlign:'left'
      }
    });
  }
  else if(state.details==='appraiser_count'){
    detailsConfig = [
      {
        id: 'appraiser',
        Header:'Appraiser',
        accessor: d => d.first_name+' '+d.last_name,
        headerStyle: {
          textAlign:'left'
        }
      },
      {
        id: 'count',
        Header:'Count',
        accessor: d => d.count,
        headerStyle: {
          textAlign:'left'
        }
      }
    ];
  }
  else if(state.details==='condition'){
    detailsConfig.splice(2, 0 , {
      id: 'condition',
      Header:'Condition',
      accessor: d => d.condition_count,
      headerStyle: {
        textAlign:'left'
      }
    });
  }
  else if(state.details==='fee'){
    detailsConfig.splice(2, 0 , {
      id: 'fee',
      Header:'Fee',
      accessor: d => d.fee,
      Cell: props => {
        return <div>
          ${formatNumber(props.row.original.fee)}
        </div>
      },
      headerStyle: {
        textAlign:'left'
      }
    });
  }

  let appraisalTypes = [
    {
      id: 'appraisalType',
      Header:'Product',
      minWidth:550,
      width:550,
      accessor: d => d.external_label,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'totalOrder',
      Header:'Total Order',
      accessor: d => d.total_order,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'fee',
      Header:'Avg Fee',
      width:150,
      accessor: d => d.fee,
      Cell: props => {
        return <NavLink to="#" onClick={(e)=>selectReportDetails('fee',props.row.original.appraisal_type)}>
          ${formatNumber(props.row.original.fee)}
      </NavLink>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'tatBD',
      Header:'Turnaround Business Days',
      width:250,
      accessor: d => d.tat_bd,
      Cell: props => {
        return <NavLink to="#" onClick={(e)=>selectReportDetails('tat',props.row.original.appraisal_type)}>
          {props.row.original.tat_bd!==-1?formatNumber(props.row.original.tat_bd):'-'}
      </NavLink>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'tat',
      Header:'Turnaround Calendar Days',
      width:250,
      accessor: d => d.tat,
      Cell: props => {
        return <NavLink to="#" onClick={(e)=>selectReportDetails('tat',props.row.original.appraisal_type)}>
          {props.row.original.tat!==-1?formatNumber(props.row.original.tat):'-'}
      </NavLink>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
  ];

  let states = [
    {
      id: 'state',
      Header:'State',
      minWidth:550,
      width:550,
      accessor: d => d.state,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'totalOrder',
      Header:'Total Order',
      accessor: d => d.total_order,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'fee',
      Header:'Avg Fee',
      width:150,
      accessor: d => d.fee,
      Cell: props => {
        return <NavLink to="#" onClick={(e)=>selectReportDetails('fee','',props.row.original.state)}>
          ${formatNumber(props.row.original.fee)}
      </NavLink>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'tatBD',
      Header:'Turnaround Business Days',
      width:250,
      accessor: d => d.tat_bd,
      Cell: props => {
        return <NavLink to="#" onClick={(e)=>selectReportDetails('tat','',props.row.original.state)}>
          {props.row.original.tat_bd!==-1?formatNumber(props.row.original.tat_bd):'-'}
      </NavLink>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'tat',
      Header:'Turnaround Calendar Days',
      width:250,
      accessor: d => d.tat,
      Cell: props => {
        return <NavLink to="#" onClick={(e)=>selectReportDetails('tat','',props.row.original.state)}>
          {props.row.original.tat!==-1?formatNumber(props.row.original.tat):'-'}
      </NavLink>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
  ];

  let newfrom, newto;
  if(state.from!=='')
    newfrom = moment(state.from);
  if(state.to!=='')
    newto = moment(state.to);

  let iconPDF = <i className="fa fa-arrow-down"> PDF</i>;

  let reportFiltersConfig1 = [
    {id:'from',value:newfrom, updateFunc:(v)=>setState({from:v}), width:'2'},
    {id:'to',value:newto, updateFunc:(v)=>setState({to:v}), width:'2'},
    {id:'rush',value:state.isRush, updateFunc:(v)=>setState({isRush:v}), width:'2'},
    {id:'complex',value:state.isComplex, updateFunc:(v)=>setState({isComplex:v}), width:'2'},
    {id:'state',value:state.state, updateFunc:(v)=>setState({state:v}), width:'2'},
    {id:'city',value:state.city, updateFunc:(v)=>setState({city:v}), width:'2'},
    {id:'accountManager',value:state.accountManager, updateFunc:(v)=>setState({accountManager:v}), width:'2'},
    {id:'loanPurpose',value:state.loanPurpose, updateFunc:(v)=>setState({loanPurpose:v}), width:'2'},
    {id:'loanType',value:state.loanType, updateFunc:(v)=>setState({loanType:v}), width:'2'},
    {id:'entity',value:state.entity, updateFunc:(v)=>setState({entity:v}), width:'2'},
    {id:'appraisalType',value:state.filterAppraisalTypes, updateFunc:(v)=>setState({filterAppraisalTypes:v}), width:'4'},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'3'},
    {id:'openBy',value:state.openBy, updateFunc:(v)=>setState({openBy:v}), width:'3'},
    {id:'clientBranch',value:state.clientBranch, updateFunc:(v)=>setState({clientBranch:v}), width:'3'},
    {id:'button',value:'Submit', updateFunc:getReport, width:'2', className:"align-right", color:"warning"},
    {id:'button',value:iconPDF, updateFunc:downloadReportPDF, width:'1', className:"align-right", color:"info"}
  ];

  let reportFiltersConfig2 = [
    {id:'custom',value:state.name, updateFunc:(v)=>setState({name:v}), width:'2', label:'Name/Description'},
    {id:'custom',value:state.emailRecipient, updateFunc:(v)=>setState({emailRecipient:v}), width:'2', label:'Email Recipient'},
    {id:'rush',value:state.isRush, updateFunc:(v)=>setState({isRush:v}), width:'2'},
    {id:'complex',value:state.isComplex, updateFunc:(v)=>setState({isComplex:v}), width:'2'},
    {id:'state',value:state.state, updateFunc:(v)=>setState({state:v}), width:'2'},
    {id:'city',value:state.city, updateFunc:(v)=>setState({city:v}), width:'2'},
    {id:'accountManager',value:state.accountManager, updateFunc:(v)=>setState({accountManager:v}), width:'2'},
    {id:'loanPurpose',value:state.loanPurpose, updateFunc:(v)=>setState({loanPurpose:v}), width:'2'},
    {id:'loanType',value:state.loanType, updateFunc:(v)=>setState({loanType:v}), width:'2'},
    {id:'entity',value:state.entity, updateFunc:(v)=>setState({entity:v}), width:'2'},
    {id:'appraisalType',value:state.filterAppraisalTypes, updateFunc:(v)=>setState({filterAppraisalTypes:v}), width:'4'},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'3'},
    {id:'openBy',value:state.openBy, updateFunc:(v)=>setState({openBy:v}), width:'3'},
    {id:'clientBranch',value:state.clientBranch, updateFunc:(v)=>setState({clientBranch:v}), width:'3'},
    {id:'button',value:'Setup', updateFunc:setupNewAutoReport, width:'3', className:"align-right", color:"warning"},
  ];

  return <div>
    <Modal className="my-modal-wide" isOpen={state.reportDetailsDropDown} toggle={reportDetailsToggle} >
      <ModalHeader hidden={true} toggle={reportDetailsToggle}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-user"></i> Report Details</h5>
        </center>
        <br/>
        <div className="large-scroll-container">
          <MyReactTable columns={detailsConfig} data={state.reportDetails} className="table table-striped"/>
        </div>
        <br/>
        <center>
          <Button color="info" onClick={reportDetailsToggle}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
    <Nav tabs>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.activeTab === '1'?"active":"inactive" )}
          onClick={() => { activeTabToggle('1'); }}
          to="#"
        >
          Report
        </NavLinkL>
      </NavItem>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.activeTab === '2'?"active":"inactive" ) }
          onClick={() => { activeTabToggle('2'); }}
          to="#"
        >
          Existing Report Setup  <span className="my-badge">{state.existingAutoReport.length}</span>
        </NavLinkL>
      </NavItem>
    </Nav>
    <TabContent activeTab={state.activeTab}>
      <TabPane tabId="1">
        <ReportFilter {...props} configs={reportFiltersConfig1}/>
        <div className="my-divider">&nbsp;</div>
        <Row>
          <Col sm="3">
            <Card>
              <CardHeader>Order Volume</CardHeader>
              <CardBody style={{height:'200px'}}>
                <center>
                  <br/>
                  <br/>
                  <NavLink to="#" style={{fontSize:'35px'}} onClick={(e)=>selectReportDetails('total_order')}>{state.report.total_order}</NavLink><br/>
                  <font style={{fontSize:'12px'}}>orders</font>
                </center>
              </CardBody>
            </Card>
          </Col>
          <Col sm="3">
            <Card>
              <CardHeader>Avg Vendor Distance</CardHeader>
              <CardBody style={{height:'200px'}}>
                <center>
                  <br/>
                  <br/>
                  <NavLink to="#" style={{fontSize:'35px'}} onClick={(e)=>selectReportDetails('distance')}>{formatNumber(state.report.avg_distance)}</NavLink><br/>
                  <font style={{fontSize:'12px'}}>miles</font>
                </center>
              </CardBody>
            </Card>
          </Col>
          <Col sm="6">
            <Card>
              <CardHeader>Turnaround Time</CardHeader>
              <CardBody style={{height:'200px'}}>
                <Table>
                  <tr>
                    <td></td>
                    <td style={{fontWeight:'bold'}}>Business Days</td>
                    <td style={{fontWeight:'bold'}}>Calendar Days</td>
                  </tr>
                  <tr>
                    <td style={{fontWeight:'bold'}}>Highest</td>
                    <td><NavLink to="#" onClick={(e)=>selectReportDetails('tat')}>{formatNumber(state.report.highest_tat_bd)}</NavLink></td>
                    <td><NavLink to="#" onClick={(e)=>selectReportDetails('tat')}>{formatNumber(state.report.highest_tat)}</NavLink></td>
                  </tr>
                  <tr>
                    <td style={{fontWeight:'bold'}}>Average</td>
                    <td><NavLink to="#" onClick={(e)=>selectReportDetails('tat')}>{formatNumber(state.report.avg_tat_bd)}</NavLink></td>
                    <td><NavLink to="#" onClick={(e)=>selectReportDetails('tat')}>{formatNumber(state.report.avg_tat)}</NavLink></td>
                  </tr>
                  <tr>
                    <td style={{fontWeight:'bold'}}>Lowest</td>
                    <td><NavLink to="#" onClick={(e)=>selectReportDetails('tat')}>{formatNumber(state.report.lowest_tat_bd)}</NavLink></td>
                    <td><NavLink to="#" onClick={(e)=>selectReportDetails('tat')}>{formatNumber(state.report.lowest_tat)}</NavLink></td>
                  </tr>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <br/>
        <Row>
          <Col sm="6">
            <Card>
              <CardHeader>Vendor Turnover</CardHeader>
              <CardBody style={{height:'100px'}}>
                <Row>
                  <Col sm="4">
                    <center>
                      <NavLink to="#" style={{fontSize:'35px'}} onClick={(e)=>selectReportDetails('appraiser')}>{state.report.total_appraiser_used}</NavLink><br/>
                      <font style={{fontSize:'12px'}}>vendors used</font>
                    </center>
                  </Col>
                  <Col sm="8" style={{fontSize:'13px'}}>
                    <Row>
                      <Col sm="8">
                        - Avg order per vendor
                      </Col>
                      <Col sm="4">
                        <NavLink to="#" onClick={(e)=>selectReportDetails('appraiser_count')}>{formatNumber(state.report.avg_appraiser_used)}</NavLink>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="8">
                        - Highest order per vendor
                      </Col>
                      <Col sm="4">
                        <NavLink to="#" onClick={(e)=>selectReportDetails('appraiser_count')}>{formatNumber(state.report.highest_appraiser_used)}</NavLink>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="8">
                        - Lowest order per vendor
                      </Col>
                      <Col sm="4">
                        <NavLink to="#" onClick={(e)=>selectReportDetails('appraiser_count')}>{formatNumber(state.report.lowest_appraiser_used)}</NavLink>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col sm="6">
            <Card>
              <CardHeader>Revision</CardHeader>
              <CardBody style={{height:'100px'}}>
                <Row>
                  <Col sm="4">
                    <center>
                      <NavLink to="#" style={{fontSize:'35px'}} onClick={(e)=>selectReportDetails('condition')}>{state.report.condition_rate}</NavLink>
                      <font style={{fontSize:'12px'}}>%</font>
                    </center>
                  </Col>
                  <Col sm="8" style={{fontSize:'13px'}}>
                    <Row>
                      <Col sm="8">
                        - Report without revision
                      </Col>
                      <Col sm="4">
                        <NavLink to="#" onClick={(e)=>selectReportDetails('condition')}>{state.report.no_condition}</NavLink>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="8">
                        - 3 or more revision
                      </Col>
                      <Col sm="4">
                        <NavLink to="#" onClick={(e)=>selectReportDetails('condition')}>{formatNumber(state.report.condition_more_than_three)}</NavLink>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="8">
                        - Avg revision per condition order
                      </Col>
                      <Col sm="4">
                        <NavLink to="#" onClick={(e)=>selectReportDetails('condition')}>{formatNumber(state.report.avg_condition)}</NavLink>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <br/>
        <Card>
          <CardHeader>Order Volume By Product</CardHeader>
          <CardBody>
            <MyReactTable columns={appraisalTypes} data={state.report.appraisal_types} className="table table-striped"/>
          </CardBody>
        </Card>
        <br/>
        <Card>
          <CardHeader>Order Volume By State</CardHeader>
          <CardBody>
            <MyReactTable columns={states} data={state.report.states} className="table table-striped"/>
          </CardBody>
        </Card>
      </TabPane>
      <TabPane tabId="2">
        <ReportFilter {...props} configs={reportFiltersConfig2}/>
        <div className="my-divider">&nbsp;</div>
        <Table className="table table-striped">
          <thead>
            <tr>
              <th>Action</th>
              <th>Name/Description</th>
              <th>Email Recipient</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {
              state.existingAutoReport.map(
                (report, index)=>{
                  return <tr key={index}>
                    <td><i onClick={(e)=>deleteAutoReport(report.ID)} className="fa fa-times red-color cursor-pointer"/></td>
                    <td>{report.name}</td>
                    <td>{report.recipient}</td>
                    <td>{formatDateTime(report.datetime_created)}</td>
                  </tr>
                }
              )
            }
          </tbody>
        </Table>
      </TabPane>
    </TabContent>
  </div>;
}

export default ClientStatistic;
