//Author Sooyoung 
//Date Aug 21, 2023
import {getReducer, getSetStateFunction, generateTooltip} from '../../util/util';
import {Card, CardHeader, CardBody, Col, Row, Input} from 'reactstrap';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import MyPlacesAutocomplete from '../util/my-places-autocomplete';
import React, {useReducer, useEffect} from 'react';
import MySelect from '../util/my-select';

//initialize the state
const initialState = {
  address:'',
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
  hoa:''
};

//reducer function that perform state update
const reducer = getReducer();

const StepPropertyInformation  = (props)=>{
  const controller = new AbortController();
  console.log(props);

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const googlePlaceOnChange = (address) => {
    if(address!==state.address){
      if(address.indexOf(',')!==-1 && address.length>10){
        geocodeByAddress(address)
          .then(
            results => {
              //always use the first returned result
              let targetResult = results[0];

              let addressComponents = targetResult.address_components;

              let streetNumber = '';
              let route = '';
              let city = '';
              let county = '';
              let state = '';
              let zip = '';

              //go through the address components and grab the street, city, county, state, and zip
              for(let i=0;i<addressComponents.length;i++){
                if(addressComponents[i].types[0]==='street_number')
                  streetNumber = addressComponents[i].long_name;
                else if(addressComponents[i].types[0]==='route')
                  route = addressComponents[i].long_name;
                else if(addressComponents[i].types[0]==='locality'&&addressComponents[i].types[1]==='political')
                  city = addressComponents[i].long_name;
                else if(addressComponents[i].types[0]==='administrative_area_level_2'&&addressComponents[i].types[1]==='political')
                  county = addressComponents[i].long_name;
                else if(addressComponents[i].types[0]==='administrative_area_level_1'&&addressComponents[i].types[1]==='political')
                  state = addressComponents[i].long_name;
                else if(addressComponents[i].types[0]==='postal_code')
                  zip = addressComponents[i].long_name;
              }


              let street = '';

              if(streetNumber!==''&&route!=='')
                street = streetNumber+' '+route;

              //get long lat
              getLatLng(targetResult)
                .then(
                  result=>{
                    if(result){
                      let lat = result.lat;
                      let lng = result.lng;
                      console.log(lng);

                      props.updateState({propertyLat:lat, propertyLng:lng});
                    }
                  }
                )
                .catch(
                  error=>{
                    console.log(error);
                  }
                );
              let stateToUpdate = {};

              if(street!==''){
                stateToUpdate.propertyStreet = street;
                stateToUpdate.propertyAddress = street;
                setState({address:street});
              }
              if(city!=='')
                stateToUpdate.propertyCity = city;
              if(county!=='')
                stateToUpdate.propertyCounty = county;
              if(state!=='')
                stateToUpdate.propertyState = state;
              if(zip!=='')
                stateToUpdate.propertyZip = zip;
              console.log(stateToUpdate);
              props.updateState(stateToUpdate);
            })
          .catch(
            error => {
              console.log(error);
            }
          );
      }
      else{
        setState({address:address});
        props.updateState({propertyAddress:address, propertyStreet:address});
      }
    }
  }

  //render
  let disabledStyling = {border:'none', background:'#fff', padding:'0',paddingLeft:'2px', height:'auto',WebkitAppearance:'none'};
  let hoa;

  if(props.hoaFee>0){
    hoa = <Col sm="2">
      <label><font color="red">*</font>HOA</label>
      <MySelect
        type="select"
        style={props.disabled?disabledStyling:{}}
        disabled={props.disabled}
        required={true}
        value={props.hoa}
        onChange={(v)=>{props.updateState({hoa:v});setState({hoa:v})}}
        options={[
          {label:"",value:""},
          {label:"Annual",value:"Annual"},
          {label:"Monthly",value:"Monthly"},
        ]}
      />
    </Col>;
  }

  let condoType;
  let condoOtherDescription;
  if(props.propertyType==='Condo'){
    condoType = <Col sm="3">
      <label><font color="red">*</font>Condo type</label>
      <MySelect
        type="select"
        style={props.disabled?disabledStyling:{}}
        disabled={props.disabled}
        required={true}
        value={props.condoType}
        onChange={(v)=>props.updateState({condoType:v})}
        options={[
          {label:"",value:""},
          {label:"Detached",value:"Detached"},
          {label:"Row or Townhouse",value:"Row or Townhouse"},
          {label:"Garden",value:"Garden"},
          {label:"Mid-Rise",value:"Mid-Rise"},
          {label:"High-Rise",value:"High-Rise"},
          {label:"Other",value:"Other"},
        ]}
      />
    </Col>;
  }

  if(props.condoType==='Other'){
    condoOtherDescription = <Col sm="3">
      <label><font color="red">*</font>Condo Other Description</label>
      <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={true} type="text" value={props.condoOtherDescription} onChange={(e)=>props.updateState({condoOtherDescription:e.target.value})}/>
    </Col>
  }

  return <div>
    <Card>
      <CardHeader className="header-color">
        Property Information
      </CardHeader>
      <CardBody>
        <Row style={{marginTop:'15px'}}>
          <Col sm="5">
            <label><font color="red">*</font>Street</label>
            <MyPlacesAutocomplete value={props.propertyStreet} onChange={googlePlaceOnChange} onSelect={googlePlaceOnChange}/>
          </Col>
          <Col sm="3">
            <label><font color="red">*</font>City</label>
            <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={true} type="text" value={props.propertyCity} onChange={(e)=>props.updateState({propertyCity:e.target.value})}/>
          </Col>
          <Col sm="4">
            <label><font color="red">*</font>State</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={true}
              value={props.propertyState}
              onChange={(v)=>props.updateState({propertyState:v})}
              options={state.states.map((state)=>{
                return {label:state.value, value:state.value};
              })}
            />
          </Col>
        </Row>
        <Row style={{marginTop:'15px'}}>
          <Col sm="3">
            <label><font color="red">*</font>County</label>
            <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} type="text" value={props.propertyCounty} onChange={(e)=>props.updateState({propertyCounty:e.target.value})}/>
          </Col>
          <Col sm="2">
            <label><font color="red">*</font>Zip</label>
            <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={true} type="text" value={props.propertyZip} onChange={(e)=>props.updateState({propertyZip:e.target.value})}/>
          </Col>
          <Col sm="3">
            <label><font color="red">*</font>Property type</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={true}
              value={props.propertyType}
              onChange={(v)=>props.updateState({propertyType:v})}
              options={[
                {label:"",value:""},
                {label:"SFD",value:"SFD"},
                {label:"Condo",value:"Condo"},
                {label:"Manufactured",value:"Manufactured"},
                {label:"2-4 Unit",value:"2-4 Unit"},
                {label:"Cooperative",value:"Cooperative"},
              ]}
            />
          </Col>
          {condoType}
          {condoOtherDescription}
        </Row>
        <Row style={{marginTop:'15px'}}>
          <Col sm="3">
            <label><font color="red">*</font>HOA Fees</label>
            <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={true} type="number" value={props.hoaFee} onChange={(e)=>props.updateState({hoaFee:e.target.value})}/>
          </Col>
          {hoa}
          <Col sm="3">
            <label><font color="red">*</font>Years Owned {generateTooltip('yearsOwned', 'Indicates years owned by Borrower. Include decimal to indicate fractional. For purchase, enter 0.')}</label>
            <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={true} type="number" value={props.yearsOwned} onChange={(e)=>{props.updateState({yearsOwned:e.target.value})}}/>
          </Col>
          <Col sm="4">
            <label><font color="red">*</font>Neighborhood</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={true}
              value={props.neighborhoodType}
              onChange={(v)=>props.updateState({neighborhoodType:v})}
              options={[
                {label:"",value:""},
                {label:"Unknown",value:"Unknown"},
                {label:"Urban",value:"Urban"},
                {label:"Suburban",value:"Suburban"},
                {label:"Rural",value:"Rural"},
              ]}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  </div>;
}


export default StepPropertyInformation;
