//Author Sooyoung Kim
//Date May 31,2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, callBackGenerator, showMessage} from '../../../util/util';
import { ButtonDropdown, DropdownToggle, Row, Col, DropdownMenu, DropdownItem, Input} from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
//import FeedsBar from '../../notification/feeds-bar';
import NotificationContainer from '../../notification/notification-container';
import {NavLink, useNavigate} from 'react-router-dom';
import React, {useReducer, useEffect, useRef} from 'react';
import './top-bar.css';

//initialize the state
const initialState = {
  dropdownOpen: false,
  searchKeyword:'',
  searchCollapse:true,
  searchHistoryOpen:false,
  searchResults:[],
  searching:false,
  id: -1,
  logs:[],
  limit: 20,
  offset: 0,
  loading: false,
  hasMoreLogs:true
};

//reducer function that perform state update
const reducer = getReducer();


const TopBar  = (props)=>{
  const controller = new AbortController();
  const history = useNavigate();
  const dropdownRef = useRef(null);
  const [state, dispatch] = useReducer(reducer,initialState);


  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    document.addEventListener('click', handleOutsideClick, true);

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    if(state.logs.length<=0 && state.hasMoreLogs && state.searchHistoryOpen){
        loadMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state]);

  //non API call but simpyl manage state
  const handleOutsideClick = (event) =>{
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setState({offset:0, loading:true, hasMoreLogs:true, searchHistoryOpen: false});
      setTimeout(()=>setState({loading:false}),1000);
    }
  }

  const refreshList = () =>{
    setState({logs:[], hasMoreLogs:true, offset:0});
  }

  const onClickHandler = (e) =>{
    e.stopPropagation();
    if(!state.searchHistoryOpen){
      refreshList();
      loadMore();
      setState({searchHistoryOpen:true});
    }
  }

  const onChangeHandler = (e) =>{
    setState({searchKeyword:e.target.value});

    if(e.target.value===''){
      setState({searchCollapse:true,searchResults:[]});
    }
  }

  const keyPressHandler = (e) =>{
    if(e.keyCode === 13){
      setState({searchCollapse:false, searching:true});
      search();
    }
  }

  const resetSearch = () =>{
    setState({searching:false, searchCollapse:true, searchKeyword:'', searchResults:[]});
  }

  const toggle = () =>{
    setState({dropdownOpen: !state.dropdownOpen});
  }

  const logoutHandler = () =>{
    let control = {
      showLoading: props.showLoading,
      hideLoading: props.hideLoading,
      logout: props.logout
    };
    props.logout(control);
  }

  //API call
  const search = () =>{
    if(state.message!==''){
      let parameters = [{field:'keyword',value:state.searchKeyword}];

      let callBack = (response)=>{
        let code = response.data?response.data.code:undefined;
        if(code==='00'){
          setState({searchResults:response.data.data, searching:false});

          if(state.searchKeyword.length <= 4){
            showMessage('success','Searched by borrower name for keywords 4 characters or less.');
          }
        }
      };
      callBack = callBack.bind(this);

      let errorCallBack = apiCallBack([{state:'searching', value:false}]);
      httpPost('appraisal/keyword/search', parameters, '', 'Oops, something went wrong and could not search appraisal. Please try again later.', callBack, errorCallBack);
    }
  }

  const loadMore = () =>{
    //do not load if there is no more conditions or it's loading data
    if(state.hasMoreLogs&&!state.loading){
      //set loading equals to true so it won't fire off before we are done
      setState({loading:true});
      //callback handler that update the state when http request return
      let callBack = (response)=>{
        let code = response.data?response.data.code:undefined;

        if(code!=='00'){
          setState({hasMoreLogs:false});
        }
        else{
          if(state.searchHistoryOpen){
            let newLogs = response.data.data;
            let hasMoreLogs = true;
            let newOffset = state.offset;
            let totalCount = response.data.count;

            //if http request return empty then no more results, end of list
            if(newLogs.length<=0){
              hasMoreLogs = false;
            }
            else{
              //increment the offset
              newOffset = state.offset + 1;
            }

            //concat the current array of announcement
            if(state.logs.length>0){
              let temp = [...state.logs,...newLogs];

              setState({logs:temp});
            }
            else
              setState({logs:newLogs});

            setState({hasMoreLogs:hasMoreLogs, offset:newOffset, totalCount:totalCount});
          }
        }
      };
      callBack = callBack.bind(this);

      //error handler when the http request return with error
      let errorCallBack = apiCallBack([{state:'hasMoreLogs', value:false}]);

      //collect the promise and wait for it to finish performing it's task
      let promises = httpGet('admin/activity/limit='+state.limit+'&offset='+state.offset+'&search=appraisal_fk', '', 'Oops, something went wrong and could not load users. Please try again later.', callBack, errorCallBack);
      promises
        .then(
          function(result){
            //set loading equals to false so the function could be fire off once again
            setState({loading:false});
          }
        );
    }
  }

  //render
  let collapseList;
  let result;

  if(state.searching===true){
    collapseList = <div className="search-container">
      <div className="search-result">
        <b>Searching for appraisal...</b>
      </div>
    </div>;
  }
  else if(state.searchCollapse===false){
    if(state.searchResults.length<=0){
      result = <div className="search-result">
        <b>No appraisal found.</b>
      </div>;
    }
    else {
      result = state.searchResults.map(
        (row, index)=>{
          return <div key={index}>
            <div className="search-result">
              <NavLink to={"/appraisal/"+row.ID} target="_blank" onClick={resetSearch} className="align-left">
                <div className="search-result-header">{row.reference_num} - {row.loan_num} - {row.borrower_f_name+' '+row.borrower_l_name}</div>
                <div className="search-result-details">{row.property_street+' '+row.property_city+', '+row.property_state+' '+row.property_zip}</div>
              </NavLink>
            </div>
          </div>
        }
      );
    }

    collapseList = <div className="search-container">
      {result}
    </div>;
  }else if(state.searchHistoryOpen){
    if(state.logs.length<=0){
      result = <div className="search-result">
      </div>;
    }
    else {
      result = state.logs.map(
        (log, index)=>{
          let message = log.message.split(' - ');

          return <div key={index}>
            <div className="search-result">
              <NavLink to={"/appraisal/"+log.appraisal_fk} target="_blank" onClick={resetSearch} className="align-left">
                <div className="search-result-header">{message[0]} - {message[1]} - {message[2]}</div>
                <div className="search-result-details">{message[3]}</div>
              </NavLink>
            </div>
          </div>
        }
      );
    }

    collapseList = <div ref={dropdownRef} className="search-container smaller">
      <div className="search-history">
        <b>Recently Viewed Orders</b>
      </div>
      <InfiniteScroll
        next={loadMore}
        dataLength={state.logs.length}
        hasMore={state.hasMoreLogs}
        loader={<div key="nill" className="loader"><center>Loading more activity history...</center></div>}
        initialLoad = {true}
        className="my-well"
        scrollableTarget="contentContainer"
      >
      {result}
      </InfiniteScroll>
    </div>;
  }

  return <div className="topbar">
    <Row style={{marginLeft:'0px',marginRight:'0px'}}>
      <Col sm="3">
        <div className="topbar-logo-container">
          <NavLink to="/announcement"><img className="topbar-logo-img" alt="@Home VMS Logo" width="120px" src="/img/logo_small.png"/></NavLink>
        </div>
      </Col>
      <Col sm="9">
        <div className="align-right">
          <div className="topbar-action-panel-container">
            <div className="topbar-action-panel" style={{width:'400px'}}>
              <Input type="text" style={{marginTop:'5px'}} onClick={onClickHandler} onKeyDown={keyPressHandler} placeholder="Search by keyword..." onChange={onChangeHandler} value={state.searchKeyword}/>
              <div className="search-container-parent">{collapseList}</div>
            </div>
            <div className="topbar-action-panel">
              <NotificationContainer pusher={props.pusher} logout={props.logout} showLoading={props.showLoading} hideLoading={props.hideLoading}/>
            </div>
            <div className="topbar-action-panel">
              <div className="valign-middle cursor-pointer" onClick={(e)=>history('/support')}><i className="topbar-icon fa fa-question-circle"></i>&nbsp;Help & Support</div>
            </div>
            <div className="topbar-action-panel">
              <ButtonDropdown className="topbar-action-panel-dropdown" isOpen={state.dropdownOpen} toggle={toggle}>
                <DropdownToggle className="topbar-action-panel-dropdown-button" caret>
                  <i className="fa fa-user topbar-icon"></i>&nbsp;&nbsp;{props.userFirstName} {props.userLastName}&nbsp;
                </DropdownToggle>
                <DropdownMenu className="topbar-action-panel-dropdown-menu" right>
                  <DropdownItem className="topbar-action-panel-dropdown-item" onClick={()=>history("/setting")}><i className="fa fa-gear topbar-icon"></i>&nbsp;&nbsp;Settings</DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem className="topbar-action-panel-dropdown-item" onClick={logoutHandler}><i className="fa fa-power-off topbar-icon"></i>&nbsp;&nbsp;Sign Out</DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  </div>;
}

export default TopBar;
