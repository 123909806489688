// Sooyoung Kim
// June 16, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, formatNumber, formatDate, getFromTo, replaceRegex} from '../../../util/util';
import {Button, Card, CardHeader, CardBody, Col, Row, Modal, ModalHeader, ModalBody} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import ReportFilter from '../report-filter';
import moment from 'moment';
import {NavLink} from 'react-router-dom';
import '../report.css';

//initialize the state
const initialState = {
  flatFee:500,
  accrued: 'no',
  loanPurpose:'',
  loanType:'',
  isRush:'',
  isComplex:'',
  state:'',
  city:'',
  entity:'',
  client:'',
  openBy:'',
  selectedAppraisalType:'',
  filterAppraisalTypes:[],
  from:getFromTo('start'),
  to:getFromTo('end'),

  revenue:{
  },
  expense:{
  },
  still_owe_as_of:'',
  transaction_not_match:0,
  revenue_order_count:0,
  expense_order_count:0,

  reportDetailsToggle:false,
  reportDetails:'',
  reportDetailsOrders:[],
  appraisers:[],
  appraiser:'',
};

//reducer function that perform state update
const reducer = getReducer();


const FlatFeeSreadEstimation  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getReport();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const reportDetailsToggle = ()=>{
    if(state.reportDetailsDropDown)
      setState({reportDetailsOrders:[]});
    setState({reportDetailsDropDown:!state.reportDetailsDropDown});
  }

  //API call
  const getReport = ()=>{
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);

    let url = 'report/flat/fee/flatFee='+state.flatFee+'&accrued='+state.accrued+'&openBy='+state.openBy+'&client='+state.client+'&appraiser='+state.appraiser+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&appraisalType='+appraisalType;
    let callBack = apiCallBack([{state:'revenue_order_count', key:'data.revenue_order_count'}, {state:'expense_order_count', key:'data.expense_order_count'}, {state:'revenue', key:'data.revenue'}, {state:'expense', key:'data.expense'}, {state:'still_owe_as_of', key:'data.still_owe_as_of'}, {state:'transaction_not_match', key:'data.transaction_not_match'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  const getReportDetails = (report,method, covered,type)=>{
    reportDetailsToggle();
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);

    let url = 'report/flat/fee/details/order/flatFee='+state.flatFee+'&accrued='+state.accrued+'&openBy='+state.openBy+'&client='+state.client+'&appraiser='+state.appraiser+'&reportType='+type+'&loanPurpose='+loanPurpose+'&report='+report+'&method='+method+'&covered='+covered+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&appraisalType='+appraisalType;
    let callBack = apiCallBack([{state:'reportDetailsOrders', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load the report details. Please try again later.', callBack);
  }

  //render
  let from, to;
  if(state.from!=='')
    from = moment(state.from);
  if(state.to!=='')
    to = moment(state.to);

  let content;

  if(state.reportDetailsOrders.length>0){
    let rows = state.reportDetailsOrders.map(
      (order, index)=>{
        return(
          <tr key={index}>
            <td><NavLink target="_blank" to={"/appraisal/"+order.ID}>{order.reference_num}</NavLink></td>
            <td>{formatDate(order.datetime_submitted)}</td>
            <td>{order.company}</td>
            <td>{order.property_street+' '+order.property_city+', '+order.property_state+' '+order.property_zip}</td>
            <td>{order.loan_num}</td>
            <td>{order.borrower_f_name+' '+order.borrower_l_name}</td>
            <td>{order.appraisal_type}</td>
            <td>{order.loan_type}</td>
            <td>${formatNumber(order.fee)}</td>
          </tr>
        )
      }
    );

    content =
    <table className="table table-striped">
      <thead>
        <tr>
          <th width="10%">Reference #</th>
          <th width="10%">Date Submitted</th>
          <th width="10%">Client</th>
          <th width="14%">Address</th>
          <th width="12%">Loan #</th>
          <th width="12%">Borrower</th>
          <th width="15%">Report Type</th>
          <th width="8%">Loan type</th>
          <th width="8%">Fee</th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </table>
  }

  let expenseTotal = 0 , revenueTotal = 0;
  let exPaid = 0, exStandby = 0, exCancelled = 0, exPending =0, exHold = 0;

  if(state.expense){
    for(let s in state.expense){
      for(let m in state.expense[s]){
        if(s==='paid')
          exPaid = exPaid + parseInt(state.expense[s][m],10);
        if(s==='standby')
          exStandby = exStandby + parseInt(state.expense[s][m],10);
        if(s==='pending')
          exPending = exPending + parseInt(state.expense[s][m],10);
        if(s==='cancelled')
          exCancelled = exCancelled + parseInt(state.expense[s][m],10);
        if(s==='hold')
          exHold = exHold + parseInt(state.expense[s][m],10);
      }
    }
  }

  if(state.revenue){
    console.log(state);
    revenueTotal = state.revenue.total;
  }

  expenseTotal = expenseTotal + parseInt(exPending,10);
  expenseTotal = expenseTotal + parseInt(exPaid,10);
  expenseTotal = expenseTotal + parseInt(exStandby,10);

  let reportFiltersConfig = [
    {id:'from',value:from, updateFunc:(v)=>setState({from:v}), width:'3'},
    {id:'to',value:to, updateFunc:(v)=>setState({to:v}), width:'3'},
    {id:'custom',value:state.flatFee, updateFunc:(v)=>setState({flatFee:v}), width:'3', label:'Flat Fee'},
    {id:'customSelect',value:state.accrued, updateFunc:(v)=>setState({accrued:v}), width:'3', label:'Accrued Based', clearable:false, className:"", options:[{label:"No",value:"no"},{label:"Yes",value:"yes"}]},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'3'},
    {id:'openBy',value:state.openBy, updateFunc:(v)=>setState({openBy:v}), width:'3'},
    {id:'appraiser',value:state.appraiser, updateFunc:(v)=>setState({appraiser:v}), width:'3'},
    {id:'entity',value:state.entity, updateFunc:(v)=>setState({entity:v}), width:'3'},
    {id:'rush',value:state.isRush, updateFunc:(v)=>setState({isRush:v}), width:'3'},
    {id:'complex',value:state.isComplex, updateFunc:(v)=>setState({isComplex:v}), width:'3'},
    {id:'state',value:state.state, updateFunc:(v)=>setState({state:v}), width:'3'},
    {id:'city',value:state.city, updateFunc:(v)=>setState({city:v}), width:'3'},
    {id:'loanPurpose',value:state.loanPurpose, updateFunc:(v)=>setState({loanPurpose:v}), width:'3'},
    {id:'loanType',value:state.loanType, updateFunc:(v)=>setState({loanType:v}), width:'3'},
    {id:'appraisalType',value:state.filterAppraisalTypes, updateFunc:(v)=>setState({filterAppraisalTypes:v}), width:'3'},
    {id:'button',value:'Submit', updateFunc:getReport, width:'3', className:"align-right", color:"warning"},
  ];

  return <div>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Fee Report
      </CardHeader>
      <CardBody>
        <ReportFilter {...props} configs={reportFiltersConfig}/>
      </CardBody>
    </Card>
    <br/>
    <i>*Spread calculations are based on all submitted orders within a calendar month or inputted date parameters and exclude the following billing status - "Charge Back", "Cancelled","Hold" and "Loss"</i>
    <br/>
    <i>*For all Non-Accrual based calculations are for orders with paid receivables and payables within the calendar month or inputted date parameters.</i>
    <br/>
    <br/>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Report
      </CardHeader>
      <CardBody>
        <Row>
          <Col sm="6" style={{borderRight:'1px solid #d2d2d2'}}>
            <Row>
              <Col sm="8">
                <label>Total</label>
              </Col>
              <Col sm="4" className="align-right">
                <NavLink to="#" onClick={(e)=>getReportDetails('','','','revenue')}>${formatNumber(revenueTotal)}</NavLink>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col sm="12">
                <b><center><font color="red">Expense</font></center></b>
              </Col>
            </Row>

            <Row>
              <Col sm="8">
                <label>To be Paid (Payable)</label>
              </Col>
              <Col sm="4" className="align-right">
                <NavLink to="#" onClick={(e)=>getReportDetails('pending','','','expense')}>${formatNumber(exPending)}</NavLink>
              </Col>
            </Row>

            <Row>
              <Col sm="8">
                <label>Paid</label>
              </Col>
              <Col sm="4" className="align-right">
                <NavLink to="#" onClick={(e)=>getReportDetails('paid','','','expense')}>${formatNumber(exPaid)}</NavLink>
              </Col>
            </Row>
            <Row>
              <Col sm="8">
                <label>Standby</label>
              </Col>
              <Col sm="4" className="align-right">
                <NavLink to="#" onClick={(e)=>getReportDetails('standby','','','expense')}>${formatNumber(exStandby)}</NavLink>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <Row>
              <Col sm="6">
                <b>Grand total</b>
              </Col>
              <Col sm="6" className="align-right" style={{fontWeight:'bold',borderBottom:'1px solid #ccc',borderTop:'1px solid #ccc'}}>
                ${formatNumber(revenueTotal)}
              </Col>
            </Row>
          </Col>
          <Col sm="6">
            <Row>
              <Col sm="6">
                <b>Grand total</b>
              </Col>
              <Col sm="6" className="align-right" style={{fontWeight:'bold',borderBottom:'1px solid #ccc',borderTop:'1px solid #ccc'}}>
                ${formatNumber(expenseTotal)}
              </Col>
            </Row>
          </Col>
        </Row>
        <br/>
        <Row>
          <Col sm="6"></Col>
          <Col sm="6">
            <Row>
              <Col sm="6">
                <b>**Net Revenue**</b>
              </Col>
              <Col sm="6" className="align-right" style={{fontWeight:'bold',borderBottom:'1px solid #ccc',borderTop:'1px solid #ccc'}}>
                ${formatNumber(revenueTotal-expenseTotal)}
              </Col>
            </Row>
          </Col>
        </Row>
        <br/>
        <Row>
          <Col sm="6">
            <Row>
              <Col sm="8">
                <label>Total order associated</label>
              </Col>
              <Col sm="4" className="align-right">
                {formatNumber(state.revenue_order_count)}
              </Col>
            </Row>
          </Col>
          <Col sm="6">
            <Row>
              <Col sm="8">
                <label>Total order associated</label>
              </Col>
              <Col sm="4" className="align-right">
                {formatNumber(state.expense_order_count)}
              </Col>
            </Row>
            <Row>
              <Col sm="8">
                <label>Avg Spread</label>
              </Col>
              <Col sm="4" className="align-right">
                ${formatNumber(((revenueTotal-expenseTotal)/state.expense_order_count))}
              </Col>
            </Row>
          </Col>
        </Row>
        <br/><br/>

        <br/>
        <Row>
          <Col sm="6" style={{borderRight:'1px solid #d2d2d2'}}>
          </Col>
          <Col>
          </Col>
        </Row>
      </CardBody>
    </Card>

    <Modal className="my-modal-wide" isOpen={state.reportDetailsDropDown} toggle={reportDetailsToggle} >
      <ModalHeader hidden={true} toggle={reportDetailsToggle}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-user"></i> Report Details</h5>
        </center>
        <br/>
        <div className="large-scroll-container">
          {content}
        </div>
        <br/>
        <center>
          <Button color="info" onClick={reportDetailsToggle}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
  </div>;
}


export default FlatFeeSreadEstimation;
