//Author June Leow
//Date June 9th, 2023
import AsyncSelect from 'react-select/async';
import {getReducer, getSetStateFunction} from '../../util/util';
import React, {useReducer, useEffect} from 'react';
import Select from 'react-select';

//initialize the state
const initialState = {

};

//reducer function that perform state update
const reducer = getReducer();


const MySelect  = (props)=>{
  const controller = new AbortController();

  let options = props.options;
  let label = '';

  if(props.defaultLabel&&props.defaultLabel!==''){
    if(props.isMulti){
      //TODO
    }else{
      label = props.defaultLabel;
    }
  }

  const modifiedState = Object.assign({}, initialState, {
    asyncSelectedLabel:label,
    type:props.type,
    ref:props.ref,
    value:props.value,
    options:options,
    onChange:props.onChange,
  });

  const [state, dispatch] = useReducer(reducer,modifiedState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  //run only once when component is loaded
  useEffect(()=>{


    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if (state.value !== props.value) {
      setState({value: props.value});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.value, props.value]);

  useEffect(()=>{

  },[props]);

  //non API call but simpyl manage state
  // const notEqual = (array1, array2)=>{
  //   if(array1&&!array2){
  //     return true;
  //   }
  //   else if(!array1&&array2){
  //     return true;
  //   }
  //   else if(!array1&&!array2){
  //     return false;
  //   }
  //   else{
  //     let str1 = JSON.stringify(array1);
  //     let str2 = JSON.stringify(array2);

  //     if(str1!==str2)
  //       return true;
  //     else
  //       return false;
  //   }
  // }

  const valueObject = (currentValue)=>{
    let labelMap = {};

    for(let i=0;i<props.options.length;i++){
      labelMap[props.options[i].value] = props.options[i].label;
    }

    return {label:labelMap[currentValue], value:currentValue};
  }

  const onChange = (e)=>{
    console.log(e)
    if(e){
      setState({value:e.value});
      props.onChange(e.value);
    }
    else{
      setState({value:""});
      props.onChange("");
    }
  }

  const asyncSelectMultiOnChange = (selected)=>{
    console.log(selected);
    if(selected){
      setState({asyncSelectedLabel:selected});
      props.onChange(selected);
    }
    else{
      setState({asyncSelectedLabel:[]});
      props.onChange([]);
    }
  }

  const asyncSelectOnChange = (selected)=>{
    if(selected){
      setState({asyncSelectedLabel:selected.label});
      props.onChange(selected.value);
    }
    else{
      setState({asyncSelectedLabel:""});
      props.onChange("");
    }
  }

  //API call

  //render
  //offline select
  if(state.type==='select'){
    if(props.isMulti){
      return <div>
          <Select
            options={state.options}
            isMulti
            menuPortalTarget={props.modal?document.body:null}
            isClearable={props.selectIsClearable}
            defaultValue={props.defaultValue}
            onChange={props.onChange}
            styles={{
              control: (provided, state) => ({
                ...provided,
                ...props.style
              }),
              menuPortal: (base) => ({ ...base, zIndex: 9999 })
            }}
          />
          <input
            tabIndex={-1}
            autoComplete="off"
            style={{ opacity: 0, height: 0 }}
            value={state.value}
            onChange={(e)=>{onChange(e)}}
            required={props.required}
          />
        </div>;
    }else{
      return <div >
        {/* styles={
          {
            control: (provided, state) => ({
              ...provided,
              minHeight: '25px',
              padding:'2px',
              height: '25px',
              borderTopLeftRadius:'5px',
              borderTopRightRadius:'5px'
            })
          }
        } */}


        <Select
          style={props.style}
          isDisabled={props.disabled}
          required={props.required}
          value={valueObject(state.value)}
          menuPortalTarget={props.modal?document.body:null}
          defaultValue={state.value}
          isClearable={props.selectIsClearable}
          onChange={(e)=>{console.log(e);onChange(e)}}
          defaultOptions
          options={props.options}
          styles={{
            control: (provided, state) => ({
              ...provided,
              ...props.style
            }),
            menuPortal: (base) => ({ ...base, zIndex: 9999 })
          }}
          inputProps={{autoComplete:props.autoComplete?props.autoComplete:'off'}}
          menuPlacement="bottom"
        />
        <input
          tabIndex={-1}
          autoComplete="off"
          style={{ opacity: 0, height: 0 }}
          value={state.value}
          onChange={(e)=>{onChange(e)}}
          required={props.required}
        />
      </div>;
    }
  }
  //async select load options with API call
  else if(state.type==='async-select'){
    if(props.isMulti){
      return <div>
        <AsyncSelect
          style={props.style}
          defaultValue={props.defaultValue}
          disabled={props.disabled}
          required={props.required}
          isMulti
          menuPortalTarget={props.modal?document.body:null}
          styles={{
            control: (provided, state) => ({
              ...provided,
              ...props.style
            }),
            menuPortal: (base) => ({ ...base, zIndex: 9999 })
          }}
          onChange={asyncSelectMultiOnChange}
          isClearable={true}
          defaultOptions={props.defaultOptions}
          menuPlacement="auto"
          loadOptions={props.loadOptions}
        />
        <input
          tabIndex={-1}
          autoComplete="off"
          style={{ opacity: 0, height: 0 }}
          value={state.value}
          onChange={(e)=>{onChange(e)}}
          required={props.required}
        />
      </div>;
    }
    else{
      return <div>
        <AsyncSelect
          style={props.style}
          defaultValue={props.defaultValue}
          disabled={props.disabled}
          required={props.required}
          value={{label:state.asyncSelectedLabel, value:props.value}}
          onChange={asyncSelectOnChange}
          menuPortalTarget={props.modal?document.body:null}
          styles={{
            control: (provided, state) => ({
              ...provided,
              ...props.style
            }),
            menuPortal: (base) => ({ ...base, zIndex: 9999 })
          }}
          isClearable={true}
          defaultOptions
          menuPlacement="auto"
          loadOptions={props.loadOptions}
        />
        <input
          tabIndex={-1}
          autoComplete="off"
          style={{ opacity: 0, height: 0 }}
          value={state.value}
          onChange={(e)=>{onChange(e)}}
          required={props.required}
        />
      </div>;
    }
  }
  else{
    return null;
  }
}

export default MySelect;
