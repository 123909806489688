//Author Sooyoung Kim
//Date date
import {getReducer, getSetStateFunction, getAPICallGenerator, deleteAPICallGenerator, callBackGenerator, formatDate, formatDateTime, sliceFromArray} from '../../util/util';
import {Card, CardHeader, CardBody, Row, Col, NavItem, Nav, NavLink as NavLinkL, TabContent, TabPane} from 'reactstrap';
import MyReactTable from '../util/my-react-table';
import React, {useReducer, useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import './review-delivery.css'

//initialize the state
const initialState = {
  checklist:[],
  shippingQueue:[],
  conditionActiveTab:'1'
};

//reducer function that perform state update
const reducer = getReducer();


const AutoReviewDeliveryDashboard  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getActiveRule();
    getShippingQueue();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const conditionActiveTabToggle = (tab) => {
    setState({conditionActiveTab:tab});
  }


  //API call
  const deleteShippingQueue = (id) => {
    let list = sliceFromArray(state.shippingQueue,'ID', id);
    httpDelete('shipping/queue/'+id,'Order deleted from shipping queue succesfully.','Oops, something went wrong and could not delete order from shipping queue. Please try again later.', apiCallBack([{state:'shippingQueue', value:list}]));
  }

  const getActiveRule = () => {
	  httpGet('review/activeRule/get', '', 'Oops, something went wrong and could not load rule checklist. Please try again later.', apiCallBack([{state:'checklist', key:'data'}]));
  }

  const getShippingQueue = () => {
	  httpGet('shipping/queue/get', '', 'Oops, something went wrong and could not load rule checklist. Please try again later.', apiCallBack([{state:'shippingQueue', key:'data'}]));
  }

  //render
  const columns = [
    {
      id: 'referenceNum',
      Header: 'Reference #',
      maxWidth: 180,
      accessor: d => d.reference_num,
      Cell: props => <div><NavLink target="_blank" to={"/review/"+props.row.original.appraisal_fk}>{props.row.original.reference_num}</NavLink>
                      <br/>{props.row.original.account_manager_name}
                      <br/>{props.row.original.rush === 'Rush'&&<div className="display-inline dark-red-color"><span className="my-badge2">RUSH</span>&nbsp;</div>}
                           {props.row.original.loan_type==='FHA'&&<div className="display-inline dark-red-color"><span className="my-badge2">FHA</span>&nbsp;</div>}
                           {props.row.original.auto_assign==='yes'&&<div className="display-inline dark-red-color"><i className="fa fa-laptop"/>&nbsp;</div>}
                           {props.row.original.hotfile==='yes'&&<div className="display-inline red-color"><i className="fa fa-fire"/>&nbsp;</div>}
                           {props.row.original.pending_receivable==='yes'&&<div className="display-inline green-color"><i className="fa fa-money"/>&nbsp;</div>}</div>,
      className: 'wordwrap',
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'expected',
      Header: 'Expected',
      maxWidth: 180,
      accessor: d => d.datetime_expected,
      Cell: props => <div>{formatDate(props.row.original.datetime_expected)}</div>,
      className: 'wordwrap',
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'status',
      Header: 'Status',
      maxWidth: 250,
      accessor: d => d.status,
      className: 'wordwrap',
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'appraiserName',
      Header: 'Appraiser',
      maxWidth: 250,
      accessor: d => d.appraiser_name,
      Cell: props => <NavLink target="_blank" to={"/appraiser/"+props.row.original.appraiser_fk}>{props.row.original.appraiser_name}</NavLink>,
      className: 'wordwrap',
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'property',
      Header: 'Property',
      accessor: d => d.property_street+' '+d.property_city+', '+d.property_state,
      className: 'wordwrap',
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'triggeredRule',
      Header: 'Triggered Rule',
      minWidth: 300,
      accessor: d => d.checklist.code,
      Cell: props => <div>
                      {
                        props.row.original.checklist.map(
                          (row2, index2)=>{
                            return <div key={index2}><font color="red">{row2.code}</font>{' - '+row2.description}<br/></div>
                          }
                        )
                      }
                    </div>,
      className: 'wordwrap',
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'lastComment',
      Header: 'Last Comment',
      minWidth: 300,
      accessor: d => d.last_comment_author+' - '+d.last_comment,
      className: 'wordwrap',
      headerStyle: {
        textAlign:'left'
      }
    },

  ];
  const columns2 = [
    {
      id: 'delete',
      Header: '',
      width: 50,
      accessor: d => d.reference_num,
      Cell: props => <i onClick={(e)=>deleteShippingQueue(props.row.original.ID)} className="fa fa-times red-color cursor-pointer"></i>,
      className: 'wordwrap',
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'referenceNum',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <div><NavLink target="_blank" to={"/review/"+props.row.original.appraisal_fk}>{props.row.original.reference_num}</NavLink><br/>{props.row.original.account_manager_name}</div>,
      className: 'wordwrap',
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'property',
      Header: 'Property',
      accessor: d => d.property_street+' '+d.property_city+', '+d.property_state,
      className: 'wordwrap',
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'tobeShip',
      Header: 'To be ship',
      accessor: d => d.datetime_shipping,
      Cell: props=> <div>{formatDateTime(props.row.original.datetime_shipping)}</div>,
      className: 'wordwrap',
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'expected',
      Header: 'Date expected',
      accessor: d => d.datetime_expected,
      Cell: props=> <div>{formatDateTime(props.row.original.datetime_expected)}</div>,
      className: 'wordwrap',
      headerStyle: {
        textAlign:'left'
      }
    },
  ];

  return <div>
    <Row>
      <Col sm="6">
        <div className="page-title">
          <i className="fa fa-reorder"></i>&nbsp;Review & Delivery dashboard
        </div>
      </Col>
      <Col sm="6">

      </Col>
    </Row>
    <div className="my-divider"></div>
    <div className="align-right">
      <NavLink to="/auto-review-delivery-stats">View triggered rule stats</NavLink>
    </div>
    <Nav tabs>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.conditionActiveTab === '1'?"active":"inactive" )}
          onClick={() => { conditionActiveTabToggle('1'); }}
          to="#"
        >
          Review
        </NavLinkL>
      </NavItem>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.conditionActiveTab === '2'?"active":"inactive" ) }
          onClick={() => { conditionActiveTabToggle('2'); }}
          to="#"
        >
          Shipping
        </NavLinkL>
      </NavItem>
    </Nav>
    <Card>
      {state.conditionActiveTab === '1'&&<CardHeader className="header-color">
        <i className="fa fa-tasks"></i> Review Queue
      </CardHeader>}
      {state.conditionActiveTab === '2'&&<CardHeader className="header-color">
        <i className="fa fa-tasks"></i> Auto Shipping Queue
      </CardHeader>}
      <CardBody>
        <TabContent activeTab={state.conditionActiveTab}>
          <TabPane tabId="1">
            <div className="overflow-auto">
              <MyReactTable columns={columns} data={state.checklist} className="table table-striped"/>
            </div>

          </TabPane>
          <TabPane tabId="2">
            <div className="overflow-auto">
              <MyReactTable columns={columns2} data={state.shippingQueue} className="table table-striped"/>
            </div>
          </TabPane>
        </TabContent>
      </CardBody>
    </Card>
  </div>;
}


export default AutoReviewDeliveryDashboard;
