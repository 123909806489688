//Author Sooyoung Kim
//Date July 25, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, deleteAPICallGenerator, callBackGenerator, formatNumber, showMessage, confirmation} from '../../util/util';
import React, {useReducer, useEffect} from 'react';
import {Button, Row, Col} from 'reactstrap';
import MyDropzone from './../util/my-dropzone';
import FileSaver from 'file-saver';

//initialize the state
const initialState = {
  clientFiles:[],
  toUploadFiles:[],
  bigFiles:[],
  resizeFilePopUp:false,
  modal: false,
  errorMessage:'',
};

//reducer function that perform state update
const reducer = getReducer();

const ClientFile  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getClientFiles();
    
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  
  //function trigger for dropzone react.
  //this function contians two list of files, the accepted and rejected file per the configuration
  const onDrop = (acceptedFiles, rejectedFiles) => {
    let existingFiles = state.toUploadFiles.slice();
    let bigFiles = state.bigFiles;

    for(let i=0;i<acceptedFiles.length;i++){
      let file = acceptedFiles[i];
      file.status = 'Pending';
      file.fileType = '';

      let isBigFile = false;
      if(file.size > 40000000){
        isBigFile = true;
        bigFiles.push(file.name);
      }

      let duplicate = false;
      for(let j=0;j<state.toUploadFiles.length;j++){
        if(state.toUploadFiles[j].name===acceptedFiles[i].name){
          duplicate = true;
          setState({errorMessage:'Duplicate file name "'+acceptedFiles[i].name+'"'})
        }
      }
      if(!duplicate && !isBigFile)
        existingFiles.push(file);
    }
    if(bigFiles.length){
      setState({resizeFilePopUp:true, bigFiles:bigFiles});
    }
    setState({toUploadFiles: existingFiles});
  }

  //remove a specific file from the toUpload list.
  const removeToUploadFile = (preview) => {
    let toRemoveIndex = -1;
    for(let i=0;i<state.toUploadFiles.length;i++){
      if(state.toUploadFiles[i].preview===preview){
        toRemoveIndex = i;
        break;
      }
    }

    if(toRemoveIndex!==-1){
      let newFiles = state.toUploadFiles.slice();
      newFiles.splice(toRemoveIndex,1);

      setState({toUploadFiles:newFiles});
    }
  }

  //format the size to use appropiate unit KB, MB and B and round up to only 1 decimal
  const formatFileSize = (size) => {
    let intSize = parseInt(size,10);

    if(intSize>=1000000.00)
      return formatNumber(Math.round((intSize*10/1000000))/10)+' MB';
    else if(intSize>=1000)
      return formatNumber(Math.round((intSize*10/1000))/10)+' KB';
    else
      return formatNumber(intSize)+' B';

  }

  //constructing a new file object
  const deepCopyFileObject = (file) => {
    let newFile = new File([file],file.name);
    newFile.preview = file.preview;
    newFile.status = file.status;

    return newFile
  }

  //remove file from the list after successfull http delete call
  const removeFileFromList = (ID) => {
    let newclientFiles = state.clientFiles.slice();

    for(let i=0;i<newclientFiles.length;i++){
      if(newclientFiles[i].ID===ID)
        newclientFiles.splice(i,1);
    }

    setState({clientFiles:newclientFiles});
  }


  //API call
  //retrieve a list of appraisal files that ties to a specific appraisal
  const getClientFiles = () => {
    let callBack = apiCallBack([{state:'clientFiles', key:'data'}]);
	  httpGet('client/file/get/'+props.clientFk, '', 'Oops, something went wrong and could not load appraisal files for this appraisal. Please try again later.', callBack);
  }

  //download the file when user click on the file name using blob
  const downloadFile = (name) => {
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let byteCharacters = atob(response.data.data);
  			let byteNumbers = new Array(byteCharacters.length);
  			for (let i = 0; i < byteCharacters.length; i++) {
  				byteNumbers[i] = byteCharacters.charCodeAt(i);
  			}
  			let byteArray = new Uint8Array(byteNumbers);
  			let data = new Blob([byteArray]);
  			FileSaver.saveAs(data, name);
      }
    };

    let parameters = [
      {
        field:'clientFk',
        value:props.clientFk
      },
      {
        field:'fileName',
        value:name
      }
    ];

    httpPost('client/file/download', parameters, '', 'Oops, something went wrong and could not download the file "'+name+'". Please try again later.', callBack);
  }

  //delete file
  const deleteFile = (ID) => {
    let callBack = apiCallBack([], removeFileFromList(ID));
    httpDelete('client/file/'+ID,'','Oops, something went wrong and could not delete the file, please try again later.', callBack);
  }

  //function that upload all toUploadFiles to the server by calling asyncPost
  const uploadAll = () => {
    for(let i=0;i<state.toUploadFiles.length;i++){
      const reader = new FileReader();
      reader.onload = () => {
        const fileAsBinaryString = reader.result;
        let base64 = btoa(fileAsBinaryString);

        let callBack = (response)=>{console.log(response);
          let code = response.data?response.data.code:undefined;
          if(code==='00'){
            //copy the array so we maintaining the immutable state
            let newToUploadFiles = [];

            //deep copy the entire file object array
            for(let j=0;j<state.toUploadFiles.length;j++){
              let newFile = deepCopyFileObject(state.toUploadFiles[j]);
              if(newFile.name===state.toUploadFiles[i].name)
                newFile.status='Done';

              newToUploadFiles.push(newFile);
            }

            //update the list of uploaded file
            let newUploadedFile = response.data.data;
            let existingUploadedFiles = state.clientFiles.slice();
            existingUploadedFiles.push(newUploadedFile);

            setState({toUploadFiles:newToUploadFiles, clientFiles:existingUploadedFiles});
          }
        };
        callBack = callBack.bind(this);

        let parameters = [
          {
            field:'clientFk',
            value:props.clientFk
          },
          {
            field:'fileName',
            value:state.toUploadFiles[i].name
          }
          ,{
            field:'file_encoded',
            value:base64
          }
        ];

        httpPost('client/upload', parameters, 'File "'+state.toUploadFiles[i].name+'" uploaded successfully.', 'Oops, something went wrong and could not upload the file "'+state.toUploadFiles[i].name+'". Please try again later.', callBack);
      };
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => {
        showMessage('error','File upload failed, please try again later.');
      };

      reader.readAsBinaryString(state.toUploadFiles[i]);
    }
  }

  //render
  let clientFiles;
  let toUploadFiles;

  if(state.clientFiles.length>0){
    clientFiles = state.clientFiles.map(
      (file, outterIndex)=>{

        return(
          <div key={outterIndex}>
            <div className="file-container">
                <div className="font-bold">
                <div className="display-inline cursor-pointer" ><i className="fa fa-trash red-color" onClick={()=>{
                  confirmation(
                    ()=>{deleteFile(file.ID)},
                    ()=>{},
                    'Delete file?',
                    'Are you sure you want to delete this file?')
                }}></i></div>&nbsp;
                <div className="cursor-pointer display-inline link-color" onClick={()=>downloadFile(file.name)}><b>{file.name}</b></div>
              </div>
              <font size="1"><div className="no-margin no-padding font-bold margin-top-extra"><i className="fa fa-clock-o"></i>&nbsp;{file.datetime_created} - {file.uploader_name}</div></font>
            </div>
          </div>
        );
      }
    );
  }

  if(state.toUploadFiles.length>0){
    toUploadFiles = state.toUploadFiles.map(
      (file,index)=>{
        return(
          <tr key={index}>
            <td>{file.name}</td>
            <td>{formatFileSize(file.size)}</td>
            <td><center>{file.status}</center></td>
            <td><center><i className="fa fa-times red-color cursor-pointer" onClick={()=>removeToUploadFile(file.preview)}></i></center></td>
          </tr>
        );
      }
    );
  }

  return <div>
    <Row>
      <Col sm="6">
        <div>
          <MyDropzone onDrop={onDrop}/>
          <br/>
          <div className="my-divider"></div>
          <div className="small-scroll-container red-color">
            <b>{state.errorMessage}</b>
          </div>
          <div className="medium-scroll-container">
            <table className="table file-list-table" cellSpacing="0" cellPadding="0">
              <thead>
                <tr>
                  <th width="50%">Name</th>
                  <th width="20%">Size</th>
                  <th width="15%"><center>Status</center></th>
                  <th width="15%">Control</th>
                </tr>
              </thead>
              <tbody>
                {toUploadFiles}
              </tbody>
            </table>
          </div>
          <br/>

          <center>
            <Button color="warning" onClick={uploadAll}><i className="fa fa-upload"></i> Upload</Button>&nbsp;
          </center>
        </div>
      </Col>
      <Col sm="6">
        <div className="file-panel">
          {clientFiles}
        </div>
      </Col>
    </Row>
  </div>;
}


export default ClientFile;
