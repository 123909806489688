//Sooyoung Kim
//June 15, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, callBackGenerator, formatDateTime, getFromTo} from '../../../util/util';
import {Card, CardHeader, CardBody, Table, UncontrolledTooltip} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import ReportFilter from '../report-filter';
import moment from 'moment';
import {NavLink} from 'react-router-dom';

let start = getFromTo('start');
let end = getFromTo('end');

//initialize the state
const initialState = {
  reportDetails:[],
  audited:'no',
  from:start,
  to:end,
  referenceNum:''
};

//reducer function that perform state update
const reducer = getReducer();


const ThresholdReport  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getReportDetails();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  //API call
  const approveAudit = (auditID, status) =>{
    let parameters = [
      {
        field:'ID',
        value:auditID
      },
      {
        field:'status',
        value:status
      }
    ];

    let callBack = (response)=>{
      let code= response.data.code;
      if(code==='00'){
        let reportDetails = state.reportDetails.slice();
        for(let i=0;i<reportDetails.length;i++){
          if(reportDetails[i].auditID===auditID){
            if(status==='yes')
              reportDetails.splice(i,1);
            else{
              let tmp = reportDetails[i];
              tmp.audited = status;
              reportDetails.splice(i,1, tmp);
            }
            setState({reportDetails:reportDetails});

            break;
          }
        }
      }
    };
    callBack = callBack.bind(this);

    httpPost('billing/accountingAudit/approve', parameters, 'Confirmed accounting changes successfully.', 'Oops, something went wrong and could not confirm this accounting changes. Please try again later.', callBack);
  }

  const getReportDetails = () =>{
    let callBack = apiCallBack([{state:'reportDetails', key:'data'}]);
    httpGet('report/accountingAudit/from='+state.from+'&to='+state.to+'&audited='+state.audited+'&reference='+state.referenceNum, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  //render
  let from, to;
  if(state.from!=='')
    from = moment(state.from);
  if(state.to!=='')
    to = moment(state.to);

  let reportFiltersConfig = [
    {id:'customSelect',value:state.audited, updateFunc:(v)=>setState({audited:v}), width:'2', label:'Audited', clearable:false, options:[{label:"All",value:"all"},{label:"Review",value:"review"},{label:"Yes",value:"yes"},{label:"No",value:"no"}]},
    {id:'from',value:from, updateFunc:(v)=>setState({from:v}), width:'2'},
    {id:'to',value:to, updateFunc:(v)=>setState({to:v}), width:'2'},
    {id:'custom',value:state.referenceNum, updateFunc:(v)=>setState({referenceNum:v}), width:'3', label:'Reference # / File #', placeHolder:""},
    {id:'button',value:'Submit', updateFunc:getReportDetails, width:'3', className:"align-right", color:"warning"},
  ];
    
  return <div>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Accounting audit report
      </CardHeader>
      <CardBody>
        <ReportFilter {...props} configs={reportFiltersConfig}/>
        <div className="my-divider">&nbsp;</div>
        <Table className="table table-striped">
          <thead>
            <tr>
              <th width="15%">Reference #</th>
              <th width="15%">Status</th>
              <th width="15%">Address</th>
              <th width="10%">Type</th>
              <th width="15%">Description</th>
              <th width="5%">Audited</th>
              <th width="15%">Change date</th>
            </tr>
          </thead>
          <tbody>
            {
              state.reportDetails.map(
                (row,index)=>{
                  let tooltip;

                  if(row.auditors&&row.auditors.length>0)
                    tooltip =
                    <UncontrolledTooltip delay={{ "show": 0, "hide": 0 }} placement="right" target={"tooltip"+index}>
                      {
                        row.auditors.map(
                          (auditor,index2)=>{
                            return(
                              <div key={index2}>
                                {auditor.auditor_name+' audited at '+formatDateTime(auditor.datetime_created)}
                              </div>
                            );
                          }
                        )
                      }
                    </UncontrolledTooltip>;

                  let audited;

                  if(row.audited==='yes')
                    audited =
                    <div className="link-color">
                      {row.audited}
                    </div>;
                  else
                    audited =
                    <div>
                      {row.audited}
                    </div>

                  let review;

                  if(row.audited==='no')
                    review = <i className="fa fa-question red-color cursor-pointer" onClick={(e)=>{approveAudit(row.auditID,'review')}}></i>;

                  let approve;

                  if(row.audited==='no'||row.audited==='review')
                    approve = <i className="fa fa-check green-color cursor-pointer" onClick={(e)=>{approveAudit(row.auditID,'yes')}}></i>;

                  return(
                    <tr key={index}>
                      <td>{approve}&nbsp;&nbsp;&nbsp;{review} <NavLink target="_blank" to={"/billing/"+row.ID+"/audit/"+row.auditID}>{row.reference_num}</NavLink></td>
                      <td>{row.status}</td>
                      <td>{row.property_street+' '+row.property_city+', '+row.property_state+' '+row.property_zip}</td>
                      <td>{row.type}</td>
                      <td>{row.description}</td>
                      <td>
                        <div id={"tooltip"+index} className="cursor-pointer">{audited}</div>
                        {tooltip}
                      </td>
                      <td>
                        {formatDateTime(row.datetime_created)}

                      </td>
                    </tr>
                  );
                }
              )
            }
          </tbody>
        </Table>
      </CardBody>
    </Card>
  </div>;
}


export default ThresholdReport;
