//Author June Leow
//Date Nov 15th, 2023
import React, {useReducer, useEffect} from 'react';
import {getReducer, getSetStateFunction} from '../../util/util';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import './my-confirmation.css';
import ReactDOM from 'react-dom';
//initialize the state
const initialState = {
  confirmationDropDownOpen:true
};


const reducer = getReducer();

const MyConfirmation  = (props)=>{
  //reducer function that perform state update

  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const closeConfirmation = ()=>{
    setState({confirmationDropDownOpen:false});
    ReactDOM.render(
      null,document.getElementById('myConfirmationContainer')
    );
  }

  //render
  return(
    <Modal className="confirmation-dialog" isOpen={state.confirmationDropDownOpen}>
      <ModalHeader className="my-confirmation-title"><i className="fa fa-warning"></i> {props.title}</ModalHeader>
      <ModalBody>
        <br/>
        <center>
          {props.message}
        </center>
        <br/>
      </ModalBody>
      <ModalFooter>
        <center>
          <Button color="warning" onClick={()=>{props.yesCallBack();closeConfirmation()}}><i className="fa fa-check"></i> Yes</Button>&nbsp;
          <Button color="info" onClick={()=>{props.noCallBack();closeConfirmation()}}><i className="fa fa-times"></i> No</Button>
        </center>
      </ModalFooter>
    </Modal>
  );
}


export default MyConfirmation;
