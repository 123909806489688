//Author author
//Date date
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, deleteAPICallGenerator, callBackGenerator, formatDateTime, formatDate, confirmation, sliceFromArray, pushToArray} from '../../util/util';
import {Alert, Button, Card, NavItem, Nav, TabContent, TabPane, CardBody, CardHeader, Col, Row,Input,Table, Modal , ModalHeader, ModalBody, UncontrolledTooltip, NavLink as NavLinkL} from 'reactstrap';
import FloatingMemo from './floating-memo';
import InfiniteScroll from 'react-infinite-scroll-component';
import MyXEditable from '../util/my-xeditable';
import React, {useReducer, useEffect} from 'react';
import {NavLink, useParams} from 'react-router-dom';
import AppraiserFile from './appraiser-file';
import DatePicker from 'react-datepicker';
import MySelect from './../util/my-select';
import MyGauge from './../util/my-gauge';
import ReactStars from 'react-stars';
import Toggle from 'react-toggle';
import moment from 'moment';
import './appraiser.css';


let today = new Date();
let month = today.getMonth()+1;

if(month<10)
  month = '0'+month;

//initialize the state
const initialState = {
  appraiser:{
    messages:[],
    licenses:[],
    memo:[]
  },
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
  dropdownOpen: false,
  memo:'',
  historyOrders:[],
  blackListedAppraisalType:[],
  hasMoreOrders:true,
  loading: false,
  sort: 'ID',
  order: 'DESC',
  limit:100,
  offset:0,

  qualityRatings:[],
  serviceRatings:[],
  type:'',
  newIssueCategory:'',
  newIssueComment:'',
  newIssueRelatedAppraisal:'',

  //existing entry of appraisal type & fee
  appraisalTypeFees:[],

  //entry of appraiser covered county/zip
  coverageCountyZip:[],

  //new appraisal type & fee entry
  serviceRatingsHistoryDropDownOpen:false,
  blackListedAppraisalTypePopUp:false,
  newAppraisalTypeDropDownOpen:false,
  newCountyZipDropDownOpen:false,
  newAppraiserFileDropDownOpen:false,
  newAppraisalTypeFeeSelection:'',
  newAppraisalTypeFeeAmount:'',
  newArea:'',
  newAreaType:'county',
  newAreaCovered:'yes',
  appraisalTypes:[],
  preferredCommunication:[],
  exclusionaryListPopUp:false,
  exclusionaryListTargets:[],
  exclusionaryListComment:'',
  clients:[],
  fieldsEnum:[],
  blackList:[],
  blackListDropDownOpen:false,
  newBlackListDescription:'',
  newBlackListConditionRuleSelected:'',
  newBlackListConditionField:'',
  newBlackListConditionOperator:'=',
  newBlackListConditionDescription:'',
  newBlackListConditionValues:[],
  newBlackListConditionValuesT:'',
  newBlackListConditionValuesS:'',

  newBlackListedAppraisalType:'',

  newLicenseType:'Unknown',
  newLicenseNumber:'',
  newLicenseState:'',
  newLicenseExpiration:today.getFullYear()+'-'+month+'-'+today.getDate(),

  newLicenseDropDownOpen:false,
  newEmailPopUpDropDownOpen:false,
  newEmail:'',

  appraiserEmailDropDownOpen:false,
  appraiserEmailSettings:[],

  appraiserStats:[],
  activeTab:'1',

  panelDiscussion:{},
  inPanelDiscussion:false,
  panelDiscussionDropDown:false,
  panelDiscussionAppraisalFk:0,
};

//reducer function that perform state update
const reducer = getReducer();


const Appraiser  = (props)=>{
  const controller = new AbortController();
  let {id, params} = useParams();

  if(params){
    params = params.replace('%ForwardSlash','ForwardSlash');
    params = params.replace('%Ampersand','Ampersand');
  }
  else {
    params = '';
  }

  let newInitialState = Object.assign({}, initialState, {
    id:id,
    params:params,
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getAppraiser();
    getAppraiserQualityRating();
    getAppraiserServiceRating();
    getAppraiserappraisalTypeFees();
    getAppraiserCoverage();
    getAppraisalTypesLabel();
    getPreferredCommunication();
    getAllClientProfiles();
    getRuleFieldEnum();
    getBlackList();
    getPanelDiscussion()
    getAppraiserStats();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    if(state.historyOrders.length<=0 && state.hasMoreOrders){
        loadMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state]);

  //non API call but simpyl manage state
  const updateMemo = (memo)=>{
    let appraiser = Object.assign({}, state.appraiser);
    appraiser.memo = memo;
    setState({appraiser:appraiser});
  }

  const emailNotificationToggle = (subject) => {
    for(let i=0;i<state.appraiserEmailSettings.length;i++){
      if(state.appraiserEmailSettings[i].subject===subject){
        if(state.appraiserEmailSettings[i].blocked==='yes')
          unblockAppraiserEmail(subject);
        else
          blockAppraiserEmail(subject);
      }
    }
  }

  const handlePanelDiscussion = () => {
    if(state.inPanelDiscussion){
      removePanelDiscussion();
    }
    else{
      togglePanelDiscussion();
    }
  }

  const togglePanelDiscussion = () => {
    setState({panelDiscussionDropDown:!state.panelDiscussionDropDown});
  }

  const toggleEmailDropDown = () => {
    if(!state.appraiserEmailDropDownOpen){
      getEmailNotificationSettings();
    }
    setState({appraiserEmailDropDownOpen:!state.appraiserEmailDropDownOpen});
  }

  const updateFieldCallBack = (field, value) => {
    console.log(field, value);
    let appraiser = Object.assign({}, state.appraiser);
    appraiser[field] = value;
    setState({appraiser:appraiser});

    if(field==='payment_method'){
      confirmation(
        ()=>{updatePendingPayable('Payment Method', value);},
        ()=>{},
        'Update all payable?',
        'Do you want to update all existing pending/standby/hold payable?');
    }
  }

  const updateFieldCallBack2 = (ID, field, value) => {
    let appraisalTypeFees = state.appraisalTypeFees.slice();
    for(let i=0;i<appraisalTypeFees.length;i++){
      if(appraisalTypeFees[i].ID===ID){
        appraisalTypeFees[i][field] = value;
        break;
      }
    }
    setState({appraisalTypeFees:appraisalTypeFees});
  }

  const updateFieldCallBack3 = (ID, field, value) => {
    console.log(field, value);
    let appraiser = Object.assign({}, state.appraiser);
    let licenses = appraiser.licenses.slice();

    for(let i=0;i<licenses.length;i++){
      if(licenses[i].ID===ID){
        licenses[i][field] = value;
        break;
      }
    }
    appraiser.licenses = licenses;
    setState({appraiser:appraiser});
  }

  const blackListValueOnChange = (v) => {
    setState({newBlackListConditionValuesT:v});
  }

  const removeExclusionaryListTarget = (ID) => {
    let targets = state.exclusionaryListTargets.slice();

    targets = targets.filter(
      (target)=>{ return target.ID!==ID;}
    );

    setState({exclusionaryListTargets:targets});
  }

  const addToExclusionaryListTarget = (client) => {
    let targets = state.exclusionaryListTargets.slice();

    for(let i=0;i<targets.length;i++){
      if(targets[i].ID===client.ID)
        return;
    }

    targets.push(client);
    setState({exclusionaryListTargets:targets});
  }

  const exclusionaryListToggle = () => {
    setState({exclusionaryListPopUp:!state.exclusionaryListPopUp});
  }

  const toggleBlackListAppraisalType = () => {
    setState({blackListedAppraisalTypePopUp:!state.blackListedAppraisalTypePopUp});
  }

  const toggleBlackList = () => {
    setState({blackListDropDownOpen:!state.blackListDropDownOpen});
  }

  const newEmailToggle = () => {
    setState({newEmailPopUpDropDownOpen:!state.newEmailPopUpDropDownOpen});
  }

  const toggleNewLicense = () => {
    setState({newLicenseDropDownOpen:!state.newLicenseDropDownOpen});
  }

  const serviceRatingToggle = () => {
    if(state.serviceRatings.length<=0)
      getServiceRatings();
    setState({serviceRatingsHistoryDropDownOpen:!state.serviceRatingsHistoryDropDownOpen});
  }

  //toggle function for pop up
  const newAappraisalTypeToggle = () => {
    setState({newAppraisalTypeDropDownOpen:!state.newAppraisalTypeDropDownOpen});
  }

  const newCountyZipToggle = () => {
    setState({newCountyZipDropDownOpen:!state.newCountyZipDropDownOpen});
  }

  //this function refresh the list of orders pipeline
  const refreshList = () => {
    setState({
      historyOrders:[],
      totalCount:0,
      hasMoreOrders:true,
      offset:0,
    });
  }

  const activeTabToggle = (tab) => {
    setState({activeTab:tab});
  }

  //render function for infinite scroller
  const renderHistoryAppraisals = () => {

    return (
      <div>
        <Table className="table table-striped">
          <thead>
            <tr>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('reference_num')}><i className="fa fa-search"></i>&nbsp;Reference # {renderSortIcon('reference_num')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('status')}><i className="fa fa-info-circle"></i>&nbsp;Status {renderSortIcon('status')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('loan_num')}><i className="fa fa-folder"></i>&nbsp;Loan # {renderSortIcon('loan_num')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('CONCAT(borrower_f_name," ",borrower_l_name)')}><i className="fa fa-user"></i>&nbsp;Borrower {renderSortIcon('CONCAT(borrower_f_name," ",borrower_l_name)')}</th>
              <th className="cursor-pointer" width="20%" onClick={()=>columnClickHandler('property_street')}><i className="fa fa-map-marker"></i>&nbsp;Property Address {renderSortIcon('property_street')}</th>
              <th className="cursor-pointer" width="20%" onClick={()=>columnClickHandler('')}><i className="fa fa-info-circle"></i>&nbsp;Order Information {renderSortIcon('')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('datetime_submitted')}><i className="fa fa-calendar-o"></i>&nbsp;Date Submitted {renderSortIcon('datetime_submitted')}</th>
            </tr>
          </thead>
          <tbody>
          {
            state.historyOrders.map(
              (appraisal)=>{
                return (
                  <tr key={appraisal.ID}>
                    <td><NavLink className="link-color" target="_blank" to={"/appraisal/"+appraisal.ID}><b>{appraisal.reference_num}</b></NavLink></td>
                    <td>{appraisal.status}</td>
                    <td>{appraisal.loan_num}</td>
                    <td>{appraisal.borrower_f_name+' '+appraisal.borrower_l_name}</td>
                    <td>{appraisal.property_street+' '+appraisal.property_city+', '+appraisal.property_state+' '+appraisal.property_zip}</td>
                    <td>
                      <i className="fa fa-tags icon-color"></i>&nbsp;
                      {appraisal.appraisal_type}
                      <br/>
                      <i className="fa fa-home icon-color"></i>&nbsp;
                      {appraisal.property_type}
                      <br/>
                      <i className="fa fa-hashtag icon-color"></i>&nbsp;
                      {appraisal.loan_purpose}
                    </td>
                    <td>{formatDateTime(appraisal.datetime_submitted)}</td>
                  </tr>
                );
              }
            )
          }
          </tbody>
        </Table>
      </div>
    )
  }

  //this function render the sort icon at each column of the table
  const renderSortIcon = (col) => {
    if(state.sort===col){
      if(state.order==='ASC')
        return <i className="red-color fa fa-arrow-down"></i>
      else
        return <i className="red-color fa fa-arrow-up"></i>
    }
  }

  
  //click handler that switch the state of sorting order then call the function to refresh the list
  //to achieve the "sorting"
  const columnClickHandler = (col) => {
    if(state.sort===col){
      if(state.order==='ASC')
        setState({order:'DESC'});
      else
        setState({order:'ASC'});
    }
    else
      setState({sort:col});
    refreshList();
  }

  //API call
  //generate a function that do the update on editable
  const generateUpdateFunction = (url, parameters, successMessage, failedMessage, parentCallBack=null) => {
    return (newValue, callBack, errorCallBack)=>{
      //add in the new value into parameters
      parameters.push({field:'value',value:newValue});
      let promise = httpPut(url,parameters,successMessage,failedMessage, callBack, errorCallBack);
      promise.then(
        (response)=>{
          let field;
          let value;

          for(let i=0;i<parameters.length;i++){
            if(parameters[i].field==='field')
              field = parameters[i].value;
            else if(parameters[i].field==='value')
              value = parameters[i].value;
          }
          if(parentCallBack!==null){
            parentCallBack(field, value);
          }
        }
      );
    }
  };

  //remove a single entry of appraisal type & fee
  const removeAppraisalTypeFee = (id) => {
    let list = sliceFromArray(state.appraisalTypeFees,'ID', id);
    httpDelete('appraiser/appraisalTypeFees/'+id,'Entry removed.','Failed to remove the entry, please try again later.', apiCallBack([{state:'appraisalTypeFees', value:list}]));
  }

  //remove a single entry of county/zip
  const removeCoverage = (id) => {
    let list = sliceFromArray(state.coverageCountyZip,'ID', id);
    httpDelete('appraiser/coverage/'+id,'Entry removed.','Failed to remove the entry, please try again later.', apiCallBack([{state:'coverageCountyZip', value:list}]));
  }

  const changeEmail = () => {
    let parameters = [
      {
        field:'appraiserID',
        value:state.id
      },
      {
        field:'newEmail',
        value:state.newEmail
      }
    ];

    let appraiser = Object.assign({},state.appraiser);
    appraiser.email = state.newEmail;
    
    let callBack = apiCallBack([{state:'newEmail', value:''},{state:'appraiser', value:appraiser}]);
    httpPost('appraiser/changeEmail', parameters, 'Email changed successfully.', 'Oops, something went wrong and could not change the email. Please try again later.', callBack);
  }

  const updatePendingPayable = (type, value) => {
    let parameters = [{field:'appraiserFk',value:state.id}, {field:'type',value:type}, {field:'value',value:value}];
    httpPut('billing/payable/update',parameters,'All ending/standby/hold payable has been updated.','Oops, something went wrong and could not update payable. Please try again later.');
  }

  const addNewBlackListedAppraisalType = (e) => {
    e.preventDefault();
    let parameters = [{field:'appraiserFk', value:state.id}, {field:'label', value:state.newBlackListedAppraisalType}];
    let callBack = apiCallBack([{state:'blackListedAppraisalType', arraykey:'data', targetArray:state.blackListedAppraisalType}], toggleBlackListAppraisalType());
    httpPost('appraiser/blackList/label/create', parameters, 'Report type added to exclusionary list successfully.', 'Oops, something went wrong and could not add this report type to exclusionary list. Please try again later.', callBack);
  }

  //submit and record a new issue
  const addToExclusionaryList = () => {
    let clients=[];

    for(let i=0;i<state.exclusionaryListTargets.length;i++)
      clients.push(state.exclusionaryListTargets[i].ID);

    let parameters = [
      {
        field:'appraiserFk',
        value:state.id
      },
      {
        field:'clients',
        value:clients
      },
      {
        field:'comment',
        value:state.exclusionaryListComment
      }
    ];

    let appraiser = Object.assign({},state.appraiser);
    let targets = appraiser.exclusionary_targets.slice();

    for(let i=0;i<state.exclusionaryListTargets.length;i++){
      let tmp = {
        ID:state.exclusionaryListTargets[i].ID,
        company:state.exclusionaryListTargets[i].company
      };

      targets.push(tmp);
    }

    appraiser.exclusionary_targets = targets;

    let callBack = apiCallBack([{state:'appraiser', value:appraiser}], exclusionaryListToggle());
    httpPost( 'appraiser/exclusionaryList/create', parameters, 'Added to exclusionary list successfully.',  'Oops, something went wrong and could not add to exclusionary list. Please try again later.', callBack);
  }

  //submit new entry of covered county/zip
  const submitNewCoverage = (e) => {
    e.preventDefault();
    let parameters = [
      {
        field:'appraiser_fk',
        value:state.id
      },
      {
        field:'coverage_area',
        value:state.newArea
      },
      {
        field:'coverage_type',
        value:state.newAreaType
      },
      {
        field:'covered',
        value:state.newAreaCovered
      },
    ];

    let callBack = apiCallBack([{state:'coverageCountyZip', arraykey:'data', targetArray:state.coverageCountyZip},{state:'newArea', value:''},{state:'newAreaType', value:'county'},{state:'newAreaCovered', value:'yes'}], newCountyZipToggle());
    httpPost('appraiser/coverage/create', parameters, 'New covered county/zip entry created successfully.', 'Oops, something went wrong and could not create this covered county/zip entry. Please try again later.', callBack);
  }

  //submit new entry of appraisal type & fee
  const submitNewAppraisalTypeFee = (e) => {
    e.preventDefault();
    let parameters = [
      {
        field:'appraiser_fk',
        value:state.id
      },
      {
        field:'label',
        value:state.newAppraisalTypeFeeSelection
      },
      {
        field:'fee',
        value:state.newAppraisalTypeFeeAmount
      }
    ];

    let callBack = apiCallBack([{state:'appraisalTypeFees', arraykey:'data', targetArray:state.appraisalTypeFees},{state:'newAppraisalTypeFeeAmount', value:''},{state:'newAppraisalTypeFeeSelection', value:''}], newAappraisalTypeToggle());
    httpPost('appraiser/appraisalTypeFees/create', parameters, 'Report type & fee entry created successfully.', 'Oops, something went wrong and could not create this report type & fee entry. Please try again later.', callBack);
  }

  const getEmailNotificationSettings = () => {
    let callBack = apiCallBack([{state:'appraiserEmailSettings', key:'data'}]);
	  httpGet('email/user/get/'+state.appraiser.email, '', 'Oops, something went wrong and could not load user email notification settings. Please try again later.', callBack);
  }

  const blockAppraiserEmail = (subject) => {
    let parameters = [
      {
        field:'subject',
        value:subject
      },
      {
        field:'email',
        value:state.appraiser.email
      }
    ];

    let appraiserEmailSettings = state.appraiserEmailSettings.slice();
    for(let i=0;i<appraiserEmailSettings.length;i++){
      if(appraiserEmailSettings[i].subject===subject){
        appraiserEmailSettings[i].blocked='yes';
        break;
      }
    }

    let callBack = apiCallBack([{state:'appraiserEmailSettings', value:appraiserEmailSettings}]);
    httpPost('email/user/block', parameters, 'Email notification disabled.', 'Oops, something went wrong and could not disable the email notification. Please try again later.', callBack);
  }

  const unblockAppraiserEmail = (subject) => {
    let appraiserEmailSettings = state.appraiserEmailSettings.slice();
    for(let i=0;i<appraiserEmailSettings.length;i++){
      if(appraiserEmailSettings[i].subject===subject){
        appraiserEmailSettings[i].blocked='no';
        break;
      }
    }

    let callBack = apiCallBack([{state:'appraiserEmailSettings', value:appraiserEmailSettings}]);
    httpDelete('email/user/'+state.appraiser.email+'/'+subject,'Email notification enabled succesfully.','Oops, something went wrong and could not enable the email notification. Please try again later.', callBack);
  }

  const getPanelDiscussion = () => {
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;

      if(code==='00'){
        if(response.data.data){
          setState({panelDiscussion:response.data.data});
          if(response.data.data.panel_status==='pending')
            setState({inPanelDiscussion:true});
        }
      }
    };
    callBack = callBack.bind(this);

    httpGet('appraiser/panelDiscussion/'+state.id, '', 'Oops, something went wrong and could not load the panel discussion information.', callBack);
  }

  //add order to manual review
  const addToPanelDiscussion = () => {
    let comment = '';

    if(state.panelDiscussion.comment&&state.panelDiscussion.comment!=='')
      comment = state.panelDiscussion.comment;

    let parameters = [
      {
        field:'appraiserFk',
        value:state.id
      },
      {
        field:'comment',
        value:comment
      },
      {
        field:'appraisalFk',
        value:state.panelDiscussionAppraisalFk
      }
    ];

    let callBack = apiCallBack([{state:'panelDiscussion', key:'data'}, {state:'inPanelDiscussion', value:true}]);
    httpPost('appraiser/panelDiscussion/submit', parameters, 'Successfully submit a request to panel discussion.', 'Oops, something went wrong and cannot submit your request. Please try again later.', callBack);
  }

  const removePanelDiscussion = () => {
    let parameters = [
      {
        field:'field',
        value:'status'
      },
      {
        field:'value',
        value:'inactive'
      },
      {
        field:'ID',
        value:state.panelDiscussion.panelID
      }
    ];

    let callBack = apiCallBack([{state:'inPanelDiscussion', value:!state.inPanelDiscussion}]);
    httpPut('appraiser/panelDiscussion/update', parameters, 'Appraiser is inactivated from panel discussion.', 'Oops, something went wrong and could not remove from panel discussion. Please try again later.', callBack);
  }

  //get appraiser profile
  const getAppraiser = () => {
    let callBack = (response)=>{console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        setState({appraiser:response.data.data});

        if(response.data.data.memo!=='')
          setState({dropdownOpen:true});
      }
    }
    callBack = callBack.bind(this);

    httpGet('appraiser/'+state.id, '', 'Oops, something went wrong and could not load this appraiser profile. Please try again later.', callBack);
  }

  const getRuleFieldEnum = () => {
    let callBack = apiCallBack([{state:'fieldsEnum', key:'data'}]);
	  httpGet('autoAssign/rules/field/value', '', 'Oops, something went wrong and could not load the rule fields. Please try again later.', callBack);
  }

  const getBlackList = () => {
    let callBack = apiCallBack([{state:'blackList', key:'data'}]);
	  httpGet('appraiser/blackList/'+state.id, '', 'Oops, something went wrong and could not load this appraiser black list. Please try again later.', callBack);
  }

  const getPreferredCommunication = () => {
    let callBack = (response)=>{console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let preferredCommunication = [{key:'',value:'empty'}];

        for(let i=0;i<response.data.data.length;i++){
          let tmp = {}
          tmp.key=response.data.data[i].method;
          tmp.value=response.data.data[i].method;
          preferredCommunication.push(tmp);
        }
        setState({preferredCommunication:preferredCommunication});
      }
    };
    callBack = callBack.bind(this);

    httpGet('appraiser/communicationMethod/get', '', 'Oops, something went wrong and could not load preferred communications. Please try again later.', callBack);
  }

  const getServiceRatings = () => {
    let callBack = apiCallBack([{state:'serviceRatings', key:'data'}]);
	  httpGet('appraiser/serviceRating/'+state.id, '', 'Oops, something went wrong and could not load this appraiser service rating. Please try again later.', callBack);
  }

  //get all the entries of appraisal type & fee of this appraiser
  const getAppraiserappraisalTypeFees = () => {
    let callBack = apiCallBack([{state:'appraisalTypeFees', key:'data'}]);
	  httpGet('appraiser/appraisalTypeFees/get/'+state.id, '', 'Oops, something went wrong and could not load this appraiser profile. Please try again later.', callBack);
  }

  // get all the covered county/zip for the specific appraiser
  const getAppraiserCoverage = () => {
    let callBack = apiCallBack([{state:'coverageCountyZip', key:'data'}]);
    httpGet('appraiser/coverage/get/'+state.id, '', 'Oops, something went wrong and could not load this appraiser coverage county/zip. Please try again later.', callBack);
  }

  //get a list of quality rating recorded of this appraiser
  const getAppraiserQualityRating = () => {
    let callBack = apiCallBack([{state:'qualityRatings', key:'data'}]);
    httpGet('appraiser/qualityRating/get/appraiser_fk='+state.id, '', 'Oops, something went wrong and could not load this appraiser\'s quality rating. Please try again later.', callBack);
  }

  const getAppraiserServiceRating = () => {
    let callBack = apiCallBack([{state:'serviceRatings', key:'data'}]);
    httpGet('appraiser/serviceRating/appraiser_fk='+state.id, '', 'Oops, something went wrong and could not load this appraiser\'s service rating. Please try again later.', callBack);
  }

  //function that fire when the infinite scroll reach bottom
  const loadMore = () => {
    //do not load if there is no more conditions or it's loading data
    if(state.hasMoreOrders&&!state.loading){
      //set loading equals to true so it won't fire off before we are done
      setState({loading:true});

      let url = 'appraisal/get/history=true&limit='+state.limit+'&offset='+state.offset+'&order='+state.order+'&sort='+state.sort+'&appraiser_fk='+state.id;

      //callback handler that update the state when http request return
      let callBack = (response)=>{
        let code = response.data?response.data.code:undefined;

        if(code!=='00'){
          setState({hasMoreOrders:false});
        }
        else{
          let newOrders = response.data.data;
          let hasMoreOrders = true;
          let newOffset = state.offset;
          let totalCount = response.data.count;

          //if http request return empty then no more results, end of list
          if(newOrders.length<=0){
            hasMoreOrders = false;
          }
          else{
            //increment the offset
            newOffset = state.offset + 1;
          }

          //concat the current array of history orders
          if(state.historyOrders.length>0){
            let temp = [...state.historyOrders,...newOrders];

            setState({historyOrders:temp});
          }
          else
            setState({historyOrders:newOrders});

          setState({hasMoreOrders:hasMoreOrders, offset:newOffset, totalCount:totalCount});
        }
      };
      callBack = callBack.bind(this);

      //error handler when the http request return with error
      let errorCallBack = apiCallBack([{state:'hasMoreOrders', value:false}]);

      //collect the promise and wait for it to finish performing it's task
      let promises = httpGet(url, '','Oops, something went wrong and could not load orders. Please try again later.', callBack, errorCallBack);
      promises
        .then(
          function(result){
            //set loading equals to false so the function could be fire off once again
            setState({loading:false});
          }
        );
    }
  }

  const addNewLicense = () => {
    let parameters = [
      {
        field:'appraiserFk',
        value:state.id
      },
      {
        field:'licenseNumber',
        value:state.newLicenseNumber
      },
      {
        field:'licenseType',
        value:state.newLicenseType
      },
      {
        field:'licenseState',
        value:state.newLicenseState
      },
      {
        field:'expirationDate',
        value:state.newLicenseExpiration
      }
    ];

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let appraiser = Object.assign({}, state.appraiser);
        let licenses = pushToArray(appraiser.licenses, response.data.data);
        appraiser.licenses = licenses;

        setState({appraiser:appraiser});
      }
    };
    callBack = callBack.bind(this);

    httpPost('appraiser/license/create', parameters, 'New license created successfully.', 'Oops, something went wrong and could not create the license. Please try again later.', callBack);
  }

  const addNewBlackList = () => {
    let parameters = [
      {
        field:'appraiserFk',
        value:state.id
      },
      {
        field:'type',
        value:'blackList'
      },
      {
        field:'description',
        value:state.newBlackListDescription
      },
    ];

    let callBack = apiCallBack([{state:'blackList', arraykey:'data', targetArray:state.blackList}, {state:'newBlackListDescription', value:''}]);
    httpPost('appraiser/blackList/create', parameters, 'Black list rule created successfully.', 'Oops, something went wrong and could not create the rule. Please try again later.', callBack);
  }

  const addNewBlackListCondition = () => {
    let select = true;

    for(let i=0;i<state.fieldsEnum.length;i++){
      //there is no drop down, the value should coming from text instead
      if(state.fieldsEnum[i].field===state.newBlackListConditionField){
        if(state.fieldsEnum[i].enum.length<=0)
          select = false;
      }
    }

    let value;

    if(select)
      value = state.newBlackListConditionValuesS;
    else
      value = state.newBlackListConditionValuesT;


    let parameters = [
      {
        field:'description',
        value:state.newBlackListConditionDescription
      },
      {
        field:'field',
        value:state.newBlackListConditionField
      },
      {
        field:'operator',
        value:state.newBlackListConditionOperator
      },
      {
        field:'value',
        value:value
      },
      {
        field:'type',
        value:'blackList'
      },
      {
        field:'ruleFk',
        value:state.newBlackListConditionRuleSelected
      },
    ];

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let list = state.blackList.slice();

        for(let i=0;i<list.length;i++){
          if(list[i].ID===state.newBlackListConditionRuleSelected){
            list[i].conditions.push(response.data.data);
          }
        }

        setState({blackList:list});
      }
    };
    callBack = callBack.bind(this);

    httpPost('appraiser/blackList/condition/create', parameters, 'Black list rule condition created successfully.', 'Oops, something went wrong and could not create the rule condition. Please try again later.', callBack);
  }

  const deleteQualityRating = (ID) => {
    let list = sliceFromArray(state.qualityRatings,'ID', ID);
    let callBack = apiCallBack([{state:'qualityRatings', value:list}]);

    httpDelete('appraiser/qualityRating/'+ID,'Quality rating deleted successfull.','Oops, something went wrong and could not delete the quality rating. Please try again later.', callBack);
  }

  const deleteLicense = (id) => {
    let appraiser = Object.assign({},state.appraiser);
    appraiser.licenses = sliceFromArray(appraiser.licenses,'ID', id);
    let callBack = apiCallBack([{state:'appraiser', value:appraiser}]);

    httpDelete('appraiser/license/'+id,'License deleted successfull.','Oops, something went wrong and could not delete the license. Please try again later.', callBack);
  }

  //delete a black list rule
  const deleteBlackListRule = (id) => {
    let list = sliceFromArray(state.blackList,'ID', id);
    let callBack = apiCallBack([{state:'blackList', value:list}]);
    httpDelete('appraiser/blackList/'+id,'Black list rule deleted successfull.','Oops, something went wrong and could not delete the rule. Please try again later.', callBack);
  }

  const removeServiceRating = (ID) => {
    let callBack = apiCallBack([], getAppraiserServiceRating());
    httpDelete('appraiser/serviceRating/'+ID,'Service rating entry deleted.','Oops, something went wrong and could not delete the service rating entry, please try again later.', callBack);
  }

  //delete a black list condition
  const deleteBlackListCondition = (id,conditionID) => {
    let list = sliceFromArray(state.blackList, ['ID','ID'], [id, conditionID], ['conditions']);
    let callBack = apiCallBack([{state:'blackList', value:list}]);
    httpDelete('appraiser/blackList/condition/'+conditionID,'Black list condition deleted successfull.','Oops, something went wrong and could not delete the condition. Please try again later.', callBack);
  }

  //get appraisal type
  const getAppraisalTypesLabel = () => {
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let appraisalTypes = [];

        for(let i=0;i<response.data.data.length;i++){
          let tmp = {
            key: response.data.data[i].appraisal_type,
            value: response.data.data[i].appraisal_type
          }
          appraisalTypes.push(tmp);
        }

        setState({appraisalTypes:appraisalTypes});
      }
    };
    callBack = callBack.bind(this);

    httpGet('appraisalType/label/get', '', 'Oops, something went wrong and could not load list of available report types label. Please try again later.', callBack);
  }

  const getAllClientProfiles = () => {
    let callBack = apiCallBack([{state:'clients', key:'data'}]);
	  httpGet('client/get/limit=-1&offset=-1', '', 'Oops, something went wrong and could not retrieve client profiles.', callBack);
  }

  const getAppraiserStats = () => {
    let callBack = apiCallBack([{state:'appraiserStats', key:'data'}]);
	  httpGet('appraiser/stats/'+state.id, '', 'Oops, something went wrong and could not load appraiser\'s stats. Please try again later.', callBack);
  }

  //render
  let qualityScore=<div>Rating not available</div>;

  if(state.appraiser.avg_quality_rating&&parseFloat(state.appraiser.avg_quality_rating)>=0){
    qualityScore = <div>
      <div id={"sixmonths_quality_rating"}>
        <ReactStars
            onChange={()=>{}}
            value={parseInt(state.appraiser.avg_quality_rating,10)}
            count={3}
            edit={false}
            half={true}
            size={20}
          />
      </div>
        <UncontrolledTooltip delay={{ "show": 0, "hide": 0 }} placement="left" target={"sixmonths_quality_rating"}>
          {'6 months: '+state.appraiser.avg_quality_rating+'/3.00'}
        </UncontrolledTooltip>
    </div>;
  }

  let lifetimeQualityScore=<div>Rating not available</div>;

  if(state.appraiser.qr_avg_score&&state.appraiser.qr_avg_score!==-1){
    lifetimeQualityScore = <div>
      <div id={"lifetime_quality_score"}>
        <ReactStars
            onChange={()=>{}}
            value={parseInt(state.appraiser.qr_avg_score,10)}
            count={3}
            edit={false}
            color2={'#fd7f00'}
            half={true}
            size={20}
          />
      </div>
        <UncontrolledTooltip delay={{ "show": 0, "hide": 0 }} placement="left" target={"lifetime_quality_score"}>
          {'Lifetime: '+state.appraiser.qr_avg_score+'/3.00'}
        </UncontrolledTooltip>
    </div>;
  }
  const { appraiserStats } = state;

  let config = {
    chart: {
      type: 'gauge',
      plotBackgroundColor: null,
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false,
      height:190
    },

    title: {
      text: null
    },

    pane: {
      startAngle: -150,
      endAngle: 150,
      background: [{
        backgroundColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, '#FFF'],
            [1, '#333']
          ]
        },
        borderWidth: 0,
        outerRadius: '50%'
      }, {
        backgroundColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, '#333'],
            [1, '#FFF']
          ]
        },
        borderWidth: 1,
        outerRadius: '48%'
      }, {
          // default background
      }, {
        backgroundColor: '#DDD',
        borderWidth: 0,
        outerRadius: '105%',
        innerRadius: '103%'
      }]
    },

    plotOptions:{
      gauge:{
        dataLabels:{
          style:{
            fontSize:'20px'
          }
        }
      }
    },
    credits: {
      enabled: false
    },

    // the value axis
    yAxis: {
      min: 0,
      max: 12,

      minorTickInterval: 'auto',
      minorTickWidth: 1,
      minorTickLength: 10,
      minorTickPosition: 'inside',
      minorTickColor: '#666',

      tickPixelInterval: 30,
      tickWidth: 2,
      tickPosition: 'inside',
      tickLength: 10,
      tickColor: '#666',
      labels: {
        distance:15,
      },
      title: {
        text: 'day',
        y:30
      },
      plotBands: [{
        from: 0,
        to: 4,
        color: '#55BF3B' // green
      }, {
        from: 4,
        to: 8,
        color: '#DDDF0D' // yellow
      }, {
        from: 8,
        to: 12,
        color: '#DF5353' // red
      }]
    },
    series: [{
      name: 'Turnaround',
      data: [1],
      tooltip: {
        valueSuffix: ' days'
      }
    }]
  };

  let successMessage = 'Appraiser profile updated.';
  let failedMessage = 'Failed to update the value of this field, please try again later.';
  let stateName, billingState;

  if(state.states.length>0){
    stateName =
      <MyXEditable
      type="select"
      value={state.appraiser.state}
      opt={state.states}
      updateFunc={
        generateUpdateFunction('appraiser/update',[{field:'field',value:'state'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
      }
    />;

    billingState =
      <MyXEditable
      type="select"
      value={state.appraiser.billing_state}
      opt={state.states}
      updateFunc={
        generateUpdateFunction('appraiser/update',[{field:'field',value:'billing_state'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
      }
    />;
  }

  let appraisalTypeFees;

  if(state.appraisalTypeFees.length>0){
    appraisalTypeFees = state.appraisalTypeFees.map(
      (appraisalTypeFee,index)=>{
        return(
          <tr key={index}>
            <td><i className="fa fa-times cursor-pointer" onClick={()=>removeAppraisalTypeFee(appraisalTypeFee.ID)}></i></td>
            <td>{appraisalTypeFee.appraisal_type}</td>
            <td>
              <MyXEditable
              type="text"
              prefix="$"
              value={appraisalTypeFee.fee}
              updateFunc={
                generateUpdateFunction('appraiser/appraisalTypeFees/update',[{field:'field',value:'fee'},{field:'ID',value:appraisalTypeFee.ID}],successMessage, failedMessage, (field, value)=>updateFieldCallBack2(appraisalTypeFee.ID, field, value))
              }
            /></td>
          </tr>
        );
      }
    );
  }

  let coverageCountyZip;

  if(state.coverageCountyZip.length>0){
    coverageCountyZip = state.coverageCountyZip.map(
      (covered,index)=>{
        return(
          <tr key={index}>
            <td><i className="fa fa-times cursor-pointer" onClick={()=>removeCoverage(covered.ID)}></i></td>
            <td>{covered.coverage_type}</td>
            <td><b>{covered.coverage_area}</b></td>
            <td>{covered.covered==='no'?<font className="red-color">{covered.covered}</font>:<font className="green-color">{covered.covered}</font>}</td>
          </tr>
        );
      }
    );
  }

  let blackList;

  if(state.blackList.length>0){
    blackList = state.blackList.map(
      (blackList, index)=>{
        return <tr key={index}><td>{blackList.description}</td></tr>;
      }
    )
  }

  let qualityRatings;

  if(state.qualityRatings.length>0){
    qualityRatings = state.qualityRatings.map(
      (qualityRating, index)=>{
        return(
          <tr key={index}>
            <td><i className="fa fa-times red-color cursor-pointer" onClick={(e)=>deleteQualityRating(qualityRating.ID)}/></td>
            <td>{formatDate(qualityRating.datetime_created)}</td>
            <td><div id={"recorded_issue"+qualityRating.ID}>
              <ReactStars
                  onChange={()=>{}}
                  value={parseInt(qualityRating.score,10)}
                  count={3}
                  edit={false}
                  half={true}
                  size={20}
                />
            </div>
              <UncontrolledTooltip delay={{ "show": 0, "hide": 0 }} placement="top" target={"recorded_issue"+qualityRating.ID}>
                {qualityRating.score+'/3.00'}
              </UncontrolledTooltip></td>
            <td>{qualityRating.comment}</td>
            <td>{qualityRating.record_user}</td>
            <td><NavLink target="_blank" to={"/appraisal/"+qualityRating.appraisal_fk}>{qualityRating.reference_num}</NavLink></td>
            <td>{qualityRating.appraisal_type}</td>
            <td>{qualityRating.rush}</td>
            <td>{qualityRating.complex}</td>
          </tr>
        );
      }
    );
  }


  let exclusionaryListTargets = state.exclusionaryListTargets.map(
    (target, index)=>{
      return (<div onClick={(e)=>removeExclusionaryListTarget(target.ID)} className="entity-container" key={index}>
        <i className="fa fa-minus red-color"></i> {target.company}
      </div>);
    }
  );

  let existingExclusionaryListTargets;

  if(state.appraiser.exclusionary_targets){
    existingExclusionaryListTargets = state.appraiser.exclusionary_targets.map(
      (target, index)=>{
        let loanTypeIcon;
        let company = '';

        if(target.loan_type === 'jumbo'){
          loanTypeIcon = <div className="display-inline dark-red-color"><span className="my-badge2">{target.loan_type.toUpperCase()}</span> </div>;
        }else if(target.loan_type === 'all'){
          loanTypeIcon = <div className="display-inline dark-blue-color"><span className="my-badge4">{target.loan_type.toUpperCase()}</span></div>;
        }

        if(parseInt(target.ID,10) !==0)
          company = target.company;
        else
          company = 'All Lender';
        return (
          <div className="entity-container">
            {company}&nbsp;{loanTypeIcon}
          </div>
        );
      }
    )
  }

  let blackLists;

  if(state.blackList&&state.blackList.length>0)
    blackLists = state.blackList.map(
      (list, index)=>{

        let conditions;

        conditions = list.conditions.map(
          (condition,index2)=>{
            return(
              <tr key={index2}>
                <td><i className="fa fa-times red-color cursor-pointer" onClick={()=>deleteBlackListCondition(list.ID, condition.ID)}></i> {condition.description}</td>
                <td>{condition.field}</td>
                <td>{condition.operator}</td>
                <td>{condition.value}</td>
              </tr>
            );
          }
        );

        return(
          <div key={index}>
            <Card>
              <CardHeader className="header-color">
                <i className="fa fa-times red-color cursor-pointer" onClick={
                  ()=>{
                    confirmation(
                      ()=>{deleteBlackListRule(list.ID)},
                      ()=>{},
                      'Delete auto assign rule?',
                      'Are you sure you want to delete this rule? You will lose all conditions that associated with this rule.');
                  }
                }></i> {list.description}
              </CardHeader>
              <CardBody>
                <Table className="table table-striped">
                  <thead>
                    <tr>
                      <th width="35%">Description</th>
                      <th width="15%">Field</th>
                      <th width="25%">Operator</th>
                      <th width="25%">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {conditions}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
            <br/>
          </div>
        );
      }
    );

  let valueWidget;

  if(state.newBlackListConditionValues&&state.newBlackListConditionValues.length>0&&state.newBlackListConditionOperator!=='like')
    valueWidget = <MySelect
      type="select"
      value={state.newBlackListConditionValuesS}
      onChange={(v)=>{blackListValueOnChange(v);setState({newBlackListConditionValuesS:v})}}
      options={state.newBlackListConditionValues.map((value)=>{
        return {label:value.label, value:value.value};
      })}
    />
  else
    valueWidget = <Input required={true} type="text" onChange={(e)=>setState({newBlackListConditionValuesT:e.target.value})} value={state.newBlackListConditionValuesT}/>;



  let date = null;
  if(state.newLicenseExpiration!=='')
    date = moment(state.newLicenseExpiration).toDate();


  let newConfig1 = config;
  let newConfig2 = config;
  let newConfig3 = config;


  if(appraiserStats){
    console.log(appraiserStats.avg_accepting);
    newConfig1 = Object.assign({}, config, {series:[{
      name: 'Accepting Time',
      data: [parseFloat(appraiserStats.avg_accepting)],
      tooltip: {
        valueSuffix: ' days'
      }
    }]});

    newConfig2 = Object.assign({}, config, {series:[{
      name: 'Scheduling Time',
      data: [parseFloat(appraiserStats.avg_scheduling)],
      tooltip: {
        valueSuffix: ' days'
      }
    }]});

    newConfig3 = Object.assign({}, config, {series:[{
      name: 'Reporting Time',
      data: [parseFloat(appraiserStats.avg_reporting)],
      tooltip: {
        valueSuffix: ' days'
      }
    }]});
  }

  return <div className="my-well">
    <FloatingMemo {...props} updateMemo={updateMemo} memos={state.appraiser.memo} id={state.id}/>
    <Row>
      <Col sm="6">
        <div className="page-title">
          <i className="fa fa-reorder"></i>&nbsp;Appraiser Profile - {state.appraiser.first_name+" "+state.appraiser.last_name}
        </div>
      </Col>
      <Col sm="6" className="align-right">
      </Col>
    </Row>
    <Row>
      <Col sm="6">
        <NavLink to={"/appraisers/"+state.params}>Back to appraiser list</NavLink>
      </Col>
      <Col sm="6" className="align-right">
        <span style={{verticalAlign:'top',fontWeight:'bold'}}>Panel Discussion</span>&nbsp;
        <Toggle
          checked={state.inPanelDiscussion}
          icons={false}
          onChange={handlePanelDiscussion} />&nbsp;
      </Col>
    </Row>
    <div className="my-divider"></div>
    {
      state.appraiser.messages.map(
        (message, index)=>{
          return(<Alert key={index} color="warning" className="my-alert">
              <i className="fa fa-information"></i> {message}
            </Alert>);
        }
      )
    }
    <br/>
    <NavLink to={"/report/appraiser-performance/appraiser="+state.appraiser.ID+"|"+state.appraiser.first_name+' '+state.appraiser.last_name}>View appraiser performance report</NavLink>&nbsp;|&nbsp;<NavLink to="#" onClick={(e)=>setState({newEmailPopUpDropDownOpen:!state.newEmailPopUpDropDownOpen})}>Change Email</NavLink>
    &nbsp;|&nbsp;<NavLink to="#" onClick={toggleEmailDropDown}>Manage email notifications</NavLink><br/>
    <label>Click here to verify if the appraiser is FHA approved:&nbsp;</label><a href={"https://entp.hud.gov/idapp/html/apprlook.cfm?name="+state.appraiser.last_name+"&fname="+state.appraiser.first_name+"&license="+state.appraiser.license_number} target="_blank" rel="noreferrer">Search by License & Name</a> &nbsp;|&nbsp;<a href={"https://entp.hud.gov/idapp/html/apprlook.cfm?name="+state.appraiser.last_name+"&fname="+state.appraiser.first_name+"&state="+state.appraiser.state_short} target="_blank" rel="noreferrer">Search by State & Name</a>
    <Nav tabs>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.activeTab === '1'?"active":"inactive" )}
          onClick={() => { activeTabToggle('1'); }}
          to="#"
        >
          Profile
        </NavLinkL>
      </NavItem>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.activeTab === '2'?"active":"inactive" ) }
          onClick={() => { activeTabToggle('2'); }}
          to="#"
        >
          Fee
        </NavLinkL>
      </NavItem>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.activeTab === '3'?"active":"inactive" ) }
          onClick={() => { activeTabToggle('3'); }}
          to="#"
        >
          Order History
        </NavLinkL>
      </NavItem>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.activeTab === '4'?"active":"inactive" ) }
          onClick={() => { activeTabToggle('4'); }}
          to="#"
        >
          <span className="my-badge">{state.appraiser.exclusionary_targets?state.appraiser.exclusionary_targets.length:0}</span> Exclusionary List
        </NavLinkL>
      </NavItem>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.activeTab === '6'?"active":"inactive" ) }
          onClick={() => { activeTabToggle('6'); }}
          to="#"
        >
            <span className="my-badge">{state.serviceRatings?state.serviceRatings.length:0}</span> Service Ratings
        </NavLinkL>
      </NavItem>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.activeTab === '5'?"active":"inactive" ) }
          onClick={() => { activeTabToggle('5'); }}
          to="#"
        >
            <span className="my-badge">{state.qualityRatings?state.qualityRatings.length:0}</span> Quality Ratings
        </NavLinkL>
      </NavItem>
    </Nav>
    <TabContent activeTab={state.activeTab}>
      <TabPane tabId="1">
        <Card>
          <CardHeader className="header-color">
            Basic Information
          </CardHeader>
          <CardBody>
            <Row>
              <Col sm="6">
                <Row>
                  <Col sm="5">
                    Date Joined
                  </Col>
                  <Col sm="7">
                    {formatDate(state.appraiser.datetime_created)}
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    Author
                  </Col>
                  <Col sm="7">
                    {state.appraiser.author}
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col sm="5">
                    First name
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="text"
                      value={state.appraiser.first_name}
                      updateFunc={
                        generateUpdateFunction('appraiser/update',[{field:'field',value:'first_name'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    Last name
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="text"
                      value={state.appraiser.last_name}
                      updateFunc={
                        generateUpdateFunction('appraiser/update',[{field:'field',value:'last_name'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    Exterior Appraisal Only
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="select"
                      opt={[{key:'yes',value:'yes'},{key:'no',value:'no'}]}
                      value={state.appraiser.exterior_only}
                      updateFunc={
                        generateUpdateFunction('appraiser/update',[{field:'field',value:'exterior_only'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    New Sign Up
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="select"
                      opt={[{key:'yes',value:'yes'},{key:'no',value:'no'}]}
                      value={state.appraiser.new_sign_up}
                      updateFunc={
                        generateUpdateFunction('appraiser/update',[{field:'field',value:'new_sign_up'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    Staff Appraiser
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="select"
                      opt={[{key:'yes',value:'yes'},{key:'no',value:'no'}]}
                      value={state.appraiser.staff_appraiser}
                      updateFunc={
                        generateUpdateFunction('appraiser/update',[{field:'field',value:'staff_appraiser'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    FHA Approved
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="select"
                      opt={[{key:'yes',value:'yes'},{key:'no',value:'no'}]}
                      value={state.appraiser.fha_approved}
                      updateFunc={
                        generateUpdateFunction('appraiser/update',[{field:'field',value:'fha_approved'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    ACE+ PDR Ready
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="select"
                      opt={[{key:'yes',value:'yes'},{key:'no',value:'no'},{key:'unknown',value:'unknown'}]}
                      value={state.appraiser.ace_pdr_ready}
                      updateFunc={
                        generateUpdateFunction('appraiser/update',[{field:'field',value:'ace_pdr_ready'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    ENV Ready
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="select"
                      opt={[{key:'yes',value:'yes'},{key:'no',value:'no'},{key:'unknown',value:'unknown'}]}
                      value={state.appraiser.env_ready}
                      updateFunc={
                        generateUpdateFunction('appraiser/update',[{field:'field',value:'env_ready'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    Desktop Appraisal Ready
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="select"
                      opt={[{key:'yes',value:'yes'},{key:'no',value:'no'},{key:'unknown',value:'unknown'}]}
                      value={state.appraiser.desktop_appraisal_ready}
                      updateFunc={
                        generateUpdateFunction('appraiser/update',[{field:'field',value:'desktop_appraisal_ready'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    Desk Review
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="select"
                      opt={[{key:'yes',value:'yes'},{key:'no',value:'no'},{key:'unknown',value:'unknown'}]}
                      value={state.appraiser.desk_review}
                      updateFunc={
                        generateUpdateFunction('appraiser/update',[{field:'field',value:'desk_review'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    Remote Appraisal Ready
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="select"
                      opt={[{key:'yes',value:'yes'},{key:'no',value:'no'},{key:'unknown',value:'unknown'}]}
                      value={state.appraiser.remote_appraisal_ready}
                      updateFunc={
                        generateUpdateFunction('appraiser/update',[{field:'field',value:'remote_appraisal_ready'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    ACE + PDR Ready
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="select"
                      opt={[{key:'yes',value:'yes'},{key:'no',value:'no'}]}
                      value={state.appraiser.ace_pdr_ready}
                      updateFunc={
                        generateUpdateFunction('appraiser/update',[{field:'field',value:'ace_pdr_ready'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    Green Mortgage Experience
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="select"
                      opt={[{key:'very',value:'Very Experienced'},{key:'some',value:'Some Experienced'},{key:'no',value:'No Experience'},{key:'unknown',value:'unknown'}]}
                      value={state.appraiser.green_renovate_exp}
                      updateFunc={
                        generateUpdateFunction('appraiser/update',[{field:'field',value:'green_renovate_exp'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    Status
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="select"
                      opt={[{key:'New',value:'New'},{key:'New Approved',value:'New Approved'},{key:'Approved',value:'Approved'},{key:'Declined',value:'Declined'},{key:'Dormant',value:'Dormant'}]}
                      value={state.appraiser.status}
                      updateFunc={
                        generateUpdateFunction('appraiser/update',[{field:'field',value:'status'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    Company name
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="text"
                      value={state.appraiser.company_name}
                      updateFunc={
                        generateUpdateFunction('appraiser/update',[{field:'field',value:'company_name'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    Make Check Payable To
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="text"
                      value={state.appraiser.billing_name}
                      updateFunc={
                        generateUpdateFunction('appraiser/update',[{field:'field',value:'billing_name'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    Email
                  </Col>
                  <Col sm="7">
                    {state.appraiser.email}
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    Notify Emails
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="text"
                      value={state.appraiser.notify_emails}
                      updateFunc={
                        generateUpdateFunction('appraiser/update',[{field:'field',value:'notify_emails'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    SMS Subscription
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="select"
                      opt={[{key:'yes',value:'yes'},{key:'no',value:'no'}]}
                      value={state.appraiser.sms_subscription}
                      updateFunc={
                        generateUpdateFunction('appraiser/update',[{field:'field',value:'sms_subscription'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    Office Phone
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="text"
                      value={state.appraiser.phone}
                      updateFunc={
                        generateUpdateFunction('appraiser/update',[{field:'field',value:'phone'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    Cell Phone
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="text"
                      value={state.appraiser.secondary_phone}
                      updateFunc={
                        generateUpdateFunction('appraiser/update',[{field:'field',value:'secondary_phone'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    SMS To Phone
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="select"
                      opt={[{key:'cell',value:'cell'},{key:'office',value:'office'}]}
                      value={state.appraiser.sms_phone}
                      updateFunc={
                        generateUpdateFunction('appraiser/update',[{field:'field',value:'sms_phone'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    Service Rating
                  </Col>
                  <Col sm="7">
                    <div id="sixmonths_service_rating">
                      <font color="red">{state.appraiser.avg_service_rating&&parseFloat(state.appraiser.avg_service_rating)>=0?state.appraiser.avg_service_rating+"/5.00":"Rating not available"}</font>
                    </div>
                    <div id="lifetime_service_rating">
                      <font color='#fd7f00'>{state.appraiser.sr_avg_score&&parseFloat(state.appraiser.sr_avg_score)>=0?state.appraiser.sr_avg_score+"/5.00":"Rating not available"}</font>
                    </div>
                    <NavLink to="#">
                      <i className="fa fa-history"></i> View service rating history
                    </NavLink>
                    <UncontrolledTooltip delay={{ "show": 0, "hide": 0 }} placement="left" target="sixmonths_service_rating">
                      6 months
                    </UncontrolledTooltip>
                    <UncontrolledTooltip delay={{ "show": 0, "hide": 0 }} placement="left" target="lifetime_service_rating">
                      Lifetime
                    </UncontrolledTooltip>
                    <Modal className="my-modal-wide" isOpen={state.exclusionaryListPopUp} toggle={exclusionaryListToggle} >
                      <ModalHeader hidden={true} toggle={exclusionaryListToggle}></ModalHeader>
                      <ModalBody>
                        <center>
                          <h5><i className="fa fa-history"></i> Exclusionary List</h5>
                        </center>
                        <br/>
                        <label>Comment</label>
                        <Input type="textarea" rows="5" value={state.exclusionaryListComment} onChange={(e)=>setState({exclusionaryListComment:e.target.value})}/>
                        <br/>
                        <br/>
                        <label>Target (Default All Lender)</label>
                        <div className="medium-scroll-container">
                          <Table className="table table-striped">
                            <tbody>
                              {
                                state.clients.map(
                                  (client, index)=>{
                                    return <tr className="cursor-pointer" key={index} onClick={(e)=>addToExclusionaryListTarget(client)}><td>{client.company}</td></tr>
                                  }
                                )
                              }
                            </tbody>
                          </Table>
                        </div>
                        <br/>
                        <br/>
                        <div className="small-scroll-container">
                          {exclusionaryListTargets}
                        </div>
                        <center>
                          <Button color="warning" onClick={addToExclusionaryList}><i className="fa fa-check"></i>&nbsp;Submit</Button>{' '}
                          <Button color="info" onClick={exclusionaryListToggle}>Close</Button>
                        </center>
                      </ModalBody>
                    </Modal>
                    <Modal className="my-modal-wide" isOpen={state.newEmailPopUpDropDownOpen} toggle={newEmailToggle} >
                      <ModalHeader hidden={true} toggle={newEmailToggle}></ModalHeader>
                      <ModalBody>
                        <center>
                          <h5><i className="fa fa-history"></i> Change Email</h5>
                        </center>
                        <br/>
                        <b><font color="red">*** Warning! Please only use this as a last resort as this involve changing a lot of record in the database. Once executed you won't be able to revert it. ***</font></b>

                        <br/>
                        <label>New Email</label><br/>
                        <Input type="text" value={state.newEmail} onChange={(e)=>setState({newEmail:e.target.value})}/>
                        <br/>
                        <center>
                          <Button color="warning" onClick={changeEmail}><i className="fa fa-check"></i>&nbsp;Submit</Button>{' '}
                          <Button color="info" onClick={newEmailToggle}>Close</Button>
                        </center>
                      </ModalBody>
                    </Modal>
                    <Modal className="my-modal" isOpen={state.appraiserEmailDropDownOpen} toggle={toggleEmailDropDown} >
                      <ModalHeader hidden={true} toggle={toggleEmailDropDown}></ModalHeader>
                      <ModalBody>
                        <center>
                          <h5><i className="fa fa-plus"></i> Email notification</h5>
                        </center>
                        <br/>
                        <div className="large-scroll-container">
                          <table className="table table-striped">
                            <tbody>
                            {
                              state.appraiserEmailSettings.map(
                                (emailSetting,index)=>{
                                  return(
                                    <tr key={index}>
                                      <td>
                                          <Row>
                                            <Col sm="6">
                                              {emailSetting.subject}
                                            </Col>
                                            <Col sm="6" className="align-right">
                                              <Toggle
                                                checked={emailSetting.blocked==='no'}
                                                icons={false}
                                                onChange={()=>emailNotificationToggle(emailSetting.subject)} />
                                            </Col>
                                          </Row>
                                      </td>
                                    </tr>
                                  )
                                }
                              )
                            }
                            </tbody>
                          </table>
                        </div>
                        <br/>
                        <center>
                          <Button color="info" onClick={toggleEmailDropDown}>Close</Button>
                        </center>
                      </ModalBody>
                    </Modal>
                    <Modal className="my-modal-wide" isOpen={state.blackListedAppraisalTypePopUp} toggle={toggleBlackListAppraisalType} >
                      <ModalHeader hidden={true} toggle={toggleBlackListAppraisalType}></ModalHeader>
                      <ModalBody>
                        <center>
                          <h5><i className="fa fa-history"></i> Exclusionary List - Report Type</h5>
                        </center>
                        <form onSubmit={addNewBlackListedAppraisalType}>
                          <br/>
                          <label>Report Type</label>
                          <MySelect 
                            modal={true}
                            type="select"
                            value={state.newBlackListedAppraisalType}
                            onChange={(v)=>setState({newBlackListedAppraisalType:v})}
                            options={state.appraisalTypes.map((appraisalType)=>{
                              return {label:appraisalType.value, value:appraisalType.value};
                            })}
                          />
                          <br/>
                          <center>
                            <Button color="warning"><i className="fa fa-check"></i>&nbsp;Submit</Button>{' '}
                            <Button color="info" onClick={toggleBlackListAppraisalType}>Close</Button>
                          </center>
                        </form>
                      </ModalBody>
                    </Modal>
                    <Modal className="my-modal-wide" isOpen={state.serviceRatingsHistoryDropDownOpen} toggle={serviceRatingToggle} >
                      <ModalHeader hidden={true} toggle={serviceRatingToggle}></ModalHeader>
                      <ModalBody>
                        <center>
                          <h5><i className="fa fa-history"></i> Service Rating history</h5>
                        </center>
                        <br/>
                        <div className="large-scroll-container">
                          <Table className="table table-striped">
                            <thead>
                              <tr>
                                <th width="15%">Reference #</th>
                                <th width="35%">Property address</th>
                                <th width="15%">Rating</th>
                                <th width="20%">Comment</th>
                                <th width="15%">Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                state.serviceRatings.map(
                                  (serviceRating, index)=>{
                                    return(
                                      <tr key={index}>
                                        <td><NavLink target="_blank" to={"/appraisal/"+serviceRating.appraisal_fk}>{serviceRating.reference_num}</NavLink></td>
                                        <td>{serviceRating.property_street+' '+serviceRating.property_city+', '+serviceRating.property_state+' '+serviceRating.property_zip}</td>
                                        <td>
                                          <div id={"score"+index}>
                                            {serviceRating.score?serviceRating.score+"/5.00":"-"}
                                          </div>
                                        </td>
                                        <td>{serviceRating.comment}</td>
                                        <td>{formatDateTime(serviceRating.datetime_created)}</td>
                                      </tr>
                                    )
                                  }
                                )
                              }
                            </tbody>
                          </Table>
                        </div>
                        <br/>
                        <center>
                          <Button color="info" onClick={serviceRatingToggle}>Close</Button>
                        </center>
                      </ModalBody>
                    </Modal>
                    <Modal className="my-modal" isOpen={state.panelDiscussionDropDown} toggle={togglePanelDiscussion} >
                      <ModalHeader hidden={true} toggle={togglePanelDiscussion}></ModalHeader>
                      <ModalBody>
                        <center>
                          <h5><i className="fa fa-commenting-o"></i> Add to Panel Discussion</h5>
                        </center>
                        <br/>
                        <label>Related Appraisal</label><br/>
                        <MySelect
                          modal={true}
                          type="select"
                          value={state.panelDiscussionAppraisalFk}
                          onChange={(v)=>setState({panelDiscussionAppraisalFk:v})}
                          options={state.historyOrders.map((order)=>{
                            return {label:order.reference_num+' - '+order.loan_num+' - '+order.property_street+' '+order.property_city+', '+order.property_state+' '+order.property_zip, value:order.ID};
                          })}
                        />
                        <br/>
                        <Input type="textarea" placeholder="Please give the reason why you are requesting not to use this appraiser." value={state.panelDiscussion.comment} onChange={(e)=>{let panelDiscussion = Object.assign({},state.panelDiscussion);panelDiscussion.comment = e.target.value;setState({panelDiscussion})}} className="new-comment-reply" rows="4"/>

                        <center>
                          <Button color="warning" onClick={addToPanelDiscussion}>Add</Button>&nbsp;
                          <Button color="info" onClick={togglePanelDiscussion}>Close</Button>
                        </center>
                      </ModalBody>
                    </Modal>
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    Quality Rating
                  </Col>
                  <Col sm="7">
                    {qualityScore}
                    {lifetimeQualityScore}
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    On Time
                  </Col>
                  <Col sm="7">
                    <NavLink to={"/report/appraiser-performance/from="+state.appraiser.stats_from+"&to="+state.appraiser.stats_to+"&appraiser="+state.appraiser.ID+"|"+state.appraiser.first_name+' '+state.appraiser.last_name}>{state.appraiser.on_time}%</NavLink>
                  </Col>
                </Row>

                <Row>
                  <Col sm="5">
                    Tax ID
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="text"
                      value={state.appraiser.tax_id}
                      updateFunc={
                        generateUpdateFunction('appraiser/update',[{field:'field',value:'tax_id'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    Preferred Communications
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="select"
                      opt={state.preferredCommunication}
                      value={state.appraiser.preferred_communication}
                      updateFunc={
                        generateUpdateFunction('appraiser/update',[{field:'field',value:'preferred_communication'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    Payment method
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="select"
                      opt={[{key:'Check',value:'Check'},{key:'Direct Deposit',value:'Direct Deposit'}]}
                      value={state.appraiser.payment_method}
                      updateFunc={
                        generateUpdateFunction('appraiser/update',[{field:'field',value:'payment_method'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    Vacation Start
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="date"
                      value={state.appraiser.vacation_start}
                      updateFunc={
                        generateUpdateFunction('appraiser/update',[{field:'field',value:'vacation_start'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    Vacation End
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="date"
                      value={state.appraiser.vacation_end}
                      updateFunc={
                        generateUpdateFunction('appraiser/update',[{field:'field',value:'vacation_end'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    E&O Expiration Date
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="date"
                      value={state.appraiser.eo_expiration_date}
                      updateFunc={
                        generateUpdateFunction('appraiser/update',[{field:'field',value:'eo_expiration_date'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col sm="10">
                    <b>License</b>
                  </Col>
                  <Col sm="2" className="align-right">
                    <i className="fa fa-plus green-color cursor-pointer" onClick={toggleNewLicense}/>
                  </Col>
                </Row>
                <Row>
                  <Col sm="1">

                  </Col>
                  <Col sm="2">
                    Number
                  </Col>
                  <Col sm="3">
                    Type
                  </Col>
                  <Col sm="3">
                    State
                  </Col>
                  <Col sm="3">
                    Expiration Date
                  </Col>
                </Row>
                {
                  state.appraiser.licenses.map(
                    (license, index)=>{
                      return <Row key={index}>
                        <Col sm="1">
                          <i className="fa fa-minus red-color cursor-pointer" onClick={(e)=>{
                            confirmation(
                              ()=>{deleteLicense(license.ID)},
                              ()=>{},
                              'Delete license?',
                              'Are you sure you want to delete this license?');
                          }}/>
                        </Col>
                        <Col sm="2">
                          <MyXEditable
                            type="text"
                            value={license.license_number}
                            updateFunc={
                              generateUpdateFunction('appraiser/license/update',[{field:'field',value:'license_number'},{field:'ID',value:license.ID}],successMessage, failedMessage, (field, value)=>updateFieldCallBack3(license.ID, field, value))
                            }
                          />
                        </Col>
                        <Col sm="3">
                          <MyXEditable
                            type="select"
                            opt={[{key:'Unknown',value:'Unknown'},{key:'Transitional License',value:'Transitional License'},{key:'License',value:'License'},{key:'Certified Residential',value:'Certified Residential'},{key:'Certified General',value:'Certified General'}]}
                            value={license.license_type}
                            updateFunc={
                              generateUpdateFunction('appraiser/license/update',[{field:'field',value:'license_type'},{field:'ID',value:license.ID}],successMessage, failedMessage, (field, value)=>updateFieldCallBack3(license.ID, field, value))
                            }
                          />
                        </Col>
                        <Col sm="3">
                          <MyXEditable
                            type="select"
                            value={license.license_state}
                            opt={state.states}
                            updateFunc={
                              generateUpdateFunction('appraiser/license/update',[{field:'field',value:'license_state'},{field:'ID',value:license.ID}],successMessage, failedMessage, (field, value)=>updateFieldCallBack3(license.ID, field, value))
                            }
                          />
                        </Col>
                        <Col sm="3">
                          <MyXEditable
                            type="date"
                            value={license.datetime_expiration}
                            updateFunc={
                              generateUpdateFunction('appraiser/license/update',[{field:'field',value:'datetime_expiration'},{field:'ID',value:license.ID}],successMessage, failedMessage, (field, value)=>updateFieldCallBack3(license.ID, field, value))
                            }
                          />
                        </Col>
                      </Row>
                    }
                  )
                }
                <br/>
                <Modal className="my-modal-wide" isOpen={state.newLicenseDropDownOpen} toggle={toggleNewLicense} >
                  <ModalHeader hidden={true} toggle={toggleNewLicense}></ModalHeader>
                  <ModalBody>
                    <Card>
                      <CardHeader className="header-color">
                        <i className="fa fa-reorder"></i> License
                      </CardHeader>
                      <CardBody>
                        <form onSubmit={(e)=>{e.preventDefault();addNewLicense()}}>
                          <div style={{minHeight:'75px'}}/>
                          <Row>
                            <Col sm="3">
                              <label><font className="red-color">*</font>Number</label>
                              <Input type="text" required={true} onChange={(e)=>setState({newLicenseNumber:e.target.value})} value={state.newLicenseNumber}></Input>
                            </Col>
                            <Col sm="3">
                              <label><font className="red-color">*</font>State</label>
                              <MySelect
                                modal={true}
                                type="select"
                                value={state.newLicenseState}
                                onChange={(v)=>setState({newLicenseState:v})}
                                options={state.states.map((stateName)=>{
                                  return {label:stateName.value, value:stateName.value};
                                })}
                              />
                            </Col>
                            <Col sm="3">
                              <label><font className="red-color">*</font>Type</label>
                              <MySelect
                                modal={true}
                                type="select"
                                value={state.newLicenseType}
                                onChange={(v)=>setState({newLicenseType:v})}
                                options={[{label:"Unknown",value:"Unknown"},{label:"Transitional License",value:"Transitional License"},{label:"License",value:"License"},{label:"Certified Residential",value:"Certified Residential"},{label:"Certified General",value:"Certified General"}]}
                              />
                            </Col>
                            <Col sm="3">
                              <label><font className="red-color">*</font>Expiration</label><br/>
                              <DatePicker
                                className="form-control"
                                dateFormat="yyyy/MM/dd"
                                selected={date}
                                onChange={(text)=>{(text)&&setState({newLicenseExpiration:text.toLocaleDateString('en-CA')})}}
                              />
                            </Col>
                          </Row>
                          <br/>
                          <div style={{minHeight:'75px'}}/>
                          <div className="align-right"><Button color="warning">Create New License</Button></div>
                        </form>
                      </CardBody>
                    </Card>
                    <br/>
                    <center>
                      <Button color="info" onClick={toggleNewLicense}>Close</Button>
                    </center>
                  </ModalBody>
                </Modal>
              </Col>
              <Col sm="6">
                <Row>
                  <Col sm="5">
                    Street
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="text"
                      value={state.appraiser.street}
                      updateFunc={
                        generateUpdateFunction('appraiser/update',[{field:'field',value:'street'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    City
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="text"
                      value={state.appraiser.city}
                      updateFunc={
                        generateUpdateFunction('appraiser/update',[{field:'field',value:'city'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    County
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="text"
                      value={state.appraiser.county}
                      updateFunc={
                        generateUpdateFunction('appraiser/update',[{field:'field',value:'county'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    State
                  </Col>
                  <Col sm="7">
                    {stateName}
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    Zip
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="text"
                      value={state.appraiser.zip}
                      updateFunc={
                        generateUpdateFunction('appraiser/update',[{field:'field',value:'zip'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <br/>
                <label>Mail Check To</label>
                <Row>
                  <Col sm="5">
                    Street
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="text"
                      value={state.appraiser.billing_street}
                      updateFunc={
                        generateUpdateFunction('appraiser/update',[{field:'field',value:'billing_street'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    City
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="text"
                      value={state.appraiser.billing_city}
                      updateFunc={
                        generateUpdateFunction('appraiser/update',[{field:'field',value:'billing_city'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    County
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="text"
                      value={state.appraiser.billing_county}
                      updateFunc={
                        generateUpdateFunction('appraiser/update',[{field:'field',value:'billing_county'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    State
                  </Col>
                  <Col sm="7">
                    {billingState}
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    Zip
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="text"
                      value={state.appraiser.billing_zip}
                      updateFunc={
                        generateUpdateFunction('appraiser/update',[{field:'field',value:'billing_zip'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <br/>

                <br/>
                <Card>
                  <CardHeader className="header-color">
                    Appraiser Coverage County/Zip
                  </CardHeader>
                  <CardBody style={{height:'450px'}}>

                    <div className="large-scroll-container">
                      <Table className="table table-striped">
                        <thead>
                          <tr>
                            <th width="5%">Action</th>
                            <th width="15%">Type</th>
                            <th width="60%">Area</th>
                            <th width="20%">Covered</th>
                          </tr>
                        </thead>
                        <tbody>
                          {coverageCountyZip}
                        </tbody>
                      </Table>
                    </div>
                    <br/>
                    <div className="align-right" style={{ display: "flex", position: "absolute", right: "10px", bottom: "10px"}}>
                      <Button color="warning" onClick={newCountyZipToggle}><i className="fa fa-plus"></i> Add</Button>
                    </div>
                    <Modal className="my-modal" isOpen={state.newCountyZipDropDownOpen} toggle={newCountyZipToggle} >
                      <ModalHeader hidden={true} toggle={newCountyZipToggle}></ModalHeader>
                      <ModalBody>
                        <center>
                          <h5><i className="fa fa-plus"></i> Add New Coverage County/Zip</h5>
                        </center>
                        <br/>
                        <form onSubmit={submitNewCoverage}>
                          <Row>
                            <Col sm="4">
                              <label>Type</label>
                              <MySelect
                                modal={true}
                                type="select"
                                value={state.newAreaType}
                                onChange={(v)=>setState({newAreaType:v})}
                                options={[{label:"County",value:"county"},{label:"Zip",value:"zip"}]}
                              />
                            </Col>
                            <Col sm="4">
                              <label>Area</label>
                              <Input required="true" type="text" value={state.newArea} onChange={(e)=>setState({newArea:e.target.value})}/>
                            </Col>
                            <Col sm="4">
                              <label>Covered</label>
                              <MySelect 
                                modal={true}
                                type="select"
                                value={state.newAreaCovered}
                                onChange={(v)=>setState({newAreaCovered:v})}
                                options={[{label:"Yes",value:"yes"},{label:"No",value:"no"}]}
                              />
                            </Col>
                          </Row>
                          <br/>
                          <br/>
                          <center>
                            <Button color="warning"><i className="green-color fa fa-plus"></i>&nbsp;Add</Button>&nbsp;
                            <Button color="info" onClick={newCountyZipToggle}>Close</Button>
                          </center>
                        </form>
                      </ModalBody>
                    </Modal>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <br/>

        <Row>
          <Col sm="6">
            <Card>
              <CardHeader className="header-color">
                Appraiser Stats
              </CardHeader>
              <CardBody style={{height:'504px'}}>
                    <Row>
                      <Col sm='4'>
                        <center><label>Avg Accepting Time</label></center>
                        {(appraiserStats.avg_accepting||appraiserStats.avg_accepting===0)&&<MyGauge options = {newConfig1} ></MyGauge>}
                      </Col>
                      <Col sm='4'>
                        <center><label>Avg Scheduling Time</label></center>
                        {(appraiserStats.avg_scheduling||appraiserStats.avg_accepting===0)&&<MyGauge options = {newConfig2} ></MyGauge>}
                      </Col>
                      <Col sm='4'>
                        <center><label>Avg Reporting Time</label></center>
                        {(appraiserStats.avg_reporting||appraiserStats.avg_accepting===0)&&<MyGauge options = {newConfig3} ></MyGauge>}
                      </Col>
                    </Row>
                  <br/>
                    <Row>
                      <Col sm="12">
                        <center>
                          Stats From: {formatDate(appraiserStats.stats_from)}<br/>
                          Stats To: {formatDate(appraiserStats.stats_to)}<br/>
                          <br/>
                          Avg Quote Acceptance: {appraiserStats.avg_quote_acceptance}%<br/>
                          Avg Turnaround Time: {appraiserStats.avg_tat} days<br/>
                          Avg On Time: {appraiserStats.on_time}%<br/>
                          <br/>
                          Report Upload Time Range: {appraiserStats.upload_time_range}
                          <br/>
                          Total Order: {appraiserStats.total_order}
                        </center>
                      </Col>
                    </Row>
              </CardBody>
            </Card>
          </Col>
          <Col sm="6">
            <Card>
              <CardHeader className="header-color">
                Document Storage
              </CardHeader>
              <CardBody style={{height:'504px'}}>
                <AppraiserFile appraiserFk={state.id} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </TabPane>
    </TabContent>

    <TabContent activeTab={state.activeTab}>
      <TabPane tabId="4">
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="header-color">
                Exclusionary List
              </CardHeader>
              <CardBody>
                <Row>
                  <Col sm="12">
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col sm="6">
                            <label><b>Lender Exclusionary List</b></label>
                          </Col>
                          <Col sm="6" className="align-right">
                            <Button color="warning" onClick={exclusionaryListToggle}>+ Exclusionary list</Button>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <div className="medium-scroll-container">
                          {
                            existingExclusionaryListTargets
                          }
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col sm="12">
                    <br/>
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col sm="8">
                            Black List
                          </Col>
                          <Col sm="4" className="align-right">
                            <Button color="warning" onClick={toggleBlackList}><i className="fa fa-plus"></i> Manage Black List</Button>
                            <Modal className="my-modal" isOpen={state.blackListDropDownOpen} toggle={toggleBlackList} >
                              <ModalHeader hidden={true} toggle={toggleBlackList}></ModalHeader>
                              <ModalBody>
                                <center>
                                  <h5><i className="fa fa-comments"></i> Manage Black List</h5>
                                </center>
                                <br/>
                                <Card>
                                  <CardHeader className="header-color">
                                    <i className="fa fa-reorder"></i> Black List Rule
                                  </CardHeader>
                                  <CardBody>
                                    <p><font className="red-color">*</font>Please create a rule first then add conditions to it.</p>

                                    <div className="medium-scroll-container">
                                      {blackLists}
                                    </div>

                                    <br/>
                                    <div className="my-divider"></div>

                                    <i className="fa fa-plus green-color"></i> Add black list rules<br/>
                                    <label>Rule description</label>
                                    <Row>
                                      <Col sm="8">
                                        <Input type="text" value={state.newBlackListDescription} onChange={(e)=>setState({newBlackListDescription:e.target.value})} />
                                      </Col>
                                      <Col sm="4" className="align-right">
                                        <Button color="warning" onClick={addNewBlackList}>Create rule</Button>
                                      </Col>
                                    </Row>
                                    <br/>
                                    <div className="my-divider"></div>
                                    <p><font className="red-color">*</font>In order for a rule to be triggered, all conditions that associated with it must be full fill.</p>

                                    <i className="fa fa-plus green-color"></i> Add black list rules conditions<br/>

                                    <form onSubmit={(e)=>{e.preventDefault();addNewBlackListCondition()}}>
                                      <Row>
                                        <Col sm="6">
                                          <label><font className="red-color">*</font>Associated rule</label>
                                          <MySelect
                                            modal={true}
                                            type="select"
                                            value={state.newBlackListConditionRuleSelected}
                                            onChange={(v)=>setState({newBlackListConditionRuleSelected:v})}
                                            options={state.blackList.map((rule)=>{
                                              return {label:rule.description, value:rule.ID};
                                            })}
                                          />
                                        </Col>
                                        <Col sm="6">
                                          <label>Description</label>
                                          <Input type="text" onChange={(e)=>setState({newBlackListConditionDescription:e.target.value})} value={state.newBlackListConditionDescription}></Input>
                                        </Col>
                                      </Row>

                                      <Row>
                                        <Col sm="4">
                                          <label><font className="red-color">*</font>Field name</label>
                                          <MySelect
                                            modal={true}
                                            type="select"
                                            value={state.newBlackListConditionField}
                                            onChange={(v)=>setState({newBlackListConditionField:v})}
                                            options={state.fieldsEnum.map((field)=>{
                                              return {label:field.label, value:field.field};
                                            })}
                                          />
                                        </Col>
                                        <Col sm="4">
                                          <label><font className="red-color">*</font>Operator</label>
                                          <MySelect
                                            modal={true}
                                            type="select"
                                            value={state.newBlackListConditionOperator}
                                            onChange={(v)=>setState({newBlackListConditionOperator:v})}
                                            options={[{label:"Equal to",value:"="},{label:"Not equal to",value:"!="},{label:"Like",value:"like"}]}
                                          />
                                        </Col>
                                        <Col sm="4">
                                          <label><font className="red-color">*</font>Value</label>
                                          {valueWidget}
                                        </Col>
                                      </Row>
                                      <br/>
                                      <div className="align-right"><Button color="warning">Create condition</Button></div>
                                    </form>
                                  </CardBody>
                                </Card>
                                <br/>
                                <center>
                                  <Button color="info" onClick={toggleBlackList}>Close</Button>
                                </center>
                              </ModalBody>
                            </Modal>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <div className="medium-scroll-container">
                          <Table className="table table-striped">
                            <tbody>
                              {blackList}
                            </tbody>
                          </Table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </TabPane>
      <TabPane tabId="5">
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="header-color">
                Quality Ratings
              </CardHeader>
              <CardBody>
                <br/>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col sm="8">
                        <label><b>Quality Ratings</b></label>
                      </Col>
                      <Col sm="4">

                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="medium-scroll-container">
                      <Table className="table table-striped">
                        <thead>
                          <tr>
                            <th width="5%"></th>
                            <th width="10%">Date</th>
                            <th width="10%">Rating</th>
                            <th width="20%">Comment</th>
                            <th width="10%">Recorded By</th>
                            <th width="10%">Appraisal</th>
                            <th width="15%">Report Type</th>
                            <th width="10%">Rush</th>
                            <th width="10%">Complex</th>
                          </tr>
                        </thead>
                        <tbody>
                          {qualityRatings}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </TabPane>
      <TabPane tabId="6">
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="header-color">
                Service Ratings (Up to 1 Year)
              </CardHeader>
              <CardBody>
                <br/>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col sm="8">
                        <label><b>Service Rating</b></label>
                      </Col>
                      <Col sm="4">

                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="ultra-large-scroll-container">
                      <Table className="table table-striped">
                        <tbody>
                        {
                          state.serviceRatings.map(
                            (serviceRating, index)=>{

                              return(
                                <tr key={index}>
                                  <td>
                                    <div>
                                      <Row>
                                        <Col sm="2">
                                          <div className="margin-top">
                                            <NavLink to={"/appraisal/"+serviceRating.appraisal_fk}>{serviceRating.reference_num}</NavLink>
                                          </div>
                                        </Col>
                                        <Col sm="4">
                                          {serviceRating.loan_type} - {serviceRating.appraisal_type}
                                        </Col>
                                        <Col sm="2">
                                          {serviceRating.rush}
                                        </Col>
                                        <Col sm="2">
                                          {serviceRating.complex}
                                        </Col>
                                        <Col sm="2" className="align-right">
                                          {serviceRating.score?serviceRating.score+"/5.00":"-"}
                                        </Col>
                                      </Row>
                                      {
                                        serviceRating.items.map(
                                          (item, index2)=>{
                                            let score = item.deduct_score;
                                            if(item.deduct_score>0)
                                              score = <div className="green-color"><b>+{item.deduct_score}</b></div>;
                                            return (
                                              <div  style={{borderBottom:'1px solid #d2d2d2',paddingTop:'10px'}} key={index2}>
                                                <Row>
                                                  <Col sm="2">
                                                    <i className="fa fa-times red-color cursor-pointer" onClick={(e)=>{
                                                      confirmation(
                                                        ()=>{removeServiceRating(item.ID)},
                                                        ()=>{},
                                                        'Delete service rating entry?',
                                                        'Are you sure you want to delete this service rating entry?');
                                                    }}></i> {item.author}<br/>
                                                    <div style={{fontSize:'10px'}}>{formatDateTime(item.datetime_created)}</div>
                                                  </Col>
                                                  <Col sm="8">
                                                    {item.description}<br/>
                                                    {item.comment}
                                                  </Col>
                                                  <Col sm="2" className="align-right">
                                                    {score}
                                                  </Col>
                                                </Row>
                                              </div>
                                            )
                                          }
                                        )
                                      }
                                    </div>
                                  </td>
                                </tr>
                              );
                            }
                          )
                        }
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </TabPane>
    </TabContent>

    <TabContent activeTab={state.activeTab}>
      <TabPane tabId="3">
        <Card>
          <CardHeader className="header-color">
            Order History
          </CardHeader>
          <CardBody style={{height:'650px'}}>
            <div className="ultra-large-scroll-container">
              <InfiniteScroll
                next={loadMore}
                dataLength={state.historyOrders.length}
                hasMore={state.hasMoreOrders}
                loader={<div key="nill" className="loader"><center>Loading more orders...</center></div>}
                initialLoad = {true}
                className="my-well"
                scrollableTarget="contentContainer"
              >
                {renderHistoryAppraisals()}
              </InfiniteScroll>
            </div>
          </CardBody>
        </Card>
      </TabPane>
    </TabContent>

    <TabContent activeTab={state.activeTab}>
      <TabPane tabId="2">
        <Row>
          <Col sm="4">
            <Card>
              <CardHeader className="header-color">
                Fee & Report Type
              </CardHeader>
              <CardBody style={{height:'504px'}}>

                <div className="large-scroll-container">
                  <Table className="table table-striped">
                    <thead>
                      <tr>
                        <th width="15%">Action</th>
                        <th width="60%">Report Type</th>
                        <th width="25%">Fee</th>
                      </tr>
                    </thead>
                    <tbody>
                      {appraisalTypeFees}
                    </tbody>
                  </Table>
                </div>
                <br/>
                <div className="align-right"><Button color="warning" onClick={newAappraisalTypeToggle}><i className="fa fa-plus"></i> Add</Button></div>
                <Modal className="my-modal" isOpen={state.newAppraisalTypeDropDownOpen} toggle={newAappraisalTypeToggle} >
                  <ModalHeader hidden={true} toggle={newAappraisalTypeToggle}></ModalHeader>
                  <ModalBody>
                    <center>
                      <h5><i className="fa fa-plus"></i> Add New Report Type & Fee</h5>
                    </center>
                    <form onSubmit={submitNewAppraisalTypeFee}>
                      <label>Report Type:</label>
                      <MySelect
                        modal={true}
                        type="select"
                        value={state.newAppraisalTypeFeeSelection}
                        onChange={(v)=>setState({newAppraisalTypeFeeSelection:v})}
                        options={state.appraisalTypes.map((appraisalType)=>{
                          return {label:appraisalType.value, value:appraisalType.value};
                        })}
                      />
                      <br/>
                      <label>Fee:</label>
                      <Input required="true" type="text" value={state.newAppraisalTypeFeeAmount} onChange={(e)=>setState({newAppraisalTypeFeeAmount:e.target.value})}/>

                      <br/>
                      <center>
                        <Button color="warning"><i className="green-color fa fa-plus"></i>&nbsp;Add</Button>&nbsp;
                        <Button color="info" onClick={newAappraisalTypeToggle}>Close</Button>
                      </center>
                    </form>
                  </ModalBody>
                </Modal>
              </CardBody>
            </Card>
          </Col>
          <Col sm="4">
            <Card>
              <CardHeader className="header-color">
                Average Fee
              </CardHeader>
              <CardBody style={{height:'504px'}}>
                <Row>
                  <Col sm="8">
                    <label>Report Type</label>
                  </Col>
                  <Col sm="4">
                    <label>Average Fee</label>
                  </Col>
                </Row>
                {state.appraiserStats.type_fee &&
                  state.appraiserStats.type_fee.map(
                    (feeReport, index)=>{
                      return(
                        <Row>
                          <Col sm="8">
                            {feeReport['appraisal_type']}
                          </Col>
                          <Col sm="4">
                            ${feeReport['avg_fee']}
                          </Col>
                        </Row>
                      )
                    }
                  )
                }
                {!state.appraiserStats.type_fee &&<Row><Col sm="7">None</Col></Row>}
              </CardBody>
            </Card>
          </Col>
          <Col sm="4">
            <Card>
              <CardHeader className="header-color">
                Average Quote Fee
              </CardHeader>
              <CardBody style={{height:'504px'}}>
                <Row>
                  <Col sm="8">
                    <label>Report Type</label>
                  </Col>
                  <Col sm="4">
                    <label>Average Fee</label>
                  </Col>
                </Row>
                {state.appraiserStats.type_quote_fee &&
                  state.appraiserStats.type_quote_fee.map(
                    (feeReport, index)=>{
                      return(
                        <Row>
                          <Col sm="8">
                            {feeReport['appraisal_type']}
                          </Col>
                          <Col sm="4">
                            ${feeReport['avg_fee']}
                          </Col>
                        </Row>
                      )
                    }
                  )
                }
                {!state.appraiserStats.type_quote_fee &&<Row><Col sm="7">None</Col></Row>}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </TabPane>
    </TabContent>
  </div>;
}


export default Appraiser;
