//Author Sooyoung Kim
//Date April 27, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, putAPICallGenerator, deleteAPICallGenerator, callBackGenerator, confirmation} from '../../util/util';
import { Button, Input, Row, Col } from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import {NavLink, useParams, useNavigate} from 'react-router-dom';
import ReactQuill from 'react-quill';


//initialize the state
const initialState = {
  body: '',
  creator: '',
  title: '',
  datetimeCreated: '',
};

//reducer function that perform state update
const reducer = getReducer();


const Announcement  = (props)=>{
  const controller = new AbortController();
  const { id } = useParams();
  const navigate = useNavigate();

  let newInitialState = Object.assign({}, initialState, {id:id});
  const [state, dispatch] = useReducer(reducer, newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getAnnouncement(state.id);

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  //API call
  //retrieve announcement
  const getAnnouncement = (id) =>{
    let url = 'announcement/'+id;
    let callBack = apiCallBack([{state:'title', key:'data.title'},{state:'body', key:'data.body'},{state:'creator', key:'data.creator'},{state:'datetimeCreated', key:'data.datetimeCreated'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load this announcement. Please try again later.', callBack);
  }

  //update the announcement
  const updateAnnouncement = () =>{
    let parameters = [
      {
        field:'body',
        value:state.body
      },
      {
        field:'creator',
        value:state.creator
      },
      {
        field:'title',
        value:state.title
      },
      {
        field:'ID',
        value:state.id
      }
    ];

    httpPut('announcement/update', parameters, 'Announcement updated.', 'Oops, something went wrong and could not update this announcement. Please try again later.');
  }

  //delete announcement
  const deleteAnnouncement = (id) =>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        navigate('/announcement');
      }
    };
    callBack = callBack.bind(this);

    httpDelete('announcement/'+id,'Announcement deleted.','Oops, something went wrong and could not delete this announcement. Please try again later.', callBack);
  }

  //render
  return <div className="card padding">
    <Row>
      <Col sm="8">
        <div className="page-title">
          <i className="fa fa-reorder"></i>&nbsp;Edit Announcement
        </div>
  			<NavLink to="/announcement">Back to announcement list</NavLink>
      </Col>
      <Col sm="4" className="align-right">
        <Button color="danger" onClick={
          ()=>{
            confirmation(
              ()=>{deleteAnnouncement(state.id)},
              ()=>{},
              'Delete announcement?',
              'Are you sure you want to delete this announcement?');
          }
        }><i className="fa fa-trash"></i>&nbsp;&nbsp;Delete</Button>&nbsp;&nbsp;
        <Button color="warning" onClick={()=>{updateAnnouncement()}}><i className="fa fa-edit"></i>&nbsp;&nbsp;Update</Button>
      </Col>
    </Row>
    <div className="hr"/>
    <br/>
    <div className="form-group">
  		<label>By: </label>
  		<Input value={state.creator} onChange={(text)=>{setState({creator:text.target.value})}}/>
  	</div>
    <br/>
    <div className="form-group">
  		<label>Title: </label>
  		<Input value={state.title} onChange={(text)=>setState({title:text.target.value})}/>
  	</div>
    <br/>
    <ReactQuill
      modules={
        {
          toolbar: [
              [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
              [{size: []}],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{'list': 'ordered'}, {'list': 'bullet'},
               {'indent': '-1'}, {'indent': '+1'}],
              ['link', 'image', 'video'],
              ['clean']
            ],
            clipboard: {
              // toggle to add extra line breaks when pasting HTML:
              matchVisual: false,
            },
            imageResize: {
            // parchment: Quill.import('parchment'),
              modules: ['Resize', 'DisplaySize']
            }
        }
      }
      formats={
        [
          'header', 'font', 'size',
          'bold', 'italic', 'underline', 'strike', 'blockquote',
          'list', 'bullet', 'indent',
          'link', 'image', 'video'
        ]
      }
      value={state.body}
      onChange={(value) => setState({body:value})}
    />
  </div>;
}

export default Announcement;
