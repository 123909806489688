//Author Sooyoung Kim
//Date Aug 22, 2023
import {generateTooltip} from '../../util/util';
import {Col, Row, Input} from 'reactstrap';
import MySelect from '../util/my-select';
import React, {useEffect} from 'react';

const StructureAmenity  = (props)=>{
  const controller = new AbortController();

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const updateField = (field, value) => {
    let amenities = props.amenities.slice();
    let target = amenities[props.index];
    if(value==='None'){
      target[field] = [];
    }
    else if(value!==''){
      let index = target[field].indexOf(value);

      if(index===-1)
        target[field].push(value);
    }
    amenities[props.index] = target;

    props.updateState({amenities:amenities});
  }

  const updateState = (field, value) => {
    let amenities = props.amenities.slice();
    let target = amenities[props.index];

    target[field] = value;

    amenities[props.index] = target;

    props.updateState({amenities:amenities});
  }

  //render
  let disabledStyling = {border:'none', background:'#fff', padding:'0',paddingLeft:'2px', height:'auto',WebkitAppearance:'none'};
  let amenitySubTypes;
  let options;

  if(props.amenities[props.index].amenityType==='Patio')
    options = <MySelect
        type="select"
        style={props.disabled?disabledStyling:{}}
        disabled={props.disabled}
        required={props.amenities[props.index].amenitySubTypes.length<=0}
        value={""}
        onChange={(v)=>updateField('amenitySubTypes',v)}
        options={[
          {label:"",value:""},
          {label:"Wood",value:"Wood"},
          {label:"Pressure Treated",value:"PressureTreated"},
          {label:"Composite Decking",value:"CompositeDecking"},
          {label:"Concrete Patio",value:"ConcretePatio"},
          {label:"Patio Block",value:"PatioBlock"},
          {label:"Natural Stone",value:"NaturalStone"},
          {label:"Stamped Concrete",value:"StampedConcrete"},
          {label:"Paver Brick",value:"PaverBrick"},
        ]}
      />;
  else if(props.amenities[props.index].amenityType==='Deck')
    options = <MySelect
          type="select"
          style={props.disabled?disabledStyling:{}}
          disabled={props.disabled}
          required={props.amenities[props.index].amenitySubTypes.length<=0}
          value={""}
          onChange={(v)=>updateField('amenitySubTypes',v)}
          options={[
            {label:"",value:""},
            {label:"Wood",value:"Wood"},
            {label:"Pressure Treated",value:"PressureTreated"},
            {label:"Composite Decking",value:"CompositeDecking"},
            {label:"Concrete Patio",value:"ConcretePatio"},
            {label:"Patio Block",value:"PatioBlock"},
            {label:"Natural Stone",value:"NaturalStone"},
            {label:"Stamped Concrete",value:"StampedConcrete"},
            {label:"Paver Brick",value:"PaverBrick"},
          ]}
        />;
  else if(props.amenities[props.index].amenityType==='Porch')
    options = <MySelect
        type="select"
        style={props.disabled?disabledStyling:{}}
        disabled={props.disabled}
        required={props.amenities[props.index].amenitySubTypes.length<=0}
        value={""}
        onChange={(v)=>updateField('amenitySubTypes',v)}
        options={[
          {label:"",value:""},
          {label:"Open",value:"Open"},
          {label:"Enclosed",value:"Enclosed"},
          {label:"Screened",value:"Screened"},
        ]}
      />;
  else if(props.amenities[props.index].amenityType==='Gazebo')
    options = <MySelect
          type="select"
          style={props.disabled?disabledStyling:{}}
          disabled={props.disabled}
          required={props.amenities[props.index].amenitySubTypes.length<=0}
          value={""}
          onChange={(v)=>updateField('amenitySubTypes',v)}
          options={[
            {label:"",value:""},
            {label:"Wood",value:"Wood"},
            {label:"Pressure Treated",value:"PressureTreated"},
            {label:"Composite Decking",value:"CompositeDecking"},
            {label:"Concrete Patio",value:"ConcretePatio"},
            {label:"Patio Block",value:"PatioBlock"},
            {label:"Natural Stone",value:"NaturalStone"},
            {label:"Stamped Concrete",value:"StampedConcrete"},
            {label:"Paver Brick",value:"PaverBrick"},
          ]}
        />;
  else if(props.amenities[props.index].amenityType==='InteriorPool')
    options = <MySelect
            type="select"
            style={props.disabled?disabledStyling:{}}
            disabled={props.disabled}
            required={props.amenities[props.index].amenitySubTypes.length<=0}
            value={""}
            onChange={(v)=>updateField('amenitySubTypes',v)}
            options={[
              {label:"",value:""},
              {label:"Concrete",value:"Concrete"},
              {label:"Gunite",value:"Gunite"},
              {label:"Vinyl",value:"Vinyl"},
              {label:"Fiberglass",value:"Fiberglass"},
              {label:"Optimum",value:"Optimum"},
              {label:"Built In Spa",value:"BuiltInSpa"},
            ]}
          />;

  if(options)
    amenitySubTypes = <Col sm="4">
      <label><font color="red">*</font>{props.amenities[props.index].amenityType} Sub Type {generateTooltip('amenitySubTypes'+props.index, 'Select all that apply.')}</label>
      {options}
      <div className="extra-small-scroll-container">
      {
        props.amenities[props.index].amenitySubTypes.map(
          (amenitySubType, index)=>{
            return(
              <div key={index} className="display-inline margin-top">
                <div className="filter-container" onClick={()=>{if(!props.disabled)updateField('amenitySubTypes', amenitySubType)}}>
                  <i hidden={props.disabled} className="fa fa-minus red-color"></i> {amenitySubType}
                </div>
                &nbsp;
              </div>
            );
          }
        )
      }
      </div>
    </Col>;

  let amenityTypeOtherDescription;

  if(props.amenities[props.index].amenityType==='Other')
    amenityTypeOtherDescription = <Col sm="3">
      <label><font color="red">*</font>Other Description</label>
      <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={true} type="text" value={props.amenityTypeOtherDescription} onChange={(e)=>{updateState('amenityTypeOtherDescription',e.target.value)}}/>
    </Col>;

  return <div>
    <Row style={{marginTop:'15px'}}>
      {amenitySubTypes}
      {amenityTypeOtherDescription}
      <Col sm="4">
        <label>{props.amenities[props.index].amenityType==='WoodStove'||props.amenities[props.index].amenityType==='Fireplace'?<font color="red">*</font>:null}{props.amenities[props.index].amenityType} Count</label>
        <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={props.amenities[props.index].amenityType==='WoodStove'||props.amenities[props.index].amenityType==='Fireplace'} type="number" value={props.amenities[props.index].amenityCount} onChange={(e)=>{updateState('amenityCount',e.target.value)}}/>
      </Col>
    </Row>
  </div>;
}


export default StructureAmenity;
