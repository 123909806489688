//Author Sooyoung Kim
//Date April 18, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator} from '../../util/util';
import {TabPane, TabContent, Nav, NavItem, Modal, ModalHeader, ModalBody, Button, Row, Col, Input} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import moment from 'moment';
import {NavLink} from 'react-router-dom';
import DatePicker from 'react-datepicker';
import MySelect from '../util/my-select';

let date = new Date(), y = date.getFullYear(), m = date.getMonth();
let firstDay = new Date(y, m, 1);
let lastDay = new Date(y, m + 1, 0);

let fromMonth = firstDay.getMonth()+1;
if(fromMonth<10)
  fromMonth = '0'+fromMonth;
let toMonth = lastDay.getMonth()+1;
if(toMonth<10)
  toMonth = '0'+toMonth;

let from = firstDay.getFullYear()+'-'+fromMonth+'-01';
let to = lastDay.getFullYear()+'-'+toMonth+'-'+lastDay.getDate();

//initialize the state
const initialState = {
  id: -1,
  activeTab:'1',
  clients:[],
  name:'',
  email:'',
  from:from,
  to:to,
  label:'',
  client:'',
  open:'',
  status:'',
  keyword:'',
  statuses:[],
  searchResult:[],
  entityList:['AE','CSR','Borrower','Co-Borrower','Appraiser','Loan Officer','Loan Processor','Broker','Broker Company','Other','Email Notification Subscriber'],
};

//reducer function that perform state update
const reducer = getReducer();


const AddRecipient  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);
  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getAllClientProfiles();
    getAllOrderStatuses();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const removeSearchResult = (index) =>{
    let searchResult = state.searchResult.slice();
    searchResult.splice(index, 1);

    setState({searchResult:searchResult});
  }

  const addNewContact = () =>{
    let tmp = {
      name: state.name,
      email: state.email
    };

    props.addEmail([tmp]);

    setState({name:'',email:''});
  }

  const addAllSearchResult = () =>{
    let searchResult = state.searchResult.slice();

    let toSend = [];
    for(let i=0;i<searchResult.length;i++){
      toSend.push({email:searchResult[i].entity_email, name:searchResult[i].entity_name});
    }

    props.addEmail(toSend);
  }

  //API call
  const searchEntity = () =>{
    let url = 'appraisal/entity/status='+state.status+'&client='+state.client+'&open='+state.open+'&from='+state.from+'&to='+state.to+'&keyword='+state.keyword+'&label='+state.label;
    let callBack = apiCallBack([{state:'searchResult',key:'data'}]);

    httpGet(url, '', 'Oops, something went wrong and could not search contacts. Please try again later.', callBack);
  }

  const getAllClientProfiles = () =>{
    let url = 'client/get/limit=-1&offset=-1';
    let callBack = apiCallBack([{state:'clients',key:'data'}]);

    httpGet(url, '', 'Oops, something went wrong and could not retrieve client profiles.', callBack);
  }

  const getAllOrderStatuses = () =>{
    let url = 'appraisal/statuses/get';
    let callBack = apiCallBack([{state:'statuses',key:'data'}]);

    httpGet(url, '', 'Oops, something went wrong and could not load list of available appraisal status. Please try again later.', callBack);
  }
  //render
  let from, to;
  if(state.from!=='')
    from = moment(state.from).toDate();
    //from = '2023-04-13'
  if(state.to!=='')
    to = moment(state.to).toDate();

  return <div>
    <Modal className="my-modal-wide" isOpen={props.emailDropdownOpen} toggle={props.toggleEmail} >
      <ModalHeader hidden={true} toggle={props.toggleEmail}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-comments"></i> Email Recipient</h5>
        </center>
        <br/>
        <label>Existing email recipient</label>
        <div className="medium-scroll-container-no-min">
          {
            props.emails.map(
              (email, index)=>{
                let action;

                if(email.status==='active')
                  action = <i className="fa fa-times red-color cursor-pointer" onClick={(e)=>props.removeRecipient(email.ID)}/>
                return <div className="entity-container">
                  &nbsp;{email.name} - {email.email} {action}
                </div>
              }
            )
          }
        </div>
        <br/>
        <div className="my-divider">&nbsp;</div>
        <br/>
        <label>Add Email Recipient</label>
        <br/>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={"nav-link "+(state.activeTab === '1'?"active":"inactive" ) }
              onClick={() => { setState({activeTab:'1'}); }}
              to="#"
            >
              Send Email
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={"nav-link "+(state.activeTab === '2'?"active":"inactive" ) }
              onClick={() => { setState({activeTab:'2'}); }}
              to="#"
            >
              Look Up Contacts
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={state.activeTab}>
          <TabPane tabId="1">
            <label>Name</label><br/>
            <Input type="text" value={state.name} onChange={(e)=>setState({name:e.target.value})}/>
            <br/>
            <label>Email</label><br/>
            <Input type="text" value={state.email} onChange={(e)=>setState({email:e.target.value})}/>
            <br/>
            <div className="align-right">
              <Button color="warning" onClick={addNewContact}>Send</Button>
            </div>
            <br/>
          </TabPane>
          <TabPane tabId="2">
          <Row>
            <Col sm="2">
              <b>From</b>
              <br/>
              <DatePicker
                className="form-control"
                dateFormat="yyyy/MM/dd"
                selected={from}
                onChange={(text)=>{(text)&&setState({from:text.toLocaleDateString('en-CA')})}}
              />
            </Col>
            <Col sm="2">
              <b>To</b>
              <br/>
              <DatePicker
                className="form-control"
                dateFormat="yyyy/MM/dd"
                selected={to}
                onChange={(text)=>{(text)&&setState({to:text.toLocaleDateString('en-CA')})}}
              />
            </Col>
            <Col sm="4">
              <b>Close By</b>
              <MySelect
                type="select"
                modal={true}
                selectIsClearable={true}
                value={state.client}
                onChange={(v)=>setState({client:v})}
                options={state.clients.map((client)=>{
                  return {label:client.company, value:client.ID};
                })}
              />
            </Col>
            <Col sm="4">
              <b>Open By</b>
              <MySelect
                modal={true}
                type="select"
                selectIsClearable={true}
                value={state.open}
                onChange={(v)=>setState({open:v})}
                options={state.clients.map((client)=>{
                  return {label:client.company, value:client.ID};
                })}
              />
            </Col>
          </Row>
          <Row>
            <Col sm="3">
              <label>Contact Name/Email</label><br/>
              <Input type="text" value={state.keyword} onChange={(e)=>setState({keyword:e.target.value})}/>
            </Col>
            <Col sm="3">
              <label>Contact Label</label><br/>
              <MySelect
                type="select"
                modal={true}
                selectIsClearable={true}
                value={state.label}
                onChange={(v)=>setState({label:v})}
                options={state.entityList.map((entity)=>{
                  return {label:entity, value:entity};
                })}
              />
            </Col>
            <Col sm="3">
              <label>Order Status</label><br/>
              <MySelect
                modal={true}
                type="select"
                selectIsClearable={true}
                value={state.status}
                onChange={(v)=>setState({status:v})}
                options={state.statuses.map((status)=>{
                  return {label:status.name, value:status.name};
                })}
              />
            </Col>
            <Col sm="3">
              <div className="align-right">
                <br/>
                <Button color="warning" onClick={searchEntity}>Look Up</Button>
              </div>
            </Col>
          </Row>
            <div className="medium-scroll-container">
              {
                state.searchResult.map(
                  (contact, index)=>{
                    return (<div className="entity-container cursor-pointer" onClick={(e)=>removeSearchResult(index)}>
                      <i className="fa fa-minus red-color"/> &nbsp;{contact.entity_name} - {contact.entity_email}
                    </div>)
                  }
                )
              }
            </div>
            <br/><br/>
            <div className="align-right">
              <Button color="warning" onClick={addAllSearchResult}>Send All</Button>
            </div>
          </TabPane>
        </TabContent>
        <center>
          <Button color="info" onClick={props.toggleEmail}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
  </div>;
}

export default AddRecipient;
