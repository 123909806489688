//Author Sooyoung Kim
//Date June 30, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, deleteAPICallGenerator, callBackGenerator, sliceFromArray} from '../../../util/util';
import {Button, Card, CardHeader, CardBody, Col, Row, Input, Table} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
//initialize the state
const initialState = {
  reportDetails:[],
  email:''
};

//reducer function that perform state update
const reducer = getReducer();


const EndOfDay  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getReportDetails();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  //API call
  const addNewRecipient = () => {
    let parameters = [
      {
        field:'email',
        value:state.email
      }
    ];

    let callBack = (response)=>{console.log(response);
      let code= response.data.code;
      if(code==='00'){
        let reportDetails = state.reportDetails.slice();

        reportDetails.push(response.data.data);

        setState({reportDetails:reportDetails});
      }
    };

    callBack = callBack.bind(this);
    httpPost('report/endOfDay/create', parameters, 'New recipient added to the End of Day Report.', 'Oops, something went wrong and could not add new recipient to this report. Please try again later.', callBack);
  }

  const deleteRecipient = (id) => {
    let list = sliceFromArray(state.reportDetails,'ID', id);
    let callBack = apiCallBack([{state:'reportDetails', value:list}]);
    httpDelete('report/endOfDay/'+id,'Recipient delted from the report successfully.','Oops, something went wrong and could not delete this recipient from the report. Please try again later.', callBack);
  }

  const getReportDetails = () => {
    let callBack = apiCallBack([{state:'reportDetails', key:'data'}]);
    httpGet('report/endOfDay/get', '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  //render
  return <div>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;End of Day Report
      </CardHeader>
      <CardBody>

        <b>Add new recipient</b><br/>
        <Row>
          <Col sm="8">
            <Input type="text" value={state.email} onChange={(e)=>setState({email:e.target.value})}/>
          </Col>
          <Col sm="4" className="align-right">
            <Button color="warning" onClick={addNewRecipient}>Add</Button>
          </Col>
        </Row>
        <div className="my-divider"></div>
        <br/>
        <Table className="table table-striped">
          <thead>
            <tr>
              <th width="5%"></th>
              <th width="15%">Recipient</th>
            </tr>
          </thead>
          <tbody>
            {
              state.reportDetails.map(
                (row,index)=>{
                  return(
                    <tr key={index}>
                      <td><i className="fa fa-times red-color cursor-pointer" onClick={(e)=>deleteRecipient(row.ID)}></i></td>
                      <td>{row.email}</td>
                    </tr>
                  );
                }
              )
            }
          </tbody>
        </Table>
      </CardBody>
    </Card>
  </div>;
}


export default EndOfDay;
