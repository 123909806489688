//Author Sooyoung Kim
//Date Nov 27, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, formatNumber, formatDateTime} from '../../util/util';
import {Button, Card, CardHeader, CardBody, Row, Col, Progress, NavItem, Nav, TabContent, TabPane, UncontrolledTooltip, Table, NavLink as NavLinkL} from 'reactstrap';
import AutoAssignLenderPanel from './auto-assign-lender-panel';
import DatePicker from 'react-datepicker';
import React, {useReducer, useEffect} from 'react';
import {NavLink, useParams} from 'react-router-dom';
import MyChart from './../util/my-chart';
import moment from 'moment';

//initialize the state
const initialState = {
  stats:[],
  currentBreakDown:'all',
  breakDowns:[],
  activeTab:'1',
};

//reducer function that perform state update
const reducer = getReducer();


const AutoAssignCountyDashboard  = (props)=>{
  const controller = new AbortController();
  let {clientFk, stateName, county, from, to} = useParams();

  let newInitialState = Object.assign({}, initialState, {
    from:from,
    to:to,
    clientFk:clientFk,
    stateName:stateName,
    county:county
  });
  
  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getStats();
    getAutoAssignBreakDown('all');

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const activeTabToggle = (tab) => {
    setState({activeTab:tab});
  }

  const setBreakDown = (breakDown) => {
    setState({currentBreakDown:breakDown});
    getAutoAssignBreakDown(breakDown);
  }

  //API call
  //get county stats
  const getStats = () => {
    let callBack = apiCallBack([{state:'stats', key:'data'}]);
	  httpGet('autoAssign/county/stats/get/from='+state.from+'&to='+state.to+'&client_fk='+state.clientFk+'&state='+state.stateName+'&county='+state.county, '', 'Oops, something went wrong and could not load auto assign stats. Please try again later.', callBack);
  }

  const getAutoAssignBreakDown = (breakDown) => {
    let callBack = apiCallBack([{state:'breakDowns', key:'data'}]);
	  httpGet('autoAssign/county/breakdown/get/from='+state.from+'&to='+state.to+'&client_fk='+state.clientFk+'&state='+state.stateName+'&county='+state.county+'&breakdown='+breakDown, '', 'Oops, something went wrong and could not load auto assign stats. Please try again later.', callBack);
  }

  //render
  let newfrom, newto;
  if(state.from!=='')
    newfrom = moment(state.from).toDate();
  if(state.to!=='')
    newto = moment(state.to).toDate();

  let stats = state.stats;

  let colorClass,colorClass2 = 'green-status';
  let icon,icon2 = 'fa fa-check';

  if(stats.avg_accepting<4){
    icon = 'fa fa-check';
    colorClass = 'green-status';
  }
  else if(stats.avg_accepting<6){
    icon = 'fa fa-warning';
    colorClass = 'yellow-status';
  }
  else{
    icon = 'fa fa-times';
    colorClass = 'red-status';
  }

  if(stats.success_rate>70){
    icon2 = 'fa fa-check';
    colorClass2 = 'green-status';
  }
  else if(stats.success_rate>50){
    icon2 = 'fa fa-warning';
    colorClass2 = 'yellow-status';
  }
  else{
    icon2 = 'fa fa-times';
    colorClass2 = 'red-status';
  }


  let skipDrillDown = [];
  let data = [];

  if(stats.skipDrillDown&&stats.skipDrillDown.length>0){
    for(let i=0;i<stats.skipDrillDown.length;i++){
      let tmp = [];
      tmp.push(stats.skipDrillDown[i].reason);
      tmp.push(parseInt(stats.skipDrillDown[i].count,10));

      data.push(tmp);
    }
    let tmp2 = {};
    tmp2.id = 'skip';
    tmp2.name = 'Skip';
    tmp2.data = data;
    skipDrillDown.push(tmp2);
  }

  const config ={
    chart: {
      type: 'pie',
      height:300
    },
    title: {
      text: null
    },
    subtitle: {
      text: null
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: false,
          format: '{point.name}: {point.y:.1f}'
        },
        showInLegend: true
      }
    },

    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> of total<br/>'
    },
    series: [{
      name: 'Skip Reasons',
      colorByPoint: true,
      data: [{
        name: 'Tried all appraiser',
        y: stats.exhausted,
      }, {
        name: 'Timeout',
        y: stats.timeout,
      },{
        name: 'Skip',
        y: stats.skip,
        drilldown: 'skip'
      },{
        name: 'Auto assign success',
        y: stats.success,
        drilldown: 'success'
      }]
    }],
    drilldown: {
      series: skipDrillDown
    },
    credits:{
      enabled:false
    },
    legend:{
      itemStyle:{
        fontSize:10
      },
      labelFormatter: function () {
        return this.name+' - '+formatNumber(this.series.yData[this.index]);
      }
    }
  };

  let chart;
  if(Object.keys(stats).length!==0){
    chart = <MyChart options={config}/>;
  }

  let totalManual = stats.exhausted + stats.timeout + stats.skip;
  let exhausted = stats.exhausted;
  let exhaustedRate = formatNumber((exhausted/totalManual)*100);

  let timeout = stats.timeout;
  let timeoutRate = formatNumber((timeout/totalManual)*100);

  let skip = stats.skip;
  let skipRate = formatNumber((skip/totalManual)*100);

  let breakDowns, rows;
  switch(state.currentBreakDown){
    case "turnaround":
      let totalValid = 0;
      let totalTAT = 0;
      rows = state.breakDowns.map(
        (row,index)=>{
          if(row.tat!=='-'){
            totalValid++;
            totalTAT+=row.tat;
          }
          return(
            <tr key={index}>
              <td><NavLink to={"/appraisal/"+row.ID}>{row.reference_num}</NavLink></td>
              <td>{row.appraiser}</td>
              <td>{row.appraisal_type}</td>
              <td>{row.property_street+" "+row.property_city}</td>
              <td>{formatDateTime(row.datetime_submitted)}</td>
              <td>{formatDateTime(row.datetime_accepted)}</td>
              <td>{row.tat}</td>
            </tr>
          );
        }
      );

      let totalDescription , total;
      if(totalValid!==0){
        totalDescription = totalTAT+"/"+totalValid+"=";
        total = formatNumber((totalTAT/totalValid).toFixed(1));
      }

      breakDowns =
      <Card>
        <CardHeader className="header-color">
          <i className="fa fa-reorder"></i> Auto Assignment Turnaround Breakdown
        </CardHeader>
        <CardBody>
          <div className="large-scroll-container">
            <Table className="table table-striped">
              <thead>
                <tr>
                  <th width="13%">Reference #</th>
                  <th width="13%">Appraiser</th>
                  <th width="20%">Report Type</th>
                  <th width="15%">Address</th>
                  <th width="13%">Submitted</th>
                  <th width="13%">Accepted</th>
                  <th width="13%">TAT (hours)</th>
                </tr>
              </thead>
              <tbody>
                {rows}
                <tr>
                  <td colSpan="5"></td><td><b>{totalDescription}</b></td><td><b>{total}</b></td>
                </tr>
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      break;
    case "success":
      rows = state.breakDowns.map(
        (row,index)=>{
          return(
            <tr key={index}>
              <td><NavLink to={"/appraisal/"+row.ID}>{row.reference_num}</NavLink></td>
              <td>{row.appraiser}</td>
              <td>{row.appraisal_type}</td>
              <td>{row.property_street+" "+row.property_city}</td>
              <td>{formatDateTime(row.datetime_submitted)}</td>
              <td>{row.status}</td>
            </tr>
          );
        }
      );

      breakDowns =
      <Card>
        <CardHeader className="header-color">
          <i className="fa fa-reorder"></i> Success Auto Assignment Breakdown
        </CardHeader>
        <CardBody>
          <div className="large-scroll-container">
            <Table className="table table-striped">
              <thead>
                <tr>
                  <th width="13%">Reference #</th>
                  <th width="13%">Appraiser</th>
                  <th width="20%">Report Type</th>
                  <th width="15%">Address</th>
                  <th width="13%">Submitted</th>
                  <th width="26%">Status</th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      break;
    case "processed":
        rows = state.breakDowns.map(
        (row,index)=>{
          return(
            <tr key={index}>
              <td><NavLink to={"/appraisal/"+row.ID}>{row.reference_num}</NavLink></td>
              <td>{row.appraiser}</td>
              <td>{row.appraisal_type}</td>
              <td>{row.property_street+" "+row.property_city}</td>
              <td>{formatDateTime(row.datetime_submitted)}</td>
              <td>{row.status}</td>
            </tr>
          );
        }
      );

      breakDowns =
      <Card>
        <CardHeader className="header-color">
          <i className="fa fa-reorder"></i> Processed Auto Assignment Breakdown
        </CardHeader>
        <CardBody>
          <div className="large-scroll-container">
            <Table className="table table-striped">
              <thead>
                <tr>
                  <th width="13%">Reference #</th>
                  <th width="13%">Appraiser</th>
                  <th width="20%">Report Type</th>
                  <th width="15%">Address</th>
                  <th width="13%">Submitted</th>
                  <th width="26%">Status</th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      break;
    case "all":
      rows = state.breakDowns.map(
        (row,index)=>{
          return(
            <tr key={index}>
              <td><NavLink to={"/appraisal/"+row.ID}>{row.reference_num}</NavLink></td>
              <td>{row.appraiser}</td>
              <td>{row.appraisal_type}</td>
              <td>{row.property_street+" "+row.property_city}</td>
              <td>{formatDateTime(row.datetime_submitted)}</td>
              <td>{row.status}</td>
            </tr>
          );
        }
      );

      breakDowns =
      <Card>
        <CardHeader className="header-color">
          <i className="fa fa-reorder"></i> All Orders Breakdown
        </CardHeader>
        <CardBody>
          <div className="large-scroll-container">
            <Table className="table table-striped">
              <thead>
                <tr>
                  <th width="13%">Reference #</th>
                  <th width="13%">Appraiser</th>
                  <th width="20%">Report Type</th>
                  <th width="15%">Address</th>
                  <th width="13%">Submitted</th>
                  <th width="26%">Status</th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      break;
    case "exhausted":
      rows = state.breakDowns.map(
        (row,index)=>{
          return(
            <tr key={index}>
              <td><NavLink to={"/appraisal/"+row.ID}>{row.reference_num}</NavLink></td>
              <td>{row.appraiser}</td>
              <td>{row.appraisal_type}</td>
              <td>{row.property_street+" "+row.property_city}</td>
              <td>{formatDateTime(row.datetime_submitted)}</td>
              <td>{row.status}</td>
            </tr>
          );
        }
      );

      breakDowns =
      <Card>
        <CardHeader className="header-color">
          <i className="fa fa-reorder"></i> Tried All Appraisers Breakdown
        </CardHeader>
        <CardBody>
          <div className="large-scroll-container">
            <Table className="table table-striped">
              <thead>
                <tr>
                  <th width="13%">Reference #</th>
                  <th width="13%">Appraiser</th>
                  <th width="20%">Report Type</th>
                  <th width="15%">Address</th>
                  <th width="13%">Submitted</th>
                  <th width="26%">Status</th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      break;
    case "timeout":
      rows = state.breakDowns.map(
        (row,index)=>{
          return(
            <tr key={index}>
              <td><NavLink to={"/appraisal/"+row.ID}>{row.reference_num}</NavLink></td>
              <td>{row.appraiser}</td>
              <td>{row.appraisal_type}</td>
              <td>{row.property_street+" "+row.property_city}</td>
              <td>{formatDateTime(row.datetime_submitted)}</td>
              <td>{row.status}</td>
            </tr>
          );
        }
      );

      breakDowns =
      <Card>
        <CardHeader className="header-color">
          <i className="fa fa-reorder"></i> Timeout Breakdown
        </CardHeader>
        <CardBody>
          <div className="large-scroll-container">
            <Table className="table table-striped">
              <thead>
                <tr>
                  <th width="13%">Reference #</th>
                  <th width="13%">Appraiser</th>
                  <th width="20%">Report Type</th>
                  <th width="15%">Address</th>
                  <th width="13%">Submitted</th>
                  <th width="26%">Status</th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      break;
    case "skip":
      rows = state.breakDowns.map(
        (row,index)=>{
          return(
            <tr key={index}>
              <td><NavLink to={"/appraisal/"+row.ID}>{row.reference_num}</NavLink></td>
              <td>{row.appraiser}</td>
              <td>{row.appraisal_type}</td>
              <td>{row.property_street+" "+row.property_city}</td>
              <td>{formatDateTime(row.datetime_submitted)}</td>
              <td>{row.status}</td>
            </tr>
          );
        }
      );

      breakDowns =
      <Card>
        <CardHeader className="header-color">
          <i className="fa fa-reorder"></i> Skip Auto Assignment Breakdown
        </CardHeader>
        <CardBody>
          <div className="large-scroll-container">
            <Table className="table table-striped">
              <thead>
                <tr>
                  <th width="13%">Reference #</th>
                  <th width="13%">Appraiser</th>
                  <th width="20%">Report Type</th>
                  <th width="15%">Address</th>
                  <th width="13%">Submitted</th>
                  <th width="26%">Status</th>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      break;
    default:
      breakDowns = null;
      break;
  }

  return <div className="my-well">
    <Row>
      <Col sm="6">
        <div className="page-title">
          <i className="fa fa-reorder"></i>&nbsp;Auto Assign Dashboard - {state.stateName} - {state.county}
        </div>
        <NavLink to={"/auto-assign/client/"+state.clientFk+"/"+state.from+"/"+state.to}>Back To Auto Assign Dashboard</NavLink>
      </Col>
      <Col sm="6">

      </Col>
    </Row>
    <div className="my-divider"></div>

    <Row>
      <Col sm="3">
        <b>From</b>
        <br/>
        <DatePicker
          className="form-control"
          dateFormat="yyyy/MM/dd"
          selected={newfrom}
          onChange={(text)=>{(text)&&setState({from:text.toLocaleDateString('en-CA')})}}
        />
      </Col>
      <Col sm="2">
        <b>To</b>
        <br/>
        <DatePicker
          className="form-control"
          dateFormat="yyyy/MM/dd"
          selected={newto}
          onChange={(text)=>{(text)&&setState({to:text.toLocaleDateString('en-CA')})}}
        />
      </Col>
      <Col sm="7" className="align-right">
        <br/>
        <Button color="warning" onClick={(e)=>getStats()}>Submit</Button>
      </Col>
    </Row>
    <div className="my-divider"></div>

    <Nav tabs>
      <NavItem>
        <NavLinkL className={"nav-link "+(state.activeTab === '1'?"active":"inactive" ) } to="#" onClick={() => { activeTabToggle('1'); }}>Stats</NavLinkL>
      </NavItem>
      <NavItem>
        <NavLinkL className={"nav-link "+(state.activeTab === '2'?"active":"inactive" ) } to="#" onClick={() => { activeTabToggle('2'); }}>Panel</NavLinkL>
      </NavItem>
    </Nav>
    <br/>
    <TabContent activeTab={state.activeTab}>
      <TabPane tabId="1">
        <Card>
          <CardHeader className="header-color">
            <i className="fa fa-area-chart"></i> Stats
          </CardHeader>
          <CardBody>
            <Row className="padding">
              <Col sm="4" className="manual-reason-container">
                <div>
                  {chart}
                </div>
              </Col>
              <Col sm="8">
                <div className="vertical-align-top">
                  <div className="display-inline">
                    <div className={"auto-assign-box "+colorClass}>
                      <center><i className={icon}></i></center>
                    </div>
                  </div>
                  <div className="display-inline">
                    Assignment Turnaround<br/>
                    <b className="cursor-pointer link-color" onClick={()=>setBreakDown('turnaround')}>{stats.avg_accepting} hours</b>
                  </div>
                </div>

                <div></div>

                <div className="vertical-align-top">
                  <div className="display-inline">
                    <div className={"auto-assign-box "+colorClass2}>
                      <center><i className={icon2}></i></center>
                    </div>
                  </div>
                  <div className="display-inline">
                    Success Rate<br/>
                    <b className="cursor-pointer link-color" onClick={()=>setBreakDown('success')}>{stats.success_rate}% </b> out of <b className="cursor-pointer link-color" onClick={()=>setBreakDown('processed')}>{formatNumber(stats.auto_assign_count)}</b>&nbsp;
                    <div id="tooltip" className="display-inline cursor-pointer"><u><i>processed orders</i></u></div>
                    <UncontrolledTooltip placement="right" target="tooltip">
                      Total number of orders that processed by auto assign regardless of success or failed in the end
                    </UncontrolledTooltip>
                  </div>
                </div>
                <div className="my-divider"></div>
                <Row>
                  <Col sm="2">

                  </Col>
                  <Col sm="8">
                    <center>
                      <b>Coverage</b>
                    </center>
                  </Col>
                  <Col sm="2" className="align-right">
                    <i className="fa fa-user"/> {formatNumber(stats.total_appraiser)}
                  </Col>
                </Row>
                <div className="text-center"><b className="cursor-pointer link-color" onClick={()=>setBreakDown('success')}>{formatNumber(stats.success)}</b> success out of <b className="cursor-pointer link-color" onClick={()=>setBreakDown('all')}>{formatNumber(stats.count)}</b> total orders <b>({formatNumber(stats.coverage)}%)</b></div>
                <Progress animated color="success" value={stats.coverage}/>

                <div className="my-divider"></div>

                <center><b>Reasons for manual assignment</b></center>
                <div className="margin-top manual-reason-container">
                  <div className="manual-reason">
                    Failed to auto assign - Tried all appraisers:<b className="cursor-pointer link-color" onClick={()=>setBreakDown('exhausted')}> {exhausted}</b><br/>
                    <Progress animated color="warning" value={exhaustedRate}/>
                  </div>

                  <div className="manual-reason">
                    Failed to auto assign - Time out:<b className="cursor-pointer link-color" onClick={()=>setBreakDown('timeout')}> {timeout}</b><br/>
                    <Progress animated color="warning" value={timeoutRate}/>
                  </div>

                  <div className="manual-reason">
                    <Row>
                      <Col sm="12">
                        Skip auto assignment:<b className="cursor-pointer link-color" onClick={()=>setBreakDown('skip')}> {skip}</b>
                      </Col>
                    </Row>
                    <Progress animated color="warning" value={skipRate}/>
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
        {breakDowns}
      </TabPane>
      <TabPane tabId="2">
        <AutoAssignLenderPanel clientFk={state.clientFk} state={state.stateName} county={state.county} showLoading={props.showLoading} hideLoading={props.hideLoading}/>
      </TabPane>
    </TabContent>
  </div>;
}


export default AutoAssignCountyDashboard;
