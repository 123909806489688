//Author June Leow
//Date Oct 11th, 2023
import {getReducer, getSetStateFunction, formatDateTime} from '../../util/util';
import {Button, Modal, ModalHeader, ModalBody} from 'reactstrap';
import {NavLink} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';

//initialize the state
const initialState = {
  activeReminders:[],
  allReminders:[],
  toggle:false,
  toggle2:false,
  currentReminderIndex:0,
  currentActiveReminderIndex:0,
  currentReminder:{}
};

//reducer function that perform state update
const reducer = getReducer();


const ActiveReminders  = (props)=>{
  const controller = new AbortController();

  let currentReminder = {};
  let currentActiveReminderIndex= -1;
  let allReminders = props.reminders;
  let activeReminders = [];

  for(let i=0;i<props.reminders.length;i++){
    if(props.reminders[i].status==='active'){
      let reminder = Object.assign({}, props.reminders[i]);
      if(currentActiveReminderIndex===-1){
        currentActiveReminderIndex = i;
        currentReminder = reminder;
      }
      activeReminders.push(Object.assign({}, props.reminders[i]));
    }
  }

  let toggleValue = false;
    if(activeReminders.length>0)
    toggleValue = true;

  const newInitialState = Object.assign({}, initialState, {activeReminders:activeReminders, allReminders:allReminders, toggle:toggleValue, currentReminder:currentReminder})
  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const toggle = ()=>{
    if(state.activeReminders.length>0){
      if(!state.toggle){
        setState({currentReminder:state.activeReminders[0], currentActiveReminderIndex:0})
      }
      console.log(state.toggle)
      setState({toggle:!state.toggle})
    }
  }

  const toggle2 = ()=>{
    if(state.allReminders.length>0){
      if(!state.toggle){
        setState({currentReminder:state.allReminders[0], currentReminderIndex:0})
      }
    }
    setState({toggle2:!state.toggle2})
  }

  const nextActiveReminder = ()=>{
    let currentActiveReminderIndex = state.currentActiveReminderIndex;

    if(currentActiveReminderIndex+1<state.activeReminders.length){
      currentActiveReminderIndex++;
      setState({currentActiveReminderIndex:currentActiveReminderIndex, currentReminder:Object.assign({}, state.activeReminders[currentActiveReminderIndex])});
    }
    else{
      toggle();
    }
  }

  const nextReminder = ()=>{
    let currentReminderIndex = state.currentReminderIndex;

    if(currentReminderIndex+1<state.allReminders.length){
      currentReminderIndex++;

      setState({currentReminderIndex:currentReminderIndex, currentReminder:Object.assign({}, state.allReminders[currentReminderIndex])});
    }
    else{
      toggle2();
    }
  }


  const updateReminder = (id)=>{
    let callBack;
    callBack = ()=>{
      let activeReminders = state.activeReminders.slice();
      let currentReminder = {}
      activeReminders.splice(state.currentActiveReminderIndex, 1);
      if(activeReminders.length>0)
        currentReminder = activeReminders[state.currentActiveReminderIndex];
      else
        toggle();
      setState({activeReminders:activeReminders, currentReminder:currentReminder});
    };
    props.updateReminder(id, callBack);
  }

  //non API call but simpyl manage state
  const getColor = (index)=>{
    let colors = ['#ff0066','#009900', '#0055ff', '#d24dff', '#ff6600'];
    return colors[index%colors.length];
  }

  //API call

  let index = state.currentActiveReminderIndex+1;

  if(index===1)
    index = '1st';
  else if(index===2)
    index = '2nd';
  else if(index===3)
    index = '3rd';
  else
    index = index+'th';

  let index2 = state.currentReminderIndex+1;

  if(index2===1)
    index2 = '1st';
  else if(index2===2)
    index2 = '2nd';
  else if(index2===3)
    index2 = '3rd';
  else
    index2 = index2+'th';

  let dismissInfo;

  if(state.currentReminder.status==='inactive' && state.currentReminder.dismiss_by!==''){
    dismissInfo = <div>
      <center>
        <font color="red">
          <b>Dismissed by {state.currentReminder.dismiss_by} at {formatDateTime(state.currentReminder.datetime_dismiss)}</b>
        </font>
      </center>
    </div>
  }

  let content1 = <div>
    <i>***You have <span className="my-badge flashit">{state.activeReminders.length}</span> active reminders. This is the {index} reminder.<br/><br/></i>
    <div>
      <center>
        <h4 className="flashit" style={{color:getColor(state.currentReminderIndex)}}>{state.currentReminder.type}</h4>
      </center>
      <br/><br/>
      <center><label><font color="red">*</font>&nbsp;{state.currentReminder.description}</label><br/><br/>
      Click 'Yes' button to verify.</center><br/><br/>
      <center>
        <Button color="warning" onClick={(e)=>updateReminder(state.currentReminder.ID)}><i className="fa fa-check"></i>Yes</Button>&nbsp;<Button color="info" onClick={nextActiveReminder}><i className="fa fa-times"></i> Read Later</Button>
      </center>
    </div>
  </div>

  let content2 = <div>
    <i>***You have <span className="my-badge flashit">{state.allReminders.length}</span> reminders. This is the {index2} reminder.<br/><br/></i>
    <div>
      <center>
        {state.currentReminder.status==='active'?<h4 style={{color:getColor(state.currentReminderIndex)}}>{state.currentReminder.type}</h4>:<s><h4 style={{color:getColor(state.currentReminderIndex)}}>{state.currentReminder.type}</h4></s>}
      </center>
      <br/><br/>
      <center>
        <label>
          {state.currentReminder.status==='active'?<div><font color="red">*</font>&nbsp;{state.currentReminder.description}</div>:<s><font color="red">*</font>&nbsp;{state.currentReminder.description}</s>}
        </label><br/><br/>
      </center><br/><br/>
      {dismissInfo}
      <br/>
      <center>
        <Button color="warning" onClick={nextReminder}>Next</Button>
      </center>
    </div>
  </div>

  if(state.activeReminders.length<=0){
    content1 = <div>
      <br/><br/><br/>
      <center>
        <i>***You have no reminder</i>
      </center>
      <br/><br/><br/>
      <center>
        <Button color="info" onClick={toggle}>Close</Button>
      </center>
    </div>
  }

  if(state.allReminders.length<=0){
    content2 = <div>
      <br/><br/><br/>
      <center>
        <i>***You have no reminder</i>
      </center>
      <br/><br/><br/>
      <center>
        <Button color="info" onClick={toggle2}>Close</Button>
      </center>
    </div>
  }

  //render
  return <div className="display-inline">
  {state.activeReminders.length>0?<NavLink to="#" onClick={toggle}><i className="fa fa-exclamation-circle"/> Active Reminders</NavLink>:null}&nbsp;
  <NavLink to="#" onClick={toggle2}>
    <i className="fa fa-exclamation-circle"/> All Reminders
  </NavLink>
  <Modal className="my-modal" isOpen={state.toggle} toggle={toggle} >
    <ModalHeader hidden={true} toggle={toggle}></ModalHeader>
    <ModalBody>
      {content1}
    </ModalBody>
  </Modal>

  <Modal className="my-modal" isOpen={state.toggle2} toggle={toggle2} >
    <ModalHeader hidden={true} toggle={toggle2}></ModalHeader>
    <ModalBody>
      {content2}
    </ModalBody>
  </Modal>
</div>;
}


export default ActiveReminders;
