//Author Sooyoung Kim
//Date Aug 29, 2023
import {formatNumber, showMessage, generateTooltip} from '../../util/util';
import {Card, CardHeader, CardBody, Col, Row, Input} from 'reactstrap';
import React, {useEffect} from 'react';
import MyDropzone from '../util/my-dropzone';
import {NavLink} from 'react-router-dom';
import {deletePhoto} from './util';

const StepPhotos  = (props)=>{
  const controller = new AbortController();

  //run only once when component is loaded
  useEffect(()=>{
    initializeGLACalculation();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const addGLACalculations = () => {
    let glaCalculations = props.glaCalculations.slice();
    glaCalculations.push({name:'',sqft:'', width:'', length:''});
    props.updateState({glaCalculations:glaCalculations});
  }

  const removeGLACalculations = (index) => {
    let glaCalculations = props.glaCalculations.slice();
    glaCalculations.splice(index, 1);
    props.updateState({glaCalculations:glaCalculations});
  }

  const updateGLACalculation = (field, index, value) => {
    let glaCalculations = props.glaCalculations.slice();

    glaCalculations[index][field] = value;

    props.updateState({glaCalculations:glaCalculations});
  }

  const initializeGLACalculation = () => {
    let glaCalculations = props.glaCalculations.slice();
    console.log(glaCalculations)
    if(glaCalculations.length<=0){
      for(let i=0;i<props.interiors.length;i++){
        glaCalculations.push({name:props.interiors[i].name, width:'', length:'' ,sqft:''});
      }

      for(let i=0;i<props.bathrooms.length;i++){
        glaCalculations.push({name:props.bathrooms[i].name, width:'', length:'' , sqft:''});
      }

      for(let i=0;i<props.kitchens.length;i++){
        glaCalculations.push({name:props.kitchens[i].name, width:'', length:'' , sqft:''});
      }

      props.updateState({glaCalculations:glaCalculations});
    }
  }

  const removeFile = (field,index) => {
    let target = props[field];

    let targetToDelete = target[index];

    if(targetToDelete.ID && targetToDelete.ID!==-1){
      let successCallBack = ()=>{
        target.splice(index, 1);
        props.updateState({[field]:target});
      };
      successCallBack = successCallBack.bind(this);
      deletePhoto(targetToDelete.ID, props, successCallBack);
    }
    else{
      target.splice(index, 1);
      props.updateState({[field]:target});
    }

  }

  //function trigger for dropzone react.
  //this function contians two list of files, the accepted and rejected file per the configuration
  const onDrop = (acceptedFiles, rejectedFiles, field, type='Misc', typeFk=0, description='') => {
    let existingFiles = [];
    if(acceptedFiles.length>0){
      for(let i=0;i<acceptedFiles.length;i++){
        existingFiles.push(acceptedFiles[i]);
        let name = existingFiles[i].name;
        let token = name.split('.');
        let extension = '';

        if(token.length>0){
          extension = token[token.length-1];
          extension = extension.toLowerCase();
        }

        if(extension!=='png'&&extension!=='jpg'&&extension!=='jpeg'){
          showMessage('error','Please upload only PNG/JPG image.');
        }
        else{
          const reader = new FileReader();
          reader.onload = () => {
              const fileAsBinaryString = reader.result;
              let base64 = btoa(fileAsBinaryString);
              let target = props[field]

              target.push({name:acceptedFiles[i].name, base64:base64, status:'Pending', size:acceptedFiles[i].size, type:type, typeFk:typeFk, description:description});
              props.updateState({[field]:target});
          }
          reader.readAsBinaryString(acceptedFiles[i]);
        }
      }
    }
  }

  //format the size to use appropiate unit KB, MB and B and round up to only 1 decimal
  const formatFileSize = (size) => {
    let intSize = parseInt(size,10);

    if(intSize>=1000000.00)
      return formatNumber(Math.round((intSize*10/1000000))/10)+' MB';
    else if(intSize>=1000)
      return formatNumber(Math.round((intSize*10/1000))/10)+' KB';
    else
      return formatNumber(intSize)+' B';

  }

  const updateFileDescription = (fileName, value) => {
    let toUploadFiles = props.photos.slice();

    for(let i=0;i<toUploadFiles.length;i++){
      if(toUploadFiles[i].name===fileName){
        toUploadFiles[i].description = value;
        break;
      }
    }

    props.updateState({photos:toUploadFiles})
  }

  //render
  let disabledStyling = {border:'none', background:'#fff', padding:'0',paddingLeft:'2px', height:'auto',WebkitAppearance:'none'};
  let toUploadFiles;

  if(props.photos.length>0){
    toUploadFiles = props.photos.map(
      (photo,index)=>{
        return(
          <tr key={index}>
            <td>{photo.name.length>16?photo.name.substr(0,16)+'...':photo.name}</td>
            <td>
              <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} type="text" value={photo.description} onChange={(e)=>updateFileDescription(photo.name, e.target.value)}/>
            </td>
            <td>{formatFileSize(photo.size)}</td>
            <td><center>{photo.status}</center></td>
            <td><center><i hidden={props.disabled} className="fa fa-times red-color cursor-pointer" onClick={()=>removeFile('photos',index)}></i></center></td>
          </tr>
        );
      }
    );
  }
  
  return <div>
    <Card>
      <CardHeader className="header-color">
        Required Photos
      </CardHeader>
      <CardBody>
        <label className="flashit"><font color="red">Please make sure the floor plan matches the room</font></label>
        <Row>
          <Col sm="12" className="align-left">
            <b><font color="red">*</font>Floor Plan Requirements {generateTooltip('floorPlan', 'Please Upload Floor plan with the list of GLA calculations for each room.')}</b>
          </Col>
        </Row>
        <Row style={{marginTop:'15px'}}>
          <Col sm="12">

            <label><font color="red">*</font>Floor Plan</label>&nbsp;|&nbsp;<a className="display-inline" href="https://www.smartdraw.com/" target="_blank" rel="noopener noreferrer">Smart Draw</a>&nbsp;|&nbsp;<a className="display-inline" href="https://www.cubi.casa/" target="_blank" rel="noopener noreferrer">Cubi Casa</a>
            <div><i style={{fontSize:'15px'}}>Try out Smart Draw and Cubi Casa for generating Floor Plan. You can find links to Smart Draw and Cubi Casa above.</i></div>
            <br/>
            <MyDropzone hidden={props.disabled} onDrop={(accepted, rejected)=>{onDrop(accepted, rejected, 'floorPlan', 'Floor Plan', 0)}} noIcon={true}/>
            {
              props.floorPlan.map(
                (photo, index)=>{
                  return <div key={index} className="display-inline" style={{width:200, height:250, margin:5}}>
                    <div>
                      <i hidden={props.disabled} className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeFile('floorPlan',index)}/> {photo.name.length>16?photo.name.substr(0,16)+'...':photo.name}
                    </div>
                    <img alt="pic" width="200" height="200" src={photo.status==='Pending'?"data:image/png;base64, "+photo.base64:photo.presign_url}/>
                  </div>
                }
              )
            }
          </Col>
        </Row>
        <br/>
        <Row>
          <Col sm="6">
            <label>GLA Calclations {generateTooltip('glaCalculations','Please include all GLA Calculations listed on the floor plan.')}</label>
          </Col>
          <Col sm="6" className="align-right">
            <NavLink to="#" onClick={(e)=>addGLACalculations()}><i className="fa fa-plus green-color"/> GLA</NavLink>
          </Col>
        </Row>
        {
          props.glaCalculations.map(
            (glaCalculation, index)=>{
              return <div key={index}>
                <Row>
                  <Col sm="3">
                    <i hidden={props.disabled} className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeGLACalculations(index)}/><label>Name</label><br/>
                    <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} type="text" value={glaCalculation.name} onChange={(e)=>updateGLACalculation('name',index, e.target.value)}/>
                  </Col>
                  <Col sm="3">
                    <label>Length</label><br/>
                    <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} type="text" value={glaCalculation.length} onChange={(e)=>updateGLACalculation('length',index, e.target.value)}/>
                  </Col>
                  <Col sm="3">
                    <label>Width</label><br/>
                    <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} type="text" value={glaCalculation.width} onChange={(e)=>updateGLACalculation('width',index, e.target.value)}/>
                  </Col>
                  <Col sm="3">
                    <label>Sqft</label><br/>
                    <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} type="number" value={glaCalculation.sqft} onChange={(e)=>updateGLACalculation('sqft',index, e.target.value)}/>
                  </Col>
                </Row>
                <br/>
              </div>
            }
          )
        }
        <br/>
        <Row style={{marginTop:'15px'}}>
          <Col sm="6">
            <label><font color="red">*</font>Front View of Property</label>
            <br/>
            <MyDropzone hidden={props.disabled} onDrop={(accepted, rejected)=>{onDrop(accepted, rejected, 'frontViewPropertyPhotos', 'Front View', 0)}} noIcon={true}/>
            {
              props.frontViewPropertyPhotos.map(
                (photo, index)=>{
                  return <div key={index} className="display-inline" style={{width:200, height:250, margin:5}}>
                    <div>
                      <i hidden={props.disabled} className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeFile('frontViewPropertyPhotos',index)}/> {photo.name.length>16?photo.name.substr(0,16)+'...':photo.name}
                    </div>
                    <img alt="pic" width="200" height="200" src={photo.status==='Pending'?"data:image/png;base64, "+photo.base64:photo.presign_url}/>
                  </div>
                }
              )
            }
          </Col>
          <Col sm="6">
            <label><font color="red">*</font>Rear View of Property</label>
            <br/>
            <MyDropzone hidden={props.disabled} onDrop={(accepted, rejected)=>{onDrop(accepted, rejected, 'rearViewPropertyPhotos', 'Rear View', 0)}} noIcon={true}/>
            {
              props.rearViewPropertyPhotos.map(
                (photo, index)=>{
                  return <div key={index} className="display-inline" style={{width:200, height:250, margin:5}}>
                    <div>
                      <i hidden={props.disabled} className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeFile('rearViewPropertyPhotos',index)}/> {photo.name.length>16?photo.name.substr(0,16)+'...':photo.name}
                    </div>
                    <img alt="pic" width="200" height="200" src={photo.status==='Pending'?"data:image/png;base64, "+photo.base64:photo.presign_url}/>
                  </div>
                }
              )
            }
          </Col>
        </Row>
        <br/>
        <Row style={{marginTop:'15px'}}>
          <Col sm="6">
            <label><font color="red">*</font>Side View of Property (Left)</label>
            <br/>
            <MyDropzone hidden={props.disabled} onDrop={(accepted, rejected)=>{onDrop(accepted, rejected, 'leftSideViewPropertyPhotos', 'Left Side View', 0)}} noIcon={true}/>
            {
              props.leftSideViewPropertyPhotos.map(
                (photo, index)=>{
                  return <div key={index} className="display-inline" style={{width:200, height:250, margin:5}}>
                    <div>
                      <i hidden={props.disabled} className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeFile('leftSideViewPropertyPhotos',index)}/> {photo.name.length>16?photo.name.substr(0,16)+'...':photo.name}
                    </div>
                    <img alt="pic" width="200" height="200" src={photo.status==='Pending'?"data:image/png;base64, "+photo.base64:photo.presign_url}/>
                  </div>
                }
              )
            }
          </Col>
          <Col sm="6">
            <label><font color="red">*</font>Side View of Property (Right)</label>
            <br/>
            <MyDropzone hidden={props.disabled} onDrop={(accepted, rejected)=>{onDrop(accepted, rejected, 'rightSideViewPropertyPhotos', 'Right Side View', 0)}} noIcon={true}/>
            {
              props.rightSideViewPropertyPhotos.map(
                (photo, index)=>{
                  return <div key={index} className="display-inline" style={{width:200, height:250, margin:5}}>
                    <div>
                      <i hidden={props.disabled} className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeFile('rightSideViewPropertyPhotos',index)}/> {photo.name.length>16?photo.name.substr(0,16)+'...':photo.name}
                    </div>
                    <img alt="pic" width="200" height="200" src={photo.status==='Pending'?"data:image/png;base64, "+photo.base64:photo.presign_url}/>
                  </div>
                }
              )
            }
          </Col>
        </Row>
        <br/>
        <Row style={{marginTop:'15px'}}>
          <Col sm="6">
            <label><font color="red">*</font>Street View of Property (Left sides)</label>
            <br/>
            <MyDropzone hidden={props.disabled} onDrop={(accepted, rejected)=>{onDrop(accepted, rejected, 'leftStreetViewPropertyPhotos', 'Left Street View', 0)}} noIcon={true}/>
            {
              props.leftStreetViewPropertyPhotos.map(
                (photo, index)=>{
                  return <div key={index} className="display-inline" style={{width:200, height:250, margin:5}}>
                    <div>
                      <i hidden={props.disabled} className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeFile('leftStreetViewPropertyPhotos',index)}/> {photo.name.length>16?photo.name.substr(0,16)+'...':photo.name}
                    </div>
                    <img alt="pic" width="200" height="200" src={photo.status==='Pending'?"data:image/png;base64, "+photo.base64:photo.presign_url}/>
                  </div>
                }
              )
            }
          </Col>
          <Col sm="6">
            <label><font color="red">*</font>Street View of Property (Right sides)</label>
            <br/>
            <MyDropzone hidden={props.disabled} onDrop={(accepted, rejected)=>{onDrop(accepted, rejected, 'rightStreetViewPropertyPhotos', 'Right Street View', 0)}} noIcon={true}/>
            {
              props.rightStreetViewPropertyPhotos.map(
                (photo, index)=>{
                  return <div key={index} className="display-inline" style={{width:200, height:250, margin:5}}>
                    <div>
                      <i hidden={props.disabled} className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeFile('rightStreetViewPropertyPhotos',index)}/> {photo.name.length>16?photo.name.substr(0,16)+'...':photo.name}
                    </div>
                    <img alt="pic" width="200" height="200" src={photo.status==='Pending'?"data:image/png;base64, "+photo.base64:photo.presign_url}/>
                  </div>
                }
              )
            }
          </Col>
        </Row>
      </CardBody>
    </Card>
    <br/>
    <Card>
      <CardHeader className="header-color">
        Extra Photos
      </CardHeader>
      <CardBody>
        <MyDropzone hidden={props.disabled} onDrop={(accepted, rejected)=>{onDrop(accepted, rejected, 'photos', 'Misc', 0)}}/>
        <br/>
        <div className="my-divider"></div>
        <div style={{minHeight:'35px',maxHeight:'35px'}} className="red-color flashit">
        </div>
        <div className="large-scroll-container">
          <table className="table file-list-table" cellSpacing="0" cellPadding="0">
            <thead>
              <tr>
                <th width="30%">Name</th>
                <th width="35%">Description</th>
                <th width="15%">Size</th>
                <th width="10%"><center>Status</center></th>
                <th width="10%">Control</th>
              </tr>
            </thead>
            <tbody>
              {toUploadFiles}
            </tbody>
          </table>
          {
            props.photos.map(
              (photo, index)=>{
                return <div key={index} className="display-inline" style={{width:200, height:250, margin:5}}>
                  <div>
                    <i hidden={props.disabled} className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeFile('photos',index)}/> {photo.name.length>16?photo.name.substr(0,16)+'...':photo.name}
                  </div>
                  <img alt="pic" width="200" height="200" src={photo.status==='Pending'?"data:image/png;base64, "+photo.base64:photo.presign_url}/>
                </div>
              }
            )
          }
        </div>
      </CardBody>
    </Card>
  </div>;
}


export default StepPhotos;
