//Author Sooyoung Kim
//Date Aug 22, 2023
import {showMessage, generateTooltip} from '../../util/util';
import {Card, CardHeader, CardBody, Col, Row, Input} from 'reactstrap';
import React, {useEffect} from 'react';
import MyDropzone from '../util/my-dropzone';
import MySelect from '../util/my-select';
import {deletePhoto} from './util';

const InteriorBathroom  = (props)=>{
  const controller = new AbortController();

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  //function trigger for dropzone react.
  //this function contians two list of files, the accepted and rejected file per the configuration
  const onDrop = (acceptedFiles, rejectedFiles, field, type='Misc', typeFk=0, description='') => {
    let existingFiles = [];
    if(acceptedFiles.length>0){
      for(let i=0;i<acceptedFiles.length;i++){
        existingFiles.push(acceptedFiles[i]);
        let name = existingFiles[i].name;
        let token = name.split('.');
        let extension = '';

        if(token.length>0){
          extension = token[token.length-1];
          extension = extension.toLowerCase();
        }

        if(extension!=='png'&&extension!=='jpg'&&extension!=='jpeg'){
          showMessage('error','Please upload only PNG/JPG image.');
        }
        else{
          const reader = new FileReader();
          reader.onload = () => {
              const fileAsBinaryString = reader.result;
              let base64 = btoa(fileAsBinaryString);
              let bathrooms = props.bathrooms.slice();
              let target = bathrooms[props.index];

              target[field].push({name:acceptedFiles[i].name, base64:base64, status:'Pending', size:acceptedFiles[i].size, type:type, typeFk:typeFk, description:description});

              bathrooms[props.index] = target;
              props.updateState({bathrooms:bathrooms});
          }
          reader.readAsBinaryString(acceptedFiles[i]);
        }
      }
    }
  }

  const removeFile = (field,index) => {
    let bathrooms = props.bathrooms.slice();
    let target = bathrooms[props.index];
    let targetToDelete = target[field][index];
    if(targetToDelete.ID && targetToDelete.ID!==-1){
      let successCallBack = ()=>{
        target[field].splice(index, 1);
        bathrooms[props.index] = target;
        props.updateState({bathrooms:bathrooms});
      };
      successCallBack = successCallBack.bind(this);
      deletePhoto(targetToDelete.ID, props, successCallBack);
    }
    else{
      target[field].splice(index, 1);
      bathrooms[props.index] = target;
      props.updateState({bathrooms:bathrooms});
    }
  }

  const removeField = (field, value) => {
    if(!props.disabled){
      let bathrooms = props.bathrooms.slice();
      let target = bathrooms[props.index];
      let index = target[field].indexOf(value);

      if(index!==-1)
        target[field].splice(index, 1);

      bathrooms[props.index] = target;
      props.updateState({bathrooms:bathrooms});
    }
  }

  const updateField = (field, value) => {
    let bathrooms = props.bathrooms.slice();
    let target = bathrooms[props.index];
    if(value==='None'){
      target[field] = ['None'];
    }
    else if(value!==''){
      let index = target[field].indexOf('None');

      if(index!==-1)
        target[field].splice(index, 1);

      index = target[field].indexOf(value);

      if(index===-1)
        target[field].push(value);
    }
    bathrooms[props.index] = target;
    console.log(bathrooms);
    props.updateState({bathrooms:bathrooms});
  }

  const updateState = (field, value) => {
    let bathrooms = props.bathrooms.slice();
    let target = bathrooms[props.index];

    target[field] = value;

    bathrooms[props.index] = target;

    props.updateState({bathrooms:bathrooms});
  }

  //render
  let disabledStyling = {border:'none', background:'#fff', padding:'0',paddingLeft:'2px', height:'auto',WebkitAppearance:'none'};

  let bathroomShowerTubMaterialConditions;

  if(props.bathrooms[props.index].bathroomShowerTubMaterialConditions.length>0)
    bathroomShowerTubMaterialConditions = props.bathrooms[props.index].bathroomShowerTubMaterialConditions.map(
      (bathroomShowerTubMaterialCondition, index)=>{
        return(
          <div key={index} className="display-inline margin-top">
            <div className="filter-container" onClick={()=>removeField('bathroomShowerTubMaterialConditions', bathroomShowerTubMaterialCondition)}>
              <i hidden={props.disabled} className="fa fa-minus red-color"></i> {bathroomShowerTubMaterialCondition}
            </div>
            &nbsp;
          </div>
        );
      }
    )

  let bathroomFloorMaterials;

  if(props.bathrooms[props.index].bathroomFloorMaterials.length>0)
    bathroomFloorMaterials = props.bathrooms[props.index].bathroomFloorMaterials.map(
      (bathroomFloorMaterial, index)=>{
        return(
          <div key={index} className="display-inline margin-top">
            <div className="filter-container" onClick={()=>removeField('bathroomFloorMaterials', bathroomFloorMaterial)}>
              <i hidden={props.disabled} className="fa fa-minus red-color"></i> {bathroomFloorMaterial}
            </div>
            &nbsp;
          </div>
        );
      }
    )

  let bathroomWallAndCeilingMaterials;

  if(props.bathrooms[props.index].bathroomWallAndCeilingMaterials.length>0)
    bathroomWallAndCeilingMaterials = props.bathrooms[props.index].bathroomWallAndCeilingMaterials.map(
      (bathroomWallAndCeilingMaterial, index)=>{
        return(
          <div key={index} className="display-inline margin-top">
            <div className="filter-container" onClick={()=>removeField('bathroomWallAndCeilingMaterials', bathroomWallAndCeilingMaterial)}>
              <i hidden={props.disabled} className="fa fa-minus red-color"></i> {bathroomWallAndCeilingMaterial}
            </div>
            &nbsp;
          </div>
        );
      }
    )

  let bathroomFloorMaterialConditions;

  if(props.bathrooms[props.index].bathroomFloorMaterialConditions.length>0)
    bathroomFloorMaterialConditions = props.bathrooms[props.index].bathroomFloorMaterialConditions.map(
      (bathroomFloorMaterialCondition, index)=>{
        return(
          <div key={index} className="display-inline margin-top">
            <div className="filter-container" onClick={()=>removeField('bathroomFloorMaterialConditions', bathroomFloorMaterialCondition)}>
              <i hidden={props.disabled} className="fa fa-minus red-color"></i> {bathroomFloorMaterialCondition}
            </div>
            &nbsp;
          </div>
        );
      }
    )

  let bathroomWallAndCeilingMaterialConditions;

  if(props.bathrooms[props.index].bathroomWallAndCeilingMaterialConditions.length>0)
    bathroomWallAndCeilingMaterialConditions = props.bathrooms[props.index].bathroomWallAndCeilingMaterialConditions.map(
      (bathroomWallAndCeilingMaterialCondition, index)=>{
        return(
          <div key={index} className="display-inline margin-top">
            <div className="filter-container" onClick={()=>removeField('bathroomWallAndCeilingMaterialConditions', bathroomWallAndCeilingMaterialCondition)}>
              <i hidden={props.disabled} className="fa fa-minus red-color"></i> {bathroomWallAndCeilingMaterialCondition}
            </div>
            &nbsp;
          </div>
        );
      }
    )


  let bathroomShowerTubMaterials;

  if(props.bathrooms[props.index].bathroomShowerTubMaterials.length>0)
    bathroomShowerTubMaterials = props.bathrooms[props.index].bathroomShowerTubMaterials.map(
      (bathroomShowerTubMaterial, index)=>{
        return(
          <div key={index} className="display-inline margin-top">
            <div className="filter-container" onClick={()=>removeField('bathroomShowerTubMaterials', bathroomShowerTubMaterial)}>
              <i hidden={props.disabled} className="fa fa-minus red-color"></i> {bathroomShowerTubMaterial}
            </div>
            &nbsp;
          </div>
        );
      }
    )

  let removeIcon;

  if(props.bathrooms[props.index].removable && !props.disabled)
    removeIcon = <i hidden={props.disabled} className="fa fa-times red-color cursor-pointer" onClick={(e)=>props.removeRoom('bathrooms', props.index)}/>;

  let showerTubPhoto;
  let showerTubRelatedFields;

  if(props.bathrooms[props.index].bathroomHalfBath==='False'){
    showerTubPhoto = <Row style={{marginTop:'15px'}}>
      <Col sm="12">
        {!props.disabled && props.bathrooms[props.index].bathroomShowerTubMaterialConditions.length>0 && props.bathrooms[props.index].bathroomShowerTubMaterialConditions.indexOf('None')===-1? <div><label className="flashit"><font color="red">Please upload photo of the shower tub condition(s)</font></label><br/></div>:null}
        <label>{props.bathrooms[props.index].bathroomShowerTubMaterialConditions.indexOf('None')===-1?<font color="red">*</font>:null}Shower/Tub Photos</label>
        <br/>
        <MyDropzone hidden={props.disabled} onDrop={(accepted, rejected)=>{onDrop(accepted, rejected, 'bathroomShowerTubPhotos', 'Bathroom Shower Tub',props.bathrooms[props.index].ID )}} noIcon={true}/>
        {
          props.bathrooms[props.index].bathroomShowerTubPhotos.map(
            (photo, index)=>{
              return <div key={index} className="display-inline" style={{width:200, height:250, margin:5}}>
                <div>
                  <i hidden={props.disabled} className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeFile('bathroomShowerTubPhotos',index)}/> {photo.name.length>16?photo.name.substr(0,16)+'...':photo.name}
                </div>
                <img alt="pic" width="200" height="200" src={photo.status==='Pending'?"data:image/png;base64, "+photo.base64:photo.presign_url}/>
              </div>
            }
          )
        }
      </Col>
    </Row>;

    showerTubRelatedFields = <Row style={{marginTop:'15px'}}>
      <Col sm="4">
        <label>{props.bathrooms[props.index].bathroomHalfBath==='False'?<font color="red">*</font>:null}Shower/Tub Material {generateTooltip('bathroomShowerTubMaterials'+props.index, 'Select all that apply.')}</label>
        <MySelect
          type="select"
          style={props.disabled?disabledStyling:{}}
          disabled={props.disabled}
          required={props.bathrooms[props.index].bathroomHalfBath==='False' && props.bathrooms[props.index].bathroomShowerTubMaterials.length<=0}
          value={""}
          onChange={(v)=>updateField('bathroomShowerTubMaterials',v)}
          options={[
            {label:"",value:""},
            {label:"Marble",value:"Marble"},
            {label:"Tile",value:"Tile"},
            {label:"Vinyl",value:"Vinyl"},
            {label:"Laminate",value:"Laminate"},
            {label:"Stone",value:"Stone"},
            {label:"Fiberglass",value:"Fiberglass"},
            {label:"Plastic",value:"Plastic"},
            {label:"None",value:"None"},
          ]}
        />
        <div className="extra-small-scroll-container">
          {bathroomShowerTubMaterials}
        </div>
      </Col>
      <Col sm="4">
        <label>{props.bathrooms[props.index].bathroomHalfBath==='False'?<font color="red">*</font>:null}Shower/Tub Material Condition {generateTooltip('bathroomShowerTubMaterialConditions'+props.index, 'Select all that apply.')}</label>
        <MySelect
          type="select"
          style={props.disabled?disabledStyling:{}}
          disabled={props.disabled}
          required={props.bathrooms[props.index].bathroomHalfBath==='False' && props.bathrooms[props.index].bathroomShowerTubMaterialConditions.length<=0}
          value={""}
          onChange={(v)=>updateField('bathroomShowerTubMaterialConditions',v)}
          options={[
            {label:"",value:""},
            {label:"None",value:"None"},
            {label:"ErodedMaterial",value:"Eroded Material"},
            {label:"Holes",value:"Holes"},
            {label:"Cracks",value:"Cracks"},
          ]}
        />
        <div className="extra-small-scroll-container">
          {bathroomShowerTubMaterialConditions}
        </div>
      </Col>
    </Row>;
  }

  return <div>
    <Card>
      <CardHeader className="header-color">
        {removeIcon} {props.bathrooms[props.index].name}
      </CardHeader>
      <CardBody>
        <Row style={{marginTop:'15px'}}>
          <Col sm="8">
            <label>Bathroom name</label>
            <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} type="text" value={props.bathrooms[props.index].name} onChange={(e)=>{updateState('name', e.target.value)}}/>
          </Col>
          <Col sm="2">
            <label><font color="red">*</font>Grade</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={props.bathrooms[props.index].bathroomHalfBath==='False' && props.bathrooms[props.index].bathroomShowerTubMaterialConditions.length<=0}
              value={props.bathrooms[props.index].grade}
              onChange={(v)=>updateState('grade',v)}
              options={[
                {label:"",value:""},
                {label:"Above Grade",value:"above"},
                {label:"Below Grade",value:"below"},
              ]}
            />
          </Col>
          <Col sm="2">
            <label>Half Bath</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={true}
              value={props.bathrooms[props.index].bathroomHalfBath}
              onChange={(v)=>updateState('bathroomHalfBath', v)}
              options={[
                {label:"",value:""},
                {label:"Yes",value:"True"},
                {label:"No",value:"False"},
              ]}
            />
          </Col>
        </Row>
        <Row style={{marginTop:'15px'}}>
          <Col sm="12">
            <label><font color="red">*</font>Bathroom Photos</label>
            <br/>
            <MyDropzone hidden={props.disabled} onDrop={(accepted, rejected)=>{onDrop(accepted, rejected, 'bathroomPhotos', 'Bathroom Photo', props.bathrooms[props.index].ID)}} noIcon={true}/>
            {
              props.bathrooms[props.index].bathroomPhotos.map(
                (photo, index)=>{
                  return <div key={index} className="display-inline" style={{width:200, height:250, margin:5}}>
                    <div>
                      <i hidden={props.disabled} className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeFile('bathroomPhotos',index)}/> {photo.name.length>16?photo.name.substr(0,16)+'...':photo.name}
                    </div>
                    <img alt="pic" width="200" height="200" src={photo.status==='Pending'?"data:image/png;base64, "+photo.base64:photo.presign_url}/>
                  </div>
                }
              )
            }
          </Col>
        </Row>
        <br/>
        <Row style={{marginTop:'15px'}}>
          <Col sm="6">
            <Row style={{marginTop:'15px'}}>
              <Col sm="6">
                <label>{(props.structureType==='PrimaryDwelling'||props.structureType==='ADU')?<font color="red">*</font>:null}Interior Floors Material {generateTooltip('bathroomFloorMaterials'+props.index, 'Select all that apply.')}</label>
                <MySelect
                  type="select"
                  style={props.disabled?disabledStyling:{}}
                  disabled={props.disabled}
                  required={(props.structureType==='PrimaryDwelling'||props.structureType==='ADU') && props.bathrooms[props.index].bathroomFloorMaterials.length<=0}
                  value={props.bathrooms[props.index].bathroomFloorMaterials}
                  onChange={(v)=>updateField('bathroomFloorMaterials',v)}
                  options={[
                    {label:"",value:""},
                    {label:"Hardwood",value:"Hardwood"},
                    {label:"Carpet",value:"Carpet"},
                    {label:"Tile",value:"Tile"},
                    {label:"Vinyl",value:"Vinyl"},
                    {label:"Laminate",value:"Laminate"},
                    {label:"Finished Concrete",value:"FinishedConcrete"},
                    {label:"Unfinished Concrete",value:"UnfinishedConcrete"},
                    {label:"Other Wood Product",value:"OtherWoodProduct"},
                    {label:"Other Composite Product",value:"OtherCompositeProduct"},
                    {label:"Stone",value:"Stone"},
                  ]}
                />
                <div className="extra-small-scroll-container">
                  {bathroomFloorMaterials}
                </div>
              </Col>
              <Col sm="6">
                <label>{(props.structureType==='PrimaryDwelling'||props.structureType==='ADU')?<font color="red">*</font>:null}Interior Floors Material Condition {generateTooltip('bathroomFloorMaterialConditions'+props.index, 'Select all that apply.')}</label>
                <MySelect
                  type="select"
                  style={props.disabled?disabledStyling:{}}
                  disabled={props.disabled}
                  required={(props.structureType==='PrimaryDwelling'||props.structureType==='ADU')&&props.bathrooms[props.index].bathroomFloorMaterialConditions.length<=0}
                  value={props.bathrooms[props.index].bathroomFloorMaterialConditions}
                  onChange={(v)=>updateField('bathroomFloorMaterialConditions',v)}
                  options={[
                    {label:"",value:""},
                    {label:"None",value:"None"},
                    {label:"Buckled",value:"Buckled"},
                    {label:"Holes",value:"Holes"},
                    {label:"Sagging",value:"Sagging"},
                    {label:"Spongy",value:"Spongy"},
                    {label:"Infestation",value:"Infestation"},
                    {label:"Dry Rot",value:"Dry Rot"},
                    {label:"Discloration",value:"discloration"},
                    {label:"Cracks",value:"cracks"},
                    {label:"Unfinished Section",value:"unfinished section"},
                    {label:"Dampness",value:"dampness"},
                  ]}
                />
                <div className="extra-small-scroll-container">
                  {bathroomFloorMaterialConditions}
                </div>
              </Col>
            </Row>
            <Row style={{marginTop:'15px'}}>
              <Col sm="6">
                <label>{(props.structureType==='PrimaryDwelling'||props.structureType==='ADU')?<font color="red">*</font>:null}Interior Floors Updates</label>
                <MySelect
                  type="select"
                  style={props.disabled?disabledStyling:{}}
                  disabled={props.disabled}
                  required={(props.structureType==='PrimaryDwelling'||props.structureType==='ADU')}
                  value={props.bathrooms[props.index].bathroomFloorUpdates}
                  onChange={(v)=>updateState('bathroomFloorUpdates',v)}
                  options={[
                    {label:"",value:""},
                    {label:"Fully Updated",value:"Fully Updated"},
                    {label:"Partially Updated",value:"Partially Updated"},
                    {label:"No Update",value:"No Update"},
                  ]}
                />
              </Col>
              <Col sm="6">
                <label>{(props.bathrooms[props.index].bathroomFloorUpdates==='Fully Updated'||props.bathrooms[props.index].bathroomFloorUpdates==='Partially Updated')?<font color="red">*</font>:null}Interior Floors Update Description</label>
                <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={(props.bathrooms[props.index].bathroomFloorUpdates==='Fully Updated'||props.bathrooms[props.index].bathroomFloorUpdates==='Partially Updated')} type="text" value={props.bathrooms[props.index].bathroomFloorUpdateDescription} onChange={(e)=>{updateState('bathroomFloorUpdateDescription',e.target.value)}}/>
              </Col>
            </Row>
            <br/>
            <Row style={{marginTop:'15px'}}>
              <Col sm="12">
                {!props.disabled && props.bathrooms[props.index].bathroomFloorMaterialConditions.length>0 && props.bathrooms[props.index].bathroomFloorMaterialConditions.indexOf('None')===-1? <div><label className="flashit"><font color="red">Please upload photo of the floor condition(s)</font></label><br/></div>:null}
                <label>{props.bathrooms[props.index].bathroomFloorMaterialConditions.indexOf('None')===-1?<font color="red">*</font>:null}Interior Floors Photos</label>
                <br/>
                <MyDropzone hidden={props.disabled} onDrop={(accepted, rejected)=>{onDrop(accepted, rejected, 'bathroomFloorMaterialPhotos', 'Bathroom Floor', props.bathrooms[props.index].ID)}} noIcon={true}/>
                {
                  props.bathrooms[props.index].bathroomFloorMaterialPhotos.map(
                    (photo, index)=>{
                      return <div key={index} className="display-inline" style={{width:200, height:250, margin:5}}>
                        <div>
                          <i hidden={props.disabled} className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeFile('bathroomFloorMaterialPhotos',index)}/> {photo.name.length>16?photo.name.substr(0,16)+'...':photo.name}
                        </div>
                        <img alt="pic" width="200" height="200" src={photo.status==='Pending'?"data:image/png;base64, "+photo.base64:photo.presign_url}/>
                      </div>
                    }
                  )
                }
              </Col>
            </Row>
          </Col>
          <Col sm="6" style={{borderLeft:'1px solid #d2d2d2'}}>
            <Row style={{marginTop:'15px'}}>
              <Col sm="6">
                <label>{(props.structureType==='PrimaryDwelling'||props.structureType==='ADU')?<font color="red">*</font>:null}Interior Wall & Ceiling Material {generateTooltip('bathroomWallAndCeilingMaterials'+props.index, 'Select all that apply.')}</label>
                <MySelect
                  type="select"
                  style={props.disabled?disabledStyling:{}}
                  disabled={props.disabled}
                  required={(props.structureType==='PrimaryDwelling'||props.structureType==='ADU')&&props.bathrooms[props.index].bathroomWallAndCeilingMaterials.length<=0}
                  value={props.bathrooms[props.index].bathroomWallAndCeilingMaterials}
                  onChange={(v)=>updateField('bathroomWallAndCeilingMaterials',v)}
                  options={[
                    {label:"",value:""},
                    {label:"Drywall",value:"Drywall"},
                    {label:"Plaster",value:"Plaster"},
                    {label:"Wood",value:"Wood"},
                    {label:"Concrete",value:"Concrete"},
                    {label:"Brick",value:"Brick"},
                    {label:"Metal",value:"Metal"},
                    {label:"None",value:"None"},
                  ]}
                />
                <div className="extra-small-scroll-container">
                  {bathroomWallAndCeilingMaterials}
                </div>
              </Col>
              <Col sm="6">
                <label>{(props.structureType==='PrimaryDwelling'||props.structureType==='ADU')?<font color="red">*</font>:null}Interior Wall & Ceiling Material Condition {generateTooltip('bathroomWallAndCeilingMaterialConditions'+props.index, 'Select all that apply.')}</label>
                <MySelect
                  type="select"
                  style={props.disabled?disabledStyling:{}}
                  disabled={props.disabled}
                  required={(props.structureType==='PrimaryDwelling'||props.structureType==='ADU')&&props.bathrooms[props.index].bathroomWallAndCeilingMaterialConditions.length<=0}
                  value={props.bathrooms[props.index].bathroomFloorMaterial}
                  onChange={(v)=>updateField('bathroomWallAndCeilingMaterialConditions',v)}
                  options={[
                    {label:"",value:""},
                    {label:"None",value:"None"},
                    {label:"Discloration",value:"Discloration"},
                    {label:"Missing",value:"Missing"},
                    {label:"Crumbling",value:"Crumbling"},
                    {label:"Water Damage",value:"water damage"},
                  ]}
                />
                <div className="extra-small-scroll-container">
                  {bathroomWallAndCeilingMaterialConditions}
                </div>
              </Col>
            </Row>
            <Row style={{marginTop:'15px'}}>
              <Col sm="6">
                <label>{(props.structureType==='PrimaryDwelling'||props.structureType==='ADU')?<font color="red">*</font>:null}Interior Wall & Ceiling Updates</label>
                <MySelect
                  type="select"
                  style={props.disabled?disabledStyling:{}}
                  disabled={props.disabled}
                  required={(props.structureType==='PrimaryDwelling'||props.structureType==='ADU')}
                  value={props.bathrooms[props.index].bathroomWallAndCeilingUpdates}
                  onChange={(v)=>updateState('bathroomWallAndCeilingUpdates',v)}
                  options={[
                    {label:"",value:""},
                    {label:"Fully Updated",value:"Fully Updated"},
                    {label:"Partially Updated",value:"Partially Updated"},
                    {label:"No Update",value:"No Update"},
                  ]}
                />
              </Col>
              <Col sm="6">
                <label>{(props.bathrooms[props.index].bathroomWallAndCeilingUpdates==='Fully Updated'||props.bathrooms[props.index].bathroomWallAndCeilingUpdates==='Partially Updated')?<font color="red">*</font>:null}Interior Wall & Ceiling Update Description</label>
                <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={(props.bathrooms[props.index].bathroomWallAndCeilingUpdates==='Fully Updated'||props.bathrooms[props.index].bathroomWallAndCeilingUpdates==='Partially Updated')} type="text" value={props.bathrooms[props.index].bathroomWallAndCeilingUpdateDescription} onChange={(e)=>{updateState('bathroomWallAndCeilingUpdateDescription',e.target.value)}}/>
              </Col>
            </Row>
            <br/>
            <Row style={{marginTop:'15px'}}>
              <Col sm="12">
                {!props.disabled && props.bathrooms[props.index].bathroomWallAndCeilingMaterialConditions.length>0 && props.bathrooms[props.index].bathroomWallAndCeilingMaterialConditions.indexOf('None')===-1? <div><label className="flashit"><font color="red">Please upload photo of the wall & ceiling condition(s)</font></label><br/></div>:null}
                <label>{props.bathrooms[props.index].bathroomWallAndCeilingMaterialConditions.indexOf('None')===-1?<font color="red">*</font>:null}Interior Wall & Ceiling Photos</label>
                <br/>
                <MyDropzone hidden={props.disabled} onDrop={(accepted, rejected)=>{onDrop(accepted, rejected, 'bathroomWallAndCeilingMaterialPhotos', 'Bathroom Wall Ceiling',props.bathrooms[props.index].ID)}} noIcon={true}/>
                {
                  props.bathrooms[props.index].bathroomWallAndCeilingMaterialPhotos.map(
                    (photo, index)=>{
                      return <div key={index} className="display-inline" style={{width:200, height:250, margin:5}}>
                        <div>
                          <i hidden={props.disabled} className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeFile('bathroomWallAndCeilingMaterialPhotos',index)}/> {photo.name.length>16?photo.name.substr(0,16)+'...':photo.name}
                        </div>
                        <img alt="pic" width="200" height="200" src={photo.status==='Pending'?"data:image/png;base64, "+photo.base64:photo.presign_url}/>
                      </div>
                    }
                  )
                }
              </Col>
            </Row>
          </Col>
        </Row>
        <br/>
        {showerTubRelatedFields}
        {showerTubPhoto}

        <Row>
          <Col sm="6">
            <label>{props.bathrooms[props.index].bathroomHalfBath==='False'?<font color="red">*</font>:null}Estimated Year Of Improvement</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={props.bathrooms[props.index].bathroomHalfBath==='False'}
              value={props.bathrooms[props.index].bathroomEstimatedYearOfImprovement}
              onChange={(v)=>updateState('bathroomEstimatedYearOfImprovement',v)}
              options={[
                {label:"",value:""},
                {label:"Less Than 1 Year Ago",value:"LessThanOneYearAgo"},
                {label:"1 To 5 Years Ago",value:"OneToFiveYearsAgo"},
                {label:"6 To 10 Years Ago",value:"SixToTenYearsAgo"},
                {label:"11 To 15 Years Ago",value:"ElevenToFifteenYearsAgo"},
                {label:"Not Updated",value:"NotUpdated"},
                {label:"Unknown",value:"Unknown"},
              ]}
            />
          </Col>
          <Col sm="6">
            <label>{(props.bathrooms[props.index].bathroomHalfBath==='False'&&props.bathrooms[props.index].bathroomEstimatedYearOfImprovement!=='NotUpdated' && props.bathrooms[props.index].bathroomEstimatedYearOfImprovement!=='Unknown' && props.bathrooms[props.index].bathroomEstimatedYearOfImprovement!=='')?<font color="red">*</font>:null}Improvement Description</label>
            <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={(props.bathrooms[props.index].bathroomHalfBath==='False'&&props.bathrooms[props.index].bathroomEstimatedYearOfImprovement!=='NotUpdated' && props.bathrooms[props.index].bathroomEstimatedYearOfImprovement!=='Unknown' && props.bathrooms[props.index].bathroomEstimatedYearOfImprovement!=='')} type="text" value={props.bathrooms[props.index].bathroomImprovementDescription} onChange={(e)=>{updateState('bathroomImprovementDescription',e.target.value)}}/>
          </Col>
        </Row>
        <br/>
      </CardBody>
    </Card>
  </div>;
}


export default InteriorBathroom;
