//Author June leow
//Date Oct 9th, 2023
import {getReducer, getSetStateFunction, sliceFromArray, formatDateTime, postAPICallGenerator, deleteAPICallGenerator} from '../../util/util';
import {Button, Modal, ModalBody, ModalHeader, Table, Input, Offcanvas, OffcanvasBody} from 'reactstrap';
import React, {useReducer, useEffect, useRef} from 'react';
//initialize the state
const initialState = {
  show:true,
  scrolled:false,
  memoDropDownOpen:false,
  newMemo:''
};

//reducer function that perform state update
const reducer = getReducer();


const FloatingMemo  = (props)=>{
  const controller = new AbortController();
  const scrolledRef = useRef(false);
  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    
    const myDiv = document.getElementById('contentContainer');
    if (myDiv) {
      myDiv.addEventListener('scroll', onScroll);
    }

    return ()=> {
      if(myDiv){
        myDiv.removeEventListener('scroll', onScroll);
      }
      controller.abort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    if(props.memos.length>0 && !state.show)
      setState({show:true});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.memos])

  //non API call but simpyl manage state
  const toggleMemoPopup = () => {
    setState({memoDropDownOpen:!state.memoDropDownOpen});
  }

  const onScroll = ()=>{
    if (!scrolledRef.current) {
      scrolledRef.current = true;
      setState({show:false})
    }
  }

  const toggleShow = ()=>{
    setState({show:!state.show});
  }

  //API call
  const addNewMemo = () => {
    let parameters = [
      {
        field:'appraiserFk',
        value:props.id
      },
      {
        field:'memo',
        value:state.newMemo
      },
    ];

    let callBack = (response)=>{
      //console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let memos = props.memos.slice();

        memos.push(response.data.data);
        props.updateMemo(memos);
        toggleMemoPopup();
      }
    };
    callBack = callBack.bind(this);

    httpPost('appraiser/memo/create', parameters, 'New memo added successfully.', 'Oops, something went wrong and could not add new memo. Please try again later.', callBack);
  }

  const deleteMemo = (id) => {
    let memos = props.memos.slice();
    let newMemos = sliceFromArray(memos,'ID', id);
    
    let callBack = (response)=>{
      let code = response.data.code;

      if(code==='00'){
        props.updateMemo(newMemos);
      }
    }

    httpDelete('appraiser/memo/'+id,'Memo deleted successfully.','Oops, something went wrong and could not delete the memo. Please try again later.', callBack);
  }

  //render
  return <div>
    <Modal className="my-modal" isOpen={state.memoDropDownOpen} toggle={toggleMemoPopup} >
      <ModalHeader hidden={true} toggle={toggleMemoPopup}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-pencil"></i> Add Memo</h5>
        </center>
        <br/>
        <Input type="textarea" placeholder="Write your memo" value={state.newMemo} onChange={(e)=>setState({newMemo:e.target.value})} className="new-comment-reply" rows="4"/>

        <center>
          <Button color="warning" onClick={addNewMemo}>Add</Button>&nbsp;
          <Button color="info" onClick={toggleMemoPopup}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
    {!state.show?<div className="floating-memo-container cursor-pointer" onClick={toggleShow}>
      <center>
        {props.memos.length>0?<i className="flashit fa fa-warning red-color"/>:null} SHOW MEMO
      </center>
    </div>:null}
    <Offcanvas
      isOpen={state.show}
      backdrop={false}
      direction="end"
      className="floating-memo"
      style={{padding:'0px'}}
    >
      <OffcanvasBody style={{padding:'0px', paddingBottom:'20px', paddingLeft:'20px'}}>
        <div className="floating-memo-nav-bar cursor-pointer" onClick={toggleShow}>
          <center>
            HIDE MEMO
          </center>
        </div>
        <div className="floating-memo-content">
          <div style={{overflowY:'auto', overflowX:'hidden', height:'355px'}}>
            <Table className="table table-striped" style={{fontSize:'14px'}}>
              <thead>
                <tr>
                  <th width="58%">Memo</th>
                  <th width="15%">Author</th>
                  <th width="27%">Date</th>
                </tr>
              </thead>
              <tbody>
                {
                  props.memos && props.memos.map(
                    (memo, index)=>{
                      return <tr className="cursor-pointer" key={index}>
                              <td style={{maxWidth:'200px'}}><i className="fa fa-times red-color cursor-pointer" onClick={(e)=>deleteMemo(memo.ID)}></i> {memo.memo}</td>
                              <td style={{fontSize:'11px'}}>{memo.author}</td>
                              <td style={{fontSize:'11px'}}>{formatDateTime(memo.datetime_created)}</td>
                            </tr>
                    }
                  )
                }
              </tbody>
            </Table>
          </div>
          <Button color="warning" className="form-control" onClick={toggleMemoPopup}><i className="fa fa-pencil"></i> Add Memo</Button>
        </div>
      </OffcanvasBody> 
    </Offcanvas>
  </div>;
}


export default FloatingMemo;
