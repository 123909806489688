//Author Sooyoung Kim
//Date April 21, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, deleteAPICallGenerator, callBackGenerator, confirmation} from '../../util/util';
import {Button, Col, Row, Table, Input, Card, CardBody, CardHeader, Modal, ModalHeader, ModalBody, UncontrolledTooltip} from 'reactstrap';
import {NavLink} from 'react-router-dom';
import MySelect from '../util/my-select';
import React, {useReducer, useEffect} from 'react';


//initialize the state
const initialState = {
  blackList:[],
  newBlackListConditionRuleSelected:'',
  newBlackListConditionField:'',
  newBlackListConditionOperator:'=',
  newBlackListConditionDescription:'',
  newBlackListConditionValues:[],
  newBlackListConditionValuesT:'',
  newBlackListConditionValuesS:'',
  newBlackListName:'',
  fieldsEnum:[],
  clients:[],
  targetClientShippingProfile:{},
  entities:[],
  shippingDropDownOpen:false,
  shipToBorrower:'no',
  emailReport:'no',
  emailRecipient:'',
  borrowerDelayTimer:0,
  targetLabel:'',
  otherLabel:'',
  shippingLabels:[],
  client:0
};

//reducer function that perform state update
const reducer = getReducer();


const AutoShippingConfiguration  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);
  const apiCallBack = callBackGenerator(setState);

  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getBlacklist();
    getRuleFieldEnum();
    getAllClientProfiles();
    getAllClientEntityList();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const addShippingLabel = () =>{
    if(state.targetLabel!==''){
      let labels = state.shippingLabels.slice();

      let index = labels.indexOf(state.targetLabel);

      if(index===-1){
        if(state.targetLabel==='Other')
          labels.push(state.otherLabel);
        else
          labels.push(state.targetLabel);
      }
      setState({shippingLabels:labels});
    }
  }

  const removeLabel = (label) =>{
    let labels = state.shippingLabels.slice();

    let index = labels.indexOf(label);

    if(index!==-1)
      labels.splice(index,1);
    setState({shippingLabels:labels});
  }

  const toggleShippingDropDown = () =>{
    setState({shippingDropDownOpen:!state.shippingDropDownOpen});
  }

  const blackListValueOnChange = (v) =>{
    setState({newBlackListConditionValuesT:v});
  }

  const blackListFieldOnChange = (v) =>{
    for(let i=0;i<state.fieldsEnum.length;i++){
      if(state.fieldsEnum[i].field===v){
        setState({newBlackListConditionValues:state.fieldsEnum[i].enum});
        break;
      }
    }
  }

  //API call
  const addNewBlackListCondition = () =>{
    let url = 'autoReview/configuration/rule/condition/create';
    let successMsg = 'Auto review dynamic rule condition created successfully.';
    let errorMsg = 'Oops, something went wrong and could not create the rule condition. Please try again later.';

    let select = true;

    for(let i=0;i<state.fieldsEnum.length;i++){
      //there is no drop down, the value should coming from text instead
      if(state.fieldsEnum[i].field===state.newBlackListConditionField){
        if(state.fieldsEnum[i].enum.length<=0)
          select = false;
      }
    }

    let value;

    if(select)
      value = state.newBlackListConditionValuesS;
    else
      value = state.newBlackListConditionValuesT;


    let parameters = [
      {
        field:'description',
        value:state.newBlackListConditionDescription
      },
      {
        field:'field',
        value:state.newBlackListConditionField
      },
      {
        field:'operator',
        value:state.newBlackListConditionOperator
      },
      {
        field:'value',
        value:value
      },
      {
        field:'ruleFk',
        value:state.newBlackListConditionRuleSelected
      },
    ];

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code!=='00'){

      }
      else{
        let list = state.blackList.slice();

        for(let i=0;i<list.length;i++){
          if(list[i].ID===state.newBlackListConditionRuleSelected){
            list[i].conditions.push(response.data.data);
          }
        }

        setState({blackList:list});
      }
    };
    callBack = callBack.bind(this);

    httpPost(url,parameters,successMsg,errorMsg, callBack);
  }

  const updateShippingConfiguration = () =>{
    let url = 'shipping/label/create';
    let successMsg = 'Client shipping configuration updated successfully.';
    let errorMsg = 'Oops, something went wrong and could not update the configuration. Please try again later.';

    let parameters = [
      {
        field:'clientFk',
        value:state.client
      },
      {
        field:'shipToBorrower',
        value:state.shipToBorrower
      },
      {
        field:'borrowerDelayTimer',
        value:state.borrowerDelayTimer
      },
      {
        field:'labels',
        value:state.shippingLabels
      },
      {
        field:'emailRecipient',
        value:state.emailRecipient
      },
      {
        field:'emailReport',
        value:state.emailReport
      },
      {
        field:'appaiserEAndO',
        value:state.appraiserEAndO
      }
    ];

    let callBack = (response)=>{console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code!=='00'){

      }
      else{
        setState({targetClientShippingProfile:response.data.data});
      }
    };
    callBack = callBack.bind(this);

    httpPost(url,parameters,successMsg,errorMsg, callBack);
  }

  const addNewBlackList = () =>{
    let url = 'autoReview/configuration/rule/create';
    let successMsg = 'Auto review dynamic rule created successfully.';
    let errorMsg = 'Oops, something went wrong and could not create the rule. Please try again later.';

    let parameters = [
      {
        field:'name',
        value:state.newBlackListName
      },
    ];

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code!=='00'){

      }
      else{
        let list = state.blackList.slice();

        list.push(response.data.data);

        setState({blackList:list});
        setState({newBlackListName:''});
      }
    };
    callBack = callBack.bind(this);

    httpPost(url,parameters,successMsg,errorMsg, callBack);
  }

  //delete a black list rule
  const deleteBlackListRule = (id) =>{
    let url = 'autoReview/configuration/rule/'+id
    let successMsg = 'Auto review dynamic rule deleted successfull.';
    let errorMsg = 'Oops, something went wrong and could not delete the rule. Please try again later.';

    let callBack = (response)=>{
      let code= response.data.code;

      if(code!=='00'){

      }
      else{
        let list = state.blackList.slice();

        for(let i=0;i<list.length;i++){
          if(list[i].ID===id){
            list.splice(i,1);
            break;
          }
        }

        setState({blackList:list});
      }
    };

    callBack = callBack.bind(this);

    httpDelete(url,successMsg,errorMsg, callBack);
  }

  const deleteShippingConfiguration = () =>{
    let url = 'shipping/label/'+state.targetClientShippingProfile.ID;
    let successMsg = 'Shipping configuration deleted successfull.';
    let errorMsg = 'Oops, something went wrong and could not delete the configuration. Please try again later.';
    let callBack = apiCallBack([{state:'targetClientShippingProfile', value:{}}]);
    httpDelete(url,successMsg,errorMsg, callBack);
  }

  //delete a black list condition
  const deleteBlackListCondition = (id,conditionID) =>{
    let url = 'autoReview/configuration/rule/condition/'+conditionID
    let successMsg = 'Auto review dynamic condition deleted successfull.';
    let errorMsg = 'Oops, something went wrong and could not delete the condition. Please try again later.';

    let callBack = (response)=>{
      let code= response.data.code;

      if(code!=='00'){

      }
      else{
        let list = state.blackList.slice();

        for(let i=0;i<list.length;i++){
          if(list[i].ID===id){
            for(let j=0;j<list[i].conditions.length;j++){
              if(list[i].conditions[j].ID===conditionID){
                list[i].conditions.splice(j,1);
                break;
              }
            }

            break;
          }
        }

        setState({blackList:list});
      }
    };

    callBack = callBack.bind(this);

    httpDelete(url,successMsg,errorMsg, callBack);
  }

  const getAllClientEntityList = () =>{
    let url = 'client/entity/label/get';
    let callBack = apiCallBack([{state:'entities', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not retrieve entity list.', callBack);
  }

  const loadShippingProfile = (clientFk) =>{
    let url = 'shipping/label/'+clientFk;
    let callBack = apiCallBack([{state:'targetClientShippingProfile', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load the black list. Please try again later.', callBack);
  }

  const getBlacklist = () =>{
    let url = 'autoReview/configuration/rule/get';
    let callBack = apiCallBack([{state:'blackList', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load the auto review dynamic rule. Please try again later.', callBack);
  }

  const getAllClientProfiles = () =>{
    let url = 'client/get/limit=-1&offset=-1';
    let callBack = apiCallBack([{state:'clients', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not retrieve client profiles.', callBack);
  }

  const getRuleFieldEnum = () =>{
    let url = 'autoAssign/rules/field/value';
    let callBack = apiCallBack([{state:'fieldsEnum', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load the rule fields. Please try again later.', callBack);
  }

  //render
  let blackLists, valueWidget;

  if(state.newBlackListConditionValues!==undefined&&state.newBlackListConditionValues.length>0 && state.newBlackListConditionOperator!=='like')
    valueWidget = <MySelect
      type="select"
      required={true}
      selectIsClearable={true}
      value={state.newBlackListConditionValuesS}
      onChange={(v)=>{blackListValueOnChange(v);setState({newBlackListConditionValuesS:v})}}
      options={state.newBlackListConditionValues.map((value)=>{
        return {label:value.label, value:value.value};
      })}
    />;
  else
    valueWidget = <Input required={true} type="text" onChange={(e)=>setState({newBlackListConditionValuesT:e.target.value})} value={state.newBlackListConditionValuesT}/>;


  blackLists = state.blackList.map(
    (list, index)=>{

      let conditions;

      conditions = list.conditions.map(
        (condition,index2)=>{
          return(
            <tr key={index2}>
              <td><i className="fa fa-times red-color cursor-pointer" onClick={()=>deleteBlackListCondition(list.ID, condition.ID)}></i> {condition.description}</td>
              <td>{condition.field}</td>
              <td>{condition.operator}</td>
              <td>{condition.value}</td>
            </tr>
          );
        }
      );

      return(
        <div key={index}>
          <Card>
            <CardHeader className="header-color">
              <i className="fa fa-times red-color cursor-pointer" onClick={
                ()=>{
                  confirmation(
                    ()=>{deleteBlackListRule(list.ID)},
                    ()=>{},
                    'Delete auto assign rule?',
                    'Are you sure you want to delete this rule? You will lose all conditions that associated with this rule.');
                }
              }></i> {list.name}
            </CardHeader>
            <CardBody>
              <Table className="table table-striped">
                <thead>
                  <tr>
                    <th width="35%">Description</th>
                    <th width="15%">Field</th>
                    <th width="25%">Operator</th>
                    <th width="25%">Value</th>
                  </tr>
                </thead>
                <tbody>
                  {conditions}
                </tbody>
              </Table>
            </CardBody>
          </Card>
          <br/>
        </div>
      );
    }
  );

  let targetClientShippingProfile;

  if(state.targetClientShippingProfile&&state.targetClientShippingProfile.labels){
    targetClientShippingProfile =
    <Card>
      <CardHeader className="header-color"><i className="fa fa-times red-color cursor-pointer" onClick={deleteShippingConfiguration}></i> Current configuration</CardHeader>
      <CardBody>
        <Row>
          <Col sm="4">
            <label>Ship to borrower</label>
          </Col>
          <Col sm="8">
            {state.targetClientShippingProfile.ship_to_borrower}
          </Col>
          <Col sm="4">
            <label>Borrower delay timer</label>
          </Col>
          <Col sm="8">
            {state.targetClientShippingProfile.borrower_delay_timer}
          </Col>
          <Col sm="4">
            <label>Appraiser E&O + License <i className="fa fa-question cursor-pointer link-color" id="appraiserEAndO"/></label>
            <UncontrolledTooltip className="mytooltip" delay={{ "show": 0, "hide": 0 }} placement="right" target={"appraiserEAndO"}>
            Select yes if the client wish to receive the appraiser's E&O when we ship the report.
            </UncontrolledTooltip>
          </Col>
          <Col sm="8">
            {state.targetClientShippingProfile.appraiser_e_and_o}
          </Col>
          <Col sm="4">
            <label>Email Report <i className="fa fa-question cursor-pointer link-color" id="emailReportHelp2"/></label>
            <UncontrolledTooltip className="mytooltip" delay={{ "show": 0, "hide": 0 }} placement="right" target={"emailReportHelp2"}>
              Select yes if the client wish to receive a separate email that has all documents attached within the email. This email will send to email recipient you specify PLUS LO + LP or any entity that is supposed to receive shipping notification.
            </UncontrolledTooltip>
          </Col>
          <Col sm="8">
            {state.targetClientShippingProfile.email_report}
          </Col>
          <Col sm="4">
            <label>Email Report Recipient</label>
          </Col>
          <Col sm="8">
            {state.targetClientShippingProfile.email_report_recipient}
          </Col>
          <Col sm="4">
            <label>Shipping targets</label>
          </Col>
          <Col sm="8">
            {
              state.targetClientShippingProfile.labels.map(
                (label,index)=>{
                  return(
                    <div key={index} className="display-inline">
                      <div className="display-inline">
                        {label.label}
                      </div>&nbsp;&nbsp;
                    </div>
                  );
                }
              )
            }
          </Col>
        </Row>
      </CardBody>
    </Card>
  }

  let otherLabel;

  if(state.targetLabel==='Other')
    otherLabel = <div>
      <Input type="text" placeholder="Title" value={state.otherLabel} onChange={(e)=>setState({otherLabel:e.target.value})}/>
    </div>

  return <div>
    <div className="my-well">
      <Row>
        <Col sm="6">
          <div className="page-title">
            <i className="fa fa-reorder"></i>&nbsp;Auto Shipping
          </div>
          <NavLink to="/system-setting">Back to settings</NavLink>
        </Col>
        <Col sm="6">

        </Col>
      </Row>

      <Card>
        <CardHeader className="header-color">
          <i className="fa fa-reorder"></i>&nbsp;Auto Shipping configuration
        </CardHeader>
        <CardBody>
          <Modal className="my-modal" isOpen={state.shippingDropDownOpen} toggle={toggleShippingDropDown} >
            <ModalHeader hidden={true} toggle={toggleShippingDropDown}></ModalHeader>
            <ModalBody>
              <center>
                <h5><i className="fa fa-user"></i> Modify auto shipping configuration</h5>
              </center>
              <br/>
              <Row>
                <Col sm="4">
                  <label>Ship to borrower</label>
                </Col>
                <Col sm="8">
                  <MySelect
                    modal={true}
                    type="select"
                    value={state.shipToBorrower}
                    onChange={(v)=>{setState({shipToBorrower:v})}}
                    options={[{label:"No",value:"no"},{label:"Yes",value:"yes"}]}
                  />
                </Col>
              </Row>
              <br/>
              <Row>
                <Col sm="4">
                  <label>Borrower delay timer (hours)</label>
                </Col>
                <Col sm="8">
                  <Input type="text" value={state.borrowerDelayTimer} onChange={(e)=>setState({borrowerDelayTimer:e.target.value})}/>
                </Col>
              </Row>
              <br/>
              <Row>
                <Col sm="4">
                  <label>Appraiser E&O <i className="fa fa-question cursor-pointer link-color" id="appraiserEAndO2"/></label>
                  <UncontrolledTooltip className="mytooltip" delay={{ "show": 0, "hide": 0 }} placement="right" target={"appraiserEAndO2"}>
                    Select yes if the client wish to receive the appraiser's E&O when we ship the report.
                  </UncontrolledTooltip>
                </Col>
                <Col sm="8">
                  <MySelect 
                     modal={true}
                    type="select"
                    value={state.appraiserEAndO}
                    onChange={(v)=>{setState({appraiserEAndO:v})}}
                    options={[{label:"No",value:"no"},{label:"Yes",value:"yes"}]}
                  />
                </Col>
              </Row>
              <br/>
              <Row>
                <Col sm="4">
                  <label>Email Report <i className="fa fa-question cursor-pointer link-color" id="emailReportHelp1"/></label>
                  <UncontrolledTooltip className="mytooltip" delay={{ "show": 0, "hide": 0 }} placement="right" target={"emailReportHelp1"}>
                    Select yes if the client wish to receive a separate email that has all documents attached within the email. This email will send to email recipient you specify PLUS LO + LP or any entity that is supposed to receive shipping notification.
                  </UncontrolledTooltip>
                </Col>
                <Col sm="8">
                  <MySelect 
                     modal={true}
                    type="select"
                    value={state.emailReport}
                    onChange={(v)=>{setState({emailReport:v})}}
                    options={[{label:"No",value:"no"},{label:"Yes",value:"yes"}]}
                  />
                </Col>
              </Row>
              <br/>
              <Row>
                <Col sm="4">
                  <label>Email Recipient</label>
                </Col>
                <Col sm="8">
                  <Input type="text" value={state.emailRecipient} onChange={(e)=>setState({emailRecipient:e.target.value})} />
                </Col>
              </Row>
              <br/>
              <Row>
                <Col sm="4">
                  <label>Shipping targets</label>
                </Col>
                <Col sm="6">
                  <MySelect
                    modal={true}
                    type="select"
                    value={state.targetLabel}
                    onChange={(v)=>{setState({targetLabel:v})}}
                    options={[{label:"Please select the target shipping label",value:""}].concat(
                      state.entities.map((entity)=>{
                        return {label:entity.entity, value:entity.entity};
                       })
                    )}
                  />

                  {otherLabel}
                </Col>
                <Col sm="2" className="align-right">
                  <Button color="warning" onClick={addShippingLabel}>Add</Button>
                </Col>
              </Row>
              <div className="my-divider"></div>
              {
                state.shippingLabels.map(
                  (label,index)=>{
                    return(
                      <div key={index} className="display-inline">
                        <div className="display-inline cursor-pointer" onClick={(e)=>removeLabel(label)}>
                          <i className="fa fa-times red-color"></i> {label}
                        </div>&nbsp;&nbsp;
                      </div>
                    );
                  }
                )
              }
              <br/>
              <center>
                <Button color="warning" onClick={updateShippingConfiguration}>Update</Button>&nbsp;&nbsp;
                <Button color="info" onClick={toggleShippingDropDown}>Close</Button>
              </center>
            </ModalBody>
          </Modal>
          <Row>
            <Col sm="4">
              <label>Client</label>
            </Col>
            <Col sm="6">
              <MySelect
                type="select"
                value={state.client}
                onChange={(v)=>{setState({client:v});loadShippingProfile(v)}}
                options={[{label:"Please select the client",value:""}].concat(
                  state.clients.map((client)=>{
                    return {label:client.company, value:client.ID};
                   })
                )}
              />
            </Col>
            <Col sm="2" className="align-right">
              <NavLink to="#" onClick={toggleShippingDropDown}>Modify shipping configuration</NavLink>
            </Col>
          </Row>

          <div className="my-divider"></div>
          {targetClientShippingProfile}
        </CardBody>
      </Card>
      <br/>

      <Card>
        <CardHeader className="header-color">
          <i className="fa fa-reorder"></i>&nbsp;Auto Review Dynamic Rule
        </CardHeader>
        <CardBody>
          {blackLists}
          <br/>
          <div className="my-divider"></div>

          <i className="fa fa-plus green-color"></i> Add dynamic rules<br/>
          <label>Rule name</label>
          <Row>
            <Col sm="4">
              <Input type="text" value={state.newBlackListName} onChange={(e)=>setState({newBlackListName:e.target.value})} />
            </Col>
            <Col sm="8">
              <Button color="warning" onClick={addNewBlackList}>Create rule</Button>
            </Col>
          </Row>
          <div className="my-divider"></div>
          <br/>

          <form onSubmit={(e)=>{e.preventDefault();addNewBlackListCondition()}}>
            <Row>
              <Col sm="6">
                <label><font className="red-color">*</font>Associated rule</label>
                <MySelect
                  type="select"
                  required={true}
                  value={state.newBlackListConditionRuleSelected}
                  onChange={(v)=>{setState({newBlackListConditionRuleSelected:v})}}
                  options={[{label:"Select a rule",value:""}].concat(
                    state.blackList.map((list)=>{
                      return {label:list.name, value:list.ID};
                     })
                  )}
                />
              </Col>
              <Col sm="6">
                <label>Description</label>
                <Input type="text" onChange={(e)=>setState({newBlackListConditionDescription:e.target.value})} value={state.newBlackListConditionDescription}></Input>
              </Col>
            </Row>

            <Row>
              <Col sm="4">
                <label><font className="red-color">*</font>Field name</label>
                <MySelect
                  type="select"
                  required={true}
                  value={state.newBlackListConditionField}
                  onChange={(v)=>{setState({newBlackListConditionField:v});blackListFieldOnChange(v);}}
                  options={[{label:"Select a field",value:""}].concat(
                    state.fieldsEnum.map((field)=>{
                      return {label:field.label, value:field.field};
                     })
                  )}
                />
              </Col>
              <Col sm="4">
                <label><font className="red-color">*</font>Operator</label>
                <MySelect
                  type="select"
                  required={true}
                  value={state.newBlackListConditionOperator}
                  onChange={(v)=>{setState({newBlackListConditionOperator:v})}}
                  options={[{label:"Equal to",value:"="},{label:"Not equal to",value:"!="},{label:"Like",value:"like"}]}
                />
              </Col>
              <Col sm="4">
                <label><font className="red-color">*</font>Value</label>
                {valueWidget}
              </Col>
            </Row>
            <br/>
            <div className="align-right"><Button color="warning">Create condition</Button></div>
          </form>
        </CardBody>
      </Card>
    </div>
  </div>;
}

export default AutoShippingConfiguration;
