//Author Sooyoung Kim
//Date July 21, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, deleteAPICallGenerator, callBackGenerator, formatNumber,showMessage, confirmation, sliceFromArray} from '../../util/util';
import {Button,Modal, ModalHeader, ModalBody} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import MySelect from '../util/my-select';
import MyDropzone from './../util/my-dropzone';
import FileSaver from 'file-saver';

//initialize the state
const initialState = {
  appraiserFiles:[],
  toUploadFiles:[],
  bigFiles:[],
  resizeFilePopUp:false,
  modal: false,
  errorMessage:'',
  fileTypes:[]
};

//reducer function that perform state update
const reducer = getReducer();


const AppraiserFile  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getAppraiserFiles();
    getFileTypes();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  //toggle function to show and hide the file upload model
  const toggle = () => {
    setState({
      modal: !state.modal
    });
  }

  const toggleResizeFile = () => {
    if(state.resizeFilePopUp)
      setState({bigFiles:[]});
    setState({resizeFilePopUp: !state.resizeFilePopUp});
  }

  //function trigger for dropzone react.
  //this function contians two list of files, the accepted and rejected file per the configuration
  const onDrop = (acceptedFiles, rejectedFiles) => {
    let existingFiles = state.toUploadFiles.slice();
    let bigFiles = state.bigFiles;

    for(let i=0;i<acceptedFiles.length;i++){
      let file = acceptedFiles[i];
      file.status = 'Pending';
      file.fileType = '';

      let isBigFile = false;
      if(file.size > 40000000){
        isBigFile = true;
        bigFiles.push(file.name);
      }

      let duplicate = false;
      for(let j=0;j<state.toUploadFiles.length;j++){
        if(state.toUploadFiles[j].name===acceptedFiles[i].name){
          duplicate = true;
          setState({errorMessage:'Duplicate file name "'+acceptedFiles[i].name+'"'})
        }
      }
      if(!duplicate && !isBigFile)
        existingFiles.push(file);
    }
    if(bigFiles.length){
      setState({resizeFilePopUp:true, bigFiles:bigFiles});
    }
    setState({toUploadFiles: existingFiles});
  }

  //remove a specific file from the toUpload list.
  const removeToUploadFile = (preview) => {
    let toRemoveIndex = -1;
    for(let i=0;i<state.toUploadFiles.length;i++){
      if(state.toUploadFiles[i].preview===preview){
        toRemoveIndex = i;
        break;
      }
    }

    if(toRemoveIndex!==-1){
      let newFiles = state.toUploadFiles.slice();
      newFiles.splice(toRemoveIndex,1);

      setState({toUploadFiles:newFiles});
    }
  }

  //format the size to use appropiate unit KB, MB and B and round up to only 1 decimal
  const formatFileSize = (size) => {
    let intSize = parseInt(size,10);

    if(intSize>=1000000.00)
      return formatNumber(Math.round((intSize*10/1000000))/10)+' MB';
    else if(intSize>=1000)
      return formatNumber(Math.round((intSize*10/1000))/10)+' KB';
    else
      return formatNumber(intSize)+' B';

  }

  //constructing a new file object
  const deepCopyFileObject = (file) => {
    let newFile = new File([file],file.name);
    newFile.preview = file.preview;
    newFile.fileType = file.fileType;
    newFile.status = file.status;

    return newFile
  }

  //on change function when user change the file type drop down in upload file pop up
  const onFileTypeChange = (name, fileType) => {
    let newToUploadFiles = [];
    console.log(state.toUploadFiles)
    for(let i=0;i<state.toUploadFiles.length;i++){
      let newFile = deepCopyFileObject(state.toUploadFiles[i]);
      if(state.toUploadFiles[i].name===name){
        newFile.fileType = fileType;
      }
      newToUploadFiles.push(newFile);
    }
    console.log(newToUploadFiles)
    setState({toUploadFiles:newToUploadFiles});
  }

  //API call
  //download the file when user click on the file name using blob
  const downloadFile = (name) => {
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let byteCharacters = atob(response.data.data);
  			let byteNumbers = new Array(byteCharacters.length);
  			for (let i = 0; i < byteCharacters.length; i++) {
  				byteNumbers[i] = byteCharacters.charCodeAt(i);
  			}
  			let byteArray = new Uint8Array(byteNumbers);
  			let data = new Blob([byteArray]);
  			FileSaver.saveAs(data, name);
      }
    };

    let parameters = [
      {
        field:'parentFk',
        value:props.appraiserFk
      },
      {
        field:'name',
        value:name
      },
      {
        field:'directory',
        value:'Appraisers'
      }
    ];

    httpPost('appraiser/file/download', parameters, '', 'Oops, something went wrong and could not download the file "'+name+'". Please try again later.', callBack);
  }

  //delete file
  const deleteFile = (ID) => {
    let list = sliceFromArray(state.appraiserFiles,'ID', ID);
    let callBack = apiCallBack([{state:'appraiserFiles', value:list}]);
    httpDelete('appraiser/file/'+ID,'','Oops, something went wrong and could not delete the file, please try again later.', callBack);
  }

  //function that upload all toUploadFiles to the server by calling asyncPost
  const uploadAll = () => {
    let preCheck = true;
    let errorMessage = '';
    setState({errorMessage:''});

    for(let i=0;i<state.toUploadFiles.length;i++){
      if(!state.toUploadFiles[i].fileType||state.toUploadFiles[i].fileType===''){
        preCheck = false;
        errorMessage = '*Please select the file type for the file "'+state.toUploadFiles[i].name+'".';
        break;
      }
    }

    //only proceed when no error
    if(preCheck){
      for(let i=0;i<state.toUploadFiles.length;i++){
        const reader = new FileReader();
        reader.onload = () => {
          const fileAsBinaryString = reader.result;
          let base64 = btoa(fileAsBinaryString);
          let newToUploadFiles = [];

          //deep copy the entire file object array
          for(let j=0;j<state.toUploadFiles.length;j++){
            let newFile = deepCopyFileObject(state.toUploadFiles[j]);
            if(newFile.name===state.toUploadFiles[i].name)
              newFile.status='Done';

            newToUploadFiles.push(newFile);
          }

          let callBack = apiCallBack([{state:'toUploadFiles', value:newToUploadFiles}, {state:'appraiserFiles', arraykey:'data', targetArray:state.appraiserFiles}]);

          let parameters = [
            {
              field:'appraiserFk',
              value:props.appraiserFk
            },
            {
              field:'file_type',
              value:state.toUploadFiles[i].fileType
            },
            {
              field:'upload_type',
              value:'Appraiser'
            },
            {
              field:'name',
              value:state.toUploadFiles[i].name
            }
            ,{
              field:'file_encoded',
              value:base64
            }
          ];

          httpPost('appraiser/upload', parameters, 'File "'+state.toUploadFiles[i].name+'" uploaded successfully.', 'Oops, something went wrong and could not upload the file "'+state.toUploadFiles[i].name+'". Please try again later.', callBack);
        };
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => {
          showMessage('error','File upload failed, please try again later.');
        };

        reader.readAsBinaryString(state.toUploadFiles[i]);
      }
    }
    else{
      setState({errorMessage:errorMessage});
    }
  }

  //retrieve a list of appraisal files that ties to a specific appraisal
  const getAppraiserFiles = () => {
    let callBack = apiCallBack([{state:'appraiserFiles', key:'data'}]);
	  httpGet('appraiser/file/get/'+props.appraiserFk, '', 'Oops, something went wrong and could not load appraisal files for this appraisal. Please try again later.', callBack);
  }

  //retrieve a list of file types for the file type drop down at the upload pop up
  const getFileTypes = () => {
    let callBack = apiCallBack([{state:'fileTypes', key:'data'}]);
	  httpGet('appraiser/file/type/get', '', 'Oops, something went wrong and could not load appraisal file types. Please try again later.', callBack);
  }


  //render
  let appraiserFiles;
  let toUploadFiles;

  if(state.appraiserFiles.length>0){
    appraiserFiles = state.appraiserFiles.map(
      (file, outterIndex)=>{

        return(
          <div key={outterIndex}>
            <div className="file-container">
                <div className="font-bold">
                <div className="display-inline cursor-pointer" ><i className="fa fa-trash red-color" onClick={
                  (e)=>{
                    confirmation(
                      ()=>{deleteFile(file.ID)},
                      ()=>{},
                      'Delete file?',
                      'Are you sure you want to delete this file?')
                  }
                }></i></div><b>&nbsp;{file.file_type}</b>
                <br/><div className="cursor-pointer display-inline link-color" onClick={()=>downloadFile(file.name)}><b>{file.name}</b></div>
              </div>
              <font size="1"><div className="no-margin no-padding font-bold margin-top-extra"><i className="fa fa-clock-o"></i>&nbsp;{file.datetime_created} - {file.uploader_name}</div></font>
            </div>
          </div>
        );
      }
    );
  }

  if(state.toUploadFiles.length>0){
    toUploadFiles = state.toUploadFiles.map(
      (file,index)=>{
        return(
          <tr key={index}>
            <td>{file.name}</td>
            <td>
              <MySelect
                type="select"
                modal={true}
                value={file.fileType}
                selectIsClearable={true}
                onChange={(v)=>{onFileTypeChange(file.name, v)}}
                options={state.fileTypes.map((fileType)=>{
                  return {label:fileType.name, value:fileType.name};
                })}
              />
            </td>
            <td>{formatFileSize(file.size)}</td>
            <td><center>{file.status}</center></td>
            <td><center><i className="fa fa-times red-color cursor-pointer" onClick={()=>removeToUploadFile(file.preview)}></i></center></td>
          </tr>
        );
      }
    );
  }

  let bigFileList = state.bigFiles.join(', ');

  return <div>
    <div className="file-panel large-scroll-container">
      {appraiserFiles}
    </div>
    <div className="my-diviver"></div>
    <div className="align-right">
      <br/>
      <Button color="warning" className="cursor-pointer" onClick={toggle}><i className="fa fa-plus"></i> Upload File</Button>
      <Modal className="my-modal" isOpen={state.modal} toggle={toggle} >
        <ModalHeader hidden={true} toggle={toggle}></ModalHeader>
        <ModalBody>
          <center>
            <h5><i className="fa fa-comments"></i> New file</h5>
          </center>
          <br/>

          <div>
            <MyDropzone onDrop={onDrop}/>
            <br/>
            <div className="my-divider"></div>
            <div className="small-scroll-container red-color">
              <b>{state.errorMessage}</b>
            </div>
            <div>
              <table className="table file-list-table" cellSpacing="0" cellPadding="0">
                <thead>
                  <tr>
                    <th width="30%">Name</th>
                    <th width="35%">File Type</th>
                    <th width="15%">Size</th>
                    <th width="10%"><center>Status</center></th>
                    <th width="10%">Control</th>
                  </tr>
                </thead>
                <tbody>
                  {toUploadFiles}
                </tbody>
              </table>
            </div>
            <br/>

            <center>
              <Button color="warning" onClick={uploadAll}><i className="fa fa-upload"></i> Upload</Button>&nbsp;
              <Button color="info" onClick={toggle}>Close</Button>
            </center>
          </div>
        </ModalBody>
      </Modal>
      <Modal className="my-modal" isOpen={state.resizeFilePopUp} toggle={toggleResizeFile} >
        <ModalHeader hidden={true} toggle={toggleResizeFile}></ModalHeader>
        <ModalBody>
          <center>
            <h5><i className="fa fa-exclamation-triangle"></i> File Too Large</h5>
          </center>

          <b>Your file is larger than <font color="red">40MB</font>. Please resize your following file(s):</b>
          <div>{bigFileList}</div>

          <br/><br/>
          <center>
            <Button color="warning" onClick={toggleResizeFile}>Ok</Button>
          </center>
        </ModalBody>
      </Modal>
    </div>
  </div>;
}


export default AppraiserFile;
