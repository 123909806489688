//Author Sooyoung Kim
//Date June 30, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, formatDateTime, getFromTo} from '../../../util/util';
import {Card, CardHeader, CardBody} from 'reactstrap';
import MyReactTable from '../../util/my-react-table';
import moment from 'moment';
import {NavLink} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';
import ReportFilter from '../report-filter';

//initialize the state
const initialState = {
  report:[],
  from:getFromTo('end'),
  to:getFromTo('end'),
  field:'',
};

//reducer function that perform state update
const reducer = getReducer();


const AppraiserUpdateLogReport  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getReportDetails();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //API call
  const getReportDetails = () => {
    let callBack = apiCallBack([{state:'report', key:'data'}]);
    httpGet('report/appraiser/update/log/get/from='+state.from+'&to='+state.to+'&field='+state.field, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  //render
  let from, to;
  if(state.from!=='')
    from = moment(state.from);
  if(state.to!=='')
    to = moment(state.to);

  const columns = [
    {
      id: 'appraiser',
      Header: 'Appraiser',
      accessor: d => d.name,
      Cell: props => <NavLink target="_blank" to={"/appraiser/"+props.row.original.ID}>{props.row.original.name}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'author',
      Header: 'Author',
      accessor: d => d.data,
      Cell: props=> <div>
        {
          props.row.original.data.map(
            (row, index)=>{
              return <div key={index}>
                {row.author}
              </div>
            }
          )
        }
      </div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'update',
      Header: 'Updates',
      accessor: d => d.data,
      Cell: props=> <div>
        {
          props.row.original.data.map(
            (row, index)=>{
              return <div key={index}>
                {row.field_label}
              </div>
            }
          )
        }
      </div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'details',
      Header: 'Details',
      accessor: d => d.data,
      width:500,
      Cell: props=> <div>
        {
          props.row.original.data.map(
            (row, index)=>{
              return <div key={index}>
                {row.comment}
              </div>
            }
          )
        }
      </div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'date',
      Header: 'Date',
      accessor: d => d.data,
      Cell: props=> <div>
        {
          props.row.original.data.map(
            (row, index)=>{
              return <div key={index}>
                {formatDateTime(row.datetime_created)}
              </div>
            }
          )
        }
      </div>,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  let reportFiltersConfig = [
    {id:'from',value:from, updateFunc:(v)=>setState({from:v}), width:'3'},
    {id:'to',value:to, updateFunc:(v)=>setState({to:v}), width:'3'},
    {id:'customSelect',value:state.field, updateFunc:(v)=>setState({field:v}), width:'3', label:'Filter', clearable:true, className:"", options:[
      {label:"All",value:""},{label:"Address Only",value:"street,city,county,state,zip,billing_street,billing_city,billing_county,billing_state,billing_zip"},{label:"File Upload Only",value:"File Upload - W9,File Upload - Resume,File Upload - License,File Upload - 1099,File Upload - Misc,File Upload - EAmpersandO"},{label:"New Register Only",value:"new profile"},{label:"New Approved Only",value:"new approved"},{label:"Status Change Only",value:"status"}
    ]},
    {id:'button',value:'Submit', updateFunc:getReportDetails, width:'3', className:"align-right", color:"warning"},
  ];

  return <div>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Appraiser Update Log Report
      </CardHeader>
      <CardBody>
        <ReportFilter {...props} configs={reportFiltersConfig}/>
        <div className="my-divider">&nbsp;</div>
        <div className="large-scroll-container">
          <MyReactTable columns={columns} data={state.report} className="table table-striped"/>
        </div>
      </CardBody>
    </Card>
  </div>;
}


export default AppraiserUpdateLogReport;
