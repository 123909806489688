//Author Sooyoung Kim
//Date July 21, 2023
import React from 'react';
import {Button} from 'reactstrap';
import Dropzone from 'react-dropzone'

const MyDropzone  = (props)=>{
  const handleButtonClick = (event) => {
    event.preventDefault(); // Prevent form submission
    props.onDrop(event);
  };

  //render
  let uploadButton;
  if(props.noIcon){
    uploadButton = <center>Choose files to upload</center>;
  }else{
    uploadButton = <center>
    <font style={{fontSize:'30px'}}>
      <i className="fa fa-cloud-upload link-color"></i>
    </font>
    <br/>
    <Button color="success" onClick={handleButtonClick}>Choose files to upload</Button>
    <br/>
    <div>
      <i><b>OR drag and drop file here.</b></i>
    </div>
  </center>;
  }
  return <div>
    {!props.hidden && <Dropzone onDrop={props.onDrop}>
    {
      ({getRootProps, getInputProps}) => (
        <div
          {...getRootProps({
            className: 'my-dropzone',
          })}
        >
          <input {...getInputProps()} />
          {uploadButton}
        </div>
      )
    }

    </Dropzone>}
  </div>;
}


export default MyDropzone;
