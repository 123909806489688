//Author Sooyoung Kim
//Date June 28, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, getFromTo} from '../../../util/util';
import {Button, Card, CardHeader, CardBody, Modal, ModalBody, ModalHeader, UncontrolledTooltip, NavItem, Nav, TabContent, TabPane, NavLink as NavLinkL} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import MyReactTable from '../../util/my-react-table';
import ReactStars from "react-stars";
import ReportFilter from '../report-filter';
import moment from 'moment';
import {NavLink} from 'react-router-dom';

//initialize the state
const initialState = {
  reportDetails:[],
  score:'',
  from:getFromTo('start', 1),
  to:getFromTo('end'),
  client:'',
  clients:[],
  state:'',
  states:'',
  author:'',
  appraiser:'',
  entity:'',
  opsStats:{},
  appraiserStats:{},
  activeTab:'1',
  selectedItem:-1,
  selectedDetails:[],
  showDetailsDropDownOpen:false
};

//reducer function that perform state update
const reducer = getReducer();


const QualityRatingReport  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getReportDetails();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const toggleShowDetailsDropDown = ()=>{
    setState({showDetailsDropDownOpen:!state.showDetailsDropDownOpen});
  }

  const selectDetails = (selectedDetails)=>{
    setState({selectedDetails:selectedDetails, showDetailsDropDownOpen:true});
  }

  const setActiveTabToggle = (tab)=>{
    setState({activeTab:tab});
  }

  //API call
  const getReportDetails = ()=>{
    let callBack = apiCallBack([{state:'reportDetails', key:'data.report'},{state:'opsStats', key:'data.ops_stats'},{state:'appraiserStats', key:'data.appraiser_stats'}]);
    httpGet('report/qualityRating/from='+state.from+'&to='+state.to+'&author='+state.author+'&appraiser='+state.appraiser+'&state='+state.state+'&client='+state.client+'&entity='+state.entity, '',  'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  //render
  let from, to;
  if(state.from!=='')
    from = moment(state.from);
  if(state.to!=='')
    to = moment(state.to);

  let opsStats=[];
  let appraiserStats=[];

  if(Object.keys(state.opsStats).length>0){
    Object.keys(state.opsStats).map((key, index)=>{

      let tmp = {
        name:key,
        "1":state.opsStats[key]["1"],
        "2":state.opsStats[key]["2"],
        "3":state.opsStats[key]["3"],
        count:state.opsStats[key].count,
      };

      opsStats.push(tmp);
      return null;
    })
  }

  if(Object.keys(state.appraiserStats).length>0){
    Object.keys(state.appraiserStats).map((key, index)=>{

      let tmp = {
        name:state.appraiserStats[key].name,
        "1":state.appraiserStats[key]["1"],
        "2":state.appraiserStats[key]["2"],
        "3":state.appraiserStats[key]["3"],
        count:state.appraiserStats[key].count
      };

      appraiserStats.push(tmp);
      return null;
    })
  }

  const columns1 = [
    {
      id: 'ops',
      Header: 'Ops',
      accessor: d => d.name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'lessThanEqualOne',
      Header: '≤ ★',
      accessor: d => d["1"].length,
      Cell: props => {
        return <div>
          <NavLink to="#" onClick={(e)=>selectDetails(props.row.original["1"])}>{props.row.original["1"].length}</NavLink>
        </div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'lessThanEqualTwo',
      Header: '≤ ★★',
      accessor: d => d["2"].length,
      Cell: props => {
        return <div>
          <NavLink to="#" onClick={(e)=>selectDetails(props.row.original["2"])}>{props.row.original["2"].length}</NavLink>
        </div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'lessThanEqualThree',
      Header: '≤ ★★★',
      accessor: d => d["3"].length,
      Cell: props => {
        return <div>
          <NavLink to="#" onClick={(e)=>selectDetails(props.row.original["3"])}>{props.row.original["3"].length}</NavLink>
        </div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'count',
      Header: 'Count',
      accessor: d => d.count,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  const columns2 = [
    {
      id: 'appraiser',
      Header: 'Appraiser',
      accessor: d => d.name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'lessThanEqualOne',
      Header: '≤ ★',
      accessor: d => d["1"].length,
      Cell: props => {
        return <div>
          <NavLink to="#" onClick={(e)=>selectDetails(props.row.original["1"])}>{props.row.original["1"].length}</NavLink>
        </div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'lessThanEqualTwo',
      Header: '≤ ★★',
      accessor: d => d["2"].length,
      Cell: props => {
        return <div>
          <NavLink to="#" onClick={(e)=>selectDetails(props.row.original["2"])}>{props.row.original["2"].length}</NavLink>
        </div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'lessThanEqualThree',
      Header: '≤ ★★★',
      accessor: d => d["3"].length,
      Cell: props => {
        return <div>
          <NavLink to="#" onClick={(e)=>selectDetails(props.row.original["3"])}>{props.row.original["3"].length}</NavLink>
        </div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'count',
      Header: 'Count',
      accessor: d => d.count,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  const columns3 = [
    {
      id: 'reference_num',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'score',
      Header: 'Rating',
      accessor: d => d.score,
      Cell: props => <div id={"recorded_issue3"+props.row.original.issueID}>
        <ReactStars
          onChange={()=>{}}
          value={props.row.original.score}
          count={3}
          edit={false}
          half={true}
          size={20}
        />
        <UncontrolledTooltip delay={{ "show": 0, "hide": 0 }} placement="top" target={"recorded_issue3"+props.row.original.issueID}>
          {props.row.original.score+'/3.00'}
        </UncontrolledTooltip>
      </div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'appraiser',
      Header: 'Appraiser',
      accessor: d => d.appraiser,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'comment',
      Header: 'Comment',
      accessor: d => d.comment,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'author',
      Header: 'Author',
      accessor: d => d.record_user,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'date',
      Header: 'Date',
      accessor: d => d.datetime_created,
      headerStyle: {
        textAlign:'left'
      }
    },
  ];

  const columns4 = [
    {
      id: 'reference_num',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'score',
      Header: 'Rating',
      accessor: d => d.score,
      Cell: props => <div id={"recorded_issue4"+props.row.original.issueID}>
        <ReactStars
          onChange={()=>{}}
          value={props.row.original.score}
          count={3}
          edit={false}
          half={true}
          size={20}
        />
        <UncontrolledTooltip delay={{ "show": 0, "hide": 0 }} placement="top" target={"recorded_issue4"+props.row.original.issueID}>
          {props.row.original.score+'/3.00'}
        </UncontrolledTooltip>
      </div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'appraiser',
      Header: 'Appraiser',
      accessor: d => d.appraiser,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'comment',
      Header: 'Comment',
      accessor: d => d.comment,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'author',
      Header: 'Author',
      accessor: d => d.record_user,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'date',
      Header: 'Date',
      accessor: d => d.datetime_created,
      headerStyle: {
        textAlign:'left'
      }
    },
  ];

  let reportFiltersConfig = [
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'3'},
    {id:'from',value:from, updateFunc:(v)=>setState({from:v}), width:'3'},
    {id:'to',value:to, updateFunc:(v)=>setState({to:v}), width:'3'},
    {id:'custom',value:state.appraiser, updateFunc:(v)=>setState({appraiser:v}), width:'3', label:'Appraiser', placeHolder:"Name"},
    {id:'custom',value:state.author, updateFunc:(v)=>setState({author:v}), width:'3', label:'Author', placeHolder:""},
    {id:'entity',value:state.entity, updateFunc:(v)=>setState({entity:v}), width:'3'},
    {id:'button',value:'Submit', updateFunc:getReportDetails, width:'6', className:"align-right", color:"warning"},
  ];
  
  return <div>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Quality Rating Report
      </CardHeader>
      <CardBody>
        <ReportFilter {...props} configs={reportFiltersConfig}/>
        <br/>
        <div className="my-divider">&nbsp;</div>
        <Nav tabs>
          <NavItem>
            <NavLinkL
              className={"nav-link "+(state.activeTab === '1'?"active":"inactive" )}
              onClick={() => { setActiveTabToggle('1'); }}
              to="#"
            >
              Ratings
            </NavLinkL>
          </NavItem>
          <NavItem>
            <NavLinkL
              className={"nav-link "+(state.activeTab === '2'?"active":"inactive" ) }
              onClick={() => { setActiveTabToggle('2'); }}
              to="#"
            >
              Ops Stats
            </NavLinkL>
          </NavItem>
          <NavItem>
            <NavLinkL
              className={"nav-link "+(state.activeTab === '3'?"active":"inactive" ) }
              onClick={() => { setActiveTabToggle('3'); }}
              to="#"
            >
              Appraiser Stats
            </NavLinkL>
          </NavItem>
        </Nav>

        <TabContent activeTab={state.activeTab}>
          <TabPane tabId="1">
            <MyReactTable columns={columns3} data={state.reportDetails} className="table table-striped"/>
          </TabPane>
          <TabPane tabId="2">
            <MyReactTable columns={columns1} data={opsStats} className="table table-striped"/>
          </TabPane>
          <TabPane tabId="3">
            <MyReactTable columns={columns2} data={appraiserStats} className="table table-striped"/>
          </TabPane>
        </TabContent>
      </CardBody>
    </Card>

    <Modal className="my-modal-wide" isOpen={state.showDetailsDropDownOpen} toggle={toggleShowDetailsDropDown} >
      <ModalHeader hidden={true} toggle={toggleShowDetailsDropDown}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-user"></i> Report Details</h5>
        </center>
        <br/>
        <div className="large-scroll-container">
          <MyReactTable columns={columns4} data={state.selectedDetails} className="table table-striped"/>
        </div>
        <br/>
        <center>
          <Button color="info" onClick={toggleShowDetailsDropDown}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
  </div>;
}


export default QualityRatingReport;
