//Author June Leow
//Date 04/20/2023
import {Input, Row, Col} from 'reactstrap';
import React, {useEffect } from 'react';
import './my-react-table.css'

import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table'


const MyReactTable  = (props)=>{
  let defaultData = [];
  let columns = [];
  let container = {};

  for(let i=0;i<props.columns.length;i++){

    let config = {
      id:props.columns[i].id,
      header: props.columns[i].Header,
      accessorFn:props.columns[i].accessor,
      size:props.columns[i].width,
      minSize:props.columns[i].minWidth,
      maxSize:props.columns[i].maxWidth,
    };

    if(props.columns[i].Cell)
      config['cell'] = props.columns[i].Cell;
    columns.push(
      config
    );
  }

  if(props.data)
    defaultData = props.data;

  if(props.container)
    container = props.container;

  const [sorting, setSorting] = React.useState([]);
  const [, setRowSelection] = React.useState({});
  const [copyPinnedRows, ] = React.useState(false)
  const [columnResizeMode, ] = React.useState('onChange');
  const [columnResizeDirection, ] = React.useState('ltr');

  const [rowPinning, setRowPinning] = React.useState({
    top: [],
    bottom: [],
  })
  const [expanded, ] = React.useState({});

  let table = {
    data:defaultData,
    columns:columns,
    state: {
      sorting,
      expanded,
      rowPinning,
    },
    getSubRows: row => row.subRows,
    enableRowSelection: true,
    enableResizing: true,
    
    getRowCanExpand:props.getRowCanExpand,
    renderSubComponent:props.renderSubComponent,
    columnResizeMode,
    columnResizeDirection,
    onRowSelectionChange: setRowSelection,
    onRowPinningChange: setRowPinning,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  };

  //run only once when component is loaded
  useEffect(()=>{

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const getRowClass = (row) => {
    if (row.original.vetted) {
      return 'highlighted-vet';
    }else if(row.original.quoted) {
      return 'highlighted-bid';
    }

    return '';
  };

  let pagination;

  if(props.pagination){
    table['getPaginationRowModel'] = getPaginationRowModel();
  }


  table = useReactTable(table);


  if(props.pagination){
    pagination = <Row>
      <Col sm="6">
        Page
        <strong>
          {table.getState().pagination.pageIndex + 1} of{' '}
          {table.getPageCount()}
        </strong>
        &nbsp;&nbsp;
        <button
          className="border rounded p-1"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          <i className="fa fa-angles-left"/>
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <i className="fa fa-angle-left"/>
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          <i className="fa fa-angle-right"/>
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          <i className="fa fa-angles-right"/>
        </button>
      </Col>
      <Col sm="6" className="align-right">
        Go to page: &nbsp;
        <div className="display-inline">
          <Input
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              table.setPageIndex(page)
            }}
            className="border p-1 rounded w-16"
          />
        </div>
        &nbsp;
        <div className="display-inline">
          <Input type="select"
            value={table.getState().pagination.pageSize}
            onChange={e => {
              table.setPageSize(Number(e.target.value))
            }}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Input>
        </div>
      </Col>
    </Row>;
  }

  //non API call but simpyl manage state

  //API call

  //render 
  return <div style={container}>
      <table className={"table "+(props.className?props.className:"")} cellSpacing="0" style={{borderCollapse: 'separate', border: '1px solid lightgray',}}>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {
                headerGroup.headers.map(header => {
                  return (
                    <th key={header.id} colSpan={header.colSpan} style={{borderTop:'1px solid #d2d2d2', width: header.getSize()}}>
                      {
                        header.isPlaceholder ? null : (
                          <div
                            {
                              ...{
                                className: header.column.getCanSort()? 'cursor-pointer select-none': '',
                                onClick: header.column.getToggleSortingHandler(),
                              }
                            }
                          >
                            <div className="display-inline link-color">
                            {
                              header.column.getIsSorted()==='asc'? <i className="fa fa-arrow-up-short-wide"/>:header.column.getIsSorted()==='desc'? <i className="fa fa-arrow-down-wide-short"/>:''
                            }
                            &nbsp;
                            </div>
                            {
                              flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )
                            }
                          </div>
                        )
                      }
                      <div
                        onDoubleClick={() => header.column.resetSize()}
                        onMouseDown={header.getResizeHandler()}
                        onTouchStart={header.getResizeHandler()}
                        className={`resizer ${table.options.columnResizeDirection} ${header.column.getIsResizing() ? 'isResizing' : ''}`}
                        style={{
                          transform:
                            columnResizeMode === 'onEnd' && header.column.getIsResizing()
                              ? `translateX(${(table.options.columnResizeDirection === 'rtl' ? -1 : 1) * (table.getState().columnSizingInfo.deltaOffset ?? 0)}px)`
                              : '',
                        }}
                      />
                    </th>
                  )
                })
              }
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getTopRows().map(row => (
            <PinnedRow key={row.id} row={row} table={table} />
          ))}
          {(copyPinnedRows
              ? table.getRowModel().rows
              : table.getCenterRows()
            ).map(row => {
              const rowClass = getRowClass(row);
              return (
                <tr key={row.id} className={rowClass}>
                  {row.getVisibleCells().map(cell => {
                    return (
                      <td key={cell.id} style={{width: cell.column.getSize()}}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          {table.getBottomRows().map(row => (
            <PinnedRow key={row.id} row={row} table={table} />
          ))}
          {/* {table.getRowModel().rows.map(row => (
            <Fragment key={row.id}>
            <tr>
              {
                row.getVisibleCells().map(
                  cell => (
                    <td key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  )
                )
              }
            </tr>
            {row.getIsExpanded() && (
                <tr>
                  <td colSpan={row.getVisibleCells().length}>
                    {props.renderSubComponent({ row })}
                  </td>
                </tr>
              )}
            </Fragment>
          ))} */}
        </tbody>
      </table>
      <div className="h-4" />
      {pagination}
    </div>;
}

// Define the PinnedRow component
function PinnedRow({ row, table }) {
  let background = '#E7EAE7';
  if(row.getIsPinned() === 'top' && row.original.vetted){
    background = '#e1fde4';
  }else if(row.getIsPinned() === 'top' && !row.original.vetted && row.original.quoted){
    background = '#deeffd';
  }

  return (
    <tr
      style={{
        backgroundColor: `${background}`,
        position: 'sticky',
        top:
          row.getIsPinned() === 'top'
            ? `${row.getPinnedIndex() * 30 + 40}px`
            : undefined,
        bottom:
          row.getIsPinned() === 'bottom'
            ? `${
                (table.getBottomRows().length - 1 - row.getPinnedIndex()) * 30
              }px`
            : undefined,
      }}
    >
      {row.getVisibleCells().map(cell => {
        return (
          <td key={cell.id}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        );
      })}
    </tr>
  );
}

export default MyReactTable;
