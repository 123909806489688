//Author Sooyoung Kim
//Date June 30, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, putAPICallGenerator, callBackGenerator, formatNumber, formatDate, generateSID, getFromTo, replaceRegex} from '../../../util/util';
import {Card, CardHeader, CardBody} from 'reactstrap';
import moment from 'moment';
import {NavLink} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';
import MyReactTable from '../../util/my-react-table';
import ReportFilter from '../report-filter';
import '../report.css';

//initialize the state
const initialState = {
  SID: generateSID(),
  status:'pending',
  loanPurpose:'',
  loanType:'',
  isRush:'',
  isComplex:'',
  state:'',
  city:'',
  county:'',
  entity:'',
  client:'',
  openBy:'',
  filterAppraisalTypes:[],
  filterCounty:[],
  from:getFromTo('from'),
  to:getFromTo('to'),

  report:[],
  appraiser:'',
};

//reducer function that perform state update
const reducer = getReducer();


const SpreadWatchReport  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getReport();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //API call
  const getReport = () => {
    let counties = state.filterCounty.join(',');
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');

    let loanPurpose = replaceRegex(state.loanPurpose);

    let url = 'report/spreadWatch/status='+state.status+'&openBy='+state.openBy+'&client='+state.client+'&appraiser='+state.appraiser+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&county='+counties+'&appraisalType='+appraisalType;
    let callBack = apiCallBack([{state:'report', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  const approveSpread = (ID) => {
    let parameters = [
      {
        field:'ID',
        value:ID
      },
      {
        field:'field',
        value:'status'
      },
      {
        field:'value',
        value:'approved'
      }
    ];

    let report = state.report.slice();

    for(let i=0;i<report.length;i++){
      if(report[i].ID===ID){
        report[i].status='approved';
        break;
      }
    }
    
    let callBack = apiCallBack([{state:'report', value:report}]);
    httpPut('billing/spreadWatch/update', parameters, 'Confirmed accounting changes successfully.', 'Oops, something went wrong and could not confirm this accounting changes. Please try again later.', callBack);
  }

  //render
  const columns = [
    {
      id: 'referenceNum',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => {
        let icon = <i className="fa fa-question cursor-pointer" onClick={(e)=>approveSpread(props.row.original.ID)}/>;

        if(props.row.original.status==='approved')
          icon = <i className="fa fa-check green-color"/>
        return <div>

          &nbsp;{icon}&nbsp;&nbsp;<NavLink target="_blank" to={"/appraisal/"+props.row.original.orderID}>{props.row.original.reference_num}</NavLink>
        </div>;
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'complex',
      Header: 'Complex',
      accessor: d => d.complex,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'spread',
      Header: 'Spread',
      accessor: d => d.spread,
      Cell: props => <div>${formatNumber(props.row.original.spread)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'tatFiveDays',
      Header: '≤5 bd TAT',
      accessor: d => d.tat_five,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'lessThanTenAppraisers',
      Header: '<10 AP',
      accessor: d => d.less_than_ten_appraiser,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'appraiserResponded',
      Header: 'AP Responded',
      accessor: d => d.appraiser_responded,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'lowestFastestBid',
      Header: 'Lowest & Fastest',
      accessor: d => d.lowest_bid_fastest_tat,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'fastestTAT',
      Header: 'Fastest TAT',
      accessor: d => d.fastest_tat,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'bestRated',
      Header: 'Best Rated AP',
      accessor: d => d.best_rated_appraiser,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'comment',
      Header: 'Comment',
      minWidth:250,
      maxWidth:250,
      accessor: d => d.comment,
      Cell: props => <div><i>{props.row.original.comment}</i></div>,
      headerStyle: {
        textAlign:'left',
      }
    },
    {
      id: 'date',
      Header: 'Date',
      accessor: d => d.datetime_created,
      Cell: props=> <div>{formatDate(props.row.original.datetime_created)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  let from, to;
  if(state.from!=='')
    from = moment(state.from);
  if(state.to!=='')
    to = moment(state.to);

  let reportFiltersConfig = [
    {id:'from',value:from, updateFunc:(v)=>setState({from:v}), width:'2'},
    {id:'to',value:to, updateFunc:(v)=>setState({to:v}), width:'2'},
    {id:'customSelect',value:state.status, updateFunc:(v)=>setState({status:v}), width:'2', label:'Spread Status', clearable:true, className:"", options:[{label:"Pending Approval",value:"pending"},{label:"Approved",value:"approved"}]},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'3'},
    {id:'openBy',value:state.openBy, updateFunc:(v)=>setState({openBy:v}), width:'3'},
    {id:'appraiser',value:state.appraiser, updateFunc:(v)=>setState({appraiser:v}), width:'3'},
    {id:'rush',value:state.isRush, updateFunc:(v)=>setState({isRush:v}), width:'3'},
    {id:'complex',value:state.isComplex, updateFunc:(v)=>setState({isComplex:v}), width:'3'},
    {id:'state',value:state.state, updateFunc:(v)=>setState({state:v}), width:'3'},
    {id:'county',value:state.filterCounty, updateFunc:(v)=>setState({filterCounty:v}), width:'3', group:true},
    {id:'city',value:state.city, updateFunc:(v)=>setState({city:v}), width:'3'},
    {id:'loanPurpose',value:state.loanPurpose, updateFunc:(v)=>setState({loanPurpose:v}), width:'3'},
    {id:'loanType',value:state.loanType, updateFunc:(v)=>setState({loanType:v}), width:'3'},
    {id:'entity',value:state.entity, updateFunc:(v)=>setState({entity:v}), width:'4'},
    {id:'appraisalType',value:state.filterAppraisalTypes, updateFunc:(v)=>setState({filterAppraisalTypes:v}), width:'4'},
    {id:'button',value:'Submit', updateFunc:getReport, width:'4', className:"align-right", color:"warning"},
  ];

  return <div>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Spread Watch Report
      </CardHeader>
      <CardBody>
        <ReportFilter {...props} configs={reportFiltersConfig}/>
      </CardBody>
    </Card>
    <br/>

    <br/>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Report
      </CardHeader>
      <CardBody>
        <div className="large-scroll-container">
          <MyReactTable columns={columns} data={state.report} className="table table-striped"/>
        </div>
      </CardBody>
    </Card>
  </div>;
}


export default SpreadWatchReport;
