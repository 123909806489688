//Author Sooyoung Kim
//Date May 9th, 2022
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, formatDateTime, formatNumber, encodeParam, generateSID} from '../../util/util';
import InfiniteScroll from 'react-infinite-scroll-component';
import {Input, Button, Row, Col} from 'reactstrap';
import MySelect from '../util/my-select';
import {NavLink} from 'react-router-dom';
import React, {useReducer, useEffect, useRef} from 'react';

//initialize the state
const initialState = {
  rebuttals:[],
  limit: 25,
  offset: 0,
  hasMoreRebuttals: true,
  loading: false,
  sort: 'c.datetime_created',
  order: 'ASC',
  status: 'Submitted',
  clients: [],
  client: '',
  statuses: [{id:1,name:'Submitted'},{id:2,name:'Started'},{id:3,name:'Processed'},{id:4,name:'Completed'},{id:5,name:'Cancelled'}],
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
  loan_num:'',
  borrower:'',
  state:'',
  county:'',
  city:'',
  street:'',
  accountManager:'',
  accountManagers:[],
  rush:'',
  pipelineStatusCount:[],
  totalCount:0
};

//reducer function that perform state update
const reducer = getReducer();


const Rebuttals  = (props)=>{
  const controller = new AbortController();
  const newsid = useRef(generateSID());
  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  useEffect(()=>{
    console.log(state);
    if(state.rebuttals.length<=0 && state.hasMoreRebuttals){
        loadMore();
        getPipelineStatusCount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state]);

  //run only once when component is loaded
  useEffect(()=>{
    getAllClientProfiles();
    getAllAccountManagers();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //function that manipulate state
  //this function refresh the list of orders pipeline
  const refreshList = ()=>{
    let SID = generateSID();
    newsid.current = SID; 
    setState({
      rebuttals:[],
      totalCount:0,
      hasMoreRebuttals:true,
      offset:0
    });

    getPipelineStatusCount();
  }

  //click handler that switch the state of sorting order then call the function to refresh the list
  //to achieve the "sorting"
  const columnClickHandler = (col)=>{
    if(state.sort===col){
      if(state.order==='ASC')
        setState({order:'DESC'});
      else
        setState({order:'ASC'});
    }
    else
      setState({order:'ASC', sort:col});
    refreshList();
  }


  //non API call but simpyl manage state
  const getPipelineStatusCount = ()=>{
    let borrower = encodeParam(state.borrower);
    let loan_num = encodeParam(state.loan_num);
    let street = encodeParam(state.street);
    let city = encodeParam(state.city);
    let county = encodeParam(state.county);

    let url = 'rebuttal/pipeline/count/status='+state.status+'&borrower='+borrower+'&loan_num='+loan_num+'&state='+state.state+'&county='+county+'&city='+city+'&street='+street+'&client='+state.client+'&accountManager='+state.accountManager+'&rush='+state.rush;
    let callBack = apiCallBack([{state:'pipelineStatusCount', key:'data'}]);
    httpGet(url, '','Oops, something went wrong and could not load the information of your pipeline. Please try again later.', callBack);
  }

  const getAllClientProfiles = ()=>{
    let url = 'client/get/limit=-1&offset=-1';
    let callBack = apiCallBack([{state:'clients', key:'data'}]);
    httpGet(url, '','Oops, something went wrong and could not retrieve client profiles.', callBack);
  }

  const getAllAccountManagers = ()=>{
    let url = 'accountManager/get';
    let callBack = apiCallBack([{state:'accountManagers', key:'data'}]);
    httpGet(url, '','Oops, something went wrong and could not load list of account managers. Please try again later.', callBack);
  }

  const setStatus = (status)=>{
    setState({status:status});
    refreshList();
  }

  //this function render the sort icon at each column of the table
  const renderSortIcon = (col)=>{
    if(state.sort===col){
      if(state.order==='ASC')
        return <i className="red-color fa fa-arrow-down"></i>
      else
        return <i className="red-color fa fa-arrow-up"></i>
    }
  }

  //render function for infinite scroller
  const renderRebuttals = ()=>{
    return (
      <div>
        <table className="primary-table">
          <thead>
            <tr>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('reference_num')}><i className="fa fa-search"></i>&nbsp;Reference # {renderSortIcon('reference_num')}</th>
              <th className="cursor-pointer" width="10%"><i className="fa fa-search"></i>&nbsp;Appraiser</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('loan_num')}><i className="fa fa-folder"></i>&nbsp;Loan # {renderSortIcon('loan_num')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('CONCAT(borrower_f_name," ",borrower_l_name)')}><i className="fa fa-user"></i>&nbsp;Borrower {renderSortIcon('CONCAT(borrower_f_name," ",borrower_l_name)')}</th>
              <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('property_street')}><i className="fa fa-map-marker"></i>&nbsp;Property Address {renderSortIcon('property_street')}</th>
              <th className="cursor-pointer" width="25%" onClick={()=>columnClickHandler('')}><i className="fa fa-info-circle"></i>&nbsp;Order Information {renderSortIcon('')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('c.datetime_created')}><i className="fa fa-calendar-o"></i>&nbsp;Date Submitted {renderSortIcon('c.datetime_created')}</th>
            </tr>
          </thead>
          <tbody>
          {
            state.rebuttals.map(
              (rebuttal, index)=>{
                let icon;
                let iconChecks = [];

                if(rebuttal.rush==='Rush')
                  iconChecks.push(<div className="display-inline dark-red-color"><span className="my-badge2">RUSH</span> </div>);
                if(rebuttal.loan_type==='FHA')
                  iconChecks.push(<div className="display-inline dark-red-color"><span className="my-badge2">FHA</span> </div>);
                if(rebuttal.auto_assign==='yes')
                  iconChecks.push(<div className="display-inline dark-red-color"><i className="fa fa-laptop"/> </div>);
                if(rebuttal.hotfile==='yes')
                  iconChecks.push(<div className="display-inline red-color"><i className="fa fa-fire"/> </div>);
                if(rebuttal.pending_receivable==='yes')
                  iconChecks.push(<div className="display-inline green-color"><i className="fa fa-money"/> </div>);

                  icon = <div className="display-inline">
                    {iconChecks.map(
                      (jsx, index)=>{ return <div className="display-inline" key={index}>{jsx}&nbsp;</div>;}
                    )}
                  </div>;
                return (
                  <tr key={index} onClick={()=>window.open("rebuttal/"+rebuttal.appraisal_fk)}>
                    <td><b>{rebuttal.reference_num}<br/>{icon}</b></td>
                    <td>{rebuttal.appraiser}</td>
                    <td>{rebuttal.loan_num}</td>
                    <td>{rebuttal.borrower_f_name+' '+rebuttal.borrower_l_name}</td>
                    <td>{rebuttal.property_street+' '+rebuttal.property_city+', '+rebuttal.property_state+' '+rebuttal.property_zip}</td>
                    <td>
                      <i className="fa fa-tags icon-color"></i>&nbsp;
                      {rebuttal.appraisal_type}
                      <br/>
                      <i className="fa fa-home icon-color"></i>&nbsp;
                      {rebuttal.property_type}
                      <br/>
                      <i className="fa fa-hashtag icon-color"></i>&nbsp;
                      {rebuttal.loan_purpose}
                    </td>
                    <td>{formatDateTime(rebuttal.datetime_created)}</td>
                  </tr>
                );
              }
            )
          }
          </tbody>
        </table>
      </div>
    )
  }


  // API call
  const loadMore = ()=>{
    //do not load if there is no more rebuttals or it's loading data
    if(state.hasMoreRebuttals&&!state.loading){
      //set loading equals to true so it won't fire off before we are done
      setState({loading:true});
      let sID = newsid.current;

      let borrower = encodeParam(state.borrower);
      let loan_num = encodeParam(state.loan_num);
      let street = encodeParam(state.street);
      let city = encodeParam(state.city);
      let county = encodeParam(state.county);

      let url = 'rebuttal/get/limit='+state.limit+'&offset='+state.offset+'&order='+state.order+'&sort='+state.sort+'&status='+state.status+'&client='+state.client+'&loan_num='+loan_num+'&borrower='+borrower+'&state='+state.state+'&county='+county+'&city='+city+'&street='+street+'&accountManager='+state.accountManager+'&rush='+state.rush;
      
      //callback handler that update the state when http request return
      let callBack = (response)=>{
        console.log(response);

        if(sID===newsid.current){
          let code = response.data?response.data.code:undefined;
          if(code!=='00'){
            setState({hasMoreRebuttals:false});
          }
          else{
            let newRebuttal = response.data.data;
            let hasMoreRebuttals = true;
            let newOffset = state.offset;
            let totalCount = response.data.count;

            //if http request return empty then no more results, end of list
            if(newRebuttal.length<=0){
              hasMoreRebuttals = false;
            }
            else{
              //increment the offset
              newOffset = state.offset + 1;
            }

            //concat the current array of announcement
            if(state.rebuttals.length>0){
              let temp = [...state.rebuttals,...newRebuttal];

              setState({rebuttals:temp});
            }
            else
              setState({rebuttals:newRebuttal});

            setState({hasMoreRebuttals:hasMoreRebuttals, offset:newOffset, totalCount:totalCount});
          }
        }
      };
      callBack = callBack.bind(this);

      let errorCallBack = apiCallBack([{state:'hasMoreRebuttals', value:false}]);

      //collect the promise and wait for it to finish performing it's task
      let promises = httpGet(url, '','Oops, something went wrong and could not load rebuttal. Please try again later.', callBack, errorCallBack);
      promises
        .then(
          function(result){
            //set loading equals to false so the function could be fire off once again
            setState({loading:false});
          }
        );
    }
  }

  //render
  let pipelineStatusCounts;

  if(state.pipelineStatusCount.length>0){
    pipelineStatusCounts = state.pipelineStatusCount.map(
      (pipelineStatus,index)=>{
        return(
          <div key={index} className={"pipeline-stage"+(pipelineStatus.status===state.status?" active":"")} onClick={()=>setStatus(pipelineStatus.status)}>
            <span className="my-badge3">{pipelineStatus.count}</span> {pipelineStatus.status}
          </div>
        )
      }
    );
  }

  //render
  return(<div>
      <InfiniteScroll
        next={loadMore}
        dataLength={state.rebuttals.length}
        hasMore={state.hasMoreRebuttals}
        loader={<div key="nill" className="loader"><center>Loading more rebuttals...</center></div>}
        initialLoad = {true}
        className="my-well"
        scrollableTarget="contentContainer"
      >
      <div className="page-title">
        <Row>
          <Col sm="8">
            <i className="fa fa-reorder"></i>&nbsp;Rebuttals
          </Col>
          <Col sm="4" className="align-right">
            <font style={{fontSize:'1rem'}}><NavLink to="/rebuttal-global">Switch to global pipeline</NavLink></font>
          </Col>
        </Row>
      </div>

      <Row>
        <Col sm="2">
          <label className="no-margin-bottom orange-color">Status</label>
          <MySelect
            type="select"
            value={state.status}
            onChange={(v)=>{setState({status:v})}}
            options={[{label:"All",value:"All"}].concat(state.statuses.map((status)=>{
              return {label:status.name, value:status.name};
            }))}
          />
        </Col>
        <Col sm="3">
          <label className="no-margin-bottom">Client</label>
          <MySelect
            type="select"
            selectIsClearable={true}
            value={state.client}
            onChange={(v)=>{setState({client:v})}}
            options={state.clients.map((client)=>{
              return {label:client.company, value:client.ID};
            })}
          />
        </Col>
        <Col sm="3">
          <label className="no-margin-bottom">Loan # / Reference #</label>
          <Input type="text" value={state.loan_num} onChange={(e)=>setState({loan_num:e.target.value})}/>
        </Col>
        <Col sm="2">
          <label className="no-margin-bottom">Borrower</label>
          <Input type="text" value={state.borrower} onChange={(e)=>setState({borrower:e.target.value})}/>
        </Col>
        <Col sm="2">
          <label className="no-margin-bottom">Account manager</label>
          <MySelect
            type="select"
            selectIsClearable={true}
            value={state.accountManager}
            onChange={(v)=>{setState({accountManager:v})}}
            options={state.accountManagers.map((ac)=>{
              return {label:ac.name, value:ac.ID};
            })}
            />
        </Col>
      </Row>
      <Row>
        <Col sm="2">
          <label className="no-margin-bottom">State</label>
          <MySelect
            type="select"
            selectIsClearable={true}
            value={state.state}
            onChange={(v)=>{setState({state:v})}}
            options={[{label:"All",value:"All"}].concat(state.states.map((state)=>{
              return {label:state.value, value:state.value};
            }))}
            />
        </Col>
        <Col sm="2">
          <label className="no-margin-bottom">County</label>
          <Input type="text" value={state.county} onChange={(e)=>setState({county:e.target.value})}/>
        </Col>
        <Col sm="2">
          <label className="no-margin-bottom">City</label>
          <Input type="text" value={state.city} onChange={(e)=>setState({city:e.target.value})}/>
        </Col>
        <Col sm="2">
          <label className="no-margin-bottom">Street</label>
          <Input type="text" value={state.street} onChange={(e)=>setState({street:e.target.value})}/>
        </Col>
        <Col sm="2">
          <label className="no-margin-bottom">Rush</label>
          <MySelect
            type="select"
            selectIsClearable={true}
            value={state.rush}
            onChange={(v)=>{setState({rush:v})}}
            options={[{label:"Rush",value:"Rush"},{label:"Non-rush",value:"Non-rush"}]}
            />
        </Col>
        <Col sm="2" className="align-right valign-bottom">
          <br/>
          <Button color="warning" onClick={()=>refreshList()} >Submit</Button>
        </Col>
      </Row>
      <br/>

      <div className="my-divider"></div>
      <Row>
        <Col sm="6">
            <div>{formatNumber(state.totalCount)} rebuttals in queue<br/><br/></div>
        </Col>
        <Col sm="6">

        </Col>
      </Row>

      <div>
        {pipelineStatusCounts}
      </div>
        {renderRebuttals()}
      </InfiniteScroll>
  </div>);
};

export default Rebuttals;
