//Author Sooyoung Kim
//Date Nov 15, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, callBackGenerator, formatDateTime} from '../../util/util';
import {Col, Row, Input, Button, UncontrolledTooltip} from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import React, {useReducer, useEffect} from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {NavLink} from 'react-router-dom';
import './notification.css';
import 'react-datepicker/dist/react-datepicker.css';

//initialize the state
const initialState = {
  notifications:[],
  limit: 5,
  offset: 0,
  hasMoreNotifications: true,
  loading: false,

  startDate:'',
  endDate:'',
  keywords:''
};

//reducer function that perform state update
const reducer = getReducer();


const Notifications  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    if(state.notifications.length<=0 && state.hasMoreNotifications){
        loadMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state]);


  //non API call but simpyl manage state
  const refreshList = () => {
    setState({
      notifications:[],
      hasMoreNotifications:true,
      offset:0
    });
  }

  const renderNotifications = () => {
    return (
      <div>
        {
          state.notifications.map(
            (notification, index)=>{
              let icon = <i className="fa fa-info-circle topbar-icon"></i>;
              let link = '#';
              let data = notification.data;
              let readClass = '';

              if(data&&data!==''){
                try{
                  data = JSON.parse(notification.data);

                  if(data.type&&data.type!==''){
                    switch(data.type){
                      case 'order-updates':
                        icon = <i className="fa fa-tasks topbar-icon"></i>;
                        link = '/appraisal/'+data.ID;
                        break;
                      case 'order-comments':
                        icon = <i className="fa fa-commenting topbar-icon"></i>;
                        link = '/appraisal/'+data.ID;
                        break;
                      case 'notifications':
                        icon = <i className="fa fa-information topbar-icon"></i>;
                        link = data.link;
                        break;
                      default:
                        break;
                    }
                  }
                }
                catch(err){
                  console.log(err);
                }
              }

              let readControlIcon = <i className="fa fa-check green-color" onClick={(e)=>{e.preventDefault();e.stopPropagation();markAsRead(notification.ID)}}></i>;

              if(notification.status==='unread'){
                readClass = ' unread-notification';
                readControlIcon =
                  <div>
                    <i className="fa fa-ellipsis-h topbar-icon" id={"notification-control-icon"+notification.ID} onClick={(e)=>{e.preventDefault();e.stopPropagation();markAsRead(notification.ID)}}></i>
                    <UncontrolledTooltip placement="right" target={"notification-control-icon"+notification.ID}>
                      mark as read
                    </UncontrolledTooltip>
                  </div>;
              }

              return(
                  <div key={index} className={"notification-bar-item2"+readClass}>
                    <NavLink to={link} onClick={(e)=>{markAsRead(notification.ID)}}>
                      <div className="notification-bar-item2-title">
                        {icon} {notification.title}
                      </div>
                      <div className="notification-bar-item2-content">
                        {notification.message}
                      </div>
                      <Row className="no-padding no-margin">
                        <Col sm="7" className="no-padding no-margin">
                          <div className="notification-bar-item2-date">
                            {formatDateTime(notification.datetime_created)}
                          </div>
                        </Col>
                        <Col sm="5" className="no-padding no-margin">
                          <div className="notification-control-icon align-right">
                            {readControlIcon}
                          </div>
                        </Col>
                      </Row>
                    </NavLink>
                  </div>
              );
            }
          )
        }
      </div>
    )
  }

  //API call
  //function that fire when the infinite scroll reach bottom
  const loadMore = () => {
    //do not load if there is no more conditions or it's loading data
    if(state.hasMoreNotifications&&!state.loading){
      //set loading equals to true so it won't fire off before we are done
      setState({loading:true});

      let start ='';
      let end ='';
      if(state.endDate&&state.endDate!=='')
        end = state.endDate;
      if(state.startDate&&state.startDate!=='')
        start = state.startDate;
      let url = 'notification/get/limit='+state.limit+'&offset='+state.offset+'&keyword='+state.keywords+'&start='+start+'&end='+end;

      //callback handler that update the state when http request return
      let callBack = (response)=>{
        let code = response.data.code;

        if(code!=='00'){
          setState({hasMoreNotifications:false});
        }
        else{
          let newNotifications = response.data.data.notifications;
          let hasMoreNotifications = true;
          let newOffset = state.offset;

          //if http request return empty then no more results, end of list

          if(newNotifications.length<=0){
            hasMoreNotifications = false;
          }
          else{
            //increment the offset
            newOffset = state.offset + 1;
          }

          //concat the current array of announcement
          if(state.notifications.length>0){
            let temp = [...state.notifications,...newNotifications];

            setState({notifications:temp});
          }
          else
            setState({notifications:newNotifications});

          setState({hasMoreNotifications:hasMoreNotifications, offset:newOffset});
        }
      };
      callBack = callBack.bind(this);

      //error handler when the http request return with error
      let errorCallBack = apiCallBack([{state:'hasMoreNotifications', value:false}]);

      //collect the promise and wait for it to finish performing it's task
      let promises = httpGet(url, '','Oops, something went wrong and could not load notifications. Please try again later.', callBack, errorCallBack);
      promises
        .then(
          function(result){
            //set loading equals to false so the function could be fire off once again
            setState({loading:false});
          }
        );
    }
  }

  //mark notification as read
  const markAsRead = (id) => {
    let parameters = [
      {
        field:'ID',
        value:id
      }
    ];

    let callBack = (response)=>{
      let code = response.data.code;

      if(code!=='00'){
      }
      else{
        let notifications = state.notifications.slice();
        for(let i=0;i<notifications.length;i++){
          if(notifications[i].ID===id){
            notifications[i].status='read';
            break;
          }
        }
        setState({notifications:notifications});
      }
    };
    callBack = callBack.bind(this);

    httpPost('notification/read', parameters, '', '', callBack);
  }

  //render
  let startDate, endDate = null;
  if(state.startDate!=='')
    startDate = moment(state.startDate).toDate();
  if(state.endDate!=='')
    endDate = moment(state.endDate).toDate();
  
  return <div>
    <InfiniteScroll
      next={loadMore}
      dataLength={state.notifications.length}
      hasMore={state.hasMoreNotifications}
      loader={<div key="nill" className="loader"><center>Loading more notifications...</center></div>}
      initialLoad = {true}
      className="my-well"
      scrollableTarget="contentContainer"
    >

      <div className="page-title">
        <i className="fa fa-reorder"></i>&nbsp;Notifications
      </div>

      <Row>
        <Col sm="4">
          <label className="no-margin-bottom">Keywords</label>
          <Input className="form-control" value={state.keywords} type="text" onChange={(value)=>{setState({keywords:value.target.value})}}/>
        </Col>
        <Col sm="3">
          <label className="no-margin-bottom">Start date</label>
          <DatePicker
            className="form-control"
            dateFormat="yyyy/MM/dd"
            selected={startDate}
            onChange={(text)=>{(text)&&setState({startDate:text})}}
          />
        </Col>
        <Col sm="3">
          <label className="no-margin-bottom">End date</label>
          <DatePicker
            className="form-control"
            dateFormat="yyyy/MM/dd"
            selected={endDate}
            onChange={(text)=>{(text)&&setState({endDate:text})}}
          />
        </Col>
        <Col sm="2" className="align-right valign-bottom">
          <br/>
          <Button color="warning" onClick={()=>refreshList()} >Submit</Button>
        </Col>
      </Row>
      <div className="my-divider"></div>
      {renderNotifications()}
    </InfiniteScroll>
  </div>;
}


export default Notifications;
