// Sooyoung Kim
// June 21, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, callBackGenerator, formatDate, formatNumber, showMessage, generateSID, getFromTo} from '../../../util/util';
import {Button, Card, NavItem, Nav, TabContent, TabPane, CardHeader, CardBody, Row, Col, Table, Input, Modal, ModalBody, ModalHeader, NavLink as NavLinkL} from 'reactstrap';
import CheckToPrint from './check-to-print.js';
import React, {useReducer, useEffect, useRef} from 'react';
import ReportFilter from '../report-filter';
import moment from 'moment';
import MyFormStep from '../../util/my-form-step';
import {NavLink} from 'react-router-dom';
import ReactToPrint from 'react-to-print';

let start = getFromTo('start');
let end = getFromTo('end');

let oneYearAgo = new Date();
oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

let current_datetime = new Date()
let formatted_date = (current_datetime.getMonth() + 1)+"-"+current_datetime.getDate() + "-" + current_datetime.getFullYear();

//initialize the state
const initialState = {
  SID: generateSID(),
  appraisers:[],
  appraisersBreakdown:[],
  oneYearAgo:oneYearAgo,
  today:end,
  start:start,
  end:end,
  date:formatted_date,
  paymentQueue:{},
  dropDowns:{},
  idToProps:{},
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
  clients:[],
  client:'',
  state:'',
  paymentType:'',
  amc:'',
  vip:'',
  bankAccountName:'Wells Fargo Checking',
  expensesAccountName:'5000 Cost of Goods Sold:5100 Appraisal Fees',
  appraiser:'',

  payAppraiserProcessDropDownOpen:false,
  print:false,
  steps:['Print Check','Update Payable To Paid','Update & Sync Quick Book'],
  maxStep:0,
  currentStep:0,
  initialCheckNum:'',
  selectedAppraisers:[],
  firstPadding:'465',
  secondPadding:'480',
  thirdPadding:'100',

  activeTab:'1',
  newPaymentQueue:{},
  check:0,
  direct:0,
  digital:0
};

//reducer function that perform state update
const reducer = getReducer();


const PendingPaymentInternalPrint  = (props)=>{
  const controller = new AbortController();
  const componentRef = useRef(null);
  const newPaymentQueueRef = useRef(null);


  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getPrinterPaddingSetting();
    getPriorityPayment();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  const addToSelected = (ID) =>{
    let selectedAppraisers = state.selectedAppraisers.slice();

    let index = selectedAppraisers.indexOf(ID);

    if(index===-1)
      selectedAppraisers.push(ID);
    else
      selectedAppraisers.splice(index,1);
    setState({selectedAppraisers:selectedAppraisers})
  }

  const toggleAppraiserDetailsDropDown = () =>{
    setState({appaiserDetailsDropDownOpen:!state.appaiserDetailsDropDownOpen});
  }

  const updateAppraiserCheckNum = (appraiserID, value) =>{
    if(value.length<=11){
      let paymentQueue = Object.assign({}, state.paymentQueue);

      paymentQueue[appraiserID].check_num = value;
      let bills = paymentQueue[appraiserID].slice();

      for(let i=0;i<bills.length;i++){
        bills[i].check_num = value;
      }
      paymentQueue[appraiserID] = bills;
      setState({paymentQueue:paymentQueue});
    }
  }

  const updateInitialCheckNum = (value) =>{

    let paymentMethod = 'Check';
    if(state.activeTab === '2'){
      paymentMethod = 'Direct Deposit';
    }else if(state.activeTab === '5'){
      paymentMethod = 'Digital Wallet';
    }else{

    }
    if(value.length<=11){
      setState({initialCheckNum:value});

      let paymentQueue = Object.assign({},state.paymentQueue);

      for(let appraiserID in paymentQueue){
        let skip = false;
        let bills = paymentQueue[appraiserID].slice();

        if(bills.length<=0)
          skip = true;

        for(let i=0;i<bills.length;i++){
          bills[i].check_num = value;
          if(bills[i].method!==paymentMethod)
            skip = true;
        }


        if(!skip){
          paymentQueue[appraiserID] = bills;
          paymentQueue[appraiserID].check_num = value;
          value++;
        }

      }

      setState({paymentQueue:paymentQueue});
    }
  }

  const togglePayAppraiserProcessDropDown = () =>{
    setState({payAppraiserProcessDropDownOpen:!state.payAppraiserProcessDropDownOpen}, modifyQueue(state.activeTab));
  }

  const handleStepAdvance = () =>{
    if (newPaymentQueueRef.current && newPaymentQueueRef.current.parentElement) {
      newPaymentQueueRef.current.parentElement.scrollTop = 0;
    }
   

    let step = state.currentStep+1;
    if(step!==2){
      setState({currentStep:step});
      if(state.maxStep<step)
        setState({maxStep:step});
    }
    else{
      let checkNumError = false;
      for(let property in state.newPaymentQueue){
        let target = state.newPaymentQueue[property];
        for(let i=0;i<target.length;i++){

          if(!target[i].check_num||target[i].check_num===''){
            checkNumError = true;
          }
        }
      }
      if(checkNumError===false){
        payAppraiser();
        setState({currentStep:step});
        if(state.maxStep<step)
          setState({maxStep:step});
      }
      else
        showMessage('error','One of the check number is empty. Please correct it before submitting.');
    }
  }

  const updateStep = (step) =>{
    if(step<=state.maxStep)
      setState({currentStep:step});
  }

  const appraiserOnClick = (bills,appraiserID) =>{
    let paymentQueue = Object.assign({},state.paymentQueue);
    if(!paymentQueue[appraiserID])
      paymentQueue[appraiserID] = [];
    let target = paymentQueue[appraiserID].slice();
    let sofar = 0;

    for(let i=0;i<bills.length;i++){
      let index = -1;
      let bill = bills[i];

      for(let j=0;j<target.length;j++){
        if(target[j].bill_fk===bill.bill_fk){
          index = j;
          sofar++;
        }
      }
      if(index!==-1){
        //target.splice(index,1);
      }else
        target.push(bill);
    }
    if(sofar === bills.length){
      target = [];
    }


    paymentQueue[appraiserID] = target;

    let check = 0;
    let directDeposit = 0;
    let digitalWallet = 0;

    let temp = Object.values(paymentQueue);
    if(temp.length > 0){
      temp.map(
        (item, index)=>{
          if(item.length >0){
            item.map(
              (bill,index2)=>{
                let billMethod = bill.method;
                if(billMethod === 'Check')
                  check++;
                else if(billMethod === 'Direct Deposit')
                  directDeposit++;
                else if(billMethod === 'Digital Wallet')
                  digitalWallet++;


                return null;
              }
            );
          }
          return null;
        }
      )
    }

    setState({paymentQueue:paymentQueue, check:check, direct:directDeposit, digital:digitalWallet});
  }

  const billOnClick = (bill, appraiserID) =>{
    console.log(bill, appraiserID);
    //check if it's in the queue
    let paymentQueue = Object.assign({},state.paymentQueue);
    if(!paymentQueue[appraiserID])
      paymentQueue[appraiserID] = [];
    let target = paymentQueue[appraiserID];
    let index = -1;
    for(let i=0;i<target.length;i++){
      if(target[i].bill_fk===bill.bill_fk){
        index = i;
      }
    }

    if(index!==-1){
      target.splice(index,1);
    }
    else
      target.push(bill);

    paymentQueue[appraiserID] = target;

    let check = 0;
    let directDeposit = 0;
    let digitalWallet = 0;

    let temp = Object.values(paymentQueue);
    console.log(temp);
    if(temp.length > 0){
      temp.map(
        (item, index)=>{
          if(item.length >0){
            item.map(
              (bill,index2)=>{
                console.log(bill);
                let billMethod = bill.method;
                if(billMethod === 'Check')
                  check++;
                else if(billMethod === 'Direct Deposit')
                  directDeposit++;
                else if(billMethod === 'Digital Wallet')
                  digitalWallet++;


                return null;
              }
            );
          }
          return null;
        }
      )
    }

    setState({paymentQueue:paymentQueue, check:check, direct:directDeposit, digital:digitalWallet});

  }

  const activeTabToggle = (tab) =>{
    setState({activeTab:tab});
    modifyQueue(tab);
  }

  const modifyQueue = (tab) =>{
    let newPaymentQueue = {};
    let paymentMethod = 'Check';
    if(tab === '2'){
      paymentMethod = 'Direct Deposit';
    }
    else if(tab === '5'){
      paymentMethod = 'Digital Wallet';
    }
    else{

    }

    let temp = Object.entries(state.paymentQueue);

    if(temp.length > 0){
      temp.map(
        (item, index)=>{
          if(item.length >0){
            newPaymentQueue[item[0]] = [];
            item[1].map(
              (bill,index2)=>{
                let billMethod = bill.method;
                if(billMethod === paymentMethod){
                  newPaymentQueue[item[0]].push(bill);
                }
                return null;
              }
            );
            if(newPaymentQueue[item[0]].length < 1)
              delete newPaymentQueue[item[0]];
          }
          return null;
        }
      )
    }

    setState({newPaymentQueue:newPaymentQueue});
  }

  //API call
  const updatePrinterPaddingSetting = () =>{
    let parameters = [
      {
        field:'padding1',
        value:state.firstPadding
      },
      {
        field:'padding2',
        value:state.secondPadding
      },
      {
        field:'padding3',
        value:state.thirdPadding
      }
    ];

    httpPut('report/check/printer/update', parameters, 'Printer padding setting saved successfully.', 'Oops, something went wrong and could not save the printer padding setting. Please try again later.');
  }

  const payAppraiser = () =>{
    if(state.bankAccountName!==''&&state.expensesAccountName!==''){
      let bills =[];
      let checkNums = [];
      let checkNumError = false;
      for(let property in state.newPaymentQueue){
        let target = state.newPaymentQueue[property];
        for(let i=0;i<target.length;i++){
          bills.push(target[i].bill_fk);

          if(target[i].check_num&&target[i].check_num!=='')
            checkNums.push(target[i].check_num);
          else {
            checkNumError = true;
          }
        }
      }

      if(checkNumError===false){

        let parameters = [
          {
            field:'bills',
            value:bills
          },
          {
            field:'checks',
            value:checkNums
          },
          {
            field:'bankAccountName',
            value:state.bankAccountName
          },
          {
            field:'expensesAccountName',
            value:state.expensesAccountName
          }
        ];

        let callBack = apiCallBack([{state:'paymentQueue', value:{}}, {state:'check', value:0}, {state:'direct', value:0}, {state:'digital', value:0}, {state:'currentStep', value:0}, {state:'appraisers', value:[]}]);
        httpPost('billing/appraiserFee/printInternal/pay', parameters, 'Payment task queue on Quickbook successfully.', 'Oops, something went wrong and could not queue this payment task on Quickbook. Please try again later.', callBack);
      }
      else{
        showMessage('error','One of the check number is empty. Please correct it before submitting.');
      }
    }
  }

  const getReport = () =>{
    //call back for retrieving announcement
    let callBack = (response)=>{console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        setState({appraisers:response.data.data});

        let dropDowns = {};
        for(let i=0;i<response.data.data.length;i++){
          dropDowns[response.data.data[i].ID] = false;
        }
        setState({dropDowns:dropDowns});
      }
    };
    callBack = callBack.bind(this);

    httpGet('report/pendingPaymentSimplify/get/vip='+state.vip+'&start='+state.start+'&end='+state.end+'&state='+state.state+'&client='+state.client+'&amc='+state.amc+'&paymentType='+state.paymentType+'&appraiser='+state.appraiser, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  const getReportDetails = (appraisersFk, directDownload=false) =>{
    //call back for retrieving announcement
    let callBack = (response)=>{console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let appraisersBreakdown = response.data.data;

        setState({appraisersBreakdown:appraisersBreakdown});

        let idToProps = Object.assign({},state.idToProps);

        let cart = [];
        for(let i=0;i<appraisersBreakdown.length;i++){
          let tmp = {
            first_name: appraisersBreakdown[i].first_name,
            last_name: appraisersBreakdown[i].last_name,
            name: appraisersBreakdown[i].name,
            billing_name: appraisersBreakdown[i].billing_name,
            street: appraisersBreakdown[i].street,
            city: appraisersBreakdown[i].city,
            state: appraisersBreakdown[i].state,
            zip: appraisersBreakdown[i].zip,
            company_name: appraisersBreakdown[i].company_name,
          };
          idToProps[appraisersBreakdown[i].ID] = tmp;

          if(directDownload){
            for(let j=0;j<appraisersBreakdown[i].bills.length;j++){
              cart.push({bill:appraisersBreakdown[i].bills[j], ID:appraisersBreakdown[i].ID});
            }
          }
        }

        setState({idToProps:idToProps});

        let dropDowns = {};
        for(let i=0;i<appraisersBreakdown.length;i++){
          dropDowns[appraisersBreakdown[i].ID] = false;
        }
        setState({dropDowns:dropDowns});
        if(!directDownload)
          toggleAppraiserDetailsDropDown();
        else{
          let newbills = [];
          for(let i=0;i<cart.length;i++){
            newbills.push(cart[i].bill);
          }
          appraiserOnClick(newbills, cart[0].ID);
        }
      }
    };

    callBack = callBack.bind(this);

    httpGet('report/pendingPaymentSimplify/detail/get/vip='+state.vip+'&start='+state.start+'&end='+state.end+'&state='+state.state+'&client='+state.client+'&amc='+state.amc+'&paymentType='+state.paymentType+'&appraisers='+appraisersFk, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  const getPriorityPayment = () =>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){

        let idToProps = Object.assign({}, state.idToProps);
        for(let i=0;i<response.data.details.length;i++){
          let tmp = {
            first_name: response.data.details[i].first_name,
            last_name: response.data.details[i].last_name,
            name: response.data.details[i].name,
            billing_name: response.data.details[i].billing_name,
            street: response.data.details[i].street,
            city: response.data.details[i].city,
            state: response.data.details[i].state,
            zip: response.data.details[i].zip,
            company_name: response.data.details[i].company_name,
          };
          idToProps[response.data.details[i].ID] = tmp;
        }

        setState({idToProps:idToProps});

        for(let i=0;i<response.data.data.length;i++){
          billOnClick(response.data.data[i].bills,response.data.data[i].appraiser_fk);
        }
      }
    };
    callBack = callBack.bind(this);

    httpGet('report/pendingPayment/priority/get', '', 'Oops, something went wrong and could not retrieve priority payment queue.', callBack);
  }

  const getPrinterPaddingSetting = () =>{
    let callBack = apiCallBack([{state:'firstPadding', key:'data.padding_1'}, {state:'secondPadding', key:'data.padding_2'}, {state:'thirdPadding', key:'data.padding_3'}]);
    httpGet('report/check/printer', '', 'Oops, something went wrong and could not retrieve printer settings.', callBack);
  }

  //render
  let start, end;
  if(state.start!=='')
    start = moment(state.start);
  if(state.end!=='')
    end = moment(state.end);

  let paymentMethod = 'Check';
  if(state.activeTab === '2'){
    paymentMethod = 'Direct Deposit';
  }else if(state.activeTab === '5'){
    paymentMethod = 'Digital Wallet';
  }else{

  }


  let grandTotal = <div className="margin-top" style={{height:'34.4px'}}>&nbsp;</div>;

  if(Object.keys(state.paymentQueue).length>0){
    let total=0;
    let tabTotal = 0;
    for(let property in state.paymentQueue){
      let target = state.paymentQueue[property];
      for(let i=0;i<target.length;i++){
        total+= parseInt(target[i].total,10);
        if(target[i].method === paymentMethod)
          tabTotal+= parseInt(target[i].total,10);
      }
    }

    grandTotal =
    <div className="margin-top">
      <Row>
        <Col sm="3">
          <label>Bank account name</label> <Input type="text" value={state.bankAccountName} onChange={(e)=>setState({bankAccountName:e.target.value})}/>
        </Col>
        <Col sm="4">
          <label>Expenses account name</label> <Input type="text" value={state.expensesAccountName} onChange={(e)=>setState({expensesAccountName:e.target.value})}/>
        </Col>
        <Col sm="5">
          <Row>
            <Col sm="5">
              <label>Grand total: ${formatNumber(total)}</label>
              <br/>
              <Button color="warning" onClick={togglePayAppraiserProcessDropDown}>Pay with&nbsp;{paymentMethod}</Button>
            </Col>
            <Col sm="7">
              <label>{paymentMethod}&nbsp;total: ${formatNumber(tabTotal)}</label>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  }
  let chartData = [];

  for(let i=0;i<state.appraisers.length;i++){
    let appraiser = state.appraisers[i];
    let tmp = {};
    tmp.ID = appraiser.ID;
    tmp.new = appraiser.new;
    tmp.name = appraiser.name;
    tmp.payment_method = appraiser.payment_method;
    tmp.y = appraiser.bill_total;
    tmp.president_club = appraiser.president_club;
    tmp.expedite_payment = appraiser.expedite_payment;

    chartData.push(tmp);
  }

  let appraisers =
  <Modal className="my-modal-wide" isOpen={state.appaiserDetailsDropDownOpen} toggle={toggleAppraiserDetailsDropDown} >
    <ModalHeader hidden={true} toggle={toggleAppraiserDetailsDropDown}></ModalHeader>
    <ModalBody>
    {
      state.appraisersBreakdown.map(
        (appraiser,index)=>{

          let totalFee = 0;
          for(let i=0;i<appraiser.bills.length;i++){
            totalFee+=parseInt(appraiser.bills[i].total,10);
          }



          return(
            <div key={index}>
              <Card>
                <CardHeader className="header-color">
                  <i className="fa fa-user"></i> {appraiser.name} - ${formatNumber(totalFee)} - {appraiser.payment_method}
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col sm="4">
                      <label>Address</label>
                    </Col>
                    <Col sm="4">
                      <label>Billing address</label>
                    </Col>
                    <Col sm="4">
                      <label>Billing note</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="4">
                      {appraiser.address}
                    </Col>
                    <Col sm="4">
                      {appraiser.billing_address}
                    </Col>
                    <Col sm="4">
                      <i>{appraiser.billing_note}</i>
                    </Col>
                  </Row>
                  <br/>
                  <div className="medium-scroll-container">
                    <Table className="table table-striped">
                      <thead>
                        <tr>
                          <th align="left" width="6%"><div className="cursor-pointer" onClick={(e)=>{appraiserOnClick(appraiser.bills,appraiser.ID); modifyQueue(state.activeTab);}}>
                            <i className="fa fa-check gray-color"></i>
                          </div></th>
                          <th align="left" width="15%">Payment method</th>
                          <th align="left" width="11%">Reference #</th>
                          <th align="left" width="10%">Receivable</th>
                          <th align="left" width="10%">Payable</th>
                          <th align="left" width="20%">Property address</th>
                          <th align="left" width="15%">Date Completed</th>
                          <th align="left" width="15%">Borrower</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          appraiser.bills.map(
                            (bill, index2)=>{
                              let flash = false;

                              if(bill.has_unsettle_receivable==='yes')
                                flash = true;
                              let checkbox =
                              <div className="cursor-pointer" onClick={(e)=>billOnClick(bill,appraiser.ID)}>
                                <i className="fa fa-check gray-color"></i>
                              </div>;

                              let checked = false;
                              if(state.paymentQueue[appraiser.ID]){
                                let target = state.paymentQueue[appraiser.ID];
                                for(let i=0;i<target.length;i++){
                                  if(target[i].bill_fk===bill.bill_fk){
                                    checked = true;
                                    break;
                                  }
                                }
                              }
                              if(checked)
                                checkbox =
                                <div className="cursor-pointer" onClick={(e)=>billOnClick(bill,appraiser.ID)}>
                                  <i className="fa fa-check blue-color thick"></i>
                                </div>;
                              return(
                                <tr className={flash?'flashit red-color':''} key={index2}>
                                  <td width="6%" align="left">{checkbox}</td>
                                  <td width="11%" align="left">#{bill.bill_fk+' - '+bill.method}</td>
                                  <td width="15%" align="left"><NavLink className="display-inline no-padding" target="_blank" to={"/appraisal/"+bill.appraisal_fk}>{bill.reference_num}</NavLink></td>
                                  <td width="8%" align="left">${formatNumber(bill.income_total)}</td>
                                  <td width="8%" align="left">${formatNumber(bill.total)}</td>
                                  <td width="20%" align="left">{bill.street+' '+bill.city+', '+bill.state}</td>
                                  <td width="10%" align="left">{formatDate(bill.datetime_completed)}</td>
                                  <td width="15%" align="left">{bill.borrower}</td>
                                </tr>
                              );
                            }
                          )
                        }
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
              <br/>
            </div>
          );
        }
      )
    }
  </ModalBody>
</Modal>;

  let paymentQueue=[];
  for(let property in state.paymentQueue){
    let target = state.paymentQueue[property];
    if(target.length>0){
      let tmp = {};
      tmp.name = state.idToProps[property].name;
      tmp.ID = property;
      tmp.bills = target;
      paymentQueue.push(tmp);
    }
  }

  let newPaymentQueue=[];
  for(let property in state.newPaymentQueue){
    let target = state.newPaymentQueue[property];
    if(target.length>0){
      let tmp = {};
      tmp.name = state.idToProps[property].name;
      tmp.first_name = state.idToProps[property].first_name;
      tmp.last_name = state.idToProps[property].last_name;
      tmp.billing_name = state.idToProps[property].billing_name;
      tmp.company_name = state.idToProps[property].company_name;
      tmp.street = state.idToProps[property].street;
      tmp.city = state.idToProps[property].city;
      tmp.state = state.idToProps[property].state;
      tmp.zip = state.idToProps[property].zip;
      tmp.ID = property;
      tmp.bills = target;
      tmp.check_num = target[0].check_num;
      newPaymentQueue.push(tmp);
    }
  }

  let step1 , step2, step3;

  step1 = <div>
    <div className="align-right">
      <ReactToPrint
        trigger={() => <Button color="warning">Print</Button>}
        content={() => componentRef.current}
        onAfterPrint={()=>handleStepAdvance()}
      />
    </div>
    <label>Print Preview</label><br/>
    First Section Height <div className="display-inline"><Input type="text" value={state.firstPadding} onChange={(e)=>setState({firstPadding:e.target.value})}/></div>&nbsp;&nbsp;
    Middle & Last Section Height<div className="display-inline"><Input type="text" value={state.secondPadding} onChange={(e)=>setState({secondPadding:e.target.value})}/></div>&nbsp;&nbsp;
    Padding <div className="display-inline"><Input type="text" value={state.thirdPadding} onChange={(e)=>setState({thirdPadding:e.target.value})}/></div>&nbsp;&nbsp;
    <Button color="warning" onClick={(e)=>updatePrinterPaddingSetting()}>Set As Default</Button>
    <div ref={componentRef}>
      {
        newPaymentQueue.map(
          (appraiser, index)=>{
            if(index===newPaymentQueue.length-1){
              return(
                <div key={index}>
                  <CheckToPrint firstPadding={state.firstPadding+'px'} secondPadding={state.secondPadding+'px'} thirdPadding={state.thirdPadding+'px'} company_name={appraiser.company_name} first_name={appraiser.first_name} last_name={appraiser.last_name} name={appraiser.name} billing_name={appraiser.billing_name} street={appraiser.street} city={appraiser.city} state={appraiser.state} zip={appraiser.zip} bills={appraiser.bills} date={state.date}/>
                </div>
              );
            }
            else{
              return(
                <div key={index}>
                  <CheckToPrint firstPadding={state.firstPadding+'px'} secondPadding={state.secondPadding+'px'} thirdPadding={state.thirdPadding+'px'} company_name={appraiser.company_name} first_name={appraiser.first_name} last_name={appraiser.last_name} name={appraiser.name} billing_name={appraiser.billing_name} street={appraiser.street} city={appraiser.city} state={appraiser.state} zip={appraiser.zip} bills={appraiser.bills} date={state.date}/>
                  <div style={{pageBreakAfter:'always'}}/>
                </div>
              );
            }
          }
        )
      }
    </div>
  </div>;

  step2 = <div>
    <div className="align-right">
      <i>Max 11 characters on check number</i><br/>
      <label>Initial check #</label> <div className="display-inline" style={{width:'250px'}}><Input type="text" value={state.initialCheckNum} onChange={(e)=>{updateInitialCheckNum(e.target.value)}}/></div>
    </div>
    <div className="large-scroll-container">
      {
        newPaymentQueue.map(
          (appraiser, index)=>{
            return(
              <div key={index}>
                <Card>
                  <CardHeader className="header-color">
                    <Row>
                      <Col sm="8">
                        {appraiser.name}
                      </Col>
                      <Col sm="4" className="align-right">
                        Check # <div className="display-inline" style={{width:'250px'}}><Input type="text" value={appraiser.check_num} onChange={(e)=>{updateAppraiserCheckNum(appraiser.ID, e.target.value)}}/></div>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Table className="table table-striped">
                      <tbody>
                        {
                          appraiser.bills.map(
                            (bill, index2)=>{
                              return(
                                <tr key={index2}>
                                  <td width="70%">{bill.reference_num+' - '+bill.bill_fk+' - '+bill.borrower}</td>
                                  <td width="15%">${formatNumber(bill.total)}</td>
                                  <td width="15%">{bill.check_num}</td>
                                </tr>
                              )
                            }
                          )
                        }
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
                <br/>
              </div>
            );
          }
        )
      }
    </div>
    <div className="align-right">
      <Button color="warning" onClick={(e)=>{handleStepAdvance()}}>Update Payable</Button>
    </div>
  </div>;

  step3 = <div>
    <label>*Please use the quickbook web connector to update & sync quickbook to complete this process.</label>
    <br/>
    <label>Step 1</label><br/>
    <i>Launch the quickbook web connector and select the correct quickbook profile by clicking the check box</i>
    <br/><br/>
    <label>Step 2</label><br/>
    <i>Make sure your quickbook (desktop version) is not running any task or have any pop up window open (this might interfere with the update & sync process). Then hit the button "Update Selected"</i>

    <br/><br/>
    <img alt="@Home VMS Logo" width="800px" src="/img/quickbook-screenshot.png"/>
  </div>

  let activeStep = step1;
  if(state.currentStep===0)
    activeStep = step1;
  else if(state.currentStep===1)
    activeStep = step2;
  else if(state.currentStep===2)
    activeStep = step3;

  let totalPending = 0;

  let button = <div style={{height:50}}></div>;

  if(state.selectedAppraisers.length>0)
    button = <div style={{height:50,paddingBottom:10}}><Button color="warning" onClick={(e)=>getReportDetails(state.selectedAppraisers)}>View Selected Appraisers</Button></div>;
  
  let reportFiltersConfig = [
    {id:'from',value:start, updateFunc:(v)=>setState({start:v}), width:'2'},
    {id:'to',value:end, updateFunc:(v)=>setState({end:v}), width:'2'},
    {id:'customSelect',value:state.amc, updateFunc:(v)=>setState({amc:v}), width:'2', label:'AMC', clearable:false, className:"", options:[{label:"Include AMC",value:""},{label:"AMC only",value:"amcOnly"},{label:"Exclude AMC",value:"excludeAmc"}]},
    {id:'customSelect',value:state.vip, updateFunc:(v)=>setState({vip:v}), width:'3', label:'VIP', clearable:true, className:"", options:[{label:"Expedite Payment",value:"expeditePayment"},{label:"President Club",value:"presidentClub"},{label:"Expdiate Payment & President Club",value:"presidentAndExpedite"},{label:"New",value:"new"}]},
    {id:'state',value:state.state, updateFunc:(v)=>setState({state:v}), width:'3'},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'3'},
    {id:'customSelect',value:state.paymentType, updateFunc:(v)=>setState({paymentType:v}), width:'3', label:'Payment Method', clearable:false, className:"", options:[{label:"Check only",value:"Check"},{label:"Direct Deposit only",value:"Direct Deposit"}]},
    {id:'appraiser',value:state.appraiser, updateFunc:(v)=>setState({appraiser:v}), width:'4'},
    {id:'button',value:'Submit', updateFunc:getReport, width:'2', className:"align-right", color:"warning"},
  ];

  return <div>
    <ReportFilter {...props} configs={reportFiltersConfig}/>
    <div className="my-divider">&nbsp;</div>
    <i className="fa fa-credit-card"></i> - Check
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <i className="fa fa-arrows-h"></i> - Direct Deposit
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <i className="fa fa-internet-explorer"></i> - Digital Wallet
    <Card>
      <CardHeader className="header-color">
        Pending payment
      </CardHeader>
      <CardBody>
        <div className="align-right">
          {button}
        </div>
        <Row>
          <Col sm="7">
            <table className="table table-striped table-fixed-header" width="100%">
              <thead>
                <tr width="100%"><th width="10%">&nbsp;</th><th width="60%">Name</th><th width="20%">Amount</th><th width="10%">&nbsp;</th></tr>
              </thead>
              <tbody style={{height:'440px'}}>
                {
                  chartData.map(
                    (data,index)=>{
                      let checkBox = <div className="display-inline cursor-pointer" onClick={(e)=>addToSelected(data.ID)}>
                        <i className="fa fa-check gray-color"></i>
                      </div>;

                      if(state.selectedAppraisers.indexOf(data.ID)!==-1)
                        checkBox = <div className="display-inline cursor-pointer" onClick={(e)=>addToSelected(data.ID)}>
                          <i className="fa fa-check blue-color thick"></i>
                        </div>
                      let presidentClub = <div className="display-inline" style={{minWidth:'20px'}}></div>;
                      let expeditePayment = <div className="display-inline" style={{minWidth:'20px'}}></div>;
                      let paymentMethodIcon = <i className="fa fa-credit-card"></i>;

                      if(data.payment_method==='Direct Deposit'){
                        paymentMethodIcon = <i className="fa fa-arrows-h"></i>;
                      }else if(data.payment_method==='Digital Wallet'){
                        paymentMethodIcon = <i className="fa fa-internet-explorer"></i>;
                      }else{

                      }

                      let keyword = '';
                      if(data.new==='yes'){
                        keyword = 'NEW';
                      }

                      if(data.president_club==='yes')
                        presidentClub = <div className="display-inline" style={{minWidth:'20px'}}><i className="fa fa-trophy orange-color"></i></div>;
                      if(data.expedite_payment==='yes')
                        expeditePayment = <div className="display-inline" style={{minWidth:'20px'}}><i className="fa fa-money green-color"></i></div>;
                      totalPending = totalPending + parseInt(data.y, 10);
                      return(
                        <tr width="100%" key={index}>
                          <td width="10%">{presidentClub} {expeditePayment}<div className="display-inline red-color">{keyword}</div></td>
                          <td width="60%">
                            <Row style={{position:'unset'}}>
                              <Col sm="8" style={{position:'unset'}}>
                                {checkBox}&nbsp;
                                {paymentMethodIcon} {data.name}
                              </Col>
                              <Col sm="4" className="align-right" style={{position:'unset'}}>
                                <NavLink target="_blank" to={"/appraiser/"+data.ID}>View profile</NavLink>
                              </Col>
                            </Row>
                          </td>
                          <td width="20%">${formatNumber(data.y)}</td>
                          <td width="10%"><i onClick={(e)=>getReportDetails([data.ID])} className="fa fa-search cursor-pointer"></i>&nbsp;&nbsp;<i onClick={(e)=>getReportDetails([data.ID], true)} className="fa fa-shopping-cart cursor-pointer"></i></td>
                        </tr>
                      )
                    }
                  )
                }
              </tbody>
            </table>
            <table className="table table-striped" width="100%">
              <tbody>
                <tr>
                  <td width="10%">&nbsp;</td><td width="70%">Grand Total</td><td width="20%">${formatNumber(totalPending)}</td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col sm="5">
            <Nav tabs className="nav flex-column flex-sm-row" >
              <NavItem>
                <NavLinkL
                  className={"nav-link "+(state.activeTab === '1'?"active":"inactive" )}
                  onClick={() => { activeTabToggle('1'); }}
                  to="#"
                >
                  <div style={{fontSize:'13px'}}><span className="my-badge">{state.check}</span>&nbsp;Check</div>
                </NavLinkL>
              </NavItem>
              <NavItem>
                <NavLinkL
                  className={"nav-link "+(state.activeTab === '2'?"active":"inactive" ) }
                  onClick={() => { activeTabToggle('2'); }}
                  to="#"
                >
                  <div style={{fontSize:'12px'}}><span className="my-badge">{state.direct}</span>&nbsp;Direct Deposit</div>
                </NavLinkL>
              </NavItem>
              <NavItem>
                <NavLinkL
                  className={"nav-link "+(state.activeTab === '5'?"active":"inactive" ) }
                  onClick={() => { activeTabToggle('5'); }}
                  to="#"
                >
                  <div style={{fontSize:'12px'}}><span className="my-badge">{state.digital}</span>&nbsp;Digital Wallet</div>
                </NavLinkL>
              </NavItem>
            </Nav>
            <TabContent activeTab={state.activeTab}>
              <TabPane tabId="1">
                <Card>
                  <CardHeader className="gray-color">
                    <i className="fa fa-cart"></i> Payment queue - Check
                  </CardHeader>
                  <CardBody>
                    <div className="large-scroll-container">
                      <Table className="table table-striped">
                        <tbody>
                          {
                            paymentQueue.map(
                              (item, index)=>{
                                let billTotal = 0;
                                let billMethod = '';
                                let isExist = false;
                                let bills = item.bills.map(
                                  (bill,index2)=>{
                                    billTotal = billTotal + parseInt(bill.total,10);
                                    billMethod = bill.method;

                                    if(billMethod !== 'Check'){
                                      return null;
                                    }
                                    isExist = true;
                                    return(
                                      <div key={index2}>
                                        <Row>
                                          <Col sm="6">
                                            <i className="fa fa-times red-color cursor-pointer" onClick={(e)=>billOnClick(bill, item.ID)}></i> Payable #{bill.bill_fk}
                                          </Col>
                                          <Col sm="6" className="align-right">
                                            ${formatNumber(bill.total)}
                                          </Col>
                                        </Row>
                                      </div>
                                    );
                                  }
                                );
                                if(!isExist){
                                  return null;
                                }

                                return(
                                  <tr key={index}>
                                    <td>
                                      <Row>
                                        <Col sm="6">
                                          <i className="fa fa-times red-color cursor-pointer" onClick={(e)=>appraiserOnClick(item.bills,item.ID)}></i> <b>{item.name}</b>
                                        </Col>
                                        <Col sm="6" className="align-right">
                                          ${formatNumber(billTotal)}
                                        </Col>
                                      </Row>
                                      <div className="padding-left padding-right">{bills}</div>
                                    </td>
                                  </tr>
                                )
                              }
                            )
                          }
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId="2">
                <Card>
                  <CardHeader className="gray-color">
                    <i className="fa fa-cart"></i> Payment queue - Direct Deposit
                  </CardHeader>
                  <CardBody>
                    <div className="large-scroll-container">
                      <Table className="table table-striped">
                        <tbody>
                          {
                            paymentQueue.map(
                              (item, index)=>{
                                let billTotal = 0;
                                let billMethod = '';
                                let isExist = false;
                                let bills = item.bills.map(
                                  (bill,index2)=>{
                                    billTotal = billTotal + parseInt(bill.total,10);
                                    billMethod = bill.method;

                                    if(billMethod !== 'Direct Deposit'){
                                      return null;
                                    }
                                    isExist = true;
                                    return(
                                      <div key={index2}>
                                        <Row>
                                          <Col sm="6">
                                            <i className="fa fa-times red-color cursor-pointer" onClick={(e)=>billOnClick(bill, item.ID)}></i> Payable #{bill.bill_fk}
                                          </Col>
                                          <Col sm="6" className="align-right">
                                            ${formatNumber(bill.total)}
                                          </Col>
                                        </Row>
                                      </div>
                                    );
                                  }
                                );
                                if(!isExist){
                                  return null;
                                }
                                return(
                                  <tr key={index}>
                                    <td>
                                      <Row>
                                        <Col sm="6">
                                          <i className="fa fa-times red-color cursor-pointer" onClick={(e)=>appraiserOnClick(item.bills,item.ID)}></i> <b>{item.name}</b>
                                        </Col>
                                        <Col sm="6" className="align-right">
                                          ${formatNumber(billTotal)}
                                        </Col>
                                      </Row>
                                      <div className="padding-left padding-right">{bills}</div>
                                    </td>
                                  </tr>
                                )
                              }
                            )
                          }
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId="3">
                <Card>
                  <CardHeader className="gray-color">
                    <i className="fa fa-cart"></i> Payment queue -Stripe
                  </CardHeader>
                  <CardBody>
                    <div className="large-scroll-container">
                      <Table className="table table-striped">
                        <tbody>
                          {
                            paymentQueue.map(
                              (item, index)=>{
                                let billTotal = 0;
                                let billMethod = '';
                                let isExist = false;
                                let bills = item.bills.map(
                                  (bill,index2)=>{
                                    billTotal = billTotal + parseInt(bill.total,10);
                                    billMethod = bill.method;

                                    if(billMethod !== 'Stripe'){
                                      return null;
                                    }
                                    isExist = true;
                                    return(
                                      <div key={index2}>
                                        <Row>
                                          <Col sm="6">
                                            <i className="fa fa-times red-color cursor-pointer" onClick={(e)=>billOnClick(bill, item.ID)}></i> Payable #{bill.bill_fk}
                                          </Col>
                                          <Col sm="6" className="align-right">
                                            ${formatNumber(bill.total)}
                                          </Col>
                                        </Row>
                                      </div>
                                    );
                                  }
                                );
                                if(!isExist){
                                  return null;
                                }
                                return(
                                  <tr key={index}>
                                    <td>
                                      <Row>
                                        <Col sm="6">
                                          <i className="fa fa-times red-color cursor-pointer" onClick={(e)=>appraiserOnClick(item.bills,item.ID)}></i> <b>{item.name}</b>
                                        </Col>
                                        <Col sm="6" className="align-right">
                                          ${formatNumber(billTotal)}
                                        </Col>
                                      </Row>
                                      <div className="padding-left padding-right">{bills}</div>
                                    </td>
                                  </tr>
                                )
                              }
                            )
                          }
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId="4">
                <Card>
                  <CardHeader className="gray-color">
                    <i className="fa fa-cart"></i> Payment queue - Authorize.net
                  </CardHeader>
                  <CardBody>
                    <div className="large-scroll-container">
                      <Table className="table table-striped">
                        <tbody>
                          {
                            paymentQueue.map(
                              (item, index)=>{
                                let billTotal = 0;
                                let billMethod = '';
                                let isExist = false;
                                let bills = item.bills.map(
                                  (bill,index2)=>{
                                    billTotal = billTotal + parseInt(bill.total,10);
                                    billMethod = bill.method;

                                    if(billMethod !== 'Authorize.net'){
                                      return null;
                                    }
                                    isExist = true;
                                    return(
                                      <div key={index2}>
                                        <Row>
                                          <Col sm="6">
                                            <i className="fa fa-times red-color cursor-pointer" onClick={(e)=>billOnClick(bill, item.ID)}></i> Payable #{bill.bill_fk}
                                          </Col>
                                          <Col sm="6" className="align-right">
                                            ${formatNumber(bill.total)}
                                          </Col>
                                        </Row>
                                      </div>
                                    );
                                  }
                                );
                                if(!isExist){
                                  return null;
                                }

                                return(
                                  <tr key={index}>
                                    <td>
                                      <Row>
                                        <Col sm="6">
                                          <i className="fa fa-times red-color cursor-pointer" onClick={(e)=>appraiserOnClick(item.bills,item.ID)}></i> <b>{item.name}</b>
                                        </Col>
                                        <Col sm="6" className="align-right">
                                          ${formatNumber(billTotal)}
                                        </Col>
                                      </Row>
                                      <div className="padding-left padding-right">{bills}</div>
                                    </td>
                                  </tr>
                                )
                              }
                            )
                          }
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId="5">
                <Card>
                  <CardHeader className="gray-color">
                    <i className="fa fa-cart"></i> Payment queue - Digital Wallet
                  </CardHeader>
                  <CardBody>
                    <div className="large-scroll-container">
                      <Table className="table table-striped">
                        <tbody>
                          {
                            paymentQueue.map(
                              (item, index)=>{
                                let billTotal = 0;
                                let billMethod = '';
                                let isExist = false;
                                let bills = item.bills.map(
                                  (bill,index2)=>{
                                    billTotal = billTotal + parseInt(bill.total,10);
                                    billMethod = bill.method;

                                    if(billMethod !== 'Digital Wallet'){
                                      return null;
                                    }
                                    isExist = true;
                                    return(
                                      <div key={index2}>
                                        <Row>
                                          <Col sm="6">
                                            <i className="fa fa-times red-color cursor-pointer" onClick={(e)=>billOnClick(bill, item.ID)}></i> Payable #{bill.bill_fk}
                                          </Col>
                                          <Col sm="6" className="align-right">
                                            ${formatNumber(bill.total)}
                                          </Col>
                                        </Row>
                                      </div>
                                    );
                                  }
                                );
                                if(!isExist){
                                  return null;
                                }
                                return(
                                  <tr key={index}>
                                    <td>
                                      <Row>
                                        <Col sm="6">
                                          <i className="fa fa-times red-color cursor-pointer" onClick={(e)=>appraiserOnClick(item.bills,item.ID)}></i> <b>{item.name}</b>
                                        </Col>
                                        <Col sm="6" className="align-right">
                                          ${formatNumber(billTotal)}
                                        </Col>
                                      </Row>
                                      <div className="padding-left padding-right">{bills}</div>
                                    </td>
                                  </tr>
                                )
                              }
                            )
                          }
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </TabPane>
            </TabContent>
          </Col>
        </Row>

        {grandTotal}
      </CardBody>
    </Card>
    <br/>
    {appraisers}

    <Modal className="my-modal-wide" isOpen={state.payAppraiserProcessDropDownOpen} toggle={togglePayAppraiserProcessDropDown} >
      <ModalHeader hidden={true} toggle={togglePayAppraiserProcessDropDown}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-user"></i> Payable In Queue</h5>
        </center>
        <br/>
        <MyFormStep steps = {state.steps} currentStep = {state.currentStep} updateStep = {updateStep} maxStep={state.maxStep}/>
        <br/>
        {activeStep}
        <br/>
        <center>
          <Button color="info" onClick={togglePayAppraiserProcessDropDown}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
  </div>;
}


export default PendingPaymentInternalPrint;
