//Author Sooyoung Kim
//Date July 11, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, formatNumber, formatDateTime, getFromTo, replaceRegex} from '../../../util/util';
import {Button, Card, CardHeader, CardBody, Collapse, Col, Row, Input, Modal, ModalHeader, ModalBody, Table} from 'reactstrap';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import React, {useReducer, useEffect} from 'react';
import MyReactTable from '../../util/my-react-table';
import ReportFilter from '../report-filter';
import MySelect from '../../util/my-select';
import MyPlacesAutocomplete from '../../util/my-places-autocomplete';
import MyGoogleMap from '../../util/my-google-map';
import moment from 'moment';
import {NavLink} from 'react-router-dom';
import '../report.css';

//initialize the state
const initialState = {
  accrued: 'no',
  loanPurpose:'',
  loanType:'',
  isRush:'',
  isComplex:'',

  address:'',
  state:'',
  zip:'',
  city:'',
  street:'',
  county:'',
  lat:'',
  lng:'',
  radius:25,

  entity:'',
  client:'',
  openBy:'',
  selectedAppraisalType:'',
  selectedCounty:'',
  filterAppraisalTypes:[],
  filterCounty:[],
  clickedHeader:false,
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
  clients:[],
  loanTypes:[{key:'Conventional',value:'Conventional'},{key:'High Balanced',value:'High Balanced'},{key:'Jumbo',value:'Jumbo'},{key:'Jumbo ARM',value:'Jumbo ARM'},{key:'Jumbo Fixed',value:'Jumbo Fixed'},{key:'Jumbo <$1m',value:'Jumbo <$1m'},{key:'Jumbo $1m - $2m',value:'Jumbo $1m - $2m'},{key:'Jumbo >$2m',value:'Jumbo >$2m'},{key:'FHA',value:'FHA'},{key:'USDA',value:'USDA'},{key:'Other',value:'Other'}],
  loanPurposes:[{key:'Purchase',value:'Purchase'},{key:'Refinance',value:'Refinance'},{key:'R/T Refinance',value:'R/T Refinance'},{key:'C/O Refinance',value:'C/O Refinance'},{key:'HELOC/2nd Mortgage',value:'HELOC/2nd Mortgage'},{key:'Other',value:'Other'}],
  appraisalTypes:[],
  from:getFromTo('from'),
  to:getFromTo('to'),

  revenue:{
  },
  expense:{
  },
  third_party_fee:[],
  still_owe_as_of:'',
  transaction_not_match:0,
  revenue_order_count:0,
  expense_order_count:0,

  longitude:-87.6500523,
  latitude:41.850033,

  searchBy:'location',
  standardDev:0,

  appraisalDetails:[],
  orderDetailName:'',
  orderDetailID:-1,
  showOrderDetail:false,
  reportDetailsToggle:false,
  reportDetails:'',
  reportDetailsOrders:[],
  appraisers:[],
  appraiser:'',
  appraiserPipelineDropDownOpen:false,
};

//reducer function that perform state update
const reducer = getReducer();


const AppraiserFeeMap  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const onClickHeader = ()=>{
    setState({clickedHeader:!state.clickedHeader});
  }

  const googlePlaceOnChange = (address)=>{
    if(address!==state.address){

      if(address.indexOf(',')!==-1 && address.length>10){
        setState({lat:null, lng:null});

        geocodeByAddress(address)
          .then(
            results => {
              //always use the first returned result
              let targetResult = results[0];

              let addressComponents = targetResult.address_components;

              let streetNumber = '';
              let route = '';
              let city = '';
              let county = '';
              let stateName = '';
              let zip = '';

              //go through the address components and grab the street, city, county, state, and zip
              for(let i=0;i<addressComponents.length;i++){
                if(addressComponents[i].types[0]==='street_number')
                  streetNumber = addressComponents[i].long_name;
                else if(addressComponents[i].types[0]==='route')
                  route = addressComponents[i].long_name;
                else if(addressComponents[i].types[0]==='locality'&&addressComponents[i].types[1]==='political')
                  city = addressComponents[i].long_name;
                else if(addressComponents[i].types[0]==='administrative_area_level_2'&&addressComponents[i].types[1]==='political')
                  county = addressComponents[i].long_name;
                else if(addressComponents[i].types[0]==='administrative_area_level_1'&&addressComponents[i].types[1]==='political')
                  stateName = addressComponents[i].long_name;
                else if(addressComponents[i].types[0]==='postal_code')
                  zip = addressComponents[i].long_name;
              }


              let street = '';

              if(streetNumber!==''&&route!=='')
                street = streetNumber+' '+route;

              //get long lat
              getLatLng(targetResult)
                .then(
                  result=>{
                    if(result){
                      let lat = result.lat;
                      let lng = result.lng;
                      console.log(lng);

                      setState({lat:lat, lng:lng});
                    }
                  }
                )
                .catch(
                  error=>{
                    console.log(error);
                  }
                );

              if(street!==''){
                setState({street:street, address:street});
              }
              if(city!=='')
                setState({city:city});
              if(county!=='')
                setState({county:county});
              if(stateName!=='')
                setState({state:stateName});
              if(zip!=='')
                setState({zip:zip});
            })
          .catch(
            error => {
              console.log(error);
            }
          );
      }
      else{
        setState({address:address, street:address});
      }
    }
  }

  const clickOrderDetail = (appraisal, name, ID)=>{
    //console.log(appraisal.length);
    setState({showOrderDetail:true, appraisalDetails:appraisal, orderDetailName:name, orderDetailID:ID});
  }

  const toggleAppraiserPipeline = ()=>{
    setState({appraiserPipelineDropDownOpen:!state.appraiserPipelineDropDownOpen});
  }

  //API call
  //get appraisers by radius
  const getAppraiserAvgFeeMap = (e)=>{
    e.preventDefault();
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);

    let url = 'report/map/fee/openBy='+state.openBy+'&client='+state.client+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&street='+state.street+'&county='+state.county+'&zip='+state.zip+'&latitude='+state.lat+'&longitude='+state.lng+'&appraisalType='+appraisalType+'&searchBy='+state.searchBy+'&miles='+state.radius;
 
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let lat = response.data.data.lat;
        let lng = response.data.data.lng;
        if(lat !== 0 && lng !== 0){
          setState({latitude:lat, longitude:lng});
        }

        console.log(lat,lng);
        setState({appraisers:response.data.data.appraiser, clickedHeader:true, standardDev:response.data.data.standard_dev, showOrderDetail:false, orderDetailID:-1});
        //console.log(response.data.data);
      }
    };
    callBack = callBack.bind(this);

    httpGet(url, '', 'Oops, something went wrong and could not load appraiser fee map arround this property. Please try again later.', callBack);
  }

  const getAppraiserPipeline = (ID)=>{
    let callBack = apiCallBack([{state:'appraiserPipeline', key:'data'}]);
	  httpGet('appraiser/pipeline/overview/'+ID, '', 'Oops, something went wrong and could not load this appraiser\'s pipeline. Please try again later.', callBack);
  }

  //render
  let from, to;
  if(state.from!=='')
    from = moment(state.from);
  if(state.to!=='')
    to = moment(state.to);

  const columns = [
    {
      id: 'appraiser',
      Header: 'Appraiser',
      accessor: d => d.first_name+" "+d.last_name,
      Cell: props => {

        return (
          <div>
            <div>
              <i onClick={(e)=>{getAppraiserPipeline(props.row.original.ID);toggleAppraiserPipeline();}} className="fa fa-drivers-license-o cursor-pointer link-color"></i>&nbsp;
              <NavLink id={"memo"+props.row.original.ID} to={"/appraiser/"+props.row.original.ID}>{props.row.original.first_name+' '+props.row.original.last_name}</NavLink>
            </div>
            <div className="display-inline">
              Office: <a href={"tel:"+props.row.original.phone}>{props.row.original.phone}</a>
            </div>
            <br/>
            <div className="display-inline">
              Cell: <a href={"tel:"+props.row.original.secondary_phone}>{props.row.original.secondary_phone}</a>
            </div>
            <br/>
          </div>
        );
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'email',
      Header: 'Email',
      accessor: d => d.email,
      Cell: props =>{
        return (
          <div>
            <a href={"mailto:"+props.row.original.email}>{props.row.original.email}</a><br/>
          </div>
        );
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'address',
      Header: 'Address',
      accessor: d => d.street+' '+d.city+', '+d.state,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'avgFee',
      Header: 'Average Fee',
      minWidth:100,
      width:100,
      maxWidth:100,
      accessor: d => parseInt(d.avg_fee, 10),
      Cell: props =>{
        let fee = <div>-</div>;

        if(props.row.original.avg_fee!==-1 )
          fee = <div>
            $ {formatNumber(props.row.original.avg_fee)}
          </div>;
        return <div>
          {fee}
        </div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'orders',
      Header: 'Orders',
      accessor: d => d.appraisals.length,
      Cell: props =>{
        return (
          <div>
            <NavLink to="#" onClick={(e)=>clickOrderDetail(props.row.original.appraisals, props.row.appraiser, props.row.original.ID)}>Click for Details</NavLink>
          </div>
        );
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'dateJoined',
      Header: 'Date Joined',
      accessor: d => d.datetime_created,
      Cell: props =>{
        return (
          <div>{formatDateTime(props.row.original.datetime_created)}</div>
        );
      },
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  const columns2 = [
    {
      id: 'referenceNumber',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'loanNum',
      Header: 'Loan #',
      accessor: d => d.loan_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'borrower',
      Header: 'Borrower',
      accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'property',
      Header: 'Property',
      accessor: d => d.property_street+' '+d.property_city+', '+d.property_state+' '+d.property_zip,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'fee',
      Header: 'Fee',
      minWidth:100,
      width:100,
      maxWidth:100,
      accessor: d => parseInt(d.fee, 10),
      Cell: props =>{
        let fee = <div>-</div>;

        if(props.row.original.fee!==-1 )
          fee = <div>
            $ {formatNumber(props.row.original.fee)}
          </div>;
        return <div>
          {fee}
        </div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'orderInfo',
      Header: 'Order Information',
      accessor: d => d.appraisal_type,
      Cell: props =>{
        return (
          <div>
            <i className="fa fa-tags icon-color"></i>&nbsp;
            {props.row.original.appraisal_type}
            <br/>
            <i className="fa fa-home icon-color"></i>&nbsp;
            {props.row.original.property_type}
            <br/>
            <i className="fa fa-hashtag icon-color"></i>&nbsp;
            {props.row.original.loan_purpose}
          </div>
        );
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'dateSubmitted',
      Header: 'Submitted',
      accessor: d => d.datetime_submitted,
      Cell: props =>{
        return (
          <div>{formatDateTime(props.row.original.datetime_submitted)}</div>
        );
      },
      headerStyle: {
        textAlign:'left'
      }
    },
  ];

  let appraiserPipelineInspectionNeeded;
  let appraiserPipelineReportNeeded;
  let appraiserPipelineConditionNeeded;
  let appraiserPipelineRebuttalNeeded;
  let appraiserPipelineInspectionScheduled;

  if(state.appraiserPipeline&&state.appraiserPipeline.inspection_needed){
    appraiserPipelineInspectionNeeded = state.appraiserPipeline.inspection_needed.map(
      (order, index)=>{
        return <tr key={index}>
          <td><NavLink to={"/appraisal/"+order.ID}>{order.reference_num}</NavLink></td>
          <td>{order.loan_num}</td>
          <td>{order.borrower_f_name+' '+order.borrower_l_name}</td>
          <td>{order.property_street+' '+order.property_city+', '+order.property_state+' '+order.property_zip}</td>
          <td>{formatDateTime(order.datetime_accepted)}</td>
        </tr>
      }
    )
  }

  if(state.appraiserPipeline&&state.appraiserPipeline.report_needed){
    appraiserPipelineReportNeeded = state.appraiserPipeline.report_needed.map(
      (order, index)=>{
        return <tr key={index}>
          <td><NavLink to={"/appraisal/"+order.ID}>{order.reference_num}</NavLink></td>
          <td>{order.loan_num}</td>
          <td>{order.borrower_f_name+' '+order.borrower_l_name}</td>
          <td>{order.property_street+' '+order.property_city+', '+order.property_state+' '+order.property_zip}</td>
          <td>{formatDateTime(order.datetime_inspection)}</td>
        </tr>
      }
    )
  }

  if(state.appraiserPipeline&&state.appraiserPipeline.condition_needed){
    appraiserPipelineConditionNeeded = state.appraiserPipeline.condition_needed.map(
      (order, index)=>{
        return <tr key={index}>
          <td><NavLink to={"/condition/"+order.ID}>{order.reference_num}</NavLink></td>
          <td>{order.loan_num}</td>
          <td>{order.borrower_f_name+' '+order.borrower_l_name}</td>
          <td>{order.property_street+' '+order.property_city+', '+order.property_state+' '+order.property_zip}</td>
          <td>{formatDateTime(order.datetime_requested)}</td>
        </tr>
      }
    )
  }

  if(state.appraiserPipeline&&state.appraiserPipeline.rebuttal_needed){
    appraiserPipelineRebuttalNeeded = state.appraiserPipeline.rebuttal_needed.map(
      (order, index)=>{
        return <tr key={index}>
          <td><NavLink to={"/rebuttal/"+order.ID}>{order.reference_num}</NavLink></td>
          <td>{order.loan_num}</td>
          <td>{order.borrower_f_name+' '+order.borrower_l_name}</td>
          <td>{order.property_street+' '+order.property_city+', '+order.property_state+' '+order.property_zip}</td>
          <td>{formatDateTime(order.datetime_requested)}</td>
        </tr>
      }
    )
  }

  if(state.appraiserPipeline&&state.appraiserPipeline.inspection_scheduled){
    appraiserPipelineInspectionScheduled = state.appraiserPipeline.inspection_scheduled.map(
      (order, index)=>{
        return <tr key={index}>
          <td><NavLink to={"/rebuttal/"+order.ID}>{order.reference_num}</NavLink></td>
          <td>{order.loan_num}</td>
          <td>{order.borrower_f_name+' '+order.borrower_l_name}</td>
          <td>{order.property_street+' '+order.property_city+', '+order.property_state+' '+order.property_zip}</td>
          <td>{formatDateTime(order.datetime_requested)}</td>
        </tr>
      }
    )
  }

  let markers=[];
  if(state.appraisers.length>0){
    for(let i=0;i<state.appraisers.length;i++){
      if(state.appraisers[i].lat!==''&&state.appraisers[i].lng!==''&&state.appraisers[i].avg_fee!==0){
        let location = {};
        location.id = state.appraisers[i].ID;
        location.lat = state.appraisers[i].lat;
        location.lng = state.appraisers[i].lng;
        //console.log(state.appraisers[i].fee_info[0].avg_fee);
        location.description = state.appraisers[i].first_name+" "+state.appraisers[i].last_name;
        location.label ="$"+state.appraisers[i].avg_fee;
        location.select = false;
        if(state.showOrderDetail && state.orderDetailID !== state.appraisers[i].ID){
          location.select = true;
        }

        markers.push(location);
      }
    }
  }

  let reportFiltersConfig = [
    {id:'from',value:from, updateFunc:(v)=>setState({from:v}), width:'2'},
    {id:'to',value:to, updateFunc:(v)=>setState({to:v}), width:'2'},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'3'},
    {id:'openBy',value:state.openBy, updateFunc:(v)=>setState({openBy:v}), width:'3'},
    {id:'entity',value:state.entity, updateFunc:(v)=>setState({entity:v}), width:'2'},
    {id:'appraisalType',value:state.filterAppraisalTypes, updateFunc:(v)=>setState({filterAppraisalTypes:v}), width:'3'},
    {id:'rush',value:state.isRush, updateFunc:(v)=>setState({isRush:v}), width:'2'},
    {id:'complex',value:state.isComplex, updateFunc:(v)=>setState({isComplex:v}), width:'2'},
    {id:'loanPurpose',value:state.loanPurpose, updateFunc:(v)=>setState({loanPurpose:v}), width:'2'},
    {id:'loanType',value:state.loanType, updateFunc:(v)=>setState({loanType:v}), width:'2'},
    {id:'button',value:'Submit', updateFunc:getAppraiserAvgFeeMap, width:'1', className:"align-right", color:"warning"},
  ];


  return <div>
    <Modal className="my-modal" isOpen={state.appraiserPipelineDropDownOpen} toggle={toggleAppraiserPipeline} >
      <ModalHeader hidden={true} toggle={toggleAppraiserPipeline}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-warning"></i> Appraiser Pipeline Overview</h5>
        </center>
        <br/>

        <Card>
          <CardHeader>
            Pending Inspection
          </CardHeader>
          <CardBody>
            <div className="medium-scroll-container-no-min">
              <Table className="table table-striped">
                <thead>
                  <tr>
                    <th width="15%">Reference #</th>
                    <th width="15%">Loan #</th>
                    <th width="15%">Borrower</th>
                    <th width="25%">Property</th>
                    <th width="20%">Date Accepted</th>
                  </tr>
                </thead>
                <tbody>
                  {appraiserPipelineInspectionNeeded}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>

        <br/>

        <Card>
          <CardHeader>
            Inspection Scheduled
          </CardHeader>
          <CardBody>
            <div className="medium-scroll-container-no-min">
              <Table className="table table-striped">
                <thead>
                  <tr>
                    <th width="15%">Reference #</th>
                    <th width="15%">Loan #</th>
                    <th width="15%">Borrower</th>
                    <th width="25%">Property</th>
                    <th width="20%">Date Accepted</th>
                  </tr>
                </thead>
                <tbody>
                  {appraiserPipelineInspectionScheduled}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>

        <br/>

        <Card>
          <CardHeader>
            Report Expected
          </CardHeader>
          <CardBody>
            <div className="medium-scroll-container-no-min">
              <Table className="table table-striped">
                <thead>
                  <tr>
                    <th width="15%">Reference #</th>
                    <th width="15%">Loan #</th>
                    <th width="15%">Borrower</th>
                    <th width="25%">Property</th>
                    <th width="20%">Inspection Date</th>
                  </tr>
                </thead>
                <tbody>
                  {appraiserPipelineReportNeeded}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>

        <br/>

        <Card>
          <CardHeader>
            Condition Started
          </CardHeader>
          <CardBody>
            <div className="medium-scroll-container-no-min">
              <Table className="table table-striped">
                <thead>
                  <tr>
                    <th width="15%">Reference #</th>
                    <th width="15%">Loan #</th>
                    <th width="15%">Borrower</th>
                    <th width="25%">Property</th>
                    <th width="20%">Date Requested</th>
                  </tr>
                </thead>
                <tbody>
                  {appraiserPipelineConditionNeeded}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>

        <br/>

        <Card>
          <CardHeader>
            Rebuttal Started
          </CardHeader>
          <CardBody>
            <div className="medium-scroll-container-no-min">
              <Table className="table table-striped">
                <thead>
                  <tr>
                    <th width="15%">Reference #</th>
                    <th width="15%">Loan #</th>
                    <th width="15%">Borrower</th>
                    <th width="25%">Property</th>
                    <th width="20%">Date Requested</th>
                  </tr>
                </thead>
                <tbody>
                  {appraiserPipelineRebuttalNeeded}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
        <center>
          <Button color="info" onClick={toggleAppraiserPipeline}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Appraiser Fee Map
      </CardHeader>
      <CardBody>
        <Row>
          <Col sm="2">
            <b><font color="red">Search By:</font></b>
            <MySelect
              type="select"
              selectIsClearable={true}
              value={state.searchBy}
              onChange={(v)=>{setState({searchBy:v})}}
              options={[{label:"City/County/State",value:"location"},{label:"Address",value:"address"}]}
            />
          </Col>
          {state.searchBy === 'location' &&
          <Col sm="10">
            <Row>
              <Col sm="4">
                <b>State<font color="red">*</font></b>
                <MySelect
                  type="select"
                  selectIsClearable={true}
                  value={state.state}
                  onChange={(v)=>{setState({state:v})}}
                  options={state.states.map((state)=>{
                    return {label:state.value, value:state.value};
                  })}
                />
              </Col>
              <Col sm="4">
                <b>City</b>
                <Input type="text" value={state.city} onChange={(e)=>setState({city:e.target.value})}/>
              </Col>
              <Col sm="4">
                <b>County</b>
                <Input type="text" value={state.county} onChange={(e)=>setState({county:e.target.value})}/>
              </Col>
            </Row>
          </Col>
          }
          {state.searchBy === 'address' &&
          <Col sm="10">
            <Row>
              <Col sm="6">
                <b>Street<font color="red">*</font></b>
                <MyPlacesAutocomplete value={state.address} onChange={googlePlaceOnChange} onSelect={googlePlaceOnChange}/>
              </Col>
              <Col sm="2">
                <b>City</b>
                <Input type="text" value={state.city} onChange={(e)=>setState({city:e.target.value})}/>
              </Col>
              <Col sm="2">
                <b>State</b>
                <MySelect
                  type="select"
                  selectIsClearable={true}
                  value={state.state}
                  onChange={(v)=>{setState({state:v})}}
                  options={state.states.map((state)=>{
                    return {label:state.value, value:state.value};
                  })}
                />
              </Col>
              <Col sm="2">
                <b>Radius</b>
                <Input type="text" placeholder="miles" value={state.radius} onChange={(e)=>setState({radius:e.target.value})}/>
              </Col>
            </Row>
          </Col>
          }
        </Row>
        <div className="my-divider">&nbsp;</div>
        <br/>
        <ReportFilter {...props} configs={reportFiltersConfig}/>
      </CardBody>
    </Card>
    <br/>
    <Card>
      <CardHeader className="header-color cursor-pointer" onClick={()=>onClickHeader()}>
        <Row>
          <Col sm="8" className="align-left">
            <i className="fa fa-map"></i>&nbsp;Map
          </Col>
          <Col sm="4" className="align-right">
            <i className={(state.clickedHeader)?"fa fa-chevron-up":"fa fa-chevron-down"}></i>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Collapse isOpen={state.clickedHeader}>
          <MyGoogleMap lat={state.latitude} lng={state.longitude} markers={markers}/>
          <div className="align-left"><b><font color="red">*</font>Standard Deviation: </b>{state.standardDev}</div>
        </Collapse>
      </CardBody>
    </Card>
    <br/>
    {!state.showOrderDetail &&
    <div className="larger-scroll-container-no-min">
      <MyReactTable columns={columns} data={state.appraisers} className="table table-striped"/>
    </div>}
    {state.showOrderDetail &&
    <div>
      <Row>
        <Col>
          <div className="align-left" style={{fontSize:'20px'}}><i className="fa fa-user online-color"></i> <b>{state.orderDetailName}</b></div>
        </Col>
        <Col>
          <div className="align-right"><NavLink to="#" onClick={(e)=>setState({showOrderDetail:false, orderDetailID:-1})}>Back to appraiser list</NavLink></div>
        </Col>
      </Row>
      <div className="larger-scroll-container-no-min">
        <MyReactTable columns={columns2} data={state.appraisalDetails} className="table table-striped"/>
      </div>
    </div>}
  </div>;
}


export default AppraiserFeeMap;
