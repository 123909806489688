//Sooyoung Kim
//June 15, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, formatNumber} from '../../../util/util';
import {Card, CardHeader, CardBody, Col, Row} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import ReportFilter from '../report-filter';
import moment from 'moment';

let today = new Date();
let month = today.getMonth()+1;

if(month<10)
  month = '0'+month;
let from = today.getFullYear()+'-'+month+'-'+today.getDate();
let to = today.getFullYear()+'-'+month+'-'+today.getDate();

//initialize the state
const initialState = {
  from:from,
  to:to,
  clients:[],
  client:'',
  openBy:'',
  ccIncome:0,
  ccRefundVoid:0,
  mercuryCCIncome:0,
  mercuryCCRefundVoid:0,
  stripeIncome:0,
  stripeRefund:0,
  afsIncome:0,
  afsRefund:0,
  checkIncome:0,
  checkExpense:0,
  directDepositExpense:0,
  digitalWalletExpense:0,
};

//reducer function that perform state update
const reducer = getReducer();


const CashFlowReport  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getReportDetails();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  //API call
  const getReportDetails = ()=>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        setState({
          ccIncome:parseInt(response.data.data.cc_income,10),
          ccRefundVoid:parseInt(response.data.data.cc_refund_void,10),
          mercuryCCIncome:parseInt(response.data.data.mercury_cc_income,10),
          mercuryCCRefundVoid:parseInt(response.data.data.mercury_cc_refund_void,10),
          stripeIncome:parseInt(response.data.data.stripe_income,10),
          stripeRefund:parseInt(response.data.data.stripe_refund,10),
          afsIncome:parseInt(response.data.data.afs_income,10),
          afsRefund:parseInt(response.data.data.afs_refund,10),
          checkIncome:parseInt(response.data.data.check_income,10),
          checkExpense:parseInt(response.data.data.check_expense,10),
          directDepositExpense:parseInt(response.data.data.direct_deposit_expense,10),
          digitalWalletExpense:parseInt(response.data.data.digital_wallet_expense,10)
        });
      }
    };
    callBack = callBack.bind(this);
    httpGet('report/cashFlow/get/from='+state.from+'&to='+state.to+'&client='+state.client+'&openBy='+state.openBy, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  //render
  let from, to;
  if(state.from!=='')
    from = moment(state.from);
  if(state.to!=='')
    to = moment(state.to);

  let reportFiltersConfig = [
    {id:'from',value:from, updateFunc:(v)=>setState({from:v}), width:'2'},
    {id:'to',value:to, updateFunc:(v)=>setState({to:v}), width:'2'},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'3'},
    {id:'openBy',value:state.openBy, updateFunc:(v)=>setState({openBy:v}), width:'3'},
    {id:'button',value:'Submit', updateFunc:getReportDetails, width:'2', className:"align-right", color:"warning"},
  ];
  
  return <div>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Cash Flow Report
      </CardHeader>
      <CardBody>
        <ReportFilter {...props} configs={reportFiltersConfig}/>
        <div className="my-divider">&nbsp;</div>
        <Row>
          <Col sm="6">
            <label>Deposit</label><br/>
            <br/>
            <Row>
              <Col sm="6">
                <label>Authorize.net Charges</label>
              </Col>
              <Col sm="6" className="align-right">
                ${formatNumber(state.ccIncome)}
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <label>Authorize.net Refund</label>
              </Col>
              <Col sm="6" className="align-right">
                ${formatNumber(state.ccRefundVoid)}
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <label>MercuryCC Charges</label>
              </Col>
              <Col sm="6" className="align-right">
                ${formatNumber(state.mercuryCCIncome)}
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <label>MercuryCC Refund</label>
              </Col>
              <Col sm="6" className="align-right">
                ${formatNumber(state.mercuryCCRefundVoid)}
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <label>Stripe Charges</label>
              </Col>
              <Col sm="6" className="align-right">
                ${formatNumber(state.stripeIncome)}
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <label>Stripe Refund</label>
              </Col>
              <Col sm="6" className="align-right">
                ${formatNumber(state.stripeRefund)}
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <label>AFS Charges</label>
              </Col>
              <Col sm="6" className="align-right">
                ${formatNumber(state.afsIncome)}
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <label>AFS Refund</label>
              </Col>
              <Col sm="6" className="align-right">
                ${formatNumber(state.afsRefund)}
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <label>Check</label>
              </Col>
              <Col sm="6" className="align-right">
                ${formatNumber(state.checkIncome)}
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                &nbsp;
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <label>**Net Deposit**</label>
              </Col>
              <Col sm="6" className="align-right">
                ${formatNumber(state.ccIncome+state.ccRefundVoid+state.mercuryCCIncome+state.mercuryCCRefundVoid+state.stripeIncome+state.stripeRefund+state.afsIncome+state.afsRefund+state.checkIncome)}
              </Col>
            </Row>
          </Col>
          <Col sm="6">
            <label>Disbursement</label><br/>
            <br/>
            <Row>
              <Col sm="6">
                <label>Check Paid To Appraisers</label>
              </Col>
              <Col sm="6" className="align-right">
                ${formatNumber(state.checkExpense)}
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <label>Direct Deposit Paid To Appraisers</label>
              </Col>
              <Col sm="6" className="align-right">
                ${formatNumber(state.directDepositExpense)}
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <label>Digital Wallet Paid To Appraisers</label>
              </Col>
              <Col sm="6" className="align-right">
                ${formatNumber(state.digitalWalletExpense)}
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                &nbsp;
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                &nbsp;
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <label>**Net Disbursement**</label>
              </Col>
              <Col sm="6" className="align-right">
                ${formatNumber(state.checkExpense+state.directDepositExpense+state.digitalWalletExpense)}
              </Col>
            </Row>
            <br/>

            <Row>
              <Col sm="6">
                <label className="red-color">**Total Net**</label>
              </Col>
              <Col sm="6" className="align-right">
                ${formatNumber((state.ccIncome+state.ccRefundVoid+state.mercuryCCIncome+state.mercuryCCRefundVoid+state.stripeIncome+state.stripeRefund+state.afsIncome+state.afsRefund+state.checkIncome)-(state.checkExpense+state.directDepositExpense+state.digitalWalletExpense))}
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  </div>;
}


export default CashFlowReport;
