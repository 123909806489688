//Author Sooyoung Kim
//Date July 18, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, putAPICallGenerator, callBackGenerator} from '../../util/util';
import {Card, CardHeader, CardBody, Row, Col} from 'reactstrap';
import MyXEditable from '../util/my-xeditable';
import React, {useReducer, useEffect} from 'react';
import {useParams} from 'react-router-dom';
//initialize the state
const initialState = {
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
  clientFk:-1,
  profile:{},
  clientBranches:[]
};

//reducer function that perform state update
const reducer = getReducer();


const BrokerRelationshipProfileBroker  = (props)=>{
  const controller = new AbortController();
  let {id} = useParams();

  let newInitialState = Object.assign({}, initialState, {
      ID:id,
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getProfile(state.ID);
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    getClientBranches();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state.clientFk]);

  //non API call but simpyl manage state
  const generateUpdateFunction = (url, parameters, successMessage, failedMessage) => {
    return (newValue, callBack, errorCallBack)=>{
      //add in the new value into parameters
      parameters.push({field:'value',value:newValue});
      httpPut(url,parameters,successMessage,failedMessage, callBack, errorCallBack);
    }
  };

  //API call
  const getClientBranches = () => {
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let clientBranches=[{key:'0',value:''}];
        for(let i=0;i<response.data.data.length;i++){
          let tmp = {};
          tmp.value = response.data.data[i].branch_name;
          tmp.key = response.data.data[i].ID;

          clientBranches.push(tmp);
        }
        console.log(clientBranches);
        setState({clientBranches:clientBranches});
      }
    };
    callBack = callBack.bind(this);

    httpGet('client/branch/get/'+state.clientFk, '', 'Oops, something went wrong and could not retrieve client branch list.', callBack);
  }

  const getProfile = (ID) => {
    let callBack = apiCallBack([{state:'profile', key:'data'}, {state:'clientFk', key:'data.client_fk'}]);
    httpGet('brokerRelationship/broker/'+ID, '', 'Oops, something went wrong and could not load the profile. Please try again later.', callBack);
  }

  //render
  let successMessage = 'Broker company profile updated.';
  let failedMessage = 'Something went wrong and failed to update broker company profile, please try again later.';

  return <div className="my-well">
    <div className="page-title">
      <i className="fa fa-reorder"></i>&nbsp;Relationship Profile - Broker
    </div>
    <div className="my-divider">&nbsp;</div>
    <br/>
    <Row>
      <Col sm="2">

      </Col>
      <Col sm="8">
        <Card>
          <CardHeader className="header-color">
            <i className="fa fa-info"></i> Broker
          </CardHeader>
          <CardBody>
            <Row className="margin-bottom">
              <Col sm="4">
                <label>Name</label>
              </Col>
              <Col sm="8">
                <MyXEditable
                  type="text"
                  value={state.profile.name}
                  updateFunc={
                    generateUpdateFunction('brokerRelationship/broker/update',[{field:'field',value:'name'},{field:'ID',value:state.ID}],successMessage, failedMessage)
                  }
                />
              </Col>
            </Row>
            <Row className="margin-bottom">
              <Col sm="4">
                <label>Associated Branch</label>
              </Col>
              <Col sm="8">
                <MyXEditable
                  type="select"
                  opt={state.clientBranches}
                  value={state.profile.client_branch_fk}
                  updateFunc={
                    generateUpdateFunction('brokerRelationship/broker/update',[{field:'field',value:'client_branch_fk'},{field:'ID',value:state.ID}],successMessage, failedMessage)
                  }
                />
              </Col>
            </Row>
            <Row className="margin-bottom">
              <Col sm="4">
                <label>Status</label>
              </Col>
              <Col sm="8">
                <MyXEditable
                  type="select"
                  opt={[{key:'active',value:'active'},{key:'inactive',value:'inactive'}]}
                  value={state.profile.status}
                  updateFunc={
                    generateUpdateFunction('brokerRelationship/broker/update',[{field:'field',value:'status'},{field:'ID',value:state.ID}],successMessage, failedMessage)
                  }
                />
              </Col>
            </Row>
            <Row className="margin-bottom">
              <Col sm="4">
                <label>Email</label>
              </Col>
              <Col sm="8">
                <MyXEditable
                  type="text"
                  value={state.profile.email}
                  updateFunc={
                    generateUpdateFunction('brokerRelationship/broker/update',[{field:'field',value:'email'},{field:'ID',value:state.ID}],successMessage, failedMessage)
                  }
                />
              </Col>
            </Row>
            <Row className="margin-bottom">
              <Col sm="4">
                <label>Phone</label>
              </Col>
              <Col sm="8">
                <MyXEditable
                  type="text"
                  value={state.profile.phone}
                  updateFunc={
                    generateUpdateFunction('brokerRelationship/broker/update',[{field:'field',value:'phone'},{field:'ID',value:state.ID}],successMessage, failedMessage)
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col sm="4">
                <label>Note</label>
              </Col>
              <Col sm="8">
                <MyXEditable
                  type="text"
                  value={state.profile.note}
                  updateFunc={
                    generateUpdateFunction('brokerRelationship/broker/update',[{field:'field',value:'note'},{field:'ID',value:state.ID}],successMessage, failedMessage)
                  }
                />
              </Col>
            </Row>

            <br/>
          </CardBody>
        </Card>
      </Col>
      <Col sm="2">
      </Col>
    </Row>
  </div>;
}


export default BrokerRelationshipProfileBroker;
