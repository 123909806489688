//Author Sooyoung Kim
//Date June 8, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, deleteAPICallGenerator, callBackGenerator, formatDateTime, getFromTo} from '../../../util/util';
import {Button, Card, CardHeader, CardBody, Col, Row, Input, Table, Modal, ModalHeader, ModalBody} from 'reactstrap';
import MyXEditable from '../../util/my-xeditable';
import moment from 'moment';
import {NavLink} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';
import MyReactTable from '../../util/my-react-table';
import ReportFilter from '../report-filter';

let from = getFromTo('from');
let to = getFromTo('to');

//initialize the state
const initialState = {
  from:from,
  to:to,
  accountManagers:[],
  aeWithoutAssignment:[],
  aeTerritory:[],
  accountManagerOptions:[],
  selectedAETerritory:{
    broker_filters:[]
  },
  aeTerritoryDropDown:false,
  newAeTerritoryDropDown:false,
  aeOrderVolumeDropDown:false,
  clients:[],
  detailsOrder:[]
};

//reducer function that perform state update
const reducer = getReducer();


const AccountManagerPipeline  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getReportDetails();
    getAETerritory();
    getAllAccountManagers();
    getAllClientProfiles();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const selectAEOrderVolume = (ae)=>{
    toggleAEOrderVolume();
    setState({selectedAE:ae});
    getAEPipeline(ae);
  }

  const selectAETerritory = (aeTerritory)=>{
    setState({selectedAETerritory:aeTerritory});
    toggleAETerritory();
  }

  const toggleAETerritory = ()=>{
    setState({aeTerritoryDropDown:!state.aeTerritoryDropDown});
  }

  const toggleAEOrderVolume = ()=>{
    setState({aeOrderVolumeDropDown:!state.aeOrderVolumeDropDown});
  }

  const toggleNewAETerritory = ()=>{
    setState({newAeTerritoryDropDown:!state.newAeTerritoryDropDown});
  }

  const updateFieldCallBack = (ID, field, value)=>{
    console.log(ID, field, value);
    let aeTerritory = state.aeTerritory.slice();

    for(let i=0;i<aeTerritory.length;i++){
      if(aeTerritory[i].ID===ID){
        aeTerritory[i][field] = value;
        break;
      }
    }

    setState({aeTerritory:aeTerritory});
  }

  const updateFieldCallBack2 = (field, value)=>{
    let selectedAETerritory = Object.assign({}, state.selectedAETerritory);
    selectedAETerritory[field] = value;

    console.log(selectedAETerritory);

    setState({selectedAETerritory:selectedAETerritory});
  }

  //API call
  const generateUpdateFunction = (url, parameters, successMessage, failedMessage, parentCallBack=null)=>{
    return (newValue, callBack, errorCallBack)=>{
      //add in the new value into parameters

      parameters.push({field:'value',value:newValue});
      let promise = httpPut(url,parameters,successMessage,failedMessage, callBack, errorCallBack);
      promise.then(
        (response)=>{
          let field;
          let value;

          for(let i=0;i<parameters.length;i++){
            if(parameters[i].field==='field')
              field = parameters[i].value;
            else if(parameters[i].field==='value')
              value = parameters[i].value;
          }
          if(parentCallBack!==null){
            parentCallBack(field, value);
          }
        }
      );
    }
  };

  const getAllAccountManagers = ()=>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let accountManagers = [{key:'', value:''}];

        for(let i=0;i<response.data.data.length;i++){
          let tmp = {
            key:response.data.data[i].ID,
            value:response.data.data[i].name
          }
          accountManagers.push(tmp);
        }
        setState({accountManagerOptions:accountManagers});
      }
    };
    callBack = callBack.bind(this);

    httpGet('accountManager/get', '', 'Oops, something went wrong and could not load a list of account managers. Please try again later.', callBack);
  }

  const getReportDetails = ()=>{
    httpGet('report/accountManager/pipelineDistribution/from='+state.from+'&to='+state.to, '', 'Oops, something went wrong and could not load the report. Please try again later.', apiCallBack([{state:'accountManagers', key:'data.account_managers'},{state:'aeWithoutAssignment', key:'data.ae_without_assignment'}]));
  }

  const getAETerritory = ()=>{
    httpGet('accountManager/aeTerritory/get/from='+state.from+'&to='+state.to, '', 'Oops, something went wrong and could not load the report. Please try again later.', apiCallBack([{state:'aeTerritory', key:'data'}]));
  }

  const getAEPipeline = (ae)=>{
    httpGet('report/accountManager/pipelineDistribution/details/from='+state.from+'&to='+state.to+'&ae='+ae, '', 'Oops, something went wrong and could not load the report. Please try again later.', apiCallBack([{state:'detailsOrder', key:'data'}]));
  }

  const getAllClientProfiles = ()=>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;

      if(code==='00'){
        let clients = [];
        for(let i=0;i<response.data.data.length;i++){
          let client = response.data.data[i];

          let tmp = {};
          tmp.key = client.ID;
          tmp.value = client.company;

          clients.push(tmp);
        }

        setState({clients:clients});
      }
    };
    callBack = callBack.bind(this);

    httpGet('client/get/limit=-1&offset=-1', '', 'Oops, something went wrong and could not retrieve client profiles.', callBack);
  }

  const deleteBroker = (ID, brokerID)=>{
    let callBack = (response)=>{
      let code= response.data.code;
      if(code==='00'){
        let selectedAETerritory = Object.assign({}, state.selectedAETerritory);
        let broker_filters = selectedAETerritory.broker_filters.slice();

        for(let i=0;i<broker_filters.length;i++){
          if(broker_filters[i].ID===brokerID){
            broker_filters.splice(i, 1);
            break;
          }
        }
        selectedAETerritory.broker_filters = broker_filters;
        setState({selectedAETerritory:selectedAETerritory});

        let aeTerritory = state.aeTerritory.slice();
        for(let i=0;i<aeTerritory.length;i++){
          if(aeTerritory[i].ID===ID){
            aeTerritory.splice(i, 1, selectedAETerritory);
            break;
          }
        }
        setState({aeTerritory:aeTerritory});
      }
    };
    callBack = callBack.bind(this);

    httpDelete('accountManager/aeTerritory/broker/'+brokerID,'Broker deleted successfully.','Oops, something went wrong and could not delete this broker. Please try again later.', callBack);
  }

  const addNewAEProfile = ()=>{
    let parameters = [
      {
        field:'name',
        value:state.newAEProfileName
      },
      {
        field:'ae',
        value:state.newAEName
      }
    ];

    let callBack = (response)=>{
      let code= response.data.code;
      if(code==='00'){
        let aeTerritory = state.aeTerritory.slice();

        aeTerritory.unshift(response.data.data);

        setState({aeTerritory:aeTerritory});
        toggleNewAETerritory();
      }
    };
    callBack = callBack.bind(this);

    httpPost('accountManager/aeTerritory/new/ae', parameters, 'New ae profile added successfully.', 'Oops, something went wrong and could not add this new ae profile. Please try again later.', callBack);
  }

  const addNewBroker = ()=>{
    let parameters = [
      {
        field:'ID',
        value:state.selectedAETerritory.ID
      },
      {
        field:'broker',
        value:state.newBroker
      }
    ];

    let callBack = (response)=>{
      let code= response.data.code;
      if(code==='00'){
        let selectedAETerritory = Object.assign({}, state.selectedAETerritory);
        let broker_filters = selectedAETerritory.broker_filters.slice();

        broker_filters.push(response.data.data);

        selectedAETerritory.broker_filters = broker_filters;
        setState({selectedAETerritory:selectedAETerritory});

        let aeTerritory = state.aeTerritory.slice();
        for(let i=0;i<aeTerritory.length;i++){
          if(aeTerritory[i].ID===state.selectedAETerritory.ID){
            aeTerritory.splice(i, 1, selectedAETerritory);
            break;
          }
        }
        setState({aeTerritory:aeTerritory});
      }
    };
    callBack = callBack.bind(this);

    httpPost('accountManager/aeTerritory/new/broker', parameters, 'New broker added successfully.', 'Oops, something went wrong and could not add this new broker. Please try again later.', callBack);
  }

  //render
  let columns3  = [
    {
      id: 'referenceNum',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'property',
      Header: 'Property',
      accessor: d => d.property_street+' '+d.property_city+', '+d.property_state+' '+d.property_zip,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'dateSubmitted',
      Header: 'Date Submitted',
      accessor: d => d.datetime_submitted,
      Cell: props=> <div>{formatDateTime(props.row.original.datetime_submitted)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  const columns = [
    {
      id: 'accountManager',
      Header: 'Account Manager',
      accessor: d => d.account_manager_name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'totalOrder',
      Header: 'Total Order Volume',
      accessor: d => d.total_order,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'amcOrder',
      Header: 'AMC Order',
      accessor: d => d.total_amc_order,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'autoAssign',
      Header: 'Auto Assigned Order',
      accessor: d => d.total_auto_assign_order,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'toalAE',
      Header: 'AE Associated',
      accessor: d => d.total_ae_associated,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  const columns2 = [
    {
      id: 'ae',
      Header: 'AE',
      accessor: d => d.ae_name,
      headerStyle: {
        textAlign:'left',
        width:'20%'
      }
    },
    {
      id: 'totalOrder',
      Header: 'Total Order Volume',
      accessor: d => d.total_order,
      Cell: props => <NavLink to="#" onClick={(e)=>selectAEOrderVolume(props.row.original.ae_name)}>{props.row.original.total_order}</NavLink>,
      headerStyle: {
        textAlign:'left',
        width:'20%'
      }
    },
    {
      id: 'brokerCompany',
      Header: 'Broker Companies Associated',
      accessor: d => d.associated_broker_companies,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'broker',
      Header: 'Brokers Associated',
      accessor: d => d.associated_brokers,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'state',
      Header: 'States Associated',
      accessor: d => d.associated_states,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  let newfrom, newto;
  if(state.from!=='')
    newfrom = moment(state.from);
  if(state.to!=='')
    newto = moment(state.to);

  let reportFiltersConfig = [
    {id:'from',value:newfrom, updateFunc:(v)=>setState({from:v}), width:'3'},
    {id:'to',value:newto, updateFunc:(v)=>setState({to:v}), width:'3'},
    {id:'button',value:'Submit', updateFunc:(v)=>{getReportDetails();getAETerritory();}, width:'6', className:"align-right", color:"warning"},
  ];

  return <div>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Accounting Pipeline Distribution Report
      </CardHeader>
      <CardBody>
        <ReportFilter {...props} configs={reportFiltersConfig}/>
        <div className="my-divider">&nbsp;</div>
        <div className="my-well">
          <label><i className="fa fa-user"></i> Account Manager</label>
          <MyReactTable columns={columns} data={state.accountManagers} className="table table-striped"/>
        </div>
        <br/>
        <div className="my-well">
          <label><i className="fa fa-user"></i> AE Without Account Manager Assigned</label>
          <MyReactTable columns={columns2} data={state.aeWithoutAssignment} className="table table-striped"/>
        </div>
        <br/>

        <Row>
          <Col sm="6">
            <label><i className="fa fa-user"></i> AE</label>
          </Col>
          <Col sm="6">
            <div className="align-right">
              <NavLink to="#" onClick={toggleNewAETerritory}>Create New AE Profile</NavLink>
            </div>
          </Col>
        </Row>
        <div className="large-scroll-container">
          <Table className="table table-striped">
            <thead>
              <tr>
                <td>AE</td>
                <td>Total Volume</td>
                <td>Assigned To</td>
              </tr>
            </thead>
            <tbody>
              {
                state.aeTerritory.map(
                  (aeTerritory, index)=>{
                    return (
                      <tr key={index}>
                        <td><NavLink to="#" onClick={(e)=>{selectAETerritory(aeTerritory)}}>{aeTerritory.name}</NavLink></td>
                        <td><NavLink to="#" onClick={(e)=>selectAEOrderVolume(aeTerritory.name)}>{aeTerritory.total_order}</NavLink></td>
                        <td><MyXEditable
                          type="select"
                          value={aeTerritory.account_manager_fk}
                          opt={state.accountManagerOptions}
                          updateFunc={
                            generateUpdateFunction('accountManager/aeTerritory/assign',[{field:'field',value:'account_manager_fk'},{field:'ID',value:aeTerritory.ID}],'Assigned to account manager successfully', 'Failed to assign to account manager, please try again later.', (field, value)=>{updateFieldCallBack(aeTerritory.ID, field, value)})
                          }
                        /></td>
                      </tr>
                    );
                  }
                )
              }
            </tbody>
          </Table>
        </div>

        <Modal className="my-modal" isOpen={state.aeOrderVolumeDropDown} toggle={toggleAEOrderVolume} >
          <ModalHeader hidden={true} toggle={toggleAEOrderVolume}></ModalHeader>
          <ModalBody>
            <center>
              <h5><i className="fa fa-history"></i> {state.selectedAE} Pipeline</h5>
            </center>
            <MyReactTable columns={columns3} data={state.detailsOrder} className="table table-striped"/>

            <br/>
            <center>
              <Button color="warning" onClick={toggleAEOrderVolume}>Close</Button>
            </center>
          </ModalBody>
        </Modal>

        <Modal className="my-modal" isOpen={state.newAeTerritoryDropDown} toggle={toggleNewAETerritory} >
          <ModalHeader hidden={true} toggle={toggleNewAETerritory}></ModalHeader>
          <ModalBody>
            <center>
              <h5><i className="fa fa-history"></i> New AE Profile</h5>
            </center>

            <Row>
              <Col sm="5">
                <label>Profile name</label>
              </Col>
              <Col sm="7">
                <Input type="text" value={state.newAEProfileName} onChange={(e)=>setState({newAEProfileName:e.target.value})}/>
              </Col>
            </Row>
            <Row>
              <Col sm="5">
                <label>AE name</label>
              </Col>
              <Col sm="7">
                <Input type="text" value={state.newAEName} onChange={(e)=>setState({newAEName:e.target.value})}/>
              </Col>
            </Row>

            <br/>

            <center>
              <Button color="warning" onClick={addNewAEProfile}>Add</Button>
            </center>
          </ModalBody>
        </Modal>
        <Modal className="my-modal" isOpen={state.aeTerritoryDropDown} toggle={toggleAETerritory} >
          <ModalHeader hidden={true} toggle={toggleAETerritory}></ModalHeader>
          <ModalBody>
            <center>
              <h5><i className="fa fa-history"></i> AE - {state.selectedAETerritory.name}</h5>
            </center>
            <Row>
              <Col sm="5">
                <label>Profile name</label>
              </Col>
              <Col sm="7">
                <MyXEditable
                  type="text"
                  value={state.selectedAETerritory.name}
                  updateFunc={
                    generateUpdateFunction('accountManager/aeTerritory/update',[{field:'field',value:'name'},{field:'ID',value:state.selectedAETerritory.ID}],'AE profile updated successfully.', 'Failed to update AE profile, please try again later.', updateFieldCallBack2)
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col sm="5">
                <label>AE name</label>
              </Col>
              <Col sm="7">
                <MyXEditable
                  type="text"
                  value={state.selectedAETerritory.ae_name}
                  updateFunc={
                    generateUpdateFunction('accountManager/aeTerritory/update',[{field:'field',value:'ae_name'},{field:'ID',value:state.selectedAETerritory.ID}],'AE profile updated successfully.', 'Failed to update AE profile, please try again later.', updateFieldCallBack2)
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col sm="5">
                <label>Split profile lender filter</label>
              </Col>
              <Col sm="7">
                <MyXEditable
                  type="select"
                  opt={state.clients}
                  value={state.selectedAETerritory.split_by_lender_fk}
                  updateFunc={
                    generateUpdateFunction('accountManager/aeTerritory/update',[{field:'field',value:'split_by_lender_fk'},{field:'ID',value:state.selectedAETerritory.ID}],'AE profile updated successfully.', 'Failed to update AE profile, please try again later.', updateFieldCallBack2)
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col sm="5">
                <label>Split profile broker filter</label>
              </Col>
              <Col sm="7">
                <MyXEditable
                  type="select"
                  opt={[{key:'yes',value:'yes'},{key:'no',value:'no'}]}
                  value={state.selectedAETerritory.split}
                  updateFunc={
                    generateUpdateFunction('accountManager/aeTerritory/update',[{field:'field',value:'split'},{field:'ID',value:state.selectedAETerritory.ID}],'AE profile updated successfully.', 'Failed to update AE profile, please try again later.', updateFieldCallBack2)
                  }
                />
              </Col>
            </Row>

            <br/>

            <label>Add new broker</label><br/>
            <Row>
              <Col sm="8">
                <Input type="text" value={state.newBroker} onChange={(e)=>setState({newBroker:e.target.value})}/>
              </Col>
              <Col sm="4" className="align-right">
                <Button color="warning" onClick={addNewBroker}>Add</Button>
              </Col>
            </Row>
            <br/>

            <label>Existing broker</label><br/>
            <div className="large-scroll-container">
              {
                state.selectedAETerritory.broker_filters.map(
                  (broker, index2)=>{
                    console.log(broker);
                    return (
                      <div key={index2}>
                        <i className="fa fa-times cursor-pointer red-color" onClick={(e)=>deleteBroker(state.selectedAETerritory.ID, broker.ID)}></i> {broker.broker_name}
                      </div>
                    )
                  }
                )
              }
            </div>
          </ModalBody>
        </Modal>
      </CardBody>
    </Card>
  </div>;
}

export default AccountManagerPipeline;
