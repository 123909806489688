//Author Sooyoung Kim
//Date May 24th, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, deleteAPICallGenerator, callBackGenerator, confirmation, sliceFromArray} from '../../util/util';
import {Button, Input, Card, CardHeader, CardBody, Modal, ModalHeader, ModalBody, Table} from 'reactstrap';
import {NavLink} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';


//initialize the state
const initialState = {
  checklists:[],
  showPopUp:false,
  newChecklistOrderBy:1,
  newChecklist:'',
};

//reducer function that perform state update
const reducer = getReducer();


const ClientProfileChecklist  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getChecklists();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const togglePopUp = () =>{
    setState({showPopUp:!state.showPopUp});
  }

  //API call
  const createNewChecklist = () =>{
    let url = 'client/profile/checklist/create';
    let successMsg = 'Checklist created.';
    let errorMsg = 'Oops, something went wrong and could not create this checklist. Please try again later.';

    let parameters = [
      {
        field:'description',
        value:state.newChecklist
      },
      {
        field:'orderBy',
        value:state.newChecklistOrderBy
      }
    ];

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let checklists = state.checklists.slice();
        checklists.push(response.data.data);

        checklists.sort(
          (a, b)=>{
            return a.order_by > b.order_by;
          }
        );

        setState({checklists:checklists});
        togglePopUp();
      }
    };
    callBack = callBack.bind(this);

    httpPost(url, parameters, successMsg, errorMsg, callBack);
  }

  const getChecklists = () =>{
    let url = 'client/profile/checklist';
    let callBack = apiCallBack([{state:'checklists',key:'data'}]);
    httpGet(url, '','Oops, something went wrong and could not retrieve client onboard checklist.', callBack);
  }

  const deleteChecklist = (id) =>{
    let url = 'client/profile/checklist/'+id;
    let list = sliceFromArray(state.checklists,'ID', id);
    let callBack = apiCallBack([{state:'checklists', value:list}]);
    httpDelete(url,'Checklist removed.','Oops, something went wrong and could not delete the checklist. Please try again later.', callBack);
  }

  //render
  return <div className="my-well">
    <div className="align-right">
      <NavLink to="#" onClick={togglePopUp}><i className="fa fa-plus green-color"/> New Checklist</NavLink>
    </div>
    <Modal className="my-modal" isOpen={state.showPopUp} toggle={togglePopUp} >
      <ModalHeader hidden={true} toggle={togglePopUp}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-plus"></i>Add New Checklist</h5>
        </center>
        <label>Checklist Description</label><br/>
        <Input type="text" value={state.newChecklist} onChange={(e)=>setState({newChecklist:e.target.value})}/>
        <br/>
        <label>Order By</label><br/>
        <Input type="number" value={state.newChecklistOrderBy} onChange={(e)=>setState({newChecklistOrderBy:e.target.value})}/>
        <br/>
        <center>
          <Button color="info" onClick={togglePopUp}>Close</Button>&nbsp;
          <Button color="warning" onClick={createNewChecklist}>Create Checklist</Button>
        </center>
      </ModalBody>
    </Modal>

    <Card>
      <CardHeader className="header-color">
        Client OnBoard Checklist
      </CardHeader>
      <CardBody>
        <div className="large-scroll-container-no-min">
          <Table className="table table-striped">
            <tbody>
              {
                state.checklists.map(
                  (checklist, index)=>{
                    return <tr key={index}>
                      <td><i className="fa fa-times red-color cursor-pointer" onClick={(e)=>
                        confirmation(
                          ()=>{deleteChecklist(checklist.ID)},
                          ()=>{},
                          'Delete client onboarding checklist?',
                          'Are you sure you want to delete this client onboarding checklist? This action cannot be revert.')
                        }/> {checklist.order_by}. {checklist.description}</td>
                    </tr>
                  }
                )
              }
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  </div>;
}

export default ClientProfileChecklist;
