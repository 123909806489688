//Author Sooyoung Kim
//Date April 25, 2023
import {getReducer, getSetStateFunction, postAPICallGenerator, getSession} from '../../util/util';
import {Button, Col, Row, Input, Card, CardHeader, CardBody} from 'reactstrap';
import DatePicker from 'react-datepicker';
import MySelect from '../util/my-select';
import moment from 'moment';
import {NavLink, useParams, useNavigate} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';
import ReactQuill from 'react-quill';
import 'react-datepicker/dist/react-datepicker.css';

let session = getSession();
let name = session.userFirstName+' '+session.userLastName;
let email = session.email;

//initialize the state
const initialState = {
  requesterName:name,
  requesterEmail:email,
  subjectFacts:'',
  comparables:[
    {
      street:'',
      city:'',
      county:'',
      state:'',
      zip:'',
      salePrice:'',
      saleDate:'',
      sqFootage:'',
      siteSize:'',
      age:'',
      sourceData:'',
      apnMlsNum:'',
      comment:'',
      status:''
    },
    {
      street:'',
      city:'',
      county:'',
      state:'',
      zip:'',
      salePrice:'',
      saleDate:'',
      sqFootage:'',
      siteSize:'',
      age:'',
      sourceData:'',
      apnMlsNum:'',
      comment:'',
      status:''
    },
    {
      street:'',
      city:'',
      county:'',
      state:'',
      zip:'',
      salePrice:'',
      saleDate:'',
      sqFootage:'',
      siteSize:'',
      age:'',
      sourceData:'',
      apnMlsNum:'',
      comment:'',
      status:''
    }
  ],
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
};

//reducer function that perform state update
const reducer = getReducer();


const NewRebuttal  = (props)=>{
  const controller = new AbortController();
  const { id } = useParams();
  const navigate = useNavigate();

  let newInitialState = Object.assign({}, initialState, {id:id});
  const [state, dispatch] = useReducer(reducer, newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{


    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const updateComparableInfo = (index, field, value) =>{
    console.log(value);
    let comparables = state.comparables.slice();
    let target = Object.assign({},comparables[index]);
    target[field] = value;

    comparables.splice(index, 1, target);

    setState({comparables:comparables});
  }

  const removeComparables = (index) =>{
    let comparables = state.comparables.slice();
    comparables.splice(index,1);

    setState({comparables:comparables});
  }

  const addComparables = () =>{
    let comparable = {};
    comparable.street='';
    comparable.city='';
    comparable.county='';
    comparable.state='';
    comparable.zip='';
    comparable.salePrice='';
    comparable.saleDate='';
    comparable.sqFootage='';
    comparable.age='';
    comparable.siteSize='';
    comparable.sourceData='';
    comparable.apnMlsNum='';
    comparable.comment='';
    comparable.status='';

    let comparables = state.comparables.slice();
    comparables.push(comparable);

    setState({comparables:comparables});
  }

  //API call
  const createNewRebuttal = (e) =>{
    e.preventDefault();
    let url = 'rebuttal/internal/create';
    let parameters = [
      {
        field:'appraisalFk',
        value:state.id
      },
      {
        field:'isInternal',
        value:state.isInternal
      },
      {
        field:'requesterName',
        value:state.requesterName
      },
      {
        field:'requesterEmail',
        value:state.requesterEmail
      },
      {
        field:'subjectFacts',
        value:state.subjectFacts
      },
      {
        field:'comparables',
        value:state.comparables
      }
    ];

    let callBack = (response)=>{
      let code= response.data.code;

      if(code==='00'){
        navigate('/rebuttal/'+state.id);
      }
    };
    callBack = callBack.bind(this);

    httpPost(url,parameters,'Rebuttal created successfully.','Oops, something went wrong and could not create the rebuttal. Please try again later.', callBack);
  }

  //render
  let comparables;

  if(state.comparables.length>0){
    comparables = state.comparables.map(
      (comparable,index)=>{
        let date = null;
        if(comparable.saleDate&&comparable.saleDate!=='')
          date = moment(comparable.saleDate).toDate();
        return(
          <div key={index}>
            <Card>
              <CardHeader className="gray-color">
                <Row>
                  <Col sm="8">
                    Comparable {index+1}
                  </Col>
                  <Col sm="4" className="align-right">
                    <i className="fa fa-times red-color cursor-pointer" onClick={()=>removeComparables(index)}></i>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col sm="5">
                    <label>Street</label>
                    <Input type="text" value={comparable.street} onChange={(e)=>updateComparableInfo(index,'street',e.target.value)}/>
                  </Col>
                  <Col sm="4">
                    <label>City</label>
                    <Input type="text" value={comparable.city} onChange={(e)=>updateComparableInfo(index,'city',e.target.value)}/>
                  </Col>
                  <Col sm="3">
                    <label>County</label>
                    <Input type="text" value={comparable.county} onChange={(e)=>updateComparableInfo(index,'county',e.target.value)}/>
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    <label>States</label>
                    <MySelect
                      type="select"
                      value={comparable.state}
                      onChange={(v)=>{updateComparableInfo(index,'state',v)}}
                      options={state.states.map((state)=>{
                        return {label:state.value, value:state.value};
                      })}
                      />
                  </Col>
                  <Col sm="4">
                    <label>Zip</label>
                    <Input type="text" value={comparable.zip} onChange={(e)=>updateComparableInfo(index,'zip',e.target.value)}/>
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <label>Living area</label>
                    <Input type="text" value={comparable.sqFootage} onChange={(e)=>updateComparableInfo(index,'sqFootage',e.target.value)}/>
                  </Col>
                  <Col sm="3">
                    <label>Site area</label>
                    <Input type="text" value={comparable.siteSize} onChange={(e)=>updateComparableInfo(index,'siteSize',e.target.value)}/>
                  </Col>
                  <Col sm="3">
                    <label>Age</label>
                    <Input type="text" value={comparable.age} onChange={(e)=>updateComparableInfo(index,'age',e.target.value)}/>
                  </Col>
                  <Col sm="3">
                    <label>Status</label>
                    <MySelect
                      type="select"
                      value={comparable.status}
                      onChange={(v)=>{updateComparableInfo(index,'status',v)}}
                      options={[{label:"",value:""},{label:"Closed",value:"Closed"},{label:"Listing",value:"Listing"}]}
                      />
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <label>Sale price</label>
                    <Input type="text" value={comparable.salePrice} onChange={(e)=>updateComparableInfo(index,'salePrice',e.target.value)}/>
                  </Col>
                  <Col sm="3">
                    <label>Sale date</label><br/>
                    <DatePicker
                      className="form-control"
                      dateFormat="yyyy/MM/dd"
                      selected={date}
                      onChange={(text)=>{(text)&&updateComparableInfo(index,'saleDate',text.toLocaleDateString('en-CA'))}}
                    />
                  </Col>
                  <Col sm="3">
                    <label>APN/MLS Number</label>
                    <Input type="text" value={comparable.apnMlsNum} onChange={(e)=>updateComparableInfo(index,'apnMlsNum',e.target.value)}/>
                  </Col>
                  <Col sm="3">
                    <label>Source data</label>
                    <Input type="text" value={comparable.sourceData} onChange={(e)=>updateComparableInfo(index,'sourceData',e.target.value)}/>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <label>Comment</label>
                    <Input type="textarea" rows="6" style={{resize:'none'}} value={comparable.comment} onChange={(e)=>updateComparableInfo(index,'comment',e.target.value)}/>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <br/>
          </div>
        );
      }
    );
  }

  return <div className="my-well">
    <Row>
      <Col sm="6">
        <div className="page-title">
          <i className="fa fa-reorder"></i>&nbsp;New rebuttal
        </div>
        <NavLink to={"/rebuttal/"+state.id}>Back to rebuttal</NavLink>
      </Col>
      <Col sm="6">

      </Col>
    </Row>
    <div className="my-divider"></div>
    <form onSubmit={createNewRebuttal}>
      <Row>
        <Col sm="12">
          <label>Requester name</label>
          <Input type="text" value={state.requesterName} onChange={(e)=>setState({requesterName:e.target.value})} required="true"/>
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <label>Requester email</label>
          <Input type="text" value={state.requesterEmail} onChange={(e)=>setState({requesterEmail:e.target.value})} required="true"/>
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <label>Subject facts</label><br/>
          <ReactQuill
            modules={
              {
                toolbar: [
                    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                    [{size: []}],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{'list': 'ordered'}, {'list': 'bullet'},
                     {'indent': '-1'}, {'indent': '+1'}],
                    ['link', 'image', 'video'],
                    ['clean']
                  ],
                  clipboard: {
                    // toggle to add extra line breaks when pasting HTML:
                    matchVisual: false,
                  },
                  imageResize: {
                  // parchment: Quill.import('parchment'),
                    modules: ['Resize', 'DisplaySize']
                  }
              }
            }
            formats={
              [
                'header', 'font', 'size',
                'bold', 'italic', 'underline', 'strike', 'blockquote',
                'list', 'bullet', 'indent',
                'link', 'image', 'video'
              ]
            }
            value={state.subjectFacts}
            onChange={(value) => setState({subjectFacts:value})}
          />
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <div className="my-divider"></div>
          <br/>
          <div className="align-right">
            <NavLink to="#" onClick={addComparables}><i className="fa fa-plus green-color"></i> Add new comparable</NavLink>
          </div>
        </Col>
      </Row>
      {comparables}
      <br/>

      <div className="align-right">
        <Button color="warning">Create</Button>
      </div>
    </form>
  </div>;
}

export default NewRebuttal;
