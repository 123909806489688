//Author Sooyoung Kim
//Date Aug 17, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, generateSID, formatNumber} from '../../util/util';
import InfiniteScroll from 'react-infinite-scroll-component';
import React, {useReducer, useEffect, useRef} from 'react';
import {Row, Col} from 'reactstrap';
import ReportFilter  from '../report/report-filter';
import {NavLink} from 'react-router-dom';

//initialize the state
const initialState = {
  pdrs:[],
  limit: 25,
  offset: 0,
  hasMorepdrs: true,
  loading: false,
  sort: 'datetime_created',
  order: 'ASC',
  status: 'Submitted',
  clients: [],
  client: '',
  state:'',
  county:'',
  city:'',
  street:'',
  loan_num:'',
  entity_name:'',
  appraiser_name:'',
  pipelineStatusCount:[],
  totalCount:0
};

//reducer function that perform state update
const reducer = getReducer();


const PropertyDataReports  = (props)=>{
  const controller = new AbortController();
  const newsid = useRef(generateSID());

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    if(state.pdrs.length<=0 && state.hasMorepdrs){
        loadMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state]);

  useEffect(()=>{
    getPipelineStatusCount();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  
  //non API call but simpyl manage state
  //click handler that switch the state of sorting order then call the function to refresh the list
  //to achieve the "sorting"
  const columnClickHandler = (col) => {
    if(state.sort===col){
      if(state.order==='ASC')
        setState({order:'DESC'});
      else
        setState({order:'ASC'});
    }
    else
      setState({sort:col,order:'ASC'});
    refreshList();
  }

  //this function refresh the list of orders pipeline
  const refreshList = () => {
    let SID = generateSID();
    newsid.current = SID; 
    setState({
      pdrs:[],
      totalCount:0,
      hasMorepdrs:true,
      offset:0,
    });
    getPipelineStatusCount();
  }

  const setStatus = (status) => {
    setState({status:status,sort:''});
    refreshList();
  }

  
  //this function render the sort icon at each column of the table
  const renderSortIcon = (col) => {
    if(state.sort===col){
      if(state.order==='ASC')
        return <i className="red-color fa fa-arrow-down"></i>
      else
        return <i className="red-color fa fa-arrow-up"></i>
    }
  }

  //render function for infinite scroller
  const renderPDR = () => {
    return (
      <div>
        <table className="primary-table">
          <thead>
            <tr>
              <th className="cursor-pointer" width="60%" onClick={()=>columnClickHandler('property_street')}><i className="fa fa-map-marker"></i>&nbsp;Property Address {renderSortIcon('property_street')}</th>
              <th className="cursor-pointer" width="20%" onClick={()=>columnClickHandler('status')}><i className="fa fa-calendar-o"></i>&nbsp;Status {renderSortIcon('status')}</th>
              <th className="cursor-pointer" width="20%" onClick={()=>columnClickHandler('datetime_created')}><i className="fa fa-calendar-o"></i>&nbsp;Date {renderSortIcon('datetime_created')}</th>
            </tr>
          </thead>
          <tbody>
          {
            state.pdrs.map(
              (pdr)=>{
                let path = 'property-data-report';

                return (
                  <tr key={pdr.ID} onClick={()=>window.open(path+"/"+pdr.ID)}>
                    <td>{pdr.property_street+' '+pdr.property_city+', '+pdr.property_state+' '+pdr.property_zip}</td>
                    <td>{pdr.status}</td>
                    <td>{pdr.datetime_created}</td>
                  </tr>
                );
              }
            )
          }
          </tbody>
        </table>
      </div>
    )
  }

  //API call
  //function that fire when the infinite scroll reach bottom
  const loadMore = () => {
    //do not load if there is no more pdrs or it's loading data
    if(state.hasMorepdrs&&!state.loading){
      //set loading equals to true so it won't fire off before we are done
      setState({loading:true});
      let sID = newsid.current;

      let loan_num = encodeURIComponent(state.loan_num.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
      let street = encodeURIComponent(state.street.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
      let appraiser_name = encodeURIComponent(state.appraiser_name.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
      let city = encodeURIComponent(state.city.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
      let county = encodeURIComponent(state.county.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));

      let url = 'propertyDataReport/get/limit='+state.limit+'&offset='+state.offset+'&order='+state.order+'&sort='+state.sort+'&status='+state.status+'&loan_num='+loan_num+'&state='+state.state+'&county='+county+'&city='+city+'&street='+street+'&client='+state.client+'&appraiser_name='+appraiser_name;

      //callback handler that update the state when http request return
      let callBack = (response)=>{
        let code = response.data?response.data.code:undefined;
        ///console.log(response);
        //console.log('sID :'+sID+' vs current sID: '+state.sID);
        if(sID===newsid.current){
          if(code!=='00'){
            setState({hasMorepdrs:false});
          }
          else{
            let newpdrs = response.data.data;
            let hasMorepdrs = true;
            let newOffset = state.offset;
            let totalCount = response.data.count;

            //if http request return empty then no more results, end of list
            if(newpdrs.length<=0){
              hasMorepdrs = false;
            }
            else{
              //increment the offset
              newOffset = state.offset + 1;
            }

            //concat the current array of announcement
            if(state.pdrs.length>0){
              let temp = [...state.pdrs,...newpdrs];

              setState({pdrs:temp});
            }
            else
              setState({pdrs:newpdrs});

            setState({hasMorepdrs:hasMorepdrs, offset:newOffset, totalCount:totalCount});
          }

        }
      };
      callBack = callBack.bind(this);

      //error handler when the http request return with error
      let errorCallBack = apiCallBack([{state:'hasMorepdrs', value:false}]);

      //collect the promise and wait for it to finish performing it's task
      let promises = httpGet(url, '','Oops, something went wrong and could not load pdrs. Please try again later.' + sID+' .', callBack, errorCallBack);
      promises
        .then(
          function(result){
            //set loading equals to false so the function could be fire off once again
            setState({loading:false});
          }
        );
    }
  }

  //get pipeline status count
  const getPipelineStatusCount = () => {
    let loan_num = encodeURIComponent(state.loan_num.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
    let street = encodeURIComponent(state.street.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
    let appraiser_name = encodeURIComponent(state.appraiser_name.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
    let city = encodeURIComponent(state.city.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
    let county = encodeURIComponent(state.county.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));

    let url = 'propertyDataReport/pipeline/count/status='+state.status+'&loan_num='+loan_num+'&state='+state.state+'&county='+county+'&city='+city+'&street='+street+'&client='+state.client+'&appraiser_name='+appraiser_name;
    let callBack = apiCallBack([{state:'pipelineStatusCount', key:'data'}]);
	  httpGet(url, '', 'Oops, something went wrong and could not load the information of your pipeline. Please try again later.', callBack);
  }

  //render
  let pipelineStatusCounts;

  if(state.pipelineStatusCount.length>0){
    pipelineStatusCounts = state.pipelineStatusCount.map(
      (pipelineStatus,index)=>{
        return(
          <div key={index} className={"pipeline-stage"+(pipelineStatus.status===state.status?" active":"")} onClick={()=>setStatus(pipelineStatus.status)}>
            <span className="my-badge3">{pipelineStatus.count}</span> {pipelineStatus.status}
          </div>
        )
      }
    );
  }

  let reportFiltersConfig = [
    {id:'customSelect',value:state.status, updateFunc:(v)=>setState({status:v}), width:'2', label:'Status', options:[{value:'Submitted',label:'Submitted'},{value:'Completed',label:'Completed'},{value:'Cancelled',label:'Cancelled'}], clearable:false, labelClassName:"no-margin-bottom orange-color"},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'2', label:'Lender'},
    {id:'custom',value:state.appraiser_name, updateFunc:(v)=>setState({appraiser_name:v}), width:'2', label:'Appraiser'},
    {id:'custom',value:state.loan_num, updateFunc:(v)=>setState({loan_num:v}), width:'2', label:'Loan # / Reference #'},
    {id:'state',value:state.state, updateFunc:(v)=>setState({state:v}), width:'2'},
    {id:'city',value:state.city, updateFunc:(v)=>setState({city:v}), width:'2'},
    {id:'custom',value:state.street, updateFunc:(v)=>setState({street:v}), width:'2', label:'Street'},
    {id:'button',value:'Submit', updateFunc:refreshList, width:'10', className:"align-right", color:"warning"},
  ];

  return <div>
    <InfiniteScroll
      className="my-well"
      dataLength={state.pdrs.length} //This is important field to render the next data
      next={loadMore}
      hasMore={state.hasMorepdrs}
      loader={<center>Loading more PDRs...</center>}
      scrollableTarget="contentContainer"
    >
      <div className="page-title">
        <Row>
          <Col sm="8">
            <i className="fa fa-reorder"></i>&nbsp;PDRs
          </Col>
          <Col sm="4" className="align-right">
          </Col>
        </Row>
      </div>
      <ReportFilter {...props} configs={reportFiltersConfig}/>
      <br/>
      <div className="my-divider"></div>
      <Row className="low-line-height">
        <Col sm="6">
            <div>{formatNumber(state.totalCount)} PDR in queue<br/><br/></div>
        </Col>
        <Col sm="6">
          <div className="align-right"><NavLink to={'/property-data-report/new'}><i className="fa fa-plus"></i>&nbsp;Create new PDR.</NavLink><br/></div>
        </Col>
      </Row>

      <div>
        {pipelineStatusCounts}
      </div>


      {renderPDR()}
    </InfiniteScroll>
  </div>;
}


export default PropertyDataReports;
