//Sooyoung Kim
//June 16, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, formatDateTime, getFromTo, replaceRegex} from '../../../util/util';
import {Button, Card, CardHeader, CardBody, Modal, ModalHeader, ModalBody} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import MyReactTable from '../../util/my-react-table';
import ReportFilter from '../report-filter';
import moment from 'moment';
import '../report.css';

let from = getFromTo('from');
let to = getFromTo('to');

//initialize the state
const initialState = {
  loanPurpose:'',
  loanType:'',
  isRush:'',
  isComplex:'',
  state:'',
  city:'',
  county:'',
  entity:'',
  client:'',
  openBy:'',
  selectedAppraisalType:'',
  filterAppraisalTypes:[],
  from:from,
  to:to,
  report:[],
  appraisers:[],
  appraisals:[],
  appraiser:'',
  detailsPopUpOpen:false,
  type:'orderCount',
  reportDetails:[]
};

//reducer function that perform state update
const reducer = getReducer();


const AppraiserPaymentPriority  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getReport();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const reportDetailsToggle = ()=>{
    setState({detailsPopUpOpen:!state.detailsPopUpOpen});
  }

  //API call
  const getReport = ()=>{
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);

    let url = 'report/paymentPriority/county='+state.county+'&client='+state.client+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&appraisalType='+appraisalType;
    let callBack = apiCallBack([{state:'appraisers', key:'data.appraiser'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load the appraiser payment priority report. Please try again later.', callBack);
  }

  //render
  let from, to;
  if(state.from!=='')
    from = moment(state.from);
  if(state.to!=='')
    to = moment(state.to);

  let columnsDetails = [
    {
      id: 'referenceNumber',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'loanNum',
      Header: 'Loan #',
      accessor: d => d.loan_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'rush',
      Header: 'Rush',
      maxWidth:100,
      accessor: d => d.rush,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'complex',
      Header: 'Complex',
      maxWidth:120,
      accessor: d => d.complex,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'ppstatus',
      Header: 'Status',
      maxWidth:100,
      accessor: d => d.status,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'property',
      Header: 'Property',
      accessor: d => d.property_street+' '+d.property_city+', '+d.property_state+' '+d.property_zip,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'orderInfo',
      Header: 'Order Information',
      accessor: d => d.appraisal_type,
      Cell: props =>{
        return (
          <div>
            <i className="fa fa-tags icon-color"></i>&nbsp;
            {props.row.original.appraisal_type}
            <br/>
            <i className="fa fa-home icon-color"></i>&nbsp;
            {props.row.original.property_type}
            <br/>
            <i className="fa fa-hashtag icon-color"></i>&nbsp;
            {props.row.original.loan_purpose}
          </div>
        );
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'dateSubmitted',
      Header: 'Submitted',
      accessor: d => d.datetime_submitted,
      Cell: props =>{
        return (
          <div>{formatDateTime(props.row.original.datetime_submitted)}</div>
        );
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'dateRequested',
      Header: 'Priority Payment Requested',
      accessor: d => d.datetime_created,
      Cell: props =>{
        return (
          <div>{formatDateTime(props.row.original.datetime_created)}</div>
        );
      },
      headerStyle: {
        textAlign:'left'
      }
    },
  ];


  const columns = [
    {
      id: 'appraiserID',
      Header: 'Appraiser',
      accessor: d => d.ID,
      Cell: props => <NavLink target="_blank" to={"/appraiser/"+props.row.original.ID}>{props.row.original.first_name+" "+props.row.original.last_name}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'email',
      Header: 'Email',
      accessor: d => d.email,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'status',
      Header: 'Status',
      accessor: d => d.status,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'payment',
      Header: 'Payment Method',
      accessor: d => d.payment_method,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'orders',
      Header: 'Requested',
      accessor: d => d.appraisals.length,
      Cell: props => <NavLink to="#" onClick={()=>{setState({appraisals:props.row.original.appraisals}, reportDetailsToggle())}}>{props.row.original.appraisals.length}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'dateJoined',
      Header: 'Date Joined',
      accessor: d => d.datetime_created,
      Cell: props =>{
        return (
          <div>{formatDateTime(props.row.original.datetime_created)}</div>
        );
      },
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  let reportFiltersConfig = [
    {id:'from',value:from, updateFunc:(v)=>setState({from:v}), width:'3'},
    {id:'to',value:to, updateFunc:(v)=>setState({to:v}), width:'3'},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'3'},
    {id:'openBy',value:state.openBy, updateFunc:(v)=>setState({openBy:v}), width:'3'},
    {id:'rush',value:state.isRush, updateFunc:(v)=>setState({isRush:v}), width:'2'},
    {id:'complex',value:state.isComplex, updateFunc:(v)=>setState({isComplex:v}), width:'2'},
    {id:'loanPurpose',value:state.loanPurpose, updateFunc:(v)=>setState({loanPurpose:v}), width:'2'},
    {id:'loanType',value:state.loanType, updateFunc:(v)=>setState({loanType:v}), width:'2'},
    {id:'entity',value:state.entity, updateFunc:(v)=>setState({entity:v}), width:'4'},
    {id:'state',value:state.state, updateFunc:(v)=>setState({state:v}), width:'2'},
    {id:'county',value:state.county, updateFunc:(v)=>setState({county:v}), width:'2'},
    {id:'city',value:state.city, updateFunc:(v)=>setState({city:v}), width:'2'},
    {id:'appraisalType',value:state.filterAppraisalTypes, updateFunc:(v)=>setState({filterAppraisalTypes:v}), width:'3'},
    {id:'button',value:'Submit', updateFunc:getReport, width:'3', className:"align-right", color:"warning"},
  ];

  return <div>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Appraiser Payment Priority Report
      </CardHeader>
      <CardBody>
        <ReportFilter {...props} configs={reportFiltersConfig}/>
      </CardBody>
    </Card>
    <br/>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Report
      </CardHeader>
      <CardBody>
        {state.appraisers && <MyReactTable columns={columns} data={state.appraisers} className="table table-striped"/>}
      </CardBody>
    </Card>
    <Modal className="my-modal-wide" isOpen={state.detailsPopUpOpen} toggle={reportDetailsToggle} >
      <ModalHeader hidden={true} toggle={reportDetailsToggle}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-user"></i> Report Details</h5>
        </center>
        <br/>
        <div className="large-scroll-container">
          <MyReactTable columns={columnsDetails} data={state.appraisals} className="table table-striped"/>
        </div>
        <br/>
        <center>
          <Button color="info" onClick={reportDetailsToggle}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
  </div>;
}


export default AppraiserPaymentPriority;
