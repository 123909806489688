//Author Sooyoung Kim
//Date Aug 21, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, generateTooltip} from '../../util/util';
import {Card, CardHeader, CardBody, Col, Row, Input} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import MySelect from '../util/my-select';
import DatePicker from 'react-datepicker';
import moment from 'moment';

//initialize the state
const initialState = {
  clients:[]
};

//reducer function that perform state update
const reducer = getReducer();

const StepLoanInformation  = (props)=>{
  const controller = new AbortController();
  const [state, dispatch] = useReducer(reducer,initialState);

  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getAllClientProfiles();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //API call
  const getAllClientProfiles = () => {
    let callBack = apiCallBack([{state:'clients', valuekey:['ID', 'company']}]);
    httpGet('client/get/limit=-1&offset=-1', '', 'Oops, something went wrong and could not retrieve client profiles.', callBack);
  }

  //render
  let disabledStyling = {border:'none', background:'#fff', padding:'0',paddingLeft:'2px', height:'auto',WebkitAppearance:'none'};
  let dateOfContract;
  if(props.dateOfContract!==''&&props.dateOfContract!=='0000-00-00')
    dateOfContract = moment(props.dateOfContract).toDate();

  return <div>
    <Card>
      <CardHeader className="header-color">
        Loan Information
      </CardHeader>
      <CardBody>
        <Row style={{marginTop:'15px'}}>
          <Col sm="3">
            <label>{props.lpaKey===''?<font color="red">*</font>:null}Seller ID {generateTooltip('sellerID', 'Seller Service ID.')}</label>
            <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={props.lpaKey===''} type="text" value={props.sellerID} onChange={(e)=>props.updateState({sellerID:e.target.value})}/>
          </Col>
          <Col sm="3">
            <label>{props.sellerID===''?<font color="red">*</font>:null}LPA Key {generateTooltip('lpaKey', 'Loan identifier produced by Freddie Mac\'s Loan Product Advisor application.')}</label>
            <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={props.sellerID===''} type="text" value={props.lpaKey} onChange={(e)=>props.updateState({lpaKey:e.target.value})}/>
          </Col>
          <Col sm="3">
            <label>File Number {generateTooltip('fileNumber', 'This is an identifier or number used by the property data collector to identify their reports. It is generally specific to the property data collector.')}</label>
            <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} type="text" value={props.fileNumber} onChange={(e)=>props.updateState({fileNumber:e.target.value})}/>
          </Col>
          <Col sm="3">
            <label><font color="red">*</font>Loan Number {generateTooltip('additionalFileNumber', 'Secondary report identifier used by the property data collector to identify their reports. Seller Loan number should be entered in this field.')}</label>
            <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={true} type="text" value={props.additionalFileNumber} onChange={(e)=>props.updateState({additionalFileNumber:e.target.value})}/>
          </Col>
        </Row>
        <Row style={{marginTop:'15px'}}>
          <Col sm="3">
            <label><font color="red">*</font>Borrower Name</label>
            <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={true} type="text" value={props.borrowerName} onChange={(e)=>props.updateState({borrowerName:e.target.value})}/>
          </Col>
          <Col sm="3">
            <label><font color="red">*</font>Lender</label>
            <MySelect
              type="select"
              required={true}
              disabled={props.disabled}
              style={props.disabled?disabledStyling:{}}
              value={props.closeBy}
              onChange={(v)=>props.updateState({closeBy:v})}
              options={state.clients.map((client)=>{
                return {label:client.value, value:client.key};
              })}
            />
          </Col>
          <Col sm="3">
            <label>Contract Price</label>
            <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} type="number" value={props.sellingPrice} onChange={(e)=>props.updateState({sellingPrice:e.target.value})}/>
          </Col>
          <Col sm="3">
            <label><font color="red">*</font>Assignment Type</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={true}
              value={props.assignmentType}
              onChange={(v)=>props.updateState({assignmentType:v})}
              options={[
                {label:"",value:""},
                {label:"Purchase",value:"Purchase"},
                {label:"Refinance",value:"Refinance"},
              ]}
            />
          </Col>
        </Row>
        <Row style={{marginTop:'15px'}}>
          <Col sm="3">
            <label><font color="red">*</font>PDR Type</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={true}
              value={props.pdrType}
              onChange={(v)=>props.updateState({pdrType:v})}
              options={[
                {label:"",value:""},
                {label:"ACE+ PDR",value:"ACE+ PDR"},
                {label:"Hybrid",value:"Hybrid"},
              ]}
            />
          </Col>
          <Col sm="3">
            <label><font color="red">*</font>Data Set Version</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={true}
              value={props.dataSetVersion}
              onChange={(v)=>props.updateState({dataSetVersion:v})}
              options={[
                {label:"",value:""},
                {label:"2.0",value:"2.0"}
              ]}
            />
          </Col>
          <Col sm="3">
            <label>{props.assignmentType==='Purchase'?<font color="red">*</font>:null}Date Of Contract</label><br/>
            <DatePicker inputStyle={props.disabled?disabledStyling:{}} disabled={props.disabled}
              required={props.assignmentType==='Purchase'}
              className="form-control"
              dateFormat="yyyy/MM/dd"
              selected={dateOfContract}
              onChange={(text)=>{(text)&&props.updateState({dateOfContract:text.toLocaleDateString('en-CA')})}}
            />
          </Col>
          <Col sm="3">
          </Col>
        </Row>
      </CardBody>
    </Card>
  </div>;
}


export default StepLoanInformation;
