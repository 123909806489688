//Author Sooyoung Kim
//Date April 25, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, deleteAPICallGenerator, callBackGenerator, confirmation, formatDate} from '../../util/util';
import {Button, Col, Row, Input, Modal, ModalBody,ModalHeader, Table, Card, CardHeader, CardBody} from 'reactstrap';
import {NavLink} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';


//initialize the state
const initialState = {
  templateTags:[],
  templates:[],
  newTemplateName:'',
  newTemplateText:'',
  addNewTemplateDropDownOpen:false,
};

//reducer function that perform state update
const reducer = getReducer();


const GlobalCommentTemplates  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getTemplates();
    getTemplateTags();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const toggleAddNewTemplateDropDown = () =>{
    setState({addNewTemplateDropDownOpen:!state.addNewTemplateDropDownOpen});
  }

  const addTag = (e,tag) =>{
    e.preventDefault();
    setState({newTemplateText:state.newTemplateText+' [:'+tag+']'});
  }

  //API call
  const deleteTemplate = (id) =>{
    let url = 'template/global/'+id;
    let successMsg = 'Template deleted successfully.';
    let errorMsg = 'Oops, something went wrong and could not delete the template. Please try again later.';

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;

      if(code==='00'){
        let templates = state.templates.slice();

        for(let i=0;i<templates.length;i++){
          if(templates[i].ID===id){
            templates.splice(i,1);
            break;
          }
        }

        setState({templates:templates});
      }
    };
    callBack = callBack.bind(this);

    httpDelete(url,successMsg,errorMsg, callBack);
  }

  const getTemplateTags = () =>{
    let url = 'template/tag/get';
    let callBack = apiCallBack([{state:'templateTags', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load tags for template. Please try again later.', callBack);
  }

  const addNewTemplate = (e) =>{
    e.preventDefault();

    let url = 'template/global/create';
    let successMsg = 'Template created successfully.';
    let errorMsg = 'Oops, something went wrong and could not create the template. Please try again later.';

    let parameters = [
      {
        field:'name',
        value:state.newTemplateName
      },
      {
        field:'template',
        value:state.newTemplateText
      }
    ];

    let callBack = (response)=>{
      let code= response.data.code;

      if(code==='00'){
        let templates = state.templates.slice();
        templates.push(response.data.data);

        setState({templates:templates});
        toggleAddNewTemplateDropDown();
      }
    };

    callBack = callBack.bind(this);

    httpPost(url,parameters,successMsg,errorMsg, callBack);
  }

  const getTemplates = () =>{
    let url = 'template/global/get';
    let callBack = apiCallBack([{state:'templates', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load global comment templates. Please try again later.', callBack);
  }

  //render
  let templateTags;

  if(state.templateTags.length>0){
    templateTags = state.templateTags.map(
      (tag, index)=>{
        return(
          <div key={index} className="display-inline template-tag" onClick={(e)=>{addTag(e,tag.tag);return false;}}>
            {tag.tag}
          </div>
        );
      }
    );
  }

  let templates;

  if(state.templates.length>0){
    templates = state.templates.map(
      (template, index)=>{
        return(
          <tr key={index}>
            <td width="80%"><i className="fa fa-trash red-color cursor-pointer" onClick={
              ()=>{
                confirmation(
                  ()=>{deleteTemplate(template.ID)},
                  ()=>{},
                  'Delete template?',
                  'Are you sure you want to delete this template?');
              }
            }></i>&nbsp;&nbsp;<NavLink to={"/global-comment-template/"+template.ID}>{template.name}</NavLink></td><td><div className="align-right">{formatDate(template.datetime_created)}</div></td>
          </tr>
        )
      }
    );
  }

  return <div className="my-well">
    <Card>
      <CardHeader>
        <Row>
          <Col sm="8">
            <i className="fa fa-edit"></i>&nbsp;Global Comment Templates
          </Col>
          <Col sm="4">
            <div className="align-right">
              <div className="display-inline cursor-pointer" onClick={toggleAddNewTemplateDropDown}>
                <i className="fa fa-plus green-color"></i> Add new template
              </div>
            </div>
            <Modal className="my-modal" isOpen={state.addNewTemplateDropDownOpen} toggle={toggleAddNewTemplateDropDown} >
              <ModalHeader hidden={true} toggle={toggleAddNewTemplateDropDown}></ModalHeader>
              <ModalBody>
                <center>
                  <h5><i className="fa fa-plus"></i> Add new template</h5>
                </center>
                <br/>
                <form onSubmit={addNewTemplate}>
                  <label><font color="red">*</font>Name</label>
                  <Input required={true} type="text" value={state.newTemplateName} onChange={(e)=>setState({newTemplateName:e.target.value})}/>

                  <label><font color="red">*</font>Template</label>
                  <Input required={true} type="textarea" rows="10" style={{resize:'none'}} value={state.newTemplateText} onChange={(e)=>setState({newTemplateText:e.target.value})}/>
                  <br/>
                  <b>Tags:</b> {templateTags}
                  <br/>
                  <center>
                    <Button color="warning"><i className="green-color fa fa-plus"></i>&nbsp;Add</Button>&nbsp;
                    <Button color="info" onClick={toggleAddNewTemplateDropDown}>Close</Button>
                  </center>
                </form>
              </ModalBody>
            </Modal>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <div className="medium-scroll-container">
          <Table className="table table-striped">
            <tbody>
              {templates}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  </div>;
}

export default GlobalCommentTemplates;
