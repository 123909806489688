//Author June Leow
//Date Mar 11th, 2024
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, deleteAPICallGenerator, callBackGenerator, sliceFromArray, confirmation} from '../../util/util';
import {Button, Col, Row, Table, Input, Card, CardBody, CardHeader} from 'reactstrap';
import MySelect from '../util/my-select';
import {NavLink} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';
//initialize the state
const initialState = {
  blackList:[],
  newBlackListConditionRuleSelected:'',
  newBlackListConditionField:'',
  newBlackListConditionOperator:'=',
  newBlackListConditionDescription:'',
  newBlackListConditionValues:[],
  newBlackListConditionValuesT:'',
  newBlackListConditionValuesS:'',
  newBlackListName:'',
  fieldsEnum:[]
};

//reducer function that perform state update
const reducer = getReducer();


const CRM  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getBlacklist();
    getRuleFieldEnum();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const blackListValueOnChange = (v)=>{
    setState({newBlackListConditionValuesT:v});
  }

  const blackListFieldOnChange = (v)=>{
    for(let i=0;i<state.fieldsEnum.length;i++){
      if(state.fieldsEnum[i].field===v){
        setState({newBlackListConditionValues:state.fieldsEnum[i].enum});
        break;
      }
    }
  }

  //API call
  const addNewBlackListCondition = () =>{
    let url = 'crm/blackList/condition/create';
    let successMsg = 'CRM rule condition created successfully.';
    let errorMsg = 'Oops, something went wrong and could not create the rule condition. Please try again later.';

    
    let select = true;

    for(let i=0;i<state.fieldsEnum.length;i++){
      //there is no drop down, the value should coming from text instead
      if(state.fieldsEnum[i].field===state.newBlackListConditionField){
        if(state.fieldsEnum[i].enum.length<=0)
          select = false;
      }
    }

    let value;

    if(select)
      value = state.newBlackListConditionValuesS;
    else
      value = state.newBlackListConditionValuesT;


    let parameters = [
      {
        field:'description',
        value:state.newBlackListConditionDescription
      },
      {
        field:'field',
        value:state.newBlackListConditionField
      },
      {
        field:'operator',
        value:state.newBlackListConditionOperator
      },
      {
        field:'value',
        value:value
      },
      {
        field:'ruleFk',
        value:state.newBlackListConditionRuleSelected
      },
    ];

    let callBack = (response)=>{
      let code= response.data.code;

      if(code==='00'){
        let list = state.blackList.slice();

        for(let i=0;i<list.length;i++){
          if(list[i].ID===state.newBlackListConditionRuleSelected){
            list[i].conditions.push(response.data.data);
          }
        }

        setState({blackList:list});
      }
    };
    callBack = callBack.bind(this);

    httpPost(url, parameters, successMsg, errorMsg, callBack);
  }

  const addNewBlackList = () =>{
    let url = 'crm/blackList/create';
    let successMsg = 'CRM rule created successfully.';
    let errorMsg = 'Oops, something went wrong and could not create the rule. Please try again later.';

    let parameters = [
      {
        field:'description',
        value:state.newBlackListName
      },
    ];

    let callBack = (response)=>{
      let code= response.data.code;

      if(code==='00'){
        let list = state.blackList.slice();
        list.push(response.data.data);
        setState({blackList:list,newBlackListName:''});
      }
    };
    callBack = callBack.bind(this);

    httpPost(url, parameters, successMsg, errorMsg, callBack);
  }

  const deleteBlackListRule = (id) =>{
    let url = 'crm/blackList/'+id;
    let successMsg = 'Black list rule deleted successfull.';
    let errorMsg = 'Oops, something went wrong and could not delete the rule. Please try again later.';

    let callBack = (response)=>{
      let code= response.data.code;
      if(code==='00'){
        let blackList = sliceFromArray(state.blackList, 'ID', id);
        setState({blackList:blackList});
      }
    };

    callBack = callBack.bind(this);

    httpDelete(url,successMsg,errorMsg, callBack);
  }

  const deleteBlackListCondition = (id, conditionID) =>{
    let url = 'crm/blackList/condition/'+conditionID
    let successMsg = 'CRM condition deleted successfull.';
    let errorMsg = 'Oops, something went wrong and could not delete the condition. Please try again later.';

    let callBack = (response)=>{
      let code= response.data.code;
      if(code==='00'){
        let blackList = sliceFromArray(state.blackList, ['ID','ID'],[id, conditionID], ['conditions']);
        setState({blackList:blackList});
      }
    };

    callBack = callBack.bind(this);

    httpDelete(url,successMsg,errorMsg, callBack);
  }

  const getBlacklist = ()=>{
    let url = 'crm/blackList/rule/get';
    let callBack = apiCallBack([{state:'blackList',key:'data'}]);
    httpGet(url, '','Oops, something went wrong and could not load the CRM rule. Please try again later.', callBack);
  }

  const getRuleFieldEnum = ()=>{
    let url = 'autoAssign/rules/field/value';
    let callBack = apiCallBack([{state:'fieldsEnum',key:'data'}]);
    httpGet(url, '','Oops, something went wrong and could not load the rule fields. Please try again later.', callBack);
  }


  //render
  let blackLists, valueWidget;

    if(state.newBlackListConditionValues!==undefined&&state.newBlackListConditionValues.length>0 && state.newBlackListConditionOperator!=='like')
      valueWidget = <MySelect
        type="select"
        required={true}
        selectIsClearable={true}
        value={state.newBlackListConditionValuesS}
        onChange={(v)=>{blackListValueOnChange(v);setState({newBlackListConditionValuesS:v})}}
        options={state.newBlackListConditionValues.map((value)=>{
          return {label:value.label, value:value.value};
        })}
      />;
    else
      valueWidget = <Input required={true} type="text" onChange={(e)=>setState({newBlackListConditionValuesT:e.target.value})} value={state.newBlackListConditionValuesT}/>;

    blackLists = state.blackList.map(
      (list, index)=>{

        let conditions;

        conditions = list.conditions.map(
          (condition,index2)=>{
            return(
              <tr key={index2}>
                <td><i className="fa fa-times red-color cursor-pointer" onClick={()=>deleteBlackListCondition(list.ID, condition.ID)}></i> {condition.description}</td>
                <td>{condition.field}</td>
                <td>{condition.operator}</td>
                <td>{condition.value}</td>
              </tr>
            );
          }
        );

        return(
          <div key={index}>
            <Card>
              <CardHeader className="header-color">
                <i className="fa fa-times red-color cursor-pointer" onClick={
                  ()=>{
                    confirmation(
                      ()=>{deleteBlackListRule(list.ID)},
                      ()=>{},
                      'Delete auto assign rule?',
                      'Are you sure you want to delete this rule? You will lose all conditions that associated with this rule.');
                  }
                }></i> {list.description}
              </CardHeader>
              <CardBody>
                <Table className="table table-striped">
                  <thead>
                    <tr>
                      <th width="35%">Description</th>
                      <th width="15%">Field</th>
                      <th width="25%">Operator</th>
                      <th width="25%">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {conditions}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
            <br/>
          </div>
        );
      }
    );
        

  return <div className="my-well">
    <Row>
      <Col sm="6">
        <div className="page-title">
          <i className="fa fa-reorder"></i>&nbsp;Customer Relationship Management (CRM)
        </div>
        <NavLink to="/system-setting">Back to settings</NavLink>
      </Col>
      <Col sm="6">

      </Col>
    </Row>
    <b>
      <font color="red">**</font> Configure a list of rules with condition to flag any incoming order into the CRM. They will stay in the CRM list until removed by ops/ when order is completed. <br/>
      If there are condition requested, the order will be put back into the CRM list again until removed manually/shipped again.
    </b>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;CRM Rule
      </CardHeader>
      <CardBody>
        {blackLists}
        <br/>
        <div className="my-divider"></div>

        <i className="fa fa-plus green-color"></i> Add CRM rules<br/>
        <label>Rule name</label>
        <Row>
          <Col sm="4">
            <Input type="text" value={state.newBlackListName} onChange={(e)=>setState({newBlackListName:e.target.value})} />
          </Col>
          <Col sm="8">
            <Button color="warning" onClick={addNewBlackList}>Create rule</Button>
          </Col>
        </Row>
        <div className="my-divider"></div>
        <br/>

        <form onSubmit={(e)=>{e.preventDefault();addNewBlackListCondition()}}>
          <Row>
            <Col sm="6">
              <label><font className="red-color">*</font>Associated rule</label>
              <MySelect
                type="select"
                required={true}
                value={state.newBlackListConditionRuleSelected}
                onChange={(v)=>{setState({newBlackListConditionRuleSelected:v})}}
                options={[{label:"Select a rule",value:""}].concat(
                  state.blackList.map((list)=>{
                    return {label:list.description, value:list.ID};
                    })
                )}
              />
            </Col>
            <Col sm="6">
              <label>Description</label>
              <Input type="text" onChange={(e)=>setState({newBlackListConditionDescription:e.target.value})} value={state.newBlackListConditionDescription}></Input>
            </Col>
          </Row>

          <Row>
            <Col sm="4">
              <label><font className="red-color">*</font>Field name</label>
              <MySelect
                type="select"
                required={true}
                value={state.newBlackListConditionField}
                onChange={(v)=>{setState({newBlackListConditionField:v});blackListFieldOnChange(v);}}
                options={[{label:"Select a field",value:""}].concat(
                  state.fieldsEnum.map((field)=>{
                    return {label:field.label, value:field.field};
                    })
                )}
              />
            </Col>
            <Col sm="4">
              <label><font className="red-color">*</font>Operator</label>
              <MySelect
                type="select"
                required={true}
                value={state.newBlackListConditionOperator}
                onChange={(v)=>{setState({newBlackListConditionOperator:v})}}
                options={[{label:"Equal to",value:"="},{label:"Not equal to",value:"!="},{label:"Like",value:"like"}]}
              />
            </Col>
            <Col sm="4">
              <label><font className="red-color">*</font>Value</label>
              {valueWidget}
            </Col>
          </Row>
          <br/>
          <div className="align-right"><Button color="warning">Create condition</Button></div>
        </form>
      </CardBody>
    </Card>
  </div>;
}


export default CRM;
