//Author Sooyoung Kim
//Date April 28, 2023
import {getReducer, getSetStateFunction, postAPICallGenerator} from '../../util/util';
import { Button, Input, Row, Col } from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import MySelect from '../util/my-select';


//initialize the state
const initialState = {
  company:'',
  street:'',
  city:'',
  county:'',
  state:'',
  zip:'',
  phone:'',
  email:'',
  notify_email:'',
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
};

//reducer function that perform state update
const reducer = getReducer();


const NewAppraiserFirm  = (props)=>{
  const controller = new AbortController();
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{


    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  //API call
  //create new appraiser firm
  const createAppraiserFirm = ()=>{
    let url = 'appraiser/firm/create';
    let successMsg = 'Appraiser firm created successfully.';
    let errorMsg = 'Oops, something went wrong and could not create this appraiser firm. Please try again later.';

    let parameters = [
      {
        field:'company',
        value:state.company
      },
      {
        field:'email',
        value:state.email
      },
      {
        field:'phone',
        value:state.phone
      },
      {
        field:'notify_email',
        value:state.notify_email
      },
      {
        field:'street',
        value:state.street
      },
      {
        field:'city',
        value:state.city
      },
      {
        field:'county',
        value:state.county
      },
      {
        field:'state',
        value:state.state
      },
      {
        field:'zip',
        value:state.zip
      }
    ];

    //call back for creating new announcement
    let callBack = (response) => {
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        navigate('/appraiser-firm');
      }
    };
    callBack = callBack.bind(this);

    httpPost(url,parameters,successMsg,errorMsg, callBack);
  }

  //render
  return <div className="card padding">
    <Row>
      <Col>
        <div className="page-title">
          <i className="fa fa-reorder"></i>&nbsp;New Appraiser Firm
        </div>
  			<NavLink to="/appraiser-firm">Back to appraiser firm list</NavLink>
      </Col>
      <Col className="align-right">
        <Button color="warning" onClick={()=>{createAppraiserFirm()}}><i className="fa fa-plus"></i>&nbsp;&nbsp;Create</Button>
      </Col>
    </Row>
    <div className="hr"/>

    <br/>
    <div className="form-group">
  		<label>Company Name: </label>
  		<Input value={state.company} onChange={(text)=>{setState({company:text.target.value})}}/>
  	</div>

    <Row>
      <Col sm="4">
        <div className="form-group">
  				<label>Email: </label>
  				<Input value={state.email} onChange={(text)=>setState({email:text.target.value})}/>
  			</div>
      </Col>
      <Col sm="4">
        <div className="form-group">
  				<label>Phone: </label>
  				<Input value={state.phone} onChange={(text)=>setState({phone:text.target.value})}/>
  			</div>
      </Col>
      <Col sm="4">
        <div className="form-group">
  				<label>Notify Email: </label>
  				<Input value={state.notify_email} onChange={(text)=>setState({notify_email:text.target.value})}/>
  			</div>
      </Col>
    </Row>

    <Row>
      <Col sm="4">
        <div className="form-group">
  				<label>Street: </label>
  				<Input value={state.street} onChange={(text)=>setState({street:text.target.value})}/>
  			</div>
      </Col>
      <Col sm="4">
        <div className="form-group">
  				<label>City: </label>
  				<Input value={state.city} onChange={(text)=>setState({city:text.target.value})}/>
  			</div>
      </Col>
      <Col sm="4">
        <div className="form-group">
  				<label>County: </label>
  				<Input value={state.county} onChange={(text)=>setState({county:text.target.value})}/>
  			</div>
      </Col>
    </Row>

    <Row>
      <Col sm="8">
        <div className="form-group">
          <label>State:</label>
          <MySelect
            type="select"
            value={state.state}
            onChange={(v)=>setState({state:v})}
            options={state.states.map((state)=>{
              return {label:state.value, value:state.value};
            })}
          />
  			</div>
      </Col>
      <Col sm="4">
        <div className="form-group">
  				<label>Zip: </label>
  				<Input value={state.zip} onChange={(text)=>setState({zip:text.target.value})}/>
  			</div>
      </Col>
    </Row>
  </div>;
}

export default NewAppraiserFirm;
