//Author Sooyoung Kim
//Date April 19, 2023
import {getReducer} from '../../util/util';
import React, {useReducer, useEffect} from 'react';
import {Col, Row, Table} from 'reactstrap';
import {NavLink} from 'react-router-dom';

let role = localStorage.getItem('role');

//initialize the state
const initialState = {
  role:role
};

//reducer function that perform state update
const reducer = getReducer();


const SystemSetting  = (props)=>{

  const [state, ] = useReducer(reducer,initialState);

  //run only once when component is loaded
  useEffect(()=>{
    const controller = new AbortController();
    return ()=> controller.abort();
  },[]);

  //non API call but simpyl manage state

  //API call

  //render
  let appraiserFee;

  if(state.role==='super_admin'){
    appraiserFee = <tr>
      <td>
        <NavLink to="/system-setting/appraiser-fee">
          Appraiser fee
        </NavLink>
        <br/>
        Configure the default appraiser fee for each report type
      </td>
    </tr>
  }

  return <div>
    <div className="my-well">
      <Row>
        <Col sm="6">
          <div className="page-title">
            <i className="fa fa-reorder"></i>&nbsp;System Settings
          </div>
        </Col>
        <Col sm="6">

        </Col>
      </Row>
      <div className="my-divider"></div>
      <Table className="table table-striped">
        <tbody>
          <tr>
            <td>
              <NavLink to="/system-setting/fee">
                Appraisal fee
              </NavLink>
              <br/>
              Configure the fee for each report type
            </td>
          </tr>
          {appraiserFee}
          <tr>
            <td>
              <NavLink to="/system-setting/emails">
                Email list
              </NavLink>
              <br/>
              View a list of all emails generated by the system.
            </td>
          </tr>
          <tr>
            <td>
              <NavLink to="/system-setting/account-manager">
                Account Manager
              </NavLink>
              <br/>
              Distribute order among account managers.
            </td>
          </tr>
          <tr>
            <td>
              <NavLink to="/system-setting/auto-assign">
                Auto assign
              </NavLink>
              <br/>
              Configure settings for auto assign - turn on and off auto assign, setup black list, enable and disable certain order for auto assign etc.
            </td>
          </tr>
          <tr>
            <td>
              <NavLink to="/system-setting/auto-condition">
                Auto condition
              </NavLink>
              <br/>
              Configure settings for auto condition - turn on and off auto condition, disable auto condition for certain order or client etc.
            </td>
          </tr>
          <tr>
            <td>
              <NavLink to="/system-setting/auto-shipping">
                Auto shipping & review
              </NavLink>
              <br/>
              Configure settings for auto review & auto shipping.
            </td>
          </tr>
          <tr>
            <td>
              <NavLink to="/system-setting/auto-bid">
                Auto Bid
              </NavLink>
              <br/>
              Configure settings for auto bid - turn on and off auto bid, setup black list, enable and disable certain order for auto bid etc.
            </td>
          </tr>
          <tr>
              <td>
                <NavLink to="/system-setting/crm">
                  CRM
                </NavLink>
                <br/>
                Configure settings for automatically flag order into customer relationship management (CRM)
              </td>
            </tr>
            <tr>
              <td>
                <NavLink to="/system-setting/order-screening">
                  Order Screening
                </NavLink>
                <br/>
                  An automatic screening process that the system cross check several facts ahead of bidding/assigning. 
              </td>
            </tr>
          <tr>
            <td>
              <NavLink to="/system-setting/integrated-platform">
                Integrated Platform List
              </NavLink>
              <br/>
                View a list of all Integrated platforms with details(link, contact, note, and etc.).
            </td>
          </tr>
          <tr>
            <td>
              <NavLink to="/system-setting/website-list">
                Website List
              </NavLink>
              <br/>
                View a list of all websites with details(link, contact, note, and etc.).
            </td>
          </tr>
          <tr>
            <td>
              <NavLink to="/system-setting/client-profile-checklist">
                Client OnBoard Checklist
              </NavLink>
              <br/>
                Configure a list of checklist for onboarding new client. These checklist would show up in client's profile.
            </td>
          </tr>
          <tr>
            <td>
              <NavLink to="/system-setting/global-wholesale-client">
                Global Approved Wholesale Client
              </NavLink>
              <br/>
                Configure a list of global approved wholesale client that can be seen on the drop down of all client when they place a wholesale order.
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  </div>;
}

export default SystemSetting;
