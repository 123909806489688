//Author Sooyoung Kim
//Date Aug 31, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, callBackGenerator, showMessage, hideMessage, getSession} from '../../util/util';
import {Card, CardHeader, CardBody, Col, Row, Input, Button, Nav, NavItem, FormGroup, Label} from 'reactstrap';
import MyDropzone from '../util/my-dropzone';
import { geocodeByAddress } from 'react-places-autocomplete';
import MyPlacesAutocomplete from '../util/my-places-autocomplete';
import moment from 'moment';
import {NavLink, useParams} from 'react-router-dom';
import MySelect from '../util/my-select';
import Signature from './signature';
import DatePicker from 'react-datepicker';
import React, {useReducer, useEffect} from 'react';

let session = getSession();

//initialize the state
const initialState = {
  name: session.userFirstName+' '+session.userLastName,
  states:[
    {key:'Northern Mariana Islands',value:'Northern Mariana Islands'},{key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
  clients:[],
  originalClientFk:'',
  originalPropertyDataCollectorName:'',
  originalPropertyDataCollectorCompany:'',

  pdrFileIdentifier:'',
  additionalFileIdentifier:'',
  clientCaseNumber:'',

  propertyStreet:'',
  propertyCity:'',
  propertyCounty:'',
  propertyState:'',
  propertyZip:'',
  borrower:'',
  datetimeOriginalPDREffective:'',
  propertyDataCollectorName:'',
  propertyDataCollectorCompany:'',
  propertyDataCollectorCompanyStreet:'',
  propertyDataCollectorCompanyCity:'',
  propertyDataCollectorCompanyCounty:'',
  propertyDataCollectorCompanyPropertyCounty:'',
  propertyDataCollectorCompanyPropertyStreet:'',
  propertyDataCollectorCompanyState:'',
  propertyDataCollectorCompanyZip:'',
  propertyDataCollectorPhone:'',
  professionalLicenseType:'',
  professionalLicenseNumber:'',
  professionalLicenseState:'',
  professionalLicenseExpDate:'',
  clientFk:'',
  hasPCR:undefined,
  showConfirmation:false,

  improvementFullfill:'',
  comment:'',
  signature:{},
  certified:false,
  datetimeVerification:'',
  photos:[],

  originalPdr:{}
};

//reducer function that perform state update
const reducer = getReducer();


const PostCompletionReport  = (props)=>{
  const controller = new AbortController();
  let {pdrFk} = useParams();

  let newInitialState = Object.assign({}, initialState, {pdrFk:pdrFk});
  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  
  //run only once when component is loaded
  useEffect(()=>{
    getReport();
    getPCRReport();
    getAllClientProfiles();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const uploadPhotos = async () => {
    let photos = state.photos.slice();

    let callCounter= 0;
    for(let i=0;i<photos.length;i++){
      if(!photos[i].ID || photos[i].ID===-1){

        // eslint-disable-next-line no-loop-func
        let updateCallBack = (ID)=>{
          callCounter++;
          photos[i].ID = ID;
          if(callCounter>=photos.length){
            setState({photos:photos});
          }
        }

        await uploadPhoto(photos[i], updateCallBack);
      }
    }
  }

  //function trigger for dropzone react.
  //this function contians two list of files, the accepted and rejected file per the configuration
  const onDrop = (acceptedFiles, rejectedFiles, type='Misc', typeFk=0, description='') => {
    let existingFiles = [];
    if(acceptedFiles.length>0){
      for(let i=0;i<acceptedFiles.length;i++){
        existingFiles.push(acceptedFiles[i]);
        let name = existingFiles[i].name;
        let token = name.split('.');
        let extension = '';

        if(token.length>0){
          extension = token[token.length-1];
          extension = extension.toLowerCase();
        }

        if(extension!=='png'&&extension!=='jpg'&&extension!=='jpeg'){
          showMessage('error','Please upload only PNG/JPG image.');
        }
        else{
          const reader = new FileReader();
          reader.onload = () => {
              const fileAsBinaryString = reader.result;
              let base64 = btoa(fileAsBinaryString);
              let photos = state.photos.slice();

              photos.push({name:acceptedFiles[i].name, base64:base64, status:'Pending', size:acceptedFiles[i].size, type:type, typeFk:typeFk, description:description});
              setState({photos:photos});
          }
          reader.readAsBinaryString(acceptedFiles[i]);
        }
      }
    }
  }

  const googlePlaceOnChange = (field='') => {
    return (address)=>{
      let stateAddress;
      if(field!=='')
        stateAddress = state[field+'Address'];
      else
        stateAddress = state.address;
      if(address!==stateAddress){

        if(address.indexOf(',')!==-1 && address.length>10){
          geocodeByAddress(address)
            .then(
              results => {
                //always use the first returned result
                let targetResult = results[0];

                let addressComponents = targetResult.address_components;

                let streetNumber = '';
                let route = '';
                let city = '';
                let county = '';
                let stateName = '';
                let zip = '';

                //go through the address components and grab the street, city, county, state, and zip
                for(let i=0;i<addressComponents.length;i++){
                  if(addressComponents[i].types[0]==='street_number')
                    streetNumber = addressComponents[i].long_name;
                  else if(addressComponents[i].types[0]==='route')
                    route = addressComponents[i].long_name;
                  else if(addressComponents[i].types[0]==='locality'&&addressComponents[i].types[1]==='political')
                    city = addressComponents[i].long_name;
                  else if(addressComponents[i].types[0]==='administrative_area_level_2'&&addressComponents[i].types[1]==='political')
                    county = addressComponents[i].long_name;
                  else if(addressComponents[i].types[0]==='administrative_area_level_1'&&addressComponents[i].types[1]==='political')
                    stateName = addressComponents[i].long_name;
                  else if(addressComponents[i].types[0]==='postal_code')
                    zip = addressComponents[i].long_name;
                }


                let street = '';

                if(streetNumber!==''&&route!=='')
                  street = streetNumber+' '+route;

                let stateToUpdate = {};

                if(street!==''){
                  if(field===''){
                    stateToUpdate.propertyStreet = street;
                    stateToUpdate.propertyAddress = street;
                    stateToUpdate.address = street;
                  }
                  else{
                    stateToUpdate[field+'PropertyStreet'] = street;
                    stateToUpdate[field+'PropertyAddress'] = street;
                    stateToUpdate[field+'Address'] = street;
                  }

                }
                if(city!=='')
                  if(field===''){
                    stateToUpdate.propertyCity = city;
                  }
                  else{
                    stateToUpdate[field+'PropertyCity'] = city;
                  }
                if(county!=='')
                  if(field===''){
                    stateToUpdate.propertyCounty = county;
                  }
                  else{
                    stateToUpdate[field+'PropertyCounty'] = county;
                  }

                if(stateName!=='')
                  if(field===''){
                    stateToUpdate.propertyState = stateName;
                  }
                  else{
                    stateToUpdate[field+'PropertyState'] = stateName;
                  }

                if(zip!=='')
                  if(field===''){
                    stateToUpdate.propertyZip = zip;
                  }
                  else{
                    stateToUpdate[field+'PropertyZip'] = zip;
                  }
                console.log(stateToUpdate)
                setState(stateToUpdate);
              })
            .catch(
              error => {
                console.log(error);
              }
            );
        }
        else{
          if(field!==''){
            let stateToUpdate = {};
            stateToUpdate[field+'Address'] = address;
            stateToUpdate[field+'PropertyAddress'] = address;
            stateToUpdate[field+'PropertyStreet'] = address;
            console.log(stateToUpdate);
            setState(stateToUpdate);
          }
          else
            setState({address:address,propertyAddress:address, propertyStreet:address});
        }
      }
    }
  }

  const removeFile = (field,index) => {
    let photos = state.photos.slice();
    photos.splice(index, 1);
    setState({photos:photos});
  }


  //API call
  const submitPCRReport = async (e) => {
    e.preventDefault();
    let fail = false;
    if((!state.signature.draw || state.signature.draw==='' )&& (!state.signature.upload || state.signature.upload==='')){
      showMessage('error', 'Please sign the report or upload your signature with a photo.');
      fail = true;
    }

    if(!fail){
      let updateCallBack = async ()=>{
        await uploadPhotos();
        sendPCRRequest();
      }

      uploadSignature(updateCallBack);
    }
  }

  const getAllClientProfiles = () => {
    let callBack = apiCallBack([{state:'clients', valuekey:['ID', 'company']}]);
    httpGet('client/get/limit=-1&offset=-1', '', 'Oops, something went wrong and could not retrieve client profiles.', callBack);
  }

  const uploadPhoto = (photo, updateCallBack) => {
    console.log(photo);

    let parameters = [
      {
        field:'pdrFk',
        value:state.pdrFk
      },
      {
        field:'typeFk',
        value:photo.typeFk
      },
      {
        field:'type',
        value:photo.type
      },
      {
        field:'description',
        value:photo.description
      },
      {
        field:'name',
        value:photo.name
      },
      {
        field:'size',
        value:photo.size
      },
      {
        field:'uploadedFile',
        value:photo.base64
      },
      {
        field:'uploaderName',
        value:state.name
      }
    ];

    let messageID = showMessage('non-dismissable','Uploading '+photo.name+', please do not close your browser.');

    let callBack = (response)=>{
      let code= response.data.code;
      hideMessage(messageID);
      if(code==='00'){
        let data = response.data.data;
        updateCallBack(data.ID);
      }
    };
    callBack = callBack.bind(this);

    let errorCallBack = apiCallBack([], hideMessage(messageID));

    return httpPost('propertyDataReport/photo/upload', parameters, '', 'Oops, something went wrong on uploading photo and could not save the report. Please try again later.', callBack, errorCallBack);
  }

  const sendPCRRequest = () => {
    let parameters = [
      {
        field:'pdrFk',
        value:state.pdrFk
      },
      {
        field:'improvementFullfill',
        value:state.improvementFullfill
      },
      {
        field:'comment',
        value:state.comment
      },
      {
        field:'datetimeVerification',
        value:state.datetimeVerification
      },
      {
        field:'pdrFileIdentifier',
        value:state.pdrFileIdentifier
      },
      {
        field:'additionalFileIdentifier',
        value:state.additionalFileIdentifier
      },
      {
        field:'clientCaseNumber',
        value:state.clientCaseNumber
      },
      {
        field:'propertyStreet',
        value:state.propertyStreet
      },
      {
        field:'propertyCity',
        value:state.propertyCity
      },
      {
        field:'propertyCounty',
        value:state.propertyCounty
      },
      {
        field:'propertyState',
        value:state.propertyState
      },
      {
        field:'propertyZip',
        value:state.propertyZip
      },
      {
        field:'borrower',
        value:state.borrower
      },
      {
        field:'datetimeOriginalPDREffective',
        value:state.datetimeOriginalPDREffective
      },
      {
        field:'propertyDataCollectorName',
        value:state.propertyDataCollectorName
      },
      {
        field:'propertyDataCollectorCompany',
        value:state.propertyDataCollectorCompany
      },
      {
        field:'propertyDataCollectorCompanyStreet',
        value:state.propertyDataCollectorCompanyPropertyStreet
      },
      {
        field:'propertyDataCollectorCompanyCity',
        value:state.propertyDataCollectorCompanyPropertyCity
      },
      {
        field:'propertyDataCollectorCompanyCounty',
        value:state.propertyDataCollectorCompanyPropertyCounty
      },
      {
        field:'propertyDataCollectorCompanyState',
        value:state.propertyDataCollectorCompanyPropertyState
      },
      {
        field:'propertyDataCollectorCompanyZip',
        value:state.propertyDataCollectorCompanyPropertyZip
      },
      {
        field:'propertyDataCollectorPhone',
        value:state.propertyDataCollectorPhone
      },
      {
        field:'professionalLicenseType',
        value:state.professionalLicenseType
      },
      {
        field:'professionalLicenseNumber',
        value:state.professionalLicenseNumber
      },
      {
        field:'professionalLicenseState',
        value:state.professionalLicenseState
      },
      {
        field:'professionalLicenseExpDate',
        value:state.professionalLicenseExpDate
      },
      {
        field:'clientFk',
        value:state.clientFk
      },
      {
        field:'originalClientFk',
        value:state.originalClientFk
      },
      {
        field:'originalPropertyDataCollectorName',
        value:state.originalPropertyDataCollectorName
      },
      {
        field:'originalPropertyDataCollectorCompany',
        value:state.originalPropertyDataCollectorCompany
      }
    ];

    let callBack = apiCallBack([{state:'hasPCR', value:true}, {state:'showConfirmation', value:true}]);
    httpPost('propertyDataReport/pcr/create', parameters, 'PCR Request submitted successfully.', 'Oops, something went wrong and could not submit this PCR request. Please try again later.', callBack);
  }

  const uploadSignature = (updateCallBack) => {
    let base64;
    let signature = Object.assign({}, state.signature);
    if(signature.draw && signature.draw!=='')
      base64 = signature.draw;
    else
      base64 = signature.upload;

    let photo = {
      type:'PCR Signature',
      typeFk:0,
      base64:base64,
      name:'signature',
      description:'PCR Signature',
      uploaderName:state.name
    };

    let parameters = [
      {
        field:'pdrFk',
        value:state.pdrFk
      },
      {
        field:'typeFk',
        value:photo.typeFk
      },
      {
        field:'type',
        value:photo.type
      },
      {
        field:'description',
        value:photo.description
      },
      {
        field:'name',
        value:photo.name
      },
      {
        field:'size',
        value:photo.size
      },
      {
        field:'uploadedFile',
        value:photo.base64
      },
      {
        field:'uploaderName',
        value:state.name
      }
    ];

    let messageID = showMessage('non-dismissable','Uploading your signature please do not close your browser.');

    let callBack = (response)=>{
      let code= response.data.code;

      hideMessage(messageID);
      if(code==='00'){
        let data = response.data.data;
        updateCallBack(data.ID);
      }
    };
    callBack = callBack.bind(this);

    let errorCallBack = apiCallBack([], hideMessage(messageID));

    return httpPost('propertyDataReport/photo/upload', parameters, '', 'Oops, something went wrong on uploading photo and could not save the report. Please try again later.', callBack, errorCallBack);
  }

  const getReport = () => {
    let callBack = apiCallBack([{state:'originalPdr', key:'data'}, {state:'originalClientFk', key:'data.client_fk'}, {state:'clientFk', key:'data.client_fk'}]);
	  httpGet('propertyDataReport/'+state.pdrFk, '', 'Oops, something went wrong and could not load this report. Please try again later.', callBack);
  }

  const getPCRReport = () => {
    let callBack = (response)=>{
      console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        setState({hasPCR:response.data.data.length>0});
      }
    };
    callBack = callBack.bind(this);
    httpGet('propertyDataReport/pcr/'+state.pdrFk, '', 'Oops, something went wrong and could not load PCR report for this PDR. Please try again later.', callBack);
  }

  //render
  let professionalLicenseExpDate;
  if(state.professionalLicenseExpDate!==''&&state.professionalLicenseExpDate!=='0000-00-00')
    professionalLicenseExpDate = moment(state.professionalLicenseExpDate).toDate();

  let datetimeOriginalPDREffective;
  if(state.datetimeOriginalPDREffective!==''&&state.datetimeOriginalPDREffective!=='0000-00-00')
    datetimeOriginalPDREffective = moment(state.datetimeOriginalPDREffective).toDate();

  let datetimeVerification;
  if(state.datetimeVerification!==''&&state.datetimeVerification!=='0000-00-00')
    datetimeVerification = moment(state.datetimeVerification).toDate();

  let content;

  if(state.showConfirmation){
    content = <div style={{background:"#fff"}}>
      <br/><br/>
      <center>
        Your PCR request had been submitted successfully. An email will be send to you once it is ready to be download.
      </center>
      <br/>
    </div>
  }
  else if(state.hasPCR===false){
    content = <form onSubmit={submitPCRReport}>
      <Card>
        <CardHeader className="header-color">
          PCR Report
        </CardHeader>
        <CardBody>
          <Card>
            <CardHeader><i className="fa fa-file"/> Loan Info</CardHeader>
            <CardBody>
              <Row>
                <Col sm="4">
                  <label>Additional File Identifier</label>
                  <Input type="text" value={state.additionalFileIdentifier} onChange={(e)=>setState({additionalFileIdentifier:e.target.value})}/>
                </Col>
                <Col sm="4">
                  <label>Client Case Number</label>
                  <Input type="text" value={state.clientCaseNumber} onChange={(e)=>setState({clientCaseNumber:e.target.value})}/>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <br/>
          <Card>
            <CardHeader><i className="fa fa-home"/> Property & Borrower</CardHeader>
            <CardBody>
              <Row style={{marginTop:'15px'}}>
                <Col sm="5">
                  <label><font color="red">*</font>Street</label>
                  <MyPlacesAutocomplete value={state.propertyStreet} onChange={googlePlaceOnChange('')} onSelect={googlePlaceOnChange('')}/>
                </Col>
                <Col sm="3">
                  <label><font color="red">*</font>City</label>
                  <Input required={true} type="text" value={state.propertyCity} onChange={(e)=>setState({propertyCity:e.target.value})}/>
                </Col>
                <Col sm="4">
                  <label><font color="red">*</font>State</label>
                  <MySelect
                    type="select"
                    required={true}
                    value={state.propertyState}
                    onChange={(v)=>setState({propertyState:v})}
                    options={state.states.map((state)=>{
                      return {label:state.value, value:state.value};
                    })}
                  />
                </Col>
              </Row>
              <Row style={{marginTop:'15px'}}>
                <Col sm="3">
                  <label>County</label>
                  <Input type="text" disabled value={state.propertyCounty} onChange={(e)=>setState({propertyCounty:e.target.value})}/>
                </Col>
                <Col sm="2">
                  <label><font color="red">*</font>Zip</label>
                  <Input required={true} type="text" value={state.propertyZip} onChange={(e)=>setState({propertyZip:e.target.value})}/>
                </Col>
                <Col sm="4">
                  <label>Borrower</label>
                  <Input type="text" value={state.borrower} onChange={(e)=>setState({borrower:e.target.value})}/>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <br/>
          <Card>
            <CardHeader>
              <i className="fa fa-file-text"/> Original PDR
            </CardHeader>
            <CardBody>
              <Row>
                <Col sm="3">
                  <label><font color="red">*</font>Original Property Data Collector Name</label>
                  <Input required={true} type="text" value={state.originalPropertyDataCollectorName} onChange={(e)=>setState({originalPropertyDataCollectorName:e.target.value})}/>
                </Col>
                <Col sm="3">
                  <label><font color="red">*</font>Original Property Data Collector Company Name</label>
                  <Input required={true} type="text" value={state.originalPropertyDataCollectorCompany} onChange={(e)=>setState({originalPropertyDataCollectorCompany:e.target.value})}/>
                </Col>
                <Col sm="3">
                  <label><font color="red">*</font>Original Lender</label>
                  <MySelect
                    type="select"
                    required={true}
                    value={state.originalClientFk}
                    onChange={(v)=>setState({originalClientFk:v})}
                    options={state.clients.map((client)=>{
                      return {label:client.value, value:client.key};
                    })}
                  />
                </Col>
                <Col sm="3">
                  <label><font color="red">*</font>Original PDR Effective Date</label><br/>
                  <DatePicker
                    required={true}
                    className="form-control"
                    dateFormat="yyyy/MM/dd"
                    selected={datetimeOriginalPDREffective}
                    onChange={(text)=>{(text)&&setState({datetimeOriginalPDREffective:text.toLocaleDateString('en-CA')})}}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
          <br/>
          <Card>
            <CardHeader><i className="fa fa-edit"/> This PCR</CardHeader>
            <CardBody>
              <label><font color="red">*</font>Lender</label>
              <MySelect
                type="select"
                required={true}
                value={state.clientFk}
                onChange={(v)=>setState({clientFk:v})}
                options={state.clients.map((client)=>{
                  return {label:client.value, value:client.key};
                })}
              />
              <label><font color="red">*</font>Have the improvements been completed in accordance with the requirements and conditions stated in the original property data report?</label>
              <MySelect
                type="select"
                required={true}
                value={state.improvementFullfill}
                onChange={(v)=>setState({improvementFullfill:v})}
                options={[{label:"Yes",value:"True"},{label:"No",value:"False"}]}
              />
              <br/>
              <label>{state.improvementFullfill==='False'?<font color="red">*</font>:null}Comment</label>
              <Input required={state.improvementFullfill==='False'} type="textarea" rows="3" value={state.comment} onChange={(e)=>{setState({comment:e.target.value})}}/>

              <br/>
              <label><font color="red">*</font>Date Verify</label><br/>
              <DatePicker
                required={true}
                className="form-control"
                dateFormat="yyyy/MM/dd"
                selected={datetimeVerification}
                onChange={(text)=>{(text)&&setState({datetimeVerification:text.toLocaleDateString('en-CA')})}}
              />
              <br/>
              <label>Photos</label>
              <br/>
              <MyDropzone hidden={props.disabled} onDrop={(accepted, rejected)=>{onDrop(accepted, rejected, 'Post Completion', 0)}} noIcon={true}/>
              {
                state.photos.map(
                  (photo, index)=>{
                    return <div key={index} className="display-inline" style={{width:200, height:250, margin:5}}>
                      <div>
                        <i className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeFile('photos',index)}/> {photo.name.length>16?photo.name.substr(0,16)+'...':photo.name}
                      </div>
                      <img alt="pic" width="200" height="200" src={photo.status==='Pending'?"data:image/png;base64, "+photo.base64:photo.presign_url}/>
                    </div>
                  }
                )
              }
            </CardBody>
          </Card>

          <br/><br/>
          <Card>
            <CardHeader><i className="fa fa-user"/> Property Data Collector Certification</CardHeader>
            <CardBody>
              <Row>
                <Col sm="4">
                  <label><font color="red">*</font>Name</label>
                  <Input required={true} type="text" value={state.propertyDataCollectorName} onChange={(e)=>setState({propertyDataCollectorName:e.target.value})}/>
                </Col>
                <Col sm="4">
                  <label><font color="red">*</font>Phone</label>
                  <Input required={true} type="text" value={state.propertyDataCollectorPhone} onChange={(e)=>setState({propertyDataCollectorPhone:e.target.value})}/>
                </Col>
              </Row>
              <Row style={{marginTop:'15px'}}>
                <Col sm="12">
                  <label><font color="red">*</font>Company Name</label>
                  <Input required={true} type="text" value={state.propertyDataCollectorCompany} onChange={(e)=>setState({propertyDataCollectorCompany:e.target.value})}/>
                </Col>
              </Row>
              <br/>
              <label>Company Address</label>
              <Row>
                <Col sm="5">
                  <label><font color="red">*</font>Street</label>
                  <MyPlacesAutocomplete value={state.propertyDataCollectorCompanyPropertyStreet} onChange={googlePlaceOnChange('propertyDataCollectorCompany')} onSelect={googlePlaceOnChange('propertyDataCollectorCompany')}/>
                </Col>
                <Col sm="3">
                  <label><font color="red">*</font>City</label>
                  <Input required={true} type="text" value={state.propertyDataCollectorCompanyPropertyCity} onChange={(e)=>setState({propertyDataCollectorCompanyPropertyCity:e.target.value})}/>
                </Col>
                <Col sm="4">
                  <label><font color="red">*</font>State</label>
                  <MySelect
                    type="select"
                    required={true}
                    value={state.propertyDataCollectorCompanyPropertyState}
                    onChange={(v)=>setState({propertyDataCollectorCompanyPropertyState:v})}
                    options={state.states.map((stateName)=>{
                      return {label:stateName.value, value:stateName.value};
                    })}
                  />
                </Col>
              </Row>
              <Row style={{marginTop:'15px'}}>
                <Col sm="3">
                  <label>County</label>
                  <Input type="text" disabled value={state.propertyDataCollectorCompanyPropertyCounty} onChange={(e)=>setState({propertyDataCollectorCompanyPropertyCounty:e.target.value})}/>
                </Col>
                <Col sm="2">
                  <label><font color="red">*</font>Zip</label>
                  <Input required={true} type="text" value={state.propertyDataCollectorCompanyPropertyZip} onChange={(e)=>setState({propertyDataCollectorCompanyPropertyZip:e.target.value})}/>
                </Col>
              </Row>
              <br/>

              <Row>
                <Col sm="3">
                  <label>License Type</label>
                  <MySelect
                    type="select"
                    required={true}
                    value={state.professionalLicenseType}
                    onChange={(v)=>setState({professionalLicenseType:v})}
                    options={[{label:"",value:""},{label:"",value:""},{label:"Certificate",value:"Certificate"},{label:"Licensed",value:"Licensed"},{label:"General",value:"General"}]}
                  />
                </Col>
                <Col sm="3">
                  <label>License Number</label>
                  <Input type="text" value={state.professionalLicenseNumber} onChange={(e)=>setState({professionalLicenseNumber:e.target.value})}/>
                </Col>
                <Col sm="3">
                  <label>License State</label>
                  <MySelect
                    type="select"
                    required={true}
                    value={state.professionalLicenseState}
                    onChange={(v)=>setState({professionalLicenseState:v})}
                    options={state.states.map((stateName)=>{
                      return {label:stateName.value, value:stateName.value};
                    })}
                  />
                </Col>
                <Col sm="3">
                  <label>License Exp Date</label>
                  <DatePicker
                    className="form-control"
                    dateFormat="yyyy/MM/dd"
                    selected={professionalLicenseExpDate}
                    onChange={(text)=>{(text)&&setState({professionalLicenseExpDate:text.toLocaleDateString('en-CA')})}}
                  />
                </Col>
              </Row>
              <br/>
              <label><font color="red">*</font>Signature</label><br/>
              <Signature update={(type, base64)=>setState({signature:Object.assign({}, state.signature, {[type]:base64})})}/>
              <br/>
              <div className="display-inline" style={{verticalAlign:'top'}}>
                <FormGroup check>
                  <Label check>
                    <Input required={true} type="checkbox" value={state.certified} onChange={(e)=>setState({certified:!state.certified})}/>
                  </Label>
                </FormGroup>
              </div>
              <div className="display-inline" style={{verticalAlign:'top', fontSize:'12px'}}>
                I certify that I have performed a visual observation of the subject property to determine if the conditions or requirements stated in the original property data report have been satisfied.
              </div>
              <br/><br/>
              <div style={{fontSize:'12px'}}>
                I also certify that if this report was transmitted as an "electronic record" containing my "electronic signature," as those terms are defined in applicable federal and/or state laws (excluding audio and video recordings), or a facsimile transmission of this report containing a copy or representation of my signature, the report shall be as effective, enforceable and valid as if a paper version of this report were delivered containing my original hand written signature.
              </div>
            </CardBody>
          </Card>

          <br/><br/>
          <div className="align-right">
            <Button color="warning">Submit & Generate PCR Report</Button>
          </div>
        </CardBody>
      </Card>
    </form>;
  }
  else if(state.hasPCR===true){
    content = <div style={{background:"#fff"}}>
      <br/><br/>
      <center>
        A PCR report is already submitted. Please wait for it to be generated before creating another request.
      </center>
      <br/>
    </div>
  }


  return <div>
    <div>
      <Row>
        <Col sm="6">
          <NavLink to="/property-data-report">Back to PDR Pipeline</NavLink>
        </Col>
        <Col sm="6" className="align-right">
        </Col>
      </Row>
    </div>
    <Nav tabs>
      <NavItem>
        <NavLink className="nav-link" to={"/property-data-report/"+state.pdrFk}>ACE+ PDR (Property Data Report)</NavLink>
      </NavItem>
      <NavItem>
        <NavLink className="nav-link active" to="#">PCR (Post Completion Report)</NavLink>
      </NavItem>
      <NavItem>
        <NavLink className="nav-link" to={"/property-data-report/file/"+state.pdrFk}>Generated Report</NavLink>
      </NavItem>
      <NavItem>
        <a className="nav-link" href="https://www.smartdraw.com/" target="_blank" rel="noopener noreferrer">Floor Plan - Smart Draw</a>
      </NavItem>
      <NavItem>
        <a className="nav-link" href="https://www.cubi.casa/" target="_blank" rel="noopener noreferrer">Floor Plan - Cubi Casa</a>
      </NavItem>
    </Nav>
    <br/>

    {content}
  </div>;
}


export default PostCompletionReport;
