//Author Sooyoung Kim
//Date July 11th, 2022
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator} from '../../util/util';
import {Input, Button, Row, Col, Table, Modal, ModalHeader, ModalBody} from 'reactstrap';
import {NavLink} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';


//initialize the state
const initialState = {
  searchAppraiserResults:[],
  selectedAppraiser:{},
  search:'',
  comment:'',
  submitDisabled:false,
};

//reducer function that perform state update
const reducer = getReducer();


const AppraiserSearch  = (props)=>{
  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props);

  //run only once when component is loaded
  useEffect(()=>{

  },[]);

  useEffect(()=>{
    if(state.submitDisabled){
        props.recommend(state.selectedAppraiser.ID, state.comment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state.submitDisabled]);

  //non API call but simpyl manage state
  const selectAppraiser = (appraiser)=>{
    setState({selectedAppraiser:appraiser});
  }

  const recommendButtonHandler = ()=>{
    if(state.submitDisabled)
      return;
    setState({submitDisabled:true});
  }

  //API call
  //search appraiser
  const searchAppraiser = ()=>{
    let keywword = encodeURIComponent(state.search.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
    let url = 'appraiser/search/keyword='+keywword;
    let callBack = apiCallBack([{state:'searchAppraiserResults', key:'data'}]);
    httpGet(url, '','Oops, something went wrong and could not locate the appraiser. Please try again later.', callBack);
  }


  let searchAppraiserRows;

  if(state.searchAppraiserResults.length>0){
    searchAppraiserRows = state.searchAppraiserResults.map(
      (appraiser,index)=>{
        return(
          <tr key={index}>
            <td><i onClick={()=>selectAppraiser(appraiser)} className="fa fa-plus link-color cursor-pointer"></i></td>
            <td>{appraiser.first_name+" "+appraiser.last_name}</td>
            <td>{appraiser.email}</td>
            <td>{appraiser.city}</td>
            <td>{appraiser.county}</td>
            <td>{appraiser.state}</td>
          </tr>
        )
      }
    );
  }

  let searchContent;

  if(Object.keys(state.selectedAppraiser).length<=0){
    searchContent =
    <div>
      <Row>
        <Col sm="10">
          <Input type="text" placeholder="Search with Name/Email/Phone/Address" value={state.search} onChange={(e)=>setState({search:e.target.value})}/>
        </Col>
        <Col sm="2" className="align-right">
          <Button color="warning" onClick={searchAppraiser}><i className="fa fa-search"></i>&nbsp;Search</Button>
        </Col>
      </Row>
      <br/>
      <div className="medium-scroll-container">
        <Table className="table table-striped">
          <thead>
            <tr>
              <th width="10%">Action</th>
              <th width="25%">Name</th>
              <th width="15%">Email</th>
              <th width="15%">City</th>
              <th width="15%">County</th>
              <th width="20%">State</th>
            </tr>
          </thead>
          <tbody>
            {searchAppraiserRows}
          </tbody>
        </Table>
      </div>
    </div>;
  }
  else{
    searchContent =
    <div>
      <NavLink to="#" onClick={(e)=>selectAppraiser({})}>Back to search result</NavLink>
      <div className="my-divider"></div>
      <Row>
        <Col sm="4">
          <label>Name</label>
        </Col>
        <Col sm="8">
          {state.selectedAppraiser.first_name+' '+state.selectedAppraiser.last_name}
        </Col>
      </Row>
      <Row>
        <Col sm="4">
          <label>Email</label>
        </Col>
        <Col sm="8">
          {state.selectedAppraiser.email}
        </Col>
      </Row>
      <Row>
        <Col sm="4">
          <label>Address</label>
        </Col>
        <Col sm="8">
          {state.selectedAppraiser.street+' '+state.selectedAppraiser.city+', '+state.selectedAppraiser.state+' '+state.selectedAppraiser.zip}
        </Col>
      </Row>
      <br/>

      <Input type="textarea" placeholder="Recommendation comment..." style={{resize:'none'}} rows="5" value={state.comment} onChange={(e)=>setState({comment:e.target.value})}></Input>

      <br/>
      <div className="align-right">
        <Button color="warning" onClick={recommendButtonHandler}>Submit recommendation</Button>
      </div>
    </div>
  }

  //render
  return <div>
    <Modal className="my-modal-wide" isOpen={props.modalState} toggle={props.toggleModalCallBack} >
      <ModalHeader hidden={true} toggle={props.toggleModalCallBack}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-cogs"></i> Add Appraiser</h5>
        </center>
        <br/>
        {searchContent}
        <br/>
        <center>
          <Button color="info" onClick={props.toggleModalCallBack}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
  </div>;
}

export default AppraiserSearch;
