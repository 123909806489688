//Author Sooyoung Kim
//Date April 24, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, deleteAPICallGenerator, callBackGenerator, sliceFromArray} from '../../util/util';
import {Button, Col, Row, Table, Card, CardHeader, CardBody} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import MySelect from '../util/my-select';
import {NavLink} from 'react-router-dom';


//initialize the state
const initialState = {
  clients:[],
  globalWholesaleClients:[],
  clientFk:0
};

//reducer function that perform state update
const reducer = getReducer();


const GlobalWholesaleClient  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getAllClientProfiles();
    getAllGlobalWholesaleClients();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  //API call
  const createNewGlobalWholesaleClient = () =>{
    let url = 'client/global/wholesale/client/create';
    let successMsg = 'Global wholesale client added successfully.';
    let errorMsg = 'Oops, something went wrong and could not add this global wholesale client. Please try again later.';

    let parameters = [
      {
        field:'clientFk',
        value:state.clientFk
      }
    ];

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let globalWholesaleClients = state.globalWholesaleClients.slice();
        globalWholesaleClients.push(response.data.data);

        setState({globalWholesaleClients:globalWholesaleClients});
      }
    };
    callBack = callBack.bind(this);

    httpPost(url, parameters, successMsg, errorMsg, callBack);
  }

  const removeGlobalWholesaleClient = (id) =>{
    let url = 'client/global/wholesale/client/'+id
    let successMsg = 'Global wholesale client deleted successfull.';
    let errorMsg = 'Oops, something went wrong and could not delete the global wholesale client. Please try again later.';

    let callBack = (response)=>{
      let code= response.data.code;
      if(code==='00'){
        let globalWholesaleClients = sliceFromArray(state.globalWholesaleClients, 'ID',id);
        setState({globalWholesaleClients:globalWholesaleClients});
      }
    };

    callBack = callBack.bind(this);

    httpDelete(url,successMsg,errorMsg, callBack);
  }

  const getAllClientProfiles = () =>{
    let url = 'client/get/limit=-1&offset=-1';
    let callBack = apiCallBack([{state:'clients', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not retrieve client profiles.', callBack);
  }

  const getAllGlobalWholesaleClients = () =>{
    let url = 'client/global/wholesale/client/get';
    let callBack = apiCallBack([{state:'globalWholesaleClients', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not retrieve global wholesale client profiles.', callBack);
  }

  //render
  return <div className="my-well">
    <Row>
      <Col sm="6">
        <div className="page-title">
          <i className="fa fa-reorder"></i>&nbsp;Approved Global Wholesale Client
        </div>
        <NavLink to="/system-setting">Back to settings</NavLink>
      </Col>
      <Col sm="6">

      </Col>
    </Row>

    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Global Approved Wholesale Clients
      </CardHeader>
      <CardBody>
        <label>Add New Global Approved Wholesale Client</label><br/>
        <Row>
          <Col sm="8">
            <MySelect
              type="select"
              value={state.clientFk}
              onChange={(v)=>setState({clientFk:v})}
              options={
                state.clients.map(
                  (client)=>{
                    return {label:client.company, value:client.ID}
                  }
                )
              }
            />
          </Col>
          <Col sm="4" className="align-right">
            <Button color="warning" onClick={createNewGlobalWholesaleClient}>Add</Button>
          </Col>
        </Row>
        <Table className="table table-striped">
          <tbody>
            {
              state.globalWholesaleClients.map(
                (client, index)=>{
                  return <tr key={index}>
                    <td><i className="fa fa-times cursor-pointer" onClick={(e)=>removeGlobalWholesaleClient(client.ID)}/> {client.company}</td>
                  </tr>
                }
              )
            }
          </tbody>
        </Table>
      </CardBody>
    </Card>
  </div>;
}

export default GlobalWholesaleClient;
