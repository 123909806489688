//Author Sooyoung Kim
//Date May 11th, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, formatDate, formatNumber, encodeParam} from '../../util/util';
import {Button, Row, Col,Input} from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import React, {useReducer, useEffect} from 'react';
import {NavLink, useNavigate } from 'react-router-dom';
import './client.css';


//initialize the state
const initialState = {
  clients:[],
  hasMoreClients:true,
  limit: 25,
  offset: 0,
  loading: false,
  sort: 'ID',
  order: 'ASC',
  name:'',
  email:'',
  totalCount:0
};

//reducer function that perform state update
const reducer = getReducer();


const DelegatorClients  = (props)=>{
  const controller = new AbortController();
  let history = useNavigate();
  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    if(state.clients.length<=0 && state.hasMoreClients){
        loadMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state]);

  useEffect(()=>{


    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  //this function refresh the list of orders pipeline
  const refreshList = ()=>{
    setState({clients:[],hasMoreClients:true,offset:0});
  }

  //click handler that switch the state of sorting order then call the function to refresh the list
  //to achieve the "sorting"
  const columnClickHandler = (col)=>{
    if(state.sort===col){
      if(state.order==='ASC')
        setState({order:'DESC'});
      else
        setState({order:'ASC'});
    }
    else
      setState({order:'ASC', sort:col});
    refreshList();
  }

  //this function render the sort icon at each column of the table
  const renderSortIcon = (col)=>{
    if(state.sort===col){
      if(state.order==='ASC')
        return <i className="red-color fa fa-arrow-down"></i>
      else
        return <i className="red-color fa fa-arrow-up"></i>
    }
  }

  //render function for infinite scroller
  const renderClients = ()=>{
    return (
      <div>
        <table className="primary-table">
          <thead>
            <tr>
              <th className="cursor-pointer" width="20%" onClick={()=>columnClickHandler('first_name')}><i className="fa fa-user"></i>&nbsp;First Name {renderSortIcon('first_name')}</th>
              <th className="cursor-pointer" width="20%" onClick={()=>columnClickHandler('last_name')}><i className="fa fa-user"></i>&nbsp;Last Name {renderSortIcon('last_name')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('status')}><i className="fa fa-info"></i>&nbsp;Status {renderSortIcon('status')}</th>
              <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('email')}><i className="fa fa-envelope-open"></i>&nbsp;Email {renderSortIcon('email')}</th>
              <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('phone')}><i className="fa fa-phone"></i>&nbsp;Phone {renderSortIcon('phone')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('datetime_created')}><i className="fa fa-calendar-o"></i>&nbsp;Date Created {renderSortIcon('datetime_created')}</th>
            </tr>
          </thead>
          <tbody>
          {
            state.clients.map(
              (client)=>{
                return (
                  <tr key={client.ID} onClick={()=>history("/client/delegator/"+client.ID)}>
                    <td>{client.first_name}</td>
                    <td>{client.last_name}</td>
                    <td>{client.status}</td>
                    <td>{client.email}</td>
                    <td>{client.phone}</td>
                    <td>{formatDate(client.datetime_created)}</td>
                  </tr>
                );
              }
            )
          }
          </tbody>
        </table>
      </div>
    )
  }

  //API call
  //function that fire when the infinite scroll reach bottom
  const loadMore = ()=>{
    //do not load if there is no more conditions or it's loading data
    if(state.hasMoreClients&&!state.loading){
      //set loading equals to true so it won't fire off before we are done
      setState({loading:true});

      let name = encodeParam(state.name);
      let email = encodeParam(state.email);

      let url = 'client/delegator/get/limit='+state.limit+'&offset='+state.offset+'&name='+name+'&email='+email+'&sort='+state.sort+'&order='+state.order;

      //callback handler that update the state when http request return
      let callBack = (response)=>{
        let code = response.data?response.data.code:undefined;

        if(code!=='00'){
          setState({hasMoreClients:false});
        }
        else{
          let newClients = response.data.data;
          let hasMoreClients = true;
          let newOffset = state.offset;
          let totalCount = response.data.count;

          //if http request return empty then no more results, end of list

          if(newClients.length<=0){
            hasMoreClients = false;
          }
          else{
            //increment the offset
            newOffset = state.offset + 1;
          }

          //concat the current array of announcement
          if(state.clients.length>0){
            let temp = [...state.clients,...newClients];

            setState({clients:temp});
          }
          else
            setState({clients:newClients});
            console.log(state);
          setState({hasMoreClients:hasMoreClients,offset:newOffset,totalCount:totalCount});
        }
      };
      callBack = callBack.bind(this);

      //error handler when the http request return with error
      let errorCallBack = apiCallBack([{state:'hasMoreClients', value:false}]);

      //collect the promise and wait for it to finish performing it's task
      let promises = httpGet(url, '','Oops, something went wrong and could not load delegator clients. Please try again later.', callBack, errorCallBack);
      promises
        .then(
          function(result){
            //set loading equals to false so the function could be fire off once again
            setState({loading:false});
          }
        );
    }
  }

  //render
  return <div>
    <InfiniteScroll
      className="my-well"
      dataLength={state.clients.length} //This is important field to render the next data
      next={loadMore}
      hasMore={state.hasMoreClients}
      loader={<center>Loading more delegator clients...</center>}
      scrollableTarget="contentContainer"
    >

      <div className="page-title">
        <i className="fa fa-reorder"></i>&nbsp;Delegator Client
      </div>

      <Row>
        <Col sm="4">
          <label className="no-margin-bottom">Name</label>
          <Input className="form-control" value={state.name} type="text" onChange={(value)=>{setState({name:value.target.value})}}/>
        </Col>
        <Col sm="4">
          <label className="no-margin-bottom">Email</label>
          <Input className="form-control" value={state.email} type="text" onChange={(value)=>{setState({email:value.target.value})}}/>
        </Col>
        <Col sm="4" className="align-right valign-bottom">
          <br/>
          <Button color="warning" onClick={()=>refreshList()} >Submit</Button>
        </Col>
      </Row>
      <div className="my-divider"></div>
      <Row>
        <Col sm="6">
            <div>{formatNumber(state.totalCount)} delegator clients in queue<br/><br/></div>
        </Col>
        <Col sm="6" className="align-right">
          <NavLink to="/client/delegator/new"><i className="fa fa-plus green-color"></i> Create new delegator client</NavLink>
        </Col>
      </Row>

      {renderClients()}
    </InfiniteScroll>
  </div>;
}

export default DelegatorClients;
