//Author June Leow
//Date Nov 15th, 2023
import {getReducer, getSetStateFunction, showMessage, postAPICallGenerator, callBackGenerator} from '../../util/util';
import {Button, Input} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import imgLogo from '../../img/logo_small.png';
import imgBackgrond from '../../img/login_background.jpg'
import {NavLink} from 'react-router-dom';
import './login.css';
//initialize the state
const initialState = {
  email:'',
  errorMessage:'',
};

//reducer function that perform state update
const reducer = getReducer();


const ForgotPassword  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  //API call
  const onSubmit = (e)=>{
    e.preventDefault();
    console.log(state.email)
    if(state.email!==''){
      let url = 'session/forgotPassword';
      let errorCallBack = apiCallBack([{state:'errorMessage',key:'message'}]);
      let parameters = [
        {
          field:'email',
          value:state.email
        }
      ]
      httpPost(url, parameters, 'Password reset email sent.','Oops, something went wrong and could not recover your password. Please try again later.', ()=>{}, errorCallBack);
    }
    else
      showMessage('error', 'Please fill in your email.');
  }

  //render
  return(
    <div className="login-background" style={{
        background:`url(${imgBackgrond}) no-repeat 50% fixed`
    }}>
      <form name="loginForm" onSubmit={onSubmit}>
        <div className="login-container well padding">
          <br/>
          <center>
            <img alt="company logo" src={imgLogo} className="logo-login"/><br/>
            Admin portal - Forgot Password
          </center>
          <div className="non-empty-container">
            <p className="no-padding no-margin">{state.errorMessage}</p>
          </div>
          <div className="align-right">
            <NavLink to="/">Back to login</NavLink>
          </div>
          <div className="form-group" style={{height:'38px'}}>
            <label className="font-blue">Email:</label>
            <Input type="email" value={state.email} name="username" autoComplete="on" onChange={e => setState({email: e.target.value})} className="form-control" id="email"/>
          </div>
          <br/>
          <label className="font-blue">&nbsp;</label>
          <div className="form-group input-group" style={{height:'38px'}}>
          </div>
          <br/>
          <center>
           <Button className="form-control btn btn-blue-noshadow btn-block font-white">Recover Password</Button>
          </center>
          <br/>
        </div>
      </form>
    </div>
  );
}


export default ForgotPassword;