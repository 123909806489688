//Author June Leow
//Date Nov 15th, 2023
import React from 'react';


const MyAccountingToast  = (props)=>{
  //non API call but simpyl manage state
  const onClickHandler = ()=>{
    
  }

  //render
  return(
    <div onClick={onClickHandler} className="my-toast">
      <div><b>{props.data.address}</b></div>
      <div>{props.data.author+' '+this.props.data.description.toLowerCase()+' Please audit it.'}</div>
    </div>
  );
}


export default MyAccountingToast;
