//Author Sooyoung Kim
//Date June 27, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator} from '../../../util/util';
import {Collapse, Card, CardHeader, CardBody, Table} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import moment from 'moment';
import {NavLink} from 'react-router-dom';
import MyReactTable from '../../util/my-react-table';
import ReportFilter from '../report-filter';

let today = new Date();
let month = today.getMonth()+1;

let fromDate =new Date(moment().add(-30, 'days'));
if(month<10)
  month = '0'+month;
let from = fromDate.getFullYear()+'-'+(fromDate.getMonth()+1)+'-'+fromDate.getDate();
let to = today.getFullYear()+'-'+month+'-'+today.getDate();

//initialize the state
const initialState = {
  clients:[],
  report:[],
  client:-1,
  from:from,
  to:to,
  collapseOpenState:[],
  collapseOpenCounty:[],
  collapseOpenCity:[],
  assignAccept:'no',
  reportBased:'lo',
};

//reducer function that perform state update
const reducer = getReducer();


const RepeatAppraiserUsageReport  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getReportDetails();
    
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const handleStateCollapse = (stateName)=>{
    let collapseOpenState = state.collapseOpenState.slice();

    let index = collapseOpenState.indexOf(stateName);
    if(index!==-1)
      collapseOpenState.splice(index,1);
    else
      collapseOpenState.push(stateName);
      
    setState({collapseOpenState:collapseOpenState});
  }

  const handleCountyCollapse = (county)=>{
    let collapseOpenCounty = state.collapseOpenCounty.slice();

    let index = collapseOpenCounty.indexOf(county);
    if(index!==-1)
      collapseOpenCounty.splice(index,1);
    else
      collapseOpenCounty.push(county);

    setState({collapseOpenCounty:collapseOpenCounty});
  }

  //API call
  const getReportDetails = ()=>{
    let url = 'report/highAppraiser/usage/get/assignAccept='+state.assignAccept+'&from='+state.from+'&to='+state.to+'&client='+state.client;
    if(state.reportBased==='client')
      url = 'report/highAppraiser/client/usage/get/assignAccept='+state.assignAccept+'&from='+state.from+'&to='+state.to+'&client='+state.client;

    let callBack = apiCallBack([{state:'report', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  //render
  let from, to;
  if(state.from!=='')
    from = moment(state.from);
  if(state.to!=='')
    to = moment(state.to);


  let columns = [
    {
      id: 'appraiser',
      Header: 'Appraiser',
      accessor: d => d.appraiser,
      Cell: props=> <NavLink target="_blank" to={"/appraiser/"+props.row.original.appraiserID}>{props.row.original.appraiser}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'loanOfficer',
      Header: 'Loan Officer',
      accessor: d => d.loan_officer,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'count',
      Header: 'count',
      accessor: d => d.counter,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  if(state.reportBased==='client')
  columns = [
    {
      id: 'appraiser',
      Header: 'Appraiser',
      accessor: d => d.appraiser,
      Cell: props=> <NavLink target="_blank" to={"/appraiser/"+props.row.original.appraiserID}>{props.row.original.appraiser}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'client',
      Header: 'Client',
      accessor: d => d.client_fk,
      Cell: props=> <NavLink target="_blank" to={"/client/"+props.row.original.client_fk}>{props.row.original.company}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'count',
      Header: 'count',
      accessor: d => d.counter,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  let reportFiltersConfig = [
    {id:'from',value:from, updateFunc:(v)=>setState({from:v}), width:'2'},
    {id:'to',value:to, updateFunc:(v)=>setState({to:v}), width:'2'},
    {id:'customSelect',value:state.reportBased, updateFunc:(v)=>setState({reportBased:v}), width:'2', label:'Report Based On', clearable:false, options:[{label:"Loan Officer Repeat Usage",value:"lo"},{label:"Close By Client Repeat Usage",value:"client"}]},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'2'},
    {id:'customSelect',value:state.assignAccept, updateFunc:(v)=>setState({assignAccept:v}), width:'2', label:'Filter', clearable:false, options:[{label:"Assigned",value:"no"},{label:"Assigned & Accepted",value:"yes"}]},
    {id:'button',value:'Submit', updateFunc:getReportDetails, width:'2', className:"align-right", color:"warning"},
  ];


  return <div>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Repeating Appraiser Usage Report
      </CardHeader>
      <CardBody>
        <div style={{fontSize:'11px'}}>
          <i>
            <font color="red">***</font> This report by default is based on repeat appraiser usage on Loan Officer. If a particular order does not have loan officer info then it wont work. <br/>
            Optionally you can switch this report to based on repeat appraiser usage to based on close by client instead.
          </i>
        </div>
        <ReportFilter {...props} configs={reportFiltersConfig}/>
        <div className="my-divider">&nbsp;</div>
        {
          state.report.map(
            (stateName, index)=>{
              let stateIcon = <i className="fa fa-plus green-color"/>;

              if(state.collapseOpenState.indexOf(stateName.state)!==-1)
                stateIcon = <i className="fa fa-minus red-color"/>;

              return <div key={index}>
                <Card>
                  <CardHeader className="cursor-pointer" onClick={(e)=>handleStateCollapse(stateName.state)}>
                    {stateIcon} {stateName.state}
                  </CardHeader>
                  <Collapse isOpen={state.collapseOpenState.indexOf(stateName.state)!==-1}>
                    <div style={{padding:'5px'}}><label>List of appraisers with reoccurrence assignment</label></div>
                    <div className="padding">
                      <MyReactTable columns={columns} data={stateName.state_stats} className="table table-striped"/>
                    </div>

                    <div className="padding" style={{marginLeft:'15px'}}>
                      {
                        stateName.state_counties.map(
                          (county, index2)=>{
                            let countyIcon = <i className="fa fa-plus green-color"/>;

                            if(state.collapseOpenCounty.indexOf(county.county)!==-1)
                              countyIcon = <i className="fa fa-minus red-color"/>;

                            return <div key={index2}>
                              <Card>
                                <CardHeader className="cursor-pointer" onClick={(e)=>handleCountyCollapse(county.county)}>
                                  {countyIcon} {county.county}
                                </CardHeader>
                                <Collapse isOpen={state.collapseOpenCounty.indexOf(county.county)!==-1}>
                                  <Table className="table table-striped">
                                    <thead>
                                      <tr>
                                        <th width="20%">Appraiser</th>
                                        <th width="20%">Loan Officer</th>
                                        <th width="60%">Previous Assignment</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        county.county_stats.map(
                                          (stat, index3)=>{
                                            return <tr key={index3}>
                                              <td>{stat.appraiser}</td>
                                              <td>{stat.loan_officer}</td>
                                              <td>
                                                <Table>
                                                  <thead>
                                                    <tr>
                                                      <th width="15%">Reference #</th><th width="35%">Client</th><th width="30%">City</th><th width="20%">Complex</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {
                                                      stat.past.map(
                                                        (order, index4)=>{
                                                          return <tr key={index4}>
                                                            <td><NavLink style={{paddingLeft:'5px'}} to={"/appraisal/"+order.ID}>{order.reference_num}</NavLink></td>
                                                            <td>{order.company}</td>
                                                            <td>{order.city}</td>
                                                            <td>{order.complex}</td>
                                                          </tr>
                                                        }
                                                      )
                                                    }
                                                  </tbody>
                                                </Table>
                                              </td>
                                            </tr>
                                          }
                                        )
                                      }
                                    </tbody>
                                  </Table>
                                </Collapse>
                              </Card>
                              <br/><br/>
                            </div>
                          }
                        )
                      }
                    </div>
                  </Collapse>
                </Card>
                <br/><br/><br/>
              </div>
            }
          )
        }
      </CardBody>
    </Card>
  </div>;
}


export default RepeatAppraiserUsageReport;
