//Author Sooyoung Kim
//Date July 31, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, formatNumber, generateSID, formatDate, stringToHexCode, replaceRegex} from '../../../util/util';
import {Button, Card, CardHeader, CardBody, Col, Row, Modal, ModalHeader, ModalBody, Nav, NavItem, TabPane, TabContent, NavLink as NavLinkL} from 'reactstrap';
import MyReactTable from '../../util/my-react-table';
import {usePusher} from '../../pusher/pusher-context';
import React, {useReducer, useEffect, useCallback, useRef} from 'react';
import MyChart from './../../util/my-chart';
import ReportFilter from '../report-filter';
import {NavLink} from 'react-router-dom';
import moment from 'moment';
import '../report.css';

//initialize the state
let today = new Date();
let month = today.getMonth()+1;

if(month<10)
  month = '0'+month;
const initialState = {
  queryID:-1,
  activeTab:'1',
  loanPurpose:'',
  accountManagers:[],
  accountManager:'',
  loanType:'',
  isRush:'',
  isComplex:'',
  state:'',
  city:'',
  entity:'',
  selectedAppraisalType:'',
  filterAppraisalTypes:[],
  date:today.getFullYear()+'-'+month+'-'+today.getDate(),

  currentDate:{
    total_business_day:0,
    total:0,
  },
  mtd:{
    total_business_day:0,
    total:0,
  },
  ytd:{
    total_business_day:0,
    total:0,
  },
  months:{
    data:[],
  },

  reportDetailsToggle:false,
  reportDetails:'',
  reportDetailsOrders:[],

  clientBranches:[],
  clientBranch:0,
  clients:[],
  client:0,
  openByClient:0,

  monthsPrior:{
    data:[],
  },
  monthsLabel:'',
  monthsPriorLabel:'',
  daysLabel:'',
  daysPriorLabel:'',
  dayByDay:{
    data:[],
  },
  dayByDayPrior:{
    data:[],
  },

  excludeQuote:'both',
  clientCloseByOpenBy:'client',
  appraiserGraph:[],
  loanOfficerGraph:[],
  appraiserMap:{},
  loanOfficerMap:{},

  currentDateLO:{},
  mtdLO:{},
  ytdLO:{},
  monthsLO:{loan_officer_data:[]},
  monthsPriorLO:{loan_officer_data:[]},
  dayByDayLO:{loan_officer_data:[]},
  dayByDayPriorLO:{loan_officer_data:[]},

  currentDateAP:{appraiser_data:[]},
  mtdAP:{},
  ytdAP:{},
  monthsAP:{appraiser_data:[]},
  monthsPriorAP:{appraiser_data:[]},
  dayByDayAP:{appraiser_data:[]},
  dayByDayPriorAP:{appraiser_data:[]},

  showReportLoading:false,
};

//reducer function that perform state update
const reducer = getReducer();


const NuclearVolumeReport  = (props)=>{
  const controller = new AbortController();
  const pusher = usePusher();
  const newsid = useRef(generateSID());
  const activeTabId = useRef('1');

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //non API call but simpyl manage state
  const toggleReportLoading = () => {
    setState({showReportLoading:!state.showReportLoading});
  }

  const loadReportResponse = useCallback((ID) => {
    let callBack = (response)=>{
      //console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        if(activeTabId.current === '2'){
          let currentDate = response.data.data.current_date;
          let mtd = response.data.data.mtd;
          let ytd = response.data.data.ytd;
          let months = response.data.data.months_prior;
          let monthsPrior = response.data.data.months_prior_year;
          let dayByDay = response.data.data.day_by_day;
          let dayByDayPrior = response.data.data.day_by_day_prior_month;

          let loanOfficerMap = {};

          for(let i=0;i<ytd.loan_officer_data.length;i++){
            loanOfficerMap[ytd.loan_officer_data[i].name] = {month:{}, monthPrior:{}, day:[], dayPrior:[]};
          }

          for(let i=0;i<dayByDay.loan_officer_data.length;i++){
            loanOfficerMap[dayByDay.loan_officer_data[i].name].day = dayByDay.loan_officer_data[i].data;
          }
          for(let i=0;i<dayByDayPrior.loan_officer_data.length;i++){
            loanOfficerMap[dayByDayPrior.loan_officer_data[i].name].dayPrior = dayByDayPrior.loan_officer_data[i].data;
          }

          for(let i=0;i<months.loan_officer_data.length;i++){
            for(let j=0;j<months.loan_officer_data[i].data.length;j++){
              if(loanOfficerMap[months.loan_officer_data[i].data[j].name])
                loanOfficerMap[months.loan_officer_data[i].data[j].name].month[months.loan_officer_data[i].label]=months.loan_officer_data[i].data[j].count;
            }
          }

          for(let i=0;i<monthsPrior.length;i++){
            for(let j=0;j<monthsPrior[i].loan_officer_data.length;j++)
              loanOfficerMap[monthsPrior[i].loan_officer_data[j].name].monthPrior[monthsPrior.loan_officer_data[i].label]=monthsPrior[i].loan_officer_data[j].count;
          }

          setState({
            currentDateLO:currentDate,
            mtdLO:mtd,
            ytdLO:ytd,
            monthsLO:months,
            monthsPriorLO:monthsPrior,
            dayByDayLO:dayByDay,
            dayByDayPriorLO:dayByDayPrior,
            loanOfficerMap:loanOfficerMap,
            loanOfficerGraph:[],
            queryID:-1,
            showReportLoading:false
          });
        }else if(activeTabId.current === '3'){
          let currentDate = response.data.data.current_date;
          let mtd = response.data.data.mtd;
          let ytd = response.data.data.ytd;
          let months = response.data.data.months_prior;
          let monthsPrior = response.data.data.months_prior_year;
          let dayByDay = response.data.data.day_by_day;
          let dayByDayPrior = response.data.data.day_by_day_prior_month;


          let appraiserMap = {};

          for(let i=0;i<ytd.appraiser_data.length;i++){
            appraiserMap[ytd.appraiser_data[i].ID] = {month:{}, monthPrior:{}, day:[], dayPrior:[],name:ytd.appraiser_data[i].name};
          }

          for(let i=0;i<dayByDay.appraiser_data.length;i++){
            appraiserMap[dayByDay.appraiser_data[i].ID].day = dayByDay.appraiser_data[i].data;
          }
          for(let i=0;i<dayByDayPrior.appraiser_data.length;i++){
            //console.log(dayByDayPrior.appraiser_data[i].ID);

            appraiserMap[dayByDayPrior.appraiser_data[i].ID].dayPrior = dayByDayPrior.appraiser_data[i].data;
          }

          for(let i=0;i<months.appraiser_data.length;i++){
            for(let j=0;j<months.appraiser_data[i].data.length;j++){
              if(appraiserMap[months.appraiser_data[i].data[j].ID])
                appraiserMap[months.appraiser_data[i].data[j].ID].month[months.appraiser_data[i].label]=months.appraiser_data[i].data[j].count;
            }
          }

          for(let i=0;i<monthsPrior.length;i++){
            for(let j=0;j<monthsPrior[i].appraiser_data.length;j++)
              appraiserMap[monthsPrior[i].appraiser_data[j].ID].monthPrior[monthsPrior.appraiser_data[i].label]=monthsPrior[i].appraiser_data[j].count;
          }

          setState({
            currentDateAP:currentDate,
            mtdAP:mtd,
            ytdAP:ytd,
            monthsAP:months,
            monthsPriorAP:monthsPrior,
            dayByDayAP:dayByDay,
            dayByDayPriorAP:dayByDayPrior,
            appraiserMap:appraiserMap,
            queryID:-1,
            showReportLoading:false
          });
        }else{
          let currentDate = response.data.data.current_date;
          let mtd = response.data.data.mtd;
          let ytd = response.data.data.ytd;
          let months = response.data.data.months_prior;
          let monthsPrior = response.data.data.months_prior_year;
          let monthsLabel = response.data.data.months_prior_label;
          let monthsPriorLabel = response.data.data.months_prior_year_label;
          let dayByDay = response.data.data.day_by_day;
          let dayByDayPrior = response.data.data.day_by_day_prior_month;
          let daysLabel = response.data.data.day_by_day_label;
          let daysPriorLabel = response.data.data.day_by_day_label_prior_year;


          //console.log(loanOfficerMap)
          setState({
            currentDate:currentDate,
            mtd:mtd,
            ytd:ytd,
            months:months,
            monthsPrior:monthsPrior,
            monthsLabel:monthsLabel,
            monthsPriorLabel:monthsPriorLabel,
            dayByDay:dayByDay,
            dayByDayPrior:dayByDayPrior,
            daysLabel:daysLabel,
            daysPriorLabel:daysPriorLabel,
            loanOfficerGraph:[],
            queryID:-1,
            showReportLoading:false
          });
        }
      }
    };
    callBack = callBack.bind(this);

    httpGet('report/query/retrieve/'+ID, '', '', callBack);
  }, [httpGet, setState]);

  const reportReady = useCallback((response) => {
    response = JSON.parse(response)
    let code = response.code;
    let queryID = response.data;
    let SID = response.SID;
    if(SID===newsid.current){
      if(code==='00'){
        loadReportResponse(queryID)
      }
    }
  }, [loadReportResponse]);
  
  //run only once when component is loaded
  useEffect(()=>{
    // Subscribe to a channel and bind to events using the pusher instance
    const channel = pusher.subscribe('private-report');
    channel.bind('report-ready', (data) => {
      console.log(data);
      reportReady(data);
    });

    getReport();

    return ()=> {
      channel.unbind('report-ready');
      pusher.unsubscribe('private-report');

      controller.abort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const activeTabToggle = (tab) => {
    activeTabId.current = tab;
    setState({activeTab:tab});
    if(tab==='2'){
      getReportLO();
    }else if(tab==='3'){
      getReportAppraiser();
    }
  }

  const addToLoanOfficerGraph = (name) => {
    let loanOfficerGraph = state.loanOfficerGraph.slice();
    let index = loanOfficerGraph.indexOf(name);

    if(index===-1)
      loanOfficerGraph.push(name);
    else
      loanOfficerGraph.splice(index, 1);

    setState({loanOfficerGraph:loanOfficerGraph});
  }

  const addToAppraiserGraph = (name) => {
    let appraiserGraph = state.appraiserGraph.slice();
    let index = appraiserGraph.indexOf(name);

    if(index===-1)
      appraiserGraph.push(name);
    else
      appraiserGraph.splice(index, 1);

    setState({appraiserGraph:appraiserGraph});
  }

  const showReportDetails = (details, client, from, to) => {
    setState({reportDetails:details});
    reportDetailsToggle();

    switch(details){
      case 'listOrders':
        getReportDetailsOrderList(client, to, from);
        break;
      case 'listAppraiserOrders':
        getReportDetailsAppraiserOrderList(client, to, from);
        break;
      case 'listLoanOfficerOrders':
        getReportDetailsLoanOfficerOrderList(client, to, from);
        break;
      case 'listSpreads':
        getReportDetailsSpreadList(to, from);
        break;
      default:

        break;
    }
  }

  const reportDetailsToggle = () => {
    if(state.reportDetailsDropDown)
      setState({reportDetailsOrders:[]});
    setState({reportDetailsDropDown:!state.reportDetailsDropDown});
  }

  //API call
  const getReport = () => {
    if(state.activeTab === '2'|| state.activeTab === '3'){
      setState({activeTab:'1'});
      activeTabId.current = '1'
    }
    setState({
      appraiserGraph:[],
      loanOfficerGraph:[],
      appraiserMap:{},
      loanOfficerMap:{},
      currentDate:{
        total_business_day:0,
        total:0,
      },
      mtd:{
        total_business_day:0,
        total:0,
      },
      ytd:{
        total_business_day:0,
        total:0,
      },
    });

    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);
    let SID = generateSID();
    newsid.current = SID; 

    let url = 'report/nuclearVolume/SID='+SID+'&accountManager='+state.accountManager+'&excludeQuote='+state.excludeQuote+'&client='+state.client+'&openBy='+state.openByClient+'&clientBranch='+state.clientBranch+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&date='+state.date+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&reportClient='+state.clientCloseByOpenBy+'&appraisalType='+appraisalType;
    let callBack = apiCallBack([{state:'queryID', key:'data'}], toggleReportLoading());
	  httpGet(url, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  const getReportAppraiser = () => {
    setState({
      appraiserGraph:[],
      appraiserMap:{},
    });

    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);

    let url = 'report/allVolumeReport/appraiser/SID='+newsid.current+'&accountManager='+state.accountManager+'&excludeQuote='+state.excludeQuote+'&client='+state.client+'&openBy='+state.openByClient+'&clientBranch='+state.clientBranch+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&date='+state.date+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&reportClient='+state.clientCloseByOpenBy+'&appraisalType='+appraisalType+'&reportType=nuclear';

    let callBack = apiCallBack([{state:'queryID', key:'data'}], toggleReportLoading());
	  httpGet(url, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  const getReportLO = () => {
    setState({
      loanOfficerGraph:[],
      loanOfficerMap:{}
    });

    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);

    let url = 'report/allVolumeReport/loanOfficer/SID='+newsid.current+'&accountManager='+state.accountManager+'&excludeQuote='+state.excludeQuote+'&client='+state.client+'&openBy='+state.openByClient+'&clientBranch='+state.clientBranch+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&date='+state.date+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&reportClient='+state.clientCloseByOpenBy+'&appraisalType='+appraisalType+'&reportType=nuclear';

    let callBack = apiCallBack([{state:'queryID', key:'data'}], toggleReportLoading());
	  httpGet(url, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  const getReportDetailsOrderList = (client, to , from) => {
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);

    let url;

    if(state.clientCloseByOpenBy==='client')
      url = 'report/nuclearVolume/details/order/reportClient='+state.clientCloseByOpenBy+'&accountManager='+state.accountManager+'&excludeQuote='+state.excludeQuote+'&openBy='+state.openByClient+'&clientBranch='+state.clientBranch+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&client='+client+'&to='+to+'&from='+from+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&appraisalType='+appraisalType;
    else
        url = 'report/nuclearVolume/details/order/reportClient='+state.clientCloseByOpenBy+'&accountManager='+state.accountManager+'&excludeQuote='+state.excludeQuote+'&openBy='+client+'&clientBranch='+state.clientBranch+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&client='+state.client+'&to='+to+'&from='+from+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&appraisalType='+appraisalType;

    let callBack = apiCallBack([{state:'reportDetailsOrders', key:'data'}]);
	  httpGet(url, '', 'Oops, something went wrong and could not load the report details. Please try again later.', callBack);
  }

  const getReportDetailsLoanOfficerOrderList = (loanOfficer, to , from) => {
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);

    let url = 'report/nuclearVolume/details/order/accountManager='+state.accountManager+'&excludeQuote='+state.excludeQuote+'&openBy='+state.openByClient+'&clientBranch='+state.clientBranch+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&client='+state.client+'&to='+to+'&from='+from+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&appraisalType='+appraisalType+'&loanOfficer='+loanOfficer;

    let callBack = apiCallBack([{state:'reportDetailsOrders', key:'data'}]);
	  httpGet(url, '', 'Oops, something went wrong and could not load the report details. Please try again later.', callBack);
  }

  const getReportDetailsAppraiserOrderList = (appraiser, to , from) => {
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);

    let url = 'report/nuclearVolume/details/order/appraiser='+appraiser+'&accountManager='+state.accountManager+'&excludeQuote='+state.excludeQuote+'&openBy='+state.openByClient+'&clientBranch='+state.clientBranch+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&client='+state.client+'&to='+to+'&from='+from+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&appraisalType='+appraisalType;

    let callBack = apiCallBack([{state:'reportDetailsOrders', key:'data'}]);
	  httpGet(url, '', 'Oops, something went wrong and could not load the report details. Please try again later.', callBack);
  }

  const getReportDetailsSpreadList = (to , from) => {
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);

    let url = 'report/nuclearVolume/details/order/accountManager='+state.accountManager+'&excludeQuote='+state.excludeQuote+'&client='+state.client+'&openBy='+state.openByClient+'&clientBranch='+state.clientBranch+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&to='+to+'&from='+from+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&appraisalType='+appraisalType;

    let callBack = apiCallBack([{state:'reportDetailsOrders', key:'data'}]);
	  httpGet(url, '', 'Oops, something went wrong and could not load the report details. Please try again later.', callBack);
  }

  //render
  let date = null;
  if(state.date!=='')
    date = moment(state.date);

  let chart;
  let chart2;
  let loanOfficerChart;
  let loanOfficerChart2;
  let appraiserChart;
  let appraiserChart2;

  if(state.appraiserGraph.length>0){
    let appraiserDaysLabel = [];
    let appraiserMonthsLabel = [];

    for(let i=0;i<state.dayByDayPrior.data.length;i++){
      appraiserDaysLabel.push(state.dayByDayPrior.data[i].date);
    }

    for(let i=0;i<state.months.data.length;i++){
      appraiserMonthsLabel.push(state.months.data[i].label);
    }

    let series = [];
    let series2 = [];

    for(let i=0;i<state.appraiserGraph.length;i++){
      let appraiserMonthsData= [];
      let appraiserMonthsDataPrior= [];
      for(let j=0;j<state.months.data.length;j++){
        let monthCount = state.appraiserMap[state.appraiserGraph[i]].month[state.months.data[j].label]?state.appraiserMap[state.appraiserGraph[i]].month[state.months.data[j].label]:0;
        appraiserMonthsData.push(monthCount);
      }


      for(let j=0;j<state.monthsPrior.data.length;j++){
        let monthCount = state.appraiserMap[state.appraiserGraph[i]].monthPrior[state.monthsPrior.data[j].label]?state.appraiserMap[state.appraiserGraph[i]].month[state.monthsPrior.data[j].label]:0;
        appraiserMonthsDataPrior.push(monthCount);
      }

      let tmp = {
        data: appraiserMonthsData,
        name:'Nuclear Volume '+state.monthsLabel+' - '+state.appraiserMap[state.appraiserGraph[i]].name,
        color:'#'+stringToHexCode(state.appraiserGraph[i])
      };

      let tmp2 = {
        data: appraiserMonthsDataPrior,
        name:'Nuclear Volume '+state.monthsPriorLabel+' - '+state.appraiserMap[state.appraiserGraph[i]].name,
        color:'#'+stringToHexCode(state.appraiserGraph[i]),
        dashStyle:'LongDashDot'
      };

      if(appraiserMonthsData.length>0&&appraiserMonthsDataPrior.length>0){
        series.push(tmp);
        series.push(tmp2);
      }

      let dayData = state.appraiserMap[state.appraiserGraph[i]].day?state.appraiserMap[state.appraiserGraph[i]].day:[];
      let dayPriorData = state.appraiserMap[state.appraiserGraph[i]].dayPrior?state.appraiserMap[state.appraiserGraph[i]].dayPrior:[];

      dayData = dayData.map(
        (data)=> {return data.count}
      );

      dayPriorData = dayPriorData.map(
        (data)=> {return data.count}
      );

      let tmp3 = {
        data: dayData,
        name:'Nuclear Volume '+state.monthsLabel+' - '+state.appraiserMap[state.appraiserGraph[i]].name,
        color:'#'+stringToHexCode(state.appraiserGraph[i])
      };

      let tmp4 = {
        data: dayPriorData,
        name:'Nuclear Volume '+state.monthsPriorLabel+' - '+state.appraiserMap[state.appraiserGraph[i]].name,
        color:'#'+stringToHexCode(state.appraiserGraph[i]),
        dashStyle:'LongDashDot'
      };

      if(dayData.length>0&&dayPriorData.length>0){
        series2.push(tmp3);
        series2.push(tmp4);
      }
    }

    let appraiserPriorMonthsConfig = {
      xAxis: {
        categories: appraiserMonthsLabel
      },
      series:series,
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true
          }
        }
      },
      title:{
        text: 'Nuclear Volume'
      }
    }

    let appraiserDayByDayConfig = {
      xAxis: {
        categories: appraiserDaysLabel
      },
      series: series2,
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true
          }
        }
      },
      title:{
        text: 'Nuclear Volume Day By Day'
      }
    }
    console.log(series2);
    if(series.length>0)
      appraiserChart = <MyChart options = {appraiserPriorMonthsConfig} ></MyChart>;
    if(series2.length>0)
      appraiserChart2 = <MyChart options = {appraiserDayByDayConfig} ></MyChart>;
  }

  if(state.loanOfficerGraph.length>0){
    let loanOfficerDaysLabel = [];
    let loanOfficerMonthsLabel = [];

    for(let i=0;i<state.dayByDayPrior.data.length;i++){
      loanOfficerDaysLabel.push(state.dayByDayPrior.data[i].date);
    }

    for(let i=0;i<state.months.data.length;i++){
      loanOfficerMonthsLabel.push(state.months.data[i].label);
    }

    let series = [];
    let series2 = [];

    for(let i=0;i<state.loanOfficerGraph.length;i++){
      let loanOfficerMonthsData= [];
      let loanOfficerMonthsDataPrior= [];
      for(let j=0;j<state.months.data.length;j++){
        let monthCount = state.loanOfficerMap[state.loanOfficerGraph[i]].month[state.months.data[j].label]?state.loanOfficerMap[state.loanOfficerGraph[i]].month[state.months.data[j].label]:0;
        loanOfficerMonthsData.push(monthCount);
      }


      for(let j=0;j<state.monthsPrior.data.length;j++){
        let monthCount = state.loanOfficerMap[state.loanOfficerGraph[i]].monthPrior[state.monthsPrior.data[j].label]?state.loanOfficerMap[state.loanOfficerGraph[i]].month[state.monthsPrior.data[j].label]:0;
        loanOfficerMonthsDataPrior.push(monthCount);
      }

      let tmp = {
        data: loanOfficerMonthsData,
        name:'Nuclear Volume '+state.monthsLabel+' - '+state.loanOfficerGraph[i],
        color:'#'+stringToHexCode(state.loanOfficerGraph[i])
      };

      let tmp2 = {
        data: loanOfficerMonthsDataPrior,
        name:'Nuclear Volume '+state.monthsPriorLabel+' - '+state.loanOfficerGraph[i],
        color:'#'+stringToHexCode(state.loanOfficerGraph[i]),
        dashStyle:'LongDashDot'
      };

      if(loanOfficerMonthsData.length>0&&loanOfficerMonthsDataPrior.length>0){
        series.push(tmp);
        series.push(tmp2);
      }

      let dayData = state.loanOfficerMap[state.loanOfficerGraph[i]].day?state.loanOfficerMap[state.loanOfficerGraph[i]].day:[];
      let dayPriorData = state.loanOfficerMap[state.loanOfficerGraph[i]].dayPrior?state.loanOfficerMap[state.loanOfficerGraph[i]].dayPrior:[];

      dayData = dayData.map(
        (data)=> {return data.count}
      );

      dayPriorData = dayPriorData.map(
        (data)=> {return data.count}
      );

      let tmp3 = {
        data: dayData,
        name:'Nuclear Volume '+state.monthsLabel+' - '+state.loanOfficerGraph[i],
        color:'#'+stringToHexCode(state.loanOfficerGraph[i])
      };

      let tmp4 = {
        data: dayPriorData,
        name:'Nuclear Volume '+state.monthsPriorLabel+' - '+state.loanOfficerGraph[i],
        color:'#'+stringToHexCode(state.loanOfficerGraph[i]),
        dashStyle:'LongDashDot'
      };

      if(dayData.length>0&&dayPriorData.length>0){
        series2.push(tmp3);
        series2.push(tmp4);
      }
    }

    let loanOfficerPriorMonthsConfig = {
      xAxis: {
        categories: loanOfficerMonthsLabel
      },
      series:series,
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true
          }
        }
      },
      title:{
        text: 'Nuclear Volume'
      }
    }

    let loanOfficerDayByDayConfig = {
      xAxis: {
        categories: loanOfficerDaysLabel
      },
      series: series2,
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true
          }
        }
      },
      title:{
        text: 'Nuclear Volume Day By Day'
      }
    }
    console.log(series2);
    if(series.length>0)
      loanOfficerChart = <MyChart options = {loanOfficerPriorMonthsConfig} ></MyChart>;
    if(series2.length>0)
      loanOfficerChart2 = <MyChart options = {loanOfficerDayByDayConfig} ></MyChart>;
  }

  if(state.months.data.length>0){
    let monthsLabel = [];
    let monthsData = [];
    let monthsDataPrior = [];

    let daysLabel = [];
    let daysData = [];
    let daysDataPrior = [];

    for(let i=0;i<state.dayByDayPrior.data.length;i++){
      daysLabel.push(state.dayByDayPrior.data[i].date);
      daysDataPrior.push(state.dayByDayPrior.data[i].count);
    }

    for(let i=0;i<state.dayByDay.data.length;i++){
      daysData.push(state.dayByDay.data[i].count);
    }

    for(let i=0;i<state.months.data.length;i++){
      monthsLabel.push(state.months.data[i].label);
      monthsData.push(state.months.data[i].total);
    }

    for(let i=0;i<state.monthsPrior.data.length;i++){
      monthsDataPrior.push(state.monthsPrior.data[i].total);
    }

    let priorMonthsConfig = {
      xAxis: {
        categories: monthsLabel
      },
      series: [
        {
          data: monthsData,
          name:'Nuclear Volume '+state.monthsLabel
        },
        {
          data: monthsDataPrior,
          name:'Nuclear Volume '+state.monthsPriorLabel
        }
      ],
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true
          }
        }
      },
      title:{
        text: 'Nuclear Volume'
      }
    }

    let dayByDayConfig = {
      xAxis: {
        categories: daysLabel
      },
      series: [
        {
          data: daysData,
          name:'Nuclear Volume '+state.daysLabel
        },
        {
          data: daysDataPrior,
          name:'Nuclear Volume '+state.daysPriorLabel
        }
      ],
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true
          }
        }
      },
      title:{
        text: 'Nuclear Volume Day By Day'
      }
    }

    chart = <MyChart options = {priorMonthsConfig} ></MyChart>;
    chart2 = <MyChart options = {dayByDayConfig} ></MyChart>;
  }

  let clientMap = {};
  let loanOfficerMap = {};
  let appraiserMap = {};
  let currentDateMap = {};
  let loanOfficerCurrentDateMap = {};
  let appraiserCurrentDateMap = {};
  let mtdMap = {};
  let loanOfficerMtdMap = {};
  let appraiserMtdMap = {};
  let ytdMap = {};
  let loanOfficerYtdMap = {};
  let appraiserYtdMap = {};
  let monthMap = [];
  let loanOfficerMonthMap = [];
  let appraiserMonthMap = [];
  if(state.currentDate.data)
    for(let i=0;i<state.currentDate.data.length;i++){
      currentDateMap[state.currentDate.data[i].ID] = state.currentDate.data[i].count;
      clientMap[state.currentDate.data[i].ID] = state.currentDate.data[i].name;
    }

  if(state.currentDateLO && state.currentDateLO.loan_officer_data)
    for(let i=0;i<state.currentDateLO.loan_officer_data.length;i++){
      loanOfficerCurrentDateMap[state.currentDateLO.loan_officer_data[i].name] = state.currentDateLO.loan_officer_data[i].count;
      loanOfficerMap[state.currentDateLO.loan_officer_data[i].name] = state.currentDateLO.loan_officer_data[i].count;
    }

  if(state.currentDateAP.appraiser_data)
    for(let i=0;i<state.currentDateAP.appraiser_data.length;i++){
      appraiserCurrentDateMap[state.currentDateAP.appraiser_data[i].ID] = state.currentDateAP.appraiser_data[i].count;
      appraiserMap[state.currentDateAP.appraiser_data[i].ID] = state.currentDateAP.appraiser_data[i].count;
      appraiserMap[state.currentDateAP.appraiser_data[i].ID] = {count:state.currentDateAP.appraiser_data[i].count, name:state.currentDateAP.appraiser_data[i].name};
    }

  if(state.mtd.data)
    for(let i=0;i<state.mtd.data.length;i++){
      mtdMap[state.mtd.data[i].ID] = state.mtd.data[i].count;
      clientMap[state.mtd.data[i].ID] = state.mtd.data[i].name;
    }


  if(state.mtdLO && state.mtdLO.loan_officer_data)
    for(let i=0;i<state.mtdLO.loan_officer_data.length;i++){
      loanOfficerMtdMap[state.mtdLO.loan_officer_data[i].name] = state.mtdLO.loan_officer_data[i].count;
      loanOfficerMap[state.mtdLO.loan_officer_data[i].name] = state.mtdLO.loan_officer_data[i].count;
    }

  if(state.mtdAP.appraiser_data)
    for(let i=0;i<state.mtdAP.appraiser_data.length;i++){
      appraiserMtdMap[state.mtdAP.appraiser_data[i].ID] = state.mtdAP.appraiser_data[i].count;
      appraiserMap[state.mtdAP.appraiser_data[i].ID] = {count:state.mtdAP.appraiser_data[i].count, name:state.mtdAP.appraiser_data[i].name};
    }


  for(let i=0;i<state.months.data.length;i++){
    let month = {};
    for(let j=0;j<state.months.data[i].data.length;j++){
      month[state.months.data[i].data[j].ID] = state.months.data[i].data[j].count;
      clientMap[state.months.data[i].data[j].ID] = state.months.data[i].data[j].name;

    }
    monthMap.push(month);
  }

  if(state.monthsLO && state.monthsLO.loan_officer_data){
    for(let i=0;i<state.monthsLO.loan_officer_data.length;i++){
      let month2 = {};
      for(let j=0;j<state.monthsLO.loan_officer_data[i].data.length;j++){
        month2[state.monthsLO.loan_officer_data[i].data[j].name] = state.monthsLO.loan_officer_data[i].data[j].count;
        loanOfficerMap[state.monthsLO.loan_officer_data[i].data[j].name] = state.monthsLO.loan_officer_data[i].count;
      }
      loanOfficerMonthMap.push(month2);
    }
  }

  for(let i=0;i<state.monthsAP.appraiser_data.length;i++){
    let month2 = {};
    for(let j=0;j<state.monthsAP.appraiser_data[i].data.length;j++){
      month2[state.monthsAP.appraiser_data[i].data[j].ID] = state.monthsAP.appraiser_data[i].data[j].count;
      appraiserMap[state.monthsAP.appraiser_data[i].data[j].ID] =  {count:state.monthsAP.appraiser_data[i].count, name:state.monthsAP.appraiser_data[i].name};
    }
    appraiserMonthMap.push(month2);
  }


  if(state.ytd.data)
    for(let i=0;i<state.ytd.data.length;i++){
      ytdMap[state.ytd.data[i].ID] = state.ytd.data[i].count;
      clientMap[state.ytd.data[i].ID] = state.ytd.data[i].name;
    }

  if(state.ytdLO.loan_officer_data)
    for(let i=0;i<state.ytdLO.loan_officer_data.length;i++){
      loanOfficerYtdMap[state.ytdLO.loan_officer_data[i].name] = state.ytdLO.loan_officer_data[i].count;
      loanOfficerMap[state.ytdLO.loan_officer_data[i].name] = state.ytdLO.loan_officer_data[i].count;
    }

  if(state.ytdAP.appraiser_data)
    for(let i=0;i<state.ytdAP.appraiser_data.length;i++){
      appraiserYtdMap[state.ytdAP.appraiser_data[i].ID] = state.ytdAP.appraiser_data[i].count;
      appraiserMap[state.ytdAP.appraiser_data[i].ID] = {count:state.ytdAP.appraiser_data[i].count, name:state.ytdAP.appraiser_data[i].name};
    }

  let content;

  let sortedLoanOfficerList = [];
  Object.getOwnPropertyNames(loanOfficerMap).map(
    (name,index)=>{
      let tmp = {};
      tmp.name = name;
      tmp.count = loanOfficerMap[name];

      sortedLoanOfficerList.push(tmp);
      return null;
    }
  );

  sortedLoanOfficerList.sort((a, b)=>{
    if ( a.count < b.count ){
      return 1;
    }
    if ( a.count > b.count ){
      return -1;
    }
    return 0;
  });

  let sortedAppraiserList = [];
  Object.getOwnPropertyNames(appraiserMap).map(
    (ID,index)=>{
      let tmp = {};
      tmp.name = appraiserMap[ID].name;
      tmp.count = appraiserMap[ID].count;
      tmp.ID = ID;

      sortedAppraiserList.push(tmp);
      return null;
    }
  );

  sortedAppraiserList.sort((a, b)=>{
    if ( a.count < b.count ){
      return 1;
    }
    if ( a.count > b.count ){
      return -1;
    }
    return 0;
  });

  let sortedCompaniesList = [];
  Object.getOwnPropertyNames(clientMap).map(
    (ID,index)=>{
      let tmp = {};
      tmp.ID = ID;
      tmp.name = clientMap[ID];

      sortedCompaniesList.push(tmp);
      return null;
    }
  );

  sortedCompaniesList.sort((a, b)=>{
    return a.name.localeCompare(b.name);
  });



  if(state.reportDetailsOrders.length>0){
    let rows = state.reportDetailsOrders.map(
      (order, index)=>{
        return(
          <tr key={index}>
            <td><NavLink target="_blank" to={"/appraisal/"+order.ID}>{order.reference_num}</NavLink></td>
            <td>{order.property_street+' '+order.property_city+', '+order.property_state+' '+order.property_zip}</td>
            <td>{order.company}</td>
            <td>{order.status}</td>
            <td>{order.appraisal_type}</td>
            <td>{order.loan_num}</td>
            <td>{order.loan_type}</td>
          </tr>
        )
      }
    );

    content =
    <table className="table table-striped">
      <thead>
        <tr>
          <th>Reference #</th>
          <th>Address</th>
          <th>Lender</th>
          <th>Status</th>
          <th>Report Type</th>
          <th>Loan #</th>
          <th>Loan type</th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </table>
  }

  let table1Data = [];
  let table2Data = [];
  let table4Data = [];
  let table1Columns = [];
  let table2Columns = [];
  let table4Columns = [];

  sortedCompaniesList.map(
    (company,index)=>{
      let clientName = company.name;
      let row = {
        clientID:company.ID,
        clientName:clientName,
        currentFrom:state.currentDate.from,
        currentTo:state.currentDate.to,
        current:currentDateMap[company.ID]?currentDateMap[company.ID]:0,
        mtdFrom:state.mtd.from,
        mtdTo:state.mtd.to,
        mtd:mtdMap[company.ID]?mtdMap[company.ID]:0,
        ytdFrom:state.ytd.from,
        ytdTo:state.ytd.to,
        ytd:ytdMap[company.ID]?ytdMap[company.ID]:0
      };
      if(index===0){
        table1Columns.push(
          {
            id: 'clientName',
            Header:'Client',
            minWidth:250,
            accessor: d => d.clientName,
            headerStyle: {
              textAlign:'left'
            }
          }
        );

        table1Columns.push(
          {
            id: 'current',
            Header:state.currentDate.label,
            accessor: d => d.current,
            Cell: props => {
              return <NavLink to="#" onClick={()=>showReportDetails('listOrders',props.row.original.clientID,props.row.original.currentFrom,props.row.original.currentTo)}>{props.row.original.current}</NavLink>
            },
            headerStyle: {
              textAlign:'left'
            }
          }
        );

        table1Columns.push(
          {
            id: 'mtd',
            Header:'MTD',
            accessor: d => d.mtd,
            Cell: props => {
              return <NavLink to="#" onClick={()=>showReportDetails('listOrders',props.row.original.clientID,props.row.original.mtdFrom,props.row.original.mtdTo)}>{props.row.original.mtd}</NavLink>
            },
            headerStyle: {
              textAlign:'left'
            }
          }
        );

        table1Columns.push(
          {
            id: 'ytd',
            Header:'YTD',
            accessor: d => d.ytd,
            Cell: props => {
              return <NavLink to="#" onClick={()=>showReportDetails('listOrders',props.row.original.clientID,props.row.original.ytdFrom,props.row.original.ytdTo)}>{props.row.original.ytd}</NavLink>
            },
            headerStyle: {
              textAlign:'left'
            }
          }
        );
      }


      monthMap.map(
        (month, index2)=>{
          console.log(index2);
          console.log(month);
          console.log(state.months);
          console.log(state.months.data[index2]);
          row[state.months.data[index2].label] = month[company.ID]?month[company.ID]:0;
          row[state.months.data[index2].label+'From'] = state.months.data[index2].from;
          row[state.months.data[index2].label+'To'] = state.months.data[index2].to;

          if(index===0){
            table1Columns.push(
              {
                id: state.months.data[index2].label,
                Header:state.months.data[index2].label,
                accessor: d => d[state.months.data[index2].label],
                Cell: props => {
                  return <NavLink to="#" onClick={()=>showReportDetails('listOrders',props.row.original.clientID,props.row.original[state.months.data[index2].label+'From'],props.row.original[state.months.data[index2].label+'To'])}>{props.row.original[state.months.data[index2].label]}</NavLink>
                },
                headerStyle: {
                  textAlign:'left'
                }
              }
            );
          }

          return null;
        }
      );

      table1Data.push(row);
      return null;
    }
  )

  sortedLoanOfficerList.map(
    (loanOfficer,index)=>{
      let name = loanOfficer.name;
      console.log(name);
      let row = {
        name:name,
        currentFrom:state.currentDate.from,
        currentTo:state.currentDate.to,
        current:loanOfficerCurrentDateMap[name]?loanOfficerCurrentDateMap[name]:0,
        mtdFrom:state.mtd.from,
        mtdTo:state.mtd.to,
        mtd:loanOfficerMtdMap[name]?loanOfficerMtdMap[name]:0,
        ytdFrom:state.ytd.from,
        ytdTo:state.ytd.to,
        ytd:loanOfficerYtdMap[name]?loanOfficerYtdMap[name]:0
      };
      if(index===0){
        table2Columns.push(
          {
            id: 'name',
            Header:'Loan Officer',
            minWidth:250,
            accessor: d => d.name,
            Cell: props => {
              return <NavLink style={{color:state.loanOfficerGraph.indexOf(props.row.original.name)!==-1?'#FF5733':''}} to="#" onClick={(e)=>addToLoanOfficerGraph(props.row.original.name)}>{props.row.original.name}</NavLink>
            },
            headerStyle: {
              textAlign:'left'
            }
          }
        );

        table2Columns.push(
          {
            id: 'current',
            Header:state.currentDate.label,
            accessor: d => d.current,
            Cell: props => {
              return <NavLink to="#" onClick={()=>showReportDetails('listLoanOfficerOrders',props.row.original.name,props.row.original.currentFrom,props.row.original.currentTo)}>{props.row.original.current}</NavLink>
            },
            headerStyle: {
              textAlign:'left'
            }
          }
        );

        table2Columns.push(
          {
            id: 'mtd',
            Header:'MTD',
            accessor: d => d.mtd,
            Cell: props => {
              return <NavLink to="#" onClick={()=>showReportDetails('listLoanOfficerOrders',props.row.original.name,props.row.original.mtdFrom,props.row.original.mtdTo)}>{props.row.original.mtd}</NavLink>
            },
            headerStyle: {
              textAlign:'left'
            }
          }
        );

        table2Columns.push(
          {
            id: 'ytd',
            Header:'YTD',
            accessor: d => d.ytd,
            Cell: props => {
              return <NavLink to="#" onClick={()=>showReportDetails('listLoanOfficerOrders',props.row.original.name,props.row.original.ytdFrom,props.row.original.ytdTo)}>{props.row.original.ytd}</NavLink>
            },
            headerStyle: {
              textAlign:'left'
            }
          }
        );
      }


      loanOfficerMonthMap.map(
        (month, index2)=>{
          row[state.months.data[index2].label] = month[name]?month[name]:0;
          row[state.months.data[index2].label+'From'] = state.months.data[index2].from;
          row[state.months.data[index2].label+'To'] = state.months.data[index2].to;

          if(index===0){
            table2Columns.push(
              {
                id: state.months.data[index2].label,
                Header:state.months.data[index2].label,
                accessor: d => d[state.months.data[index2].label],
                Cell: props => {
                  return <NavLink to="#" onClick={()=>showReportDetails('listLoanOfficerOrders',props.row.original.name,props.row.original[state.months.data[index2].label+'From'],props.row.original[state.months.data[index2].label+'To'])}>{props.row.original[state.months.data[index2].label]}</NavLink>
                },
                headerStyle: {
                  textAlign:'left'
                }
              }
            );
          }
          return null;
        }
      );

      table2Data.push(row);
      return null;
    }
  )

  sortedAppraiserList.map(
    (appraiser,index)=>{
      let name = appraiser.name;
      let ID = appraiser.ID;
      let row = {
        name:name,
        ID:ID,
        currentFrom:state.currentDate.from,
        currentTo:state.currentDate.to,
        current:appraiserCurrentDateMap[ID]?appraiserCurrentDateMap[ID]:0,
        mtdFrom:state.mtd.from,
        mtdTo:state.mtd.to,
        mtd:appraiserMtdMap[ID]?appraiserMtdMap[ID]:0,
        ytdFrom:state.ytd.from,
        ytdTo:state.ytd.to,
        ytd:appraiserYtdMap[ID]?appraiserYtdMap[ID]:0
      };
      if(index===0){
        table4Columns.push(
          {
            id: 'name',
            Header:'Appraiser',
            minWidth:250,
            accessor: d => d.name,
            Cell: props => {
              return <NavLink style={{color:state.appraiserGraph.indexOf(props.row.original.ID)!==-1?'#FF5733':''}} to="#" onClick={(e)=>addToAppraiserGraph(props.row.original.ID)}>{props.row.original.name}</NavLink>
            },
            headerStyle: {
              textAlign:'left'
            }
          }
        );

        table4Columns.push(
          {
            id: 'current',
            Header:state.currentDate.label,
            accessor: d => d.current,
            Cell: props => {
              return <NavLink to="#" onClick={()=>showReportDetails('listAppraiserOrders',props.row.original.ID,props.row.original.currentFrom,props.row.original.currentTo)}>{props.row.original.current}</NavLink>
            },
            headerStyle: {
              textAlign:'left'
            }
          }
        );

        table4Columns.push(
          {
            id: 'mtd',
            Header:'MTD',
            accessor: d => d.mtd,
            Cell: props => {
              return <NavLink to="#" onClick={()=>showReportDetails('listAppraiserOrders',props.row.original.ID,props.row.original.mtdFrom,props.row.original.mtdTo)}>{props.row.original.mtd}</NavLink>
            },
            headerStyle: {
              textAlign:'left'
            }
          }
        );

        table4Columns.push(
          {
            id: 'ytd',
            Header:'YTD',
            accessor: d => d.ytd,
            Cell: props => {
              return <NavLink to="#" onClick={()=>showReportDetails('listAppraiserOrders',props.row.original.ID,props.row.original.ytdFrom,props.row.original.ytdTo)}>{props.row.original.ytd}</NavLink>
            },
            headerStyle: {
              textAlign:'left'
            }
          }
        );
      }


      appraiserMonthMap.map(
        (month, index2)=>{
          row[state.months.data[index2].label] = month[ID]?month[ID]:0;
          row[state.months.data[index2].label+'From'] = state.months.data[index2].from;
          row[state.months.data[index2].label+'To'] = state.months.data[index2].to;

          if(index===0){
            table4Columns.push(
              {
                id: state.months.data[index2].label,
                Header:state.months.data[index2].label,
                accessor: d => d[state.months.data[index2].label],
                Cell: props => {
                  return <NavLink to="#" onClick={()=>showReportDetails('listAppraiserOrders',props.row.original.ID,props.row.original[state.months.data[index2].label+'From'],props.row.original[state.months.data[index2].label+'To'])}>{props.row.original[state.months.data[index2].label]}</NavLink>
                },
                headerStyle: {
                  textAlign:'left'
                }
              }
            );
          }
          return null;
        }
      );

      table4Data.push(row);
      return null;
    }
  )

  let reportFiltersConfig = [
    {id:'from',value:date, updateFunc:(v)=>setState({date:v}), width:'2'},
    {id:'rush',value:state.isRush, updateFunc:(v)=>setState({isRush:v}), width:'2'},
    {id:'complex',value:state.isComplex, updateFunc:(v)=>setState({isComplex:v}), width:'2'},
    {id:'state',value:state.state, updateFunc:(v)=>setState({state:v}), width:'2'},
    {id:'city',value:state.city, updateFunc:(v)=>setState({city:v}), width:'2'},
    {id:'accountManager',value:state.accountManager, updateFunc:(v)=>setState({accountManager:v}), width:'2'},
    {id:'loanPurpose',value:state.loanPurpose, updateFunc:(v)=>setState({loanPurpose:v}), width:'2'},
    {id:'loanType',value:state.loanType, updateFunc:(v)=>setState({loanType:v}), width:'2'},
    {id:'entity',value:state.entity, updateFunc:(v)=>setState({entity:v}), width:'2'},
    {id:'appraisalType',value:state.filterAppraisalTypes, updateFunc:(v)=>setState({filterAppraisalTypes:v}), width:'4'},
    {id:'customSelect',value:state.excludeQuote, updateFunc:(v)=>setState({excludeQuote:v}), width:'2', label:'Exclude', clearable:false, className:"", options:[
      {label:"None",value:"no"},
      {label:"Hold",value:"hold"},
      {label:"Cancel",value:"cancel"},
      {label:"Hold + Cancel",value:"both"}
    ]},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'3'},
    {id:'openBy',value:state.openByClient, updateFunc:(v)=>setState({openByClient:v}), width:'3'},
    {id:'clientBranch',value:state.clientBranch, updateFunc:(v)=>setState({clientBranch:v}), width:'3'},
    {id:'customSelect',value:state.clientCloseByOpenBy, updateFunc:(v)=>setState({clientCloseByOpenBy:v}), width:'2', label:'Client', clearable:false, className:"", options:[
      {label:"Close By",value:"client"},{label:"Open By",value:"openby"}
    ]},
    {id:'button',value:'Submit', updateFunc:getReport, width:'1', className:"align-right", color:"warning"},
  ];


  return <div>
    <Modal className="my-modal" isOpen={state.showReportLoading} toggle={toggleReportLoading} >
      <ModalHeader hidden={true} toggle={toggleReportLoading}></ModalHeader>
      <ModalBody>
        <center>
          <i style={{fontSize:'55px'}} className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
          <br/><br/>
          Report Loading...<br/>
          Please wait, your report will be loaded once it is ready.
        </center>
        <br/><br/>
        <center>
          <Button color="info" onClick={toggleReportLoading}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Nuclear Volume Report
      </CardHeader>
      <CardBody>
        <ReportFilter {...props} configs={reportFiltersConfig}/>
      </CardBody>
    </Card>
    <br/>



    <br/>
    <br/>
    <Row style={{gridAutoRows:'1fr'}}>
      <Col sm="4" style={{display:'flex'}}>
        <Card style={{alignItems:'stretch', width:'100%'}}>
          <CardHeader className="header-color">
            <i className="fa fa-reorder"></i>&nbsp;{state.currentDate.label}
          </CardHeader>
          <CardBody>
            <label>Duration</label><br/>
            {formatDate(state.currentDate.from)+' to '+formatDate(state.currentDate.to) }<b>{' ('+state.currentDate.total_business_day+' business days)'}</b>
            <br/>

            <label>Total Nuclears</label><br/>
            <NavLink to="#" onClick={()=>showReportDetails('listSpreads','',state.currentDate.from,state.currentDate.to)}>{state.currentDate.total}</NavLink>
            <br/>



          </CardBody>
        </Card>
      </Col>
      <Col sm="4" style={{display:'flex'}}>
        <Card style={{alignItems:'stretch', width:'100%'}}>
          <CardHeader className="header-color">
            <i className="fa fa-reorder"></i>&nbsp;{state.mtd.label}
          </CardHeader>
          <CardBody>
            <label>Duration</label><br/>
            {formatDate(state.mtd.from)+' to '+formatDate(state.mtd.to) }<b>{' ('+state.mtd.total_business_day+' business days)'}</b>
            <br/>

            <Row>
              <Col sm="6">
                <label>Total Nuclears</label><br/>
                <NavLink to="#" onClick={()=>showReportDetails('listSpreads','',state.mtd.from,state.mtd.to)}>{state.mtd.total}</NavLink>
              </Col>
              <Col sm="6">
                <label>Avg Nuclears Per Day </label><br/>
                {formatNumber(state.mtd.total/state.mtd.total_business_day)}
              </Col>
            </Row>

          </CardBody>
        </Card>
      </Col>
      <Col sm="4" style={{display:'flex'}}>
        <Card style={{alignItems:'stretch', width:'100%'}}>
          <CardHeader className="header-color">
            <i className="fa fa-reorder"></i>&nbsp;{state.ytd.label}
          </CardHeader>
          <CardBody>
            <label>Duration</label><br/>
            {formatDate(state.ytd.from)+' to '+formatDate(state.ytd.to) }<b>{' ('+state.ytd.total_business_day+' business days)'}</b>
            <br/>

            <Row>
              <Col sm="6">
                <label>Total Nuclears</label><br/>
                <NavLink to="#" onClick={()=>showReportDetails('listSpreads','',state.ytd.from,state.ytd.to)}>{state.ytd.total}</NavLink>
              </Col>
              <Col sm="6">
                <label>Avg Nuclears Per Day </label><br/>
                {formatNumber(state.ytd.total/state.ytd.total_business_day)}
              </Col>
            </Row>


          </CardBody>
        </Card>
      </Col>
    </Row>

    <br/>
    <Row>
      <Col sm="12">
        {chart}
        <br/>
        {chart2}
      </Col>
    </Row>
    <br/><div className="my-divider">&nbsp;</div><br/><br/>
    <Nav tabs>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.activeTab === '1'?"active":"inactive" )}
          onClick={() => { activeTabToggle('1'); }}
          to="#"
        >
          Client Level Overview
        </NavLinkL>
      </NavItem>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.activeTab === '2'?"active":"inactive" )}
          onClick={() => { activeTabToggle('2'); }}
          to="#"
        >
          Loan Officer Level Overview
        </NavLinkL>
      </NavItem>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.activeTab === '3'?"active":"inactive" )}
          onClick={() => { activeTabToggle('3'); }}
          to="#"
        >
          Appraiser Level Overview
        </NavLinkL>
      </NavItem>
    </Nav>
    <TabContent activeTab={state.activeTab}>
      <TabPane tabId="1">
        <br/>
        <MyReactTable columns={table1Columns} data={table1Data} className="table table-striped"/>
        <Modal className="my-modal-wide" isOpen={state.reportDetailsDropDown} toggle={reportDetailsToggle} >
          <ModalHeader hidden={true} toggle={reportDetailsToggle}></ModalHeader>
          <ModalBody>
            <center>
              <h5><i className="fa fa-user"></i> Report Details</h5>
            </center>
            <br/>
            <div className="large-scroll-container">
              {content}
            </div>
            <br/>
            <center>
              <Button color="info" onClick={reportDetailsToggle}>Close</Button>
            </center>
          </ModalBody>
        </Modal>
      </TabPane>
      <TabPane tabId="2">
        <Row>
          <Col sm="12">
            {loanOfficerChart}
            <br/>
            {loanOfficerChart2}
          </Col>
        </Row>
        <MyReactTable columns={table2Columns} data={table2Data} className="table table-striped"/>
      </TabPane>
      <TabPane tabId="3">
        <Row>
          <Col sm="12">
            {appraiserChart}
            <br/>
            {appraiserChart2}
          </Col>
        </Row>
        <MyReactTable columns={table4Columns} data={table4Data} className="table table-striped"/>
      </TabPane>
    </TabContent>
  </div>;
}


export default NuclearVolumeReport;
