//Author Sooyoung Kim
//Date Aug 10, 2023
import {getReducer, getSetStateFunction, postAPICallGenerator, callBackGenerator} from '../../util/util';
import {Button, Input,Modal, ModalBody,ModalHeader,Row, Col} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import MySelect from '../util/my-select';

//initialize the state
const initialState = {
  comment:'',
  tatFiveDays:'',
  lessThanTenAppraisers:'',
  appraiserResponded:'',
  lowestBidFastestTAT:'',
  fastestTAT:'',
  bestRatedAppraiser:''
};

//reducer function that perform state update
const reducer = getReducer();


const SpreadWatchQuestionnaire  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //API call
  const submitQuestionnaire = (e) => {
    e.preventDefault();

    let parameters = [
      {
        field:'appraisalFk',
        value:props.appraisal_fk
      },
      {
        field:'comment',
        value:state.comment
      },
      {
        field:'tatFiveDays',
        value:state.tatFiveDays
      },
      {
        field:'lessThanTenAppraisers',
        value:state.lessThanTenAppraisers
      },
      {
        field:'appraiserResponded',
        value:state.appraiserResponded
      },
      {
        field:'lowestBidFastestTAT',
        value:state.lowestBidFastestTAT
      },
      {
        field:'fastestTAT',
        value:state.fastestTAT
      },
      {
        field:'bestRatedAppraiser',
        value:state.bestRatedAppraiser
      }
    ];

    let callBack = apiCallBack([], props.callBack());
    httpPost('billing/spreadWatch/create', parameters, 'Questionnaire submitted successfully.', 'Oops, something went wrong and could not submit the questionnaire. Please try again later.', callBack);
  }

  //render
  let secondQuestionFollowUp;
  let thirdQuestionFollowUp1;
  let thirdQuestionFollowUp2;

  if(state.lessThanTenAppraisers==='yes'){
    secondQuestionFollowUp = <Row>
      <Col sm="12">
        <label>&nbsp;&nbsp;&nbsp;2.1) Have <font color="red"><b>all appraisers</b> responded to quote</font>?</label>
        <MySelect
          type="select"
          required={true}
          value={state.appraiserResponded}
          onChange={(v)=>setState({appraiserResponded:v})}
          options={[{label:"Yes",value:"all"},{label:"No",value:"no"}]}
        />
      </Col>
    </Row>;
  }
  else if(state.lessThanTenAppraisers==='no'){
    secondQuestionFollowUp = <Row>
      <Col sm="12">
        <label>&nbsp;&nbsp;&nbsp;2.1) How many appraisers responded?</label>
        <Input type="text" required={true} value={state.appraiserResponded} onChange={(e)=>setState({appraiserResponded:e.target.value})}/>
      </Col>
    </Row>;
  }

  if(state.lowestBidFastestTAT==='no'){
    thirdQuestionFollowUp1 = <Row>
      <Col sm="12">
        <label>&nbsp;&nbsp;&nbsp;3.1) Was this the <font color="red"><b>fastest</b> TAT</font>?</label>
        <MySelect
          modal={true}
          type="select"
          required={true}
          value={state.fastestTAT}
          onChange={(v)=>setState({fastestTAT:v})}
          options={[{label:"Yes",value:"all"},{label:"No",value:"no"}]}
        />
      </Col>
    </Row>
  }

  if(state.fastestTAT==='no'){
    thirdQuestionFollowUp2 = <Row>
      <Col sm="12">
        <label>&nbsp;&nbsp;&nbsp;3.2) Was this the <font color="red"><b>best-rated</b> appraiser</font>?</label>
        <MySelect
          modal={true}
          type="select"
          required={true}
          value={state.bestRatedAppraiser}
          onChange={(v)=>setState({bestRatedAppraiser:v})}
          options={[{label:"Yes",value:"all"},{label:"No",value:"no"}]}
        />
      </Col>
    </Row>
  }
  
  return <div>
    <Modal className="my-modal" isOpen={true} toggle={props.callBack} >
      <ModalHeader hidden={true} toggle={props.callBack}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-comments"></i> Spread Watch Questionnaire</h5>
        </center>
        <br/>
        <form onSubmit={submitQuestionnaire}>
          <Row>
            <Col sm="12">
              <label>1) Is the TAT less than <font color="red"><b>5 business days</b></font>?</label>
              <MySelect
                modal={true}
                type="select"
                required={true}
                value={state.tatFiveDays}
                onChange={(v)=>setState({tatFiveDays:v})}
                options={[{label:"Yes",value:"all"},{label:"No",value:"no"}]}
              />
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <label>2) Does this county have less than <font color="red"><b>10 appraisers</b></font>?</label>
              <MySelect
                modal={true}
                type="select"
                required={true}
                value={state.lessThanTenAppraisers}
                onChange={(v)=>setState({lessThanTenAppraisers:v})}
                options={[{label:"Yes",value:"all"},{label:"No",value:"no"}]}
              />
            </Col>
          </Row>
          {secondQuestionFollowUp}
          <Row>
            <Col sm="12">
              <label>3) Was this the <font color="red"><b>lowest bid with fastest TAT</b></font>?</label>
              <MySelect
                modal={true}
                type="select"
                required={true}
                value={state.lowestBidFastestTAT}
                onChange={(v)=>setState({lowestBidFastestTAT:v})}
                options={[{label:"Yes",value:"all"},{label:"No",value:"no"}]}
              />
            </Col>
          </Row>
          {thirdQuestionFollowUp1}
          {thirdQuestionFollowUp2}
          <Row>
            <Col sm="12">
              <label>Comment:</label>
              <Input type="textarea" rows="5" className="non-editable-textarea" value={state.comment} onChange={(e)=>setState({comment:e.target.value})}/>
            </Col>
          </Row>
          <br/>
          <center>
            <Button color="warning"><i className="fa fa-check"></i>&nbsp;Submit</Button>{' '}
            <Button color="info" onClick={props.callBack}>Close</Button>
          </center>
        </form>
      </ModalBody>
    </Modal>
  </div>;
}


export default SpreadWatchQuestionnaire;
