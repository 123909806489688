//Author Sooyoung Kim
//Date May 25th, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, deleteAPICallGenerator, callBackGenerator,formatDateTime, confirmation, pushToArray} from '../../util/util';
import {Button, Table, Input, Modal, ModalHeader, ModalBody, ModalFooter, NavItem, Nav, TabContent, TabPane, NavLink as NavLinkL} from 'reactstrap';
import {NavLink} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';
import ReactStars from 'react-stars';

//initialize the state
const initialState = {
  activeTab:'2',
  qualityRatings:[],
  currentRatingComment:'',
  currentRating:3,
  likes:[]
};

//reducer function that perform state update
const reducer = getReducer();

const QualityRating  = (props)=>{
  const controller = new AbortController();
  let newInitialState = Object.assign({}, initialState, {
    appraisal: props.appraisal,
    appraisalFk: props.appraisalFk,
    popUp: props.popUp?props.popUp:false,
    renderTriggerLink: props.renderTriggerLink?props.renderTriggerLink:true,
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const handleRating = (value) =>{
    setState({currentRating:value});
  }

  const togglePopUp = () =>{
    if(state.popUp===false&&state.qualityRatings.length<=0){

      getQualityRating();
      getLikes(props.appraisalFk);
    }
    setState({popUp:!state.popUp});
  }

  const tabToggle = (tab) =>{
    setState({activeTab:tab});
  }

  //API call
  const removeQualityRating = (ID) =>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        getQualityRating();
      }
    };
    httpDelete('appraiser/qualityRating/'+ID,'Quality rating entry deleted.','Oops, something went wrong and could not delete the quality rating entry, please try again later.', callBack);
  }

  const likeAppraiser = (appraiserFk) =>{
    let list = pushToArray(state.likes, appraiserFk);
    let callBack = apiCallBack([{state:'likes', value:list}]);
    let parameters = [{field:'appraisalFk',value:state.appraisalFk},{field:'appraiserFk',value:appraiserFk}];
    httpPost('appraiser/like/internal', parameters, 'Like submitted.', 'Oops, something went wrong and could not submit the like. Please try again later.', callBack);
  }

  const unlikeAppraiser = (appraiserFk) =>{
    let likes = state.likes.slice();
    let index = likes.indexOf(appraiserFk);

    if(index!==-1)
      likes.splice(index, 1);

    let callBack = apiCallBack([{state:'likes', value:likes}]);
    httpDelete('appraiser/like/internal/'+props.appraisalFk+'/'+appraiserFk,'Unliked appraiser','Oops, something went wrong and could not submit the unlike request. Please try again later.', callBack);
  }

  const submitRating = () =>{
    let parameters = [
      {
        field:'quality_comment',
        value:state.currentRatingComment
      },
      {
        field:'appraisal_fk',
        value:state.appraisalFk
      },
      {
        field:'appraiser_fk',
        value:state.appraisal.appraiser_fk
      },
      {
        field:'score',
        value:state.currentRating
      }
    ];

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        getQualityRating();
      }
    };
    callBack = callBack.bind(this);

    httpPost('appraiser/qualityRating/create', parameters, 'Rating posted successfully.', 'Oops, something went wrong and could not post this rating. Please try again later.', callBack);
  }

  const getQualityRating = () =>{
    let callBack = apiCallBack([{state:'qualityRatings', key:'data'}]);
    httpGet('appraiser/qualityRating/get/appraiser_fk='+state.appraisal.appraiser_fk+'&appraisal_fk='+state.appraisalFk, '', 'Oops, something went wrong and could not load service rating for this appraisal. Please try again later.', callBack);
  }

  const getLikes = (ID) =>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let likes = [];
        for(let i=0;i<response.data.data.length;i++)
          likes.push(response.data.data[i].appraiser_fk);

        setState({likes:likes});
      }
    };
    callBack = callBack.bind(this);
    httpGet('appraiser/like/internal/'+ID, '', 'Oops, something went wrong and could not load the like of this order. Please try again later.', callBack);
  }

  //render
  let submitButton =<Button color="warning" onClick={submitRating}><i className="fa fa-check"></i> Submit</Button>;
  let triggerLink;

  if(state.renderTriggerLink)
    triggerLink = <NavLink to="#" onClick={togglePopUp} color="warning"><i className="fa fa-plus green-color"/> Quality Rating</NavLink>


  let appraiser;

  if(props.appraisal.appraiser_fk!=='0'){
    let thumbsUpIcon = <i className="fa fa-thumbs-o-up cursor-pointer" onClick={(e)=>likeAppraiser(props.appraisal.appraiser_fk)}/>;

    if(state.likes.indexOf(props.appraisal.appraiser_fk)!==-1)
      thumbsUpIcon = <i className="fa fa-thumbs-up link-color cursor-pointer" onClick={(e)=>unlikeAppraiser(props.appraisal.appraiser_fk)}/>;

    appraiser = <div>{thumbsUpIcon} {props.appraisal.appraiser} </div>
  }

  return <div className="display-inline">
    {triggerLink}
    <Modal className="my-modal" isOpen={state.popUp} toggle={togglePopUp} >
      <ModalHeader className="my-confirmation-title"><i className="fa fa-warning"></i> Quality Ratings</ModalHeader>
      <ModalBody>
        <Nav tabs>
          <NavItem>
            <NavLinkL
              className={"nav-link "+(state.activeTab === '1'?"active":"inactive" )}
              onClick={() => { tabToggle('1'); }}
              to="#"
            >
              Quality Ratings
            </NavLinkL>
          </NavItem>
          <NavItem>
            <NavLinkL
              className={"nav-link "+(state.activeTab === '2'?"active":"inactive" )}
              onClick={() => { tabToggle('2'); }}
              to="#"
            >
              New Quality Ratings
            </NavLinkL>
          </NavItem>
        </Nav>
        <TabContent activeTab={state.activeTab}>
          <TabPane tabId="1">
            <br/>
            <div className="large-scroll-container">
              <label>Appraiser</label><br/>
              {appraiser}
              <Table className="table table-striped">
                <tbody>
                  {
                    state.qualityRatings.map(
                      (qualityRating, index)=>{
                        let comment;

                        if(qualityRating.comment!=='')
                          comment = <div>
                            <i><i className="fa fa-quote-left"/> {qualityRating.comment} <i className="fa fa-quote-right"/></i>
                          </div>

                        return(
                          <tr key={index}>
                            <td>
                              <div className="my-well">
                                <div>
                                  <i className="fa fa-times red-color cursor-pointer" onClick={(e)=>{
                                    confirmation(
                                      ()=>{removeQualityRating(qualityRating.ID)},
                                      ()=>{},
                                      'Delete service rating entry?',
                                      'Are you sure you want to delete this service rating entry?');
                                  }}></i>
                                  <b>{qualityRating.record_user}</b><br/>
                                  Reviewed on {formatDateTime(qualityRating.datetime_created)}<br/>
                                  <ReactStars
                                     onChange={()=>{}}
                                     value={qualityRating.score}
                                     count={3}
                                     edit={false}
                                     half={true}
                                     size={25}
                                   />
                                </div>
                                <br/>
                                {comment}
                              </div>
                            </td>
                          </tr>
                        );
                      }
                    )
                  }
                </tbody>
              </Table>
            </div>
          </TabPane>
          <TabPane tabId="2">
            <br/>
            <div className="large-scroll-container padding">
              <b>Please review the appraiser</b>
              <center>
                <ReactStars
                 onChange={handleRating}
                 value={state.currentRating}
                 count={3}
                 half={true}
                 size={25}
                />
                <br/>
                <label>Comment</label>
                <Input type="textarea" rows="4" value={state.currentRatingComment} onChange={(e)=>setState({currentRatingComment:e.target.value})}/>
                <br/>
                <div className="align-right">
                  {submitButton}
                </div>
              </center>
              <br/>
            </div>
          </TabPane>
        </TabContent>
      </ModalBody>
      <ModalFooter>
        <div style={{width:'100%'}}>
          <center>
            <Button color="info" onClick={()=>{togglePopUp()}}><i className="fa fa-times"></i> Close</Button>
          </center>
        </div>
      </ModalFooter>
    </Modal>
  </div>;
}

export default QualityRating;
