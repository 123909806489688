//Author Sooyoung Kim
//Date May 12th, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, deleteAPICallGenerator, callBackGenerator, formatDate, formatDateTime, confirmation, generateSID, sliceFromArray} from '../../util/util';
import {Button, Card, CardHeader, CardBody, Row, Col,Table,Modal,ModalHeader,ModalBody,Input, NavItem, Nav, TabContent, TabPane,NavLink as NavLinkL} from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import ClientFile from './client-file';
import MyXEditable from '../util/my-xeditable';
import MySelect from '../util/my-select';
import {NavLink, useParams} from 'react-router-dom';
import React, {useReducer, useEffect, useRef} from 'react';
import './client.css';


//initialize the state
const initialState = {
  client:{
    checklists:[]
  },
  clients:[{key:'0', value:''}],
  targetConsolidateClientFk:'',
  branches:[],
  retail:[],
  wholesale:[],
  retailBranch:0,
  wholesaleBranch:0,
  wholesaleCloseby:'',
  masterUsers:[],
  paymentMethods:[],
  addNewBranchDropDownOpen:false,
  addNewMasterUserDropDownOpen:false,
  consolidateDropDownOpen:false,
  newBranchName:'',
  newBranchStreet:'',
  newBranchCity:'',
  newBranchCounty:'',
  newBranchState:'',
  newBranchZip:'',
  newBranchPhone:'',
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
  users:[],
  hasMoreUsers:true,

  name:'',
  email:'',
  wholesaleClientContacts:[],
  clientEntities:[],
  clientEntityLabels:[],
  newClientEntity:'',
  clientContactSearchResult:[],

  clientBranchFk:'0',
  selectedRetail:{contacts:[]},
  selectedWholesale:{contacts:[]},

  newRetailClientContacts:[{label:'Loan Officer',name:'',email:'',phone:''},{label:'Loan Processor',name:'',email:'',phone:''},{label:'Broker',name:'',email:'',phone:''}],
  newWholesaleClientContacts:[{label:'Loan Officer',name:'',email:'',phone:''},{label:'Loan Processor',name:'',email:'',phone:''},{label:'Broker',name:'',email:'',phone:''},{label:'AE',name:'',email:'',phone:''},{label:'CSR',name:'',email:'',phone:''}],

  editWholesalePopup:false,
  editRetailPopup:false,
  createNewRetailPopup:false,
  createNewWholesalePopup:false,
  addNewClientContactRetailRolePopup:false,
  addNewClientContactWholesaleRolePopup:false,

  newClientContactWholesaleClient:'',
  addNewRetailClientContactRole:'AE',
  addNewRetailClientContactRoleOther:'',

  addNewWholesaleClientContactRole:'AE',
  addNewWholesaleClientContactRoleOther:'',


  clientEntityList:['AE','CSR','Borrower','Co-Borrower','Loan Officer','Loan Processor','Broker','Broker Company','Other','Email Notification Subscriber'],

  historyOrders:[],
  hasMoreOrders:true,
  loading: false,
  sort: 'ID',
  order: 'DESC',
  limit:100,
  offset:0,
  userSort:'concat(u.first_name," ",u.last_name)',
  userOrder:'ASC',
  userLoading:false,
  userOffset:0,
};

//reducer function that perform state update
const reducer = getReducer();


const Client  = (props)=>{
  const controller = new AbortController();
  const newsid = useRef(generateSID);
  let {params} = useParams();
  let {id} = useParams();

  let activeTab = '1';
  let subActiveTab = '1';
  let clientContactName = '';
  let retailKeywordName = '';
  let wholesaleKeywordName = '';
  let tokens = params.split('&');

  for(let i=0;i<tokens.length;i++){
    let pair = tokens[i].split('=');

    if(pair[0]==='tab'){
      activeTab = pair[1];
    }else if(pair[0]==='subTab'){
      subActiveTab = pair[1];
    }else if(pair[0]==='clientContactName'){
      clientContactName = pair[1];
    }
  }

  if(subActiveTab === '1'){
    retailKeywordName = clientContactName;
  }else{
    wholesaleKeywordName = clientContactName;
  }

  if(params){
    params = params.replace('%ForwardSlash','ForwardSlash');
    params = params.replace('%Ampersand','Ampersand');
  }

  let newInitialState = Object.assign({}, initialState, {
    id: id,
    activeTab:activeTab,
    subActiveTab:subActiveTab,
    retailKeyword:retailKeywordName,
    wholesaleKeyword:wholesaleKeywordName,
    params:params,
    SID: generateSID(),
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getClient();
    getRetailClientContacts();
    getWholesaleClientContacts();
    getAllClientProfiles();
    getPaymentMethods();
    getWholesaleAssociate();
    getMasterUsers();
    getClientEntity();
    getClientEntityLabel();
    getBranches();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    if(state.historyOrders.length<=0 && state.hasMoreOrders){
        loadMore2();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state]);

  useEffect(()=>{
    if(state.users.length<=0 && state.hasMoreUsers && state.addNewMasterUserDropDownOpen){
        loadMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state]);

  //non API call but simpyl manage state
  const resetSearchUser = ()=>{
    setState({hasMoreUsers:true,userLoading:false,users:[],userOffset:0})
  }

  //this function render the sort icon at each column of the table
  const renderSortIcon = (col)=>{
    if(state.sort===col){
      if(state.order==='ASC')
        return <i className="red-color fa fa-arrow-down"></i>
      else
        return <i className="red-color fa fa-arrow-up"></i>
    }
  }

  //click handler that switch the state of sorting order then call the function to refresh the list
  //to achieve the "sorting"
  const columnClickHandler = (col)=>{
    if(state.sort===col){
      if(state.order==='ASC')
        setState({order:'DESC'});
      else
        setState({order:'ASC'});
    }
    else
      setState({sort:col});
    refreshList2();
  }

  const updateFieldCallBack = (field, value, id)=>{
    if(field==='checklists'){
      let checklists = state.client.checklists.slice();
      for(let i=0;i<checklists.length;i++){
        if(checklists[i].ID===id){
          checklists[i].answer = value;
          break;
        }
      }
      value = checklists;
    }

    let client = Object.assign({}, state.client);
    client[field] = value;
    setState({client:client});

    if(field==='payment_type'){
      confirmation(
        ()=>{updatePendingReceivable('Payment Type', value);},
        ()=>{},
        'Update all receivable?',
        'Do you want to update all existing pending/declined/hold receivable?');
    }
    else if(field==='covered_by'){
      confirmation(
        ()=>{updatePendingReceivable('Covered By', value);},
        ()=>{},
        'Update all receivable?',
        'Do you want to update all existing pending/declined/hold receivable?');
    }
  }

  const addNewWholesaleClientContact = ()=>{
    let type = 'modify';
    if(state.createNewWholesalePopup)
      type='create'
    let label = state.addNewWholesaleClientContactRole;

    if(label==='Other')
      label = state.addNewWholesaleClientContactRoleOther;
    if(label==='')
      label = 'Other';

    if(type==='modify'){
      let selectedWholesale = Object.assign({}, state.selectedWholesale);
      let contacts = selectedWholesale.contacts.slice();

      let tmp = {
        label:label,
        name:'',
        email:'',
        phone:''
      };

      contacts.push(tmp);
      selectedWholesale.contacts = contacts;

      setState({selectedWholesale:selectedWholesale,addNewWholesaleClientContactRole:'AE'});
    }
    else if(type==='create'){
      let newWholesaleClientContacts = state.newWholesaleClientContacts.slice();

      let tmp = {
        label:label,
        name:'',
        email:'',
        phone:''
      };

      newWholesaleClientContacts.push(tmp);

      setState({newWholesaleClientContacts:newWholesaleClientContacts,addNewWholesaleClientContactRole:'AE'});
    }
    toggleAddNewClientContactWholesaleRole();
  }

  const addNewRetailClientContact = ()=>{
    let type = 'modify';
    if(state.createNewRetailPopup)
      type='create'
    let label = state.addNewRetailClientContactRole;

    if(label==='Other')
      label = state.addNewRetailClientContactRoleOther;
    if(label==='')
      label = 'Other';

    if(type==='modify'){
      let selectedRetail = Object.assign({}, state.selectedRetail);
      let contacts = selectedRetail.contacts.slice();

      let tmp = {
        label:label,
        name:'',
        email:'',
        phone:''
      };

      contacts.push(tmp);
      selectedRetail.contacts = contacts;

      setState({selectedRetail:selectedRetail,addNewRetailClientContactRole:'AE'});
    }
    else if(type==='create'){
      let newRetailClientContacts = state.newRetailClientContacts.slice();

      let tmp = {
        label:label,
        name:'',
        email:'',
        phone:''
      };

      newRetailClientContacts.push(tmp);

      setState({newRetailClientContacts:newRetailClientContacts,addNewRetailClientContactRole:'AE'});
    }
    toggleAddNewClientContactRetailRole();
  }

  const removeNewRetailClientContact = (index)=>{
    let selectedRetail = Object.assign({}, state.selectedRetail);
    let contacts = selectedRetail.contacts.slice();

    contacts.splice(index,1 );
    selectedRetail.contacts = contacts;

    setState({selectedRetail:selectedRetail});
  }

  const removeNewWholesaleClientContact = (index)=>{
    let selectedWholesale = Object.assign({}, state.selectedWholesale);
    let contacts = selectedWholesale.contacts.slice();

    contacts.splice(index,1 );
    selectedWholesale.contacts = contacts;

    setState({selectedWholesale:selectedWholesale});
  }

  const removeCreateNewRetailClientContact = (index)=>{
    let newRetailClientContacts = state.newRetailClientContacts.slice();

    newRetailClientContacts.splice(index,1 );

    setState({newRetailClientContacts:newRetailClientContacts});
  }

  const removeCreateNewWholesaleClientContact = (index)=>{
    let newWholesaleClientContacts = state.newWholesaleClientContacts.slice();

    newWholesaleClientContacts.splice(index,1 );

    setState({newWholesaleClientContacts:newWholesaleClientContacts});
  }

  const updateSelectedRetailClientContact = (index, field, value)=>{
    let selectedRetail = Object.assign({}, state.selectedRetail);
    let contacts = selectedRetail.contacts.slice();

    contacts[index][field] = value;

    selectedRetail.contacts = contacts;

    setState({selectedRetail:selectedRetail});
  }

  const updateCreateNewRetailClientContact = (index, field, value)=>{
    let newRetailClientContacts = state.newRetailClientContacts.slice();

    newRetailClientContacts[index][field] = value;

    setState({newRetailClientContacts:newRetailClientContacts});
  }

  const updateCreateNewWholesaleClientContact = (index, field, value)=>{
    let newWholesaleClientContacts = state.newWholesaleClientContacts.slice();

    newWholesaleClientContacts[index][field] = value;

    setState({newWholesaleClientContacts:newWholesaleClientContacts});
  }

  const modifySelectedWholesaleClientContact = (index, field, value)=>{
    let selectedWholesale = Object.assign({}, state.selectedWholesale);
    let contacts = selectedWholesale.contacts.slice();

    contacts[index][field] = value;

    selectedWholesale.contacts = contacts;

    setState({selectedWholesale:selectedWholesale});
  }

  //this function refresh the list of orders pipeline
  const refreshList = ()=>{
    setState({
      users:[],
      hasMoreUsers:true,
      userOffset:0,
    });
  }

  //this function refresh the list of orders pipeline
  const refreshList2 = ()=>{
    setState({
      historyOrders:[],
      totalCount:0,
      hasMoreOrders:true,
      offset:0
    });
  }

  const toggleAddNewClientContactRetailRole = ()=>{
    setState({addNewClientContactRetailRolePopup:!state.addNewClientContactRetailRolePopup});
  }

  const toggleAddNewClientContactWholesaleRole = ()=>{
    setState({addNewClientContactWholesaleRolePopup:!state.addNewClientContactWholesaleRolePopup});
  }

  const toggleSelectRetail = ()=>{
    setState({editRetailPopup:!state.editRetailPopup});
  }

  const toggleSelectWholesale = ()=>{
    setState({editWholesalePopup:!state.editWholesalePopup});
  }

  const toggleCreateNewRetail = ()=>{
    setState({createNewRetailPopup:!state.createNewRetailPopup});
  }

  const toggleCreateNewWholesale = ()=>{
    setState({createNewWholesalePopup:!state.createNewWholesalePopup});
  }

  const selectRetail = (retail)=>{
    setState({selectedRetail:retail});
    toggleSelectRetail();
  }

  const selectWholesale = (wholesale)=>{
    setState({selectedWholesale:wholesale});
    toggleSelectWholesale();
  }

  //toggle function
  const addNewBranchDropDownToggle = ()=>{
    setState({addNewBranchDropDownOpen:!state.addNewBranchDropDownOpen});
  }

  const consolidateDropDownToggle = ()=>{
    setState({consolidateDropDownOpen:!state.consolidateDropDownOpen});
  }

  const addNewMasterUserDropDownToggle = ()=>{
    if(!state.addNewMasterUserDropDownOpen){
      refreshList();
      setState({addNewMasterUserDropDownOpen:!state.addNewMasterUserDropDownOpen});
    }
    else{
      setState({userOffset:0,userLoading:true,hasMoreUsers:true,addNewMasterUserDropDownOpen:!state.addNewMasterUserDropDownOpen});
      setTimeout(()=>setState({userLoading:false}),1000);
    }
  }

  //render function for infinite scroller
  const renderHistoryAppraisals = ()=>{
    return (
      <div>
        <Table className="table table-striped">
          <thead>
            <tr>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('reference_num')}><i className="fa fa-search"></i>&nbsp;Reference # {renderSortIcon('reference_num')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('status')}><i className="fa fa-info-circle"></i>&nbsp;Status {renderSortIcon('status')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('loan_num')}><i className="fa fa-folder"></i>&nbsp;Loan # {renderSortIcon('loan_num')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('CONCAT(borrower_f_name," ",borrower_l_name)')}><i className="fa fa-user"></i>&nbsp;Borrower {renderSortIcon('CONCAT(borrower_f_name," ",borrower_l_name)')}</th>
              <th className="cursor-pointer" width="20%" onClick={()=>columnClickHandler('property_street')}><i className="fa fa-map-marker"></i>&nbsp;Property Address {renderSortIcon('property_street')}</th>
              <th className="cursor-pointer" width="20%" onClick={()=>columnClickHandler('')}><i className="fa fa-info-circle"></i>&nbsp;Order Information {renderSortIcon('')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('datetime_submitted')}><i className="fa fa-calendar-o"></i>&nbsp;Date Submitted {renderSortIcon('datetime_submitted')}</th>
            </tr>
          </thead>
          <tbody>
          {
            state.historyOrders.map(
              (appraisal)=>{
                return (
                  <tr key={appraisal.ID}>
                    <td><NavLink className="link-color" target="_blank" to={"/appraisal/"+appraisal.ID}><b>{appraisal.reference_num}</b></NavLink></td>
                    <td>{appraisal.status}</td>
                    <td>{appraisal.loan_num}</td>
                    <td>{appraisal.borrower_f_name+' '+appraisal.borrower_l_name}</td>
                    <td>{appraisal.property_street+' '+appraisal.property_city+', '+appraisal.property_state+' '+appraisal.property_zip}</td>
                    <td>
                      <i className="fa fa-tags icon-color"></i>&nbsp;
                      {appraisal.appraisal_type}
                      <br/>
                      <i className="fa fa-home icon-color"></i>&nbsp;
                      {appraisal.property_type}
                      <br/>
                      <i className="fa fa-hashtag icon-color"></i>&nbsp;
                      {appraisal.loan_purpose}
                    </td>
                    <td>{formatDateTime(appraisal.datetime_submitted)}</td>
                  </tr>
                );
              }
            )
          }
          </tbody>
        </Table>
      </div>
    )
  }

  const handleAsyncSelectChange1 = (v,index)=>{
    let clientContactSearchResult = state.clientContactSearchResult.slice();

    for(let i=0;i<clientContactSearchResult.length;i++){
      if(clientContactSearchResult[i].name+' - '+clientContactSearchResult[i].email===v){
        updateCreateNewWholesaleClientContact(index, 'name', clientContactSearchResult[i].name);
        updateCreateNewWholesaleClientContact(index, 'email', clientContactSearchResult[i].email);
        updateCreateNewWholesaleClientContact(index, 'phone', clientContactSearchResult[i].phone);
        break;
      }
    }
  }

  const handleAsyncSelectChange2 = (v,index)=>{
    let clientContactSearchResult = state.clientContactSearchResult.slice();

    for(let i=0;i<clientContactSearchResult.length;i++){
      if(clientContactSearchResult[i].name+' - '+clientContactSearchResult[i].email===v){
        updateCreateNewRetailClientContact(index, 'name', clientContactSearchResult[i].name);
        updateCreateNewRetailClientContact(index, 'email', clientContactSearchResult[i].email);
        updateCreateNewRetailClientContact(index, 'phone', clientContactSearchResult[i].phone);
        break;
      }
    }
  }

  //API call
  //get client profile
  const getClient = ()=>{
    let url = 'client/'+state.id;
    let callBack = apiCallBack([{state:'client',key:'data'}]);
    httpGet(url, '','Oops, something went wrong and could not load this client profile. Please try again later.', callBack);
  }

  //generate a function that do the update on editable
  const generateUpdateFunction = (url, parameters, successMessage, failedMessage, parentCallBack=null)=>{
    return (newValue, callBack, errorCallBack)=>{
      //add in the new value into parameters
      parameters.push({field:'value',value:newValue});
      let promise = httpPut(url,parameters,successMessage,failedMessage, callBack, errorCallBack);
      promise.then(
        (response)=>{
          //let ID;
          let field;
          let value;
          let checklistID = -1;

          for(let i=0;i<parameters.length;i++){
            if(parameters[i].field==='field')
              field = parameters[i].value;
            else if(parameters[i].field==='value')
              value = parameters[i].value;
            else if(parameters[i].field==='checklistFk')
              checklistID = parameters[i].value;
            // else if(parameters[i].field==='ID')
            //   ID = parameters[i].value;
          }
          if(parentCallBack!==null){
            parentCallBack(field, value,checklistID);
          }
        }
      );
    }
  };

  const getWholesaleClientContacts = ()=>{
    let url = 'client/contact/wholesale/client_branch_fk='+state.wholesaleBranch+'&client_fk='+state.id+'&keyword='+state.wholesaleKeyword+'&close_by_client_fk='+state.wholesaleCloseby;
    let callBack = apiCallBack([{state:'wholesale', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load client contacts. Please try again later.', callBack);
  }

  const getRetailClientContacts = ()=>{
    let url = 'client/contact/retail/client_branch_fk='+state.retailBranch+'&client_fk='+state.id+'&keyword='+state.retailKeyword;
    let callBack = apiCallBack([{state:'retail', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load client contacts. Please try again later.', callBack);
  }

  const getWholesaleAssociate = ()=>{
    let url = 'client/wholesale/associate/'+state.id;
    let callBack = apiCallBack([{state:'wholesaleClientContacts', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load client wholesale associate. Please try again later.', callBack);
  }

  //get client entity
  const getClientEntityLabel = ()=>{
    let url = 'client/entity/label/get';
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let labels = [];
        for(let i=0;i<response.data.data.length;i++){
          if(i===0)
            setState({newClientEntity:response.data.data[i].entity});
          let tmp = {};
          tmp.key = response.data.data[i].entity;
          tmp.value = response.data.data[i].entity;

          labels.push(tmp);
        }
        setState({clientEntityLabels:labels});
      }
    };
    callBack = callBack.bind(this);

    httpGet(url, '', 'Oops, something went wrong and could not load client entity label. Please try again later.', callBack);
  }

  const getPaymentMethods = ()=>{
    let url = 'billing/paymentType/get';
    let callBack = (response)=>{
      let paymentMethods = [];

      for(let i=0;i<response.data.data.length;i++){
        paymentMethods.push({key:response.data.data[i].payment_type, value:response.data.data[i].payment_type});
      }
      setState({paymentMethods:paymentMethods});
    };
    callBack = callBack.bind(this);

    httpGet(url, '', 'Oops, something went wrong and could not load payment methods. Please try again later.', callBack);
  }

  //get client entity
  const getClientEntity = ()=>{
    let url = 'client/entity/get/'+state.id;
    let callBack = apiCallBack([{state:'clientEntities', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load this client entity profile. Please try again later.', callBack);
  }

  //get list of branches under this client profile
  const getBranches = ()=>{
    let url = 'client/branch/get/'+state.id;
    let callBack = apiCallBack([{state:'branches', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load this client\'s branches. Please try again later.', callBack);
  }

  //get list of master users of this client profile
  const getMasterUsers = ()=>{
    let url = 'client/masterUser/get/'+state.id;
    let callBack = apiCallBack([{state:'masterUsers', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load this client\'s master users. Please try again later.', callBack);
  }

  const getAllClientProfiles = ()=>{
    let url = 'client/get/limit=-1&offset=-1';
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let clients = [{key:'0', value:''}];
        for(let i=0;i<response.data.data.length;i++){
          let client = response.data.data[i];

          let tmp = {};
          tmp.key = client.ID;
          tmp.datetime_created = client.datetime_created;
          tmp.value = client.company;

          clients.push(tmp);
        }

        setState({clients:clients});
      }
    };
    callBack = callBack.bind(this);

    httpGet(url, '', 'Oops, something went wrong and could not retrieve client profiles.', callBack);
  }

  const updatePendingReceivable = (type, value)=>{
    let url = 'billing/client/receivable/update';
    let successMsg = 'All pending/declined/hold receivable has been updated.';
    let errorMsg = 'Oops, something went wrong and could not update receivables. Please try again later.';

    let parameters = [
      {
        field:'clientFk',
        value:state.id
      },
      {
        field:'type',
        value:type
      },
      {
        field:'value',
        value:value
      }
    ];

    let callBack = ()=>{};
    httpPost(url, parameters, successMsg, errorMsg, callBack);
  }

  const getClientContacts = (keyword, label)=>{
    if(keyword&&keyword!==''){
      let SID = generateSID();
      newsid.current = SID;

      let type = 'Retail';
      let clientFk = '0';

      if(state.subActiveTab==='2'){
        type = 'Wholesale';
        clientFk = state.newClientContactWholesaleClient;
      }

      let encodedKeyword = encodeURIComponent(keyword.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));

      let url = 'client/contact/all/keyword='+encodedKeyword+'&label='+label+'&type='+type+'&client_fk='+clientFk+'&client_branch_fk='+state.clientBranchFk;

      let callBack = (response)=>{
        if(SID===newsid.current){
          let code = response.data?response.data.code:undefined;

          if(code!=='00'){
            return [{}];
          }
          else{
            let options = [];

            let results = [];

            for(let i=0;i<response.data.data.length;i++){
              results.push(response.data.data[i]);
            }

            //push the word search so that if there were no result, user can always select what they type
            results.push({name:keyword, label:'', phone:'', email:''});

          setState({clientContactSearchResult:results});

            for(let i=0;i<results.length;i++){
              let tmp = {};
              tmp.label = results[i].name +' - '+results[i].email;
              tmp.value = results[i].name +' - '+results[i].email;

              options.push(tmp);
            }


            return options;
          }
        }
      };
      callBack = callBack.bind(this);

      let promise = httpGet(url, '', 'Oops, something went wrong and could not search for client entity. Please try again later.', callBack);

      return promise;
    }
  }

  const createNewClientContactWholesale = (e)=>{
    e.preventDefault();

    let url = 'client/contact/wholesale/create';
    let successMsg = 'Client wholesale contact created successfully.';
    let errorMsg = 'Oops, something went wrong and could not create this client wholesale contact. Please try again later.';

    let parameters = [
      {
        field:'clientFk',
        value:state.id
      },
      {
        field:'associatedClientFk',
        value:state.newClientContactWholesaleClient
      },
      {
        field:'contacts',
        value:state.newWholesaleClientContacts
      },
      {
        field:'clientBranchFk',
        value:state.clientBranchFk
      }
    ];


    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let wholesale = state.wholesale.slice();
        wholesale.push(response.data.data);

        setState({wholesale:wholesale});
        toggleCreateNewWholesale();
      }
    };
    callBack = callBack.bind(this);

    httpPost(url, parameters, successMsg, errorMsg, callBack);
  }

  const updateSelectedWholesaleClientContact = ()=>{
    let url = 'client/contact/retail/create';
    let successMsg = 'Client retail contact created successfully.';
    let errorMsg = 'Oops, something went wrong and could not create this client retail contact. Please try again later.';

    let parameters = [
      {
        field:'clientFk',
        value:state.id
      },
      {
        field:'contacts',
        value:state.newRetailClientContacts
      },
      {
        field:'clientBranchFk',
        value:state.clientBranchFk
      }
    ];

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let retail = state.retail.slice();
        retail.push(response.data.data);

        setState({retail:retail});
        toggleCreateNewRetail();
      }
    };
    callBack = callBack.bind(this);

    httpPost(url, parameters, successMsg, errorMsg, callBack);
  }

  const updateWholesale = ()=>{
    let url = 'client/contact/wholesale';
    let successMsg = 'Client wholesale contact updated successfully.';
    let errorMsg = 'Oops, something went wrong and could not update this client wholesale contact. Please try again later.';

    let parameters = [
      {
        field:'ID',
        value:state.selectedWholesale.ID
      },
      {
        field:'close_by_client_fk',
        value:state.selectedWholesale.close_by_client_fk
      },
      {
        field:'client_branch_fk',
        value:state.selectedWholesale.client_branch_fk
      },
      {
        field:'contacts',
        value:state.selectedWholesale.contacts
      }
    ];


    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let wholesale = state.wholesale.slice();
        for(let i=0;i<wholesale.length;i++){
          if(wholesale[i].ID===state.selectedWholesale.ID){
            wholesale[i] = state.selectedWholesale;
          }
        }

        setState({wholesale:wholesale});
        toggleSelectWholesale();
      }
    };
    callBack = callBack.bind(this);

    httpPut(url, parameters, successMsg, errorMsg, callBack);
  }

  const updateRetail = ()=>{
    let url = 'client/contact/retail';
    let successMsg = 'Client retail contact updated successfully.';
    let errorMsg = 'Oops, something went wrong and could not update this client retail contact. Please try again later.';

    let parameters = [
      {
        field:'ID',
        value:state.selectedRetail.ID
      },
      {
        field:'client_branch_fk',
        value:state.selectedRetail.client_branch_fk
      },
      {
        field:'contacts',
        value:state.selectedRetail.contacts
      }
    ];

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let retail = state.retail.slice();

        for(let i=0;i<retail.length;i++){
          if(retail[i].ID===state.selectedRetail.ID){
            retail[i] = state.selectedRetail;
          }
        }

        setState({retail:retail});
        toggleSelectRetail();
      }
    };
    callBack = callBack.bind(this);

    httpPut(url, parameters, successMsg, errorMsg, callBack);
  }

  const createWholesaleAssociate = ()=>{
    let url = 'client/wholesale/associate/create';
    let successMsg = 'Client wholesale associate created successfully.';
    let errorMsg = 'Oops, something went wrong and could not create this client wholesale associate. Please try again later.';

    let parameters = [
      {
        field:'clientFk',
        value:state.id
      },
      {
        field:'associatedClientFk',
        value:state.newWholesaleClient
      }
    ];


    let callBack = (response)=>{console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let wholesaleClientContacts = state.wholesaleClientContacts.slice();
        wholesaleClientContacts.push(response.data.data);

        setState({wholesaleClientContacts:wholesaleClientContacts});
      }
    };
    callBack = callBack.bind(this);

    httpPost(url, parameters, successMsg, errorMsg, callBack);
  }

  //submit a new master user from all branch users
  const submitNewMasterUser = (id)=>{
    let url = 'client/masterUser/create';
    let successMsg = 'Master user created successfully.';
    let errorMsg = 'Oops, something went wrong and could not create this master user. Please try again later.';

    let parameters = [
      {
        field:'client_fk',
        value:state.id
      },
      {
        field:'user_fk',
        value:id
      }
    ];

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let masterUsers = state.masterUsers.slice();

        masterUsers.push(response.data.data);

        setState({masterUsers:masterUsers});

        addNewMasterUserDropDownToggle();
      }
    };
    callBack = callBack.bind(this);

    httpPost(url, parameters, successMsg, errorMsg, callBack);
  }

  const consolidateClientProfile = (e)=>{
    e.preventDefault();

    let url = 'client/consolidate';
    let successMsg = 'Client profile consolidated.';
    let errorMsg = 'Oops, something went wrong and could not consolidated this client profile. Please try again later.';

    let parameters = [
      {
        field:'clientFk',
        value:state.id
      },
      {
        field:'targetClientFk',
        value:state.targetConsolidateClientFk
      }
    ];

    let callBack = () =>{};
    httpPost(url, parameters, successMsg, errorMsg, callBack);
  }

  //submit a new branch
  const submitNewBranch = (e)=>{
    e.preventDefault();
    let url = 'client/branch/create';
    let successMsg = 'New branch created successfully.';
    let errorMsg = 'Oops, something went wrong and could not create this new branch. Please try again later.';

    let parameters = [
      {
        field:'client_fk',
        value:state.id
      },
      {
        field:'branch_name',
        value:state.newBranchName
      },
      {
        field:'phone',
        value:state.newBranchPhone
      },
      {
        field:'street',
        value:state.newBranchStreet
      },
      {
        field:'city',
        value:state.newBranchCity
      },
      {
        field:'county',
        value:state.newBranchCounty
      },
      {
        field:'state',
        value:state.newBranchState
      },
      {
        field:'zip',
        value:state.newBranchZip
      }
    ];

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;

      if(code==='00'){
        let branches = state.branches.slice();
        branches.push(response.data.data);

        setState({branches:branches});
        addNewBranchDropDownToggle();
      }
    };
    callBack = callBack.bind(this);
    httpPost(url, parameters, successMsg, errorMsg, callBack);
  }

  //function that fire when the infinite scroll reach bottom
  const loadMore = ()=>{
    //do not load if there is no more conditions or it's loading data
    if(state.hasMoreUsers&&!state.userLoading){
      //set loading equals to true so it won't fire off before we are done
      setState({userLoading:true});

      let name = encodeURIComponent(state.name.replace(/\//g, '%ForwardSlash'));
      let email = encodeURIComponent(state.email.replace(/\//g, '%ForwardSlash'));

      let url = 'client/user/get/limit='+state.limit+'&offset='+state.userOffset+'&order='+state.userOrder+'&sort='+state.userSort+'&name='+name+'&email='+email+'&client_fk='+state.id;

      //callback handler that update the state when http request return
      let callBack = (response)=>{
        let code = response.data?response.data.code:undefined;

        if(code!=='00'){
          setState({hasMoreUsers:false});
        }
        else{
          if(state.addNewMasterUserDropDownOpen){
            let newUsers = response.data.data;
            let hasMoreUsers = true;
            let newOffset = state.offset;
            let totalCount = response.data.count;

            //if http request return empty then no more results, end of list
            if(newUsers.length<=0 || newUsers.length<state.limit){
              hasMoreUsers = false;
            }
            else{
              //increment the offset
              newOffset = state.offset + 1;
            }

            //concat the current array of announcement
            if(state.users.length>0){
              let temp = [...state.users,...newUsers];
              newUsers = temp;
            }

            setState({users:newUsers,hasMoreUsers:hasMoreUsers,userOffset:newOffset,totalCount:totalCount});
          }
        }
      };
      callBack = callBack.bind(this);

      //error handler when the http request return with error
      let errorCallBack = apiCallBack([{state:'hasMoreUsers', value:false}]);

      //collect the promise and wait for it to finish performing it's task
      let promises = httpGet(url, '','Oops, something went wrong and could not load users. Please try again later.', callBack, errorCallBack);
      promises
        .then(
          function(result){
            //set loading equals to false so the function could be fire off once again
            setState({loading:false});
          }
        );
    }
  }

  const removeClientContact = (id)=>{
    let url = 'client/contact/'+id;
    let wholesaleList = sliceFromArray(state.wholesale,'ID', id);
    let retailList = sliceFromArray(state.retail,'ID', id);
    let callBack = apiCallBack([{state:'wholesale', value:wholesaleList},{state:'retail', value:retailList}]);
    httpDelete(url,'Client contact deleted successfully.','Oops, something went wrong and could not delete this client contact. Please try again later.', callBack);
  }

  const removeWholesaleAssociate = (id)=>{
    let url = 'client/wholesale/associate/'+id;
    let list = sliceFromArray(state.wholesaleClientContacts,'ID', id);
    let callBack = apiCallBack([{state:'wholesaleClientContacts', value:list}]);
    httpDelete(url,'Client wholesale associate deleted successfully.','Oops, something went wrong and could not delete this client wholesale associate. Please try again later.', callBack);
  }

  //remove a branch from the client profile
  //confirm delete when the branch is not empty and has branch users
  const removeBranch = (id)=>{
    let url = 'client/branch/'+id;
    let list = sliceFromArray(state.branches,'ID', id);
    let callBack = apiCallBack([{state:'branches', value:list}]);
    httpDelete(url,'Branch deleted successfully.','Oops, something went wrong and could not delete this branch. Please try again later.', callBack);
  }

  //remove a master user
  const removeMasterUser = (id)=>{
    let url = 'client/masterUser/'+id;
    let list = sliceFromArray(state.masterUsers,'ID', id);
    let callBack = apiCallBack([{state:'masterUsers', value:list}]);
    httpDelete(url,'Master user deleted successfully.','Oops, something went wrong and could not delete this master user. Please try again later.', callBack);
  }

  //function that fire when the infinite scroll reach bottom
  const loadMore2 = ()=>{
    //do not load if there is no more conditions or it's loading data
    if(state.hasMoreOrders&&!state.loading){
      //set loading equals to true so it won't fire off before we are done
      setState({loading:true});

      let url = 'appraisal/get/limit='+state.limit+'&offset='+state.offset+'&order='+state.order+'&sort='+state.sort+'&open_close_client='+state.id;

      //callback handler that update the state when http request return
      let callBack = (response)=>{
        let code = response.data?response.data.code:undefined;

        if(code!=='00'){
          setState({hasMoreOrders:false});
        }
        else{
          let newOrders = response.data.data;
          let hasMoreOrders = true;
          let newOffset = state.offset;
          let totalCount = response.data.count;

          //if http request return empty then no more results, end of list
          if(newOrders.length<=0){
            hasMoreOrders = false;
          }
          else{
            //increment the offset
            newOffset = state.offset + 1;
          }

          //concat the current array of history orders
          if(state.historyOrders.length>0){
            let temp = [...state.historyOrders,...newOrders];
            newOrders = temp;
          }

          setState({historyOrders:newOrders,hasMoreOrders:hasMoreOrders,offset:newOffset,totalCount:totalCount});
        }
      };
      callBack = callBack.bind(this);

      //error handler when the http request return with error
      let errorCallBack = apiCallBack([{state:'hasMoreOrders', value:false}]);

      //collect the promise and wait for it to finish performing it's task
      let promises = httpGet(url, '','Oops, something went wrong and could not load orders. Please try again later.', callBack, errorCallBack);
      promises
        .then(
          function(result){
            //set loading equals to false so the function could be fire off once again
            setState({loading:false});
          }
        );
    }
  }

  const updateChecklist = (checklist)=>{
    let url = 'client/profile/checklist/update';
    let successMsg = 'Checklist updated successfully.';
    let errorMsg = 'Oops, something went wrong and could not save the checklist. Please try again later.';

    let parameters = [
      {
        field:'clientFk',
        value:state.id
      },
      {
        field:'checklistFk',
        value:checklist.ID
      },
      {
        field:'action',
        value:checklist.checked==='yes'?'delete':'add'
      }
    ];

    let client = Object.assign({}, state.client);
    let checklists = client.checklists.slice();

    for(let i=0;i<checklists.length;i++){
      if(checklists[i].ID===checklist.ID){
        checklists[i].checked = checklist.checked==='yes'?'no':'yes';
        checklists[i].answer = '';
        break;
      }
    }
    client.checklists = checklists;

    let callBack = apiCallBack([{state:'client', value:client}]);
    httpPut(url, parameters, successMsg, errorMsg, callBack);
  }


  //render
  let successMessage = 'Client profile updated.';
  let failedMessage = 'Failed to update the value of this field, please try again later.';

  let masterUsers, branches;

  if(state.branches.length>0){
    branches = state.branches.map(
      (branch,index)=>{
        return(
          <tr key={index}>
            <td><i className="fa fa-minus cursor-pointer red-color" onClick={()=>{
              confirmation(
                ()=>{removeBranch(branch.ID)},
                ()=>{},
                'Delete client branch?',
                'Are you sure you want to delete this client branch?');
            }}></i>&nbsp;&nbsp;<NavLink to={"/client/"+branch.client_fk+'/'+state.params+"/branch/"+branch.ID}>{branch.branch_name}</NavLink></td>
            <td>{branch.street+' '+branch.city+', '+branch.state+' '+branch.zip}</td>
            <td>{formatDate(branch.datetime_created)}</td>
          </tr>
        );
      }
    );
  }

  let stateName;

  if(state.states.length>0){
    stateName =
     <MyXEditable
      type="select"
      value={state.client.state}
      opt={state.states}
      updateFunc={
        generateUpdateFunction('client/update',[{field:'field',value:'state'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
      }
    />;

  }

  if(state.masterUsers.length>0){
    masterUsers = state.masterUsers.map(
      (masterUser,index)=>{
        return(
          <tr key={index}>
            <td>
              <i className="fa fa-minus cursor-pointer red-color" onClick={()=>removeMasterUser(masterUser.ID)}></i>&nbsp;&nbsp;
              <div className="display-inline">
                <MyXEditable
                  type="select"
                  opt={[{key:'activated',value:'activated'},{key:'deactivated',value:'deactivated'}]}
                  value={masterUser.status}
                  updateFunc={
                    generateUpdateFunction('client/user/update',[{field:'field',value:'status'},{field:'ID',value:masterUser.branch_user_fk}],successMessage, failedMessage, updateFieldCallBack)
                  }
                />
              </div>
            </td>
            <td>{masterUser.first_name+' '+masterUser.last_name}</td>
            <td>{masterUser.email}<br/>{masterUser.phone}</td>
          </tr>
        );
      }
    );
  }

  let users;
  if(state.users.length>0){
    users = state.users.map(
      (user,index)=>{
        return(
          <tr key={index}>
            <td><i className="fa fa-arrow-up cursor-pointer green-color" onClick={()=>submitNewMasterUser(user.user_fk)}></i></td>
            <td>{user.first_name+' '+user.last_name}</td>
            <td>{user.email}</td>
            <td>{user.phone}</td>
          </tr>
        );
      }
    );
  }


  let temporaryProfileIcon;

  if(state.client.public_created==='yes')
    temporaryProfileIcon = <font className="flashit" color="red">TEMPORARY PROFILE</font>;

  let retailOther;
  let wholesaleOther;

  if(state.addNewRetailClientContactRole==='Other')
    retailOther = <div>
      <br/><Input type="text" placeholder="Role description" value={state.addNewRetailClientContactRoleOther} onChange={(e)=>setState({addNewRetailClientContactRoleOther:e.target.value})}/>
    </div>;
  if(state.addNewWholesaleClientContactRole==='Other')
    wholesaleOther = <div>
      <br/><Input type="text" placeholder="Role description" value={state.addNewWholesaleClientContactRoleOther} onChange={(e)=>setState({addNewWholesaleClientContactRoleOther:e.target.value})}/>
    </div>;

  return <div className="my-well">
    <Modal className="my-modal" isOpen={state.consolidateDropDownOpen} toggle={consolidateDropDownToggle} >
      <ModalHeader hidden={true} toggle= {consolidateDropDownToggle}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-bank"></i> Consolidate Client Profile</h5>
        </center>
        <br/><br/><br/><br/>
        <form onSubmit={consolidateClientProfile}>
          <label>Target Client Profile</label>
          <MySelect
            modal={true}
            type="select"
            value={state.targetConsolidateClientFk}
            onChange={(v)=>setState({targetConsolidateClientFk:v})}
            options={state.clients.map((client)=>{
              return {label:client.value+' - '+formatDate(client.datetime_created), value:client.key};
            })}
          />
          <br/>
          <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
          <center>
            <Button color="warning"><i className="fa fa-check"></i>&nbsp;Submit</Button>{' '}
            <Button color="info" onClick={consolidateDropDownToggle}>Close</Button>
          </center>
        </form>
      </ModalBody>
    </Modal>
    <Modal className="my-modal-wide" isOpen={state.editRetailPopup} toggle={toggleSelectRetail} >
      <ModalHeader hidden={true} toggle={toggleSelectRetail}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-user"></i> Edit Selected Retail Client Contact</h5>
        </center>
        <Row>
          <Col sm="8">
            <label>Branch</label><br/>
            <MySelect
              modal={true}            
              type="select"
              value={state.selectedRetail.client_branch_fk}
              onChange={(v)=>setState({selectedRetail:Object.assign({}, state.selectedRetail,{client_branch_fk:v})})}
              options={state.branches.map((branch)=>{
                return {label:branch.branch_name, value:branch.ID};
              })}
            />
          </Col>
          <Col sm="4">
            <div className="align-right">
              <NavLink to="#" onClick={(e)=>toggleAddNewClientContactRetailRole()}><i className="fa fa-plus green-color"/> Add New Client Contact</NavLink>
            </div>
          </Col>
        </Row>
        <br/>
        <Row>
          {
            state.selectedRetail.contacts.map(
              (contact, index)=>{
                return(
                  <Col sm="3" key={index}>
                    <div className="my-well" style={{height:'350px'}}>
                      <div className="align-right"><i className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeNewRetailClientContact(index)}/></div>
                      <b>{contact.label}</b><br/>
                      <label>Name</label><br/>
                      <Input type="text" value={contact.name} onChange={(e)=>updateSelectedRetailClientContact(index, 'name', e.target.value)}/><br/>
                      <label>Email</label><br/>
                      <Input type="text" value={contact.email} onChange={(e)=>updateSelectedRetailClientContact(index, 'email', e.target.value)}/><br/>
                      <label>Phone</label><br/>
                      <Input type="text" value={contact.phone} onChange={(e)=>updateSelectedRetailClientContact(index, 'phone', e.target.value)}/><br/>
                    </div><br/>
                  </Col>
                )
              }
            )
          }
        </Row>
        <center>
          <Button color="info" onClick={toggleSelectRetail}>Close</Button>&nbsp;<Button color="warning" onClick={updateRetail}>Update</Button>
        </center>
      </ModalBody>
    </Modal>

    <Modal className="my-modal" isOpen={state.addNewClientContactRetailRolePopup} toggle={toggleAddNewClientContactRetailRole} >
      <ModalHeader hidden={true} toggle={toggleAddNewClientContactRetailRole}></ModalHeader>
      <ModalBody>
        <label>Role</label>
        <MySelect
          modal={true}
          type="select"
          value={state.addNewRetailClientContactRole}
          onChange={(v)=>setState({addNewRetailClientContactRole:v})}
          options={state.clientEntityList.map((role)=>{
            return {label:role, value:role};
          })}
        />
        {retailOther}
        <br/>
        <center>
          <Button color="info" onClick={toggleAddNewClientContactRetailRole}>Close</Button>&nbsp;<Button color="warning" onClick={(e)=>addNewRetailClientContact()}>Add</Button>
        </center>
      </ModalBody>
    </Modal>

    <Modal className="my-modal" isOpen={state.addNewClientContactWholesaleRolePopup} toggle={toggleAddNewClientContactWholesaleRole} >
      <ModalHeader hidden={true} toggle={toggleAddNewClientContactWholesaleRole}></ModalHeader>
      <ModalBody>
        <label>Role</label>
        <MySelect
          modal={true}
          type="select"
          value={state.addNewWholesaleClientContactRole}
          onChange={(v)=>setState({addNewWholesaleClientContactRole:v})}
          options={state.clientEntityList.map((role)=>{
            return {label:role, value:role};
          })}
        />

        {wholesaleOther}
        <br/>
        <center>
          <Button color="info" onClick={toggleAddNewClientContactWholesaleRole}>Close</Button>&nbsp;<Button color="warning" onClick={(e)=>addNewWholesaleClientContact()}>Add</Button>
        </center>
      </ModalBody>
    </Modal>

    <Modal className="my-modal-wide" isOpen={state.editWholesalePopup} toggle={toggleSelectWholesale} >
      <ModalHeader hidden={true} toggle={toggleSelectWholesale}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-user"></i> Edit Selected Wholesale Client Contact</h5>
        </center>
        <div className="align-right">
          <NavLink to="#" onClick={(e)=>toggleAddNewClientContactWholesaleRole()}><i className="fa fa-plus green-color"/> Add New Client Contact</NavLink>
        </div>
        <Row>
          <Col sm="6">
            <label>Branch</label><br/>
            <MySelect
              modal={true}
              type="select"
              value={state.selectedWholesale.client_branch_fk}
              onChange={(v)=>setState({selectedWholesale:Object.assign({}, state.selectedWholesale,{client_branch_fk:v })})}
              options={state.branches.map((branch)=>{
                return {label:branch.branch_name, value:branch.ID};
              })}
            />
          </Col>
          <Col sm="6">
            <label>Client</label><br/>
            <MySelect
              modal={true}
              type="select"
              value={state.selectedWholesale.close_by_client_fk}
              onChange={(v)=>setState({selectedWholesale:Object.assign({}, state.selectedWholesale,{close_by_client_fk:v})})}
              options={state.wholesaleClientContacts.map((client)=>{
                return {label:client.company, value:client.associated_client_fk};
              })}
            />
          </Col>
        </Row>
        <br/>
        <Row>
          {
            state.selectedWholesale.contacts.map(
              (contact, index)=>{
                return(
                  <Col sm="3" key={index}>
                    <div className="my-well" style={{height:'350px'}}>
                      <div className="align-right"><i className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeNewWholesaleClientContact(index)}/></div>
                      <b>{contact.label}</b><br/>
                      <label>Name</label><br/>
                      <Input type="text" value={contact.name} onChange={(e)=>modifySelectedWholesaleClientContact(index, 'name', e.target.value)}/><br/>
                      <label>Email</label><br/>
                      <Input type="text" value={contact.email} onChange={(e)=>modifySelectedWholesaleClientContact(index, 'email', e.target.value)}/><br/>
                      <label>Phone</label><br/>
                      <Input type="text" value={contact.phone} onChange={(e)=>modifySelectedWholesaleClientContact(index, 'phone', e.target.value)}/><br/>
                    </div><br/>
                  </Col>
                )
              }
            )
          }
        </Row>

        <center>
          <Button color="info" onClick={toggleSelectWholesale}>Close</Button>&nbsp;<Button color="warning" onClick={updateWholesale}>Update</Button>
        </center>
      </ModalBody>
    </Modal>


    <Modal className="my-modal-wide" isOpen={state.createNewRetailPopup} toggle={toggleCreateNewRetail} >
      <ModalHeader hidden={true} toggle={toggleCreateNewRetail}></ModalHeader>
      <ModalBody>
        <label>New Retail Client Contact</label>
        <br/>
        <label>Branch</label>
        <MySelect
          modal={true}
          type="select"
          value={state.clientBranchFk}
          onChange={(v)=>setState({clientBranchFk:v})}
          options={state.branches.map((branch)=>{
            return {label:branch.branch_name, value:branch.ID};
          })}
        />
        <div className="align-right">
          <NavLink to="#" onClick={(e)=>toggleAddNewClientContactRetailRole()}><i className="fa fa-plus green-color"/> Add New Client Contact</NavLink>
        </div>
        <Row>
          {
            state.newRetailClientContacts.map(
              (contact, index)=>{
                return (
                  <Col sm="3" key={index}>
                    <div className="my-well" style={{height:'350px'}}>
                      <div className="align-right"><i className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeCreateNewRetailClientContact(index)}/></div>
                      <b>{contact.label}</b><br/>
                      <label><i className="fa fa-times red-color cursor-pointer" onClick={(e)=>{updateCreateNewRetailClientContact(index, 'name', '');updateCreateNewRetailClientContact(index, 'email', '');updateCreateNewRetailClientContact(index, 'phone', '');}}></i> Name</label><br/>
                      {(contact.label==='Borrower'||contact.label==='Co-Borrower'||contact.label==='Other'||contact.label==='Payment Contact')?<Input type="text" value={contact.name} onChange={(e)=>updateCreateNewRetailClientContact(index, 'name', e.target.value)}/>:<MySelect
                        type="async-select"
                        value={contact.name}
                        onChange={(e)=>handleAsyncSelectChange2(e,index)}
                        defaultOptions
                        loadOptions={(keyword)=>getClientContacts(keyword, contact.label)}
                      />}
                      <br/>
                      <label>Email</label><br/>
                      <Input type="text" value={contact.email} onChange={(e)=>updateCreateNewRetailClientContact(index, 'email', e.target.value)}/><br/>
                      <label>Phone</label><br/>
                      <Input type="text" value={contact.phone} onChange={(e)=>updateCreateNewRetailClientContact(index, 'phone', e.target.value)}/><br/>
                    </div><br/>
                    <br/>
                  </Col>
                )
              }
            )
          }
        </Row>
        <center>
          <Button color="info" onClick={toggleCreateNewRetail}>Close</Button>&nbsp;<Button color="warning" onClick={updateSelectedWholesaleClientContact}>Create</Button>
        </center>
      </ModalBody>
    </Modal>

    <Modal className="my-modal-wide" isOpen={state.createNewWholesalePopup} toggle={toggleCreateNewWholesale} >
      <ModalHeader hidden={true} toggle={toggleCreateNewWholesale}></ModalHeader>
      <ModalBody>
        <label>New Wholesale Client Contact</label>
        <br/>
        <form onSubmit={createNewClientContactWholesale}>
          <Row>
            <Col sm="6">
              <label>Branch</label>
              <MySelect
                modal={true}
                type="select"
                value={state.clientBranchFk}
                onChange={(v)=>setState({clientBranchFk:v})}
                options={state.branches.map((branch)=>{
                  return {label:branch.branch_name, value:branch.ID};
                })}
              />
            </Col>
            <Col sm="6">
              <label><font color="red">*</font>Client</label>
              <MySelect
                modal={true}
                type="select"
                value={state.newClientContactWholesaleClient}
                onChange={(v)=>setState({newClientContactWholesaleClient:v})}
                options={state.wholesaleClientContacts.map((client)=>{
                  return {label:client.company, value:client.associated_client_fk};
                })}
              />
            </Col>
          </Row>

          <div className="align-right">
            <NavLink to="#" onClick={(e)=>toggleAddNewClientContactWholesaleRole()}><i className="fa fa-plus green-color"/> Add New Client Contact</NavLink>
          </div>
          <Row>
            {
              state.newWholesaleClientContacts.map(
                (contact, index)=>{
                  return (
                    <Col sm="3" key={index}>
                      <div className="my-well" style={{height:'350px'}}>
                        <div className="align-right"><i className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeCreateNewWholesaleClientContact(index)}/></div>
                        <b>{contact.label}</b><br/>
                        <label><i className="fa fa-times red-color cursor-pointer" onClick={(e)=>{updateCreateNewWholesaleClientContact(index, 'name', '');updateCreateNewWholesaleClientContact(index, 'email', '');updateCreateNewWholesaleClientContact(index, 'phone', '');}}></i> Name</label><br/>
                        {(contact.label==='Borrower'||contact.label==='Co-Borrower'||contact.label==='Other'||contact.label==='Payment Contact')?<Input type="text" value={contact.name} onChange={(e)=>updateCreateNewWholesaleClientContact(index, 'name', e.target.value)}/>:<MySelect
                          type="async-select"
                          value={contact.name}
                          onChange={(e)=>handleAsyncSelectChange1(e,index)}
                          loadOptions={(keyword)=>getClientContacts(keyword, contact.label)}
                        />}
                        <br/>
                        <label>Email</label><br/>
                        <Input type="text" value={contact.email} onChange={(e)=>updateCreateNewWholesaleClientContact(index, 'email', e.target.value)}/><br/>
                        <label>Phone</label><br/>
                        <Input type="text" value={contact.phone} onChange={(e)=>updateCreateNewWholesaleClientContact(index, 'phone', e.target.value)}/><br/>
                      </div><br/>
                      <br/>
                    </Col>
                  )
                }
              )
            }
          </Row>
          <center>
            <Button color="info" onClick={toggleCreateNewWholesale}>Close</Button>&nbsp;<Button color="warning">Create</Button>
          </center>
        </form>
      </ModalBody>
    </Modal>

    <Row>
      <Col sm="6">
        <div className="page-title">
          <i className="fa fa-reorder"></i>&nbsp;Client Profile - {state.client.company} {temporaryProfileIcon}
        </div>
        <NavLink to={"/client/"+state.params}>Back to client list</NavLink>
      </Col>
      <Col sm="6" className="align-right">
        <div className="page-title">
          &nbsp;
        </div>
        <NavLink target="_blank" to={"/broker-relationship/"+state.id}>View Relationship [Deprecated]</NavLink>
      </Col>
    </Row>
    <div className="my-divider"></div>

    <Row>
      <Col sm="8">
        <Nav tabs>
          <NavItem>
            <NavLinkL
              className={"nav-link "+(state.activeTab === '1'?"active":"inactive" )}
              onClick={() => { setState({activeTab:'1'}) }}
              to="#"
            >
              Company Information
            </NavLinkL>
          </NavItem>
          <NavItem>
            <NavLinkL
              className={"nav-link "+(state.activeTab === '2'?"active":"inactive" ) }
              onClick={() => { setState({activeTab:'2'}) }}
              to="#"
            >
              Client Contacts & Wholesale - Broker
            </NavLinkL>
          </NavItem>
          <NavItem>
            <NavLinkL
              className={"nav-link "+(state.activeTab === '3'?"active":"inactive" ) }
              onClick={() => { setState({activeTab:'3'}) }}
              to="#"
            >
              Order History
            </NavLinkL>
          </NavItem>
          <NavItem>
            <NavLinkL
              className={"nav-link "+(state.activeTab === '4'?"active":"inactive" ) }
              onClick={() => { setState({activeTab:'4'}) }}
              to="#"
            >
              Files
            </NavLinkL>
          </NavItem>
        </Nav>
      </Col>
      <Col className="align-right" sm="4">
        <NavLink to="#" onClick={consolidateDropDownToggle}>Consolidate Profile</NavLink>
      </Col>
    </Row>
    <TabContent activeTab={state.activeTab}>
      <TabPane tabId="1">
        <Card>
          <CardHeader className="header-color">
            Basic Information
          </CardHeader>
          <CardBody>
            <Row>
              <Col sm="6">
                <Row>
                  <Col sm="4">
                    Created Date
                  </Col>
                  <Col sm="8">
                    {formatDate(state.client.datetime_created)}
                  </Col>
                </Row>
                <Row>
                  <Col sm="4">
                    Author
                  </Col>
                  <Col sm="8">
                    {state.client.author}
                  </Col>
                </Row>
                <Row>
                  <Col sm="4">
                    Company
                  </Col>
                  <Col sm="8">
                    <MyXEditable
                      type="text"
                      value={state.client.company}
                      updateFunc={
                        generateUpdateFunction('client/update',[{field:'field',value:'company'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="4">
                    Name On Report
                  </Col>
                  <Col sm="8">
                    <MyXEditable
                      type="text"
                      value={state.client.name_on_report}
                      updateFunc={
                        generateUpdateFunction('client/update',[{field:'field',value:'name_on_report'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="4">
                    Status
                  </Col>
                  <Col sm="8">
                    <MyXEditable
                      type="select"
                      opt={[{key:'Approved',value:'Approved'},{key:'Declined',value:'Declined'}]}
                      value={state.client.status}
                      updateFunc={
                        generateUpdateFunction('client/update',[{field:'field',value:'status'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="4">
                    Email
                  </Col>
                  <Col sm="8">
                    <MyXEditable
                      type="text"
                      value={state.client.email}
                      updateFunc={
                        generateUpdateFunction('client/update',[{field:'field',value:'email'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="4">
                    Phone
                  </Col>
                  <Col sm="8">
                    <MyXEditable
                      type="text"
                      value={state.client.phone}
                      updateFunc={
                        generateUpdateFunction('client/update',[{field:'field',value:'phone'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="4">
                    Fax
                  </Col>
                  <Col sm="8">
                    <MyXEditable
                      type="text"
                      value={state.client.fax}
                      updateFunc={
                        generateUpdateFunction('client/update',[{field:'field',value:'fax'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="4">
                    Street
                  </Col>
                  <Col sm="8">
                    <MyXEditable
                      type="text"
                      value={state.client.street}
                      updateFunc={
                        generateUpdateFunction('client/update',[{field:'field',value:'street'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="4">
                    City
                  </Col>
                  <Col sm="8">
                    <MyXEditable
                      type="text"
                      value={state.client.city}
                      updateFunc={
                        generateUpdateFunction('client/update',[{field:'field',value:'city'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="4">
                    County
                  </Col>
                  <Col sm="8">
                    <MyXEditable
                      type="text"
                      value={state.client.county}
                      updateFunc={
                        generateUpdateFunction('client/update',[{field:'field',value:'county'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="4">
                    State
                  </Col>
                  <Col sm="8">
                    {stateName}
                  </Col>
                </Row>
                <Row>
                  <Col sm="4">
                    Zip
                  </Col>
                  <Col sm="8">
                    <MyXEditable
                      type="text"
                      value={state.client.zip}
                      updateFunc={
                        generateUpdateFunction('client/update',[{field:'field',value:'zip'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>

                <br/><br/>
                <Row>
                  <Col sm="4">
                    Payment Type
                  </Col>
                  <Col sm="8">
                    <MyXEditable
                      type="select"
                      opt={state.paymentMethods}
                      value={state.client.payment_type}
                      updateFunc={
                        generateUpdateFunction('client/update',[{field:'field',value:'payment_type'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="4">
                    Covered By
                  </Col>
                  <Col sm="8">
                    <MyXEditable
                      type="select"
                      opt={[{key:'Broker',value:'Broker'},{key:'Borrower',value:'Borrower'},{key:'Lender',value:'Lender'},{key:'Client',value:'Client'},{key:'Paid Outside Of Closing',value:'Paid Outside Of Closing'},{key:'Paid at Closing',value:'Paid at Closing'}]}
                      value={state.client.covered_by}
                      updateFunc={
                        generateUpdateFunction('client/update',[{field:'field',value:'covered_by'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="4">
                    FNM
                  </Col>
                  <Col sm="8">
                    <MyXEditable
                      type="text"
                      value={state.client.ssr_fnm}
                      updateFunc={
                        generateUpdateFunction('client/update',[{field:'field',value:'ssr_fnm'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="4">
                    FRE
                  </Col>
                  <Col sm="8">
                    <MyXEditable
                      type="text"
                      value={state.client.ssr_fre}
                      updateFunc={
                        generateUpdateFunction('client/update',[{field:'field',value:'ssr_fre'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="4">
                    Business Unit #
                  </Col>
                  <Col sm="8">
                    <MyXEditable
                      type="text"
                      value={state.client.ssr_business_unit}
                      updateFunc={
                        generateUpdateFunction('client/update',[{field:'field',value:'ssr_business_unit'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="4">
                    Business Unit # (FHA)
                  </Col>
                  <Col sm="8">
                    <MyXEditable
                      type="text"
                      value={state.client.fha_ssr_business_unit}
                      updateFunc={
                        generateUpdateFunction('client/update',[{field:'field',value:'fha_ssr_business_unit'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="4">
                    FHA Client ID
                  </Col>
                  <Col sm="8">
                    <MyXEditable
                      type="text"
                      value={state.client.fha_client_id}
                      updateFunc={
                        generateUpdateFunction('client/update',[{field:'field',value:'fha_client_id'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>

                <Row>
                  <Col sm="4">
                    Default Close By Lender
                  </Col>
                  <Col sm="8">
                    <MyXEditable
                      type="select"
                      opt={state.clients}
                      value={state.client.default_close_by}
                      updateFunc={
                        generateUpdateFunction('client/update',[{field:'field',value:'default_close_by'},{field:'ID',value:state.id}],successMessage, failedMessage)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="4">
                    Payment Contact
                  </Col>
                  <Col sm="8">
                    <MyXEditable
                      type="text"
                      value={state.client.payment_contact}
                      updateFunc={
                        generateUpdateFunction('client/update',[{field:'field',value:'payment_contact'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>

                <br/>
                <Card>
                  <CardHeader>
                    Profile Checklist
                  </CardHeader>
                  <CardBody>
                    <Table className="table table-striped">
                      <tbody>
                        {
                          state.client.checklists.map(
                            (checklist, index)=>{
                              return <tr key={index}>
                                <td>&nbsp;&nbsp;&nbsp;&nbsp;<Input type="checkbox" checked={checklist.checked==='yes'} onClick={(e)=>updateChecklist(checklist)}/> {checklist.description}
                                  {checklist.checked==='yes'&&<Row><Col sm="1" className="align-right" style={{paddingRight:'0px'}}><i className="fa fa-caret-right"></i>&nbsp;</Col>
                                    <Col sm="11" style={{fontSize:'14px', paddingLeft:'0px'}} className="align-left"><MyXEditable
                                      type="text"
                                      value={checklist.answer!==''?checklist.answer:"Comment your answer"}
                                      updateFunc={
                                        generateUpdateFunction('client/profile/checklist/update',[{field:'field',value:'checklists'},{field:'clientFk',value:state.id}, {field:'checklistFk',value:checklist.ID}, {field:'action',value:"answer"}],"Record the answer successfully", "Failed to update the answer, please try again later.", updateFieldCallBack)
                                      }
                                    /></Col></Row>}
                                </td>
                              </tr>
                            }
                          )
                        }
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
              <Col sm="6">
                <label>General Note</label><br/>
                <div className="small-scroll-container-ex-large">
                  <MyXEditable
                      type="textarea"
                      passiveTextColor="#1B53B3"
                      prefix=""
                      value={state.client.general_note}
                      updateFunc={
                        generateUpdateFunction('client/update',[{field:'field',value:'general_note'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                   />
                </div>
                <div className="small-scroll-container-ex-large">
                 <label>Assignment Note</label><br/>
                 <MyXEditable
                     type="textarea"
                     passiveTextColor="#1B53B3"
                     prefix=""
                     value={state.client.assignment_note}
                     updateFunc={
                       generateUpdateFunction('client/update',[{field:'field',value:'assignment_note'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                     }
                  />
                </div>
                <div className="small-scroll-container-ex-large">
                  <label>Billing Note</label><br/>
                  <MyXEditable
                      type="textarea"
                      passiveTextColor="#1B53B3"
                      prefix=""
                      value={state.client.billing_note}
                      updateFunc={
                        generateUpdateFunction('client/update',[{field:'field',value:'billing_note'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                      }
                   />
                 </div>
                 <div className="small-scroll-container-ex-large">
                   <label>Review Note</label><br/>
                   <MyXEditable
                       type="textarea"
                       passiveTextColor="#1B53B3"
                       prefix=""
                       value={state.client.review_note}
                       updateFunc={
                         generateUpdateFunction('client/update',[{field:'field',value:'review_note'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                       }
                    />
                  </div>
                  <div className="small-scroll-container-ex-large">
                    <label>Condition / Rebuttal Note</label><br/>
                    <MyXEditable
                        type="textarea"
                        passiveTextColor="#1B53B3"
                        prefix=""
                        value={state.client.condition_rebuttal_note}
                        updateFunc={
                          generateUpdateFunction('client/update',[{field:'field',value:'condition_rebuttal_note'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                        }
                     />
                   </div>
                   <div className="small-scroll-container-ex-large">
                     <label>Shipping Note</label><br/>
                     <MyXEditable
                         type="textarea"
                         passiveTextColor="#1B53B3"
                         prefix=""
                         value={state.client.shipping_note}
                         updateFunc={
                           generateUpdateFunction('client/update',[{field:'field',value:'shipping_note'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                         }
                      />
                    </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <br/>
        <Row>
          <Col sm="6">
            <Card>
              <CardHeader className="header-color">
                Master User
              </CardHeader>
              <CardBody>
                <div className="align-right">
                  <Button color="warning" onClick={addNewMasterUserDropDownToggle}>Add</Button>
                  <br/>
                  <br/>
                </div>
                <div className="large-scroll-container">
                  <Table className="table table-striped">
                    <thead>
                      <tr>
                        <th width="20%">Status</th>
                        <th width="20%">Name</th>
                        <th width="60%">Email + Phone</th>
                      </tr>
                    </thead>
                    <tbody>
                      {masterUsers}
                    </tbody>
                  </Table>
                  <div className="align-right">
                    <Modal className="my-modal" isOpen={state.addNewMasterUserDropDownOpen} toggle={addNewMasterUserDropDownToggle} >
                      <ModalHeader hidden={true} toggle={addNewMasterUserDropDownToggle}></ModalHeader>
                      <ModalBody>
                        <center>
                          <h5><i className="fa fa-user"></i> Add New Master User</h5>
                        </center>
                        <br/>
                        <form onSubmit={submitNewMasterUser}>
                          <Row style={{marginBottom:'10px'}}>
                            <Col sm="4">
                              <label>Name</label><br/>
                              <Input type="text" value={state.name} onChange={(e)=>setState({name:e.target.value})}/>
                            </Col>
                            <Col sm="4">
                              <label>Email</label><br/>
                              <Input type="text" value={state.email} onChange={(e)=>setState({email:e.target.value})}/>
                            </Col>
                            <Col sm="4" className="align-right">
                              <br/>
                              <Button color="warning" onClick={resetSearchUser}>Submit</Button>
                            </Col>
                          </Row>
                          <div className="large-scroll-container">
                            <InfiniteScroll
                              next={loadMore}
                              dataLength={state.users.length}
                              hasMore={state.hasMoreUsers}
                              loader={<div key="nill" className="loader"><center>Loading more users...</center></div>}
                              initialLoad = {true}
                              className="my-well"
                              scrollableTarget="contentContainer"
                            >

                              <Table className="table table-striped">
                                <thead>
                                  <tr>
                                    <th width="10%">Action</th>
                                    <th width="35%">Name</th>
                                    <th width="30%">Email</th>
                                    <th width="25%">Phone</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {users}
                                </tbody>
                              </Table>
                            </InfiniteScroll>
                          </div>
                          <br/>
                          <center>
                            <Button color="info" onClick={addNewMasterUserDropDownToggle}>Close</Button>
                          </center>
                        </form>
                      </ModalBody>
                    </Modal>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="6">
            <Card>
              <CardHeader className="header-color">
                Branches
              </CardHeader>
              <CardBody>
                <div className="align-right">
                  <Button color="warning" onClick={addNewBranchDropDownToggle}>Add</Button>
                  <br/>
                  <br/>
                </div>
                <div className="large-scroll-container">
                  <Table className="table table-striped">
                    <thead>
                      <tr>
                        <th width="30%">Branch Name</th>
                        <th width="50%">Address</th>
                        <th width="20%">Date Created</th>
                      </tr>
                    </thead>
                    <tbody>
                      {branches}
                    </tbody>
                  </Table>
                  <Modal className="my-modal" isOpen={state.addNewBranchDropDownOpen} toggle={addNewBranchDropDownToggle} >
                    <ModalHeader hidden={true} toggle= {addNewBranchDropDownToggle}></ModalHeader>
                    <ModalBody>
                      <center>
                        <h5><i className="fa fa-bank"></i> Add New Branch</h5>
                      </center>
                      <br/>
                      <form onSubmit={submitNewBranch}>
                        <label><font color="red">*</font>Branch Name:</label>
                        <Input type="text" required="true" value={state.newBranchName} onChange={(e)=>setState({newBranchName:e.target.value})}/>
                        <br/>
                        <label><font color="red">*</font>Phone:</label>
                        <Input type="text" required="true" value={state.newBranchPhone} onChange={(e)=>setState({newBranchPhone:e.target.value})}/>
                        <br/>
                        <label><font color="red">*</font>Street:</label>
                        <Input type="text" required="true" value={state.newBranchStreet} onChange={(e)=>setState({newBranchStreet:e.target.value})}/>
                        <br/>
                        <Row>
                          <Col sm="7">
                            <label><font color="red">*</font>City:</label>
                            <Input type="text" required="true" value={state.newBranchCity} onChange={(e)=>setState({newBranchCity:e.target.value})}/>
                            <br/>
                          </Col>
                          <Col sm="5">
                            <label><font color="red">*</font>County:</label>
                            <Input type="text" required="true" value={state.newBranchCounty} onChange={(e)=>setState({newBranchCounty:e.target.value})}/>
                            <br/>
                          </Col>
                        </Row>
                        <br/>
                        <Row>
                          <Col sm="7">
                            <label><font color="red">*</font>State:</label>
                            <MySelect
                              type="select"
                              value={state.newBranchState}
                              onChange={(v)=>setState({newBranchState:v})}
                              options={state.states.map((state)=>{
                                return {label:state.value, value:state.value};
                              })}
                            />
                            <br/>
                          </Col>
                          <Col sm="5">
                            <label><font color="red">*</font>Zip:</label>
                            <Input type="text" required="true" value={state.newBranchZip} onChange={(e)=>setState({newBranchZip:e.target.value})}/>
                            <br/>
                          </Col>
                        </Row>
                        <br/>
                        <br/>
                        <center>
                          <Button color="warning"><i className="fa fa-check"></i>&nbsp;Add</Button>{' '}
                          <Button color="info" onClick={addNewBranchDropDownToggle}>Close</Button>
                        </center>
                      </form>
                    </ModalBody>
                  </Modal>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>


      </TabPane>
      <TabPane tabId="2">
        <Card>
          <CardHeader className="header-color">
            Associated Wholesale Client
          </CardHeader>
          <CardBody>
            <Row>
              <Col sm="3">
                <label>New Wholesale Associated Client</label>
              </Col>
              <Col sm="6">
                <MySelect
                  type="select"
                  value={state.newWholesaleClient}
                  onChange={(v)=>setState({newWholesaleClient:v})}
                  options={state.clients.map((client)=>{
                    return {label:client.value, value:client.key};
                  })}
                />
              </Col>
              <Col sm="3">
                <Button color="warning" onClick={createWholesaleAssociate}>Add</Button>
              </Col>
            </Row>
            <br/>
            {
              state.wholesaleClientContacts.map(
                (client, key)=>{
                  if(client.global_wholesale_client && client.global_wholesale_client==='yes')
                    return null;
                  else
                  return(
                    <div key={key} className="entity-container">
                      <i className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeWholesaleAssociate(client.ID)}/> {client.company}
                    </div>
                  )
                }
              )
            }
          </CardBody>
        </Card>

        <br/><br/>

        <Nav tabs>
          <NavItem>
            <NavLinkL
              className={"nav-link "+(state.subActiveTab === '1'?"active":"inactive" )}
              onClick={() => { setState({subActiveTab:'1'}) }}
              to="#"
            >
              Retail Client Contacts<span className="my-badge">{state.retail.length}</span>
            </NavLinkL>
          </NavItem>
          <NavItem>
            <NavLinkL
              className={"nav-link "+(state.subActiveTab === '2'?"active":"inactive" ) }
              onClick={() => { setState({subActiveTab:'2'}) }}
              to="#"
            >
              Wholesale Client Contacts<span className="my-badge">{state.wholesale.length}</span>
            </NavLinkL>
          </NavItem>
        </Nav>
        <TabContent activeTab={state.subActiveTab}>
          <TabPane tabId="1">
            <Card>
              <CardHeader className="header-color">
                Retail Client Contact
              </CardHeader>
              <CardBody>
                <Row>
                  <Col sm="6">
                    <Input type="text" placeholder="Name/Email" value={state.retailKeyword} onChange={(e)=>setState({retailKeyword:e.target.value})}/>&nbsp;&nbsp;
                  </Col>
                  <Col sm="3">
                    <MySelect
                      type="select"
                      value={state.retailBranch}
                      onChange={(v)=>setState({retailBranch:v})}
                      options={state.branches.map((branch)=>{
                        return {label:branch.branch_name, value:branch.ID};
                      })}
                    />
                  </Col>
                  <Col sm="3" className="align-right">
                    <Button color="warning" onClick={getRetailClientContacts}>Search</Button>
                  </Col>
                </Row>
                <div className="align-right">
                  <NavLink to="#" onClick={toggleCreateNewRetail}><i className="fa fa-plus green-color cursor-pointer"/> New Client Contact</NavLink>
                </div>
                <Row>
                  {
                    state.retail.map(
                      (entry, index)=>{
                        return <Col key={index} sm="3">
                          <div>
                            <div className="my-well padding" style={{height:'340px'}}>
                              <Row>
                                <Col sm="2"><i className="fa fa-times red-color cursor-pointer" onClick={
                                  (e)=>{
                                    confirmation(
                                      ()=>{removeClientContact(entry.ID)},
                                      ()=>{},
                                      'Delete client contact?',
                                      'Are you sure you want to delete this client contact?');
                                  }
                                }/></Col>
                                <Col sm="8">
                                  <div style={{fontSize:'11px'}}>
                                    <center>
                                      {entry.client_branch_name}
                                    </center>
                                  </div>
                                </Col>
                                <Col sm="2" className="align-right"><i className="fa fa-edit blue-color cursor-pointer" onClick={(e)=>selectRetail(entry)}/></Col>
                              </Row>
                              <div className="my-divider" style={{height:'15px',marginTop:'0px'}}>&nbsp;</div>
                              {
                                entry.contacts.map(
                                  (contact, index2)=>{
                                    return <div style={{fontSize:'11px'}} key={index2}>
                                      <div style={{marginBottom:'5px'}}>
                                        <b>{contact.label}</b><br/>
                                        {contact.name}<br/>
                                        {contact.email}&nbsp;&nbsp;
                                        {contact.phone}
                                      </div>
                                    </div>
                                  }
                                )
                              }
                            </div>
                            <br/>
                          </div>
                        </Col>
                      }
                    )
                  }
                </Row>
              </CardBody>
            </Card>
          </TabPane>
          <TabPane tabId="2">
            <Card>
              <CardHeader className="header-color">
                Wholesale Client Contact
              </CardHeader>
              <CardBody>
                <Row>
                  <Col sm="4">
                    <MySelect
                      type="select"
                      value={state.wholesaleCloseby}
                      onChange={(v)=>setState({wholesaleCloseby:v})}
                      options={state.wholesaleClientContacts.map((client)=>{
                        return {label:client.company, value:client.associated_client_fk};
                      })}
                    />
                  </Col>
                  <Col sm="3">
                    <MySelect
                      type="select"
                      value={state.wholesaleBranch}
                      onChange={(v)=>setState({wholesaleBranch:v})}
                      options={state.branches.map((branch)=>{
                        return {label:branch.branch_name, value:branch.ID};
                      })}
                    />
                  </Col>
                  <Col sm="3">
                    <Input type="text" placeholder="Name/Email" value={state.wholesaleKeyword} onChange={(e)=>setState({wholesaleKeyword:e.target.value})}/>&nbsp;&nbsp;
                  </Col>
                  <Col sm="2" className="align-right">
                    <Button color="warning" onClick={getWholesaleClientContacts}>Search</Button>
                  </Col>
                </Row>
                <div className="align-right">
                  <NavLink to="#" onClick={toggleCreateNewWholesale}><i className="fa fa-plus green-color cursor-pointer"/> New Client Contact</NavLink>
                </div>
                <Row>
                  {
                    state.wholesale.map(
                      (entry, index)=>{
                        return <Col key={index} sm="3">
                          <div>
                            <div className="my-well padding" style={{height:'340px'}}>
                              <Row>
                                <Col sm="2"><i className="fa fa-times red-color cursor-pointer" onClick={
                                  (e)=>{
                                    confirmation(
                                      ()=>{removeClientContact(entry.ID)},
                                      ()=>{},
                                      'Delete client contact?',
                                      'Are you sure you want to delete this client contact?');
                                  }
                                }/></Col>
                                <Col sm="8" className="align-right" style={{fontSize:'11px'}}><center>
                                  {entry.client_branch_name} - {entry.company}
                                </center></Col>
                                <Col sm="2" className="align-right"><i className="fa fa-edit blue-color cursor-pointer" onClick={(e)=>selectWholesale(entry)}/></Col>
                              </Row>
                              <div className="my-divider" style={{height:'15px',marginTop:'0px'}}>&nbsp;</div>
                              {
                                entry.contacts.map(
                                  (contact, index2)=>{
                                    return <div style={{fontSize:'11px'}} key={index2}>
                                      <div style={{marginBottom:'5px'}}>
                                        <b><i className="fa fa-user"/> {contact.label}</b><br/>
                                        {contact.name}<br/>
                                        {contact.email}&nbsp;&nbsp;
                                        {contact.phone}
                                      </div>
                                    </div>
                                  }
                                )
                              }
                            </div>
                            <br/>
                          </div>
                        </Col>
                      }
                    )
                  }
                </Row>
              </CardBody>
            </Card>
          </TabPane>
        </TabContent>
      </TabPane>
      <TabPane tabId="3">
        <Card>
          <CardHeader className="header-color">
            Order History
          </CardHeader>
          <CardBody style={{height:'650px'}}>
            <div className="ultra-large-scroll-container">
              <InfiniteScroll
                next={loadMore2}
                dataLength={state.historyOrders.length}
                hasMore={state.hasMoreOrders}
                loader={<div key="nill" className="loader"><center>Loading more orders...</center></div>}
                initialLoad = {true}
                className="my-well"
                scrollableTarget="contentContainer"
              >
                {renderHistoryAppraisals()}
              </InfiniteScroll>
            </div>
          </CardBody>
        </Card>
      </TabPane>
      <TabPane tabId="4">
        <Card>
          <CardHeader className="header-color">
            Files
          </CardHeader>
          <CardBody style={{height:'650px'}}>
            <ClientFile clientFk={state.id} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          </CardBody>
        </Card>
      </TabPane>
    </TabContent>


    <br/>
  </div>;
}

export default Client;
