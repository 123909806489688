//Author June Leow
//Date Oct 20th, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, callBackGenerator, generateSID,formatDateTime, showMessage, formatNumber} from '../../util/util';
import {Alert, Button, Input, Card, CardHeader, CardBody,Nav, NavLink as NavLinkL, NavItem, Row, Col, Table, TabContent, TabPane} from 'reactstrap';
import SpreadWatchQuestionnaire from '../billing/spread-watch-questionnaire';
import DatePicker from 'react-datepicker';
import {NavLink} from 'react-router-dom';
import moment from 'moment';
import MySelect from '../util/my-select';
import React, {useReducer, useEffect, useRef} from 'react';
//initialize the state
const initialState = {
  feeEscalationTab:'1',
  feeEscalations:[],
  feeCategories:[],
  feeEscalationComment:'',
  feeEscalationDoNotModifyBilling:false,
  feeEscalationAmount:'',
  feeEscalationPopUp:false,
  feeEscalationDisabled:false,
  submitDisabled:false,
  templateName:'',

  selectedLenderCCProfile:'',
  selectedLenderPaymentMethod:'',
  defaultLenderFeeCoveredBy:'Broker',
  paymentMethods:[],
  ccProfiles:[],
  expectedDate:'',

  extraRecipients:'',
  extraOpsRecipients:[],
  entities:[],
  targetRecipients:[],
  commentTemplates:[],
  showSpreadWatchQuestionnaire:false,

  settledInvoices:[],
  unsettleInvoices:[],
  settledBills:[],
  unsettleBills:[],
};

//reducer function that perform state update
const reducer = getReducer();


const FeeIncrease  = (props)=>{
  const controller = new AbortController();
  const newsid = useRef(generateSID());
  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    initialize();
    getPaymentMethods();
    getAppraisalFeeCategory();
    getFeeEscalations(props.appraisalFk);
    getCommentTemplates(props.appraisalFk);
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    let targetLabel = '';
    for(let i=0;i<state.entities.length;i++){
      if(state.entities[i].ID===props.appraisalFk){
        targetLabel = state.entities[i].entity_label;
        break;
      }
    }
    let apiIntegrationID = -1;
    let extraID = [];
    for(let i=0;i<state.entities.length;i++){
      if(state.entities[i].entity_label==='API Integration'){
        apiIntegrationID = state.entities[i].ID;
      }

      if(state.entities[i].entity_label==='AE' || state.entities[i].entity_label==='CSR'){
        if(state.targetRecipients.filter(e => e.ID === state.entities[i].ID).length <= 0){
          extraID.push(state.entities[i].ID);
        }
      }
    }
    
    if(targetLabel==='Loan Officer'||targetLabel==='Loan Processor'){
      if(apiIntegrationID!==-1){
        addNewCommentRecipients(apiIntegrationID, extraID);
      }
      if(state.extraRecipientsID.length > 0){
        addMultipleRecipients(state.extraRecipientsID);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state.targetRecipients]);

  useEffect(()=>{
    if(state.feeEscalationDisabled)
     feeEscalation(props.appraisalFk);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state.feeEscalationDisabled]);

  //non API call but simpyl manage state
  const initialize = ()=>{
    let entities = [];
    for(let i=0;i<props.appraisal.entities.length;i++){
      let entity = props.appraisal.entities[i];
      entities.push(entity);
    }

    setState({entities:entities});
  }

  const feeEscalationTabToggle = (tab)=>{
    setState({feeEscalationTab:tab});
  }

  const updateFee = (category, total)=>{
    let feeCategories = state.feeCategories.slice();

    for(let i=0;i<feeCategories.length;i++){
      if(feeCategories[i].category===category){
        feeCategories[i].total = total;
        break;
      }
    }
    setState({feeCategories:feeCategories});
  }

  useEffect(()=>{
    checkSpreadWatch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state.settledBills, state.unsettleBills, state.settledInvoices, state.unsettleInvoices]);

  const addAllToTargetRecipients = ()=>{
    let allRecipients = state.entities.slice();
    let tmp = [];
    for(let i=0;i<allRecipients.length;i++){
      if(allRecipients[i].entity_email==='' && allRecipients[i].entity_label!=='API Integration'){
        showMessage('error','Contact '+allRecipients[i].entity_name+' does not has email, notification wont be able to reach.');
      }
      else{
        tmp.push(allRecipients[i]);
      }
    }
    setState({targetRecipients:tmp});
  }

  const addNewCommentRecipientsBorrower = ()=>{
    setState({extraRecipients:props.appraisal.borrower_email});
  }

  const removeFromTargetRecipients = (ID)=>{
    for(let i =0;i<state.targetRecipients.length;i++){
      if(state.targetRecipients[i].ID===ID){
        let newTargetRecipients = state.targetRecipients.slice();
        newTargetRecipients.splice(i,1);
        setState({targetRecipients:newTargetRecipients});
      }
    }
  }

  const addNewCommentRecipients = (ID, extraRecipientsID)=>{
    setState({ID:ID, extraRecipientsID:extraRecipientsID});
    if(ID==='All')
      addAllToTargetRecipients();
    else{
      for(let i =0;i<state.targetRecipients.length;i++){
        if(state.targetRecipients[i].ID===ID)
          return;
      }
      let existingNewTargetRecipients = state.targetRecipients.slice();

      let targetEntity = null;
      for(let i=0;i<state.entities.length;i++){
        if(state.entities[i].ID===ID){
          targetEntity = state.entities[i];
          break;
        }
      }
    
      if(targetEntity){
        //check if entity email is empty
        if(targetEntity.entity_email==='' && targetEntity.entity_label!=='API Integration'){
          showMessage('error','Contact '+targetEntity.entity_name+' does not has email, notification wont be able to reach.');
        }
        else{
          existingNewTargetRecipients.push(targetEntity);
          setState({targetRecipients:existingNewTargetRecipients});
        }
      }
    }
  }

  const addMultipleRecipients = (extraID)=>{
    let existingNewTargetRecipients = state.targetRecipients.slice();

    for(let i=0;i<extraID.length;i++){
      for(let j=0;j<state.entities.length;j++){
        if(state.entities[j].ID===extraID[i]){
          existingNewTargetRecipients.push(state.entities[j]);
        }
      }
    }
    setState({targetRecipients:existingNewTargetRecipients});
  }

  const handleChange = (selectedOption) => {
    console.log(selectedOption)
    setState({ extraOpsRecipients:selectedOption });
  }

  const commentTemplatesOnChange = (id, tmp)=>{
    let template;

    for(let i=0;i<state.commentTemplates.length;i++){
      if(state.commentTemplates[i].ID===id){
        template = state.commentTemplates[i];
        break;
      }
    }
    if(template){
      setState({feeEscalationComment:template.template, templateName:id});
    }
  }

  const feeEscalationButtonHandler = ()=>{
    if(state.feeEscalationDisabled)
      return;

    setState({feeEscalationDisabled:true});
  }

  const checkSpreadWatch = ()=>{
    let totalPayable = 0;
    let totalReceivable = 0;
    let settledInvoices = state.settledInvoices.slice();
    let unsettleInvoices = state.unsettleInvoices.slice();
    let unsettleBills = state.unsettleBills.slice();
    let settledBills = state.settledBills.slice();

    let complex = false;
    for(let i=0;i<settledInvoices.length;i++){
      if(settledInvoices[i].status!=='Cancelled')
        for(let j=0;j<settledInvoices[i].fees.length;j++){
          if(settledInvoices[i].fees[j].category==='Complex'){
            complex = true;
            break;
          }
        }
      if(settledInvoices[i].status==='Loss')
        totalReceivable = totalReceivable+0;
      else if(settledInvoices[i].status!=='Overage'&&settledInvoices[i].status!=='Cancelled'&&settledInvoices[i].status!=='Voided'&&settledInvoices[i].status!=='Charge Back'&&settledInvoices[i].status!=='Refunded'&&settledInvoices[i].status!=='Partially Received'&&settledInvoices[i].status!=='Reconcile'&&settledInvoices[i].status!=='Balance Due')
        totalReceivable = totalReceivable + parseInt(settledInvoices[i].total, 10);
      else if(settledInvoices[i].status==='Refunded')
        totalReceivable = totalReceivable - parseInt(settledInvoices[i].total, 10);
    }

    for(let i=0;i<unsettleInvoices.length;i++){
      if(unsettleInvoices[i].status!=='Cancelled')
        for(let j=0;j<unsettleInvoices[i].fees.length;j++){
          if(unsettleInvoices[i].fees[j].category==='Complex'){
            complex = true;
            break;
          }
        }

      if(unsettleInvoices[i].status==='Loss')
        totalReceivable = totalReceivable+0;
      else if(unsettleInvoices[i].status!=='Overage'&&unsettleInvoices[i].status!=='Cancelled'&&unsettleInvoices[i].status!=='Voided'&&unsettleInvoices[i].status!=='Charge Back'&&unsettleInvoices[i].status!=='Refunded'&&unsettleInvoices[i].status!=='Partially Received'&&unsettleInvoices[i].status!=='Reconcile'&&unsettleInvoices[i].status!=='Balance Due')
        totalReceivable = totalReceivable + parseInt(unsettleInvoices[i].total, 10);
      else if(unsettleInvoices[i].status==='Refunded')
        totalReceivable = totalReceivable - parseInt(unsettleInvoices[i].total, 10);
    }

    for(let i=0;i<unsettleBills.length;i++){
      if(unsettleBills[i].status!=='Cancelled')
        totalPayable= totalPayable+ parseInt(unsettleBills[i].total,10);
    }

    for(let i=0;i<settledBills.length;i++){
      if(settledBills[i].status!=='Cancelled')
        totalPayable= totalPayable+ parseInt(settledBills[i].total,10);
    }

    let spreadLimit = complex?200:150;
    if(totalReceivable-totalPayable>spreadLimit)
      setState({showSpreadWatchQuestionnaire:true});
  }

  //API call
  const getPaymentMethods = ()=>{
    let callBack = apiCallBack([{state:'paymentMethods',key:'data'}]);
    httpGet('billing/paymentType/get', '', 'Oops, something went wrong and could not load payment methods. Please try again later.', callBack);
  }

  const getAppraisalFeeCategory = ()=>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        setState({feeCategories:response.data.data});

        getAppraisalBilling(props.appraisalFk, response.data.data);
      }
    };
    return httpGet('billing/feeCategory/get', '', 'Oops, something went wrong and could not load appraisal fee category. Please try again later.', callBack);
  }

  //get all the comment templates
  const getCommentTemplates = (ID)=>{
    let callBack = apiCallBack([{state:'commentTemplates',key:'data'}]);
    httpGet('template/get/'+ID, '', 'Oops, something went wrong and could not load your comment template.', callBack);
  }

  const getFeeEscalations = (ID)=>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        setState({feeEscalations:response.data.data});
        if(response.data.data.length>0){
          setState({feeEscalationTab:'2'});
        }
      }
    };

    httpGet('billing/fee/escalation/get/'+ID, '' , 'Oops, something went wrong and could not load fee increase of this order. Please try again later.', callBack);
  }

  const getAdmin = (keyword) => {
    if(keyword&&keyword!==''){
      let SID = generateSID();
      newsid.current = SID;

      keyword = encodeURIComponent(keyword.replace(/\//g, '%ForwardSlash'));

      let callBack = (response)=>{
        if(SID===newsid.current){
          let code = response.data?response.data.code:undefined;

          if(code!=='00'){
            return [{}];
          }
          else{
            let options = [];

            for(let i=0;i<response.data.data.length;i++){
              let tmp = {};
              tmp.label = response.data.data[i].email;
              tmp.value = response.data.data[i].email;

              options.push(tmp);
            }

            return options;
          }
        }
      };
      callBack = callBack.bind(this);

      let promise = httpGet('admin/search/'+keyword, '', 'Oops, something went wrong and could not search for admin. Please try again later.', callBack);

      return promise;
    }
  }

  const feeEscalationAcceptance = (ID, status)=>{
    let parameters = [
      {
        field:'ID',
        value:ID
      },
      {
        field:'status',
        value:status
      },
      {
        field:'userName',
        value:props.userName
      }
    ];

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;

      if(code==='00'){
        //clear the text area
        let feeEscalations = state.feeEscalations.slice();
        for(let i=0;i<feeEscalations.length;i++){
          if(feeEscalations[i].ID===ID){
            feeEscalations[i].status = status==='accept'?'Approved':'Rejected';
            break;
          }
        }

        let overallStatus = Object.assign({},props.overallStatus);
        overallStatus.fee_increase_count-=1;
        props.updateOverallStatus(overallStatus);

        setState({feeEscalations:feeEscalations});
      }
    }

    httpPost('billing/fee/escalation/acceptance',parameters, 'Fee increase status updated successfully.', 'Oops, something went wrong and could not update the fee increase status. Please try again later.', callBack);
  }

  const feeEscalation = ()=>{
    let targetRecipients =[];

    for(let i=0;i<state.targetRecipients.length;i++)
      targetRecipients.push(state.targetRecipients[i].ID);

    let extraRecipients = state.extraRecipients.split(',');

    for(let i=0;i<state.extraOpsRecipients.length;i++){
      extraRecipients.push(state.extraOpsRecipients[i].value);
    }

    extraRecipients = extraRecipients.join(', ');

    let parameters = [
      {
        field:'appraisalFk',
        value:props.appraisalFk
      },
      {
        field:'fees',
        value:state.feeCategories
      },
      {
        field:'paidBy',
        value:state.defaultLenderFeeCoveredBy
      },
      {
        field:'paymentMethod',
        value:state.selectedLenderPaymentMethod
      },
      {
        field:'comment',
        value:state.feeEscalationComment
      },
      {
        field:'expectedDate',
        value:state.expectedDate
      },
      {
        field:'to_send_entities',
        value:targetRecipients
      },
      {
        field:'extra_recipients',
        value:extraRecipients
      }
    ];

    if(state.selectedLenderPaymentMethod==='Authorize.net'||state.selectedLenderPaymentMethod==='Mercury CC')
      parameters.push({field:'profileFk',value:state.selectedLenderCCProfile});
    
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      setState({feeEscalationDisabled:false});

      if(code==='00'){
        //clear the text area
        let feeEscalations = state.feeEscalations.slice();
        feeEscalations.push(response.data.data);

        let overallStatus = Object.assign({},props.overallStatus);
        overallStatus.fee_increase_count+=1;
        props.updateOverallStatus(overallStatus);

        setState({feeEscalationPopUp:false, feeEscalations:feeEscalations});

        getAppraisalBilling(props.appraisalFk, state.feeCategories, true);
      }
    }

    let errorCallBack = (error)=>{
      setState({feeEscalationDisabled:false});
    }

    httpPost('billing/fee/escalate', parameters, 'Fee increase submitted successfully.', 'Oops, something went wrong and could not submit the fee increase. Please try again later.', callBack, errorCallBack);
  }

  const getAppraisalBilling = (ID, feeCategory, checkSpread = false)=>{
    let callBack = (response)=>{
      let feeEscalationDoNotModifyBilling = false;
      let feeCategories = feeCategory.slice();
      let invoices = response.data.data.unsettle_invoices.concat(response.data.data.settled_invoices);
      let transactions = response.data.data.transactions;
      let ccProfiles = response.data.data.credit_card_profiles;

      if(transactions.length>0)
        feeEscalationDoNotModifyBilling = true;

      for(let i=0;i<invoices.length;i++){
        if(invoices[i].shadow_invoice!=='0')
          continue;
        if(invoices[i].status==='Pending'||invoices[i].status==='Declined'||invoices[i].status==='Hold'||invoices[i].status==='Partially Received'||invoices[i].status==='Reconcile'||invoices[i].status==='Charge Back'||invoices[i].status==='Charge Back Resolved'||invoices[i].status==='Received'){
          let fees = invoices[i].fees.slice();

          for(let j=0;j<feeCategories.length;j++){
            feeCategories[j].total = 0;
            for(let k=0;k<fees.length;k++){
              if(feeCategories[j].category===fees[k].category){
                feeCategories[j].total += parseFloat(fees[k].amount, 10);
              }
            }
          }
        }
      }

      if(ccProfiles)
        setState({ccProfiles:ccProfiles});

      if(ccProfiles.length>0)
        setState({selectedLenderCCProfile:ccProfiles[ccProfiles.length-1].ID});

      console.log(response.data.data.default_payment_method);
      if(response.data.data.default_payment_method&&response.data.data.default_payment_method!=='')
        setState({selectedLenderPaymentMethod:response.data.data.default_payment_method});

      if(response.data.data.default_covered_by&&response.data.data.default_covered_by!=='')
        setState({defaultLenderFeeCoveredBy:response.data.data.default_covered_by});


      //Spread Watch Questionnaire On Fee Increase
      let unsettle_invoices = response.data.data.unsettle_invoices;
      let settled_invoices = response.data.data.settled_invoices;

      let unsettle_bills = response.data.data.unsettle_bills;
      let settled_bills = response.data.data.settled_bills;

      let spreadSubmitted = response.data.data.spreadSubmitted;


      let total = 0;
      let aTotal = 0;
      for(let i=0;i<settled_invoices.length;i++){
        if(settled_invoices[i].status==='Refunded')
          total -= parseInt(settled_invoices[i].total,10);
        else if(settled_invoices[i].status!=='Cancelled'&&settled_invoices[i].status!=='Loss'&&settled_invoices[i].status!=='Voided'&&settled_invoices[i].shadow_invoice==='0')
          total+= parseInt(settled_invoices[i].total,10);
      }
      for(let i=0;i<unsettle_invoices.length;i++){
        if(unsettle_invoices[i].status==='Refunded')
          total -= parseInt(unsettle_invoices[i].total,10);
        else if(unsettle_invoices[i].status!=='Cancelled'&&unsettle_invoices[i].status!=='Loss'&&unsettle_invoices[i].status!=='Voided'&&unsettle_invoices[i].shadow_invoice==='0')
          total+= parseInt(unsettle_invoices[i].total,10);
      }

      for(let i=0;i<unsettle_bills.length;i++)
        aTotal+= parseInt(unsettle_bills[i].total,10);
      for(let i=0;i<settled_bills.length;i++)
        aTotal+= parseInt(settled_bills[i].total,10);
      setState({totalLenderFee:total});
      setState({appraiserFees:aTotal});

      if(checkSpread&&!spreadSubmitted){
        let state = {};
        
        if(settled_bills)
          state.settledBills = settled_bills;
        if(unsettle_bills)
          state.unsettleBills = unsettle_bills;
        if(settled_invoices)
          state.settledInvoices = settled_invoices;
        if(unsettle_invoices)
          state.unsettleInvoices = unsettle_invoices;
        setState(state);
      }
      else{
        let state = {};

        if(settled_bills)
          state.settledBills = settled_bills;
        if(unsettle_bills)
          state.unsettleBills = unsettle_bills;
        if(settled_invoices)
          state.settledInvoices = settled_invoices;
        if(unsettle_invoices)
          state.unsettleInvoices = unsettle_invoices;
        setState(state);
      }
      setState({feeCategories:feeCategories, feeEscalationDoNotModifyBilling:feeEscalationDoNotModifyBilling});
    };

    httpGet('billing/'+ID, '', 'Oops, something went wrong and could not load billing for this appraisal. Please try again later.', callBack);
  }

  //render
  let entities;
  if(state.entities){
    entities = state.entities.map(
      (entity, index)=>{
        if(entity.entity_label==='Broker'||entity.entity_label==='Broker Company')
          return null;
        return (
          <tr key={index} className="cursor-pointer align-left" onClick={(e)=>addNewCommentRecipients(entity.ID, [])}><td><b>{entity.entity_label}</b></td><td>{entity.entity_name} - {entity.entity_email}</td></tr>
        );
      }
    );
  }

  let targetRecipients;
  if(state.targetRecipients){
    targetRecipients = state.targetRecipients.map(
      (recipient)=>{
        return(
          <div style={{display:'inline-block'}} key={recipient.ID}>
            <div className="entity-container cursor-pointer" onClick={()=>{removeFromTargetRecipients(recipient.ID)}}>
              <i className="fa fa-minus link-color"></i>&nbsp;{recipient.entity_name}
            </div>&nbsp;
          </div>
        );
      }
    );
  }

  let expectedDate;

  if(state.expectedDate!=='')
    expectedDate = moment(state.expectedDate).toDate();

  console.log(expectedDate);

  let totalFeeEscalation = 0;

  for(let i=0;i<state.feeCategories.length;i++){
    if(state.feeCategories[i].total)
      totalFeeEscalation = totalFeeEscalation + parseFloat(state.feeCategories[i].total, 10);
  }

  let feeEscalationMessage;

  if(state.feeEscalationDoNotModifyBilling)
    feeEscalationMessage = <div className="flashit red-color"> ** There are transactions charged, system will not modify billing. Please adjust the billing manually. **</div>;
  else
    feeEscalationMessage = <div className="flashit"> ** Please note that, system will cancelled all pending receivable and create a new receivable based on your fee increase AND send email/message to the client for fee approval. **</div>;

  let spreadWatchQuestionnaire;

  if(state.showSpreadWatchQuestionnaire){
    spreadWatchQuestionnaire = <SpreadWatchQuestionnaire appraisal_fk={props.appraisalFk} callBack={()=>{setState({showSpreadWatchQuestionnaire:false});}} logout={props.logout} showLoading={props.showLoading} hideLoading={props.hideLoading}/>
  }

  let ccProfilesSelected = state.ccProfiles.map(
    (ccProfile,index)=>{
      if(state.selectedLenderPaymentMethod===ccProfile.payment_method)
        return(
          {value:ccProfile.ID, label:ccProfile.billing_f_name+' '+ccProfile.billing_l_name+' - ****'+ccProfile.cc_last_four+' Exp: '+ccProfile.expiration_month+'/'+ccProfile.expiration_year+' - '+formatDateTime(ccProfile.datetime_created)}
        );
      else
        return null;
    }
  );

  ccProfilesSelected = ccProfilesSelected.filter(x=>x);

  let lenderCCProfiles;
  if(state.selectedLenderPaymentMethod==='Authorize.net'||state.selectedLenderPaymentMethod==='Mercury CC'){
    lenderCCProfiles =
    <Col sm="4">
      <div className="display-inline">
        Credit Card Profile&nbsp;
      </div>
      <div style={{width:'300px'}}>
        <MySelect
          type="select"
          value={state.selectedLenderCCProfile}
          onChange={(v)=>setState({selectedLenderCCProfile:v})}
          options={ccProfilesSelected}
        />
      </div>
    </Col>
  }
  return <div>
    {spreadWatchQuestionnaire}
    <Card  style={{borderTop:'0px'}}>
      <CardHeader className="header-color">
        <i className="fa fa-home"></i>&nbsp;{props.appraisal.reference_num} - {props.appraisal.property_street} {props.appraisal.property_city}, {props.appraisal.property_state} {props.appraisal.property_zip} - {props.appraisal.borrower_f_name} {props.appraisal.borrower_l_name}
      </CardHeader>
      <CardBody>
        {
          props.messages.map(
            (message, index)=>{
              if(message.link){
                return(<a key={index} href={'/'+message.link+'/'+message.ID} target="_blank" rel="noreferrer"><Alert key={index} color="warning" className="my-alert">
                    <i className="fa fa-information"></i> {message.msg}
                  </Alert></a>);
              }

              return(<Alert key={index} color="warning" className="my-alert">
                  <i className="fa fa-information"></i> {message.msg}
                </Alert>);
            }
          )
        }
        <Nav tabs>
        <NavItem>
          <NavLinkL className="cursor-pointer" active={state.feeEscalationTab==='1'} onClick={() => { feeEscalationTabToggle('1'); }}to="#">
            New Fee Increase
          </NavLinkL>
        </NavItem>
        <NavItem>
          <NavLinkL className="cursor-pointer" active={state.feeEscalationTab==='2'} onClick={() => { feeEscalationTabToggle('2'); }}to="#">
            Existing Fee Increase <span className="my-badge">{state.feeEscalations.length}</span>
          </NavLinkL>
        </NavItem>
      </Nav>

      <TabContent activeTab={state.feeEscalationTab}>
        <TabPane tabId="1">
          <label>Total Fee To Escalate: <font color="red">${formatNumber(totalFeeEscalation)}</font></label> <br/>
          <b><i>{feeEscalationMessage}</i></b>
          <Card>
            <CardHeader>
              <Row>
                <Col sm="2">
                  <div style={{paddingTop:'5px'}}>
                    Fee Breakdown
                  </div>
                </Col>
                <Col sm="3">
                  <div className="display-inline">
                    Paid By&nbsp;
                  </div>
                  <div style={{maxWidth:'300px'}}>
                    <MySelect
                      type="select"
                      value={state.defaultLenderFeeCoveredBy}
                      onChange={(v)=>setState({defaultLenderFeeCoveredBy:v})}
                      options={[
                        {label:"Broker",value:"Broker"},
                        {label:"Borrower",value:"Borrower"},
                        {label:"Lender",value:"Lender"},
                        {label:"Client",value:"Client"},
                        {label:"Paid Outside Of Closing",value:"Paid Outside Of Closing"},
                        {label:"Paid at Closing",value:"Paid at Closing"}
                      ]}
                    />
                  </div>
                </Col>
                <Col sm="3">
                  <div className="display-inline">
                    Payment Method&nbsp;
                  </div>
                  <div style={{maxWidth:'300px'}}>
                    <MySelect
                      type="select"
                      value={state.selectedLenderPaymentMethod}
                      onChange={(v)=>setState({selectedLenderPaymentMethod:v})}
                      options={state.paymentMethods.map((paymentMethod)=>{
                        return {label:paymentMethod.payment_type, value:paymentMethod.payment_type};
                      })}
                    />
                  </div>
                </Col>
                {lenderCCProfiles}
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
              {
                state.feeCategories.map(
                  (fee, index)=>{
                    return <Col sm="6" key={index}>
                      <Row>
                        <Col sm="4">
                          <label>{fee.category}</label>
                        </Col>
                        <Col sm="8">
                          <Input type="text" value={fee.total} onChange={(e)=>updateFee(fee.category, e.target.value)}/>
                        </Col>
                      </Row>
                    </Col>
                  }
                )
              }
              </Row>
            </CardBody>
          </Card>
          <br/>
          <Row>
            <Col sm="4">
              <label>Expected Date</label>
            </Col>
            <Col sm="8">
              <DatePicker
                className="form-control"
                timeIntervals={15}
                showTimeSelect
                dateFormat="dd MMM, yyyy hh:mm aa"
                selected={expectedDate}
                onChange={
                  (text)=>{
                    if(text){
                      setState({expectedDate:text})
                    }
                  }
                }
              />
            </Col>
          </Row>
          <br/>
          <Row>
            <Col sm="6">
              <div className="align-left">
                <label>Tag Someone</label>
              </div>
              <div className="small-scroll-container-ex-large">
                <Table hover className="table">
                  <tbody>
                    <tr className="cursor-pointer align-left" onClick={(e)=>addNewCommentRecipientsBorrower()}><td><b>Borrower</b></td><td>{props.appraisal.borrower_f_name+' '+props.appraisal.borrower_l_name+' - '+props.appraisal.borrower_email}</td></tr>
                    {entities}
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col sm="6">
              <Row>
                <Col sm="6" className="align-left">
                  <label>Extra recipients</label><br/>
                  <Input type="text" value={state.extraRecipients} onChange={(e)=>setState({extraRecipients:e.target.value})}/>
                </Col>
                <Col sm="6" className="align-left">
                  <label>Tag admin</label>
                  <MySelect
                    isMulti
                    type="async-select"
                    onChange={(v)=>handleChange(v)}
                    loadOptions={getAdmin}
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <div className="small-scroll-container">
            {targetRecipients}
          </div>
          <label>Comment:</label>
          <Input type="textarea" className="form-control comment-textarea" value={state.feeEscalationComment} rows={3} onChange={(e)=>setState({feeEscalationComment:e.target.value})}/>
          <br/>
          <Row>
            <Col sm="6" className="align-left">
              <label>Template:</label>
            </Col>
            <Col sm="6" className="align-right">
              <NavLink to="/setting">Manage my template</NavLink>
            </Col>
          </Row>
          <MySelect
            type="select"
            selectIsClearable={true}
            value={state.templateName}
            onChange={(v)=>{commentTemplatesOnChange(v)}}
            options={state.commentTemplates.map((template)=>{
              return {label:template.name, value:template.ID};
            })}
          />
          <br/>
          <div className="align-right">
            <Button color="warning" disabled={state.feeEscalationDisabled} onClick={()=>{feeEscalationButtonHandler()}}><i className="fa fa-check"></i> Submit Fee Increase</Button>
          </div>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col sm="2">
              <label>Status</label>
            </Col>
            <Col sm="2">
              <label>Date Requested</label>
            </Col>
            <Col sm="2">
              <label>Date Response</label>
            </Col>
            <Col sm="1">
              <label>Fee</label>
            </Col>
            <Col sm="2">
              <label>Author</label>
            </Col>
            <Col sm="3">
              <label>Comment</label>
            </Col>
          </Row>
          {
            state.feeEscalations.map(
              (feeEscalation, index)=>{
                console.log(feeEscalation)
                let action = <div style={{height:'40px'}}>&nbsp;</div>;

                if(feeEscalation.status==='Pending'){
                  action = <div style={{height:'50px'}}>
                    <NavLink to="#" onClick={(e)=>feeEscalationAcceptance(feeEscalation.ID, 'accept')}><i className="fa fa-check green-color"/> Approve</NavLink>&nbsp;&nbsp;<NavLink to="#" onClick={(e)=>feeEscalationAcceptance(feeEscalation.ID, 'reject')}><i className="fa fa-times red-color"/> Reject</NavLink>
                  </div>
                }
                return <Row key={index} style={{borderBottom:'1px solid #d2d2d2', paddingBottom:'10px'}}>
                  <Col sm="2">
                    <b>{feeEscalation.status}</b><br/>
                    {action}
                  </Col>
                  <Col sm="2">
                    {formatDateTime(feeEscalation.datetime_created)}
                  </Col>
                  <Col sm="2">
                    {formatDateTime(feeEscalation.datetime_response)}
                  </Col>
                  <Col sm="1">
                    ${formatNumber(feeEscalation.fee)}
                  </Col>
                  <Col sm="2">
                    {feeEscalation.author}
                  </Col>
                  <Col sm="3">
                    {feeEscalation.comment}
                  </Col>
                </Row>
              }
            )
          }
        </TabPane>
      </TabContent>
      </CardBody>
    </Card>
  </div>;
}


export default FeeIncrease;
