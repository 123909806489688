//Author Sooyoung Kim
//Date Aug 21, 2023
import {Card, CardHeader, CardBody, Col, Row, Input} from 'reactstrap';
import React, {useEffect} from 'react';
import MySelect from '../util/my-select';

const AdditionalStructure  = (props)=>{
  const controller = new AbortController();

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const updateState = (field, value) => {
    let additionalStructures = props.additionalStructures.slice();
    let target = additionalStructures[props.index];

    target[field] = value;

    additionalStructures[props.index] = target;

    props.updateState({additionalStructures:additionalStructures});
  }

  //render
  let disabledStyling = {border:'none', background:'#fff', padding:'0',paddingLeft:'2px', height:'auto',WebkitAppearance:'none'};
  let removeIcon = <i hidden={props.disabled} className="fa fa-times red-color cursor-pointer" onClick={(e)=>props.removeRoom('additionalStructures', props.index)}/>;

  return <div>
    <Card>
      <CardHeader className="header-color">
        {removeIcon} {props.additionalStructures[props.index].structureType}
      </CardHeader>
      <CardBody>
        <Row style={{marginTop:'15px'}}>
          <Col sm="6">
            <label><font color="red">*</font>Structure Type</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={true}
              value={props.additionalStructures[props.index].structureType}
              onChange={(v)=>updateState('structureType',v)}
              options={[
                {label:"PrimaryDwelling",value:"Primary Dwelling"},
                {label:"ADU",value:"ADU"},
                {label:"Detached Garage",value:"Detached Garage"},
                {label:"Workshop",value:"Workshop"},
                {label:"Storage",value:"Storage"},
                {label:"Barn",value:"Barn"},
                {label:"Other",value:"Other"},
              ]}
            />
          </Col>
          <Col sm="6">
            <label><font color="red">*</font>Room/Area Type</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={true}
              value={props.additionalStructures[props.index].roomType}
              onChange={(v)=>updateState('roomType',v)}
              options={[
                {label:"Kitchen",value:"Kitchen"},
                {label:"Bedroom",value:"Bedroom"},
                {label:"Bathroom",value:"Bathroom"},
                {label:"Half Bath",value:"Half Bath"},
                {label:"Garage",value:"Garage"},
                {label:"Recreation",value:"Recreation"},
                {label:"Den",value:"Den"},
                {label:"Dining Room",value:"Dining Room"},
                {label:"Family Room",value:"Family Room"},
                {label:"Foyer",value:"Foyer"},
                {label:"Laundry Room",value:"Laundry Room"},
                {label:"Living Room",value:"Living Room"},
                {label:"Media Room",value:"Media Room"},
                {label:"Mudroom",value:"Mudroom"},
                {label:"Office",value:"Office"},
                {label:"Other",value:"Other"}
              ]}
            />
          </Col>
          <Col sm="12">
            <label>{props.additionalStructures[props.index].structureType==='Other'?<font color="red">*</font>:null}Structure Description</label>
            <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={props.additionalStructures[props.index].structureType==='Other'} type="text" value={props.additionalStructures[props.index].structureDescription} onChange={(e)=>{updateState('structureDescription',e.target.value)}}/>
          </Col>
        </Row>
      </CardBody>
    </Card>
  </div>;
}


export default AdditionalStructure;
