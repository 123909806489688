//Author June Leow
//Date Nov 15th, 2023
import {getReducer, getSetStateFunction, postAPICallGenerator, callBackGenerator} from '../../util/util';
import React, {useReducer, useEffect} from 'react';
import {Button, Input} from 'reactstrap';
import {NavLink, useParams} from 'react-router-dom';
//initialize the state
const initialState = { 
  email: '',
  token: '',
  errorMessage:'',
  password1:'',
  password2:'',
};

//reducer function that perform state update
const reducer = getReducer();


const ResetPassword  = (props)=>{
  const controller = new AbortController();
  let { email, token} = useParams();
  const newInitialState = Object.assign({}, initialState, {email:email, token:token});
  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  //API call
  const onSubmit = (e)=>{
    e.preventDefault();
    if(state.password1!==state.password2){
      setState({errorMessage:'Your password does not match.'});
    }
    else if(state.password1===''){
      setState({errorMessage:'Please specify your new password.'});
    }
    else{
      let url = 'session/resetPassword';
      let callBack = apiCallBack([{state:'stateName',key:'data'}]);
      let parameters = [
        {
          field:'email',
          value:state.email
        },
        {
          field:'token',
          value:state.token
        },
        {
          field:'password',
          value:state.password1
        }
      ];
      httpPost(url, parameters, 'Passord reset successfully.','Oops, something went wrong and could not reset your password. Please try again later.', callBack);
    }
  }

  //render
  return(
    <div className="login-background">
      <form name="loginForm" onSubmit={onSubmit}>
        <div className="login-container well padding">
          <br/>
          <center>
            <img alt="company logo" src="/img/logo_small.png" className="logo-login"/><br/>
            Admin portal - Forgot password
          </center>
          <div className="non-empty-container">
            <p className="no-padding no-margin">{state.errorMessage}</p>
          </div>
          <div className="align-right"> 
            <NavLink to="/">Back to login</NavLink>
          </div>
          <div className="form-group" style={{height:'38px'}}>
            <label className="font-blue">New password:</label>
            <Input type="password" value={state.password1} name="username" autoComplete="on" onChange={e => setState({password1: e.target.value})} className="form-control" id="password1"/>
          </div>
          <br/>
          <label className="font-blue">Confirm password:</label>
          <div className="form-group input-group" style={{height:'38px'}}>
            <Input type="password" value={state.password2} name="username" autoComplete="on" onChange={e => setState({password2: e.target.value})} className="form-control" id="password2"/>
          </div>
          <br/>
          <Button className="form-control btn btn-blue-noshadow btn-block font-white">Reset Password</Button>
          <br/>
        </div>
      </form>
    </div>
  );
}


export default ResetPassword;
