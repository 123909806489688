//Author Sooyoung Kim
//Date July 5, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, putAPICallGenerator, callBackGenerator, formatDateTime, sliceFromArray} from '../../../util/util';
import {Card, CardHeader, CardBody, Table} from 'reactstrap';
import {NavLink} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';

//initialize the state
const initialState = {
  reportDetails:[],
};

//reducer function that perform state update
const reducer = getReducer();


const ClientPublicSignup  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getReportDetails();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //API call
  const markAsVerified = (id)=>{
    let parameters = [
      {
        field:'ID',
        value:id
      },
      {
        field:'field',
        value:'public_sign_up_verification_needed'
      },
      {
        field:'value',
        value:'no'
      }
    ];

    let list = sliceFromArray(state.reportDetails,'ID', id);
    let callBack = apiCallBack([{state:'reportDetails', value:list}]);

    httpPut('user/update',parameters,'Account verified','Oops, something went wrong and could not mark this user account as verified. Please try again later.', callBack);
  }

  const getReportDetails = ()=>{
    let callBack = apiCallBack([{state:'reportDetails', key:'data'}]);
	  httpGet('report/client/public/signup', '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  //render
  return <div>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;New User Account & Client Contact Report
      </CardHeader>
      <CardBody>

        <Table className="table table-striped">
          <thead>
            <tr>
              <th width="3%"></th>
              <th width="15%">Reference #</th>
              <th width="25%">Email</th>
              <th width="20%">Name</th>
              <th width="22%">Company</th>
              <th width="15%">Date Created</th>
            </tr>
          </thead>
          <tbody>
            {
              state.reportDetails.map(
                (row,index)=>{
                  return(
                    <tr key={index}>
                      <td><i className="fa fa-check green-color cursor-pointer" onClick={(e)=>markAsVerified(row.ID)}></i></td>
                      <td><NavLink className="link-color" target="_blank" to={"/appraisal/"+row.appraisal_fk}><b>{row.reference_num}</b></NavLink></td>
                      <td><NavLink target="_blank" to={"/client/"+row.client_fk+"/status=/branch/"+row.branch_fk+"/user/"+row.type_fk}>{row.email}</NavLink></td>
                      <td>{row.first_name+' '+row.last_name}</td>
                      <td>{row.company}</td>
                      <td>
                        {formatDateTime(row.datetime_created)}
                      </td>
                    </tr>
                  );
                }
              )
            }
          </tbody>
        </Table>
      </CardBody>
    </Card>
  </div>;
}


export default ClientPublicSignup;
