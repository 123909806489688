//Author Sooyoung Kim
//Date July 10, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, getFromTo, replaceRegex, formatNumber} from '../../../util/util';
import {Button, Collapse, Card, CardHeader, CardBody, Col, Row, Modal, ModalHeader, ModalBody} from 'reactstrap';
import MyGauge from '../../util/my-gauge';
import moment from 'moment';
import {NavLink} from 'react-router-dom';
import MyReactTable from '../../util/my-react-table';
import ReportFilter from '../report-filter';
import React, {useReducer, useEffect} from 'react';
import '../report.css';

//initialize the state
const initialState = {
  client:'',
  clientBranch:'0',
  clientBranches:[],
  loanPurpose:'',
  loanType:'',
  isRush:'',
  isComplex:'',
  state:'',
  city:'',
  entity:'',
  selectedAppraisalType:'',
  filterAppraisalTypes:[],
  loanTypes:[{key:'Conventional',value:'Conventional'},{key:'High Balanced',value:'High Balanced'},{key:'Jumbo',value:'Jumbo'},{key:'Jumbo ARM',value:'Jumbo ARM'},{key:'Jumbo Fixed',value:'Jumbo Fixed'},{key:'Jumbo <$1m',value:'Jumbo <$1m'},{key:'Jumbo $1m - $2m',value:'Jumbo $1m - $2m'},{key:'Jumbo >$2m',value:'Jumbo >$2m'},{key:'FHA',value:'FHA'},{key:'USDA',value:'USDA'},{key:'Other',value:'Other'}],
  loanPurposes:[{key:'Purchase',value:'Purchase'},{key:'Refinance',value:'Refinance'},{key:'R/T Refinance',value:'R/T Refinance'},{key:'C/O Refinance',value:'C/O Refinance'},{key:'HELOC/2nd Mortgage',value:'HELOC/2nd Mortgage'},{key:'Other',value:'Other'}],
  states:[
    {key:'All',value:'All'},{key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
  appraisalTypes:[],
  from:getFromTo('from'),
  to:getFromTo('to'),

  counties:[],
  statesStats:[],

  reportDetailsToggle:false,
  reportDetails:'',
  reportDetailsOrders:[],
  clients:[],
  openByClient:0,
  turntime:'1',
  selectedStates:['California'],
  showStatesStats:true
};

//reducer function that perform state update
const reducer = getReducer();


const ClientTurntimeReport  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const reportDetailsToggle = () => {
    if(state.reportDetailsDropDown)
      setState({reportDetailsOrders:[]});
    setState({reportDetailsDropDown:!state.reportDetailsDropDown});
  }

  const removeState = (stateName) => {
    let states = state.selectedStates.slice();

    let index = states.indexOf(stateName);

    if(index!==-1)
      states.splice(index, 1);
    setState({selectedStates:states});
  }

  const updateSelectedState = (stateName) => {
    let states = state.selectedStates.slice();

    let index = states.indexOf(stateName);

    if(index===-1)
      states.push(stateName);
    if(stateName==='All')
      states = ['All'];

    setState({selectedStates:states, state:stateName});
  }


  //API call
  const getReport = () => {
    console.log(state);
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);
    let states = replaceRegex(state.selectedStates, ',');

    let url = 'report/turnTime/clientBranch='+state.clientBranch+'&turntime='+state.turntime+'&client='+state.client+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+states+'&city='+state.city+'&appraisalType='+appraisalType;

    let callBack = apiCallBack([{state:'counties', key:'data'}, {state:'statesStats', key:'data2'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  const getReportDetails = (classIndex, stateName, county) => {
    reportDetailsToggle();
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);

    let url = 'report/turnTime/details/order/turntime='+state.turntime+'&client='+state.client+'&loanPurpose='+loanPurpose+'&county='+county+'&class='+classIndex+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+stateName+'&city='+state.city+'&appraisalType='+appraisalType;

    let callBack = apiCallBack([{state:'reportDetailsOrders', key:'data'}]);
	  httpGet(url, '', 'Oops, something went wrong and could not load the report details. Please try again later.', callBack);
  }


  //render
  let config = {
    chart: {
      type: 'gauge',
      plotBackgroundColor: null,
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false,
      height:150
    },

    title: {
      text: null
    },

    pane: {
      startAngle: -150,
      endAngle: 150,
      background: [{
        backgroundColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, '#FFF'],
            [1, '#333']
          ]
        },
        borderWidth: 0,
        outerRadius: '50%'
      }, {
        backgroundColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, '#333'],
            [1, '#FFF']
          ]
        },
        borderWidth: 1,
        outerRadius: '48%'
      }, {
          // default background
      }, {
        backgroundColor: '#DDD',
        borderWidth: 0,
        outerRadius: '105%',
        innerRadius: '103%'
      }]
    },

    plotOptions:{
      gauge:{
        dataLabels:{
          style:{
            fontSize:'20px'
          }
        }
      }
    },
    credits: {
      enabled: false
    },

    // the value axis
    yAxis: {
      min: 0,
      max: 12,

      minorTickInterval: 'auto',
      minorTickWidth: 1,
      minorTickLength: 10,
      minorTickPosition: 'inside',
      minorTickColor: '#666',

      tickPixelInterval: 30,
      tickWidth: 2,
      tickPosition: 'inside',
      tickLength: 10,
      tickColor: '#666',
      labels: {
        step: 2,
        rotation: 'auto',
      },
      title: {
        text: 'day',
        y:30
      },
      plotBands: [{
        from: 0,
        to: 4,
        color: '#55BF3B' // green
      }, {
        from: 4,
        to: 8,
        color: '#DDDF0D' // yellow
      }, {
        from: 8,
        to: 12,
        color: '#DF5353' // red
      }]
    },
    series: [{
      name: 'Turnaround',
      data: [1],
      tooltip: {
        valueSuffix: ' days'
      }
    }]
  };

  const columns = [
    {
      id: 'state',
      Header: 'State',
      accessor: d => d.state,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'county',
      Header: 'County',
      accessor: d => d.county,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'overallTotal',
      Header: 'Total Volume',
      accessor: d => d.overall_total,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'appraiserTotal',
      Header: 'Available Appraiser',
      accessor: d => d.appraiser_total,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'overallAvgTurntime',
      Header: 'Overall avg TAT',
      accessor: d => d.overall_avg_turntime,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'unclassified',
      Header: 'Unclassified',
      accessor: d => d.unknown_avg_turntime,
      Cell: props => <NavLink to="#" onClick={()=>getReportDetails(0,props.row.original.state, props.row.original.county)}>{props.row.original.unknown_avg_turntime} bd</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'urban',
      Header: 'Urban',
      accessor: d => d.urban_avg_turntime,
      Cell: props => <NavLink to="#" onClick={()=>getReportDetails(1,props.row.original.state, props.row.original.county)}>{props.row.original.urban_avg_turntime} bd</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'suburban',
      Header: 'Suburban',
      accessor: d => d.suburban_avg_turntime,
      Cell: props => <NavLink to="#" onClick={()=>getReportDetails(2,props.row.original.state, props.row.original.county)}>{props.row.original.suburban_avg_turntime} bd</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'rural',
      Header: 'Rural',
      accessor: d => d.rural_avg_turntime,
      Cell: props => <NavLink to="#" onClick={()=>getReportDetails(3,props.row.original.state, props.row.original.county)}>{props.row.original.rural_avg_turntime} bd</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
  ];

  let from, to;
  if(state.from!=='')
    from = moment(state.from);
  if(state.to!=='')
    to = moment(state.to);

  let content;

  if(state.reportDetailsOrders.length>0){
    let rows = state.reportDetailsOrders.map(
      (order, index)=>{
        return(
          <tr key={index}>
            <td><NavLink target="_blank" to={"/appraisal/"+order.ID}>{order.reference_num}</NavLink></td>
            <td>{order.property_street+' '+order.property_city+', '+order.property_state+' '+order.property_zip}</td>
            <td>{order.appraisal_type}</td>
            <td>{order.loan_type}</td>
            <td>{formatNumber(order.turntime)} bd</td>
          </tr>
        )
      }
    );

    content =
    <table className="table table-striped">
      <thead>
        <tr>
          <th>Reference #</th>
          <th>Address</th>
          <th>Report Type</th>
          <th>Loan type</th>
          <th>Turntime</th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </table>
  }

  let table = <MyReactTable columns={columns} data={state.counties} className="table table-striped"/>;
  
  let reportFiltersConfig = [
    {id:'from',value:from, updateFunc:(v)=>setState({from:v}), width:'2'},
    {id:'to',value:to, updateFunc:(v)=>setState({to:v}), width:'2'},
    {id:'rush',value:state.isRush, updateFunc:(v)=>setState({isRush:v}), width:'2'},
    {id:'complex',value:state.isComplex, updateFunc:(v)=>setState({isComplex:v}), width:'2'},
    {id:'customSelect',value:state.turntime, updateFunc:(v)=>setState({turntime:v}), width:'2', label:'Turntime', clearable:false, className:"", options:[
      {label:"Assignment Received - Completed",value:"1"},{label:"Assignment Received - Appraiser Assigned",value:"2"},{label:"Inspection Completed - Completed",value:"3"}
    ]},
    {id:'state',value:state.state, updateFunc:(v)=>updateSelectedState(v), width:'2', group:true},
    {id:'city',value:state.city, updateFunc:(v)=>setState({city:v}), width:'2'},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'3'},
    {id:'clientBranch',value:state.clientBranch, updateFunc:(v)=>setState({clientBranch:v}), width:'3'},
    {id:'loanPurpose',value:state.loanPurpose, updateFunc:(v)=>setState({loanPurpose:v}), width:'2'},
    {id:'loanType',value:state.loanType, updateFunc:(v)=>setState({loanType:v}), width:'2'},
    {id:'custom',value:state.entity, updateFunc:(v)=>setState({entity:v}), width:'3', label:'Loan Officer name'},
    {id:'appraisalType',value:state.filterAppraisalTypes, updateFunc:(v)=>setState({filterAppraisalTypes:v}), width:'4'},
    {id:'button',value:'Submit', updateFunc:getReport, width:'5', className:"align-right", color:"warning"},
  ];

  let selectedStates;

  if(state.selectedStates.length>0){
    selectedStates = state.selectedStates.map(
      (stateName, index)=>{

        return <div key={index} className="display-inline margin-top">
          <div className="filter-container" onClick={()=>removeState(stateName)}>
            <i className="fa fa-minus red-color"></i> {stateName}
          </div>
          &nbsp;
        </div>;
      }
    )
  }

  return <div>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Turntime Report
      </CardHeader>
      <CardBody>
        <ReportFilter {...props} configs={reportFiltersConfig}/>
        {selectedStates}
      </CardBody>
    </Card>
    <br/>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Turntime Report
      </CardHeader>
      <CardBody>
        <Card>
          <CardHeader className="header-color cursor-pointer" onClick={()=>setState({showStatesStats:!state.showStatesStats})}>
            <Row>
              <Col sm="8">
                <i className="fa fa-edit"></i>&nbsp;States Stats
              </Col>
              <Col sm="4" className="align-right">
                <i className={(state.showStatesStats===false)?"fa fa-chevron-up":"fa fa-chevron-down"}></i>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Collapse isOpen={state.showStatesStats}>
              <Row>
              {
                state.statesStats.map(
                  (stat, index)=>{
                    let newConfig = Object.assign({}, config, {series:[{
                      name: 'Turnaround',
                      data: [stat.overall_avg_turntime],
                      tooltip: {
                        valueSuffix: ' days'
                      }
                    }]});

                    return <Col sm="2">
                      <center><label>{stat.state}</label></center>
                      <MyGauge options = {newConfig} ></MyGauge>
                      <center className="small-font">
                        Urban TAT: {stat.urban_avg_turntime}<br/>
                        Suburban TAT: {stat.suburban_avg_turntime}<br/>
                        Rural TAT: {stat.rural_avg_turntime}<br/>
                        Unknown TAT: {stat.unknown_avg_turntime}<br/>
                      </center>
                      <div className="my-divider">&nbsp;</div>
                    </Col>
                  }
                )
              }
              </Row>
            </Collapse>
          </CardBody>
        </Card>

        <br/><div className="my-divider">&nbsp;</div>

        {table}
      </CardBody>
    </Card>

    <Modal className="my-modal-wide" isOpen={state.reportDetailsDropDown} toggle={reportDetailsToggle} >
      <ModalHeader hidden={true} toggle={reportDetailsToggle}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-user"></i> Report Details</h5>
        </center>
        <br/>
        <div className="large-scroll-container">
          {content}
        </div>
        <br/>
        <center>
          <Button color="info" onClick={reportDetailsToggle}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
  </div>;
}


export default ClientTurntimeReport;
