//Author Sooyoung Kim
//Date June 30, 2022
import {formatDate, formatNumber, formatDateTime, confirmation, getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, deleteAPICallGenerator, callBackGenerator, sliceFromArray} from '../../util/util';
import {Input, Button, Row, Col, Table, Modal, ModalHeader, ModalBody, Nav, NavItem, TabPane, TabContent, NavLink as NavLinkL} from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import {NavLink} from 'react-router-dom';
import AppraiserSearch from './appraiser-search';
import React, {useReducer, useEffect} from 'react';
import ReportFilter from './../report/report-filter';


//initialize the state
const initialState = {
  appraisers:[],
  limit: 25,
  offset: 0,
  hasMoreAppraisers: true,
  loading: false,
  sort: 'first_name',
  order: 'ASC',
  status: '',
  totalCount:0,

  pendingRecommendations:[],

  //for search
  name:'',
  company_name:'',
  email:'',
  state:'',
  county:'',
  city:'',

  modal:false,
  modal2:false,
  selectedRecommendation:{},
  activeTab:'1'
};

//reducer function that perform state update
const reducer = getReducer();


const AppraiserExpeditePayment  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getPendingRecommendations();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    console.log(state);
    if(state.appraisers.length<=0 && state.hasMoreAppraisers){
        loadMore();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state]);

  //non API call but simpyl manage state
  const selectedRecommendation = (recommendation)=>{
    setState({selectedRecommendation:recommendation});
    toggleModal2();
  }

  const toggleModal = ()=>{
    setState({modal:!state.modal});
  }

  const toggleModal2 = ()=>{
    setState({modal2:!state.modal2});
  }

  const activeTabToggle = (tab)=>{
    setState({activeTab:tab});
  }

  //click handler that switch the state of sorting order then call the function to refresh the list
  //to achieve the "sorting"
  const columnClickHandler = (col)=>{
    if(state.sort===col){
      if(state.order==='ASC')
        setState({order:'DESC'});
      else
        setState({order:'ASC'});
    }
    else
      setState({sort:col,order:'ASC'});
    refreshList();
  }

  //this function refresh the list of orders pipeline
  const refreshList = ()=>{
    setState({
      appraisers:[],
      totalCount:0,
      hasMoreAppraisers:true,
      offset:0
    });
  }

  //this function render the sort icon at each column of the table
  const renderSortIcon = (col)=>{
    if(state.sort===col){
      if(state.order==='ASC')
        return <i className="red-color fa fa-arrow-down"></i>
      else
        return <i className="red-color fa fa-arrow-up"></i>
    }
  }


  //render function for infinite scroller
  const renderAppraisers = ()=>{
    return (
      <div>
        <table className="primary-table">
          <thead>
            <tr>
              <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('first_name')}><i className="fa fa-user"></i>&nbsp;First Name {renderSortIcon('first_name')}</th>
              <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('last_name')}><i className="fa fa-user"></i>&nbsp;Last Name {renderSortIcon('last_name')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('status')}><i className="fa fa-info"></i>&nbsp;Status {renderSortIcon('status')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('state')}><i className="fa fa-map-marker"></i>&nbsp;State {renderSortIcon('state')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('county')}><i className="fa fa-map-marker"></i>&nbsp;County {renderSortIcon('county')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('city')}><i className="fa fa-map-marker"></i>&nbsp;City {renderSortIcon('city')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('email')}><i className="fa fa-envelope-open"></i>&nbsp;Email {renderSortIcon('email')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('phone')}><i className="fa fa-phone"></i>&nbsp;Phone {renderSortIcon('phone')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('datetime_created')}><i className="fa fa-calendar-o"></i>&nbsp;Date Created {renderSortIcon('datetime_created')}</th>
            </tr>
          </thead>
          <tbody>
          {
            state.appraisers.map(
              (appraiser)=>{
                return (
                  <tr key={appraiser.ID}>
                    <td><i className="fa fa-times red-color cursor-pointer" onClick={
                      ()=>{
                        confirmation(
                          ()=>{deleteExpeditePayment(appraiser.clubID)},
                          ()=>{},
                          'Delete appraiser from Expedite Payment?',
                          'Are you sure you want to delete this appraiser profile from the Expedite Payment?');
                      }
                    }></i> {appraiser.first_name}</td>
                    <td>{appraiser.last_name}</td>
                    <td>{appraiser.status}</td>
                    <td>{appraiser.state}</td>
                    <td>{appraiser.county}</td>
                    <td>{appraiser.city}</td>
                    <td>{appraiser.email}</td>
                    <td>{appraiser.phone}</td>
                    <td>{formatDate(appraiser.datetime_created)}</td>
                  </tr>
                );
              }
            )
          }
          </tbody>
        </table>
      </div>
    )
  }

  //API call
  const getPendingRecommendations = ()=>{
    let callBack = apiCallBack([{state:'pendingRecommendations', key:'data'}]);
    httpGet('appraiser/expeditePayment/pending/get', '','Oops, something went wrong and could not load pending recommendations. Please try again later.', callBack);
  }

  //function that fire when the infinite scroll reach bottom
  const loadMore = ()=>{
    //do not load if there is no more conditions or it's loading data
    if(state.hasMoreAppraisers&&!state.loading){
      //set loading equals to true so it won't fire off before we are done
      setState({loading:true});

      let company_name = encodeURIComponent(state.company_name.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
      let name = encodeURIComponent(state.name.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
      let email = encodeURIComponent(state.email.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
      let city = encodeURIComponent(state.city.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
      let county = encodeURIComponent(state.county.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));

      let url = 'appraiser/expeditePayment/get/limit='+state.limit+'&offset='+state.offset+'&order='+state.order+'&sort='+state.sort+'&status='+state.status+'&name='+name+'&company_name='+company_name+'&email='+email+'&state='+state.state+'&county='+county+'&city='+city;

      //callback handler that update the state when http request return
      let callBack = (response)=>{
        let code = response.data?response.data.code:undefined;

        if(code!=='00'){
          setState({hasMoreAppraisers:false});
        }
        else{
          let newAppraiser = response.data.data;
          let hasMoreAppraisers = true;
          let newOffset = state.offset;
          let totalCount = response.data.count;

          //if http request return empty then no more results, end of list
          if(newAppraiser.length<=0){
            hasMoreAppraisers = false;
          }
          else{
            //increment the offset
            newOffset = state.offset + 1;
          }

          //concat the current array of announcement
          if(state.appraisers.length>0){
            let temp = [...state.appraisers,...newAppraiser];

            setState({appraisers:temp});
          }
          else
            setState({appraisers:newAppraiser});

          setState({hasMoreAppraisers:hasMoreAppraisers, offset:newOffset, totalCount:totalCount});
        }
      };
      callBack = callBack.bind(this);

      //error handler when the http request return with error
      let errorCallBack = apiCallBack([{state:'hasMoreAppraisers', value:false}]);


      //collect the promise and wait for it to finish performing it's task
      let promises = httpGet(url, '','Oops, something went wrong and could not load appraisers. Please try again later.', callBack, errorCallBack);
      promises
        .then(
          function(result){
            //set loading equals to false so the function could be fire off once again
            setState({loading:false});
          }
        );
    }
  }

  const approval = (approval)=>{
    let callBack = (response)=>{console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let selectedRecommendation = Object.assign({},state.selectedRecommendation);
        selectedRecommendation.recommendation_status = approval;

        if(approval==='approved'){
          let pendingRecommendations = state.pendingRecommendations.slice();
          let appraisers = state.appraisers.slice();
          for(let i=0;i<pendingRecommendations.length;i++){
            if(pendingRecommendations[i].clubID===state.selectedRecommendation.clubID){
              pendingRecommendations.splice(i,1);
              appraisers.push(state.selectedRecommendation);
              break;
            }
          }
          setState({pendingRecommendations:pendingRecommendations, appraisers:appraisers, totalCount: parseInt(state.totalCount)+1});
        }
        else{
          let pendingRecommendations = state.pendingRecommendations.slice();
          for(let i=0;i<pendingRecommendations.length;i++){
            if(pendingRecommendations[i].ID===state.selectedRecommendation.ID){
              pendingRecommendations[i].recommendation_status = approval;
              break;
            }
          }
          setState({pendingRecommendations:pendingRecommendations});
        }

        setState({selectedRecommendation:selectedRecommendation});
      }
    };
    callBack = callBack.bind(this);

    let parameters =[
      {
        field:'ID',
        value:state.selectedRecommendation.clubID
      },
      {
        field:'approval',
        value:approval
      }
    ];

    httpPost('appraiser/expeditePayment/approval', parameters, 'Appraiser recommendation status updated.','Oops, something went wrong and could not update the recommendation status. Please try again later.', callBack);
  }

  const recommendAppraiser = (ID, comment)=>{
    let callBack = (response)=>{console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let pendingRecommendations = state.pendingRecommendations.slice();
        pendingRecommendations.push(response.data.data);
        setState({pendingRecommendations:pendingRecommendations});
      }
    };
    callBack = callBack.bind(this);

    let parameters =[
      {
        field:'appraiserFk',
        value:ID
      },
      {
        field:'comment',
        value:comment
      }
    ];

    httpPost('appraiser/expeditePayment/recommend', parameters, 'Appraiser recommended for Expedite Payment.','Oops, something went wrong and could not recommend the appraiser. Please try again later.', callBack);
  }

  const deleteExpeditePayment = (id)=>{
    let list = sliceFromArray(state.appraisers,'clubID', id);
    let callBack = apiCallBack([{state:'appraisers', value:list}, {state:'totalCount', value:state.totalCount-1}]);
    httpDelete('appraiser/expeditePayment/'+id,'Removed from Expedite Payment.','Oops, something went wrong and could not delete this appraiser profile from Expedite Payment. Please try again later.', callBack);
  }

  //render
  let reportFiltersConfig = [
    {id:'appraiserStatus',value:state.status, updateFunc:(v)=>setState({status:v}), width:'3'},
    {id:'custom',value:state.name, updateFunc:(v)=>setState({name:v}), width:'3', label:'Name'},
    {id:'custom',value:state.company_name, updateFunc:(v)=>setState({company_name:v}), width:'3', label:'Company Name'},
    {id:'custom',value:state.email, updateFunc:(v)=>setState({email:v}), width:'3', label:'Email'},
    {id:'state',value:state.state, updateFunc:(v)=>setState({state:v}), width:'3'},
    {id:'county',value:state.county, updateFunc:(v)=>setState({county:v}), width:'3'},
    {id:'city',value:state.city, updateFunc:(v)=>setState({city:v}), width:'3'},
    {id:'button',value:'Submit', updateFunc:()=>refreshList(), width:'3', className:"align-right", color:"warning"},
  ];

  return <div>
  <InfiniteScroll
    className="my-well"
    dataLength={state.appraisers.length} //This is important field to render the next data
    next={loadMore}
    hasMore={state.hasMoreAppraisers}
    loader={<center>Loading more appraisers...</center>}
    scrollableTarget="contentContainer"
  >
    <AppraiserSearch recommend={recommendAppraiser} modalState={state.modal} toggleModalCallBack={toggleModal} showLoading={props.showLoading} logout={props.logout} hideLoading={props.hideLoading}/>

    <Modal className="my-modal-wide" isOpen={state.modal2} toggle={toggleModal2} >
      <ModalHeader hidden={true} toggle={toggleModal2}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-cogs"></i> Recommendation approval</h5>
        </center>
        <br/>
        <Row>
          <Col sm="4">
            <label>Name</label>
          </Col>
          <Col sm="8">
            {state.selectedRecommendation.first_name+' '+state.selectedRecommendation.last_name}
          </Col>
        </Row>
        <Row>
          <Col sm="4">
            <label>Email</label>
          </Col>
          <Col sm="8">
            {state.selectedRecommendation.email}
          </Col>
        </Row>
        <Row>
          <Col sm="4">
            <label>Address</label>
          </Col>
          <Col sm="8">
            {state.selectedRecommendation.street+' '+state.selectedRecommendation.city+', '+state.selectedRecommendation.state+' '+state.selectedRecommendation.zip}
          </Col>
        </Row>
        <br/>
        <Row>
          <Col sm="4">
            <label>Status</label>
          </Col>
          <Col sm="8">
            <Input type="select" value={state.selectedRecommendation.recommendation_status} onChange={(e)=>approval(e.target.value)}>
              <option value="pending">Pending</option>
              <option value="declined">Declined</option>
              <option value="approved">Approved</option>
            </Input>
          </Col>
        </Row>
        <br/>
        <center>
          <Button color="info" onClick={toggleModal2}>Close</Button>
        </center>
      </ModalBody>
    </Modal>

    <div className="page-title">
      <i className="fa fa-reorder"></i>&nbsp;Appraiser - Expedite Payment
    </div>
    <ReportFilter {...props} configs={reportFiltersConfig}/>

    <div className="my-divider"></div>
    <Row>
      <Col sm="6">
          <div>{formatNumber(state.totalCount)} appraisers in queue<br/><br/></div>
      </Col>
      <Col sm="6" className="align-right">
        <NavLink to="#" onClick={toggleModal}><i className="fa fa-plus green-color"></i> Recommend new appraiser</NavLink>
      </Col>
    </Row>
    <Nav tabs>
      <NavItem>
        <NavLinkL
          className={"cursor-pointer nav-link "+(state.activeTab === '1'?"active":"inactive" )}
          onClick={() => { activeTabToggle('1'); }}
        >
          Expedite Payment <span className="my-badge">{state.totalCount}</span>
        </NavLinkL>
      </NavItem>
      <NavItem>
        <NavLinkL
          className={"cursor-pointer nav-link "+(state.activeTab === '2'?"active":"inactive" )}
          onClick={() => { activeTabToggle('2'); }}
        >
          Pending Approval <span className="my-badge">{state.pendingRecommendations.length}</span>
        </NavLinkL>
      </NavItem>
    </Nav>
    <TabContent activeTab={state.activeTab}>
      <TabPane tabId="1">
        {renderAppraisers()}
      </TabPane>
      <TabPane tabId="2">
        <div className="large-scroll-container">
          <Table className="table table-striped">
            <thead>
              <tr>
                <th width="5%">Action</th>
                <th width="15%">Appraiser</th>
                <th width="10%">Status</th>
                <th width="10%">State</th>
                <th width="10%">County</th>
                <th width="10%">Email</th>
                <th width="10%">Phone</th>
                <th width="10%">Recommender</th>
                <th width="10%">Comment</th>
                <th width="10%">Date recommended</th>
              </tr>
            </thead>
            <tbody>
              {
                state.pendingRecommendations.map(
                  (recommendation,index)=>{
                    return(
                      <tr key={index}>
                        <td><i className="fa fa-question-circle link-color cursor-pointer" onClick={(e)=>selectedRecommendation(recommendation)}></i></td>
                        <td>{recommendation.first_name+' '+recommendation.last_name}</td>
                        <td>{recommendation.status}</td>
                        <td>{recommendation.state}</td>
                        <td>{recommendation.county}</td>
                        <td>{recommendation.email}</td>
                        <td>{recommendation.phone}</td>
                        <td>{recommendation.recommender}</td>
                        <td>{recommendation.comment}</td>
                        <td>{formatDateTime(recommendation.datetime_suggest)}</td>
                      </tr>
                    );
                  }
                )
              }
            </tbody>
          </Table>
        </div>
      </TabPane>
    </TabContent>
  </InfiniteScroll>
  </div>;
}

export default AppraiserExpeditePayment;
