//Author Sooyoung Kim
//Date May 30, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, callBackGenerator, getSession} from '../../util/util';
import {Button, Col, Row, Input} from 'reactstrap';
import MySelect from '../util/my-select';
import {NavLink, useParams, useNavigate} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';
import ReactQuill from 'react-quill';

let session = getSession();
let name = session.userFirstName+' '+session.userLastName;
let email = session.email;

//initialize the state
const initialState = {
  isInternal:'',
  requesterName:name,
  requesterEmail:email,
  subjectFacts:'',
  other:'',
  conditionTypes:[],
  appraisal: {
    property_street:'',
    property_city:'',
    property_state:'',
    borrower_f_name:'',
    borrower_l_name:'',
    reference_num:'',
    loan_num:'',
    modification_log:[]
  },
};

//reducer function that perform state update
const reducer = getReducer();


const NewCondition  = (props)=>{
  const controller = new AbortController();
  const { id } = useParams();
  let history = useNavigate();

  let newInitialState = Object.assign({}, initialState, {id:id});
  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  //run only once when component is loaded
  useEffect(()=>{
    getConditionTypes();
    getAppraisal(state.id);

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //API call
  //get appraisal
  const getAppraisal = (id) =>{
    setState({inspectionHistory:[],old_special_instructions:''});
    httpGet('appraisal/'+id, '', 'Oops, something went wrong and could not load this appraisal. Please try again later.', apiCallBack([{state:'appraisal', key:'data'}]));
  }

  const getConditionTypes = () =>{
    httpGet('condition/type/get', '', 'Oops, something went wrong and could not load condition types. Please try again later.', apiCallBack([{state:'conditionTypes', key:'data'}]));
  }

  const createNewCondition = (e) =>{
    e.preventDefault();
    let parameters = [
      {
        field:'appraisalFk',
        value:state.id
      },
      {
        field:'isInternal',
        value:state.isInternal
      },
      {
        field:'requesterName',
        value:state.requesterName
      },
      {
        field:'requesterEmail',
        value:state.requesterEmail
      },
      {
        field:'subjectFacts',
        value:state.subjectFacts
      },
      {
        field:'type',
        value:state.type
      },
      {
        field:'other',
        value:state.other
      },
    ];

    let callBack = (response)=>{
      let code= response.data.code;
      if(code==='00'){
        history('/condition/'+state.id);
      }
    };
    callBack = callBack.bind(this);

    httpPost('condition/create', parameters, 'Condition created successfully.', 'Oops, something went wrong and could not create the condition. Please try again later.', callBack);
  }

  //render
  let fhaCaseNumberTitle='';
  if(state.appraisal.fha_case_number&&state.appraisal.fha_case_number!=='0')
    fhaCaseNumberTitle = ' - FHA#'+state.appraisal.fha_case_number;

  let fileNum='';
  if(state.appraisal.third_party_num&&state.appraisal.third_party_num!=='0')
    fileNum = ' - File#'+state.appraisal.third_party_num;

  let other;

  if(state.type==='Other'){
    other =
    <Row>
      <Col sm="12">
        <label>Description</label>
        <Input type="text" value={state.other} onChange={(e)=>setState({other:e.target.value})} required="true"/>
      </Col>
    </Row>
  }

  return <div className="my-well">
    <Row>
      <Col sm="12">
        <div className="page-title">
          <i className="fa fa-reorder"></i>&nbsp;New condition - {state.appraisal.reference_num+'- '+state.appraisal.loan_num+fileNum+' - '+state.appraisal.property_street+' '+state.appraisal.property_city+', '+state.appraisal.property_state+' - '+state.appraisal.borrower_f_name+' '+state.appraisal.borrower_l_name+fhaCaseNumberTitle}
        </div>
      </Col>
      <Col sm="4">
        <NavLink to={"/condition/"+state.id}>Back to condition</NavLink>
      </Col>
    </Row>
    <div className="my-divider"></div>
    <form onSubmit={createNewCondition}>
      <Row>
        <Col sm="12">
          <label>Is internal</label>
          <MySelect
            type="select"
            required="true"
            value={state.isInternal}
            onChange={(v)=>setState({isInternal:v})}
            options={[{label:"Yes",value:"yes"},{label:"No",value:"no"}]}
          />
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <label>Type</label>
          <MySelect
            type="select"
            required="true"
            value={state.type}
            onChange={(v)=>setState({type:v})}
            options={state.conditionTypes.map((conditionType)=>{
              return {label:conditionType.type, value:conditionType.type};
            })}
          />
        </Col>
      </Row>
      {other}
      <Row>
        <Col sm="12">
          <label>Requester name</label>
          <Input type="text" value={state.requesterName} onChange={(e)=>setState({requesterName:e.target.value})} required="true"/>
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <label>Requester email</label>
          <Input type="text" value={state.requesterEmail} onChange={(e)=>setState({requesterEmail:e.target.value})} required="true"/>
        </Col>
      </Row>
      <Row style={{height:'360px'}}>
        <Col sm="12">
          <label>Subject facts</label><br/>
          <ReactQuill
            modules={
              {
                toolbar: [
                    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                    [{size: []}],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{'list': 'ordered'}, {'list': 'bullet'},
                     {'indent': '-1'}, {'indent': '+1'}],
                    ['link', 'image', 'video'],
                    ['clean']
                  ],
                  clipboard: {
                    // toggle to add extra line breaks when pasting HTML:
                    matchVisual: false,
                  },
                  imageResize: {
                  // parchment: Quill.import('parchment'),
                    modules: ['Resize', 'DisplaySize']
                  }
              }
            }
            formats={
              [
                'header', 'font', 'size',
                'bold', 'italic', 'underline', 'strike', 'blockquote',
                'list', 'bullet', 'indent',
                'link', 'image', 'video'
              ]
            }
            value={state.subjectFacts}
            onChange={(value) => setState({subjectFacts:value})}
          />
        </Col>
      </Row>
      <br/>

      <div className="align-right">
        <Button color="warning">Create</Button>
      </div>
    </form>
  </div>;
}

export default NewCondition;
