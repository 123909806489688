//Author Sooyoung Kim
//Date July 7, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, formatDateTime, generateSID, getFromTo, replaceRegex} from '../../../util/util';
import {Button, Card, CardHeader, CardBody, Modal, ModalHeader, ModalBody} from 'reactstrap';
import MyChart from './../../util/my-chart';
import ReportFilter from '../report-filter';
import moment from 'moment';
import {NavLink} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';
import '../report.css';

//initialize the state
const initialState = {
  SID: generateSID(),
  loanPurpose:'',
  loanType:'',
  isRush:'',
  isComplex:'',
  state:'',
  city:'',
  county:'',
  entity:'',
  client:'',
  openBy:'',
  filterAppraisalTypes:[],
  filterCounty:[],
  from:getFromTo('from'),
  to:getFromTo('to'),

  reports:[],
  reportDetailsOrders:[],
  appraisers:[],
  appraiser:'',
};

//reducer function that perform state update
const reducer = getReducer();


const UploadTimeReport  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getReport();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const reportDetailsToggle = () =>{
    if(state.reportDetailsDropDown)
      setState({reportDetailsOrders:[]});
    setState({reportDetailsDropDown:!state.reportDetailsDropDown});
  }

  //API call
  const getReport = () =>{
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);
    let counties = replaceRegex(state.filterCounty, ',');

    let url = 'report/uploadTime/openBy='+state.openBy+'&client='+state.client+'&appraiser='+state.appraiser+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&county='+counties+'&appraisalType='+appraisalType;
	  httpGet(url, '', 'Oops, something went wrong and could not load the report. Please try again later.', apiCallBack([{state:'reports', key:'data'}]));
  }

  const getReportDetails = (hour) =>{
    reportDetailsToggle();
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);
    let counties = replaceRegex(state.filterCounty, ',');

    let url = 'report/uploadTime/details/openBy='+state.openBy+'&client='+state.client+'&appraiser='+state.appraiser+'&loanPurpose='+loanPurpose+'&hour='+hour+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&county='+counties+'&appraisalType='+appraisalType;
    httpGet(url, '', 'Oops, something went wrong and could not load the report details. Please try again later.', apiCallBack([{state:'reportDetailsOrders', key:'data'}]));
  }

  //render
  let from, to;
  if(state.from!=='')
    from = moment(state.from);
  if(state.to!=='')
    to = moment(state.to);

  let content;

  if(state.reportDetailsOrders.length>0){
    let rows = state.reportDetailsOrders.map(
      (order, index)=>{
        return(
          <tr key={index}>
            <td><NavLink target="_blank" to={"/appraisal/"+order.ID}>{order.reference_num}</NavLink></td>
            <td>{order.appraiser_name}</td>
            <td>{order.company}</td>
            <td>{order.property_street+' '+order.property_city+', '+order.property_state+' '+order.property_zip}</td>
            <td>{order.appraisal_type}</td>
            <td>{formatDateTime(order.datetime_appraisal_uploaded)}</td>
          </tr>
        )
      }
    );

    content =
    <table className="table table-striped">
      <thead>
        <tr>
          <th width="15%">Reference #</th>
          <th width="15%">Appraiser</th>
          <th width="15%">Client</th>
          <th width="25%">Address</th>
          <th width="15%">Report Type</th>
          <th width="15%">Date Appraisal Uploaded</th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </table>
  }

  let hourLabel = [];
  let hourCount = [];
  for(let i=0;i<state.reports.length;i++){
    if(state.reports[i].hour<12)
      if(state.reports[i].hour===0)
        hourLabel.push('12 am');
      else
        hourLabel.push(state.reports[i].hour+' am');
    else
      if(state.reports[i].hour===12)
        hourLabel.push('12 pm');
      else
        hourLabel.push(state.reports[i].hour-12+' pm');
    hourCount.push(state.reports[i].count);
  }

  console.log(hourLabel);
  let config = {
    xAxis: {
      categories: hourLabel,
    },
    series: [
      {
        data: hourCount,
        name:'# of upload ',
        point: {
          events: {
            click: (e)=>{
              let hour = e.point.category;

              if(hour.includes('pm')){
                hour = hour.replace(' pm', '');
                hour = parseInt(hour);

                if(hour===12)
                hour = 12;
                else
                hour = hour+12;

                getReportDetails(hour);
              }
              else{
                hour = hour.replace(' am', '');
                hour = parseInt(hour);

                if(hour===12)
                hour = 0;
                getReportDetails(hour);
              }
            }
          }
        }
      }
    ],
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true
        }
      }
    },
    title:{
      text: 'Appraisal Upload Time'
    }
  }

  let chart;

  if(state.reports.length>0){
    chart = <MyChart options={config}/>;
  }  

  let reportFiltersConfig = [
    {id:'from',value:from, updateFunc:(v)=>setState({from:v}), width:'2'},
    {id:'to',value:to, updateFunc:(v)=>setState({to:v}), width:'2'},
    {id:'customSelect',value:state.accrued, updateFunc:(v)=>setState({accrued:v}), width:'2', label:'Accrued Based', clearable:false, className:"", options:[
      {label:"No",value:"no"},{label:"Yes",value:"yes"}
    ]},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'3'},
    {id:'openBy',value:state.openBy, updateFunc:(v)=>setState({openBy:v}), width:'3'},
    {id:'appraiser',value:state.appraiser, updateFunc:(v)=>setState({appraiser:v}), width:'2'},
    {id:'rush',value:state.isRush, updateFunc:(v)=>setState({isRush:v}), width:'2'},
    {id:'complex',value:state.isComplex, updateFunc:(v)=>setState({isComplex:v}), width:'2'},
    {id:'state',value:state.state, updateFunc:(v)=>setState({state:v}), width:'2'},
    {id:'city',value:state.city, updateFunc:(v)=>setState({city:v}), width:'2'},
    {id:'county',value:state.filterCounty, updateFunc:(v)=>setState({filterCounty:v}), width:'2', group:true},
    {id:'loanPurpose',value:state.loanPurpose, updateFunc:(v)=>setState({loanPurpose:v}), width:'2'},
    {id:'loanType',value:state.loanType, updateFunc:(v)=>setState({loanType:v}), width:'2'},
    {id:'entity',value:state.entity, updateFunc:(v)=>setState({entity:v}), width:'2'},
    {id:'appraisalType',value:state.filterAppraisalTypes, updateFunc:(v)=>setState({filterAppraisalTypes:v}), width:'4'},
    {id:'button',value:'Submit', updateFunc:getReport, width:'2', className:"align-right", color:"warning"},
  ];


  return <div>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Appraisal Upload Time Report
      </CardHeader>
      <CardBody>
        <ReportFilter {...props} configs={reportFiltersConfig}/>
      </CardBody>
    </Card>
    <br/>
    <i>Click on the data point on the graph to show a list of orders</i><br/>
    {chart}
    <Modal className="my-modal-wide" isOpen={state.reportDetailsDropDown} toggle={reportDetailsToggle} >
      <ModalHeader hidden={true} toggle={reportDetailsToggle}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-user"></i> Report Details</h5>
        </center>
        <br/>
        <div className="large-scroll-container">
          {content}
        </div>
        <br/>
        <center>
          <Button color="info" onClick={reportDetailsToggle}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
  </div>;
}


export default UploadTimeReport;
