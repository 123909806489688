//Author Sooyoung Kim
//Date Aug 17, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, callBackGenerator, getSession, showMessage, hideMessage, confirmation} from '../../util/util';
import {Button, Modal, ModalHeader, ModalBody, Row, Col, Nav, NavItem} from 'reactstrap';
import {autoResetFields, dataLength, objectDataLengthConfig} from './util';
import React, {useReducer, useEffect, useRef} from 'react';
import MyFormStep from '../util/my-form-step';
import StepPropertyInformation from './step-property-information';
import StepLoanInformation from './step-loan-information';
import StepSite from './step-site';
import StepInspection from './step-inspection';
import StepPhotos from './step-photos';
import StepDataCollectorCertification from './step-data-collector-certification';
import StepReview from './step-review';
import {NavLink, useParams} from 'react-router-dom';

//initialize the state
let session = getSession();
let steps = ['Property Info','Loan Info','Site', 'Repair / Inspection / Improvement', 'Photos', 'Data Collector Certification', 'Review'];
let currentStep = 0;
let maxStep = 0;

const interiorRoom = {
  name:'Room',
  grade:'above',
  type:'Bedroom',
  removable:true,
  floorMaterials:[],
  floorMaterialConditions:[],
  roomPhotos:[],
  floorMaterialPhotos:[],
  floorUpdates:'',
  floorUpdateDescription:'',
  wallAndCeilingMaterials:[],
  wallAndCeilingMaterialConditions:[],
  wallAndCeilingMaterialPhotos:[],
  wallAndCeilingUpdates:'',
  wallAndCeilingUpdateDescription:''
};

const garage = {
  name:'Garage',
  grade:'above',
  removable:true,
  garagePhotos:[],
  garageFloorMaterials:[],
  garageFloorMaterialConditions:[],
  garageFloorMaterialPhotos:[],
  garageFloorUpdates:'',
  garageFloorUpdateDescription:'',
  garageWallAndCeilingMaterials:[],
  garageWallAndCeilingMaterialConditions:[],
  garageWallAndCeilingMaterialPhotos:[],
  garageWallAndCeilingUpdates:'',
  garageWallAndCeilingUpdateDescription:'',
  garageNumberOfSpaceForCar:'',
  garageBuiltIn:'',
  garageSqft:''
};

const interiorKitchen = {
  name:'Kitchen',
  grade:'above',
  removable:true,
  kitchenPhotos:[],
  kitchenFloorMaterials:[],
  kitchenFloorMaterialConditions:[],
  kitchenFloorMaterialPhotos:[],
  kitchenFloorUpdates:'',
  kitchenFloorUpdateDescription:'',
  kitchenWallAndCeilingMaterials:[],
  kitchenWallAndCeilingMaterialConditions:[],
  kitchenWallAndCeilingMaterialPhotos:[],
  kitchenWallAndCeilingUpdates:'',
  kitchenWallAndCeilingUpdateDescription:'',
  kitchenAppliances:[],
  kitchenCountertops:[],
  kitchenApplianceOtherDescription:'',
  kitchenCabinet:'',
  kitchenEstimatedYearOfImprovement:'',
  kitchenImprovementDescription:'',
};

const interiorBathroom = {
  name:'Bathroom',
  grade:'above',
  removable:true,
  bathroomPhotos:[],
  bathroomHalfBath:'False',
  bathroomFloorMaterials:[],
  bathroomFloorMaterialConditions:[],
  bathroomFloorMaterialPhotos:[],
  bathroomFloorUpdates:'',
  bathroomFloorUpdateDescription:'',
  bathroomWallAndCeilingMaterials:[],
  bathroomWallAndCeilingMaterialConditions:[],
  bathroomWallAndCeilingMaterialPhotos:[],
  bathroomWallAndCeilingUpdates:'',
  bathroomWallAndCeilingUpdateDescription:'',
  bathroomShowerTubMaterials:[],
  bathroomShowerTubMaterialConditions:[],
  bathroomShowerTubPhotos:[],
  showSubmittedPrompt:false,
  bathroomEstimatedYearOfImprovement:'',
  bathroomImprovementDescription:'',
};

const initialState = {
  activeTab:'1',
  name: session.userFirstName+' '+session.userLastName,
  status:'',
  appraisalFk:-1,
  steps:steps,
  maxStep:maxStep,
  currentStep:currentStep,

  glaCalculations:[],

  propertyStreet:'',
  propertyCity:'',
  propertyCounty:'',
  propertyState:'',
  propertyZip:'',
  propertyType:'',
  condoType:'',
  condoOtherDescription:'',
  occupancyType:'',
  hoa:'',
  hoaFee:'0',
  yearsOwned:'',
  neighborhoodType:'',
  propertyLat:'',
  propertyLng:'',

  lpaKey:'',
  sellerID:'',
  fileNumber:'',
  additionalFileNumber:'',
  borrowerName:'',
  closeBy:'',
  loanPurpose:'',
  sellingPrice:'',
  dateOfContract:'',
  assignmentType:'',
  pdrType:'ACE+PDR',
  dataSetVersion:'2.0',


  constructionStatus:'',
  requiredRepairs:'',
  requiredInspections:[],
  accessoryUnit:'',
  storiesNumber:'',
  projectStoriesNumber:'',
  unitStoriesNumber:'',
  elevatorsNumber:'',
  floorNumber:'',
  attachmentType:'',
  attachmentTypeEndUnit:'',
  buildingCompletion:'',
  sumpPump:'',
  notLivableIndicator:'',
  notLivableComment:'',
  defectIndicator:'',
  alteredIndicator:[],
  upToStandard:'',
  allSeasonAccessToSystem:'',
  structuralDamage:'',

  personAtProperty:'',
  dataCollectorName:'',
  dataCollectorType:'',
  collectionType:'',
  dataCollectorCompany:'',
  dataCollectorCompanyStreet:'',
  dataCollectorCompanyCity:'',
  dataCollectorCompanyState:'',
  dataCollectorCompanyZip:'',
  dataCollectorContact:'',
  dataCollectorContactMethod:'',
  dateOfSignatureReport:'',
  dateOfObservation:'',
  professionalLicenseType:'',
  professionalLicenseNumber:'',
  professionalLicenseState:'',
  professionalLicenseExpDate:'',
  signature:{},
  supervisorName:'',
  supervisorEmail:'',
  supervisorPhone:'',
  supervisorDateOfSignature:'',
  supervisorLicenseNumber:'',
  supervisorState:'',
  supervisorLicenseExpDate:'',
  observeBothInAndExt:'',
  supervisorSignature:{},
  dataCollectorCertified:false,
  supervisorCertified:false,

  photos:[],
  renewableEnergyComponentPhotos:[],
  frontViewPropertyPhotos:[],
  rearViewPropertyPhotos:[],
  leftSideViewPropertyPhotos:[],
  rightSideViewPropertyPhotos:[],
  leftStreetViewPropertyPhotos:[],
  rightStreetViewPropertyPhotos:[],
  floorPlan:[],

  interiors:[
    Object.assign({}, interiorRoom, {name:'Bedroom 1', removable:false, grade:''}),
    Object.assign({}, interiorRoom, {name:'Bedroom 2', floorMaterials:[], floorMaterialPhotos:[], grade:'', roomPhotos:[], floorMaterialConditions:[], wallAndCeilingMaterials:[], wallAndCeilingMaterialPhotos:[], wallAndCeilingMaterialConditions:[]}),
    Object.assign({}, interiorRoom, {name:'Bedroom 3', floorMaterials:[], floorMaterialPhotos:[], roomPhotos:[], floorMaterialConditions:[], wallAndCeilingMaterials:[], wallAndCeilingMaterialPhotos:[], wallAndCeilingMaterialConditions:[]}),
    Object.assign({}, interiorRoom, {name:'Dining Room', removable:false, floorMaterials:[], grade:'', roomPhotos:[], floorMaterialPhotos:[], floorMaterialConditions:[], wallAndCeilingMaterials:[], wallAndCeilingMaterialPhotos:[], wallAndCeilingMaterialConditions:[]}),
    Object.assign({}, interiorRoom, {name:'Living Room', removable:false, floorMaterials:[], grade:'', roomPhotos:[], floorMaterialPhotos:[], floorMaterialConditions:[], wallAndCeilingMaterials:[], wallAndCeilingMaterialPhotos:[], wallAndCeilingMaterialConditions:[]}),
  ],
  kitchens:[
    Object.assign({}, interiorKitchen, {name:'Kitchen', grade:'',removable:false, kitchenFloorMaterials:[], kitchenPhotos:[], kitchenFloorMaterialPhotos:[], kitchenFloorMaterialConditions:[], kitchenWallAndCeilingMaterials:[], kitchenWallAndCeilingMaterialPhotos:[], kitchenWallAndCeilingMaterialConditions:[], kitchenAppliances:[], kitchenCountertops:[],kitchenApplianceOtherDescription:'', kitchenCabinet:''}),
  ],

  bathrooms:[
    Object.assign({}, interiorBathroom, {name:'Bathroom', grade:'',bathroomHalfBath:'False', removable:false, bathroomPhotos:[], bathroomFloorMaterials:[], bathroomFloorMaterialPhotos:[], bathroomFloorMaterialConditions:[], bathroomWallAndCeilingMaterials:[], bathroomWallAndCeilingMaterialPhotos:[], bathroomWallAndCeilingMaterialConditions:[], bathroomShowerTubMaterials:[], bathroomShowerTubMaterialConditions:[], bathroomShowerTubPhotos:[], thisistoTEST:[]}),
  ],

  garages:[
    Object.assign({}, garage, {name:'Garage', grade:'',removable:true, garageFloorMaterials:[], garagePhotos:[], garageFloorMaterialPhotos:[], garageFloorMaterialConditions:[], garageWallAndCeilingMaterials:[], garageWallAndCeilingMaterialPhotos:[], garageWallAndCeilingMaterialConditions:[], garageNumberOfSpaceForCar:'', garageBuiltIn:'', garageSqft:''}),
  ],

  parkings:[],
  amenities:[],
  commonAmenities:[],
  utilities:[],

  attic:'',
  atticFeatures:[],
  atticFeatureDescription:'',
  heatings:[],
  heatingPhotos:[],
  coolings:[],
  coolingPhotos:[],
  washerDryerHookup:'',
  siteStructureAmenityCount:'',
  siteStructureAmenityOtherDescription:'',
  foundationTypes:[],
  evidenceOfCondition:'',
  evidenceOfConditionDescription:'',
  foundationWallMaterials:[],
  foundationWallMaterialDescription:'',
  foundationWallConditions:[],
  foundationWallPhotos:[],
  exteriorWallMaterials:[],
  exteriorWallMaterialDescription:'',
  exteriorWallConditions:[],
  exteriorWallPhotos:[],
  roofMaterials:[],
  roofMaterialDescription:'',
  roofConditions:[],
  roofPhotos:[],
  roofAge:'',
  gutterAndDownspoutMaterials:[],
  gutterOtherDescription:'',
  gutterAndDownspoutConditions:[],
  gutterAndDownspoutPhotos:[],
  windowMaterials:[],
  windowConditions:[],
  windowPhotos:[],
  windowUpdates:'',
  windowUpdateDescription:'',
  stormSash:'',
  screens:'',
  viewTypes:[],
  viewDescription:'',
  streetAlleyTypes:[],
  streetAlleyMaterials:[],
  streetAlleyOwnership:'',
  siteConditions:[],
  siteConditionDescription:'',
  adjoinExternalFactors:[],
  adjoinExternalFactorDescription:'',
  sqftBelowGrade:'',
  belowGradeAccess:[],
  percentBelowGrade:'',
  recreationBelowGrade:'',
  bedroomBelowGrade:'',
  bathroomBelowGrade:'',
  halfbathBelowGrade:'',
  roomBelowGrade:'',
  aboveGradeRoomCount:'',
  aboveGradeBedroomCount:'',
  aboveGradeBathroomCount:'',
  aboveGradeHalfBathCount:'',
  aboveGradeGrossLivingArea:'',
  structureType:'PrimaryDwelling',
  additionalStructures:[],
  primaryDwellingAreaType:'',
  primaryDwellingGarageBuiltIn:'',
  primaryDwellingAreaDescription:'',
  aduAreaType:'',
  aduAreaDescription:'',
  aduGarageBuiltIn:'',
  structureDescription:'',
  energyRatingPerformed:'',
  energyRatingPerformedPhotos:[],
  energyRatingOrganization:'',
  energyRatingDescription:'',
  renewableEnergyPresent:'',
  renewableEnergyComponent:'',
  renewableEnergyComponentDescription:'',
  solarPanelOwnership:'',
  carStorageIndicator:'',
  onSiteParkingTypes:[],
  garageBuiltIn:'',
  guestParking:'',
  guestParkingNumber:'',
  guestParkingType:'',
};

//reducer function that perform state update
const reducer = getReducer();


const PropertyDataReport  = (props)=>{
  const controller = new AbortController();
  const parentElementRef = useRef(null);
  let {pdrFk} = useParams();

  let newInitialState = Object.assign({}, initialState, {
    pdrFk:pdrFk,
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);
  const interiorsRef = useRef(state.interiors);
  const bathroomsRef = useRef(state.bathrooms);
  const kitchensRef = useRef(state.kitchens);
  const garagesRef = useRef(state.garages);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getReport();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  //non API call but simpyl manage state
  const saveProgress = async () => {
    await saveForm();
  };

  const toggleShowPrompt = () => {
    setState({showSubmittedPrompt:!state.showSubmittedPrompt});
  }

  const finalCheck = () => {
    let fail = false;

    const checkAndShowMessage = (condition, errorMessage) => {
      if (condition) {
        showMessage('error', errorMessage);
        fail = true;
      }
    };

    checkAndShowMessage(state.frontViewPropertyPhotos.length <= 0,'Please upload the front view photo of the property.');
    checkAndShowMessage(state.rearViewPropertyPhotos.length <= 0,'Please upload the rear view photo of the property.');
    checkAndShowMessage(state.rearViewPropertyPhotos.length <= 0,'Please upload the left side view photo of the property.');
    checkAndShowMessage(state.rearViewPropertyPhotos.length <= 0,'Please upload the right side photo of the property.');
    checkAndShowMessage(state.rearViewPropertyPhotos.length <= 0,'Please upload the left street view photo of the property.');
    checkAndShowMessage(state.rearViewPropertyPhotos.length <= 0,'Please upload the right street view photo of the property.');
    checkAndShowMessage(state.rearViewPropertyPhotos.length <= 0,'Please upload the floor plan of the property.');
    checkAndShowMessage(state.rearViewPropertyPhotos.length <= 0 && state.heatings.length>0,'Please upload the heating photo of the property.');
    checkAndShowMessage(state.rearViewPropertyPhotos.length <= 0 && state.coolings.length>0,'Please upload the cooling photo of the property.');
    checkAndShowMessage(state.rearViewPropertyPhotos.length <= 0 && state.foundationWallConditions.indexOf('None')===-1,'Please upload the foundation wall photo of the property.');
    checkAndShowMessage(state.rearViewPropertyPhotos.length <= 0 && state.exteriorWallConditions.indexOf('None')===-1,'Please upload the exterior wall photo of the property.');
    checkAndShowMessage(state.rearViewPropertyPhotos.length <= 0 && state.roofConditions.indexOf('None')===-1,'Please upload the roof photo of the property.');
    checkAndShowMessage(state.rearViewPropertyPhotos.length <= 0 && state.gutterAndDownspoutConditions.indexOf('None')===-1,'Please upload the gutter & downspout photo of the property.');
    checkAndShowMessage(state.rearViewPropertyPhotos.length <= 0 && state.windowConditions.indexOf('None')===-1,'Please upload the window of the property.');
    checkAndShowMessage(state.rearViewPropertyPhotos.length <= 0 && state.renewableEnergyComponentPhotos.length<=0,'Please upload the renewable energy component photo of the property.');
    checkAndShowMessage(state.rearViewPropertyPhotos.length <= 0 && state.energyRatingPerformedPhotos.length<=0,'Please upload the energy rating performed photo of the property.');

    for(let i=0;i<state.interiors.length;i++){
      checkAndShowMessage(state.interiors[i].floorMaterialPhotos.length<=0&&state.interiors[i].floorMaterialConditions.indexOf('None')===-1,'Please upload the floor material photo of the bedroom '+state.interiors[i].name+' of the property.');
      checkAndShowMessage(state.interiors[i].wallAndCeilingMaterialPhotos.length<=0&&state.interiors[i].wallAndCeilingMaterialPhotos.indexOf('None')===-1,'Please upload the wall & ceiling material photo of the bedroom '+state.interiors[i].name+' of the property.');
    }

    for(let i=0;i<state.bathrooms.length;i++){
      checkAndShowMessage(state.bathrooms[i].bathroomFloorMaterialPhotos.length<=0 && state.bathrooms[i].bathroomFloorMaterialConditions.indexOf('None')===-1,'Please upload the floor material photo of the bathroom '+state.bathrooms[i].name+' of the property.');
      checkAndShowMessage(state.bathrooms[i].bathroomWallAndCeilingMaterialPhotos.length<=0 && state.bathrooms[i].bathroomWallAndCeilingMaterialConditions.indexOf('None')===-1,'Please upload the wall & ceiling material photo of the bathroom '+state.bathrooms[i].name+' of the property.');
      checkAndShowMessage(state.bathrooms[i].halfBath==='False' && state.bathrooms[i].bathroomShowerTubPhotos.length<=0 && state.bathrooms[i].bathroomShowerTubMaterialCondition.indexOf('None')===-1,'Please upload the shower tub material photo of the bathroom '+state.bathrooms[i].name+' of the property.');
    }

    for(let i=0;i<state.kitchens.length;i++){
      checkAndShowMessage(state.kitchens[i].kitchenFloorMaterialPhotos.length<=0&&state.kitchens[i].kitchenFloorMaterialConditions.indexOf('None')===-1,'Please upload the floor material photo of the kitchen '+state.kitchens[i].name+' of the property.');
      checkAndShowMessage(state.kitchens[i].kitchenWallAndCeilingMaterialPhotos.length<=0&&state.kitchens[i].kitchenWallAndCeilingMaterialConditions.indexOf('None')===-1,'Please upload the wall & ceiling material photo of the kitchen '+state.kitchens[i].name+' of the property.');
    }

    //make sure signature is signed
    if((!state.signature.draw||state.signature.draw==='')&&(!state.signature.upload||state.signature.upload==='')){
      showMessage('error', 'Please sign the report or upload your signature with a photo.');
      fail = true;
    }
    if(state.supervisorName!==''&&(!state.signature.draw||state.signature.draw==='')&&(!state.signature.upload || state.signature.upload==='')){
      showMessage('error', 'Please have your supervisor sign the report or upload your supervisor signature with a photo.');
      fail = true;
    }

    return fail;
  };

  const addNewRoom = (arrayName, item) => {
    let currentRoom = state[arrayName];
    currentRoom.push(item);
    setState({[arrayName]:currentRoom});
  };

  const removeRoom = (arrayName, index) => {
    let currentRoom = state[arrayName].slice();
    currentRoom.splice(index, 1);
    setState({[arrayName]:currentRoom});
  };

  const addNewItem = (arrayName, newItem, keyName, type) => {
    let items = state[arrayName].slice();
    let found = false;
  
    if(newItem === 'None'){
      if(arrayName==='amenities'||arrayName==='utilities'){
        items = ['None']
      }else{
        items = [];
      }
    }else{
      for(let i = 0; i < items.length; i++){
        if(items[i][keyName] === newItem){
          found = true;
          break;
        }
      }
  
      if(!found){
        const newItemObject = {[keyName]: newItem,...type};
        items.push(newItemObject);
      }
    }
  
    setState({[arrayName]:items});
  };
  
  const removeItem = (arrayName, keyName, item) => {
    let items = state[arrayName].slice();
    for(let i=0;i<items.length;i++){
      if(items[i][keyName] === item){
        items.splice(i, 1);
        break;
      }
    }
    setState({[arrayName]:items});
  };

  const updateState = (stateUpdate) => {
    Object.keys(stateUpdate).forEach((key)=>{
      let tmp = Object.assign({}, stateUpdate);
      let newValue = tmp[key];

      let objectDataControls = objectDataLengthConfig();
      let objectKeys = Object.keys(objectDataControls);

      if(objectKeys.indexOf(key)!==-1){
        let dataControls = objectDataControls[key];

        for(let x=0;x<newValue.length;x++){
          for(let y=0;y<dataControls.length;y++){
            let field = dataControls[y].field;
            let targetField = newValue[x][field];

            let maxChar = dataControls[y].max;
            let actualLength = -1;

            let spaceCount = targetField.split(' ').length - 1;
            actualLength = targetField.length - spaceCount;

            if(actualLength > maxChar && maxChar !== -1){
              showMessage('warning', 'Max character of input reached.');
              let start = -1;
              let end = -1;
              let j = 0;

              for(let i=0;i<targetField.length;i++){
                if(start=== -1 && targetField[i]!==' ')
                  start = i;

                if(targetField[i]!==' '&& j <= maxChar){
                  end = i;
                  j++;
                }
              }

              targetField = targetField.substr(start, end);
              newValue[x][field] = targetField;
            }
          }
        }
      }
      else{
        let maxChar = dataLength(key, tmp[key]);
        let actualLength = -1;
        if(typeof tmp[key] === 'string'){
          let spaceCount = tmp[key].split(' ').length - 1;
          actualLength = tmp[key].length - spaceCount;
        }

        if(actualLength > maxChar && maxChar !== -1){
          showMessage('warning', 'Max character of input reached.');
          let start = -1;
          let end = -1;
          let j = 0;

          for(let i=0;i<newValue.length;i++){
            if(start=== -1 && newValue[i]!==' ')
              start = i;

            if(newValue[i]!==' '&& j <= maxChar){
              end = i;
              j++;
            }
          }

          newValue = tmp[key].substr(start, end);
        }
      }


      stateUpdate[key] = newValue;
    });
    console.log('before update',stateUpdate);
    let newState = Object.assign({}, stateUpdate);
    let toUpdatedState = Object.assign({}, state, {...stateUpdate});
    Object.keys(stateUpdate).forEach((key)=>{
      let stateNeedsReset = autoResetFields(key, stateUpdate[key], toUpdatedState);
      //console.log(stateNeedsReset);
      newState = Object.assign({}, newState, {...stateNeedsReset});
    });
    console.log('after update',newState);

    setState(newState);
  }

  const updateStep = (step) => {
    if(parentElementRef.current)
      parentElementRef.current.scrollTop = 0;
    if(step<=state.maxStep)
      setState({currentStep:step});
  }

  const handleStepAdvance = async (e) => {
    e.preventDefault();

    if(parentElementRef.current)
      parentElementRef.current.scrollTop = 0;

    let step = state.currentStep+1;

    if(step<7){
      let stateToUpdate = {};
      saveProgress();

      stateToUpdate.currentStep = step;
      if(state.maxStep<step)
        stateToUpdate.maxStep = step;
      setState(stateToUpdate);
    }
    else{
      //submit
      if(!finalCheck())
        submitPDRRequest();
    }
  }


  //API call
  const saveForm = async (message='') => {
    let parameters = [];

    let arrayFields = ['interiors','kitchens','bathrooms','heatings','coolings','parkings','amenities','commonAmenities','additionalStructures'];
    //push all the fields into http post
    Object.keys(state).forEach((key)=>{
      //skip photos
      if(!key.toLowerCase().includes('photos')){
        if(key!=='steps'&&key!=='currentStep' && arrayFields.indexOf(key)===-1){
          let value = state[key];
          if(Array.isArray(value))
            value = value.join(', ');
          if(key==='maxStep')
            value = parseInt(value, 10) + 1;
          parameters.push({field:key, value:value});
        }
      }
    });

    //array format parameters
    const formatArrayFields = ['glaCalculations','parkings','interiors','kitchens','bathrooms','garages','heatings','coolings','additionalStructures','amenities','utilities','commonAmenities'];

    formatArrayFields.forEach((fieldName) => {
      const fieldData = state[fieldName].slice();
      const tmp = [];
      for (let i = 0; i < fieldData.length; i++) {
        const tmp2 = Object.assign({}, fieldData[i]);
        Object.keys(tmp2).forEach((key2) => {
          if (Array.isArray(tmp2[key2])) {
            tmp2[key2] = tmp2[key2].join(', ');
          }
        });
        tmp.push(tmp2);
      }
      parameters.push({ field: fieldName, value: tmp });
    });

    let callBack = (response)=>{
      let code= response.data.code;
      if(code==='00'){
        if(response.data.warning_message){
          showMessage('warning', response.data.warning_message);
        }
        loadState(response.data.data);
      }
    };
    callBack = callBack.bind(this);

    httpPut('propertyDataReport/update', parameters, message, 'Oops, something went wrong and could not save the report. Please try again later.', callBack);
  };

  const getPhotos = () => {
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        //load the state with value
        loadPhotos(response.data.data);
      }
    };

    callBack = callBack.bind(this);

    httpGet('propertyDataReport/photos/'+state.pdrFk, '', 'Oops, something went wrong and could not load this report. Please try again later.', callBack);
  }

  const loadPhotos = (res) => {
    let interiors = interiorsRef.current.slice();
    let bathrooms = bathroomsRef.current.slice();
    let kitchens = kitchensRef.current.slice();
    let garages = garagesRef.current.slice();
    console.log(interiorsRef.current);
    console.log(bathroomsRef.current);

    let photos = [];
    let renewableEnergyComponentPhotos = [];
    let heatingPhotos = [];
    let coolingPhotos = [];
    let foundationWallPhotos = [];
    let exteriorWallPhotos = [];
    let roofPhotos = [];
    let gutterAndDownspoutPhotos = [];
    let energyRatingPerformedPhotos = [];
    let windowPhotos = [];
    let frontViewPropertyPhotos = [];
    let rearViewPropertyPhotos = [];
    let leftSideViewPropertyPhotos = [];
    let rightSideViewPropertyPhotos = [];
    let leftStreetViewPropertyPhotos = [];
    let rightStreetViewPropertyPhotos = [];
    let floorPlan = [];
    let signature = {};
    let supervisorSignature = {};

    for(let i=0;i<res.length;i++){

      let tmp = {
        ID:res[i].ID,
        name:res[i].name,
        type:res[i].type,
        typeFk:res[i].type_fk,
        size:res[i].file_size,
        description:res[i].description,
        status:'Loading',
        presign_url:res[i].presign_url
      }
      switch(res[i].type){
        case 'Room Floor':
          for(let j=0;j<interiors.length;j++){
            if(interiors[j].ID && interiors[j].ID===res[i].type_fk)
              interiors[j].floorMaterialPhotos.push(tmp);
          }
          break;
        case 'Room Wall Ceiling':
          for(let j=0;j<interiors.length;j++){
            if(interiors[j].ID && interiors[j].ID===res[i].type_fk)
              interiors[j].wallAndCeilingMaterialPhotos.push(tmp);
          }
          break;
        case 'Bathroom Floor':
          for(let j=0;j<bathrooms.length;j++){
            if(bathrooms[j].ID && bathrooms[j].ID===res[i].type_fk)
              bathrooms[j].bathroomFloorMaterialPhotos.push(tmp);
          }
          break;
        case 'Bathroom Wall Ceiling':
          for(let j=0;j<bathrooms.length;j++){
            if(bathrooms[j].ID && bathrooms[j].ID===res[i].type_fk)
              bathrooms[j].bathroomWallAndCeilingMaterialPhotos.push(tmp);
          }
          break;
        case 'Bathroom Shower Tub':
          for(let j=0;j<bathrooms.length;j++){
            if(bathrooms[j].ID && bathrooms[j].ID===res[i].type_fk)
              bathrooms[j].bathroomShowerTubPhotos.push(tmp);
          }
          break;
        case 'Bathroom Photo':
          for(let j=0;j<bathrooms.length;j++){
            if(bathrooms[j].ID && bathrooms[j].ID===res[i].type_fk)
              bathrooms[j].bathroomPhotos.push(tmp);
          }
          break;
        case 'Kitchen Floor':
          for(let j=0;j<kitchens.length;j++){
            if(kitchens[j].ID && kitchens[j].ID===res[i].type_fk)
              kitchens[j].kitchenFloorMaterialPhotos.push(tmp);
          }
          break;
        case 'Kitchen Wall Ceiling':
          for(let j=0;j<kitchens.length;j++){
            if(kitchens[j].ID && kitchens[j].ID===res[i].type_fk)
              kitchens[j].kitchenWallAndCeilingMaterialPhotos.push(tmp);
          }
          break;
        case 'Kitchen Photo':
          for(let j=0;j<kitchens.length;j++){
            if(kitchens[j].ID && kitchens[j].ID===res[i].type_fk)
              kitchens[j].kitchenPhotos.push(tmp);
          }
          break;
        case 'Garage Floor':
          for(let j=0;j<garages.length;j++){
            if(garages[j].ID && garages[j].ID===res[i].type_fk)
              garages[j].garageFloorMaterialPhotos.push(tmp);
          }
          break;
        case 'Garage Photo':
          for(let j=0;j<garages.length;j++){
            if(garages[j].ID && garages[j].ID===res[i].type_fk)
              garages[j].garagePhotos.push(tmp);
          }
          break;
        case 'Room Photo':
          for(let j=0;j<interiors.length;j++){
            if(interiors[j].ID && interiors[j].ID===res[i].type_fk)
              interiors[j].roomPhotos.push(tmp);
          }
          break;
        case 'Garage Wall Ceiling':
          for(let j=0;j<garages.length;j++){
            if(garages[j].ID && garages[j].ID===res[i].type_fk)
              garages[j].garageWallAndCeilingMaterialPhotos.push(tmp);
          }
          break;
        case 'Heating':
          heatingPhotos.push(tmp);
          break;
        case 'Cooling':
          coolingPhotos.push(tmp);
          break;
        case 'Foundation Wall':
          foundationWallPhotos.push(tmp);
          break;
        case 'Exterior Wall':
          exteriorWallPhotos.push(tmp);
          break;
        case 'Roof Surface':
          roofPhotos.push(tmp);
          break;
        case 'Renewable Energy Component':
          renewableEnergyComponentPhotos.push(tmp);
          break;
        case 'Gutter Downspout':
          gutterAndDownspoutPhotos.push(tmp);
          break;
        case 'Window':
          windowPhotos.push(tmp);
          break;
        case 'Energy Rating':
          energyRatingPerformedPhotos.push(tmp);
          break;
        case 'Floor Plan':
          floorPlan.push(tmp);
          break;
        case 'Front View':
          frontViewPropertyPhotos.push(tmp);
          break;
        case 'Rear View':
          rearViewPropertyPhotos.push(tmp);
          break;
        case 'Left Side View':
          leftSideViewPropertyPhotos.push(tmp);
          break;
        case 'Right Side View':
          rightSideViewPropertyPhotos.push(tmp);
          break;
        case 'Left Street View':
          leftStreetViewPropertyPhotos.push(tmp);
          break;
        case 'Right Street View':
          rightStreetViewPropertyPhotos.push(tmp);
          break;
        case 'Misc':
          photos.push(tmp);
          break;
        case 'Signature':
          signature = tmp;
          break;
        case 'Supervisor Signature':
          supervisorSignature = tmp;
          break;
        default:

          break;
      }
    }
    console.log(interiors)
    setState({
      interiors:interiors,
      kitchens:kitchens,
      bathrooms:bathrooms,
      garages:garages,
      heatingPhotos:heatingPhotos,
      coolingPhotos:coolingPhotos,
      foundationWallPhotos:foundationWallPhotos,
      exteriorWallPhotos:exteriorWallPhotos,
      roofPhotos:roofPhotos,
      windowPhotos:windowPhotos,
      gutterAndDownspoutPhotos:gutterAndDownspoutPhotos,
      energyRatingPerformedPhotos:energyRatingPerformedPhotos,
      frontViewPropertyPhotos:frontViewPropertyPhotos,
      rearViewPropertyPhotos:rearViewPropertyPhotos,
      leftSideViewPropertyPhotos:leftSideViewPropertyPhotos,
      rightSideViewPropertyPhotos:rightSideViewPropertyPhotos,
      leftStreetViewPropertyPhotos:leftStreetViewPropertyPhotos,
      rightStreetViewPropertyPhotos:rightStreetViewPropertyPhotos,
      floorPlan:floorPlan,
      signature:signature,
      supervisorSignature:supervisorSignature,
      photos:photos,
      renewableEnergyComponentPhotos:renewableEnergyComponentPhotos,
    });
  }

  const getReport = () => {
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        //load the state with value
        loadState(response.data.data);
        getPhotos();
        if(state.maxStep<response.data.data.max_step)
          setState({maxStep:response.data.data.max_step});
      }
    };
    callBack = callBack.bind(this);

    httpGet('propertyDataReport/'+state.pdrFk, '', 'Oops, something went wrong and could not load this report. Please try again later.', callBack);
  }

  const loadState = (res) => {
    console.log(res);
    let extKitchenPhoto = {};
    let extInteriorPhoto = {};
    let extBathroomPhoto = {};
    let extGaragePhoto = {};

    let interiorsCopied = state.interiors.slice();
    for(let i=0;i<interiorsCopied.length;i++){

        extInteriorPhoto[i] = {
          roomPhotos: interiorsCopied[i].roomPhotos && interiorsCopied[i].roomPhotos!==''?interiorsCopied[i].roomPhotos.slice():[],
          floorMaterialPhotos: interiorsCopied[i].floorMaterialPhotos && interiorsCopied[i].floorMaterialPhotos!==''?interiorsCopied[i].floorMaterialPhotos.slice():[],
          wallAndCeilingMaterialPhotos: interiorsCopied[i].wallAndCeilingMaterialPhotos && interiorsCopied[i].wallAndCeilingMaterialPhotos!==''?interiorsCopied[i].wallAndCeilingMaterialPhotos.slice():[],
        };
    }

    let kitchensCopied = state.kitchens.slice();
    for(let i=0;i<kitchensCopied.length;i++){
        extKitchenPhoto[i] = {
          kitchenPhotos: kitchensCopied[i].kitchenPhotos && kitchensCopied[i].kitchenPhotos!==''?kitchensCopied[i].kitchenPhotos.slice():[],
          kitchenFloorMaterialPhotos: kitchensCopied[i].kitchenFloorMaterialPhotos && kitchensCopied[i].kitchenFloorMaterialPhotos!==''?kitchensCopied[i].kitchenFloorMaterialPhotos.slice():[],
          kitchenWallAndCeilingMaterialPhotos: kitchensCopied[i].kitchenWallAndCeilingMaterialPhotos && kitchensCopied[i].kitchenWallAndCeilingMaterialPhotos!==''?kitchensCopied[i].kitchenWallAndCeilingMaterialPhotos.slice():[],
        };
    }

    let bathroomsCopied = state.bathrooms.slice();
    for(let i=0;i<bathroomsCopied.length;i++){
        extBathroomPhoto[i] = {
          bathroomPhotos: bathroomsCopied[i].bathroomPhotos && bathroomsCopied[i].bathroomPhotos!==''?bathroomsCopied[i].bathroomPhotos.slice():[],
          bathroomFloorMaterialPhotos: bathroomsCopied[i].bathroomFloorMaterialPhotos && bathroomsCopied[i].bathroomFloorMaterialPhotos!==''?bathroomsCopied[i].bathroomFloorMaterialPhotos.slice():[],
          bathroomWallAndCeilingMaterialPhotos: bathroomsCopied[i].bathroomWallAndCeilingMaterialPhotos && bathroomsCopied[i].bathroomWallAndCeilingMaterialPhotos!==''?bathroomsCopied[i].bathroomWallAndCeilingMaterialPhotos.slice():[],
          bathroomShowerTubPhotos: bathroomsCopied[i].bathroomShowerTubPhotos && bathroomsCopied[i].bathroomShowerTubPhotos!==''?bathroomsCopied[i].bathroomShowerTubPhotos.slice():[],
        };
    }

    let garagesCopied = state.garages.slice();
    for(let i=0;i<garagesCopied.length;i++){
        extGaragePhoto[i] = {
          garagePhotos: garagesCopied[i].garagePhotos && garagesCopied[i].garagePhotos!==''?garagesCopied[i].garagePhotos.slice():[],
          garageFloorMaterialPhotos: garagesCopied[i].garageFloorMaterialPhotos && garagesCopied[i].garageFloorMaterialPhotos!==''?garagesCopied[i].garageFloorMaterialPhotos.slice():[],
          garageWallAndCeilingMaterialPhotos: garagesCopied[i].garageWallAndCeilingMaterialPhotos && garagesCopied[i].garageWallAndCeilingMaterialPhotos!==''?garagesCopied[i].garageWallAndCeilingMaterialPhotos.slice():[],
        };
    }

    let commonAmenities = [];
    let amenities = [];
    let heatings = [];
    let coolings = [];
    let interiors = [];
    let kitchens = [];
    let bathrooms = [];
    let garages = [];
    let parkings = [];
    let additionalStructures = [];
    let utilities = [];
    let glaCalculations = [];

    for(let i=0;i<res.interiors.length;i++){
      interiors.push(
        {
          ID:res.interiors[i].ID?res.interiors[i].ID:-1,
          name:res.interiors[i].name,
          grade:res.interiors[i].grade,
          type:res.interiors[i].type,
          removable:true,
          floorMaterials:res.interiors[i].interior_floor_materials && res.interiors[i].interior_floor_materials!==''?res.interiors[i].interior_floor_materials.split(', '):[],
          roomPhotos:extInteriorPhoto[i]?extInteriorPhoto[i].roomPhotos:[],
          floorMaterialPhotos:extInteriorPhoto[i]?extInteriorPhoto[i].floorMaterialPhotos:[],
          floorMaterialConditions:res.interiors[i].interior_floor_material_conditions && res.interiors[i].interior_floor_material_conditions!==''?res.interiors[i].interior_floor_material_conditions.split(', '):[],
          floorUpdates:res.interiors[i].interior_floor_updates,
          floorUpdateDescription:res.interiors[i].interior_floor_update_description,
          wallAndCeilingMaterials:res.interiors[i].interior_wall_ceiling_materials && res.interiors[i].interior_wall_ceiling_materials!==''?res.interiors[i].interior_wall_ceiling_materials.split(', '):[],
          wallAndCeilingMaterialPhotos:extInteriorPhoto[i]?extInteriorPhoto[i].wallAndCeilingMaterialPhotos:[],
          wallAndCeilingMaterialConditions:res.interiors[i].interior_wall_ceiling_material_conditions && res.interiors[i].interior_wall_ceiling_material_conditions!==''?res.interiors[i].interior_wall_ceiling_material_conditions.split(', '):[],
          wallAndCeilingUpdates:res.interiors[i].interior_wall_ceiling_updates,
          wallAndCeilingUpdateDescription:res.interiors[i].interior_wall_ceiling_update_description,
        }
      )
    }

    for(let i=0;i<res.kitchens.length;i++){
      kitchens.push(
        {
          ID:res.kitchens[i].ID?res.kitchens[i].ID:-1,
          name:res.kitchens[i].name,
          grade:res.kitchens[i].grade,
          removable:true,
          kitchenFloorMaterials:res.kitchens[i].floor_materials  && res.kitchens[i].floor_materials!==''?res.kitchens[i].floor_materials.split(', '):[],
          kitchenPhotos:extKitchenPhoto[i]?extKitchenPhoto[i].kitchenPhotos:[],
          kitchenFloorMaterialPhotos:extKitchenPhoto[i]?extKitchenPhoto[i].kitchenFloorMaterialPhotos:[],
          kitchenFloorMaterialConditions:res.kitchens[i].floor_material_conditions  && res.kitchens[i].floor_material_conditions!==''?res.kitchens[i].floor_material_conditions.split(', '):[],
          kitchenFloorUpdates:res.kitchens[i].floor_updates,
          kitchenFloorUpdateDescription:res.kitchens[i].floor_update_description,
          kitchenWallAndCeilingMaterials:res.kitchens[i].wall_ceiling_materials  && res.kitchens[i].wall_ceiling_materials!==''?res.kitchens[i].wall_ceiling_materials.split(', '):[],
          kitchenWallAndCeilingMaterialPhotos:extKitchenPhoto[i]?extKitchenPhoto[i].kitchenWallAndCeilingMaterialPhotos:[],
          kitchenWallAndCeilingMaterialConditions:res.kitchens[i].wall_ceiling_material_conditions  && res.kitchens[i].wall_ceiling_material_conditions!==''?res.kitchens[i].wall_ceiling_material_conditions.split(', '):[],
          kitchenWallAndCeilingUpdates:res.kitchens[i].wall_ceiling_updates,
          kitchenWallAndCeilingUpdateDescription:res.kitchens[i].wall_ceiling_update_description,
          kitchenAppliances:res.kitchens[i].appliances  && res.kitchens[i].appliances!==''?res.kitchens[i].appliances.split(', '):[],
          kitchenApplianceOtherDescription:res.kitchens[i].appliance_other_description,
          kitchenCabinet:res.kitchens[i].cabinet,
          kitchenEstimatedYearOfImprovement:res.kitchens[i].estimated_year_of_improvement,
          kitchenImprovementDescription:res.kitchens[i].improvement_description,
          kitchenCountertops:res.kitchens[i].countertops  && res.kitchens[i].countertops!==''?res.kitchens[i].countertops.split(', '):[],
        }
      )
    }

    for(let i=0;i<res.bathrooms.length;i++){
      bathrooms.push(
        {
          ID:res.bathrooms[i].ID?res.bathrooms[i].ID:-1,
          bathroomHalfBath:parseInt(res.bathrooms[i].half_bath,10)===1?'True':'False',
          name:res.bathrooms[i].name,
          grade:res.bathrooms[i].grade,
          removable:true,
          bathroomFloorMaterials:res.bathrooms[i].floor_materials  && res.bathrooms[i].floor_materials!==''?res.bathrooms[i].floor_materials.split(', '):[],
          bathroomPhotos:extBathroomPhoto[i]?extBathroomPhoto[i].bathroomPhotos:[],
          bathroomFloorMaterialPhotos:extBathroomPhoto[i]?extBathroomPhoto[i].bathroomFloorMaterialPhotos:[],
          bathroomFloorMaterialConditions:res.bathrooms[i].floor_material_conditions  && res.bathrooms[i].floor_material_conditions!==''?res.bathrooms[i].floor_material_conditions.split(', '):[],
          bathroomFloorUpdates:res.bathrooms[i].floor_updates,
          bathroomFloorUpdateDescription:res.bathrooms[i].floor_update_description,
          bathroomWallAndCeilingMaterials:res.bathrooms[i].wall_ceiling_materials  && res.bathrooms[i].wall_ceiling_materials!==''?res.bathrooms[i].wall_ceiling_materials.split(', '):[],
          bathroomWallAndCeilingMaterialPhotos:extBathroomPhoto[i]?extBathroomPhoto[i].bathroomWallAndCeilingMaterialPhotos:[],
          bathroomWallAndCeilingMaterialConditions:res.bathrooms[i].wall_ceiling_material_conditions  && res.bathrooms[i].wall_ceiling_material_conditions!==''?res.bathrooms[i].wall_ceiling_material_conditions.split(', '):[],
          bathroomWallAndCeilingUpdates:res.bathrooms[i].wall_ceiling_updates,
          bathroomWallAndCeilingUpdateDescription:res.bathrooms[i].wall_ceiling_update_description,
          bathroomEstimatedYearOfImprovement:res.bathrooms[i].estimated_year_of_improvement,
          bathroomImprovementDescription:res.bathrooms[i].improvement_description,
          bathroomShowerTubMaterials:res.bathrooms[i].shower_tub_materials  && res.bathrooms[i].shower_tub_materials!==''?res.bathrooms[i].shower_tub_materials.split(', '):[],
          bathroomShowerTubMaterialConditions:res.bathrooms[i].shower_tub_material_conditions  && res.bathrooms[i].shower_tub_material_conditions!==''?res.bathrooms[i].shower_tub_material_conditions.split(', '):[],
          bathroomShowerTubPhotos:extBathroomPhoto[i]?extBathroomPhoto[i].bathroomShowerTubPhotos:[]
        }
      )
    }

    for(let i=0;i<res.garages.length;i++){
      garages.push(
        {
          ID:res.garages[i].ID?res.garages[i].ID:-1,
          name:res.garages[i].name,
          removable:true,
          garageFloorMaterials:res.garages[i].floor_materials  && res.garages[i].floor_materials!==''?res.garages[i].floor_materials.split(', '):[],
          garagePhotos:extGaragePhoto[i]?extGaragePhoto[i].garagePhotos:[],
          garageFloorMaterialPhotos:extGaragePhoto[i]?extGaragePhoto[i].garageFloorMaterialPhotos:[],
          garageFloorMaterialConditions:res.garages[i].floor_material_conditions  && res.garages[i].floor_material_conditions!==''?res.garages[i].floor_material_conditions.split(', '):[],
          garageFloorUpdates:res.garages[i].floor_updates,
          garageFloorUpdateDescription:res.garages[i].floor_update_description,
          garageWallAndCeilingMaterials:res.garages[i].wall_ceiling_materials  && res.garages[i].wall_ceiling_materials!==''?res.garages[i].wall_ceiling_materials.split(', '):[],
          garageWallAndCeilingMaterialPhotos:extGaragePhoto[i]?extGaragePhoto[i].garageWallAndCeilingMaterialPhotos:[],
          garageWallAndCeilingMaterialConditions:res.garages[i].wall_ceiling_material_conditions  && res.garages[i].wall_ceiling_material_conditions!==''?res.garages[i].wall_ceiling_material_conditions.split(', '):[],
          garageWallAndCeilingUpdates:res.garages[i].wall_ceiling_updates,
          garageWallAndCeilingUpdateDescription:res.garages[i].wall_ceiling_update_description,
          garageNumberOfSpaceForCar:res.garages[i].number_of_space_for_car,
          garageBuiltIn:parseInt(res.garages[i].built_in,10)===1?"True":"False",
          garageSqft:res.garages[i].sqft,
        }
      )
    }


    for(let i=0;i<res.gla_calculations.length;i++){
      glaCalculations.push(
        {
          ID:res.gla_calculations[i].ID?res.gla_calculations[i].ID:-1,
          name:res.gla_calculations[i].name,
          length:res.gla_calculations[i].length,
          width:res.gla_calculations[i].width,
          sqft:res.gla_calculations[i].sqft,
        }
      )
    }

    for(let i=0;i<res.heatings.length;i++){
      heatings.push(
        {
          ID:res.heatings[i].ID?res.heatings[i].ID:-1,
          heatingType:res.heatings[i].heating_type,
          heatingOtherDescription:res.heatings[i].heating_other_description,
          heatingUpdates:res.heatings[i].heating_updates,
          heatingUpdateDescription:res.heatings[i].heating_update_description,
          heatingDamagesDefect:parseInt(res.heatings[i].heating_damage_defect,10)===1?'True':'False',
        }
      )
    }

    for(let i=0;i<res.coolings.length;i++){
      coolings.push(
        {
          ID:res.coolings[i].ID?res.coolings[i].ID:-1,
          coolingType:res.coolings[i].cooling_type,
          coolingOtherDescription:res.coolings[i].cooling_other_description,
          coolingUpdates:res.coolings[i].cooling_updates,
          coolingUpdateDescription:res.coolings[i].cooling_update_description,
          coolingDamagesDefect:parseInt(res.coolings[i].cooling_damage_defect,10)===1?'True':'False',
        }
      )
    }


    for(let i=0;i<res.additional_structures.length;i++){
      additionalStructures.push(
        {
          ID:res.additional_structures[i].ID?res.additional_structures[i].ID:-1,
          structureType:res.additional_structures[i].structure_type,
          structureDescription:res.additional_structures[i].structure_description,
          roomType:res.additional_structures[i].room_area_type
        }
      );
    }


    for(let i=0;i<res.amenities.length;i++){
      amenities.push(
        {
          ID:res.amenities[i].ID?res.amenities[i].ID:-1,
          amenityType:res.amenities[i].amenity_type,
          amenitySubTypes:res.amenities[i].amenity_sub_type && res.amenities[i].amenity_sub_type!==''?res.amenities[i].amenity_sub_type.split(', '):[],
          amenityCount:res.amenities[i].amenity_count,
          amenityTypeOtherDescription:res.amenities[i].amenity_description,
        }
      );
    }

    for(let i=0;i<res.utilities.length;i++){
      utilities.push(
        {
          ID:res.utilities[i].ID?res.utilities[i].ID:-1,
          utilityType:res.utilities[i].utility_type,
          utilitySupplier:res.utilities[i].utility_supplier,
          utilitySubType:res.utilities[i].utility_sub_type,
        }
      );
    }


    for(let i=0;i<res.common_amenities.length;i++){
      commonAmenities.push(
        {
          ID:res.common_amenities[i].ID?res.common_amenities[i].ID:-1,
          amenityType:res.common_amenities[i].amenity_type,
          amenitySubTypes:res.common_amenities[i].amenity_sub_type && res.common_amenities[i].amenity_sub_type!==''?res.common_amenities[i].amenity_sub_type.split(', '):[],
          amenityCount:res.common_amenities[i].amenity_count,
          amenityTypeOtherDescription:res.common_amenities[i].amenity_description,
        }
      );
    }
    for(let i=0;i<res.parkings.length;i++){
      parkings.push(
        {
          ID:res.parkings[i].ID?res.parkings[i].ID:-1,
          parkingType:res.parkings[i].parking_type,
          parkingTypeOtherDescription:res.parkings[i].parking_type_other_description,
          parkingNumberOfCar:res.parkings[i].parking_space_count,
          parkingCovered:parseInt(res.parkings[i].parking_cover_indicator,10)===1?"True":"False",
          parkingBuiltIn:parseInt(res.parkings[i].parking_built_in,10)===1?"True":"False",
          parkingSurface:res.parkings[i].parking_surface,
          parkingSqft:res.parkings[i].parking_sqft,
          parkingAttached:parseInt(res.parkings[i].parking_attached_indicator,10)===1?"True":"False",
        }
      );
    }
    if(parseInt(res.max_step,10)<=3){
      if(interiors.length<=0){
        interiors = [
          Object.assign({}, interiorRoom, {name:'Bedroom 1', grade:'', removable:true, floorMaterials:[], roomPhotos:[], floorMaterialPhotos:[], floorMaterialConditions:[], wallAndCeilingMaterials:[], wallAndCeilingMaterialPhotos:[], wallAndCeilingMaterialConditions:[]}),
          Object.assign({}, interiorRoom, {name:'Bedroom 2', grade:'', floorMaterials:[], roomPhotos:[], floorMaterialPhotos:[], floorMaterialConditions:[], wallAndCeilingMaterials:[], wallAndCeilingMaterialPhotos:[], wallAndCeilingMaterialConditions:[]}),
          Object.assign({}, interiorRoom, {name:'Bedroom 3', grade:'', floorMaterials:[], roomPhotos:[], floorMaterialPhotos:[], floorMaterialConditions:[], wallAndCeilingMaterials:[], wallAndCeilingMaterialPhotos:[], wallAndCeilingMaterialConditions:[]}),
          Object.assign({}, interiorRoom, {name:'Dining Room', grade:'', removable:true, roomPhotos:[], floorMaterials:[], floorMaterialPhotos:[], floorMaterialConditions:[], wallAndCeilingMaterials:[], wallAndCeilingMaterialPhotos:[], wallAndCeilingMaterialConditions:[]}),
          Object.assign({}, interiorRoom, {name:'Living Room', grade:'', removable:true, roomPhotos:[], floorMaterials:[], floorMaterialPhotos:[], floorMaterialConditions:[], wallAndCeilingMaterials:[], wallAndCeilingMaterialPhotos:[], wallAndCeilingMaterialConditions:[]}),
        ];
      }

      if(kitchens.length<=0){
        kitchens = [
          Object.assign({}, interiorKitchen, {name:'Kitchen', grade:'', removable:true, kitchenFloorMaterials:[], kitchenPhotos:[], kitchenFloorMaterialPhotos:[], kitchenFloorMaterialConditions:[], kitchenWallAndCeilingMaterials:[], kitchenWallAndCeilingMaterialPhotos:[], kitchenWallAndCeilingMaterialConditions:[], kitchenAppliances:[], kitchenCountertops:[],kitchenApplianceOtherDescription:'', kitchenCabinet:'', kitchenEstimatedYearOfImprovement:'', kitchenImprovementDescription:''}),
        ];
      }

      if(bathrooms.length<=0){
        bathrooms = [
          Object.assign({}, interiorBathroom, {name:'Bathroom', grade:'', removable:true, bathroomFloorMaterials:[], bathroomPhotos:[], bathroomFloorMaterialPhotos:[], bathroomFloorMaterialConditions:[], bathroomWallAndCeilingMaterials:[], bathroomWallAndCeilingMaterialPhotos:[], bathroomWallAndCeilingMaterialConditions:[], bathroomShowerTubMaterials:[], bathroomShowerTubMaterialConditions:[], bathroomShowerTubPhotos:[], bathroomEstimatedYearOfImprovement:'', bathroomImprovementDescription:''}),
        ];
      }

      if(garages.length<=0){
        garages = [
          Object.assign({}, garage, {name:'Garage', grade:'', removable:true, garageFloorMaterials:[], garagePhotos:[], garageFloorMaterialPhotos:[], garageFloorMaterialConditions:[], garageWallAndCeilingMaterials:[], garageWallAndCeilingMaterialPhotos:[], garageWallAndCeilingMaterialConditions:[], garageNumberOfSpaceForCar:'', builtIn:'', sqft:''}),
        ];
      }
    }

    let newState = {
      pdrFk:res.ID,
      status:res.status,
      propertyStreet:res.property_street,
      propertyCity:res.property_city,
      propertyCounty:res.property_county,
      propertyState:res.property_state,
      propertyZip:res.property_zip,
      propertyType:res.property_type,
      condoType:res.condo_type,
      condoOtherDescription:res.condo_type_other_description,
      occupancyType:res.occupancy_type,
      hoa:res.hoa,
      hoaFee:res.hoa_fee,
      yearsOwned:res.years_owned,
      neighborhoodType:res.property_location_type,
      propertyLat:res.property_lat,
      propertyLng:res.property_lng,

      lpaKey:res.lpa_key,
      sellerID:res.seller_id,
      fileNumber:res.file_num,
      additionalFileNumber:res.additional_file_num,
      borrowerName:res.borrower_name,
      closeBy:res.client_fk,
      loanPurpose:res.loan_purpose,
      sellingPrice:res.selling_price,
      dateOfContract:res.datetime_contract,
      assignmentType:res.assignment_type,
      pdrType:res.pdr_type,
      dataSetVersion:res.data_set_version,


      constructionStatus:res.construction_status,
      requiredRepairs:res.required_repairs,
      requiredInspections:res.required_inspections && res.required_inspections!==''?res.required_inspections.split(', '):[],
      accessoryUnit:parseInt(res.accessory_unit,10)===1?'True':'False',
      storiesNumber:res.story_count,
      projectStoriesNumber:res.story_project_count,
      unitStoriesNumber:res.story_unit_count,
      elevatorsNumber:res.elevator_count,
      floorNumber:res.floor_count,
      attachmentType:parseInt(res.attachment_type,10)===1?'True':'False',
      attachmentTypeEndUnit:parseInt(res.attachment_type_end_unit,10)===1?'True':'False',
      buildingCompletion:res.building_completion_status,
      sumpPump:parseInt(res.sump_pump_indicator,10)===1?'True':'False',
      notLivableIndicator:parseInt(res.not_livable_indicator,10)===1?'True':'False',
      notLivableComment:res.not_livable_comment,
      defectIndicator:parseInt(res.defect_indicator,10)===1?'True':'False',
      alteredIndicator:res.altered_indicator && res.altered_indicator!==''?res.altered_indicator.split(', '):[],
      upToStandard:parseInt(res.up_to_standard,10)===1?'True':'False',
      allSeasonAccessToSystem:parseInt(res.all_season_access_system,10)===1?'True':'False',
      structuralDamage:parseInt(res.structural_damage,10)===1?'True':'False',

      personAtProperty:res.person_at_property,
      dataCollectorName:res.property_data_collector_name,
      dataCollectorType:res.property_data_collector_type,
      collectionType:res.collection_type,
      dataCollectorCompany:res.property_data_collector_company_name,
      dataCollectorCompanyStreet:res.pdc_company_street,
      dataCollectorCompanyCity:res.pdc_company_city,
      dataCollectorCompanyState:res.pdc_company_state,
      dataCollectorCompanyZip:res.pdc_company_zip,
      dataCollectorContactMethod:res.property_data_collector_contact_method,
      dataCollectorContact:res.property_data_collector_contact,
      dateOfSignatureReport:res.datetime_pdc_signed,
      dateOfObservation:res.datetime_observed,
      professionalLicenseType:res.professional_license_type,
      professionalLicenseNumber:res.professional_license_num,
      professionalLicenseState:res.professional_license_state,
      professionalLicenseExpDate:res.datetime_license_expiration,
      signature:Object.assign({}, state.signature),
      supervisorName:res.supervisor_name,
      supervisorContactMethod:res.supervisor_contact_method,
      supervisorContact:res.supervisor_contact,
      supervisorDateOfSignature:res.datetime_supervisor_signed,
      supervisorLicenseNumber:res.supervisor_license_num,
      supervisorState:res.supervisor_license_state,
      supervisorLicenseExpDate:res.datetime_supervisor_license_expiration,
      observeBothInAndExt:parseInt(res.personally_observed,10)===1?'True':'False',
      supervisorSignature:Object.assign({}, state.supervisorSignature),

      photos:state.photos.slice(),
      renewableEnergyComponentPhotos:state.renewableEnergyComponentPhotos.slice(),
      frontViewPropertyPhotos:state.frontViewPropertyPhotos.slice(),
      rearViewPropertyPhotos:state.rearViewPropertyPhotos.slice(),
      leftSideViewPropertyPhotos:state.leftSideViewPropertyPhotos.slice(),
      rightSideViewPropertyPhotos:state.rightSideViewPropertyPhotos.slice(),
      leftStreetViewPropertyPhotos:state.leftStreetViewPropertyPhotos.slice(),
      rightStreetViewPropertyPhotos:state.rightStreetViewPropertyPhotos.slice(),
      floorPlan:state.floorPlan.slice(),
      foundationWallPhotos:state.foundationWallPhotos.slice(),
      windowPhotos:state.windowPhotos.slice(),
      exteriorWallPhotos:state.exteriorWallPhotos.slice(),
      gutterAndDownspoutPhotos:state.gutterAndDownspoutPhotos.slice(),
      roofPhotos:state.roofPhotos.slice(),
      coolingPhotos:state.coolingPhotos.slice(),
      heatingPhotos:state.heatingPhotos.slice(),

      interiors:interiors,
      kitchens:kitchens,

      garages:garages,
      bathrooms:bathrooms,
      parkings:parkings,
      heatings:heatings,
      coolings:coolings,
      amenities:amenities,
      commonAmenities:commonAmenities,
      utilities:utilities,
      glaCalculations:glaCalculations,

      attic:parseInt(res.attic,10)===1?'True':'False',
      atticFeatures:res.attic_features && res.attic_features!==''?res.attic_features.split(', '):[],
      atticFeatureDescription:res.attic_feature_description,

      washerDryerHookup:parseInt(res.washer_dryer_hookup,10)===1?'True':'False',
      foundationTypes:res.foundation_types && res.foundation_types!==''?res.foundation_types.split(', '):[],
      evidenceOfCondition:parseInt(res.evidence_condition,10)===1?'True':'False',
      evidenceOfConditionDescription:res.evidence_condition_description,
      foundationWallMaterials:res.foundation_wall_materials && res.foundation_wall_materials!==''?res.foundation_wall_materials.split(', '):[],
      foundationWallMaterialDescription:res.foundation_wall_material_description,
      foundationWallConditions:res.foundation_wall_conditions && res.foundation_wall_conditions!==''?res.foundation_wall_conditions.split(', '):[],
      exteriorWallMaterials:res.exterior_wall_materials && res.exterior_wall_materials!==''?res.exterior_wall_materials.split(', '):[],
      exteriorWallMaterialDescription:res.exterior_wall_material_description,
      exteriorWallConditions:res.exterior_wall_conditions && res.exterior_wall_conditions!==''?res.exterior_wall_conditions.split(', '):[],
      roofMaterials:res.roof_surface_materials && res.roof_surface_materials!==''?res.roof_surface_materials.split(', '):[],
      roofMaterialDescription:res.roof_surface_material_description,
      roofConditions:res.roof_surface_conditions && res.roof_surface_conditions!==''?res.roof_surface_conditions.split(', '):[],
      roofAge:res.roof_age,
      gutterAndDownspoutMaterials:res.gutter_downspouts_materials && res.gutter_downspouts_materials!==''?res.gutter_downspouts_materials.split(', '):[],
      gutterOtherDescription:res.gutter_downspouts_other_material_description,
      gutterAndDownspoutConditions:res.gutter_downspouts_conditions && res.gutter_downspouts_conditions!==''?res.gutter_downspouts_conditions.split(', '):[],
      windowMaterials:res.window_materials && res.window_materials!==''?res.window_materials.split(', '):[],
      windowConditions:res.window_material_conditions && res.window_material_conditions!==''?res.window_material_conditions.split(', '):[],
      windowUpdates:res.window_updates,
      windowUpdateDescription:res.window_update_description,
      stormSash:parseInt(res.storm_sash,10)===1?'True':'False',
      screens:parseInt(res.screens,10)===1?'True':'False',
      viewTypes:res.view_types && res.view_types!==''?res.view_types.split(', '):[],
      viewDescription:res.view_description,
      streetAlleyTypes:res.street_alley_types && res.street_alley_types!==''?res.street_alley_types.split(', '):[],
      streetAlleyMaterials:res.street_materials && res.street_materials!==''?res.street_materials.split(', '):[],
      streetAlleyOwnership:res.street_ownership,
      siteConditions:res.site_conditions && res.site_conditions!==''?res.site_conditions.split(', '):[],
      siteConditionDescription:res.site_condition_description,
      adjoinExternalFactors:res.adjoin_external_factors && res.adjoin_external_factors!==''?res.adjoin_external_factors.split(', '):[],
      adjoinExternalFactorDescription:res.adjoin_external_factor_description,
      sqftBelowGrade:res.below_grade_sqft,
      belowGradeAccess:res.below_grade_access && res.below_grade_access!==''?res.below_grade_access.split(', '):[],
      percentBelowGrade:res.below_grade_p_finished,
      recreationBelowGrade:res.below_grade_recreation_room_count,
      bedroomBelowGrade:res.below_grade_bedroom_count,
      bathroomBelowGrade:res.below_grade_bathroom_count,
      halfbathBelowGrade:res.below_grade_halfbath_count,
      roomBelowGrade:res.below_grade_room_count,
      aboveGradeRoomCount:res.above_grade_room_count,
      aboveGradeBedroomCount:res.above_grade_bedroom_count	,
      aboveGradeBathroomCount:res.above_grade_bathroom_count,
      aboveGradeHalfBathCount:res.above_grade_halfbath_count,
      aboveGradeGrossLivingArea:res.above_grade_area_sqft,
      structureType:res.structure_type,
      additionalStructures:additionalStructures,
      primaryDwellingAreaType:'',
      primaryDwellingGarageBuiltIn:'',
      primaryDwellingAreaDescription:'',
      aduAreaType:'',
      aduAreaDescription:'',
      aduGarageBuiltIn:'',
      structureDescription:'',
      energyRatingPerformed:parseInt(res.energy_rating_performed,10)===1?'True':'False',
      energyRatingPerformedPhotos:state.energyRatingPerformedPhotos.slice(),
      energyRatingOrganization:res.energy_rating_organization,
      energyRatingDescription:res.energy_rating_description,
      renewableEnergyPresent:parseInt(res.renewable_energy_component_present,10)===1?'True':'False',
      renewableEnergyComponent:res.renewable_energy_component,
      renewableEnergyComponentDescription:res.renewable_energy_description,
      solarPanelOwnership:res.renewable_energy_solar_panel_ownership,
      carStorageIndicator:parseInt(res.car_storage_indicator,10)===1?'True':'False',
      numberOfParking:'',
      padNumberOfParking:'',
      garageNumberOfCar:'',
      drivewayNumberOfParking:'',
      carPortNumberOfParking:'',
      padCovered:'',
      drivewayCovered:'',
      carPortCovered:'',
      garageCovered:'',
      garageBuiltIn:'',
      padAttached:'',
      drivewayAttached:'',
      carPortAttached:'',
      garageAttached:'',
      padSurface:'',
      drivewaySurface:'',
      carPortSurface:'',
      garageSurface:'',
      garageSqft:'',
      guestParking:parseInt(res.guest_parking,10)===1?'True':'False',
      guestParkingNumber:res.guest_parking_count,
      guestParkingType:res.guest_parking_type,
    };

    setState(newState);
    console.log('loading state updated');
    interiorsRef.current = interiors;
    bathroomsRef.current = bathrooms;
    kitchensRef.current = kitchens;
    garagesRef.current = garages;
    uploadPhotos();
  }

  const uploadPhotos = async () => {
    let messageID = -1;
    let photos = state.photos.slice();
    const hasPendingPhoto = state.photos.filter((photo) => !photo.ID || photo.ID === -1);

    if(hasPendingPhoto.length>0)
      messageID = showMessage('non-dismissable','Uploading photos, please do not close your browser.');

    const updatePhotoState = async (photos, setState, typefK=-1) => {
      let callCounter = 0;
      for (let i=0;i<photos.length; i++) {
        if (!photos[i].ID || photos[i].ID === -1) {

          if(typefK!==-1){
            photos[i].typeFk = typefK;
          }

          // eslint-disable-next-line no-loop-func
          const updateCallBack = (ID) => {
            callCounter++;
            photos[i].ID = ID;
            if (callCounter >= photos.length) {
              setState([...photos]);
            }
          };
          await uploadPhoto(photos[i], updateCallBack);
        }
      }
    };

    console.log(hasPendingPhoto);

    // Call updatePhotoState for each photo type
    await Promise.all([
      updatePhotoState(photos, (v)=>setState({photos:v})),
      updatePhotoState(state.renewableEnergyComponentPhotos.slice(), (v)=>setState({renewableEnergyComponentPhotos:v})),
      updatePhotoState(state.coolingPhotos.slice(), (v)=>setState({coolingPhotos:v})),
      updatePhotoState(state.heatingPhotos.slice(), (v)=>setState({heatingPhotos:v})),
      updatePhotoState(state.foundationWallPhotos.slice(), (v)=>setState({foundationWallPhotos:v})),
      updatePhotoState(state.exteriorWallPhotos.slice(), (v)=>setState({exteriorWallPhotos:v})),
      updatePhotoState(state.roofPhotos.slice(), (v)=>setState({roofPhotos:v})),
      updatePhotoState(state.windowPhotos.slice(), (v)=>setState({windowPhotos:v})),
      updatePhotoState(state.gutterAndDownspoutPhotos.slice(), (v)=>setState({gutterAndDownspoutPhotos:v})),
      updatePhotoState(state.energyRatingPerformedPhotos.slice(), (v)=>setState({energyRatingPerformedPhotos:v})),
      updatePhotoState(state.floorPlan.slice(), (v)=>setState({floorPlan:v})),
      updatePhotoState(state.frontViewPropertyPhotos.slice(), (v)=>setState({frontViewPropertyPhotos:v})),
      updatePhotoState(state.rearViewPropertyPhotos.slice(), (v)=>setState({rearViewPropertyPhotos:v})),
      updatePhotoState(state.leftSideViewPropertyPhotos.slice(), (v)=>setState({leftSideViewPropertyPhotos:v})),
      updatePhotoState(state.rightSideViewPropertyPhotos.slice(), (v)=>setState({rightSideViewPropertyPhotos:v})),
      updatePhotoState(state.leftStreetViewPropertyPhotos.slice(), (v)=>setState({leftStreetViewPropertyPhotos:v})),
      updatePhotoState(state.rightStreetViewPropertyPhotos.slice(), (v)=>setState({rightStreetViewPropertyPhotos:v})),
    ]);

    //Interior
    let interiors = state.interiors.slice();
    for(let i=0;i<interiors.length;i++){
      let roomPhotos = state.interiors[i].roomPhotos.slice();
      await updatePhotoState(roomPhotos, (photos) => {
        interiors[i].roomPhotos = photos;
        setState((prevState) => ({
          ...prevState,
          interiors: [...interiors],
        }));
      }, interiors[i].ID);


      let floorMaterialPhotos = state.interiors[i].floorMaterialPhotos.slice();
      await updatePhotoState(floorMaterialPhotos, (photos) => {
        interiors[i].floorMaterialPhotos = photos;
        setState((prevState) => ({
          ...prevState,
          interiors: [...interiors],
        }));
      }, interiors[i].ID);


      let wallAndCeilingMaterialPhotos = state.interiors[i].wallAndCeilingMaterialPhotos.slice();
      await updatePhotoState(wallAndCeilingMaterialPhotos, (photos) => {
        interiors[i].wallAndCeilingMaterialPhotos = photos;
        setState((prevState) => ({
          ...prevState,
          interiors: [...interiors],
        }));
      }, interiors[i].ID);
    }

    //Garage
    let garages = state.garages.slice();
    for(let i=0;i<garages.length;i++){
      let garagePhotos = state.garages[i].garagePhotos.slice();
      await updatePhotoState(garagePhotos, (photos) => {
        garages[i].garagePhotos = photos;
        setState((prevState) => ({
          ...prevState,
          garages: [...garages],
        }));
      }, garages[i].ID);


      let garageFloorMaterialPhotos = state.garages[i].garageFloorMaterialPhotos.slice();
      await updatePhotoState(garageFloorMaterialPhotos, (photos) => {
        garages[i].garageFloorMaterialPhotos = photos;
        setState((prevState) => ({
          ...prevState,
          garages: [...garages],
        }));
      }, garages[i].ID);


      let garageWallAndCeilingMaterialPhotos = state.garages[i].garageWallAndCeilingMaterialPhotos.slice();
      await updatePhotoState(garageWallAndCeilingMaterialPhotos, (photos) => {
        garages[i].garageWallAndCeilingMaterialPhotos = photos;
        setState((prevState) => ({
          ...prevState,
          garages: [...garages],
        }));
      }, garages[i].ID);
    }

    //Bathroom
    let bathrooms = state.bathrooms.slice();
    for(let i=0;i<bathrooms.length;i++){
      let bathroomPhotos = state.bathrooms[i].bathroomPhotos.slice();
      await updatePhotoState(bathroomPhotos, (photos) => {
        bathrooms[i].bathroomPhotos = photos;
        setState((prevState) => ({
          ...prevState,
          bathrooms: [...bathrooms],
        }));
      }, bathrooms[i].ID);


      let bathroomFloorMaterialPhotos = state.bathrooms[i].bathroomFloorMaterialPhotos.slice();
      await updatePhotoState(bathroomFloorMaterialPhotos, (photos) => {
        bathrooms[i].bathroomFloorMaterialPhotos = photos;
        setState((prevState) => ({
          ...prevState,
          bathrooms: [...bathrooms],
        }));
      }, bathrooms[i].ID);


      let bathroomWallAndCeilingMaterialPhotos = state.bathrooms[i].bathroomWallAndCeilingMaterialPhotos.slice();
      await updatePhotoState(bathroomWallAndCeilingMaterialPhotos, (photos) => {
        bathrooms[i].bathroomWallAndCeilingMaterialPhotos = photos;
        setState((prevState) => ({
          ...prevState,
          bathrooms: [...bathrooms],
        }));
      }, bathrooms[i].ID);


      let bathroomShowerTubPhotos = state.bathrooms[i].bathroomShowerTubPhotos.slice();
      await updatePhotoState(bathroomShowerTubPhotos, (photos) => {
        bathrooms[i].bathroomShowerTubPhotos = photos;
        setState((prevState) => ({
          ...prevState,
          bathrooms: [...bathrooms],
        }));
      }, bathrooms[i].ID);
    }

    //Kitchen
    let kitchens = state.kitchens.slice();
    for(let i=0;i<kitchens.length;i++){
      let kitchenPhotos = state.kitchens[i].kitchenPhotos.slice();
      await updatePhotoState(kitchenPhotos, (photos) => {
        kitchens[i].kitchenPhotos = photos;
        setState((prevState) => ({
          ...prevState,
          kitchens: [...kitchens],
        }));
      }, kitchens[i].ID);

      let kitchenFloorMaterialPhotos = state.kitchens[i].kitchenFloorMaterialPhotos.slice();
      await updatePhotoState(kitchenFloorMaterialPhotos, (photos) => {
        kitchens[i].kitchenFloorMaterialPhotos = photos;
        setState((prevState) => ({
          ...prevState,
          kitchens: [...kitchens],
        }));
      }, kitchens[i].ID);

      let kitchenWallAndCeilingMaterialPhotos = state.kitchens[i].kitchenWallAndCeilingMaterialPhotos.slice();
      await updatePhotoState(kitchenWallAndCeilingMaterialPhotos, (photos) => {
        kitchens[i].kitchenWallAndCeilingMaterialPhotos = photos;
        setState((prevState) => ({
          ...prevState,
          kitchens: [...kitchens],
        }));
      }, kitchens[i].ID);
    }

    //Signature
    let signature = Object.assign({}, state.signature);
    if((signature.upload && signature.upload!=='')||(signature.draw && signature.draw!=='')){
      let base64;
      if(signature.draw && signature.draw!=='')
        base64 = signature.draw;
      else
        base64 = signature.upload;

      let tmp = {
        type:'Signature',
        typeFk:0,
        base64:base64,
        name:'signature',
        uploaderName:state.dataCollectorName
      };

      let updateCallBack = (ID)=>{
        // no need to update
        // signature need to be sign everytime a form was submitted
      }
      await uploadPhoto(tmp, updateCallBack);
    }


    //Supervisor Signature
    let supervisorSignature = Object.assign({}, state.supervisorSignature);

    if((supervisorSignature.upload && supervisorSignature.upload!=='')||(supervisorSignature.draw && supervisorSignature.draw!=='')){
      let base64;
      if(supervisorSignature.draw && supervisorSignature.draw!=='')
        base64 = supervisorSignature.draw;
      else
        base64 = supervisorSignature.upload;

      let tmp = {
        type:'Supervisor Signature',
        typeFk:0,
        base64:base64,
        name:'supervisor signature'
      };

      let updateCallBack = (ID)=>{
        // no need to update
        // signature need to be sign everytime a form was submitted
      }
      await uploadPhoto(tmp, updateCallBack);
    }

    if(messageID!==-1)
      hideMessage(messageID);
  }

  const cancelPDR = () => {
    let parameters = [
      {
        field:'pdrFk',
        value:state.pdrFk
      },
      {
        field:'status',
        value:'Cancelled'
      }
    ];

    let callBack = apiCallBack([{state:'status', value:'Cancelled'}]);
    httpPut('propertyDataReport/status/update',parameters,'','Oops, something went wrong on uploading photo and could not save the report. Please try again later.', callBack);
  }

  const uploadPhoto = (photo, updateCallBack) => {
    let parameters = [
      {
        field:'pdrFk',
        value:state.pdrFk
      },
      {
        field:'typeFk',
        value:photo.typeFk
      },
      {
        field:'type',
        value:photo.type
      },
      {
        field:'description',
        value:photo.description
      },
      {
        field:'name',
        value:photo.name
      },
      {
        field:'size',
        value:photo.size
      },
      {
        field:'uploadedFile',
        value:photo.base64
      },
      {
        field:'uploaderName',
        value:state.name
      }
    ];

    let messageID = showMessage('non-dismissable','Uploading '+photo.name+', please do not close your browser.');

    let callBack = (response)=>{
      let code= response.data.code;

      hideMessage(messageID);
      if(code==='00'){
        updateCallBack(response.data.data.ID);
      }
    };
    callBack = callBack.bind(this);

    let errorCallBack = apiCallBack([], hideMessage(messageID));
    return httpPost('propertyDataReport/photo/upload', parameters, '', 'Oops, something went wrong on uploading photo and could not save the report. Please try again later.', callBack,errorCallBack);
  }

  const submitPDRRequest = () => {
    let parameters = [
      {
        field:'pdrFk',
        value:state.pdrFk
      }
    ];

    let callBack = apiCallBack([{state:'showSubmittedPrompt', value:true}], showMessage('success','Request submitted successfully, you will receive an email when the PDF report is ready.'));
    return httpPost('propertyDataReport/create/request', parameters, '', 'Oops, something went wrong and request could not be process successfully. Please try again later.', callBack);
  }


  //render
  let propertyInfo = {
    pdrFk:state.pdrFk,
    propertyStreet:state.propertyStreet,
    propertyCity:state.propertyCity,
    propertyCounty:state.propertyCounty,
    propertyState:state.propertyState,
    propertyZip:state.propertyZip,
    propertyType:state.propertyType,
    condoType:state.condoType,
    condoOtherDescription:state.condoOtherDescription,
    hoa:state.hoa,
    hoaFee:state.hoaFee,
    yearsOwned:state.yearsOwned,
    neighborhoodType:state.neighborhoodType
  };

  let loanInfo = {
    pdrFk:state.pdrFk,
    sellerID:state.sellerID,
    lpaKey:state.lpaKey,
    fileNumber:state.fileNumber,
    additionalFileNumber:state.additionalFileNumber,
    borrowerName:state.borrowerName,
    closeBy:state.closeBy,
    loanPurpose:state.loanPurpose,
    sellingPrice:state.sellingPrice,
    dateOfContract:state.dateOfContract,
    assignmentType:state.assignmentType,
    pdrType:state.pdrType,
    dataSetVersion:state.dataSetVersion,
  };

  let inspection = {
    pdrFk:state.pdrFk,
    constructionStatus:state.constructionStatus,
    requiredRepairs:state.requiredRepairs,
    requiredInspections:state.requiredInspections,
    accessoryUnit:state.accessoryUnit,
    storiesNumber:state.storiesNumber,
    projectStoriesNumber:state.projectStoriesNumber,
    unitStoriesNumber:state.unitStoriesNumber,
    elevatorsNumber:state.elevatorsNumber,
    floorNumber:state.floorNumber,
    attachmentType:state.attachmentType,
    attachmentTypeEndUnit:state.attachmentTypeEndUnit,
    buildingCompletion:state.buildingCompletion,
    sumpPump:state.sumpPump,
    notLivableIndicator:state.notLivableIndicator,
    notLivableComment:state.notLivableComment,
    defectIndicator:state.defectIndicator,
    alteredIndicator:state.alteredIndicator,
    upToStandard:state.upToStandard,
    allSeasonAccessToSystem:state.allSeasonAccessToSystem,
    structuralDamage:state.structuralDamage,
    structureType:state.structureType,
    foundationTypes:state.foundationTypes,
  }

  let certification = {
    pdrFk:state.pdrFk,
    personAtProperty:state.personAtProperty,
    dataCollectorName:state.dataCollectorName,
    dataCollectorType:state.dataCollectorType,
    collectionType:state.collectionType,
    dataCollectorCompany:state.dataCollectorCompany,
    dataCollectorCompanyStreet:state.dataCollectorCompanyStreet,
    dataCollectorCompanyCity:state.dataCollectorCompanyCity,
    dataCollectorCompanyState:state.dataCollectorCompanyState,
    dataCollectorCompanyZip:state.dataCollectorCompanyZip,
    dataCollectorContact:state.dataCollectorContact,
    dataCollectorContactMethod:state.dataCollectorContactMethod,
    dateOfSignatureReport:state.dateOfSignatureReport,
    dateOfObservation:state.dateOfObservation,
    professionalLicenseType:state.professionalLicenseType,
    professionalLicenseNumber:state.professionalLicenseNumber,
    professionalLicenseState:state.professionalLicenseState,
    professionalLicenseExpDate:state.professionalLicenseExpDate,
    signature:state.signature,
    supervisorName:state.supervisorName,
    supervisorContact:state.supervisorContact,
    supervisorContactMethod:state.supervisorContactMethod,
    supervisorDateOfSignature:state.supervisorDateOfSignature,
    supervisorLicenseNumber:state.supervisorLicenseNumber,
    supervisorState:state.supervisorState,
    supervisorLicenseExpDate:state.supervisorLicenseExpDate,
    observeBothInAndExt:state.observeBothInAndExt,
    supervisorSignature:state.supervisorSignature,
    dataCollectorCertified:state.dataCollectorCertified,
    supervisorCertified:state.supervisorCertified,
  };

  let site = {
    pdrFk:state.pdrFk,
    interiors:state.interiors,
    kitchens:state.kitchens,
    bathrooms:state.bathrooms,
    garages:state.garages,


    occupancyType:state.occupancyType,

    attic:state.attic,
    atticFeatures:state.atticFeatures,
    atticFeatureDescription:state.atticFeatureDescription,

    additionalStructures:state.additionalStructures,
    amenities:state.amenities,
    commonAmenities:state.commonAmenities,
    utilities:state.utilities,
    parkings:state.parkings,
    heatings:state.heatings,
    heatingPhotos:state.heatingPhotos,
    coolings:state.coolings,
    coolingPhotos:state.coolingPhotos,
    washerDryerHookup:state.washerDryerHookup,
    siteStructureAmenities:state.siteStructureAmenities,
    siteStructureAmenityCount:state.siteStructureAmenityCount,
    siteStructureAmenityOtherDescription:state.siteStructureAmenityOtherDescription,
    foundationTypes:state.foundationTypes,
    evidenceOfCondition:state.evidenceOfCondition,
    evidenceOfConditionDescription:state.evidenceOfConditionDescription,
    foundationWallMaterials:state.foundationWallMaterials,
    foundationWallMaterialDescription:state.foundationWallMaterialDescription,
    foundationWallConditions:state.foundationWallConditions,
    foundationWallPhotos:state.foundationWallPhotos,
    exteriorWallMaterials:state.exteriorWallMaterials,
    exteriorWallMaterialDescription:state.exteriorWallMaterialDescription,
    exteriorWallConditions:state.exteriorWallConditions,
    exteriorWallPhotos:state.exteriorWallPhotos,
    renewableEnergyComponentPhotos:state.renewableEnergyComponentPhotos,
    roofMaterials:state.roofMaterials,
    roofMaterialDescription:state.roofMaterialDescription,
    roofConditions:state.roofConditions,
    roofPhotos:state.roofPhotos,
    roofAge:state.roofAge,

    gutterAndDownspoutMaterials:state.gutterAndDownspoutMaterials,
    gutterOtherDescription:state.gutterOtherDescription,
    gutterAndDownspoutConditions:state.gutterAndDownspoutConditions,
    gutterAndDownspoutPhotos:state.gutterAndDownspoutPhotos,

    windowMaterials:state.windowMaterials,
    windowConditions:state.windowConditions,
    windowPhotos:state.windowPhotos,
    windowUpdates:state.windowUpdates,
    windowUpdateDescription:state.windowUpdateDescription,
    stormSash:state.stormSash,
    screens:state.screens,
    viewTypes:state.viewTypes,
    viewDescription:state.viewDescription,

    streetAlleyTypes:state.streetAlleyTypes,
    streetAlleyMaterials:state.streetAlleyMaterials,
    streetAlleyOwnership:state.streetAlleyOwnership,
    siteConditions:state.siteConditions,
    siteConditionDescription:state.siteConditionDescription,
    siteCommonAmenities:state.siteCommonAmenities,
    siteCommonAmenityCount:state.siteCommonAmenityCount,
    siteCommonAmenityDescription:state.siteCommonAmenityDescription,
    adjoinExternalFactors:state.adjoinExternalFactors,
    adjoinExternalFactorDescription:state.adjoinExternalFactorDescription,
    sqftBelowGrade:state.sqftBelowGrade,
    belowGradeAccess:state.belowGradeAccess,
    percentBelowGrade:state.percentBelowGrade,
    recreationBelowGrade:state.recreationBelowGrade,
    bedroomBelowGrade:state.bedroomBelowGrade,
    bathroomBelowGrade:state.bathroomBelowGrade,
    halfbathBelowGrade:state.halfbathBelowGrade,
    roomBelowGrade:state.roomBelowGrade,
    aboveGradeRoomCount:state.aboveGradeRoomCount,
    aboveGradeBedroomCount:state.aboveGradeBedroomCount,
    aboveGradeBathroomCount:state.aboveGradeBathroomCount,
    aboveGradeHalfBathCount:state.aboveGradeHalfBathCount,
    aboveGradeGrossLivingArea:state.aboveGradeGrossLivingArea,
    structureType:state.structureType,
    structureDescription:state.structureDescription,
    energyRatingPerformed:state.energyRatingPerformed,
    energyRatingPerformedPhotos:state.energyRatingPerformedPhotos,
    energyRatingOrganization:state.energyRatingOrganization,
    energyRatingDescription:state.energyRatingDescription,
    renewableEnergyPresent:state.renewableEnergyPresent,
    renewableEnergyComponent:state.renewableEnergyComponent,
    renewableEnergyComponentDescription:state.renewableEnergyComponentDescription,
    solarPanelOwnership:state.solarPanelOwnership,
    carStorageIndicator:state.carStorageIndicator,
    onSiteParkingTypes:state.onSiteParkingTypes,
    guestParking:state.guestParking,
    guestParkingNumber:state.guestParkingNumber,
    guestParkingType:state.guestParkingType,
  };

  let photos = {
    pdrFk:state.pdrFk,
    photos:state.photos,
    frontViewPropertyPhotos:state.frontViewPropertyPhotos,
    rearViewPropertyPhotos:state.rearViewPropertyPhotos,
    leftSideViewPropertyPhotos:state.leftSideViewPropertyPhotos,
    rightSideViewPropertyPhotos:state.rightSideViewPropertyPhotos,
    leftStreetViewPropertyPhotos:state.leftStreetViewPropertyPhotos,
    rightStreetViewPropertyPhotos:state.rightStreetViewPropertyPhotos,
    floorPlan:state.floorPlan,
    glaCalculations:state.glaCalculations,
    interiors:state.interiors,
    bathrooms:state.bathrooms,
    garages:state.garages,
    kitchens:state.kitchens
  };

  let currentStep = <StepPropertyInformation updateState={updateState} {...props} {...propertyInfo}/>;

  switch(state.currentStep){
    case 0:
      currentStep = <StepPropertyInformation updateState={updateState} {...props} {...propertyInfo}/>;
      break;
    case 1:
      currentStep = <StepLoanInformation updateState={updateState} {...props} {...loanInfo}/>;
      break;
    case 2:
      currentStep = <StepSite addNewRoom={addNewRoom} removeRoom={removeRoom} addNewItem={addNewItem} removeItem={removeItem} updateState={updateState} {...props} {...site}/>;
      break;
    case 3:
      currentStep = <StepInspection updateState={updateState} {...props} {...inspection} propertyType={state.propertyType}/>;
      break;
    case 4:
      currentStep = <StepPhotos updateState={updateState} {...props} {...photos}/>;
      break;
    case 5:
      currentStep = <StepDataCollectorCertification updateState={updateState} {...props} {...certification}/>;
      break;
    case 6:
      currentStep = <StepReview updateStep={updateStep} updateState={updateState} {...props} {...propertyInfo} {...loanInfo} {...site} {...inspection} {...certification} {...photos}/>;
      break;
    default:
      currentStep = <StepPropertyInformation updateState={updateState} {...props}/>;
      break;
  }

  let button;

  if(state.currentStep!==7){
    button = <Button color="warning">{state.currentStep<6 ?"Next":"Submit & Generate PDF"}</Button>;
  }

  let cancelLink;

  if(state.status!=='Cancelled'){
    cancelLink = <NavLink to="#" onClick={(e)=>{
      confirmation(
        ()=>{cancelPDR()},
        ()=>{},
        'Cancel PDR?',
        'Are you sure you want to cancel this PDR?');
    }}><i className="fa fa-times red-color"/> Cancel PDR</NavLink>;
  }

  return <div ref={parentElementRef}>
    <div>
      <Row>
        <Col sm="6">
          <NavLink to="/property-data-report">Back to PDR Pipeline</NavLink>
        </Col>
        <Col sm="6" className="align-right">
          <NavLink to="#" onClick={saveProgress}><i className="fa fa-save green-color"/> Save Information</NavLink>&nbsp;&nbsp;
          {cancelLink}
        </Col>
      </Row>
    </div>

    <Modal className="my-modal" isOpen={state.showSubmittedPrompt} toggle={toggleShowPrompt} >
      <ModalHeader hidden={true} toggle={toggleShowPrompt}></ModalHeader>
      <ModalBody>

        <center>
          <h5><i className="fa fa-check"></i> PDR Submitted Successfully</h5>
          <br/>
          Your PDR Report had been submitted for process successfully. It will be automatically delivered to System X, if you need a copy for your record it will be available in your profile pipeline.<br/><br/>
          You may click <NavLink to="/property-data-report">here</NavLink> to return to the PDR Pipeline.
          Once the report is ready you will receive an email to access the report.<br/><br/>
        </center>
      </ModalBody>
    </Modal>
    <Nav tabs>
      <NavItem>
        <NavLink className="nav-link active" to="#">ACE+ PDR (Property Data Report)</NavLink>
      </NavItem>
      <NavItem>
        <NavLink className="nav-link" to={"/post-completion-report/"+state.pdrFk}>PCR (Post Completion Report)</NavLink>
      </NavItem>
      <NavItem>
        <NavLink className="nav-link" to={"/property-data-report/file/"+state.pdrFk}>Generated Report</NavLink>
      </NavItem>
      <NavItem>
        <a className="nav-link" href="https://www.smartdraw.com/" target="_blank" rel="noopener noreferrer">Floor Plan - Smart Draw</a>
      </NavItem>
      <NavItem>
        <a className="nav-link" href="https://www.cubi.casa/" target="_blank" rel="noopener noreferrer">Floor Plan - Cubi Casa</a>
      </NavItem>
    </Nav>
    <MyFormStep steps = {state.steps} currentStep = {state.currentStep} updateStep = {updateStep} maxStep={state.maxStep}/>

    <form onSubmit={handleStepAdvance}>
      <br/>
      {currentStep}
      <br/>
      <div className="align-right">
        {button}
      </div>
    </form>
  </div>;
}


export default PropertyDataReport;
