//Sooyoung Kim
//June 13, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator} from '../../../util/util';
import {Button, Card, CardHeader, CardBody, Row, Col} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import ReportFilter from '../report-filter';

//initialize the state
const initialState = {
  reportDetails:[],
  subActiveTab:'1',

  retail:[],
  wholesale:[],
  keyword:'',

  orderType:'',
  client:'',
  openBy:'',
  clients:[],
  clientContactID:[],
  clientContactList:[],
};

//reducer function that perform state update
const reducer = getReducer();


const ClientContactReport  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getUnfoundContact();
    
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const selectThisClientContacts = (entry)=>{
    let newArrayID = state.clientContactID;
    let index = newArrayID.indexOf(entry.ID);
    if (index > -1) {
      newArrayID.splice(index, 1);
    }else{
      newArrayID.push(entry.ID)
    }
  
    setState({clientContactID:newArrayID});
  }

  const handleLoanChange = (thisType)=>{
    console.log("loanchange");
    setState({orderType:thisType});
    getUnfoundContact();
  }

  //API call
  const getUnfoundContact = ()=>{
    let closeBy = state.client;
    if (state.orderType==='Retail'){
      closeBy = 0;
    }

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
      if(state.orderType === 'Wholesale'){
        setState({wholesale:response.data.data});
      }else{
        setState({retail:response.data.data});
      }
      }
    };
    callBack = callBack.bind(this);
  
    httpGet('report/contact/unfound/client_fk='+state.openBy+'&keyword='+state.keyword+'&close_by_client_fk='+closeBy+'&type='+state.orderType, '', 'Oops, something went wrong and could not load client contacts. Please try again later.', callBack);
  }

  const createClientContactList = ()=>{
    let loan;
    let clientContactList = [];
    if(state.orderType === 'Wholesale'){
      loan = state.wholesale;
    }else{
      loan = state.retail;
    }

    let clickedClientContact;

    for(let i = 0; i < state.clientContactID.length; i++) {
      clickedClientContact = loan.find(entry => entry.ID === state.clientContactID[i]);

      clientContactList.push(clickedClientContact);
    }

    setState({clientContactList:clientContactList});
    addNewClientContacts();
  }

  const addNewClientContacts = ()=>{
    let parameters = [
      {
      field:'clientFk',
      value:state.openBy
      },
      {
      field:'contacts',
      value:state.clientContactList
      }
    ];

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        getUnfoundContact();
        setState({clientContactID:[]});
      }
    };
    callBack = callBack.bind(this);

    httpPost('report/contact/unfound/add', parameters, 'Client contact added successfully.', 'Oops, something went wrong and could not add this client contact. Please try again later.', callBack);
  }

  //render
  let loan;

  if(state.orderType === 'Wholesale'){
    loan = state.wholesale;
  }else{
    loan = state.retail;
  }

  let reportFiltersConfig = [
    {id:'loan',value:state.orderType, updateFunc:(v)=>handleLoanChange(v), width:'2'},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'3'},
    {id:'openBy',value:state.openBy, updateFunc:(v)=>setState({openBy:v}), width:'3'},
    {id:'custom',value:state.keyword, updateFunc:(v)=>setState({keyword:v}), width:'2', label:'Find', placeHolder:"Name/Email"},
    {id:'button',value:'Search', updateFunc:getUnfoundContact, width:'2', className:"align-right valign-bottom", color:"warning"},
  ];

  return <div>
    <Card>
      <CardHeader className="header-color">
      Add Contact
      </CardHeader>
      <CardBody>
        <ReportFilter {...props} configs={reportFiltersConfig}/>
        <Row>
          {
          loan.map(
            (entry, index)=>{
            let style = {height:'300px'};
            if(state.clientContactID.includes(entry.ID)){
              style = {height:'300px', borderStyle: 'solid', borderWidth: 'thick', borderColor: '#2E8BC0'}
            }
            return <Col key={index} sm="3">
              <div>
              <div className="my-well padding" onClick={(e)=>selectThisClientContacts(entry)} style={style}>
                <Row>
                <Col>{entry.company}</Col>
                </Row>
                <br/>
                {
                entry.contacts.map(
                  (contact, index2)=>{
                  return <div style={{fontSize:'11px'}} key={index2}>
                    <div style={{marginBottom:'5px'}}>
                    <b><i className="fa fa-user"/> {contact.label}</b><br/>
                    {contact.name}<br/>
                    {contact.email}&nbsp;&nbsp;
                    {contact.phone}
                    </div>
                  </div>
                  }
                )
                }
              </div>
              <br/>
              </div>
            </Col>
            }
          )
          }
        </Row>
        <div className="my-divider"></div>
        <br/>
        <center>
          <Button color="warning" onClick={createClientContactList}>Add to Client Contact</Button>
        </center>
      </CardBody>
    </Card>
  </div>;
}


export default ClientContactReport;
