//Author author
//Date date
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, callBackGenerator, showMessage, generateSID} from '../../../util/util';
import {Button, Row, Col, Input, Modal, ModalHeader, ModalBody} from 'reactstrap';
import MySelect from '../../util/my-select';
import React, {useReducer, useEffect, useRef} from 'react';
import CustomCheckToPrint from './custom-check-to-print';
import { geocodeByAddress } from 'react-places-autocomplete';
import MyPlacesAutocomplete from '../../util/my-places-autocomplete';
import ReactToPrint from 'react-to-print';

let today = new Date();
let month = today.getMonth()+1;

if(month<10)
  month = '0'+month;
let date = today.getFullYear()+'-'+month+'-'+today.getDate();

//initialize the state
const initialState = {
  SID: generateSID(),
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],

  vendorName:'',
  checkNum:'',
  amount:'',
  billingName:'',
  companyName:'',
  street:'',
  address:'',
  city:'',
  state:'',
  zip:'',
  memo:'',
  bankAccountName:'Wells Fargo Checking',
  expensesAccountName:'5000 Cost of Goods Sold:5100 Appraisal Fees',
  printPopUp:false,
  date:date,

  firstPadding:'465',
  secondPadding:'480',
  options:[]
};

//reducer function that perform state update
const reducer = getReducer();


const CustomQuickbookCheck  = (props)=>{
  const controller = new AbortController();
  const stateSID = useRef();
  const componentRef = useRef(null);

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getPrinterPaddingSetting();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const togglePrint = () =>{
    setState({printPopUp:!state.printPopUp});
  }

  const handleChange = (selectedOption) =>{
    console.log(selectedOption);
    let options = state.options.slice();

    for(let i=0;i<options.length;i++){
      if(options[i].vendor_name===selectedOption){
        setState({
          vendorName:{label:options[i].vendor_name, value:options[i].vendor_name},
          billingName:options[i].payee_billing_name,
          companyName:options[i].company_name,
          street:options[i].street,
          address:options[i].street,
          city:options[i].city,
          state:options[i].state,
          zip:options[i].zip,
          bankAccountName:options[i].bank_account,
          expensesAccountName:options[i].expense_account,
        })
      }
    }
  }

  const googlePlaceOnSelect = (address) =>{
    if(address!==state.address){

      if(address.indexOf(',')!==-1 && address.length>10){
        setState({lat:null, lng:null});

        geocodeByAddress(address)
          .then(
            results => {
              //always use the first returned result
              let targetResult = results[0];

              let addressComponents = targetResult.address_components;

              let streetNumber = '';
              let route = '';
              let city = '';
              let state = '';
              let zip = '';

              //go through the address components and grab the street, city, county, state, and zip
              for(let i=0;i<addressComponents.length;i++){
                if(addressComponents[i].types[0]==='street_number')
                  streetNumber = addressComponents[i].long_name;
                else if(addressComponents[i].types[0]==='route')
                  route = addressComponents[i].long_name;
                else if(addressComponents[i].types[0]==='locality'&&addressComponents[i].types[1]==='political')
                  city = addressComponents[i].long_name;
                else if(addressComponents[i].types[0]==='administrative_area_level_1'&&addressComponents[i].types[1]==='political')
                  state = addressComponents[i].long_name;
                else if(addressComponents[i].types[0]==='postal_code')
                  zip = addressComponents[i].long_name;
              }
              
              let street = '';

              if(streetNumber!==''&&route!=='')
                street = streetNumber+' '+route;

              if(street!==''){
                setState({street:street, address:street});
              }
              if(city!=='')
                setState({city:city});
              if(state!=='')
                setState({state:state});
              if(zip!=='')
                setState({zip:zip});
            })
          .catch(
            error => {
              console.log(error);
            }
          );
      }
      else{
        setState({address:address, street:address});
      }
    }
  }


  const checkForm = () =>{
    let incomplete = false;
    console.log(state.vendorName);
    if(state.vendorName===''){
      showMessage('error', 'Please fill in the pay to order of the check.');
      incomplete = true;
    }
    if(state.amount===''){
      showMessage('error', 'Please fill in the amount of the check.');
      incomplete = true;
    }
    if(state.billingName===''){
      showMessage('error', 'Please fill in the billing name of the check.');
      incomplete = true;
    }
    if(state.checkNum===''){
      showMessage('error', 'Please fill in the check number of the check.');
      incomplete = true;
    }
    if(state.date===''){
      showMessage('error', 'Please fill in the date of the check.');
      incomplete = true;
    }

    if(incomplete)
      return false;
    return true;
  }

  //API call
  const enqueueCheck = () =>{
    let parameters = [
      {
        field:'vendorName',
        value:state.vendorName.value
      },
      {
        field:'checkNum',
        value:state.checkNum
      },
      {
        field:'billingName',
        value:state.billingName
      },
      {
        field:'companyName',
        value:state.companyName
      },
      {
        field:'address',
        value:state.address
      },
      {
        field:'city',
        value:state.city
      },
      {
        field:'state',
        value:state.state
      },
      {
        field:'zip',
        value:state.zip
      },
      {
        field:'memo',
        value:state.memo
      },
      {
        field:'amount',
        value:state.amount
      },
      {
        field:'bankAccountName',
        value:state.bankAccountName
      },
      {
        field:'expensesAccountName',
        value:state.expensesAccountName
      }
    ];

    httpPost('billing/customBill/pay', parameters, 'Check created successfully.', 'Oops, something went wrong and could not create this check. Please try again later.');
  }

  const getRecipient = async (keyword) =>{
    if(keyword!==''){
      let SID = generateSID();
      stateSID.current = SID;


      let callBack = (response)=>{
        if(SID===stateSID.current){
          let code = response.data?response.data.code:undefined;

          if(code==='00'){
            let options = [];

            for(let i=0;i<response.data.data.length;i++){
              options.push({label:response.data.data[i].vendor_name, value:response.data.data[i].vendor_name});
            }

            setState({options:response.data.data});

            if(options.length<=0)
              setState({vendorName:{label:keyword,value:keyword}});
            return options;
          }
        }
      };
      callBack = callBack.bind(this);

      let promise = httpGet('billing/customBill/recipient/search/'+keyword, '', 'Oops, something went wrong and could not retrieve check recipient.', callBack);

      return promise;
    }
  }

  const getPrinterPaddingSetting = () =>{
    let callBack = apiCallBack([{state:'firstPadding', key:'data.padding_1'}, {state:'firstPadding', key:'data.padding_2'}]);
    httpGet('report/check/printer', '', 'Oops, something went wrong and could not retrieve printer settings.', callBack);
  }

  const updatePrinterPaddingSetting = () =>{
    let parameters = [{field:'padding1',value:state.firstPadding},{field:'padding2',value:state.secondPadding}];
    httpPut('report/check/printer/update', parameters, 'Printer padding setting saved successfully.', 'Oops, something went wrong and could not save the printer padding setting. Please try again later.');
  }


  //render
  return <div>
    <Modal className="my-modal-wide" isOpen={state.printPopUp} toggle={togglePrint} >
      <ModalHeader hidden={true} toggle={togglePrint}></ModalHeader>
      <ModalBody>
        <div className="large-scroll-container">
          <center>
            <h5><i className="fa fa-user"></i> Print Check</h5>
          </center>
          <br/>
          <label>Print Preview</label><br/>
          Padding 1 <div className="display-inline"><Input type="text" value={state.firstPadding} onChange={(e)=>setState({firstPadding:e.target.value})}/></div>&nbsp;&nbsp;
          Padding 2 <div className="display-inline"><Input type="text" value={state.secondPadding} onChange={(e)=>setState({secondPadding:e.target.value})}/></div>&nbsp;&nbsp;
          <Button color="warning" onClick={(e)=>updatePrinterPaddingSetting()}>Set As Default</Button>
          <div ref={componentRef}>
            <CustomCheckToPrint firstPadding={state.firstPadding+'px'} secondPadding={state.secondPadding+'px'} company_name={state.companyName} first_name='' last_name='' amount={state.amount} name={state.billingName} billing_name={state.billingName} street={state.street} city={state.city} state={state.state} zip={state.zip} memo={state.memo} date={state.date}/>
          </div>
          <br/>
        </div>
        <center>
          <ReactToPrint
            trigger={() => <Button color="warning">Print</Button>}
            content={() => componentRef.current}
            onAfterPrint={()=>{togglePrint();enqueueCheck()}}
          />&nbsp;&nbsp;<Button color="info" onClick={togglePrint}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
    <div className="padding">
      <Row>
        <Col sm="12">
          <label><font color="red">*</font>Pay to the order of (Vendor Name) <i>*Has to be identical, no extra space or case mismatch allowed*</i></label>
          <br/>
          <MySelect
            type="async-select"
            onChange={(v)=>handleChange(v)}
            defaultOptions
            loadOptions={getRecipient}
          />
        </Col>
        <Col sm="4">
          <label><font color="red">*</font>Check Number</label>
          <br/>
          <Input type="text" value={state.checkNum} onChange={(e)=>setState({checkNum:e.target.value})}/>
        </Col>
        <Col sm="4">
          <label><font color="red">*</font>Amount</label>
          <br/>
          <Input type="text" value={state.amount} onChange={(e)=>setState({amount:e.target.value})}/>
        </Col>
        <Col sm="4">
          <label><font color="red">*</font>Date</label>
          <br/>
          <Input type="text" value={state.date} onChange={(e)=>setState({date:e.target.value})}/>
        </Col>
      </Row>
      <br/>
      <Row>
        <Col sm="6">
          <label><font color="red">*</font>Payee Billing Name</label>
          <br/>
          <Input type="text" value={state.billingName} onChange={(e)=>setState({billingName:e.target.value})}/>
        </Col>
        <Col sm="6">
          <label>Company Name</label>
          <br/>
          <Input type="text" value={state.companyName} onChange={(e)=>setState({companyName:e.target.value})}/>
        </Col>
      </Row>

      <Row>
        <Col sm="12">
          <label>Address</label>
          <br/>
          <MyPlacesAutocomplete value={state.street} onChange={googlePlaceOnSelect} onSelect={googlePlaceOnSelect}/>
        </Col>
        <Col sm="5">
          <label>City</label>
          <br/>
          <Input type="text" value={state.city} onChange={(e)=>setState({city:e.target.value})}/>
        </Col>
        <Col sm="4">
          <label>State</label>
          <br/>
          <MySelect
            type="select"
            selectIsClearable={true}
            value={state.state}
            onChange={(v)=>{setState({state:v})}}
            options={state.states.map((state)=>{
              return {label:state.value, value:state.key};
            })}
          />
        </Col>
        <Col sm="3">
          <label>Zip</label>
          <br/>
          <Input type="text" value={state.zip} onChange={(e)=>setState({zip:e.target.value})}/>
        </Col>
      </Row>
      <br/>
      <Row>
        <Col sm="12">
          <label>Memo</label>
          <br/>
          <Input type="text" value={state.memo} onChange={(e)=>setState({memo:e.target.value})}/>
        </Col>
      </Row>

      <Row>
        <Col sm="6">
          <label><font color="red">*</font>Bank account name</label>
          <br/>
          <Input type="text" value={state.bankAccountName} onChange={(e)=>setState({bankAccountName:e.target.value})}/>
        </Col>
        <Col sm="6">
          <label><font color="red">*</font>Expenses account name</label>
          <br/>
          <Input type="text" value={state.expensesAccountName} onChange={(e)=>setState({expensesAccountName:e.target.value})}/>
        </Col>
      </Row>
      <br/><br/>
      <center>
        <Button color="warning" onClick={(e)=>{if(checkForm())togglePrint()}}>Print</Button>
      </center>
    </div>
  </div>;
}


export default CustomQuickbookCheck;
