//Sooyoung Kim
//June 21, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, formatNumber, formatDateTime, getFromTo} from '../../../util/util';
import {Card, CardHeader, CardBody, Table} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import moment from 'moment';
import ReportFilter from '../report-filter';

let start = getFromTo('start');
let end = getFromTo('end');

//initialize the state
const initialState = {
  reportDetails:[],
  reportTypes:[],
  reports:{},

  from:start,
  to:end,
};

//reducer function that perform state update
const reducer = getReducer();


const TransactionReport  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getReportDetails();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //API call
  const getReportDetails = () =>{
    let callBack = (response)=>{console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let reportTypes = state.reportTypes.slice();
        let reports = Object.assign({},state.reports);

        for(let i=0;i<response.data.data.length;i++){
          let type = response.data.data[i].payment_method;
          if(response.data.data[i].purpose!=='')
            type = type+' '+response.data.data[i].purpose;

          let index = reportTypes.indexOf(type);

          if(index===-1){
            reportTypes.push(type);
            reports[type] = [];
          }

          let subReports = reports[type].slice();
          subReports.push(response.data.data[i]);

          reports[type] = subReports;
        }

        setState({reportDetails:response.data.data, reports:reports, reportTypes:reportTypes});
      }
    };
    callBack = callBack.bind(this);

    httpGet('report/transaction/from='+state.from+'&to='+state.to, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }


  //render
  let from, to;
  if(state.from!=='')
    from = moment(state.from);
  if(state.to!=='')
    to = moment(state.to);
    
  let grandTotal = 0;

  let reportFiltersConfig = [
    {id:'from',value:from, updateFunc:(v)=>setState({from:v}), width:'3'},
    {id:'to',value:to, updateFunc:(v)=>setState({to:v}), width:'3'},
    {id:'button',value:'Submit', updateFunc:getReportDetails, width:'6', className:"align-right", color:"warning"},
  ];
    
  return <div>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Transaction Report
      </CardHeader>
      <CardBody>
        <ReportFilter {...props} configs={reportFiltersConfig}/>
        <div className="my-divider">&nbsp;</div>

        {
          state.reportTypes.map(
            (reportType,index)=>{
              let total = 0;
              let reports = state.reports[reportType];

              let subReport;
              let header;


              if(reportType==='Authorize.net'||reportType==='Authorize.net Refund'||reportType==='Authorize.net Void'||reportType==='Authorize.net Charge Back'){
                header = <tr>
                  <th width="15%">Reference #</th>
                  <th width="20%">Address</th>
                  <th width="10%">Transaction #</th>
                  <th width="10%">Invoice #</th>
                  <th width="10%">Amount</th>
                  <th width="20%">Card Info</th>
                  <th width="15%">Date</th>
                </tr>
                subReport = reports.map(
                  (row,index2)=>{
                    total = total+parseInt(row.amount,10);
                    return (
                      <tr key={index2}>
                        <td><NavLink target="_blank" to={"/appraisal/"+row.ID}>{row.reference_num}</NavLink></td>
                        <td>{row.property_street+' '+row.property_city+', '+row.property_state+' '+row.property_zip}</td>
                        <td>{row.transaction_num}</td>
                        <td>{row.invoice_bill_fk}</td>
                        <td>${formatNumber(row.amount)}</td>
                        <td>{row.billing_f_name+' '+row.billing_l_name+' ****'+row.cc_last_four}</td>
                        <td>{formatDateTime(row.datetime_created)}</td>
                      </tr>
                    );
                  }
                )
              }
              else{
                header = <tr>
                  <th width="15%">Reference #</th>
                  <th width="25%">Address</th>
                  <th width="15%">Transaction #</th>
                  <th width="15%">Invoice #</th>
                  <th width="15%">Amount</th>
                  <th width="15%">Date</th>
                </tr>
                subReport = reports.map(
                  (row,index2)=>{
                    total = total+parseInt(row.amount,10);
                    return (
                      <tr key={index2}>
                        <td><NavLink target="_blank" to={"/appraisal/"+row.ID}>{row.reference_num}</NavLink></td>
                        <td>{row.property_street+' '+row.property_city+', '+row.property_state+' '+row.property_zip}</td>
                        <td>{row.transaction_num}</td>
                        <td>{row.invoice_bill_fk}</td>
                        <td>${formatNumber(row.amount)}</td>
                        <td>{formatDateTime(row.datetime_created)}</td>
                      </tr>
                    );
                  }
                )
              }

              if(reportType==='Authorize.net Refund'||reportType==='Authorize.net Void'||reportType==='Authorize.net Charge Back')
                grandTotal -=total;
              else
                grandTotal +=total;
              return(
                <div key={index}>
                  <label>{reportType} total: ${formatNumber(total)}</label>
                  <div className="large-scroll-container-no-min">
                    <Table className="table table-striped">
                      <thead>
                        {header}
                      </thead>
                      <tbody>
                        {subReport}
                      </tbody>
                    </Table>
                  </div>
                  <br/>
                  <br/>
                </div>
              )
            }
          )
        }
        <br/>
        <b>Grand total</b>: ${formatNumber(grandTotal)}
      </CardBody>
    </Card>
  </div>;
}


export default TransactionReport;
