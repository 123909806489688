//Author Sooyoung Kim
//Date May 10th, 2022
import {formatDate, formatNumber, getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator} from '../../util/util';
import {Button, Row, Col,Input} from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import {NavLink, useNavigate} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';
import MySelect from '../util/my-select';
import './admin.css';


//initialize the state
const initialState = {
  admins:[],
  hasMoreAdmins:true,
  limit: 15,
  offset: 0,
  loading: false,
  sort: 'ID',
  order: 'ASC',
  name:'',
  email:'',
  status:'activated',
  totalCount:0
};

//reducer function that perform state update
const reducer = getReducer();

const Freelanceadmins  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);
  const navigate = useNavigate();

  //wrapper function
  const setState = getSetStateFunction(dispatch);
  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    console.log(state);
    if(state.admins.length<=0 && state.hasMoreAdmins){
        loadMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state]);

  useEffect(()=>{

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //function that manipulate state
  //this function refresh the list of admins pipeline
  const refreshList = ()=>{
    setState({admins:[],hasMoreAdmins:true,offset:0});
  }

  //click handler that switch the state of sorting order then call the function to refresh the list
  //to achieve the "sorting"
  const columnClickHandler = (col)=>{
    if(state.sort===col){
      if(state.order==='ASC')
        setState({order:'DESC'});
      else
        setState({order:'ASC'});
    }
    else
      setState({order:'ASC', sort:col});
    refreshList();
  }

  //non API call but simpyl manage state

  //this function render the sort icon at each column of the table
  const renderSortIcon = (col)=>{
    if(state.sort===col){
      if(state.order==='ASC')
        return <i className="red-color fa fa-arrow-down"></i>
      else
        return <i className="red-color fa fa-arrow-up"></i>
    }
  }

  //render function for infinite scroller
  const renderAdmins = ()=>{
    return (
      <div>
        <table className="primary-table">
          <thead>
            <tr>
              <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('first_name')}><i className="fa fa-user"></i>&nbsp;First Name {renderSortIcon('first_name')}</th>
              <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('last_name')}><i className="fa fa-user"></i>&nbsp;Last Name {renderSortIcon('last_name')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('role')}><i className="fa fa-user"></i>&nbsp;Role {renderSortIcon('role')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('status')}><i className="fa fa-info"></i>&nbsp;Status {renderSortIcon('status')}</th>
              <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('email')}><i className="fa fa-envelope-open"></i>&nbsp;Email {renderSortIcon('email')}</th>
              <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('phone')}><i className="fa fa-phone"></i>&nbsp;Phone {renderSortIcon('phone')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('datetime_created')}><i className="fa fa-calendar-o"></i>&nbsp;Date Created {renderSortIcon('datetime_created')}</th>
            </tr>
          </thead>
          <tbody>
          {
            state.admins.map(
              (admin)=>{
                return (
                  <tr key={admin.ID} onClick={()=>navigate("/admin/"+admin.ID)}>
                    <td>{admin.first_name}</td>
                    <td>{admin.last_name}</td>
                    <td>{admin.role}</td>
                    <td>{admin.status}</td>
                    <td>{admin.email}</td>
                    <td>{admin.phone}</td>
                    <td>{formatDate(admin.datetime_created)}</td>
                  </tr>
                );
              }
            )
          }
          </tbody>
        </table>
      </div>
    )
  }


  //API call
  //function that fire when the infinite scroll reach bottom
  const loadMore = ()=>{
    //do not load if there is no more conditions or it's loading data
    if(state.hasMoreAdmins&&!state.loading){
      //set loading equals to true so it won't fire off before we are done
      setState({loading:true});

      let name = encodeURIComponent(state.name.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
      let email = encodeURIComponent(state.email.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));

      let url = 'admin/get/limit='+state.limit+'&offset='+state.offset+'&name='+name+'&email='+email+'&sort='+state.sort+'&order='+state.order+'&status='+state.status;

      //callback handler that update the state when http request return
      let callBack = (response)=>{
        let code = response.data?response.data.code:undefined;

        if(code!=='00'){
          setState({hasMoreAdmins:false});
        }
        else{
          let newadmins = response.data.data;
          let hasMoreAdmins = true;
          let newOffset = state.offset;
          let totalCount = response.data.count;

          //if http request return empty then no more results, end of list

          if(newadmins.length<=0){
            hasMoreAdmins = false;
          }
          else{
            //increment the offset
            newOffset = state.offset + 1;
          }

          //concat the current array of announcement
          if(state.admins.length>0){
            let temp = [...state.admins,...newadmins];

            setState({admins:temp});

          }
          else
            setState({admins:newadmins});

          setState({hasMoreAdmins:hasMoreAdmins, offset:newOffset, totalCount:totalCount});
        }
      };

      //error handler when the http request return with error
      let errorCallBack = apiCallBack([{state:'hasMoreAdmins', value:false}]);

      //collect the promise and wait for it to finish performing it's task
      let promises = httpGet(url, '','Oops, something went wrong and could not load freelance admins. Please try again later.', callBack, errorCallBack);
      promises
        .then(
          function(result){
            //set loading equals to false so the function could be fire off once again
            setState({loading:false});
          }
        );
    }
  }

  //render
  return <div>
    <InfiniteScroll
      next={loadMore}
      dataLength={state.admins.length}
      hasMore={state.hasMoreAdmins}
      loader={<div key="nill" className="loader"><center>Loading more admins...</center></div>}
      initialLoad = {true}
      className="my-well"
      scrollableTarget="contentContainer"
    >
      <div className="page-title">
        <i className="fa fa-reorder"></i>&nbsp;Admins
      </div>

      <Row>
        <Col sm="3">
          <label className="no-margin-bottom">Name</label>
          <Input className="form-control" value={state.name} type="text" onChange={(e)=>setState({name:e.target.value})}/>
        </Col>
        <Col sm="3">
          <label className="no-margin-bottom">Email</label>
          <Input className="form-control" value={state.email} type="text" onChange={(e)=>setState({borrower:e.target.value})}/>
        </Col>
        <Col sm="3">
          <b>Status</b><br/>
          <MySelect
            type="select"
            value={state.status}
            onChange={(v)=>{setState({status:v})}}
            options={[{label:"All",value:""},{label:"Acativated",value:"activated"},{label:"Deactivated",value:"deactivated"}]}
          />
        </Col>
        <Col sm="3" className="align-right valign-bottom">
          <br/>
          <Button color="warning" onClick={()=>refreshList()} >Submit</Button>
        </Col>
      </Row>
      <div className="my-divider"></div>
      <Row>
        <Col sm="6">
            <div>{formatNumber(state.totalCount)} admins in queue<br/><br/></div>
        </Col>
        <Col sm="6" className="align-right">
          <NavLink to="/admin/new"><i className="fa fa-plus green-color"></i> Create new admin</NavLink>
        </Col>
      </Row>

      {renderAdmins()}
    </InfiniteScroll>
  </div>;
}

export default Freelanceadmins;
