//Author Sooyoung Kim
//Date Aug 16, 2023
import AppraisalFile from './file';
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, callBackGenerator, showMessage, sliceFromArray} from '../../util/util';
import {Modal, ModalHeader, ModalBody, Row, Col, Table} from 'reactstrap';
import FileUpload from './file-upload';
import React, {useReducer, useEffect} from 'react';
import './file.css';


//initialize the state
const initialState = {
  appraisalFk:-1,
  appraisalFiles:[],
  newAppraisalFiles:[],
  targetRecipients:[],
  modal: false,
  selectedRecipient:-1,
  entities:[],
  fileTypes:[],
  appraisal:{},
  checkPrice:false,
  standAloneKeywords:[
    'Final Inspection with Photos (1004D / HUD 92051)',
    'Appraisal Update / Re Cert',
    'Rent Survey (stand alone)',
    'Operating Income Statement (stand alone)',
    'Condo Drive By with Photos (1075)',
    'SFR Drive By with Photos (2055)',
    'Exterior Only Inspection (2075)',
    'Desk Review (Cost Enhanced with Photos)',
    'Field Review (Cost with Photos)',
    'Disaster Inspection & with Photos (1004D)'
  ]
};

//reducer function that perform state update
const reducer = getReducer();


const PopUpFile  = (props)=>{
  const controller = new AbortController();
  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const togglePopUp = ()=>{
    if(Object.keys(state.appraisal).length===0){
      //extract the ID
      let url = window.location.href;
      let tokens = url.split('/');
      let ID = tokens[tokens.length-1];
      setState({appraisalFk:ID});

      getAppraisal(ID);
      getAppraisalFiles(ID);
      getFileTypes();
    }
    let newState = {popUpOpen:!state.popUpOpen};
    if(state.popUpOpen){
      //console.log('initialize')
      newState = Object.assign({}, newState, {appraisal:{}, appraisalFiles:[], entities:[], fileTypes:[]});
    }
    setState(newState);
  }


  //remove file from the list after successfull http delete call
  const removeFileFromList = (ID)=>{
    let list = sliceFromArray(state.appraisalFiles,'ID', ID);
    setState({appraisalFiles:list});
  }


  const fileUploadPreCheck = (files)=>{
    let hasAppraisal = false;
    let hasXmlReport = false;
    let hasENVReport = false;
    let xmlRequired = false;
    let envRequired = false;
    let xmlRequiredType = ['1075','2055','1004c','1004','1073','1025','Condo Appraisal','SFR Appraisal','Family Multi','Manufactured Home Appraisal'];

    for(let i=0;i<xmlRequiredType.length;i++){
      if(state.appraisal.appraisal_type.includes(xmlRequiredType[i])){
        if(state.appraisal.appraisal_type.includes('1004D')){
          if(state.appraisal.appraisal_type.includes('Final Inspection with Photos (1004D / HUD 92051)')){
            xmlRequired = true;
          }
          else{
            continue;
          }
        }

        xmlRequired = true;
      }
    }

    if(xmlRequired && state.appraisal.env_required==='yes')
      envRequired = true;


    for(let i =0; i<files.length;i++){
      //console.log(files);
      if(!files[i].fileType||files[i].fileType===''){
        return '*Please select the file type for the file "'+files[i].name+'".';
      }
      else{
        if(files[i].fileType==='Appraisal'||files[i].fileType==='Appraisal - Revised'||files[i].fileType==='Final Inspection with Photos (1004D / HUD 92051)'||state.standAloneKeywords.indexOf(files[i].fileType)!==-1){
          hasAppraisal = true;

          let name = files[i].name;
          name = name.substr(name.length-3,3);

          if(name.toLowerCase()==='xml')
            hasXmlReport = true;
        }
        if(files[i].fileType==='Appraisal ENV'){
          hasENVReport = true;
        }
      }
    }

    if(files.length<=0){
      return 'Please upload at least one file.';
    }

    if(xmlRequired&&hasAppraisal){
      if(!hasXmlReport){
        return '*Please upload the XML as well.';
      }

      if(envRequired&&!hasENVReport){
        return '*Please upload the ENV as well.';
      }
    }
    return "";
  }



  //remove file recipient of new file upload
  //file recipient are those entity that able to access the file
  const removeFromTargetRecipients = (id)=>{
    let list = sliceFromArray(state.targetRecipients,'ID', id);
    setState({targetRecipients:list});
  }

  //all all entity of the appraisal into the file recipient
  const addAllToTargetRecipients = ()=>{
    let allRecipients = state.entities.slice();
    setState({targetRecipients:allRecipients});
  }

  //on click function when the + button is click to add new file recipeint from chosen entity of appraisal
  const addNewFileRecipients = (ID)=>{
    if(ID==='All')
      addAllToTargetRecipients();
    else{
      for(let i =0;i<state.targetRecipients.length;i++){
        if(state.targetRecipients[i].ID===ID)
          return;
      }
      let existingNewTargetRecipients = state.targetRecipients.slice();

      let targetEntity = null;
      for(let i=0;i<state.entities.length;i++){
        if(state.entities[i].ID===ID){
          targetEntity = state.entities[i];
          break;
        }
      }

      if(targetEntity){
        existingNewTargetRecipients.push(targetEntity);
        setState({targetRecipients:existingNewTargetRecipients});
      }
    }
  }

  //API cal
  const uploadFileAdded = (files)=>{
    let extractXml = false;
    let hasXmlReport = false;
    let hasPdfReport = false;
    for(let i=0;i<files.length;i++){
      if(files[i].fileType==='Appraisal'||files[i].fileType==='Appraisal - Revised'||files[i].fileType==='Final Inspection with Photos (1004D / HUD 92051)'||state.standAloneKeywords.indexOf(files[i].fileType)!==-1){

        let name = files[i].name;
        name = name.substr(name.length-3,3);

        if(name.toLowerCase()==='xml')
          hasXmlReport = true;
        else if(name.toLowerCase()==='pdf')
          hasPdfReport = true;
      }
    }

    if(!hasPdfReport && hasXmlReport)
      extractXml = true;
    setState({extractXml:extractXml});
  }


  //call back when file upload is completed
  //check if there are appraisal document, if yes then update order status to 'Review'
  //check if it is purchase order and need check price then prompt message
  const uploadCompleteCallBack = (files)=>{
    //console.log(files);
    let hasAppraisal = false;
    for(let i=0;i<files.length;i++){
      if(files[i].fileType==='Appraisal'||files[i].fileType==='Appraisal - Revised'||files[i].fileType==='Final Inspection with Photos (1004D / HUD 92051)'||state.standAloneKeywords.indexOf(files[i].fileType)!==-1){
        hasAppraisal = true;
      }
    }
    //console.log(hasAppraisal);
    //console.log(state.checkPrice);


    if(state.checkPrice && state.appraisal.loan_purpose === "Purchase"){
      showMessage('warning','Please verify the purchase price.');
    }
    //if all file is done uploading and one of the file is appraisal then change status to review
    if(hasAppraisal)
      setState({appraisal:Object.assign({},state.appraisal,{status:'Review'})});
  }

  //upload function
  const uploadFile = (file) =>{
    let email = localStorage.getItem('email');
    let userID = localStorage.getItem('userID');
    let name = localStorage.getItem('firstName')+' '+localStorage.getItem('lastName');

    let base64 = file.file;

    //if there are purchase contract related document/misc then we need to prompt for check purchase price if it's purchase order
    if(file.fileType==='Purchase Contract' ||file.fileType==='Contract Addendum' || file.fileType==='Misc'){
      setState({checkPrice:true});
    }

    let targetRecipients =[];

    for(let i=0;i<state.targetRecipients.length;i++)
      targetRecipients.push(state.targetRecipients[i].ID);


    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;

      if(code==='00'){
        let newUploadedFile = response.data.data;
        let existingUploadedFiles = state.appraisalFiles.slice();
        existingUploadedFiles.unshift(newUploadedFile);

        setState({appraisalFiles:existingUploadedFiles});
      }
      return response;
    };


    let parameters = [
      {
        field:'appraisal_fk',
        value:state.appraisalFk
      },
      {
        field:'file_type',
        value:file.fileType
      },
      {
        field:'upload_type',
        value:'Orders'
      },
      {
        field:'upload_userfk',
        value:userID
      },
      {
        field:'upload_useremail',
        value:email
      },
      {
        field:'upload_username',
        value:name
      },
      {
        field:'name',
        value:file.name
      }
      ,{
        field:'file_encoded',
        value:base64
      },
      {
        field:'to_send_entities',
        value:targetRecipients
      }
    ];

    if(state.extractXml){
      let tmp = {
        field:'extract_xml',
        value:'yes'
      };

      parameters.push(tmp);
    }

    return httpPost('file/upload',parameters,file.name+' uploaded successfully.','Oops, something went wrong and could not upload the file "'+file.name+'". Please try again later.', callBack);
  }

  const getAppraisal = (id)=>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let newState = {appraisal:response.data.data};

        let entities = [];
        for(let i=0;i<response.data.data.entities.length;i++){
          let entity = response.data.data.entities[i];
          entities.push(entity);
        }
        newState = Object.assign({}, newState, {entities:entities});
        setState(newState);
      }
    };
    httpGet('appraisal/'+id, '','Oops, something went wrong and could not load this appraisal. Please try again later.', callBack);
  }

  const getFileTypes = ()=>{
    let callBack = apiCallBack([{state:'fileTypes', key:'data'}]);
    httpGet('file/fileType/get', '','Oops, something went wrong and could not load appraisal file types. Please try again later.', callBack);
  }

  const getAppraisalFiles = (appraisalFk)=>{
    let url = 'file/get/'+appraisalFk;
    //Call Back
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        response.data.data.reverse();
        //console.log('retrieve appraisal file');
        setState({appraisalFiles:response.data.data});
      }
    };
    httpGet(url, '','Oops, something went wrong and could not load appraisal files for this appraisal. Please try again later.', callBack);
  }

  //render related variable
  let entities;
  if(state.entities){
    entities = state.entities.map(
      (entity, index)=>{
        return (
          <tr key={index} className="cursor-pointer" onClick={(e)=>addNewFileRecipients(entity.ID)}><td><b>{entity.entity_label}</b></td><td>{entity.entity_name} - {entity.entity_email}</td></tr>
        );
      }
    );
  }

  let targetRecipients;
  if(state.targetRecipients){
    targetRecipients = state.targetRecipients.map(
      (recipient)=>{
        return(
          <div style={{display:'inline-block'}} key={recipient.ID}>
            <div className="entity-container cursor-pointer" onClick={()=>{removeFromTargetRecipients(recipient.ID)}}>
              <i className="fa fa-minus link-color"></i>&nbsp;{recipient.entity_name}
            </div>&nbsp;
          </div>
        );
      }
    );
  }

  let appraisalFileUI = React.useMemo(
    ()=>{
      return <AppraisalFile {...props} height="700px" newAppraisalFiles={state.appraisalFiles} appraisalFk={state.appraisalFk} appraisal={state.appraisal} removeFiles={removeFileFromList}/>;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.appraisalFiles, state.appraisalFk]
  );

  //render
  return <div>
    <div className="cursor-pointer" onClick={togglePopUp}><i className="fa fa-files-o"></i> Files</div>
    <Modal className="my-modal-wide" isOpen={state.popUpOpen} toggle={togglePopUp} >
      <ModalHeader hidden={true} toggle={togglePopUp}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-search"></i> Files - {state.appraisal.reference_num} {state.appraisal.property_street}</h5>
        </center>
        <Row>
          <Col sm="6">
            <br/>
            <center>
              <h5><i className="fa fa-files-o"></i> Existing Files</h5>
            </center>
            <div>
              {appraisalFileUI}
            </div>
          </Col>
          <Col sm="6">
            <br/>
            <center>
              <h5><i className="fa fa-comments"></i> New file</h5>
            </center>
            <div>
              <label>Tag Someone</label>
              <div className="small-scroll-container-ex-large">
                <Table hover className="table">
                  <tbody>
                    {entities}
                  </tbody>
                </Table>
              </div>

              <div className="small-scroll-container">
                {targetRecipients}
              </div>

              <FileUpload fileTypes={state.fileTypes} onFileAdded={uploadFileAdded} preCheckFunc={fileUploadPreCheck} upload={uploadFile} uploadCompleteCallBack={uploadCompleteCallBack}/>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  </div>;
}

export default PopUpFile;
