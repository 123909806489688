//Author Sooyoung Kim
//Date April 24, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, putAPICallGenerator, callBackGenerator} from '../../util/util';
import {Col, Row} from 'reactstrap';
import MyXEditable from '../util/my-xeditable';
import {NavLink, useParams} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';


//initialize the state
const initialState = {
  admin:{}
};

//reducer function that perform state update
const reducer = getReducer();


const Admin  = (props)=>{
  const controller = new AbortController();
  const { id } = useParams();

  let newInitialState = Object.assign({}, initialState, {id:id});
  const [state, dispatch] = useReducer(reducer, newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getAdmin();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const updateFieldCallBack = (field, value) =>{
    console.log(field, value);
    let admin = Object.assign({}, state.admin);
    admin[field] = value;
    setState({admin:admin});
  }

  //API call
  //generate a function that do the update on editable
  const generateUpdateFunction = (url, parameters, successMessage, failedMessage, parentCallBack=null)=>{
    return (newValue, callBack, errorCallBack)=>{
      //add in the new value into parameters

      parameters.push({field:'value',value:newValue});
      let promise = httpPut(url,parameters,successMessage,failedMessage, callBack, errorCallBack);
      promise.then(
        (response)=>{
          let field;
          let value;

          for(let i=0;i<parameters.length;i++){
            if(parameters[i].field==='field')
              field = parameters[i].value;
            else if(parameters[i].field==='value')
              value = parameters[i].value;
          }
          if(parentCallBack!==null){
            parentCallBack(field, value);
          }
        }
      );
    }
  };

  //get admin profile
  const getAdmin = () =>{
    let url = 'admin/'+state.id;
    let callBack = apiCallBack([{state:'admin', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load this admin profile. Please try again later.', callBack);
  }

  //render
  let successMessage = 'Admin profile updated.';
  let failedMessage = 'Failed to update the value of this field, please try again later.';

  return <div className="my-well">
    <Row>
      <Col sm="6">
        <div className="page-title">
          <i className="fa fa-reorder"></i>&nbsp;Admin
        </div>
        <NavLink to="/admin">Back to admin list</NavLink>
      </Col>
      <Col sm="6" className="align-right">
      </Col>
    </Row>
    <div className="my-divider"></div>

    <Row>
      <Col sm="4">
        <Row>
          <Col sm="4">
            <label>Email</label>
          </Col>
          <Col sm="8">
            {state.admin.email}
          </Col>
        </Row>
      </Col>
    </Row>
    <br/><br/>
    <Row>
      <Col sm="4">
        <Row>
          <Col sm="4">
            <label>Status</label>
          </Col>
          <Col sm="8">
            <MyXEditable
              type="select"
              opt={[{key:'activated',value:'activated'},{key:'deactivated',value:'deactivated'}]}
              value={state.admin.status}
              updateFunc={
                generateUpdateFunction('admin/update',[{field:'field',value:'status'},{field:'ID',value:state.admin.ID}],successMessage, failedMessage, updateFieldCallBack)
              }
            />
          </Col>
        </Row>
      </Col>
      <Col sm="4">
        <Row>
          <Col sm="4">
            <label>Title</label>
          </Col>
          <Col sm="8">
            <MyXEditable
              type="select"
              opt={[{key:'President',value:'President'},{key:'Vice President',value:'Vice President'}, {key:'Account Manager',value:'Account Manager'},{key:'Software Engineer',value:'Software Engineer'},{key:'Finance Manager',value:'Finance Manager'}, {key:'HR',value:'HR'}]}
              value={state.admin.title}
              updateFunc={
                generateUpdateFunction('admin/update',[{field:'field',value:'title'},{field:'ID',value:state.admin.ID}],successMessage, failedMessage, updateFieldCallBack)
              }
            />
          </Col>
        </Row>
      </Col>
    </Row>
    <Row>
      <Col sm="4">
        <Row>
          <Col sm="4">
            <label>First name</label>
          </Col>
          <Col sm="8">
            <MyXEditable
              type="text"
              value={state.admin.first_name}
              updateFunc={
                generateUpdateFunction('admin/update',[{field:'field',value:'first_name'},{field:'ID',value:state.admin.ID}],successMessage, failedMessage, updateFieldCallBack)
              }
            />
          </Col>
        </Row>
      </Col>
      <Col sm="4">
        <Row>
          <Col sm="4">
            <label>Last name</label>
          </Col>
          <Col sm="8">
            <MyXEditable
              type="text"
              value={state.admin.last_name}
              updateFunc={
                generateUpdateFunction('admin/update',[{field:'field',value:'last_name'},{field:'ID',value:state.admin.ID}],successMessage, failedMessage, updateFieldCallBack)
              }
            />
          </Col>
        </Row>
      </Col>
    </Row>
    <Row>
      <Col sm="4">
        <Row>
          <Col sm="4">
            <label>Role</label>
          </Col>
          <Col sm="8">
            <MyXEditable
              type="select"
              opt={[{key:'admin',value:'Admin'},{key:'admin_manager',value:'Admin Manager'},{key:'super_admin',value:'Super Admin'}]}
              value={state.admin.role}
              updateFunc={
                generateUpdateFunction('admin/update',[{field:'field',value:'role'},{field:'ID',value:state.admin.ID}],successMessage, failedMessage, updateFieldCallBack)
              }
            />
          </Col>
        </Row>
      </Col>
      <Col sm="4">
        <Row>
          <Col sm="4">
            <label>Phone</label>
          </Col>
          <Col sm="8">
            <MyXEditable
              type="text"
              value={state.admin.phone}
              updateFunc={
                generateUpdateFunction('admin/update',[{field:'field',value:'phone'},{field:'ID',value:state.admin.ID}],successMessage, failedMessage, updateFieldCallBack)
              }
            />
          </Col>
        </Row>
      </Col>
    </Row>
  </div>;
}

export default Admin;
