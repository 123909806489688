//Author June Leow
//Date June 12th, 2023
//initialize the state
import React, {useEffect} from 'react';
import GoogleMapReact from 'google-map-react';

const MyGoogleMap  = (props)=>{
    const controller = new AbortController();

    //run only once when component is loaded
    useEffect(()=>{
        return ()=> controller.abort();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    //non API call but simpyl manage state

    //API call
   
    //render
    const defaultProps = {
      center: {
        lat: props.lat,
        lng: props.lng
      },
      zoom: props.zoom?props.zoom:11
    };

    return <div style={{ height: props.height?props.height:'50vh', width: props.width?props.width:'100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyBTQF2wciwq-fvkKRYIAPaXGuzdNPE2cjs' }}
        defaultCenter={[defaultProps.center.lat, defaultProps.center.lng]}
        defaultZoom={defaultProps.zoom}
      >
        <i className="fa fa-location-dot" style={{color:'#D83923',fontSize:'40px'}}
          lat={defaultProps.center.lat}
          lng={defaultProps.center.lng}
        />
        {
          props.markers.map(
            (marker, index)=>{
              return <div lat={marker.lat} lng={marker.lng} key={marker.id}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', lineHeight:'10px'}}>
                <font size="2" style={{ fontWeight: 'bold', textAlign: 'center', backgroundColor: '#f2f2f2', borderRadius: '5px' }}>
                  {marker.label ? marker.label : ''}
                </font>
                <i className="fa fa-street-view flashit" style={{ color: '#D83923', fontSize: '20px' }} />
                <font size="2" style={{ fontWeight: 'bold', textAlign: 'center', backgroundColor: '#f2f2f2', borderRadius: '5px', fontSize: "10px" }}>
                  {marker.description ? marker.description : ''}
                </font>
              </div>
            </div>;
            }
          )
        }
        
      </GoogleMapReact>
    </div>;
}


export default MyGoogleMap;
