//Author June Leow
//Date Oct 4th, 2023
import Assignment from '../assignment/assignment.js';
import ActiveReminders from './active-reminders';
import AppraisalDetails from './appraisal-details';
import PropertyDetails from './property-details';
import FloatingInfo from './floating-info.js';
import Billing from '../billing/billing';
import CommentTab from '../comment/comment-tab';
import FeeIncrease from '../appraisal/fee-increase';
import Review from '../review/review';
import Condition from '../condition/condition';
import Rebuttal from '../rebuttal/rebuttal';
import {getReducer, getSetStateFunction, getAPICallGenerator, putAPICallGenerator, callBackGenerator, sliceFromArray} from '../../util/util';
import {Row, Col, Nav, NavLink as NavLinkL, NavItem, TabPane, TabContent, Progress} from 'reactstrap';
import {NavLink} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';
import { useParams} from "react-router-dom";
import QuoteVetting from '../appraisal/quote-vetting';
import QualityRating from '../rating/quality-rating';
import ServiceRating from '../rating/service-rating';

//initialize the state
const initialState = {
  id:-1,
  activeTab:'1',
  globalPipeline: localStorage.getItem('pipelinePreference')==='global',
  appraisal: {
    property_street:'',
    property_city:'',
    property_state:'',
    borrower_f_name:'',
    borrower_l_name:'',
    reference_num:'',
    loan_num:'',
    modification_log:[],
  },
  overallStatus:{
    review_count:0,
    condition_count:0,
    rebuttal_count:0,
    due_invoice_count:0,
    unread_comment_count:0,
    fee_increase_count:0,
    quote_vetting_count:0
  },
  messages:[],
  presenceMembers:[],
  activeReminders:[],
  tabHistory:[],
  commentID:-1,
  reviewID:-1,
  auditID:-1,
};

//reducer function that perform state update
const reducer = getReducer();


const Appraisal  = (props)=>{
  const controller = new AbortController();
  let { id, commentID, reviewID, auditID} = useParams();
  console.log(props);
  let newInitialState = Object.assign({}, initialState, {id:id, activeTab:props.activeTab, tabHistory:[props.activeTab], commentID:commentID, reviewID:reviewID, auditID:auditID});
  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    console.log(this);
    getAppraisal(state.id);
    getOverallStatus(state.id);
    getReminder(state.id);

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const activeTabToggle = (tab) =>{
    setState({activeTab:tab});
    let tabHistory = state.tabHistory.slice();
    if(tabHistory.indexOf(tab)===-1){
      tabHistory.push(tab);
      setState({tabHistory:tabHistory});
    }

    switch(tab){
      case '1':
        window.history.replaceState(null, "", "/appraisal/"+state.id)
        break;
      case '2':
        window.history.replaceState(null, "", "/assignment/"+state.id)
        break;
      case '3':
        window.history.replaceState(null, "", "/comment/"+state.id)
        break;
      case '4':
        window.history.replaceState(null, "", "/fee-increase/"+state.id)
        break;
      case '5':
        window.history.replaceState(null, "", "/quote/"+state.id)
        break;
      case '6':
        window.history.replaceState(null, "", "/property-details/"+state.id)
        break;
      case '7':
        window.history.replaceState(null, "", "/review/"+state.id)
        break;
      case '8':
        window.history.replaceState(null, "", "/condition/"+state.id)
        break;
      case '9':
        window.history.replaceState(null, "", "/rebuttal/"+state.id)
        break;
      case '10':
        window.history.replaceState(null, "", "/billing/"+state.id)
        break;
      default:
        break;
    }
  }

  

  const updateAppraisal = (appraisal)=>{
    setState({appraisal:appraisal});
  }

  const updateOverallStatus = (overallStatus)=>{
    setState({overallStatus:overallStatus});
  }

  //API call
  const updateReminder = (id, childCallBack)=>{
    let parameters = [
      {
        field:'ID',
        value:id
      }
    ];

    let activeReminders = sliceFromArray(state.activeReminders,'panelID', id);
    let callBack = apiCallBack([{state:'activeReminders', value:activeReminders}]);
    if(childCallBack){
      callBack = apiCallBack([{state:'activeReminders', value:activeReminders}], childCallBack);
    }

    httpPut('appraisal/reminder/update',parameters,'','Oops, something went wrong and could not save the memo. Please try again later.', callBack);
  }
  const getAppraisal = (id)=>{
    let url = 'appraisal/'+id;
    //generate a generic call back that will update state
    let callBack = apiCallBack([{state:'appraisal',key:'data'}, {state:'messages', key:'messages'}]);
    httpGet(url, '','Oops, something went wrong and could not load appraisal details. Please try again later.', callBack);
  }

  const getOverallStatus = (id)=>{
    let url = 'appraisal/overall/status/'+id;
    //generate a generic call back that will update state
    let callBack = apiCallBack([{state:'overallStatus',key:'data'}]);
    httpGet(url, '','Oops, something went wrong and could not load appraisal status. Please try again later.', callBack);
  }

  const getReminder = (id)=>{
    let callBack = apiCallBack([{state:'activeReminders', key:'data'}]);
    httpGet('appraisal/reminder/'+id, '', 'Oops, something went wrong and could not load active reminder.', callBack);
  }

  console.log('appraisal render');

  let pdrTab;

  if(state.appraisal.pdr_fk){
    pdrTab =
    <NavItem>
      <NavLinkL className="cursor-pointer" active={state.activeTab==='11'} onClick={(e)=>activeTabToggle('11')}> Property Data Report (ACE+ PDR)</NavLinkL>
    </NavItem>;
  }

  let appraisalLink = 'appraisal';

  if(state.globalPipeline)
    appraisalLink = 'appraisal-global';

  let fileNum='';
  if(state.appraisal.third_party_num&&state.appraisal.third_party_num!=='0')
    fileNum = ' - File#'+state.appraisal.third_party_num;

  let fhaCaseNumberTitle='';
  if(state.appraisal.fha_case_number&&state.appraisal.fha_case_number!=='0')
    fhaCaseNumberTitle = ' - FHA#'+state.appraisal.fha_case_number;
  
  
  let fields = [];

  if(state.activeTab==='2'){
    fields = ['address','datetime_expected','property_county','property_type','loan_purpose','appraisal_type','loan_type'];
  }
  else if(state.activeTab==='3'){
    fields = ['rush','priority','loan_purpose','loan_type','appraisal_type','datetime_submitted','datetime_inspection','datetime_due','datetime_expected'];
  }
  else if(state.activeTab==='8'||state.activeTab==='9'){
    fields = ['address','property_type','loan_type','appraisal_type','loan_purpose'];
  }
  //render
  return <div className="my-well">
    <FloatingInfo {...props} updateAppraisal={updateAppraisal} appraisal={state.appraisal} activeTab={state.activeTab} fields={fields}/>
    <Row>
      <Col sm="12">
        <div className="page-title">
          <i className="fa fa-reorder"></i>&nbsp;Appraisal Details - {state.appraisal.reference_num+'- '+state.appraisal.loan_num+fileNum+' - '+state.appraisal.property_street+' '+state.appraisal.property_city+', '+state.appraisal.property_state+' - '+state.appraisal.borrower_f_name+' '+state.appraisal.borrower_l_name+fhaCaseNumberTitle}
        </div>
      </Col>
      <Col sm="4">
        <NavLink to={"/"+appraisalLink}>Back to appraisal list</NavLink>
      </Col>
      <Col sm="8" className="align-right">
        <ActiveReminders reminders={state.activeReminders} updateReminder={updateReminder}/>
        &nbsp;
        <ServiceRating appraisal={state.appraisal} appraisalFk={state.id} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
        &nbsp;
        <QualityRating appraisal={state.appraisal} appraisalFk={state.id} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
      </Col>
    </Row>
    <div className="my-divider"></div>
    <div style={{minHeight:'25px', maxHeight:'25px', paddingLeft:'5px'}}>
      {
        state.presenceMembers.map(
          (member, index)=>{
            return (
              <div key={index} className="display-inline flashit">
                <i className="fa fa-user online-color"></i> {member.info.name} &nbsp;
              </div>
            )
          }
        )
      }
    </div>
    <Nav tabs>
      <NavItem>
        <NavLinkL className="my-nav-link cursor-pointer" active={state.activeTab==='1'} onClick={(e)=>activeTabToggle('1')}>Appraisal Details</NavLinkL>
      </NavItem>
      <NavItem>
        <NavLinkL className="my-nav-link cursor-pointer" active={state.activeTab==='2'} onClick={(e)=>activeTabToggle('2')}>Assignment</NavLinkL>
      </NavItem>
      <NavItem>
        <NavLinkL className="my-nav-link cursor-pointer" active={state.activeTab==='3'} onClick={(e)=>activeTabToggle('3')}><span className="my-badge">{state.overallStatus.unread_comment_count}</span> Comments</NavLinkL>
      </NavItem>
      <NavItem>
        <NavLinkL className="my-nav-link cursor-pointer" active={state.activeTab==='4'} onClick={(e)=>activeTabToggle('4')}><span className="my-badge">{state.overallStatus.fee_increase_count}</span> Fee Increase</NavLinkL>
      </NavItem>
      <NavItem>
        <NavLinkL className="my-nav-link cursor-pointer" active={state.activeTab==='5'} onClick={(e)=>activeTabToggle('5')}><span className="my-badge">{state.overallStatus.quote_vetting_count}</span> Quote Vetting</NavLinkL>
      </NavItem>
      <NavItem>
        <NavLinkL className="my-nav-link cursor-pointer" active={state.activeTab==='6'} onClick={(e)=>activeTabToggle('6')}> Property Details</NavLinkL>
      </NavItem>
      <NavItem>
        <NavLinkL className="my-nav-link cursor-pointer" active={state.activeTab==='7'} onClick={(e)=>activeTabToggle('7')}><span className="my-badge">{state.overallStatus.review_count}</span> Review</NavLinkL>
      </NavItem>
      <NavItem>
        <NavLinkL className="my-nav-link cursor-pointer" active={state.activeTab==='8'} onClick={(e)=>activeTabToggle('8')}><span className="my-badge">{state.overallStatus.condition_count}</span> Condition</NavLinkL>
      </NavItem>
      <NavItem>
        <NavLinkL className="my-nav-link cursor-pointer" active={state.activeTab==='9'} onClick={(e)=>activeTabToggle('9')}><span className="my-badge">{state.overallStatus.rebuttal_count}</span> Rebuttal</NavLinkL>
      </NavItem>
      <NavItem>
        <NavLinkL className="my-nav-link cursor-pointer" active={state.activeTab==='10'} onClick={(e)=>activeTabToggle('10')}><span className="my-badge">{state.overallStatus.due_invoice_count}</span> Billing</NavLinkL>
      </NavItem>
      {pdrTab}
    </Nav>
    <Progress animated value={100} style={{height:'30px',fontSize:'15px'}} className="custom-progress custom-progress-blue"><b>{state.appraisal.status}</b></Progress>
    <TabContent activeTab={state.activeTab}>
      <TabPane tabId="1">
        {(state.appraisal.ID&&state.appraisal.ID!==-1&&state.tabHistory.indexOf('1')!==-1)?<AppraisalDetails {...props} appraisal={state.appraisal} messages={state.messages} updateAppraisal={updateAppraisal} updateOverallStatus={updateOverallStatus} getAppraisal={getAppraisal}/>:null}
      </TabPane>
      <TabPane tabId="2">
        {(state.appraisal.ID&&state.appraisal.ID!==-1&&state.tabHistory.indexOf('2')!==-1)?<Assignment appraisalFk={state.appraisal.ID} appraisal={state.appraisal} messages={state.messages} updateAppraisal={updateAppraisal} updateOverallStatus={updateOverallStatus} overallStatus={state.overallStatus} commentID={state.commentID} {...props}/>:null}
      </TabPane>
      <TabPane tabId="3">
        {(state.appraisal.ID&&state.appraisal.ID!==-1&&state.tabHistory.indexOf('3')!==-1)?<CommentTab appraisalFk={state.appraisal.ID} appraisal={state.appraisal} messages={state.messages} updateAppraisal={updateAppraisal} updateOverallStatus={updateOverallStatus} overallStatus={state.overallStatus} commentID={state.commentID} {...props}/>:null}
      </TabPane>
      <TabPane tabId="4">
        {(state.appraisal.ID&&state.appraisal.ID!==-1&&state.tabHistory.indexOf('4')!==-1)?<FeeIncrease appraisalFk={state.appraisal.ID} appraisal={state.appraisal} messages={state.messages} updateAppraisal={updateAppraisal} updateOverallStatus={updateOverallStatus} overallStatus={state.overallStatus} commentID={state.commentID} {...props}/>:null}
      </TabPane>
      <TabPane tabId="5">
        {(state.appraisal.ID&&state.appraisal.ID!==-1&&state.tabHistory.indexOf('5')!==-1)?<QuoteVetting appraisalFk={state.appraisal.ID} appraisal={state.appraisal} messages={state.messages} updateAppraisal={updateAppraisal} updateOverallStatus={updateOverallStatus} overallStatus={state.overallStatus} commentID={state.commentID} {...props}/>:null}
      </TabPane>
      <TabPane tabId="6">
        {(state.appraisal.ID&&state.appraisal.ID!==-1&&state.tabHistory.indexOf('6')!==-1)?<PropertyDetails appraisalFk={state.appraisal.ID} appraisal={state.appraisal} messages={state.messages} updateAppraisal={updateAppraisal} updateOverallStatus={updateOverallStatus} {...props}/>:null}
      </TabPane>
      <TabPane tabId="7">
        {(state.appraisal.ID&&state.appraisal.ID!==-1&&state.tabHistory.indexOf('7')!==-1)?<Review appraisalFk={state.appraisal.ID} appraisal={state.appraisal} messages={state.messages} reviewID={state.reviewID} updateAppraisal={updateAppraisal} updateOverallStatus={updateOverallStatus} overallStatus={state.overallStatus} {...props}/>:null}
      </TabPane>
      <TabPane tabId="8">
        {(state.appraisal.ID&&state.appraisal.ID!==-1&&state.tabHistory.indexOf('8')!==-1)?<Condition appraisalFk={state.appraisal.ID} appraisal={state.appraisal} messages={state.messages} updateAppraisal={updateAppraisal} updateOverallStatus={updateOverallStatus} overallStatus={state.overallStatus} {...props}/>:null}
      </TabPane>
      <TabPane tabId="9">
        {(state.appraisal.ID&&state.appraisal.ID!==-1&&state.tabHistory.indexOf('9')!==-1)?<Rebuttal appraisalFk={state.appraisal.ID} appraisal={state.appraisal} messages={state.messages} updateAppraisal={updateAppraisal} updateOverallStatus={updateOverallStatus} {...props}/>:null}
      </TabPane>
      <TabPane tabId="10">
        {(state.appraisal.ID&&state.appraisal.ID!==-1&&state.tabHistory.indexOf('10')!==-1)?<Billing appraisalFk={state.appraisal.ID} appraisal={state.appraisal} messages={state.messages} auditID={state.auditID} updateAppraisal={updateAppraisal} updateOverallStatus={updateOverallStatus} {...props}/>:null}
      </TabPane>
    </TabContent>
  </div>;
}


export default Appraisal;
