//Author June Leow
//Date Nov 14th, 2023
import React, {useRef} from 'react';

import Appraisal from './appraisal/appraisal';
import Appraisals from './appraisal/appraisals';
import Appraisers from './appraiser/appraisers';
import Appraiser from './appraiser/appraiser';
import AccountManager from './setting/account-manager';
import AccountingTickets from './support/accounting-tickets';
import AccountingTicket from './support/accounting-ticket';
import Admins from './admin/admins';
import Admin from './admin/admin';
import Amc from './appraiser/amc';
import Amcs from './appraiser/amcs';
import Articles from './article/articles';
import Article from './article/article';
import Announcements from './announcement/announcements';
import Announcement from './announcement/announcement';
import AppraiserPresidentClub from './appraiser/president-club';
import AppraiserExpeditePayment from './appraiser/expedite-payment';
import AppraiserExclusionaryList from './appraiser/appraiser-exclusionary-list';
import AppraiserFirms from './appraiser/appraiser-firms';
import AppraiserFirm from './appraiser/appraiser-firm';
import AutoAssignConfiguration from './setting/auto-assign';
import AutoAssignDashboard from './auto-assign/auto-assign-dashboard';
import AutoAssignLenderDashboard from './auto-assign/auto-assign-lender-dashboard';
import AutoAssignCountyDashboard from './auto-assign/auto-assign-county-dashboard';
import AutoAssignCountyPanel from './auto-assign/auto-assign-county-panel';
import AutoAssignAppraiser from './auto-assign/auto-assign-appraiser';
import AutoAssignAppraiserDashboard from './auto-assign/auto-assign-appraiser-dashboard';
import AutoBidConfiguration from './setting/auto-bid';
import AutoConditionConfiguration from './setting/auto-condition';
import AutoShippingConfiguration from './setting/auto-shipping';
import AutoReviewDeliveryDashboard from './dashboard/auto-review-delivery-dashboard';
import AutoReviewDeliveryStatsDashboard from './dashboard/auto-review-delivery-stats-dashboard';
import AppraiserPanelDiscussion from './appraiser/panel-discussion';
import BottomBar from './structure/bottom-bar/bottom-bar';
import BatchNewAppraisal from './appraisal/batch-new-appraisal';
import BrokerRelationshipProfile from './broker-relationship/broker-relationship-profile';
import BrokerRelationshipProfiles from './broker-relationship/broker-relationship-profiles';
import BrokerRelationshipProfileAE from './broker-relationship/broker-relationship-profile-ae';
import BrokerRelationshipProfileCSR from './broker-relationship/broker-relationship-profile-csr';
import BrokerRelationshipProfileBroker from './broker-relationship/broker-relationship-profile-broker';
import BrokerRelationshipProfileLoanOfficer from './broker-relationship/broker-relationship-profile-loan-officer';
import BrokerRelationshipProfileProcessor from './broker-relationship/broker-relationship-profile-processor';
import BrokerRelationshipProfileNew from './broker-relationship/broker-relationship-profile-new';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import config from '../config';
import CRM from './setting/crm';
import Clients from './client/clients';
import Client from './client/client';
import ClientReports from './report/client-report/client-reports';
import ClientBranch from './client/client-branch';
import ClientBranchUser from './client/client-branch-user';
import ClientFee from './fee/client-fee';
import ClientStateFee from './fee/client-state-fee';
import ClientStateOpenByFee from './fee/client-state-open-by-fee';
import ClientProfileChecklist from './setting/client-profile-checklist';
import Conditions from './condition/conditions';
import DashboardSimplify from './dashboard/dashboard-simplify';
import DashboardGlobalSimplify from './dashboard/dashboard-global-simplify';
import DefaultFee from './fee/default-fee';
import DelegatorClients from './client/delegator-clients';
import DelegatorClient from './client/delegator-client';
import DefaultAppraiserFee from './fee/default-appraiser-fee';
import Emails from './setting/emails';
import FileStorage from './file-storage/file-storage';
import ForgotPassword from './login/forgot-password';
import GlobalAppraisals from './appraisal/global-appraisals';
import GlobalConditions from './condition/global-conditions';
import GlobalRebuttals from './rebuttal/global-rebuttals';
import GlobalWholesaleClient from './setting/global-wholesale-client';
import GlobalCommentTemplate from './template/global-comment-template';
import GlobalCommentTemplates from './template/global-comment-templates';
import IntegratedPlatform from './setting/integrated-platform';
import LoadingBar from 'react-redux-loading-bar';
import Login from './login/login';
import {logout} from '../actions/action-login';
import ManualAutoAssignStats from './auto-assign/manual-auto-assign-stats';
import NewAdmin from './admin/new-admin';
import NewArticle from './article/new-article';
import NewAppraisal from './appraisal/new-appraisal';
import NewAppraiser from './appraiser/new-appraiser';
import NewAppraiserFirm from './appraiser/new-appraiser-firm';
import NewAmc from './appraiser/new-amc';
import NewAccountingTicket from './support/new-accounting-ticket';
import NewAnnouncement from './announcement/new-announcement';
import NewClient from './client/new-client';
import NewCondition from './condition/new-condition';
import NewDelegatorClient from './client/new-delegator-client';
import NewStateRegistration from './state-registration/new-state-registration';
import NewTicket from './support/new-ticket';
import NewPolicy from './policy/new-policy';
import NewProcedure from './procedure/new-procedure';
import NewRebuttal from './rebuttal/new-rebuttal';
import Notifications from './notification/notifications';
import NotificationChannel from './setting/notification-channel';
import OrderScreening from './setting/order-screening';
import OpenByClientFee from './fee/client-open-by-fee';
import PersonalSetting from './setting/personal-setting';
import Policies from './policy/policies';
import Policy from './policy/policy';
import Procedures from './procedure/procedures';
import Procedure from './procedure/procedure';
import PropertyDataReport from './property-data-report/property-data-report';
import PropertyDataReports from './property-data-report/property-data-reports';
import PropertyDataReportPDF from './property-data-report/pdf';
import NewPropertyDataReport from './property-data-report/new-property-data-report';
import PostCompletionReport from './property-data-report/post-completion-report';
import Pusher from 'pusher-js';
import {PusherProvider} from './pusher/pusher-context';
import Rebuttals from './rebuttal/rebuttals';
import ResetPassword from './login/reset-password';
import Reports from './report/reports';
import {Route, BrowserRouter as Router, Routes, Navigate} from 'react-router-dom';
import ScrubAppraiserExclusionaryList from './appraiser/scrub-appraiser-exclusionary-list';
import ScrubAppraiserExclusionaryListResult from './appraiser/scrub-appraiser-exclusionary-list-result';
import StateRegistration from './state-registration/state-registration';
import StateRegistrations from './state-registration/state-registrations';
import SideBar from './structure/side-bar/side-bar';
import Support from './support/support';
import SystemSetting from './setting/system-setting';
import Tickets from './support/tickets';
import Ticket from './support/ticket';
import TurnAroundTimeDashboard from './dashboard/tat-dashboard';
import TurnAroundTimeDashboardOverall from './dashboard/tat-overall-dashboard';
import TurnAroundTimeStateDashboard from './dashboard/tat-state-dashboard';
import TurnAroundTimeCityDashboard from './dashboard/tat-county-dashboard';
import TopBar from './structure/top-bar/top-bar';
import WebsiteList from './setting/website-list';
import { ToastContainer} from 'react-toastify';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';


const App  = (props)=>{
  let pusher = new Pusher(config.pusherAppKey,{
    authEndpoint: config.baseAPIUrl + 'session/notification_auth',
    auth:{
      headers: {
        'Authorization': "Authorization: Bearer "+localStorage.getItem('token')
      }
    },
    cluster: config.pusherCluster
  });

  const container = useRef();

  if(props.isLoggedIn){
    return (
      <div>
       <PusherProvider pusher={pusher}>
         <Router>
           <div style={{height:'100%'}}>
             <ToastContainer theme="colored" className="my-toast-container always-on-top"/>
             <LoadingBar style={{zIndex:'99'}}/>
             <BottomBar {...props} userFirstName={props.userFirstName} userLastName={props.userLastName} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
             <div className="container-fluid">
               <div className="row">
                 <div className="col-sm-12 col-md-12 no-padding">
                   <TopBar {...props} userFirstName={props.userFirstName} userLastName={props.userLastName} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
                 </div>
               </div>

               <div className="row page-content">
                 <div className="col-sm-2 col-md-2 no-padding">
                   <SideBar logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc} {...props} isLoggedIn={props.isLoggedIn}/>
                 </div>

                 <div  id="contentContainer" className="col-sm-10 col-md-10 padding content-container" ref={container}>
                   <Routes>
                     <Route
                       exact path="/"
                       element={<div/>}
                     />
                     <Route
                       exact path="/article"
                       element={<Articles {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/article/:id"
                       element={<Article {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/article/new"
                       element={<NewArticle {...props} logout={props.logout} userName={props.userFirstName+' '+props.userLastName} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/appraisal"
                       element={<Appraisals {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/appraisal/new"
                       element={<NewAppraisal {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/appraisal/:id"
                       element={<Appraisal activeTab="1" {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/appraisal/batch-new"
                       element={<BatchNewAppraisal {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/appraisal-global"
                       element={<GlobalAppraisals {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/property-data-report"
                       element={<PropertyDataReports {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/property-data-report/file/:pdrFk"
                       element={<PropertyDataReportPDF {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/property-data-report/new"
                       element={<NewPropertyDataReport {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />  
                     <Route
                       exact path="/post-completion-report/:pdrFk"
                       element={<PostCompletionReport {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/property-data-report/:pdrFk"
                       element={<PropertyDataReport {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/property-details/:id"
                       element={<Appraisal activeTab="6" {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/fee-increase/:id"
                       element={<Appraisal activeTab="4" {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/admin"
                       element={<Admins {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/admin/:id"
                       element={<Admin {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/admin/new"
                       element={<NewAdmin {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/announcement"
                       element={<Announcements {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/announcement/:id"
                       element={<Announcement {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/announcement/new"
                       element={<NewAnnouncement {...props} logout={props.logout} userName={props.userFirstName+' '+props.userLastName} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/amc"
                       element={<Amcs {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/amc/:id"
                       element={<Amc {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                        exact path="/amc/new"
                        element={<NewAmc {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/appraiser/new"
                       element={<NewAppraiser {...props} userName={props.userFirstName+' '+props.userLastName} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/appraiser/new/:params"
                       element={<NewAppraiser {...props} userName={props.userFirstName+' '+props.userLastName} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/appraiser/:id"
                       element={<Appraiser {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/appraiser/:id/:params"
                       element={<Appraiser {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/appraiser-president-club"
                       element={<AppraiserPresidentClub {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/appraiser-expedite-payment"
                       element={<AppraiserExpeditePayment {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/appraiser-panel-discussion"
                       element={<AppraiserPanelDiscussion {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/appraiser-firm"
                       element={<AppraiserFirms {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/appraiser-firm/:id"
                       element={<AppraiserFirm {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/appraiser-firm/new"
                       element={<NewAppraiserFirm {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/appraiser-exclusionary-list"
                       element={<AppraiserExclusionaryList {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/scrub-appraiser-exclusionary-list"
                       element={<ScrubAppraiserExclusionaryList {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/scrub-appraiser-exclusionary-list-result/:id"
                       element={<ScrubAppraiserExclusionaryListResult {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/assignment/:id"
                       element={<Appraisal activeTab="2" {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/billing/:id"
                       element={<Appraisal activeTab="10" {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/billing/:id/audit/:aid"
                       element={<Appraisal activeTab="10" {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/broker-relationship"
                       element={<BrokerRelationshipProfiles {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/broker-relationship/:id"
                       element={<BrokerRelationshipProfile {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/broker-relationship/broker/:id"
                       element={<BrokerRelationshipProfileBroker {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/broker-relationship/ae/:id"
                       element={<BrokerRelationshipProfileAE {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/broker-relationship/csr/:id"
                       element={<BrokerRelationshipProfileCSR {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/broker-relationship/loan-officer/:id"
                       element={<BrokerRelationshipProfileLoanOfficer {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/broker-relationship/processor/:id"
                       element={<BrokerRelationshipProfileProcessor {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                        exact path="/broker-relationship/new"
                        element={<BrokerRelationshipProfileNew {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                     <Route
                       exact path="/client"
                       element={<Clients {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/client/:params"
                       element={<Clients {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/client/:id"
                       element={<Client {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/client/:id/:params"
                       element={<Client {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/client/:clientFk/branch/:id"
                       element={<ClientBranch {...props} userFirstName={props.userFirstName} userLastName={props.userLastName} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/client/:clientFk/:params/branch/:id"
                       element={<ClientBranch {...props} userFirstName={props.userFirstName} userLastName={props.userLastName} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/client/:clientFk/branch/:branchFk/user/:id"
                       element={<ClientBranchUser {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/client/:clientFk/:params/branch/:branchFk/user/:id"
                       element={<ClientBranchUser {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/client/new"
                       element={<NewClient {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/client/delegator"
                       element={<DelegatorClients {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/client/delegator/:id"
                       element={<DelegatorClient {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/client/delegator/new"
                       element={<NewDelegatorClient {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/client-report"
                       element={<ClientReports {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/comment/:commentID/:id"
                       element={<Appraisal activeTab="3" {...props} userName={props.userFirstName+' '+props.userLastName} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/comment/:id"
                       element={<Appraisal activeTab="3" {...props} userName={props.userFirstName+' '+props.userLastName} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/condition-global"
                       element={<GlobalConditions {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/condition"
                       element={<Conditions {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/condition/:id"
                       element={<Appraisal activeTab="8" {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/condition/new/:id"
                       element={<NewCondition {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/dashboard"
                       element={<DashboardSimplify {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/dashboard-global"
                       element={<DashboardGlobalSimplify {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/dashboard-tat-overall"
                       element={<TurnAroundTimeDashboardOverall {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/dashboard-tat-state/:from/:to/:client/:filterAppraisalTypes/:accountManagerFk"
                       element={<TurnAroundTimeDashboard {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/dashboard-tat-county/:stateName/:from/:to/:client/:filterAppraisalTypes/:accountManagerFk"
                       element={<TurnAroundTimeStateDashboard {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/dashboard-tat-city/:stateName/:county/:from/:to/:client/:filterAppraisalTypes/:accountManagerFk"
                       element={<TurnAroundTimeCityDashboard {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/auto-review-delivery"
                       element={<AutoReviewDeliveryDashboard {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/auto-review-delivery-stats"
                       element={<AutoReviewDeliveryStatsDashboard {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/global-comment-template"
                       element={<GlobalCommentTemplates {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/global-comment-template/:id"
                       element={<GlobalCommentTemplate {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/manual-auto-assign-stats"
                       element={<ManualAutoAssignStats {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                        exact path="/auto-assign"
                        element={<AutoAssignDashboard {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                      <Route
                        exact path="/auto-assign/client/:clientFk/:from/:to"
                        element={<AutoAssignLenderDashboard {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                      <Route
                        exact path="/auto-assign/county/:clientFk/:stateName/:county/:from/:to"
                        element={<AutoAssignCountyDashboard {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                      <Route
                        exact path="/auto-assign/appraiser/:clientFk/:appraiser/:from/:to"
                        element={<AutoAssignAppraiserDashboard {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                      <Route
                        exact path="/auto-assign/panel/:stateName/:county/:id"
                        element={<AutoAssignAppraiser {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                      <Route
                        exact path="/auto-assign/panel/:clientFk/:stateName/:county/:from/:to"
                        element={<AutoAssignCountyPanel {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                     <Route
                       exact path="/report"
                       element={<Reports {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/report/:report/:params"
                       element={<Reports {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/report/:report"
                       element={<Reports {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/rebuttal-global"
                       element={<GlobalRebuttals {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/rebuttal"
                       element={<Rebuttals {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/rebuttal/:id"
                       element={<Appraisal activeTab="9" {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/rebuttal/new/:id"
                       element={<NewRebuttal {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/quote/:id"
                       element={<Appraisal activeTab="5" {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/review/:id"
                       element={<Appraisal activeTab="7" {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/review/:id/:reviewId"
                       element={<Appraisal activeTab="7" {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                        exact path="/notification"
                        element={<Notifications {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                     <Route
                       exact path="/appraisers"
                       element={<Appraisers {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/appraisers/:params"
                       element={<Appraisers {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/policy"
                       element={<Policies {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/policy/:id"
                       element={<Policy {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/policy/new"
                       element={<NewPolicy {...props} logout={props.logout} userName={props.userFirstName+' '+props.userLastName} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/procedure"
                       element={<Procedures {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/procedure/:id"
                       element={<Procedure {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/procedure/new"
                       element={<NewProcedure {...props} logout={props.logout} userName={props.userFirstName+' '+props.userLastName} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/file-storage"
                       element={<FileStorage {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/setting"
                       element={<PersonalSetting {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/system-setting"
                       element={<SystemSetting {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/system-setting/account-manager"
                       element={<AccountManager {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/system-setting/auto-assign"
                       element={<AutoAssignConfiguration {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/system-setting/auto-bid"
                       element={<AutoBidConfiguration {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/system-setting/auto-condition"
                       element={<AutoConditionConfiguration {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/system-setting/order-screening"
                       element={<OrderScreening {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/system-setting/crm"
                       element={<CRM {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/system-setting/auto-shipping"
                       element={<AutoShippingConfiguration {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/system-setting/global-wholesale-client"
                       element={<GlobalWholesaleClient {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/system-setting/integrated-platform"
                       element={<IntegratedPlatform {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/system-setting/emails"
                       element={<Emails {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/system-setting/website-list"
                       element={<WebsiteList {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/system-setting/client-profile-checklist"
                       element={<ClientProfileChecklist {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/system-setting/fee"
                       element={<DefaultFee {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/system-setting/fee/client/:clientFk"
                       element={<ClientFee {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/system-setting/fee/client/:clientFk/:stateName"
                       element={<ClientStateFee {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/system-setting/fee/client/:clientFk/openBy/:openBy"
                       element={<OpenByClientFee {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/system-setting/fee/client/:clientFk/openBy/:openBy/:stateName"
                       element={<ClientStateOpenByFee {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/system-setting/appraiser-fee"
                       element={<DefaultAppraiserFee {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                        exact path="/setting/channel/:id"
                        element={<NotificationChannel {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                      />
                     <Route
                       exact path="/support"
                       element={<Support {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/support/ticket"
                       element={<Tickets {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/support/accounting/ticket"
                       element={<AccountingTickets {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/support/new"
                       element={<NewTicket {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/support/accounting/new"
                       element={<NewAccountingTicket {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/support/accounting/ticket/:id"
                       element={<AccountingTicket {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/support/ticket/:id"
                       element={<Ticket {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/state-registration"
                       element={<StateRegistrations {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                      <Route
                       exact path="/state-registration/new"
                       element={<NewStateRegistration {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                     <Route
                       exact path="/state-registration/:id"
                       element={<StateRegistration {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
                     />
                   </Routes>
                 </div>
               </div>
             </div>
           </div>
         </Router>
       </PusherProvider>
     </div>
   );
  }
  else{
    return (
      <div>
        <ToastContainer theme="colored" className="my-toast-container always-on-top"/>
        <LoadingBar style={{zIndex:'99'}}/>
        <Router>
          <div id="contentContainer" className="content-container" ref={container}>
            <Routes>
              <Route
                exact path="/forgot-password"
                element={<ForgotPassword {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
              />
              <Route
                exact path="/reset-password/:email/:token"
                element={<ResetPassword {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
              />
              <Route
                path="/"
                element={<Login {...props} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>}
              />
              <Route path="*" element={<Navigate to="/" replace />}/>
            </Routes>
          </div>
        </Router>
      </div>
    );
  }
}

function mapStateToProps(state){
  return {
    jwtToken: state.session.jwtToken,
    isLoggedIn: state.session.isLoggedIn,
    userRole: state.session.userRole,
    userFirstName: state.session.userFirstName,
    userLastName: state.session.userLastName
  };
}

function matchDispatchToProps(dispatch){
  return bindActionCreators({
    logout: logout,
    dispatchShowLoadingFunc: ()=>{return showLoading()},
    dispatchHideLoadingFunc: ()=>{return hideLoading()},
  },dispatch);
}

export default connect(mapStateToProps,matchDispatchToProps)(App);
