//Author Sooyoung Kim
//Date April 25, 2023
import {getReducer, getSetStateFunction, postAPICallGenerator} from '../../util/util';
import {Button, Col, Row, Input} from 'reactstrap';
import {NavLink, useNavigate} from 'react-router-dom';
import MySelect from '../util/my-select';
import React, {useReducer, useEffect} from 'react';


//initialize the state
const initialState = {
  firstName:'',
  lastName:'',
  email:'',
  role:'admin',
  phone:'',
  password:'',
  password2:'',
  errorMessage:''
};

//reducer function that perform state update
const reducer = getReducer();


const NewAdmin  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);
  const navigate = useNavigate();
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{


    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const onPassword1Change = (val)=>{
    setState({password:val});
    verifyPassword(val, state.password2);
  }

  const onPassword2Change = (val)=>{
    setState({password2:val});
    verifyPassword(state.password, val);
  }

  const verifyPassword = (password, password2)=>{
    if(password.length<8)
      setState({errorMessage:'Your password is too short.'});
    else if(password!==password2)
      setState({errorMessage:'Your password not match.'});
    else
      setState({errorMessage:''});
  }

  const submitForm = (e)=>{
    e.preventDefault();console.log(state);
    if(state.errorMessage==='')
      createNewAdmin();
  }

  //API call
  const createNewAdmin = () =>{
    let url = 'admin/create';
    let successMsg = 'Admin profile created successfully.';
    let errorMsg = 'Oops, something went wrong and could not create the admin profile. Please try again later.';

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        navigate('/admin');
      }
    };
    callBack = callBack.bind(this);

    let parameters =[
      {
        field:'firstName',
        value:state.firstName
      },
      {
        field:'lastName',
        value:state.lastName
      },
      {
        field:'email',
        value:state.email
      },
      {
        field:'password',
        value:state.password
      },
      {
        field:'phone',
        value:state.phone
      },
      {
        field:'role',
        value:state.role
      }
    ];

    httpPost(url,parameters,successMsg,errorMsg, callBack);
  }

  //render
  return <div className="my-well">
    <Row>
      <Col sm="8">
        <div className="page-title">
          <i className="fa fa-reorder"></i>&nbsp;New admin
        </div>
        <NavLink to="/admin">Back to admin list</NavLink>
      </Col>
      <Col sm="4">

      </Col>
    </Row>
    <div className="my-divider"></div>
    <form onSubmit={submitForm}>
      <Row>
        <Col sm="4">
          <label><font color="red">*</font>First name</label>
          <Input type="text" required="true" value={state.firstName} onChange={(e)=>setState({firstName:e.target.value})}/>
        </Col>
        <Col sm="4">
          <label><font color="red">*</font>Last name</label>
          <Input type="text" required="true" value={state.lastName} onChange={(e)=>setState({lastName:e.target.value})}/>
        </Col>
      </Row>
      <br/>
      <br/>
      <div style={{minHeight:'30px'}}>
        <font color="red">{state.errorMessage}</font>
      </div>
      <Row>
        <Col sm="12">
          <label><font color="red">*</font>Email</label>
          <Input type="text" required="true" value={state.email} onChange={(e)=>setState({email:e.target.value})}/>
        </Col>
        <Col sm="4">
          <label><font color="red">*</font>Password</label>
          <Input type="password" required="true" value={state.password} onChange={(e)=>onPassword1Change(e.target.value)}/>
        </Col>
        <Col sm="4">
          <label><font color="red">*</font>Verify password</label>
          <Input type="password" required="true" value={state.password2} onChange={(e)=>onPassword2Change(e.target.value)}/>
        </Col>
      </Row>
      <br/>
      <br/>
      <Row>
        <Col sm="4">
          <label>Role</label>
          <MySelect
            type="select"
            value={state.role}
            onChange={(v)=>{console.log(v);setState({role:v})}}
            options={[{label:"Admin",value:"admin"},{label:"Admin Manager",value:"admin_manager"},{label:"Super Admin",value:"super_admin"}]}
            />
        </Col>
        <Col sm="4">
          <label>Phone</label>
          <Input type="text" value={state.phone} onChange={(e)=>setState({phone:e.target.value})}/>
        </Col>
      </Row>
      <div className="my-divider"></div>
      <br/>
      <div className="align-right">
        <Button color="warning">Create</Button>
      </div>
    </form>
  </div>;
}

export default NewAdmin;
