//Author Sooyoung Kim
//Date Aug 21, 2023
import {showMessage, generateTooltip} from '../../util/util';
import {Card, CardHeader, CardBody, Col, Row, Input} from 'reactstrap';
import React, {useEffect} from 'react';
import MyDropzone from '../util/my-dropzone';
import MySelect from '../util/my-select';
import {deletePhoto} from './util';

const InteriorRoom  = (props)=>{
  console.log(props);
  const controller = new AbortController();

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const onDrop = (acceptedFiles, rejectedFiles, field, type='Misc', typeFk=0, description='') => {
    let existingFiles = [];
    if(acceptedFiles.length>0){
      for(let i=0;i<acceptedFiles.length;i++){
        existingFiles.push(acceptedFiles[i]);
        let name = existingFiles[i].name;
        let token = name.split('.');
        let extension = '';

        if(token.length>0){
          extension = token[token.length-1];
          extension = extension.toLowerCase();
        }

        if(extension!=='png'&&extension!=='jpg'&&extension!=='jpeg'){
          showMessage('error','Please upload only PNG/JPG image.');
        }
        else{
          const reader = new FileReader();
          reader.onload = () => {
              const fileAsBinaryString = reader.result;
              let base64 = btoa(fileAsBinaryString);
              let interiors = props.interiors.slice();
              let target = interiors[props.index];

              target[field].push({name:acceptedFiles[i].name, base64:base64, status:'Pending', size:acceptedFiles[i].size, type:type, typeFk:typeFk, description:description});

              interiors[props.index] = target;
              props.updateState({interiors:interiors});
          }
          reader.readAsBinaryString(acceptedFiles[i]);
        }
      }
    }
  }

  const removeFile = (field,index) => {
    let interiors = props.interiors.slice();
    let target = interiors[props.index];
    let targetToDelete = target[field][index];

    if(targetToDelete.ID && targetToDelete.ID!==-1){
      let successCallBack = ()=>{
        target[field].splice(index, 1);
        interiors[props.index] = target;
        props.updateState({interiors:interiors});
      };
      successCallBack = successCallBack.bind(this);
      deletePhoto(targetToDelete.ID, props, successCallBack);
    }
    else{
      target[field].splice(index, 1);
      interiors[props.index] = target;
      props.updateState({interiors:interiors});
    }
  }

  const removeField = (field, value) => {
    if(!props.disabled){
      let interiors = props.interiors.slice();
      let target = interiors[props.index];
      let index = target[field].indexOf(value);

      if(index!==-1)
        target[field].splice(index, 1);

      interiors[props.index] = target;
      props.updateState({interiors:interiors});
    }
  }

  const updateField = (field, value) => {
    let interiors = props.interiors.slice();
    let target = interiors[props.index];
    if(value==='None'){
      target[field] = ['None'];
    }
    else if(value!==''){
      let index = target[field].indexOf('None');

      if(index!==-1)
        target[field].splice(index, 1);

      index = target[field].indexOf(value);

      if(index===-1)
        target[field].push(value);
    }
    interiors[props.index] = target;

    props.updateState({interiors:interiors});
  }

  const updateState = (field, value) => {
    let interiors = props.interiors.slice();
    let target = interiors[props.index];

    target[field] = value;

    interiors[props.index] = target;

    props.updateState({interiors:interiors});
  }

  //render
  let disabledStyling = {border:'none', background:'#fff', padding:'0',paddingLeft:'2px', height:'auto',WebkitAppearance:'none'};
  let floorMaterials;

  if(props.interiors[props.index].floorMaterials.length>0)
    floorMaterials = props.interiors[props.index].floorMaterials.map(
      (floorMaterial, index)=>{
        return(
          <div key={index} className="display-inline margin-top">
            <div className="filter-container" onClick={()=>removeField('floorMaterials', floorMaterial)}>
              <i hidden={props.disabled} className="fa fa-minus red-color"></i> {floorMaterial}
            </div>
            &nbsp;
          </div>
        );
      }
    )

  let wallAndCeilingMaterials;

  if(props.interiors[props.index].wallAndCeilingMaterials.length>0)
    wallAndCeilingMaterials = props.interiors[props.index].wallAndCeilingMaterials.map(
      (wallAndCeilingMaterial, index)=>{
        return(
          <div key={index} className="display-inline margin-top">
            <div className="filter-container" onClick={()=>removeField('wallAndCeilingMaterials', wallAndCeilingMaterial)}>
              <i hidden={props.disabled} className="fa fa-minus red-color"></i> {wallAndCeilingMaterial}
            </div>
            &nbsp;
          </div>
        );
      }
    )

  let floorMaterialConditions;

  if(props.interiors[props.index].floorMaterialConditions.length>0)
    floorMaterialConditions = props.interiors[props.index].floorMaterialConditions.map(
      (floorMaterialCondition, index)=>{
        return(
          <div key={index} className="display-inline margin-top">
            <div className="filter-container" onClick={()=>removeField('floorMaterialConditions', floorMaterialCondition)}>
              <i hidden={props.disabled} className="fa fa-minus red-color"></i> {floorMaterialCondition}
            </div>
            &nbsp;
          </div>
        );
      }
    )

  let wallAndCeilingMaterialConditions;

  if(props.interiors[props.index].wallAndCeilingMaterialConditions.length>0)
    wallAndCeilingMaterialConditions = props.interiors[props.index].wallAndCeilingMaterialConditions.map(
      (wallAndCeilingMaterialCondition, index)=>{
        return(
          <div key={index} className="display-inline margin-top">
            <div className="filter-container" onClick={()=>removeField('wallAndCeilingMaterialConditions', wallAndCeilingMaterialCondition)}>
              <i hidden={props.disabled} className="fa fa-minus red-color"></i> {wallAndCeilingMaterialCondition}
            </div>
            &nbsp;
          </div>
        );
      }
    )
  let removeIcon;

  if(props.interiors[props.index].removable && !props.disabled)
    removeIcon = <i hidden={props.disabled} className="fa fa-times red-color cursor-pointer" onClick={(e)=>props.removeRoom('interiors', props.index)}/>;

    
  return <div>
    <Card>
      <CardHeader className="header-color">
        {removeIcon} {props.interiors[props.index].name}
      </CardHeader>
      <CardBody>
        <Row style={{marginTop:'15px'}}>
          <Col sm="4">
            <label><font color="red">*</font>Type</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={true}
              value={props.interiors[props.index].type}
              onChange={(v)=>updateState('type',v)}
              options={[
                {label:"",value:""},
                {label:"Bedroom",value:"Bedroom"},
                {label:"Dining Room",value:"DiningRoom"},
                {label:"Laundry Room",value:"LaundryRoom"},
                {label:"Living Room",value:"LivingRoom"},
                {label:"Media Room",value:"MediaRoom"},
                {label:"Mud Room",value:"MudRoom"},
                {label:"Office",value:"Office"},
                {label:"Foyer",value:"Foyer"},
                {label:"Den",value:"Den"},
                {label:"Other",value:"Other"},
                {label:"Recreation",value:"Recreation"},
                {label:"Family Room",value:"FamilyRoom"},
              ]}
            />
          </Col>
          <Col sm="6">
            <label>Room name</label>
            <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} type="text" value={props.interiors[props.index].name} onChange={(e)=>{updateState('name', e.target.value)}}/>
          </Col>
          <Col sm="2">
            <label><font color="red">*</font>Grade</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={true}
              value={props.interiors[props.index].grade}
              onChange={(v)=>updateState('grade',v)}
              options={[
                {label:"",value:""},
                {label:"Above Grade",value:"above"},
                {label:"Below Grade",value:"below"},
              ]}
            />
          </Col>
        </Row>
        <Row style={{marginTop:'15px'}}>
          <Col sm="12">
            <label><font color="red">*</font>Room Photos</label>
            <br/>
            <MyDropzone hidden={props.disabled} onDrop={(accepted, rejected)=>{onDrop(accepted, rejected, 'roomPhotos', 'Room Photo', props.interiors[props.index].ID)}} noIcon={true}/>
            {
              props.interiors[props.index].roomPhotos.map(
                (photo, index)=>{
                  return <div key={index} className="display-inline" style={{width:200, height:250, margin:5}}>
                    <div>
                      <i hidden={props.disabled} className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeFile('roomPhotos',index)}/> {photo.name.length>16?photo.name.substr(0,16)+'...':photo.name}
                    </div>
                    <img alt="pic" width="200" height="200" src={photo.status==='Pending'?"data:image/png;base64, "+photo.base64:photo.presign_url}/>
                  </div>
                }
              )
            }
          </Col>
        </Row>
        <br/>
        <Row style={{marginTop:'15px'}}>
          <Col sm="6">
            <Row style={{marginTop:'15px'}}>
              <Col sm="6">
                <label>{(props.structureType==='PrimaryDwelling'||props.structureType==='ADU')?<font color="red">*</font>:null}Interior Floors Material {generateTooltip('floorMaterials'+props.index, 'Select all that apply.')}</label>
                <MySelect
                  type="select"
                  style={props.disabled?disabledStyling:{}}
                  disabled={props.disabled}
                  required={(props.structureType==='PrimaryDwelling'||props.structureType==='ADU') && props.interiors[props.index].floorMaterials.length<=0}
                  value={props.interiors[props.index].floorMaterials}
                  onChange={(v)=>updateField('floorMaterials',v)}
                  options={[
                    {label:"",value:""},
                    {label:"Hardwood",value:"Hardwood"},
                    {label:"Carpet",value:"Carpet"},
                    {label:"Tile",value:"Tile"},
                    {label:"Vinyl",value:"Vinyl"},
                    {label:"Laminate",value:"Laminate"},
                    {label:"Finished Concrete",value:"FinishedConcrete"},
                    {label:"Unfinished Concrete",value:"UnfinishedConcrete"},
                    {label:"Other Wood Product",value:"OtherWoodProduct"},
                    {label:"Other Composite Product",value:"OtherCompositeProduct"},
                    {label:"Stone",value:"Stone"},
                  ]}
                />
                <div className="extra-small-scroll-container">
                  {floorMaterials}
                </div>
              </Col>
              <Col sm="6">
                <label>{(props.structureType==='PrimaryDwelling'||props.structureType==='ADU')?<font color="red">*</font>:null}Interior Floors Material Condition {generateTooltip('floorMaterialConditions'+props.index, 'Select all that apply.')}</label>
                <MySelect
                  type="select"
                  style={props.disabled?disabledStyling:{}}
                  disabled={props.disabled}
                  required={(props.structureType==='PrimaryDwelling'||props.structureType==='ADU') && props.interiors[props.index].floorMaterialConditions.length<=0}
                  value={props.interiors[props.index].floorMaterialConditions}
                  onChange={(v)=>updateField('floorMaterialConditions',v)}
                  options={[
                    {label:"",value:""},
                    {label:"None",value:"None"},
                    {label:"Buckled",value:"Buckled"},
                    {label:"Holes",value:"Holes"},
                    {label:"Sagging",value:"Sagging"},
                    {label:"Spongy",value:"Spongy"},
                    {label:"Infestation",value:"Infestation"},
                    {label:"Dry Rot",value:"Dry Rot"},
                    {label:"Discloration",value:"discloration"},
                    {label:"Cracks",value:"cracks"},
                    {label:"Unfinished Section",value:"unfinished section"},
                    {label:"Dampness",value:"dampness"},
                  ]}
                />
                <div className="extra-small-scroll-container">
                  {floorMaterialConditions}
                </div>
              </Col>
            </Row>
            <Row style={{marginTop:'15px'}}>
              <Col sm="6">
                <label>{(props.structureType==='PrimaryDwelling'||props.structureType==='ADU')?<font color="red">*</font>:null}Interior Floors Updates</label>
                <MySelect
                  type="select"
                  style={props.disabled?disabledStyling:{}}
                  disabled={props.disabled}
                  required={(props.structureType==='PrimaryDwelling'||props.structureType==='ADU')}
                  value={props.interiors[props.index].floorUpdates}
                  onChange={(v)=>updateState('floorUpdates',v)}
                  options={[
                    {label:"",value:""},
                    {label:"Fully Updated",value:"Fully Updated"},
                    {label:"Partially Updated",value:"Partially Updated"},
                    {label:"No Update",value:"No Update"},
                  ]}
                />
              </Col>
              <Col sm="6">
                <label>{(props.interiors[props.index].floorUpdates==='Fully Updated'||props.interiors[props.index].floorUpdates==='Partially Updated')?<font color="red">*</font>:null}Interior Floors Update Description</label>
                <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={(props.interiors[props.index].floorUpdates==='Fully Updated'||props.interiors[props.index].floorUpdates==='Partially Updated')} type="text" value={props.interiors[props.index].floorUpdateDescription} onChange={(e)=>{updateState('floorUpdateDescription',e.target.value)}}/>
              </Col>
            </Row>
            <br/>
            <Row style={{marginTop:'15px'}}>
              <Col sm="12">
                {!props.disabled && props.interiors[props.index].floorMaterialConditions.length>0 && props.interiors[props.index].floorMaterialConditions.indexOf('None')===-1? <div><label className="flashit"><font color="red">Please upload photo of the floor condition(s)</font></label><br/></div>:null}
                <label>{props.interiors[props.index].floorMaterialConditions.indexOf('None')===-1?<font color="red">*</font>:null}Interior Floors Photos</label>
                <br/>
                <MyDropzone hidden={props.disabled} onDrop={(accepted, rejected)=>{onDrop(accepted, rejected, 'floorMaterialPhotos', 'Room Floor', props.interiors[props.index].ID)}} noIcon={true}/>
                {
                  props.interiors[props.index].floorMaterialPhotos.map(
                    (photo, index)=>{
                      console.log(photo);
                      return <div key={index} className="display-inline" style={{width:200, height:250, margin:5}}>
                        <div>
                          <i hidden={props.disabled} className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeFile('floorMaterialPhotos',index)}/> {photo.name.length>16?photo.name.substr(0,16)+'...':photo.name}
                        </div>
                        <img alt="pic" width="200" height="200" src={photo.status==='Pending'?"data:image/png;base64, "+photo.base64:photo.presign_url}/>
                      </div>
                    }
                  )
                }
              </Col>
            </Row>
          </Col>
          <Col sm="6" style={{borderLeft:'1px solid #d2d2d2'}}>
            <Row style={{marginTop:'15px'}}>
              <Col sm="6">
                <label>{(props.structureType==='PrimaryDwelling'||props.structureType==='ADU')?<font color="red">*</font>:null}Interior Wall & Ceiling Material {generateTooltip('wallAndCeilingMaterials'+props.index, 'Select all that apply.')}</label>
                <MySelect
                  type="select"
                  style={props.disabled?disabledStyling:{}}
                  disabled={props.disabled}
                  required={(props.structureType==='PrimaryDwelling'||props.structureType==='ADU') && props.interiors[props.index].wallAndCeilingMaterials.length<=0}
                  value={props.interiors[props.index].wallAndCeilingMaterials}
                  onChange={(v)=>updateField('wallAndCeilingMaterials',v)}
                  options={[
                    {label:"",value:""},
                    {label:"Drywall",value:"Drywall"},
                    {label:"Plaster",value:"Plaster"},
                    {label:"Wood",value:"Wood"},
                    {label:"Concrete",value:"Concrete"},
                    {label:"Brick",value:"Brick"},
                    {label:"Metal",value:"Metal"},
                    {label:"None",value:"None"},
                  ]}
                />
                <div className="extra-small-scroll-container">
                  {wallAndCeilingMaterials}
                </div>
              </Col>
              <Col sm="6">
                <label>{(props.structureType==='PrimaryDwelling'||props.structureType==='ADU')?<font color="red">*</font>:null}Interior Wall & Ceiling Material Condition {generateTooltip('wallAndCeilingMaterialConditions'+props.index, 'Select all that apply.')}</label>
                <MySelect
                  type="select"
                  style={props.disabled?disabledStyling:{}}
                  disabled={props.disabled}
                  required={(props.structureType==='PrimaryDwelling'||props.structureType==='ADU') && props.interiors[props.index].wallAndCeilingMaterialConditions.length<=0}
                  value={props.interiors[props.index].wallAndCeilingMaterialConditions}
                  onChange={(v)=>updateField('wallAndCeilingMaterialConditions',v)}
                  options={[
                    {label:"",value:""},
                    {label:"None",value:"None"},
                    {label:"Discloration",value:"Discloration"},
                    {label:"Missing",value:"Missing"},
                    {label:"Crumbling",value:"Crumbling"},
                    {label:"Water Damage",value:"water damage"},
                  ]}
                />
                <div className="extra-small-scroll-container">
                  {wallAndCeilingMaterialConditions}
                </div>
              </Col>
            </Row>
            <Row style={{marginTop:'15px'}}>
              <Col sm="6">
                <label>{(props.structureType==='PrimaryDwelling'||props.structureType==='ADU')?<font color="red">*</font>:null}Interior Wall & Ceiling Updates</label>
                <MySelect
                  type="select"
                  style={props.disabled?disabledStyling:{}}
                  disabled={props.disabled}
                  required={(props.structureType==='PrimaryDwelling'||props.structureType==='ADU')}
                  value={props.interiors[props.index].wallAndCeilingUpdates}
                  onChange={(v)=>updateState('wallAndCeilingUpdates',v)}
                  options={[
                    {label:"",value:""},
                    {label:"Fully Updated",value:"Fully Updated"},
                    {label:"Partially Updated",value:"Partially Updated"},
                    {label:"No Update",value:"No Update"},
                  ]}
                />
              </Col>
              <Col sm="6">
                <label>{(props.interiors[props.index].wallAndCeilingUpdates==='Fully Updated'||props.interiors[props.index].wallAndCeilingUpdates==='Partially Updated')?<font color="red">*</font>:null}Interior Wall & Ceiling Update Description</label>
                <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={(props.interiors[props.index].wallAndCeilingUpdates==='Fully Updated'||props.interiors[props.index].wallAndCeilingUpdates==='Partially Updated')} type="text" value={props.interiors[props.index].wallAndCeilingUpdateDescription} onChange={(e)=>{updateState('wallAndCeilingUpdateDescription',e.target.value)}}/>
              </Col>
            </Row>
            <br/>
            <Row style={{marginTop:'15px'}}>
              <Col sm="12">
                {!props.disabled && props.interiors[props.index].wallAndCeilingMaterialConditions.length>0 && props.interiors[props.index].wallAndCeilingMaterialConditions.indexOf('None')===-1? <div><label className="flashit"><font color="red">Please upload photo of the floor condition(s)</font></label><br/></div>:null}
                <label>{props.interiors[props.index].wallAndCeilingMaterialConditions.indexOf('None')===-1?<font color="red">*</font>:null}Interior Wall & Ceiling Photos</label>
                <br/>
                <MyDropzone hidden={props.disabled} onDrop={(accepted, rejected)=>{onDrop(accepted, rejected, 'wallAndCeilingMaterialPhotos', 'Room Wall Ceiling', props.interiors[props.index].ID)}} noIcon={true}/>
                {
                  props.interiors[props.index].wallAndCeilingMaterialPhotos.map(
                    (photo, index)=>{
                      return <div key={index} className="display-inline" style={{width:200, height:250, margin:5}}>
                        <div>
                          <i hidden={props.disabled} className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeFile('wallAndCeilingMaterialPhotos',index)}/> {photo.name.length>16?photo.name.substr(0,16)+'...':photo.name}
                        </div>
                        <img alt="pic" width="200" height="200" src={photo.status==='Pending'?"data:image/png;base64, "+photo.base64:photo.presign_url}/>
                      </div>
                    }
                  )
                }
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  </div>;
}


export default InteriorRoom;
