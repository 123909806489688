//Author Sooyoung Kim
//Date Sep 15, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, confirmation, generateSID, showMessage} from '../../util/util';
import {Button, Row, Col, Input, Table} from 'reactstrap';
import MySelect from '../util/my-select';
import TextArea from './text-area';
import React, {useReducer, useEffect, useRef} from 'react';

//initialize the state
const initialState = {
  commentForQuoteVetting:'',
  extraOpsRecipients:[],
  targetRecipients:[],
  extraRecipients:'',
};

//reducer function that perform state update
const reducer = getReducer();


const AssignmentVet  = (props)=>{
  const controller = new AbortController();
  const newsid = useRef(generateSID());
  let newInitialState = Object.assign({}, initialState, {
    vetDisabled:props.vetDisabled?props.vetDisabled:false,
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const handleRecipientChange = (e) => {
    setState({ extraRecipients:e.target.value });
  }

  const handleAdminChange = (selectedOption) => {
    setState({ extraOpsRecipients:selectedOption });
  }

  const addNewRecipientsBorrower = () => {
    setState({extraRecipients:props.appraisal.borrower_email});
  }

  const handleChangeQuoteInput = (v) => {
    setState({commentForQuoteVetting:v});
  }

  //add all associated entity in this appraisal order to the target recipients
  const addAllToTargetRecipients = () => {
    let allRecipients = props.entities.slice();
    let tmp = [];
    for(let i=0;i<allRecipients.length;i++){
      if(allRecipients[i].entity_email==='' && allRecipients[i].entity_label!=='API Integration'){
        showMessage('error','Contact '+allRecipients[i].entity_name+' does not has email, notification wont be able to reach.');
      }
      else{
        tmp.push(allRecipients[i]);
      }
    }
    setState({targetRecipients:tmp});
  }

  const addNewRecipients = (ID, extraRecipientsID, target = state.targetRecipients) => {
    console.log(ID);
    let targetLabel ='';
    if(ID==='All')
      addAllToTargetRecipients();
    else{
      for(let i =0;i<target.length;i++){
        if(target[i].ID===ID)
          return;
      }
      let existingNewTargetRecipients = target.slice();

      let targetEntity = null;
      for(let i=0;i<props.entities.length;i++){
        if(props.entities[i].ID===ID){
          targetEntity = props.entities[i];
          targetLabel = props.entities[i].entity_label;
          break;
        }
      }
      if(targetEntity){
        //check if entity email is empty
        if(targetEntity.entity_email==='' && targetEntity.entity_label!=='API Integration'){
          showMessage('error','Contact '+targetEntity.entity_name+' does not has email, notification wont be able to reach.');
        }
        else{
          existingNewTargetRecipients.push(targetEntity);
          let apiIntegrationID = -1;
          let extraID = [];
          if(targetLabel==='Loan Officer'||targetLabel==='Loan Processor'){
            //look for API Integration and add it
            if(props.appraisal.client_name.includes('Finance of America Mortgage')){
              for(let i=0;i<props.entities.length;i++){
                if(props.entities[i].entity_label==='API Integration'){
                  apiIntegrationID = props.entities[i].ID;
                }else if(props.entities[i].entity_label==='AE' || props.entities[i].entity_label==='CSR'){
                  if(target.filter(e => e.ID === props.entities[i].ID).length <= 0){
                    extraID.push(props.entities[i].ID);
                  }
                }
              }
            }else{
              for(let i=0;i<props.entities.length;i++){
                if(props.entities[i].entity_label==='API Integration'){
                  apiIntegrationID = props.entities[i].ID;
                  break;
                }
              }
            }
          }

          setState({targetRecipients:existingNewTargetRecipients})

          if(apiIntegrationID!==-1){
            addNewRecipients(apiIntegrationID, extraID, existingNewTargetRecipients);
          }
          if(extraRecipientsID.length > 0){
            addMultipleRecipients(extraRecipientsID, existingNewTargetRecipients);
          }
        }
      }
    }
  }

  const addMultipleRecipients = (extraID, target = state.targetRecipients) => {
    let existingNewTargetRecipients = target.slice();

    for(let i=0;i<extraID.length;i++){
      for(let j=0;j<props.entities.length;j++){
        if(props.entities[j].ID===extraID[i]){
          existingNewTargetRecipients.push(props.entities[j]);
        }
      }
    }

    setState({targetRecipients:existingNewTargetRecipients});
  }

  //remove a specific target recipient from the list of recipients that will receive the comment
  const removeFromTargetRecipients = (id) => {
    for(let i =0;i<state.targetRecipients.length;i++){
      if(state.targetRecipients[i].ID===id){
        let newTargetRecipients = state.targetRecipients.slice();
        newTargetRecipients.splice(i,1);

        setState({targetRecipients:newTargetRecipients});
      }
    }
  }

  const vetButtonHandler = () => {
    if(state.vetDisabled)
      return;

    setState({vetDisabled:true});
    startVetting();
  }

  // API call
  const getAdmin = (keyword) => {
    if(keyword&&keyword!==''){
      let SID = generateSID();
      newsid.current = SID;

      keyword = encodeURIComponent(keyword.replace(/\//g, '%ForwardSlash'));

      let callBack = (response)=>{
        if(SID===newsid.current){
          let code = response.data?response.data.code:undefined;

          if(code!=='00'){
            return [{}];
          }
          else{
            let options = [];

            for(let i=0;i<response.data.data.length;i++){
              let tmp = {};
              tmp.label = response.data.data[i].email;
              tmp.value = response.data.data[i].email;

              options.push(tmp);
            }

            return options;
          }
        }
      };
      callBack = callBack.bind(this);

      let promise = httpGet('admin/search/'+keyword, '', 'Oops, something went wrong and could not search for admin. Please try again later.', callBack);

      return promise;
    }
  }

  const startVetting = () => {
    if(props.selectedAppraisers.length>0){
      let bidID = [];
      for(let i=0;i<props.selectedAppraisers.length;i++){
        if(props.appraiserInfoMap[props.selectedAppraisers[i]] && props.appraiserInfoMap[props.selectedAppraisers[i]].bid && props.appraiserInfoMap[props.selectedAppraisers[i]].bid !==-1){
          bidID.push(props.appraiserInfoMap[props.selectedAppraisers[i]].bid);
        }else{
          showMessage('warning','Selected appraiser does not have bid record. Please select appraiser with bid record.');
          return;
        }
      }

      let targetRecipients =[];

      for(let i=0;i<state.targetRecipients.length;i++)
        targetRecipients.push(state.targetRecipients[i].ID);

      let extraRecipients = state.extraRecipients.split(',');

      for(let i=0;i<state.extraOpsRecipients.length;i++){
        extraRecipients.push(state.extraOpsRecipients[i].value);
      }

      extraRecipients = extraRecipients.join(', ');

      let parameters = [
        {
          field:'comment',
          value:state.commentForQuoteVetting
        },
        {
          field:'ID',
          value:props.id
        },
        {
          field:'entities',
          value:targetRecipients
        },
        {
          field:'quotes',
          value:bidID
        },
        {
          field:'extra_recipients',
          value:extraRecipients
        }
      ];

      console.log(parameters);

      let callBack = (response)=>{
        let code = response.data?response.data.code:undefined;

      if(code!=='00'){
        setState({vetDisabled:false});
      }
      else{
          //clear the text area
          let appraisal = Object.assign({}, props.appraisal);
          appraisal.status='Quote';

          let overallStatus = Object.assign({},props.overallStatus);
          overallStatus.quote_vetting_count+=1;

          let appraisers = props.appraisers.slice();

          for(let i=0;i<props.selectedAppraisers.length;i++){
            for(let j=0;j<appraisers.length;j++){
              if(appraisers[j].ID===props.selectedAppraisers[i]){
                appraisers[j].vetted = true;
              }
            }
          }

          props.updateAppraisal(appraisal);
          props.updateOverallStatus(overallStatus)
          props.setState('appraisers', appraisers);
          props.setState('selectedAppraisers', []);
          setState({extraRecipients:'', targetRecipients:[], commentForQuoteVetting:''});

          props.toggleVettingPage();
        }
      };
      callBack = callBack.bind(this);

      httpPost('appraisal/quote/vet', parameters, 'Selected Quotes sent out successfully.', 'Oops, something went wrong and could not send selected quotes. Please try again later.', callBack);
    }
    else{
      showMessage('warning','Please select at least one appraiser with bid record.');
    }
  }


  //render
  let sendVetbutton;

  sendVetbutton = <Button color="warning" disabled={state.vetDisabled} onClick={
    (e)=>{
      if(props.appraisal.status!=='Submitted')
        confirmation(
          ()=>{vetButtonHandler()},
          ()=>{},
          'Send Selected Quote for Vetting?',
          'The appraisal is currently in '+props.appraisal.status+' status, do you still want to send quote for vetting?'
        );
      else {
        vetButtonHandler()
      }
    }
  }><i className="fa fa-check"></i>&nbsp;Send to Client</Button>;

  let entities;
  if(props.entities){
    entities = props.entities.map(
      (entity, index)=>{
        if(entity.entity_label==='Broker'||entity.entity_label==='Broker Company')
          return null;
        return (
          <tr key={index} className="cursor-pointer" onClick={(e)=>addNewRecipients(entity.ID, [])}><td><b>{entity.entity_label}</b></td><td>{entity.entity_name} - {entity.entity_email}</td></tr>
        );
      }
    );
  }

  let targetRecipients;
  if(state.targetRecipients){
    targetRecipients = state.targetRecipients.map(
      (recipient)=>{
        return(
          <div style={{display:'inline-block'}} key={recipient.ID}>
            <div className="entity-container cursor-pointer" onClick={()=>{removeFromTargetRecipients(recipient.ID)}}>
              <i className="fa fa-minus link-color"></i>&nbsp;{recipient.entity_name}
            </div>&nbsp;
          </div>
        );
      }
    );
  }

  return <div>
    <Row>
      <Col sm="6">
        <div className="align-left">
          <label>Tag Someone</label>
        </div>
        <div className="small-scroll-container-ex-large">
          <Table hover className="table">
            <tbody>
              <tr className="cursor-pointer" onClick={(e)=>addNewRecipientsBorrower()}><td><b>Borrower</b></td><td>{props.appraisal.borrower_f_name+' '+props.appraisal.borrower_l_name+' - '+props.appraisal.borrower_email}</td></tr>
              {entities}
            </tbody>
          </Table>
        </div>
      </Col>
      <Col sm="6">
        <Row>
          <Col sm="6" className="align-left">
            <label>Extra recipients</label><br/>
            <Input type="text" value={state.extraRecipients} onChange={(e)=>handleRecipientChange(e)}/>
          </Col>
          <Col sm="6" className="align-left">
            <label>Tag admin</label>
            <MySelect
              isMulti
              type="async-select"
              onChange={(v)=>handleAdminChange(v)}
              loadOptions={getAdmin}
            />
          </Col>
        </Row>
        <br/>
        <label>Selected recipients</label>
        <div className="small-scroll-container">
          {targetRecipients}
        </div>
      </Col>
    </Row>
    <br/>
    <TextArea id={props.id} placeholder="Message for sending selected bids to client." showLoading={props.showLoading} hideLoading={props.hideLoading} logout={props.logout} onChange={handleChangeQuoteInput}/>
    <div className="align-right">
      <Button color="info" onClick={props.toggleVettingPage}><i className="fa fa-arrow-left"></i>&nbsp;Back</Button>&nbsp;&nbsp;
      {sendVetbutton}
    </div>
  </div>;
}


export default AssignmentVet;
