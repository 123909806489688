//Author Sooyoung Kim
//Date Aug 22, 2023
import React, {useEffect} from 'react';
import {Col, Row, Input} from 'reactstrap';
import MySelect from '../util/my-select';

const Parkings  = (props)=>{
  const controller = new AbortController();

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const updateState = (field, value) => {
    let parkings = props.parkings.slice();
    let target = parkings[props.index];

    target[field] = value;

    parkings[props.index] = target;

    props.updateState({parkings:parkings});
  }

  //render
  let disabledStyling = {border:'none', background:'#fff', padding:'0',paddingLeft:'2px', height:'auto',WebkitAppearance:'none'};
  let parkingTypeOtherDescription;

  if(props.parkings[props.index].parkingType==='Other')
    parkingTypeOtherDescription = <Col sm="3">
      <label><font color="red">*</font>Other Description</label>
      <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={true} type="text" value={props.parkingTypeOtherDescription} onChange={(e)=>{updateState({parkingTypeOtherDescription:e.target.value})}}/>
    </Col>;

  return <div>
    <Row style={{marginTop:'15px'}}>
      <Col sm="3">
        <label>{(props.parkings[props.index].parkingType==='Driveway'||props.parkings[props.index].parkingType==='Pad')?<font color="red">*</font>:null}{props.parkings[props.index].parkingType} Space# For Car</label>
        <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={props.parkings[props.index].parkingType==='Driveway'||props.parkings[props.index].parkingType==='Pad'} type="number" value={props.parkings[props.index].parkingNumberOfCar} onChange={(e)=>{updateState('parkingNumberOfCar',e.target.value)}}/>
      </Col>
      {parkingTypeOtherDescription}
      <Col sm="3">
        <label><font color="red">*</font>{props.parkings[props.index].parkingType} Covered Indicator</label>
        <MySelect
          type="select"
          style={props.disabled?disabledStyling:{}}
          disabled={props.disabled}
          required={true}
          value={props.parkings[props.index].parkingCovered}
          onChange={(v)=>updateState('parkingCovered',v)}
          options={[
            {label:"",value:""},
            {label:"Yes",value:"True"},
            {label:"No",value:"False"},
          ]}
        />
      </Col>
      <Col sm="3">
        <label><font color="red">*</font>{props.parkings[props.index].parkingType} Surface Material</label>
        <MySelect
          type="select"
          style={props.disabled?disabledStyling:{}}
          disabled={props.disabled}
          required={true}
          value={props.parkings[props.index].parkingSurface}
          onChange={(v)=>updateState('parkingSurface',v)}
          options={[
            {label:"",value:""},
            {label:"Concrete",value:"Concrete"},
            {label:"Asphalt",value:"Asphalt"},
            {label:"Dirt",value:"Dirt"},
            {label:"Gravel",value:"Gravel"},
            {label:"Brick",value:"Brick"},
            {label:"Cobblestone",value:"Cobblestone"},
            {label:"Planking",value:"Planking"},
            {label:"Grass",value:"Grass"},
          ]}
        />
      </Col>
      <Col sm="3">
        <label>{props.parkings[props.index].parkingCovered==='True'?<font color="red">*</font>:null}{props.parkings[props.index].parkingType} Attached</label>
        <MySelect
          type="select"
          style={props.disabled?disabledStyling:{}}
          disabled={props.disabled}
          required={props.parkings[props.index].parkingCovered==='True'}
          value={props.parkings[props.index].parkingAttached}
          onChange={(v)=>updateState('parkingAttached',v)}
          options={[
            {label:"",value:""},
            {label:"Yes",value:"True"},
            {label:"No",value:"False"},
          ]}
        />
      </Col>
    </Row>
  </div>;
}


export default Parkings;
