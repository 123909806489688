//Author Sooyoung Kim
//Date July 13, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, generateSID, getFromTo, replaceRegex, formatNumber} from '../../util/util';
import {Button, Card, CardHeader, CardBody, Row, Col, Table} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import MyChart from './../util/my-chart';
import MySelect from './../util/my-select';
import ReportFilter from './../report/report-filter';
import moment from 'moment';

import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

//initialize the state
const initialState = {
  SID: generateSID(),
  loanPurpose:'',
  loanType:'',
  isRush:'',
  isComplex:'',
  state:'',
  city:'',
  county:'',
  entity:'',
  client:'',
  openBy:'',
  filterAppraisalTypes:[],
  filterCounty:[],
  clients:[],
  from:getFromTo('start'),
  to:getFromTo('end'),
  appraisers:[],
  appraiser:'',

  checklist:[],
  details:[],
  rules:[],
  selectedRule:'',
};

//reducer function that perform state update
const reducer = getReducer();


const AutoReviewDeliveryStatsDashboard  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getRules();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const onChange = (rule) =>{
    setState({selectedRule:rule});
    getRuleStatsDetails(rule);
  }

  //API call
  const getRuleStats = (rules) =>{
    setState({details:[],selectedRule:''})
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);
    let counties = replaceRegex(state.filterCounty, ',');

    let url = 'review/ruleStats/get/openBy='+state.openBy+'&client='+state.client+'&appraiser='+state.appraiser+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&county='+counties+'&appraisalType='+appraisalType;

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let unique = [];
        let newRules = rules.slice();
        for(let i=0;i<response.data.data.length;i++){
          if(response.data.data[i].code.toString().startsWith('9011 ')){
            if(unique.indexOf(response.data.data[i].code))
            newRules.push({rule_code:response.data.data[i].code, rule_description:''});;
          }
        }
        setState({checklist:response.data.data, rules:newRules});
      }
    };
    callBack = callBack.bind(this);

    httpGet(url, '', 'Oops, something went wrong and could not load rule checklist. Please try again later.', callBack);
  }

  const getRuleStatsDetails = (selectedRule) =>{
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);
    let counties = replaceRegex(state.filterCounty, ',');

    let url = 'review/ruleStats/details/code='+selectedRule+'&openBy='+state.openBy+'&client='+state.client+'&appraiser='+state.appraiser+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&county='+counties+'&appraisalType='+appraisalType;
    let callBack = apiCallBack([{state:'details', key:'data'}]);
	  httpGet(url, '', 'Oops, something went wrong and could not load rule checklist details. Please try again later.', callBack);
  }

  const getRules = () =>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let rules = [];
        for(let i=0;i<response.data.data.length;i++){
          if(response.data.data[i].rule_code!=='9011')
            rules.push(response.data.data[i]);
        }
        setState({rules:rules});

        getRuleStats(rules);
        if(response.data.data.length>0){
          setState({selectedRule:rules[0].rule_code});
          getRuleStatsDetails(rules[0].rule_code);
        }
      }
    };
    callBack = callBack.bind(this);

    httpGet('review/rule/list', '', 'Oops, something went wrong and could not load rule checklist. Please try again later.', callBack);
  }

  //render
  let from, to;
  if(state.from!=='')
    from = moment(state.from);
  if(state.to!=='')
    to = moment(state.to);

  let data = [];
  for(let i=0;i<state.checklist.length;i++){
    let tmp = {};
    tmp.name = state.checklist[i].code;
    tmp.description = state.checklist[i].description;
    tmp.y = state.checklist[i].count;

    data.push(tmp);
  }

  const config ={
    chart: {
      type: 'pie',
      height:300
    },
    title: {
      text: null
    },
    subtitle: {
      text: null
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          format: '{point.name}: {point.y}'
        },
        showInLegend: true
      }
    },

    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat: '<span>{point.name} - {point.description}</span>: <b>{point.y}</b> of total<br/>'
    },
    series: [{
      name: 'Rule code',
      colorByPoint: true,
      data: data,
      point: {
       events: {
         click: (e)=>{
           console.log(e);
           setState({selectedRule:e.point.name});
           onChange(e.point.name);
         }
       }
     }
    }],
    credits:{
      enabled:false
    },
    legend:{
      align: 'right',
      layout: 'vertical',
      verticalAlign: 'top',
      x: 340,
      y: 0,
      itemStyle:{
        fontSize:13
      },
      labelFormatter: function () {
        return this.name+' - '+formatNumber(this.series.yData[this.index]);
      }
    }
  };

  let chart;
  if(state.checklist.length>0){
    chart = <MyChart options={config}/>;
  }

  let reportFiltersConfig = [
    {id:'from',value:from, updateFunc:(v)=>setState({from:v}), width:'2'},
    {id:'to',value:to, updateFunc:(v)=>setState({to:v}), width:'2'},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'3'},
    {id:'openBy',value:state.openBy, updateFunc:(v)=>setState({openBy:v}), width:'3'},
    {id:'appraiser',value:state.appraiser, updateFunc:(v)=>setState({appraiser:v}), width:'2'},
    {id:'rush',value:state.isRush, updateFunc:(v)=>setState({isRush:v}), width:'2'},
    {id:'complex',value:state.isComplex, updateFunc:(v)=>setState({isComplex:v}), width:'2'},
    {id:'state',value:state.state, updateFunc:(v)=>setState({state:v}), width:'3'},
    {id:'city',value:state.city, updateFunc:(v)=>setState({city:v}), width:'2'},
    {id:'county',value:state.filterCounty, updateFunc:(v)=>setState({filterCounty:v}), width:'3', group:true},
    {id:'loanPurpose',value:state.loanPurpose, updateFunc:(v)=>setState({loanPurpose:v}), width:'2'},
    {id:'loanType',value:state.loanType, updateFunc:(v)=>setState({loanType:v}), width:'2'},
    {id:'entity',value:state.entity, updateFunc:(v)=>setState({entity:v}), width:'2'},
    {id:'appraisalType',value:state.filterAppraisalTypes, updateFunc:(v)=>setState({filterAppraisalTypes:v}), width:'4'},
    {id:'button',value:'Submit', updateFunc:(v)=>getRuleStats(state.rules), width:'2', className:"align-right", color:"warning"},
  ];
    
  return <div>
    <Row>
      <Col sm="6">
        <div className="page-title">
          <i className="fa fa-reorder"></i>&nbsp;Review & Delivery dashboard
        </div>
      </Col>
      <Col sm="6">

      </Col>
    </Row>
    <div className="my-divider"></div>
    <Row>
      <Col sm="6">

      </Col>
      <Col sm="6">
        <div className="align-right">
          <NavLink to="/auto-review-delivery">Back to review & delivery dashboard</NavLink>
        </div>
      </Col>
    </Row>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Review Rule Checklist Report
      </CardHeader>
      <CardBody>
        <ReportFilter {...props} configs={reportFiltersConfig}/>
      </CardBody>
    </Card>
    <br/>
    <div className="my-divider"/>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-tasks"></i> Rule checklist stats
      </CardHeader>
      <CardBody>
        <Row>
          <Col sm="5">
            {chart}
          </Col>
          <Col sm="7">
            <div className="large-scroll-container">
              <Table className="table table-striped">
                <tbody>
                  {
                    state.checklist.map(
                      (rule,index)=>{
                        return <tr key={index}>
                          <td width="20%">
                            {rule.code}
                          </td>
                          <td>
                            {rule.description}
                          </td>
                        </tr>
                      }
                    )
                  }
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>

        <br/>
        <Row>
          <Col sm="8">
            <MySelect
              type="select"
              value={state.selectedRule}
              onChange={(v)=>onChange(v)}
              options={[{label:"",value:""}].concat(
                state.rules.map((rule)=>{
                  return {label:rule.rule_code+' - '+rule.rule_description, value:rule.rule_code};
                })
              )}
            />
          </Col>
          <Col sm="4">
            <div className="align-right">
              <ExcelFile element={<Button to="#" disabled={state.details.length<=0} color="warning">Download Excel</Button>}>
                <ExcelSheet data={state.details} name="XLSX">
                    <ExcelColumn label="Reference #" value="reference_num"/>
                    <ExcelColumn label="Appraiser" value="appraiser"/>
                    <ExcelColumn label="Property Street" value="property_street"/>
                    <ExcelColumn label="Property City" value="property_city"/>
                    <ExcelColumn label="Property State" value="property_state"/>
                    <ExcelColumn label="Property Zip" value="property_zip"/>
                    <ExcelColumn label="Details" value="details"/>
                    <ExcelColumn label="Comment" value="comment"/>
                </ExcelSheet>
              </ExcelFile>
            </div>
          </Col>
        </Row>

        <br/>
        <div className="ultra-large-scroll-container-no-min">
          <Table className="table table-striped">
            <thead>
              <tr>
                <th>Reference #</th>
                <th>Appraiser</th>
                <th>Report Type</th>
                <th>Property</th>
                <th>Rule details</th>
              </tr>
            </thead>
            <tbody>
              {
                state.details.map(
                  (row, index)=>{

                    return <tr key={index}>
                      <td width="15%"><NavLink target="_blank" to={"/review/"+row.appraisal_fk}>{row.reference_num}</NavLink></td>
                      <td width="15%"><NavLink target="_blank" to={"/appraiser/"+row.appraiser_fk}>{row.appraiser}</NavLink></td>
                      <td width="15%">{row.appraisal_type}</td>
                      <td width="20%">{row.property_street+' '+row.property_city+', '+row.property_state+' '+row.property_zip}</td>
                      <td width="35%">{row.details}<br/><i>{row.comment}</i></td>
                    </tr>
                  }
                )
              }
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  </div>;
}


export default AutoReviewDeliveryStatsDashboard;
