//Author Sooyoung Kim
//Date Aug 4, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, callBackGenerator, generateSID} from '../../../util/util';
import {Button, Card, CardHeader, CardBody, Collapse, Col, Row, FormGroup, Modal, Carousel,CarouselItem,CarouselControl,CarouselIndicators,ModalHeader, ModalBody, Label, Input} from 'reactstrap';
import React, {useReducer, useEffect, useCallback, useRef} from 'react';
import MyReactTable from '../../util/my-react-table';
import {usePusher} from '../../pusher/pusher-context';
import ReportFilter from '../report-filter';
import moment from 'moment';
import './year-to-date-report.css';

//initialize the state
let today = new Date();

let from = today.getFullYear()+'-01-01';
let to = today.getFullYear()+'-12-31';

let currentYear = today.getFullYear();

const initialState = {
  SID: generateSID(),
  showReportLoading:false,

  new_report_year:currentYear,
  activeIndex:0,
  reportTitle:'client',
  animating:false,
  clickedHeader:false,
  reportReadyHeader:false,
  selectRecipientButton:false,
  report:[],
  clients:[],
  name:'',
  email:'',
  client:'',
  openBy:'',
  status:'',
  label:'',
  statuses:[],
  keyword:'',
  from:from,
  to:to,
  loanPurpose:'',
  loanType:'',
  allClicked:false,
  checkedRow:[],
  searchResult:[],
  appraisalTypes:[],
  selectedEntities:[],
  selectedAllEntities:[],
  selectedEntitiesDetail:[],
};

//reducer function that perform state update
const reducer = getReducer();


const ClientYearToDateReport  = (props)=>{
  const controller = new AbortController();
  const newsid = useRef(generateSID());
  const pusher = usePusher();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const toggleReportLoading = () => {
    setState({showReportLoading:!state.showReportLoading});
  }

  const loadReportResponse = useCallback((ID) => {
    let callBack = apiCallBack([{state:'report', key:'data'},{state:'selectedEntities', value:[]},{state:'selectedEntitiesDetail', value:[]},{state:'selectRecipientButton', value:true},{state:'reportReadyHeader', value:false},{state:'activeIndex', value:0},{state:'showReportLoading', value:false}]);
    httpGet('report/query/retrieve/'+ID, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [httpGet, setState]);

  const reportReady = useCallback((response) => {
    response = JSON.parse(response)
    let code = response.code;
    let queryID = response.data;
    let SID = response.SID;
    if(SID===newsid.current){
      if(code==='00'){
        loadReportResponse(queryID)
      }
    }
  }, [loadReportResponse]);

  //run only once when component is loaded
  useEffect(() => {
    // Subscribe to a channel and bind to events using the pusher instance
    const channel = pusher.subscribe('private-report');
    channel.bind('report-ready', (data) => {
      reportReady(data);
    });

    // Clean up the subscription when the component is unmounted
    return () => {
      channel.unbind('report-ready');
      pusher.unsubscribe('private-report');

      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addNewContact = () => {
    let tmp = {
      entity_name: state.name,
      entity_email: state.email
    };

    let existingArray = state.selectedEntities.slice();
    let selectedEntitiesDetail = state.selectedEntitiesDetail.slice();

    existingArray.push(-1);
    selectedEntitiesDetail.push(tmp);

    setState({name:'', email:'', selectedEntities:existingArray, selectedEntitiesDetail:selectedEntitiesDetail});
  }

  const onClickHeader = () => {
    setState({clickedHeader:!state.clickedHeader});
  }

  const previousButton = () => {
    if(state.animating){
       return;
    }
    let nextIndex;
    let activeIndex = state.activeIndex
    if(activeIndex === 0){
      nextIndex = state.report.length-1;
    }else{
      nextIndex = activeIndex - 1;
    }
    setState({activeIndex:nextIndex});
  }

  const nextButton = () => {
    if(state.animating) {
      return;
    }
    let nextIndex;
    let activeIndex = state.activeIndex
    if(activeIndex === state.report.length-1){
      nextIndex = 0;
    }else{
      nextIndex = activeIndex + 1;
    }
    setState({activeIndex:nextIndex});
  }

  const changeReportYear = (year) => {
    let from = year+'-01-01';
    let to = year+'-12-31';
    setState({new_report_year: year, from: from, to: to});
  }

  const removeSelectedEntity = (index) => {
    let selectedEntities = state.selectedEntities.slice();
    let selectedEntitiesDetail = state.selectedEntitiesDetail.slice();
    selectedEntities.splice(index, 1);
    selectedEntitiesDetail.splice(index, 1);

    setState({selectedEntities:selectedEntities, selectedEntitiesDetail:selectedEntitiesDetail});
  }

  const isCheck = (ID) => {
    let index = state.selectedEntities.indexOf(ID);

    if(index!==-1)
      return true;
    return false;
  }

  const clickRowListener = (id) => {
    let index = state.selectedEntities.indexOf(id);
    let index2 = state.selectedEntitiesDetail.map(e => e.ID).indexOf(id);
    let existingArray = state.selectedEntities.slice();
    let selectedEntitiesDetail = state.selectedEntitiesDetail.slice();

    if(index===-1){
        existingArray.push(id);
        let result = state.searchResult.find(entity => entity.ID === id);
        selectedEntitiesDetail.push(result);
        setState({selectedEntities:existingArray, selectedEntitiesDetail:selectedEntitiesDetail});
    }
    else{
        existingArray.splice(index,1);
        selectedEntitiesDetail.splice(index2,1);
        setState({selectedEntities:existingArray, selectedEntitiesDetail:selectedEntitiesDetail});
    }
  }

  const clickAllRowListener = () => {
    if(!state.allClicked){
      setState({selectedEntities:state.selectedAllEntities, selectedEntitiesDetail:state.searchResult, allClicked:true})
    }else{
      setState({selectedEntities:[], selectedEntitiesDetail:[], allClicked:false});
    }
  }

  //API call
  const searchEntity = () => {
    //call back for retrieving Article
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let selectedAllEntities = [];
        response.data.data.map(
          (item, index)=>{
            return selectedAllEntities.push(item.ID);
          }
        );

        setState({selectedAllEntities:selectedAllEntities, searchResult:response.data.data, clickedHeader: true, reportReadyHeader: true, selectRecipientButton: false});
      }
    };
    callBack = callBack.bind(this);

    httpGet('appraisal/entity/client='+state.client+'&open='+state.openBy+'&from='+state.from+'&to='+state.to, '', 'Oops, something went wrong and could not search contacts. Please try again later.', callBack);
  }

  const sendEmailReport = () => {
    let searchResult = state.selectedEntitiesDetail.slice();

    let entities = [];
    for(let i=0;i<searchResult.length;i++){
      entities.push({email:searchResult[i].entity_email, name:searchResult[i].entity_name});
    }

    let parameters = [
      {
        field:'reports',
        value:state.report
      },
      {
        field:'entities',
        value:entities
      }
    ];

    httpPost('report/ytdreport/client/email', parameters, 'Report successfully sent through email.', 'Oops, something went wrong and could not send the report. Please try again later.');
  }

  const getYTDReport = () => {
    let SID = generateSID();
    newsid.current = SID;

    //selectedEntities
    let url = 'report/ytdreport/client/SID='+SID+'&client='+state.client+'&openBy='+state.openBy+'&title='+state.reportTitle+'&from='+state.from+'&to='+state.to+'&year='+state.new_report_year;
    let callBack = apiCallBack([], toggleReportLoading());
    httpGet(url, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  //render
  let from, to;
  if(state.from!=='')
    from = moment(state.from);
  if(state.to!=='')
    to = moment(state.to);

  const columns = [
    {
      id:'action',
      Header: <div className="display-inline" style={{verticalAlign:'top'}}><Input type="checkbox" onClick={(e) => clickAllRowListener()}/></div>,
      minWidth:60,
      width:60,
      accessor: d => '',
      Cell: props =>{
        return(
          <div className="display-inline" style={{verticalAlign:'top'}}>
            <FormGroup check>
              <Label check>
                <Input type="checkbox" value={props.row.original.ID} checked={isCheck(props.row.original.ID)} onChange={(e) => clickRowListener(props.row.original.ID)}/>
              </Label>
            </FormGroup>
          </div>
        )
      }
    },
    {
      id: 'label',
      Header: 'Label',
      minWidth:150,
      width:150,
      accessor: d => d.entity_label,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'name',
      Header: 'Name',
      accessor: d => d.entity_name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'email',
      Header: 'Email',
      accessor: d => d.entity_email,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'order',
      Header: 'Order(s)',
      minWidth:100,
      width:100,
      accessor: d => parseInt(d.order,10),
      headerStyle: {
        textAlign:'left'
      }
    },
  ];


  let reportYear = [];

  let currentYear = new Date().getFullYear();

  for(let i=0;i<10;i++){
    reportYear.push(currentYear);
    currentYear--;
  }

  let yearOption = reportYear.map((year)=>{
    return {label:year, value:year};
  })

  let entityList;
  if(state.selectedEntitiesDetail.length > 0){
    entityList = <div className="ultra-large-scroll-container">
      {
        state.selectedEntitiesDetail.map(
          (contact, index)=>{
            return (<div key={contact.ID} className="entity-container cursor-pointer" onClick={(e)=>removeSelectedEntity(index)}>
              <i className="fa fa-minus red-color"/> &nbsp;{contact.entity_name} - {contact.entity_email}
            </div>)
          }
        )
      }
    </div>
  }

  let carouselItemData;
  let carousel;
  if(state.report.length > 0){
    carouselItemData = state.report.map(
      (item, index) => {
        return (
            <CarouselItem key={item.key}
              onExited={() => setState({animating:false})}
              onExiting={() => setState({animating:true})}>
              <center>
                <div dangerouslySetInnerHTML={{__html:item.content}}/>
              </center>
            </CarouselItem>
        );
      }
    );

    carousel =  <div>
      <div className="my-divider">&nbsp;</div>
      <br/>
      <Carousel interval={null} previous={previousButton} next={nextButton} activeIndex={state.activeIndex}>
          <CarouselIndicators items={state.report}
              activeIndex={state.activeIndex}
              onClickHandler={(newIndex) => {
                  if(state.animating) {
                    return;
                  }
                  setState({activeIndex:newIndex});
              }} />
              {carouselItemData}
          <CarouselControl directionText="Prev"
              direction="prev" onClickHandler={previousButton} />
          <CarouselControl directionText="Next"
              direction="next" onClickHandler={nextButton} />
      </Carousel>
    </div>;
  }

  let reportFiltersConfig = [
    {id:'customSelect',value:state.new_report_year, updateFunc:(v)=>changeReportYear(v), width:'3', label:'Report Year', clearable:false, className:"", options:[{label:"Select the year of report",value:""}].concat(yearOption)},
    {id:'from',value:from, updateFunc:(v)=>setState({from:v}), width:'3'},
    {id:'to',value:to, updateFunc:(v)=>setState({to:v}), width:'3'},
    {id:'customSelect',value:state.reportTitle, updateFunc:(v)=>setState({reportTitle:v}), width:'3', label:'Report Title', clearable:true, className:"", options:[{label:"None",value:""},{label:"Closed By",value:"client"},{label:"Open By",value:"openBy"}]},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'5'},
    {id:'openBy',value:state.openBy, updateFunc:(v)=>setState({openBy:v}), width:'5'},
    {id:'button',value:'View Report', updateFunc:getYTDReport, width:'2', className:"align-right", color:"warning"},
  ];

  return <div>
    <Modal className="my-modal" isOpen={state.showReportLoading} toggle={toggleReportLoading} >
      <ModalHeader hidden={true} toggle={toggleReportLoading}></ModalHeader>
      <ModalBody>
        <center>
          <i style={{fontSize:'55px'}} className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
          <br/><br/>
          Report Loading...<br/>
          Please wait, your report will be loaded once it is ready.
        </center>
        <br/><br/>
        <center>
          <Button color="info" onClick={toggleReportLoading}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Year to Date Report
      </CardHeader>
      <CardBody>
        <ReportFilter {...props} configs={reportFiltersConfig}/>
        {carousel}
        {state.selectRecipientButton && <div>
          <div className="my-divider">&nbsp;</div>
          <div className="align-right">
          <Button disabled={state.report.length<=0} onClick={searchEntity} color="warning"><i className="fa fa-plus green-color"></i>&nbsp;Select Recipient</Button>
          </div>
        </div>}
        {state.reportReadyHeader && <div>
          <br/>
          <div className="my-divider">&nbsp;</div>
          <Card>
            <CardHeader className="header-color cursor-pointer" onClick={()=>onClickHeader()}>
              <Row>
                <Col sm="12" className="align-left">
                  <i className={(state.clickedHeader)?"fa fa-chevron-up":"fa fa-chevron-down"}></i> &nbsp;Recipients
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Collapse isOpen={state.clickedHeader}>
                <Row>
                  <Col sm="8">
                    <i className="fa fa-check"></i>&nbsp;<b>Select Recipient(s)</b><br/>
                    <div className="large-scroll-container">
                      <MyReactTable columns={columns} data={state.searchResult} className="table table-striped"/>
                    </div>
                    <br/>
                    <i className="fa fa-plus"></i>&nbsp;<b>Add New Recipient</b><br/>
                    <label>Name</label><br/>
                    <Input type="text" value={state.name} onChange={(e)=>setState({name:e.target.value})}/>
                    <br/>
                    <label>Email</label><br/>
                    <Input type="text" value={state.email} onChange={(e)=>setState({email:e.target.value})}/>
                    <br/>
                    <div className="align-right">
                      <Button color="warning" onClick={addNewContact}>Add</Button>
                    </div>
                  </Col>
                  <Col sm='4'>
                    <i className="fa fa-users"></i>&nbsp;<b>Selected Recipient(s)</b> {entityList}
                  </Col>
                </Row>
              </Collapse>
            </CardBody>
          </Card>
        </div>}
        {state.reportReadyHeader && <div className="align-right">
          <br/>
          <Button disabled={state.selectedEntities.length<=0} onClick={sendEmailReport} color="warning">Send Report</Button>
        </div>}
      </CardBody>
    </Card>
  </div>;
}


export default ClientYearToDateReport;
