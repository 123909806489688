//Author Sooyoung Kim
//Date April 28, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, putAPICallGenerator} from '../../util/util';
import {Col, Row} from 'reactstrap';
import {Card, CardBody, CardHeader, Table, UncontrolledTooltip} from 'reactstrap';
import MyXEditable from '../util/my-xeditable';
import {NavLink, useParams} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';
import './appraiser.css';


//initialize the state
const initialState = {
  amc:{},
  appraisalTypes:[],
  selectedColumns:[],
  selectedRows:[],
};

//reducer function that perform state update
const reducer = getReducer();


const AMC  = (props)=>{
  const controller = new AbortController();
  const { id } = useParams();

  let newInitialState = Object.assign({}, initialState, {id:id});
  const [state, dispatch] = useReducer(reducer, newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getAmc(state.id);

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const updateFieldCallBack2 = (amcFk, appraisalType, state, value, amc)=>{

    let fees = amc.fees.slice();

    for(let i=0;i<fees.length;i++){
      if(fees[i].state===state){
        let stateFees = fees[i].state_fees.slice();

        for(let j=0;j<stateFees.length;j++){
          if(stateFees[j].appraisal_type===appraisalType){
            stateFees[j].fee = value;
            break;
          }
        }

        fees[i].state_fees = stateFees;
      }
    }

    amc.fees = fees;
    setState({amc:amc});
  }

  const updateFieldCallBack = (field, value)=>{
    console.log(field, value);
    let amc = Object.assign({}, state.amc);
    amc[field] = value;
    setState({amc:amc});
  }

  const selectColumn = (column)=>{
    let selectedColumns = state.selectedColumns.slice();

    let index = selectedColumns.indexOf(column);
    if(index===-1)
      selectedColumns.push(column);
    else
      selectedColumns.splice(index,1);

    setState({selectedColumns:selectedColumns});

  }

  const selectRow = (row)=>{
    let selectedRows = state.selectedRows.slice();

    let index = selectedRows.indexOf(row);
    if(index===-1)
      selectedRows.push(row);
    else
      selectedRows.splice(index,1);

    setState({selectedRows:selectedRows});

  }

  //API call
  //generate a function that do the update on editable
  const generateUpdateFunction = (url, parameters, successMessage, failedMessage, parentCallBack=null)=>{
    return (newValue, callBack, errorCallBack)=>{
      //add in the new value into parameters

      parameters.push({field:'value',value:newValue});
      let promise = httpPut(url, parameters, successMessage, failedMessage, callBack,errorCallBack);
      promise.then(
        (response)=>{
          let field;
          let value;

          for(let i=0;i<parameters.length;i++){
            if(parameters[i].field==='field')
              field = parameters[i].value;
            else if(parameters[i].field==='value')
              value = parameters[i].value;
          }
          if(parentCallBack!==null){
            parentCallBack(field, value);
          }
        }
      );
    }
  };

  const getAmc = ()=>{
    let callBack = (response)=>{console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        setState({amc:response.data.data});
        if(response.data.data.fees&&response.data.data.fees.length>0){

          //get appraisal types
          let firstRow = response.data.data.fees[0];

          let appraisalTypes = [];
          for(let i=0;i<firstRow.state_fees.length;i++){
            appraisalTypes.push(firstRow.state_fees[i].appraisal_type);
          }

          setState({appraisalTypes:appraisalTypes});
        }
      }
    };
    callBack = callBack.bind(this);
    httpGet('amc/'+state.id, '', 'Oops, something went wrong and could not load this AMC. Please try again later.', callBack);
  }

  //render
  let autoRelayComment, autoReview, generateSSR, skipPayment;

  let successMessage = 'AMC information updated.';
  let failedMessage = 'Failed to update the value of this field, please try again later.';

  if(state.amc.auto_review)
    autoReview =
      <Row>
        <Col sm="6">
          Auto review
        </Col>
        <Col sm="6">
          <MyXEditable
            type="select"
            value={state.amc.auto_review}
            opt={[{key:'yes',value:'yes'},{key:'no',value:'no'}]}
            updateFunc={
              generateUpdateFunction('amc/update',[{field:'field',value:'auto_review'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
            }
          />
        </Col>
      </Row>;

  if(state.amc.generate_ssr)
    generateSSR =
      <Row>
        <Col sm="6">
          Generate SSR
        </Col>
        <Col sm="6">
          <MyXEditable
            type="select"
            value={state.amc.generate_ssr}
            opt={[{key:'yes',value:'yes'},{key:'no',value:'no'}]}
            updateFunc={
              generateUpdateFunction('amc/update',[{field:'field',value:'generate_ssr'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
            }
          />
        </Col>
      </Row>;
  if(state.amc.skip_payment)
    skipPayment =
      <Row>
        <Col sm="6">
          Skip payment
        </Col>
        <Col sm="6">
          <MyXEditable
            type="select"
            value={state.amc.skip_payment}
            opt={[{key:'yes',value:'yes'},{key:'no',value:'no'}]}
            updateFunc={
              generateUpdateFunction('amc/update',[{field:'field',value:'skip_payment'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
            }
          />
        </Col>
      </Row>;

  if(state.amc.auto_relay_comment)
    autoRelayComment =
      <Row>
        <Col sm="6">
          Auto relay comment
        </Col>
        <Col sm="6">
          <MyXEditable
            type="select"
            value={state.amc.auto_relay_comment}
            opt={[{key:'yes',value:'yes'},{key:'no',value:'no'}]}
            updateFunc={
              generateUpdateFunction('amc/update',[{field:'field',value:'auto_relay_comment'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
            }
          />
        </Col>
      </Row>;

  let firstRow, stateFees;
  if(state.amc.appraiser_fk){
    let columns = state.appraisalTypes.map(
      (appraisalType, index)=>{
        return(
          <th key={index} className="rotate" onClick={(e)=>selectColumn(index)} id={"tooltip"+index}>
              <div>
              <span>
                {appraisalType}
                <UncontrolledTooltip delay={{ "show": 0, "hide": 0 }} placement="right" target={"tooltip"+index}>
                  {appraisalType}
                </UncontrolledTooltip>
              </span>
            </div>
          </th>
        );
      }
    );
    firstRow =
      <tr>
        <th></th>
        {columns}
      </tr>;

    stateFees = state.amc.fees.map(
      (amcState, index)=>{
        let selected2 = '';
        let i = state.selectedRows.indexOf(index);
        if(i!==-1)
          selected2 = 'highlight';

        let fees = amcState.state_fees.map(
          (fee,index2)=>{
            let selected = '';
            let i = state.selectedColumns.indexOf(index2);
            if(i!==-1)
              selected = 'highlight';
            if(fee.appraisal_type===state.appraisalTypes[index2])
              return(
                <td key={index2} className={selected+' '+selected2}>
                  <MyXEditable
                  type="text"
                  value={fee.fee}
                  prefix="$"
                  updateFunc={
                    generateUpdateFunction('amc/fee/state/update',[{field:'state',value:amcState.state},{field:'amcFk',value:state.id},{field:'appraisalType', value:fee.appraisal_type}],successMessage, failedMessage, (field, value)=>{updateFieldCallBack2(state.id, fee.appraisal_type, amcState.state, value, state.amc)})
                  }/>
                </td>
              );
            else
              return <td key={index2}>-</td>;
          }
        )

        return(
          <tr key={index} onClick={(e)=>selectRow(index)} className="cursor-pointer">
            <td>{amcState.state}</td>
            {fees}
          </tr>
        );
      }
    );
  }


  return <div className="my-well">
    <Row>
      <Col sm="6">
        <div className="page-title">
          <i className="fa fa-reorder"></i>&nbsp;AMC
        </div>
        <NavLink to="/amc">Back to amc list</NavLink>
      </Col>
      <Col sm="6">

      </Col>
    </Row>
    <div className="my-divider"></div>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-gears"></i>&nbsp;{state.amc.first_name+' '+state.amc.last_name}
      </CardHeader>
      <CardBody>
        {autoReview}
        {generateSSR}
        {skipPayment}
        {autoRelayComment}
      </CardBody>
    </Card>
    <br/>

    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-dollar"></i>&nbsp;Fees
      </CardHeader>
      <CardBody>
        <div className="Container Flipped">
          <div className="Content">
            <Table className="table table-striped table-header-rotated">
              <thead>
                {firstRow}
              </thead>
              <tbody>
                {stateFees}
              </tbody>
            </Table>
          </div>
        </div>
      </CardBody>
    </Card>
  </div>;
}

export default AMC;
