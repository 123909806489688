//Sooyoung Kim
//June 15, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, formatNumber, formatDateTime} from '../../../util/util';
import {Button, Card, CardHeader, CardBody, Col, Row} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import MyReactTable from '../../util/my-react-table';
import ReportFilter from '../report-filter';
import ReactDOM from 'react-dom';
import moment from 'moment';
import {NavLink} from 'react-router-dom';

import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

let today = new Date();
let month = today.getMonth()+1;

if(month<10)
  month = '0'+month;
let from = today.getFullYear()+'-'+month+'-'+today.getDate();
let to = today.getFullYear()+'-'+month+'-'+today.getDate();

//initialize the state
const initialState = {
  declined:[],
  success:[],
  voidRefund:[],
  balanceDue:[],
  from:from,
  to:to,
  clients:[],
  client:'',
  successTotal:0,
  failedTotal:0,
  balanceDueTotal:0,
  voidRefundTotal:0,
  successProfitTotal:0,
  failedProfitTotal:0,
};

//reducer function that perform state update
const reducer = getReducer();


const CreditCardChargingReport  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getReportDetails();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  //API call
  const getReportDetails = ()=>{
    let callBack = (response)=>{console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let successTotal = 0, failedTotal = 0, balanceDueTotal = 0, voidRefundTotal =0;
        let successProfitTotal = 0, failedProfitTotal = 0;

        for(let i=0;i<response.data.data.success.length;i++){
          successTotal = successTotal + parseInt(response.data.data.success[i].total, 10);
          successProfitTotal = successProfitTotal + parseInt(response.data.data.success[i].profit, 10);
        }

        for(let i=0;i<response.data.data.declined.length;i++){
          failedTotal = failedTotal + parseInt(response.data.data.declined[i].total, 10);
          failedProfitTotal = failedProfitTotal + parseInt(response.data.data.declined[i].profit, 10);
        }

        for(let i=0;i<response.data.data.balance_due.length;i++)
          balanceDueTotal = balanceDueTotal + parseInt(response.data.data.balance_due[i].total, 10);

        for(let i=0;i<response.data.data.void_refund.length;i++)
          voidRefundTotal = voidRefundTotal + parseInt(response.data.data.void_refund[i].amount, 10);

          ReactDOM.unstable_batchedUpdates(() => {
            setState({
              successProfitTotal:successProfitTotal,
              failedProfitTotal:failedProfitTotal,
              successTotal:successTotal,
              failedTotal:failedTotal,
              balanceDueTotal:balanceDueTotal,
              voidRefundTotal:voidRefundTotal,
              success:response.data.data.success,
              voidRefund:response.data.data.void_refund,
              declined:response.data.data.declined,
              balanceDue:response.data.data.balance_due
            });
          });
      }
    };
    callBack = callBack.bind(this);

    httpGet('report/charge/get/start='+state.from+'&end='+state.to+'&client_fk='+state.client, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  //render
  let from, to;
  if(state.from!=='')
    from = moment(state.from);
  if(state.to!=='')
    to = moment(state.to);

  const columns = [
    {
      id: 'referenceNum',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'loanNum',
      Header: 'Loan #',
      accessor: d => d.loan_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'transactionNum',
      Header: 'Transaction #',
      accessor: d => d.transaction_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'property',
      Header: 'Property',
      accessor: d => d.property_street+' '+d.property_city+', '+d.property_state+' '+d.property_zip,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'borrower',
      Header: 'Borrower',
      accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'status',
      Header: 'Order Status',
      accessor: d => d.order_status,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'amount',
      Header: 'Amount',
      accessor: d => d.total,
      Cell: props => <div>${formatNumber(props.row.original.total)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'profit',
      Header: 'Profit',
      accessor: d => d.profit,
      Cell: props => <div>${formatNumber(props.row.original.profit)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'date',
      Header: 'Date',
      accessor: d => d.datetime_created,
      Cell: props=> <div>{formatDateTime(props.row.original.datetime_created)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  const columns2 = [
    {
      id: 'referenceNum',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'loanNum',
      Header: 'Loan #',
      accessor: d => d.loan_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'transactionNum',
      Header: 'Transaction #',
      accessor: d => d.transaction_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'property',
      Header: 'Property',
      accessor: d => d.property_street+' '+d.property_city+', '+d.property_state+' '+d.property_zip,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'borrower',
      Header: 'Borrower',
      accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'status',
      Header: 'Order Status',
      accessor: d => d.order_status,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'amount',
      Header: 'Amount',
      accessor: d => d.amount,
      Cell: props => <div>${formatNumber(props.row.original.amount)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'date',
      Header: 'Date',
      accessor: d => d.datetime_created,
      Cell: props=> <div>{formatDateTime(props.row.original.datetime_created)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  const columns3 = [
    {
      id: 'referenceNum',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'loanNum',
      Header: 'Loan #',
      accessor: d => d.loan_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'property',
      Header: 'Property',
      accessor: d => d.property_street+' '+d.property_city+', '+d.property_state+' '+d.property_zip,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'orderStatus',
      Header: 'Order Status',
      accessor: d => d.order_status,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'status',
      Header: 'Status',
      accessor: d => d.status,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'amount',
      Header: 'Amount',
      accessor: d => d.total,
      Cell: props => <div>${formatNumber(props.row.original.total)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'profit',
      Header: 'Profit',
      accessor: d => d.profit,
      Cell: props => <div>${formatNumber(props.row.original.profit)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'date',
      Header: 'Date',
      accessor: d => d.datetime_created,
      Cell: props=> <div>{formatDateTime(props.row.original.datetime_created)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  let reportFiltersConfig = [
    {id:'from',value:from, updateFunc:(v)=>setState({from:v}), width:'3'},
    {id:'to',value:to, updateFunc:(v)=>setState({to:v}), width:'3'},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'3'},
    {id:'button',value:'Submit', updateFunc:getReportDetails, width:'3', className:"align-right", color:"warning"},
  ];
    
  return <div>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Authorize.net Charging Report
      </CardHeader>
      <CardBody>
        <ReportFilter {...props} configs={reportFiltersConfig}/>
        <div className="my-divider">&nbsp;</div>
        <Card>
          <CardHeader className="gray-color">
            <Row>
              <Col sm="8">
                Success
              </Col>
              <Col sm="4">
                <div className="align-right">
                  <ExcelFile element={<Button disabled={state.success.length<=0} color="warning">Download Excel</Button>}>
                    <ExcelSheet data={state.success} name="XLSX">
                        <ExcelColumn label="Reference #" value="reference_num"/>
                        <ExcelColumn label="Loan #" value="loan_num"/>
                        <ExcelColumn label="Transaction #" value="transaction_num"/>
                        <ExcelColumn label="Address" value={(col)=>{return col.property_street+' '+col.property_city+', '+col.property_state+' '+col.property_zip}}/>
                        <ExcelColumn label="Borrower" value={(col)=>{return col.borrower_f_name+' '+col.borrower_l_name}}/>
                        <ExcelColumn label="Status" value="order_status"/>
                        <ExcelColumn label="Amount" value={(col)=>'$'+formatNumber(col.total)}/>
                        <ExcelColumn label="Profit" value={(col)=>'$'+formatNumber(col.profit)}/>
                        <ExcelColumn label="Date" value={(col)=>formatDateTime(col.datetime_created)}/>
                    </ExcelSheet>
                  </ExcelFile>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <div className="large-scroll-container">
              <MyReactTable columns={columns} data={state.success} className="table table-striped"/>
            </div>
            <div className="align-right">
              <b>Profit Total: ${formatNumber(state.successProfitTotal)}&nbsp;&nbsp;&nbsp;Total: ${formatNumber(state.successTotal)}</b>
            </div>
          </CardBody>
        </Card>
        <br/>
        <div className="my-divider">&nbsp;</div>
        <Card>
          <CardHeader className="gray-color">
            <Row>
              <Col sm="8">
                Void / Refund
              </Col>
              <Col sm="4">
                <div className="align-right">
                  <ExcelFile element={<Button disabled={state.voidRefund.length<=0} color="warning">Download Excel</Button>}>
                    <ExcelSheet data={state.voidRefund} name="XLSX">
                        <ExcelColumn label="Reference #" value="reference_num"/>
                        <ExcelColumn label="Loan #" value="loan_num"/>
                        <ExcelColumn label="Transaction #" value="transaction_num"/>
                        <ExcelColumn label="Address" value={(col)=>{return col.property_street+' '+col.property_city+', '+col.property_state+' '+col.property_zip}}/>
                        <ExcelColumn label="Borrower" value={(col)=>{return col.borrower_f_name+' '+col.borrower_l_name}}/>
                        <ExcelColumn label="Status" value="order_status"/>
                        <ExcelColumn label="Amount" value={(col)=>'$'+formatNumber(col.amount)}/>
                        <ExcelColumn label="Date" value={(col)=>formatDateTime(col.datetime_created)}/>
                    </ExcelSheet>
                  </ExcelFile>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <div className="large-scroll-container">
              <MyReactTable columns={columns2} data={state.voidRefund} className="table table-striped"/>
            </div>
            <div className="align-right">
              <b>Total: ${formatNumber(state.voidRefundTotal)}</b>
            </div>
          </CardBody>
        </Card>
        <br/>
        <Card>
          <CardHeader className="gray-color">
            <Row>
              <Col sm="8">
                Failed
              </Col>
              <Col sm="4">
                <div className="align-right">
                  <ExcelFile element={<Button disabled={state.declined.length<=0} color="warning">Download Excel</Button>}>
                    <ExcelSheet data={state.declined} name="XLSX">
                        <ExcelColumn label="Reference #" value="reference_num"/>
                        <ExcelColumn label="Loan #" value="loan_num"/>
                        <ExcelColumn label="Address" value={(col)=>{return col.property_street+' '+col.property_city+', '+col.property_state+' '+col.property_zip}}/>
                        <ExcelColumn label="Order Status" value="order_status"/>
                        <ExcelColumn label="Status" value="status"/>
                        <ExcelColumn label="Amount" value={(col)=>'$'+formatNumber(col.total)}/>
                        <ExcelColumn label="Profit" value={(col)=>'$'+formatNumber(col.profit)}/>
                        <ExcelColumn label="Date" value={(col)=>formatDateTime(col.datetime_created)}/>
                    </ExcelSheet>
                  </ExcelFile>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <div className="large-scroll-container">
              <MyReactTable columns={columns3} data={state.declined} className="table table-striped"/>
            </div>
            <div className="align-right">
              <b>Profit Total: ${formatNumber(state.failedProfitTotal)}&nbsp;&nbsp;&nbsp;Total: ${formatNumber(state.failedTotal)}</b>
            </div>
          </CardBody>
        </Card>
        <br/>
        <Card>
          <CardHeader className="gray-color">
            <Row>
              <Col sm="8">
                  Balance Due / Reconcile (Charged Transaction Amnount Not Match)
              </Col>
              <Col sm="4">
                <div className="align-right">
                  <ExcelFile element={<Button disabled={state.balanceDue.length<=0} color="warning">Download Excel</Button>}>
                    <ExcelSheet data={state.balanceDue} name="XLSX">
                      <ExcelColumn label="Reference #" value="reference_num"/>
                      <ExcelColumn label="Loan #" value="loan_num"/>
                      <ExcelColumn label="Address" value={(col)=>{return col.property_street+' '+col.property_city+', '+col.property_state+' '+col.property_zip}}/>
                      <ExcelColumn label="Order Status" value="order_status"/>
                      <ExcelColumn label="Status" value="status"/>
                      <ExcelColumn label="Amount" value={(col)=>'$'+formatNumber(col.total)}/>
                      <ExcelColumn label="Profit" value={(col)=>'$'+formatNumber(col.profit)}/>
                      <ExcelColumn label="Date" value={(col)=>formatDateTime(col.datetime_created)}/>
                    </ExcelSheet>
                  </ExcelFile>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <div className="large-scroll-container">
              <MyReactTable columns={columns3} data={state.balanceDue} className="table table-striped"/>
            </div>
            <div className="align-right">
              <b>Total: ${formatNumber(state.balanceDueTotal)}</b>
            </div>
          </CardBody>
        </Card>
      </CardBody>
    </Card>
  </div>;
}


export default CreditCardChargingReport;
