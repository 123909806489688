// Sooyoung Kim
// June 16, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, formatNumber, formatDate, getFromTo, generateSID, replaceRegex} from '../../../util/util';
import {Button, Card, CardHeader, CardBody, Col, Row, Modal, ModalHeader, ModalBody} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import ReportFilter from '../report-filter';
import moment from 'moment';
import {NavLink} from 'react-router-dom';
import '../report.css';

//initialize the state
const initialState = {
  SID: generateSID(),
  accrued: 'no',
  loanPurpose:'',
  loanType:'',
  isRush:'',
  isComplex:'',
  state:'',
  city:'',
  county:'',
  entity:'',
  client:'',
  openBy:'',
  selectedAppraisalType:'',
  selectedCounty:'',
  filterAppraisalTypes:[],
  filterCounty:[],
  from:getFromTo('from'),
  to:getFromTo('to'),

  revenue:{
  },
  expense:{
  },
  third_party_fee:[],
  still_owe_as_of:'',
  transaction_not_match:0,
  revenue_order_count:0,
  expense_order_count:0,

  reportDetailsToggle:false,
  reportDetails:'',
  reportDetailsOrders:[],
  appraisers:[],
  appraiser:'',
};

//reducer function that perform state update
const reducer = getReducer();


const FeeReport  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getReport();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const reportDetailsToggle = ()=>{
    if(state.reportDetailsDropDown)
      setState({reportDetailsOrders:[]});
    setState({reportDetailsDropDown:!state.reportDetailsDropDown});
  }

  //API call
  const getReport = ()=>{
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);
    let counties = replaceRegex(state.filterCounty, ',');

    let url = 'report/fee/accrued='+state.accrued+'&openBy='+state.openBy+'&client='+state.client+'&appraiser='+state.appraiser+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&county='+counties+'&appraisalType='+appraisalType;
    let callBack = apiCallBack([{state:'revenue_order_count', key:'data.revenue_order_count'}, {state:'expense_order_count', key:'data.expense_order_count'}, {state:'revenue', key:'data.revenue'}, {state:'expense', key:'data.expense'}, {state:'still_owe_as_of', key:'data.still_owe_as_of'}, {state:'transaction_not_match', key:'data.transaction_not_match'}, {state:'third_party_fee', key:'data.third_party_fee'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  const getReportDetails = (report,method, covered,type)=>{
    reportDetailsToggle();
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);
    let counties = replaceRegex(state.filterCounty, ',');

    let url = 'report/fee/details/order/accrued='+state.accrued+'&openBy='+state.openBy+'&client='+state.client+'&appraiser='+state.appraiser+'&reportType='+type+'&loanPurpose='+loanPurpose+'&report='+report+'&method='+method+'&covered='+covered+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&county='+counties+'&appraisalType='+appraisalType;
    let callBack = apiCallBack([{state:'reportDetailsOrders', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load the report details. Please try again later.', callBack);
  }

  //render
  let from, to;
  if(state.from!=='')
    from = moment(state.from);
  if(state.to!=='')
    to = moment(state.to);

  let content;

  if(state.reportDetailsOrders.length>0){
    let rows = state.reportDetailsOrders.map(
      (order, index)=>{
        return(
          <tr key={index}>
            <td><NavLink target="_blank" to={"/appraisal/"+order.ID}>{order.reference_num}</NavLink></td>
            <td>{formatDate(order.datetime_submitted)}</td>
            <td>{order.company}</td>
            <td>{order.property_street+' '+order.property_city+', '+order.property_state+' '+order.property_zip}</td>
            <td>{order.loan_num}</td>
            <td>{order.borrower_f_name+' '+order.borrower_l_name}</td>
            <td>{order.appraisal_type}</td>
            <td>{order.loan_type}</td>
            <td>${formatNumber(order.fee)}</td>
          </tr>
        )
      }
    );

    content =
    <table className="table table-striped">
      <thead>
        <tr>
          <th width="10%">Reference #</th>
          <th width="10%">Date Submitted</th>
          <th width="10%">Client</th>
          <th width="14%">Address</th>
          <th width="12%">Loan #</th>
          <th width="12%">Borrower</th>
          <th width="15%">Report Type</th>
          <th width="8%">Loan type</th>
          <th width="8%">Fee</th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </table>
  }

  let expenseTotal = 0 , revenueTotal = 0;
  let ar=0, arByLender = 0, arHold = 0, refundNeeded = 0, arPaidAtClosing = 0, arPaidOutsideOfClosing = 0, arReceivedPure = 0,arDeclined = 0, arVoided = 0, arRefunded = 0, arReceived = 0, arChargedBack = 0, arChargedBackResolved = 0, arCancelled = 0, arLoss = 0, arBalanceDue =0;
  let arBreakDown;
  let notRecognized = 0, overCharge = 0, partiallyReceived = 0, transactionNotMatch=0;

  let exPaid = 0, exStandby = 0, exCancelled = 0, exPending =0, exHold = 0, exThirdPartyFee = 0;

  if(state.expense){
    for(let s in state.expense){
      for(let m in state.expense[s]){
        if(s==='paid')
          exPaid = exPaid + parseInt(state.expense[s][m],10);
        if(s==='standby')
          exStandby = exStandby + parseInt(state.expense[s][m],10);
        if(s==='pending')
          exPending = exPending + parseInt(state.expense[s][m],10);
        if(s==='cancelled')
          exCancelled = exCancelled + parseInt(state.expense[s][m],10);
        if(s==='hold')
          exHold = exHold + parseInt(state.expense[s][m],10);
      }
    }
  }

  for(let i=0;i<state.third_party_fee.length;i++){
    exThirdPartyFee = exThirdPartyFee + parseFloat(state.third_party_fee[i].total,10);
  }

  transactionNotMatch = parseInt(state.transaction_not_match,10);
  let notRecognizedItems = {};

  if(state.revenue){
    for(let s in state.revenue){
      for(let m in state.revenue[s]){
        for(let c in state.revenue[s][m]){
          if(s==='declined')
            arDeclined = arDeclined + parseInt(state.revenue[s][m][c],10);
          else if(s==='refunded'||s==='voided'){
            if(s==='voided'){
              arVoided = arVoided + parseInt(state.revenue[s][m][c],10);
              arReceived = arReceived + parseInt(state.revenue[s][m][c],10);
            }
            arRefunded = arRefunded + parseInt(state.revenue[s][m][c],10);
          }
          else if(s==='charge_back')
            arChargedBack = arChargedBack + parseInt(state.revenue[s][m][c],10);
          else if(s==='charge_back_resolved'){
            arChargedBackResolved = arChargedBackResolved + parseInt(state.revenue[s][m][c],10);
            arReceived = arReceived + parseInt(state.revenue[s][m][c],10);
          }
          else if(s==='received'){
            arReceived = arReceived + parseInt(state.revenue[s][m][c],10);
            arReceivedPure = arReceivedPure + parseInt(state.revenue[s][m][c],10);
          }
          else if(s==='loss')
            arLoss = arLoss + parseInt(state.revenue[s][m][c],10);
          else if(s==='cancelled')
            arCancelled = arCancelled + parseInt(state.revenue[s][m][c],10);
          else if(s==='balance_due'&&(c!=='lender'&&c!=='paid_at_closing'&&c!=='paid_outside_of_closing')){
            //ar = ar + parseInt(state.revenue[s][m][c],10);
            arBalanceDue = arBalanceDue + parseInt(state.revenue[s][m][c],10);
          }
          else if(s==='overage')
            refundNeeded = refundNeeded + parseInt(state.revenue[s][m][c],10);
          else if(s==='hold')
            arHold = arHold + parseInt(state.revenue[s][m][c],10);
          else if(s==='reconcile'){
            //arReceived = arReceived + parseInt(state.revenue[s][m][c],10);
            overCharge = overCharge + parseInt(state.revenue[s][m][c],10);
          }
          else if(s==='partially_received'){
            //arReceived = arReceived + parseInt(state.revenue[s][m][c],10);
            partiallyReceived = partiallyReceived + parseInt(state.revenue[s][m][c],10);
          }
          else{
            if(s!=='pending'){
              if(!notRecognizedItems[s])
                notRecognizedItems[s] = [s+'|'+m+'|'+c];
              else
                notRecognizedItems[s].push(s+'|'+m+'|'+c);
              notRecognized = notRecognized + parseInt(state.revenue[s][m][c],10);
            }
          }
        }
      }
    }

  }

  console.log(notRecognizedItems);

  arRefunded = -1* arRefunded;
  arChargedBack = -1 * arChargedBack;

  if(state.revenue.pending){
    for(let m in state.revenue['pending']){
      for(let c in state.revenue['pending'][m]){
        if(c!=='lender'&&c!=='paid_at_closing'&&c!=='paid_outside_of_closing')
          ar = ar + parseInt(state.revenue['pending'][m][c],10);
        else if(c==='lender')
          arByLender = arByLender + parseInt(state.revenue['pending'][m][c],10);
        else if(c==='paid_at_closing')
          arPaidAtClosing = arPaidAtClosing + parseInt(state.revenue['pending'][m][c],10);
        else if(c==='paid_outside_of_closing')
          arPaidOutsideOfClosing = arPaidOutsideOfClosing + parseInt(state.revenue['pending'][m][c],10);
      }
    }
    console.log(state.revenue);
    for(let m in state.revenue['balance_due']){
      for(let c in state.revenue['balance_due'][m]){
        if(c!=='lender'&&c!=='paid_at_closing'&&c!=='paid_outside_of_closing')
          ar = ar + parseInt(state.revenue['balance_due'][m][c],10);
        else if(c==='lender')
          arByLender = arByLender + parseInt(state.revenue['balance_due'][m][c],10);
        else if(c==='paid_at_closing')
          arPaidAtClosing = arPaidAtClosing + parseInt(state.revenue['balance_due'][m][c],10);
        else if(c==='paid_outside_of_closing')
          arPaidOutsideOfClosing = arPaidOutsideOfClosing + parseInt(state.revenue['balance_due'][m][c],10);
      }
    }

    let method = [];
    let re = new RegExp('_','g');

    for(let m in state.revenue.pending)
      method.push({method:m, object:state.revenue.pending[m]});

    arBreakDown = method.map(
      (method, index)=>{
        let methodTotal = 0;
        for(let c in method.object){
          if(c!=='lender'&&c!=='paid_at_closing'&&c!=='paid_outside_of_closing')
            methodTotal = methodTotal + parseInt(method.object[c],10);
        }
        return(
            <Row key={index}>
              <Col sm="8">
                &nbsp;&nbsp;&nbsp;&nbsp;AR - {method.method.charAt(0)+method.method.replace(re,' ').slice(1)}
              </Col>
              <Col sm="4">
                <NavLink to="#" onClick={(e)=>getReportDetails('pending',method.method,'','revenue')}>${formatNumber(methodTotal)}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</NavLink>
              </Col>
            </Row>
        );
      }
    );

    let tmp = arBreakDown;
    let tmp2 =
    <Row>
      <Col sm="8">
        &nbsp;&nbsp;&nbsp;&nbsp;AR - balance due
      </Col>
      <Col sm="4">
        <NavLink to="#" onClick={(e)=>getReportDetails('balance_due','','','revenue')}>${formatNumber(arBalanceDue)}</NavLink>
      </Col>
    </Row>;

    arBreakDown = <div>
      {tmp}
      {tmp2}
    </div>;
  }

  revenueTotal = revenueTotal + parseInt(ar,10);
  revenueTotal = revenueTotal + parseInt(arByLender,10);
  revenueTotal = revenueTotal + parseInt(arPaidAtClosing,10);
  revenueTotal = revenueTotal + parseInt(arPaidOutsideOfClosing,10);
  revenueTotal = revenueTotal + parseInt(arDeclined,10);
  revenueTotal = revenueTotal + parseInt(arRefunded,10);
  revenueTotal = revenueTotal + parseInt(refundNeeded,10);
  revenueTotal = revenueTotal + parseInt(arReceived,10);

  expenseTotal = expenseTotal + parseInt(exPending,10);
  expenseTotal = expenseTotal + parseInt(exPaid,10);
  expenseTotal = expenseTotal + parseInt(exStandby,10);
  expenseTotal = expenseTotal + parseInt(exThirdPartyFee,10);



  let tmp3 = <Row>
    <Col sm="8">
      &nbsp;&nbsp;&nbsp;&nbsp;Received
    </Col>
    <Col sm="4">
      <NavLink to="#" onClick={(e)=>getReportDetails('received','','','revenue')}>${formatNumber(arReceivedPure)}</NavLink>
    </Col>
  </Row>;


  let tmp5 = <Row>
    <Col sm="8">
      &nbsp;&nbsp;&nbsp;&nbsp;Voided
    </Col>
    <Col sm="4">
      <NavLink to="#" onClick={(e)=>getReportDetails('voided','','','revenue')}>${formatNumber(arVoided)}</NavLink>
    </Col>
  </Row>;

  let tmp6 = <Row>
    <Col sm="8">
      &nbsp;&nbsp;&nbsp;&nbsp;Authorize.net Charged Back Resolved
    </Col>
    <Col sm="4">
      <NavLink to="#" onClick={(e)=>getReportDetails('charge_back_resolved','','','revenue')}>${formatNumber(arChargedBackResolved)}</NavLink>
    </Col>
  </Row>

  let receivedSub = <div>
    {tmp3}
    {tmp5}
    {tmp6}
  </div>

  let reportFiltersConfig = [
    {id:'from',value:from, updateFunc:(v)=>setState({from:v}), width:'3'},
    {id:'to',value:to, updateFunc:(v)=>setState({to:v}), width:'3'},
    {id:'customSelect',value:state.accrued, updateFunc:(v)=>setState({accrued:v}), width:'3', label:'Accrued Based', clearable:false, className:"", options:[{label:"No",value:"no"},{label:"Yes",value:"yes"}]},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'3'},
    {id:'openBy',value:state.openBy, updateFunc:(v)=>setState({openBy:v}), width:'3'},
    {id:'appraiser',value:state.appraiser, updateFunc:(v)=>setState({appraiser:v}), width:'3'},
    {id:'rush',value:state.isRush, updateFunc:(v)=>setState({isRush:v}), width:'3'},
    {id:'complex',value:state.isComplex, updateFunc:(v)=>setState({isComplex:v}), width:'3'},
    {id:'state',value:state.state, updateFunc:(v)=>setState({state:v}), width:'3'},
    {id:'city',value:state.city, updateFunc:(v)=>setState({city:v}), width:'3'},
    {id:'county',value:state.filterCounty, updateFunc:(v)=>setState({filterCounty:v}), width:'3', group:true},
    {id:'loanPurpose',value:state.loanPurpose, updateFunc:(v)=>setState({loanPurpose:v}), width:'3'},
    {id:'loanType',value:state.loanType, updateFunc:(v)=>setState({loanType:v}), width:'3'},
    {id:'entity',value:state.entity, updateFunc:(v)=>setState({entity:v}), width:'3'},
    {id:'appraisalType',value:state.filterAppraisalTypes, updateFunc:(v)=>setState({filterAppraisalTypes:v}), width:'3'},
    {id:'button',value:'Submit', updateFunc:getReport, width:'3', className:"align-right", color:"warning"},
  ];


  return <div>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Fee Report
      </CardHeader>
      <CardBody>
        <ReportFilter {...props} configs={reportFiltersConfig}/>
      </CardBody>
    </Card>
    <br/>
    <i>*Spread calculations are based on all submitted orders within a calendar month or inputted date parameters and exclude the following billing status - "Charge Back", "Cancelled","Hold" and "Loss"</i>
    <br/>
    <i>*For all Non-Accrual based calculations are for orders with paid receivables and payables within the calendar month or inputted date parameters.</i>
    <br/>
    <br/>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Report
      </CardHeader>
      <CardBody>
        <Row>
          <Col sm="6" style={{borderRight:'1px solid #d2d2d2'}}>
            <Row>
              <Col sm="12">
                <b><center><font color="green">Revenue</font></center></b>
              </Col>
            </Row>
            <Row>
              <Col sm="8">
                <label>AR</label>
              </Col>
              <Col sm="4" className="align-right">
                <NavLink to="#" onClick={(e)=>getReportDetails('pending','','','revenue')}>${formatNumber(ar)}</NavLink>
              </Col>
            </Row>
            {arBreakDown}
            <Row>
              <Col sm="8">
                <label>AR At Closing</label>
              </Col>
              <Col sm="4" className="align-right">
                <NavLink to="#" onClick={(e)=>getReportDetails('pending','','paid_at_closing','revenue')}>${formatNumber(arPaidAtClosing)}</NavLink>
              </Col>
            </Row>
            <Row>
              <Col sm="8">
                <label>AR Outside Of Closing</label>
              </Col>
              <Col sm="4" className="align-right">
                <NavLink to="#" onClick={(e)=>getReportDetails('pending','','paid_outside_of_closing','revenue')}>${formatNumber(arPaidOutsideOfClosing)}</NavLink>
              </Col>
            </Row>
            <Row>
              <Col sm="8">
                <label>AR By Lender</label>
              </Col>
              <Col sm="4" className="align-right">
                <NavLink to="#" onClick={(e)=>getReportDetails('pending','','lender','revenue')}>${formatNumber(arByLender)}</NavLink>
              </Col>
            </Row>
            <Row>
              <Col sm="8">
                <label>Received</label>
              </Col>
              <Col sm="4" className="align-right">
                <NavLink to="#" onClick={(e)=>getReportDetails('received_mix','','','revenue')}>${formatNumber(arReceived)}</NavLink>
              </Col>
            </Row>
            {receivedSub}
            <Row>
              <Col sm="8">
                <label>Refunded</label>
              </Col>
              <Col sm="4" className="align-right">
                <NavLink to="#" onClick={(e)=>getReportDetails('refunded','','','revenue')}>${formatNumber(arRefunded)}</NavLink>
              </Col>
            </Row>

            <Row>
              <Col sm="8">
                <label>Overage</label>
              </Col>
              <Col sm="4" className="align-right">
                <NavLink to="#" onClick={(e)=>getReportDetails('overage','','','revenue')}>${formatNumber(refundNeeded)}</NavLink>
              </Col>
            </Row>
            <br/>
            <Row>
              <Col sm="8">
                <label>Authorize.net Declined</label>
              </Col>
              <Col sm="4" className="align-right">
                <NavLink to="#" onClick={(e)=>getReportDetails('declined','','','revenue')}>${formatNumber(arDeclined)}</NavLink>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col sm="12">
                <b><center><font color="red">Expense</font></center></b>
              </Col>
            </Row>

            <Row>
              <Col sm="8">
                <label>To be Paid (Payable)</label>
              </Col>
              <Col sm="4" className="align-right">
                <NavLink to="#" onClick={(e)=>getReportDetails('pending','','','expense')}>${formatNumber(exPending)}</NavLink>
              </Col>
            </Row>

            <Row>
              <Col sm="8">
                <label>Paid</label>
              </Col>
              <Col sm="4" className="align-right">
                <NavLink to="#" onClick={(e)=>getReportDetails('paid','','','expense')}>${formatNumber(exPaid)}</NavLink>
              </Col>
            </Row>
            <Row>
              <Col sm="8">
                <label>Standby</label>
              </Col>
              <Col sm="4" className="align-right">
                <NavLink to="#" onClick={(e)=>getReportDetails('standby','','','expense')}>${formatNumber(exStandby)}</NavLink>
              </Col>
            </Row>

            <Row>
              <Col sm="12">&nbsp;</Col>
            </Row>
            <Row>
              <Col sm="12">&nbsp;</Col>
            </Row>
            <Row>
              <Col sm="8">
                <label>Third Party Fee</label>
              </Col>
              <Col sm="4" className="align-right">
                <NavLink to="#" onClick={(e)=>getReportDetails('all','','','third_party_fee')}>${formatNumber(exThirdPartyFee)}</NavLink>
              </Col>
            </Row>

            {
              state.third_party_fee.map(
                (fee, index)=>{
                  return <Row key={index}>
                    <Col sm="8">
                      <label>&nbsp;&nbsp;&nbsp;&nbsp;{fee.description}</label>
                    </Col>
                    <Col sm="4" className="align-right">
                      <NavLink to="#" onClick={(e)=>getReportDetails(fee.description,'','','third_party_fee')}>${formatNumber(fee.total)}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</NavLink>
                    </Col>
                  </Row>;
                }
              )
            }
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <Row>
              <Col sm="6">
                <b>Grand total</b>
              </Col>
              <Col sm="6" className="align-right" style={{fontWeight:'bold',borderBottom:'1px solid #ccc',borderTop:'1px solid #ccc'}}>
                ${formatNumber(revenueTotal)}
              </Col>
            </Row>
          </Col>
          <Col sm="6">
            <Row>
              <Col sm="6">
                <b>Grand total</b>
              </Col>
              <Col sm="6" className="align-right" style={{fontWeight:'bold',borderBottom:'1px solid #ccc',borderTop:'1px solid #ccc'}}>
                ${formatNumber(expenseTotal)}
              </Col>
            </Row>
          </Col>
        </Row>
        <br/>
        <Row>
          <Col sm="6"></Col>
          <Col sm="6">
            <Row>
              <Col sm="6">
                <b>**Net Revenue**</b>
              </Col>
              <Col sm="6" className="align-right" style={{fontWeight:'bold',borderBottom:'1px solid #ccc',borderTop:'1px solid #ccc'}}>
                ${formatNumber(revenueTotal-expenseTotal)}
              </Col>
            </Row>
          </Col>
        </Row>
        <br/>
        <Row>
          <Col sm="6">
            <Row>
              <Col sm="8">
                <label>Total order associated</label>
              </Col>
              <Col sm="4" className="align-right">
                {formatNumber(state.revenue_order_count)}
              </Col>
            </Row>
          </Col>
          <Col sm="6">
            <Row>
              <Col sm="8">
                <label>Total order associated</label>
              </Col>
              <Col sm="4" className="align-right">
                {formatNumber(state.expense_order_count)}
              </Col>
            </Row>
            <Row>
              <Col sm="8">
                <label>Avg Spread</label>
              </Col>
              <Col sm="4" className="align-right">
                ${formatNumber(((revenueTotal-expenseTotal)/state.expense_order_count))}
              </Col>
            </Row>
          </Col>
        </Row>
        <br/><br/>
        <Row>
          <Col sm="6">
            <Row>
              <Col sm="12">
                <b><center><font color="purple">Not Included</font></center></b>
              </Col>
            </Row>
          </Col>
          <Col sm="6">
            <Row>
              <Col sm="12">
                <b><center><font color="purple">Not Included</font></center></b>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col sm="6">
            <Row>
              <Col sm="8">
                <label>Authorize.net Charged Back</label>
              </Col>
              <Col sm="4" className="align-right">
                <NavLink to="#" onClick={(e)=>getReportDetails('charge_back','','','revenue')}>${formatNumber(arChargedBack)}</NavLink>
              </Col>
            </Row>

            <Row>
              <Col sm="8">
                <label>Cancelled</label>
              </Col>
              <Col sm="4" className="align-right">
                <NavLink to="#" onClick={(e)=>getReportDetails('cancelled','','','revenue')}>${formatNumber(arCancelled)}</NavLink>
              </Col>
            </Row>
            <Row>
              <Col sm="8">
                <label>Hold</label>
              </Col>
              <Col sm="4" className="align-right">
                <NavLink to="#" onClick={(e)=>getReportDetails('hold','','','revenue')}>${formatNumber(arHold)}</NavLink>
              </Col>
            </Row>
            <Row>
              <Col sm="8">
                <label>Loss</label>
              </Col>
              <Col sm="4" className="align-right">
                <NavLink to="#" onClick={(e)=>getReportDetails('loss','','','revenue')}>${formatNumber(arLoss)}</NavLink>
              </Col>
            </Row>


            <br/><br/>
            <Row>
              <Col sm="12">
                <b><center><font color="orange">Error</font></center></b>
              </Col>
            </Row>


            <Row>
              <Col sm="8">
                <label>Transaction Not Match</label>
              </Col>
              <Col sm="4" className="align-right">
                <NavLink to="#" onClick={(e)=>getReportDetails('transaction_not_match','','','transaction_not_match')}>{formatNumber(transactionNotMatch)}</NavLink>
              </Col>
            </Row>
            <Row>
              <Col sm="8">
                <label>Not Recognized</label>
              </Col>
              <Col sm="4" className="align-right">
                <NavLink to="#" onClick={(e)=>getReportDetails('','','','revenue')}>${formatNumber(notRecognized)}</NavLink>
              </Col>
            </Row>
          </Col>
          <Col sm="6">



            <Row>
              <Col sm="8">
                <label>Cancelled</label>
              </Col>
              <Col sm="4" className="align-right">
                <NavLink to="#" onClick={(e)=>getReportDetails('cancelled','','','expense')}>${formatNumber(exCancelled)}</NavLink>
              </Col>
            </Row>
            <Row>
              <Col sm="8">
                <label>Hold</label>
              </Col>
              <Col sm="4" className="align-right">
                <NavLink to="#" onClick={(e)=>getReportDetails('hold','','','expense')}>${formatNumber(exHold)}</NavLink>
              </Col>
            </Row>


            <br/>
            <Row>
              <Col sm="6">
                <b>Still owe as of {state.to}</b>
              </Col>
              <Col sm="6" className="align-right" style={{fontWeight:'bold',borderBottom:'1px solid #ccc',borderTop:'1px solid #ccc'}}>
                <NavLink to="#" onClick={(e)=>getReportDetails('still_owe_as_of','','','expense')}>${formatNumber(state.still_owe_as_of)}</NavLink>
              </Col>
            </Row>
          </Col>
        </Row>

        <br/>
        <Row>
          <Col sm="6" style={{borderRight:'1px solid #d2d2d2'}}>
          </Col>
          <Col>
          </Col>
        </Row>
      </CardBody>
    </Card>

    <Modal className="my-modal-wide" isOpen={state.reportDetailsDropDown} toggle={reportDetailsToggle} >
      <ModalHeader hidden={true} toggle={reportDetailsToggle}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-user"></i> Report Details</h5>
        </center>
        <br/>
        <div className="large-scroll-container">
          {content}
        </div>
        <br/>
        <center>
          <Button color="info" onClick={reportDetailsToggle}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
  </div>;
}


export default FeeReport;
