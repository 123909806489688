//Author Sooyoung Kim
//Date Aug 22,
import React, {useEffect} from 'react';
import {generateTooltip} from '../../util/util';
import {Col, Row, Input} from 'reactstrap';
import MySelect from '../util/my-select';

const CommonAmenity  = (props)=>{
  const controller = new AbortController();

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const updateField = (field, value) => {
    if(!props.disabled){
      let commonAmenities = props.commonAmenities.slice();
      let target = commonAmenities[props.index];
      if(value==='None'){
        target[field] = ['None'];
      }
      else if(value!==''){
        let index = target[field].indexOf('None');

        if(index!==-1)
          target[field].splice(index, 1);

        index = target[field].indexOf(value);

        if(index===-1)
          target[field].push(value);
      }
      commonAmenities[props.index] = target;

      props.updateState({commonAmenities:commonAmenities});
    }
  }

  const updateState = (field, value) => {
    let commonAmenities = props.commonAmenities.slice();
    let target = commonAmenities[props.index];

    target[field] = value;

    commonAmenities[props.index] = target;

    props.updateState({commonAmenities:commonAmenities});
  }


  //render
  let disabledStyling = {border:'none', background:'#fff', padding:'0',paddingLeft:'2px', height:'auto',WebkitAppearance:'none'};
  let amenitySubTypes;
  let options;

  if(props.commonAmenities[props.index].amenityType==='Fence')
    options = <MySelect
          type="select"
          style={props.disabled?disabledStyling:{}}
          disabled={props.disabled}
          required={props.commonAmenities[props.index].amenitySubTypes.length<=0}
          value={""}
          onChange={(v)=>updateField('amenitySubTypes',v)}
          options={[
            {label:"Wood",value:"Wood"},
            {label:"ChainLink",value:"ChainLink"},
            {label:"Composite",value:"Composite"},
            {label:"Aluminum",value:"Aluminum"},
            {label:"Iron",value:"Iron"},
            {label:"Vinyl",value:"Vinyl"}
          ]}
        />
  else if(props.commonAmenities[props.index].amenityType==='AboveGroundPool')
    options = <MySelect
          type="select"
          style={props.disabled?disabledStyling:{}}
          disabled={props.disabled}
          required={props.commonAmenities[props.index].amenitySubTypes.length<=0}
          value={""}
          onChange={(v)=>updateField('amenitySubTypes',v)}
          options={[
            {label:"DeckSurround",value:"Deck Surround"},
            {label:"SemiInGround",value:"Semi In Ground"}
          ]}
        />;
  else if(props.commonAmenities[props.index].amenityType==='InGroundPool')
    options = <MySelect
            type="select"
            style={props.disabled?disabledStyling:{}}
            disabled={props.disabled}
            required={props.commonAmenities[props.index].amenitySubTypes.length<=0}
            value={""}
            onChange={(v)=>updateField('amenitySubTypes',v)}
            options={[
              {label:"Concrete",value:"Concrete"},
              {label:"Gunite",value:"Gunite"},
              {label:"Vinyl",value:"Vinyl"},
              {label:"Fiberglass",value:"Fiberglass"},
              {label:"Optimum",value:"Optimum"},
              {label:"BuiltInSpa",value:"Built In Spa"}
            ]}
          />;

  if(options)
    amenitySubTypes = <Col sm="4">
      <label><font color="red">*</font>{props.commonAmenities[props.index].amenityType} Sub Type {generateTooltip('commonAmenitySubTypes'+props.index, 'Select all that apply.')}</label>
      {options}
      <div className="extra-small-scroll-container">
      {
        props.commonAmenities[props.index].amenitySubTypes.map(
          (amenitySubType, index)=>{
            return(
              <div key={index} className="display-inline margin-top">
                <div className="filter-container" onClick={()=>updateField('amenitySubTypes', amenitySubType)}>
                  <i hidden={props.disabled} className="fa fa-minus red-color"></i> {amenitySubType}
                </div>
                &nbsp;
              </div>
            );
          }
        )
      }
      </div>
    </Col>;

  let amenityTypeOtherDescription;

  if(props.commonAmenities[props.index].amenityType==='Other')
    amenityTypeOtherDescription = <Col sm="3">
      <label><font color="red">*</font>Other Description</label>
      <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={true} type="text" value={props.commonAmenities[props.index].amenityTypeOtherDescription} onChange={(e)=>{updateState('amenityTypeOtherDescription',e.target.value)}}/>
    </Col>;

  return <div>
    <Row style={{marginTop:'15px'}}>
      {amenitySubTypes}
      {amenityTypeOtherDescription}
      <Col sm="4">
        <label><font color="red">*</font>{props.commonAmenities[props.index].amenityType} Count</label>
        <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={true} type="number" value={props.commonAmenities[props.index].amenityCount} onChange={(e)=>{updateState('amenityCount',e.target.value)}}/>
      </Col>
    </Row>
  </div>;
}


export default CommonAmenity;
