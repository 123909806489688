//Author Sooyoung Kim
//Date April 25, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, putAPICallGenerator, deleteAPICallGenerator, callBackGenerator, confirmation} from '../../util/util';
import {Button, Col, Row, Input} from 'reactstrap';
import {NavLink, useParams, useNavigate} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';


//initialize the state
const initialState = {
  templateTags:[],
  template:{},

  templateName:'',
  templateText:''
};

//reducer function that perform state update
const reducer = getReducer();


const GlobalCommentTemplate  = (props)=>{
  const controller = new AbortController();
  const { id } = useParams();
  const navigate = useNavigate();

  let newInitialState = Object.assign({}, initialState, {id:id});
  const [state, dispatch] = useReducer(reducer, newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getTemplate();
    getTemplateTags();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const addTag = (e,tag) =>{
    e.preventDefault();
    setState({templateText:state.templateText+' [:'+tag+']'});
  }

  //API call
  const deleteTemplate = () =>{
    let url = 'template/global/'+state.id;
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;

      if(code==='00'){
        navigate('/global-comment-template');
      }
    };
    callBack = callBack.bind(this);

    httpDelete(url,'Template deleted successfully.','Oops, something went wrong and could not delete the template. Please try again later.', callBack);
  }

  const getTemplateTags = () =>{
    let url = 'template/tag/get';
    let callBack = apiCallBack([{state:'templateTags', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load tags for template. Please try again later.', callBack);
  }

  const updateTemplate = () =>{
    let url = 'template/global/update';

    let parameters = [
      {
        field:'ID',
        value:state.id
      },
      {
        field:'name',
        value:state.templateName
      },
      {
        field:'template',
        value:state.templateText
      }
    ];

    httpPut(url, parameters, 'Template update successfully.', 'Oops, something went wrong and could not update the template. Please try again later.', ()=>{});
  }

  const getTemplate = () =>{
    let url = 'template/global/'+state.id;
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code!=='00'){

      }
      else{
        setState({templateName:response.data.data.name,templateText:response.data.data.template});
      }
    };
    callBack = callBack.bind(this);

    httpGet(url, '', 'Oops, something went wrong and could not load template. Please try again later.', callBack);
  }

  //render
  let templateTags;

  if(state.templateTags.length>0){
    templateTags = state.templateTags.map(
      (tag, index)=>{
        return(
          <div key={index} className="display-inline template-tag" onClick={(e)=>{addTag(e,tag.tag);return false;}}>
            {tag.tag}
          </div>
        );
      }
    );
  }

  return <div className="my-well">
    <Row>
      <Col sm="6">
        <div className="page-title">
          <i className="fa fa-reorder"></i>&nbsp;Settings
        </div>
        <NavLink to="/global-comment-template">Back to global comment template</NavLink>
      </Col>
      <Col sm="6">
        <div className="align-right">
          <Button color="danger" onClick={
            ()=>{
              confirmation(
                ()=>{deleteTemplate()},
                ()=>{},
                'Delete template?',
                'Are you sure you want to delete this template?');
            }
          }><i className="fa fa-trash"></i>&nbsp;&nbsp;Delete</Button>&nbsp;&nbsp;
          <Button color="warning" onClick={updateTemplate}><i className="fa fa-edit"></i>&nbsp;&nbsp;Update</Button>
        </div>
      </Col>
    </Row>
    <div className="my-divider"></div>
    <label><font color="red">*</font>Name</label>
    <Input required={true} type="text" value={state.templateName} onChange={(e)=>setState({templateName:e.target.value})}/>

    <label><font color="red">*</font>Template</label>
    <Input required={true} type="textarea" rows="10" style={{resize:'none'}} value={state.templateText} onChange={(e)=>setState({templateText:e.target.value})}/>
    <br/>
    <b>Tags:</b> {templateTags}
  </div>;
}

export default GlobalCommentTemplate;
