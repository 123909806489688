//Sooyoung Kim
//Oct 26, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, deleteAPICallGenerator, callBackGenerator, formatNumber, formatDateTime, stringToHexCode, confirmation, generateSID, sliceFromArray, replaceRegex} from '../../util/util';
import {Col, Row, Table, Card, CardHeader, CardBody, Nav, NavItem,TabContent, TabPane, NavLink as NavLinkL} from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import React, {useReducer, useEffect, useRef} from 'react';
import MyReactTable from '../util/my-react-table';
import ReportFilter from '../report/report-filter';
import {NavLink} from 'react-router-dom';
import MySelect from '../util/my-select';
import TodayStat from './today-stat';
import Avatar from 'react-avatar';
import ReactDOM from 'react-dom';
import moment from 'moment';
import './dashboard.css';
import 'react-datepicker/dist/react-datepicker.css';


//initialize the state
const initialState = {
  lastID:-1,
  sID: generateSID(),
  activeTab:'3',
  allComments:[],
  internalEscalation:[],
  expedite:[],
  receivable:[],
  pipelineAndOverdueActiveTab:'1',
  hasMoreNewComments:true,
  limit: 100,
  offset: 0,
  loadingNewComments: false,
  totalNewCommentsCount:0,
  selectedNewComment:-1,
  selectedOrderForReply:-1,
  replyToNewComment:'',
  targetRecipients:[],
  selectedRecipient:-1,
  entities:[],

  statuses: [{ID:'1',name:'Submitted'},{ID:'2',name:'Auto Assigning'},{ID:'3',name:'Assigned'},{ID:'4',name:'Pending Inspection'},{ID:'5',name:'Inspection Scheduled'},{ID:'6',name:'Inspection Completed'},{ID:'7',name:'Review'},{ID:'8',name:'Shipping'},{ID:'9',name:'Completed'},{ID:'10',name:'Hold'},{ID:'11',name:'Cancelled'},{ID:'12',name:'Conditioned'},{ID:'13',name:'Rebuttal'}],
  status:'',

  stats:{
    order_count:0,
    quote_count:0,
    spread:0,
    non_full_appraisal_count:0,
    submitted_count:0,
    submitted_stale_count:0,
    bid_count:0,
    bid_stale_count:0,
    bid_to_review_count:0,
    bid_to_review_stale_count:0,
    quote_stale_count:0,
    pending_inspection_count:0,
    inspection_completed_count:0,
    inspection_scheduled_count:0,
    pending_inspection_stale_count:0,
    waiting_on_poc_count:0,
    inspection_today:0,
    due_today:0,
    past_due:0,
  },
  submittedOrders:0,
  latePayments:0,
  holdOrders:0,
  assignedOrders:0,
  waitingOnContacts:0,
  newConditions:0,
  newRebuttals:0,
  lateAssignments:0,
  lateSchedulings:0,
  lateReports:0,
  lateConditions:0,
  lateRebuttals:0,
  dueOrders:0,
  accountingAudits:0,

  dataRows:[],

  startDate:'0000-00-00',
  endDate:'0000-00-00',
  hotfile:[],
  nuclearfile:[],
  filter:'all',

  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
  clients:[],
  crm:[],

  state:'',
  from:'',
  to:'',
  client:'',
  taggedAccountManagerEmail:'',

  todayStatsDetails:[],
  todayStatsDropDown:false,
  todayStatsDetailsType:'',
  entity:'',
  street:'',
  city:'',
};

//reducer function that perform state update
const reducer = getReducer();


const DashboardSimplify  = (props)=>{
  const controller = new AbortController();
  const newsid = useRef(generateSID());
  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getHotfile();
    getNuclearfile();
    getDashboardStats();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    if(state.allComments.length<=0 && state.hasMoreNewComments){
      loadMoreNewComments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state]);


  //non API call but simpyl manage state
  const filterOnChange = (filter) => {
    setState({filter:filter});
    refreshComment();
  }

  const activeTabToggle = (tab) => {
    setState({activeTab:tab});

    switch(tab){
      case '2':

        break;
      case '3':

        break;
      case '4':

        break;
      case '5':
        if(state.dataRows.length===0){
          getDashboardPipeline();
          getDashboardPipelineData('Submitted');
        }
        break;
      case '6':
        if(state.receivable.length===0){
          getReceivable();
        }
        break;
      case '7':
        if(state.internalEscalation.length===0){
          getInternalEscalation();
        }
        break;
      case '10':
        if(state.crm.length===0){
          getCRM();
        }
        break;
      case '9':
        if(state.expedite.length===0){
          getExpedite();
        }
        break;
      default:
        break;
    }
  }

  const refreshComment = () => {
    let SID = generateSID();
    newsid.current = SID; 

    setState({
      lastID:-1,
      allComments:[],
      hasMoreNewComments:true,
      loadingNewComments:false,
      offset:0
    });
  }

  const refreshList = () => {
    let SID = generateSID();
    newsid.current = SID; 
  
    setState({
      lastID:-1,
      sID:generateSID(),
      allComments:[],
      hasMoreNewComments:true, 
      loadingNewComments:false,
      offset:0
    });

    getHotfile();
    getNuclearfile();
    getDashboardStats();
    getDashboardPipeline();
    getDashboardPipelineData('Submitted');
    getInternalEscalation();
    getExpedite();
    getReceivable();
  }

  const pipelineAndOverdueTabToogle = (tab) => {
    if(tab!==state.pipelineAndOverdueActiveTab){
      switch(tab){
        case '1':
          getDashboardPipelineData('Submitted');
          break;
        case '2':
          getDashboardPipelineData('Assigned');
          break;
        case '3':
          getDashboardPipelineData('Waiting On Contact');
          break;
        case '4':
          getDashboardPipelineData('New Condition');
          break;
        case '5':
          getDashboardPipelineData('New Rebuttal');
          break;
        case '6':
          getDashboardPipelineData('Late Assignment');
          break;
        case '7':
          getDashboardPipelineData('Late Scheduling');
          break;
        case '8':
          getDashboardPipelineData('Late Report');
          break;
        case '9':
          getDashboardPipelineData('Late Condition');
          break;
        case '10':
          getDashboardPipelineData('Late Rebuttal');
          break;
        case '12':
          getDashboardPipelineData('Due');
          break;
        case '13':
          getDashboardPipelineData('Accounting Audit');
          break;
        case '14':
          getDashboardPipelineData('Hold');
          break;
        default:
          break;
      }
    }

    ReactDOM.unstable_batchedUpdates(() => {
      setState({pipelineAndOverdueActiveTab:tab, dataRows:[]});
    });
  }

  //API call
  const loadMoreNewComments = () => {
    //do not load if there is no more conditions or it's loading data
    if(state.hasMoreNewComments&&!state.loadingNewComments){
      //set loading equals to true so it won't fire off before we are done
      setState({loadingNewComments:true});
      let sID = newsid.current;

      let street = replaceRegex(state.street);
      let city = replaceRegex(state.city);
      let entity = replaceRegex(state.entity);

      let url = 'dashboard/newComment/simplify/v3/get/lastID='+state.lastID+'&status='+state.status+'&limit='+state.limit+'&offset='+state.offset+'&state='+state.state+'&from='+state.from+'&to='+state.to+'&client='+state.client+'&filter='+state.filter+'&street='+street+'&city='+city+'&entity='+entity;

      //callback handler that update the state when http request return
      let callBack = (response)=>{
        let code = response.data?response.data.code:undefined;

        if(sID===newsid.current){
          if(code!=='00'){
            setState({hasMoreNewComments:false});
          }
          else{
            let newComments = response.data.data;
            let hasMoreNewComments = true;
            let newOffset = state.offset;
            let totalCount = response.data.count;
            let lastID = response.data.data.length>0?response.data.data[response.data.data.length-1].ID:state.lastID;

            //if http request return empty then no more results, end of list
            if(newComments.length<=0){
              hasMoreNewComments = false;
            }else{
              //increment the offset
              newOffset = state.offset + 1;
              if(newComments.length<state.limit)
                hasMoreNewComments = false;
            }

            let comments = [];
            //concat the current array of announcement
            if(state.allComments.length>0){
              let existingComments = state.allComments.slice();
              existingComments = existingComments.concat(newComments);
              comments = existingComments;
              setState({});
            }
            else
              comments = newComments;

            setState({totalNewCommentsCount:totalCount, hasMoreNewComments:hasMoreNewComments,offset:newOffset, allComments:comments, lastID:lastID});
          }
        }
      };
      callBack = callBack.bind(this);

      let errorCallBack = apiCallBack([{state:'hasMoreNewComments', value:false}]);

      //collect the promise and wait for it to finish performing it's task
      let promises = httpGet(url, '','Oops, something went wrong and could not load new comments. Please try again later.', callBack, errorCallBack);
      promises
        .then(
          function(result){
            //set loading equals to false so the function could be fire off once again
            setState({loadingNewComments:false});
          }
        );
    }
  }


  const ignoreComment = (ID) => {
    let parameters = [{field:'commentFk',value:ID}];

    let list = sliceFromArray(state.allComments,'ID', ID);
    let callBack = apiCallBack([{state:'allComments', value:list}, {state:'replyToNewComment', value:''}, {state:'targetRecipients', value:[]}, {state:'selectedNewComment', value:-1}, {state:'selectedOrderForReply', value:-1}, {state:'totalNewCommentsCount', value:state.totalNewCommentsCount - 1}]);

    httpPost('comment/ignore', parameters, 'Comment marked as read successfully.', 'Oops, something went wrong and could not mark this comment as read. Please try again later.', callBack);
  }

  //get dashboard pipeline
  const getDashboardPipeline = () => {
    let city = replaceRegex(state.city);
    let street = replaceRegex(state.street);
    let entity = replaceRegex(state.entity);

    let url = 'dashboard/pipeline/simplify/get/status='+state.status+'&state='+state.state+'&from='+state.from+'&to='+state.to+'&client='+state.client+'&street='+street+'&city='+city+'&entity='+entity;

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        ReactDOM.unstable_batchedUpdates(() => {
          setState({
            accountingAudits:response.data.data.accounting_audits,
            submittedOrders:response.data.data.submitted,
            holdOrders:response.data.data.hold,
            assignedOrders:response.data.data.assigned,
            waitingOnContacts:response.data.data.waiting_on_contact,
            newConditions:response.data.data.new_condition,
            newRebuttals:response.data.data.new_rebuttal,
            lateAssignments:response.data.data.late_assignment,
            lateSchedulings:response.data.data.late_scheduling,
            lateReports:response.data.data.late_report,
            lateConditions:response.data.data.late_condition,
            lateRebuttals:response.data.data.late_rebuttal,
            latePayments:response.data.data.late_payment,
            dueOrders:response.data.data.due
          });
        });
      }
    };
    callBack = callBack.bind(this);

    httpGet(url, '', 'Oops, something went wrong and could not load your dashboard pipeline. Please try again later.', callBack);
  }

  const getDashboardPipelineData = (dataRequested) => {
    let city = replaceRegex(state.city);
    let street = replaceRegex(state.street);
    let entity = replaceRegex(state.entity);

    let url = 'dashboard/pipeline/simplify/get/data/status='+state.status+'&state='+state.state+'&from='+state.from+'&to='+state.to+'&dataRequested='+dataRequested+'&client='+state.client+'&street='+street+'&city='+city+'&entity='+entity;

    let callBack = apiCallBack([{state:'dataRows', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load your dashboard pipeline. Please try again later.', callBack);
  }

  //get hotfile
  const getHotfile = () => {
    let city = replaceRegex(state.city);
    let street = replaceRegex(state.street);
    let entity = replaceRegex(state.entity);

    let url = 'hotfile/personal/get/status='+state.status+'&state='+state.state+'&from='+state.from+'&to='+state.to+'&client='+state.client+'&street='+street+'&city='+city+'&entity='+entity;

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let hotfile = [];

        for(let i=0;i<response.data.data.length;i++)
          hotfile.push(response.data.data[i]);

        //sort by sub category
        hotfile.sort((a, b )=>{
          if(a.sub_category>b.sub_category)
            return 1;
          return -1;
        });
        setState({hotfile:hotfile});
      }
    };
    callBack = callBack.bind(this);

    httpGet(url, '', 'Oops, something went wrong and could not load your dashboard stats. Please try again later.', callBack);
  }

  //get nuclear file
  const getNuclearfile = () => {
    let city = replaceRegex(state.city);
    let street = replaceRegex(state.street);
    let entity = replaceRegex(state.entity);

    let url = 'nuclearfile/filter/get/status='+state.status+'&state='+state.state+'&from='+state.from+'&to='+state.to+'&client='+state.client+'&street='+street+'&city='+city+'&entity='+entity;

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let nuclearfile = [];

        for(let i=0;i<response.data.data.length;i++)
          nuclearfile.push(response.data.data[i]);

        //sort by sub category
        nuclearfile.sort((a, b )=>{
          if(a.sub_category>b.sub_category)
            return 1;
          return -1;
        });
        setState({nuclearfile:nuclearfile});
      }
    };
    callBack = callBack.bind(this);

    httpGet(url, '', 'Oops, something went wrong and could not load your dashboard stats. Please try again later.', callBack);
  }


  //get dashboard stats
  const getDashboardStats = () => {
    let city = replaceRegex(state.city);
    let street = replaceRegex(state.street);
    let entity = replaceRegex(state.entity);

    let url = 'dashboard/stats/get/status='+state.status+'&state='+state.state+'&from='+state.from+'&to='+state.to+'&client='+state.client+'&street='+street+'&city='+city+'&entity='+entity;
    let callBack = apiCallBack([{state:'stats', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load your dashboard stats. Please try again later.', callBack);
  }

  const removeHotfile = (type,id) => {
    let list = sliceFromArray(state.hotfile,'ID', id);
    let callBack = apiCallBack([{state:'hotfile', value:list}]);

    httpDelete('hotfile/'+type.toLowerCase()+'/'+id,'','Oops, something went wrong and could not delete this hotfile. Please try again later.', callBack);
  }

  const getCRM = () => {
    let city = replaceRegex(state.city);
    let street = replaceRegex(state.street);
    let entity = replaceRegex(state.entity);

    let url = 'crm/get/status='+state.status+'&state='+state.state+'&from='+state.from+'&to='+state.to+'&client='+state.client+'&street='+street+'&city='+city+'&entity='+entity;

    let callBack = apiCallBack([{state:'crm', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load CRM. Please try again later.', callBack);
  }

  const getInternalEscalation = () => {
    let city = replaceRegex(state.city);
    let street = replaceRegex(state.street);
    let entity = replaceRegex(state.entity);

    let url = 'dashboard/escalation/internal/personal/status='+state.status+'&state='+state.state+'&from='+state.from+'&to='+state.to+'&client='+state.client+'&street='+street+'&city='+city+'&entity='+entity;

    let callBack = apiCallBack([{state:'internalEscalation', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load escalation. Please try again later.', callBack);
  }

  const getExpedite = () => {
    let city = replaceRegex(state.city);
    let street = replaceRegex(state.street);
    let entity = replaceRegex(state.entity);

    let url = 'dashboard/expedite/personal/status='+state.status+'&state='+state.state+'&from='+state.from+'&to='+state.to+'&client='+state.client+'&street='+street+'&city='+city+'&entity='+entity;
    let callBack = apiCallBack([{state:'expedite', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load escalation. Please try again later.', callBack);
  }

  const getReceivable = () => {
    let city = replaceRegex(state.city);
    let street = replaceRegex(state.street);
    let entity = replaceRegex(state.entity);

    let url = 'dashboard/receivable/personal/status='+state.status+'&state='+state.state+'&from='+state.from+'&to='+state.to+'&client='+state.client+'&street='+street+'&city='+city+'&entity='+entity;
    let callBack = apiCallBack([{state:'receivable', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load pending receivable Please try again later.', callBack);
  }

  const removeNuclearfile = (ID) => {
    let list = sliceFromArray(state.nuclearfile,'ID', ID);
    let callBack = apiCallBack([{state:'nuclearfile', value:list}]);
    httpDelete('nuclearfile/order/'+ID,'Removed from nuclear file.','Oops, something went wrong and could not delete the nuclear file. Please try again later.', callBack);
  }

  const removeCRM = (ID) => {
    let list = sliceFromArray(state.crm,'appraisal_fk', ID);
    let callBack = apiCallBack([{state:'crm', value:list}]);
    httpDelete('crm/'+ID,'Removed from CRM.','Oops, something went wrong and could not delete the CRM. Please try again later.', callBack);
  }

  //render
  let from, to;
  if(state.from!=='')
    from = moment(state.from);
  if(state.to!=='')
    to = moment(state.to);

  let hotfile;

  if(state.hotfile.length>0){
    hotfile = state.hotfile.map(
      (f, index)=>{
        let url = '/appraisal/';
        if(f.category==='Order')
          url = '/appraisal/';
        else if(f.category==='Condition')
          url = '/condition/';
        else if(f.category==='Rebuttal')
          url = '/rebuttal/';

        let acIcon = '';
        if(f.account_manager !=='')
          acIcon = <Avatar size={30} style={{overflow:'hidden'}} textSizeRatio={1.5} color={"#"+stringToHexCode(f.account_manager_email)} name={f.account_manager}/>;
        return(
          <tr key={index}>
            <td>
              <Row>
                <Col sm="11">
                  {acIcon}&nbsp;
                  <p className="display-inline">
                    <b>{f.sub_category}</b>
                    <br/>
                    <b>{f.datetime_last_modified!=='0000-00-00 00:00:00'?formatDateTime(f.datetime_last_modified):formatDateTime(f.datetime_created)}</b>
                  </p>
                </Col>
                <Col sm="1" className="align-right">
                  <i className="fa fa-times cursor-pointer red-color" onClick={(e)=>{
                    confirmation(
                      ()=>{removeHotfile(f.type, f.ID)},
                      ()=>{},
                      'Delete hotfile?',
                      'Are you sure you want to delete this hotfile?');}
                  }></i>
                </Col>
              </Row>
            <NavLink target="_blank" to={url+f.appraisal_fk}>{f.reference_num+' - '+f.property+' - '+f.borrower+' - ['+f.appraiser+']'}</NavLink><br/>
            <b>{f.order_status}</b> - <i>{f.comment}</i></td>
          </tr>
        );
      }
    );
  }

  let nuclearfile;

  if(state.nuclearfile.length>0){
    nuclearfile = state.nuclearfile.map(
      (f, index)=>{
        let url = '/appraisal/';
        if(f.category==='Order')
          url = '/appraisal/';
        else if(f.category==='Condition')
          url = '/condition/';
        else if(f.category==='Rebuttal')
          url = '/rebuttal/';

        let acIcon = '';
        if(f.account_manager !=='')
          acIcon = <Avatar size={30} style={{overflow:'hidden'}} textSizeRatio={1.5} color={"#"+stringToHexCode(f.account_manager_email)} name={f.account_manager}/>;
        return(
          <tr key={index}>
            <td>
              <Row>
                <Col sm="11">
                  {acIcon}&nbsp;
                  <p className="display-inline">
                    <b>{f.sub_category}</b>
                    <br/>
                    <b>{f.datetime_last_modified!=='0000-00-00 00:00:00'?formatDateTime(f.datetime_last_modified):formatDateTime(f.datetime_created)}</b>
                  </p>
                </Col>
                <Col sm="1" className="align-right">
                  <i className="fa fa-times cursor-pointer red-color" onClick={(e)=>{
                    confirmation(
                      ()=>{removeNuclearfile(f.ID)},
                      ()=>{},
                      'Delete nuclear file?',
                      'Are you sure you want to delete this nuclear file?');}
                  }></i>
                </Col>
              </Row>
            <NavLink target="_blank" to={url+f.appraisal_fk}>{f.reference_num+' - '+f.property+' - '+f.borrower+' - ['+f.appraiser+']'}</NavLink><br/>
            <b>{f.order_status}</b> - <i>{f.comment}</i></td>
          </tr>
        );
      }
    );
  }

  let crm;

    if(state.crm.length>0){
      crm = state.crm.map(
        (f, index)=>{
          
          let acIcon = '';
          if(f.account_manager !=='')
            acIcon = <Avatar size={30} style={{overflow:'hidden'}} textSizeRatio={1.5} color={"#"+stringToHexCode(f.account_manager_email)} name={f.account_manager}/>;
          return(
            <tr key={index}>
              <td>
                <Row>
                  <Col sm="11">
                    {acIcon}&nbsp;
                    <p className="display-inline">
                      <b>{f.datetime_last_updated!=='0000-00-00 00:00:00'?formatDateTime(f.datetime_last_updated):formatDateTime(f.datetime_created)}</b>
                    </p>
                  </Col>
                  <Col sm="1" className="align-right">
                    <i className="fa fa-times cursor-pointer red-color" onClick={(e)=>{
                      confirmation(
                        ()=>{removeCRM(f.appraisal_fk)},
                        ()=>{},
                        'Delete CRM?',
                        'Are you sure you want to delete this CRM?');}
                    }></i>
                  </Col>
                </Row>
              <NavLink target="_blank" to={'/appraisal/'+f.appraisal_fk}>{f.reference_num+' - '+f.property+' - '+f.borrower+' - ['+f.appraiser+']'}</NavLink><br/>
              <b>{f.order_status}</b> - <i>{f.comment}</i></td>
            </tr>
          );
        }
      );
    }

  let internalEscalation;

  if(state.internalEscalation.length>0){
    internalEscalation = state.internalEscalation.map(
      (f, index)=>{
        let acIcon = '';
        if(f.account_manager !=='')
          acIcon = <Avatar size={15} style={{overflow:'hidden'}} textSizeRatio={1.5} color={"#"+stringToHexCode(f.account_manager_email)} name={f.account_manager}/>;
        return(
          <tr key={index}>
            <td>
              <div>
                {acIcon}&nbsp;
                <p className="display-inline" style={{marginBottom:'0px'}}>
                <b>{f.datetime_last_updated!=='0000-00-00 00:00:00'?formatDateTime(f.datetime_last_updated):formatDateTime(f.datetime_created)}</b>
                </p>
              </div>
              <NavLink target="_blank" to={"/appraisal/"+f.ID}>{f.reference_num+' - '+f.property_street+' '+f.property_city+' '+f.property_state+' - '+f.borrower_f_name+' '+f.borrower_l_name}</NavLink><br/>
              <b>{f.order_status}</b> - <i>{f.comment}</i>
            </td>
          </tr>
        );
      }
    );
  }

  let expedite;

  if(state.expedite.length>0){
    expedite = state.expedite.map(
      (f, index)=>{
        let acIcon = '';
        if(f.account_manager !=='')
          acIcon = <Avatar size={15} style={{overflow:'hidden'}} textSizeRatio={1.5} color={"#"+stringToHexCode(f.account_manager_email)} name={f.account_manager}/>;
        return(
          <tr key={index}>
            <td>
              <div>
                {acIcon}&nbsp;
                <p className="display-inline" style={{marginBottom:'0px'}}>
                <b>{f.datetime_last_updated!=='0000-00-00 00:00:00'?formatDateTime(f.datetime_last_updated):formatDateTime(f.datetime_created)}</b>
                </p>
              </div>
              <NavLink target="_blank" to={"/appraisal/"+f.ID}>{f.reference_num+' - '+f.property_street+' '+f.property_city+' '+f.property_state+' - '+f.borrower_f_name+' '+f.borrower_l_name}</NavLink><br/>
              <b>{f.order_status}</b> - <i>{f.comment}</i>
            </td>
          </tr>
        );
      }
    );
  }

  let receivable;

  if(state.receivable.length>0){
    receivable = state.receivable.map(
      (f, index)=>{
        let acIcon = '';
        if(f.account_manager !=='')
          acIcon = <Avatar size={30} style={{overflow:'hidden'}} textSizeRatio={1.5} color={"#"+stringToHexCode(f.account_manager_email)} name={f.account_manager}/>;
        return(
          <tr key={index}>
            <td>
              <div>
                {acIcon}&nbsp;
                <p className="display-inline" style={{marginBottom:'0px'}}>
                  <b>{f.method}</b> - <i>{f.invoice_status}</i>&nbsp;(Covered by {f.covered_by})<br/>
                  {formatDateTime(f.datetime_submitted)}
                </p>
              </div>
              <NavLink target="_blank" to={"/appraisal/"+f.ID}>{f.reference_num+' - '+f.property_street+' '+f.property_city+' '+f.property_state+' - '+f.borrower_f_name+' '+f.borrower_l_name}</NavLink><br/>
              <b>{f.status}</b> - <i>{f.client}</i>
            </td>
          </tr>
        );
      }
    );
  }

  let newComments;
  if(state.allComments.length>0){
    newComments = state.allComments.map(
      (comment, index)=>{
        let emailIcon;

        if(comment.parsed_from_email==='yes')
          emailIcon = <i className="fa fa-envelope link-color"/>;
        return(
          <div className="new-comment-container" key={index}>
            <Row>
              <Col sm="11">
                <Avatar size={30} style={{overflow:'hidden'}} textSizeRatio={1.5} color={"#"+stringToHexCode(comment.author_email)} name={comment.author_name}/>&nbsp;
                <p className="display-inline comment-metadata no-margin">
                  {comment.author} - {comment.author_label}
                  <br/>
                  {formatDateTime(comment.datetime_created)}
                </p>
              </Col>
              <Col sm="1" className="align-right">
                <i className="fa fa-envelope-open cursor-pointer" onClick={(e)=>{e.stopPropagation();ignoreComment(comment.ID)}}></i>&nbsp;
              </Col>
            </Row>
            {emailIcon}<br/>
            <NavLink target="_blank" to={"/comment/"+comment.ID+"/"+comment.appraisal_fk}>{comment.property_street+' '+comment.property_city+', '+comment.property_state+' '+comment.property_zip}</NavLink>
            <br/>
            <div>
              <b>{comment.order_status}</b> - <i>{comment.comment}</i>
            </div>
        </div>
        );
      }
    );
  }

  const columns = [
    {
      id: 'referenceNum',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'address',
      Header: 'Address',
      accessor: d => d.property_street+' '+d.property_city,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'borrower',
      Header: 'Borrower',
      accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'description',
      Header: 'Description',
      accessor: d => d.description,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'client',
      Header: 'Client',
      accessor: d => d.client,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  const columns2 = [
    {
      id: 'referenceNum',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'address',
      Header: 'Address',
      accessor: d => d.property_street+' '+d.property_city,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'borrower',
      Header: 'Borrower',
      accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'client',
      Header: 'Client',
      accessor: d => d.client,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'date',
      Header: 'Date',
      accessor: d => d.datetime_submitted,
      Cell: props=> <div>{formatDateTime(props.row.original.datetime_submitted)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  const columns9 = [
    {
      id: 'referenceNum',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'address',
      Header: 'Address',
      accessor: d => d.property_street+' '+d.property_city,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'borrower',
      Header: 'Borrower',
      accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'client',
      Header: 'Client',
      accessor: d => d.client,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'status',
      Header: 'Status',
      accessor: d => d.status,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'receivableStatus',
      Header: 'Receivable Status',
      accessor: d => d.invoice_status,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'paymentMethod',
      Header: 'Payment Method',
      accessor: d => d.method,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'date',
      Header: 'Date',
      accessor: d => d.datetime_submitted,
      Cell: props=> <div>{formatDateTime(props.row.original.datetime_submitted)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  const columns3 = [
    {
      id: 'referenceNum',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'address',
      Header: 'Address',
      accessor: d => d.property_street+' '+d.property_city,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'appraiser',
      Header: 'Appraiser',
      accessor: d => d.appraiser,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'borrower',
      Header: 'Borrower',
      accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'client',
      Header: 'Client',
      accessor: d => d.client,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  const columns4 = [
    {
      id: 'referenceNum',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'address',
      Header: 'Address',
      accessor: d => d.property_street+' '+d.property_city,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'borrower',
      Header: 'Borrower',
      accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'client',
      Header: 'Client',
      accessor: d => d.client,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'date',
      Header: 'Overdue',
      accessor: d => d.datetime_submitted,
      Cell: props=> {
        let tmp = props.row.original.datetime_submitted.split(' ');
        let date = tmp[0];
        let time = tmp[1];

        tmp = date.split('-');
        let year = tmp[0];
        let month = tmp[1];
        let day = tmp[2];

        tmp = time.split(':');
        let hour = tmp[0];
        let minute = tmp[1];
        let second = tmp[2];

        let tDate = new Date(year, month-1, day, hour, minute, second);
        let tNow = Date.now();

        let difference = tNow - tDate;

        let secondLimit = 1000;
        let minuteLimit = 60000;
        let hourLimit = 3600000;
        let dayLimit = 86400000;

        let overdue = '';
        if(difference>dayLimit)
          overdue = formatNumber(Math.round(difference/dayLimit,2))+' d';
        else if(difference>hourLimit)
          overdue = formatNumber(Math.round(difference/hourLimit,2))+' h';
        else if(difference>minuteLimit)
          overdue = formatNumber(Math.round(difference/minuteLimit,2))+' m';
        else if(difference>secondLimit)
          overdue = formatNumber(Math.round(difference/secondLimit,2))+' s';


        if(props.row.original.datetime_submitted==='0000-00-00 00:00:00')
          overdue = '-';

        return <div>{overdue}</div>;
      },
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  const columns5 = [
    {
      id: 'referenceNum',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'address',
      Header: 'Address',
      accessor: d => d.property_street+' '+d.property_city,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'borrower',
      Header: 'Borrower',
      accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'client',
      Header: 'Client',
      accessor: d => d.client,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'date',
      Header: 'Overdue',
      accessor: d => d.datetime_accepted,
      Cell: props=> {
        let tmp = props.row.original.datetime_accepted.split(' ');
        let date = tmp[0];
        let time = tmp[1];

        tmp = date.split('-');
        let year = tmp[0];
        let month = tmp[1];
        let day = tmp[2];

        tmp = time.split(':');
        let hour = tmp[0];
        let minute = tmp[1];
        let second = tmp[2];

        let tDate = new Date(year, month-1, day, hour, minute, second);
        let tNow = Date.now();

        let difference = tNow - tDate;

        let secondLimit = 1000;
        let minuteLimit = 60000;
        let hourLimit = 3600000;
        let dayLimit = 86400000;

        let overdue = '';
        if(difference>dayLimit)
          overdue = formatNumber(Math.round(difference/dayLimit,2))+' d';
        else if(difference>hourLimit)
          overdue = formatNumber(Math.round(difference/hourLimit,2))+' h';
        else if(difference>minuteLimit)
          overdue = formatNumber(Math.round(difference/minuteLimit,2))+' m';
        else if(difference>secondLimit)
          overdue = formatNumber(Math.round(difference/secondLimit,2))+' s';

        if(props.row.original.datetime_accepted==='0000-00-00 00:00:00')
          overdue = '-';

        return <div>{overdue}</div>;
      },
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  const columns6 = [
    {
      id: 'referenceNum',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'address',
      Header: 'Address',
      accessor: d => d.property_street+' '+d.property_city,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'borrower',
      Header: 'Borrower',
      accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'client',
      Header: 'Client',
      accessor: d => d.client,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'date',
      Header: 'Overdue',
      accessor: d => d.datetime_expected,
      Cell: props=> {
        let tmp = props.row.original.datetime_expected.split(' ');
        let date = tmp[0];
        let time = tmp[1];

        tmp = date.split('-');
        let year = tmp[0];
        let month = tmp[1];
        let day = tmp[2];

        tmp = time.split(':');
        let hour = tmp[0];
        let minute = tmp[1];
        let second = tmp[2];

        let tDate = new Date(year, month-1, day, hour, minute, second);
        let tNow = Date.now();

        let difference = tNow - tDate;

        let secondLimit = 1000;
        let minuteLimit = 60000;
        let hourLimit = 3600000;
        let dayLimit = 86400000;

        let overdue = '';
        if(difference>dayLimit)
          overdue = formatNumber(Math.round(difference/dayLimit,2))+' d';
        else if(difference>hourLimit)
          overdue = formatNumber(Math.round(difference/hourLimit,2))+' h';
        else if(difference>minuteLimit)
          overdue = formatNumber(Math.round(difference/minuteLimit,2))+' m';
        else if(difference>secondLimit)
          overdue = formatNumber(Math.round(difference/secondLimit,2))+' s';

        if(props.row.original.datetime_due==='0000-00-00 00:00:00')
          overdue = '-';
        return <div>{overdue}</div>;
      },
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  const columns7 = [
    {
      id: 'referenceNum',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'address',
      Header: 'Address',
      accessor: d => d.property_street+' '+d.property_city,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'borrower',
      Header: 'Borrower',
      accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'client',
      Header: 'Client',
      accessor: d => d.client,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'date',
      Header: 'Overdue',
      accessor: d => d.datetime_requested,
      Cell: props=> {
        let tmp = props.row.original.datetime_requested.split(' ');
        let date = tmp[0];
        let time = tmp[1];

        tmp = date.split('-');
        let year = tmp[0];
        let month = tmp[1];
        let day = tmp[2];

        tmp = time.split(':');
        let hour = tmp[0];
        let minute = tmp[1];
        let second = tmp[2];

        let tDate = new Date(year, month-1, day, hour, minute, second);
        let tNow = Date.now();

        let difference = tNow - tDate;

        let secondLimit = 1000;
        let minuteLimit = 60000;
        let hourLimit = 3600000;
        let dayLimit = 86400000;

        let overdue = '';
        if(difference>dayLimit)
          overdue = formatNumber(Math.round(difference/dayLimit,2))+' d';
        else if(difference>hourLimit)
          overdue = formatNumber(Math.round(difference/hourLimit,2))+' h';
        else if(difference>minuteLimit)
          overdue = formatNumber(Math.round(difference/minuteLimit,2))+' m';
        else if(difference>secondLimit)
          overdue = formatNumber(Math.round(difference/secondLimit,2))+' s';

        if(props.row.original.datetime_requested==='0000-00-00 00:00:00')
          overdue = '-';

        return <div>{overdue}</div>;
      },
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  const columns8 = [
    {
      id: 'referenceNum',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'address',
      Header: 'Address',
      accessor: d => d.property_street+' '+d.property_city,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'borrower',
      Header: 'Borrower',
      accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'client',
      Header: 'Client',
      accessor: d => d.client,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'inspection',
      Header: 'Inspection',
      accessor: d => d.datetime_inspection,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'date',
      Header: 'Due',
      accessor: d => d.datetime_due,
      Cell: props=> <div>{formatDateTime(props.row.original.datetime_due)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
  ];

  let currentColumns = columns2;

  switch(state.pipelineAndOverdueActiveTab){
    case '1':
      currentColumns = columns2;
      break;
    case '2':
      currentColumns = columns3;
      break;
    case '3':
      currentColumns = columns3;
      break;
    case '4':
      currentColumns = columns3;
      break;
    case '5':
      currentColumns = columns3;
      break;
    case '6':
      currentColumns = columns4;
      break;
    case '7':
      currentColumns = columns5;
      break;
    case '8':
      currentColumns = columns6;
      break;
    case '9':
      currentColumns = columns7;
      break;
    case '10':
      currentColumns = columns7;
      break;
    case '11':
      currentColumns = columns9;
      break;
    case '12':
      currentColumns = columns8;
      break;
    case '13':
      currentColumns = columns;
      break;
    case '14':
      currentColumns = columns2;
      break;
    default:
      break;
  }

  let hotfileTab, nuclearFileTab, newCommentsTab, overduePipelineTab, internalEscalationTab, expediteTab, receivableTab, crmTab;

  crmTab = <Card>
      <CardHeader className="header-color">
        <Row>
          <Col sm="10">
            <i className="fa fa-warning"></i> CRM
          </Col>
          <Col sm="2" className="align-right">
            <span className="my-badge">{state.crm.length}</span>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <div>
          <div>
            <Table className="table table-striped">
              <tbody>
                {crm}
              </tbody>
            </Table>
          </div>
        </div>
      </CardBody>
      </Card>;

  internalEscalationTab = <Card>
      <CardHeader className="header-color">
        <Row>
          <Col sm="10">
            <i className="fa fa-warning"></i> Escalation
          </Col>
          <Col sm="2" className="align-right">
            <span className="my-badge">{state.internalEscalation.length}</span>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <div>
          <div>
            <Table className="table table-striped">
              <tbody>
                {internalEscalation}
              </tbody>
            </Table>
          </div>
        </div>
      </CardBody>
    </Card>;

    expediteTab = <Card>
    <CardHeader className="header-color">
      <Row>
        <Col sm="10">
          <i className="fa fa-warning"></i> Expedite
        </Col>
        <Col sm="2" className="align-right">
          <span className="my-badge">{state.expedite.length}</span>
        </Col>
      </Row>
    </CardHeader>
    <CardBody>
      <div>
        <div>
          <Table className="table table-striped">
            <tbody>
              {expedite}
            </tbody>
          </Table>
        </div>
      </div>
    </CardBody>
    </Card>;




  hotfileTab = <Card>
    <CardHeader className="header-color">
      <Row>
        <Col sm="10">
          <i className="fa fa-warning"></i> Hotfiles
        </Col>
        <Col sm="2" className="align-right">
          <span className="my-badge">{state.hotfile.length}</span>
        </Col>
      </Row>
    </CardHeader>
    <CardBody>
      <div>
        <div>
          <Table className="table table-striped">
            <tbody>
              {hotfile}
            </tbody>
          </Table>
        </div>
      </div>
    </CardBody>
  </Card>;

  nuclearFileTab = <Card>
      <CardHeader className="header-color">
        <Row>
          <Col sm="10">
            <i className="fa fa-warning"></i> Nuclear Files
          </Col>
          <Col sm="2" className="align-right">
            <span className="my-badge">{state.nuclearfile.length}</span>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <div>
          <div>
            <Table className="table table-striped">
              <tbody>
                {nuclearfile}
              </tbody>
            </Table>
          </div>
        </div>
      </CardBody>
    </Card>;

  overduePipelineTab = <Card>
      <CardHeader className="header-color">
        <i className="fa fa-times red-color cursor-pointer"></i> <i className="fa fa-tasks"></i> Pipeline & Overdue
      </CardHeader>
      <CardBody>
        <Row>
          <Col sm="4">
            <Nav tabs className="dashboard-pane">
              <NavItem className="dashboard-tab">
                <NavLinkL
                  className={"nav-link "+(state.pipelineAndOverdueActiveTab === '1'?"active":"inactive" )}
                  onClick={() => { pipelineAndOverdueTabToogle('1'); }}
                  to="#"
                >
                  <Row>
                    <Col sm="10">
                      Submitted
                    </Col>
                    <Col sm="2" className="align-right">
                      <span className="my-badge">{state.submittedOrders}</span>
                    </Col>
                  </Row>
                </NavLinkL>
              </NavItem>
              <NavItem className="dashboard-tab">
                <NavLinkL
                  className={"nav-link "+(state.pipelineAndOverdueActiveTab === '2'?"active":"inactive" )}
                  onClick={() => { pipelineAndOverdueTabToogle('2'); }}
                  to="#"
                >
                  <Row>
                    <Col sm="10">
                      Assigned
                    </Col>
                    <Col sm="2" className="align-right">
                      <span className="my-badge">{state.assignedOrders}</span>
                    </Col>
                  </Row>
                </NavLinkL>
              </NavItem>
              <NavItem className="dashboard-tab">
                <NavLinkL
                  className={"nav-link "+(state.pipelineAndOverdueActiveTab === '3'?"active":"inactive" )}
                  onClick={() => { pipelineAndOverdueTabToogle('3'); }}
                  to="#"
                >
                  <Row>
                    <Col sm="10">
                      Waiting on contact
                    </Col>
                    <Col sm="2" className="align-right">
                      <span className="my-badge">{state.waitingOnContacts}</span>
                    </Col>
                  </Row>
                </NavLinkL>
              </NavItem>
              <NavItem className="dashboard-tab">
                <NavLinkL
                  className={"nav-link "+(state.pipelineAndOverdueActiveTab === '14'?"active":"inactive" )}
                  onClick={() => { pipelineAndOverdueTabToogle('14'); }}
                  to="#"
                >
                  <Row>
                    <Col sm="10">
                      Hold
                    </Col>
                    <Col sm="2" className="align-right">
                       <span className="my-badge">{state.holdOrders}</span>
                    </Col>
                  </Row>
                </NavLinkL>
              </NavItem>
              <NavItem className="dashboard-tab">
                <NavLinkL
                  className={"nav-link "+(state.pipelineAndOverdueActiveTab === '4'?"active":"inactive" )}
                  onClick={() => { pipelineAndOverdueTabToogle('4'); }}
                  to="#"
                >
                  <Row>
                    <Col sm="10">
                      New condition
                    </Col>
                    <Col sm="2" className="align-right">
                      <span className="my-badge">{state.newConditions}</span>
                    </Col>
                  </Row>
                </NavLinkL>
              </NavItem>
              <NavItem className="dashboard-tab">
                <NavLinkL
                  className={"nav-link "+(state.pipelineAndOverdueActiveTab === '5'?"active":"inactive" )}
                  onClick={() => { pipelineAndOverdueTabToogle('5'); }}
                  to="#"
                >
                  <Row>
                    <Col sm="10">
                      New rebuttal
                    </Col>
                    <Col sm="2" className="align-right">
                      <span className="my-badge">{state.newRebuttals}</span>
                    </Col>
                  </Row>
                </NavLinkL>
              </NavItem>
              <NavItem className="dashboard-tab">
                <NavLinkL
                  className={"nav-link "+(state.pipelineAndOverdueActiveTab === '6'?"active":"inactive" )}
                  onClick={() => { pipelineAndOverdueTabToogle('6'); }}
                  to="#"
                >
                  <Row>
                    <Col sm="10">
                      Late assignment
                    </Col>
                    <Col sm="2" className="align-right">
                      <span className="my-badge">{state.lateAssignments}</span>
                    </Col>
                  </Row>
                </NavLinkL>
              </NavItem>
              <NavItem className="dashboard-tab">
                <NavLinkL
                  className={"nav-link "+(state.pipelineAndOverdueActiveTab === '7'?"active":"inactive" )}
                  onClick={() => { pipelineAndOverdueTabToogle('7'); }}
                  to="#"
                >
                  <Row>
                    <Col sm="10">
                      Late scheduling
                    </Col>
                    <Col sm="2" className="align-right">
                      <span className="my-badge">{state.lateSchedulings}</span>
                    </Col>
                  </Row>
                </NavLinkL>
              </NavItem>
              <NavItem className="dashboard-tab">
                <NavLinkL
                  className={"nav-link "+(state.pipelineAndOverdueActiveTab === '8'?"active":"inactive" )}
                  onClick={() => { pipelineAndOverdueTabToogle('8'); }}
                  to="#"
                >
                  <Row>
                    <Col sm="10">
                      Late report
                    </Col>
                    <Col sm="2" className="align-right">
                      <span className="my-badge">{state.lateReports}</span>
                    </Col>
                  </Row>
                </NavLinkL>
              </NavItem>
              <NavItem className="dashboard-tab">
                <NavLinkL
                  className={"nav-link "+(state.pipelineAndOverdueActiveTab === '9'?"active":"inactive" )}
                  onClick={() => { pipelineAndOverdueTabToogle('9'); }}
                  to="#"
                >
                  <Row>
                    <Col sm="10">
                      Late condition
                    </Col>
                    <Col sm="2" className="align-right">
                      <span className="my-badge">{state.lateConditions}</span>
                    </Col>
                  </Row>
                </NavLinkL>
              </NavItem>
              <NavItem className="dashboard-tab">
                <NavLinkL
                  className={"nav-link "+(state.pipelineAndOverdueActiveTab === '10'?"active":"inactive" )}
                  onClick={() => { pipelineAndOverdueTabToogle('10'); }}
                  to="#"
                >
                  <Row>
                    <Col sm="10">
                      Late rebuttal
                    </Col>
                    <Col sm="2" className="align-right">
                      <span className="my-badge">{state.lateRebuttals}</span>
                    </Col>
                  </Row>
                </NavLinkL>
              </NavItem>
              <NavItem className="dashboard-tab">
                <NavLinkL
                  className={"nav-link "+(state.pipelineAndOverdueActiveTab === '12'?"active":"inactive" )}
                  onClick={() => { pipelineAndOverdueTabToogle('12'); }}
                  to="#"
                >
                  <Row>
                    <Col sm="10">
                      Due
                    </Col>
                    <Col sm="2" className="align-right">
                      <span className="my-badge">{state.dueOrders}</span>
                    </Col>
                  </Row>
                </NavLinkL>
              </NavItem>
              <NavItem className="dashboard-tab">
                <NavLinkL
                  className={"nav-link "+(state.pipelineAndOverdueActiveTab === '13'?"active":"inactive" )}
                  onClick={() => { pipelineAndOverdueTabToogle('13'); }}
                  to="#"
                >
                  <Row>
                    <Col sm="10">
                      Accounting
                    </Col>
                    <Col sm="2" className="align-right">
                      <span className="my-badge">{state.accountingAudits}</span>
                    </Col>
                  </Row>
                </NavLinkL>
              </NavItem>
            </Nav>
          </Col>
          <Col sm="8">
            <div className="pipeline-overdue-container">
              <MyReactTable columns={currentColumns} data={state.dataRows} className="table table-striped"/>
            </div>
          </Col>
        </Row>


      </CardBody>
    </Card>;

  receivableTab = <Card>
      <CardHeader className="header-color">
        <Row>
          <Col sm="10">
            <i className="fa fa-warning"></i> Pending Receivable
          </Col>
          <Col sm="2" className="align-right">
            <span className="my-badge">{state.receivable.length}</span>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <div>
          <div>
            <Table className="table table-striped">
              <tbody>
                {receivable}
              </tbody>
            </Table>
          </div>
        </div>
      </CardBody>
      </Card>;

  newCommentsTab = <Card>
      <CardHeader className="header-color">
        <Row>
          <Col sm="10">
            <i className="fa fa-times red-color cursor-pointer"></i> <i className="fa fa-comments"></i> New comments
          </Col>
          <Col sm="2" className="align-right">
            <span className="my-badge">{state.totalNewCommentsCount}</span>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col sm="8">
            <div className="display-inline" style={{width:'200px'}}>
              <MySelect
                type="select"
                value={state.filter}
                onChange={(v)=>filterOnChange(v)}
                options={[{label:"All",value:"all"},{label:"Client Only",value:"client"},{label:"Appraiser Only",value:"appraiser"},{label:"System Updates",value:"system"},{label:"Email Scanned Comment",value:"scan"}]}
              />
            </div>
          </Col>
          <Col sm="4" className="align-right">
            <i className="fa fa-envelope link-color"/> Email Scanned Comment
          </Col>
        </Row>
        <br/>
        <div style={{minHeight:'500px',maxHeight:'500px', overflowY:'auto'}}>
          <InfiniteScroll
            className="my-well"
            dataLength={state.allComments.length} //This is important field to render the next data
            next={loadMoreNewComments}
            hasMore={state.hasMoreNewComments}
            loader={<center>Loading more new comments...</center>}
            scrollableTarget="contentContainer"
          >
            <div>
              {newComments}
            </div>
          </InfiniteScroll>
        </div>
      </CardBody>
    </Card>;

  let filtersConfig = [
    {id:'from',value:from, updateFunc:(v)=>setState({from:v}), width:'2'},
    {id:'to',value:to, updateFunc:(v)=>setState({to:v}), width:'2'},
    {id:'orderStatus',value:state.status, updateFunc:(v)=>setState({status:v}), width:'3'},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'5'},
    {id:'custom',value:state.street, updateFunc:(v)=>setState({street:v}), width:'2', label:'Street'},
    {id:'city',value:state.city, updateFunc:(v)=>setState({city:v}), width:'2'},
    {id:'state',value:state.state, updateFunc:(v)=>setState({state:v}), width:'3'},
    {id:'entity',value:state.entity, updateFunc:(v)=>setState({entity:v}), width:'3'},
    {id:'button',value:'Submit', updateFunc:()=>{refreshList()}, width:'2', className:"align-right", color:"warning"},
  ];


  return <div className="my-well">
    <TodayStat type="personal" {...props}/>
    <div>
      <Row>
        <Col sm="6">
          <div className="page-title">
            <i className="fa fa-reorder"></i>&nbsp;Global Dashboard
          </div>
        </Col>
        <Col sm="6" className="align-right">
          <font style={{fontSize:'1rem'}}><NavLink to="/dashboard-global">Switch to global dashboard</NavLink></font>
        </Col>
      </Row>
      <div className="my-divider"></div>
    </div>
    <div>
      <ReportFilter {...props} configs={filtersConfig}/>
      <div className="my-divider">&nbsp;</div>
    </div>
    <div>
      <Nav tabs>
        <NavItem>
          <NavLinkL
            className={"nav-link "+(state.activeTab === '3'?"active":"inactive" )}
            onClick={() => { activeTabToggle('3'); }}
            to="#">
              Nuclear Files
          </NavLinkL>
        </NavItem>
        <NavItem>
          <NavLinkL
            className={"nav-link "+(state.activeTab === '7'?"active":"inactive" )}
            onClick={() => { activeTabToggle('7'); }}
            to="#">
              Escalation
          </NavLinkL>
        </NavItem>
        <NavItem>
          <NavLinkL
            className={"nav-link "+(state.activeTab === '2'?"active":"inactive" )}
            onClick={() => { activeTabToggle('2'); }}
            to="#">
              Hotfiles
          </NavLinkL>
        </NavItem>
        <NavItem>
          <NavLinkL
            className={"nav-link "+(state.activeTab === '9'?"active":"inactive" )}
            onClick={() => { activeTabToggle('9'); }}
            to="#">
              Expedite
          </NavLinkL>
        </NavItem>
        <NavItem>
          <NavLinkL
            className={"nav-link "+(state.activeTab === '4'?"active":"inactive" )}
            onClick={() => { activeTabToggle('4'); }}
            to="#">
              New Comments <span className="my-badge">{state.totalNewCommentsCount}</span>
          </NavLinkL>
        </NavItem>
        <NavItem>
          <NavLinkL
            className={"nav-link "+(state.activeTab === '5'?"active":"inactive" )}
            onClick={() => { activeTabToggle('5'); }}
            to="#">
              Pipeline & Overdue
          </NavLinkL>
        </NavItem>
        <NavItem>
          <NavLinkL
            className={"nav-link "+(state.activeTab === '6'?"active":"inactive" )}
            onClick={() => { activeTabToggle('6'); }}
            to="#">
              Receivable
          </NavLinkL>
        </NavItem>
        <NavItem>
          <NavLinkL
            className={"nav-link "+(state.activeTab === '10'?"active":"inactive" )}
            onClick={() => { activeTabToggle('10'); }}
            to="#">
              CRM
          </NavLinkL>
        </NavItem>
      </Nav>
      <TabContent activeTab={state.activeTab}>
        <TabPane tabId="2">
          {hotfileTab}
        </TabPane>
        <TabPane tabId="3">
          {nuclearFileTab}
        </TabPane>
        <TabPane tabId="4">
          {newCommentsTab}
        </TabPane>
        <TabPane tabId="5">
          {overduePipelineTab}
        </TabPane>
        <TabPane tabId="6">
          {receivableTab}
        </TabPane>
        <TabPane tabId="7">
          {internalEscalationTab}
        </TabPane>
        <TabPane tabId="9">
          {expediteTab}
        </TabPane>
        <TabPane tabId="10">
          {crmTab}
        </TabPane>
      </TabContent>
    </div>
  </div>;
}


export default DashboardSimplify;
