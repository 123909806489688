//Author Sooyoung Kim
//Date April 19, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, deleteAPICallGenerator, callBackGenerator, confirmation} from '../../util/util';
import {Button, Col, Row, Table, NavItem, Nav, TabContent, TabPane, Input, Card, CardBody, CardHeader, NavLink as NavLinkL} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import MySelect from '../util/my-select';


//initialize the state
const initialState = {
  blackList:[],
  newBlackListConditionRuleSelected:'',
  newBlackListConditionField:'',
  newBlackListConditionOperator:'=',
  newBlackListConditionDescription:'',
  newBlackListConditionValues:[],
  newBlackListConditionValuesT:'',
  newBlackListConditionValuesS:'',
  newBlackListName:'',
  enableList:[],
  newEnableListConditionRuleSelected:'',
  newEnableListConditionField:'',
  newEnableListConditionOperator:'=',
  newEnableListConditionDescription:'',
  newEnableListConditionValues:[],
  newEnableListConditionValuesT:'',
  newEnableListConditionValuesS:'',
  newEnableListName:'',
  enrolledAppraisers:[],
  fieldsEnum:[],
  clients:[],
  entities:[],
  shippingDropDownOpen:false,
  shipToBorrower:'no',
  borrowerDelayTimer:0,
  targetLabel:'',
  otherLabel:'',
  shippingLabels:[],
  client:0,
  searchAppraiserResults:[],
  searchKeyword:'',
  maxDistance:15,
  selectedAppraiser:{},
  addAppraiserPopUp:false,
  autoBidActiveTab:'1'
};

//reducer function that perform state update
const reducer = getReducer();


const AutoBidConfiguration  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);
  const apiCallBack = callBackGenerator(setState);

  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getBlacklist();
    getEnablelist();
    getRuleFieldEnum();
    getEnrolledAppraisers();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const autoBidActiveTabToggle = (tab)=>{
    setState({autoBidActiveTab:tab});
  }

  const blackListValueOnChange = (v) =>{
    setState({newBlackListConditionValuesT:v});
  }

  const enableListValueOnChange = (v) =>{
    setState({newEnableListConditionValuesT:v});
  }

  const blackListFieldOnChange = (v) =>{
    for(let i=0;i<state.fieldsEnum.length;i++){
      if(state.fieldsEnum[i].field===v){
        setState({newBlackListConditionValues:state.fieldsEnum[i].enum});
        break;
      }
    }
  }

  const enableListFieldOnChange = (v) =>{
    for(let i=0;i<state.fieldsEnum.length;i++){
      if(state.fieldsEnum[i].field===v){
        setState({newEnableListConditionValues:state.fieldsEnum[i].enum});
        break;
      }
    }
  }

  //API call
  const addNewBlackListCondition = () =>{
    let url = 'autoBid/blackList/condition/create';
    let successMsg = 'Auto bid black list rule condition created successfully.';
    let errorMsg = 'Oops, something went wrong and could not create the rule condition. Please try again later.';

    let select = true;

    for(let i=0;i<state.fieldsEnum.length;i++){
      //there is no drop down, the value should coming from text instead
      if(state.fieldsEnum[i].field===state.newBlackListConditionField){
        if(state.fieldsEnum[i].enum.length<=0)
          select = false;
      }
    }

    let value;

    if(select)
      value = state.newBlackListConditionValuesS;
    else
      value = state.newBlackListConditionValuesT;


    let parameters = [
      {
        field:'description',
        value:state.newBlackListConditionDescription
      },
      {
        field:'field',
        value:state.newBlackListConditionField
      },
      {
        field:'operator',
        value:state.newBlackListConditionOperator
      },
      {
        field:'value',
        value:value
      },
      {
        field:'ruleFk',
        value:state.newBlackListConditionRuleSelected
      },
    ];

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code!=='00'){

      }
      else{
        let list = state.blackList.slice();

        for(let i=0;i<list.length;i++){
          if(list[i].ID===state.newBlackListConditionRuleSelected){
            list[i].conditions.push(response.data.data);
          }
        }

        setState({blackList:list});
      }
    };
    callBack = callBack.bind(this);

    httpPost(url,parameters,successMsg,errorMsg, callBack);
  }

  const addNewEnableListCondition = () =>{
    let url = 'autoBid/enableList/condition/create';
    let successMsg = 'Auto bid black list rule condition created successfully.';
    let errorMsg = 'Oops, something went wrong and could not create the rule condition. Please try again later.';

    let select = true;

    for(let i=0;i<state.fieldsEnum.length;i++){
      //there is no drop down, the value should coming from text instead
      if(state.fieldsEnum[i].field===state.newEnableListConditionField){
        if(state.fieldsEnum[i].enum.length<=0)
          select = false;
      }
    }

    let value;

    if(select)
      value = state.newEnableListConditionValuesS;
    else
      value = state.newEnableListConditionValuesT;


    let parameters = [
      {
        field:'description',
        value:state.newEnableListConditionDescription
      },
      {
        field:'field',
        value:state.newEnableListConditionField
      },
      {
        field:'operator',
        value:state.newEnableListConditionOperator
      },
      {
        field:'value',
        value:value
      },
      {
        field:'ruleFk',
        value:state.newEnableListConditionRuleSelected
      },
    ];

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code!=='00'){

      }
      else{
        let list = state.enableList.slice();

        for(let i=0;i<list.length;i++){
          if(list[i].ID===state.newEnableListConditionRuleSelected){
            list[i].conditions.push(response.data.data);
          }
        }

        setState({enableList:list});
      }
    };
    callBack = callBack.bind(this);

    httpPost(url,parameters,successMsg,errorMsg, callBack);
  }

  const addNewBlackList = () =>{
    let url = 'autoBid/blackList/create';
    let successMsg = 'Auto Bid black list rule created successfully.';
    let errorMsg = 'Oops, something went wrong and could not create the rule. Please try again later.';

    let parameters = [
      {
        field:'description',
        value:state.newBlackListName
      },
    ];

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code!=='00'){

      }
      else{
        let list = state.blackList.slice();

        list.push(response.data.data);

        setState({blackList:list});
        setState({newBlackListName:''});
      }
    };
    callBack = callBack.bind(this);

    httpPost(url,parameters,successMsg,errorMsg, callBack);
  }

  const addNewEnableList = () =>{
    let url = 'autoBid/enableList/create';
    let successMsg = 'Auto Bid enable list rule created successfully.';
    let errorMsg = 'Oops, something went wrong and could not create the rule. Please try again later.';

    let parameters = [
      {
        field:'description',
        value:state.newEnableListName
      },
    ];

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code!=='00'){

      }
      else{
        let list = state.enableList.slice();

        list.push(response.data.data);

        setState({enableList:list});
        setState({newEnableListName:''});
      }
    };
    callBack = callBack.bind(this);

    httpPost(url,parameters,successMsg,errorMsg, callBack);
  }

  //delete a black list rule
  const deleteBlackListRule = (id) =>{
    let url = 'autoBid/blackList/'+id
    let successMsg = 'Black list rule deleted successfull.';
    let errorMsg = 'Oops, something went wrong and could not delete the rule. Please try again later.';

    let callBack = (response)=>{
      let code= response.data.code;

      if(code!=='00'){

      }
      else{
        let list = state.blackList.slice();

        for(let i=0;i<list.length;i++){
          if(list[i].ID===id){
            list.splice(i,1);
            break;
          }
        }

        setState({blackList:list});
      }
    };
    callBack = callBack.bind(this);

    httpDelete(url,successMsg,errorMsg, callBack);
  }

  const deleteEnableListRule = (id) =>{
    let url = 'autoBid/enableList/'+id
    let successMsg = 'Enable list rule deleted successfull.';
    let errorMsg = 'Oops, something went wrong and could not delete the rule. Please try again later.';

    let callBack = (response)=>{
      let code= response.data.code;

      if(code!=='00'){

      }
      else{
        let list = state.enableList.slice();

        for(let i=0;i<list.length;i++){
          if(list[i].ID===id){
            list.splice(i,1);
            break;
          }
        }

        setState({enableList:list});
      }
    };
    callBack = callBack.bind(this);

    httpDelete(url,successMsg,errorMsg, callBack);
  }

  //delete a black list condition
  const deleteBlackListCondition = (id,conditionID) =>{
    let url = 'autoBid/blackList/condition/'+conditionID
    let successMsg = 'Black list condition deleted successfull.';
    let errorMsg = 'Oops, something went wrong and could not delete the condition. Please try again later.';

    let callBack = (response)=>{
      let code= response.data.code;

      if(code!=='00'){

      }
      else{
        let list = state.blackList.slice();

        for(let i=0;i<list.length;i++){
          if(list[i].ID===id){
            for(let j=0;j<list[i].conditions.length;j++){
              if(list[i].conditions[j].ID===conditionID){
                list[i].conditions.splice(j,1);
                break;
              }
            }

            break;
          }
        }

        setState({blackList:list});
      }
    };
    callBack = callBack.bind(this);

    httpDelete(url,successMsg,errorMsg, callBack);
  }

  const deleteEnableListCondition = (id,conditionID) =>{
    let url = 'autoBid/enableList/condition/'+conditionID
    let successMsg = 'Enable list condition deleted successfull.';
    let errorMsg = 'Oops, something went wrong and could not delete the condition. Please try again later.';

    let callBack = (response)=>{
      let code= response.data.code;

      if(code!=='00'){

      }
      else{
        let list = state.enableList.slice();

        for(let i=0;i<list.length;i++){
          if(list[i].ID===id){
            for(let j=0;j<list[i].conditions.length;j++){
              if(list[i].conditions[j].ID===conditionID){
                list[i].conditions.splice(j,1);
                break;
              }
            }

            break;
          }
        }

        setState({enableList:list});
      }
    };

    callBack = callBack.bind(this);

    httpDelete(url,successMsg,errorMsg, callBack);
  }


  const getBlacklist = () =>{
    let url = 'autoBid/blackList';
    let callBack = apiCallBack([{state:'blackList', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load the black list. Please try again later.', callBack);
  }

  const getEnablelist = () =>{
    let url = 'autoBid/enableList';
    let callBack = apiCallBack([{state:'enableList', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load the black list. Please try again later.', callBack);
  }

  const getEnrolledAppraisers = () =>{
    let url = 'autoBid/appraiser/get';
    let callBack = apiCallBack([{state:'enrolledAppraisers', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not retrieve auto bid enrolled appraisers.', callBack);
  }

  const getRuleFieldEnum = () =>{
    let url = 'autoAssign/rules/field/value';
    let callBack = apiCallBack([{state:'fieldsEnum', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load the rule fields. Please try again later.', callBack);
  }


  //render
  let blackLists, blackListValueWidget;

  let enableLists, enableListValueWidget;

  // black list value option
  if(state.newBlackListConditionValues!==undefined&&state.newBlackListConditionValues.length>0&&state.newBlackListConditionOperator!=='like')
    blackListValueWidget = <MySelect
      type="select"
      required={true}
      selectIsClearable={true}
      value={state.newBlackListConditionValuesS}
      onChange={(v)=>{blackListValueOnChange(v);setState({newBlackListConditionValuesS:v})}}
      options={state.newBlackListConditionValues.map((value)=>{
        return {label:value.label, value:value.value};
      })}
    />;
  else
    blackListValueWidget = <Input required={true} type="text" onChange={(e)=>setState({newBlackListConditionValuesT:e.target.value})} value={state.newBlackListConditionValuesT}/>;


  if(state.newEnableListConditionValues!==undefined&&state.newEnableListConditionValues.length>0&&state.newEnableListConditionOperator!=='like')
    enableListValueWidget =
    <MySelect
      type="select"
      required={true}
      selectIsClearable={true}
      value={state.newEnableListConditionValuesS}
      onChange={(v)=>{enableListValueOnChange(v);setState({newEnableListConditionValuesS:v})}}
      options={state.newEnableListConditionValues.map((value)=>{
        return {label:value.label, value:value.value};
      })}
    />;
  else
    enableListValueWidget = <Input required={true} type="text" onChange={(e)=>setState({newEnableListConditionValuesT:e.target.value})} value={state.newEnableListConditionValuesT}/>;

  blackLists = state.blackList.map(
    (list, index)=>{

      let conditions;

      conditions = list.conditions.map(
        (condition,index2)=>{
          return(
            <tr key={index2}>
              <td><i className="fa fa-times red-color cursor-pointer" onClick={()=>deleteBlackListCondition(list.ID, condition.ID)}></i> {condition.description}</td>
              <td>{condition.field}</td>
              <td>{condition.operator}</td>
              <td>{condition.value}</td>
            </tr>
          );
        }
      );

      return(
        <div key={index}>
          <Card>
            <CardHeader className="header-color">
              <i className="fa fa-times red-color cursor-pointer" onClick={
                ()=>{
                  confirmation(
                    ()=>{deleteBlackListRule(list.ID)},
                    ()=>{},
                    'Delete auto bid rule?',
                    'Are you sure you want to delete this rule? You will lose all conditions that associated with this rule.');
                }
              }></i> {list.description}
            </CardHeader>
            <CardBody>
              <Table className="table table-striped">
                <thead>
                  <tr>
                    <th width="35%">Description</th>
                    <th width="15%">Field</th>
                    <th width="25%">Operator</th>
                    <th width="25%">Value</th>
                  </tr>
                </thead>
                <tbody>
                  {conditions}
                </tbody>
              </Table>
            </CardBody>
          </Card>
          <br/>
        </div>
      );
    }
  );

  enableLists = state.enableList.map(
    (list, index)=>{

      let conditions;

      conditions = list.conditions.map(
        (condition,index2)=>{
          return(
            <tr key={index2}>
              <td><i className="fa fa-times red-color cursor-pointer" onClick={()=>deleteEnableListCondition(list.ID, condition.ID)}></i> {condition.description}</td>
              <td>{condition.field}</td>
              <td>{condition.operator}</td>
              <td>{condition.value}</td>
            </tr>
          );
        }
      );

      return(
        <div key={index}>
          <Card>
            <CardHeader className="header-color">
              <i className="fa fa-times red-color cursor-pointer" onClick={
                ()=>{
                  confirmation(
                    ()=>{deleteEnableListRule(list.ID)},
                    ()=>{},
                    'Delete auto bid rule?',
                    'Are you sure you want to delete this rule? You will lose all conditions that associated with this rule.');
                }
              }></i> {list.description}
            </CardHeader>
            <CardBody>
              <Table className="table table-striped">
                <thead>
                  <tr>
                    <th width="35%">Description</th>
                    <th width="15%">Field</th>
                    <th width="25%">Operator</th>
                    <th width="25%">Value</th>
                  </tr>
                </thead>
                <tbody>
                  {conditions}
                </tbody>
              </Table>
            </CardBody>
          </Card>
          <br/>
        </div>
      );
    }
  );

  return <div>
    <div className="my-well">
      <Row>
        <Col sm="6">
          <div className="page-title">
            <i className="fa fa-reorder"></i>&nbsp;Auto Bid
          </div>
          <NavLink to="/system-setting">Back to settings</NavLink>
        </Col>
        <Col sm="6">

        </Col>
      </Row>
      <br/>
      <Nav tabs>
        <NavItem>
          <NavLinkL
            className={"nav-link "+(state.autoBidActiveTab === '1'?"active":"inactive" )}
            onClick={() => { autoBidActiveTabToggle('1'); }}
            to="#"
          >
            Enable List
          </NavLinkL>
        </NavItem>
        <NavItem>
          <NavLinkL
            className={"nav-link "+(state.autoBidActiveTab === '2'?"active":"inactive" ) }
            onClick={() => { autoBidActiveTabToggle('2'); }}
            to="#"
          >
            Black List
          </NavLinkL>
        </NavItem>
      </Nav>
      <TabContent activeTab={state.autoBidActiveTab}>
        <TabPane tabId="1">
          <Card>
            <CardHeader className="header-color">
              <i className="fa fa-reorder"></i>&nbsp;Enable list
            </CardHeader>
            <CardBody>
              {enableLists}
              <br/>
              <div className="my-divider"></div>

              <i className="fa fa-plus green-color"></i> Add enable list rules<br/>
              <label>Rule name</label>
              <Row>
                <Col sm="4">
                  <Input type="text" value={state.newEnableListName} onChange={(e)=>setState({newEnableListName:e.target.value})} />
                </Col>
                <Col sm="8">
                  <Button color="warning" onClick={addNewEnableList}>Create rule</Button>
                </Col>
              </Row>
              <div className="my-divider"></div>
              <br/>

              <form onSubmit={(e)=>{e.preventDefault();addNewEnableListCondition()}}>
                <Row>
                  <Col sm="6">
                    <label><font className="red-color">*</font>Associated rule</label>
                    <MySelect
                      type="select"
                      required={true}
                      value={state.newEnableListConditionRuleSelected}
                      onChange={(v)=>{setState({newEnableListConditionRuleSelected:v})}}
                      options={[{label:"Select a rule",value:""}].concat(
                        state.enableList.map((list)=>{
                          return {label:list.description, value:list.ID};
                         })
                      )}
                    />
                  </Col>
                  <Col sm="6">
                    <label>Description</label>
                    <Input type="text" onChange={(e)=>setState({newEnableListConditionDescription:e.target.value})} value={state.newEnableListConditionDescription}></Input>
                  </Col>
                </Row>

                <Row>
                  <Col sm="4">
                    <label><font className="red-color">*</font>Field name</label>
                    <MySelect
                      type="select"
                      required={true}
                      value={state.newEnableListConditionField}
                      onChange={(v)=>{setState({newEnableListConditionField:v});enableListFieldOnChange(v);}}
                      options={[{label:"Select a field",value:""}].concat(
                        state.fieldsEnum.map((field)=>{
                          return {label:field.label, value:field.field};
                         })
                      )}
                    />
                  </Col>
                  <Col sm="4">
                    <label><font className="red-color">*</font>Operator</label>
                    <MySelect
                      type="select"
                      required={true}
                      value={state.newEnableListConditionOperator}
                      onChange={(v)=>{setState({newEnableListConditionOperator:v})}}
                      options={[{label:"Equal to",value:"="},{label:"Not equal to",value:"!="},{label:"Like",value:"like"}]}
                    />
                  </Col>
                  <Col sm="4">
                    <label><font className="red-color">*</font>Value</label>
                    {enableListValueWidget}
                  </Col>
                </Row>
                <br/>
                <div className="align-right"><Button color="warning">Create condition</Button></div>
              </form>
            </CardBody>
          </Card>
        </TabPane>
      </TabContent>
      <TabContent activeTab={state.autoBidActiveTab}>
        <TabPane tabId="2">
          <Card>
            <CardHeader className="header-color">
              <i className="fa fa-reorder"></i>&nbsp;Black list
            </CardHeader>
            <CardBody>
              {blackLists}
              <br/>
              <div className="my-divider"></div>

              <i className="fa fa-plus green-color"></i> Add black list rules<br/>
              <label>Rule name</label>
              <Row>
                <Col sm="4">
                  <Input type="text" value={state.newBlackListName} onChange={(e)=>setState({newBlackListName:e.target.value})} />
                </Col>
                <Col sm="8">
                  <Button color="warning" onClick={addNewBlackList}>Create rule</Button>
                </Col>
              </Row>
              <div className="my-divider"></div>
              <br/>

              <form onSubmit={(e)=>{e.preventDefault();addNewBlackListCondition()}}>
                <Row>
                  <Col sm="6">
                    <label><font className="red-color">*</font>Associated rule</label>
                    <MySelect
                      type="select"
                      required={true}
                      value={state.newBlackListConditionRuleSelected}
                      onChange={(v)=>{setState({newBlackListConditionRuleSelected:v})}}
                      options={[{label:"Select a rule",value:""}].concat(
                        state.blackList.map((list)=>{
                          return {label:list.description, value:list.ID};
                         })
                      )}
                    />
                  </Col>
                  <Col sm="6">
                    <label>Description</label>
                    <Input type="text" onChange={(e)=>setState({newBlackListConditionDescription:e.target.value})} value={state.newBlackListConditionDescription}></Input>
                  </Col>
                </Row>

                <Row>
                  <Col sm="4">
                    <label><font className="red-color">*</font>Field name</label>
                    <MySelect
                      type="select"
                      required={true}
                      value={state.newBlackListConditionField}
                      onChange={(v)=>{setState({newBlackListConditionField:v});blackListFieldOnChange(v);}}
                      options={[{label:"Select a field",value:""}].concat(
                        state.fieldsEnum.map((field)=>{
                          return {label:field.label, value:field.field};
                         })
                      )}
                    />
                  </Col>
                  <Col sm="4">
                    <label><font className="red-color">*</font>Operator</label>
                    <MySelect
                      type="select"
                      required={true}
                      value={state.newBlackListConditionOperator}
                      onChange={(v)=>{setState({newBlackListConditionOperator:v})}}
                      options={[{label:"Equal to",value:"="},{label:"Not equal to",value:"!="},{label:"Like",value:"like"}]}
                    />
                  </Col>
                  <Col sm="4">
                    <label><font className="red-color">*</font>Value</label>
                    {blackListValueWidget}
                  </Col>
                </Row>
                <br/>
                <div className="align-right"><Button color="warning">Create condition</Button></div>
              </form>
            </CardBody>
          </Card>
        </TabPane>
      </TabContent>
    </div>
  </div>;
}

export default AutoBidConfiguration;
