//Author Sooyoung Kim
//Date April 21, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, deleteAPICallGenerator, callBackGenerator, formatDateTime} from '../../util/util';
import {Button, Col, Modal, ModalHeader, ModalBody, Row, Input, Card, CardBody, CardHeader} from 'reactstrap';
import MyXEditable from '../util/my-xeditable';
import {NavLink} from 'react-router-dom';
import MyReactTable from '../util/my-react-table';
import '../util/my-confirmation.css';
import React, {useReducer, useEffect} from 'react';


//initialize the state
const initialState = {
  websiteList:[],
  userPassword:'',
  selectedID:'',
  changePasswordMessage:'',
  showPassword:'',

  newWebsiteName:'',
  newPortalUrl:'',
  newUsername:'',
  newPassword:'',
  newNote:'',

  passwordPopUpOpen:false,
  addNewLinkPopUpOpen:false,
};

//reducer function that perform state update
const reducer = getReducer();


const WebsiteList  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);
  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getWebsites();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const togglePasswordPopUp = () =>{
    if(!state.passwordPopUpOpen){
      setState({showPassword:''});
    }
    setState({passwordPopUpOpen:!state.passwordPopUpOpen});
  }

  const addNewLinkDropDownToggle = () =>{
    setState({addNewLinkPopUpOpen:!state.addNewLinkPopUpOpen});
  }

  const updateFieldCallBack = (ID, field, value) =>{
    let websiteList = state.websiteList.slice();
    for(let i=0;i<websiteList.length;i++){
      if(websiteList[i].ID===ID){
        websiteList[i][field] = value;
        break;
      }
    }
    setState({websiteList:websiteList});
  }

  //API call
  const getWebsites = () =>{
    let url = 'website/get';
    let callBack = apiCallBack([{state:'websiteList', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load website list. Please try again later.', callBack);
  }

  //generate a function that do the update on editable
  const generateUpdateFunction = (url, parameters, successMessage, failedMessage, parentCallBack=null) =>{
    return (newValue, callBack, errorCallBack)=>{
      //add in the new value into parameters

      parameters.push({field:'value',value:newValue});
      let promise = httpPut(url,parameters,successMessage,failedMessage, callBack, errorCallBack);
      promise.then(
        (response)=>{
          if(parentCallBack!==null){
            let ID;
            let field;
            let value;

            for(let i=0;i<parameters.length;i++){
              if(parameters[i].field==='ID')
                ID = parameters[i].value;
              else if(parameters[i].field==='field')
                field = parameters[i].value;
              else if(parameters[i].field==='value')
                value = parameters[i].value;
            }
            parentCallBack(ID, field, value);
          }
        }
      );
    }
  };

  const verifyPassword = (ID) =>{
    let url = 'website/password/check';
    let successMsg = 'Password revealed successfully';
    let errorMsg = 'Oops, something went wrong and could not show the password. Please try again later.';

    let parameters = [
      {
        field:'password',
        value:state.userPassword
      },
      {
        field:'partyId',
        value:state.selectedID
      }
    ];

    let callBack = (response)=>{
      console.log(response)
      let code= response.data.code;

      if(code==='00'){
        let websiteList = state.websiteList.slice();
        for(let i=0;i<websiteList.length;i++){
          if(websiteList[i].ID===ID){
            websiteList[i] = response.data.data;
            break;
          }
        }
        setState({showPassword:response.data.data.portal_password, websiteList:websiteList});

        togglePasswordPopUp();
      }
    };

    callBack = callBack.bind(this);

    httpPost(url, parameters, successMsg, errorMsg, callBack);
  }

  // add new link for specific website
  const addNewLink = (e) =>{
    e.preventDefault();
    let url = 'website/create';
    let successMsg = 'New link added successfully.';
    let errorMsg = 'Oops, something went wrong and could not add this link. Please try again later.';

    let parameters = [
      {
        field:'website',
        value:state.newWebsiteName
      },
      {
        field:'portalUrl',
        value:state.newPortalUrl
      },
      {
        field:'userName',
        value:state.newUsername
      },
      {
        field:'password',
        value:state.newPassword
      },
      {
        field:'note',
        value:state.newNote
      },
    ];

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;

      if(code==='00'){
        let websiteList = state.websiteList.slice();

        websiteList.push(response.data.data);

        setState({websiteList:websiteList});
        addNewLinkDropDownToggle();
      }
    };
    callBack = callBack.bind(this);

    httpPost(url, parameters, successMsg, errorMsg, callBack);
  }

  const removeLink = (id) =>{
    let url = 'website/link/'+id;
    let successMsg = 'Website info deleted successfully.';
    let errorMsg = 'Oops, something went wrong and could not delete this website info. Please try again later.';

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;

      if(code!=='00'){

      }
      else{
        let websiteList = state.websiteList.slice();

        for(let i=0;i<websiteList.length;i++)
          if(websiteList[i].ID===id)
            websiteList.splice(i,1);

        setState({websiteList:websiteList});
      }
    };
    callBack = callBack.bind(this);

    httpDelete(url,successMsg,errorMsg, callBack);
  }

  //render
  const columns = [
    {
      id:'action',
      Header:'',
      minWidth:30,
      width:30,
      maxWidth:30,
      accessor: d => '',
      Cell: props =>{
        return <center>
          <i className="fa fa-times red-color cursor-pointer" onClick={()=>removeLink(props.row.original.ID)}></i>
        </center>
      }
    },
    {
      id: 'website',
      Header: 'Website',
      accessor: d => d.website,
      Cell: props => <a className="display-inline" href={props.row.original.portal_url} rel="noreferrer" target="_blank"><b>{props.row.original.website}</b></a>,
      className: 'wordwrap',
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'link',
      Header: 'Link',
      minWidth:140,
      accessor: d => d.portal_url,
      Cell: props => <MyXEditable
          type="textarea"
          passiveTextColor="#1B53B3"
          prefix=""
          value={props.row.original.portal_url}
          updateFunc={
            generateUpdateFunction('website/update',[{field:'field',value:'portal_url'},{field:'ID',value:props.row.original.ID}],'Url successfully updated.', 'Update url failed, please try again later.', updateFieldCallBack)
          }
       />,
      className: 'wordwrap',
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'username',
      Header: 'Username',
      accessor: d => d.portal_username,
      Cell: props => <MyXEditable
          type="textarea"
          passiveTextColor="#1B53B3"
          prefix=""
          value={props.row.original.portal_username}
          updateFunc={
            generateUpdateFunction('website/update',[{field:'field',value:'portal_username'},{field:'ID',value:props.row.original.ID}],'Username successfully updated.', 'Update username failed, please try again later.', updateFieldCallBack)
          }
       />,
      className: 'wordwrap',
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'password',
      Header: 'Password',
      accessor: d => d.portal_password,
      Cell: props => {

        let view = <div>-</div>;

        if(props.row.original.portal_password!==''){
          view = <NavLink to="#" style={{'fontSize':'14px'}} onClick={(e)=>setState({selectedID:props.row.original.ID}, togglePasswordPopUp())}>View Password</NavLink>;

          if(state.selectedID === props.row.original.ID && state.showPassword!==''){
            view = <MyXEditable
                type="textarea"
                passiveTextColor="#1B53B3"
                prefix=""
                value={props.row.original.portal_password}
                updateFunc={
                  generateUpdateFunction('website/update',[{field:'field',value:'portal_password'},{field:'ID',value:props.row.original.ID}],'Password successfully updated.', 'Update password failed, please try again later.', updateFieldCallBack)
                }
             />;
          }
        }

        return(
          <div>
            {view}
          </div>
        )
      },
      className: 'wordwrap',
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'note',
      Header: 'Note',
      minWidth:140,
      accessor: d => d.general_note,
      Cell: props => <MyXEditable
          type="textarea"
          passiveTextColor="#1B53B3"
          prefix=""
          value={props.row.original.general_note}
          updateFunc={
            generateUpdateFunction('website/update',[{field:'field',value:'general_note'},{field:'ID',value:props.row.original.ID}],'General note updated.', 'Update general note failed, please try again later.', updateFieldCallBack)
          }
       />,
      className: 'wordwrap',
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'lastModified',
      Header: 'Last Modified By',
      accessor: d => d.last_modified_by,
      className: 'wordwrap',
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'lastModifiedDate',
      Header: 'Last Modified Date',
      accessor: d => d.last_modified_date,
      Cell: props=> <div>{formatDateTime(props.row.original.last_modified_date)}</div>,
      className: 'wordwrap',
      headerStyle: {
        textAlign:'left'
      }
    },
  ];

  return <div className="my-well">
    <Modal className="confirmation-dialog" isOpen={state.passwordPopUpOpen} toggle={togglePasswordPopUp} >
      <ModalHeader className="my-confirmation-title" toggle={togglePasswordPopUp}><i className="fa fa-warning"></i>Enter your account password</ModalHeader>
      <ModalBody>
        {(state.changePasswordMessage!=='')&&<div style={{height:'30px'}}>
          <label><font color="red">{state.changePasswordMessage}</font></label>
        </div>}
        <br/>
        <label>Password:</label><br/>
        <Input required={true} type="text" value={state.userPassword} onChange={(e)=>setState({userPassword:e.target.value})}/>
        <br/>
        <center>
          <Button color="warning" onClick={(e)=>{verifyPassword(state.selectedID)}}>Submit</Button>&nbsp;
          <Button color="info" onClick={togglePasswordPopUp}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
    <Modal className="my-modal" isOpen={state.addNewLinkPopUpOpen} toggle={addNewLinkDropDownToggle} >
      <ModalHeader hidden={true} toggle= {addNewLinkDropDownToggle}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-window-maximize"></i> Add New Website</h5>
        </center>
        <br/>
        <form onSubmit={addNewLink}>
          <Row>
            <Col sm="12">
              <label>Website Name:</label>
              <Input required="true" type="text" value={state.newWebsiteName} onChange={(e)=>setState({newWebsiteName:e.target.value})}/>
            </Col>
            <Col sm="12">
              <label>Link:</label>
              <Input type="text" value={state.newPortalUrl} onChange={(e)=>setState({newPortalUrl:e.target.value})}/>
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <label>Username:</label>
              <Input required="true" type="text" value={state.newUsername} onChange={(e)=>setState({newUsername:e.target.value})}/>
            </Col>
            <Col sm="6">
              <label>Password:</label>
              <Input required="true" type="text" value={state.newPassword} onChange={(e)=>setState({newPassword:e.target.value})}/>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <label>General Note:</label>
              <Input type="textarea" value={state.newNote} onChange={(e)=>setState({newNote:e.target.value})} rows="3"/>
            </Col>
          </Row>
          <br/>
          <center>
            <Button color="warning"><i className="fa fa-check"></i>&nbsp;Add</Button>{' '}
            <Button color="info" onClick={addNewLinkDropDownToggle}>Close</Button>
          </center>
        </form>
      </ModalBody>
    </Modal>
    <Row>
      <Col sm="6">
        <div className="page-title">
          <i className="fa fa-reorder"></i>&nbsp;Website List
        </div>
        <NavLink to="/system-setting">Back to settings</NavLink>
      </Col>
      <Col sm="6">
        <div className="align-right">
          <Button color="warning" onClick={addNewLinkDropDownToggle}><i className="fa fa-plus"></i>&nbsp;&nbsp;Add Link</Button>
        </div>
      </Col>
    </Row>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-tasks"></i> Website
      </CardHeader>
      <CardBody>
        <div className="overflow-auto">
          <MyReactTable columns={columns} data={state.websiteList} className="table table-striped"/>
        </div>
      </CardBody>
    </Card>
  </div>;
}

export default WebsiteList;
