//Author Sooyoung Kim
//Date June 27, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, formatNumber, formatDate} from '../../../util/util';
import {Collapse, Card, CardHeader, CardBody} from 'reactstrap';
import moment from 'moment';
import React, {useReducer, useEffect} from 'react';
import MyHeatMapCounties from './../../util/my-heat-map-counties';
import {NavLink} from 'react-router-dom';
import MyReactTable from '../../util/my-react-table';
import ReportFilter from '../report-filter';

let today = new Date();
let month = today.getMonth()+1;

if(month<10)
  month = '0'+month;
let from = today.getFullYear()+'-'+month+'-'+today.getDate();
let to = today.getFullYear()+'-'+month+'-'+today.getDate();

//initialize the state
const initialState = {
  clients:[],
  report:[],
  client:-1,
  from:from,
  to:to,
  collapseState:[],
  collapseCounty:[],
};

//reducer function that perform state update
const reducer = getReducer();


const ComplexFeeReport  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getReportDetails();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const stateCollapseOnClick = (stateName) => {
  
    let collapseState = state.collapseState.slice();

    let index = collapseState.indexOf(stateName);

    if(index===-1)
      collapseState.push(stateName);
    else
      collapseState.splice(index, 1);

    setState({collapseState:collapseState});
  }

  const countyCollapseOnClick = (county) => {
  
    let collapseCounty = state.collapseCounty.slice();

    let index = collapseCounty.indexOf(county);

    if(index===-1)
      collapseCounty.push(county);
    else
      collapseCounty.splice(index, 1);

    setState({collapseCounty:collapseCounty});
  }

  const isStateCollapse = (stateName) => {
  
    let collapseState = state.collapseState.slice();

    if(collapseState.indexOf(stateName)!==-1)
      return true;
    else
      return false;
  }

  const isCountyCollapse = (county) => {
  
    let collapseCounty = state.collapseCounty.slice();

    if(collapseCounty.indexOf(county)!==-1)
      return true;
    else
      return false;
  }

  //API call
  const getReportDetails = () => {
    setState({report:[]});

    let callBack = apiCallBack([{state:'report', key:'data'}]);
    httpGet('report/complexFee/get/from='+state.from+'&to='+state.to+'&client='+state.client, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  //render
  // props data for heat map
  let propsData = [];

  for(let i=0;i<state.report.length;i++){
    for(let j=0;j<state.report[i].counties.length;j++){
      let tmp = {
        state:state.report[i].state,
        state_short:state.report[i].state_short,
        county:state.report[i].counties[j].county,
        value:formatNumber((state.report[i].counties[j].counter/state.report[i].counties[j].volume)*100)
      };

      propsData.push(tmp);
    }
  }

  let from, to;
  if(state.from!=='')
    from = moment(state.from);
  if(state.to!=='')
    to = moment(state.to);

  /*
  React Table
  */

  const columns3 = [
    {
      id: 'referenceNum',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/billing/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'company',
      Header: 'Client',
      accessor: d => d.company,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'loanNum',
      Header: 'Loan #',
      accessor: d => d.loan_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'property',
      Header: 'Property',
      accessor: d => d.property_street+' '+d.property_city+', '+d.property_state+' '+d.property_zip,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'borrower',
      Header: 'Borrower',
      accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'amount',
      Header: 'Complex Fee',
      accessor: d => d.amount,
      Cell: props => <div>${formatNumber(props.row.original.amount)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'date',
      Header: 'Date Submitted',
      accessor: d => d.datetime_submitted,
      Cell: props=> <div>{formatDate(props.row.original.datetime_submitted)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  const columns2 = [
    {
      id: 'county',
      Header: 'County',
      accessor: d => d.county,
      Cell: props => <div className="cursor-pointer" >
        <div onClick={(e)=>countyCollapseOnClick(props.row.original.county)}><label>{isCountyCollapse(props.row.original.county)?<i className="fa fa-minus red-color"/>:<i className="fa fa-plus green-color"/>} {props.row.original.county}</label></div>
        <Collapse isOpen={isCountyCollapse(props.row.original.county)}>
          {
            props.row.original.cities.map(
              (city, index)=>{
                return <div key={index}>
                  <label>{city.city}</label><br/>
                  <MyReactTable columns={columns3} data={city.data} className="table table-striped"/>
                  <br/><br/>
                </div>
              }
            )
          }
        </Collapse>
      </div>,
      headerStyle: {
        textAlign:'left',
        fontWeight:'bold'
      }
    },
    {
      id: 'count',
      Header: 'Count',
      width:100,
      accessor: d => d.counter,
      headerStyle: {
        textAlign:'left',
        fontWeight:'bold'
      }
    },
    {
      id: 'totalAppraiser',
      Header: 'Total Appraiser',
      width:100,
      accessor: d => d.total_appraiser,
      headerStyle: {
        textAlign:'left',
        fontWeight:'bold'
      }
    },
    {
      id: 'totalAppraiserExteriorOnly',
      Header: 'Total Appraiser Exterior Only',
      width:100,
      accessor: d => d.total_appraiser_exterior_only,
      headerStyle: {
        textAlign:'left',
        fontWeight:'bold'
      }
    },
    {
      id: 'volume',
      Header: 'Order Volume',
      width:100,
      accessor: d => d.volume,
      Cell: props => <div>{props.row.original.volume+' ('+formatNumber(props.row.original.counter/+props.row.original.volume*100)+'%)'}</div>,
      headerStyle: {
        textAlign:'left',
        fontWeight:'bold'
      }
    }
  ]

  const columns = [
    {
      id: 'state',
      Header: 'State',
      accessor: d => d.state,
      Cell: props => <div className="cursor-pointer" >
        <div onClick={(e)=>stateCollapseOnClick(props.row.original.state)}><label>{isStateCollapse(props.row.original.state)?<i className="fa fa-minus red-color"/>:<i className="fa fa-plus green-color"/>} {props.row.original.state}</label></div>
        <Collapse isOpen={isStateCollapse(props.row.original.state)}>
          <MyReactTable columns={columns2} data={props.row.original.counties} className="table table-striped"/>
        </Collapse>
      </div>,
      headerStyle: {
        textAlign:'left',
        fontWeight:'bold'
      }
    },
    {
      id: 'count',
      Header: 'Count',
      width:100,
      accessor: d => d.counter,
      headerStyle: {
        textAlign:'left',
        fontWeight:'bold'
      }
    },
    {
      id: 'totalAppraiser',
      Header: 'Appraiser',
      width:100,
      accessor: d => d.total_appraiser,
      headerStyle: {
        textAlign:'left',
        fontWeight:'bold'
      }
    },
    {
      id: 'totalAppraiserExteriorOnly',
      Header: 'Appraiser Exterior Only',
      width:100,
      accessor: d => d.total_appraiser_exterior_only,
      headerStyle: {
        textAlign:'left',
        fontWeight:'bold'
      }
    },
    {
      id: 'volume',
      Header: 'Order Volume',
      width:100,
      accessor: d => d.volume,
      Cell: props => <div>{props.row.original.volume+' ('+formatNumber(props.row.original.counter/+props.row.original.volume*100)+'%)'}</div>,
      headerStyle: {
        textAlign:'left',
        fontWeight:'bold'
      }
    }
  ];

  let reportFiltersConfig = [
    {id:'from',value:from, updateFunc:(v)=>setState({from:v}), width:'2'},
    {id:'to',value:to, updateFunc:(v)=>setState({to:v}), width:'2'},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'5'},
    {id:'button',value:'Submit', updateFunc:getReportDetails, width:'3', className:"align-right", color:"warning"},
  ];

  let heatMap;

  if(state.report.length>0)
    heatMap = <MyHeatMapCounties data={propsData} title={'Complex Fee Report'} min={0} max={50} dataLabel='report'/>;  

  return <div>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Complex Fee Report
      </CardHeader>
      <CardBody>
        <ReportFilter {...props} configs={reportFiltersConfig}/>
        <div className="my-divider">&nbsp;</div>
        {heatMap}
        <MyReactTable columns={columns} data={state.report} className="table table-striped"/>
      </CardBody>
    </Card>
  </div>;
}


export default ComplexFeeReport;
