//Author June leow
//Date Oct 9th, 2023
import {getReducer, getSetStateFunction, putAPICallGenerator, formatDateTime} from '../../util/util';
import {Button, Row, Col, Input, Offcanvas, OffcanvasBody} from 'reactstrap';
import React, {useReducer, useEffect, useRef} from 'react';
//initialize the state
const initialState = {
  show:false,
  show2:true,
  scrolled:false
};

//reducer function that perform state update
const reducer = getReducer();


const FloatingInfo  = (props)=>{
  const controller = new AbortController();
  const scrolledRef = useRef(false);

  const newInitialState = Object.assign({}, initialState, {show:props.appraisal.special_instructions!==''});
  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    
    const myDiv = document.getElementById('contentContainer');
    if (myDiv) {
      myDiv.addEventListener('scroll', onScroll);
    }

    return ()=> {
      if(myDiv){
        myDiv.removeEventListener('scroll', onScroll);
      }
      controller.abort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    scrolledRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.activeTab]);

  //non API call but simpyl manage state
  const onScroll = ()=>{
    if (!scrolledRef.current) {
      scrolledRef.current = true;
      setState({show:false,show2:false})
    }
  }
   

  const toggleShow = ()=>{
    setState({show:!state.show});
  }

  const toggleShow2 = ()=>{
    setState({show2:!state.show2});
  }

  const changeInstructions = (e)=>{
    props.updateAppraisal(Object.assign({}, props.appraisal, {special_instructions:e.target.value}))
  }

  //API call
  const updateSpecialInstructions = ()=>{
    let url = 'appraisal/update';
    let parameters = [{field:'field',value:'special_instructions'}, {field:'value',value:props.appraisal.special_instructions}, {field:'ID',value:props.appraisal.ID}];

    let callBack = (response)=>{
      props.updateAppraisal(props.appraisal);
    }
    httpPut(url, parameters, 'Special instructions updated successfully','Oops, something went wrong and could not update special instructions. Please try again later.', callBack);
  }

  const dates =['datetime_submitted','datetime_inspection','datetime_due','datetime_expected'];
  const dateLabels = ['Date Submitted', 'Date Inspection', 'Appraiser Due Date', 'Client Due Date'];
  const fields = ['property_type','loan_type','loan_purpose','selling_price','property_county','priority','rush','appraisal_type'];
  const fieldLabels = ['Property Type','Loan Type','Loan Purpose','Purchase Price','County','Priority','Rush','Report Type'];
  
  //render
  return <div>
    {!state.show2 && !props.activeTab!=='1' && props.fields.length>0?<div className="floating-order-container cursor-pointer" onClick={toggleShow2}>
      <center>
        SHOW ORDER INFO
      </center>
    </div>:null}
    {!props.activeTab!=='1' && props.fields.length>0?<Offcanvas
      isOpen={state.show2}
      backdrop={false}
      direction="end"
      className="floating-order"
      style={{padding:'0px'}}
    >
      <OffcanvasBody style={{padding:'0px', paddingBottom:'20px', paddingLeft:'20px'}}>
        <div className="floating-order-nav-bar cursor-pointer" onClick={toggleShow2}>
          <center>
            HIDE ORDER INFO
          </center>
        </div>
        <div className="floating-order-content" style={{fontSize:'11px'}}>
          {props.fields.indexOf('address')!==-1?<div><label>Property</label><br/>{props.appraisal.property_street+' '+props.appraisal.property_city+', '+props.appraisal.property_state+' '+props.appraisal.property_zip}</div>:null}
          <Row style={{width:'100%'}}>
            {
              dates.map((date, index)=>{
                if(props.fields.indexOf(date)!==-1){
                  return <Col sm="6" key={index}>
                    <label>{dateLabels[index]}</label><br/>{formatDateTime(props.appraisal[date])}
                  </Col>;
                }
                return null;
              })
            }
          </Row> 
          <Row style={{width:'100%'}}>
            {
              props.fields.map((field, index)=>{
                if(fields.indexOf(field)!==-1){
                  return <Col sm="6" key={index}>
                    <label>{fieldLabels[index]}</label><br/>{props.appraisal[field]}
                  </Col>;
                }
                return null;
              })
            }
          </Row> 
        </div>
      </OffcanvasBody> 
    </Offcanvas>:null}
    {!state.show?<div className="floating-memo-container cursor-pointer" onClick={toggleShow}>
      <center>
        {props.appraisal.special_instructions!==''?<i className="flashit fa fa-warning red-color"/>:null} SHOW SPECIAL INSTRUCTIONS
      </center>
    </div>:null}
    <Offcanvas
      isOpen={state.show}
      backdrop={false}
      direction="end"
      className="floating-memo"
      style={{padding:'0px'}}
    >
      <OffcanvasBody style={{padding:'0px', paddingBottom:'20px', paddingLeft:'20px'}}>
        <div className="floating-memo-nav-bar cursor-pointer" onClick={toggleShow}>
          <center>
            HIDE SPECIAL INSTRUCTIONS
          </center>
        </div>
        <div className="floating-memo-content">
          <Input type="textarea" value={props.appraisal.special_instructions} onChange={changeInstructions} className="non-editable-textarea yellow-background-textarea margin-bottom" style={{borderRadius:'0px'}} rows="14" name="text" id="exampleText"/>
          <Button color="info" className="form-control" onClick={updateSpecialInstructions}><i className="fa fa-pencil"></i> Save Memo</Button>
        </div>
      </OffcanvasBody> 
    </Offcanvas>
  </div>;
}


export default FloatingInfo;
