//Author Sooyoung 
//Date July 18, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, deleteAPICallGenerator, callBackGenerator, confirmation, formatDate, generateSID} from '../../util/util';
import {Input, Button, Card, CardHeader, CardBody, Table, Row, Col, Modal, ModalHeader, ModalBody, NavItem, Nav, TabContent, TabPane, NavLink as NavLinkL} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import MySelect from './../util/my-select';
import {NavLink, useParams} from 'react-router-dom';

//initialize the state
const initialState = {
  SID: generateSID(),
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
  profile:{
      company:'',
      phone:'',
      street:'',
      city:'',
      state:'',
      zip:'',
      note:'',
      brokers:[],
      aes:[],
      clients:[],
      loan_officers:[],
      processors:[],
      appraisers:[],
  },
  clients:[],
  tab:'1',

  newBrokerDropdownOpen: false,
  newAEDropdownOpen: false,
  newLoanOfficerDropdownOpen: false,
  newProcessorDropdownOpen: false,
  newAppraiserDropdownOpen: false,

  clientBranches:[],
  branchFk:0,

  firstName:'',
  lastName:'',
  email:'',
  phone:'',
  street:'',
  city:'',
  state:'',
  zip:'',
  note:'',

  selectedAE:-1,
  appraiser:'',
  autoAssign:false
};

//reducer function that perform state update
const reducer = getReducer();


const BrokerRelationshipProfiles  = (props)=>{
  const controller = new AbortController();
  let {id} = useParams();

  let newInitialState = Object.assign({}, initialState, {
      ID:id,
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getProfile(id);

    getAllClientProfiles();
    getClientBranches();
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const handleChange = (selectedOption) => {
    setState({ appraiser:selectedOption });
  }

  const toggleNewCSRDropdown = (aeFk) => {
    setState({selectedAE:aeFk, newCSRDropdownOpen:!state.newCSRDropdownOpen});
  }

  const toggleNewBrokerDropdown = () => {
    setState({newBrokerDropdownOpen:!state.newBrokerDropdownOpen});
  }

  const toggleNewAEDropdown = () => {
    setState({newAEDropdownOpen:!state.newAEDropdownOpen});
  }

  const toggleNewLoanOfficerDropdown = () => {
    setState({newLoanOfficerDropdownOpen:!state.newLoanOfficerDropdownOpen});
  }

  const toggleNewProcessorDropdown = () => {
    setState({newProcessorDropdownOpen:!state.newProcessorDropdownOpen});
  }

  const toggleNewAppraiserDropdown = () => {
    setState({newAppraiserDropdownOpen:!state.newAppraiserDropdownOpen});
  }

  //toggle function
  const activeTabToggle = (tab) => {
    setState({tab:tab});
  }

  //API call
  const updateAutoAssign = (ID, checked) => {
    let parameters = [
      {
        field:'ID',
        value:ID
      },
      {
        field:'field',
        value:'auto_assign'
      },
      {
        field:'value',
        value:checked==='no'?'yes':'no'
      }
    ];


    let callBack = (response)=>{
      let code= response.data.code;

      if(code==='00'){
        let profile = Object.assign({},state.profile);
        let appraisers = profile.appraisers.slice();

        for(let i=0;i<appraisers.length;i++){
          if(appraisers[i].ID===ID){
            if(checked==='no')
              appraisers[i].auto_assign='yes';
            else
              appraisers[i].auto_assign='no';
            break;
          }
        }
        profile.appraisers = appraisers;
        setState({profile:profile});
      }
    };
    callBack = callBack.bind(this);

    httpPut('brokerRelationship/appraiser/update', parameters, 'Client profile associated successfully.', 'Oops, something went wrong and could not associate the client profile. Please try again later.', callBack);
  }

  const addNewClient = () => {
    let url = 'brokerRelationship/client/create';
    let successMsg = 'Client profile associated successfully.';
    let errorMsg = 'Oops, something went wrong and could not associate the client profile. Please try again later.';

    let parameters = [
      {
        field:'brokerCompanyProfileFk',
        value:state.ID
      },
      {
        field:'clientFk',
        value:state.client
      }
    ];

    let callBack = (response)=>{
      let code= response.data.code;

      if(code==='00'){
        let profile = Object.assign({}, state.profile);
        let clients = profile.clients.slice();
        clients.push(response.data.data);

        profile.clients = clients;
        setState({profile:profile});
      }
    };
    callBack = callBack.bind(this);

    httpPost(url, parameters, successMsg, errorMsg, callBack);
  }

  const getAllClientProfiles = () => {
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;

      if(code==='00'){

        let clients = [];
        for(let i=0;i<response.data.data.length;i++){
          let client = response.data.data[i];

          let tmp = {};
          tmp.key = client.ID;
          tmp.value = client.company;

          clients.push(tmp);
        }
        if(clients.length>0)
          setState({client:clients[0].key});

        setState({clients:clients});
      }
    };
    callBack = callBack.bind(this);

    httpGet('client/get/limit=-1&offset=-1', '', 'Oops, something went wrong and could not retrieve client profiles.', callBack);
  }

  const deleteProfile = (type,id) => {
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;

      if(code==='00'){
        let profile = Object.assign({}, state.profile);
        if(type==='broker'){
          let brokers = profile.brokers.slice();

          for(let i=0;i<brokers.length;i++){
            if(brokers[i].ID===id){
              brokers.splice(i, 1);
              break;
            }
          }
          profile.brokers = brokers;
        }
        else if(type==='ae'){
          let aes = profile.aes.slice();

          for(let i=0;i<aes.length;i++){
            if(aes[i].ID===id){
              aes.splice(i, 1);
              break;
            }
          }
          profile.aes = aes;

        }
        else if(type==='csr'){
          let aes = profile.aes.slice();

          for(let i=0;i<aes.length;i++){
            for(let j=0;j<aes[i].csrs.length;j++){
              if(aes[i].csrs[j].ID===id){
                aes[i].csrs.splice(j,1);
                break;
              }
            }
          }
          profile.aes = aes;

        }
        else if(type==='client'){
          let clients = profile.clients.slice();

          for(let i=0;i<clients.length;i++){
            if(clients[i].ID===id){
              clients.splice(i, 1);
              break;
            }
          }
          profile.clients = clients;
        }
        else if(type==='loanOfficer'){
          let loan_officers = profile.loan_officers.slice();

          for(let i=0;i<loan_officers.length;i++){
            if(loan_officers[i].ID===id){
              loan_officers.splice(i, 1);
              break;
            }
          }
          profile.loan_officers = loan_officers;
        }
        else if(type==='processor'){
          let processors = profile.processors.slice();

          for(let i=0;i<processors.length;i++){
            if(processors[i].ID===id){
              processors.splice(i, 1);
              break;
            }
          }
          profile.processors = processors;
        }
		else if(type==='appraiser'){
          let appraisers = profile.appraisers.slice();

          for(let i=0;i<appraisers.length;i++){
            if(appraisers[i].ID===id){
              appraisers.splice(i, 1);
              break;
            }
          }
          profile.appraisers = appraisers;
        }
        setState({profile:profile});
      }
    };
    callBack = callBack.bind(this);

    httpDelete('brokerRelationship/'+type+'/'+id,'Deleted succesfully.','Oops, something went wrong and could not delete the record. Please try again later.', callBack);
  }

  const getProfile = (id) => {
    httpGet('brokerRelationship/'+id, '', 'Oops, something went wrong and could not load broker relationship profile. Please try again later.', apiCallBack([{state:'profile', key:'data'}]));
  }

  const createProfile = (type) => {
    let parameters = [
      {
        field:'brokerCompanyProfileFk',
        value:state.ID
      },
      {
        field:'firstName',
        value:state.firstName
      },
      {
        field:'lastName',
        value:state.lastName
      },
      {
        field:'email',
        value:state.email
      },
      {
        field:'phone',
        value:state.phone
      },
      {
        field:'street',
        value:state.street
      },
      {
        field:'city',
        value:state.city
      },
      {
        field:'state',
        value:state.state
      },
      {
        field:'zip',
        value:state.zip
      },
      {
        field:'note',
        value:state.note
      }
    ];

    if(type==='loanOfficer'||type==='processor'||type==='broker'||type==='ae'){
      let tmp = {
        field:'branchFk',
        value:state.branchFk
      };

      parameters.push(tmp);
    }

    let callBack = (response)=>{
      let code= response.data.code;

      if(code==='00'){
        let profile = Object.assign({}, state.profile);

        if(type==='broker'){
          let brokers = profile.brokers.slice();

          brokers.unshift(response.data.data);
          profile.brokers = brokers;

          toggleNewBrokerDropdown();
        }
        else if(type==='ae'){
          let aes = profile.aes.slice();
          response.data.data.csrs = [];
          aes.unshift(response.data.data);
          profile.aes = aes;

          toggleNewAEDropdown();
        }
        else if(type==='loanOfficer'){
          let loan_officers = profile.loan_officers.slice();

          loan_officers.unshift(response.data.data);
          profile.loan_officers = loan_officers;

          toggleNewLoanOfficerDropdown();
        }
        else if(type==='processor'){
          let processors = profile.processors.slice();

          processors.unshift(response.data.data);
          profile.processors = processors;

          toggleNewProcessorDropdown();
        }
        else if(type==='appraiser'){
          let appraisers = profile.appraisers.slice();

          appraisers.unshift(response.data.data);
          profile.appraisers = appraisers;

          toggleNewAppraiserDropdown();
        }

        setState({profile:profile, name:'', email:'', phone:'', street:'', city:'', state:'', zip:'', note:'', branchFk:0});
      }
    };
    callBack = callBack.bind(this);

    httpPost('brokerRelationship/'+type+'/create', parameters, 'Profile associated successfully.', 'Oops, something went wrong and could not associate the profile. Please try again later.', callBack);
  }

  const createAppraiserProfile = () => {
    let parameters = [
      {
        field:'appraiserFk',
        value:state.appraiser
      },
      {
        field:'brokerCompanyProfileFk',
        value:state.ID
      },
      {
        field:'autoAssign',
        value:state.autoAssign?'yes':'no'
      }
    ];

    let callBack = (response)=>{
      let code= response.data.code;

      if(code==='00'){
        let profile = Object.assign({}, state.profile);

        let appraisers = profile.appraisers.slice();

        appraisers.unshift(response.data.data);

        profile.appraisers = appraisers;

        toggleNewAppraiserDropdown(-1);

        setState({profile:profile, appraiser:{label:'',name:''}, autoAssign:false});
      }
    };
    callBack = callBack.bind(this);

    httpPost('brokerRelationship/appraiser/create', parameters, 'Appraiser profile associated successfully.', 'Oops, something went wrong and could not associate the appraiser profile. Please try again later.', callBack);
  }

  const createCSRProfile = () => {
    let parameters = [
      {
        field:'firstName',
        value:state.firstName
      },
      {
        field:'lastName',
        value:state.lastName
      },
      {
        field:'email',
        value:state.email
      },
      {
        field:'aeFk',
        value:state.selectedAE
      }
    ];


    let callBack = (response)=>{
      let code= response.data.code;
      if(code==='00'){
        let profile = Object.assign({}, state.profile);

        let aes = profile.aes.slice();
        for(let i=0;i<aes.length;i++){
          if(aes[i].ID===state.selectedAE){
            aes[i].csrs.unshift(response.data.data);
            break;
          }
        }

        profile.aes = aes;

        toggleNewCSRDropdown(-1);
        setState({profile:profile, name:'', email:'', selectedAE:-1});
      }
    };

    callBack = callBack.bind(this);

    httpPost('brokerRelationship/csr/create', parameters, 'Broker profile associated successfully.', 'Oops, something went wrong and could not associate the broker profile. Please try again later.', callBack);
  }

  const getAppraiser = (keyword) => {
    if(keyword&&keyword!==''){
      let SID = generateSID();
      setState({SID:SID});

      let callBack = (response)=>{
        if(SID===state.SID){
          let code = response.data?response.data.code:undefined;

          if(code!=='00'){
            return [{}];
          }
          else{
            let options = [];

            for(let i=0;i<response.data.data.length;i++){
              let tmp = {};
              tmp.label = response.data.data[i].first_name+' '+response.data.data[i].last_name+' - '+response.data.data[i].email+' - '+formatDate(response.data.data[i].datetime_last_login);
              tmp.value = response.data.data[i].ID;

              options.push(tmp);
            }
            return options;
          }
        }
      };
      callBack = callBack.bind(this);

      let promise = httpGet('appraiser/get/limit=100&name='+keyword, '', 'Oops, something went wrong and could not search for appraiser. Please try again later.', callBack);
      return promise;
    }
  }

  const getClientBranches = () => {
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;

      if(code==='00'){
        let clientBranches=[{key:'0',value:''}];
        for(let i=0;i<response.data.data.length;i++){
          let tmp = {};
          tmp.value = response.data.data[i].branch_name;
          tmp.key = response.data.data[i].ID;

          clientBranches.push(tmp);
        }
        setState({clientBranches:clientBranches});
      }
    };
    callBack = callBack.bind(this);

    httpGet('client/branch/get/'+state.ID, '', 'Oops, something went wrong and could not retrieve client branch list.', callBack);
  }

  //render
  return <div className="my-well">
    <Modal className="my-modal" isOpen={state.newAppraiserDropdownOpen} toggle={toggleNewAppraiserDropdown} >
      <ModalHeader hidden={true} toggle={toggleNewAppraiserDropdown}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-cogs"></i> New Appraiser Association</h5>
        </center>
        <Card>
          <CardHeader className="header-color"><i className="fa fa-info"/> Appraiser</CardHeader>
          <CardBody>
            <b>Appraiser</b>
            <MySelect
              modal={true}
              type="async-select"
              onChange={(v)=>handleChange(v)}
              defaultOptions
              loadOptions={getAppraiser}
            />
            <br/>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input type="checkbox" onClick={(e)=>{setState({autoAssign:e.target.checked})}}/>&nbsp;&nbsp; Auto Assign

            <br/>
            <div className="align-right">
              <Button color="warning" onClick={(e)=>createAppraiserProfile()}>Submit</Button>
            </div>
          </CardBody>
        </Card>
      </ModalBody>
    </Modal>

    <Modal className="my-modal" isOpen={state.newCSRDropdownOpen} toggle={toggleNewCSRDropdown} >
      <ModalHeader hidden={true} toggle={toggleNewCSRDropdown}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-cogs"></i> New CSR Association</h5>
        </center>
        <Card>
          <CardHeader className="header-color">
            <i className="fa fa-info"></i> CSR
          </CardHeader>
          <CardBody>
            <form onSubmit={createCSRProfile}>
              <Row className="margin-bottom">
                <Col sm="4">
                  <label>First Name</label>
                </Col>
                <Col sm="8">
                  <Input type="text" required="true" value={state.firstName} onChange={(e)=>setState({firstName:e.target.value})}/>
                </Col>
              </Row>
              <Row className="margin-bottom">
                <Col sm="4">
                  <label>Last Name</label>
                </Col>
                <Col sm="8">
                  <Input type="text" required="true" value={state.lastName} onChange={(e)=>setState({lastName:e.target.value})}/>
                </Col>
              </Row>
              <Row className="margin-bottom">
                <Col sm="4">
                  <label>Email</label>
                </Col>
                <Col sm="8">
                  <Input type="text" required="true" value={state.email} onChange={(e)=>setState({email:e.target.value})}/>
                </Col>
              </Row>
              <br/>
              <div className="align-right">
                <Button color="warning" onClick={(e)=>createCSRProfile()}>Submit</Button>
              </div>
            </form>
          </CardBody>
        </Card>
      </ModalBody>
    </Modal>

    <Modal className="my-modal" isOpen={state.newBrokerDropdownOpen} toggle={toggleNewBrokerDropdown} >
      <ModalHeader hidden={true} toggle={toggleNewBrokerDropdown}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-cogs"></i> New Broker Association</h5>
        </center>
        <Card>
          <CardHeader className="header-color">
            <i className="fa fa-info"></i> Broker
          </CardHeader>
          <CardBody>
            <form onSubmit={createProfile}>
              <Row className="margin-bottom">
                <Col sm="4">
                    <label>Associated Branch</label>
                </Col>
                <Col sm="8">
                  <MySelect
                    type="select"
                    value={state.branchFk}
                    onChange={(v)=>setState({branchFk:v})}
                    options={state.clientBranches.map((branch)=>{
                      return {label:branch.value, value:branch.key};
                    })}
                  />
                </Col>
              </Row>
              <Row className="margin-bottom">
                <Col sm="4">
                  <label>First Name</label>
                </Col>
                <Col sm="8">
                  <Input type="text" required="true" value={state.firstName} onChange={(e)=>setState({firstName:e.target.value})}/>
                </Col>
              </Row>
              <Row className="margin-bottom">
                <Col sm="4">
                  <label>Last Name</label>
                </Col>
                <Col sm="8">
                  <Input type="text" required="true" value={state.lastName} onChange={(e)=>setState({lastName:e.target.value})}/>
                </Col>
              </Row>
              <Row className="margin-bottom">
                <Col sm="4">
                  <label>Email</label>
                </Col>
                <Col sm="8">
                  <Input type="text" required="true" value={state.email} onChange={(e)=>setState({email:e.target.value})}/>
                </Col>
              </Row>
              <Row className="margin-bottom">
                <Col sm="4">
                  <label>Phone</label>
                </Col>
                <Col sm="8">
                  <Input type="text" value={state.phone} onChange={(e)=>setState({phone:e.target.value})}/>
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  <label>Note</label>
                </Col>
                <Col sm="8">
                  <Input type="text" value={state.note} onChange={(e)=>setState({note:e.target.value})}/>
                </Col>
              </Row>

              <br/>
              <div className="align-right">
                <Button color="warning" onClick={(e)=>createProfile('broker')}>Submit</Button>
              </div>
            </form>
          </CardBody>
        </Card>
      </ModalBody>
    </Modal>

    <Modal className="my-modal" isOpen={state.newAEDropdownOpen} toggle={toggleNewAEDropdown} >
      <ModalHeader hidden={true} toggle={toggleNewAEDropdown}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-cogs"></i> New AE Association</h5>
        </center>
        <Card>
          <CardHeader className="header-color">
            <i className="fa fa-info"></i> AE
          </CardHeader>
          <CardBody>
            <form onSubmit={createProfile}>
              <Row className="margin-bottom">
                <Col sm="4">
                    <label>Associated Branch</label>
                </Col>
                <Col sm="8">
                  <MySelect 
                    modal={true}
                    type="select"
                    value={state.branchFk}
                    onChange={(v)=>setState({branchFk:v})}
                    options={state.clientBranches.map((branch)=>{
                      return {label:branch.value, value:branch.key};
                    })}
                  />
                </Col>
              </Row>
              <Row className="margin-bottom">
                <Col sm="4">
                  <label>First Name</label>
                </Col>
                <Col sm="8">
                  <Input type="text" required="true" value={state.firstName} onChange={(e)=>setState({firstName:e.target.value})}/>
                </Col>
              </Row>
              <Row className="margin-bottom">
                <Col sm="4">
                  <label>Last Name</label>
                </Col>
                <Col sm="8">
                  <Input type="text" required="true" value={state.lastName} onChange={(e)=>setState({lastName:e.target.value})}/>
                </Col>
              </Row>
              <Row className="margin-bottom">
                <Col sm="4">
                  <label>Email</label>
                </Col>
                <Col sm="8">
                  <Input type="text" required="true" value={state.email} onChange={(e)=>setState({email:e.target.value})}/>
                </Col>
              </Row>
              <Row className="margin-bottom">
                <Col sm="4">
                  <label>Phone</label>
                </Col>
                <Col sm="8">
                  <Input type="text" value={state.phone} onChange={(e)=>setState({phone:e.target.value})}/>
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  <label>Note</label>
                </Col>
                <Col sm="8">
                  <Input type="text" value={state.note} onChange={(e)=>setState({note:e.target.value})}/>
                </Col>
              </Row>

              <br/>
              <div className="align-right">
                <Button color="warning" onClick={(e)=>createProfile('ae')}>Submit</Button>
              </div>
            </form>
          </CardBody>
        </Card>
      </ModalBody>
    </Modal>

    <Modal className="my-modal" isOpen={state.newLoanOfficerDropdownOpen} toggle={toggleNewLoanOfficerDropdown} >
      <ModalHeader hidden={true} toggle={toggleNewLoanOfficerDropdown}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-cogs"></i> New Loan Officer Association</h5>
        </center>
        <Card>
          <CardHeader className="header-color">
            <i className="fa fa-info"></i> Loan Officer
          </CardHeader>
          <CardBody>
            <form onSubmit={createProfile}>
              <Row className="margin-bottom">
                <Col sm="4">
                  <label>Associated Branch</label>
                </Col>
                <Col sm="8">
                  <MySelect
                    modal={true}
                    type="select"
                    value={state.branchFk}
                    onChange={(v)=>setState({branchFk:v})}
                    options={state.clientBranches.map((branch)=>{
                      return {label:branch.value, value:branch.key};
                    })}
                  />
                </Col>
              </Row>
              <Row className="margin-bottom">
                <Col sm="4">
                  <label>First Name</label>
                </Col>
                <Col sm="8">
                  <Input type="text" required="true" value={state.firstName} onChange={(e)=>setState({firstName:e.target.value})}/>
                </Col>
              </Row>
              <Row className="margin-bottom">
                <Col sm="4">
                  <label>Last Name</label>
                </Col>
                <Col sm="8">
                  <Input type="text" required="true" value={state.lastName} onChange={(e)=>setState({lastName:e.target.value})}/>
                </Col>
              </Row>
              <Row className="margin-bottom">
                <Col sm="4">
                  <label>Email</label>
                </Col>
                <Col sm="8">
                  <Input type="text" required="true" value={state.email} onChange={(e)=>setState({email:e.target.value})}/>
                </Col>
              </Row>
              <Row className="margin-bottom">
                <Col sm="4">
                  <label>Phone</label>
                </Col>
                <Col sm="8">
                  <Input type="text" value={state.phone} onChange={(e)=>setState({phone:e.target.value})}/>
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  <label>Note</label>
                </Col>
                <Col sm="8">
                  <Input type="text" value={state.note} onChange={(e)=>setState({note:e.target.value})}/>
                </Col>
              </Row>

              <br/>
              <div className="align-right">
                <Button color="warning" onClick={(e)=>createProfile('loanOfficer')}>Submit</Button>
              </div>
            </form>
          </CardBody>
        </Card>
      </ModalBody>
    </Modal>

    <Modal className="my-modal" isOpen={state.newProcessorDropdownOpen} toggle={toggleNewProcessorDropdown} >
      <ModalHeader hidden={true} toggle={toggleNewProcessorDropdown}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-cogs"></i> New Loan Processor Association</h5>
        </center>
        <Card>
          <CardHeader className="header-color">
            <i className="fa fa-info"></i> Loan Processor
          </CardHeader>
          <CardBody>
            <form onSubmit={createProfile}>
              <Row className="margin-bottom">
                <Col sm="4">
                    <label>Associated Branch</label>
                </Col>
                <Col sm="8">
                  <MySelect
                    modal={true}
                    type="select"
                    value={state.branchFk}
                    onChange={(v)=>setState({branchFk:v})}
                    options={state.clientBranches.map((branch)=>{
                      return {label:branch.value, value:branch.key};
                    })}
                  />
                </Col>
              </Row>
              <Row className="margin-bottom">
                <Col sm="4">
                  <label>First Name</label>
                </Col>
                <Col sm="8">
                  <Input type="text" required="true" value={state.firstName} onChange={(e)=>setState({firstName:e.target.value})}/>
                </Col>
              </Row>
              <Row className="margin-bottom">
                <Col sm="4">
                  <label>Last Name</label>
                </Col>
                <Col sm="8">
                  <Input type="text" required="true" value={state.lastName} onChange={(e)=>setState({lastName:e.target.value})}/>
                </Col>
              </Row>
              <Row className="margin-bottom">
                <Col sm="4">
                  <label>Email</label>
                </Col>
                <Col sm="8">
                  <Input type="text" required="true" value={state.email} onChange={(e)=>setState({email:e.target.value})}/>
                </Col>
              </Row>
              <Row className="margin-bottom">
                <Col sm="4">
                  <label>Phone</label>
                </Col>
                <Col sm="8">
                  <Input type="text" value={state.phone} onChange={(e)=>setState({phone:e.target.value})}/>
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  <label>Note</label>
                </Col>
                <Col sm="8">
                  <Input type="text" value={state.note} onChange={(e)=>setState({note:e.target.value})}/>
                </Col>
              </Row>

              <br/>
              <div className="align-right">
                <Button color="warning" onClick={(e)=>createProfile('processor')}>Submit</Button>
              </div>
            </form>
          </CardBody>
        </Card>
      </ModalBody>
    </Modal>

    <Row>
      <Col sm="8">
        <div className="page-title">
          <i className="fa fa-reorder"></i>&nbsp;Relationship Profile - {state.profile.company}
        </div>
        <NavLink to={"/client/"+state.ID+'/keyword='}>Back to company profile</NavLink>
      </Col>
      <Col sm="4">

      </Col>
    </Row>


    <div className="hr"/>
    <br/>
    <Nav tabs>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.tab === '1'?"active":"inactive" )}
          to="#"
          onClick={() => { activeTabToggle('1'); }}>Broker Associated</NavLinkL>
      </NavItem>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.tab === '2'?"active":"inactive" )}
          to="#"
          onClick={() => { activeTabToggle('2'); }}>AE Associated</NavLinkL>
      </NavItem>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.tab === '3'?"active":"inactive" )}
          to="#"
          onClick={() => { activeTabToggle('3'); }}>Loan Officer Associated</NavLinkL>
      </NavItem>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.tab === '4'?"active":"inactive" )}
          to="#"
          onClick={() => { activeTabToggle('4'); }}>Loan Processor Associated</NavLinkL>
      </NavItem>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.tab === '5'?"active":"inactive" )}
          to="#"
          onClick={() => { activeTabToggle('5'); }}>Appraiser Associated</NavLinkL>
      </NavItem>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.tab === '6'?"active":"inactive" )}
          to="#"
          onClick={() => { activeTabToggle('6'); }}>Client Associated</NavLinkL>
      </NavItem>
    </Nav>

    <TabContent activeTab={state.tab}>
      <TabPane tabId="1">
        <div className="align-right"><NavLink to="#" onClick={()=>toggleNewBrokerDropdown()}>Associate new Broker</NavLink></div>
        <Card>
          <CardHeader className="header-color">
            <i className="fa fa-user"></i> Broker
          </CardHeader>
          <CardBody>
            <Table className="table table-striped">
              <tbody>
                {
                  state.profile.brokers.map(
                    (broker, index)=>{
                      return <tr key={index}><td><i className="fa fa-times red-color cursor-pointer" onClick={
                        ()=>{
                          confirmation(
                            ()=>{deleteProfile('broker',broker.ID)},
                            ()=>{},
                            'Delete broker?',
                            'Are you sure you want to delete this broker from the profile?');
                        }
                      }></i> <NavLink to={"/broker-relationship/broker/"+broker.ID}>{broker.name}</NavLink></td></tr>;
                    }
                  )
                }
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </TabPane>
      <TabPane tabId="2">
        <div className="align-right"><NavLink to="#" onClick={()=>toggleNewAEDropdown()}>Associate new AE</NavLink></div>
        <Card>
          <CardHeader className="header-color">
            <i className="fa fa-user"></i> AE
          </CardHeader>
          <CardBody>
            <Table className="table table-striped">
              <tbody>
                {
                  state.profile.aes.map(
                    (ae, index)=>{
                      return <tr key={index}><td>
                        <div className="align-right">
                          <NavLink to="#" onClick={(e)=>toggleNewCSRDropdown(ae.ID)}><i className="fa fa-plus green-color cursor-pointer"></i> CSR</NavLink>
                        </div>
                        <i className="fa fa-times red-color cursor-pointer" onClick={
                          ()=>{
                            confirmation(
                              ()=>{deleteProfile('ae',ae.ID)},
                              ()=>{},
                              'Delete AE?',
                              'Are you sure you want to delete this AE the profile?');
                          }
                        }></i> <NavLink to={"/broker-relationship/ae/"+ae.ID}>{ae.name}</NavLink>
                        {
                          ae.csrs.map(
                            (csr, index2)=>{
                              return <div className="my-well" key={index2}>
                                <i className="fa fa-times red-color cursor-pointer" onClick={
                                  ()=>{
                                    confirmation(
                                      ()=>{deleteProfile('csr',csr.ID)},
                                      ()=>{},
                                      'Delete CSR?',
                                      'Are you sure you want to delete this CSR the profile?');
                                  }
                                }></i> <NavLink to={"/broker-relationship/csr/"+csr.ID}>{csr.name} - {csr.email}</NavLink>
                              </div>
                            }
                          )
                        }
                      </td></tr>;
                    }
                  )
                }
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </TabPane>
      <TabPane tabId="3">
        <div className="align-right"><NavLink to="#" onClick={()=>toggleNewLoanOfficerDropdown()}>Associate new Loan Officer</NavLink></div>
        <Card>
          <CardHeader className="header-color">
            <i className="fa fa-user"></i> Loan Officer
          </CardHeader>
          <CardBody>
            <Table className="table table-striped">
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>Name</th>
                  <th>Branch</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {
                  state.profile.loan_officers.map(
                    (loanOfficer, index)=>{
                      return <tr key={index}><td><i className="fa fa-times red-color cursor-pointer" onClick={
                        ()=>{
                          confirmation(
                            ()=>{deleteProfile('loanOfficer',loanOfficer.ID)},
                            ()=>{},
                            'Delete loan officer?',
                            'Are you sure you want to delete this loan officer from the profile?');
                        }
                      }></i></td>
                      <td><NavLink to={"/broker-relationship/loan-officer/"+loanOfficer.ID}>{loanOfficer.name}</NavLink></td>
                      <td>{loanOfficer.branch_name}</td>
                      <td>{loanOfficer.status}</td>
                    </tr>;
                    }
                  )
                }
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </TabPane>
      <TabPane tabId="4">
        <div className="align-right"><NavLink to="#" onClick={()=>toggleNewProcessorDropdown()}>Associate new Loan Processor</NavLink></div>
        <Card>
          <CardHeader className="header-color">
            <i className="fa fa-user"></i> Loan Processor
          </CardHeader>
          <CardBody>
            <Table className="table table-striped">
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>Name</th>
                  <th>Branch</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {
                  state.profile.processors.map(
                    (processor, index)=>{
                      return <tr key={index}><td><i className="fa fa-times red-color cursor-pointer" onClick={
                        ()=>{
                          confirmation(
                            ()=>{deleteProfile('processor',processor.ID)},
                            ()=>{},
                            'Delete loan processor?',
                            'Are you sure you want to delete this loan processor from the profile?');
                        }
                      }></i></td>
                      <td><NavLink to={"/broker-relationship/processor/"+processor.ID}>{processor.name}</NavLink></td>
                      <td>{processor.branch_name}</td>
                      <td>{processor.status}</td>
                    </tr>;
                    }
                  )
                }
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </TabPane>
      <TabPane tabId="5">
        <div className="align-right"><NavLink to="#" onClick={()=>toggleNewAppraiserDropdown()}>Associate new Appraiser</NavLink></div>
        <Card>
          <CardHeader className="header-color">
            <i className="fa fa-user"></i> Appraiser
          </CardHeader>
          <CardBody>
            <Table className="table table-striped">
              <tbody>
                <tr>
                  <th width="8%">Action</th>
                  <th width="12%">Auto Assign</th>
                  <th>Name</th>
                </tr>
                {
                  state.profile.appraisers.map(
                    (appraiser, index)=>{
                      return <tr key={index}><td><i className="fa fa-times red-color cursor-pointer" onClick={
                        ()=>{
                          confirmation(
                            ()=>{deleteProfile('appraiser',appraiser.ID)},
                            ()=>{},
                            'Delete appraiser?',
                            'Are you sure you want to delete this appraiser from the profile?');
                        }
                      }></i>
                    </td><td>
                      <div className="display-inline" style={{width:'200px'}}>
                        <Input type="checkbox" checked={appraiser.auto_assign==='yes'} onClick={(e)=>{updateAutoAssign(appraiser.ID, appraiser.auto_assign)}}/> Enroll
                      </div>
                    </td>
                      <td><NavLink to={"/broker-relationship/appraiser/"+appraiser.ID}>{appraiser.name}</NavLink></td></tr>;
                    }
                  )
                }
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </TabPane>
      <TabPane tabId="6">
        <label>Associate new client</label>
        <Row>
          <Col sm="8">
            <MySelect
              type="select"
              value={state.client}
              onChange={(v)=>setState({client:v})}
              options={state.clients.map((client)=>{
                return {label:client.value, value:client.key};
              })}
            />
          </Col>
          <Col sm="4" className="align-right">
            <Button color="warning" onClick={(e)=>addNewClient()}>Associate</Button>
          </Col>
        </Row>
        <br/>
        <Card>
          <CardHeader className="header-color">
            <i className="fa fa-user"></i> Client
          </CardHeader>
          <CardBody>
            <div className="small-scroll-container-ex-large">
              <Table className="table table-striped">
                <tbody>
                  {
                    state.profile.clients.map(
                      (client, index)=>{
                        return <tr key={index}><td><i className="fa fa-times red-color cursor-pointer" onClick={
                          ()=>{
                            confirmation(
                              ()=>{deleteProfile('client',client.ID)},
                              ()=>{},
                              'Delete client?',
                              'Are you sure you want to delete this client from the profile?');
                          }
                        }></i> {client.company}</td></tr>;
                      }
                    )
                  }
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
      </TabPane>
    </TabContent>
  </div>;
}


export default BrokerRelationshipProfiles;
