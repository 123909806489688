//Author Sooyoung Kim
//Date Dec 4, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, deleteAPICallGenerator, callBackGenerator,formatDate} from '../../util/util';
import {Col, Row, Table, Card, CardBody, CardHeader} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import { useParams } from "react-router-dom";
import {NavLink} from 'react-router-dom';
import Toggle from 'react-toggle';

//initialize the state
const initialState = {
  channel:{},
};

//reducer function that perform state update
const reducer = getReducer();


const NotificationChannel  = (props)=>{
  const controller = new AbortController();
  let { id } = useParams();
  let newInitialState = Object.assign({}, initialState, {id:id});
  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getChannel();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const channelHandleChange = () => {
    if(state.channel.subscribed==='yes')
      unsubscribeChannel();
    else
      subscribeChannel();
  }

  //API call
  const subscribeChannel = () => {
    let parameters = [
      {
        field:'ID',
        value:state.id
      }
    ];

    let channel = Object.assign({},state.channel,{subscribed:'yes'});
    let callBack = apiCallBack([{state:'channel', value:channel}]);
    httpPost('notification/channel/subscribe', parameters, 'Channel subscribe successfully.', 'Oops, something went wrong and could not subscribe to the channel. Please try again later.', callBack);
  }

  const unsubscribeChannel = () => {
    let channel = Object.assign({},state.channel,{subscribed:'no'});
    let callBack = apiCallBack([{state:'channel', value:channel}]);
    httpDelete('notification/channel/unsubscribe/'+state.id,'Channel unsubscribed successfully.','Oops, something went wrong and could not unsubscribe the channel. Please try again later.', callBack);
  }

  const getChannel = () => {
    let callBack = apiCallBack([{state:'channel', key:'data'}]);
    httpGet('notification/channel/'+state.id, '', 'Oops, something went wrong and could not load this notification channel. Please try again later.', callBack);
  }

  //render
  let events;

  if(state.channel.events){
    events = state.channel.events.map(
      (event,index)=>{
        return(
          <tr key={index}>
            <td>
              <Row>
                <Col sm="8">
                  <b>{event.name}</b>
                </Col>
                <Col sm="4" className="align-right">
                  {formatDate(event.datetime_created)}
                </Col>
              </Row>
              {event.description}
            </td>
          </tr>
        );
      }
    )
  }
    
  return <div className="my-well">
    <Row>
      <Col sm="6">
        <div className="page-title">
          <i className="fa fa-reorder"></i>&nbsp;Notification Channel
        </div>
        <NavLink to="/setting">Back to settings</NavLink>
      </Col>
      <Col sm="6">

      </Col>
    </Row>
    <Card>
      <CardHeader className="header-color">
        <Row>
          <Col sm="8">
            <i className="fa fa-bell"></i>&nbsp;{state.channel.name}
          </Col>
          <Col sm="4" className="align-right">
            <Toggle
              checked={state.channel.subscribed==='yes'}
              icons={false}
              onChange={()=>channelHandleChange()} />
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Table className="table table-striped">
          <tbody>
            {events}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  </div>;
}


export default NotificationChannel;
