//Author June Leow
//Date 04/17/2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, callBackGenerator, formatDateTime} from '../../util/util';
import {Button, Card, CardHeader, CardBody, Input, Row, Col} from 'reactstrap';
import FileSaver from 'file-saver';
import MyXEditable from '../util/my-xeditable';
import {NavLink} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';
import { useParams } from "react-router-dom";

//initialize the state
const initialState = {
  ID:-1,
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
  ticket:{
    files:[],
    comments:[]
  },
  comment:'',
  categories:[],
  criticalLevels:[{key:'Minor',value:'Minor'},{key:'Major',value:'Major'},{key:'Critical',value:'Critical'}],
  statuses:[{key:'Pending',value:'Pending'},{key:'Resolved',value:'Resolved'},{key:'Closed',value:'Closed'}],
};

//reducer function that perform state update
const reducer = getReducer();


const AccountingTicket  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);
  state.ID = useParams().id;

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getTicket(state.ID)
    getTicketCategory();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const updateFieldCallBack = (field, value)=>{
    console.log(field, value);
    let ticket = Object.assign({}, state.ticket);
    ticket[field] = value;
    setState({ticket:ticket});
  }

  //API call
  const getTicket = (id)=>{
    let url = 'ticket/accounting/'+id;

    //generate a generic call back that will update state
    let callBack = apiCallBack([{state:'ticket',key:'data'}]);

    httpGet(url, '','Oops, something went wrong and could not load the ticket. Please try again later.', callBack);
  }

  const getTicketCategory = ()=>{
    let url = 'ticket/category';

    //generate a generic call back that will update state
    let callBack = apiCallBack([{state:'categories',key:'data'}]);

    httpGet(url, '','Oops, something went wrong and could not load ticket category. Please try again later.', callBack);
  }

  const createTicketComment = ()=>{
    let url = 'ticket/accounting/comment/create';
    let parameters = [
      {
        field:'ticketID',
        value:state.ID
      },
      {
        field:'comment',
        value:state.comment
      },
    ];

    let callBack = (response)=>{
      let code= response.data.code;

      if(code!=='00'){

      }
      else{
        let data = response.data.data;
        let ticket = Object.assign({}, state.ticket);

        let comments = ticket.comments.slice();


        comments.push(data);
        ticket.comments = comments;

        setState({comment:'', ticket:ticket});
      }
    };

    httpPost(url, parameters, 'Ticket comment submitted successfully.','Oops, something went wrong and could not submit the support ticket comment. Please try again later.', callBack);
  }

  const downloadFile = (name)=>{
    let url = 'ticket/accounting/download';
    let parameters = [
      {
        field:'supportTicketFk',
        value:state.ID
      },
      {
        field:'name',
        value:name
      }
    ];

    let callBack = (response)=>{
      console.log(response);
      let code = response.data?response.data.code:undefined;

      if(code!=='00'){

      }
      else{
        let byteCharacters = atob(response.data.data);
  			let byteNumbers = new Array(byteCharacters.length);
  			for (let i = 0; i < byteCharacters.length; i++) {
  				byteNumbers[i] = byteCharacters.charCodeAt(i);
  			}
  			let byteArray = new Uint8Array(byteNumbers);
  			let data = new Blob([byteArray]);
  			FileSaver.saveAs(data, name);
      }
    };

    httpPost(url, parameters, '','Oops, something went wrong and could not download the file "'+name+'". Please try again later.', callBack);
  }

  const generateUpdateFunction = (url, parameters, successMessage, failedMessage, parentCallBack=null)=>{

    return (newValue, callBack, errorCallBack)=>{
      //add in the new value into parameters

      parameters.push({field:'value',value:newValue});
      let promise = httpPut(url,parameters,successMessage,failedMessage, callBack, errorCallBack);
      promise.then(
        (response)=>{
          let field;
          let value;

          for(let i=0;i<parameters.length;i++){
            if(parameters[i].field==='field')
              field = parameters[i].value;
            else if(parameters[i].field==='value')
              value = parameters[i].value;
          }
          if(parentCallBack!==null){
            parentCallBack(field, value);
          }
        }
      );
    }
  };

  //render
  let successMessage = 'Ticket status updated.';
  let failedMessage = 'Something went wrong and failed to update the ticket status, please try again later.';

  return <div className="my-well">
        <Row>
          <Col sm="8">
            <div className="page-title">
              <i className="fa fa-reorder"></i>&nbsp;Help & Support - Ticket
            </div>
            <NavLink to="/support/accounting/ticket">Back to accounting support ticket queue</NavLink>
          </Col>
          <Col sm="4">

          </Col>
        </Row>
        <div className="hr"/>
        <br/><br/>
        <Row>
          <Col sm="2">

          </Col>
          <Col sm="8">
            <Card>
              <CardHeader className="header-color">
                <i className="fa fa-info"></i> Accounting Ticket
              </CardHeader>
              <CardBody>
                <Row className="margin-bottom">
                  <Col sm="4">
                    <label>Reported</label>
                  </Col>
                  <Col sm="8">
                    {formatDateTime(state.ticket.datetime_created)}
                  </Col>
                </Row>
                <Row className="margin-bottom">
                  <Col sm="4">
                    <label>Reporter</label>
                  </Col>
                  <Col sm="8">
                    {state.ticket.author}
                  </Col>
                </Row>
                <Row className="margin-bottom">
                  <Col sm="4">
                    <label>Reporter Email</label>
                  </Col>
                  <Col sm="8">
                    {state.ticket.author_email}
                  </Col>
                </Row>
                <Row className="margin-bottom">
                  <Col sm="4">
                    <label>Status</label>
                  </Col>
                  <Col sm="8">
                    <MyXEditable
                      type="select"
                      opt={state.statuses}
                      value={state.ticket.status}
                      updateFunc={
                        generateUpdateFunction('ticket/accounting/update',[{field:'field',value:'status'},{field:'ID',value:state.ID}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row className="margin-bottom">
                  <Col sm="4">
                    <label>Issue</label>
                  </Col>
                  <Col sm="8">
                    {state.ticket.issue}
                  </Col>
                </Row>
                <Row className="margin-bottom">
                  <Col sm="4">
                    <label>Description</label>
                  </Col>
                  <Col sm="8">
                    <Input type="textarea" rows="10" style={{resize:'none'}} disabled value={state.ticket.description}/>
                  </Col>
                </Row>
                <Row className="margin-bottom">
                  <Col sm="4">
                    <label>Category</label>
                  </Col>
                  <Col sm="8">
                    <MyXEditable
                      type="select"
                      opt={state.categories.map((category)=>{return {key:category.category, value:category.category};})}
                      value={state.ticket.category}
                      updateFunc={
                        generateUpdateFunction('ticket/accounting/update',[{field:'field',value:'category'},{field:'ID',value:state.ID}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row className="margin-bottom">
                  <Col sm="4">
                    <label>Critical Level</label>
                  </Col>
                  <Col sm="8">
                    <MyXEditable
                      type="select"
                      opt={state.criticalLevels}
                      value={state.ticket.critical_level}
                      updateFunc={
                        generateUpdateFunction('ticket/accounting/update',[{field:'field',value:'critical_level'},{field:'ID',value:state.ID}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <br/><br/>
                <label>Attachment(s)</label><br/>
                <div>
                  {
                    state.ticket.files.map(
                      (file, index)=>{
                        return <tr>
                          <td width="85%"><NavLink to="#" onClick={(e)=>downloadFile(file.name)}>{file.label_name}</NavLink></td>
                          <td className="align-right">{formatDateTime(file.datetime_created)}</td>
                        </tr>
                      }
                    )
                  }
                </div>
                <br/>
                <label>Comment(s)</label><br/>
                <div className="large-scroll-container comments-panel">
                  {
                    state.ticket.comments.map(
                      (comment,outterIndex)=>{

                        if(comment.author!==state.ticket.author){

                          return (
                            <Row className="align-right" key={comment.ID}>
                              <Col sm="12">
                                <div className="admin-comment-container">
                                  <div className="admin-name">{comment.author}</div>
                                  {comment.comment}
                                  <br/>
                                  <Row>
                                    <Col sm="6">
                                      <div className="margin-top comment-date display-inline">{formatDateTime(comment.datetime_created)}&nbsp;&nbsp;</div>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                          );
                        }
                        else{
                          return(
                            <Row key={comment.ID}>
                              <Col sm="12">
                                <div className="nonadmin-comment-container">
                                  <div className="admin-name">{comment.author}</div>
                                  {comment.comment}
                                  <br/>
                                  <Row>
                                    <Col sm="6">
                                      <div className="margin-top comment-date display-inline">{formatDateTime(comment.datetime_created)}&nbsp;&nbsp;</div>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                          );
                        }
                      }
                    )
                  }
                </div>
                <br/>
                <label>New Comment</label><br/>
                <Row>
                  <Col sm="10">
                    <Input type="textarea" className="form-control comment-textarea" value={state.newComment} id="comment" placeholder="Say something..." style={{resize:'none'}} rows="1" onChange={(e)=>setState({comment:e.target.value})}></Input>
                  </Col>
                  <Col sm="2" className="align-right">
                    <Button color="warning" onClick={createTicketComment} ><i className="fa fa-check"></i>&nbsp;Post</Button>{' '}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col sm="2">

          </Col>
        </Row>
      </div>;
}

export default AccountingTicket;
