//Author Sooyoung Kim
//Date July 1st, 2022
import {formatDate, formatNumber, formatDateTime, confirmation, getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, deleteAPICallGenerator, callBackGenerator, sliceFromArray} from '../../util/util';
import {Input, Button, Row, Col, Table, Modal, ModalHeader, ModalBody, Nav, NavItem, TabPane, TabContent, NavLink as NavLinkL} from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import {NavLink} from 'react-router-dom';
import AppraiserSearch from './appraiser-search';
import React, {useReducer, useEffect} from 'react';
import MySelect from '../util/my-select';


//initialize the state
const initialState = {
  appraisers:[],
  limit: 25,
  offset: 0,
  hasMoreAppraisers: true,
  loading: false,
  sort: 'first_name',
  order: 'ASC',
  status: '',
  statuses: [],
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
  totalCount:0,

  pendingRecommendations:[],

  //for search
  name:'',
  company_name:'',
  email:'',
  state:'',
  county:'',
  city:'',

  modal:false,
  modal2:false,
  selectedRecommendation:{},
  activeTab:'1'
};

//reducer function that perform state update
const reducer = getReducer();


const AppraiserPresidentClub  = (props)=>{
  const controller = new AbortController();
  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getAllAppraiserStatuses();
    getPendingRecommendations();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    if(state.appraisers.length<=0 && state.hasMoreAppraisers){
        loadMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state]);

  //non API call but simpyl manage state
  const selectedRecommendation = (recommendation)=>{
    setState({selectedRecommendation:recommendation});
    toggleModal2();
  }

  const toggleModal = ()=>{
    setState({modal:!state.modal});
  }

  const toggleModal2 = ()=>{
    setState({modal2:!state.modal2});
  }

  const activeTabToggle = (tab)=>{
    setState({activeTab:tab});
  }

  //click handler that switch the state of sorting order then call the function to refresh the list
  //to achieve the "sorting"
  const columnClickHandler = (col)=>{
    if(state.sort===col){
      if(state.order==='ASC')
        setState({order:'DESC'});
      else
        setState({order:'ASC'});
    }
    else
      setState({sort:col,order:'ASC'});
    refreshList();
  }

  //this function refresh the list of orders pipeline
  const refreshList = ()=>{
    setState({
      appraisers:[],
      totalCount:0,
      hasMoreAppraisers:true,
      offset:0
    });
  }


  //this function render the sort icon at each column of the table
  const renderSortIcon = (col)=>{
    if(state.sort===col){
      if(state.order==='ASC')
        return <i className="red-color fa fa-arrow-down"></i>
      else
        return <i className="red-color fa fa-arrow-up"></i>
    }
  }

  //render function for infinite scroller
  const renderAppraisers = ()=>{
    return (
      <div>
        <table className="primary-table">
          <thead>
            <tr>
              <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('first_name')}><i className="fa fa-user"></i>&nbsp;First Name {renderSortIcon('first_name')}</th>
              <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('last_name')}><i className="fa fa-user"></i>&nbsp;Last Name {renderSortIcon('last_name')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('status')}><i className="fa fa-info"></i>&nbsp;Status {renderSortIcon('status')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('state')}><i className="fa fa-map-marker"></i>&nbsp;State {renderSortIcon('state')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('county')}><i className="fa fa-map-marker"></i>&nbsp;County {renderSortIcon('county')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('city')}><i className="fa fa-map-marker"></i>&nbsp;City {renderSortIcon('city')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('email')}><i className="fa fa-envelope-open"></i>&nbsp;Email {renderSortIcon('email')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('phone')}><i className="fa fa-phone"></i>&nbsp;Phone {renderSortIcon('phone')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('datetime_created')}><i className="fa fa-calendar-o"></i>&nbsp;Date Created {renderSortIcon('datetime_created')}</th>
            </tr>
          </thead>
          <tbody>
          {
            state.appraisers.map(
              (appraiser)=>{
                return (
                  <tr key={appraiser.ID}>
                    <td><i className="fa fa-times red-color cursor-pointer" onClick={
                      ()=>{
                        confirmation(
                          ()=>{deletePresidentClub(appraiser.clubID)},
                          ()=>{},
                          'Delete appraiser from president club?',
                          'Are you sure you want to delete this appraiser profile from the president club?');
                      }
                    }></i> {appraiser.first_name}</td>
                    <td>{appraiser.last_name}</td>
                    <td>{appraiser.status}</td>
                    <td>{appraiser.state}</td>
                    <td>{appraiser.county}</td>
                    <td>{appraiser.city}</td>
                    <td>{appraiser.email}</td>
                    <td>{appraiser.phone}</td>
                    <td>{formatDate(appraiser.datetime_created)}</td>
                  </tr>
                );
              }
            )
          }
          </tbody>
        </table>
      </div>
    )
  }


  //API call
  const getPendingRecommendations = ()=>{
    let url = 'appraiser/presidentClub/pending/get';
    let callBack = apiCallBack([{state:'pendingRecommendations', key:'data'}]);
    httpGet(url, '','Oops, something went wrong and could not load pending recommendations. Please try again later.', callBack);
  }

  //function that fire when the infinite scroll reach bottom
  const loadMore = ()=>{
    //do not load if there is no more conditions or it's loading data
    if(state.hasMoreAppraisers&&!state.loading){
      //set loading equals to true so it won't fire off before we are done
      setState({loading:true});

      let company_name = encodeURIComponent(state.company_name.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
      let name = encodeURIComponent(state.name.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
      let email = encodeURIComponent(state.email.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
      let city = encodeURIComponent(state.city.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
      let county = encodeURIComponent(state.county.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));

      let url = 'appraiser/presidentClub/get/limit='+state.limit+'&offset='+state.offset+'&order='+state.order+'&sort='+state.sort+'&status='+state.status+'&name='+name+'&company_name='+company_name+'&email='+email+'&state='+state.state+'&county='+county+'&city='+city;

      //callback handler that update the state when http request return
      let callBack = (response)=>{
        let code = response.data?response.data.code:undefined;

        if(code!=='00'){
          setState({hasMoreAppraisers:false});
        }
        else{
          let newAppraiser = response.data.data;
          let hasMoreAppraisers = true;
          let newOffset = state.offset;
          let totalCount = response.data.count;

          //if http request return empty then no more results, end of list
          if(newAppraiser.length<=0){
            hasMoreAppraisers = false;
          }
          else{
            //increment the offset
            newOffset = state.offset + 1;
          }

          //concat the current array of announcement
          if(state.appraisers.length>0){
            let temp = [...state.appraisers,...newAppraiser];

            setState({appraisers:temp});
          }
          else
            setState({appraisers:newAppraiser});

          setState({hasMoreAppraisers:hasMoreAppraisers, offset:newOffset, totalCount:totalCount});
        }
      };
      callBack = callBack.bind(this);

      //error handler when the http request return with error
      let errorCallBack = apiCallBack([{state:'hasMoreAppraisers', value:false}]);

      //collect the promise and wait for it to finish performing it's task
      let promises = httpGet(url, '','Oops, something went wrong and could not load appraisers. Please try again later.', callBack, errorCallBack);
      promises
        .then(
          function(result){
            //set loading equals to false so the function could be fire off once again
            setState({loading:false});
          }
        );
    }
  }

  const approval = (approval)=>{
    let url = 'appraiser/presidentClub/approval';

    let callBack = (response)=>{console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code!=='00'){

      }
      else{
        let selectedRecommendation = Object.assign({},state.selectedRecommendation);
        selectedRecommendation.recommendation_status = approval;

        if(approval==='approved'){
          let pendingRecommendations = state.pendingRecommendations.slice();
          let appraisers = state.appraisers.slice();
          for(let i=0;i<pendingRecommendations.length;i++){
            if(pendingRecommendations[i].clubID===state.selectedRecommendation.clubID){
              pendingRecommendations.splice(i,1);
              appraisers.push(state.selectedRecommendation);
              break;
            }
          }
          setState({pendingRecommendations:pendingRecommendations,appraisers:appraisers,totalCount:parseInt(state.totalCount)+1});
        }
        else{
          let pendingRecommendations = state.pendingRecommendations.slice();
          for(let i=0;i<pendingRecommendations.length;i++){
            if(pendingRecommendations[i].ID===state.selectedRecommendation.ID){
              pendingRecommendations[i].recommendation_status = approval;
              break;
            }
          }
          setState({pendingRecommendations:pendingRecommendations});
        }

        setState({selectedRecommendation:selectedRecommendation});
      }
    };
    callBack = callBack.bind(this);

    let parameters =[
      {
        field:'ID',
        value:state.selectedRecommendation.clubID
      },
      {
        field:'approval',
        value:approval
      }
    ];

    httpPost(url, parameters, 'Appraiser recommendation status updated.','Oops, something went wrong and could not update the recommendation status. Please try again later.', callBack);
  }

  const recommendAppraiser = (ID, comment)=>{
    let url = 'appraiser/presidentClub/recommend';

    let callBack = (response)=>{console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code!=='00'){

      }
      else{
        let pendingRecommendations = state.pendingRecommendations.slice();

        pendingRecommendations.push(response.data.data);

        setState({pendingRecommendations:pendingRecommendations});
      }
    };
    callBack = callBack.bind(this);

    let parameters =[
      {
        field:'appraiserFk',
        value:ID
      },
      {
        field:'comment',
        value:comment
      }
    ];

    httpPost(url, parameters, 'Appraiser recommended for president club.','Oops, something went wrong and could not recommend the appraiser. Please try again later.', callBack);
  }

  //get a list of appraiser status for the drop down
  const getAllAppraiserStatuses = ()=>{
    let url = 'appraiser/statuses/get';
    let callBack = apiCallBack([{state:'statuses', key:'data'}]);
    httpGet(url, '','Oops, something went wrong and could not retrieve appraiser statuses.', callBack);
  }

  const deletePresidentClub = (id)=>{
    let url = 'appraiser/presidentClub/'+id;
    let list = sliceFromArray(state.appraisers,'clubID', id);
    let callBack = apiCallBack([{state:'appraisers', value:list},{state:'totalCount', value:parseInt(state.totalCount)-1}]);
    httpDelete(url,'Removed from president club.','Oops, something went wrong and could not delete this appraiser profile from president club. Please try again later.', callBack);
  }

  //render
  return <div>
    <InfiniteScroll
      next={loadMore}
      dataLength={state.appraisers.length}
      hasMore={state.hasMoreAppraisers}
      loader={<div key="nill" className="loader"><center>Loading more appraisers...</center></div>}
      initialLoad = {true}
      className="my-well"
      scrollableTarget="contentContainer"
    >

      <AppraiserSearch recommend={recommendAppraiser} modalState={state.modal} toggleModalCallBack={toggleModal} showLoading={props.showLoading} logout={props.logout} hideLoading={props.hideLoading}/>

      <Modal className="my-modal-wide" isOpen={state.modal2} toggle={toggleModal2} >
        <ModalHeader hidden={true} toggle={toggleModal2}></ModalHeader>
        <ModalBody>
          <center>
            <h5><i className="fa fa-cogs"></i> Recommendation approval</h5>
          </center>
          <br/>
          <Row>
            <Col sm="4">
              <label>Name</label>
            </Col>
            <Col sm="8">
              {state.selectedRecommendation.first_name+' '+state.selectedRecommendation.last_name}
            </Col>
          </Row>
          <Row>
            <Col sm="4">
              <label>Email</label>
            </Col>
            <Col sm="8">
              {state.selectedRecommendation.email}
            </Col>
          </Row>
          <Row>
            <Col sm="4">
              <label>Address</label>
            </Col>
            <Col sm="8">
              {state.selectedRecommendation.street+' '+state.selectedRecommendation.city+', '+state.selectedRecommendation.state+' '+state.selectedRecommendation.zip}
            </Col>
          </Row>
          <br/>
          <Row>
            <Col sm="4">
              <label>Status</label>
            </Col>
            <Col sm="8">
              <MySelect
                modal={true}
                type="select"
                selectIsClearable={true}
                value={state.selectedRecommendation.recommendation_status}
                onChange={(v)=>approval(v)}
                options={[{label:"Pending",value:"pending"},{label:"Declined",value:"declined"},{label:"Approved",value:"approved"}]}
              />
            </Col>
          </Row>
          <br/>
          <center>
            <Button color="info" onClick={toggleModal2}>Close</Button>
          </center>
        </ModalBody>
      </Modal>

      <div className="page-title">
        <i className="fa fa-reorder"></i>&nbsp;Appraiser - President club
      </div>

      <Row>
        <Col sm="3">
          <label className="no-margin-bottom">Status</label>
          <MySelect
            type="select"
            selectIsClearable={true}
            value={state.status}
            onChange={(v)=>setState({status:v})}
            options={[{label:"All",value:""}].concat(
              state.statuses.map((status)=>{
                return {label:status.status, value:status.status};
              })
            )}
          />
        </Col>
        <Col sm="3">
          <label className="no-margin-bottom">Name</label>
          <Input type="text" value={state.name} onChange={(e)=>setState({name:e.target.value})}/>
        </Col>
        <Col sm="3">
          <label className="no-margin-bottom">Company Name</label>
          <Input type="text" value={state.company_name} onChange={(e)=>setState({company_name:e.target.value})}/>
        </Col>
        <Col sm="3">
          <label className="no-margin-bottom">Email</label>
          <Input type="text" value={state.email} onChange={(e)=>setState({email:e.target.value})}/>
        </Col>
      </Row>
      <Row>
        <Col sm="3">
          <label className="no-margin-bottom">State</label>
          <MySelect
            type="select"
            selectIsClearable={true}
            value={state.state}
            onChange={(v)=>setState({state:v})}
            options={[{label:"All",value:""}].concat(
              state.states.map((state)=>{
                return {label:state.value, value:state.value};
              })
            )}
          />
        </Col>
        <Col sm="3">
          <label className="no-margin-bottom">County</label>
          <Input type="text" value={state.county} onChange={(e)=>setState({county:e.target.value})}/>
        </Col>
        <Col sm="3">
          <label className="no-margin-bottom">City</label>
          <Input type="text" value={state.city} onChange={(e)=>setState({city:e.target.value})}/>
        </Col>
        <Col sm="3" className="align-right valign-bottom">
          <br/>
          <Button color="warning" onClick={()=>refreshList()} >Submit</Button>
        </Col>
      </Row>
      <br/>

      <div className="my-divider"></div>
      <Row>
        <Col sm="6">
            <div>{formatNumber(state.totalCount)} appraisers in queue<br/><br/></div>
        </Col>
        <Col sm="6" className="align-right">
          <NavLink to="#" onClick={toggleModal}><i className="fa fa-plus green-color"></i> Recommend new appraiser</NavLink>
        </Col>
      </Row>
      <Nav tabs>
        <NavItem>
          <NavLinkL
            className={"cursor-pointer nav-link "+(state.activeTab === '1'?"active":"inactive" )}
            onClick={() => { activeTabToggle('1'); }}
          >
            President Club <span className="my-badge">{state.totalCount}</span>
          </NavLinkL>
        </NavItem>
        <NavItem>
          <NavLinkL
            className={"cursor-pointer nav-link "+(state.activeTab === '2'?"active":"inactive" )}
            onClick={() => { activeTabToggle('2'); }}
          >
            Pending Approval <span className="my-badge">{state.pendingRecommendations.length}</span>
          </NavLinkL>
        </NavItem>
      </Nav>
      <TabContent activeTab={state.activeTab}>
        <TabPane tabId="1">
          {renderAppraisers()}
        </TabPane>
        <TabPane tabId="2">
          <div className="large-scroll-container">
            <Table className="table table-striped">
              <thead>
                <tr>
                  <th width="15%">Action</th>
                  <th width="15%">Appraiser</th>
                  <th width="15%">Status</th>
                  <th width="15%">Recommender</th>
                  <th width="25%">Comment</th>
                  <th width="15%">Date recommended</th>
                </tr>
              </thead>
              <tbody>
                {
                  state.pendingRecommendations.map(
                    (recommendation,index)=>{
                      return(
                        <tr key={index}>
                          <td><i className="fa fa-question-circle link-color cursor-pointer" onClick={(e)=>selectedRecommendation(recommendation)}></i></td>
                          <td>{recommendation.first_name+' '+recommendation.last_name}</td>
                          <td>{recommendation.recommendation_status}</td>
                          <td>{recommendation.Recommender}</td>
                          <td>{recommendation.comment}</td>
                          <td>{formatDateTime(recommendation.datetime_recommended)}</td>
                        </tr>
                      );
                    }
                  )
                }
              </tbody>
            </Table>
          </div>
        </TabPane>
      </TabContent>
    </InfiniteScroll>
  </div>;
}

export default AppraiserPresidentClub;
