//Author Sooyoung Kim
//Date July 5, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, deleteAPICallGenerator, callBackGenerator, formatNumber, formatDateTime, confirmation, getFromTo, sliceFromArray} from '../../../util/util';
import {Button, Card, CardHeader, CardBody, Row, Col, Table, Input, Modal, ModalHeader, ModalBody} from 'reactstrap';
import moment from 'moment';
import {NavLink} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';
import ReportFilter from '../report-filter';

//initialize the state
const initialState = {
  appraisers:[],
  start:getFromTo('start'),
  end:getFromTo('end'),
  dropDowns:{},
  idToName:{},
  clients:[],
  states:[],
  paymentBatchs:[],

  paymentQueue:[],

  confirmation:'',
  description:'',
  client:'',
  state:'',
  active:'yes',
  captureTransactionDropDownOpen:false,
  selectedPaymentBatch:{},

  confirmationNum:'',
  total:'',
  comment:''
};

//reducer function that perform state update
const reducer = getReducer();


const ViewPaymentBatchReport  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const selectedPaymentBatch = (paymentBatch)=>{
    setState({selectedPaymentBatch:paymentBatch});
    toggleDropDown();
  }

  const toggleDropDown = ()=>{
    setState({captureTransactionDropDownOpen:!state.captureTransactionDropDownOpen});
  }

  //API call
  const deleteInvoice = (id, invoiceFk)=>{
    let list = sliceFromArray(state.paymentBatchs, ['ID','ID'],[id, invoiceFk], ['invoices']);
    let callBack = apiCallBack([{state:'appraisers', value:list}]);
    httpDelete('report/paymentBatch/invoice/'+id+'/'+invoiceFk,'Invoice removed from payment batch successfully.','Oops, something went wrong and could not remove the invoice from payment batch. Please try again later.', callBack);
  }

  const deletePaymentBatch = (id)=>{
    let list = sliceFromArray(state.paymentBatchs,'ID', id);
    let callBack = apiCallBack([{state:'paymentBatchs', value:list},{state:'selectedPaymentBatch', value:{}}]);
    httpDelete('report/paymentBatch/'+id,'Payment batch removed successfully.','Oops, something went wrong and could not remove the payment batch. Please try again later.', callBack);
  }

  const getReport = ()=>{
    let callBack = apiCallBack([{state:'paymentBatchs', key:'data'}]);
    httpGet('report/paymentBatch/get/from='+state.start+'&to='+state.end+'&active='+state.active, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  const capturePaymentBatch = ()=>{
    let parameters = [
      {
        field:'batchID',
        value:state.selectedPaymentBatch.ID
      },
      {
        field:'confirmationNum',
        value:state.confirmationNum
      },
      {
        field:'total',
        value:state.total
      },
      {
        field:'comment',
        value:state.comment
      }
    ];

    let list = sliceFromArray(state.paymentBatchs,'ID', state.selectedPaymentBatch);
    let callBack = apiCallBack([{state:'paymentBatchs', value:list}], toggleDropDown());

    httpPost('report/paymentBatch/pay', parameters, 'Payment batch captured successfully.', 'Oops, something went wrong and could not captured this payment batch. Please try again later.', callBack);
  }

  //render
  let start, end;
  if(state.start!=='')
    start = moment(state.start);
  if(state.end!=='')
    end = moment(state.end);

  let total = 0;

  if(state.selectedPaymentBatch.invoices){
    for(let i=0;i<state.selectedPaymentBatch.invoices.length;i++){
      total = total +parseInt(state.selectedPaymentBatch.invoices[i].total,10);
    }
  }

  let reportFiltersConfig = [
    {id:'from',value:start, updateFunc:(v)=>setState({start:v}), width:'2'},
    {id:'to',value:end, updateFunc:(v)=>setState({end:v}), width:'2'},
    {id:'customSelect',value:state.active, updateFunc:(v)=>setState({active:v}), width:'3', label:'Active', clearable:false, className:"", options:[
      {label:"Active",value:"yes"},
      {label:"Inactive",value:"no"},
      {label:"All",value:""},
    ]},
    {id:'button',value:'Submit', updateFunc:getReport, width:'5', className:"align-right", color:"warning"},
  ];
  
  return <div>
    <Modal className="my-modal" isOpen={state.captureTransactionDropDownOpen} toggle={toggleDropDown} >
      <ModalHeader hidden={true} toggle={toggleDropDown}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-edit"></i> Capture transaction</h5>
        </center>
        <br/>

        <label>Payment batch confirmation #</label>
        <br/>
        {state.selectedPaymentBatch.confirmation_num}

        <br/><br/>
        <label>Payment batch description</label>
        <br/>
        {state.selectedPaymentBatch.description}

        <br/><br/>
        <label>Payment batch total</label>
        <br/>
        ${formatNumber(total)}

        <br/><br/>
        <label>Payment batch created date</label>
        <br/>
        {formatDateTime(state.selectedPaymentBatch.datetime_created)}

        <br/><br/>
        <Row>
          <Col sm="6">
            <label>Transaction confirmation #</label>
            <Input type="text" value={state.confirmationNum} onChange={(e)=>setState({confirmationNum:e.target.value})}/>
          </Col>
          <Col sm="6">
            <label>Transaction amount</label>
            <Input disabled type="text" value={total}/>
          </Col>
          <Col sm="12">
            <label>Comment</label>
            <Input type="textarea" onChange={(e)=>setState({comment:e.target.value})} style={{resize:'none'}} rows="4"></Input>
          </Col>
        </Row>
        <br/>
        <center>
          <Button color="warning" onClick={capturePaymentBatch}>Capture</Button>&nbsp;
          <Button color="info" onClick={toggleDropDown}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
    <br/>
    <ReportFilter {...props} configs={reportFiltersConfig}/>
    <div className="my-divider">&nbsp;</div>
    <Card>
      <CardHeader className="header-color">
        Payment batch
      </CardHeader>
      <CardBody>
        {
          state.paymentBatchs.map(
            (batch,index)=>{
              let batchTotal = 0;
              for(let i=0;i<batch.invoices.length;i++){
                batchTotal = batchTotal + parseFloat(batch.invoices[i].total);
              }
              return(
                <div key={index}>
                  <Card>
                    <CardHeader className="header-color">
                      <Row>
                        <Col sm="6">
                          <i className="fa fa-times red-color cursor-pointer" onClick={
                            (e)=>confirmation(
                              ()=>{deletePaymentBatch(batch.ID)},
                              ()=>{},
                              'Delete payment batch?',
                              'Are you sure you want to delete this payment batch?')
                          }></i>{batch.company} ${formatNumber(batchTotal)} #{(batch.datetime_created.split(' ')[0]).split('-').join('')+'-'+batch.ID} {batch.confirmation_num}
                        </Col>
                        <Col sm="6" className="align-right">
                          <NavLink to="#" onClick={(e)=>selectedPaymentBatch(batch)}><i className="fa fa-edit"></i> Capture transaction</NavLink>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col sm="8">
                          <i>{batch.description}</i>
                        </Col>
                        <Col sm="4" className="align-right">
                          Created {formatDateTime(batch.datetime_created)}
                        </Col>
                      </Row>
                      <Table className="table table-striped">
                        <thead>
                          <tr>
                            <th>Property</th>
                            <th>Invoice #</th>
                            <th>Status</th>
                            <th>Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            batch.invoices.map(
                              (invoice,index)=>{
                                return(
                                  <tr key={index}>
                                    <td><i className="fa fa-times cursor-pointer red-color" onClick={(e)=>{deleteInvoice(batch.ID, invoice.ID)}}></i><NavLink target="_blank" to={"/appraisal/"+invoice.appraisal_fk}>{invoice.property_street+' '+invoice.property_city+', '+invoice.property_state+' '+invoice.property_zip}</NavLink></td>
                                    <td>#{invoice.ID}</td>
                                    <td>{invoice.status}</td>
                                    <td>${formatNumber(invoice.total)}</td>
                                  </tr>
                                )
                              }
                            )
                          }
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                  <br/>
                </div>
              )
            }
          )
        }
      </CardBody>
    </Card>
  </div>;
}


export default ViewPaymentBatchReport;
