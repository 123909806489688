//Author June Leow
//Date Oct 11th, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, formatDateTime} from '../../util/util';
import {Button, Card, CardHeader, CardBody, Table, Modal, ModalHeader, ModalBody} from 'reactstrap';
import {NavLink} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';
//initialize the state
const initialState = {
  appraiserPipelineDropDownOpen:false,
  appraiserPipeline:{}
};

//reducer function that perform state update
const reducer = getReducer();


const AppraiserPipeline  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const toggleAppraiserPipeline = ()=>{
    if(state.appraiserPipeline&&Object.keys(state.appraiserPipeline).length===0){
      getAppraiserPipeline();
    }
    setState({appraiserPipelineDropDownOpen:!state.appraiserPipelineDropDownOpen});
  }

  //API call
  const getAppraiserPipeline = ()=>{
    let callBack = apiCallBack([{state:'appraiserPipeline',key:'data'}]);
    httpGet('appraiser/pipeline/overview/'+props.appraisal.appraiser_fk,'','Oops, something went wrong and could not load this appraiser\'s pipeline. Please try again later.', callBack);
  }

  let appraiserPipelineIcon;
  let appraiserPipelineInspectionNeeded;
  let appraiserPipelineReportNeeded;
  let appraiserPipelineConditionNeeded;
  let appraiserPipelineRebuttalNeeded;
  let appraiserPipelineInspectionScheduled;
  let appraiserPipelineWaitingOnContact;

  if(state.appraisal&&state.appraisal.appraiser_fk&&state.appraisal.appraiser_fk!==0)
    appraiserPipelineIcon = <i onClick={toggleAppraiserPipeline} className="fa fa-drivers-license-o cursor-pointer link-color"></i>;

  if(state.appraiserPipeline&&state.appraiserPipeline.inspection_needed){
    appraiserPipelineInspectionNeeded = state.appraiserPipeline.inspection_needed.map(
      (order, index)=>{
        return <tr key={index}>
          <td><NavLink to={"/appraisal/"+order.ID}>{order.reference_num}</NavLink></td>
          <td>{order.loan_num}</td>
          <td>{order.borrower_f_name+' '+order.borrower_l_name}</td>
          <td>{order.property_street+' '+order.property_city+', '+order.property_state+' '+order.property_zip}</td>
          <td>{formatDateTime(order.datetime_accepted)}</td>
        </tr>
      }
    )
  }

  if(state.appraiserPipeline&&state.appraiserPipeline.waiting_on_contact){
    appraiserPipelineWaitingOnContact = state.appraiserPipeline.waiting_on_contact.map(
      (order, index)=>{
        return <tr key={index}>
          <td><NavLink to={"/appraisal/"+order.ID}>{order.reference_num}</NavLink></td>
          <td>{order.loan_num}</td>
          <td>{order.borrower_f_name+' '+order.borrower_l_name}</td>
          <td>{order.property_street+' '+order.property_city+', '+order.property_state+' '+order.property_zip}</td>
          <td>{formatDateTime(order.datetime_accepted)}</td>
        </tr>
      }
    )
  }

  if(state.appraiserPipeline&&state.appraiserPipeline.inspection_scheduled){
    appraiserPipelineInspectionScheduled = state.appraiserPipeline.inspection_scheduled.map(
      (order, index)=>{
        return <tr key={index}>
          <td><NavLink to={"/appraisal/"+order.ID}>{order.reference_num}</NavLink></td>
          <td>{order.loan_num}</td>
          <td>{order.borrower_f_name+' '+order.borrower_l_name}</td>
          <td>{order.property_street+' '+order.property_city+', '+order.property_state+' '+order.property_zip}</td>
          <td>{formatDateTime(order.datetime_accepted)}</td>
        </tr>
      }
    )
  }

  if(state.appraiserPipeline&&state.appraiserPipeline.report_needed){
    appraiserPipelineReportNeeded = state.appraiserPipeline.report_needed.map(
      (order, index)=>{
        return <tr key={index}>
          <td><NavLink to={"/appraisal/"+order.ID}>{order.reference_num}</NavLink></td>
          <td>{order.loan_num}</td>
          <td>{order.borrower_f_name+' '+order.borrower_l_name}</td>
          <td>{order.property_street+' '+order.property_city+', '+order.property_state+' '+order.property_zip}</td>
          <td>{formatDateTime(order.datetime_inspection)}</td>
        </tr>
      }
    )
  }

  if(state.appraiserPipeline&&state.appraiserPipeline.condition_needed){
    appraiserPipelineConditionNeeded = state.appraiserPipeline.condition_needed.map(
      (order, index)=>{
        return <tr key={index}>
          <td><NavLink to={"/condition/"+order.ID}>{order.reference_num}</NavLink></td>
          <td>{order.loan_num}</td>
          <td>{order.borrower_f_name+' '+order.borrower_l_name}</td>
          <td>{order.property_street+' '+order.property_city+', '+order.property_state+' '+order.property_zip}</td>
          <td>{formatDateTime(order.datetime_requested)}</td>
        </tr>
      }
    )
  }

  if(state.appraiserPipeline&&state.appraiserPipeline.rebuttal_needed){
    appraiserPipelineRebuttalNeeded = state.appraiserPipeline.rebuttal_needed.map(
      (order, index)=>{
        return <tr key={index}>
          <td><NavLink to={"/rebuttal/"+order.ID}>{order.reference_num}</NavLink></td>
          <td>{order.loan_num}</td>
          <td>{order.borrower_f_name+' '+order.borrower_l_name}</td>
          <td>{order.property_street+' '+order.property_city+', '+order.property_state+' '+order.property_zip}</td>
          <td>{formatDateTime(order.datetime_requested)}</td>
        </tr>
      }
    )
  }

  //render
  return <div className="display-inline">
    {appraiserPipelineIcon}
    <Modal className="my-modal" isOpen={state.appraiserPipelineDropDownOpen} toggle={toggleAppraiserPipeline} >
      <ModalHeader hidden={true} toggle={toggleAppraiserPipeline}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-warning"></i> Appraiser Pipeline Overview</h5>
        </center>
        <br/>

        <Card>
          <CardHeader>
            Waiting On POC
          </CardHeader>
          <CardBody>
            <div className="medium-scroll-container-no-min">
              <Table className="table table-striped">
                <thead>
                  <tr>
                    <th width="15%">Reference #</th>
                    <th width="15%">Loan #</th>
                    <th width="15%">Borrower</th>
                    <th width="25%">Property</th>
                    <th width="20%">Date Accepted</th>
                  </tr>
                </thead>
                <tbody>
                  {appraiserPipelineWaitingOnContact}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>

        <br/>

        <Card>
          <CardHeader>
            Pending Inspection
          </CardHeader>
          <CardBody>
            <div className="medium-scroll-container-no-min">
              <Table className="table table-striped">
                <thead>
                  <tr>
                    <th width="15%">Reference #</th>
                    <th width="15%">Loan #</th>
                    <th width="15%">Borrower</th>
                    <th width="25%">Property</th>
                    <th width="20%">Date Accepted</th>
                  </tr>
                </thead>
                <tbody>
                  {appraiserPipelineInspectionNeeded}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>

        <br/>

        <Card>
          <CardHeader>
            Inspection Scheduled
          </CardHeader>
          <CardBody>
            <div className="medium-scroll-container-no-min">
              <Table className="table table-striped">
                <thead>
                  <tr>
                    <th width="15%">Reference #</th>
                    <th width="15%">Loan #</th>
                    <th width="15%">Borrower</th>
                    <th width="25%">Property</th>
                    <th width="20%">Date Accepted</th>
                  </tr>
                </thead>
                <tbody>
                  {appraiserPipelineInspectionScheduled}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>

        <br/>

        <Card>
          <CardHeader>
            Report Expected
          </CardHeader>
          <CardBody>
            <div className="medium-scroll-container-no-min">
              <Table className="table table-striped">
                <thead>
                  <tr>
                    <th width="15%">Reference #</th>
                    <th width="15%">Loan #</th>
                    <th width="15%">Borrower</th>
                    <th width="25%">Property</th>
                    <th width="20%">Inspection Date</th>
                  </tr>
                </thead>
                <tbody>
                  {appraiserPipelineReportNeeded}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>

        <br/>

        <Card>
          <CardHeader>
            Condition Started
          </CardHeader>
          <CardBody>
            <div className="medium-scroll-container-no-min">
              <Table className="table table-striped">
                <thead>
                  <tr>
                    <th width="15%">Reference #</th>
                    <th width="15%">Loan #</th>
                    <th width="15%">Borrower</th>
                    <th width="25%">Property</th>
                    <th width="20%">Date Requested</th>
                  </tr>
                </thead>
                <tbody>
                  {appraiserPipelineConditionNeeded}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>

        <br/>

        <Card>
          <CardHeader>
            Rebuttal Started
          </CardHeader>
          <CardBody>
            <div className="medium-scroll-container-no-min">
              <Table className="table table-striped">
                <thead>
                  <tr>
                    <th width="15%">Reference #</th>
                    <th width="15%">Loan #</th>
                    <th width="15%">Borrower</th>
                    <th width="25%">Property</th>
                    <th width="20%">Date Requested</th>
                  </tr>
                </thead>
                <tbody>
                  {appraiserPipelineRebuttalNeeded}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
        <center>
          <Button color="info" onClick={toggleAppraiserPipeline}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
  </div>;
}


export default AppraiserPipeline;
