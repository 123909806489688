//Author Sooyoung Kim
//Date June 8, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, formatDateTime} from '../../../util/util';
import {Button, Card, CardHeader, CardBody, Col, Row} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import MyReactTable from '../../util/my-react-table';
import moment from 'moment';
import {NavLink} from 'react-router-dom'
import ReportFilter from '../report-filter';
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

let today = new Date();
let month = today.getMonth()+1;

if(month<10)
  month = '0'+month;
let from = today.getFullYear()+'-'+month+'-'+today.getDate();
let to = today.getFullYear()+'-'+month+'-'+today.getDate();

//initialize the state
const initialState = {
  report:[],
  from:from,
  to:to,
};

//reducer function that perform state update
const reducer = getReducer();


const ValuePadOrders  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getReportDetails();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //API call
  const getReportDetails = ()=>{
    httpGet('report/valuePad/get/from='+state.from+'&to='+state.to, '', 'Oops, something went wrong and could not load the report. Please try again later.', apiCallBack([{state:'report', key:'data'}]));
  }

  //render
  let newfrom, newto;
  if(state.from!=='')
    newfrom = moment(state.from);
  if(state.to!=='')
    newto = moment(state.to);

  let reportFiltersConfig = [
    {id:'from',value:newfrom, updateFunc:(v)=>setState({from:v}), width:'2'},
    {id:'to',value:newto, updateFunc:(v)=>setState({to:v}), width:'2'},
    {id:'button',value:'Submit', updateFunc:getReportDetails, width:'8', className:"align-right", color:"warning"},
  ];

  const columns = [
    {
      id: 'referenceNum',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'thirdPartyNum',
      Header: 'File #',
      accessor: d => d.third_party_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'vpDatetimeSubmitted',
      Header: 'VP Submitted Date',
      accessor: d => d.value_pad_datetime_submitted,
      Cell: props=> <div>{formatDateTime(props.row.original.value_pad_datetime_submitted)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'datetimeSubmitted',
      Header: 'System Submitted Date',
      accessor: d => d.datetime_submitted,
      Cell: props=> <div>{formatDateTime(props.row.original.datetime_submitted)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'borrower',
      Header: 'Borrower',
      accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'street',
      Header: 'Address',
      accessor: d => d.property_street,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'city',
      Header: 'City',
      accessor: d => d.property_city,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'state',
      Header: 'State',
      accessor: d => d.property_state,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'vpStatus',
      Header: 'VP Status',
      accessor: d => d.value_pad_status,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'status',
      Header: 'System Status',
      accessor: d => d.status,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'appraisalType',
      Header: 'Report Type',
      accessor: d => d.appraisal_type,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  return <div>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Value Pad Orders Report
      </CardHeader>
      <CardBody>
        <ReportFilter {...props} configs={reportFiltersConfig}/>
        <div className="my-divider">&nbsp;</div>
        <Card>
          <CardHeader className="gray-color">
            <Row>
              <Col sm="8">
                Orders
              </Col>
              <Col sm="4">
                <div className="align-right">
                  <ExcelFile element={<Button disabled={state.report.length<=0} color="warning">Download Excel</Button>}>
                    <ExcelSheet data={state.report} name="XLSX">
                        <ExcelColumn label="Reference #" value="reference_num"/>
                        <ExcelColumn label="File #" value="third_party_num"/>
                        <ExcelColumn label="VP Submitted Date" value="value_pad_datetime_submitted"/>
                        <ExcelColumn label="System Submitted Date" value="datetime_submitted"/>
                        <ExcelColumn label="Borrower" value={(col)=>{return col.borrower_f_name+' '+col.borrower_l_name}}/>
                        <ExcelColumn label="Address" value="property_street"/>
                        <ExcelColumn label="City" value="property_city"/>
                        <ExcelColumn label="State" value="property_state"/>
                        <ExcelColumn label="VP Status" value="value_pad_status"/>
                        <ExcelColumn label="System Status" value="status"/>
                        <ExcelColumn label="Report Type" value="appraisal_type"/>
                    </ExcelSheet>
                  </ExcelFile>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <div className="large-scroll-container">
              <MyReactTable columns={columns} data={state.report} className="table table-striped"/>
            </div>
          </CardBody>
        </Card>
      </CardBody>
    </Card>
  </div>;
}

export default ValuePadOrders;
