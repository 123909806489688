//Author Sooyoung Kim
//Date July 10, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, formatNumber, formatDateTime, getFromTo, replaceRegex} from '../../../util/util';
import {Card, CardHeader, CardBody} from 'reactstrap';
import FileSaver from 'file-saver';
import moment from 'moment';
import {NavLink} from 'react-router-dom';
import '../report.css';
import React, {useReducer, useEffect} from 'react';
import ReportFilter from '../report-filter';

//initialize the state
const initialState = {
  client:'',
  clientBranch:'0',
  loanPurpose:'',
  loanType:'',
  isRush:'',
  isComplex:'',
  state:'',
  city:'',
  entity:'',
  filterAppraisalTypes:[],
  from:getFromTo('from'),
  to:getFromTo('to'),
  report:[],
};

//reducer function that perform state update
const reducer = getReducer();


const PipelineReport  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //API call
  const downloadReport = (e) => {
    e.preventDefault();
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);

    let url = 'report/client/pipeline/download/clientBranch='+state.clientBranch+'&client='+state.client+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&appraisalType='+appraisalType;
    let callBack = (response)=>{
      console.log(response.data.data);
      let byteCharacters = atob(response.data.data);
      let byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      let data = new Blob([byteArray]);
      FileSaver.saveAs(data, 'client-pipeline.xlsx');
    };
    callBack = callBack.bind(this);

    httpGet(url, '', 'Oops, something went wrong and could not download the fee schedule. Please try again later.', callBack);
  }

  const getReport = () => {
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);

    let url = 'report/client/pipeline/clientBranch='+state.clientBranch+'&client='+state.client+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&appraisalType='+appraisalType;
    httpGet(url, '', 'Oops, something went wrong and could not load the report. Please try again later.', apiCallBack([{state:'report', key:'data'}]));
  }


  //render
  let from, to;
  if(state.from!=='')
    from = moment(state.from);
  if(state.to!=='')
    to = moment(state.to);
    
  let table;

  if(state.report.length>0){
    table = <table className="table table-striped">
      <thead>
        <tr>
          <th>Reference #</th>
          <th>Loan #</th>
          <th>Date Submitted</th>
          <th>Address</th>
          <th>Borrower</th>
          <th>Loan Officer</th>
          <th>Branch</th>
          <th>Fee</th>
          <th>Date Charged</th>
          <th>LOS Integration</th>
        </tr>
      </thead>
      <tbody>
        {
          state.report.map(
            (row,index)=>{
              return(
                <tr key={index}>
                  <td><NavLink target="_blank" to={"/appraisal/"+row.ID}>{row.reference_num}</NavLink></td>
                  <td>{row.loan_num}</td>
                  <td>{formatDateTime(row.datetime_submitted)}</td>
                  <td>{row.property_street+' '+row.property_city+', '+row.property_state+' '+row.property_zip}</td>
                  <td>{row.borrower_f_name+' '+row.borrower_l_name}</td>
                  <td>{row.loan_officer}</td>
                  <td>{row.branch}</td>
                  <td>${formatNumber(row.fee)}</td>
                  <td>{formatDateTime(row.datetime_charged)}</td>
                  <td>{row.third_party}</td>
                </tr>
              )
            }
          )
        }
      </tbody>
    </table>;
  }
  else{
    table = <table className="table table-striped">
      <thead>
        <tr>
          <th>Reference #</th>
          <th>Loan #</th>
          <th>Date Submitted</th>
          <th>Address</th>
          <th>Borrower</th>
          <th>Loan Officer</th>
          <th>Branch</th>
          <th>Fee</th>
          <th>Date Charged</th>
          <th>LOS Integration</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="10" align="center">No data</td>
        </tr>
      </tbody>
    </table>;
  }

  let reportFiltersConfig = [
    {id:'from',value:from, updateFunc:(v)=>setState({from:v}), width:'2'},
    {id:'to',value:to, updateFunc:(v)=>setState({to:v}), width:'2'},
    {id:'rush',value:state.isRush, updateFunc:(v)=>setState({isRush:v}), width:'2'},
    {id:'complex',value:state.isComplex, updateFunc:(v)=>setState({isComplex:v}), width:'2'},
    {id:'state',value:state.state, updateFunc:(v)=>setState({state:v}), width:'2'},
    {id:'city',value:state.city, updateFunc:(v)=>setState({city:v}), width:'2'},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v, clientBranch:0}), width:'3'},
    {id:'clientBranch',value:state.clientBranch, updateFunc:(v)=>setState({clientBranch:v}), width:'3'},
    {id:'loanPurpose',value:state.loanPurpose, updateFunc:(v)=>setState({loanPurpose:v}), width:'3'},
    {id:'loanType',value:state.loanType, updateFunc:(v)=>setState({loanType:v}), width:'3'},
    {id:'custom',value:state.entity, updateFunc:(v)=>setState({entity:v}), width:'3', label:'Loan Officer name'},
    {id:'appraisalType',value:state.filterAppraisalTypes, updateFunc:(v)=>setState({filterAppraisalTypes:v}), width:'4'},
    {id:'button',value:'Submit', updateFunc:getReport, width:'5', className:"align-right", color:"warning"},
  ];

  return <div>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Pipeline Report
      </CardHeader>
      <CardBody>
        <ReportFilter {...props} configs={reportFiltersConfig}/>
      </CardBody>
    </Card>
    <br/>

    <div className="align-right">
      <NavLink to="#" onClick={downloadReport}>Download Report</NavLink>
    </div>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Pipeline Report
      </CardHeader>
      <CardBody>
        {table}
      </CardBody>
    </Card>
  </div>;
}


export default PipelineReport;
