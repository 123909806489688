//Author Sooyoung KimMyPlacesAutocomplete
//Date July 21, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, formatDate, showMessage, generateSID} from '../../util/util';
import {Button, Input, Col, Row, Card, CardHeader, CardBody} from 'reactstrap';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import MyPlacesAutocomplete from './../util/my-places-autocomplete';
import React, {useReducer, useEffect, useRef} from 'react';
import MyFormStep from '../util/my-form-step.js';
import MySelect from './../util/my-select';
import DatePicker from 'react-datepicker';
import {NavLink, useNavigate, useParams} from 'react-router-dom';
import moment from 'moment';

//initialize the state
const initialState = {
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],

  street:'',
  city:'',
  county:'',
  state:'',
  zip:'',

  billingStreet:'',
  billingCity:'',
  billingCounty:'',
  billingState:'',
  billingZip:'',
  billingName:'',

  preferredCommunication:'',

  firstName:'',
  lastName:'',
  email:'',
  password:'',
  notifyEmails:'',
  phone:'',
  taxID:'',
  eoExpirationDate:'',

  newLicenseType:'Unknown',
  newLicenseNumber:'',
  newLicenseState:'',
  newLicenseExpiration:'',

  lat:'',
  lng:'',

  steps:['General Information','Address','Review'],
  maxStep:0,
  currentStep:0,

  checked:false,
  address:'',
  preferredCommunicationList:[],
  searchAppraisers:[],
  sid:''
};

//reducer function that perform state update
const reducer = getReducer();


const NewAppraiser  = (props)=>{
  const controller = new AbortController();
  const sidRef = useRef('');
  const history = useNavigate();
  let {params} = useParams();

  let newInitialState = Object.assign({}, initialState, {
    params:params,
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getPreferredCommunication();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const submitForm = (e) => {
    e.preventDefault();

    switch(state.currentStep){
      case 0:

        break;
      case 1:

        break;
      case 2:

        break;
      case 3:

        break;
      default:

        break;
    }
    handleStepAdvance();
  }

  const checkHandler = (e) => {
    if(e.target.checked)
      copyAddress();

    setState({checked:e.target.checked});
  }

  const copyAddress = () => {
    setState({
      billingStreet:state.street, 
      billingCity:state.city, 
      billingCounty:state.county, 
      billingState:state.state, 
      billingZip:state.zip,
    });
  }

  const handleStepAdvance = () => {
    let step = state.currentStep+1;
    //step 3 then finish, send create appraisal request
    if(step===3)
      createNewAppraiser();
    //else advance the step
    else{
      setState({currentStep:step});
      if(state.maxStep<step)
        setState({maxStep:step});
    }
  }

  const updateStep = (step) => {
    if(step<=state.maxStep)
      setState({currentStep:step});
  }

  const googlePlaceOnChange = (address) => {
    if(address!==state.address){

      if(address.indexOf(',')!==-1 && address.length>10){
        setState({lat:null, lng:null});

        geocodeByAddress(address)
          .then(
            results => {
              //always use the first returned result
              let targetResult = results[0];

              let addressComponents = targetResult.address_components;

              let streetNumber = '';
              let route = '';
              let city = '';
              let county = '';
              let stateName = '';
              let zip = '';

              //go through the address components and grab the street, city, county, state, and zip
              for(let i=0;i<addressComponents.length;i++){
                if(addressComponents[i].types[0]==='street_number')
                  streetNumber = addressComponents[i].long_name;
                else if(addressComponents[i].types[0]==='route')
                  route = addressComponents[i].long_name;
                else if(addressComponents[i].types[0]==='locality'&&addressComponents[i].types[1]==='political')
                  city = addressComponents[i].long_name;
                else if(addressComponents[i].types[0]==='administrative_area_level_2'&&addressComponents[i].types[1]==='political')
                  county = addressComponents[i].long_name;
                else if(addressComponents[i].types[0]==='administrative_area_level_1'&&addressComponents[i].types[1]==='political')
                  stateName = addressComponents[i].long_name;
                else if(addressComponents[i].types[0]==='postal_code')
                  zip = addressComponents[i].long_name;
              }


              let street = '';

              if(streetNumber!==''&&route!=='')
                street = streetNumber+' '+route;

              //get long lat
              getLatLng(targetResult)
                .then(
                  result=>{
                    if(result){
                      let lat = result.lat;
                      let lng = result.lng;
                      console.log(lng);

                      setState({lat:lat, lng:lng});
                    }
                  }
                )
                .catch(
                  error=>{
                    console.log(error);
                  }
                );

              if(street!==''){
                setState({street:street, address:street});
              }
              if(city!=='')
                setState({city:city});
              if(county!=='')
                setState({county:county});
              if(stateName!=='')
                setState({state:stateName});
              if(zip!=='')
                setState({zip:zip});
            })
          .catch(
            error => {
              console.log(error);
            }
          );
      }
      else{
        setState({address:address, street:address});
      }
    }
  }

  //API call
  const createNewAppraiser = () => {
    let callBack = (response)=>{console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let data = response.data.data;
        let newID = data.ID;

        history('/appraiser/'+newID+'/status=');

        if(response.data.exclusionary_list)
          showMessage('error','Appraiser under exclusionary list. Please see details in the profile.');
      }
    };
    callBack = callBack.bind(this);

    let parameters =[
      {
        field:'billingName',
        value:state.billingName
      },
      {
        field:'street',
        value:state.street
      },
      {
        field:'city',
        value:state.city
      },
      {
        field:'state',
        value:state.state
      },
      {
        field:'county',
        value:state.county
      },
      {
        field:'zip',
        value:state.zip
      },
      {
        field:'billingStreet',
        value:state.billingStreet
      },
      {
        field:'billingCity',
        value:state.billingCity
      },
      {
        field:'billingCounty',
        value:state.billingCounty
      },
      {
        field:'billingState',
        value:state.billingState
      },
      {
        field:'billingZip',
        value:state.billingZip
      },
      {
        field:'author',
        value:props.userName
      },
      {
        field:'firstName',
        value:state.firstName
      },
      {
        field:'lastName',
        value:state.lastName
      },
      {
        field:'email',
        value:state.email
      },
      {
        field:'notifyEmails',
        value:state.notifyEmails
      },
      {
        field:'password',
        value:state.password
      },
      {
        field:'phone',
        value:state.phone
      },
      {
        field:'preferredCommunication',
        value:state.preferredCommunication
      },
      {
        field:'lat',
        value:state.lat
      },
      {
        field:'lng',
        value:state.lng
      },
      {
        field:'taxID',
        value:state.taxID
      },
      {
        field:'eoExpirationDate',
        value:state.eoExpirationDate
      },
      {
        field:'licenseType',
        value:state.newLicenseType
      },
      {
        field:'licenseNumber',
        value:state.newLicenseNumber
      },
      {
        field:'licenseState',
        value:state.newLicenseState
      },
      {
        field:'licenseExpiration',
        value:state.newLicenseExpiration
      }
    ];

    httpPost('appraiser/create', parameters, 'Appraiser profile created successfully.', 'Oops, something went wrong and could not create the appraiser profile. Please try again later.', callBack);
  }

  const searchAppraisers = (name) => {
    let newName = encodeURIComponent(name.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
    let sid = generateSID();
    sidRef.current = sid;

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        if(sid===sidRef.current)
          setState({searchAppraisers:response.data.data});
      }
    };
    callBack = callBack.bind(this);
	  httpGet('appraiser/get/limit=100&offset=0&name='+newName, '', 'Oops, something went wrong and could not load list of companies. Please try again later.', callBack);
  }

  const getPreferredCommunication = () => {
    let callBack = (response)=>{console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let preferredCommunication = [];

        for(let i=0;i<response.data.data.length;i++){
          let tmp = {}
          tmp.key=response.data.data[i].method;
          tmp.value=response.data.data[i].method;
          preferredCommunication.push(tmp);
        }
        setState({preferredCommunicationList:preferredCommunication});
      }
    };

    callBack = callBack.bind(this);
    httpGet('appraiser/communicationMethod/get', '', 'Oops, something went wrong and could not load preferred communications. Please try again later.', callBack);
  }

  //render
  let step1, step2, step3;
  let activeStep = step1;

  let date;
  if(state.eoExpirationDate!=='')
      date = moment(state.eoExpirationDate).toDate();

  let date2;
  if(state.newLicenseExpiration!=='')
      date2 = moment(state.newLicenseExpiration).toDate();


  let searchResult  = <div className="medium-scroll-container"></div>;

  if(state.searchAppraisers.length>0){
    searchResult = <div>
      <font color="red">*Below are a list of existing appraisers with similar name</font><br/><br/>
      <div className="medium-scroll-container">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Appraiser</th>
              <th>Email</th>
              <th>Status</th>
              <th>Profile Registered</th>
            </tr>
          </thead>
          <tbody>
            {
              state.searchAppraisers.map(
                (appraiser, index)=>{
                  return <tr key={index}>
                    <td><NavLink to={"/appraiser/"+appraiser.ID+"/status="}>{appraiser.first_name+' '+appraiser.last_name}</NavLink></td>
                    <td>{appraiser.email}</td>
                    <td>{appraiser.status}</td>
                    <td>{formatDate(appraiser.datetime_created)}</td>
                  </tr>
                }
              )
            }
          </tbody>
        </table>
      </div>
      <div className="my-divider">&nbsp;</div>
    </div>
  }
  /*
  STEP 1
  */
  step1 =
  <div>
    <form onSubmit={submitForm}>
      <Row>
        <Col sm="4">
          <label><font className="red-color">*</font>First name</label><br/>
          <Input type="text" required={true} value={state.firstName} onChange={(e)=>{setState({firstName:e.target.value});searchAppraisers(e.target.value+' '+state.lastName)}}/>
        </Col>
        <Col sm="4">
          <label><font className="red-color">*</font>Last name</label><br/>
          <Input type="text" required={true} value={state.lastName} onChange={(e)=>{setState({lastName:e.target.value});searchAppraisers(state.firstName+' '+e.target.value)}}/>
        </Col>
      </Row>
      <br/>
      {searchResult}
      <br/>
      <br/>
      <Row>
        <Col sm="3">
          <label><font className="red-color">*</font>Email (username)</label><br/>
          <Input type="text" required={true} value={state.email} onChange={(e)=>setState({email:e.target.value})}/>
        </Col>
        <Col sm="3">
          <label><font className="red-color">*</font>Password</label><br/>
          <Input type="text" required={true} value={state.password} onChange={(e)=>setState({password:e.target.value})}/>
        </Col>
      </Row>
      <br/>
      <Row>
        <Col sm="3">
          <label>Notify emails</label><br/>
          <Input type="text" value={state.notifyEmails} onChange={(e)=>setState({notifyEmails:e.target.value})}/>
        </Col>
        <Col sm="3">
          <label><font className="red-color">*</font>Phone</label><br/>
          <Input type="text" required={true} value={state.phone} onChange={(e)=>setState({phone:e.target.value})}/>
        </Col>
        <Col sm="3">
          <label>Preffered communication</label><br/>
          <MySelect
            type="select"
            value={state.preferredCommunication}
            onChange={(v)=>setState({preferredCommunication:v})}
            options={state.preferredCommunicationList.map((method)=>{
              return {label:method.value, value:method.value};
            })}
          />
        </Col>
      </Row>
      <br/>
      <div className="my-divider">&nbsp;</div>
      <br/>
      <Row>
        <Col sm="4">
          <label><font className="red-color">*</font>Tax ID</label><br/>
          <Input type="text" required={true} value={state.taxID} onChange={(e)=>setState({taxID:e.target.value})}/>
        </Col>
        <Col sm="4">
          <label><font className="red-color">*</font>E&O Expiration Date</label><br/>
          <DatePicker
            required={true}
            className="form-control"
            dateFormat="yyyy/MM/dd"
            selected={date}
            onChange={(text)=>{(text)&&setState({eoExpirationDate:text.toLocaleDateString('en-CA')})}}
          />
        </Col>
      </Row>
      <Row>
        <Col sm="3">
          <label><font className="red-color">*</font>License Number</label>
          <Input type="text" required={true} onChange={(e)=>setState({newLicenseNumber:e.target.value})} value={state.newLicenseNumber}></Input>
        </Col>
        <Col sm="3">
          <label><font className="red-color">*</font>State</label>
          <MySelect
            type="select"
            autoComplete="off"
            value={state.newLicenseState}
            onChange={(v)=>setState({newLicenseState:v})}
            options={state.states.map((state)=>{
              return {label:state.value, value:state.value};
            })}
          />
        </Col>
        <Col sm="3">
          <label><font className="red-color">*</font>Type</label>
          <MySelect
            type="select"
            value={state.newLicenseType}
            onChange={(v)=>setState({newLicenseType:v})}
            options={[{label:"Unknown",value:"Unknown"},{label:"Transitional License",value:"Transitional License"},{label:"License",value:"License"},{label:"Certified Residential",value:"Certified Residential"},{label:"Certified General",value:"Certified General"}]}
          />
        </Col>
        <Col sm="3">
          <label><font className="red-color">*</font>Expiration</label><br/>
          <DatePicker
            required={true}
            className="form-control"
            dateFormat="yyyy/MM/dd"
            selected={date2}
            onChange={(text)=>{(text)&&setState({newLicenseExpiration:text.toLocaleDateString('en-CA')})}}
          />
        </Col>
      </Row>
      <div className="align-right">
        <Button color="warning">Next</Button>
      </div>
    </form>
  </div>

  /*
  STEP 2
  */
  step2 =
  <div>
    <form onSubmit={submitForm}>
      <Card>
        <CardHeader className="gray-color">
          Address
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="7">
              <label><font color="red">*</font>Street</label>
              <MyPlacesAutocomplete value={state.address} onChange={googlePlaceOnChange} onSelect={googlePlaceOnChange}/>
            </Col>
            <Col sm="5">
              <label><font color="red">*</font>City</label>
              <Input required={true} type="text" value={state.city} onChange={(e)=>setState({city:e.target.value})}/>
            </Col>
          </Row>
          <Row>
            <Col sm="4">
              <label><font color="red">*</font>State</label>
              <MySelect
                type="select"
                value={state.state}
                onChange={(v)=>setState({state:v})}
                options={state.states.map((state)=>{
                  return {label:state.value, value:state.value};
                })}
              />
            </Col>
            <Col sm="3">
              <label>County</label>
              <Input type="text" disabled value={state.county} onChange={(e)=>setState({county:e.target.value})}/>
            </Col>
            <Col sm="2">
              <label><font color="red">*</font>Zip</label>
              <Input required={true} type="text" value={state.zip} onChange={(e)=>setState({zip:e.target.value})}/>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <br/>
      <Card>
        <CardHeader className="gray-color">
          Mail Check To
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="12">
              <label>Make Check Payable to </label><br/>
              <Input type="text" value={state.billingName} onChange={(e)=>setState({billingName:e.target.value})}/>
            </Col>
          </Row>
          <br/><br/>
          <Row>
            <Col sm="12">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input type="checkbox" value={state.checked} onChange={(e)=>checkHandler(e)}/> Same as above
            </Col>
          </Row>
          <Row>
            <Col sm="7">
              <label><font color="red">*</font>Street</label>
              <Input required={true} type="text" value={state.billingStreet} onChange={(e)=>setState({billingStreet:e.target.value})}/>
            </Col>
            <Col sm="5">
              <label><font color="red">*</font>City</label>
              <Input required={true} type="text" value={state.billingCity} onChange={(e)=>setState({billingCity:e.target.value})}/>
            </Col>
          </Row>
          <Row>
            <Col sm="4">
              <label><font color="red">*</font>State</label>
              <MySelect
                type="select"
                selectIsClearable={true}
                value={state.billingState}
                onChange={(v)=>setState({billingState:v})}
                options={state.states.map((state)=>{
                  return {label:state.value, value:state.value};
                })}
              />
            </Col>
            <Col sm="3">
              <label>County</label>
              <Input type="text" disabled value={state.billingCounty} onChange={(e)=>setState({billingCounty:e.target.value})}/>
            </Col>
            <Col sm="2">
              <label><font color="red">*</font>Zip</label>
              <Input required={true} type="text" value={state.billingZip} onChange={(e)=>setState({billingZip:e.target.value})}/>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <br/>
      <div className="align-right">
        <Button color="warning">Next</Button>
      </div>
    </form>
  </div>


  /*
  STEP 3
  */
  step3 =
  <div>
    <form onSubmit={submitForm}>
      <Card>
        <CardHeader className="gray-color">
          General information
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="12">
              <label>Name</label><br/>
              {state.firstName+' '+state.lastName}
            </Col>
          </Row>
          <Row>
            <Col sm="3">
              <label>Email (username)</label><br/>
              {state.email}
            </Col>
            <Col sm="3">
              <label>Password</label><br/>
              {state.password}
            </Col>
          </Row>
          <Row>
            <Col sm="3">
              <label>Notify emails</label><br/>
              {state.notifyEmails}
            </Col>
            <Col sm="3">
              <label>Phone</label><br/>
              {state.phone}
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <label>Preffered communication</label><br/>
              {state.preferredCommunication}
            </Col>
          </Row>

          <div className="my-divider">&nbsp;</div>
          <br/><br/>
          <Row>
            <Col sm="2">
              <label><font className="red-color">*</font>Tax ID</label><br/>
              {state.taxID}
            </Col>
            <Col sm="4">
              <label><font className="red-color">*</font>E&O Expiration Date</label><br/>
              {state.eoExpirationDate}
            </Col>
          </Row>
          <Row>
            <Col sm="3">
              <label><font className="red-color">*</font>License Number</label><br/>
              {state.newLicenseNumber}
            </Col>
            <Col sm="3">
              <label><font className="red-color">*</font>State</label><br/>
              {state.newLicenseState}
            </Col>
            <Col sm="3">
              <label><font className="red-color">*</font>Type</label><br/>
              {state.newLicenseType}
            </Col>
            <Col sm="3">
              <label><font className="red-color">*</font>Expiration</label><br/>
              {state.newLicenseExpiration}
            </Col>
          </Row>
        </CardBody>
      </Card>
      <br/>
      <Card>
        <CardHeader className="gray-color">
          Address
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="5">
              <label>Street</label><br/>
              {state.street}
            </Col>
            <Col sm="4">
              <label>City</label><br/>
              {state.city}
            </Col>
            <Col sm="3">
              <label>County</label><br/>
              {state.county}
            </Col>
          </Row>
          <Row>
            <Col sm="5">
              <label>State</label><br/>
              {state.state}
            </Col>
            <Col sm="4">
              <label>Zip</label><br/>
              {state.zip}
            </Col>
          </Row>
        </CardBody>
      </Card>
      <br/>
      <Card>
        <CardHeader className="gray-color">
          Mail Check To
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="12">
              <label>Make Check Payable To</label><br/>
              {state.billingName}
            </Col>
          </Row>
          <br/><br/>
          <Row>
            <Col sm="5">
              <label>Street</label><br/>
              {state.billingStreet}
            </Col>
            <Col sm="4">
              <label>City</label><br/>
              {state.billingCity}
            </Col>
            <Col sm="3">
              <label>County</label><br/>
              {state.billingCounty}
            </Col>
          </Row>
          <Row>
            <Col sm="5">
              <label>State</label><br/>
              {state.billingState}
            </Col>
            <Col sm="4">
              <label>Zip</label><br/>
              {state.billingZip}
            </Col>
          </Row>
        </CardBody>
      </Card>
      <br/>
      <div className="align-right">
        <Button color="warning">Finish</Button>
      </div>
    </form>
  </div>

  if(state.currentStep===0)
    activeStep = step1;
  else if(state.currentStep===1)
    activeStep = step2;
  else if(state.currentStep===2)
    activeStep = step3;


  return <div className="my-well">
    <Row>
      <Col sm="8">
        <div className="page-title">
          <i className="fa fa-reorder"></i>&nbsp;New appraiser
        </div>
        <NavLink to={"/appraisers"}>Back to appraiser list</NavLink>
      </Col>
      <Col sm="4">

      </Col>
    </Row>
    <div className="my-divider"></div>
    <MyFormStep steps = {state.steps} currentStep = {state.currentStep} updateStep = {updateStep} maxStep={state.maxStep}/>
    <br/>
    {activeStep}
  </div>;
}


export default NewAppraiser;
