//Author Sooyoung Kim
//Date Sep 15, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator} from '../../util/util';
import React, {useReducer, useEffect} from 'react';
import MySelect from '../util/my-select';
import {Input} from 'reactstrap';

//initialize the state
const initialState = {
  comment:'',
  commentForBid:'',
  templateName:'',
  commentTemplates:[],
};

//reducer function that perform state update
const reducer = getReducer();


const TextArea  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getCommentTemplates();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const onChangeTextArea = (e) => {
    setState({commentForBid:e.target.value});
  }

  const commentTemplatesOnChange = (id) => {
    let template;

    for(let i=0;i<state.commentTemplates.length;i++){
      if(state.commentTemplates[i].ID===id){
        template = state.commentTemplates[i];
        break;
      }
    }

    if(template){
        setState({commentForBid:template.template, templateName:id});
        props.onChange(template.template);
    }
  }


  //API call
  //get a list of comment templates
  const getCommentTemplates = () => {
    let callBack = apiCallBack([{state:'commentTemplates', key:'data'}]);
	  httpGet('template/get/'+props.id, '', 'Oops, something went wrong and could not load your comment template.', callBack);
  }

  let placeholder = props.placeholder? props.placeholder:"Message for the bid email to selected appraisers.";

  //render
  return <div>
    <Input type="textarea" placeholder={placeholder} value={state.commentForBid} onChange={(e)=>{props.onChange(e.target.value);onChangeTextArea(e)}} className="non-editable-textarea yellow-background-textarea margin-bottom" rows="10" name="text"></Input>
    <br/>
    <MySelect
      type="select"
      selectIsClearable={true}
      value={state.templateName}
      onChange={(v)=>{commentTemplatesOnChange(v)}}
      options={state.commentTemplates.map((template)=>{
        return {label:template.name, value:template.ID};
      })}
    />
  </div>;
}


export default TextArea;
