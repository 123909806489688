//Author June Leow
//Date 04/18/2023
import {Col, Row, Table} from 'reactstrap';
import {NavLink} from 'react-router-dom';
import React, {useEffect} from 'react';

const Support  = (props)=>{
  const controller = new AbortController();

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  //render
  return <div className="my-well">
        <Row>
          <Col sm="6">
            <div className="page-title">
              <i className="fa fa-reorder"></i>&nbsp;Settings
            </div>
          </Col>
          <Col sm="6">

          </Col>
        </Row>
        <div className="my-divider"></div>
        <Table className="table table-striped">
          <tbody>
            <tr>
              <td>
                <NavLink to="/support/accounting/new">
                  <i className="fa fa-plus green-color"/> New Support ticket (Accounting)
                </NavLink>
                <br/>
                Submit a new support ticket regarding accounting/billing.
              </td>
            </tr>
            <tr>
              <td>
                <NavLink to="/support/new">
                  <i className="fa fa-plus green-color"/> New Support ticket
                </NavLink>
                <br/>
                Submit a new support ticket regarding an issue/problem/error/glitch/suggestion/enhancement needed.
              </td>
            </tr>
            <tr>
              <td>
                <NavLink to="/support/accounting/ticket">
                  <i className="fa fa-receipt gray-color"/> Support Ticket Queue (Accounting)
                </NavLink>
                <br/>
                View a list of submitted support ticket.
              </td>
            </tr>
            <tr>
              <td>
                <NavLink to="/support/ticket">
                  <i className="fa fa-server gray-color"/> Support Ticket Queue
                </NavLink>
                <br/>
                View a list of submitted support ticket.
              </td>
            </tr>
          </tbody>
        </Table>
      </div>;
}

export default Support;
