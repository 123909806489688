//Author Sooyoung Kim
//Date July 24, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, deleteAPICallGenerator, callBackGenerator, sliceFromArray, pushToArray, formatNumber} from '../../util/util';
import {Input, Button, Row, Col, Table, Modal, ModalHeader, ModalBody,Card, CardHeader, CardBody, TabPane, TabContent, Label, NavItem, Nav, NavLink as NavLinkL} from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import MyXEditable from '../util/my-xeditable';
import {NavLink} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';
import {showMessage} from '../../util/util';
import MySelect from './../util/my-select';
import MyDropzone from './../util/my-dropzone';
import ReportFilter from './../report/report-filter';
import './appraiser.css';

//initialize the state
const initialState = {
  activeTab:'1',
  exclusions:[],
  limit: 50,
  offset: 0,
  hasMoreExclusions: true,
  loading: false,
  sort: 'first_name',
  order: 'ASC',
  status: '',
  states:[],
  clients:[],
  targetClients:[],
  jumboOnly:false,
  totalCount:0,

  //for search
  name:'',
  company_name:'',
  email:'',
  state:'',
  county:'',
  city:'',

  dropDownOpen: false,
  crossCheckDropDownOpen: false,
  selectedExclusion:{
    targets:[],
    exceptions:[],
    investors:[],
    comments:[]
  },

  newTarget:'',
  newException:'',
  newInvestor:'',
  newComment:'',
  newLoanType:'all',
  loanTypeDropDownOpen: false,
  clickedClient:[],
  updatedLoanType:'unknown',

  passiveName:'',
  passiveLicense:'',
  passiveCity:'',
  passiveState:'',
  newPassiveCity:'',
  newPassiveState:'Alabama',
  newPassiveFirstName:'',
  newPassiveLastName:'',
  passiveDropDownOpen:false,
  passiveExclusions:[],
  passiveSort:'first_name',
  passiveOrder:'ASC',
  passiveHasMoreExclusions:true,
  passiveOffset:0,
  passiveLoading:false,
  passiveTotalCount:0,

  columnHeaders:[],
  result:[],
  toUploadFiles:[],
  request:[],
  crossCheckActiveTab:'1',
  crossCheckResult:[],
  crossCheckStep:'1'
};

//reducer function that perform state update
const reducer = getReducer();


const AppraiserExclusionaryList  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getAllClientProfiles();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    if(state.exclusions.length<=0 && state.hasMoreExclusions){
      loadMore();
    }

    if(state.passiveExclusions.length<=0 && state.passiveHasMoreExclusions){
      passiveLoadMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state]);

  //non API call but simpyl manage state
  const changeLoanType = (target) => {
    setState({clickedClient:target, updatedLoanType:target.loan_type}, toggleChangeLoanType);
  }

  const toggleChangeLoanType = () => {
    setState({loanTypeDropDownOpen:!state.loanTypeDropDownOpen})
  }

  const modifyTargetClient = (targetClient) => {
    let filteredClients = state.targetClients.filter(
      (client)=>{
        return client.ID!==targetClient.ID;
      }
    )


    if(filteredClients.length===state.targetClients.length){
      filteredClients.push(targetClient);
    }

    setState({targetClients:filteredClients});
  }

  //remove a specific file from the toUpload list.
  const removeToUploadFile = (preview) => {
    let toRemoveIndex = -1;
    for(let i=0;i<state.toUploadFiles.length;i++){
      if(state.toUploadFiles[i].preview===preview){
        toRemoveIndex = i;
        break;
      }
    }

    if(toRemoveIndex!==-1){
      let newFiles = state.toUploadFiles.slice();
      newFiles.splice(toRemoveIndex,1);

      setState({toUploadFiles:newFiles, result:[], crossCheckStep:'1'});
    }
  }

  const updateRequest = (index, property, value) => {
    let request = state.request.slice();

    for(let i=0;i<request.length;i++){
      if(request[i].index===index){
        let obj = Object.assign({}, request[i]);
        obj[property] = value;
        request[i] = obj;
        break;
      }
    }

    setState({request:request});
  }

  //toggle function
  const crossCheckActiveTabToggle = (tab) => {
    setState({crossCheckActiveTab:tab});
  }

  const modifyRequest = (index) => {
    let filteredRequest = state.request.filter(
      (request)=>{
        return request.index !==index;
      }
    );

    if(filteredRequest.length===state.request.length){
      let tmp = {};
      tmp.index = index;
      tmp.license = 0;
      tmp.name = 1;
      tmp.state = 2;

      filteredRequest.push(tmp);
    }

    setState({request:filteredRequest});
  }

  const getColumnHeaders = (index) => {
    let columnHeaders = state.columnHeaders.slice();
    console.log(index)
    console.log(columnHeaders)
    for(let i=0;i<columnHeaders.length;i++){
      if(columnHeaders[i].index===index)
        return columnHeaders[i].headers;
    }

    return [];
  }

  const getSheet = (index) => {
    let request = state.request.slice();

    for(let i=0;i<request.length;i++){
      if(request[i].index===index)
        return request[i];
    }

    return null;
  }

  const parentCallBack = (ID, field, value) => {
    console.log(ID, field, value);
    let passiveExclusions = state.passiveExclusions.slice();

    for(let i=0;i<passiveExclusions.length;i++){
      if(passiveExclusions[i].ID===ID){
        passiveExclusions[i][field] = value;
        break;
      }
    }

    setState({passiveExclusions:passiveExclusions});
  }

  const toggleDropDown = () => {
    setState({dropDownOpen:!state.dropDownOpen});
  }

  const crossCheckToggleDropDown = () => {
    setState({crossCheckDropDownOpen:!state.crossCheckDropDownOpen});
  }

  const togglePassiveDropDown = () => {
    setState({passiveDropDownOpen:!state.passiveDropDownOpen});
  }

  const selectExclusion = (exclusion) => {
    setState({selectedExclusion:exclusion});
    toggleDropDown();
  }

  //function trigger for dropzone react.
  //this function contians two list of files, the accepted and rejected file per the configuration
  const onDrop = (acceptedFiles, rejectedFiles) => {
    let existingFiles = [];

    if(acceptedFiles.length>0)
      existingFiles.push(acceptedFiles[0]);
    setState({toUploadFiles: existingFiles, result:[], crossCheckStep:'1'});
  }

  //click handler that switch the state of sorting order then call the function to refresh the list
  //to achieve the "sorting"
  const columnClickHandler = (col) => {
    if(state.sort===col){
      if(state.order==='ASC')
        setState({order:'DESC'});
      else
        setState({order:'ASC'});
    }
    else
      setState({sort:col});
    refreshList();
  }

  const passiveColumnClickHandler = (col) => {
    if(state.passiveSort===col){
      if(state.passiveOrder==='ASC')
        setState({passiveOrder:'DESC'});
      else
        setState({passiveOrder:'ASC'});
    }
    else
      setState({passiveSort:col});
    passiveRefreshList();
  }

  //this function refresh the list of orders pipeline
  const refreshList = () => {
    setState({exclusions:[], totalCount:0, hasMoreExclusions:true, offset:0});
  }
  
  const passiveRefreshList = () => {
    setState({
      targetClients:[], 
      jumboOnly:false,
      passiveExclusions:[],
      passiveTotalCount:0,
      passiveHasMoreExclusions:true,
      passiveOffset:0
    });
  }

  //this function render the sort icon at each column of the table
  const renderSortIcon = (col) => {
    if(state.sort===col){
      if(state.order==='ASC')
        return <i className="red-color fa fa-arrow-down"></i>
      else
        return <i className="red-color fa fa-arrow-up"></i>
    }
  }

  const passiveRenderSortIcon = (col) => {
    if(state.passiveSort===col){
      if(state.passiveOrder==='ASC')
        return <i className="red-color fa fa-arrow-down"></i>
      else
        return <i className="red-color fa fa-arrow-up"></i>
    }
  }

  //render function for infinite scroller
  const renderExclusions = () => {
    return (
      <div>
        <Table className="table table-striped">
          <thead>
            <tr>
              <th className="cursor-pointer" width="13%" onClick={()=>columnClickHandler('first_name')}><i className="fa fa-user"></i>&nbsp;Name {renderSortIcon('first_name')}</th>
              <th className="cursor-pointer" width="8%" onClick={()=>columnClickHandler('state')}><i className="fa fa-map-marker"></i>&nbsp;State {renderSortIcon('state')}</th>
              <th className="cursor-pointer" width="8%" onClick={()=>columnClickHandler('county')}><i className="fa fa-map-marker"></i>&nbsp;County {renderSortIcon('county')}</th>
              <th className="cursor-pointer" width="8%" onClick={()=>columnClickHandler('city')}><i className="fa fa-map-marker"></i>&nbsp;City {renderSortIcon('city')}</th>
              <th width="13%">Targets</th>
              <th width="15%">Exceptions</th>
              <th width="10%">Investors</th>
              <th width="6%">Discipline Action</th>
              <th width="19%">Comments</th>
            </tr>
          </thead>
          <tbody>
          {
            state.exclusions.map(
              (exclusion)=>{
                //console.log(exclusion);
                let targets , exceptions, investors, comments;

                if(exclusion.investors)
                investors = exclusion.investors.map(
                  (investor,index)=>{
                    return <div className="exclusionary-list-inline-border header-color" key={index}>
                      {investor.investor}
                    </div>
                  }
                );

                if(exclusion.comments)
                comments = exclusion.comments.map(
                  (comment,index)=>{
                    return <div className="exclusionary-list-inline-border" key={index}>
                      {comment.comment}
                    </div>
                  }
                );

                if(exclusion.targets){
                  targets = exclusion.targets.map(
                    (target,index)=>{
                      let loanTypeIcon;
                      if(target.loan_type === 'jumbo'){
                        loanTypeIcon = <div className="display-inline dark-red-color"><span className="my-badge2">{target.loan_type.toUpperCase()}</span> </div>;
                      }else if(target.loan_type === 'all'){
                        loanTypeIcon = <div className="display-inline dark-blue-color"><span className="my-badge4">{target.loan_type.toUpperCase()}</span></div>;
                      }
                      return <div className="exclusionary-list-inline-border header-color" key={index}>
                        {target.company}&nbsp;{loanTypeIcon}
                      </div>
                    }
                  );
                }

                if(exclusion.exceptions)
                exceptions = exclusion.exceptions.map(
                  (exception,index)=>{
                    return <div className="exclusionary-list-inline-border header-color" key={index}>
                      {exception.company}
                    </div>
                  }
                );


                return (
                  <tr key={exclusion.ID}>
                    <td><i className="fa fa-cogs cursor-pointer" onClick={(e)=>selectExclusion(exclusion)}></i> <i className="fa fa-times red-color cursor-pointer"></i> <NavLink to={"/appraiser/"+exclusion.appraiser_fk}>{exclusion.first_name} {exclusion.last_name}</NavLink></td>
                    <td>{exclusion.state}</td>
                    <td>{exclusion.county}</td>
                    <td>{exclusion.city}</td>
                    <td>
                      {
                        targets
                      }
                    </td>
                    <td>
                      {
                        exceptions
                      }
                    </td>
                    <td>
                      {
                        investors
                      }
                    </td>
                    <td>
                      <MyXEditable
                        type="select"
                        value={exclusion.discipline_action}
                        opt={[{key:'unverified',value:'Not Yet Verified'},{key:'no',value:'No Discipline Action'},{key:'yes',value:'Has Discipline Action'}]}
                        updateFunc={
                          generateUpdateFunction('appraiser/exclusionary/update',[{field:'field',value:'discipline_action'},{field:'ID',value:exclusion.ID}],'Exclusionary list updated.', 'Failed to update exclusionary list, please try again later.')
                        }
                      />
                    </td>
                    <td>
                      {
                        comments
                      }
                    </td>
                  </tr>
                );
              }
            )
          }
          </tbody>
        </Table>
      </div>
    )
  }

  //render function for infinite scroller
  const renderPassiveExclusions = () => {
    return (
      <div>
        <Table className="table table-striped">
          <thead>
            <tr>
              <th width="5%">&nbsp;</th>
              <th className="cursor-pointer" width="18%" onClick={()=>passiveColumnClickHandler('first_name')}><i className="fa fa-user"></i>&nbsp;First Name {passiveRenderSortIcon('first_name')}</th>
              <th className="cursor-pointer" width="15%" onClick={()=>passiveColumnClickHandler('last_name')}><i className="fa fa-user"></i>&nbsp;Last Name {passiveRenderSortIcon('last_name')}</th>
              <th className="cursor-pointer" width="15%" onClick={()=>passiveColumnClickHandler('city')}><i className="fa fa-map-marker"></i>&nbsp;City {passiveRenderSortIcon('city')}</th>
              <th className="cursor-pointer" width="15%" onClick={()=>passiveColumnClickHandler('state')}><i className="fa fa-map-marker"></i>&nbsp;State {passiveRenderSortIcon('state')}</th>
              <th width="17%">Targets</th>
              <th className="cursor-pointer" width="15%" onClick={()=>passiveColumnClickHandler('license')}>License {passiveRenderSortIcon('license')}</th>
            </tr>
          </thead>
          <tbody>
          {
            state.passiveExclusions.map(
              (exclusion)=>{
                let targets;

                if(exclusion.targets){
                  targets = exclusion.targets.map(
                    (target,index)=>{
                      let loanTypeIcon;
                      if(target.loan_type === 'jumbo'){
                        loanTypeIcon = <div className="display-inline dark-red-color"><span className="my-badge2">{target.loan_type.toUpperCase()}</span> </div>;
                      }else if(target.loan_type === 'all'){
                        loanTypeIcon = <div className="display-inline dark-blue-color"><span className="my-badge4">{target.loan_type.toUpperCase()}</span></div>;
                      }

                      return <div className="exclusionary-list-inline-border header-color" key={index}>
                        {target.company} &nbsp; {loanTypeIcon}
                      </div>
                    }
                  );
                }

                return (
                  <tr key={exclusion.ID}>
                    <td><i className="fa fa-times red-color cursor-pointer"></i></td>
                    <td>
                      <MyXEditable
                        type="text"
                        value={exclusion.first_name}
                        updateFunc={
                          generateUpdateFunction('appraiser/inactive/exclusionaryList/update',[{field:'field',value:'first_name'},{field:'ID',value:exclusion.ID}],'Passive exclusionary list updated.', 'Failed to update passive exclusionary list, please try again later.', parentCallBack)
                        }
                      />
                    </td>
                    <td>
                      <MyXEditable
                        type="text"
                        value={exclusion.last_name}
                        updateFunc={
                          generateUpdateFunction('appraiser/inactive/exclusionaryList/update',[{field:'field',value:'last_name'},{field:'ID',value:exclusion.ID}],'Passive exclusionary list updated.', 'Failed to update passive exclusionary list, please try again later.', parentCallBack)
                        }
                      />
                    </td>
                    <td><MyXEditable
                      type="text"
                      value={exclusion.city}
                      updateFunc={
                        generateUpdateFunction('appraiser/inactive/exclusionaryList/update',[{field:'field',value:'city'},{field:'ID',value:exclusion.ID}],'Passive exclusionary list updated.', 'Failed to update passive exclusionary list, please try again later.', parentCallBack)
                      }
                    /></td>
                    <td><MyXEditable
                      type="text"
                      value={exclusion.state}
                      updateFunc={
                        generateUpdateFunction('appraiser/inactive/exclusionaryList/update',[{field:'field',value:'state'},{field:'ID',value:exclusion.ID}],'Passive exclusionary list updated.', 'Failed to update passive exclusionary list, please try again later.', parentCallBack)
                      }
                    /></td>
                    <td>{targets}</td>
                    <td><MyXEditable
                      type="text"
                      value={exclusion.license}
                      updateFunc={
                        generateUpdateFunction('appraiser/inactive/exclusionaryList/update',[{field:'field',value:'license'},{field:'ID',value:exclusion.ID}],'Passive exclusionary list updated.', 'Failed to update passive exclusionary list, please try again later.', parentCallBack)
                      }
                    /></td>
                  </tr>
                );
              }
            )
          }
          </tbody>
        </Table>
      </div>
    )
  }

  //API call
  //generate a function that do the update on editable
  const generateUpdateFunction = (url, parameters, successMessage, failedMessage, parentCallBack=null) => {
    return (newValue, callBack, errorCallBack)=>{
      //add in the new value into parameters

      parameters.push({field:'value',value:newValue});
      let promise = httpPut(url,parameters,successMessage,failedMessage, callBack, errorCallBack);
      promise.then(
        (response)=>{
          let field;
          let value;
          let ID;

          for(let i=0;i<parameters.length;i++){
            if(parameters[i].field==='field')
              field = parameters[i].value;
            else if(parameters[i].field==='value')
              value = parameters[i].value;
            else if(parameters[i].field==='ID')
              ID = parameters[i].value;
          }
          if(parentCallBack!==null){
            parentCallBack(ID, field, value);
          }
        }
      );
    }
  };

  //function that fire when the infinite scroll reach bottom
  const loadMore = () => {
    //do not load if there is no more conditions or it's loading data
    if(state.hasMoreExclusions&&!state.loading){
      //set loading equals to true so it won't fire off before we are done
      setState({loading:true});

      let company_name = encodeURIComponent(state.company_name.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
      let name = encodeURIComponent(state.name.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
      let email = encodeURIComponent(state.email.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
      let city = encodeURIComponent(state.city.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
      let county = encodeURIComponent(state.county.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
      let url = 'appraiser/exclusionaryList/get/limit='+state.limit+'&offset='+state.offset+'&order='+state.order+'&sort='+state.sort+'&status='+state.status+'&name='+name+'&company_name='+company_name+'&email='+email+'&state='+state.state+'&county='+county+'&city='+city;

      //callback handler that update the state when http request return
      let callBack = (response)=>{console.log(response);
        let code = response.data?response.data.code:undefined;

        if(code!=='00'){
          setState({hasMoreExclusions:false});
        }
        else{
          console.log(response.data.data);
          let newExclusions = response.data.data;
          let hasMoreExclusions = true;
          let newOffset = state.offset;
          let totalCount = response.data.count;

          //if http request return empty then no more results, end of list
          if(newExclusions.length<=0){
            hasMoreExclusions = false;
          }
          else{
            //increment the offset
            newOffset = state.offset + 1;
          }

          //concat the current array of announcement
          if(state.exclusions.length>0){
            let temp = [...state.exclusions,...newExclusions];

            setState({exclusions:temp});
          }
          else
            setState({exclusions:newExclusions});

          setState({hasMoreExclusions:hasMoreExclusions, offset:newOffset, totalCount:totalCount});
        }
      };
      callBack = callBack.bind(this);

      //error handler when the http request return with error
      let errorCallBack = apiCallBack([{state:'hasMoreExclusions', value:false}]);

      //collect the promise and wait for it to finish performing it's task
      let promises = httpGet(url, '','Oops, something went wrong and could not load exclusions. Please try again later.', callBack, errorCallBack);
      promises
        .then(
          function(result){
            //set loading equals to false so the function could be fire off once again
            setState({loading:false});
          }
        );
    }
  }

  const passiveLoadMore = () => {
    //do not load if there is no more conditions or it's loading data
    if(state.passiveHasMoreExclusions&&!state.passiveLoading){
      //set loading equals to true so it won't fire off before we are done
      setState({passiveLoading:true});

      let name = encodeURIComponent(state.passiveName.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
      let city = encodeURIComponent(state.passiveCity.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
      let url = 'appraiser/inactive/exclusionaryList/get/limit='+state.limit+'&offset='+state.passiveOffset+'&order='+state.passiveOrder+'&sort='+state.passiveSort+'&name='+name+'&license='+state.passiveLicense+'&state='+state.passiveState+'&city='+city;

      //callback handler that update the state when http request return
      let callBack = (response)=>{console.log(response);
        let code = response.data?response.data.code:undefined;

        if(code!=='00'){
          setState({passiveHasMoreExclusions:false});
        }
        else{
          console.log(response.data.data);
          let newExclusions = response.data.data;
          let hasMoreExclusions = true;
          let newOffset = state.passiveOffset;
          let totalCount = response.data.count;

          //if http request return empty then no more results, end of list
          if(newExclusions.length<=0){
            hasMoreExclusions = false;
          }
          else{
            //increment the offset
            newOffset = state.passiveOffset + 1;
          }

          //concat the current array of announcement
          if(state.passiveExclusions.length>0){
            let temp = [...state.passiveExclusions,...newExclusions];

            setState({passiveExclusions:temp});
          }
          else
            setState({passiveExclusions:newExclusions});

          setState({passiveHasMoreExclusions:hasMoreExclusions, passiveOffset:newOffset, passiveTotalCount:totalCount});
        }
      };
      callBack = callBack.bind(this);

      //error handler when the http request return with error
      let errorCallBack = apiCallBack([{state:'passiveHasMoreExclusions', value:false}]);

      //collect the promise and wait for it to finish performing it's task
      let promises = httpGet(url, '','Oops, something went wrong and could not load exclusions. Please try again later.', callBack, errorCallBack);
      promises
        .then(
          function(result){
            //set loading equals to false so the function could be fire off once again
            setState({passiveLoading:false});
          }
        );
    }
  }

  const removeInvestor = (id) => {
    let list = sliceFromArray(state.exclusions, ['ID','ID'],[state.selectedExclusion.ID, id], ['investors']);
    let list2 = sliceFromArray(state.selectedExclusion.investors,'ID', id);
    let callBack = apiCallBack([{state:'exclusions', value:list}, {state:'selectedExclusion', value:Object.assign({},state.selectedExclusion,{investors:list2})}]);

    httpDelete('appraiser/exclusionaryList/investor/'+id,'Investor removed.','Oops, something went wrong and could not delete the investor. Please try again later.', callBack);
  }

  const removeException = (id) => {
    let list = sliceFromArray(state.exclusions, ['ID','ID'],[state.selectedExclusion.ID, id], ['exceptions']);
    let list2 = sliceFromArray(state.selectedExclusion.exceptions,'ID', id);
    let callBack = apiCallBack([{state:'exclusions', value:list}, {state:'selectedExclusion', value:Object.assign({},state.selectedExclusion,{exceptions:list2})}]);

    httpDelete('appraiser/exclusionaryList/exception/'+id,'Investor removed.','Oops, something went wrong and could not delete the exception. Please try again later.', callBack);
  }

  const removeTarget = (e, id) => {
    e.stopPropagation();
    let list = sliceFromArray(state.exclusions, ['ID','ID'],[state.selectedExclusion.ID, id], ['targets']);
    let list2 = sliceFromArray(state.selectedExclusion.targets,'ID', id);
    let callBack = apiCallBack([{state:'exclusions', value:list}, {state:'selectedExclusion', value:Object.assign({},state.selectedExclusion,{targets:list2})}]);

    httpDelete('appraiser/exclusionaryList/target/'+id,'Investor removed.','Oops, something went wrong and could not delete the target. Please try again later.', callBack);
  }

  const createNewPassiveExclusionaryList = () => {
    let targetClients = state.targetClients.slice();
    let clients = [];

    for(let i=0;i<targetClients.length;i++)
      clients.push(targetClients[i].ID);

    let loanType = 'all';
    if(state.jumboOnly){
      loanType = 'jumbo';
    }

    let parameters = [
      {
        field:'firstName',
        value:state.newPassiveFirstName
      },
      {
        field:'lastName',
        value:state.newPassiveLastName
      },
      {
        field:'city',
        value:state.newPassiveCity
      },
      {
        field:'state',
        value:state.newPassiveState
      },
      {
        field:'clients',
        value:clients
      },
      {
        field:'loanType',
        value:loanType
      },
    ];

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        passiveRefreshList();
        togglePassiveDropDown();
      }
    };
    callBack = callBack.bind(this);

    httpPost('appraiser/inactive/exclusionaryList/create', parameters, 'Passive exclusionary candidate added.', 'Oops, something went wrong and could add the passive exclusionary candidate. Please try again later.', callBack);
  }

  const updateLoanType = () => {
    let parameters = [
      {
        field:'ID',
        value:state.clickedClient.ID
      },
      {
        field:'loanType',
        value:state.updatedLoanType
      }
    ];

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let exclusions = state.exclusions.slice();
        for(let i=0;i<exclusions.length;i++){
          if(exclusions[i].ID===state.selectedExclusion.ID){
            let t = exclusions[i].targets.slice()
            for(let j=0;j<t.length;j++){
              if(t[j].ID===state.clickedClient.ID){
                t[j].loan_type = state.updatedLoanType;
                 break;
              }
            }
            exclusions[i].targets = t;
            break;
          }
        }
        setState({exclusions:exclusions});
        toggleChangeLoanType();
      }
    };
    callBack = callBack.bind(this);

    httpPut('appraiser/exclusionary/loanType/update',parameters,'Loan type updated successfully.','Oops, something went wrong and cannot update the loan type. Please try again later.', callBack);
  }

  const createNewTarget = () => {
    let parameters = [
      {
        field:'exclusionaryFk',
        value:state.selectedExclusion.ID
      },
      {
        field:'clientFk',
        value:state.newTarget
      },
      {
        field:'loanType',
        value:state.newLoanType
      }
    ];

    let callBack = (response)=>{console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let exclusions = state.exclusions.slice();
        for(let i=0;i<exclusions.length;i++){
          if(exclusions[i].ID===state.selectedExclusion.ID){
            exclusions[i].targets = pushToArray(exclusions[i].targets, response.data.data);
            break;
          }
        }
        //update the selected exclusions
        let selectedExclusion = Object.assign({},state.selectedExclusion);
        selectedExclusion.targets = pushToArray(selectedExclusion.targets, response.data.data);

        setState({exclusions:exclusions});
        //setState({selectedExclusion:selectedExclusion});
      }
    };
    callBack = callBack.bind(this);

    httpPost('appraiser/exclusionaryList/target/create', parameters, 'Target added.', 'Oops, something went wrong and could add the target. Please try again later.', callBack);
  }

  const createNewInvestor = () => {
    let parameters = [
      {
        field:'exclusionaryFk',
        value:state.selectedExclusion.ID
      },
      {
        field:'investor',
        value:state.newInvestor
      }
    ];

    let callBack = (response)=>{console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let exclusions = state.exclusions.slice();
        for(let i=0;i<exclusions.length;i++){
          if(exclusions[i].ID===state.selectedExclusion.ID){
            exclusions[i].investors = pushToArray(exclusions[i].investors, response.data.data);
            break;
          }
        }
        //update the selected exclusions
        let selectedExclusion = Object.assign({},state.selectedExclusion);
        selectedExclusion.investors = pushToArray(selectedExclusion.investors, response.data.data);

        setState({exclusions:exclusions});
        //setState({selectedExclusion:selectedExclusion});
      }
    };
    callBack = callBack.bind(this);

    httpPost('appraiser/exclusionaryList/investor/create', parameters, 'New investor added.', 'Oops, something went wrong and could add the investor Please try again later.', callBack);
  }

  const createNewException = () => {
    let parameters = [
      {
        field:'exclusionaryFk',
        value:state.selectedExclusion.ID
      },
      {
        field:'clientFk',
        value:state.newException
      }
    ];

    let callBack = (response)=>{console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let exclusions = state.exclusions.slice();
        for(let i=0;i<exclusions.length;i++){
          if(exclusions[i].ID===state.selectedExclusion.ID){
            exclusions[i].exceptions = pushToArray(exclusions[i].exceptions, response.data.data);
            break;
          }
        }
        //update the selected exclusions
        let selectedExclusion = Object.assign({},state.selectedExclusion);
        selectedExclusion.exceptions = pushToArray(selectedExclusion.exceptions, response.data.data);

        setState({exclusions:exclusions});
        //setState({selectedExclusion:selectedExclusion});
      }
    };
    callBack = callBack.bind(this);

    httpPost('appraiser/exclusionaryList/exception/create', parameters, 'Exception added.', 'Oops, something went wrong and could add the exception. Please try again later.', callBack);
  }

  const createNewComment = () => {
    let parameters = [
      {
        field:'exclusionaryFk',
        value:state.selectedExclusion.ID
      },
      {
        field:'comment',
        value:state.newComment
      },
      {
        field:'category',
        value:'Order'
      }
    ];

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let exclusions = state.exclusions.slice();
        for(let i=0;i<exclusions.length;i++){
          if(exclusions[i].ID===state.selectedExclusion.ID){
            exclusions[i].comments = pushToArray(exclusions[i].comments, response.data.data);
            break;
          }
        }
        //update the selected exclusions
        let selectedExclusion = Object.assign({},state.selectedExclusion);
        selectedExclusion.comments = pushToArray(selectedExclusion.comments, response.data.data);
        setState({exclusions:exclusions});
        //setState({selectedExclusion:selectedExclusion});
      }
    };
    callBack = callBack.bind(this);

    httpPost('appraiser/exclusionaryList/comment/create', parameters, 'Comment added.', 'Oops, something went wrong and could add the comment. Please try again later.', callBack);
  }

  const uploadAll = () => {
    let preCheck = true;
    let errorMessage = '';

    if(state.toUploadFiles.length<=0){
      preCheck = false;
      errorMessage = 'Please upload at least one file.';
    }

    //only proceed when no error
    if(preCheck){
      setState({errorMessage:'Uploading...Please do not close the window.'});

      let fileCompleted = 0;
      for(let i=0;i<state.toUploadFiles.length;i++){
        //skip file that has done upload
        if(state.toUploadFiles[i].status==='Done')
          continue;
        const reader = new FileReader();
        // eslint-disable-next-line no-loop-func
        reader.onload = () => {
            const fileAsBinaryString = reader.result;
            let base64 = btoa(fileAsBinaryString);

            let callBack = (response)=>{
              console.log(response);
              let code = response.data?response.data.code:undefined;

              if(code==='00'){
                setState({result:response.data.data, crossCheckStep:'2'});

                // get cell headers
                let columnHeaders = [];
                for(let i=0;i<response.data.data.length;i++){
                  let rows = response.data.data[i].rows;

                  if(rows.length>0){
                    let cells = rows[0];

                    let tmp = {
                      index:i,
                      headers:[]
                    };
                    for(let j=0;j<cells.length;j++){
                      tmp.headers.push(cells[j]);;
                    }

                    columnHeaders.push(tmp);
                  }
                }

                setState({columnHeaders:columnHeaders});
              }
              fileCompleted++;
              if(fileCompleted>=state.toUploadFiles.length){
                setState({uploadDisabled:false, errorMessage:''});
              }
            };

            callBack = callBack.bind(this);

            let errorCallBack = (error)=>{
              fileCompleted++;

              if(fileCompleted>=state.toUploadFiles.length){
                setState({uploadDisabled:false, errorMessage:''});
              }
            };

            errorCallBack = errorCallBack.bind(this);

            let parameters = [
              {
                field:'file_encoded',
                value:base64
              },
              {
                field:'name',
                value:state.toUploadFiles[i].name
              }
            ];

            httpPost('appraiser/exclusionaryList/upload', parameters, '', 'Oops, something went wrong and could not upload the file "'+state.toUploadFiles[i].name+'". Please try again later.', callBack, errorCallBack);
        };
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => {
          showMessage('error','File upload failed, please try again later.');
        };

        reader.readAsBinaryString(state.toUploadFiles[i]);
      }
      setState({uploadDisabled:false});
    }
    else{
      setState({errorMessage:errorMessage});
      setTimeout(()=>setState({uploadDisabled:false}),1000);
    }
  }

  const sendRequest = () => {
    for(let i=0;i<state.toUploadFiles.length;i++){
      //skip file that has done upload
      if(state.toUploadFiles[i].status==='Done')
        continue;
      const reader = new FileReader();
      reader.onload = () => {
          const fileAsBinaryString = reader.result;
          let base64 = btoa(fileAsBinaryString);

          let parameters = [
            {
              field:'request',
              value:state.request
            },
            {
              field:'file_encoded',
              value:base64
            }
          ];

          let callBack = apiCallBack([{state:'crossCheckResult', key:'data'}, {state:'crossCheckStep', value:'3'}]);
          httpPost('appraiser/exclusionaryList/crosscheck/upload/scrub', parameters, 'Cross check successfully.', 'Oops, something went wrong and could finish the cross check request. Please try again later.', callBack);
      };
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => {
        showMessage('error','File upload failed, please try again later.');
      };

      reader.readAsBinaryString(state.toUploadFiles[i]);
    }
  }

  const getAllClientProfiles = ()=>{
    httpGet('client/get/limit=-1&offset=-1' , '','Oops, something went wrong and could not retrieve client profiles.', apiCallBack([{state:'clients', key:'data'}]));
  }

  //render
  let clients;

  if(state.clients.length>0)
    clients = state.clients.map(
      (client, index)=>{
        return <tr onClick={(e)=>modifyTargetClient(client)} value={client.ID} key={index}><td>{client.company}</td></tr>;
      }
    );

  let toUploadFiles;
  if(state.toUploadFiles.length>0){
    toUploadFiles = state.toUploadFiles.map(
      (file,index)=>{
        return <div key={index}><i className="fa fa-times red-color cursor-pointer" onClick={()=>removeToUploadFile(file.preview)}></i> {file.name}</div>
      }
    );

  }


  let actionButton;
  let content;

  if(state.crossCheckStep==='1'){
    actionButton = <Button color="warning" onClick={()=>{uploadAll()}}><i className="fa fa-check"></i> Upload</Button>;
    content = <div>
      <i>Please upload a spreadsheet to cross check against the exclusionary list</i><br/>
      <div className="small-scroll-container">
        {toUploadFiles}
      </div>
      <MyDropzone onDrop={onDrop}/>
    </div>;
  }
  else if(state.crossCheckStep==='2'){
    actionButton = <Button color="warning" onClick={()=>{sendRequest()}}><i className="fa fa-check"></i> Submit</Button>;

    content = <div>
      <NavLink to="#" onClick={(e)=>setState({crossCheckStep:'1', result:[]})}>Back</NavLink>
      <br/>
      <Nav tabs>
        {
          state.result.map(
            (sheet, index)=>{
              return (
                <NavItem key={index}>
                  <NavLinkL
                    className={"cursor-pointer nav-link "+(state.crossCheckActiveTab === (index+1).toString()?"active":"inactive" )}
                    onClick={() => { crossCheckActiveTabToggle((index+1).toString()); }}
                    to="#"
                  >
                    {sheet.name}
                  </NavLinkL>
                </NavItem>
              )
            }
          )
        }
      </Nav>
      <TabContent activeTab={state.crossCheckActiveTab}>
        {
          state.result.map(
            (sheet, index)=>{
              let sheetObj = getSheet(index);
              console.log(sheetObj);
              let columnArrangement;

              if(sheetObj){

                let columHeaders = getColumnHeaders(index);

                columnArrangement = <div>
                  <Row>
                    <Col sm="4">
                      <label>License #</label>
                    </Col>
                    <Col sm="8">
                      <MySelect
                        type="select"
                        value={sheetObj.license}
                        onChange={(v)=>updateRequest(index, 'license', v)}
                        options={[{label:"",value:""}].concat(columHeaders.map((col, index4)=>{
                          return {label:"Column "+(index4+1) +"- "+col, value:index4};
                        }))}
                        />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="4">
                      <label>Name</label>
                    </Col>
                    <Col sm="8">
                      <MySelect
                        type="select"
                        value={sheetObj.name}
                        onChange={(v)=>updateRequest(index, 'name', v)}
                        options={[{label:"",value:""}].concat(columHeaders.map((col, index4)=>{
                          return {label:"Column "+(index4+1) +"- "+col, value:index4};
                        }))}
                        />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="4">
                      <label>State</label>
                    </Col>
                    <Col sm="8">
                      <MySelect
                        type="select"
                        value={sheetObj.state}
                        onChange={(v)=>updateRequest(index, 'state', v)}
                        options={[{label:"",value:""}].concat(columHeaders.map((col, index4)=>{
                          return {label:"Column "+(index4+1) +"- "+col, value:index4};
                        }))}
                        />
                    </Col>
                  </Row>
                </div>;
              }

              return (
                <TabPane key={index} tabId={(index+1).toString()}>

                  <br/>
                  <div className="padding display-inline" onClick={(e)=>modifyRequest(index)}>
                    <Label check>
                      &nbsp;&nbsp;<Input checked={sheetObj} type="checkbox"/>&nbsp;Cross check this sheet
                    </Label>
                  </div>

                  {columnArrangement}
                  <div className="my-divider">&nbsp;</div>

                  <Table className="table table-striped">
                    <tbody>
                      {
                        sheet.rows.map(
                          (row, index2)=>{
                            return <tr key={index2}>
                              {
                                row.map(
                                  (cell, index3)=>{
                                    return <td colSpan={(sheet.max_cell/row.length)} key={index3}>{cell}</td>
                                  }
                                )
                              }
                            </tr>
                          }
                        )
                      }
                    </tbody>
                  </Table>
                </TabPane>
              )
            }
          )
        }
      </TabContent>
    </div>
  }
  else if(state.crossCheckStep==='3'){
    if(state.crossCheckResult.length>0){
      content = <div>
        <NavLink to="#" onClick={(e)=>setState({crossCheckStep:'1', result:[], crossCheckResult:[]})}>Back</NavLink>
        <br/>
        <div className="mega-large-scroll-container">
          <Table className="table table-striped">
            <thead>
              <tr>
                <th width="5%">Tab #</th>
                <th width="5%">Row #</th>
                <th width="10%">Appraiser</th>
                <th width="13%">Status</th>
                <th width="14%">State</th>
                <th width="13%">License #</th>
                <th width="20%">Exclusionary List Target</th>
                <th width="20%">Exclusionary List Exception</th>
              </tr>
            </thead>
            <tbody>
              {
                state.crossCheckResult.map(
                  (appraiser, index)=>{
                    let name = <NavLink to={"/appraiser/"+appraiser.ID}>{appraiser.first_name+' '+appraiser.last_name}</NavLink>;

                    if(appraiser.ID==='0')
                      name = <div>{"Not On Panel " +appraiser.first_name+' '+appraiser.last_name}</div>
                    return <tr key={index}>
                      <td>{appraiser.tab_index}</td>
                      <td>{appraiser.row_index}</td>
                      <td>{name}</td>
                      <td>{appraiser.status}</td>
                      <td>{appraiser.state}</td>
                      <td>{appraiser.license_number}</td>
                      <td>
                        {
                          appraiser.targets.map(
                            (target, index2)=>{
                              return <div className="exclusionary-list-inline-border header-color">
                                {target.company}
                              </div>
                            }
                          )
                        }
                      </td>
                      <td>
                        {
                          appraiser.exceptions.map(
                            (exception, index2)=>{
                              return <div className="exclusionary-list-inline-border header-color">
                                {exception.company}
                              </div>
                            }
                          )
                        }
                      </td>
                    </tr>
                  }
                )
              }
            </tbody>
          </Table>
        </div>
      </div>;
    }
    else{
      content = <div>
        <NavLink to="#" onClick={(e)=>setState({crossCheckStep:'1', result:[], crossCheckResult:[]})}>Back</NavLink>
        <br/><br/><br/><br/>
        <center>
        <b>** No result found **</b>
      </center></div>
    }
  }

  let exclusionaryFiltersConfig1 = [
    {id:'custom',value:state.name, updateFunc:(v)=>setState({name:v}), width:'3', label:'Name'},
    {id:'custom',value:state.company_name, updateFunc:(v)=>setState({company_name:v}), width:'3', label:'Company Name'},
    {id:'custom',value:state.email, updateFunc:(v)=>setState({email:v}), width:'3', label:'Email'},
    {id:'state',value:state.state, updateFunc:(v)=>setState({state:v}), width:'3'},
    {id:'county',value:state.county, updateFunc:(v)=>setState({county:v}), width:'3'},
    {id:'city',value:state.city, updateFunc:(v)=>setState({city:v}), width:'3'},
    {id:'button',value:'Submit', updateFunc:()=>refreshList(), width:'6', className:"align-right", color:"warning"},
  ];

  let exclusionaryFiltersConfig2 = [
    {id:'custom',value:state.passiveName, updateFunc:(v)=>setState({passiveName:v}), width:'3', label:'Name'},
    {id:'custom',value:state.passiveLicense, updateFunc:(v)=>setState({passiveLicense:v}), width:'2', label:'License'},
    {id:'state',value:state.passiveState, updateFunc:(v)=>setState({passiveState:v}), width:'3'},
    {id:'city',value:state.passiveCity, updateFunc:(v)=>setState({passiveCity:v}), width:'2'},
    {id:'button',value:'Submit', updateFunc:()=>passiveRefreshList(), width:'2', className:"align-right", color:"warning"},
  ];

  let exclusionaryFiltersConfig3 = [
    {id:'custom',value:state.newPassiveFirstName, updateFunc:(v)=>setState({newPassiveFirstName:v}), width:'6', label:'First Name'},
    {id:'custom',value:state.newPassiveLastName, updateFunc:(v)=>setState({newPassiveLastName:v}), width:'6', label:'Last Name'},
    {id:'state',value:state.newPassiveState, updateFunc:(v)=>setState({newPassiveState:v}), width:'6'},
    {id:'city',value:state.newPassiveCity, updateFunc:(v)=>setState({newPassiveCity:v}), width:'6'},
  ];

  return <div>
    <Nav tabs>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.activeTab === '1'?"active":"inactive" )}
          onClick={() => { setState({activeTab:'1'}) }}
          to="#"
        >
          Active Exclusionary List
        </NavLinkL>
      </NavItem>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.activeTab === '2'?"active":"inactive" ) }
          onClick={() => { setState({activeTab:'2'}) }}
          to="#"
        >
          Passive Exclusionary List
        </NavLinkL>
      </NavItem>
    </Nav>
    <TabContent activeTab={state.activeTab}>
      <TabPane tabId="1">
        <div style={{minHeight:'100%',height:'100%',maxHeight:'100%',overflowY:'auto',overflowX:'hidden'}}>
          <InfiniteScroll
            next={loadMore}
            dataLength={state.exclusions.length}
            hasMore={state.hasMoreExclusions}
            loader={<div key="nill" className="loader"><center>Loading more exclusionary...</center></div>}
            initialLoad = {true}
            className="my-well"
            scrollableTarget="contentContainer"
          >
            <Modal className="my-modal-wide" isOpen={state.dropDownOpen} toggle={toggleDropDown} >
              <ModalHeader hidden={true} toggle={toggleDropDown}></ModalHeader>
              <ModalBody>
                <center>
                  <i className="fa fa-warning"></i> Configure Exclusionary Profile - {state.selectedExclusion.first_name+' '+state.selectedExclusion.last_name}
                </center>
                <br/><br/>
                <Row>
                  <Col sm="6">
                    <Card>
                      <CardHeader className="gray-color">Targets</CardHeader>
                      <CardBody>

                        <div className="medium-scroll-container">
                          {
                            state.selectedExclusion.targets.map(
                              (target,index)=>{
                                let loanTypeIcon;
                                if(target.loan_type === 'jumbo'){
                                  loanTypeIcon = <div className="display-inline dark-red-color"><span className="my-badge2">{target.loan_type.toUpperCase()}</span> </div>;
                                }else if(target.loan_type === 'all'){
                                  loanTypeIcon = <div className="display-inline dark-blue-color"><span className="my-badge4">{target.loan_type.toUpperCase()}</span></div>;
                                }
                                return <div className="exclusionary-list-inline-border header-color cursor-pointer" key={index} onClick={(e)=>changeLoanType(target)}>
                                  <i className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeTarget(e, target.ID)}></i>&nbsp;
                                  {target.company}&nbsp;{loanTypeIcon}
                                </div>
                              }
                            )
                          }
                        </div>
                        <Row>
                          <Col sm="6">
                            <label><i className="fa fa-plus green-color"></i> New Target</label>
                            <MySelect
                              modal={true}
                              type="select"
                              value={state.newTarget}
                              onChange={(v)=>setState({newTarget:v})}
                              options={[{label:"All Lender",value:0}].concat(state.clients.map((client)=>{
                                return {label:client.company, value:client.ID};
                              }))}
                              />
                          </Col>
                          <Col sm="4">
                            <label>Loan Type</label>
                            <MySelect
                              modal={true}
                              type="select"
                              value={state.newLoanType}
                              onChange={(v)=>setState({newLoanType:v})}
                              options={[{label:"Unknown",value:"unknown"},{label:"All",value:"all"},{label:"Jumbo Only",value:"jumbo"}]}
                              />
                          </Col>
                          <Col sm="2">
                            <br/>
                            <Button color="warning" onClick={(e)=>createNewTarget()}>Add</Button>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col sm="6">

                    <Card>
                      <CardHeader className="gray-color">Exceptions</CardHeader>
                      <CardBody>
                        <div className="medium-scroll-container">
                            {
                              state.selectedExclusion.exceptions.map(
                                (exception,index)=>{
                                  return <div className="exclusionary-list-inline-border header-color" key={index}>
                                    <i className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeException(exception.ID)}></i>&nbsp;
                                    {exception.company}
                                  </div>
                                }
                              )
                            }
                        </div>
                        <Row>
                          <Col sm="8">
                            <label><i className="fa fa-plus green-color"></i> New Exception</label>
                            <MySelect
                              modal={true}
                              type="select"
                              value={state.newException}
                              onChange={(v)=>setState({newException:v})}
                              options={[{label:"All Lender",value:0}].concat(state.clients.map((client)=>{
                                return {label:client.company, value:client.ID};
                              }))}
                              />
                          </Col>
                          <Col sm="4">
                            <br/>
                            <Button color="warning" onClick={(e)=>createNewException()}>Add</Button>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>


                <br/><br/>
                <div className="large-scroll-container-no-min">
                  <Card>
                    <CardHeader className="gray-color">Investors</CardHeader>
                    <CardBody>
                      <div className="medium-scroll-container-no-min">
                        {
                          state.selectedExclusion.investors.map(
                            (investor,index)=>{
                              return <div className="exclusionary-list-inline-border header-color" key={index}>
                                <i className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeInvestor(investor.ID)}></i>&nbsp;
                                {investor.investor}
                              </div>
                            }
                          )
                        }
                      </div>
                      <Row>
                        <Col sm="8">
                          <label><i className="fa fa-plus green-color"></i> New Investor</label>
                          <Input type="text" value={state.newInvestor} onChange={(e)=>{setState({newInvestor:e.target.value})}}/>
                        </Col>
                        <Col sm="4">
                          <br/>
                          <Button color="warning" onClick={(e)=>createNewInvestor()}>Add</Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <br/><br/>
                  <Card>
                    <CardHeader className="gray-color">Comment</CardHeader>
                    <CardBody>
                      <div className="medium-scroll-container-no-min">
                        {
                          state.selectedExclusion.comments.map(
                            (comment,index)=>{
                              return <div className="exclusionary-list-inline-border" key={index}>
                                {comment.comment}
                              </div>
                            }
                          )
                        }
                      </div>
                      <Row>
                        <Col sm="8">
                          <label><i className="fa fa-plus green-color"></i> New Comment</label>
                          <Input type="text" value={state.newComment} onChange={(e)=>setState({newComment:e.target.value})}/>
                        </Col>
                        <Col sm="4">
                          <br/>
                          <Button color="warning" onClick={(e)=>createNewComment()}>Add</Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>

                <br/><br/>
                <center>
                  <Button color="info" onClick={()=>{toggleDropDown()}}><i className="fa fa-times"></i> Close</Button>
                </center>
              </ModalBody>
            </Modal>

            <Modal className="my-modal" isOpen={state.loanTypeDropDownOpen} toggle={toggleChangeLoanType} >
              <ModalHeader hidden={true} toggle={toggleChangeLoanType}></ModalHeader>
              <ModalBody>
                <center>
                  <i className="fa fa-cogs"></i>&nbsp;Change Loan Type
                </center>
                <br/><br/>
                <Row>
                  <Col sm="4">
                    <label>{state.clickedClient.company}</label>
                  </Col>
                  <Col sm="8">
                    <MySelect
                      modal={true}
                      type="select"
                      value={state.updatedLoanType}
                      onChange={(v)=>setState({updatedLoanType:v})}
                      options={[{label:"Unknown",value:"unknown"},{label:"All",value:"all"},{label:"Jumbo Only",value:"jumbo"}]}
                      />
                  </Col>
                </Row>
                <br/><br/>
                <center>
                  <Button color="warning" onClick={()=>updateLoanType()}>Save</Button> &nbsp; <Button color="info" onClick={()=>{toggleChangeLoanType()}}><i className="fa fa-times"></i> Close</Button>&nbsp;
                </center>
              </ModalBody>
            </Modal>

            <div className="page-title">
              <i className="fa fa-reorder"></i>&nbsp;Appraisers - Exclusionary List
            </div>
            <ReportFilter {...props} configs={exclusionaryFiltersConfig1}/>
            <br/>

            <div className="my-divider"></div>
            <Row>
              <Col sm="6">
                  <div>{formatNumber(state.totalCount)} exclusionary in queue<br/><br/></div>
              </Col>
              <Col sm="6" className="align-right">
                <NavLink to="#" onClick={crossCheckToggleDropDown}>Cross check</NavLink>&nbsp;&nbsp;<NavLink to="/scrub-appraiser-exclusionary-list">Scrub new spreadsheet</NavLink>
              </Col>
            </Row>


            {renderExclusions()}
          </InfiniteScroll>
        </div>
      </TabPane>
      <TabPane tabId="2">
        <Modal className="my-modal-wide" isOpen={state.crossCheckDropDownOpen} toggle={crossCheckToggleDropDown} >
          <ModalHeader hidden={true} toggle={crossCheckToggleDropDown}></ModalHeader>
          <ModalBody>
            {content}
            <br/><br/>
            <center>
              <Button color="info" onClick={()=>{crossCheckToggleDropDown()}}><i className="fa fa-times"></i> Close</Button>&nbsp;{actionButton}
            </center>
          </ModalBody>
        </Modal>

        <div style={{minHeight:'100%',height:'100%',maxHeight:'100%',overflowY:'auto',overflowX:'hidden'}}>
          <InfiniteScroll
            next={passiveLoadMore}
            dataLength={state.passiveExclusions.length}
            hasMore={state.passiveHasMoreExclusions}
            loader={<div key="nill" className="loader"><center>Loading more passive exclusionary...</center></div>}
            initialLoad = {true}
            className="my-well"
            scrollableTarget="contentContainer"
          >
            <Modal className="my-modal-wide" isOpen={state.passiveDropDownOpen} toggle={togglePassiveDropDown} >
              <ModalHeader hidden={true} toggle={state.passiveDropDownOpen}></ModalHeader>
              <ModalBody>
                <center>
                  <i className="fa fa-warning"></i> Add New Passive Exclusionary List
                </center>
                <br/><br/>
                <Card>
                  <CardHeader className="gray-color">Information</CardHeader>
                  <CardBody>
                    <ReportFilter {...props} configs={exclusionaryFiltersConfig3}/>
                    <br/>
                    <Row>
                      <Col sm="6">
                        <label>Target Lender (Default to all lender if none specified)</label>
                      </Col>
                      <Col sm="6">
                        <div className='align-right' onClick={(e)=>setState({jumboOnly:!state.jumboOnly})}>
                          <Label check>
                            &nbsp;&nbsp;<Input checked={state.jumboOnly} type="checkbox"/>&nbsp;Excluded for Jumbo Loans
                          </Label>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <div className="medium-scroll-container">
                          <Table className="table table-striped">
                            <tbody>
                              {clients}
                            </tbody>
                          </Table>
                        </div>
                        <div className="small-scroll-container">
                          {
                            state.targetClients.map(
                              (client, index)=>{
                                return (
                                  <div style={{display:'inline-block'}} key={index}>
                                    <div className="entity-container cursor-pointer" onClick={()=>{modifyTargetClient(client)}}>
                                      <i className="fa fa-minus link-color"></i>&nbsp;{client.company}
                                    </div>&nbsp;
                                  </div>
                                );
                              }
                            )
                          }
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <br/><br/>
                <center>
                  <Button color="info" onClick={()=>{togglePassiveDropDown()}}><i className="fa fa-times"></i> Close</Button>&nbsp;<Button color="warning" onClick={()=>{createNewPassiveExclusionaryList()}}><i className="fa fa-check"></i> Submit</Button>
                </center>
              </ModalBody>
            </Modal>

            <div className="page-title">
              <i className="fa fa-reorder"></i>&nbsp;Appraisers - Passive Exclusionary List
            </div>
            <ReportFilter {...props} configs={exclusionaryFiltersConfig2}/>
            <br/>

            <div className="my-divider"></div>
            <Row>
              <Col sm="6">
                  <div>{formatNumber(state.passiveTotalCount)} passive exclusionary in queue<br/><br/></div>
              </Col>
              <Col sm="6" className="align-right">
                <NavLink to="#" onClick={togglePassiveDropDown}><i className="fa fa-plus green-color"/> Add New Passive Exclusionary Candidate</NavLink>
              </Col>
            </Row>


            {renderPassiveExclusions()}
          </InfiniteScroll>
        </div>
      </TabPane>
    </TabContent>
  </div>;
}


export default AppraiserExclusionaryList;
