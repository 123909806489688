//Author Sooyoung Kim
//Date June 22, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, callBackGenerator, formatDate, formatNumber, generateSID, getFromTo} from '../../../util/util';
import {Button, NavItem, Nav, TabContent, TabPane, Card, CardHeader, CardBody, Row, Col, Table, Input, NavLink as NavLinkL} from 'reactstrap';
import React, {useReducer, useEffect, useRef} from 'react';
import {NavLink} from 'react-router-dom';
import ReportFilter from '../report-filter';
import moment from 'moment';

//initialize the state
const initialState = {
  SID: generateSID(),
  appraisers:[],
  appraisersBreakdown:[],
  start:getFromTo('start'),
  end:getFromTo('end'),
  paymentQueue:{},
  dropDowns:{},
  idToName:{},
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
  clients:[],
  client:'',
  state:'',
  paymentType:'',
  amc:'',
  vip:'',
  bankAccountName:'Wells Fargo Checking',
  expensesAccountName:'5000 Cost of Goods Sold:5100 Appraisal Fees',
  appraiser:'',
  conditionActiveTab:'1',
  check:0,
  direct:0,
  stripe:0,
  authorize:0,
  digital:0
};

//reducer function that perform state update
const reducer = getReducer();


const PendingPayment  = (props)=>{
  const controller = new AbortController();
  const appraiserRefs = useRef({});

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  
  const scrollTo = (ID) => {
    const elem = appraiserRefs.current["appraiser" + ID];
    if (elem) {
      elem.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const appraiserOnClick = (bills,appraiserID) =>{
    let paymentQueue = Object.assign({},state.paymentQueue);
    if(!paymentQueue[appraiserID])
      paymentQueue[appraiserID] = [];
    let target = paymentQueue[appraiserID].slice();

    for(let i=0;i<bills.length;i++){
      let index = -1;
      let bill = bills[i];

      for(let j=0;j<target.length;j++){
        if(target[j].bill_fk===bill.bill_fk){
          index = j;
        }
      }

      if(index!==-1){
        target.splice(index,1);
      }
      else
        target.push(bill);
    }

    paymentQueue[appraiserID] = target;
    let check = 0;
    let stripe = 0;
    let authorize = 0;
    let directDeposit = 0;
    let digitalWallet = 0;

    let temp = Object.values(paymentQueue);
    if(temp.length > 0){
      temp.map(
        (item, index)=>{
          if(item.length >0){
            let billMethod = item[0].method;
            if(billMethod === 'Check')
              check++;
            else if(billMethod === 'Direct Deposit')
              directDeposit++;
            else if(billMethod === 'Stripe')
              stripe++;
            else if(billMethod === 'Authorize.net')
              authorize++;
            else if(billMethod === 'Digital Wallet')
              digitalWallet++;


          }
          return null;
        }
      )
    }

    setState({paymentQueue:paymentQueue, check:check, direct:directDeposit, stripe:stripe, authorize:authorize, digital:digitalWallet});
  }

  const billOnClick = (bill, appraiserID) =>{
    //check if it's in the queue
    let paymentQueue = Object.assign({},state.paymentQueue);
    if(!paymentQueue[appraiserID])
      paymentQueue[appraiserID] = [];
    let target = paymentQueue[appraiserID];
    let index = -1;
    for(let i=0;i<target.length;i++){
      if(target[i].bill_fk===bill.bill_fk){
        index = i;
      }
    }

    if(index!==-1){
      target.splice(index,1);
    }
    else
      target.push(bill);

    paymentQueue[appraiserID] = target;

    let check = 0;
    let stripe = 0;
    let authorize = 0;
    let directDeposit = 0;
    let digitalWallet = 0;

    let temp = Object.values(paymentQueue);
    if(temp.length > 0){
      temp.map(
        (item, index)=>{
          if(item.length >0){
            let billMethod = item[0].method;
            if(billMethod === 'Check')
              check++;
            else if(billMethod === 'Direct Deposit')
              directDeposit++;
            else if(billMethod === 'Stripe')
              stripe++;
            else if(billMethod === 'Authorize.net')
              authorize++;
            else if(billMethod === 'Digital Wallet')
              digitalWallet++;


          }
          return null;
        }
      )
    }

    setState({paymentQueue:paymentQueue, check:check, direct:directDeposit, stripe:stripe, authorize:authorize, digital:digitalWallet});
  }

  const conditionActiveTabToggle = (tab) =>{
    setState({conditionActiveTab:tab});
  }

  //API call
  const payAppraiser = () =>{
    if(state.bankAccountName!==''&&state.expensesAccountName!==''){
      let bills =[];
      for(let property in state.paymentQueue){
        let target = state.paymentQueue[property];
        for(let i=0;i<target.length;i++)
          bills.push(target[i].bill_fk);
      }

      let parameters = [
        {
          field:'bills',
          value:bills
        },
        {
          field:'bankAccountName',
          value:state.bankAccountName
        },
        {
          field:'expensesAccountName',
          value:state.expensesAccountName
        }
      ];

      let callBack = apiCallBack([{state:'appraisers', value:[]},{state:'paymentQueue', value:{}}]);
      httpPost('billing/appraiserFee/pay', parameters, 'Payment task queue on Quickbook successfully.', 'Oops, something went wrong and could not queue this payment task on Quickbook. Please try again later.', callBack);
    }
  }

  const getReport = () =>{
    let callBack = apiCallBack([{state:'appraisers', key:'data'},{state:'dropDowns', objectkey:'ID',value:false}]);
    httpGet('report/pendingPaymentSimplify/get/vip='+state.vip+'&start='+state.start+'&end='+state.end+'&state='+state.state+'&client='+state.client+'&amc='+state.amc+'&paymentType='+state.paymentType+'&appraiser='+state.appraiser, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  const getReportDetails = (appraiserFk) =>{
    if(!state.idToName[appraiserFk]){
      //call back for retrieving announcement
      let callBack = (response)=>{console.log(response);
        let code = response.data?response.data.code:undefined;
        if(code==='00'){
          let appraisersBreakdown = state.appraisersBreakdown.slice();
          for(let i=0;i<response.data.data.length;i++){
            appraisersBreakdown.unshift(response.data.data[i]);
          }
          console.log(appraisersBreakdown);
          
          let idToName = {};

          for(let i=0;i<appraisersBreakdown.length;i++){
            idToName[appraisersBreakdown[i].ID] = appraisersBreakdown[i].name;
          }


          let dropDowns = {};
          for(let i=0;i<appraisersBreakdown.length;i++){
            dropDowns[appraisersBreakdown[i].ID] = false;
          }
          setState({appraisersBreakdown:appraisersBreakdown, idToName:idToName, dropDowns:dropDowns});
        }
      };
      callBack = callBack.bind(this);

      httpGet('report/pendingPaymentSimplify/detail/get/vip='+state.vip+'&start='+state.start+'&end='+state.end+'&state='+state.state+'&client='+state.client+'&amc='+state.amc+'&paymentType='+state.paymentType+'&appraiser='+appraiserFk, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
    }
  }

  //render
  let start, end;
  if(state.start!=='')
    start = moment(state.start);
  if(state.end!=='')
    end = moment(state.end);

  let grandTotal = <div className="margin-top" style={{height:'34.4px'}}>&nbsp;</div>;

  if(Object.keys(state.paymentQueue).length>0){
    let total=0;
    for(let property in state.paymentQueue){
      let target = state.paymentQueue[property];
      for(let i=0;i<target.length;i++)
        total+= parseInt(target[i].total,10);
    }

    grandTotal =
    <div className="margin-top">
      <Row>
        <Col sm="3">
          <label>Bank account name</label> <Input type="text" value={state.bankAccountName} onChange={(e)=>setState({bankAccountName:e.target.value})}/>
        </Col>
        <Col sm="4">
          <label>Expenses account name</label> <Input type="text" value={state.expensesAccountName} onChange={(e)=>setState({expensesAccountName:e.target.value})}/>
        </Col>
        <Col sm="5">
          <label>Grand total: ${formatNumber(total)}</label>
          <br/>
          <Button color="warning" onClick={payAppraiser}>Pay</Button>
        </Col>
      </Row>
    </div>
  }

  let chartData = [];

  for(let i=0;i<state.appraisers.length;i++){
    let appraiser = state.appraisers[i];
    let tmp = {};
    tmp.ID = appraiser.ID;
    tmp.new = appraiser.new;
    tmp.name = appraiser.name;
    tmp.payment_method = appraiser.payment_method;
    tmp.y = appraiser.bill_total;
    tmp.president_club = appraiser.president_club;
    tmp.expedite_payment = appraiser.expedite_payment;

    chartData.push(tmp);
  }

  let appraisers = state.appraisersBreakdown.map(
    (appraiser,index)=>{

      let totalFee = 0;
      for(let i=0;i<appraiser.bills.length;i++){
        totalFee+=parseInt(appraiser.bills[i].total,10);
      }

      return(
        <div key={index} ref={(ref) => {appraiserRefs.current["appraiser"+appraiser.ID] = ref}}>
          <Card>
            <CardHeader className="header-color">
              <i className="fa fa-user"></i> {appraiser.name} - ${formatNumber(totalFee)} - {appraiser.payment_method}
            </CardHeader>
            <CardBody>
              <Row>
                <Col sm="4">
                  <label>Address</label>
                </Col>
                <Col sm="4">
                  <label>Billing address</label>
                </Col>
                <Col sm="4">
                  <label>Billing note</label>
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  {appraiser.address}
                </Col>
                <Col sm="4">
                  {appraiser.billing_address}
                </Col>
                <Col sm="4">
                  <i>{appraiser.billing_note}</i>
                </Col>
              </Row>
              <br/>
              <div className="medium-scroll-container">
                <Table className="table table-striped">
                  <thead>
                    <tr>
                      <th align="left" width="6%"><div className="cursor-pointer" onClick={(e)=>appraiserOnClick(appraiser.bills,appraiser.ID)}>
                        <i className="fa fa-check gray-color"></i>
                      </div></th>
                      <th align="left" width="15%">Payment method</th>
                      <th align="left" width="11%">Reference #</th>
                      <th align="left" width="10%">Receivable</th>
                      <th align="left" width="10%">Payable</th>
                      <th align="left" width="20%">Property address</th>
                      <th align="left" width="15%">Date Completed</th>
                      <th align="left" width="15%">Borrower</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      appraiser.bills.map(
                        (bill, index2)=>{
                          let checkbox =
                          <div className="cursor-pointer" onClick={(e)=>billOnClick(bill,appraiser.ID)}>
                            <i className="fa fa-check gray-color"></i>
                          </div>;

                          let checked = false;
                          if(state.paymentQueue[appraiser.ID]){
                            let target = state.paymentQueue[appraiser.ID];
                            for(let i=0;i<target.length;i++){
                              if(target[i].bill_fk===bill.bill_fk){
                                checked = true;
                                break;
                              }
                            }
                          }
                          if(checked)
                            checkbox =
                            <div className="cursor-pointer" onClick={(e)=>billOnClick(bill,appraiser.ID)}>
                              <i className="fa fa-check blue-color thick"></i>
                            </div>;
                          return(
                            <tr key={index2}>
                              <td width="6%" align="left">{checkbox}</td>
                              <td width="11%" align="left">#{bill.bill_fk+' - '+bill.method}</td>
                              <td width="15%" align="left"><NavLink className="display-inline no-padding" target="_blank" to={"/appraisal/"+bill.appraisal_fk}>{bill.reference_num}</NavLink></td>
                              <td width="8%" align="left">${formatNumber(bill.income_total)}</td>
                              <td width="8%" align="left">${formatNumber(bill.total)}</td>
                              <td width="20%" align="left">{bill.street+' '+bill.city+', '+bill.state}</td>
                              <td width="10%" align="left">{formatDate(bill.datetime_completed)}</td>
                              <td width="15%" align="left">{bill.borrower}</td>
                            </tr>
                          );
                        }
                      )
                    }
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
          <br/>
        </div>
      );
    }
  );

  let paymentQueue=[];
  for(let property in state.paymentQueue){
    let target = state.paymentQueue[property];
    if(target.length>0){
      let tmp = {};
      tmp.name = state.idToName[property];
      tmp.ID = property;
      tmp.bills = target;
      paymentQueue.push(tmp);
    }
  }

  let totalPending = 0;

  let reportFiltersConfig = [
    {id:'from',value:start, updateFunc:(v)=>setState({start:v}), width:'2'},
    {id:'to',value:end, updateFunc:(v)=>setState({end:v}), width:'2'},
    {id:'customSelect',value:state.amc, updateFunc:(v)=>setState({amc:v}), width:'2', label:'AMC', clearable:false, className:"", options:[{label:"Include AMC",value:""},{label:"AMC only",value:"amcOnly"},{label:"Exclude AMC",value:"excludeAmc"}]},
    {id:'customSelect',value:state.vip, updateFunc:(v)=>setState({vip:v}), width:'3', label:'VIP', clearable:true, className:"", options:[{label:"Expedite Payment",value:"expeditePayment"},{label:"President Club",value:"presidentClub"},{label:"Expdiate Payment & President Club",value:"presidentAndExpedite"},{label:"New",value:"new"}]},
    {id:'state',value:state.state, updateFunc:(v)=>setState({state:v}), width:'3'},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'3'},
    {id:'customSelect',value:state.paymentType, updateFunc:(v)=>setState({paymentType:v}), width:'3', label:'Payment Method', clearable:false, className:"", options:[{label:"Check only",value:"Check"},{label:"Direct Deposit only",value:"Direct Deposit"}]},
    {id:'appraiser',value:state.appraiser, updateFunc:(v)=>setState({appraiser:v}), width:'4'},
    {id:'button',value:'Submit', updateFunc:getReport, width:'2', className:"align-right", color:"warning"},
  ];

  return <div>
    <ReportFilter {...props} configs={reportFiltersConfig}/>
    <div className="my-divider">&nbsp;</div>
    <i className="fa fa-credit-card"></i> - Check
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <i className="fa fa-arrows-h"></i> - Direct Deposit
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <i className="fa fa-cc-stripe"></i> - Stripe
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <i className="fa fa-font"></i> - Authorize.net
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <i className="fa fa-money"></i> - Digital Wallet
    <Card>
      <CardHeader className="header-color">
        Pending payment
      </CardHeader>
      <CardBody>
        <Row>
          <Col sm="7">
            <table className="table table-striped table-fixed-header" width="100%">
              <thead>
                <tr width="100%"><th width="10%">&nbsp;</th><th width="60%">Name</th><th width="20%">Amount</th><th width="10%">&nbsp;</th></tr>
              </thead>
              <tbody style={{height:'440px'}}>
                {
                  chartData.map(
                    (data,index)=>{
                      let presidentClub = <div className="display-inline"></div>;
                      let expeditePayment = <div className="display-inline"></div>;
                      let paymentMethodIcon = <i className="fa fa-credit-card"></i>;

                      if(data.payment_method==='Direct Deposit')
                        paymentMethodIcon = <i className="fa fa-arrows-h"></i>;
                      if(data.payment_method==='Stripe')
                        paymentMethodIcon = <i className="fa fa-cc-stripe"></i>;
                      if(data.payment_method==='Authorize.net')
                        paymentMethodIcon = <i className="fa fa-font"></i>;
                      if(data.payment_method==='Digital Wallet')
                        paymentMethodIcon = <i className="fa fa-money"></i>;

                      let rowClass = '';
                      let keyword = '';
                      if(data.new==='yes'){
                        rowClass = 'flashit red-color';
                        keyword = 'NEW';
                      }

                      if(data.president_club==='yes')
                        presidentClub = <div className="display-inline" style={{minWidth:'20px'}}><i className="fa fa-trophy orange-color"></i></div>;
                      if(data.expedite_payment==='yes')
                        expeditePayment = <div className="display-inline" style={{minWidth:'20px'}}><i className="fa fa-money green-color"></i></div>;
                      totalPending = totalPending + parseInt(data.y, 10);
                      return(
                        <tr width="100%" key={index}>
                          <td width="10%">{presidentClub} {expeditePayment}<div className="display-inline red-color">{keyword}</div></td>
                          <td width="60%"><NavLink to="#" onClick={(e)=>{scrollTo(data.ID)}} className={rowClass}>{paymentMethodIcon} {data.name}</NavLink></td>
                          <td width="20%">${formatNumber(data.y)}</td>
                          <td width="10%"><i onClick={(e)=>getReportDetails(data.ID)} className="fa fa-arrow-down cursor-pointer header-color"></i></td>
                        </tr>
                      )
                    }
                  )
                }
              </tbody>
            </table>
            <table className="table table-striped" width="100%">
              <tbody>
                <tr>
                  <td width="10%">&nbsp;</td><td width="70%">Grand Total</td><td width="20%">${formatNumber(totalPending)}</td>
                </tr>
              </tbody>
            </table>
          </Col>
          <Col sm="5">
            <Nav tabs className="nav flex-column flex-sm-row" >
              <NavItem>
                <NavLinkL
                  className={"nav-link "+(state.conditionActiveTab === '1'?"active":"inactive" )}
                  onClick={() => { conditionActiveTabToggle('1'); }}
                  to="#"
                >
                  <div style={{fontSize:'13px'}}><span className="my-badge">{state.check}</span>&nbsp;Check</div>
                </NavLinkL>
              </NavItem>
              <NavItem>
                <NavLinkL
                  className={"nav-link "+(state.conditionActiveTab === '2'?"active":"inactive" ) }
                  onClick={() => { conditionActiveTabToggle('2'); }}
                  to="#"
                >
                  <div style={{fontSize:'12px'}}><span className="my-badge">{state.direct}</span>&nbsp;Direct Deposit</div>
                </NavLinkL>
              </NavItem>
              <NavItem>
                <NavLinkL
                  className={"nav-link "+(state.conditionActiveTab === '3'?"active":"inactive" ) }
                  onClick={() => { conditionActiveTabToggle('3'); }}
                  to="#"
                >
                  <div style={{fontSize:'13px'}}><span className="my-badge">{state.stripe}</span>&nbsp;Stripe</div>
                </NavLinkL>
              </NavItem>
              <NavItem>
                <NavLinkL
                  className={"nav-link "+(state.conditionActiveTab === '4'?"active":"inactive" ) }
                  onClick={() => { conditionActiveTabToggle('4'); }}
                  to="#"
                >
                  <div style={{fontSize:'12px'}}><span className="my-badge">{state.authorize}</span>&nbsp;Authorize.net</div>
                </NavLinkL>
              </NavItem>
              <NavItem>
                <NavLinkL
                  className={"nav-link "+(state.conditionActiveTab === '5'?"active":"inactive" ) }
                  onClick={() => { conditionActiveTabToggle('5'); }}
                  to="#"
                >
                  <div style={{fontSize:'12px'}}><span className="my-badge">{state.digital}</span>&nbsp;Digital Wallet</div>
                </NavLinkL>
              </NavItem>
            </Nav>
            <TabContent activeTab={state.conditionActiveTab}>
              <TabPane tabId="1">
                <Card>
                  <CardHeader className="gray-color">
                    <i className="fa fa-cart"></i> Payment queue - Check
                  </CardHeader>
                  <CardBody>
                    <div className="large-scroll-container">
                      <Table className="table table-striped">
                        <tbody>
                          {
                            paymentQueue.map(
                              (item, index)=>{
                                let billTotal = 0;
                                let billMethod = '';
                                let bills = item.bills.map(
                                  (bill,index2)=>{
                                    billTotal = billTotal + parseInt(bill.total,10);
                                    billMethod = bill.method;

                                    if(billMethod !== 'Check'){
                                      return null;
                                    }

                                    return(
                                      <div key={index2}>
                                        <Row>
                                          <Col sm="6">
                                            <i className="fa fa-times red-color cursor-pointer" onClick={(e)=>billOnClick(bill, item.ID)}></i> Payable #{bill.bill_fk}
                                          </Col>
                                          <Col sm="6" className="align-right">
                                            ${formatNumber(bill.total)}
                                          </Col>
                                        </Row>
                                      </div>
                                    );
                                  }
                                );
                                if(billMethod !== 'Check'){
                                  return null;
                                }

                                return(
                                  <tr key={index}>
                                    <td>
                                      <Row>
                                        <Col sm="6">
                                          <i className="fa fa-times red-color cursor-pointer" onClick={(e)=>appraiserOnClick(item.bills,item.ID)}></i> <b>{item.name}</b>
                                        </Col>
                                        <Col sm="6" className="align-right">
                                          ${formatNumber(billTotal)}
                                        </Col>
                                      </Row>
                                      <div className="padding-left padding-right">{bills}</div>
                                    </td>
                                  </tr>
                                )
                              }
                            )
                          }
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId="2">
                <Card>
                  <CardHeader className="gray-color">
                    <i className="fa fa-cart"></i> Payment queue - Direct Deposit
                  </CardHeader>
                  <CardBody>
                    <div className="large-scroll-container">
                      <Table className="table table-striped">
                        <tbody>
                          {
                            paymentQueue.map(
                              (item, index)=>{
                                let billTotal = 0;
                                let billMethod = '';
                                let bills = item.bills.map(
                                  (bill,index2)=>{
                                    billTotal = billTotal + parseInt(bill.total,10);
                                    billMethod = bill.method;

                                    if(billMethod !== 'Direct Deposit'){
                                      return null;
                                    }
                                    return(
                                      <div key={index2}>
                                        <Row>
                                          <Col sm="6">
                                            <i className="fa fa-times red-color cursor-pointer" onClick={(e)=>billOnClick(bill, item.ID)}></i> Payable #{bill.bill_fk}
                                          </Col>
                                          <Col sm="6" className="align-right">
                                            ${formatNumber(bill.total)}
                                          </Col>
                                        </Row>
                                      </div>
                                    );
                                  }
                                );
                                if(billMethod !== 'Direct Deposit'){
                                  return null;
                                }
                                return(
                                  <tr key={index}>
                                    <td>
                                      <Row>
                                        <Col sm="6">
                                          <i className="fa fa-times red-color cursor-pointer" onClick={(e)=>appraiserOnClick(item.bills,item.ID)}></i> <b>{item.name}</b>
                                        </Col>
                                        <Col sm="6" className="align-right">
                                          ${formatNumber(billTotal)}
                                        </Col>
                                      </Row>
                                      <div className="padding-left padding-right">{bills}</div>
                                    </td>
                                  </tr>
                                )
                              }
                            )
                          }
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId="3">
                <Card>
                  <CardHeader className="gray-color">
                    <i className="fa fa-cart"></i> Payment queue -Stripe
                  </CardHeader>
                  <CardBody>
                    <div className="large-scroll-container">
                      <Table className="table table-striped">
                        <tbody>
                          {
                            paymentQueue.map(
                              (item, index)=>{
                                let billTotal = 0;
                                let billMethod = '';
                                let bills = item.bills.map(
                                  (bill,index2)=>{
                                    billTotal = billTotal + parseInt(bill.total,10);
                                    billMethod = bill.method;

                                    if(billMethod !== 'Stripe'){
                                      return null;
                                    }
                                    return(
                                      <div key={index2}>
                                        <Row>
                                          <Col sm="6">
                                            <i className="fa fa-times red-color cursor-pointer" onClick={(e)=>billOnClick(bill, item.ID)}></i> Payable #{bill.bill_fk}
                                          </Col>
                                          <Col sm="6" className="align-right">
                                            ${formatNumber(bill.total)}
                                          </Col>
                                        </Row>
                                      </div>
                                    );
                                  }
                                );
                                if(billMethod !== 'Stripe'){
                                  return null;
                                }
                                return(
                                  <tr key={index}>
                                    <td>
                                      <Row>
                                        <Col sm="6">
                                          <i className="fa fa-times red-color cursor-pointer" onClick={(e)=>appraiserOnClick(item.bills,item.ID)}></i> <b>{item.name}</b>
                                        </Col>
                                        <Col sm="6" className="align-right">
                                          ${formatNumber(billTotal)}
                                        </Col>
                                      </Row>
                                      <div className="padding-left padding-right">{bills}</div>
                                    </td>
                                  </tr>
                                )
                              }
                            )
                          }
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId="4">
                <Card>
                  <CardHeader className="gray-color">
                    <i className="fa fa-cart"></i> Payment queue - Authorize.net
                  </CardHeader>
                  <CardBody>
                    <div className="large-scroll-container">
                      <Table className="table table-striped">
                        <tbody>
                          {
                            paymentQueue.map(
                              (item, index)=>{
                                let billTotal = 0;
                                let billMethod = '';
                                let bills = item.bills.map(
                                  (bill,index2)=>{
                                    billTotal = billTotal + parseInt(bill.total,10);
                                    billMethod = bill.method;

                                    if(billMethod !== 'Authorize.net'){
                                      return null;
                                    }
                                    return(
                                      <div key={index2}>
                                        <Row>
                                          <Col sm="6">
                                            <i className="fa fa-times red-color cursor-pointer" onClick={(e)=>billOnClick(bill, item.ID)}></i> Payable #{bill.bill_fk}
                                          </Col>
                                          <Col sm="6" className="align-right">
                                            ${formatNumber(bill.total)}
                                          </Col>
                                        </Row>
                                      </div>
                                    );
                                  }
                                );
                                if(billMethod !== 'Authorize.net'){
                                  return null;
                                }
                                return(
                                  <tr key={index}>
                                    <td>
                                      <Row>
                                        <Col sm="6">
                                          <i className="fa fa-times red-color cursor-pointer" onClick={(e)=>appraiserOnClick(item.bills,item.ID)}></i> <b>{item.name}</b>
                                        </Col>
                                        <Col sm="6" className="align-right">
                                          ${formatNumber(billTotal)}
                                        </Col>
                                      </Row>
                                      <div className="padding-left padding-right">{bills}</div>
                                    </td>
                                  </tr>
                                )
                              }
                            )
                          }
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </TabPane>
              <TabPane tabId="5">
                <Card>
                  <CardHeader className="gray-color">
                    <i className="fa fa-cart"></i> Payment queue - Digital Wallet
                  </CardHeader>
                  <CardBody>
                    <div className="large-scroll-container">
                      <Table className="table table-striped">
                        <tbody>
                          {
                            paymentQueue.map(
                              (item, index)=>{
                                let billTotal = 0;
                                let billMethod = '';
                                let bills = item.bills.map(
                                  (bill,index2)=>{
                                    billTotal = billTotal + parseInt(bill.total,10);
                                    billMethod = bill.method;

                                    if(billMethod !== 'Digital Wallet'){
                                      return null;
                                    }
                                    return(
                                      <div key={index2}>
                                        <Row>
                                          <Col sm="6">
                                            <i className="fa fa-times red-color cursor-pointer" onClick={(e)=>billOnClick(bill, item.ID)}></i> Payable #{bill.bill_fk}
                                          </Col>
                                          <Col sm="6" className="align-right">
                                            ${formatNumber(bill.total)}
                                          </Col>
                                        </Row>
                                      </div>
                                    );
                                  }
                                );
                                if(billMethod !== 'Digital Wallet'){
                                  return null;
                                }
                                return(
                                  <tr key={index}>
                                    <td>
                                      <Row>
                                        <Col sm="6">
                                          <i className="fa fa-times red-color cursor-pointer" onClick={(e)=>appraiserOnClick(item.bills,item.ID)}></i> <b>{item.name}</b>
                                        </Col>
                                        <Col sm="6" className="align-right">
                                          ${formatNumber(billTotal)}
                                        </Col>
                                      </Row>
                                      <div className="padding-left padding-right">{bills}</div>
                                    </td>
                                  </tr>
                                )
                              }
                            )
                          }
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </TabPane>
            </TabContent>
          </Col>
        </Row>

        {grandTotal}
      </CardBody>
    </Card>
    <br/>
    {appraisers}
  </div>;
}


export default PendingPayment;
