//Author Sooyoung Kim
//Date Aug 15, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, callBackGenerator, formatNumber,  formatDateTime, showMessage} from '../../util/util';
import {Alert, Button, Collapse, Input, Card, CardHeader, Label, CardBody, Row, Col, Table} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import {usePresencePusher } from '../pusher/pusher-context';
import MySelect from '../util/my-select';
import {useParams} from 'react-router-dom';
import ReactQuill, {Quill} from 'react-quill';
import ImageResize from 'quill-image-resize-module-react';

// Register the image resize module
Quill.register('modules/imageResize', ImageResize);

//initialize the state
const initialState = {
  appraisal:{
    property_street:'',
    property_city:'',
    property_state:'',
    borrower_f_name:'',
    borrower_l_name:'',
    reference_num:'',
    loan_num:''
  },
  overallStatus:{
    review_count:0,
    condition_count:0,
    rebuttal_count:0,
    due_invoice_count:0
  },
  comments:[],
  presenceMembers:[],
  messages:[],
  comment:'',

  old_special_instructions:'',
  dropdownOpen:false,
  propertyDetail:{
    comps:[],
    data_source:'',
    sales_price:'',
    date_sale:'',
    year_built:'',
    age:'',
    gla:'',
    lot_size:'',
    room_count:'',
    bedroom_count:'',
    bathroom_count:'',
    accessory_unit_exist:'',
    property_street:'',
    property_city:'',
    property_state:'',
    property_zip:'',
  },

  markAsComplex:false,
  extractedFromXMLCollapse:false,

  glaType:'Sqft',
  gla:'',
  lotSizeType:'Sqft',
  lotSize:'',
  yearBuilt:'',
  totalRoom:'',
  totalBedroom:'',
  totalBathroom:'',
  linkPlatform:'Zillow',
  link:'',
  links:[],
  facts:{
    gla:'',
    lotSize:'',
    yearBuilt:'',
    totalRoom:'',
    totalBathroom:'',
    totalBedroom:''
  }
};

//reducer function that perform state update
const reducer = getReducer();

const PropertyDetailsReport  = (props)=>{
  const controller = new AbortController();
  const presencePusher = usePresencePusher ();
  let {id} = useParams();

  let newInitialState = Object.assign({}, initialState, {
    id: id,
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getPropertyDetailsReport(props.appraisalFk);

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if (presencePusher) {
      const presenceChannel = presencePusher.subscribe('presence-order');

      const handleSubscriptionSucceeded = (members) => {
        // Get the initial user list from presence channel
        let presenceMembers = [];
        members.each(
          function(member)
          {
            let ID = member.info.ID;
            let orderID = member.info.orderID;
            let myID = localStorage.getItem('userID');
            if(orderID===props.appraisalFk){
              if(ID!==myID){
                presenceMembers.push(member);
              }
            }
          }
        );
        console.log(presenceMembers)
        setState({presenceMembers:presenceMembers})
      };

      const handleMemberAdded = (member) => {
        let ID = member.info.ID;
        let orderID = member.info.orderID;
        let myID = localStorage.getItem('userID');
        if(orderID===props.appraisalFk){
          if(ID!==myID){
            let presenceMembers = state.presenceMembers.slice();
            presenceMembers.push(member);

            setState({presenceMembers:presenceMembers});
          }
        }
      };

      const handleMemberRemoved = (member) => {
        // Remove a user from the user list
        let ID = member.info.ID;
        let orderID = member.info.orderID;
        let myID = localStorage.getItem('userID');

        if(orderID===props.appraisalFk){
          if(ID!==myID){
            let presenceMembers = state.presenceMembers.slice();

            for(let i=0;i<presenceMembers.length;i++){
              if(presenceMembers[i].info.ID===member.info.ID){
                presenceMembers.splice(i, 1);
                setState({presenceMembers:presenceMembers});
                break;
              }
            }
          }
        }
      };

      presenceChannel.bind('pusher:subscription_succeeded', handleSubscriptionSucceeded);
      presenceChannel.bind('pusher:member_added', handleMemberAdded);
      presenceChannel.bind('pusher:member_removed', handleMemberRemoved);

      return () => {
        presenceChannel.unbind('pusher:subscription_succeeded', handleSubscriptionSucceeded);
        presenceChannel.unbind('pusher:member_added', handleMemberAdded);
        presenceChannel.unbind('pusher:member_removed', handleMemberRemoved);

        presenceChannel.unsubscribe();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presencePusher, props.appraisalFk]);

  const submitPropertyDetails = () => {
    let valid = false;
    if(state.markAsComplex){
      markPropertyAsComplex();
      valid = true;
    }

    if(state.comment!==''){
      createPropertyDetailsComment();
      valid = true;
    }

    if(state.link!==''){
      createPropertyDetailsLink();
      valid = true;
    }

    if(state.gla!=='' || state.lotSize!=='' || state.yearBuilt!=='' || state.totalRoom!=='' || state.totalBedroom!=='' || state.totalBathroom!==''){
      createPropertyDetailsFacts();
      valid = true;
    }

    if(!valid)
      showMessage('error', 'Please fill in property details information before submitting.');
  }


  //API call
  
  const markPropertyAsComplex = () => {
    let parameters = [
      {
        field:'ID',
        value:props.appraisalFk
      },
      {
        field:'field',
        value:'complex'
      },
      {
        field:'value',
        value:'Complex'
      }
    ];

    let callBack = apiCallBack([{state:'markAsComplex', value:false}]);
    httpPut('appraisal/update', parameters, 'Property marked as complex.', 'Oops, something went wrong and could not mark property as complex. Please try again later.', callBack);
  }


  //get appraisal
  const getPropertyDetailsReport = (id) => {
    let callBack = (response)=>{console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        setState({
          comments:response.data.comments,
          gla:response.data.facts.gla,
          lotSize:response.data.facts.lot_size,
          yearBuilt:response.data.facts.year_built,
          totalRoom:response.data.facts.total_room,
          totalBedroom:response.data.facts.total_bedroom,
          totalBathroom:response.data.facts.total_bathroom,
          links:response.data.links,
          propertyDetail:response.data.data2,
        });
        if(response.data.data2.comps && response.data.data2.comps.length>0)
          setState({extractedFromXMLCollapse:true});
      }
    };
    callBack = callBack.bind(this);

    httpGet('appraisal/propertyDetails/report/'+id, '', 'Oops, something went wrong and could not load this property details report. Please try again later.', callBack);
  }

  const createPropertyDetailsComment = () => {
    let parameters = [
      {
        field:'comment',
        value:state.comment
      },
      {
        field:'appraisalFk',
        value:props.appraisalFk
      }
    ];
    let callBack = apiCallBack([{state:'comments', arraykey:'data', targetArray:state.comments}, {state:'comment', value:''}]);
    httpPost('appraisal/propertyDetails/comment/create', parameters, 'Property details comment recorded successfully.', 'Oops, something went wrong and could not record property details comment on this order. Please try again later.', callBack);
  }

  const createPropertyDetailsLink = () => {
    let parameters = [
      {
        field:'link',
        value:state.link
      },
      {
        field:'platform',
        value:state.linkPlatform
      },
      {
        field:'appraisalFk',
        value:props.appraisalFk
      }
    ];

    let callBack = apiCallBack([{state:'links', arraykey:'data', targetArray:state.links}, {state:'link', value:''}]);
    httpPost('appraisal/propertyDetails/link/create', parameters, 'Property details link recorded successfully.', 'Oops, something went wrong and could not record property details link on this order. Please try again later.', callBack);
  }

  const createPropertyDetailsFacts = () => {
    let gla = state.gla;
    let lotSize = state.lotSize;

    if(state.glaType==='Acre' && gla!=='')
      gla = gla * 43560;

    if(state.lotSizeType==='Acre' && lotSize!=='')
      lotSize = lotSize * 43560;



    let parameters = [
      {
        field:'gla',
        value:gla
      },
      {
        field:'lotSize',
        value:lotSize
      },
      {
        field:'yearBuilt',
        value:state.yearBuilt
      },
      {
        field:'totalRoom',
        value:state.totalRoom
      },
      {
        field:'totalBedroom',
        value:state.totalBedroom
      },
      {
        field:'totalBathroom',
        value:state.totalBathroom
      },
      {
        field:'appraisalFk',
        value:props.appraisalFk
      }
    ];

    let callBack = apiCallBack([{state:'facts', key:'data'}]);
    httpPut('appraisal/propertyDetails/admin/research/update', parameters, 'Property details facts recorded successfully.', 'Oops, something went wrong and could not record property details facts on this order. Please try again later.', callBack);
  }

  //render
  let comps;

  if(state.propertyDetail.comps.length>0){
    comps =
    <div className="horizontal-scroll-container" style={{height:'auto'}}>
      <Table className="table table-striped">
      <thead>
        <th>&nbsp;</th>
        <th style={{verticalAlign:'top', minWidth:'200px'}}>{state.propertyDetail.property_street+' '+state.propertyDetail.property_city+', '+state.propertyDetail.property_state+' '+state.propertyDetail.property_zip}</th>
        {
          state.propertyDetail.comps.map(
            (comp, index)=>{
              return <th style={{verticalAlign:'top', minWidth:'200px'}} key={index}>
                {comp.property_street+' '+comp.property_city+', '+comp.property_state+' '+comp.property_zip}
              </th>
            }
          )
        }
      </thead>
      <tbody>
        <tr>
          <td style={{fontWeight:'bold'}}>Data Source</td>
          <td>{state.propertyDetail.data_source}</td>
          {
            state.propertyDetail.comps.map(
              (comp, index)=>{
                return <td key={index}>
                  {comp.data_source}
                </td>
              }
            )
          }
        </tr>
        <tr>
          <td style={{fontWeight:'bold'}}>Sale Price</td>
          <td>${formatNumber(state.propertyDetail.sales_price)}</td>
          {
            state.propertyDetail.comps.map(
              (comp, index)=>{
                return <td key={index}>
                  ${formatNumber(comp.sales_price)}
                </td>
              }
            )
          }
        </tr>
        <tr>
          <td style={{fontWeight:'bold'}}>Date Sale</td>
          <td>{state.propertyDetail.date_sale}</td>
          {
            state.propertyDetail.comps.map(
              (comp, index)=>{
                return <td key={index}>
                  {comp.date_sale}
                </td>
              }
            )
          }
        </tr>
        <tr>
          <td style={{fontWeight:'bold'}}>Year Built</td>
          <td>{state.propertyDetail.year_built}</td>
          {
            state.propertyDetail.comps.map(
              (comp, index)=>{
                return <td key={index}>
                  {comp.year_built}
                </td>
              }
            )
          }
        </tr>
        <tr>
          <td style={{fontWeight:'bold'}}>Age</td>
          <td>{state.propertyDetail.age}</td>
          {
            state.propertyDetail.comps.map(
              (comp, index)=>{
                return <td key={index}>
                  {comp.age}
                </td>
              }
            )
          }
        </tr>
        <tr>
          <td style={{fontWeight:'bold'}}>GLA</td>
          <td>{state.propertyDetail.gla} sqft</td>
          {
            state.propertyDetail.comps.map(
              (comp, index)=>{
                return <td key={index}>
                  {comp.gla} sqft
                </td>
              }
            )
          }
        </tr>
        <tr>
          <td style={{fontWeight:'bold'}}>Lot Size</td>
          <td>{state.propertyDetail.lot_size} sqft</td>
          {
            state.propertyDetail.comps.map(
              (comp, index)=>{
                return <td key={index}>
                  {comp.lot_size} sqft
                </td>
              }
            )
          }
        </tr>
        <tr>
          <td style={{fontWeight:'bold'}}>Total Room</td>
          <td>{state.propertyDetail.room_count}</td>
          {
            state.propertyDetail.comps.map(
              (comp, index)=>{
                return <td key={index}>
                  {comp.room_count}
                </td>
              }
            )
          }
        </tr>
        <tr>
          <td style={{fontWeight:'bold'}}>Total Bedroom</td>
          <td>{state.propertyDetail.bedroom_count}</td>
          {
            state.propertyDetail.comps.map(
              (comp, index)=>{
                return <td key={index}>
                  {comp.bedroom_count}
                </td>
              }
            )
          }
        </tr>
        <tr>
          <td style={{fontWeight:'bold'}}>Total Bathroom</td>
          <td>{state.propertyDetail.bathroom_count}</td>
          {
            state.propertyDetail.comps.map(
              (comp, index)=>{
                return <td key={index}>
                  {comp.bathroom_count}
                </td>
              }
            )
          }
        </tr>
        <tr>
          <td style={{fontWeight:'bold'}}>Accessory Unit Exist</td>
          <td>{state.propertyDetail.accessory_unit_exist}</td>
          {
            state.propertyDetail.comps.map(
              (comp, index)=>{
                return <td key={index}>
                  {comp.accessory_unit_exist}
                </td>
              }
            )
          }
        </tr>
      </tbody>
    </Table>
    </div>;
  }
  else{
    comps =<center>Property Detail data not available</center>;
  }

  let otherLinkPlatform;

  if(state.linkPlatform==='Other'){
    otherLinkPlatform = <Col sm="2">
      <Input type="text" value={state.otherLinkPlatform} onChange={(e)=>setState({otherLinkPlatform:e.target.value})}/>
    </Col>;
  }


  return <div>
    <Card  style={{borderTop:'0px'}}>
      <CardHeader className="header-color">
        <i className="fa fa-home"></i>&nbsp;{props.appraisal.reference_num} - {props.appraisal.property_street} {props.appraisal.property_city}, {props.appraisal.property_state} {props.appraisal.property_zip} - {props.appraisal.borrower_f_name} {props.appraisal.borrower_l_name}
      </CardHeader>
      <CardBody>
      {
        props.messages.map(
          (message, index)=>{
            if(message.link){
              return(<a key={index} href={'/'+message.link+'/'+message.ID} target="_blank" rel="noreferrer"><Alert key={index} color="warning" className="my-alert">
                  <i className="fa fa-information"></i> {message.msg}
                </Alert></a>);
            }

            return(<Alert key={index} color="warning" className="my-alert">
                <i className="fa fa-information"></i> {message.msg}
              </Alert>);
          }
        )
      }
      </CardBody>
    </Card>
    <Card>
      <CardHeader className="header-color cursor-pointer" onClick={(e)=>setState({extractedFromXMLCollapse:!state.extractedFromXMLCollapse})}>
        {state.extractedFromXMLCollapse?<i className="fa fa-minus red-color"/>:<i className="fa fa-plus green-color"/>} Property Facts & Comps From Appraisal Report {formatDateTime(state.appraisal.datetime_appraisal_uploaded)}
      </CardHeader>
      <CardBody>
        <Collapse isOpen={state.extractedFromXMLCollapse}>
          {comps}
        </Collapse>
      </CardBody>
    </Card>
    <div className="my-divider">&nbsp;</div>
    <div className="larger-scroll-container-no-min">
      {
        state.comments.map(
          (comment, index)=>{
            return <div key={index}>
              <Card>
                <CardHeader>
                  <label>{formatDateTime(comment.datetime_created)} - {comment.author}</label>
                </CardHeader>
                <CardBody>
                  <div dangerouslySetInnerHTML={{__html: comment.comment}} />
                </CardBody>
              </Card>
              <br/>
            </div>;
          }
        )
      }
    </div>
    <br/>
    <div className="my-divider"/>
    <Card>
      <CardHeader className="header-color"><i className="fa fa-plus green-color"/> Property Facts Prior To Bid</CardHeader>
      <CardBody>
        <label>Property Facts</label><br/>
        <Row>
          <Col sm="5">
            GLA<br/>
            <div className="display-inline">
              <MySelect
                type="select"
                value={state.glaType}
                onChange={(v)=>setState({glaType:v})}
                options={[{label:"Sqft", value:"Sqft"}, {label:"Acre", value:"Acre"}]}
              />
            </div>
            <div className="display-inline" style={{verticalAlign:'top', paddingLeft:'5px'}}>
              <Input type="number" value={state.gla} onChange={(e)=>setState({gla:e.target.value})}/>
            </div>
          </Col>
          <Col sm="5">
            Lot Size<br/>
            <div className="display-inline">
              <MySelect
                type="select"
                value={state.lotSizeType}
                onChange={(v)=>setState({lotSizeType:v})}
                options={[{label:"Sqft", value:"Sqft"}, {label:"Acre", value:"Acre"}]}
              />
            </div>
            <div className="display-inline" style={{verticalAlign:'top', paddingLeft:'5px'}}>
              <Input type="number" value={state.lotSize} onChange={(e)=>setState({lotSize:e.target.value})}/>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm="3">
            Year Built<br/>
            <Input type="number" value={state.yearBuilt} onChange={(e)=>setState({yearBuilt:e.target.value})}/>
          </Col>
          <Col sm="3">
            Total Room<br/>
            <Input type="number" value={state.totalRoom} onChange={(e)=>setState({totalRoom:e.target.value})}/>
          </Col>
          <Col sm="3">
            Total Bedroom<br/>
            <Input type="number" value={state.totalBedroom} onChange={(e)=>setState({totalBedroom:e.target.value})}/>
          </Col>
          <Col sm="3">
            Total Bathroom<br/>
            <Input type="number" value={state.totalBathroom} onChange={(e)=>setState({totalBathroom:e.target.value})}/>
          </Col>
        </Row>
        <div className="my-divider"/>
        <br/>

        <label>Links</label><br/>
        <Row>
          <Col sm="2">
            <MySelect
              type="select"
              value={state.linkPlatform}
              onChange={(v)=>setState({linkPlatform:v})}
              options={[{label:"Zillow",value:"Zillow"}, {label:"Redfin", value:"Redfin"}, {label:"MLS", value:"MLS"}, {label:"Other", value:"Other"}]}
            />
          </Col>
          {otherLinkPlatform}
          <Col sm="8">
            <Input type="text" value={state.link} onChange={(e)=>setState({link:e.target.value})}/>
          </Col>
        </Row>
        <label>Existing Link</label>
        {
          state.links.map(
            (link, index)=>{
              return <div key={index}>
                <a href={link.link} rel="noreferrer" target="_blank">{link.link}</a> By {link.author} @ {formatDateTime(link.datetime_created)}
              </div>
            }
          )
        }

        <div className="my-divider"/>
        <br/>
        <label>Comment</label><br/>
        <ReactQuill
          value={state.comment}
          modules={
            {
                toolbar: [
                  [{ 'header': [1, 2, false] }],
                  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                  [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                  ['link', 'image'],
                  ['clean']
                ],
                imageResize: {
                // parchment: Quill.import('parchment'),
                  modules: ['Resize', 'DisplaySize']
                }
            }
          }
          formats={
            [
              'header',
              'alt','width','style','size',
              'bold', 'italic', 'underline', 'strike', 'blockquote',
              'list', 'bullet', 'indent',
              'link', 'image'
            ]
          }
          onChange={(value) => setState({comment:value})}
        />
        <br/>
        <div className="align-right">
          <Label check>
            <Input type="checkbox" checked={state.markAsComplex} onClick={(e)=>{setState({markAsComplex:!state.markAsComplex})}}/>&nbsp;Mark Property As Complex
          </Label>&nbsp;&nbsp;<Button color="warning" onClick={()=>{submitPropertyDetails()}}><i className="fa fa-plus"></i>&nbsp;&nbsp;Submit All</Button>
        </div>
      </CardBody>
    </Card>
  </div>;
}


export default PropertyDetailsReport;
