//Author author
//Date date
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, formatNumber, formatDateTime, replaceRegex} from '../../../util/util';
import {Card, CardHeader, CardBody, Button, Col, Row, Modal, ModalHeader, ModalBody} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import moment from 'moment';
import {NavLink} from 'react-router-dom';
import ReportFilter from '../report-filter';

let today = new Date();
let month = today.getMonth()+1;

if(month<10)
  month = '0'+month;
let from = today.getFullYear()+'-'+month+'-'+today.getDate();

//initialize the state
const initialState = {
  loanPurpose:'',
  loanType:'',
  isRush:'',
  isComplex:'',
  state:'',
  city:'',
  entity:'',
  client:'',
  selectedAppraisalType:'',
  filterAppraisalTypes:[],
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
  clients:[],
  loanTypes:[{key:'Conventional',value:'Conventional'},{key:'High Balanced',value:'High Balanced'},{key:'Jumbo',value:'Jumbo'},{key:'Jumbo ARM',value:'Jumbo ARM'},{key:'Jumbo Fixed',value:'Jumbo Fixed'},{key:'Jumbo <$1m',value:'Jumbo <$1m'},{key:'Jumbo $1m - $2m',value:'Jumbo $1m - $2m'},{key:'Jumbo >$2m',value:'Jumbo >$2m'},{key:'FHA',value:'FHA'},{key:'USDA',value:'USDA'},{key:'Other',value:'Other'}],
  loanPurposes:[{key:'Purchase',value:'Purchase'},{key:'Refinance',value:'Refinance'},{key:'R/T Refinance',value:'R/T Refinance'},{key:'C/O Refinance',value:'C/O Refinance'},{key:'HELOC/2nd Mortgage',value:'HELOC/2nd Mortgage'},{key:'Other',value:'Other'}],
  appraisalTypes:[],
  date:from,

  report:{
    assigned_accept:0,
    assigned_not_accept:0,
    cancel_hold:0,
    not_assigned:0,
    submitted:0,
    cc_auto:0,
    cc_auto_amount:0,
    cc_auto_cur_amount:0,
    cc_manual:0,
    cc_manual_amount:0,
    cc_manual_cur_amount:0,
    cc_refund_amount:0,
    cc_refund_cur_amount:0,
    cc_refund:0,
    cc_void:0,
    cc_void_amount:0,
    cc_void_cur_amount:0,
    cc_declined:0,
    cc_declined_cur:0,
    cc_not_charge_ytd:0,
    cc_charge_prior:0,
    carried_over_ytd:0,
    check_amount:0,
    check_ytd:0,
    check:0,
    check_cur:0,
    cc_total:0,
    cc_total_amount:0,
    cc_manual_refund_void:0,
    cc_charged_previously:0,
  },
  reportDetailsToggle:false,
  reportDetails:'',
  reportDetailsOrders:[],
  selectedReport:'',
};

//reducer function that perform state update
const reducer = getReducer();


const MasterTransactionReport  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getReport();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const reportDetailsToggle = () =>{
    if(state.reportDetailsDropDown)
      setState({reportDetailsOrders:[]});
    setState({reportDetailsDropDown:!state.reportDetailsDropDown});
  }

  //API call
  const getReport = () =>{
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);

    let callBack = apiCallBack([{state:'report', key:'data'}]);
    httpGet('report/masterTransaction/client='+state.client+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&to='+state.date+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&appraisalType='+appraisalType, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  const getReportDetails = (report) =>{
    setState({selectedReport:report});
    reportDetailsToggle();
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);

    let callBack = apiCallBack([{state:'reportDetailsOrders', key:'data'}]);
    httpGet('report/masterTransaction/details/client='+state.client+'&loanPurpose='+loanPurpose+'&report='+report+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&to='+state.date+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&appraisalType='+appraisalType, '', 'Oops, something went wrong and could not load the report details. Please try again later.', callBack);
  }

  //render
  let date = null;
  if(state.date!=='')
    date = moment(state.date);

  let content;
  let rows;
  switch(state.selectedReport){
    case "cc_auto_amount":
    case "cc_manual_amount":
    case "cc_void_amount":
    case "cc_refund_amount":
    case "cc_auto_cur_amount":
    case "cc_manual_cur_amount":
    case "cc_void_cur_amount":
    case "cc_refund_cur_amount":
    case "cc_auto":
    case "cc_charged_previously":
    case "cc_manual_refund_void":
    case "cc_total":
    case "cc_total_amount":
    case "check_amount":
    case "check_cur":
    case "cc_declined_cur":
    case "check_ytd":
    case "cc_charge_prior":
    case "cc_not_charge_ytd":
      rows  = state.reportDetailsOrders.map(
          (order, index)=>{
            return(
              <tr key={index}>
                <td><NavLink target="_blank" to={"/appraisal/"+order.ID}>{order.reference_num}</NavLink></td>
                <td>{order.transaction_num}</td>
                <td>{order.property_street+' '+order.property_city+', '+order.property_state+' '+order.property_zip}</td>
                <td>{order.loan_type}</td>
                <td>${formatNumber(order.amount)}</td>
              </tr>
            )
          }
        );

        content =
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Reference #</th>
              <th>Transaction #</th>
              <th>Address</th>
              <th>Loan type</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>;
      break;
    case "carried_over_ytd":
      rows  = state.reportDetailsOrders.map(
          (order, index)=>{
            return(
              <tr key={index}>
                <td><NavLink target="_blank" to={"/appraisal/"+order.ID}>{order.reference_num}</NavLink></td>
                <td>{order.company}</td>
                <td>{order.invoice_status}</td>
                <td>{order.property_street+' '+order.property_city+', '+order.property_state+' '+order.property_zip}</td>
                <td>{order.loan_type}</td>
                <td>${formatNumber(order.amount)}</td>
              </tr>
            )
          }
        );
      content =
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Reference #</th>
            <th>Client</th>
            <th>Receivable Status</th>
            <th>Address</th>
            <th>Loan type</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>;
      break;
    case "submitted":
    case "assigned_not_accept":
    case "assigned_accept":
    case "not_assigned":
    case "cancel_hold":
      rows  = state.reportDetailsOrders.map(
          (order, index)=>{
            return(
              <tr key={index}>
                <td><NavLink target="_blank" to={"/appraisal/"+order.ID}>{order.reference_num}</NavLink></td>
                <td>{order.appraisal_type}</td>
                <td>{order.property_street+' '+order.property_city+', '+order.property_state+' '+order.property_zip}</td>
                <td>{order.loan_type}</td>
                <td>{formatDateTime(order.datetime_submitted)}</td>
              </tr>
            )
          }
        );

        content =
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Reference #</th>
              <th>Report Types</th>
              <th>Address</th>
              <th>Loan type</th>
              <th>Date Submitted</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </table>;
      break;
    default:
      break;
  }

  let reportFiltersConfig = [
    {id:'from',value:date, updateFunc:(v)=>setState({date:v}), width:'2'},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'4'},
    {id:'rush',value:state.isRush, updateFunc:(v)=>setState({isRush:v}), width:'3'},
    {id:'complex',value:state.isComplex, updateFunc:(v)=>setState({isComplex:v}), width:'3'},
    {id:'state',value:state.state, updateFunc:(v)=>setState({state:v}), width:'3'},
    {id:'city',value:state.city, updateFunc:(v)=>setState({city:v}), width:'3'},
    {id:'loanPurpose',value:state.loanPurpose, updateFunc:(v)=>setState({loanPurpose:v}), width:'3'},
    {id:'loanType',value:state.loanType, updateFunc:(v)=>setState({loanType:v}), width:'3'},
    {id:'entity',value:state.entity, updateFunc:(v)=>setState({entity:v}), width:'4'},
    {id:'appraisalType',value:state.filterAppraisalTypes, updateFunc:(v)=>setState({filterAppraisalTypes:v}), width:'4'},
    {id:'button',value:'Submit', updateFunc:getReport, width:'4', className:"align-right", color:"warning"},
  ];

  return <div>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Master Transaction Report
      </CardHeader>
      <CardBody>
        <ReportFilter {...props} configs={reportFiltersConfig}/>
        <div className="my-divider">&nbsp;</div>
        <Row>
          <Col sm="4">
            <div className="padding">
              <Card>
                <CardHeader>Total Authorize.net Transaction and Orders</CardHeader>
                <CardBody>
                  <Row>
                    <Col sm="8">
                      <label>CC Charges</label>
                    </Col>
                    <Col sm="4">
                      <NavLink to="#" onClick={(e)=>getReportDetails('cc_auto_amount')}>${formatNumber(state.report.cc_auto_amount)}</NavLink>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="8">
                      <label>Manual Charges</label>
                    </Col>
                    <Col sm="4">
                      <NavLink to="#" onClick={(e)=>getReportDetails('cc_manual_amount')}>${formatNumber(state.report.cc_manual_amount)}</NavLink>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="8">
                      <label>Refund</label>
                    </Col>
                    <Col sm="4">
                      <NavLink to="#" onClick={(e)=>getReportDetails('cc_refund_amount')}>${formatNumber(state.report.cc_refund_amount)}</NavLink>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="8">
                      <label>Void</label>
                    </Col>
                    <Col sm="4">
                      <NavLink to="#" onClick={(e)=>getReportDetails('cc_void_amount')}>${formatNumber(state.report.cc_void_amount)}</NavLink>
                    </Col>
                  </Row>
                  <div className="my-divider"></div>
                  <Row>
                    <Col sm="8">
                      <label>Order Charged</label>
                    </Col>
                    <Col sm="4">
                      <NavLink to="#" onClick={(e)=>getReportDetails('cc_auto')}>{formatNumber(state.report.cc_auto)}</NavLink>
                    </Col>
                    <Col sm="8">
                      <label>Orders (previously submitted) charged</label>
                    </Col>
                    <Col sm="4">
                      <NavLink to="#" onClick={(e)=>getReportDetails('cc_charged_previously')}>{formatNumber(state.report.cc_charged_previously)}</NavLink>
                    </Col>
                    <Col sm="8">
                      <label>Manual Charges/Refunds/Voids/Declined</label>
                    </Col>
                    <Col sm="4">
                      <NavLink to="#" onClick={(e)=>getReportDetails('cc_manual_refund_void')}>{formatNumber(state.report.cc_manual_refund_void)}</NavLink>
                    </Col>
                    <Col sm="8">
                      <label>Total Transactions (CC Charges)</label>
                    </Col>
                    <Col sm="4">
                      <NavLink to="#" onClick={(e)=>getReportDetails('cc_total')}>{formatNumber(state.report.cc_total)}</NavLink>
                    </Col>
                  </Row>
                  <br/>
                  <Row>
                    <Col sm="8">
                      <label>Total Income (Authorized.Net)</label>
                    </Col>
                    <Col sm="4">
                      <NavLink to="#" onClick={(e)=>getReportDetails('cc_total_amount')}>${formatNumber(state.report.cc_total_amount)}</NavLink>
                    </Col>
                  </Row>
                  <br/>
                  <Row>
                    <Col sm="8">
                      <label>Checks</label>
                    </Col>
                    <Col sm="4">
                      <NavLink to="#" onClick={(e)=>getReportDetails('check_amount')}>${formatNumber(state.report.check_amount)}</NavLink>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Col>
          <Col sm="4">
            <div className="padding">
              <Card>
                <CardHeader>Order Received {state.date} Only</CardHeader>
                <CardBody>
                  <Row>
                    <Col sm="8">
                      <label>CC Charges</label>
                    </Col>
                    <Col sm="4">
                      <NavLink to="#" onClick={(e)=>getReportDetails('cc_auto_cur_amount')}>${formatNumber(state.report.cc_auto_cur_amount)}</NavLink>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="8">
                      <label>Manual Charges</label>
                    </Col>
                    <Col sm="4">
                      <NavLink to="#" onClick={(e)=>getReportDetails('cc_manual_cur_amount')}>${formatNumber(state.report.cc_manual_cur_amount)}</NavLink>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="8">
                      <label>Refund</label>
                    </Col>
                    <Col sm="4">
                      <NavLink to="#" onClick={(e)=>getReportDetails('cc_refund_cur_amount')}>${formatNumber(state.report.cc_refund_cur_amount)}</NavLink>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="8">
                      <label>Void</label>
                    </Col>
                    <Col sm="4">
                      <NavLink to="#" onClick={(e)=>getReportDetails('cc_void_cur_amount')}>${formatNumber(state.report.cc_void_cur_amount)}</NavLink>
                    </Col>
                  </Row>
                  <div className="my-divider"></div>
                  <Row>
                    <Col sm="8">
                      <label>Submitted</label>
                    </Col>
                    <Col sm="4">
                      <NavLink to="#" onClick={(e)=>getReportDetails('submitted')}>{formatNumber(state.report.submitted)}</NavLink>
                    </Col>
                    <Col sm="8">
                      <label>Not Assigned</label>
                    </Col>
                    <Col sm="4">
                      <NavLink to="#" onClick={(e)=>getReportDetails('not_assigned')}>{formatNumber(state.report.not_assigned)}</NavLink>
                    </Col>
                    <Col sm="8">
                      <label>Assigned/Not Accepted</label>
                    </Col>
                    <Col sm="4">
                      <NavLink to="#" onClick={(e)=>getReportDetails('assigned_not_accept')}>{formatNumber(state.report.assigned_not_accept)}</NavLink>
                    </Col>
                    <Col sm="8">
                      <label>Assigned and Accepted</label>
                    </Col>
                    <Col sm="4">
                      <NavLink to="#" onClick={(e)=>getReportDetails('assigned_accept')}>{formatNumber(state.report.assigned_accept)}</NavLink>
                    </Col>
                    <Col sm="8">
                      <label>Cancelled & Hold</label>
                    </Col>
                    <Col sm="4">
                      <NavLink to="#" onClick={(e)=>getReportDetails('cancel_hold')}>{formatNumber(state.report.cancel_hold)}</NavLink>
                    </Col>
                  </Row>
                  <br/>
                  <Row>
                    <Col sm="8">
                      <label>Declined Charges</label>
                    </Col>
                    <Col sm="4">
                      <NavLink to="#" onClick={(e)=>getReportDetails('cc_declined_cur')}>{formatNumber(state.report.cc_declined_cur)}</NavLink>
                    </Col>
                  </Row>
                  <br/>
                  <Row>
                    <Col sm="8">
                      <label>Checks</label>
                    </Col>
                    <Col sm="4">
                      <NavLink to="#" onClick={(e)=>getReportDetails('check_cur')}>{formatNumber(state.report.check_cur)}</NavLink>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Col>
          <Col sm="4">
            <div className="padding">
              <Card>
                <CardHeader>Year to Date</CardHeader>
                <CardBody>
                  <Row>
                    <Col sm="8">
                      <label>Balance carried over from Yesterday (Orders Not Charged)</label>
                    </Col>
                    <Col sm="4">
                      <NavLink to="#" onClick={(e)=>getReportDetails('carried_over_ytd')}>{formatNumber(state.report.carried_over_ytd)}</NavLink>
                    </Col>
                    <Col sm="8">
                      <label>Orders Not Charged Today</label>
                    </Col>
                    <Col sm="4">
                      <NavLink to="#" onClick={(e)=>getReportDetails('cc_not_charge_ytd')}>{formatNumber(state.report.cc_not_charge_ytd)}</NavLink>
                    </Col>
                    <Col sm="8">
                      <label>Sub Total</label>
                    </Col>
                    <Col sm="4">
                      {formatNumber((parseInt(state.report.carried_over_ytd,10)+parseInt(state.report.cc_not_charge_ytd,10)))}
                    </Col>
                    <Col sm="8">
                      <label>Less Prior Orders Charged Today</label>
                    </Col>
                    <Col sm="4">
                      <NavLink to="#" onClick={(e)=>getReportDetails('cc_charge_prior')}>{formatNumber(state.report.cc_charge_prior)}</NavLink>
                    </Col>
                    <Col sm="8">
                      <label>Plus Checks</label>
                    </Col>
                    <Col sm="4">
                      <NavLink to="#" onClick={(e)=>getReportDetails('check_ytd')}>{formatNumber(state.report.check_ytd)}</NavLink>
                    </Col>
                    <Col sm="8">
                      <label>Total</label>
                    </Col>
                    <Col sm="4">
                      {formatNumber((parseInt(state.report.carried_over_ytd,10)+parseInt(state.report.cc_not_charge_ytd,10)-parseInt(state.report.cc_charge_prior,10)+parseInt(state.report.check_ytd,10)))}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
    <Modal className="my-modal-wide" isOpen={state.reportDetailsDropDown} toggle={reportDetailsToggle} >
      <ModalHeader hidden={true} toggle={reportDetailsToggle}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-user"></i> Report Details</h5>
        </center>
        <br/>
        <div className="large-scroll-container">
          {content}
        </div>
        <br/>
        <center>
          <Button color="info" onClick={reportDetailsToggle}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
  </div>;
}


export default MasterTransactionReport;
