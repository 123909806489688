//Author Sooyoung Kim
//Date June 26, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, getFromTo, formatDateTime, formatNumber} from '../../../util/util';
import {Button, Card, CardHeader, CardBody, Col, Row, Modal, ModalBody, ModalHeader, NavItem, Nav, NavLink as NavLinkL, TabContent, TabPane, } from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import moment from 'moment';
import {NavLink} from 'react-router-dom';
import MyReactTable from '../../util/my-react-table';
import ReportFilter from '../report-filter';

//initialize the state
const initialState = {
  reportDetails:[],
  score:'',
  from:getFromTo('start', 1),
  to:getFromTo('end'),
  client:'',
  clients:[],
  state:'',
  states:'',
  author:'',
  appraiser:'',
  entity:'',
  opsStats:{},
  appraiserStats:{},
  activeTab:'3',
  items:[],
  predefinedItems:[],
  selectedItem:-1,
  selectedDetails:[],
  showDetailsDropDownOpen:false
};

//reducer function that perform state update
const reducer = getReducer();


const AppraiserServiceRating  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getReportDetails();
    getPredefinedItems();
    
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const toggleShowDetailsDropDown = ()=>{
    setState({showDetailsDropDownOpen:!state.showDetailsDropDownOpen});
  }

  const selectDetails = (selectedDetails)=>{
    setState({selectedDetails:selectedDetails, showDetailsDropDownOpen:true});
  }

  const removeItem = (item)=>{
    let items = state.items.slice();

    for(let i=0;i<items.length;i++){
      if(items[i].ID===item){
        items.splice(i, 1);
        break;
      }
    }
    setState({items:items});
  }

  const addItem = (item)=>{
    setState({selectedItem:item});

    let name;

    for(let i=0;i<state.predefinedItems.length;i++){
      if(state.predefinedItems[i].ID===item)
        name = state.predefinedItems[i].description;
    }

    let items = state.items.slice();

    let found = false;
    for(let i=0;i<items.length;i++){
      if(items[i].ID===item){
        found = true;
        break;
      }
    }

    if(!found){
      let tmp = {
        ID:item,
        name: name
      }

      items.push(tmp);
    }

    setState({items:items});
  }

  const setActiveTabToggle = (tab)=>{
    setState({activeTab:tab});
  }

  //API call
  const getReportDetails = ()=>{
    let items = [];
    for(let i=0;i<state.items.length;i++){
      items.push(state.items[i].ID);
    }

    let item = items.join('|');
    let callBack = apiCallBack([{state:'reportDetails', key:'data.report'},{state:'opsStats', key:'data.ops_stats'},{state:'appraiserStats', key:'data.appraiser_stats'}]);
    httpGet('report/serviceRating/item='+item+'&from='+state.from+'&to='+state.to+'&score='+state.score+'&author='+state.author+'&appraiser='+state.appraiser+'&state='+state.state+'&client='+state.client+'&entity='+state.entity, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  const getPredefinedItems = ()=>{
    let callBack = apiCallBack([{state:'predefinedItems', key:'data'}]);
    httpGet('appraiser/serviceRating/get', '', 'Oops, something went wrong and could not retrieve a list of service rating items.', callBack);
  }

  //render
  let from, to;
  if(state.from!=='')
    from = moment(state.from);
  if(state.to!=='')
    to = moment(state.to);

  let opsStats=[];
  let appraiserStats=[];

  if(Object.keys(state.opsStats).length>0){
    Object.keys(state.opsStats).map((key, index)=>{

      let ratings = Object.keys(state.opsStats[key]).map((key2, index2)=>{
          return <div key={index2}>{key2}</div>
      });

      let total_count = 0;
      let count = Object.keys(state.opsStats[key]).map((key2, index2)=>{
          total_count = total_count + parseInt(state.opsStats[key][key2].counter,10);
          return <div key={index2}><NavLink to="#" onClick={(e)=>{e.preventDefault();selectDetails(state.opsStats[key][key2].orders)}}>{formatNumber(state.opsStats[key][key2].counter)}</NavLink></div>
      })

      let tmp = {
        name:key,
        ratings:ratings,
        count:count,
        total_count:total_count
      };

      opsStats.push(tmp);
      return null;
    })
  }

  if(Object.keys(state.appraiserStats).length>0){
    Object.keys(state.appraiserStats).map((key, index)=>{

      let ratings = Object.keys(state.appraiserStats[key].stats).map((key2, index2)=>{
          return <div key={index2}>{key2}</div>
      });

      let total_count = 0;
      let count = Object.keys(state.appraiserStats[key].stats).map((key2, index2)=>{
          total_count = total_count + parseInt(state.appraiserStats[key].stats[key2].counter,10);
          return <div key={index2}><NavLink to="#" onClick={(e)=>{e.preventDefault();selectDetails(state.appraiserStats[key].stats[key2].orders)}} >{formatNumber(state.appraiserStats[key].stats[key2].counter)}</NavLink></div>
      })

      let tmp = {
        name:state.appraiserStats[key].name,
        ratings:ratings,
        count:count,
        total_count:total_count
      };

      appraiserStats.push(tmp);
      return null;
    })
  }

  const columns1 = [
    {
      id: 'ops',
      Header: 'Ops',
      accessor: d => d.name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'rating',
      Header: 'Rating',
      accessor: d => d.ratings,
      Cell: props => props.row.original.ratings,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'count',
      Header: 'Count',
      accessor: d => d.count,
      Cell: props => props.row.original.count,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'totalCount',
      Header: 'Total Count',
      accessor: d => d.total_count,
      headerStyle: {
        textAlign:'left'
      }
    },
  ];

  const columns2 = [
    {
      id: 'appraiser',
      Header: 'Appraiser',
      accessor: d => d.name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'rating',
      Header: 'Rating',
      accessor: d => d.ratings,
      Cell: props => props.row.original.ratings,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'count',
      Header: 'Count',
      accessor: d => d.count,
      Cell: props => props.row.original.count,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'totalCount',
      Header: 'Total Count',
      accessor: d => d.total_count,
      headerStyle: {
        textAlign:'left'
      }
    },
  ];

  const columns3 = [
    {
      id: 'reference_num',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'address',
      Header: 'Address',
      accessor: d => d.ratings,
      Cell: props => props.row.original.property_street+' '+props.row.original.property_city+', '+props.row.original.property_state+' '+props.row.original.property_zip,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'appraiser',
      Header: 'Appraiser',
      accessor: d => d.appraiser,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'rating',
      Header: 'Rating',
      accessor: d => d.rating,
      Cell: props => props.row.original.score+' out of 5.00',
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'changeDate',
      Header: 'Change Date',
      accessor: d => d.datetime_created,
      headerStyle: {
        textAlign:'left'
      }
    },
  ];

  const columns4 = [
    {
      id: 'referenceNum',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'loanNum',
      Header: 'Loan #',
      accessor: d => d.loan_num,
      Cell: props=> props.row.original.loan_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'borrower',
      Header: 'Borrower',
      accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'address',
      Header: 'Address',
      accessor: d => d.property_street+' '+d.property_city+', '+d.property_state,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'author',
      Header: 'Target',
      accessor: d => d.author,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'datetime_submitted',
      Header: 'Date Submitted',
      accessor: d => formatDateTime(d.datetime_submitted),
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  let reportFiltersConfig = [
    {id:'from',value:from, updateFunc:(v)=>setState({from:v}), width:'3'},
    {id:'to',value:to, updateFunc:(v)=>setState({to:v}), width:'3'},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'3'},
    {id:'custom',value:state.appraiser, updateFunc:(v)=>setState({appraiser:v}), width:'3', label:'Appraiser', placeHolder:"Name"},
    {id:'customSelect',value:state.selectedItem, updateFunc:(v)=>{addItem(v)}, width:'3', label:'Service Rating Item', clearable:true, className:"", options:state.predefinedItems.map((item)=>{return {label:item.description, value:item.ID}})},
    {id:'custom',value:state.author, updateFunc:(v)=>setState({author:v}), width:'3', label:'Author', placeHolder:""},
    {id:'entity',value:state.entity, updateFunc:(v)=>setState({entity:v}), width:'3'},
    {id:'button',value:'Submit', updateFunc:getReportDetails, width:'3', className:"align-right", color:"warning"},
  ];

  return <div>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Service Rating Report
      </CardHeader>
      <CardBody>
        <ReportFilter {...props} configs={reportFiltersConfig}/>
        <br/>
        <Row>
          <Col sm="9">
            {
              state.items.map(
                (item, index)=>{
                  return <div key={index} className="my-well display-inline cursor-pointer" onClick={(e)=>removeItem(item.ID)} style={{padding:'5px', marginRight:'5px'}}>
                    <i className="fa fa-minus red-color"/> {item.name}
                  </div>
                }
              )
            }
          </Col>
        </Row>
        <div className="my-divider">&nbsp;</div>
        <Nav tabs>
          <NavItem>
            <NavLinkL
              className={"nav-link "+(state.activeTab === '1'?"active":"inactive" )}
              onClick={() => { setActiveTabToggle('1'); }}
              to="#"
            >
              Ratings
            </NavLinkL>
          </NavItem>
          <NavItem>
            <NavLinkL
              className={"nav-link "+(state.activeTab === '2'?"active":"inactive" ) }
              onClick={() => { setActiveTabToggle('2'); }}
              to="#"
            >
              Ops Stats
            </NavLinkL>
          </NavItem>
          <NavItem>
            <NavLinkL
              className={"nav-link "+(state.activeTab === '3'?"active":"inactive" ) }
              onClick={() => { setActiveTabToggle('3'); }}
              to="#"
            >
              Appraiser Stats
            </NavLinkL>
          </NavItem>
        </Nav>

        <TabContent activeTab={state.activeTab}>
          <TabPane tabId="1">
            <MyReactTable columns={columns3} data={state.reportDetails} className="table table-striped"/>
          </TabPane>
          <TabPane tabId="2">
            <MyReactTable columns={columns1} data={opsStats} className="table table-striped"/>
          </TabPane>
          <TabPane tabId="3">
            <MyReactTable columns={columns2} data={appraiserStats} className="table table-striped"/>
          </TabPane>
        </TabContent>
      </CardBody>
    </Card>

    <Modal className="my-modal-wide" isOpen={state.showDetailsDropDownOpen} toggle={toggleShowDetailsDropDown} >
      <ModalHeader hidden={true} toggle={toggleShowDetailsDropDown}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-user"></i> Report Details</h5>
        </center>
        <br/>
        <div className="large-scroll-container">
          <MyReactTable columns={columns4} data={state.selectedDetails} className="table table-striped"/>
        </div>
        <br/>
        <center>
          <Button color="info" onClick={toggleShowDetailsDropDown}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
  </div>;
}


export default AppraiserServiceRating;
