//Author Sooyoung Kim
//Date July 5th, 2022
import {formatDate, formatNumber, getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, encodeParam} from '../../util/util';
import InfiniteScroll from 'react-infinite-scroll-component';
import {Input, Button, Row, Col} from 'reactstrap';
import {NavLink, useParams, useNavigate } from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';
import MySelect from '../util/my-select';


//initialize the state
const initialState = {
  appraisers:[],
  limit: 25,
  offset: 0,
  hasMoreAppraisers: true,
  loading: false,
  statuses: [],
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
};

//reducer function that perform state update
const reducer = getReducer();

const Appraisers  = (props)=>{
  const controller = new AbortController();
  let history = useNavigate();
  let {params} = useParams();

  let company = '';
  let name = '';
  let email = '';
  let stateName = '';
  let county = '';
  let city = '';
  let licenseType = '';
  let licenseNumber = '';
  let envReady = '';
  let deskReview = '';
  let desktopAppraisalReady = '';
  let remoteAppraisalReady = '';
  let greenRenovateExp = '';
  let status = '';
  let order = '';
  let sort = '';

  if(params&&params!==''){
    params = params.split('&');
    if(params)
      for(let i=0;i<params.length;i++){
        let token = params[i].split('=');
        token[1] = token[1].replace('ForwardSlash', '/');
        token[1] = token[1].replace('Ampersand', '&');
        token[1] = decodeURIComponent(token[1]);

        switch(token[0]){
          case 'company':
            company = token[1];
            break;
          case 'name':
            name = token[1];
            break;
          case 'email':
            email = token[1];
            break;
          case 'state':
            stateName = token[1];
            break;
          case 'county':
            county = token[1];
            break;
          case 'city':
            city = token[1];
            break;
          case 'licenseType':
            licenseType = token[1];
            break;
          case 'licenseNumber':
            licenseNumber = token[1];
            break;
          case 'envReady':
            envReady = token[1];
            break;
          case 'desktopAppraisalReady':
            desktopAppraisalReady = token[1];
            break;
          case 'deskReview':
            deskReview = token[1];
            break;
          case 'remoteAppraisalReady':
            remoteAppraisalReady = token[1];
            break;
          case 'greenRenovateExp':
            greenRenovateExp = token[1];
            break;
          case 'status':
            status = token[1];
            break;
          case 'order':
            order = token[1];
            break;
          case 'sort':
            sort = token[1];
            break;
          default:

            break;
        }
      }
  }

  let newInitialState = Object.assign({}, initialState, {
    sort: sort,
    order: order,
    status: status,

    //for search
    name:name,
    company_name:company,
    email:email,
    stateName:stateName,
    county:county,
    city:city,
    green_renovate_exp:greenRenovateExp,
    license_type:licenseType,
    license_number:licenseNumber,
    env_ready:envReady,
    desktop_appraisal_ready:desktopAppraisalReady,
    desk_review:deskReview,
    remote_appraisal_ready:remoteAppraisalReady,
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getAllAppraiserStatuses();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    if(state.appraisers.length<=0 && state.hasMoreAppraisers){
        loadMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state]);

  //non API call but simpyl manage state
  const navigateURL = (url)=>{
    let company = encodeParam(state.company_name);
    let name = encodeParam(state.name);
    let county = encodeParam(state.county);
    let city = encodeParam(state.city);
    let email = encodeParam(state.email);

    let param = 'status='+state.status+'&order='+state.order+'&sort='+state.sort+'&licenseType='+state.license_type+'&licenseNumber='+state.license_number+'&greenRenovateExp='+state.green_renovate_exp+'&envReady='+state.env_ready+'&desktopAppraisalReady='+state.desktop_appraisal_ready+'&deskReview='+state.desk_review+'&remoteAppraisalReady='+state.remote_appraisal_ready+'&name='+name+'&company='+company+'&state='+state.stateName+'&county='+county+'&city='+city+'&email='+email;

    history(url+'/'+param);
  }

  //click handler that switch the state of sorting order then call the function to refresh the list
  //to achieve the "sorting"
  const columnClickHandler = (col)=>{
    if(state.sort===col){
      if(state.order==='ASC')
        setState({order:'DESC'});
      else
        setState({order:'ASC'});
    }
    else
      setState({sort:col,order:'ASC'});
    refreshList();
  }


  //this function refresh the list of orders pipeline
  const refreshList = ()=>{
    setState({
      appraisers:[],
      totalCount:0,
      hasMoreAppraisers:true,
      offset:0
    });

    let company = encodeParam(state.company_name);
    let name = encodeParam(state.name);
    let county = encodeParam(state.county);
    let city = encodeParam(state.city);
    let email = encodeParam(state.email);

    let param = 'status='+state.status+'&sort='+state.sort+'&order='+state.order+'&licenseNumber='+state.license_number+'&licenseType='+state.license_type+'&env_ready='+state.env_ready+'&desktop_appraisal_ready='+state.desktop_appraisal_ready+'&remote_appraisal_ready='+state.remote_appraisal_ready+'&name='+name+'&company='+company+'&state='+state.stateName+'&county='+county+'&city='+city+'&email='+email;
    history("/appraisers/"+param);
  }

  //this function render the sort icon at each column of the table
  const renderSortIcon = (col)=>{
    if(state.sort===col){
      if(state.order==='ASC')
        return <i className="red-color fa fa-arrow-down"></i>
      else
        return <i className="red-color fa fa-arrow-up"></i>
    }
  }

  //render function for infinite scroller
  const renderAppraisers = ()=>{
    let company = encodeParam(state.company_name);
    let name = encodeParam(state.name);
    let county = encodeParam(state.county);
    let city = encodeParam(state.city);
    let email = encodeParam(state.email);

    let param = 'status='+state.status+'&order='+state.order+'&sort='+state.sort+'&licenseType='+state.license_type+'&licenseNumber='+state.license_number+'&envReady='+state.env_ready+'&desktopAppraisalReady='+state.desktop_appraisal_ready+'&remoteAppraisalReady='+state.remote_appraisal_ready+'&name='+name+'&company='+company+'&state='+state.stateName+'&county='+county+'&city='+city+'&email='+email;


    return (
      <div>
        <table className="primary-table">
          <thead>
            <tr>
              <th className="cursor-pointer" width="12%" onClick={()=>columnClickHandler('first_name')}><i className="fa fa-user"></i>&nbsp;Name {renderSortIcon('first_name')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('license_type')}><i className="fa fa-info"></i>&nbsp;License Type {renderSortIcon('license_type')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('status')}><i className="fa fa-info"></i>&nbsp;Status {renderSortIcon('status')}</th>
              <th className="cursor-pointer" width="12%" onClick={()=>columnClickHandler('city')}><i className="fa fa-map-marker"></i>&nbsp;Location {renderSortIcon('city')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('avg_tat')}><i className="fa fa-check-square-o"></i>&nbsp;Avg TAT {renderSortIcon('avg_tat')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('avg_fee')}><i className="fa fa-usd"></i>&nbsp;Avg Fee {renderSortIcon('avg_fee')}</th>
              <th className="cursor-pointer" width="8%" onClick={()=>columnClickHandler('avg_service_rating')}><i className="fa fa-users"></i>&nbsp;Service Rating {renderSortIcon('avg_service_rating')}</th>
              <th className="cursor-pointer" width="8%" onClick={()=>columnClickHandler('avg_quality_rating')}><i className="fa fa-users"></i>&nbsp;Quality Rating {renderSortIcon('avg_quality_rating')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('email')}><i className="fa fa-envelope-open"></i>&nbsp;Email {renderSortIcon('email')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('datetime_created')}><i className="fa fa-calendar-o"></i>&nbsp;Date Created {renderSortIcon('datetime_created')}</th>
            </tr>
          </thead>
          <tbody>
          {
            state.appraisers.map(
              (appraiser)=>{
                let presidentClub = <div className="display-inline" style={{minWidth:'20px'}}></div>;

                if(appraiser.president_club==='yes')
                  presidentClub = <div className="display-inline" style={{minWidth:'20px'}}><i className="fa fa-trophy orange-color"></i></div>;

                return (
                  <tr key={appraiser.ID} onClick={()=>{navigateURL('/appraiser/'+appraiser.ID)}}>
                    <td><NavLink to={"/appraiser/"+appraiser.ID+'/'+param}>{presidentClub}{appraiser.first_name+" "+appraiser.last_name}</NavLink></td>
                    <td>{appraiser.license_type}</td>
                    <td>{appraiser.status}</td>
                    <td>{appraiser.city+" "+appraiser.county+" "+appraiser.state}</td>
                    <td>{appraiser.avg_tat}</td>
                    <td>{"$ "+appraiser.avg_fee}</td>
                    <td>{parseFloat(appraiser.avg_service_rating)>=0?parseFloat(appraiser.avg_service_rating).toFixed(2):'NA'}/5.00</td>
                    <td>{parseFloat(appraiser.avg_quality_rating)>=0?parseFloat(appraiser.avg_quality_rating).toFixed(2):'NA'}/3.00</td>
                    <td>{appraiser.email}</td>
                    <td>{formatDate(appraiser.datetime_created)}</td>
                  </tr>
                );
              }
            )
          }
          </tbody>
        </table>
      </div>
    )
  }

  //API call
  //function that fire when the infinite scroll reach bottom
  const loadMore = ()=>{
    //do not load if there is no more conditions or it's loading data
    if(state.hasMoreAppraisers&&!state.loading){
      //set loading equals to true so it won't fire off before we are done
      setState({loading:true});

      let company_name = encodeParam(state.company_name);
      let name = encodeParam(state.name);
      let city = encodeParam(state.city);
      let email = encodeParam(state.email);

      let url = 'appraiser/get/limit='+state.limit+'&offset='+state.offset+'&order='+state.order+'&license_number='+state.license_number+'&sort='+state.sort+'&license_type='+state.license_type+'&env_ready='+state.env_ready+'&desktop_appraisal_ready='+state.desktop_appraisal_ready+'&remote_appraisal_ready='+state.remote_appraisal_ready+'&status='+state.status+'&name='+name+'&company_name='+company_name+'&email='+email+'&state='+state.stateName+'&county='+state.county+'&city='+city;

      //callback handler that update the state when http request return
      let callBack = (response)=>{console.log(response);
        let code = response.data?response.data.code:undefined;

        if(code!=='00'){
          setState({hasMoreAppraisers:false});
        }
        else{
          let newAppraiser = response.data.data;
          let hasMoreAppraisers = true;
          let newOffset = state.offset;
          let totalCount = response.data.count;

          //if http request return empty then no more results, end of list
          if(newAppraiser.length<=0){
            hasMoreAppraisers = false;
          }
          else{
            //increment the offset
            newOffset = state.offset + 1;
          }

          //concat the current array of announcement
          if(state.appraisers.length>0){
            let temp = [...state.appraisers,...newAppraiser];

            setState({appraisers:temp});
          }
          else
            setState({appraisers:newAppraiser});

          setState({hasMoreAppraisers:hasMoreAppraisers, offset:newOffset, totalCount:totalCount});
        }
      };
      callBack = callBack.bind(this);

      //error handler when the http request return with error
      let errorCallBack = apiCallBack([{state:'hasMoreAppraisers', value:false}]);

      //collect the promise and wait for it to finish performing it's task
      let promises = httpGet(url, '','Oops, something went wrong and could not load appraisers. Please try again later.', callBack, errorCallBack);
      promises
        .then(
          function(result){
            //set loading equals to false so the function could be fire off once again
            setState({loading:false});
          }
        );
    }
  }

  //get a list of appraiser status for the drop down
  const getAllAppraiserStatuses = ()=>{
    let url = 'appraiser/statuses/get';
    let callBack = apiCallBack([{state:'statuses', key:'data'}]);
    httpGet(url, '','Oops, something went wrong and could not retrieve appraiser statuses.', callBack);
  }

  //render
  return <div>
  <InfiniteScroll
    next={loadMore}
    dataLength={state.appraisers.length}
    hasMore={state.hasMoreAppraisers}
    loader={<div key="nill" className="loader"><center>Loading more appraisers...</center></div>}
    initialLoad = {true}
    className="my-well"
    scrollableTarget="contentContainer"
  >

    <div className="page-title">
      <i className="fa fa-reorder"></i>&nbsp;Appraisers
    </div>

    <Row>
      <Col sm="2">
        <label className="no-margin-bottom">Status</label>
        <MySelect
          type="select"
          selectIsClearable={true}
          value={state.status}
          onChange={(v)=>setState({status:v})}
          options={[{label:"All",value:""}].concat(
            state.statuses.map((status)=>{
              return {label:status.status, value:status.status};
            })
          )}
        />
      </Col>
      <Col sm="2">
        <label className="no-margin-bottom">ENV Ready</label>
        <MySelect
          type="select"
          selectIsClearable={true}
          value={state.env_ready}
          onChange={(v)=>setState({env_ready:v})}
          options={[{label:"All",value:""},{label:"Yes",value:"yes"},{label:"No",value:"no"},{label:"Unknown",value:"unknown"}]}
        />
      </Col>
      <Col sm="2">
        <label className="no-margin-bottom">Desktop Appraisal Ready</label>
        <MySelect
          type="select"
          selectIsClearable={true}
          value={state.desktop_appraisal_ready}
          onChange={(v)=>setState({desktop_appraisal_ready:v})}
          options={[{label:"All",value:""},{label:"Yes",value:"yes"},{label:"No",value:"no"},{label:"Unknown",value:"unknown"}]}
        />
      </Col>
      <Col sm="2">
        <label className="no-margin-bottom">Remote Appraisal Ready</label>
        <MySelect
          type="select"
          selectIsClearable={true}
          value={state.remote_appraisal_ready}
          onChange={(v)=>setState({remote_appraisal_ready:v})}
          options={[{label:"All",value:""},{label:"Yes",value:"yes"},{label:"No",value:"no"},{label:"Unknown",value:"unknown"}]}
        />
      </Col>
      <Col sm="2">
        <label className="no-margin-bottom">License Type</label>
        <MySelect
          type="select"
          selectIsClearable={true}
          value={state.license_type}
          onChange={(v)=>setState({license_type:v})}
          options={[{label:"Unknown",value:"Unknown"},{label:"Licensed",value:"Licensed"},{label:"Certified Residential",value:"Certified Residential"},{label:"Certified General",value:"Certified General"}]}
        />
      </Col>
      <Col sm="2">
        <label className="no-margin-bottom">Desk Review</label>
        <MySelect
          type="select"
          selectIsClearable={true}
          value={state.desk_review}
          onChange={(v)=>setState({desk_review:v})}
          options={[{label:"Yes",value:"yes"},{label:"No",value:"no"},{label:"Unknown",value:"unknown"}]}
        />
      </Col>
    </Row>
    <Row>
      <Col sm="3">
        <label className="no-margin-bottom">Name</label>
        <Input type="text" value={state.name} onChange={(e)=>setState({name:e.target.value})}/>
      </Col>
      <Col sm="3">
        <label className="no-margin-bottom">Company Name</label>
        <Input type="text" value={state.company_name} onChange={(e)=>setState({company_name:e.target.value})}/>
      </Col>
      <Col sm="3">
        <label className="no-margin-bottom">Email</label>
        <Input type="text" value={state.email} onChange={(e)=>setState({email:e.target.value})}/>
      </Col>
      <Col sm="3">
        <label className="no-margin-bottom">License Number</label>
        <Input type="text" value={state.license_number} onChange={(e)=>setState({license_number:e.target.value})}/>
      </Col>
    </Row>
    <Row>
      <Col sm="3">
        <label className="no-margin-bottom">State</label>
        <MySelect
          type="select"
          selectIsClearable={true}
          value={state.stateName}
          onChange={(v)=>setState({stateName:v})}
          options={[{label:"All",value:""}].concat(
            state.states.map((state)=>{
              return {label:state.value, value:state.value};
            })
          )}
        />
      </Col>
      <Col sm="2">
        <label className="no-margin-bottom">County</label>
        <Input type="text" value={state.county} onChange={(e)=>setState({county:e.target.value})}/>
      </Col>
      <Col sm="2">
        <label className="no-margin-bottom">City</label>
        <Input type="text" value={state.city} onChange={(e)=>setState({city:e.target.value})}/>
      </Col>
      <Col sm="2">
        <label className="no-margin-bottom">Green Mortgage Experience</label>
        <MySelect
          type="select"
          selectIsClearable={true}
          value={state.green_renovate_exp}
          onChange={(v)=>setState({green_renovate_exp:v})}
          options={[{label:"Very Experienced",value:"very"},{label:"Some Experienced",value:"some"},{label:"No Experience",value:"no"},{label:"Experience Unknown",value:"unknown"}]}
        />
      </Col>
      <Col sm="3" className="align-right valign-bottom">
        <br/>
        <Button color="warning" onClick={()=>refreshList()}>Submit</Button>
      </Col>
    </Row>
    <br/>

    <div className="my-divider"></div>
    <Row>
      <Col sm="6">
          <div>{formatNumber(state.totalCount)} appraisers in queue<br/><br/></div>
      </Col>
      <Col sm="6" className="align-right">
        <NavLink to={'/appraiser/new'}><i className="fa fa-plus green-color"></i> Create new appraiser</NavLink>
      </Col>
    </Row>
    {renderAppraisers()}
  </InfiniteScroll>
  </div>;
}

export default Appraisers;
