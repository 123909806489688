//Author June Yee Leow
//Date Jul 25th, 2022
//This script serve as a utility function for property data reports
import {asyncDelete} from '../../util/util';

export function deletePhoto(ID, props, successCallBack){
  let url = 'propertyDataReport/photo/'+ID;
  let successMsg = '';
  let errorMsg = 'Oops, something went wrong and could not delete this photo. Please try again later.';

  //control is the object that holds control function from parent
  let control = {
    showLoading: props.showLoading,
    hideLoading: props.hideLoading,
    logout: props.logout
  };

  let callBack = (response)=>{
    console.log(response);
    let code = response.data?response.data.code:undefined;
    if(code!=='00'){

    }
    else{
      successCallBack();
    }
  };

  callBack = callBack.bind(this);

  //get appraisal call back
  let errorCallBack = (error)=>{

  };

  errorCallBack = errorCallBack.bind(this);

  asyncDelete(url,control,callBack,errorCallBack,successMsg,errorMsg);
}

export function dataLength(field, value){
  let dataControls = [
    {
      field:'lpaKey',
      type:'string',
      max:8
    },
    {
      field:'fileNumber',
      type:'string',
      max:50
    },
    {
      field:'additionalFileNumber',
      type:'string',
      max:50
    },
    {
      field:'propertyStreet',
      type:'string',
      max:60
    },
    {
      field:'propertyCity',
      type:'string',
      max:40
    },
    {
      field:'propertyZip',
      type:'string',
      max:10
    },
    {
      field:'propertyCounty',
      type:'string',
      max:30
    },
    {
      field:'borrowerName',
      type:'string',
      max:50
    },
    {
      field:'viewDescription',
      type:'string',
      max:19
    },
    {
      field:'siteConditionDescription',
      type:'string',
      max:4000
    },
    {
      field:'foundationWallMaterialDescription',
      type:'string',
      max:4000
    },
    {
      field:'exteriorWallMaterialDescription',
      type:'string',
      max:4000
    },
    {
      field:'roofMaterialDescription',
      type:'string',
      max:4000
    },
    {
      field:'windowUpdateDescription',
      type:'string',
      max:4000
    },
    {
      field:'atticFeatureDescription',
      type:'string',
      max:4000
    },
    {
      field:'siteStructureAmenityOtherDescription',
      type:'string',
      max:12,
      min:6
    },
    {
      field:'siteStructureAmenityOtherDescription',
      type:'string',
      max:10
    },
    {
      field:'primaryDwellingAreaDesciption',
      type:'string',
      max:4000
    },
    {
      field:'aduAreaDesciption',
      type:'string',
      max:4000
    },
    {
      field:'structureDescription',
      type:'string',
      max:4000
    },
    {
      field:'notLivableComment',
      type:'string',
      max:4000
    },
    {
      field:'adjoinExternalFactorDescription',
      type:'string',
      max:4000
    },
    {
      field:'requiredRepairs',
      type:'string',
      max:20
    },
    {
      field:'energyRatingDescription',
      type:'string',
      max:4000
    },
    {
      field:'renewableEnergyComponentDescription',
      type:'string',
      max:4000
    },
    {
      field:'dataCollectorName',
      type:'string',
      max:55
    },
    {
      field:'dataCollectorCompany',
      type:'string',
      max:48
    },
    {
      field:'professionalLicenseNumber',
      type:'string',
      max:20
    },
    {
      field:'dataCollectorCompanyStreet',
      type:'string',
      max:50
    },
    {
      field:'dataCollectorCompanyCity',
      type:'string',
      max:70
    },
    {
      field:'dataCollectorCompanyZip',
      type:'string',
      max:10
    },
    {
      field:'dataCollectorPhone',
      type:'string',
      max:30
    },
    {
      field:'professionalLicenseNumber',
      type:'string',
      max:20
    },
    {
      field:'supervisorName',
      type:'string',
      max:25
    },
    {
      field:'supervisorPhone',
      type:'string',
      max:50
    },
    {
      field:'supervisorEmail',
      type:'string',
      max:50
    },
    {
      field:'dataCollectorEmail',
      type:'string',
      max:50
    }
  ];

  for(let i = 0;i<dataControls.length;i++){
    if(dataControls[i].field===field){
      //limit the max
      value = dataControls[i].max;
      return value;
    }
  }
  return -1;
}

export function objectDataLengthConfig(){
  let dataControls = {
    kitchens:[
      {
        field:'kitchenFloorUpdateDescription',
        type:'string',
        max:4000
      },
      {
        field:'kitchenWallAndCeilingUpdateDescription',
        type:'string',
        max:4000
      },
      {
        field:'kitchenApplianceOtherDescription',
        type:'string',
        max:4000
      }
    ],
    bathrooms:[
      {
        field:'bathroomWallAndCeilingUpdateDescription',
        type:'string',
        max:4000
      },
      {
        field:'bathroomFloorUpdateDescription',
        type:'string',
        max:4000
      }
    ],
    interiors:[
      {
        field:'floorUpdateDescription',
        type:'string',
        max:4000
      },
      {
        field:'wallAndCeilingUpdateDescription',
        type:'string',
        max:4000
      }
    ],
    garages:[
      {
        field:'garageFloorUpdateDescription',
        type:'string',
        max:4000
      },
      {
        field:'garageWallAndCeilingUpdateDescription',
        type:'string',
        max:4000
      }
    ],
    heatings:[
      {
        field:'heatingUpdateDescription',
        type:'string',
        max:4000
      },
      {
        field:'heatingOtherDescription',
        type:'string',
        max:10
      }
    ],
    coolings:[
      {
        field:'coolingUpdateDescription',
        type:'string',
        max:4000
      },
      {
        field:'coolingOtherDescription',
        type:'string',
        max:10
      }
    ],
  }

  return dataControls;
}

export function autoResetFields(fieldChanged, fieldValue, existingState){
  let dependencies = getAllDependencies();
  let stateToUpdate = {};

  for(let i=0;i<dependencies.length;i++){
    let dependency = dependencies[i];
    let needReset = false;

    //check if need reset
    if(dependency.field===fieldChanged){
      //if it's array we only update when none of the element in the dependency array is in target to update state array value
      if(dependency.value.type==='array'){
        let arrayExist = false;
        for(let j=0;j<dependency.value.value.length;j++){
          if(fieldValue.indexOf(dependency.value.value[j])!==-1)
            arrayExist = true;
        }

        if(!arrayExist)
          needReset = true;
      }
      else if(dependency.value.type==='number'){
        if(dependency.value.value==='>0'){
          if(fieldValue<=0)
            needReset = true;
        }
      }
      else if(dependency.value.type==='string'){
        if(Array.isArray(dependency.value.value)){
          let notFound = true;
          for(let j=0;j<dependency.value.value.length;j++){
            if(dependency.value.value[j]===fieldValue)
              notFound = false;
          }

          if(notFound)
            needReset = true;
        }
        else if(dependency.value.value!==fieldValue)
            needReset = true;
      }


    }
    else
      continue;

    //need reset, go through all dependencies
    if(needReset){
      for(let j=0;j<dependency.dependencies.length;j++){
        //check for additional condition depends on top of the field
        let proceed = true;

        if(dependency.dependencies[j].conditions){
          for(let k=0;k<dependency.dependencies[j].conditions.length;k++){
            let extraCond = dependency.dependencies[j].conditions[k];
            if(extraCond.value.type==='array'){
              let arrayExist = false;
              for(let j=0;j<extraCond.value.value.length;j++){
                if(existingState[extraCond.field].indexOf(extraCond.value.value[j])!==-1)
                  arrayExist = true;
              }

              if(arrayExist){
                proceed = false;
                break;
              }
            }
            else if(extraCond.value.type==='number'){
              if(extraCond.value.value==='>0'){
                if(existingState[extraCond.field]<=0){
                  proceed = false;
                  break;
                }
              }
            }
            else if(extraCond.value.type!=='string'){
              if(Array.isArray(existingState[extraCond.field])){
                let notFound = true;
                for(let l=0;l<existingState[extraCond.field].length;l++){
                  if(existingState[extraCond.field][l]===fieldValue)
                    notFound = false;
                }

                if(notFound)
                  needReset = true;
              }
              else if(existingState[extraCond.field]!==extraCond.value.value){
                proceed = false;
                break;
              }
            }
          }
        }

        //only proceed if extra condition is all full fill
        if(proceed){
          let resetValue = '';
          if(dependency.dependencies[j].type==='string'||dependency.dependencies[j].type==='number'){
            stateToUpdate[dependency.dependencies[j].name] = '';
            resetValue = '';
          }
          else if(dependency.dependencies[j].type==='array'){
            stateToUpdate[dependency.dependencies[j].name] = [];
            resetValue = [];
          }

          //recursion for all fields affected
          let stateNeedsReset = autoResetFields(dependency.dependencies[j].name, resetValue, existingState);
          stateToUpdate = Object.assign({}, stateToUpdate, {...stateNeedsReset});
        }
      }
    }
  }

  return stateToUpdate;
}

export function getAllDependencies(){
  let dependencies = [
    {
      field:'hoaFee',
      value:{
        type:'number',
        value:'>0'
      },
      dependencies:[
        {
          type:'string',
          name:'hoa'
        }
      ]
    },
    {
      field:'evidenceOfCondition',
      value:{
        type:'string',
        value:'True'
      },
      dependencies:[
        {
          type:'string',
          name:'evidenceOfConditionDescription'
        }
      ]
    },
    {
      field:'roofMaterials',
      value:{
        type:'array',
        value:['Other Shingle', 'Other Non Shingle']
      },
      dependencies:[
        {
          type:'string',
          name:'roofMaterialDescription'
        }
      ]
    },
    {
      field:'attic',
      value:{
        type:'string',
        value:'True'
      },
      dependencies:[
        {
          type:'array',
          name:'atticFeatures'
        }
      ]
    },
    {
      field:'coolingUpdates',
      value:{
        type:'string',
        value:['Fully','Partially']
      },
      dependencies:[
        {
          type:'string',
          name:'coolingUpdateDescription'
        }
      ]
    },
    {
      field:'coolingTypes',
      value:{
        type:'array',
        value:[]
      },
      dependencies:[
        {
          type:'string',
          name:'coolingOtherDescription',
          conditions:[
            {
              field:'coolingTypes',
              value:{
                type:'array',
                value:['Other']
              }
            }
          ]
        },
        {
          type:'string',
          name:'coolingDamagesDefect',
          conditions:[
            {
              field:'coolingTypes',
              value:{
                type:'array',
                value:['']
              }
            }
          ]
        }
      ]
    },
    {
      field:'heatingUpdates',
      value:{
        type:'string',
        value:['Fully','Partially']
      },
      dependencies:[
        {
          type:'string',
          name:'heatingUpdateDescription'
        }
      ]
    },
    {
      field:'heatingTypes',
      value:{
        type:'array',
        value:[]
      },
      dependencies:[
        {
          type:'string',
          name:'heatingOtherDescription',
          conditions:[
            {
              field:'heatingTypes',
              value:{
                type:'array',
                value:['Other']
              }
            }
          ]
        },
        {
          type:'string',
          name:'heatingDamagesDefect',
          conditions:[
            {
              field:'heatingTypes',
              value:{
                type:'array',
                value:['']
              }
            }
          ]
        }
      ]
    },
    {
      field:'atticFeatures',
      value:{
        type:'array',
        value:['Other']
      },
      dependencies:[
        {
          type:'string',
          name:'atticFeatureDescription'
        }
      ]
    },
    {
      field:'windowUpdates',
      value:{
        type:'string',
        value:['Fully','Partially']
      },
      dependencies:[
        {
          type:'string',
          name:'roofMaterialDescription'
        }
      ]
    },
    {
      field:'primaryDwellingAreaType',
      value:{
        type:'string',
        value:['']
      },
      dependencies:[
        {
          type:'string',
          name:'bathroomShowerTubMaterials',
          conditions:[
            {
              field:'primaryDwellingAreaType',
              value:{
                type:'string',
                value:'Bathroom'
              }
            }
          ]
        },
        {
          type:'string',
          name:'bathroomShowerTubConditions',
          conditions:[
            {
              field:'primaryDwellingAreaType',
              value:{
                type:'string',
                value:'Bathroom'
              }
            }
          ]
        },
        {
          type:'string',
          name:'primaryDwellingAreaDesciption'
        },
        {
          type:'string',
          name:'aduAreaDesciption'
        }
      ]
    },
    {
      field:'aduAreaType',
      value:{
        type:'string',
        value:'Bathroom'
      },
      dependencies:[
        {
          type:'string',
          name:'bathroomShowerTubMaterials'
        },
        {
          type:'string',
          name:'bathroomShowerTubConditions'
        }
      ]
    },
    {
      field:'siteStructureAmenities',
      value:{
        type:'array',
        value:[]
      },
      dependencies:[
        {
          type:'string',
          name:'siteStructureAmenityCount',
          condition:[
            {
              field:'siteStructureAmenities',
              value:{
                type:'array',
                value:['Wood Stove','Fireplace']
              }
            }
          ]
        },
        {
          type:'array',
          name:'patioSubTypes',
          condition:[
            {
              field:'siteStructureAmenities',
              value:{
                type:'array',
                value:['Patio']
              }
            }
          ]
        },
        {
          type:'array',
          name:'deckSubTypes',
          condition:[
            {
              field:'siteStructureAmenities',
              value:{
                type:'array',
                value:['Deck']
              }
            }
          ]
        },
        {
          type:'array',
          name:'gazeboSubTypes',
          condition:[
            {
              field:'siteStructureAmenities',
              value:{
                type:'array',
                value:['Gazebo']
              }
            }
          ]
        },
        {
          type:'array',
          name:'porchSubTypes',
          condition:[
            {
              field:'siteStructureAmenities',
              value:{
                type:'array',
                value:['Porch']
              }
            }
          ]
        },
        {
          type:'array',
          name:'interiorPoolSubTypes',
          condition:[
            {
              field:'siteStructureAmenities',
              value:{
                type:'array',
                value:['Interior Pool']
              }
            }
          ]
        },
        {
          type:'string',
          name:'siteStructureAmenityOtherDescription',
          condition:[
            {
              field:'siteStructureAmenities',
              value:{
                type:'array',
                value:['Other']
              }
            }
          ]
        }
      ]
    },
    {
      field:'notLivableIndicator',
      value:{
        type:'string',
        value:'True'
      },
      dependencies:[
        {
          type:'string',
          name:'notLivableComment'
        }
      ]
    },
    {
      field:'collectionType',
      value:{
        type:'string',
        value:'Virtual'
      },
      dependencies:[
        {
          type:'string',
          name:'personAtProperty'
        }
      ]
    },
    {
      field:'condoType',
      value:{
        type:'string',
        value:'Other'
      },
      dependencies:[
        {
          type:'string',
          name:'condoOtherDescription'
        }
      ]
    },
    {
      field:'dataCollectorType',
      value:{
        type:'string',
        value:['Real Estate Agent', 'Appraiser']
      },
      dependencies:[
        {
          type:'string',
          name:'professionalLicenseType'
        },
        {
          type:'string',
          name:'professionalLicenseNumber'
        },
        {
          type:'string',
          name:'professionalLicenseState'
        },
        {
          type:'string',
          name:'professionalLicenseExpDate'
        }
      ]
    },
    {
      field:'structureType',
      value:{
        type:'string',
        value:['PrimaryDwelling', 'ADU']
      },
      dependencies:[
        {
          type:'string',
          name:'requiredRepairs'
        },
        {
          type:'array',
          name:'requiredInspections'
        },
        {
          type:'string',
          name:'constructionStatus'
        },
        {
          type:'string',
          name:'defectIndicator'
        },
        {
          type:'string',
          name:'allSeasonAccessToSystem'
        },
        {
          type:'string',
          name:'structuralDamage'
        },
        {
          type:'string',
          name:'upToStandard'
        },
        {
          type:'string',
          name:'aboveGradeBathroomCount'
        },
        {
          type:'string',
          name:'notLivableIndicator'
        },
        {
          type:'string',
          name:'aboveGradeBedroomCount'
        },
        {
          type:'string',
          name:'aboveGradeRoomCount'
        },
        {
          type:'string',
          name:'aboveGradeGrossLivingArea'
        },
        {
          type:'string',
          name:'finished'
        },
        {
          type:'array',
          name:'kitchenCountertops'
        },
        {
          type:'string',
          name:'kitchenCabinet'
        },
        {
          type:'array',
          name:'viewTypes'
        },
        {
          type:'number',
          name:'storiesNumber',
          conditions:[
            {
              field:'propertyType',
              value:{
                type:'string',
                value:['SFD','Manufactured','2-4 Unit','Cooperative']
              }
            }
          ]
        },
        {
          type:'array',
          name:'exteriorWallConditions'
        },
        {
          type:'string',
          name:'stormSash'
        },
        {
          type:'string',
          name:'screens'
        },
        {
          type:'string',
          name:'attic'
        },
        {
          type:'array',
          name:'siteStructureAmenities'
        },
        {
          type:'array',
          name:'foundationTypes',
          conditions:[
            {
              field:'propertyType',
              value:{
                type:'string',
                value:'Condo'
              }
            }
          ]
        },
        {
          type:'array',
          name:'foundationWallMaterials',
          conditions:[
            {
              field:'foundationTypes',
              value:{
                type:'array',
                value:['Basement', 'Partial Basement', 'Crawlspace']
              }
            }
          ]
        },
        {
          type:'array',
          name:'foundationWallConditions',
          conditions:[
            {
              field:'foundationTypes',
              value:{
                type:'array',
                value:['Basement', 'Partial Basement', 'Crawlspace']
              }
            }
          ]
        },
        {
          type:'number',
          name:'sqftBelowGrade',
          conditions:[
            {
              field:'foundationTypes',
              value:{
                type:'array',
                value:['Basement', 'Partial Basement']
              }
            }
          ]
        },
        {
          type:'number',
          name:'percentBelowGrade',
          conditions:[
            {
              field:'foundationTypes',
              value:{
                type:'array',
                value:['Basement', 'Partial Basement']
              }
            }
          ]
        },
        {
          type:'string',
          name:'sumpPump',
          conditions:[
            {
              field:'foundationTypes',
              value:{
                type:'array',
                value:['Basement', 'Partial Basement']
              }
            }
          ]
        },
        {
          type:'string',
          name:'primaryDwellingAreaType'
        },
        {
          type:'string',
          name:'aduAreaType'
        },
        {
          type:'string',
          name:'structureDescription'
        }
      ]
    },
    {
      field:'adjoinExternalFactors',
      value:{
        type:'array',
        value:['Other']
      },
      dependencies:[
        {
          type:'string',
          name:'adjoinExternalFactorDescription'
        }
      ]
    },
    {
      field:'foundationTypes',
      value:{
        type:'array',
        value:['Basement', 'Partial Basement']
      },
      dependencies:[
        {
          type:'string',
          name:'recreationBelowGrade'
        },
        {
          type:'string',
          name:'bedroomBelowGrade'
        },
        {
          type:'string',
          name:'bathroomBelowGrade'
        },
        {
          type:'string',
          name:'otherRoomBelowGrade'
        },
        {
          type:'number',
          name:'sqftBelowGrade',
          conditions:[
            {
              field:'structureType',
              value:{
                type:'string',
                value:['PrimaryDwelling', 'ADU', 'Detached Garage','Workshop','Barn', 'Storage', 'Other']
              }
            }
          ]
        },
        {
          type:'array',
          name:'belowGradeAccess'
        },
        {
          type:'array',
          name:'foundationWallMaterials',
          conditions:[
            {
              field:'foundationTypes',
              value:{
                type:'array',
                value:['Crawlspace']
              }
            },
            {
              field:'structureType',
              value:{
                type:'string',
                value:['PrimaryDwelling', 'ADU', 'Detached Garage','Workshop','Barn', 'Storage', 'Other']
              }
            }
          ]
        },
        {
          type:'array',
          name:'foundationWallConditions',
          conditions:[
            {
              field:'structureType',
              value:{
                type:'string',
                value:['Basement', 'Partial Basement', 'Crawlspace']
              }
            }
          ]
        },
        {
          type:'number',
          name:'percentBelowGrade',
          conditions:[
            {
              field:'structureType',
              value:{
                type:'string',
                value:['PrimaryDwelling', 'ADU']
              }
            }
          ]
        },
        {
          type:'string',
          name:'sumpPump',
          conditions:[
            {
              field:'structureType',
              value:{
                type:'string',
                value:['PrimaryDwelling', 'ADU']
              }
            }
          ]
        }
      ]
    },
    {
      field:'propertyType',
      value:{
        type:'string',
        value:'Condo'
      },
      dependencies:[
        {
          type:'array',
          name:'foundationTypes',
          conditions:[
            {
              field:'structureType',
              value:{
                type:'string',
                value:['PrimaryDwelling', 'ADU']
              }
            }
          ]
        },
        {
          type:'string',
          name:'guestParking'
        },
        {
          type:'string',
          name:'condoType'
        },
        {
          type:'number',
          name:'projectStoriesNumber'
        },
        {
          type:'number',
          name:'unitStoriesNumber'
        },
        {
          type:'number',
          name:'elevatorNumber'
        },
        {
          type:'number',
          name:'floorNumber'
        }
      ]
    },
    {
      field:'viewTypes',
      value:{
        type:'array',
        value:['Other']
      },
      dependencies:[
        {
          type:'string',
          name:'viewDescription'
        }
      ]
    },
    {
      field:'structureType',
      value:{
        type:'string',
        value:['Other']
      },
      dependencies:[
        {
          type:'string',
          name:'structureDescription'
        }
      ]
    },
    {
      field:'carStorageIndicator',
      value:{
        type:'string',
        value:'True'
      },
      dependencies:[
        {
          type:'array',
          name:'onSiteParkingTypes'
        },
        {
          type:'string',
          name:'numberOfParking'
        }
      ]
    },
    {
      field:'onSiteParkingTypes',
      value:{
        type:'array',
        value:['Pad']
      },
      dependencies:[
        {
          type:'string',
          name:'padCovered'
        },
        {
          type:'string',
          name:'padSurface'
        }
      ]
    },
    {
      field:'onSiteParkingTypes',
      value:{
        type:'array',
        value:['Carport']
      },
      dependencies:[
        {
          type:'string',
          name:'carPortCovered'
        },
        {
          type:'string',
          name:'carPortSurface'
        }
      ]
    },
    {
      field:'onSiteParkingTypes',
      value:{
        type:'array',
        value:['Garage']
      },
      dependencies:[
        {
          type:'string',
          name:'garageCovered'
        },
        {
          type:'string',
          name:'garageSurface'
        },
        {
          type:'string',
          name:'garageNumberOfCar'
        },
        {
          type:'string',
          name:'garageSqft'
        },
        {
          type:'string',
          name:'garageBuiltIn'
        }
      ]
    },
    {
      field:'drivewayCovered',
      value:{
        type:'string',
        value:'True'
      },
      dependencies:[
        {
          type:'string',
          name:'drivewayAttached'
        }
      ]
    },
    {
      field:'padCovered',
      value:{
        type:'string',
        value:'True'
      },
      dependencies:[
        {
          type:'string',
          name:'padAttached'
        }
      ]
    },
    {
      field:'carPortCovered',
      value:{
        type:'string',
        value:'True'
      },
      dependencies:[
        {
          type:'string',
          name:'carPortAttached'
        }
      ]
    },
    {
      field:'guestParking',
      value:{
        type:'string',
        value:'True'
      },
      dependencies:[
        {
          type:'string',
          name:'guestParkingType'
        }
      ]
    },
    {
      field:'kitchenAppliances',
      value:{
        type:'array',
        value:['Other']
      },
      dependencies:[
        {
          type:'string',
          name:'kitchenApplianceOtherDescription'
        }
      ]
    },
    {
      field:'garageCovered',
      value:{
        type:'string',
        value:'True'
      },
      dependencies:[
        {
          type:'string',
          name:'garageAttached'
        }
      ]
    },
    {
      field:'onSiteParkingTypes',
      value:{
        type:'array',
        value:['Pad']
      },
      dependencies:[
        {
          type:'string',
          name:'padCovered'
        },
        {
          type:'string',
          name:'padSurface'
        }
      ]
    },
    {
      field:'utilityType',
      value:{
        type:'string',
        value:'Private'
      },
      dependencies:[
        {
          type:'string',
          name:'utilitySubType'
        }
      ]
    },
    {
      field:'siteCommonAmenities',
      value:{
        type:'array',
        value:[]
      },
      dependencies:[
        {
          type:'array',
          name:'fenceSubTypes',
          conditions:[
            {
              field:'siteCommonAmenities',
              value:{
                type:'array',
                value:['Fence']
              }
            }
          ]
        },
        {
          type:'array',
          name:'ingroundPoolSubTypes',
          conditions:[
            {
              field:'siteCommonAmenities',
              value:{
                type:'array',
                value:['In Ground Pool']
              }
            }
          ]
        },
        {
          type:'array',
          name:'aboveGroundPoolSubTypes',
          conditions:[
            {
              field:'siteCommonAmenities',
              value:{
                type:'array',
                value:['Above Ground Pool']
              }
            }
          ]
        },
        {
          type:'string',
          name:'siteCommonAmenityDescription',
          conditions:[
            {
              field:'siteCommonAmenities',
              value:{
                type:'array',
                value:['Other']
              }
            }
          ]
        }
      ]
    },
    {
      field:'foundationWallMaterials',
      value:{
        type:'array',
        value:['Other']
      },
      dependencies:[
        {
          type:'string',
          name:'foundationWallMaterialDescription'
        },
      ]
    },
    {
      field:'exteriorWallMaterials',
      value:{
        type:'array',
        value:['Other Siding', 'Other Masonary']
      },
      dependencies:[
        {
          type:'string',
          name:'exteriorWallMaterialDescription'
        },
      ]
    },
    {
      field:'streetAlleyTypes',
      value:{
        type:'array',
        value:['Street', 'Alley']
      },
      dependencies:[
        {
          type:'array',
          name:'streetAlleyMaterials'
        },
        {
          type:'string',
          name:'streetAlleyOwnership'
        }
      ]
    },
    {
      field:'siteConditions',
      value:{
        type:'array',
        value:['Other']
      },
      dependencies:[
        {
          type:'string',
          name:'siteConditionDescription'
        }
      ]
    },
    {
      field:'heatingTypes',
      value:{
        type:'array',
        value:['Forced Warm Air']
      },
      dependencies:[
        {
          type:'string',
          name:'heatingDamagesDefectForcedWarmAir'
        }
      ]
    },
    {
      field:'heatingTypes',
      value:{
        type:'array',
        value:['Radiant']
      },
      dependencies:[
        {
          type:'string',
          name:'heatingDamagesDefectRadiant'
        }
      ]
    },
    {
      field:'heatingTypes',
      value:{
        type:'array',
        value:['Hot Water Baseboard']
      },
      dependencies:[
        {
          type:'string',
          name:'heatingDamagesDefectHotWaterBaseboard'
        }
      ]
    },
    {
      field:'heatingTypes',
      value:{
        type:'array',
        value:['Other']
      },
      dependencies:[
        {
          type:'string',
          name:'heatingDamagesDefectOther'
        }
      ]
    },
    {
      field:'coolingTypes',
      value:{
        type:'array',
        value:['Central Air']
      },
      dependencies:[
        {
          type:'string',
          name:'coolingDamagesDefectCentralAir'
        }
      ]
    },
    {
      field:'coolingTypes',
      value:{
        type:'array',
        value:['Individual']
      },
      dependencies:[
        {
          type:'string',
          name:'coolingDamagesDefectIndividual'
        }
      ]
    },
    {
      field:'coolingTypes',
      value:{
        type:'array',
        value:['Evaporative']
      },
      dependencies:[
        {
          type:'string',
          name:'coolingDamagesDefectEvaporative'
        }
      ]
    },
    {
      field:'coolingTypes',
      value:{
        type:'array',
        value:['Other']
      },
      dependencies:[
        {
          type:'string',
          name:'coolingDamagesDefectOther'
        }
      ]
    },
    {
      field:'renewableEnergyComponent',
      value:{
        type:'string',
        value:'Other'
      },
      dependencies:[
        {
          type:'string',
          name:'renewableEnergyComponentDescription'
        }
      ]
    },
    {
      field:'estimatedYearImprovement',
      value:{
        type:'string',
        name:['Less than 1 year ago','1-5 years ago','6-10 years ago','11-15 years ago']
      },
      dependencies:[
        {
          type:'string',
          name:'improvementDescription'
        }
      ]
    },
    {
      field:'propertyType',
      value:{
        type:'string',
        value:{
          type:'string',
          value:['SFD','Manufactured','2-4 Unit','Cooperative']
        }
      },
      dependencies:[
        {
          type:'string',
          name:'storiesNumber',
          conditions:[
            {
              field:'structureType',
              value:{
                type:'string',
                value:['PrimaryDwelling','ADU']
              }
            }
          ]
        }
      ]
    }
  ];

  return dependencies;
}
