//Author June Leow
//Date 04/19/2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, formatDate, formatNumber} from '../../util/util';
import React, {useReducer, useEffect} from 'react';
import moment from 'moment';
import MyReactTable from '../util/my-react-table';
import {NavLink} from 'react-router-dom';

import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

//initialize the state
const initialState = {
  registrations:[],
};

//reducer function that perform state update
const reducer = getReducer();


const StateRegistrations  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  //run only once when component is loaded
  useEffect(()=>{
    getAllStateRegistration();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  //API call
  const getAllStateRegistration = ()=>{
    let url = 'stateRegistration/get';

    //generate a generic call back that will update state
    let callBack = apiCallBack([{state:'registrations',key:'data'}]);

    httpGet(url, '','Oops, something went wrong and could not load list of state registration. Please try again later.', callBack);
  }

  const columns = [
    {
      id: 'state',
      Header: 'State',
      accessor: d => d.state,
      Cell: props => {
        return <NavLink to={"/state-registration/"+props.row.original.ID}>{props.row.original.state}</NavLink>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'active',
      Header: 'Active',
      maxWidth:100,
      width:100,
      accessor: (d)=> {
        if(d.expiration_date==='-'||d.expiration_date==='0000-00-00')
          return 'No';
        else if(moment(d.expiration_date).unix()<moment().unix())
          return 'No';
        else
          return 'Yes';
      },
      Cell: props => {
        if(props.row.original.expiration_date==='-'||props.row.original.expiration_date==='0000-00-00')
          return 'No';
        else if(moment(props.row.original.expiration_date).unix()<moment().unix())
          return 'No';
        else
          return 'Yes';
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'registrationNumber',
      Header: 'AMC Registration #',
      accessor: d => d.registration_number,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'expirationDate',
      Header: 'AMC Exp Date',
      maxWidth:150,
      width:150,
      accessor: d => d.expiration_date,
      Cell: props => {
        return <div>{formatDate(props.row.original.expiration_date)}</div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'bondRequired',
      Header: 'Bond Required',
      maxWidth:100,
      width:100,
      accessor: d => d.bond_required,
      Cell: props => {
        return <div>{props.row.original.bond_required}</div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'bondAmount',
      Header: 'Bond Amount',
      maxWidth:120,
      width:120,
      accessor: d => d.bond_amount,
      Cell: props => {
        return <div>${formatNumber(props.row.original.bond_amount)}</div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'bondExpirationDate',
      Header: 'Bond Expiration Date',
      maxWidth:150,
      width:150,
      accessor: d => d.bond_expiration_date,
      Cell: props => {
        return <div>{formatDate(props.row.original.bond_expiration_date)}</div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'sosRegistrationNumber',
      Header: 'SOS Registration #',
      accessor: d => d.sos_registration_number,
      Cell: props => {
        return <div>{props.row.original.sos_registration_number}</div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'tradeNameExpirationDate',
      Header: 'Trade Name Exp Date',
      maxWidth:150,
      width:150,
      accessor: d => d.trade_name_expiration_date,
      Cell: props => {
        return <div>{formatDate(props.row.original.trade_name_expiration_date)}</div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'registrationFee',
      Header: 'Reg Fee',
      maxWidth:100,
      width:100,
      accessor: d => d.registration_fee,
      Cell: props => {
        return <div>{props.row.original.registration_fee}</div>
      },
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  console.log(state.registrations);
  //render
  return <div>
    <div className="align-right">
      <NavLink to="/state-registration/new"><i className="fa fa-plus green-color"></i> Create new state registration</NavLink>&nbsp;&nbsp;
      <ExcelFile element={<div className="display-inline"><i className="fa fa-file-excel-o"/>&nbsp;<NavLink to="#">Download Excel</NavLink></div>}>
        <ExcelSheet data={state.registrations} name="XLSX">
            <ExcelColumn label="State" value="state"/>
            <ExcelColumn label="Active" value={(col)=>{
                if(col.expiration_date==='-'||col.expiration_date==='0000-00-00')
                  return 'No';
                else if(moment(col.expiration_date).unix()<moment().unix())
                  return 'No';
                else
                  return 'Yes';
              }
            }/>
            <ExcelColumn label="AMC Registration #" value="registration_number"/>
            <ExcelColumn label="AMC Exp Date" value="expiration_date"/>
            <ExcelColumn label="Bond Required" value="bond_required"/>
            <ExcelColumn label="Bond Amount" value="bond_amount"/>
            <ExcelColumn label="Bond Expiration Date" value="bond_expiration_date"/>
            <ExcelColumn label="SOS Registration #" value="sos_registration_number"/>
            <ExcelColumn label="Trade Number Exp Date" value="trade_name_expiration_date"/>
            <ExcelColumn label="Reg Fee" value="registration_fee"/>
        </ExcelSheet>
      </ExcelFile>
    </div>
    <MyReactTable columns={columns} data={state.registrations} className="table-striped"/>
  </div>;
}

export default StateRegistrations;
