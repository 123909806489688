//Author Sooyoung 
//Date June 27, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, generateSID, formatDate, formatNumberNoDecimal} from '../../../util/util';
import {Button, Card, CardHeader, CardBody, Modal, ModalHeader, ModalBody, Nav, NavItem, TabPane, TabContent, NavLink as NavLinkL} from 'reactstrap';
import moment from 'moment';
import React, {useReducer, useEffect, useCallback, useRef } from 'react';
import ReportFilter from '../report-filter';
import {NavLink} from 'react-router-dom';
import ReactExport from "react-export-excel";
import {usePusher} from '../../pusher/pusher-context';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

let today = new Date();

let from = today.getFullYear()+'-01-01';
let to = today.getFullYear()+'-12-31';

//initialize the state
const initialState = {
  queryID:-1,
  keyword:'',
  showReportLoading:false,
  report:{
    registered:[],
    labels:[],
    loan_officers:[],
    loan_processors:[],
    aes:[],
    brokers:[],
    clients:[],
  },
  activeTab:'1',
  noTimeLimit:'yes',
  from:from,
  to:to,
  reportDetailsToggle:false,
  reportDetailsOrders:[],
  clients:[],
  client:'',
  openByClient:''
};

//reducer function that perform state update
const reducer = getReducer();


const Lookup  = (props)=>{
  const controller = new AbortController();
  const newsid = useRef(generateSID());
  const pusher = usePusher();

  console.log(pusher);

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  // PUSHER
  const loadReportResponse = useCallback((ID) => {
    let callBack = apiCallBack([{state:'report', key:'data'},{state:'showReportLoading', value:false},{state:'queryID', value:-1}]);
    httpGet('report/query/retrieve/'+ID, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }, [apiCallBack, httpGet]);

  const reportReady = useCallback((response) => {
    console.log(response);
    response = JSON.parse(response)
    let code = response.code;
    let queryID = response.data;
    let SID = response.SID;

    if(SID===newsid.current){
      if(code==='00'){
        loadReportResponse(queryID)
      }
    }
  }, [loadReportResponse]);


  useEffect(() => {
    // Subscribe to a channel and bind to events using the pusher instance
    const channel = pusher.subscribe('private-report');
    channel.bind('report-ready', (data) => {
      reportReady(data);
    });

    // Clean up the subscription when the component is unmounted
    return () => {
      channel.unbind('report-ready');
      pusher.unsubscribe('private-report');
    };
  }, [pusher, reportReady]);

  // const failSafeLoadReport = () => {
  //   let timeout = 5;//mins
  //   setTimeout(()=>{
  //     //disable loop once timeout limit hit
  //     setState({queryID:-1});
  //   }, timeout * 1000 * 60);

  //   failSafeRetry();
  // }

  // const failSafeRetry = () => {
  //   let retryPeriod = 5;//seconds

  //   if(state.queryID!==-1){
  //     loadReportResponse(state.queryID);
  //   }
    
  //   setTimeout(()=>{
  //     if(state.queryID!==-1)
  //       failSafeRetry();
  //   }, retryPeriod * 1000);
  // }

  const activeTabToggle = (tab) => {
    setState({activeTab:tab});
  }

  const toggleReportLoading = () => {
    setState({showReportLoading:!state.showReportLoading});
  }

  const reportDetailsToggle = () => {
    if(state.reportDetailsDropDown)
      setState({reportDetailsOrders:[]});
    setState({reportDetailsDropDown:!state.reportDetailsDropDown});
  }

  //API call
  const getReportDetails = (reportType, target, email='') => {
    reportDetailsToggle();
    email = email.replace(new RegExp('/','g'),'fslash');
    email = email.replace(/\\/, "bslash");
    email = email.replace(new RegExp('[+]','g'),'plus');

    target = target.replace(new RegExp('/','g'),'fslash');
    target = target.replace(/\\/, "bslash");
    target = target.replace(new RegExp('[+]','g'),'plus');

    let url;
    if(state.noTimeLimit==='yes')
      url = 'report/lookup/details/keyword='+state.keyword+'&report='+reportType+'&target='+target+'&targetEmail='+email+'&client='+state.client+'&open_by_client='+state.openByClient;
    else
      url = 'report/lookup/details/keyword='+state.keyword+'&from='+state.from+'&to='+state.to+'&report='+reportType+'&target='+target+'&targetEmail='+email+'&client='+state.client+'&open_by_client='+state.openByClient;

    let callBack = apiCallBack([{state:'reportDetailsOrders', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load report. Please try again later.', callBack);
  }

  const getReport = () => {
    let SID = generateSID();
    newsid.current = SID;

    let url;
    if(state.noTimeLimit==='yes')
      url = 'report/lookup/keyword='+state.keyword+'&SID='+SID+'&client='+state.client+'&open_by_client='+state.openByClient;
    else
      url = 'report/lookup/keyword='+state.keyword+'&SID='+SID+'&from='+state.from+'&to='+state.to+'&client='+state.client+'&open_by_client='+state.openByClient;

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        console.log(response.data.data);
        toggleReportLoading();
        let queryID = response.data.data;

        setState({queryID:queryID});
      }
    };
    callBack = callBack.bind(this);

    httpGet(url, '', 'Oops, something went wrong and could not load report. Please try again later.', callBack);
  }


  //render
  let content;

  if(state.reportDetailsOrders.length>0){
    let rows = state.reportDetailsOrders.map(
      (order, index)=>{
        return(
          <tr key={index}>
            <td><NavLink target="_blank" to={"/appraisal/"+order.ID}>{order.reference_num}</NavLink></td>
            <td>{order.property_street+' '+order.property_city+', '+order.property_state+' '+order.property_zip}</td>
            <td>{order.status}</td>
            <td>{order.appraisal_type}</td>
            <td>{order.loan_num}</td>
            <td>{order.loan_type}</td>
          </tr>
        )
      }
    );

    content =
    <table className="table table-striped">
      <thead>
        <tr>
          <th>Reference #</th>
          <th>Address</th>
          <th>Status</th>
          <th>Report Type</th>
          <th>Loan #</th>
          <th>Loan type</th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </table>
  }

  let registeredAccount;
  let labels;
  let loanOfficers;
  let loanProcessors;
  let aes;
  let brokers;
  let clients;

  if(state.report.loan_officers.length>0){
    loanOfficers = <div>
      <div className="align-right">
        <br/>
        <ExcelFile element={<Button to="#" disabled={state.report.loan_officers.length<=0} color="warning">Download Excel</Button>}>
          <ExcelSheet data={state.report.loan_officers} name="XLSX">
              <ExcelColumn label="Name" value="name"/>
              <ExcelColumn label="Email" value="email"/>
              <ExcelColumn label="Order Count" value="count"/>
          </ExcelSheet>
        </ExcelFile>
        <br/>
        <br/>
      </div>
      <table className="table table-striped">
        <thead>
          <tr>
            <th width="50%">Name</th>
            <th width="30%">Email</th>
            <th width="20%">Order Count</th>
          </tr>
        </thead>
        <tbody>
          {
            state.report.loan_officers.map(
              (lo, index)=>{
                return <tr key={index}>
                  <td>{lo.name}</td>
                  <td>{lo.email}</td>
                  <td><NavLink to="#" onClick={(e)=>getReportDetails('associatedLoanOfficer', lo.name, lo.email)}>{formatNumberNoDecimal(lo.count)}</NavLink></td>
                </tr>
              }
            )
          }
        </tbody>
      </table>
    </div>
  }

  if(state.report.loan_processors.length>0){
    loanProcessors = <div>
      <div className="align-right">
        <br/>
        <ExcelFile element={<Button to="#" disabled={state.report.loan_processors.length<=0} color="warning">Download Excel</Button>}>
          <ExcelSheet data={state.report.loan_processors} name="XLSX">
              <ExcelColumn label="Name" value="name"/>
              <ExcelColumn label="Email" value="email"/>
              <ExcelColumn label="Order Count" value="count"/>
          </ExcelSheet>
        </ExcelFile>
        <br/>
        <br/>
      </div>
      <table className="table table-striped">
        <thead>
          <tr>
            <th width="50%">Name</th>
            <th width="30%">Email</th>
            <th width="20%">Order Count</th>
          </tr>
        </thead>
        <tbody>
          {
            state.report.loan_processors.map(
              (lp, index)=>{
                return <tr key={index}>
                  <td>{lp.name}</td>
                  <td>{lp.email}</td>
                  <td><NavLink to="#" onClick={(e)=>getReportDetails('associatedLoanProcessor', lp.name, lp.email)}>{formatNumberNoDecimal(lp.count)}</NavLink></td>
                </tr>
              }
            )
          }
        </tbody>
      </table>
    </div>
  }

  if(state.report.aes.length>0){
    aes = <div>
      <div className="align-right">
        <br/>
        <ExcelFile element={<Button to="#" disabled={state.report.aes.length<=0} color="warning">Download Excel</Button>}>
          <ExcelSheet data={state.report.aes} name="XLSX">
              <ExcelColumn label="Name" value="name"/>
              <ExcelColumn label="Email" value="email"/>
              <ExcelColumn label="Order Count" value="count"/>
          </ExcelSheet>
        </ExcelFile>
        <br/>
        <br/>
      </div>
      <table className="table table-striped">
        <thead>
          <tr>
            <th width="50%">Name</th>
            <th width="30%">Email</th>
            <th width="20%">Order Count</th>
          </tr>
        </thead>
        <tbody>
          {
            state.report.aes.map(
              (ae, index)=>{
                return <tr key={index}>
                  <td>{ae.name}</td>
                  <td>{ae.email}</td>
                  <td><NavLink to="#" onClick={(e)=>getReportDetails('associatedAE', ae.name, ae.email)}>{formatNumberNoDecimal(ae.count)}</NavLink></td>
                </tr>
              }
            )
          }
        </tbody>
      </table>
    </div>
  }

  if(state.report.brokers.length>0){
    brokers = <div>
      <div className="align-right">
        <br/>
        <ExcelFile element={<Button to="#" disabled={state.report.brokers.length<=0} color="warning">Download Excel</Button>}>
          <ExcelSheet data={state.report.brokers} name="XLSX">
              <ExcelColumn label="Name" value="name"/>
              <ExcelColumn label="Email" value="email"/>
              <ExcelColumn label="Order Count" value="count"/>
          </ExcelSheet>
        </ExcelFile>
        <br/>
        <br/>
      </div>
      <table className="table table-striped">
        <thead>
          <tr>
            <th width="50%">Name</th>
            <th width="30%">Email</th>
            <th width="20%">Order Count</th>
          </tr>
        </thead>
        <tbody>
          {
            state.report.brokers.map(
              (br, index)=>{
                return <tr key={index}>
                  <td>{br.name}</td>
                  <td>{br.email}</td>
                  <td><NavLink to="#" onClick={(e)=>getReportDetails('associatedBroker', br.name, br.email)}>{formatNumberNoDecimal(br.count)}</NavLink></td>
                </tr>
              }
            )
          }
        </tbody>
      </table>
    </div>
  }

  if(state.report.clients.length>0){
    clients = <div>
      <div className="align-right">
        <br/>
        <ExcelFile element={<Button to="#" disabled={state.report.clients.length<=0} color="warning">Download Excel</Button>}>
          <ExcelSheet data={state.report.clients} name="XLSX">
              <ExcelColumn label="Name" value="company"/>
              <ExcelColumn label="Order Count" value="count"/>
          </ExcelSheet>
        </ExcelFile>
        <br/>
        <br/>
      </div>
      <table className="table table-striped">
        <thead>
          <tr>
            <th width="80%">Name</th>
            <th width="20%">Order Count</th>
          </tr>
        </thead>
        <tbody>
          {
            state.report.clients.map(
              (cl, index)=>{
                return <tr key={index}>
                  <td><NavLink target="_blank" to={"client/"+cl.ID+"/status="}>{cl.company}</NavLink></td>
                  <td><NavLink to="#" onClick={(e)=>getReportDetails('associatedClient', cl.ID)}>{formatNumberNoDecimal(cl.count)}</NavLink></td>
                </tr>
              }
            )
          }
        </tbody>
      </table>
    </div>
  }

  if(state.report.labels.length>0){
    labels = <div>
      <label>Labels (From Order Details)</label>
      <div className="horizontal-scroll-container" style={{height:'340px'}}>
      {
        state.report.labels.map(
          (label, index)=>{
            return <div className="display-inline" style={{marginLeft:'10px'}}>
              <Card style={{width:'300px', height:'280px'}}>
                <CardHeader>
                  <i className="fa fa-id-card-o"/> {label.name}
                </CardHeader>
                <CardBody>
                  <div>
                    Label<br/>
                    <b>{label.label}</b>
                  </div>
                  <br/>
                  <div>
                    Email<br/>
                    <b>{label.email}</b>
                  </div>
                  <br/>
                  <div>
                    Order Count<br/>
                    <b><NavLink to="#" onClick={(e)=>getReportDetails('associatedLabel', label.label, label.email)}>{formatNumberNoDecimal(label.order_count)}</NavLink></b>
                  </div>
                </CardBody>
              </Card>
            </div>
          }
        )
      }
      </div>
    </div>
  }

  if(state.report.registered.length>0){
    registeredAccount = <div>
      <label>Registered User Account</label>
      <div className="horizontal-scroll-container" style={{height:'320px'}}>
      {
        state.report.registered.map(
          (account, index)=>{
            let company;

            if(account.registered_under_company){
              company = <div className="display-inline">
                &nbsp;at <NavLink style={{fontSize:'12px'}} target="_blank" to={"client/"+account.registered_under_company_fk+"/status="}>{account.registered_under_company}</NavLink>
              </div>
            }
            return <div className="display-inline" style={{marginLeft:'10px'}}>
              <Card style={{width:'300px'}}>
                <CardHeader>
                  <i className="fa fa-user"/> {account.registered_name}
                </CardHeader>
                <CardBody>
                  <div>
                    Email<br/>
                    <b>{account.registered_email}</b>
                  </div>
                  <br/>
                  <div>
                    Role<br/>
                    <b>{account.registered_role}</b>{company}
                  </div>
                  <br/>
                  <div>
                    User Since<br/>
                    <b>{formatDate(account.registered_date)}</b>
                  </div>
                </CardBody>
              </Card>
            </div>
          }
        )
      }
      </div>
    </div>
  }

  let associates;
  if(state.report.loan_officers.length>0||state.report.loan_processors.length>0||state.report.aes.length>0||state.report.brokers.length>0||state.report.clients.length>0){
    associates = <div>
      <Card>
        <CardHeader className="header-color">
          Associates
        </CardHeader>
        <CardBody>
          <Nav tabs>
            <NavItem>
              <NavLinkL
                className={"nav-link "+(state.activeTab === '1'?"active":"inactive" )}
                onClick={() => { activeTabToggle('1'); }}
                to="#"
              >
                <span className="my-badge3">{state.report.clients.length}</span> Associated Clients
              </NavLinkL>
            </NavItem>
            <NavItem>
              <NavLinkL
                className={"nav-link "+(state.activeTab === '2'?"active":"inactive" )}
                onClick={() => { activeTabToggle('2'); }}
                to="#"
              >
                <span className="my-badge3">{state.report.loan_officers.length}</span> Associated Loan Officers
              </NavLinkL>
            </NavItem>
            <NavItem>
              <NavLinkL
                className={"nav-link "+(state.activeTab === '3'?"active":"inactive" )}
                onClick={() => { activeTabToggle('3'); }}
                to="#"
              >
                <span className="my-badge3">{state.report.loan_processors.length}</span> Associated Loan Processors
              </NavLinkL>
            </NavItem>
            <NavItem>
              <NavLinkL
                className={"nav-link "+(state.activeTab === '4'?"active":"inactive" )}
                onClick={() => { activeTabToggle('4'); }}
                to="#"
              >
                <span className="my-badge3">{state.report.aes.length}</span> Associated AEs
              </NavLinkL>
            </NavItem>
            <NavItem>
              <NavLinkL
                className={"nav-link "+(state.activeTab === '5'?"active":"inactive" )}
                onClick={() => { activeTabToggle('5'); }}
                to="#"
              >
                <span className="my-badge3">{state.report.brokers.length}</span> Associated Brokers
              </NavLinkL>
            </NavItem>
          </Nav>
          <TabContent activeTab={state.activeTab}>
            <TabPane tabId="1">
              {clients}
            </TabPane>
            <TabPane tabId="2">
              {loanOfficers}
            </TabPane>
            <TabPane tabId="3">
              {loanProcessors}
            </TabPane>
            <TabPane tabId="4">
              {aes}
            </TabPane>
            <TabPane tabId="5">
              {brokers}
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </div>
  }

  let userInfo;

  if(state.report.registered.length>0 || state.report.labels.length>0){
    userInfo = <div>
      <Card>
        <CardHeader className="header-color">
          User Info
        </CardHeader>
        <CardBody>
          {registeredAccount}
          <br/>
          {labels}
        </CardBody>
      </Card>
    </div>
  }

  let reportFiltersConfig = [{id:'customSelect',value:state.noTimeLimit, updateFunc:(v)=>setState({noTimeLimit:v}), width:'4', label:'No Time Limit', clearable:false, options:[{label:"Yes",value:"yes"}, {label:"No", value:"no"}]}];
  let buttonWidth = '12';

  if(state.noTimeLimit==='no'){let from, to;
    if(state.from!=='')
      from = moment(state.from);
    if(state.to!=='')
      to = moment(state.to);

    buttonWidth = '4';
    
    reportFiltersConfig.push({id:'from',value:from, updateFunc:(v)=>setState({from:v}), width:'4'}, {id:'to',value:to, updateFunc:(v)=>setState({to:v}), width:'4'});
  }

  reportFiltersConfig.push(
    {id:'custom',value:state.keyword, updateFunc:(v)=>setState({keyword:v}), width:'2', label:'Name', placeHolder:"Lookup a person with name"},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'3'},
    {id:'openBy',value:state.openByClient, updateFunc:(v)=>setState({openByClient:v}), width:'3'},
    {id:'button',value:'Search', updateFunc:getReport, width:buttonWidth, className:"align-right", color:"warning"},
  );


  return <div>
    <Modal className="my-modal-wide" isOpen={state.reportDetailsDropDown} toggle={reportDetailsToggle} >
      <ModalHeader hidden={true} toggle={reportDetailsToggle}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-user"></i> Report Details</h5>
        </center>
        <br/>
        <div className="align-right">
          <br/>
          <ExcelFile element={<Button to="#" disabled={state.reportDetailsOrders.length<=0} color="warning">Download Excel</Button>}>
            <ExcelSheet data={state.reportDetailsOrders} name="XLSX">
                <ExcelColumn label="Reference #" value="reference_num"/>
                <ExcelColumn label="Address" value={(col)=>{return col.property_street+' '+col.property_city+', '+col.property_state+' '+col.property_zip}}/>
                <ExcelColumn label="Status" value="status"/>
                <ExcelColumn label="Report Type" value="appraisal_type"/>
                <ExcelColumn label="Loan #" value="loan_num"/>
                <ExcelColumn label="Loan Type" value="loan_type"/>
            </ExcelSheet>
          </ExcelFile>
          <br/>
          <br/>
        </div>
        <div className="large-scroll-container">
          {content}
        </div>
        <br/>
        <center>
          <Button color="info" onClick={reportDetailsToggle}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
    <Modal className="my-modal" isOpen={state.showReportLoading} toggle={toggleReportLoading} >
      <ModalHeader hidden={true} toggle={toggleReportLoading}></ModalHeader>
      <ModalBody>
        <center>
          <i style={{fontSize:'55px'}} className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
          <br/><br/>
          Report Loading...<br/>
          Please wait, your report will be loaded once it is ready.
        </center>
        <br/><br/>
        <center>
          <Button color="info" onClick={toggleReportLoading}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
    <ReportFilter {...props} configs={reportFiltersConfig}/>
    <div className="my-divider"/>

    {userInfo}

    <br/><br/>
    {associates}
  </div>;
}


export default Lookup;
