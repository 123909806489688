//Author Sooyoung Kim
//Date Aug 10, 2023
import {getReducer, getSetStateFunction, postAPICallGenerator, callBackGenerator} from '../../util/util';
import React, {useReducer, useEffect} from 'react';
import { Document, Page } from 'react-pdf';

//initialize the state
const initialState = {
  invoice:'',
  numPages: null,
  pageNumber: 1,
};

//reducer function that perform state update
const reducer = getReducer();

const Invoice  = (props)=>{
  const controller = new AbortController();

  let newInitialState = Object.assign({}, initialState, {
    id: props.id,
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getInvoice();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const onDocumentLoad = (pdf) => {
    setState({ numPages :pdf.numPages});
  }

  //API call
  const getInvoice = () => {
    let parameters = [
      {
        field:'transactionID',
        value:state.id
      },
    ];

    let callBack = apiCallBack([{state:'invoice', key:'data'}]);
    httpPost('file/invoice/download', parameters, '', 'Oops, something went wrong and could not load payment methods. Please try again later.', callBack);
  }

  //render
  let pdf;

  if(state.invoice!=='')
    pdf =
    <Document
      file={`data:application/pdf;base64,${state.invoice}`}
      onLoadSuccess={onDocumentLoad}
    >
      <Page pageNumber={state.numPages} />
    </Document>
  else
    pdf = <div>Loading PDF...</div>;

  return <div>
    <center>
      {pdf}
    </center>
  </div>;
}


export default Invoice;
