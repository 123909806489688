//Sooyoung Kim
//Oct 17, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, callBackGenerator, getSession} from '../../util/util';
import {Button, Modal, ModalHeader, ModalBody,Row, Col, Input, FormGroup, Label, Table} from 'reactstrap';
import MyReactTable from '../util/my-react-table';
import MySelect from '../util/my-select';
import React, {useReducer, useEffect} from 'react';

let session = getSession();
let name = session.userFirstName+' '+session.userLastName;

//initialize the state
const initialState = {
  aqbAppraisers:[],
  selectedAqbAppraisersID:[],
  selectedAqbAppraisers:[],
  addAppraiserPopUp:false,
  maxRow:50,
  license_type:'',
  name:name,
};

//reducer function that perform state update
const reducer = getReducer();


const AQB  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    if(props.appraisal&&props.appraisal.property_state!==''){
      getAQB();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.appraisal]);

  //non API call but simpyl manage state
  const toggleAddAppraisers = () => {
    setState({addAppraiserPopUp:!state.addAppraiserPopUp});
  }

  const updateAQBAppraiserEmail = (index, email) => {
    let aqbAppraisers = state.selectedAqbAppraisers.slice();
    let targetAppraiser = aqbAppraisers[index];

    targetAppraiser.email = email;

    aqbAppraisers[index] = targetAppraiser;
    setState({selectedAqbAppraisers:aqbAppraisers});
  }

  const clickAQBListener = (checked, ID, info) => {
    let selectedID = state.selectedAqbAppraisersID.slice();
    let selectedAppraisers = state.selectedAqbAppraisers.slice();

    if(checked){
      let index = selectedID.indexOf(ID);

      if(index===-1)
        selectedID.push(ID);
        selectedAppraisers.push(info);
    }
    else{
      let index = selectedID.indexOf(ID);

      if(index!==-1)
        selectedID.splice(index,1);
        selectedAppraisers.splice(index,1);
    }
    console.log(ID);
    setState({selectedAqbAppraisersID:selectedID, selectedAqbAppraisers: selectedAppraisers});
  }

  //API call
  const addNewAppraisers = (e) => {
    e.preventDefault();

    let selectedAqbAppraisers = state.selectedAqbAppraisers;
    let successCount = 0;

    for(let i=0;i<selectedAqbAppraisers.length;i++){
      // eslint-disable-next-line no-loop-func
      let callBack = (response)=>{console.log(response);
        let code = response.data?response.data.code:undefined;
        if(code==='00'){
          successCount++;
          if(successCount === selectedAqbAppraisers.length){
            toggleAddAppraisers();
            setState({selectedAqbAppraisersID:[], selectedAqbAppraisers:[]});
          }
        }
      };
      callBack = callBack.bind(this);

      let parameters =[
        {
          field:'billingName',
          value:''
        },
        {
          field:'street',
          value:selectedAqbAppraisers[i].street
        },
        {
          field:'city',
          value:selectedAqbAppraisers[i].city
        },
        {
          field:'state',
          value:selectedAqbAppraisers[i].state
        },
        {
          field:'county',
          value:''
        },
        {
          field:'zip',
          value:selectedAqbAppraisers[i].zip
        },
        {
          field:'billingStreet',
          value:''
        },
        {
          field:'billingCity',
          value:''
        },
        {
          field:'billingCounty',
          value:''
        },
        {
          field:'billingState',
          value:''
        },
        {
          field:'billingZip',
          value:''
        },
        {
          field:'firstName',
          value:selectedAqbAppraisers[i].firstName
        },
        {
          field:'lastName',
          value:selectedAqbAppraisers[i].lastName
        },
        {
          field:'email',
          value:selectedAqbAppraisers[i].email
        },
        {
          field:'notifyEmails',
          value:''
        },
        {
          field:'password',
          value:''
        },
        {
          field:'phone',
          value:selectedAqbAppraisers[i].phone
        },
        {
          field:'preferredCommunication',
          value:''
        },
        {
          field:'lat',
          value:''
        },
        {
          field:'lng',
          value:''
        },
        {
          field:'taxID',
          value:''
        },
        {
          field:'eoExpirationDate',
          value:''
        },
        {
          field:'licenseType',
          value:selectedAqbAppraisers[i].licenseType
        },
        {
          field:'licenseNumber',
          value:selectedAqbAppraisers[i].licenseNumber
        },
        {
          field:'licenseState',
          value:''
        },
        {
          field:'licenseExpiration',
          value:selectedAqbAppraisers[i].expDate
        },
        {
          field:'author',
          value:state.name
        }
      ];

      httpPost('appraiser/create', parameters, 'Appraiser profile for '+selectedAqbAppraisers[i].firstName+' '+selectedAqbAppraisers[i].lastName+' created successfully.', 'Oops, something went wrong and could not create the appraiser profile for '+selectedAqbAppraisers[i].firstName+' '+selectedAqbAppraisers[i].lastName+'. Please try again later.', callBack);
    }
  }

  const getAQB = () => {
    console.log(props.aqbCounty);
    let url = 'appraiser/aqb/get/county='+props.aqbCounty+'&state='+props.appraisal.property_state+'&license_type='+state.license_type+'&limit='+state.maxRow;
    let callBack = apiCallBack([{state:'aqbAppraisers', key:'data'}, {state:'selectedAqbAppraisersID', value:[]}, {state:'selectedAqbAppraisers', value:[]}]);

    httpGet(url, '', 'Oops, something went wrong and could not retrieve new appraisers.', callBack);
  }


  //render

  const columns = [
    {
      id:'action',
      Header:'',
      minWidth:50,
      width:50,
      accessor: d => '',
      Cell: props =>{
        return <div>
          <div className="display-inline">
            <FormGroup check>
              <Label check>
                <Input type="checkbox" checked={state.selectedAqbAppraisersID.indexOf(props.row.id)!==-1} onChange={(e)=>clickAQBListener(e.target.checked,props.row.id,props.row.original)}/>
              </Label>
            </FormGroup>
          </div>
        </div>
      }
    },
    {
      id: 'appraiser',
      Header: 'Appraiser',
      width:210,
      maxWidth:210,
      Cell: cell=>{
        let officePhone;

        if(cell.row.original.phone&&cell.row.original.phone!==''){
          officePhone = <div>
            Office: <a href={"tel:"+cell.row.original.phone}>{cell.row.original.phone}</a> <div className="display-inline" style={{fontSize:'9px'}}>
                    <div style={{fontSize:'9px'}}>
                      &nbsp;<a onClick={(e)=>props.recordBid('phone',cell.row.original.firstName+' '+cell.row.original.lastName, '','','','', 0,'',cell.row.original.phone)} href={"tel:"+cell.row.original.phone}><i className="fa fa-phone"/><i>Bid</i></a>
                    </div>
            </div>
          </div>;
        }

        return <div>
          <Row>
            <Col sm="10">
              <i className="fa fa-drivers-license-o cursor-pointer link-color"></i>&nbsp;
              {cell.row.original.firstName+' '+cell.row.original.lastName}
            </Col>
          </Row>
          {officePhone}
        </div>
      },
      className: 'wordwrap',
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'licenseNumebr',
      Header: 'License #',
      width:100,
      maxWidth:130,
      accessor: d=>d.licenseNumber,
      Cell: props => {
        return <div>{props.row.original.licenseNumber}</div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'licenseType',
      Header: 'License Type',
      width:150,
      maxWidth:150,
      accessor: d=>d.licenseType,
      Cell: props => {
        return <div>{props.row.original.licenseType}</div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'effectiveDate',
      Header: 'Effective Date',
      width:120,
      maxWidth:130,
      accessor: d=>d.effectiveDate,
      Cell: props => {
        return <div>{props.row.original.effectiveDate}</div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'expDate',
      Header: 'Expiration Date',
      width:120,
      maxWidth:130,
      accessor: d=>d.expDate,
      Cell: props => {
        return <div>{props.row.original.expDate}</div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'address',
      Header: 'Address',
      accessor: d => d.street+' '+d.city+', '+d.state,
      className: 'wordwrap',
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'aqbCompliant',
      Header: 'AQB Compliant',
      width:120,
      maxWidth:130,
      Cell: props => {
        return <div>{props.row.original.aqbCompliant}</div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'discipline',
      Header: 'Discipline',
      width:120,
      maxWidth:130,
      Cell: props => {
        return <div>{props.row.original.discipline}</div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
  ];

  let aqbAppraisers;

  if(state.aqbAppraisers&&state.aqbAppraisers.length>0){
    aqbAppraisers = <MyReactTable columns={columns} data={state.aqbAppraisers} className="table table-striped" container={{height:'600px', overflow: 'auto'}}/>;
  }
  
  return <div>
    <Modal className="my-modal" isOpen={state.addAppraiserPopUp} toggle={toggleAddAppraisers} >
      <ModalHeader hidden={true} toggle={toggleAddAppraisers}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-warning"></i>&nbsp; Add Emails for Selected Appraisers</h5>
        </center>
        <br/>
        <form onSubmit={addNewAppraisers}>
          <Table className="table">
            <thead>
              <tr>
                <th width="30%">Name</th>
                <th width="70%">Email</th>
              </tr>
            </thead>
            <tbody>
              {
                state.selectedAqbAppraisers.map(
                  (aqbAppraiser, index)=>{
                    return <tr key={index}>
                      <td>{aqbAppraiser.firstName+" "+aqbAppraiser.lastName}</td>
                      <td>
                        <Input type="email" required={true} value={aqbAppraiser.email|| ''} onChange={(e)=>updateAQBAppraiserEmail(index, e.target.value)}/>
                      </td>
                    </tr>
                  }
                )
              }
            </tbody>
          </Table>
          <br/>
          <br/>
          <center>
            <Button color="warning"><i className="green-color fa fa-plus"></i>&nbsp;Add</Button>&nbsp;
            <Button color="info" onClick={toggleAddAppraisers}>Cancel</Button>
          </center>
        </form>
      </ModalBody>
    </Modal>
    <Row>
      <Col sm="3">
        <label><i className="fa fa-id-card-o"></i>&nbsp;License Type</label>
        <MySelect
          type="select"
          selectIsClearable={true}
          value={state.license_type}
          onChange={(v)=>setState({license_type:v})}
          options={[{label:"All",value:""},{label:"Licensed",value:"1"},{label:"Certified General",value:"2"},{label:"Certified Residential",value:"3"},{label:"Transitional License",value:"4"}]}
        />
      </Col>
      <Col sm="3">
        <label><i className="fa fa-map-marker"></i>&nbsp;County / City</label>
        <Input type="text" placeholder="Search by county" defaultValue={props.aqbCounty} onChange={(e)=>{props.setState('aqbCounty',e.target.value)}}/>
      </Col>
      <Col sm="3">
        <label><i className="fa fa-list-ol"></i>&nbsp;Max number of rows to display</label>
        <Input type="text" defaultValue={state.maxRow} onChange={(e)=>{setState({maxRow:e.target.value})}}/>
      </Col>
      <Col sm="3">
        <br/>
        <div className="align-right"><Button color="warning" onClick={()=>getAQB()} >Search</Button></div>
      </Col>
    </Row>
    <br/>
      {aqbAppraisers}
    <br/>
    <center>
      <Button color="warning" disabled={state.selectedAqbAppraisersID.length < 1} onClick={toggleAddAppraisers}><i className="fa fa-plus"></i> Add Selected Appraisers</Button>
    </center>
  </div>;
}


export default AQB;
