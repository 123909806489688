//Author Sooyoung Kim
//Date July 7, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, formatNumber, formatDate, getFromTo} from '../../../util/util';
import {Card, CardHeader, CardBody, Col, Row} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import MyChart from './../../util/my-chart';
import {NavLink} from 'react-router-dom';
import moment from 'moment';
import ReportFilter from '../report-filter';

//initialize the state
const initialState = {
  from:getFromTo('from'),
  to:getFromTo('end'),
  actualFrom:getFromTo('from'),
  actualTo:getFromTo('end'),
  report:{
    totalOrderAssociated:0,
    totalBusinessDay:0,
    quarterTotalBusinessDay:0,
    quarterTotalOrderAssociated:0
  },
  admins:[],
  taskTypes:[],
  taskType:''
};

//reducer function that perform state update
const reducer = getReducer();


const OperationCapabilitiesReport  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getTasks();
    getReportDetails();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  //API call
  //get task types
  const getTasks = () => {
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let allTasks = [];

        for(let i=0;i<response.data.data.length;i++){
          let tmp = {
            key: response.data.data[i],
            value: response.data.data[i]
          }

          allTasks.push(tmp);
        }
        setState({taskTypes:allTasks});
      }
    };
    callBack = callBack.bind(this);

    httpGet('report/operation/capabilities/get/task', '', 'Oops, something went wrong and could not load list of tasks. Please try again later.', callBack);
  }

  const getReportDetails = () => {
    setState({report:{
      totalOrderAssociated:0,
      totalBusinessDay:0,
      quarterTotalBusinessDay:0,
      quarterTotalOrderAssociated:0
    }, admins:[], actualFrom:state.from, actualTo:state.to});

    let callBack = (response)=>{console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let admins = response.data.data.admins;
        let report = {
          tasks:response.data.data.tasks,
          totalOrderAssociated:response.data.data.total_order_handled,
          totalBusinessDay:response.data.data.total_business_day,
          quarterTotalBusinessDay:response.data.data.quarter_total_business_day,
          quarterTotalOrderAssociated:response.data.data.quarter_total_order_handled,
          quarterBeginFrom:response.data.data.quarter_begin,
          quarterBeginTo:response.data.data.quarter_end
        }
        setState({report:report, admins:admins});
      }
    };
    callBack = callBack.bind(this);

    httpGet('report/operation/capabilities/from='+state.from+'&to='+state.to+'&task='+state.taskType, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  //render
  let from, to;
  if(state.from!=='')
    from = moment(state.from);
  if(state.to!=='')
    to = moment(state.to);

  let chart;
  let adminCharts;

  if(state.report.tasks){
    let tasks = Object.keys(state.report.tasks);
    let tasksPerformed = [];
    let tasksPerformedAvg = [];


    for(let i=0;i<tasks.length;i++){
      tasksPerformed.push(state.report.tasks[tasks[i]]);
      tasksPerformedAvg.push(Math.round((state.report.tasks[tasks[i]]/state.report.totalBusinessDay),2));
    }

    let config = {
      chart:{
        height:250,
        type:'column'
      },
      xAxis: {
        categories: tasks
      },
      series: [
        {
          data: tasksPerformed,
          name:'# of operation'
        },
        {
          data: tasksPerformedAvg,
          name:'avg # of operation per business day'
        }
      ],
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true
          }
        }
      },
      title:{
        text: 'Tasks Performed'
      }
    }

    chart = <MyChart options = {config}/>;
  }

  let adminsTaskPerformed = [];
  let totalAdminTaskPerformed = 0;
  if(state.admins.length>0){
    adminCharts = <Row>
      {
        state.admins.map(
          (admin, index)=>{
            let totalTaskPerformed = 0;
            let tasks = Object.keys(admin.data);

            let viewOrder = 0;
            for(let i=0;i<tasks.length;i++){
              if(tasks[i]==='View Order'){
                viewOrder = admin.data[tasks[i]];
                tasks.splice(i, 1);
                break;
              }
            }

            let tasksPerformed = [];
            let specificTaskPerformed = 0;
            for(let i=0;i<tasks.length;i++){
              totalTaskPerformed= totalTaskPerformed + parseInt(admin.data[tasks[i]],10);

              let tmp = {
                name: tasks[i],
                y:parseInt(admin.data[tasks[i]], 10),
              };
              tasksPerformed.push(tmp);
            }

            specificTaskPerformed = parseInt(totalTaskPerformed,10);
            totalAdminTaskPerformed += totalTaskPerformed;

            let tmp2 = {
              name: admin.name,
              y:specificTaskPerformed,
              x: 0
            };
            adminsTaskPerformed.push(tmp2);

            let config = {
              chart:{
                height:250
              },
              xAxis: {
                categories: tasks
              },
              series: [
                {
                  data: tasksPerformed,
                  name:'# of operation',
                  color:'green'
                }
              ],
              plotOptions: {
                line: {
                  dataLabels: {
                    enabled: true,
                  }
                }
              },
              title:{
                text: 'Tasks Performed'
              }
            }

            console.log(admin.quarter_total_order_associated/state.report.quarterTotalBusinessDay);

            return <Col sm="6" key={index} style={{marginBottom:'25px'}}>
              <Row>
                <Col sm="4">
                  <Card>
                    <CardHeader><font style={{fontSize:'15px',fontWeight:'bold'}}><i className="fa fa-user"/>&nbsp; <NavLink target="_blank" to={"/report/admin-timeline/from="+state.from+'&to='+state.to+'&user_fk='+admin.ID}>{admin.name}</NavLink></font></CardHeader>
                    <CardBody>
                      <b>{formatNumber(viewOrder)}</b> <font style={{fontSize:'12px'}}>viewed order(s)</font><br/>
                      <b>{formatNumber(admin.total_order_associated)}</b> <font style={{fontSize:'12px'}}>order(s) handled</font><br/>
                      <b>{formatNumber(totalTaskPerformed)}</b> <font style={{fontSize:'12px'}}>task(s) performed</font><br/>

                      <div className="my-divider">&nbsp;</div>
                      <br/>
                      <b>{(admin.quarter_total_order_associated/state.report.quarterTotalBusinessDay).toFixed(2)}</b> <font style={{fontSize:'12px'}}>avg order(s) handled prior quater</font><br/>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="8">
                  <MyChart options={config}/>
                </Col>
              </Row>
            </Col>
          }
        )
      }
    </Row>
  }

  adminsTaskPerformed = adminsTaskPerformed.map(task =>{
    let x = task.x;
    if(task.y > 0){
      x = Math.round((task.y / totalAdminTaskPerformed)* 100);
    }
    return{
      ...task,
      x
    }
  });

  let taskTypeTemp = state.taskType!==''?state.taskType:'';
  const config2 ={
    chart: {
      type: 'pie',
      height:400,
      width:400
    },
    title: {
      text: 'Admin '+taskTypeTemp+' Tasks Performance'
    },
    subtitle: {
      text: null
    },
    plotOptions: {
      series: {
        showInLegend: true,
        dataLabels: {
          style: {
            fontSize: '10px'
          },
          enabled: true,
          overflow:'allow',
          format: '{point.name}: {point.y} tasks ({point.x}%)'
        },
        label: {
          connectorAllowed:true,
        }
      }
    },

    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> of total ({point.x}%)<br/>'
    },
    series: [{
      name: 'Rule code',
      colorByPoint: true,
      data: adminsTaskPerformed,
      type: 'pie'
    }],
    credits:{
      enabled:false
    },
    legend:{
      align: 'right',
      layout: 'vertical',
      verticalAlign: 'top',
      x: 340,
      y: 0,
      itemStyle:{
        fontSize:13
      },
      labelFormatter: function () {
        return this.name+' - '+formatNumber(this.series.yData[this.index]);
      }
    }
  };
  let chart2;
  if(state.admins.length>0){
    chart2 = <MyChart options={config2}/>;
  }

  let reportFiltersConfig = [
    {id:'from',value:from, updateFunc:(v)=>setState({from:v}), width:'2'},
    {id:'to',value:to, updateFunc:(v)=>setState({to:v}), width:'2'},
    {id:'customSelect',value:state.period, updateFunc:(v)=>setState({period:v}), width:'2', label:'Period', clearable:true, className:"", options:[{label:"All",value:""}].concat(
      state.taskTypes.filter((task)=>{
        if(task.value==='View Order'){
          return false;
        }
        return true;
      }).map((task)=>{
        return {label:task.value, value:task.value};
      })
    )},
    {id:'button',value:'Submit', updateFunc:getReportDetails, width:'2', className:"align-right", color:"warning"},
  ];

  return <div>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Operation Capacity Report
      </CardHeader>
      <CardBody>
        <ReportFilter {...props} configs={reportFiltersConfig}/>
        <div className="my-divider">&nbsp;</div>
        <div style={{fontSize:'11px'}}>
          <i>*Order handled is the distinct number of order handled excluding viewing order. Ops has to perform some task like making a comment/updating order/updating billing/assigning to be counted toward this statistic.</i><br/>
          <i>*Order viewed is the total number of times order being viewed by ops.</i><br/>
          <i>*Task performed is the total number of task performed. E.g Ops making two comments and updating the order after viewing a specific order will be counted as 4 task performed.</i><br/>
        </div>
        <Row>
          <Col sm="8">
            <Row>
              <Col sm="5">
                <font style={{fontSize:'20px',fontWeight:'bold',marginBottom:'10px'}}>
                  {state.actualFrom} - {state.actualTo}
                </font>
                <Row>
                  <Col sm="4">
                    <div className="my-well display-inline" style={{padding:'5px', height:'100px'}}>
                      <div style={{height:'40px', borderBottom:'1px solid #d2d2d2', lineHeight:'10px'}}>
                        <center><font style={{fontSize:'10px'}}>Total Order Handled</font></center>
                      </div>
                      <font style={{fontSize:'25px',fontWeight:'bold'}}>
                        <center>{formatNumber(state.report.totalOrderAssociated)}</center>
                      </font>
                    </div>
                  </Col>
                  <Col sm="4">
                    <div className="my-well display-inline" style={{padding:'5px', height:'100px'}}>
                      <div style={{height:'40px', borderBottom:'1px solid #d2d2d2', lineHeight:'10px'}}>
                        <center><font style={{fontSize:'10px'}}>Avg Order Handled Per Business Day</font></center>
                      </div>
                      <font style={{fontSize:'25px',fontWeight:'bold'}}>
                        <center>{formatNumber(Math.round(state.report.totalOrderAssociated/state.report.totalBusinessDay))}</center>
                      </font>
                    </div>
                  </Col>
                  <Col sm="4">
                    <div className="my-well display-inline" style={{padding:'5px', height:'100px'}}>
                      <div style={{height:'40px', borderBottom:'1px solid #d2d2d2', lineHeight:'10px'}}>
                        <center><font style={{fontSize:'10px'}}>Total Business Day</font></center>
                      </div>
                      <font style={{fontSize:'25px',fontWeight:'bold'}}>
                        <center>{formatNumber(state.report.totalBusinessDay)}</center>
                      </font>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col sm="7" style={{borderLeft:'1px solid #d2d2d2'}}>
                <font style={{fontSize:'20px',fontWeight:'bold',marginBottom:'10px'}}>
                  Past Quarter
                </font>
                <Row>
                  <Col sm="3">
                    <div className="my-well display-inline" style={{padding:'5px',width:'100%', height:'100px'}}>
                      <div style={{height:'40px', borderBottom:'1px solid #d2d2d2', lineHeight:'10px'}}>
                        <center><font style={{fontSize:'10px'}}>Date Range</font></center>
                      </div>
                      <font style={{fontSize:'12px',fontWeight:'bold'}}>
                        <center>{formatDate(state.report.quarterBeginFrom)+' - '+formatDate(state.report.quarterBeginTo)}</center>
                      </font>
                    </div>
                  </Col>
                  <Col sm="3">
                    <div className="my-well display-inline" style={{padding:'5px',width:'100%', height:'100px'}}>
                      <div style={{height:'40px', borderBottom:'1px solid #d2d2d2', lineHeight:'10px'}}>
                        <center><font style={{fontSize:'10px'}}>Total Order Handled</font></center>
                      </div>
                      <font style={{fontSize:'25px',fontWeight:'bold'}}>
                        <center>{formatNumber(state.report.quarterTotalOrderAssociated)}</center>
                      </font>
                    </div>
                  </Col>
                  <Col sm="3">
                    <div className="my-well display-inline" style={{padding:'5px',width:'100%', height:'100px'}}>
                      <div style={{height:'40px', borderBottom:'1px solid #d2d2d2', lineHeight:'10px'}}>
                        <center><font style={{fontSize:'10px'}}>Total Business Day</font></center>
                      </div>
                      <font style={{fontSize:'25px',fontWeight:'bold'}}>
                        <center>{formatNumber(state.report.quarterTotalBusinessDay)}</center>
                      </font>
                    </div>
                  </Col>
                  <Col sm="3">
                    <div className="my-well display-inline" style={{padding:'5px',width:'100%', height:'100px'}}>
                      <div style={{height:'40px', borderBottom:'1px solid #d2d2d2', lineHeight:'10px'}}>
                        <center><font style={{fontSize:'10px'}}>Avg Total Order Handled Per Day</font></center>
                      </div>

                      <font style={{fontSize:'25px',fontWeight:'bold'}}>
                        <center>{formatNumber(Math.round((state.report.quarterTotalOrderAssociated/state.report.quarterTotalBusinessDay),2))}</center>
                      </font>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            {chart}
          </Col>
          <Col sm="4" style={{borderLeft:'1px solid #d2d2d2'}}>
            <div className="display-inline" style={{paddingLeft:'30px'}}>
              {chart2}
            </div>
          </Col>
        </Row>
        <div className="my-divider">&nbsp;</div><br/>
        {adminCharts}
      </CardBody>
    </Card>
  </div>;
}


export default OperationCapabilitiesReport;
