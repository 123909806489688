//Author Sooyoung Kim
//Date Aug 14, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, generateSID, formatDateTime, formatNumber, stringToHexCode} from '../../util/util';
import React, {useReducer, useEffect, useRef} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReportFilter  from '../report/report-filter';
import {Row, Col} from 'reactstrap';
import {NavLink} from 'react-router-dom';

//initialize the state
const initialState = {
  appraisals:[],
  limit: 25,
  offset: 0,
  hasMoreAppraisals: true,
  loading: false,
  sort: 'datetime_submitted',
  order: 'ASC',
  status: 'Submitted',
  client: '',
  borrower:'',
  entity_name:'',
  appraiser_name:'',
  state:'',
  county:'',
  city:'',
  street:'',
  accountManager:'',
  loan_num:'',
  pipelineStatusCount:[],
  priority:'',
  rush:'',
  totalCount:0
};

//reducer function that perform state update
const reducer = getReducer();

const GlobalAppraisals  = (props)=>{
  const controller = new AbortController();
  const newsid = useRef(generateSID());

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getPipelineStatusCount();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    if(state.appraisals.length<=0 && state.hasMoreAppraisals){
      loadMore();
      getPipelineStatusCount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state]);

  //non API call but simpyl manage state
  //click handler that switch the state of sorting order then call the function to refresh the list
  //to achieve the "sorting"
  const columnClickHandler = (col) => {
    if(state.sort===col){
      if(state.order==='ASC')
        setState({order:'DESC'});
      else
        setState({order:'ASC'});
    }
    else
      setState({sort:col,order:'ASC'});
    refreshList();
  }

  //this function refresh the list of orders pipeline
  const refreshList = () => {
    let SID = generateSID();
    newsid.current = SID; 
    setState({
      appraisals:[],
      totalCount:0,
      hasMoreAppraisals:true,
      offset:0,
    });
    getPipelineStatusCount();
  }

  const setStatus = (status) => {
    setState({status:status,sort:''});
    refreshList();
  }

  //this function render the sort icon at each column of the table
  const renderSortIcon = (col) => {
    if(state.sort===col){
      if(state.order==='ASC')
        return <i className="red-color fa fa-arrow-down"></i>
      else
        return <i className="red-color fa fa-arrow-up"></i>
    }
  }

  //render function for infinite scroller
  const renderAppraisals = () => {
    let dateHeader = <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('datetime_submitted')}><i className="fa fa-calendar-o"></i>&nbsp;Date Submitted {renderSortIcon('datetime_submitted')}</th>;
    let dateHeader2;
    let dateHeader3;
    let dateHeader4;

    if(state.status==='Assigned'||state.status==='Auto Assigning'){
      dateHeader2 = <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('datetime_quote')}><i className="fa fa-calendar-o"></i>&nbsp;Quote {renderSortIcon('datetime_quote')}</th>;
      dateHeader = <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('datetime_expected')}><i className="fa fa-calendar-o"></i>&nbsp;Client Due {renderSortIcon('datetime_expected')}</th>;
    }
    else if(state.status==='Submitted'||state.status==='Quote'||state.status==='Hold'){
      dateHeader2 = <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('datetime_submitted')}><i className="fa fa-calendar-o"></i>&nbsp;Submitted {renderSortIcon('datetime_submitted')}</th>;
      dateHeader = <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('datetime_expected')}><i className="fa fa-calendar-o"></i>&nbsp;Client Due {renderSortIcon('datetime_expected')}</th>;
    }
    else if(state.status==='Inspection Scheduled'){
      dateHeader3 = <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('datetime_inspection')}><i className="fa fa-calendar-o"></i>&nbsp;Inspection {renderSortIcon('datetime_inspection')}</th>;
      dateHeader2 = <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('datetime_assigned')}><i className="fa fa-calendar-o"></i>&nbsp;Assigned {renderSortIcon('datetime_assigned')}</th>;
      dateHeader = <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('datetime_expected')}><i className="fa fa-calendar-o"></i>&nbsp;Client Due {renderSortIcon('datetime_expected')}</th>;
    }
    else if(state.status==='Pending Inspection'){

      dateHeader2 = <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('datetime_accepted')}><i className="fa fa-calendar-o"></i>&nbsp;Accepted {renderSortIcon('datetime_accepted')}</th>;
      dateHeader = <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('datetime_expected')}><i className="fa fa-calendar-o"></i>&nbsp;Client Due {renderSortIcon('datetime_expected')}</th>;
    }
    else if(state.status==='Waiting On POC'){
      dateHeader2 = <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('datetime_assigned')}><i className="fa fa-calendar-o"></i>&nbsp;Assigned {renderSortIcon('datetime_assigned')}</th>;
      dateHeader = <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('datetime_expected')}><i className="fa fa-calendar-o"></i>&nbsp;Client Due {renderSortIcon('datetime_expected')}</th>;
    }
    else if(state.status==='Conditioned'){
      dateHeader3 = <th className="cursor-pointer" width="10%"><i className="fa fa-info"></i>&nbsp;Status</th>;
      dateHeader2 = <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('datetime_created')}><i className="fa fa-calendar-o"></i>&nbsp;Conditioned {renderSortIcon('datetime_created')}</th>;
      dateHeader = <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('datetime_expected')}><i className="fa fa-calendar-o"></i>&nbsp;Client Due {renderSortIcon('datetime_expected')}</th>;
    }
    else if(state.status==='Shipping'){
      dateHeader2 = <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('datetime_review_started')}><i className="fa fa-calendar-o"></i>&nbsp;Review {renderSortIcon('datetime_review_started')}</th>;
      dateHeader = <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('datetime_expected')}><i className="fa fa-calendar-o"></i>&nbsp;Client Due {renderSortIcon('datetime_expected')}</th>;
    }
    else if(state.status==='Inspection Completed'){
      dateHeader2 = <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('datetime_inspection_completed')}><i className="fa fa-calendar-o"></i>&nbsp;Inspection Completed {renderSortIcon('datetime_inspection_completed')}</th>;
      dateHeader = <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('datetime_expected')}><i className="fa fa-calendar-o"></i>&nbsp;Client Due {renderSortIcon('datetime_expected')}</th>;
    }
    else if(state.status==='Review'){
      dateHeader3 = <th className="cursor-pointer" width="10%"><i className="fa fa-tags"></i>&nbsp;Review Note</th>;
      dateHeader2 = <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('datetime_appraisal_uploaded')}><i className="fa fa-calendar-o"></i>&nbsp;Initial Upload Date/Time{renderSortIcon('datetime_appraisal_uploaded')}</th>;
      dateHeader4 = <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('datetime_latest_appraisal_uploaded')}><i className="fa fa-calendar-o"></i>&nbsp;Latest Upload Date/Time{renderSortIcon('datetime_latest_appraisal_uploaded')}</th>;
      dateHeader = <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('datetime_expected')}><i className="fa fa-calendar-o"></i>&nbsp;Client Due {renderSortIcon('datetime_expected')}</th>;
    }
    else if(state.status==='Completed'){
      dateHeader = <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('datetime_completed')}><i className="fa fa-calendar-o"></i>&nbsp;Completed {renderSortIcon('datetime_completed')}</th>;
    }
    return (
      <div>
        <table className="primary-table">
          <thead>
            <tr>
              <th className="cursor-pointer" width="12%" onClick={()=>columnClickHandler('reference_num')}><i className="fa fa-search"></i>&nbsp;Reference # {renderSortIcon('reference_num')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('loan_num')}><i className="fa fa-folder"></i>&nbsp;Loan # {renderSortIcon('loan_num')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('CONCAT(borrower_f_name," ",borrower_l_name)')}><i className="fa fa-user"></i>&nbsp;Borrower {renderSortIcon('CONCAT(borrower_f_name," ",borrower_l_name)')}</th>
              <th className="cursor-pointer" width="20%" onClick={()=>columnClickHandler('property_street')}><i className="fa fa-map-marker"></i>&nbsp;Property Address {renderSortIcon('property_street')}</th>
              <th className="cursor-pointer" onClick={()=>columnClickHandler('')}><i className="fa fa-info-circle"></i>&nbsp;Order Information {renderSortIcon('')}</th>
              {dateHeader3}
              {dateHeader2}
              {dateHeader4}
              {dateHeader}
            </tr>
          </thead>
          <tbody>
          {
            state.appraisals.map(
              (appraisal)=>{
                let rowClassName;

                if(appraisal.pipeline_late && appraisal.pipeline_late==='yes')
                  rowClassName = 'flashit red-color';
                let date2;
                let date3;
                let date4;
                let date = formatDateTime(appraisal.datetime_submitted);

                if(state.status==='Assigned'||state.status==='Auto Assigning'){
                  date = formatDateTime(appraisal.datetime_expected);
                  date2 = <td>{formatDateTime(appraisal.datetime_quote)}</td>;
                }
                else if(state.status==='Submitted'||state.status==='Quote'||state.status==='Hold'){
                  date2 = <td>{formatDateTime(appraisal.datetime_submitted)}</td>;
                  date = formatDateTime(appraisal.datetime_expected);
                }
                else if(state.status==='Inspection Scheduled'){
                  date = formatDateTime(appraisal.datetime_expected);
                  date2 = <td>{formatDateTime(appraisal.datetime_assigned)}</td>;
                  date3 = <td>{formatDateTime(appraisal.datetime_inspection)}</td>;
                }
                else if(state.status==='Pending Inspection'){
                  date = formatDateTime(appraisal.datetime_expected);
                  date2 = <td>{formatDateTime(appraisal.datetime_accepted)}</td>;
                }
                else if(state.status==='Waiting On POC'){
                  date = formatDateTime(appraisal.datetime_expected);
                  date2 = <td>{formatDateTime(appraisal.datetime_assigned)}</td>;
                }
                else if(state.status==='Conditioned'){
                  date = formatDateTime(appraisal.datetime_expected);
                  date2 = <td>{formatDateTime(appraisal.datetime_created)}</td>;
                  date3 = <td>{appraisal.last_condition_status}</td>;
                }
                else if(state.status==='Shipping'){
                  date = formatDateTime(appraisal.datetime_expected);
                  date2 = <td>{formatDateTime(appraisal.datetime_review_started)}</td>;
                }
                else if(state.status==='Inspection Completed'){
                  date = formatDateTime(appraisal.datetime_expected);
                  date2 = <td>{formatDateTime(appraisal.datetime_inspection_completed)}</td>;
                }
                else if(state.status==='Review'){
                  date = formatDateTime(appraisal.datetime_expected);
                  date2 = <td>{formatDateTime(appraisal.datetime_appraisal_uploaded)}</td>;
                  date4 = <td>{formatDateTime(appraisal.datetime_latest_appraisal_uploaded)}</td>;
                  date3 = <td>{appraisal.labels}</td>;
                }
                else if(state.status==='Completed'){
                  date = formatDateTime(appraisal.datetime_completed);
                }

                let path = 'appraisal';

                if(appraisal.status==='Submitted')
                  path = 'assignment';

                let icon;
                let iconChecks = [];


                if(appraisal.account_manager !=='')
                  iconChecks.push(<div className="display-inline dark-red-color"><span className="my-badge-no-color" style={{backgroundColor:"#"+stringToHexCode(appraisal.account_manager_email)}}>{appraisal.account_manager.split(" ").map((n)=>n[0]).join(" ")}</span></div>);
                if(appraisal.rush==='Rush')
                  iconChecks.push(<div className="display-inline dark-red-color"><span className="my-badge2">RUSH</span> </div>);
                if(appraisal.loan_type==='FHA')
                  iconChecks.push(<div className="display-inline dark-red-color"><span className="my-badge2">FHA</span> </div>);
                if(appraisal.priority==='P0 - Nuclear')
                  iconChecks.push(<div className="display-inline dark-blue-color"><span className="my-badge4">P0</span></div>);
                if(appraisal.priority==='P1 - Hot')
                  iconChecks.push(<div className="display-inline dark-blue-color"><span className="my-badge4">P1</span></div>);
                if(appraisal.priority==='P2 - Rush By End of Day')
                  iconChecks.push(<div className="display-inline dark-blue-color"><span className="my-badge4">P2</span></div>);
                if(appraisal.priority==='P3 - Due Today')
                  iconChecks.push(<div className="display-inline dark-blue-color"><span className="my-badge4">P3</span></div>);
                if(appraisal.priority==='P4 - Standard')
                  iconChecks.push(<div className="display-inline dark-blue-color"><span className="my-badge4">P4</span></div>);
                if(appraisal.auto_assign==='yes')
                  iconChecks.push(<div className="display-inline dark-red-color"><i className="fa fa-laptop"/> </div>);
                if(appraisal.hotfile==='yes')
                  iconChecks.push(<div className="display-inline red-color"><i className="fa fa-fire"/> </div>);
                if(appraisal.pending_receivable==='yes')
                  iconChecks.push(<div className="display-inline green-color"><i className="fa fa-money"/> </div>);
                if(appraisal.potential_complex==='yes')
                  iconChecks.push(<div className="display-inline green-color"><i className="fa fa-exclamation-circle orange-color"/> </div>);

                  icon = <div className="display-inline">
                    {iconChecks.map(
                      (jsx, index)=>{ return <div className="display-inline" key={index}>{jsx}&nbsp;</div>;}
                    )}
                  </div>;

                let appraiser;
                let lender;

                if(appraisal.company!=='')
                  lender = <div>
                    <i className="fa fa-bank icon-color"></i>&nbsp;
                    {appraisal.company}
                  </div>;

                if(appraisal.appraiser_name!==''){
                  appraiser = <div>
                    <i className="fa fa-user icon-color"></i>&nbsp;
                    {appraisal.appraiser_name}
                  </div>;
                }
                return (
                  <tr className={rowClassName} key={appraisal.ID} onClick={()=>window.open(path+"/"+appraisal.ID)}>
                    <td><b>{appraisal.reference_num}<br/>{icon}</b></td>
                    <td>{appraisal.loan_num}</td>
                    <td>{appraisal.borrower_f_name+' '+appraisal.borrower_l_name}</td>
                    <td>{appraisal.property_street+' '+appraisal.property_city+', '+appraisal.property_state+' '+appraisal.property_zip}</td>
                    <td>
                      {appraiser}
                      {lender}
                      <i className="fa fa-hashtag icon-color"></i>&nbsp;
                      {appraisal.loan_purpose}
                    </td>
                    {date3}
                    {date2}
                    {date4}
                    <td>{date}</td>
                  </tr>
                );
              }
            )
          }
          </tbody>
        </table>
      </div>
    )
  }

  //API call
  //function that fire when the infinite scroll reach bottom
  const loadMore = () => {
    //do not load if there is no more appraisals or it's loading data
    if(state.hasMoreAppraisals&&!state.loading){
      let sID = newsid.current;
      //set loading equals to true so it won't fire off before we are done
      setState({loading:true});

      let borrower = encodeURIComponent(state.borrower.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
      let entity_name = encodeURIComponent(state.entity_name.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
      let loan_num = encodeURIComponent(state.loan_num.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
      let street = encodeURIComponent(state.street.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
      let appraiser_name = encodeURIComponent(state.appraiser_name.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
      let city = encodeURIComponent(state.city.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
      let county = encodeURIComponent(state.county.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));

      let url = 'appraisal/global/get/limit='+state.limit+'&offset='+state.offset+'&order='+state.order+'&sort='+state.sort+'&entity_name='+entity_name+'&status='+state.status+'&borrower='+borrower+'&loan_num='+loan_num+'&state='+state.state+'&county='+county+'&city='+city+'&street='+street+'&client='+state.client+'&accountManager='+state.accountManager+'&appraiser_name='+appraiser_name+'&priority='+state.priority+'&rush='+state.rush;

      //callback handler that update the state when http request return
      let callBack = (response)=>{

        if(sID===newsid.current){
          let code = response.data?response.data.code:undefined;
          if(code!=='00'){
            setState({hasMoreAppraisals:false});
          }
          else{
            let newAppraisals = response.data.data;
            let hasMoreAppraisals = true;
            let newOffset = state.offset;
            let totalCount = response.data.count;

            //if http request return empty then no more results, end of list
            if(newAppraisals.length<=0){
              hasMoreAppraisals = false;
            }
            else{
              //increment the offset
              newOffset = state.offset + 1;
            }

            //concat the current array of announcement
            if(state.appraisals.length>0){
              let temp = [...state.appraisals,...newAppraisals];

              setState({appraisals:temp});
            }
            else
              setState({appraisals:newAppraisals});

            setState({hasMoreAppraisals:hasMoreAppraisals, offset:newOffset, totalCount:totalCount});
          }
        }
      };
      callBack = callBack.bind(this);

      //error handler when the http request return with error
      let errorCallBack = apiCallBack([{state:'hasMoreAppraisals', value:false}]);

      //collect the promise and wait for it to finish performing it's task
      let promises = httpGet(url, '', 'Oops, something went wrong and could not load appraisals. Please try again later.', callBack, errorCallBack);
      promises
        .then(
          function(result){
            //set loading equals to false so the function could be fire off once again
            setState({loading:false});
          }
        );
    }
  }

  //get pipeline status count
  const getPipelineStatusCount = () => {
    let borrower = encodeURIComponent(state.borrower.replace(/\//g, '%ForwardSlash'));
    let entity_name = encodeURIComponent(state.entity_name.replace(/\//g, '%ForwardSlash'));
    let loan_num = encodeURIComponent(state.loan_num.replace(/\//g, '%ForwardSlash'));
    let street = encodeURIComponent(state.street.replace(/\//g, '%ForwardSlash'));
    let appraiser_name = encodeURIComponent(state.appraiser_name.replace(/\//g, '%ForwardSlash'));
    let city = encodeURIComponent(state.city.replace(/\//g, '%ForwardSlash'));
    let county = encodeURIComponent(state.county.replace(/\//g, '%ForwardSlash'));

    let url = 'appraisal/pipeline/global/count/status='+state.status+'&entity_name='+entity_name+'&borrower='+borrower+'&loan_num='+loan_num+'&state='+state.state+'&county='+county+'&city='+city+'&street='+street+'&client='+state.client+'&accountManager='+state.accountManager+'&appraiser_name='+appraiser_name+'&priority='+state.priority+'&rush='+state.rush;
    let callBack = apiCallBack([{state:'pipelineStatusCount', key:'data'}]);
	  httpGet(url, '', 'Oops, something went wrong and could not load the information of your pipeline. Please try again later.', callBack);
  }

  //render
  let pipelineStatusCounts;

  if(state.pipelineStatusCount.length>0){
    pipelineStatusCounts = state.pipelineStatusCount.map(
      (pipelineStatus,index)=>{
        let redTab;
        let redBadge;

        if(pipelineStatus.status==='Hold'){
          if(state.status===pipelineStatus.status)
            redTab = {
              background:'#ff6666',
              color:'white'
            };
          else
            redTab = {
              background:'#ffcccc',
            };
          redBadge = {
            background:'#ff1a1a'
          };
        }
        return(
          <div key={index} style={redTab} className={"pipeline-stage"+(pipelineStatus.status===state.status?" active":"")} onClick={()=>setStatus(pipelineStatus.status)}>
            <span className="my-badge3" style={redBadge}>{pipelineStatus.count}</span> {pipelineStatus.status}
          </div>
        )
      }
    );
  }

  let reportFiltersConfig = [
    {id:'orderStatus',value:state.status, updateFunc:(v)=>setState({status:v}), width:'2', labelClassName:"no-margin-bottom orange-color"},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'2', label:'Lender'},
    {id:'entity',value:state.entity_name, updateFunc:(v)=>setState({entity_name:v}), width:'2'},
    {id:'custom',value:state.appraiser_name, updateFunc:(v)=>setState({appraiser_name:v}), width:'2', label:'Appraiser'},
    {id:'custom',value:state.loan_num, updateFunc:(v)=>setState({loan_num:v}), width:'2', label:'Loan # / Reference #'},
    {id:'custom',value:state.borrower, updateFunc:(v)=>setState({borrower:v}), width:'2', label:'Borrower'},
    {id:'state',value:state.state, updateFunc:(v)=>setState({state:v}), width:'2'},
    {id:'county',value:state.county, updateFunc:(v)=>setState({county:v}), width:'2'},
    {id:'city',value:state.city, updateFunc:(v)=>setState({city:v}), width:'2'},
    {id:'custom',value:state.street, updateFunc:(v)=>setState({street:v}), width:'2', label:'Street'},
    {id:'accountManager',value:state.accountManager, updateFunc:(v)=>setState({accountManager:v}), width:'2'},
    {id:'priority',value:state.priority, updateFunc:(v)=>setState({priority:v}), width:'2'},
    {id:'rush',value:state.rush, updateFunc:(v)=>setState({rush:v}), width:'2'},
    {id:'button',value:'Submit', updateFunc:refreshList, width:'10', className:"align-right", color:"warning"},
  ];

  return <div>
    <InfiniteScroll
      className="my-well"
      dataLength={state.appraisals.length} //This is important field to render the next data
      next={loadMore}
      hasMore={state.hasMoreAppraisals}
      loader={<center>Loading more appraisals...</center>}
      scrollableTarget="contentContainer"
    >
      <div className="page-title">
        <Row>
          <Col sm="8">
            <i className="fa fa-reorder"></i>&nbsp;Appraisals
          </Col>
          <Col sm="4" className="align-right">
            <font style={{fontSize:'1rem'}}><NavLink to="/appraisal">Switch to personal pipeline</NavLink></font>
          </Col>
        </Row>
      </div>
      <ReportFilter {...props} configs={reportFiltersConfig}/>
      <div className="my-divider"></div>
      <Row className="low-line-height">
        <Col sm="6">
            <div>{formatNumber(state.totalCount)} orders in queue<br/><br/></div>
        </Col>
        <Col sm="6">
          <div className="align-right"><NavLink to={'/appraisal/batch-new'}><i className="fa fa-plus"></i>&nbsp;Batch Submission.</NavLink>&nbsp;&nbsp;&nbsp;<NavLink to={'/appraisal/new'}><i className="fa fa-plus"></i>&nbsp;Create new appraisal.</NavLink><br/></div>
        </Col>
      </Row>
      <i className="fa fa-laptop"/> Auto Assign&nbsp;&nbsp;<i className="fa fa-fire red-color"/> Hotfile&nbsp;&nbsp;<i className="fa fa-money green-color"/>Pending Receivable&nbsp;&nbsp;<i className="fa fa-flash flashit red-color"/> Late In Pipeline&nbsp;&nbsp;<i className="fa fa-exclamation-circle orange-color"/> Potential Complex
      <div>
        {pipelineStatusCounts}
      </div>
      {renderAppraisals()}
    </InfiniteScroll>
  </div>;
}


export default GlobalAppraisals;
