//Author Sooyoung Kim
//Date Aug 22, 2023
import React, {useEffect} from 'react';
import MySelect from '../util/my-select';
import {Col, Row} from 'reactstrap';

const Utility  = (props)=>{
  const controller = new AbortController();

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const updateState = (field, value) => {
    let utilities = props.utilities.slice();
    let target = utilities[props.index];

    target[field] = value;
    console.log(target);
    console.log(field);

    utilities[props.index] = target;

    props.updateState({utilities:utilities});
  }

  //render
  let disabledStyling = {border:'none', background:'#fff', padding:'0',paddingLeft:'2px', height:'auto',WebkitAppearance:'none'};

  return <div>
    <Row style={{marginTop:'15px'}}>
      <Col sm="4">
        <label><font color="red">*</font>{props.utilities[props.index].utilityType} Supplier</label>
        <MySelect
          type="select"
          style={props.disabled?disabledStyling:{}}
          disabled={props.disabled}
          required={true}
          value={props.utilities[props.index].utilitySupplier}
          onChange={(v)=>updateState('utilitySupplier',v)}
          options={[
            {label:"",value:""},
            {label:"Public",value:"Public"},
            {label:"Private",value:"Private"},
            {label:"Unknown",value:"Unknown"},
            {label:"None",value:"None"},
          ]}
        />
      </Col>
      <Col sm="4">
        <label>{props.utilities[props.index].utilitySupplier==='Private'?<font color="red">*</font>:null}{props.utilities[props.index].utilityType} Sub Type</label>
        <MySelect
          type="select"
          style={props.disabled?disabledStyling:{}}
          disabled={props.disabled}
          required={props.utilities[props.index].utilitySupplier==='Private'}
          value={props.utilities[props.index].utilitySubType}
          onChange={(v)=>updateState('utilitySubType',v)}
          options={[
            {label:"",value:""},
            {label:"Solar",value:"Solar"},
            {label:"Wind Turbine",value:"WindTurbine"},
            {label:"Generator",value:"Generator"},
            {label:"Hydro Electric",value:"HydroElectric"},
            {label:"Propane",value:"Propane"},
            {label:"Natural Gas",value:"NaturalGas"},
            {label:"Cistern",value:"Cistern"},
            {label:"Hauled Water",value:"HauledWater"},
            {label:"Well Private",value:"WellPrivate"},
            {label:"Well Shared",value:"WellShared"},
            {label:"Cess Pool",value:"CessPool"},
            {label:"Septic Private",value:"SepticPrivate"},
            {label:"Septic Shared",value:"SepticShared"},
            {label:"Unknown",value:"Unknown"},
          ]}
        />
      </Col>
    </Row>
  </div>;
}


export default Utility;
