//Author Sooyoung Kim
//Date Aug 22, 2023
import React, {useEffect} from 'react';
import {Col, Row, Input} from 'reactstrap';
import MySelect from '../util/my-select';

const Cooling  = (props)=>{
  const controller = new AbortController();

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const updateState = (field, value) => {
    let coolings = props.coolings.slice();
    let target = coolings[props.index];

    target[field] = value;

    coolings[props.index] = target;

    props.updateState({coolings:coolings});
  }

  //render
  let disabledStyling = {border:'none', background:'#fff', padding:'0',paddingLeft:'2px', height:'auto',WebkitAppearance:'none'};
  let coolingOtherDescription;

  if(props.coolings[props.index].coolingType==='Other')
    coolingOtherDescription = <Col sm="3">
      <label><font color="red">*</font>Cooling Other Description</label>
      <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={true} type="text" value={props.coolings[props.index].coolingOtherDescription} onChange={(e)=>{updateState('coolingOtherDescription',e.target.value)}}/>
    </Col>;

  let coolingUpdateDescription;

  if(props.coolings[props.index].coolingUpdates==='Partially Updated' || props.coolings[props.index].coolingUpdates==='Fully Updated')
    coolingUpdateDescription = <Col sm="3">
      <label><font color="red">*</font>{props.coolings[props.index].coolingType}  Update Description</label>
      <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={true} type="text" value={props.coolings[props.index].coolingUpdateDescription} onChange={(e)=>{updateState('coolingUpdateDescription',e.target.value)}}/>
    </Col>;
    console.log(props.coolings)
    
  return <div>
    <Row style={{marginTop:'15px'}}>
      {coolingOtherDescription}
      <Col sm="3">
        <label><font color="red">*</font>{props.coolings[props.index].coolingType} Updates</label>
        <MySelect
          type="select"
          style={props.disabled?disabledStyling:{}}
          disabled={props.disabled}
          required={true}
          value={props.coolings[props.index].coolingUpdates}
          onChange={(v)=>updateState('coolingUpdates',v)}
          options={[
            {label:"",value:""},
            {label:"Fully Updated",value:"Fully Updated"},
            {label:"Partially Updated",value:"Partially Updated"},
            {label:"No Update",value:"No Update"},
          ]}
        />
      </Col>
      {coolingUpdateDescription}
      <Col sm="3">
        <label><font color="red">*</font>{props.coolings[props.index].coolingType} Damage Defect</label>
        <MySelect
          type="select"
          style={props.disabled?disabledStyling:{}}
          disabled={props.disabled}
          required={true}
          value={props.coolings[props.index].coolingDamagesDefect}
          onChange={(v)=>updateState('coolingDamagesDefect',v)}
          options={[
            {label:"",value:""},
            {label:"Yes",value:"True"},
            {label:"No",value:"False"},
          ]}
        />
      </Col>
    </Row>
  </div>;
}


export default Cooling;
