//Sooyoung Kim
// Oct 13, 2023
import {getReducer, getSetStateFunction, postAPICallGenerator, deleteAPICallGenerator, formatNumber, formatNumberNoDecimal, formatDate} from '../../util/util';
import {Button, Row, Col, Input, FormGroup, Label, UncontrolledTooltip} from 'reactstrap';
import MyReactTable from '../util/my-react-table';
import {NavLink} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';
import ReactStars from 'react-stars';
import '../util/my-xeditable.css';
import '../dashboard/review-delivery.css';
import '../appraisal/appraisal.css';

//initialize the state
const initialState = {
  serviceRatingID:[],
  qualityRatingID:[],
  selectedAppraiserAutoBid:{},
  maxDistance:15,
};

//reducer function that perform state update
const reducer = getReducer();


const AssignmentTab  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const changeQualityScore = (ID) => {
    let newQualityRatingID = state.qualityRatingID.slice();
    let index = newQualityRatingID.indexOf(ID);

    if(index===-1 && ID !== -1){
      newQualityRatingID.push(ID);
    }else{
      newQualityRatingID.splice(index,1);
    }
    setState({qualityRatingID:newQualityRatingID});
  }

  const changeServiceScore = (ID) => {
    let newServiceRatingID = state.serviceRatingID.slice();
    let index = newServiceRatingID.indexOf(ID);

    if(index===-1 && ID !== -1){
      newServiceRatingID.push(ID);
    }else{
      newServiceRatingID.splice(index,1);
    }
    setState({serviceRatingID:newServiceRatingID});
  }

  //API call

  const addAppraiserToRelationship = (appraiser, autoAssign='no') => {
    let parameters = [
      {
        field:'brokerCompanyProfileFk',
        value:props.appraisal.client_fk
      },
      {
        field:'appraiserFk',
        value:appraiser
      },
      {
        field:'autoAssign',
        value:autoAssign
      }
    ];

    let callBack = (response)=>{
      let code= response.data.code;

      if(code==='00'){
        let appraisers = props.appraisers.slice();

        for(let i=0;i<appraisers.length;i++){
          if(appraisers[i].ID===appraiser){
            appraisers[i].broker_relationship.ID=response.data.data.ID;
            appraisers[i].broker_relationship.auto_assign=autoAssign;
            break;
          }
        }

        props.setState('appraisers', appraisers);
      }
    };
    callBack = callBack.bind(this);

    httpPost('brokerRelationship/appraiser/create', parameters, 'Broker profile associated successfully.', 'Oops, something went wrong and could not add the appraiser to the relationship. Please try again later.', callBack);
  }

  const deleteProfileID = (appraiserID, ID) => {
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;

      if(code==='00'){
        let appraisers = props.appraisers.slice();

        for(let i=0;i<appraisers.length;i++){
          if(appraisers[i].ID===appraiserID){
            appraisers[i].broker_relationship.ID = 0;
            appraisers[i].broker_relationship.auto_assign='';

            break;
          }
        }

        props.setState('appraisers', appraisers);
      }
    };
    callBack = callBack.bind(this);
    httpDelete('brokerRelationship/appraiser/'+ID,'Profile deleted.','Oops, something went wrong and could not delete the profile. Please try again later.', callBack);
  }

  //render
  const columns2 = [
    {
      id:'action',
      Header:'Action',
      minWidth:100,
      width:100,
      accessor: d => '',
      Cell: cell =>{
        let warning;
        let warning2;

        if(cell.row.original.warnings.length>0){
          warning = <div className="display-inline">
            <i className="fa fa-warning cursor-pointer red-color" id={"warning"+cell.row.original.ID}/>
              <UncontrolledTooltip delay={{ "show": 0, "hide": 0 }} placement="right" target={"warning"+cell.row.original.ID}>
                {
                  cell.row.original.warnings.map(
                    (warning, index2)=>{
                      return <div key={index2} style={{fontSize:'9px'}}>
                        {warning}<br/><br/>
                      </div>
                    }
                  )
                }
              </UncontrolledTooltip>
          </div>
        }

        if(cell.row.original.second_level_warnings.length>0){
          warning2 = <div className="display-inline">
            <i className="fa fa-warning cursor-pointer orange-color" id={"second_level_warning"+cell.row.original.ID}/>
              <UncontrolledTooltip delay={{ "show": 0, "hide": 0 }} placement="right" target={"second_level_warning"+cell.row.original.ID}>
                {
                  cell.row.original.second_level_warnings.map(
                    (warning, index2)=>{
                      return <div key={index2} style={{fontSize:'9px'}}>
                        {warning}<br/><br/>
                      </div>
                    }
                  )
                }
              </UncontrolledTooltip>
          </div>
        }

        let icon;

        if(cell.row.original.new_sign_up==='yes')
          icon = <div className="display-inline flashit green-color" style={{fontSize:'9px',minWidth:'7px'}}>
            &nbsp;NEW&nbsp;
          </div>

        let presidentClub;

        if(cell.row.original.president_club==='yes')
          presidentClub = <div className="display-inline" style={{minWidth:'7px'}}>&nbsp;<i className="fa fa-trophy orange-color"></i>&nbsp;</div>;

        let starIcon = <i className="fa fa-star cursor-pointer" style={{color:'gray'}} onClick={(e)=>{e.stopPropagation();addAppraiserToRelationship(cell.row.original.ID)}}/>;


        if(cell.row.original.broker_relationship.ID!==0){
          if(cell.row.original.broker_relationship.auto_assign==='no')
            starIcon = <i className="fa fa-star cursor-pointer" style={{color:'blue'}} onClick={(e)=>{e.stopPropagation();addAppraiserToRelationship(cell.row.original.ID, 'yes')}}/>;
          else if(cell.row.original.broker_relationship.auto_assign==='yes')
            starIcon = <i className="fa fa-star cursor-pointer" style={{color:'green'}} onClick={(e)=>{e.stopPropagation();deleteProfileID(cell.row.original.ID, cell.row.original.broker_relationship.ID)}}/>;
        }

        // let button = cell.row.getIsPinned() ? (
        //   <i className="fa fa-times cursor-pointer red-color"  onClick={() => cell.row.pin(false)}></i>
        // ) : (
        //   <Button onClick={() =>cell.row.pin('top')} style={{ fontSize: '10px', width: '45px', height: '30px', backgroundColor: '#5dadfc', border: 'none'}}>
        //     Bid
        //   </Button>
        // )

        let bidID = -1;

        if(!cell.row.getIsPinned()&&cell.row.original.quoted){
          //cell.row.pin('top');
        }
 
        if(cell.row.original.bid_list&&cell.row.original.bid_list!==''&&cell.row.original.bid_list[cell.row.original.bid_list.length-1].fee!==''){
          bidID = cell.row.original.bid_list[cell.row.original.bid_list.length-1].ID;
        }
        

        return <div>
          <div className="display-inline" style={{verticalAlign:'top'}}>
            <FormGroup check>
              <Label check>
                <Input type="checkbox" onChange={(e) => {
                  if(props.isCheck(cell.row.original.ID)&&cell.row.getIsPinned()){
                    cell.row.pin(false)
                  }else{
                    cell.row.pin('top');
                  }
                  
                  props.clickRowListener(cell.row.original.ID,  {fees:cell.row.original.fees, warnings:cell.row.original.warnings, second_level_warnings: cell.row.original.second_level_warnings, distance: cell.row.original.distance, first_name: cell.row.original.first_name, last_name:cell.row.original.last_name, email:cell.row.original.email, bid:bidID})
                  }} value={cell.row.original.ID} checked={props.isCheck(cell.row.original.ID)}/>
              </Label>
            </FormGroup>
          </div>
          <div className="display-inline" style={{minWidth:'20px'}}>
              {icon}{presidentClub}<br/>{warning}&nbsp;{warning2}&nbsp;{starIcon}
          </div>
        </div>
      }
    },
    {
      id: 'appraiser',
      Header: 'Appraiser',
      minWidth:300,
      Cell: cell=>{
        let phoneCallInfo;
        let officePhone;
        let cellPhone;

        if(cell.row.original.phone&&cell.row.original.phone!==''){
          officePhone = <div>
            Office: <a href={"tel:"+cell.row.original.phone} onClick={(e)=>e.stopPropagation()}>{cell.row.original.phone}</a> <div className="display-inline" style={{fontSize:'9px'}}>
              <a onClick={(e)=>{e.stopPropagation();props.recordBid('phone',cell.row.original.first_name+' '+cell.row.original.last_name, '','','','', cell.row.original.ID,'',cell.row.original.phone)}} href={"tel:"+cell.row.original.phone}><i className="fa fa-phone"/><i>Bid</i></a>
            </div>
          </div>;
        }

        if(cell.row.original.secondary_phone&&cell.row.original.secondary_phone!==''){
          cellPhone = <div>
            Cell: <a href={"tel:"+cell.row.original.secondary_phone} onClick={(e)=>e.stopPropagation()}>{cell.row.original.secondary_phone}</a> <div className="display-inline" style={{fontSize:'9px'}}>
              <a onClick={(e)=>{e.stopPropagation();props.recordBid('phone',cell.row.original.first_name+' '+cell.row.original.last_name, '','','','', cell.row.original.ID,'',cell.row.original.secondary_phone)}} href={"tel:"+cell.row.original.secondary_phone}><i className="fa fa-phone"/><i>Bid</i></a>
            </div>
          </div>;
        }

        phoneCallInfo = <div>
          {officePhone}
          {cellPhone}
        </div>;

        let appraiserMemo = '';
        if(cell.row.original.ID!=='0' && cell.row.original.memo_list!==''){
            appraiserMemo = <UncontrolledTooltip className="mytooltip" delay={{ "show": 0, "hide": 0 }} placement="right" target={"memo"+cell.row.original.ID}>
                              {cell.row.original.memo_list.map(
                                (memo, index)=>{
                                  return <div key={index}>{memo.memo}<br/></div>
                                }
                              )}
                            </UncontrolledTooltip>
        }

        return <div>
          <div>
            <i onClick={(e)=>{e.stopPropagation();props.getAppraiserPipeline(cell.row.original.ID);props.toggleAppraiserPipeline();}} className="fa fa-drivers-license-o cursor-pointer link-color"></i>&nbsp;
            <NavLink id={"memo"+cell.row.original.ID} to={"/appraiser/"+cell.row.original.ID} target="_blank" onClick={(e)=>e.stopPropagation()}>{cell.row.original.first_name+' '+cell.row.original.last_name}</NavLink>
          </div>
          ({cell.row.original.license_type})
          {phoneCallInfo}
          {appraiserMemo}
        </div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'bid',
      Header: 'Bid',
      minWidth:100,
      width:100,
      maxWidth:100,
      accessor: d => d.bid_list[d.bid_list.length-1].fee,
      Cell: props =>{
        let bidInfo = <div>-</div>;

        if(props.row.original.bid_list&&props.row.original.bid_list!==''&&props.row.original.bid_list[props.row.original.bid_list.length-1].fee!==''){
          let style = {cursor: 'pointer', border: '3px solid #89b1ff', borderRadius:'5px', padding: '8px', textAlign: 'center'};
          if(props.row.getIsExpanded()){
            style.border = '4px solid #3679ff';
          }

          bidInfo = <div>
            <div className="display-inline" id={"bid"+props.row.original.ID}>${formatNumber(props.row.original.bid_list[props.row.original.bid_list.length-1].fee)}</div>
            <UncontrolledTooltip delay={{ "show": 0, "hide": 0 }} placement="right" target={"bid"+props.row.original.ID}>
              turnaround: {formatDate(props.row.original.bid_list[props.row.original.bid_list.length-1].turnaround)}
            </UncontrolledTooltip>
          </div>;
        }

        return <div>{bidInfo}</div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'distance',
      Header: 'Distance',
      minWidth:150,
      width:150,
      maxWidth:150,
      accessor: d => d.distance,
      Cell: props => {
        return <div>{formatNumber(props.row.original.distance)} miles </div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'address',
      Header: 'Address',
      accessor: d => d.street+' '+d.city+', '+d.state,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'fee',
      Header: 'Set Fee',
      minWidth:100,
      width:100,
      maxWidth:100,
      accessor: d => d.fee,
      Cell: props =>{
        let totalFee = 0;
        for(let i=0;i<props.row.original.fees.length;i++){
          totalFee = totalFee + parseInt(props.row.original.fees[i].fee,10);
        }

        return <div>
          <div className="display-inline" id={"fee"+props.row.original.ID}>${formatNumber(totalFee)}</div>
          <UncontrolledTooltip delay={{ "show": 0, "hide": 0 }} placement="right" target={"fee"+props.row.original.ID}>
            {
              props.row.original.fees.map(
                (fee,index2)=>{
                  return <div key={index2}>{fee.appraisal_type} - ${formatNumber(fee.fee)}</div>
                }
              )
            }
          </UncontrolledTooltip>
        </div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'avgFee',
      Header: 'Avg Fee',
      minWidth:100,
      width:100,
      maxWidth:150,
      accessor: d => d.avg_fee,
      Cell: props =>{
        let fee = <div>-</div>;

        if(props.row.original.avg_fee!==-1)
          fee = <div>
            $ {formatNumber(props.row.original.avg_fee)}
          </div>;
        return <div>
          {fee}
        </div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'orders',
      Header: 'Orders',
      minWidth:250,
      width:250,
      maxWidth:250,
      accessor: d => d.orders_count,
      Cell: props =>{

        let onHand = <UncontrolledTooltip className="mytooltip" delay={{ "show": 0, "hide": 0 }} placement="top" target={"orders-on-hand"+props.row.original.ID}>Number of orders on hand</UncontrolledTooltip>
        let nearby = <UncontrolledTooltip className="mytooltip" delay={{ "show": 0, "hide": 0 }} placement="top" target={"nearby-order"+props.row.original.ID}>Number of orders completed nearby</UncontrolledTooltip>
        let lifetime = <UncontrolledTooltip className="mytooltip" delay={{ "show": 0, "hide": 0 }} placement="top" target={"lifetime-order"+props.row.original.ID}>Number of orders in lifetime</UncontrolledTooltip>

        return <div>
          <div>
            <i id={"orders-on-hand"+props.row.original.ID} className="fa fa-hand-paper-o cursor-pointer"/>  <b>{props.row.original.orders_count}</b>{onHand} &nbsp; 
            <i id={"nearby-order"+props.row.original.ID} className="fa fa-street-view cursor-pointer"/>  <b>{props.row.original.orders_in_area_count}</b>{nearby} &nbsp; 
            <i id={"lifetime-order"+props.row.original.ID} className="fa fa-clock-o cursor-pointer"/>  <b>{formatNumberNoDecimal(props.row.original.lifetime_order)}</b>{lifetime}
          </div>
          <b>{formatNumber(props.row.original.on_time_percent)}%</b> out of {props.row.original.total_order} orders on time<br/>
        </div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'serviceRating',
      Header: 'Service Rating',
      minWidth:130,
      width:130,
      maxWidth:130,
      accessor: d=>d.avg_service_rating,
      Cell: props => {
        let sixAvg = <div>-</div>;

        if(props.row.original.avg_service_rating&&parseFloat(props.row.original.avg_service_rating)>=0)
          sixAvg=  <div>{parseFloat(props.row.original.avg_service_rating).toFixed(2)}/5.00</div>

        let sixMonthsServiceRating = <div className='margin-top'><center>{sixAvg}<div style={{fontSize: '10px'}}><NavLink to="#" onClick={(e)=>changeServiceScore(props.row.original.ID)}>View Lifetime</NavLink></div></center></div>;

        let lifetimeAvg = <div>-</div>;

        if(props.row.original.sr_avg_score&&parseFloat(props.row.original.sr_avg_score)>=0)
          lifetimeAvg =  <div>{parseFloat(props.row.original.sr_avg_score).toFixed(2)}/5.00</div>

        let lifetimeServiceRating = <div className='margin-top'><center><div><font color="red">{lifetimeAvg}</font></div><div style={{fontSize: '10px'}}><NavLink to="#" onClick={(e)=>changeServiceScore(props.row.original.ID)}>View 6 Months</NavLink></div></center></div>;

        return(
          <div>
            {state.serviceRatingID.indexOf(props.row.original.ID)===-1 && sixMonthsServiceRating}
            {state.serviceRatingID.indexOf(props.row.original.ID)!==-1 && lifetimeServiceRating}
          </div>
        )
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'qualityRating',
      Header: 'Quality Rating',
      minWidth:130,
      width:130,
      maxWidth:130,
      accessor: d=>d.avg_quality_rating,
      Cell: props => {
        let sixAvg = '-';

        if(props.row.original.avg_quality_rating&&parseFloat(props.row.original.avg_quality_rating)>=0)
          sixAvg = <div className="display-inline">
                      <div id={"score"+props.row.original.ID} className="display-inline">
                        <ReactStars
                            onChange={()=>{}}
                            value={parseInt(props.row.original.avg_quality_rating,10)}
                            count={3}
                            edit={false}
                            half={true}
                            size={20}
                          />
                      </div>
                        <UncontrolledTooltip delay={{ "show": 0, "hide": 0 }} placement="top" target={"score"+props.row.original.ID}>
                          {parseFloat(props.row.original.avg_quality_rating).toFixed(2)}/3.00
                        </UncontrolledTooltip>
                  </div>;

        let sixMonthsQualityRating = <div><center><div>{sixAvg}</div><div style={{fontSize: '10px'}}><NavLink to="#" onClick={(e)=>changeQualityScore(props.row.original.ID)}>View Lifetime</NavLink></div></center></div>;

        let lifetimeAvg = '-';

        if(props.row.original.qr_avg_score&&parseFloat(props.row.original.qr_avg_score)>=0)
          lifetimeAvg = <div className="display-inline">
                      <div id={"lf_score"+props.row.original.ID} className="display-inline">
                        <ReactStars
                            onChange={()=>{}}
                            value={parseInt(props.row.original.qr_avg_score,10)}
                            count={3}
                            color2={'#fd7f00'}
                            edit={false}
                            half={true}
                            size={20}
                          />
                      </div>
                        <UncontrolledTooltip delay={{ "show": 0, "hide": 0 }} placement="top" target={"lf_score"+props.row.original.ID}>
                          {parseFloat(props.row.original.qr_avg_score).toFixed(2)}/3.00
                        </UncontrolledTooltip>
                  </div>;

        let lifetimeQualityRating = <div><center><div>{lifetimeAvg}</div><div style={{fontSize: '10px'}}><NavLink to="#" onClick={(e)=>changeQualityScore(props.row.original.ID)}>View 6 Months</NavLink></div></center></div>;

        return(
          <div>
            {state.qualityRatingID.indexOf(props.row.original.ID)===-1 && sixMonthsQualityRating}
            {state.qualityRatingID.indexOf(props.row.original.ID)!==-1 && lifetimeQualityRating}
          </div>
        )
      },
      headerStyle: {
        textAlign:'left'
      }
    }
  ];


  let appraisers;

  if(props.appraisers&&props.appraisers.length>0){
    appraisers = <MyReactTable columns={columns2} data={props.appraisers} className="table" container={{maxHeight:'600px', overflow: 'auto'}}/>;
  }

  return <div>
    <Row>
      <Col sm="3">
        <label>County / City</label>
        <Input type="text" placeholder="Search by county" value={props.county} onChange={(e)=>{props.setState('county',e.target.value)}}/>
      </Col>
      <Col sm="3">
        <label>Radius (Miles)</label>
        <Input type="text" placeholder="Search by radius (miles)" value={props.searchRadius} onChange={(e)=>{props.setState('searchRadius',e.target.value)}}/>
      </Col>
      <Col sm="3">
        <label>Keyword </label>
        <Input type="text" placeholder="Search by keyword - First name, last name or address (optional)" value={props.searchKeyword} onChange={(e)=>props.setState('searchKeyword',e.target.value)}/>
      </Col>
      <Col sm="3" className="align-right">
        <div className="ex-margin-bottom">&nbsp;</div>
        <Button color="warning" onClick={props.getAppraisers}>Search</Button>
      </Col>
    </Row>
    <br/>
    <i className="fa fa-star cursor-pointer" style={{color:'gray'}}/> Not in client relationship list&nbsp;&nbsp;&nbsp;<i className="fa fa-star cursor-pointer" style={{color:'blue'}}/> In client relationship list&nbsp;&nbsp;&nbsp;<i className="fa fa-star cursor-pointer" style={{color:'green'}}/> Auto assign enabled&nbsp;&nbsp;&nbsp;
    <i className="fa fa-square" style={{color:'#deeffd'}}/> Bid Requested&nbsp;&nbsp;&nbsp;
    <i className="fa fa-square" style={{color:'#e1fde4'}}/> Quote Vetted
    {appraisers}
  </div>;
}


export default AssignmentTab;
