//Author Sooyoung Kim
//Date Aug 30, 2023
import {getReducer, getSetStateFunction} from '../../util/util';
import {Label, FormGroup, Card, CardHeader, CardBody, Col, Row, Input} from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import React, {useReducer, useEffect} from 'react';
import MyPlacesAutocomplete from '../util/my-places-autocomplete';
import { geocodeByAddress } from 'react-places-autocomplete';
import MySelect from '../util/my-select';
import Signature from './signature';

//initialize the state
const initialState = {
  address:'',
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
  showSupervisor:false
};

//reducer function that perform state update
const reducer = getReducer();


const StepDataCollectorCertification  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const googlePlaceOnChange = (address) => {
    if(address!==state.address){

      if(address.indexOf(',')!==-1 && address.length>10){
        geocodeByAddress(address)
          .then(
            results => {
              //always use the first returned result
              let targetResult = results[0];

              let addressComponents = targetResult.address_components;

              let streetNumber = '';
              let route = '';
              let city = '';

              let stateName = '';
              let zip = '';

              //go through the address components and grab the street, city, county, state, and zip
              for(let i=0;i<addressComponents.length;i++){
                if(addressComponents[i].types[0]==='street_number')
                  streetNumber = addressComponents[i].long_name;
                else if(addressComponents[i].types[0]==='route')
                  route = addressComponents[i].long_name;
                else if(addressComponents[i].types[0]==='locality'&&addressComponents[i].types[1]==='political')
                  city = addressComponents[i].long_name;

                else if(addressComponents[i].types[0]==='administrative_area_level_1'&&addressComponents[i].types[1]==='political')
                  stateName = addressComponents[i].long_name;
                else if(addressComponents[i].types[0]==='postal_code')
                  zip = addressComponents[i].long_name;
              }


              let street = '';

              if(streetNumber!==''&&route!=='')
                street = streetNumber+' '+route;

              let stateToUpdate = {};

              if(street!==''){
                stateToUpdate.dataCollectorCompanyStreet = street;
                setState({address:street});
              }
              if(city!=='')
                stateToUpdate.dataCollectorCompanyCity = city;
              if(stateName!=='')
                stateToUpdate.dataCollectorCompanyState = stateName;
              if(zip!=='')
                stateToUpdate.dataCollectorCompanyZip = zip;
              console.log(stateToUpdate);
              props.updateState(stateToUpdate);
            })
          .catch(
            error => {
              console.log(error);
            }
          );
      }
      else{
        setState({address:address});
        props.updateState({dataCollectorCompanyStreet:address});
      }
    }
  }


  //render
  let disabledStyling = {border:'none', background:'#fff', padding:'0',paddingLeft:'2px', height:'auto',WebkitAppearance:'none'};
  let checkbox;
  let supervisorCheckbox;

  if(!props.disabled){
    supervisorCheckbox = <div className="display-inline" style={{verticalAlign:'top'}}>
      <FormGroup check>
        <Label check>
          <Input required={props.supervisorName!==''} type="checkbox" value={props.supervisorCertified} onChange={(e)=>props.updateState({supervisorCertified:!state.supervisorCertified})}/>
        </Label>
      </FormGroup>
    </div>;

    checkbox = <div className="display-inline" style={{verticalAlign:'top'}}>
      <FormGroup check>
        <Label check>
          <Input required={true} type="checkbox" value={props.dataCollectorCertified} onChange={(e)=>props.updateState({dataCollectorCertified:!state.dataCollectorCertified})}/>
        </Label>
      </FormGroup>
    </div>;
  }
  let signature = <div style={{width:'100%',minHeight:'200px',maxHeight:'200px',background:'white'}}>&nbsp;</div>;
  let supervisorSignature = <div style={{width:'100%',minHeight:'200px',maxHeight:'200px',background:'white'}}>&nbsp;</div>;

  if(props.disabled){
    if(props.signature.draw && props.signature.draw!==''){
      signature = <img alt="pic" width="100%" height="200" src={'data:image/png;base64, '+props.signature.draw}/>
    }
    else if(props.signature.upload && props.signature.upload!==''){
      signature = <img alt="pic" width="100%" height="200" src={'data:image/png;base64, '+props.signature.upload}/>
    }

    if(props.supervisorSignature.draw && props.supervisorSignature.draw!==''){
      supervisorSignature = <img alt="pic" width="100%" height="200" src={'data:image/png;base64, '+props.supervisorSignature.draw}/>
    }
    else if(props.supervisorSignature.upload && props.supervisorSignature.upload!==''){
      supervisorSignature = <img alt="pic" width="100%" height="200" src={'data:image/png;base64, '+props.supervisorSignature.upload}/>
    }
  }
  else{
    console.log(props.signature);
    console.log(props.supervisorSignature);
    signature = <Signature update={(type, base64)=>props.updateState({signature:Object.assign({}, props.signature, {[type]:base64})})}/>;
    supervisorSignature = <Signature update={(type, base64)=>props.updateState({supervisorSignature:Object.assign({}, props.supervisorSignature, {[type]:base64})})}/>;
  }
  let professionalLicenseExpDate;
  if(props.professionalLicenseExpDate!==''&&props.professionalLicenseExpDate!=='0000-00-00')
    professionalLicenseExpDate = moment(props.professionalLicenseExpDate).toDate();

  let dateOfObservation;
  if(props.dateOfObservation!==''&&props.dateOfObservation!=='0000-00-00')
    dateOfObservation = moment(props.dateOfObservation).toDate();

  let supervisorLicenseExpDate;
  if(props.supervisorLicenseExpDate!==''&&props.supervisorLicenseExpDate!=='0000-00-00')
    supervisorLicenseExpDate = moment(props.supervisorLicenseExpDate).toDate();

  let supervisorDateOfSignature;
  if(props.supervisorDateOfSignature!==''&&props.supervisorDateOfSignature!=='0000-00-00')
    supervisorDateOfSignature = moment(props.supervisorDateOfSignature).toDate();


  let dateOfSignatureReport;
  if(props.dateOfSignatureReport!==''&&props.dateOfSignatureReport!=='0000-00-00')
    dateOfSignatureReport = moment(props.dateOfSignatureReport).toDate();

  let supervisorDetails;

  if(state.showSupervisor){
    supervisorDetails = <div>
      <Card>
        <CardHeader>
          Supervisor Certification
        </CardHeader>
        <CardBody>
          <Row style={{marginTop:'15px'}}>
            <Col sm="4">
              <label>Supervisor Name</label>
              <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} type="text" value={props.supervisorName} onChange={(e)=>props.updateState({supervisorName:e.target.value})}/>
            </Col>
          </Row>
          <Row style={{marginTop:'15px'}}>
            <Col sm="3">
              <label>Supervisor Contact Method</label>
              <MySelect
                type="select"
                style={props.disabled?disabledStyling:{}}
                disabled={props.disabled}
                value={props.supervisorContactMethod}
                onChange={(v)=>props.updateState({supervisorContactMethod:v})}
                options={[
                  {label:"",value:""},
                  {label:"Phone",value:"Phone"},
                  {label:"Fax",value:"Fax"},
                  {label:"Email",value:"Email"},
                  {label:"Other",value:"Other"},
                ]}
              />
            </Col>
            <Col sm="3">
              <label>Supervisor Contact</label>
              <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} type="text" value={props.supervisorContact} onChange={(e)=>props.updateState({supervisorContact:e.target.value})}/>
            </Col>
          </Row>
          <Row style={{marginTop:'15px'}}>
            <Col sm="4">
              <label>Supervisor License Number</label>
              <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} type="text" value={props.supervisorLicenseNumber} onChange={(e)=>props.updateState({supervisorLicenseNumber:e.target.value})}/>
            </Col>
            <Col sm="4">
              <label>Supervisor License State</label>
              <MySelect
                type="select"
                style={props.disabled?disabledStyling:{}}
                disabled={props.disabled}
                value={props.supervisorState}
                onChange={(v)=>props.updateState({supervisorState:v})}
                options={state.states.map((stateName)=>{
                  return {label:stateName.value, value:stateName.value};
                })}
              />
            </Col>
            <Col sm="4">
              <label>Supervisor License Exp Date</label><br/>
              <DatePicker style={props.disabled?disabledStyling:{}} disabled={props.disabled}
                className="form-control"
                dateFormat="yyyy/MM/dd"
                selected={supervisorLicenseExpDate}
                onChange={(text)=>{(text)&&props.updateState({supervisorLicenseExpDate:text.toLocaleDateString('en-CA')})}}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <br/>
      <Card>
        <CardHeader>
          Supervisor Signature
        </CardHeader>
        <CardBody>
          <Row style={{marginTop:'15px'}}>
            <Col sm="4">
              <label>Date of Signature</label><br/>
              <DatePicker style={props.disabled?disabledStyling:{}} disabled={props.disabled}
                className="form-control"
                dateFormat="yyyy/MM/dd"
                selected={supervisorDateOfSignature}
                onChange={(text)=>{(text)&&props.updateState({supervisorDateOfSignature:text.toLocaleDateString('en-CA')})}}
              />
            </Col>
            <Col sm="8">
              <label>Did or did not personally observe interior and exterior of subject property</label>
              <MySelect
                type="select"
                style={props.disabled?disabledStyling:{}}
                disabled={props.disabled}
                value={props.observeBothInAndExt}
                onChange={(v)=>props.updateState({observeBothInAndExt:v})}
                options={[
                  {label:"",value:""},
                  {label:"Yes",value:"True"},
                  {label:"No",value:"False"},
                ]}
              />
            </Col>
          </Row>
          <br/>
          <label>Supervisor Signature</label>
          <br/>
          {supervisorSignature}
          <br/>
          {supervisorCheckbox}
          <div className="display-inline" style={{verticalAlign:'top', fontSize:'12px'}}>
            The Supervisory Appraiser certifies and agrees that:
          </div>
          <br/>
          <br/>
          <div className="medium-scroll-container" style={{fontSize:'12px'}}>
            1.I directly supervised the appraiser who acted as the data collectorfor this assignment, have read the report, and agree with the data collector's statements, conclusions, and the data collector's certification<br/>
            2.I accept full responsibility for the contents of this report including, but not limited to, the data collector's statementsandconclusionsand the data collector's certification<br/>
            3.The data collector identified in this report is either a sub-contractor or an employee of the supervisory appraiser (or the appraisal firm), is qualified to perform this assignment, and is acceptable to perform this report under the applicable state law<br/>
            4.If this report was transmitted as an "electronic record" containing my "electronic signature,"as those terms are defined in applicable federal and/or state laws (excluding audio and video recordings), or a facsimile transmission of this report containing a copy or representation of my signature, the report shall be as effective, enforceable and valid as if a paper version of this report were delivered containing my original hand-written signature
          </div>
          <br/>
        </CardBody>
      </Card>
    </div>
  }


  return <div>
    <Card>
      <CardHeader className="header-color">
        Data Collector Information
      </CardHeader>
      <CardBody>
        <Row style={{marginTop:'15px'}}>
          <Col sm="3">
            <label><font color="red">*</font>Collection Type</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={true}
              value={props.collectionType}
              onChange={(v)=>props.updateState({collectionType:v})}
              options={[
                {label:"",value:""},
                {label:"On Site",value:"OnSite"},
                {label:"Virtual",value:"Virtual"},
              ]}
            />
          </Col>
          <Col sm="3">
            <label>{props.collectionType==='Virtual'?<font color="red">*</font>:null}Person at Subject Property</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={props.collectionType==='Virtual'}
              value={props.personAtProperty}
              onChange={(v)=>{props.updateState({personAtProperty:v}); if(v==='AppraiserTrainee')setState({showSupervisor:true})}}
              options={[
                {label:"",value:""},
                {label:"Real Estate Agent",value:"Real Estate Agent"},
                {label:"Licensed Home Inspector",value:"LicensedHomeInspector"},
                {label:"Appraiser",value:"Appraiser"},
                {label:"Appraiser Trainee",value:"AppraiserTrainee"},
                {label:"Home Owner",value:"Homeowner"},
                {label:"Other",value:"Other"},
              ]}
            />
          </Col>
          <Col sm="3">
            <label><font color="red">*</font>Person who completed property data report</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={true}
              value={props.dataCollectorType}
              onChange={(v)=>props.updateState({dataCollectorType:v})}
              options={[
                {label:"",value:""},
                {label:"Real Estate Agent",value:"Real Estate Agent"},
                {label:"Licensed Home Inspector",value:"LicensedHomeInspector"},
                {label:"Appraiser",value:"Appraiser"},
                {label:"Appraiser Trainee",value:"AppraiserTrainee"},
                {label:"Other",value:"Other"},
              ]}
            />
          </Col>
          <Col sm="3">
            <label><font color="red">*</font>Data Collector Name</label>
            <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={true} type="text" value={props.dataCollectorName} onChange={(e)=>props.updateState({dataCollectorName:e.target.value})}/>
          </Col>
        </Row>
        <Row style={{marginTop:'15px'}}>
          <Col sm="3">
            <label><font color="red">*</font>Data Collector Contact Method</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={true}
              value={props.dataCollectorContactMethod}
              onChange={(v)=>props.updateState({dataCollectorContactMethod:v})}
              options={[
                {label:"",value:""},
                {label:"Phone",value:"Phone"},
                {label:"Fax",value:"Fax"},
                {label:"Email",value:"Email"},
                {label:"Other",value:"Other"},
              ]}
            />
          </Col>
          <Col sm="3">
            <label><font color="red">*</font>Data Collector Contact</label>
            <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={true} type="text" value={props.dataCollectorContact} onChange={(e)=>props.updateState({dataCollectorContact:e.target.value})}/>
          </Col>
        </Row>
        <Row style={{marginTop:'15px'}}>
          <Col sm="3">
            <label>{(props.dataCollectorType==='Appraiser'|| props.dataCollectorType==='Real Estate Agent')?<font color="red">*</font>:null}Professional License Type</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={(props.dataCollectorType==='Appraiser'|| props.dataCollectorType==='Real Estate Agent')}
              value={props.professionalLicenseType}
              onChange={(v)=>props.updateState({professionalLicenseType:v})}
              options={[
                {label:"",value:""},
                {label:"Certificate",value:"Certificate"},
                {label:"License",value:"Licensed"},
                {label:"General",value:"General"},
              ]}
            />
          </Col>
          <Col sm="3">
            <label>{(props.dataCollectorType==='Appraiser'|| props.dataCollectorType==='Real Estate Agent')?<font color="red">*</font>:null}Professional License Number</label>
            <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={(props.dataCollectorType==='Appraiser'|| props.dataCollectorType==='Real Estate Agent')} type="text" value={props.professionalLicenseNumber} onChange={(e)=>props.updateState({professionalLicenseNumber:e.target.value})}/>
          </Col>
          <Col sm="3">
            <label>{(props.dataCollectorType==='Appraiser'|| props.dataCollectorType==='Real Estate Agent')?<font color="red">*</font>:null}Professional License State</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={(props.dataCollectorType==='Appraiser'|| props.dataCollectorType==='Real Estate Agent')}
              value={props.professionalLicenseState}
              onChange={(v)=>props.updateState({professionalLicenseState:v})}
              options={state.states.map((stateName)=>{
                return {label:stateName.value, value:stateName.value};
              })}
            />
          </Col>
          <Col sm="3">
            <label>{(props.dataCollectorType==='Appraiser'|| props.dataCollectorType==='Real Estate Agent')?<font color="red">*</font>:null}Professional License Exp Date</label>
            <DatePicker style={props.disabled?disabledStyling:{}} disabled={props.disabled}
              required={(props.dataCollectorType==='Appraiser'|| props.dataCollectorType==='Real Estate Agent')}
              className="form-control"
              dateFormat="yyyy/MM/dd"
              selected={professionalLicenseExpDate}
              onChange={(text)=>{(text)&&props.updateState({professionalLicenseExpDate:text.toLocaleDateString('en-CA')})}}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
    <br/>
    <Card>
      <CardHeader className="header-color">
        Data Collector Company Info
      </CardHeader>
      <CardBody>
        <Row style={{marginTop:'15px'}}>
          <Col sm="4">
            <label><font color="red">*</font>Data Collector Company</label>
            <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={true} type="text" value={props.dataCollectorCompany} onChange={(e)=>props.updateState({dataCollectorCompany:e.target.value})}/>
          </Col>
        </Row>
        <Row style={{marginTop:'15px'}}>
          <Col sm="4">
            <label><font color="red">*</font>Street</label>
            <MyPlacesAutocomplete value={props.dataCollectorCompanyStreet} onChange={googlePlaceOnChange} onSelect={googlePlaceOnChange}/>
          </Col>
          <Col sm="3">
            <label><font color="red">*</font>City</label>
            <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={true} type="text" value={props.dataCollectorCompanyCity} onChange={(e)=>props.updateState({dataCollectorCompanyCity:e.target.value})}/>
          </Col>
          <Col sm="3">
            <label><font color="red">*</font>State</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={(props.dataCollectorType==='Appraiser'|| props.dataCollectorType==='Real Estate Agent')}
              value={props.dataCollectorCompanyState}
              onChange={(v)=>props.updateState({dataCollectorCompanyState:v})}
              options={state.states.map((stateName)=>{
                return {label:stateName.value, value:stateName.value};
              })}
            />
          </Col>
          <Col sm="2">
            <label>Zip</label>
            <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={true} type="text" value={props.dataCollectorCompanyZip} onChange={(e)=>props.updateState({dataCollectorCompanyZip:e.target.value})}/>
          </Col>
        </Row>
      </CardBody>
    </Card>

    <br/>
    <Card>
      <CardHeader className="header-color">
        Data Collector Signature
      </CardHeader>
      <CardBody>
        <Row style={{marginTop:'15px'}}>
          <Col sm="4">
            <label><font color="red">*</font>Date of Observation</label><br/>
            <DatePicker style={props.disabled?disabledStyling:{}} disabled={props.disabled}
              className="form-control"
              dateFormat="yyyy/MM/dd"
              selected={dateOfObservation}
              onChange={(text)=>{(text)&&props.updateState({dateOfObservation:text.toLocaleDateString('en-CA')})}}
            />
          </Col>
          <Col sm="4">
            <label><font color="red">*</font>Date of signature & report</label><br/>
            <DatePicker style={props.disabled?disabledStyling:{}} disabled={props.disabled}
              className="form-control"
              dateFormat="yyyy/MM/dd"
              selected={dateOfSignatureReport}
              onChange={(text)=>{(text)&&props.updateState({dateOfSignatureReport:text.toLocaleDateString('en-CA')})}}
            />
          </Col>
        </Row>
        <br/>
        <label><font color="red">*</font>Data Collector Signature</label>
        <br/>
        {signature}
        <br/>
        {checkbox}
        <div className="display-inline" style={{verticalAlign:'top', fontSize:'12px'}}>
          <b>STATEMENT OF LIMITING CONDITIONS</b>
          <br/>CONTINGENT AND LIMITING CONDITIONS: The data collector\'s certification that appears in this report is subject to the following conditions:
        </div>
        <div style={{fontSize:'12px'}}>
          1. The data collector will not be responsible for matters of a legal nature that affect either the subject property or the title to it<br/>
          2. The data collector will not give testimony or appear in court because of this assignment unless specific arrangements to do so have been made beforehand<br/>
          3. The data collector has noted in the report any observed adverse conditions (such as, but not limited to, needed repairs, the presence of hazardous wastes, toxic substances, pest infestation, structural problems, mechanical problems, or habitability issues) and makes no guarantees or warranties, expressed or implied, regarding any unobserved conditions. Because the data collector is not an expert in the field of environmental hazards, pests, structural integrity or mechanical systems, this report must not be considered as an environmental assessment, pest inspection, or engineering report.<br/>
          4. The data collector obtained the information and estimates that were expressed in this report from sources that he or she considers to be reliable and believes them to be true and correct. The data collector does not assume responsibility for the accuracy of such items furnished by other parties.<br/>
          5. The data collector will not disclose the contents of this report to any individual or party other than the client or intended user, except as required by law or as directed by the client<br/>
        </div>
        <br/>
        <div className="display-inline" style={{verticalAlign:'top', fontSize:'12px'}}>
          <b>DATA COLLECTOR\'S CERTIFICATION</b><br/>I certify that, to the best of my knowledge and belief:
        </div>
        <br/><br/>
        <div className="large-scroll-container" style={{fontSize:'12px'}}>
          1.The statements of fact contained in this report are true and correct<br/>
          2.In the development of this report I have obtained adequate information to support my unbiased professional conclusions<br/>
          3.Any intentional or negligent misrepresentation(s) contained in this report may result in civil liability and/or criminal penalties including, but not limited to fineor imprisonment or both under the provisions of Title 18, United States Code, Section 1001, et seq., or similar state laws<br/>
          4.When the data collection was completed onsite as designated under data collection type-I have performed a complete visual data collection of the interior and exterior areas of the subject property. I reported the condition of the improvements in factual, specific terms, I identified and reported physical deficiencies that could affect the livability, soundness or structural integrity of the property.When the data collection was completed virtually as designated under data collection type-In completion of this assignment I have utilized video technology sufficient to perform a complete and adequate visual data collection of the interior and exterior areas of the subject property. I reported the condition of the improvements in factual, specific terms, identified and reported the physical deficiencies that could affect the livability, soundness or structural integrity of the property.<br/>
          5.My compensation is not contingent on an action or event resulting from the conclusionsor use of this report<br/>
          6.The information used as the basis of the conclusions within this report is adequate and deemed reliable by the data collector<br/>
          7.I have adequate knowledge,trainingand competencyto complete this assignment<br/>
          8.I have not withheld any information relevant to this assignment<br/>
          9.I have no present or prospective interest in the property that is the subject of this report, and I have no present or prospective personal interest or bias withrespect to the participants in the transaction. I did not base, either partially or completely, my conclusions within this report, on the race, color, religion,sex, age, marital status, handicap, familial status, or national origin of either the prospective owners or occupants of the subject property or of the present owners or occupants of the properties in the vicinity of the subject property or on any other basis prohibited by law.<br/>
          10.My employment and/or compensation for performing this assignment or anyfuture or anticipated assignments was not conditioned on any agreement or understanding, written or otherwise, that I would report a predetermined specific outcome that favors the cause of any party, or the attainment of aspecific result or occurrence of a specific subsequent event (such as approval of a pending mortgage loan application)<br/>
          11.The lender/client may disclose or distribute this report to: the borrower; an appraiser to utilize in the completion of an appraisal assignment; another lender at the request of the borrower; the mortgagee or its successors and assigned; mortgage insurers; government sponsored enterprises; other secondary market participants; data collection or reporting services; professional appraisal organizations; any department, agency , or instrumentality of the United States; and any state, the District of Columbia, or other jurisdictions; without having to obtain the data collector's or supervisor appraiser (if applicable) consent. Such consent must be obtained before this report may be disclosed or distributed to any other party (including but not limited to, the public throughadvertising, public relations, news, sales, or other media).<br/>
          12.If this report was transmitted as an "electronic record "containing my "electronic signature," as those terms are defined in applicable federal and/or state laws (excluding audio and video recordings), or a facsimile transmission of this report containing a copy or representation of my signature, the report shall be as effective, enforceable and valid as if a paper version of this report were delivered containing my original hand-written signature
        </div>
        <br/>
      </CardBody>
    </Card>
    <br/>
    <Card>
      <CardHeader className="cursor-pointer header-color" onClick={(e)=>setState({showSupervisor:!state.showSupervisor})}>
        {state.showSupervisor?<i className="fa fa-minus"/>:<i className="fa fa-plus"/>} Supervisor
      </CardHeader>
      <CardBody>
        {supervisorDetails}
      </CardBody>
    </Card>
  </div>;
}


export default StepDataCollectorCertification;
