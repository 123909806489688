//Author June Yee Leow
//Date Oct 24, 2017
//This script serve as a container that holds all the utility function
import axios from 'axios';
import config from '../config';
import React from 'react';
import MyToast from '../components/util/my-toast';
import MyAccountingToast from '../components/util/my-accounting-toast';
import MyConfirmation from '../components/util/my-confirmation';
import {toast} from 'react-toastify';
import ReactDOM from 'react-dom';
import {UncontrolledTooltip} from 'reactstrap';

export function generateTooltip(id, description){
  return <i className="fa fa-question-circle cursor-pointer link-color" id={id}>
    <UncontrolledTooltip className="mytooltip" delay={{ "show": 0, "hide": 0 }} placement="right" target={id}>
      {description}
    </UncontrolledTooltip>
  </i>;
}

export function pushToArray(targetArray, value){
  let array = targetArray.slice();
  array.push(value);

  return array;
}

export function replaceRegex(value, joinValue=''){
  let newvalue = value;
  if(joinValue !== ''){
    newvalue = newvalue.join(joinValue)
  }
  newvalue = newvalue.replace(new RegExp('&','g'),'ampersand');
  newvalue = newvalue.replace(new RegExp('/','g'),'fslash');
  newvalue = newvalue.replace(new RegExp('[+]','g'),'plus');

  return newvalue;
}

export function encodeParam(value){
  if(value&&value!==''){
    return encodeURIComponent(value.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'))
  }
  return value;
}

export function sliceFromArray(targetArray, fields, targets, subArray=[]){
  let array = targetArray.slice();

  if(!Array.isArray(fields))
    fields = [fields];
  if(!Array.isArray(targets))
    targets = [targets];

  //fields and targets has to be matching
  if(fields.length!==targets.length)
    return [];

  for(let i=0;i<array.length;i++){
    if(array[i][fields[0]]===targets[0]){
      if(fields.length===1){
        array.splice(i, 1);
        break;
      }
      else{
        let fieldsSubset = fields.slice();
        let targetsSubset = targets.slice();
        let subArraySubset = subArray.slice();

        fieldsSubset.shift();
        targetsSubset.shift();
        subArraySubset.shift();
        array[i][subArray[0]] = sliceFromArray(array[i][subArray[0]], fieldsSubset, targetsSubset, subArraySubset);
        break;
      }
    }
  }

  return array;
}

export function getFromTo(type, month=3){
  let date = new Date(), y = date.getFullYear(), m = date.getMonth();

  if(type==='from'){
    // firstday of current month
    let firstDay = new Date(y, m, 1);
    let fromMonth = firstDay.getMonth()+1;
    if(fromMonth<10)
      fromMonth = '0'+fromMonth;

    return firstDay.getFullYear()+'-'+fromMonth+'-01';
  }else if(type==='to'){
    //last day of current month
    let lastDay = new Date(y, m + 1, 0);
    let toMonth = lastDay.getMonth()+1;
    if(toMonth<10)
      toMonth = '0'+toMonth;

    return lastDay.getFullYear()+'-'+toMonth+'-'+lastDay.getDate();
  }else if(type==='start'){
    // 3 months past
    let firstDay = new Date(y, m - month, 1);
    let startMonth = firstDay.getMonth()+1;
    if(startMonth<10)
      startMonth = '0'+startMonth;

    return firstDay.getFullYear()+'-'+startMonth+'-01';
  }else if(type==='end'){
    // current date
    m = (date.getMonth()+1)
    if(parseInt(m,10)<10)
      m = '0'+m;

    return y+'-'+m+'-'+date.getDate();
  }else{
    return;
  }
}

export function generateSID(){
  let text = "";
  let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < 5; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

export function arraysOfObjectEqual(value, other) {

	// Get the value type
	var type = Object.prototype.toString.call(value);

	// If the two objects are not the same type, return false
	if (type !== Object.prototype.toString.call(other)) return false;

	// If items are not an object or array, return false
	if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

	// Compare the length of the length of the two items
	var valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
	var otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
	if (valueLen !== otherLen) return false;

	// Compare two items
	var compare = function (item1, item2) {

		// Get the object type
		var itemType = Object.prototype.toString.call(item1);

		// If an object or array, compare recursively
		if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
			if (!arraysOfObjectEqual(item1, item2)) return false;
		}

		// Otherwise, do a simple comparison
		else {

			// If the two items are not the same type, return false
			if (itemType !== Object.prototype.toString.call(item2)) return false;

			// Else if it's a function, convert to a string and compare
			// Otherwise, just compare
			if (itemType === '[object Function]') {
				if (item1.toString() !== item2.toString()) return false;
			} else {
				if (item1 !== item2) return false;
			}

		}
	};

	// Compare properties
	if (type === '[object Array]') {
		for (var i = 0; i < valueLen; i++) {
			if (compare(value[i], other[i]) === false) return false;
		}
	} else {
		for (var key in value) {
			if (value.hasOwnProperty(key)) {
				if (compare(value[key], other[key]) === false) return false;
			}
		}
	}

	// If nothing failed, return true
	return true;

};

export const getAPICallGenerator = (props, customControl={}) =>{
  return (url, successMessage='', failedMessage='', callBack=()=>{}, errorCallBack=()=>{})=>{
    let forbiddenMessage='Access denied.';
    let control = {
      showLoading: props.showLoading,
      hideLoading: props.hideLoading,
      logout: props.logout
    };

    control = Object.assign({}, control, customControl);

    return asyncGet(url,control,callBack,errorCallBack,successMessage,failedMessage, forbiddenMessage);
  };
}

export const deleteAPICallGenerator = (props, customControl={}) =>{
  return (url, successMessage='', failedMessage='', callBack=()=>{}, errorCallBack=()=>{})=>{
    let control = {
      showLoading: props.showLoading,
      hideLoading: props.hideLoading,
      logout: props.logout
    };

    control = Object.assign({}, control, customControl);

    return asyncDelete(url,control,callBack,errorCallBack,successMessage,failedMessage);
  };
}

export const postAPICallGenerator = (props, customControl={}) =>{
  return (url, parameters, successMessage='', failedMessage='', callBack=()=>{}, errorCallBack=()=>{})=>{
    let control = {
      showLoading: props.showLoading,
      hideLoading: props.hideLoading,
      logout: props.logout
    };

    control = Object.assign({}, control, customControl);

    return asyncPost(url, parameters, control,callBack,errorCallBack,successMessage,failedMessage);
  };
}

export const putAPICallGenerator = (props, customControl={}) =>{
  return (url, parameters, successMessage='', failedMessage='', callBack=()=>{}, errorCallBack=()=>{})=>{
    let control = {
      showLoading: props.showLoading,
      hideLoading: props.hideLoading,
      logout: props.logout
    };

    control = Object.assign({}, control, customControl);

    return asyncPut(url, parameters, control,callBack,errorCallBack,successMessage,failedMessage);
  };
}

//call back generator
//array of object for state to update
//if key exist then we use the response return from API and access the object using the key
//otherwise we use the custom value
//{state:'stateToUpdate', key:'keyNameOfAPIResponse', value'customValue'}
export const callBackGenerator = (setState)=>{
  return (stateToMutate, updateFuncs = [])=>{
    return (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code!=='00'){

      }
      else{
        let newState = {};
        for(let i=0;i<stateToMutate.length;i++){
          //if we are updating state with dynamic key from response.data
          //loop through each level by splitting dot "."
          if(stateToMutate[i].key){
            let tokens = stateToMutate[i].key.split('.');

            let target;

            for(let j=0;j<tokens.length;j++){
              if(j===0)
                target = response.data[tokens[j]];
              else
                target = target?target[tokens[j]]:undefined;
            }

            newState[stateToMutate[i].state] = target;
          }
          else if(stateToMutate[i].arraykey){
            let tokens = stateToMutate[i].arraykey.split('.');

            let target;

            for(let j=0;j<tokens.length;j++){
              if(j===0)
                target = response.data[tokens[j]];
              else
                target = target?target[tokens[j]]:undefined;
            }

            let newValue = pushToArray(stateToMutate[i].targetArray, target)

            newState[stateToMutate[i].state] = newValue;
          }
          else if(stateToMutate[i].valuekey){
            let key = stateToMutate[i].valuekey[0];
            let value = stateToMutate[i].valuekey[1];

            let newValue = [];
            for(let j=0;j<response.data.data.length;j++){
              let data = response.data.data[j];

              let tmp = {};
              tmp.key = data[key];
              tmp.value = data[value];
    
              newValue.push(tmp);
            }

            newState[stateToMutate[i].state] = newValue;
          }
          else if(stateToMutate[i].objectkey){
            let token = stateToMutate[i].objectkey;
            let newValue = {};

            for(let j=0;j<response.data.data.length;j++){
              newValue[response.data.data[j][token]] = stateToMutate[i].value;
            }

            newState[stateToMutate[i].state] = newValue;
          }
          //plain value
          else{
            newState[stateToMutate[i].state] = stateToMutate[i].value;
          }
        }

        setState(newState);

        if(Array.isArray(updateFuncs)){
          updateFuncs.forEach(updateFunc => {
            updateFunc();
          });
        }else{
          updateFuncs();
        }
      }
    };
  };
}

export function getReducer(){
  return (state, action)=>{
    switch(action.type){
      //override the state with new value
      case "CONCAT_SET_STATES":
        let newState = {...state};
        Object.keys(action.new_states).forEach((key) =>{
          if(Array.isArray(state[key])){
            let array = state[key].slice();
            array = array.concat(action.new_states[key]);
            newState[key] = array;
          }
          else if(newState[key] instanceof Object){
              newState[key] = Object.assign({}, newState[key], {...action.new_states[key]});
          }
          else{
            newState[key] = action.new_states[key];
          }
        });
        return newState;
      //override the state with new value
      case "SET_STATES":
        return Object.assign({}, state, action.new_states);
      default:
        return state;
    }
  };
};

export const getSetStateFunction = (dispatch)=>{
  return (mutateState, type="SET_STATES")=>{dispatch({type:type, new_states:mutateState});};
};

export function arraysEqual(a, b) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

export function getStateCode(state){
  let stateMap = {
    'Alabama':'AL', 'Alaska':'AK', 'Arizona':'AZ', 'Arkansas':'AR', 'California':'CA', 'Colorado':'CO', 'Connecticut':'CT', 'Delaware':'DE', 'Washington DC':'DC', 'Florida':'FL', 'Georgia':'GA', 'Hawaii':'HI', 'Idaho':'ID', 'Illinois':'IL', 'Indiana':'IN', 'Iowa':'IA', 'Kansas':'KS', 'Kentucky':'KY', 'Louisiana':'LA', 'Maine':'ME', 'Maryland':'MD', 'Massachusetts':'MA', 'Michigan':'MI', 'Minnesota':'MN', 'Mississippi':'MS', 'Missouri':'MO', 'Montana':'MT', 'Nebraska':'NE', 'Nevada':'NV', 'New Hampshire':'NH', 'New Jersey':'NJ', 'New Mexico':'NM', 'New York':'NY', 'North Carolina':'NC', 'North Dakota':'ND', 'Ohio':'OH', 'Oklahoma':'OK', 'Oregon':'OR', 'Pennsylvania':'PA', 'Rhode Island':'RI', 'South Carolina':'SC', 'South Dakota':'SD', 'Tennessee':'TN', 'Texas':'TX', 'Utah':'UT', 'Vermont':'VT', 'Virginia':'VA', 'Washington':'WA', 'West Virginia':'WV', 'Wisconsin':'WI', 'Wyoming':'WY'};

  if(stateMap[state])
    return stateMap[state];
  else
    return '';
}

export function getStateWithCode(stateCode){
  let stateMap = {
    'AL':'Alabama', 'AK':'Alaska', 'AZ':'Arizona', 'AR':'Arkansas', 'CA':'California', 'CO':'Colorado', 'CT':'Connecticut', 'DE':'Delaware', 'DC':'Washington DC', 'FL':'Florida', 'GA':'Georgia', 'HI':'Hawaii', 'ID':'Idaho', 'IL':'Illinois', 'IN':'Indiana', 'IA':'Iowa', 'KS':'Kansas', 'KY':'Kentucky', 'LA':'Louisiana', 'ME':'Maine', 'MD':'Maryland', 'MA':'Massachusetts', 'MI':'Michigan', 'MN':'Minnesota', 'MS':'Mississippi', 'MO':'Missouri', 'MT':'Montana', 'NE':'Nebraska', 'NV':'Nevada', 'NH':'New Hampshire', 'NJ':'New Jersey', 'NM':'New Mexico', 'NY':'New York', 'NC':'North Carolina', 'ND':'North Dakota', 'OH':'Ohio', 'OK':'Oklahoma', 'OR':'Oregon', 'PA':'Pennsylvania', 'RI':'Rhode Island', 'SC':'South Carolina', 'SD':'South Dakota', 'TN':'Tennessee', 'TX':'Texas', 'UT':'Utah', 'VT':'Vermont', 'VA':'Virginia', 'WA':'Washington', 'WV':'West Virginia', 'WI':'Wisconsin', 'WY':'Wyoming'};

  if(stateMap[stateCode])
    return stateMap[stateCode];
  else
    return '';
}

export function confirmation(yesCallBack, noCallBack, title, message){
  ReactDOM.render(
    <MyConfirmation yesCallBack={yesCallBack} noCallBack={noCallBack} message={message} title={title}/>,document.getElementById('myConfirmationContainer')
  );
}
export function addBusinessDays(d,n) {
    d = new Date(d.getTime());
    var day = d.getDay();
    d.setDate(d.getDate() + n + (day === 6 ? 2 : +!day) + (Math.floor((n - 1 + (day % 6 || 1)) / 5) * 2));
    return d;
}

export function getRoles(){
  let roles = [];
  let role = localStorage.getItem('role');

  roles.push(role);
  return roles;
}



export function numToWords (num) {

    let a = ['','one ','two ','three ','four ', 'five ','six ','seven ','eight ','nine ','ten ','eleven ','twelve ','thirteen ','fourteen ','fifteen ','sixteen ','seventeen ','eighteen ','nineteen '];
    let b = ['', '', 'twenty','thirty','forty','fifty', 'sixty','seventy','eighty','ninety'];
    let c = ['','one hundred','two hundred','three hundred','four hundred','five hundred','six hundred','seven hundred','eight hundred','nine hundred'];

    let token = num.toString().split('.');
    let cents = '';

    //special handle for cent
    if(token.length>1){
      cents = token[1];
      num = token[0];
    }

    if ((num = num.toString()).length > 9) return 'overflow';
    let n = ('000000000' + num).substr(-9).match(/^(\d{3})(\d{3})(\d{1})(\d{2})$/);
    if (!n) return; var str = '';
    str += (n[1] !== 0) ? (a[Number(n[1])] || c[n[1][0]] +' '+b[n[1][1]] + ' ' + a[n[1][2]]) + 'million ' : '';
    str += (n[2] !== 0) ? (a[Number(n[2])] || c[n[2][0]] +' '+(a[n[2][1].toString()+n[2][2].toString()]||b[n[2][1]]+ ' ' + a[n[2][2]])) + 'thousand ' : '';
    str += (n[3] !== 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'hundred ' : '';

    if(cents==='')
      str += (n[4] !== 0) ? ((str !== '') ? 'and ' : '') + (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'only ' : '';
    else{
      let cent = b[cents[0]]+' '+a[cents[1]];
      str += (n[4] !== 0) ? ((str !== '') ? ' ' : '') + (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'and '+cent+' cents only ' : '';
    }
    if(str.indexOf('only')===-1)
      str+='only';
    return str;
}

export function getSession(){
  let session = {};
  session.userFirstName = localStorage.getItem('firstName');
  session.userLastName = localStorage.getItem('lastName');
  session.role = localStorage.getItem('role');
  session.userID = localStorage.getItem('userID');
  session.email = localStorage.getItem('email');

  return session;
}

//show toast message
//this will trigger a toast appear at the toastcontainer
//depends on the type, the toast background color will be different
export function showMessage(type,message,from='System',link=''){
  switch(type){
    case "success":
      toast.success(<MyToast from={from} content={message}/>,{className:'my-toast'});
      break;
    case "info":
      toast.info(<MyToast from={from} content={message}/>,{className:'my-toast'});
      break;
    case "notification":
      toast.info(<MyToast from={from} content={message} link={link}/>,{autoClose:false,className:'my-toast'});
      break;
    case "warning":
      toast.warning(<MyToast from={from} content={message}/>,{className:'my-toast'});
      break;
    case "error":
      toast.error(<MyToast icon={false} from={from} content={message}/>,{autoClose:false,className:'my-toast'});
      break;
    default:
      toast(<MyToast from={from} content={message}/>,{className:'my-toast'});
      break;
  }
}

//show toast message
export function hideMessage(id){
  toast.dismiss(id);
}

//show toast message for accounting accounting-audit
export function showAuditMessage(data){
  return toast.error(<MyAccountingToast data={data}/>,{autoClose:false});
}

export function showAuditMessageOrderDetails(data, history){
  return toast.error(<MyAccountingToast history={history} data={data}/>,{autoClose:false});
}

//show toast message for accounting accounting-audit
export function hideAuditMessage(id){
  toast.dismiss(id);
}

//hashcode for string
//for converting string to html color code
export function hashCode(str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
}

// Convert an int to hexadecimal with a max length
// of six characters.
export function intToARGB(i) {
    var hex = ((i>>24)&0xFF).toString(16) +
            ((i>>16)&0xFF).toString(16) +
            ((i>>8)&0xFF).toString(16) +
            (i&0xFF).toString(16);
    // Sometimes the string returned will be too short so we
    // add zeros to pad it out, which later get removed if
    // the length is greater than six.
    hex += '000000';
    return hex.substring(0, 6);
}

// Extend the string type to allow converting to hex for quick access.
export function stringToHexCode(string) {
  if(string&&string!==''&&string.length>0)
    return intToARGB(hashCode(string));
  else
    return '';
}

export function formatNumber(number){
  return Number(number).toLocaleString(undefined, {minimumFractionDigits:2, maximumFractionDigits: 2});
}

export function formatNumberNoDecimal(number){
  return Number(number).toLocaleString(undefined, {minimumFractionDigits:0, maximumFractionDigits: 0});
}

export function bindContext(context,funcs){
  for(let i=0;i<funcs.length;i++){
    context[funcs[i]] = context[funcs[i]].bind(context);
  }
}

//format a string to date time format
export function formatDateTime(date){
  if(date&&date!=='0000-00-00'&&date!=='0000-00-00 00:00:00'){
    let moment = require('moment-timezone');
    let formattedDateTime = moment(date).format("MMM Do, YY hh:mm a");

    return formattedDateTime;
  }
  else
    return '-';
}

//format a string to date only format
export function formatDate(date){
  if(date&&date!=='0000-00-00'&&date!=='0000-00-00 00:00:00'){
    let moment = require('moment-timezone');
    let formattedDateTime = moment(date).format("MMM Do, YYYY");

    return formattedDateTime;
  }
  else
    return '-';
}

//check if JWT access token is expired by checking the localStorage on expire entry
export function tokenExpired(){
  let moment = require('moment-timezone');

  //refresh 5 minutes before expire
  let now = moment().add(5,'minutes').tz('America/Los_Angeles').unix();
  let tokenExpire = localStorage.getItem('expire');

  if(tokenExpire<now)
    return true;

  return false;
}

//http error handler to check if it's a 401 unauthorized
//will refresh token and retry request
export function jwtHttpErrorResponseInterceptor(error,control){
  if(error.config && error.response && error.response.status ===401){

    //refreshToken
    return refreshToken().then(function(response){
      error.config.headers.Authorization = 'Bearer '+localStorage.getItem('token');

      return axios.request(error.config);
    }, function(error){
      //refresh error, logout user
      //use refresh token to logout
      control.logout(control);
      return Promise.reject(error);
    });
  }
  else
    return Promise.reject(error);
}

//refresh the JWT access token
export function refreshToken(){
  //return a promise to defer and wait until the refresh http request return
  return new Promise(function (resolve, reject) {
    let fullUrl = config.baseAPIUrl+'session/refresh';

    //construct the JSON data
    let rtoken = localStorage.getItem('rtoken');
    let data = {
      rtoken: rtoken
    };
    data = JSON.stringify(data);

    let axiosConfig = {
      headers: {Authorization: 'Bearer '+rtoken},
      "Content-Type": "application/json"
    };

    //http post to refresh token
    axios.post(fullUrl, data,axiosConfig)
      .then(function(response){
        if(response.data.code==='00'){
          localStorage.setItem('token',response.data.token);
          localStorage.setItem('expire',response.data.expire);
          localStorage.setItem('role', response.data.role);

          resolve(response);
        }
        else{
          reject(response);
        }
      })
      .catch(
        function(error){
          reject(error);
        }
      );

  });
}

//return an instance of axios for http request
//attached interceptors
export function getAxiosInstance(control){
  let axiosInstance = axios.create();

  //interceptor before request begin
  axiosInstance.interceptors.request.use(function(request){
    //if token expired
    if(tokenExpired()&&!control.noToken){
      return refreshToken().then(
        function(response){
          if(response.data.code==='00'){
            //update the header to use new token
            request.headers.Authorization = 'Bearer '+localStorage.getItem('token');

            return request;
          }
          else {
            control.logout(control);
            return request;
          }
        },
        function(error){
          control.logout(control);
          //show error message
          showMessage('error','Session expired, please login again.');
          return request;
        }
      );
    }
    else
      return request;
  });

  //interceptor after request done
  //second guard if the local time of the computer mess up
  axiosInstance.interceptors.response.use(
    function(response){
      //no error just return the response
      return response;
    },
    function (error){
      //possibly 401 Unauthozied, let error interceptor to handle and refresh token
      return jwtHttpErrorResponseInterceptor(error,control);
    }
  );


  return axiosInstance;
}


//FOR LOCAL STATE ONLY, FOR REDUX USE asyncPostRedux INSTEAD
//control is a js object that store functions from parent component that enable child to trigger or perform update from parent level.
//E.g show loading bar

//callBack is a function that handle the response once http request is complete

//errorCallBack is a function that handle the response once http request is complete BUT WITH HTTP ERROR
export const asyncPost = (url,parameters,control,callBack,errorCallBack,successMsg='',failedMsg='') => {
  let fullUrl = config.baseAPIUrl+url;
  //construct the JSON data
  var data = {};
  for(let i=0;i<parameters.length;i++){
    data[parameters[i].field] = parameters[i].value;
  }

  data = JSON.stringify(data);

  let token = localStorage.getItem('token');
  let axiosConfig = {
    headers: {Authorization: 'Bearer '+token},
    "Content-Type": "application/json"
  };

  if(control.signal)
    axiosConfig.signal = control.signal;

  //show loading and start async post
  control.showLoading();

  //get an axios instance that inject with interceptor to handle tokken expiration
  let axiosInstance = getAxiosInstance(control);

  let promise = axiosInstance.post(fullUrl, data,axiosConfig)
    .then(
      function(response){
        let code = response.data?response.data.code:undefined;
        //stop loading progress bar
        control.hideLoading();

        if(code){
          if(code==='00'){
            if(successMsg!=='')
              showMessage('success',successMsg);
            return callBack(response);

          }
          else{
            if(code==='09')
              autoReportError('POST', url, response.data.message);
            if(failedMsg!=='')
              showMessage('error',failedMsg+' '+response.data.message);

            return callBack(response);
          }
        }
        else{
          autoReportError('POST', url, response.data);
          showMessage('error',failedMsg);
          return errorCallBack(response.data);
        }
      }
    )
    .catch(
      function(error){
        let resToRecord = JSON.stringify(error);

        if(error.message)
          resToRecord = resToRecord + ' ** '+error.message;
        if(error.status)
          resToRecord = resToRecord + ' ** '+error.status;
        if(error.response&&error.response.status)
          resToRecord = resToRecord + ' ** '+error.response.status;
        if(error.code)
          resToRecord = resToRecord + ' ** '+error.code;

        autoReportError('POST', url, resToRecord);
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if(failedMsg!=='')
              showMessage('error',failedMsg+' '+error.response.data.message);
        } else if (error.request) {
            // The request was made but no response was received
            if(failedMsg!=='')
              showMessage('error',failedMsg+' Empty response');
        } else {
            // Something happened in setting up the request that triggered an Error
            if(failedMsg!==''){
              if(error.message){
                if(error.message!=='Cancel: canceled' && error.message!=='canceled')
                  showMessage('error',failedMsg+' 1'+error.message);
              }
              else
                showMessage('error',failedMsg+' '+error);
            }
        }
        //stop loading progress bar
        control.hideLoading();

        return errorCallBack(error);
      }
    );

  //return promise
  return promise;
}

//FOR ASYNC THAT DO NOT NEED CALL BACK

export const asyncPostNoCallback = (url,parameters,control) => {
  let fullUrl = config.baseAPIUrl+url;
  //construct the JSON data
  var data = {};
  for(let i=0;i<parameters.length;i++){
    data[parameters[i].field] = parameters[i].value;
  }

  data = JSON.stringify(data);

  let token = localStorage.getItem('token');
  let axiosConfig = {
    headers: {Authorization: 'Bearer '+token},
    "Content-Type": "application/json"
  };

  //get an axios instance that inject with interceptor to handle tokken expiration
  let axiosInstance = getAxiosInstance(control);

  let promise = axiosInstance.post(fullUrl, data,axiosConfig)
    .then(
      function(response){

      }
    )
    .catch(
      function(error){

      }
    );

  //return promise
  return promise;
}


//FOR LOCAL STATE ONLY, FOR REDUX USE asyncDeleteRedux INSTEAD
//control is a js object that store functions from parent component that enable child to trigger or perform update from parent level.
//E.g show loading bar

//callBack is a function that handle the response once http request is complete

//errorCallBack is a function that handle the response once http request is complete BUT WITH HTTP ERROR
export const asyncDelete = (url,control,callBack,errorCallBack,successMsg='',failedMsg='') => {
  let fullUrl = config.baseAPIUrl+url;

  let token = localStorage.getItem('token');
  let axiosConfig = {
    headers: {Authorization: 'Bearer '+token},
    "Content-Type": "application/json"
  };

  if(control.signal)
    axiosConfig.signal = control.signal;

  //show loading and start async post
  control.showLoading();

  //get an axios instance that inject with interceptor to handle tokken expiration
  let axiosInstance = getAxiosInstance(control);

  let promise = axiosInstance.delete(fullUrl,axiosConfig)
    .then(
      function(response){
        let code = response.data?response.data.code:undefined;
        //stop loading progress bar
        control.hideLoading();

        if(code){
          if(code==='00'){
            if(successMsg!=='')
              showMessage('success',successMsg);
            return callBack(response);

          }
          else{
            if(code==='09')
              autoReportError('DELETE', url, response.data.message);
            if(failedMsg!=='')
              showMessage('error',failedMsg+' '+response.data.message);

            return callBack(response);
          }
        }
        else{
          autoReportError('DELETE', url, response.data);
          showMessage('error',failedMsg);
          return errorCallBack(response.data);
        }
      }
    )
    .catch(
      function(error){
        let resToRecord = JSON.stringify(error);

        if(error.message)
          resToRecord = resToRecord + ' ** '+error.message;
        if(error.status)
          resToRecord = resToRecord + ' ** '+error.status;
        if(error.response&&error.response.status)
          resToRecord = resToRecord + ' ** '+error.response.status;
        if(error.code)
          resToRecord = resToRecord + ' ** '+error.code;

        autoReportError('DELETE', url, resToRecord);
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if(failedMsg!=='')
              showMessage('error',failedMsg+' '+error.response.data.message);
        } else if (error.request) {
            // The request was made but no response was received
            if(failedMsg!=='')
              showMessage('error',failedMsg+' Empty response');
        } else {
            // Something happened in setting up the request that triggered an Error
            if(failedMsg!==''){
              if(error.message){
                if(error.message!=='Cancel: canceled' && error.message!=='canceled')
                  showMessage('error',failedMsg+' 1'+error.message);
              }
              else
                showMessage('error',failedMsg+' '+error);
            }
        }
        //stop loading progress bar
        control.hideLoading();

        return errorCallBack(error);
      }
    );
  //return the promise
  return promise;
}

//FOR LOCAL STATE ONLY, FOR REDUX USE asyncPutRedux INSTEAD
//control is a js object that store functions from parent component that enable child to trigger or perform update from parent level.
//E.g show loading bar

//callBack is a function that handle the response once http request is complete

//errorCallBack is a function that handle the response once http request is complete BUT WITH HTTP ERROR
export const asyncPut = (url,parameters,control,callBack,errorCallBack,successMsg='',failedMsg='') => {
  let fullUrl = config.baseAPIUrl+url;

  //construct the JSON data
  var data = {};
  for(let i=0;i<parameters.length;i++){
    data[parameters[i].field] = parameters[i].value;
  }

  data = JSON.stringify(data);

  let token = localStorage.getItem('token');
  let axiosConfig = {
    headers: {
      Authorization: 'Bearer '+token,
      "Content-Type": "application/json"
    }
  };

  if(control.signal)
    axiosConfig.signal = control.signal;

  //show loading and start async post
  control.showLoading();

  //get an axios instance that inject with interceptor to handle tokken expiration
  let axiosInstance = getAxiosInstance(control);

  let promise = axiosInstance.put(fullUrl,data,axiosConfig)
    .then(
      function(response){
        let code = response.data?response.data.code:undefined;
        //stop loading progress bar
        control.hideLoading();



        if(code){
          if(code==='00'){
            if(successMsg!=='')
              showMessage('success',successMsg);
            return callBack(response);

          }
          else{
            if(code==='09')
              autoReportError('PUT', url, response.data.message);
            if(failedMsg!=='')
              showMessage('error',failedMsg+' '+response.data.message);

            return callBack(response);
          }
        }
        else{
          autoReportError('PUT', url, response.data);
          showMessage('error',failedMsg);
          return errorCallBack(response.data);
        }
      }
    )
    .catch(
      function(error){
        let resToRecord = JSON.stringify(error);

        if(error.message)
          resToRecord = resToRecord + ' ** '+error.message;
        if(error.status)
          resToRecord = resToRecord + ' ** '+error.status;
        if(error.response&&error.response.status)
          resToRecord = resToRecord + ' ** '+error.response.status;
        if(error.code)
          resToRecord = resToRecord + ' ** '+error.code;

        autoReportError('PUT', url, resToRecord);
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if(failedMsg!=='')
              showMessage('error',failedMsg+' '+error.response.data.message);
        } else if (error.request) {
            // The request was made but no response was received
            if(failedMsg!=='')
              showMessage('error',failedMsg+' Empty response');
        } else {
            // Something happened in setting up the request that triggered an Error
            if(failedMsg!==''){
              if(error.message){
                if(error.message!=='Cancel: canceled' && error.message!=='canceled')
                  showMessage('error',failedMsg+' 1'+error.message);
              }
              else
                showMessage('error',failedMsg+' '+error);
            }
        }
        //stop loading progress bar
        control.hideLoading();

        return errorCallBack(error);
      }
    );
  //return the promise
  return promise;
}

//FOR LOCAL STATE ONLY, FOR REDUX USE asyncGetRedux INSTEAD
//control is a js object that store functions from parent component that enable child to trigger or perform update from parent level.
//E.g show loading bar

//callBack is a function that handle the response once http request is complete

//errorCallBack is a function that handle the response once http request is complete BUT WITH HTTP ERROR
export const asyncGet = (url,control,callBack,errorCallBack,successMsg='',failedMsg='', forbiddenMessage='Access denied.') => {
  let fullUrl = config.baseAPIUrl+url;

  let token = localStorage.getItem('token');
  let axiosConfig = {
    headers: {
      'Authorization':'Bearer '+token,
      "Content-Type": "application/json"
    }
  };

  if(control.signal)
    axiosConfig.signal = control.signal;

  //show loading and start async post
  control.showLoading();

  //get an axios instance that inject with interceptor to handle tokken expiration
  let axiosInstance = getAxiosInstance(control);

  let promise = axiosInstance.get(fullUrl,axiosConfig)
    .then(
      function(response){
        let code = response.data?response.data.code:undefined;
        //stop loading progress bar
        control.hideLoading();
        if(code){
          if(code==='00'){
            if(successMsg!=='')
              showMessage('success',successMsg);
            return callBack(response);

          }
          else{
            if(code==='09')
              autoReportError('GET', url, response.data.message);
            if(failedMsg!=='')
              showMessage('error',failedMsg+' '+response.data.message);

            return callBack(response);
          }
        }
        else{
          autoReportError('GET', url, response.data);
          showMessage('error',failedMsg);
          return errorCallBack(response.data);
        }
      }
    )
    .catch(
      function(error){
        let resToRecord = JSON.stringify(error);

        if(error.message)
          resToRecord = resToRecord + ' ** '+error.message;
        if(error.status)
          resToRecord = resToRecord + ' ** '+error.status;
        if(error.response&&error.response.status)
          resToRecord = resToRecord + ' ** '+error.response.status;
        if(error.code)
          resToRecord = resToRecord + ' ** '+error.code;

        autoReportError('GET', url, resToRecord);
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if(error.response.status===403)
              showMessage('error',forbiddenMessage);
            else if(failedMsg!=='')
              showMessage('error',failedMsg+' '+error.response.data.message);
        } else if (error.request) {
            // The request was made but no response was received
            if(failedMsg!=='')
              showMessage('error',failedMsg+' Empty response');
        } else {
            // Something happened in setting up the request that triggered an Error
            if(failedMsg!==''){
              if(error.message){
                if(error.message!=='Cancel: canceled' && error.message!=='canceled')
                  showMessage('error',failedMsg+' '+error.message);
              }
              else
                showMessage('error',failedMsg+' '+error);
            }
        }
        //stop loading progress bar
        control.hideLoading();

        return errorCallBack(error);
      }
    );
  //return the promise
  return promise;
}

export const autoReportError = (httpMethod, httpUrl, httpResponse, category='Error - Error Message Encountered', criticalLevel='Minor')=>{
  let name = localStorage.getItem('firstName')+' '+localStorage.getItem('lastName');
  let email = localStorage.getItem('email');

  let issue = httpMethod+' '+httpUrl+' Error';
  let description = '**'+name+' - '+email+'** '+httpResponse;

  let parameters = [
    {
      field:'issue',
      value:issue
    },
    {
      field:'description',
      value:description
    },
    {
      field:'author',
      value:'System'
    },
    {
      field:'email',
      value:''
    },
    {
      field:'category',
      value:category
    },
    {
      field:'criticalLevel',
      value:criticalLevel
    },
    {
      field:'tag',
      value:'#systemreporterror'
    }
  ];

  let fullUrl = config.baseAPIUrl+'/ticket/create';
  //construct the JSON data
  var data = {};
  for(let i=0;i<parameters.length;i++){
    data[parameters[i].field] = parameters[i].value;
  }

  data = JSON.stringify(data);

  let token = localStorage.getItem('token');
  let axiosConfig = {
    headers: {Authorization: 'Bearer '+token},
    "Content-Type": "application/json"
  };

  //create axios instance that does not have interceptor or anything in it, just post it
  //don't show loading bar too
  let axiosInstance = axios.create();
  axiosInstance.post(fullUrl, data,axiosConfig)
}
