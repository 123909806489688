//Author Sooyoung Kim
//Date July 12, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, formatNumber, getStateWithCode, getStateCode, getFromTo, replaceRegex} from '../../../util/util';
import {Button, Collapse, Card, CardHeader, CardBody, Col, Row,  NavItem, Nav, TabContent, TabPane, Input, UncontrolledTooltip, Modal, ModalHeader, ModalBody, NavLink as NavLinkL} from 'reactstrap';
import MyHeatMapDrillDown from '../../util/my-heat-map-drilldown';
import moment from 'moment';
import MyChart from '../../util/my-chart';
import {NavLink} from 'react-router-dom';
import React, {useReducer, useEffect, useRef} from 'react';
import '../report.css';
import MyReactTable from '../../util/my-react-table';
import ReportFilter from '../report-filter';

import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

//initialize the state
const initialState = {
  activeTab:'3',
  publicReportShowVolume:false,
  publicReportShowSpread:false,
  publicReportShowComplex:false,
  publicReportShowAppraiser:false,
  loanPurpose:'',
  loanType:'',
  isRush:'',
  isComplex:'',
  state:'All',
  city:'',
  entity:'',
  county:'',
  filterCounty:[],
  filterAppraisalTypes:['Conv Condo (1073)','FHA Condo (1073)','USDA Condo (1073)','Conv SFR (1004)','FHA SFR (1004)','USDA SFR (1004)'],
  from:getFromTo('from'),
  to:getFromTo('to'),

  usa:[],
  statesStat:[],
  counties:[],
  tempCounties:[],
  statesStats:[],

  showLegend:false,

  reportDetailsToggle:false,
  reportDetails:'',
  reportDetailsOrders:[],
  clients:[],
  client:0,
  openByClient:0,
  turntime:'1',
  selectedStates:['All'],
  showStatesStats:true,
  encodedUrl:'',
  heatMapDrillDownStateCounty:'',
  heatMapDrillDownLevel:'',
};

//reducer function that perform state update
const reducer = getReducer();


const TurntimeReport  = (props)=>{
  const controller = new AbortController();
  const inputRef = useRef();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getReport();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const activeTabToggle = (tab) =>{
    setState({activeTab:tab});
  }

  const updateEncodedUrl = (v, access) =>{
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);
    let counties = replaceRegex(state.filterCounty, ',');
    let states = replaceRegex(state.selectedStates, ',');

    console.log(states);

    let encodedUrl = btoa("client="+state.client+"&openBy="+state.openByClient+"&turntime="+state.turntime+"&loanPurpose="+loanPurpose+"&loanType="+state.loanType+"&complex="+state.isComplex+"&rush="+state.isRush+"&from="+state.from+"&to="+state.to+"&entity="+state.entity+"&state="+states+"&county="+counties+"&city="+state.city+"&appraisalType="+appraisalType+"&showVolume="+state.publicReportShowVolume+"&showAppraiser="+state.publicReportShowAppraiser+"&showSpread="+state.publicReportShowSpread+"&showComplex="+state.publicReportShowComplex);
    setState({encodedUrl: encodedUrl});

    if(access === 'publicReportShowVolume'){
      setState({publicReportShowVolume: v});
    }else if(access === 'publicReportShowAppraiser'){
      setState({publicReportShowAppraiser: v});
    }else if(access === 'publicReportShowComplex'){
      setState({publicReportShowComplex: v});
    }else if(access === 'publicReportShowSpread'){
      setState({publicReportShowSpread: v});
    }
  }

  const heatMapOnClick = (stateCounty, level='state') =>{
    setState({heatMapDrillDownLevel:level, heatMapDrillDownStateCounty:stateCounty});
    if(level==='state'){
      heatMapDrillDown(stateCounty);
    }
  }

  const heatMapDrillDown = (stateName) =>{
    if(stateName===''){
      setState({counties:state.tempCounties.slice(), tempCounties:state.counties.slice()});
    }
    else{
      let counties = state.counties.slice();
      let newCounties = [];

      for(let i=0;i<counties.length;i++){
        if(counties[i].state===stateName)
          newCounties.push(counties[i]);
      }

      setState({counties:newCounties, tempCounties:counties});
    }
  }

  const reportDetailsToggle = () =>{
    if(state.reportDetailsDropDown)
      setState({reportDetailsOrders:[]});
    setState({reportDetailsDropDown:!state.reportDetailsDropDown});
  }

  const toggleLegend = () =>{
    setState({showLegend:!state.showLegend});
  }

  //API call
  const getReport = () =>{
    setState({counties:[], statesStats:[]});
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);
    let counties = replaceRegex(state.filterCounty, ',');
    let states = replaceRegex(state.selectedStates, ',');

    let url = 'report/turnTime/client='+state.client+'&turntime='+state.turntime+'&openBy='+state.openByClient+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+states+'&county='+counties+'&city='+state.city+'&appraisalType='+appraisalType;
    let encodedUrl = btoa("client="+state.client+"&openBy="+state.openByClient+"&turntime="+state.turntime+"&loanPurpose="+loanPurpose+"&loanType="+state.loanType+"&complex="+state.isComplex+"&rush="+state.isRush+"&from="+state.from+"&to="+state.to+"&entity="+state.entity+"&state="+states+'&county='+counties+"&city="+state.city+"&appraisalType="+appraisalType+"&showVolume="+state.publicReportShowVolume+"&showSpread="+state.publicReportShowSpread+"&showComplex="+state.publicReportShowComplex);
    console.log(encodedUrl);
    let callBack = apiCallBack([{state:'counties', key:'data'},{state:'statesStats', key:'data2'},{state:'usa', key:'data3'},{state:'encodedUrl', value:encodedUrl}]);

    httpGet(url, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  const getReportDetails = (classIndex, stateName, county, complex=state.isComplex) =>{
    reportDetailsToggle();
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);

    let url = 'report/turnTime/details/order/client='+state.client+'&turntime='+state.turntime+'&openBy='+state.openByClient+'&loanPurpose='+loanPurpose+'&county='+county+'&class='+classIndex+'&loanType='+state.loanType+'&complex='+complex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+stateName+'&city='+state.city+'&appraisalType='+appraisalType;
	  httpGet(url, '', 'Oops, something went wrong and could not load the report details. Please try again later.', apiCallBack([{state:'reportDetailsOrders', key:'data'}]));
  }

  //render
  let data = [];
  let data2 = [];
  let map;
  // eslint-disable-next-line no-template-curly-in-string
  let pointFormat = '<span>{point.code}: {point.value} bd<br/>{point.overall_total} orders<br/>{point.complex_percentage}% complex<br/>{point.total_appraiser} appraisers<br/>${point.spread} spread</span>';
  let joinBy =['postal-code','code'];

  //state data
  for(let i=0;i<state.statesStats.length;i++){
    data.push({
      state:state.statesStats[i].state,
      state_short:getStateCode(state.statesStats[i].state),
      code:getStateCode(state.statesStats[i].state),
      value:state.statesStats[i].overall_avg_turntime,
      overall_total: state.statesStats[i].overall_total,
      spread:state.statesStats[i].spread,
      total_lender_fee:state.statesStats[i].total_lender_fee,
      total_appraiser:state.statesStats[i].appraiser_total,
      total_appraiser_fee:state.statesStats[i].total_appraiser_fee,
      total_complex:state.statesStats[i].total_complex,
      complex_percentage:state.statesStats[i].complex_percentage,
    });
  }

  //county data
  for(let i=0;i<state.counties.length;i++){
    let tmp = {
      state:state.counties[i].state,
      state_short:getStateCode(state.counties[i].state),
      county:state.counties[i].county.trim(),
      overall_total: state.counties[i].overall_total,
      spread:state.counties[i].spread,
      total_lender_fee:state.counties[i].total_lender_fee,
      total_appraiser_fee:state.counties[i].total_appraiser_fee,
      total_appraiser:state.counties[i].appraiser_total,
      total_complex:state.counties[i].total_complex,
      complex_percentage:state.counties[i].complex_percentage,
      value:state.counties[i].overall_avg_turntime
    };
    data2.push(tmp);
  }

  if(data.length>0)
    map = <MyHeatMapDrillDown data={data} data2={data2} legendTitle="day" joinBy={joinBy} pointFormat={pointFormat} title="Turnaround Time (days)" onClick={heatMapOnClick}/>;

  const columns = [
    {
      id: 'state',
      Header: 'State',
      accessor: d => d.state,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'county',
      Header: 'County',
      accessor: d => d.county,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'overallAvgTurntime',
      Header: 'Overall TAT',
      accessor: d => d.overall_avg_turntime,
      Cell: props => <b>{props.row.original.overall_avg_turntime} bd</b>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'appraiserTotal',
      Header: 'Available Appraiser',
      accessor: d => d.appraiser_total,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'exteriorOnlyAppraiser',
      Header: 'Exterior Only Appraiser',
      accessor: d => d.total_appraiser_exterior_only,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'spread',
      Header: 'Spread',
      accessor: d => d.spread,
      Cell: props => <div>${formatNumber(props.row.original.spread)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'unclassified',
      Header: 'Unclassified',
      accessor: d => d.unknown_avg_turntime,
      Cell: props => <NavLink to="#" onClick={()=>getReportDetails(0,props.row.original.state, props.row.original.county)}>{props.row.original.unknown_avg_turntime} bd</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'urban',
      Header: 'Urban',
      accessor: d => d.urban_avg_turntime,
      Cell: props => <NavLink to="#" onClick={()=>getReportDetails(1,props.row.original.state, props.row.original.county)}>{props.row.original.urban_avg_turntime} bd</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'suburban',
      Header: 'Suburban',
      accessor: d => d.suburban_avg_turntime,
      Cell: props => <NavLink to="#" onClick={()=>getReportDetails(2,props.row.original.state, props.row.original.county)}>{props.row.original.suburban_avg_turntime} bd</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'rural',
      Header: 'Rural',
      accessor: d => d.rural_avg_turntime,
      Cell: props => <NavLink to="#" onClick={()=>getReportDetails(3,props.row.original.state, props.row.original.county)}>{props.row.original.rural_avg_turntime} bd</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'complex',
      Header: 'Complex',
      accessor: d => d.total_complex,
      Cell: props => <NavLink to="#" onClick={()=>getReportDetails('',props.row.original.state, props.row.original.county, 'Complex')}>{props.row.original.total_complex}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'overallTotal',
      Header: 'Total Volume',
      accessor: d => d.overall_total,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  const columns2 = [
    {
      id: 'state',
      Header: 'State',
      accessor: d => d.state,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'overallAvgTurntime',
      Header: 'Overall TAT',
      accessor: d => d.overall_avg_turntime,
      Cell: props => <b>{props.row.original.overall_avg_turntime} bd</b>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'appraiserTotal',
      Header: 'Available Appraiser',
      accessor: d => d.appraiser_total,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'exteriorOnlyAppraiser',
      Header: 'Exterior Only Appraiser',
      accessor: d => d.total_appraiser_exterior_only,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'spread',
      Header: 'Spread',
      accessor: d => d.spread,
      Cell: props => <div>${formatNumber(props.row.original.spread)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'unclassified',
      Header: 'Unclassified',
      accessor: d => d.unknown_avg_turntime,
      Cell: props => <NavLink to="#" onClick={()=>getReportDetails(0,props.row.original.state, '')}>{props.row.original.unknown_avg_turntime} bd</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'urban',
      Header: 'Urban',
      accessor: d => d.urban_avg_turntime,
      Cell: props => <NavLink to="#" onClick={()=>getReportDetails(1,props.row.original.state, '')}>{props.row.original.urban_avg_turntime} bd</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'suburban',
      Header: 'Suburban',
      accessor: d => d.suburban_avg_turntime,
      Cell: props => <NavLink to="#" onClick={()=>getReportDetails(2,props.row.original.state, '')}>{props.row.original.suburban_avg_turntime} bd</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'rural',
      Header: 'Rural',
      accessor: d => d.rural_avg_turntime,
      Cell: props => <NavLink to="#" onClick={()=>getReportDetails(3,props.row.original.state, '')}>{props.row.original.rural_avg_turntime} bd</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'complex',
      Header: 'Complex',
      accessor: d => d.total_complex,
      Cell: props => <NavLink to="#" onClick={()=>getReportDetails('',props.row.original.state, '', 'Complex')}>{props.row.original.total_complex}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'overallTotal',
      Header: 'Total Volume',
      accessor: d => d.overall_total,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];


  //pie chart
  let pieChartData = [];
  let total = -1;
  let totalComplex = 0;
  let lenderFee = -1;
  let appraiserFee = -1;
  let spread = 0;
  let totalAppraiser = 0;
  let stats = <div style={{height:'80px'}}>
    &nbsp;
  </div>;

  if(state.heatMapDrillDownLevel==='county' && state.heatMapDrillDownStateCounty!==''){
    let tokens = state.heatMapDrillDownStateCounty.split(', ');
    let stateName = '';
    let stateShort = '';
    let county = '';

    if(tokens.length>1){
      stateShort = tokens[1];
      stateName = getStateWithCode(stateShort);
      county = tokens[0];
    }
    
    console.log(state.counties)
    for(let i=0;i<state.counties.length;i++){
    
      if(state.counties[i].county.trim()===county && state.counties[i].state===stateName){
        total = state.counties[i].overall_total;
        totalComplex = state.counties[i].total_complex;
        lenderFee = state.counties[i].total_lender_fee;
        appraiserFee = state.counties[i].total_appraiser_fee;
        totalAppraiser = state.counties[i].appraiser_total;
      }
    }
  }
  else{
    if(state.heatMapDrillDownStateCounty!=='')
      for(let i=0;i<state.statesStats.length;i++){
        if(state.statesStats[i].state===state.heatMapDrillDownStateCounty){
          total = state.statesStats[i].overall_total;
          totalComplex = state.statesStats[i].total_complex;
          lenderFee = state.statesStats[i].total_lender_fee;
          appraiserFee = state.statesStats[i].total_appraiser_fee;
          totalAppraiser = state.statesStats[i].appraiser_total;
        }
      }
  }

  let tmp = {};
  tmp.name = 'complex';
  tmp.y = totalComplex;

  pieChartData.push(tmp);

  tmp = {};
  tmp.name = 'regular';
  tmp.y = total - totalComplex;

  pieChartData.push(tmp);

  const pieChartConfig ={
    chart: {
      type: 'pie',
      height:200
    },
    title: {
      text: 'Regular vs Complex Distribution',
      style:{"color":"#6E7170"}
    },
    subtitle: {
      text: null
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          distance:-50,
          format: '<font color="#6E7170">{point.name}: {point.y}</font>'
        },
      }
    },

    tooltip: {
      headerFormat: '<span style="font-size:11px">Complex vs Regular Orders</span><br>',
      pointFormat: '<span>{point.name} {point.y} orders</span>'
    },
    series: [{
      name: 'Complex orders',
      colorByPoint: true,
      data: pieChartData
    }],
    credits:{
      enabled:false
    },
    legend:{
      align: 'right',
      layout: 'vertical',
      verticalAlign: 'top',
      x: 340,
      y: 0,
      itemStyle:{
        fontSize:13
      },
      labelFormatter: function () {
        return this.name+' - '+formatNumber(this.series.yData[this.index]);
      }
    }
  };
  let chart;
  if(total>-1){
    chart = <MyChart options={pieChartConfig}/>;
  }

  //line chart
  let lineChart;
  let fees = [
    {
      name:'Lender',
      data:[lenderFee, parseInt(formatNumber(lenderFee/total), 10)]
    },
    {
      name:'Appraiser',
      data:[appraiserFee, parseInt(formatNumber(appraiserFee/total),10)]
    }
  ];

  let lineChartConfig = {
    chart:{
      height:200,
      type:'column'
    },
    xAxis: {
      categories: ['Total fee', 'Avg fee']
    },
    series: fees,
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true
        }
      }
    },
    title:{
      text: 'Fee',
      style:{"color":"#6E7170"}
    }
  }

  if(lenderFee>-1)
    lineChart = <MyChart options={lineChartConfig}/>;

  if(total>-1){

    spread = formatNumber((lenderFee-appraiserFee)/total);
    stats = <div style={{height:'80px', fontSize:'12px'}}>
      <b>{state.heatMapDrillDownStateCounty} </b>
      <Row>
        <Col sm="6">
        Order Volume
        </Col>
        <Col sm="6">
          <b>{total}</b>
        </Col>
      </Row>
      <Row>
        <Col sm="6">
          Total Appraiser
        </Col>
        <Col sm="6">
          <b>{totalAppraiser}</b>
        </Col>
      </Row>
      <Row>
        <Col sm="6">
          Spread
        </Col>
        <Col sm="6">
          <b>${spread}</b>
        </Col>
      </Row>
    </div>
  }

  let from, to;
  if(state.from!=='')
    from = moment(state.from);
  if(state.to!=='')
    to = moment(state.to);

  let content;

  if(state.reportDetailsOrders.length>0){
    let rows = state.reportDetailsOrders.map(
      (order, index)=>{
        return(
          <tr key={index}>
            <td><NavLink target="_blank" to={"/appraisal/"+order.ID}>{order.reference_num}</NavLink></td>
            <td>{order.property_street+' '+order.property_city+', '+order.property_state+' '+order.property_zip}</td>
            <td>{order.appraisal_type}</td>
            <td>{order.loan_type}</td>
            <td>{formatNumber(order.turntime)} bd</td>
          </tr>
        )
      }
    );

  content =
    <table className="table table-striped">
      <thead>
        <tr>
          <th>Reference #</th>
          <th>Address</th>
          <th>Report Type</th>
          <th>Loan type</th>
          <th>Turntime</th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </table>
  }

  let reportFiltersConfig = [
    {id:'from',value:from, updateFunc:(v)=>setState({from:v}), width:'2'},
    {id:'to',value:to, updateFunc:(v)=>setState({to:v}), width:'2'},
    {id:'rush',value:state.isRush, updateFunc:(v)=>setState({isRush:v}), width:'2'},
    {id:'complex',value:state.isComplex, updateFunc:(v)=>setState({isComplex:v}), width:'2'},
    {id:'customSelect',value:state.turntime, updateFunc:(v)=>setState({turntime:v}), width:'2', label:'Turntime', clearable:false, className:"", options:[
      {label:"Submitted - Completed",value:"1"},
      {label:"Submitted - Assignment Accepted",value:"2"},
      {label:"Inspection Completed - Completed",value:"3"},
      {label:"Accepted - Inspection Date",value:"4"},
      {label:"Submitted - Scheduled + Inspection Date - Completed",value:"5"}
    ]},
    {id:'state',value:state.selectedStates, updateFunc:(v)=>setState({selectedStates:v}), width:'2', group:true},
    {id:'county',value:state.filterCounty, updateFunc:(v)=>setState({filterCounty:v}), width:'3', group:true},
    {id:'city',value:state.city, updateFunc:(v)=>setState({city:v}), width:'3'},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'3'},
    {id:'openBy',value:state.openBy, updateFunc:(v)=>setState({openBy:v}), width:'3'},
    {id:'loanPurpose',value:state.loanPurpose, updateFunc:(v)=>setState({loanPurpose:v}), width:'2'},
    {id:'loanType',value:state.loanType, updateFunc:(v)=>setState({loanType:v}), width:'2'},
    {id:'entity',value:state.entity, updateFunc:(v)=>setState({entity:v}), width:'2'},
    {id:'appraisalType',value:state.filterAppraisalTypes, updateFunc:(v)=>setState({filterAppraisalTypes:v}), width:'4'},
    {id:'button',value:'Submit', updateFunc:getReport, width:'2', className:"align-right", color:"warning"},
  ];

  let reportFiltersConfig2 = [
    {id:'customSelect',value:state.publicReportShowVolume, updateFunc:(v)=>updateEncodedUrl(v, 'publicReportShowVolume'), width:'3', label:'Show Order Volume', clearable:true, className:"", options:[{label:"No",value:false},{label:"Yes",value:true}]},
    {id:'customSelect',value:state.publicReportShowAppraiser, updateFunc:(v)=>updateEncodedUrl(v, 'publicReportShowAppraiser'), width:'3', label:'Show Available Appraiser', clearable:true, className:"", options:[{label:"No",value:false},{label:"Yes",value:true}]},
    {id:'customSelect',value:state.publicReportShowComplex, updateFunc:(v)=>updateEncodedUrl(v, 'publicReportShowComplex'), width:'3', label:'Show Complex', clearable:true, className:"", options:[{label:"No",value:false},{label:"Yes",value:true}]},
    {id:'customSelect',value:state.publicReportShowSpread, updateFunc:(v)=>updateEncodedUrl(v, 'publicReportShowSpread'), width:'3', label:'Show Spread', clearable:true, className:"", options:[{label:"No",value:false},{label:"Yes",value:true}]},

  ];


  return <div>
    <Modal className="my-modal" isOpen={state.showLegend} toggle={toggleLegend} >
      <ModalHeader hidden={true} toggle={toggleLegend}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-question-circle"></i>&nbsp;Report Information</h5>
        </center>
        <br/>
        <font size="8px">
          <font color="red">*** If the action is taken by the user during non-business hours(Monday through Friday before 9:00 AM and after 5:00 PM and weekend), the system will record the action time using the earliest business hours(bh)/business day(bd) for the TAT calculation. *** </font><br/><br/>
          <b><font color="#FD7F20">Submitted</font> <i className="fa fa-arrow-right"></i> <font color="#006DB0">Completed:</font></b> TAT Calculation that includes <b>everything</b> into account from beginning to the end. It does not exclude the wait time for inspection to happen.<br/><br/>
          <b><font color="#FD7F20">Submitted</font> <i className="fa fa-arrow-right"></i> <font color="#006DB0">Assignment Accepted:</font></b> TAT Calculation that includes everything in between of <b>date submitted</b> and <b>date assignment accepted</b> by appraiser.<br/><br/>
          <b><font color="#FD7F20">Inspection Completed</font> <i className="fa fa-arrow-right"></i> <font color="#006DB0">Completed:</font></b> TAT Calculation that includes everything in between of <b>date inspection completed</b> and date completed when the report was <b>shipped</b>.<br/><br/>
          <b><font color="#FD7F20">Accepted</font> <i className="fa fa-arrow-right"></i> <font color="#006DB0">Inspection Date:</font></b> TAT Calculation that includes everything in between <b>date assignment accepted</b> by appraiser and <b>date of inspection</b>. The wait time for inspection to happen is included.<br/><br/>
          <b><font color="#FD7F20">Submitted</font> <i className="fa fa-arrow-right"></i> <font color="#F26627">Scheduled + Inspection Date</font> <i className="fa fa-arrow-right"></i> <font color="#006DB0"> Completed:</font></b> TAT Calculation that is similar to <b>Submitted - Completed TAT Calculation</b> except it <b>excludes the wait time</b> for inspection to happen.<br/><br/>
          *** All TAT exclude hold and waiting on contact period ***
        </font>
        <br/><br/>
        <center>
          <Button color="info" onClick={toggleLegend}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
    <Card>
      <CardHeader className="header-color">
        <Row>
          <Col sm="6" className="align-left">
            <i className="fa fa-reorder"></i>&nbsp;Turntime Report
          </Col>
          <Col sm="6" className="align-right white-color">
            <NavLink to="#" onClick={toggleLegend}><i className="fa fa-question-circle cursor-link" id="turntimeLegend"/>&nbsp;Report Information</NavLink>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <ReportFilter {...props} configs={reportFiltersConfig}/>
        <UncontrolledTooltip placement='left' width="300px" target='turntimeLegend'>
          <font size="8px">
            <font color="red">Submitted - Completed</font> TAT Calculation include everything into account from beginning to the end. It does not exclude the wait time for inspection to happen.<br/><br/>
            <font color="red">Submitted - Assignment</font> Accepted TAT Calculation include everything in between of date submitted and date assignment accepted by appraiser.<br/><br/>
            <font color="red">Inspection Completed - Completed</font> TAT Calculation include everything in between of date inspection completed and date completed when the report was shipped.<br/><br/>
            <font color="red">Accepted - Inspection Date</font> TAT Calculation include everything in between date assignment accepted by appraiser and date of inspection. The wait time for inspection to happen is included.<br/><br/>
            <font color="red">Submitted - Scheduled + Inspection Date</font> - Completed TAT Calculation is similar to Submitted - Completed TAT Calculation except it exclude the wait time for inspection to happen.<br/><br/>
            *** All TAT exclude hold and waiting on contact period***
          </font>
        </UncontrolledTooltip>
      </CardBody>
    </Card>
    <br/>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Public Report Access
      </CardHeader>
      <CardBody>
        <ReportFilter {...props} configs={reportFiltersConfig2}/>
        <div style={{display:'flex', marginTop:'5px'}}>
          <div style={{flex:'0 1 auto', padding:'5px'}}>
            <i className="fa fa-copy cursor-pointer" style={{fontSize:'30px'}} onClick={(e)=>{
              let tempInput = document.createElement("input");
              tempInput.value = "https://portal.homevms.com/report/turntime/"+state.encodedUrl;
              document.body.appendChild(tempInput);
              tempInput.select();
              document.execCommand("copy");
              document.body.removeChild(tempInput);
            }}/>
          </div>
          <div style={{flex:1}}>
            <Input type="text" value={"https://portal.homevms.com/report/turntime/"+state.encodedUrl} onChange={(e)=>{}} ref={inputRef} disabled/>
          </div>
        </div>
      </CardBody>
    </Card>
    <br/>
    <Card>
      <CardHeader className="header-color cursor-pointer" onClick={()=>setState({showStatesStats:!state.showStatesStats})}>
        <Row>
          <Col sm="8">
            <i className="fa fa-edit"></i>&nbsp;Report Details
          </Col>
          <Col sm="4" className="align-right">
            <i className={(state.showStatesStats===false)?"fa fa-chevron-up":"fa fa-chevron-down"}></i>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Collapse isOpen={state.showStatesStats}>
          <Row>
            <Col sm="8">
              {map}
            </Col>
            <Col sm="4">
              {stats}
              {chart}
              <br/>
              {lineChart}
            </Col>
          </Row>
        </Collapse>
      </CardBody>
    </Card>
    <br/>
    <div className="my-divider">&nbsp;</div>
    <Card>
      <CardHeader className="header-color">
        USA Overall
      </CardHeader>
      <CardBody>
        <Row>
          <Col sm="2">
            <label>Total Volume</label><br/>
            {state.usa.overall_total}
          </Col>
          <Col sm="2">
            <label>Available Appraiser</label><br/>
            {state.usa.appraiser_total}
          </Col>
          <Col sm="2">
            <label>Complex</label><br/>
            {state.usa.total_complex}
          </Col>
          <Col sm="2">
            <label>Spread</label><br/>
            ${state.usa.spread}
          </Col>
        </Row>
        <Row>
          <Col sm="2">
            <label>Overall TAT</label><br/>
            {state.usa.overall_avg_turntime} bd
          </Col>
          <Col sm="2">
            <label>Unclassified</label><br/>
            {state.usa.unknown_avg_turntime} bd
          </Col>
          <Col sm="2">
            <label>Urban</label><br/>
            {state.usa.urban_avg_turntime} bd
          </Col>
          <Col sm="2">
            <label>Suburban</label><br/>
            {state.usa.suburban_avg_turntime} bd
          </Col>
          <Col sm="2">
            <label>Rural</label><br/>
            {state.usa.rural_avg_turntime} bd
          </Col>
        </Row>

      </CardBody>
    </Card>
    <br/>
    <Nav tabs>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.activeTab === '2'?"active":"inactive" )}
          onClick={() => { activeTabToggle('2'); }}
          to="#"
        >
          State Level
        </NavLinkL>
      </NavItem>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.activeTab === '3'?"active":"inactive" )}
          onClick={() => { activeTabToggle('3'); }}
          to="#"
        >
          County Level
        </NavLinkL>
      </NavItem>
    </Nav>

    <TabContent activeTab={state.activeTab}>
      <TabPane tabId="2">
        <div className="align-right" style={{marginBottom:'15px'}}>
          <ExcelFile element={<Button disabled={state.statesStats.length<=0} color="warning">Download Excel</Button>}>
            <ExcelSheet data={state.statesStats} name="XLSX">
                <ExcelColumn label="State" value="state"/>
                <ExcelColumn label="Overall TAT" value="overall_avg_turntime"/>
                <ExcelColumn label="Available Appraiser" value="appraiser_total"/>
                <ExcelColumn label="Unclassified" value="unknown_avg_turntime"/>
                <ExcelColumn label="Urban" value="urban_avg_turntime"/>
                <ExcelColumn label="Suburban" value="suburban_avg_turntime"/>
                <ExcelColumn label="Rural" value="rural_avg_turntime"/>
                <ExcelColumn label="Complex" value="total_complex"/>
                <ExcelColumn label="Total Volume" value="overall_total"/>
            </ExcelSheet>
          </ExcelFile>
        </div>
        <MyReactTable columns={columns2} data={state.statesStats} defaultSorted={[ { id: 'state', desc: false } ]} className="table table-striped"/>
      </TabPane>
      <TabPane tabId="3">
        <div className="align-right" style={{marginBottom:'15px'}}>
          <ExcelFile element={<Button disabled={state.counties.length<=0} color="warning">Download Excel</Button>}>
            <ExcelSheet data={state.counties} name="XLSX">
                <ExcelColumn label="State" value="state"/>
                <ExcelColumn label="County" value="county"/>
                <ExcelColumn label="Overall TAT" value="overall_avg_turntime"/>
                <ExcelColumn label="Available Appraiser" value="appraiser_total"/>
                <ExcelColumn label="Unclassified" value="unknown_avg_turntime"/>
                <ExcelColumn label="Urban" value="urban_avg_turntime"/>
                <ExcelColumn label="Suburban" value="suburban_avg_turntime"/>
                <ExcelColumn label="Rural" value="rural_avg_turntime"/>
                <ExcelColumn label="Complex" value="total_complex"/>
                <ExcelColumn label="Total Volume" value="overall_total"/>
            </ExcelSheet>
          </ExcelFile>
        </div>
        <MyReactTable columns={columns} data={state.counties} defaultSorted={[ { id: 'state', desc: false } ]} className="table table-striped"/>
      </TabPane>
    </TabContent>

    <Modal className="my-modal-wide" isOpen={state.reportDetailsDropDown} toggle={reportDetailsToggle} >
      <ModalHeader hidden={true} toggle={reportDetailsToggle}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-user"></i> Report Details</h5>
        </center>
        <br/>
        <div className="large-scroll-container">
          {content}
        </div>
        <br/>
        <center>
          <Button color="info" onClick={reportDetailsToggle}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
  </div>;
}


export default TurntimeReport;
