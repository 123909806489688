//Author Sooyoung Kim
//Date Aug 22, 2023
import {Card, CardHeader, CardBody, Col, Row, Input} from 'reactstrap';
import {showMessage, generateTooltip} from '../../util/util';
import React, {useEffect} from 'react';
import MyDropzone from '../util/my-dropzone';
import MySelect from '../util/my-select';
import {deletePhoto} from './util';


const Garage  = (props)=>{
  const controller = new AbortController();

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  //function trigger for dropzone react.
  //this function contians two list of files, the accepted and rejected file per the configuration
  const onDrop = (acceptedFiles, rejectedFiles, field, type='Misc', typeFk=0, description='') => {
    let existingFiles = [];
    if(acceptedFiles.length>0){
      for(let i=0;i<acceptedFiles.length;i++){
        existingFiles.push(acceptedFiles[i]);
        let name = existingFiles[i].name;
        let token = name.split('.');
        let extension = '';

        if(token.length>0){
          extension = token[token.length-1];
          extension = extension.toLowerCase();
        }

        if(extension!=='png'&&extension!=='jpg'&&extension!=='jpeg'){
          showMessage('error','Please upload only PNG/JPG image.');
        }
        else{
          const reader = new FileReader();
          reader.onload = () => {
              const fileAsBinaryString = reader.result;
              let base64 = btoa(fileAsBinaryString);
              let garages = props.garages.slice();
              let target = garages[props.index];

              target[field].push({name:acceptedFiles[i].name, base64:base64, status:'Pending', size:acceptedFiles[i].size, type:type, typeFk:typeFk, description:description});

              garages[props.index] = target;
              props.updateState({garages:garages});
          }
          reader.readAsBinaryString(acceptedFiles[i]);
        }
      }
    }
  }

  const removeFile = (field,index) => {
    let garages = props.garages.slice();
    let target = garages[props.index];
    let targetToDelete = target[field][index];

    if(targetToDelete.ID && targetToDelete.ID!==-1){
      let successCallBack = ()=>{
        target[field].splice(index, 1);
        garages[props.index] = target;
        props.updateState({garages:garages});
      };
      successCallBack = successCallBack.bind(this);
      deletePhoto(targetToDelete.ID, props, successCallBack);
    }
    else{
      target[field].splice(index, 1);
      garages[props.index] = target;
      props.updateState({garages:garages});
    }
  }

  const removeField = (field, value) => {
    if(!props.disabled){
      let garages = props.garages.slice();
      let target = garages[props.index];
      let index = target[field].indexOf(value);

      if(index!==-1)
        target[field].splice(index, 1);

      garages[props.index] = target;
      props.updateState({garages:garages});
    }
  }

  const updateField = (field, value) => {
    let garages = props.garages.slice();
    let target = garages[props.index];
    console.log(field)
    console.log(target[field])
    if(value==='None'){
      target[field] = ['None'];
    }
    else if(value!==''){
      let index = target[field].indexOf('None');

      if(index!==-1)
        target[field].splice(index, 1);

      index = target[field].indexOf(value);

      if(index===-1)
        target[field].push(value);
    }
    garages[props.index] = target;
    console.log(garages);
    props.updateState({garages:garages});
  }

  const updateState = (field, value) => {
    let garages = props.garages.slice();
    let target = garages[props.index];

    target[field] = value;

    garages[props.index] = target;

    props.updateState({garages:garages});
  }


  //render
  let disabledStyling = {border:'none', background:'#fff', padding:'0',paddingLeft:'2px', height:'auto',WebkitAppearance:'none'};
  let garageFloorMaterials;

  if(props.garages[props.index].garageFloorMaterials.length>0)
    garageFloorMaterials = props.garages[props.index].garageFloorMaterials.map(
      (garageFloorMaterial, index)=>{
        return(
          <div key={index} className="display-inline margin-top">
            <div className="filter-container" onClick={()=>removeField('garageFloorMaterials', garageFloorMaterial)}>
              <i hidden={props.disabled} className="fa fa-minus red-color"></i> {garageFloorMaterial}
            </div>
            &nbsp;
          </div>
        );
      }
    )

  let garageWallAndCeilingMaterials;

  if(props.garages[props.index].garageWallAndCeilingMaterials.length>0)
    garageWallAndCeilingMaterials = props.garages[props.index].garageWallAndCeilingMaterials.map(
      (garageWallAndCeilingMaterial, index)=>{
        return(
          <div key={index} className="display-inline margin-top">
            <div className="filter-container" onClick={()=>removeField('garageWallAndCeilingMaterials', garageWallAndCeilingMaterial)}>
              <i hidden={props.disabled} className="fa fa-minus red-color"></i> {garageWallAndCeilingMaterial}
            </div>
            &nbsp;
          </div>
        );
      }
    )

  let garageFloorMaterialConditions;

  if(props.garages[props.index].garageFloorMaterialConditions.length>0)
    garageFloorMaterialConditions = props.garages[props.index].garageFloorMaterialConditions.map(
      (garageFloorMaterialCondition, index)=>{
        return(
          <div key={index} className="display-inline margin-top">
            <div className="filter-container" onClick={()=>removeField('garageFloorMaterialConditions', garageFloorMaterialCondition)}>
              <i hidden={props.disabled} className="fa fa-minus red-color"></i> {garageFloorMaterialCondition}
            </div>
            &nbsp;
          </div>
        );
      }
    )

  let garageWallAndCeilingMaterialConditions;

  if(props.garages[props.index].garageWallAndCeilingMaterialConditions.length>0)
    garageWallAndCeilingMaterialConditions = props.garages[props.index].garageWallAndCeilingMaterialConditions.map(
      (garageWallAndCeilingMaterialCondition, index)=>{
        return(
          <div key={index} className="display-inline margin-top">
            <div className="filter-container" onClick={()=>removeField('garageWallAndCeilingMaterialConditions', garageWallAndCeilingMaterialCondition)}>
              <i hidden={props.disabled} className="fa fa-minus red-color"></i> {garageWallAndCeilingMaterialCondition}
            </div>
            &nbsp;
          </div>
        );
      }
    )

  let removeIcon;

  if(props.garages[props.index].removable && !props.disabled)
    removeIcon = <i hidden={props.disabled} className="fa fa-times red-color cursor-pointer" onClick={(e)=>props.removeRoom('garages', props.index)}/>;

  return <div>
    <Card>
      <CardHeader className="header-color">
        {removeIcon} {props.garages[props.index].name}
      </CardHeader>
      <CardBody>
        <Row style={{marginTop:'15px'}}>
          <Col sm="12">
            <label>Garage Name</label>
            <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} type="text" value={props.garages[props.index].name} onChange={(e)=>{updateState('name', e.target.value)}}/>
          </Col>
        </Row>
        <Row style={{marginTop:'15px'}}>
          <Col sm="12">
            <label><font color="red">*</font>Garage Photos</label>
            <br/>
            <MyDropzone hidden={props.disabled} onDrop={(accepted, rejected)=>{onDrop(accepted, rejected, 'garagePhotos', 'Garage Photo', props.garages[props.index].ID)}} noIcon={true}/>
            {
              props.garages[props.index].garagePhotos.map(
                (photo, index)=>{
                  return <div key={index} className="display-inline" style={{width:200, height:250, margin:5}}>
                    <div>
                      <i hidden={props.disabled} className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeFile('garagePhotos',index)}/> {photo.name.length>16?photo.name.substr(0,16)+'...':photo.name}
                    </div>
                    <img alt="pic" width="200" height="200" src={photo.status==='Pending'?"data:image/png;base64, "+photo.base64:photo.presign_url}/>
                  </div>
                }
              )
            }
          </Col>
        </Row>
        <br/>
        <Row style={{marginTop:'15px'}}>
          <Col sm="6">
            <Row style={{marginTop:'15px'}}>
              <Col sm="6">
                <label>{(props.structureType==='Primary Dwelling'||props.structureType==='ADU')?<font color="red">*</font>:null}Interior Floors Material {generateTooltip('garageFloorMaterials'+props.index, 'Select all that apply.')}</label>
                <MySelect
                  type="select"
                  style={props.disabled?disabledStyling:{}}
                  disabled={props.disabled}
                  required={true}
                  value={props.garages[props.index].garageFloorMaterials}
                  onChange={(v)=>updateField('garageFloorMaterials',v)}
                  options={[
                    {label:"",value:""},
                    {label:"Hardwood",value:"Hardwood"},
                    {label:"Carpet",value:"Carpet"},
                    {label:"Tile",value:"Tile"},
                    {label:"Vinyl",value:"Vinyl"},
                    {label:"Laminate",value:"Laminate"},
                    {label:"Finished Concrete",value:"Finished Concrete"},
                    {label:"Unfinished Concrete",value:"Unfinished Concrete"},
                    {label:"Other Wood Product",value:"Other Wood Product"},
                    {label:"Other Composite Product",value:"Other Composite Product"},
                    {label:"Stone",value:"Stone"}
                  ]}
                />
                <div className="extra-small-scroll-container">
                  {garageFloorMaterials}
                </div>
              </Col>
              <Col sm="6">
                <label>{(props.structureType==='Primary Dwelling'||props.structureType==='ADU')?<font color="red">*</font>:null}Interior Floors Material Condition {generateTooltip('garageFloorMaterialConditions'+props.index, 'Select all that apply.')}</label>
                <MySelect
                  type="select"
                  style={props.disabled?disabledStyling:{}}
                  disabled={props.disabled}
                  required={(props.structureType==='Primary Dwelling'||props.structureType==='ADU') && props.garages[props.index].garageFloorMaterialConditions.length<=0}
                  value={props.garages[props.index].garageFloorMaterialConditions}
                  onChange={(v)=>updateField('garageFloorMaterialConditions',v)}
                  options={[
                    {label:"",value:""},
                    {label:"None",value:"None"},
                    {label:"Buckled",value:"Buckled"},
                    {label:"Holes",value:"Holes"},
                    {label:"Sagging",value:"Sagging"},
                    {label:"Spongy",value:"Spongy"},
                    {label:"Infestation",value:"Infestation"},
                    {label:"Dry Rot",value:"Dry Rot"},
                    {label:"Discloration",value:"Discloration"},
                    {label:"Cracks",value:"Cracks"},
                    {label:"Unfinished Section",value:"Unfinished Section"},
                    {label:"Dampness",value:"Dampness"}
                  ]}
                />
                <div className="extra-small-scroll-container">
                  {garageFloorMaterialConditions}
                </div>
              </Col>
            </Row>
            <Row style={{marginTop:'15px'}}>
              <Col sm="6">
                <label>{(props.structureType==='Primary Dwelling'||props.structureType==='ADU')?<font color="red">*</font>:null}Interior Floors Updates</label>
                <MySelect
                  type="select"
                  style={props.disabled?disabledStyling:{}}
                  disabled={props.disabled}
                  required={(props.structureType==='Primary Dwelling'||props.structureType==='ADU')}
                  value={props.garages[props.index].garageFloorUpdates}
                  onChange={(v)=>updateState('garageFloorUpdates',v)}
                  options={[
                    {label:"",value:""},
                    {label:"Fully Updated",value:"Fully Updated"},
                    {label:"Partially Updated",value:"Partially Updated"},
                    {label:"No Update",value:"No Update"},
                  ]}
                />
              </Col>
              <Col sm="6">
                <label>{(props.garages[props.index].garageFloorUpdates==='Fully Updated'||props.garages[props.index].garageFloorUpdates==='Partially Updated')?<font color="red">*</font>:null}Interior Floors Update Description</label>
                <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={(props.garages[props.index].garageFloorUpdates==='Fully Updated'||props.garages[props.index].garageFloorUpdates==='Partially Updated')} type="text" value={props.garages[props.index].garageFloorUpdateDescription} onChange={(e)=>{updateState('garageFloorUpdateDescription',e.target.value)}}/>
              </Col>
            </Row>
            <br/>
            <Row style={{marginTop:'15px'}}>
              <Col sm="12">
                <label>{props.garages[props.index].garageFloorMaterialConditions.indexOf('None')===-1?<font color="red">*</font>:null}Interior Floors Photos</label>
                <br/>
                <MyDropzone hidden={props.disabled} onDrop={(accepted, rejected)=>{onDrop(accepted, rejected, 'garageFloorMaterialPhotos', 'Garage Floor', props.garages[props.index].ID)}} noIcon={true}/>
                {
                  props.garages[props.index].garageFloorMaterialPhotos.map(
                    (photo, index)=>{
                      return <div key={index} className="display-inline" style={{width:200, height:250, margin:5}}>
                        <div>
                          <i hidden={props.disabled} className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeFile('garageFloorMaterialPhotos',index)}/> {photo.name.length>16?photo.name.substr(0,16)+'...':photo.name}
                        </div>
                        <img alt="pic" width="200" height="200" src={photo.status==='Pending'?"data:image/png;base64, "+photo.base64:photo.presign_url}/>
                      </div>
                    }
                  )
                }
              </Col>
            </Row>
          </Col>
          <Col sm="6" style={{borderLeft:'1px solid #d2d2d2'}}>
            <Row style={{marginTop:'15px'}}>
              <Col sm="6">
                <label>{(props.structureType==='Primary Dwelling'||props.structureType==='ADU')?<font color="red">*</font>:null}Interior Wall & Ceiling Material {generateTooltip('garageWallAndCeilingMaterials'+props.index, 'Select all that apply.')}</label>
                <MySelect
                  type="select"
                  style={props.disabled?disabledStyling:{}}
                  disabled={props.disabled}
                  required={(props.structureType==='Primary Dwelling'||props.structureType==='ADU') && props.garages[props.index].garageWallAndCeilingMaterials.length<=0}
                  value={props.garages[props.index].garageWallAndCeilingMaterials}
                  onChange={(v)=>updateField('garageWallAndCeilingMaterials',v)}
                  options={[
                    {label:"",value:""},
                    {label:"Drywall",value:"Drywall"},
                    {label:"Plaster",value:"Plaster"},
                    {label:"Wood",value:"Wood"},
                    {label:"Concrete",value:"Concrete"},
                    {label:"Brick",value:"Brick"},
                    {label:"Metal",value:"Metal"},
                    {label:"None",value:"None"},
                  ]}
                />
                <div className="extra-small-scroll-container">
                  {garageWallAndCeilingMaterials}
                </div>
              </Col>
              <Col sm="6">
                <label>{(props.structureType==='Primary Dwelling'||props.structureType==='ADU')?<font color="red">*</font>:null}Interior Wall & Ceiling Material Condition {generateTooltip('garageWallAndCeilingMaterialConditions'+props.index, 'Select all that apply.')}</label>
                <MySelect
                  type="select"
                  style={props.disabled?disabledStyling:{}}
                  disabled={props.disabled}
                  required={(props.structureType==='Primary Dwelling'||props.structureType==='ADU') && props.garages[props.index].garageWallAndCeilingMaterialConditions.length<=0}
                  value={props.garages[props.index].garageWallAndCeilingMaterialConditions}
                  onChange={(v)=>updateField('garageWallAndCeilingMaterialConditions',v)}
                  options={[
                    {label:"",value:""},
                    {label:"None",value:"None"},
                    {label:"Discloration",value:"Discloration"},
                    {label:"Missing",value:"Missing"},
                    {label:"Crumbling",value:"Crumbling"},
                    {label:"Water Damage",value:"Water Damage"},
                  ]}
                />
                <div className="extra-small-scroll-container">
                  {garageWallAndCeilingMaterialConditions}
                </div>
              </Col>
            </Row>
            <Row style={{marginTop:'15px'}}>
              <Col sm="6">
                <label>{(props.structureType==='Primary Dwelling'||props.structureType==='ADU')?<font color="red">*</font>:null}Interior Wall & Ceiling Updates</label>
                <MySelect
                  type="select"
                  style={props.disabled?disabledStyling:{}}
                  disabled={props.disabled}
                  required={(props.structureType==='Primary Dwelling'||props.structureType==='ADU')}
                  value={props.garages[props.index].garageWallAndCeilingUpdates}
                  onChange={(v)=>updateState('garageWallAndCeilingUpdates',v)}
                  options={[
                    {label:"",value:""},
                    {label:"Fully Updated",value:"Fully Updated"},
                    {label:"Partially Updated",value:"Partially Updated"},
                    {label:"No Update",value:"No Update"},
                  ]}
                />
              </Col>
              <Col sm="6">
                <label>{(props.garages[props.index].garageWallAndCeilingUpdates==='Fully Updated'||props.garages[props.index].garageWallAndCeilingUpdates==='Partially Updated')?<font color="red">*</font>:null}Interior Wall & Ceiling Update Description</label>
                <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={(props.garages[props.index].garageWallAndCeilingUpdates==='Fully Updated'||props.garages[props.index].garageWallAndCeilingUpdates==='Partially Updated')} type="text" value={props.garages[props.index].garageWallAndCeilingUpdateDescription} onChange={(e)=>{updateState('garageWallAndCeilingUpdateDescription',e.target.value)}}/>
              </Col>
            </Row>

            <br/>
            <Row style={{marginTop:'15px'}}>
              <Col sm="12">
                <label>{props.garages[props.index].garageWallAndCeilingMaterialConditions.indexOf('None')===-1?<font color="red">*</font>:null}Interior Wall & Ceiling Photos</label>
                <br/>
                <MyDropzone hidden={props.disabled} onDrop={(accepted, rejected)=>{onDrop(accepted, rejected, 'garageWallAndCeilingMaterialPhotos', 'Garage Wall Ceiling', props.garages[props.index].ID)}} noIcon={true}/>
                {
                  props.garages[props.index].garageWallAndCeilingMaterialPhotos.map(
                    (photo, index)=>{
                      return <div key={index} className="display-inline" style={{width:200, height:250, margin:5}}>
                        <div>
                          <i hidden={props.disabled} className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeFile('garageWallAndCeilingMaterialPhotos',index)}/> {photo.name.length>16?photo.name.substr(0,16)+'...':photo.name}
                        </div>
                        <img alt="pic" width="200" height="200" src={photo.status==='Pending'?"data:image/png;base64, "+photo.base64:photo.presign_url}/>
                      </div>
                    }
                  )
                }
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm="4">
            <label><font color="red">*</font>Number Of Space For Car</label>
            <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={true} type="text" value={props.garages[props.index].garageNumberOfSpaceForCar} onChange={(e)=>{updateState('garageNumberOfSpaceForCar',e.target.value)}}/>
          </Col>
          <Col sm="4">
            <label><font color="red">*</font>Built In Indicator</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={true}
              value={props.garages[props.index].garageBuiltIn}
              onChange={(v)=>updateState('garageBuiltIn',v)}
              options={[
                {label:"",value:""},
                {label:"Yes",value:"True"},
                {label:"No",value:"False"},
              ]}
            />
          </Col>
          <Col sm="4">
            <label><font color="red">*</font>Sqft</label>
            <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={true} type="number" value={props.garages[props.index].garageSqft} onChange={(e)=>{updateState('garageSqft',e.target.value)}}/>
          </Col>
        </Row>
      </CardBody>
    </Card>
  </div>;
}


export default Garage;
