//Author Sooyoung Kim
//Date Nove 17, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, putAPICallGenerator, callBackGenerator, formatNumber, getFromTo} from '../../util/util';
import {Button, Col, Row, Table} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import DatePicker from 'react-datepicker';
import {NavLink} from 'react-router-dom';
import Toggle from 'react-toggle';
import moment from 'moment';

//initialize the state
const initialState = {
  lenderStats:[],
  from:getFromTo('from'),
  to:getFromTo('to'),
};

//reducer function that perform state update
const reducer = getReducer();


const AutoAssignDashboard  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getLenderStats();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //API call
  //get state stats
  const getLenderStats = () => {
    let callBack = apiCallBack([{state:'lenderStats', key:'data'}]);
    httpGet('autoAssign/lender/stats/get/from='+state.from+'&to='+state.to, '', 'Oops, something went wrong and could not load auto assign stats. Please try again later.', callBack);
  }

  const handleActivation = (clientFk) => {
    let lenderStats = state.lenderStats.slice();

    for(let i=0;i<lenderStats.length;i++){
      if(lenderStats[i].client_fk===clientFk){
        let parameters = [
          {
            field:'clientFk',
            value:clientFk
          }
        ];

        let callBack = (response)=>{
          let code = response.data.code;
          if(code==='00'){
            if(lenderStats[i].activation==='yes'){
              lenderStats[i].activation='no';
            }else{
              lenderStats[i].activation='yes';
            }
          }

          setState({lenderStats:lenderStats});
        };
        callBack = callBack.bind(this);

        httpPut('autoAssign/activation', parameters, 'Auto assign activation updated.', 'Oops, something went wrong and cannot update the activation. Please try again later.', callBack);
        break;
      }
    }
  }

  //render
  let from, to;
  if(state.from!=='')
    from = moment(state.from).toDate();
  if(state.to!=='')
    to = moment(state.to).toDate();

  let lenderStats;
  if(state.lenderStats.length>0){
    lenderStats = state.lenderStats.map(
      (lenderStat,index)=>{

        return(
          <tr key={index}>
            <td><div className="display-inline">
              <Toggle
                checked={lenderStat.activation==='yes'}
                icons={false}
                onChange={(e)=>handleActivation(lenderStat.client_fk)} />
            </div></td>
            <td><NavLink target="_blank" to={"/auto-assign/client/"+lenderStat.client_fk+"/"+state.from+"/"+state.to}>{lenderStat.lender}</NavLink></td>
            <td>{lenderStat.avg_accepting}</td>
            <td>{formatNumber(lenderStat.success)}</td>
            <td>{lenderStat.success_rate}%</td>
            <td>{formatNumber(lenderStat.auto_assign_count)}</td>
            <td>{formatNumber(lenderStat.count)}</td>
            <td>{formatNumber(lenderStat.total_appraiser)}</td>
            <td>{lenderStat.coverage}%</td>
          </tr>
        );
      }
    );
  }

  return <div className="my-well">
    <Row>
      <Col sm="6">
        <div className="page-title">
          <i className="fa fa-reorder"></i>&nbsp;Auto Assign Dashboard
        </div>
      </Col>
      <Col sm="6" className="align-right">
        <NavLink to="/system-setting/auto-assign">Go to auto assign settings</NavLink>
      </Col>
    </Row>
    <br/>

    <Row>
      <Col sm="3">
        <b>From</b>
        <br/>
        <DatePicker
          className="form-control"
          dateFormat="yyyy/MM/dd"
          selected={from}
          onChange={(text)=>{(text)&&setState({from:text.toLocaleDateString('en-CA')})}}
        />
      </Col>
      <Col sm="2">
        <b>To</b>
        <br/>
        <DatePicker
          className="form-control"
          dateFormat="yyyy/MM/dd"
          selected={to}
          onChange={(text)=>{(text)&&setState({to:text.toLocaleDateString('en-CA')})}}
        />
      </Col>
      <Col sm="7" className="align-right">
        <br/>
        <Button color="warning" onClick={(e)=>getLenderStats()}>Submit</Button>
      </Col>
    </Row>
    <div className="my-divider"></div>

    <Table className="table table-striped">
      <thead>
        <tr>
          <th width="5%">Activation</th>
          <th width="21%">Client</th>
          <th width="12%">Turnaround</th>
          <th width="10%">Success</th>
          <th width="13%">Success Rate</th>
          <th width="13%">Processed</th>
          <th width="13%">Total Order</th>
          <th width="10%">Appraiser Enrolled</th>
          <th width="13%">Coverage</th>
        </tr>
      </thead>
      <tbody>
        {lenderStats}
      </tbody>
    </Table>
  </div>;
}


export default AutoAssignDashboard;
