//Author Sooyoung Kim
//Date April 24, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, callBackGenerator, formatDateTime} from '../../util/util';
import {Button, Col, Modal, ModalHeader, ModalBody, Row, Input, Card, CardBody, CardHeader} from 'reactstrap';
import MyXEditable from '../util/my-xeditable';
import {NavLink} from 'react-router-dom';
import MyReactTable from '../util/my-react-table';
import '../util/my-confirmation.css';
import React, {useReducer, useEffect} from 'react';


//initialize the state
const initialState = {
  thirdPatyList:[],
  userPassword:'',
  selectedID:'',
  changePasswordMessage:'',
  showPassword:false,
  passwordPopUpOpen:false,
};

//reducer function that perform state update
const reducer = getReducer();


const IntegratedPlatform  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);
  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getIntegratedPlatforms();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const togglePasswordPopUp = () =>{
    if(!state.passwordPopUpOpen){
      setState({showPassword:false});
    }
    setState({passwordPopUpOpen:!state.passwordPopUpOpen});
  }

  const updateFieldCallBack = (ID, field, value) =>{
    let thirdPatyList = state.thirdPatyList.slice();
    for(let i=0;i<thirdPatyList.length;i++){
      if(thirdPatyList[i].ID===ID){
        thirdPatyList[i][field] = value;
        break;
      }
    }
    setState({thirdPatyList:thirdPatyList});
  }

  //API call
  const getIntegratedPlatforms = () =>{
    let url = 'thirdParty/get';
    let callBack = apiCallBack([{state:'thirdPatyList', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load third party list. Please try again later.', callBack);
  }

  //generate a function that do the update on editable
  const generateUpdateFunction = (url, parameters, successMessage, failedMessage, parentCallBack=null) =>{
    return (newValue, callBack, errorCallBack)=>{
      //add in the new value into parameters

      parameters.push({field:'value',value:newValue});
      let promise = httpPut(url,parameters,successMessage,failedMessage, callBack, errorCallBack);
      promise.then(
        (response)=>{
          if(parentCallBack!==null){
            let ID;
            let field;
            let value;

            for(let i=0;i<parameters.length;i++){
              if(parameters[i].field==='ID')
                ID = parameters[i].value;
              else if(parameters[i].field==='field')
                field = parameters[i].value;
              else if(parameters[i].field==='value')
                value = parameters[i].value;
            }
            parentCallBack(ID, field, value);
          }
        }
      );
    }
  };

  const verifyPassword = (ID) =>{
    let url = 'thirdParty/password/check';
    let successMsg = 'Password revealed successfully';
    let errorMsg = 'Oops, something went wrong and could not show the password. Please try again later.';

    let parameters = [
      {
        field:'password',
        value:state.userPassword
      },
      {
        field:'partyId',
        value:state.selectedID
      }
    ];

    let callBack = (response)=>{
      let code= response.data.code;

      if(code==='00'){
        let thirdPatyList = state.thirdPatyList.slice();
        for(let i=0;i<thirdPatyList.length;i++){
          if(thirdPatyList[i].ID===ID){
            thirdPatyList[i] = response.data.data;
            break;
          }
        }
        setState({showPassword:true, thirdPatyList:thirdPatyList});

        togglePasswordPopUp();
      }
    };
    callBack = callBack.bind(this);

    httpPost(url, parameters, successMsg, errorMsg, callBack);
  }

  //render
  const columns = [
    {
      id: 'company',
      Header: 'Company',
      minWidth:120,
      width:120,
      maxWidth: 120,
      accessor: d => d.companyName,
      Cell: props => <a className="display-inline" href={props.row.original.portal_url} target="_blank" rel="noreferrer"><b>{props.row.original.companyName}</b></a>,
      className: 'wordwrap',
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'link',
      Header: 'Link',
      accessor: d => d.portal_url,
      Cell: props => <MyXEditable
          type="textarea"
          passiveTextColor="#1B53B3"
          prefix=""
          value={props.row.original.portal_url}
          updateFunc={
            generateUpdateFunction('thirdParty/update',[{field:'field',value:'portal_url'},{field:'ID',value:props.row.original.ID}],'Url successfully updated.', 'Update url failed, please try again later.', updateFieldCallBack)
          }
       />,
      className: 'wordwrap',
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'username',
      Header: 'Username',
      minWidth:120,
      width:120,
      maxWidth: 120,
      accessor: d => d.portal_username,
      Cell: props => <MyXEditable
          type="textarea"
          passiveTextColor="#1B53B3"
          prefix=""
          value={props.row.original.portal_username}
          updateFunc={
            generateUpdateFunction('thirdParty/update',[{field:'field',value:'portal_username'},{field:'ID',value:props.row.original.ID}],'Username successfully updated.', 'Update username failed, please try again later.', updateFieldCallBack)
          }
       />,
      className: 'wordwrap',
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'password',
      Header: 'Password',
      minWidth:120,
      width:120,
      maxWidth: 120,
      accessor: d => d.portal_password,
      Cell: props => {
        let view = <NavLink to="#" style={{'fontSize':'14px'}} onClick={(e)=>setState({selectedID:props.row.original.ID}, togglePasswordPopUp())}>View Password</NavLink>;

        if(state.selectedID === props.row.original.ID && state.showPassword){
          view = <MyXEditable
              type="textarea"
              passiveTextColor="#1B53B3"
              prefix=""
              value={props.row.original.portal_password}
              updateFunc={
                generateUpdateFunction('thirdParty/update',[{field:'field',value:'portal_password'},{field:'ID',value:props.row.original.ID}],'Password successfully updated.', 'Update password failed, please try again later.', updateFieldCallBack)
              }
           />;
        }

        return(
          <div>
            {view}
          </div>
        )
      },
      className: 'wordwrap',
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'general',
      Header: 'General Note',
      width:140,
      maxWidth: 140,
      accessor: d => d.general_note,
      Cell: props => <MyXEditable
          type="textarea"
          passiveTextColor="#1B53B3"
          prefix=""
          value={props.row.original.general_note}
          updateFunc={
            generateUpdateFunction('thirdParty/update',[{field:'field',value:'general_note'},{field:'ID',value:props.row.original.ID}],'General note updated.', 'Update general note failed, please try again later.', updateFieldCallBack)
          }
       />,
      className: 'wordwrap',
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'assignment',
      Header: 'Assignment Note',
      width:140,
      maxWidth: 140,
      accessor: d => d.assignment_note,
      Cell: props => <MyXEditable
          type="textarea"
          passiveTextColor="#1B53B3"
          prefix=""
          value={props.row.original.assignment_note}
          updateFunc={
            generateUpdateFunction('thirdParty/update',[{field:'field',value:'assignment_note'},{field:'ID',value:props.row.original.ID}],'Assignment note updated.', 'Update assginment note failed, please try again later.', updateFieldCallBack)
          }
       />,
      className: 'wordwrap',
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'billing',
      Header: 'Billing Note',
      width:140,
      maxWidth: 140,
      accessor: d => d.billing_note,
      Cell: props => <MyXEditable
          type="textarea"
          passiveTextColor="#1B53B3"
          prefix=""
          value={props.row.original.billing_note}
          updateFunc={
            generateUpdateFunction('thirdParty/update',[{field:'field',value:'billing_note'},{field:'ID',value:props.row.original.ID}],'Billing note updated.', 'Update billing note failed, please try again later.', updateFieldCallBack)
          }
       />,
      className: 'wordwrap',
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'review',
      Header: 'Review Note',
      width:140,
      maxWidth: 140,
      accessor: d => d.review_note,
      Cell: props => <MyXEditable
          type="textarea"
          passiveTextColor="#1B53B3"
          prefix=""
          value={props.row.original.review_note}
          updateFunc={
            generateUpdateFunction('thirdParty/update',[{field:'field',value:'review_note'},{field:'ID',value:props.row.original.ID}],'Review note updated.', 'Update review note failed, please try again later.', updateFieldCallBack)
          }
       />,
      className: 'wordwrap',
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'shipping',
      Header: 'Shipping Note',
      width:140,
      maxWidth: 140,
      accessor: d => d.shipping_note,
      Cell: props => <MyXEditable
          type="textarea"
          passiveTextColor="#1B53B3"
          prefix=""
          value={props.row.original.shipping_note}
          updateFunc={
            generateUpdateFunction('thirdParty/update',[{field:'field',value:'shipping_note'},{field:'ID',value:props.row.original.ID}],'Shipping note updated.', 'Update shipping note failed, please try again later.', updateFieldCallBack)
          }
       />,
      className: 'wordwrap',
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'conditionrebuttal',
      Header: 'Condition + Rebuttal',
      width:140,
      maxWidth: 140,
      accessor: d => d.condition_rebuttal_note,
      Cell: props => <MyXEditable
          type="textarea"
          passiveTextColor="#1B53B3"
          prefix=""
          value={props.row.original.condition_rebuttal_note}
          updateFunc={
            generateUpdateFunction('thirdParty/update',[{field:'field',value:'condition_rebuttal_note'},{field:'ID',value:props.row.original.ID}],'Condition Rebuttal note updated.', 'Update Condition Rebuttal note failed, please try again later.', updateFieldCallBack)
          }
       />,
      className: 'wordwrap',
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'lastModified',
      Header: 'Last Modified By',
      accessor: d => d.last_modified_by,
      className: 'wordwrap',
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'lastModifiedDate',
      Header: 'Last Modified Date',
      accessor: d => d.last_modified_date,
      Cell: props=> <div>{formatDateTime(props.row.original.last_modified_date)}</div>,
      className: 'wordwrap',
      headerStyle: {
        textAlign:'left'
      }
    },
  ];

  return <div className="my-well">
    <Modal className="confirmation-dialog" isOpen={state.passwordPopUpOpen} toggle={togglePasswordPopUp} >
      <ModalHeader className="my-confirmation-title" toggle={togglePasswordPopUp}><i className="fa fa-warning"></i>Enter your account password</ModalHeader>
      <ModalBody>
        {(state.changePasswordMessage!=='')&&<div style={{height:'30px'}}>
          <label><font color="red">{state.changePasswordMessage}</font></label>
        </div>}
        <br/>
        <label>Password:</label><br/>
        <Input required={true} type="text" value={state.userPassword} onChange={(e)=>setState({userPassword:e.target.value})}/>
        <br/>
        <center>
          <Button color="warning" onClick={(e)=>{verifyPassword(state.selectedID)}}>Submit</Button>&nbsp;
          <Button color="info" onClick={togglePasswordPopUp}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
    <Row>
      <Col sm="6">
        <div className="page-title">
          <i className="fa fa-reorder"></i>&nbsp;Integrated Platform List
        </div>
        <NavLink to="/system-setting">Back to settings</NavLink>
      </Col>
      <Col sm="6">

      </Col>
    </Row>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-tasks"></i> Integrated Platforms
      </CardHeader>
      <CardBody>
        <div className="overflow-auto">
          <MyReactTable columns={columns} data={state.thirdPatyList} className="table table-striped"/>
        </div>
      </CardBody>
    </Card>
  </div>;
}

export default IntegratedPlatform;
