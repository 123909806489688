//Author Sooyoug Kim
//Date Sep 5, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, deleteAPICallGenerator, callBackGenerator, formatDateTime, formatNumber, showMessage, confirmation, sliceFromArray} from '../../util/util';
import {Button, Card, CardHeader, CardBody, Row, Col, Table, Input} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import {NavLink, useNavigate, useParams} from 'react-router-dom';
import MyXEditable from '../util/my-xeditable';
import MyDropzone from '../util/my-dropzone';
import FileSaver from 'file-saver';

//initialize the state
const initialState = {
  registration:{},
  notes:[],
  files:[],
  newNote:'',
  toUploadFiles:[],
  uploadDisabled:false,
  errorMessage:'',
};

//reducer function that perform state update
const reducer = getReducer();


const StateRegistration  = (props)=>{
  const controller = new AbortController();
  const history = useNavigate();
  let {id} = useParams();

  let newInitialState = Object.assign({}, initialState, {id:id});
  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getStateRegistration();
    getStateRegistrationNote();
    getStateRegistrationFile();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const updateFieldCallBack = (field, value) => {
    console.log(field, value);
    let registration = Object.assign({}, state.registration);
    registration[field] = value;
    setState({registration:registration});
  }

  //function trigger for dropzone react.
  //this function contians two list of files, the accepted and rejected file per the configuration
  const onDrop = (acceptedFiles, rejectedFiles) => {
    let existingFiles = state.toUploadFiles.slice();

    for(let i=0;i<acceptedFiles.length;i++){
      let file = acceptedFiles[i];
      file.status = 'Pending';
      file.fileType = '';

      let duplicate = false;
      for(let j=0;j<state.toUploadFiles.length;j++){
        if(state.toUploadFiles[j].name===acceptedFiles[i].name){
          duplicate = true;
          setState({errorMessage:'Duplicate file name "'+acceptedFiles[i].name+'"'})
        }
      }
      if(!duplicate)
        existingFiles.push(file);
    }
    setState({toUploadFiles: existingFiles});
  }

  const uploadButtonHandler = () => {
    if(state.uploadDisabled)
      return;

    setState({uploadDisabled:true});
    uploadAll();
  }

  //remove a specific file from the toUpload list.
  const removeToUploadFile = (preview) => {
    let toRemoveIndex = -1;
    for(let i=0;i<state.toUploadFiles.length;i++){
      if(state.toUploadFiles[i].preview===preview){
        toRemoveIndex = i;
        break;
      }
    }

    if(toRemoveIndex!==-1){
      let newFiles = state.toUploadFiles.slice();
      newFiles.splice(toRemoveIndex,1);

      setState({toUploadFiles:newFiles});
    }
  }

  const formatFileSize = (size) => {
    let intSize = parseInt(size,10);

    if(intSize>=1000000.00)
      return formatNumber(Math.round((intSize*10/1000000))/10)+' MB';
    else if(intSize>=1000)
      return formatNumber(Math.round((intSize*10/1000))/10)+' KB';
    else
      return formatNumber(intSize)+' B';

  }

  const deepCopyFileObject = (file) => {
    let newFile = new File([file],file.name);
    newFile.preview = file.preview;
    newFile.fileType = file.fileType;
    newFile.status = file.status;

    return newFile
  }

  //API call
  //generate a function that do the update on editable
  const generateUpdateFunction = (url, parameters, successMessage, failedMessage, parentCallBack=null) => {
    return (newValue, callBack, errorCallBack)=>{
      //add in the new value into parameters

      parameters.push({field:'value',value:newValue});
      let promise = httpPut(url,parameters,successMessage,failedMessage, callBack, errorCallBack);
      promise.then(
        (response)=>{
          let field;
          let value;

          for(let i=0;i<parameters.length;i++){
            if(parameters[i].field==='field')
              field = parameters[i].value;
            else if(parameters[i].field==='value')
              value = parameters[i].value;
          }
          if(parentCallBack!==null){
            parentCallBack(field, value);
          }
        }
      );
    }
  };

  const getStateRegistration = () => {
    let callBack = apiCallBack([{state:'registration', key:'data'}]);
    httpGet('stateRegistration/'+state.id, '', 'Oops, something went wrong and could not load list of state registration. Please try again later.', callBack);
  }

  const getStateRegistrationFile = () => {
    let callBack = apiCallBack([{state:'files', key:'data'}]);
	  httpGet('stateRegistration/file/'+state.id, '', 'Oops, something went wrong and could not load list of state registration. Please try again later.', callBack);
  }

  const getStateRegistrationNote = () => {
    let callBack = apiCallBack([{state:'notes', key:'data'}]);
    httpGet('stateRegistration/note/'+state.id, '', 'Oops, something went wrong and could not load list of state registration. Please try again later.', callBack);
  }

  const downloadFile = (name) => {
    let callBack = (response)=>{
      console.log(response);
      let code = response.data?response.data.code:undefined;

      if(code==='00'){
        let byteCharacters = atob(response.data.data);
        let byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);
        let data = new Blob([byteArray]);
        FileSaver.saveAs(data, name);
      }
    };

    let parameters = [
      {
        field:'parentFk',
        value:state.id
      },
      {
        field:'name',
        value:name
      },
      {
        field:'directory',
        value:'StateRegistration'
      }
    ];

    httpPost('stateRegistration/download', parameters, '', 'Oops, something went wrong and could not download the file "'+name+'". Please try again later.', callBack);
  }

  const createNewNote = () => {
    let parameters = [
      {
        field:'ID',
        value:state.id
      },
      {
        field:'note',
        value:state.newNote
      }
    ];

    let callBack = apiCallBack([{state:'notes', arraykey:'data', targetArray:state.notes}]);
    httpPost('stateRegistration/note/create', parameters, '', 'Oops, something went wrong and could not create the note. Please try again later.', callBack);
  }


  const deleteNote = (id) => {
    let list = sliceFromArray(state.notes,'ID', id);
    let callBack = apiCallBack([{state:'notes', value:list}]);

    httpDelete('stateRegistration/note/'+id,'Note deleted.','Oops, something went wrong and could not delete this note. Please try again later.', callBack);
  }

  const deleteRegistration = (id) => {
    let callBack = apiCallBack([], history('/state-registration'));
    httpDelete('stateRegistration/'+id,'Registration deleted.','Oops, something went wrong and could not delete this registration. Please try again later.', callBack);
  }

  const deleteFile = (id) => {
    let list = sliceFromArray(state.files,'ID', id);
    let callBack = apiCallBack([{state:'files', value:list}]);

    httpDelete('stateRegistration/file/'+id,'File deleted.','Oops, something went wrong and could not delete this file. Please try again later.', callBack);
  }

  const uploadAll = () => {
    if(state.toUploadFiles.length<=0){
      setState({errorMessage:'Please upload at least one file.'});
      setTimeout(()=>setState({uploadDisabled:false}),1000);
    }
    else{
      setState({errorMessage:'Uploading...Please do not close the window.'});

      let fileCompleted = 0;
      for(let i=0;i<state.toUploadFiles.length;i++){
        //skip file that has done upload
        if(state.toUploadFiles[i].status==='Done')
          continue;
        const reader = new FileReader();
        // eslint-disable-next-line no-loop-func
        reader.onload = () => {
            const fileAsBinaryString = reader.result;
            let base64 = btoa(fileAsBinaryString);

            let callBack = (response)=>{console.log(response);
              let code = response.data?response.data.code:undefined;
              if(code==='00'){
                //copy the array so we maintaining the immutable state
                let newToUploadFiles = [];

                //deep copy the entire file object array
                for(let j=0;j<state.toUploadFiles.length;j++){
                  let newFile = deepCopyFileObject(state.toUploadFiles[j]);
                  if(newFile.name===state.toUploadFiles[i].name)
                    newFile.status='Done';

                  newToUploadFiles.push(newFile);
                }

                setState({toUploadFiles:newToUploadFiles});

                //update the list of uploaded file
                let files = state.files.slice();
                files.push(response.data.data);

                setState({files:files});
              }
              fileCompleted++;
              if(fileCompleted>=state.toUploadFiles.length){
                setState({uploadDisabled:false, errorMessage:''});
              }
            };
            callBack = callBack.bind(this);

            let errorCallBack = (error)=>{
              fileCompleted++;

              if(fileCompleted>=state.toUploadFiles.length){
                setState({uploadDisabled:false, errorMessage:''});
              }
            };
            errorCallBack = errorCallBack.bind(this);

            let parameters = [
              {
                field:'ID',
                value:state.id
              },
              {
                field:'file_encoded',
                value:base64
              },
              {
                field:'name',
                value:state.toUploadFiles[i].name
              },
            ];

            console.log(parameters);

            httpPost('stateRegistration/file/upload', parameters, 'File "'+state.toUploadFiles[i].name+'" uploaded successfully.', 'Oops, something went wrong and could not upload the file "'+state.toUploadFiles[i].name+'". Please try again later.', callBack, errorCallBack);
        };
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => {
          showMessage('error','File upload failed, please try again later.');
        };

        reader.readAsBinaryString(state.toUploadFiles[i]);
      }
      setState({uploadDisabled:false});
    }
  }

  //render
  let successMessage = 'State registration information updated.';
  let failedMessage = 'Failed to update the value of this field, please try again later.';


  let toUploadFiles;

  if(state.toUploadFiles.length>0){
    toUploadFiles = state.toUploadFiles.map(
      (file,index)=>{
        return(
          <tr key={index}>
            <td>{file.name}</td>
            <td>{formatFileSize(file.size)}</td>
            <td><center>{file.status}</center></td>
            <td><center><i className="fa fa-times red-color cursor-pointer" onClick={()=>removeToUploadFile(file.preview)}></i></center></td>
          </tr>
        );
      }
    );
  }
  
  return <div>
    <div className="align-right">
      <NavLink to="#" onClick={
        (e)=>{
          confirmation(
            ()=>{deleteRegistration(state.registration.ID)},
            ()=>{},
            'Delete the registration?',
            'Are you sure you want to delete this registration?');
        }
      }><i className="fa fa-times red-color"></i> Delete this registration</NavLink>
    </div>
    <center>
      <Card>
        <CardHeader className="header-color  cursor-pointer">
          <i className="fa fa-information"></i> {state.registration.state} License Information
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="6">
              <label>Registration Number</label>
            </Col>
            <Col sm="6">
              <MyXEditable
                type="text"
                value={state.registration.registration_number}
                updateFunc={
                  generateUpdateFunction('stateRegistration/update',[{field:'field',value:'registration_number'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                }
              />
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <label>Expiration Date</label>
            </Col>
            <Col sm="6">
              <MyXEditable
                type="date"
                value={state.registration.expiration_date}
                updateFunc={
                  generateUpdateFunction('stateRegistration/update',[{field:'field',value:'expiration_date'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                }
              />
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <label>Bond Required</label>
            </Col>
            <Col sm="6">
              <MyXEditable
                type="select"
                opt={[{key:'yes',value:'Yes'},{key:'no',value:'No'}]}
                value={state.registration.bond_required}
                updateFunc={
                  generateUpdateFunction('stateRegistration/update',[{field:'field',value:'bond_required'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                }
              />
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <label>Bond Amount</label>
            </Col>
            <Col sm="6">
              <MyXEditable
                type="text"
                value={state.registration.bond_amount}
                updateFunc={
                  generateUpdateFunction('stateRegistration/update',[{field:'field',value:'bond_amount'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                }
              />
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <label>Bond Expiration Date</label>
            </Col>
            <Col sm="6">
              <MyXEditable
                type="date"
                value={state.registration.bond_expiration_date}
                updateFunc={
                  generateUpdateFunction('stateRegistration/update',[{field:'field',value:'bond_expiration_date'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                }
              />
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <label>SOS Registration Number</label>
            </Col>
            <Col sm="6">
              <MyXEditable
                type="text"
                value={state.registration.sos_registration_number}
                updateFunc={
                  generateUpdateFunction('stateRegistration/update',[{field:'field',value:'sos_registration_number'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                }
              />
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <label>Trade Name Expiration Date</label>
            </Col>
            <Col sm="6">
              <MyXEditable
                type="date"
                value={state.registration.trade_name_expiration_date}
                updateFunc={
                  generateUpdateFunction('stateRegistration/update',[{field:'field',value:'trade_name_expiration_date'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                }
              />
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <label>Registration Fee</label>
            </Col>
            <Col sm="6">
              <MyXEditable
                type="text"
                value={state.registration.registration_fee}
                updateFunc={
                  generateUpdateFunction('stateRegistration/update',[{field:'field',value:'registration_fee'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                }
              />
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <label>Notify Email</label>
            </Col>
            <Col sm="6">
              <MyXEditable
                type="text"
                value={state.registration.notify_email}
                updateFunc={
                  generateUpdateFunction('stateRegistration/update',[{field:'field',value:'notify_email'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                }
              />
            </Col>
          </Row>
        </CardBody>
      </Card>

      <br/><br/>
      <Card>
        <CardHeader className="header-color  cursor-pointer">
          <i className="fa fa-comments"></i> Notes
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="8" className="align-left">
              <label>Note</label><br/>
              <Input type="text" value={state.newNote} onChange={(e)=>setState({newNote:e.target.value})}/>
            </Col>
            <Col sm="4" className="align-right">
              <br/>
              <Button color="warning" onClick={createNewNote}>Create</Button>
            </Col>
          </Row>
          <div className="my-divider"></div>
          <Table className="table table-striped">
            <thead>
              <tr>
                <th width="5%">Action</th>
                <th width="15%">From</th>
                <th width="65%">Note</th>
                <th width="15%">Date</th>
              </tr>
            </thead>
            <tbody>
              {
                state.notes.map(
                  (note,index)=>{
                    return <tr key={index}>
                      <td><NavLink to="#" onClick={
                        (e)=>{
                          confirmation(
                            ()=>{deleteNote(note.ID)},
                            ()=>{},
                            'Delete note?',
                            'Are you sure you want to delete this note?');
                        }
                      }><i className="fa fa-times red-color"></i></NavLink></td>
                      <td>{note.author}</td>
                      <td><i>{note.note}</i></td>
                      <td>{formatDateTime(note.datetime_created)}</td>
                    </tr>
                  }
                )
              }
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <br/><br/>
      <Card>
        <CardHeader className="header-color  cursor-pointer">
          <i className="fa fa-files-o"></i> Files
        </CardHeader>
        <CardBody>
          <div className="small-scroll-container red-color">
            <b>{state.errorMessage}</b>
          </div>
          <MyDropzone onDrop={onDrop}/>
          <table className="table file-list-table" cellSpacing="0" cellPadding="0">
            <thead>
              <tr>
                <th width="55%">Name</th>
                <th width="15%">Size</th>
                <th width="10%"><center>Status</center></th>
                <th width="10%">Control</th>
              </tr>
            </thead>
            <tbody>
              {toUploadFiles}
            </tbody>
          </table>

          <Button color="warning" className="form-control" disabled={state.uploadDisabled!==false} onClick={uploadButtonHandler}>Upload</Button>
          <br/><br/>
          <div className="my-divider">&nbsp;</div>
          <Table className="table table-striped">
            <thead>
              <tr>
                <th width="20%">From</th>
                <th width="55%">File</th>
                <th width="5%">Action</th>
                <th width="20%">Date</th>
              </tr>
            </thead>
            <tbody>
              {
                state.files.map(
                  (file,index)=>{
                    return <tr key={index}>
                      <td>{file.uploader}</td>
                      <td><NavLink to="#" onClick={(e)=>downloadFile(file.name)}>{file.label_name}</NavLink></td>
                      <td><NavLink to="#" onClick={(e)=>{
                        confirmation(
                          ()=>{deleteFile(file.ID)},
                          ()=>{},
                          'Delete file?',
                          'Are you sure you want to delete this file?');
                      }}><i className="fa fa-times red-color"></i></NavLink></td>
                      <td>{formatDateTime(file.datetime_created)}</td>
                    </tr>
                  }
                )
              }
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </center>
  </div>;
}


export default StateRegistration;
