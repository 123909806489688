//Author author
//Date date
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, formatDate, generateSID, getFromTo, replaceRegex} from '../../../util/util';
import {Button, Card, CardHeader, CardBody, Modal, ModalHeader, ModalBody} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import MyReactTable from '../../util/my-react-table';
import ReportFilter from '../report-filter';
import {NavLink} from 'react-router-dom';
import moment from 'moment';
import '../report.css';

let from = getFromTo('from');
let to = getFromTo('to');

//initialize the state
const initialState = {
  SID: generateSID(),
  loanPurpose:'',
  loanType:'',
  isRush:'',
  isComplex:'',
  state:'',
  city:'',
  county:'',
  entity:'',
  client:'',
  filterAppraisalTypes:[],
  from:from,
  to:to,
  report:[],
  appraiser:'',
  detailsPopUpOpen:false,
  type:'orderCount',
  reportDetails:[]
};

//reducer function that perform state update
const reducer = getReducer();


const AppraiserQuality  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getReport();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const reportDetailsToggle = () =>{
    setState({reportDetails:[], detailsPopUpOpen:!state.detailsPopUpOpen});
  }

  //API call
  const getReport = () =>{
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);
  
    let url = 'report/appraiserQuality/county='+state.county+'&client='+state.client+'&appraiser='+state.appraiser+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&appraisalType='+appraisalType;
    httpGet(url, '', 'Oops, something went wrong and could not load the report. Please try again later.', apiCallBack([{state:'report', key:'data'}]));
  }

  const getReportDetails = (appraiserFk, type) =>{
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);

    let url = 'report/appraiserQuality/details/county='+state.county+'&client='+state.client+'&appraiser='+appraiserFk+'&type='+type+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&appraisalType='+appraisalType;
    httpGet(url, '', 'Oops, something went wrong and could not load the report. Please try again later.', apiCallBack([{state:'type', value:type}, {state:'reportDetails', key:'data'}]));
  }

  //render
  let newfrom, newto;
  if(state.from!=='')
    newfrom = moment(state.from);
  if(state.to!=='')
    newto = moment(state.to);

  let columnsDetails = [
    {
      id: 'referenceNumber',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'loanNum',
      Header: 'Loan #',
      accessor: d => d.loan_num,
      headerStyle: {
         textAlign:'left'
      }
    },
    {
      id: 'borrower',
      Header: 'Borrower',
      accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'property',
      Header: 'Property',
      accessor: d => d.property_street+' '+d.property_city+', '+d.property_state,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'dateSubmitted',
      Header: 'Submitted',
      accessor: d => formatDate(d.datetime_submitted),
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'rebuttal',
      Header: 'Rebuttal Count',
      accessor: d => d.rebuttal_count,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'condition',
      Header: 'Condition Count',
      accessor: d => d.condition_count,
      headerStyle: {
        textAlign:'left'
      }
    },
  ];

  const columns = [
    {
      id: 'appraiserID',
      Header: 'Appraiser',
      accessor: d => d.ID,
      Cell: props => <NavLink target="_blank" to={"/appraiser/"+props.row.original.ID}>{props.row.original.name}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'rebuttal',
      Header: 'Rebuttal Rate',
      accessor: d => d.rebuttal,
      Cell: props => <NavLink to="#" onClick={()=>{reportDetailsToggle();getReportDetails(props.row.original.ID, 'rebuttal')}}>{props.row.original.rebuttal+'%'}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'condition',
      Header: 'Condition Rate',
      accessor: d => d.condition,
      Cell: props => <NavLink to="#" onClick={()=>{reportDetailsToggle();getReportDetails(props.row.original.ID, 'condition')}}>{props.row.original.condition+'%'}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'orderCount',
      Header: 'Order Count',
      accessor: d => d.order_count,
      Cell: props => <NavLink to="#" onClick={()=>{reportDetailsToggle();getReportDetails(props.row.original.ID, 'orderCount')}}>{props.row.original.order_count}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
  ];

  let reportFiltersConfig = [
    {id:'from',value:newfrom, updateFunc:(v)=>setState({from:v}), width:'2'},
    {id:'to',value:newto, updateFunc:(v)=>setState({to:v}), width:'2'},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'4'},
    {id:'appraiser',value:state.appraiser, updateFunc:(v)=>setState({appraiser:v}), width:'4'},
    {id:'rush',value:state.isRush, updateFunc:(v)=>setState({isRush:v}), width:'2'},
    {id:'complex',value:state.isComplex, updateFunc:(v)=>setState({isComplex:v}), width:'2'},
    {id:'state',value:state.state, updateFunc:(v)=>setState({state:v}), width:'2'},
    {id:'county',value:state.county, updateFunc:(v)=>setState({county:v}), width:'3'},
    {id:'city',value:state.city, updateFunc:(v)=>setState({city:v}), width:'2'},
    {id:'loanPurpose',value:state.loanPurpose, updateFunc:(v)=>setState({loanPurpose:v}), width:'2'},
    {id:'loanType',value:state.loanType, updateFunc:(v)=>setState({loanType:v}), width:'2'},
    {id:'entity',value:state.entity, updateFunc:(v)=>setState({entity:v}), width:'2'},
    {id:'appraisalType',value:state.filterAppraisalTypes, updateFunc:(v)=>setState({filterAppraisalTypes:v}), width:'4'},
    {id:'button',value:'Submit', updateFunc:getReport, width:'2', className:"align-right", color:"warning"},
  ];

  return <div>
    <Card>
      <CardHeader className="header-color">
      <i className="fa fa-reorder"></i>&nbsp;Appraiser Quality Report
      </CardHeader>
      <CardBody>
      <ReportFilter {...props} configs={reportFiltersConfig}/>
      </CardBody>
    </Card>
    <br/>
    <Card>
      <CardHeader className="header-color">
      <i className="fa fa-reorder"></i>&nbsp;Report
      </CardHeader>
      <CardBody>
      <MyReactTable columns={columns} data={state.report} className="table table-striped"/>
      </CardBody>
    </Card>

    <Modal className="my-modal-wide" isOpen={state.detailsPopUpOpen} toggle={reportDetailsToggle} >
      <ModalHeader hidden={true} toggle={reportDetailsToggle}></ModalHeader>
      <ModalBody>
      <center>
        <h5><i className="fa fa-user"></i> Report Details</h5>
      </center>
      <br/>
      <div className="large-scroll-container">
        <MyReactTable columns={columnsDetails} data={state.reportDetails} className="table table-striped"/>
      </div>
      <br/>
      <center>
        <Button color="info" onClick={reportDetailsToggle}>Close</Button>
      </center>
      </ModalBody>
    </Modal>
  </div>;
}


export default AppraiserQuality;
