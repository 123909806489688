//Author Sooyoung Kim
//Date April 18, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, deleteAPICallGenerator,confirmation} from '../../util/util';
import { Button, Input, Row, Col} from 'reactstrap';
import {NavLink, useParams, useNavigate } from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';
import ReactQuill from 'react-quill';
import Toggle from 'react-toggle';
import AddRecipient from './add-recipient';
import './article.css';

//initialize the state
const initialState = {
  body: '',
  creator: '',
  title: '',
  internal:false,
  datetimeCreated: '',
  emails:[],
  emailDropdownOpen:false,
};

//reducer function that perform state update
const reducer = getReducer();


const Article  = (props)=>{
  const controller = new AbortController();
  const { id } = useParams();
  const navigate = useNavigate();

  let newInitialState = Object.assign({}, initialState, {id:id});
  const [state, dispatch] = useReducer(reducer, newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getArticle(state.id);
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  const toggleEmail = () =>{
    setState({emailDropdownOpen:!state.emailDropdownOpen});
  }

  //API call
  //retrieve Article
  const getArticle = (id) =>{
    let url = 'article/'+id;
    //call back for retrieving Article
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code!=='00'){

      }
      else{
        setState({title:response.data.data.title});
        setState({body:response.data.data.body});
        setState({creator:response.data.data.creator});
        setState({emails:response.data.data.emails});
        setState({internal:response.data.data.internal==='yes'});
        setState({datetimeCreated:response.data.data.datetimeCreated});
      }
    };

    callBack = callBack.bind(this);

    httpGet(url, '', 'Oops, something went wrong and could not load this Article. Please try again later.', callBack);
  }

  const addEmail = (entities) =>{
    let url = 'article/email';
    let successMsg = 'Email recipient(s) added';
    let errorMsg = 'Oops, something went wrong and could not add email recipient. Please try again later.';

    let parameters = [
      {
        field:'articleFk',
        value:state.id
      },
      {
        field:'entities',
        value:entities
      }
    ];

    let callBack = (response)=>{
      console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code!=='00'){

      }
      else{
        let emails = state.emails.slice();
        emails = emails.concat(response.data.data);

        setState({emails:emails});
      }
    };
    callBack = callBack.bind(this);

    httpPost(url,parameters,successMsg,errorMsg, callBack);
  }

  //update the Article
  const updateArticle = () =>{
    let url = 'article/update';
    let successMsg = 'Article updated.';
    let errorMsg = 'Oops, something went wrong and could not update this Article. Please try again later.';

    let parameters = [
      {
        field:'body',
        value:state.body
      },
      {
        field:'creator',
        value:state.creator
      },
      {
        field:'title',
        value:state.title
      },
      {
        field:'internal',
        value:state.internal?"yes":"no"
      },
      {
        field:'ID',
        value:state.id
      }
    ];

    //call back for update Article
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code!=='00'){

      }
      else{

      }
    };
    callBack = callBack.bind(this);

    httpPut(url, parameters, successMsg, errorMsg, callBack);
  }

  //delete Article
  const deleteArticle = (id) =>{
    let url = 'article/'+id;
    let successMsg = 'Article deleted.';
    let errorMsg = 'Oops, something went wrong and could not delete this Article. Please try again later.';

    //call back for delete Article
    let callBack = (response)=>{

        console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code!=='00'){

      }
      else{
        navigate('/article');
      }
    };
    callBack = callBack.bind(this);

    httpDelete(url,successMsg,errorMsg, callBack);
  }

  //delete email recipient
  const removeRecipient = (id) =>{
    let url = 'article/email/'+id;
    let successMsg = 'Article email recipient deleted.';
    let errorMsg = 'Oops, something went wrong and could not delete this article email recipient . Please try again later.';

    //call back for delete Article
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code!=='00'){

      }
      else{
        let emails = state.emails.slice();

        for(let i=0;i<emails.length;i++){
          if(emails[i].ID===id){
            emails.splice(i, 1);
            break;
          }
        }

        setState({emails:emails});
      }
    };
    callBack = callBack.bind(this);

    httpDelete(url,successMsg,errorMsg, callBack);
  }


  //render

  let content = <ReactQuill
    modules={
      {
        toolbar: [
            [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
            [{size: []}],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'},
             {'indent': '-1'}, {'indent': '+1'}],
            ['link', 'image', 'video'],
            ['clean']
          ],
          clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
          },
          imageResize: {
          // parchment: Quill.import('parchment'),
            modules: ['Resize', 'DisplaySize']
          }
      }
    }
    formats={
      [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video'
      ]
    }
    value={state.body}
    className="high-quill"
    onChange={(value) => {setState({body:value});}}
  />;

  let action = <div>
    <Button color="danger" onClick={
      ()=>{
        confirmation(
          ()=>{deleteArticle(state.id)},
          ()=>{},
          'Delete Article?',
          'Are you sure you want to delete this Article?');
      }
    }><i className="fa fa-trash"></i>&nbsp;&nbsp;Delete</Button>&nbsp;&nbsp;
    <Button color="warning" onClick={toggleEmail}>Add Recipients</Button>&nbsp;&nbsp;
    <Button color="warning" onClick={()=>{updateArticle()}}><i className="fa fa-edit"></i>&nbsp;&nbsp;Update</Button>
  </div>;

  let creator = <div className="form-group">
    <label>By: </label>
    <Input value={state.creator} onChange={(text)=>{setState({creator:text.target.value})}}/>
  </div>;

  let title = <div className="form-group">
    <label>Title: </label>
    <Input value={state.title} onChange={(text)=>setState({title:text.target.value})}/>
  </div>;

  let internal = <div>
    <span style={{verticalAlign:'top',fontWeight:'bold'}}>Internal:</span><br/><Toggle
      checked={state.internal}
      icons={false}
      onChange={(e)=>setState({internal:!state.internal})} />&nbsp;
    </div>
  return <div>
    <div className="card padding">
      <AddRecipient emails={state.emails} removeRecipient={removeRecipient} addEmail={addEmail} toggleEmail={toggleEmail} emailDropdownOpen={state.emailDropdownOpen} logout={props.logout} showLoading={props.showLoading} hideLoading={props.hideLoading}/>
      <Row>
        <Col sm="8">
          <div className="page-title">
            <i className="fa fa-reorder"></i>&nbsp;Edit Article
          </div>
  				<NavLink to="/Article">Back to Article list</NavLink>
        </Col>
        <Col sm="4" className="align-right">
          {action}
        </Col>
      </Row>
      <div className="hr"/>

      <br/>
      <Row>
        <Col sm="11">
          {creator}
        </Col>
        <Col sm="1" className="align-right">
          {internal}
        </Col>
      </Row>
      <br/>
      {title}
      <br/>
      {content}
    </div>
  </div>;
}

export default Article;
