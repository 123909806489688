//Author Sooyoung Kim
//Date Aug 3, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, replaceRegex, formatNumber, formatDateTime, generateSID} from '../../../util/util';
import {Button, Card, CardHeader, CardBody, Modal, ModalBody, ModalHeader, Table} from 'reactstrap';
import {usePusher} from '../../pusher/pusher-context';
import MyReactTable from '../../util/my-react-table';
import ReportFilter from '../report-filter';
import {NavLink} from 'react-router-dom';
import moment from 'moment';
import React, {useReducer, useEffect, useCallback, useRef} from 'react';

//initialize the state
let today = new Date();
let month = today.getMonth()+1;

if(month<10)
  month = '0'+month;
let from = today.getFullYear()+'-01-01';
let to = today.getFullYear()+'-'+month+'-'+today.getDate();

const initialState = {
  queryID:-1,
  showReportLoading:false,
  clients:[],
  report:[],
  reportDetails:[],
  client:-1,
  from:from,
  to:to,
  turnaround:8,
  filterAppraisalTypes:[],
  tat:'bh',
  complex:'',
  date1:'',
  date2:'',
  date1Label:'',
  date2Label:'',
  toggle:false,
  state:'',
  county:'',
  city:'',
};

//reducer function that perform state update
const reducer = getReducer();


const CheckpointTurntime  = (props)=>{
  const controller = new AbortController();
  const newsid = useRef(generateSID());
  const pusher = usePusher();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  const toggleReportLoading = () => {
    setState({showReportLoading:!state.showReportLoading});
  }

  const loadReportResponse = useCallback((ID) => {
    let callBack = apiCallBack([{state:'report', key:'data'},{state:'queryID', value:-1},{state:'showReportLoading', value:false}]);
    httpGet('report/query/retrieve/'+ID, '', '', callBack);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [httpGet]);

  const reportReady = useCallback((response) => {
    response = JSON.parse(response)
    let code = response.code;
    let queryID = response.data;
    let SID = response.SID;
    if(SID===newsid.current){
      if(code==='00'){
        loadReportResponse(queryID)
      }
    }
  }, [loadReportResponse]);

  //run only once when component is loaded
  useEffect(() => {
    // Subscribe to a channel and bind to events using the pusher instance
    const channel = pusher.subscribe('private-report');
    channel.bind('report-ready', (data) => {
      reportReady(data);
    });

    getReport();

    // Clean up the subscription when the component is unmounted
    return () => {
      channel.unbind('report-ready');
      pusher.unsubscribe('private-report');

      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggle = () => {
    setState({toggle:!state.toggle})
  }

  //API call
  const getReport = () => {
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let county = replaceRegex(state.county);
    let city = replaceRegex(state.city);

    let SID = generateSID();
    newsid.current = SID

    let url = 'report/checkpoint/turntime/get/SID='+SID+'&from='+state.from+'&to='+state.to+'&client='+state.client+'&appraisalType='+appraisalType+'&complex='+state.complex+'&tat='+state.tat+'&state='+state.state+'&county='+county+'&city='+city;

    let callBack = apiCallBack([{state:'queryID', key:'data'}], toggleReportLoading());
	  httpGet(url, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  const getReportDetails = (checkpoint, date1, date2, date1Label, date2Label) => {
    setState({date1:date1, date2:date2, date1Label:date1Label, date2Label:date2Label});
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let county = replaceRegex(state.county);
    let city = replaceRegex(state.city);

    let url = 'report/checkpoint/turntime/details/checkpoint='+checkpoint+'&from='+state.from+'&to='+state.to+'&client='+state.client+'&appraisalType='+appraisalType+'&complex='+state.complex+'&tat='+state.tat+'&state='+state.state+'&county='+county+'&city='+city;

    setState({reportDetails:[]});

    let callBack = apiCallBack([{state:'reportDetails', key:'data'}], toggle());
    httpGet(url, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  //render
  let from, to; 
  if(state.from!=='')
    from = moment(state.from);
  if(state.to!=='')
    to = moment(state.to);

  const columns = [
    {
      id: 'referenceNum',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'loanNum',
      Header: 'Loan #',
      accessor: d => d.loan_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'address',
      Header: 'Property',
      accessor: d => d.property_street+' '+d.property_city+' '+d.property_state+', '+d.property_zip,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'appraisalType',
      Header: 'Appraisal Type',
      accessor: d => d.appraisal_type,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'date1',
      Header: state.date1Label,
      accessor: d => d[state.date1],
      Cell: props => formatDateTime(props.row.original[state.date1]),
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'date2',
      Header: state.date2Label,
      accessor: d => d[state.date2],
      Cell: props => formatDateTime(props.row.original[state.date2]),
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'tat',
      Header: 'TAT',
      accessor: d => d.tat,
      Cell: props => formatNumber(props.row.original.tat)+' hour',
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  let reportFiltersConfig = [
    {id:'from',value:from, updateFunc:(v)=>setState({from:v}), width:'2'},
    {id:'to',value:to, updateFunc:(v)=>setState({to:v}), width:'2'},
    {id:'complex',value:state.complex, updateFunc:(v)=>setState({complex:v}), width:'2'},
    {id:'customSelect',value:state.tat, updateFunc:(v)=>setState({tat:v}), width:'2', label:'TAT Type', clearable:false, className:"", options:[
      {label:'Business Hour',value:'bh'},{label:'Standard Hour',value:'standard'}
    ]},
    {id:'appraisalType',value:state.filterAppraisalTypes, updateFunc:(v)=>setState({filterAppraisalTypes:v}), width:'4'},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'3'},
    {id:'state',value:state.state, updateFunc:(v)=>setState({state:v}), width:'3'},
    {id:'county',value:state.county, updateFunc:(v)=>setState({county:v}), width:'2', group:false},
    {id:'city',value:state.city, updateFunc:(v)=>setState({city:v}), width:'2'},
    {id:'button',value:'Submit', updateFunc:getReport, width:'2', className:"align-right", color:"warning"},
  ];

  return <div>
    <Modal className="my-modal" isOpen={state.showReportLoading} toggle={toggleReportLoading} >
      <ModalHeader hidden={true} toggle={toggleReportLoading}></ModalHeader>
      <ModalBody>
        <center>
          <i style={{fontSize:'55px'}} className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
          <br/><br/>
          Report Loading...<br/>
          Please wait, your report will be loaded once it is ready.
        </center>
        <br/><br/>
        <center>
          <Button color="info" onClick={toggleReportLoading}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
    <Modal className="my-modal-wide" isOpen={state.toggle} toggle={toggle} >
      <ModalHeader hidden={true} toggle={toggle}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-user"></i> Report Details (Breakdown)</h5>
        </center>
        <br/>
        <div className="large-scroll-container">
          <MyReactTable columns={columns} data={state.reportDetails} className="table table-striped"/>
        </div>
        <br/>
        <center>
          <Button color="info" onClick={toggle}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Checkpoint Turntime Report
      </CardHeader>
      <CardBody>
        <ReportFilter {...props} configs={reportFiltersConfig}/>
        <div className="my-divider">&nbsp;</div>
        <label><i>All turnaround are measured in standard business hours. 5 business day per week and 8 business hours (9am - 5pm) per day.</i></label>
        <br/><br/>
        <p style={{lineHeight:'12px',fontSize:'12px',paddingLeft:'15px'}}>
          <label>Bid TAT</label> <i>&lt; Date Submitted&gt; - &lt; Date Bid Out&gt; </i> <br/>
          <label>Bid Review TAT</label> <i>&lt; Date Bid Out&gt; - &lt; Date Bid Ready To Review&gt; </i> <br/>
          <label>Bid Quote TAT</label> <i>&lt; Date Bid Ready To Review&gt; - &lt; Date Quote To Client&gt; </i> <br/>
          <label>Assign TAT</label> <i>&lt; Date Quote To Client&gt; - &lt; Date Assigned&gt; </i> <br/>
          <label>Submitted To Assign TAT</label> <i>&lt; Date Submitted&gt; - &lt; Date Assigned&gt; </i> <br/>
          <label>Acceptance TAT</label> <i>&lt; Date Assigned&gt; - &lt; Date Accepted&gt; </i> <br/>
          <label>Scheduling TAT</label> <i>&lt; Date Accepted&gt; - &lt; Date Inspection Scheduled&gt; </i> <br/>
          <label>Report TAT</label> <i>&lt; Date Inspection&gt; - &lt; Date Report Uploaded&gt; </i> <br/>
          <label>Review TAT</label> <i>&lt; Date Review Started&gt; - &lt; Date Review Completed&gt; </i> <br/>
          <label>Completed TAT</label> <i>&lt; Date Submitted&gt; - &lt; Date Shipped/Completed&gt; </i> <br/>
          <label>Condition TAT</label> <i>&lt; Date Condition Requested&gt; - &lt; Date Condition Completed&gt; </i> <br/>
          <label>Rebuttal TAT</label> <i>&lt; Date Rebuttal Requested&gt; - &lt; Date Rebuttal Completed&gt; </i> <br/>
        </p>
        <div className="my-divider"/>
        <Table className="table table-striped">
          <thead>
            <tr>
              <th>Checkpoint</th>
              <th>Avg TAT</th>
              <th>Standard Deviation</th>
              <th>Acceptable Range</th>
              <th>Count</th>
            </tr>
          </thead>
          <tbody>
            {
              state.report.map(
                (row, index)=>{
                  let lowerLimit, upperLimit = 0;

                  lowerLimit = row.avg - row.standard_deviation;
                  if(lowerLimit<=0)
                    lowerLimit = 0;
                  upperLimit = row.avg + row.standard_deviation;

                  return <tr key={index}>
                    <td><b>{row.label}</b></td>
                    <td><NavLink to="#" onClick={(e)=>getReportDetails(row.checkpoint, row.date1, row.date2, row.date1_label, row.date2_label)}>{formatNumber(row.avg)} hour</NavLink></td>
                    <td><NavLink to="#" onClick={(e)=>getReportDetails(row.checkpoint, row.date1, row.date2, row.date1_label, row.date2_label)}>{formatNumber(row.standard_deviation)} hour</NavLink></td>
                    <td><NavLink to="#" onClick={(e)=>getReportDetails(row.checkpoint, row.date1, row.date2, row.date1_label, row.date2_label)}>{formatNumber(lowerLimit)} <i className="fa fa-arrows-h"/> {formatNumber(upperLimit)} hour</NavLink></td>
                    <td><NavLink to="#" onClick={(e)=>getReportDetails(row.checkpoint, row.date1, row.date2, row.date1_label, row.date2_label)}>{row.count}</NavLink></td>
                  </tr>
                }
              )
            }
          </tbody>
        </Table>
      </CardBody>
    </Card>
  </div>;
}


export default CheckpointTurntime;
