//Author Sooyoung Kim
//Date April 20, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, deleteAPICallGenerator, callBackGenerator, confirmation} from '../../util/util';
import {Button, Col, Row, Table, Input, Card, CardBody, CardHeader} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import MySelect from '../util/my-select';


//initialize the state
const initialState = {
  blackList:[],
  newBlackListConditionRuleSelected:'',
  newBlackListConditionField:'',
  newBlackListConditionOperator:'=',
  newBlackListConditionDescription:'',
  newBlackListConditionValues:[],
  newBlackListConditionValuesT:'',
  newBlackListConditionValuesS:'',
  newBlackListName:'',
  fieldsEnum:[]
};

//reducer function that perform state update
const reducer = getReducer();


const AutoConditionConfiguration  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);
  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getBlacklist();
    getRuleFieldEnum();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const blackListValueOnChange = (v) =>{
    setState({newBlackListConditionValuesT:v});
  }

  const blackListFieldOnChange = (v) =>{
    for(let i=0;i<state.fieldsEnum.length;i++){
      if(state.fieldsEnum[i].field===v){
        setState({newBlackListConditionValues:state.fieldsEnum[i].enum});
        break;
      }
    }
  }

  //API call
  const addNewBlackListCondition = () =>{
    let url = 'autoCondition/configuration/rule/condition/create';
    let successMsg = 'Auto assign rule condition created successfully.';
    let errorMsg = 'Oops, something went wrong and could not create the rule condition. Please try again later.';

    let select = true;

    for(let i=0;i<state.fieldsEnum.length;i++){
      //there is no drop down, the value should coming from text instead
      if(state.fieldsEnum[i].field===state.newBlackListConditionField){
        if(state.fieldsEnum[i].enum.length<=0)
          select = false;
      }
    }

    let value;

    if(select)
      value = state.newBlackListConditionValuesS;
    else
      value = state.newBlackListConditionValuesT;


    let parameters = [
      {
        field:'description',
        value:state.newBlackListConditionDescription
      },
      {
        field:'field',
        value:state.newBlackListConditionField
      },
      {
        field:'operator',
        value:state.newBlackListConditionOperator
      },
      {
        field:'value',
        value:value
      },
      {
        field:'ruleFk',
        value:state.newBlackListConditionRuleSelected
      },
    ];

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code!=='00'){

      }
      else{
        let list = state.blackList.slice();

        for(let i=0;i<list.length;i++){
          if(list[i].ID===state.newBlackListConditionRuleSelected){
            list[i].conditions.push(response.data.data);
          }
        }

        setState({blackList:list});
      }
    };
    callBack = callBack.bind(this);

    httpPost(url,parameters,successMsg,errorMsg, callBack);
  }

  const addNewBlackList = () =>{
    let url = 'autoCondition/configuration/rule/create';
    let successMsg = 'Auto assign rule created successfully.';
    let errorMsg = 'Oops, something went wrong and could not create the rule. Please try again later.';

    let parameters = [
      {
        field:'name',
        value:state.newBlackListName
      },
    ];

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code!=='00'){

      }
      else{
        let list = state.blackList.slice();

        list.push(response.data.data);

        setState({blackList:list});
        setState({newBlackListName:''});
      }
    };
    callBack = callBack.bind(this);

    httpPost(url,parameters,successMsg,errorMsg, callBack);
  }

  //delete a black list rule
  const deleteBlackListRule = (id) =>{
    let url = 'autoCondition/configuration/rule/'+id
    let successMsg = 'Black list rule deleted successfull.';
    let errorMsg = 'Oops, something went wrong and could not delete the rule. Please try again later.';


    let callBack = (response)=>{
      let code= response.data.code;

      if(code!=='00'){

      }
      else{
        let list = state.blackList.slice();

        for(let i=0;i<list.length;i++){
          if(list[i].ID===id){
            list.splice(i,1);
            break;
          }
        }

        setState({blackList:list});
      }
    };
    callBack = callBack.bind(this);

    httpDelete(url,successMsg,errorMsg, callBack);
  }

  //delete a black list condition
  const deleteBlackListCondition = (id,conditionID) =>{
    let url = 'autoCondition/configuration/rule/condition/'+conditionID
    let successMsg = 'Black list condition deleted successfull.';
    let errorMsg = 'Oops, something went wrong and could not delete the condition. Please try again later.';

    let callBack = (response)=>{
      let code= response.data.code;

      if(code!=='00'){

      }
      else{
        let list = state.blackList.slice();

        for(let i=0;i<list.length;i++){
          if(list[i].ID===id){
            for(let j=0;j<list[i].conditions.length;j++){
              if(list[i].conditions[j].ID===conditionID){
                list[i].conditions.splice(j,1);
                break;
              }
            }

            break;
          }
        }

        setState({blackList:list});
      }
    };

    callBack = callBack.bind(this);

    httpDelete(url,successMsg,errorMsg, callBack);
  }

  const getBlacklist = () =>{
    let url = 'autoCondition/configuration/rule/get';
    let callBack = apiCallBack([{state:'blackList', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load the black list. Please try again later.', callBack);
  }

  const getRuleFieldEnum = () =>{
    let url = 'autoAssign/rules/field/value';
    let callBack = apiCallBack([{state:'fieldsEnum', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load the rule fields. Please try again later.', callBack);
  }

  //render
  let blackLists, valueWidget;

  if(state.newBlackListConditionValues!==undefined&&state.newBlackListConditionValues.length>0&&state.newBlackListConditionOperator!=='like')
    valueWidget = <MySelect
      type="select"
      required={true}
      selectIsClearable={true}
      value={state.newBlackListConditionValuesS}
      onChange={(v)=>{blackListValueOnChange(v);setState({newBlackListConditionValuesS:v})}}
      options={state.newBlackListConditionValues.map((value)=>{
        return {label:value.label, value:value.value};
      })}
    />;
  else
    valueWidget = <Input required={true} type="text" onChange={(e)=>setState({newBlackListConditionValuesT:e.target.value})} value={state.newBlackListConditionValuesT}/>;

  blackLists = state.blackList.map(
    (list, index)=>{

      let conditions;

      conditions = list.conditions.map(
        (condition,index2)=>{
          return(
            <tr key={index2}>
              <td><i className="fa fa-times red-color cursor-pointer" onClick={()=>deleteBlackListCondition(list.ID, condition.ID)}></i> {condition.description}</td>
              <td>{condition.field}</td>
              <td>{condition.operator}</td>
              <td>{condition.value}</td>
            </tr>
          );
        }
      );

      return(
        <div key={index}>
          <Card>
            <CardHeader className="header-color">
              <i className="fa fa-times red-color cursor-pointer" onClick={
                ()=>{
                  confirmation(
                    ()=>{deleteBlackListRule(list.ID)},
                    ()=>{},
                    'Delete auto assign rule?',
                    'Are you sure you want to delete this rule? You will lose all conditions that associated with this rule.');
                }
              }></i> {list.name}
            </CardHeader>
            <CardBody>
              <Table className="table table-striped">
                <thead>
                  <tr>
                    <th width="35%">Description</th>
                    <th width="15%">Field</th>
                    <th width="25%">Operator</th>
                    <th width="25%">Value</th>
                  </tr>
                </thead>
                <tbody>
                  {conditions}
                </tbody>
              </Table>
            </CardBody>
          </Card>
          <br/>
        </div>
      );
    }
  );

  return <div>
    <div className="my-well">
      <Row>
        <Col sm="6">
          <div className="page-title">
            <i className="fa fa-reorder"></i>&nbsp;Auto Condition
          </div>
          <NavLink to="/system-setting">Back to settings</NavLink>
        </Col>
        <Col sm="6">

        </Col>
      </Row>

      <Card>
        <CardHeader className="header-color">
          <i className="fa fa-reorder"></i>&nbsp;Black list
        </CardHeader>
        <CardBody>
          {blackLists}
          <br/>
          <div className="my-divider"></div>

          <i className="fa fa-plus green-color"></i> Add black list rules<br/>
          <label>Rule name</label>
          <Row>
            <Col sm="4">
              <Input type="text" value={state.newBlackListName} onChange={(e)=>setState({newBlackListName:e.target.value})} />
            </Col>
            <Col sm="8">
              <Button color="warning" onClick={addNewBlackList}>Create rule</Button>
            </Col>
          </Row>
          <div className="my-divider"></div>
          <br/>

          <form onSubmit={(e)=>{e.preventDefault();addNewBlackListCondition()}}>
            <Row>
              <Col sm="6">
                <label><font className="red-color">*</font>Associated rule</label>
                <MySelect
                  type="select"
                  required={true}
                  value={state.newBlackListConditionRuleSelected}
                  onChange={(v)=>{setState({newBlackListConditionRuleSelected:v})}}
                  options={[{label:"Select a rule",value:""}].concat(
                    state.blackList.map((list)=>{
                      return {label:list.name, value:list.ID};
                     })
                  )}
                />
              </Col>
              <Col sm="6">
                <label>Description</label>
                <Input type="text" onChange={(e)=>setState({newBlackListConditionDescription:e.target.value})} value={state.newBlackListConditionDescription}></Input>
              </Col>
            </Row>
            <Row>
              <Col sm="4">
                <label><font className="red-color">*</font>Field name</label>
                <MySelect
                  type="select"
                  required={true}
                  value={state.newBlackListConditionField}
                  onChange={(v)=>{setState({newBlackListConditionField:v});blackListFieldOnChange(v);}}
                  options={[{label:"Select a field",value:""}].concat(
                    state.fieldsEnum.map((field)=>{
                      return {label:field.label, value:field.field};
                     })
                  )}
                />
              </Col>
              <Col sm="4">
                <label><font className="red-color">*</font>Operator</label>
                <MySelect
                  type="select"
                  required={true}
                  value={state.newBlackListConditionOperator}
                  onChange={(v)=>{setState({newBlackListConditionOperator:v})}}
                  options={[{label:"Equal to",value:"="},{label:"Not equal to",value:"!="},{label:"Like",value:"like"}]}
                />
              </Col>
              <Col sm="4">
                <label><font className="red-color">*</font>Value</label>
                {valueWidget}
              </Col>
            </Row>
            <br/>
            <div className="align-right"><Button color="warning">Create condition</Button></div>
          </form>
        </CardBody>
      </Card>
    </div>
  </div>;
}

export default AutoConditionConfiguration;
