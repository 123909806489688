//Author Sooyoung Kim
//Date Sep 14, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, callBackGenerator, replaceRegex, formatNumber,  formatDateTime, showMessage, confirmation, getSession, addBusinessDays, getFromTo} from '../../util/util';
import {Alert, Button,Card, CardHeader, CardBody,Modal, ModalHeader, ModalBody, Nav, NavItem, Row, Col, Input, Table, TabContent, TabPane, NavLink as NavLinkL} from 'reactstrap';
import SpreadWatchQuestionnaire from '../billing/spread-watch-questionnaire';
import {usePresencePusher } from '../pusher/pusher-context';
import MyReactTable from '../util/my-react-table';
import React, {useReducer, useEffect} from 'react';
import MyGoogleMap from '../util/my-google-map';
import AssignmentList from './assignment-list';
import AssignmentDraft from './assignment-draft';
import AssignmentTab from './assignment-tab';
import AssignmentVet from './assignment-vet';
import AQB from './assignment-aqb';
import BidLog from './bid-log';
import ReportFilter from '../report/report-filter';
import DatePicker from 'react-datepicker';
import MySelect from '../util/my-select';
import {NavLink, useParams} from 'react-router-dom';
import moment from 'moment';
import '../util/my-xeditable.css';
import '../dashboard/review-delivery.css';
import '../appraisal/appraisal.css';
import 'react-datepicker/dist/react-datepicker.css';
let today = new Date();
let month = today.getMonth()+1;

if(month<10)
  month = '0'+month;

let session = getSession();
let name = session.userFirstName+' '+session.userLastName;

//initialize the state
const initialState = {
  name:name,
  //default center of google map
  appraisal:{
    property_lat:41.850033,
    property_lng:-87.6500523,
    property_street:'',
    property_city:'',
    property_state:'',
    borrower_f_name:'',
    borrower_l_name:'',
    reference_num:'',
    loan_num:''
  },
  appraisers:[],
  searchRadius:'',
  searchKeyword:'',
  selectedAppraisers:[],
  selectedAppraiserAutoBid:{},
  maxDistance:15,
  appraiserFeeForBid:'',
  turnAroundTimeForBid:'',
  commentForBid:'',
  commentForQuoteVetting:'',
  acceptOnBehalf: false,
  commentTemplates:[],
  templateName:'',
  lenderFees:[],
  totalLenderFee:0,
  assignmentHistories:[],
  doNotChangeFee:false,
  presenceMembers:[],

  submitDisabled:false,
  bidDisabled:false,
  vetDisabled:false,
  appraiserPipelineDropDownOpen:false,
  appraiserPipeline:{},
  bids:[],
  addnewBid:false,
  choosesendBid:false,
  newBidName:'',
  newBidEmail:'',
  newBidPhone:'',
  newBidMethod:'phone',
  newBidFee:'',
  newBidRecipientEmail:'',
  newBidRecipientFk:0,
  newBidTurnaround:today.getFullYear()+'-'+month+'-'+today.getDate(),
  newBidRespond:today.getFullYear()+'-'+month+'-'+today.getDate(),
  newBidNote:'',

  conditionComment:'',
  declineReasons:[],
  declineReason:'',
  conditionReasons:[],
  conditionreason:'',
  conditionFee:'',
  conditionTurntime:'',
  declineComment:'',
  conditionPopUp:false,
  declinePopUp:false,
  manualAutoAssignPopUp:false,
  manualAutoAssign:[],


  mapAppraisers:[],
  clientName:'',
  clientEmail:'',
  client:'',
  clients:[],
  entity:'',
  openBy:'',
  filterAppraisalTypes:[],
  loanPurpose:'',
  loanType:'',
  isRush:'',
  isComplex:'',
  from:getFromTo('from'),
  to:getFromTo('to'),
  radius:15,
  longitude:-87.6500523,
  latitude:41.850033,
  clickedFeeMap:false,
  appraisalDetails:[],
  orderDetailName:'',
  showOrderDetail:false,
  orderDetailID:-1,
  standardDev:0,
  initialclickedFeeMap:false,

  county:'',
  aqbCounty:'',
  activeTab:'1',
  tabHistory:['1'],

  showSpreadWatchQuestionnaire:false,
  unsettleBills:[],
  unsettleInvoices:[],
  settledInvoices:[],
  settledBills:[],

  showQuoteVetting:false,
  entities:[],
  lookUpAppraiser:false,
};

//reducer function that perform state update
const reducer = getReducer();


const Assignment  = (props)=>{
  const controller = new AbortController();
  const presencePusher = usePresencePusher ();

  let {id} = useParams();

  let newInitialState = Object.assign({}, initialState, {id: id});
  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    initialize();
    getCommentTemplates();
    getAppraisalBilling();
    getAssignmentHistories();

    getConditionReasons();
    getDeclineReasons();
    getManualAutoAssign();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if (presencePusher) {
      const presenceChannel = presencePusher.subscribe('presence-order');

      const handleSubscriptionSucceeded = (members) => {
        // Get the initial user list from presence channel
        let presenceMembers = [];
        members.each(
          function(member)
          {
            let ID = member.info.ID;
            let orderID = member.info.orderID;
            let myID = localStorage.getItem('userID');
            if(orderID===state.id){
              if(ID!==myID){
                presenceMembers.push(member);
              }
            }
          }
        );
        setState({presenceMembers:presenceMembers})
      };

      const handleMemberAdded = (member) => {
        let ID = member.info.ID;
        let orderID = member.info.orderID;
        let myID = localStorage.getItem('userID');
        if(orderID===state.id){
          if(ID!==myID){
            let presenceMembers = state.presenceMembers.slice();
            presenceMembers.push(member);

            setState({presenceMembers:presenceMembers});
          }
        }
      };

      const handleMemberRemoved = (member) => {
        // Remove a user from the user list
        let ID = member.info.ID;
        let orderID = member.info.orderID;
        let myID = localStorage.getItem('userID');

        if(orderID===state.id){
          if(ID!==myID){
            let presenceMembers = state.presenceMembers.slice();

            for(let i=0;i<presenceMembers.length;i++){
              if(presenceMembers[i].info.ID===member.info.ID){
                presenceMembers.splice(i, 1);
                setState({presenceMembers:presenceMembers});
                break;
              }
            }
          }
        }
      };

      presenceChannel.bind('pusher:subscription_succeeded', handleSubscriptionSucceeded);
      presenceChannel.bind('pusher:member_added', handleMemberAdded);
      presenceChannel.bind('pusher:member_removed', handleMemberRemoved);

      return () => {
        presenceChannel.unbind('pusher:subscription_succeeded', handleSubscriptionSucceeded);
        presenceChannel.unbind('pusher:member_added', handleMemberAdded);
        presenceChannel.unbind('pusher:member_removed', handleMemberRemoved);

        presenceChannel.unsubscribe();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presencePusher, state.id]);

  //non API call but simpyl manage state
  const initialize = ()=>{
    let date = new Date();

    let days =5;
    if(props.appraisal.rush==='Rush'){
      if(props.appraisal.datetime_expected==='0000-00-00 00:00:00'){
        //3 standard business days
        days = 3;
      }
      else{
        let now = new Date();
        let expectedDate = new Date(props.appraisal.datetime_expected);

        let diff = Math.abs(expectedDate - now);
        //take out milliseconds
        diff = diff/1000;
        diff = diff/60;
        diff = diff/60;

        days = Math.floor(diff/24);

        if(days<1)
          days = 0;
        if(days>3)
          days = 3;

      }
    }

    let entities = [];
    for(let i=0;i<props.appraisal.entities.length;i++){
      let entity = props.appraisal.entities[i];
      entities.push(entity);
    }

    let turnAroundTimeForBid = addBusinessDays(date, days);
    let county = props.appraisal.property_county;
    county = county.replace('county','');
    county = county.replace('County','');
    setState({clientName:props.appraisal.client_name, clientEmail:props.appraisal.client_email, entities:entities, turnAroundTimeForBid:turnAroundTimeForBid, county:county, aqbCounty:county});

    //get nearby appraiser
    getAppraiserByRadius(county, props.appraisal);
    getBid(props.appraisal.ID);

    if(props.appraisal.client_assignment_note && props.appraisal.client_assignment_note!=='')
      showMessage('notification', props.appraisal.client_assignment_note);
  }

  const handleChangeInput = (v) => {
    setState({commentForBid:v});
  }

  const removeAppraiser = (id) => {
    clickRowListener(id, {});
  }

  const toggleAppraiserPipeline = () => {
    setState({appraiserPipelineDropDownOpen:!state.appraiserPipelineDropDownOpen});
  }

  const toggleAddnewBid = () => {
    setState({addnewBid:!state.addnewBid});
  }

  // Not being used
  // const toggleAutoAssign = () => {
  //   setState({manualAutoAssignPopUp:!state.manualAutoAssignPopUp});
  // }

  const conditionPopUpToggle = () => {
    setState({conditionPopUp:!state.conditionPopUp});
  }

  const declinePopUpToggle = () => {
    setState({declinePopUp:!state.declinePopUp});
  }

  const activeTabToggle = (tab) => {
    setState({activeTab:tab});

    let tabHistory = state.tabHistory.slice();
    if(tabHistory.indexOf(tab)===-1){
      tabHistory.push(tab);
      setState({tabHistory:tabHistory})
    }


    if(tab==='4'){
      if(state.initialclickedFeeMap === false){
        setState({initialclickedFeeMap:true});
        getAppraiserAvgFeeMap();
      }
      setState({clickedFeeMap:true});
    }else{
      setState({clickedFeeMap:false});
    }
  }

  const clickOrderDetail = (appraisal, name, ID) => {
    setState({showOrderDetail:true, appraisalDetails:appraisal, orderDetailName:name, orderDetailID:ID});
  }

  const checkSpreadWatch = () => {
    let totalPayable = 0;
    let totalReceivable = 0;
    let settledInvoices = state.settledInvoices.slice();
    let unsettleInvoices = state.unsettleInvoices.slice();
    let unsettleBills = state.unsettleBills.slice();
    let settledBills = state.settledBills.slice();

    let complex = false;
    for(let i=0;i<settledInvoices.length;i++){
      if(settledInvoices[i].status!=='Cancelled')
        for(let j=0;j<settledInvoices[i].fees.length;j++){
          if(settledInvoices[i].fees[j].category==='Complex'){
            complex = true;
            break;
          }
        }
      if(settledInvoices[i].status==='Loss')
        totalReceivable = totalReceivable+0;
      else if(settledInvoices[i].status!=='Overage'&&settledInvoices[i].status!=='Cancelled'&&settledInvoices[i].status!=='Voided'&&settledInvoices[i].status!=='Charge Back'&&settledInvoices[i].status!=='Refunded'&&settledInvoices[i].status!=='Partially Received'&&settledInvoices[i].status!=='Reconcile'&&settledInvoices[i].status!=='Balance Due')
        totalReceivable = totalReceivable + parseInt(settledInvoices[i].total, 10);
      else if(settledInvoices[i].status==='Refunded')
        totalReceivable = totalReceivable - parseInt(settledInvoices[i].total, 10);
    }

    for(let i=0;i<unsettleInvoices.length;i++){
      if(unsettleInvoices[i].status!=='Cancelled')
        for(let j=0;j<unsettleInvoices[i].fees.length;j++){
          if(unsettleInvoices[i].fees[j].category==='Complex'){
            complex = true;
            break;
          }
        }

      if(unsettleInvoices[i].status==='Loss')
        totalReceivable = totalReceivable+0;
      else if(unsettleInvoices[i].status!=='Overage'&&unsettleInvoices[i].status!=='Cancelled'&&unsettleInvoices[i].status!=='Voided'&&unsettleInvoices[i].status!=='Charge Back'&&unsettleInvoices[i].status!=='Refunded'&&unsettleInvoices[i].status!=='Partially Received'&&unsettleInvoices[i].status!=='Reconcile'&&unsettleInvoices[i].status!=='Balance Due')
        totalReceivable = totalReceivable + parseInt(unsettleInvoices[i].total, 10);
      else if(unsettleInvoices[i].status==='Refunded')
        totalReceivable = totalReceivable - parseInt(unsettleInvoices[i].total, 10);
    }

    for(let i=0;i<unsettleBills.length;i++){
      if(unsettleBills[i].status!=='Cancelled')
        totalPayable= totalPayable+ parseInt(unsettleBills[i].total,10);
    }

    for(let i=0;i<settledBills.length;i++){
      if(settledBills[i].status!=='Cancelled')
        totalPayable= totalPayable+ parseInt(settledBills[i].total,10);
    }

    let spreadLimit = complex?200:150;

    if(totalReceivable-totalPayable>spreadLimit)
      setState({showSpreadWatchQuestionnaire:true});
  }

  const isCheck = (ID) => {
    let index = state.selectedAppraisers.indexOf(ID);

    if(index!==-1)
      return true;
    return false;
  }

  

  //click listener that listen to the checkbox on a list of appraiser
  //whenever the checkbox is checked it add that appraiser ID to an array
  const clickRowListener = (id, info, col = '') => {
    console.log(col)
    if(col === 'Service Rating' || col === 'Quality Rating'|| col === 'Fee'|| col === 'Turnaround'|| col === 'Date Respond'|| col === 'Note'|| col === 'Message'){
      return;
    }
    let index = state.selectedAppraisers.indexOf(id);
    console.log(index);
    console.log(state.selectedAppraisers);
    let existingArray = state.selectedAppraisers.slice();
    let appraiserInfoMap = Object.assign({}, state.appraiserInfoMap);
    if(index===-1){
      existingArray.push(id);
      appraiserInfoMap[id] = info;
      setState({selectedAppraisers:existingArray, appraiserInfoMap:appraiserInfoMap});
    }else{
      existingArray.splice(index,1);
      delete appraiserInfoMap[id];
      setState({selectedAppraisers:existingArray, appraiserInfoMap:appraiserInfoMap});
    }
  }
  
  const bidButtonHandler = () => {
    if(state.bidDisabled)
      return;

    setState({bidDisabled:true});
    sendBid();
  }

  const toggleVettingPage = () => {
    setState({showQuoteVetting:!state.showQuoteVetting});
  }
  
  const buttonHandler = () => {
    if(state.submitDisabled)
      return;

    if(state.selectedAppraisers.length>1){
      showMessage('error','You can only select one appraiser for assignment.');
      return;
    }

    if(state.selectedAppraisers.length<=0){
      showMessage('error','Please select an appraiser for assignment.');
      return;
    }


    if(props.appraisal.datetime_expected!=='0000-00-00 00:00:00' && state.turnAroundTimeForBid!==''){
      let dueDate = state.turnAroundTimeForBid;
      if(typeof dueDate ==='string'){
        dueDate = new Date(dueDate+' PDT');
      }
      let expDate = new Date(props.appraisal.datetime_expected);


      if(dueDate>=expDate){
        confirmation(
          ()=>{setState({submitDisabled:true}); assign();},
          ()=>{},
          'Late Delivery',
          'The appraiser due date is on the same day or later than the client expected date. Are you sure you want to assign?'
        );
      }
      else{
        setState({submitDisabled:true})
        assign();
      }
    }
    else{
      setState({submitDisabled:true})
      assign();
    }
  }


  //API call
  //get appraisers by radius
  const getAppraiserByRadius = (county, appraisal) => {
    let searchKeyword = encodeURIComponent(state.searchKeyword.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
    let radius = state.searchRadius;

    console.log(county, appraisal);

    if(county===''&&radius===''){
      if(appraisal.property_county==='')
        showMessage('error','Property county is unknown.');
      else
        showMessage('error','Please at least specify the county or radius');
    }

    let url = 'appraiser/get/nearby/keyword='+searchKeyword+'&state='+appraisal.property_state+'&county='+county+'&lat='+appraisal.property_lat+'&lng='+appraisal.property_lng+'&miles='+radius+'&appraisalFk='+state.id;
    let callBack = apiCallBack([{state:'appraisers', key:'data'}]);

    httpGet(url, '', 'Oops, something went wrong and could not load appraiser arround this property. Please try again later.', callBack);
  }

  //get appraisers switch function by checking if search keyword is empty
  //whenever search keyword is not empty, search by keyword always have priority than search by radius
  const getAppraisers = () => {
    if(state.keyword==='' && state.county==='' && state.searchRadius===''){
      showMessage('warning', 'Please at least supply one search parameter - Radius / County / Keyword.');
      return;
    }

    if(state.keyword!=='' && state.searchRadius==='')
      getAppraiserByKeyword();
    else
      getAppraiserByRadius(state.county, props.appraisal);
  }

  const getBid = (id) => {
    let callBack = apiCallBack([{state:'bids', key:'data'}]);
    httpGet('appraisal/bid/'+id, '', 'Oops, something went wrong and could not load list of bids sent out. Please try again later.', callBack);
  }

  //get appraiser by keyword
  const getAppraiserByKeyword = () => {
    let searchKeyword = encodeURIComponent(state.searchKeyword.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
    let county = encodeURIComponent(state.county.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
    let callBack = apiCallBack([{state:'appraisers', key:'data'}]);
    
    httpGet('appraiser/search/keyword='+searchKeyword+'&appraisalFk='+state.id+'&county='+county, '', 'Oops, something went wrong and could not found any appraiser with this keyword. Please try again later.', callBack);
  }

  const getAppraiserPipeline = (ID) => {
    let callBack = apiCallBack([{state:'appraiserPipeline', key:'data'}]);
    httpGet('appraiser/pipeline/overview/'+ID, '', 'Oops, something went wrong and could not load this appraiser\'s pipeline. Please try again later.', callBack);
  }

  //get a list of comment templates
  const getCommentTemplates = () => {
    let callBack = apiCallBack([{state:'commentTemplates', key:'data'}]);
    httpGet('template/get/'+state.id, '', 'Oops, something went wrong and could not load your comment template.', callBack);
  }

  const getManualAutoAssign = () => {
    let callBack = apiCallBack([{state:'manualAutoAssign', key:'data'}]);
    httpGet('autoAssign/manual/appraiser/'+state.id, '', 'Oops, something went wrong and could not load auto assign candidates.', callBack);
  }

  //get the billing of the appraisal information as assignment needed lender fee & appraiser fee
  const getAppraisalBilling = (checkSpread = false) => {
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;

      if(code==='00'){
        let unsettle_invoices = response.data.data.unsettle_invoices;
        let settled_invoices = response.data.data.settled_invoices;

        let unsettle_bills = response.data.data.unsettle_bills;
        let settled_bills = response.data.data.settled_bills;

        let spreadSubmitted = response.data.data.spreadSubmitted;

        let total = 0;
        let aTotal = 0;
        for(let i=0;i<settled_invoices.length;i++){
          if(settled_invoices[i].status==='Refunded')
            total -= parseInt(settled_invoices[i].total,10);
          else if(settled_invoices[i].status!=='Cancelled'&&settled_invoices[i].status!=='Loss'&&settled_invoices[i].status!=='Voided'&&settled_invoices[i].shadow_invoice==='0')
            total+= parseInt(settled_invoices[i].total,10);
        }
        for(let i=0;i<unsettle_invoices.length;i++){
          if(unsettle_invoices[i].status==='Refunded')
            total -= parseInt(unsettle_invoices[i].total,10);
          else if(unsettle_invoices[i].status!=='Cancelled'&&unsettle_invoices[i].status!=='Loss'&&unsettle_invoices[i].status!=='Voided'&&unsettle_invoices[i].shadow_invoice==='0')
            total+= parseInt(unsettle_invoices[i].total,10);
        }

        for(let i=0;i<unsettle_bills.length;i++)
          aTotal+= parseInt(unsettle_bills[i].total,10);
        for(let i=0;i<settled_bills.length;i++)
          aTotal+= parseInt(settled_bills[i].total,10);
        setState({totalLenderFee:total});
        setState({appraiserFees:aTotal});

        if(settled_bills)
          setState({settledBills:settled_bills});
        if(unsettle_bills)
          setState({unsettleBills:unsettle_bills});
        if(settled_invoices)
          setState({settledInvoices:settled_invoices});
        if(unsettle_invoices)
          setState({unsettleInvoices:unsettle_invoices});


        if(checkSpread&&!spreadSubmitted){
          checkSpreadWatch();
        }
      }
    };
    callBack = callBack.bind(this);

    httpGet('billing/'+state.id, '', 'Oops, something went wrong and could not load billing for this appraisal. Please try again later.', callBack);
  }

  //get the assignment log for display the history on doing assignment
  const getAssignmentHistories = () => {
    let callBack = apiCallBack([{state:'assignmentHistories', key:'data'}]);
    httpGet('appraisal/assignment/history/get/'+state.id, '', 'Oops, something went wrong and could not load the assignment history for this appraisal. Please try again later.', callBack);
  }

  const recordBid = (method, recipient_name, email, turnaround, fee, recipient_email, recipient_fk, respond='',phone='', note='') => {
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;

      if(code==='00'){
        let quotes = state.bids.slice();
        quotes.push(response.data.data);

        //setState({quotes:quotes});
        getBid(state.id);

        if(state.addnewBid){
          toggleAddnewBid();
        }

        let appraisal = Object.assign({}, props.appraisal);
        if(appraisal.appraiser_fk==='0'){
          appraisal.status='Bid';
          props.updateAppraisal(appraisal);
        }
        setState({lookUpAppraiser:false, appraiser:'', newBidRecipientFk:0, newBidName:'', newBidEmail:'', newBidRecipientEmail:'', newBidPhone:'', newBidFee:'', newBidNote:''});
      }
    };

    let parameters = [
      {
        field:'appraisal_fk',
        value:state.id
      },
      {
        field:'method',
        value:method
      },
      {
        field:'email',
        value:email
      },
      {
        field:'note',
        value:note
      },
      {
        field:'turnaround',
        value:turnaround
      },
      {
        field:'fee',
        value:fee
      },
      {
        field:'date_respond',
        value:respond
      },
      {
        field:'recipient_email',
        value:recipient_email
      },
      {
        field:'recipient_name',
        value:recipient_name
      },
      {
        field:'recipient_phone',
        value:phone
      },
      {
        field:'recipient_fk',
        value:recipient_fk
      }
    ];

    httpPost('appraisal/bid/create', parameters, 'Bid recorded.', 'Oops, something went wrong and could not record bid for this appraisal. Please try again later.', callBack);
  }

  //send bid to appraiser with specified fee & turntime and customized message if any
  const sendBid = () => {
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code!=='00'){
        setState({bidDisabled:false});
      }
      else{
        //getAssignmentHistories();
        let appraisal = Object.assign({}, props.appraisal);
        console.log(appraisal.appraiser_fk);
        if(appraisal.appraiser_fk==='0'||appraisal.appraiser_fk===0)
          appraisal.status='Bid';

        let appraisers = state.appraisers.slice();
        
        for(let i=0;i<state.selectedAppraisers.length;i++){
          for(let j=0;j<appraisers.length;j++){
            if(appraisers[j].ID===state.selectedAppraisers[i]){
              appraisers[j].quoted = true;
            }
          }
        }
        props.updateAppraisal(appraisal);


        setState({appraisers:appraisers, bidDisabled:false, selectedAppraisers:[]});
        getBid(state.id);
      }
    };
    callBack = callBack.bind(this);

    let errorCallBack = apiCallBack([{state:'bidDisabled', value:false}]);

    let parameters = [
      {
        field:'ID',
        value:state.id
      },
      {
        field:'appraisers',
        value:state.selectedAppraisers
      },
      {
        field:'turntime',
        value:state.turnAroundTimeForBid
      },
      {
        field:'comment',
        value:state.commentForBid
      },
      {
        field:'fee',
        value:state.appraiserFeeForBid
      }
    ];

    httpPost('appraisal/bid', parameters, 'Bid sent to appraiser(s).', 'Oops, something went wrong and could not send bid for this appraisal. Please try again later.', callBack, errorCallBack);
  }

  //assign to the appraiser, it has to be only one appraiser's checkbox is checked otherwise prompt error
  const assign = () => {
    if(state.selectedAppraisers.length===1){

      let callBack = (response)=>{console.log(response);
        let code = response.data?response.data.code:undefined;

        if(code!=='00'){
          setState({submitDisabled:false});
        }
        else{
          if(response.data.repeat_message)
            showMessage('warning',response.data.repeat_message);
          let status = 'Assigned';
          if(state.acceptOnBehalf)
            status='Pending Inspection';

          let appraisal = Object.assign({}, props.appraisal, {status:status});
          props.updateAppraisal(appraisal);
          getAppraisalBilling(true)
        }
      };
      callBack = callBack.bind(this);

      let errorCallBack = apiCallBack([{state:'submitDisabled', value:false}]);

      let doNotChangeFee = 'no';
      if(state.doNotChangeFee)
        doNotChangeFee = 'yes';

      let parameters = [
        {
          field:'ID',
          value:state.id
        },
        {
          field:'appraiser',
          value:state.selectedAppraisers[0]
        },
        {
          field:'turntime',
          value:state.turnAroundTimeForBid
        },
        {
          field:'comment',
          value:state.commentForBid
        },
        {
          field:'fee',
          value:state.appraiserFeeForBid
        },
        {
          field:'accept_onbehalf',
          value:state.acceptOnBehalf
        },
        {
          field:'doNotChangeFee',
          value:doNotChangeFee
        }
      ];

      httpPost('appraisal/assign', parameters, 'Order has been assigned.', 'Oops, something went wrong and could not assign for this appraisal. Please try again later.', callBack, errorCallBack);
    }
  }

  const setAcceptance = (action) => {
    let successMsg = 'Assignment accepted successfully.';
    if(action==='no')
      successMsg = 'Assignment declined successfully.';
    let errorMsg = 'Oops, something went wrong and could not accept the assignment. Please try again later.';
    if(action==='no')
      errorMsg = 'Oops, something went wrong and could not decline the assignment. Please try again later.';
    

    let parameters = [
      {
        field:'orderID',
        value:state.id
      },
      {
        field:'appraiserFk',
        value:props.appraisal.appraiser_fk
      },
      {
        field:'action',
        value:action
      }
    ];

    if(action==='no'){
      let tmp = {
        field:'reason',
        value:state.declineReason
      };

      parameters.push(tmp);

      tmp = {
        field:'comment',
        value:state.declineComment
      }

      parameters.push(tmp);
    }


    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let appraisal = Object.assign({}, props.appraisal);
        if(action==='yes')
          appraisal.status='Pending Inspection';
        else{
          declinePopUpToggle();
          appraisal.status='Submitted';
        }
        props.updateAppraisal(appraisal);
        getAssignmentHistories();
      }
    };
    callBack = callBack.bind(this);
  
    httpPost('appraisal/assignment/noSession/acceptance', parameters, successMsg, errorMsg, callBack);
  }

  // IS Hybrid Auto Assign Being used?
  // const deleteManualAutoAssign = (ID) => {
  //   let callBack = apiCallBack([], getManualAutoAssign());
  //   httpDelete('autoAssign/manual/appraiser/'+ID,'Profile deleted.','Oops, something went wrong and could not delete the auto assign candidate. Please try again later.', callBack);
  // }

  const conditionallyAccept = (e) => {
    e.preventDefault();
    let parameters = [
      {
        field:'orderID',
        value:state.id
      },
      {
        field:'appraiserFk',
        value:props.appraisal.appraiser_fk
      },
      {
        field:'reason',
        value:state.conditionReason
      },
      {
        field:'fee',
        value:state.conditionFee
      },
      {
        field:'turntime',
        value:state.conditionTurntime
      },
      {
        field:'comment',
        value:state.conditionComment
      }
    ];

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;

      if(code==='00'){
        setState({valid:false})
        props.updateAppraisal(Object.assign({}, props.appraisal, {status:'Submitted'}));
        conditionPopUpToggle();
        getAssignmentHistories();
      }
    };
    callBack = callBack.bind(this);

    httpPost('appraisal/assignment/noSession/conditionallyAccept', parameters, 'Assignment conditionally accepted successfully.', 'Oops, something went wrong and could not conditionally accept the assignment. Please try again later.', callBack);
  }

  const getConditionReasons = () => {
    let callBack = apiCallBack([{state:'conditionReasons', key:'data'}]);
    httpGet('appraisal/conditionReason/get', '', 'Oops, something went wrong and could not load the information of this appraisal. Please try again later.', callBack);
  }

  const getDeclineReasons = () => {
    let callBack = apiCallBack([{state:'declineReasons', key:'data'}]);
    httpGet('appraisal/declineReason/get', '', 'Oops, something went wrong and could not load the information of this appraisal. Please try again later.', callBack);
  }

  const getAppraiserAvgFeeMap = () => {
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);
    let url = 'report/map/fee/openBy='+state.openBy+'&client='+state.client+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+props.appraisal.property_state+'&city='+props.appraisal.property_city+'&street='+props.appraisal.property_street+'&county='+props.appraisal.property_county+'&zip='+props.appraisal.property_zip+'&appraisalType='+appraisalType+'&searchBy=address&miles='+state.radius;

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let lat = response.data.data.lat;
        let lng = response.data.data.lng;
        if(lat !== 0 && lng !== 0){
          setState({latitude:lat, longitude:lng});
        }
        setState({mapAppraisers:response.data.data.appraiser, standardDev:response.data.data.standard_dev, showOrderDetail:false, orderDetailID:-1});
        //console.log(response.data.data.appraiser);
      }
    };
    callBack = callBack.bind(this);

    httpGet(url, '', 'Oops, something went wrong and could not load appraiser fee map arround this property. Please try again later.', callBack);
  }

  //render
  let spreadWatchQuestionnaire;

  if(state.showSpreadWatchQuestionnaire){
    spreadWatchQuestionnaire = <SpreadWatchQuestionnaire appraisal_fk={state.id} callBack={()=>{setState({showSpreadWatchQuestionnaire:false});}} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
  }

  let from, to;
  if(state.from!=='')
    from = moment(state.from);
  if(state.to!=='')
    to = moment(state.to);

  const columns4 = [
    {
      id: 'appraiser',
      Header: 'Appraiser',
      accessor: d => d.first_name+" "+d.last_name,
      Cell: props => {

        return (
          <div>
            <div>
              <i onClick={(e)=>{getAppraiserPipeline(props.row.original.ID);toggleAppraiserPipeline();}} className="fa fa-drivers-license-o cursor-pointer link-color"></i>&nbsp;
              <NavLink id={"memo"+props.row.original.ID} to={"/appraiser/"+props.row.original.ID}>{props.row.original.first_name+' '+props.row.original.last_name}</NavLink>
            </div>
            <div className="display-inline">
              Office: <a href={"tel:"+props.row.original.phone}>{props.row.original.phone}</a>
            </div>
            <br/>
            <div className="display-inline">
              Cell: <a href={"tel:"+props.row.original.secondary_phone}>{props.row.original.secondary_phone}</a>
            </div>
            <br/>
          </div>
        );
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'email',
      Header: 'Email',
      accessor: d => d.email,
      Cell: props =>{
        return (
          <div>
            <a href={"mailto:"+props.row.original.email}>{props.row.original.email}</a><br/>
          </div>
        );
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'address',
      Header: 'Address',
      accessor: d => d.street+' '+d.city+', '+d.state,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'avgFee',
      Header: 'Average Fee',
      minWidth:100,
      width:100,
      maxWidth:100,
      accessor: d => parseInt(d.avg_fee, 10),
      Cell: props =>{
        let fee = <div>-</div>;

        if(props.row.original.avg_fee!==-1 )
          fee = <div>
            $ {formatNumber(props.row.original.avg_fee)}
          </div>;
        return <div>
          {fee}
        </div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'orders',
      Header: 'Orders',
      accessor: d => d.appraisals.length,
      Cell: props =>{
        return (
          <div>
            <NavLink to="#" onClick={(e)=>clickOrderDetail(props.row.original.appraisals, props.row.appraiser, props.row.original.ID)}>Click for Details</NavLink>
          </div>
        );
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'dateJoined',
      Header: 'Date Joined',
      accessor: d => d.datetime_created,
      Cell: props =>{
        return (
          <div>{formatDateTime(props.row.original.datetime_created)}</div>
        );
      },
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  const columns5 = [
    {
      id: 'referenceNumber',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'loanNum',
      Header: 'Loan #',
      accessor: d => d.loan_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'borrower',
      Header: 'Borrower',
      accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'property',
      Header: 'Property',
      accessor: d => d.property_street+' '+d.property_city+', '+d.property_state+' '+d.property_zip,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'fee',
      Header: 'Fee',
      minWidth:100,
      width:100,
      maxWidth:100,
      accessor: d => parseInt(d.fee, 10),
      Cell: props =>{
        let fee = <div>-</div>;

        if(props.row.original.fee!==-1 )
          fee = <div>
            $ {formatNumber(props.row.original.fee)}
          </div>;
        return <div>
          {fee}
        </div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'orderInfo',
      Header: 'Order Information',
      accessor: d => d.appraisal_type,
      Cell: props =>{
        return (
          <div>
            <i className="fa fa-tags icon-color"></i>&nbsp;
            {props.row.original.appraisal_type}
            <br/>
            <i className="fa fa-home icon-color"></i>&nbsp;
            {props.row.original.property_type}
            <br/>
            <i className="fa fa-hashtag icon-color"></i>&nbsp;
            {props.row.original.loan_purpose}
          </div>
        );
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'dateSubmitted',
      Header: 'Submitted',
      accessor: d => d.datetime_submitted,
      Cell: props =>{
        return (
          <div>{formatDateTime(props.row.original.datetime_submitted)}</div>
        );
      },
      headerStyle: {
        textAlign:'left'
      }
    },
  ];

  let appraiserPipelineInspectionNeeded;
  let appraiserPipelineReportNeeded;
  let appraiserPipelineConditionNeeded;
  let appraiserPipelineRebuttalNeeded;
  let appraiserPipelineInspectionScheduled;

  if(state.appraiserPipeline&&state.appraiserPipeline.inspection_needed){
    appraiserPipelineInspectionNeeded = state.appraiserPipeline.inspection_needed.map(
      (order, index)=>{
        return <tr key={index}>
          <td><NavLink to={"/appraisal/"+order.ID}>{order.reference_num}</NavLink></td>
          <td>{order.loan_num}</td>
          <td>{order.borrower_f_name+' '+order.borrower_l_name}</td>
          <td>{order.property_street+' '+order.property_city+', '+order.property_state+' '+order.property_zip}</td>
          <td>{formatDateTime(order.datetime_accepted)}</td>
        </tr>
      }
    )
  }

  if(state.appraiserPipeline&&state.appraiserPipeline.report_needed){
    appraiserPipelineReportNeeded = state.appraiserPipeline.report_needed.map(
      (order, index)=>{
        return <tr key={index}>
          <td><NavLink to={"/appraisal/"+order.ID}>{order.reference_num}</NavLink></td>
          <td>{order.loan_num}</td>
          <td>{order.borrower_f_name+' '+order.borrower_l_name}</td>
          <td>{order.property_street+' '+order.property_city+', '+order.property_state+' '+order.property_zip}</td>
          <td>{formatDateTime(order.datetime_inspection)}</td>
        </tr>
      }
    )
  }

  if(state.appraiserPipeline&&state.appraiserPipeline.condition_needed){
    appraiserPipelineConditionNeeded = state.appraiserPipeline.condition_needed.map(
      (order, index)=>{
        return <tr key={index}>
          <td><NavLink to={"/condition/"+order.ID}>{order.reference_num}</NavLink></td>
          <td>{order.loan_num}</td>
          <td>{order.borrower_f_name+' '+order.borrower_l_name}</td>
          <td>{order.property_street+' '+order.property_city+', '+order.property_state+' '+order.property_zip}</td>
          <td>{formatDateTime(order.datetime_requested)}</td>
        </tr>
      }
    )
  }

  if(state.appraiserPipeline&&state.appraiserPipeline.rebuttal_needed){
    appraiserPipelineRebuttalNeeded = state.appraiserPipeline.rebuttal_needed.map(
      (order, index)=>{
        return <tr key={index}>
          <td><NavLink to={"/rebuttal/"+order.ID}>{order.reference_num}</NavLink></td>
          <td>{order.loan_num}</td>
          <td>{order.borrower_f_name+' '+order.borrower_l_name}</td>
          <td>{order.property_street+' '+order.property_city+', '+order.property_state+' '+order.property_zip}</td>
          <td>{formatDateTime(order.datetime_requested)}</td>
        </tr>
      }
    )
  }

  if(state.appraiserPipeline&&state.appraiserPipeline.inspection_scheduled){
    appraiserPipelineInspectionScheduled = state.appraiserPipeline.inspection_scheduled.map(
      (order, index)=>{
        return <tr key={index}>
          <td><NavLink to={"/rebuttal/"+order.ID}>{order.reference_num}</NavLink></td>
          <td>{order.loan_num}</td>
          <td>{order.borrower_f_name+' '+order.borrower_l_name}</td>
          <td>{order.property_street+' '+order.property_city+', '+order.property_state+' '+order.property_zip}</td>
          <td>{formatDateTime(order.datetime_requested)}</td>
        </tr>
      }
    )
  }

  let markers=[];
  if(state.appraisers.length>0){
    for(let i=0;i<state.appraisers.length;i++){
      if(state.appraisers[i].lat!==''&&state.appraisers[i].lng!==''){
        let location = {};
        location.lat = state.appraisers[i].lat;
        location.lng = state.appraisers[i].lng;
        location.name = state.appraisers[i].first_name+" "+state.appraisers[i].last_name;
        location.label = '';

        markers.push(location);
      }
    }
  }

  let markers2=[];
  if(state.mapAppraisers.length>0){
    for(let i=0;i<state.mapAppraisers.length;i++){
      if(state.mapAppraisers[i].lat!==''&&state.mapAppraisers[i].lng!==''&&state.mapAppraisers[i].avg_fee!==0){
        let location = {};
        location.lat = state.mapAppraisers[i].lat;
        location.lng = state.mapAppraisers[i].lng;
        location.name = state.mapAppraisers[i].first_name+" "+state.mapAppraisers[i].last_name;
        location.label ="$"+state.mapAppraisers[i].avg_fee;
        location.select = false;
        if(state.showOrderDetail && state.orderDetailID !== state.mapAppraisers[i].ID){
          location.select = true;
        }
        markers2.push(location);
      }
    }
  }
  
  let acceptanceButton;


  if(props.appraisal.status==='Assigned'){
    acceptanceButton = <div>
      <br/><br/>
      <center>
        <Button style={{width:'300px'}} color="warning" onClick={()=>setAcceptance('yes')}><i className="fa fa-check"></i> Accept On Behalf</Button>&nbsp;&nbsp;&nbsp;&nbsp;
        <Button style={{width:'170px'}} color="warning" onClick={conditionPopUpToggle}><i className="fa fa-info"></i> Conditionally accept</Button>&nbsp;&nbsp;&nbsp;&nbsp;
        <Button style={{width:'170px'}} color="warning" onClick={declinePopUpToggle}><i className="fa fa-times"></i> Decline</Button>
      </center>
    </div>
  }
  let turnAroundTimeForBid;
  if(state.turnAroundTimeForBid!=='')
    turnAroundTimeForBid = moment(state.turnAroundTimeForBid).toDate();


  let conditionFee, conditionTurntime, conditionTurntimeValue;

  if(state.conditionTurntime!=='')
    conditionTurntimeValue = moment(state.conditionTurntime).toDate();

  if(state.conditionReason==='Fee'||state.conditionReason==='Fee & Turntime')
    conditionFee =
    <div>
      <label>Fee</label>
      <Input type="text" value={state.conditionFee} onChange={(e)=>setState({conditionFee:e.target.value})}/>
    </div>;

  if(state.conditionReason==='Turntime'||state.conditionReason==='Fee & Turntime')
    conditionTurntime =
    <div>
      <label>Due date</label>
      &nbsp;<DatePicker
        onChangeRaw={(e)=>{e.preventDefault()}}
        className="form-control"
        selected={conditionTurntimeValue}
        onChange={(text)=>{setState({conditionTurntime:text.toLocaleDateString('en-CA')})}}
        dateFormat="yyyy/MM/dd"
      />
    </div>;

  let filtersConfig = [
    {id:'from',value:from, updateFunc:(v)=>setState({from:v}), width:'2'},
    {id:'to',value:to, updateFunc:(v)=>setState({to:v}), width:'2'},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'4'},
    {id:'openBy',value:state.openBy, updateFunc:(v)=>setState({openBy:v}), width:'4'},
    {id:'rush',value:state.isRush, updateFunc:(v)=>setState({isRush:v}), width:'2'},
    {id:'complex',value:state.isComplex, updateFunc:(v)=>setState({isComplex:v}), width:'2'},
    {id:'entity',value:state.entity, updateFunc:(v)=>setState({entity:v}), width:'4'},
    {id:'appraisalType',value:state.filterAppraisalTypes, updateFunc:(v)=>setState({filterAppraisalTypes:v}), width:'4'},
    {id:'loanPurpose',value:state.loanPurpose, updateFunc:(v)=>setState({loanPurpose:v}), width:'2'},
    {id:'loanType',value:state.loanType, updateFunc:(v)=>setState({loanType:v}), width:'2'},
    {id:'custom',value:state.radius, updateFunc:(v)=>setState({radius:v}), width:'2', label:'Radius', placeHolder:"miles"},
    {id:'button',value:'Submit', updateFunc:getAppraiserAvgFeeMap, width:'6', className:"align-right", color:"warning"},
  ];

  let sendContent = <AssignmentDraft
    id={state.id}
    totalLenderFee={state.totalLenderFee}                    
    totalAppraiserFee={state.appraiserFees}
    turnAroundTimeForBid={turnAroundTimeForBid}
    setState={(name, value)=>setState({[name]:value})}
    appraiserFeeForBid={state.appraiserFeeForBid}
    handleChangeInput={handleChangeInput}
    showLoading={props.showLoading}
    hideLoading={props.hideLoading}
    logout={props.logout}
    selectFromBid={false}
    selectedAppraisers={state.selectedAppraisers}
    appraiserInfoMap={state.appraiserInfoMap}
    appraisal={props.appraisal}
    acceptOnBehalf={state.acceptOnBehalf}
    bidDisabled={state.bidDisabled}
    submitDisabled={state.submitDisabled}
    bidButtonHandler={bidButtonHandler}
    buttonHandler={buttonHandler}
    toggleVettingPage={toggleVettingPage}
    manualAutoAssign={state.manualAutoAssign}
  />;

  if(state.showQuoteVetting){
    sendContent = <AssignmentVet 
      id={state.id}
      showLoading={props.showLoading}
      hideLoading={props.hideLoading}
      logout={props.logout}
      appraisal={props.appraisal}
      entities={state.entities}
      appraisers={state.appraisers}
      toggleVettingPage={toggleVettingPage}
      selectedAppraisers={state.selectedAppraisers}
      appraiserInfoMap={state.appraiserInfoMap}
      setState={(name, value)=>setState({[name]:value})}
      updateAppraisal={props.updateAppraisal}
      updateOverallStatus={props.updateOverallStatus}
      overallStatus={props.overallStatus}
    />
  }

  return <div>
    <Card  style={{borderTop:'0px'}}>
      <CardHeader className="header-color">
        <i className="fa fa-home"></i>&nbsp;{props.appraisal.reference_num} - {props.appraisal.property_street} {props.appraisal.property_city}, {props.appraisal.property_state} {props.appraisal.property_zip} - {props.appraisal.borrower_f_name} {props.appraisal.borrower_l_name}
      </CardHeader>
      <CardBody>
        {
          props.messages.map(
            (message, index)=>{
              if(message.link){
                return(<a key={index} href={'/'+message.link+'/'+message.ID} target="_blank" rel="noreferrer"><Alert key={index} color="warning" className="my-alert">
                    <i className="fa fa-information"></i> {message.msg}
                  </Alert></a>);
              }

              return(<Alert key={index} color="warning" className="my-alert">
                  <i className="fa fa-information"></i> {message.msg}
                </Alert>);
            }
          )
        }
      </CardBody>
    </Card>
    <br/>
    <AssignmentList list={state.selectedAppraisers} listInfoMap={state.appraiserInfoMap} removeAppraiser={removeAppraiser}/>
    {spreadWatchQuestionnaire}
    <Modal className="my-modal" isOpen={state.declinePopUp} toggle={declinePopUpToggle} >
      <ModalHeader hidden={true} toggle={declinePopUpToggle}></ModalHeader>
      <ModalBody>
        <form onSubmit={(e)=>{e.preventDefault();setAcceptance('no')}}>
          <label>Reason</label>
          <MySelect
            modal={true}
            type="select"
            value={state.declineReason}
            onChange={(v)=>setState({declineReason:v})}
            options={state.declineReasons.map((reason)=>{
              return {label:reason.reason, value:reason.reason};
            })}
          />
          <br/>
          <label>Comment</label>
          <Input required={true} onChange={(e)=>setState({declineComment:e.target.value})} type="textarea" rows="5" style={{resize:'none'}}></Input>
          <br/>

          <b><font color="red">*</font>By declining this assignment you will no longer be able to access this order.</b>
          <div className="my-divider">&nbsp;</div>
          <center>
            <Button color="info" onClick={declinePopUpToggle}>Close</Button>&nbsp;
            <Button color="danger">Decline</Button>
          </center>
        </form>
      </ModalBody>
    </Modal>

    <Modal className="my-modal" isOpen={state.conditionPopUp} toggle={conditionPopUpToggle} >
      <ModalHeader hidden={true} toggle={conditionPopUpToggle}></ModalHeader>
      <ModalBody>
        <form onSubmit={conditionallyAccept}>
          <label>Reason</label>
          <MySelect
            modal={true}
            type="select"
            value={state.conditionReason}
            onChange={(v)=>setState({conditionReason:v})}
            options={state.conditionReasons.map((reason)=>{
              return {label:reason.reason, value:reason.reason};
            })}
          />
          <br/>
          {conditionFee}
          <br/>
          {conditionTurntime}
          <label>Comment</label>
          <Input required={true} onChange={(e)=>setState({conditionComment:e.target.value})} type="textarea" rows="5" style={{resize:'none'}}></Input>
          <br/>

          <b><font color="red">*</font>By conditionally accept this assignment, the order will be temporary taken away from you for consideration of your request.</b>
          <div className="my-divider">&nbsp;</div>
          <center>
            <Button color="info" onClick={conditionPopUpToggle}>Close</Button>&nbsp;
            <Button color="danger">Conditionally accept</Button>
          </center>
        </form>
      </ModalBody>
    </Modal>
    <Modal className="my-modal" isOpen={state.appraiserPipelineDropDownOpen} toggle={toggleAppraiserPipeline} >
      <ModalHeader hidden={true} toggle={toggleAppraiserPipeline}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-warning"></i> Appraiser Pipeline Overview</h5>
        </center>
        <br/>

        <Card>
          <CardHeader>
            Pending Inspection
          </CardHeader>
          <CardBody>
            <div className="medium-scroll-container-no-min">
              <Table className="table table-striped">
                <thead>
                  <tr>
                    <th width="15%">Reference #</th>
                    <th width="15%">Loan #</th>
                    <th width="15%">Borrower</th>
                    <th width="25%">Property</th>
                    <th width="20%">Date Accepted</th>
                  </tr>
                </thead>
                <tbody>
                  {appraiserPipelineInspectionNeeded}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>

        <br/>

        <Card>
          <CardHeader>
            Inspection Scheduled
          </CardHeader>
          <CardBody>
            <div className="medium-scroll-container-no-min">
              <Table className="table table-striped">
                <thead>
                  <tr>
                    <th width="15%">Reference #</th>
                    <th width="15%">Loan #</th>
                    <th width="15%">Borrower</th>
                    <th width="25%">Property</th>
                    <th width="20%">Date Accepted</th>
                  </tr>
                </thead>
                <tbody>
                  {appraiserPipelineInspectionScheduled}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>

        <br/>

        <Card>
          <CardHeader>
            Report Expected
          </CardHeader>
          <CardBody>
            <div className="medium-scroll-container-no-min">
              <Table className="table table-striped">
                <thead>
                  <tr>
                    <th width="15%">Reference #</th>
                    <th width="15%">Loan #</th>
                    <th width="15%">Borrower</th>
                    <th width="25%">Property</th>
                    <th width="20%">Inspection Date</th>
                  </tr>
                </thead>
                <tbody>
                  {appraiserPipelineReportNeeded}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>

        <br/>

        <Card>
          <CardHeader>
            Condition Started
          </CardHeader>
          <CardBody>
            <div className="medium-scroll-container-no-min">
              <Table className="table table-striped">
                <thead>
                  <tr>
                    <th width="15%">Reference #</th>
                    <th width="15%">Loan #</th>
                    <th width="15%">Borrower</th>
                    <th width="25%">Property</th>
                    <th width="20%">Date Requested</th>
                  </tr>
                </thead>
                <tbody>
                  {appraiserPipelineConditionNeeded}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>

        <br/>

        <Card>
          <CardHeader>
            Rebuttal Started
          </CardHeader>
          <CardBody>
            <div className="medium-scroll-container-no-min">
              <Table className="table table-striped">
                <thead>
                  <tr>
                    <th width="15%">Reference #</th>
                    <th width="15%">Loan #</th>
                    <th width="15%">Borrower</th>
                    <th width="25%">Property</th>
                    <th width="20%">Date Requested</th>
                  </tr>
                </thead>
                <tbody>
                  {appraiserPipelineRebuttalNeeded}
                </tbody>
              </Table>
            </div>
          </CardBody>
        </Card>
        <center>
          <Button color="info" onClick={toggleAppraiserPipeline}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
    {/* Not bewing used */}
    {/* <Modal className="my-modal" isOpen={state.manualAutoAssignPopUp} toggle={toggleAutoAssign} >
      <ModalHeader hidden={true} toggle={toggleAutoAssign}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-warning"></i> Hybrid Auto Assign</h5>
        </center>
        <div className="large-scroll-container-no-min">
          <Table className="table table-striped">
            <thead>
              <tr><th>Action</th><th>Active</th><th>Appraiser</th><th>Date Added</th></tr>
            </thead>
            <tbody>
              {
                state.manualAutoAssign.map(
                  (autoAssign, index)=>{
                    let icon;

                    if(autoAssign.active==='yes')
                      icon = <i className="fa fa-times red-color cursor-pointer" onClick={(e)=>{deleteManualAutoAssign(autoAssign.ID)}}/>;

                    return <tr key={index}>
                      <td width="10%">{icon}</td>
                      <td width="10%">{autoAssign.active}</td>
                      <td width="50%"><NavLink to={"/appraiser/"+autoAssign.appraiserFk} target="_blank">{autoAssign.first_name+' '+autoAssign.last_name}</NavLink></td>
                      <td>{formatDateTime(autoAssign.datetime_created)}</td>
                    </tr>
                  }
                )
              }
            </tbody>
          </Table>
        </div>
        <center>
          <Button color="info" onClick={toggleAutoAssign}>Close</Button>
        </center>
      </ModalBody>
    </Modal> */}
    {acceptanceButton}
    <br/>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-map"></i>&nbsp;Map
      </CardHeader>
      <CardBody>
        {(!state.clickedFeeMap && props.appraisal && props.appraisal.property_lat && props.appraisal.property_lat!=='')? <MyGoogleMap lat={parseFloat(props.appraisal.property_lat)} lng={parseFloat(props.appraisal.property_lng)} markers={markers}/>:null}
        {(state.clickedFeeMap)?
          <div>
            <MyGoogleMap lat={state.latitude} lng={state.longitude} markers={markers2}/>
            <div className="align-left"><b><font color="red">*</font>Standard Deviation: </b>{state.standardDev}</div>
          </div>:null
        }
      </CardBody>
    </Card>
    <br/>
    <div className="my-divider"></div>
    <Nav tabs>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.activeTab === '1'?"active":"inactive" )}
          onClick={() => { activeTabToggle('1'); }}
          to="#"
        >
          Assignment
        </NavLinkL>
      </NavItem>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.activeTab === '2'?"active":"inactive" ) }
          onClick={() => { activeTabToggle('2'); }}
          to="#"
        >
          AQB
        </NavLinkL>
      </NavItem>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.activeTab === '3'?"active":"inactive" ) }
          onClick={() => { activeTabToggle('3'); }}
          to="#"
        >
          Bid Log
        </NavLinkL>
      </NavItem>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.activeTab === '4'?"active":"inactive" ) }
          onClick={() => { activeTabToggle('4'); }}
          to="#"
        >
          Appraiser Fee
        </NavLinkL>
      </NavItem>
    </Nav>
    <Card>
      {state.activeTab === '1'&&<CardHeader className="header-color">
        <i className="fa fa-sitemap"></i>&nbsp;Assignment
      </CardHeader>}
      {state.activeTab === '2'&&<CardHeader className="header-color">
        <i className="fa fa-user-plus"></i>&nbsp;AQB - Look Up Appraiser
      </CardHeader>}
      {state.activeTab === '4'&&<CardHeader className="header-color">
        <i className="fa fa-street-view"></i>&nbsp;Appraiser Avg Fee Map
      </CardHeader>}
      <CardBody>
        <TabContent activeTab={state.activeTab}>
          <TabPane tabId="1">
            {(props.appraisal.ID&&props.appraisal.ID!==-1&&state.tabHistory.indexOf('1')!==-1)&&<AssignmentTab
              id={state.id}
              setState={(name, value)=>setState({[name]:value})}
              appraisal={props.appraisal}
              getAppraisers={getAppraisers}
              recordBid={recordBid}
              getAppraiserPipeline={getAppraiserPipeline}
              toggleAppraiserPipeline={toggleAppraiserPipeline}
              appraisers={state.appraisers}
              showLoading={props.showLoading}
              hideLoading={props.hideLoading}
              logout={props.logout}
              isCheck={isCheck}
              clickRowListener={clickRowListener}
              county={state.county}
              searchRadius={state.searchRadius}
              searchKeyword={state.searchKeyword}
            />}
          </TabPane>
          <TabPane tabId="2">
            {(props.appraisal.ID&&props.appraisal.ID!==-1&&state.tabHistory.indexOf('2')!==-1)&&<AQB
              setState={(name, value)=>setState({[name]:value})}
              appraisal={props.appraisal}
              aqbCounty={state.aqbCounty}
              recordBid={recordBid}
              showLoading={props.showLoading}
              hideLoading={props.hideLoading}
              logout={props.logout}
            />}
          </TabPane>
          <TabPane tabId="3">
            {(props.appraisal.ID&&props.appraisal.ID!==-1&&state.tabHistory.indexOf('3')!==-1)&&<BidLog
              id={state.id}
              setState={(name, value)=>setState({[name]:value})}
              recordBid={recordBid}
              getBid={getBid}
              bids={state.bids}
              isCheck={isCheck}
              clickRowListener={clickRowListener}
              showLoading={props.showLoading}
              hideLoading={props.hideLoading}
              logout={props.logout}
            />}
          </TabPane>
          <TabPane tabId="4">
            <ReportFilter {...props} configs={filtersConfig}/>
            <br/>
            <br/>
            <br/>
            {!state.showOrderDetail &&
            <div className="larger-scroll-container-no-min">
              <MyReactTable columns={columns4} data={state.mapAppraisers} className="table table-striped"/>
            </div>}
            {state.showOrderDetail &&
            <div>
              <Row>
                <Col>
                  <div className="align-left" style={{fontSize:'20px'}}><i className="fa fa-user online-color"></i> <b>{state.orderDetailName}</b></div>
                </Col>
                <Col>
                  <div className="align-right"><NavLink to="#" onClick={(e)=>setState({showOrderDetail:false, orderDetailID:-1})}>Back to appraiser list</NavLink></div>
                </Col>
              </Row>
              <div className="larger-scroll-container-no-min">
                <MyReactTable columns={columns5} data={state.appraisalDetails} className="table table-striped"/>
              </div>
            </div>}
          </TabPane>
        </TabContent>
      </CardBody>
    </Card>
    <br/>
    {(props.appraisal.ID&&props.appraisal.ID!==-1&&(state.activeTab==='1'||state.activeTab==='3'))&&sendContent}
    <br/>
  </div>;
}


export default Assignment;
