//Author Sooyoung Kim
//Date May 1, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, deleteAPICallGenerator, callBackGenerator, formatDate, confirmation} from '../../util/util';
import {Button, Card, CardHeader, CardBody, Modal, ModalBody, ModalHeader, Col, Row, Input, NavItem, Nav, TabContent, TabPane, NavLink as NavLinkL} from 'reactstrap';
import MyXEditable from '../util/my-xeditable';
import MySelect from '../util/my-select';
import {NavLink} from 'react-router-dom';
import {sliceFromArray} from '../../util/util';
import MyReactTable from '../util/my-react-table';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import React, {useReducer, useEffect} from 'react';

//initialize the state
const initialState = {
  accountManagers:[],
  admins:[],
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
  addNewAccountManagerPopUp:false,
  addNewAssignmentProfilePopUp:false,
  addNewBlockAssignmentProfilePopUp:false,
  showAssignmentProfilePopUp:false,
  showBlockAssignmentProfilePopUp:false,
  clients:[],
  clientBranches:[],
  openByClientBranches:[],
  unassignedOrder:'yes',
  prefix:'',

  selectedAccountManager:{
    profiles:[],
    block_profiles:[]
  },
  accountManagerFk:'',
  name:'',
  state:'',
  county:'',
  clientFk:'0',
  clientBranchFk:'0',
  openByClient:'0',
  openByClientBranchFk:'0',
  ae:'',
  loanOfficer:'',
  loanProcessor:'',
  etaOrderVolume:'',
  activeTab:'1',
  detailsPopUpOpen:false,
  orderDetails:[],
  unassignedOrderDetails:[],
  from:'',
  to:'',
};

//reducer function that perform state update
const reducer = getReducer();


const AccountManager  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getAllClientProfiles();
    getAllAdmins();
    getAccountManagers();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const toggleAddNewAccountManager = () =>{
    setState({addNewAccountManagerPopUp:!state.addNewAccountManagerPopUp});
  }

  const toggleAddAssignmentProfile = () =>{
    setState({addNewAssignmentProfilePopUp:!state.addNewAssignmentProfilePopUp});
  }

  const toggleAddBlockAssignmentProfile = () =>{
    setState({addNewBlockAssignmentProfilePopUp:!state.addNewBlockAssignmentProfilePopUp});
  }

  const toggleShowAssignmentProfile = () =>{
    setState({showAssignmentProfilePopUp:!state.showAssignmentProfilePopUp});
  }

  const toggleShowBlockAssignmentProfile = () =>{
    setState({showBlockAssignmentProfilePopUp:!state.showBlockAssignmentProfilePopUp});
  }

  const setActiveTabToggle = (tab) =>{
    if(tab === 2){
      getUnassignedOrderDetails();
    }
    setState({activeTab:tab});
  }

  const orderDetailsToggle = () =>{
    setState({orderDetails:[],detailsPopUpOpen:!state.detailsPopUpOpen});
  }

  const updateCallBack = (accountManagerID, field, value) =>{
    let accountManagers = state.accountManagers.slice();

    for(let i=0;i<accountManagers.length;i++){
      if(accountManagers[i].ID===accountManagerID){
        accountManagers[i][field] = value;
        break;
      }
    }

    setState({accountManagers:accountManagers});
  }

  //API call
  //generate a function that do the update on editable
  const generateUpdateFunction = (url, parameters, successMessage, failedMessage, parentCallBack=null) =>{
    return (newValue, callBack, errorCallBack)=>{
      //add in the new value into parameters

      parameters.push({field:'value',value:newValue});
      let promise = httpPut(url,parameters,successMessage,failedMessage, callBack, errorCallBack);
      promise.then(
        (response)=>{
          let field;
          let value;

          for(let i=0;i<parameters.length;i++){
            if(parameters[i].field==='field')
              field = parameters[i].value;
            else if(parameters[i].field==='value')
              value = parameters[i].value;
          }
          if(parentCallBack!==null){
            parentCallBack(field, value);
          }
        }
      );
    }
  };

  const deleteAccountManagerProfiles = (ID) =>{
    let url = 'accountManager/profile/'+ID;
    let successMsg = 'Account manager assignment profile deleted.';
    let errorMsg = 'Oops, something went wrong and could not delete account manager assignment profile. Please try again later.';
    let list = sliceFromArray(state.accountManagers, ['ID','ID'],[state.selectedAccountManager.ID, ID], ['profiles']);
    let callBack = apiCallBack([{state:'accountManagers',value:list}]);
    httpDelete(url,successMsg,errorMsg, callBack);
  }

  const deleteAccountManagerBlockProfiles = (ID) =>{
    let url = 'accountManager/block/profile/'+ID;
    let successMsg = 'Account manager assignment profile deleted.';
    let errorMsg = 'Oops, something went wrong and could not delete account manager assignment profile. Please try again later.';
    let list = sliceFromArray(state.accountManagers, ['ID','ID'],[state.selectedAccountManager.ID, ID], ['block_profiles']);
    let callBack = apiCallBack([{state:'accountManagers',value:list}]);
    httpDelete(url,successMsg,errorMsg, callBack);
  }

  const deleteAccountManager = (ID) =>{
    let url = 'accountManager/'+ID;
    let successMsg = 'Account manager deleted.';
    let errorMsg = 'Oops, something went wrong and could not delete account manager. Please try again later.';
    let list = sliceFromArray(state.accountManagers, 'ID', ID)
    let callBack = apiCallBack([{state:'accountManagers',value:list}]);
    httpDelete(url,successMsg,errorMsg, callBack);
  }

  const createNewAccountManager = (e) =>{
    e.preventDefault();
    let url = 'accountManager/create';
    let successMsg = 'Admin promoted as account manager.';
    let errorMsg = 'Oops, something went wrong and could not promote this admin as account manager. Please try again later.';

    let parameters = [
      {
        field:'userFk',
        value:state.accountManager
      },
      {
        field:'unassignedOrder',
        value:state.unassignedOrder
      },
      {
        field:'prefix',
        value:state.prefix
      }
    ];

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let accountManagers = state.accountManagers.slice();
        accountManagers.push(response.data.data);

        setState({accountManagers:accountManagers});
      }
    };
    callBack = callBack.bind(this);

    httpPost(url, parameters, successMsg, errorMsg, callBack);
  }

  const createNewAccountManagerAssignmentProfile = (e) =>{
    e.preventDefault();

    let url = 'accountManager/profile/create';
    let successMsg = '';
    let errorMsg = 'Oops, something went wrong and could not create the profile. Please try again later.';

    let parameters = [
      {
        field:'state',
        value:state.state
      },
      {
        field:'county',
        value:state.county
      },
      {
        field:'clientFk',
        value:state.clientFk
      },
      {
        field:'clientBranchFk',
        value:state.clientBranchFk
      },
      {
        field:'openByClient',
        value:state.openByClient
      },
      {
        field:'openByClientBranchFk',
        value:state.openByClientBranchFk
      },
      {
        field:'ae',
        value:state.ae
      },
      {
        field:'loanOfficer',
        value:state.loanOfficer
      },
      {
        field:'loanProcessor',
        value:state.loanProcessor
      },
      {
        field:'accountManagerFk',
        value:state.accountManagerFk
      },
      {
        field:'name',
        value:state.name
      }
    ];

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let accountManagers = state.accountManagers.slice();

        for(let i=0;i<accountManagers.length;i++){
          if(accountManagers[i].ID===state.accountManagerFk){
            let profiles = accountManagers[i].profiles.slice();
            profiles.push(response.data.data);
            accountManagers[i].profiles = profiles;
            break;
          }
        }

        setState({accountManagers:accountManagers});
        toggleAddAssignmentProfile();
      }
    };
    callBack = callBack.bind(this);

    httpPost(url, parameters, successMsg, errorMsg, callBack);
  }

  const createNewAccountManagerBlockAssignmentProfile = (e) =>{
    e.preventDefault();

    let url = 'accountManager/block/profile/create';
    let successMsg = '';
    let errorMsg = 'Oops, something went wrong and could not create the profile. Please try again later.';

    let parameters = [
      {
        field:'state',
        value:state.state
      },
      {
        field:'county',
        value:state.county
      },
      {
        field:'clientFk',
        value:state.clientFk
      },
      {
        field:'clientBranchFk',
        value:state.clientBranchFk
      },
      {
        field:'openByClient',
        value:state.openByClient
      },
      {
        field:'openByClientBranchFk',
        value:state.openByClientBranchFk
      },
      {
        field:'ae',
        value:state.ae
      },
      {
        field:'loanOfficer',
        value:state.loanOfficer
      },
      {
        field:'loanProcessor',
        value:state.loanProcessor
      },
      {
        field:'accountManagerFk',
        value:state.accountManagerFk
      },
      {
        field:'name',
        value:state.name
      }
    ];

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let accountManagers = state.accountManagers.slice();

        for(let i=0;i<accountManagers.length;i++){
          if(accountManagers[i].ID===state.accountManagerFk){
            let blockProfiles = accountManagers[i].block_profiles.slice();
            blockProfiles.push(response.data.data);
            accountManagers[i].block_profiles = blockProfiles;
            break;
          }
        }

        setState({accountManagers:accountManagers});
        toggleAddBlockAssignmentProfile();
      }
    };
    callBack = callBack.bind(this);

    httpPost(url, parameters, successMsg, errorMsg, callBack);
  }

  const getETAOrderVolume = () =>{
    let url = 'accountManager/profile/eta';
    let successMsg = '';
    let errorMsg = 'Oops, something went wrong and could not estimate the order volume. Please try again later.';

    let parameters = [
      {
        field:'state',
        value:state.state
      },
      {
        field:'county',
        value:state.county
      },
      {
        field:'clientFk',
        value:state.clientFk
      },
      {
        field:'clientBranchFk',
        value:state.clientBranchFk
      },
      {
        field:'openByClient',
        value:state.openByClient
      },
      {
        field:'openByClientBranchFk',
        value:state.openByClientBranchFk
      },
      {
        field:'ae',
        value:state.ae
      },
      {
        field:'loanOfficer',
        value:state.loanOfficer
      },
      {
        field:'loanProcessor',
        value:state.loanProcessor
      }
    ];

    let callBack = apiCallBack([{state:'etaOrderVolume',key:'data'}]);
    httpPost(url, parameters, successMsg, errorMsg, callBack);
  }

  const getClientBranch = (ID) =>{
    let url = 'client/branch/get/'+ID;
    let callBack = apiCallBack([{state:'clientBranches',key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load account managers. Please try again later.', callBack);
  }

  const getOpenByClientBranch = (ID) =>{
    let url = 'client/branch/get/'+ID;
    let callBack = apiCallBack([{state:'openByClientBranches',key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load account managers. Please try again later.', callBack);
  }

  const getAccountManagers = () =>{
    let url = 'accountManager/date/get/from='+state.from+'&to='+state.to;
    let callBack = apiCallBack([{state:'accountManagers',key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load account managers. Please try again later.', callBack);
  }

  const getOrderDetails = (accountManagerID) =>{
    let url = 'accountManager/order/get/from='+state.from+'&to='+state.to+'&accountManagerID='+accountManagerID;
    let callBack = apiCallBack([{state:'orderDetails',key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load order details. Please try again later.', callBack);
  }

  const getUnassignedOrderDetails = () =>{
    let url = 'accountManager/unassigned/order/get/from='+state.from+'&to='+state.to;
    let callBack = apiCallBack([{state:'unassignedOrderDetails',key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load order details. Please try again later.', callBack);
  }

  const getAllClientProfiles = () =>{
    let url = 'client/get/limit=-1&offset=-1';

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){

        let clients = [];
        for(let i=0;i<response.data.data.length;i++){
          let client = response.data.data[i];

          let tmp = {};
          tmp.key = client.ID;
          tmp.value = client.company;

          clients.push(tmp);
        }

        setState({clients:clients});
      }
    };
    callBack = callBack.bind(this);

    httpGet(url, '', 'Oops, something went wrong and could not load order details. Please try again later.', callBack);
  }

  const getAllAdmins = () =>{
    let url = 'admin/get/limit=-1';
    let callBack = apiCallBack([{state:'admins',key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load account managers. Please try again later.', callBack);
  }

  //render
  let from, to;
  if(state.from!=='')
    from = moment(state.from).toDate();
  if(state.to!=='')
    to = moment(state.to).toDate();

  const columns = [
    {
      id: 'referenceNumber',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'loanNum',
      Header: 'Loan #',
      accessor: d => d.loan_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'client',
      Header: 'Client',
      accessor: d => d.client,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'clientBranch',
      Header: 'Client Branch',
      accessor: d => d.client_branch,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'openby',
      Header: 'Open By Client',
      accessor: d => d.open_by,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'openbyBranch',
      Header: 'Open By Client Branch',
      accessor: d => d.open_by_branch,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'ae',
      Header: 'AE',
      accessor: d => d.ae,
      Cell: props => props.row.original.ae.join(', '),
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'loanOfficer',
      Header: 'Loan Officer',
      accessor: d => d.loan_officer,
      Cell: props => props.row.original.loan_officer.join(', '),
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'processor',
      Header: 'Processor',
      accessor: d => d.loan_processor,
      Cell: props => props.row.original.loan_processor.join(', '),
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'state',
      Header: 'State',
      accessor: d => d.property_state,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'county',
      Header: 'County',
      accessor: d => d.property_county,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'dateSubmitted',
      Header: 'Submitted',
      accessor: d => formatDate(d.datetime_submitted),
      headerStyle: {
        textAlign:'left'
      }
    },
  ];

  let etaOrderVolume;

  if(state.etaOrderVolume!=='')
    etaOrderVolume = <div className="flashit"><font color="red"><b>ETA Order Volume: {state.etaOrderVolume}</b></font></div>;

  return <div className="my-well">
    <Modal className="my-modal-wide" isOpen={state.addNewAssignmentProfilePopUp} toggle={toggleAddAssignmentProfile} >
      <ModalHeader hidden={true} toggle={toggleAddAssignmentProfile}></ModalHeader>
      <ModalBody>
        {etaOrderVolume}
        <form onSubmit={createNewAccountManagerAssignmentProfile}>
          <Row>
            <Col sm="6">
              <label>Account Manager</label>
            </Col>
            <Col sm="6">
              <MySelect
                type="select"
                required={true}
                selectIsClearable={true}
                value={state.accountManagerFk}
                onChange={(v)=>{setState({accountManagerFk:v})}}
                options={state.accountManagers.map((ac)=>{
                  return {label:ac.name, value:ac.ID};
                })}
              />
            </Col>
          </Row>

          <Row>
            <Col sm="6">
              <label>Profile Name</label>
            </Col>
            <Col sm="6">
              <Input type="text" value={state.name} onChange={(e)=>setState({name:e.target.value})}/>
            </Col>
          </Row>

          <Row>
            <Col sm="6">
              <label>Close By Client</label>
            </Col>
            <Col sm="6">
              <MySelect
                type="select"
                selectIsClearable={true}
                value={state.clientFk}
                onChange={(v)=>{getClientBranch(v);setState({clientFk:v})}}
                options={state.clients.map((client)=>{
                  return {label:client.value, value:client.key};
                })}
              />
            </Col>
          </Row>

          <Row>
            <Col sm="6">
              <label>Close By Client Branch</label>
            </Col>
            <Col sm="6">
              <MySelect
                type="select"
                value={state.clientBranchFk}
                onChange={(v)=>{setState({clientBranchFk:v})}}
                options={[{label:"",value:"0"}].concat(
                  state.clientBranches.map((client)=>{
                    return {label:client.branch_name, value:client.ID};
                   })
                )}
              />
            </Col>
          </Row>

          <Row>
            <Col sm="6">
              <label>Open By Client</label>
            </Col>
            <Col sm="6">
              <MySelect
                type="select"
                selectIsClearable={true}
                value={state.openByClient}
                onChange={(v)=>{getOpenByClientBranch(v);setState({openByClient:v})}}
                options={state.clients.map((client)=>{
                  return {label:client.value, value:client.key};
                })}
              />
            </Col>
          </Row>

          <Row>
            <Col sm="6">
              <label>Open By Client Branch</label>
            </Col>
            <Col sm="6">
              <MySelect
                type="select"
                value={state.openByClientBranchFk}
                onChange={(v)=>{setState({openByClientBranchFk:v})}}
                options={[{label:"",value:"0"}].concat(
                  state.openByClientBranches.map((client)=>{
                    return {label:client.branch_name, value:client.ID};
                   })
                )}
              />
            </Col>
          </Row>


          <Row>
            <Col sm="6">
              <label>AE</label>
            </Col>
            <Col sm="6">
              <Input type="text" value={state.ae} onChange={(e)=>setState({ae:e.target.value})}/>
            </Col>
          </Row>

          <Row>
            <Col sm="6">
              <label>Loan Officer</label>
            </Col>
            <Col sm="6">
              <Input type="text" value={state.loanOfficer} onChange={(e)=>setState({loanOfficer:e.target.value})}/>
            </Col>
          </Row>

          <Row>
            <Col sm="6">
              <label>Loan Processor</label>
            </Col>
            <Col sm="6">
              <Input type="text" value={state.loanProcessor} onChange={(e)=>setState({loanProcessor:e.target.value})}/>
            </Col>
          </Row>

          <Row>
            <Col sm="6">
              <label>State</label>
            </Col>
            <Col sm="6">
              <MySelect
                type="select"
                selectIsClearable={true}
                value={state.state}
                onChange={(v)=>{setState({state:v})}}
                options={state.states.map((state)=>{
                  return {label:state.value, value:state.key};
                })}
              />
            </Col>
          </Row>

          <Row>
            <Col sm="6">
              <label>County</label>
            </Col>
            <Col sm="6">
              <Input type="text" value={state.county} onChange={(e)=>setState({county:e.target.value})}/>
            </Col>
          </Row>

          <br/><br/>
          <center>
            <Button color="warning">Submit</Button>
            &nbsp;

            <Button color="warning" onClick={getETAOrderVolume}>Get ETA Order Volume</Button>
          </center>
        </form>

      </ModalBody>
    </Modal>

    <Modal className="my-modal-wide" isOpen={state.addNewBlockAssignmentProfilePopUp} toggle={toggleAddBlockAssignmentProfile} >
      <ModalHeader hidden={true} toggle={toggleAddBlockAssignmentProfile}></ModalHeader>
      <ModalBody>
        {etaOrderVolume}
        <form onSubmit={createNewAccountManagerBlockAssignmentProfile}>
          <Row>
            <Col sm="6">
              <label>Account Manager</label>
            </Col>
            <Col sm="6">
              <MySelect
                type="select"
                required={true}
                selectIsClearable={true}
                value={state.accountManagerFk}
                onChange={(v)=>{setState({accountManagerFk:v})}}
                options={state.accountManagers.map((ac)=>{
                  return {label:ac.name, value:ac.ID};
                })}
              />
            </Col>
          </Row>

          <Row>
            <Col sm="6">
              <label>Profile Name</label>
            </Col>
            <Col sm="6">
              <Input type="text" value={state.name} onChange={(e)=>setState({name:e.target.value})}/>
            </Col>
          </Row>

          <Row>
            <Col sm="6">
              <label>Close By Client</label>
            </Col>
            <Col sm="6">
              <MySelect
                type="select"
                selectIsClearable={true}
                value={state.clientFk}
                onChange={(v)=>{getClientBranch(v);setState({clientFk:v})}}
                options={state.clients.map((client)=>{
                  return {label:client.value, value:client.key};
                })}
              />
            </Col>
          </Row>

          <Row>
            <Col sm="6">
              <label>Close By Client Branch</label>
            </Col>
            <Col sm="6">
              <MySelect
                type="select"
                value={state.clientBranchFk}
                onChange={(v)=>{setState({clientBranchFk:v})}}
                options={[{label:"",value:"0"}].concat(
                  state.clientBranches.map((client)=>{
                    return {label:client.branch_name, value:client.ID};
                   })
                )}
              />
            </Col>
          </Row>

          <Row>
            <Col sm="6">
              <label>Open By Client</label>
            </Col>
            <Col sm="6">
              <MySelect
                type="select"
                selectIsClearable={true}
                value={state.openByClient}
                onChange={(v)=>{getOpenByClientBranch(v);setState({openByClient:v})}}
                options={state.clients.map((client)=>{
                  return {label:client.value, value:client.key};
                })}
              />
            </Col>
          </Row>

          <Row>
            <Col sm="6">
              <label>Open By Client Branch</label>
            </Col>
            <Col sm="6">
              <MySelect
                type="select"
                value={state.openByClientBranchFk}
                onChange={(v)=>{setState({openByClientBranchFk:v})}}
                options={[{label:"",value:"0"}].concat(
                  state.openByClientBranches.map((client)=>{
                    return {label:client.branch_name, value:client.ID};
                   })
                )}
              />
            </Col>
          </Row>


          <Row>
            <Col sm="6">
              <label>AE</label>
            </Col>
            <Col sm="6">
              <Input type="text" value={state.ae} onChange={(e)=>setState({ae:e.target.value})}/>
            </Col>
          </Row>

          <Row>
            <Col sm="6">
              <label>Loan Officer</label>
            </Col>
            <Col sm="6">
              <Input type="text" value={state.loanOfficer} onChange={(e)=>setState({loanOfficer:e.target.value})}/>
            </Col>
          </Row>

          <Row>
            <Col sm="6">
              <label>Loan Processor</label>
            </Col>
            <Col sm="6">
              <Input type="text" value={state.loanProcessor} onChange={(e)=>setState({loanProcessor:e.target.value})}/>
            </Col>
          </Row>

          <Row>
            <Col sm="6">
              <label>State</label>
            </Col>
            <Col sm="6">
              <MySelect
                type="select"
                selectIsClearable={true}
                value={state.state}
                onChange={(v)=>{setState({state:v})}}
                options={state.states.map((state)=>{
                  return {label:state.value, value:state.key};
                })}
              />
            </Col>
          </Row>

          <Row>
            <Col sm="6">
              <label>County</label>
            </Col>
            <Col sm="6">
              <Input type="text" value={state.county} onChange={(e)=>setState({county:e.target.value})}/>
            </Col>
          </Row>

          <br/><br/>
          <center>
            <Button color="warning">Submit</Button>
            &nbsp;

            <Button color="warning" onClick={getETAOrderVolume}>Get ETA Order Volume</Button>
          </center>
        </form>

      </ModalBody>
    </Modal>
    <Modal className="my-modal-wide" isOpen={state.showAssignmentProfilePopUp} toggle={toggleShowAssignmentProfile} >
      <ModalHeader hidden={true} toggle={toggleShowAssignmentProfile}></ModalHeader>
      <ModalBody>
        <h5>{state.selectedAccountManager.name}</h5>
        <div className="my-divider">&nbsp;</div>
        <Row>
          {
            state.selectedAccountManager.profiles.map(
              (profile, index)=>{
                return <Col sm="4" key={index}>
                  <Card>
                    <CardHeader>
                      <i className="fa fa-times red-color cursor-pointer" onClick={(e)=>{
                        confirmation(
                          ()=>{deleteAccountManagerProfiles(profile.ID)},
                          ()=>{},
                          'Delete account manager assignment profile?',
                          'Are you sure you want to delete this account manager assignment profile?');
                      }}/> {profile.name}
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col sm="6">
                          <label>ETA Order Volume</label>
                        </Col>
                        <Col sm="6">
                          {profile.eta_order_volume}
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="6">
                          <label>Client</label>
                        </Col>
                        <Col sm="6">
                          {profile.client_name}
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="6">
                          <label>Client Branch</label>
                        </Col>
                        <Col sm="6">
                          {profile.client_branch_name}
                        </Col>
                      </Row>

                      <Row>
                        <Col sm="6">
                          <label>Open By Client</label>
                        </Col>
                        <Col sm="6">
                          {profile.open_by_client_name}
                        </Col>
                      </Row>

                      <Row>
                        <Col sm="6">
                          <label>Open By Client Branch</label>
                        </Col>
                        <Col sm="6">
                          {profile.open_by_client_branch_name}
                        </Col>
                      </Row>

                      <Row>
                        <Col sm="6">
                          <label>AE</label>
                        </Col>
                        <Col sm="6">
                          {profile.ae}
                        </Col>
                      </Row>

                      <Row>
                        <Col sm="6">
                          <label>Loan Officer</label>
                        </Col>
                        <Col sm="6">
                          {profile.loan_officer}
                        </Col>
                      </Row>

                      <Row>
                        <Col sm="6">
                          <label>Loan Processor</label>
                        </Col>
                        <Col sm="6">
                          {profile.loan_processor}
                        </Col>
                      </Row>

                      <Row>
                        <Col sm="6">
                          <label>State</label>
                        </Col>
                        <Col sm="6">
                          {profile.state}
                        </Col>
                      </Row>

                      <Row>
                        <Col sm="6">
                          <label>County</label>
                        </Col>
                        <Col sm="6">
                          {profile.county}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <br/>
                </Col>
              }
            )
          }
        </Row>
      </ModalBody>
    </Modal>

    <Modal className="my-modal-wide" isOpen={state.showBlockAssignmentProfilePopUp} toggle={toggleShowBlockAssignmentProfile} >
      <ModalHeader hidden={true} toggle={toggleShowBlockAssignmentProfile}></ModalHeader>
      <ModalBody>
        <h5>{state.selectedAccountManager.name}</h5>
        <div className="my-divider">&nbsp;</div>
        <Row>
          {
            state.selectedAccountManager.block_profiles.map(
              (profile, index)=>{
                return <Col sm="4" key={index}>
                  <Card>
                    <CardHeader>
                      <i className="fa fa-times red-color cursor-pointer" onClick={(e)=>{
                        confirmation(
                          ()=>{deleteAccountManagerBlockProfiles(profile.ID)},
                          ()=>{},
                          'Delete account manager assignment profile?',
                          'Are you sure you want to delete this account manager assignment profile?');
                      }}/> {profile.name}
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col sm="6">
                          <label>ETA Order Volume</label>
                        </Col>
                        <Col sm="6">
                          {profile.eta_order_volume}
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="6">
                          <label>Client</label>
                        </Col>
                        <Col sm="6">
                          {profile.client_name}
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="6">
                          <label>Client Branch</label>
                        </Col>
                        <Col sm="6">
                          {profile.client_branch_name}
                        </Col>
                      </Row>

                      <Row>
                        <Col sm="6">
                          <label>Open By Client</label>
                        </Col>
                        <Col sm="6">
                          {profile.open_by_client_name}
                        </Col>
                      </Row>

                      <Row>
                        <Col sm="6">
                          <label>Open By Client Branch</label>
                        </Col>
                        <Col sm="6">
                          {profile.open_by_client_branch_name}
                        </Col>
                      </Row>

                      <Row>
                        <Col sm="6">
                          <label>AE</label>
                        </Col>
                        <Col sm="6">
                          {profile.ae}
                        </Col>
                      </Row>

                      <Row>
                        <Col sm="6">
                          <label>Loan Officer</label>
                        </Col>
                        <Col sm="6">
                          {profile.loan_officer}
                        </Col>
                      </Row>

                      <Row>
                        <Col sm="6">
                          <label>Loan Processor</label>
                        </Col>
                        <Col sm="6">
                          {profile.loan_processor}
                        </Col>
                      </Row>

                      <Row>
                        <Col sm="6">
                          <label>State</label>
                        </Col>
                        <Col sm="6">
                          {profile.state}
                        </Col>
                      </Row>

                      <Row>
                        <Col sm="6">
                          <label>County</label>
                        </Col>
                        <Col sm="6">
                          {profile.county}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <br/>
                </Col>
              }
            )
          }
        </Row>
      </ModalBody>
    </Modal>
    <Modal className="my-modal" isOpen={state.addNewAccountManagerPopUp} toggle={toggleAddNewAccountManager} >
      <ModalHeader hidden={true} toggle={toggleAddNewAccountManager}>Promote New Account Manager</ModalHeader>
      <ModalBody>
        <form onSubmit={createNewAccountManager}>
          <label>Admin</label><br/>
          <MySelect
            modal={true}
            type="select"
            selectIsClearable={true}
            value={state.accountManager}
            onChange={(v)=>{setState({accountManager:v})}}
            options={state.admins.map((admin)=>{
              return {label:admin.first_name+' '+admin.last_name+' - '+admin.email, value:admin.user_fk};
            })}
          />

          <br/>
          <label><i className="fa fa-gear"/> Even Distribution of Unassigned Orders:</label><br/>
          <MySelect
            modal={true}
            type="select"
            value={state.unassignedOrder}
            onChange={(v)=>{setState({unassignedOrder:v})}}
            options={[{label:"Yes - Received Unassigned Orders",value:"yes"},{label:"No - Won't Receive Unassigned Orders",value:"no"}]}
          />

          <br/><br/>
          <label><i className="fa fa-gear"/> Prefix On Email Subject Line:</label><br/>
          <Input type="text" value={state.prefix} onChange={(e)=>setState({prefix:e.target.value})}/>

          <br/><br/>
          <div className="align-right">
            <Button color="warning">Promote as Account Manager</Button>
          </div>
        </form>
      </ModalBody>
    </Modal>
    <Modal className="my-modal-wide" isOpen={state.detailsPopUpOpen} toggle={orderDetailsToggle} >
      <ModalHeader hidden={true} toggle={orderDetailsToggle}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-user"></i> Order Details</h5>
        </center>
        <br/>
        <div className="large-scroll-container">
          <MyReactTable columns={columns} data={state.orderDetails} className="table table-striped"/>
        </div>
        <br/>
        <center>
          <Button color="info" onClick={orderDetailsToggle}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
    <Row>
      <Col sm="6">
        <div className="page-title">
          <i className="fa fa-users"></i>&nbsp;Account Managers
        </div>
      </Col>
      <Col sm="6" className="align-right">
        <NavLink to="#" onClick={toggleAddNewAccountManager}><i className="fa fa-plus green-color"/> Account Manager</NavLink>&nbsp;&nbsp;
        <NavLink to="#" onClick={toggleAddAssignmentProfile}><i className="fa fa-plus green-color"/> Assignment Profile</NavLink>&nbsp;&nbsp;
        <NavLink to="#" onClick={toggleAddBlockAssignmentProfile}><i className="fa fa-times red-color"/> Block Assignment Profile</NavLink>
      </Col>
    </Row>
    <div className="my-divider"></div>
    <Row>
      <Col sm="2">
        <label>From</label><br/>
        <DatePicker
          className="form-control"
          dateFormat="yyyy/MM/dd"
          selected={from}
          onChange={(text)=>{(text)&&setState({from:text.toLocaleDateString('en-CA')})}}
        />
      </Col>
      <Col sm="2">
        <label>To</label><br/>
        <DatePicker
          className="form-control"
          dateFormat="yyyy/MM/dd"
          selected={to}
          onChange={(text)=>{(text)&&setState({to:text.toLocaleDateString('en-CA')})}}
        />
      </Col>
      <Col sm="8" className="align-right">
        <br/>
        <Button color="warning" onClick={()=>{getAccountManagers();getUnassignedOrderDetails();}}><i className="fa fa-plus green-color"></i>&nbsp;Submit</Button>
      </Col>
    </Row>
    <div className="my-divider"></div>
    <Nav tabs>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.activeTab === '1'?"active":"inactive" )}
          onClick={() => { setActiveTabToggle('1'); }}
          to="#"
        >
          Profiles
        </NavLinkL>
      </NavItem>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.activeTab === '2'?"active":"inactive" ) }
          onClick={() => { setActiveTabToggle('2'); }}
          to="#"
        >
          Unassigned Orders
        </NavLinkL>
      </NavItem>
    </Nav>
    <br/>
    <TabContent activeTab={state.activeTab}>
      <TabPane tabId="1">
        <Row>
          {
            state.accountManagers.map(
              (accountManager, index)=>{
                return <Col sm="4" key={index}>
                  <Card>
                    <CardHeader>
                      <h4><i className="fa fa-times red-color cursor-pointer" onClick={
                        (e)=>{
                          confirmation(
                            ()=>{deleteAccountManager(accountManager.ID)},
                            ()=>{},
                            'Delete account manager?',
                            'Are you sure you want to delete this account manager?');

                        }
                      }/>&nbsp;<i className="fa fa-user"/> {accountManager.name}</h4>
                    </CardHeader>
                    <CardBody>
                      <b><i className="fa fa-envelope"/> Email</b><br/>
                      {accountManager.email}<br/><br/>

                      <b><i className="fa fa-files-o"/> Order Volume</b><br/>
                      <NavLink to="#" onClick={()=>{orderDetailsToggle();getOrderDetails(accountManager.ID)}}>{accountManager.order_volume}</NavLink> orders over {accountManager.total_days} days<br/><br/>

                      <b><i className="fa fa-gear"/> Even Distribution of Unassigned Orders:</b><br/>
                      <MyXEditable
                       type="select"
                       value={accountManager.unassigned_order}
                       opt={[{key:'yes',value:'Yes - Received Unassigned Orders'},{key:'no',value:'No - Won\'t Receive Unassigned Orders'}]}
                       updateFunc={
                         generateUpdateFunction('accountManager/update',[{field:'field',value:'unassigned_order'},{field:'ID',value:accountManager.ID}],'Information updated.', 'Failed to update account manager profile, please try again later.', (field, value)=>updateCallBack(accountManager.ID, field, value))
                       }
                      />

                    <br/><br/>
                    <b><i className="fa fa-tag"/> Prefix:</b><br/>
                     <MyXEditable
                      type="text"
                      value={accountManager.prefix}
                      updateFunc={
                        generateUpdateFunction('accountManager/update',[{field:'field',value:'prefix'},{field:'ID',value:accountManager.ID}],'Information updated.', 'Failed to update account manager profile, please try again later.', (field, value)=>updateCallBack(accountManager.ID, field, value))
                      }
                     />
                     <br/><br/>
                     <b><i className="fa fa-tag"/> Account Manager Status:</b><br/>
                      <MyXEditable
                       type="select"
                       value={accountManager.status}
                       opt={[{key:'active',value:'Active - Ready To Be Assign'},{key:'inactive',value:'Inactive - Won\'t Receive Order Assignment'}]}
                       updateFunc={
                         generateUpdateFunction('accountManager/update',[{field:'field',value:'status'},{field:'ID',value:accountManager.ID}],'Information updated.', 'Failed to update account manager profile, please try again later.', (field, value)=>updateCallBack(accountManager.ID, field, value))
                       }
                      />
                      <br/><br/>
                      <b><i className="fa fa-group"/> Assignment profiles: </b><br/>
                      <NavLink to="#" onClick={(e)=>{
                        setState({selectedAccountManager:accountManager});
                        toggleShowAssignmentProfile();
                      }}>View Profiles</NavLink>
                      <br/><br/>
                      <b><i className="fa fa-group"/> Block Assignment profiles: </b><br/>
                      <NavLink to="#" onClick={(e)=>{
                        setState({selectedAccountManager:accountManager});
                        toggleShowBlockAssignmentProfile();
                      }}>View Profiles</NavLink>
                    </CardBody>
                  </Card>
                  <br/>
                </Col>
              }
            )
          }
        </Row>
      </TabPane>
      <TabPane tabId="2">
          <div className="mega-large-scroll-container">
            <MyReactTable columns={columns} data={state.unassignedOrderDetails} className="table table-striped"/>
          </div>
      </TabPane>
    </TabContent>
  </div>;
}

export default AccountManager;
