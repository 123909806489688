//Author Sooyoung Kim
//Date June 8, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, getFromTo} from '../../../util/util';
import {Button, Col, Row, Table, Collapse} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import moment from 'moment';
import '../report.css';
import ReportFilter from '../report-filter';
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

let from = getFromTo('from');
let to = getFromTo('to');

//initialize the state
const initialState = {
  report:{},
  names:[],
  from:from,
  to:to,
};

//reducer function that perform state update
const reducer = getReducer();


const LoanOfficerRankReport  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{


    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  //API call
  const getReport = ()=>{
    let callBack = (response)=>{console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let res = response.data.data.loanOfficer;

        let array = [];
        for(let p in res){
          let tmp = {
            name:p,
            value:res[p]
          };
          array.push(tmp);
        }

        array.sort(
          (a, b)=>{
            return b.value - a.value;
          }
        );

        for(let i=0;i<array.length;i++){
          setState({['loanOfficer'+array[i].name]:false});

          for(let p in response.data.data.loanOfficerProcessor[array[i].name]){
            setState({["loanOfficer"+array[i].name+"Processor"+p]:false});
          }
        }

        setState({names:array, report:response.data.data.loanOfficerProcessor});
      }
    };
    callBack = callBack.bind(this);

    httpGet('report/loanOfficerRank/get/from='+state.from+'&to='+state.to, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  //render
  let newfrom, newto;
  if(state.from!=='')
    newfrom = moment(state.from);
  if(state.to!=='')
    newto = moment(state.to);

  let reportFiltersConfig = [
    {id:'from',value:newfrom, updateFunc:(v)=>setState({from:v}), width:'3'},
    {id:'to',value:newto, updateFunc:(v)=>setState({to:v}), width:'3'},
    {id:'button',value:'Submit', updateFunc:getReport, width:'6', className:"align-right", color:"warning"},
  ];

  return <div>
    <div className="padding">
      <ReportFilter {...props} configs={reportFiltersConfig}/>
      <div className="my-divider">&nbsp;</div>
      <Row>
        <Col sm="8">
          <b>Loan Officer Ranking</b>
        </Col>
        <Col sm="4" className="align-right">
          <ExcelFile element={<Button disabled={state.names.length<=0} color="warning">Download Excel</Button>}>
            <ExcelSheet data={state.names} name="XLSX">
                <ExcelColumn label="Loan Officer" value="name"/>
                <ExcelColumn label="Total Order #" value="value"/>
            </ExcelSheet>
          </ExcelFile>
        </Col>
      </Row>
      <br/>
      <Table className="table table-striped">
        <tbody>
          {
            state.names.map(
              (name, index)=>{
                let icon = <i className="fa fa-plus green-color"></i>;

                if(state["loanOfficer"+name.name])
                  icon = <i className="fa fa-minus red-color"></i>;

                let list = state.report[name.name];
                let array = [];
                for(let p in list){
                  let total = 0;
                  for(let p2 in list[p]){
                    total = total + parseInt(list[p][p2],10);
                  }

                  let cities = []

                  for(let p3 in list[p]){
                    let tmp = {
                      city:p3,
                      total:list[p][p3]
                    }

                    cities.push(tmp);
                  }

                  let tmp = {
                    name:p,
                    cities: cities,
                    total:total
                  };

                  array.push(tmp);
                }

                array.sort(
                  (a, b)=>{
                    return b.total-a.total;
                  }
                )

                return(
                  <tr key={index}>
                    <td>
                      <Row className="cursor-pointer" id={"loanOfficer"+index} onClick={(e)=>{setState({["loanOfficer"+name.name]:!state["loanOfficer"+name.name]})}}>
                        <Col sm="10">
                          {icon} <font color="blue">{name.name}</font>
                        </Col>
                        <Col sm="2" className="align-right">
                          {name.value} orders
                        </Col>
                      </Row>
                      <Collapse isOpen={state["loanOfficer"+name.name]}>
                        <br/>

                        <label><b>Processor</b></label>
                        <div className="padding my-well">
                          <Table>
                            <tbody>
                              {
                                array.map(
                                  (broker, index2)=>{
                                    let icon2 = <i className="fa fa-plus green-color"></i>;

                                    if(state["loanOfficer"+name.name+"Processor"+broker.name])
                                      icon2 = <i className="fa fa-minus red-color"></i>;

                                    return (
                                      <tr key={index2}>
                                        <td>
                                          <Row className="cursor-pointer" onClick={(e)=>{setState({["loanOfficer"+name.name+"Processor"+broker.name]:!state["loanOfficer"+name.name+"Processor"+broker.name]})}}>
                                            <Col sm="10">
                                              {icon2} <font color="green">{broker.name}</font>
                                            </Col>
                                            <Col sm="2" className="align-right">
                                              {broker.total} orders
                                            </Col>
                                          </Row>
                                          <Collapse isOpen={state["loanOfficer"+name.name+"Processor"+broker.name]}>
                                            <br/>
                                            <label><b>Cities</b></label>
                                            <div className="padding my-well">
                                              <Table>
                                                <tbody>
                                                  {
                                                    broker.cities.map(
                                                      (city, index3)=>{
                                                        return (
                                                          <tr key={index3}>
                                                            <td>
                                                              <Row>
                                                                <Col sm="10">
                                                                  <font color="orange">{city.city}</font>
                                                                </Col>
                                                                <Col sm="2" className="align-right">
                                                                  {city.total} orders
                                                                </Col>
                                                              </Row>
                                                            </td>
                                                          </tr>
                                                        )
                                                      }
                                                    )
                                                  }
                                                </tbody>
                                              </Table>
                                            </div>
                                          </Collapse>
                                        </td>
                                      </tr>
                                    )
                                  }
                                )
                              }
                            </tbody>
                          </Table>
                        </div>
                      </Collapse>
                    </td>
                  </tr>
                )
              }
            )
          }
        </tbody>
      </Table>
    </div>
  </div>;
}

export default LoanOfficerRankReport;
