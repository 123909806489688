//Author Sooyoung Kim
//Date July 7, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, formatNumber, formatDateTime, getFromTo} from '../../../util/util';
import {Button, Card, CardHeader, CardBody, Col, Row, Label, FormGroup, Input, Nav, NavItem, TabPane, TabContent, UncontrolledTooltip, NavLink as NavLinkL} from 'reactstrap';
import FileSaver from 'file-saver';
import moment from 'moment';
import {NavLink} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';
import ReactExport from "react-export-excel";
import MyReactTable from '../../util/my-react-table';
import ReportFilter from '../report-filter';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

//initialize the state
const initialState = {
  status:'',
  statuses: [{ID:'1',name:'Submitted'},{ID:'2',name:'Auto Assigning'},{ID:'3',name:'Assigned'},{ID:'4',name:'Pending Inspection'},{ID:'5',name:'Inspection Scheduled'},{ID:'6',name:'Inspection Completed'},{ID:'7',name:'Review'},{ID:'8',name:'Shipping'},{ID:'9',name:'Completed'},{ID:'10',name:'Hold'},{ID:'11',name:'Cancelled'},{ID:'12',name:'Conditioned'},{ID:'13',name:'Rebuttal'}],
  ccDeclined:[],
  ccSuccess:[],
  ccVoidRefund:[],
  ccBalanceDue:[],
  mercuryCCDeclined:[],
  mercuryCCSuccess:[],
  mercuryCCVoidRefund:[],
  mercuryCCBalanceDue:[],
  stripeSuccess:[],
  stripeBalanceDue:[],
  stripePending:[],
  stripeRefunded:[],
  paidAtOutsideSuccess:[],
  paidAtOutsideBalanceDue:[],
  paidAtOutsidePending:[],
  otherSuccess:[],
  otherPending:[],
  otherBalanceDue:[],
  appraisalPortACH:[],
  from:getFromTo('end'),
  to:getFromTo('end'),
  clients:[],
  client:'',
  openBy:'',
  appraisalPortACHTotal:0,
  ccSuccessTotal:0,
  ccFailedTotal:0,
  ccBalanceDueTotal:0,
  ccVoidRefundTotal:0,
  ccSuccessProfitTotal:0,
  ccFailedProfitTotal:0,
  stripeSuccessTotal:0,
  stripeSuccessProfitTotal:0,
  stripeBalanceDueTotal:0,
  stripeBalanceDueProfitTotal:0,
  stripePendingTotal:0,
  stripePendingProfitTotal:0,
  stripeRefundedTotal:0,
  stripeRefundedProfitTotal:0,

  paidAtOutsideSuccessTotal:0,
  paidAtOutsideSuccessProfitTotal:0,
  paidAtOutsideBalanceDueTotal:0,
  paidAtOutsideBalanceDueProfitTotal:0,
  paidAtOutsidePendingTotal:0,
  paidAtOutsidePendingProfitTotal:0,

  otherSuccessTotal:0,
  otherSuccessProfitTotal:0,
  otherBalanceDueTotal:0,
  otherBalanceDueProfitTotal:0,
  otherPendingTotal:0,
  otherPendingProfitTotal:0,

  totalBalanceDueTotal:0,

  activeTab:'1',
  IDsForInvoice:[],
  arrayForExcel:[],
};

//reducer function that perform state update
const reducer = getReducer();


const ReceivableReport  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getReportDetails();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const selectAll = (stateName) => {
    let checked = state[stateName+'Checked'];
    let IDsForInvoice = state.IDsForInvoice.slice();
    let arrayForExcel = state.arrayForExcel.slice();
    let array = state[stateName].slice();

    if(checked){
      for(let i=0;i<array.length;i++){
        let index = IDsForInvoice.indexOf(array[i].invoice_fk);

        if(index!==-1){
          IDsForInvoice.splice(index, 1);
          arrayForExcel.splice(index, 1);
        }
      }
    }
    else{
      for(let i=0;i<array.length;i++){
        let index = IDsForInvoice.indexOf(array[i].invoice_fk);

        if(index===-1){
          IDsForInvoice.push(array[i].invoice_fk);
          arrayForExcel.push(array[i]);
        }
      }
    }
    setState({IDsForInvoice:IDsForInvoice, arrayForExcel:arrayForExcel, [stateName+"Checked"]:state[stateName+"Checked"]?!state[stateName+"Checked"]:true});
  }

  const addToInvoiceIDs = (data) => {
    let IDsForInvoice = state.IDsForInvoice.slice();
    let arrayForExcel = state.arrayForExcel.slice();

    let index = IDsForInvoice.indexOf(data.invoice_fk);
    if(index===-1){
      IDsForInvoice.push(data.invoice_fk);
      arrayForExcel.push(data);
    }
    else{
      IDsForInvoice.splice(index, 1);
      arrayForExcel.splice(index, 1);
    }
    setState({IDsForInvoice:IDsForInvoice, arrayForExcel:arrayForExcel});
  }

  const activeTabToggle = (tab) => {
    setState({activeTab:tab});
  }

  //API call
  const generateInvoice = () => {
    let parameters = [{field:'IDs',value:state.IDsForInvoice}];

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let byteCharacters = atob(response.data.data);
        let byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);
        let data = new Blob([byteArray]);
        FileSaver.saveAs(data, 'client-invoice.pdf');
      }
    };
    callBack = callBack.bind(this);

    httpPost('billing/formalInvoice/create', parameters, '', 'Oops, something went wrong and could not download the invoice. Please try again later.', callBack);
  }

  const getReportDetails = () => {
    let callBack = (response)=>{console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let ccSuccessTotal = 0, ccFailedTotal = 0, ccBalanceDueTotal = 0, ccVoidRefundTotal =0;
        let mercuryCCSuccessTotal = 0, mercuryCCFailedTotal = 0, mercuryCCBalanceDueTotal = 0, mercuryCCVoidRefundTotal =0;
        let ccSuccessProfitTotal = 0, ccFailedProfitTotal = 0;
        let mercuryCCSuccessProfitTotal = 0, mercuryCCFailedProfitTotal = 0;

        for(let i=0;i<response.data.data.cc.success.length;i++){
          ccSuccessTotal = ccSuccessTotal + parseInt(response.data.data.cc.success[i].total, 10);
          ccSuccessProfitTotal = ccSuccessProfitTotal + parseInt(response.data.data.cc.success[i].profit, 10);
        }

        for(let i=0;i<response.data.data.cc.declined.length;i++){
          ccFailedTotal = ccFailedTotal + parseInt(response.data.data.cc.declined[i].total, 10);
          ccFailedProfitTotal = ccFailedProfitTotal + parseInt(response.data.data.cc.declined[i].profit, 10);
        }

        for(let i=0;i<response.data.data.cc.balance_due.length;i++)
          ccBalanceDueTotal = ccBalanceDueTotal + parseInt(response.data.data.cc.balance_due[i].total, 10);

        for(let i=0;i<response.data.data.cc.void_refund.length;i++)
          ccVoidRefundTotal = ccVoidRefundTotal + parseInt(response.data.data.cc.void_refund[i].amount, 10);

        //MERCURY CC
        for(let i=0;i<response.data.data.mercury_cc.success.length;i++){
          mercuryCCSuccessTotal = mercuryCCSuccessTotal + parseInt(response.data.data.mercury_cc.success[i].total, 10);
          mercuryCCSuccessProfitTotal = mercuryCCSuccessProfitTotal + parseInt(response.data.data.mercury_cc.success[i].profit, 10);
        }

        for(let i=0;i<response.data.data.mercury_cc.declined.length;i++){
          mercuryCCFailedTotal = mercuryCCFailedTotal + parseInt(response.data.data.mercury_cc.declined[i].total, 10);
          mercuryCCFailedProfitTotal = mercuryCCFailedProfitTotal + parseInt(response.data.data.mercury_cc.declined[i].profit, 10);
        }

        for(let i=0;i<response.data.data.mercury_cc.balance_due.length;i++)
          mercuryCCBalanceDueTotal = mercuryCCBalanceDueTotal + parseInt(response.data.data.mercury_cc.balance_due[i].total, 10);

        for(let i=0;i<response.data.data.mercury_cc.void_refund.length;i++)
          mercuryCCVoidRefundTotal = mercuryCCVoidRefundTotal + parseInt(response.data.data.mercury_cc.void_refund[i].amount, 10);



        //stripe
        let stripeSuccessTotal = 0, stripeSuccessProfitTotal = 0, stripePendingTotal = 0, stripePendingProfitTotal = 0, stripeBalanceDueTotal = 0, stripeBalanceDueProfitTotal = 0;
        let stripeRefundedTotal = 0, stripeRefundedProfitTotal = 0;

        for(let i=0;i<response.data.data.stripe.success.length;i++){
          stripeSuccessTotal = stripeSuccessTotal + parseInt(response.data.data.stripe.success[i].total, 10);
          stripeSuccessProfitTotal = stripeSuccessProfitTotal + parseInt(response.data.data.stripe.success[i].profit, 10);
        }

        for(let i=0;i<response.data.data.stripe.pending.length;i++){
          stripePendingTotal = stripePendingTotal + parseInt(response.data.data.stripe.pending[i].total, 10);
          stripePendingProfitTotal = stripePendingProfitTotal + parseInt(response.data.data.stripe.pending[i].profit, 10);
        }

        for(let i=0;i<response.data.data.stripe.refund.length;i++){
          stripeRefundedTotal = stripeRefundedTotal + parseInt(response.data.data.stripe.refund[i].total, 10);
          stripeRefundedProfitTotal = stripeRefundedProfitTotal + parseInt(response.data.data.stripe.refund[i].profit, 10);
        }

        for(let i=0;i<response.data.data.stripe.balance_due.length;i++){
          stripeBalanceDueTotal = stripeBalanceDueTotal + parseInt(response.data.data.stripe.balance_due[i].total, 10);
          stripeBalanceDueProfitTotal = stripeBalanceDueProfitTotal + parseInt(response.data.data.stripe.balance_due[i].profit, 10);
        }

        //paid at / paid outside of closing
        let paidAtOutsideSuccessTotal = 0, paidAtOutsideSuccessProfitTotal = 0, paidAtOutsidePendingTotal = 0, paidAtOutsidePendingProfitTotal = 0, paidAtOutsideBalanceDueTotal = 0, paidAtOutsideBalanceDueProfitTotal = 0;

        for(let i=0;i<response.data.data.paid_at_outside.success.length;i++){
          paidAtOutsideSuccessTotal = paidAtOutsideSuccessTotal + parseInt(response.data.data.paid_at_outside.success[i].total, 10);
          paidAtOutsideSuccessProfitTotal = paidAtOutsideSuccessProfitTotal + parseInt(response.data.data.paid_at_outside.success[i].profit, 10);
        }

        for(let i=0;i<response.data.data.paid_at_outside.pending.length;i++){
          paidAtOutsidePendingTotal = paidAtOutsidePendingTotal + parseInt(response.data.data.paid_at_outside.pending[i].total, 10);
          paidAtOutsidePendingProfitTotal = paidAtOutsidePendingProfitTotal + parseInt(response.data.data.paid_at_outside.pending[i].profit, 10);
        }

        for(let i=0;i<response.data.data.paid_at_outside.balance_due.length;i++){
          paidAtOutsideBalanceDueTotal = paidAtOutsideBalanceDueTotal + parseInt(response.data.data.paid_at_outside.balance_due[i].total, 10);
          paidAtOutsideBalanceDueProfitTotal = paidAtOutsideBalanceDueProfitTotal + parseInt(response.data.data.paid_at_outside.balance_due[i].profit, 10);
        }

        //other
        let otherSuccessTotal = 0, otherSuccessProfitTotal = 0, otherPendingTotal = 0, otherPendingProfitTotal = 0, otherBalanceDueTotal = 0, otherBalanceDueProfitTotal = 0;

        for(let i=0;i<response.data.data.other.success.length;i++){
          otherSuccessTotal = otherSuccessTotal + parseInt(response.data.data.other.success[i].total, 10);
          otherSuccessProfitTotal = otherSuccessProfitTotal + parseInt(response.data.data.other.success[i].profit, 10);
        }

        for(let i=0;i<response.data.data.other.pending.length;i++){
          otherPendingTotal = otherPendingTotal + parseInt(response.data.data.other.pending[i].total, 10);
          otherPendingProfitTotal = otherPendingProfitTotal + parseInt(response.data.data.other.pending[i].profit, 10);
        }

        for(let i=0;i<response.data.data.other.balance_due.length;i++){
          otherBalanceDueTotal = otherBalanceDueTotal + parseInt(response.data.data.other.balance_due[i].total, 10);
          otherBalanceDueProfitTotal = otherBalanceDueProfitTotal + parseInt(response.data.data.other.balance_due[i].profit, 10);
        }

        let totalBalanceDueTotal = 0;

        for(let i=0;i<response.data.data.total.balance_due.length;i++){
          totalBalanceDueTotal = totalBalanceDueTotal + parseInt(response.data.data.total.balance_due[i].total, 10);
        }

        let appraisalPortACHTotal = 0;
        for(let i=0;i<response.data.data.appraisal_port_ach_transferred.length;i++){
          appraisalPortACHTotal = appraisalPortACHTotal + parseInt(response.data.data.appraisal_port_ach_transferred[i].total, 10);
        }


        setState(
          {
            ccSuccessProfitTotal:ccSuccessProfitTotal,
            ccSuccessTotal:ccSuccessTotal,
            ccFailedTotal:ccFailedTotal,
            ccBalanceDueTotal:ccBalanceDueTotal,
            ccVoidRefundTotal:ccVoidRefundTotal,
            ccFailedProfitTotal:ccFailedProfitTotal,
            ccSuccess:response.data.data.cc.success,
            ccVoidRefund:response.data.data.cc.void_refund,
            ccDeclined:response.data.data.cc.declined,
            ccBalanceDue:response.data.data.cc.balance_due,

            mercuryCCSuccessProfitTotal:mercuryCCSuccessProfitTotal,
            mercuryCCSuccessTotal:mercuryCCSuccessTotal,
            mercuryCCFailedTotal:mercuryCCFailedTotal,
            mercuryCCBalanceDueTotal:mercuryCCBalanceDueTotal,
            mercuryCCVoidRefundTotal:mercuryCCVoidRefundTotal,
            mercuryCCFailedProfitTotal:mercuryCCFailedProfitTotal,
            mercuryCCSuccess:response.data.data.mercury_cc.success,
            mercuryCCVoidRefund:response.data.data.mercury_cc.void_refund,
            mercuryCCDeclined:response.data.data.mercury_cc.declined,
            mercuryCCBalanceDue:response.data.data.mercury_cc.balance_due,

            stripeSuccess:response.data.data.stripe.success,
            stripePending:response.data.data.stripe.pending,
            appraisalPortACH:response.data.data.appraisal_port_ach_transferred,
            appraisalPortACHTotal:appraisalPortACHTotal,
            stripeBalanceDue:response.data.data.stripe.balance_due,
            stripeRefunded:response.data.data.stripe.refund,
            stripeSuccessTotal:stripeSuccessTotal,
            stripeSuccessProfitTotal:stripeSuccessProfitTotal,
            stripePendingTotal:stripePendingTotal,
            stripePendingProfitTotal:stripePendingProfitTotal,
            stripeRefundedTotal:stripeRefundedTotal,
            stripeRefundedProfitTotal:stripeRefundedProfitTotal,
            stripeBalanceDueTotal:stripeBalanceDueTotal,
            stripeBalanceDueProfitTotal:stripeBalanceDueProfitTotal,
            paidAtOutsideSuccess:response.data.data.paid_at_outside.success,
            paidAtOutsidePending:response.data.data.paid_at_outside.pending,
            paidAtOutsideBalanceDue:response.data.data.paid_at_outside.balance_due,
            paidAtOutsideSuccessTotal:paidAtOutsideSuccessTotal,
            paidAtOutsideSuccessProfitTotal:paidAtOutsideSuccessProfitTotal,
            paidAtOutsidePendingProfitTotal:paidAtOutsidePendingProfitTotal,
            paidAtOutsidePendingTotal:paidAtOutsidePendingTotal,
            paidAtOutsideBalanceDueTotal:paidAtOutsideBalanceDueTotal,
            paidAtOutsideBalanceDueProfitTotal:paidAtOutsideBalanceDueProfitTotal,
            otherSuccess:response.data.data.other.success,
            otherPending:response.data.data.other.pending,
            otherBalanceDue:response.data.data.other.balance_due,
            otherSuccessTotal:otherSuccessTotal,
            otherSuccessProfitTotal:otherSuccessProfitTotal,
            otherPendingTotal:otherPendingTotal,
            otherPendingProfitTotal:otherPendingProfitTotal,
            otherBalanceDueTotal:otherBalanceDueTotal,
            otherBalanceDueProfitTotal:otherBalanceDueProfitTotal,
            totalBalanceDueTotal:totalBalanceDueTotal,
          }
        );
      }
    };
    callBack = callBack.bind(this);

    httpGet('report/receivable/get/status='+state.status+'&start='+state.from+'&end='+state.to+'&client_fk='+state.client+'&open_by='+state.openBy, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  //render
  let from, to;
  if(state.from!=='')
    from = moment(state.from);
  if(state.to!=='')
    to = moment(state.to);

  /*
  React Table
  */
  const paidAtOutsideBalanceDue = [
    {
      id: 'action',
      Header: ()=><Input style={{marginLeft:'1px'}} type="checkbox" checked={state['paidAtOutsideBalanceDueChecked']} onChange={()=>selectAll('paidAtOutsideBalanceDue')}/>,
      sortable: false,
      maxWidth:50,
      width:50,
      accessor: d => d.reference_num,
      Cell: props => {
        return <div>
        <div className="display-inline">
          <FormGroup check>
            <Label check>
              <Input type="checkbox" checked={state.IDsForInvoice.indexOf(props.row.original.invoice_fk)!==-1} onChange={(e)=>addToInvoiceIDs(props.row.original)}/>
            </Label>
          </FormGroup>
        </div>
      </div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'referenceNum',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'loanNum',
      Header: 'Loan #',
      accessor: d => d.loan_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'client',
      Header: 'Close By',
      accessor: d => d.company,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'property',
      Header: 'Property',
      accessor: d => d.property_street+' '+d.property_city+', '+d.property_state+' '+d.property_zip,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'borrower',
      Header: 'Borrower',
      accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'status',
      Header: 'Order Status',
      accessor: d => d.order_status,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'amount',
      Header: 'Amount',
      accessor: d => d.total,
      Cell: props => <div>${formatNumber(props.row.original.total)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'profit',
      Header: 'Profit',
      accessor: d => d.profit,
      Cell: props => <div>${formatNumber(props.row.original.profit)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'date',
      Header: 'Date',
      accessor: d => d.datetime_created,
      Cell: props=> <div>{formatDateTime(props.row.original.datetime_created)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  const ccSuccess = [
    {
      id: 'action',
      sortable: false,
      maxWidth:50,
      width:50,
      accessor: d => d.reference_num,
      Cell: props => {
        return <div>

      </div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'referenceNum',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'loanNum',
      Header: 'Loan #',
      accessor: d => d.loan_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'client',
      Header: 'Close By',
      accessor: d => d.company,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'transactionNum',
      Header: 'Transaction #',
      accessor: d => d.transaction_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'property',
      Header: 'Property',
      accessor: d => d.property_street+' '+d.property_city+', '+d.property_state+' '+d.property_zip,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'borrower',
      Header: 'Borrower',
      accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'status',
      Header: 'Order Status',
      accessor: d => d.order_status,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'amount',
      Header: 'Amount',
      accessor: d => d.total,
      Cell: props => <div>${formatNumber(props.row.original.total)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'profit',
      Header: 'Profit',
      accessor: d => d.profit,
      Cell: props => <div>${formatNumber(props.row.original.profit)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'date',
      Header: 'Date',
      accessor: d => d.datetime_created,
      Cell: props=> <div>{formatDateTime(props.row.original.datetime_created)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  const paidAtOutsideSuccess = [
    {
      id: 'action',
      sortable: false,
      maxWidth:50,
      width:50,
      Cell: props => {
        return <div>

      </div>
      },
      accessor: d => d.reference_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'referenceNum',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'loanNum',
      Header: 'Loan #',
      accessor: d => d.loan_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'client',
      Header: 'Close By',
      accessor: d => d.company,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'transactionNum',
      Header: 'Transaction #',
      accessor: d => d.transaction_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'property',
      Header: 'Property',
      accessor: d => d.property_street+' '+d.property_city+', '+d.property_state+' '+d.property_zip,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'borrower',
      Header: 'Borrower',
      accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'status',
      Header: 'Order Status',
      accessor: d => d.order_status,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'amount',
      Header: 'Amount',
      accessor: d => d.total,
      Cell: props => <div>${formatNumber(props.row.original.total)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'profit',
      Header: 'Profit',
      accessor: d => d.profit,
      Cell: props => <div>${formatNumber(props.row.original.profit)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'date',
      Header: 'Date',
      accessor: d => d.datetime_created,
      Cell: props=> <div>{formatDateTime(props.row.original.datetime_created)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  const stripeSuccess = [
    {
      id: 'action',
      sortable: false,
      maxWidth:50,
      width:50,
      Cell: props => {
        return <div>

      </div>
      },
      accessor: d => d.reference_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'referenceNum',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'loanNum',
      Header: 'Loan #',
      accessor: d => d.loan_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'client',
      Header: 'Close By',
      accessor: d => d.company,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'transactionNum',
      Header: 'Transaction #',
      accessor: d => d.transaction_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'property',
      Header: 'Property',
      accessor: d => d.property_street+' '+d.property_city+', '+d.property_state+' '+d.property_zip,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'borrower',
      Header: 'Borrower',
      accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'status',
      Header: 'Order Status',
      accessor: d => d.order_status,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'amount',
      Header: 'Amount',
      accessor: d => d.total,
      Cell: props => <div>${formatNumber(props.row.original.total)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'profit',
      Header: 'Profit',
      accessor: d => d.profit,
      Cell: props => <div>${formatNumber(props.row.original.profit)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'date',
      Header: 'Date',
      accessor: d => d.datetime_created,
      Cell: props=> <div>{formatDateTime(props.row.original.datetime_created)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];


  const ccVoidRefund = [
    {
      id: 'action',
      Header: ()=><Input style={{marginLeft:'1px'}} type="checkbox" checked={state['ccVoidRefundChecked']} onChange={()=>selectAll('ccVoidRefund')}/>,
      sortable: false,
      maxWidth:50,
      width:50,
      accessor: d => d.reference_num,
      Cell: props => {
        return <div>
        <div className="display-inline">
          <FormGroup check>
            <Label check>
              <Input type="checkbox" checked={state.IDsForInvoice.indexOf(props.row.original.invoice_fk)!==-1} onChange={(e)=>addToInvoiceIDs(props.row.original)}/>
            </Label>
          </FormGroup>
        </div>
      </div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'referenceNum',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'loanNum',
      Header: 'Loan #',
      accessor: d => d.loan_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'client',
      Header: 'Close By',
      accessor: d => d.company,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'transactionNum',
      Header: 'Transaction #',
      accessor: d => d.transaction_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'property',
      Header: 'Property',
      accessor: d => d.property_street+' '+d.property_city+', '+d.property_state+' '+d.property_zip,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'borrower',
      Header: 'Borrower',
      accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'status',
      Header: 'Order Status',
      accessor: d => d.order_status,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'amount',
      Header: 'Amount',
      accessor: d => d.amount,
      Cell: props => <div>${formatNumber(props.row.original.amount)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'date',
      Header: 'Date',
      accessor: d => d.datetime_created,
      Cell: props=> <div>{formatDateTime(props.row.original.datetime_created)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  const appraisalPortACH = [
    {
      id: 'action',
      Header: ()=><Input style={{marginLeft:'1px'}} type="checkbox" checked={state['appraisalPortACHChecked']} onChange={()=>selectAll('appraisalPortACH')}/>,
      sortable: false,
      maxWidth:50,
      width:50,
      accessor: d => d.reference_num,
      Cell: props => {
        return <div>
        <div className="display-inline">
          <FormGroup check>
            <Label check>
              <Input type="checkbox" checked={state.IDsForInvoice.indexOf(props.row.original.invoice_fk)!==-1} onChange={(e)=>addToInvoiceIDs(props.row.original)}/>
            </Label>
          </FormGroup>
        </div>
      </div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'referenceNum',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'loanNum',
      Header: 'Loan #',
      accessor: d => d.loan_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'client',
      Header: 'Close By',
      accessor: d => d.company,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'transactionNum',
      Header: 'Transaction #',
      accessor: d => d.transaction_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'property',
      Header: 'Property',
      accessor: d => d.property_street+' '+d.property_city+', '+d.property_state+' '+d.property_zip,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'borrower',
      Header: 'Borrower',
      accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'status',
      Header: 'Order Status',
      accessor: d => d.order_status,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'amount',
      Header: 'Amount',
      accessor: d => d.total,
      Cell: props => <div>${formatNumber(props.row.original.total)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'profit',
      Header: 'Profit',
      accessor: d => d.profit,
      Cell: props => <div>${formatNumber(props.row.original.profit)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'date',
      Header: 'Date',
      accessor: d => d.datetime_created,
      Cell: props=> <div>{formatDateTime(props.row.original.datetime_created)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  const otherSuccess = [
    {
      id: 'action',
      sortable: false,
      maxWidth:50,
      width:50,
      Cell: props => {
        return <div>

      </div>
      },
      accessor: d => d.reference_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'referenceNum',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'method',
      Header: 'Method',
      accessor: d => d.method,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'loanNum',
      Header: 'Loan #',
      accessor: d => d.loan_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'client',
      Header: 'Close By',
      accessor: d => d.company,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'transactionNum',
      Header: 'Transaction #',
      accessor: d => d.transaction_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'property',
      Header: 'Property',
      accessor: d => d.property_street+' '+d.property_city+', '+d.property_state+' '+d.property_zip,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'borrower',
      Header: 'Borrower',
      accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'status',
      Header: 'Order Status',
      accessor: d => d.order_status,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'amount',
      Header: 'Amount',
      accessor: d => d.total,
      Cell: props => <div>${formatNumber(props.row.original.total)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'profit',
      Header: 'Profit',
      accessor: d => d.profit,
      Cell: props => <div>${formatNumber(props.row.original.profit)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'date',
      Header: 'Date',
      accessor: d => d.datetime_created,
      Cell: props=> <div>{formatDateTime(props.row.original.datetime_created)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  const ccDeclined = [
    {
      id: 'action',
      Header: ()=><Input style={{marginLeft:'1px'}} type="checkbox" checked={state['ccDeclinedChecked']} onChange={()=>selectAll('ccDeclined')}/>,
      sortable: false,
      maxWidth:50,
      width:50,
      accessor: d => d.reference_num,
      Cell: props => {
        return <div>
        <div className="display-inline">
          <FormGroup check>
            <Label check>
              <Input type="checkbox" checked={state.IDsForInvoice.indexOf(props.row.original.invoice_fk)!==-1} onChange={(e)=>addToInvoiceIDs(props.row.original)}/>
            </Label>
          </FormGroup>
        </div>
      </div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'referenceNum',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'loanNum',
      Header: 'Loan #',
      accessor: d => d.loan_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'client',
      Header: 'Close By',
      accessor: d => d.company,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'property',
      Header: 'Property',
      accessor: d => d.property_street+' '+d.property_city+', '+d.property_state+' '+d.property_zip,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'orderStatus',
      Header: 'Order Status',
      accessor: d => d.order_status,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'status',
      Header: 'Status',
      accessor: d => d.status,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'amount',
      Header: 'Amount',
      accessor: d => d.total,
      Cell: props => <div>${formatNumber(props.row.original.total)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'profit',
      Header: 'Profit',
      accessor: d => d.profit,
      Cell: props => <div>${formatNumber(props.row.original.profit)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'date',
      Header: 'Date',
      accessor: d => d.datetime_created,
      Cell: props=> <div>{formatDateTime(props.row.original.datetime_created)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  const ccBalanceDue = [
    {
      id: 'action',
      Header: ()=><Input style={{marginLeft:'1px'}} type="checkbox" checked={state['ccBalanceDueChecked']} onChange={()=>selectAll('ccBalanceDue')}/>,
      sortable: false,
      maxWidth:50,
      width:50,
      accessor: d => d.reference_num,
      Cell: props => {
        return <div>
        <div className="display-inline">
          <FormGroup check>
            <Label check>
              <Input type="checkbox" checked={state.IDsForInvoice.indexOf(props.row.original.invoice_fk)!==-1} onChange={(e)=>addToInvoiceIDs(props.row.original)}/>
            </Label>
          </FormGroup>
        </div>
      </div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'referenceNum',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'loanNum',
      Header: 'Loan #',
      accessor: d => d.loan_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'client',
      Header: 'Close By',
      accessor: d => d.company,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'property',
      Header: 'Property',
      accessor: d => d.property_street+' '+d.property_city+', '+d.property_state+' '+d.property_zip,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'orderStatus',
      Header: 'Order Status',
      accessor: d => d.order_status,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'status',
      Header: 'Status',
      accessor: d => d.status,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'amount',
      Header: 'Amount',
      accessor: d => d.total,
      Cell: props => <div>${formatNumber(props.row.original.total)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'profit',
      Header: 'Profit',
      accessor: d => d.profit,
      Cell: props => <div>${formatNumber(props.row.original.profit)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'date',
      Header: 'Date',
      accessor: d => d.datetime_created,
      Cell: props=> <div>{formatDateTime(props.row.original.datetime_created)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];


  const paidAtOutsidePending = [
    {
      id: 'action',
      Header: ()=><Input style={{marginLeft:'1px'}} type="checkbox" checked={state['paidAtOutsidePendingChecked']} onChange={()=>selectAll('paidAtOutsidePending')}/>,
      sortable: false,
      maxWidth:50,
      width:50,
      accessor: d => d.reference_num,
      Cell: props => {
        return <div>
        <div className="display-inline">
          <FormGroup check>
            <Label check>
              <Input type="checkbox" checked={state.IDsForInvoice.indexOf(props.row.original.invoice_fk)!==-1} onChange={(e)=>addToInvoiceIDs(props.row.original)}/>
            </Label>
          </FormGroup>
        </div>
      </div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'referenceNum',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'loanNum',
      Header: 'Loan #',
      accessor: d => d.loan_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'client',
      Header: 'Close By',
      accessor: d => d.company,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'property',
      Header: 'Property',
      accessor: d => d.property_street+' '+d.property_city+', '+d.property_state+' '+d.property_zip,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'borrower',
      Header: 'Borrower',
      accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'status',
      Header: 'Order Status',
      accessor: d => d.order_status,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'amount',
      Header: 'Amount',
      accessor: d => d.total,
      Cell: props => <div>${formatNumber(props.row.original.total)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'profit',
      Header: 'Profit',
      accessor: d => d.profit,
      Cell: props => <div>${formatNumber(props.row.original.profit)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'date',
      Header: 'Date',
      accessor: d => d.datetime_created,
      Cell: props=> <div>{formatDateTime(props.row.original.datetime_created)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];


  const stripePending = [
    {
      id: 'action',
      Header: ()=><Input style={{marginLeft:'1px'}} type="checkbox" checked={state['stripePendingChecked']} onChange={()=>selectAll('stripePending')}/>,
      sortable: false,
      maxWidth:50,
      width:50,
      accessor: d => d.reference_num,
      Cell: props => {
        return <div>
        <div className="display-inline">
          <FormGroup check>
            <Label check>
              <Input type="checkbox" checked={state.IDsForInvoice.indexOf(props.row.original.invoice_fk)!==-1} onChange={(e)=>addToInvoiceIDs(props.row.original)}/>
            </Label>
          </FormGroup>
        </div>
      </div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'referenceNum',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'loanNum',
      Header: 'Loan #',
      accessor: d => d.loan_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'client',
      Header: 'Close By',
      accessor: d => d.company,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'property',
      Header: 'Property',
      accessor: d => d.property_street+' '+d.property_city+', '+d.property_state+' '+d.property_zip,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'borrower',
      Header: 'Borrower',
      accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'status',
      Header: 'Order Status',
      accessor: d => d.order_status,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'amount',
      Header: 'Amount',
      accessor: d => d.total,
      Cell: props => <div>${formatNumber(props.row.original.total)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'profit',
      Header: 'Profit',
      accessor: d => d.profit,
      Cell: props => <div>${formatNumber(props.row.original.profit)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'date',
      Header: 'Date',
      accessor: d => d.datetime_created,
      Cell: props=> <div>{formatDateTime(props.row.original.datetime_created)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  const stripeRefunded = [
    {
      id: 'action',
      Header: ()=><Input style={{marginLeft:'1px'}} type="checkbox" checked={state['stripePendingChecked']} onChange={()=>selectAll('stripePending')}/>,
      sortable: false,
      maxWidth:50,
      width:50,
      accessor: d => d.reference_num,
      Cell: props => {
        return <div>
        <div className="display-inline">
          <FormGroup check>
            <Label check>
              <Input type="checkbox" checked={state.IDsForInvoice.indexOf(props.row.original.invoice_fk)!==-1} onChange={(e)=>addToInvoiceIDs(props.row.original)}/>
            </Label>
          </FormGroup>
        </div>
      </div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'referenceNum',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'clientRefunded',
      Header: 'Client Refund',
      accessor: d => d.third_party_refund,
      Cell: props => props.row.original.third_party_refund==='yes'?<font color="red">Yes</font>:'No',
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'loanNum',
      Header: 'Loan #',
      accessor: d => d.loan_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'client',
      Header: 'Close By',
      accessor: d => d.company,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'property',
      Header: 'Property',
      accessor: d => d.property_street+' '+d.property_city+', '+d.property_state+' '+d.property_zip,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'borrower',
      Header: 'Borrower',
      accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'status',
      Header: 'Order Status',
      accessor: d => d.order_status,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'amount',
      Header: 'Amount',
      accessor: d => d.total,
      Cell: props => <div>${formatNumber(props.row.original.total)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'profit',
      Header: 'Profit',
      accessor: d => d.profit,
      Cell: props => <div>${formatNumber(props.row.original.profit)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'date',
      Header: 'Date',
      accessor: d => d.datetime_created,
      Cell: props=> <div>{formatDateTime(props.row.original.datetime_created)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  const stripeBalanceDue = [
    {
      id: 'action',
      Header: ()=><Input style={{marginLeft:'1px'}} type="checkbox" checked={state['stripeBalanceDueChecked']} onChange={()=>selectAll('stripeBalanceDue')}/>,
      sortable: false,
      maxWidth:50,
      width:50,
      accessor: d => d.reference_num,
      Cell: props => {
        return <div>
        <div className="display-inline">
          <FormGroup check>
            <Label check>
              <Input type="checkbox" checked={state.IDsForInvoice.indexOf(props.row.original.invoice_fk)!==-1} onChange={(e)=>addToInvoiceIDs(props.row.original)}/>
            </Label>
          </FormGroup>
        </div>
      </div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'referenceNum',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'loanNum',
      Header: 'Loan #',
      accessor: d => d.loan_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'client',
      Header: 'Close By',
      accessor: d => d.company,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'property',
      Header: 'Property',
      accessor: d => d.property_street+' '+d.property_city+', '+d.property_state+' '+d.property_zip,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'borrower',
      Header: 'Borrower',
      accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'status',
      Header: 'Order Status',
      accessor: d => d.order_status,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'amount',
      Header: 'Amount',
      accessor: d => d.total,
      Cell: props => <div>${formatNumber(props.row.original.total)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'profit',
      Header: 'Profit',
      accessor: d => d.profit,
      Cell: props => <div>${formatNumber(props.row.original.profit)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'date',
      Header: 'Date',
      accessor: d => d.datetime_created,
      Cell: props=> <div>{formatDateTime(props.row.original.datetime_created)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  const otherPending = [
    {
      id: 'action',




      Header: ()=><Input style={{marginLeft:'1px'}} type="checkbox" checked={state['otherPendingChecked']} onChange={()=>selectAll('otherPending')}/>,
      sortable: false,

      maxWidth:50,
      width:50,
      accessor: d => d.reference_num,
      Cell: props => {
        return <div>
        <div className="display-inline">
          <FormGroup check>
            <Label check>
              <Input type="checkbox" checked={state.IDsForInvoice.indexOf(props.row.original.invoice_fk)!==-1} onChange={(e)=>addToInvoiceIDs(props.row.original)}/>
            </Label>
          </FormGroup>
        </div>
      </div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'referenceNum',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'method',
      Header: 'Method',
      accessor: d => d.method,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'loanNum',
      Header: 'Loan #',
      accessor: d => d.loan_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'client',
      Header: 'Close By',
      accessor: d => d.company,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'property',
      Header: 'Property',
      accessor: d => d.property_street+' '+d.property_city+', '+d.property_state+' '+d.property_zip,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'borrower',
      Header: 'Borrower',
      accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'status',
      Header: 'Order Status',
      accessor: d => d.order_status,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'amount',
      Header: 'Amount',
      accessor: d => d.total,
      Cell: props => <div>${formatNumber(props.row.original.total)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'profit',
      Header: 'Profit',
      accessor: d => d.profit,
      Cell: props => <div>${formatNumber(props.row.original.profit)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'date',
      Header: 'Date',
      accessor: d => d.datetime_created,
      Cell: props=> <div>{formatDateTime(props.row.original.datetime_created)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  const otherBalanceDue = [
    {
      id: 'action',
      Header: ()=><Input style={{marginLeft:'1px'}} type="checkbox" checked={state['otherBalanceDueChecked']} onChange={()=>selectAll('otherBalanceDue')}/>,
      sortable: false,
      maxWidth:50,
      width:50,
      accessor: d => d.reference_num,
      Cell: props => {
        return <div>
        <div className="display-inline">
          <FormGroup check>
            <Label check>
              <Input type="checkbox" checked={state.IDsForInvoice.indexOf(props.row.original.invoice_fk)!==-1} onChange={(e)=>addToInvoiceIDs(props.row.original)}/>
            </Label>
          </FormGroup>
        </div>
      </div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'referenceNum',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'method',
      Header: 'Method',
      accessor: d => d.method,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'loanNum',
      Header: 'Loan #',
      accessor: d => d.loan_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'client',
      Header: 'Close By',
      accessor: d => d.company,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'property',
      Header: 'Property',
      accessor: d => d.property_street+' '+d.property_city+', '+d.property_state+' '+d.property_zip,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'borrower',
      Header: 'Borrower',
      accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'status',
      Header: 'Order Status',
      accessor: d => d.order_status,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'amount',
      Header: 'Amount',
      accessor: d => d.total,
      Cell: props => <div>${formatNumber(props.row.original.total)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'profit',
      Header: 'Profit',
      accessor: d => d.profit,
      Cell: props => <div>${formatNumber(props.row.original.profit)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'date',
      Header: 'Date',
      accessor: d => d.datetime_created,
      Cell: props=> <div>{formatDateTime(props.row.original.datetime_created)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  let totalSuccess = state.ccSuccessTotal+state.stripeSuccessTotal+state.paidAtOutsideSuccessTotal+state.otherSuccessTotal;
  let totalOwe = state.ccFailedTotal + state.stripePendingTotal+state.paidAtOutsidePendingTotal+state.otherPendingTotal + state.totalBalanceDueTotal;
  let totalFailed = state.ccFailedTotal;
  let totalPending = state.stripePendingTotal+state.paidAtOutsidePendingTotal+state.otherPendingTotal;
  let totalBalanceDue = state.totalBalanceDueTotal;

  let reportFiltersConfig = [
    {id:'from',value:from, updateFunc:(v)=>setState({from:v}), width:'2'},
    {id:'to',value:to, updateFunc:(v)=>setState({to:v}), width:'2'},
    {id:'openBy',value:state.openBy, updateFunc:(v)=>setState({openBy:v}), width:'2'},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'2'},
    {id:'orderStatus',value:state.status, updateFunc:(v)=>setState({status:v}), width:'2'},
    {id:'button',value:'Submit', updateFunc:getReportDetails, width:'2', className:"align-right", color:"warning"},
  ];

  return <div>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Receivable Report
      </CardHeader>
      <CardBody>
        <ReportFilter {...props} configs={reportFiltersConfig}/>
        <div className="my-divider">&nbsp;</div>
        <Row>
          <Col sm="12" style={{textAlign:'center'}}>
            <label>Total</label><br/>
            &nbsp;&nbsp;&nbsp;<i><b style={{fontSize:'20px'}}>Success : ${formatNumber(totalSuccess)}</b></i><br/>
            &nbsp;&nbsp;&nbsp;<i><b style={{fontSize:'20px'}}>Outstanding <i className="fa fa-info-circle cursor-pointer link-color" id="totalOwe"/>
            <UncontrolledTooltip className="mytooltip" delay={{ "show": 0, "hide": 0 }} placement="right" target={"totalOwe"}>
              <i>Failed : ${formatNumber(totalFailed)}</i><br/>
              <i>Pending : ${formatNumber(totalPending)}</i><br/>
              <i>Balance Due Only: ${formatNumber(totalBalanceDue)}</i><br/>
            </UncontrolledTooltip>
              : ${formatNumber(totalOwe)}</b></i><br/>
              <br/>
            &nbsp;&nbsp;&nbsp;<i>Refunded : ${formatNumber(state.stripeRefundedTotal)}</i><br/>
            &nbsp;&nbsp;&nbsp;<i>Void / Refund : ${formatNumber(state.ccVoidRefundTotal)}</i><br/>
          </Col>
        </Row>
        <div className="my-divider">&nbsp;</div>
        <Row>
          <Col sm="3">
            <label>Authorize.net</label><br/>
            &nbsp;&nbsp;&nbsp;<i>Success : ${formatNumber(state.ccSuccessTotal)}</i><br/>
            &nbsp;&nbsp;&nbsp;<i>Failed : ${formatNumber(state.ccFailedTotal)}</i><br/>
            &nbsp;&nbsp;&nbsp;<i>Void / Refund : ${formatNumber(state.ccVoidRefundTotal)}</i><br/>
            &nbsp;&nbsp;&nbsp;<i>Balance Due / Overage : ${formatNumber(state.ccBalanceDueTotal)}</i><br/><br/>
          </Col>
          <Col sm="3">
            <label>Mercury CC</label><br/>
            &nbsp;&nbsp;&nbsp;<i>Success : ${formatNumber(state.mercuryCCSuccessTotal)}</i><br/>
            &nbsp;&nbsp;&nbsp;<i>Failed : ${formatNumber(state.mercuryCCFailedTotal)}</i><br/>
            &nbsp;&nbsp;&nbsp;<i>Void / Refund : ${formatNumber(state.mercuryCCVoidRefundTotal)}</i><br/>
            &nbsp;&nbsp;&nbsp;<i>Balance Due / Overage : ${formatNumber(state.mercuryCCBalanceDueTotal)}</i><br/><br/>
          </Col>
          <Col sm="3">
            <label>Stripe</label><br/>
            &nbsp;&nbsp;&nbsp;<i>Success : ${formatNumber(state.stripeSuccessTotal)}</i><br/>
            &nbsp;&nbsp;&nbsp;<i>Pending : ${formatNumber(state.stripePendingTotal)}</i><br/>
            &nbsp;&nbsp;&nbsp;<i>Refunded : ${formatNumber(state.stripeRefundedTotal)}</i><br/>
            &nbsp;&nbsp;&nbsp;<i>Balance Due / Overage : ${formatNumber(state.stripeBalanceDueTotal)}</i><br/><br/>
          </Col>
          <Col sm="3">
            <label>Paid At / Paid Outside Of Closing</label><br/>
            &nbsp;&nbsp;&nbsp;<i>Success : ${formatNumber(state.paidAtOutsideSuccessTotal)}</i><br/>
            &nbsp;&nbsp;&nbsp;<i>Pending : ${formatNumber(state.paidAtOutsidePendingTotal)}</i><br/>
            &nbsp;&nbsp;&nbsp;<i>Balance Due / Overage : ${formatNumber(state.paidAtOutsideBalanceDueTotal)}</i><br/><br/>
          </Col>
          <Col sm="3">
            <label>Other</label><br/>
            &nbsp;&nbsp;&nbsp;<i>Success : ${formatNumber(state.otherSuccessTotal)}</i><br/>
            &nbsp;&nbsp;&nbsp;<i>Pending : ${formatNumber(state.otherPendingTotal)}</i><br/>
            &nbsp;&nbsp;&nbsp;<i>Balance Due / Overage : ${formatNumber(state.otherBalanceDueTotal)}</i><br/><br/>
          </Col>
        </Row>
        <br/>
        <div className="align-right">
          <Button color="warning" disabled={state.IDsForInvoice.length===0} onClick={generateInvoice}>Generate Invoice For Selected Orders</Button>
          &nbsp;&nbsp;<ExcelFile element={<Button to="#" disabled={state.arrayForExcel.length<=0} color="warning">Download Excel For Selected Orders</Button>}>
            <ExcelSheet data={state.arrayForExcel} name="XLSX">
                <ExcelColumn label="Reference #" value="reference_num"/>
                <ExcelColumn label="Loan #" value="loan_num"/>
                <ExcelColumn label="Close By" value="company"/>
                <ExcelColumn label="Transaction #" value={(col)=>{return col.transaction_num?col.transaction_num:''}}/>
                <ExcelColumn label="Address" value={(col)=>{return col.property_street+' '+col.property_city+', '+col.property_state+' '+col.property_zip}}/>
                <ExcelColumn label="Borrower" value={(col)=>{return col.borrower_f_name+' '+col.borrower_l_name}}/>
                <ExcelColumn label="Status" value="order_status"/>
                <ExcelColumn label="Amount" value={(col)=>'$'+col.total}/>
                <ExcelColumn label="Profit" value={(col)=>col.profit?'$'+formatNumber(col.profit):''}/>
                <ExcelColumn label="Date" value={(col)=>formatDateTime(col.datetime_created)}/>
            </ExcelSheet>
          </ExcelFile>
        </div>
        <br/>
        <Nav tabs>
          <NavItem>
            <NavLinkL
              className={"nav-link "+(state.activeTab === '1'?"active":"inactive" )}
              onClick={() => { activeTabToggle('1'); }}
              to="#"
            >
              Authorize.net
            </NavLinkL>
          </NavItem>
          <NavItem>
            <NavLinkL
              className={"nav-link "+(state.activeTab === '7'?"active":"inactive" )}
              onClick={() => { activeTabToggle('7'); }}
              to="#"
            >
              Mercury CC
            </NavLinkL>
          </NavItem>
          <NavItem>
            <NavLinkL
              className={"nav-link "+(state.activeTab === '2'?"active":"inactive" ) }
              onClick={() => { activeTabToggle('2'); }}
              to="#"
            >
              Stripe
            </NavLinkL>
          </NavItem>
          <NavItem>
            <NavLinkL
              className={"nav-link "+(state.activeTab === '3'?"active":"inactive" ) }
              onClick={() => { activeTabToggle('3'); }}
              to="#"
            >
              Paid At / Paid Outisde of Closing
            </NavLinkL>
          </NavItem>
          <NavItem>
            <NavLinkL
              className={"nav-link "+(state.activeTab === '4'?"active":"inactive" ) }
              onClick={() => { activeTabToggle('4'); }}
              to="#"
            >
              Other
            </NavLinkL>
          </NavItem>
          <NavItem>
            <NavLinkL
              className={"nav-link "+(state.activeTab === '5'?"active":"inactive" ) }
              onClick={() => { activeTabToggle('5'); }}
              to="#"
            >
              Appraisal Port ACH Transferred
            </NavLinkL>
          </NavItem>
        </Nav>
        <TabContent activeTab={state.activeTab}>
          <TabPane tabId="1">
            <Card>
              <CardHeader className="gray-color">
                <Row>
                  <Col sm="8">
                    Success
                  </Col>
                  <Col sm="4">
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="large-scroll-container">
                  <MyReactTable columns={ccSuccess} data={state.ccSuccess} className="table table-striped"/>
                </div>
                <div className="align-right">
                  <b>Profit Total: ${formatNumber(state.ccSuccessProfitTotal)}&nbsp;&nbsp;&nbsp;Total: ${formatNumber(state.ccSuccessTotal)}</b>
                </div>
              </CardBody>
            </Card>
            <br/>
            <div className="my-divider">&nbsp;</div>
            <Card>
              <CardHeader className="gray-color">
                <Row>
                  <Col sm="8">
                    Void / Refund
                  </Col>
                  <Col sm="4">
                    <div className="align-right">

                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="large-scroll-container">
                  <MyReactTable columns={ccVoidRefund} data={state.ccVoidRefund} className="table table-striped"/>
                </div>
                <div className="align-right">
                  <b>Total: ${formatNumber(state.ccVoidRefundTotal)}</b>
                </div>
              </CardBody>
            </Card>
            <br/>
            <Card>
              <CardHeader className="gray-color">
                <Row>
                  <Col sm="8">
                    Failed
                  </Col>
                  <Col sm="4">
                    <div className="align-right">

                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="large-scroll-container">
                  <MyReactTable columns={ccDeclined} data={state.ccDeclined} className="table table-striped"/>
                </div>
                <div className="align-right">
                  <b>Profit Total: ${formatNumber(state.ccFailedProfitTotal)}&nbsp;&nbsp;&nbsp;Total: ${formatNumber(state.ccFailedTotal)}</b>
                </div>
              </CardBody>
            </Card>
            <br/>
            <Card>
              <CardHeader className="gray-color">
                <Row>
                  <Col sm="8">
                      Balance Due / Reconcile (Charged Transaction Amnount Not Match)
                  </Col>
                  <Col sm="4">
                    <div className="align-right">

                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="large-scroll-container">
                  <MyReactTable columns={ccBalanceDue} data={state.ccBalanceDue} className="table table-striped"/>
                </div>
                <div className="align-right">
                  <b>Total: ${formatNumber(state.ccBalanceDueTotal)}</b>
                </div>
              </CardBody>
            </Card>
          </TabPane>
          <TabPane tabId="7">
            <Card>
              <CardHeader className="gray-color">
                <Row>
                  <Col sm="8">
                    Success
                  </Col>
                  <Col sm="4">
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="large-scroll-container">
                  <MyReactTable columns={ccSuccess} data={state.mercuryCCSuccess} className="table table-striped"/>
                </div>
                <div className="align-right">
                  <b>Profit Total: ${formatNumber(state.mercuryCCSuccessProfitTotal)}&nbsp;&nbsp;&nbsp;Total: ${formatNumber(state.mercuryCCSuccessTotal)}</b>
                </div>
              </CardBody>
            </Card>
            <br/>
            <div className="my-divider">&nbsp;</div>
            <Card>
              <CardHeader className="gray-color">
                <Row>
                  <Col sm="8">
                    Void / Refund
                  </Col>
                  <Col sm="4">
                    <div className="align-right">

                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="large-scroll-container">
                  <MyReactTable columns={ccVoidRefund} data={state.mercuryCCVoidRefund} className="table table-striped"/>
                </div>
                <div className="align-right">
                  <b>Total: ${formatNumber(state.mercuryCCVoidRefundTotal)}</b>
                </div>
              </CardBody>
            </Card>
            <br/>
            <Card>
              <CardHeader className="gray-color">
                <Row>
                  <Col sm="8">
                    Failed
                  </Col>
                  <Col sm="4">
                    <div className="align-right">

                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="large-scroll-container">
                  <MyReactTable columns={ccDeclined} data={state.mercuryCCDeclined} className="table table-striped"/>
                </div>
                <div className="align-right">
                  <b>Profit Total: ${formatNumber(state.mercuryCCFailedProfitTotal)}&nbsp;&nbsp;&nbsp;Total: ${formatNumber(state.mercuryCCFailedTotal)}</b>
                </div>
              </CardBody>
            </Card>
            <br/>
            <Card>
              <CardHeader className="gray-color">
                <Row>
                  <Col sm="8">
                      Balance Due / Reconcile (Charged Transaction Amnount Not Match)
                  </Col>
                  <Col sm="4">
                    <div className="align-right">

                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="large-scroll-container">
                  <MyReactTable columns={ccBalanceDue} data={state.mercuryCCBalanceDue} className="table table-striped"/>
                </div>
                <div className="align-right">
                  <b>Total: ${formatNumber(state.mercuryCCBalanceDueTotal)}</b>
                </div>
              </CardBody>
            </Card>
          </TabPane>
          <TabPane tabId="2">
            <Card>
              <CardHeader className="gray-color">
                <Row>
                  <Col sm="8">
                    Success
                  </Col>
                  <Col sm="4">
                    <div className="align-right">

                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="large-scroll-container">
                  <MyReactTable columns={stripeSuccess} data={state.stripeSuccess} className="table table-striped"/>
                </div>
                <div className="align-right">
                  <b>Profit Total: ${formatNumber(state.stripeSuccessProfitTotal)}&nbsp;&nbsp;&nbsp;Total: ${formatNumber(state.stripeSuccessTotal)}</b>
                </div>
              </CardBody>
            </Card>
            <br/>
            <div className="my-divider">&nbsp;</div>
            <Card>
              <CardHeader className="gray-color">
                <Row>
                  <Col sm="8">
                    Pending
                  </Col>
                  <Col sm="4">
                    <div className="align-right">

                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="large-scroll-container">
                  <MyReactTable columns={stripePending} data={state.stripePending} className="table table-striped"/>
                </div>
                <div className="align-right">
                  <b>Profit Total: ${formatNumber(state.stripePendingProfitTotal)}&nbsp;&nbsp;&nbsp;Total: ${formatNumber(state.stripePendingTotal)}</b>
                </div>
              </CardBody>
            </Card>
            <br/>
            <div className="my-divider">&nbsp;</div>

            <Card>
              <CardHeader className="gray-color">
                <Row>
                  <Col sm="8">
                    Refunded
                  </Col>
                  <Col sm="4">
                    <div className="align-right">

                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="large-scroll-container">
                  <MyReactTable columns={stripeRefunded} data={state.stripeRefunded} className="table table-striped"/>
                </div>
                <div className="align-right">
                  <b>Profit Total: ${formatNumber(state.stripeRefundedProfitTotal)}&nbsp;&nbsp;&nbsp;Total: ${formatNumber(state.stripeRefundedTotal)}</b>
                </div>
              </CardBody>
            </Card>
            <br/>
            <div className="my-divider">&nbsp;</div>

            <Card>
              <CardHeader className="gray-color">
                <Row>
                  <Col sm="8">
                    Balance Due / Reconcile (Charged Transaction Amnount Not Match)
                  </Col>
                  <Col sm="4">
                    <div className="align-right">

                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="large-scroll-container">
                  <MyReactTable columns={stripeBalanceDue} data={state.stripeBalanceDue} className="table table-striped"/>
                </div>
                <div className="align-right">
                  <b>Profit Total: ${formatNumber(state.stripeBalanceDueProfitTotal)}&nbsp;&nbsp;&nbsp;Total: ${formatNumber(state.stripeBalanceDueTotal)}</b>
                </div>
              </CardBody>
            </Card>
            <br/>
          </TabPane>
          <TabPane tabId="3">
            <Card>
              <CardHeader className="gray-color">
                <Row>
                  <Col sm="8">
                    Success
                  </Col>
                  <Col sm="4">
                    <div className="align-right">

                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="large-scroll-container">
                  <MyReactTable columns={paidAtOutsideSuccess} data={state.paidAtOutsideSuccess} className="table table-striped"/>
                </div>
                <div className="align-right">
                  <b>Profit Total: ${formatNumber(state.paidAtOutsideSuccessProfitTotal)}&nbsp;&nbsp;&nbsp;Total: ${formatNumber(state.paidAtOutsideSuccessTotal)}</b>
                </div>
              </CardBody>
            </Card>
            <br/>
            <div className="my-divider">&nbsp;</div>
            <Card>
              <CardHeader className="gray-color">
                <Row>
                  <Col sm="8">
                    Pending
                  </Col>
                  <Col sm="4">
                    <div className="align-right">

                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="large-scroll-container">
                  <MyReactTable columns={paidAtOutsidePending} data={state.paidAtOutsidePending} className="table table-striped"/>
                </div>
                <div className="align-right">
                  <b>Profit Total: ${formatNumber(state.paidAtOutsidePendingProfitTotal)}&nbsp;&nbsp;&nbsp;Total: ${formatNumber(state.paidAtOutsidePendingTotal)}</b>
                </div>
              </CardBody>
            </Card>
            <br/>
            <div className="my-divider">&nbsp;</div>

            <Card>
              <CardHeader className="gray-color">
                <Row>
                  <Col sm="8">
                    Balance Due / Reconcile (Charged Transaction Amnount Not Match)
                  </Col>
                  <Col sm="4">
                    <div className="align-right">

                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="large-scroll-container">
                  <MyReactTable columns={paidAtOutsideBalanceDue} data={state.paidAtOutsideBalanceDue} className="table table-striped"/>
                </div>
                <div className="align-right">
                  <b>Profit Total: ${formatNumber(state.paidAtOutsideBalanceDueProfitTotal)}&nbsp;&nbsp;&nbsp;Total: ${formatNumber(state.paidAtOutsideBalanceDueTotal)}</b>
                </div>
              </CardBody>
            </Card>
            <br/>
          </TabPane>
          <TabPane tabId="4">
            <Card>
              <CardHeader className="gray-color">
                <Row>
                  <Col sm="8">
                    Success
                  </Col>
                  <Col sm="4">
                    <div className="align-right">

                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="large-scroll-container">
                  <MyReactTable columns={otherSuccess} data={state.otherSuccess} className="table table-striped"/>
                </div>
                <div className="align-right">
                  <b>Profit Total: ${formatNumber(state.otherSuccessProfitTotal)}&nbsp;&nbsp;&nbsp;Total: ${formatNumber(state.otherSuccessTotal)}</b>
                </div>
              </CardBody>
            </Card>
            <br/>
            <div className="my-divider">&nbsp;</div>
            <Card>
              <CardHeader className="gray-color">
                <Row>
                  <Col sm="8">
                    Pending
                  </Col>
                  <Col sm="4">
                    <div className="align-right">

                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="large-scroll-container">
                  <MyReactTable columns={otherPending} data={state.otherPending} className="table table-striped"/>
                </div>
                <div className="align-right">
                  <b>Profit Total: ${formatNumber(state.otherPendingProfitTotal)}&nbsp;&nbsp;&nbsp;Total: ${formatNumber(state.otherPendingTotal)}</b>
                </div>
              </CardBody>
            </Card>
            <br/>
            <div className="my-divider">&nbsp;</div>

            <Card>
              <CardHeader className="gray-color">
                <Row>
                  <Col sm="8">
                    Balance Due / Reconcile (Charged Transaction Amnount Not Match)
                  </Col>
                  <Col sm="4">
                    <div className="align-right">

                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="large-scroll-container">
                  <MyReactTable columns={otherBalanceDue} data={state.otherBalanceDue} className="table table-striped"/>
                </div>
                <div className="align-right">
                  <b>Profit Total: ${formatNumber(state.otherBalanceDueProfitTotal)}&nbsp;&nbsp;&nbsp;Total: ${formatNumber(state.otherBalanceDueTotal)}</b>
                </div>
              </CardBody>
            </Card>
            <br/>
          </TabPane>
          <TabPane tabId="5">
            <Card>
              <CardHeader className="gray-color">
                <Row>
                  <Col sm="8">
                    Transferred
                  </Col>
                  <Col sm="4">
                    <div className="align-right">

                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div className="large-scroll-container">
                  <MyReactTable columns={appraisalPortACH} data={state.appraisalPortACH} className="table table-striped"/>
                </div>

                <div className="align-right">
                  <b>Total: ${formatNumber(state.appraisalPortACHTotal)}</b>
                </div>
              </CardBody>
            </Card>
            <br/>
          </TabPane>
        </TabContent>
      </CardBody>
    </Card>
  </div>;
}


export default ReceivableReport;
