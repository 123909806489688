//Sooyoung Kim
//June 21, 2023
import {getReducer, numToWords, formatNumber} from '../../../util/util';
import React, {useReducer, useEffect} from 'react';
import {Row, Col} from 'reactstrap';

//initialize the state
const initialState = {
};

//reducer function that perform state update
const reducer = getReducer();


const CheckToPrint  = (props)=>{
  const controller = new AbortController();
  let amount = 0;

  for(let i=0;i<props.bills.length;i++){
    amount = amount + parseFloat(props.bills[i].total);
  }

  let newInitialState = Object.assign({}, initialState, {
    name: props.first_name+' '+props.last_name,
    amount: amount
  });

  const [state] = useReducer(reducer,newInitialState);

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //render
  let companyName;

  if(props.company_name!==''&&props.company_name!==state.name)
    companyName = <div>
      {props.company_name}
      <br/>
    </div>

  return <div>
    <div style={{height:props.thirdPadding,maxHeight:props.thirdPadding}}>

    </div>
    <div style={{height:props.firstPadding}}>
      <div style={{paddingBottom:'10px'}}>
        <div style={{verticalAlign:'top',display:'inline-block',width:'50%',height:'70px',maxHeight:'70px'}}>
          <center style={{paddingTop:'15px'}}>

          </center>
        </div>
        <div style={{verticalAlign:'top',display:'inline-block',width:'29%',height:'70px',maxHeight:'70px'}}>
          <center style={{paddingTop:'10px'}}>

          </center>
        </div>
        <div style={{fontSize:'25px',verticalAlign:'top',display:'inline-block',width:'19%',height:'70px',maxHeight:'70px'}}>
          <div style={{textAlign:'right',paddingTop:'30px',height:'20px',maxHeight:'15px'}}>

          </div>
          <div style={{textAlign:'right',fontSize:'18px'}}>
            {props.date}
          </div>
        </div>
      </div>
      <div>
        <div style={{fontSize:'13px',verticalAlign:'top',display:'inline-block',width:'13%',height:'30px',maxHeight:'30px'}}>

        </div>
        <div style={{fontSize:'20px',verticalAlign:'top',display:'inline-block',width:'66%',height:'30px',maxHeight:'30px'}}>
          <div style={{paddingTop:'32px'}}>
            {props.billing_name}
          </div>
        </div>
        <div style={{fontSize:'25px',verticalAlign:'top',display:'inline-block',width:'2%',height:'30px',maxHeight:'30px'}}>

        </div>
        <div style={{textAlign:'right',fontSize:'21px',verticalAlign:'top',display:'inline-block',width:'17%',height:'30px',maxHeight:'30px',paddingTop:'32px',paddingRight:'30px'}}>
          **{formatNumber(parseFloat(state.amount).toFixed(2))}
        </div>
      </div>
      <div style={{marginTop:'15px'}}>
        <div style={{fontSize:'20px',verticalAlign:'top',display:'inline-block',width:'85%',height:'30px',maxHeight:'30px'}}>
          <div style={{marginTop:'25px',verticalAlign:'bottom',paddingLeft:'30px'}}>
            {numToWords(parseFloat(state.amount)).toUpperCase()}
          </div>
        </div>
        <div style={{fontSize:'15px',verticalAlign:'top',display:'inline-block',width:'13%',marginTop:'10px'}}>

        </div>
      </div>
      <div style={{paddingLeft:'200px',paddingTop:'70px',fontSize:'20px',lineHeight:'20px'}}>
        {companyName}
        {state.name}<br/>
        {props.street}<br/>
        {props.city} {props.state} {props.zip}
      </div>
    </div>
    <div style={{height:props.secondPadding,maxHeight:props.secondPadding,minHeight:props.secondPadding}}>
      <div style={{fontSize:'20px',height:'20px',maxHeight:'20px',paddingTop:'10px',paddingLeft:'35px'}}>
        <Row>
          <Col sm="8">
            {state.name}
          </Col>
          <Col sm="4" className="align-right" style={{fontSize:'18px'}}>
            {props.date}
          </Col>
        </Row>
      </div>
      <div style={{paddingTop:'40px',paddingLeft:'100px',fontSize:'18px',lineHeight:'16px'}}>
        {
          props.bills.map(
            (bill, index)=>{
              return (
                <div key={index}>
                  <Row style={{height:'16px'}}>
                    <Col sm="8">
                      {bill.reference_num+':'+bill.bill_fk+'-'+bill.street+'-'+bill.borrower}
                    </Col>
                    <Col sm="4" className="align-right" style={{paddingRight:'50px'}}>
                      ${formatNumber(bill.total)}
                    </Col>
                  </Row>
                </div>
              )
            }
          )
        }
      </div>
      <div style={{paddingTop:'15px',paddingLeft:'100px',fontSize:'18px',lineHeight:'16px'}}>
        <Row style={{height:'16px'}}>
          <Col sm="10">
            <b>Total</b>
          </Col>
          <Col sm="2" className="align-right"  style={{paddingRight:'50px', borderTop:'1px solid #d2d2d2', borderBottom:'1px solid #d2d2d2'}}>
            <b>${formatNumber(parseFloat(state.amount).toFixed(2))}</b>
          </Col>
        </Row>
      </div>
    </div>
    <div style={{height:props.secondPadding,maxHeight:props.secondPadding,minHeight:props.secondPadding}}>
      <div style={{fontSize:'20px',height:'20px',maxHeight:'20px',paddingTop:'10px',paddingLeft:'35px'}}>
        <Row>
          <Col sm="8">
            {state.name}
          </Col>
          <Col sm="4" className="align-right" style={{fontSize:'18px'}}>
            {props.date}
          </Col>
        </Row>
      </div>
      <div style={{paddingTop:'40px',paddingLeft:'100px',fontSize:'18px',lineHeight:'16px'}}>
        {
          props.bills.map(
            (bill, index)=>{
              return (
                <div>
                  <Row style={{height:'16px'}}>
                    <Col sm="8">
                      {bill.reference_num+':'+bill.bill_fk+'-'+bill.borrower}
                    </Col>
                    <Col sm="4" className="align-right" style={{paddingRight:'50px'}}>
                      ${formatNumber(bill.total)}
                    </Col>
                  </Row>
                </div>
              )
            }
          )
        }
      </div>
      <div style={{paddingTop:'15px',paddingLeft:'100px',fontSize:'18px',lineHeight:'16px'}}>
        <Row style={{height:'16px'}}>
          <Col sm="10">
            <b>Total</b>
          </Col>
          <Col sm="2" className="align-right" style={{paddingRight:'50px', borderTop:'1px solid #d2d2d2', borderBottom:'1px solid #d2d2d2'}}>
            <b>${formatNumber(parseFloat(state.amount).toFixed(2))}</b>
          </Col>
        </Row>
      </div>
    </div>
  </div>;
}


export default CheckToPrint;
