//Author Sooyoung Kim
//Date June 23, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, formatNumber, formatDateTime, formatDate, getFromTo, replaceRegex} from '../../../util/util';
import {Button, Card, CardHeader, CardBody, Col, Row, Table} from 'reactstrap';
import React, { useReducer, useEffect } from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from "moment";
import { NavLink } from "react-router-dom";
import ReportFilter from '../report-filter';
import '../report.css';

import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

//initialize the state
const initialState = {
  appraisers: [],
  orders: [],

  limit: 25,
  offset: 0,
  hasMoreAppraisers: true,
  loading: false,
  sort: "first_name",
  order: "ASC",
  status: "",
  statuses: [],

  //for search
  name: "",
  company_name: "",
  email: "",
  state: "",
  county: "",
  city: "",
  license_type: "",

  selectedAppraiser: -1,
  selectedAppraiserName: "",

  from: getFromTo("start"),
  to: getFromTo("end"),

  loanPurpose: "",
  loanType: "",
  isRush: "",
  isComplex: "",
  entity: "",
  client: "",
  openBy: "",
  selectedAppraisalType: "",
  filterAppraisalTypes: [],
};

//reducer function that perform state update
const reducer = getReducer();

const AppraiserReport = (props) => {
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer, initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, { signal: controller.signal });

  useEffect(() => {
    console.log(state);
    if (state.appraisers.length <= 0 && state.hasMoreAppraisers) {
      loadMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  //run only once when component is loaded
  useEffect(() => {
    return () => controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //non API call but simpyl manage state
  const columnClickHandler = (col) => {
    if (state.sort === col) {
      if (state.order === "ASC") setState({ order: "DESC" });
      else setState({ order: "ASC" });
    } else setState({ sort: col });
    refreshList();
  };

  //this function refresh the list of orders pipeline
  const refreshList = () => {
    setState({
      appraisers: [],
      totalCount: 0,
      hasMoreAppraisers: true,
      offset: 0,
    });
  };

  //this function render the sort icon at each column of the table
  const renderSortIcon = (col) => {
    if (state.sort === col) {
      if (state.order === "ASC")
        return <i className="red-color fa fa-arrow-down"></i>;
      else return <i className="red-color fa fa-arrow-up"></i>;
    }
  };

  //render function for infinite scroller
  const renderAppraisers = () => {
    return (
      <div>
        <table className="primary-table">
          <thead>
            <tr>
              <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('first_name')}><i className="fa fa-user"></i>&nbsp;First Name {renderSortIcon('first_name')}</th>
              <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('last_name')}><i className="fa fa-user"></i>&nbsp;Last Name {renderSortIcon('last_name')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('license_type')}><i className="fa fa-info"></i>&nbsp;License Type {renderSortIcon('license_type')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('status')}><i className="fa fa-info"></i>&nbsp;Status {renderSortIcon('status')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('state')}><i className="fa fa-map-marker"></i>&nbsp;State {renderSortIcon('state')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('county')}><i className="fa fa-map-marker"></i>&nbsp;County {renderSortIcon('county')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('city')}><i className="fa fa-map-marker"></i>&nbsp;City {renderSortIcon('city')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('email')}><i className="fa fa-envelope-open"></i>&nbsp;Email {renderSortIcon('email')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('datetime_created')}><i className="fa fa-calendar-o"></i>&nbsp;Date Created {renderSortIcon('datetime_created')}</th>
            </tr>
          </thead>
          <tbody>
          {
            state.appraisers.map(
              (appraiser)=>{
                let presidentClub = <div className="display-inline" style={{minWidth:'20px'}}></div>;

                if(appraiser.president_club==='yes')
                  presidentClub = <div className="display-inline" style={{minWidth:'20px'}}><i className="fa fa-trophy orange-color"></i></div>;

                return (
                  <tr key={appraiser.ID} onClick={()=>{setState({selectedAppraiser:appraiser.ID});setState({selectedAppraiserName:appraiser.first_name+' '+appraiser.last_name})}}>
                    <td>{presidentClub}{appraiser.first_name}</td>
                    <td>{appraiser.last_name}</td>
                    <td>{appraiser.license_type}</td>
                    <td>{appraiser.status}</td>
                    <td>{appraiser.state}</td>
                    <td>{appraiser.county}</td>
                    <td>{appraiser.city}</td>
                    <td>{appraiser.email}</td>
                    <td>{formatDate(appraiser.datetime_created)}</td>
                  </tr>
                );
              }
            )
          }
          </tbody>
        </table>
      </div>
    )
  };

  //API call
  const getReport = () => {
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);

    let url = 'report/appraiser/openBy='+state.openBy+'&client='+state.client+'&appraiser_fk='+state.selectedAppraiser+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&appraisalType='+appraisalType;
    let callBack = apiCallBack([{state:'orders', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  };

  //function that fire when the infinite scroll reach bottom
  const loadMore = () => {
    //do not load if there is no more conditions or it's loading data
    if(state.hasMoreAppraisers&&!state.loading){
      //set loading equals to true so it won't fire off before we are done
      setState({loading:true});

      let url = 'appraiser/get/limit='+state.limit+'&offset='+state.offset+'&order='+state.order+'&sort='+state.sort+'&license_type='+state.license_type+'&status='+state.status+'&name='+state.name+'&company_name='+state.company_name+'&email='+state.email+'&state='+state.state+'&county='+state.county+'&city='+state.city;

      //callback handler that update the state when http request return
      let callBack = (response)=>{console.log(response);
        let code = response.data?response.data.code:undefined;

        if(code!=='00'){
          setState({hasMoreAppraisers:false});
        }
        else{
          let newAppraiser = response.data.data;
          let hasMoreAppraisers = true;
          let newOffset = state.offset;
          let totalCount = response.data.count;

          //if http request return empty then no more results, end of list
          if(newAppraiser.length<=0){
            hasMoreAppraisers = false;
          }
          else{
            //increment the offset
            newOffset = state.offset + 1;
          }

          //concat the current array of announcement
          if(state.appraisers.length>0){
            let temp = [...state.appraisers,...newAppraiser];

            setState({appraisers:temp});
          }
          else
            setState({appraisers:newAppraiser});

          setState({hasMoreAppraisers:hasMoreAppraisers, offset:newOffset, totalCount:totalCount});
        }
      };
      callBack = callBack.bind(this);
      let errorCallBack = apiCallBack([{state:'hasMoreAppraisers', value:false}]);

      //collect the promise and wait for it to finish performing it's task
      let promises = httpGet(url, '','Oops, something went wrong and could not load appraisers. Please try again later.', callBack, errorCallBack);
      promises
        .then(
          function(result){
            //set loading equals to false so the function could be fire off once again
            setState({loading:false});
          }
        );
    }
  };

  //render
  let start, end;
  if (state.from !== "") start = moment(state.from);
  if (state.to !== "") end = moment(state.to);

  if (state.selectedAppraiser === -1) {
    let reportFiltersConfig1 = [
      {id:'appraiserStatus',value:state.status, updateFunc:(v)=>setState({status:v}), width:'3'},
      {id:'custom',value:state.name, updateFunc:(v)=>setState({name:v}), width:'3', label:'Name'},
      {id:'custom',value:state.company_name, updateFunc:(v)=>setState({company_name:v}), width:'3', label:'Company Name'},
      {id:'custom',value:state.email, updateFunc:(v)=>setState({email:v}), width:'3', label:'Email'},
      {id:'state',value:state.state, updateFunc:(v)=>setState({state:v}), width:'3'},
      {id:'county',value:state.county, updateFunc:(v)=>setState({county:v}), width:'2', group:false},
      {id:'city',value:state.city, updateFunc:(v)=>setState({city:v}), width:'2'},
      {id:'customSelect',value:state.license_type, updateFunc:(v)=>setState({license_type:v}), width:'3', label:'License Type', clearable:true, className:"", options:[{ label: "Unknown", value: "Unknown" },
      { label: "Licensed", value: "Licensed" },
      { label: "Certified Residential", value: "Certified Residential"},
      { label: "Certified General", value: "Certified General" }]},
      {id:'button',value:'Submit', updateFunc:refreshList, width:'2', className:"align-right", color:"warning"},
    ];

    return (
      <div>
        <InfiniteScroll
          next={loadMore}
          dataLength={state.appraisers.length}
          hasMore={state.hasMoreAppraisers}
          loader={<div key="nill" className="loader"><center>Loading more appraisers...</center></div>}
          initialLoad = {true}
          className="my-well"
          scrollableTarget="contentContainer"
        >
          <div className="page-title">
            <i className="fa fa-reorder"></i>&nbsp;Appraiser Report - Select An Appraiser
          </div>
          <ReportFilter {...props} configs={reportFiltersConfig1}/>
          <br />

          <div className="my-divider"></div>
          <Row>
            <Col sm="6">
              <div>
                {formatNumber(state.totalCount)} appraisers in queue
                <br />
                <br />
              </div>
            </Col>
            <Col sm="6" className="align-right"></Col>
          </Row>
          <div className="large-scroll-container">
            {renderAppraisers()}
          </div>
        </InfiniteScroll>
      </div>
    );
  } else {
    let reportFiltersConfig2 = [
      {id:'from',value:start, updateFunc:(v)=>setState({from:v}), width:'2'},
      {id:'to',value:end, updateFunc:(v)=>setState({to:v}), width:'2'},
      {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'4'},
      {id:'openBy',value:state.openBy, updateFunc:(v)=>setState({openBy:v}), width:'4'},
      {id:'rush',value:state.isRush, updateFunc:(v)=>setState({isRush:v}), width:'3'},
      {id:'complex',value:state.isComplex, updateFunc:(v)=>setState({isComplex:v}), width:'3'},
      {id:'state',value:state.state, updateFunc:(v)=>setState({state:v}), width:'3'},
      {id:'city',value:state.city, updateFunc:(v)=>setState({city:v}), width:'3'},
      {id:'loanPurpose',value:state.loanPurpose, updateFunc:(v)=>setState({loanPurpose:v}), width:'2'},
      {id:'loanType',value:state.loanType, updateFunc:(v)=>setState({loanType:v}), width:'2'},
      {id:'entity',value:state.entity, updateFunc:(v)=>setState({entity:v}), width:'3'},
      {id:'appraisalType',value:state.filterAppraisalTypes, updateFunc:(v)=>setState({filterAppraisalTypes:v}), width:'3'},
      {id:'button',value:'Submit', updateFunc:getReport, width:'2', className:"align-right", color:"warning"},
    ];

    return (
      <div className="my-well">
        <div className="page-title">
          <i className="fa fa-reorder"></i>&nbsp;Appraiser Report - Select Date
          for {state.selectedAppraiserName}
        </div>
        <NavLink
          to="#"
          onClick={(e) => setState({ selectedAppraiser: -1 }, getReport())}
        >
          Go back to appraiser selection
        </NavLink>

        <ReportFilter {...props} configs={reportFiltersConfig2}/>
        <div className="my-divider">&nbsp;</div>
        <Card>
          <CardHeader>
            <Row>
              <Col sm="8">Appraiser Report</Col>
              <Col sm="4">
                <div className="align-right">
                  <ExcelFile
                    element={
                      <Button
                        disabled={state.orders.length <= 0}
                        color="warning"
                      >
                        Download Excel
                      </Button>
                    }
                  >
                    <ExcelSheet data={state.orders} name="XLSX">
                      <ExcelColumn label="Reference #" value="reference_num" />
                      <ExcelColumn label="Loan #" value="loan_num" />
                      <ExcelColumn label="Address" value="property_street" />
                      <ExcelColumn label="City" value="property_city" />
                      <ExcelColumn label="State" value="property_state" />
                      <ExcelColumn label="Report Type" value="appraisal_type" />
                      <ExcelColumn
                        label="Appraiser Fee"
                        value={(col) => "$" + formatNumber(col.appraiser_fees)}
                      />
                      <ExcelColumn
                        label="Lender Fee"
                        value={(col) => "$" + formatNumber(col.lender_fees)}
                      />
                      <ExcelColumn
                        label="Date Uploaded"
                        value={(col) =>
                          formatDateTime(col.datetime_appraisal_uploaded)
                        }
                      />
                    </ExcelSheet>
                  </ExcelFile>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Table className="sortable table table-striped">
              <thead>
                <tr>
                  <th width="13%">Reference #</th>
                  <th width="13%">Loan #</th>
                  <th width="15%">Address</th>
                  <th width="10%">City</th>
                  <th width="10%">State</th>
                  <th width="15%">Report Type</th>
                  <th width="7%">Appraiser Fee</th>
                  <th width="7%">Lender Fee</th>
                  <th width="10%">Date Uploaded</th>
                </tr>
              </thead>
              <tbody>
                {state.orders.map((order, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <NavLink target="_blank" to={"/appraisal/" + order.ID}>
                          {order.reference_num}
                        </NavLink>
                      </td>
                      <td>{order.loan_num}</td>
                      <td>{order.property_street}</td>
                      <td>{order.property_city}</td>
                      <td>{order.property_state}</td>
                      <td>{order.appraisal_type}</td>
                      <td>${order.appraiser_fees}</td>
                      <td>${order.lender_fees}</td>
                      <td
                        data_sorttable_customkey={new Date(
                          order.datetime_appraisal_uploaded
                        ).getTime()}
                      >
                        {formatDateTime(order.datetime_appraisal_uploaded)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </div>
    );
  }
};

export default AppraiserReport;
