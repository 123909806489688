//Author Sooyoung Kim
//Date Aug 30, 2023
import {getReducer, getSetStateFunction, showMessage} from '../../util/util';
import {NavItem, Nav, TabContent, TabPane, NavLink as NavLinkL} from 'reactstrap';
import React, {useReducer, useEffect, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas'
import MyDropzone from '../util/my-dropzone';
import {NavLink} from 'react-router-dom';


//initialize the state
const initialState = {
  tab:'1',
  uploadedSignature:false,
  toUploadFiles:[],
};

//reducer function that perform state update
const reducer = getReducer();


const Signature  = (props)=>{
  const controller = new AbortController();
  const sigPadRef = useRef(null);

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  //function trigger for dropzone react.
  //this function contians two list of files, the accepted and rejected file per the configuration
  const onDrop = (acceptedFiles, rejectedFiles) => {
    let existingFiles = [];
    if(acceptedFiles.length>0){
      existingFiles.push(acceptedFiles[0]);
      let name = existingFiles[0].name;
      let token = name.split('.');
      let extension = '';

      if(token.length>0){
        extension = token[token.length-1];
        extension = extension.toLowerCase();
      }

      if(extension!=='png'){
        showMessage('error','Please upload only PNG image.');
      }
      else{
        const reader = new FileReader();
        reader.onload = () => {
            const fileAsBinaryString = reader.result;
            let base64 = btoa(fileAsBinaryString);
            setState({toUploadFiles: base64, uploadedSignature:true});
            props.update('upload',base64);
        }
        reader.readAsBinaryString(acceptedFiles[0]);
      }
    }
  }

  const tabToggle = (tab) => {
    setState({tab:tab});
  }

  const onDrawSignatureEnd = () => {
    if(sigPadRef.current && !sigPadRef.current.isEmpty()){
      let signature = sigPadRef.current.getTrimmedCanvas().toDataURL('image/png');

      let base64 = signature.replace('data:image/png;base64,', '');
      props.update('draw',base64);
    }else {
      console.error('Signature canvas is empty or invalid.');
    }
  }

  //render
  let uploadSignatureUI;
  let uploadNew;

  if(!state.uploadedSignature){
    uploadSignatureUI = <MyDropzone hidden={props.disabled} onDrop={onDrop} noIcon={true}/>;
  }
  else {
    uploadNew = <NavLink to="#" onClick={(e)=>setState({uploadedSignature:false})}><i className="fa fa-times"/> Upload new signature</NavLink>;

    uploadSignatureUI = <div>
      <img alt="pic" width="100%" height="200" src={"data:image/png;base64, "+state.toUploadFiles}/>
    </div>
  }
  
  return <div>
    <Nav tabs>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.tab === '1'?"active":"inactive" )}
          onClick={() => { tabToggle('1'); }}
          to="#"
        >
          Draw
        </NavLinkL>
      </NavItem>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.tab === '2'?"active":"inactive" )}
          onClick={() => { tabToggle('2'); }}
          to="#"
        >
          Upload
        </NavLinkL>
      </NavItem>
    </Nav>
    <TabContent activeTab={state.tab}>
      <TabPane tabId="1">
        <div style={{height:'250px'}}>
          <div style={{height:'200px'}}>
          <SignatureCanvas penColor='black' onEnd={onDrawSignatureEnd} canvasProps={{style:{background:'#F9F4F2', width:'100%', height:'200px', borderBottom:'1px solid #d2d2d2', borderRadius:'5px'}}} ref={sigPadRef}/>
          </div>
          <NavLink to="#" onClick={(e)=>{sigPadRef.current.clear();props.update('draw', '')}}><i className="fa fa-times"/> Clear Signature</NavLink>
        </div>
      </TabPane>
      <TabPane tabId="2">
        <div style={{height:'250px'}}>
          <div style={{height:'200px'}}>
            {uploadSignatureUI}
          </div>
          {uploadNew}
        </div>
      </TabPane>
    </TabContent>
  </div>;
}


export default Signature;
