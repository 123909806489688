//Author Sooyoung Kim
//Date Sep 12, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, callBackGenerator, generateSID,formatDateTime,stringToHexCode , showMessage, confirmation, sliceFromArray} from '../../util/util';
import {Alert, Button, Input, Card, CardHeader, Label, CardBody, Row, Col, Table, UncontrolledTooltip} from 'reactstrap';
import React, {useReducer, useEffect, useRef} from 'react';
import {usePresencePusher } from '../pusher/pusher-context';
import {NavLink} from 'react-router-dom';
import MySelect from '../util/my-select';
import Linkify from 'react-linkify';
import Avatar from 'react-avatar';
import '../comment/comment.css';

//initialize the state
const initialState = {
  presenceMembers:[],
  typingMembers:[],
  messages:[],
  comments: [],
  allComments:[],
  filter:'comment+email',
  newComment:'',
  targetRecipients:[],
  sms:false,
  copyBilling:false,
  copyPropertyDetails:false,
  actionRequired:false,
  copyAssignment:false,
  respondTo:[],
  extraRecipients:'',
  extraOpsRecipients:[],
  templateName:'',
  commentTemplates:[],
  smsSubscription:'no',
  submitDisabled:false,
  entities:[],
  entitiesToAddPopUp:false,
  selectedComment:{
    recipients:[]
  },
};

//reducer function that perform state update
const reducer = getReducer();


const CommentTab  = (props)=>{
  const controller = new AbortController();
  const presencePusher = usePresencePusher ();
  const newsid = useRef(generateSID());
  let commentID = props.commentID;

  let newInitialState = Object.assign({}, initialState, {
    commentID: commentID?commentID:-1,
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    initialize();
    getAppraisalComments(props.appraisalFk);
    getCommentTemplates(props.appraisalFk);

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if (presencePusher) {
      const presenceChannel = presencePusher.subscribe('presence-order');

      const handleSubscriptionSucceeded = (members) => {
        // Get the initial user list from presence channel
        let presenceMembers = [];
        let typingMembers = [];
        members.each(
          function(member)
          {
            let ID = member.info.ID;
            let orderID = member.info.orderID;
            let myID = localStorage.getItem('userID');
            //let status = member.info.state;
            if(orderID===props.appraisalFk){
              if(ID!==myID){
                presenceMembers.push(member);
                // if(status==='typing')
                //   typingMembers.push(member);
              }
            }
          }
        );
        console.log(presenceMembers)
        setState({presenceMembers:presenceMembers, typingMembers:typingMembers})
      };

      const handleMemberAdded = (member) => {
        let ID = member.info.ID;
        let orderID = member.info.orderID;
        let myID = localStorage.getItem('userID');
        //let status = member.info.state;
        if(orderID===props.appraisalFk){
          if(ID!==myID){
            let presenceMembers = state.presenceMembers.slice();
            presenceMembers.push(member);

            // if(status==='typing'){
            //   let typingMembers = state.typingMembers.slice();
            //   typingMembers.push(member);
            //   setState({typingMembers:typingMembers});
            // }

            setState({presenceMembers:presenceMembers});
          }
        }
      };

      const handleMemberRemoved = (member) => {
        // Remove a user from the user list
        let ID = member.info.ID;
        let orderID = member.info.orderID;
        let myID = localStorage.getItem('userID');

        if(orderID===props.appraisalFk){
          if(ID!==myID){
            let presenceMembers = state.presenceMembers.slice();
            // let typingMembers = state.typingMembers.slice();

            for(let i=0;i<presenceMembers.length;i++){
              if(presenceMembers[i].info.ID===member.info.ID){
                presenceMembers.splice(i, 1);
                setState({presenceMembers:presenceMembers});
                break;
              }
            }

            // for(let i=0;i<typingMembers.length;i++){
            //   if(typingMembers[i].info.ID===member.info.ID){
            //     typingMembers.splice(i, 1);
            //     setState({typingMembers:typingMembers});
            //     break;
            //   }
            // }
          }
        }
      };

      presenceChannel.bind('pusher:subscription_succeeded', handleSubscriptionSucceeded);
      presenceChannel.bind('pusher:member_added', handleMemberAdded);
      presenceChannel.bind('pusher:member_removed', handleMemberRemoved);

      return () => {
        presenceChannel.unbind('pusher:subscription_succeeded', handleSubscriptionSucceeded);
        presenceChannel.unbind('pusher:member_added', handleMemberAdded);
        presenceChannel.unbind('pusher:member_removed', handleMemberRemoved);

        presenceChannel.unsubscribe();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presencePusher, props.appraisalFk]);


  //non API call but simpyl manage state
  const initialize = ()=>{
    let entities = [];
    for(let i=0;i<props.appraisal.entities.length;i++){
      let entity = props.appraisal.entities[i];
      entities.push(entity);
    }

    entities.sort((a, b)=>{return a.entity_label.localeCompare(b.entity_label)});

    setState({entities:entities, smsSubscription:props.appraisal.appraiser_sms_subscription});
  }

  const toggleEntitiesToAdd = (ID) => {
    let existingComments = state.comments.slice();

    for(let i=0;i<existingComments.length;i++){
      if(existingComments[i].ID===ID){
        setState({selectedComment:existingComments[i]});
        break;
      }
    }

    setState({entitiesToAddPopUp:!state.entitiesToAddPopUp});
  }

  const filterOnChange = (v, allComments) => {
    setState({filter:v})
    let comments = [];

    if(v==='all')
      comments = allComments.slice();
    else if(v==='comment'){
      for(let i=0;i<allComments.length;i++){
        if(allComments[i].type==='comment - internal'||allComments[i].type==='comment - external')
          comments.push(allComments[i]);
      }
    }
    else if(v==='comment+email'){
      for(let i=0;i<allComments.length;i++){
        if(allComments[i].type==='comment - internal'||allComments[i].type==='comment - external'||allComments[i].type==='comment - email scanned')
          comments.push(allComments[i]);
      }
    }
    else{
      for(let i=0;i<allComments.length;i++){
        if(allComments[i].type===v)
          comments.push(allComments[i]);
      }
    }

    setState({comments:comments});
  }

  const addNewCommentRecipientsBorrower = () => {
    setState({extraRecipients:props.appraisal.borrower_email});
  }

  const removeRespondComment = (id) => {
    let list = sliceFromArray(state.respondTo,'ID', id);
    setState({respondTo:list});
  }

  const doubleClick = (comment) => {
    let respondTo = state.respondTo.slice();
    let index = -1;
    for(let i=0;i<respondTo.length;i++){
        if(respondTo[i].ID===comment.ID){
          index = i;
          break;
        }
    }

    if(index===-1)
      respondTo.push(comment);

    if(comment.author_fk==="0" && comment.parsed_from_email_sender===''){
      showMessage('error', 'This comment was parsed from email and does not contains sender information and hence you cannot reply within the system.');
    }
    else
      setState({respondTo:respondTo});
  }

  const handleChange = (selectedOption) => {
    setState({ extraOpsRecipients:selectedOption });
  }

  //remove a specific target recipient from the list of recipients that will receive the comment
  const removeFromTargetRecipients = (id) => {
    for(let i =0;i<state.targetRecipients.length;i++){
      if(state.targetRecipients[i].ID===id){
        let newTargetRecipients = state.targetRecipients.slice();
        newTargetRecipients.splice(i,1);

        setState({targetRecipients:newTargetRecipients});
      }
    }
  }

  const insertCreditCardLink = () => {
    if(props.appraisal.client_fk!=='1900')
      setState({newComment:state.newComment+' https://portal.homevms.com/payment/update-credit-card/'+props.appraisalFk})
    else
      showMessage('error','This is Homelight order, payment are being settled through stripe.');
  }

  const insertInspectionCompletedLink = () => {
    if(props.appraisal.status==='Inspection Scheduled')
      setState({newComment:state.newComment+' https://appraiser.homevms.com/appraisal/inspection-completed/'+props.appraisalFk})
    else
      showMessage('error','This order does not need inspection completed link.');
  }

  const commentTemplatesOnChange = (id) => {
    let template;

    for(let i=0;i<state.commentTemplates.length;i++){
      if(state.commentTemplates[i].ID===id){
        template = state.commentTemplates[i];
        break;
      }
    }

    if(template){
      setState({newComment:template.template, templateName:id});
    }
  }

  const buttonHandler = () => {
    if(state.submitDisabled)
      return;
    if(state.newComment==='')
      return;

    setState({submitDisabled:true},postComment());
  }

  const pasteAppraiserInfo = () => {
    let newComment = state.newComment;

    newComment = newComment + ' #Appraiser '+props.appraisal.appraiser + ' phone - '+props.appraisal.appraiser_phone+' email - '+props.appraisal.appraiser_email;
    setState({newComment:newComment});
  }

  const pastePropertyAccess = () => {
    let newComment = state.newComment;

    newComment = newComment + ' #Property Access '+props.appraisal.property_access_name + ' primary phone - '+props.appraisal.property_access_work_phone+' secondary phone - '+props.appraisal.property_access_home_phone+' email - '+props.appraisal.property_access_email;
    setState({newComment:newComment});
  }

  const addAllToTargetRecipients = () => {
    let allRecipients = state.entities.slice();
    let tmp = [];
    for(let i=0;i<allRecipients.length;i++){
      if(allRecipients[i].entity_email==='' && allRecipients[i].entity_label!=='API Integration'){
        showMessage('error','Contact '+allRecipients[i].entity_name+' does not has email, notification wont be able to reach.');
      }
      else{
        tmp.push(allRecipients[i]);
      }
    }
    setState({targetRecipients:tmp});
  }

  const addNewCommentRecipients = (ID, extraRecipientsID, target) => {
    let targetLabel ='';
    if(ID==='All')
      addAllToTargetRecipients();
    else{
      for(let i =0;i<target.length;i++){
        if(target[i].ID===ID)
          return;
      }
      let existingNewTargetRecipients = target.slice();

      let targetEntity = null;
      for(let i=0;i<state.entities.length;i++){
        if(state.entities[i].ID===ID){
          targetEntity = state.entities[i];
          targetLabel = state.entities[i].entity_label;
          break;
        }
      }
      // console.log(targetEntity);
      // console.log(state.entities);
      // console.log(ID);
      // console.log(targetLabel);
      if(targetEntity){
        //check if entity email is empty
        if(targetEntity.entity_email==='' && targetEntity.entity_label!=='API Integration'){
          showMessage('error','Contact '+targetEntity.entity_name+' does not has email, notification wont be able to reach.');
        }
        else{
          existingNewTargetRecipients.push(targetEntity);
          let apiIntegrationID = -1;
          let extraID = [];
          if(targetLabel==='Loan Officer'||targetLabel==='Loan Processor'){
            //look for API Integration and add it
            if(props.appraisal.client_name.includes('Finance of America Mortgage')){
              for(let i=0;i<state.entities.length;i++){
                if(state.entities[i].entity_label==='API Integration'){
                  apiIntegrationID = state.entities[i].ID;
                }else if(state.entities[i].entity_label==='AE' || state.entities[i].entity_label==='CSR'){
                  if(target.filter(e => e.ID === state.entities[i].ID).length <= 0){
                    extraID.push(state.entities[i].ID);
                  }
                }
              }
            }else{
              for(let i=0;i<state.entities.length;i++){
                if(state.entities[i].entity_label==='API Integration'){
                  apiIntegrationID = state.entities[i].ID;
                  break;
                }
              }
            }
          }

          setState({targetRecipients:existingNewTargetRecipients});
          if(apiIntegrationID!==-1){
            addNewCommentRecipients(apiIntegrationID, extraID, existingNewTargetRecipients);
          }
          if(extraRecipientsID.length > 0){
            addMultipleRecipients(existingNewTargetRecipients, extraRecipientsID);
          }
        }
      }
    }
  }

  const addMultipleRecipients = (targetRecipients, extraID) => {
    let existingNewTargetRecipients = targetRecipients.slice();

    for(let i=0;i<extraID.length;i++){
      for(let j=0;j<state.entities.length;j++){
        if(state.entities[j].ID===extraID[i]){
          existingNewTargetRecipients.push(state.entities[j]);
        }
      }
    }

    setState({targetRecipients:existingNewTargetRecipients});
  }

  //API call
  const getAdmin = (keyword) => {
    if(keyword&&keyword!==''){
      let SID = generateSID();
      newsid.current = SID;

      keyword = encodeURIComponent(keyword.replace(/\//g, '%ForwardSlash'));

      let callBack = (response)=>{
        if(SID===newsid.current){
          let code = response.data?response.data.code:undefined;

          if(code!=='00'){
            return [{}];
          }
          else{
            let options = [];

            for(let i=0;i<response.data.data.length;i++){
              let tmp = {};
              tmp.label = response.data.data[i].email;
              tmp.value = response.data.data[i].email;

              options.push(tmp);
            }

            return options;
          }
        }
      };
      callBack = callBack.bind(this);

      let promise = httpGet('admin/search/'+keyword, '', 'Oops, something went wrong and could not search for admin. Please try again later.', callBack);

      return promise;
    }
  }

  //get all the comments
  const getAppraisalComments = (appraisalFk) => {
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;

      if(code==='00'){
        response.data.data.reverse();
        setState({
          comments:response.data.data,
          allComments:response.data.data,
        });
        filterOnChange(state.filter, response.data.data);
      }
    };
    callBack = callBack.bind(this);

    httpGet('comment/get/'+appraisalFk, '', 'Oops, something went wrong and could not load comments for this appraisal. Please try again later.', callBack);
  }

  //get all the comment templates
  const getCommentTemplates = (ID) => {
    let callBack = apiCallBack([{state:'commentTemplates', key:'data'}]);
	  httpGet('template/get/'+ID, '', 'Oops, something went wrong and could not load your comment template.', callBack);
  }

  //post a comment
  const postComment = () => {
    let actionRequired = 'no';
    let copyBilling = 'no';
    let copyPropertyDetails = 'no';
    let copyAssignment = 'no';
    let sms = 'no';

    if(state.copyAssignment)
      copyAssignment = 'yes';

    if(state.sms)
      sms = 'yes';

    if(state.copyBilling)
      copyBilling = 'yes';

    if(state.copyPropertyDetails)
      copyPropertyDetails = 'yes';

    if(state.actionRequired)
      actionRequired = 'yes';

    let targetRecipients =[];

    for(let i=0;i<state.targetRecipients.length;i++)
      targetRecipients.push(state.targetRecipients[i].ID);

    let extraRecipients = state.extraRecipients.split(',');

    for(let i=0;i<state.extraOpsRecipients.length;i++){
      extraRecipients.push(state.extraOpsRecipients[i].value);
    }

    extraRecipients = extraRecipients.join(', ');
    let respondTo = [];

    for(let i=0;i<state.respondTo.length;i++){
      respondTo.push(state.respondTo[i].ID);
    }

    let parameters = [
      {
        field:'comment',
        value:state.newComment
      },
      {
        field:'sms',
        value:sms
      },
      {
        field:'appraisal_fk',
        value:props.appraisalFk
      },
      {
        field:'to_send_entities',
        value:targetRecipients
      },
      {
        field:'copy_billing',
        value:copyBilling
      },
      {
        field:'copy_property_details',
        value:copyPropertyDetails
      },
      {
        field:'actionRequired',
        value:actionRequired
      },
      {
        field:'copy_assignment',
        value:copyAssignment
      },
      {
        field:'extra_recipients',
        value:extraRecipients
      },
      {
        field:'respondTo',
        value:respondTo
      }
    ];

    let callBack = (response)=>{
      setState({submitDisabled:false});
      let code = response.data?response.data.code:undefined;

      if(code==='00'){
        //insert new comment into state
        let commentCreated = response.data.data;
        let existingComments = state.comments.slice();

        existingComments.unshift(commentCreated);

        setState({
          comments:existingComments,
          copyAssignment:false,
          copyBilling:false,
          copyPropertyDetails:false,
          actionRequired:false,
          sms:false,
          newComment:'',
          targetRecipients:[],
          extraOpsRecipients:[],
          extraRecipients:'',
          respondTo:[]
        });
      }
    };
    callBack = callBack.bind(this);
    let errorCallBack = apiCallBack([{state:'submitDisabled', value:false}]);

    httpPost('comment/create', parameters, 'Comment posted successfully.', 'Oops, something went wrong and could not post this comment. Please try again later.', callBack, errorCallBack);
  }

  const ignoreComment = (ID) => {
    let parameters = [
      {
        field:'commentFk',
        value:ID
      },
    ];

    let newcomments = state.comments.slice();

    for(let i=0;i<newcomments.length;i++){
      if(newcomments[i].ID===ID){
        newcomments[i].replied = 'yes';
        break;
      }
    }

    let callBack = apiCallBack([{state:'comments', value:newcomments}]);
    httpPost('comment/ignore', parameters, 'Comment marked as no respond required successfully.', 'Oops, something went wrong and could not mark this comment as no respond required. Please try again later.', callBack);
  }

  const deleteComment = (id) => {
    let parameters = [
      {
        field:'id',
        value:id
      }
    ];
    let list = sliceFromArray(state.comments,'ID', id);
    let callBack = apiCallBack([{state:'comments', value:list}]);
    httpPut('comment/remove', parameters, 'Comment removed successfully.', 'Oops, something went wrong and could not remove the comment. Please try again later.', callBack);
  }

  //render
  let respondToComment = state.respondTo.map(
    (comment)=>{
      return <div key={comment.ID}>
        <div className="my-well" style={{fontSize:'11px', padding:'5px', textAlign:'left', background:'#E6E3E2'}}>
          <i className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeRespondComment(comment.ID)}/> <i className="fa fa-reply"/> <i><b>{comment.author}</b> - {comment.comment}</i>
        </div>
      </div>
    }
  )

  let entities;
  if(state.entities){
    entities = state.entities.map(
      (entity, index)=>{
        if(entity.entity_label==='Broker'||entity.entity_label==='Broker Company')
          return null;
        return (
          <tr key={index} className="cursor-pointer" onClick={(e)=>addNewCommentRecipients(entity.ID, [], state.targetRecipients)}><td><b>{entity.entity_label}</b></td><td>{entity.entity_name} - {entity.entity_email}</td></tr>
        );
      }
    );
  }

  let targetRecipients;
  if(state.targetRecipients){
    targetRecipients = state.targetRecipients.map(
      (recipient)=>{
        return(
          <div style={{display:'inline-block'}} key={recipient.ID}>
            <div className="entity-container cursor-pointer" onClick={()=>{removeFromTargetRecipients(recipient.ID)}}>
              <i className="fa fa-minus link-color"></i>&nbsp;{recipient.entity_name}
            </div>&nbsp;
          </div>
        );
      }
    );
  }

  let idCounter = 0;
  let comments = state.comments.map(
    (comment,outterIndex)=>{
      let smsIcon;

      if(comment.sms_to_appraiser==='yes')
        smsIcon = <i className="fa fa-envelope"/>;
      let recipients = comment.recipients.map(
        (entity,index)=>{
          idCounter++;
          if(entity.associated_name===""){
            return(
              <div key={index} className="display-inline file-entity">
                <a href="#/" id={"commentAvatar"+idCounter}><Avatar style={{overflow:'hidden'}} size={15} textSizeRatio={1.5} name={"?"}/></a>&nbsp;
                <UncontrolledTooltip placement="top" target={"commentAvatar"+idCounter}>
                  {entity.associated_email}
                </UncontrolledTooltip>
              </div>
            );
          }
          else{
            return(
              <div key={index} className="display-inline file-recipient">
                <a href="#/" id={"commentAvatar"+idCounter}><Avatar style={{overflow:'hidden'}} size={15} textSizeRatio={1.5} color={"#"+stringToHexCode(entity.associated_email)} name={entity.associated_name}/></a>&nbsp;
                <UncontrolledTooltip placement="top" target={"commentAvatar"+idCounter}>
                  {entity.associated_name+" - "+entity.associated_email}
                </UncontrolledTooltip>
              </div>
            );
          }
        }
      );

      let outterContainerClass = 'align-right';
      let commentContainerClass = 'nonadmin-comment-container';
      let nameClass2 = '';
      let nameClass = 'nonadmin-name';
      let alignClass = 'align-left';
      let alignClass2 = 'align-right';
      let dateClass = 'margin-top comment-date display-inline';
      let nameAddon = '';

      if(comment.type==='comment - email scanned'){
        nameClass2 = 'flashit';
        nameAddon = 'Email Scanned - ';
      }

      if(comment.type==='system - updates'){
        commentContainerClass = 'system-updates-comment-container';
        outterContainerClass = 'comment-system-updates';
        nameClass = 'align-left system-update-name';
        dateClass = 'comment-date-small display-inline';
      }
      else if(comment.internal==='yes'){
        outterContainerClass = '';
        commentContainerClass = 'admin-comment-container';
        nameClass = 'admin-name';
      }

      let deleteButton;
      if(comment.recipients.length <= 0 && comment.internal==='yes' && comment.type==='comment - internal'){
        deleteButton = <div className="display-inline">
          <i className="fa fa-times red-color" style={{fontSize:'15px'}} onClick={()=>{
            confirmation(
              ()=>{deleteComment(comment.ID)},
              ()=>{},
              'Delete this comment?',
              'Are you sure you want to delete this comment?');
          }}></i>
        </div>;
      }

      let markReadIcon;

      if(comment.internal!=='yes' && comment.replied==='no'){
        markReadIcon = <div className="display-inline">
          <i className="flashit fa fa-envelope-open cursor-pointer" onClick={(e)=>ignoreComment(comment.ID)}/>
        </div>;
      }

      return(
        <Row className={outterContainerClass} key={comment.ID} onClick={(e)=>{if(e.detail>=2)doubleClick(comment)}}>
          <Col sm="12">
            <div className={commentContainerClass+" "+(comment.ID===state.commentID?" highlightit":"")}>
              <Row>
                <Col sm="10" className={alignClass}>
                  <div className={nameClass+" "+nameClass2}>{nameAddon+comment.author}</div>
                </Col>
                <Col sm="2" className={alignClass2}>
                  {deleteButton} {markReadIcon}
                </Col>
              </Row>
              <div className={alignClass}>
                <Linkify>{comment.comment}</Linkify>
              </div>
              <Row>
                <Col sm="7" className={alignClass}>
                  <div className={dateClass}><i className="fa fa-edit link-color cursor-pointer" onClick={()=>toggleEntitiesToAdd(comment.ID)}></i> <i className="fa fa-reply cursor-pointer" onClick={(e)=>doubleClick(comment)}/> {formatDateTime(comment.datetime_created)}&nbsp;&nbsp;{smsIcon}</div>
                </Col>
                <Col sm="5" className={alignClass2}>
                  <div className="display-inline">
                    {recipients}
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      );
    }
  );

  return <div>
    <Card  style={{borderTop:'0px'}}>
      <CardHeader className="header-color">
        <i className="fa fa-home"></i>&nbsp;{props.appraisal.reference_num} - {props.appraisal.property_street} {props.appraisal.property_city}, {props.appraisal.property_state} {props.appraisal.property_zip} - {props.appraisal.borrower_f_name} {props.appraisal.borrower_l_name}
      </CardHeader>
      <CardBody>
        {
          props.messages.map(
            (message, index)=>{
              if(message.link){
                return(<a key={index} href={'/'+message.link+'/'+message.ID} target="_blank" rel="noreferrer"><Alert key={index} color="warning" className="my-alert">
                    <i className="fa fa-information"></i> {message.msg}
                  </Alert></a>);
              }

              return(<Alert key={index} color="warning" className="my-alert">
                  <i className="fa fa-information"></i> {message.msg}
                </Alert>);
            }
          )
        }
        <Row>
          <Col sm="6">
            <br/>
            <div className="align-right">
              <label>Filter</label>&nbsp;&nbsp;
              <div style={{width:'250px'}} className="display-inline">
                <MySelect
                  type="select"
                  value={state.filter}
                  onChange={(v)=>filterOnChange(v, state.allComments)}
                  options={[
                    {label:"All",value:"all"},
                    {label:"Comment Only",value:"comment"},
                    {label:"Comment & Scanned Email",value:"comment+email"},
                    {label:"System updates",value:"system - updates"},
                    {label:"External Comment",value:"comment - external"},
                    {label:"Internal Comment",value:"comment - internal"},
                    {label:"Scanned Email",value:"comment - email scanned"}
                  ]}
                />
              </div>
            </div>
            <br/>
            <div className="ultra-large-scroll-container">
              {comments}
            </div>
            <br/>
            {
              state.presenceMembers.map(
                (member, index)=>{
                  return <div key={index} className="flashit">
                    <b className="red-color">{member.info.name} is typing a comment...</b>
                  </div>
                }
              )
            }
          </Col>
          <Col sm="6">
            <div>
              <br/>

              <div className="align-left">
                <label>Tag Someone</label>
              </div>
              <div className="medium-scroll-container">
                <Table hover className="table">
                  <tbody>
                    <tr className="cursor-pointer" onClick={(e)=>addNewCommentRecipientsBorrower()}><td><b>Borrower</b></td><td>{props.appraisal.borrower_f_name+' '+props.appraisal.borrower_l_name+' - '+props.appraisal.borrower_email}</td></tr>
                    {entities}
                  </tbody>
                </Table>
              </div>

              <Row>
                <Col sm="6" className="align-left">
                  <label>Extra recipients</label><br/>
                  <Input type="text" value={state.extraRecipients} onChange={(e)=>setState({extraRecipients:e.target.value})}/>
                </Col>
                <Col sm="6" className="align-left">
                  <label>Tag admin</label>
                  <MySelect
                    isMulti
                    type="async-select"
                    onChange={(v)=>handleChange(v)}
                    loadOptions={getAdmin}
                  />
                </Col>
              </Row>
              <div className="small-scroll-container">
                {targetRecipients}
              </div>
              <br/>
              <div className="form-group">
                <Row>
                  <Col sm="8" className="align-left">
                    <span><label>Comment:</label></span>
                  </Col>
                  <Col sm="4" className="align-right">
                    &nbsp;<i className="cursor-pointer link-color fa fa-check" onClick={(e)=>insertInspectionCompletedLink()}></i>&nbsp;<i className="cursor-pointer link-color fa fa-credit-card" onClick={(e)=>insertCreditCardLink()}></i>&nbsp;<i className="cursor-pointer link-color fa fa-file" onClick={(e)=>setState({newComment:state.newComment+' https://client.homevms.com/appraisal/file-upload/'+props.appraisalFk})}></i>&nbsp;<i className="fa fa-home cursor-pointer link-color" style={{fontSize:'22px'}} onClick={(e)=>pastePropertyAccess()}></i>&nbsp;<i className="fa fa-user cursor-pointer link-color" style={{fontSize:'22px'}} onClick={(e)=>pasteAppraiserInfo()}></i>
                  </Col>
                </Row>
                {respondToComment}
                <Input type="textarea" className="form-control comment-textarea" value={state.newComment} id="comment" placeholder="Say something...Double click on existing comment to reply" rows="10" onChange={(e)=>{setState({newComment:e.target.value})}}></Input>
              </div>

              <Row>
                <Col sm="6" className="align-left">
                  <label>Template:</label>
                </Col>
                <Col sm="6" className="align-right">
                  <NavLink to="/setting">Manage my template</NavLink>
                </Col>
              </Row>
              <MySelect
                type="select"
                selectIsClearable={true}
                value={state.templateName}
                onChange={(v)=>{commentTemplatesOnChange(v)}}
                options={state.commentTemplates.map((template)=>{
                  return {label:template.name, value:template.ID};
                })}
              />
              <hr className="margin-bottom"/>
              <Row>
                <Col sm="9" className="align-left">
                  <div className="display-inline" style={{paddingRight:'25px'}}>
                    <Label check>
                      <Input type="checkbox" onClick={(e)=>{setState({copyBilling:e.target.checked})}}/>&nbsp;Billing comment
                    </Label>
                  </div>
                  <div className="display-inline" style={{paddingRight:'25px'}}>
                    <Label check>
                      <Input type="checkbox" onClick={(e)=>{setState({copyPropertyDetails:e.target.checked})}}/>&nbsp;Property Details
                    </Label>
                  </div>
                  <div className="display-inline" style={{paddingRight:'30px'}}>
                    <Label check>
                      <Input type="checkbox" onClick={(e)=>{setState({copyAssignment:e.target.checked})}}/>&nbsp;Assignment history
                    </Label>
                  </div>
                  <div className="display-inline" style={{paddingRight:'30px'}}>
                    <Label check>
                      <Input type="checkbox" onClick={(e)=>{setState({actionRequired:e.target.checked})}}/>&nbsp;Action Required
                    </Label>
                  </div>
                  <div className="display-inline" style={{paddingRight:'30px'}}>
                    <Label check>
                      <Input type="checkbox" disabled={state.smsSubscription!=='yes'} onClick={(e)=>{setState({sms:e.target.checked})}}/>&nbsp;SMS
                    </Label>
                  </div>
                </Col>
                <Col sm="3" className="align-right">
                  <Button color="warning" disabled={state.submitDisabled!==false} onClick={buttonHandler} ><i className="fa fa-check"></i>&nbsp;Post</Button>{' '}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  </div>;
}
 

export default CommentTab;
