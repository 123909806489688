//Author Sooyoung Kim
//Date Aug 2, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, formatNumber, generateSID, formatDate, stringToHexCode, replaceRegex} from '../../../util/util';
import {Button, Card, CardHeader, CardBody, Col, Row, Modal, ModalHeader, ModalBody, Nav, NavItem, TabPane, TabContent, NavLink as NavLinkL} from 'reactstrap';
import MyReactTable from '../../util/my-react-table';
import {usePusher} from '../../pusher/pusher-context';
import React, {useReducer, useEffect, useCallback, useRef} from 'react';
import MyChart from './../../util/my-chart';
import ReportFilter from '../report-filter';
import {NavLink} from 'react-router-dom';
import moment from 'moment';
import '../report.css';

//initialize the state
let today = new Date();
let month = today.getMonth()+1;

if(month<10)
  month = '0'+month;

const initialState = {
  queryID:-1,
  activeTab:'1',
  loanPurpose:'',
  accountManagers:[],
  accountManager:'',
  loanType:'',
  isRush:'',
  isComplex:'',
  state:'',
  city:'',
  entity:'',
  selectedAppraisalType:'',
  filterAppraisalTypes:[],
  date:today.getFullYear()+'-'+month+'-'+today.getDate(),

  currentDate:{
    total_business_day:0,
    total:0,
  },
  mtd:{
    total_business_day:0,
    total:0,
  },
  ytd:{
    total_business_day:0,
    total:0,
  },
  months:{
    data:[],
    entity_data:[],
    appraiser_data:[],
  },

  reportDetailsToggle:false,
  reportDetails:'',
  reportDetailsOrders:[],

  clientBranches:[],
  clientBranch:0,
  clients:[],
  client:0,
  openByClient:0,

  emailParsed:'',

  monthsPrior:{
    data:[],
    entity_data:[],
    appraiser_data:[]
  },
  monthsLabel:'',
  monthsPriorLabel:'',
  daysLabel:'',
  daysPriorLabel:'',
  dayByDay:{
    data:[],
    entity_data:[],
    appraiser_data:[]
  },
  dayByDayPrior:{
    data:[],
    entity_data:[],
    appraiser_data:[]
  },

  excludeQuote:'both',
  clientCloseByOpenBy:'client',
  appraiserGraph:[],
  entityGraph:[],
  appraiserMap:{},
  entityMap:{},

  currentDateEntity:{},
  mtdEntity:{},
  ytdEntity:{},
  monthsEntity:{entity_data:[]},
  monthsPriorEntity:{entity_data:[]},
  dayByDayEntity:{entity_data:[]},
  dayByDayPriorEntity:{entity_data:[]},

  currentDateAP:{appraiser_data:[]},
  mtdAP:{appraiser_data:[]},
  ytdAP:{appraiser_data:[]},
  monthsAP:{appraiser_data:[]},
  monthsPriorAP:{appraiser_data:[]},
  dayByDayAP:{appraiser_data:[]},
  dayByDayPriorAP:{appraiser_data:[]},

  showReportLoading:false,
};

//reducer function that perform state update
const reducer = getReducer();


const CommentVolumeReport  = (props)=>{
  const controller = new AbortController();
  const pusher = usePusher();
  const newsid = useRef(generateSID);
  const activeTabId = useRef('1');

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  const toggleReportLoading = () => {
    setState({showReportLoading:!state.showReportLoading});
  }

  const loadReportResponse = useCallback((ID) => {
    let callBack = (response)=>{
      //console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        if(activeTabId.current === '2'){
          //console.log(response.data.data);
          let currentDate = response.data.data.current_date;
          let mtd = response.data.data.mtd;
          let ytd = response.data.data.ytd;
          let months = response.data.data.months_prior;
          let monthsPrior = response.data.data.months_prior_year;
          let dayByDay = response.data.data.day_by_day;
          let dayByDayPrior = response.data.data.day_by_day_prior_month;

          let entityMap = {};

          for(let i=0;i<ytd.entity_data.length;i++){
            entityMap[ytd.entity_data[i].name] = {month:{}, monthPrior:{}, day:[], dayPrior:[]};
          }

          for(let i=0;i<dayByDay.entity_data.length;i++){
            entityMap[dayByDay.entity_data[i].name].day = dayByDay.entity_data[i].data;
          }
          for(let i=0;i<dayByDayPrior.entity_data.length;i++){
            entityMap[dayByDayPrior.entity_data[i].name].dayPrior = dayByDayPrior.entity_data[i].data;
          }

          for(let i=0;i<months.entity_data.length;i++){
            for(let j=0;j<months.entity_data[i].data.length;j++){
              if(entityMap[months.entity_data[i].data[j].name])
                entityMap[months.entity_data[i].data[j].name].month[months.entity_data[i].label]=months.entity_data[i].data[j].count;
            }
          }

          for(let i=0;i<monthsPrior.length;i++){
            for(let j=0;j<monthsPrior[i].entity_data.length;j++)
              entityMap[monthsPrior[i].entity_data[j].name].monthPrior[monthsPrior.entity_data[i].label]=monthsPrior[i].entity_data[j].count;
          }

          setState({
            currentDateEntity:currentDate,
            mtdEntity:mtd,
            ytdEntity:ytd,
            monthsEntity:months,
            monthsPriorEntity:monthsPrior,
            dayByDayEntity:dayByDay,
            dayByDayPriorEntity:dayByDayPrior,
            entityMap:entityMap,
            entityGraph:[],
            queryID:-1,
            showReportLoading:false
          });

        }else if(activeTabId.current === '3'){
          //console.log(response.data.data);
          let currentDate = response.data.data.current_date;
          let mtd = response.data.data.mtd;
          let ytd = response.data.data.ytd;
          let months = response.data.data.months_prior;
          let monthsPrior = response.data.data.months_prior_year;
          let dayByDay = response.data.data.day_by_day;
          let dayByDayPrior = response.data.data.day_by_day_prior_month;

          let appraiserMap = {};

          for(let i=0;i<ytd.appraiser_data.length;i++){
            appraiserMap[ytd.appraiser_data[i].ID] = {month:{}, monthPrior:{}, day:[], dayPrior:[],name:ytd.appraiser_data[i].name};
          }

          for(let i=0;i<dayByDay.appraiser_data.length;i++){
            appraiserMap[dayByDay.appraiser_data[i].ID].day = dayByDay.appraiser_data[i].data;
          }

          for(let i=0;i<dayByDayPrior.appraiser_data.length;i++){

            appraiserMap[dayByDayPrior.appraiser_data[i].ID].dayPrior = dayByDayPrior.appraiser_data[i].data;
          }

          for(let i=0;i<months.appraiser_data.length;i++){
            for(let j=0;j<months.appraiser_data[i].data.length;j++){
              if(appraiserMap[months.appraiser_data[i].data[j].ID])
                appraiserMap[months.appraiser_data[i].data[j].ID].month[months.appraiser_data[i].label]=months.appraiser_data[i].data[j].count;
            }
          }

          for(let i=0;i<monthsPrior.length;i++){
            for(let j=0;j<monthsPrior[i].appraiser_data.length;j++)
              appraiserMap[monthsPrior[i].appraiser_data[j].ID].monthPrior[monthsPrior.appraiser_data[i].label]=monthsPrior[i].appraiser_data[j].count;
          }

          setState({
            currentDateAp:currentDate,
            mtdAp:mtd,
            ytdAp:ytd,
            monthsAp:months,
            monthsPriorAp:monthsPrior,
            dayByDayAp:dayByDay,
            dayByDayPriorAp:dayByDayPrior,
            appraiserMap:appraiserMap,
            queryID:-1,
            showReportLoading:false
          });

        }else{
          //console.log(response.data.data);
          let currentDate = response.data.data.current_date;
          let mtd = response.data.data.mtd;
          let ytd = response.data.data.ytd;
          let months = response.data.data.months_prior;
          let monthsPrior = response.data.data.months_prior_year;
          let monthsLabel = response.data.data.months_prior_label;
          let monthsPriorLabel = response.data.data.months_prior_year_label;
          let dayByDay = response.data.data.day_by_day;
          let dayByDayPrior = response.data.data.day_by_day_prior_month;
          let daysLabel = response.data.data.day_by_day_label;
          let daysPriorLabel = response.data.data.day_by_day_label_prior_year;

          //console.log(entityMap)
          setState({
            currentDate:currentDate,
            mtd:mtd,
            ytd:ytd,
            months:months,
            monthsPrior:monthsPrior,
            monthsLabel:monthsLabel,
            monthsPriorLabel:monthsPriorLabel,
            dayByDay:dayByDay,
            dayByDayPrior:dayByDayPrior,
            daysLabel:daysLabel,
            daysPriorLabel:daysPriorLabel,
            entityGraph:[],
            queryID:-1,
            showReportLoading:false
          });
        }
      }
    };
    callBack = callBack.bind(this);

    httpGet('report/query/retrieve/'+ID, '', '', callBack);
  }, [httpGet, setState]);

  const reportReady = useCallback((response) => {
    response = JSON.parse(response)
    let code = response.code;
    let queryID = response.data;
    let SID = response.SID;
    if(SID===newsid.current){
      if(code==='00'){
        loadReportResponse(queryID)
      }
    }
  }, [loadReportResponse]);

  //run only once when component is loaded
  useEffect(()=>{
    // Subscribe to a channel and bind to events using the pusher instance
    const channel = pusher.subscribe('private-report');
    channel.bind('report-ready', (data) => {
      reportReady(data);
    });

    getReport();

    return ()=> {
      channel.unbind('report-ready');
      pusher.unsubscribe('private-report');

      controller.abort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const activeTabToggle = (tab) => {
    setState({activeTab:tab});
    activeTabId.current = tab;
    if(tab==='2'){
      getReportEntity();
    }else if(tab==='3'){
      getReportAppraiser();
    }
  }

  const addToLoanOfficerGraph = (name) => {
    let entityGraph = state.entityGraph.slice();
    let index = entityGraph.indexOf(name);

    if(index===-1)
      entityGraph.push(name);
    else
      entityGraph.splice(index, 1);

    setState({entityGraph:entityGraph});
  }

  const addToAppraiserGraph = (name) => {
    let appraiserGraph = state.appraiserGraph.slice();
    let index = appraiserGraph.indexOf(name);

    if(index===-1)
      appraiserGraph.push(name);
    else
      appraiserGraph.splice(index, 1);

    setState({appraiserGraph:appraiserGraph});
  }

  const showReportDetails = (details, client, from, to) => {
    setState({reportDetails:details});
    reportDetailsToggle();

    switch(details){
      case 'listOrders':
        getReportDetailsOrderList(client, to, from);
        break;
      case 'listAppraiserOrders':
        getReportDetailsAppraiserOrderList(client, to, from);
        break;
      case 'listEntityOrders':
        getReportDetailsEntityOrderList(client, to, from);
        break;
      case 'listSpreads':
        getReportDetailsList(to, from);
        break;
      default:
        break;
    }
  }

  const reportDetailsToggle = () => {
    if(state.reportDetailsDropDown)
      setState({reportDetailsOrders:[]});
    setState({reportDetailsDropDown:!state.reportDetailsDropDown});
  }

  //API call
  const getReport = () => {
    if(state.activeTab === '2'|| state.activeTab === '3'){
      setState({activeTab:'1'});
      activeTabId.current = '1'
    }
    setState({
      appraiserGraph:[],
      entityGraph:[],
      appraiserMap:{},
      entityMap:{},
      currentDate:{
        total_business_day:0,
        total:0,
      },
      mtd:{
        total_business_day:0,
        total:0,
      },
      ytd:{
        total_business_day:0,
        total:0,
      },
    });

    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);
    let SID = generateSID();
    newsid.current = SID; 

    let url = 'report/commentVolume/SID='+SID+'&emailParsed='+state.emailParsed+'&accountManager='+state.accountManager+'&excludeQuote='+state.excludeQuote+'&client='+state.client+'&openBy='+state.openByClient+'&clientBranch='+state.clientBranch+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&date='+state.date+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&reportClient='+state.clientCloseByOpenBy+'&appraisalType='+appraisalType;

    let callBack = apiCallBack([{state:'queryID', key:'data'}], toggleReportLoading());
	  httpGet(url, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  const getReportAppraiser = () => {
    setState({
      appraiserGraph:[],
      appraiserMap:{},
    });

    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);

    let url = 'report/commentVolume/appraiser/SID='+newsid.current+'&emailParsed='+state.emailParsed+'&accountManager='+state.accountManager+'&excludeQuote='+state.excludeQuote+'&client='+state.client+'&openBy='+state.openByClient+'&clientBranch='+state.clientBranch+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&date='+state.date+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&reportClient='+state.clientCloseByOpenBy+'&appraisalType='+appraisalType;
    
    let callBack = apiCallBack([{state:'queryID', key:'data'}], toggleReportLoading());
	  httpGet(url, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  const getReportEntity = () => {
    setState({
      entityGraph:[],
      entityMap:{}
    });

    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);

    let url = 'report/commentVolume/entity/SID='+newsid.current+'&emailParsed='+state.emailParsed+'&accountManager='+state.accountManager+'&excludeQuote='+state.excludeQuote+'&client='+state.client+'&openBy='+state.openByClient+'&clientBranch='+state.clientBranch+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&date='+state.date+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&reportClient='+state.clientCloseByOpenBy+'&appraisalType='+appraisalType;

    let callBack = apiCallBack([{state:'queryID', key:'data'}], toggleReportLoading());
	  httpGet(url, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  const getReportDetailsOrderList = (client, to , from) => {
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);

    let url;

    if(state.clientCloseByOpenBy==='client')
      url = 'report/commentVolume/details/order/reportClient='+state.clientCloseByOpenBy+'&emailParsed='+state.emailParsed+'&accountManager='+state.accountManager+'&excludeQuote='+state.excludeQuote+'&openBy='+state.openByClient+'&clientBranch='+state.clientBranch+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&client='+client+'&to='+to+'&from='+from+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&appraisalType='+appraisalType;
    else
        url = 'report/commentVolume/details/order/reportClient='+state.clientCloseByOpenBy+'&emailParsed='+state.emailParsed+'&accountManager='+state.accountManager+'&excludeQuote='+state.excludeQuote+'&openBy='+client+'&clientBranch='+state.clientBranch+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&client='+state.client+'&to='+to+'&from='+from+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&appraisalType='+appraisalType;
    let callBack = apiCallBack([{state:'reportDetailsOrders', key:'data'}]);
	  httpGet(url, '', 'Oops, something went wrong and could not load the report details. Please try again later.', callBack);
  }

  const getReportDetailsEntityOrderList = (entity, to , from) => {
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);

    let url = 'report/commentVolume/details/order/accountManager='+state.accountManager+'&emailParsed='+state.emailParsed+'&excludeQuote='+state.excludeQuote+'&openBy='+state.openByClient+'&clientBranch='+state.clientBranch+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&client='+state.client+'&to='+to+'&from='+from+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&appraisalType='+appraisalType+'&commentAuthor='+entity;
    let callBack = apiCallBack([{state:'reportDetailsOrders', key:'data'}]);
	  httpGet(url, '', 'Oops, something went wrong and could not load the report details. Please try again later.', callBack);
  }

  const getReportDetailsAppraiserOrderList = (appraiser, to , from) => {
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);

    let url = 'report/commentVolume/details/order/commentAuthorFk='+appraiser+'&emailParsed='+state.emailParsed+'&accountManager='+state.accountManager+'&excludeQuote='+state.excludeQuote+'&openBy='+state.openByClient+'&clientBranch='+state.clientBranch+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&client='+state.client+'&to='+to+'&from='+from+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&appraisalType='+appraisalType;
    let callBack = apiCallBack([{state:'reportDetailsOrders', key:'data'}]);
	  httpGet(url, '', 'Oops, something went wrong and could not load the report details. Please try again later.', callBack);
  }

  const getReportDetailsList = (to , from) => {
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);

    let url = 'report/commentVolume/details/order/accountManager='+state.accountManager+'&excludeQuote='+state.excludeQuote+'&client='+state.client+'&openBy='+state.openByClient+'&clientBranch='+state.clientBranch+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&to='+to+'&from='+from+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&appraisalType='+appraisalType;
    let callBack = apiCallBack([{state:'reportDetailsOrders', key:'data'}]);
	  httpGet(url, '', 'Oops, something went wrong and could not load the report details. Please try again later.', callBack);
  }

  //render
  let date = null;
  if(state.date!=='')
    date = moment(state.date);

  let chart;
  let chart2;
  let entityChart;
  let entityChart2;
  let appraiserChart;
  let appraiserChart2;

  if(state.appraiserGraph.length>0){
    let appraiserDaysLabel = [];
    let appraiserMonthsLabel = [];

    for(let i=0;i<state.dayByDayPrior.data.length;i++){
      appraiserDaysLabel.push(state.dayByDayPrior.data[i].date);
    }

    for(let i=0;i<state.months.data.length;i++){
      appraiserMonthsLabel.push(state.months.data[i].label);
    }

    let series = [];
    let series2 = [];

    for(let i=0;i<state.appraiserGraph.length;i++){
      let appraiserMonthsData= [];
      let appraiserMonthsDataPrior= [];
      for(let j=0;j<state.months.data.length;j++){
        let monthCount = state.appraiserMap[state.appraiserGraph[i]].month[state.months.data[j].label]?state.appraiserMap[state.appraiserGraph[i]].month[state.months.data[j].label]:0;
        appraiserMonthsData.push(monthCount);
      }


      for(let j=0;j<state.monthsPrior.data.length;j++){
        let monthCount = state.appraiserMap[state.appraiserGraph[i]].monthPrior[state.monthsPrior.data[j].label]?state.appraiserMap[state.appraiserGraph[i]].month[state.monthsPrior.data[j].label]:0;
        appraiserMonthsDataPrior.push(monthCount);
      }

      let tmp = {
        data: appraiserMonthsData,
        name:'Comment Volume '+state.monthsLabel+' - '+state.appraiserMap[state.appraiserGraph[i]].name,
        color:'#'+stringToHexCode(state.appraiserGraph[i])
      };

      let tmp2 = {
        data: appraiserMonthsDataPrior,
        name:'Comment Volume '+state.monthsPriorLabel+' - '+state.appraiserMap[state.appraiserGraph[i]].name,
        color:'#'+stringToHexCode(state.appraiserGraph[i]),
        dashStyle:'LongDashDot'
      };

      if(appraiserMonthsData.length>0&&appraiserMonthsDataPrior.length>0){
        series.push(tmp);
        series.push(tmp2);
      }

      let dayData = state.appraiserMap[state.appraiserGraph[i]].day?state.appraiserMap[state.appraiserGraph[i]].day:[];
      let dayPriorData = state.appraiserMap[state.appraiserGraph[i]].dayPrior?state.appraiserMap[state.appraiserGraph[i]].dayPrior:[];

      dayData = dayData.map(
        (data)=> {return data.count}
      );

      dayPriorData = dayPriorData.map(
        (data)=> {return data.count}
      );

      let tmp3 = {
        data: dayData,
        name:'Comment Volume '+state.monthsLabel+' - '+state.appraiserMap[state.appraiserGraph[i]].name,
        color:'#'+stringToHexCode(state.appraiserGraph[i])
      };

      let tmp4 = {
        data: dayPriorData,
        name:'Comment Volume '+state.monthsPriorLabel+' - '+state.appraiserMap[state.appraiserGraph[i]].name,
        color:'#'+stringToHexCode(state.appraiserGraph[i]),
        dashStyle:'LongDashDot'
      };

      if(dayData.length>0&&dayPriorData.length>0){
        series2.push(tmp3);
        series2.push(tmp4);
      }
    }

    let appraiserPriorMonthsConfig = {
      xAxis: {
        categories: appraiserMonthsLabel
      },
      series:series,
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true
          }
        }
      },
      title:{
        text: 'Comment Volume'
      }
    }

    let appraiserDayByDayConfig = {
      xAxis: {
        categories: appraiserDaysLabel
      },
      series: series2,
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true
          }
        }
      },
      title:{
        text: 'Comment Volume Day By Day'
      }
    }
    console.log(series2);
    if(series.length>0)
      appraiserChart = <MyChart options = {appraiserPriorMonthsConfig} ></MyChart>;
    if(series2.length>0)
      appraiserChart2 = <MyChart options = {appraiserDayByDayConfig} ></MyChart>;
  }

  if(state.entityGraph.length>0){
    let entityDaysLabel = [];
    let entityMonthsLabel = [];

    for(let i=0;i<state.dayByDayPrior.data.length;i++){
      entityDaysLabel.push(state.dayByDayPrior.data[i].date);
    }

    for(let i=0;i<state.months.data.length;i++){
      entityMonthsLabel.push(state.months.data[i].label);
    }

    let series = [];
    let series2 = [];

    for(let i=0;i<state.entityGraph.length;i++){
      let entityMonthsData= [];
      let entityMonthsDataPrior= [];
      for(let j=0;j<state.months.data.length;j++){
        let monthCount = state.entityMap[state.entityGraph[i]].month[state.months.data[j].label]?state.entityMap[state.entityGraph[i]].month[state.months.data[j].label]:0;
        entityMonthsData.push(monthCount);
      }


      for(let j=0;j<state.monthsPrior.data.length;j++){
        let monthCount = state.entityMap[state.entityGraph[i]].monthPrior[state.monthsPrior.data[j].label]?state.entityMap[state.entityGraph[i]].month[state.monthsPrior.data[j].label]:0;
        entityMonthsDataPrior.push(monthCount);
      }

      let tmp = {
        data: entityMonthsData,
        name:'Comment Volume '+state.monthsLabel+' - '+state.entityGraph[i],
        color:'#'+stringToHexCode(state.entityGraph[i])
      };

      let tmp2 = {
        data: entityMonthsDataPrior,
        name:'Comment Volume '+state.monthsPriorLabel+' - '+state.entityGraph[i],
        color:'#'+stringToHexCode(state.entityGraph[i]),
        dashStyle:'LongDashDot'
      };

      if(entityMonthsData.length>0&&entityMonthsDataPrior.length>0){
        series.push(tmp);
        series.push(tmp2);
      }

      let dayData = state.entityMap[state.entityGraph[i]].day?state.entityMap[state.entityGraph[i]].day:[];
      let dayPriorData = state.entityMap[state.entityGraph[i]].dayPrior?state.entityMap[state.entityGraph[i]].dayPrior:[];

      dayData = dayData.map(
        (data)=> {return data.count}
      );

      dayPriorData = dayPriorData.map(
        (data)=> {return data.count}
      );

      let tmp3 = {
        data: dayData,
        name:'Comment Volume '+state.monthsLabel+' - '+state.entityGraph[i],
        color:'#'+stringToHexCode(state.entityGraph[i])
      };

      let tmp4 = {
        data: dayPriorData,
        name:'Comment Volume '+state.monthsPriorLabel+' - '+state.entityGraph[i],
        color:'#'+stringToHexCode(state.entityGraph[i]),
        dashStyle:'LongDashDot'
      };

      if(dayData.length>0&&dayPriorData.length>0){
        series2.push(tmp3);
        series2.push(tmp4);
      }
    }

    let entityPriorMonthsConfig = {
      xAxis: {
        categories: entityMonthsLabel
      },
      series:series,
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true
          }
        }
      },
      title:{
        text: 'Comment Volume'
      }
    }

    let entityDayByDayConfig = {
      xAxis: {
        categories: entityDaysLabel
      },
      series: series2,
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true
          }
        }
      },
      title:{
        text: 'Comment Volume Day By Day'
      }
    }
    console.log(series2);
    if(series.length>0)
      entityChart = <MyChart options = {entityPriorMonthsConfig} ></MyChart>;
    if(series2.length>0)
      entityChart2 = <MyChart options = {entityDayByDayConfig} ></MyChart>;
  }

  if(state.months.data.length>0){
    let monthsLabel = [];
    let monthsData = [];
    let monthsDataPrior = [];

    let daysLabel = [];
    let daysData = [];
    let daysDataPrior = [];

    for(let i=0;i<state.dayByDayPrior.data.length;i++){
      daysLabel.push(state.dayByDayPrior.data[i].date);
      daysDataPrior.push(state.dayByDayPrior.data[i].count);
    }

    for(let i=0;i<state.dayByDay.data.length;i++){
      daysData.push(state.dayByDay.data[i].count);
    }

    for(let i=0;i<state.months.data.length;i++){
      monthsLabel.push(state.months.data[i].label);
      monthsData.push(state.months.data[i].total);
    }

    for(let i=0;i<state.monthsPrior.data.length;i++){
      monthsDataPrior.push(state.monthsPrior.data[i].total);
    }

    let priorMonthsConfig = {
      xAxis: {
        categories: monthsLabel
      },
      series: [
        {
          data: monthsData,
          name:'Comment Volume '+state.monthsLabel
        },
        {
          data: monthsDataPrior,
          name:'Comment Volume '+state.monthsPriorLabel
        }
      ],
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true
          }
        }
      },
      title:{
        text: 'Comment Volume'
      }
    }

    let dayByDayConfig = {
      xAxis: {
        categories: daysLabel
      },
      series: [
        {
          data: daysData,
          name:'Comment Volume '+state.daysLabel
        },
        {
          data: daysDataPrior,
          name:'Comment Volume '+state.daysPriorLabel
        }
      ],
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true
          }
        }
      },
      title:{
        text: 'Comment Volume Day By Day'
      }
    }

    chart = <MyChart options = {priorMonthsConfig} ></MyChart>;
    chart2 = <MyChart options = {dayByDayConfig} ></MyChart>;
  }

  let clientMap = {};
  let entityMap = {};
  let appraiserMap = {};
  let currentDateMap = {};
  let entityCurrentDateMap = {};
  let appraiserCurrentDateMap = {};
  let mtdMap = {};
  let entityMtdMap = {};
  let appraiserMtdMap = {};
  let ytdMap = {};
  let entityYtdMap = {};
  let appraiserYtdMap = {};
  let monthMap = [];
  let entityMonthMap = [];
  let appraiserMonthMap = [];
  if(state.currentDate.data)
    for(let i=0;i<state.currentDate.data.length;i++){
      currentDateMap[state.currentDate.data[i].ID] = state.currentDate.data[i].count;
      clientMap[state.currentDate.data[i].ID] = state.currentDate.data[i].name;
    }

  if(state.currentDateEntity.entity_data){
    let currentDateEntity = state.currentDateEntity.entity_data
    for(let i=0;i<currentDateEntity.length;i++){
      entityCurrentDateMap[currentDateEntity[i].name] = currentDateEntity[i].count;
      entityMap[currentDateEntity[i].name] = currentDateEntity[i].count;
    }
  }

  if(state.currentDateAp && state.currentDateAp.appraiser_data)
    for(let i=0;i<state.currentDateAp.appraiser_data.length;i++){
      appraiserCurrentDateMap[state.currentDateAp.appraiser_data[i].ID] = state.currentDateAp.appraiser_data[i].count;
      appraiserMap[state.currentDateAp.appraiser_data[i].ID] = state.currentDateAp.appraiser_data[i].count;
      appraiserMap[state.currentDateAp.appraiser_data[i].ID] = {userID:state.currentDateAp.appraiser_data[i].userID,count:state.currentDateAp.appraiser_data[i].count, name:state.currentDateAp.appraiser_data[i].name};
    }

  if(state.mtd.data)
    for(let i=0;i<state.mtd.data.length;i++){
      mtdMap[state.mtd.data[i].ID] = state.mtd.data[i].count;
      clientMap[state.mtd.data[i].ID] = state.mtd.data[i].name;
    }

  if(state.mtdEntity.entity_data)
    for(let i=0;i<state.mtdEntity.entity_data.length;i++){
      entityMtdMap[state.mtdEntity.entity_data[i].name] = state.mtdEntity.entity_data[i].count;
      entityMap[state.mtdEntity.entity_data[i].name] = state.mtdEntity.entity_data[i].count;
    }

  if(state.mtdAp && state.mtdAp.appraiser_data)
    for(let i=0;i<state.mtdAp.appraiser_data.length;i++){
      appraiserMtdMap[state.mtdAp.appraiser_data[i].ID] = state.mtdAp.appraiser_data[i].count;
      appraiserMap[state.mtdAp.appraiser_data[i].ID] = {userID:state.mtdAp.appraiser_data[i].userID,count:state.mtdAp.appraiser_data[i].count, name:state.mtdAp.appraiser_data[i].name};
    }


  for(let i=0;i<state.months.data.length;i++){
    let month = {};
    for(let j=0;j<state.months.data[i].data.length;j++){
      month[state.months.data[i].data[j].ID] = state.months.data[i].data[j].count;
      clientMap[state.months.data[i].data[j].ID] = state.months.data[i].data[j].name;

    }
    monthMap.push(month);
  }

  for(let i=0;i<state.monthsEntity.entity_data.length;i++){
    let month2 = {};
    for(let j=0;j<state.monthsEntity.entity_data[i].data.length;j++){
      month2[state.monthsEntity.entity_data[i].data[j].name] = state.monthsEntity.entity_data[i].data[j].count;
      entityMap[state.monthsEntity.entity_data[i].data[j].name] = state.monthsEntity.entity_data[i].count;
    }
    entityMonthMap.push(month2);
  }

  if(state.monthsAp && state.monthsAp.appraiser_data){
    for(let i=0;i<state.monthsAp.appraiser_data.length;i++){
      let month2 = {};
      let monthsAppraiser = state.monthsAp.appraiser_data;
      for(let j=0;j<monthsAppraiser[i].data.length;j++){
        console.log(monthsAppraiser[i]);
        month2[monthsAppraiser[i].data[j].ID] = monthsAppraiser[i].data[j].count;
        appraiserMap[monthsAppraiser[i].data[j].ID] =  {userID:monthsAppraiser[i].userID,count:monthsAppraiser[i].count, name:monthsAppraiser[i].name};
      }
      appraiserMonthMap.push(month2);
    }

  }

  if(state.ytd.data)
    for(let i=0;i<state.ytd.data.length;i++){
      ytdMap[state.ytd.data[i].ID] = state.ytd.data[i].count;
      clientMap[state.ytd.data[i].ID] = state.ytd.data[i].name;
    }

  if(state.ytdEntity.entity_data)
    for(let i=0;i<state.ytdEntity.entity_data.length;i++){
      entityYtdMap[state.ytdEntity.entity_data[i].name] = state.ytdEntity.entity_data[i].count;
      entityMap[state.ytdEntity.entity_data[i].name] = state.ytdEntity.entity_data[i].count;
    }

  if(state.ytdAp && state.ytdAp.appraiser_data)
    for(let i=0;i<state.ytdAp.appraiser_data.length;i++){
      appraiserYtdMap[state.ytdAp.appraiser_data[i].ID] = state.ytdAp.appraiser_data[i].count;
      appraiserMap[state.ytdAp.appraiser_data[i].ID] = {userID:state.ytdAp.appraiser_data[i].userID,count:state.ytdAp.appraiser_data[i].count, name:state.ytdAp.appraiser_data[i].name};
    }

  let content;

  let sortedEntityList = [];
  Object.getOwnPropertyNames(entityMap).map(
    (name,index)=>{
      let tmp = {};
      tmp.name = name;
      tmp.count = entityMap[name];

      sortedEntityList.push(tmp);
      return null;
    }
  );

  sortedEntityList.sort((a, b)=>{
    if ( a.count < b.count ){
      return 1;
    }
    if ( a.count > b.count ){
      return -1;
    }
    return 0;
  });

  let sortedAppraiserList = [];
  Object.getOwnPropertyNames(appraiserMap).map(
    (ID,index)=>{
      let tmp = {};
      tmp.name = appraiserMap[ID].name;
      tmp.count = appraiserMap[ID].count;
      tmp.userID = appraiserMap[ID].userID;
      tmp.ID = ID;

      sortedAppraiserList.push(tmp);
      return null;
    }
  );

  sortedAppraiserList.sort((a, b)=>{
    if ( a.count < b.count ){
      return 1;
    }
    if ( a.count > b.count ){
      return -1;
    }
    return 0;
  });

  let sortedCompaniesList = [];
  Object.getOwnPropertyNames(clientMap).map(
    (ID,index)=>{
      let tmp = {};
      tmp.ID = ID;
      tmp.name = clientMap[ID];

      sortedCompaniesList.push(tmp);
      return null;
    }
  );

  sortedCompaniesList.sort((a, b)=>{
    return a.name.localeCompare(b.name);
  });



  if(state.reportDetailsOrders.length>0){
    let rows = state.reportDetailsOrders.map(
      (order, index)=>{
        return(
          <tr key={index}>
            <td><NavLink target="_blank" to={"/appraisal/"+order.ID}>{order.reference_num}</NavLink></td>
            <td>{order.property_street+' '+order.property_city+', '+order.property_state+' '+order.property_zip}</td>
            <td>{order.company}</td>
            <td>{order.status}</td>
            <td>{order.appraisal_type}</td>
            <td>{order.loan_num}</td>
            <td>{order.loan_type}</td>
            <td>{order.comment}</td>
          </tr>
        )
      }
    );

    content =
    <table className="table table-striped">
      <thead>
        <tr>
          <th>Reference #</th>
          <th>Address</th>
          <th>Lender</th>
          <th>Status</th>
          <th>Report Type</th>
          <th>Loan #</th>
          <th>Loan type</th>
          <th>Comment</th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </table>
  }

  let table1Data = [];
  let table2Data = [];
  let table4Data = [];
  let table1Columns = [];
  let table2Columns = [];
  let table4Columns = [];

  sortedCompaniesList.map(
    (company,index)=>{
      let clientName = company.name;
      let row = {
        clientID:company.ID,
        clientName:clientName,
        currentFrom:state.currentDate.from,
        currentTo:state.currentDate.to,
        current:currentDateMap[company.ID]?currentDateMap[company.ID]:0,
        mtdFrom:state.mtd.from,
        mtdTo:state.mtd.to,
        mtd:mtdMap[company.ID]?mtdMap[company.ID]:0,
        ytdFrom:state.ytd.from,
        ytdTo:state.ytd.to,
        ytd:ytdMap[company.ID]?ytdMap[company.ID]:0
      };
      if(index===0){
        table1Columns.push(
          {
            id: 'clientName',
            Header:'Client',
            minWidth:250,
            accessor: d => d.clientName,
            headerStyle: {
              textAlign:'left'
            }
          }
        );

        table1Columns.push(
          {
            id: 'current',
            Header:state.currentDate.label,
            accessor: d => d.current,
            Cell: props => {
              return <NavLink to="#" onClick={()=>showReportDetails('listOrders',props.row.original.clientID,props.row.original.currentFrom,props.row.original.currentTo)}>{props.row.original.current}</NavLink>
            },
            headerStyle: {
              textAlign:'left'
            }
          }
        );

        table1Columns.push(
          {
            id: 'mtd',
            Header:'MTD',
            accessor: d => d.mtd,
            Cell: props => {
              return <NavLink to="#" onClick={()=>showReportDetails('listOrders',props.row.original.clientID,props.row.original.mtdFrom,props.row.original.mtdTo)}>{props.row.original.mtd}</NavLink>
            },
            headerStyle: {
              textAlign:'left'
            }
          }
        );

        table1Columns.push(
          {
            id: 'ytd',
            Header:'YTD',
            accessor: d => d.ytd,
            Cell: props => {
              return <NavLink to="#" onClick={()=>showReportDetails('listOrders',props.row.original.clientID,props.row.original.ytdFrom,props.row.original.ytdTo)}>{props.row.original.ytd}</NavLink>
            },
            headerStyle: {
              textAlign:'left'
            }
          }
        );
      }


      monthMap.map(
        (month, index2)=>{
          row[state.months.data[index2].label] = month[company.ID]?month[company.ID]:0;
          row[state.months.data[index2].label+'From'] = state.months.data[index2].from;
          row[state.months.data[index2].label+'To'] = state.months.data[index2].to;

          if(index===0){
            table1Columns.push(
              {
                id: state.months.data[index2].label,
                Header:state.months.data[index2].label,
                accessor: d => d[state.months.data[index2].label],
                Cell: props => {
                  return <NavLink to="#" onClick={()=>showReportDetails('listOrders',props.row.original.clientID,props.row.original[state.months.data[index2].label+'From'],props.row.original[state.months.data[index2].label+'To'])}>{props.row.original[state.months.data[index2].label]}</NavLink>
                },
                headerStyle: {
                  textAlign:'left'
                }
              }
            );
          }

          // Return null to satisfy the requirement of the map function
          return null;
        }
      );

      table1Data.push(row);
      return null;
    }
  )

  sortedEntityList.map(
    (entity,index)=>{
      let name = entity.name;
      console.log(name);
      let row = {
        name:name,
        currentFrom:state.currentDate.from,
        currentTo:state.currentDate.to,
        current:entityCurrentDateMap[name]?entityCurrentDateMap[name]:0,
        mtdFrom:state.mtd.from,
        mtdTo:state.mtd.to,
        mtd:entityMtdMap[name]?entityMtdMap[name]:0,
        ytdFrom:state.ytd.from,
        ytdTo:state.ytd.to,
        ytd:entityYtdMap[name]?entityYtdMap[name]:0
      };
      if(index===0){
        table2Columns.push(
          {
            id: 'name',
            Header:'Entity',
            minWidth:250,
            accessor: d => d.name,
            Cell: props => {
              return <NavLink style={{color:state.entityGraph.indexOf(props.row.original.name)!==-1?'#FF5733':''}} to="#" onClick={(e)=>addToLoanOfficerGraph(props.row.original.name)}>{props.row.original.name}</NavLink>
            },
            headerStyle: {
              textAlign:'left'
            }
          }
        );

        table2Columns.push(
          {
            id: 'current',
            Header:state.currentDate.label,
            accessor: d => d.current,
            Cell: props => {
              return <NavLink to="#" onClick={()=>showReportDetails('listEntityOrders',props.row.original.name,props.row.original.currentFrom,props.row.original.currentTo)}>{props.row.original.current}</NavLink>
            },
            headerStyle: {
              textAlign:'left'
            }
          }
        );

        table2Columns.push(
          {
            id: 'mtd',
            Header:'MTD',
            accessor: d => d.mtd,
            Cell: props => {
              return <NavLink to="#" onClick={()=>showReportDetails('listEntityOrders',props.row.original.name,props.row.original.mtdFrom,props.row.original.mtdTo)}>{props.row.original.mtd}</NavLink>
            },
            headerStyle: {
              textAlign:'left'
            }
          }
        );

        table2Columns.push(
          {
            id: 'ytd',
            Header:'YTD',
            accessor: d => d.ytd,
            Cell: props => {
              return <NavLink to="#" onClick={()=>showReportDetails('listEntityOrders',props.row.original.name,props.row.original.ytdFrom,props.row.original.ytdTo)}>{props.row.original.ytd}</NavLink>
            },
            headerStyle: {
              textAlign:'left'
            }
          }
        );
      }


      entityMonthMap.map(
        (month, index2)=>{
          row[state.months.data[index2].label] = month[name]?month[name]:0;
          row[state.months.data[index2].label+'From'] = state.months.data[index2].from;
          row[state.months.data[index2].label+'To'] = state.months.data[index2].to;

          if(index===0){
            table2Columns.push(
              {
                id: state.months.data[index2].label,
                Header:state.months.data[index2].label,
                accessor: d => d[state.months.data[index2].label],
                Cell: props => {
                  return <NavLink to="#" onClick={()=>showReportDetails('listEntityOrders',props.row.original.name,props.row.original[state.months.data[index2].label+'From'],props.row.original[state.months.data[index2].label+'To'])}>{props.row.original[state.months.data[index2].label]}</NavLink>
                },
                headerStyle: {
                  textAlign:'left'
                }
              }
            );
          }
          // Return null to satisfy the requirement of the map function
          return null;
        }
      );

      table2Data.push(row);
      return null;
    }
  )

  sortedAppraiserList.map(
    (appraiser,index)=>{
      let name = appraiser.name;
      let ID = appraiser.ID;
      let userID = appraiser.userID;
      let row = {
        name:name,
        ID:ID,
        userID:userID,
        currentFrom:state.currentDate.from,
        currentTo:state.currentDate.to,
        current:appraiserCurrentDateMap[ID]?appraiserCurrentDateMap[ID]:0,
        mtdFrom:state.mtd.from,
        mtdTo:state.mtd.to,
        mtd:appraiserMtdMap[ID]?appraiserMtdMap[ID]:0,
        ytdFrom:state.ytd.from,
        ytdTo:state.ytd.to,
        ytd:appraiserYtdMap[ID]?appraiserYtdMap[ID]:0
      };
      if(index===0){
        table4Columns.push(
          {
            id: 'name',
            Header:'Appraiser',
            minWidth:250,
            accessor: d => d.name,
            Cell: props => {
              return <NavLink style={{color:state.appraiserGraph.indexOf(props.row.original.ID)!==-1?'#FF5733':''}} to="#" onClick={(e)=>addToAppraiserGraph(props.row.original.ID)}>{props.row.original.name}</NavLink>
            },
            headerStyle: {
              textAlign:'left'
            }
          }
        );

        table4Columns.push(
          {
            id: 'current',
            Header:state.currentDate.label,
            accessor: d => d.current,
            Cell: props => {
              return <NavLink to="#" onClick={()=>showReportDetails('listAppraiserOrders',props.row.original.userID,props.row.original.currentFrom,props.row.original.currentTo)}>{props.row.original.current}</NavLink>
            },
            headerStyle: {
              textAlign:'left'
            }
          }
        );

        table4Columns.push(
          {
            id: 'mtd',
            Header:'MTD',
            accessor: d => d.mtd,
            Cell: props => {
              return <NavLink to="#" onClick={()=>showReportDetails('listAppraiserOrders',props.row.original.userID,props.row.original.mtdFrom,props.row.original.mtdTo)}>{props.row.original.mtd}</NavLink>
            },
            headerStyle: {
              textAlign:'left'
            }
          }
        );

        table4Columns.push(
          {
            id: 'ytd',
            Header:'YTD',
            accessor: d => d.ytd,
            Cell: props => {
              return <NavLink to="#" onClick={()=>showReportDetails('listAppraiserOrders',props.row.original.userID,props.row.original.ytdFrom,props.row.original.ytdTo)}>{props.row.original.ytd}</NavLink>
            },
            headerStyle: {
              textAlign:'left'
            }
          }
        );
      }


      appraiserMonthMap.map(
        (month, index2)=>{
          row[state.months.data[index2].label] = month[ID]?month[ID]:0;
          row[state.months.data[index2].label+'From'] = state.months.data[index2].from;
          row[state.months.data[index2].label+'To'] = state.months.data[index2].to;

          if(index===0){
            table4Columns.push(
              {
                id: state.months.data[index2].label,
                Header:state.months.data[index2].label,
                accessor: d => d[state.months.data[index2].label],
                Cell: props => {
                  return <NavLink to="#" onClick={()=>showReportDetails('listAppraiserOrders',props.row.original.userID,props.row.original[state.months.data[index2].label+'From'],props.row.original[state.months.data[index2].label+'To'])}>{props.row.original[state.months.data[index2].label]}</NavLink>
                },
                headerStyle: {
                  textAlign:'left'
                }
              }
            );
          }

          // Return null to satisfy the requirement of the map function
          return null;
        }
      );

      table4Data.push(row);
      return null;
    }
  )
  
  let reportFiltersConfig = [
    {id:'from',value:date, updateFunc:(v)=>setState({date:v}), width:'2'},
    {id:'rush',value:state.isRush, updateFunc:(v)=>setState({isRush:v}), width:'2'},
    {id:'complex',value:state.isComplex, updateFunc:(v)=>setState({isComplex:v}), width:'2'},
    {id:'state',value:state.state, updateFunc:(v)=>setState({state:v}), width:'3'},
    {id:'city',value:state.city, updateFunc:(v)=>setState({city:v}), width:'3'},
    {id:'accountManager',value:state.accountManager, updateFunc:(v)=>setState({accountManager:v}), width:'2'},
    {id:'loanPurpose',value:state.loanPurpose, updateFunc:(v)=>setState({loanPurpose:v}), width:'2'},
    {id:'loanType',value:state.loanType, updateFunc:(v)=>setState({loanType:v}), width:'2'},
    {id:'entity',value:state.entity, updateFunc:(v)=>setState({entity:v}), width:'3'},
    {id:'customSelect',value:state.emailParsed, updateFunc:(v)=>setState({emailParsed:v}), width:'3', label:'Email Parsed Comment', clearable:false, className:"", options:[
      {label:"None",value:""},
      {label:"Exclude Email Parsed",value:"no"},
      {label:"Show Only Email Parsed",value:"yes"}
    ]},
    {id:'customSelect',value:state.excludeQuote, updateFunc:(v)=>setState({excludeQuote:v}), width:'2', label:'Exclude', clearable:false, className:"", options:[
      {label:"None",value:"no"},
      {label:"Hold",value:"hold"},
      {label:"Cancel",value:"cancel"},
      {label:"Hold + Cancel",value:"both"}
    ]},
    {id:'appraisalType',value:state.filterAppraisalTypes, updateFunc:(v)=>setState({filterAppraisalTypes:v}), width:'4'},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'3'},
    {id:'openBy',value:state.openByClient, updateFunc:(v)=>setState({openByClient:v}), width:'3'},
    {id:'clientBranch',value:state.clientBranch, updateFunc:(v)=>setState({clientBranch:v}), width:'3'},
    {id:'customSelect',value:state.clientCloseByOpenBy, updateFunc:(v)=>setState({clientCloseByOpenBy:v}), width:'2', label:'Client', clearable:false, className:"", options:[
      {label:"Close By",value:"client"},{label:"Open By",value:"openby"}
    ]},
    {id:'button',value:'Submit', updateFunc:getReport, width:'7', className:"align-right", color:"warning"},
  ];

  return <div>
    <Modal className="my-modal" isOpen={state.showReportLoading} toggle={toggleReportLoading} >
      <ModalHeader hidden={true} toggle={toggleReportLoading}></ModalHeader>
      <ModalBody>
        <center>
          <i style={{fontSize:'55px'}} className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
          <br/><br/>
          Report Loading...<br/>
          Please wait, your report will be loaded once it is ready.
        </center>
        <br/><br/>
        <center>
          <Button color="info" onClick={toggleReportLoading}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Comment Volume Report
      </CardHeader>
      <CardBody>
        <ReportFilter {...props} configs={reportFiltersConfig}/>
      </CardBody>
    </Card>
    <br/>
    <br/>
    <br/>
    <Row style={{gridAutoRows:'1fr'}}>
      <Col sm="4" style={{display:'flex'}}>
        <Card style={{alignItems:'stretch', width:'100%'}}>
          <CardHeader className="header-color">
            <i className="fa fa-reorder"></i>&nbsp;{state.currentDate.label}
          </CardHeader>
          <CardBody>
            <label>Duration</label><br/>
            {formatDate(state.currentDate.from)+' to '+formatDate(state.currentDate.to) }<b>{' ('+state.currentDate.total_business_day+' business days)'}</b>
            <br/>

            <label>Total Comments</label><br/>
            <NavLink to="#" onClick={()=>showReportDetails('listSpreads','',state.currentDate.from,state.currentDate.to)}>{state.currentDate.total}</NavLink>
            <br/>
          </CardBody>
        </Card>
      </Col>
      <Col sm="4" style={{display:'flex'}}>
        <Card style={{alignItems:'stretch', width:'100%'}}>
          <CardHeader className="header-color">
            <i className="fa fa-reorder"></i>&nbsp;{state.mtd.label}
          </CardHeader>
          <CardBody>
            <label>Duration</label><br/>
            {formatDate(state.mtd.from)+' to '+formatDate(state.mtd.to) }<b>{' ('+state.mtd.total_business_day+' business days)'}</b>
            <br/>

            <Row>
              <Col sm="6">
                <label>Total Comments</label><br/>
                <NavLink to="#" onClick={()=>showReportDetails('listSpreads','',state.mtd.from,state.mtd.to)}>{state.mtd.total}</NavLink>
              </Col>
              <Col sm="6">
                <label>Avg Comments Per Day </label><br/>
                {formatNumber(state.mtd.total/state.mtd.total_business_day)}
              </Col>
            </Row>

          </CardBody>
        </Card>
      </Col>
      <Col sm="4" style={{display:'flex'}}>
        <Card style={{alignItems:'stretch', width:'100%'}}>
          <CardHeader className="header-color">
            <i className="fa fa-reorder"></i>&nbsp;{state.ytd.label}
          </CardHeader>
          <CardBody>
            <label>Duration</label><br/>
            {formatDate(state.ytd.from)+' to '+formatDate(state.ytd.to) }<b>{' ('+state.ytd.total_business_day+' business days)'}</b>
            <br/>

            <Row>
              <Col sm="6">
                <label>Total Comments</label><br/>
                <NavLink to="#" onClick={()=>showReportDetails('listSpreads','',state.ytd.from,state.ytd.to)}>{state.ytd.total}</NavLink>
              </Col>
              <Col sm="6">
                <label>Avg Comments Per Day </label><br/>
                {formatNumber(state.ytd.total/state.ytd.total_business_day)}
              </Col>
            </Row>


          </CardBody>
        </Card>
      </Col>
    </Row>

    <br/>
    <Row>
      <Col sm="12">
        {chart}
        <br/>
        {chart2}
      </Col>
    </Row>
    <br/><div className="my-divider">&nbsp;</div><br/><br/>
    <Nav tabs>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.activeTab === '1'?"active":"inactive" )}
          onClick={() => { activeTabToggle('1'); }}
          to="#"
        >
          Client Level Overview
        </NavLinkL>
      </NavItem>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.activeTab === '2'?"active":"inactive" )}
          onClick={() => { activeTabToggle('2'); }}
          to="#"
        >
          Entity Level Overview
        </NavLinkL>
      </NavItem>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.activeTab === '3'?"active":"inactive" )}
          onClick={() => { activeTabToggle('3'); }}
          to="#"
        >
          Appraiser Level Overview
        </NavLinkL>
      </NavItem>
    </Nav>
    <TabContent activeTab={state.activeTab}>
      <TabPane tabId="1">
        <br/>
        <MyReactTable columns={table1Columns} data={table1Data} className="table table-striped"/>
        <Modal className="my-modal-wide" isOpen={state.reportDetailsDropDown} toggle={reportDetailsToggle} >
          <ModalHeader hidden={true} toggle={reportDetailsToggle}></ModalHeader>
          <ModalBody>
            <center>
              <h5><i className="fa fa-user"></i> Report Details</h5>
            </center>
            <br/>
            <div className="large-scroll-container">
              {content}
            </div>
            <br/>
            <center>
              <Button color="info" onClick={reportDetailsToggle}>Close</Button>
            </center>
          </ModalBody>
        </Modal>
      </TabPane>
      <TabPane tabId="2">
        <Row>
          <Col sm="12">
            {entityChart}
            <br/>
            {entityChart2}
          </Col>
        </Row>
        <MyReactTable columns={table2Columns} data={table2Data} className="table table-striped"/>
      </TabPane>
      <TabPane tabId="3">
        <Row>
          <Col sm="12">
            {appraiserChart}
            <br/>
            {appraiserChart2}
          </Col>
        </Row>
        <MyReactTable columns={table4Columns} data={table4Data} className="table table-striped"/>
      </TabPane>
    </TabContent>
  </div>;
}


export default CommentVolumeReport;
