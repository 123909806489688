//Author June Leow
//Date 2022-05-19
import {formatNumber, getReducer, getSetStateFunction, showMessage, postAPICallGenerator} from '../../util/util';
import {Button, Modal, ModalHeader, ModalBody, Label, Input, Table, FormGroup} from 'reactstrap';
import MyDropzone from '../util/my-dropzone';
import MySelect from '../util/my-select';
import React, {useReducer, useEffect} from 'react';


//initialize the state
const initialState = {
  filesUploaded:[],
  callBack:false,
  toUploadFiles:[],
  resizeFilePopUp:false,
  bigFiles:[],
  errorMessage:'',
  uploadDisabled:false,
  toggle:false,
  sendFiles:[],
  targetRecipients:[],
  comment:'',
  sendFilePopUp:false,
};

//reducer function that perform state update
const reducer = getReducer();


const FileUpload  = (props)=>{
  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);
  
  const httpPost = postAPICallGenerator(props);

  //run only once when component is loaded
  useEffect(()=>{

  },[]);

  useEffect(()=>{
    //console.log(state.callBack);
    if(state.callBack){

      props.uploadCompleteCallBack(state.filesUploaded);
      setState({callBack:false});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state.callBack])

  useEffect(()=>{
    if(state.uploadDisabled){
        uploadAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state.uploadDisabled]);

  useEffect(()=>{
    props.onFileAdded(state.toUploadFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state.toUploadFiles]);

  //non API call but simpyl manage state
  const toggleResizeFile = ()=>{
    let newState = {resizeFilePopUp: !state.resizeFilePopUp};
    if(state.resizeFilePopUp)
      newState = Object.assign({}, newState, {bigFiles:[]});
    setState(newState);
  }


  const toggle = ()=>{
    setState({toggle:!state.toggle});
  }

  const toggleSendFile = ()=>{
    setState({sendFilePopUp:!state.sendFilePopUp});
  }

  const removeFromTargetRecipients = (id)=>{
    for(let i =0;i<state.targetRecipients.length;i++){
      if(state.targetRecipients[i].ID===id){
        let newTargetRecipients = state.targetRecipients.slice();
        newTargetRecipients.splice(i,1);

        setState({targetRecipients:newTargetRecipients});
      }
    }
  }

  const addAllToTargetRecipients = ()=>{
    let allRecipients = props.entities?props.entities.slice():[];
    setState({targetRecipients:allRecipients});
  }

  const addNewFileRecipients = (ID)=>{
    if(ID==='All')
      addAllToTargetRecipients();
    else{
      for(let i =0;i<state.targetRecipients.length;i++){
        if(state.targetRecipients[i].ID===ID)
          return;
      }
      let existingNewTargetRecipients = state.targetRecipients.slice();

      let targetEntity = null;
      for(let i=0;i<props.entities.length;i++){
        if(props.entities[i].ID===ID){
          targetEntity =props.entities[i];
          break;
        }
      }

      if(targetEntity){
        existingNewTargetRecipients.push(targetEntity);
        setState({targetRecipients:existingNewTargetRecipients});
      }
    }
  }

  const addSendFile = (ID)=>{
    let sendFiles = state.sendFiles.slice();

    let index = sendFiles.indexOf(ID);

    if(index===-1)
      sendFiles.push(ID);
    else
      sendFiles.splice(index,1);

    setState({sendFiles:sendFiles});
  }

  const uploadButtonHandler = ()=>{
    if(state.uploadDisabled)
      return;
    setState({uploadDisabled:true});
  }
  //function trigger for dropzone react.
  //this function contians two list of files, the accepted and rejected file per the configuration
  const onDrop = (acceptedFiles, rejectedFiles)=>{
    let existingFiles = state.toUploadFiles.slice();
    let bigFiles = state.bigFiles;

    let newState = {};
    for(let i=0;i<acceptedFiles.length;i++){
      let file = acceptedFiles[i];
      file.status = 'Pending';
      file.fileType = '';

      let isBigFile = false;
      if(file.size > 40000000){
        isBigFile = true;
        bigFiles.push(file.name);
      }

      let duplicate = false;
      for(let j=0;j<state.toUploadFiles.length;j++){
        if(state.toUploadFiles[j].name===acceptedFiles[i].name){
          duplicate = true;
          newState = Object.assign({}, newState,{errorMessage:'Duplicate file name "'+acceptedFiles[i].name+'"'});
        }
      }
      if(!duplicate && !isBigFile)
        existingFiles.push(file);
    }
    if(bigFiles.length){
      newState = Object.assign({}, newState,{resizeFilePopUp:true, bigFiles:bigFiles});
    }
    newState = Object.assign({}, newState,{toUploadFiles:existingFiles});
    setState(newState);
  }

  //remove a specific file from the toUpload list.
  const removeToUploadFile = (name)=>{
    let toRemoveIndex = -1;
    for(let i=0;i<state.toUploadFiles.length;i++){
      if(state.toUploadFiles[i].name===name){
        toRemoveIndex = i;
        break;
      }
    }

    if(toRemoveIndex!==-1){
      let newFiles = state.toUploadFiles.slice();
      newFiles.splice(toRemoveIndex,1);

      setState({toUploadFiles:newFiles});
    }
  }

  //format the size to use appropiate unit KB, MB and B and round up to only 1 decimal
  const formatFileSize = (size)=>{
    let intSize = parseInt(size,10);

    if(intSize>=1000000.00)
      return formatNumber(Math.round((intSize*10/1000000))/10)+' MB';
    else if(intSize>=1000)
      return formatNumber(Math.round((intSize*10/1000))/10)+' KB';
    else
      return formatNumber(intSize)+' B';

  }

  //constructing a new file object
  const deepCopyFileObject = (file)=>{
    let newFile = new File([file],file.name);
    newFile.preview = file.preview;
    newFile.fileType = file.fileType;
    newFile.status = file.status;

    return newFile
  }


  //on change function when user change the file type drop down in upload file pop up
  const onFileTypeChange = (name, fileType)=>{
    for(let i=0;i<state.toUploadFiles.length;i++){
      if(state.toUploadFiles[i].name===name){
        let newToUploadFiles = [];

        for(let j=0;j<state.toUploadFiles.length;j++){
          let newFile = deepCopyFileObject(state.toUploadFiles[j]);

          if(j===i)
            newFile.fileType = fileType;

          newToUploadFiles.push(newFile);
        }

        setState({toUploadFiles:newToUploadFiles});
      }
    }
  }

  //API call
  const sendFiles = ()=>{
    let targetRecipients =[];

    for(let i=0;i<state.targetRecipients.length;i++)
      targetRecipients.push(state.targetRecipients[i].ID);

    if(targetRecipients.length>0){
      let parameters = [
        {
          field:'ID',
          value:props.appraisalFk
        },
        {
          field:'files',
          value:state.sendFiles
        },
        {
          field:'entities',
          value:targetRecipients
        },
        {
          field:'comment',
          value:state.comment
        }
      ];
      let callBack = (response)=>{
        let code = response.data.code;

        if(code==='00'){
          toggleSendFile();
          setState({sendFiles:[],targetRecipients:[]});
        }
      }
      
      httpPost('file/send', parameters, 'File(s) sent successfully.','Oops, something went wrong and could not send files. Please try again later.', callBack);
    }
    else{
      showMessage('error','Please select recipient that you want to send the file.');
    }
  }

  const uploadAll = ()=>{
    let failedMessage = props.preCheckFunc(state.toUploadFiles);

    if(failedMessage===''){
      let fileCompleted = 0;
      for(let i=0;i<state.toUploadFiles.length;i++){
        //skip file that has done upload
        if(state.toUploadFiles[i].status==='Done')
          continue;
        const reader = new FileReader();
        // eslint-disable-next-line no-loop-func
        reader.onload = () => {
            const fileAsBinaryString = reader.result;
            let base64 = btoa(fileAsBinaryString);

            let promise = props.upload({name:state.toUploadFiles[i].name, fileType:state.toUploadFiles[i].fileType, file:base64});
            //console.log(promise);
            promise
            .then(
              (response)=>{
                //console.log(response);
                let toUploadFiles = state.toUploadFiles.slice();
                toUploadFiles[i].status='Done';

                setState({toUploadFiles:toUploadFiles});
                setState({filesUploaded:response.data.data}, "CONCAT_SET_STATES");
              }
            )
            .finally(
              ()=>{
                fileCompleted++;

                if(fileCompleted>=state.toUploadFiles.length){
                  setState({uploadDisabled:false, errorMessage:''})
                  if(props.uploadCompleteCallBack){
                    setState({callBack:true});
                  }
                }
              }
            );
        };

        reader.onabort = () => //console.log('file reading was aborted');

        reader.onerror = () => {
          showMessage('error','File upload failed, please try again later.');
        };

        reader.readAsBinaryString(state.toUploadFiles[i]);
      }
    }
    else{
      setState({errorMessage:failedMessage, uploadDisabled:false});
    }
  }

  //render related variable

  let bigFileList = state.bigFiles.join(', ');
  let toUploadFiles;

  if(state.toUploadFiles.length>0){
    toUploadFiles = state.toUploadFiles.map(
      (file,index)=>{
        return(
          <tr key={index}>
            <td style={{borderTop:'none'}}>{file.name}</td>
            <td style={{borderTop:'none'}}>
              <MySelect
                type="select"
                modal={true}
                value={file.fileType}
                selectIsClearable={true}
                onChange={(v)=>{onFileTypeChange(file.name, v)}}
                options={props.fileTypes.map((fileType)=>{
                  return {label:fileType.name, value:fileType.name};
                })}
              />
            </td>
            <td style={{borderTop:'none'}}>{formatFileSize(file.size)}</td>
            <td style={{borderTop:'none'}}><center>{file.status}</center></td>
            <td style={{borderTop:'none'}}><center><i className="fa fa-times red-color cursor-pointer" onClick={()=>removeToUploadFile(file.name)}></i></center></td>
          </tr>
        );
      }
    );
  }

  let entities;
  if(props.entities){
    entities = props.entities.map(
      (entity, index)=>{
        if(entity.entity_label==='Broker'||entity.entity_label==='Broker Company')
          return null;
        return (
          <tr key={index} className="cursor-pointer" onClick={(e)=>addNewFileRecipients(entity.ID)}><td><b>{entity.entity_label}</b></td><td>{entity.entity_name} - {entity.entity_email}</td></tr>
        );
      }
    );
  }

  let targetRecipients;
    if(state.targetRecipients){
      targetRecipients = state.targetRecipients.map(
        (recipient)=>{
          return(
            <div style={{display:'inline-block'}} key={recipient.ID}>
              <div className="entity-container cursor-pointer" onClick={()=>{removeFromTargetRecipients(recipient.ID)}}>
                <i className="fa fa-minus link-color"></i>&nbsp;{recipient.entity_name}
              </div>&nbsp;
            </div>
          );
        }
      );
    }
  
  let appraisalFiles;

  if(props.appraisalFiles){
    appraisalFiles = props.appraisalFiles.map(
      (file, index)=>{
        return(
          <div key={index}>
            <div className="file-container">
              &nbsp;<div className="padding display-inline">
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" checked={state.sendFiles.indexOf(file.ID)!==-1} onChange={(e)=>{addSendFile(file.ID)}}/>&nbsp;
                  </Label>
                </FormGroup>
              </div>
              {file.name}
            </div>
          </div>
        );
      }
    );
  }


  //render
  console.log(props);
  if(props.modalMode){
    return <div>
      <Modal className="my-modal" isOpen={state.sendFilePopUp} toggle={toggleSendFile} >
        <ModalHeader hidden={true} toggle={toggleSendFile}></ModalHeader>
        <ModalBody>
          <center>
            <h5><i className="fa fa-comments"></i> Send Files</h5>
          </center>
          <br/>
          <font color="red"><b>* Please do not use this as a method of shipping appraisal report to the client, use the shipping portal instead.</b></font>
          <div className="small-scroll-container-ex-large">
            {appraisalFiles}
          </div>
          <div>
            <label>Tag Someone</label>
            <div className="small-scroll-container-ex-large">
              <Table hover className="table">
                <tbody>
                  {entities}
                </tbody>
              </Table>
            </div>

            <hr className="margin-bottom"/>
            <div className="small-scroll-container">
              {targetRecipients}
            </div>

            <br/>
            <Input type="textarea" className="form-control comment-textarea" value={state.comment} id="comment" placeholder="Say something..." style={{resize:'none'}} rows="2" onChange={(e)=>setState({comment:e.target.value})}></Input>
            <br/>

            <center>
              <Button color="warning" onClick={sendFiles}><i className="fa fa-forward"></i> Send</Button>&nbsp;
              <Button color="info" onClick={toggleSendFile}>Close</Button>
            </center>
          </div>
        </ModalBody>
      </Modal>
      <Modal className="my-modal" isOpen={state.resizeFilePopUp} toggle={toggleResizeFile} >
        <ModalHeader hidden={true} toggle={toggleResizeFile}></ModalHeader>
        <ModalBody>
          <center>
            <h5><i className="fa fa-exclamation-triangle"></i> File Too Large</h5>
          </center>

          <b>Your file is larger than <font color="red">40MB</font>. Please resize your following file(s):</b>
          <div>{bigFileList}</div>

          <br/><br/>
          <center>
            <Button color="warning" onClick={toggleResizeFile}>Ok</Button>
          </center>
        </ModalBody>
      </Modal>
      <Modal className="my-modal" isOpen={state.toggle} toggle={toggle} >
        <ModalHeader hidden={true} toggle={toggle}></ModalHeader>
        <ModalBody>
          <MyDropzone onDrop={onDrop}/>
          <br/>
          <div style={{minHeight:'35px',maxHeight:'35px'}}>
            <b className="flashit red-color">{state.errorMessage}</b>
          </div>
          <div className="large-scroll-container">
            <table className="table file-list-table" cellSpacing="0" cellPadding="0">
              <thead>
                <tr>
                  <th width="30%">Name</th>
                  <th width="35%">File Type</th>
                  <th width="15%">Size</th>
                  <th width="10%"><center>Status</center></th>
                  <th width="10%">Control</th>
                </tr>
              </thead>
              <tbody>
                {toUploadFiles}
              </tbody>
            </table>
          </div>
          <br/>
          <div className="align-right">
            <Button color="warning" disabled={state.uploadDisabled!==false} onClick={uploadButtonHandler}><i className="fa fa-upload"></i> Upload</Button>&nbsp;
          </div>
        </ModalBody>
      </Modal>
      <br/>
      <div className="align-right">
        <Button color="warning" className="cursor-pointer" onClick={toggleSendFile}><i className="fa fa-forward"></i> Send File</Button>&nbsp;&nbsp;
        <Button color="warning" disabled={state.uploadDisabled!==false} onClick={toggle}><i className="fa fa-upload"></i> Upload</Button>&nbsp;
      </div>
    </div>;
  }
  else{
    return <div>
      <Modal className="my-modal" isOpen={state.resizeFilePopUp} toggle={toggleResizeFile} >
        <ModalHeader hidden={true} toggle={toggleResizeFile}></ModalHeader>
        <ModalBody>
          <center>
            <h5><i className="fa fa-exclamation-triangle"></i> File Too Large</h5>
          </center>

          <b>Your file is larger than <font color="red">40MB</font>. Please resize your following file(s):</b>
          <div>{bigFileList}</div>

          <br/><br/>
          <center>
            <Button color="warning" onClick={toggleResizeFile}>Ok</Button>
          </center>
        </ModalBody>
      </Modal>
      <MyDropzone onDrop={onDrop}/>
      <br/>
      <div style={{minHeight:'35px',maxHeight:'35px'}}>
        <b className="flashit red-color">{state.errorMessage}</b>
      </div>
      <div className="medium-scroll-container">
        <table className="table file-list-table" cellSpacing="0" cellPadding="0">
          <thead>
            <tr>
              <th width="30%">Name</th>
              <th width="35%">File Type</th>
              <th width="15%">Size</th>
              <th width="10%"><center>Status</center></th>
              <th width="10%">Control</th>
            </tr>
          </thead>
          <tbody>
            {toUploadFiles}
          </tbody>
        </table>
      </div>
      <div className="align-right">
        <Button color="warning" disabled={state.uploadDisabled!==false} onClick={uploadButtonHandler}><i className="fa fa-upload"></i> Upload</Button>&nbsp;
      </div>
    </div>;
  }
  
}

export default FileUpload;
