//Author Sooyoung Kim
//Date Dec 5, 2023
import {getReducer, getSetStateFunction, postAPICallGenerator} from '../../util/util';
import {Button, Card, CardHeader, CardBody, Input, Row, Col} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import MySelect from '../util/my-select';


//initialize the state
const initialState = {
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
  name:'',
  phone:'',
  street:'',
  city:'',
  state:'',
  zip:'',
  note:'',
};

//reducer function that perform state update
const reducer = getReducer();


const BrokerRelationshipProfileNew  = (props)=>{
  const controller = new AbortController();
  const history = useNavigate();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  //API call
  const createProfile = (e) => {
    e.preventDefault();

    let parameters = [
      {
        field:'name',
        value:state.name
      },
      {
        field:'phone',
        value:state.phone
      },
      {
        field:'street',
        value:state.street
      },
      {
        field:'city',
        value:state.city
      },
      {
        field:'state',
        value:state.state
      },
      {
        field:'zip',
        value:state.zip
      },
      {
        field:'note',
        value:state.note
      }
    ];

    let callBack = (response)=>{
      let code= response.data.code;
      if(code==='00'){
        history('/broker-relationship');
      }
    };
    callBack = callBack.bind(this);

    httpPost('brokerRelationship/create', parameters, 'Broker profile created successfully.', 'Oops, something went wrong and could not create the broker profile. Please try again later.', callBack);
  }

  //render
  return <div className="my-well">
    <div className="page-title">
      <i className="fa fa-reorder"></i>&nbsp;Relationship Profile - New Profile
    </div>
    <div className="my-divider">&nbsp;</div>
    <br/><br/>
    <Row>
      <Col sm="2">

      </Col>
      <Col sm="8">
        <Card>
          <CardHeader className="header-color">
            <i className="fa fa-info"></i> Broker Company Profile
          </CardHeader>
          <CardBody>
            <form onSubmit={createProfile}>
              <Row className="margin-bottom">
                <Col sm="4">
                  <label>Name</label>
                </Col>
                <Col sm="8">
                  <Input type="text" required="true" value={state.name} onChange={(e)=>setState({name:e.target.value})}/>
                </Col>
              </Row>
              <Row className="margin-bottom">
                <Col sm="4">
                  <label>Phone</label>
                </Col>
                <Col sm="8">
                  <Input type="text" value={state.phone} onChange={(e)=>setState({phone:e.target.value})}/>
                </Col>
              </Row>
              <Row className="margin-bottom">
                <Col sm="4">
                  <label>Street</label>
                </Col>
                <Col sm="8">
                  <Input type="text" value={state.street} onChange={(e)=>setState({street:e.target.value})}/>
                </Col>
              </Row>
              <Row className="margin-bottom">
                <Col sm="4">
                  <label>City</label>
                </Col>
                <Col sm="8">
                  <Input type="text" value={state.city} onChange={(e)=>setState({city:e.target.value})}/>
                </Col>
              </Row>
              <Row className="margin-bottom">
                <Col sm="4">
                  <label>State</label>
                </Col>
                <Col sm="8">
                  <MySelect
                    type="select"
                    value={state.state}
                    onChange={(v)=>setState({state:v})}
                    options={state.states.map((state)=>{
                      return {label:state.key, value:state.key};
                    })}
                  />
                </Col>
              </Row>
              <Row className="margin-bottom">
                <Col sm="4">
                  <label>Zip</label>
                </Col>
                <Col sm="8">
                  <Input type="text" value={state.zip} onChange={(e)=>setState({zip:e.target.value})}/>
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  <label>Note</label>
                </Col>
                <Col sm="8">
                  <Input type="text" value={state.note} onChange={(e)=>setState({note:e.target.value})}/>
                </Col>
              </Row>

              <br/>
              <div className="align-right">
                <Button color="warning">Submit</Button>
              </div>
            </form>
          </CardBody>
        </Card>
      </Col>
      <Col sm="2">

      </Col>
    </Row>
  </div>;
}


export default BrokerRelationshipProfileNew;
