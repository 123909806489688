//Author Sooyoung Kim
//Date Aug 21, 2023
import {showMessage, generateTooltip} from '../../util/util';
import {Card, CardHeader, CardBody, Col, Row, Input} from 'reactstrap';
import AdditionalStructure from './additional-structure';
import InteriorRoom from './interior-room';
import InteriorKitchen from './interior-kitchen';
import InteriorBathroom from './interior-bathroom';
import Heating from './heating';
import Cooling from './cooling';
import Garage from './garage';
import Parking from './parking';
import StructureAmenity from './structure-amenity';
import CommonAmenity from './common-amenity';
import Utility from './utility';
import React, {useEffect} from 'react';
import MyDropzone from '../util/my-dropzone';
import MySelect from '../util/my-select';
import {NavLink} from 'react-router-dom';
import {deletePhoto} from './util';

const interiorRoom     = {name:'Room',grade:'above',type:'Bedroom',removable:true,floorMaterials:[],floorMaterialConditions:[],roomPhotos:[],floorMaterialPhotos:[],floorUpdates:'',floorUpdateDescription:'',wallAndCeilingMaterials:[],wallAndCeilingMaterialConditions:[],wallAndCeilingMaterialPhotos:[],wallAndCeilingUpdates:'',wallAndCeilingUpdateDescription:''};
const interiorBathroom = {name:'Bathroom',grade:'above',removable:true,bathroomPhotos:[],bathroomHalfBath:'False',bathroomFloorMaterials:[],bathroomFloorMaterialConditions:[],bathroomFloorMaterialPhotos:[],bathroomFloorUpdates:'',bathroomFloorUpdateDescription:'',bathroomWallAndCeilingMaterials:[],bathroomWallAndCeilingMaterialConditions:[],bathroomWallAndCeilingMaterialPhotos:[],bathroomWallAndCeilingUpdates:'',bathroomWallAndCeilingUpdateDescription:'',bathroomShowerTubMaterials:[],bathroomShowerTubMaterialConditions:[],bathroomShowerTubPhotos:[],showSubmittedPrompt:false,bathroomEstimatedYearOfImprovement:'',bathroomImprovementDescription:'',};
const interiorKitchen  = {name:'Kitchen',grade:'above',removable:true,kitchenPhotos:[],kitchenFloorMaterials:[],kitchenFloorMaterialConditions:[],kitchenFloorMaterialPhotos:[],kitchenFloorUpdates:'',kitchenFloorUpdateDescription:'',kitchenWallAndCeilingMaterials:[],kitchenWallAndCeilingMaterialConditions:[],kitchenWallAndCeilingMaterialPhotos:[],kitchenWallAndCeilingUpdates:'',kitchenWallAndCeilingUpdateDescription:'',kitchenAppliances:[],kitchenCountertops:[],kitchenApplianceOtherDescription:'',kitchenCabinet:'',kitchenEstimatedYearOfImprovement:'',kitchenImprovementDescription:'',};
const additionalStructure = {removable:true,structureType:'',structureDescription:'',roomType:''}
const garage = {name:'Garage',grade:'above',removable:true,garagePhotos:[],garageFloorMaterials:[],garageFloorMaterialConditions:[],garageFloorMaterialPhotos:[],garageFloorUpdates:'',garageFloorUpdateDescription:'',garageWallAndCeilingMaterials:[],garageWallAndCeilingMaterialConditions:[],garageWallAndCeilingMaterialPhotos:[],garageWallAndCeilingUpdates:'',garageWallAndCeilingUpdateDescription:'',garageNumberOfSpaceForCar:'',garageBuiltIn:'',garageSqft:''};

const StepSite  = (props)=>{
  const controller = new AbortController();

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const removeField = (field, value) => {
    if(!props.disabled){
      let target = props[field].slice();
      let index = target.indexOf(value);

      if(index!==-1)
        target.splice(index, 1);

      props.updateState({[field]:target});
    }
  }

  const updateField = (field, value) => {
    let target = props[field].slice();
    if(value==='None'){
      target = ['None'];
    }
    else if(value!==''){
      let index = target.indexOf('None');

      if(index!==-1)
        target.splice(index, 1);

      index = target.indexOf(value);

      if(index===-1)
        target.push(value);
    }
    props.updateState({[field]:target});
  }



  //function trigger for dropzone react.
  //this function contians two list of files, the accepted and rejected file per the configuration
  const onDrop = (acceptedFiles, rejectedFiles, field, type='Misc', typeFk=0, description='') => {
    let existingFiles = [];
    if(acceptedFiles.length>0){
      for(let i=0;i<acceptedFiles.length;i++){
        existingFiles.push(acceptedFiles[i]);
        let name = existingFiles[i].name;
        let token = name.split('.');
        let extension = '';

        if(token.length>0){
          extension = token[token.length-1];
          extension = extension.toLowerCase();
        }

        if(extension!=='png'&&extension!=='jpg'&&extension!=='jpeg'){
          showMessage('error','Please upload only PNG/JPG image.');
        }
        else{
          const reader = new FileReader();
          reader.onload = () => {
              const fileAsBinaryString = reader.result;
              let base64 = btoa(fileAsBinaryString);
              let target = props[field]
              console.log(props);
              console.log(field);
              console.log(target);

              target.push({name:acceptedFiles[i].name, base64:base64, status:'Pending', size:acceptedFiles[i].size, type:type, typeFk:typeFk, description:description});
              props.updateState({[field]:target});
          }
          reader.readAsBinaryString(acceptedFiles[i]);
        }
      }
    }
  }

  const removeFile = (field,index) => {
    let target = props[field];
    let targetToDelete = target[index];

    if(targetToDelete.ID && targetToDelete.ID!==-1){
      let successCallBack = ()=>{
        target.splice(index, 1);
        props.updateState({[field]:target});
      };
      successCallBack = successCallBack.bind(this);
      deletePhoto(targetToDelete.ID, props, successCallBack);
    }
    else{
      target.splice(index, 1);
      props.updateState({[field]:target});
    }
  }

  //render
  let disabledStyling = {border:'none', background:'#fff', padding:'0',paddingLeft:'2px', height:'auto',WebkitAppearance:'none'};
  let renewableEnergyPhoto;

  if(props.renewableEnergyPresent==='True'){
    renewableEnergyPhoto = <Row style={{marginTop:'15px'}}>
      <Col sm="12">
        <label><font color="red">*</font>Renewable Energy Component Photos</label>
        <br/>
        <MyDropzone hidden={props.disabled} onDrop={(accepted, rejected)=>{onDrop(accepted, rejected, 'renewableEnergyComponentPhotos', 'Renewable Energy Component', 0)}} noIcon={true}/>
        {
          props.renewableEnergyComponentPhotos.map(
            (photo, index)=>{
              return <div key={index} className="display-inline" style={{width:200, height:250, margin:5}}>
                <div>
                  <i hidden={props.disabled} className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeFile('renewableEnergyComponentPhotos',index)}/> {photo.name.length>16?photo.name.substr(0,16)+'...':photo.name}
                </div>
                <img alt="pic" width="200" height="200" src={photo.status==='Pending'?"data:image/png;base64, "+photo.base64:photo.presign_url}/>
              </div>
            }
          )
        }
      </Col>
    </Row>;
  }

  let atticFeatures, atticFeatureDescription;

  if(props.attic==='True'){
    atticFeatures = <Col sm="4">
      <label><font color="red">*</font>Attic Features {generateTooltip('atticFeatures', 'Select all that apply.')}</label>
      <MySelect
        type="select"
        style={props.disabled?disabledStyling:{}}
        disabled={props.disabled}
        required={props.atticFeatures.length<=0}
        value={""}
        onChange={(v)=>updateField('atticFeatures',v)}
        options={[
          {label:"",value:""},
          {label:"Drop Stair",value:"DropStair"},
          {label:"Stairs",value:"Stairs"},
          {label:"Floor",value:"Floor"},
          {label:"Scuttle",value:"Scuttle"},
          {label:"Finished",value:"Finished"},
          {label:"Heated",value:"Heated"},
          {label:"Other",value:"Other"},
        ]}
      />
      <div className="extra-small-scroll-container">
      {
        props.atticFeatures.map(
          (atticFeature, index)=>{
            return(
              <div key={index} className="display-inline margin-top">
                <div className="filter-container" onClick={()=>removeField('atticFeatures', atticFeature)}>
                  <i hidden={props.disabled} className="fa fa-minus red-color"></i> {atticFeature}
                </div>
                &nbsp;
              </div>
            );
          }
        )
      }
    </div>
    </Col>;
    if(props.atticFeatures.indexOf('Other')!==-1)
      atticFeatureDescription = <Col sm="4">
        <label><font color="red">*</font>Attic Features Description</label>
        <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={true} type="text" value={props.atticFeatureDescription} onChange={(e)=>{props.updateState({atticFeatureDescription:e.target.value})}}/>
      </Col>
  }

  let heatingTypes;

  if(props.heatings.length>0)
    heatingTypes = props.heatings.map(
      (heating, index)=>{
        return(
          <div key={index} className="display-inline margin-top">
            <div className="filter-container" onClick={()=>{if(!props.disabled)props.removeItem('heatings', 'heatingType', heating.heatingType)}}>
              <i hidden={props.disabled} className="fa fa-minus red-color"></i> {heating.heatingType}
            </div>
            &nbsp;
          </div>
        );
      }
    )


  let evidenceOfConditionDescription;

  if(props.evidenceOfCondition==='True')
    evidenceOfConditionDescription = <Col sm="4">
      <label><font color="red">*</font>Description of Dampness, Settlement and infestation conditions</label>
      <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={true} type="text" value={props.evidenceOfConditionDescription} onChange={(e)=>{props.updateState({evidenceOfConditionDescription:e.target.value})}}/>
    </Col>;



  let structureDescription;

  if(props.structureType==='Other')
    structureDescription = <Col sm="4">
      <label><font color="red">*</font>Structure Other Description</label>
      <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={true} type="text" value={props.structureDescription} onChange={(e)=>{props.updateState({structureDescription:e.target.value})}}/>
    </Col>;


  let siteConditionDescription;

  if(props.siteConditions.indexOf('Other')!==-1)
    siteConditionDescription = <Col sm="4">
      <label><font color="red">*</font>Site Condition Other Description</label>
      <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={true} type="text" value={props.siteConditionDescription} onChange={(e)=>{props.updateState({siteConditionDescription:e.target.value})}}/>
    </Col>;

  let belowGradeAccess;

  if(props.belowGradeAccess.length>0)
    belowGradeAccess = props.belowGradeAccess.map(
          (access, index)=>{
            return(
              <div key={index} className="display-inline margin-top">
                <div className="filter-container" onClick={()=>{if(!props.disabled)removeField('belowGradeAccess', access)}}>
                  <i hidden={props.disabled} className="fa fa-minus red-color"></i> {access}
                </div>
                &nbsp;
              </div>
            );
          }
        );

  let guestParking;

  if(props.propertyType==='Condo')
    guestParking = <Col sm="4">
      <label><font color="red">*</font>Guest Parking Indicator</label>
      <MySelect
        type="select"
        style={props.disabled?disabledStyling:{}}
        disabled={props.disabled}
        required={true}
        value={props.guestParking}
        onChange={(v)=>props.updateState({guestParking:v})}
        options={[
          {label:"",value:""},
          {label:"Yes",value:"True"},
          {label:"No",value:"False"},
        ]}
      />
    </Col>;


  let guestParkingNumber;

  if(props.propertyType==='Condo')
    guestParkingNumber = <Col sm="4">
      <label><font color="red">*</font>Guest Parking Number</label>
      <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={true} type="number" value={props.guestParkingNumber} onChange={(e)=>{props.updateState({guestParkingNumber:e.target.value})}}/>
    </Col>;

  let onSiteParkingTypesValue;
  if(props.parkings.length>0){
    onSiteParkingTypesValue = props.parkings.map(
      (onSiteParkingType, index)=>{
        return(
          <div key={index} className="display-inline margin-top">
            <div className="filter-container" onClick={()=>{if(!props.disabled)props.removeItem('parkings', 'parkingType', onSiteParkingType.parkingType)}}>
              <i hidden={props.disabled} className="fa fa-minus red-color"></i> {onSiteParkingType.parkingType}
            </div>
            &nbsp;
          </div>
        );
      }
    );
  }

  let onSiteParkingTypes;

  if(props.carStorageIndicator==='True')
    onSiteParkingTypes = <Col sm="4">
      <label><font color="red">*</font>On Site Parking Type {generateTooltip('onSiteParkingTypes', 'Select all that apply.')}</label>
      <MySelect
        type="select"
        style={props.disabled?disabledStyling:{}}
        disabled={props.disabled}
        required={props.parkings.length<=0}
        value={""}
        onChange={(v)=>props.addNewItem('parkings', v, 'parkingType', {parkingTypeOtherDescription:'', parkingCovered:'', parkingSurface:'', parkingNumberOfCar:'', parkingSqft:'', parkingBuiltIn:'', parkingAttached:''})}
        options={[
          {label:"",value:""},
          {label:"Pad",value:"Pad"},
          {label:"Driveway",value:"Driveway"},
          {label:"Garage",value:"Garage"},
          {label:"Carport",value:"Carport"},
          {label:"None",value:"None"},
        ]}
      />
      <div className="extra-small-scroll-container">
        {onSiteParkingTypesValue}
      </div>
    </Col>;


  let energyRatingDescription;

  if(props.energyRatingOrganization==='Other')
    energyRatingDescription = <Col sm="4">
      <label><font color="red">*</font>Energy/Solar Rating Additional Information</label>
      <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={true} type="text" value={props.energyRatingDescription} onChange={(e)=>{props.updateState({energyRatingDescription:e.target.value})}}/>
    </Col>;

  let renewableEnergyComponentDescription;

  if(props.renewableEnergyComponent==='Other')
    renewableEnergyComponentDescription = <Col sm="4">
      <label><font color="red">*</font>Renewable Energy Component Additional Information</label>
      <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={true} type="text" value={props.renewableEnergyComponentDescription} onChange={(e)=>{props.updateState({renewableEnergyComponentDescription:e.target.value})}}/>
    </Col>;

  let solarPanelOwnership;

  if(props.renewableEnergyComponent==='Solar Panel')
    solarPanelOwnership = <Col sm="4">
      <label><font color="red">*</font>Renewable Energy - Solar Panel Ownership</label>
      <MySelect
        type="select"
        style={props.disabled?disabledStyling:{}}
        disabled={props.disabled}
        required={true}
        value={props.solarPanelOwnership}
        onChange={(v)=>props.updateState({solarPanelOwnership:v})}
        options={[
          {label:"",value:""},
          {label:"Owned",value:"Owned"},
          {label:"Leased",value:"Leased"},
          {label:"Power Purchase Agreement",value:"Power Purchase Agreement"},
          {label:"Unknown",value:"Unknown"},
        ]}
      />
    </Col>;

  let adjoinExternalFactorDescription;

  if(props.adjoinExternalFactors.indexOf('Other')!==-1)
    adjoinExternalFactorDescription = <Col sm="4">
      <label><font color="red">*</font>External Factor Other Description</label>
      <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={true} type="text" value={props.adjoinExternalFactorDescription} onChange={(e)=>{props.updateState({adjoinExternalFactorDescription:e.target.value})}}/>
    </Col>;

  let windowUpdateDescription;

  if(props.windowUpdates==='Partially Updated' || props.windowUpdates==='Fully Updated')
    windowUpdateDescription = <Col sm="4">
      <label><font color="red">*</font>Window Update Description</label>
      <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={true} type="text" value={props.windowUpdateDescription} onChange={(e)=>{props.updateState({windowUpdateDescription:e.target.value})}}/>
    </Col>;

  let coolingTypes;

  if(props.coolings.length>0)
    coolingTypes = props.coolings.map(
      (cooling, index)=>{
        return(
          <div key={index} className="display-inline margin-top">
            <div className="filter-container" onClick={()=>{if(!props.disabled)props.removeItem('coolings', 'coolingType', cooling.coolingType)}}>
              <i hidden={props.disabled} className="fa fa-minus red-color"></i> {cooling.coolingType}
            </div>
            &nbsp;
          </div>
        );
      }
    )

  let foundationTypes;

  if(props.foundationTypes.length>0)
    foundationTypes = props.foundationTypes.map(
      (foundationType, index)=>{
        return(
          <div key={index} className="display-inline margin-top">
            <div className="filter-container" onClick={()=>{if(!props.disabled)removeField('foundationTypes', foundationType)}}>
              <i hidden={props.disabled} className="fa fa-minus red-color"></i> {foundationType}
            </div>
            &nbsp;
          </div>
        );
      }
    )

  let foundationWallMaterials;

  if(props.foundationWallMaterials.length>0)
    foundationWallMaterials = props.foundationWallMaterials.map(
      (foundationWallMaterial, index)=>{
        return(
          <div key={index} className="display-inline margin-top">
            <div className="filter-container" onClick={()=>removeField('foundationWallMaterials', foundationWallMaterial)}>
              <i hidden={props.disabled} className="fa fa-minus red-color"></i> {foundationWallMaterial}
            </div>
            &nbsp;
          </div>
        );
      }
    )


  let adjoinExternalFactors;

  if(props.adjoinExternalFactors.length>0)
    adjoinExternalFactors = props.adjoinExternalFactors.map(
      (adjoinExternalFactor, index)=>{
        return(
          <div key={index} className="display-inline margin-top">
            <div className="filter-container" onClick={()=>removeField('adjoinExternalFactors', adjoinExternalFactor)}>
              <i hidden={props.disabled} className="fa fa-minus red-color"></i> {adjoinExternalFactor}
            </div>
            &nbsp;
          </div>
        );
      }
    )


  let streetAlleyTypes;

  if(props.streetAlleyTypes.length>0)
    streetAlleyTypes = props.streetAlleyTypes.map(
      (streetAlleyType, index)=>{
        return(
          <div key={index} className="display-inline margin-top">
            <div className="filter-container" onClick={()=>removeField('streetAlleyTypes', streetAlleyType)}>
              <i hidden={props.disabled} className="fa fa-minus red-color"></i> {streetAlleyType}
            </div>
            &nbsp;
          </div>
        );
      }
    )

  let streetAlleyMaterials;

  if(props.streetAlleyMaterials.length>0)
    streetAlleyMaterials = props.streetAlleyMaterials.map(
      (streetAlleyMaterial, index)=>{
        return(
          <div key={index} className="display-inline margin-top">
            <div className="filter-container" onClick={()=>removeField('streetAlleyMaterials', streetAlleyMaterial)}>
              <i hidden={props.disabled} className="fa fa-minus red-color"></i> {streetAlleyMaterial}
            </div>
            &nbsp;
          </div>
        );
      }
    )

  let siteConditions;

  if(props.siteConditions.length>0)
    siteConditions = props.siteConditions.map(
      (siteCondition, index)=>{
        return(
          <div key={index} className="display-inline margin-top">
            <div className="filter-container" onClick={()=>removeField('siteConditions', siteCondition)}>
              <i hidden={props.disabled} className="fa fa-minus red-color"></i> {siteCondition}
            </div>
            &nbsp;
          </div>
        );
      }
    )

  let siteCommonAmenities;

  if(props.commonAmenities.length>0)
    siteCommonAmenities = props.commonAmenities.map(
      (siteCommonAmenity, index)=>{
        return(
          <div key={index} className="display-inline margin-top">
            <div className="filter-container" onClick={()=>{if(!props.disabled)props.removeItem('commonAmenities', 'amenityType', siteCommonAmenity.amenityType)}}>
              <i hidden={props.disabled} className="fa fa-minus red-color"></i> {siteCommonAmenity.amenityType}
            </div>
            &nbsp;
          </div>
        );
      }
    )

  let utilities;

  if(props.utilities.length>0)
    utilities = props.utilities.map(
      (utility, index)=>{
        return(
          <div key={index} className="display-inline margin-top">
            <div className="filter-container" onClick={()=>{if(!props.disabled)props.removeItem('utilities', 'utilityType', utility.utilityType)}}>
              <i hidden={props.disabled} className="fa fa-minus red-color"></i> {utility.utilityType}
            </div>
            &nbsp;
          </div>
        );
      }
    )


  let viewTypes;

  if(props.viewTypes.length>0)
    viewTypes = props.viewTypes.map(
      (viewType, index)=>{
        return(
          <div key={index} className="display-inline margin-top">
            <div className="filter-container" onClick={()=>removeField('viewTypes', viewType)}>
              <i hidden={props.disabled} className="fa fa-minus red-color"></i> {viewType}
            </div>
            &nbsp;
          </div>
        );
      }
    )

  let foundationWallConditions;

  if(props.foundationWallConditions.length>0)
    foundationWallConditions = props.foundationWallConditions.map(
      (foundationWallCondition, index)=>{
        return(
          <div key={index} className="display-inline margin-top">
            <div className="filter-container" onClick={()=>removeField('foundationWallConditions', foundationWallCondition)}>
              <i hidden={props.disabled} className="fa fa-minus red-color"></i> {foundationWallCondition}
            </div>
            &nbsp;
          </div>
        );
      }
    )

  let exteriorWallMaterials;

  if(props.exteriorWallMaterials.length>0)
    exteriorWallMaterials = props.exteriorWallMaterials.map(
      (exteriorWallMaterial, index)=>{
        return(
          <div key={index} className="display-inline margin-top">
            <div className="filter-container" onClick={()=>removeField('exteriorWallMaterials', exteriorWallMaterial)}>
              <i hidden={props.disabled} className="fa fa-minus red-color"></i> {exteriorWallMaterial}
            </div>
            &nbsp;
          </div>
        );
      }
    )

  let exteriorWallConditions;

  if(props.exteriorWallConditions.length>0)
    exteriorWallConditions = props.exteriorWallConditions.map(
      (exteriorWallCondition, index)=>{
        return(
          <div key={index} className="display-inline margin-top">
            <div className="filter-container" onClick={()=>removeField('exteriorWallConditions', exteriorWallCondition)}>
              <i hidden={props.disabled} className="fa fa-minus red-color"></i> {exteriorWallCondition}
            </div>
            &nbsp;
          </div>
        );
      }
    )

  let gutterAndDownspoutMaterials;

  if(props.gutterAndDownspoutMaterials.length>0)
    gutterAndDownspoutMaterials = props.gutterAndDownspoutMaterials.map(
      (gutterAndDownspoutMaterial, index)=>{
        return(
          <div key={index} className="display-inline margin-top">
            <div className="filter-container" onClick={()=>removeField('gutterAndDownspoutMaterials', gutterAndDownspoutMaterial)}>
              <i hidden={props.disabled} className="fa fa-minus red-color"></i> {gutterAndDownspoutMaterial}
            </div>
            &nbsp;
          </div>
        );
      }
    )

  let gutterAndDownspoutConditions;

  if(props.gutterAndDownspoutConditions.length>0)
    gutterAndDownspoutConditions = props.gutterAndDownspoutConditions.map(
      (gutterAndDownspoutCondition, index)=>{
        return(
          <div key={index} className="display-inline margin-top">
            <div className="filter-container" onClick={()=>removeField('gutterAndDownspoutConditions', gutterAndDownspoutCondition)}>
              <i hidden={props.disabled} className="fa fa-minus red-color"></i> {gutterAndDownspoutCondition}
            </div>
            &nbsp;
          </div>
        );
      }
    )

  let windowMaterials;

  if(props.windowMaterials.length>0)
    windowMaterials = props.windowMaterials.map(
      (windowMaterial, index)=>{
        return(
          <div key={index} className="display-inline margin-top">
            <div className="filter-container" onClick={()=>removeField('windowMaterials', windowMaterial)}>
              <i hidden={props.disabled} className="fa fa-minus red-color"></i> {windowMaterial}
            </div>
            &nbsp;
          </div>
        );
      }
    )

  let windowConditions;

  if(props.windowConditions.length>0)
    windowConditions = props.windowConditions.map(
      (windowCondition, index)=>{
        return(
          <div key={index} className="display-inline margin-top">
            <div className="filter-container" onClick={()=>removeField('windowConditions', windowCondition)}>
              <i hidden={props.disabled} className="fa fa-minus red-color"></i> {windowCondition}
            </div>
            &nbsp;
          </div>
        );
      }
    )

    let roofMaterials;

    if(props.roofMaterials.length>0)
      roofMaterials = props.roofMaterials.map(
        (roofMaterial, index)=>{
          return(
            <div key={index} className="display-inline margin-top">
              <div className="filter-container" onClick={()=>removeField('roofMaterials', roofMaterial)}>
                <i hidden={props.disabled} className="fa fa-minus red-color"></i> {roofMaterial}
              </div>
              &nbsp;
            </div>
          );
        }
      )

  let roofConditions;

  if(props.roofConditions.length>0)
    roofConditions = props.roofConditions.map(
      (roofCondition, index)=>{
        return(
          <div key={index} className="display-inline margin-top">
            <div className="filter-container" onClick={()=>removeField('roofConditions', roofCondition)}>
              <i hidden={props.disabled} className="fa fa-minus red-color"></i> {roofCondition}
            </div>
            &nbsp;
          </div>
        );
      }
    )

  let siteStructureAmenities;

  if(props.amenities.length>0)
    siteStructureAmenities = props.amenities.map(
      (siteStructureAmenity, index)=>{
        return(
          <div key={index} className="display-inline margin-top">
            <div className="filter-container" onClick={()=>{if(!props.disabled)props.removeItem('amenities', 'amenityType', siteStructureAmenity.amenityType)}}>
              <i hidden={props.disabled} className="fa fa-minus red-color"></i> {siteStructureAmenity.amenityType}
            </div>
            &nbsp;
          </div>
        );
      }
    )

  let foundationWallMaterialDescription;
  if(props.foundationWallMaterials.indexOf('Other')!==-1)
    foundationWallMaterialDescription = <Col sm="4">
      <label><font color="red">*</font>Foundation Wall Other Description</label>
      <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={true} type="text" value={props.foundationWallMaterialDescription} onChange={(e)=>{props.updateState({foundationWallMaterialDescription:e.target.value})}}/>
    </Col>;

  let exteriorWallMaterialDescription;
  if(props.exteriorWallMaterials.indexOf('Other Siding')!==-1||props.exteriorWallMaterials.indexOf('Other Masonry')!==-1)
    exteriorWallMaterialDescription = <Col sm="4">
      <label><font color="red">*</font>Exterior Wall Other Description</label>
      <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={true} type="text" value={props.exteriorWallMaterialDescription} onChange={(e)=>{props.updateState({exteriorWallMaterialDescription:e.target.value})}}/>
    </Col>;

  let viewDescription;
  if(props.viewTypes.indexOf('Other')!==-1)
    viewDescription = <Col sm="4">
      <label><font color="red">*</font>View Type Other Description</label>
      <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={true} type="text" value={props.viewDescription} onChange={(e)=>{props.updateState({viewDescription:e.target.value})}}/>
    </Col>;


  let roofMaterialDescription;
  if(props.roofMaterials.indexOf('Other Shingle')!==-1||props.roofMaterials.indexOf('Other Non Shingle')!==-1)
    roofMaterialDescription = <Col sm="3">
      <label><font color="red">*</font>Roof Surface Other Description</label>
      <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={true} type="text" value={props.roofMaterialDescription} onChange={(e)=>{props.updateState({roofMaterialDescription:e.target.value})}}/>
    </Col>;

  let gutterOtherDescription;

  if(props.gutterAndDownspoutMaterials.indexOf('Other')!==-1)
    gutterOtherDescription = <Col sm="4">
      <label><font color="red">*</font>Gutter & Downspout Materials Description</label>
      <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={true} type="text" value={props.gutterOtherDescription} onChange={(e)=>{props.updateState({gutterOtherDescription:e.target.value})}}/>
    </Col>;

  return <div>
    <div className="align-right">
      {props.disabled?null:<NavLink to="#" onClick={(v)=>props.addNewRoom('additionalStructures', additionalStructure)}><i className="fa fa-plus green-color"/> Add additional structure</NavLink>}
    </div>
    {
      props.additionalStructures.map(
        (room, index)=>{
          return <div key={index}>
            <AdditionalStructure index={index} disabled={props.disabled} additionalStructures={props.additionalStructures} removeRoom={props.removeRoom} updateState={props.updateState}/>
            <br/>
          </div>;
        }
      )
    }
    <Card>
      <CardHeader className="header-color">
        Property Information
      </CardHeader>
      <CardBody>
        <Row style={{marginTop:'15px'}}>
          <Col sm="4">
            <label>{((props.structureType==='PrimaryDwelling'|| props.structureType==='ADU')||(props.foundationTypes.indexOf('Basement')!==-1|| props.foundationTypes.indexOf('Partial Basement')!==-1))?<font color="red">*</font>:null}Square feet of basement/Below Grade</label>
            <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={((props.structureType==='PrimaryDwelling'|| props.structureType==='ADU') || (props.foundationTypes.indexOf('Basement')!==-1|| props.foundationTypes.indexOf('Partial Basement')!==-1))} type="number" value={props.sqftBelowGrade} onChange={(e)=>{props.updateState({sqftBelowGrade:e.target.value})}}/>
          </Col>
          <Col sm="4">
            <label>{((props.structureType==='PrimaryDwelling'|| props.structureType==='ADU')||(props.foundationTypes.indexOf('Basement')!==-1|| props.foundationTypes.indexOf('Partial Basement')!==-1))?<font color="red">*</font>:null}Basement/Below Grade Percent Finished</label>
            <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={((props.structureType==='PrimaryDwelling'|| props.structureType==='ADU') || (props.foundationTypes.indexOf('Basement')!==-1|| props.foundationTypes.indexOf('Partial Basement')!==-1))} type="number" value={props.percentBelowGrade} onChange={(e)=>{props.updateState({percentBelowGrade:e.target.value})}}/>
          </Col>
          <Col sm="4">
            <label>{(props.foundationTypes.indexOf('Basement')!==-1|| props.foundationTypes.indexOf('Partial Basement')!==-1)?<font color="red">*</font>:null}Basement / Below Grade Access {generateTooltip('belowGradeAccess', 'Select all that apply.')}</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={(props.foundationTypes.indexOf('Basement')!==-1|| props.foundationTypes.indexOf('Partial Basement')!==-1)&&props.belowGradeAccess.length<=0}
              value={""}
              onChange={(v)=>updateField('belowGradeAccess',v)}
              options={[
                {label:"",value:""},
                {label:"Walkout",value:"Walkout"},
                {label:"Walkup",value:"Walkup"},
                {label:"Interior",value:"Interior"},
              ]}
            />
            <div className="extra-small-scroll-container">
              {belowGradeAccess}
            </div>
          </Col>
          <Col sm="4">
            <label>{(props.foundationTypes.indexOf('Basement')!==-1|| props.foundationTypes.indexOf('Partial Basement')!==-1)?<font color="red">*</font>:null}Number of Recreation rooms below grade</label>
            <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={(props.foundationTypes.indexOf('Basement')!==-1|| props.foundationTypes.indexOf('Partial Basement')!==-1)} type="number" value={props.recreationBelowGrade} onChange={(e)=>{props.updateState({recreationBelowGrade:e.target.value})}}/>
          </Col>
          <Col sm="4">
            <label>{(props.foundationTypes.indexOf('Basement')!==-1|| props.foundationTypes.indexOf('Partial Basement')!==-1)?<font color="red">*</font>:null}Number of Bedroom below grade</label>
            <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={(props.foundationTypes.indexOf('Basement')!==-1|| props.foundationTypes.indexOf('Partial Basement')!==-1)} type="number" value={props.bedroomBelowGrade} onChange={(e)=>{props.updateState({bedroomBelowGrade:e.target.value})}}/>
          </Col>
          <Col sm="4">
            <label>{(props.foundationTypes.indexOf('Basement')!==-1|| props.foundationTypes.indexOf('Partial Basement')!==-1)?<font color="red">*</font>:null}Number of Full Bathrooms below grade</label>
            <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={(props.foundationTypes.indexOf('Basement')!==-1|| props.foundationTypes.indexOf('Partial Basement')!==-1)} type="number" value={props.bathroomBelowGrade} onChange={(e)=>{props.updateState({bathroomBelowGrade:e.target.value})}}/>
          </Col>
          <Col sm="4">
            <label>{(props.foundationTypes.indexOf('Basement')!==-1|| props.foundationTypes.indexOf('Partial Basement')!==-1)?<font color="red">*</font>:null}Number of Half Bath below grade</label>
            <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={(props.foundationTypes.indexOf('Basement')!==-1|| props.foundationTypes.indexOf('Partial Basement')!==-1)} type="number" value={props.halfbathBelowGrade} onChange={(e)=>{props.updateState({halfbathBelowGrade:e.target.value})}}/>
          </Col>
          <Col sm="4">
            <label>{(props.foundationTypes.indexOf('Basement')!==-1|| props.foundationTypes.indexOf('Partial Basement')!==-1)?<font color="red">*</font>:null}Number of other rooms below grade</label>
            <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={(props.foundationTypes.indexOf('Basement')!==-1|| props.foundationTypes.indexOf('Partial Basement')!==-1)} type="number" value={props.roomBelowGrade} onChange={(e)=>{props.updateState({roomBelowGrade:e.target.value})}}/>
          </Col>
          <Col sm="4">
            <label>{(props.structureType==='PrimaryDwelling'|| props.structureType==='ADU')?<font color="red">*</font>:null}Total Number of Room Above Grade</label>
            <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={(props.structureType==='PrimaryDwelling'|| props.structureType==='ADU')} type="number" value={props.aboveGradeRoomCount} onChange={(e)=>{props.updateState({aboveGradeRoomCount:e.target.value})}}/>
          </Col>
          <Col sm="4">
            <label>{(props.structureType==='PrimaryDwelling'|| props.structureType==='ADU')?<font color="red">*</font>:null}Total Number of Bedroom Above Grade</label>
            <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={(props.structureType==='PrimaryDwelling'|| props.structureType==='ADU')} type="number" value={props.aboveGradeBedroomCount} onChange={(e)=>{props.updateState({aboveGradeBedroomCount:e.target.value})}}/>
          </Col>
          <Col sm="4">
            <label>{(props.structureType==='PrimaryDwelling'|| props.structureType==='ADU')?<font color="red">*</font>:null}Total Number of Full Bathroom Above Grade</label>
            <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={(props.structureType==='PrimaryDwelling'|| props.structureType==='ADU')} type="number" value={props.aboveGradeBathroomCount} onChange={(e)=>{props.updateState({aboveGradeBathroomCount:e.target.value})}}/>
          </Col>
          <Col sm="4">
            <label>{(props.structureType==='PrimaryDwelling'|| props.structureType==='ADU')?<font color="red">*</font>:null}Total Number of Half Bathroom Above Grade</label>
            <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={(props.structureType==='PrimaryDwelling'|| props.structureType==='ADU')} type="number" value={props.aboveGradeHalfBathCount} onChange={(e)=>{props.updateState({aboveGradeHalfBathCount:e.target.value})}}/>
          </Col>
          <Col sm="4">
            <label>{(props.structureType==='PrimaryDwelling'|| props.structureType==='ADU')?<font color="red">*</font>:null}Square Feet of Gross Living Area Above Grade</label>
            <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={(props.structureType==='PrimaryDwelling'|| props.structureType==='ADU')} type="number" value={props.aboveGradeGrossLivingArea} onChange={(e)=>{props.updateState({aboveGradeGrossLivingArea:e.target.value})}}/>
          </Col>
        </Row>
        <Row style={{marginTop:'15px'}}>
          <Col sm="4">
            <label><font color="red">*</font>Structure Type</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={true}
              value={props.structureType}
              onChange={(v)=>props.updateState({'structureType':v})}
              options={[
                {label:"",value:""},
                {label:"Primary Dwelling",value:"PrimaryDwelling"},
                {label:"ADU",value:"ADU"},
                {label:"Detached Garage",value:"Detached Garage"},
                {label:"Workshop",value:"Workshop"},
                {label:"Storage",value:"Storage"},
                {label:"Barn",value:"Barn"},
                {label:"Other",value:"Other"},
              ]}
            />
          </Col>
          <Col sm="3">
            <label>{(props.structureType==='PrimaryDwelling'|| props.structureType==='ADU')?<font color="red">*</font>:null}Property Occupancy</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={(props.structureType==='PrimaryDwelling'|| props.structureType==='ADU')}
              value={props.occupancyType}
              onChange={(v)=>props.updateState({occupancyType:v})}
              options={[
                {label:"",value:""},
                {label:"Owner",value:"Owner"},
                {label:"Tenant",value:"Tenant"},
                {label:"Vacant",value:"Vacant"},
              ]}
            />
          </Col>
          {structureDescription}
        </Row>
        <Row style={{marginTop:'15px'}}>
          <Col sm="6">
            <label>{(props.structureType==='PrimaryDwelling' || props.structureType==='ADU')?<font color="red">*</font>:null}View Type {generateTooltip('viewTypes','The types of views associated with the property. Multiple selections permitted. Select all that apply.')}</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={(props.structureType==='PrimaryDwelling' || props.structureType==='ADU') && props.viewTypes.length<=0}
              value={""}
              onChange={(v)=>updateField('viewTypes',v)}
              options={[
                {label:"",value:""},
                {label:"Water",value:"Water"},
                {label:"Pastoral",value:"Pastoral"},
                {label:"Woods",value:"Woods"},
                {label:"Park",value:"Park"},
                {label:"Golf Course",value:"GolfCourse"},
                {label:"City Skyline",value:"CitySkyline"},
                {label:"Mountain",value:"Mountain"},
                {label:"Residential",value:"Residential"},
                {label:"City Street",value:"CityStreet"},
                {label:"Industrial",value:"Industrial"},
                {label:"Power Lines",value:"PowerLines"},
                {label:"Limited Sight",value:"LimitedSight"},
                {label:"Nothing Notable",value:"NothingNotable"},
                {label:"Other",value:"Other"}
              ]}
            />
            {viewTypes}
          </Col>
          {viewDescription}
        </Row>
      </CardBody>
    </Card>

    <br/>
    <div className="align-right">
      {props.disabled?null:<NavLink to="#" onClick={(v)=>props.addNewRoom('interiors', interiorRoom)}><i className="fa fa-plus green-color"/> Add a Room</NavLink>}
    </div>
    <br/>
    {
      props.interiors.map(
        (room, index)=>{
          return <div key={index}>
            <InteriorRoom {...props} index={index} loadPhotoContent={props.loadPhotoContent} removeRoom={props.removeRoom} interiors={props.interiors} structureType={props.structureType} updateState={props.updateState}/>
            <br/>
          </div>;
        }
      )
    }
    <div className="align-right">
      {props.disabled?null:<NavLink to="#" onClick={(v)=>props.addNewRoom('bathrooms', interiorBathroom)}><i className="fa fa-plus green-color"/> Add a Bathroom</NavLink>}
    </div>
    <br/>
    {
      props.bathrooms.map(
        (room, index)=>{
          return <div key={index}>
            <InteriorBathroom {...props} index={index} loadPhotoContent={props.loadPhotoContent} removeRoom={props.removeRoom} bathrooms={props.bathrooms} structureType={props.structureType} updateState={props.updateState}/>
            <br/>
          </div>;
        }
      )
    }
    <div className="align-right">
      {props.disabled?null:<NavLink to="#" onClick={(v)=>props.addNewRoom('kitchens', interiorKitchen)}><i className="fa fa-plus green-color"/> Add a Kitchen</NavLink>}
    </div>
    <br/>
    {
      props.kitchens.map(
        (room, index)=>{
          return <div key={index}>
            <InteriorKitchen {...props} index={index} loadPhotoContent={props.loadPhotoContent} removeRoom={props.removeRoom} kitchens={props.kitchens} structureType={props.structureType} updateState={props.updateState}/>
            <br/>
          </div>;
        }
      )
    }
    <br/>
    <div className="align-right">
      {props.disabled?null:<NavLink to="#" onClick={(v)=>props.addNewRoom('garages', garage)}><i className="fa fa-plus green-color"/> Add a Garage</NavLink>}
    </div>
    <br/>
    {
      props.garages.map(
        (room, index)=>{
          return <div key={index}>
            <Garage {...props} index={index} loadPhotoContent={props.loadPhotoContent} removeRoom={props.removeRoom} garages={props.garages} structureType={props.structureType} updateState={props.updateState}/>
            <br/>
          </div>;
        }
      )
    }
    <br/>
    <Card>
      <CardHeader className="header-color">
        Attic / Heating / Cooling / Fireplace & Other Interior
      </CardHeader>
      <CardBody>
        <Row style={{marginTop:'15px'}}>
          <Col sm="4">
            <label>{(props.structureType==='PrimaryDwelling'|| props.structureType==='ADU')?<font color="red">*</font>:null}Attic</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={(props.structureType==='PrimaryDwelling'|| props.structureType==='ADU')}
              value={props.attic}
              onChange={(v)=>props.updateState({attic:v})}
              options={[
                {label:"",value:""},
                {label:"Yes",value:"True"},
                {label:"No",value:"False"},
              ]}
            />
          </Col>
          {atticFeatures}
          {atticFeatureDescription}
        </Row>
        <Row style={{marginTop:'15px'}}>
          <Col sm="12">
            <label>Heating Type {generateTooltip('heatingTypes', 'Select all that apply.')}</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              value={""}
              onChange={(v)=>props.addNewItem('heatings', v, 'heatingType', {heatingUpdates:'', heatingUpdateDescription:'',heatingOtherDescription:'',heatingDamagesDefect:''})}
              options={[
                {label:"",value:""},
                {label:"Forced Warm Air",value:"ForcedWarmAir"},
                {label:"Radiant",value:"Radiant"},
                {label:"Hot Water Baseboard",value:"HotWaterBaseboard"},
                {label:"Other",value:"Other"},
                {label:"None",value:"None"},
              ]}
            />
            <div className="extra-small-scroll-container">
              {heatingTypes}
            </div>
          </Col>
        </Row>
        {
          props.heatings.map(
            (heating, index)=>{
              return <Heating {...props} key={index} index={index} heatings={props.heatings} updateState={props.updateState}/>
            }
          )
        }
        <Row style={{marginTop:'15px'}}>
          <Col sm="12">
            <label><font color="red">*</font>Heating Photos</label>
            <br/>
            <MyDropzone hidden={props.disabled} onDrop={(accepted, rejected)=>{onDrop(accepted, rejected, 'heatingPhotos', 'Heating', 0)}} noIcon={true}/>
            {
              props.heatingPhotos.map(
                (photo, index)=>{
                  return <div key={index} className="display-inline" style={{width:200, height:250, margin:5}}>
                    <div>
                      <i hidden={props.disabled} className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeFile('heatingPhotos',index)}/> {photo.name.length>16?photo.name.substr(0,16)+'...':photo.name}
                    </div>
                    <img alt="pic" width="200" height="200" src={photo.status==='Pending'?"data:image/png;base64, "+photo.base64:photo.status==='Pending'?"data:image/png;base64, "+photo.base64:photo.presign_url}/>
                  </div>
                }
              )
            }
          </Col>
        </Row>
        <Row style={{marginTop:'15px'}}>
          <Col sm="12">
            <label>Cooling Type {generateTooltip('coolingTypes', 'Select all that apply.')}</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              value={""}
              onChange={(v)=>props.addNewItem('coolings', v, 'coolingType', {coolingUpdates:'', coolingUpdateDescription:'',coolingOtherDescription:'',coolingDamagesDefect:''})}
              options={[
                {label:"",value:""},
                {label:"Central Air",value:"CentralAir"},
                {label:"Individual",value:"Individual"},
                {label:"Evaporative",value:"Evaporative"},
                {label:"Other",value:"Other"},
                {label:"None",value:"None"},
              ]}
            />
            <div className="extra-small-scroll-container">
              {coolingTypes}
            </div>
          </Col>
        </Row>
        {
          props.coolings.map(
            (cooling, index)=>{
              return <Cooling {...props} key={index} index={index} coolings={props.coolings} updateState={props.updateState}/>
            }
          )
        }
        <Row style={{marginTop:'15px'}}>
          <Col sm="12">
            <label><font color="red">*</font>Cooling Photos</label>
            <br/>
            <MyDropzone hidden={props.disabled} onDrop={(accepted, rejected)=>{onDrop(accepted, rejected, 'coolingPhotos', 'Cooling', 0)}} noIcon={true}/>
            {
              props.coolingPhotos.map(
                (photo, index)=>{
                  return <div key={index} className="display-inline" style={{width:200, height:250, margin:5}}>
                    <div>
                      <i hidden={props.disabled} className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeFile('coolingPhotos',index)}/> {photo.name.length>16?photo.name.substr(0,16)+'...':photo.name}
                    </div>
                    <img alt="pic" width="200" height="200" src={photo.status==='Pending'?"data:image/png;base64, "+photo.base64:photo.presign_url}/>
                  </div>
                }
              )
            }
          </Col>
        </Row>
        <Row style={{marginTop:'15px'}}>
          <Col sm="4">
            <label><font color="red">*</font>Washer/Dryer Hookup</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={true}
              value={props.washerDryerHookup}
              onChange={(v)=>props.updateState({washerDryerHookup:v})}
              options={[
                {label:"",value:""},
                {label:"Yes",value:"True"},
                {label:"No",value:"False"},
              ]}
            />
          </Col>
          <Col sm="8">
            <label>{(props.structureType==='PrimaryDwelling'|| props.structureType==='ADU')?<font color="red">*</font>:null}Site Structure Amenities {generateTooltip('structureTypes', 'Select all that apply.')}</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={(props.structureType==='PrimaryDwelling'|| props.structureType==='ADU') && props.amenities.length<=0}
              value={""}
              onChange={(v)=>props.addNewItem('amenities', v, 'amenityType', {amenityTypeOtherDescription:'', amenitySubTypes:[], amenityCount:''})}
              options={[
                {label:"",value:""},
                {label:"Fireplace",value:"Fireplace"},
                {label:"Patio",value:"Patio"},
                {label:"Interior Pool",value:"InteriorPool"},
                {label:"Deck",value:"Deck"},
                {label:"Wood Stove",value:"WoodStove"},
                {label:"Porch",value:"Porch"},
                {label:"Interior Sauna",value:"InteriorSauna"},
                {label:"Interior Spa",value:"InteriorSpa"},
                {label:"Gazebo",value:"Gazebo"},
                {label:"Balcony",value:"Balcony"},
                {label:"Other",value:"Other"},
                {label:"None",value:"None"},
              ]}
            />
            <div className="extra-small-scroll-container">
              {siteStructureAmenities}
            </div>
          </Col>
        </Row>
        {
          props.amenities.map(
            (amenity, index)=>{
              return <StructureAmenity {...props} key={index} index={index} amenities={props.amenities} updateState={props.updateState}/>
            }
          )
        }
      </CardBody>
    </Card>
    <br/>
    <Card>
      <CardHeader className="header-color">
        Foundation & Exterior Wall
      </CardHeader>
      <CardBody>
        <Row style={{marginTop:'15px'}}>
          <Col sm="4">
            <label>{((props.structureType==='PrimaryDwelling'|| props.structureType==='ADU') || props.propertyType==='Condo')?<font color="red">*</font>:null}Foundation Type {generateTooltip('foundationTypes', 'Select all that apply.')}</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={((props.structureType==='PrimaryDwelling'|| props.structureType==='ADU') || props.propertyType==='Condo') && props.foundationTypes.length<=0}
              value={""}
              onChange={(v)=>updateField('foundationTypes',v)}
              options={[
                {label:"",value:""},
                {label:"Slab",value:"Slab"},
                {label:"Crawlspace",value:"Crawlspace"},
                {label:"Basement",value:"Basement"},
                {label:"Partial Basement",value:"PartialBasement"},
              ]}
            />
            <div className="extra-small-scroll-container">
              {foundationTypes}
            </div>
          </Col>
          <Col sm="4">
            <label><font color="red">*</font>Evidence of Dampness, Settlement and infestation conditions</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={true}
              value={props.evidenceOfCondition}
              onChange={(v)=>props.updateState({evidenceOfCondition:v})}
              options={[
                {label:"",value:""},
                {label:"Yes",value:"True"},
                {label:"No",value:"False"},
              ]}
            />
          </Col>
          {evidenceOfConditionDescription}
        </Row>
        <Row style={{marginTop:'15px'}}>
          <Col sm="4">
            <label>
              {
                (
                  (props.foundationTypes.indexOf('Basement')!==-1 || props.foundationTypes.indexOf('Partial Basement')!==-1 || props.foundationTypes.indexOf('Crawl Space')!==-1)||
                  (props.structureType==='PrimaryDwelling'|| props.structureType==='ADU'|| props.structureType==='Barn'|| props.structureType==='Detached Garage'||props.structureType==='Workshop'||props.structureType==='Storage'||props.structureType==='Other')
                )?<font color="red">*</font>:null
              }
              Foundation Wall Materials {generateTooltip('foundationWallMaterials', 'Select all that apply.')}</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={(
                (props.foundationTypes.indexOf('Basement')!==-1 || props.foundationTypes.indexOf('Partial Basement')!==-1 || props.foundationTypes.indexOf('Crawl Space')!==-1)||
                (props.structureType==='PrimaryDwelling'|| props.structureType==='ADU'|| props.structureType==='Barn'|| props.structureType==='Detached Garage'||props.structureType==='Workshop'||props.structureType==='Storage'||props.structureType==='Other')
              ) && props.foundationWallMaterials.length<=0}
              value={""}
              onChange={(v)=>updateField('foundationWallMaterials',v)}
              options={[
                {label:"",value:""},
                {label:"Concrete",value:"Concrete"},
                {label:"Concrete Block",value:"ConcreteBlock"},
                {label:"Brick",value:"Brick"},
                {label:"Stone",value:"Stone"},
                {label:"Other",value:"Other"},
              ]}
            />
            <div className="extra-small-scroll-container">
              {foundationWallMaterials}
            </div>
          </Col>
          {foundationWallMaterialDescription}
          <Col sm="4">
            <label>{
              (
                (props.foundationTypes.indexOf('Basement')!==-1 || props.foundationTypes.indexOf('Partial Basement')!==-1 || props.foundationTypes.indexOf('Crawl Space')!==-1)||
                (props.structureType==='PrimaryDwelling'|| props.structureType==='ADU'|| props.structureType==='Barn'|| props.structureType==='Detached Garage'||props.structureType==='Workshop'||props.structureType==='Storage'||props.structureType==='Other')
              )?<font color="red">*</font>:null
            }Foundation Wall Conditions {generateTooltip('foundationWallConditions', 'Select all that apply.')}</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={(
                (props.foundationTypes.indexOf('Basement')!==-1 || props.foundationTypes.indexOf('Partial Basement')!==-1 || props.foundationTypes.indexOf('Crawl Space')!==-1)||
                (props.structureType==='PrimaryDwelling'|| props.structureType==='ADU'|| props.structureType==='Barn'|| props.structureType==='Detached Garage'||props.structureType==='Workshop'||props.structureType==='Storage'||props.structureType==='Other')
                ) && props.foundationWallConditions.length<=0}
              value={""}
              onChange={(v)=>updateField('foundationWallConditions',v)}
              options={[
                {label:"",value:""},
                {label:"Eroded Material",value:"ErodedMaterial"},
                {label:"Cracking",value:"Cracking"},
                {label:"Settlement",value:"Settlement"},
                {label:"Evidence Of Moisture",value:"EvidenceOfMoisture"},
                {label:"Seepage",value:"Seepage"},
                {label:"Infestation",value:"Infestation"},
                {label:"Leaning Or Bowed Walls",value:"LeaningOrBowedWalls"},
                {label:"None",value:"None"},
              ]}
            />
            <div className="extra-small-scroll-container">
              {foundationWallConditions}
            </div>
          </Col>
        </Row>
        <Row style={{marginTop:'15px'}}>
          <Col sm="12">
            <label><font color="red">*</font>Foundation Wall Photos</label>
            <br/>
            <MyDropzone hidden={props.disabled} onDrop={(accepted, rejected)=>{onDrop(accepted, rejected, 'foundationWallPhotos', 'Foundation Wall', 0)}} noIcon={true}/>
            {
              props.foundationWallPhotos.map(
                (photo, index)=>{
                  return <div key={index} className="display-inline" style={{width:200, height:250, margin:5}}>
                    <div>
                      <i hidden={props.disabled} className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeFile('foundationWallPhotos',index)}/> {photo.name.length>16?photo.name.substr(0,16)+'...':photo.name}
                    </div>
                    <img alt="pic" width="200" height="200" src={photo.status==='Pending'?"data:image/png;base64, "+photo.base64:photo.presign_url}/>
                  </div>
                }
              )
            }
          </Col>
        </Row>
        <br/>
        <Row style={{marginTop:'15px'}}>
          <Col sm="4">
            <label><font color="red">*</font>Exterior Wall Materials {generateTooltip('exteriorWallMaterials', 'Select all that apply.')}</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={props.exteriorWallMaterials.length<=0}
              value={""}
              onChange={(v)=>updateField('exteriorWallMaterials',v)}
              options={[
                {label:"",value:""},
                {label:"Brick",value:"Brick"},
                {label:"Stucco",value:"Stucco"},
                {label:"Aluminum Siding",value:"AluminumSiding"},
                {label:"Wood Shake",value:"WoodShake"},
                {label:"Vinyl Siding",value:"VinylSiding"},
                {label:"Other Siding",value:"OtherSiding"},
                {label:"Other Masonry",value:"OtherMasonry"},
                {label:"Concrete",value:"Concrete"},
                {label:"Brick Veneer",value:"BrickVeneer"},
                {label:"Stone Veneer",value:"StoneVeneer"},
                {label:"Stone",value:"Stone"},
                {label:"Glass",value:"Glass"},
                {label:"Steel",value:"Steel"}
              ]}
            />
            <div className="extra-small-scroll-container">
              {exteriorWallMaterials}
            </div>
          </Col>
          {exteriorWallMaterialDescription}
          <Col sm="4">
            <label>{(props.structureType==='PrimaryDwelling' || props.structureType==='ADU')?<font color="red">*</font>:null}Exterior Wall Conditions {generateTooltip('exteriorWallConditions', 'Select all that apply.')}</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={(props.structureType==='PrimaryDwelling' || props.structureType==='ADU') && props.exteriorWallConditions.length<=0}
              value={""}
              onChange={(v)=>updateField('exteriorWallConditions',v)}
              options={[
                {label:"",value:""},
                {label:"Eroded Material",value:"ErodedMaterial"},
                {label:"Cracking",value:"Cracking"},
                {label:"Holes",value:"Holes"},
                {label:"Dry Rot",value:"DryRot"},
                {label:"Moisture Rot",value:"MoistureRot"},
                {label:"Settlement",value:"Settlement"},
                {label:"Wind Damage",value:"WindDamage"},
                {label:"Separation",value:"Separation"},
                {label:"Missing Materia",value:"MissingMaterial"},
                {label:"None",value:"None"}
              ]}
            />
            <div className="extra-small-scroll-container">
              {exteriorWallConditions}
            </div>
          </Col>
        </Row>
        <Row style={{marginTop:'15px'}}>
          <Col sm="12">
            <label><font color="red">*</font>Exterior Wall Photos</label>
            <br/>
            <MyDropzone hidden={props.disabled} onDrop={(accepted, rejected)=>{onDrop(accepted, rejected, 'exteriorWallPhotos', 'Exterior Wall', 0)}} noIcon={true}/>
            {
              props.exteriorWallPhotos.map(
                (photo, index)=>{
                  return <div key={index} className="display-inline" style={{width:200, height:250, margin:5}}>
                    <div>
                      <i hidden={props.disabled} className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeFile('exteriorWallPhotos',index)}/> {photo.name.length>16?photo.name.substr(0,16)+'...':photo.name}
                    </div>
                    <img alt="pic" width="200" height="200" src={photo.status==='Pending'?"data:image/png;base64, "+photo.base64:photo.presign_url}/>
                  </div>
                }
              )
            }
          </Col>
        </Row>
      </CardBody>
    </Card>
    <br/>
    <Card>
      <CardHeader className="header-color">
        Roof / Window / Gutter
      </CardHeader>
      <CardBody>
        <br/>
        <Row style={{marginTop:'15px'}}>
          <Col sm="3">
            <label><font color="red">*</font>Roof Surface Materials {generateTooltip('roofMaterials', 'Select all that apply.')}</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={props.roofMaterials.length<=0}
              value={""}
              onChange={(v)=>updateField('roofMaterials',v)}
              options={[
                {label:"",value:""},
                {label:"Composition",value:"Composition"},
                {label:"Wood Shake",value:"WoodShake"},
                {label:"Slate",value:"Slate"},
                {label:"Tile",value:"Tile"},
                {label:"Other Shingle",value:"OtherShingle"},
                {label:"Other Non Shingle",value:"OtherNonShingle"},
                {label:"Steel",value:"Steel"},
              ]}
            />
            <div className="extra-small-scroll-container">
              {roofMaterials}
            </div>
          </Col>
          {roofMaterialDescription}
          <Col sm="3">
            <label><font color="red">*</font>Roof Surface Conditions {generateTooltip('roofConditions', 'Select all that apply.')}</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={props.roofConditions.length<=0}
              value={""}
              onChange={(v)=>updateField('roofConditions',v)}
              options={[
                {label:"",value:""},
                {label:"Eroded Material",value:"ErodedMaterial"},
                {label:"Cracking",value:"Cracking"},
                {label:"Tarp On Roof",value:"TarpOnRoof"},
                {label:"Leak Evident",value:"LeakEvident"},
                {label:"Missing Material",value:"MissingMaterial"},
                {label:"Wood Rot",value:"WoodRot"},
                {label:"Soffit Damage",value:"SoffitDamage"},
                {label:"Fascia Damage",value:"FasciaDamage"},
                {label:"None",value:"None"},
              ]}
            />
            <div className="extra-small-scroll-container">
              {roofConditions}
            </div>
          </Col>
          <Col sm="3">
            <label><font color="red">*</font>Roof Age</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={true}
              value={props.roofAge}
              onChange={(v)=>props.updateState({roofAge:v})}
              options={[
                {label:"",value:""},
                {label:"0 - 1 Yr",value:"0 - 1 Yr"},
                {label:"Greater 1 to 10",value:"Greater 1 to 10"},
                {label:"Greater than 10 to 20",value:"Greater than 10 to 20"},
                {label:"Greater than 20+",value:"Greater than 20+"},
              ]}
            />
          </Col>
        </Row>
        <Row style={{marginTop:'15px'}}>
          <Col sm="12">
            <label><font color="red">*</font>Roof Surface Photos</label>
            <br/>
            <MyDropzone hidden={props.disabled} onDrop={(accepted, rejected)=>{onDrop(accepted, rejected, 'roofPhotos', 'Roof Surface', 0)}} noIcon={true}/>
            {
              props.roofPhotos.map(
                (photo, index)=>{
                  return <div key={index} className="display-inline" style={{width:200, height:250, margin:5}}>
                    <div>
                      <i hidden={props.disabled} className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeFile('roofPhotos',index)}/> {photo.name.length>16?photo.name.substr(0,16)+'...':photo.name}
                    </div>
                    <img alt="pic" width="200" height="200" src={photo.status==='Pending'?"data:image/png;base64, "+photo.base64:photo.presign_url}/>
                  </div>
                }
              )
            }
          </Col>
        </Row>
        <br/>
        <Row style={{marginTop:'15px'}}>
          <Col sm="4">
            <label><font color="red">*</font>Gutter & Downspout Materials {generateTooltip('gutterAndDownspoutMaterials', 'Select all that apply.')}</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={props.gutterAndDownspoutMaterials.length<=0}
              value={""}
              onChange={(v)=>updateField('gutterAndDownspoutMaterials',v)}
              options={[
                {label:"",value:""},
                {label:"Aluminium",value:"Aluminium"},
                {label:"Copper",value:"Copper"},
                {label:"Galvanized Steel",value:"GalvanizedSteel"},
                {label:"PVC",value:"PVC"},
                {label:"None",value:"None"},
                {label:"Other",value:"Other"},
              ]}
            />
            <div className="extra-small-scroll-container">
              {gutterAndDownspoutMaterials}
            </div>
          </Col>
          {gutterOtherDescription}
          <Col sm="4">
            <label><font color="red">*</font>Gutter & Downspout Conditions {generateTooltip('gutterAndDownspoutConditions', 'Select all that apply.')}</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={props.gutterAndDownspoutConditions.length<=0}
              value={""}
              onChange={(v)=>updateField('gutterAndDownspoutConditions',v)}
              options={[
                {label:"",value:""},
                {label:"Eroded Material",value:"ErodedMaterial"},
                {label:"Cracking",value:"Cracking"},
                {label:"Missing Downspouts",value:"MissingDownspouts"},
                {label:"Damaged",value:"Damaged"},
                {label:"Leaking",value:"Leaking"},
                {label:"Infestation",value:"Infestation"},
                {label:"Improper Flow",value:"ImproperFlow"},
                {label:"None",value:"None"},
              ]}
            />
            <div className="extra-small-scroll-container">
              {gutterAndDownspoutConditions}
            </div>
          </Col>
        </Row>
        <Row style={{marginTop:'15px'}}>
          <Col sm="12">
            <label><font color="red">*</font>Gutter & Downspout Photos</label>
            <br/>
            <MyDropzone hidden={props.disabled} onDrop={(accepted, rejected)=>{onDrop(accepted, rejected, 'gutterAndDownspoutPhotos', 'Gutter Downspout', 0)}} noIcon={true}/>
            {
              props.gutterAndDownspoutPhotos.map(
                (photo, index)=>{
                  return <div key={index} className="display-inline" style={{width:200, height:250, margin:5}}>
                    <div>
                      <i hidden={props.disabled} className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeFile('gutterAndDownspoutPhotos',index)}/> {photo.name.length>16?photo.name.substr(0,16)+'...':photo.name}
                    </div>
                    <img alt="pic" width="200" height="200" src={photo.status==='Pending'?"data:image/png;base64, "+photo.base64:photo.presign_url}/>
                  </div>
                }
              )
            }
          </Col>
        </Row>
        <br/>
        <Row style={{marginTop:'15px'}}>
          <Col sm="3">
            <label><font color="red">*</font>Window Materials {generateTooltip('windowMaterials', 'Select all that apply.')}</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={props.windowMaterials.length<=0}
              value={""}
              onChange={(v)=>updateField('windowMaterials',v)}
              options={[
                {label:"",value:""},
                {label:"Multi Pane",value:"MultiPane"},
                {label:"Single Pane",value:"SinglePane"},
                {label:"Wood",value:"Wood"},
                {label:"Vinyl",value:"Vinyl"},
                {label:"Clad",value:"Clad"},
                {label:"Aluminum",value:"Aluminum"},
                {label:"Steel",value:"Steel"},
                {label:"Double Hung",value:"DoubleHung"},
                {label:"Single Hung",value:"SingleHung"},
                {label:"Casement",value:"Casement"},
                {label:"Sliding",value:"Sliding"},
                {label:"Awning",value:"Awning"},
                {label:"Thermopane",value:"Thermopane"},
                {label:"None",value:"None"}
              ]}
            />
            <div className="extra-small-scroll-container">
              {windowMaterials}
            </div>
          </Col>
          <Col sm="3">
            <label><font color="red">*</font>Window Conditions {generateTooltip('windowConditions', 'Select all that apply.')}</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={props.windowConditions.length<=0}
              value={""}
              onChange={(v)=>updateField('windowConditions',v)}
              options={[
                {label:"",value:""},
                {label:"Cracking",value:"Cracking"},
                {label:"Separation",value:"Separation"},
                {label:"Missing Window Pane",value:"MissingWindowPane"},
                {label:"Dry Rot",value:"DryRot"},
                {label:"Broken Glass",value:"BrokenGlass"},
                {label:"Boarded Up",value:"BoardedUp"},
                {label:"Missing Window",value:"MissingWindow"},
                {label:"Exposed Wood",value:"Exposed Wood"},
                {label:"Storm Sash Damage",value:"Storm Sash Damage"},
                {label:"Thermopane Damage",value:"Thermopane Damage"},
                {label:"None",value:"None"},
              ]}
            />
            <div className="extra-small-scroll-container">
              {windowConditions}
            </div>
          </Col>
          <Col sm="3">
            <label><font color="red">*</font>Window Updates</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={true}
              value={props.windowUpdates}
              onChange={(v)=>props.updateState({windowUpdates:v})}
              options={[
                {label:"",value:""},
                {label:"Fully Updated",value:"Fully Updated"},
                {label:"Partially Updated",value:"Partially Updated"},
                {label:"No Update",value:"No Update"},
              ]}
            />
          </Col>
          {windowUpdateDescription}
        </Row>
        <Row style={{marginTop:'15px'}}>
          <Col sm="12">
            <label><font color="red">*</font>Window Photos</label>
            <br/>
            <MyDropzone hidden={props.disabled} onDrop={(accepted, rejected)=>{onDrop(accepted, rejected, 'windowPhotos', 'Window', 0)}} noIcon={true}/>
            {
              props.windowPhotos.map(
                (photo, index)=>{
                  return <div key={index} className="display-inline" style={{width:200, height:250, margin:5}}>
                    <div>
                      <i hidden={props.disabled} className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeFile('windowPhotos',index)}/> {photo.name.length>16?photo.name.substr(0,16)+'...':photo.name}
                    </div>
                    <img alt="pic" width="200" height="200" src={photo.status==='Pending'?"data:image/png;base64, "+photo.base64:photo.presign_url}/>
                  </div>
                }
              )
            }
          </Col>
        </Row>
        <br/>
      </CardBody>
    </Card>
    <br/>
    <Card>
      <CardHeader className="header-color">
        Other
      </CardHeader>
      <CardBody>
        <Row style={{marginTop:'15px'}}>
          <Col sm="4">
            <label>{(props.structureType==='PrimaryDwelling' || props.structureType==='ADU')?<font color="red">*</font>:null}Storm Sash {generateTooltip('stormSash', 'Indication of whether any storm sash/insulation is present.')}</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={(props.structureType==='PrimaryDwelling' || props.structureType==='ADU')}
              value={props.stormSash}
              onChange={(v)=>props.updateState({stormSash:v})}
              options={[
                {label:"",value:""},
                {label:"Yes",value:"True"},
                {label:"No",value:"False"},
              ]}
            />
          </Col>
          <Col sm="4">
            <label>{(props.structureType==='PrimaryDwelling' || props.structureType==='ADU')?<font color="red">*</font>:null}Screens {generateTooltip('screens','Indication of whether any screens are present.')}</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={(props.structureType==='PrimaryDwelling' || props.structureType==='ADU')}
              value={props.screens}
              onChange={(v)=>props.updateState('screens',v)}
              options={[
                {label:"",value:""},
                {label:"Yes",value:"True"},
                {label:"No",value:"False"},
              ]}
            />
          </Col>
        </Row>
        <br/>
        <Row style={{marginTop:'15px'}}>
          <Col sm="4">
            <label><font color="red">*</font>Utility Type{generateTooltip('utilityType','The types of utilities available at the property and the public or private status of the entity that supplies each one.')}</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={props.utilities.length<=0}
              value={""}
              onChange={(v)=>props.addNewItem('utilities', v, 'utilityType', {utilitySubType:'', utilitySupplier:''})}
              options={[
                {label:"",value:""},
                {label:"Electricity",value:"Electricity"},
                {label:"Gas",value:"Gas"},
                {label:"Oil",value:"Oil"},
                {label:"Water",value:"Water"},
                {label:"Sanitary Sewer",value:"SanitarySewer"},
              ]}
            />
            <div className="extra-small-scroll-container">
              {utilities}
            </div>
          </Col>
        </Row>
        {
          props.utilities.map(
            (utility, index)=>{
              return <Utility {...props} key={index} index={index} utilities={props.utilities} updateState={props.updateState}/>
            }
          )
        }
        <Row style={{marginTop:'15px'}}>
          <Col sm="4">
            <label><font color="red">*</font>Street Alley Types {generateTooltip('streetAlleyTypes', 'Select all that apply.')}</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={props.streetAlleyTypes.length<=0}
              value={""}
              onChange={(v)=>updateField('streetAlleyTypes',v)}
              options={[
                {label:"",value:""},
                {label:"Street",value:"Street"},
                {label:"Alley",value:"Alley"},
                {label:"None",value:"None"},
              ]}
            />
            <div className="extra-small-scroll-container">
              {streetAlleyTypes}
            </div>
          </Col>
          <Col sm="4">
            <label>{(props.streetAlleyTypes.indexOf('Street')!==-1 || props.streetAlleyTypes.indexOf('Alley')!==-1)?<font color="red">*</font>:null}Street Alley Materials {generateTooltip('streetAlleyMaterials', 'Select all that apply.')}</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={(props.streetAlleyTypes.indexOf('Street')!==-1 || props.streetAlleyTypes.indexOf('Alley')!==-1) && props.streetAlleyMaterials.length<=0}
              value={""}
              onChange={(v)=>updateField('streetAlleyMaterials',v)}
              options={[
                {label:"",value:""},
                {label:"Dirt",value:"Dirt"},
                {label:"Gravel",value:"Gravel"},
                {label:"Concrete",value:"Concrete"},
                {label:"Asphalt",value:"Asphalt"},
                {label:"Other",value:"Other"},
              ]}
            />
            <div className="extra-small-scroll-container">
              {streetAlleyMaterials}
            </div>
          </Col>
          <Col sm="4">
            <label>{(props.streetAlleyTypes.indexOf('Street')!==-1 || props.streetAlleyTypes.indexOf('Alley')!==-1)?<font color="red">*</font>:null}Street Alley Ownership</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={(props.streetAlleyTypes.indexOf('Street')!==-1 || props.streetAlleyTypes.indexOf('Alley')!==-1)}
              value={props.streetAlleyOwnership}
              onChange={(v)=>props.updateState({streetAlleyOwnership:v})}
              options={[
                {label:"",value:""},
                {label:"Public",value:"Public"},
                {label:"Private",value:"Private"},
                {label:"Unknown",value:"Unknown"},
              ]}
            />
          </Col>
        </Row>
        <Row style={{marginTop:'15px'}}>
          <Col sm="4">
            <label><font color="red">*</font>Site Conditions {generateTooltip('siteConditions', 'Select all that apply.')}</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={props.siteConditions.length<=0}
              value={""}
              onChange={(v)=>updateField('siteConditions',v)}
              options={[
                {label:"",value:""},
                {label:"Extreme Slope",value:"ExtremeSlope"},
                {label:"Erosions",value:"Erosions"},
                {label:"Sink Hole",value:"SinkHole"},
                {label:"Wet Lands",value:"WetLands"},
                {label:"Substantial Junk Or Trash",value:"SubstantialJunkOrTrash"},
                {label:"Failing Structure",value:"FailingStructure"},
                {label:"Easement",value:"Easement"},
                {label:"Encroachment",value:"Encroachment"},
                {label:"Environmental Conditions",value:"EnvironmentalConditions"},
                {label:"Land Uses",value:"LandUses"},
                {label:"Other",value:"Other"},
                {label:"None",value:"None"},
              ]}
            />
            <div className="extra-small-scroll-container">
              {siteConditions}
            </div>
          </Col>
          {siteConditionDescription}
          <Col sm="8">
            <label>Site Common Amenities {generateTooltip('siteCommonAmenities', 'Select all that apply.')}</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              value={""}
              onChange={(v)=>props.addNewItem('commonAmenities', v, 'amenityType', {amenityTypeOtherDescription:'', amenitySubTypes:[], amenityCount:''})}
              options={[
                {label:"",value:""},
                {label:"Fence",value:"Fence"},
                {label:"Irrigation System",value:"IrrigationSystem"},
                {label:"Water Collection System",value:"WaterCollectionSystem"},
                {label:"Elaborate Landscaping",value:"ElaborateLandscaping"},
                {label:"In Ground Pool",value:"InGroundPool"},
                {label:"Concrete Slab",value:"ConcreteSlab"},
                {label:"Sport Court",value:"SportCourt"},
                {label:"Gazebo",value:"Gazebo"},
                {label:"Sauna",value:"Sauna"},
                {label:"Spa",value:"Spa"},
                {label:"Above Ground Pool",value:"AboveGroundPool"},
                {label:"Party Room",value:"Party Room"},
                {label:"Office Space",value:"Office"},
                {label:"Space",value:"Space"},
                {label:"Exercise Room",value:"Exercise Room"},
                {label:"Laundry",value:"Laundry"},
                {label:"Doorman",value:"Doorman"},
                {label:"Commerical Services",value:"Commerical Services"},
                {label:"Other",value:"Other"}
              ]}
            />
            <div className="extra-small-scroll-container">
              {siteCommonAmenities}
            </div>
          </Col>
        </Row>
        {
          props.commonAmenities.map(
            (amenity, index)=>{
              return <CommonAmenity {...props} key={index} index={index} commonAmenities={props.commonAmenities} updateState={props.updateState}/>
            }
          )
        }
        <Row style={{marginTop:'15px'}}>
          <Col sm="4">
            <label><font color="red">*</font>Does the property adjoin to any external factors? {generateTooltip('adjoinExternalFactors', 'Select all that apply.')}</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={props.adjoinExternalFactors.length<=0}
              value={""}
              onChange={(v)=>updateField('adjoinExternalFactors',v)}
              options={[
                {label:"",value:""},
                {label:"Residential",value:"Residential"},
                {label:"Industrial",value:"Industrial"},
                {label:"Commercial",value:"Commercial"},
                {label:"Landfill",value:"Landfill"},
                {label:"Waterfront",value:"Waterfront"},
                {label:"Airport",value:"Airport"},
                {label:"Golf Course",value:"GolfCourse"},
                {label:"Park",value:"Park"},
                {label:"Power Lines",value:"PowerLines"},
                {label:"Major Utility",value:"MajorUtility"},
                {label:"Public Transportation",value:"PublicTransportation"},
                {label:"Railroad",value:"Railroad"},
                {label:"Major Highway",value:"MajorHighway"},
                {label:"School",value:"School"},
                {label:"Greenbelt",value:"Greenbelt"},
                {label:"Other",value:"Other"},
              ]}
            />
            <div className="extra-small-scroll-container">
              {adjoinExternalFactors}
            </div>
          </Col>
          {adjoinExternalFactorDescription}
        </Row>
      </CardBody>
    </Card>
    <br/>

    <Card>
      <CardHeader className="header-color">
        Energy
      </CardHeader>
      <CardBody>
        <Row style={{marginTop:'15px'}}>
          <Col sm="4">
            <label><font color="red">*</font>Energy/Solar Rating Performed</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={true}
              value={props.energyRatingPerformed}
              onChange={(v)=>props.updateState({energyRatingPerformed:v})}
              options={[
                {label:"",value:""},
                {label:"Yes",value:"True"},
                {label:"No",value:"False"},
              ]}
            />
          </Col>
          <Col sm="4">
            <label><font color="red">*</font>Energy/Solar Rating Organization</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={true}
              value={props.energyRatingOrganization}
              onChange={(v)=>props.updateState({energyRatingOrganization:v})}
              options={[
                {label:"",value:""},
                {label:"RESNET'S HERS",value:"RESNETS HERS"},
                {label:"DOE's Home Energy Score",value:"DOE's Home Energy Score"},
                {label:"Other",value:"Other"},
              ]}
            />
          </Col>
          {energyRatingDescription}
        </Row>
        <Row style={{marginTop:'15px'}}>
          <Col sm="12">
            <label><font color="red">*</font>Energy Rating Performed Photos</label>
            <br/>
            <MyDropzone hidden={props.disabled} onDrop={(accepted, rejected)=>{onDrop(accepted, rejected, 'energyRatingPerformedPhotos', 'Energy Rating', 0)}} noIcon={true}/>
            {
              props.energyRatingPerformedPhotos.map(
                (photo, index)=>{
                  return <div key={index} className="display-inline" style={{width:200, height:250, margin:5}}>
                    <div>
                      <i hidden={props.disabled} className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeFile('energyRatingPerformedPhotos',index)}/> {photo.name.length>16?photo.name.substr(0,16)+'...':photo.name}
                    </div>
                    <img alt="pic" width="200" height="200" src={photo.status==='Pending'?"data:image/png;base64, "+photo.base64:photo.presign_url}/>
                  </div>
                }
              )
            }
          </Col>
        </Row>
        <Row style={{marginTop:'15px'}}>
          <Col sm="4">
            <label><font color="red">*</font>Renewable Energy Present</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={true}
              value={props.renewableEnergyPresent}
              onChange={(v)=>props.updateState({renewableEnergyPresent:v})}
              options={[
                {label:"",value:""},
                {label:"Yes",value:"True"},
                {label:"No",value:"False"},
              ]}
            />
          </Col>
          <Col sm="4">
            <label>{props.renewableEnergyPresent==='True'?<font color="red">*</font>:null}Identify Renewable Energy Component</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={props.renewableEnergyPresent==='True'}
              value={props.renewableEnergyComponent}
              onChange={(v)=>props.updateState({renewableEnergyComponent:v})}
              options={[
                {label:"",value:""},
                {label:"Geothermal",value:"Geothermal"},
                {label:"Solar Panel",value:"Solar Panel"},
                {label:"Wind Turbine",value:"Wind Turbine"},
                {label:"Other",value:"Other"},
              ]}
            />
          </Col>
          {renewableEnergyComponentDescription}
          {solarPanelOwnership}
        </Row>
        {renewableEnergyPhoto}
      </CardBody>
    </Card>
    <br/>
    <Card>
      <CardHeader className="header-color">
        Car Storage
      </CardHeader>
      <CardBody>
        <Row style={{marginTop:'15px'}}>
          <Col sm="4">
            <label><font color="red">*</font>Car Storage Indicator</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={true}
              value={props.carStorageIndicator}
              onChange={(v)=>{props.updateState({carStorageIndicator:v});if(v==="False")props.updateState({parkings:[]})}}
              options={[
                {label:"",value:""},
                {label:"Yes",value:"True"},
                {label:"No",value:"False"},
              ]}
            />
          </Col>
          {onSiteParkingTypes}
        </Row>
        {
          props.parkings.map(
            (parking, index)=>{
              return <Parking disabled={props.disabled} key={index} index={index} parkings={props.parkings} updateState={props.updateState}/>
            }
          )
        }
        <Row>
          {guestParking}
          {/* {guestParkingType} */}
          {guestParkingNumber}
        </Row>
      </CardBody>
    </Card>
  </div>;
}


export default StepSite;
