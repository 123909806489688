//Author Sooyoung Kim
//Date June 8, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, formatNumber, formatDateTime} from '../../../util/util';
import {Button, Card, CardHeader, CardBody, Col, Row} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import moment from 'moment';
import {NavLink} from 'react-router-dom';
import MyReactTable from '../../util/my-react-table';
import ReportFilter from '../report-filter';
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

let today = new Date();
let month = today.getMonth()+1;

if(month<10)
  month = '0'+month;
let from = today.getFullYear()+'-'+month+'-'+today.getDate();
let to = today.getFullYear()+'-'+month+'-'+today.getDate();

//initialize the state
const initialState = {
  clients:[],
  report:[],
  client:-1,
  from:from,
  to:to,
};

//reducer function that perform state update
const reducer = getReducer();


const AnticipatedValueReport  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getReportDetails();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //API call
  const getReportDetails = ()=>{
    httpGet('report/anticipated/get/from='+state.from+'&to='+state.to+'&client='+state.client, '', 'Oops, something went wrong and could not load the report. Please try again later.', apiCallBack([{state:'report', key:'data'}]));
  }

  //render
  let newfrom, newto;
  if(state.from!=='')
    newfrom = moment(state.from);
  if(state.to!=='')
    newto = moment(state.to);

  const columns = [
    {
      id: 'referenceNum',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'loanNum',
      Header: 'Loan #',
      accessor: d => d.loan_num,
      Cell: props=> props.row.original.loan_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'borrower',
      Header: 'Borrower',
      accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'address',
      Header: 'Address',
      accessor: d => d.property_street+' '+d.property_city+', '+d.property_state,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'appraisedValue',
      Header: 'Appraised',
      accessor: d => '$'+formatNumber(d.appraised_value),
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'anticipatedValue',
      Header: 'Anticipated',
      accessor: d => '$'+formatNumber(d.estimated_value),
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'datetimeUpload',
      Header: 'Report Uploaded',
      accessor: d => formatDateTime(d.datetime_appraisal_uploaded),
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  let reportFiltersConfig = [
    {id:'from',value:newfrom, updateFunc:(v)=>setState({from:v}), width:'2'},
    {id:'to',value:newto, updateFunc:(v)=>setState({to:v}), width:'2'},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'5'},
    {id:'button',value:'Submit', updateFunc:getReportDetails, width:'3', className:"align-right", color:"warning"},
  ];

  return <div>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Anticipated Value Report
      </CardHeader>
      <CardBody>
        <ReportFilter {...props} configs={reportFiltersConfig}/>
        <div className="my-divider">&nbsp;</div>
        <Card>
          <CardHeader className="gray-color">
            <Row>
              <Col sm="8">
                Orders
              </Col>
              <Col sm="4">
                <div className="align-right">
                  <ExcelFile element={<Button disabled={state.report.length<=0} color="warning">Download Excel</Button>}>
                    <ExcelSheet data={state.report} name="XLSX">
                        <ExcelColumn label="Reference #" value="reference_num"/>
                        <ExcelColumn label="Loan #" value="loan_num"/>
                        <ExcelColumn label="Borrower" value={(col)=>{return col.borrower_f_name+' '+col.borrower_l_name}}/>
                        <ExcelColumn label="Address" value={(col)=>{return col.property_street+' '+col.property_city+', '+col.property_state}}/>
                        <ExcelColumn label="Appraised" value={(col)=>'$'+formatNumber(col.appraised_value)}/>
                        <ExcelColumn label="Anticipated" value={(col)=>'$'+formatNumber(col.estimated_value)}/>
                        <ExcelColumn label="Report Uploaded" value={(col)=>formatDateTime(col.datetime_appraisal_uploaded)}/>
                    </ExcelSheet>
                  </ExcelFile>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <div className="large-scroll-container">
              <MyReactTable columns={columns} data={state.report} className="table table-striped"/>
            </div>
          </CardBody>
        </Card>
      </CardBody>
    </Card>
  </div>;
}

export default AnticipatedValueReport;
