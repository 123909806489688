//Author Sooyoung Kim
//Date Dec 5, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, deleteAPICallGenerator, callBackGenerator, formatDate, formatNumber, confirmation, encodeParam, sliceFromArray} from '../../util/util';
import InfiniteScroll from 'react-infinite-scroll-component';
import {Button, Row, Col,Input} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import MySelect from '../util/my-select';

//initialize the state
const initialState = {
  profiles:[],
  hasMoreProfiles:true,
  limit: 50,
  offset: 0,
  loading: false,
  sort: 'name',
  order: 'ASC',
  keyword:'',
  city:'',
  state:'',
  totalCount:0,

  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
};

//reducer function that perform state update
const reducer = getReducer();


const BrokerRelationshipProfiles  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    if(state.profiles.length<=0 && state.hasMoreProfiles){
      //loadMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state]);

  //non API call but simpyl manage state
  //this function refresh the list of orders pipeline
  const refreshList = () => {
    setState({profiles:[], hasMoreProfiles:true, offset:0});
  }

  //click handler that switch the state of sorting order then call the function to refresh the list
  //to achieve the "sorting"\
  const columnClickHandler = (col) => {
    if(state.sort===col){
      if(state.order==='ASC')
        setState({order:'DESC'});
      else
        setState({order:'ASC'});
    }
    else
      setState({sort:col});
    refreshList();
  }

  //this function render the sort icon at each column of the table
  const renderSortIcon = (col) => {
    if(state.sort===col){
      if(state.order==='ASC')
        return <i className="red-color fa fa-arrow-down"></i>
      else
        return <i className="red-color fa fa-arrow-up"></i>
    }
  }

  //render function for infinite scroller
  const renderProfiles = () => {
    console.log(state.profiles);
    return (
      <div className="my-well">
        <table className="primary-table">
          <thead>
            <tr>
              <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('name')}><i className="fa fa-user"></i>&nbsp;Name {renderSortIcon('name')}</th>
              <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('phone')}><i className="fa fa-phone"></i>&nbsp;Phone {renderSortIcon('phone')}</th>
              <th className="cursor-pointer" width="20%" onClick={()=>columnClickHandler('street')}><i className="fa fa-map"></i>&nbsp;Street {renderSortIcon('street')}</th>
              <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('city')}><i className="fa fa-map"></i>&nbsp;City {renderSortIcon('city')}</th>
              <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('state')}><i className="fa fa-map"></i>&nbsp;State {renderSortIcon('state')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('datetime_updated')}><i className="fa fa-map"></i>&nbsp;Updated {renderSortIcon('datetime_updated')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('datetime_created')}><i className="fa fa-calendar-o"></i>&nbsp;Date Created {renderSortIcon('datetime_created')}</th>
            </tr>
          </thead>
          <tbody>
          {
            state.profiles.map(
              (profile)=>{
                return (
                  <tr key={profile.ID} onClick={()=>props.history.push("/broker-relationship/"+profile.ID)}>
                    <td><i className="fa fa-times red-color cursor-pointer" onClick={
                      (e)=>{
                        e.stopPropagation();
                        confirmation(
                          ()=>{deleteProfile(profile.ID)},
                          ()=>{},
                          'Delete profile?',
                          'Are you sure you want to delete this profile?');
                      }
                    }></i> {profile.name}</td>
                    <td>{profile.phone}</td>
                    <td>{profile.street}</td>
                    <td>{profile.city}</td>
                    <td>{profile.state}</td>
                    <td>{formatDate(profile.datetime_updated)}</td>
                    <td>{formatDate(profile.datetime_created)}</td>
                  </tr>
                );
              }
            )
          }
          </tbody>
        </table>
      </div>
    )
  }

  //API call
  //function that fire when the infinite scroll reach bottom
  const loadMore = () => {
    //do not load if there is no more conditions or it's loading data
    if(state.hasMoreProfiles&&!state.loading){
      //set loading equals to true so it won't fire off before we are done
      setState({loading:true});

      let keyword = encodeParam(state.keyword);
      let city = encodeParam(state.city);

      let url = 'brokerRelationship/get/limit='+state.limit+'&offset='+state.offset+'&keyword='+keyword+'&city='+city+'&state='+state.state+'&sort='+state.sort+'&order='+state.order;

      //callback handler that update the state when http request return
      let callBack = (response)=>{
        let code = response.data.code;

        if(code!=='00'){
          setState({hasMoreProfiles:false});
        }
        else{
          let newProfiles = response.data.data;
          let hasMoreProfiles = true;
          let newOffset = state.offset;
          let totalCount = response.data.count;

          //if http request return empty then no more results, end of list

          if(newProfiles.length<=0){
            hasMoreProfiles = false;
          }
          else{
            //increment the offset
            newOffset = state.offset + 1;
          }

          //concat the current array of announcement
          if(state.profiles.length>0){
            let temp = [...state.profiles,...newProfiles];

            setState({profiles:temp});
          }
          else
            setState({profiles:newProfiles});

          setState({hasMoreProfiles:hasMoreProfiles, offset:newOffset, totalCount:totalCount});
        }
      };
      callBack = callBack.bind(this);

      //error handler when the http request return with error
      let errorCallBack = apiCallBack([{state:'hasMoreProfiles', value:false}]);

      //collect the promise and wait for it to finish performing it's task
      let promises = httpGet(url, '', 'Oops, something went wrong and could not load broker relationship profile. Please try again later.', callBack, errorCallBack);
      promises
        .then(
          function(result){
            //set loading equals to false so the function could be fire off once again
            setState({loading:false});
          }
        );
    }
  }

  const deleteProfile = (id) => {
    let list = sliceFromArray(state.profiles,'ID', id);
    let callBack = apiCallBack([{state:'profiles', value:list}]);

    httpDelete('brokerRelationship/'+id,'Deleted succesfully.','Oops, something went wrong and could not delete the record. Please try again later.', callBack);
  }

  //render
  return <div>
    <InfiniteScroll
      next={loadMore}
      dataLength={state.profiles.length}
      hasMore={state.hasMoreProfiles}
      loader={<div key="nill" className="loader"><center>Loading more profiles...</center></div>}
      initialLoad = {true}
      className="my-well"
      scrollableTarget="contentContainer"
    >
      <div className="page-title">
        <i className="fa fa-reorder"></i>&nbsp;Relationship Profiles
      </div>
      <i>
        <font color="red">*</font>The relationship hierachy goes by a broker company that will have one or more brokers and each will have AE/Loan Officer/Loan Processor that associated with it.<br/>
        <font color="red">*</font>To add a new broker, first create it's broker company profile then you can add them to the profile.<br/>
        <font color="red">*</font>To add a new AE/Loan Officer/Loan Processor, first you locate the broker company profile then you add them.
      </i>
      <Row>
        <Col sm="3">
          <label className="no-margin-bottom">Keyword</label>
          <Input className="form-control" value={state.keyword} type="text" onChange={(value)=>{setState({keyword:value.target.value})}}/>
        </Col>
        <Col sm="3">
          <label className="no-margin-bottom">City</label>
          <Input className="form-control" value={state.city} type="text" onChange={(value)=>{setState({city:value.target.value})}}/>
        </Col>
        <Col sm="3">
          <label className="no-margin-bottom">State</label>
          <MySelect
            type="select"
            value={state.state}
            onChange={(v)=>setState({state:v})}
            options={state.states.map((state)=>{
              return {label:state.key, value:state.key};
            })}
          />
        </Col>
        <Col sm="3" className="align-right valign-bottom">
          <br/>
          <Button color="warning" onClick={()=>refreshList()} >Submit</Button>
        </Col>
      </Row>
      <div className="my-divider"></div>
      <Row>
        <Col sm="6">
            <div>{formatNumber(state.totalCount)} profiles in queue<br/><br/></div>
        </Col>
        <Col sm="6" className="align-right">
          <NavLink to="/broker-relationship/new"><i className="fa fa-plus green-color"></i> Create new profile</NavLink>
        </Col>
      </Row>

      {renderProfiles()}
    </InfiniteScroll>
  </div>;
}


export default BrokerRelationshipProfiles;
