//Author Sooyoung Kim
//Date April 27, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, putAPICallGenerator, callBackGenerator, getRoles} from '../../util/util';
import InfiniteScroll from 'react-infinite-scroll-component';
import {NavLink} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';
import {Card, CardHeader, CardBody, Row, Col} from 'reactstrap';
import {showMessage} from '../../util/util';
import './announcement.css';

//initialize the state
const initialState = {
  announcements:[],
  limit: 25,
  offset: 0,
  hasMoreAnnouncements: true,
  loading: false,
};

//reducer function that perform state update
const reducer = getReducer();


const Announcements  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    if(state.announcements.length<=0 && state.hasMoreAnnouncements){
        loadMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state]);

  //non API call but simpyl manage state
  //render function for infinite scroller
  const renderAnnouncements = () =>{
    let roles = getRoles();

    return state.announcements.map(
      (announcement)=>{
        let adminAccess, clientAccess, appraiserAccess;

        if(announcement.admin_access==='1')
          adminAccess = <div className="display-inline"><div className="square-box">Admin</div>&nbsp;</div>;

        if(announcement.client_access==='1')
          clientAccess = <div className="display-inline"><div className="square-box">Client</div>&nbsp;</div>;

        if(announcement.appraiser_access==='1')
          appraiserAccess = <div className="display-inline"><div className="square-box">Appraiser</div>&nbsp;</div>;

        let icon = <div className="display-inline" style={{minWidth:'7px'}}></div>;

        if(announcement.unread === true){
          icon = <div className="display-inline flashit red-color" style={{fontSize:'13px',minWidth:'7px'}}>
            &nbsp;NEW
          </div>;
        }

        let announcementHeader =
        <Row>
          <Col sm="6">
            <b>{announcement.title}</b>&nbsp;{icon}
          </Col>
          <Col className="align-right">
            <b>{announcement.creator}&nbsp;-&nbsp;{announcement.datetime_created}</b>
          </Col>
        </Row>

        if(roles.indexOf('admin_manager')!==-1||roles.indexOf('super_admin')!==-1)
          announcementHeader =
          <NavLink to={'/announcement/'+announcement.ID}>
            <Row>
              <Col sm="6">
                <b>{announcement.title}</b>&nbsp;{icon}
              </Col>
              <Col className="align-right">
                <b>{announcement.creator}&nbsp;-&nbsp;{announcement.datetime_created}</b>
              </Col>
            </Row>
          </NavLink>;

        return(
          <div key={announcement.ID}>
            <Card>
              <CardHeader className="header-color">
                {announcementHeader}
              </CardHeader>
              <CardBody>
                <div dangerouslySetInnerHTML={{__html:announcement.body}}/>
              </CardBody>
              <div className="align-right">
                {adminAccess}{appraiserAccess}{clientAccess}
              </div>
            </Card>
            <br/>
          </div>
        );
      }
    );
  }

  //API call
  const updateSeenIndex = () =>{
    httpPut('announcement/seenIndex', [], '', '');
  }

  //function that fire when the infinite scroll reach bottom
  const loadMore = () =>{
    //do not load if there is no more announcement or it's loading data
    if(state.hasMoreAnnouncements&&!state.loading){
      //set loading equals to true so it won't fire off before we are done
      setState({loading:true});

      //callback handler that update the state when http request return
      let callBack = (response)=>{
        let code = response.data?response.data.code:undefined;

        if(code!=='00'){
          setState({hasMoreAnnouncements:false});
        }
        else{
          let newAnnouncements = response.data.data;
          let hasMoreAnnouncements = true;
          let newOffset = state.offset;

          //if http request return empty then no more results, end of list
          if(newAnnouncements.length<=0){
            hasMoreAnnouncements = false;
            showMessage('info','No more announcement.');
          }
          else{
            if(state.offset===0)
              updateSeenIndex();
            //increment the offset
            newOffset = state.offset + 1;
          }

          //concat the current array of announcement
          if(state.announcements.length>0){
            let temp = [...state.announcements,...newAnnouncements];

            setState({announcements:temp});
          }
          else
            setState({announcements:newAnnouncements});

          setState({hasMoreAnnouncements:hasMoreAnnouncements, offset:newOffset});
        }
      };
      callBack = callBack.bind(this);

      //error handler when the http request return with error
      let errorCallBack = apiCallBack([{state:'hasMoreAnnouncements', value:false}]);

      //collect the promise and wait for it to finish performing it's task
      let promises = httpGet('announcement/get/limit='+state.limit+'&offset='+state.offset, '','Oops, something went wrong and could not load announcements. Please try again later.', callBack, errorCallBack);
      promises
        .then(
          function(result){
            //set loading equals to false so the function could be fire off once again
            setState({loading:false});
          }
        );
    }
  }

  //render
  let roles = getRoles();

  let createNewAnnouncementLink;
  if(roles.indexOf('admin_manager')!==-1||roles.indexOf('super_admin')!==-1)
    createNewAnnouncementLink = <div className="align-right"><NavLink to={'/announcement/new'}><i className="fa fa-plus"></i>&nbsp;Create new announcement.</NavLink><br/><br/></div>;

  return <div>
    <InfiniteScroll
      next={loadMore}
      dataLength={state.announcements.length}
      hasMore={state.hasMoreAnnouncements}
      loader={<div key="nill" className="loader"><center>Loading more announcements...</center></div>}
      initialLoad = {true}
      className="my-well"
      scrollableTarget="contentContainer"
    >
      <Row>
        <Col sm="6">
          <div className="page-title">
            <i className="fa fa-reorder"></i>&nbsp;Announcements
          </div>
        </Col>
        <Col sm="6">
          {createNewAnnouncementLink}
        </Col>
      </Row>
      <div className="my-divider"></div>
      {renderAnnouncements()}
    </InfiniteScroll>
  </div>;
}

export default Announcements;
