//Author Sooyoung Kim
//Date Sep 15, 2023
import {confirmation, formatDate, formatNumber} from '../../util/util';
import DatePicker from 'react-datepicker';
import {Button, FormGroup, Label, Row, Col, Input} from 'reactstrap';
import TextArea from './text-area';
import React, {useEffect} from 'react';


const AssignmentDraft  = (props)=>{
  const controller = new AbortController();

  //wrapper function

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  //render
  let bidButton;
  let showQuoteButton;
  let selectedAppraiserFromBid;

  if(!props.selectFromBid){
    bidButton = <Button color="info" disabled={props.bidDisabled!==false} onClick={
      (e)=>{
        if(props.appraisal.status!=='Submitted')
          confirmation(
            ()=>{props.bidButtonHandler()},
            ()=>{},
            'Send Bid?',
            'The appraisal is currently in '+props.appraisal.status+' status, do you still want to send bid?'
          );
        else {
          props.bidButtonHandler()
        }
      }
    }><i className="fa fa-question"></i> Bid</Button>;
  }
  
  if(!props.vetDisabled){
    showQuoteButton = <Button color="info" onClick={props.toggleVettingPage}><i className="fa fa-commenting-o"></i>&nbsp;Quote Vet</Button>;
  }

  let fee = props.totalAppraiserFee;

  if(props.selectedAppraisers.length===1){
    fee = 0;
    let appraiserInfo = props.appraiserInfoMap[props.selectedAppraisers[0]];

    for(let i=0;i<appraiserInfo.fees.length;i++){
      fee+= parseInt(appraiserInfo.fees[i].fee, 10);
    }
  }

  if(props.appraiserFeeForBid!=='')
    fee = props.appraiserFeeForBid;

  let appraiserFee;

  if(props.appraiserFeeForBid===''){
    appraiserFee = <Col sm="2">
      <label>Appraiser Fee ($)</label><br/>
      {formatNumber(fee)}
    </Col>
  }

  return <div>
    {selectedAppraiserFromBid}
    <Row>
      <Col sm="2">
        <label>Override Payable Section</label>
        <Input type="text" value={props.appraiserFeeForBid} onChange={(e)=>props.setState('appraiserFeeForBid',e.target.value)}/>
      </Col>
      {appraiserFee}
      <Col sm="2">
        <label>Lender Fee ($)</label>
        <Input type="text" value={formatNumber(props.totalLenderFee)} disabled/>
      </Col>
      <Col sm="2">
        <label>Spread ($)</label>
        <Input type="text" value={formatNumber(props.totalLenderFee-fee)} disabled/>
      </Col>
      <Col sm="2">
        <label>Due Date</label><br/>
        <DatePicker
          className="form-control"
          dateFormat="yyyy/MM/dd"
          selected={props.turnAroundTimeForBid}
          onChange={(text)=>{if(text){props.setState('turnAroundTimeForBid',text.toLocaleDateString('en-CA'))}}}
        />
      </Col>
      <Col sm="2">
        <label>Client Exp Date</label><br/>
        {formatDate(props.appraisal.datetime_expected)}
      </Col>
    </Row>
    <br/>
    <TextArea id={props.id} showLoading={props.showLoading} hideLoading={props.hideLoading} logout={props.logout} onChange={props.handleChangeInput}/>

    <Row>
      <Col sm="6">
        <FormGroup check>
          <Label check>
            <Input type="checkbox" onClick={()=>props.setState('acceptOnBehalf',!props.acceptOnBehalf)}/> Accept on behalf of appraiser
          </Label>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </FormGroup>
      </Col>
      <Col sm="6" className="align-right">

        {bidButton}&nbsp;&nbsp;{showQuoteButton}&nbsp;&nbsp;
        <Button color="warning" disabled={!props.appraisal&&props.appraisal.status!=='Submitted'&&props.appraisal.status!=='Auto Assigning'} onClick={props.manualAutoAssign}><i className="fa fa-laptop"></i> Auto Assign</Button>
        &nbsp;
        &nbsp;<Button color="warning" disabled={props.submitDisabled!==false} onClick={
          ()=>{
            if(props.appraisal.disclosure_issue==='yes'){
              prompt(
                ()=>{props.buttonHandler()},
                'Assign - Disclosure Issue',
                'This order has a disclosure issue where the payment hasn\'t been cleared. You can proceed with assignment but please keep in mind that the property cannot be inspected prior to the payment.');
            }
            else {
              console.log("button clicked");
              props.buttonHandler();
            }
          }
          }><i className="fa fa-check"></i> Assign</Button>
      </Col>
    </Row>
  </div>;
}


export default AssignmentDraft;
