//Author Sooyoung Kim
//Date July 14, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, formatNumber, formatDateTime} from '../../util/util';
import {Button, Card, CardHeader, CardBody, Col, Row, Modal, ModalHeader, ModalBody} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import {NavLink, useParams} from 'react-router-dom';
import ReportFilter from './../report/report-filter';
import MyReactTable from '../util/my-react-table';
import MyGauge from './../util/my-gauge';
import moment from 'moment';
import './tat-dashboard.css';

//initialize the state
const initialState = {
  tatDashboard:[],
  entity:'',
  showDetailsDropDownOpen:false,
  detailsOrder:[],
  selectedDetails:'',
  state:'',
};

//reducer function that perform state update
const reducer = getReducer();


const TurnAroundTimeDashboard  = (props)=>{
  const controller = new AbortController();
  let {from, to, client, filterAppraisalTypes, accountManagerFk} = useParams();
  console.log(client);

  let newfilterAppraisalTypes = [];
  if(filterAppraisalTypes&&filterAppraisalTypes!=='-1'){
    newfilterAppraisalTypes = filterAppraisalTypes.replace(new RegExp('/','g'),'fslash').split('|');
  }
  let newInitialState = Object.assign({}, initialState, {
      from:from,
      to:to,
      client:client==='-1'?'':client,
      accountManagerFk:accountManagerFk==='-1'?'':accountManagerFk,
      filterAppraisalTypes:newfilterAppraisalTypes
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getTAT();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const toggleShowDetails = () =>{
    setState({showDetailsDropDownOpen:!state.showDetailsDropDownOpen});
  }

  const selectDetails = (report, state) =>{
    setState({selectedDetails:report});
    toggleShowDetails();

    getDetails(report, state);
  }

  //API call
  const getTAT = () =>{
    setState({tatDashboard:[]});
    let appraisalType = state.filterAppraisalTypes.join('|').replace(new RegExp('/','g'),'fslash');
    let url = 'dashboard/tat/from='+state.from+'&to='+state.to+'&state='+state.state+'&accountManager='+state.accountManagerFk+'&appraisalType='+appraisalType+'&client='+state.client+'&entity='+state.entity;
    let callBack = apiCallBack([{state:'tatDashboard', key:'data'}]);
	  httpGet(url, '', 'Oops, something went wrong and could not load the dashboard. Please try again later.', callBack);
  }

  const getDetails = (report, stateName) =>{
    let appraisalType = state.filterAppraisalTypes.join('|').replace(new RegExp('/','g'),'fslash');
    let url = 'dashboard/tat/details/appraisalType='+appraisalType+'&accountManager='+state.accountManagerFk+'&state='+stateName+'&report='+report+'&from='+state.from+'&to='+state.to+'&client='+state.client;
    let callBack = apiCallBack([{state:'detailsOrder', key:'data'}]);
	  httpGet(url, '', 'Oops, something went wrong and could not load orders. Please try again later.', callBack);
  }

  //render
  let config = {
    chart: {
      type: 'gauge',
      plotBackgroundColor: null,
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false,
      height:190
    },

    title: {
      text: null
    },

    pane: {
      startAngle: -150,
      endAngle: 150,
      background: [{
        backgroundColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, '#FFF'],
            [1, '#333']
          ]
        },
        borderWidth: 0,
        outerRadius: '50%'
      }, {
        backgroundColor: {
          linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
          stops: [
            [0, '#333'],
            [1, '#FFF']
          ]
        },
        borderWidth: 1,
        outerRadius: '48%'
      }, {
          // default background
      }, {
        backgroundColor: '#DDD',
        borderWidth: 0,
        outerRadius: '105%',
        innerRadius: '103%'
      }]
    },

    plotOptions:{
      gauge:{
        dataLabels:{
          enabled: true,
          style:{
            fontSize:'20px'
          },
          crop: false
        }
      }
    },
    credits: {
      enabled: false
    },

    // the value axis
    yAxis: {
      min: 0,
      max: 12,

      minorTickInterval: 'auto',
      minorTickWidth: 1,
      minorTickLength: 10,
      minorTickPosition: 'inside',
      minorTickColor: '#666',

      tickPixelInterval: 30,
      tickWidth: 2,
      tickPosition: 'inside',
      tickLength: 10,
      tickColor: '#666',
      labels: {
        distance:20
      },
      title: {
        text: 'day',
        y:30
      },
      plotBands: [{
        from: 0,
        to: 4,
        color: '#55BF3B' // green
      }, {
        from: 4,
        to: 8,
        color: '#DDDF0D' // yellow
      }, {
        from: 8,
        to: 12,
        color: '#DF5353' // red
      }]
    },
    series: [{
      name: 'Turnaround',
      data: [1],
      tooltip: {
        valueSuffix: ' days'
      }
    }]
  };


  let newfrom, newto;
  if(state.from!=='')
    newfrom = moment(state.from);
  if(state.to!=='')
    newto = moment(state.to);

  let columns  = [
    {
      id: 'referenceNum',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'property',
      Header: 'Property',
      accessor: d => d.property_street+' '+d.property_city,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'dateSubmitted',
      Header: 'Date Submitted',
      accessor: d => d.datetime_submitted,
      Cell: props=> <div>{formatDateTime(props.row.original.datetime_submitted)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  if(state.selectedDetails==='order-delivered'){
    columns = [
      {
        id: 'referenceNum',
        Header: 'Reference #',
        accessor: d => d.reference_num,
        Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
        headerStyle: {
          textAlign:'left'
        }
      },
      {
        id: 'property',
        Header: 'Property',
        accessor: d => d.property_street+' '+d.property_city,
        headerStyle: {
          textAlign:'left'
        }
      },
      {
        id: 'dateSubmitted',
        Header: 'Date Submitted',
        accessor: d => d.datetime_submitted,
        Cell: props=> <div>{formatDateTime(props.row.original.datetime_submitted)}</div>,
        headerStyle: {
          textAlign:'left'
        }
      },
      {
        id: 'dateScheduled',
        Header: 'Date Inspection Scheduled',
        accessor: d => d.datetime_scheduled,
        Cell: props=> <div>{formatDateTime(props.row.original.datetime_scheduled)}</div>,
        headerStyle: {
          textAlign:'left'
        }
      },
      {
        id: 'dateInspection',
        Header: 'Date Inspection',
        accessor: d => d.datetime_inspection,
        Cell: props=> <div>{formatDateTime(props.row.original.datetime_inspection)}</div>,
        headerStyle: {
          textAlign:'left'
        }
      },
      {
        id: 'dateCompleted',
        Header: 'Date Completed',
        accessor: d => d.datetime_completed,
        Cell: props=> <div>{formatDateTime(props.row.original.datetime_completed)}</div>,
        headerStyle: {
          textAlign:'left'
        }
      },
      {
        id: 'tat',
        Header: 'TAT',
        accessor: d => d.tat_1+d.tat_2,
        Cell: props=> <div>{Number(props.row.original.tat_1+props.row.original.tat_2).toFixed(2)+ 'bd'}</div>,
        headerStyle: {
          textAlign:'left'
        }
      }
    ];
  }
  else if(state.selectedDetails==='assignment-tat'){
    columns = [
      {
        id: 'referenceNum',
        Header: 'Reference #',
        accessor: d => d.reference_num,
        Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
        headerStyle: {
          textAlign:'left'
        }
      },
      {
        id: 'property',
        Header: 'Property',
        accessor: d => d.property_street+' '+d.property_city,
        headerStyle: {
          textAlign:'left'
        }
      },
      {
        id: 'dateSubmitted',
        Header: 'Date Submitted',
        accessor: d => d.datetime_submitted,
        Cell: props=> <div>{formatDateTime(props.row.original.datetime_submitted)}</div>,
        headerStyle: {
          textAlign:'left'
        }
      },
      {
        id: 'dateAssigned',
        Header: 'Date Assigned',
        accessor: d => d.datetime_assigned,
        Cell: props=> <div>{formatDateTime(props.row.original.datetime_assigned)}</div>,
        headerStyle: {
          textAlign:'left'
        }
      },
      {
        id: 'tat',
        Header: 'TAT',
        accessor: d => d.tat_1,
        Cell: props=> <div>{Number(props.row.original.tat_1).toFixed(2)+ 'bd'}</div>,
        headerStyle: {
          textAlign:'left'
        }
      }
    ];
  }
  else if(state.selectedDetails==='acceptance-tat'){
    columns = [
      {
        id: 'referenceNum',
        Header: 'Reference #',
        accessor: d => d.reference_num,
        Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
        headerStyle: {
          textAlign:'left'
        }
      },
      {
        id: 'property',
        Header: 'Property',
        accessor: d => d.property_street+' '+d.property_city,
        headerStyle: {
          textAlign:'left'
        }
      },
      {
        id: 'dateAcceptance',
        Header: 'Date Assigned',
        accessor: d => d.datetime_assigned,
        Cell: props=> <div>{formatDateTime(props.row.original.datetime_assigned)}</div>,
        headerStyle: {
          textAlign:'left'
        }
      },
      {
        id: 'dateAssigned',
        Header: 'Date Accepted',
        accessor: d => d.datetime_accepted,
        Cell: props=> <div>{formatDateTime(props.row.original.datetime_accepted)}</div>,
        headerStyle: {
          textAlign:'left'
        }
      },
      {
        id: 'tat',
        Header: 'TAT',
        accessor: d => d.tat_1,
        Cell: props=> <div>{Number(props.row.original.tat_1).toFixed(2)+ 'bd'}</div>,
        headerStyle: {
          textAlign:'left'
        }
      }
    ];
  }
  else if(state.selectedDetails==='appraiser-tat'){
    columns = [
      {
        id: 'referenceNum',
        Header: 'Reference #',
        accessor: d => d.reference_num,
        Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
        headerStyle: {
          textAlign:'left'
        }
      },
      {
        id: 'property',
        Header: 'Property',
        accessor: d => d.property_street+' '+d.property_city,
        headerStyle: {
          textAlign:'left'
        }
      },
      {
        id: 'dateAccepted',
        Header: 'Date Accepted',
        accessor: d => d.datetime_accepted,
        Cell: props=> <div>{formatDateTime(props.row.original.datetime_accepted)}</div>,
        headerStyle: {
          textAlign:'left'
        }
      },
      {
        id: 'dateUploaded',
        Header: 'Date Appraisal Uploaded',
        accessor: d => d.datetime_appraisal_uploaded,
        Cell: props=> <div>{formatDateTime(props.row.original.datetime_appraisal_uploaded)}</div>,
        headerStyle: {
          textAlign:'left'
        }
      },
      {
        id: 'tat',
        Header: 'TAT',
        accessor: d => d.tat_1,
        Cell: props=> <div>{Number(props.row.original.tat_1).toFixed(2)+ 'bd'}</div>,
        headerStyle: {
          textAlign:'left'
        }
      }
    ];
  }
  else if(state.selectedDetails==='review-tat'){
    columns = [
      {
        id: 'referenceNum',
        Header: 'Reference #',
        accessor: d => d.reference_num,
        Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
        headerStyle: {
          textAlign:'left'
        }
      },
      {
        id: 'property',
        Header: 'Property',
        accessor: d => d.property_street+' '+d.property_city,
        headerStyle: {
          textAlign:'left'
        }
      },
      {
        id: 'dateReviewStarted',
        Header: 'Date Review Started',
        accessor: d => d.datetime_review_started,
        Cell: props=> <div>{formatDateTime(props.row.original.datetime_review_started)}</div>,
        headerStyle: {
          textAlign:'left'
        }
      },
      {
        id: 'dateReviewCompleted',
        Header: 'Date Review Completed',
        accessor: d => d.datetime_review_completed,
        Cell: props=> <div>{formatDateTime(props.row.original.datetime_review_completed)}</div>,
        headerStyle: {
          textAlign:'left'
        }
      },
      {
        id: 'tat',
        Header: 'TAT',
        accessor: d => d.tat_1,
        Cell: props=> <div>{Number(props.row.original.tat_1).toFixed(2)+ 'bd'}</div>,
        headerStyle: {
          textAlign:'left'
        }
      }
    ];
  }
  else if(state.selectedDetails==='rebuttal-received'){
    columns  = [
      {
        id: 'referenceNum',
        Header: 'Reference #',
        accessor: d => d.reference_num,
        Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
        headerStyle: {
          textAlign:'left'
        }
      },
      {
        id: 'property',
        Header: 'Property',
        accessor: d => d.property_street+' '+d.property_city,
        headerStyle: {
          textAlign:'left'
        }
      },
      {
        id: 'dateRequested',
        Header: 'Date Requested',
        accessor: d => d.datetime_created,
        Cell: props=> <div>{formatDateTime(props.row.original.datetime_created)}</div>,
        headerStyle: {
          textAlign:'left'
        }
      }
    ];
  }
  else if(state.selectedDetails==='rebuttal-tat'){
    columns = [
      {
        id: 'referenceNum',
        Header: 'Reference #',
        accessor: d => d.reference_num,
        Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
        headerStyle: {
          textAlign:'left'
        }
      },
      {
        id: 'property',
        Header: 'Property',
        accessor: d => d.property_street+' '+d.property_city,
        headerStyle: {
          textAlign:'left'
        }
      },
      {
        id: 'dateCreated',
        Header: 'Date Requested',
        accessor: d => d.datetime_created,
        Cell: props=> <div>{formatDateTime(props.row.original.datetime_created)}</div>,
        headerStyle: {
          textAlign:'left'
        }
      },
      {
        id: 'dateCompleted',
        Header: 'Date Completed',
        accessor: d => d.datetime_completed,
        Cell: props=> <div>{formatDateTime(props.row.original.datetime_r_completed)}</div>,
        headerStyle: {
          textAlign:'left'
        }
      },
      {
        id: 'tat',
        Header: 'TAT',
        accessor: d => d.tat_1,
        Cell: props=> <div>{Number(props.row.original.tat_1).toFixed(2)+ 'bd'}</div>,
        headerStyle: {
          textAlign:'left'
        }
      }
    ];
  }

  let reportFiltersConfig = [
    {id:'from',value:newfrom, updateFunc:(v)=>setState({from:v}), width:'3'},
    {id:'to',value:newto, updateFunc:(v)=>setState({to:v}), width:'3'},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'3'},
    {id:'accountManager',value:state.accountManagerFk, updateFunc:(v)=>setState({accountManagerFk:v}), width:'3'},
    {id:'appraisalType',value:state.filterAppraisalTypes, updateFunc:(v)=>setState({filterAppraisalTypes:v}), width:'3'},
    {id:'state',value:state.state, updateFunc:(v)=>setState({state:v}), width:'3'},
    {id:'button',value:'Submit', updateFunc:getTAT, width:'6', className:"align-right", color:"warning"},
  ];

  console.log(state.tatDashboard);

  return <div>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Turn Around Time Dashboard&nbsp;
        <i className="fa fa-angle-right"></i>&nbsp;USA
      </CardHeader>
      <CardBody>
        <ReportFilter {...props} configs={reportFiltersConfig}/>
        <div className="my-divider">&nbsp;</div>
        <Row>
          {
            state.tatDashboard.map(
              (stateName, index)=>{

                let newConfig = Object.assign({}, config, {series:[{
                  name: 'Turnaround',
                  data: [stateName.overall_tat],
                  tooltip: {
                    valueSuffix: ' days'
                  },
                }]});

                let ap  = state.filterAppraisalTypes.join('|').replace(new RegExp('/','g'),'fslash');

                if(ap==='')
                  ap = -1;
                let client = state.client;

                if(client==='')
                  client = -1;

                let accountManagerFk = state.accountManagerFk;

                if(accountManagerFk==='')
                  accountManagerFk = -1;
                return(
                  <Col sm="3" key={index}>
                    <Card>
                      <CardHeader className="header-color">
                        <center>
                          <NavLink to={"/dashboard-tat-county/"+stateName.state+"/"+state.from+"/"+state.to+"/"+client+"/"+ap+"/"+accountManagerFk}>{stateName.state}</NavLink>
                        </center>
                      </CardHeader>
                      <CardBody className={stateName.total_order_received<=0?'gray-out':''}>
                        <div style={{height:'450px'}}>
                          <MyGauge options = {newConfig} ></MyGauge>
                          <center className="small-font">
                            Order received: <NavLink to="#" onClick={(e)=>{selectDetails('order-received',stateName.state)}}>{stateName.total_order_received}</NavLink><br/>
                            Order delivered: <NavLink to="#" onClick={(e)=>{selectDetails('order-delivered',stateName.state)}}>{stateName.total_order_delivered}</NavLink><br/>
                            Standard deviation of turn time: {stateName.standard_deviation}<br/>
                            Acceptable range of turn time: {stateName.lower_limit} to {stateName.upper_limit}<br/>
                            <br/>
                            Assignment TAT: <NavLink to="#" onClick={(e)=>{selectDetails('assignment-tat',stateName.state)}}>{stateName.assignment_tat}</NavLink><br/>
                            Acceptance TAT: <NavLink to="#" onClick={(e)=>{selectDetails('acceptance-tat',stateName.state)}}>{stateName.acceptance_tat}</NavLink><br/>
                            Appraiser TAT: <NavLink to="#" onClick={(e)=>{selectDetails('appraiser-tat',stateName.state)}}>{stateName.appraiser_tat}</NavLink><br/>
                            Review TAT: <NavLink to="#" onClick={(e)=>{selectDetails('review-tat',stateName.state)}}>{stateName.review_tat}</NavLink><br/>
                            <br/>
                            Rebuttal received: <NavLink to="#" onClick={(e)=>{selectDetails('rebuttal-received',stateName.state)}}>{stateName.total_rebuttal}</NavLink><br/>
                            Rebuttal TAT: <NavLink to="#" onClick={(e)=>{selectDetails('rebuttal-tat',stateName.state)}}>{stateName.rebuttal_tat}</NavLink><br/>
                            Satisfaction: <NavLink to="#" onClick={(e)=>{selectDetails('rebuttal-received',stateName.state)}}>{formatNumber(stateName.satisfaction)}%</NavLink><br/>
                          </center>
                        </div>
                      </CardBody>
                    </Card><br/>
                  </Col>
                );
              }
            )
          }
        </Row>

        <Modal className="my-modal-wide" isOpen={state.showDetailsDropDownOpen} toggle={toggleShowDetails} >
          <ModalHeader hidden={true} toggle={toggleShowDetails}></ModalHeader>
          <ModalBody>
            <center>
              <h5><i className="fa fa-warning"></i> Break Down</h5>
            </center>
            <br/>
            <MyReactTable columns={columns} data={state.detailsOrder} className="table table-striped"/>
            <center>
              <Button color="info" onClick={toggleShowDetails}>Close</Button>
            </center>
          </ModalBody>
        </Modal>
      </CardBody>
    </Card>
  </div>;
}


export default TurnAroundTimeDashboard;
