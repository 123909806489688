//Author Sooyoung Kim
//Date July 5, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, callBackGenerator, generateSID, formatNumberNoDecimal, formatNumber, formatDate, formatDateTime, getFromTo, replaceRegex} from '../../../util/util';
import {Button, Card, CardHeader, CardBody, Col, FormGroup,NavItem, Nav, TabContent, TabPane, Row, Input, Modal, ModalHeader, ModalBody, Label, NavLink as NavLinkL} from 'reactstrap';
import FileSaver from 'file-saver';
import moment from 'moment';
import {NavLink} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';
import ViewPaymentBatchReport from './view-payment-batch';
import MyReactTable from '../../util/my-react-table';
import ReportFilter from '../report-filter';
import '../report.css';

import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


//initialize the state
const initialState = {
  SID: generateSID(),
  activeTab:'1',
  accrued: 'no',
  loanPurpose:'',
  loanType:'',
  isRush:'',
  isComplex:'',
  state:'',
  city:'',
  entity:'',
  client:'',
  selectedAppraisalType:'',
  filterAppraisalTypes:[],
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
  clients:[],
  loanTypes:[{key:'Conventional',value:'Conventional'},{key:'High Balanced',value:'High Balanced'},{key:'Jumbo',value:'Jumbo'},{key:'Jumbo ARM',value:'Jumbo ARM'},{key:'Jumbo Fixed',value:'Jumbo Fixed'},{key:'Jumbo <$1m',value:'Jumbo <$1m'},{key:'Jumbo $1m - $2m',value:'Jumbo $1m - $2m'},{key:'Jumbo >$2m',value:'Jumbo >$2m'},{key:'FHA',value:'FHA'},{key:'USDA',value:'USDA'},{key:'Other',value:'Other'}],
  loanPurposes:[{key:'Purchase',value:'Purchase'},{key:'Refinance',value:'Refinance'},{key:'R/T Refinance',value:'R/T Refinance'},{key:'C/O Refinance',value:'C/O Refinance'},{key:'HELOC/2nd Mortgage',value:'HELOC/2nd Mortgage'},{key:'Other',value:'Other'}],
  appraisalTypes:[],
  from:getFromTo('from'),
  to:getFromTo('to'),
  coveredBy:'',
  paymentMethod:'',
  paymentMethods:[],
  openBy:'',
  shipOnly:'no',

  appraisers:[],
  appraiser:'',

  report:[],

  selectedClientCompany:'',
  selectedClient:-1,
  selectedOrders:[],
  selectedOrdersForInvoice:[],
  showPopUp:false,
  showOrder:false,

  multipleCompanies:[],
  multipleCompanyLabels:[],
  description:''
};

//reducer function that perform state update
const reducer = getReducer();


const ClientInvoiceReport  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getPaymentMethods();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const selectMultipleCompanies = (clientFk, company, orders)=>{
    let multipleCompanies = state.multipleCompanies.slice();
    let multipleCompanyLabels = state.multipleCompanyLabels.slice();
    let selectedOrdersForInvoice = state.selectedOrdersForInvoice.slice();

    let index = multipleCompanies.indexOf(clientFk);

    if(index===-1){
      multipleCompanies.push(clientFk);
      multipleCompanyLabels.push(company);
      selectedOrdersForInvoice = selectedOrdersForInvoice.concat(orders);
    }
    else{
      multipleCompanies.splice(index,1);
      multipleCompanyLabels.splice(index,1);
      let tmp = [];
      let found = false;
      for(let i=0;i<selectedOrdersForInvoice.length;i++){
        found = false;
        for(let j=0;j<orders.length;j++){
          if(selectedOrdersForInvoice[i].invoiceID===orders[j].invoiceID){
            found = true;
            break;
          }
        }

        if(!found)
          tmp.push(selectedOrdersForInvoice[i]);
      }

      selectedOrdersForInvoice = tmp;
    }

    setState({description: multipleCompanyLabels.join(' - '),selectedClient:0, multipleCompanyLabels:multipleCompanyLabels, multipleCompanies:multipleCompanies, selectedOrdersForInvoice:selectedOrdersForInvoice});
  }

  const isCheck = (clientFk)=>{
    let multipleCompanies = state.multipleCompanies.slice();
    return multipleCompanies.indexOf(clientFk)!==-1;
  }

  const activeTabToggle = (tab)=>{
    setState({activeTab:tab});
  }

  const checked = (invoiceID)=>{
    let selectedOrdersForInvoice = state.selectedOrdersForInvoice.slice();

    let found = false;
    for(let i=0;i<selectedOrdersForInvoice.length;i++){
      if(selectedOrdersForInvoice[i].invoiceID===invoiceID){
        found = true;
      }
    }

    return found;
  }

  const tooglePopUp = ()=>{
    setState({showPopUp:!state.showPopUp})
  }

  const selectCompany = (clientCompany, clientID, orders)=>{
    setState({selectedClientCompany:clientCompany,selectedClient:clientID, selectedOrders:orders, selectedOrdersForInvoice:orders, showPopUp:true});
  }

  const checkOrder = (row)=>{
    let selectedOrdersForInvoice = state.selectedOrdersForInvoice.slice();

    let found = false;
    for(let i=0;i<selectedOrdersForInvoice.length;i++){
      if(selectedOrdersForInvoice[i].invoiceID===row.invoiceID){
        selectedOrdersForInvoice.splice(i, 1);
        found = true;
      }
    }
    if(!found)
      selectedOrdersForInvoice.push(row);

    setState({selectedOrdersForInvoice:selectedOrdersForInvoice});
  }

  //API call
  const generateInvoice = (invoiceIDs, newID='')=>{
    if(invoiceIDs.length>0){
      let parameters = [
        {
          field:'IDs',
          value:invoiceIDs
        },
        {
          field:'invoiceNumber',
          value:newID
        }
      ];

      let callBack = (response)=>{
        let code = response.data?response.data.code:undefined;
        if(code==='00'){
          let byteCharacters = atob(response.data.data);
          let byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          let byteArray = new Uint8Array(byteNumbers);
          let data = new Blob([byteArray]);
          FileSaver.saveAs(data, 'client-invoice.pdf');
        }
      };
      callBack = callBack.bind(this);

      httpPost('billing/formalInvoice/create', parameters, '', 'Oops, something went wrong and could not download the invoice. Please try again later.', callBack);
    }
  }

  const createPaymentBatch = ()=>{
    let invoiceIDs = [];

    for(let i=0;i<state.selectedOrdersForInvoice.length;i++){
      invoiceIDs.push(state.selectedOrdersForInvoice[i].invoiceID);
    }

    if(invoiceIDs.length>0){

      let parameters = [
        {
          field:'description',
          value:state.description
        },
        {
          field:'confirmation',
          value:''
        },
        {
          field:'clientFk',
          value:state.selectedClient
        },
        {
          field:'invoices',
          value:invoiceIDs
        },
      ];

      let callBack = (response)=>{
        let code = response.data?response.data.code:undefined;

        if(code==='00'){
          let newID = response.data.newID;
          generateInvoice(invoiceIDs, newID);

          let report = state.report.slice();

          for(let i=0;i<report.length;i++){
            if(report[i].client_fk===state.selectedClient){
              const today = new Date();
              const yyyy = today.getFullYear();
              let mm = today.getMonth() + 1; // Months start at 0!
              let dd = today.getDate();

              report[i].last_invoice=yyyy+'-'+mm+'-'+dd;
            }
          }

          setState({report:report});
        }
      };
      callBack = callBack.bind(this);

      httpPost('report/paymentBatch/create', parameters, '', 'Oops, something went wrong and could not generate the invoice. Please try again later.', callBack);
    }
  }

  const getReport = ()=>{
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);

    let url = 'report/owe/shipOnly='+state.shipOnly+'&covered_by='+state.coveredBy+'&method='+state.paymentMethod+'&accrued='+state.accrued+'&client='+state.client+'&openBy='+state.openBy+'&appraiser='+state.appraiser+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&appraisalType='+appraisalType;
    let callBack = apiCallBack([{state:'report', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not download the fee schedule. Please try again later.', callBack);
  }

  const getPaymentMethods = ()=>{
    let callBack = apiCallBack([{state:'paymentMethods', key:'data'}]);
    httpGet('billing/paymentType/get', '', 'Oops, something went wrong and could not load payment methods. Please try again later.', callBack);
  }

  //render
  let total = 0;
  for(let i=0;i<state.selectedOrdersForInvoice.length;i++){
    total += parseInt(state.selectedOrdersForInvoice[i].total, 10);
  }

  const columns = [
    {
      id: 'action',
      sortable: false,
      maxWidth:50,
      width:50,
      Cell: props => {
        return <div>
          <i className="fa fa-external-link-square cursor-pointer link-color" onClick={(e)=>{selectCompany(props.row.original.company,props.row.original.client_fk,props.row.original.orders)}}/>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Input type="checkbox" checked={isCheck(props.row.original.client_fk)} onClick={(e)=>selectMultipleCompanies(props.row.original.client_fk, props.row.original.company, props.row.original.orders)}/>
      </div>
      },
    },
    {
      id: 'client',
      Header: 'Company Name',
      accessor: d => d.company,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'totalOrders',
      Header: 'Total Orders Associated',
      accessor: d => d.orders.length,
      Cell: props=> <div>{formatNumberNoDecimal(props.row.original.orders.length)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'lastInvoice',
      Header: 'Last Invoice Date',
      accessor: d => d.orders.length,
      Cell: props=> <div>{formatDate(props.row.original.last_invoice)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'total',
      Header: 'Total Outstanding Payment',
      accessor: d => d.total,
      Cell: props => <div className="align-right">${formatNumber(props.row.original.total)}</div>,
      headerStyle: {
        textAlign:'right'
      }
    }
  ];

  const columns2 = [
    {
      id: 'action',
      sortable: false,
      maxWidth:50,
      width:50,
      Cell: props => {
        return <div>
          <FormGroup check>
            <Label check>
              <Input type="checkbox" checked={checked(props.row.original.invoiceID)} onClick={()=>checkOrder(props.row.original)}/>
            </Label>
          </FormGroup>
      </div>
      }
    },
    {
      id: 'referenceNum',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => {
        return <div>
          <NavLink to={"billing/"+props.row.original.appraisal_fk} target="_blank">{props.row.original.reference_num}</NavLink>
        </div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'loanNum',
      Header: 'Loan #',
      accessor: d => d.loan_num,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'borrower',
      Header: 'Borrower',
      accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'appraisalType',
      Header: 'Report Type',
      accessor: d => d.appraisal_type,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'invoiceID',
      Header: 'Receivable ID',
      accessor: d => d.invoiceID,
      Cell: props => {
        return <div>
          #{props.row.original.invoiceID}
        </div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'paymentMethod',
      Header: 'Payment Method',
      accessor: d => d.method,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'amount',
      Header: 'Amount',
      accessor: d => d.total,
      Cell: props => {
        return <div>
          ${formatNumber(props.row.original.total)}
        </div>
      },
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  let from, to;
  if(state.from!=='')
    from = moment(state.from);
  if(state.to!=='')
    to = moment(state.to);

  let orderList = <div>
    <NavLink to="#" onClick={(e)=>setState({showOrder:true})}>Show Order List</NavLink>
    <br/>
  </div>

  if(state.showOrder){
    orderList = <div>
      <NavLink to="#" onClick={(e)=>setState({showOrder:false})}>Hide Order List</NavLink>
      <br/>
      <div className="ultra-large-scroll-container">
        <MyReactTable columns={columns2} data={state.selectedOrders} className="table table-striped"/>
      </div>
    </div>
  }

  let options = [];
  if(state.paymentMethods && state.paymentMethods.length > 0){
    options = state.paymentMethods.map((paymentMethod)=>{
      return {label:paymentMethod.payment_type, value:paymentMethod.payment_type};
    })
  }

  let reportFiltersConfig = [
    {id:'from',value:from, updateFunc:(v)=>setState({from:v}), width:'2'},
    {id:'to',value:to, updateFunc:(v)=>setState({to:v}), width:'2'},
    {id:'customSelect',value:state.accrued, updateFunc:(v)=>setState({accrued:v}), width:'2', label:'Accrued Based', clearable:false, className:"", options:[{label:"No",value:"no"},{label:"Yes",value:"yes"}]},
    {id:'customSelect',value:state.shipOnly, updateFunc:(v)=>setState({shipOnly:v}), width:'2', label:'Shipped Order', clearable:false, className:"", options:[{label:"No - All Order",value:"no"},{label:"Yes - Only Shipped Order",value:"yes"}]},
    {id:'appraiser',value:state.appraiser, updateFunc:(v)=>setState({appraiser:v}), width:'4'},
    {id:'openBy',value:state.openBy, updateFunc:(v)=>setState({openBy:v}), width:'4'},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'4'},
    {id:'rush',value:state.isRush, updateFunc:(v)=>setState({isRush:v}), width:'2'},
    {id:'complex',value:state.isComplex, updateFunc:(v)=>setState({isComplex:v}), width:'2'},
    {id:'state',value:state.state, updateFunc:(v)=>setState({state:v}), width:'2'},
    {id:'city',value:state.city, updateFunc:(v)=>setState({city:v}), width:'2'},
    {id:'loanPurpose',value:state.loanPurpose, updateFunc:(v)=>setState({loanPurpose:v}), width:'2'},
    {id:'loanType',value:state.loanType, updateFunc:(v)=>setState({loanType:v}), width:'2'},
    {id:'entity',value:state.entity, updateFunc:(v)=>setState({entity:v}), width:'4'},
    {id:'appraisalType',value:state.filterAppraisalTypes, updateFunc:(v)=>setState({filterAppraisalTypes:v}), width:'3'},
    {id:'customSelect',value:state.paymentMethod, updateFunc:(v)=>setState({paymentMethod:v}), width:'3', label:'Payment Method', clearable:true, className:"", options:options},
    {id:'customSelect',value:state.coveredBy, updateFunc:(v)=>setState({coveredBy:v}), width:'3', label:'Covered By', clearable:true, className:"", options:[
      {label:"Broker",value:"Broker"},
      {label:"Borrower",value:"Borrower"},
      {label:"Broker and Borrower",value:"Broker and Borrower"},
      {label:"Lender",value:"Lender"},
      {label:"Paid Outside of Closing",value:"Paid Outside of Closing"},
      {label:"Paid at Closing",value:"Paid at Closing"}
    ]},
    {id:'button',value:'Submit', updateFunc:getReport, width:'3', className:"align-right", color:"warning"},
  ];

  return <div>
    <Nav tabs>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.activeTab === '1'?"active":"inactive" )}
          onClick={() => { activeTabToggle('1'); }}
          to="#"
        >
          Client Invoice
        </NavLinkL>
      </NavItem>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.activeTab === '2'?"active":"inactive" )}
          onClick={() => { activeTabToggle('2'); }}
          to="#"
        >
          Generated Invoice - Capture Payment In Batch
        </NavLinkL>
      </NavItem>
    </Nav>

    <TabContent activeTab={state.activeTab}>
      <TabPane tabId="1">

        <Modal className="my-modal-wide" isOpen={state.showPopUp} toggle={tooglePopUp} >
        <ModalHeader hidden={true} toggle={tooglePopUp}></ModalHeader>
        <ModalBody>
          <center>
            <h5><i className="fa fa-user"></i> Generate Invoice - {state.selectedClientCompany}</h5>
          </center>
          <br/>
          {orderList}
          <br/>
          <Card>
            <CardHeader>
              <label>{state.selectedClientCompany}</label><br/>
            </CardHeader>
            <CardBody>
              <div style={{frontSize:'25px'}}>
                <br/>
                <Row>
                  <Col sm="7">
                    <label>Total: </label> ${formatNumber(total)}<br/>
                    <label>Total Order Associated: </label> {formatNumberNoDecimal(state.selectedOrdersForInvoice.length)}<br/>
                  </Col>
                  <Col sm="5">
                    <br/>
                    <div className="align-right">
                      <ExcelFile element={<Button to="#" disabled={state.selectedOrdersForInvoice.length<=0} color="warning"><i className="fa fa-file-excel-o"/> Download Excel For Selected Orders</Button>}>
                        <ExcelSheet data={state.selectedOrdersForInvoice} name="XLSX">
                            <ExcelColumn label="Reference #" value="reference_num"/>
                            <ExcelColumn label="Loan #" value="loan_num"/>
                            <ExcelColumn label="Close By" value="company"/>
                            <ExcelColumn label="Invoice #" value="invoiceID"/>
                            <ExcelColumn label="Address" value={(col)=>{return col.property_street+' '+col.property_city+', '+col.property_state+' '+col.property_zip}}/>
                            <ExcelColumn label="Borrower" value={(col)=>{return col.borrower_f_name+' '+col.borrower_l_name}}/>
                            <ExcelColumn label="Status" value="order_status"/>
                            <ExcelColumn label="Amount" value={(col)=>'$'+col.total}/>
                            <ExcelColumn label="Date" value={(col)=>formatDateTime(col.datetime_submitted)}/>
                        </ExcelSheet>
                      </ExcelFile>&nbsp;&nbsp;
                      <Button color="warning" onClick={createPaymentBatch}><i className="fa fa-file-pdf-o"/> Generate Invoice</Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>
          <br/>
          <center>
            <Button color="info" onClick={tooglePopUp}>Close</Button>&nbsp;
          </center>
        </ModalBody>
        </Modal>

        <Card>
        <CardHeader className="header-color">
          <i className="fa fa-reorder"></i>&nbsp;Client Invoice Report
        </CardHeader>
        <CardBody>
          <ReportFilter {...props} configs={reportFiltersConfig}/>
        </CardBody>
        </Card>
        <br/>
        <div className="large-scroll-container">
        <MyReactTable columns={columns} data={state.report} className="table table-striped"/>
        </div>
        <br/>
        <div className="align-right">
          <ExcelFile element={<Button to="#" disabled={state.selectedOrdersForInvoice.length<=0} color="warning"><i className="fa fa-file-excel-o"/> Download Excel For Selected Orders</Button>}>
            <ExcelSheet data={state.selectedOrdersForInvoice} name="XLSX">
                <ExcelColumn label="Reference #" value="reference_num"/>
                <ExcelColumn label="Loan #" value="loan_num"/>
                <ExcelColumn label="Close By" value="company"/>
                <ExcelColumn label="Invoice #" value="invoiceID"/>
                <ExcelColumn label="Address" value={(col)=>{return col.property_street+' '+col.property_city+', '+col.property_state+' '+col.property_zip}}/>
                <ExcelColumn label="Borrower" value={(col)=>{return col.borrower_f_name+' '+col.borrower_l_name}}/>
                <ExcelColumn label="Status" value="order_status"/>
                <ExcelColumn label="Amount" value={(col)=>'$'+col.total}/>
                <ExcelColumn label="Date" value={(col)=>formatDateTime(col.datetime_submitted)}/>
            </ExcelSheet>
          </ExcelFile>&nbsp;&nbsp;
          <Button color="warning" onClick={createPaymentBatch}><i className="fa fa-file-pdf-o"/> Generate Invoice For Selected Companies</Button>
        </div>
      </TabPane>
      <TabPane tabId="2">
        <ViewPaymentBatchReport logout={props.logout} showLoading={props.showLoading} hideLoading={props.hideLoading}/>
      </TabPane>
    </TabContent>
  </div>;
}


export default ClientInvoiceReport;
