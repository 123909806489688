//Author Sooyoung Kim
//Date Aug 30, 2023
import StepPropertyInformation from './step-property-information';
import StepLoanInformation from './step-loan-information';
import StepSite from './step-site';
import StepInspection from './step-inspection';
import StepPhotos from './step-photos';
import StepDataCollectorCertification from './step-data-collector-certification';
import {NavLink} from 'react-router-dom';
import React, {useEffect} from 'react';

const StepReview  = (props)=>{
  const controller = new AbortController();

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  //render
  return <div>
    <label><font color="red">***</font>Please review the report below before submitting it.</label>
    <br/>
    <div className="align-right">
      <NavLink to="#" onClick={(e)=>props.updateStep(0)}>
        Edit Property Information <i className="fa fa-edit cursor-pointer"/>
      </NavLink>
    </div>
    <StepPropertyInformation disabled={true} updateState={()=>{}} {...props}/>
    <br/><br/>
    <div className="align-right">
      <NavLink to="#" onClick={(e)=>props.updateStep(1)}>
        Edit Loan Info <i className="fa fa-edit cursor-pointer"/>
      </NavLink>
    </div>
    <StepLoanInformation disabled={true} updateState={()=>{}} {...props}/>
    <br/><br/>
    <div className="align-right">
      <NavLink to="#" onClick={(e)=>props.updateStep(2)}>
        Edit Site <i className="fa fa-edit cursor-pointer"/>
      </NavLink>
    </div>
    <StepSite disabled={true} updateState={()=>{}} {...props}/>
    <br/><br/>
    <div className="align-right">
      <NavLink to="#" onClick={(e)=>props.updateStep(3)}>
        Edit Repair / Inspection / Improvement <i className="fa fa-edit cursor-pointer"/>
      </NavLink>
    </div>
    <StepInspection disabled={true} updateState={()=>{}} {...props}/>
    <br/><br/>
    <div className="align-right">
      <NavLink to="#" onClick={(e)=>props.updateStep(4)}>
        Edit Photo <i className="fa fa-edit cursor-pointer"/>
      </NavLink>
    </div>
    <StepPhotos disabled={true} updateState={()=>{}} {...props}/>
    <br/><br/>
    <div className="align-right">
      <NavLink to="#" onClick={(e)=>props.updateStep(5)}>
        Edit Data Collector Certification <i className="fa fa-edit cursor-pointer"/>
      </NavLink>
    </div>
    <StepDataCollectorCertification disabled={true} updateState={()=>{}} {...props}/>
  </div>;
}


export default StepReview;
