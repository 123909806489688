//Author Sooyoung Kim
//Date July 5, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, formatNumber, generateSID, getFromTo, replaceRegex} from '../../../util/util';
import {Button, Col, Row, Modal, ModalHeader, ModalBody, Table} from 'reactstrap';
import ReportFilter from '../report-filter';
import moment from 'moment';
import {NavLink, useNavigate, useParams} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';
import '../report.css';

let defaultfrom = getFromTo('from');
let defaultto = getFromTo('to');

//initialize the state
const initialState = {
  SID: generateSID(),
  report:[],
  filterAppraisalTypes:[],
  showLegend:false,
};

//reducer function that perform state update
const reducer = getReducer();


const ExceptionalReport  = (props)=>{
  const controller = new AbortController();
  let {params} = useParams();
  let history = useNavigate();

  let from = defaultfrom;
  let to = defaultto;
  let fee = '';
  let tat = '';
  let stateName = '';
  let city = '';
  let loanType = '';
  let loanPurpose = '';
  let complex = '';
  let rush = '';
  let client = '';
  let openBy = '';
  let entity = '';
  let appraiser = '';
  let appraiserName = '';
  let appraisalTypes = [];

  if(params&&params!==''){
    params = params.split('&');
    if(params){
      for(let i=0;i<params.length;i++){
        let keyPair = params[i].split('=');
        keyPair[1] = keyPair[1].replace('ForwardSlash', '/');
        keyPair[1] = keyPair[1].replace('Ampersand', '&');
        keyPair[1] = decodeURIComponent(keyPair[1]);
        
        switch(keyPair[0]){
        case 'from':
          from = keyPair[1];
          break;
        case 'to':
          to = keyPair[1];
          break;
        case 'fee':
          fee = keyPair[1];
          break;
        case 'tat':
          tat = keyPair[1];
          break;
        case 'state':
          stateName = keyPair[1];
          break;
        case 'city':
          city = keyPair[1];
          break;
        case 'loanType':
          loanType = keyPair[1];
          break;
        case 'loanPurpose':
          loanPurpose = keyPair[1];
          break;
        case 'appraiser':
        console.log(keyPair[1]);
          if(keyPair[1]&&keyPair[1]!==''){
            let keyPair2 = keyPair[1].split('|');
            if(keyPair2.length>=2){
              appraiser = keyPair2[1];
              appraiserName = keyPair2[0];
            }
          }
          break;
        case 'complex':
          complex = keyPair[1];
          break;
        case 'rush':
          rush = keyPair[1];
          break;
        case 'openBy':
          openBy = keyPair[1];
          break;
        case 'client':
          client = keyPair[1];
          break;
        case 'appraisalType':
          appraisalTypes = keyPair[1].split('|');
          break;
        case 'entity':
          entity = keyPair[1];
          break;
        default:
          break;
        }
      }
    }
  }

  let newInitialState = Object.assign({}, initialState, {
    params:params,
    fee:fee,
    tat:tat,
    from:from,
    to:to,
    loanPurpose:loanPurpose,
    loanType:loanType,
    appraiser:appraiser,
    appraiserName:appraiserName,
    appraisalTypes:appraisalTypes,
    isRush:rush,
    isComplex:complex,
    state:stateName,
    city:city,
    entity:entity,
    client:client,
    openBy:openBy,
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getReport();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const toggleLegend = ()=>{
    setState({showLegend:!state.showLegend});
  }

  //API call
  const getReport = ()=>{
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);

    let url ='report/exception/get/fee='+state.fee+'&tat='+state.tat+'&appraiser='+state.appraiser+'&appraisalType='+appraisalType+'&openBy='+state.openBy+'&client='+state.client+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+state.state+'&city='+state.city;
    let callBack = apiCallBack([{state:'report', key:'data'}]);
	  httpGet(url, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);

    history("/report/exception/fee="+state.fee+'&tat='+state.tat+'&appraiser='+state.appraiserName+'|'+state.appraiser+'&appraisalType='+appraisalType+'&openBy='+state.openBy+'&client='+state.client+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+state.state+'&city='+state.city);
  }

  //render
  let newfrom, newto;
  if(state.from!=='')
    newfrom = moment(state.from);
  if(state.to!=='')
    newto = moment(state.to);

  let reportFiltersConfig = [
    {id:'from',value:newfrom, updateFunc:(v)=>setState({from:v}), width:'2'},
    {id:'to',value:newto, updateFunc:(v)=>setState({to:v}), width:'2'},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'3'},
    {id:'openBy',value:state.openBy, updateFunc:(v)=>setState({openBy:v}), width:'3'},
    {id:'customNumber',value:state.fee, updateFunc:(v)=>setState({fee:v}), width:'2', label:'Fee'},
    {id:'customNumber',value:state.tat, updateFunc:(v)=>setState({tat:v}), width:'2', label:'Turnaround Time'},
    {id:'appraiser',value:state.appraiser, updateFunc:(v)=>setState({appraiser:v}), width:'2'},
    {id:'rush',value:state.isRush, updateFunc:(v)=>setState({isRush:v}), width:'2'},
    {id:'complex',value:state.isComplex, updateFunc:(v)=>setState({isComplex:v}), width:'2'},
    {id:'state',value:state.state, updateFunc:(v)=>setState({state:v}), width:'2'},
    {id:'city',value:state.city, updateFunc:(v)=>setState({city:v}), width:'2'},
    {id:'loanPurpose',value:state.loanPurpose, updateFunc:(v)=>setState({loanPurpose:v}), width:'2'},
    {id:'loanType',value:state.loanType, updateFunc:(v)=>setState({loanType:v}), width:'2'},
    {id:'entity',value:state.entity, updateFunc:(v)=>setState({entity:v}), width:'2'},
    {id:'appraisalType',value:state.filterAppraisalTypes, updateFunc:(v)=>setState({filterAppraisalTypes:v}), width:'4'},
    {id:'button',value:'Submit', updateFunc:getReport, width:'2', className:"align-right", color:"warning"},
  ];
    
  return <div>
    <Modal className="my-modal" isOpen={state.showLegend} toggle={toggleLegend} >
      <ModalHeader hidden={true} toggle={toggleLegend}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-question-circle"></i>&nbsp;Report Information</h5>
        </center>
        <br/>
        <font>
          <font color="red">*** If the action is taken by the user during non-business hours(Monday through Friday before 9:00 AM and after 5:00 PM and weekend), the system will record the action time using the earliest business hours(bh)/business day(bd) for the TAT calculation. *** </font><br/><br/>
          <b><font color="#FD7F20">Submitted</font> <i className="fa fa-arrow-right"></i> <font color="#006DB0">Completed:</font></b> TAT Calculation that includes <b>everything</b> into account from beginning to the end. It does not exclude the wait time for inspection to happen.<br/><br/>
          <b><font color="#FD7F20">Submitted</font> <i className="fa fa-arrow-right"></i> <font color="#006DB0">Assignment Accepted:</font></b> TAT Calculation that includes everything in between of <b>date submitted</b> and <b>date assignment accepted</b> by appraiser.<br/><br/>
          <b><font color="#FD7F20">Inspection Completed</font> <i className="fa fa-arrow-right"></i> <font color="#006DB0">Completed:</font></b> TAT Calculation that includes everything in between of <b>date inspection completed</b> and date completed when the report was <b>shipped</b>.<br/><br/>
          <b><font color="#FD7F20">Accepted</font> <i className="fa fa-arrow-right"></i> <font color="#006DB0">Inspection Date:</font></b> TAT Calculation that includes everything in between <b>date assignment accepted</b> by appraiser and <b>date of inspection</b>. The wait time for inspection to happen is included.<br/><br/>
          <b><font color="#FD7F20">Submitted</font> <i className="fa fa-arrow-right"></i> <font color="#F26627">Scheduled + Inspection Date</font> <i className="fa fa-arrow-right"></i> <font color="#006DB0"> Completed:</font></b> TAT Calculation that is similar to <b>Submitted - Completed TAT Calculation</b> except it <b>excludes the wait time</b> for inspection to happen.<br/><br/>
          *** All TAT exclude hold and waiting on contact period ***

        </font>
        <br/><br/>
        <center>
          <Button color="info" onClick={toggleLegend}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
    <div className="padding">
      <ReportFilter {...props} configs={reportFiltersConfig}/>
      <Row>
        <Col className="align-right">
          <NavLink to="#" onClick={toggleLegend}><i className="fa fa-question-circle"></i>&nbsp;Report Information</NavLink>
        </Col>
      </Row>
      <br/>
      <div className="my-divider">&nbsp;</div>
      <Table className="table table-striped">
        <thead>
          <tr>
            <td><b>Appraiser</b></td>
            <td>
              <Table style={{marginBottom:'0px'}}>
                <thead>
                  <tr>
                    <td style={{borderTop:'0px'}} width="15%"><b>Reference #</b></td>
                    <td style={{borderTop:'0px'}} width="25%"><b>Report Type</b></td>
                    <td style={{borderTop:'0px'}} width="15%"><b>City</b></td>
                    <td style={{borderTop:'0px'}} width="15%"><b>State</b></td>
                    <td style={{borderTop:'0px'}} width="15%"><b>Total Fee</b></td>
                    <td style={{borderTop:'0px'}} width="15%"><b>{"TAT (Accepted=>Completed)"}</b></td>
                  </tr>
                </thead>
              </Table>
            </td>
          </tr>
        </thead>
        <tbody>
          {
            state.report.map(
              (appraiser, index)=>{
                return (
                  <tr>
                    <td><NavLink to={"/appraiser/"+appraiser.ID} target="_blank"><div><span className="my-badge">{appraiser.orders.length}</span> {appraiser.name}</div></NavLink></td>
                    <td>
                      <Table>
                        <tbody>
                          {
                            appraiser.orders.map(
                              (order, index2)=>{
                                return(
                                  <tr key={order.ID}>
                                    <td style={{borderTop:'0px'}} width="15%"><NavLink to={"/appraisal/"+order.ID} target="_blank">{order.reference_num}</NavLink></td>
                                    <td style={{borderTop:'0px'}} width="25%">{order.appraisal_type}</td>
                                    <td style={{borderTop:'0px'}} width="15%">{order.property_city}</td>
                                    <td style={{borderTop:'0px'}} width="15%">{order.property_state}</td>
                                    <td style={{borderTop:'0px'}} width="15%">${formatNumber(order.fee)}</td>
                                    <td style={{borderTop:'0px'}} width="15%">{order.tat} days</td>
                                  </tr>
                                )
                              }
                            )
                          }
                        </tbody>
                      </Table>
                    </td>
                  </tr>

                )
              }
            )
          }
        </tbody>
      </Table>
    </div>
  </div>;
}


export default ExceptionalReport;
