//Author June Leow
//Date Nov 15th, 2023
import React from 'react';

const MyTimline  = (props)=>{
  //render
  return(
    <section id="conference-timeline">
      <div className="conference-center-line"></div>
      <div className="conference-timeline-content">
        {props.children}
      </div>
    </section>
  );
}


export default MyTimline;
