//Author Sooyoung Kim
//Date Nov 17, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, putAPICallGenerator, callBackGenerator, formatNumber} from '../../util/util';
import {Button, Card, CardHeader, CardBody, Row, Col,Progress, UncontrolledTooltip, NavItem, Nav, TabContent, TabPane, NavLink as NavLinkL} from 'reactstrap';
import AutoAssignLenderPanel from './auto-assign-lender-panel';
import React, {useReducer, useEffect} from 'react';
import DatePicker from 'react-datepicker';
import {NavLink, useParams} from 'react-router-dom';
import moment from 'moment';
import './auto-assign.css';

//initialize the state
const initialState = {
  countiesStats:[],
  activeTab:'1',
  appraiserStats:[],
  company:'',
};

//reducer function that perform state update
const reducer = getReducer();


const AutoAssignLenderDashboard  = (props)=>{
  const controller = new AbortController();
  let {clientFk, from, to} = useParams();

  let newInitialState = Object.assign({}, initialState, {
    from:from,
    to:to,
    clientFk:clientFk,
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getClient(state.clientFk);
    getLenderStats();
    getAppraiserStats();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  //toggle function
  const activeTabToggle = (tab) => {
    setState({activeTab:tab});
  }

  //API call
  const getAppraiserStats = () => {
    let callBack = apiCallBack([{state:'appraiserStats', key:'data'}]);
    httpGet('autoAssign/appraisers/stats/get/from='+state.from+'&to='+state.to+'&client_fk='+state.clientFk, '', 'Oops, something went wrong and could not load auto assign stats. Please try again later.', callBack);
  }

  const getClient = (clientFk) => {
    let callBack = apiCallBack([{state:'company', key:'data.company'}]);
    httpGet('client/'+clientFk, '', 'Oops, something went wrong and could not load client profile. Please try again later.', callBack);
  }

  //get counties stats
  const getLenderStats = () => {
    let callBack = apiCallBack([{state:'countiesStats', key:'data'}]);
    httpGet('autoAssign/counties/stats/get/from='+state.from+'&to='+state.to+'&client_fk='+state.clientFk, '', 'Oops, something went wrong and could not load auto assign stats. Please try again later.', callBack);

  }

  const pauseAutoAssign = (ID, paused) => {
    let pause = 'paused';

    if(paused==='yes')
      pause = 'unpaused';

    let parameters = [
      {
        field:'ID',
        value:ID
      },
      {
        field:'field',
        value:'paused'
      },
      {
        field:'value',
        value:paused==='no'?'yes':'no'
      }
    ];

    let callBack = (response)=>{
      let code= response.data.code;
      if(code==='00'){
        let appraiserStats = state.appraiserStats.slice();

        for(let i=0;i<appraiserStats.length;i++){

          if(appraiserStats[i].baID===ID){
            if(paused==='no')
              appraiserStats[i].paused='yes';
            else
              appraiserStats[i].paused='no';
            break;
          }
        }

        setState({appraiserStats:appraiserStats});
      }
    };
    callBack = callBack.bind(this);

    httpPut('brokerRelationship/appraiser/update', parameters, 'Auto assign '+pause+' successfully.', 'Oops, something went wrong and could not '+pause+' the auto assign. Please try again later.', callBack);
  }

  //render
  let newfrom, newto;
  if(state.from!=='')
    newfrom = moment(state.from).toDate();
  if(state.to!=='')
    newto = moment(state.to).toDate();

  let countiesStats;
  let appraiserStats;

  if(state.countiesStats.length>0){
    countiesStats = state.countiesStats.map(
      (countyStats,index)=>{

        let totalManual = countyStats.exhausted + countyStats.timeout + countyStats.skip;
        let exhausted = countyStats.exhausted;
        let exhaustedRate = formatNumber((exhausted/totalManual)*100);

        let timeout = countyStats.timeout;
        let timeoutRate = formatNumber((timeout/totalManual)*100);

        let skip = countyStats.skip;
        let skipRate = formatNumber((skip/totalManual)*100);


        let className = '';
        if(countyStats.auto_assign_count===0)
          className='no-data';

        let colorClass,colorClass2 = 'green-status';
        let icon,icon2 = 'fa fa-check';

        if(countyStats.avg_accepting<4){
          icon = 'fa fa-check';
          colorClass = 'green-status';
        }
        else if(countyStats.avg_accepting<6){
          icon = 'fa fa-warning';
          colorClass = 'yellow-status';
        }
        else{
          icon = 'fa fa-times';
          colorClass = 'red-status';
        }

        if(countyStats.success_rate>70){
          icon2 = 'fa fa-check';
          colorClass2 = 'green-status';
        }
        else if(countyStats.success_rate>50){
          icon2 = 'fa fa-warning';
          colorClass2 = 'yellow-status';
        }
        else{
          icon2 = 'fa fa-times';
          colorClass2 = 'red-status';
        }

        let skipReasons;

        if(countyStats.skipDrillDown&&countyStats.skipDrillDown.length>0){
          skipReasons = countyStats.skipDrillDown.map(
            (reason,index)=>{
              return(
                <div className="align-left" key={index}>
                  <b>{reason.reason+": "+reason.count}</b>
                </div>
              );
            }
          );
        }


        return(
          <Col sm="4" key={index} className="margin-bottom-ex">
            <Card>
              <CardHeader className="header-color">
                <Row>
                  <Col sm="2">

                  </Col>
                  <Col sm="8">
                    <center>
                      <NavLink className="no-padding" target="_blank" to={"/auto-assign/county/"+state.clientFk+"/"+countyStats.state+"/"+countyStats.county+"/"+state.from+"/"+state.to}>{countyStats.county} - {countyStats.state}</NavLink>
                    </center>
                  </Col>
                  <Col sm="2">
                    <div className="align-right">
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className={className} style={{height:'400px'}}>
                <div className="vertical-align-top">
                  <div className="display-inline">
                    <div className={"auto-assign-box "+colorClass}>
                      <center><i className={icon}></i></center>
                    </div>
                  </div>
                  <div className="display-inline">
                    Assignment Turnaround<br/>
                    <b>{countyStats.avg_accepting} hours</b>
                  </div>
                </div>

                <div></div>

                <div className="vertical-align-top">
                  <div className="display-inline">
                    <div className={"auto-assign-box "+colorClass2}>
                      <center><i className={icon2}></i></center>
                    </div>
                  </div>
                  <div className="display-inline">
                    Success Rate<br/>
                    <b>{countyStats.success_rate}%</b> out of <b>{formatNumber(countyStats.auto_assign_count)}</b>&nbsp;
                    <div id={"tooltip"+index} className="display-inline cursor-pointer"><u><i>processed orders</i></u></div>
                    <UncontrolledTooltip placement="right" target={"tooltip"+index}>
                      Total number of orders that processed by auto assign regardless of success or failed in the end
                    </UncontrolledTooltip>
                  </div>
                </div>
                <div className="my-divider"></div>
                <Row>
                  <Col sm="2">

                  </Col>
                  <Col sm="8">
                    <center>
                      <b>Coverage</b>
                    </center>
                  </Col>
                  <Col sm="2">
                    <i className="fa fa-user"/> {formatNumber(countyStats.total_appraiser)}
                  </Col>
                </Row>
                <div className="text-center"><b>{formatNumber(countyStats.success)}</b> success out of <b>{formatNumber(countyStats.count)}</b> total orders <b>({formatNumber(countyStats.coverage)}%)</b></div>
                <Progress animated color="success" value={countyStats.coverage}/>

                <div className="my-divider"></div>

                <center><b>Reasons for manual assignment</b></center>
                <div className="margin-top manual-reason-container">
                  <div className="manual-reason">
                    <b>Failed to auto assign - Tried all appraisers: {exhausted}</b><br/>
                    <Progress animated color="warning" value={exhaustedRate}/>
                  </div>

                  <div className="manual-reason">
                    <b>Failed to auto assign - Time out: {timeout}</b><br/>
                    <Progress animated color="warning" value={timeoutRate}/>
                  </div>

                  <div className="manual-reason">
                    <Row>
                      <Col sm="10">
                        <b>Skip auto assignment: {skip}</b>
                      </Col>
                      <Col sm="2" className="align-right">
                        <i className="fa fa-question-circle link-color cursor-pointer" id={"skipReasons"+index}></i>
                        <UncontrolledTooltip placement="right" target={"skipReasons"+index}>
                          {skipReasons}
                        </UncontrolledTooltip>
                      </Col>
                    </Row>
                    <Progress animated color="warning" value={skipRate}/>
                  </div>
                </div>
              </CardBody>
            </Card>

          </Col>
        );
      }
    );
  }

  if(state.appraiserStats.length>0){
    appraiserStats = state.appraiserStats.map(
      (appraiserStat,index)=>{

        let totalManual = appraiserStat.exhausted + appraiserStat.timeout + appraiserStat.skip;
        let exhausted = appraiserStat.exhausted;
        let exhaustedRate = formatNumber((exhausted/totalManual)*100);

        let timeout = appraiserStat.timeout;
        let timeoutRate = formatNumber((timeout/totalManual)*100);

        let skip = appraiserStat.skip;
        let skipRate = formatNumber((skip/totalManual)*100);


        let className = '';
        if(appraiserStat.auto_assign_count===0)
          className='no-data';

        let colorClass,colorClass2 = 'green-status';
        let icon,icon2 = 'fa fa-check';

        if(appraiserStat.avg_accepting<4){
          icon = 'fa fa-check';
          colorClass = 'green-status';
        }
        else if(appraiserStat.avg_accepting<6){
          icon = 'fa fa-warning';
          colorClass = 'yellow-status';
        }
        else{
          icon = 'fa fa-times';
          colorClass = 'red-status';
        }

        if(appraiserStat.success_rate>70){
          icon2 = 'fa fa-check';
          colorClass2 = 'green-status';
        }
        else if(appraiserStat.success_rate>50){
          icon2 = 'fa fa-warning';
          colorClass2 = 'yellow-status';
        }
        else{
          icon2 = 'fa fa-times';
          colorClass2 = 'red-status';
        }

        let skipReasons;

        if(appraiserStat.skipDrillDown&&appraiserStat.skipDrillDown.length>0){
          skipReasons = appraiserStat.skipDrillDown.map(
            (reason,index)=>{
              return(
                <div className="align-left" key={index}>
                  <b>{reason.reason+": "+reason.count}</b>
                </div>
              );
            }
          );
        }


        let pauseIcon = <i className="fa fa-play-circle cursor-pointer" onClick={(e)=>{pauseAutoAssign(appraiserStat.baID, appraiserStat.paused)}}/>;

        if(appraiserStat.paused==='yes')
          pauseIcon = <i className="fa fa-pause-circle cursor-pointer" onClick={(e)=>{pauseAutoAssign(appraiserStat.baID, appraiserStat.paused)}}/>;


        return(
          <Col sm="4" key={index} className="margin-bottom-ex">
            <Card>
              <CardHeader className="header-color">
                <Row>
                  <Col sm="2">

                  </Col>
                  <Col sm="8">
                    <center>
                      <NavLink className="no-padding" target="_blank" to={"/auto-assign/appraiser/"+state.clientFk+"/"+appraiserStat.appraiser_fk+"/"+state.from+"/"+state.to}>{appraiserStat.name}</NavLink>
                    </center>
                  </Col>
                  <Col sm="2">
                    <div className="align-right">
                      {pauseIcon}
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className={className} style={{height:'400px'}}>
                <div className="vertical-align-top">
                  <div className="display-inline">
                    <div className={"auto-assign-box "+colorClass}>
                      <center><i className={icon}></i></center>
                    </div>
                  </div>
                  <div className="display-inline">
                    Assignment Turnaround<br/>
                    <b>{appraiserStat.avg_accepting} hours</b>
                  </div>
                </div>

                <div></div>

                <div className="vertical-align-top">
                  <div className="display-inline">
                    <div className={"auto-assign-box "+colorClass2}>
                      <center><i className={icon2}></i></center>
                    </div>
                  </div>
                  <div className="display-inline">
                    Success Rate<br/>
                    <b>{appraiserStat.success_rate}%</b> out of <b>{formatNumber(appraiserStat.auto_assign_count)}</b>&nbsp;
                    <div id={"tooltip"+index} className="display-inline cursor-pointer"><u><i>processed orders</i></u></div>
                    <UncontrolledTooltip placement="right" target={"tooltip"+index}>
                      Total number of orders that processed by auto assign regardless of success or failed in the end
                    </UncontrolledTooltip>
                  </div>
                </div>
                <div className="my-divider"></div>
                <center>
                  <b>Coverage</b>
                </center>
                <div className="text-center"><b>{formatNumber(appraiserStat.success)}</b> success out of <b>{formatNumber(appraiserStat.count)}</b> total orders <b>({formatNumber(appraiserStat.coverage)}%)</b></div>
                <Progress animated color="success" value={appraiserStat.coverage}/>

                <div className="my-divider"></div>

                <center><b>Reasons for manual assignment</b></center>
                <div className="margin-top manual-reason-container">
                  <div className="manual-reason">
                    <b>Failed to auto assign - Tried all appraisers: {exhausted}</b><br/>
                    <Progress animated color="warning" value={exhaustedRate}/>
                  </div>

                  <div className="manual-reason">
                    <b>Failed to auto assign - Time out: {timeout}</b><br/>
                    <Progress animated color="warning" value={timeoutRate}/>
                  </div>

                  <div className="manual-reason">
                    <Row>
                      <Col sm="10">
                        <b>Skip auto assignment: {skip}</b>
                      </Col>
                      <Col sm="2" className="align-right">
                        <i className="fa fa-question-circle link-color cursor-pointer" id={"skipReasons"+index}></i>
                        <UncontrolledTooltip placement="right" target={"skipReasons"+index}>
                          {skipReasons}
                        </UncontrolledTooltip>
                      </Col>
                    </Row>
                    <Progress animated color="warning" value={skipRate}/>
                  </div>
                </div>
              </CardBody>
            </Card>

          </Col>
        );
      }
    );
  }
  return <div className="my-well">
    <Row>
      <Col sm="6">
        <div className="page-title">
          <i className="fa fa-reorder"></i>&nbsp;Auto Assign Dashboard - {state.company}
        </div>
        <NavLink to="/auto-assign">Back To Auto Assign Overview</NavLink>
      </Col>
      <Col sm="6">

      </Col>
    </Row>
    <div className="my-divider"></div>

    <Row>
      <Col sm="3">
        <b>From</b>
        <br/>
        <DatePicker
          className="form-control"
          dateFormat="yyyy/MM/dd"
          selected={newfrom}
          onChange={(text)=>{(text)&&setState({from:text.toLocaleDateString('en-CA')})}}
        />
      </Col>
      <Col sm="2">
        <b>To</b>
        <br/>
        <DatePicker
          className="form-control"
          dateFormat="yyyy/MM/dd"
          selected={newto}
          onChange={(text)=>{(text)&&setState({to:text.toLocaleDateString('en-CA')})}}
        />
      </Col>
      <Col sm="7" className="align-right">
        <br/>
        <Button color="warning" onClick={(e)=>{getLenderStats();getAppraiserStats()}}>Submit</Button>
      </Col>
    </Row>
    <div className="my-divider"></div>



    <Nav tabs>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.activeTab === '1'?"active":"inactive" )}
          onClick={() => { activeTabToggle('1'); }}
          to="#"
        >
          Counties By Counties Perspective
        </NavLinkL>
      </NavItem>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.activeTab === '2'?"active":"inactive" ) }
          onClick={() => { activeTabToggle('2'); }}
          to="#"
        >
          Appraiser By Appraiser Perspective
        </NavLinkL>
      </NavItem>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.activeTab === '3'?"active":"inactive" ) }
          onClick={() => { activeTabToggle('3'); }}
          to="#"
        >
          Panel
        </NavLinkL>
      </NavItem>
    </Nav>
    <TabContent activeTab={state.activeTab}>
      <TabPane tabId="1">
        <br/>
        <Row>
          {countiesStats}
        </Row>
      </TabPane>
      <TabPane tabId="2">
        <br/>
        <Row>
          {appraiserStats}
        </Row>
      </TabPane>
      <TabPane tabId="3">
        <br/>
        <Row className="padding">
          <AutoAssignLenderPanel clientFk={state.clientFk} state={""} county={""} showLoading={props.showLoading} hideLoading={props.hideLoading}/>
        </Row>
      </TabPane>
    </TabContent>
  </div>;
}


export default AutoAssignLenderDashboard;
