//Author Sooyoung Kim
//Date May 24th, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, callBackGenerator, getSession, showMessage, formatDateTime} from '../../util/util';
import {Alert, Col, Row, Collapse, FormGroup, Label, Input, Card, CardHeader, CardBody, Button, Modal, ModalHeader, ModalBody} from 'reactstrap';
import MySelect from '../util/my-select';
import {usePresencePusher } from '../pusher/pusher-context';
import {NavLink, useParams} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';


//initialize the state
const initialState = {
  overallStatus:{
    review_count:0,
    condition_count:0,
    rebuttal_count:0,
    due_invoice_count:0,
    unread_comment_count:0,
    fee_increase_count:0,
    quote_vetting_count:0
  },
  reviews:[],
  reviewCollapse:[],
  clearChecklistComment:'',
  convertChecklistComment:'',
  clearChecklistTarget:[],
  convertChecklistTarget:[],
  clearChecklistPopUpIsOpen:false,
  convertChecklistPopUpIsOpen:false,
  conditionTypes:[],
  selectedConditionType:-1,
  presenceMembers:[],

  messages:[],
  reviewLabels:[],
  newReviewLabels:[],
  reviewLabelList:[],
  reviewLabelPopupOpen:false,

  qualityMetricsPopupOpen:false,
  qualityConditionPopupOpen:false,
  qualityRebuttalPopupOpen:false,
  deductScoreChecklist:-1,
  deductRebuttalScore:'',
  deductScore:false,
  deductScoreComment:'',
  conditionDings:[],
  rebuttalDings:[],
  likes:[],
  selectedBatch:-1,
  selectedSubBatch:-1,
  reviewBatchClear:false,
  reviewBatchCondition:false,
  checklistToClear:[],
  checklistToCondition:[],
  clearDisabled:false,
  convertDisabled:false,
};

//reducer function that perform state update
const reducer = getReducer();


const Review  = (props)=>{
  const controller = new AbortController();
  const presencePusher = usePresencePusher ();
  let session = getSession();
  let {id} = useParams();
  let name = session.userFirstName+' '+session.userLastName;

  let newInitialState = Object.assign({}, initialState, {
    id: id,
    name: name,
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getReviews();
    getRebuttalDings();
    getConditionTypes();
    getReviewLabels();
    getReviewLabelList();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if (presencePusher) {
      const presenceChannel = presencePusher.subscribe('presence-order');

      const handleSubscriptionSucceeded = (members) => {
        // Get the initial user list from presence channel
        let presenceMembers = [];
        members.each(
          function(member)
          {
            let ID = member.info.ID;
            let orderID = member.info.orderID;
            let myID = localStorage.getItem('userID');
            if(orderID===props.appraisalFk){
              if(ID!==myID){
                presenceMembers.push(member);
              }
            }
          }
        );
        console.log(presenceMembers)
        setState({presenceMembers:presenceMembers})
      };

      const handleMemberAdded = (member) => {
        let ID = member.info.ID;
        let orderID = member.info.orderID;
        let myID = localStorage.getItem('userID');
        if(orderID===props.appraisalFk){
          if(ID!==myID){
            let presenceMembers = state.presenceMembers.slice();
            presenceMembers.push(member);

            setState({presenceMembers:presenceMembers});
          }
        }
      };

      const handleMemberRemoved = (member) => {
        // Remove a user from the user list
        let ID = member.info.ID;
        let orderID = member.info.orderID;
        let myID = localStorage.getItem('userID');

        if(orderID===props.appraisalFk){
          if(ID!==myID){
            let presenceMembers = state.presenceMembers.slice();

            for(let i=0;i<presenceMembers.length;i++){
              if(presenceMembers[i].info.ID===member.info.ID){
                presenceMembers.splice(i, 1);
                setState({presenceMembers:presenceMembers});
                break;
              }
            }
          }
        }
      };

      presenceChannel.bind('pusher:subscription_succeeded', handleSubscriptionSucceeded);
      presenceChannel.bind('pusher:member_added', handleMemberAdded);
      presenceChannel.bind('pusher:member_removed', handleMemberRemoved);

      return () => {
        presenceChannel.unbind('pusher:subscription_succeeded', handleSubscriptionSucceeded);
        presenceChannel.unbind('pusher:member_added', handleMemberAdded);
        presenceChannel.unbind('pusher:member_removed', handleMemberRemoved);

        presenceChannel.unsubscribe();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presencePusher, props.appraisalFk]);

  //non API call but simpyl manage state
  const buttonHandler1 = (e) => {
    e.preventDefault();
    
    if(state.clearDisabled){
      return;
    }else{
      setState({clearDisabled:true});
      clearChecklist(e);
    }
  }

  const buttonHandler2 = (e) => {
    e.preventDefault();

    if(state.selectedConditionType===''|| state.selectedConditionType===-1){
      showMessage('error','Please select the condition type.');
      return;
    }
    
    if(state.convertDisabled){
      return;
    }else{
      setState({convertDisabled:true});
      convertChecklist(e);
    }
  }

  const handleChange = (selectedOption) => {
    setState({ newReviewLabels:selectedOption });
  }

  const modifyChecklistToClear = (id) => {
    let checklistToClear = state.checklistToClear.slice();

    let index = checklistToClear.indexOf(id);

    if(index===-1)
      checklistToClear.push(id);
    else
      checklistToClear.splice(index, 1);

    setState({checklistToClear:checklistToClear});
  }

  const modifyChecklistToCondition = (id) => {
    let checklistToCondition = state.checklistToCondition.slice();

    let index = checklistToCondition.indexOf(id);

    if(index===-1)
      checklistToCondition.push(id);
    else
      checklistToCondition.splice(index, 1);

    setState({checklistToCondition:checklistToCondition});
  }

  const handleNewQualityRebuttalSubmit = (e) =>{
    e.preventDefault();

    if(state.deductRebuttalScore===''){
      showMessage('Please select an option.');
    }
    else{
      dingAppraiserRebuttal();
    }
  }

  const toggleReviewBatchClear = () =>{
    setState({reviewBatchClear:!state.reviewBatchClear});
  }

  const toggleReviewBatchCondition = () =>{
    setState({reviewBatchCondition:!state.reviewBatchCondition});
  }

  const toggleQualityRebuttalPopUp = () =>{
    setState({qualityRebuttalPopupOpen:!state.qualityRebuttalPopupOpen});
  }

  const toggleReviewLabelPopUp = () =>{
    setState({reviewLabelPopupOpen:!state.reviewLabelPopupOpen});
  }

  const clearChecklistPopUpToggle = (id) =>{
    let open = state.clearChecklistPopUpIsOpen;
    setState({clearChecklistPopUpIsOpen:!state.clearChecklistPopUpIsOpen});
    if(open){
      setState({clearChecklistTarget:[], clearChecklistComment:[], clearDisabled:false});
    }
    else{
      clearCheckListPopUp(id);
    }
  }

  const convertChecklistPopUpToggle = (id) =>{
    let open = state.convertChecklistPopUpIsOpen;
    setState({convertChecklistPopUpIsOpen:!state.convertChecklistPopUpIsOpen});
    if(open){
      setState({convertChecklistTarget:[], convertChecklistComment:[], convertDisabled:false});
    }
    else{
      convertChecklistPopUp(id);
    }
  }

  const clearCheckListPopUp = (id) =>{
    let reviews = state.reviews.slice();

    for(let i=0;i<reviews.length;i++){
      let checklists = reviews[i].checklists.slice();
      for(let j=0;j<checklists.length;j++){
        if(checklists[j].ID===id){
          setState({clearChecklistTarget:checklists[j]});
          break;
        }
      }
    }
  }

  const convertChecklistPopUp = (id) =>{
    let reviews = state.reviews.slice();
    let targetChecklist;
    for(let i=0;i<reviews.length;i++){
      let checklists = reviews[i].checklists.slice();
      for(let j=0;j<checklists.length;j++){
        if(checklists[j].ID===id){
          targetChecklist = checklists[j];
          break;
        }
      }
    }
    setState({convertChecklistComment:targetChecklist.details+"\n\n"+targetChecklist.instructions, convertChecklistTarget:targetChecklist});
  }

  const selectReview = (code) =>{
    let reviewCollapse = state.reviewCollapse.slice();

    let index=reviewCollapse.indexOf(code);
    if(index===-1)
      reviewCollapse.push(code);
    else
      reviewCollapse.splice(index, 1);
    setState({reviewCollapse:reviewCollapse});
  }

  //API call
  const batchClear = (e) =>{
    e.preventDefault();

    let checklistToClear = state.checklistToClear.slice();
    let checklistToClearCounter = checklistToClear.length;
    for(let i=0;i<checklistToClear.length;i++){
      let parameters = [
        {
          field:'ID',
          value:checklistToClear[i]
        },
        {
          field:'comment',
          value:state.batchClearComment
        }
      ];

      // eslint-disable-next-line no-loop-func
      let callBack = (response)=>{
        let code= response.data.code;

        if(code==='00'){
          checklistToClearCounter--;

          if(checklistToClearCounter<=0)
            toggleReviewBatchClear();

          let reviews = state.reviews.slice();
          let target = reviews[state.selectedBatch];
          let targetChecklist = target.checklists[state.selectedSubBatch].checklists;

          for(let j=0;j<targetChecklist.length;j++){
            if(targetChecklist[j].ID===checklistToClear[i]){
              targetChecklist[j].status='Cleared';
              target.checklists[state.selectedSubBatch].checklists = targetChecklist;
              reviews[state.selectedBatch] = target;
              break;
            }
          }

          setState({reviews:reviews});
        }
      };
      callBack = callBack.bind(this);

      httpPost('review/checklist/clear', parameters, 'Checkclist cleared successfully.', 'Oops, something went wrong and could not clear the checklist. Please try again later.', callBack);
    }
  }

  const batchCondition = (e) =>{
    e.preventDefault();
    if(state.selectedConditionType===''|| state.selectedConditionType===-1){
      showMessage('error','Please select the condition type.');
      return;
    }

    let checklistToCondition = state.checklistToCondition.slice();
    let parameters = [
      {
        field:'IDs',
        value:checklistToCondition
      },
      {
        field:'appraisalFk',
        value:props.appraisalFk
      },
      {
        field:'conditionTypeFk',
        value:state.selectedConditionType
      },
      {
        field:'subjectFacts',
        value:state.batchConditionComment
      }
    ];


    let callBack = (response)=>{
      let code= response.data.code;

      if(code==='00'){
        toggleReviewBatchCondition();

        let reviews = state.reviews.slice();
        let target = reviews[state.selectedBatch];
        let targetChecklist = target.checklists[state.selectedSubBatch].checklists;
        for(let i=0;i<checklistToCondition.length;i++){
          for(let j=0;j<targetChecklist.length;j++){
            if(targetChecklist[j].ID===checklistToCondition[i]){
              targetChecklist[j].status='Conditioned';
              target.checklists[state.selectedSubBatch].checklists = targetChecklist;
              reviews[state.selectedBatch] = target;
              break;
            }
          }
        }


        setState({reviews:reviews});
      }
    };
    callBack = callBack.bind(this);

    httpPost('review/checklist/batch/convert', parameters, 'Checkclist convert successfully.', 'Oops, something went wrong and could not convert the checklist. Please try again later.', callBack);
  }

  const clearChecklist = (e) =>{
    e.preventDefault();
    let parameters = [
      {
        field:'ID',
        value:state.clearChecklistTarget.ID
      },
      {
        field:'comment',
        value:state.clearChecklistComment
      }
    ];

    let callBack = (response)=>{
      let code= response.data.code;
      if(code==='00'){
        let today = new Date();
        let todayFormatted = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate()+' '+(today.getHours()>10?today.getHours():'0'+today.getHours())+':'+(today.getMinutes()>10?today.getMinutes():'0'+today.getMinutes())+':'+(today.getSeconds()>10?today.getSeconds():'0'+today.getSeconds());
        let reviews = state.reviews.slice();

        for(let i=0;i<reviews.length;i++){
          for(let j=0;j<reviews[i].checklists.length;j++){
            if(reviews[i].checklists[j].ID===state.clearChecklistTarget.ID){
              reviews[i].checklists[j].status= 'Cleared';
              reviews[i].checklists[j].comment= state.clearChecklistComment;
              reviews[i].checklists[j].datetime_converted= todayFormatted;
              reviews[i].checklists[j].converter_name= state.name;
              console.log(reviews[i].checklists[j])
              break;
            }
          }
        }
        setState({reviews:reviews});
        clearChecklistPopUpToggle();
      }
    };
    callBack = callBack.bind(this);

    httpPost('review/checklist/clear', parameters, 'Checkclist cleared successfully.', 'Oops, something went wrong and could not clear the checklist. Please try again later.', callBack);
  }

  const dingAppraiserRebuttal = () =>{
    let parameters = [
      {
        field:'score',
        value:state.deductRebuttalScore
      },
      {
        field:'comment',
        value:state.deductScoreComment
      },
      {
        field:'appraisalFk',
        value:props.appraisalFk
      },
    ];

    let callBack = apiCallBack([{state:'deductScoreComment', value:''}, {state:'qualityRebuttalPopupOpen', value:false}], getRebuttalDings());
    httpPost('appraiser/quality/rebuttal', parameters, 'Appraiser score submitted.', 'Oops, something went wrong and could not submit the score. Please try again later.', callBack);
  }

  const convertChecklist = (e) =>{
    e.preventDefault();

    let parameters = [
      {
        field:'ID',
        value:state.convertChecklistTarget.ID
      },
      {
        field:'conditionTypeFk',
        value:state.selectedConditionType
      },
      {
        field:'subjectFacts',
        value:state.convertChecklistComment
      }
    ];

    let callBack = (response)=>{
      let code= response.data.code;

      if(code==='00'){
        let reviews = state.reviews.slice();
        for(let i=0;i<reviews.length;i++){
          for(let j=0;j<reviews[i].checklists.length;j++){
            if(reviews[i].checklists[j].ID===state.convertChecklistTarget.ID){
              reviews[i].checklists[j].status= 'Conditioned';
              break;
            }
          }
        }
        convertChecklistPopUpToggle(state.convertChecklistTarget.ID);
        //update the overall status
        let overallStatus = Object.assign({},state.overallStatus);
        overallStatus.condition_count+=1;

        setState({reviews:reviews});
        props.updateOverallStatus(overallStatus);
      }
    };
    callBack = callBack.bind(this);

    httpPost('review/checklist/convert', parameters, 'Checklist converted successfully.', 'Oops, something went wrong and could not convert the checklist. Please try again later.', callBack);
  }

  //get condition types
  const getConditionTypes = () =>{
    let callBack = apiCallBack([{state:'conditionTypes', key:'data'}]);
    httpGet('condition/type/get', '', 'Oops, something went wrong and could not load condition types. Please try again later.', callBack);
  }

  //get reviews
  const getReviews = () =>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        //group review by rule
        let rulesMapping = {};

        for(let i=0;i<response.data.data.length;i++){
          let review = response.data.data[i];
          let checklists = review.checklists;

          for(let j=0;j<checklists.length;j++){
            if(!rulesMapping[checklists[j].code]){
              rulesMapping[checklists[j].code] = {
                "code":checklists[j].code,
                "description":"",
                "custom_rule":"",
                "checklists":[],
                "datetime_created":"",
                "datetime_converted":"",
                "status":""
              }
            }

            rulesMapping[checklists[j].code].checklists.push(checklists[j]);
            rulesMapping[checklists[j].code].datetime_created = checklists[j].datetime_created;
            rulesMapping[checklists[j].code].datetime_converted = checklists[j].datetime_converted;
            rulesMapping[checklists[j].code].status = checklists[j].status;
            rulesMapping[checklists[j].code].description = checklists[j].description;
            rulesMapping[checklists[j].code].custom_rule = checklists[j].custom_rule;
          }
        };


        let reviews = [];

        Object.keys(rulesMapping).forEach(key => {
          let tmp = rulesMapping[key];
          tmp['code'] = key;

          let checklists = tmp.checklists;
          let status = 'Cleared';
          for(let i=0;i<checklists.length;i++){
            if(checklists[i].status==='Pending')
              status = 'Pending';
          }

          tmp.status = status;

          reviews.push(tmp);
        });

        //special handling for 2500 and 2501
        //group by review report fk
        if(rulesMapping['2500']){
          let checklists = rulesMapping['2500'].checklists;
          let newChecklists = {};

          for(let i=0;i<checklists.length;i++){
            if(!newChecklists[checklists[i].review_report_fk]){
              newChecklists[checklists[i].review_report_fk] = [];
            }
            newChecklists[checklists[i].review_report_fk].push(checklists[i]);
          }

          let tmp = newChecklists;
          newChecklists = [];
          Object.keys(tmp).forEach(key => {
            let checklists = tmp[key];
            let date = '';
            let status='Cleared';

            for(let i=0;i<checklists.length;i++){
              date = checklists[i].datetime_created;
              if(checklists[i].status==='Pending')
                status = 'Pending';
            }
            newChecklists.push({status:status, date:date, checklists:checklists});
          });

          rulesMapping['2500'].checklists = newChecklists;
        }
        if(rulesMapping['2501']){
          let checklists = rulesMapping['2501'].checklists;
          let newChecklists = {};

          for(let i=0;i<checklists.length;i++){
            if(!newChecklists[checklists[i].review_report_fk]){
              newChecklists[checklists[i].review_report_fk] = [];
            }
            newChecklists[checklists[i].review_report_fk].push(checklists[i]);
          }

          let tmp = newChecklists;
          newChecklists = [];
          Object.keys(tmp).forEach(key => {
            let checklists = tmp[key];
            let date = '';
            let status='Cleared';
            for(let i=0;i<checklists.length;i++){
              date = checklists[i].datetime_created;
              if(checklists[i].status==='Pending')
                status = 'Pending';
            }
            newChecklists.push({status:status, date:date, checklists:checklists});
          });

          rulesMapping['2501'].checklists = newChecklists;
        }

        console.log(reviews);
        setState({reviews:reviews});
      }
    };
    callBack = callBack.bind(this);
    httpGet('review/get/'+props.appraisalFk, '', 'Oops, something went wrong and could not load the reviews of this appraisal. Please try again later.', callBack);
  }

  const getRebuttalDings = () =>{
    let callBack = apiCallBack([{state:'rebuttalDings', key:'data'}]);
    httpGet('appraiser/quality/rebuttal/'+props.appraisalFk, '', 'Oops, something went wrong and could not load the reviews of this appraisal. Please try again later.', callBack);
  }

  //get review labels to identify orders that have been reviewed
  const getReviewLabels = () =>{
    let callBack = apiCallBack([{state:'reviewLabels', valuekey:['label', 'label']}]);
    httpGet('review/label/get/'+props.appraisalFk, '', 'Oops, something went wrong and could not load review labels. Please try again later.', callBack);
  }

  //get review label list
  const getReviewLabelList = () =>{
    let callBack = apiCallBack([{state:'reviewLabelList', valuekey:['label', 'label']}]);
    httpGet('review/label/list/get', '', 'Oops, something went wrong and could not load list of review labels. Please try again later.', callBack);
  }

  const modifyReviewLabel = (e) =>{
    e.preventDefault();
    let parameters = [
      {
        field:'appraisalFk',
        value:props.appraisalFk
      },
      {
        field:'labels',
        value:state.newReviewLabels
      },
    ];

    let callBack = apiCallBack([{state:'reviewLabels', valuekey:['label', 'label']}], toggleReviewLabelPopUp());
    httpPut('review/label/update', parameters, 'Review Labels has been modified.', 'Oops, something went wrong and could not modify review labels. Please try again later.', callBack);
  }

  //render
  let clearPopUp, convertPopUp;

  clearPopUp =
  <Modal className="my-modal" isOpen={state.clearChecklistPopUpIsOpen} toggle={clearChecklistPopUpToggle} >
    <ModalHeader hidden={true} toggle={clearChecklistPopUpToggle}></ModalHeader>
    <ModalBody>
      <form onSubmit={buttonHandler1}>
        <center>
          <h5><i className="fa fa-check"></i> Clearing checklist</h5>
        </center>
        <br/>
        <b>{state.clearChecklistTarget.code+' - '+state.clearChecklistTarget.description}</b>
        <br/><br/>
        {state.clearChecklistTarget.details}
        <br/><label>Comment</label>
        <Input type="textarea" required="true" rows="7" style={{resize:'none'}} value={state.clearChecklistComment} onChange={(e)=>setState({clearChecklistComment:e.target.value})}/>
        <br/>
        <center>
          <Button color="info" onClick={clearChecklistPopUpToggle}>Close</Button>
          &nbsp;<Button color="warning" disabled={state.clearDisabled}>Clear</Button>
        </center>
      </form>
    </ModalBody>
  </Modal>;

  convertPopUp =
  <Modal className="my-modal" isOpen={state.convertChecklistPopUpIsOpen} toggle={convertChecklistPopUpToggle} >
    <ModalHeader hidden={true} toggle={convertChecklistPopUpToggle}></ModalHeader>
    <ModalBody>
      <form onSubmit={buttonHandler2}>
        <center>
          <h5><i className="fa fa-refresh"></i> Converting checklist</h5>
        </center>
        <br/>
        <b>{state.convertChecklistTarget.code+' - '+state.convertChecklistTarget.description}</b>
        <br/><br/>
        <label>Condition Type</label>
        <MySelect
          type="select"
          required={true}
          value={state.selectedConditionType}
          onChange={(v)=>{setState({selectedConditionType:v})}}
          options={state.conditionTypes.map((conditionType)=>{
            return {label:conditionType.type, value:conditionType.ID};
          })}
        />
        <label>Subject facts</label>
        <Input type="textarea" required="true" rows="7" style={{resize:'none'}} value={state.convertChecklistComment} onChange={(e)=>setState({convertChecklistComment:e.target.value})}/>

        <br/>
        <center>
          <Button color="info" onClick={convertChecklistPopUpToggle}>Close</Button>
          &nbsp;<Button color="warning" disabled={state.convertDisabled}>Convert</Button>
        </center>
      </form>
    </ModalBody>
  </Modal>

  let reviews = <div>No rule triggered</div>;

  if(state.reviews.length>0){
    reviews = state.reviews.map(
      (review, index)=>{
        let status;

        if(review.status==="Cleared")
          status = <i className="fa fa-check green-color"/>;
        else if(review.status==="Conditioned")
          status = <i className="fa fa-exchange orange-color"/>;
        else
          status = <i className="fa fa-clock-o red-color"/>;

        let checklists = review.checklists.map(
          (checklist,index)=>{
            let actionPanel;

            if(checklist.status==='Pending')
              actionPanel = <div className="align-right">
                <Button color="info" onClick={()=>clearChecklistPopUpToggle(checklist.ID)}>Clear</Button>&nbsp;
                <Button color="warning" onClick={()=>convertChecklistPopUpToggle(checklist.ID)}>Condition</Button>
              </div>;
            else if(checklist.status==='Cleared')
              actionPanel = <div className="align-right">
                <Button color="warning" onClick={()=>convertChecklistPopUpToggle(checklist.ID)}>Condition</Button>
              </div>;


            let icon;
            let clearComment;

            if(checklist.status==='Cleared' || checklist.status==='Conditioned'){
              if(checklist.comment!==''){
                clearComment = <div>
                  <br/>
                  <b>{checklist.convter_name!==''?checklist.status+' by '+checklist.converter_name:checklist.status} @ {formatDateTime(checklist.datetime_converted)}</b><br/>
                  <div className="my-well margin-bottom">
                    <i>{checklist.comment}</i>
                  </div>
                </div>
              }
            }

            if(checklist.status==='Cleared')
              icon = <i className="fa fa-check green-color"></i>;
            else if(checklist.status==='Conditioned')
              icon = <i className="fa fa-exchange orange-color"></i>;
            else if(checklist.status==='Pending')
              icon = <i className="fa fa-clock-o red-color"></i>;

            let customRule;

            if(checklist.custom_rule && checklist.custom_rule!=='')
              customRule = <div>
                <b>Dynamic Rule</b><br/>
                <div className="my-well margin-bottom" dangerouslySetInnerHTML={{__html:checklist.custom_rule}}></div>
              </div>
            return(
              <div key={index}>
                <Card>
                  <CardHeader className="gray-color">
                    <Row>
                      <Col sm="8">
                        {icon} {formatDateTime(checklist.datetime_created)}
                        <br/>
                      </Col>
                      <Col sm="4" className="align-right">
                        {checklist.status}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>

                    {customRule}

                    <div>
                      <b>Triggered Rule Details</b><br/>
                      <div className="my-well margin-bottom" dangerouslySetInnerHTML={{__html:checklist.details!==''?checklist.details:'-'}}></div>
                    </div>
                    {clearComment}

                    {actionPanel}
                  </CardBody>
                </Card>
                <br/>
              </div>
            );
          }
        );

        if(review.code==='2500'||review.code==='2501'){
          let allCleared = true;
          let allSettled = true;

          checklists = review.checklists.map(
            (checklistByReviewReport,index2)=>{
              let icon;
              if(checklistByReviewReport.status==='Cleared'){
                icon = <i className="fa fa-check green-color"></i>;
                allSettled = false;
              }
              else if(checklistByReviewReport.status==='Conditioned')
                icon = <i className="fa fa-exchange orange-color"></i>;
              else if(checklistByReviewReport.status==='Pending'){
                icon = <i className="fa fa-clock-o red-color"></i>;
                allCleared = false;
                allSettled = false;
              }

              let clearButton = <Button color="info" onClick={(e)=>{setState({selectedBatch:index, selectedSubBatch:index2},toggleReviewBatchClear);}}>Clear</Button>;
              let conditionButton = <Button color="warning" onClick={(e)=>{setState({selectedBatch:index, selectedSubBatch:index2},toggleReviewBatchCondition);}}>Condition</Button>;

              if(allCleared)
                clearButton = null;
              if(allSettled)
                conditionButton = null;

              return (
                <div key={index2}>
                  <Card>
                    <CardHeader className="gray-color">
                      <Row>
                        <Col sm="8">
                          {icon} {formatDateTime(checklistByReviewReport.date)}
                          <br/>
                        </Col>
                        <Col sm="4" className="align-right">
                          {checklistByReviewReport.status}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <div>
                        <b>Triggered Rule Details</b><br/>
                        {
                          checklistByReviewReport.checklists.map(
                            (checklistEntry, index3)=>{
                              let icon;
                              if(checklistEntry.status==='Cleared')
                                icon = <i className="fa fa-check green-color"></i>;
                              else if(checklistEntry.status==='Conditioned')
                                icon = <i className="fa fa-exchange orange-color"></i>;
                              else if(checklistEntry.status==='Pending')
                                icon = <i className="fa fa-clock-o red-color"></i>;

                              let clearComment = <div/>;

                              if(checklistEntry.status==='Cleared' || checklistEntry.status==='Conditioned'){
                                if(checklistEntry.comment!==''){
                                  clearComment = <div>
                                    {checklistEntry.convter_name!==''?checklistEntry.status+' by '+checklistEntry.converter_name:checklistEntry.status} @ {formatDateTime(checklistEntry.datetime_converted)}
                                    &nbsp;<i>"{checklistEntry.comment}"</i>
                                  </div>
                                }
                              }

                              return <div key={index3}>
                                {icon} <div className="display-inline" dangerouslySetInnerHTML={{__html:checklistEntry.details!==''?checklistEntry.details:'-'}}></div>{clearComment}
                                <br/>
                              </div>
                            }
                          )
                        }
                      </div>
                      <div className="align-right">
                        {clearButton}&nbsp;{conditionButton}
                      </div>
                    </CardBody>
                  </Card>
                  <br/>
                </div>
              );
            }
          );
        }

        return (
          <div key={index}>
            <Card>
              <CardHeader className="header-color cursor-pointer" onClick={()=>selectReview(review.code)}>
                <Row>
                  <Col sm="9">
                    {status} {review.code} - {review.description.length<100?review.description.replace(/<\/?[^>]+(>|$)/g, ""):review.description.substr(0, 99).replace(/<\/?[^>]+(>|$)/g, "")+'...'}
                  </Col>
                  <Col sm="3" className="align-right">
                    <div>
                      <i className={(state.reviewCollapse.indexOf(review.code)!==-1)?"fa fa-chevron-up":"fa fa-chevron-down"}></i>
                    </div>
                  </Col>
                  <Col sm="4" className="align-right">

                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Collapse isOpen={state.reviewCollapse.indexOf(review.code)!==-1}>
                  {checklists}
                </Collapse>
              </CardBody>
            </Card>
          </div>
        )
      }
    )
  }
  let reviewLabels;

  if(state.reviewLabels.length>0)
    reviewLabels = state.reviewLabels.map(
      (label, index)=>{
        return(
          <div key={index} className="display-inline margin-top">
            <div className="filter-container">
              {label.key}
            </div>
            &nbsp;
          </div>
        );
      }
    );

  let selectedBatchChecklist;
  let selectedBatchChecklistCondition;

  if(state.reviews[state.selectedBatch]){
    selectedBatchChecklist = state.reviews[state.selectedBatch].checklists[state.selectedSubBatch].checklists.map(
      (checklistEntry, index)=>{
        if(checklistEntry.status!=='Pending')
          return null;
        return <div key={index}>
          <FormGroup check>
            <Label check>
              <Input type="checkbox" checked={state.checklistToClear.indexOf(checklistEntry.ID)!==-1} onClick={()=>modifyChecklistToClear(checklistEntry.ID)}/>&nbsp;{checklistEntry.description}
            </Label>
          </FormGroup>
        </div>
      }
    );
  }

  if(state.reviews[state.selectedBatch]){
    selectedBatchChecklistCondition = state.reviews[state.selectedBatch].checklists[state.selectedSubBatch].checklists.map(
      (checklistEntry, index)=>{
        if(checklistEntry.status==='Conditioned')
          return null;
        return <div key={index}>
          <FormGroup check>
            <Label check>
              <Input type="checkbox" checked={state.checklistToCondition.indexOf(checklistEntry.ID)!==-1} onClick={()=>modifyChecklistToCondition(checklistEntry.ID)}/>&nbsp;{checklistEntry.description}
            </Label>
          </FormGroup>
        </div>
      }
    );
  }

  return <div>
    {convertPopUp}
    {clearPopUp}
    <Modal className="my-modal" isOpen={state.reviewBatchClear} toggle={toggleReviewBatchClear} >
      <ModalHeader hidden={true} toggle={toggleReviewBatchClear}></ModalHeader>
      <ModalBody>
        <form onSubmit={batchClear}>
          <center>
            <h5><i className="fa fa-check"></i> Clearing Checklist</h5>
          </center>
          <br/>

          <b>{state.reviews[state.selectedBatch]?state.reviews[state.selectedBatch].code:''} - <div className="display-inline" dangerouslySetInnerHTML={{__html:state.reviews[state.selectedBatch]?state.reviews[state.selectedBatch].description:''}}/></b>
          <br/><br/>
          Please select which checklists you want to clear.
          <div className="medium-scroll-container my-well">
            {selectedBatchChecklist}
          </div>
          <br/><br/>
          <label>Comment</label>
          <Input type="textarea" required={true} placeholder="" value={state.batchClearComment} onChange={(e)=>setState({batchClearComment:e.target.value})}/>
          <br/>

          <br/><br/><br/><br/><br/><br/>
          <center>
            <Button color="info" onClick={(e)=>{e.preventDefault();toggleReviewBatchClear();}}>Close</Button>&nbsp;<Button color="warning">Clear</Button>
          </center>
        </form>
      </ModalBody>
    </Modal>

    <Modal className="my-modal" isOpen={state.reviewBatchCondition} toggle={toggleReviewBatchCondition} >
      <ModalHeader hidden={true} toggle={toggleReviewBatchCondition}></ModalHeader>
      <ModalBody>
        <form onSubmit={batchCondition}>
          <center>
            <h5><i className="fa fa-check"></i> Condition Checklist</h5>
          </center>
          <br/>
          <MySelect
            modal={true}
            type="select"
            value={state.selectedConditionType}
            onChange={(v)=>{setState({selectedConditionType:v})}}
            options={state.conditionTypes.map((conditionType)=>{
              return {label:conditionType.type, value:conditionType.ID};
            })}
          /><br/>

          <b>{state.reviews[state.selectedBatch]?state.reviews[state.selectedBatch].code:''} - <div className="display-inline" dangerouslySetInnerHTML={{__html:state.reviews[state.selectedBatch]?state.reviews[state.selectedBatch].description:''}}/></b>
          <br/><br/>
          Please select which checklists you want to condition.
          <div className="medium-scroll-container my-well">
            {selectedBatchChecklistCondition}
          </div>
          <br/><br/>
          <label>Comment</label>
          <Input type="textarea" required={true} placeholder="" value={state.batchConditionComment} onChange={(e)=>setState({batchConditionComment:e.target.value})}/>
          <br/>

          <br/><br/><br/><br/><br/><br/>
          <center>
            <Button color="info" onClick={(e)=>{e.preventDefault();toggleReviewBatchCondition();}}>Close</Button>&nbsp;<Button color="warning">Condition</Button>
          </center>
        </form>
      </ModalBody>
    </Modal>
    <Modal className="my-modal" isOpen={state.reviewLabelPopupOpen} toggle={toggleReviewLabelPopUp} >
      <ModalHeader hidden={true} toggle={toggleReviewLabelPopUp}></ModalHeader>
      <ModalBody>
        <form onSubmit={modifyReviewLabel}>
          <center>
            <h5><i className="fa fa-check"></i> Add/Remove Review Labels</h5>
          </center>
          <br/>
          <Row>
            <Col sm="12" className="align-left">
              <label>Review Label(s):</label>
              <MySelect
                isMulti
                type="select"
                selectIsClearable={true}
                defaultValue={state.reviewLabels.map((label)=>{
                  return {label:label.value, value:label.value};
                })}
                onChange={(v)=>handleChange(v)}
                options={state.reviewLabelList.map((label)=>{
                  return {label:label.value, value:label.value};
                })}
              />
            </Col>
          </Row>
          <br/><br/><br/><br/><br/><br/>
          <center>
            <Button color="info" onClick={(e)=>{e.preventDefault();toggleReviewLabelPopUp();}}>Close</Button>&nbsp;<Button color="warning">Save</Button>
          </center>
        </form>
      </ModalBody>
    </Modal>
    <Modal className="my-modal" isOpen={state.qualityRebuttalPopupOpen} toggle={toggleQualityRebuttalPopUp} >
      <ModalHeader hidden={true} toggle={toggleQualityRebuttalPopUp}></ModalHeader>
      <ModalBody>
        <form onSubmit={handleNewQualityRebuttalSubmit}>
          <center>
            <h5><i className="fa fa-check"></i> New Rebuttal Score Deduction</h5>
          </center>
          <label>Select an option</label><br/>
          <div className={state.deductRebuttalScore===-1?"my-well cursor-pointer link-color":"my-well cursor-pointer"} onClick={(e)=>setState({deductRebuttalScore:-1})}><i className="fa fa-minus red-color"/> 1 Point</div>
          <div className={state.deductRebuttalScore===-2?"my-well cursor-pointer link-color":"my-well cursor-pointer"} onClick={(e)=>setState({deductRebuttalScore:-2})}><i className="fa fa-minus red-color"/> 2 Point</div>
          <div className={state.deductRebuttalScore===1?"my-well cursor-pointer link-color":"my-well cursor-pointer"} onClick={(e)=>setState({deductRebuttalScore:1})}><i className="fa fa-plus green-color"/> 1 Point</div>
          <div className={state.deductRebuttalScore===2?"my-well cursor-pointer link-color":"my-well cursor-pointer"} onClick={(e)=>setState({deductRebuttalScore:2})}><i className="fa fa-plus green-color"/> 2 Point</div>
          <br/>
          <label>Comment</label>
          <Input type="textarea" placeholder="Optional" value={state.deductScoreComment} onChange={(e)=>setState({deductScoreComment:e.target.value})}/>
          <br/>
          <center>
            <Button color="info" onClick={(e)=>{e.preventDefault();toggleQualityRebuttalPopUp();}}>Close</Button>&nbsp;<Button color="warning">Submit</Button>
          </center>
        </form>
      </ModalBody>
    </Modal>

    
    <Card  style={{borderTop:'0px'}}>
      <CardHeader className="header-color">
        <i className="fa fa-home"></i>&nbsp;{props.appraisal.reference_num} - {props.appraisal.property_street} {props.appraisal.property_city}, {props.appraisal.property_state} {props.appraisal.property_zip} - {props.appraisal.borrower_f_name} {props.appraisal.borrower_l_name}
      </CardHeader>
      <CardBody>
      {
        props.messages.map(
          (message, index)=>{
            if(message.link){
              return(<a key={index} href={'/'+message.link+'/'+message.ID} target="_blank" rel="noreferrer"><Alert key={index} color="warning" className="my-alert">
                  <i className="fa fa-information"></i> {message.msg}
                </Alert></a>);
            }

            return(<Alert key={index} color="warning" className="my-alert">
                <i className="fa fa-information"></i> {message.msg}
              </Alert>);
          }
        )
      }
      <br/>
      <Row>
        <Col sm="10">
            <label>Review Labels:</label> {reviewLabels}
        </Col>
        <Col sm="2" className="align-right">
          <NavLink to="#" onClick={toggleReviewLabelPopUp}><i className="fa fa-plus green-color"></i> Modify Labels</NavLink>
        </Col>
      </Row>
      <br/>
      {reviews}
      </CardBody>
    </Card>
  </div>;
}

export default Review;
