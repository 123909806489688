//Author Sooyoung Kim
//Date Nov 27, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, deleteAPICallGenerator, callBackGenerator, confirmation,getRoles, sliceFromArray} from '../../util/util';
import {Card, CardHeader, CardBody, Row, Col, Table, Nav, NavItem, NavLink as NavLinkTab, TabPane, TabContent, Modal, ModalHeader, ModalBody, Input, Button} from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import React, {useReducer, useEffect} from 'react';
import {NavLink, useParams} from 'react-router-dom';
import MySelect from '../util/my-select';
import Toggle from 'react-toggle'

//initialize the state
const initialState = {
  activation: false,
  ID: -1,
  lat:'',
  lng:'',
  panels:{},
  clients:[],
  client:'',
  searchResults:[],
  activePanelTab:'0',
  newPanelDropDownOpen:false,
  newAppraiserPanelDropDownOpen:false,
  respondTime:'',
  maxCapacity:'',
  selectedAppraiser:{},

  limit: 25,
  offset: 0,
  hasMoreAppraisers: true,
  loading: false,
  sort: 'first_name',
  order: 'ASC',
  status: '',
  statuses: [],
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],

  //for search
  searchName:'',
  searchCity:''
};

//reducer function that perform state update
const reducer = getReducer();

const AutoAssignCountyPanel  = (props)=>{
  const controller = new AbortController();
  let {stateName, county} = useParams();

  let newInitialState = Object.assign({}, initialState, {
    stateName:stateName,
    county:county,
    searchState:stateName,
    searchCounty:county,
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    //getCountyLongLat();
    //getCountyPanel();
    //getAllClientProfiles();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    if(state.searchResults.length<=0 && state.hasMoreAppraisers){
      //loadMore();
    }
  },[state]);


  //non API call but simpyl manage state
  
  const handleActivationChange = (e) => {
    setState({activation:e.target.checked})
    updateZoneActivation(e.target.checked);
  }

  const newPanelDropDownToggle = () => {
    setState({newPanelDropDownOpen:!state.newPanelDropDownOpen});
  }

  const newAppraiserPanelDropDownToggle = () => {
    if(!state.newAppraiserPanelDropDownOpen){
      refreshList();
      setState({newAppraiserPanelDropDownOpen:!state.newAppraiserPanelDropDownOpen});
    }
    else {
      setState({
        offset:0, 
        loading:true, 
        hasMoreAppraisers:true,
        newAppraiserPanelDropDownOpen:!state.newAppraiserPanelDropDownOpen
      });
      setTimeout(()=>setState({loading:false}),1000)
    }
  }

  const setSelected = (appraiser) => {
    setState({selectedAppraiser:appraiser});
  }

  //this function refresh the list of orders pipeline
  const refreshList = () => {
    setState({
      searchResults:[],
      hasMoreAppraisers:true,
      offset:0
    });
  }

  const activePanelTabToggle = (index) => {
    setState({activePanelTab:index});
  }

  const columnClickHandler = (col) => {
    if(state.sort===col){
      if(state.order==='ASC')
        setState({order:'DESC'});
      else
        setState({order:'ASC'});
    }
    else
      setState({sort:col,order:'ASC'});
    refreshList();
  }


  //render function for infinite scroller
  const renderAppraisers = () => {

    return (
      <div>
        <table className="primary-table">
          <thead>
            <tr>
              <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('first_name')}><i className="fa fa-user"></i>&nbsp;First Name {renderSortIcon('first_name')}</th>
              <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('last_name')}><i className="fa fa-user"></i>&nbsp;Last Name {renderSortIcon('last_name')}</th>
              <th className="cursor-pointer" width="17%" onClick={()=>columnClickHandler('license_type')}><i className="fa fa-user"></i>&nbsp;License Type {renderSortIcon('license_type')}</th>
              <th className="cursor-pointer" width="12%" onClick={()=>columnClickHandler('status')}><i className="fa fa-info"></i>&nbsp;Status {renderSortIcon('status')}</th>
              <th className="cursor-pointer" width="12%" onClick={()=>columnClickHandler('city')}><i className="fa fa-map-marker"></i>&nbsp;City {renderSortIcon('city')}</th>
              <th className="cursor-pointer" width="17%" onClick={()=>columnClickHandler('email')}><i className="fa fa-envelope-open"></i>&nbsp;Email {renderSortIcon('email')}</th>
              <th className="cursor-pointer" width="17%" onClick={()=>columnClickHandler('phone')}><i className="fa fa-phone"></i>&nbsp;Phone {renderSortIcon('phone')}</th>
            </tr>
          </thead>
          <tbody>
          {
            state.searchResults.map(
              (appraiser)=>{
                return (
                  <tr key={appraiser.ID} onClick={()=>setSelected(appraiser)}>
                    <td>{appraiser.first_name}</td>
                    <td>{appraiser.last_name}</td>
                    <td>{appraiser.license_type}</td>
                    <td>{appraiser.status}</td>
                    <td>{appraiser.city}</td>
                    <td>{appraiser.email}</td>
                    <td>{appraiser.phone}</td>
                  </tr>
                );
              }
            )
          }
          </tbody>
        </table>
      </div>
    )
  }

  //this function render the sort icon at each column of the table
  const renderSortIcon = (col) => {
    if(state.sort===col){
      if(state.order==='ASC')
        return <i className="red-color fa fa-arrow-down"></i>
      else
        return <i className="red-color fa fa-arrow-up"></i>
    }
  }

  //API 
  const updateZoneActivation = (activation) => {
    let parameters = [
      {
        field:'autoAssignZoneFk',
        value:state.autoAssignZoneFk
      },
      {
        field:'activation',
        value:activation
      }
    ];

    httpPost('autoAssign/zone/activation', parameters, 'Zone activation updated successfully.', 'Oops, something went wrong and could not update the zone activation. Please try again later.');
  }

  const addNewAutoAssignPanel = () => {
    let parameters = [
      {
        field:'autoAssignZoneFk',
        value:this.state.autoAssignZoneFk
      },
      {
        field:'lenderFk',
        value:this.state.client
      }
    ];
    
    let callBack = apiCallBack([{state:'panels', arraykey:'data', targetArray:state.panels}], newPanelDropDownToggle());
    httpPost('autoAssign/panel/create', parameters, 'Auto assign panel created successfully.', 'Oops, something went wrong and could not create the auto assign panel. Please try again later.', callBack);
  }

  const addNewAutoAssignAppraiserPanel = () => {
    let currentPanel = state.panels[parseInt(state.activePanelTab,10)];

    let parameters = [
      {
        field:'appraiserFk',
        value:state.selectedAppraiser.ID
      },
      {
        field:'respondTime',
        value:state.respondTime
      },
      {
        field:'maxCapacity',
        value:state.maxCapacity
      },
      {
        field:'autoAssignPanelFk',
        value:currentPanel.ID
      }
    ];

    let callBack = (response)=>{
      let code = response.data.code;
      if(code==='00'){
        let panels = state.panels.slice();
        let targetPanel = panels[state.activePanelTab];
        targetPanel.appraisers.push(response.data.data);
        panels.splice(state.activePanelTab,1,targetPanel);

        setState({panels:panels});

        newAppraiserPanelDropDownToggle();
        setState({maxCapacity:'', respondTime:''});
      }
    };
    callBack = callBack.bind(this);

    httpPost('autoAssign/panel/appraiser/create', parameters, 'Added appraiser to auto assign panel successfully.', 'Oops, something went wrong and could not add the following appraiser to the auto assign panel. Please try again later.', callBack);
  }

  //delete an appraiser 
  const deleteAutoAssignAppraiserPanel = (id) => {
    let callBack = (response)=>{
      let code= response.data.code;
      if(code==='00'){
        let panels = state.panels.slice();
        let targetPanel = panels[state.activePanelTab];
        for(let i=0;i<targetPanel.appraisers.length;i++){
          if(targetPanel.appraisers[i].poolFk===id){
            targetPanel.appraisers.splice(i,1);
            break;
          }
        }

        panels.splice(state.activePanelTab,1,targetPanel);

        setState({panels:panels});
      }
    };
    callBack = callBack.bind(this);

    httpDelete('autoAssign/panel/appraiser/'+id,'Appraiser deleted from panel successfully.','Oops, something went wrong and could not delete the appraiser from the panel. Please try again later.', callBack);
  }

  //delete a panel
  const deleteAutoAssignPanel = (id) => {
    let list = sliceFromArray(state.panels,'ID', id);
    let callBack = apiCallBack([{state:'panels', value:list}]);
    httpDelete('autoAssign/panel/'+id,'Panel deleted successfully.','Oops, something went wrong and could not delete the panel. Please try again later.', callBack);
  }

  const loadMore = () => {
    if(state.hasMoreAppraisers&&!state.loading){
      //set loading equals to true so it won't fire off before we are done
      setState({loading:true});

      let url = 'appraiser/get/limit='+state.limit+'&offset='+state.offset+'&order='+state.order+'&sort='+state.sort+'&name='+state.searchName+'&state='+state.searchState+'&county='+state.searchCounty+'&city='+state.searchCity;

      //callback handler that update the state when http request return
      let callBack = (response)=>{console.log(response);
        let code = response.data.code;

        if(code!=='00'){
          setState({hasMoreAppraisers:false});
        }
        else{
          if(state.newAppraiserPanelDropDownOpen){
            let newAppraiser = response.data.data;
            let hasMoreAppraisers = true;
            let newOffset = state.offset;

            //if http request return empty then no more results, end of list
            if(newAppraiser.length<=0){
              hasMoreAppraisers = false;
            }
            else{
              //increment the offset
              newOffset = state.offset + 1;
            }

            //concat the current array of announcement
            if(state.searchResults.length>0){
              let temp = [...state.searchResults,...newAppraiser];

              setState({searchResults:temp});
            }
            else
              setState({searchResults:newAppraiser});

            setState({hasMoreAppraisers:hasMoreAppraisers, offset:newOffset});
          }
        }
      };
      callBack = callBack.bind(this);

      //error handler when the http request return with error
      let errorCallBack = apiCallBack([{state:'hasMoreAppraisers', value:false}]);

      //collect the promise and wait for it to finish performing it's task
      let promises = httpGet(url, '','Oops, something went wrong and could not load appraisers. Please try again later.', callBack, errorCallBack);
      promises
        .then(
          function(result){
            //set loading equals to false so the function could be fire off once again
            setState({loading:false});
          }
        );
    }
  }

  //render
  let markers, markers2;

  //check if it's not account manager then do not render the add new panel, add new appraiser and configure appraiser link
  let roles = getRoles();
  let newPanel, newAppraiser, activation;

  if(roles.indexOf('admin')!==-1){
    newPanel = <NavItem>
      <NavLinkTab className="cursor-pointer" onClick={newPanelDropDownToggle}>
        <i className="fa fa-plus green-color"></i> Add new panel
      </NavLinkTab>
    </NavItem>;

    newAppraiser = <div className="padding display-inline cursor-pointer" onClick={newAppraiserPanelDropDownToggle}><i className="fa fa-plus green-color"></i> Add new appraiser to panel</div>;

    activation = <div className="align-right">
      <label>
        <span style={{verticalAlign:'top',fontWeight:'normal'}}>Activation</span>&nbsp;
        <Toggle
          checked={state.activation}
          icons={false}
          onChange={handleActivationChange} />
      </label>
    </div>;
  }

  if(state.panels&&state.panels.length>0){
    let currentPanel = state.panels[parseInt(state.activePanelTab,10)];
    markers = [];
    currentPanel.appraisers.map(
      (appraiser,index)=>{
        if(appraiser.lat!==''&&appraiser.lng!==''){
          let location = {};
          location.lat = appraiser.lat;
          location.lng = appraiser.lng;
          location.name = appraiser.first_name+" "+appraiser.last_name;
          location.label = '';
          markers.push(location);

        }
        return '';
      }
    );
  }

  if(Object.keys(state.selectedAppraiser).length!==0){
    markers2 = [];
    let location = {};
    location.lat = state.selectedAppraiser.lat;
    location.lng = state.selectedAppraiser.lng;
    location.name = state.selectedAppraiser.first_name+" "+state.selectedAppraiser.last_name;
    location.label = '';

    markers2.push(location);
  }

  let map, map2;

  // if(state.lat!==''&&state.lng!==''){
  //   map = <MyGoogleMap lat={state.lat} lng={state.lng} markers={markers}/>;
  //   map2 = <MyGoogleMap lat={state.lat} lng={state.lng} markers={markers2} isMarkerShown={false}/>;
  // }

  let tab;
  if(state.panels&&state.panels.length>0)
    tab = state.panels.map(
      (lenderPanel,index)=>{

        let panel;
        if(roles.indexOf('admin')!==-1){
          panel = <NavLinkTab className={"cursor-pointer nav-link "+(state.activePanelTab === index.toString()?"active":"inactive" )} onClick={() => { activePanelTabToggle(index.toString()); }}>
            <i className="fa fa-minus red-color cursor-pointer" onClick={()=>{
              confirmation(
                ()=>{deleteAutoAssignPanel(lenderPanel.ID)},
                ()=>{},
                'Delete auto assign panel?',
                'Are you sure you want to delete this panel? You will lose the appraiser within the panel too.');
            }}></i> {lenderPanel.name}
          </NavLinkTab>;
        }
        else{
          panel = <NavLinkTab className={"cursor-pointer nav-link "+(state.activePanelTab === index.toString()?"active":"inactive" )} onClick={() => { activePanelTabToggle(index.toString()); }}>
            {lenderPanel.name}
          </NavLinkTab>;
        }
        return(
          <NavItem key={index}>
            {panel}
          </NavItem>
        );
      }
    );
  let tabContent;

  if(state.panels&&state.panels.length>0)
    tabContent = state.panels.map(
      (lenderPanel,index)=>{
        let rows = lenderPanel.appraisers.map(
          (appraiser,index)=>{
            let appraiserRow, link;

            if(roles.indexOf('admin')!==-1){
              appraiserRow = <div><i onClick={()=>deleteAutoAssignAppraiserPanel(appraiser.poolFk)} className="fa fa-minus red-color cursor-pointer"></i> {appraiser.first_name+" "+appraiser.last_name}</div>;
              link = <NavLink to={"/auto-assign/panel/"+state.stateName+"/"+state.county+"/"+appraiser.poolFk}><i className="fa fa-cogs cursor-pointer"></i> Configure</NavLink>;
            }
            else{
              appraiserRow = appraiser.first_name+" "+appraiser.last_name;
            }
            return(
              <tr key={index}>
                <td>{appraiserRow}</td>
                <td>{appraiser.license_type}</td>
                <td>{appraiser.city}</td>
                <td>{appraiser.avg_assignment_experience}</td>
                <td>{appraiser.avg_internal_qc_score}</td>
                <td>{appraiser.avg_turnaround_time}</td>
                <td>{appraiser.max_capacity}</td>
                <td>{appraiser.respond_time}</td>
                <td>{link}</td>
              </tr>
            );
          }
        );

        return(
          <TabPane tabId={index.toString()} key={index}>
            <div className="large-scroll-container">
              <Table className="table table-striped">
                <thead>
                  <tr>
                    <th width="15%">Appraiser</th>
                    <th width="9%">License Type</th>
                    <th width="15%">City</th>
                    <th width="12%">Assignment Exp</th>
                    <th width="10%">QC Score</th>
                    <th width="10%">TAT</th>
                    <th width="10%">Max Capacity</th>
                    <th width="10%">Respond Time</th>
                    <th width="9%"></th>
                  </tr>
                </thead>
                <tbody>
                  {rows}
                </tbody>
              </Table>
            </div>
          </TabPane>
        );
      }
    );

  let selectedAppraiser;
  if(Object.keys(state.selectedAppraiser).length===0)
    selectedAppraiser = <div>&nbsp;</div>;
  else
    selectedAppraiser = state.selectedAppraiser.first_name+' '+state.selectedAppraiser.last_name;

    
  return <div className="my-well">
    <Row>
      <Col sm="6">
        <div className="page-title">
          <i className="fa fa-reorder"></i>&nbsp;Auto Assign Panel - {state.county}
        </div>
        <NavLink to={"/auto-assign/"+state.stateName}>Back to auto assign counties</NavLink>
      </Col>
      <Col sm="6">
        <br/>
        {activation}
      </Col>
    </Row>

    <div className="my-divider"></div>

    <Nav tabs>
      <NavItem>
        <NavLink className="nav-link" to={"/auto-assign/county/"+state.stateName+"/"+state.county}>Stats</NavLink>
      </NavItem>
      <NavItem>
        <NavLink className="nav-link active" to="#">Panel</NavLink>
      </NavItem>
    </Nav>

    <br/>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-map"></i> Appraiser Distribution
      </CardHeader>
      <CardBody>
        {map}
      </CardBody>
    </Card>

    <br/>

    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-users"></i> Panel
      </CardHeader>
      <CardBody>
        <Nav tabs>
          {tab}
          {newPanel}
        </Nav>
        <div className="align-right">
          {newAppraiser}
        </div>
        <TabContent activeTab={state.activePanelTab}>
          {tabContent}
        </TabContent>

        <Modal className="my-modal" isOpen={state.newPanelDropDownOpen} toggle={newPanelDropDownToggle} >
          <ModalHeader hidden={true} toggle={newPanelDropDownToggle}></ModalHeader>
          <ModalBody>
            <center>
              <h5><i className="fa fa-cogs"></i> Add new auto assign panel</h5>
            </center>
            <br/>
            <label className="no-margin-bottom">Client</label>
            <MySelect
              modal={true}
              type="select"
              value={state.client}
              onChange={(v)=>setState({client:v})}
              options={state.clients.map((client)=>{
                return {label:client.company, value:client.ID};
              })}
            />
            <br/>
            <center>
              <Button color="warning" onClick={addNewAutoAssignPanel}>Add</Button>&nbsp;
              <Button color="info" onClick={newPanelDropDownToggle}>Close</Button>
            </center>
          </ModalBody>
        </Modal>

        <Modal className="my-modal-wide" isOpen={state.newAppraiserPanelDropDownOpen} toggle={newAppraiserPanelDropDownToggle} >
          <ModalHeader hidden={true} toggle={newAppraiserPanelDropDownToggle}></ModalHeader>
          <ModalBody>
            <center>
              <h5><i className="fa fa-cogs"></i> Add new appraiser to panel</h5>
            </center>
            <br/>
            <Row>
              <Col sm="6">
                <Card>
                  <CardHeader className="header-color">
                    <i className="fa fa-users"></i> Add new appraiser
                  </CardHeader>
                  <CardBody style={{height:'550px'}}>
                    {map2}
                    <div className="my-divider"></div>
                    <div>{selectedAppraiser}</div>
                    <Row>
                      <Col sm="4">
                        <label>Respond time</label>
                        <Input type="text" value={state.respondTime} onChange={(e)=>setState({respondTime:e.target.value})}/>
                      </Col>
                      <Col sm="4">
                        <label>Max Capacity</label>
                        <Input type="text" value={state.maxCapacity} onChange={(e)=>setState({maxCapacity:e.target.value})}/>
                      </Col>
                      <Col sm="4" className="align-right">
                        <br/>
                        <Button color="warning" onClick={addNewAutoAssignAppraiserPanel}>Add</Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col sm="6">
                <Card>
                  <CardHeader className="header-color">
                    <i className="fa fa-users"></i> Search for appraiser
                  </CardHeader>
                  <CardBody style={{height:'550px'}}>
                    <Row>
                      <Col sm="3">
                        <label>Name</label>
                        <Input type="text" value={state.searchName} onChange={(e)=>setState({searchName:e.target.value})}/>
                      </Col>
                      <Col sm="3">
                        <label>City</label>
                        <Input type="text" value={state.searchCity} onChange={(e)=>setState({searchCity:e.target.value})}/>
                      </Col>
                      <Col sm="3">
                        <label>County</label>
                        <Input type="text" value={state.searchCounty} onChange={(e)=>setState({searchCounty:e.target.value})}/>
                      </Col>
                      <Col sm="3" className="align-right">
                        <br/>
                        <Button color="warning" onClick={refreshList}>Search</Button>
                      </Col>
                    </Row>
                    <br/>
                    <div className="large-scroll-container">
                      <InfiniteScroll
                        className="my-well"
                        dataLength={state.searchResults.length} //This is important field to render the next data
                        next={loadMore}
                        hasMore={state.hasMoreAppraisers}
                        initialLoad = {true}
                        loader={<center>Loading more appraisers...</center>}
                        scrollableTarget="contentContainer"
                      >
                        {renderAppraisers()}
                      </InfiniteScroll>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <br/>
            <center>
              <Button color="info" onClick={newAppraiserPanelDropDownToggle}>Close</Button>
            </center>
          </ModalBody>
        </Modal>
      </CardBody>
    </Card>
  </div>;
}


export default AutoAssignCountyPanel;
