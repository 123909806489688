//Author Sooyoung Kim
//Date April 27, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, callBackGenerator, showMessage, confirmation, formatNumber, sliceFromArray} from '../../util/util';
import {Button, Card, CardHeader, CardBody, Col, Row, Table, Input} from 'reactstrap';
import Dropzone from 'react-dropzone'
import React, {useReducer, useEffect} from 'react';
import MySelect from '../util/my-select';

//initialize the state
const initialState = {
  entityLabel:'',
  entityName:'',
  entityEmail:'',
  entityPhone:'',

  subjects:[],
  clients:[],
  toUploadFiles:[],
  errorMessage:'',
  uploadDisabled:false,


  openBy:0,
  client:0,

  clientBranches:[],
  openByClientBranches:[],
  clientBranch:0,
  openByClientBranch:0,
  openByClientBranchLabel:''
};

//reducer function that perform state update
const reducer = getReducer();


const BatchNewAppraisal  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getAllClientProfiles();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const removeSubject = (ID) =>{
    let subjects = sliceFromArray(state.subjects,'ID', ID);
    setState({subjects:subjects});
  }

  const handleClientProfileChange = (client) =>{
    setState({client:client});
    getAllClientProfiles(client);
    getClientBranches(client);
  }

  const handleOpenByClientProfileChange = (client) =>{
    setState({openBy:client});
    getOpenByClientBranches(client);
  }

  const uploadButtonHandler = () =>{
    if(state.uploadDisabled)
      return;

    setState({uploadDisabled:true},uploadAll());
  }

  //function trigger for dropzone react.
  //this function contians two list of files, the accepted and rejected file per the configuration
  const onDrop = (acceptedFiles, rejectedFiles) =>{
    let existingFiles = [];

    if(acceptedFiles.length>0)
      existingFiles.push(acceptedFiles[0]);
    setState({toUploadFiles: existingFiles});
  }

  //remove a specific file from the toUpload list.
  const removeToUploadFile = (preview) =>{
    let toUploadFiles = sliceFromArray(state.toUploadFiles, 'preview', preview);
    setState({toUploadFiles:toUploadFiles});
  }

  //format the size to use appropiate unit KB, MB and B and round up to only 1 decimal
  const formatFileSize = (size) =>{
    let intSize = parseInt(size,10);

    if(intSize>=1000000.00)
      return formatNumber(Math.round((intSize*10/1000000))/10)+' MB';
    else if(intSize>=1000)
      return formatNumber(Math.round((intSize*10/1000))/10)+' KB';
    else
      return formatNumber(intSize)+' B';

  }

  const beginSubmission = async () =>{
    //check for open and close by
    if(state.client===0||state.openBy===0){
      showMessage('error', 'Please specify the close by and open by client.');
    }
    else if(state.entityLabel!==''&&(state.entityName===''||state.entityEmail==='')){
      showMessage('error', 'Please fill up the entity information');
    }
    else{
      let subjects = state.subjects.slice();

      for(let i=0;i<subjects.length;i++){
        await createNewAppraisal(subjects[i].ID, subjects[i].street, subjects[i].city, subjects[i].state, subjects[i].zip);
      }
    }
  }

  //API call
  const uploadAll = () =>{
    let preCheck = true;
    let errorMessage = '';

    if(state.toUploadFiles.length<=0){
      preCheck = false;
      errorMessage = 'Please upload at least one file.';
    }

    //only proceed when no error
    if(preCheck){
      setState({errorMessage:'Uploading...Please do not close the window.'});

      for(let i=0;i<state.toUploadFiles.length;i++){
        //skip file that has done upload
        if(state.toUploadFiles[i].status==='Done')
          continue;
        const reader = new FileReader();
        reader.onload = () => {
            const fileAsBinaryString = reader.result;
            let base64 = btoa(fileAsBinaryString);
            let callBack = (response)=>{
              let code = response.data?response.data.code:undefined;

              if(code==='00'){
                setState({subjects:response.data.data});
              }
              setState({uploadDisabled:false,errorMessage:''});
            };
            callBack = callBack.bind(this);

            let parameters = [
              {
                field:'file_encoded',
                value:base64
              }
            ];

            httpPost('appraisal/batchSubmit/scrub',parameters,'File "'+state.toUploadFiles[i].name+'" uploaded successfully.','Oops, something went wrong and could not upload the file "'+state.toUploadFiles[i].name+'". Please try again later.', callBack);
        };
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => {
          showMessage('error','File upload failed, please try again later.');
        };

        reader.readAsBinaryString(state.toUploadFiles[i]);
      }
      setState({uploadDisabled:false});
    }
    else{
      setState({errorMessage:errorMessage});
      setTimeout(()=>setState({uploadDisabled:false}),1000);
    }
  }

  const createNewAppraisal = async (ID, street, city, state, zip) =>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code!=='00'){
        setState({submitDisabled:false});
      }
      else{
        let subjects = state.subjects.slice();

        for(let i=0;i<subjects.length;i++){
          if(subjects[i].ID===ID){
            subjects[i].status='Submitted';
            break;
          }
        }
        setState({subjects:subjects});
      }
    };
    callBack = callBack.bind(this);

    let entities = [];

    if(state.entityLabel!==''){
      entities = [{
       label: state.entityLabel,
       name: state.entityName,
       email: state.entityEmail,
       phone: state.entityPhone
     }];
    }

    let parameters =[
      {
        field:'openBy',
        value:state.openBy
      },
      {
        field:'specialInstructions',
        value:''
      },
      {
        field:'propertyAccessName',
        value:'TBD'
      },
      {
        field:'propertyAccessTitle',
        value:'TBD'
      },
      {
        field:'propertyAccessEmail',
        value:'TBD'
      },
      {
        field:'coveredBy',
        value:''
      },
      {
        field:'propertyType',
        value:'Other'
      },
      {
        field:'appraisalType',
        value:'Other'
      },
      {
        field:'entities',
        value:entities
      },
      {
        field:'propertyAccessWorkPhone',
        value:''
      },
      {
        field:'propertyAccessHomePhone',
        value:''
      },
      {
        field:'salePrice',
        value:''
      },
      {
        field:'propertyStreet',
        value:street
      },
      {
        field:'propertyCity',
        value:city
      },
      {
        field:'propertyCounty',
        value:''
      },
      {
        field:'propertyState',
        value:state
      },
      {
        field:'propertyZip',
        value:zip
      },
      {
        field:'loanNumber',
        value:'TBD'
      },
      {
        field:'fhaNumber',
        value:''
      },
      {
        field:'loanAmount',
        value:''
      },
      {
        field:'loanPurpose',
        value:'Other'
      },
      {
        field:'loanType',
        value:'Other'
      },
      {
        field:'occupant',
        value:'Vacant'
      },
      {
        field:'apn',
        value:''
      },
      {
        field:'client',
        value:state.client
      },
      {
        field:'isRush',
        value:'Non-Rush'
      },
      {
        field:'borrowerFirstName',
        value:'QUOTE'
      },
      {
        field:'borrowerLastName',
        value:'QUOTE'
      },
      {
        field:'borrowerEmail',
        value:'QUOTE'
      },
      {
        field:'borrowerPhone',
        value:'QUOTE'
      },
      {
        field:'lat',
        value:''
      },
      {
        field:'lng',
        value:''
      },
      {
        field:'billing_first_name',
        value:''
      },
      {
        field:'billing_last_name',
        value:''
      },
      {
        field:'email',
        value:''
      },
      {
        field:'card_number',
        value:''
      },
      {
        field:'card_type',
        value:''
      },
      {
        field:'cvc',
        value:''
      },
      {
        field:'expiration_month',
        value:''
      },
      {
        field:'expiration_year',
        value:''
      },
      {
        field:'billing_street',
        value:''
      },
      {
        field:'billing_city',
        value:''
      },
      {
        field:'billing_state',
        value:''
      },
      {
        field:'billing_zip',
        value:''
      }
    ];

    if(state.clientBranch!==0){
      let tmp = {
        field:'clientBranchFk',
        value:state.clientBranch
      };

      parameters.push(tmp);
    }

    if(state.openByClientBranch!==0){
      let tmp = {
        field:'openByClientBranch',
        value:state.openByClientBranch
      };

      parameters.push(tmp);
    }

    await httpPost('appraisal/create',parameters,'Appraisal - '+street+' created successfully.','Oops, something went wrong and could not create the appraisal. Please try again later.', callBack);
  }

  const getAllClientProfiles = () =>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let clients = [];
        for(let i=0;i<response.data.data.length;i++){
          let client = response.data.data[i];

          let tmp = {};
          tmp.key = client.ID;
          tmp.value = client.company;

          clients.push(tmp);
        }
        setState({clients:clients});
      }
    };
    callBack = callBack.bind(this);

    httpGet('client/get/limit=-1&offset=-1', '', 'Oops, something went wrong and could not retrieve client profiles.', callBack);
  }

  const getClientBranches = (client) =>{
    let url = 'client/branch/get/'+client;
    let callBack = apiCallBack([{state:'clientBranches',key:'data'},{state:'clientBranch',key:'data[0].ID'}]);

    httpGet(url, '','Oops, something went wrong and could not retrieve client entities list.', callBack);
  }

  const getOpenByClientBranches = (client) =>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;

      if(code==='00'){
        setState({openByClientBranches:response.data.data});

        if(response.data.data.length>0){
          let branch = response.data.data[0];
          setState({openByClientBranch:response.data.data[0].ID, openByClientBranchLabel:branch.branch_name+' - '+branch.street+' '+branch.city+', '+branch.state+' '+branch.zip});
        }
      }
    };
    callBack = callBack.bind(this);

    httpGet('client/branch/get/'+client, '','Oops, something went wrong and could not retrieve client entities list.', callBack);
  }

  //render
  let toUploadFiles;

  if(state.toUploadFiles.length>0){
    toUploadFiles = state.toUploadFiles.map(
      (file,index)=>{
        return(
          <tr key={index}>
            <td>{file.name}</td>
            <td>{formatFileSize(file.size)}</td>
            <td><center>{file.status}</center></td>
            <td><center><i className="fa fa-times red-color cursor-pointer" onClick={()=>removeToUploadFile(file.preview)}></i></center></td>
            <td className="align-right"><Button color="warning" disabled={state.uploadDisabled!==false} onClick={uploadButtonHandler}><i className="fa fa-upload"></i> Upload</Button></td>
          </tr>
        );
      }
    );
  }

  let secondStep;

  if(state.subjects.length>0){
    secondStep = <Card>
      <CardHeader className="header-color">
        <i className="fa fa-list"></i>&nbsp;Batch Appraisal Submission
      </CardHeader>
      <CardBody>
        <Row>
          <Col sm="6">
            <label>Close By Client</label>
            <MySelect
              type="select"
              value={state.client}
              onChange={(v)=>{handleClientProfileChange(v);setState({client:v})}}
              options={state.clients.map((client)=>{
                return {label:client.value, value:client.key,};
              })}
              />
          </Col>
          <Col sm="6">
            <label>Close By Client Branch</label>
            <MySelect
              type="select"
              value={state.clientBranch}
              onChange={(v)=>setState({clientBranch:v})}
              options={state.clientBranches.map((branch)=>{
                return {label:branch.branch_name+' - '+branch.street+' '+branch.city+', '+branch.state+' '+branch.zip, value:branch.ID,};
              })}
              />
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <label>Open By Client</label>
            <MySelect
              type="select"
              value={state.openBy}
              onChange={(v)=>{handleOpenByClientProfileChange(v);setState({openBy:v})}}
              options={state.clients.map((client)=>{
                return {label:client.value, value:client.key,};
              })}
              />
          </Col>
          <Col sm="6">
            <label>Open By Client Branch</label>
            <MySelect
              type="select"
              value={state.openByClientBranch}
              onChange={(v)=>setState({openByClientBranch:v})}
              options={state.openByClientBranches.map((branch)=>{
                return {label:branch.branch_name+' - '+branch.street+' '+branch.city+', '+branch.state+' '+branch.zip, value:branch.ID,};
              })}
              />
          </Col>
        </Row>
        <br/>
        <Row>
          <Col sm="3">
            <label>Entity Label</label>
            <MySelect
              type="select"
              value={state.entityLabel}
              onChange={(v)=>setState({entityLabel:v})}
              options={[{label:"",value:""},{label:"Loan Officer",value:"Loan Officer"},{label:"Loan Processor",value:"Loan Processor"},{label:"AE",value:"AE"},{label:"Broker",value:"Broker"},{label:"Broker Company",value:"Broker Company"}]}
              />
          </Col>
          <Col sm="3">
            <label>Entity Name</label>
            <Input type="text" value={state.entityName} onChange={(e)=>setState({entityName:e.target.value})}/>
          </Col>
          <Col sm="3">
            <label>Entity Email</label>
            <Input type="text" value={state.entityEmail} onChange={(e)=>setState({entityEmail:e.target.value})}/>
          </Col>
          <Col sm="3">
            <label>Entity Phone</label>
            <Input type="text" value={state.entityPhone} onChange={(e)=>setState({entityPhone:e.target.value})}/>
          </Col>
        </Row>
        <br/>
        <label>Subject List</label>
        <div className="Container Flipped">
          <div className="Content">
            <Table className="table table-striped table-header-rotated">
              <thead>
                <tr>
                  <th width="10%">Status</th>
                  <th width="25%">Address</th>
                  <th width="25%">City</th>
                  <th width="25%">State</th>
                  <th width="15%">Zip</th>
                </tr>
              </thead>
              <tbody>
                {
                  state.subjects.map(
                    (subject, index)=>{
                      return(
                        <tr key={index}>
                          <td><i className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeSubject(subject.ID)}></i> {subject.status==='Submitted'?subject.status:'Pending'}</td>
                          <td>{subject.street}</td>
                          <td>{subject.city}</td>
                          <td>{subject.state}</td>
                          <td>{subject.zip}</td>
                        </tr>
                      );
                    }
                  )
                }
              </tbody>
            </Table>
          </div>
        </div>
        <br/><br/>
        <div className="align-right">
          <Button color="warning" enabled={state.submitDisabled} onClick={(e)=>{
            confirmation(
              ()=>{beginSubmission()},
              ()=>{},
              'Begin Submission?',
              'Please note that once the submission begin you have to stay on the page until the last order go through. You can see the status of each order in the table changed from "Pending" to "Submitted" once they have been submitted successfully. Press yes to begin or no to cancel.');
          }}>Begin Submission</Button>
        </div>
      </CardBody>
    </Card>;
  }

  return <div className="my-well">
    <Row>
      <Col sm="6">
        <div className="page-title">
          <i className="fa fa-list"></i>&nbsp;Batch Submit
        </div>
      </Col>
      <Col sm="6">

      </Col>
    </Row>
    <div className="my-divider"></div>

    <font color="red"><label>Instructions</label></font><br/>
    <b>1. Upload an excel file that contains a list of subject property addresses. First column = street address, Second column = city, Third column = state, Fourth column = zip</b><br/>
    <b>2. Configure the order details. Some non crucial fields like borrower name, property access etc will be left as either "TBD" or "QUOTE". You can configure them later after it has been submitted.</b><br/>

    <Dropzone onDrop={onDrop} className="my-dropzone">
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <center>
            <font style={{fontSize:'60px'}}>
              <i className="fa fa-cloud-upload link-color"></i>
            </font>
            <br/>
            <Button color="success">Choose files to upload</Button>
            <br/>
            <div className="margin-top">
              <i><b>OR drag and drop file here.</b></i>
            </div>
          </center>
        </div>
      )}
    </Dropzone>
    <br/>
    <div className="my-divider"></div>
    <div className="small-scroll-container red-color">
      <b>{state.errorMessage}</b>
    </div>
    <div className="medium-scroll-container">
      <table className="table file-list-table" cellSpacing="0" cellPadding="0">
        <thead>
          <tr>
            <th width="35%">Name</th>
            <th width="15%">Size</th>
            <th width="10%"><center>Status</center></th>
            <th width="10%">Control</th>
            <th width="30%">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {toUploadFiles}
        </tbody>
      </table>
    </div>

    <br/><br/>

    {secondStep}
  </div>;
}

export default BatchNewAppraisal;
