//Author MyXEditable
//Date Nov 15th, 2023
import {getReducer, getSetStateFunction} from '../../util/util';
import React, {useReducer, useEffect} from 'react';
import {Input, InputGroup, Button} from 'reactstrap';
import DatePicker from 'react-datepicker';
import {formatNumber} from '../../util/util';
import moment from 'moment';
import './my-xeditable.css';
import 'react-datepicker/dist/react-datepicker.css';

//initialize the state
const initialState = {
  type: '',
  value: '',
  hover:false,
  focused: false,
  tempValue: '',
  options: [],
  updateFunc:null,
  parentCallBack:null
};

//reducer function that perform state update
const reducer = getReducer();


const MyXEditable  = (props)=>{
  const newInitialState = Object.assign({}, initialState, {type:props.type, options: props.opt, updateFunc: props.updateFunc, parentCallBack: props.parentCallBack, value:props.value, tempValue:props.value});
  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  //run only once when component is loaded
  useEffect(()=>{
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if(props.value!==state.value&&props.value!==undefined){
      let v = props.value;
      setState({value:v,tempValue:v});
    }
    else if(props.opt!==state.options&&props.opt!==undefined){
      let v = props.opt;
      setState({options:v});
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props]);

  //non API call but simpyl manage state

  //listen for enter
  const keyPressListener = (event)=>{
    console.log(event.charCode);
    if(event.charCode===13){
      update();
      hide();
    }
  }

  //hide the inlin editor
  const hide = ()=>{
    setState({focused:false});
  }

  const reset = ()=>{
    setState({value:state.tempValue, focused:false});
  }

  //show the inline editor
  const show = () =>{
    setState({focused:true});
  }

  //do update to server
  const update = ()=>{
    if(state.value!==state.tempValue){
      //call the update function provided by the upper level component
      let func = props.updateFunc;

      func(state.value, updateCallBack, updateErrorCallBack);
    }
    hide();
  }

  const updateCallBack = (response)=>{
    setState({tempValue:state.value});
    if(state.parentCallBack)
      state.parentCallBack(response, state.value);
  }

  const updateErrorCallBack = (error)=>{
    setState({value:state.tempValue});
  }

  //render
  switch(state.type){
    case 'text':
      if(!state.focused){
        let value = 'empty';
        if(state.value&&state.value.replace(/\s/g, '')!=='')
          value = (props.prefix?props.prefix:'')+state.value;
        return (
          <div className="xeditable-container">
            <div className="xeditable-text" onClick={show}>{value}</div>
          </div>
        );
      }
      else{
        if(props.hideButton){
          return ( <div className="xeditable-container">
            <InputGroup>
              <Input
                type="text"
                autoFocus
                className="xeditable-input-text"
                value={state.value}
                onChange={(text)=>setState({value: text.target.value})}
                onKeyPress={keyPressListener}
              />
            </InputGroup>
          </div>);
        }
        else {
          return (<div className="xeditable-container">
            <InputGroup>
              <Input
                type="text"
                autoFocus
                className="xeditable-input-text"
                value={state.value}
                onChange={(text)=>setState({value: text.target.value})}
                onKeyPress={keyPressListener}
              />
              <Button className="display-inline active-xeditable-input-group-addon xeditable-input-group-addon" onClick={update}><font color="white"><i className="fa fa-check"></i></font></Button>
              <Button className="display-inline xeditable-input-group-addon xeditable-input-group-addon" onClick={reset}><i className="fa fa-close"></i></Button>
            </InputGroup>
          </div>);
        }
      }
    case 'textarea':
      if(state.hover){
        let value = 'empty';
        if(state.value&&state.value.replace(/\s/g, '')!=='')
          value = (props.prefix?props.prefix:'')+state.value;
        return (
          <div className="cursor-pointer link-color" style={{paddingBottom:'25px'}}  onMouseOut={(e)=>setState({hover:false})} onClick={show}>
            <div>{value}</div>
          </div>
        );
      }
      else if(!state.focused){
        let value = 'empty';
        if(state.value&&state.value.replace(/\s/g, '')!=='')
          value = (props.prefix?props.prefix:'')+state.value;
        return (
          <div onMouseOver={(e)=>setState({hover:true})} onClick={show}>
            <div>{value}</div>
          </div>
        );
      }
      else{
        return ( <div>
          <InputGroup>
            <Input
              type="textarea"
              autoFocus
              value={state.value}
              onChange={(text)=>setState({value: text.target.value})}
              onKeyPress={keyPressListener}
              rows="4"
            />
            <Button className="display-inline active-xeditable-input-group-addon xeditable-input-group-addon" onClick={update}><font color="white"><i className="fa fa-check"></i></font></Button>
            <Button className="display-inline xeditable-input-group-addon xeditable-input-group-addon" onClick={reset}><i className="fa fa-close"></i></Button>
          </InputGroup>
        </div>);
      }
    case 'number':
      if(!state.focused){
        let value = 'empty';
        if(state.value&&state.value.replace(/\s/g, '')!=='')
          value = (props.prefix?props.prefix:'')+state.value;
        return (
          <div className="xeditable-container">
            <div className="xeditable-text" onClick={show}>{formatNumber(value)}</div>
          </div>
        );
      }
      else{
        return (
          <div className="xeditable-container">
            <InputGroup>
              <Input
                type="number"
                autoFocus
                className="xeditable-input-text"
                value={state.value}
                onChange={(text)=>setState({value: text.target.value})}
                onKeyPress={keyPressListener}
              />
              <Button className="active-xeditable-input-group-addon xeditable-input-group-addon" onClick={update}><font color="white"><i className="fa fa-check"></i></font></Button>
              <Button className="xeditable-input-group-addon xeditable-input-group-addon" onClick={reset}><i className="fa fa-close"></i></Button>
            </InputGroup>
          </div>
        );
      }
    case 'date':
      if(!state.focused){
        let value = state.value;
        if(value&&value!=='0000-00-00'&&value!==''&&value!=='empty')
          value = moment(value).format("DD MMM, YYYY");
        else
          value = '-';
        return (
          <div className="xeditable-container">
            <div className="xeditable-text" onClick={show}>{value}</div>
          </div>
        );
      }
      else{
        let value = state.value;

        if(value&&value!=='0000-00-00'&&value!=='')
          value = moment(state.value).toDate();
        else
          value = null;

        return (
          <div className="xeditable-container">
            <InputGroup>
              <DatePicker
                className="xeditable-input-datetime"
                selected={value}
                onChange={(text)=>{setState({value:text.toLocaleDateString('en-CA')})}}
              />
              <Button className="active-xeditable-input-group-addon xeditable-input-group-addon" onClick={update}><font color="white"><i className="fa fa-check"></i></font></Button>
              <Button className="xeditable-input-group-addon xeditable-input-group-addon" onClick={reset}><i className="fa fa-close"></i></Button>
            </InputGroup>
          </div>
        );
      }
    case 'datetime':
      if(!state.focused){
        let value = state.value;
        if(value&&value!=='0000-00-00 00:00:00'&&value!==''&&value!=='empty')
          value = moment(value).format("DD MMM, YYYY hh:mm A");
        else
          value = 'empty';

        return (
          <div className="xeditable-container">
            <div className="xeditable-text" onClick={show}>{value}</div>
          </div>
        );
      }
      else{
        let value = state.value;

        if(value&&value!=='0000-00-00 00:00:00'&&value!=='')
          value = moment(state.value).toDate();
        else
          value = null;

        return (
          <div className="xeditable-container">
            <InputGroup>
              <DatePicker
                className="xeditable-datetime display-inline"
                showTimeSelect
                selected={value}
                timeIntervals={15}
                onChange={(text)=>{if(text !== null){
                    setState({ value: text });
                  }else{
                    setState({value:''});
                  }
                }}
                dateFormat='dd MMM yyyy hh:mm aa'
              />
              <Button className="active-xeditable-input-group-addon xeditable-input-group-addon" onClick={update}><font color="white"><i className="fa fa-check"></i></font></Button>
              <Button className="xeditable-input-group-addon xeditable-input-group-addon" onClick={reset}><i className="fa fa-close"></i></Button>
            </InputGroup>
          </div>
        );
      }
    case 'select':
      if(!state.focused){
        let value = state.value;

        for(let i=0;i<state.options.length;i++){
          if(state.options[i].key===state.value)
            value = state.options[i].value;
        }
        if(!value||value==='')
          value='empty';
        return (
          <div className="xeditable-container">
            <div className="xeditable-text" onClick={show}>{props.prefix}{value}</div>
          </div>
        );
      }
      else{
        let options = state.options.map(
          (option) => {
            return(
              <option value={option.key} key={option.key}>{option.value}</option>
            );
          }
        );
        return (
          <div className="xeditable-container">
            <InputGroup>
              <Input
                type="select"
                autoFocus
                className="xeditable-input-select"
                value={state.value}
                onChange={(text)=>setState({value: text.target.value})}
                onKeyPress={keyPressListener}
              >
                {options}
              </Input>
              <Button className="active-xeditable-input-group-addon xeditable-input-group-addon" onClick={update}><font color="white"><i className="fa fa-check"></i></font></Button>
              <Button className="xeditable-input-group-addon xeditable-input-group-addon" onClick={reset}><i className="fa fa-close"></i></Button>
            </InputGroup>
          </div>
        );
      }
    default:
      return null;
  }
}


export default MyXEditable;
