//Author Sooyoung Kim
//Date Nov 28, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, deleteAPICallGenerator, callBackGenerator, confirmation, sliceFromArray} from '../../util/util';
import {Card, CardHeader, CardBody, Row, Col, Table, Input, Button} from 'reactstrap';
import React, {useReducer, useEffect, useRef } from 'react';
import MySelect from '../util/my-select';
import {NavLink, useParams} from 'react-router-dom';


//initialize the state
const initialState = {
  appraiser:{},
  blackList:[],
  whiteList:[],
  onlyUseList:[],
  newBlackListDescription:'',
  newWhiteListDescription:'',
  newOnlyUseListDescription:'',
  fieldsEnum:[],
  newBlackListConditionRuleSelected:'',
  newBlackListConditionField:'',
  newBlackListConditionOperator:'=',
  newBlackListConditionDescription:'',
  newBlackListConditionValues:[],
  newBlackListConditionValuesT:'',
  newBlackListConditionValuesS:'',
  newWhiteListConditionRuleSelected:'',
  newWhiteListConditionField:'',
  newWhiteListConditionOperator:'=',
  newWhiteListConditionDescription:'',
  newWhiteListConditionValues:[],
  newWhiteListConditionValuesT:'',
  newWhiteListConditionValuesS:'',
  newOnlyUseListConditionRuleSelected:'',
  newOnlyUseListConditionField:'',
  newOnlyUseListConditionOperator:'=',
  newOnlyUseListConditionDescription:'',
  newOnlyUseListConditionValues:[],
  newOnlyUseListConditionValuesT:'',
  newOnlyUseListConditionValuesS:''
};

//reducer function that perform state update
const reducer = getReducer();


const AutoAssignAppraiser  = (props)=>{
  const controller = new AbortController();
  const componentRef = useRef();

  let {stateName, county, id} = useParams();

  let newInitialState = Object.assign({}, initialState, {
    stateName:stateName,
    county:county,
    id: id
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    componentRef.current.scrollIntoView();

    getAppraiserPoolInfo();
    getRuleFieldEnum();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const blackListValueOnChange = (v) => {
    setState({newBlackListConditionValuesT:v});
  }

  const whiteListValueOnChange = (v) => {
    setState({newWhiteListConditionValuesT:v});
  }

  const onlyUseListValueOnChange = (v) => {
    setState({newOnlyUseListConditionValuesT:v});
  }

  //API call
  const getAppraiserPoolInfo = () => {
    let callBack = apiCallBack([{state:'appraiser', key:'data'}, {state:'blackList', key:'data.black_list'}, {state:'whiteList', key:'data.white_list'}, {state:'onlyUseList', key:'data.only_use_list'}]);
    httpGet('autoAssign/pool/'+state.id, '', 'Oops, something went wrong and could not load the information of this appraiser. Please try again later.', callBack);
  }

  const getRuleFieldEnum = () => {
    let callBack = apiCallBack([{state:'fieldsEnum', key:'data'}]);
    httpGet('autoAssign/rules/field/value', '', 'Oops, something went wrong and could not load the rule fields. Please try again later.', callBack);
  }

  const addNewBlackList = () => {
    let parameters = [
      {
        field:'autoAssignPoolFk',
        value:state.id
      },
      {
        field:'type',
        value:'blackList'
      },
      {
        field:'description',
        value:state.newBlackListDescription
      },
    ];

    let callBack = apiCallBack([{state:'blackList', arraykey:'data', targetArray:state.blackList}, {state:'newBlackListDescription', value:''}]);
    httpPost('autoAssign/rule/create', parameters, 'Auto assign rule created successfully.', 'Oops, something went wrong and could not create the rule. Please try again later.', callBack);
  }

  const addNewBlackListCondition = () => {
    let url = 'autoAssign/rule/condition/create';
    let successMsg = 'Auto assign rule condition created successfully.';
    let errorMsg = 'Oops, something went wrong and could not create the rule condition. Please try again later.';

    let select = true;

    for(let i=0;i<state.fieldsEnum.length;i++){
      //there is no drop down, the value should coming from text instead
      if(state.fieldsEnum[i].field===state.newBlackListConditionField){
        if(state.fieldsEnum[i].enum.length<=0)
          select = false;
      }
    }

    let value;

    if(select)
      value = state.newBlackListConditionValuesS;
    else
      value = state.newBlackListConditionValuesT;


    let parameters = [
      {
        field:'description',
        value:state.newBlackListConditionDescription
      },
      {
        field:'field',
        value:state.newBlackListConditionField
      },
      {
        field:'operator',
        value:state.newBlackListConditionOperator
      },
      {
        field:'value',
        value:value
      },
      {
        field:'type',
        value:'blackList'
      },
      {
        field:'ruleFk',
        value:state.newBlackListConditionRuleSelected
      },
    ];

    let callBack = (response)=>{
      let code = response.data.code;
      if(code==='00'){
        let list = state.blackList.slice();

        for(let i=0;i<list.length;i++){
          if(list[i].ID===state.newBlackListConditionRuleSelected){
            list[i].conditions.push(response.data.data);
          }
        }

        setState({blackList:list});
      }
    };
    callBack = callBack.bind(this);

    httpPost(url, parameters, successMsg, errorMsg, callBack);
  }

  //delete a black list rule
  const deleteBlackListRule = (id) => {
    let list = sliceFromArray(state.blackList,'ID', id);
    let callBack = apiCallBack([{state:'blackList', value:list}]);
    httpDelete('autoAssign/rule/blackList/'+id,'Black list rule deleted successfull.','Oops, something went wrong and could not delete the rule. Please try again later.', callBack);
  }

  //delete a black list condition
  const deleteBlackListCondition = (id,conditionID) => {
    let list = sliceFromArray(state.blackList, ['ID','ID'],[id, conditionID], ['conditions']);
    let callBack = apiCallBack([{state:'blackList', value:list}]);
    httpDelete('autoAssign/rule/condition/blackList/'+conditionID,'Black list condition deleted successfull.','Oops, something went wrong and could not delete the condition. Please try again later.', callBack);
  }

  const addNewWhiteList = () => {
    let parameters = [
      {
        field:'autoAssignPoolFk',
        value:state.id
      },
      {
        field:'type',
        value:'whiteList'
      },
      {
        field:'description',
        value:state.newWhiteListDescription
      },
    ];

    let callBack = apiCallBack([{state:'whiteList', arraykey:'data', targetArray:state.whiteList}, {state:'newWhiteListDescription', value:''}]);
    httpPost('autoAssign/rule/create', parameters, 'Auto assign rule created successfully.', 'Oops, something went wrong and could not create the rule. Please try again later.', callBack);
  }

  const addNewWhiteListCondition = () => {
    let select = true;

    for(let i=0;i<state.fieldsEnum.length;i++){
      //there is no drop down, the value should coming from text instead
      if(state.fieldsEnum[i].field===state.newWhiteListConditionField){
        if(state.fieldsEnum[i].enum.length<=0)
          select = false;
      }
    }

    let value;

    if(select)
      value = state.newWhiteListConditionValuesS;
    else
      value = state.newWhiteListConditionValuesT;


    let parameters = [
      {
        field:'description',
        value:state.newWhiteListConditionDescription
      },
      {
        field:'field',
        value:state.newWhiteListConditionField
      },
      {
        field:'operator',
        value:state.newWhiteListConditionOperator
      },
      {
        field:'value',
        value:value
      },
      {
        field:'type',
        value:'whiteList'
      },
      {
        field:'ruleFk',
        value:state.newWhiteListConditionRuleSelected
      },
    ];

    let callBack = (response)=>{
      let code = response.data.code;
      if(code==='00'){
        let list = state.whiteList.slice();

        for(let i=0;i<list.length;i++){
          if(list[i].ID===state.newWhiteListConditionRuleSelected){
            list[i].conditions.push(response.data.data);
          }
        }

        setState({whiteList:list});
      }
    };
    callBack = callBack.bind(this);

    httpPost('autoAssign/rule/condition/create', parameters, 'Auto assign rule condition created successfully.', 'Oops, something went wrong and could not create the rule condition. Please try again later.', callBack);
  }

  //delete a white list rule
  const deleteWhiteListRule = (id) => {
    let list = sliceFromArray(state.whiteList,'ID', id);
    let callBack = apiCallBack([{state:'whiteList', value:list}]);

    httpDelete('autoAssign/rule/whiteList/'+id,'White list rule deleted successfull.','Oops, something went wrong and could not delete the rule. Please try again later.', callBack);
  }

  //delete a white list condition
  const deleteWhiteListCondition = (id,conditionID) => {
    let list = sliceFromArray(state.whiteList, ['ID','ID'],[id, conditionID], ['conditions']);
    let callBack = apiCallBack([{state:'appraisers', value:list}]);

    httpDelete('autoAssign/rule/condition/whiteList/'+conditionID,'White list condition deleted successfull.','Oops, something went wrong and could not delete the condition. Please try again later.', callBack);
  }

  const addNewOnlyUseList = () => {
    let parameters = [
      {
        field:'autoAssignPoolFk',
        value:state.id
      },
      {
        field:'type',
        value:'onlyUseList'
      },
      {
        field:'description',
        value:state.newOnlyUseListDescription
      },
    ];

    let callBack = apiCallBack([{state:'onlyUseList', arraykey:'data', targetArray:state.onlyUseList}, {state:'newOnlyUseListDescription', value:''}]);
    httpPost('autoAssign/rule/create', parameters, 'Auto assign rule created successfully.', 'Oops, something went wrong and could not create the rule. Please try again later.', callBack);
  }

  const addNewOnlyUseListCondition = () => {
    let select = true;

    for(let i=0;i<state.fieldsEnum.length;i++){
      //there is no drop down, the value should coming from text instead
      if(state.fieldsEnum[i].field===state.newOnlyUseListConditionField){
        if(state.fieldsEnum[i].enum.length<=0)
          select = false;
      }
    }

    let value;

    if(select)
      value = state.newOnlyUseListConditionValuesS;
    else
      value = state.newOnlyUseListConditionValuesT;


    let parameters = [
      {
        field:'description',
        value:state.newOnlyUseListConditionDescription
      },
      {
        field:'field',
        value:state.newOnlyUseListConditionField
      },
      {
        field:'operator',
        value:state.newOnlyUseListConditionOperator
      },
      {
        field:'value',
        value:value
      },
      {
        field:'type',
        value:'onlyUseList'
      },
      {
        field:'ruleFk',
        value:state.newOnlyUseListConditionRuleSelected
      },
    ];

    let callBack = (response)=>{
      let code = response.data.code;
      if(code==='00'){
        let list = state.onlyUseList.slice();

        for(let i=0;i<list.length;i++){
          if(list[i].ID===state.newOnlyUseListConditionRuleSelected){
            list[i].conditions.push(response.data.data);
          }
        }

        setState({onlyUseList:list});
      }
    };
    callBack = callBack.bind(this);

    httpPost('autoAssign/rule/condition/create', parameters, 'Auto assign rule condition created successfully.', 'Oops, something went wrong and could not create the rule condition. Please try again later.', callBack);
  }

  //delete a black list rule
  const deleteOnlyUseListRule = (id) => {
    let list = sliceFromArray(state.onlyUseList,'ID', id);
    let callBack = apiCallBack([{state:'onlyUseList', value:list}]);
    httpDelete('autoAssign/rule/onlyUseList/'+id,'Only use list rule deleted successfull.','Oops, something went wrong and could not delete the rule. Please try again later.', callBack);
  }

  //delete a only use list condition
  const deleteOnlyUseListCondition = (id,conditionID) => {
    let list = sliceFromArray(state.onlyUseList, ['ID','ID'],[id, conditionID], ['conditions']);
    let callBack = apiCallBack([{state:'onlyUseList', value:list}]);
    httpDelete('autoAssign/rule/condition/onlyUseList/'+conditionID,'Only use list condition deleted successfull.','Oops, something went wrong and could not delete the condition. Please try again later.', callBack);
  }

  //render
  let blackLists, valueWidget;
  let whiteLists, valueWidget2;
  let onlyUseLists, valueWidget3;

  if(state.newBlackListConditionValues&&state.newBlackListConditionValues.length>0)
    valueWidget = <MySelect
      type="select"
      required={true}
      selectIsClearable={true}
      value={state.newBlackListConditionValuesS}
      onChange={(v)=>{blackListValueOnChange(v);setState({newBlackListConditionValuesS:v})}}
      options={state.newBlackListConditionValues.map((value)=>{
        return {label:value.value, value:value.value};
      })}
    />
  else
    valueWidget = <Input required={true} type="text" onChange={(e)=>setState({newBlackListConditionValuesT:e.target.value})} value={state.newBlackListConditionValuesT}/>;


  blackLists = state.blackList.map(
    (list, index)=>{

      let conditions;

      conditions = list.conditions.map(
        (condition,index2)=>{
          return(
            <tr key={index2}>
              <td><i className="fa fa-times red-color cursor-pointer" onClick={()=>deleteBlackListCondition(list.ID, condition.ID)}></i> {condition.description}</td>
              <td>{condition.field}</td>
              <td>{condition.operator}</td>
              <td>{condition.value}</td>
            </tr>
          );
        }
      );

      return(
        <div key={index}>
          <Card>
            <CardHeader className="header-color">
              <i className="fa fa-times red-color cursor-pointer" onClick={
                ()=>{
                  confirmation(
                    ()=>{deleteBlackListRule(list.ID)},
                    ()=>{},
                    'Delete auto assign rule?',
                    'Are you sure you want to delete this rule? You will lose all conditions that associated with this rule.');
                }
              }></i> {list.description}
            </CardHeader>
            <CardBody>
              <Table className="table table-striped">
                <thead>
                  <tr>
                    <th width="35%">Description</th>
                    <th width="15%">Field</th>
                    <th width="25%">Operator</th>
                    <th width="25%">Value</th>
                  </tr>
                </thead>
                <tbody>
                  {conditions}
                </tbody>
              </Table>
            </CardBody>
          </Card>
          <br/>
        </div>
      );
    }
  );



  if(state.newWhiteListConditionValues&&state.newWhiteListConditionValues.length>0)
    valueWidget2 = <MySelect
      type="select"
      required={true}
      selectIsClearable={true}
      value={state.newWhiteListConditionValuesS}
      onChange={(v)=>{whiteListValueOnChange(v);setState({newWhiteListConditionValuesS:v})}}
      options={state.newWhiteListConditionValues.map((value)=>{
        return {label:value.value, value:value.value};
      })}
    />
  else
    valueWidget2 = <Input required={true} type="text" onChange={(e)=>setState({newWhiteListConditionValuesT:e.target.value})} value={state.newWhiteListConditionValuesT}/>;

  whiteLists = state.whiteList.map(
    (list, index)=>{

      let conditions;

      conditions = list.conditions.map(
        (condition,index2)=>{
          return(
            <tr key={index2}>
              <td><i className="fa fa-times red-color cursor-pointer" onClick={()=>deleteWhiteListCondition(list.ID, condition.ID)}></i> {condition.description}</td>
              <td>{condition.field}</td>
              <td>{condition.operator}</td>
              <td>{condition.value}</td>
            </tr>
          );
        }
      );

      return(
        <div key={index}>
          <Card>
            <CardHeader className="header-color">
              <i className="fa fa-times red-color cursor-pointer" onClick={
                ()=>{
                  confirmation(
                    ()=>{deleteWhiteListRule(list.ID)},
                    ()=>{},
                    'Delete auto assign rule?',
                    'Are you sure you want to delete this rule? You will lose all conditions that associated with this rule.');
                }
              }></i> {list.description}
            </CardHeader>
            <CardBody>
              <Table className="table table-striped">
                <thead>
                  <tr>
                    <th width="35%">Description</th>
                    <th width="15%">Field</th>
                    <th width="25%">Operator</th>
                    <th width="25%">Value</th>
                  </tr>
                </thead>
                <tbody>
                  {conditions}
                </tbody>
              </Table>
            </CardBody>
          </Card>
          <br/>
        </div>
      );
    }
  );

  if(state.newOnlyUseListConditionValuesS && state.newOnlyUseListConditionValuesS.length>0)
    valueWidget3 = <MySelect
      type="select"
      required={true}
      selectIsClearable={true}
      value={state.newOnlyUseListConditionValuesS}
      onChange={(v)=>{onlyUseListValueOnChange(v);setState({newOnlyUseListConditionValuesS:v})}}
      options={state.newOnlyUseListConditionValues.map((value)=>{
        return {label:value.value, value:value.value};
      })}
    />
  else
    valueWidget3 = <Input required={true} type="text" onChange={(e)=>setState({newOnlyUseListConditionValuesT:e.target.value})} value={state.newOnlyUseListConditionValuesT}/>;


  onlyUseLists = state.onlyUseList.map(
    (list, index)=>{

      let conditions;

      conditions = list.conditions.map(
        (condition,index2)=>{
          return(
            <tr key={index2}>
              <td><i className="fa fa-times red-color cursor-pointer" onClick={()=>deleteOnlyUseListCondition(list.ID, condition.ID)}></i> {condition.description}</td>
              <td>{condition.field}</td>
              <td>{condition.operator}</td>
              <td>{condition.value}</td>
            </tr>
          );
        }
      );

      return(
        <div key={index}>
          <Card>
            <CardHeader className="header-color">
              <i className="fa fa-times red-color cursor-pointer" onClick={
                ()=>{
                  confirmation(
                    ()=>{deleteOnlyUseListRule(list.ID)},
                    ()=>{},
                    'Delete auto assign rule?',
                    'Are you sure you want to delete this rule? You will lose all conditions that associated with this rule.');
                }
              }></i> {list.description}
            </CardHeader>
            <CardBody>
              <Table className="table table-striped">
                <thead>
                  <tr>
                    <th width="35%">Description</th>
                    <th width="15%">Field</th>
                    <th width="25%">Operator</th>
                    <th width="25%">Value</th>
                  </tr>
                </thead>
                <tbody>
                  {conditions}
                </tbody>
              </Table>
            </CardBody>
          </Card>
          <br/>
        </div>
      );
    }
  );
  return <div className="my-well" ref={componentRef}>
    <Row>
      <Col sm="6">
        <div className="page-title">
          <i className="fa fa-reorder"></i>&nbsp;Auto Assign Panel - {state.appraiser.first_name+' '+state.appraiser.last_name}
        </div>
        <NavLink to={"/auto-assign/panel/"+state.stateName+"/"+state.county}>Back to auto assign panel</NavLink>
      </Col>
      <Col sm="6">

      </Col>
    </Row>
    <div className="my-divider"></div>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i> Black List Rule
      </CardHeader>
      <CardBody>
        <p><font className="red-color">*</font>Please create a rule first then add conditions to it.</p>

        <div className="large-scroll-container">
          {blackLists}
        </div>

        <br/>
        <div className="my-divider"></div>

        <i className="fa fa-plus green-color"></i> Add black list rules<br/>
        <label>Rule description</label>
        <Row>
          <Col sm="4">
            <Input type="text" value={state.newBlackListDescription} onChange={(e)=>setState({newBlackListDescription:e.target.value})} />
          </Col>
          <Col sm="8">
            <Button color="warning" onClick={addNewBlackList}>Create rule</Button>
          </Col>
        </Row>
        <br/>
        <div className="my-divider"></div>
        <p><font className="red-color">*</font>In order for a rule to be triggered, all conditions that associated with it must be full fill.</p>

        <i className="fa fa-plus green-color"></i> Add black list rules conditions<br/>

        <form onSubmit={(e)=>{e.preventDefault();addNewBlackListCondition()}}>
          <Row>
            <Col sm="6">
              <label><font className="red-color">*</font>Associated rule</label>
              <MySelect
                type="select"
                required={true}
                selectIsClearable={true}
                value={state.newBlackListConditionRuleSelected}
                onChange={(v)=>setState({newBlackListConditionRuleSelected:v})}
                options={state.blackList.map((rule)=>{
                  return {label:rule.description, value:rule.ID};
                })}
              />
            </Col>
            <Col sm="6">
              <label>Description</label>
              <Input type="text" onChange={(e)=>setState({newBlackListConditionDescription:e.target.value})} value={state.newBlackListConditionDescription}></Input>
            </Col>
          </Row>

          <Row>
            <Col sm="4">
              <label><font className="red-color">*</font>Field name</label>
              <MySelect
                type="select"
                required={true}
                selectIsClearable={true}
                value={state.newBlackListConditionField}
                onChange={(v)=>setState({newBlackListConditionField:v})}
                options={state.fieldsEnum.map((field)=>{
                  return {label:field.label, value:field.field};
                })}
              />
            </Col>
            <Col sm="4">
              <label><font className="red-color">*</font>Operator</label>
              <MySelect
                type="select"
                required={true}
                selectIsClearable={true}
                value={state.newBlackListConditionOperator}
                onChange={(v)=>setState({newBlackListConditionOperator:v})}
                options={[{label:"Equal to",value:"="},{label:"Not equal to",value:"!="},{label:"Like",value:"like"}]}
              />
            </Col>
            <Col sm="4">
              <label><font className="red-color">*</font>Value</label>
              {valueWidget}
            </Col>
          </Row>
          <br/>
          <div className="align-right"><Button color="warning">Create condition</Button></div>
        </form>
      </CardBody>
    </Card>

    <br/>
    <div className="my-divider"></div>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i> White List Rule
      </CardHeader>
      <CardBody>
        <p><font className="red-color">*</font>Please create a rule first then add conditions to it.</p>

        <div className="large-scroll-container">
          {whiteLists}
        </div>

        <br/>
        <div className="my-divider"></div>

        <i className="fa fa-plus green-color"></i> Add white list rules<br/>
        <label>Rule description</label>
        <Row>
          <Col sm="4">
            <Input type="text" value={state.newWhiteListDescription} onChange={(e)=>setState({newWhiteListDescription:e.target.value})} />
          </Col>
          <Col sm="8">
            <Button color="warning" onClick={addNewWhiteList}>Create rule</Button>
          </Col>
        </Row>
        <br/>
        <div className="my-divider"></div>
        <p><font className="red-color">*</font>In order for a rule to be triggered, all conditions that associated with it must be full fill.</p>

        <i className="fa fa-plus green-color"></i> Add white list rules conditions<br/>

        <form onSubmit={(e)=>{e.preventDefault();addNewWhiteListCondition()}}>
          <Row>
            <Col sm="6">
              <label><font className="red-color">*</font>Associated rule</label>
              <MySelect
                type="select"
                required={true}
                selectIsClearable={true}
                value={state.newWhiteListConditionRuleSelected}
                onChange={(v)=>setState({newWhiteListConditionRuleSelected:v})}
                options={state.whiteList.map((rule)=>{
                  return {label:rule.description, value:rule.ID};
                })}
              />
            </Col>
            <Col sm="6">
              <label>Description</label>
              <Input type="text" onChange={(e)=>setState({newWhiteListConditionDescription:e.target.value})} value={state.newWhiteListConditionDescription}></Input>
            </Col>
          </Row>

          <Row>
            <Col sm="4">
              <label><font className="red-color">*</font>Field name</label>
              <MySelect
                type="select"
                required={true}
                selectIsClearable={true}
                value={state.newWhiteListConditionField}
                onChange={(v)=>setState({newWhiteListConditionField:v})}
                options={state.fieldsEnum.map((field)=>{
                  return {label:field.label, value:field.field};
                })}
              />
            </Col>
            <Col sm="4">
              <label><font className="red-color">*</font>Operator</label>
              <MySelect
                type="select"
                required={true}
                selectIsClearable={true}
                value={state.newWhiteListConditionOperator}
                onChange={(v)=>setState({newWhiteListConditionOperator:v})}
                options={[{label:"Equal to",value:"="},{label:"Not equal to",value:"!="},{label:"Like",value:"like"}]}
              />
            </Col>
            <Col sm="4">
              <label><font className="red-color">*</font>Value</label>
              {valueWidget2}
            </Col>
          </Row>
          <br/>
          <div className="align-right"><Button color="warning">Create condition</Button></div>
        </form>
      </CardBody>
    </Card>

    <br/>
    <div className="my-divider"></div>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i> Only List Rule
      </CardHeader>
      <CardBody>
        <p><font className="red-color">*</font>Please create a rule first then add conditions to it.</p>

        <div className="large-scroll-container">
          {onlyUseLists}
        </div>

        <br/>
        <div className="my-divider"></div>

        <i className="fa fa-plus green-color"></i> Add only use list rules<br/>
        <label>Rule description</label>
        <Row>
          <Col sm="4">
            <Input type="text" value={state.newOnlyUseListDescription} onChange={(e)=>setState({newOnlyUseListDescription:e.target.value})} />
          </Col>
          <Col sm="8">
            <Button color="warning" onClick={addNewOnlyUseList}>Create rule</Button>
          </Col>
        </Row>
        <br/>
        <div className="my-divider"></div>
        <p><font className="red-color">*</font>In order for a rule to be triggered, all conditions that associated with it must be full fill.</p>

        <i className="fa fa-plus green-color"></i> Add only use list rules conditions<br/>

        <form onSubmit={(e)=>{e.preventDefault();addNewOnlyUseListCondition()}}>
          <Row>
            <Col sm="6">
              <label><font className="red-color">*</font>Associated rule</label>
              <MySelect
                type="select"
                required={true}
                selectIsClearable={true}
                value={state.newOnlyUseListConditionRuleSelected}
                onChange={(v)=>setState({newOnlyUseListConditionRuleSelected:v})}
                options={state.onlyUseList.map((rule)=>{
                  return {label:rule.description, value:rule.ID};
                })}
              />
            </Col>
            <Col sm="6">
              <label>Description</label>
              <Input type="text" onChange={(e)=>setState({newOnlyUseListConditionDescription:e.target.value})} value={state.newOnlyUseListConditionDescription}></Input>
            </Col>
          </Row>

          <Row>
            <Col sm="4">
              <label><font className="red-color">*</font>Field name</label>
              <MySelect
                type="select"
                required={true}
                selectIsClearable={true}
                value={state.newOnlyUseListConditionField}
                onChange={(v)=>setState({newOnlyUseListConditionField:v})}
                options={state.fieldsEnum.map((field)=>{
                  return {label:field.label, value:field.field};
                })}
              />
            </Col>
            <Col sm="4">
              <label><font className="red-color">*</font>Operator</label>
              <MySelect
                type="select"
                required={true}
                selectIsClearable={true}
                value={state.newOnlyUseListConditionOperator}
                onChange={(v)=>setState({newOnlyUseListConditionOperator:v})}
                options={[{label:"Equal to",value:"="},{label:"Not equal to",value:"!="},{label:"Like",value:"like"}]}
              />
            </Col>
            <Col sm="4">
              <label><font className="red-color">*</font>Value</label>
              {valueWidget3}
            </Col>
          </Row>
          <br/>
          <div className="align-right"><Button color="warning">Create condition</Button></div>
        </form>
      </CardBody>
    </Card>
  </div>;
}


export default AutoAssignAppraiser;
