//Author Sooyoung Kim
//Date June 7, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, formatNumber, getFromTo, replaceRegex} from '../../../util/util';
import {Button, Col, Row, Table, Collapse, Card, CardHeader, CardBody} from 'reactstrap';
import {useNavigate, useParams} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';
import moment from 'moment';
import '../report.css';

import ReactExport from "react-export-excel";
import ReportFilter from '../report-filter';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

let defaultfrom = getFromTo('from');
let defaultto = getFromTo('to');

//initialize the state
const initialState = {
  report:[],
  clients:[],
  states:[],
  loanPurpose:'',
  loanType:'',
};

//reducer function that perform state update
const reducer = getReducer();


const StateRankReport  = (props)=>{
  const controller = new AbortController();
  let history = useNavigate();
  let {params} = useParams();

  let stateName = '';
  let county = '';
  let city = '';
  let loanType = '';
  let loanPurpose = '';
  let complex = '';
  let rush = '';
  let client = '';
  let openBy = '';
  let entity = '';

  let from = defaultfrom;
  let to = defaultto;

  if(params&&params!==''){
    params = params.split('&');
    if(params)
      for(let i=0;i<params.length;i++){
        let token = params[i].split('=');
        token[1] = token[1].replace('ForwardSlash', '/');
        token[1] = token[1].replace('Ampersand', '&');
        token[1] = decodeURIComponent(token[1]);

        switch(token[0]){
          case 'from':
            from = token[1];
            break;
          case 'to':
            to = token[1];
            break;
          case 'state':
            stateName = token[1];
            break;
          case 'county':
            county = token[1];
            break;
          case 'city':
            city = token[1];
            break;
          case 'loanType':
            loanType = token[1];
            break;
          case 'loanPurpose':
            loanPurpose = token[1];
            break;
          case 'complex':
            complex = token[1];
            break;
          case 'rush':
            rush = token[1];
            break;
          case 'openBy':
            openBy = token[1];
            break;
          case 'client':
            client = token[1];
            break;
          case 'entity':
            entity = token[1];
            break;
          default:
            break;
        }
      }
  }

  let newInitialState = Object.assign({}, initialState, {
    from:from,
    to:to,
    loanPurpose:loanPurpose,
    loanType:loanType,
    isRush:rush,
    isComplex:complex,
    stateName:stateName,
    county:county,
    city:city,
    entity:entity,
    client:client,
    openBy:openBy,
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getReport();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  //API call
  const getReport = ()=>{
    let loanPurpose = replaceRegex(state.loanPurpose);
    let url = 'report/stateRank/get/openBy='+state.openBy+'&client='+state.client+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+state.state+'&county='+state.county+'&city='+state.city;

    let callBack = (response)=>{console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        for(let i=0;i<response.data.data.length;i++){
          setState({['state'+response.data.data.state]:false});
        }
        setState({report:response.data.data});
      }
    };
    callBack = callBack.bind(this);

    httpGet(url, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
    history('/report/state-rank/openBy='+state.openBy+'&client='+state.client+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+state.state+'&county='+state.county+'&city='+state.city);
  }

  //render
  let newfrom, newto;
  if(state.from!=='')
    newfrom = moment(state.from);
  if(state.to!=='')
    newto = moment(state.to);

  let stateInfo = [];
  let countyInfo = [];
  if(state.report && state.report.length!==0){
    stateInfo = state.report.map(
      (stateName, index)=>{
        let stateInfoValue = {};

        let stateAvgFee = 0;

        if(stateName.count>0)
          stateAvgFee = stateName.total_fee/stateName.count;

        let avg1004Fee = 0;

        if(stateName['1004']>0)
          avg1004Fee = stateName['1004_fee']/stateName['1004'];

        let avg1073Fee = 0;

        if(stateName['1073']>0)
          avg1073Fee = stateName['1073_fee']/stateName['1073'];

        let avg1025Fee = 0;

        if(stateName['1025']>0)
          avg1025Fee = stateName['1025_fee']/stateName['1025'];

        stateInfoValue = {place:stateName.state, count:stateName.count, average:formatNumber(stateAvgFee), avg1004:formatNumber(avg1004Fee), avg1073:formatNumber(avg1073Fee), avg1025:formatNumber(avg1025Fee)}

        stateName.counties.map(
          (county, index3)=>{
            if(county.county !== ''){
              let countyInfoValue = {};

              let countyAvgFee = 0;

              if(county.count>0)
                countyAvgFee = county.total_fee/county.count;

              let countyAvg1004Fee = 0;

              if(county['1004']>0)
                countyAvg1004Fee = county['1004_fee']/county['1004'];

              let countyAvg1073Fee = 0;

              if(county['1073']>0)
                countyAvg1073Fee = county['1073_fee']/county['1073'];

              let countyAvg1025Fee = 0;

              if(county['1025']>0)
                countyAvg1025Fee = county['1025_fee']/county['1025'];

                countyInfoValue = {state:stateName.state, place:county.county, count:county.count, average:formatNumber(countyAvgFee), avg1004:formatNumber(countyAvg1004Fee), avg1073:formatNumber(countyAvg1073Fee), avg1025:formatNumber(countyAvg1025Fee)}

              countyInfo.push(countyInfoValue);
            }
            return null;
        });
        return(stateInfoValue);
      }
    );
  }

  let reportFiltersConfig = [
    {id:'from',value:newfrom, updateFunc:(v)=>setState({from:v}), width:'2'},
    {id:'to',value:newto, updateFunc:(v)=>setState({to:v}), width:'2'},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'4'},
    {id:'openBy',value:state.openBy, updateFunc:(v)=>setState({openBy:v}), width:'4'},
    {id:'rush',value:state.isRush, updateFunc:(v)=>setState({isRush:v}), width:'2'},
    {id:'complex',value:state.isComplex, updateFunc:(v)=>setState({isComplex:v}), width:'2'},
    {id:'state',value:state.state, updateFunc:(v)=>setState({state:v}), width:'2'},
    {id:'county',value:state.county, updateFunc:(v)=>setState({county:v}), width:'2', group:false},
    {id:'city',value:state.city, updateFunc:(v)=>setState({city:v}), width:'2'},
    {id:'entity',value:state.entity, updateFunc:(v)=>setState({entity:v}), width:'2'},
    {id:'loanPurpose',value:state.loanPurpose, updateFunc:(v)=>setState({loanPurpose:v}), width:'2'},
    {id:'loanType',value:state.loanType, updateFunc:(v)=>setState({loanType:v}), width:'2'},
    {id:'button',value:'Submit', updateFunc:getReport, width:'8', className:"align-right", color:"warning"},
  ];

  return <div>
    <div className="padding">
      <ReportFilter {...props} configs={reportFiltersConfig}/>
      <div className="my-divider">&nbsp;</div>
      <i>Orders that do not have appraiser fee / assignment pending will not be display & count towards the average appraiser fee.</i>
      <Card>
        <CardHeader className="header-color">
          <Row>
            <Col sm="6" className="align-left">
              State Rank Report
            </Col>
            <Col sm="6" className="align-right">
              <ExcelFile element={<Button disabled={stateInfo.length<=0} color="info" >Download Excel</Button>}>
                <ExcelSheet data={stateInfo} name="State">
                    <ExcelColumn label="State" value="place"/>
                    <ExcelColumn label="Order #" value="count"/>
                    <ExcelColumn label="Avg Fee" value="average"/>
                    <ExcelColumn label="Avg 1004" value="avg1004"/>
                    <ExcelColumn label="Avg 1073" value="avg1073"/>
                    <ExcelColumn label="Avg 1025" value="avg1025"/>
                </ExcelSheet>
                <ExcelSheet data={countyInfo} name="County">
                    <ExcelColumn label="State" value="state"/>
                    <ExcelColumn label="County" value="place"/>
                    <ExcelColumn label="Order #" value="count"/>
                    <ExcelColumn label="Avg Fee" value="average"/>
                    <ExcelColumn label="Avg 1004" value="avg1004"/>
                    <ExcelColumn label="Avg 1073" value="avg1073"/>
                    <ExcelColumn label="Avg 1025" value="avg1025"/>
                </ExcelSheet>
              </ExcelFile>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Table className="table table-striped">
            <tbody>
              <tr>
                <td>
                  <Row style={{fontWeight:'bold',color:'#1C91E0'}}>
                    <Col sm="7">State</Col><Col sm="1">Avg Fee</Col><Col sm="1">Order #</Col><Col sm="1">Avg 1004</Col><Col sm="1">Avg 1073</Col><Col sm="1">Avg 1025</Col>
                  </Row>
                </td>
              </tr>
              {
                state.report.map(
                  (stateName, index)=>{
                    let icon1 = <i className="fa fa-plus green-color"></i>;

                    if(state["state"+stateName.state])
                      icon1 = <i className="fa fa-minus red-color"></i>;

                    let stateAvgFee = 0;

                    if(stateName.count>0)
                      stateAvgFee = stateName.total_fee/stateName.count;

                    let avg1004Fee = 0;

                    if(stateName['1004']>0)
                      avg1004Fee = stateName['1004_fee']/stateName['1004'];

                    let avg1073Fee = 0;

                    if(stateName['1073']>0)
                      avg1073Fee = stateName['1073_fee']/stateName['1073'];

                    let avg1025Fee = 0;

                    if(stateName['1025']>0)
                      avg1025Fee = stateName['1025_fee']/stateName['1025'];
                    return (
                      <tr key={index}>
                        <td>
                          <Row className="cursor-pointer" id={"state"+index} onClick={(e)=>{setState({["state"+stateName.state]:!state["state"+stateName.state]})}}>
                            <Col sm="7">
                              {icon1} {stateName.state}
                            </Col>
                            <Col sm="1">
                              ${formatNumber(stateAvgFee)}
                            </Col>
                            <Col sm="1">
                              {stateName.count}
                            </Col>
                            <Col sm="1">
                              ${formatNumber(avg1004Fee)}
                            </Col>
                            <Col sm="1">
                              ${formatNumber(avg1073Fee)}
                            </Col>
                            <Col sm="1">
                              ${formatNumber(avg1025Fee)}
                            </Col>
                          </Row>
                          <Collapse isOpen={state["state"+stateName.state]}>
                            <br/>
                            <label><b>Cities</b></label>
                            <div className="padding my-well">
                              <Table>
                                <tbody>
                                  <tr>
                                    <td>
                                      <Row style={{fontWeight:'bold',color:'#58DD69'}}>
                                        <Col sm="7">Cities</Col><Col sm="1">Avg Fee</Col><Col sm="1">Order #</Col><Col sm="1">Avg 1004</Col><Col sm="1">Avg 1073</Col><Col sm="1">Avg 1025</Col>
                                      </Row>
                                    </td>
                                  </tr>
                                  {
                                    stateName.cities.map(
                                      (city, index2)=>{
                                        let cityAvgFee = 0;

                                        if(city.count>0)
                                          cityAvgFee = city.total_fee/city.count;

                                        let cityAvg1004Fee = 0;

                                        if(city['1004']>0)
                                          cityAvg1004Fee = city['1004_fee']/city['1004'];

                                        let cityAvg1073Fee = 0;

                                        if(city['1073']>0)
                                          cityAvg1073Fee = city['1073_fee']/city['1073'];

                                        let cityAvg1025Fee = 0;

                                        if(city['1025']>0)
                                          cityAvg1025Fee = city['1025_fee']/city['1025'];

                                        return (
                                          <tr key={index2}>
                                            <td>
                                              <Row>
                                                <Col sm="7">
                                                  {city.city}
                                                </Col>
                                                <Col sm="1">
                                                  ${formatNumber(cityAvgFee)}
                                                </Col>
                                                <Col sm="1">
                                                  {city.count}
                                                </Col>
                                                <Col sm="1">
                                                  ${formatNumber(cityAvg1004Fee)}
                                                </Col>
                                                <Col sm="1">
                                                  ${formatNumber(cityAvg1073Fee)}
                                                </Col>
                                                <Col sm="1">
                                                  ${formatNumber(cityAvg1025Fee)}
                                                </Col>
                                              </Row>
                                            </td>
                                          </tr>
                                        )
                                      }
                                    )
                                  }
                                </tbody>
                              </Table>
                            </div>
                            <br/>
                            <label><b>Counties</b></label>
                            <div className="padding my-well">
                              <Table>
                                <tbody>
                                  <tr>
                                    <td>
                                      <Row style={{fontWeight:'bold',color:'#58DD69'}}>
                                        <Col sm="7">Counties</Col><Col sm="1">Avg Fee</Col><Col sm="1">Order #</Col><Col sm="1">Avg 1004</Col><Col sm="1">Avg 1073</Col><Col sm="1">Avg 1025</Col>
                                      </Row>
                                    </td>
                                  </tr>
                                  {
                                    stateName.counties.map(
                                      (county, index3)=>{
                                        if(county.county !== ''){
                                          let countyAvgFee = 0;

                                          if(county.count>0)
                                            countyAvgFee = county.total_fee/county.count;

                                          let countyAvg1004Fee = 0;

                                          if(county['1004']>0)
                                            countyAvg1004Fee = county['1004_fee']/county['1004'];

                                          let countyAvg1073Fee = 0;

                                          if(county['1073']>0)
                                            countyAvg1073Fee = county['1073_fee']/county['1073'];

                                          let countyAvg1025Fee = 0;

                                          if(county['1025']>0)
                                            countyAvg1025Fee = county['1025_fee']/county['1025'];

                                          return (
                                            <tr key={index3}>
                                              <td>
                                                <Row>
                                                  <Col sm="7">
                                                    {county.county}
                                                  </Col>
                                                  <Col sm="1">
                                                    ${formatNumber(countyAvgFee)}
                                                  </Col>
                                                  <Col sm="1">
                                                    {county.count}
                                                  </Col>
                                                  <Col sm="1">
                                                    ${formatNumber(countyAvg1004Fee)}
                                                  </Col>
                                                  <Col sm="1">
                                                    ${formatNumber(countyAvg1073Fee)}
                                                  </Col>
                                                  <Col sm="1">
                                                    ${formatNumber(countyAvg1025Fee)}
                                                  </Col>
                                                </Row>
                                              </td>
                                            </tr>
                                          )
                                        }

                                        return null;
                                      }
                                    )
                                  }
                                </tbody>
                              </Table>
                            </div>
                          </Collapse>
                        </td>
                      </tr>
                    )
                  }
                )
              }
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </div>
  </div>;
}

export default StateRankReport;
