//Author Sooyoung Kim
//Date June 29,2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, deleteAPICallGenerator, callBackGenerator, formatNumber, getFromTo, replaceRegex} from '../../../util/util';
import {Button, Card, CardHeader, CardBody} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import moment from 'moment';
import {NavLink} from 'react-router-dom';
import MyReactTable from '../../util/my-react-table';
import ReportFilter from '../report-filter';
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

//initialize the state
const initialState = {
  accountManager:'',
  reportDetails:[],

  threshold:'75',
  from:getFromTo('start'),
  to:getFromTo('end'),
  client:'',
  openBy:'',
  selectedAppraisalType:'',
  filterAppraisalTypes:[],
  state:'',
  appraiser:''
};

//reducer function that perform state update
const reducer = getReducer();


const ThresholdReport  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getReportDetails();
    
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  //API call
  const getReportDetails = () =>{
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let callBack = apiCallBack([{state:'reportDetails', key:'data'}]);
    httpGet('report/threshold/from='+state.from+'&to='+state.to+'&accountManager='+state.accountManager+'&client='+state.client+'&openBy='+state.openBy+'&state='+state.state+'&appraisalType='+appraisalType+'&appraiser='+state.appraiser+'&threshold='+state.threshold, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  const approveThreshold = (id) =>{
    let callBack = (response)=>{console.log(response);
      let code = response.data?response.data.code:undefined;

      if(code==='00'){
        let reportDetails = state.reportDetails.slice();

        for(let i=0;i<reportDetails.length;i++){
          if(reportDetails[i].ID===id){
            reportDetails[i].approved = true;
            break;
          }
        }
        setState({reportDetails:reportDetails});
      }
    };
    callBack = callBack.bind(this);

    let parameters = [{field:'appraisalFk',value:id}];

    httpPost('report/threshold/create', parameters, 'Threshold approved.', 'Oops, something went wrong and could not approved this threshold. Please try again later.', callBack);
  }

  const removeApproval = (id) =>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;

      if(code==='00'){
        let reportDetails = state.reportDetails.slice();

        for(let i=0;i<reportDetails.length;i++){
          if(reportDetails[i].ID===id){
            reportDetails[i].approved = false;
            break;
          }
        }

        setState({reportDetails:state.reportDetails});
      }
    };
    callBack = callBack.bind(this);


    httpDelete('report/threshold/'+id,'Approval removed.','Oops, something went wrong and could not delete the approval. Please try again later.', callBack);
  }


  //render
  const columns = [
    {
      id: 'referenceNum',
      Header: 'Reference #',
      accessor: d => d.reference_num,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'appraiser',
      Header: 'Appraiser',
      accessor: d => d.appraiser_name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'state',
      Header: 'State',
      accessor: d => d.property_state,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'appraisalType',
      Header: 'Report Type',
      accessor: d => d.appraisal_type,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'receivableTotal',
      Header: 'Receivable Total',
      accessor: d => d.invoice_total,
      Cell: props => <div>${formatNumber(props.row.original.invoice_total)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'status',
      Header: 'Payable Total',
      accessor: d => d.bill_total,
      Cell: props => <div>${formatNumber(props.row.original.bill_total)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'profit',
      Header: 'Profit',
      accessor: d => d.invoice_total-d.bill_total,
      Cell: props => <div>${formatNumber(props.row.original.invoice_total-props.row.original.bill_total)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'action',
      Header: 'Approved',
      accessor: d => d.approved,
      Cell: props => {
        let icon = <i onClick={(e)=>approveThreshold(props.row.original.ID)} className="fa fa-check gray-color cursor-pointer"></i>;

        if(props.row.original.approved)
          icon = <i onClick={(e)=>removeApproval(props.row.original.ID)} className="fa fa-check green-color cursor-pointer"></i>;

          return icon;
      },
      headerStyle: {
        textAlign:'left'
      }
    },
  ];

  let from, to;
  if(state.from!=='')
    from = moment(state.from);
  if(state.to!=='')
    to = moment(state.to);

  let reportFiltersConfig = [
    {id:'custom',value:state.threshold, updateFunc:(v)=>setState({threshold:v}), width:'2', label:'Threshold'},
    {id:'from',value:from, updateFunc:(v)=>setState({from:v}), width:'2'},
    {id:'to',value:to, updateFunc:(v)=>setState({to:v}), width:'2'},
    {id:'accountManager',value:state.accountManager, updateFunc:(v)=>setState({accountManager:v}), width:'3'},
    {id:'state',value:state.state, updateFunc:(v)=>setState({state:v}), width:'3'},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'3'},
    {id:'openBy',value:state.openBy, updateFunc:(v)=>setState({openBy:v}), width:'3'},
    {id:'custom',value:state.appraiser, updateFunc:(v)=>setState({appraiser:v}), width:'2', label:'Appraiser'},
    {id:'appraisalType',value:state.filterAppraisalTypes, updateFunc:(v)=>setState({filterAppraisalTypes:v}), width:'2'},
    {id:'button',value:'Submit', updateFunc:getReportDetails, width:'2', className:"align-right", color:"warning"},
  ];  

  return <div>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Threshold Report
      </CardHeader>
      <CardBody>
        <ReportFilter {...props} configs={reportFiltersConfig}/>
        <div className="my-divider">&nbsp;</div>
        <div className="align-right" style={{marginBottom:'15px'}}>
          <ExcelFile element={<Button disabled={state.reportDetails.length<=0} color="warning">Download Excel</Button>}>
            <ExcelSheet data={state.reportDetails} name="XLSX">
                <ExcelColumn label="Reference #" value="reference_num"/>
                <ExcelColumn label="Appraiser" value="appraiser_name"/>
                <ExcelColumn label="State" value="property_state"/>
                <ExcelColumn label="Report Type" value="appraisal_type"/>
                <ExcelColumn label="Receivable Total" value={(col)=>'$'+formatNumber(col.invoice_total)}/>
                <ExcelColumn label="Payable Total" value={(col)=>'$'+formatNumber(col.bill_total)}/>
                <ExcelColumn label="Profit" value={(col)=>{return '$'+formatNumber(col.invoice_total-col.bill_total)}}/>
            </ExcelSheet>
          </ExcelFile>
        </div>
        <MyReactTable columns={columns} data={state.reportDetails} className="table table-striped"/>
      </CardBody>
    </Card>
  </div>;
}


export default ThresholdReport;
