//Author Sooyoung Kim
//Date June 7, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, formatNumber, getFromTo,replaceRegex} from '../../../util/util';
import {Button, Card, CardHeader, CardBody, Col, Row, Modal, ModalHeader, ModalBody} from 'reactstrap';
import {NavLink} from 'react-router-dom';
import MyReactTable from '../../util/my-react-table';
import React, {useReducer, useEffect} from 'react';
import moment from 'moment';
import '../report.css';

import ReactExport from "react-export-excel";
import ReportFilter from '../report-filter';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

let from = getFromTo('from');
let to = getFromTo('to');

//initialize the state
const initialState = {
  loanPurpose:'',
  loanType:'',
  isRush:'',
  isComplex:'',
  state:'',
  city:'',
  entity:'',
  associatedEntity:'',
  selectedAppraisalType:'',
  filterAppraisalTypes:[],
  states:[],
  from:from,
  to:to,

  counties:[],
  showInfo:false,

  reportDetailsToggle:false,
  reportDetails:'',
  reportDetailsOrders:[]
};

//reducer function that perform state update
const reducer = getReducer( );


const CountyByCountyReport  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getReport();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const reportDetailsToggle = (orders)=>{
    setState({reportDetailsOrders:orders, reportDetailsDropDown:!state.reportDetailsDropDown});
  }

  const toggleInfo = ()=>{
    setState({showInfo:!state.showInfo});
  }


  //API call
  const getReport = ()=>{
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);

    let url = 'report/yellowPage/associated_entity='+state.associatedEntity+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&appraisalType='+appraisalType;
    let callBack = apiCallBack([{state:'counties', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }


  //render
  let from, to;
  if(state.from!=='')
    from = moment(state.from);
  if(state.to!=='')
    to = moment(state.to);

  let content;

  if(state.reportDetailsOrders.length>0){
    let rows = state.reportDetailsOrders.map(
      (order, index)=>{
        return(
          <tr key={index}>
            <td><NavLink target="_blank" to={"/appraisal/"+order.ID}>{order.reference_num}</NavLink></td>
            <td>{order.property_street+' '+order.property_city+', '+order.property_state+' '+order.property_zip}</td>
            <td>{order.appraisal_type}</td>
            <td>${formatNumber(order.total_receivable)}</td>
            <td>${formatNumber(order.total_payable)}</td>
            <td>${formatNumber(order.total_profit)}</td>
            <td>{order.condition_count}</td>
            <td>{order.rebuttal_count}</td>
            <td>{formatNumber(order.tat)} bd</td>
          </tr>
        )
      }
    );

    content =
    <table className="table table-striped">
      <thead>
        <tr>
          <th>Reference #</th>
          <th>Address</th>
          <th>Report Type</th>
          <th>Receivable</th>
          <th>Payable</th>
          <th>Profit</th>
          <th>Total Condition</th>
          <th>Total Rebuttal</th>
          <th>Turntime</th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </table>
  }

  const columns = [
    {
      id: 'county',
      Header: 'County',
      accessor: d => d.county,
      Cell: props => <NavLink onClick={(e)=>{reportDetailsToggle(props.row.original.orders)}} to="#">{props.row.original.county}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'totalVolume',
      Header: 'Total Volume',
      accessor: d => d.overall_total,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'availableAppraiser',
      Header: 'Available Appraiser',
      accessor: d => d.appraiser_total,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'overallAvgTAT',
      Header: 'Overall avg TAT',
      accessor: d => d.overall_avg_turntime,
      Cell: props => <div>{props.row.original.overall_avg_turntime} bd</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'totalCondition',
      Header: 'Total Condition',
      accessor: d => d.total_condition,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'totalRebuttal',
      Header: 'Total Rebuttal',
      accessor: d => d.total_rebuttal,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'totalProfit',
      Header: 'Profit',
      accessor: d => d.total_profit,
      Cell: props => <div>${formatNumber(props.row.original.total_profit)}</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'unknownAvgTAT',
      Header: 'Unclassified',
      accessor: d => d.unknown_avg_turntime,
      Cell: props => <div>{props.row.original.unknown_avg_turntime} bd</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'urbanAvgTAT',
      Header: 'Urban',
      accessor: d => d.urban_avg_turntime,
      Cell: props => <div>{props.row.original.urban_avg_turntime} bd</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'subUrbanAvgTAT',
      Header: 'Suburban',
      accessor: d => d.suburban_avg_turntime,
      Cell: props => <div>{props.row.original.suburban_avg_turntime} bd</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'ruralAvgTAT',
      Header: 'Rural',
      accessor: d => d.rural_avg_turntime,
      Cell: props => <div>{props.row.original.rural_avg_turntime} bd</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
  ];

  let reportFiltersConfig = [
    {id:'from',value:from, updateFunc:(v)=>setState({from:v}), width:'2'},
    {id:'to',value:to, updateFunc:(v)=>setState({to:v}), width:'2'},
    {id:'rush',value:state.isRush, updateFunc:(v)=>setState({isRush:v}), width:'2'},
    {id:'complex',value:state.isComplex, updateFunc:(v)=>setState({isComplex:v}), width:'2'},
    {id:'state',value:state.state, updateFunc:(v)=>setState({state:v}), width:'2'},
    {id:'city',value:state.city, updateFunc:(v)=>setState({city:v}), width:'2'},
    {id:'loanPurpose',value:state.loanPurpose, updateFunc:(v)=>setState({loanPurpose:v}), width:'2'},
    {id:'loanType',value:state.loanType, updateFunc:(v)=>setState({loanType:v}), width:'2'},
    {id:'entity',value:state.entity, updateFunc:(v)=>setState({entity:v}), width:'2'},
    {id:'appraisalType',value:state.filterAppraisalTypes, updateFunc:(v)=>setState({filterAppraisalTypes:v}), width:'3'},
    {id:'button',value:'Submit', updateFunc:getReport, width:'3', className:"align-right", color:"warning"},
  ];

  return <div>
    <Modal className="my-modal" isOpen={state.showInfo} toggle={toggleInfo} >
      <ModalHeader hidden={true} toggle={toggleInfo}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-question-circle"></i>&nbsp;Report Information</h5>
        </center>
        <br/>
        <font size="8px">
          <font color="red">*** If the action is taken by the user during non-business hours(Monday through Friday before 9:00 AM and after 5:00 PM and weekend), the system will record the action time using the earliest business hours(bh)/business day(bd) for the TAT calculation. *** </font><br/><br/>
          <b><font color="#FD7F20">Submitted</font> <i className="fa fa-arrow-right"></i> <font color="#006DB0">Completed:</font></b> TAT Calculation that includes <b>everything</b> into account from beginning to the end. It does not exclude the wait time for inspection to happen.<br/><br/>
          <b><font color="#FD7F20">Submitted</font> <i className="fa fa-arrow-right"></i> <font color="#006DB0">Assignment Accepted:</font></b> TAT Calculation that includes everything in between of <b>date submitted</b> and <b>date assignment accepted</b> by appraiser.<br/><br/>
          <b><font color="#FD7F20">Inspection Completed</font> <i className="fa fa-arrow-right"></i> <font color="#006DB0">Completed:</font></b> TAT Calculation that includes everything in between of <b>date inspection completed</b> and date completed when the report was <b>shipped</b>.<br/><br/>
          <b><font color="#FD7F20">Accepted</font> <i className="fa fa-arrow-right"></i> <font color="#006DB0">Inspection Date:</font></b> TAT Calculation that includes everything in between <b>date assignment accepted</b> by appraiser and <b>date of inspection</b>. The wait time for inspection to happen is included.<br/><br/>
          <b><font color="#FD7F20">Submitted</font> <i className="fa fa-arrow-right"></i> <font color="#F26627">Scheduled + Inspection Date</font> <i className="fa fa-arrow-right"></i> <font color="#006DB0"> Completed:</font></b> TAT Calculation that is similar to <b>Submitted - Completed TAT Calculation</b> except it <b>excludes the wait time</b> for inspection to happen.<br/><br/>
          *** All TAT exclude hold and waiting on contact period ***
        </font>
        <br/><br/>
        <center>
          <Button color="info" onClick={toggleInfo}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
    <Card>
      <CardHeader className="header-color">
        <Row>
          <Col sm="6" className="align-left">
            County By County Report
          </Col>
          <Col sm="6" className="align-right white-color">
            <NavLink to="#" onClick={toggleInfo}><i className="fa fa-question-circle"></i>&nbsp;Report Information</NavLink>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <ReportFilter {...props} configs={reportFiltersConfig} getReport={getReport}/>
      </CardBody>
    </Card>
    <br/>
    <Card>
      <CardHeader className="header-color">
        <Row>
          <Col sm="8">
            <i className="fa fa-reorder"></i>&nbsp;County By County Report
          </Col>
          <Col sm="4">
            <div className="align-right">
              <ExcelFile element={<Button disabled={state.counties.length<=0} color="warning">Download Excel</Button>}>
                <ExcelSheet data={state.counties} name="XLSX">
                    <ExcelColumn label="County" value="county"/>
                    <ExcelColumn label="Total Volume" value="overall_total"/>
                    <ExcelColumn label="Available Appraiser" value="appraiser_total"/>
                    <ExcelColumn label="Overall avg TAT" value="overall_avg_turntime"/>
                    <ExcelColumn label="Total Condition" value="total_condition"/>
                    <ExcelColumn label="Total Rebuttal" value="total_rebuttal"/>
                    <ExcelColumn label="Profit" value="total_profit"/>
                    <ExcelColumn label="Unclassified" value="unknown_avg_turntime"/>
                    <ExcelColumn label="Urban" value="urban_avg_turntime"/>
                    <ExcelColumn label="Suburban" value="suburban_avg_turntime"/>
                    <ExcelColumn label="Rural" value="rural_avg_turntime"/>
                </ExcelSheet>
              </ExcelFile>
            </div>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <MyReactTable columns={columns} data={state.counties} className="table table-striped"/>
      </CardBody>
    </Card>
    <Modal className="my-modal-wide" isOpen={state.reportDetailsDropDown} toggle={reportDetailsToggle} >
      <ModalHeader hidden={true} toggle={reportDetailsToggle}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-user"></i> Report Details</h5>
        </center>
        <br/>
        <div className="large-scroll-container">
          {content}
        </div>
        <br/>
        <center>
          <Button color="info" onClick={reportDetailsToggle}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
    <br/>
  </div>;
}

export default CountyByCountyReport;
