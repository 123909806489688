//Author Sooyoung Kim
//Date June 30, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, formatNumber, getRoles, replaceRegex} from '../../../util/util';
import {Button, Card, CardHeader, CardBody, Modal, ModalHeader, ModalBody} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import moment from 'moment';
import {NavLink} from 'react-router-dom';
import ReactExport from "react-export-excel";
import MyReactTable from '../../util/my-react-table';
import ReportFilter from '../report-filter';
import '../report.css';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

let today = new Date();
let month = today.getMonth()+1;

if(month<10)
  month = '0'+month;
let from = today.getFullYear()+'-'+month+'-'+today.getDate();
let to = today.getFullYear()+'-'+month+'-'+today.getDate();

//initialize the state
const initialState = {
  role:getRoles(),
  from:from,
  to:to,
  activeTab:'1',
  loanPurpose:'',
  accountManagers:[],
  accountManager:'',
  loanType:'',
  isRush:'',
  isComplex:'',
  state:'',
  city:'',
  entity:'',
  selectedAppraisalType:'',
  filterAppraisalTypes:['Conv Condo (1073)','FHA Condo (1073)','USDA Condo (1073)','Conv SFR (1004)','FHA SFR (1004)','USDA SFR (1004)'],
  appraisalTypes:[],
  report2:[],
  report:[],
  columns:[],
  columns2:[],

  reportDetailsToggle:false,
  reportDetailsToggle2:false,
  reportDetailsOrders:[],
  reportDetailsOrders2:[],

  clientBranches:[],
  clientBranch:0,
  clients:[],
  client:0,
  openByClient:0,

  excludeQuote:'both',
  filterCounty:[],
  priorities:[],
  priority:'',
  autoBid:'',
  excelColumns:[]
};

//reducer function that perform state update
const reducer = getReducer();


const AssignmentLeaderReport  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getReport();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const reportDetailsToggle = () => {
    if(state.reportDetailsDropDown)
      setState({reportDetailsOrders:[]});
    setState({reportDetailsDropDown:!state.reportDetailsDropDown});
  }

  const reportDetailsToggle2 = () => {
    if(state.reportDetailsDropDown2)
      setState({reportDetailsOrders2:[]});
    setState({reportDetailsDropDown2:!state.reportDetailsDropDown2});
  }


  //API call
  const getReport = () => {
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);
    let counties = replaceRegex(state.filterCounty,',');

    let url = 'report/assignment/leader/accountManager='+state.accountManager+'&county='+counties+'&autoBid='+state.autoBid+'&priority='+state.priority+'&excludeQuote='+state.excludeQuote+'&openBy='+state.openByClient+'&clientBranch='+state.clientBranch+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&client='+state.client+'&to='+state.to+'&from='+state.from+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&appraisalType='+appraisalType;
    let callBack = (response)=>{console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let spreadMap = {};

        let spread = response.data.spread;

        for(let i=0;i<spread.length;i++){
          spreadMap[spread[i].user_fk] = {spread:spread[i].spread, total_order:spread[i].total_order};
        }
        let users = {};

        let data = response.data.data;
        let data2 = response.data.data2;
        let max = 1;

        let report = [];
        for(let i=0;i<data.length;i++){
          if(!users[data[i].user_fk]){
            users[data[i].user_fk] = {user_fk:data[i].user_fk, name:data[i].first_name+' '+data[i].last_name};
          }

          if(!users[data[i].user_fk][data[i].version])
            users[data[i].user_fk][data[i].version] = {};

          users[data[i].user_fk][data[i].version][data[i].stat] = {tat:data[i].tat, count:data[i].count};

          if(data[i].version>max)
            max = data[i].version;
        }

        let report2 = [];
        let users2={};

        if(data2.length>0){
          users2[0] = {};
        }
        for(let i=0;i<data2.length;i++){
          if(!users2[0][data2[i].version])
            users2[0][data2[i].version] = {};
          users2[0][data2[i].version][data2[i].stat] = {tat:data2[i].tat};
        }

        Object.keys(users2).forEach(key=>{
          report2.push(Object.assign({}, users2[key]));
        });

        Object.keys(users).forEach(key=>{
          report.push(Object.assign({}, users[key], {spread:spreadMap[key].spread, total_order:spreadMap[key].total_order}));
        });

        //configure columns configure
        let arrangement = [{userLabel:"Submitted", label:'Received', key:'received'},{userLabel:"Bid", label:'Research', key:'research'},{userLabel:"Bid Review", label:'Bid Request', key:'bid-request'},{userLabel:"Quote", label:'Quote', key:'quote'},{userLabel:"Pending Inspection", label:'Acceptance', key:'acceptance'}];
        let columns = [
          {
            id: 'name',
            Header:'Name',
            accessor: d => d.name,
            headerStyle: {
              textAlign:'left'
            },
            minWidth:150
          }
        ];

        let columns2 = [];
        let excelColumns = [];
        //loop through all available stat cycle
        for(let i=1;i<=max;i++){
          for(let j=0;j<arrangement.length;j++){
            let label = arrangement[j].label;

            if(i!==1)
              label = label + 'v'+i;
            excelColumns.push({label: label,version:i, key:arrangement[j].key});
            let column = {
              id: arrangement[j].key+i,
              Header:i===1?<font style={{fontWeight:'bold', fontSize:'11px'}}>{arrangement[j].userLabel}</font>:<font style={{fontWeight:'bold', fontSize:'11px'}}>{arrangement[j].label+' v'+i}</font>,
              accessor: d => d[i]?d[i][arrangement[j].key]:'',
              Cell: props => {
                return <NavLink to="#" onClick={(e)=>getReportDetails(arrangement[j].label, props.row.original.user_fk, i)}>
                  {props.row.original[i] && props.row.original[i][arrangement[j].key]?formatNumber(props.row.original[i][arrangement[j].key].tat):'-'}
              </NavLink>
              },
              headerStyle: {
                textAlign:'left'
              }
            };

            let column2 = {
              id: arrangement[j].key+i,
              Header:i===1?<font style={{fontWeight:'bold', fontSize:'11px'}}>{arrangement[j].userLabel}</font>:<font style={{fontWeight:'bold', fontSize:'11px'}}>{arrangement[j].label+' v'+i}</font>,
              accessor: d => d[i]?d[i][arrangement[j].key]:'',
              Cell: props => {
                return <NavLink to="#" onClick={(e)=>getReportDetails2(arrangement[j].label, i)}>
                  {props.row.original[i] && props.row.original[i][arrangement[j].key]?formatNumber(props.row.original[i][arrangement[j].key].tat):'-'}
              </NavLink>
              },
              headerStyle: {
                textAlign:'left'
              }
            };

            columns.push(column);
            columns2.push(column2);
          }
        }


        columns.push({
          id: 'spread',
          Header:'Spread',
          accessor: d => d.spread,
          Cell: props => {
            return <div>
              ${formatNumber(props.row.original.spread)}
          </div>
          },
          headerStyle: {
            textAlign:'left'
          },
          minWidth:100
        });

        columns.push({
          id: 'overall',
          Header:'Overall',
          accessor: d => d.spread,
          Cell: props => {
            return <NavLink to="#" onClick={(e)=>getReportDetails('overall', props.row.original.user_fk, 1)}>
              {props.row.original[1] && props.row.original[1].overall? formatNumber(props.row.original[1].overall.tat):'-'}
          </NavLink>
          },
          headerStyle: {
            textAlign:'left'
          }
        });

        columns2.push({
          id: 'overall',
          Header:'Overall',
          accessor: d => d.spread,
          Cell: props => {
            return <NavLink to="#" onClick={(e)=>getReportDetails2('overall', 1)}>
              {props.row.original[1] && props.row.original[1].overall? formatNumber(props.row.original[1].overall.tat):'-'}
          </NavLink>
          },
          headerStyle: {
            textAlign:'left'
          }
        });

        setState({report2:report2, report:report, columns2:columns2, columns:columns, excelColumns:excelColumns});
      }
    };
    callBack = callBack.bind(this);

    httpGet(url, '',  'Oops, something went wrong and could not load the report details. Please try again later.', callBack);
  }

  const getReportDetails = (stat, userFk, version) => {
    reportDetailsToggle();

    let counties = state.filterCounty.join(',');
    let appraisalType = state.filterAppraisalTypes.join('|');
    appraisalType = replaceRegex(appraisalType);

    let loanPurpose = replaceRegex(state.loanPurpose);
    let callBack = apiCallBack([{state:'reportDetailsOrders', key:'data'}]);
    httpGet('report/assignment/leader/details/version='+version+'&stat='+stat+'&userFk='+userFk+'&accountManager='+state.accountManager+'&county='+counties+'&autoBid='+state.autoBid+'&priority='+state.priority+'&excludeQuote='+state.excludeQuote+'&openBy='+state.openByClient+'&clientBranch='+state.clientBranch+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&client='+state.client+'&to='+state.to+'&from='+state.from+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&appraisalType='+appraisalType, '', 'Oops, something went wrong and could not load the report details. Please try again later.', callBack);
  }

  const getReportDetails2 = (stat, version) => {
    reportDetailsToggle2();

    let counties = state.filterCounty.join(',');
    let appraisalType = state.filterAppraisalTypes.join('|');
    appraisalType = replaceRegex(appraisalType);

    let loanPurpose = replaceRegex(state.loanPurpose);
    let callBack = apiCallBack([{state:'reportDetailsOrders2', key:'data'}]);
    httpGet('report/assignment/leader/details/version='+version+'&stat='+stat+'&userFk=0&accountManager='+state.accountManager+'&county='+counties+'&autoBid='+state.autoBid+'&priority='+state.priority+'&excludeQuote='+state.excludeQuote+'&openBy='+state.openByClient+'&clientBranch='+state.clientBranch+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&client='+state.client+'&to='+state.to+'&from='+state.from+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&appraisalType='+appraisalType, '', 'Oops, something went wrong and could not load the report details. Please try again later.', callBack);
  }

  //render
  let excelConfig;

  const columns = state.columns.slice();
  const columns2 = state.columns2.slice();

  excelConfig = <ExcelFile style={{marginBottom:'15px'}} element={<Button to="#" disabled={state.report.length<=0} color="warning">Download</Button>}>
    <ExcelSheet data={state.report} name="XLSX">
      <ExcelColumn label="Name" value="name"/>
        {
          state.excelColumns.map((columns)=>{
            return <ExcelColumn label={columns.userLabel} value={(col)=>{return col[columns.version] && col[columns.version][columns.key]? formatNumber(col[columns.version][columns.key].tat):'-'}}/>
          })
        }
        <ExcelColumn label="Spread" value={(col)=>{return '$'+formatNumber(col.spread)}}/>
        <ExcelColumn label="Overall" value={(col)=>{return col[1]&& col[1].overall ?col[1].overall.tat: '-'}}/>
    </ExcelSheet>
  </ExcelFile>;

  let table;
  let table2;
  if(columns.length>0){
    table = <MyReactTable columns={columns} data={state.report} className="table table-striped"/>;

    table2 = <MyReactTable columns={columns2} data={state.report2} className="table table-striped"/>;

  }

  let from, to;
  if(state.from!=='')
    from = moment(state.from);
  if(state.to!=='')
    to = moment(state.to);

  let content;
  let content2;
  if(state.reportDetailsOrders.length>0){
    let rows = state.reportDetailsOrders.map(
      (order, index)=>{
        return(
          <tr key={index}>
            <td><NavLink target="_blank" to={"/appraisal/"+order.ID}>{order.reference_num}</NavLink></td>
            <td>{order.loan_num}</td>
            <td>{order.property_state}</td>
            <td>{order.property_county}</td>
            <td>{order.appraisal_type}</td>
            <td>{order.complex}</td>
            <td>{order.rush}</td>
            <td>{order.tat} bh</td>
          </tr>
        )
      }
    );


    content =
    <table className="table table-striped">
      <thead>
        <tr>
          <th width="12%">Reference #</th>
          <th width="12%">Loan #</th>
          <th width="10%">State</th>
          <th width="15%">County</th>
          <th width="20%">Report Type</th>
          <th width="11%">Complex</th>
          <th width="10%">Rush</th>
          <th width="10%">TAT</th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </table>
  }

  if(state.reportDetailsOrders2.length>0){
    let rows = state.reportDetailsOrders2.map(
      (order, index)=>{
        return(
          <tr key={index}>
            <td><NavLink target="_blank" to={"/appraisal/"+order.ID}>{order.reference_num}</NavLink></td>
            <td>{order.loan_num}</td>
            <td>{order.property_state}</td>
            <td>{order.property_county}</td>
            <td>{order.appraisal_type}</td>
            <td>{order.complex}</td>
            <td>{order.rush}</td>
            <td>{order.tat} bh</td>
          </tr>
        )
      }
    );


    content2 =
    <table className="table table-striped">
      <thead>
        <tr>
          <th width="12%">Reference #</th>
          <th width="12%">Loan #</th>
          <th width="10%">State</th>
          <th width="15%">County</th>
          <th width="20%">Report Type</th>
          <th width="11%">Complex</th>
          <th width="10%">Rush</th>
          <th width="10%">TAT</th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </table>
  }

  let reportFiltersConfig = [
    {id:'from',value:from, updateFunc:(v)=>setState({from:v}), width:'2'},
    {id:'to',value:to, updateFunc:(v)=>setState({to:v}), width:'2'},
    {id:'state',value:state.state, updateFunc:(v)=>setState({state:v}), width:'2'},
    {id:'county',value:state.filterCounty, updateFunc:(v)=>setState({filterCounty:v}), width:'2', group:true},
    {id:'city',value:state.city, updateFunc:(v)=>setState({city:v}), width:'2'},
    {id:'accountManager',value:state.accountManager, updateFunc:(v)=>setState({accountManager:v}), width:'2'},
    {id:'loanPurpose',value:state.loanPurpose, updateFunc:(v)=>setState({loanPurpose:v}), width:'2'},
    {id:'loanType',value:state.loanType, updateFunc:(v)=>setState({loanType:v}), width:'2'},
    {id:'entity',value:state.entity, updateFunc:(v)=>setState({entity:v}), width:'2'},
    {id:'complex',value:state.isComplex, updateFunc:(v)=>setState({isComplex:v}), width:'2'},
    {id:'rush',value:state.isRush, updateFunc:(v)=>setState({isRush:v}), width:'2'},
    {id:'appraisalType',value:state.filterAppraisalTypes, updateFunc:(v)=>setState({filterAppraisalTypes:v}), width:'2'},
    {id:'customSelect',value:state.excludeQuote, updateFunc:(v)=>setState({excludeQuote:v}), width:'2', label:'Exclude', clearable:true, className:"", options:[
      {label:"None",value:"no"},{label:"Hold",value:"hold"},{label:"Cancel",value:"cancel"},{label:"Hold + Cancel",value:"both"}
    ]},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'2'},
    {id:'openBy',value:state.openBy, updateFunc:(v)=>setState({openBy:v}), width:'2'},
    {id:'clientBranch',value:state.clientBranch, updateFunc:(v)=>setState({clientBranch:v}), width:'2'},
    {id:'priority',value:state.priority, updateFunc:(v)=>setState({priority:v}), width:'2'},
    {id:'customSelect',value:state.excludeQuote, updateFunc:(v)=>setState({excludeQuote:v}), width:'1', label:'Auto Bid', clearable:true, className:"", options:[
      {label:"Yes",value:"yes"},{label:"No",value:"no"}
    ]},
    {id:'button',value:'Submit', updateFunc:getReport, width:'1', className:"align-right", color:"warning"},
  ];
    
  return <div>
    <Modal className="my-modal-wide" isOpen={state.reportDetailsDropDown} toggle={reportDetailsToggle} >
      <ModalHeader hidden={true} toggle={reportDetailsToggle}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-user"></i> Report Details (Breakdown)</h5>
        </center>
        <br/>
        <div className="large-scroll-container">
          {content}
        </div>
        <br/>
        <center>
          <Button color="info" onClick={reportDetailsToggle}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
    <Modal className="my-modal-wide" isOpen={state.reportDetailsDropDown2} toggle={reportDetailsToggle2} >
      <ModalHeader hidden={true} toggle={reportDetailsToggle2}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-user"></i> Report Details (Overview)</h5>
        </center>
        <br/>
        <div className="large-scroll-container">
          {content2}
        </div>
        <br/>
        <center>
          <Button color="info" onClick={reportDetailsToggle2}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Assignment Turntime Report
      </CardHeader>
      <CardBody>
        <ReportFilter {...props} configs={reportFiltersConfig}/>
      </CardBody>
    </Card>
    <br/>
    <div style={{fontSize:'11px'}}>

      <font style={{fontWeight:'bold'}}><i>Received</i></font> - Order submitted until viewed<br/>
      <font style={{fontWeight:'bold'}}><i>Research</i></font> - Viewed until bid submitted to appraisers<br/>
      <font style={{fontWeight:'bold'}}><i>Bid Request</i></font> - (Received + Research) Order submitted until bid submitted to appraisers<br/>
      <font style={{fontWeight:'bold'}}><i>Quote</i></font> - Bid submitted to appraiser until quote are presented/vetted to client<br/>
      <font style={{fontWeight:'bold'}}><i>Acceptance</i></font> - Quote presented/vetted to client until assignment was accepted by appraiser<br/>

    </div>
    <br/>
    <div className="align-right">
      {excelConfig}
    </div>
    <div className="align-right" style={{fontSize:'11px', marginTop:'5px'}}>
      <i>** all stats showed below are labeled by business hours</i><br/>
    </div>
    <br/>
    <label>Overview</label><br/>
    {table2}
    <br/>
    <label>Break Down</label>
    <div className="large-scroll-container">
      {table}
    </div>
  </div>;
}


export default AssignmentLeaderReport;
