//Author Sooyoung Kim
//Date May 11th, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, callBackGenerator, formatDate, encodeParam} from '../../util/util';
import {Col, Row, Button, Card, CardHeader, CardBody, Input} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import MySelect from '../util/my-select';
import {NavLink, useNavigate} from 'react-router-dom';


//initialize the state
const initialState = {
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
  paymentTypes:[],
  company:'',
  email:'',
  phone:'',
  fax:'',
  street:'',
  city:'',
  county:'',
  stateName:'',
  zip:'',
  paymentType:'',
  fnm:'',
  fre:'',
  businessUnit:'',
  businessUnitFHA:'',
  fhaClientID:'',

  searchCompanies:[]
};

//reducer function that perform state update
const reducer = getReducer();


const NewClient  = (props)=>{
  const controller = new AbortController();
  let history = useNavigate();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getPaymentTypes();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  //API call
  const addNewClient = (e)=>{
    e.preventDefault();
    let url = 'client/create';
    let successMsg = 'Client created successfully.';
    let errorMsg = 'Oops, something went wrong and could not create this client. Please try again later.';

    let parameters = [
      {
        field:'company',
        value:state.company
      },
      {
        field:'paymentType',
        value:state.paymentType
      },
      {
        field:'email',
        value:state.email
      },
      {
        field:'phone',
        value:state.phone
      },
      {
        field:'fax',
        value:state.fax
      },
      {
        field:'street',
        value:state.street
      },
      {
        field:'city',
        value:state.city
      },
      {
        field:'county',
        value:state.county
      },
      {
        field:'state',
        value:state.stateName
      },
      {
        field:'zip',
        value:state.zip
      },
      {
        field:'fnm',
        value:state.fnm
      },
      {
        field:'fre',
        value:state.fre
      },
      {
        field:'businessUnit',
        value:state.businessUnit
      },
      {
        field:'fhaClientID',
        value:state.fhaClientID
      },
      {
        field:'businessUnitFHA',
        value:state.businessUnitFHA
      },
      {
        field:'status',
        value:'Approved'
      }
    ];


    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let data = response.data.data;
        let newID = data.ID;
        history('/client/'+newID+'/status=');
      }
    };
    callBack = callBack.bind(this);

    httpPost(url, parameters, successMsg, errorMsg, callBack);
  }

  const searchCompanies = ()=>{
    let company = encodeParam(state.company);
    let url = 'client/get/limit=100&offset=0&company='+company+'&status=All';
    let callBack = apiCallBack([{state:'searchCompanies', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load list of companies. Please try again later.', callBack)
  }

  //get payment types
  const getPaymentTypes = ()=>{
    let url = 'billing/paymentType/get';
    let callBack = apiCallBack([{state:'paymentTypes', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load list of available payment type. Please try again later.', callBack)
  }

  //render
  let searchResult  = <div className="medium-scroll-container"></div>;

  if(state.searchCompanies.length>0){
    searchResult = <div>
      <font color="red">*Below are a list of existing companies with similar name</font><br/><br/>
      <div className="medium-scroll-container">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Company</th>
              <th>Email Registered</th>
              <th>Status</th>
              <th>Profile Registered</th>
            </tr>
          </thead>
          <tbody>
            {
              state.searchCompanies.map(
                (company, index)=>{
                  return <tr key={index}>
                    <td><NavLink to={"/client/"+company.ID+"/status="}>{company.company}</NavLink></td>
                    <td>{company.email}</td>
                    <td>{company.status}</td>
                    <td>{formatDate(company.datetime_created)}</td>
                  </tr>
                }
              )
            }
          </tbody>
        </table>
      </div>
    </div>
  }

  return <div className="my-well">
    <Row>
      <Col sm="6">
        <div className="page-title">
          <i className="fa fa-reorder"></i>&nbsp;New Client Profile
        </div>
        <NavLink to="/client">Back to client list</NavLink>
      </Col>
      <Col sm="6">

      </Col>
    </Row>
    <div className="my-divider"></div>

    <form onSubmit={addNewClient}>
      <Card>
        <CardHeader className="header-color">
          Basic Information
        </CardHeader>
        <CardBody>
          {searchResult}
          <Row>
            <Col sm="3">
              <label><font color="red">*</font>Company name</label>
              <Input type="text" value={state.company} required={true} onChange={(e)=>{setState({company:e.target.value});searchCompanies()}}/>
            </Col>
            <Col sm="3">
              <label><font color="red">*</font>Payment type</label>
              <MySelect
                type="select"
                value={state.paymentType}
                onChange={(v)=>setState({paymentType:v})}
                options={state.paymentTypes.map((paymentType)=>{
                  return {label:paymentType.payment_type, value:paymentType.payment_type};
                })}
              />
            </Col>
          </Row>
          <Row>
            <Col sm="3">
              <label><font color="red">*</font>Email</label>
              <Input type="text" value={state.email} required={true} onChange={(e)=>setState({email:e.target.value})}/>
            </Col>
            <Col sm="3">
              <label><font color="red">*</font>Phone</label>
              <Input type="text" value={state.phone} required={true} onChange={(e)=>setState({phone:e.target.value})}/>
            </Col>
            <Col sm="3">
              <label>Fax</label>
              <Input type="text" value={state.fax} onChange={(e)=>setState({fax:e.target.value})}/>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <br/>
      <Card>
        <CardHeader className="header-color">
          Address
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="3">
              <label><font color="red">*</font>Street</label>
              <Input type="text" value={state.street} required={true} onChange={(e)=>setState({street:e.target.value})}/>
            </Col>
            <Col sm="3">
              <label><font color="red">*</font>City</label>
              <Input type="text" value={state.city} required={true} onChange={(e)=>setState({city:e.target.value})}/>
            </Col>
            <Col sm="3">
              <label><font color="red">*</font>State</label>
              <MySelect
                type="select"
                value={state.stateName}
                onChange={(v)=>setState({stateName:v})}
                options={state.states.map((state)=>{
                  return {label:state.value, value:state.value};
                })}
              />
            </Col>
            <Col sm="3">
              <label><font color="red">*</font>Zip</label>
              <Input type="text" value={state.zip} required={true} onChange={(e)=>setState({zip:e.target.value})}/>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <br/>

      <Card>
        <CardHeader className="header-color">
          SSR Information
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="4">
              <label>FNM</label>
              <Input type="text" value={state.fnm} onChange={(e)=>setState({fnm:e.target.value})}/>
            </Col>
            <Col sm="4">
              <label>FRE</label>
              <Input type="text" value={state.fre} onChange={(e)=>setState({fre:e.target.value})}/>
            </Col>
            <Col sm="4">
              <label>Business unit #</label>
              <Input type="text" value={state.businessUnit} onChange={(e)=>setState({businessUnit:e.target.value})}/>
            </Col>
          </Row>
          <Row>
            <Col sm="4">
              <label>FHA Client ID</label>
              <Input type="text" value={state.fhaClientID} onChange={(e)=>setState({fhaClientID:e.target.value})}/>
            </Col>
            <Col sm="4">
              <label>Business unit # (FHA)</label>
              <Input type="text" value={state.businessUnitFHA} onChange={(e)=>setState({businessUnitFHA:e.target.value})}/>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <br/>
      <div className="align-right">
        <Button color="warning">Add</Button>
      </div>
    </form>
  </div>;
}

export default NewClient;
