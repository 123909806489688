//Author June Leow
//Date June 1st, 2023
import React, {useEffect, useRef, useState} from 'react';
import Highcharts from "highcharts";
import highchartsMap from "highcharts/modules/map";
import HighchartsReact from "highcharts-react-official";
import countiesMap from "../../util/us-all-counties.json";

const MyHeatMapCounties  = (props)=>{
  const controller = new AbortController();
  highchartsMap(Highcharts);

  //run only once when component is loaded
  useEffect(()=>{


    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  //API call
  const chartComponent = useRef(null);


  let counties = countiesMap.objects.default.geometries;
  let chartData = [];
  let propsData = props.data;

  for(let i=0;i<propsData.length;i++){
    let stateshort = propsData[i].state_short.toLowerCase();
    let county = propsData[i].county.toLowerCase().replace('county','').trim();

    let keyword2 = county+', '+stateshort;

    for(let j=0;j<counties.length;j++){
      if(!counties[j].properties.name || !counties[j].properties['hc-key'])
        continue;
      let tokens =  counties[j].properties['hc-key'].split('-');

      let keyword1 = counties[j].properties.name.toLowerCase()+', '+tokens[1];

      if(keyword1===keyword2){
        let tmp = {
          code : counties[j].properties['hc-key'],
          name : propsData[i].county+', '+propsData[i].state_short,
          value: propsData[i].value
        };

        chartData.push(tmp);
        break;
      }
    }
  }
  console.log(countiesMap)
  const [options, ] = useState({
    chart: {
      map: countiesMap
    },
    title: {
      text: props.title
    },
    mapNavigation: {
      enabled: true,
    },
    legend: {
        layout: 'vertical',
        align: 'right',
        floating: true,
        backgroundColor: ( // theme
            Highcharts.defaultOptions &&
            Highcharts.defaultOptions.legend &&
            Highcharts.defaultOptions.legend.backgroundColor
        ) || 'rgba(255, 255, 255, 0.85)'
    },
    colorAxis: {
        min: props.min?props.min:0,
        max: props.max?props.max:20,
        tickInterval: 5,
        stops: [[0, '#77E818'], [0.5, '#BFE818'], [0.7, '#E8E818'], [0.8,'#E88A18'],[1,'#E83518']],
        labels: {
          format: '{value} '+(props.dataLabel?props.dataLabel:props.title)
        }
    },
    plotOptions: {
      mapline: {
        showInLegend: false,
        enableMouseTracking: false
      }
    },
    series: [
      {
        data: chartData,
        joinBy: ['hc-key', 'code'],
        name: props.title,
        tooltip: {
          valueSuffix: '%'
        },
        borderWidth: 0.5,
        shadow: false,
        accessibility: {
            enabled: false
        }
      },
      {
        type: 'mapline',
        name: 'State borders',
        color: 'white',
        shadow: false,
        borderWidth: 2,
        accessibility: {
          enabled: false
        }
      }
    ]
  });
  
  //render
  return <div>
    <HighchartsReact
      ref={chartComponent}
      highcharts={Highcharts}
      options={options}
      constructorType="mapChart"
    />
  </div>;
}

export default MyHeatMapCounties;
