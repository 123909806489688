// Sooyoung Kim
// June 21, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, deleteAPICallGenerator, callBackGenerator, formatNumber, formatDate, generateSID, sliceFromArray, getFromTo} from '../../../util/util';
import {Button, Card, CardHeader, CardBody, Row, Col, Collapse, Modal, ModalHeader, ModalBody, Table, Input, TabContent, TabPane, Nav, NavItem, NavLink as NavLinkL} from 'reactstrap';
import DatePicker from 'react-datepicker';
import MyXEditable from '../../util/my-xeditable';
import moment from 'moment';
import {NavLink} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';
import ReactDOM from 'react-dom';
import ReportFilter from '../report-filter';

let start = getFromTo('start');
let end = getFromTo('end');

//initialize the state
const initialState = {
  SID: generateSID(),
  activeTab : "1",
  appraisers:[],
  datesMap:{},
  start:start,
  end:end,
  paymentQueue:{},
  dropDowns:{},
  idToName:{},
  appraiser:'',
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
  clients:[],
  client:'',
  state:'',
  paymentType:'',
  amc:'',
  directDeposits:{},
  reminders:[],

  checks:[],
  bills:[],
  bankAccountName:'Wells Fargo Checking',
  expensesAccountName:'5000 Cost of Goods Sold:5100 Appraisal Fees',
  submitDisabled:false
};

//reducer function that perform state update
const reducer = getReducer();


const PaidPayment  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const clickHandler = () =>{
    setState({submitDisabled:true});
    submitCheckResync();
  }
  
  const addCheck = (check, billsArr) =>{
    let checks = state.checks.slice();
    let bills = state.bills.slice();

    let index = getCheckIndex(check);
    if(index===-1){
      checks.push(check);
      bills.push(billsArr);
    }
    else{
      checks.splice(index,1);
      bills.splice(index,1);
    }
    setState({checks:checks, bills:bills});
  }

  const getCheckIndex = (check) =>{
    let checks = state.checks.slice();

    let index = checks.indexOf(check);

    return index;
  }

  const updateFieldCallBack = (ID, field, value) =>{
    console.log(ID, field, value);
    let reminders = state.reminders.slice();

    for(let i=0;i<reminders.length;i++){
      if(reminders[i].ID===ID){
        reminders[i][field] = value;
        break;
      }
    }

    setState({reminders:reminders});
  }

  const toggleTab = (tab) =>{
    setState({activeTab:tab});
  }

  const scrollTo = (ID) =>{
    const elem = ReactDOM.findDOMNode(this.refs["appraiser"+ID]);
    if(elem){
      elem.scrollTop = elem.scrollIntoView();
    }
  }

  const updateDate = (appraiserFk, date) =>{
    let directDeposits = Object.assign({},state.directDeposits);
    let datesMap = Object.assign({},state.datesMap);

    for(let property in directDeposits){
      if(property===appraiserFk){
        datesMap[property] = date;
        break;
      }
    }

    setState({datesMap:datesMap});
  }

  const toggleReminder = () =>{
    getReminders();
    setState({reminderPopUp:!state.reminderPopUp});
  }

  //API call
  const generateUpdateFunction = (url, parameters, successMessage, failedMessage, parentCallBack=null) =>{
    return (newValue, callBack, errorCallBack)=>{
      //add in the new value into parameters
      parameters.push({field:'value',value:newValue});
      let promise = httpPut(url,parameters,successMessage,failedMessage, callBack, errorCallBack);
      promise.then(
        (response)=>{
          let field;
          let value;

          for(let i=0;i<parameters.length;i++){
            if(parameters[i].field==='field')
              field = parameters[i].value;
            else if(parameters[i].field==='value')
              value = parameters[i].value;
          }
          if(parentCallBack!==null){
            parentCallBack(field, value);
          }
        }
      );
    }
  };

  const submitCheckResync = () =>{
    let checks = state.checks.slice();
    let bills = state.bills.slice();

    let c = [];
    let b = [];

    for(let i=0;i<checks.length;i++){
      let actualCheck = checks[i].split('|')
      actualCheck = actualCheck[1];
      for(let j=0;j<bills[i].length;j++){
        b.push(bills[i][j].bill_fk);
        c.push(checks[i]);
      }
    }

    let parameters = [
      {
        field:'bills',
        value:b
      },
      {
        field:'checks',
        value:c
      },
      {
        field:'expensesAccountName',
        value:state.expensesAccountName
      },
      {
        field:'bankAccountName',
        value:state.bankAccountName
      }
    ];

    let callBack = apiCallBack([{state:'billds', value:[]},{state:'checks', value:[]},{state:'submitDisabled', value:false}]);

    let errorCallBack = (error)=>{
      setState({submitDisabled:false});
    };
    errorCallBack = errorCallBack.bind(this);

    httpPost('billing/appraiserFee/quickbook/enqueue', parameters, 'Check inquiry task queue on Quickbook successfully.', 'Oops, something went wrong and could not queue this check inquiry task on Quickbook. Please try again later.', callBack, errorCallBack);
  }

  const getReport = () =>{
    let url = 'report/paidPayment/get/start='+state.start+'&end='+state.end+'&state='+state.state+'&client='+state.client+'&amc='+state.amc+'&paymentType='+state.paymentType+'&appraiser='+state.appraiser;

    //call back for retrieving announcement
    let callBack = (response)=>{console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let appraisers = response.data.data;

        let directDeposits = {};
        let datesMap = {};
        for(let i=0;i<appraisers.length;i++){
          let checks = [];
          let checksMap = [];

          for(let j=0;j<appraisers[i].bills.length;j++){
      	    if(checksMap.indexOf(appraisers[i].bills[j].check_num)===-1&&appraisers[i].bills[j].check_num!==''){
              checks.push({check_num:appraisers[i].bills[j].check_num, check_status:appraisers[i].bills[j].check_status, check_qb_id:appraisers[i].bills[j].check_qb_id});
      			  checksMap.push(appraisers[i].bills[j].check_num);
    		    }

            if(appraisers[i].bills[j].method==='Direct Deposit'){
              if(!directDeposits[appraisers[i].ID]){
                directDeposits[appraisers[i].ID] = [];
                datesMap[appraisers[i].ID] = null;
              }
              directDeposits[appraisers[i].ID].push(appraisers[i].bills[j]);
            }
          }

          appraisers[i].check_associated = checks;
        }

        let idToName = {};
        for(let i=0;i<response.data.data.length;i++){
          idToName[response.data.data[i].ID] = response.data.data[i].name;
        }

        let dropDowns = {};
        for(let i=0;i<response.data.data.length;i++){
          dropDowns[response.data.data[i].ID] = false;
        }
        
        setState({appraisers:response.data.data, directDeposits:directDeposits, datesMap:datesMap, idToName:idToName, dropDowns:dropDowns});
      }
    };
    callBack = callBack.bind(this);

    httpGet(url, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  const getReminders = () =>{
    let callBack = apiCallBack([{state:'reminders', key:'data'}]);
    httpGet('report/directDeposit/get', '', 'Oops, something went wrong and could not load direct deposit reminders. Please try again later.', callBack);
  }

  const deleteReminder = (id) =>{
    let list = sliceFromArray(state.reminders,'ID', id);
    let callBack = apiCallBack([{state:'reminders', value:list}]);
    httpDelete('report/directDeposit/'+id,'Reminder deleted.','Oops, something went wrong and could not delete this reminder. Please try again later.', callBack);
  }

  const createReminder = () =>{
      let reminders = [];

      for(let property in state.datesMap){
        if(state.datesMap[property]&&state.datesMap[property]!==''){
          let target = state.directDeposits[property];
          let tmp = {};
          let tmp2 = [];
          for(let i=0;i<target.length;i++){
            tmp2.push(target[i].bill_fk);
          }

          tmp.date = state.datesMap[property].format("YYYY-MM-DD");
          tmp.bills = tmp2;

          reminders.push(tmp);
        }
      }

      let parameters = [{field:'reminders',value:reminders}];

      httpPost('report/directDeposit/create', parameters, 'Direct deposit reminder created successfully.', 'Oops, something went wrong and could not create direct deposit reminders. Please try again later.');
  }


  //render
  let start, end;
  if(state.start!=='')
    start = moment(state.start);
  if(state.end!=='')
    end = moment(state.end);


  let chartData = [];
  let appraisers = state.appraisers.map(
    (appraiser,index)=>{
      let totalFee = 0;
      for(let i=0;i<appraiser.bills.length;i++){
        totalFee+=parseInt(appraiser.bills[i].total,10);
      }

      let tmp = {};
      tmp.ID = appraiser.ID;
      tmp.name = appraiser.name;
      tmp.y = totalFee;

      chartData.push(tmp);

      let icon =
      <div className="border cursor-pointer" onClick={(e)=>setState({dropDowns:Object.assign({},state.dropDowns,{[appraiser.ID]:!state.dropDowns[appraiser.ID]})})}>
        <center><b>Show paid</b></center>
      </div>;

      if(state.dropDowns[appraiser.ID])
        icon =
        <div className="border cursor-pointer" onClick={(e)=>setState({dropDowns:Object.assign({},state.dropDowns,{[appraiser.ID]:!state.dropDowns[appraiser.ID]})})}>
          <center><b>Hide paid</b></center>
        </div>

      let categorizedBills = {};

      for(let i=0;i<appraiser.bills.length;i++){
        if(!categorizedBills[appraiser.bills[i].check_num])
          categorizedBills[appraiser.bills[i].check_num] = [];
        categorizedBills[appraiser.bills[i].check_num].push(appraiser.bills[i]);
      }


      let checksAssociated = <div className="display-inline">
        {appraiser.check_associated.map(
          (check, index2)=>{
            return <div className="display-inline" key={index2}>
              #{check.check_num}
            </div>
          }
        )}
      </div>

      return(
        <div key={index} ref={"appraiser"+appraiser.ID}>
          <Card>
            <CardHeader className="header-color">
              <i className="fa fa-user"></i> {appraiser.name} - ${formatNumber(totalFee)} - {appraiser.payment_method} {checksAssociated}
            </CardHeader>
            <CardBody>
              <Row>
                <Col sm="4">
                  <label>Address</label>
                </Col>
                <Col sm="4">
                  <label>Billing address</label>
                </Col>
                <Col sm="4">
                  <label>Billing note</label>
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  {appraiser.address}
                </Col>
                <Col sm="4">
                  {appraiser.billing_address}
                </Col>
                <Col sm="4">
                  <i>{appraiser.billing_note}</i>
                </Col>
              </Row>
              <br/>
              <div className="align-right">
                {icon}
              </div>
              <Collapse isOpen={state.dropDowns[appraiser.ID]}>
                  {
                    Object.keys(categorizedBills).map(
                      (key, index2)=>{
                        return (
                          <div key={index2}>
                            <Card>
                              <CardHeader>
                                &nbsp;&nbsp;&nbsp;&nbsp;<i title="Check the checkbox of this check to resync with QB" className="fa fa-question-circle cursor-pointer"/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input type="checkbox" onClick={(e)=>{addCheck(appraiser.ID+'|'+key, categorizedBills[key])}} checked={getCheckIndex(appraiser.ID+'|'+key)!==-1}/> {categorizedBills[key][0].method} - {key} - {formatDate(categorizedBills[key][0].datetime_paid)}
                              </CardHeader>
                              <CardBody>
                                <Table className="table table-striped">
                                  <thead>
                                    <tr>
                                      <th align="left" width="15%">Payable</th>
                                      <th align="left" width="15^">Date Paid</th>
                                      <th align="left" width="10%">Reference #</th>
                                      <th align="left" width="12%">Paid</th>
                                      <th align="left" width="21%">Property address</th>
                                      <th align="left" width="15%">Borrower</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      categorizedBills[key].map(
                                        (bill, index3)=>{
                                          return(
                                            <tr key={index3}>
                                              <td width="15%" align="left">#{bill.bill_fk}</td>
                                              <td width="15">{formatDate(bill.datetime_paid)}</td>
                                              <td width="15%" align="left"><NavLink className="display-inline no-padding" target="_blank" to={"/appraisal/"+bill.appraisal_fk}>{bill.reference_num}</NavLink></td>
                                              <td width="10%" align="left">${formatNumber(bill.total)}</td>
                                              <td width="31%" align="left">{bill.street+' '+bill.city+', '+bill.state+' '+bill.zip}</td>
                                              <td width="15%" align="left">{bill.borrower}</td>
                                            </tr>
                                          );
                                        }
                                      )
                                    }
                                  </tbody>
                                </Table>
                              </CardBody>
                            </Card>
                            <br/>
                          </div>
                        );
                      }
                    )
                  }
              </Collapse>
            </CardBody>
          </Card>
          <br/>
        </div>
      );
    }
  );

  let paymentQueue=[];
  for(let property in state.paymentQueue){
    let target = state.paymentQueue[property];
    if(target.length>0){
      let tmp = {};
      tmp.name = state.idToName[property];
      tmp.ID = property;
      tmp.bills = target;
      paymentQueue.push(tmp);
    }
  }

  let directDeposits = [];

  for(let property in state.directDeposits){
    let target = state.directDeposits[property];
    let tmp = {};

    tmp.appraiser = target[0].appraiser;

    let total = 0;
    let bills = [];
    for(let i=0;i<target.length;i++){
      bills.push(target[i].bill_fk);
      total = total + parseInt(target[i].total,10);
    }
    tmp.total = total;
    tmp.bills = bills;
    tmp.appraiser_fk = property;
    directDeposits.push(tmp);
  }

  let floatingWindow;

  if(state.checks.length>0){
    floatingWindow = <div style={{width:'450px',background:'#f5f5f5', padding:'15px',borderTopLeftRadius:'15px', borderBottomLeftRadius:'15px',position:'fixed',right:0, top:'350',zIndex:100, boxShadow:'0 0 8px 2px #888'}}>
      <div><b>Check to resync with QB</b></div>
      <div className="my-well">
        {
          state.checks.map(
            (check, index)=>{
              let actualCheck = check.split('|');
              actualCheck = actualCheck[1];
              return <div className="display-inline">#{actualCheck}&nbsp;&nbsp;</div>;
            }
          )
        }
      </div>

      <div className="my-divider">&nbsp;</div>
      <div>
        <label>Bank account name</label> <Input type="text" value={state.bankAccountName} onChange={(e)=>setState({bankAccountName:e.target.value})}/>
        <br/>
        <label>Expenses account name</label> <Input type="text" value={state.expensesAccountName} onChange={(e)=>setState({expensesAccountName:e.target.value})}/>
        <br/>
        <Button color="warning" disabled={state.submitDisabled} onClick={clickHandler}>Submit</Button>
      </div>
    </div>
  }

  let paidTotal = 0;

  let reportFiltersConfig = [
    {id:'from',value:start, updateFunc:(v)=>setState({start:v}), width:'3'},
    {id:'to',value:end, updateFunc:(v)=>setState({end:v}), width:'3'},
    {id:'state',value:state.state, updateFunc:(v)=>setState({state:v}), width:'3'},
    {id:'appraiser',value:state.appraiser, updateFunc:(v)=>setState({appraiser:v}), width:'3'},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'3'},
    {id:'customSelect',value:state.amc, updateFunc:(v)=>setState({amc:v}), width:'3', label:'AMC', clearable:false, className:"", options:[{label:"Include AMC",value:""},{label:"AMC only",value:"amcOnly"},{label:"Exclude AMC",value:"excludeAmc"}]},
    {id:'customSelect',value:state.paymentType, updateFunc:(v)=>setState({paymentType:v}), width:'3', label:'Payment Method', clearable:false, className:"", options:[{label:"Check only",value:"Check"},{label:"Direct Deposit only",value:"Direct Deposit"}]},
    {id:'button',value:'Submit', updateFunc:getReport, width:'3', className:"align-right", color:"warning"},
  ];

  return <div>
    <ReportFilter {...props} configs={reportFiltersConfig}/>
    <div className="my-divider">&nbsp;</div>

    <Nav tabs>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.activeTab === '1'?"active":"inactive" )}
          onClick={() => { toggleTab('1'); }}
          to="#"
        >
          Paid Invoices
        </NavLinkL>
      </NavItem>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.activeTab === '2'?"active":"inactive" ) }
          onClick={() => { toggleTab('2'); }}
          to="#"
        >
          Direct Deposit & Reminders
        </NavLinkL>
      </NavItem>
    </Nav>
    <TabContent activeTab={state.activeTab}>
      <TabPane tabId="1">

        <Card>
          <CardHeader className="header-color">
            Paid payment
          </CardHeader>
          <CardBody>
            {floatingWindow}
            <Row>
              <Col sm="12">
                <table className="table table-striped table-fixed-header" width="100%">
                  <thead>
                    <tr width="100%"><th width="80%">Name</th><th width="20%">Amount</th></tr>
                  </thead>
                  <tbody style={{height:'440px'}}>
                    {
                      chartData.map(
                        (data,index)=>{
                          paidTotal = paidTotal + parseInt(data.y, 10);
                          return(
                            <tr width="100%" key={index}>
                              <td width="80%"><NavLink to="#" onClick={(e)=>scrollTo(data.ID)}>{data.name}</NavLink></td>
                              <td width="20%">${formatNumber(data.y)}</td>
                            </tr>
                          )
                        }
                      )
                    }
                    <tr>
                      <td width="80%"><b>Total</b></td>
                      <td width="20%">${formatNumber(paidTotal)}</td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <br/>
        {appraisers}
      </TabPane>
      <TabPane tabId="2">
        <Card>
          <CardHeader className="header-color">
            <Row>
              <Col sm="6">
                <i className="fa fa-reorder"></i>&nbsp;Direct deposit reminders
              </Col>
              <Col sm="6" className="align-right">
                <NavLink to="#" onClick={toggleReminder}>View existing reminders</NavLink>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <div className="large-scroll-container-no-min">
              <Table className="table table-striped">
                <thead>
                  <tr>
                    <th width="35%">Appraiser</th>
                    <th width="30%">Bill #</th>
                    <th width="15%">Amount</th>
                    <th width="20%">Email reminder date</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    directDeposits.map(
                      (directDeposit,index)=>{
                        return (
                          <tr key={index}>
                            <td>{state.idToName[directDeposit.appraiser_fk]}</td>
                            <td>{directDeposit.bills.map(
                              (bill, index2)=>{
                                return <div className="display-inline" key={index2}>&nbsp;<u>#{bill}</u></div>
                              }
                            )}</td>
                            <td>${formatNumber(directDeposit.total)}</td>
                            <td><DatePicker
                              className="form-control"
                              dateFormat='DD MMM, YYYY'
                              selected={state.datesMap[directDeposit.appraiser_fk]}
                              onChange={(text)=>{updateDate(directDeposit.appraiser_fk,text)}}
                            /></td>
                          </tr>
                        )
                      }
                    )
                  }
                </tbody>
              </Table>
            </div>
            <div className="align-right"><Button color="warning" onClick={createReminder}>Create direct deposit reminder</Button></div>
            <Modal className="my-modal-wide" isOpen={state.reminderPopUp} toggle={toggleReminder} >
              <ModalHeader hidden={true} toggle={toggleReminder}></ModalHeader>
              <ModalBody>
                <center>
                  <h5><i className="fa fa-comments"></i> Active direct deposit reminder</h5>
                </center>
                <br/>

                <div className="large-scroll-container">
                  <Table className="table table-striped">
                    <thead>
                      <tr>
                        <th width="10%"></th><th width="25%">Appraiser</th><th width="20%">Reference #</th><th width="10%">Bill #</th><th width="15%">Amount</th><th width="20%">Reminder date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        state.reminders.map(
                          (reminder,index)=>{
                            return <tr key={index}>
                              <td><i className="fa fa-times red-color cursor-pointer" onClick={(e)=>deleteReminder(reminder.ID)}></i></td>
                              <td>{reminder.appraiser}</td>
                              <td>{reminder.reference_num}</td>
                              <td>{reminder.bill_fk}</td>
                              <td>{reminder.total}</td>
                              <td><MyXEditable
                                type="date"
                                value={reminder.datetime_reminder}
                                updateFunc={
                                  generateUpdateFunction('report/directDeposit/update',[{field:'field',value:'datetime_reminder'}, {field:'ID',value:reminder.ID}],'reminder date updated.', 'An error occured, please try again later.', (field, value)=>{updateFieldCallBack(reminder.ID, field, value)})
                                }
                              /></td>
                            </tr>
                          }
                        )
                      }
                    </tbody>
                  </Table>
                </div>

                <center>
                  <Button color="info" onClick={toggleReminder}>Close</Button>
                </center>
              </ModalBody>
            </Modal>
          </CardBody>
        </Card>
      </TabPane>
    </TabContent>
  </div>;
}


export default PaidPayment;
