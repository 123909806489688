//Author Sooyoung Kim
//Date Sep 15, 2023
import {getReducer, getSetStateFunction, formatNumber} from '../../util/util';
import {Modal, ModalHeader, ModalBody, Table} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import './assignment-cart.css';

//initialize the state
const initialState = {
  modal:false
};

//reducer function that perform state update
const reducer = getReducer();


const AssignmentList  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const toggleModal = () => {
    setState({modal:!state.modal});
  }

  //render

  let content = '';
  if(props.list && props.list.length>0){
    content = <div className="assignment-cart">
      <div onClick={toggleModal} className="flashit">
        <i className="fa fa-list"/> Selected Assignment/Bid List <span className="my-badge3">{props.list?props.list.length:0}</span>
      </div>

      <Modal className="my-modal-wide" isOpen={state.modal} toggle={toggleModal} >
        <ModalHeader hidden={true} toggle={toggleModal}>Assignment/Bid Cart</ModalHeader>
        <ModalBody>
          <Table className="table table-striped">
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Email</th>
                <th width="25">Warnings</th>
                <th width="20">Second Level Warnings</th>
                <th>Distance To Subject Property</th>
              </tr>
            </thead>
            <tbody>
              { 
                props.list.map(
                  (appraiserID, index)=>{
                    let appraiserInfo = props.listInfoMap[appraiserID];
                    return <tr key={index}>
                      <td><i className="fa fa-times red-color cursor-pointer" onClick={(e)=>props.removeAppraiser(appraiserID)}/></td>
                      <td>{appraiserInfo.first_name+' '+appraiserInfo.last_name}</td>
                      <td>{appraiserInfo.email}</td>
                      <td>{
                        appraiserInfo.warnings.map(
                          (warning, index)=>{
                            return (<div key={index}>"<i>{warning}</i>"</div>);
                          }
                        )
                      }</td>
                      <td>{
                        appraiserInfo.second_level_warnings.map(
                          (warning, index)=>{
                            return (<div key={index}>"<i>{warning}</i>"</div>);
                          }
                        )
                      }</td>
                      <td>{formatNumber(appraiserInfo.distance)} miles</td>
                    </tr>
                  }
                )
              }
            </tbody>
          </Table>
        </ModalBody>
      </Modal>
    </div>
  } 

  return <div>
    {content}
  </div>;
}


export default AssignmentList;
