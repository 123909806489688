//Author Sooyoung Kim
//Date July 1st, 2022
import {formatDate, formatNumber, getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, encodeParam} from '../../util/util';
import InfiniteScroll from 'react-infinite-scroll-component';
import {Input, Button, Row, Col} from 'reactstrap';
import {NavLink, useNavigate} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';


//initialize the state
const initialState = {
  appraiserFirms:[],
  limit: 25,
  offset: 0,
  hasMoreAppraiserFirms: true,
  loading: false,
  sort: 'company',
  order: 'ASC',
  status: '',
  statuses: [],
  states:[],
  //for searching
  company:'',
  email:'',
  state:'',
  county:'',
  city:''
};

//reducer function that perform state update
const reducer = getReducer();


const AppraiserFirms  = (props)=>{
  const controller = new AbortController();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getStates();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    console.log(state);
    if(state.appraiserFirms.length<=0 && state.hasMoreAppraiserFirms){
        loadMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state]);


  //non API call but simpyl manage state
  //click handler that switch the state of sorting order then call the function to refresh the list
  //to achieve the "sorting"
  const columnClickHandler = (col)=>{
    if(state.sort===col){
      if(state.order==='ASC')
        setState({order:'DESC'});
      else
        setState({order:'ASC'});
    }
    else
      setState({sort:col,order:'ASC'});
    refreshList();
  }

  //this function refresh the list of orders pipeline
  const refreshList = ()=>{
    setState({
      appraiserFirms:[],
      totalCount:0,
      hasMoreAppraiserFirms:true,
      offset:0
    });
  }

  //this function render the sort icon at each column of the table
  const renderSortIcon = (col)=>{
    if(state.sort===col){
      if(state.order==='ASC')
        return <i className="red-color fa fa-arrow-down"></i>
      else
        return <i className="red-color fa fa-arrow-up"></i>
    }
  }

  //render function for infinite scroller
  const renderAppraiserFirms = ()=>{
    return (
      <div>
        <table className="primary-table">
          <thead>
            <tr>
              <th className="cursor-pointer" width="19%" onClick={()=>columnClickHandler('company')}><i className="fa fa-user"></i>&nbsp;Company {renderSortIcon('first_name')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('status')}><i className="fa fa-info"></i>&nbsp;Status {renderSortIcon('status')}</th>
              <th className="cursor-pointer" width="12%" onClick={()=>columnClickHandler('state')}><i className="fa fa-map-marker"></i>&nbsp;State {renderSortIcon('state')}</th>
              <th className="cursor-pointer" width="12%" onClick={()=>columnClickHandler('county')}><i className="fa fa-map-marker"></i>&nbsp;County {renderSortIcon('county')}</th>
              <th className="cursor-pointer" width="12%" onClick={()=>columnClickHandler('city')}><i className="fa fa-map-marker"></i>&nbsp;City {renderSortIcon('city')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('email')}><i className="fa fa-envelope-open"></i>&nbsp;Email {renderSortIcon('email')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('phone')}><i className="fa fa-phone"></i>&nbsp;Phone {renderSortIcon('phone')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('datetime_created')}><i className="fa fa-calendar-o"></i>&nbsp;Date Created {renderSortIcon('datetime_created')}</th>
            </tr>
          </thead>
          <tbody>
          {
            state.appraiserFirms.map(
              (appraiserFirm)=>{
                return (
                  <tr key={appraiserFirm.ID} onClick={()=>navigate("/appraiser-firm/"+appraiserFirm.ID)}>
                    <td>{appraiserFirm.company}</td>
                    <td>{appraiserFirm.status}</td>
                    <td>{appraiserFirm.state}</td>
                    <td>{appraiserFirm.county}</td>
                    <td>{appraiserFirm.city}</td>
                    <td>{appraiserFirm.email}</td>
                    <td>{appraiserFirm.phone}</td>
                    <td>{formatDate(appraiserFirm.datetime_created)}</td>
                  </tr>
                );
              }
            )
          }
          </tbody>
        </table>
      </div>
    )
  }

  //API call
  //function that fire when the infinite scroll reach bottom
  const loadMore = ()=>{
    //do not load if there is no more conditions or it's loading data
    if(state.hasMoreAppraiserFirms&&!state.loading){
      //set loading equals to true so it won't fire off before we are done
      setState({loading:true});

      let company = encodeParam(state.company);
      let email = encodeParam(state.email);
      let city = encodeParam(state.city);
      let county = encodeParam(state.county);

      let url = 'appraiser/firm/get/limit='+state.limit+'&offset='+state.offset+'&order='+state.order+'&sort='+state.sort+'&status='+state.status+'&company='+company+'&email='+email+'&state='+state.state+'&county='+county+'&city='+city;

      //callback handler that update the state when http request return
      let callBack = (response)=>{
        console.log(response);
        let code = response.data?response.data.code:undefined;

        if(code!=='00'){
          setState({hasMoreAppraiserFirms:false});
        }
        else{
          let newAppraiserFirm = response.data.data;
          let hasMoreAppraiserFirms = true;
          let newOffset = state.offset;
          let totalCount = response.data.count;

          //if http request return empty then no more results, end of list
          if(newAppraiserFirm.length<=0){
            hasMoreAppraiserFirms = false;
          }
          else{
            //increment the offset
            newOffset = state.offset + 1;
          }

          //concat the current array of announcement
          if(state.appraiserFirms.length>0){
            let temp = [...state.appraiserFirms,...newAppraiserFirm];

            setState({appraiserFirms:temp});
          }
          else
            setState({appraiserFirms:newAppraiserFirm});

          setState({hasMoreAppraiserFirms:hasMoreAppraiserFirms, offset:newOffset, totalCount:totalCount});
        }
      };
      callBack = callBack.bind(this);

      //error handler when the http request return with error
      let errorCallBack = apiCallBack([{state:'hasMoreAppraiserFirms', value:false}]);

      //collect the promise and wait for it to finish performing it's task
      let promises = httpGet(url, '','Oops, something went wrong and could not load appraiser firms. Please try again later.', callBack, errorCallBack);
      promises
        .then(
          function(result){
            //set loading equals to false so the function could be fire off once again
            setState({loading:false});
          }
        );
    }
  }

  //get states
  const getStates = ()=>{
    let url = 'state/get';

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code!=='00'){

      }
      else{
        let allStates = [];

        for(let i=0;i<response.data.data.length;i++){
          let tmp = {
            key: response.data.data[i].state,
            value: response.data.data[i].state
          }

          allStates.push(tmp);
        }
        setState({states:allStates});
      }
    };
    callBack = callBack.bind(this);
    httpGet(url, '','Oops, something went wrong and could not load list of available states. Please try again later.', callBack);
  }

  let states;

  if(state.states.length>0){
    states = state.states.map(
      (state,index)=>{
        return(
          <option key={index} value={state.value}>{state.value}</option>
        );
      }
    );
  }

  //render
  return <div>
  <InfiniteScroll
    next={loadMore}
    dataLength={state.appraiserFirms.length}
    hasMore={state.hasMoreAppraiserFirms}
    loader={<div key="nill" className="loader"><center>Loading more appraiser firms...</center></div>}
    initialLoad = {true}
    className="my-well"
    scrollableTarget="contentContainer"
  >

    <div className="page-title">
      <i className="fa fa-reorder"></i>&nbsp;Appraiser Firms
    </div>

    <Row>
      <Col sm="3">
        <label className="no-margin-bottom">Status</label>
        <Input className="form-control" value={state.status} type="select" name="select" id="status" onChange={(value)=>{setState({status:value.target.value})}}>
          <option value="">All</option>
          <option value="disabled">Disabled</option>
          <option value="enable">Enable</option>
        </Input>
      </Col>
      <Col sm="9">
        <label className="no-margin-bottom">Company Name</label>
        <Input type="text" value={state.company} onChange={(e)=>setState({company:e.target.value})}/>
      </Col>
    </Row>
    <Row>
      <Col sm="3">
        <label className="no-margin-bottom">State</label>
        <Input type="select" value={state.state} onChange={(e)=>setState({state:e.target.value})}>
          <option value="">All</option>
          {states}
        </Input>
      </Col>
      <Col sm="3">
        <label className="no-margin-bottom">County</label>
        <Input type="text" value={state.county} onChange={(e)=>setState({county:e.target.value})}/>
      </Col>
      <Col sm="3">
        <label className="no-margin-bottom">City</label>
        <Input type="text" value={state.city} onChange={(e)=>setState({city:e.target.value})}/>
      </Col>
      <Col sm="3" className="align-right valign-bottom">
        <br/>
        <Button color="warning" onClick={()=>refreshList()} >Submit</Button>
      </Col>
    </Row>
    <br/>

    <div className="my-divider"></div>
    <Row>
      <Col sm="6">
          <div>{formatNumber(state.totalCount)} appraiser firms in queue<br/><br/></div>
      </Col>
      <Col sm="6" className="align-right">
        <NavLink to="/appraiser-firm/new"><i className="fa fa-plus"></i> Create new appraiser firm</NavLink>
      </Col>
    </Row>


    {renderAppraiserFirms()}
  </InfiniteScroll>
  </div>;
}

export default AppraiserFirms;
