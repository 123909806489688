//Author Sooyoung Kim
//Date May 31, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator} from '../../util/util';
import {Col, Row} from 'reactstrap';
import MySelect from '../util/my-select';
import React, {useReducer, useEffect} from 'react';


//initialize the state
const initialState = {
  emails:[],
  selectedEmail:'',
  email:'',
  categories:[]
};

//reducer function that perform state update
const reducer = getReducer();

const Emails  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getEmails();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //API call
  const getEmail = (email) =>{
    let callBack = (response)=>{console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        if(response.data.data!==false){
          response.data.data = response.data.data.replace(/cellspacing="15"/g,'cellPadding="15"');
          setState({email:response.data.data});
        }
        else
          setState({email:"<div><br/><br/><center>Preview not available.</center></div>"});
      }
    };
    callBack = callBack.bind(this);
    httpGet('email/get/'+email, '', 'Oops, something went wrong and could not load email. Please try again later.', callBack);
  }

  const getEmails = () =>{
    let callBack = (response)=>{console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let categories = [];
        for(let i=0;i<response.data.data.length;i++){
          if(categories.indexOf(response.data.data[i].category)===-1)
            categories.push(response.data.data[i].category);
        }
        setState({emails:response.data.data,categories:categories});
      }
    };
    callBack = callBack.bind(this);

    httpGet('email/get/all', '', 'Oops, something went wrong and could not load emails. Please try again later.', callBack);
  }

  //render
  let html;

  if(state.email!==''&&state.email!==false)
    html = <center>
      <div dangerouslySetInnerHTML={{__html:state.email}}/>
    </center>;

  return <div className="my-well">
    <Row>
      <Col sm="6">
        <div className="page-title">
          <i className="fa fa-reorder"></i>&nbsp;Emails
        </div>
      </Col>
      <Col sm="6">

      </Col>
    </Row>
    <div className="my-divider"></div>
    <Row>
      <Col sm="4">
        <label>Category</label>&nbsp;
        <MySelect
          type="select"
          selectIsClearable={true}
          value={state.category}
          onChange={(v)=>{setState({category:v})}}
          options={state.categories.map((category)=>{
            return {label:category, value:category};
          })}
        />
      </Col>
      <Col sm="8">
        <label>Email</label>&nbsp;
        <MySelect
          type="select"
          selectIsClearable={true}
          value={state.selectedEmail}
          onChange={(v)=>{setState({selectedEmail:v});getEmail(v)}}
          options={state.emails.filter((email)=>{
            if(email.category===state.category){
              return true;
            }
            return false;
          }).map((email)=>{
            return {label:email.subject, value:email.subject};
          })}
        />
      </Col>
    </Row>
    <br/>
    <div className="my-divider"></div>
    {html}
  </div>;
}

export default Emails;
