//Author Sooyoung Kim
//Date Aug 3, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, callBackGenerator, generateSID, replaceRegex} from '../../../util/util';
import {Button, Card, CardHeader, CardBody, Collapse, Col, Row, Input, FormGroup, Modal, Carousel,CarouselItem,CarouselControl,CarouselIndicators,ModalHeader, ModalBody, Label} from 'reactstrap';
import React, {useReducer, useEffect, useCallback, useRef} from 'react';
import MyReactTable from '../../util/my-react-table';
import {usePusher} from '../../pusher/pusher-context';
import ReportFilter from '../report-filter';
import moment from 'moment';
import './year-to-date-report.css';

//initialize the state
let today = new Date();
let from = today.getFullYear()+'-01-01';
let to = today.getFullYear()+'-12-31';

let currentYear = today.getFullYear();

const initialState = {
  showReportLoading:false,

  new_report_year:currentYear,
  activeIndex:0,
  animating:false,
  clickedHeader:true,
  report:[],
  clients:[],
  client:'',
  open:'',
  status:'',
  label:'',
  statuses:[],
  keyword:'',
  from:from,
  to:to,
  selectedAppraisalType:'',
  filterAppraisalTypes:[],
  loanPurpose:'',
  loanType:'',
  allClicked:false,
  checkedRow:[],
  searchResult:[],
  appraisalTypes:[],
  selectedEntities:[],
  selectedAllEntities:[],
  selectedEntitiesDetail:[],
  loanTypes:[{key:'Conventional',value:'Conventional'},{key:'High Balanced',value:'High Balanced'},{key:'Jumbo',value:'Jumbo'},{key:'Jumbo ARM',value:'Jumbo ARM'},{key:'Jumbo Fixed',value:'Jumbo Fixed'},{key:'Jumbo <$1m',value:'Jumbo <$1m'},{key:'Jumbo $1m - $2m',value:'Jumbo $1m - $2m'},{key:'Jumbo >$2m',value:'Jumbo >$2m'},{key:'FHA',value:'FHA'},{key:'USDA',value:'USDA'},{key:'Other',value:'Other'}],
  loanPurposes:[{key:'Purchase',value:'Purchase'},{key:'Refinance',value:'Refinance'},{key:'R/T Refinance',value:'R/T Refinance'},{key:'C/O Refinance',value:'C/O Refinance'},{key:'HELOC/2nd Mortgage',value:'HELOC/2nd Mortgage'},{key:'Other',value:'Other'}],
  entityList:['Appraiser','AE','CSR','Borrower','Co-Borrower','Loan Officer','Loan Processor','Broker','Broker Company','Other'],
};

//reducer function that perform state update
const reducer = getReducer();


const YearToDateReport  = (props)=>{
  const controller = new AbortController();
  const newsid = useRef(generateSID());
  const pusher = usePusher();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //non API call but simpyl manage state
  const toggleReportLoading = () => {
    setState({showReportLoading:!state.showReportLoading});
  }

  const loadReportResponse = useCallback((ID) => {
    let callBack = apiCallBack([{state:'report', key:'data'},{state:'activeIndex', value:0},{state:'clickedHeader', value:false},{state:'showReportLoading', value:false}]);
    httpGet('report/query/retrieve/'+ID, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [httpGet, setState]);

  const reportReady = useCallback((response) => {
    response = JSON.parse(response)
    let code = response.code;
    let queryID = response.data;
    let SID = response.SID;
    if(SID===newsid.current){
      if(code==='00'){
        loadReportResponse(queryID)
      }
    }
  }, [loadReportResponse]);

  //run only once when component is loaded
  useEffect(() => {
    // Subscribe to a channel and bind to events using the pusher instance
    const channel = pusher.subscribe('private-report');
    channel.bind('report-ready', (data) => {
      reportReady(data);
    });

    searchEntity();

    // Clean up the subscription when the component is unmounted
    return () => {
      channel.unbind('report-ready');
      pusher.unsubscribe('private-report');

      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickHeader = () => {
    setState({clickedHeader:!state.clickedHeader});
  }

  const deleteReport = (index) => {
    let newreport = state.report.slice();
    let newentities = state.selectedEntities.slice();
    let newEntitiesDetail = state.selectedEntitiesDetail.slice();

    newreport.splice(index,1);
    newentities.splice(index,1);
    newEntitiesDetail.splice(index, 1);

    if(newreport.length === index){
      index-=1;
    }

    setState({report:newreport, selectedEntities:newentities, selectedEntitiesDetail:newEntitiesDetail, activeIndex:index});
  }

  const previousButton = () => {
    if(state.animating){
       return;
    }
    let nextIndex;
    let activeIndex = state.activeIndex
    if(activeIndex === 0){
      nextIndex = state.report.length-1;
    }else{
      nextIndex = activeIndex - 1;
    }
    setState({activeIndex:nextIndex});
  }

  const nextButton = () => {
    if(state.animating) {
      return;
    }
    let nextIndex;
    let activeIndex = state.activeIndex
    if(activeIndex === state.report.length-1){
      nextIndex = 0;
    }else{
      nextIndex = activeIndex + 1;
    }
    setState({activeIndex:nextIndex});
  }

  const changeReportYear = (year) => {
    let from = year+'-01-01';
    let to = year+'-12-31';
    setState({new_report_year: year, from: from, to: to});
  }

  const removeSelectedEntity = (index) => {
    let selectedEntities = state.selectedEntities.slice();
    let selectedEntitiesDetail = state.selectedEntitiesDetail.slice();
    selectedEntities.splice(index, 1);
    selectedEntitiesDetail.splice(index, 1);

    setState({selectedEntities:selectedEntities, selectedEntitiesDetail:selectedEntitiesDetail});
  }

  const isCheck = (ID) => {
    let index = state.selectedEntities.indexOf(ID);

    if(index!==-1)
      return true;
    return false;
  }

  const clickRowListener = (id) => {
    let index = state.selectedEntities.indexOf(id);
    let index2 = state.selectedEntitiesDetail.map(e => e.ID).indexOf(id);
    let existingArray = state.selectedEntities.slice();
    let selectedEntitiesDetail = state.selectedEntitiesDetail.slice();

    if(index===-1){
        existingArray.push(id);
        let result = state.searchResult.find(entity => entity.ID === id);
        selectedEntitiesDetail.push(result);
        setState({selectedEntities:existingArray, selectedEntitiesDetail:selectedEntitiesDetail});
    }
    else{
        existingArray.splice(index,1);
        selectedEntitiesDetail.splice(index2,1);
        setState({selectedEntities:existingArray, selectedEntitiesDetail:selectedEntitiesDetail});
    }
  }

  const clickAllRowListener = () => {
    if(!state.allClicked){
      setState({selectedEntities:state.selectedAllEntities, selectedEntitiesDetail:state.searchResult, allClicked:true})
    }else{
      setState({selectedEntities:[], selectedEntitiesDetail:[], allClicked:false});
    }
  }

  //API call
  const searchEntity = () => {
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);

    let url = 'appraisal/entity/status='+state.status+'&client='+state.client+'&open='+state.open+'&from='+state.from+'&to='+state.to+'&keyword='+state.keyword+'&label='+state.label+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&appraisalType='+appraisalType;

    //call back for retrieving Article
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let selectedAllEntities = [];
        response.data.data.map(
          (item, index)=>{
            return selectedAllEntities.push(item.ID);
          }
        );

        setState({selectedAllEntities:selectedAllEntities, searchResult:response.data.data, clickedHeader: true});
      }
    };
    callBack = callBack.bind(this);

    httpGet(url, '', 'Oops, something went wrong and could not search contacts. Please try again later.', callBack);
  }

  const sendEmailReport = () => {
    let searchResult = state.selectedEntitiesDetail.slice();

    let entities = [];
    for(let i=0;i<searchResult.length;i++){
      entities.push({email:searchResult[i].entity_email, name:searchResult[i].entity_name});
    }

    let parameters = [
      {
        field:'reports',
        value:state.report
      },
      {
        field:'entities',
        value:entities
      }
    ];

    httpPost('report/ytdreport/email', parameters, 'Report(s) successfully sent through email.', 'Oops, something went wrong and could not send reports. Please try again later.');
  }

  const getYTDReport = () => {
    let entities = encodeURIComponent(state.selectedEntities);
    let SID = generateSID();
    newsid.current = SID;

    //selectedEntities
    let url = 'report/ytdreport/SID='+SID+'&entity='+entities+'&from='+state.from+'&to='+state.to+'&year='+state.new_report_year;
    let callBack = apiCallBack([], toggleReportLoading());
    httpGet(url, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  //render
  let from, to;
  if(state.from!=='')
    from = moment(state.from);
  if(state.to!=='')
    to = moment(state.to);

  /*
  React Table
  */

  const columns = [
    {
      id:'action',
      Header: <div className="display-inline" style={{verticalAlign:'top'}}><Input type="checkbox" onClick={(e) => clickAllRowListener()}/></div>,
      minWidth:60,
      width:60,
      accessor: d => '',
      Cell: props =>{
        return(
          <div className="display-inline" style={{verticalAlign:'top'}}>
            <FormGroup check>
              <Label check>
                <Input type="checkbox" value={props.row.original.ID} checked={isCheck(props.row.original.ID)} onChange={(e) => clickRowListener(props.row.original.ID)}/>
              </Label>
            </FormGroup>
          </div>
        )
      }
    },
    {
      id: 'label',
      Header: 'Label',
      minWidth:150,
      width:150,
      accessor: d => d.entity_label,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'name',
      Header: 'Name',
      accessor: d => d.entity_name,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'email',
      Header: 'Email',
      accessor: d => d.entity_email,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'order',
      Header: 'Order(s)',
      minWidth:100,
      width:100,
      accessor: d => parseInt(d.order,10),
      headerStyle: {
        textAlign:'left'
      }
    },
  ];

  let reportYear = [];
  let currentYear = new Date().getFullYear();

  for(let i=0;i<10;i++){
    reportYear.push(currentYear);
    currentYear--;
  }

  let yearOption = reportYear.map((year)=>{
    return {label:year, value:year};
  })

  let contactOption = state.entityList.map((entity)=>{
    return {label:entity, value:entity};
  })

  let entityList;
  if(state.selectedEntitiesDetail.length > 0){
    entityList = <div className="large-scroll-container">
      {
        state.selectedEntitiesDetail.map(
          (contact, index)=>{
            return (<div key={contact.ID} className="entity-container cursor-pointer" onClick={(e)=>removeSelectedEntity(index)}>
              <i className="fa fa-minus red-color"/> &nbsp;{contact.entity_name} - {contact.entity_email}
            </div>)
          }
        )
      }
    </div>
  }

  let carouselItemData;
  let carousel;
  if(state.report.length > 0){
    carouselItemData = state.report.map(
      (item, index) => {
        return (
            <CarouselItem key={item.key}
              onExited={() => setState({animating:false})}
              onExiting={() => setState({animating:true})}>
              <center>
                <div dangerouslySetInnerHTML={{__html:item.content}}/>
              </center>
            </CarouselItem>
        );
      }
    );

    carousel =  <div>
      <div className="align-right" style={{marginRight:'30px', marginTop:'30px'}}>
        <i className="fa fa-times red-color fa-lg" onClick={()=>deleteReport(state.activeIndex)}></i>
      </div>
      <Carousel interval={null} previous={previousButton} next={nextButton} activeIndex={state.activeIndex}>
          <CarouselIndicators items={state.report}
              activeIndex={state.activeIndex}
              onClickHandler={(newIndex) => {
                  if(state.animating) {
                    return;
                  }
                  setState({activeIndex:newIndex});
              }} />
              {carouselItemData}
          <CarouselControl directionText="Prev" direction="prev" onClickHandler={previousButton} />
          <CarouselControl directionText="Next" direction="next" onClickHandler={nextButton} />
      </Carousel>
    </div>;
  }

  let reportFiltersConfig = [
    {id:'customSelect',value:state.new_report_year, updateFunc:(v)=>changeReportYear(v), width:'2', label:'Report Year', clearable:false, className:"", options:[{label:"Select the year of report",value:""}].concat(yearOption)},
    {id:'from',value:from, updateFunc:(v)=>setState({from:v}), width:'2'},
    {id:'to',value:to, updateFunc:(v)=>setState({to:v}), width:'2'},
    {id:'custom',value:state.keyword, updateFunc:(v)=>setState({keyword:v}), width:'3', label:'Contact Name/Email'},
    {id:'customSelect',value:state.label, updateFunc:(v)=>setState({label:v}), width:'3', label:'Contact Label', clearable:true, className:"", options:contactOption},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'3'},
    {id:'openBy',value:state.openBy, updateFunc:(v)=>setState({openBy:v}), width:'3'},
    {id:'button',value:'Submit', updateFunc:searchEntity, width:'6', className:"align-right", color:"warning"},
  ];

  return <div>
    <Modal className="my-modal" isOpen={state.showReportLoading} toggle={toggleReportLoading} >
      <ModalHeader hidden={true} toggle={toggleReportLoading}></ModalHeader>
      <ModalBody>
        <center>
          <i style={{fontSize:'55px'}} className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
          <br/><br/>
          Report Loading...<br/>
          Please wait, your report will be loaded once it is ready.
        </center>
        <br/><br/>
        <center>
          <Button color="info" onClick={toggleReportLoading}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Year to Date Report
      </CardHeader>
      <CardBody>
        <ReportFilter {...props} configs={reportFiltersConfig}/>
        <div className="my-divider">&nbsp;</div>
        <Card>
          <CardHeader className="header-color cursor-pointer" onClick={()=>onClickHeader()}>
            <Row>
              <Col sm="8" className="align-left">
                <i className={(state.clickedHeader)?"fa fa-chevron-up":"fa fa-chevron-down"}></i> &nbsp;Entities
              </Col>
              <Col sm="4">
                <div className="align-right">
                  <Button disabled={state.selectedEntities.length<=0} onClick={getYTDReport} color="warning">View Selected Report(s)</Button>
                </div>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Collapse isOpen={state.clickedHeader}>
              <Row>
                <Col sm="8">
                  <i className="fa fa-plus"></i>&nbsp;<b>Add Entities</b>
                  <div className="large-scroll-container">
                    <MyReactTable columns={columns} data={state.searchResult} className="table table-striped"/>
                  </div>
                </Col>
                <Col sm='4'>
                  <i className="fa fa-users"></i>&nbsp;<b>Selected Entities</b> {entityList}
                </Col>
              </Row>
            </Collapse>
          </CardBody>
        </Card>
        <br/>
        {carousel}
        <br/>
        <br/>
        <div className="my-divider">&nbsp;</div>
        <div className="align-right">
          <Button disabled={state.report.length<=0} onClick={sendEmailReport} color="warning">Send Report(s)</Button>
        </div>
      </CardBody>
    </Card>
  </div>;
}


export default YearToDateReport;
