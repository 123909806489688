//Sooyoung Kim
//Oct 18, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, putAPICallGenerator, deleteAPICallGenerator, callBackGenerator, formatNumber, formatDate,  formatDateTime, generateSID, getFromTo} from '../../util/util';
import {Button,Modal, ModalHeader, ModalBody, Row, Col, Input, FormGroup, Label, Table, UncontrolledTooltip} from 'reactstrap';
import MyXEditable from '../util/my-xeditable';
import DatePicker from 'react-datepicker';
import {NavLink} from 'react-router-dom';
import MyReactTable from '../util/my-react-table';
import React, {useReducer, useEffect, useRef} from 'react';
import MySelect from '../util/my-select';
import ReactStars from 'react-stars';
import moment from 'moment';

//initialize the state
const initialState = {
  showBidDetailsPopup:false,
  lookUpAppraiser:false,
  appraiser:'',
  addnewBid:false,
  vettingBids:[],
  bidsToDelete:[],
  bids:[],

  newBidName:'',
  newBidEmail:'',
  newBidPhone:'',
  newBidMethod:'phone',
  newBidFee:'',
  newBidRecipientEmail:'',
  newBidRecipientFk:0,
  newBidTurnaround:getFromTo('end'),
  newBidRespond:getFromTo('end'),
  newBidNote:'',
};

//reducer function that perform state update
const reducer = getReducer();


const BidLog  = (props)=>{
  const controller = new AbortController();
  const newsid = useRef(generateSID);
  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    props.getBid(props.id);
    getVettingBids();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const toggleBidDetails = () => {
    setState({showBidDetailsPopup:!state.showBidDetailsPopup});
  }

  const toggleAddnewBid = () => {
    setState({addnewBid:!state.addnewBid});
  }

  const checkLookUpAppraiser = () => {
    setState({lookUpAppraiser:!state.lookUpAppraiser, appraiser:'', newBidRecipientFk:0, newBidName:'', newBidEmail:'', newBidRecipientEmail:'', newBidPhone:''});
  }

  const bidVetted = (ID) => {
    let vettingBids = state.vettingBids.slice();

    for(let i=0;i<vettingBids.length;i++){
      for(let j=0;j<vettingBids[i].recipients.length;j++){
        if(vettingBids[i].recipients[j].ID===ID&& vettingBids[i].recipients[j].status==='active')
          return true;
      }
    }
    return false;
  }

  const deleteBid = (ID) => {
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code!=='00'){
        props.getBid(props.id);

        if(state.showBidDetailsPopup){
          toggleBidDetails();
        }
      }
    };
    callBack = callBack.bind(this);

    httpDelete('appraisal/bid/'+ID,'Quote deleted.','Oops, something went wrong and could not delete the bid. Please try again later.', callBack);
  }

  const handleChange = (selectedOption) => {
    console.log(selectedOption);
    let newLabel;
    let newSelectedOption = {label:'', value:selectedOption};

    let options = state.options.slice();
    for(let i=0;i<options.length;i++){
      if(options[i].value===selectedOption){
        newLabel = options[i].label.split(" - ");
        newSelectedOption = {label:newLabel[0], value:selectedOption};

        setState({ appraiser:newSelectedOption,newBidName:newLabel[0],newBidRecipientEmail:newLabel[1],newBidPhone:newLabel[2]});
        break;
      }
    }

    setState({newBidRecipientFk:selectedOption});
  }

  const showBidDetails = (bid) => {
    let bidList = [];
    for(let i=0;i<bid.email_list.length;i++){
      bidList[i] = {ID: bid.email_list[i].ID,
                      status:bid.status_list[i].status,
                      fee:bid.fee_list[i].fee,
                      recipient_fk:bid.recipient_fk,
                      method:bid.method,
                      turnaround:bid.turnaround_list[i].turnaround,
                      datetime_respond:bid.datetime_respond_list[i].datetime_respond,
                      email:bid.email_list[i].email,
                      note:bid.note_list[i].note,
                      datetime_created:bid.datetime_created_list[i].datetime_created,
                    }
    }
    let additionalBid = { recipient_fk: bid.recipient_fk,
                            recipient_name: bid.recipient_name,
                            recipient_email: bid.recipient_email,
                            recipient_phone: bid.recipient_phone,
                            method: bid.method
                          };
                          console.log(bid);
                          console.log(bidList);
    setState({selectedBid:bid, bidsToDelete: bidList, additionalBid:additionalBid}, toggleBidDetails());
  }

  const addAdditionalBid = () => {
    if(state.additionalBid){
      let bidInfo = state.additionalBid;
      let userExist = false;
      if(bidInfo.recipient_fk > 0){
        userExist = true;
        setState({appraiser: {label:bidInfo.recipient_name,value:bidInfo.recipient_fk}});
      }
      setState({newBidName:bidInfo.recipient_name, newBidRecipientEmail:bidInfo.recipient_email, newBidPhone:bidInfo.recipient_phone, newBidMethod:bidInfo.method, newBidRecipientFk:bidInfo.recipient_fk, lookUpAppraiser:userExist},
        () => {
           toggleAddnewBid();
           toggleBidDetails();
        }
      );
    }
  }

  const updateBidCallBack = (ID, field, value) => {
    let fieldList = '';
    if(field === 'email'){
      fieldList = 'email_list';
    }else if(field === 'note'){
      fieldList = 'note_list';
    }else if(field === 'fee'){
      fieldList = 'fee_list';
    }else if(field === 'status'){
      fieldList = 'status_list';
    }else if(field === 'turnaround'){
      fieldList = 'turnaround_list';
    }else if(field === 'datetime_respond'){
      fieldList = 'datetime_respond_list';
    }else if(field === 'datetime_created'){
      fieldList = 'datetime_created_list';
    }

    let bids = props.bids.slice();
    let found = false;
    for(let i=0;i<bids.length;i++){
      if(fieldList !== ''){
        for(let j=0;j<bids[i][fieldList].length;j++){
          if(bids[i][fieldList][j].ID===ID){
            bids[i][fieldList][j][field] = value;
            found = true;
            break;
          }
        }
        if(found){
          break;
        }
      }else{
        if(bids[i].ID===ID){
          bids[i][field] = value;
          break;
        }
      }
    }

    let bidsToDelete = state.bidsToDelete.slice();

    for(let i=0;i<bidsToDelete.length;i++){
      if(bidsToDelete[i].ID===ID){
        bidsToDelete[i][field] = value;
        break;
      }
    }

    props.setState('bids', bids)
    setState({bidsToDelete:bidsToDelete});
  }


  //API call
  //generate a function that do the update on editable
  const generateUpdateFunction = (url, parameters, successMessage, failedMessage, parentCallBack=null) => {
    return (newValue, callBack, errorCallBack)=>{
      //add in the new value into parameters

      parameters.push({field:'value',value:newValue});
      let promise = httpPut(url,parameters,successMessage,failedMessage, callBack, errorCallBack);
      promise.then(
        (response)=>{
          if(parentCallBack!==null){
            let ID;
            let field;
            let value;

            for(let i=0;i<parameters.length;i++){
              if(parameters[i].field==='ID')
                ID = parameters[i].value;
              else if(parameters[i].field==='field')
                field = parameters[i].value;
              else if(parameters[i].field==='value')
                value = parameters[i].value;
            }
            parentCallBack(ID, field, value);
          }
        }
      );
    }
  };

  const getAppraiser = (keyword) => {
    if(keyword&&keyword!==''){
      let SID = generateSID;
      newsid.current = SID;

      let callBack = (response)=>{
        if(SID===newsid.current){
          let code = response.data?response.data.code:undefined;

          if(code!=='00'){
            return [{}];
          }
          else{
            let options = [];

            for(let i=0;i<response.data.data.length;i++){
              let tmp = {};
              tmp.label = response.data.data[i].first_name+' '+response.data.data[i].last_name+' - '+response.data.data[i].email+' - '+response.data.data[i].phone+' - '+formatDate(response.data.data[i].datetime_last_login);
              tmp.value = response.data.data[i].ID;

              options.push(tmp);
            }

            setState({options:options});
            return options;
          }
        }
      };
      callBack = callBack.bind(this);

      let promise = httpGet('appraiser/get/limit=100&name='+keyword, '', 'Oops, something went wrong and could not search for appraiser. Please try again later.', callBack);

      return promise;
    }
  }

  // get quotes that are sent out to client and in process of vetting
  const getVettingBids = () => {
    let callBack = apiCallBack([{state:'vettingBids', key:'data'}]);
    httpGet('appraisal/quote/'+state.id, '', 'Oops, something went wrong and could not load vetting quotes.', callBack);
  }


  //render
  const columns = [
    {
      id: 'select',
      Header: 'Appraiser',
      accessor: d => d.status_list[d.status_list.length-1].status,
      Cell: cell => {
        let autoBid;
        if(cell.row.original.auto_bid==='yes')
          autoBid = <i className="fa fa-paper-plane"/>

        let bidDetails = <i className="fa fa-cogs cursor-pointer link-color" onClick={(e)=>showBidDetails(cell.row.original)}/>;


        let icon;
        let iconMethod;

        if(cell.row.original.method==='phone')
          iconMethod = <i className="fa fa-phone" style={{padding:'5px', paddingLeft:'0px',  verticalAlign:'top'}}/>;
        else if(cell.row.original.method==='email')
          iconMethod = <i className="fa fa-address-book" style={{padding:'5px', paddingLeft:'0px',  verticalAlign:'top'}}/>;
        else{
          iconMethod = <i className="fa fa-envelope-open" style={{padding:'5px', paddingLeft:'0px', verticalAlign:'top'}}/>;
        }

        let lastest = cell.row.original.status_list[cell.row.original.status_list.length-1];

        if(lastest.status==='pending'){
          icon = <i className="fa fa-circle gray-color" style={{padding:'5px', verticalAlign:'top'}}>&nbsp;</i>
        }
        else if(lastest.status==='completed'){
          icon = <i className="fa fa-circle green-color" style={{padding:'5px', verticalAlign:'top'}}>&nbsp;</i>
        }
        else if(lastest.status==='scan-failed'){
          icon = <i className="fa fa-circle orange-color" style={{padding:'5px', verticalAlign:'top'}}>&nbsp;</i>
        }
        else if(lastest.status==='reject'||lastest.status==='no-reply'){
          icon = <i className="fa fa-circle red-color" style={{padding:'5px', verticalAlign:'top'}}>&nbsp;</i>
        }

        let phoneAction;
        let cellAction;

        let appraiserName = <b>{cell.row.original.appraiser}</b>;
        let appraiserMemo = '';
        if(cell.row.original.recipient_fk !== '0'){
          appraiserName = <NavLink target="_blank" id={"memo"+cell.row.original.ID} to={"/appraiser/"+cell.row.original.recipient_fk}>{cell.row.original.appraiser}</NavLink>

          if(cell.row.original.memo !== ''){
            appraiserMemo = <UncontrolledTooltip className="mytooltip" delay={{ "show": 0, "hide": 0 }} placement="right" target={"memo"+cell.row.original.ID}>
                {cell.row.original.memo.map(
                  (memo, index)=>{
                    return <div key={index}>{memo.memo}<br/></div>
                  }
                )}
              </UncontrolledTooltip>;
          }
        }

        if(cell.row.original.appraiser_phone&&cell.row.original.appraiser_phone!=='')
          phoneAction = <div className="display-inline" >
            <div style={{fontSize:'9px'}}>
              &nbsp;<a onClick={(e)=>props.recordBid('phone',cell.row.original.recipient_name, '','','','', cell.row.original.recipient_fk,'',cell.row.original.appraiser_phone)} href={"tel:"+cell.row.original.appraiser_phone} id={"phoneBid"+cell.row.original.recipient_fk}><i className="fa fa-phone"/><i>Bid</i></a>
            </div>
          </div>;

        if(cell.row.original.appraiser_secondary_phone&&cell.row.original.appraiser_secondary_phone!=='')
          cellAction = <div className="display-inline">
            <div style={{fontSize:'9px'}}>
              &nbsp;<a onClick={(e)=>props.recordBid('phone',cell.row.original.recipient_name, '','','','', cell.row.original.recipient_fk,'',cell.row.original.appraiser_secondary_phone)} href={"tel:"+cell.row.original.appraiser_secondary_phone} id={"secondaryPhoneBid"+cell.row.original.recipient_fk}><i className="fa fa-phone"/><i>Bid</i></a>
            </div>
          </div>;

        let serviceScore= <div className="display-inline" style={{verticalAlign:'top', paddingTop:'4px'}}>-</div>;

        if(cell.row.original.avg_service_rating&&parseFloat(cell.row.original.avg_service_rating)>=0){
          serviceScore = <div style={{paddingTop:'5px'}}>
            <div id={"service"+cell.row.original.ID}>
              <font color="red">{cell.row.original.avg_service_rating+"/5.00"}</font>
            </div>
            <UncontrolledTooltip delay={{ "show": 0, "hide": 0 }} placement="left" target={"service"+cell.row.original.ID}>
              {cell.row.original.avg_service_rating&&cell.row.original.avg_service_rating!==-1?cell.row.original.avg_service_rating+'/5.00':'-'}
            </UncontrolledTooltip>
          </div>;
        }

        let qualityScore = <div className="display-inline" style={{verticalAlign:'top', paddingTop:'4px'}}>-</div>;


        if(cell.row.original.avg_quality_rating&&parseFloat(cell.row.original.avg_quality_rating)>=0){
          qualityScore = <div>
            <div id={"quality"+cell.row.original.ID}>
              <ReactStars
                onChange={()=>{}}
                value={parseInt(cell.row.original.avg_quality_rating,10)}
                count={3}
                edit={false}
                half={true}
                size={20}
              />
            </div>
            <UncontrolledTooltip delay={{ "show": 0, "hide": 0 }} placement="left" target={"quality"+cell.row.original.ID}>
              {cell.row.original.avg_quality_rating}/3.00
            </UncontrolledTooltip>
          </div>;
        }

        let names = cell.row.original.appraiser.split(' ');
        let firstName='';
        let lastName = '';

        if(names.length>1){
          firstName = names[0];
          lastName = names.splice(1, names.length).join(' ');
        }
        else{
          firstName = names.join(' ');
        }

        let checkbox = <FormGroup check>
          <Label check>
            <Input checked={props.isCheck(cell.row.original.recipient_fk)} onChange={(e) => props.clickRowListener(cell.row.original.recipient_fk,  {fees:cell.row.original.fees, warnings:cell.row.original.warnings, second_level_warnings: cell.row.original.second_level_warnings, distance: cell.row.original.distance, first_name: firstName, last_name:lastName, email:cell.row.original.email})} type="checkbox" disabled={cell.row.original.method==='phone' || cell.row.original.method==='email'}/>
          </Label>
        </FormGroup>;

        if(cell.row.original.method==='email'||cell.row.original.method==='phone')
          checkbox = null;


        return (
          <div>
            <div>
              <div className="display-inline" style={{verticalAlign:'top'}}>
                {checkbox}
              </div>
              {icon}{iconMethod}<div className="display-inline" style={{height:'40px'}}>
                <MyXEditable
                  width="100px"
                  type="select"
                  opt={[{key:'pending', value:'pending'}, {key:'scan-failed', value:'scan-failed'}, {key:'completed', value:'completed'}, {key:'no-reply', value:'no-reply'},{key:'reject', value:'reject'}]}
                  value={lastest.status}
                  updateFunc={
                    generateUpdateFunction('appraisal/bid/update',[{field:'field',value:'status'},{field:'ID',value:lastest.ID}],'Bid updated.', 'Update bid failed, please try again later.', updateBidCallBack)
                  }
                />
              </div>
            </div>

            <br/>
            <div style={{marginTop:'10px'}}>{bidDetails} {autoBid} {appraiserName}{appraiserMemo}</div>
            <div className="display-inline" style={{verticalAlign:'top', paddingTop:'4px'}}>
              <label>QR</label>
            </div>
            <div className="display-inline" style={{verticalAlign:'top', marginRight:'5px'}}>
              {qualityScore}
            </div>
            <div className="display-inline" style={{verticalAlign:'top', paddingTop:'4px'}}>
              <label>SR</label>
            </div>&nbsp;
            <div className="display-inline" style={{verticalAlign:'top'}}>
              {serviceScore}
            </div>
            <div style={{marginTop:'10px'}}>
              Email: <a href={"mailto:"+cell.row.original.recipient_email}>{cell.row.original.recipient_email}</a><br/>
              {(cell.row.original.recipient_notify_email&&cell.row.original.recipient_notify_email!=='')&&
                <div>
                  Notify Email: <a href={"mailto:"+cell.row.original.recipient_notify_email}>{cell.row.original.recipient_notify_email}</a><br/>
                </div>
              }
            </div>
            <div>
              <div className="display-inline">
                Office: <a href={"tel:"+cell.row.original.appraiser_phone}>{cell.row.original.appraiser_phone}</a>
              </div>
              {phoneAction}
            </div>
            <div>
              <div className="display-inline">
                Cell: <a href={"tel:"+cell.row.original.appraiser_secondary_phone}>{cell.row.original.appraiser_secondary_phone}</a>
              </div>
              {cellAction}
            </div>
            <br/>
          </div>
        );
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'distance',
      Header: 'Distance',
      maxWidth:80,
      accessor: d => d.distance,
      Cell: props => {
        return <div>{props.row.original.distance!==-1?formatNumber(props.row.original.distance)+ ' miles':'unknown'}</div>
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'fee',
      Header: 'Fee',
      overflow:'visible',
      maxWidth:80,
      accessor: d => parseInt(d.fee_list[0].fee, 10),
      Cell: props =>{
        let fees = props.row.original.fee_list.map(
            (fee, index)=>{
              let crossOutFee;
              let flash = bidVetted(fee.ID);

              if(index === props.row.original.email_list.length -1){
                crossOutFee = <tr className={flash?'flashit red-color':''} key={index}><td><MyXEditable
                          type="text"
                          prefix="$"
                          value={fee.fee}
                          updateFunc={
                            generateUpdateFunction('appraisal/bid/update',[{field:'field',value:'fee'},{field:'ID',value:fee.ID}],'Bid updated.', 'Update bid failed, please try again later.', updateBidCallBack)
                          }
                        /></td></tr>
              }else{
                crossOutFee = <tr className={flash?'flashit red-color':''} key={index}><td height="50px"><div className="xeditable-container">
                                    <s>${fee.fee}</s>
                                  </div></td></tr>;
              }
              return crossOutFee
            }
        );
        return (
          <Table>
            <tbody>
              {fees}
            </tbody>
          </Table>
        );
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'turnaround',
      Header: 'Turnaround',
      overflow:'visible',
      maxWidth:220,
      minWidth:220,
      accessor: d => d.turnaround_list[0].turnaround,
      Cell: props =>{
        let turnarounds = props.row.original.turnaround_list.map(
          (turnaround, index)=>{

            let value = turnaround.turnaround;
            let formatDate = 'No Date'
            let crossOutTurnaround;

            if(value&&value!=='0000-00-00'&&value!==''&&value!=='empty'){
              formatDate = moment(value).format("dd MMM, yyyy");
            }

            if(index===props.row.original.turnaround_list.length-1){
              crossOutTurnaround = <tr key={index}><td height="50px">
                <div style={{position:'relative'}}>
                  <div style={{overflow:'hidden'}}>
                    <div style={{position:'absolute'}}>
                      <MyXEditable
                          type="date"
                          value={value}
                          updateFunc={
                            generateUpdateFunction('appraisal/bid/update',[{field:'field',value:'turnaround'},{field:'ID',value:turnaround.ID}],'Bid updated.', 'Update bid failed, please try again later.', updateBidCallBack)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </td>
              </tr>;
            }
            else{
              crossOutTurnaround = <tr key={index}><td height="50px"><div className="xeditable-container">
                    <s>{formatDate}</s>
                  </div></td></tr>;
            }

            return crossOutTurnaround;
          }
        )
        return (
          <Table>
            <tbody>
              {turnarounds}
            </tbody>
          </Table>
        );
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'datetimeRespond',
      maxWidth:220,
      minWidth:220,
      Header: 'Date Respond',
      accessor: d => d.datetime_respond_list[0].datetime_respond,
      Cell: props =>{
      let respondDates = props.row.original.datetime_respond_list.map(
        (respond, index)=>{

          let value = respond.datetime_respond;
          let formatDateTime = 'No Date'
          if(value&&value!=='0000-00-00 00:00:00'&&value!==''&&value!=='empty'){
            formatDateTime = moment(value).format("dd MMM, yyyy hh:mm A");
          }

          let crossOutDateRepond ;
          if(index===props.row.original.datetime_respond_list.length-1){
            crossOutDateRepond = <tr key={index}><td height="50px">
              <div style={{position:'relative'}}>
                <div style={{overflow:'hidden'}}>
                  <div style={{position:'absolute'}}>
                    <MyXEditable
                      type="datetime"
                      value={value}
                      updateFunc={
                        generateUpdateFunction('appraisal/bid/update',[{field:'field',value:'datetime_respond'},{field:'ID',value:respond.ID}],'Bid updated.', 'Update bid failed, please try again later.', updateBidCallBack)
                      }
                    />
                  </div>
                </div>
              </div>
            </td></tr>;
          }
          else{
            crossOutDateRepond = <tr key={index}><td height="50px"><div className="xeditable-container">
                  <s>{formatDateTime}</s>
                </div></td></tr>;
          }

          return crossOutDateRepond
        }
      )

        return (
          <Table>
            <tbody>
              {respondDates}
            </tbody>
          </Table>
        );
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'email',
      Header: 'Message',
      minWidth:255,
      accessor: d => d.email_list[0].email,
      Cell: props =>{

        let emails = props.row.original.email_list.map(
          (email, index)=>{

            let flash = bidVetted(email.ID);

            if(index===props.row.original.email_list.length-1)
              return <tr key={index} className={flash?"flashit ":""} >
                <td height="50px">
                  <MyXEditable
                      type="textarea"
                      prefix="• "
                      value={email.email}
                      updateFunc={
                        generateUpdateFunction('appraisal/bid/update',[{field:'field',value:'email'},{field:'ID',value:email.ID}],'Bid updated.', 'Update bid failed, please try again later.', updateBidCallBack)
                      }
                    />
                </td>
              </tr>;
            else
              return <tr className={flash?"flashit ":""} >
                <td height="50px">
                  <s className="cursor-pointer" id={"bidEmail"+email.ID}>{email.email.substr(0, 30)}</s>...
                  <UncontrolledTooltip className="long-tooltip" autohide={false} delay={{ "show": 0, "hide": 0 }} placement="right" target={"bidEmail"+email.ID}>
                    {email.email}
                  </UncontrolledTooltip>
                  <br/>
                </td>
              </tr>
          }
        );

        return (
          <div style={{whiteSpace:'pre-wrap',wordBreak:'break-all',overflowWrap:'break-word',Wrap:'break-word'}}>
            <Table>
              <tbody>
                {emails}
              </tbody>
            </Table>
          </div>
        );
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'note',
      Header: 'Note',
      minWidth:255,
      accessor: d => d.note_list[0].note,
      Cell: props =>{
        let notes = props.row.original.note_list.map(
          (note, index)=>{
            let flash = bidVetted(note.ID);

            if(index===props.row.original.note_list.length-1)
              return <tr key={index} className={flash?"flashit ":""} >
                <td height="50px">
                  <MyXEditable
                      type="textarea"
                      prefix="• "
                      value={note.note}
                      updateFunc={
                        generateUpdateFunction('appraisal/bid/update',[{field:'field',value:'note'},{field:'ID',value:note.ID}],'Bid updated.', 'Update bid failed, please try again later.', updateBidCallBack)
                      }
                    />
                </td>
              </tr>;
            else
              return <tr key={index} className={flash?"flashit ":""} >
                <td height="50px">
                  <s className="cursor-pointer" id={"bidNote"+note.ID}>{note.note.substr(0, 30)}</s>...
                  <UncontrolledTooltip className="long-tooltip" autohide={false} delay={{ "show": 0, "hide": 0 }} placement="right" target={"bidNote"+note.ID}>
                    {note.note}
                  </UncontrolledTooltip>
                </td>
              </tr>
          }
        );

        return (
          <div style={{whiteSpace:'pre-wrap',wordBreak:'break-all',overflowWrap:'break-word',Wrap:'break-word'}}>
            <Table>
              <tbody>
                {notes}
              </tbody>
            </Table>
          </div>
        );
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'bidRequested',
      maxWidth:200,
      minWidth:200,
      Header: 'Bid Requested',
      accessor: d => d.datetime_created_list[0].datetime_created,
      Cell: props =>{
        let requestedDates = props.row.original.datetime_created_list.map(
          (created, index)=>{
            let flash = bidVetted(created.ID);
            return <tr  className={flash?"flashit ":""} key={index}><td height="50px">{formatDateTime(created.datetime_created)}</td></tr>
          }
        );
        return (
          <Table>
            <tbody>
              {requestedDates}
            </tbody>
          </Table>
        );
      },
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  const columns6 = [
    {
      id:'action',
      Header:'',
      maxWidth:25,
      accessor: d => '',
      Cell: props =>{
        return <div>
          <i className="fa fa-minus red-color cursor-pointer" onClick={(e)=>deleteBid(props.row.original.ID)}/>

        </div>
      }
    },
    {
      id: 'status',
      Header: 'Status',
      maxWidth:85,
      accessor: d => d.status,
      Cell: props=>{
        let icon;
        let iconMethod;

        if(props.row.original.method==='phone')
          iconMethod = <i className="fa fa-phone"/>;
        else if(props.row.original.method==='email')
          iconMethod = <i className="fa fa-address-book"/>;
        else
          iconMethod = <i className="fa fa-envelope-open"/>;

        let status = props.row.original.status;

        if(status==='pending'){
          icon = <i className="fa fa-circle gray-color">&nbsp;</i>
        }
        else if(status==='completed'){
          icon = <i className="fa fa-circle green-color">&nbsp;</i>
        }
        else if(status==='scan-failed'){
          icon = <i className="fa fa-circle orange-color">&nbsp;</i>
        }
        else if(status==='reject'||status==='no-reply'){
          icon = <i className="fa fa-circle red-color">&nbsp;</i>
        }

        return <div>
          {iconMethod} {icon}
          <MyXEditable
            type="select"
            opt={[{key:'pending', value:'pending'}, {key:'scan-failed', value:'scan-failed'}, {key:'completed', value:'completed'}, {key:'no-reply', value:'no-reply'},{key:'reject', value:'reject'}]}
            value={props.row.original.status}
            updateFunc={
              generateUpdateFunction('appraisal/bid/update',[{field:'field',value:'status'},{field:'ID',value:props.row.original.ID}],'Bid updated.', 'Update bid failed, please try again later.', updateBidCallBack)
            }
          />
        </div>;
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'fee',
      Header: 'Fee',
      overflow:'visible',
      maxWidth:60,
      accessor: d => parseInt(d.fee, 10),
      Cell: props =>{
        let fees = <MyXEditable
                      type="text"
                      prefix="$"
                      value={props.row.original.fee}
                      updateFunc={
                        generateUpdateFunction('appraisal/bid/update',[{field:'field',value:'fee'},{field:'ID',value:props.row.original.ID}],'Bid updated.', 'Update bid failed, please try again later.', updateBidCallBack)
                      }
                    />;
        return (
          <div>
            <div className="display-inline">
              {fees}
            </div>
          </div>
        );
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'turnaround',
      Header: 'Turnaround',
      overflow:'visible',
      maxWidth:105,
      accessor: d => d.turnaround,
      Cell: props =>{
        let turnarounds = <MyXEditable
                      type="date"
                      value={props.row.original.turnaround}
                      updateFunc={
                        generateUpdateFunction('appraisal/bid/update',[{field:'field',value:'turnaround'},{field:'ID',value:props.row.original.ID}],'Bid updated.', 'Update bid failed, please try again later.', updateBidCallBack)
                      }
                    />;
        return (
          <div>
            <div className="display-inline" style={{width:'80px', position:'absolute'}}>
              {turnarounds}
            </div>
          </div>
        );
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'datetimeRespond',
      Header: 'Date Respond',
      accessor: d => d.datetime_respond,
      Cell: props =>{
        let respondDates = <MyXEditable
                      type="datetime"
                      value={props.row.original.datetime_respond}
                      updateFunc={
                        generateUpdateFunction('appraisal/bid/update',[{field:'field',value:'datetime_respond'},{field:'ID',value:props.row.original.ID}],'Bid updated.', 'Update bid failed, please try again later.', updateBidCallBack)
                      }
                    />;
        return (
          <div>
            <div className="display-inline" style={{width:'80px', position:'absolute'}}>
              {respondDates}
            </div>
          </div>
        );
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'email',
      Header: 'Email',
      accessor: d => d.email,
      Cell: props =>{
        let emails = <MyXEditable
                        type="textarea"
                        prefix="• "
                        value={props.row.original.email}
                        updateFunc={
                          generateUpdateFunction('appraisal/bid/update',[{field:'field',value:'email'},{field:'ID',value:props.row.original.ID}],'Bid updated.', 'Update bid failed, please try again later.', updateBidCallBack)
                        }
                      />;
        return (
          <div style={{whiteSpace:'pre-wrap',wordBreak:'break-all',overflowWrap:'break-word',Wrap:'break-word'}}>
            {emails}
          </div>
        );
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'note',
      Header: 'Note',
      accessor: d => d.note,
      Cell: props =>{
        let notes = <MyXEditable
                        type="textarea"
                        prefix="• "
                        value={props.row.original.note}
                        updateFunc={
                          generateUpdateFunction('appraisal/bid/update',[{field:'field',value:'note'},{field:'ID',value:props.row.original.ID}],'Bid updated.', 'Update bid failed, please try again later.', updateBidCallBack)
                        }
                      />;
        return (
          <div style={{whiteSpace:'pre-wrap',wordBreak:'break-all',overflowWrap:'break-word',Wrap:'break-word'}}>
            {notes}
          </div>
        );
      },
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'bidRequested',
      Header: 'Bid Requested',
      accessor: d => d.datetime_created,
      Cell: props =>{
        let requestedDates = formatDateTime(props.row.original.datetime_created);

        return (
          <div>{requestedDates}</div>
        );
      },
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  let bidsToDelete;

  if(state.bidsToDelete&&state.bidsToDelete.length>0){
    bidsToDelete = <MyReactTable columns={columns6} data={state.bidsToDelete} className="table table-striped"/>;
  }

  let newBidTurnaround;

  if(state.newBidTurnaround!=='')
    newBidTurnaround = moment(state.newBidTurnaround).toDate();

  let newBidRespond;

  if(state.newBidRespond!=='')
    newBidRespond = moment(state.newBidRespond).toDate();


  return <div>
    <Modal className="my-modal-wide" isOpen={state.showBidDetailsPopup} toggle={toggleBidDetails} >
      <ModalHeader hidden={true} toggle={toggleBidDetails}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-cogs"></i> Bid Details</h5>
        </center>
        <br/>
        {bidsToDelete}
        <br/>
        <center>
          <Button color="warning" onClick={addAdditionalBid}>Record A Bid Manually</Button> <Button color="info" onClick={toggleBidDetails}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
    <Modal className="my-modal" isOpen={state.addnewBid} toggle={toggleAddnewBid} >
      <ModalHeader hidden={true} toggle={toggleAddnewBid}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-warning"></i> Add New Bid</h5>
        </center>
        <Row>
          <Col className="align-right">
            <FormGroup check>
              <Label check>
                <Input type="checkbox" checked={state.lookUpAppraiser} onChange={()=>checkLookUpAppraiser()}/>Appraiser in System
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <br/>
        {!state.lookUpAppraiser && <div><i className="red-color">
          **Please only use this to record any bid that you reach out to appraiser outside of system.**
        </i>
        <br/><br/>
        <Row>
          <Col sm="4">
            <label>Name</label>
          </Col>
          <Col sm="8">
            <Input type="text" value={state.newBidName} onChange={(e)=>setState({newBidName:e.target.value})}/>
          </Col>
        </Row></div>}
        {state.lookUpAppraiser && <Row>
          <Col sm="4">
            <b>Name</b>
          </Col>
          <Col sm="8">
            <MySelect
              modal={true}
              type="async-select"
              value={state.appraiser}
              onChange={(v)=>handleChange(v)}
              defaultOptions
              loadOptions={getAppraiser}
            />
          </Col>
        </Row>}
        <br/>
        <Row>
          <Col sm="4">
            <label>Email</label>
          </Col>
          <Col sm="8">
            <Input type="text" value={state.newBidRecipientEmail} onChange={(e)=>setState({newBidRecipientEmail:e.target.value})}/>
          </Col>
        </Row>
        <br/>
        <Row>
          <Col sm="4">
            <label>Phone</label>
          </Col>
          <Col sm="8">
            <Input type="text" value={state.newBidPhone} onChange={(e)=>setState({newBidPhone:e.target.value})}/>
          </Col>
        </Row>
        <br/>
        <Row>
          <Col sm="4">
            <label>Method</label>
          </Col>
          <Col sm="8">
            <MySelect
              modal={true}
              type="select"
              value={state.newBidMethod}
              onChange={(v)=>setState({newBidMethod:v})}
              options={[{label:"Phone Call",value:"phone"},{label:"Email",value:"email"}]}
            />
          </Col>
        </Row>
        <br/>


        <Row>
          <Col sm="4">
            <label>Turnaround</label>
          </Col>
          <Col sm="8">
            <DatePicker
              className="form-control"
              dateFormat="yyyy/MM/dd"
              selected={newBidTurnaround}
              onChange={(text)=>{(text)&&setState({newBidTurnaround:text.toLocaleDateString('en-CA')})}}
            />
          </Col>
        </Row>
        <br/>
        <Row>
          <Col sm="4">
            <label>Date Responded</label>
          </Col>
          <Col sm="8">
            <DatePicker
              className="form-control"
              dateFormat="yyyy/MM/dd"
              selected={newBidRespond}
              onChange={(text)=>{(text)&&setState({newBidRespond:text.toLocaleDateString('en-CA')})}}
            />
          </Col>
        </Row>
        <br/>
        <Row>
          <Col sm="4">
            <label>Fee</label>
          </Col>
          <Col sm="8">
            <Input type="text" value={state.newBidFee} onChange={(e)=>setState({newBidFee:e.target.value})}/>
          </Col>
        </Row>
        <br/>
        <Row>
          <Col sm="4">
            <label>Message</label>
          </Col>
          <Col sm="8">
            <Input type="text" value={state.newBidEmail} onChange={(e)=>setState({newBidEmail:e.target.value})}/>
          </Col>
        </Row>
        <br/>
        <Row>
          <Col sm="4">
            <label>Note</label>
          </Col>
          <Col sm="8">
            <Input type="text" value={state.newBidNote} onChange={(e)=>setState({newBidNote:e.target.value})}/>
          </Col>
        </Row>

        <br/><br/>
        <br/><br/>
        <br/><br/>
        <center>
          <Button color="warning" onClick={(e)=>props.recordBid(state.newBidMethod, state.newBidName, state.newBidEmail, state.newBidTurnaround, state.newBidFee, state.newBidRecipientEmail, state.newBidRecipientFk, state.newBidRespond,state.newBidPhone, state.newBidNote)}>Submit</Button> <Button color="info" onClick={toggleAddnewBid}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
    <Row>
      <Col>
        <div className="align-right">
          <NavLink to="#" onClick={toggleAddnewBid}><i className="fa fa-plus green-color"/> Add New Bid</NavLink>
          &nbsp;
        </div>
      </Col>
    </Row>
    <label>SR</label> Service Rating&nbsp;<label>QR</label> Quality Rating&nbsp;
    <i className="fa fa-envelope-open"/> System Bid&nbsp;
    <i className="fa fa-address-book"/> Email Bid&nbsp;
    <i className="fa fa-phone"/> Phone Call Bid&nbsp;
    <i className="fa fa-paper-plane"/> Auto Bid&nbsp;
    <i className="red-color">*flashing*</i> Bid vetted to client
    <div className="larger-scroll-container-no-min">
      <MyReactTable columns={columns} data={props.bids} className="" container={{height:'600px', overflow: 'auto'}}/>
    </div>
  </div>;
}


export default BidLog;
