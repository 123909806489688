//Author Sooyoung Kim
//Date Aug 22, 2023
import React, {useEffect} from 'react';
import {Col, Row, Input} from 'reactstrap';
import MySelect from '../util/my-select';

const Heating  = (props)=>{
  const controller = new AbortController();

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const updateState = (field, value) => {
    let heatings = props.heatings.slice();
    let target = heatings[props.index];

    target[field] = value;

    heatings[props.index] = target;

    props.updateState({heatings:heatings});
  }

  //render
  let disabledStyling = {border:'none', background:'#fff', padding:'0',paddingLeft:'2px', height:'auto',WebkitAppearance:'none'};
  let heatingOtherDescription;

  if(props.heatings[props.index].heatingType==='Other')
    heatingOtherDescription = <Col sm="3">
      <label><font color="red">*</font>Heating Other Description</label>
      <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={true} type="text" value={props.heatings[props.index].heatingOtherDescription} onChange={(e)=>{updateState('heatingOtherDescription',e.target.value)}}/>
    </Col>;

  let heatingUpdateDescription;

  if(props.heatings[props.index].heatingUpdates==='Partially Updated' || props.heatings[props.index].heatingUpdates==='Fully Updated')
    heatingUpdateDescription = <Col sm="3">
      <label><font color="red">*</font>{props.heatings[props.index].heatingType} Update Description</label>
      <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={true} type="text" value={props.heatings[props.index].heatingUpdateDescription} onChange={(e)=>{updateState('heatingUpdateDescription',e.target.value)}}/>
    </Col>;

  return <div>
    <Row style={{marginTop:'15px'}}>
      {heatingOtherDescription}
      <Col sm="3">
        <label><font color="red">*</font>{props.heatings[props.index].heatingType} Updates</label>
        <MySelect
          type="select"
          style={props.disabled?disabledStyling:{}}
          disabled={props.disabled}
          required={true}
          value={props.heatings[props.index].heatingUpdates}
          onChange={(v)=>updateState('heatingUpdates',v)}
          options={[
            {label:"",value:""},
            {label:"Fully Updated",value:"Fully Updated"},
            {label:"Partially Updated",value:"Partially Updated"},
            {label:"No Update",value:"No Update"},
          ]}
        />
      </Col>
      {heatingUpdateDescription}
      <Col sm="3">
        <label><font color="red">*</font>{props.heatings[props.index].heatingType} Damage Defect</label>
        <MySelect
          type="select"
          style={props.disabled?disabledStyling:{}}
          disabled={props.disabled}
          required={true}
          value={props.heatings[props.index].heatingDamagesDefect}
          onChange={(v)=>updateState('heatingDamagesDefect',v)}
          options={[
            {label:"",value:""},
            {label:"Yes",value:"True"},
            {label:"No",value:"False"}
          ]}
        />
      </Col>
    </Row>
  </div>;
}


export default Heating;
