//Author Sooyoung Kim
//Date April 19, 2023
import {getReducer, getSetStateFunction, postAPICallGenerator, showMessage} from '../../util/util';
import { Button, Input, Row, Col} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import ReactQuill from 'react-quill';


//initialize the state
const initialState = {
  body: '',
  title: '',
};

//reducer function that perform state update
const reducer = getReducer();


const NewProcedure  = (props)=>{
  const controller = new AbortController();
  const navigate = useNavigate();

  let newInitialState = Object.assign({}, initialState, {creator:props.userName});
  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const checkTitle = ()=>{
    if(state.title.trim()===''){
      showMessage('warning', 'Please fill out the title.');
    }else{
      createProcedure();
    }
  }

  //API call
  //create new procedure
  const createProcedure = ()=>{
    let url = 'procedure/create';
    let successMsg = 'Procedure created successfully.';
    let errorMsg = 'Oops, something went wrong and could not create this procedure. Please try again later.';

    let parameters = [
      {
        field:'body',
        value:state.body
      },
      {
        field:'creator',
        value:state.creator
      },
      {
        field:'title',
        value:state.title
      },
    ];

    //call back for creating new procedure
    let callBack = (response) => {
      let code = response.data?response.data.code:undefined;
      if(code!=='00'){

      }
      else{
        navigate('/procedure');
      }
    };
    callBack = callBack.bind(this);

    httpPost(url,parameters,successMsg,errorMsg, callBack);
  }

  //render
  return <div>
    <div className="card padding">
      <Row>
        <Col>
          <div className="page-title">
            <i className="fa fa-reorder"></i>&nbsp;New Procedure
          </div>
					<NavLink to="/procedure">Back to procedure list</NavLink>
        </Col>
        <Col className="align-right">
          <Button color="warning" onClick={()=>{checkTitle()}}><i className="fa fa-plus"></i>&nbsp;&nbsp;Create</Button>
        </Col>
      </Row>
      <div className="hr"/>

      <br/>
      <div className="form-group">
				<label>By: </label>
				<Input value={state.creator} onChange={(text)=>{setState({creator:text.target.value})}}/>
			</div>
      <br/>
      <div className="form-group">
				<label>Title: </label>
				<Input value={state.title} onChange={(text)=>setState({title:text.target.value})}/>
			</div>
      <br/>
      <ReactQuill
        modules={
          {
            toolbar: [
                [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                [{size: []}],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{'list': 'ordered'}, {'list': 'bullet'},
                 {'indent': '-1'}, {'indent': '+1'}],
                ['link', 'image', 'video'],
                ['clean']
              ],
              clipboard: {
                // toggle to add extra line breaks when pasting HTML:
                matchVisual: false,
              },
              imageResize: {
              // parchment: Quill.import('parchment'),
                modules: ['Resize', 'DisplaySize']
              }
          }
        }
        formats={
          [
            'header', 'font', 'size',
            'bold', 'italic', 'underline', 'strike', 'blockquote',
            'list', 'bullet', 'indent',
            'link', 'image', 'video'
          ]
        }
        value={state.body}
        onChange={(value) => setState({body:value})}
      />
    </div>
  </div>;
}

export default NewProcedure;
