//Author Sooyoung Kim
//Date April 28, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, deleteAPICallGenerator, callBackGenerator, confirmation} from '../../util/util';
import { Button, Card, CardHeader, CardBody,Table, Input, Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import MyXEditable from '../util/my-xeditable';
import {sliceFromArray} from '../../util/util';
import {NavLink, useParams, useNavigate} from 'react-router-dom';


//initialize the state
const initialState = {
  appraiserFirm:{},
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
  addAppraiserDropDownOpen:false,
  searchAppraiserResults:[],
  search:'',
  master_users:[]
};

//reducer function that perform state update
const reducer = getReducer();


const AppraiserFirm  = (props)=>{
  const controller = new AbortController();
  const { id } = useParams();
  const navigate = useNavigate();

  let newInitialState = Object.assign({}, initialState, {id:id});
  const [state, dispatch] = useReducer(reducer, newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getAppraiserFirm();
    getMasterUsers();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const updateFieldCallBack = (field, value) =>{
    console.log(field, value);
    let appraiserFirm = Object.assign({}, state.appraiserFirm);
    appraiserFirm[field] = value;
    setState({appraiserFirm:appraiserFirm});
  }

  const addAppraiserToggle = () =>{
    setState({addAppraiserDropDownOpen:!state.addAppraiserDropDownOpen});
  }

  //generate a function that do the update on editable
  const generateUpdateFunction = (url, parameters, successMessage, failedMessage, parentCallBack=null) =>{
    return (newValue, callBack, errorCallBack)=>{
      //add in the new value into parameters

      parameters.push({field:'value',value:newValue});
      let promise = httpPut(url,parameters,successMessage,failedMessage, callBack, errorCallBack);
      promise.then(
        (response)=>{
          let field;
          let value;

          for(let i=0;i<parameters.length;i++){
            if(parameters[i].field==='field')
              field = parameters[i].value;
            else if(parameters[i].field==='value')
              value = parameters[i].value;
          }
          if(parentCallBack!==null){
            parentCallBack(field, value);
          }
        }
      );
    }
  };

  //API call
  //get appraiser firm
  const getAppraiserFirm = () =>{
    let url = 'appraiser/firm/'+state.id;
    let callBack = apiCallBack([{state:'appraiserFirm',key:'data'}]);
    httpGet(url, '','Oops, something went wrong and could not load this appraiser firm. Please try again later.', callBack);
  }

  //search appraiser
  const searchAppraiser = () =>{
    let url = 'appraiser/search/keyword='+state.search;
    let callBack = apiCallBack([{state:'searchAppraiserResults',key:'data'}]);
    httpGet(url, '','Oops, something went wrong and could not locate the appraiser. Please try again later.', callBack);
  }

  //get master users for this appraiser firm
  const getMasterUsers = () =>{
    let url = 'appraiser/firm/master/get/'+state.id;
    let callBack = apiCallBack([{state:'master_users',key:'data'}]);
    httpGet(url, '','Oops, something went wrong and could not locate the master user for this firm. Please try again later.', callBack);
  }

  //add new appraiser
  const addAppraiser = (id) =>{
    let url = 'appraiser/firm/user/create';
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let newAppraiser = response.data.data;
        let currentAppraiserFirm = Object.assign({},state.appraiserFirm);

        currentAppraiserFirm.appraisers.push(newAppraiser);

        setState({appraiserFirm:currentAppraiserFirm});
      }
    };
    callBack = callBack.bind(this);

    let parameters =[
      {
        field:'appraiser_fk',
        value:id
      },
      {
        field:'appraiser_firm_fk',
        value:state.id
      }
    ];

    httpPost(url, parameters, 'Appraiser added.','Oops, something went wrong and could not add the appraiser. Please try again later.', callBack);
  }

  const removeAppraiser = (id) =>{
    let url = 'appraiser/firm/'+id+'/'+state.id;
    let successMsg = 'Appraiser removed.';
    let errorMsg = 'Oops, something went wrong and could not remove the appraiser. Please try again later.';

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let currentAppraiserFirm = Object.assign({},state.appraiserFirm);

        //find the appraiser index
        currentAppraiserFirm.appraisers = sliceFromArray(currentAppraiserFirm.appraisers, 'ID', id);
        setState({appraiserFirm:currentAppraiserFirm});
      }
    };
    callBack = callBack.bind(this);

    httpDelete(url,successMsg,errorMsg, callBack);
  }

  //promote the appraiser to master user
  const promoteAppraiser = (id) =>{
    let url = 'appraiser/firm/promote';
    let successMsg = 'Appraiser promoted to master user.';
    let errorMsg = 'Oops, something went wrong and could not promote the appraiser. Please try again later.';

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let newAppraiser = response.data.data;
        let currentMasterUsers = state.master_users.slice();

        currentMasterUsers.push(newAppraiser);

        setState({master_users:currentMasterUsers});
      }
    };
    callBack = callBack.bind(this);

    let parameters =[
      {
        field:'appraiser_fk',
        value:id
      },
      {
        field:'appraiser_firm_fk',
        value:state.id
      }
    ];

    httpPost(url, parameters, successMsg, errorMsg, callBack);
  }

  //demote the appraiser back to normal user
  const demoteAppraiser = (id) =>{
    let url = 'appraiser/firm/demote/'+id+'/'+state.id;
    let masterUser = sliceFromArray(state.master_users,'ID', id);
    let callBack = apiCallBack([{state:'master_users', value:masterUser}]);

    httpDelete(url,'Appraiser demoted.','Oops, something went wrong and could not demote the appraiser. Please try again later.', callBack);
  }

  const removeAppraiserFirm = (id) =>{
    let url = 'appraiser/remove/firm/'+id;
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        navigate('/appraiser-firm');
      }
    };
    callBack = callBack.bind(this);

    httpDelete(url,'Appraiser firm has succesfully removed.','Oops, something went wrong and could not remove the appraiser firm. Please try again later.', callBack);
  }

  //render
  let successMessage = 'Appraiser firm profile updated.';
  let failedMessage = 'Failed to update the value of this field, please try again later.';

  let appraiserRows,searchAppraiserRows, masterUserRows;

  if(state.appraiserFirm.appraisers&&state.appraiserFirm.appraisers.length>0){
    appraiserRows = state.appraiserFirm.appraisers.map(
      (appraiser, index)=>{

        return(
          <tr key={index}>
            <td><i className="fa fa-minus red-color cursor-pointer" onClick={()=>removeAppraiser(appraiser.ID)}></i>&nbsp;<i onClick={()=>promoteAppraiser(appraiser.ID)} className="fa fa-arrow-up cursor-pointer link-color"></i></td>
            <td><NavLink to={"/appraiser/"+appraiser.ID}>{appraiser.first_name+" "+appraiser.last_name}</NavLink></td>
            <td>{appraiser.email}</td>
            <td>{appraiser.phone}</td>
            <td>{appraiser.street}</td>
            <td>{appraiser.city}</td>
            <td>{appraiser.county}</td>
            <td>{appraiser.state}</td>
            <td>{appraiser.zip}</td>
          </tr>
        );
      }
    );
  }

  if(state.master_users.length>0){
    masterUserRows = state.master_users.map(
      (appraiser, index)=>{
        return(
          <tr key={index}>
            <td><i className="fa fa-minus red-color cursor-pointer" onClick={()=>demoteAppraiser(appraiser.ID)}></i></td>
            <td><NavLink to={"/appraiser/"+appraiser.ID}>{appraiser.first_name+" "+appraiser.last_name}</NavLink></td>
            <td>{appraiser.email}</td>
            <td>{appraiser.phone}</td>
            <td>{appraiser.street}</td>
            <td>{appraiser.city}</td>
            <td>{appraiser.county}</td>
            <td>{appraiser.state}</td>
            <td>{appraiser.zip}</td>
          </tr>
        );
      }
    );
  }

  if(state.searchAppraiserResults.length>0){
    searchAppraiserRows = state.searchAppraiserResults.map(
      (appraiser,index)=>{
        return(
          <tr key={index}>
            <td><i onClick={()=>addAppraiser(appraiser.ID)} className="fa fa-plus link-color cursor-pointer"></i></td>
            <td>{appraiser.first_name+" "+appraiser.last_name}</td>
            <td>{appraiser.email}</td>
            <td>{appraiser.city}</td>
            <td>{appraiser.county}</td>
            <td>{appraiser.state}</td>
          </tr>
        )
      }
    );
  }


  let state2;

  if(state.states.length>0){
    state2 =
     <MyXEditable
      type="select"
      value={state.appraiserFirm.state}
      opt={state.states}
      updateFunc={
        generateUpdateFunction('appraiser/firm/update',[{field:'field',value:'state'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
      }
    />;

  }

  return <div className="card padding">
    <Row>
      <Col>
        <div className="page-title">
          <i className="fa fa-reorder"></i>&nbsp;Appraiser Firm
        </div>
      </Col>
      <Col className="align-right">

      </Col>
    </Row>
    <Row>
      <Col sm="6" className="align-left">
        <NavLink to="/appraiser-firm">Back to appraiser firm list</NavLink>
      </Col>
      <Col sm="6" className="align-right">
        <NavLink to="#" onClick={()=>{
          confirmation(
            ()=>{removeAppraiserFirm(state.id)},
            ()=>{},
            'Delete this appraiser firm?',
            'Are you sure you want to delete this appraiser firm?');
        }}><font color="red"><i className="fa fa-minus red-color"></i>&nbsp;Delete Appraiser Firm</font></NavLink>
      </Col>
    </Row>
    <div className="hr"/>

    <Card>
      <CardHeader className="header-color">
        Basic Information
      </CardHeader>
      <CardBody>

        <Row>
          <Col sm="6">
            <Row>
              <Col sm="4">
                Company name
              </Col>
              <Col sm="8">
                <MyXEditable
                  type="text"
                  value={state.appraiserFirm.company}
                  updateFunc={
                    generateUpdateFunction('appraiser/firm/update',[{field:'field',value:'company'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col sm="6">
            <Row>
              <Col sm="4">
                Street
              </Col>
              <Col sm="8">
                <MyXEditable
                  type="text"
                  value={state.appraiserFirm.street}
                  updateFunc={
                    generateUpdateFunction('appraiser/firm/update',[{field:'field',value:'street'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <Row>
              <Col sm="4">
                Email
              </Col>
              <Col sm="8">
                <MyXEditable
                  type="text"
                  value={state.appraiserFirm.email}
                  updateFunc={
                    generateUpdateFunction('appraiser/firm/update',[{field:'field',value:'email'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col sm="6">
            <Row>
              <Col sm="4">
                City
              </Col>
              <Col sm="8">
                <MyXEditable
                  type="text"
                  value={state.appraiserFirm.city}
                  updateFunc={
                    generateUpdateFunction('appraiser/firm/update',[{field:'field',value:'city'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <Row>
              <Col sm="4">
                Phone
              </Col>
              <Col sm="8">
                <MyXEditable
                  type="text"
                  value={state.appraiserFirm.phone}
                  updateFunc={
                    generateUpdateFunction('appraiser/firm/update',[{field:'field',value:'phone'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col sm="6">
            <Row>
              <Col sm="4">
                County
              </Col>
              <Col sm="8">
                <MyXEditable
                  type="text"
                  value={state.appraiserFirm.county}
                  updateFunc={
                    generateUpdateFunction('appraiser/firm/update',[{field:'field',value:'county'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <Row>
              <Col sm="4">
                Notify Email
              </Col>
              <Col sm="8">
                <MyXEditable
                  type="text"
                  value={state.appraiserFirm.notify_email}
                  updateFunc={
                    generateUpdateFunction('appraiser/firm/update',[{field:'field',value:'notify_email'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col sm="6">
            <Row>
              <Col sm="4">
                State
              </Col>
              <Col sm="8">
                {state2}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <Row>
              <Col sm="4">
                Status
              </Col>
              <Col sm="8">
                <MyXEditable
                  type="select"
                  opt={[{key:'enable',value:'activated'},{key:'disable',value:'deactivated'}]}
                  value={state.appraiserFirm.status}
                  updateFunc={
                    generateUpdateFunction('appraiser/firm/update',[{field:'field',value:'status'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col sm="6">
            <Row>
              <Col sm="4">
                Zip
              </Col>
              <Col sm="8">
                <MyXEditable
                  type="text"
                  value={state.appraiserFirm.zip}
                  updateFunc={
                    generateUpdateFunction('appraiser/firm/update',[{field:'field',value:'zip'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>

    <br/>

    <Card>
      <CardHeader className="header-color">
        Master Users
      </CardHeader>
      <CardBody>
        <Table className="table table-striped">
          <thead>
            <tr>
              <th width="8%">Action</th>
              <th width="14%">Name</th>
              <th width="10%">Email</th>
              <th width="10%">Phone</th>
              <th width="15%">Street</th>
              <th width="10%">City</th>
              <th width="15%">County</th>
              <th width="10%">State</th>
              <th width="8%">Zip</th>
            </tr>
          </thead>
          <tbody>
            {masterUserRows}
          </tbody>
        </Table>
      </CardBody>
    </Card>

    <br/>

    <Card>
      <CardHeader className="header-color">
        Appraiser list
      </CardHeader>
      <CardBody>
        <div className="align-right">
          <Button color="warning" onClick={addAppraiserToggle}><i className="fa fa-plus"></i>&nbsp;Add</Button>
          <Modal className="my-modal-wide" isOpen={state.addAppraiserDropDownOpen} toggle={addAppraiserToggle} >
            <ModalHeader hidden={true} toggle={addAppraiserToggle}></ModalHeader>
            <ModalBody>
              <center>
                <h5><i className="fa fa-cogs"></i> Add Appraiser</h5>
              </center>
              <br/>
              <Row>
                <Col sm="10">
                  <Input type="text" placeholder="Search with Name/Email/Phone/Address" value={state.search} onChange={(e)=>setState({search:e.target.value})}/>
                </Col>
                <Col sm="2" className="align-right">
                  <Button color="warning" onClick={searchAppraiser}><i className="fa fa-search"></i>&nbsp;Search</Button>
                </Col>
              </Row>
              <br/>
              <div className="medium-scroll-container">
                <Table className="table table-striped">
                  <thead>
                    <tr>
                      <th width="10%">Action</th>
                      <th width="25%">Name</th>
                      <th width="15%">Email</th>
                      <th width="15%">City</th>
                      <th width="15%">County</th>
                      <th width="20%">State</th>
                    </tr>
                  </thead>
                  <tbody>
                    {searchAppraiserRows}
                  </tbody>
                </Table>
              </div>
              <br/>
              <center>
                <Button color="info" onClick={addAppraiserToggle}>Close</Button>
              </center>
            </ModalBody>
          </Modal>
        </div>
        <br/>
        <div className="large-scroll-container">
          <Table className="table table-striped">
            <thead>
              <tr>
                <th width="8%">Action</th>
                <th width="14%">Name</th>
                <th width="10%">Email</th>
                <th width="10%">Phone</th>
                <th width="15%">Street</th>
                <th width="10%">City</th>
                <th width="15%">County</th>
                <th width="10%">State</th>
                <th width="8%">Zip</th>
              </tr>
            </thead>
            <tbody>
              {appraiserRows}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  </div>;
}

export default AppraiserFirm;
