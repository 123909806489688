//Author Sooyoung Kim
//Date Aug 10, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, deleteAPICallGenerator, callBackGenerator, formatNumber, formatDateTime, formatDate, confirmation, showMessage} from '../../util/util';
import {Alert, Button, Card, CardHeader, CardBody, Table, Input,Modal, ModalBody,ModalHeader,TabContent, TabPane, Row, Col, UncontrolledTooltip} from 'reactstrap';
import SpreadWatchQuestionnaire from './spread-watch-questionnaire';
import {usePresencePusher} from '../pusher/pusher-context';
import React, {useReducer, useEffect} from 'react';
import MyXEditable from '../util/my-xeditable';
import MySelect from '../util/my-select';
import {NavLink} from 'react-router-dom';
import {useParams} from 'react-router-dom';
import valid from "card-validator";
import FileSaver from 'file-saver';
import Invoice from './invoice';
import '../comment/comment.css';
import './billing.css';

//initialize the state
const initialState = {
  role:localStorage.getItem('role'),
  overallStatus:{
    review_count:0,
    condition_count:0,
    rebuttal_count:0,
    due_invoice_count:0,
    unread_comment_count:0,
    fee_increase_count:0,
    quote_vetting_count:0
  },
  shadowInvoiceOriginals:[],
  invoiceStatuses:[],
  billStatuses:[{status:'Hold'},{status:'Cancelled'},{status:'Standby'},{status:'Paid'},{status:'Pending'}],
  unsettleBills:[],
  unsettleInvoices:[],
  settledInvoices:[],
  settledBills:[],
  lenderFees:[],
  appraiserFees:[],
  tripFeeDropDownOpen:false,
  newLenderFeeDropDownOpen:false,
  newAppraiserFeeDropDownOpen:false,
  showInvoiceDetailsDropDownOpen:false,
  showBillDetailsDropDownOpen:false,
  showTransactionDetailsDropDownOpen:false,
  viewCreditCardDropDownOpen:false,
  registerCreditCardDropDownOpen:false,
  registerPartialCreditCardDropDownOpen:false,
  newLenderFeeCategory:'',
  newLenderFeeCoveredBy:'Broker',
  defaultLenderFeeCoveredBy:'Broker',
  newLenderFeeDescription:'',
  newSplitFee1Description:'',
  newSplitFee2Description:'',
  newLenderFeeAmount:'',
  newSplitFee1Amount:'',
  newSplitFee2Amount:'',
  newSplitFee1CoveredBy:'Broker',
  newSplitFee2CoveredBy:'Broker',
  newAppraiserFeeCategory:'',
  newAppraiserFeeDescription:'',
  newAppraiserFeeAmount:'',
  selectedInvoice:{
    fees:[],
    total:0
  },
  selectedBill:{
    fees:[],
    total:0
  },
  selectedTransaction:{},
  paymentMethods:[],
  partialCCPlatform:'Mercury CC',
  selectedAppraiserPaymentMethod:'',
  defaultAppraiserPaymentMethod:'',
  selectedLenderPaymentMethod:'',
  defaultLenderPaymentMethod:'',
  selectedSplitFee1PaymentMethod:'',
  selectedSplitFee2PaymentMethod:'',
  selectedLenderCCProfile:0,
  selectedAppraiserCCProfile:0,
  splitFee1CCProfiles:0,
  splitFee2CCProfiles:0,
  ccProfiles:[],
  allCCProfiles:[],
  splitFee:false,
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
  transactionTab:'1',

  ccErrorMsg:'',
  ccEnable:true,
  ccNumberError:'',
  ccNumberValid:true,
  ccTypeError:'',
  ccTypeValid:true,

  newcc_first_name:'',
  newcc_last_name:'',
  newcc_email:'',
  newcc_card_type:'',
  newcc_card_number:'',
  newcc_card_number1:'',
  newcc_cvc:'',
  newcc_expiration_year:'',
  newcc_expiration_month:'',
  newcc_street:'',
  newcc_city:'',
  newcc_state:'',
  newcc_zip:'',
  transactions:[],
  loading: false,

  refundAmount:'',
  refundReason:'',
  refundNewPaymentMethod:'',
  refundCCProfile:0,

  chargeBackNum:'',

  transactionConfirmationNumber:'',
  transactionAmount:0,
  refundedTransactionNum:'',

  comments:[],
  newComment:'',
  commentTemplates:[],
  billingNoteDropDownOpen:false,

  stackInvoices: false,
  stackBills: false,
  removeTransactionReverseInvoice:'no',
  manualTransactionID:'',
  manualBillNum:'',
  presenceMembers:[],

  messages:[],
  pendingTripFee:false,
  showSpreadWatchQuestionnaire:false
};

//reducer function that perform state update
const reducer = getReducer();


const Billing  = (props)=>{
  const controller = new AbortController();
  const presencePusher = usePresencePusher ();
  let {id} = useParams();
  let {aid} = useParams();

  let newInitialState = Object.assign({}, initialState, {
    id: id,
    auditID: aid,
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  useEffect(() => {
    validateCCType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.newcc_card_type, state.ccNumberValid]);

  useEffect(() => {
    validateCCNumber();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.newcc_card_number, state.ccTypeValid]);


  //run only once when component is loaded
  useEffect(()=>{
    getAppraisalBilling(props.appraisalFk);
    getAppraisalFeeCategory();
    getPaymentMethods();
    getInvoiceStatus();
    getBillingComments();
    getCommentTemplates();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if (presencePusher) {
      const presenceChannel = presencePusher.subscribe('presence-order');

      const handleSubscriptionSucceeded = (members) => {
        // Get the initial user list from presence channel
        let presenceMembers = [];
        members.each(
          function(member)
          {
            let ID = member.info.ID;
            let orderID = member.info.orderID;
            let myID = localStorage.getItem('userID');
            if(orderID===props.appraisalFk){
              if(ID!==myID){
                presenceMembers.push(member);
              }
            }
          }
        );
        console.log(presenceMembers)
        setState({presenceMembers:presenceMembers})
      };

      const handleMemberAdded = (member) => {
        let ID = member.info.ID;
        let orderID = member.info.orderID;
        let myID = localStorage.getItem('userID');
        if(orderID===props.appraisalFk){
          if(ID!==myID){
            let presenceMembers = state.presenceMembers.slice();
            presenceMembers.push(member);

            setState({presenceMembers:presenceMembers});
          }
        }
      };

      const handleMemberRemoved = (member) => {
        // Remove a user from the user list
        let ID = member.info.ID;
        let orderID = member.info.orderID;
        let myID = localStorage.getItem('userID');

        if(orderID===props.appraisalFk){
          if(ID!==myID){
            let presenceMembers = state.presenceMembers.slice();

            for(let i=0;i<presenceMembers.length;i++){
              if(presenceMembers[i].info.ID===member.info.ID){
                presenceMembers.splice(i, 1);
                setState({presenceMembers:presenceMembers});
                break;
              }
            }
          }
        }
      };

      presenceChannel.bind('pusher:subscription_succeeded', handleSubscriptionSucceeded);
      presenceChannel.bind('pusher:member_added', handleMemberAdded);
      presenceChannel.bind('pusher:member_removed', handleMemberRemoved);

      return () => {
        presenceChannel.unbind('pusher:subscription_succeeded', handleSubscriptionSucceeded);
        presenceChannel.unbind('pusher:member_added', handleMemberAdded);
        presenceChannel.unbind('pusher:member_removed', handleMemberRemoved);

        presenceChannel.unsubscribe();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presencePusher, props.appraisalFk]);

  useEffect(() => {
    if (state.auditID !== aid) {
      getBillingComments();
      setState({auditID:aid});
    }

    if (props.appraisalFk !== id) {
      getAppraisalBilling(id);
      setState({id:id});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aid, id]);

  //non API call but simpyl manage state
  const checkMercuryCCProfile = (callBack) => {
    if(state.selectedInvoice.method==='Mercury CC' && state.selectedInvoice.profile_fk==="0"){
      confirmation(
        ()=>{toggleRegisterPartialCreditCard();setState({mercuryCallBack:callBack,})},
        ()=>{callBack()},
        'Missing Credit Card Profile',
        'Looks like this receivable is missing credit card profile. Do you want to import the credit card before proceeding?'
      );
    }
    else
      callBack();
  }

  const updatePaymentMethod = (method) => {
    let ccProfiles = state.ccProfiles.slice();

    let selectedLenderCCProfile = 0;
    for(let i=ccProfiles.length-1;i>=0;i--){
      if(method===ccProfiles[i].payment_method){
        selectedLenderCCProfile = ccProfiles[i].ID;
        break;
      }
    }

    setState({selectedLenderCCProfile:selectedLenderCCProfile, selectedLenderPaymentMethod:method});
  }

  const copyPaymentLink = (e) => {
    e.preventDefault();
    let link = 'https://portal.homevms.com/payment/update-credit-card/'+props.appraisalFk+'/'+state.selectedInvoice.ID;
    let tempInput = document.createElement("input");
    tempInput.value = link;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);

    showMessage('success', 'Payment link copied into clipboard');
  }

  const checkSpreadWatch = (spreadSubmitted) => {
    if(spreadSubmitted){
      return;
    }
    let totalPayable = 0;
    let totalReceivable = 0;
    let settledInvoices = state.settledInvoices.slice();
    let unsettleInvoices = state.unsettleInvoices.slice();
    let unsettleBills = state.unsettleBills.slice();
    let settledBills = state.settledBills.slice();
    let complex = false;
    for(let i=0;i<settledInvoices.length;i++){
      if(settledInvoices[i].status!=='Cancelled')
        for(let j=0;j<settledInvoices[i].fees.length;j++){
          if(settledInvoices[i].fees[j].category==='Complex'){
            complex = true;
            break;
          }
        }
      if(settledInvoices[i].status==='Loss')
        totalReceivable = totalReceivable+0;
      else if(settledInvoices[i].status!=='Overage'&&settledInvoices[i].status!=='Cancelled'&&settledInvoices[i].status!=='Voided'&&settledInvoices[i].status!=='Charge Back'&&settledInvoices[i].status!=='Refunded'&&settledInvoices[i].status!=='Partially Received'&&settledInvoices[i].status!=='Reconcile'&&settledInvoices[i].status!=='Balance Due')
        totalReceivable = totalReceivable + parseInt(settledInvoices[i].total, 10);
      else if(settledInvoices[i].status==='Refunded')
        totalReceivable = totalReceivable - parseInt(settledInvoices[i].total, 10);
    }

    for(let i=0;i<unsettleInvoices.length;i++){
      if(unsettleInvoices[i].status!=='Cancelled')
        for(let j=0;j<unsettleInvoices[i].fees.length;j++){
          if(unsettleInvoices[i].fees[j].category==='Complex'){
            complex = true;
            break;
          }
        }

      if(unsettleInvoices[i].status==='Loss')
        totalReceivable = totalReceivable+0;
      else if(unsettleInvoices[i].status!=='Overage'&&unsettleInvoices[i].status!=='Cancelled'&&unsettleInvoices[i].status!=='Voided'&&unsettleInvoices[i].status!=='Charge Back'&&unsettleInvoices[i].status!=='Refunded'&&unsettleInvoices[i].status!=='Partially Received'&&unsettleInvoices[i].status!=='Reconcile'&&unsettleInvoices[i].status!=='Balance Due')
        totalReceivable = totalReceivable + parseInt(unsettleInvoices[i].total, 10);
      else if(unsettleInvoices[i].status==='Refunded')
        totalReceivable = totalReceivable - parseInt(unsettleInvoices[i].total, 10);
    }

    for(let i=0;i<unsettleBills.length;i++){
      if(unsettleBills[i].status!=='Cancelled')
        totalPayable= totalPayable+ parseInt(unsettleBills[i].total,10);
    }

    for(let i=0;i<settledBills.length;i++){
      if(settledBills[i].status!=='Cancelled')
        totalPayable= totalPayable+ parseInt(settledBills[i].total,10);
    }

    let spreadLimit = complex?200:150;
    console.log(totalPayable,totalReceivable)
    if(props.appraisal.appraisal_type&&(props.appraisal.appraisal_type==='FHA Appraisal Disaster Inspection In & Out (1004D)'||props.appraisal.appraisal_type==='FHA Appraisal Final Inspection (1004D / HUD 92051)'||props.appraisal.appraisal_type==='USDA Appraisal Disaster Inspection In & Out (1004D)'||props.appraisal.appraisal_type==='USDA Appraisal Final Inspection (1004D / HUD 92051)'||props.appraisal.appraisal_type==='Appraisal Disaster Inspection In & Out (1004D)'||props.appraisal.appraisal_type==='Appraisal Final Inspection (1004D / HUD 92051)'||props.appraisal.appraisal_type==='Rent Survey (1007)'||props.appraisal.appraisal_type==='FHA Rent Survey (1007)'||props.appraisal.appraisal_type==='USDA Rent Survey (1007)')){

     if(totalReceivable-totalPayable<=0){
       setState({showSpreadWatchQuestionnaire:true});
     }
     else
      return;
    }

    if(totalReceivable-totalPayable>spreadLimit)
      setState({showSpreadWatchQuestionnaire:true});
  }

  const handleRefund = () => {
    if(state.selectedTransaction.payment_method==='Authorize.net')
      refundCC();
    else if(state.selectedTransaction.payment_method==='Mercury CC')
      refundMercuryCC();
    else if(state.selectedTransaction.payment_method==='Stripe')
      refundStripe();
  }

  const updateFieldCallBack = (field, value) => {
    console.log(field, value);
    let selectedBill = Object.assign({}, state.selectedBill);
    selectedBill[field] = value;
    setState({selectedBill:selectedBill});
  }

  const checkForInvoiceGrayResolution = (oldStatus, newStatus) => {
    if(oldStatus==='Reconcile'||oldStatus==='Partially Received'||state.shadowInvoiceOriginals.indexOf(state.selectedInvoice.ID)!==-1){
      if(newStatus==='Received'){
        confirmation(
          ()=>{updateInvoiceStatus(newStatus, 'yes')},
          ()=>{updateInvoiceStatus(newStatus)},
          'Resolve Gray Receivable?',
          'You are tryig to update this receivable status from (Partially Received/Reconcile) to Received. This receivable might have hidden gray receivable associated with it due to transaction not match. Do you want to resolve it? Click yes to flip this receivable to Pending and delete hidden gray receivable and update the total of invoice to match transaction. Click no to just update the status to Pending and do nothing.'
        );
      }
      else {
        updateInvoiceStatus(newStatus);
      }
    }
    else {
      updateInvoiceStatus(newStatus);
    }
  }

  const toggleSpreadWatchQuestionnaire = () => {
    setState({showSpreadWatchQuestionnaire:!state.showSpreadWatchQuestionnaire});
  }

  const showSettledInvoices = () => {
    setState({stackInvoices:false});
  }

  const showSettledBills = () => {
    setState({stackBills:false});
  }

  const setTransactionTab = (tab) => {
    setState({transactionTab:tab});
  }

  const compare = (a,b) => {
    if (a.datetime_created > b.datetime_created)
      return 1;
    if (a.datetime_created < b.datetime_created)
      return -1;
    return 0;
  }

  const selectTransaction = (method, id) => {
    let t = state.transactions.slice();
    for(let i=0;i<t.length;i++){
      if(t[i].ID===id){

        t[i].status = 'Received';
        for(let j=0;j<state.settledInvoices.length;j++){
          if(state.settledInvoices[j].ID===t[i].invoice_bill_fk)
            t[i].status = state.settledInvoices[j].status;
        }

        setState({refundAmount:t[i].amount});
        setState({selectedTransaction:t[i]});
        toggleTransactionDetails();
        break;
      }
    }
    setState({transactions:t});
  }

  const toggleBillingNote = () => {
    setState({
      billingNoteDropDownOpen: !state.billingNoteDropDownOpen
    });
  }

  const toggleTransactionDetails = () => {
      setState({showTransactionDetailsDropDownOpen:!state.showTransactionDetailsDropDownOpen});
  }

  const toggleViewCreditCard = () => {
    setState({viewCreditCardDropDownOpen:!state.viewCreditCardDropDownOpen});
  }

  const toggleRegisterCreditCard = () => {
    setState({registerCreditCardDropDownOpen:!state.registerCreditCardDropDownOpen});
  }

  const toggleRegisterPartialCreditCard = () => {
    setState({registerPartialCreditCardDropDownOpen:!state.registerPartialCreditCardDropDownOpen});
  }

  const toggleBillDetails = () => {
    setState({showBillDetailsDropDownOpen:!state.showBillDetailsDropDownOpen});
  }

  const toggleInvoiceDetails = () => {
    //pop up closed
    if(state.showInvoiceDetailsDropDownOpen===true){
      if(state.selectedInvoice){
        let originalInvoice;

        let settledInvoices = state.settledInvoices.slice();
        let unsettleInvoices = state.unsettleInvoices.slice();
        for(let i=0;i<settledInvoices.length;i++){
          if(settledInvoices[i].ID===state.selectedInvoice.ID){
            originalInvoice = Object.assign({},settledInvoices[i]);
            settledInvoices[i].note = '';
          }
        }

        if(!originalInvoice){
          for(let i=0;i<unsettleInvoices.length;i++){
            if(unsettleInvoices[i].ID===state.selectedInvoice.ID){
              originalInvoice = Object.assign({},unsettleInvoices[i]);
              unsettleInvoices[i].note = '';
            }
          }
        }

        if(state.selectedInvoice&&originalInvoice&&originalInvoice.note!==state.selectedInvoice.note){
          updateInvoiceNoteToServer(state.selectedInvoice.note);
          let selectedInvoice = Object.assign({}, state.selectedInvoice);
          selectedInvoice.note = '';
          setState({selectedInvoice:selectedInvoice});
          setState({settledInvoices:settledInvoices});
          setState({unsettleInvoices:unsettleInvoices});
        }
      }

    }
    setState({showInvoiceDetailsDropDownOpen:!state.showInvoiceDetailsDropDownOpen});
  }

  const selectInvoice = (invoice) => {
    setState({splitFee:false});
    setState({selectedInvoice:invoice});
    toggleInvoiceDetails();
    setState({transactionAmount:invoice.total});
  }

  const selectInvoiceByID = (id) => {
    for(let i=0;i<state.settledInvoices.length;i++){
      if(state.settledInvoices[i].ID===id){
        let invoice = state.settledInvoices[i];
        setState({splitFee:false});
        setState({selectedInvoice:invoice});
        toggleInvoiceDetails();
        setState({transactionAmount:invoice.total});
        break;
      }
    }
    for(let i=0;i<state.unsettleInvoices.length;i++){
      if(state.unsettleInvoices[i].ID===id){
        let invoice = state.unsettleInvoices[i];
        setState({splitFee:false});
        setState({selectedInvoice:invoice});
        toggleInvoiceDetails();
        setState({transactionAmount:invoice.total});
        break;
      }
    }
  }

  const selectBill = (bill) => {
    setState({selectedBill:bill});
    toggleBillDetails();
  }

  //toggle function for pop up
  const newLenderFeeToggle = () => {
    setState({newLenderFeeDropDownOpen:!state.newLenderFeeDropDownOpen});
  }

  const newAppraiserFeeToggle = () => {
    setState({newAppraiserFeeDropDownOpen:!state.newAppraiserFeeDropDownOpen});
  }

  const tripFeeToggle = () => {
    setState({tripFeeDropDownOpen:!state.tripFeeDropDownOpen});
  }

  const updateTripFeePriority = () => {
    setState({pendingTripFee:true},updateBillStatus('Pending'));

    tripFeeToggle();
  }

  const commentTemplatesOnChange = (v) => {
    let id = v;

    let template;

    for(let i=0;i<state.commentTemplates.length;i++){
      if(state.commentTemplates[i].ID===id){
        template = state.commentTemplates[i];
        break;
      }
    }

    if(template){
      setState({newComment:template.template});
    }
  }

  // Validate the Credit Card Number
  const validateCCNumber = () => {
    // console.log(state.newcc_card_number);
    let newCCNumber = state.newcc_card_number;

    let creditCard = valid.number(newCCNumber);
    let ccNumberError = "An unknown error occured. Please try again later";
    let ccNumberValid = false;

    if (newCCNumber === null || !newCCNumber.trim()) {
      ccNumberError = "Credit card number is not complete";
    } else if (creditCard.isValid) {
      ccNumberValid = true;
    } else {
      ccNumberError = "Credit card number is invalid";
    }

    setState({ccNumberValid: ccNumberValid, ccNumberError: ccNumberError});
  }

  // Validate the Credit Card Type
  const validateCCType = () => {
    let newCCNumber = state.newcc_card_number;
    let newCCType = state.newcc_card_type;

    let creditCard = valid.number(newCCNumber);
    let ccTypeError = "An unknown error occured. Please try again later";
    let ccTypeValid = false;

    //Mapping
    if(newCCType === "amex"){
      newCCType = "american-express";
    }else if(newCCType === "dinersclub"){
      newCCType = "diners-club";
    }

    //Card Type Verification
    if (newCCType === null || !newCCType.trim() || creditCard.card === null) {
      if(creditCard.card === null){
        ccTypeError = "";
        ccTypeValid = true;
      }else{
        ccTypeError = "Credit card type is not complete";
      }
    } else if (creditCard.card.type &&creditCard.card.type.toUpperCase() === newCCType.toUpperCase()) {
      ccTypeValid = true;
    } else {
      ccTypeError = "Credit card type is invalid";
    }

    setState({ccTypeValid: ccTypeValid, ccTypeError: ccTypeError});
  }

  //API call
  const getPaymentMethods = () => {
    let callBack = apiCallBack([{state:'paymentMethods', key:'data'}]);
	  httpGet('billing/paymentType/get', '', 'Oops, something went wrong and could not load payment methods. Please try again later.', callBack);
  }

  const getInvoiceStatus = () => {
    let callBack = apiCallBack([{state:'invoiceStatuses', key:'data'}]);
	  httpGet('billing/invoiceStatus/get', '', 'Oops, something went wrong and could not load invoice status. Please try again later.', callBack);
  }

  const getAppraisalBilling = (id) => {
    let callBack = (response)=>{
      let unsettleBills = response.data.data.unsettle_bills;
      let unsettleInvoices = response.data.data.unsettle_invoices;
      let lenderFees = response.data.data.lender_fees;
      let appraiserFees = response.data.data.appraiser_fees;
      let ccProfiles = response.data.data.credit_card_profiles;
      let transactions = response.data.data.transactions;
      let settledInvoices = response.data.data.settled_invoices;
      let settledBills = response.data.data.settled_bills;

      if(unsettleBills)
        setState({unsettleBills:unsettleBills});
      if(unsettleInvoices)
        setState({unsettleInvoices:unsettleInvoices});
      if(settledInvoices)
        setState({settledInvoices:settledInvoices});
      if(lenderFees)
        setState({lenderFees:lenderFees});
      if(appraiserFees)
        setState({appraiserFees:appraiserFees});
      if(ccProfiles)
        setState({ccProfiles:ccProfiles});
      if(transactions)
        setState({transactions:transactions});

      setState({priority:response.data.data.priority_queue});

      if(response.data.data.default_payment_method&&response.data.data.default_payment_method!==''){
        setState({defaultLenderPaymentMethod:response.data.data.default_payment_method,selectedLenderPaymentMethod:response.data.data.default_payment_method});
        if(response.data.data.default_payment_method==='Mercury CC'|| response.data.data.default_payment_method==='Authorize.net')
          updatePaymentMethod(response.data.data.default_payment_method);
      }

      if(response.data.data.default_appraiser_payment_method&&response.data.data.default_appraiser_payment_method!=='')
        setState({defaultAppraiserPaymentMethod:response.data.data.default_appraiser_payment_method,selectedAppraiserPaymentMethod:response.data.data.default_appraiser_payment_method});

      if(response.data.data.default_covered_by&&response.data.data.default_covered_by!=='')
        setState({defaultLenderFeeCoveredBy:response.data.data.default_covered_by,newLenderFeeCoveredBy:response.data.data.default_covered_by});

      let shadowInvoiceOriginals = [];

      for(let i=0;i<unsettleInvoices.length;i++){
        if(unsettleInvoices[i].shadow_invoice!=='0' && shadowInvoiceOriginals.indexOf(unsettleInvoices[i].shadow_invoice)===-1)
          shadowInvoiceOriginals.push(unsettleInvoices[i].shadow_invoice);
      }

      for(let i=0;i<transactions.length;i++){
        if(transactions[i].transaction_type==='Expense'){
          for(let j=0;j<settledBills.length;j++){
            if(settledBills[j].ID===transactions[i].invoice_bill_fk){
              settledBills[j].check_num = transactions[i].transaction_num;
            }

          }
        }
      }

      if(settledBills)
        setState({settledBills:settledBills});

      setState({shadowInvoiceOriginals:shadowInvoiceOriginals});

      if(ccProfiles.length>0){
        setState({
          selectedLenderCCProfile:ccProfiles[ccProfiles.length-1].ID,
          selectedAppraiserCCProfile:ccProfiles[ccProfiles.length-1].ID,
          splitFee2CCProfiles:ccProfiles[ccProfiles.length-1].ID,
          refundCCProfile:ccProfiles[ccProfiles.length-1].ID
        });
      }
    };
    callBack = callBack.bind(this);

    httpGet('billing/'+id, '', 'Oops, something went wrong and could not load billing for this appraisal. Please try again later.', callBack);
  }

  const getAppraisalFeeCategory = () => {
    let callBack = apiCallBack([{state:'feeCategories', key:'data'}]);
	  httpGet('billing/feeCategory/get', '', 'Oops, something went wrong and could not load appraisal fee category. Please try again later.', callBack);
  }

  const updatePriority = (ID) => {
    let parameters = [
      {
        field:'appraisalFk',
        value:ID
      }
    ];

    let callBack = apiCallBack([{state:'priority', value:(state.priority==='yes'?'no':'yes')}]);
    httpPut('report/pendingPayment/priority/update', parameters, 'Priority payment updated successfully.', 'Oops, something went wrong and could not update this priority payment. Please try again later.', callBack);
  }

  const approveAudit = (status) => {
    let parameters = [
      {
        field:'ID',
        value:state.auditID
      },
      {
        field:'status',
        value:status
      }
    ];

    let callBack = (response)=>{
      let code= response.data.code;
      if(code==='00'){
        props.history.push('/billing/'+props.appraisalFk);
      }
    };
    callBack = callBack.bind(this);

    httpPost('billing/accountingAudit/approve', parameters, 'Confirmed accounting changes successfully.', 'Oops, something went wrong and could not confirm this accounting changes. Please try again later.', callBack);
  }

  const captureBillManually = () => {
    let parameters = [
      {
        field:'bill_fk',
        value:state.selectedBill.ID
      },
      {
        field:'appraisal_fk',
        value:state.selectedBill.appraisal_fk
      },
      {
        field:'amount',
        value:state.selectedBill.total
      },
      {
        field:'transaction_type',
        value:'Expense'
      },
      {
        field:'transaction_num',
        value:state.manualBillNum
      }
    ];

    let callBack = (response)=>{
      console.log(response);
      let code= response.data.code;
      if(code==='00'){
        let unsettleBills = response.data.data.unsettle_bills;
        let unsettleInvoices = response.data.data.unsettle_invoices;
        let transactions = response.data.data.transactions;
        let settledInvoices = response.data.data.settled_invoices;
        let settledBills = response.data.data.settled_bills;

        if(unsettleBills)
          setState({unsettleBills:unsettleBills});
        if(unsettleInvoices)
          setState({unsettleInvoices:unsettleInvoices});
        if(settledInvoices)
          setState({settledInvoices:settledInvoices});
        if(transactions)
          setState({transactions:transactions});

        for(let i=0;i<transactions;i++){
          if(transactions[i].transaction_type==='Expense'){
            for(let j=0;j<settledBills.length;j++){
              if(settledBills[j].ID===transactions[i].invoice_bill_fk){
                settledBills[j].check_num = transactions[i].transaction_num;
              }
            }
          }
        }

        if(settledBills)
          setState({settledBills:settledBills});
        getBillingComments();
        toggleBillDetails();
      }
    };
    callBack = callBack.bind(this);

    httpPost('billing/transaction/create', parameters, 'Transaction captured manually.', 'Oops, something went wrong and could not capture this transaction. Please try again later.', callBack);
  }

  const captureAuthorizeManualTransaction = () => {
    let parameters = [
      {
        field:'ID',
        value:props.appraisalFk
      },
      {
        field:'transactionID',
        value:state.manualTransactionID
      },
      {
        field:'invoiceFk',
        value:state.selectedInvoice.ID
      }
    ];

    let callBack = (response)=>{
      let code= response.data.code;
      if(code==='00'){
        let unsettleBills = response.data.data.unsettle_bills;
        let unsettleInvoices = response.data.data.unsettle_invoices;
        let transactions = response.data.data.transactions;
        let settledInvoices = response.data.data.settled_invoices;
        let settledBills = response.data.data.settled_bills;

        if(unsettleBills)
          setState({unsettleBills:unsettleBills});
        if(unsettleInvoices)
          setState({unsettleInvoices:unsettleInvoices});
        if(settledInvoices)
          setState({settledInvoices:settledInvoices});
        if(settledBills)
          setState({settledBills:settledBills});
        if(transactions)
          setState({transactions:transactions});
        getBillingComments();
        toggleInvoiceDetails();
      }
    };
    callBack = callBack.bind(this);

    httpPost('billing/transaction/authorize/manual', parameters, 'Authorize transaction captured successfully.', 'Oops, something went wrong and could not capture this transaction. Please try again later.', callBack);
  }

  const captureStripeManualTransaction = () => {
    let parameters = [
      {
        field:'ID',
        value:props.appraisalFk
      },
      {
        field:'transactionID',
        value:state.manualTransactionID
      },
      {
        field:'invoiceFk',
        value:state.selectedInvoice.ID
      }
    ];

    let callBack = (response)=>{
      let code= response.data.code;

      if(code==='00'){
        let unsettleBills = response.data.data.unsettle_bills;
        let unsettleInvoices = response.data.data.unsettle_invoices;
        let transactions = response.data.data.transactions;
        let settledInvoices = response.data.data.settled_invoices;
        let settledBills = response.data.data.settled_bills;

        if(unsettleBills)
          setState({unsettleBills:unsettleBills});
        if(unsettleInvoices)
          setState({unsettleInvoices:unsettleInvoices});
        if(settledInvoices)
          setState({settledInvoices:settledInvoices});
        if(settledBills)
          setState({settledBills:settledBills});
        if(transactions)
          setState({transactions:transactions});
        getBillingComments();
        toggleInvoiceDetails();
      }
    };
    callBack = callBack.bind(this);

    httpPost('billing/transaction/stripe/manual', parameters, 'Stripe transaction captured successfully.', 'Oops, something went wrong and could not capture this transaction. Please try again later.', callBack);
  }

  //submit a new appraiser fee
  const splitFee = () => {
    let parameters = [
      {
        field:'appraisal_fk',
        value:props.appraisalFk
      },
      {
        field:'invoice_fk',
        value:state.selectedInvoice.ID
      },
      {
        field:'description1',
        value:state.newSplitFee1Description
      },
      {
        field:'coveredBy1',
        value:state.newSplitFee1CoveredBy
      },
      {
        field:'amount1',
        value:state.newSplitFee1Amount
      },
      {
        field:'paymentMethod1',
        value:state.selectedSplitFee1PaymentMethod
      },
      {
        field:'description2',
        value:state.newSplitFee2Description
      },
      {
        field:'coveredBy2',
        value:state.newSplitFee2CoveredBy
      },
      {
        field:'amount2',
        value:state.newSplitFee2Amount
      },
      {
        field:'paymentMethod2',
        value:state.selectedSplitFee2PaymentMethod
      }
    ];

    if(state.selectedSplitFee1PaymentMethod==='Authorize.net'||state.selectedSplitFee1PaymentMethod==='Mercury CC')
      parameters.push({field:'profileFk1',value:state.splitFee1CCProfiles});

    if(state.selectedSplitFee2PaymentMethod==='Authorize.net'||state.selectedSplitFee2PaymentMethod==='Mercury CC')
      parameters.push({field:'profileFk2',value:state.splitFee2CCProfiles});

    let callBack = apiCallBack([{state:'unsettleInvoices', key:'data.unsettle_invoices'}, {state:'showInvoiceDetailsDropDownOpen', key:false}, {state:'splitFee', value:false}], getBillingComments());
    httpPost('billing/fee/split', parameters, 'Fee splitted successfully.', 'Oops, something went wrong and could not split the fee. Please try again later.', callBack);
  }

  //submit a new appraiser fee
  const voidTransaction = () => {
    let parameters = [
      {
        field:'transactionID',
        value:state.selectedTransaction.ID
      }
    ];

    if(state.refundReason==='2'){
      let tmp = {
        field:'cancelOrder',
        value:'yes'
      };

      parameters.push(tmp);
    }

    let callBack = (response)=>{console.log(response);
      let code= response.data.code;

      if(code==='00'){
        let unsettleBills = response.data.data.unsettle_bills;
        let unsettleInvoices = response.data.data.unsettle_invoices;
        let transactions = response.data.data.transactions;
        let settledInvoices = response.data.data.settled_invoices;
        let settledBills = response.data.data.settled_bills;

        if(unsettleBills)
          setState({unsettleBills:unsettleBills});
        if(unsettleInvoices)
          setState({unsettleInvoices:unsettleInvoices});
        if(settledInvoices)
          setState({settledInvoices:settledInvoices});
        if(settledBills)
          setState({settledBills:settledBills});
        if(transactions)
          setState({transactions:transactions});
        getBillingComments();
        toggleTransactionDetails();
      }
    };
    callBack = callBack.bind(this);

    httpPost('billing/creditCard/void', parameters, 'Transaction voided successfully.', 'Oops, something went wrong and could not void the transaction. Please try again later.', callBack);
  }

  const voidMercuryTransaction = () => {
    let parameters = [
      {
        field:'transactionID',
        value:state.selectedTransaction.ID
      }
    ];

    if(state.refundReason==='2'){
      let tmp = {
        field:'cancelOrder',
        value:'yes'
      };

      parameters.push(tmp);
    }

    let callBack = (response)=>{console.log(response);
      let code= response.data.code;

      if(code==='00'){
        let unsettleBills = response.data.data.unsettle_bills;
        let unsettleInvoices = response.data.data.unsettle_invoices;
        let transactions = response.data.data.transactions;
        let settledInvoices = response.data.data.settled_invoices;
        let settledBills = response.data.data.settled_bills;

        if(unsettleBills)
          setState({unsettleBills:unsettleBills});
        if(unsettleInvoices)
          setState({unsettleInvoices:unsettleInvoices});
        if(settledInvoices)
          setState({settledInvoices:settledInvoices});
        if(settledBills)
          setState({settledBills:settledBills});
        if(transactions)
          setState({transactions:transactions});
        getBillingComments();
        toggleTransactionDetails();
      }
    };
    callBack = callBack.bind(this);

    httpPost('billing/mercuryCC/void', parameters, 'Transaction voided successfully.', 'Oops, something went wrong and could not void the transaction. Please try again later.', callBack);
  }

  const chargeBackResolved = () => {
    let parameters = [
      {
        field:'transactionID',
        value:state.selectedTransaction.ID
      },
      {
        field:'transactionNum',
        value:state.chargeBackNum
      }
    ];

    let callBack = (response)=>{console.log(response);
      let code= response.data.code;

      if(code==='00'){
        let unsettleBills = response.data.data.unsettle_bills;
        let unsettleInvoices = response.data.data.unsettle_invoices;
        let transactions = response.data.data.transactions;
        let settledInvoices = response.data.data.settled_invoices;
        let settledBills = response.data.data.settled_bills;

        if(unsettleBills)
          setState({unsettleBills:unsettleBills});
        if(unsettleInvoices)
          setState({unsettleInvoices:unsettleInvoices});
        if(settledInvoices)
          setState({settledInvoices:settledInvoices});
        if(settledBills)
          setState({settledBills:settledBills});
        if(transactions)
          setState({transactions:transactions});
        getBillingComments();
        toggleTransactionDetails();
      }
    };
    callBack = callBack.bind(this);

    httpPost('billing/creditCard/chargeBackResolved', parameters, 'Transaction charge back successfully.', 'Oops, something went wrong and could not charge back the transaction. Please try again later.', callBack);
  }

  const chargeBackCC = () => {
    let parameters = [
      {
        field:'transactionID',
        value:state.selectedTransaction.ID
      },
      {
        field:'transactionNum',
        value:state.chargeBackNum
      }
    ];

    let callBack = (response)=>{console.log(response);
      let code= response.data.code;
      if(code==='00'){
        let unsettleBills = response.data.data.unsettle_bills;
        let unsettleInvoices = response.data.data.unsettle_invoices;
        let transactions = response.data.data.transactions;
        let settledInvoices = response.data.data.settled_invoices;
        let settledBills = response.data.data.settled_bills;

        if(unsettleBills)
          setState({unsettleBills:unsettleBills});
        if(unsettleInvoices)
          setState({unsettleInvoices:unsettleInvoices});
        if(settledInvoices)
          setState({settledInvoices:settledInvoices});
        if(settledBills)
          setState({settledBills:settledBills});
        if(transactions)
          setState({transactions:transactions});
        getBillingComments();
        toggleTransactionDetails();
      }
    };
    callBack = callBack.bind(this);

    httpPost('billing/creditCard/chargeBack', parameters, 'Transaction charge back successfully.', 'Oops, something went wrong and could not charge back the transaction. Please try again later.', callBack);
  }

  const updateBillStatus = (status) => {
    let parameters = [
      {
        field:'invoiceID',
        value:state.selectedBill.ID
      },
      {
        field:'status',
        value:status
      }
    ];

    let callBack = (response)=>{
      let code= response.data.code;
      if(code==='00'){
        let selectedBill = Object.assign({},state.selectedBill,{status:status});
        setState({selectedBill:selectedBill});

        if(status!=='Paid'){
          let unsettleBills = state.unsettleBills.slice();

          //check if it's already inside the array
          let notExist = true;
          for(let i=0;i<unsettleBills.length;i++){
            if(unsettleBills[i].ID===selectedBill.ID){
              notExist = false;
              unsettleBills[i].status = status;
            }
          }
          if(notExist===true)
            unsettleBills.push(selectedBill);

          setState({unsettleBills:unsettleBills});

          let settledBills = state.settledBills.slice();

          for(let i=0;i<settledBills.length;i++){
            if(settledBills[i].ID===selectedBill.ID)
              settledBills.splice(i,1);
          }
          setState({settledBills:settledBills});
        }
        else{
          let unsettleBills = state.unsettleBills.slice();

          for(let i=0;i<unsettleBills.length;i++){
            if(unsettleBills[i].ID===selectedBill.ID)
              unsettleBills.splice(i,1);
          }
          setState({unsettleBills:unsettleBills});

          let settledBills = state.settledBills.slice();

          settledBills.push(selectedBill);
          setState({settledBills:settledBills});
        }
        getBillingComments();

        if(state.pendingTripFee){
          if(state.priority!=='yes'){
            updatePriority(props.appraisalFk);
          }
          setState({pendingTripFee:false});
        }else{
          toggleBillDetails();
        }
      }
    };
    callBack = callBack.bind(this);

    httpPut('billing/bill/update', parameters, 'Bill updated successfully.', 'Oops, something went wrong and could not update the bill. Please try again later.', callBack);
  }

  const updateBillMethod = (method) => {
    let parameters = [
      {
        field:'invoiceID',
        value:state.selectedBill.ID
      },
      {
        field:'method',
        value:method
      }
    ];

    let callBack = (response)=>{
      let code= response.data.code;

      if(code==='00'){
        let selectedBill = Object.assign({},state.selectedBill,{method:method});

        let unsettleBills = state.unsettleBills.slice();

        for(let i=0;i<unsettleBills.length;i++){
          if(unsettleBills[i].ID===selectedBill.ID)
            unsettleBills.splice(i,1, selectedBill);
        }

        let settledBills = state.settledBills.slice();
        for(let i=0;i<settledBills.length;i++){
          if(settledBills[i].ID===selectedBill.ID)
            settledBills.splice(i,1, selectedBill);
        }

        setState({selectedBill:selectedBill, unsettleBills:unsettleBills, settledBills:settledBills});
        getBillingComments();
        toggleBillDetails();
      }
    };
    callBack = callBack.bind(this);

    httpPut('billing/bill/method/update', parameters, 'Bill updated successfully.', 'Oops, something went wrong and could not update the bill. Please try again later.', callBack);
  }


  //generate a function that do the update on editable
  const generateUpdateFunction = (url, parameters, successMessage, failedMessage, parentCallBack=null) => {
    return (newValue, callBack, errorCallBack)=>{
      parameters.push({field:'value',value:newValue});
      let promise = httpPut(url,parameters,successMessage,failedMessage, callBack, errorCallBack);
      promise.then(
        (response)=>{
          getAppraisalBilling(props.appraisalFk);
          getBillingComments();

          let field;
          let value;

          for(let i=0;i<parameters.length;i++){
            if(parameters[i].field==='field')
              field = parameters[i].value;
            else if(parameters[i].field==='value')
              value = parameters[i].value;
          }
          if(parentCallBack!==null){
            parentCallBack(field, value);
          }
        }
      );
    }
  };

  const updateInvoiceStatus = (status, resolve='no') => {
    let parameters = [
      {
        field:'invoiceID',
        value:state.selectedInvoice.ID
      },
      {
        field:'status',
        value:status
      }
    ];

    if(resolve==='yes'){
      let tmp = {
        field:'resolveGrayReceivable',
        value:'yes'
      };

      parameters.push(tmp);
    }

    let callBack = (response)=>{console.log(response);
      let code= response.data.code;

      if(code==='00'){
        getAppraisalBilling(props.appraisalFk);
        getBillingComments();
        toggleInvoiceDetails();
      }
    };
    callBack = callBack.bind(this);

    httpPut('billing/invoice/update', parameters, 'Invoice updated successfully.', 'Oops, something went wrong and could not update the invoice. Please try again later.', callBack);
  }

  const updateInvoiceCoveredBy = (coveredBy) => {
    let parameters = [
      {
        field:'invoiceID',
        value:state.selectedInvoice.ID
      },
      {
        field:'coveredBy',
        value:coveredBy
      }
    ];

    let callBack = (response)=>{console.log(response);
      let code= response.data.code;

      if(code==='00'){
        let selectedInvoice = Object.assign({},state.selectedInvoice,{covered_by:coveredBy});
        setState({selectedInvoice:selectedInvoice});

        let settledInvoices = state.settledInvoices.slice();

        for(let i=0;i<settledInvoices.length;i++){
          if(settledInvoices[i].ID===selectedInvoice.ID)
            settledInvoices[i].covered_by = coveredBy;
        }
        setState({settledInvoices:settledInvoices});

        let unsettleInvoices = state.unsettleInvoices.slice();

        for(let i=0;i<unsettleInvoices.length;i++){
          if(unsettleInvoices[i].ID===selectedInvoice.ID)
            unsettleInvoices[i].covered_by = coveredBy;
        }
        getBillingComments();
        setState({unsettleInvoices:unsettleInvoices});
      }
    };
    callBack = callBack.bind(this);

    httpPut( 'billing/invoice/coveredBy/update', parameters, 'Invoice updated successfully.', 'Oops, something went wrong and could not update the invoice. Please try again later.', callBack);
  }

  const updateInvoiceNoteToServer = (note) => {
    let parameters = [
      {
        field:'invoiceID',
        value:state.selectedInvoice.ID
      },
      {
        field:'note',
        value:note
      }
    ];

    let callBack = apiCallBack([], getBillingComments());
    httpPut('billing/invoice/note/update', parameters, '', 'Oops, something went wrong and could not update the invoice. Please try again later.', callBack);
  }

  const updateInvoiceMethod = (method) => {
    let parameters = [
      {
        field:'invoiceID',
        value:state.selectedInvoice.ID
      },
      {
        field:'method',
        value:method
      }
    ];

    let callBack = (response)=>{console.log(response);
      let code= response.data.code;
      if(code==='00'){
        let selectedInvoice = Object.assign({},state.selectedInvoice,{method:method});
        let settledInvoices = state.settledInvoices.slice();

        for(let i=0;i<settledInvoices.length;i++){
          if(settledInvoices[i].ID===selectedInvoice.ID)
            settledInvoices[i].method = method;
        }
        let unsettleInvoices = state.unsettleInvoices.slice();

        for(let i=0;i<unsettleInvoices.length;i++){
          if(unsettleInvoices[i].ID===selectedInvoice.ID)
            unsettleInvoices[i].method = method;
        }
        getBillingComments();
        setState({selectedInvoice:selectedInvoice, settledInvoices:settledInvoices, unsettleInvoices:unsettleInvoices});
      }
    };
    callBack = callBack.bind(this);
    
    httpPut('billing/invoice/method/update', parameters, 'Invoice updated successfully.', 'Oops, something went wrong and could not update the invoice. Please try again later.', callBack);
  }

  const updateInvoiceProfileFk = (profileFk) => {
    let parameters = [
      {
        field:'invoiceID',
        value:state.selectedInvoice.ID
      },
      {
        field:'profile_fk',
        value:profileFk
      }
    ];

    let callBack = (response)=>{console.log(response);
      let code= response.data.code;

      if(code==='00'){
        let selectedInvoice = Object.assign({},state.selectedInvoice,{profile_fk:profileFk});
        let settledInvoices = state.settledInvoices.slice();

        for(let i=0;i<settledInvoices.length;i++){
          if(settledInvoices[i].ID===selectedInvoice.ID)
            settledInvoices[i].profile_fk = profileFk;
        }
        let unsettleInvoices = state.unsettleInvoices.slice();

        for(let i=0;i<unsettleInvoices.length;i++){
          if(unsettleInvoices[i].ID===selectedInvoice.ID)
            unsettleInvoices[i].profile_fk = profileFk;
        }
        getBillingComments();
        setState({selectedInvoice:selectedInvoice, settledInvoices:settledInvoices, unsettleInvoices:unsettleInvoices});
      }
    };
    callBack = callBack.bind(this);

    httpPut('billing/invoice/profileFk/update', parameters, 'Invoice updated successfully.', 'Oops, something went wrong and could not update the invoice. Please try again later.', callBack);
  }

  //submit a new appraiser fee
  const refundCC = () => {
    let parameters = [
      {
        field:'transactionID',
        value:state.selectedTransaction.ID
      },
      {
        field:'amount',
        value:state.refundAmount
      },
      {
        field:'reason',
        value:state.refundReason
      }
    ];

    if(state.refundReason==='5'){
      let tmp = {
        field:'refundedTransactionNum',
        value:state.refundedTransactionNum
      };

      parameters.push(tmp);
    }
    else if(state.refundReason==='3'){
      let tmp = {
        field:'paymentMethod',
        value:state.refundNewPaymentMethod
      };

      parameters.push(tmp);

      if(state.refundNewPaymentMethod==='Authorize.net'||state.refundNewPaymentMethod==='MercuryCC'){
        tmp = {
          field:'profileFk',
          value:state.refundCCProfile
        };

        parameters.push(tmp);
      }
    }
    let callBack = (response)=>{console.log(response);
      let code= response.data.code;


      if(code==='03'){
        let subCode = response.data.sub_code;

        if(subCode==='E00051'||subCode==='E00027'){
          confirmation(
            ()=>{voidTransaction()},
            ()=>{},
            'Void transaction?',
            'Refund transaction failed, it might be the transaction is not settle yet. Do you want to try to void the transaction?'
          );
        }
      }
      else if(code!=='00'){

      }
      else{
        let unsettleBills = response.data.data.unsettle_bills;
        let unsettleInvoices = response.data.data.unsettle_invoices;
        let transactions = response.data.data.transactions;
        let settledInvoices = response.data.data.settled_invoices;
        let settledBills = response.data.data.settled_bills;

        if(unsettleBills)
          setState({unsettleBills:unsettleBills});
        if(unsettleInvoices)
          setState({unsettleInvoices:unsettleInvoices});
        if(settledInvoices)
          setState({settledInvoices:settledInvoices});
        if(settledBills)
          setState({settledBills:settledBills});
        if(transactions)
          setState({transactions:transactions});
        getBillingComments();
      }
    };
    callBack = callBack.bind(this);

    httpPost('billing/creditCard/refund', parameters, 'Refunded successfully.', 'Oops, something went wrong and could not refund the card. Please try again later.', callBack);
  }


  //submit a new appraiser fee
  const refundMercuryCC = () => {
    let parameters = [
      {
        field:'transactionID',
        value:state.selectedTransaction.ID
      },
      {
        field:'amount',
        value:state.refundAmount
      },
      {
        field:'reason',
        value:state.refundReason
      }
    ];

    if(state.refundReason==='5'){
      let tmp = {
        field:'refundedTransactionNum',
        value:state.refundedTransactionNum
      };

      parameters.push(tmp);
    }
    else if(state.refundReason==='3'){
      let tmp = {
        field:'paymentMethod',
        value:state.refundNewPaymentMethod
      };

      parameters.push(tmp);

      if(state.refundNewPaymentMethod==='Authorize.net'||state.refundNewPaymentMethod==='MercuryCC'){
        tmp = {
          field:'profileFk',
          value:state.refundCCProfile
        };

        parameters.push(tmp);
      }
    }

    let callBack = (response)=>{console.log(response);
      let code= response.data.code;


      if(code==='03'){
        confirmation(
          ()=>{voidMercuryTransaction()},
          ()=>{},
          'Void transaction?',
          'Refund transaction failed, it might be the transaction is not settle yet. Do you want to try to void the transaction?'
        );
      }
      else if(code!=='00'){

      }
      else{
        let unsettleBills = response.data.data.unsettle_bills;
        let unsettleInvoices = response.data.data.unsettle_invoices;
        let transactions = response.data.data.transactions;
        let settledInvoices = response.data.data.settled_invoices;
        let settledBills = response.data.data.settled_bills;

        if(unsettleBills)
          setState({unsettleBills:unsettleBills});
        if(unsettleInvoices)
          setState({unsettleInvoices:unsettleInvoices});
        if(settledInvoices)
          setState({settledInvoices:settledInvoices});
        if(settledBills)
          setState({settledBills:settledBills});
        if(transactions)
          setState({transactions:transactions});
        getBillingComments();
      }
    };
    callBack = callBack.bind(this);

    httpPost('billing/mercuryCC/refund', parameters, 'Refunded successfully.', 'Oops, something went wrong and could not refund the card. Please try again later.', callBack);
  }

  const refundStripe = () => {
    let parameters = [
      {
        field:'transactionID',
        value:state.selectedTransaction.ID
      },
      {
        field:'amount',
        value:state.refundAmount
      },
      {
        field:'reason',
        value:state.refundReason
      }
    ];

    if(state.refundReason==='5'){
      let tmp = {
        field:'refundedTransactionNum',
        value:state.refundedTransactionNum
      };

      parameters.push(tmp);
    }
    else if(state.refundReason==='3'){
      let tmp = {
        field:'paymentMethod',
        value:state.refundNewPaymentMethod
      };

      parameters.push(tmp);

      if(state.refundNewPaymentMethod==='Authorize.net'||state.refundNewPaymentMethod==='MercuryCC'){
        tmp = {
          field:'profileFk',
          value:state.refundCCProfile
        };

        parameters.push(tmp);
      }
    }

    let callBack = (response)=>{console.log(response);
      let code= response.data.code;
      if(code==='00'){
        let unsettleBills = response.data.data.unsettle_bills;
        let unsettleInvoices = response.data.data.unsettle_invoices;
        let transactions = response.data.data.transactions;
        let settledInvoices = response.data.data.settled_invoices;
        let settledBills = response.data.data.settled_bills;

        if(unsettleBills)
          setState({unsettleBills:unsettleBills});
        if(unsettleInvoices)
          setState({unsettleInvoices:unsettleInvoices});
        if(settledInvoices)
          setState({settledInvoices:settledInvoices});
        if(settledBills)
          setState({settledBills:settledBills});
        if(transactions)
          setState({transactions:transactions});
        getBillingComments();
      }
    };
    callBack = callBack.bind(this);

    httpPost('billing/stripe/refund', parameters, 'Refunded successfully.', 'Oops, something went wrong and could not refund this stripe transaction. Please try again later.', callBack);
  }

  //remove a transaction
  const removeTransaction = () => {
    let parameters = [
      {
        field:'transactionID',
        value:state.selectedTransaction.ID
      },
      {
        field:'reverseInvoice',
        value:state.removeTransactionReverseInvoice
      }
    ];

    let callBack = (response)=>{console.log(response);
      let code= response.data.code;

      if(code==='00'){
        let unsettleBills = response.data.data.unsettle_bills;
        let unsettleInvoices = response.data.data.unsettle_invoices;
        let transactions = response.data.data.transactions;
        let settledInvoices = response.data.data.settled_invoices;
        let settledBills = response.data.data.settled_bills;

        if(unsettleBills)
          setState({unsettleBills:unsettleBills});
        if(unsettleInvoices)
          setState({unsettleInvoices:unsettleInvoices});
        if(settledInvoices)
          setState({settledInvoices:settledInvoices});
        if(settledBills)
          setState({settledBills:settledBills});
        if(transactions)
          setState({transactions:transactions});
        getBillingComments();
      }
    };
    callBack = callBack.bind(this);

    httpPost('billing/transaction/remove', parameters, 'Transaction removed successfully.', 'Oops, something went wrong and could not remove the transaction. Please try again later.', callBack);
  }

  //submit a new appraiser fee
  const newAppraiserFee = (e) => {
    e.preventDefault();
    let parameters = [
      {
        field:'appraisal_fk',
        value:props.appraisalFk
      },
      {
        field:'type',
        value:'Appraiser'
      },
      {
        field:'category',
        value:state.newAppraiserFeeCategory
      },
      {
        field:'description',
        value:state.newAppraiserFeeDescription
      },
      {
        field:'amount',
        value:state.newAppraiserFeeAmount
      },
      {
        field:'paymentMethod',
        value:state.selectedAppraiserPaymentMethod
      }
    ];

    if(state.selectedAppraiserPaymentMethod==='Authorize.net' || state.selectedAppraiserPaymentMethod==='MercuryCC')
      parameters.push({field:'profileFk',value:state.selectedAppraiserCCProfile});


    let callBack = (response)=>{
      let code= response.data.code;
      if(code==='00'){
        let settledBills = response.data.data.settled_bills;
        let unsettleBills = response.data.data.unsettle_bills;
        let settledInvoices = response.data.data.settled_invoices;
        let unsettleInvoices = response.data.data.unsettle_invoices;
        let fee = response.data.data.fee;
        let spreadSubmitted = response.data.data.spreadSubmitted;


        //update all settled & unsettled invoice + bill
        if(settledBills)
          setState({settledBills:settledBills});
        if(unsettleBills)
          setState({unsettleBills:unsettleBills});
        if(settledInvoices)
          setState({settledInvoices:settledInvoices});
        if(unsettleInvoices)
          setState({unsettleInvoices:unsettleInvoices});
        
        checkSpreadWatch(spreadSubmitted)

        if(fee){
          let existingFees = state.appraiserFees.slice();
          existingFees.push(fee);

          setState({appraiserFees:existingFees});

          if(props.appraisal.status==='Cancelled'&&state.newAppraiserFeeCategory==='Trip'){
            let selectBill={fees:[],total:0,status:''};
            if(unsettleBills&&unsettleBills.length>0&&response.data.data.bill_ID){
              selectBill = unsettleBills.find(bill => bill.ID === response.data.data.bill_ID);
            }
            setState({selectedBill:selectBill});
            tripFeeToggle()
          }

          newAppraiserFeeToggle();
          setState({newAppraiserFeeAmount:'', newAppraiserFeeCategory:'', newAppraiserFeeDescription:''});
          getBillingComments();
        }
      }
    };
    callBack = callBack.bind(this);

    httpPost('billing/fee/create', parameters, 'New appraiser fee created successfully.', 'Oops, something went wrong and could not create the appraiser fee. Please try again later.', callBack);
  }

  //submit a new lender fee
  const newLenderFee = (e) => {
    e.preventDefault();
    let parameters = [
      {
        field:'appraisal_fk',
        value:props.appraisalFk
      },
      {
        field:'type',
        value:'Lender'
      },
      {
        field:'category',
        value:state.newLenderFeeCategory
      },
      {
        field:'coveredBy',
        value:state.newLenderFeeCoveredBy
      },
      {
        field:'description',
        value:state.newLenderFeeDescription
      },
      {
        field:'amount',
        value:state.newLenderFeeAmount
      },
      {
        field:'paymentMethod',
        value:state.selectedLenderPaymentMethod
      }
    ];

    console.log(parameters);

    if(state.selectedLenderPaymentMethod==='Authorize.net'||state.selectedLenderPaymentMethod==='Mercury CC')
      parameters.push({field:'profileFk',value:state.selectedLenderCCProfile});

    let callBack = (response)=>{
      let code= response.data.code;
      if(code==='00'){
        let settledBills = response.data.data.settled_bills;
        let unsettleBills = response.data.data.unsettle_bills;
        let settledInvoices = response.data.data.settled_invoices;
        let unsettleInvoices = response.data.data.unsettle_invoices;
        let fee = response.data.data.fee;

        let spreadSubmitted = response.data.data.spreadSubmitted;

        if(settledBills)
          setState({settledBills:settledBills});
        if(unsettleBills)
          setState({unsettleBills:unsettleBills});
        if(settledInvoices)
          setState({settledInvoices:settledInvoices});
        if(unsettleInvoices)
          setState({unsettleInvoices:unsettleInvoices});

        checkSpreadWatch(spreadSubmitted);

        if(fee){
          console.log();
          let existingFees = state.lenderFees.slice();
          existingFees.push(fee);

          setState({lenderFees:existingFees});

          newLenderFeeToggle();
          setState({newLenderFeeAmount:'', newLenderFeeCategory:'', newLenderFeeCoveredBy:state.defaultLenderFeeCoveredBy, newLenderFeeDescription:''});
          getBillingComments();
        }
      }
    };
    callBack = callBack.bind(this);

    httpPost('billing/fee/create', parameters, 'New lender fee created successfully.', 'Oops, something went wrong and could not create the lender fee. Please try again later.', callBack);
  }

  const chargeCC = () => {
    let parameters = [
      {
        field:'invoiceFk',
        value:state.selectedInvoice.ID
      }
    ];

    let callBack = (response)=>{console.log(response);
      let code= response.data.code;
      if(code==='00'){
        let transactions = state.transactions.slice();

        transactions.push(response.data.data.transaction);
        setState({unsettleInvoices:response.data.data.unsettle_invoices, settledInvoices:response.data.data.settled_invoices, transactions:transactions});
        toggleInvoiceDetails();
        getBillingComments();
      }
    };
    callBack = callBack.bind(this);

    httpPost('billing/creditCard/charge', parameters, 'Credit card charged successfully.', 'Oops, something went wrong and could not charge the credit card. Please try again later.', callBack);
  }

  const chargeMercuryCC = () => {
    let parameters = [
      {
        field:'invoiceFk',
        value:state.selectedInvoice.ID
      }
    ];

    let callBack = (response)=>{console.log(response);
      let code= response.data.code;
      if(code==='00'){
        let transactions = state.transactions.slice();

        transactions.push(response.data.data.transaction);
        setState({unsettleInvoices:response.data.data.unsettle_invoices, settledInvoices:response.data.data.settled_invoices, transactions:transactions, mercuryCallBack:null});
        toggleInvoiceDetails();
        getBillingComments();
      }
    };
    callBack = callBack.bind(this);

    httpPost('billing/mercuryCC/charge', parameters, 'Credit card charged successfully.', 'Oops, something went wrong and could not charge the credit card. Please try again later.', callBack);
  }

  const captureRemaindingPartialPayment = () => {
    let parameters = [
      {
        field:'transactionID',
        value:state.transactionConfirmationNumber
      },
      {
        field:'invoiceID',
        value:state.selectedInvoice.ID
      }
    ];

    let callBack = (response)=>{console.log(response);
      let code= response.data.code;
      if(code==='00'){
        setState({unsettleInvoices:response.data.data.unsettle_invoices, settledInvoices:response.data.data.settled_invoices, transactions:response.data.data.transactions});
        toggleInvoiceDetails();
        getBillingComments();
      }
    };
    callBack = callBack.bind(this);

    httpPost('billing/invoice/completePartialPayment', parameters, 'Remainding of partial payment captured successfully.', 'Oops, something went wrong and could not capture the transaction. Please try again later.', callBack);
  }

  const chargeRemaining = () => {
    let parameters = [
      {
        field:'invoiceID',
        value:state.selectedInvoice.ID
      }
    ];

    let callBack = (response)=>{console.log(response);
      let code= response.data.code;

      if(code==='00'){
        setState({unsettleInvoices:response.data.data.unsettle_invoices, settledInvoices:response.data.data.settled_invoices, transactions:response.data.data.transactions});
        toggleInvoiceDetails();
        getBillingComments();
      }
    };
    callBack = callBack.bind(this);

    httpPost('billing/invoice/completePartialPayment/cc', parameters, 'Remainding of partial payment captured successfully.', 'Oops, something went wrong and could not capture the transaction. Please try again later.', callBack);
  }

  const captureTransaction = () => {
    let parameters = [
      {
        field:'appraisal_fk',
        value:props.appraisalFk
      },
      {
        field:'transaction_num',
        value:state.transactionConfirmationNumber
      },
      {
        field:'amount',
        value:state.transactionAmount
      },
      {
        field:'transaction_type',
        value:'Income'
      },
      {
        field:'invoice_fk',
        value:state.selectedInvoice.ID
      }
    ];

    let callBack = (response)=>{console.log(response);
      let code= response.data.code;
      if(code==='00'){
        let transactions = state.transactions.slice();
        transactions.push(response.data.data.transaction);

        setState({unsettleInvoices:response.data.data.unsettle_invoices, settledInvoices:response.data.data.settled_invoices, transactions:transactions, mercuryCallBack:null});
        toggleInvoiceDetails();
        getBillingComments();
      }
    };
    callBack = callBack.bind(this);

    httpPost('billing/transaction/create', parameters, 'Transaction captured successfully.', 'Oops, something went wrong and could not capture the transaction. Please try again later.', callBack);
  }

  //download the file when user click on the file name using blob
  const downloadFile = (transactionID) => {
    let callBack = (response)=>{
      console.log(response);
      let code = response.data?response.data.code:undefined;

      if(code!=='00'){
        let byteCharacters = atob(response.data.data);
  			let byteNumbers = new Array(byteCharacters.length);
  			for (let i = 0; i < byteCharacters.length; i++) {
  				byteNumbers[i] = byteCharacters.charCodeAt(i);
  			}
  			let byteArray = new Uint8Array(byteNumbers);
  			let data = new Blob([byteArray]);
  			FileSaver.saveAs(data, 'invoice.pdf');
      }
    };

    let parameters = [
      {
        field:'transactionID',
        value:transactionID
      }
    ];

    httpPost('file/invoice/download', parameters, '', 'Oops, something went wrong and could not download the invoice. Please try again later.', callBack);
  }

  const registerCC = (e) => {
    e.preventDefault();
    if(!state.loading){
      setState({loading:true});

      let parameters = [
        {
          field:'ID',
          value:props.appraisalFk
        },
        {
          field:'isInternal',
          value:'yes'
        },
        {
          field:'billing_first_name',
          value:state.newcc_first_name
        },
        {
          field:'billing_last_name',
          value:state.newcc_last_name
        },
        {
          field:'email',
          value:state.newcc_email
        },
        {
          field:'card_number',
          value:state.newcc_card_number
        },
        {
          field:'card_type',
          value:state.newcc_card_type
        },
        {
          field:'cvc',
          value:state.newcc_cvc
        },
        {
          field:'expiration_month',
          value:state.newcc_expiration_month
        },
        {
          field:'expiration_year',
          value:state.newcc_expiration_year
        },
        {
          field:'billing_street',
          value:state.newcc_street
        },
        {
          field:'billing_city',
          value:state.newcc_city
        },
        {
          field:'billing_state',
          value:state.newcc_state
        },
        {
          field:'billing_zip',
          value:state.newcc_zip
        }
      ];

      let callBack = (response)=>{console.log(response);
        let code= response.data.code;

        if(code==='00'){
          let ccProfiles = state.ccProfiles.slice();
          ccProfiles.push(response.data.data);

          let allCCProfiles = state.allCCProfiles.slice();
          allCCProfiles.push(response.data.data);

          setState({
            ccProfiles:ccProfiles,
            allCCProfiles:allCCProfiles,
            selectedLenderCCProfile:ccProfiles[ccProfiles.length-1].ID, 
            selectedAppraiserCCProfile:ccProfiles[ccProfiles.length-1].ID,
            splitFee1CCProfiles:ccProfiles[ccProfiles.length-1].ID,
            splitFee2CCProfiles:ccProfiles[ccProfiles.length-1].ID,
            refundCCProfile:ccProfiles[ccProfiles.length-1].ID,
          });

          toggleRegisterCreditCard();
          getBillingComments();
        }
      };
      callBack = callBack.bind(this);

      let promises = httpPost('billing/creditCard/create', parameters, 'Credit card registered successfully.', 'Oops, something went wrong and could not register the credit card. Please try again later.', callBack);
      promises
        .then(
          function(result){
            //set loading equals to false so the function could be fire off once again
            setState({loading:false});
          }
        );
    }
  }

  const registerPartialCC = (e) => {
    e.preventDefault();
    if(!state.loading){
      setState({loading:true});

      let parameters = [
        {
          field:'ID',
          value:props.appraisalFk
        },
        {
          field:'isInternal',
          value:'yes'
        },
        {
          field:'partial',
          value:'yes'
        },
        {
          field:'platform',
          value:state.partialCCPlatform
        },
        {
          field:'billing_first_name',
          value:state.newcc_first_name
        },
        {
          field:'billing_last_name',
          value:state.newcc_last_name
        },
        {
          field:'email',
          value:state.newcc_email
        },
        {
          field:'card_number',
          value:state.newcc_card_number
        },
        {
          field:'card_type',
          value:state.newcc_card_type
        },
        {
          field:'cvc',
          value:state.newcc_cvc
        },
        {
          field:'expiration_month',
          value:state.newcc_expiration_month
        },
        {
          field:'expiration_year',
          value:state.newcc_expiration_year
        },
        {
          field:'billing_street',
          value:state.newcc_street
        },
        {
          field:'billing_city',
          value:state.newcc_city
        },
        {
          field:'billing_state',
          value:state.newcc_state
        },
        {
          field:'billing_zip',
          value:state.newcc_zip
        }
      ];

      let callBack = (response)=>{console.log(response);
        let code= response.data.code;

        if(code==='00'){
          let ccProfiles = state.ccProfiles.slice();
          ccProfiles.push(response.data.data);

          let allCCProfiles = state.allCCProfiles.slice();
          allCCProfiles.push(response.data.data);

          setState({
            ccProfiles:ccProfiles,
            allCCProfiles:allCCProfiles,
            selectedLenderCCProfile:ccProfiles[ccProfiles.length-1].ID,
            selectedAppraiserCCProfile:ccProfiles[ccProfiles.length-1].ID,
            splitFee1CCProfiles:ccProfiles[ccProfiles.length-1].ID,
            splitFee2CCProfiles:ccProfiles[ccProfiles.length-1].ID,
            refundCCProfile:ccProfiles[ccProfiles.length-1].ID,
          });

          toggleRegisterPartialCreditCard();
          getBillingComments();

          if(state.mercuryCallBack){
            state.mercuryCallBack();
          }
        }
      };
      callBack = callBack.bind(this);

      let promises = httpPost('billing/creditCard/create', parameters, 'Credit card registered successfully.', 'Oops, something went wrong and could not register the credit card. Please try again later.', callBack);
      promises
        .then(
          function(result){
            //set loading equals to false so the function could be fire off once again
            setState({loading:false});
          }
        );
    }
  }

  //delete a fee
  const deleteFee = (id) => {
    let callBack = (response)=>{
      let code= response.data.code;

      if(code==='00'){
        let bills = state.unsettleBills.slice();
        let invoices = state.unsettleInvoices.slice();

        let targetID = '';
        let isLender = false;
        for(let i=0;i<bills.length;i++){
          for(let j=0;j<bills[i].fees.length;j++){
            if(bills[i].fees[j].ID===id){
              targetID = bills[i].ID;
              break;
            }
          }
        }

        for(let i=0;i<invoices.length;i++){
          for(let j=0;j<invoices[i].fees.length;j++){
            if(invoices[i].fees[j].ID===id){
              targetID = invoices[i].ID;
              isLender = true;
              break;
            }
          }
        }

        bills = response.data.data.unsettle_bills;
        invoices = response.data.data.unsettle_invoices;

        let found = false;
        if(targetID!==''){
          for(let i=0;i<bills.length;i++){
            if(bills[i].ID===targetID){
              found = true;
              setState({selectedBill:bills[i]});
              break;
            }
          }

          for(let i=0;i<invoices.length;i++){
            if(invoices[i].ID===targetID){
              found = true;
              setState({selectedInvoice:invoices[i]});
              break;
            }
          }
        }
        if(!found){
          if(isLender){
            setState({selectedInvoice:{fees:[],total:0}});
            toggleInvoiceDetails();
          }
          else{
            setState({selectedBill:{fees:[],total:0}});
            toggleBillDetails();
          }
        }

        let unsettleBills = response.data.data.unsettle_bills;
        let unsettleInvoices = response.data.data.unsettle_invoices;

        //update all settled & unsettle invoice + bill

        if(unsettleBills)
          setState({unsettleBills:unsettleBills});
        if(unsettleInvoices)
          setState({unsettleInvoices:unsettleInvoices});
        getBillingComments();
      }
    };
    callBack = callBack.bind(this);

    httpDelete('billing/fee/'+id,'Fee deleted successfully.','Oops, something went wrong and could not delete the fee. Please try again later.', callBack);
  }

  //post a comment
  const postComment = () => {
    let parameters = [
      {
        field:'comment',
        value:state.newComment
      },
      {
        field:'appraisal_fk',
        value:props.appraisalFk
      }
    ];

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        //clear the text area
        setState({newComment:''});

        //insert new comment into state
        let commentCreated = response.data.data;
        let existingComments = state.comments.slice();

        existingComments.unshift(commentCreated);

        setState({comments:existingComments});
      }
    };
    callBack = callBack.bind(this);

    httpPost('comment/billing/create', parameters, 'Billing note posted successfully.', 'Oops, something went wrong and could not post this note. Please try again later.', callBack);
    toggleBillingNote();
  }

  const getBillingComments = () => {
    let callBack = apiCallBack([{state:'comments', key:'data'}]);
    httpGet('comment/billing/get/'+props.appraisalFk, '', 'Oops, something went wrong and could not load billing notes for this appraisal. Please try again later.', callBack);
  }

  const getCommentTemplates = () => {
    let callBack = apiCallBack([{state:'commentTemplates', key:'data'}]);
    httpGet('template/get/'+props.appraisalFk, '', 'Oops, something went wrong and could not load your comment template.', callBack);
  }

  const downloadInvoice = () => {
    let parameters = [
      {
        field:'invoice',
        value:state.selectedInvoice.ID
      },

    ];
    let callBack = (response)=>{
      let byteCharacters = atob(response.data.data);
      let byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      let data = new Blob([byteArray]);
      FileSaver.saveAs(data, 'invoice.pdf');
    };
    callBack = callBack.bind(this);

    httpPost('file/invoice/unpaid/download', parameters, '', 'Oops, something went wrong and could not load AIR Cert. Please try again later.', callBack);
  }

  const downloadAllInOneInvoice = () => {
    let parameters = [
      {
        field:'appraisalFk',
        value:props.appraisalFk
      },
    ];
    let callBack = (response)=>{
      let byteCharacters = atob(response.data.data);
      let byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      let data = new Blob([byteArray]);
      FileSaver.saveAs(data, 'invoice.pdf');
    };
    callBack = callBack.bind(this);

    httpPost('file/invoice/allInOne/download', parameters, '', 'Oops, something went wrong and could not load Invoice. Please try again later.', callBack);
  }

  //render
  let spreadWatchQuestionnaire;

  if(state.showSpreadWatchQuestionnaire){
    spreadWatchQuestionnaire = <SpreadWatchQuestionnaire appraisal_fk={props.appraisalFk} callBack={()=>{setState({showSpreadWatchQuestionnaire:false});getBillingComments()}} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
  }

  let approveAuditLink;

  if(state.auditID){
    approveAuditLink =
    <div className="floating-audit-container">
      <div className="cursor-pointer" onClick={(e)=>approveAudit('yes')}>
        <u><i className="fa fa-check green-color"></i> I confirm this changes is accurate.</u>
      </div>
      <div className="cursor-pointer" onClick={(e)=>approveAudit('review')}>
        <u><i className="fa fa-question red-color"></i> Further research required.</u>
      </div>
    </div>
  }

  let appraiserCCProfiles, lenderCCProfiles, splitFee1CCProfiles, splitFee2CCProfiles;

  let ccProfiles = state.ccProfiles.map(
    (ccProfile,index)=>{
      if(state.selectedLenderPaymentMethod===ccProfile.payment_method)
        return(
          {value:ccProfile.ID, label:ccProfile.payment_method+' - '+ccProfile.billing_f_name+' '+ccProfile.billing_l_name+' - ****'+ccProfile.cc_last_four+' Exp: '+ccProfile.expiration_month+'/'+ccProfile.expiration_year+' - '+formatDateTime(ccProfile.datetime_created)}
        );
      else
        return null;
    }
  );

  let ccProfilesSelected = state.ccProfiles.map(
    (ccProfile,index)=>{
      if(state.selectedInvoice.method===ccProfile.payment_method)
        return(
          {value:ccProfile.ID, label:ccProfile.payment_method+' - '+ccProfile.billing_f_name+' '+ccProfile.billing_l_name+' - ****'+ccProfile.cc_last_four+' Exp: '+ccProfile.expiration_month+'/'+ccProfile.expiration_year+' - '+formatDateTime(ccProfile.datetime_created)}
        );
      else
        return null;
    }
  );

  let ccProfiles1 = state.ccProfiles.map(
    (ccProfile,index)=>{
      if(state.selectedSplitFee1PaymentMethod===ccProfile.payment_method)
        return(
          {value:ccProfile.ID, label:ccProfile.payment_method+' - '+ccProfile.billing_f_name+' '+ccProfile.billing_l_name+' - ****'+ccProfile.cc_last_four+' Exp: '+ccProfile.expiration_month+'/'+ccProfile.expiration_year+' - '+formatDateTime(ccProfile.datetime_created)}
        );
      else
        return null;
    }
  );


  if(!ccProfilesSelected)
    ccProfilesSelected = [];
  if(!ccProfilesSelected)
    ccProfiles = [];
  if(!ccProfilesSelected)
    ccProfiles1 = [];

  ccProfilesSelected = ccProfilesSelected.filter(x => x);
  ccProfiles = ccProfiles.filter(x => x);
  ccProfiles1 = ccProfiles1.filter(x => x);


  if(state.selectedLenderPaymentMethod==='Authorize.net'|| state.selectedLenderPaymentMethod==='Mercury CC')
    lenderCCProfiles =
    <MySelect
      modal={true}
      type="select"
      required={true}
      value={state.selectedLenderCCProfile}
      onChange={(v)=>setState({selectedLenderCCProfile:v})}
      options={ccProfiles}
    />
  if(state.selectedAppraiserPaymentMethod==='Authorize.net'|| state.selectedAppraiserPaymentMethod==='Mercury CC')
    appraiserCCProfiles =
    <MySelect
      modal={true}
      type="select"
      required={true}
      value={state.selectedAppraiserCCProfile}
      onChange={(v)=>setState({selectedAppraiserCCProfile:v})}
      options={ccProfiles}
    />
  if(state.selectedSplitFee1PaymentMethod==='Authorize.net'|| state.selectedSplitFee1PaymentMethod==='Mercury CC')
    splitFee1CCProfiles =
    <MySelect
      modal={true}
      type="select"
      required={true}
      value={state.splitFee1CCProfiles}
      onChange={(v)=>setState({splitFee1CCProfiles:v})}
      options={ccProfiles1}
    />
  if(state.selectedSplitFee2PaymentMethod==='Authorize.net'|| state.selectedSplitFee2PaymentMethod==='Mercury CC')
    splitFee2CCProfiles =
    <MySelect
      modal={true}
      type="select"
      required={true}
      value={state.splitFee2CCProfiles}
      onChange={(v)=>setState({splitFee2CCProfiles:v})}
      options={ccProfiles1}
    />


  let totalPayable =0, totalReceivable = 0;
  let unsettleBills;
  if(state.unsettleBills&&state.unsettleBills.length>0)
    unsettleBills = state.unsettleBills.map(
      (bill) =>{
        if(bill.status!=='Cancelled')
          totalPayable = totalPayable + parseInt(bill.total, 10);
        return(
          <div key={bill.ID} className="bill cursor-pointer" onClick={(e)=>selectBill(bill)}>
            <center>
              <b className="link-color"><i className="fa fa-home"></i>&nbsp;Payable #{bill.ID}</b>
              <br/><br/>
              <b className="red-color">**{bill.status}**</b>
              <br/>
              ${bill.total}
              <br/>
              By {bill.method} {bill.check_num}
              <div style={{height:'11px'}}>
                <font style={{fontSize:'10px'}}>&nbsp;</font>
              </div>
            </center>
          </div>
        )
      }
    );
  else{
    if(state.settledBills&&state.settledBills.length>0){
      unsettleBills = null;
    }
    else{
      unsettleBills =
      <div>
        <br/><br/>
        <center>**No payable.**</center>
      </div>;
    }
  }


  let settledInvoices;
  if(state.settledInvoices&&state.settledInvoices.length>0){
    if(state.stackInvoices){
      settledInvoices =
        <div className="invoice cursor-pointer stacked-invoices" onClick={showSettledInvoices}>
          <center>
            <b className="link-color"><i className="fa fa-home"></i>&nbsp;Receivable #***</b>
            <br/><br/>
            <b className="green-color">**Received**</b>
            <br/>
            $***
            <br/>
            By
          </center>
        </div>;

        for(let i=0;i<state.settledInvoices.length;i++){
          if(settledInvoices[i].status==='Loss')
            totalReceivable = totalReceivable+0;
          else if(settledInvoices[i].status!=='Cancelled'&&settledInvoices[i].status!=='Voided'&&settledInvoices[i].status!=='Charge Back'&&settledInvoices[i].status!=='Refunded'&&settledInvoices[i].status!=='Partially Received'&&settledInvoices[i].status!=='Reconcile'&&settledInvoices[i].status!=='Balance Due')
            totalReceivable = totalReceivable + parseInt(settledInvoices[i].total, 10);
          else if(settledInvoices[i].status.status==='Refunded')
            totalReceivable = totalReceivable - parseInt(settledInvoices[i].total, 10);
        }
    }
    else{
      settledInvoices = state.settledInvoices.map(
        (invoice) =>{
          if(invoice.status==='Loss')
            totalReceivable = totalReceivable+0;
          else if(invoice.status!=='Cancelled'&&invoice.status!=='Voided'&&invoice.status!=='Charge Back'&&invoice.status!=='Refunded'&&invoice.status!=='Partially Received'&&invoice.status!=='Reconcile'&&invoice.status!=='Balance Due')
            totalReceivable = totalReceivable + parseInt(invoice.total, 10);
          else if(invoice.status==='Refunded')
            totalReceivable = totalReceivable - parseInt(invoice.total, 10);
          let className = 'invoice';
          if(parseInt(invoice.shadow_invoice,10)!==0)
            className='shadow-invoice';
          return(
            <div key={invoice.ID} className={className+" cursor-pointer"} onClick={(e)=>selectInvoice(invoice)}>
              <center>
                <b className="link-color"><i className="fa fa-home"></i>&nbsp;Receivable #{invoice.ID}</b>
                <br/>
                <b className="green-color">**{invoice.status}**</b>
                <br/>
                ${formatNumber(invoice.total)}
                <br/>
                By {invoice.method}
                <br/>
                <font style={{fontSize:'10px'}}>{formatDate(invoice.datetime_paid)}</font>
              </center>
            </div>
          )
        }
      );
    }
  }

  let settledBills;
  if(state.settledBills&&state.settledBills.length>0){
    if(state.stackBills){
      settledBills =
        <div className="invoice cursor-pointer stacked-invoices" onClick={showSettledBills}>
          <center>
            <b className="link-color"><i className="fa fa-home"></i>&nbsp;Payable #***</b>
            <br/><br/>
            <b className="green-color">**Paid**</b>
            <br/>
            $***
            <br/>
            By
          </center>
        </div>;

        for(let i=0;i<state.settledBills.length;i++){
          if(state.settledBills[i].status!=='Cancelled')
            totalPayable = totalPayable + parseInt(state.settledBills[i].total, 10);
        }
    }
    else{
      settledBills = state.settledBills.map(
        (bill) =>{
          if(bill.status!=='Cancelled')
          totalPayable = totalPayable + parseInt(bill.total, 10);
          return(
            <div key={bill.ID} className="bill cursor-pointer" onClick={(e)=>selectBill(bill)}>
              <center>
                <b className="link-color"><i className="fa fa-home"></i>&nbsp;Payable #{bill.ID}</b>
                <br/><br/>
                <b className="green-color">**{bill.status}**</b>
                <br/>
                ${formatNumber(bill.total)}
                <br/>
                By {bill.method} {bill.check_num}
                <br/>
                <div style={{height:'11px'}}>
                  <font style={{fontSize:'10px'}}>{formatDate(bill.datetime_paid)}</font>
                </div>
              </center>
            </div>
          )
        }
      );
    }
  }


  let feeOptions;

  if(state.feeCategories&&state.feeCategories.length>0){
    feeOptions = state.feeCategories.map(
      (category,index)=>{
        return(
          {label:category.category, value:category.category}
        );
      }
    );
  }

  let action, splitFeePanel, button;

  if(state.splitFee&&state.selectedInvoice&&state.selectedInvoice.status==='Pending')
    splitFeePanel =
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-clone"></i> Split Fee
      </CardHeader>
      <CardBody>
        <Table className="table">
          <thead>
            <tr>
              <th width="20%">Paid By</th>
              <th width="30%">Payment method</th>
              <th width="30%">Description</th>
              <th width="20%">Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <MySelect
                  modal={true}
                  type="select"
                  required={true}
                  value={state.newSplitFee1CoveredBy}
                  onChange={(v)=>setState({newSplitFee1CoveredBy:v})}
                  options={[{label:"Broker",value:"Broker"},{label:"Borrower",value:"Borrower"},{label:"Lender",value:"Lender"},{label:"Client",value:"Client"},{label:"Paid Outside of Closing",value:"Paid Outside of Closing"},{label:"Paid at Closing",value:"Paid at Closing"}]}
                />
              </td>
              <td>
                <MySelect
                  modal={true}
                  type="select"
                  required={true}
                  value={state.selectedSplitFee1PaymentMethod}
                  onChange={(v)=>setState({selectedSplitFee1PaymentMethod:v})}
                  options={state.paymentMethods.map((paymentMethod)=>{
                    return {label:paymentMethod.payment_type, value:paymentMethod.payment_type};
                  })}
                />
                {splitFee1CCProfiles}
              </td>
              <td>
                <Input type="text" value={state.newSplitFee1Description} onChange={(e)=>setState({newSplitFee1Description:e.target.value})} className="form-control"/>
              </td>
              <td>
                <Input type="text" required={true} value={state.newSplitFee1Amount} onChange={(e)=>setState({newSplitFee1Amount:e.target.value})} className="form-control"/>
              </td>
            </tr>
            <tr>
              <td>
                <MySelect
                  modal={true}
                  type="select"
                  required={true}
                  value={state.newSplitFee2CoveredBy}
                  onChange={(v)=>setState({newSplitFee2CoveredBy:v})}
                  options={[{label:"Broker",value:"Broker"},{label:"Borrower",value:"Borrower"},{label:"Lender",value:"Lender"},{label:"Client",value:"Client"},{label:"Paid Outside of Closing",value:"Paid Outside of Closing"},{label:"Paid at Closing",value:"Paid at Closing"}]}
                />
              </td>
              <td>
                <MySelect
                  modal={true}
                  type="select"
                  required={true}
                  value={state.selectedSplitFee2PaymentMethod}
                  onChange={(v)=>setState({selectedSplitFee2PaymentMethod:v})}
                  options={state.paymentMethods.map((paymentMethod)=>{
                    return {label:paymentMethod.payment_type, value:paymentMethod.payment_type};
                  })}
                />
                {splitFee2CCProfiles}
              </td>
              <td>
                <Input type="text" value={state.newSplitFee2Description} onChange={(e)=>setState({newSplitFee2Description:e.target.value})} className="form-control"/>
              </td>
              <td>
                <Input type="text" required={true} value={state.newSplitFee2Amount} onChange={(e)=>setState({newSplitFee2Amount:e.target.value})} className="form-control"/>
              </td>
            </tr>
          </tbody>
        </Table>
        <br/>
        <div className="align-right">
          <Button color="warning" onClick={splitFee}>Split</Button>
        </div>
      </CardBody>
    </Card>

  if(state.selectedInvoice.method==='Authorize.net'&&state.selectedInvoice&&(state.selectedInvoice.status==='Pending'||state.selectedInvoice.status==='Hold'||state.selectedInvoice.status==='Declined')){
    button = <Button color="warning" onClick={chargeCC}>Charge</Button>;
    action =

    <div>
      <br/>
      {splitFeePanel}
      <br/>
      <br/><br/>
    </div>
  }
  else if(state.selectedInvoice&&state.selectedInvoice.method==='Authorize.net'&&state.selectedInvoice.status==='Partially Received'){
    button = <Button color="warning" onClick={chargeRemaining}>Charge Remaining</Button>;
  }
  else if(state.selectedInvoice.method==='Mercury CC'&&state.selectedInvoice&&(state.selectedInvoice.status==='Pending'||state.selectedInvoice.status==='Hold'||state.selectedInvoice.status==='Declined')){
    button = <Button color="warning" onClick={(e)=>checkMercuryCCProfile(chargeMercuryCC)}>Charge Mercury CC</Button>;
    action =

    <div>
      <br/>
      {splitFeePanel}
      <br/>
      <br/><br/>
    </div>
  }
  else if(state.selectedInvoice.method!=='Stripe'&&state.selectedInvoice.method!=='Authorize.net'){
    if(state.selectedInvoice&&(state.selectedInvoice.status==='Pending'||state.selectedInvoice.status==='Hold'))
      action =

      <div>
        {splitFeePanel}
        <br/>
        <br/><br/>
        <Table className="table">
          <thead>
            <tr>
              <th width="30%">
                Check / Confirmation #
              </th>
              <th width="40%">
                Amount
              </th>
              <th width="30%">

              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><Input type="text" value={state.transactionConfirmationNumber} onChange={(e)=>setState({transactionConfirmationNumber:e.target.value})}/></td>
              <td><Input type="text" value={state.transactionAmount} onChange={(e)=>setState({transactionAmount:e.target.value})}/></td>
              <td><div className="align-right"><Button color="warning" onClick={captureTransaction}>Capture Transaction</Button></div></td>
            </tr>
          </tbody>
        </Table>
      </div>
    else if(state.selectedInvoice&&(state.selectedInvoice.status==='Partially Received'))
      action =
        <div>
          <br/><br/>
          <Card>
            <CardHeader>Partially Received / Reconcile</CardHeader>
            <CardBody>
              <Table className="table">
                <thead>
                  <tr>
                    <th width="30%">
                      Check / Confirmation #
                    </th>
                    <th width="60%">
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><Input type="text" value={state.transactionConfirmationNumber} onChange={(e)=>setState({transactionConfirmationNumber:e.target.value})}/></td>
                    <td><div className="align-right"><Button color="warning" onClick={captureRemaindingPartialPayment}>Capture Remaining Partial Payment</Button></div></td>
                  </tr>
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </div>
  }

  let expirationYear = [];

  let currentYear = new Date().getFullYear();

  for(let i=0;i<20;i++){
    expirationYear.push(currentYear);
    currentYear++;
  }

  let allInvoices = state.unsettleInvoices.concat(state.settledInvoices);

  allInvoices.sort(compare);
  let invoiceTransactions = allInvoices;
  //let invoiceTransactions = allInvoices.concat(state.transactions);

  //invoiceTransactions.sort(compare);

  let transactionsFlow=[];
  let pendingInvoice = [];
  let balanceLeft = 0;
  for(let i=0;i<invoiceTransactions.length;i++){
    if(invoiceTransactions[i]){
      let tmp = {};
      if(invoiceTransactions[i].total){
        //invoice
        tmp.datetime_created = invoiceTransactions[i].datetime_created;
        tmp.amount = invoiceTransactions[i].total;
        tmp.payment_method = invoiceTransactions[i].method;
        tmp.type = 'Invoice';
        tmp.status = invoiceTransactions[i].status;

        let fees = [];
        for(let j=0;j<invoiceTransactions[i].fees.length;j++)
          fees.push(invoiceTransactions[i].fees[j].category);

        tmp.invoice_num = invoiceTransactions[i].ID;
        tmp.covered_by = invoiceTransactions[i].covered_by;
        tmp.shadow_invoice = invoiceTransactions[i].shadow_invoice;
        tmp.description = fees.join(', ');
        if(tmp.status==='Charge Back'){
          //do not add charge back and ignore
        }
        else if(tmp.status==='Pending'||tmp.status==='Declined'||tmp.status==='Balance Due'){
          balanceLeft = balanceLeft-(parseInt(tmp.amount,10));
          pendingInvoice.push(tmp.ID)
        }
        else if(tmp.status==='Overage'){
          balanceLeft = balanceLeft-(parseInt(tmp.amount,10));
          pendingInvoice.push(tmp.ID)
        }

        //locate the transaction
        let transactions = [];
        for(let k=0;k<state.transactions.length;k++){

          if(state.transactions[k].invoice_bill_fk===tmp.invoice_num&&(state.transactions[k].transaction_type!=='Expense' || (state.transactions[k].transaction_type==='Expense' && (state.transactions[k].purpose==='Refund' || state.transactions[k].purpose==='Void')))){
            let tmp2 = {};
            tmp2.transaction_ID = state.transactions[k].ID;
            tmp2.purpose = state.transactions[k].purpose;
            tmp2.transaction_amount = state.transactions[k].amount;
            tmp2.transaction_num = state.transactions[k].transaction_num;

            if(pendingInvoice.indexOf(state.transactions[k].invoice_bill_fk)!==-1)
              balanceLeft = balanceLeft+parseInt(state.transactions[k].amount,10);

            transactions.push(tmp2);
          }
        }
        tmp.transactions = transactions;
      }
      else{
        //transaction
        tmp.datetime_created = invoiceTransactions[i].datetime_created;
        tmp.amount = invoiceTransactions[i].amount;
        tmp.purpose = invoiceTransactions[i].purpose;
        tmp.payment_method = invoiceTransactions[i].payment_method;
        tmp.transaction_num = invoiceTransactions[i].transaction_num;
        tmp.invoice_fk = invoiceTransactions[i].invoice_bill_fk;
        tmp.ID = invoiceTransactions[i].ID;
        tmp.type = 'Transaction';

      }

      if(invoiceTransactions[i].shadow_invoice!=='0')
        continue;
      transactionsFlow.push(tmp);
    }
  }

  let ccProfileForInvoice;
  let ccLink;

  if(state.selectedInvoice.method==='Authorize.net'){
    ccLink = <div className="display-inline" id="ccLink">
      <i className="fa fa-credit-card"/> <NavLink onClick={copyPaymentLink} to="#">Payment Link</NavLink>
      <UncontrolledTooltip className="mytooltip" delay={{ "show": 0, "hide": 0 }} placement="right" target={"ccLink"}>
        This payment link will update the credit card profile on this receivable. If you intend to update all existing receivable that is Pending/Decline please use the payment link in COMMUNICATION - Comment
      </UncontrolledTooltip>
    </div>
    ccProfileForInvoice = <div>
      <br/>
      <label>Credit Card Profile:</label><br/>
      <MySelect
        modal={true}
        type="select"
        required={true}
        value={state.selectedInvoice.profile_fk}
        onChange={(v)=>updateInvoiceProfileFk(v)}
        options={ccProfilesSelected}
      />
    </div>;
  }
  else if(state.selectedInvoice.method==='Mercury CC'){
    ccProfileForInvoice = <div>
      <br/>
      <label>Credit Card Profile:</label><br/>
      <MySelect
        modal={true}
        type="select"
        required={true}
        value={state.selectedInvoice.profile_fk}
        onChange={(v)=>updateInvoiceProfileFk(v)}
        options={ccProfilesSelected}
      />
    </div>;
  }

  let invoiceAction;

  if(state.selectedInvoice&&state.selectedInvoice.status==='Pending')
    invoiceAction =
      <div>
        <i className="fa fa-arrows-h"/> <NavLink to="#" onClick={(e)=>setState({splitFee:!state.splitFee})}>Split fee</NavLink>&nbsp;&nbsp;
        <i className="fa fa-cloud-download"/> <NavLink to="#" onClick={downloadInvoice}>Download Invoice</NavLink>&nbsp;&nbsp;
        {ccLink}
      </div>;

  let transactionAction;
  if(state.selectedTransaction.payment_method==='Authorize.net'&&state.selectedTransaction.status==='Received')
    transactionAction =
    <div className="display-inline">
      <NavLink to="#" onClick={(e)=>downloadFile(state.selectedTransaction.ID)}><i className="fa fa-file-pdf-o green-color"></i> Download</NavLink>&nbsp;
      <NavLink to="#" onClick={(e)=>setTransactionTab('2')}><i className="fa fa-times-circle orange-color"></i> Refund</NavLink>&nbsp;
      <NavLink to="#" onClick={(e)=>setTransactionTab('3')}><i className="fa fa-mail-reply-all red-color"></i> Charge Back</NavLink>&nbsp;
      <NavLink to="#" onClick={(e)=>{chargeBackResolved(state.selectedTransaction.ID)}}><i className="fa fa-refresh red-color"></i> Resolve Charge Back</NavLink>&nbsp;
      <NavLink to="#" onClick={(e)=>setTransactionTab('4')}><i className="fa fa-times red-color"></i> Remove Transaction</NavLink>&nbsp;
    </div>
  else if(state.selectedTransaction.payment_method==='Stripe'&&state.selectedTransaction.status==='Received')
    transactionAction =
    <div className="display-inline">
      <NavLink to="#" onClick={(e)=>downloadFile(state.selectedTransaction.ID)}><i className="fa fa-file-pdf-o"></i> Download</NavLink>&nbsp;
      <NavLink to="#" onClick={(e)=>setTransactionTab('2')}><i className="fa fa-times-circle orange-color"></i> Refund</NavLink>&nbsp;
      <NavLink to="#" onClick={(e)=>setTransactionTab('4')}><i className="fa fa-times red-color"></i> Remove Transaction</NavLink>&nbsp;
    </div>
  else if(state.selectedTransaction.payment_method==='Mercury CC'&&state.selectedTransaction.status==='Received')
    transactionAction =
    <div className="display-inline">
      <NavLink to="#" onClick={(e)=>downloadFile(state.selectedTransaction.ID)}><i className="fa fa-file-pdf-o"></i> Download</NavLink>&nbsp;
      <NavLink to="#" onClick={(e)=>setTransactionTab('2')}><i className="fa fa-times-circle orange-color"></i> Refund</NavLink>&nbsp;
      <NavLink to="#" onClick={(e)=>setTransactionTab('3')}><i className="fa fa-mail-reply-all red-color"></i> Charge Back</NavLink>&nbsp;
      <NavLink to="#" onClick={(e)=>{chargeBackResolved(state.selectedTransaction.ID)}}><i className="fa fa-refresh red-color"></i> Resolve Charge Back</NavLink>&nbsp;
      <NavLink to="#" onClick={(e)=>setTransactionTab('4')}><i className="fa fa-times red-color"></i> Remove Transaction</NavLink>&nbsp;
    </div>
  else
    transactionAction =
    <div className="display-inline">
      <NavLink to="#" onClick={(e)=>downloadFile(state.selectedTransaction.ID)}><i className="fa fa-file-pdf-o"></i> Download</NavLink>
      <NavLink to="#" onClick={(e)=>setTransactionTab('4')}><i className="fa fa-times red-color"></i> Remove Transaction</NavLink>&nbsp;
    </div>

  let balance;

  if(balanceLeft>=0)
    balance =
    <Row>
      <Col sm="6">

      </Col>
      <Col sm="6">
        <font color="green">${formatNumber(balanceLeft)}</font>
      </Col>
    </Row>
  else if(balanceLeft<0)
    balance =
    <Row>
      <Col sm="6">
          <font color="red">${formatNumber(balanceLeft)}</font>
      </Col>
      <Col sm="6">
      </Col>
    </Row>

  let refundPaymentMethod, refundCCProfile;

  if(state.refundNewPaymentMethod==='Authorize.net'||state.refundNewPaymentMethod==='MercuryCC'){
    refundCCProfile =
    <div>
      <br/>
      <MySelect
        modal={true}
        type="select"
        required={true}
        value={state.refundCCProfile}
        onChange={(v)=>setState({refundCCProfile:v})}
        options={ccProfiles}
      />
    </div>
  }

  if(state.refundReason==='3')
    refundPaymentMethod =
    <div>
      <Row>
        <Col sm="4">
          <label>New payment method</label>
        </Col>
        <Col sm="8">
          <MySelect
            modal={true}
            type="select"
            required={true}
            value={state.refundNewPaymentMethod}
            onChange={(v)=>setState({refundNewPaymentMethod:v})}
            options={state.paymentMethods.map((paymentMethod)=>{
              return {label:paymentMethod.payment_type, value:paymentMethod.payment_type};
            })}
          />
          {refundCCProfile}
        </Col>
      </Row>
      <br/>
    </div>

  let templates;

  if(state.commentTemplates){
    templates = state.commentTemplates.map(
      (template)=>{
        return(
          {value:template.ID, label:template.name}
        );
      }
    );
  }

  let comments = state.comments.map(
    (comment,outterIndex)=>{

      if(comment.internal==='yes'){

        return (
          <Row key={comment.ID}>
            <Col sm="12">
              <div className="admin-comment-container">
                <div className="admin-name">{comment.author}</div>
                {comment.comment}
                <br/>
                <Row>
                  <Col sm="6">
                    <div className="margin-top comment-date display-inline">{formatDateTime(comment.datetime_created)}&nbsp;&nbsp;</div>
                  </Col>
                  <Col sm="6" className="align-right">
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        );
      }
      else{
        return(
          <Row key={comment.ID}>
            <Col sm="12">
              <div className="nonadmin-comment-container">
                <div className="nonadmin-name">{comment.author}</div>
                <div className="display-inline align-left">
                  {comment.comment}
                </div>
                <br/>
                <Row>
                  <Col sm="6" className="align-left">
                    <div className="margin-top comment-date display-inline">{formatDateTime(comment.datetime_created)}&nbsp;&nbsp;</div>
                  </Col>
                  <Col sm="6" className="align-right">
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        );
      }
    }
  );

  let invoiceStatus;

  if(state.role==='super_admin'||state.role==='admin_manager'||state.role==='admin')
    invoiceStatus =
    <div>
      <br/>
      <Row>
        <Col sm="12">
          <label>Status:</label>
          <MySelect
            modal={true}
            type="select"
            required={true}
            value={state.selectedInvoice.status}
            onChange={(v)=>checkForInvoiceGrayResolution(state.selectedInvoice.status, v)}
            options={state.invoiceStatuses.map((status)=>{
              return {label:status.status, value:status.status};
            })}
          />
        </Col>
      </Row>
    </div>;



  let authorizeManualCapture;

  if(state.selectedInvoice.method==='Authorize.net'&&state.selectedInvoice.status!=='Balance Due'&&state.selectedInvoice.status!=='Received'){
    authorizeManualCapture = <div className="my-well">
      <i>For transaction already captured on Authorize.net please enter the transaction ID below and hit "Capture" button instead</i>
      <br/>
      <label>Authorize.net Transaction ID:</label><br/>
      <Row>
        <Col sm="8">
          <Input type="text" value={state.manualTransactionID} onChange={(e)=>setState({manualTransactionID:e.target.value})}/>
        </Col>
        <Col sm="4" className="align-right">
          <Button onClick={captureAuthorizeManualTransaction} color="warning">Record Transaction</Button>
        </Col>
      </Row>
    </div>;
  }

  let mercuryCCManualCapture;

  if(state.selectedInvoice.method==='Mercury CC'&&state.selectedInvoice.status!=='Balance Due'&&state.selectedInvoice.status!=='Received'){
    mercuryCCManualCapture = <div className="my-well">
      <i>For transaction already captured on Mercury portal please enter the transaction # below and hit "Capture" button instead</i>
      <br/>
      <label>Mercury Transaction #:</label><br/>
      <Row>
        <Col sm="8">
          <Input type="text" value={state.transactionConfirmationNumber} onChange={(e)=>setState({transactionConfirmationNumber:e.target.value})}/>
        </Col>
        <Col sm="4" className="align-right">
          <Button onClick={(e)=>checkMercuryCCProfile(captureTransaction)} color="warning">Record Transaction</Button>
        </Col>
      </Row>
    </div>;
  }

  let stripeManualCapture;

  if(state.selectedInvoice.method==='Stripe'&&state.selectedInvoice.status!=='Balance Due'&&state.selectedInvoice.status!=='Received'){
    stripeManualCapture = <div className="my-well">
      <i>For transaction already captured on Stripe please enter the transaction ID below and hit "Capture" button instead</i>
      <br/>
      <label>Stripe Transaction ID:</label><br/>
      <Row>
        <Col sm="8">
          <Input type="text" value={state.manualTransactionID} onChange={(e)=>setState({manualTransactionID:e.target.value})}/>
        </Col>
        <Col sm="4" className="align-right">
          <Button onClick={captureStripeManualTransaction} color="warning">Record Transaction</Button>
        </Col>
      </Row>
    </div>;
  }

  let billStatus;

  if(state.role==='super_admin'||state.role==='admin_manager'||state.role==='admin')
    billStatus =
    <div>
      <br/>
      <Row>
        <Col sm="12">
          <label>Status:</label>
          <MySelect
            modal={true}
            type="select"
            required={true}
            value={state.selectedBill.status}
            onChange={(v)=>updateBillStatus(v)}
            options={state.billStatuses.map((status)=>{
              return {label:status.status, value:status.status};
            })}
          />
        </Col>
      </Row>
    </div>;

  let refundedTransaction;

  if(state.refundReason==='5'){
    refundedTransaction = <div>
      <Row>
        <Col sm="4">
          <label>Transaction #</label>
        </Col>
        <Col sm="4">
          <Input required={true} type="text" value={state.refundedTransactionNum} onChange={(e)=>setState({refundedTransactionNum:e.target.value})}/>
        </Col>
      </Row>
      <br/>
    </div>;
  }

  let priorityLink = <div className="display-inline">
    <NavLink to="#" onClick={(e)=>updatePriority(props.appraisalFk)}><i className="fa fa-plus green-color"></i> Appraiser Payment Priority</NavLink>&nbsp;&nbsp;
  </div>

  if(state.priority==='yes')
    priorityLink = <div className="display-inline">
      <NavLink to="#" onClick={(e)=>updatePriority(props.appraisalFk)}><i className="fa fa-minus red-color"></i> Appraiser Payment Priority</NavLink>&nbsp;&nbsp;
    </div>

  let transactionDetails;

  if(state.selectedTransaction.payment_method==='Authorize.net')
    transactionDetails = <Row>
      <Col sm="4">
        <label>Last 4 of credit card #</label>
      </Col>
      <Col sm="8">
        ****{state.selectedTransaction.cc_last_four}
      </Col>
    </Row>;

  return <div>
    {approveAuditLink}
    {spreadWatchQuestionnaire}
    <Card  style={{borderTop:'0px'}}>
      <CardHeader className="header-color">
        <i className="fa fa-home"></i>&nbsp;{props.appraisal.reference_num} - {props.appraisal.property_street} {props.appraisal.property_city}, {props.appraisal.property_state} {props.appraisal.property_zip} - {props.appraisal.borrower_f_name} {props.appraisal.borrower_l_name}
      </CardHeader>
      <CardBody>
      {
        props.messages.map(
          (message, index)=>{
            if(message.link){
              return(<a key={index} href={'/'+message.link+'/'+message.ID} target="_blank" rel="noreferrer"><Alert key={index} color="warning" className="my-alert">
                  <i className="fa fa-information"></i> {message.msg}
                </Alert></a>);
            }

            return(<Alert key={index} color="warning" className="my-alert">
                <i className="fa fa-information"></i> {message.msg}
              </Alert>);
          }
        )
      }
      </CardBody>
    </Card>
    <br/>

    <b className="link-color">Profit: </b> ${formatNumber(totalReceivable)} (Receivable) - ${formatNumber(totalPayable)} (Payable) = <b className="link-color">${formatNumber((totalReceivable-totalPayable))}</b>

    <div className="align-right">
      {priorityLink}
      <NavLink to="#" onClick={(e)=>downloadAllInOneInvoice()}><i className="fa fa-file"></i> Invoice</NavLink>&nbsp;&nbsp;
      <NavLink to="#" onClick={toggleViewCreditCard}><i className="fa fa-credit-card"></i> Registered credit card</NavLink>&nbsp;&nbsp;
      <NavLink to="#" onClick={toggleRegisterCreditCard}><i className="fa fa-plus green-color"></i> New credit card</NavLink>&nbsp;&nbsp;
      <NavLink to="#" onClick={toggleSpreadWatchQuestionnaire}><i className="fa fa-question green-color"></i> Spread Questionnaire</NavLink>
    </div>
    <Row>
      <Col sm="4">
        <div className="card">
          <div className="card-header header-color">
            <i className="fa fa-sticky-note"></i>&nbsp;Payable
          </div>
          <div className="card-body">
            <div style={{height:'45px'}}>&nbsp;</div>
            <Row>
              <Col sm="12">
                <div className="horizontal-scroll-container">
                  {unsettleBills}
                  {settledBills}
                </div>
              </Col>
            </Row>

            <div className="my-divider">&nbsp;</div>

            <Button color="warning" className="form-control" onClick={newAppraiserFeeToggle}><i className="fa fa-plus green-color"></i>&nbsp;Add New Payable</Button>
          </div>
        </div>
      </Col>

      <Col sm="8">
        <Modal className="my-modal" isOpen={state.billingNoteDropDownOpen} toggle={toggleBillingNote} >
          <ModalHeader hidden={true} toggle={toggleBillingNote}></ModalHeader>
          <ModalBody>
            <center>
              <h5><i className="fa fa-comments"></i> New Comment</h5>
            </center>
            <br/>
            <div className="form-group">
              <span><label>Comment:</label></span>
              <Input type="textarea" className="form-control comment-textarea" value={state.newComment} id="comment" placeholder="Say something..." style={{resize:'none'}} rows="8" onChange={(e)=>setState({newComment:e.target.value})}></Input>
            </div>

            <Row>
              <Col sm="6">
                <label>Template:</label>
              </Col>
              <Col sm="6" className="align-right">
                <NavLink to="/setting">Manage my template</NavLink>
              </Col>
            </Row>
            <br/>

            <MySelect
              modal={true}
              type="select"
              onChange={(v)=>commentTemplatesOnChange(v)}
              options={templates}
            />
            <label>Recipients to notify:</label>
            <br/>

            <div className="my-diviver"></div>
            <center>
              <Button color="warning" onClick={postComment} ><i className="fa fa-check"></i>&nbsp;Post</Button>{' '}
              <Button color="info" onClick={toggleBillingNote}>Close</Button>
            </center>
          </ModalBody>
        </Modal>

        <Card>
          <CardHeader className="header-color">
            <i className="fa fa-comments"></i> Billing notes
          </CardHeader>
          <CardBody>
            <div className="medium-scroll-container">
              {comments}
            </div>
            <div className="my-divider"></div>
            <div className="align-right"><Button color="warning" className="cursor-pointer form-control" onClick={toggleBillingNote}><i className="fa fa-plus green-color"></i> Post note</Button></div>
          </CardBody>
        </Card>
      </Col>
    </Row>

    <Modal className="my-modal" isOpen={state.viewCreditCardDropDownOpen} toggle={toggleViewCreditCard} >
      <ModalHeader hidden={true} toggle={toggleViewCreditCard}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-home"></i> Credit card profile</h5>
        </center>

        <br/>

        <div className="horizontal-scroll-container">
          {
            state.ccProfiles.map(
              (cc,index)=>{
                let cardType;

                if(cc.card_type&&cc.card_type!=='')
                  cardType = cc.card_type[0].toUpperCase()+cc.card_type.slice(1);
                return(
                  <div key={index} className="credit-card">
                    <div><i className="fa fa-credit-card-alt"></i>&nbsp;<font color="#484A43"><b>{cc.payment_method==='Mercury CC'?<font color="red"><b>Mercury</b></font>:null}{cardType}</b></font></div>
                    <br/>
                    <div>XXXX XXXX XXXX {cc.cc_last_four}</div>
                    <div>
                      {cc.expiration_year+' - '+cc.expiration_month}
                    </div>
                    <div><font color="#484A43">{cc.billing_f_name+' '+cc.billing_l_name}</font></div>

                    <div className="align-right"><small><i>Created {formatDateTime(cc.datetime_created)}</i></small></div>
                  </div>
                )
              }
            )
          }
        </div>
        <br/>
        <center>
          <Button color="info" onClick={toggleViewCreditCard}>Close</Button>
        </center>
      </ModalBody>
    </Modal>

    <Modal className="my-modal" isOpen={state.registerCreditCardDropDownOpen} toggle={toggleRegisterCreditCard} >
      <ModalHeader hidden={true} toggle={toggleRegisterCreditCard}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-home"></i> Register new credit card profile</h5>
        </center>
        <br/>
        <div className="align-right"><NavLink to="#" onClick={(e)=>{toggleRegisterCreditCard();toggleRegisterPartialCreditCard()}}>Import Credit Card Profile (Last 4 Card # Only)</NavLink></div>
        {/* <div>{!state.ccEnable&&<h6><font color="red">{'*** '+state.ccErrorMsg+' ***'}</font></h6>}</div> */}
        <form onSubmit={registerCC}>
          <Card>
            <CardHeader className="header-color">
              <i className="fa fa-user"></i> Card holder information
            </CardHeader>
            <CardBody>

              <Row>
                <Col sm="6">
                  <Input type="text" placeholder="First name*" value={state.newcc_first_name} onChange={(e)=>setState({newcc_first_name:e.target.value})}/>
                </Col>
                <Col sm="6">
                  <Input type="text" placeholder="Last name*" value={state.newcc_last_name} onChange={(e)=>setState({newcc_last_name:e.target.value})}/>
                </Col>
                <Col sm="6">
                  <br/>
                  <Input type="text" placeholder="Email (optional, for receipt)" value={state.newcc_email} onChange={(e)=>setState({newcc_email:e.target.value})}/>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <br/>
          <Card>
            <CardHeader className="header-color">
              <i className="fa fa-credit-card"></i> Credit card information
            </CardHeader>
            <CardBody>
              <Row>
                <Col sm="6">
                  <MySelect
                    modal={true}
                    type="select"
                    required={true}
                    value={state.newcc_card_type}
                    onChange={(v)=>setState({newcc_card_type:v})}
                    options={[{label:"Visa",value:"visa"},{label:"Master Card",value:"mastercard"},{label:"American Express",value:"amex"},{label:"Dinners Club",value:"dinersclub"},{label:"Discover",value:"discover"}]}
                  />
                  <div>{!state.ccTypeValid&&<h6><font color="red">{'*** '+state.ccTypeError+' ***'}</font></h6>}</div>
                </Col>
              </Row>
              <br/>
              <Row>
                <Col sm="7">
                  <Input type="text" placeholder="Credit card number*" value={state.newcc_card_number} onChange={(e)=>{setState({newcc_card_number:e.target.value})}}/>
                  <div>{!state.ccNumberValid&&<h6><font color="red">{'*** '+state.ccNumberError+' ***'}</font></h6>}</div>
                </Col>
                <Col sm="5">
                  <Input type="text" placeholder="CVC*" value={state.newcc_cvc} onChange={(e)=>setState({newcc_cvc:e.target.value})}/>
                </Col>
              </Row>
              <br/>
              <Row>
                <Col sm="6">
                  <MySelect
                    modal={true}
                    required={true}
                    type="select"
                    value={state.newcc_expiration_month}
                    onChange={(v)=>setState({newcc_expiration_month:v})}
                    options={[
                      {label:"Select expiration month",value:""},
                      {value:"01",label:"January"},
                      {value:"02",label:"Febuary"},
                      {value:"03",label:"March"},
                      {value:"04",label:"April"},
                      {value:"05",label:"May"},
                      {value:"06",label:"June"},
                      {value:"07",label:"July"},
                      {value:"08",label:"August"},
                      {value:"09",label:"September"},
                      {value:"10",label:"October"},
                      {value:"11",label:"November"},
                      {value:"12",label:"December"},
                    ]}
                  />
                </Col>
                <Col sm="6">
                  <MySelect
                    modal={true}
                    type="select"
                    required={true}
                    value={state.newcc_expiration_year}
                    onChange={(v)=>setState({newcc_expiration_year:v})}
                    options={[{label:"Select expiration year",value:""}].concat(expirationYear.map((year)=>{
                      return {label:year, value:year};
                    }))}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
          <br/>
          <Card>
            <CardHeader className="header-color">
              <i className="fa fa-bank"></i> Billing address
            </CardHeader>
            <CardBody>
              <Row>
                <Col sm="8">
                  <Input type="text" placeholder="Street*" value={state.newcc_street} onChange={(e)=>setState({newcc_street:e.target.value})}/>
                </Col>
              </Row>
              <br/>
              <Row>
                <Col sm="4">
                  <Input type="text" placeholder="City*" value={state.newcc_city} onChange={(e)=>setState({newcc_city:e.target.value})}/>
                </Col>
                <Col sm="5">
                  <MySelect
                    type="select"
                    modal={true}
                    required={true}
                    value={state.newcc_state}
                    onChange={(v)=>setState({newcc_state:v})}
                    options={state.states.map((stateName)=>{
                      return {label:stateName.value, value:stateName.value};
                    })}
                  />
                </Col>
                <Col sm="3">
                  <Input type="text" placeholder="Zip*" value={state.newcc_zip} onChange={(e)=>setState({newcc_zip:e.target.value})}/>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <br/><br/>

          <center>
            <Button color="info" onClick={toggleRegisterCreditCard}>Close</Button>&nbsp;&nbsp;
            <Button color="warning" disabled={state.loading} type="submit">Register</Button>
          </center>
        </form>
      </ModalBody>
    </Modal>

    <Modal className="my-modal" isOpen={state.registerPartialCreditCardDropDownOpen} toggle={toggleRegisterPartialCreditCard} >
      <ModalHeader hidden={true} toggle={toggleRegisterPartialCreditCard}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-home"></i> Import new credit card profile</h5>
        </center>
        <br/>
        {/* <div>{!state.ccEnable&&<h6><font color="red">{'*** '+state.ccErrorMsg+' ***'}</font></h6>}</div> */}
        <form onSubmit={registerPartialCC}>
          <Card>
            <CardHeader className="header-color">
              <i className="fa fa-user"></i> Card holder information
            </CardHeader>
            <CardBody>
              <Row>
                <Col sm="6">
                  <MySelect
                    modal={true}
                    type="select"
                    value={state.partialCCPlatform}
                    onChange={(v)=>setState({partialCCPlatform:v})}
                    options={[{label:"Authorize.net",value:"Authorize.net"},{label:"Mercury CC",value:"Mercury CC"}]}
                  />
                </Col>
              </Row>
              <br/>
              <Row>
                <Col sm="6">
                  <Input type="text" placeholder="First name*" value={state.newcc_first_name} onChange={(e)=>setState({newcc_first_name:e.target.value})}/>
                </Col>
                <Col sm="6">
                  <Input type="text" placeholder="Last name*" value={state.newcc_last_name} onChange={(e)=>setState({newcc_last_name:e.target.value})}/>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <br/>
          <Card>
            <CardHeader className="header-color">
              <i className="fa fa-credit-card"></i> Credit card information
            </CardHeader>
            <CardBody>
              <Row>
                <Col sm="6">
                  <MySelect
                    modal={true}
                    type="select"
                    required={true}
                    value={state.newcc_card_type}
                    onChange={(v)=>setState({newcc_card_type:v})}
                    options={[{label:"Visa",value:"visa"},{label:"Master Card",value:"mastercard"},{label:"American Express",value:"amex"},{label:"Dinners Club",value:"dinersclub"},{label:"Discover",value:"discover"}]}
                  />
                  <div>{!state.ccTypeValid&&<h6><font color="red">{'*** '+state.ccTypeError+' ***'}</font></h6>}</div>
                </Col>
              </Row>
              <br/>
              <Row>
                <Col sm="7">
                  <Input type="text" placeholder="Last 4 CC #*" maxLength="4" value={state.newcc_card_number1} onChange={(e)=>setState({newcc_card_number1:e.target.value})}/>
                </Col>
              </Row>
              <br/>
              <Row>
                <Col sm="6">
                  <MySelect
                    modal={true}
                    required={true}
                    type="select"
                    value={state.newcc_expiration_month}
                    onChange={(v)=>setState({newcc_expiration_month:v})}
                    options={[
                      {label:"Select expiration month",value:""},
                      {value:"01",label:"January"},
                      {value:"02",label:"Febuary"},
                      {value:"03",label:"March"},
                      {value:"04",label:"April"},
                      {value:"05",label:"May"},
                      {value:"06",label:"June"},
                      {value:"07",label:"July"},
                      {value:"08",label:"August"},
                      {value:"09",label:"September"},
                      {value:"10",label:"October"},
                      {value:"11",label:"November"},
                      {value:"12",label:"December"},
                    ]}
                  />
                </Col>
                <Col sm="6">
                  <MySelect
                    modal={true}
                    type="select"
                    required={true}
                    value={state.newcc_expiration_year}
                    onChange={(v)=>setState({newcc_expiration_year:v})}
                    options={[{label:"Select expiration year",value:""}].concat(expirationYear.map((year)=>{
                      return {label:year, value:year};
                    }))}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>

          <br/><br/>

          <center>
            <Button color="info" onClick={toggleRegisterPartialCreditCard}>Close</Button>&nbsp;&nbsp;
            <Button color="warning" disabled={state.loading} type="submit">Register</Button>
          </center>
        </form>
      </ModalBody>
    </Modal>

    <Modal className="my-modal-wide" isOpen={state.showInvoiceDetailsDropDownOpen} toggle={toggleInvoiceDetails} >
      <ModalHeader hidden={true} toggle={toggleInvoiceDetails}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-home"></i> Receivable #{state.selectedInvoice.ID} {formatDate(state.selectedInvoice.datetime_created)}</h5>
        </center>

        <br/>

        <Row>
          <Col sm="6">
            <label>Payment method:</label><br/>
            <MySelect
              modal={true}
              type="select"
              required={true}
              value={state.selectedInvoice.method}
              onChange={(v)=>updateInvoiceMethod(v)}
              options={state.paymentMethods.map((paymentMethod)=>{
                if(paymentMethod.target==='invoice'||paymentMethod.target==='all')
                  return {label:paymentMethod.payment_type, value:paymentMethod.payment_type};
                else
                  return null;
              }).filter(x=>x)}
            />
            {ccProfileForInvoice}
            <br/>
            <label>Paid by</label><br/>
            <MySelect
              modal={true}
              type="select"
              required={true}
              value={state.selectedInvoice.covered_by}
              onChange={(v)=>updateInvoiceCoveredBy(v)}
              options={[{label:"Broker",value:"Broker"},{label:"Borrower",value:"Borrower"},{label:"Lender",value:"Lender"},{label:"Client",value:"Client"},{label:"Paid Outside Of Closing",value:"Paid Outside Of Closing"},{label:"Paid at Closing",value:"Paid at Closing"}]}
            />
            {invoiceStatus}
            <br/>
            <Table className="table">
              <tbody>
                {
                  state.selectedInvoice.fees.map(
                    (fee,index)=>{
                      let icon;
                      if(state.selectedInvoice&&state.selectedInvoice.status==='Pending'){
                        icon = <div className="display-inline" onClick={()=>deleteFee(fee.ID)}><i className="red-color cursor-pointer fa fa-trash"></i>&nbsp;</div>
                      }
                      else
                        icon = <div className="display-inline"><i className="fa fa-lock"></i>&nbsp;</div>
                      return(
                        <tr key={index}>
                          <td width="80%">{icon}{fee.category} - {fee.description}</td>
                          <td align="right">${formatNumber(fee.amount)}</td>
                        </tr>
                      )
                    }
                  )
                }
                <tr>
                  <td><b>Total</b></td><td align="right">${formatNumber(state.selectedInvoice.total)}</td>
                </tr>
              </tbody>
            </Table>
            {mercuryCCManualCapture}
            {authorizeManualCapture}
            {stripeManualCapture}
            {action}
          </Col>
          <Col sm="6">
            <i>Leave a note about this receivable, it will be saved once you close this pop up and will post to billing note at the same time</i><br/>
            <label>Note</label>
            <Input className="non-editable-textarea yellow-background-textarea margin-bottom" rows="20" type="textarea" value={state.selectedInvoice.note} onChange={(e)=>{setState({selectedInvoice:Object.assign({},state.selectedInvoice, {note:e.target.value})})}}/>

            {invoiceAction}
          </Col>
        </Row>
        <br/>
        <center>
          <Button color="info" onClick={toggleInvoiceDetails}>Close</Button>&nbsp;&nbsp;
          {button}
        </center>
      </ModalBody>
    </Modal>

    <Modal className="my-modal" isOpen={state.showBillDetailsDropDownOpen} toggle={toggleBillDetails} >
      <ModalHeader hidden={true} toggle={toggleBillDetails}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-home"></i> Payable #{state.selectedBill.ID} {formatDate(state.selectedBill.datetime_created)}</h5>
        </center>

        <br/>
        <div style={{verticalAlign:'bottom'}}>
          <label>Payment method</label><br/>
          <MySelect
            modal={true}
            type="select"
            required={true}
            value={state.selectedBill.method}
            onChange={(v)=>updateBillMethod(v)}
            options={state.paymentMethods.map((paymentMethod)=>{
              if(paymentMethod.target==='bill'||paymentMethod.target==='all')
                return {label:paymentMethod.payment_type, value:paymentMethod.payment_type};
              else
                return null;
            }).filter(x=>x)}
          />
          <br/>
          <label>Transaction #:</label><br/>
          <MyXEditable
            type="text"
            value={state.selectedBill.check_num?state.selectedBill.check_num:''}
            updateFunc={
              generateUpdateFunction('billing/payable/check/update',[{field:'field',value:'check_num'},{field:'ID',value:state.selectedBill.ID}],'Information updated successfully.', 'Failed to upate the information, please try again later.', updateFieldCallBack)
            }
          />
          <br/>
          <label>Paid Date:</label><br/>
          <MyXEditable
            type="datetime"
            value={state.selectedBill.datetime_paid?state.selectedBill.datetime_paid:'0000-00-00 00:00:00'}
            updateFunc={
              generateUpdateFunction('billing/payable/date/update',[{field:'field',value:'datetime_paid'},{field:'ID',value:state.selectedBill.ID}],'Information updated successfully.', 'Failed to upate the information, please try again later.', updateFieldCallBack)
            }
          />
        </div>
        <br/>
        {billStatus}
        <br/><br/>
        <Table className="table">
          <tbody>
            {
              state.selectedBill.fees.map(
                (fee,index)=>{
                  let icon = <div className="display-inline" onClick={()=>deleteFee(fee.ID)}><i className="red-color cursor-pointer fa fa-trash"></i>&nbsp;</div>
                  return(
                    <tr key={index}>
                      <td width="80%">{icon}{fee.category} - {fee.description}</td>
                      <td align="right">${formatNumber(fee.amount)}</td>
                    </tr>
                  )
                }
              )
            }
            <tr>
              <td><b>Total</b></td><td align="right">${formatNumber(state.selectedBill.total)}</td>
            </tr>
            <tr>
              <td colspan="2"><div className="my-divider">&nbsp;</div></td>
            </tr>
            <tr>
              <td colspan="2">
                <i>Capture the transaction manually by entering the check number / receipt number below</i><br/>
                <br/>
                <Row>
                  <Col sm="8">
                    <label>Check # / Receipt #</label><br/>
                    <Input type="text" value={state.manualBillNum} onChange={(e)=>setState({manualBillNum:e.target.value})}/>
                  </Col>
                  <Col sm="4">
                    <br/>
                    <Button color="warning" onClick={captureBillManually}>Capture</Button>
                  </Col>
                </Row>
              </td>
            </tr>
          </tbody>
        </Table>
        <center>
          <Button color="info" onClick={toggleBillDetails}>Close</Button>
        </center>
      </ModalBody>
    </Modal>

    <Modal className="my-modal" isOpen={state.showTransactionDetailsDropDownOpen} toggle={toggleTransactionDetails} >
      <ModalHeader hidden={true} toggle={toggleTransactionDetails}></ModalHeader>
      <ModalBody>
        <TabContent activeTab={state.transactionTab}>
          <TabPane tabId="1">
            <center>
              <h5><i className="fa fa-home"></i> Transaction #{state.selectedTransaction.transaction_num}</h5>
            </center>
            {transactionAction}
            <br/><br/>
            <Invoice id={state.selectedTransaction.ID} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          </TabPane>
          <TabPane tabId="2">
            <center>
              <h5><i className="fa fa-home"></i> Refund - Transaction #{state.selectedTransaction.transaction_num}</h5>
            </center>
            <NavLink to="#" onClick={(e)=>setTransactionTab('1')}>Go back to transaction details</NavLink>
            <br/><br/>
            <form onSubmit={(e)=>{e.preventDefault();handleRefund()}}>
              <Row>
                <Col sm="4">
                  <label>Captured amount</label>
                </Col>
                <Col sm="8">
                  ${formatNumber(state.selectedTransaction.amount)}
                </Col>
                <Col sm="4">
                  <label>Captured date</label>
                </Col>
                <Col sm="8">
                  {formatDateTime(state.selectedTransaction.datetime_created)}
                </Col>
              </Row>
              {transactionDetails}
              <Row>
                <Col sm="4">
                  <label>Reason</label>
                </Col>
                <Col sm="8">
                  <MySelect
                    modal={true}
                    type="select"
                    required={true}
                    value={state.refundReason}
                    onChange={(v)=>setState({refundReason:v})}
                    options={[{label:"Please select the reason for refund",value:""},{label:"Hold the order until further notice",value:"1"},{label:"Cancel the order",value:"2"},{label:"New payment method",value:"3"},{label:"Over charge",value:"4"},{label:"Record a manual refunded transaction",value:"5"}]}
                  />
                </Col>
              </Row>
              <br/>
              {refundPaymentMethod}
              {refundedTransaction}
              <Row>
                <Col sm="4">
                  <label>Amount</label>
                </Col>
                <Col sm="4">
                  <Input type="text" value={state.refundAmount} onChange={(e)=>{if(state.refundReason==='4'||state.refundReason==='5')setState({refundAmount:e.target.value})}}/>
                </Col>
                <Col sm="4">
                  <Button color="warning">Refund</Button>
                </Col>
              </Row>
            </form>
          </TabPane>
          <TabPane tabId="3">
            <center>
              <h5><i className="fa fa-home"></i> Charge Back - Transaction #{state.selectedTransaction.transaction_num}</h5>
            </center>
            <NavLink to="#" onClick={(e)=>setTransactionTab('1')}>Go back to transaction details</NavLink>
            <br/><br/>
            <form onSubmit={(e)=>{e.preventDefault();chargeBackCC()}}>
              <Row>
                <Col sm="4">
                  <label>Confirmation #</label>
                </Col>
                <Col sm="5">
                  <Input type="text" value={state.chargeBackNum} onChange={(e)=>setState({chargeBackNum:e.target.value})}/>
                </Col>
                <Col sm="3" className="align-right">
                  <Button color="warning">Charge Back</Button>
                </Col>
              </Row>
            </form>
          </TabPane>
          <TabPane tabId="4">
            <center>
              <h5><i className="fa fa-home"></i> Remove Transaction #{state.selectedTransaction.transaction_num}</h5>
            </center>
            <NavLink to="#" onClick={(e)=>setTransactionTab('1')}>Go back to transaction details</NavLink>
            <br/><br/>
            <form onSubmit={(e)=>{e.preventDefault();removeTransaction()}}>
              <Row>
                <Col sm="7">
                  <label>Do you want to reverse the receivable #{state.selectedTransaction.invoice_bill_fk} back to pending?</label>
                </Col>
                <Col sm="2">
                  <MySelect
                    modal={true}
                    type="select"
                    required={true}
                    value={state.removeTransactionReverseInvoice}
                    onChange={(v)=>setState({removeTransactionReverseInvoice:v})}
                    options={[{label:"No",value:"no"},{label:"Yes",value:"yes"}]}
                  />
                </Col>
                <Col sm="3" className="align-right">
                  <Button color="warning" onClick={removeTransaction}>Remove</Button>
                </Col>
              </Row>
            </form>
          </TabPane>
        </TabContent>

        <br/>
        <center>
          <Button color="info" onClick={toggleTransactionDetails}>Close</Button>
        </center>
      </ModalBody>
    </Modal>

    <Modal className="my-modal" isOpen={state.tripFeeDropDownOpen} toggle={tripFeeToggle} >
      <ModalHeader hidden={true} toggle={tripFeeToggle}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-warning"></i>Trip Fee Priority</h5>
        </center>
        <br/><br/>
        <font color="red">*</font>Trip fee has been added to payable. Would you like to update the payment status to <b>Pending</b> and priortize this appraiser payment?<br/>
        <center>
          <br/><br/>
          <Button color="warning" onClick={updateTripFeePriority}><i className="fa fa-check"/>&nbsp;Yes</Button> &nbsp;&nbsp;&nbsp;
          <Button color="info" onClick={tripFeeToggle}>No</Button>
        </center>
      </ModalBody>
    </Modal>

    <Modal className="my-modal-wide" isOpen={state.newAppraiserFeeDropDownOpen} toggle={newAppraiserFeeToggle} >
      <ModalHeader hidden={true} toggle={newAppraiserFeeToggle}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-plus"></i> Add New Payable</h5>
        </center>
        <br/>
        <form onSubmit={newAppraiserFee}>
          <Table className="table">
            <thead>
              <tr>
                <th width="30%">Category</th>
                <th width="25%">Payment method</th>
                <th width="25%">Description</th>
                <th width="20%">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <MySelect
                    modal={true}
                    type="select"
                    required={true}
                    value={state.newAppraiserFeeCategory}
                    onChange={(v)=>setState({newAppraiserFeeCategory:v})}
                    options={feeOptions}
                  />
                </td>
                <td>
                  <MySelect
                    modal={true}
                    type="select"
                    required={true}
                    value={state.selectedAppraiserPaymentMethod}
                    onChange={(v)=>setState({selectedAppraiserPaymentMethod:v})}
                    options={state.paymentMethods.map((paymentMethod)=>{
                      if(paymentMethod.target==='bill'||paymentMethod.target==='all')
                        return {label:paymentMethod.payment_type, value:paymentMethod.payment_type};
                      else
                        return null;
                    }).filter(x=>x)}
                  />
                  {appraiserCCProfiles}
                </td>
                <td>
                  <Input type="text" value={state.newAppraiserFeeDescription} onChange={(e)=>setState({newAppraiserFeeDescription:e.target.value})} className="form-control"/>
                </td>
                <td>
                  <Input type="text" required={true} value={state.newAppraiserFeeAmount} onChange={(e)=>setState({newAppraiserFeeAmount:e.target.value})} className="form-control"/>
                </td>
              </tr>
            </tbody>
          </Table>
          <br/>
          <br/>
          <center>
            <Button color="warning"><i className="green-color fa fa-plus"></i>&nbsp;Add</Button>&nbsp;
            <Button color="info" onClick={newAppraiserFeeToggle}>Close</Button>
          </center>
        </form>
      </ModalBody>
    </Modal>
    <Modal className="my-modal-wide" isOpen={state.newLenderFeeDropDownOpen} toggle={newLenderFeeToggle} >
      <ModalHeader hidden={true} toggle={newLenderFeeToggle}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-plus"></i> Add New Receivable</h5>
        </center>
        <br/>
        <form onSubmit={newLenderFee}>
          <Table className="table">
            <thead>
              <tr>
                <th width="25%">Category</th>
                <th width="20%">Paid By</th>
                <th width="20%">Payment method</th>
                <th width="20%">Description</th>
                <th width="15%">Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <MySelect
                    modal={true}
                    type="select"
                    required={true}
                    value={state.newLenderFeeCategory}
                    onChange={(v)=>setState({newLenderFeeCategory:v})}
                    options={feeOptions}
                  />
                </td>
                <td>
                  <MySelect
                    modal={true}
                    type="select"
                    required={true}
                    value={state.newLenderFeeCoveredBy}
                    onChange={(v)=>setState({newLenderFeeCoveredBy:v})}
                    options={[{label:"Broker",value:"Broker"},{label:"Borrower",value:"Borrower"},{label:"Lender",value:"Lender"},{label:"Client",value:"Client"},{label:"Paid Outside of Closing",value:"Paid Outside of Closing"},{label:"Paid at Closing",value:"Paid at Closing"}]}
                  />
                </td>
                <td>
                  <MySelect
                    modal={true}
                    type="select"
                    required={true}
                    value={state.selectedLenderPaymentMethod}
                    onChange={(v)=>setState({selectedLenderPaymentMethod:v})}
                    options={state.paymentMethods.map((paymentMethod)=>{
                      return {label:paymentMethod.payment_type, value:paymentMethod.payment_type};
                    })}
                  />
                  {lenderCCProfiles}
                </td>
                <td>
                  <Input type="text" value={state.newLenderFeeDescription} onChange={(e)=>setState({newLenderFeeDescription:e.target.value})} className="form-control"/>
                </td>
                <td>
                  <Input type="text" required={true} value={state.newLenderFeeAmount} onChange={(e)=>setState({newLenderFeeAmount:e.target.value})} className="form-control"/>
                </td>
              </tr>
            </tbody>
          </Table>
          <br/>
          <br/>
          <center>
            <Button color="warning"><i className="green-color fa fa-plus"></i>&nbsp;Add</Button>&nbsp;
            <Button color="info" onClick={newLenderFeeToggle}>Close</Button>
          </center>
        </form>
      </ModalBody>
    </Modal>
    <br/><br/>

    <div className="align-right">
      <NavLink color="warning" to="#" onClick={newLenderFeeToggle}><i className="fa fa-plus green-color"></i> Add New Receivable</NavLink>
    </div>
    <Row>
      <Col sm="12">
        <Card>
          <CardHeader className="header-color">
            <i className="fa fa-tasks"></i>&nbsp;Receivables
          </CardHeader>
          <CardBody>
            <Table>
              <tbody>

                <tr>
                  <td width="70%"><b>Balance Due</b></td>
                  <td>{balance}</td>
                </tr>
              </tbody>
            </Table>
            <Table className="table table-striped">
              <thead>
                <tr>
                  <th width="20%">
                    Date
                  </th>
                  <th width="30%">
                    Description
                  </th>
                  <th width="50%">
                    <Row>
                      <Col sm="6">
                        Receivable
                      </Col>
                      <Col sm="6">
                        Transaction
                      </Col>
                    </Row>
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  transactionsFlow.map(
                    (transaction,index)=>{
                      let grayInvoice;
                      if(transaction.shadow_invoice!=='0')
                        grayInvoice = <i className="fa fa-bookmark flashit"></i>
                      if(transaction.type==='Invoice'){
                        let correspondTransaction;
                        if(transaction.transactions.length>0){
                          correspondTransaction = transaction.transactions.map(
                            (t,index2)=>{
                              let additionalStatus;

                              if(t.purpose==='Charge Back'){
                                additionalStatus = <font color="red">Charged Back</font>;
                              }
                              return (
                                <div key={index2}>
                                  <font color="green">
                                    <NavLink to="#" onClick={(e)=>selectTransaction(t.method,t.transaction_ID)}><u>Transaction #{t.transaction_num}</u></NavLink><br/>
                                    {additionalStatus}${formatNumber(t.transaction_amount)}
                                  </font>

                                </div>
                              )
                            }
                          );
                        }
                        let status = <div className="display-inline">{transaction.status}</div>;

                        if(transaction.status!=='Received'&&transaction.status!=='Hold')
                          status = <div className="display-inline"><font color="red">{transaction.status}</font></div>;

                        return(
                          <tr key={index}>
                            <td>{formatDateTime(transaction.datetime_created)}</td>
                            <td>{grayInvoice}{transaction.payment_method} - <i>{transaction.description} ({status} by {transaction.covered_by})</i></td>
                            <td>
                              <Row>
                                <Col sm="6">
                                  <font color="red">
                                    <NavLink to="#" onClick={(e)=>selectInvoiceByID(transaction.invoice_num)}><u>Receivable #{transaction.invoice_num}</u></NavLink><br/>
                                    ${formatNumber(transaction.amount)}
                                  </font>
                                </Col>
                                <Col sm="6">
                                  {correspondTransaction}
                                </Col>
                              </Row>
                            </td>
                          </tr>
                        );
                      }
                      else if(transaction.type==='Transaction'){
                        return(
                          <tr key={index}>
                            <td>{formatDateTime(transaction.datetime_created)}</td>
                            <td>{transaction.payment_method} <font color="red">{transaction.purpose}</font> - <NavLink to="#" onClick={(e)=>selectTransaction(transaction.method,transaction.ID)}><u>Transaction {transaction.transaction_num}</u></NavLink><br/><NavLink to="#" onClick={(e)=>selectInvoiceByID(transaction.invoice_fk)}><u>Receivable #{transaction.invoice_fk}</u></NavLink></td>
                            <td><Row><Col sm="6"></Col><Col sm="6"><font color="green">${formatNumber(transaction.amount)}</font></Col></Row></td>
                          </tr>
                        );
                      }
                      return null;
                    }
                  )
                }
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </div>;
}


export default Billing;
