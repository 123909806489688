//Author Sooyoung Kim
//Date May 25th, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, deleteAPICallGenerator, callBackGenerator, formatDateTime, confirmation, pushToArray} from '../../util/util';
import {Button, Table, Input, Modal, ModalHeader, ModalBody, ModalFooter, NavItem, Nav, TabContent, TabPane, Row, Col, NavLink as NavLinkL} from 'reactstrap';
import {NavLink} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';


//initialize the state
const initialState = {
  activeTab:'2',
  serviceRatings:[],
  serviceRatingItems:[],
  serviceRatingItemComments:[],
  serviceRatingItemLabels:[],
  serviceRatingPredefinedItems:[],
  likes:[]
};

//reducer function that perform state update
const reducer = getReducer();


const ServiceRating  = (props)=>{
  const controller = new AbortController();
  let newInitialState = Object.assign({}, initialState, {
    appraisal: props.appraisal,
    appraisalFk: props.appraisalFk,
    popUp: props.popUp?props.popUp:false,
    renderTriggerLink: props.renderTriggerLink?props.renderTriggerLink:true,
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{


    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const togglePopUp = () =>{
    if(state.popUp===false&&state.serviceRatingPredefinedItems.length<=0){

      getServiceRatings();
      getServiceRatingPredefinedItem();
      getLikes(props.appraisalFk);
    }
    setState({popUp:!state.popUp});
  }

  const addNewServiceRatingItem = (itemID) =>{
    let targetLabel = '';

    for(let i=0;i<state.serviceRatingPredefinedItems.length;i++){
      if(state.serviceRatingPredefinedItems[i].ID===itemID){
        targetLabel = state.serviceRatingPredefinedItems[i].description;
        break;
      }
    }

    let items = state.serviceRatingItems.slice();
    let labels = state.serviceRatingItemLabels.slice();
    let comments = state.serviceRatingItemComments.slice();
    let index = items.indexOf(itemID);

    if(index===-1){
      items.push(itemID);
      labels.push(targetLabel);
      comments.push('');
    }
    else{
      items.splice(index, 1);
      labels.splice(index, 1);
      comments.splice(index, 1);
    }

    setState({serviceRatingItems:items, serviceRatingItemLabels:labels, serviceRatingItemComments:comments});
  }

  const updateServiceRatingComment = (index, comment) =>{
    let comments = state.serviceRatingItemComments.slice();

    comments[index] = comment;

    setState({serviceRatingItemComments:comments});
  }

  const tabToggle = (tab) =>{
    setState({activeTab:tab});
  }

  //API call
  const removeServiceRating = (ID) =>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        getServiceRatings();
      }
    };
    httpDelete('appraiser/serviceRating/'+ID,'Service rating entry deleted.','Oops, something went wrong and could not delete the service rating entry, please try again later.', callBack);
  }

  const likeAppraiser = (appraiserFk) =>{
    let list = pushToArray(state.likes, appraiserFk);
    let callBack = apiCallBack([{state:'likes', value:list}]);
    let parameters = [{field:'appraisalFk',value:state.appraisalFk},{field:'appraiserFk',value:appraiserFk}];
    httpPost('appraiser/like/internal', parameters, 'Like submitted.', 'Oops, something went wrong and could not submit the like. Please try again later.', callBack);
  }

  const unlikeAppraiser = (appraiserFk) =>{
    let likes = state.likes.slice();
    let index = likes.indexOf(appraiserFk);

    if(index!==-1)
      likes.splice(index, 1);

    let callBack = apiCallBack([{state:'likes', value:likes}]);
    httpDelete('appraiser/like/internal/'+state.appraisalFk+'/'+appraiserFk,'Unliked appraiser','Oops, something went wrong and could not submit the unlike request. Please try again later.', callBack);
  }

  const getServiceRatingPredefinedItem = () =>{
    let callBack = apiCallBack([{state:'serviceRatingPredefinedItems', key:'data'}]);
    httpGet('appraiser/serviceRating/get', '', 'Oops, something went wrong and could not load the service rating items.', callBack);
  }

  const submitRating = () =>{
    let parameters = [
      {
        field:'comments',
        value:state.serviceRatingItemComments
      },
      {
        field:'appraisalFk',
        value:state.appraisalFk
      },
      {
        field:'items',
        value:state.serviceRatingItems
      }
    ];

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        setState({serviceRatingItemComments:[], serviceRatingItems:[], serviceRatingItemLabels:[]});
        getServiceRatings();
      }
    };
    callBack = callBack.bind(this);

    httpPost('appraiser/serviceRating/record', parameters, 'Rating posted successfully.', 'Oops, something went wrong and could not post this rating. Please try again later.', callBack);
  }

  const getServiceRatings = () =>{
    let callBack = apiCallBack([{state:'serviceRatings', key:'data'}]);
    httpGet('appraiser/serviceRating/appraisal_fk='+state.appraisalFk, '', 'Oops, something went wrong and could not load service rating for this appraisal. Please try again later.', callBack);
  }

  const getLikes = (ID) =>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let likes = [];
        for(let i=0;i<response.data.data.length;i++)
          likes.push(response.data.data[i].appraiser_fk);

        setState({likes:likes});
      }
    };
    callBack = callBack.bind(this);

    httpGet('appraiser/like/internal/'+ID, '', 'Oops, something went wrong and could not load the like of this order. Please try again later.', callBack);
  }

  //render
  let submitButton;
  let triggerLink;

  if(state.renderTriggerLink)
    triggerLink = <NavLink to="#" onClick={togglePopUp} color="warning"><i className="fa fa-plus green-color"/> Service Rating</NavLink>

  if(state.serviceRatingItems.length>0){
    submitButton =<Button color="warning" onClick={submitRating}><i className="fa fa-check"></i> Submit</Button>;
  }

  let appraiser;

  if(props.appraisal.appraiser_fk!=='0'){
    let thumbsUpIcon = <i className="fa fa-thumbs-o-up cursor-pointer" onClick={(e)=>likeAppraiser(props.appraisal.appraiser_fk)}/>;

    if(state.likes.indexOf(props.appraisal.appraiser_fk)!==-1)
      thumbsUpIcon = <i className="fa fa-thumbs-up link-color cursor-pointer" onClick={(e)=>unlikeAppraiser(props.appraisal.appraiser_fk)}/>;

    appraiser = <div>
      {thumbsUpIcon} {props.appraisal.appraiser}
    </div>
  }

  return <div  className="display-inline">
    {triggerLink}
    <Modal className="my-modal" isOpen={state.popUp} toggle={togglePopUp} >
      <ModalHeader className="my-confirmation-title"><i className="fa fa-warning"></i> Service Ratings</ModalHeader>
      <ModalBody>
        <Nav tabs>
          <NavItem>
            <NavLinkL
              className={"nav-link "+(state.activeTab === '1'?"active":"inactive" )}
              onClick={() => { tabToggle('1'); }}
              to="#"
            >
              Service Ratings
            </NavLinkL>
          </NavItem>
          <NavItem>
            <NavLinkL
              className={"nav-link "+(state.activeTab === '2'?"active":"inactive" )}
              onClick={() => { tabToggle('2'); }}
              to="#"
            >
              New Service Ratings
            </NavLinkL>
          </NavItem>
        </Nav>
        <TabContent activeTab={state.activeTab}>
          <TabPane tabId="1">
            <br/>
            <div className="ultra-large-scroll-container">
              <label>Appraiser</label><br/>
              {appraiser}
              <Table className="table table-striped">
                <tbody>
                  {
                    state.serviceRatings.map(
                      (serviceRating, index)=>{

                        return(
                          <tr key={index}>
                            <td>
                              <div className="my-well">
                                <Row>
                                  <Col sm="3">
                                    <div className="margin-top">
                                      {serviceRating.appraiser_name}
                                    </div>
                                  </Col>
                                  <Col sm="9">
                                    {serviceRating.score?serviceRating.score+"/5.00":"-"}
                                  </Col>
                                </Row>
                                {
                                  serviceRating.items.map(
                                    (item, index2)=>{
                                      let score = item.deduct_score;
                                      if(item.deduct_score>0)
                                        score = <div className="green-color"><b>+{item.deduct_score}</b></div>;
                                      return (
                                        <div  style={{borderBottom:'1px solid #d2d2d2',paddingTop:'10px'}} key={index2}>
                                          <Row>
                                            <Col sm="3">
                                              <i className="fa fa-times red-color cursor-pointer" onClick={(e)=>{
                                                confirmation(
                                                  ()=>{removeServiceRating(item.ID)},
                                                  ()=>{},
                                                  'Delete service rating entry?',
                                                  'Are you sure you want to delete this service rating entry?');
                                              }}></i> {item.author}<br/>
                                              <div style={{fontSize:'10px'}}>{formatDateTime(item.datetime_created)}</div>
                                            </Col>
                                            <Col sm="7">
                                              {item.description}<br/>
                                              {item.comment}
                                            </Col>
                                            <Col sm="2">
                                              {score}
                                            </Col>
                                          </Row>
                                        </div>
                                      )
                                    }
                                  )
                                }
                              </div>
                            </td>
                          </tr>
                        );
                      }
                    )
                  }
                </tbody>
              </Table>
            </div>
          </TabPane>
          <TabPane tabId="2">
            <br/>
            <div className="ultra-large-scroll-container">
              <b>Please select items that applies</b>
              <center>
                {
                  state.serviceRatingPredefinedItems.map(
                    (item, index)=>{
                      let score = item.deduct_score;

                      if(item.deduct_score>0)
                        score = <div className="green-color"><b>+{item.deduct_score}</b></div>;
                      let className = "my-well cursor-pointer background-gray";
                      if(state.serviceRatingItems.indexOf(item.ID)===-1)
                        className = "my-well cursor-pointer";

                      return(
                        <div key={index} className={className} onClick={(e)=>addNewServiceRatingItem(item.ID)}>
                          <Row>
                            <Col sm="3">
                              <b>{item.category}</b>
                            </Col>
                            <Col sm="7" className="align-left">
                              {item.description}
                            </Col>
                            <Col sm="2">
                              {score}
                            </Col>
                          </Row>
                        </div>
                      )
                    }
                  )
                }
                <br/>
                {
                  state.serviceRatingItems.map(
                    (item, index)=>{
                      return (
                        <div key={index}>
                          <div className="my-well">
                            {state.serviceRatingItemLabels[index]}
                            <Input type="textarea" className="form-control comment-textarea" value={state.serviceRatingItemComments[index]} id="comment" placeholder="Say something..." style={{resize:'none'}} rows="3" onChange={(e)=>updateServiceRatingComment(index, e.target.value)}></Input>
                          </div>
                          <br/>
                        </div>
                      )
                    }
                  )
                }
                <br/>
                <div className="align-right">
                  {submitButton}
                </div>
              </center>
              <br/>
            </div>
          </TabPane>
        </TabContent>
      </ModalBody>
      <ModalFooter>
        <div style={{width:'100%'}}>
          <center>
            <Button color="info" onClick={()=>{togglePopUp()}}><i className="fa fa-times"></i> Close</Button>
          </center>
        </div>
      </ModalFooter>
    </Modal>
  </div>;
}

export default ServiceRating;
