//Author Sooyoung Kim
//Date July 18
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, getFromTo, formatNumber} from '../../util/util';
import {Card, CardHeader, CardBody, Modal, ModalHeader, ModalBody, Row, Col, Table, Button} from 'reactstrap';
import MyReactTable from '../util/my-react-table';
import ReportFilter from './../report/report-filter';
import moment from 'moment';
import MyChart from './../util/my-chart';
import {NavLink} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';

//initialize the state
const initialState = {
  from:getFromTo('from'),
  to:getFromTo('to'),

  stats:[],
  statDetails:[],
  countyDetails:[],
  countyDetailsStats:{},

  selectedUser:-1,
  selectedUserName:'',

  selectedCounty:'',
  selectedState:'',

  showPopUp: false,
  auto_assign_failed_reasons:[],

  appraisersStat:[]
};

//reducer function that perform state update
const reducer = getReducer();


const ManualAutoAssignStats  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getAutoAssignStats();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const togglePopUp = () => {
    setState({showPopUp:!state.showPopUp});
  }

  const selectUser = (e, user, userName) => {
    e.preventDefault();
    setState({selectedUser:user, selectedUserName:userName});
    getAutoAssignStatsDetails(user);
  }

  //API call
  const getAutoAssignStats = () => {
    let callBack = apiCallBack([{state:'stats', key:'data'}]);
	  httpGet('autoAssign/manual/stats/user/from='+state.from+'&to='+state.to, '', 'Oops, something went wrong and could not load auto assign stats. Please try again later.', callBack);
  }

  const getAutoAssignStatsDetails = (user) => {
    let callBack = apiCallBack([{state:'statDetails', key:'data'}]);
    httpGet('autoAssign/manual/stats/user/details/from='+state.from+'&to='+state.to+'&user='+user, '', 'Oops, something went wrong and could not load auto assign stats. Please try again later.', callBack);
  }

  const countyBreakDown = (county, stateName) => {
    togglePopUp();
    setState({selectedState:stateName,selectedCounty:county});

    let callBack = apiCallBack([
      {state:'countyDetails', key:'data.orders'}, 
      {state:'no_auto_assign_avg_fee', key:'data.no_auto_assign_avg_fee'}, 
      {state:'auto_assign_avg_fee', key:'data.auto_assign_avg_fee'}, 
      {state:'no_auto_assign_avg_tat', key:'data.no_auto_assign_avg_tat'}, 
      {state:'auto_assign_avg_tat', key:'data.auto_assign_avg_tat'}, 
      {state:'auto_assign_failed_reasons', key:'data.auto_assign_failed_reasons'},
      {state:'appraisersStat', key:'data.appraiser_stats'}]);
	  httpGet('autoAssign/manual/stats/user/county/details/from='+state.from+'&to='+state.to+'&user='+state.selectedUser+'&state='+stateName+'&county='+county, '', 'Oops, something went wrong and could not load auto assign stats. Please try again later.', callBack);
  }

  //render
  let chart;
  if(state.stats.length>0){
    let data = [];
    let totalVolume = 0;
    let totalAutoAssignVolume = 0;

    for(let i=0;i<state.stats.length;i++){
      let tmp = {};
      tmp.name = state.stats[i].account_manager;
      tmp.y = state.stats[i].auto_assign_volume;

      totalVolume = totalVolume + parseInt(state.stats[i].total_volume, 10);
      totalAutoAssignVolume = totalAutoAssignVolume + parseInt(state.stats[i].auto_assign_volume, 10);

      data.push(tmp);
    }

    let noAutoAssign = totalVolume - totalAutoAssignVolume;

    const config ={
      chart: {
        type: 'pie',
        height:280
      },
      title: {
        text: null
      },
      subtitle: {
        text: null
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            format: '{point.name}: {point.y}'
          },
          showInLegend: true
        }
      },

      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> of total<br/>'
      },
      series: [{
        name: 'Rule code',
        colorByPoint: true,
        data: data
      }],
      credits:{
        enabled:false
      },
      legend:{
        align: 'right',
        layout: 'vertical',
        verticalAlign: 'top',
        x: 340,
        y: 0,
        itemStyle:{
          fontSize:13
        },
        labelFormatter: function () {
          return this.name+' - '+formatNumber(this.series.yData[this.index]);
        }
      }
    };

    let data2 = [{name:'No auto assign', y:noAutoAssign}, {name:'Auto assign processed', y:totalAutoAssignVolume}];

    const config2 ={
      chart: {
        type: 'pie',
        height:300
      },
      title: {
        text: null
      },
      subtitle: {
        text: null
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            format: '{point.name}: {point.y}'
          },
          showInLegend: true
        }
      },

      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> of total<br/>'
      },
      series: [{
        name: 'Rule code',
        colorByPoint: true,
        data: data2
      }],
      credits:{
        enabled:false
      },
      legend:{
        align: 'right',
        layout: 'vertical',
        verticalAlign: 'top',
        x: 340,
        y: 0,
        itemStyle:{
          fontSize:13
        },
        labelFormatter: function () {
          return this.name+' - '+formatNumber(this.series.yData[this.index]);
        }
      }
    };

    chart = <Row>
      <Col sm="6">
        <MyChart options={config2}/>
      </Col>
      <Col sm="6">
        <MyChart options={config}/>
      </Col>
    </Row>;
  }

  const columns = [
    {
      id: 'county',
      Header: 'County',
      accessor: d => d.county,
      Cell: props => <NavLink to="#" onClick={(e)=>{e.preventDefault();countyBreakDown(props.row.original.county, props.row.original.state)}}>{props.row.original.county}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'state',
      Header: 'State',
      accessor: d => d.state,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'totalVolume',
      Header: 'Total Volume',
      accessor: d => d.total_volume,
      Cell: props => <div>{formatNumber(props.row.original.total_volume)}  ({formatNumber(props.row.original.own_pipeline)} Own Pipeline)</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'totalAutoAssign',
      Header: 'Total Auto Assign',
      accessor: d => d.auto_assign_volume,
      Cell: props => <div>{formatNumber(props.row.original.auto_assign_volume)} ({props.row.original.total_volume!==0?formatNumber((props.row.original.auto_assign_volume/props.row.original.total_volume)*100):0}%)</div>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'totalAutoAssignSucccess',
      Header: 'Auto Assign Success',
      accessor: d => d.total_volume,
      Cell: props => <div>{formatNumber(props.row.original.auto_assign_success)} ({props.row.original.auto_assign_volume!==0?formatNumber((props.row.original.auto_assign_success/props.row.original.auto_assign_volume)*100):0}%)</div>,
      headerStyle: {
        textAlign:'left'
      }
    }
  ];

  let from, to;
  if(state.from!=='')
    from = moment(state.from);
  if(state.to!=='')
    to = moment(state.to);

  let details;
  let countyLabel = [];
  let noAutoAssign = [];
  let autoAssign = [];

  for(let i=0;i<state.statDetails.length;i++){
    countyLabel.push(state.statDetails[i].property_county);

    let noAutoAssignCount = state.statDetails[i].total_volume - state.statDetails[i].auto_assign_volume;

    noAutoAssign.push(noAutoAssignCount);
    autoAssign.push(state.statDetails[i].auto_assign_volume);
  }

  let chart3;
  let chart4;
  if(state.selectedCounty!==''){
    let appraisers = [];
    let success = [];
    let decline = [];
    let condition = [];
    let noRespond = [];
    for(let i=0;i<state.appraisersStat.length;i++){
      appraisers.push(state.appraisersStat[i].appraiser);
      success.push(state.appraisersStat[i].success);
      decline.push(state.appraisersStat[i].decline);
      condition.push(state.appraisersStat[i].conditionally_accept);
      noRespond.push(state.appraisersStat[i].no_respond);
    }
    let config4 = {
      chart:{
        height:250,
        type:'bar'
      },
      xAxis: {
        categories: appraisers
      },
      yAxis:{
        tickInterval: 1,
        title: {
          text: 'Auto Assign Acceptance'
        }
      },
      series: [
        {
          data: success,
          name:'Accepted'
        },
        {
          data: decline,
          name:'Declined'
        },
        {
          data: condition,
          name:'Conditionally Accept'
        },
        {
          data: noRespond,
          name:'No Respond Within Time Limit'
        }
      ],
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true
          }
        },
        series: {
          stacking: 'normal'
        }
      },
      legend: {
          reversed: true
      },
      title:{
        text: 'Appraisers Stat'
      }
    }

    chart4 = <MyChart options={config4}></MyChart>;

    let data3 = [];

    for(let i=0;i<state.auto_assign_failed_reasons.length;i++){
      data3.push({name:state.auto_assign_failed_reasons[i].reason, y:state.auto_assign_failed_reasons[i].count});
    }
    const config3 ={
      chart: {
        type: 'pie',
        height:200
      },
      title: {
        text: null
      },
      subtitle: {
        text: null
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            format: '{point.name}: {point.y}'
          },
          showInLegend: true
        }
      },

      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> of total<br/>'
      },
      series: [{
        name: 'Rule code',
        colorByPoint: true,
        data: data3
      }],
      credits:{
        enabled:false
      },
      legend:{
        align: 'right',
        layout: 'vertical',
        verticalAlign: 'top',
        x: 340,
        y: 0,
        itemStyle:{
          fontSize:13
        },
        labelFormatter: function () {
          return this.name+' - '+formatNumber(this.series.yData[this.index]);
        }
      }
    };

    chart3 = <MyChart options={config3}></MyChart>;
  }



  if(state.selectedUser!==-1){
    let countyConfig = {
      xAxis: {
        categories: countyLabel
      },
      series: [
        {
          data: noAutoAssign,
          name:'No auto assign'
        },
        {
          data: autoAssign,
          name:'Auto assign processed'
        }
      ],
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true
          }
        }
      },
      title:{
        text: 'Count Auto Assign Stats'
      }
    }

    details = <div>
      <Modal className="my-modal-wide" isOpen={state.showPopUp} toggle={togglePopUp} >
        <ModalHeader hidden={true} toggle={togglePopUp}></ModalHeader>
        <ModalBody>
          <center>
            <h5><i className="fa fa-user"></i> {state.selectedCounty+' - '+state.selectedState}</h5>
          </center>
          <br/>
          <Row>
            <Col sm="5">
              <Card style={{height:300}}>
                <CardHeader className="header-color">
                  Auto Assign Vs Non Auto Assign
                </CardHeader>
                <CardBody>
                  <Table>
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th>No auto assign</th>
                        <th>Auto assign processed</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><b>Avg Fee</b></td>
                        <td>${state.no_auto_assign_avg_fee}</td>
                        <td>${state.auto_assign_avg_fee}</td>
                      </tr>
                      <tr>
                        <td><b>Avg Submitted to Accepted TAT</b></td>
                        <td>{state.no_auto_assign_avg_tat} business hour(s)</td>
                        <td>{state.auto_assign_avg_tat} business hour(s)</td>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
            <Col sm="7">
              <Card style={{height:300}}>
                <CardHeader className="header-color">
                  Auto Assign Failed Reason(s)
                </CardHeader>
                <CardBody>
                  {chart3}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <br/><br/>
          <Card>
            <CardHeader className="header-color">Appraisers Acceptance Rate</CardHeader>
            <CardBody>
              {chart4}
            </CardBody>
          </Card>
          <br/><br/>
          <Card>
            <CardHeader className="header-color">
              Auto Assign orders
            </CardHeader>
            <CardBody>
              <Table className="table table-striped">
                <thead>
                  <tr>
                    <th width="15%">Reference #</th>
                    <th width="30%">Address</th>
                    <th width="20%">Appraiser</th>
                    <th>Fee</th>
                    <th>Submit to Accepted TAT</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    state.countyDetails.map(
                      (row, index)=>{
                        return <tr key={index}>
                          <td><NavLink to={"appraisal/"+row.ID} target="_blank">{row.reference_num}</NavLink></td>
                          <td>{row.property_street+' '+row.property_city}</td>
                          <td>{row.appraiser}</td>
                          <td>{row.fee}</td>
                          <td>{row.tat!==-1?row.tat:'Not available'}</td>
                        </tr>
                      }
                    )
                  }
                </tbody>
              </Table>
            </CardBody>
          </Card>

          <br/>
          <center>
            <Button color="info" onClick={togglePopUp}>Close</Button>
          </center>
        </ModalBody>
      </Modal>

      <div className="my-divider"></div>
      <br/>
      <Card>
        <CardHeader className="header-color">
          <label>{state.selectedUserName}'s Stats</label>
        </CardHeader>
        <CardBody>
          <MyChart options = {countyConfig} ></MyChart>
          <MyReactTable columns={columns} data={state.statDetails} className="table table-striped"/>
        </CardBody>
      </Card>
    </div>
  }

  let reportFiltersConfig = [
    {id:'from',value:from, updateFunc:(v)=>setState({from:v}), width:'3'},
    {id:'to',value:to, updateFunc:(v)=>setState({to:v}), width:'3'},
    {id:'button',value:'Submit', updateFunc:getAutoAssignStats, width:'6', className:"align-right", color:"warning"},
  ];

  return <div className="my-well">
    <ReportFilter {...props} configs={reportFiltersConfig}/>
    <div className="my-divider"></div>
    <br/>
    <Row>
      <Col sm="6">
        <Table className="table table-striped">
          <thead>
            <tr>
              <th>Account Manager</th>
              <th>Total Order Volume</th>
              <th>Total Auto Assign Processed</th>
              <th>Total Auto Assign Success</th>
            </tr>
          </thead>
          <tbody>
            {
              state.stats.map(
                (row, index)=>{
                  return <tr key={index}>
                    <td><NavLink to="#" onClick={(e)=>{selectUser(e, row.user_fk, row.author)}}>{row.author}</NavLink></td>
                    <td>{formatNumber(row.total_volume)} ({formatNumber(row.own_pipeline)} Own Pipeline)</td>
                    <td>{formatNumber(row.auto_assign_volume)} ({row.total_volume!==0?formatNumber((row.auto_assign_volume/row.total_volume)*100):0}%)</td>
                    <td>{formatNumber(row.auto_assign_success)} ({row.auto_assign_volume!==0?formatNumber((row.auto_assign_success/row.auto_assign_volume)*100):0}%)</td>
                  </tr>
                }
              )
            }
          </tbody>
        </Table>
      </Col>
      <Col sm="6">
        {chart}
      </Col>
    </Row>
    <div className="my-divider">&nbsp;</div>
    {details}
  </div>;
}


export default ManualAutoAssignStats;
