//Author Sooyoung Kim
//Date April 27, 2023
import {getReducer, getSetStateFunction, postAPICallGenerator} from '../../util/util';
import { Button, Input, Row, Col,FormGroup, Label } from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import ReactQuill from 'react-quill';


//initialize the state
const initialState = {
  body: '',
  title: '',
  adminAccess:true,
  clientAccess:false,
  appraiserAccess:false,
};

//reducer function that perform state update
const reducer = getReducer();


const NewAnnouncement  = (props)=>{
  const controller = new AbortController();
  const navigate = useNavigate();

  let newInitialState = Object.assign({}, initialState, {creator:props.userName});
  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //API call
  //create new announcement
  const createAnnouncement = () =>{
    let parameters = [
      {
        field:'body',
        value:state.body
      },
      {
        field:'creator',
        value:state.creator
      },
      {
        field:'title',
        value:state.title
      },
      {
        field:'adminAccess',
        value:state.adminAccess
      },
      {
        field:'appraiserAccess',
        value:state.appraiserAccess
      },
      {
        field:'clientAccess',
        value:state.clientAccess
      }
    ];

    //call back for creating new announcement
    let callBack = (response) => {
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        navigate('/announcement');
      }
    };
    callBack = callBack.bind(this);
    httpPost('announcement/create',parameters,'Announcement created successfully.','Oops, something went wrong and could not create this announcement. Please try again later.', callBack);
  }

  //render
  return <div className="card padding">
    <Row>
      <Col>
        <div className="page-title">
          <i className="fa fa-reorder"></i>&nbsp;New Annnouncement
        </div>
				<NavLink to="/announcement">Back to announcement list</NavLink>
      </Col>
      <Col className="align-right">
        <Button color="warning" onClick={()=>{createAnnouncement()}}><i className="fa fa-plus"></i>&nbsp;&nbsp;Create</Button>
      </Col>
    </Row>
    <div className="hr"/>

    <br/>
    <div className="form-group">
			<label>By: </label>
			<Input value={state.creator} onChange={(text)=>{setState({creator:text.target.value})}}/>
		</div>
    <br/>
    <div className="form-group">
			<label>Title: </label>
			<Input value={state.title} onChange={(text)=>setState({title:text.target.value})}/>
		</div>
    <br/>
    <ReactQuill
      modules={
        {
          toolbar: [
              [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
              [{size: []}],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{'list': 'ordered'}, {'list': 'bullet'},
               {'indent': '-1'}, {'indent': '+1'}],
              ['link', 'image', 'video'],
              ['clean']
            ],
            clipboard: {
              // toggle to add extra line breaks when pasting HTML:
              matchVisual: false,
            },
            imageResize: {
            // parchment: Quill.import('parchment'),
              modules: ['Resize', 'DisplaySize']
            }
        }
      }
      formats={
        [
          'header', 'font', 'size',
          'bold', 'italic', 'underline', 'strike', 'blockquote',
          'list', 'bullet', 'indent',
          'link', 'image', 'video'
        ]
      }
      value={state.body}
      onChange={(value) => setState({body:value})}
    />

    <FormGroup check>
      <div className="display-inline" style={{width:'100px'}} onClick={(e)=>{setState({clientAccess:e.target.checked})}}>
        <Label check>
          <Input type="checkbox"/>&nbsp;Client
        </Label>
      </div>

      <div className="display-inline" style={{width:'120px'}} onClick={(e)=>{setState({appraiserAccess:e.target.checked})}}>
        <Label check>
          <Input type="checkbox"/>&nbsp;Appraiser
        </Label>
      </div>

      <div className="display-inline" style={{width:'100px'}} onClick={(e)=>{setState({adminAccess:e.target.checked})}}>
        <Label check>
          <Input type="checkbox" defaultChecked={true}/>&nbsp;Admin
        </Label>
      </div>
    </FormGroup>
  </div>;
}

export default NewAnnouncement;
