//Author Sooyoung Kim
//Date May 23rd, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, deleteAPICallGenerator, callBackGenerator, formatDateTime, formatDate, confirmation} from '../../util/util';
import {Button, Card, NavItem, Nav, TabContent, TabPane, CardBody, CardHeader, Col, Collapse, Row,Input,Table, Modal , ModalHeader, ModalBody, NavLink as NavLinkL} from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import MyXEditable from '../util/my-xeditable';
import {NavLink, useParams, useNavigate} from 'react-router-dom';
import Toggle from 'react-toggle';
import React, {useReducer, useEffect} from 'react';
import MySelect from '../util/my-select';

//initialize the state
const initialState = {
  user:{},
  client:{},
  branch:{},
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
  dropdownOpen: false,
  memo:'',
  historyOrders:[],
  hasMoreOrders:true,
  loading: false,
  sort: 'ID',
  order: 'DESC',
  limit:100,
  offset:0,

  newEmailPopUpDropDownOpen:false,
  newEmail:'',

  userEmailDropDownOpen:false,
  userEmailSettings:[],

  activeTab:'1',

  transferAccountDropDownOpen:false,
  transferAccountAddToWholesaleAssociate:'no',
  clientBranches:[],
  clients:[]
};

//reducer function that perform state update
const reducer = getReducer();


const ClientBranchUser  = (props)=>{
  const controller = new AbortController();
  let history = useNavigate();
  let {clientFk} = useParams();
  let {branchFk} = useParams();
  let {params} = useParams();
  let {id} = useParams();

  let newInitialState = Object.assign({}, initialState, {
    id: id,
    params: params,
    clientFk: clientFk,
    branchFk: branchFk,
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getRegisteredUser();
    getClient();
    getBranch();
    getAllClientProfiles();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    if(state.historyOrders.length<=0 && state.hasMoreOrders && Object.keys(state.user).length !== 0){
        loadMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state]);

  //non API call but simpyl manage state
  const emailNotificationToggle = (subject) =>{
    for(let i=0;i<state.userEmailSettings.length;i++){
      if(state.userEmailSettings[i].subject===subject){
        if(state.userEmailSettings[i].blocked==='yes')
          unblockUserEmail(subject);
        else
          blockUserEmail(subject);
      }
    }
  }

  const toggleEmailDropDown = () =>{
    if(!state.userEmailDropDownOpen){
      getEmailNotificationSettings();
    }
    setState({userEmailDropDownOpen:!state.userEmailDropDownOpen});
  }

  const updateFieldCallBack = (update, field, value) =>{
    if(update === 'client/update'){
      let client = Object.assign({}, state.client);
      client[field] = value;
      setState({client:client});
    }else if(update === 'client/branch/update'){
      let branch = Object.assign({}, state.branch);
      branch[field] = value;
      setState({branch:branch});
    }else if(update === 'client/changeEmail'){
      let user = Object.assign({}, state.user);
      user['email'] = value;
      setState({user:user});
    }else{
      let user = Object.assign({}, state.user);
      user[field] = value;
      setState({user:user});
    }
  }

  //update the memo note on appraiser
  const changeMemo = (e) =>{
    let user = Object.assign({},state.user,{memo:e.target.value});
    setState({user:user});
  }

  //toggle function for the instruction floating note
  const floatingMemoToggle = () =>{
    setState({
      dropdownOpen: !state.dropdownOpen
    });
  }

  const activeTabToggle = (tab) =>{
    setState({activeTab:tab});
  }

  const toggleTransferUserAccount = () =>{
    setState({transferAccountDropDownOpen:!state.transferAccountDropDownOpen});
  }

  //this function refresh the list of orders pipeline
  const refreshList = () =>{
    setState({historyOrders:[],totalCount:0,hasMoreOrders:true,offset:0});
  }

  //this function render the sort icon at each column of the table
  const renderSortIcon = (col) =>{
    if(state.sort===col){
      if(state.order==='ASC')
        return <i className="red-color fa fa-arrow-down"></i>
      else
        return <i className="red-color fa fa-arrow-up"></i>
    }
  }

  const columnClickHandler = (col) =>{
    if(state.sort===col){
      if(state.order==='ASC')
        setState({order:'DESC'});
      else
        setState({order:'ASC'});
    }
    else
      setState({sort:col});
    refreshList();
  }

  const renderHistoryAppraisals = () =>{
    return (
      <div>
        <Table className="table table-striped">
          <thead>
            <tr>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('reference_num')}><i className="fa fa-search"></i>&nbsp;Reference # {renderSortIcon('reference_num')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('status')}><i className="fa fa-info-circle"></i>&nbsp;Status {renderSortIcon('status')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('loan_num')}><i className="fa fa-folder"></i>&nbsp;Loan # {renderSortIcon('loan_num')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('CONCAT(borrower_f_name," ",borrower_l_name)')}><i className="fa fa-user"></i>&nbsp;Borrower {renderSortIcon('CONCAT(borrower_f_name," ",borrower_l_name)')}</th>
              <th className="cursor-pointer" width="20%" onClick={()=>columnClickHandler('property_street')}><i className="fa fa-map-marker"></i>&nbsp;Property Address {renderSortIcon('property_street')}</th>
              <th className="cursor-pointer" width="20%" onClick={()=>columnClickHandler('')}><i className="fa fa-info-circle"></i>&nbsp;Order Information {renderSortIcon('')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('datetime_submitted')}><i className="fa fa-calendar-o"></i>&nbsp;Date Submitted {renderSortIcon('datetime_submitted')}</th>
            </tr>
          </thead>
          <tbody>
          {
            state.historyOrders.map(
              (appraisal)=>{
                return (
                  <tr key={appraisal.ID}>
                    <td><NavLink className="link-color" target="_blank" to={"/appraisal/"+appraisal.ID}><b>{appraisal.reference_num}</b></NavLink></td>
                    <td>{appraisal.status}</td>
                    <td>{appraisal.loan_num}</td>
                    <td>{appraisal.borrower_f_name+' '+appraisal.borrower_l_name}</td>
                    <td>{appraisal.property_street+' '+appraisal.property_city+', '+appraisal.property_state+' '+appraisal.property_zip}</td>
                    <td>
                      <i className="fa fa-tags icon-color"></i>&nbsp;
                      {appraisal.appraisal_type}
                      <br/>
                      <i className="fa fa-home icon-color"></i>&nbsp;
                      {appraisal.property_type}
                      <br/>
                      <i className="fa fa-hashtag icon-color"></i>&nbsp;
                      {appraisal.loan_purpose}
                    </td>
                    <td>{formatDateTime(appraisal.datetime_submitted)}</td>
                  </tr>
                );
              }
            )
          }
          </tbody>
        </Table>
      </div>
    )
  }

  //API call
  //generate a function that do the update on editable
  const generateUpdateFunction = (url, parameters, successMessage, failedMessage, parentCallBack=null) =>{
    return (newValue, callBack, errorCallBack)=>{

      parameters.push({field:'value',value:newValue});
      let promise = httpPut(url,parameters,successMessage,failedMessage, callBack, errorCallBack);
      promise.then(
        (response)=>{
          let field;
          let value;

          for(let i=0;i<parameters.length;i++){
            if(parameters[i].field==='field')
              field = parameters[i].value;
            else if(parameters[i].field==='value')
              value = parameters[i].value;
          }
          if(parentCallBack!==null){
            parentCallBack(url, field, value);
          }
        }
      );
    }
  };

  //generate a function that do the update on editable
  const generateUpdateFunction2 = (url, parameters, successMessage, failedMessage, parentCallBack=null) =>{
    return (newValue, callBack, errorCallBack)=>{
      //add in the new value into parameters

      parameters.push({field:'newEmail',value:newValue});
      let promise = httpPost(url, parameters, successMessage, failedMessage, callBack, errorCallBack);
      promise.then(
        (response)=>{
          let field;
          let value;

          for(let i=0;i<parameters.length;i++){
            if(parameters[i].field==='field')
              field = parameters[i].value;
            else if(parameters[i].field==='value')
              value = parameters[i].value;
          }
          if(parentCallBack!==null){
            parentCallBack(url, field, value);
          }
        }
      );
    }
  };

  const getEmailNotificationSettings = () =>{
    let url = 'email/user/get/'+state.user.email;
    let callBack = apiCallBack([{state:'userEmailSettings', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load user email notification settings. Please try again later.', callBack);
  }

  const blockUserEmail = (subject) =>{
    let url = 'email/user/block';

    let parameters = [
      {
        field:'subject',
        value:subject
      },
      {
        field:'email',
        value:state.user.email
      }
    ];

    let callBack = (response)=>{
      let code= response.data.code;
      if(code==='00'){
        let userEmailSettings = state.userEmailSettings.slice();
        for(let i=0;i<userEmailSettings.length;i++){
          if(userEmailSettings[i].subject===subject){
            userEmailSettings[i].blocked='yes';
            break;
          }
        }

        setState({userEmailSettings:userEmailSettings});
      }
    };
    callBack = callBack.bind(this);

    httpPost(url, parameters, 'Email notification disabled.', 'Oops, something went wrong and could not disable the email notification. Please try again later.', callBack);
  }

  const unblockUserEmail = (subject) =>{
    let url = 'email/user/'+state.user.email+'/'+subject;

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let userEmailSettings = state.userEmailSettings.slice();
        for(let i=0;i<userEmailSettings.length;i++){
          if(userEmailSettings[i].subject===subject){
            userEmailSettings[i].blocked='no';
            break;
          }
        }

        setState({userEmailSettings:userEmailSettings});
      }
    };
    callBack = callBack.bind(this);

    httpDelete(url,'Email notification enabled succesfully.','Oops, something went wrong and could not enable the email notification. Please try again later.', callBack);
  }

  //remove a branch user
  const removeBranchUser = (id) =>{
    let url = 'client/branch/user/'+id;
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        history('/client/'+state.clientFk+'/'+state.params+"/branch/"+state.branchFk);
      }
    };
    callBack = callBack.bind(this);
    httpDelete(url,'User deleted successfully.','Oops, something went wrong and could not delete this user. Please try again later.', callBack);
  }

  //get registered user profile
  const getRegisteredUser = () =>{
    let url = 'client/branch/user/'+state.id;
    let callBack = (response)=>{console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        setState({user:response.data.data});
        console.log(response.data.data);
        if(response.data.data.memo!=='')
          setState({dropdownOpen:true});
      }
    };
    callBack = callBack.bind(this);

    httpGet(url, '','Oops, something went wrong and could not load this registered user profile. Please try again later.', callBack);
  }

  //get client profile
  const getClient = () =>{
    let url = 'client/'+state.clientFk;
    let callBack = apiCallBack([{state:'client',key:'data'}]);
    httpGet(url, '','Oops, something went wrong and could not load this client profile. Please try again later.', callBack);
  }

  //get the branch information
  const getBranch = () =>{
    let url = '/client/branch/'+state.branchFk;
    let callBack = apiCallBack([{state:'branch',key:'data'}]);
    httpGet(url, '','Oops, something went wrong and could not load this branch profile. Please try again later.', callBack);
  }

  //update special instructions
  const updateMemo = () =>{
    let url = 'user/update';
    let parameters = [
      {
        field:'field',
        value:'memo'
      },
      {
        field:'value',
        value:state.user.memo
      },
      {
        field:'ID',
        value:state.user.ID
      }
    ];

    //update special instruction call back
    let errorCallBack = (error)=>{
      let user = Object.assign({},state.user);
      user.memo = state.user.old_memo;
      setState({user:user});
    };
    errorCallBack = errorCallBack.bind(this);

    let callBack = ()=>{};
    httpPut(url, parameters, 'Memo saved successfully.', 'Oops, something went wrong and could not save the memo. Please try again later.', callBack, errorCallBack);
  }

  const getAllClientProfiles = () =>{
    let url = 'client/get/limit=-1&offset=-1';
    let callBack = apiCallBack([{state:'clients',key:'data'}]);
    httpGet(url, '','Oops, something went wrong and could not retrieve client profiles.', callBack);
  }

  const getClientBranches = (client) =>{
    let url = 'client/branch/get/'+client;
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;

      if(code==='00'){
        let clientBranches=[];
        for(let i=0;i<response.data.data.length;i++){
          let tmp = {};
          tmp.value = response.data.data[i].branch_name;
          tmp.key = response.data.data[i].ID;

          clientBranches.push(tmp);
        }
        setState({clientBranches:clientBranches});
      }
    };
    callBack = callBack.bind(this);
    httpGet(url, '','Oops, something went wrong and could not retrieve client entities list.', callBack);
  }

  const transferUserAccount = (e) =>{
    e.preventDefault();
    let url = 'client/user/account/transfer';
    let successMsg = 'Account transfered successfully and email notification sent to user.';
    let errorMsg = 'Oops, something went wrong and could not finish processing the request. Please try again later.';

    let parameters = [
      {
        field:'targetClientFk',
        value:state.targetClientFk
      },
      {
        field:'targetClientBranchFk',
        value:state.targetClientBranchFk
      },
      {
        field:'userFk',
        value:state.user.ID
      },
    ];

    if(state.transferAccountAddToWholesaleAssociate==='yes'){
      parameters.push({field:'addAssociateClientFk', value:state.clientFk});
    }

    let callBack = (response)=>{
      let code= response.data.code;
      if(code==='00'){
        history('/client/'+state.clientFk+'/'+state.params+"/branch/"+state.branchFk);
      }
    };
    callBack = callBack.bind(this);

    httpPost(url, parameters, successMsg, errorMsg, callBack);
  }

  //function that fire when the infinite scroll reach bottom
  const loadMore = () =>{
    //do not load if there is no more conditions or it's loading data
    if(state.hasMoreOrders&&!state.loading){
      //set loading equals to true so it won't fire off before we are done
      setState({loading:true});

      let url = 'appraisal/get/history=true&limit='+state.limit+'&offset='+state.offset+'&order='+state.order+'&sort='+state.sort+'&client='+state.clientFk+'&client_branch='+state.branchFk+'&entity_name='+state.user.first_name+" "+state.user.last_name+'&entity_email='+state.user.email;
      console.log(url);
      //callback handler that update the state when http request return
      let callBack = (response)=>{console.log(response);
        let code = response.data?response.data.code:undefined;

        if(code!=='00'){
          setState({hasMoreOrders:false});
        }
        else{
          let newOrders = response.data.data;
          let hasMoreOrders = true;
          let newOffset = state.offset;
          let totalCount = response.data.count;

          //if http request return empty then no more results, end of list
          if(newOrders.length<=0){
            hasMoreOrders = false;
          }
          else{
            //increment the offset
            newOffset = state.offset + 1;
          }

          //concat the current array of history orders
          if(state.historyOrders.length>0){
            let temp = [...state.historyOrders,...newOrders];

            setState({historyOrders:temp});
          }
          else
            setState({historyOrders:newOrders});

          setState({hasMoreOrders:hasMoreOrders,offset:newOffset,totalCount:totalCount});
        }
      };
      callBack = callBack.bind(this);

      //error handler when the http request return with error
      let errorCallBack = apiCallBack([{state:'hasMoreOrders', value:false}]);

      //collect the promise and wait for it to finish performing it's task
      let promises = httpGet(url, '','Oops, something went wrong and could not load orders. Please try again later.', callBack, errorCallBack);
      promises
        .then(
          function(result){
            //set loading equals to false so the function could be fire off once again
            setState({loading:false});
          }
        );
    }
  }

  //render
  let successMessage = 'Registered user profile updated.';
  let failedMessage = 'Failed to update the value of this field, please try again later.';

  let states;

  if(state.states.length>0)
    states =
     <MyXEditable
      type="select"
      value={state.branch.state}
      opt={state.states}
      updateFunc={
        generateUpdateFunction('client/branch/update',[{field:'field',value:'state'},{field:'ID',value:state.branchFk}],successMessage, failedMessage, updateFieldCallBack)
      }
    />;

  return <div className="my-well">
    <Modal className="my-modal" isOpen={state.transferAccountDropDownOpen} toggle={toggleTransferUserAccount} >
      <ModalHeader hidden={true} toggle= {toggleTransferUserAccount}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-bank"></i> Transfer User Profile</h5>
        </center>
        <br/>
        <form onSubmit={transferUserAccount}>
          <label>Target Client Profile</label>
          
          <MySelect
            modal={true}
            type="select"
            defaultValue={state.targetClientFk}
            value={state.targetClientFk}
            onChange={(v)=>{setState({targetClientFk:v});getClientBranches(v)}}
            options={state.clients.map((client)=>{
              return {label:client.company, value:client.ID};
            })}
          />
          <br/>
          <label>Target Client Branch</label><br/>
          <MySelect
            modal={true}
            type="select"
            value={state.targetClientBranchFk}
            onChange={(v)=>setState({targetClientBranchFk:v})}
            options={state.clientBranches.map((clientBranch)=>{
              return {label:clientBranch.value, value:clientBranch.key};
            })}
          />
          <br/>
          <label>Add this client profile to target client's wholesale associates</label><br/>
          <MySelect
            modal={true}
            type="select"
            value={state.transferAccountAddToWholesaleAssociate}
            onChange={(v)=>setState({transferAccountAddToWholesaleAssociate:v})}
            options={[{label:"Yes",value:"yes"},{label:"No",value:"no"}]}
          />
          <br/><br/>
          <center>
            <Button color="warning"><i className="fa fa-check"></i>&nbsp;Transfer User Account</Button>{' '}
            <Button color="info" onClick={toggleTransferUserAccount}>Close</Button>
          </center>
        </form>
      </ModalBody>
    </Modal>

    <div className="floating-memo-appraiser">
      <div onClick={floatingMemoToggle} className="cursor-pointer"><i className="fa fa-tag"></i>&nbsp;Memo</div>
      <Collapse isOpen={state.dropdownOpen}>
        <Input type="textarea" value={state.user.memo} onChange={changeMemo} className="non-editable-textarea yellow-background-textarea margin-bottom" rows="20" name="text" id="exampleText">

        </Input>
        <Button color="warning" className="form-control" onClick={updateMemo}><i className="fa fa-pencil"></i> Save Memo</Button>
      </Collapse>
    </div>
    <Row>
      <Col sm="6">
        <div className="page-title">
          <i className="fa fa-reorder"></i>&nbsp;Branch Registered User Profile - {state.user.first_name+" "+state.user.last_name}
        </div>
        <NavLink to={"/client/"+state.clientFk+'/'+state.params+"/branch/"+state.branchFk}>Back to client branch</NavLink>
      </Col>
      <Col sm="6" className="align-right">
      </Col>
    </Row>
    <div className="my-divider"></div>
    <NavLink to="#" onClick={toggleEmailDropDown}>Manage email notifications</NavLink>&nbsp;&nbsp;|&nbsp;&nbsp;<NavLink to="#" onClick={toggleTransferUserAccount}>Transfer User Account</NavLink>&nbsp;&nbsp;|&nbsp;&nbsp;<NavLink to="#" onClick={()=>{
      confirmation(
        ()=>{removeBranchUser(state.id)},
        ()=>{},
        'Delete client account?',
        'Are you sure you want to delete this client account?');
    }}><font color="red">Delete Client Account</font></NavLink>
    <br/>
    <Nav tabs>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.activeTab === '1'?"active":"inactive" )}
          onClick={() => { activeTabToggle('1'); }}
          to="#"
        >
          Profile
        </NavLinkL>
      </NavItem>
      <NavItem>
        <NavLinkL
          className={"nav-link "+(state.activeTab === '2'?"active":"inactive" ) }
          onClick={() => { activeTabToggle('2'); }}
          to="#"
        >
          Order History
        </NavLinkL>
      </NavItem>
    </Nav>
    <TabContent activeTab={state.activeTab}>
      <TabPane tabId="1">
        <Card>
          <CardHeader className="header-color">
            Basic Information
          </CardHeader>
          <CardBody>
            <Row>
              <Col sm="6">
                <Row>
                  <Col sm="5">
                    Date Joined
                  </Col>
                  <Col sm="7">
                    {formatDate(state.user.datetime_created)}
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    Author
                  </Col>
                  <Col sm="7">
                    {state.user.author}
                  </Col>
                </Row>
                <br/>
                <Row>
                  <Col sm="5">
                    First name
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="text"
                      value={state.user.first_name}
                      updateFunc={
                        generateUpdateFunction('user/update',[{field:'field',value:'first_name'},{field:'ID',value:state.user.ID}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    Last name
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="text"
                      value={state.user.last_name}
                      updateFunc={
                        generateUpdateFunction('user/update',[{field:'field',value:'last_name'},{field:'ID',value:state.user.ID}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    Status
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="select"
                      opt={[{key:'activated',value:'activated'},{key:'deactivated',value:'deactivated'}]}
                      value={state.user.status}
                      updateFunc={
                        generateUpdateFunction('client/user/update',[{field:'field',value:'status'},{field:'ID',value:state.user.ID}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    Role
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="select"
                      opt={[{key:'Loan Officer',value:'Loan Officer'},{key:'Loan Processor',value:'Loan Processor'},{key:'Broker',value:'Broker'}]}
                      value={state.user.role}
                      updateFunc={
                        generateUpdateFunction('client/branch/user/update',[{field:'field',value:'role'},{field:'ID',value:state.user.bID}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    Public Sign Up - Verification Needed
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="select"
                      opt={[{key:'yes',value:'yes'},{key:'no',value:'no'}]}
                      value={state.user.public_sign_up_verification_needed}
                      updateFunc={
                        generateUpdateFunction('user/update',[{field:'field',value:'public_sign_up_verification_needed'},{field:'ID',value:state.user.ID}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    Company name
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="text"
                      value={state.client.company}
                      updateFunc={
                        generateUpdateFunction('client/update',[{field:'field',value:'company'},{field:'ID',value:state.clientFk}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    Email
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="text"
                      value={state.user.email}
                      updateFunc={
                        generateUpdateFunction2('client/changeEmail',[{field:'userID',value:state.user.ID}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    Phone
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="text"
                      value={state.user.phone}
                      updateFunc={
                        generateUpdateFunction('user/update',[{field:'field',value:'phone'},{field:'ID',value:state.user.ID}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Modal className="my-modal" isOpen={state.userEmailDropDownOpen} toggle={toggleEmailDropDown} >
                      <ModalHeader hidden={true} toggle={toggleEmailDropDown}></ModalHeader>
                      <ModalBody>
                        <center>
                          <h5><i className="fa fa-plus"></i> Email notification</h5>
                        </center>
                        <br/>
                        <div className="large-scroll-container">
                          <table className="table table-striped">
                            <tbody>
                            {
                              state.userEmailSettings.map(
                                (emailSetting,index)=>{
                                  return(
                                    <tr key={index}>
                                      <td>
                                          <Row>
                                            <Col sm="6">
                                              {emailSetting.subject}
                                            </Col>
                                            <Col sm="6" className="align-right">
                                              <Toggle
                                                checked={emailSetting.blocked==='no'}
                                                icons={false}
                                                onChange={()=>emailNotificationToggle(emailSetting.subject)} />
                                            </Col>
                                          </Row>
                                      </td>
                                    </tr>
                                  )
                                }
                              )
                            }
                            </tbody>
                          </table>
                        </div>
                        <br/>
                        <center>
                          <Button color="info" onClick={toggleEmailDropDown}>Close</Button>
                        </center>
                      </ModalBody>
                    </Modal>
                  </Col>
                </Row>
                <br/>
                <label>Branch Information</label>
                <Row>
                  <Col sm="5">
                    Branch
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="text"
                      value={state.branch.branch_name}
                      updateFunc={
                        generateUpdateFunction('client/branch/update',[{field:'field',value:'branch_name'},{field:'ID',value:state.branchFk}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    Street
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="text"
                      value={state.branch.street}
                      updateFunc={
                        generateUpdateFunction('client/branch/update',[{field:'field',value:'street'},{field:'ID',value:state.branchFk}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    City
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="text"
                      value={state.branch.city}
                      updateFunc={
                        generateUpdateFunction('client/branch/update',[{field:'field',value:'city'},{field:'ID',value:state.branchFk}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    County
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="text"
                      value={state.branch.county}
                      updateFunc={
                        generateUpdateFunction('client/branch/update',[{field:'field',value:'county'},{field:'ID',value:state.branchFk}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    State
                  </Col>
                  <Col sm="7">
                    {states}
                  </Col>
                </Row>
                <Row>
                  <Col sm="5">
                    Zip
                  </Col>
                  <Col sm="7">
                    <MyXEditable
                      type="text"
                      value={state.branch.zip}
                      updateFunc={
                        generateUpdateFunction('client/branch/update',[{field:'field',value:'zip'},{field:'ID',value:state.branchFk}],successMessage, failedMessage, updateFieldCallBack)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </TabPane>
    </TabContent>
    <TabContent activeTab={state.activeTab}>
      <TabPane tabId="2">
        <Card>
          <CardHeader className="header-color">
            Order History
          </CardHeader>
          <CardBody style={{height:'650px'}}>
            <div className="ultra-large-scroll-container">
              <InfiniteScroll
                next={loadMore}
                dataLength={state.historyOrders.length}
                hasMore={state.hasMoreOrders}
                loader={<div key="nill" className="loader"><center>Loading more orders...</center></div>}
                initialLoad = {true}
                className="my-well"
                scrollableTarget="contentContainer"
              >
                {renderHistoryAppraisals()}
              </InfiniteScroll>
            </div>
          </CardBody>
        </Card>
      </TabPane>
    </TabContent>
  </div>;
}

export default ClientBranchUser;
