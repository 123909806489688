//Author Sooyoung Kim
//Date Aug 29, 
import {Card, CardHeader, CardBody, Col, Row, Input} from 'reactstrap';
import {generateTooltip} from '../../util/util';
import React, {useEffect} from 'react';
import MySelect from '../util/my-select';

const StepInspection  = (props)=>{
  const controller = new AbortController();

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const removeField = (field, value) => {
    if(!props.disabled){
      let target = props[field].slice();
      let index = target.indexOf(value);

      if(index!==-1)
        target.splice(index, 1);

      props.updateState({[field]:target});
    }
  }

  const updateField = (field, value) => {
    let target = props[field].slice();
    if(value==='None'){
      target = ['None'];
    }
    else if(value!==''){
      let index = target.indexOf('None');

      if(index!==-1)
        target.splice(index, 1);

      index = target.indexOf(value);

      if(index===-1)
        target.push(value);
    }
    props.updateState({[field]:target});
  }

  //render
  let disabledStyling = {border:'none', background:'#fff', padding:'0',paddingLeft:'2px', height:'auto',WebkitAppearance:'none'};
  let requiredInspections;

  if(props.requiredInspections.length>0)
    requiredInspections = props.requiredInspections.map(
      (requiredInspection, index)=>{
        return(
          <div key={index} className="display-inline margin-top">
            <div className="filter-container" onClick={()=>removeField('requiredInspections', requiredInspection)}>
              <i hidden={props.disabled} className="fa fa-minus red-color"></i> {requiredInspection}
            </div>
            &nbsp;
          </div>
        );
      }
    )

  let alteredIndicator;

  if(props.alteredIndicator.length>0)
    alteredIndicator = props.alteredIndicator.map(
      (b, index)=>{
        return(
          <div key={index} className="display-inline margin-top">
            <div className="filter-container" onClick={()=>removeField('alteredIndicator', b)}>
              <i hidden={props.disabled} className="fa fa-minus red-color"></i> {b}
            </div>
            &nbsp;
          </div>
        );
      }
    )
  let endUnit;
  if(props.attachmentType==='True'){
    endUnit = <Col sm="4">
      <label>End Unit</label>
      <MySelect
        type="select"
        style={props.disabled?disabledStyling:{}}
        disabled={props.disabled}
        value={props.attachmentTypeEndUnit}
        onChange={(v)=>props.updateState({attachmentTypeEndUnit:v})}
        options={[
          {label:"",value:""},
          {label:"Yes",value:"True"},
          {label:"No",value:"False"},
        ]}
      />
    </Col>;
  }

  let storiesNumber;
  let projectStoriesNumber;
  let unitStoriesNumber;
  let elevatorsNumber;
  let floorNumber;

  storiesNumber = <Col sm="4">
    <label>{((props.structureType.indexOf('PrimaryDwelling')!==-1|| props.structureType.indexOf('ADU')!==-1) && props.propertyType!=='Condo')?<font color="red">*</font>:null}Number of stories {generateTooltip('storiesNumber','The number of whole or partial stories of the property.')}</label>
    <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} type="number" required={((props.structureType.indexOf('PrimaryDwelling')!==-1|| props.structureType.indexOf('ADU')!==-1) && props.propertyType!=='Condo')} value={props.storiesNumber} onChange={(e)=>{props.updateState({storiesNumber:e.target.value})}}/>
  </Col>;


  if(props.propertyType==='Condo'){
    projectStoriesNumber = <Col sm="4">
      <label>{props.propertyType==='Condo'?<font color="red">*</font>:null}Number of stories for project {generateTooltip('projectStoriesNumber','The number of whole or partial stories of the Project.')}</label>
      <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} type="number" required={props.propertyType==='Condo'} value={props.projectStoriesNumber} onChange={(e)=>{props.updateState({projectStoriesNumber:e.target.value})}}/>
    </Col>;

    unitStoriesNumber = <Col sm="4">
      <label>{props.propertyType==='Condo'?<font color="red">*</font>:null}Number of stories for unit {generateTooltip('unitStoriesNumber','The number of whole or partial stories of the unit.')}</label>
      <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={props.propertyType==='Condo'} type="number" value={props.unitStoriesNumber} onChange={(e)=>{props.updateState({unitStoriesNumber:e.target.value})}}/>
    </Col>;

    elevatorsNumber = <Col sm="4">
      <label>{props.propertyType==='Condo'?<font color="red">*</font>:null}Number of elevators</label>
      <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={props.propertyType==='Condo'} type="number" value={props.elevatorsNumber} onChange={(e)=>{props.updateState({elevatorsNumber:e.target.value})}}/>
    </Col>;

    floorNumber = <Col sm="4">
      <label>{props.propertyType==='Condo'?<font color="red">*</font>:null}Floor Number</label>
      <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={props.propertyType==='Condo'} type="number" value={props.floorNumber} onChange={(e)=>{props.updateState({floorNumber:e.target.value})}}/>
    </Col>;
  }

  return <div>
    <Card>
      <CardHeader className="header-color">
        Repairs / Inspections
      </CardHeader>
      <CardBody>
        <Row style={{marginTop:'15px'}}>
          <Col sm="5">
            <label>
              <div style={{height:'50px'}}>
                <font color="red">*</font>Are any parts of the subject property under construction or incomplete?
              </div>
            </label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={true}
              value={props.constructionStatus}
              onChange={(v)=>props.updateState({constructionStatus:v})}
              options={[
                {label:"",value:""},
                {label:"Under Construction",value:"UnderConstruction"},
                {label:"Incomplete",value:"Incomplete"},
                {label:"Proposed",value:"Proposed"},
                {label:"Completed",value:"Completed"},
              ]}
            />
          </Col>
          <Col sm="3">
            <label>
              <div style={{height:'50px'}}>
                {(props.structureType.indexOf('PrimaryDwelling')!==-1|| props.structureType.indexOf('ADU')!==-1)?<font color="red">*</font>:null}Required Repairs {generateTooltip('requiredRepairs','Additional comments related to any needed repairs.')}
              </div>
            </label>
            <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={(props.structureType.indexOf('PrimaryDwelling')!==-1|| props.structureType.indexOf('ADU')!==-1)} type="text" value={props.requiredRepairs} onChange={(e)=>{props.updateState({requiredRepairs:e.target.value})}}/>
          </Col>
          <Col sm="4">
            <label>
              <div style={{height:'50px'}}>
                {(props.structureType.indexOf('PrimaryDwelling')!==-1|| props.structureType.indexOf('ADU')!==-1)?<font color="red">*</font>:null}Required Inspection {generateTooltip('requiredInspections','Indicate the type of inspections required. Select all that apply.')}
              </div>
            </label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={(props.structureType.indexOf('PrimaryDwelling')!==-1|| props.structureType.indexOf('ADU')!==-1) && props.requiredInspections.length<=0}
              value={props.requiredInspections}
              onChange={(v)=>updateField('requiredInspections',v)}
              options={[
                {label:"",value:""},
                {label:"Pest",value:"Pest"},
                {label:"Structural",value:"Structural"},
                {label:"Roof",value:"Roof"},
                {label:"HVAC",value:"HVAC"},
                {label:"Electrical",value:"Electrical"},
                {label:"Plumbing",value:"Plumbing"},
                {label:"Sewer",value:"Sewer"},
                {label:"Mold",value:"Mold"},
                {label:"None",value:"None"},
              ]}
            />
            <div className="extra-small-scroll-container">
              {requiredInspections}
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
    <br/>
    <Card>
      <CardHeader className="header-color">
        Improvements
      </CardHeader>
      <CardBody>
        <Row style={{marginTop:'15px'}}>
          <Col sm="4">
            <label><font color="red">*</font>Accessory Unit {generateTooltip('accessoryUnit','Indicates whether the property has at least one accessory unit.')}</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={true}
              value={props.accessoryUnit}
              onChange={(v)=>props.updateState({accessoryUnit:v})}
              options={[
                {label:"",value:""},
                {label:"Yes",value:"True"},
                {label:"No",value:"False"},
              ]}
            />
          </Col>
          {storiesNumber}
          {projectStoriesNumber}
        </Row>
        <Row style={{marginTop:'15px'}}>
          {unitStoriesNumber}
          {elevatorsNumber}
          {floorNumber}
        </Row>
        <Row style={{marginTop:'15px'}}>
          <Col sm="4">
            <label><font color="red">*</font>Attachment Type</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={true}
              value={props.attachmentType}
              onChange={(v)=>props.updateState({attachmentType:v})}
              options={[
                {label:"",value:""},
                {label:"Yes",value:"True"},
                {label:"No",value:"False"},
              ]}
            />
          </Col>
          {endUnit}
          <Col sm="4">
            <label><font color="red">*</font>Building Completion Status</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={true}
              value={props.buildingCompletion}
              onChange={(v)=>props.updateState({buildingCompletion:v})}
              options={[
                {label:"",value:""},
                {label:"Existing",value:"Existing"},
                {label:"Proposed",value:"Proposed"},
                {label:"Under Construction",value:"UnderConstruction"},
              ]}
            />
          </Col>
        </Row>
        <Row style={{marginTop:'15px'}}>
          <Col sm="4">
            <label>{((props.structureType.indexOf('PrimaryDwelling')!==-1|| props.structureType.indexOf('ADU')!==-1)||(props.foundationTypes.indexOf('Basement')!==-1|| props.foundationTypes.indexOf('Partial Basement')!==-1))?<font color="red">*</font>:null}Sump Pump Indicator</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={((props.structureType.indexOf('PrimaryDwelling')!==-1|| props.structureType.indexOf('ADU')!==-1) || (props.foundationTypes.indexOf('Basement')!==-1|| props.foundationTypes.indexOf('Partial Basement')!==-1))}
              value={props.sumpPump}
              onChange={(v)=>props.updateState({sumpPump:v})}
              options={[
                {label:"",value:""},
                {label:"Yes",value:"True"},
                {label:"No",value:"False"},
              ]}
            />
          </Col>
        </Row>
        <Row style={{marginTop:'15px'}}>
          <Col sm="4">
            <label>
              <div style={{height:'100px'}}>
                {(props.structureType.indexOf('PrimaryDwelling')!==-1|| props.structureType.indexOf('ADU')!==-1)?<font color="red">*</font>:null}Property is not livable indicator
              </div>
            </label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={(props.structureType.indexOf('PrimaryDwelling')!==-1|| props.structureType.indexOf('ADU')!==-1)}
              value={props.notLivableIndicator}
              onChange={(v)=>props.updateState({notLivableIndicator:v})}
              options={[
                {label:"",value:""},
                {label:"Yes",value:"True"},
                {label:"No",value:"False"},
              ]}
            />
          </Col>
          <Col sm="4">
            <label>
              <div style={{height:'100px'}}>
                {props.notLivableIndicator==='True'?<font color="red">*</font>:null}Property is not livable comment
              </div>
            </label>
            <Input style={props.disabled?disabledStyling:{}} disabled={props.disabled} required={props.notLivableIndicator==='True'} type="text" value={props.notLivableComment} onChange={(e)=>{props.updateState({notLivableComment:e.target.value})}}/>
          </Col>
          <Col sm="4">
            <label>
              <div style={{height:'100px'}}>
                {(props.structureType.indexOf('PrimaryDwelling')!==-1|| props.structureType.indexOf('ADU')!==-1)?<font color="red">*</font>:null}Are there any apparent defects or deficiencies to the electrical, plumbing systems, water or sewer that may cause them to function inadequately for their intended purpose?
              </div>
            </label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={(props.structureType.indexOf('PrimaryDwelling')!==-1|| props.structureType.indexOf('ADU')!==-1)}
              value={props.defectIndicator}
              onChange={(v)=>props.updateState({defectIndicator:v})}
              options={[
                {label:"",value:""},
                {label:"Yes",value:"True"},
                {label:"No",value:"False"},
              ]}
            />
          </Col>
        </Row>
        <Row style={{marginTop:'15px'}}>
          <Col sm="4">
            <label>
              <div style={{height:'100px'}}>
                <font color="red">*</font>Has the property been altered or modified specifically to support or facilitate any non-residential, or income producing use? {generateTooltip('alteredIndicator', 'Select all that apply.')}
              </div>
            </label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={props.alteredIndicator.length<=0}
              value={""}
              onChange={(v)=>updateField('alteredIndicator', v)}
              options={[
                {label:"",value:""},
                {label:"Retail",value:"Retail"},
                {label:"Hospitality",value:"Hospitality"},
                {label:"Restaurant",value:"Restaurant"},
                {label:"Agricultural",value:"Agricultural"},
                {label:"Commercial",value:"Commercial"},
                {label:"ChildCare",value:"ChildCare"},
                {label:"ElderCare",value:"ElderCare"},
                {label:"Services",value:"Services"},
                {label:"None",value:"None"},
              ]}
            />
            {alteredIndicator}
          </Col>
          <Col sm="4">
            <label>
              <div style={{height:'100px'}}>
                {(props.structureType.indexOf('PrimaryDwelling')!==-1|| props.structureType.indexOf('ADU')!==-1)?<font color="red">*</font>:null}Does the property appear to be constructed to community standards, is not of poor quality, substandard or non-conforming workmanlike manner?
              </div>
            </label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={(props.structureType.indexOf('PrimaryDwelling')!==-1|| props.structureType.indexOf('ADU')!==-1)}
              value={props.upToStandard}
              onChange={(v)=>props.updateState({upToStandard:v})}
              options={[
                {label:"",value:""},
                {label:"Yes",value:"True"},
                {label:"No",value:"False"},
              ]}
            />
          </Col>
          <Col sm="4">
            <label>
              <div style={{height:'100px'}}>
                {(props.structureType.indexOf('PrimaryDwelling')!==-1|| props.structureType.indexOf('ADU')!==-1)?<font color="red">*</font>:null}Does the property have adequate access and appropriate systems suitable for year-round occupancy?
              </div>
            </label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={(props.structureType.indexOf('PrimaryDwelling')!==-1|| props.structureType.indexOf('ADU')!==-1)}
              value={props.allSeasonAccessToSystem}
              onChange={(v)=>props.updateState({allSeasonAccessToSystem:v})}
              options={[
                {label:"",value:""},
                {label:"Yes",value:"True"},
                {label:"No",value:"False"},
              ]}
            />
          </Col>
        </Row>
        <Row style={{marginTop:'15px'}}>
          <Col sm="4">
            <label>{(props.structureType.indexOf('PrimaryDwelling')!==-1|| props.structureType.indexOf('ADU')!==-1)?<font color="red">*</font>:null}Is there any apparent fire or water damage that may compromise the livability, safety, soundness, or structural integrity of the property?</label>
            <MySelect
              type="select"
              style={props.disabled?disabledStyling:{}}
              disabled={props.disabled}
              required={(props.structureType.indexOf('PrimaryDwelling')!==-1|| props.structureType.indexOf('ADU')!==-1)}
              value={props.structuralDamage}
              onChange={(v)=>props.updateState({structuralDamage:v})}
              options={[
                {label:"",value:""},
                {label:"Yes",value:"True"},
                {label:"No",value:"False"},
              ]}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  </div>;
}


export default StepInspection;
