//Author Sooyoung Kim
//Date May 23rd, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, deleteAPICallGenerator, callBackGenerator, confirmation, sliceFromArray} from '../../util/util';
import {Button, Col, Row, Card, CardHeader, CardBody} from 'reactstrap';
import MyXEditable from '../util/my-xeditable';
import MySelect from '../util/my-select';
import {NavLink, useParams, useNavigate} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';


//initialize the state
const initialState = {
  client:{},
  newDelegatorClient:'',
  clients:[],
  delegatorAssociateClients:[],
};

//reducer function that perform state update
const reducer = getReducer();


const DelegatorClient  = (props)=>{
  const controller = new AbortController();
  const { id } = useParams();
  const history = useNavigate();

  let newInitialState = Object.assign({}, initialState, {id:id});
  const [state, dispatch] = useReducer(reducer, newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getDelegatorClient();
    getAllClientProfiles();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const updateFieldCallBack = (field, value) =>{
    console.log(field, value);
    let client = Object.assign({}, state.client);
    client[field] = value;
    setState({client:client});
  }

  //API call
  //generate a function that do the update on editable
  const generateUpdateFunction = (url, parameters, successMessage, failedMessage, parentCallBack=null) =>{
    return (newValue, callBack, errorCallBack)=>{
      //add in the new value into parameters

      parameters.push({field:'value',value:newValue});
      let promise = httpPut(url,parameters,successMessage,failedMessage, callBack, errorCallBack);
      promise.then(
        (response)=>{
          let field;
          let value;

          for(let i=0;i<parameters.length;i++){
            if(parameters[i].field==='field')
              field = parameters[i].value;
            else if(parameters[i].field==='value')
              value = parameters[i].value;
          }
          if(parentCallBack!==null){
            parentCallBack(field, value);
          }
        }
      );
    }
  };

  //get delegator client profile
  const getDelegatorClient = () =>{
    let url = 'client/delegator/'+state.id;
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        setState({client:response.data.data});
        getDelegatorAssociate(response.data.data.user_fk);
      }
    };
    callBack = callBack.bind(this);
    httpGet(url, '', 'Oops, something went wrong and could not load this delegator client profile. Please try again later.', callBack);
  }

  const getDelegatorAssociate = (userFk) =>{
    let url = 'client/delegator/associate/'+userFk;
    let callBack = apiCallBack([{state:'delegatorAssociateClients', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load this delegator client profile. Please try again later.', callBack);
  }

  const removeDelegatorAssociate = (id) =>{
    let url = 'client/delegator/associate/'+id;
    let list = sliceFromArray(state.delegatorAssociateClients,'ID', id);
    let callBack = apiCallBack([{state:'delegatorAssociateClients', value:list}]);
    httpDelete(url,'Client delegator associate deleted successfully.','Oops, something went wrong and could not delete this client delegator associate. Please try again later.', callBack);
  }

  const createDelegatorAssociate = () =>{
    let url = 'client/delegator/associate/create';
    let successMsg = 'Client wholesale associate created successfully.';
    let errorMsg = 'Oops, something went wrong and could not create this client wholesale associate. Please try again later.';

    let parameters = [
      {
        field:'userFk',
        value:state.client.user_fk
      },
      {
        field:'associatedClientFk',
        value:state.newDelegatorClient
      }
    ];


    let callBack = (response)=>{console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let delegatorAssociateClients = state.delegatorAssociateClients.slice();
        delegatorAssociateClients.push(response.data.data);

        setState({delegatorAssociateClients:delegatorAssociateClients});
      }
    };

    callBack = callBack.bind(this);
    httpPost(url, parameters, successMsg, errorMsg, callBack);
  }

  const getAllClientProfiles = () =>{
    let url = 'client/get/limit=-1&offset=-1';

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;

      if(code==='00'){

        let clients = [{key:'0', value:''}];
        for(let i=0;i<response.data.data.length;i++){
          let client = response.data.data[i];

          let tmp = {};
          tmp.key = client.ID;
          tmp.datetime_created = client.datetime_created;
          tmp.value = client.company;

          clients.push(tmp);
        }

        setState({clients:clients});
      }
    };
    callBack = callBack.bind(this);

    httpGet(url, '', 'Oops, something went wrong and could not retrieve client profiles.', callBack);
  }

  //delete delegator client
  const deleteDelegatorClient = (id) =>{
    let url = 'client/delegator/'+id;
    //call back for delete announcement
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        history('/client/delegator');
      }
    };
    callBack = callBack.bind(this);

    httpDelete(url,'delegator client\'s profile deleted.','Oops, something went wrong and could not delete this delegator client\'s profile. Please try again later.', callBack);
  }

  //render
  let successMessage = 'delegator client profile updated.';
  let failedMessage = 'Failed to update the value of this field, please try again later.';

  return <div className="my-well">
    <Row>
      <Col sm="6">
        <div className="page-title">
          <i className="fa fa-reorder"></i>&nbsp;Delegator Client
        </div>
        <NavLink to="/client/delegator">Back to delegator client list</NavLink>
      </Col>
      <Col sm="6" className="align-right">
        <Button color="danger" onClick={
          ()=>{
            confirmation(
              ()=>{deleteDelegatorClient(state.client.ID)},
              ()=>{},
              'Delete delegator client profile?',
              'Are you sure you want to delete this delegator client\'s profile?');
          }}>Delete</Button>
      </Col>
    </Row>
    <div className="my-divider"></div>

    <Row>
      <Col sm="4">
        <Row>
          <Col sm="4">
            <label>Email</label>
          </Col>
          <Col sm="8">
            {state.client.email}
          </Col>
        </Row>
      </Col>
      <Col sm="4">
        <Row>
          <Col sm="4">
            <label>Status</label>
          </Col>
          <Col sm="8">
            <MyXEditable
              type="select"
              opt={[{key:'active',value:'active'},{key:'inactive',value:'inactive'}]}
              value={state.client.status}
              updateFunc={
                generateUpdateFunction('client/delegator/update',[{field:'field',value:'status'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
              }
            />
          </Col>
        </Row>
      </Col>
    </Row>
    <br/><br/>
    <Row>
      <Col sm="4">
        <Row>
          <Col sm="4">
            <label>First name</label>
          </Col>
          <Col sm="8">
            <MyXEditable
              type="text"
              value={state.client.first_name}
              updateFunc={
                generateUpdateFunction('user/update',[{field:'field',value:'first_name'},{field:'ID',value:state.client.user_fk}],successMessage, failedMessage, updateFieldCallBack)
              }
            />
          </Col>
        </Row>
      </Col>
      <Col sm="4">
        <Row>
          <Col sm="4">
            <label>Last name</label>
          </Col>
          <Col sm="8">
            <MyXEditable
              type="text"
              value={state.client.last_name}
              updateFunc={
                generateUpdateFunction('user/update',[{field:'field',value:'last_name'},{field:'ID',value:state.client.user_fk}],successMessage, failedMessage, updateFieldCallBack)
              }
            />
          </Col>
        </Row>
      </Col>
    </Row>
    <Row>
      <Col sm="4">
        <Row>
          <Col sm="4">
            <label>Notify emails</label>
          </Col>
          <Col sm="8">
            <MyXEditable
              type="text"
              value={state.client.notify_emails}
              updateFunc={
                generateUpdateFunction('client/delegator/update',[{field:'field',value:'notify_emails'},{field:'ID',value:state.client.ID}],successMessage, failedMessage, updateFieldCallBack)
              }
            />
          </Col>
        </Row>
      </Col>
      <Col sm="4">
        <Row>
          <Col sm="4">
            <label>Phone</label>
          </Col>
          <Col sm="8">
            <MyXEditable
              type="text"
              value={state.client.phone}
              updateFunc={
                generateUpdateFunction('user/update',[{field:'field',value:'phone'},{field:'ID',value:state.client.user_fk}],successMessage, failedMessage, updateFieldCallBack)
              }
            />
          </Col>
        </Row>
      </Col>
    </Row>
    <br/>
    <Card>
      <CardHeader className="header-color">
        Associated Delegator Client
      </CardHeader>
      <CardBody>
        <Row>
          <Col sm="3">
            <label>New Delegator Associated Client</label>
          </Col>
          <Col sm="6">
            <MySelect
              type="select"
              value={state.newDelegatorClient}
              onChange={(v)=>setState({newDelegatorClient:v})}
              options={state.clients.map((client)=>{
                return {label:client.value, value:client.key};
              })}
            />
          </Col>
          <Col sm="3">
            <Button color="warning" onClick={createDelegatorAssociate}>Add</Button>
          </Col>
        </Row>
        <br/>
        {
          state.delegatorAssociateClients.map(
            (client, key)=>{
              return(
                <div key={key} className="entity-container">
                  <i className="fa fa-times red-color cursor-pointer" onClick={(e)=>removeDelegatorAssociate(client.ID)}/> {client.company}
                </div>
              )
            }
          )
        }
      </CardBody>
    </Card>
  </div>;
}

export default DelegatorClient;
