//Author Sooyoung Kim
//Date July 25, 20231
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, callBackGenerator, showMessage, getSession} from '../../util/util';
import {Button, Label, Card, CardHeader, CardBody, Input, Row, Col, Table, TabPane, TabContent, Nav, NavItem, NavLink} from 'reactstrap';
import MySelect from './../util/my-select';
import MyDropzone from './../util/my-dropzone';
import React, {useReducer, useEffect} from 'react';
let session = getSession();

//initialize the state
const initialState = {
  toUploadFiles:[],
  activeTab:'1',
  errorMessage:'',
  uploadDisabled:false,
  result:[],
  request:[],
  email:session.email,
  investor:'',
  clients:[],
  targetClients:[],
  columnHeaders:[],
};

//reducer function that perform state update
const reducer = getReducer();


const ScrubAppraiserExclusionaryList  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getAllClientProfiles();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const updateRequest = (index, property, value) => {
    let request = state.request.slice();

    for(let i=0;i<request.length;i++){
      if(request[i].index===index){
        let obj = Object.assign({}, request[i]);
        if(property==='jumbo'){
          if(request[i].jumbo === 'no'){
            obj[property] = value;
          }else{
            obj[property] = 'no';
          }
        }
        else if(property==='removal'){
          if(request[i].removal === 'no'){
            obj[property] = value;
          }else{
            obj[property] = 'no';
          }
        }
        else{
          obj[property] = value;
        }

        request[i] = obj;
        break;
      }
    }

    setState({request:request});
  }

  const getColumnHeaders = (index) => {
    let columnHeaders = state.columnHeaders.slice();

    for(let i=0;i<columnHeaders.length;i++){
      if(columnHeaders[i].index===index)
        return columnHeaders[i].headers;
    }

    return [];
  }

  const getSheet = (index) => {
    let request = state.request.slice();
    console.log(request);

    for(let i=0;i<request.length;i++){
      if(request[i].index===index)
        return request[i];
    }

    return null;
  }

  const modifyRequest = (index) => {
    let filteredRequest = state.request.filter(
      (request)=>{
        return request.index !==index;
      }
    );

    if(filteredRequest.length===state.request.length){
      let tmp = {};
      tmp.index = index;
      tmp.fName = 0;
      tmp.lName = 1;
      tmp.state = 2;
      tmp.city = '';
      tmp.jumbo = 'no';
      tmp.removal = 'no';

      filteredRequest.push(tmp);
    }

    setState({request:filteredRequest});
  }

  //toggle function
  const activeTabToggle = (tab) => {
    setState({activeTab:tab});
  }

  const modifyTargetClient = (targetClient) => {
    let filteredClients = state.targetClients.filter(
      (client)=>{
        return client.key!==targetClient.key;
      }
    )


    if(filteredClients.length===state.targetClients.length){
      filteredClients.push(targetClient);
    }

    setState({targetClients:filteredClients});
  }

  const uploadButtonHandler = () => {
    if(state.uploadDisabled)
      return;

    setState({uploadDisabled:true},uploadAll());
  }

  //remove a specific file from the toUpload list.
  const removeToUploadFile = (preview) => {
    let toRemoveIndex = -1;
    for(let i=0;i<state.toUploadFiles.length;i++){
      if(state.toUploadFiles[i].preview===preview){
        toRemoveIndex = i;
        break;
      }
    }

    if(toRemoveIndex!==-1){
      let newFiles = state.toUploadFiles.slice();
      newFiles.splice(toRemoveIndex,1);

      setState({toUploadFiles:newFiles});
    }
  }

  //function trigger for dropzone react.
  //this function contians two list of files, the accepted and rejected file per the configuration
  const onDrop = (acceptedFiles, rejectedFiles) => {
    let existingFiles = [];

    if(acceptedFiles.length>0)
      existingFiles.push(acceptedFiles[0]);
    setState({toUploadFiles: existingFiles});
  }


  //API call
  //function that upload all toUploadFiles to the server by calling asyncPost
  const uploadAll = () => {
    let preCheck = true;
    let errorMessage = '';

    if(state.toUploadFiles.length<=0){
      preCheck = false;
      errorMessage = 'Please upload at least one file.';
    }

    //only proceed when no error
    if(preCheck){
      setState({errorMessage:'Uploading...Please do not close the window.'});

      let fileCompleted = 0;
      for(let i=0;i<state.toUploadFiles.length;i++){
        //skip file that has done upload
        if(state.toUploadFiles[i].status==='Done')
          continue;
        const reader = new FileReader();
        // eslint-disable-next-line no-loop-func
        reader.onload = () => {
            const fileAsBinaryString = reader.result;
            let base64 = btoa(fileAsBinaryString);

            let callBack = (response)=>{
              console.log(response);
              let code = response.data?response.data.code:undefined;

              if(code==='00'){
                // get cell headers
                let columnHeaders = [];
                for(let i=0;i<response.data.data.length;i++){
                  let rows = response.data.data[i].rows;

                  if(rows.length>0){
                    let cells = rows[0];

                    let tmp = { index:i, headers:[] };
                    for(let j=0;j<cells.length;j++){
                      tmp.headers.push(cells[j]);;
                    }

                    columnHeaders.push(tmp);
                  }
                }

                setState({result:response.data.data, columnHeaders:columnHeaders});
              }

              fileCompleted++;
              if(fileCompleted>=state.toUploadFiles.length){
                setState({uploadDisabled:false, errorMessage:''});
              }
            };

            callBack = callBack.bind(this);

            let errorCallBack = (error)=>{
              fileCompleted++;

              if(fileCompleted>=state.toUploadFiles.length){
                setState({uploadDisabled:false, errorMessage:''});
              }
            };
            errorCallBack = errorCallBack.bind(this);

            let parameters = [
              {
                field:'file_encoded',
                value:base64
              },
              {
                field:'name',
                value:state.toUploadFiles[i].name
              }
            ];

            httpPost('appraiser/exclusionaryList/upload', parameters, '', 'Oops, something went wrong and could not upload the file "'+state.toUploadFiles[i].name+'". Please try again later.', callBack, errorCallBack);
        };
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => {
          showMessage('error','File upload failed, please try again later.');
        };

        reader.readAsBinaryString(state.toUploadFiles[i]);
      }
      setState({uploadDisabled:false});
    }
    else{
      setState({errorMessage:errorMessage});
      setTimeout(()=>setState({uploadDisabled:false}),1000);
    }
  }

  const sendScrubRequest = () => {
    for(let i=0;i<state.toUploadFiles.length;i++){
      //skip file that has done upload
      if(state.toUploadFiles[i].status==='Done')
        continue;
      const reader = new FileReader();
      reader.onload = () => {
          const fileAsBinaryString = reader.result;
          let base64 = btoa(fileAsBinaryString);

          let targetClients = state.targetClients.slice();
          let clients = [];

          for(let i=0;i<targetClients.length;i++)
            clients.push(targetClients[i].key);

          let parameters = [
            {
              field:'clients',
              value:clients
            },
            {
              field:'email',
              value:state.email
            },
            {
              field:'investor',
              value:state.investor
            },
            {
              field:'request',
              value:state.request
            },
            {
              field:'file_encoded',
              value:base64
            },
            {
              field:'name',
              value:state.toUploadFiles[i].name
            }
          ];

          httpPost('appraiser/exclusionaryList/upload/scrub', parameters, 'Request sent successfully, you will be notified via email when the result is ready.', 'Oops, something went wrong and could finish the scrub request. Please try again later.');
      };
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => {
        showMessage('error','File upload failed, please try again later.');
      };

      reader.readAsBinaryString(state.toUploadFiles[i]);
    }
  }


  const getAllClientProfiles = () => {
    let callBack = apiCallBack([{state:'clients', valuekey:['ID', 'company']}]);
    httpGet('client/get/limit=-1&offset=-1', '', 'Oops, something went wrong and could not retrieve client profiles.', callBack);
  }

  //render
  let clients;

  if(state.clients.length>0)
    clients = state.clients.map(
      (client, index)=>{
        return <tr onClick={(e)=>modifyTargetClient(client)} value={client.key} key={index}><td>{client.value}</td></tr>;
      }
    );

  let toUploadFiles;
  if(state.toUploadFiles.length>0){
    toUploadFiles = state.toUploadFiles.map(
      (file,index)=>{
        return <div key={index}><i className="fa fa-times red-color cursor-pointer" onClick={()=>removeToUploadFile(file.preview)}></i> {file.name}</div>
      }
    );

  }

  let scrubResult;

  if(state.result.length>0){
    scrubResult = <div>
      <Card>
        <CardHeader className="header-color">
          <i className="fa fa-cog"></i> Scrub Configuration
        </CardHeader>
        <CardBody>
          <label>*The scrub could takes a very long time, please provide your email below so the system would notify you when it is finish.</label>
          <br/>
          <Row>
            <Col sm="5">
              <label>Email</label><br/>
              <Input type="text" value={state.email} onChange={(e)=>setState({email:e.target.value})}/>
            </Col>
            <Col sm="7">
              <label>Investor</label><br/>
              <Input type="text" value={state.investor} onChange={(e)=>setState({investor:e.target.value})}/>
            </Col>
          </Row>
          <br/>
          <label>Target Lender (Default to all lender if none specified)</label>
          <div className="medium-scroll-container">
            <Table className="table table-striped">
              <tbody>
                {clients}
              </tbody>
            </Table>
          </div>
          <div className="small-scroll-container">
            {
              state.targetClients.map(
                (client, index)=>{
                  return (
                    <div style={{display:'inline-block'}} key={index}>
                      <div className="entity-container cursor-pointer" onClick={()=>{modifyTargetClient(client)}}>
                        <i className="fa fa-minus link-color"></i>&nbsp;{client.value}
                      </div>&nbsp;
                    </div>
                  );
                }
              )
            }
          </div>
        </CardBody>
      </Card>
      <br/>
      <div className="my-divider">&nbsp;</div>

      <Card>
        <CardHeader className="header-color">
          <i className="fa fa-code"></i> Format Configuration
        </CardHeader>
        <CardBody>
          <Nav tabs>
            {
              state.result.map(
                (sheet, index)=>{
                  return (
                    <NavItem key={index}>
                      <NavLink
                        className={"cursor-pointer nav-link "+(state.activeTab === (index+1).toString()?"active":"inactive" )}
                        onClick={() => { activeTabToggle((index+1).toString()); }}
                        to="#"
                      >
                        {sheet.name}
                      </NavLink>
                    </NavItem>
                  )
                }
              )
            }
          </Nav>
          <TabContent activeTab={state.activeTab}>
            {
              state.result.map(
                (sheet, index)=>{
                  let sheetObj = getSheet(index);

                  let columnArrangement;

                  if(sheetObj){

                    let columHeaders = getColumnHeaders(index);

                    columnArrangement = <div>
                      <div className="padding display-inline" onClick={(e)=>updateRequest(index, 'removal', 'yes')}>
                        <Label check>
                          &nbsp;&nbsp;<Input checked={sheetObj.removal==='yes'} type="checkbox"/>&nbsp;Removal
                        </Label>
                      </div>
                      <div className="padding display-inline" onClick={(e)=>updateRequest(index, 'jumbo', 'yes')}>
                        <Label check>
                          &nbsp;&nbsp;<Input checked={sheetObj.jumbo==='yes'} type="checkbox"/>&nbsp;Excluded for Jumbo Loans
                        </Label>
                      </div>
                      <Row>
                        <Col sm="4">
                          <label>First name</label>
                        </Col>
                        <Col sm="8">
                          <MySelect
                            type="select"
                            value={sheetObj.fName}
                            onChange={(v)=>updateRequest(index, 'fName', v)}
                            options={[{label:"",value:""}].concat(columHeaders.map((col, index4)=>{
                              return {label:"Column "+(index4+1) +"- "+col, value:index4};
                            }))}
                            />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="4">
                          <label>Last name</label>
                        </Col>
                        <Col sm="8">
                          <MySelect
                            type="select"
                            value={sheetObj.lName}
                            onChange={(v)=>updateRequest(index, 'lName', v)}
                            options={[{label:"",value:""}].concat(columHeaders.map((col, index4)=>{
                              return {label:"Column "+(index4+1) +"- "+col, value:index4};
                            }))}
                            />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="4">
                          <label>State</label>
                        </Col>
                        <Col sm="8">
                          <MySelect
                            type="select"
                            value={sheetObj.state}
                            onChange={(v)=>updateRequest(index, 'state', v)}
                            options={[{label:"",value:""}].concat(columHeaders.map((col, index4)=>{
                              return {label:"Column "+(index4+1) +"- "+col, value:index4};
                            }))}
                            />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="4">
                          <label>City</label>
                        </Col>
                        <Col sm="8">
                          <MySelect
                            type="select"
                            value={sheetObj.city}
                            onChange={(v)=>updateRequest(index, 'city', v)}
                            options={[{label:"",value:""}].concat(columHeaders.map((col, index4)=>{
                              return {label:"Column "+(index4+1) +"- "+col, value:index4};
                            }))}
                            />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="4">
                          <label>License</label>
                        </Col>
                        <Col sm="8">
                          <MySelect
                            type="select"
                            value={sheetObj.license}
                            onChange={(v)=>updateRequest(index, 'license', v)}
                            options={[{label:"",value:""}].concat(columHeaders.map((col, index4)=>{
                              return {label:"Column "+(index4+1) +"- "+col, value:index4};
                            }))}
                            />
                        </Col>
                      </Row>
                    </div>;
                  }

                  return (
                    <TabPane key={index} tabId={(index+1).toString()}>

                      <br/>
                      <div className="padding display-inline" onClick={(e)=>modifyRequest(index)}>
                        <Label check>
                          &nbsp;&nbsp;<Input checked={sheetObj} type="checkbox"/>&nbsp;Scrub this sheet
                        </Label>
                      </div>

                      {columnArrangement}
                      <div className="my-divider">&nbsp;</div>

                      <Table className="table table-striped">
                        <tbody>
                          {
                            sheet.rows.map(
                              (row, index2)=>{
                                return <tr key={index2}>
                                  {
                                    row.map(
                                      (cell, index3)=>{
                                        return <td colSpan={(sheet.max_cell/row.length)} key={index3}>{cell}</td>
                                      }
                                    )
                                  }
                                </tr>
                              }
                            )
                          }
                        </tbody>
                      </Table>
                    </TabPane>
                  )
                }
              )
            }
          </TabContent>
        </CardBody>
      </Card>


      <br/>
      <div className="my-divider">&nbsp;</div>
      <div className="align-right">
        <Button color="warning" onClick={sendScrubRequest}>Submit</Button>
      </div>
    </div>;
  }

  return <div className="padding">
    <div className="page-title">
      <i className="fa fa-reorder"></i>&nbsp;Appraisers Exclusionary List - Scrub New Spreadsheet
    </div>
    <div className="my-divider"></div>

    <i>Please upload a spreadsheet to scrub against the exclusionary list</i><br/>
    <div className="small-scroll-container">
      {toUploadFiles}
    </div>
    <MyDropzone onDrop={onDrop}/>
    <br/>
    <div className="align-right">
      <Button color="warning" onClick={uploadButtonHandler}>Upload</Button>
    </div>
    <br/>
    <div className="my-divider">&nbsp;</div>

    {scrubResult}
  </div>;
}


export default ScrubAppraiserExclusionaryList;
