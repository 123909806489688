//Author Sooyoung Kim
//Date July 24, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, callBackGenerator, formatDateTime} from '../../util/util';
import {Card, CardBody, CardHeader, Row, Col, Button} from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import {NavLink, useNavigate} from 'react-router-dom';
import MySelect from './../util/my-select';
import MyFormStep from './../util/my-form-step';
import ReportFilter from './../report/report-filter';
import React, {useReducer, useEffect} from 'react';

//initialize the state
const initialState = {
  appraisers:[],
  limit: 50,
  offset: 0,
  hasMoreAppraisers: true,
  loading: false,
  sort: 'first_name',
  order: 'ASC',
  name:'',
  status:'',
  state:'',
  county:'',
  city:'',
  totalCount:0,
  currentStep:0,
  steps:['Select appraiser','Configure settings'],
  maxStep: 0,
  autoReview:'',
  generateSSR:'',
  skipPayment:'',
  autoRelayComment:''
};

//reducer function that perform state update
const reducer = getReducer();


const NewAMC  = (props)=>{
  const controller = new AbortController();
  let history = useNavigate();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    if(state.appraisers.length<=0 && state.hasMoreAppraisers){
        loadMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state]);

  //non API call but simpyl manage state
  const handleStepAdvance = (appraiser) => {
    setState({selectedAppraiser:appraiser, currentStep:1});
    if(state.maxStep<1)
      setState({maxStep:1});
  }

  const updateStep = (step) => {
    if(step<=state.maxStep)
      setState({currentStep:step});
  }

  //this function render the sort icon at each column of the table
  const renderSortIcon = (col) => {
    if(state.sort===col){
      if(state.order==='ASC')
        return <i className="red-color fa fa-arrow-down"></i>
      else
        return <i className="red-color fa fa-arrow-up"></i>
    }
  }

  //click handler that switch the state of sorting order then call the function to refresh the list
  //to achieve the "sorting"
  const columnClickHandler = (col) => {
    if(state.sort===col){
      if(state.order==='ASC')
        setState({order:'DESC'});
      else
        setState({order:'ASC'});
    }
    else
      setState({sort:col});
    refreshList();
  }

  //this function refresh the list of orders pipeline
  const refreshList = () => {
    setState({appraisers:[], hasMoreAppraisers:true, offset:0});
  }

  const renderAppraisers = () => {
    return (
      <div>
        <table className="primary-table">
          <thead>
            <tr>
              <th className="cursor-pointer" width="30%" onClick={()=>columnClickHandler('first_name')}><i className="fa fa-search"></i>&nbsp;Name {renderSortIcon('first_name')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('status')}><i className="fa fa-folder"></i>&nbsp;Status {renderSortIcon('status')}</th>
              <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('state')}><i className="fa fa-folder"></i>&nbsp;State {renderSortIcon('state')}</th>
              <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('county')}><i className="fa fa-folder"></i>&nbsp;County {renderSortIcon('county')}</th>
              <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('city')}><i className="fa fa-folder"></i>&nbsp;City {renderSortIcon('city')}</th>
              <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('datetime_created')}><i className="fa fa-calendar-o"></i>&nbsp;Date Created {renderSortIcon('datetime_created')}</th>
            </tr>
          </thead>
          <tbody>
          {
            state.appraisers.map(
              (appraiser)=>{
                return (
                  <tr key={appraiser.ID} onClick={()=>handleStepAdvance(appraiser)}>
                    <td>{appraiser.first_name+' '+appraiser.last_name}</td>
                    <td>{appraiser.status}</td>
                    <td>{appraiser.state}</td>
                    <td>{appraiser.county}</td>
                    <td>{appraiser.city}</td>
                    <td>{formatDateTime(appraiser.datetime_created)}</td>
                  </tr>
                );
              }
            )
          }
          </tbody>
        </table>
      </div>
    );
  }

  //API call
  //function that fire when the infinite scroll reach bottom
  const loadMore = () => {
    //do not load if there is no more appraisals or it's loading data
    if(state.hasMoreAppraisers&&!state.loading){
      //set loading equals to true so it won't fire off before we are done
      setState({loading:true});

      let url = 'appraiser/get/limit='+state.limit+'&offset='+state.offset+'&order='+state.order+'&sort='+state.sort+'&status='+state.status+'&name='+state.name+'&state='+state.state+'&county='+state.county+'&city='+state.city;

      //callback handler that update the state when http request return
      let callBack = (response)=>{
        let code = response.data?response.data.code:undefined;

        if(code!=='00'){
          setState({hasMoreAppraisers:false});
        }
        else{
          let newAppraisers = response.data.data;
          let hasMoreAppraisers = true;
          let newOffset = state.offset;
          let totalCount = response.data.count;

          //if http request return empty then no more results, end of list
          if(newAppraisers.length<=0){
            hasMoreAppraisers = false;
          }
          else{
            //increment the offset
            newOffset = state.offset + 1;
          }

          //concat the current array of announcement
          if(state.appraisers.length>0){
            let temp = [...state.appraisers,...newAppraisers];

            setState({appraisers:temp});
          }
          else
            setState({appraisers:newAppraisers});

          setState({hasMoreAppraisers:hasMoreAppraisers, offset:newOffset, totalCount:totalCount});
        }
      };
      callBack = callBack.bind(this);

      //error handler when the http request return with error
      let errorCallBack = apiCallBack([{state:'hasMoreAppraisers', value:false}]);

      //collect the promise and wait for it to finish performing it's task
      let promises = httpGet(url, '','Oops, something went wrong and could not load appraiser. Please try again later.', callBack, errorCallBack);
      promises
        .then(
          function(result){
            //set loading equals to false so the function could be fire off once again
            setState({loading:false});
          }
        );
    }
  }

  const createNewAMC = (e) => {
    e.preventDefault();

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        history('/amc/'+response.data.data.ID);
      }
    };
    callBack = callBack.bind(this);

    let parameters =[
      {
        field:'appraiserFk',
        value:state.selectedAppraiser.ID
      },
      {
        field:'autoReview',
        value:state.autoReview
      },
      {
        field:'generateSSR',
        value:state.generateSSR
      },
      {
        field:'skipPayment',
        value:state.skipPayment
      },
      {
        field:'autoRelayComment',
        value:state.autoRelayComment
      }
    ];

    httpPost('amc/create', parameters, 'AMC created. Please set the fee schedule.', 'Oops, something went wrong and could not create the AMC. Please try again later.', callBack);
  }

  //render
  let reportFiltersConfig = [
    {id:'custom',value:state.name, updateFunc:(v)=>setState({name:v}), width:'2', label:'Name'},
    {id:'appraiserStatus',value:state.status, updateFunc:(v)=>setState({status:v}), width:'2'},
    {id:'state',value:state.state, updateFunc:(v)=>setState({state:v}), width:'2'},
    {id:'county',value:state.county, updateFunc:(v)=>setState({county:v}), width:'2', group:false},
    {id:'city',value:state.city, updateFunc:(v)=>setState({city:v}), width:'2'},
    {id:'button',value:'Submit', updateFunc:()=>refreshList(), width:'2', className:"align-right", color:"warning"},
  ];

  let step1,step2;
  if(state.currentStep===0)
    step1 =
      <div>
        <font color="red">*</font><i>Search for the AMC appraiser profile and click on it to select it. If you haven't create the appraiser profile please go ahead an do that first.</i>
        <br/><br/>
        <ReportFilter {...props} configs={reportFiltersConfig}/>
        <br/>

        <div className="large-scroll-container">
          <InfiniteScroll
            next={loadMore}
            dataLength={state.appraisers.length}
            hasMore={state.hasMoreAppraisers}
            loader={<div key="nill" className="loader"><center>Loading more appraisers...</center></div>}
            initialLoad = {true}
            className="my-well"
            scrollableTarget="contentContainer"
          >
            {renderAppraisers()}
          </InfiniteScroll>
        </div>
      </div>;

  else
    step2 =
      <div>
        <font color="red">*</font><i>Please configure the settings for the new AMC before creating the profile.</i>
        <br/><br/>
          <Card>
            <CardHeader className="header-color">
              AMC - {state.selectedAppraiser.first_name+' '+state.selectedAppraiser.last_name}
            </CardHeader>
            <CardBody>
                <form onSubmit={createNewAMC}>
                  <label>Auto review</label><br/>
                  <i>Does the orders of this AMC require auto review?</i>
                  <MySelect
                    type="select"
                    value={state.autoReview}
                    onChange={(v)=>setState({autoReview:v})}
                    options={[{label:"Yes",value:"yes"},{label:"No",value:"no"}]}
                  />
                  <br/>

                  <label>Generate SSR</label><br/>
                  <i>Does the orders of this AMC require SSR generation?</i>
                  <MySelect
                    type="select"
                    value={state.generateSSR}
                    onChange={(v)=>setState({generateSSR:v})}
                    options={[{label:"Yes",value:"yes"},{label:"No",value:"no"}]}
                  />
                  <br/>

                  <label>Skip payment processing</label><br/>
                  <i>Should the payment being handled by the AMC and skip payment processing on our end?</i>
                  <MySelect
                    type="select"
                    value={state.skipPayment}
                    onChange={(v)=>setState({skipPayment:v})}
                    options={[{label:"Yes",value:"yes"},{label:"No",value:"no"}]}
                  />
                  <br/>

                  <label>Auto relay comment</label><br/>
                  <i>Should all comments of this AMC forward to the client automatically?</i>
                  <MySelect
                    type="select"
                    value={state.autoRelayComment}
                    onChange={(v)=>setState({autoRelayComment:v})}
                    options={[{label:"Yes",value:"yes"},{label:"No",value:"no"}]}
                  />
                  <br/>

                  <div className="align-right">
                    <Button color="warning">Finish</Button>
                  </div>
                </form>
            </CardBody>
          </Card>

      </div>;


  return <div className="my-well">
    <Row>
      <Col sm="6">
        <div className="page-title">
          <i className="fa fa-reorder"></i>&nbsp;New AMC
        </div>
        <NavLink to="/amc">Back to amc list</NavLink>
      </Col>
      <Col sm="6">

      </Col>
    </Row>
    <div className="my-divider"></div>
    <br/>
    <MyFormStep steps = {state.steps} currentStep = {state.currentStep} updateStep = {updateStep} maxStep={state.maxStep}/>
    <br/><br/>
    {step1}
    {step2}
  </div>;
}


export default NewAMC;
