//Sooyoung Kim
//Oct 26, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, formatDateTime, formatNumber, formatNumberNoDecimal} from '../../util/util';
import {Button,Card, CardHeader, CardBody,Modal, ModalHeader, ModalBody, Row, Col} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import './today-stat.css';


//initialize the state
const initialState = {
  stats:{
    order_count:0,
    quote_count:0,
    spread:0,
    non_full_appraisal_count:0,
    submitted_count:0,
    submitted_stale_count:0,
    bid_count:0,
    bid_stale_count:0,
    bid_to_review_count:0,
    bid_to_review_stale_count:0,
    quote_stale_count:0,
    pending_inspection_count:0,
    inspection_completed_count:0,
    inspection_scheduled_count:0,
    pending_inspection_stale_count:0,
    waiting_on_poc_count:0,
    inspection_today:0,
    due_today:0,
    past_due:0,
  },


  todayStatsDetails:[],
  todayStatsDropDown:false,
  todayStatsDetailsType:'',
  showTodayStat:true
};

//reducer function that perform state update
const reducer = getReducer();


const TodayStat  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getDashboardStats();
    
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const todayStatsToggle = () => {
    setState({todayStatsDropDown:!state.todayStatsDropDown});
  }


  //API call
  //get dashboard stats
  const getTodayStatsDetails = (type) => {
    todayStatsToggle();
    let url = 'dashboard/stats/global/details/get/type='+type+'&status='+state.status+'&state='+state.state+'&from='+state.from+'&to='+state.to+'&client='+state.client;
    if(props.type && props.type==='personal')
      url = 'dashboard/stats/details/get/type='+type+'&status='+state.status+'&state='+state.state+'&from='+state.from+'&to='+state.to+'&client='+state.client;

    let callBack = apiCallBack([{state:'todayStatsDetails', key:'data'}, {state:'todayStatsDetailsType', value:type}]);
    httpGet(url, '', 'Oops, something went wrong and could not load your dashboard stats. Please try again later.', callBack);
  }

  //get dashboard stats
  const getDashboardStats = () => {
    let url = 'dashboard/stats/global/get/status=';
    if(props.type && props.type==='personal')
      url = 'dashboard/stats/get/status=';


    let callBack = apiCallBack([{state:'stats', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load your dashboard stats. Please try again later.', callBack);
  }

  //render
  let todayStatsContent;

  if(state.todayStatsDetails.length>0){
    if(state.todayStatsDetailsType!=='past_due')
      todayStatsContent = <table className="table table-striped">
        <thead>
          <tr>
            <th>Reference #</th>
            <th>Client</th>
            <th>Address</th>
            <th>Report Type</th>
            <th>Date Submitted</th>
          </tr>
        </thead>global
        <tbody>
          {
            state.todayStatsDetails.map(
              (row, index)=>{
                let date = row.datetime_submitted;

                return <tr key={index}>
                  <td><NavLink target="_blank" to={"appraisal/"+row.ID}>{row.reference_num}</NavLink></td>
                  <td>{row.company}</td>
                  <td>{row.property_street+' '+row.property_city+', '+row.property_state+' '+row.property_zip}</td>
                  <td>{row.appraisal_type}</td>
                  <td>{formatDateTime(date)}</td>
                </tr>
              }
            )
          }
        </tbody>
      </table>
    else
      todayStatsContent = <table className="table table-striped">
        <thead>
          <tr>
            <th>Reference #</th>
            <th>Client</th>
            <th>Address</th>
            <th>Report Type</th>
            <th>Date Submitted</th>
            <th>Date Due To Client</th>
          </tr>
        </thead>
        <tbody>
          {
            state.todayStatsDetails.map(
              (row, index)=>{
                let date = row.datetime_submitted;
                let date2 = row.datetime_expected;

                return <tr key={index}>
                  <td><NavLink target="_blank" to={"appraisal/"+row.ID}>{row.reference_num}</NavLink></td>
                  <td>{row.company}</td>
                  <td>{row.property_street+' '+row.property_city+', '+row.property_state+' '+row.property_zip}</td>
                  <td>{row.appraisal_type}</td>
                  <td>{formatDateTime(date)}</td>
                  <td>{formatDateTime(date2)}</td>
                </tr>
              }
            )
          }
        </tbody>
      </table>
  }


  let statsTab = <div style={{minWidth:'350px', height:'100%'}}>
    <Card style={{borderRadius:'0px', border:'0px', height:'100%', fontSize:'14px'}}>
      <CardHeader className="header-color" style={{borderRadius:'0px'}}>
        <i className="fa fa-area-chart"></i> Today's Stats
      </CardHeader>
      <CardBody>
        <div>
          <center>
            <Row>
              <Col sm="7">
                <div style={{height:"28px"}}>
                  <label>Submitted</label>
                </div>
                <NavLink to="#" onClick={(e)=>getTodayStatsDetails('submitted')}>{formatNumberNoDecimal(state.stats.submitted_count)}</NavLink> (<NavLink to="#" onClick={(e)=>getTodayStatsDetails('submitted_stale')}>{formatNumberNoDecimal(state.stats.submitted_stale_count)}</NavLink> stale)
                <br/>
                <div style={{height:"28px"}}>
                  <label>Bid</label>
                </div>
                <NavLink to="#" onClick={(e)=>getTodayStatsDetails('bid')}>{formatNumberNoDecimal(state.stats.bid_count)}</NavLink> (<NavLink to="#" onClick={(e)=>getTodayStatsDetails('bid_stale')}>{formatNumberNoDecimal(state.stats.bid_stale_count)}</NavLink> stale)
                <br/>
              </Col>
              <Col sm="5">
                <div style={{height:"28px"}}>
                  <label>Bid To Review</label>
                </div>
                <NavLink to="#" onClick={(e)=>getTodayStatsDetails('bid_to_review')}>{formatNumberNoDecimal(state.stats.bid_to_review_count)}</NavLink> (<NavLink to="#" onClick={(e)=>getTodayStatsDetails('bid_to_review_stale')}>{formatNumberNoDecimal(state.stats.bid_to_review_stale_count)}</NavLink> stale)
                <br/>
                <div style={{height:"28px"}}>
                  <label>Quote</label>
                </div>
                <NavLink to="#" onClick={(e)=>getTodayStatsDetails('quote')}>{formatNumberNoDecimal(state.stats.quote_count)}</NavLink> (<NavLink to="#" onClick={(e)=>getTodayStatsDetails('quote_stale')}>{formatNumberNoDecimal(state.stats.quote_stale_count)}</NavLink> stale)
                <br/>
              </Col>
            </Row>
            <br/>
            <label>Waiting On POC</label><br/>
            <NavLink to="#" onClick={(e)=>getTodayStatsDetails('waiting_on_poc')}>{formatNumberNoDecimal(state.stats.waiting_on_poc_count)}</NavLink>
            <br/>

            <div className="my-divider"/>
            <label>Pending Inspection</label><br/>
            <NavLink to="#" onClick={(e)=>getTodayStatsDetails('pending_inspection')}>{formatNumberNoDecimal(state.stats.pending_inspection_count)}</NavLink> (<NavLink to="#" onClick={(e)=>getTodayStatsDetails('pending_inspection_stale')}>{formatNumberNoDecimal(state.stats.pending_inspection_stale_count)}</NavLink> stale)
            <div style={{height:'5px'}}/>

            <label>Inspection Scheduled</label><br/>
            <NavLink to="#" onClick={(e)=>getTodayStatsDetails('inspection_scheduled')}>{formatNumberNoDecimal(state.stats.inspection_scheduled_count)}</NavLink>
            <div style={{height:'5px'}}/>

            <label>Inspection Happening Today</label><br/>
            <NavLink to="#" onClick={(e)=>getTodayStatsDetails('inspection_today')}>{formatNumberNoDecimal(state.stats.inspection_today)}</NavLink>
            <div style={{height:'5px'}}/>

            <label>Inspection Completed</label><br/>
            <NavLink to="#" onClick={(e)=>getTodayStatsDetails('inspection_completed')}>{formatNumberNoDecimal(state.stats.inspection_completed_count)}</NavLink>
            <div style={{height:'5px'}}/>

            <label>Order Due Today</label><br/>
            <NavLink to="#" onClick={(e)=>getTodayStatsDetails('due_today')}>{formatNumberNoDecimal(state.stats.due_today)}</NavLink>
            <div style={{height:'5px'}}/>

            <label>Order Past Due</label><br/>
            <NavLink to="#" onClick={(e)=>getTodayStatsDetails('past_due')}>{formatNumberNoDecimal(state.stats.past_due)}</NavLink>
            <br/>
            <div className="my-divider"/>
            <label>Avg spread</label>
            <div style={{fontSize:'20px'}}>
              ${formatNumber(state.stats.spread)}
            </div><br/>
            <label>Total orders</label>
            <div style={{fontSize:'20px'}}>
              <NavLink to="#" onClick={(e)=>getTodayStatsDetails('order_count')}>{formatNumberNoDecimal(state.stats.order_count)}</NavLink>
            </div>
            <font size="2">
              Non full appraisal: <b><NavLink to="#" onClick={(e)=>getTodayStatsDetails('non_full_appraisal_count')}>{formatNumberNoDecimal(state.stats.non_full_appraisal_count)}</NavLink></b><br/>
              Quote order: <b><NavLink to="#" onClick={(e)=>getTodayStatsDetails('quote_count')}>{formatNumberNoDecimal(state.stats.quote_count)}</NavLink></b>
            </font>
          </center>
        </div>
      </CardBody>
    </Card>
  </div>;

  return <div className="today-stat">
    <Modal className="my-modal-wide" isOpen={state.todayStatsDropDown} toggle={todayStatsToggle} >
      <ModalHeader hidden={true} toggle={todayStatsToggle}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-user"></i> Report Details</h5>
        </center>
        <br/>
        <div className="large-scroll-container">
          {todayStatsContent}
        </div>
        <br/>
        <center>
          <Button color="info" onClick={todayStatsToggle}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
    <table cellPadding="0" style={{height:'100%'}}>
      <tr>
        <td style={{verticalAlign:'middle', cursor:'pointer', background:'#D2D2D2',padding:'5px', borderTopLeftRadius:'5px'}} onClick={(e)=>setState({showTodayStat:!state.showTodayStat})}>
          <font size="5">
            <i className={state.showTodayStat?"fa fa-caret-right":"fa fa-caret-left"}/>
          </font>
        </td>
        <td>
          {state.showTodayStat?statsTab:null}
        </td>
      </tr>
    </table>
  </div>;
}


export default TodayStat;
