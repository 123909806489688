//Author Sooyoung Kim
//Date April 14, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, putAPICallGenerator, deleteAPICallGenerator, confirmation, stringToHexCode} from '../../util/util';
import { Button, Input, Row, Col, UncontrolledTooltip} from 'reactstrap';
import {NavLink, useParams, useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import React, {useReducer, useEffect} from 'react';


//initialize the state
const initialState = {
  body: '',
  creator: '',
  title: '',
  readRecipient:[],
  datetimeCreated: '',
};

//reducer function that perform state update
const reducer = getReducer();


const Policy  = (props)=>{
  const controller = new AbortController();
  const { id } = useParams();
  const navigate = useNavigate();

  let newInitialState = Object.assign({}, initialState, {id:id});
  const [state, dispatch] = useReducer(reducer, newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  useEffect(()=>{
    getPolicy(state.id);
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  //API call
  const getPolicy = (id) =>{
    let url = 'policy/'+id;
    let successMsg = '';
    let errorMsg = 'Oops, something went wrong and could not load this policy. Please try again later.';

    //call back for retrieving Policy
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code!=='00'){

      }
      else{
        setState({
          title:response.data.data.title,
          body:response.data.data.body,
          creator:response.data.data.creator,
          datetimeCreated:response.data.data.datetimeCreated,
          readRecipient:response.data.data.read_recipient,
        });
      }
    };

    callBack = callBack.bind(this);

    httpGet(url, successMsg, errorMsg, callBack);
  }

  //update the Policy
  const updatePolicy = () =>{
    let url = 'policy/update';
    let successMsg = 'Policy updated.';
    let errorMsg = 'Oops, something went wrong and could not update this policy. Please try again later.';

    let parameters = [
      {
        field:'body',
        value:state.body
      },
      {
        field:'creator',
        value:state.creator
      },
      {
        field:'title',
        value:state.title
      },
      {
        field:'ID',
        value:state.id
      }
    ];

    //call back for update Policy
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code!=='00'){
      }
      else{
      }
    };
    callBack = callBack.bind(this);

    httpPut(url, parameters, successMsg, errorMsg, callBack);
  }

  //delete Policy
  const deletePolicy = (id) =>{
    let url = 'policy/'+id;
    let successMsg = 'Policy deleted.';
    let errorMsg = 'Oops, something went wrong and could not delete this policy. Please try again later.';

    //call back for delete Policy
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code!=='00'){
      }
      else{
        navigate('/policy');
      }
    };
    callBack = callBack.bind(this);

    httpDelete(url,successMsg,errorMsg, callBack);
  }

  // render
  let content = <ReactQuill
      modules={
        {
          toolbar: [
              [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
              [{size: []}],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{'list': 'ordered'}, {'list': 'bullet'},
               {'indent': '-1'}, {'indent': '+1'}],
              ['link', 'image', 'video'],
              ['clean']
            ],
            clipboard: {
              // toggle to add extra line breaks when pasting HTML:
              matchVisual: false,
            },
            imageResize: {
            // parchment: Quill.import('parchment'),
              modules: ['Resize', 'DisplaySize']
            }
        }
      }
      formats={
        [
          'header', 'font', 'size',
          'bold', 'italic', 'underline', 'strike', 'blockquote',
          'list', 'bullet', 'indent',
          'link', 'image', 'video'
        ]
      }
      value={state.body}
      className="high-quill"
      onChange={(value) => setState({body:value})}
    />;

  let action = <div>
    <Button color="danger" onClick={
      ()=>{
        confirmation(
          ()=>{deletePolicy(state.id)},
          ()=>{},
          'Delete Policy?',
          'Are you sure you want to delete this policy?');
      }
    }><i className="fa fa-trash"></i>&nbsp;&nbsp;Delete</Button>&nbsp;&nbsp;
    <Button color="warning" onClick={()=>{updatePolicy()}}><i className="fa fa-edit"></i>&nbsp;&nbsp;Update</Button>
  </div>;

  let creator = <div className="form-group">
    <label>By: </label>
    <Input value={state.creator} onChange={(text)=>{setState({creator:text.target.value})}}/>
  </div>;

  let title = <div className="form-group">
    <label>Title: </label>
    <Input value={state.title} onChange={(text)=>setState({title:text.target.value})}/>
  </div>;

  return <div>
    <div className="card padding">
      <Row>
        <Col sm="8">
          <div className="page-title">
            <i className="fa fa-reorder"></i>&nbsp;Edit Policy
          </div>
  				<NavLink to="/Policy">Back to Policy list</NavLink>
        </Col>
        <Col sm="4" className="align-right">
          {action}
        </Col>
      </Row>
      <div className="hr"/>
      <div>
        {
          state.readRecipient.map(
            (user, index)=>{
              return <div className="display-inline padding-right" key={index}>
                <div className="display-inline dark-red-color" id={"policy"+index}>
                  <span className="my-badge-no-color" style={{backgroundColor:"#"+stringToHexCode(user.email)}}>
                    {user.first_name[0]+user.last_name[0]}
                  </span>
                </div>
                <UncontrolledTooltip delay={{ "show": 0, "hide": 0 }} placement="right" target={"policy"+index}>
                  {user.first_name+' '+user.last_name}
                </UncontrolledTooltip>
              </div>
            }
          )
        }
      </div>
      <br/>
      {creator}
      <br/>
      {title}
      <br/>
      {content}
    </div>
  </div>;
}

export default Policy;
