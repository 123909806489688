//Author Sooyoung Kim
//Date July 8th, 2022
import {formatDate, formatNumber, getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, encodeParam} from '../../util/util';
import InfiniteScroll from 'react-infinite-scroll-component';
import {Input, Button, Row, Col} from 'reactstrap';
import MySelect from '../util/my-select';
import {NavLink, useParams, useNavigate } from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';


//initialize the state
const initialState = {
  clients:[],
  limit: 25,
  offset: 0,
  hasMoreClients: true,
  loading: false,
  sort: 'company',
  order: 'ASC',
  statuses: [],
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
};

//reducer function that perform state update
const reducer = getReducer();


const Clients  = (props)=>{
  const controller = new AbortController();
  let history = useNavigate();
  let {params} = useParams();
  let {newParam} = useParams();

  let publicCreated = '';
  let company = '';
  let email = '';
  let companyEmail = '';
  let stateName = '';
  let county = '';
  let city = '';
  let status = 'Approved';
  let entityRelationship = '';

  if(params&&params!==''){
    params = params.split('&');
    if(params)
      for(let i=0;i<params.length;i++){
        let token = params[i].split('=');
        token[1] = token[1].replace('ForwardSlash', '/');
        token[1] = token[1].replace('Ampersand', '&');
        token[1] = decodeURIComponent(token[1]);

        switch(token[0]){
          case 'company':
            company = token[1];
            break;
          case 'publicCreated':
            publicCreated = token[1];
            break;
          case 'entityRelationship':
            entityRelationship = token[1];
            break;
          case 'email':
            email = token[1];
            break;
          case 'companyEmail':
            email = token[1];
            break;
          case 'state':
            stateName = token[1];
            break;
          case 'county':
            county = token[1];
            break;
          case 'city':
            city = token[1];
            break;
          case 'status':
            status = token[1];
            break;
          default:
            break;
        }
      }
  }else{
    newParam = "status=&company=&state=&county=&city=&entityRelationship=&publicCreated="
  }

  let newInitialState = Object.assign({}, initialState, {
    params:newParam,
    status: status,
    company:company,
    entityRelationship:entityRelationship,
    email:email,
    companyEmail:companyEmail,
    stateName:stateName,
    county:county,
    city:city,
    publicCreated:publicCreated,
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getAllClientStatuses();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    if(state.clients.length<=0 && state.hasMoreClients){
        loadMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state]);

  //non API call but simpyl manage state
  //click handler that switch the state of sorting order then call the function to refresh the list
  //to achieve the "sorting"
  const columnClickHandler = (col)=>{
    if(state.sort===col){
      if(state.order==='ASC')
        setState({order:'DESC'});
      else
        setState({order:'ASC'});
    }
    else
      setState({sort:col,order:'ASC'});
    refreshList();
  }

  //this function refresh the list of orders pipeline
  const refreshList = ()=>{
    setState({
      clients:[],
      totalCount:0,
      hasMoreClients:true,
      offset:0
    });

    let company = encodeParam(state.company);
    let county = encodeParam(state.county);
    let city = encodeParam(state.city);

    let param = 'status='+state.status+'&company='+company+'&state='+state.stateName+'&county='+county+'&city='+city+'&publicCreated='+state.publicCreated;

    history("/client/"+param);
  }

  const viewClient = (clientID)=>{
    let company = encodeParam(state.company);
    let county = encodeParam(state.county);
    let city = encodeParam(state.city);
    let entityRelationship = encodeParam(state.entityRelationship);

    let param = 'status='+state.status+'&company='+company+'&state='+state.stateName+'&county='+county+'&city='+city+'&entityRelationship='+entityRelationship+'&publicCreated='+state.publicCreated;

    history("/client/"+clientID+'/'+param);
  }

  //this function render the sort icon at each column of the table
  const renderSortIcon = (col)=>{
    if(state.sort===col){
      if(state.order==='ASC')
        return <i className="red-color fa fa-arrow-down"></i>
      else
        return <i className="red-color fa fa-arrow-up"></i>
    }
  }

  //render function for infinite scroller
  const renderClients = ()=>{
    return (
      <div>
        <table className="primary-table">
          <thead>
            <tr>
              <th className="cursor-pointer" width="25%" onClick={()=>columnClickHandler('company')}><i className="fa fa-user"></i>&nbsp;Company {renderSortIcon('company')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('status')}><i className="fa fa-info"></i>&nbsp;Status {renderSortIcon('status')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('state')}><i className="fa fa-map-marker"></i>&nbsp;State {renderSortIcon('state')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('county')}><i className="fa fa-map-marker"></i>&nbsp;County {renderSortIcon('county')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('city')}><i className="fa fa-map-marker"></i>&nbsp;City {renderSortIcon('city')}</th>
              <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('email')}><i className="fa fa-envelope-open"></i>&nbsp;Email {renderSortIcon('email')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('phone')}><i className="fa fa-phone"></i>&nbsp;Phone {renderSortIcon('phone')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('datetime_created')}><i className="fa fa-calendar-o"></i>&nbsp;Date Created {renderSortIcon('datetime_created')}</th>
            </tr>
          </thead>
          <tbody>
          {
            state.clients.map(
              (client)=>{
                let clientProfile = client.company;
                if(client.branchFk){
                  clientProfile = <div>
                    {client.company}<br/>
                    <NavLink to={"/client/"+client.ID+"/"+state.params+"/branch/"+client.branchFk+"/user/"+client.branchUserFk}><i className="cursor-pointer fa fa-user-circle"></i>&nbsp;{client.first_name+" "+client.last_name}</NavLink>
                  </div>;
                }

                if(client.type){
                  let type = '1';
                  if(client.type === 'Wholesale'){
                    type = '2';
                  }

                  let company = encodeParam(state.company);
                  let county = encodeParam(state.county);
                  let city = encodeParam(state.city);
                  let entityRelationship = encodeParam(state.entityRelationship);

                  let param = 'status='+state.status+'&company='+company+'&state='+state.stateName+'&county='+county+'&city='+city+'&entityRelationship='+entityRelationship+"&associateName="+client.name+"&tab=2&subTab="+type;

                  let url = "/client/"+client.ID+"/"+param;

                  clientProfile = <div>
                    {client.company}<br/>
                    <NavLink to={url}><i className="cursor-pointer fa fa-user-circle"></i>&nbsp;{client.name}</NavLink>
                  </div>;
                }
                return (
                  <tr key={client.ID} onClickCapture={()=>viewClient(client.ID)}>
                    <td>{clientProfile}</td>
                    <td>{client.status}</td>
                    <td>{client.state}</td>
                    <td>{client.county}</td>
                    <td>{client.city}</td>
                    <td>{client.email}</td>
                    <td>{client.phone}</td>
                    <td>{formatDate(client.datetime_created)}</td>
                  </tr>
                );
              }
            )
          }
          </tbody>
        </table>
      </div>
    )
  }

  //API call
  //function that fire when the infinite scroll reach bottom
  const loadMore = ()=>{
    //do not load if there is no more conditions or it's loading data
    if(state.hasMoreClients&&!state.loading){
      //set loading equals to true so it won't fire off before we are done
      setState({loading:true});

      let email = encodeParam(state.email);
      let companyEmail = encodeParam(state.companyEmail);
      let company = encodeParam(state.company);
      let county = encodeParam(state.county);
      let city = encodeParam(state.city);
      let entityRelationship = encodeParam(state.entityRelationship);

      let url = 'client/get/limit='+state.limit+'&offset='+state.offset+'&order='+state.order+'&sort='+state.sort+'&entityRelationship='+entityRelationship+'&status='+state.status+'&company='+company+'&state='+state.stateName+'&county='+county+'&city='+city+'&email='+email+'&companyEmail='+companyEmail+'&publicCreated='+state.publicCreated;

      //callback handler that update the state when http request return
      let callBack = (response)=>{
        let code = response.data?response.data.code:undefined;

        if(code!=='00'){
          setState({hasMoreClients:false});
        }
        else{
          let newClient = response.data.data;
          let hasMoreClients = true;
          let newOffset = state.offset;
          let totalCount = response.data.count;

          //if http request return empty then no more results, end of list

          if(newClient.length<=0){
            hasMoreClients = false;
          }
          else{
            //increment the offset
            newOffset = state.offset + 1;
          }

          //concat the current array of announcement
          if(state.clients.length>0){
            let temp = [...state.clients,...newClient];

            setState({clients:temp});
          }
          else
            setState({clients:newClient});

          setState({hasMoreClients:hasMoreClients, offset:newOffset, totalCount:totalCount});
        }
      };
      callBack = callBack.bind(this);

      //error handler when the http request return with error
      let errorCallBack = apiCallBack([{state:'hasMoreClients', value:false}]);

      //collect the promise and wait for it to finish performing it's task
      let promises = httpGet(url, '','Oops, something went wrong and could not load clients. Please try again later.', callBack, errorCallBack);
      promises
        .then(
          function(result){
            //set loading equals to false so the function could be fire off once again
            setState({loading:false});
          }
        );
    }
  }

  //get all client status for the select drop down
  const getAllClientStatuses = ()=>{
    let url = 'client/statuses/get';
    let callBack = apiCallBack([{state:'statuses', key:'data'}]);
    httpGet(url, '','Oops, something went wrong and could not retrieve client statuses.', callBack);
  }

  //render
  return <div>
  <InfiniteScroll
    next={loadMore}
    dataLength={state.clients.length}
    hasMore={state.hasMoreClients}
    loader={<div key="nill" className="loader"><center>Loading more clients...</center></div>}
    initialLoad = {true}
    className="my-well"
    scrollableTarget="contentContainer"
  >

    <div className="page-title">
      <i className="fa fa-reorder"></i>&nbsp;Clients
    </div>

    <Row>
      <Col sm="3">
        <label className="no-margin-bottom">Status</label>
        <MySelect
          type="select"
          value={state.status}
          onChange={(v)=>setState({status:v})}
          options={[{label:"All", value:"All"}].concat(
            state.statuses.map((status)=>{
              return {label:status.status, value:status.status};
            })
          )}
        />
      </Col>
      <Col sm="3">
        <label className="no-margin-bottom">Registered User Email</label>
        <Input type="text" value={state.email} onChange={(e)=>setState({email:e.target.value})}/>
      </Col>
      <Col sm="3">
        <label className="no-margin-bottom">Company Email</label>
        <Input type="text" value={state.companyEmail} onChange={(e)=>setState({companyEmail:e.target.value})}/>
      </Col>
      <Col sm="3">
        <label className="no-margin-bottom">Company</label>
        <Input type="text" value={state.company} onChange={(e)=>setState({company:e.target.value})}/>
      </Col>
    </Row>
    <Row>
      <Col sm="3">
        <label className="no-margin-bottom">Associate</label>
        <Input type="text" value={state.entityRelationship} onChange={(e)=>setState({entityRelationship:e.target.value})}/>
      </Col>
      <Col sm="2">
        <label className="no-margin-bottom">State</label>
        <MySelect
          type="select"
          value={state.stateName}
          onChange={(v)=>setState({stateName:v})}
          options={state.states.map((state)=>{
            return {label:state.value, value:state.value};
          })}
        />
      </Col>
      <Col sm="2">
        <label className="no-margin-bottom">County</label>
        <Input type="text" value={state.county} onChange={(e)=>setState({county:e.target.value})}/>
      </Col>
      <Col sm="2">
        <label className="no-margin-bottom">City</label>
        <Input type="text" value={state.city} onChange={(e)=>setState({city:e.target.value})}/>
      </Col>
      <Col sm="2">
        <label className="no-margin-bottom">Temporary Profile</label>
        <MySelect
          type="select"
          value={state.publicCreated}
          onChange={(v)=>setState({publicCreated:v})}
          options={[{label:"",value:""},{label:"Yes",value:"yes"},{label:"No",value:"no"}]}
        />
      </Col>
      <Col sm="1" className="align-right valign-bottom">
        <br/>
        <Button color="warning" onClick={()=>refreshList()} >Submit</Button>
      </Col>
    </Row>
    <br/>

    <div className="my-divider"></div>
    <Row>
      <Col sm="6">
          <div>{formatNumber(state.totalCount)} clients in queue<br/><br/></div>
      </Col>
      <Col sm="6">
        <div className="align-right"><NavLink to={'/client/new'}><i className="fa fa-plus"></i>&nbsp;Create new client.</NavLink><br/></div>
      </Col>
    </Row>


    {renderClients()}
  </InfiniteScroll>
  </div>;
}

export default Clients;
