//Author Sooyoung Kim
//Date Nov 17, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, callBackGenerator} from '../../util/util';
import {Card, CardHeader, CardBody, Row, Col} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import MyXEditable from '../util/my-xeditable';
import {NavLink} from 'react-router-dom';

//initialize the state
const initialState = {
  panel:[],
  company:'',
};

//reducer function that perform state update
const reducer = getReducer();

const AutoAssignLenderPanel  = (props)=>{
  const controller = new AbortController();
  
  let newInitialState = Object.assign({}, initialState, {
    clientFk:props.clientFk,
    state:props.state,
    county:props.county,
  });

const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getPanel();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //API call
  const pauseAutoAssign = (ID, paused) => {
    let url = 'brokerRelationship/appraiser/update';
    let pause = 'paused';

    if(paused==='yes')
      pause = 'unpaused';
    let successMsg = 'Auto assign '+pause+' successfully.';
    let errorMsg = 'Oops, something went wrong and could not '+pause+' the auto assign. Please try again later.';

    let parameters = [
      {
        field:'ID',
        value:ID
      },
      {
        field:'field',
        value:'paused'
      },
      {
        field:'value',
        value:paused==='no'?'yes':'no'
      }
    ];

    let callBack = (response)=>{
      let code= response.data.code;

      if(code==='00'){
        let panel = state.panel.slice();

        for(let i=0;i<panel.length;i++){
          if(panel[i].baID===ID){
            if(paused==='no')
              panel[i].paused='yes';
            else
              panel[i].paused='no';
            break;
          }
        }
        setState({panel:panel});
      }
    };
    callBack = callBack.bind(this);

    httpPut(url, parameters, successMsg, errorMsg, callBack);
  }


  //generate a function that do the update on editable
  const generateUpdateFunction = (url, parameters, successMessage, failedMessage, parentCallBack=null) => {
    return (newValue, callBack, errorCallBack)=>{
      //add in the new value into parameters

      parameters.push({field:'value',value:newValue});
      let promise = httpPut(url,parameters,successMessage,failedMessage, callBack, errorCallBack);
      promise.then(
        (response)=>{
          if(parentCallBack!==null){
            parentCallBack();
          }
        }
      );
    }
  };

  const getPanel = () => {
    let callBack = apiCallBack([{state:'panel', key:'data'}]);
    httpGet('autoAssign/panel/appraiser/client_fk='+state.clientFk+'&state='+state.state+'&county='+state.county, '', 'Oops, something went wrong and could not load the panel. Please try again later.', callBack);
  }

  const updateFavor = (appraiserFk, label, name, status) => {
    let parameters = [
      {
        field:'appraiserFk',
        value:appraiserFk
      },
      {
        field:'clientFk',
        value:state.clientFk
      },
      {
        field:'label',
        value:label
      },
      {
        field:'name',
        value:name
      },
      {
        field:'status',
        value:status
      }
    ];

    let callBack = (response)=>{
      let code = response.data.code;

      if(code==='00'){
        let panel = state.panel.slice();

        for(let i=0;i<panel.length;i++){
          if(panel[i].ID===appraiserFk){
            if(label==='Broker'){
              let brokers = panel[i].associated_brokers.slice();

              for(let j=0;j<brokers.length;j++){
                if(brokers[j].name===name){
                  brokers[j].status = status;
                  break;
                }
              }

              panel[i].associated_brokers = brokers;
              break;
            }
            else if(label==='Loan Officer'){
              let loanOfficers = panel[i].associated_loan_officers.slice();

              for(let j=0;j<loanOfficers.length;j++){
                if(loanOfficers[j].name===name){
                  loanOfficers[j].status = status;
                  break;
                }
              }

              panel[i].associated_loan_officers = loanOfficers;
              break;
            }
          }
        }

        setState({panel:panel});
      }
    };
    callBack = callBack.bind(this);

    httpPost('autoAssign/favor', parameters, '', 'Oops, something went wrong and could not update ', callBack);
  }

  //render
  let successMessage = 'Auto assign configuration updated.';
  let failedMessage = 'Oops, something went wrong and could not update the auto assign configuration. Please try again later.';


  return <div style={{width:'100%'}}>
    {
      state.panel.map(
        (appraiser, index)=>{
          let pauseIcon = <i className="fa fa-play-circle cursor-pointer" onClick={(e)=>{pauseAutoAssign(appraiser.baID, appraiser.paused)}}/>;


          if(appraiser.paused==='yes')
            pauseIcon = <i className="fa fa-pause-circle cursor-pointer" onClick={(e)=>{pauseAutoAssign(appraiser.baID, appraiser.paused)}}/>;

          return(
            <div key={index}>
              <Card>
                <CardHeader>
                  <Row>
                    <Col sm="8">
                      <NavLink target="_blank" to={"/appraiser/"+appraiser.ID}>{appraiser.first_name+' '+appraiser.last_name}</NavLink>
                    </Col>
                    <Col className="align-right" sm="4">
                      {pauseIcon}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <label>License</label>
                  {
                    appraiser.licenses.map(
                      (license, index2)=>{
                        return(
                          <div key={index2}>
                            {license.license_number} - {license.license_type} - {license.license_state}
                          </div>
                        )
                      }
                    )
                  }
                  <br/>
                  <Row>
                    <Col sm="6">
                      <label>Associated Broker</label><br/>
                      <div className="medium-scroll-container-no-min">
                        {
                          appraiser.associated_brokers.map(
                            (broker, index2)=>{
                              let thumbsUpIcon = <i className="fa fa-thumbs-up cursor-pointer" onClick={(e)=>{updateFavor(appraiser.ID, 'Broker', broker.name, 'favor')}}/>;
                              let thumbsDownIcon = <i className="fa fa-thumbs-down cursor-pointer" onClick={(e)=>{updateFavor(appraiser.ID, 'Broker', broker.name, 'block')}}/>;

                              if(broker.status==='favor')
                                thumbsUpIcon = <i className="fa fa-thumbs-up cursor-pointer green-color" onClick={(e)=>{updateFavor(appraiser.ID, 'Broker', broker.name, '')}}/>;
                              if(broker.status==='block')
                                thumbsDownIcon = <i className="fa fa-thumbs-down cursor-pointer red-color" onClick={(e)=>{updateFavor(appraiser.ID, 'Broker', broker.name, '')}}/>;
                              return(
                                <div className="my-well display-inline" key={index2} style={{padding:'5',marginRight:'5px',marginBottom:'5px',fontSize:'12px'}}>
                                  {broker.name}&nbsp;&nbsp;{thumbsUpIcon}&nbsp;&nbsp;&nbsp;&nbsp;{thumbsDownIcon}
                                </div>
                              )
                            }
                          )
                        }
                      </div>
                    </Col>
                    <Col sm="6">
                      <label>Associated Loan Officer</label><br/>
                      <div className="medium-scroll-container-no-min">
                        {
                          appraiser.associated_loan_officers.map(
                            (loanOfficer, index2)=>{
                              let thumbsUpIcon = <i className="fa fa-thumbs-up cursor-pointer" onClick={(e)=>{updateFavor(appraiser.ID, 'Loan Officer', loanOfficer.name, 'favor')}}/>;
                              let thumbsDownIcon = <i className="fa fa-thumbs-down cursor-pointer" onClick={(e)=>{updateFavor(appraiser.ID, 'Loan Officer', loanOfficer.name, 'block')}}/>;

                              if(loanOfficer.status==='favor')
                                thumbsUpIcon = <i className="fa fa-thumbs-up cursor-pointer green-color" onClick={(e)=>{updateFavor(appraiser.ID, 'Loan Officer', loanOfficer.name, '')}}/>;
                              if(loanOfficer.status==='block')
                                thumbsDownIcon = <i className="fa fa-thumbs-down cursor-pointer red-color" onClick={(e)=>{updateFavor(appraiser.ID, 'Loan Officer', loanOfficer.name, '')}}/>;

                              return(
                                <div className="my-well display-inline" key={index2} style={{padding:'5',marginRight:'5px',marginBottom:'5px',fontSize:'12px'}}>
                                  {loanOfficer.name}&nbsp;&nbsp;{thumbsUpIcon}&nbsp;&nbsp;&nbsp;&nbsp;{thumbsDownIcon}
                                </div>
                              )
                            }
                          )
                        }
                      </div>
                    </Col>
                  </Row>
                  <br/>
                  <Card>
                    <CardHeader>
                        <i className="fa fa-cogs"/> Auto Assign Configuration
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col sm="4">
                          <label>Max Assignment</label><br/>
                          <MyXEditable
                            type="text"
                            value={appraiser.override_max_capacity}
                            updateFunc={
                              generateUpdateFunction('brokerRelationship/appraiser/update',[{field:'field',value:'override_max_capacity'},{field:'ID',value:appraiser.baID}],successMessage, failedMessage)
                            }
                          />
                        </Col>
                        <Col sm="4">
                          <label>Max Radius From Property</label><br/>
                          <MyXEditable
                            type="text"
                            value={appraiser.override_radius}
                            updateFunc={
                              generateUpdateFunction('brokerRelationship/appraiser/update',[{field:'field',value:'override_radius'},{field:'ID',value:appraiser.baID}],successMessage, failedMessage)
                            }
                          />
                        </Col>
                        <Col sm="4">
                          <label>Max Acceptance Response Time</label><br/>
                          <MyXEditable
                            type="text"
                            value={appraiser.override_response_time}
                            updateFunc={
                              generateUpdateFunction('brokerRelationship/appraiser/update',[{field:'field',value:'override_response_time'},{field:'ID',value:appraiser.baID}],successMessage, failedMessage)
                            }
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </CardBody>
              </Card>
              <br/>
            </div>
          );
        }
      )
    }
  </div>;
}


export default AutoAssignLenderPanel;
