//Author Sooyoung Kim
//Date May 12, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator} from '../../util/util';
import AvgSalesReport from './management/avg-sales-report';
import AppraiserUpdateLogReport from './appraiser/appraiser-update-log-report';
import AnticipatedValueReport from './orders/anticipated-value-report';
import AppraiserPerformance from './appraiser/appraiser-performance';
import AppraiserQualityMetrics from './appraiser/appraiser-quality-metrics';
import AppraiserQuality from './appraiser/appraiser-quality';
import AppraiserFeeMap from './appraiser/appraiser-fee-map';
import AppraiserPaymentPriority from './billings/appraiser-payment-priority';
import AccountManagerPipeline from './orders/account-manager-pipeline';
import AutoReviewDeliveryStatsDashboard from '../dashboard/auto-review-delivery-stats-dashboard';
import AppraiserReport from './appraiser/appraiser-report';
import AccountingAuditReport from './billings/accounting-audit';
import AssignmentLeaderReport from './management/assignment-leader';
import MissingSSR from './orders/missing-ssr';
import AppraiserServiceRating from './appraiser/appraiser-service-rating';
import CustomQuickbookCheck from './billings/custom-quickbook-check';
import CUScoreReport from './orders/cu-score-report';
import CashFlowReport from './billings/cash-flow';
import CreditCardChargingReport from './billings/credit-card-charging-report';
import ClientStatistic from './orders/client-statistic';
import ClientInvoiceReport from './billings/client-owe';
import CountyByCountyReport from './orders/county-by-county-report';
import ComplexFeeReport from './management/complex-fee';
import CheckpointTurntime from './management/checkpoint-turntime';
import ExceptionalReport from './appraiser/exceptional-report';
import EndOfDay from './orders/end-of-day';
import FlatFeeSreadEstimation from './billings/flat-fee-spread-estimation';
import FeeReport from './billings/fee-report';
import FeeEscalationVolumeReport from './management/fee-escalation-volume-report';
import GlobalHotfileVolumeReport from './management/global-hotfile-volume-report';
import MasterTransactionReport from './billings/master-transaction';
import OverdueVolumeReport from './management/overdue-volume-report';
import OrderVolumeSpreadReport from './orders/order-volume-spread-report';
import CommentVolumeReport from './management/comment-volume-report';
import PendingPayment from './billings/pending-payment';
import PendingPaymentInternalPrint from './billings/pending-payment-internal-print';
import UpdateCheckNumber from './billings/update-check-number';
import PaidPayment from './billings/paid-payment';
import RepeatAppraiserUsageReport from './management/repeat-appraiser-usage-report';
import ReceivableReport from './billings/receivable-report';
import PublicOrderVolumeReport from './orders/public-order-volume-report';
import ClientContactReport from './orders/client-contact-report';
import ThresholdReport from './billings/threshold-report';
import TransactionReport from './billings/transaction-report';
import TurntimeReport from './orders/turntime-report';
import UploadTimeReport from './management/upload-time-report';
import AERankReport from './orders/ae-rank-report';
import StateRankReport from './appraiser/state-rank-report';
import BrokerRankReport from './orders/broker-rank-report';
import LoanOfficerRankReport from './orders/loan-officer-rank-report';
import LateCheckpointReport from './management/late-checkpoint-report';
import LateReport from './management/late-report';
import Lookup from './management/lookup';
import ValuePadOrders from './orders/value-pad-orders';
import OperationCapabilitiesReport from './management/operation-capabilities.js';
import NuclearVolumeReport from './management/nuclear-volume-report';
import AdminTimelineReport from './management/admin-timeline';
import YearToDateReport from './orders/year-to-date-report';
import ClientYearToDateReport from './orders/client-year-to-date-report';
import SpreadWatchReport from './billings/spread-watch';
import QualityRatingReport from './appraiser/quality-rating-report';
import ClientPublicSignup from './management/client-public-signup';
import React, {useReducer, useEffect} from 'react';
import {Col, Row} from 'reactstrap';
import MySelect from '../util/my-select';
import { useParams } from 'react-router-dom';


//initialize the state
const initialState = {
  reports:[],
};

//reducer function that perform state update
const reducer = getReducer();


const Reports  = (props)=>{
  const controller = new AbortController();
  let {params} = useParams();

  let selectedReport = -1;
  let selectedReportCategory = -1;
  let params2;

  if(params&&params!==''){
    if(params.report&&params.report==='appraiser-performance'){
      selectedReport = '29';
      selectedReportCategory = '5';
    }
    else if(params.report&&params.report==='admin-timeline'){
      selectedReport = '34';
      selectedReportCategory = '6';
    }
    else if(params.report&&params.report==='state-rank'){
      selectedReport = '27';
      selectedReportCategory = '5';
    }
    else if(params.report&&params.report==='exception'){
      selectedReport = '48';
      selectedReportCategory = '5';
    }

    if(params.params)
      params2 = params.params;
  }

  let newInitialState = Object.assign({}, initialState, {
    selectedReportCategory:selectedReportCategory,
    selectedReport:selectedReport,
    params:params2
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getReports();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  //API call
  const getReports = ()=>{
    let url = 'report/get';
    let callBack = apiCallBack([{state:'reports',key:'data'}]);
    httpGet(url, '','Oops, something went wrong and could not load a list of available reports. Please try again later.', callBack);
  }

  //render
  let report;
  let reports = [];

  state.reports.map(
    (category,index)=>{
      if(category.ID===state.selectedReportCategory){
        reports = category.reports;
      }
      return null
    }
  );

  switch(state.selectedReportCategory){
    case '1':
      switch(state.selectedReport){
        case '1':
          report = <OrderVolumeSpreadReport pusher={state.pusher} params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '2':
          report = <TurntimeReport params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '15':
          report = <MissingSSR params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '16':
          report = <EndOfDay params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '18':
          report = <AERankReport params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '19':
          report = <BrokerRankReport params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '20':
          report = <LoanOfficerRankReport params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '22':
          report = <ValuePadOrders params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '23':
          report = <CountyByCountyReport params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '24':
          report = <AccountManagerPipeline params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '35':
          report = <AnticipatedValueReport params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '36':
          report = <CUScoreReport params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '44':
          report = <PublicOrderVolumeReport params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '49':
          report = <ClientContactReport params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '50':
          report = <YearToDateReport params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '51':
          report = <ClientYearToDateReport params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '58':
          report = <ClientStatistic params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        default:
          report = '';
          break;
      }
      break;
    case '2':

      break;
    case '3':

      break;
    case '4':
      switch(state.selectedReport){
        case '6':
          report = <FeeReport params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '3':
          report = <CreditCardChargingReport params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '4':
          report = <ThresholdReport params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '7':
          report = <PendingPayment params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '8':
          report = <PaidPayment params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '9':
          report = <AccountingAuditReport params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '11':
          report = <TransactionReport params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '14':
          report = <UpdateCheckNumber params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '17':
          report = <MasterTransactionReport params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '26':
          report = <PendingPaymentInternalPrint params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '28':
          report = <ClientInvoiceReport params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '32':
          report = <CashFlowReport logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '42':
          report = <CustomQuickbookCheck logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '43':
          report = <ReceivableReport logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '47':
          report = <FlatFeeSreadEstimation logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '54':
          report = <SpreadWatchReport logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '53':
          report = <AppraiserPaymentPriority params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        default:
          report = '';
          break;
      }
      break;
    case '6':
      switch(state.selectedReport){
        case '33':
          report = <OperationCapabilitiesReport logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '34':
          report = <AdminTimelineReport params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '38':
          report = <RepeatAppraiserUsageReport logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '39':
          report = <ComplexFeeReport logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '40':
          report = <LateCheckpointReport logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '41':
          report = <LateReport logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '57':
          report = <AssignmentLeaderReport logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '59':
          report = <ClientPublicSignup params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '60':
          report = <CommentVolumeReport params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '61':
          report = <NuclearVolumeReport params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '62':
          report = <GlobalHotfileVolumeReport params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '63':
          report = <FeeEscalationVolumeReport params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '64':
          report = <OverdueVolumeReport params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '65':
          report = <AutoReviewDeliveryStatsDashboard params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '66':
          report = <Lookup params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '67':
          report = <CheckpointTurntime params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '68':
          report = <UploadTimeReport params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '69':
          report = <AvgSalesReport params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        default:
          report = '';
		      break;
    }
    break;
    case '5':
      switch(state.selectedReport){
        case '10':
          report = <AppraiserServiceRating params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '21':
          report = <AppraiserReport params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '27':
          report = <StateRankReport {...props} params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '29':
          report = <AppraiserPerformance params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '30':
          report = <AppraiserQuality logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '37':
          report = <AppraiserUpdateLogReport params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '46':
          report = <AppraiserQualityMetrics params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '48':
          report = <ExceptionalReport {...props} params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '52':
          report = <AppraiserFeeMap params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        case '55':
          report = <QualityRatingReport params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>
          break;
        default:
          break;
      }
      break;
    default:
      report = '';
      break;
  }


  return <div className="my-well">
    <Row>
      <Col sm="6">
        <div className="page-title">
          <i className="fa fa-reorder"></i>&nbsp;Report & Tools
        </div>
      </Col>
      <Col sm="6">

      </Col>
    </Row>
    <div className="my-divider"></div>
    <Row>
      <Col sm="4">
        <label>Category</label>&nbsp;
        <MySelect
          type="select"
          selectIsClearable={true}
          value={state.selectedReportCategory}
          onChange={(v)=>{setState({selectedReportCategory:v})}}
          options={state.reports.map((category)=>{
            return {label:category.category, value:category.ID};
          })}
        />
      </Col>
      <Col sm="8">
        <label>Report</label>&nbsp;
        <MySelect
          type="select"
          selectIsClearable={true}
          value={state.selectedReport}
          onChange={(v)=>{setState({selectedReport:v})}}
          options={reports.map((report)=>{
            return {label:report.name, value:report.ID};
          })}
        />
      </Col>
    </Row>
    <br/>
    <div className="my-divider"></div>
    {report}
  </div>;
}

export default Reports;
