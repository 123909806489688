//Author Sooyoung Kim
//Date June 28, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, deleteAPICallGenerator, callBackGenerator, formatDateTime, formatNumber, getFromTo, sliceFromArray} from '../../../util/util';
import {Button, Modal, ModalHeader, ModalBody, Card, CardHeader, CardBody, Col, Row, Input, Table, FormGroup, Label} from 'reactstrap';
import DatePicker from 'react-datepicker';
import React, {useReducer, useEffect} from 'react';
import moment from 'moment';
import MyXEditable from '../../util/my-xeditable';
import {NavLink} from 'react-router-dom';
import ReportFilter from '../report-filter';

//initialize the state
const initialState = {
  reportDetails:[],
  reminders:[],
  directDeposits:{},
  datesMap:{},
  audited:'all',
  from:getFromTo('end'),
  to:getFromTo('end'),
  reminderPopUp:false,
  checklist:[]
};

//reducer function that perform state update
const reducer = getReducer();


const UpdateCheckNumber  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getReportDetails();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const clickListener = (clicked, id) =>{
    let checklist = state.checklist.slice();

    let index = checklist.indexOf(id);
    if(clicked){
      if(index===-1)
        checklist.push(id);
    }
    else{
      checklist.splice(index,1);
    }

    setState({checklist:checklist});
  }

  const updateDate = (appraiserFk, date) =>{
    let directDeposits = Object.assign({},state.directDeposits);
    let datesMap = Object.assign({},state.datesMap);

    for(let property in directDeposits){
      if(property===appraiserFk){
        datesMap[property] = date;
        break;
      }
    }

    setState({datesMap:datesMap});
  }

  const toggleReminder = () =>{
    getReminders();
    setState({reminderPopUp:!state.reminderPopUp});
  }

  //API call
  const generateUpdateFunction = (url, parameters, successMessage, failedMessage) =>{
    return (newValue, callBack, errorCallBack)=>{
      //add in the new value into parameters
      parameters.push({field:'value',value:newValue});
      httpPut(url,parameters,successMessage,failedMessage, callBack, errorCallBack);
    }
  };

  const createReminder = () =>{
    let reminders = [];

    for(let property in state.datesMap){
      if(state.datesMap[property]&&state.datesMap[property]!==''){
        let target = state.directDeposits[property];
        let tmp = {};
        let tmp2 = [];
        for(let i=0;i<target.length;i++){
          tmp2.push(target[i].bill_fk);
        }

        tmp.date = state.datesMap[property].format("YYYY-MM-DD");
        tmp.bills = tmp2;

        reminders.push(tmp);
      }
    }

    let parameters = [{field:'reminders', value:reminders}];
    httpPost('report/directDeposit/create', parameters, 'Direct deposit reminder created successfully.', 'Oops, something went wrong and could not create direct deposit reminders. Please try again later.');
  }

  const updateCheckNumber = () =>{
    let parameters = [{field:'IDs',value:state.checklist}];
    httpPost('billing/updateCheckNumber', parameters, 'Check number updated successfully.', 'Oops, something went wrong and could not update the check number. Please try again later.');
  }

  const queryCheck = () =>{
    if(state.bankAccountName!==''&&state.expensesAccountName!==''){
      let parameters = [{field:'from',value:state.from},{field:'to',value:state.to}];
      httpPost('billing/check/inquiry', parameters, 'Check inquiry task queue on Quickbook successfully.', 'Oops, something went wrong and could not queue this check inquiry task on Quickbook. Please try again later.');
    }
  }

  const getReminders = () =>{
    let callBack = apiCallBack([{state:'reminders', key:'data'}]);
    httpGet('report/directDeposit/get', '', 'Oops, something went wrong and could not load direct deposit reminders. Please try again later.', callBack);
  }

  const getReportDetails = () =>{
    let callBack = (response)=>{console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let directDeposits = {};
        let datesMap = {};
        let checks = response.data.data;
        for(let i=0;i<checks.length;i++){
          if(checks[i].payment_method==='Direct Deposit'){
            if(!directDeposits[checks[i].appraiser_fk]){
              directDeposits[checks[i].appraiser_fk] = [];
              datesMap[checks[i].appraiser_fk] = null;
            }
            directDeposits[checks[i].appraiser_fk].push(checks[i]);
          }
        }
        setState({reportDetails:response.data.data});

        let checklist = [];
        for(let i=0;i<response.data.data.length;i++){
          checklist.push(response.data.data[i].ID);
        }

        setState({checklist:checklist, directDeposits:directDeposits, datesMap:datesMap});
      }
    };
    callBack = callBack.bind(this);

    httpGet('report/check/get', '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  const deleteReminder = (id) =>{
    let list = sliceFromArray(state.reminders,'ID', id);
    let callBack = apiCallBack([{state:'reminders', value:list}]);
    httpDelete('report/directDeposit/'+id,'Reminder deleted.','Oops, something went wrong and could not delete this reminder. Please try again later.', callBack);
  }

  //render
  let from, to;
  if(state.from!=='')
    from = moment(state.from);
  if(state.to!=='')
    to = moment(state.to);
  let directDeposits = [];

  for(let property in state.directDeposits){
    let target = state.directDeposits[property];
    let tmp = {};
    tmp.appraiser = target[0].appraiser;

    let total = 0;
    let bills = [];
    for(let i=0;i<target.length;i++){
      bills.push(target[i].bill_fk);
      total = total + parseInt(target[i].total,10);
    }
    tmp.total = total;
    tmp.bills = bills;
    tmp.appraiser_fk = property;
    directDeposits.push(tmp);
  }

  let reportFiltersConfig = [
    {id:'from',value:from, updateFunc:(v)=>setState({from:v}), width:'3'},
    {id:'to',value:to, updateFunc:(v)=>setState({to:v}), width:'3'},
    {id:'button',value:'Submit', updateFunc:queryCheck, width:'6', className:"align-right", color:"warning"},
  ];

  return <div>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Retrieve Check From Quickbook
      </CardHeader>
      <CardBody>
        <ReportFilter {...props} configs={reportFiltersConfig}/>
        <div className="my-divider">&nbsp;</div>
      </CardBody>
    </Card>
    <br/>

    <Card>
      <CardHeader className="header-color">
        <Row>
          <Col sm="6">
            <i className="fa fa-reorder"></i>&nbsp;Direct deposit reminders
          </Col>
          <Col sm="6" className="align-right">
            <NavLink to="#" onClick={toggleReminder}>View existing reminders</NavLink>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <div className="large-scroll-container-no-min">
          <Table className="table table-striped">
            <thead>
              <tr>
                <th width="50%">Appraiser</th>
                <th width="15%">Bill #</th>
                <th width="15%">Amount</th>
                <th width="20%">Email reminder date</th>
              </tr>
            </thead>
            <tbody>
              {
                directDeposits.map(
                  (directDeposit,index)=>{
                    return (
                      <tr key={index}>
                        <td>{directDeposit.appraiser}</td>
                        <td>{directDeposit.bills.map(
                          (bill, index2)=>{
                            return <div className="display-inline" key={index2}>&nbsp;<u>#{bill}</u></div>
                          }
                        )}</td>
                        <td>${formatNumber(directDeposit.total)}</td>
                        <td><DatePicker
                          className="form-control"
                          dateFormat='dd MMM, yyyy'
                          selected={state.datesMap[directDeposit.appraiser_fk]!==null?moment(state.datesMap[directDeposit.appraiser_fk]).toDate():null}
                          onChange={(text)=>{(text)&&updateDate(directDeposit.appraiser_fk,text.toLocaleDateString('en-CA'))}}
                        /></td>
                      </tr>
                    )
                  }
                )
              }
            </tbody>
          </Table>
        </div>
        <div className="align-right"><Button color="warning" onClick={createReminder}>Create direct deposit reminder</Button></div>
        <Modal className="my-modal-wide" isOpen={state.reminderPopUp} toggle={toggleReminder} >
          <ModalHeader hidden={true} toggle={toggleReminder}></ModalHeader>
          <ModalBody>
            <center>
              <h5><i className="fa fa-comments"></i> Active direct deposit reminder</h5>
            </center>
            <br/>

            <div className="large-scroll-container">
              <Table className="table table-striped">
                <thead>
                  <tr>
                    <th width="10%"></th><th width="25%">Appraiser</th><th width="20%">Reference #</th><th width="10%">Bill #</th><th width="15%">Amount</th><th width="20%">Reminder date</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    state.reminders.map(
                      (reminder,index)=>{
                        return <tr key={index}>
                          <td><i className="fa fa-times red-color cursor-pointer" onClick={(e)=>deleteReminder(reminder.ID)}></i></td>
                          <td>{reminder.appraiser}</td>
                          <td>{reminder.reference_num}</td>
                          <td>{reminder.bill_fk}</td>
                          <td>{reminder.total}</td>
                          <td><MyXEditable
                            type="date"
                            value={reminder.datetime_reminder}
                            updateFunc={
                              generateUpdateFunction('report/directDeposit/update',[{field:'ID',value:reminder.ID}],'reminder date updated.', 'An error occured, please try again later.')
                            }
                          /></td>
                        </tr>
                      }
                    )
                  }
                </tbody>
              </Table>
            </div>

            <center>
              <Button color="info" onClick={toggleReminder}>Close</Button>
            </center>
          </ModalBody>
        </Modal>
      </CardBody>
    </Card>
    <br/>
    <Card>
      <CardHeader className="header-color">
        <Row>
          <Col sm="6">
            <i className="fa fa-reorder"></i>&nbsp;Checks from Quickbook
          </Col>
          <Col sm="6" className="align-right">
            <i className="fa fa-refresh cursor-pointer" onClick={getReportDetails}></i>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <div className="large-scroll-container-no-min">
          <Table className="table table-striped">
            <thead>
              <tr>
                <th width="5%"></th>
                <th width="15%">Reference #</th>
                <th width="20%">Address</th>
                <th width="10%">Bill #</th>
                <th width="10%">Check #</th>
                <th width="10%">Amount</th>
                <th width="15%">Appraiser</th>
                <th width="15%">Check created</th>
              </tr>
            </thead>
            <tbody>
              {
                state.reportDetails.map(
                  (row,index)=>{
                    return(
                      <tr key={index}>
                        <td>
                          <FormGroup check>
                            <Label check>
                              <Input type="checkbox" defaultChecked={true} onClick={(e)=>clickListener(e.target.checked,row.ID)}/>
                            </Label>
                          </FormGroup>
                        </td>
                        <td><NavLink target="_blank" to={"/appraisal/"+row.appraisal_fk}>{row.reference_num}</NavLink></td>
                        <td>{row.property_street+' '+row.property_city+', '+row.property_state+' '+row.property_zip}</td>
                        <td>{row.bill_fk}</td>
                        <td>{row.check_num}</td>
                        <td>${formatNumber(row.total)}</td>
                        <td>{row.appraiser}</td>
                        <td>{formatDateTime(row.datetime_paid)}</td>
                      </tr>
                    );
                  }
                )
              }
            </tbody>
          </Table>
        </div>
        <div className="align-right">
          <Button color="warning" onClick={updateCheckNumber}>Update check number to bills</Button>
        </div>
      </CardBody>
    </Card>
  </div>;
}


export default UpdateCheckNumber;
