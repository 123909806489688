//Author Sooyoung Kim
//Date May 11th, 2022
//The component for viewing a list of article(S)
import {formatDate, getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator} from '../../util/util';
import InfiniteScroll from 'react-infinite-scroll-component';
import {NavLink} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';
import {Row, Col, Input, Button} from 'reactstrap';
import './article.css';

//initialize the state
const initialState = {
  articles:[],
  limit: 25,
  offset: 0,
  hasMoreArticles: true,
  loading: false,
  sort: 'datetime_created',
  order: 'DESC',
  title: ''
};

//reducer function that perform state update
const reducer = getReducer();


const Articles  = (props)=>{
  const controller = new AbortController();
  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);
  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    if(state.articles.length<=0 && state.hasMoreArticles){
        loadMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state]);

  useEffect(()=>{

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //function that manipulate state
  //this function refresh the list of admins pipeline
  const refreshList = ()=>{
    setState({
      articles:[],
      hasMoreArticles:true,
      offset:0
    });
  }

  //click handler that switch the state of sorting order then call the function to refresh the list
  //to achieve the "sorting"
  const columnClickHandler = (col)=>{
    if(state.sort===col){
      if(state.order==='ASC')
        setState({order:'DESC'});
      else
        setState({order:'ASC'});
    }
    else
      setState({order:'ASC', sort:col});
    refreshList();
  }

  //non API call but simpyl manage state

  //this function render the sort icon at each column of the table
  const renderSortIcon = (col)=>{
    if(state.sort===col){
      if(state.order==='ASC')
        return <i className="red-color fa fa-arrow-down"></i>
      else
        return <i className="red-color fa fa-arrow-up"></i>
    }
  }

  //render function for infinite scroller
  const renderarticles = ()=>{
    return state.articles.map(
      (article)=>{
        let keyword = 'portal';

        if(article.internal==='yes')
          keyword = 'admin';


        return(
          <tr key={article.ID}>
            <td><NavLink to={'/article/'+article.ID}>{article.title}</NavLink></td>
            <td><i style={{fontSize:'20px'}} onClick={(e)=>{
                  let tempInput = document.createElement("input");
                  tempInput.value = `https://${keyword}.homevms.com/article/`+article.ID;
                  document.body.appendChild(tempInput);
                  tempInput.select();
                  document.execCommand("copy");
                  document.body.removeChild(tempInput);
                }}/> <a target="_blank" rel="noreferrer" href={`https://${keyword}.homevms.com/article/`+article.ID}>{`https://${keyword}.homevms.com/article/`+article.ID}</a>
            </td>
            <td>{article.creator}</td>
            <td>{formatDate(article.datetime_created)}</td>
            <br/>
          </tr>
        );
      }
    );
  }


  //API call
  //function that fire when the infinite scroll reach bottom
  const loadMore = ()=>{
    //do not load if there is no more article or it's loading data
    if(state.hasMoreArticles&&!state.loading){
      //set loading equals to true so it won't fire off before we are done
      setState({loading:true});

      let title = encodeURIComponent(state.title.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));

      let url = 'article/get/limit='+state.limit+'&offset='+state.offset+'&order='+state.order+'&sort='+state.sort+'&title='+title;

      //callback handler that update the state when http request return
      let callBack = (response)=>{
        let code = response.data?response.data.code:undefined;

        if(code!=='00'){
          setState({hasMoreArticles:false});
        }
        else{
          let newarticles = response.data.data;
          let hasMoreArticles = true;
          let newOffset = state.offset;

          //if http request return empty then no more results, end of list
          if(newarticles.length<=0){
            hasMoreArticles = false;
          }
          else{
            //increment the offset
            newOffset = state.offset + 1;
          }

          //concat the current array of article
          if(state.articles.length>0){
            let temp = [...state.articles,...newarticles];

            setState({articles:temp});
          }
          else
            setState({articles:newarticles});

          setState({hasMoreArticles:hasMoreArticles, offset:newOffset});
        }
      };

      //error handler when the http request return with error
      let errorCallBack = apiCallBack([{state:'hasMoreArticles', value:false}]);

      //collect the promise and wait for it to finish performing it's task
      let promises = httpGet(url, '','Oops, something went wrong and could not load articles. Please try again later.', callBack, errorCallBack);
      promises
        .then(
          function(result){
            //set loading equals to false so the function could be fire off once again
            setState({loading:false});
          }
        );
    }
  }

  //render
  let createNewarticleLink = <div className="align-right"><NavLink to={'/article/new'}><i className="fa fa-plus"></i>&nbsp;Create new article.</NavLink><br/><br/></div>;

  return <div>
    <InfiniteScroll
      next={loadMore}
      dataLength={state.articles.length}
      hasMore={state.hasMoreArticles}
      loader={<div key="nill" className="loader"><center>Loading more articles...</center></div>}
      initialLoad = {true}
      className="my-well"
      scrollableTarget="contentContainer"
    >
      <Row>
        <Col sm="6">
          <div className="page-title">
            <i className="fa fa-reorder"></i>&nbsp;Articles
          </div>
        </Col>
        <Col sm="6">
          {createNewarticleLink}
        </Col>
      </Row>
      <Row>
        <Col sm="6">
          <label className="no-margin-bottom"><i className="fa fa-search"></i>&nbsp;Search by Title</label>
          <Input type="text" value={state.title} onChange={(e)=>setState({title:e.target.value})}/>
        </Col>
        <Col sm="6">
          <br/>
          <div className="align-right"><Button color="warning" onClick={()=>refreshList()} >Submit</Button></div>
        </Col>
      </Row>
      <br/>
      <table className="primary-table">
        <thead>
          <tr>
            <th className="cursor-pointer" width="35%" onClick={()=>columnClickHandler('title')}><i className="fa fa-list"></i>&nbsp;Title {renderSortIcon('title')}</th>
            <th className="cursor-pointer" width="35%" onClick={()=>columnClickHandler('internal')}><i className="fa fa-copy cursor-pointer"></i>&nbsp;Page {renderSortIcon('internal')}</th>
            <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('creator')}><i className="fa fa-user"></i>&nbsp;Creator {renderSortIcon('creator')}</th>
            <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('datetime_created')}><i className="fa fa-calendar-o"></i>&nbsp;Date {renderSortIcon('datetime_created')}</th>
          </tr>
        </thead>
        <tbody>
          {renderarticles()}
        </tbody>
      </table>
    </InfiniteScroll>
  </div>;
}

export default Articles;
