//Author Sooyoung Kim
//Date May 26, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, callBackGenerator, formatDateTime, showMessage} from '../../util/util';
import {Alert, Card, CardHeader, CardBody, Col, Row, FormGroup, Label,Modal, ModalHeader, ModalBody, NavItem, Nav, TabContent, TabPane, Button,Input, Table,NavLink as NavLinkL} from 'reactstrap';
import {usePresencePusher} from '../pusher/pusher-context';
import MySelect from '../util/my-select';
import {NavLink, useParams, } from 'react-router-dom';
import ReactQuill from 'react-quill';
import './condition.css';
import React, {useReducer, useEffect, useRef} from 'react';


//initialize the state
const initialState = {
  conditionInternalTickets:[],
  conditionExternalTickets:[],
  conditionActiveTab:'1',
  appraisal:{
    property_street:'',
    property_city:'',
    property_state:'',
    borrower_f_name:'',
    borrower_l_name:'',
    reference_num:'',
    loan_num:''
  },
  overallStatus:{
    review_count:0,
    condition_count:0,
    rebuttal_count:0,
    due_invoice_count:0
  },
  internalConditionPopUp: false,
  externalConditionPopUp: false,
  entities:[],
  selectedRecipient:-1,
  templates:[],
  ticketsToSend:[],
  filesToSend:[],
  internalConditionCommentToAppraiser:'',
  externalConditionCommentToAppraiser:'',
  templateName1:'',
  templateName2:'',
  appraisalFiles:[],
  targetRecipients:[],
  statuses:[],
  conditionTypes:[],
  presenceMembers:[],
  messages:[],
};

//reducer function that perform state update
const reducer = getReducer();


const Condition  = (props)=>{
  const controller = new AbortController();
  const presencePusher = usePresencePusher ();
  const componentRef = useRef(null);
  const { id } = useParams();

  let newInitialState = Object.assign({}, initialState, {
    id:id,
    globalPipeline:localStorage.getItem('pipelinePreference')==='global',
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    initialize();
    getConditionTickets();
    getTemplates();
    getAppraisalFiles();
    getAllConditionStatuses();
    getConditionTypes();

    if (componentRef.current && componentRef.current.parentElement) {
      componentRef.current.parentElement.scrollTop = 0;
    }

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if (presencePusher) {
      const presenceChannel = presencePusher.subscribe('presence-order');

      const handleSubscriptionSucceeded = (members) => {
        // Get the initial user list from presence channel
        let presenceMembers = [];
        members.each(
          function(member)
          {
            let ID = member.info.ID;
            let orderID = member.info.orderID;
            let myID = localStorage.getItem('userID');
            if(orderID===props.appraisalFk){
              if(ID!==myID){
                presenceMembers.push(member);
              }
            }
          }
        );
        console.log(presenceMembers)
        setState({presenceMembers:presenceMembers})
      };

      const handleMemberAdded = (member) => {
        let ID = member.info.ID;
        let orderID = member.info.orderID;
        let myID = localStorage.getItem('userID');
        if(orderID===props.appraisalFk){
          if(ID!==myID){
            let presenceMembers = state.presenceMembers.slice();
            presenceMembers.push(member);

            setState({presenceMembers:presenceMembers});
          }
        }
      };

      const handleMemberRemoved = (member) => {
        // Remove a user from the user list
        let ID = member.info.ID;
        let orderID = member.info.orderID;
        let myID = localStorage.getItem('userID');

        if(orderID===props.appraisalFk){
          if(ID!==myID){
            let presenceMembers = state.presenceMembers.slice();

            for(let i=0;i<presenceMembers.length;i++){
              if(presenceMembers[i].info.ID===member.info.ID){
                presenceMembers.splice(i, 1);
                setState({presenceMembers:presenceMembers});
                break;
              }
            }
          }
        }
      };

      presenceChannel.bind('pusher:subscription_succeeded', handleSubscriptionSucceeded);
      presenceChannel.bind('pusher:member_added', handleMemberAdded);
      presenceChannel.bind('pusher:member_removed', handleMemberRemoved);

      return () => {
        presenceChannel.unbind('pusher:subscription_succeeded', handleSubscriptionSucceeded);
        presenceChannel.unbind('pusher:member_added', handleMemberAdded);
        presenceChannel.unbind('pusher:member_removed', handleMemberRemoved);

        presenceChannel.unsubscribe();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presencePusher, props.appraisalFk]);

  const initialize = ()=>{
    let entities = [];
    for(let i=0;i<props.appraisal.entities.length;i++){
      let entity = props.appraisal.entities[i];
      entities.push(entity);
    }

    setState({entities:entities});
  }

  const conditionActiveTabToggle = (tab) =>{
    setState({conditionActiveTab:tab});
  }

  const internalConditionPopUpToggle = () =>{
    setState({internalConditionPopUp:!state.internalConditionPopUp});
    let tickets = [];
    for(let i=0;i<state.conditionInternalTickets.length;i++){
      if(state.conditionInternalTickets[i].status==='Submitted')
        tickets.push(state.conditionInternalTickets[i].ID);
    }
    setState({ticketsToSend:tickets});
  }

  const externalConditionPopUpToggle = () =>{
    setState({externalConditionPopUp:!state.externalConditionPopUp});
    let tickets = [];
    for(let i=0;i<state.conditionExternalTickets.length;i++){
      if(state.conditionExternalTickets[i].status==='Submitted')
        tickets.push(state.conditionExternalTickets[i].ID);
    }
    setState({ticketsToSend:tickets});
  }

  const modifyTicketsToSend = (ID) =>{
    let ticketsToSend = state.ticketsToSend.slice();
    let index = ticketsToSend.indexOf(ID);


    if(index!==-1)
      ticketsToSend.splice(index,1);
    else
      ticketsToSend.push(ID);
    setState({ticketsToSend:ticketsToSend});
  }

  const modifyFilesToSend = (ID) =>{
    let filesToSend = state.filesToSend.slice();
    let index = filesToSend.indexOf(ID);


    if(index!==-1)
      filesToSend.splice(index,1);
    else
      filesToSend.push(ID);
      console.log(filesToSend);
    setState({filesToSend:filesToSend});
  }

  //add all entity associated with this appraisal to target recipients that will receive condition notification
  const addAllToTargetRecipients = () =>{
    let allRecipients = state.entities.slice();
    setState({targetRecipients:allRecipients});
  }

  //remove a recipient from target recipient that will receive condition notification
  const removeFromTargetRecipients = (id) =>{
    for(let i =0;i<state.targetRecipients.length;i++){
      if(state.targetRecipients[i].ID===id){
        let newTargetRecipients = state.targetRecipients.slice();
        newTargetRecipients.splice(i,1);

        setState({targetRecipients:newTargetRecipients});
      }
    }
  }

  //on change function for the select drop down that will populate the text area
  const templatesOnChange = (id, type) =>{
    let template;

    for(let i=0;i<state.templates.length;i++){
      if(state.templates[i].ID===id){
        template = state.templates[i];
        break;
      }
    }

    if(template){
      if(type ==='internal'){
        setState({internalConditionCommentToAppraiser:template.template, templateName1:id});
      }else{
        setState({externalConditionCommentToAppraiser:template.template, templateName2:id});
      }
    }
  }

  const updateExternalTicketType = (id, type) =>{
    let tickets = state.conditionExternalTickets.slice();
    for(let i=0;i<tickets.length;i++){
      if(tickets[i].ID===id){
        tickets[i].type= type;
        break;
      }
    }
    setState({conditionExternalTickets:tickets});

    updateConditionType(id,type);
  }

  const updateInternalTicketType = (id, type) =>{
    let tickets = state.conditionExternalTickets.slice();

    for(let i=0;i<tickets.length;i++){
      if(tickets[i].ID===id){
        tickets[i].type= type;
        break;
      }
    }
    setState({conditionExternalTickets:tickets});

    updateConditionType(id,type);
  }

  const addNewRecipients = (ID) =>{
    if(ID==='All')
      addAllToTargetRecipients();
    else{
      for(let i =0;i<state.targetRecipients.length;i++){
        if(state.targetRecipients[i].ID===ID)
          return;
      }
      let existingNewTargetRecipients = state.targetRecipients.slice();

      let targetEntity = null;
      for(let i=0;i<state.entities.length;i++){
        if(state.entities[i].ID===ID){
          targetEntity = state.entities[i];
          break;
        }
      }

      if(targetEntity){
        existingNewTargetRecipients.push(targetEntity);
        setState({targetRecipients:existingNewTargetRecipients});
      }
    }
  }
  //API call
  //get all condition tickets
  const getConditionTickets = () =>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let internalTickets = [];
        let externalTickets = [];

        for(let i=0;i<response.data.data.length;i++){
          let condition = response.data.data[i];

          if(condition.is_internal==='yes')
            internalTickets.push(condition);
          else
            externalTickets.push(condition);
        }

        setState({conditionInternalTickets:internalTickets,conditionExternalTickets:externalTickets});
      }
    };

    callBack = callBack.bind(this);
    httpGet('condition/'+props.appraisalFk, '', 'Oops, something went wrong and could not load the condition for this appraisal. Please try again later.', callBack);
  }

  //get templates
  const getTemplates = () =>{
    let callBack = apiCallBack([{state:'templates', key:'data'}]);
    httpGet('template/get/'+props.appraisalFk, '', 'Oops, something went wrong and could not load your comment template.', callBack);
  }

  //retrieve a list of appraisal files that ties to a specific appraisal
  const getAppraisalFiles = () =>{
    //Call Back
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        setState({appraisalFiles:response.data.data});

        for(let i=0;i<response.data.data.length;i++){
          if(response.data.data[i].condition_use==='yes')
            modifyFilesToSend(response.data.data[i].ID);
        }
      }
    };
    callBack = callBack.bind(this);
    httpGet('file/get/'+props.appraisalFk, '', 'Oops, something went wrong and could not load appraisal files for this appraisal. Please try again later.', callBack);
  }

  const updateConditionField = (id, field, value) =>{
    let parameters = [
      {
        field:'ID',
        value:id
      },
      {
        field:'field',
        value:field
      },
      {
        field:'value',
        value:value
      }
    ];

    let callBack = (response)=>{
      let code= response.data.code;

      if(code==='00'){
        let conditions = state.conditionInternalTickets.slice();

        for(let i=0;i<conditions.length;i++){
          if(conditions[i].ID===id){
            conditions[i][field] = value;
            break;
          }
        }
        setState({conditionInternalTickets:conditions});

        conditions = state.conditionExternalTickets.slice();

        for(let i=0;i<conditions.length;i++){
          if(conditions[i].ID===id){
            conditions[i][field] = value;
            break;
          }
        }
        setState({conditionExternalTickets:conditions});
      }
    };
    callBack = callBack.bind(this);
    httpPut('condition/update', parameters, 'Condition updated successfully.', 'Oops, something went wrong and could not updated the condition. Please try again later.', callBack);
  }

  const startCondition = (type) =>{
    if(state.ticketsToSend.length>0){
      let comment;
      if(type==='internal')
        comment = state.internalConditionCommentToAppraiser;
      else
        comment = state.externalConditionCommentToAppraiser;

      let targetRecipients =[];

      for(let i=0;i<state.targetRecipients.length;i++)
        targetRecipients.push(state.targetRecipients[i].ID);

      let parameters = [
        {
          field:'ID',
          value:props.appraisalFk
        },
        {
          field:'files',
          value:state.filesToSend
        },
        {
          field:'tickets',
          value:state.ticketsToSend
        },
        {
          field:'comment',
          value:comment
        },
        {
          field:'entities',
          value:targetRecipients
        }
      ];
      let callBack = (response)=>{
        let code= response.data.code;
        if(code==='00'){
          if(type==='internal'){
            let internalTickets = state.conditionInternalTickets.slice();
            for(let i=0;i<state.ticketsToSend.length;i++){
              for(let j=0;j<internalTickets.length;j++){
                if(internalTickets[j].ID===state.ticketsToSend[i]){
                  internalTickets[j].status = 'Started';
                  break;
                }
              }
            }

            setState({conditionInternalTickets:internalTickets});
          }
          else{
            let externalTickets = state.conditionExternalTickets.slice();
            for(let i=0;i<state.ticketsToSend.length;i++){
              for(let j=0;j<externalTickets.length;j++){
                if(externalTickets[j].ID===state.ticketsToSend[i]){
                  externalTickets[j].status = 'Started';
                  break;
                }
              }
            }

            setState({conditionExternalTickets:externalTickets});
          }


          setState({ticketsToSend:[], filesToSend:[], internalConditionCommentToAppraiser:'', externalConditionCommentToAppraiser:'', targetRecipients:[]});
          if(type==='internal')
            internalConditionPopUpToggle();
          else
            externalConditionPopUpToggle();
        }
      };
      callBack = callBack.bind(this);
      httpPost('condition/start', parameters, 'Condition started successfully.', 'Oops, something went wrong and could not start the condition. Please try again later.', callBack);
    }
    else{
      showMessage('warning','Please select at least one condition.');
    }
  }

  //get all the status of condition for the drop down filter
  const getAllConditionStatuses = () =>{
    httpGet('condition/status/get', '', 'Oops, something went wrong and could not retrieve condition statuses.', apiCallBack([{state:'statuses', key:'data'}]));
  }

  const getConditionTypes = () =>{
    httpGet('condition/type/get', '', 'Oops, something went wrong and could not load condition types. Please try again later.', apiCallBack([{state:'conditionTypes', key:'data'}]));
  }

  const updateConditionType = (id, type) =>{
    let parameters = [{field:'field',value:'type'},{field:'value',value:type},{field:'ID',value:id}];
    httpPut('condition/update', parameters, '', 'Oops, something went wrong and could not update this condition. Please try again later.');
  }

  //render
  let appraisalFiles;

  if(state.appraisalFiles.length>0){
    appraisalFiles = state.appraisalFiles.map(
      (appraisalFile,index)=>{
        return(
          <div key={index}>
            <div className="display-inline">
              <FormGroup check>
                <Label check>
                  <Input type="checkbox" defaultChecked={appraisalFile.condition_use==='yes'} onClick={()=>modifyFilesToSend(appraisalFile.ID)}/>&nbsp;
                </Label>
              </FormGroup>
            </div>
            {appraisalFile.name}
          </div>
        );
      }
    );
  }


  let internalConditionTickets, externalConditionTickets;
  let internalConditionTicketsToSend, externalConditionTicketsToSend;

  if(state.conditionInternalTickets.length>0){
    internalConditionTicketsToSend = state.conditionInternalTickets.map(
      (ticket,index)=>{
        if(ticket.status!=='Submitted')
          return null;
        let ticketCategory = ticket.type;

        if(ticket.type==='Other')
          ticketCategory += " "+ticket.other;

        return(
          <div key={index}>
            <div className="display-inline">
              <FormGroup check>
                <Label check>
                  <Input type="checkbox" defaultChecked onClick={()=>modifyTicketsToSend(ticket.ID)}/>&nbsp;
                </Label>
              </FormGroup>
            </div>
            {ticketCategory}
          </div>
        );
      }
    );
    internalConditionTickets = state.conditionInternalTickets.map(
      (ticket,index)=>{
        let icon;
        let ticketCategory;

        if(ticket.type==='Other')
          ticketCategory = ticket.other;

        if(ticket.status==='Completed')
          icon = <i className="fa fa-check green-color"></i>
        else
          icon = <i className="fa fa-clock-o link-color"></i>

        return(
          <div className="ex-margin-bottom" key={ticket.ID}>
            <Card>
              <CardHeader className="gray-color">
                <Row>
                  <Col sm="8">
                    <div className="display-inline" style={{verticalAlign:'top',paddingTop:'10px'}}>
                      {icon}{" "+(index+1)+". "}&nbsp;
                    </div>
                    <div className="display-inline" style={{width:'250px'}}>
                      <MySelect
                        type="select"
                        value={ticket.type}
                        onChange={(v)=>updateInternalTicketType(ticket.ID, v)}
                        options={state.conditionTypes.map((type)=>{
                          return {label:type.type, value:type.type};
                        })}
                      />
                    </div>&nbsp;&nbsp;
                    {ticketCategory}
                  </Col>
                  <Col sm="4">
                    <div className="align-right">
                      <div className="display-inline" style={{width:'150px'}}>
                        <MySelect
                          type="select"
                          value={ticket.status}
                          onChange={(v)=>updateConditionField(ticket.ID,'status',v)}
                          options={state.statuses.map((status)=>{
                            return {label:status.name, value:status.name};
                          })}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row className="ex-margin-bottom">
                  <Col sm="6" className="low-line-height">
                    <label>Submitted</label><br/>
                    {formatDateTime(ticket.datetime_created)}
                  </Col>
                  <Col sm="6" className="low-line-height">
                    <label>Started</label><br/>
                    {formatDateTime(ticket.datetime_started)}
                  </Col>
                </Row>
                <Row className="ex-margin-bottom">
                  <Col sm="6" className="low-line-height">
                    <label>Processed</label><br/>
                    {formatDateTime(ticket.datetime_processed)}
                  </Col>
                  <Col sm="6" className="low-line-height">
                    <label>Completed</label><br/>
                    {formatDateTime(ticket.datetime_completed)}
                  </Col>
                </Row>
                <Row className="ex-margin-bottom">
                  <Col sm="6" className="low-line-height">
                    <label>Requester Name</label><br/>
                    {ticket.requester_name}
                  </Col>
                  <Col sm="6" className="low-line-height">
                    <label>Is Internal</label><br/>
                    {ticket.is_internal}
                  </Col>
                </Row>
                <Row className="ex-margin-bottom">
                  <Col sm="12" className="low-line-height">
                    <label>Requester Email</label><br/>
                    {ticket.requester_email}
                  </Col>
                </Row>
                <div className="my-divider"></div>
                <ReactQuill
                  value={ticket.subject_facts}
                  onChange={(value) => ticket.subject_facts=value}
                />
                <Button color="info" className="form-control" onClick={()=>updateConditionField(ticket.ID,'subject_facts',ticket.subject_facts)}><i className="fa fa-edit"></i> Save Subject Facts</Button>
              </CardBody>
            </Card>
          </div>
        )
      }
    );
  }

  if(state.conditionExternalTickets.length>0){
    externalConditionTicketsToSend = state.conditionExternalTickets.map(
      (ticket,index)=>{
        if(ticket.status!=='Submitted')
          return null;

        let ticketCategory = ticket.type;

        if(ticket.type==='Other')
          ticketCategory += " "+ticket.other;

        return(
          <div key={index}>
            <div className="display-inline">
              <FormGroup check>
                <Label check>
                  <Input type="checkbox" defaultChecked onClick={()=>modifyTicketsToSend(ticket.ID)}/>&nbsp;
                </Label>
              </FormGroup>
            </div>
            {ticketCategory}
          </div>
        );
      }
    );
    externalConditionTickets = state.conditionExternalTickets.map(
      (ticket,index)=>{
        let icon;
        let ticketCategory;

        if(ticket.type==='Other')
          ticketCategory = ticket.type;

        if(ticket.status==='Completed')
          icon = <i className="fa fa-check green-color"></i>
        else
          icon = <i className="fa fa-clock-o link-color"></i>

        return(
          <div className="ex-margin-bottom" key={ticket.ID}>
            <Card>
              <CardHeader className="gray-color">
                <Row>
                  <Col sm="8">
                    <div className="display-inline" style={{verticalAlign:'top',paddingTop:'10px'}}>
                      {icon}{" "+(index+1)+". "}&nbsp;
                    </div>
                    <div className="display-inline" style={{width:'250px'}}>
                      <MySelect
                        type="select"
                        value={ticket.type}
                        onChange={(v)=>updateExternalTicketType(ticket.ID, v)}
                        options={state.conditionTypes.map((type)=>{
                          return {label:type.type, value:type.type};
                        })}
                      />
                    </div>&nbsp;&nbsp;
                    {ticketCategory}
                  </Col>
                  <Col sm="4">
                    <div className="align-right">
                      <div className="display-inline" style={{width:'150px'}}>
                        <MySelect
                          type="select"
                          value={ticket.status}
                          onChange={(v)=>updateConditionField(ticket.ID, 'status',v)}
                          options={state.statuses.map((status)=>{
                            return {label:status.name, value:status.name};
                          })}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row className="ex-margin-bottom">
                  <Col sm="6" className="low-line-height">
                    <label>Submitted</label><br/>
                    {formatDateTime(ticket.datetime_created)}
                  </Col>
                  <Col sm="6" className="low-line-height">
                    <label>Started</label><br/>
                    {formatDateTime(ticket.datetime_started)}
                  </Col>
                </Row>
                <Row className="ex-margin-bottom">
                  <Col sm="6" className="low-line-height">
                    <label>Processed</label><br/>
                    {formatDateTime(ticket.datetime_processed)}
                  </Col>
                  <Col sm="6" className="low-line-height">
                    <label>Completed</label><br/>
                    {formatDateTime(ticket.datetime_completed)}
                  </Col>
                </Row>
                <Row className="ex-margin-bottom">
                  <Col sm="6" className="low-line-height">
                    <label>Requester Name</label><br/>
                    {ticket.requester_name}
                  </Col>
                  <Col sm="6" className="low-line-height">
                    <label>Is Internal</label><br/>
                    {ticket.is_internal}
                  </Col>
                </Row>
                <Row className="ex-margin-bottom">
                  <Col sm="12" className="low-line-height">
                    <label>Requester Email</label><br/>
                    {ticket.requester_email}
                  </Col>
                </Row>
                <div className="my-divider"></div>
                <ReactQuill
                  value={ticket.subject_facts}
                  onChange={(value) => ticket.subject_facts=value}
                />
                <Button color="info" className="form-control" onClick={()=>updateConditionField(ticket.ID,'subject_facts',ticket.subject_facts)}><i className="fa fa-edit"></i> Save Subject Facts</Button>
              </CardBody>
            </Card>
          </div>
        )
      }
    );
  }

  let internalConditionButton, externalConditionButton;

  if(state.conditionInternalTickets.length>0)
    internalConditionButton = <Button onClick={internalConditionPopUpToggle} color="warning"><i className="fa fa-power-off"></i> Begin Condition</Button>
  else
    internalConditionButton = <Button color="info" disabled><i className="fa fa-power-off"></i> Begin Condition</Button>

  if(state.conditionExternalTickets.length>0)
    externalConditionButton = <Button onClick={externalConditionPopUpToggle} color="warning"><i className="fa fa-power-off"></i> Begin Condition</Button>
  else
    externalConditionButton = <Button color="info" disabled><i className="fa fa-power-off"></i> Begin Condition</Button>

  let entities;
  if(state.entities){
    entities = state.entities.map(
      (entity, index)=>{
        return (
          <tr key={index} className="cursor-pointer" onClick={(e)=>addNewRecipients(entity.ID)}><td><b>{entity.entity_label}</b></td><td>{entity.entity_name} - {entity.entity_email}</td></tr>
        );
      }
    );
  }

  let targetRecipients;
  if(state.targetRecipients){
    targetRecipients = state.targetRecipients.map(
      (recipient)=>{
        return(
          <div style={{display:'inline-block'}} key={recipient.ID}>
            <div className="entity-container cursor-pointer" onClick={()=>{removeFromTargetRecipients(recipient.ID)}}>
              <i className="fa fa-minus link-color"></i>&nbsp;{recipient.entity_name}
            </div>&nbsp;
          </div>
        );
      }
    );
  }

  return <div ref={componentRef}>
    <Card  style={{borderTop:'0px'}}>
      <CardHeader className="header-color">
        <i className="fa fa-home"></i>&nbsp;{props.appraisal.reference_num} - {props.appraisal.property_street} {props.appraisal.property_city}, {props.appraisal.property_state} {props.appraisal.property_zip} - {props.appraisal.borrower_f_name} {props.appraisal.borrower_l_name}
      </CardHeader>
      <CardBody>
        {
          props.messages.map(
            (message, index)=>{
              if(message.link){
                return(<a key={index} href={'/'+message.link+'/'+message.ID} target="_blank" rel="noreferrer"><Alert key={index} color="warning" className="my-alert">
                    <i className="fa fa-information"></i> {message.msg}
                  </Alert></a>);
              }

              return(<Alert key={index} color="warning" className="my-alert">
                  <i className="fa fa-information"></i> {message.msg}
                </Alert>);
            }
          )
        }
      </CardBody>
    </Card>
    <br/>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-home"></i>&nbsp;Condition - ({state.conditionExternalTickets.length+state.conditionInternalTickets.length} tickets)
      </CardHeader>
      <CardBody>
        <div className="align-right">
          <NavLink to={"/condition/new/"+props.appraisalFk}><i className="fa fa-plus green-color"></i> Create new condition</NavLink>
        </div>
        <Nav tabs>
          <NavItem>
            <NavLinkL
              className="my-nav-link cursor-pointer" active={state.conditionActiveTab==='1'} 
              onClick={() => { conditionActiveTabToggle('1'); }}
              to="#"
            >
              Internal <span className="my-badge">{state.conditionInternalTickets.length}</span>
            </NavLinkL>
          </NavItem>
          <NavItem>
            <NavLinkL
              className="my-nav-link cursor-pointer" active={state.conditionActiveTab==='2'} 
              onClick={() => { conditionActiveTabToggle('2'); }}
              to="#"
            >
              External  <span className="my-badge">{state.conditionExternalTickets.length}</span>
            </NavLinkL>
          </NavItem>
        </Nav>
        <TabContent activeTab={state.conditionActiveTab}>
          <TabPane tabId="1">
            {internalConditionTickets}
            <br/>
            <div className="align-right">
              {internalConditionButton}
            </div>
            <Modal className="my-modal" isOpen={state.internalConditionPopUp} toggle={internalConditionPopUpToggle} >
              <ModalHeader hidden={true} toggle={internalConditionPopUpToggle}></ModalHeader>
              <ModalBody>
                <center>
                  <h5><i className="fa fa-cogs"></i> Begin Internal Condition</h5>
                </center>
                <br/>
                <label>Tag someone</label>

                <div className="small-scroll-container-ex-large">
                  <Table hover className="table">
                    <tbody>
                      {entities}
                    </tbody>
                  </Table>
                </div>
                <hr className="margin-bottom"/>
                <div className="small-scroll-container">
                  {targetRecipients}
                </div>
                <br/>
                <label>Tickets to send:</label>
                <br/>
                {internalConditionTicketsToSend}

                <div className="my-divider"></div>
                <font red="color">** If total file size is bigger than 7MB then it won't attach within email **</font>
                <label>Files to send along:</label>
                <br/>

                <div className="small-scroll-container">
                  {appraisalFiles}
                </div>
                <div className="my-divider"></div>
                <br/>
                <label>Comment:</label>
                <br/>
                <Input type="textarea" className="form-control comment-textarea" value={state.internalConditionCommentToAppraiser} id="comment" placeholder="Say something..." style={{resize:'none'}} rows="5" onChange={(e)=>setState({internalConditionCommentToAppraiser:e.target.value})}></Input>
                <Row>
                  <Col sm="6">
                    <label>Template:</label>
                  </Col>
                  <Col sm="6" className="align-right">
                    <a href="/setting">Manage my template</a>
                  </Col>
                </Row>
                <MySelect
                  type="select"
                  selectIsClearable={true}
                  value={state.templateName1}
                  onChange={(v)=>{templatesOnChange(v, 'internal')}}
                  options={state.templates.map((template)=>{
                    return {label:template.name, value:template.ID};
                  })}
                />
                <div className="my-diviver"></div>
                <center>
                  <Button color="warning" onClick={()=>startCondition('internal')}>Start</Button>&nbsp;
                  <Button color="info" onClick={internalConditionPopUpToggle}>Close</Button>
                </center>
              </ModalBody>
            </Modal>
          </TabPane>
          <TabPane tabId="2">
            {externalConditionTickets}
            <br/>
            <div className="align-right">
              {externalConditionButton}
            </div>
            <Modal className="my-modal" isOpen={state.externalConditionPopUp} toggle={externalConditionPopUpToggle} >
              <ModalHeader hidden={true} toggle={externalConditionPopUpToggle}></ModalHeader>
              <ModalBody>
                <center>
                  <h5><i className="fa fa-cogs"></i> Begin External Condition</h5>
                </center>
                <br/>
                <label>Tickets to send:</label>
                <br/>
                {externalConditionTicketsToSend}

                <div className="my-divider"></div>
                <font red="color">** If total file size is bigger than 7MB then it won't attach within email **</font>
                <label>Files to send along:</label>
                <br/>

                <div className="small-scroll-container">
                  {appraisalFiles}
                </div>
                <div className="my-divider"></div>
                <br/>
                <label>Comment:</label>
                <br/>
                <Input type="textarea" className="form-control comment-textarea" value={state.externalConditionCommentToAppraiser} id="comment" placeholder="Say something..." style={{resize:'none'}} rows="5" onChange={(e)=>setState({externalConditionCommentToAppraiser:e.target.value})}></Input>
                <Row>
                  <Col sm="6">
                    <label>Template:</label>
                  </Col>
                  <Col sm="6" className="align-right">
                    <a href="/setting">Manage my template</a>
                  </Col>
                </Row>

                <MySelect
                  type="select"
                  selectIsClearable={true}
                  value={state.templateName2}
                  onChange={(v)=>{templatesOnChange(v, 'external')}}
                  options={state.templates.map((template)=>{
                    return {label:template.name, value:template.ID};
                  })}
                />

                <hr className="margin-bottom"/>
                <div className="small-scroll-container">
                  {targetRecipients}
                </div>
                <div className="my-diviver"></div>
                <br/>
                <center>
                  <Button color="warning" onClick={()=>startCondition('external')}>Start</Button>&nbsp;
                  <Button color="info" onClick={externalConditionPopUpToggle}>Close</Button>
                </center>
              </ModalBody>
            </Modal>
          </TabPane>
        </TabContent>
      </CardBody>
    </Card>
  </div>;
}

export default Condition;
