//Author June Leow
//Date 2022-05-20
import Avatar from 'react-avatar';
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, deleteAPICallGenerator, callBackGenerator, stringToHexCode, sliceFromArray, confirmation, formatDate} from '../../util/util';
import {Button, Table, Row, Col, Input, Modal, ModalBody,ModalHeader, UncontrolledTooltip} from 'reactstrap';
import {NavLink} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';
import FileSaver from 'file-saver';

//initialize the state
let initialState = {
  appraisalFiles:[],
  previousReport:[],
  selectedFile:{
    recipients:[]
  },
};

//reducer function that perform state update
const reducer = getReducer();


const AppraisalFile  = (props)=>{
  //console.log('loaded',props);
  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props);
  const httpPost = postAPICallGenerator(props);
  const httpPut = putAPICallGenerator(props);
  const httpDelete = deleteAPICallGenerator(props);

  //run only once when component is loaded
  useEffect(()=>{
    getAppraisalFiles(props.appraisalFk);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  //console.log(props);

  useEffect(()=>{
    //console.log(props.newAppraisalFiles);
    if(props.newAppraisalFiles && props.newAppraisalFiles.length !== 0){
      setState({appraisalFiles:props.newAppraisalFiles});
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.newAppraisalFiles]);


  //non API call but simpyl manage state
  const toggleEngagementLetter = ()=>{
    setState({engagementLetterPopUp:!state.engagementLetterPopUp});
  }

  //remove file from the list after successfull http delete call
  const removeFileFromList = (ID)=>{
    let newAppraisalFiles = state.appraisalFiles.slice();

    for(let i=0;i<newAppraisalFiles.length;i++){
      if(newAppraisalFiles[i].ID===ID)
        newAppraisalFiles.splice(i,1);
    }

    if(typeof props.removeFiles == 'function'){
      props.removeFiles(ID);
    }

    setState({appraisalFiles:newAppraisalFiles});
  }

  const toggleEntitiesToAdd = (ID)=>{
    let existingFiles = state.appraisalFiles.slice();

    for(let i=0;i<existingFiles.length;i++){
      if(existingFiles[i].ID===ID){
        setState({selectedFile:existingFiles[i]});
        break;
      }
    }

    setState({entitiesToAddPopUp:!state.entitiesToAddPopUp});
  }

  //API call
  const updateEngagementLetterSpecialInstructions = ()=>{
    let parameters = [
      {
        field:'ID',
        value:props.appraisalFk
      },
      {
        field:'field',
        value:'engagement_letter_special_instructions'
      },
      {
        field:'value',
        value:state.engagementLetterSpecialInstructions
      }
    ];

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;

      if(code==='00'){
        getAppraisalFiles(props.appraisalFk);
      }
    }
    
    httpPut('appraisal/update', parameters, 'Special instructions for engagement letter updated successfully.', 'Oops, something went wrong and could not update special instructions for engagement letter. Please try again later.', callBack);
  }

  //download the file when user click on the file name using blob
  const downloadFile = (name)=>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;

      if(code==='00'){
        let byteCharacters = atob(response.data.data);
        let byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);
        let data = new Blob([byteArray]);
        FileSaver.saveAs(data, name);
      }
    };

    let parameters = [
      {
        field:'parentFk',
        value:props.appraisalFk
      },
      {
        field:'name',
        value:name
      },
      {
        field:'directory',
        value:'Orders'
      }
    ];

    httpPost('file/download', parameters, '','Oops, something went wrong and could not download the file "'+name+'". Please try again later.', callBack);
  }

  //delete file
  const deleteFile = (ID)=>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        removeFileFromList(ID);
      }
    };
    httpDelete('file/'+ID, 'File successfully deleted','Oops, something went wrong and could not delete the file, please try again later.', callBack);
  }

  const getAppraisalFiles = (appraisalFk)=>{
    //Call Back
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        response.data.data.reverse();
        //console.log(response.data.data);
        //console.log(appraisalFk);
        setState({appraisalFiles:response.data.data, previousReport:response.data.data2});
      }
    };
    httpGet('file/get/'+appraisalFk, '','Oops, something went wrong and could not load appraisal files for this appraisal. Please try again later.', callBack);
  }

  const downloadEngagementLetter = ()=>{
    let parameters = [
      {
        field:'appraisalFk',
        value:props.appraisalFk
      },
    ];
    let callBack = (response)=>{
      let byteCharacters = atob(response.data.data);
      let byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      let data = new Blob([byteArray]);
      FileSaver.saveAs(data, 'engagement_letter.pdf');
    };
    httpPost('file/engagementLetter/download', parameters, '', 'Oops, something went wrong and could not download engagement letter. Please try again later.', callBack);
  }

  const downloadAIRCert = ()=>{
    let parameters = [
      {
        field:'appraisalFk',
        value:props.appraisalFk
      },
    ];
    let callBack = (response)=>{
      let byteCharacters = atob(response.data.data);
      let byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      let data = new Blob([byteArray]);
      FileSaver.saveAs(data, 'engagement_letter.pdf');
    };
    httpPost('file/airCert/download', parameters, '', 'Oops, something went wrong and could not download AIR Cert. Please try again later.', callBack);
  }

  const downloadAppraisalCover = ()=>{
    let parameters = [
      {
        field:'appraisalFk',
        value:props.appraisalFk
      },
    ];
    let callBack = (response)=>{
      let byteCharacters = atob(response.data.data);
      let byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      let data = new Blob([byteArray]);
      FileSaver.saveAs(data, 'appraisal_cover.pdf');
    };

    httpPost('file/appraisal/cover/download', parameters, '', 'Oops, something went wrong and could not download appraisal cover. Please try again later.', callBack);
  }

  const downloadAllInOneInvoice = ()=>{
    let parameters = [
      {
        field:'appraisalFk',
        value:props.appraisalFk
      },
    ];
    let callBack = (response)=>{
      let byteCharacters = atob(response.data.data);
      let byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      let data = new Blob([byteArray]);
      FileSaver.saveAs(data, 'invoice.pdf');
    };
    httpPost('file/invoice/allInOne/download', parameters, '', 'Oops, something went wrong and could not download invoice. Please try again later.', callBack);
  }

  const downloadEmailCert = ()=>{
    let parameters = [
      {
        field:'appraisalFk',
        value:props.appraisalFk
      },
    ];
    let callBack = (response)=>{
      let byteCharacters = atob(response.data.data);
      let byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      let data = new Blob([byteArray]);
      FileSaver.saveAs(data, 'email_cert.pdf');
    };

    httpPost('file/emailCert/download', parameters, '', 'Oops, something went wrong and could not download Email Cert. Please try again later.', callBack);
  }

  const restoreFile = (name)=>{
    let parameters = [
      {
        field:'parentFk',
        value:props.appraisalFk
      },
      {
        field:'name',
        value:name
      },
      {
        field:'directory',
        value:'Orders'
      }
    ];
    httpPost('file/glacier/restore', parameters, 'File restoration initiated. Please try to download the file again after 2-5 mins.', 'Oops, something went wrong and could not restore the file "'+name+'". Please try again later.');
  }

  const removeFromAddExistingRecipients = (ID) =>{
    let selectedFile = state.selectedFile;
    let list = sliceFromArray(state.appraisalFiles, ['ID','ID'],[state.selectedFile.ID, ID], ['recipients']);
    let list2 = sliceFromArray(state.selectedFile.recipients,'ID', ID);
    selectedFile.recipients = list2;
    let callBack = apiCallBack([{state:'appraisalFiles', value:list}, {state:'selectedFile', value:selectedFile}]);
    httpDelete('file/accessibility/'+ID,'File accessibility removed.', 'Oops, something went wrong and could not modify the file accessibility, please try again later.',callBack);
  }

  const addEntities = (ID)=>{
    let url = 'file/accessibility/add';
    let parameters = [
      {
        field:'fileFk',
        value:state.selectedFile.ID
      },
      {
        field:'entities',
        value:[ID]
      }
    ];

    let callBack = apiCallBack([{state:'selectedFile', key:'data'}]);
    httpPost(url, parameters, 'File accessibility modified successfully.', 'Oops, something went wrong and could not modify this file accessibility. Please try again later.', callBack);
  }

  //render related variables
  let appraisalFiles;
  let previousReport;
  let idCounter = 0;

  if(state.previousReport&&state.previousReport.length>0){
    previousReport = <div>
      {
        state.previousReport.map(
          (file, index)=>{
            return <div className="file-container" key={index}>
                <div className="font-bold">
                <div className="display-inline cursor-pointer" ></div><b>Previous Appraisal - {formatDate(file.datetime_created)}</b>
                <br/><div className="cursor-pointer display-inline link-color" onClick={()=>downloadFile(file.name, file.appraisal_fk)}><b>{file.name}</b></div>
                <br/><font size="1"><i className="fa fa-clock-o"></i>&nbsp;{file.datetime_created}</font>
              </div>
            </div>
          }
        )
      }
      <div className="my-divider"/>
    </div>
  }

  if(state.appraisalFiles.length>0){
    //console.log(state.appraisalFiles);
    appraisalFiles = state.appraisalFiles.map(
      (appraisalFile, outterIndex)=>{
        //console.log(appraisalFile);
        let recipients = appraisalFile.recipients.map(
          (entity,index)=>{
            idCounter++;
            if(entity.associated_name===""){
              return(
                <div key={index} className="display-inline file-entity">
                  <a href="#/" id={"fileAvatar"+idCounter}><Avatar style={{overflow:'hidden'}} size={15} textSizeRatio={1.5} name={"?"}/></a>&nbsp;
                  <UncontrolledTooltip placement="top" target={"fileAvatar"+idCounter}>
                    {entity.associated_email}
                  </UncontrolledTooltip>
                </div>
              );
            }
            else{
              return(
                <div key={index} className="display-inline file-recipient">
                  <a href="#/" id={"fileAvatar"+idCounter}><Avatar style={{overflow:'hidden'}} size={15} textSizeRatio={1.5} color={"#"+stringToHexCode(entity.associated_email)} name={entity.associated_name}/></a>&nbsp;
                  <UncontrolledTooltip placement="top" target={"fileAvatar"+idCounter}>
                    {entity.associated_name+" - "+entity.associated_email}
                  </UncontrolledTooltip>
                </div>
              );
            }
          }
        );

        return(
          <div key={outterIndex}>
            <div className="file-container">
								<div className="font-bold">
								<Row>
                  <Col sm="10">
                    <div className="display-inline cursor-pointer" ><i className="fa fa-trash red-color" onClick={
                      ()=>{
                        confirmation(
                          ()=>{deleteFile(appraisalFile.ID)},
                          ()=>{},
                          'Delete file?',
                          'Are you sure you want to delete this file?');
                      }
                    }></i></div><b>&nbsp;{appraisalFile.file_type} <i>{appraisalFile.description}</i></b>
                  </Col>
                  <Col sm="2">
                    <div className="align-right">
                      <i className="fa fa-refresh green-color cursor-pointer" onClick={
                        ()=>{
                          confirmation(
                            ()=>{restoreFile(appraisalFile.name)},
                            ()=>{},
                            'Restore file?',
                            'Are you sure you want to initiate the restoration of this file?');
                        }
                      }></i>
                    </div>
                  </Col>
                </Row>
                <div className="cursor-pointer display-inline link-color" onClick={()=>downloadFile(appraisalFile.name)}><b>{appraisalFile.name}</b></div>
							</div>
							<font size="1"><div className="no-margin no-padding font-bold margin-top-extra"><i className="fa fa-edit link-color cursor-pointer" onClick={()=>toggleEntitiesToAdd(appraisalFile.ID)}></i> <i className="fa fa-clock-o"></i>&nbsp;{appraisalFile.datetime_created} - {appraisalFile.uploader_name} </div></font>
							{recipients}
						</div>
          </div>
        );
      }
    );
  }

  let entities;
  if(props.appraisal.entities){
    entities = props.appraisal.entities.map(
      (entity, index)=>{
        if(entity.entity_label==='Broker'||entity.entity_label==='Broker Company')
          return null;
        return (
          <tr key={index} className="cursor-pointer" onClick={(e)=>addEntities(entity.ID)}><td><b>{entity.entity_label}</b></td><td>{entity.entity_name} - {entity.entity_email}</td></tr>
        );
      }
    );
  }

  //render
  return <div>
    <Modal className="my-modal" isOpen={state.entitiesToAddPopUp} toggle={toggleEntitiesToAdd} >
      <ModalHeader hidden={true} toggle={toggleEntitiesToAdd}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-files-o"></i> File Accessibility</h5>
        </center>
        <label>File</label><br/>
        <i>{state.selectedFile.name}</i>

        <br/><br/>

        <label>Existing recipients</label><br/>
        {
          state.selectedFile.recipients.map(
            (entity, index)=>{
              return (
                <div className="entity-container cursor-pointer" key={index} onClick={()=>{removeFromAddExistingRecipients(entity.ID)}}>
                  <i className="fa fa-minus link-color"></i>&nbsp;{entity.associated_name+' - '+entity.associated_email}&nbsp;&nbsp;
                </div>
              );
            }
          )
        }

        <br/><br/>

        <label>Add new recipients</label><br/>

        <Table hover className="table">
          <tbody>
            {
              entities
            }
          </tbody>
        </Table>

        <div className="my-diviver"></div>
        <center>
          <Button color="info" onClick={toggleEntitiesToAdd}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
    <Modal className="my-modal" isOpen={state.engagementLetterPopUp} toggle={toggleEngagementLetter} >
      <ModalHeader hidden={true} toggle={toggleEngagementLetter}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-files-o"></i> Engagement Letter</h5>
        </center>
        <br/><br/>
        <Row>
          <Col sm="6" style={{height:'80px',borderRight:'1px solid #d2d2d2'}}>
            <br/>
            <center>
              <NavLink to="#" onClick={downloadEngagementLetter}>Download Engagement Letter</NavLink>
            </center>
          </Col>
          <Col sm="6" style={{height:'80px'}}>
            <label>Special Instructions in Engagement Letter</label>
            <Row>
              <Col sm="8">
                <Input type="text" value={state.engagementLetterSpecialInstructions} onChange={(e)=>setState({engagementLetterSpecialInstructions:e.target.value})}/>
              </Col>
              <Col sm="4" className="align-right">
                <Button color="warning" onClick={(e)=>updateEngagementLetterSpecialInstructions()}>Update</Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <br/>
        <div className="my-diviver"></div>
        <center>
          <Button color="info" onClick={toggleEngagementLetter}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
    <div className="file-panel" style={{minHeight:props.height?props.height:'400px',maxHeight:props.height?props.height:'400px'}}>
      <div>
        <div className="file-container">
            <div className="font-bold">
            <div className="display-inline cursor-pointer" ></div><b>Invoice - Home VMS</b>
            <br/><div className="cursor-pointer display-inline link-color" onClick={downloadAllInOneInvoice}><b>Invoice - Latest</b></div>
          </div>
        </div>
      </div>
      {previousReport}
      {appraisalFiles}
    </div>
    <div className="my-diviver"></div>
    <NavLink to="#" onClick={downloadEmailCert}>Email Cert</NavLink>&nbsp;&nbsp;<NavLink to="#" onClick={downloadAIRCert}>AIR Cert</NavLink>&nbsp;&nbsp;<NavLink to="#" onClick={toggleEngagementLetter}>Engagement Letter</NavLink>&nbsp;&nbsp;<NavLink to="#" onClick={downloadAllInOneInvoice}>Invoice</NavLink>&nbsp;&nbsp;<NavLink to="#" onClick={downloadAppraisalCover}>Appraisal Cover</NavLink>
  </div>
}

export default AppraisalFile;
