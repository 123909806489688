//Author June Leow
//Date Nov 15th, 2023
import React from 'react';

const MyTimlineElement  = (props)=>{
  //render
  let container;

    if(props.position==='left'){
      container = <div className="content-left-container" style={props.style}>
        <div className="content-left">
          {props.children}
        </div>
      </div>;
    }
    else{
      container = <div className="content-right-container" style={props.style}>
        <div className="content-right">
          {props.children}
        </div>
      </div>;
    }
    console.log(props.color)

    return <div className="timeline-article">
      {container}
      <div className="meta-date" style={{background:props.color}}>
        <span class="date">{props.date}</span>
          <span class="month">{props.month}</span>
       </div>
    </div>
}


export default MyTimlineElement;
