//Author Sooyoung Kim
//Date July 10, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator} from '../../../util/util';
import {Col, Row} from 'reactstrap';
import {useParams} from 'react-router-dom';
import MySelect from '../../util/my-select';
import React, {useReducer, useEffect} from 'react';
import ClientTurntimeReport from './client-turntime-report';
import PipelineReport from './pipeline-report';

//initialize the state
const initialState = {
  selectedReport:-1,
  reports:[],
};

//reducer function that perform state update
const reducer = getReducer();


const ClientReport  = (props)=>{
  const controller = new AbortController();
  let {params} = useParams();

  let newInitialState = Object.assign({}, initialState, {
    params:params,
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getReports();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //API call
  const getReports = () => {
    let callBack = apiCallBack([{state:'reports', key:'data'}]);
	  httpGet('report/client/get', '', 'Oops, something went wrong and could not load a list of available reports. Please try again later.', callBack);
  }

  //render
  let report;

  switch(state.selectedReport){
    case '1':
      report = <ClientTurntimeReport params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>;
      break;
    case '2':
      report = <PipelineReport params={state.params} logout={props.logout} showLoading={props.dispatchShowLoadingFunc} hideLoading={props.dispatchHideLoadingFunc}/>;
      break;
    default:
      report = '';
      break;
  }

  return <div className="my-well">
    <Row>
      <Col sm="6">
        <div className="page-title">
          <i className="fa fa-reorder"></i>&nbsp;Report
        </div>
      </Col>
      <Col sm="6">

      </Col>
    </Row>
    <div className="my-divider"></div>
    <Row>
      <Col sm="8">
        <label>Report</label>&nbsp;
        <MySelect
          type="select"
          selectIsClearable={true}
          value={state.selectedReport}
          onChange={(v)=>{setState({selectedReport:v})}}
          options={state.reports.map((report)=>{
            return {label:report.report, value:report.ID};
          })}
        />
      </Col>
    </Row>
    <br/>
    <div className="my-divider"></div>
    {report}
  </div>;
}


export default ClientReport;
