//Author Sooyoung Kim
//Date July 25, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, deleteAPICallGenerator, callBackGenerator, formatDateTime,stringToHexCode,confirmation, sliceFromArray} from '../../util/util';
import {Button,Modal, ModalHeader, ModalBody, Row, Col, UncontrolledTooltip, Table} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import MySelect from './../util/my-select';
import Linkify from 'react-linkify';
import Avatar from 'react-avatar';
import './comment.css';

//initialize the state
const initialState = {
  comments: [],
  newComment:'',
  targetRecipients:[],
  modal: false,
  selectedRecipient:-1,
  commentTemplates:[],
  copyBilling:false,
  copyAssignment:false,
  extraRecipients:'',
  extraOpsRecipients:[],
  entitiesToAdd:[],
  entitiesToAddPopUp:false,
  selectedComment:{
    recipients:[]
  },
  systemOnlyComments:[],
  clientOnlyComments:[],
  allComments:[],
  filter:'all',
  likes:[],

  emailThreads:[],
  emailCollapse:[],
  emailThreadCollapse:[]
};

//reducer function that perform state update
const reducer = getReducer();


const Comment  = (props)=>{
  const controller = new AbortController();

  let newInitialState = Object.assign({}, initialState, {
    appraisalFk:props.appraisalFk,
    appraiserFk:props.appraiserFk,
    appraiser:props.appraiser,
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getAppraisalComments(props.appraisalFk);
    getCommentTemplates();
    getLikes(props.appraisalFk);

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if (state.appraisalFk !== props.appraisalFk) {
      setState({appraisalFk:props.appraisalFk});
      getAppraisalComments(props.appraisalFk);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.appraisalFk, props.appraisalFk]);

  useEffect(() => {
    if (state.appraiser !== props.appraiser || state.appraiserFk !== props.appraiserFk) {
      setState({appraiser:props.appraiser, appraiserFk:props.appraiserFk});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.appraiser, state.appraiserFk, props.appraiser, props.appraiserFk]);

  //non API call but simpyl manage state
  const filterOnChange = (v) => {
    setState({filter:v})

    if(v==='all')
      setState({comments:state.allComments});
    else if(v==='client')
      setState({comments:state.clientOnlyComments});
    else if(v==='system')
      setState({comments:state.systemOnlyComments});
  }

  const toggleEntitiesToAdd = (ID) => {
    let existingComments = state.comments.slice();

    for(let i=0;i<existingComments.length;i++){
      if(existingComments[i].ID===ID){
        setState({selectedComment:existingComments[i]});
        break;
      }
    }

    setState({entitiesToAddPopUp:!state.entitiesToAddPopUp});
  }

  //API call
  const addEntities = (ID) => {
    let parameters = [
      {
        field:'commentFk',
        value:state.selectedComment.ID
      },
      {
        field:'entities',
        value:[ID]
      }
    ];

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        //insert new comment into state
        let commentModified = response.data.data;
        let existingComments = state.comments.slice();

        for(let i=0;i<existingComments.length;i++){
          if(existingComments[i].ID===state.selectedComment.ID){
            existingComments.splice(i, 1, commentModified);
            setState({selectedComment:commentModified});
          }
        }

        setState({comments:existingComments});
      }
    };
    callBack = callBack.bind(this);

    httpPost('comment/accessibility/add', parameters, 'Comment accessibility modified successfully.', 'Oops, something went wrong and could not modify this comment accessibility. Please try again later.', callBack);
  }

  //get all the comments
  const getAppraisalComments = (appraisalFk) => {
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        response.data.data.reverse();

        let systemOnlyComments = [];
        let clientOnlyComments = [];

        for(let i=0;i<response.data.data.length;i++){
          if(response.data.data[i].author==='System')
            systemOnlyComments.push(response.data.data[i]);
          else
            clientOnlyComments.push(response.data.data[i]);
        }
        setState({comments:response.data.data, allComments:response.data.data, systemOnlyComments:systemOnlyComments, clientOnlyComments:clientOnlyComments });
      }
    };
    callBack = callBack.bind(this);

    httpGet('comment/get/'+appraisalFk, '', 'Oops, something went wrong and could not load comments for this appraisal. Please try again later.', callBack);
  }

  //get all the comment templates
  const getCommentTemplates = () => {
    let callBack = apiCallBack([{state:'commentTemplates', key:'data'}]);
	  httpGet('template/get/'+props.appraisalFk, '', 'Oops, something went wrong and could not load your comment template.', callBack);
  }

  const getLikes = (ID) => {
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let likes = [];
        for(let i=0;i<response.data.data.length;i++)
          likes.push(response.data.data[i].appraiser_fk);

        setState({likes:likes});
      }
    };
    callBack = callBack.bind(this);

    httpGet('appraiser/like/internal/'+ID, '', 'Oops, something went wrong and could not load the like of this order. Please try again later.', callBack);
  }

  const removeFromAddExistingRecipients = (ID) => {
    let list = sliceFromArray(state.comments, ['ID','ID'],[state.selectedComment.ID, ID], ['recipients']);
    let callBack = apiCallBack([{state:'comments', value:list}]);

    httpDelete('comment/accessibility/'+ID,'Oops, something went wrong and could not modify the comment accessibility, please try again later.','Comment accessibility removed.', callBack);
  }

  const deleteComment = (id) => {
    let parameters = [{field:'id',value:id}];
    let list = sliceFromArray(state.comments,'ID', id);
    httpPut('comment/remove', parameters, 'Comment removed successfully.', 'Oops, something went wrong and could not remove the comment. Please try again later.', apiCallBack([{state:'comments', value:list}]));
  }

  //render
  let entities2;
  if(props.entities){

    entities2 = props.entities.map(
      (entity, index)=>{
        if(entity.entity_label==='Broker'||entity.entity_label==='Broker Company')
          return null;
        return (
          <tr key={index} className="cursor-pointer" onClick={(e)=>addEntities(entity.ID)}><td><b>{entity.entity_label}</b></td><td>{entity.entity_name} - {entity.entity_email}</td></tr>
        );
      }
    );
  }


  let idCounter = 0;
  let comments = state.comments.map(
    (comment,outterIndex)=>{

      let smsIcon;
      if(comment.sms_to_appraiser==='yes')
        smsIcon = <i className="fa fa-envelope-open"/>;
      let recipients = comment.recipients.map(
        (entity,index)=>{
          idCounter++;
          if(entity.associated_name===""){
            return(
              <div key={index} className="display-inline file-entity">
                <a href="#/" id={"commentAvatar"+idCounter}><Avatar style={{overflow:'hidden'}} size={15} textSizeRatio={1.5} name={"?"}/></a>&nbsp;
                <UncontrolledTooltip placement="top" target={"commentAvatar"+idCounter}>
                  {entity.associated_email}
                </UncontrolledTooltip>
              </div>
            );
          }
          else{
            return(
              <div key={index} className="display-inline file-recipient">
                <a href="#/" id={"commentAvatar"+idCounter}><Avatar style={{overflow:'hidden'}} size={15} textSizeRatio={1.5} color={"#"+stringToHexCode(entity.associated_email)} name={entity.associated_name}/></a>&nbsp;
                <UncontrolledTooltip placement="top" target={"commentAvatar"+idCounter}>
                  {entity.associated_name+" - "+entity.associated_email}
                </UncontrolledTooltip>
              </div>
            );
          }
        }
      );

      let outterContainerClass = 'align-right';
      let commentContainerClass = 'nonadmin-comment-container';
      let nameClass2 = '';
      let nameClass = 'nonadmin-name';
      let alignClass = 'align-left';
      let alignClass2 = 'align-right';
      let dateClass = 'margin-top comment-date display-inline';
      let nameAddon = '';

      if(comment.type==='comment - email scanned'){
        nameClass2 = 'flashit';
        nameAddon = 'Email Scanned - ';
      }

      if(comment.type==='system - updates'){
        commentContainerClass = 'system-updates-comment-container';
        outterContainerClass = 'comment-system-updates';
        nameClass = 'align-left system-update-name';
        dateClass = 'comment-date-small display-inline';
      }
      else if(comment.internal==='yes'){
        outterContainerClass = '';
        commentContainerClass = 'admin-comment-container';
        nameClass = 'admin-name';
      }

      let deleteButton;
      if(comment.recipients.length <= 0 && comment.internal==='yes' && comment.type==='comment - internal'){
        deleteButton = <div className="display-inline">
          <i className="fa fa-times red-color" style={{fontSize:'15px'}} onClick={()=>{
            confirmation(
              ()=>{deleteComment(comment.ID)},
              ()=>{},
              'Delete this comment?',
              'Are you sure you want to delete this comment?');
          }}></i>
        </div>;
      }


      return(
        <Row className={outterContainerClass} key={comment.ID}>
          <Col sm="12">
            <div className={commentContainerClass}>
              <Row>
                <Col sm="10" className={alignClass}>
                  <div className={nameClass+" "+nameClass2}>{nameAddon+comment.author}</div>
                </Col>
                <Col sm="2" className={alignClass2}>
                  {deleteButton}
                </Col>
              </Row>
              <div className={alignClass}>
                <Linkify>{comment.comment}</Linkify>
              </div>
              <Row>
                <Col sm="6" className={alignClass}>
                  <div className={dateClass}><i className="fa fa-edit link-color cursor-pointer" onClick={()=>toggleEntitiesToAdd(comment.ID)}></i> {formatDateTime(comment.datetime_created)}&nbsp;&nbsp;{smsIcon}</div>
                </Col>
                <Col sm="6" className={alignClass2}>
                  <div className="display-inline">
                    {recipients}
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      );
    }
  );

  return <div>
    <div className="comments-panel">
    {comments}
    </div>
    <div className="my-diviver"></div>
    <Row>
      <Col sm="4">
        <Modal className="my-modal" isOpen={state.entitiesToAddPopUp} toggle={toggleEntitiesToAdd} >
          <ModalHeader hidden={true} toggle={toggleEntitiesToAdd}></ModalHeader>
          <ModalBody>
            <center>
              <h5><i className="fa fa-comments"></i> Comment Accessibility</h5>
            </center>
            <label>Comment</label><br/>
            <i>{state.selectedComment.comment}</i>

            <br/><br/>

            <label>Existing recipients</label><br/>
            {
              state.selectedComment.recipients.map(
                (entity, index)=>{
                  return (
                    <div className="entity-container cursor-pointer" key={index} onClick={()=>{removeFromAddExistingRecipients(entity.ID)}}>
                      <i className="fa fa-minus link-color"></i>&nbsp;{entity.associated_name+' - '+entity.associated_email}&nbsp;&nbsp;
                    </div>
                  );
                }
              )
            }

            <br/><br/>

            <label>Add new recipients</label><br/>

            <Table className="table table-striped">
              <tbody>
                {
                  entities2
                }
              </tbody>
            </Table>

            <div className="my-diviver"></div>
            <center>
              <Button color="info" onClick={toggleEntitiesToAdd}>Close</Button>
            </center>
          </ModalBody>
        </Modal>
      </Col>
      <Col sm="8" className="align-right">
        <div className="display-inline" style={{verticalAlign:'top'}}>
          <b>Filter</b>&nbsp;&nbsp;
        </div>
        <div className="display-inline" style={{width:"200px"}}>
          <MySelect
            type="select"
            value={state.filter}
            onChange={(v)=>filterOnChange(v)}
            options={[{label:"All",value:"all"},{label:"Non System Related",value:"client"},{label:"System Only",value:"system"}]}
          />
        </div>
      </Col>
    </Row>
  </div>;
}


export default Comment;
