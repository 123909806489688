//Author Sooyoung Kim
//Date July 11, 2023
import HighchartsReact from "highcharts-react-official";
import Highcharts from 'highcharts'
import HCMore from "highcharts/highcharts-more";
import React, {useEffect} from 'react';
import './my-gauge.css';

HCMore(Highcharts);


const MyGauge  = (props)=>{
  const controller = new AbortController();

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  
  //render
  return <div>
    <HighchartsReact
      highcharts={Highcharts}
      options={props.options}
    />
  </div>;
}


export default MyGauge;
