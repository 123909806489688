//Author June Leow
//Date 04/04/2023
import {getReducer, getSetStateFunction, getAPICallGenerator, formatDateTime, formatNumber} from '../../util/util';
import {Button, Row, Col} from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import MySelect from '../util/my-select';
import {NavLink} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';


//initialize the state
const initialState = {
  tickets:[],
  hasMoreTickets:true,
  limit: 50,
  offset: 0,
  loading: false,
  sort: 'datetime_created',
  order: 'ASC',
  status:'Pending',
  criticalLevel:'',
  category:'',
  totalCount:0,
  categories:[],
};

//reducer function that perform state update
const reducer = getReducer();


const AccountingTickets  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    if(state.tickets.length<=0 && state.hasMoreTickets){
        loadMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state]);

  useEffect(()=>{

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  //this function refresh the list of orders pipeline
  const refreshList = ()=>{
    setState({tickets:[],hasMoreTickets:true,offset:0});
  };


  //click handler that switch the state of sorting order then call the function to refresh the list
  //to achieve the "sorting"
  const columnClickHandler = (col)=>{
    if(state.sort===col){
      if(state.order==='ASC')
        setState({order:'DESC'});
      else
        setState({order:'ASC'});
    }
    else
      setState({order:'ASC', sort:col});
    refreshList();
  };

  //this function render the sort icon at each column of the table
  const renderSortIcon = (col)=>{
    if(state.sort===col){
      if(state.order==='ASC')
        return <i className="red-color fa fa-arrow-down"></i>
      else
        return <i className="red-color fa fa-arrow-up"></i>
    }
  };

  //render function for infinite scroller
  const renderTickets = ()=>{
    return (
      <div>
        <table className="primary-table">
          <thead>
            <tr>
              <th className="cursor-pointer" width="20%" onClick={()=>columnClickHandler('issue')}><i className="fa fa-edit"></i>&nbsp;Issue {renderSortIcon('issue')}</th>
              <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('critical_level')}><i className="fa fa-warning"></i>&nbsp;Critical Level {renderSortIcon('critical_level')}</th>
              <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('category')}><i className="fa fa-clone"></i>&nbsp;Category {renderSortIcon('category')}</th>
              <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('status')}><i className="fa fa-info"></i>&nbsp;Status {renderSortIcon('status')}</th>
              <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('author')}><i className="fa fa-user"></i>&nbsp;Author {renderSortIcon('author')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('datetime_created')}><i className="fa fa-calendar-o"></i>&nbsp;Date Created {renderSortIcon('datetime_created')}</th>
            </tr>
          </thead>
          <tbody>
          {
            state.tickets.map(
              (ticket)=>{
                return (
                  <tr key={ticket.ID}>
                    <td className="dont-break-out"><NavLink target="_blank" to={"/support/accounting/ticket/"+ticket.ID}>{ticket.issue}</NavLink></td>
                    <td>{ticket.critical_level}</td>
                    <td>{ticket.category}</td>
                    <td>{ticket.status}</td>
                    <td>{ticket.author}</td>
                    <td>{formatDateTime(ticket.datetime_created)}</td>
                  </tr>
                );
              }
            )
          }
          </tbody>
        </table>
      </div>
    )
  }

  const loadMore = ()=>{
    console.log(state);
    if(state.hasMoreTickets&&!state.loading){
      setState({loading:true});
      let category = encodeURIComponent(state.category.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
      let url = 'ticket/accounting/get/limit='+state.limit+'&offset='+state.offset+'&status='+state.status+'&category='+category.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand')+'&criticalLevel='+state.criticalLevel+'&sort='+state.sort+'&order='+state.order;

      //generate a generic call back that will update state
      let callBack = (response)=>{
        console.log(response);
        let code = response.data?response.data.code:undefined;

        if(code!=='00'){
          setState({hasMoreTickets:false});
        }
        else{
          let newTickets = response.data.data;
          let hasMoreTickets = true;
          let newOffset = state.offset;
          let totalCount = response.data.count;

          //if http request return empty then no more results, end of list

          if(newTickets.length<=0){
            hasMoreTickets = false;
          }
          else{
            //increment the offset
            newOffset = state.offset + 1;
          }

          //concat the current array of announcement
          if(state.tickets.length>0){
            let tmp = [...state.tickets,...newTickets];

            setState({tickets:tmp});
          }
          else
            setState({tickets:newTickets});
          setState({hasMoreTickets:hasMoreTickets,offset:newOffset,totalCount:totalCount});
        }
      };
      callBack = callBack.bind(this);
      let promise = httpGet(url, '','Oops, something went wrong and could not load support tickets. Please try again later.', callBack);
      promise.then(
        function(result){
          //set loading equals to false so the function could be fire off once again
          setState({loading:false});
        }
      );
    }

  };

  console.log(state);

  //render
  return <div>
    <InfiniteScroll
      className="my-well"
      dataLength={state.tickets.length} //This is important field to render the next data
      next={loadMore}
      hasMore={state.hasMoreTickets}
      loader={<center>Loading...</center>}
      scrollableTarget="contentContainer"
    >
    <div className="page-title">
        <i className="fa fa-reorder"></i>&nbsp;Accounting Helpdesk Ticket Queue
      </div>
      <Row>
        <Col sm="3">
          <label className="no-margin-bottom">Status</label>
          <MySelect
            type="select"
            value={state.status}
            onChange={(v)=>{setState({status:v})}}
            options={[{label:"All",value:""},{label:"Pending",value:"Pending"},{label:"Resolved",value:"Resolved"},{label:"Closed",value:"Closed"}]}
          />
        </Col>
        <Col sm="3">
          <label className="no-margin-bottom">Critical Level</label>
          <MySelect
            type="select"
            value={state.criticalLevel}
            onChange={(v)=>{setState({criticalLevel:v})}}
            options={[{label:"All",value:""},{label:"Minor",value:"Minor"},{label:"Major",value:"Major"},{label:"Critical",value:"Critical"}]}
          />
        </Col>
        <Col sm="3">
          <label className="no-margin-bottom">Category</label>
          <MySelect
            type="select"
            selectIsClearable={true}
            value={state.category}
            onChange={(v)=>{setState({category:v})}}
            options={state.categories.map((category)=>{
              return {label:category.category, value:category.category};
            })}
          />
        </Col>
        <Col sm="3" className="align-right valign-bottom">
          <br/>
          <Button color="warning" onClick={()=>refreshList()} >Submit</Button>
        </Col>
      </Row>
      <div className="my-divider"></div>
      <Row>
        <Col sm="6">
            <div>{formatNumber(state.totalCount)} tickets in queue<br/><br/></div>
        </Col>
        <Col sm="6" className="align-right">
        </Col>
      </Row>

      {renderTickets()}
    </InfiniteScroll>
  </div>;
}

export default AccountingTickets;
