//Author Sooyoung Kim
//Date April 28, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, formatNumber, formatDateTime} from '../../util/util';
import React, {useReducer, useEffect} from 'react';
import {Row, Col, Input, Button} from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import MySelect from '../util/my-select';
import {NavLink, useNavigate} from 'react-router-dom';


//initialize the state
const initialState = {
  amcs:[],
  limit: 25,
  offset: 0,
  hasMoreAmcs: true,
  loading: false,
  sort: 'concat(first_name," ",last_name)',
  order: 'ASC',
  statuses: [],
  name:'',
  status:'',
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
  state:'',
  county:'',
  city:'',
  totalCount:0
};

//reducer function that perform state update
const reducer = getReducer();


const AMCS  = (props)=>{
  const controller = new AbortController();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getAllAppraiserStatuses();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    if(state.amcs.length<=0 && state.hasMoreAmcs){
        loadMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state]);

  //non API call but simpyl manage state
  //this function render the sort icon at each column of the table
  const renderSortIcon = (col)=>{
    if(state.sort===col){
      if(state.order==='ASC')
        return <i className="red-color fa fa-arrow-down"></i>
      else
        return <i className="red-color fa fa-arrow-up"></i>
    }
  }

  //click handler that switch the state of sorting order then call the function to refresh the list
  //to achieve the "sorting"
  const columnClickHandler = (col)=>{
    if(state.sort===col){
      if(state.order==='ASC')
        setState({order:'DESC'});
      else
        setState({order:'ASC'});
    }
    else
      setState({sort:col,order:'ASC'});
    refreshList();
  }

  //this function refresh the list of orders pipeline
  const refreshList = ()=>{
    setState({
      amcs:[],
      totalCount:0,
      hasMoreAmcs:true,
      offset:0,
    });
  }


  const renderAmcs = ()=>{
    return (
      <div>
        <table className="primary-table">
          <thead>
            <tr>
              <th className="cursor-pointer" width="30%" onClick={()=>columnClickHandler('concat(first_name," ",last_name)')}><i className="fa fa-search"></i>&nbsp;Name {renderSortIcon('concat(first_name," ",last_name)')}</th>
              <th className="cursor-pointer" width="10%" onClick={()=>columnClickHandler('status')}><i className="fa fa-folder"></i>&nbsp;Status {renderSortIcon('status')}</th>
              <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('state')}><i className="fa fa-folder"></i>&nbsp;State {renderSortIcon('state')}</th>
              <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('county')}><i className="fa fa-folder"></i>&nbsp;County {renderSortIcon('county')}</th>
              <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('city')}><i className="fa fa-folder"></i>&nbsp;City {renderSortIcon('city')}</th>
              <th className="cursor-pointer" width="15%" onClick={()=>columnClickHandler('datetime_created')}><i className="fa fa-calendar-o"></i>&nbsp;Date Created {renderSortIcon('datetime_created')}</th>
            </tr>
          </thead>
          <tbody>
          {
            state.amcs.map(
              (amc)=>{
                return (
                  <tr key={amc.ID} onClick={()=>navigate("/amc/"+amc.ID)}>
                    <td><NavLink className="link-color" to={"/"+amc.ID}><b>{amc.first_name+' '+amc.last_name}</b></NavLink></td>
                    <td>{amc.status}</td>
                    <td>{amc.state}</td>
                    <td>{amc.county}</td>
                    <td>{amc.city}</td>
                    <td>{formatDateTime(amc.datetime_created)}</td>
                  </tr>
                );
              }
            )
          }
          </tbody>
        </table>
      </div>
    );
  }

  //API call
  //function that fire when the infinite scroll reach bottom
  const loadMore = ()=>{
    //do not load if there is no more appraisals or it's loading data
    if(state.hasMoreAmcs&&!state.loading){
      //set loading equals to true so it won't fire off before we are done
      setState({loading:true});

      let url = 'amc/get/limit='+state.limit+'&offset='+state.offset+'&order='+state.order+'&sort='+state.sort+'&status='+state.status+'&name='+state.name+'&state='+state.state+'&county='+state.county+'&city='+state.city;

      //callback handler that update the state when http request return
      let callBack = (response)=>{
        console.log(response);
        let code = response.data?response.data.code:undefined;

        if(code!=='00'){
          setState({hasMoreAmcs:false});
        }
        else{
          let newAmcs = response.data.data;
          let hasMoreAmcs = true;
          let newOffset = state.offset;
          let totalCount = response.data.count;

          //if http request return empty then no more results, end of list
          if(newAmcs.length<=0){
            hasMoreAmcs = false;
          }
          else{
            //increment the offset
            newOffset = state.offset + 1;
          }

          //concat the current array of announcement
          if(state.amcs.length>0){
            let temp = [...state.amcs,...newAmcs];

            setState({amcs:temp});
          }
          else
            setState({amcs:newAmcs});

          setState({hasMoreAmcs:hasMoreAmcs, offset:newOffset, totalCount:totalCount});
        }
      };
      callBack = callBack.bind(this);

      //error handler when the http request return with error
      let errorCallBack = apiCallBack([{state:'hasMoreAmcs', value:false}]);

      //collect the promise and wait for it to finish performing it's task
      let promises = httpGet(url, '','Oops, something went wrong and could not load amc. Please try again later.', callBack, errorCallBack);
      promises
        .then(
          function(result){
            //set loading equals to false so the function could be fire off once again
            setState({loading:false});
          }
        );
    }
  }

  //get appraiser statuses
  const getAllAppraiserStatuses = ()=>{
    let callBack = apiCallBack([{state:'statuses', key:'data'}]);
    httpGet('appraiser/statuses/get', '', 'Oops, something went wrong and could not load information of AMC. Please try again later.', callBack);
  }

  //render
  let states;

  if(state.states.length>0){
    states = state.states.map(
      (state,index)=>{
        return(
          <option key={index} value={state.value}>{state.value}</option>
        );
      }
    );
  }
  else
    states = null;

  return <div className="my-well">
    <Row>
      <Col sm="6">
        <div className="page-title">
          <i className="fa fa-reorder"></i>&nbsp;AMC
        </div>
      </Col>
      <Col sm="6">

      </Col>
    </Row>
    <InfiniteScroll
      next={loadMore}
      dataLength={state.amcs.length}
      hasMore={state.hasMoreAmcs}
      loader={<div key="nill" className="loader"><center>Loading more amcs...</center></div>}
      initialLoad = {true}
      className="my-well"
      scrollableTarget="contentContainer"
    >
      <Row>
        <Col sm="2">
          <label className="no-margin-bottom">Name</label>
          <Input type="text" value={state.name} onChange={(e)=>setState({name:e.target.value})}/>
        </Col>
        <Col sm="2">
          <label className="no-margin-bottom">Status</label>
          <MySelect
            type="select"
            selectIsClearable={true}
            value={state.status}
            onChange={(v)=>setState({status:v})}
            options={state.statuses.map((status)=>{
              return {label:status.status, value:status.status};
            })}
            />
        </Col>
        <Col sm="2">
          <label className="no-margin-bottom">State</label>
          <Input type="select" value={state.state} onChange={(e)=>setState({state:e.target.value})}>
            <option value="">All</option>
            {states}
          </Input>
        </Col>
        <Col sm="2">
          <label className="no-margin-bottom">County</label>
          <Input type="text" value={state.county} onChange={(e)=>setState({county:e.target.value})}/>
        </Col>
        <Col sm="2">
          <label className="no-margin-bottom">City</label>
          <Input type="text" value={state.city} onChange={(e)=>setState({city:e.target.value})}/>
        </Col>
        <Col sm="2" className="align-right valign-bottom">
          <br/>
          <Button color="warning" onClick={()=>refreshList()} >Submit</Button>
        </Col>
      </Row>
      <br/>

      <div className="my-divider"></div>
      <Row className="low-line-height">
        <Col sm="6">
            <div>{formatNumber(state.totalCount)} amcs in queue<br/><br/></div>
        </Col>
        <Col sm="6">
          <div className="align-right"><NavLink to={'/amc/new'}><i className="fa fa-plus"></i>&nbsp;Create new AMC</NavLink><br/></div>
        </Col>
      </Row>
      {renderAmcs()}
    </InfiniteScroll>
  </div>;
}

export default AMCS;
