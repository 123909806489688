//Author Sooyoung Kim
//Date May 1, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, deleteAPICallGenerator, callBackGenerator, formatDate, confirmation} from '../../util/util';
import {Button, Col, Row, Table, Input, Modal, ModalBody,ModalHeader, Card, CardHeader, CardBody} from 'reactstrap';
import {NavLink} from 'react-router-dom';
import DatePicker from 'react-datepicker';
import MySelect from '../util/my-select';
import {sliceFromArray} from '../../util/util';
import moment from 'moment';
import React, {useReducer, useEffect} from 'react';
import Toggle from 'react-toggle';
import './setting.css';

let phone = localStorage.getItem('phone');

if(!phone)
  phone = '';

//initialize the state
const initialState = {
  templateTags:[],
  templates:[],
  channels:[],
  newAppraiserCommentNotification:{
    status:'no'
  },

  newTemplateName:'',
  newTemplateText:'',

  notificationChannels:[],
  accountManagers:[],
  addNewTemplateDropDownOpen:false,

  accountManagerPipelineSharing:[],
  emailSettings:[],
  clientEmailSettings:[],
  clients:[],
  clientFk:'',
  clientEmailDropDownOpen:false,

  from:'',
  to:'',
  currentVacationDates:{},

  oldPassword:'',
  newPassword:'',
  confirmNewPassword:'',
  changePasswordMessage:'',
  internalResetEmail:'',
  globalPipeline:localStorage.getItem('pipelinePreference')==='global',
  globalFeeds:localStorage.getItem('feedsPreference')==='global',
  phone:phone,
  userID:localStorage.getItem('userID')
};

//reducer function that perform state update
const reducer = getReducer();


const PersonalSetting  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getChannels();
    getEmailNotificationSettings();
    getTemplates();
    getTemplateTags();
    getAccountManagerPipelineSharing();
    getAllClientProfiles();
    getNewAppraiserCommentNotificationStatus();
    getCurrentVacationDates();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const clientEmailTargetChange = (clientFk)=>{
    setState({clientEmailSettings:[]});
    setState({clientFk:clientFk});

    if(clientFk!==''){
      getClientEmailNotificationSettings(clientFk);
    }
  }

  const emailNotificationToggle = (subject)=>{
    for(let i=0;i<state.emailSettings.length;i++){
      if(state.emailSettings[i].subject===subject){
        if(state.emailSettings[i].blocked==='yes')
          unblockEmail(subject);
        else
          blockEmail(subject);
      }
    }
  }

  const clientEmailNotificationToggle = (subject)=>{
    for(let i=0;i<state.clientEmailSettings.length;i++){
      if(state.clientEmailSettings[i].subject===subject){
        if(state.clientEmailSettings[i].blocked==='yes')
          unblockClientEmail(subject);
        else
          blockClientEmail(subject);
      }
    }
  }

  const accountManagerPipelineSettingHandleChange = (id)=>{
    for(let i=0;i<state.accountManagerPipelineSharing.length;i++){
      if(state.accountManagerPipelineSharing[i].ID===id){
        if(state.accountManagerPipelineSharing[i].shared==='yes')
          removePipelineSharing(id);
        else
          sharePipeline(id);
        break;
      }
    }
  }

  const channelHandleChange = (id)=>{
    let channels = state.channels.slice();

    for(let i=0;i<channels.length;i++){
      if(channels[i].ID===id){
        if(channels[i].subscribed==='yes')
          unsubscribeChannel(id);
        else
          subscribeChannel(id);
      }
    }
  }

  const toggleClientEmailDropDown = ()=>{
    setState({clientEmailDropDownOpen:!state.clientEmailDropDownOpen});
  }

  const toggleAddNewTemplateDropDown = ()=>{
    setState({addNewTemplateDropDownOpen:!state.addNewTemplateDropDownOpen});
  }

  const addTag = (e,tag)=>{
    e.preventDefault();
    setState({newTemplateText:state.newTemplateText+' [:'+tag+']'});
  }

  //API call
  const updatePhoneNumber = ()=>{
    let url = 'user/update';
    let successMsg = 'Phone number updated.';
    let errorMsg = 'Oops, something went wrong and could not update your phone number. Please try again later.';

    let parameters = [
      {
        field:'field',
        value:'phone'
      },
      {
        field:'value',
        value:state.phone
      },
      {
        field:'ID',
        value:state.userID
      }
    ];

    let callBack = (response)=>{
      let code= response.data.code;
      if(code==='00'){
        localStorage.setItem('phone', state.phone)
      }
    };
    callBack = callBack.bind(this);

    httpPut(url, parameters, successMsg, errorMsg, callBack);
  }

  const updatePipelinePreference = ()=>{
    let url = 'admin/pipeline/preference/update';
    let successMsg = 'Pipeline preference updated, please refresh your browser for it to take effect.';
    let errorMsg = 'Oops, something went wrong and could not update your pipeline preference. Please try again later.';

    let preference;
    if(state.globalPipeline)
      preference = 'personal';
    else
      preference = 'global';

    let parameters = [
      {
        field:'preference',
        value:preference
      }
    ];

    let callBack = (response)=>{
      console.log(response)
      let code= response.data.code;
      if(code==='00'){
        setState({globalPipeline:preference==='global'});
        localStorage.setItem('pipelinePreference', preference);
      }
    };
    callBack = callBack.bind(this);

    httpPut(url, parameters, successMsg, errorMsg, callBack);
  }

  const resetPassword = ()=>{
    if(state.internalResetEmail===''){
      setState({changePasswordMessage:'Please provide the email for resetting the password.'});
    }
    else{
      let url = 'session/internalResetPassword';
      let successMsg = 'Password had been reset to "password".';
      let errorMsg = 'Oops, something went wrong and could not reset the password. Please try again later.';

      let parameters = [
        {
          field:'email',
          value:state.internalResetEmail
        }
      ];

      let callBack = (response)=>{
        let code= response.data.code;

        if(code!=='00'){
          setState({changePasswordMessage:response.data.message});
        }
        else{
          setState({internalResetEmail:''});
        }
      };

      callBack = callBack.bind(this);

      httpPost(url, parameters, successMsg, errorMsg, callBack);
    }
  }

  const updateNewAppraiserCommentNotificationStatus = (status)=>{
    let url = 'notification/newAppraiserComment/subscribe';
    let successMsg = 'Notification preference updated.';
    let errorMsg = 'Oops, something went wrong and could not update your notification preference. Please try again later.';

    let parameters = [
      {
        field:'status',
        value:status
      }
    ];

    let callBack = apiCallBack([{state:'newAppraiserCommentNotification', value:{status:status}}]);

    httpPost(url, parameters, successMsg, errorMsg, callBack);
  }

  const updatePassword = ()=>{
    if(state.oldPassword===''){
      setState({changePasswordMessage:'Please provide your current password.'});
    }
    else if(state.newPassword!==state.confirmNewPassword){
      setState({changePasswordMessage:'Your new password does not match.'});
    }
    else{
      let url = 'session/updatePassword';
      let parameters = [
        {
          field:'oldPassword',
          value:state.oldPassword
        },
        {
          field:'newPassword',
          value:state.newPassword
        }
      ];
      let callBack = apiCallBack([{state:'newPassword', value:''},{state:'confirmNewPassword', value:''},{state:'oldPassword', value:''}]);

      httpPost(url, parameters, 'Password updated.', 'Oops, something went wrong and could not update your password. Please try again later.', callBack);
    }
  }

  const blockEmail = (subject)=>{
    let url = 'email/block';
    let successMsg = 'Email notification disabled.';
    let errorMsg = 'Oops, something went wrong and could not disable the email notification. Please try again later.';

    let parameters = [
      {
        field:'subject',
        value:subject
      }
    ];

    let callBack = (response)=>{
      let code= response.data.code;
      if(code==='00'){
        let emailSettings = state.emailSettings.slice();
        for(let i=0;i<emailSettings.length;i++){
          if(emailSettings[i].subject===subject){
            emailSettings[i].blocked='yes';
            break;
          }
        }

        setState({emailSettings:emailSettings});
      }
    };
    callBack = callBack.bind(this);

    httpPost(url, parameters, successMsg, errorMsg, callBack);
  }

  const unblockEmail = (subject)=>{
    let url = 'email/'+subject;
    let successMsg = 'Email notification enabled succesfully.';
    let errorMsg = 'Oops, something went wrong and could not enable the email notification. Please try again later.';

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let emailSettings = state.emailSettings.slice();
        for(let i=0;i<emailSettings.length;i++){
          if(emailSettings[i].subject===subject){
            emailSettings[i].blocked='no';
            break;
          }
        }

        setState({emailSettings:emailSettings});
      }
    };
    callBack = callBack.bind(this);

    httpDelete(url,successMsg,errorMsg, callBack);
  }

  const blockClientEmail = (subject)=>{
    let url = 'email/client/block';
    let successMsg = 'Email notification disabled.';
    let errorMsg = 'Oops, something went wrong and could not disable the email notification. Please try again later.';

    let parameters = [
      {
        field:'subject',
        value:subject
      },
      {
        field:'clientFk',
        value:state.clientFk
      }
    ];

    let callBack = (response)=>{
      let code= response.data.code;

      if(code==='00'){
        let clientEmailSettings = state.clientEmailSettings.slice();
        for(let i=0;i<clientEmailSettings.length;i++){
          if(clientEmailSettings[i].subject===subject){
            clientEmailSettings[i].blocked='yes';
            break;
          }
        }

        setState({clientEmailSettings:clientEmailSettings});
      }
    };
    callBack = callBack.bind(this);

    httpPost(url, parameters, successMsg, errorMsg, callBack);
  }

  const unblockClientEmail = (subject)=>{
    let url = 'email/client/'+state.clientFk+'/'+subject;
    let successMsg = 'Email notification enabled succesfully.';
    let errorMsg = 'Oops, something went wrong and could not enable the email notification. Please try again later.';
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let clientEmailSettings = state.clientEmailSettings.slice();
        for(let i=0;i<clientEmailSettings.length;i++){
          if(clientEmailSettings[i].subject===subject){
            clientEmailSettings[i].blocked='no';
            break;
          }
        }

        setState({clientEmailSettings:clientEmailSettings});
      }
    };
    callBack = callBack.bind(this);
    httpDelete(url,successMsg,errorMsg, callBack);
  }

  const sharePipeline = (id)=>{
    let url = 'accountManager/pipeline/share';
    let successMsg = 'Pipeline shared successfully.';
    let errorMsg = 'Oops, something went wrong and could not share the pipeline. Please try again later.';

    let parameters = [
      {
        field:'target_account_manager_fk',
        value:id
      }
    ];

    let callBack = (response)=>{
      let code= response.data.code;
      if(code==='00'){
        let accountManagerPipelineSharing = state.accountManagerPipelineSharing.slice();

        for(let i=0;i<accountManagerPipelineSharing.length;i++){
          if(accountManagerPipelineSharing[i].ID===id){
            accountManagerPipelineSharing[i].shared='yes';
            break;
          }
        }
        setState({accountManagerPipelineSharing:accountManagerPipelineSharing});
      }
    };
    callBack = callBack.bind(this);

    httpPost(url, parameters, successMsg, errorMsg, callBack);
  }

  const addNewTemplate = (e)=>{
    e.preventDefault();
    let url = 'template/create';
    let successMsg = 'Template created successfully.';
    let errorMsg = 'Oops, something went wrong and could not create the template. Please try again later.';

    let parameters = [
      {
        field:'name',
        value:state.newTemplateName
      },
      {
        field:'template',
        value:state.newTemplateText
      }
    ];

    let callBack = (response)=>{
      let code= response.data.code;
      if(code==='00'){
        let templates = state.templates.slice();
        templates.push(response.data.data);

        setState({templates:templates});
        toggleAddNewTemplateDropDown();
      }
    };
    callBack = callBack.bind(this);

    httpPost(url, parameters, successMsg, errorMsg, callBack);
  }

  const subscribeChannel = (id)=>{
    let url = 'notification/channel/subscribe';
    let successMsg = 'Channel subscribe successfully.';
    let errorMsg = 'Oops, something went wrong and could not subscribe to the channel. Please try again later.';

    let parameters = [
      {
        field:'ID',
        value:id
      }
    ];

    let callBack = (response)=>{
      let code= response.data.code;
      if(code==='00'){
        let channels = state.channels.slice();
        for(let i=0;i<channels.length;i++){
          if(channels[i].ID===id){
            channels[i].subscribed = 'yes';
            break;
          }
        }

        setState({channels:channels});
      }
    };
    callBack = callBack.bind(this);

    httpPost(url, parameters, successMsg, errorMsg, callBack);
  }

  const getAccountManagerPipelineSharing = ()=>{
    let url = 'accountManager/pipeline/get';
    let callBack = apiCallBack([{state:'accountManagerPipelineSharing', key:'data'}]);
    httpGet(url, '', '', callBack);
  }

  const getTemplates = ()=>{
    let url = 'template/get/-1';
    let callBack = apiCallBack([{state:'templates', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load your templates. Please try again later.', callBack);
  }

  const getNewAppraiserCommentNotificationStatus = ()=>{
    let url = 'notification/newAppraiserComment/status'
    let callBack = apiCallBack([{state:'newAppraiserCommentNotification', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load notification channels. Please try again later.', callBack);
  }

  const getChannels = ()=>{
    let url = 'notification/channel/get'
    let callBack = apiCallBack([{state:'channels', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load notification channels. Please try again later.', callBack);
  }

  const getEmailNotificationSettings = ()=>{
    let url = 'email/get';
    let callBack = apiCallBack([{state:'emailSettings', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load email notification settings. Please try again later.', callBack);
  }

  const getClientEmailNotificationSettings = (clientFk)=>{
    let url = 'email/client/get/'+clientFk;
    let callBack = apiCallBack([{state:'clientEmailSettings', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load client email notification settings. Please try again later.', callBack);
  }

  const getTemplateTags = ()=>{
    let url = 'template/tag/get';
    let callBack = apiCallBack([{state:'templateTags', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load tags for template. Please try again later.', callBack);
  }

  const removePipelineSharing = (id)=>{
    let url = 'accountManager/pipeline/'+id;
    let successMsg = 'Pipeline unshared successfully.';
    let errorMsg = 'Oops, something went wrong and could not unshared the pipeline. Please try again later.';

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let accountManagerPipelineSharing = state.accountManagerPipelineSharing.slice();

        for(let i=0;i<accountManagerPipelineSharing.length;i++){
          if(accountManagerPipelineSharing[i].ID===id){
            accountManagerPipelineSharing[i].shared='no';
            break;
          }
        }

        setState({accountManagerPipelineSharing:accountManagerPipelineSharing});
      }
    };
    callBack = callBack.bind(this);

    httpDelete(url,successMsg,errorMsg, callBack);
  }

  const deleteTemplate = (id)=>{
    let url = 'template/'+id;
    let templateList = sliceFromArray(state.templates,'ID', id);
    let callBack = apiCallBack([{state:'templates',value:templateList}]);
    httpDelete(url,'Template deleted successfully.','Oops, something went wrong and could not delete the template. Please try again later.', callBack);
  }

  const unsubscribeChannel = (id)=>{
    let url = 'notification/channel/unsubscribe/'+id;
    let successMsg = 'Channel unsubscribed successfully.';
    let errorMsg = 'Oops, something went wrong and could not unsubscribe the channel. Please try again later.';

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let channels = state.channels.slice();

        for(let i=0;i<channels.length;i++){
          if(channels[i].ID===id){
            channels[i].subscribed = 'no';
            break;
          }
        }

        setState({channels:channels});
      }
    };
    callBack = callBack.bind(this);

    httpDelete(url,successMsg,errorMsg, callBack);
  }

  const addVacationDates = ()=>{
    let url = 'admin/vacation/update';
    let successMsg = 'Vacation period updated successfully.';
    let errorMsg = 'Oops, something went wrong and could not add the vacation dates Please try again later.';

    let parameters = [
      {
        field:'vacation_from',
        value:state.from
      },
      {
        field:'vacation_to',
        value:state.to+' 23:59:59'
      },
    ];

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        getCurrentVacationDates();
      }
    };
    callBack = callBack.bind(this);

    httpPut(url, parameters, successMsg, errorMsg, callBack);
  }

  const getCurrentVacationDates = ()=>{
    let url = 'admin/vacation/get';
    let callBack = apiCallBack([{state:'currentVacationDates', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not retrieve vacation period.', callBack);
  }

  const getAllClientProfiles = ()=>{
    let url = 'client/get/limit=-1&offset=-1';
    let callBack = apiCallBack([{state:'clients', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not retrieve client profiles.', callBack);
  }

  //render
  let from, to;
  if(state.from!=='')
    from = moment(state.from).toDate();
  if(state.to!=='')
    to = moment(state.to).toDate();

  let templateTags;

  if(state.templateTags && state.templateTags.length>0){
    templateTags = state.templateTags.map(
      (tag, index)=>{
        return(
          <div key={index} className="display-inline template-tag" onClick={(e)=>{addTag(e,tag.tag);return false;}}>
            {tag.tag}
          </div>
        );
      }
    );
  }
  let templates;

  if(state.templates.length>0){
    templates = state.templates.map(
      (template, index)=>{
        if(template.user_fk==='0')
          return null;
        return(
          <tr key={index}>
            <td width="80%"><i className="fa fa-trash red-color cursor-pointer" onClick={
              ()=>{
                confirmation(
                  ()=>{deleteTemplate(template.ID)},
                  ()=>{},
                  'Delete template?',
                  'Are you sure you want to delete this template?');
              }
            }></i>&nbsp;&nbsp;<NavLink to={"/setting/template/"+template.ID}>{template.name}</NavLink></td><td><div className="align-right">{formatDate(template.datetime_created)}</div></td>
          </tr>
        )
      }
    );
  }

  let channels;

  if(state.channels.length>0){
    channels = state.channels.map(
      (channel, index)=>{
        return(
          <tr key={index}>
            <td width="80%">
              <NavLink to={"/setting/channel/"+channel.ID}>{channel.name}</NavLink><br/>
              {channel.description}
            </td>
            <td className="align-right">
              <Toggle
                checked={channel.subscribed==='yes'}
                icons={false}
                onChange={()=>channelHandleChange(channel.ID)} />
            </td>
          </tr>
        )
      }
    );
  }
  return <div className="my-well">
    <Row>
      <Col sm="6">
        <div className="page-title">
          <i className="fa fa-reorder"></i>&nbsp;Settings
        </div>
      </Col>
      <Col sm="6">

      </Col>
    </Row>
    <div className="my-divider"></div>
    <div className="card">
      <div className="card-header header-color">
        <Row>
          <Col sm="8">
            <i className="fa fa-edit"></i>&nbsp;Personal Comment Templates
          </Col>
          <Col sm="4">
            <div className="align-right">
              <div className="display-inline cursor-pointer" onClick={toggleAddNewTemplateDropDown}>
                <i className="fa fa-plus green-color"></i> Add new template
              </div>
            </div>
            <Modal className="my-modal" isOpen={state.addNewTemplateDropDownOpen} toggle={toggleAddNewTemplateDropDown} >
              <ModalHeader hidden={true} toggle={toggleAddNewTemplateDropDown}></ModalHeader>
              <ModalBody>
                <center>
                  <h5><i className="fa fa-plus"></i> Add new template</h5>
                </center>
                <br/>
                <form onSubmit={addNewTemplate}>
                  <label><font color="red">*</font>Name</label>
                  <Input required={true} type="text" value={state.newTemplateName} onChange={(e)=>setState({newTemplateName:e.target.value})}/>

                  <label><font color="red">*</font>Template</label>
                  <Input required={true} type="textarea" rows="10" style={{resize:'none'}} value={state.newTemplateText} onChange={(e)=>setState({newTemplateText:e.target.value})}/>
                  <br/>
                  <b>Tags:</b> {templateTags}
                  <br/>
                  <center>
                    <Button color="warning"><i className="green-color fa fa-plus"></i>&nbsp;Add</Button>&nbsp;
                    <Button color="info" onClick={toggleAddNewTemplateDropDown}>Close</Button>
                  </center>
                </form>
              </ModalBody>
            </Modal>
          </Col>
        </Row>
      </div>
      <div className="card-body">
        <div className="medium-scroll-container">
          <Table className="table table-striped">
            <tbody>
              {templates}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
    <br/>
    <div className="card">
      <div className="card-header header-color">
        <i className="fa fa-desktop"></i>&nbsp;New Appraiser Comemnt Notification
      </div>
      <div className="card-body">
        Subscription Status<br/>
        <MySelect
          type="select"
          value={state.newAppraiserCommentNotification.status}
          onChange={(v)=>{updateNewAppraiserCommentNotificationStatus(v)}}
          options={[{label:"Not subscribed",value:"no"},{label:"All new comment",value:"all"},{label:"Only my order",value:"private"}]}
        />
      </div>
    </div>
    <br/>
    <div className="card">
      <div className="card-header header-color">
        <i className="fa fa-desktop"></i>&nbsp;Notification channels
      </div>
      <div className="card-body">
        <Table className="table table-striped">
          <tbody>
            {channels}
          </tbody>
        </Table>
      </div>
    </div>
    <br/>
    <div className="card">
      <div className="card-header header-color">
        <i className="fa fa-tasks"></i>&nbsp;Admin Vacation
      </div>
      <div className="card-body">
        <Row>
          <Col sm="6">
            <Card>
              <CardHeader>
                <i className="fa fa-calendar"></i>&nbsp;Current Vacation Period
              </CardHeader>
              <CardBody>
                From:&nbsp;{formatDate(state.currentVacationDates['vacation_from'])}&nbsp;&nbsp;-&nbsp;To:&nbsp;{formatDate(state.currentVacationDates['vacation_to'])}
              </CardBody>
            </Card>
          </Col>
          <Col sm="2">
            <label>From</label><br/>
            <DatePicker
              className="form-control"
              dateFormat="yyyy/MM/dd"
              selected={from}
              onChange={(text)=>{(text)&&setState({from:text.toLocaleDateString('en-CA')})}}
            />
          </Col>
          <Col sm="2">
            <label>To</label><br/>
            <DatePicker
              className="form-control"
              dateFormat="yyyy/MM/dd"
              selected={to}
              onChange={(text)=>{(text)&&setState({to:text.toLocaleDateString('en-CA')})}}
            />
          </Col>
          <Col sm="2">
            <br/>
            <Button color="warning" onClick={()=>{addVacationDates()}}><i className="fa fa-plus green-color"></i>&nbsp;Add New Dates</Button>
          </Col>
        </Row>
      </div>
    </div>
    <br/>
    <div className="card">
      <div className="card-header header-color">
        <i className="fa fa-tasks"></i>&nbsp;Phone Number
      </div>
      <div className="card-body">
        <Row>
          <Col sm="8">
            <label>Phone Number</label><br/>
            <Input type="phone" value={state.phone} onChange={(e)=>setState({phone:e.target.value})}/>
          </Col>
          <Col sm="4" className="align-right">
            <br/>
            <Button color="warning" onClick={updatePhoneNumber}>Update</Button>
          </Col>
        </Row>
      </div>
    </div>
    <br/>
    <div className="card">
      <div className="card-header header-color">
        <i className="fa fa-tasks"></i>&nbsp;Pipeline Preference
      </div>
      <div className="card-body">
         <label>Switch it on to make your pipeline preference to global.</label><br/><Toggle
          checked={state.globalPipeline}
          icons={false}
          onChange={()=>updatePipelinePreference()} />
      </div>
    </div>
    <br/>


    <div className="card">
      <div className="card-header header-color">
        <i className="fa fa-tasks"></i>&nbsp;Account manager pipeline sharing
      </div>
      <div className="card-body">
        <table className="table table-striped">
          <tbody>
          {
            state.accountManagerPipelineSharing.map(
              (accountManagersPipelineSetting,index)=>{
                return(
                  <tr key={index}>
                    <td>
                        <Row>
                          <Col sm="6">
                            {accountManagersPipelineSetting.name}
                          </Col>
                          <Col sm="6" className="align-right">
                            <Toggle
                              checked={accountManagersPipelineSetting.shared==='yes'}
                              icons={false}
                              onChange={()=>accountManagerPipelineSettingHandleChange(accountManagersPipelineSetting.ID)} />
                          </Col>
                        </Row>
                    </td>
                  </tr>
                )
              }
            )
          }
          </tbody>
        </table>
      </div>
    </div>
    <br/>

    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-tasks"></i>&nbsp;Email notification
      </CardHeader>
      <CardBody>
        <div className="align-right">
          <NavLink to="#" onClick={toggleClientEmailDropDown}>Manage client email notifications</NavLink>
        </div>
        <div className="large-scroll-container">
          <table className="table table-striped">
            <tbody>
            {
              state.emailSettings.map(
                (emailSetting,index)=>{
                  return(
                    <tr key={index}>
                      <td>
                          <Row>
                            <Col sm="6">
                              {emailSetting.subject}
                            </Col>
                            <Col sm="6" className="align-right">
                              <Toggle
                                checked={emailSetting.blocked==='no'}
                                icons={false}
                                onChange={()=>emailNotificationToggle(emailSetting.subject)} />
                            </Col>
                          </Row>
                      </td>
                    </tr>
                  )
                }
              )
            }
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>

    <Modal className="my-modal" isOpen={state.clientEmailDropDownOpen} toggle={toggleClientEmailDropDown} >
      <ModalHeader hidden={true} toggle={toggleClientEmailDropDown}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-plus"></i> Client email notification</h5>
        </center>
        <br/>
        <Row>
          <Col sm="4">
            <label>Client</label>
          </Col>
          <Col sm="8">
            <MySelect
              type="select"
              modal={true}
              value={state.clientFk}
              onChange={(v)=>{clientEmailTargetChange(v)}}
              options={[{label:"All",value:""}].concat(
                state.clients.map((client)=>{
                  return {label:client.company, value:client.ID};
                 })
              )}
            />
          </Col>
        </Row>
        <br/>
        <div className="large-scroll-container">
          <table className="table table-striped">
            <tbody>
            {
              state.clientEmailSettings.map(
                (emailSetting,index)=>{
                  return(
                    <tr key={index}>
                      <td>
                          <Row>
                            <Col sm="6">
                              {emailSetting.subject}
                            </Col>
                            <Col sm="6" className="align-right">
                              <Toggle
                                checked={emailSetting.blocked==='no'}
                                icons={false}
                                onChange={()=>clientEmailNotificationToggle(emailSetting.subject)} />
                            </Col>
                          </Row>
                      </td>
                    </tr>
                  )
                }
              )
            }
            </tbody>
          </table>
        </div>
        <br/>
        <center>
          <Button color="info" onClick={toggleClientEmailDropDown}>Close</Button>
        </center>
      </ModalBody>
    </Modal>

    <br/>

    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-tasks"></i>&nbsp;Update password
      </CardHeader>
      <CardBody>
        <div style={{height:'30px'}}>
          <label><font color="red">{state.changePasswordMessage}</font></label>
        </div>
        <label>Your current password</label>
        <input className="form-control" type="password" value={state.oldPassword} onChange={(e)=>setState({oldPassword:e.target.value})}/>

        <label>New password</label>
        <input className="form-control" type="password" value={state.newPassword} onChange={(e)=>setState({newPassword:e.target.value})}/>

        <label>Confirm password</label>
        <input className="form-control" type="password" value={state.confirmNewPassword} onChange={(e)=>setState({confirmNewPassword:e.target.value})}/>

        <br/>

        <div className="align-right">
          <Button color="warning" onClick={updatePassword}>Update password</Button>
        </div>
      </CardBody>
    </Card>
    <br/>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-tasks"></i>&nbsp;Reset User Password
      </CardHeader>
      <CardBody>
        <div style={{height:'30px'}}>
          <label><font color="red">{state.changePasswordMessage}</font></label>
        </div>
        <br/><br/>
        <label>Email</label>
        <input className="form-control" type="email" value={state.internalResetEmail} onChange={(e)=>setState({internalResetEmail:e.target.value})}/>

        <br/>

        <div className="align-right">
          <Button color="warning" onClick={resetPassword}>Reset password</Button>
        </div>
      </CardBody>
    </Card>

  </div>;
}

export default PersonalSetting;
