//Author June Leow
//Date Mar 11th, 2024
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, deleteAPICallGenerator, callBackGenerator, sliceFromArray} from '../../util/util';
import {Button, Card, CardHeader, CardBody, Col, Row, Input} from 'reactstrap';
import {NavLink} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';
//initialize the state
const initialState = {
  keywords:[],
  newKeyword:''
};

//reducer function that perform state update
const reducer = getReducer();


const OrderScreening  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getKeywords();
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  //API call
  const getKeywords = ()=>{
    let url = 'orderScreening/keyword/get';
    let callBack = apiCallBack([{state:'keywords',key:'data'}]);
    httpGet(url, '','Oops, something went wrong and could not load the order screening keyword list. Please try again later.', callBack);
  }

  const deleteKeyword = (id) =>{
    let url = 'orderScreening/keyword/'+id
    let successMsg = 'Order screening keyword removed.';
    let errorMsg = 'Oops, something went wrong and could not delete the keyword. Please try again later.';

    let callBack = (response)=>{
      let code= response.data.code;
      if(code==='00'){
        let keywords = sliceFromArray(state.keywords, 'ID',id);
        setState({keywords:keywords});
      }
    };

    callBack = callBack.bind(this);

    httpDelete(url,successMsg,errorMsg, callBack);
  }

  const createNewKeyword = () =>{
    let url = 'orderScreening/keyword/create';
    let successMsg = 'Keyword created successfully.';
    let errorMsg = 'Oops, something went wrong and could not create the keyword. Please try again later.';

    let parameters = [
      {
        field:'keyword',
        value:state.newKeyword
      }
    ];

    console.log(parameters)

    let callBack = (response)=>{
      let code= response.data.code;

      if(code==='00'){
        let keywords = state.keywords.slice();
        keywords.push(response.data.data);
        setState({keywords:keywords});
      }
    };
    callBack = callBack.bind(this);

    httpPost(url, parameters, successMsg, errorMsg, callBack);
  }

  //render
  return <div className="my-well">
    <Row>
      <Col sm="6">
        <div className="page-title">
          <i className="fa fa-reorder"></i>&nbsp;Order Screening 
        </div>
        <NavLink to="/system-setting">Back to settings</NavLink>
      </Col>
      <Col sm="6">

      </Col>
    </Row>
    <b><font color="red">**</font>An automatic screening process that the system cross check several order facts before bidding/assignment.
      <br/>
      <font color="red">**</font>This includes: <br/>
      &nbsp;&nbsp;&nbsp;&nbsp;1) Cross check the property type client submitted VS Zillow<br/>
      &nbsp;&nbsp;&nbsp;&nbsp;2) Cross check if Zillow's listing description has any keyword that match the order screening keyword list<br/>
      &nbsp;&nbsp;&nbsp;&nbsp;3) Cross check if Zillow's listing has no data on number of bedroom or bathroom<br/>
    </b>
    <br/>
    <Card>
      <CardHeader className="header-color">
        Order Screening Keyword List
      </CardHeader>
      <CardBody>
        <Row>
          <Col sm="8">
            <Input type="text" value={state.newKeyword} onChange={(e)=>setState({newKeyword:e.target.value})} placeHolder="New Keyword"/>
          </Col>
          <Col sm="4" className="align-right">
            <Button color="warning" onClick={createNewKeyword}>Submit</Button>
          </Col>
        </Row>
        <div className="my-divider"/>
        {
          state.keywords.map(
            (keyword, index)=>{
              return <div key={index}>
                <i className="fa fa-times red-color cursor-pointer" onClick={(e)=>deleteKeyword(keyword.ID)}/> "{keyword.keyword}"
              </div>
            }
          )
        }
      </CardBody>
    </Card>
  </div>;
}


export default OrderScreening;
