//Author Sooyoung Kim
//Date May 31, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator,formatDateTime} from '../../util/util';
import {Modal, ModalHeader, ModalBody, Button, Row, Col, Table, Nav, NavItem, TabPane, TabContent,NavLink} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import './tracker.css';


//initialize the state
const initialState = {
  id: -1,
  popUpOpen: false,
  appraisal:{},
  timeline:[],
  trackerActiveTab:'6',
  appraisalLogs:[],
  conditionRebuttalLogs:[],
  paymentLogs:[],
  shippingLogs:[],
  changeLogs:[],
  allLogs:[],
  email:[]
};

//reducer function that perform state update
const reducer = getReducer();


const Tracker  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  //run only once when component is loaded
  useEffect(()=>{

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const orderTimeline = (timeline) =>{
    let set = [];
    let notset = [];
    let expected = -1;
    let duedate = -1;

    // get set array and notset array
    timeline.map(
      (entry,index)=>{
        if(entry.date!=='0000-00-00 00:00:00'){
          set.push(entry);
          if(entry.stage === 'Client Expected Date'){
            expected = 1;
          }else if(entry.stage === 'Appraiser Due Date'){
            duedate = 1;
          }
        }else{
          notset.push(entry);
        }

        return null;
      }
    );

    // sort the set array
    set.sort(function (a, b) {
      return a.date.localeCompare(b.date);
    });

    // concat set and notset array
    let newtimeline = set.concat(notset);

    if(expected!==-1 && duedate!==-1){
      return newtimeline;
    }else if(expected!==-1){
      return moveDates(newtimeline, 'Appraiser Due Date', 'Review Completed');
    }else if(duedate!==-1){
      return moveDates(newtimeline, 'Client Expected Date', 'Order Delivered');
    }else{
      return timeline;
    }
  }

  const moveDates = (timeline, keyStage, toStage) =>{
    let dateIndex = timeline.findIndex(entry => entry.stage === keyStage);
    let moveToIndex = timeline.findIndex(entry => entry.stage === toStage);
    return moveTimestamp(timeline, dateIndex, moveToIndex-1);
  }

  // Move the timestamp to the specific index
  const moveTimestamp = (arr, old_index, new_index) =>{
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }

  //toggle function
  const togglePopUp = () =>{
    if(Object.keys(state.appraisal).length===0){
      //extract the ID
      let url = window.location.href;
      let tokens = url.split('/');
      let ID;

      if(url.includes('audit'))
        ID = tokens[tokens.length-3];
      else
        ID = tokens[tokens.length-1];
      setState({id:ID});

      getAppraisal(ID);
      getTrackLog(ID);
    }
    if(state.popUpOpen){
      setState({
        appraisal:{},
        timeline:[],
        appraisalLogs:[],
        conditionRebuttalLogs:[],
        paymentLogs:[],
        shippingLogs:[],
        changeLogs:[],
        allLogs:[],
        email:[],
      });
    }
    setState({popUpOpen:!state.popUpOpen});
  }

  //toggle function between tab
  const trackerActiveTabToggle = (tab) =>{
    setState({trackerActiveTab:tab});
  }

  const customSort = (a, b) =>{
    if(a.datetime_created<b.datetime_created)
      return -1;
    if(a.datetime_created>b.datetime_created)
      return 1;
    return 0;
  }

  //API call
  //get appraisal
  const getAppraisal = (id) =>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        if(response.data.data.special_instructions!=='')
          setState({dropdownOpen:true});

        //order the date variable for tracker
        let appraisal = response.data.data;

        //submitted date always present
        let timeline = [];
        let temp = {};
        temp.stage = 'Order Submitted';
        temp.date = appraisal.datetime_submitted;
        timeline.push(temp);

        temp = {};
        temp.stage = 'Assignment Accepted';
        temp.date = appraisal.datetime_accepted;
        timeline.push(temp);

        if(appraisal.datetime_tat_cutoff!=='0000-00-00 00:00:00'){
          temp = {};
          temp.stage = 'TAT Reset';
          temp.date = appraisal.datetime_tat_cutoff;
          timeline.push(temp);
        }

        temp = {};
        temp.stage = 'Inspection Scheduled';
        temp.date = appraisal.datetime_scheduled;
        timeline.push(temp);

        temp = {};
        temp.stage = 'Inspection Completed';
        temp.date = appraisal.datetime_inspection_completed;
        timeline.push(temp);

        temp = {};
        temp.stage = 'Appraiser Due Date';
        temp.date = appraisal.datetime_due;
        timeline.push(temp);

        temp = {};
        temp.stage = 'Review Completed';
        temp.date = appraisal.datetime_review_completed;
        timeline.push(temp);

        temp = {};
        temp.stage = 'Client Expected Date';
        temp.date = appraisal.datetime_expected;
        timeline.push(temp);

        temp = {};
        temp.stage = 'Order Delivered';
        temp.date = appraisal.datetime_ship;
        timeline.push(temp);

        let newTimeline = orderTimeline(timeline);
        setState({timeline:newTimeline, appraisal:response.data.data, old_special_instructions:response.data.data.special_instructions});
      }
    };
    callBack = callBack.bind(this);
    httpGet('appraisal/'+id, '', 'Oops, something went wrong and could not load this appraisal. Please try again later.', callBack);
  }

  //get track log
  const getTrackLog = (id) =>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let appraisalLogs = response.data.data.appraisal;
        let emailsLog = response.data.data.email;
        let conditionRebuttalLogs = response.data.data.condition;
        let paymentLogs = response.data.data.payment;
        let shippingLogs = response.data.data.shipping;
        let changeLogs = response.data.data.change;
        let allLogs = appraisalLogs.concat(conditionRebuttalLogs).concat(paymentLogs).concat(shippingLogs);

        allLogs.sort(customSort);

        setState({
          appraisalLogs:appraisalLogs,
          conditionRebuttalLogs:conditionRebuttalLogs,
          paymentLogs:paymentLogs,
          shippingLogs:shippingLogs,
          changeLogs:changeLogs,
          allLogs:allLogs,
          email:emailsLog,
        });
      }
    };
    callBack = callBack.bind(this);
    httpGet('tracker/internal/'+id, '', 'Oops, something went wrong and could not load this appraisal\'s tracking information. Please try again later.', callBack);
  }

  //render
  let timeline;
  let appraisalType;

  if(state.appraisal.appraisal_type){
    appraisalType = <div className="display-inline">{state.appraisal.appraisal_type}</div>;
  }

  if(state.timeline.length>0){
    timeline = state.timeline.map(
      (entry,index)=>{
        let status = '';
        if(entry.date!=='0000-00-00 00:00:00'){
          if(entry.stage === 'Appraiser Due Date' || entry.stage === 'Client Expected Date'){
            status = 'completedate';
          }else{
            status = 'complete';
          }

          if(entry.stage==='TAT Reset')
            status= status+' bold';
        }
        return(
          <li key={index} className={"li "+status}>
            <div className="timestamp">
              <span className="date" style={{ fontSize: '14px' }}>
                {formatDateTime(entry.date)}
              </span>
            </div>
            <div className="status">
              <br/>{entry.stage}
            </div>
          </li>
        );
      }
    );
  }

  let appraisalLogs, conditionRebuttalLogs, paymentLogs, shippingLogs, changeLogs, allLogs, emailLogs;

  if(state.appraisalLogs.length>0){
    appraisalLogs = state.appraisalLogs.map(
      (appraisalLog, index)=>{
        return(
          <tr key={index}>
            <td>{formatDateTime(appraisalLog.datetime_created)}</td>
            <td>{appraisalLog.message}</td>
          </tr>
        );
      }
    );
  }

  if(state.conditionRebuttalLogs.length>0){
    conditionRebuttalLogs = state.conditionRebuttalLogs.map(
      (conditionRebuttalLogs, index)=>{
        return(
          <tr key={index}>
            <td>{formatDateTime(conditionRebuttalLogs.datetime_created)}</td>
            <td>{conditionRebuttalLogs.message}</td>
          </tr>
        );
      }
    );
  }

  if(state.paymentLogs.length>0){
    paymentLogs = state.paymentLogs.map(
      (paymentLog, index)=>{
        return(
          <tr key={index}>
            <td>{formatDateTime(paymentLog.datetime_created)}</td>
            <td>{paymentLog.message}</td>
          </tr>
        );
      }
    );
  }

  if(state.shippingLogs.length>0){
    shippingLogs = state.shippingLogs.map(
      (shippingLog, index)=>{
        return(
          <tr key={index}>
            <td>{formatDateTime(shippingLog.datetime_created)}</td>
            <td>{shippingLog.message}</td>
          </tr>
        );
      }
    );
  }

  if(state.email.length>0){
    emailLogs = state.email.map(
      (emailLog, index)=>{
        return(
          <tr key={index}>
            <td>{formatDateTime(emailLog.datetime_created)}</td>
            <td>{emailLog.subject}</td>
            <td>{emailLog.to+' '+emailLog.cc}</td>
          </tr>
        );
      }
    );
  }

  if(state.changeLogs.length>0){
    changeLogs = state.changeLogs.map(
      (changeLog, index)=>{
        return(
          <tr key={index}>
            <td>{formatDateTime(changeLog.datetime_created)}</td>
            <td>{changeLog.message}</td>
          </tr>
        );
      }
    );
  }

  if(state.allLogs.length>0){
    allLogs = state.allLogs.map(
      (changeLog, index)=>{
        return(
          <tr key={index}>
            <td>{formatDateTime(changeLog.datetime_created)}</td>
            <td>{changeLog.message}</td>
          </tr>
        );
      }
    );
  }

  return <div>
    <div className="cursor-pointer" onClick={togglePopUp}><i className="fa fa-search"></i> Tracker</div>
    <Modal className="my-modal-wide" isOpen={state.popUpOpen} toggle={togglePopUp} >
      <ModalHeader hidden={true} toggle={togglePopUp}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-search"></i> Tracking - {state.appraisal.reference_num}</h5>
        </center>
        <br/>


        <div className="timeline">
          {timeline}
        </div>
        <br/>
        <br/>
        <div>
          <label>Property:&nbsp;</label>
          {state.appraisal.property_street+' '+state.appraisal.property_city+', '+state.appraisal.property_state+' '+state.appraisal.property_zip}
          &nbsp;&nbsp;
          <label>Borrower:&nbsp;</label>
          {state.appraisal.borrower_f_name+' '+state.appraisal.borrower_l_name}
          &nbsp;&nbsp;
          &nbsp;&nbsp;
          <label>Loan Number:&nbsp;</label>
          {state.appraisal.loan_num}
          &nbsp;&nbsp;
          <label>Property Type:&nbsp;</label>
          {state.appraisal.property_type}
          &nbsp;&nbsp;
          <label>Loan Type:&nbsp;</label>
          {state.appraisal.loan_type}
          &nbsp;&nbsp;
          <label>Report Type:&nbsp;</label>
          {appraisalType}
        </div>
        <br/>
        <Row>
          <Col sm="12">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={"cursor-pointer nav-link "+(state.trackerActiveTab === '6'?"active":"inactive" ) }
                  onClick={() => { trackerActiveTabToggle('6'); }}
                >
                  All Log <span className="my-badge">{state.allLogs.length}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={"cursor-pointer nav-link "+(state.trackerActiveTab === '1'?"active":"inactive" )}
                  onClick={() => { trackerActiveTabToggle('1'); }}
                >
                  Appraisal <span className="my-badge">{state.appraisalLogs.length}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={"cursor-pointer nav-link "+(state.trackerActiveTab === '2'?"active":"inactive" )}
                  onClick={() => { trackerActiveTabToggle('2'); }}
                >
                  Condition/Rebuttal <span className="my-badge">{state.conditionRebuttalLogs.length}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={"cursor-pointer nav-link "+(state.trackerActiveTab === '3'?"active":"inactive" )}
                  onClick={() => { trackerActiveTabToggle('3'); }}
                >
                  Payment <span className="my-badge">{state.paymentLogs.length}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={"cursor-pointer nav-link "+(state.trackerActiveTab === '4'?"active":"inactive" )}
                  onClick={() => { trackerActiveTabToggle('4'); }}
                >
                  Shipping <span className="my-badge">{state.shippingLogs.length}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={"cursor-pointer nav-link "+(state.trackerActiveTab === '5'?"active":"inactive" ) }
                  onClick={() => { trackerActiveTabToggle('5'); }}
                >
                  Change Log <span className="my-badge">{state.changeLogs.length}</span>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={"cursor-pointer nav-link "+(state.trackerActiveTab === '7'?"active":"inactive" ) }
                  onClick={() => { trackerActiveTabToggle('7'); }}
                >
                  Email Log <span className="my-badge">{state.email.length}</span>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={state.trackerActiveTab}>
              <TabPane tabId="6">
                <Row>
                  <Col sm="12">
                    <div className="large-scroll-container">
                      <Table className="table table-striped">
                        <thead>
                          <tr>
                            <th className="header-color" width="15%"  style={{ color: 'white' }}>Date</th>
                            <th className="header-color" >Message</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allLogs}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="1">
                <Row>
                  <Col sm="12">
                    <div className="large-scroll-container">
                      <Table className="table table-striped">
                        <thead>
                          <tr>
                            <th className="header-color" width="15%">Date</th>
                            <th className="header-color" >Message</th>
                          </tr>
                        </thead>
                        <tbody>
                          {appraisalLogs}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col sm="12">
                    <div className="large-scroll-container">
                      <Table className="table table-striped">
                        <thead>
                          <tr>
                            <th className="header-color" width="15%">Date</th>
                            <th className="header-color" >Message</th>
                          </tr>
                        </thead>
                        <tbody>
                          {conditionRebuttalLogs}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="3">
                <Row>
                  <Col sm="12">
                    <div className="large-scroll-container">
                      <Table className="table table-striped">
                        <thead>
                          <tr>
                            <th className="header-color" width="15%">Date</th>
                            <th className="header-color" >Message</th>
                          </tr>
                        </thead>
                        <tbody>
                          {paymentLogs}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="4">
                <Row>
                  <Col sm="12">
                    <div className="large-scroll-container">
                      <Table className="table table-striped">
                        <thead>
                          <tr>
                            <th className="header-color" width="15%">Date</th>
                            <th className="header-color" >Message</th>
                          </tr>
                        </thead>
                        <tbody>
                          {shippingLogs}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="5">
                <Row>
                  <Col sm="12">
                    <div className="large-scroll-container">
                      <Table className="table table-striped">
                        <thead>
                          <tr>
                            <th className="header-color" width="15%">Date</th>
                            <th className="header-color" >Message</th>
                          </tr>
                        </thead>
                        <tbody>
                          {changeLogs}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="7">
                <Row>
                  <Col sm="12">
                    <div className="large-scroll-container">
                      <Table className="table table-striped">
                        <thead>
                          <tr>
                            <th className="header-color" width="15%">Date</th>
                            <th className="header-color" width="50%">Subject</th>
                            <th className="header-color" >Recipient</th>
                          </tr>
                        </thead>
                        <tbody>
                          {emailLogs}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Col>
        </Row>

        <br/>
        <center>
          <Button color="info" onClick={togglePopUp}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
  </div>;
}

export default Tracker;
