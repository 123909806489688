//Author Sooyoung Kim
//Date May 22nd, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, deleteAPICallGenerator, callBackGenerator, formatDate, confirmation, sliceFromArray} from '../../util/util';
import {Button, Row, Col, Modal, ModalHeader, ModalBody, Input, Card, CardHeader, CardBody, Table} from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import MyXEditable from '../util/my-xeditable';
import {NavLink, useParams} from 'react-router-dom';
import Toggle from 'react-toggle';
import React, {useReducer, useEffect} from 'react';
import MySelect from '../util/my-select';


//initialize the state
const initialState = {
  branch:{},
  branchUsers:[],
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
  userEmailDropDownOpen:false,
  userEmailSettings:[],
  clickedUserEmail:'',
  clickedUserName:'',
  addNewBranchUserDropDownOpen:false,
  warningMessage:'',
  newUserPassword1:'',
  newUserPassword2:'',
  newUserFirstName:'',
  newUserLastName:'',
  newUserEmail:'',
  newUserPhone:'',
  newUserRole:'',
  brokerRelationshipFk:'',
  formValid:false,
  hasMoreUsers:true,
  hasMoreUsers2:true,
  limit: 100,
  offset: 0,
  offset2: 0,
  loading: false,
  loading2: false,
  userSort: 'concat(u.first_name," ",u.last_name)',
  userOrder:'ASC',
  sort: 'first_name',
  order: 'ASC',
  name:'',
  email:'',
  searchName:'',
  searchEmail:'',
  clientEntities:[],
  clientEntityLabels:[],
  newClientEntity:'',
  masterUsers:[],
  users:[],
  publicSignUp:'no',
};

//reducer function that perform state update
const reducer = getReducer();


const ClientBranch  = (props)=>{
  const controller = new AbortController();
  let {clientFk} = useParams();
  let {params} = useParams();
  let {id} = useParams();

  let newInitialState = Object.assign({}, initialState, {
    id: id,
    params: params,
    clientFk: clientFk,
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getBranch();
    getBranchMasterUser();
    getClientBranchEntity();
    getClientEntityLabel();
    getRelationshipID(clientFk);

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //run only once when component is loaded
  useEffect(()=>{

    if(state.users.length<=0 && state.hasMoreUsers2 && state.addNewMasterUserDropDownOpen){
        loadMore2();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state]);

  useEffect(()=>{
    if(state.branchUsers.length<=0 && state.hasMoreUsers){
        loadMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state]);

  //non API call but simpyl manage state
  const refreshList = ()=>{
    setState({branchUsers:[],hasMoreUsers:true,offset:0});
  }

  const refreshList2 = ()=>{
    setState({users:[],hasMoreUsers2:true,offset2:0});
  }

  const resetSearchUser = ()=>{
    setState({hasMoreUsers2:true,loading2:false,users:[],offset2:0})
  }

  const updateFieldCallBack = (field, value)=>{
    let branch = Object.assign({}, state.branch);
    branch[field] = value;
    setState({branch:branch});
  }

  const updateUserStatus = (userID)=>{
    let branchUsers = state.branchUsers.slice();
    for(let i=0;i<branchUsers.length;i++){
      if(branchUsers[i].user_fk===userID){
        if(branchUsers[i].status==='activated')
          branchUsers[i].status = 'deactivated';
        else
          branchUsers[i].status = 'activated';
        setState({branchUsers:branchUsers});
        break;
      }
    }

    let masterUsers = state.masterUsers.slice();
    for(let i=0;i<masterUsers.length;i++){
      if(masterUsers[i].user_fk===userID){
        if(masterUsers[i].status==='activated')
          masterUsers[i].status = 'deactivated';
        else
          masterUsers[i].status = 'activated';
        setState({masterUsers:masterUsers});
        break;
      }
    }
  }

  const addNewMasterUserDropDownToggle = ()=>{
    if(!state.addNewMasterUserDropDownOpen){
      refreshList2();
      setState({addNewMasterUserDropDownOpen:!state.addNewMasterUserDropDownOpen});
    }
    else{
      setState({offset2:0,loading2:false,hasMoreUsers:true,addNewMasterUserDropDownOpen:!state.addNewMasterUserDropDownOpen});
      setTimeout(()=>setState({loading2:false}),1000);
    }
  }

  const emailNotificationToggle = (subject)=>{
    for(let i=0;i<state.userEmailSettings.length;i++){
      if(state.userEmailSettings[i].subject===subject){
        if(state.userEmailSettings[i].blocked==='yes')
          unblockUserEmail(subject);
        else
          blockUserEmail(subject);
      }
    }
  }

  const toggleEmailDropDown = (email, name)=>{
    if(!state.userEmailDropDownOpen){
      getEmailNotificationSettings(email, name);
    }else{
      setState({clickedUserEmail:'', clickedUserName:''});
    }
    setState({userEmailDropDownOpen:!state.userEmailDropDownOpen});
  }

  const updateUserInfo = (ID, field, value)=>{
    let branchUsers = state.branchUsers.slice();

    for(let i=0;i<branchUsers.length;i++){
      if(field==='role'){
        if(branchUsers[i].ID===ID){
          branchUsers[i][field] = value;
          break;
        }
      }
      else{
        if(branchUsers[i].user_fk===ID){
          branchUsers[i][field] = value;
          break;
        }
      }
    }

    setState({branchUsers:branchUsers});
  }

  //toggle function for pop up
  const addNewBranchUserDropDownToggle = ()=>{
    setState({addNewBranchUserDropDownOpen:!state.addNewBranchUserDropDownOpen});
  }

  //check if the second password matched the first one and set the state accordingly
  const checkPassword = (e)=>{
    if(state.newUserPassword1!==e.target.value){
      setState({warningMessage:'Your password does not match.',formValid:false});
    }
    else{
      setState({warningMessage:'',formValid:true});
    }
  }

  //API call
  //get the branch information
  const getBranch = ()=>{
    let url = '/client/branch/'+state.id;
    let callBack = apiCallBack([{state:'branch',key:'data'}]);
    httpGet(url, '','Oops, something went wrong and could not load this branch profile. Please try again later.', callBack);
  }

  const getBranchMasterUser = ()=>{
    //get list of master users of this client profile
    let url = 'client/masterUser/branch/get/'+state.clientFk+'/'+state.id;
    let callBack = apiCallBack([{state:'masterUsers',key:'data'}]);
    httpGet(url, '','Oops, something went wrong and could not load this client\'s branch master users. Please try again later.', callBack);
  }

  //remove a master user
  const removeMasterUser = (id)=>{
    let url = 'client/masterUser/branch/'+id;
    let list = sliceFromArray(state.masterUsers,'ID', id);
    let callBack = apiCallBack([{state:'masterUsers', value:list}]);
    httpDelete(url,'Branch master user deleted successfully.','Oops, something went wrong and could not delete this branch master user. Please try again later.', callBack);
  }

  //generate a function that do the update on editable
  const generateUpdateFunction = (url, parameters, successMessage, failedMessage, parentCallBack=null)=>{
    return (newValue, callBack, errorCallBack)=>{
      //add in the new value into parameters

      parameters.push({field:'value',value:newValue});
      let promise = httpPut(url,parameters,successMessage,failedMessage, callBack, errorCallBack);
      promise.then(
        (response)=>{
          let field;
          let value;

          for(let i=0;i<parameters.length;i++){
            if(parameters[i].field==='field')
              field = parameters[i].value;
            else if(parameters[i].field==='value')
              value = parameters[i].value;
          }
          if(parentCallBack!==null){
            parentCallBack(field, value);
          }
        }
      );
    }
  };

  //generate a function that do the update on editable
  const generateUpdateFunction2 = (url, parameters, successMessage, failedMessage, parentCallBack=null)=>{
    return (newValue, callBack, errorCallBack)=>{
      //add in the new value into parameters
      parameters.push({field:'newEmail',value:newValue});
      let promise = httpPost(url, parameters, successMessage, failedMessage, callBack, errorCallBack);
      promise.then(
        (response)=>{
          let field;
          let value;

          for(let i=0;i<parameters.length;i++){
            if(parameters[i].field==='field')
              field = parameters[i].value;
            else if(parameters[i].field==='value')
              value = parameters[i].value;
          }
          if(parentCallBack!==null){
            parentCallBack(field, value);
          }
        }
      );
    }
  };

  //submit a new lender user under this branch
  const submitNewUser = (e)=>{
    e.preventDefault();
    if(state.formValid){
      let url = 'client/branch/user/create';
      let successMsg = 'User created successfully.';
      let errorMsg = 'Oops, something went wrong and could not create this user. Please try again later.';

      let parameters = [
        {
          field:'branch_fk',
          value:state.id
        },
        {
          field:'first_name',
          value:state.newUserFirstName
        },
        {
          field:'last_name',
          value:state.newUserLastName
        },
        {
          field:'email',
          value:state.newUserEmail
        },
        {
          field:'phone',
          value:state.newUserPhone
        },
        {
          field:'public_signup',
          value:state.publicSignUp
        },
        {
          field:'author',
          value:props.userFirstName+" "+props.userLastName
        },
        {
          field:'password',
          value:state.newUserPassword1
        },
        {
          field:'role',
          value:state.newUserRole
        }
      ];

      let callBack = (response)=>{
        let code = response.data?response.data.code:undefined;
        if(code==='00'){
          let branchUsers = state.branchUsers.slice();

          branchUsers.push(response.data.data);

          setState({branchUsers:branchUsers});

          if(state.newUserRole!=='Other')
            createNewClientRelationship(state.newUserEmail, state.newUserRole, state.newUserPhone, state.id, state.newUserFirstName, state.newUserLastName, state.brokerCompanyProfileFk);
          addNewBranchUserDropDownToggle();
        }
      };
      callBack = callBack.bind(this);

      httpPost(url, parameters, successMsg, errorMsg, callBack);
    }
  }

  //remove a branch user
  const removeBranchUser = (id)=>{
    let url = 'client/branch/user/'+id;
    let list = sliceFromArray(state.branchUsers,'ID', id);
    let callBack = apiCallBack([{state:'branchUsers', value:list}]);
    httpDelete(url,'User deleted successfully.','Oops, something went wrong and could not delete this user. Please try again later.', callBack);
  }

  //function that fire when the infinite scroll reach bottom
  const loadMore2 = ()=>{
    //do not load if there is no more conditions or it's loading data
    if(state.hasMoreUsers2&&!state.loading2){
      //set loading equals to true so it won't fire off before we are done
      setState({loading2:true});
      let name = encodeURIComponent(state.searchName.replace(/\//g, '%ForwardSlash'));
      let email = encodeURIComponent(state.searchEmail.replace(/\//g, '%ForwardSlash'));

      let url = 'client/branch/user/get/branch_fk='+state.id+'&limit='+state.limit+'&offset='+state.offset2+'&order='+state.userOrder+'&sort='+state.userSort+'&client_fk='+state.clientFk+'&name='+name+'&email='+email;

      //callback handler that update the state when http request return
      let callBack = (response)=>{
        let code = response.data?response.data.code:undefined;

        if(code!=='00'){
          setState({hasMoreUsers2:false});
        }
        else{
          if(state.addNewMasterUserDropDownOpen){
            let newUsers = response.data.data;
            let hasMoreUsers = true;
            let newOffset = state.offset2;
            let totalCount = response.data.count;

            //if http request return empty then no more results, end of list
            if(newUsers.length<=0){
              hasMoreUsers = false;
            }
            else{
              //increment the offset
              newOffset = newOffset + 1;
            }

            //concat the current array of announcement
            if(state.users.length>0){
              let temp = [...state.users,...newUsers];

              setState({users:temp});
            }
            else
              setState({users:newUsers});

            setState({hasMoreUsers2:hasMoreUsers,offset2:newOffset,totalCount:totalCount});
          }
        }
      };
      callBack = callBack.bind(this);

      //error handler when the http request return with error
      let errorCallBack = apiCallBack([{state:'hasMoreUsers2', value:false}]);

      //collect the promise and wait for it to finish performing it's task
      let promises = httpGet(url, '','Oops, something went wrong and could not load users. Please try again later.', callBack, errorCallBack);
      promises
        .then(
          function(result){
            //set loading equals to false so the function could be fire off once again
            setState({loading2:false});
          }
        );
    }
  }

  //function that fire when the infinite scroll reach bottom
  const loadMore = ()=>{
    //do not load if there is no more conditions or it's loading data
    if(state.hasMoreUsers&&!state.loading){
      //set loading equals to true so it won't fire off before we are done
      setState({loading:true});

      let name = encodeURIComponent(state.name.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
      let email = encodeURIComponent(state.email.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));

      let url = 'client/branch/user/get/limit='+state.limit+'&offset='+state.offset+'&order='+state.order+'&sort='+state.sort+'&status='+state.status+'&name='+name+'&email='+email+'&branch_fk='+state.id;

      //callback handler that update the state when http request return
      let callBack = (response)=>{
        let code = response.data?response.data.code:undefined;

        if(code!=='00'){
          setState({hasMoreUsers:false});
        }
        else{
          let newUsers = response.data.data;
          let hasMoreUsers = true;
          let newOffset = state.offset;

          //if http request return empty then no more results, end of list
          if(newUsers.length<=0){
            hasMoreUsers = false;
          }
          else{
            //increment the offset
            newOffset = state.offset + 1;
          }

          //concat the current array of announcement
          if(state.branchUsers.length>0){
            let temp = [...state.branchUsers,...newUsers];

            setState({branchUsers:temp});
          }
          else
            setState({branchUsers:newUsers});

          setState({hasMoreUsers:hasMoreUsers,offset:newOffset});
        }
      };
      callBack = callBack.bind(this);

      //error handler when the http request return with error
      let errorCallBack = apiCallBack([{state:'hasMoreUsers', value:false}]);

      //collect the promise and wait for it to finish performing it's task
      let promises = httpGet(url, '','Oops, something went wrong and could not load users. Please try again later.', callBack, errorCallBack);
      promises
        .then(
          function(result){
            //set loading equals to false so the function could be fire off once again
            setState({loading:false});
          }
        );
    }
  }

  //submit a new master user from all branch users
  const submitNewMasterUser = (id)=>{
    let url = 'client/masterUser/branch/create';
    let successMsg = 'Branch master user created successfully.';
    let errorMsg = 'Oops, something went wrong and could not create this branch master user. Please try again later.';

    let parameters = [
      {
        field:'client_fk',
        value:state.clientFk
      },
      {
        field:'client_branch_fk',
        value:state.id
      },
      {
        field:'user_fk',
        value:id
      }
    ];

    let callBack = (response)=>{console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let masterUsers = state.masterUsers.slice();

        masterUsers.push(response.data.data);

        setState({masterUsers:masterUsers});

        addNewMasterUserDropDownToggle();
      }
    };
    callBack = callBack.bind(this);

    httpPost(url, parameters, successMsg, errorMsg, callBack);
  }

  //get client entity
  const getClientEntityLabel = ()=>{
    let url = 'client/entity/label/get';
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let labels = [];
        for(let i=0;i<response.data.data.length;i++){
          if(i===0)
            setState({newClientEntity:response.data.data[i].entity});
          let tmp = {};
          tmp.key = response.data.data[i].entity;
          tmp.value = response.data.data[i].entity;

          labels.push(tmp);
        }
        setState({clientEntityLabels:labels});
      }
    };
    callBack = callBack.bind(this);

    httpGet(url, '','Oops, something went wrong and could not load client entity label. Please try again later.', callBack);
  }

  //get client entity
  const getClientBranchEntity = ()=>{
    let url = 'client/branch/entity/get/'+state.clientFk+'/'+state.id;
    let callBack = apiCallBack([{state:'clientEntities',key:'data'}]);
    httpGet(url, '','Oops, something went wrong and could not load this client entity profile. Please try again later.', callBack);
  }

  //add a new entity label
  const addNewClientEntity = ()=>{
    let url = 'client/entity/create';
    let successMsg = 'Client entity created successfully.';
    let errorMsg = 'Oops, something went wrong and could not create this client entity. Please try again later.';

    let parameters = [
      {
        field:'clientFk',
        value:state.clientFk
      },
      {
        field:'secondaryFk',
        value:state.id
      },
      {
        field:'label',
        value:state.newClientEntity
      },
      {
        field:'level',
        value:'Branch'
      }
    ];

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let clientEntities = state.clientEntities.slice();
        clientEntities.push(response.data.data);

        setState({clientEntities:clientEntities});
      }
    };
    callBack = callBack.bind(this);

    httpPost(url, parameters, successMsg, errorMsg, callBack);
  }

  const removeClientEntity = (id)=>{
    let url = 'client/entity/'+id;
    let list = sliceFromArray(state.clientEntities,'ID', id);
    let callBack = apiCallBack([{state:'clientEntities', value:list}]);
    httpDelete(url,'Client entity deleted successfully.','Oops, something went wrong and could not delete this client entity. Please try again later.', callBack);
  }

  const getRelationshipID = (id)=>{
    let url = 'brokerRelationship/'+id;
    let callBack = apiCallBack([{state:'brokerCompanyProfileFk',key:'data.ID'}]);
    httpGet(url, '','Oops, something went wrong and could not your company profile. Please try again later.', callBack);
  }

  const createNewClientRelationship = (email, role, phone, branchFk, firstName, lastName, brokerCompanyProfileFk)=>{
    let url = 'brokerRelationship/processor';

    if(role==='Loan Officer'){
      url = 'brokerRelationship/loanOfficer/create';
    }
    else if(role==='Loan Processor'){
      url = 'brokerRelationship/processor/create';
    }
    else if(role==='Broker'){
      url = 'brokerRelationship/broker/create';
    }

    let parameters = [
      {
        field:'branchFk',
        value:branchFk
      },
      {
        field:'firstName',
        value:firstName
      },
      {
        field:'note',
        value:''
      },
      {
        field:'lastName',
        value:lastName
      },
      {
        field:'email',
        value:email
      },
      {
        field:'phone',
        value:phone
      },
      {
        field:'brokerCompanyProfileFk',
        value:brokerCompanyProfileFk
      }
    ];

    let callBack = ()=>{};

    //do not show success message and do another post to register user in client relationship
    httpPost(url, parameters, '', 'Oops, something went wrong and could not create this user. Please try again later.', callBack);
  }

  const blockUserEmail = (subject)=>{
    let url = 'email/user/block';

    let parameters = [
      {
        field:'subject',
        value:subject
      },
      {
        field:'email',
        value:state.clickedUserEmail
      }
    ];

    let callBack = (response)=>{
      let code= response.data.code;
      if(code==='00'){
        let userEmailSettings = state.userEmailSettings.slice();
        for(let i=0;i<userEmailSettings.length;i++){
          if(userEmailSettings[i].subject===subject){
            userEmailSettings[i].blocked='yes';
            break;
          }
        }
        setState({userEmailSettings:userEmailSettings});
      }
    };
    callBack = callBack.bind(this);

    httpPost(url, parameters, 'Email notification disabled.', 'Oops, something went wrong and could not disable the email notification. Please try again later.', callBack);
  }

  const unblockUserEmail = (subject)=>{
    let url = 'email/user/'+state.clickedUserEmail+'/'+subject;

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let userEmailSettings = state.userEmailSettings.slice();
        for(let i=0;i<userEmailSettings.length;i++){
          if(userEmailSettings[i].subject===subject){
            userEmailSettings[i].blocked='no';
            break;
          }
        }

        setState({userEmailSettings:userEmailSettings});
      }
    };
    callBack = callBack.bind(this);

    httpDelete(url,'Email notification enabled succesfully.','Oops, something went wrong and could not enable the email notification. Please try again later.', callBack);
  }

  const getEmailNotificationSettings = (email, name)=>{
    let url = 'email/user/get/'+email;
    let callBack = apiCallBack([{state:'userEmailSettings', key:'data'},{state:'clickedUserEmail', value:email},{state:'clickedUserName', key:name}]);
    httpGet(url, '', 'Oops, something went wrong and could not load user email notification settings. Please try again later.', callBack);
  }

  //render
  let successMessage = 'Branch profile updated.';
  let failedMessage = 'Failed to update the value of this field, please try again later.';
  let states;

  if(state.states.length>0)
    states =
     <MyXEditable
      type="select"
      value={state.branch.state}
      opt={state.states}
      updateFunc={
        generateUpdateFunction('client/branch/update',[{field:'field',value:'state'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
      }
    />;

  let masterUsers;

  if(state.masterUsers.length>0){
    masterUsers = state.masterUsers.map(
      (masterUser,index)=>{
        return(
          <tr key={index}>
            <td><i className="fa fa-minus cursor-pointer red-color" onClick={()=>removeMasterUser(masterUser.ID)}></i></td>
            <td>{masterUser.first_name+' '+masterUser.last_name}</td>
            <td><MyXEditable
              type="select"
              opt={[{key:'activated',value:'activated'},{key:'deactivated',value:'deactivated'}]}
              value={masterUser.status}
              parentCallBack={()=>{updateUserStatus(masterUser.user_fk)}}
              updateFunc={
                generateUpdateFunction('client/user/update',[{field:'field',value:'status'},{field:'ID',value:masterUser.client_branch_user_fk}],successMessage, failedMessage)
              }
            /></td>
            <td>{masterUser.email}</td>
            <td>{masterUser.phone}</td>
            <td>{formatDate(masterUser.datetime_created)}</td>
          </tr>
        );
      }
    );
  }

  let users;

  if(state.users.length>0){
    users = state.users.map(
      (user,index)=>{
        return(
          <tr key={index}>
            <td><i className="fa fa-arrow-up cursor-pointer green-color" onClick={()=>submitNewMasterUser(user.user_fk)}></i></td>
            <td>{user.first_name+' '+user.last_name}</td>
            <td>{user.email}</td>
            <td>{user.phone}</td>
          </tr>
        );
      }
    );
  }

  let warningMessage = <div>&nbsp;</div>;

  if(state.warningMessage!=='')
    warningMessage = <div className="display-inline"><font color="red">*</font>{state.warningMessage}</div>;

  let branchUsers;

  if(state.branchUsers.length>0){
    branchUsers = state.branchUsers.map(
      (branchUser,index)=>{
        return(
          <tr key={index}>
            <td><i className="fa fa-minus cursor-pointer red-color" onClick={()=>{
              confirmation(
                ()=>{removeBranchUser(branchUser.ID)},
                ()=>{},
                'Delete client account?',
                'Are you sure you want to delete this client account?');
            }}></i>&nbsp; &nbsp;<i className="cursor-pointer fa fa-cog" onClick={()=>toggleEmailDropDown(branchUser.email, branchUser.first_name+' '+branchUser.last_name)}></i>
             &nbsp; &nbsp;<NavLink to={"/client/"+state.clientFk+"/"+state.params+"/branch/"+state.id+"/user/"+branchUser.ID}><i className="cursor-pointer fa fa-user-circle"></i></NavLink>

            </td>

            <td>
              <MyXEditable
                type="text"
                value={branchUser.first_name}
                parentCallBack={(response, newValue)=>{updateUserInfo(branchUser.user_fk, 'first_name', newValue)}}
                updateFunc={
                  generateUpdateFunction('user/update',[{field:'field',value:'first_name'},{field:'ID',value:branchUser.user_fk}],successMessage, failedMessage)
                }
              />
            </td>
            <td>
              <MyXEditable
                type="text"
                value={branchUser.last_name}
                parentCallBack={(response, newValue)=>{updateUserInfo(branchUser.user_fk, 'last_name', newValue)}}
                updateFunc={
                  generateUpdateFunction('user/update',[{field:'field',value:'last_name'},{field:'ID',value:branchUser.user_fk}],successMessage, failedMessage)
                }
              />
            </td>
            <td><MyXEditable
              type="select"
              opt={[{key:'activated',value:'activated'},{key:'deactivated',value:'deactivated'}]}
              value={branchUser.status}
              parentCallBack={(response, newValue)=>{updateUserInfo(branchUser.user_fk, 'status', newValue)}}
              updateFunc={
                generateUpdateFunction('client/user/update',[{field:'field',value:'status'},{field:'ID',value:branchUser.ID}],successMessage, failedMessage)
              }
            /></td>
            <td><MyXEditable
              type="select"
              opt={[{key:'Loan Officer',value:'Loan Officer'},{key:'Loan Processor',value:'Loan Processor'},{key:'Broker',value:'Broker'}]}
              value={branchUser.role}
              parentCallBack={(response, newValue)=>{updateUserInfo(branchUser.ID, 'role', newValue)}}
              updateFunc={
                generateUpdateFunction('client/branch/user/update',[{field:'field',value:'role'},{field:'ID',value:branchUser.ID}],successMessage, failedMessage)
              }
            />
            </td>
            <td>
              <MyXEditable
                type="text"
                value={branchUser.email}
                parentCallBack={(response, newValue)=>{
                  getBranch();
                  getBranchMasterUser();
                  refreshList();
                  setState({loading:false});
                }}
                updateFunc={
                  generateUpdateFunction2('client/changeEmail',[{field:'userID',value:branchUser.user_fk}],successMessage, failedMessage)
                }
              />
            </td>
            <td>
              <MyXEditable
                type="text"
                value={branchUser.phone}
                parentCallBack={(response, newValue)=>{updateUserInfo(branchUser.user_fk, 'phone', newValue)}}
                updateFunc={
                  generateUpdateFunction('user/update',[{field:'field',value:'phone'},{field:'ID',value:branchUser.user_fk}],successMessage, failedMessage)
                }
              />
            </td>
            <td>{branchUser.author}<br/>{formatDate(branchUser.datetime_created)}</td>
          </tr>
        );
      }
    );
  }

  let clientEntities;

  if(state.clientEntities.length>0){
    clientEntities = state.clientEntities.map(
      (entity,index)=>{
        return(
          <div key={index} className="entity-container" onClick={()=>removeClientEntity(entity.ID)}>
            &nbsp;&nbsp;<i className="fa fa-trash red-color"></i> {entity.label}
          </div>
        );
      }
    );
  }

  return <div className="my-well">
    <Modal className="my-modal" isOpen={state.userEmailDropDownOpen} toggle={toggleEmailDropDown} >
      <ModalHeader hidden={true} toggle={toggleEmailDropDown}></ModalHeader>
      <ModalBody>
        <center>
          <h5><i className="fa fa-plus"></i> Email notification - {state.clickedUserName}</h5>
        </center>
        <br/>
        <div className="large-scroll-container">
          <table className="table table-striped">
            <tbody>
            {
              state.userEmailSettings.map(
                (emailSetting,index)=>{
                  return(
                    <tr key={index}>
                      <td>
                          <Row>
                            <Col sm="6">
                              {emailSetting.subject}
                            </Col>
                            <Col sm="6" className="align-right">
                              <Toggle
                                checked={emailSetting.blocked==='no'}
                                icons={false}
                                onChange={()=>emailNotificationToggle(emailSetting.subject)} />
                            </Col>
                          </Row>
                      </td>
                    </tr>
                  )
                }
              )
            }
            </tbody>
          </table>
        </div>
        <br/>
        <center>
          <Button color="info" onClick={toggleEmailDropDown}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
    <Row>
      <Col sm="6">
        <div className="page-title">
          <i className="fa fa-reorder"></i>&nbsp;Client Branch - {state.branch.branch_name}
        </div>
        <NavLink to={"/client/"+state.branch.client_fk+'/'+state.params}>Back to client profile</NavLink>
      </Col>
      <Col sm="6">

      </Col>
    </Row>
    <div className="my-divider"></div>
    <b>Sign-Up URL:</b> <a target="_blank" rel="noreferrer" href={"https://client.homevms.com/sign-up/"+btoa("clientFk="+state.clientFk+"&branchFk="+state.id)}>{"https://client.homevms.com/sign-up/"+btoa("clientFk="+state.clientFk+"&branchFk="+state.id)}</a>
    <Card>
      <CardHeader className="header-color">
        Basic Information
      </CardHeader>
      <CardBody>
          <Row>
            <Col sm="6">
              <Row>
                <Col sm="4">
                  Branch
                </Col>
                <Col sm="8">
                  <MyXEditable
                    type="text"
                    value={state.branch.branch_name}
                    updateFunc={
                      generateUpdateFunction('client/branch/update',[{field:'field',value:'branch_name'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Street
                </Col>
                <Col sm="8">
                  <MyXEditable
                    type="text"
                    value={state.branch.street}
                    updateFunc={
                      generateUpdateFunction('client/branch/update',[{field:'field',value:'street'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  City
                </Col>
                <Col sm="8">
                  <MyXEditable
                    type="text"
                    value={state.branch.city}
                    updateFunc={
                      generateUpdateFunction('client/branch/update',[{field:'field',value:'city'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  County
                </Col>
                <Col sm="8">
                  <MyXEditable
                    type="text"
                    value={state.branch.county}
                    updateFunc={
                      generateUpdateFunction('client/branch/update',[{field:'field',value:'county'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  State
                </Col>
                <Col sm="8">
                  {states}
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Zip
                </Col>
                <Col sm="8">
                  <MyXEditable
                    type="text"
                    value={state.branch.zip}
                    updateFunc={
                      generateUpdateFunction('client/branch/update',[{field:'field',value:'zip'},{field:'ID',value:state.id}],successMessage, failedMessage, updateFieldCallBack)
                    }
                  />
                </Col>
              </Row>
            </Col>
            <Col sm="6">
              <Row>
                <Col sm="12" className="align-right">

                </Col>
              </Row>
            </Col>
          </Row>
      </CardBody>
    </Card>

    <br/>

    <Card>
      <CardHeader className="header-color">
        {state.branch.branch_name} Branch Master User
      </CardHeader>
      <CardBody>
        <Table className="table table-striped">
          <thead>
            <tr>
              <th width="10%">Action</th>
              <th width="25%">Name</th>
              <th width="10%">Status</th>
              <th width="25%">Email</th>
              <th width="15%">Phone</th>
              <th width="15%">Date Created</th>
            </tr>
          </thead>
          <tbody>
            {masterUsers}
          </tbody>
        </Table>
        <div className="align-right">
          <Button color="warning" onClick={addNewMasterUserDropDownToggle}>Add</Button>
          <Modal className="my-modal" isOpen={state.addNewMasterUserDropDownOpen} toggle={addNewMasterUserDropDownToggle} >
            <ModalHeader hidden={true} toggle={addNewMasterUserDropDownToggle}></ModalHeader>
            <ModalBody>
              <center>
                <h5><i className="fa fa-user"></i> Add New Master User</h5>
              </center>
              <br/>
              <Row style={{marginBottom:'10px'}}>
                <Col sm="4">
                  <label>Name</label><br/>
                  <Input type="text" value={state.searchName} onChange={(e)=>setState({searchName:e.target.value})}/>
                </Col>
                <Col sm="4">
                  <label>Email</label><br/>
                  <Input type="text" value={state.searchEmail} onChange={(e)=>setState({searchEmail:e.target.value})}/>
                </Col>
                <Col sm="4" className="align-right">
                  <br/>
                  <Button color="warning" onClick={resetSearchUser}>Submit</Button>
                </Col>
              </Row>
              <form onSubmit={submitNewMasterUser}>
                <div className="large-scroll-container">
                  <InfiniteScroll
                    next={loadMore2}
                    dataLength={state.users.length}
                    hasMore={state.hasMoreUsers2}
                    loader={<div key="nill" className="loader"><center>Loading more users...</center></div>}
                    initialLoad = {true}
                    className="my-well"
                    scrollableTarget="contentContainer"
                  >
                    <Table className="table table-striped">
                      <thead>
                        <tr>
                          <th width="15%">Action</th>
                          <th width="3%">Name</th>
                          <th width="30%">Email</th>
                          <th width="25%">Phone</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users}
                      </tbody>
                    </Table>
                  </InfiniteScroll>
                </div>
                <br/>
                <center>
                  <Button color="info" onClick={addNewMasterUserDropDownToggle}>Close</Button>
                </center>
              </form>
            </ModalBody>
          </Modal>
        </div>
      </CardBody>
    </Card>

    <br/>

    <Card>
      <CardHeader className="header-color">
        {state.branch.branch_name} Branch Registered Users
      </CardHeader>
      <CardBody>
        <Row>
          <Col sm="4">
            <label>Name:</label>
            <Input type="text" value={state.name} onChange={(e)=>setState({name:e.target.value})}/>
          </Col>
          <Col sm="4">
            <label>Email:</label>
            <Input type="text" value={state.email} onChange={(e)=>setState({email:e.target.value})}/>
          </Col>
          <Col sm="4" className="align-right">
            <div className="ex-margin-bottom">&nbsp;</div>
            <Button color="warning" onClick={refreshList}>Submit</Button>
          </Col>
        </Row>
        <br/>
        <div className="large-scroll-container">
          <InfiniteScroll
            next={loadMore}
            dataLength={state.branchUsers.length}
            hasMore={state.hasMoreUsers}
            loader={<div key="nill" className="loader"><center>Loading more users...</center></div>}
            initialLoad = {true}
            className="my-well"
            scrollableTarget="contentContainer"
          >
            <Table className="table table-striped">
              <thead>
                <tr>
                  <th width="12%">Action</th>
                  <th width="10%">First Name</th>
                  <th width="10%">Last Name</th>
                  <th width="15%">Status</th>
                  <th width="13%">Role</th>
                  <th width="15%">Email</th>
                  <th width="10%">Phone</th>
                  <th width="15%">Date Created</th>
                </tr>
              </thead>
              <tbody>
                {branchUsers}
              </tbody>
            </Table>
          </InfiniteScroll>
        </div>
        <br/>
        <div className="align-right">
          <Button color="warning" onClick={addNewBranchUserDropDownToggle}>Add</Button>
        </div>
        <Modal className="my-modal" isOpen={state.addNewBranchUserDropDownOpen} toggle={addNewBranchUserDropDownToggle} >
          <ModalHeader hidden={true} toggle= {addNewBranchUserDropDownToggle}></ModalHeader>
          <ModalBody>
            <center>
              <h5><i className="fa fa-bank"></i> Add New User</h5>
            </center>
            <br/>
            <form onSubmit={submitNewUser}>
              <div>{warningMessage}</div>
              <Row>
                <Col sm="6">
                  <label>First Name:</label>
                  <Input required={true} type="text" value={state.newUserFirstName} onChange={(e)=>setState({newUserFirstName:e.target.value})}/>
                </Col>
                <Col sm="6">
                  <label>Last Name:</label>
                  <Input required={true} type="text" value={state.newUserLastName} onChange={(e)=>setState({newUserLastName:e.target.value})}/>
                </Col>
              </Row>
              <Row>
                <Col sm="6">
                  <label>Role</label>
                  <MySelect
                    modal={true}
                    type="select"
                    value={state.newUserRole}
                    onChange={(v)=>setState({newUserRole:v})}
                    options={[{label:"Loan Officer",value:"Loan Officer"},{label:"Loan Processor",value:"Loan Processor"},{label:"Broker",value:"Broker"}]}
                  />
                </Col>
                <Col sm="6">
                  <label>Public Sign-up</label>
                  <MySelect
                    modal={true}
                    type="select"
                    value={state.publicSignUp}
                    onChange={(v)=>setState({publicSignUp:v})}
                    options={[{label:"No",value:"no"},{label:"Yes",value:"yes"}]}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <label>Email:</label>
                  <Input required={true} type="text" value={state.newUserEmail} onChange={(e)=>setState({newUserEmail:e.target.value})}/>
                </Col>
                <Col sm="12">
                  <label>Phone:</label>
                  <Input type="text" value={state.newUserPhone} onChange={(e)=>setState({newUserPhone:e.target.value})}/>
                </Col>
              </Row>
              <Row>
                <Col sm="6">
                  <label>Password:</label>
                  <Input required={true} type="text" value={state.newUserPassword1} onChange={(e)=>{setState({newUserPassword1:e.target.value});}}/>
                </Col>
                <Col sm="6">
                  <label>Re-type Password:</label>
                  <Input required={true} type="text" value={state.newUserPassword2} onChange={(e)=>{setState({newUserPassword2:e.target.value});checkPassword(e)}}/>
                </Col>
              </Row>
              <br/>
              <center>
                <Button color="warning"><i className="fa fa-check"></i>&nbsp;Add</Button>{' '}
                <Button color="info" onClick={addNewBranchUserDropDownToggle}>Close</Button>
              </center>
            </form>
          </ModalBody>
        </Modal>
      </CardBody>
    </Card>
    <br/>
    <Card>
      <CardHeader className="header-color">
        Order Details Structure
      </CardHeader>
      <CardBody>
        <label>Add new entity</label>
        <Row>
          <Col sm="4">
            <MySelect
              modal={true}            
              type="select"
              value={state.newClientEntity}
              onChange={(v)=>setState({newClientEntity:v})}
              options={state.clientEntityLabels.map((label)=>{
                return {label:label.value, value:label.value};
              })}
            />
          </Col>
          <Col sm="4">
            <Button color="warning" onClick={addNewClientEntity}>Add</Button>
          </Col>
        </Row>
        <br/>
        <div className="my-wel">
          {clientEntities}
        </div>
      </CardBody>
    </Card>
  </div>;
}

export default ClientBranch;
