//Author Sooyoung Kim
//Date June 5, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, callBackGenerator, formatNumber, showMessage} from '../../util/util';
import {Button, Card, CardHeader, CardBody, Col, Row, Table} from 'reactstrap';
import Dropzone from 'react-dropzone'
import FileSaver from 'file-saver';
import MyXEditable from '../util/my-xeditable';
import MySelect from '../util/my-select';
import {NavLink, useNavigate} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';
import './fee.css';

//initialize the state
const initialState = {
  fees:[],
  types:[],
  appraisalTypes:[],
  selectedColumns:[],
  selectedRows:[],
  clients:[],
  toUploadFiles:[],
  errorMessage:'',
  uploadDisabled:false
};

//reducer function that perform state update
const reducer = getReducer();


const DefaultFee  = (props)=>{
  const controller = new AbortController();
  const history = useNavigate();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getDefaultFee();
    getAllClientProfiles();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const updateFieldCallBack = (state, appraisalType, value)=>{
    let fees = state.fees.slice();

    for(let i=0;i<fees.length;i++){

      if(fees[i].state===state){

        let aFees = fees[i].fees;

        for(let j=0;j<aFees.length;j++){
          if(aFees[j].appraisal_type===appraisalType){
            aFees[j].fee = value;
            fees[i].fees = aFees;

            setState({fees:fees});
            return;
          }
        }
      }
    }
  }

  const selectColumn = (column)=>{
    let selectedColumns = state.selectedColumns.slice();

    let index = selectedColumns.indexOf(column);
    if(index===-1)
      selectedColumns.push(column);
    else
      selectedColumns.splice(index,1);

    setState({selectedColumns:selectedColumns});

  }

  const selectRow = (row)=>{
    let selectedRows = state.selectedRows.slice();

    let index = selectedRows.indexOf(row);
    if(index===-1)
      selectedRows.push(row);
    else
      selectedRows.splice(index,1);

    setState({selectedRows:selectedRows});

  }

  const clientOnChange = (clientFk)=>{
    if(clientFk!=='0')
      history('/system-setting/fee/client/'+clientFk);
  }

  const uploadButtonHandler = ()=>{
    if(state.uploadDisabled)
      return;

    setState({uploadDisabled:true},uploadAll());
  }

  //function trigger for dropzone react.
  //this function contians two list of files, the accepted and rejected file per the configuration
  const onDrop = (acceptedFiles, rejectedFiles)=>{
    let existingFiles = [];

    if(acceptedFiles.length>0)
      existingFiles.push(acceptedFiles[0]);
    setState({toUploadFiles: existingFiles});
  }

  //remove a specific file from the toUpload list.
  const removeToUploadFile = (preview)=>{
    let toRemoveIndex = -1;
    for(let i=0;i<state.toUploadFiles.length;i++){
      if(state.toUploadFiles[i].preview===preview){
        toRemoveIndex = i;
        break;
      }
    }

    if(toRemoveIndex!==-1){
      let newFiles = state.toUploadFiles.slice();
      newFiles.splice(toRemoveIndex,1);

      setState({toUploadFiles:newFiles});
    }
  }

  //format the size to use appropiate unit KB, MB and B and round up to only 1 decimal
  const formatFileSize = (size)=>{
    let intSize = parseInt(size,10);

    if(intSize>=1000000.00)
      return formatNumber(Math.round((intSize*10/1000000))/10)+' MB';
    else if(intSize>=1000)
      return formatNumber(Math.round((intSize*10/1000))/10)+' KB';
    else
      return formatNumber(intSize)+' B';

  }

  //API call
  //generate a function that do the update on editable
  const generateUpdateFunction = (url, parameters, successMessage, failedMessage, parentCallBack=null)=>{
    return (newValue, callBack, errorCallBack)=>{
      //add in the new value into parameters

      parameters.push({field:'value',value:newValue});
      let promise = httpPut(url, parameters, successMessage, failedMessage, callBack, errorCallBack);
      promise.then(
        (response)=>{
          let state;
          let value;
          let appraisalType;

          for(let i=0;i<parameters.length;i++){
            if(parameters[i].field==='state')
              state = parameters[i].value;
            else if(parameters[i].field==='value')
              value = parameters[i].value;
            else if(parameters[i].field==='appraisalType')
              appraisalType = parameters[i].value;
          }
          if(parentCallBack!==null){
            parentCallBack(state, appraisalType, value);
          }
        }
      );
    }
  };

  //function that upload all toUploadFiles to the server by calling asyncPost
  const uploadAll = ()=>{
    let preCheck = true;
    let errorMessage = '';

    if(state.toUploadFiles.length<=0){
      preCheck = false;
      errorMessage = 'Please upload at least one file.';
    }

    //only proceed when no error
    if(preCheck){
      setState({errorMessage:'Uploading...Please do not close the window.'});

      let fileCompleted = 0;
      for(let i=0;i<state.toUploadFiles.length;i++){
        //skip file that has done upload
        if(state.toUploadFiles[i].status==='Done')
          continue;
        const reader = new FileReader();
        // eslint-disable-next-line no-loop-func
        reader.onload = () => {
            const fileAsBinaryString = reader.result;
            let base64 = btoa(fileAsBinaryString);

            let callBack = (response)=>{
              let code = response.data?response.data.code:undefined;
              if(code==='00'){
                getDefaultFee();
              }
              fileCompleted++;
              if(fileCompleted>=state.toUploadFiles.length){
                setState({uploadDisabled:false, errorMessage:''});
              }
            };
            callBack = callBack.bind(this);

            let errorCallBack = (error)=>{
              fileCompleted++;

              if(fileCompleted>=state.toUploadFiles.length){
                setState({uploadDisabled:false, errorMessage:''});
              }
            };

            errorCallBack = errorCallBack.bind(this);

            let parameters = [{ field:'file_encoded', value:base64 }];

            httpPost('fee/upload', parameters, 'File "'+state.toUploadFiles[i].name+'" uploaded successfully.', 'Oops, something went wrong and could not upload the file "'+state.toUploadFiles[i].name+'". Please try again later.', callBack, errorCallBack);
        };
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => {
          showMessage('error','File upload failed, please try again later.');
        };

        reader.readAsBinaryString(state.toUploadFiles[i]);
      }
      setState({uploadDisabled:false});
    }
    else{
      setState({errorMessage:errorMessage});
      setTimeout(()=>setState({uploadDisabled:false}),1000);
    }
  }

  const downloadFeeSchedule = ()=>{
    let callBack = (response)=>{
      let byteCharacters = atob(response.data.data.data);
      let byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      let data = new Blob([byteArray]);
      FileSaver.saveAs(data, 'fee-schedule.xlsx');
    };
    callBack = callBack.bind(this);

    httpPost('fee/default/download', [], '', 'Oops, something went wrong and could not download the fee schedule. Please try again later.', callBack);
  }

  const getAllClientProfiles = ()=>{
    httpGet('client/get/limit=-1&offset=-1', '', 'Oops, something went wrong and could not retrieve client profiles.', apiCallBack([{state:'clients', key:'data'}]));
  }

  const getDefaultFee = ()=>{
    let callBack = (response)=>{console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        setState({fees:response.data.data});

        if(response.data.data.length>0&&response.data.data[0].fees.length>0){

          //get appraisal types
          let firstRow = response.data.data[0].fees;

          let appraisalTypes = [];
          let types = [];
          let tmp = {};
          for(let i=0;i<firstRow.length;i++){
            appraisalTypes.push({appraisal_type:firstRow[i].appraisal_type, type:firstRow[i].type});
            if(!tmp[firstRow[i].type])
              tmp[firstRow[i].type]=0;
            tmp[firstRow[i].type]++;
          }

          for(const property in tmp){
            types.push({type:property, count:tmp[property]});
          }

          setState({appraisalTypes:appraisalTypes, types:types});
        }
      }
    };
    callBack = callBack.bind(this);

    httpGet('fee/default/get', '', 'Oops, something went wrong and could not load the default fee.', callBack);
  }


  //render
  let successMessage = 'Fee updated.';
  let failedMessage = 'Failed to update the value of this field, please try again later.';

  let firstRow, stateFees, header;
  if(state.fees.length>0){
    let colors = ['#ffb3b3','#9999ff','#00cc00','#0066cc','#6600ff','#ff00ff','#660033','#99ccff','#996600','#669999','#f5d6eb'];
    let columns = state.appraisalTypes.map(
      (appraisalType, index)=>{
        let colorIndex = 0;
        for(let i=0;i<state.types.length;i++){

          if(state.types[i].type===appraisalType.type)
            colorIndex =i;
        }

        return(
          <th key={index} className="cursor-pointer border-right" onClick={(e)=>selectColumn(index)} id={"tooltip"+index}>
            <div className="vertical-text" style={{minHeight:'300px', color: colors[colorIndex]}}>
              {appraisalType.appraisal_type}
            </div>
          </th>
        );
      }
    );


    let headerColumns = state.types.map(
      (type, index)=>{
        return(
          <th colSpan={type.count} key={index} className="cursor-pointer border-right" style={{color:'black',background:colors[index]}}>
            <center>{type.type}</center>
          </th>
        );
      }
    );

    header =
      <tr>
        <th></th>
        {headerColumns}
      </tr>;

    firstRow =
      <tr>
        <th></th>
        {columns}
      </tr>;

    stateFees = state.fees.map(
      (states, index)=>{
        let selected2 = '';
        let i = state.selectedRows.indexOf(index);
        if(i!==-1)
          selected2 = 'highlight';
        let fees = states.fees.map(
          (feeState,index2)=>{
            let selected = '';
            let i = state.selectedColumns.indexOf(index2);
            if(i!==-1)
              selected = 'highlight';
            return(
              <td key={index2} className={selected+' '+selected2}>
                <MyXEditable
                hideButton={true}
                type="text"
                value={feeState.fee}
                prefix="$"
                updateFunc={
                  generateUpdateFunction('fee/default/update',[{field:'state',value:states.state},{field:'appraisalType', value:feeState.appraisal_type}],successMessage, failedMessage, updateFieldCallBack)
                }/>
              </td>
            );
          }
        )

        return(
          <tr key={index}>
            <td style={{minWidth:'50px'}} onClick={(e)=>selectRow(index)} className="cursor-pointer">{states.state_code}</td>
            {fees}
          </tr>
        );
      }
    );
  }

  let toUploadFiles;

  if(state.toUploadFiles.length>0){
    toUploadFiles = state.toUploadFiles.map(
      (file,index)=>{
        return(
          <tr key={index}>
            <td>{file.name}</td>
            <td>{formatFileSize(file.size)}</td>
            <td><center>{file.status}</center></td>
            <td><center><i className="fa fa-times red-color cursor-pointer" onClick={()=>removeToUploadFile(file.preview)}></i></center></td>
            <td className="align-right"><Button color="warning" disabled={state.uploadDisabled!==false} onClick={uploadButtonHandler}><i className="fa fa-upload"></i> Upload</Button></td>
          </tr>
        );
      }
    );
  }

  return <div className="my-well">
    <Row>
      <Col sm="6">
        <div className="page-title">
          <i className="fa fa-dollar"></i>&nbsp;Fee
        </div>
      </Col>
      <Col sm="6">
      </Col>
    </Row>
    <div className="my-divider"></div>
    <Row>
      <Col sm="12">
        <Row>
          <Col sm="6">
            <label>Fee schedule</label>
          </Col>
          <Col sm="6" className="align-right">
            <NavLink to="#" onClick={downloadFeeSchedule}>Download fee schedule</NavLink>
          </Col>
        </Row>
        <MySelect
          type="select"
          value={state.clientFk}
          onChange={(v)=>clientOnChange(v)}
          options={[{label:"Default fee schedule", value:0}].concat(
            state.clients.map((client)=>{
              return {label:client.company, value:client.ID};
            })
          )}
        />
      </Col>
    </Row>
    <br/>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-dollar"></i>&nbsp;Fees
      </CardHeader>
      <CardBody>
        <div className="Container Flipped">
          <div className="Content">
            <Table className="table table-striped table-header-rotated">
              <thead>
                {header}
                {firstRow}
              </thead>
              <tbody>
                {stateFees}
              </tbody>
            </Table>
          </div>
        </div>
      </CardBody>
    </Card>
    <div className="my-divider">&nbsp;</div>
    <Dropzone onDrop={onDrop} className="my-dropzone">
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <center>
            <font style={{fontSize:'60px'}}>
              <i className="fa fa-cloud-upload link-color"></i>
            </font>
            <br/>
            <Button color="success">Choose files to upload</Button>
            <br/>
            <div className="margin-top">
              <i><b>OR drag and drop file here.</b></i>
            </div>
          </center>
        </div>
      )}
    </Dropzone>
    <br/>
    <div className="my-divider"></div>
    <div className="small-scroll-container red-color">
      <b>{state.errorMessage}</b>
    </div>
    <div className="medium-scroll-container">
      <table className="table file-list-table" cellSpacing="0" cellPadding="0">
        <thead>
          <tr>
            <th width="35%">Name</th>
            <th width="15%">Size</th>
            <th width="10%"><center>Status</center></th>
            <th width="10%">Control</th>
            <th width="30%">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {toUploadFiles}
        </tbody>
      </table>
    </div>
  </div>;
}

export default DefaultFee;
