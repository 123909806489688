//Author Sooyoung Kim
//Date Aug 7, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, deleteAPICallGenerator, callBackGenerator, formatDate, formatNumber, confirmation, showMessage, sliceFromArray} from '../../util/util';
import {Row, Col, Input, Button, Table, Collapse, CardHeader, CardBody, Modal, ModalBody,ModalHeader, Card} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import MyDropzone from './../util/my-dropzone';
import MySelect from '../util/my-select';
import {NavLink} from 'react-router-dom';
import FileSaver from 'file-saver';
import './file-storage.css';

var ua = window.navigator.userAgent;
var msie = ua.indexOf("MSIE ");

//extend FileReader
if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)){
    FileReader.prototype.readAsBinaryString = function (fileData) {
        var binary = "";
        var pt = this;
        var reader = new FileReader();
        reader.onload = function (e) {
            var bytes = new Uint8Array(reader.result);
            var length = bytes.byteLength;
            for (var i = 0; i < length; i++) {
                binary += String.fromCharCode(bytes[i]);
            }
            //pt.result  - readonly so assign content to another property
            pt.content = binary;
            pt.onload(); // thanks to @Denis comment
        }
        reader.readAsArrayBuffer(fileData);
    }
}

//initialize the state
const initialState = {
  limit: 25,
  offset: 0,
  showFolder:true,
  toUploadFiles:[],
  loading: false,
  sort: 'name',
  order: 'ASC',
  name: '',

  selectedFolder:{
    name:'',
  },
  fileUploadPopUp:false,
  addFolderPopUp:false,
  folderFilesPopUp:false,
  folderFk:0,
  failedMessage:'',
  hasMoreFiles:true,
  folderName:'',
  folderType:'public',
  folderFiles:[],
  folders:[],
  files:[],
};

//reducer function that perform state update
const reducer = getReducer();


const FileStorage  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getFolders()

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    if(state.files.length<=0 && state.hasMoreFiles){
        loadMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state]);

  //non API call but simpyl manage state
  const onFileTypeChange = (name, fileType) => {
    for(let i=0;i<state.toUploadFiles.length;i++){
      if(state.toUploadFiles[i].name===name){
        let newToUploadFiles = [];

        for(let j=0;j<state.toUploadFiles.length;j++){
          let newFile = deepCopyFileObject(state.toUploadFiles[j]);

          if(j===i)
            newFile.fileType = fileType;

          newToUploadFiles.push(newFile);
        }

        setState({toUploadFiles:newToUploadFiles});
      }
    }
  }

  const getValueByKey = (name, property) => {
    const file = state.toUploadFiles.find(item => item.name === name);
    return file ? file[property] : '';
  }

  const showFolderCard = () => {
    setState({showFolder:!state.showFolder});
  }

  const fileUploadToggle = (folderFk = 0) => {
    setState({folderFk:folderFk, fileUploadPopUp:!state.fileUploadPopUp});
  }

  const addFolderToggle = (e) => {
    e.stopPropagation();
    setState({addFolderPopUp:!state.addFolderPopUp});
  }

  const folderFilesToggle = () => {
    setState({folderFilesPopUp:!state.folderFilesPopUp});
  }

  const selectFolder = (folder, e) => {
    e.preventDefault();

    if (e.target === e.currentTarget) {
      setState({selectedFolder:folder}, getFolderFiles(folder.ID));
    }
  }

  const columnClickHandler = (col) => {
    if(state.sort===col){
      if(state.order==='ASC')
        setState({order:'DESC'});
      else
        setState({order:'ASC'});
    }
    else
      setState({sort:col,order:'ASC'});
    refreshList();
  }

  const refreshList = () => {
    setState({files:[], hasMoreFiles:true, offset:0});
    if(state.showFolder){
      showFolderCard();
    }
  }

  const renderSortIcon = (col) => {
    if(state.sort===col){
      if(state.order==='ASC')
        return <i className="red-color fa fa-arrow-down"></i>
      else
        return <i className="red-color fa fa-arrow-up"></i>
    }
  }

  //format the size to use appropiate unit KB, MB and B and round up to only 1 decimal
  const formatFileSize = (size) => {
    let intSize = parseInt(size,10);

    if(intSize>=1000000.00)
      return formatNumber(Math.round((intSize*10/1000000))/10)+' MB';
    else if(intSize>=1000)
      return formatNumber(Math.round((intSize*10/1000))/10)+' KB';
    else
      return formatNumber(intSize)+' B';
  }

  //constructing a new file object
  const deepCopyFileObject = (file) => {
    let newFile = new File([file],file.name);
    newFile.preview = file.preview;
    newFile.fileType = file.fileType;
    newFile.status = file.status;

    return newFile
  }

  //render function for infinite scroller
  const renderFiles = () => {
    return state.files.map(
      (file)=>{
        return(
          <tr key={file.ID}>
            <td><i className="fa fa-trash red-color" onClick={()=>deleteFile(file.ID, file.folder_fk)}></i></td>
            <td><NavLink to="#" onClick={()=>downloadFile(file)}>{file.name}</NavLink></td>
            <td>{formatFileSize(file.file_size)}</td>
            <td>{file.uploader_name}</td>
            <td>{formatDate(file.datetime_created)}</td>
          </tr>
        );
      }
    );
  }

  //remove a specific file from the toUpload list.
  const removeToUploadFile = (preview) => {
    let toRemoveIndex = -1;
    for(let i=0;i<state.toUploadFiles.length;i++){
      if(state.toUploadFiles[i].preview===preview){
        toRemoveIndex = i;
        break;
      }
    }

    if(toRemoveIndex!==-1){
      let newFiles = state.toUploadFiles.slice();
      newFiles.splice(toRemoveIndex,1);

      setState({toUploadFiles:newFiles});
    }
  }

  //API call
  //delete file
  const deleteFile = (ID, folderID) => {
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let newFiles = state.files.slice();

        for(let i=0;i<newFiles.length;i++){
          if(newFiles[i].ID===ID)
            newFiles.splice(i,1);
        }

        let newFolderFiles = state.folderFiles.slice();

        for(let i=0;i<newFolderFiles.length;i++){
          if(newFolderFiles[i].ID===ID)
            newFolderFiles.splice(i,1);
        }

        let newFolders = state.folders.slice();

        for(let i=0;i<newFolders.length;i++){
          if(newFolders[i].ID===folderID && newFolders[i].count>0)
            newFolders[i].count = newFolders[i].count-1;
        }

        setState({files:newFiles, folderFiles:newFolderFiles, folders:newFolders});
      }
    };

    httpDelete('file/storage/remove/'+ID,'Successfully deleted a file.','Oops, something went wrong and could not delete the file, please try again later.', callBack);
  }

  const deleteFolder = (id) => {
    let list = sliceFromArray(state.folders,'ID', id);
    let callBack = apiCallBack([{state:'folders', value:list}]);

    httpDelete('file/storage/folder/'+id, 'Folder deleted successfully.','Oops, something went wrong and could not delete the folder. Please try again later.', callBack);
  }

  const addFolder = (e) => {
    let parameters = [
      {
        field:'folderName',
        value:state.folderName
      },
      {
        field:'folderType',
        value:state.folderType
      },
    ];


    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;

      if(code==='00'){
        let newAddedFolder = response.data.data;
        let existingFolders = state.folders.slice();
        existingFolders.push(newAddedFolder);

        setState({folders:existingFolders});

        addFolderToggle(e);
        setState({folderName:'', folderType:'public'});
      }
    }

    httpPost('file/storage/folder/create', parameters, 'Added new folder successfully.', 'Oops, something went wrong and could not add new folder. Please try again later.', callBack);
  }

  const getFolders = () => {
    let callBack = apiCallBack([{state:'folders', key:'data'}]);
    httpGet('file/storage/folder/get', '', 'Oops, something went wrong and could not folders for file storage. Please try again later.', callBack);
  }

  //function that upload all toUploadFiles to the server by calling asyncPost
  const uploadAll = () => {
    let preCheck = true;
    let failedMessage = '';

    let fileTypes = [];

    for(let i =0; i<state.toUploadFiles.length;i++){
      let value = state.toUploadFiles[i].fileType;


      if(!value||value===''){
        preCheck = false;
        fileTypes.push('');
        failedMessage = '*Please select the file type for the file "'+state.toUploadFiles[i].name+'".';
      }
      else
        fileTypes.push(value);
    }

    //only proceed when no error
    if(preCheck){
      for(let i=0;i<state.toUploadFiles.length;i++){
        const reader = new FileReader();
        reader.onload = () => {
          const fileAsBinaryString = reader.result?reader.result:reader.content;
          let base64 = btoa(fileAsBinaryString);

          let callBack = (response)=>{
            let code = response.data?response.data.code:undefined;

            if(code==='00'){
              //copy the array so we maintaining the immutable state
              let newToUploadFiles = [];

              //deep copy the entire file object array
              for(let j=0;j<state.toUploadFiles.length;j++){
                let newFile;

                try{
                  newFile = deepCopyFileObject(state.toUploadFiles[j]);

                  if(newFile.name===state.toUploadFiles[i].name)
                    newFile.status='Done';


                  newToUploadFiles.push(newFile);
                }
                catch(err){
                  //IE Error , not supporting file consturctor
                }
              }

              setState({toUploadFiles:newToUploadFiles});

              //update the list of uploaded file
              let newUploadedFile = response.data.data;
              let existingUploadedFiles = state.files.slice();

              existingUploadedFiles.push(newUploadedFile);

              if(state.folderFk!==0){
                let existingUploadedFolderFiles = state.folderFiles.slice();
                existingUploadedFolderFiles.push(newUploadedFile);

                let newFolders = state.folders.slice();

                for(let i=0;i<newFolders.length;i++){
                  if(newFolders[i].ID===state.folderFk)
                    newFolders[i].count = parseInt(newFolders[i].count,10)+1;
                }

                setState({folderFiles:existingUploadedFolderFiles, folders:newFolders});
              }

              setState({files:existingUploadedFiles});
            }
          };
          callBack = callBack.bind(this);

          let parameters = [
            {
              field:'file_type',
              value:fileTypes[i]
            },
            {
              field:'name',
              value:state.toUploadFiles[i].name
            }
            ,{
              field:'file_encoded',
              value:base64
            }
          ];

          if(state.folderFk!==0){
            let tmp = {
              field:'folderFk',
              value:state.folderFk
            };

            parameters.push(tmp);
          }

          httpPost('file/storage/upload', parameters, 'File "'+state.toUploadFiles[i].name+'" uploaded successfully.', 'Oops, something went wrong and could not upload the file "'+state.toUploadFiles[i].name+'". Please try again later.', callBack);
        };
        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => {
          showMessage('error','File upload failed, please try again later.');
        };

        reader.readAsBinaryString(state.toUploadFiles[i]);
      }
    }
    else{
      setState({failedMessage:failedMessage});
    }
  }

  //function trigger for dropzone react.
  //this function contians two list of files, the accepted and rejected file per the configuration
  const onDrop = (acceptedFiles, rejectedFiles) => {
    let existingFiles = [];
    if(state.toUploadFiles){
      existingFiles = state.toUploadFiles.slice();
    }

    for(let i=0;i<acceptedFiles.length;i++){
      let file = acceptedFiles[i];
      file.status = 'Pending';
      file.fileType = '';

      let duplicate = false;
      for(let j=0;j<state.toUploadFiles.length;j++){
        if(state.toUploadFiles[j].name===acceptedFiles[i].name){
          duplicate = true;
          setState({failedMessage:'Duplicate file name "'+acceptedFiles[i].name+'"'})
        }
      }
      if(!duplicate)
        existingFiles.push(file);
    }
    setState({toUploadFiles: existingFiles});
  }

  //retrieve a list of files that ties to a specific folder
  const getFolderFiles = (folderID) => {
    let callBack = apiCallBack([{state:'folderFiles', key:'data'}], folderFilesToggle());
    httpGet('file/storage/folder/file/get/'+folderID, '', 'Oops, something went wrong and could not load files for this folder. Please try again later.', callBack);
  }

  //download the file when user click on the file name using blob
  const downloadFile = (file) => {
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let byteCharacters = atob(response.data.data);
        let byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);
        let data = new Blob([byteArray]);
        FileSaver.saveAs(data, file.name);
      }
    };

    let parameters = [
      {
        field:'name',
        value:file.name
      },
      {
        field:'directory',
        value:'Storage'
      }
    ];

    httpPost('file/storage/download', parameters, '', 'Oops, something went wrong and could not download the file "'+file.name+'". Please try again later.', callBack);
  }

  const loadMore = () => {
    //do not load if there is no more article or it's loading data
    if(state.hasMoreFiles&&!state.loading){
      setState({loading:true});

      let name = encodeURIComponent(state.name.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
      let url = 'file/storage/get/limit='+state.limit+'&offset='+state.offset+'&order='+state.order+'&sort='+state.sort+'&name='+name;

      //callback handler that update the state when http request return
      let callBack = (response)=>{
        let code = response.data?response.data.code:undefined;

        if(code!=='00'){
          setState({hasMoreFiles:false});
        }
        else{
          let newfiles = response.data.data;
          let hasMoreFiles = true;
          let newOffset = state.offset;

          //if http request return empty then no more results, end of list
          if(newfiles.length<=0){
            hasMoreFiles = false;
          }
          else{
            //increment the offset
            newOffset = state.offset + 1;
          }

          //concat the current array of article
          if(state.files.length>0){
            let temp = [...state.files,...newfiles];

            setState({files:temp});
          }
          else
            setState({files:newfiles});

          setState({hasMoreFiles:hasMoreFiles, offset:newOffset});
        }
      };
      callBack = callBack.bind(this);

      let errorCallBack = apiCallBack([{state:'hasMoreFiles', value:false}]);

      //collect the promise and wait for it to finish performing it's task
      let promises = httpGet(url, '', 'Oops, something went wrong and could not load files for the storage. Please try again later.', callBack, errorCallBack);
      promises
        .then(
          function(result){
            //set loading equals to false so the function could be fire off once again
            setState({loading:false});
          }
        );
    }
  }


  //render
  let toUploadFiles;

  if(state.toUploadFiles.length>0){
    toUploadFiles = state.toUploadFiles.map(
      (file,index)=>{
        return(
          <tr key={index}>
            <td>{file.name}</td>
            <td>
              <MySelect
                type="select"
                selectIsClearable={true}
                value={getValueByKey(file.name, 'fileType')}
                onChange={(e)=>{onFileTypeChange(file.name,e)}}
                options={[{label:"Private",value:"private"},{label:"Public",value:"public"}]}
              />
            </td>
            <td>{formatFileSize(file.size)}</td>
            <td><center>{file.status}</center></td>
            <td><center><i className="fa fa-times red-color cursor-pointer" onClick={()=>removeToUploadFile(file.preview)}></i></center></td>
          </tr>
        );
      }
    );
  }

  let folders;
  if(state.folders&&state.folders.length>0){
    folders = state.folders.map(
      (folder) =>{

        return(
          <div key={folder.ID} className="folder cursor-pointer" onClick={(e)=>selectFolder(folder, e)}>
            <Row>
              <Col sm="9">
                <b className="link-color" style={{fontSize:'17px'}}><i className="fa fa-folder"></i>&nbsp;{folder.name}</b>
              </Col>
              <Col sm="3">
                &nbsp;<i className="fa fa-ellipsis-v cursor-pointer" onClick={(e)=>{
                  confirmation(
                    ()=>{deleteFolder(folder.ID)},
                    ()=>{},
                    'Delete Folder?',
                    'Are you sure you want to delete this "'+ folder.name +'" folder?');
                }}/>
              </Col>
            </Row>
            <div style={{height:'11px'}}>
              <font style={{fontSize:'10px'}}>{formatDate(folder.datetime_created)}</font>
            </div>
            <br/><br/><br/>
            <div className='align-right'>
              <b><font style={{fontSize:'15px'}}>{folder.count} {(folder.count>1)?"files":"file"}</font></b>
            </div>
          </div>
        )
      }
    );
  }

  let folderFiles;

  if(state.folderFiles&&state.selectedFolder){
    folderFiles = state.folderFiles.map(
      (file, index)=>{
        return <tr key={file.ID}>
          <td><i className="fa fa-trash red-color" onClick={()=>deleteFile(file.ID, file.folder_fk)}></i></td>
          <td><NavLink to="#" onClick={()=>downloadFile(file)}>{file.name}</NavLink></td>
          <td>{formatFileSize(file.file_size)}</td>
          <td>{file.uploader_name}</td>
          <td>{formatDate(file.datetime_created)}</td>
        </tr>
      }
    )
  }

  return <div>
    <InfiniteScroll
      next={loadMore}
      dataLength={state.files.length}
      hasMore={state.hasMoreFiles}
      loader={<div key="nill" className="loader"><center>Loading more files...</center></div>}
      initialLoad = {true}
      className="my-well"
      scrollableTarget="contentContainer"
    >
      <Modal className="my-modal" isOpen={state.fileUploadPopUp} toggle={(e)=>fileUploadToggle()} >
        <ModalHeader hidden={true} toggle={(e)=>fileUploadToggle()}></ModalHeader>
        <ModalBody>
          <div className="align-right">
            <div className="my-divider">&nbsp;</div>
            <div>
              <MyDropzone onDrop={onDrop}/>
              <br/>
              <div className="my-divider"></div>
              <div className="ex-small-scroll-container red-color align-left">
                <b>{state.failedMessage}</b>
              </div>
              <div className="medium-scroll-container">
                <table className="table file-list-table" cellSpacing="0" cellPadding="0">
                  <thead>
                    <tr>
                      <th width="25%">Name</th>
                      <th width="20%">Type</th>
                      <th width="10%">Size</th>
                      <th width="10%"><center>Status</center></th>
                      <th width="10%">Control</th>
                    </tr>
                  </thead>
                  <tbody>
                    {toUploadFiles}
                  </tbody>
                </table>
              </div>
              <br/>

              <center>
                <Button color="warning" onClick={uploadAll}><i className="fa fa-upload"></i> Upload</Button>&nbsp;
                <Button color="info" onClick={(e)=>fileUploadToggle()}>Close</Button>
              </center>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal className="my-modal" isOpen={state.addFolderPopUp} toggle={(e)=>addFolderToggle(e)} >
        <ModalHeader hidden={true} toggle={(e)=>addFolderToggle(e)}></ModalHeader>
        <ModalBody>
          <center>
            <h5><i className="fa fa-folder"></i> Add Folder</h5>
          </center>
          <br/>
          <label>Name</label><br/>
          <Input type="text" className="form-control" value={state.folderName} onChange={(e)=>{setState({folderName:e.target.value})}}></Input>
          <br/>
          <label>Type</label><br/>
          <MySelect
            type="select"
            modal={true}
            value={state.folderType}
            selectIsClearable={true}
            onChange={(v)=>setState({folderType:v})}
            options={[{label:"Public",value:"public"},{label:"Private",value:"private"}]}
          />
          <br/><br/>
          <center>
            <Button color="warning" onClick={(e)=>addFolder(e)}>Add</Button>&nbsp;
            <Button color="info" onClick={(e)=>addFolderToggle(e)}>Close</Button>
          </center>
        </ModalBody>
      </Modal>
      <Modal className="my-modal" isOpen={state.folderFilesPopUp} toggle={folderFilesToggle} >
        <ModalHeader hidden={true} toggle={folderFilesToggle}></ModalHeader>
        <ModalBody>
          <center>
            <h5><i className="fa fa-folder"></i>&nbsp;{state.selectedFolder.name}</h5>
          </center>
          <br/>
          <div className="medium-scroll-container-no-min">
            <Table className="table table-striped">
              <thead>
                <tr>
                  <th width="3%" style={{color:'#666677'}}><i className="fa fa-list"></i></th>
                  <th width="40%" style={{color:'#666677 !important'}}>Name</th>
                  <th width="17%"><i className="fa fa-info-circle cursor-pointer"></i>&nbsp;Size </th>
                  <th width="20%"><i className="fa fa-user"></i>&nbsp;Uploader</th>
                  <th width="20%"><i className="fa fa-calendar-o"></i>&nbsp;Date Uploaded </th>
                </tr>
              </thead>
              <tbody>
                {folderFiles}
              </tbody>
            </Table>
          </div>
          <br/><br/>
          <center>
            <Button color="warning" onClick={(e)=>fileUploadToggle(state.selectedFolder.ID)}>Add Files</Button>&nbsp;
            <Button color="info" onClick={folderFilesToggle}>Close</Button>
          </center>
        </ModalBody>
      </Modal>
      <Row>
        <Col sm="6">
          <div className="page-title">
            <i className="fa fa-reorder"></i>&nbsp;File Storage
          </div>
        </Col>
        <Col sm="6">
        </Col>
      </Row>
      <Row>
        <Col sm="6">
          <label className="no-margin-bottom"><i className="fa fa-search"></i>&nbsp;Search by File Name</label>
          <Input type="text" value={state.name} onChange={(e)=>setState({name:e.target.value})}/>
        </Col>
        <Col sm="6">
          <br/>
          <div className="align-right"><Button color="warning" onClick={()=>refreshList()} >Submit</Button></div>
        </Col>
      </Row>
      <br/>
      <Row>
        <Col sm="12">
          <Card>
            <CardHeader className="header-color" onClick={(e)=>showFolderCard()}>
              <Row>
                <Col sm="10" className="align-left">
                  <i className="fa fa-folder-o"></i>&nbsp;Folders &nbsp;<i className={(state.showFolder)?"fa fa-chevron-up":"fa fa-chevron-down"}></i>
                </Col>
                <Col sm="2" className="align-right">
                  <NavLink to="#" onClick={(e)=>addFolderToggle(e)} style={{color:'white'}}><i className="fa fa-plus"></i>&nbsp;Add Folder</NavLink>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Collapse isOpen={state.showFolder}>
              <Row>
                <Col sm="12">
                  <div className="horizontal-scroll-container">
                    {folders}
                  </div>
                </Col>
              </Row>
              </Collapse>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <br/>
      <Row>
        <Col sm="12">
          <Card>
            <CardHeader className="header-color">
              <Row>
                <Col sm="10" className="align-left">
                  <i className="fa fa-file-o"></i>&nbsp;All Files
                </Col>
                <Col sm="2" className="align-right">
                  <NavLink to="#" onClick={(e)=>fileUploadToggle()} style={{color:'white'}}><i className="fa fa-plus"></i>&nbsp;Add Files</NavLink>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th width="3%"><i className="fa fa-list"></i></th>
                    <th className="cursor-pointer" width="40%" onClick={()=>columnClickHandler('name')}>Name {renderSortIcon('name')}</th>
                    <th className="cursor-pointer" width="17%" onClick={()=>columnClickHandler('file_size')}><i className="fa fa-info-circle cursor-pointer"></i>&nbsp;Size {renderSortIcon('file_size')}</th>
                    <th className="cursor-pointer" width="20%" onClick={()=>columnClickHandler('uploader_name')}><i className="fa fa-user"></i>&nbsp;Uploader{renderSortIcon('uploader_name')}</th>
                    <th className="cursor-pointer" width="20%" onClick={()=>columnClickHandler('datetime_created')}><i className="fa fa-calendar-o"></i>&nbsp;Date Uploaded {renderSortIcon('datetime_created')}</th>
                  </tr>
                </thead>
                <tbody>
                  {renderFiles()}
                </tbody>
              </table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </InfiniteScroll>
  </div>;
}


export default FileStorage;
