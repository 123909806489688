//Author Sooyoung Kim
//Date July 25, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator} from '../../util/util';
import {Button, Row, Col, Label, Input, Card, CardHeader, CardBody} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import { useParams } from 'react-router-dom';


//initialize the state
const initialState = {
  result:[],
  appraisers:[],
  loading:true
};

//reducer function that perform state update
const reducer = getReducer();


const ScrubAppraiserExclusionaryListResult  = (props)=>{
  const controller = new AbortController();
  let {id} = useParams();

  let newInitialState = Object.assign({}, initialState, {
    ID:id,
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getResult();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const isChecked = (ID) => {
    return state.appraisers.indexOf(ID)!==-1
  }

  const modifyAppraiserList = (ID) => {
    let appraisers = state.appraisers.slice();

    let index = state.appraisers.indexOf(ID);
    if(index!==-1)
      appraisers.splice(index, 1);
    else
      appraisers.push(ID);

    setState({appraisers:appraisers});
  }

  //API call
  const addToList = () => {
    let parameters = [
      {
        field:'ID',
        value:state.ID
      },
      {
        field:'appraisers',
        value:state.appraisers
      }
    ];

    httpPost('appraiser/exclusionaryList/scrub/store', parameters, 'Appraiser(s) added to exclusionary list.', 'Oops, something went wrong and could finish the request. Please try again later.');
  }

  const getResult = () => {
    let callBack = (response)=>{console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let appraisers = [];

        for(let i=0;i<response.data.data.length;i++)
          appraisers.push(response.data.data[i].hitID);

        setState({appraisers:appraisers, loading:false, result:response.data.data});
      }
    };
    callBack = callBack.bind(this);

    httpGet('appraiser/exclusionaryList/scrub/result/'+state.ID, '', 'Oops, something went wrong and could not retrieve the result.', callBack);
  }

  //render
  let result;

  if(state.result.length>0){
    let subResult = state.result.map(
      (appraiser, index)=>{
        return (
          <div key={index}>
            <Card>
              <CardHeader className="header-color">
                <Label check>
                  &nbsp;<Input checked={isChecked(appraiser.hitID)} onChange={(e)=>modifyAppraiserList(appraiser.hitID)} type="checkbox"/>
                </Label>
                &nbsp;&nbsp;{appraiser.first_name+' '+appraiser.last_name}
              </CardHeader>
              <CardBody>
                <Row>
                  <Col sm="8">
                    <label>Addres</label><br/>
                    {appraiser.street+' '+appraiser.city+', '+appraiser.state+' '+appraiser.zip}
                  </Col>
                  <Col sm="4">
                    <label>License Type</label><br/>
                    {appraiser.license_type}
                  </Col>
                </Row>
                <Row>
                  <Col sm="4">
                    <label>Phone</label><br/>
                    {appraiser.phone}
                  </Col>
                  <Col sm="4">
                    <label>Email</label><br/>
                    {appraiser.email}
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <br/><br/>
          </div>
        );
      }
    );

    result = <div>

        <Row>
          <Col sm="8">
            <label>* Add the appraiser(s) selected below to exclusionary list.</label>
          </Col>
          <Col sm="4">
            <div className="align-right">
              <Button color="warning" onClick={addToList}>Submit</Button>
            </div>
          </Col>
        </Row>
        <br/>
        {subResult}
    </div>
  }
  else if(!state.loading){

    result = <div>
      <label>There is not hit against our database with the spreadsheet you provided.</label>
    </div>
  }
  
  return <div className="padding">
    <div className="page-title">
      <i className="fa fa-reorder"></i>&nbsp;Appraisers Exclusionary List - Scrub New Spreadsheet
    </div>
    <div className="my-divider"></div>
    {result}
  </div>;
}


export default ScrubAppraiserExclusionaryListResult;
