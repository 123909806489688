//Author author
//Date date
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, formatDate, formatNumber, getFromTo, replaceRegex} from '../../../util/util';
import {Button, Card, CardHeader, CardBody, Col, Row, Modal, ModalHeader, ModalBody} from 'reactstrap';
import React, {useReducer, useEffect} from 'react';
import MyReactTable from '../../util/my-react-table';
import ReportFilter from '../report-filter';
import ReactExport from "react-export-excel";
import {NavLink, useParams} from 'react-router-dom';
import moment from 'moment';
import MyChart from '../../util/my-chart';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

let defaultfrom = getFromTo('from');
let defaultto = getFromTo('to');

//initialize the state
const initialState = {
  reportType:'',
  selectedAppraisalType:'',
  report:[],
  detailsPopUpOpen:false,
  weeklyReportPopUpOpen:false,
  existingWeeklyReportPopUpOpen:false,
  type:'total_order',
  reportDetails:[],

  existingWeeklyReport:[],
  weeklyReportPeriod:'1m',

  simplify:true
};

//reducer function that perform state update
const reducer = getReducer();


const AppraiserQualityMetrics  = (props)=>{
  const controller = new AbortController();
  let {params} = useParams();

  let filterAppraisalTypes = [];
  let stateName = '';
  let county = '';
  let city = '';
  let client = '';
  let openBy = '';
  let from = defaultfrom;
  let to = defaultto;
  let isRush = '';
  let isComplex = '';
  let loanPurpose = '';
  let loanType = '';
  let appraiser = '';
  let appraiserName = '';
  let entity = '';

  if(params&&params!==''){
    params = params.split('&');

    for(let i=0;i<params.length;i++){
      let keyPair = params[i].split('=');
      switch(keyPair[0]){
      case 'from':
        from = keyPair[1];
        break;
      case 'to':
        to = keyPair[1];
        break;
      case 'client':
        client = keyPair[1];
        break;
      case 'openBy':
        openBy = keyPair[1];
        break;
      case 'rush':
        isRush = keyPair[1];
        break;
      case 'complex':
        isComplex = keyPair[1];
        break;
      case 'state':
        stateName = keyPair[1];
        break;
      case 'county':
        county = keyPair[1];
        break;
      case 'city':
        city = keyPair[1];
        break;
      case 'loanPurpose':
        keyPair[1] = keyPair[1].replace(new RegExp('fslash','g'),'/');
        loanPurpose = keyPair[1];
        break;
      case 'loanType':
        loanType = keyPair[1];
        break;
      case 'entity':
        entity = keyPair[1];
        break;
      case 'appraisalTypes':
        keyPair[1] = keyPair[1].replace(new RegExp('fslash','g'),'/');
        keyPair[1] = keyPair[1].replace(new RegExp('[+]','g'),'plus');
        let appraisalTypes = keyPair[1].split('|');
        filterAppraisalTypes = appraisalTypes;
        break;
      case 'appraiser':
        if(keyPair[1]&&keyPair[1]!==''){
        let token = keyPair[1].split('|');
        if(token.length>=2){
          appraiser = token[0];
          appraiserName = token[1];
        }else{
          appraiser = '';
          appraiserName = '';
        }
        }else{
        appraiser = '';
        appraiserName = '';
        }
        break;
      default:
        break;
      }
    }
  }

  let newInitialState = Object.assign({}, initialState, {
    filterAppraisalTypes:filterAppraisalTypes,
    from:from,
    to:to,
    client:client,
    openBy:openBy,
    isRush: isRush,
    isComplex: isComplex,
    county:county,
    city:city,
    state:stateName,
    loanPurpose:loanPurpose,
    loanType:loanType,
    entity:entity,
    appraiser:appraiser,
    appraiserName:appraiserName,
  });

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const reportDetailsToggle = ()=>{
    setState({reportDetails:[], detailsPopUpOpen:!state.detailsPopUpOpen});
  }

  //API call
  const getReport = ()=>{
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);
  
    let url = 'report/appraiserQualityMetrics/county='+state.county+'&client='+state.client+'&openBy='+state.openBy+'&appraiser='+state.appraiser+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&appraisalType='+appraisalType+'&status='+state.status;
  
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let report = response.data.data;
    
        let total = 0;
        for(let i=0;i<report.length;i++){
          total = total + report[i].cu_score_4 + report[i].cu_score_5;
        }
    
        report['cu_score_4_5'] = total;
        setState({report:response.data.data});
      }
    };
    callBack = callBack.bind(this);

    httpGet(url, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  const getReportDetails = (appraiserFk, type)=>{
    setState({reportType:type});
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);

    if(type==='cu_score_4+cu_score_5'){
      let reportDetails = [];
      let url;

      let callBack = (response)=>{
        let code = response.data?response.data.code:undefined;
        if(code==='00'){
          for(let i=0;i<response.data.data.length;i++){
            reportDetails.push(response.data.data[i]);
          }
        }
      };

      callBack = callBack.bind(this);

      url = 'report/appraiserQualityMetrics/details/county='+state.county+'&client='+state.client+'&openBy='+state.openBy+'&appraiser='+appraiserFk+'&type=cu_score_4&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&appraisalType='+appraisalType+'&status='+state.status;
      httpGet(url, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);

      callBack = (response)=>{
        let code = response.data?response.data.code:undefined;
        if(code==='00'){
          for(let i=0;i<response.data.data.length;i++){
            reportDetails.push(response.data.data[i]);
          }
          setState({reportDetails:reportDetails, type:'cu_score_4+cu_score_5'});
        }
      };
      callBack = callBack.bind(this);

      url = 'report/appraiserQualityMetrics/details/county='+state.county+'&client='+state.client+'&openBy='+state.openBy+'&appraiser='+appraiserFk+'&type=cu_score_5&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&appraisalType='+appraisalType+'&status='+state.status;
      httpGet(url, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
    }
    else{
      let url ='report/appraiserQualityMetrics/details/county='+state.county+'&client='+state.client+'&openBy='+state.openBy+'&appraiser='+appraiserFk+'&type='+type+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&appraisalType='+appraisalType+'&status='+state.status;
      let callBack = apiCallBack([{state:'type', value:type},{state:'reportDetails', key:'data'}]);
      httpGet(url, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
    }
  }

  //render
  let newfrom, newto;
  if(state.from!=='')
    newfrom = moment(state.from);
  if(state.to!=='')
    newto = moment(state.to);

  let columnsDetails;

  switch(state.type){
    case 'avg_rebuttal_rate':
    case 'value_opinion_changed_rate':
    case 'avg_redo_rate':
      columnsDetails = [
        {
          id: 'referenceNumber',
          Header: 'Reference #',
          accessor: d => d.reference_num,
          Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
          headerStyle: {
            textAlign:'left'
          }
        },
        {
          id: 'loanNum',
          Header: 'Loan #',
          accessor: d => d.loan_num,
          headerStyle: {
            textAlign:'left'
          }
        },
        {
          id: 'borrower',
          Header: 'Borrower',
          accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
          headerStyle: {
            textAlign:'left'
          }
        },
        {
          id: 'property',
          Header: 'Property',
          accessor: d => d.property_street+' '+d.property_city+', '+d.property_state,
          headerStyle: {
            textAlign:'left'
          }
        },
        {
          id: 'dateSubmitted',
          Header: 'Submitted',
          accessor: d => formatDate(d.datetime_submitted),
          headerStyle: {
            textAlign:'left'
          }
        },
      ];
      break;
    case 'avg_internal_condition_rate':
    case 'avg_external_condition_rate':
      columnsDetails = [
        {
          id: 'referenceNumber',
          Header: 'Reference #',
          accessor: d => d.reference_num,
          Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
          headerStyle: {
            textAlign:'left'
          }
        },
        {
          id: 'totalCondition',
          Header: 'Total Condition',
          accessor: d => d.total_condition,
          headerStyle: {
            textAlign:'left'
          }
        },
        {
          id: 'loanNum',
          Header: 'Loan #',
          accessor: d => d.loan_num,
          headerStyle: {
            textAlign:'left'
          }
        },
        {
          id: 'borrower',
          Header: 'Borrower',
          accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
          headerStyle: {
            textAlign:'left'
          }
        },
        {
          id: 'property',
          Header: 'Property',
          accessor: d => d.property_street+' '+d.property_city+', '+d.property_state,
          headerStyle: {
            textAlign:'left'
          }
        },
        {
          id: 'dateSubmitted',
          Header: 'Submitted',
          accessor: d => formatDate(d.datetime_submitted),
          headerStyle: {
            textAlign:'left'
          }
        },
      ];
      break;
    case 'cu_score_4+cu_score_5':
      columnsDetails = [
        {
          id: 'score',
          Header: 'Score',
          accessor: d => d.score,
          headerStyle: {
            textAlign:'left'
          }
        },
        {
          id: 'referenceNumber',
          Header: 'Reference #',
          accessor: d => d.reference_num,
          Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
          headerStyle: {
            textAlign:'left'
          }
        },
        {
          id: 'loanNum',
          Header: 'Loan #',
          accessor: d => d.loan_num,
          headerStyle: {
            textAlign:'left'
          }
        },
        {
          id: 'borrower',
          Header: 'Borrower',
          accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
          headerStyle: {
            textAlign:'left'
          }
        },
        {
          id: 'property',
          Header: 'Property',
          accessor: d => d.property_street+' '+d.property_city+', '+d.property_state,
          headerStyle: {
            textAlign:'left'
          }
        },
        {
          id: 'dateSubmitted',
          Header: 'Submitted',
          accessor: d => formatDate(d.datetime_submitted),
          headerStyle: {
            textAlign:'left'
          }
        },
      ];
      break;
    case 'total_order':
    case 'cu_score_1':
    case 'cu_score_2':
    case 'cu_score_3':
    case 'cu_score_4':
    case 'cu_score_5':
      columnsDetails = [
        {
        id: 'referenceNumber',
        Header: 'Reference #',
        accessor: d => d.reference_num,
        Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.ID}>{props.row.original.reference_num}</NavLink>,
        headerStyle: {
          textAlign:'left'
        }
        },
        {
        id: 'loanNum',
        Header: 'Loan #',
        accessor: d => d.loan_num,
        headerStyle: {
          textAlign:'left'
        }
        },
        {
        id: 'borrower',
        Header: 'Borrower',
        accessor: d => d.borrower_f_name+' '+d.borrower_l_name,
        headerStyle: {
          textAlign:'left'
        }
        },
        {
        id: 'property',
        Header: 'Property',
        accessor: d => d.property_street+' '+d.property_city+', '+d.property_state,
        headerStyle: {
          textAlign:'left'
        }
        },
        {
        id: 'dateSubmitted',
        Header: 'Submitted',
        accessor: d => formatDate(d.datetime_submitted),
        headerStyle: {
          textAlign:'left'
        }
        },
      ];
      break;
    default:
      break;
  }

  console.log(state.reportDetails);

  let excelFile = <ExcelFile element={<Button disabled={state.report.length<=0} color="warning">Download Excel</Button>}>
    <ExcelSheet data={state.report} name="XLSX">
      <ExcelColumn label="Appraiser" value="name"/>
      <ExcelColumn label="Redo Rate" value="avg_redo_rate"/>
      <ExcelColumn label="Rebuttal Rate" value="avg_rebuttal_rate"/>
      <ExcelColumn label="Value Opinion Change Rate" value="value_opinion_changed_rate"/>
      <ExcelColumn label="Internal Condition Rate" value="avg_internal_condition_rate"/>
      <ExcelColumn label="External Condition Rate" value="avg_external_condition_rate"/>
      <ExcelColumn label="CU Score >=4" value="cu_score_4_5"/>
      <ExcelColumn label="Total Order" value="total_order"/>
    </ExcelSheet>
  </ExcelFile>;

  let columns = [
    {
      id: 'appraiserID',
      Header: 'Appraiser',
      accessor: d => d.ID,
      Cell: props => <NavLink target="_blank" to={"/appraiser/"+props.row.original.ID}>{props.row.original.name}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'avg_redo_rate',
      Header: 'Redo Rate',
      sortMethod: (a, b) => Number(a)-Number(b),
      accessor: d => Number(d.avg_redo_rate).toFixed(2),
      Cell: props => <NavLink to="#" onClick={()=>{reportDetailsToggle();getReportDetails(props.row.original.ID, 'avg_redo_rate')}}>{props.row.original.avg_redo_rate+'% on '+props.row.original.total_order} orders</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'avg_rebuttal_rate',
      Header: 'Rebuttal Rate',
      sortMethod: (a, b) => Number(a)-Number(b),
      accessor: d => Number(d.avg_rebuttal_rate).toFixed(2),
      Cell: props => <NavLink to="#" onClick={()=>{reportDetailsToggle();getReportDetails(props.row.original.ID, 'avg_rebuttal_rate')}}>{props.row.original.avg_rebuttal_rate+'% on '+props.row.original.total_order} orders </NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'value_opinion_changed_rate',
      Header: 'Value Opinion Changed Rate',
      sortMethod: (a, b) => Number(a)-Number(b),
      accessor: d => Number(d.value_opinion_changed_rate).toFixed(2),
      Cell: props => <NavLink to="#" onClick={()=>{reportDetailsToggle();getReportDetails(props.row.original.ID, 'value_opinion_changed_rate')}}>{props.row.original.value_opinion_changed_rate+'% on '+props.row.original.total_rebuttal} rebuttals</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'internalConditionRate',
      Header: 'Internal Condition Rate',
      sortMethod: (a, b) => Number(a)-Number(b),
      accessor: d => Number(d.avg_internal_condition_rate).toFixed(2),
      Cell: props => <NavLink to="#" onClick={()=>{reportDetailsToggle();getReportDetails(props.row.original.ID, 'avg_internal_condition_rate')}}>{props.row.original.avg_internal_condition_rate+'%'}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'externalConditionRate',
      Header: 'External Condition Rate',
      sortMethod: (a, b) => Number(a)-Number(b),
      accessor: d => Number(d.avg_external_condition_rate).toFixed(2),
      Cell: props => <NavLink to="#" onClick={()=>{reportDetailsToggle();getReportDetails(props.row.original.ID, 'avg_external_condition_rate')}}>{props.row.original.avg_external_condition_rate+'%'}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'cuScore45',
      Header: 'CU Score >=4',
      sortMethod: (a, b) => Number(a)-Number(b),
      accessor: d => Number(d.cu_score_4+d.cu_score_5).toFixed(2),
      Cell: props => <NavLink to="#" onClick={()=>{reportDetailsToggle();getReportDetails(props.row.original.ID, 'cu_score_4+cu_score_5')}}>{props.row.original.cu_score_4+props.row.original.cu_score_5}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'orderCount',
      Header: 'Order Count',
      sortMethod: (a, b) => Number(a)-Number(b),
      accessor: d => Number(d.cu_score_4+d.total_order).toFixed(2),
      Cell: props => <NavLink to="#" onClick={()=>{reportDetailsToggle();getReportDetails(props.row.original.ID, 'total_order')}}>{props.row.original.total_order}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
  ];

  if(!state.simplify){
    excelFile = <ExcelFile element={<Button disabled={state.report.length<=0} color="warning">Download Excel</Button>}>
    <ExcelSheet data={state.report} name="XLSX">
      <ExcelColumn label="Appraiser" value="name"/>
      <ExcelColumn label="Condition Score" value="avg_condition_score"/>
      <ExcelColumn label="Rebuttal Score" value="avg_rebuttal_score"/>
      <ExcelColumn label="Internal Condition Rate" value="avg_internal_condition_rate"/>
      <ExcelColumn label="External Condition Rate" value="avg_external_condition_rate"/>
      <ExcelColumn label="CU Score 1" value="cu_score_1"/>
      <ExcelColumn label="CU Score 2" value="cu_score_2"/>
      <ExcelColumn label="CU Score 3" value="cu_score_3"/>
      <ExcelColumn label="CU Score 4" value="cu_score_4"/>
      <ExcelColumn label="CU Score 5" value="cu_score_5"/>
      <ExcelColumn label="Total Order" value="total_order"/>
    </ExcelSheet>
    </ExcelFile>;

    columns = [
    {
      id: 'appraiserID',
      Header: 'Appraiser',
      accessor: d => d.ID,
      Cell: props => <NavLink target="_blank" to={"/appraiser/"+props.row.original.ID}>{props.row.original.name}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'avgConditionScore',
      Header: 'Condition Score',
      accessor: d => d.avg_condition_score,
      Cell: props => props.row.original.avg_condition_score===-1?'Not available': <NavLink to="#" onClick={()=>{reportDetailsToggle();getReportDetails(props.row.original.ID, 'avg_condition_score')}}>{props.row.original.avg_condition_score+'/5.00'}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'avgRebuttalScore',
      Header: 'Rebuttal Score',
      accessor: d => d.avg_rebuttal_score,
      Cell: props => props.row.original.avg_rebuttal_score===-1?'Not available': <NavLink to="#" onClick={()=>{reportDetailsToggle();getReportDetails(props.row.original.ID, 'avg_rebuttal_score')}}>{props.row.original.avg_rebuttal_score+'/5.00'}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'internalConditionRate',
      Header: 'Internal Condition Rate',
      accessor: d => d.avg_internal_condition_rate,
      Cell: props => <NavLink to="#" onClick={()=>{reportDetailsToggle();getReportDetails(props.row.original.ID, 'avg_internal_condition_rate')}}>{props.row.original.avg_internal_condition_rate+'%'}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'externalConditionRate',
      Header: 'External Condition Rate',
      accessor: d => d.avg_external_condition_rate,
      Cell: props => <NavLink to="#" onClick={()=>{reportDetailsToggle();getReportDetails(props.row.original.ID, 'avg_external_condition_rate')}}>{props.row.original.avg_external_condition_rate+'%'}</NavLink>,
      headerStyle: {
       textAlign:'left'
      }
    },
    {
      id: 'cuScore1',
      Header: 'CU Score ≤1',
      accessor: d => d.cu_score_1,
      Cell: props => <NavLink to="#" onClick={()=>{reportDetailsToggle();getReportDetails(props.row.original.ID, 'cu_score_1')}}>{props.row.original.cu_score_1}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'cuScore2',
      Header: 'CU Score ≤2',
      accessor: d => d.cu_score_2,
      Cell: props => <NavLink to="#" onClick={()=>{reportDetailsToggle();getReportDetails(props.row.original.ID, 'cu_score_2')}}>{props.row.original.cu_score_2}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'cuScore3',
      Header: 'CU Score ≤3',
      accessor: d => d.cu_score_3,
      Cell: props => <NavLink to="#" onClick={()=>{reportDetailsToggle();getReportDetails(props.row.original.ID, 'cu_score_3')}}>{props.row.original.cu_score_3}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'cuScore4',
      Header: 'CU Score ≤4',
      accessor: d => d.cu_score_4,
      Cell: props => <NavLink to="#" onClick={()=>{reportDetailsToggle();getReportDetails(props.row.original.ID, 'cu_score_4')}}>{props.row.original.cu_score_4}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'cuScore5',
      Header: 'CU Score ≤5',
      accessor: d => d.cu_score_5,
      Cell: props => <NavLink to="#" onClick={()=>{reportDetailsToggle();getReportDetails(props.row.original.ID, 'cu_score_5')}}>{props.row.original.cu_score_5}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'orderCount',
      Header: 'Order Count',
      accessor: d => d.total_order,
      Cell: props => <NavLink to="#" onClick={()=>{reportDetailsToggle();getReportDetails(props.row.original.ID, 'total_order')}}>{props.row.original.total_order}</NavLink>,
      headerStyle: {
       textAlign:'left'
      }
    },
    ];
  }

  let chart;

  if(state.reportType==='avg_internal_condition_rate' || state.reportType==='avg_external_condition_rate'){
    let allConditions = {};

    for(let i=0;i<state.reportDetails.length;i++){
      let conditions = state.reportDetails[i].conditions.slice();

      for(let j=0;j<conditions.length;j++){
        if(!allConditions[conditions[j].type])
        allConditions[conditions[j].type] = 0;
        allConditions[conditions[j].type]++;
      }
    }

    let data = [];
    Object.keys(allConditions).forEach((key)=>{
      let tmp = {};
      tmp.name = key;
      tmp.y = allConditions[key];

      data.push(tmp);
    })


    const config ={
    chart: {
      type: 'pie',
      height:300
    },
    title: {
      text: null
    },
    subtitle: {
      text: null
    },
    plotOptions: {
      series: {
      dataLabels: {
        enabled: true,
        format: '{point.name}: {point.percentage:.1f}% - {point.y}'
      },
      showInLegend: true
      }
    },

    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.percentage:.1f}% - {point.y}</b> of total<br/>'
    },
    series: [{
      name: 'Condition Type',
      colorByPoint: true,
      data: data
    }],
    credits:{
      enabled:false
    },
    legend:{
      align: 'right',
      layout: 'vertical',
      verticalAlign: 'top',
      x: 340,
      y: 0,
      itemStyle:{
      fontSize:13
      },
      labelFormatter: function () {
      return this.name+' - '+formatNumber(this.series.yData[this.index]);
      }
    }
    };
    if(data.length>0)
    chart = <MyChart config={config}/>;
  }

  let reportFiltersConfig = [
    {id:'from',value:newfrom, updateFunc:(v)=>setState({from:v}), width:'2'},
    {id:'to',value:newto, updateFunc:(v)=>setState({to:v}), width:'2'},
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'4'},
    {id:'openBy',value:state.openBy, updateFunc:(v)=>setState({openBy:v}), width:'4'},
    {id:'customSelect',value:state.status, updateFunc:(v)=>setState({status:v}), width:'3', label:'Order Status', clearable:false, className:"", options:[
      {label:"Completed",value:"Completed"},{label:"Conditioned",value:"Conditioned"}
    ]},
    {id:'appraiser',value:state.appraiser, updateFunc:(v)=>setState({appraiser:v}), width:'3'},
    {id:'rush',value:state.isRush, updateFunc:(v)=>setState({isRush:v}), width:'3'},
    {id:'complex',value:state.isComplex, updateFunc:(v)=>setState({isComplex:v}), width:'3'},
    {id:'state',value:state.state, updateFunc:(v)=>setState({state:v}), width:'3'},
    {id:'county',value:state.county, updateFunc:(v)=>setState({county:v}), width:'3'},
    {id:'city',value:state.city, updateFunc:(v)=>setState({city:v}), width:'2'},
    {id:'loanPurpose',value:state.loanPurpose, updateFunc:(v)=>setState({loanPurpose:v}), width:'2'},
    {id:'loanType',value:state.loanType, updateFunc:(v)=>setState({loanType:v}), width:'2'},
    {id:'entity',value:state.entity, updateFunc:(v)=>setState({entity:v}), width:'4'},
    {id:'appraisalType',value:state.filterAppraisalTypes, updateFunc:(v)=>setState({filterAppraisalTypes:v}), width:'4'},
    {id:'button',value:'Submit', updateFunc:getReport, width:'4', className:"align-right", color:"warning"},
  ];

  return <div>
    <Card>
      <CardHeader className="header-color">
      <i className="fa fa-reorder"></i>&nbsp;Appraiser Quality Metrics
      </CardHeader>
      <CardBody>
      <ReportFilter {...props} configs={reportFiltersConfig}/>
      </CardBody>
    </Card>
    <br/>
    <Card>
      <CardHeader className="header-color">
      <Row>
        <Col sm="8">
        <i className="fa fa-reorder"></i>&nbsp;Appraiser Quality Metrics
        </Col>
        <Col sm="4">
        <div className="align-right">
          {excelFile}
        </div>
        </Col>
      </Row>
      </CardHeader>
      <CardBody>
      <div className="align-right">
        <NavLink to="#" onClick={(e)=>setState({simplify:!state.simplify})}>{state.simplify?"Show All CU Score":"Simplify CU Score"}</NavLink>
      </div>
      <br/>
      <MyReactTable columns={columns} data={state.report} className="table table-striped"/>
      </CardBody>
    </Card>

    <Modal className="my-modal-wide" isOpen={state.detailsPopUpOpen} toggle={reportDetailsToggle} >
      <ModalHeader hidden={true} toggle={reportDetailsToggle}></ModalHeader>
      <ModalBody>
      <center>
        <h5><i className="fa fa-user"></i> Report Details</h5>
      </center>
      <br/>
      {chart}
      <br/>
      <div className="large-scroll-container">
        <MyReactTable columns={columnsDetails} data={state.reportDetails} className="table table-striped"/>
      </div>
      <br/>
      <center>
        <Button color="info" onClick={reportDetailsToggle}>Close</Button>
      </center>
      </ModalBody>
    </Modal>
  </div>;
}


export default AppraiserQualityMetrics;
