//Author June Leow
//Date Oct 9th, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, deleteAPICallGenerator, callBackGenerator} from '../../util/util';
import {Card, CardHeader, CardBody, Label, FormGroup, Table, Row, Col, Collapse, Input, Modal, ModalHeader, ModalBody, Nav, NavItem, TabContent, TabPane, Button,NavLink as NavLinkL} from 'reactstrap';
import {confirmation, showMessage, formatDateTime, sliceFromArray} from '../../util/util';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import MySelect from '../util/my-select';
import {NavLink} from 'react-router-dom';
import Toggle from 'react-toggle';
import React, {useReducer, useEffect} from 'react';

//initialize the state
const initialState = {
  hotfileWatched:false,
  globalHotfileWatched:false,
  nuclearfileWatched:false,
  manualReviewEnabled:false,
  internalEscalationEnabled:false,
  expediteEnabled:false,
  autoAssignEnabled:false,
  hotfileSetReminder:false,
  hotfileSetReminderSelf:false,
  snoozeHotfileOption:false,
  snoozeHotfileDropDownOpen:false,
  haltAutoBidOnUpdate:false,
  hotfileReminderEveryHour:2,
  hotfileReminderSnooze:1,
  hotFileUserFk:'',
  hotfile:{
    subCategory:'Other'
  },
  globalHotfile:{
    sub_category:'Other'
  },
  nuclearfile:{
    sub_category:'Other'
  },
  manualReview:{},
  internalEscalation:{},
  expedite:{},
  autoAssign:{},
  hotfileDropDownOpen:false,
  globalHotfileDropDownOpen:false,
  nuclearfileDropDownOpen:false,
  manualReviewDropDownOpen:false,
  internalEscalationDropDownOpen:false,
  expediteDropDownOpen:false,
  moreActionDropDownOpen:false,
  moreActionChoice:'1',
  moreActionOrderStatus:'',
  moreActionMessage:'',
  globalHotfileActiveTab:'1',
  globalDateSnooze:'',
  hotfileSubCategory:[],
  nuclearfileSubCategory:[],
  statuses:[],
  accountManagers:[],
  admins:[],
  smsNotifications:[],
  globalHotfileSubmit:false,
  internalEscalationfileSubmit:false,
  expeditefileSubmit:false,
  manualReviewSubmit:false,

  
  manualReviewHistories:[],
  nuclearHistories:[],
  personalHotfileHistories:[],
  globalHotfileHistories:[],
  internalEscalationHistories:[],
  expediteHistories:[],

  crm:{},
  crmEnabled:false,
  crmDropDownOpen:false,
  crmSubmit:false,
  crmHistories:[],
  showCRMModify:false
};

//reducer function that perform state update
const reducer = getReducer();


const AppraisalActions  = (props)=>{
  const controller = new AbortController();
  const newInitialState = Object.assign({}, initialState, {appraisal:props.appraisal});
  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getHotfile(props.appraisal.ID);
    getGlobalHotfile(props.appraisal.ID);
    getHotfileSubCategory();
    getNuclearfileSubCategory();
    getNuclearfile(props.appraisal.ID);
    getAutoBidHalt(props.appraisal.ID);
    getManualReview(props.appraisal.ID);
    getCRM(props.appraisal.ID);
    getGlobalSnooze(props.appraisal.ID);

    getExpedite(props.appraisal.ID);
    getInternalEscalation(props.appraisal.ID);
    getAutoAssign(props.appraisal.ID);
    getAllAccountManagers();
    getAllAdmins();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{ 
    //reload all nuclear, hotfile, expedite and escalation
    getNuclearfile(props.appraisal.ID);
    getGlobalHotfile(props.appraisal.ID);
    getExpedite(props.appraisal.ID);
    getInternalEscalation(props.appraisal.ID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[props.appraisal.priority])

  useEffect(()=>{
    if(state.globalHotfileSubmit){
      addToGlobalHotfile();
      setState({globalHotfileSubmit:false});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state.globalHotfileSubmit])

  useEffect(()=>{
    if(state.personalHotfileSubmit){
      addToHotfile();
      setState({personalHotfileSubmit:false});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state.personalHotfileSubmit])
 
  useEffect(()=>{
    if(state.internalEscalationfileSubmit){
      addToInternalEscalation();
      setState({internalEscalationfileSubmit:false});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state.internalEscalationfileSubmit])

  useEffect(()=>{
    if(state.expeditefileSubmit){
      addToExpedite();
      setState({expeditefileSubmit:false});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state.expeditefileSubmit])

  useEffect(()=>{
    if(state.nuclearfileSubmit){
      addToNuclearfile();
      setState({nuclearfileSubmit:false});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state.nuclearfileSubmit])

  useEffect(()=>{
    if(state.manualReviewSubmit){
      addToManualReview();
      setState({manualReviewSubmit:false});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state.manualReviewSubmit])

  useEffect(()=>{
    if(state.crmSubmit){
      addToCRM();
      setState({crmSubmit:false});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state.crmSubmit])

  //non API call but simpyl manage state
  const globalHotfileButtonHandler = ()=>{
    if(state.globalHotfileSubmit)
      return;
    setState({globalHotfileSubmit:true});
  }

  const toggleglobalHotfile = ()=>{
    if(!state.globalHotfileDropDownOpen){
      setState({showGlobalHotfileModify:false});
    }

    setState({globalHotfileDropDownOpen:!state.globalHotfileDropDownOpen});
  }

  const snoozeHotfileToggle = ()=>{
    setState({snoozeHotfileDropDownOpen:!state.snoozeHotfileDropDownOpen});
  }

  const modifyGlobalHotfile = ()=>{
    setState({showGlobalHotfileModify:!state.showGlobalHotfileModify});
  }

  const modifyManualReview = ()=>{
    setState({showManualReviewModify:!state.showManualReviewModify});
  }

  const modifyCRM = ()=>{
    setState({showCRMModify:!state.showCRMModify});
  }

  const modifyNuclearfile = ()=>{
    setState({showNuclearfileModify:!state.showNuclearfileModify});
  }

  const internalEscalationButtonHandler = ()=>{
    if(state.internalEscalationfileSubmit){
      return;
    }

    setState({internalEscalationfileSubmit:true});
  }

  const expediteButtonHandler = ()=>{
    if(state.expeditefileSubmit){
      return;
    }

    setState({expeditefileSubmit:true});
  }

  const modifyInternalEscalation = ()=>{
    setState({showInternalEscalationfileModify:!state.showInternalEscalationfileModify});
  }

  const modifyExpedite = ()=>{
    setState({showExpeditefileModify:!state.showExpeditefileModify});
  }

  const nuclearfileButtonHandler = ()=>{
    if(state.nuclearfileSubmit){
      return;
    }

    setState({nuclearfileSubmit:true});
  }

  const manualReviewButtonHandler = ()=>{
    if(state.manualReviewSubmit){
      return;
    }

    setState({manualReviewSubmit:true});
  }

  const crmButtonHandler = ()=>{
    if(state.crmSubmit){
      return;
    }

    setState({crmSubmit:true});
  }

  const toggleModificationLog = ()=>{
    setState({showModificationLog:!state.showModificationLog});
  }

  const selectMoreAction = (choice)=>{
    setState({moreActionChoice:choice});
  }

  const toggleMoreAction = ()=>{
    if(state.statuses.length<=0)
      getAllOrderStatuses();
    setState({moreActionDropDownOpen:!state.moreActionDropDownOpen});
  }

  const toggleNuclearfile = ()=>{
    if(!state.nuclearfileDropDownOpen){
      setState({showNuclearfileModify:false});
    }

    setState({nuclearfileDropDownOpen:!state.nuclearfileDropDownOpen});
  }

  const handleCRMChange = (e)=>{
    if(!state.crmDropDownOpen){
      setState({showCRMModify:false});
    }

    setState({crmDropDownOpen:!state.crmDropDownOpen});
  }

  const handleManualReviewChange = (e)=>{
    if(!state.manualReviewDropDownOpen){
      setState({showManualReviewModify:false});
    }

    setState({manualReviewDropDownOpen:!state.manualReviewDropDownOpen});
  }

  const handleAutoBidChange=()=>{
    if(state.autoBidHalt)
      removeAutoBidHalt();
    else
      haltAutoBid();
  }

  const toggleManualReview = ()=>{
    setState({manualReviewDropDownOpen:!state.manualReviewDropDownOpen});
  }

  const toggleCRM = ()=>{
    setState({crmDropDownOpen:!state.crmDropDownOpen});
  }

  const toggleSMSNotification = ()=>{
    if(!state.showSmsNotifications && state.smsNotifications.length<=0)
      getSMSNotifications();
    setState({showSmsNotifications:!state.showSmsNotifications});
  }


  const toggleInternalEscalation = ()=>{
    if(!state.internalEscalationDropDownOpen){
      setState({showInternalEscalationfileModify:false});
    }

    setState({internalEscalationDropDownOpen:!state.internalEscalationDropDownOpen});
  }

  const handleInternalEscalation = ()=>{
    if(state.internalEscalationEnabled){
      toggleInternalEscalation();
    }
    else{
      if(state.nuclearfileWatched)
        showMessage('warning','This order is under Nuclear file. Fail to add to escalate.');
      else
        toggleInternalEscalation();
    }
  }

  const handleExpedite = ()=>{
    if(state.expediteEnabled){
      toggleExpedite();
    }
    else{
      if(state.nuclearfileWatched)
        showMessage('warning','This order is under Nuclear file. Fail to add to expedite.');
      else if(state.globalHotfileWatched)
        showMessage('warning','This order is under Global Hotfile. Fail to add to expedite.');
      else if(state.internalEscalationEnabled)
        showMessage('warning','This order is under Escalation. Fail to add to expedite.');
      else
        toggleExpedite();
    }
  }

  const toggleExpedite = ()=>{
    if(!state.expediteDropDownOpen){
      setState({showExpeditefileModify:false});
    }

    setState({expediteDropDownOpen:!state.expediteDropDownOpen});
  }

  const handleHotfileChange = ()=>{
    if(state.hotfileWatched){
      removeHotfile();
    }
    else{
      toggleHotfile();
    }
  }

  const toggleHotfile = ()=>{
    setState({hotfileDropDownOpen:!state.hotfileDropDownOpen});
  }

  const handleglobalHotfileChange = ()=>{
    if(state.nuclearfileWatched){
      showMessage('warning','This order is under Nuclear file. Fail to add to global hotfile.');
    }
    else if(state.internalEscalationEnabled){
      showMessage('warning','This order is under Escalation. Fail to add to global hotfile.');
    }else{
      toggleglobalHotfile();
    }
  }

  const handleNuclearfileChange = ()=>{
    toggleNuclearfile();
  }

  const handleHotfileReminderChange = ()=>{
    setState({hotfileSetReminder:!state.hotfileSetReminder});
  }

  const handleHotfileReminderSelfChange = ()=>{
    setState({hotfileSetReminderSelf:!state.hotfileSetReminderSelf});
  }

  const personalHotfileButtonHandler = ()=>{
    if(state.personalHotfileSubmit)
      return;

    setState({personalHotfileSubmit:true});
  }

  //API call
  const getAllAccountManagers = ()=>{
    let callBack = apiCallBack([{state:'accountManagers',key:'data'}]);
    httpGet('accountManager/get','', 'Oops, something went wrong and could not load a list of account managers. Please try again later.',callBack);
  }

  const getAutoAssign = (id)=>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;

      if(code==='00'){
        if(response.data.data){
          setState({autoAssign:response.data.data});
          if(response.data.data.status==='active')
            setState({autoAssignEnabled:true});
        }
      }
    };

    httpGet('autoAssign/get/'+id, '', 'Oops, something went wrong and could not load the auto assign information.', callBack);
  }

  const getInternalEscalation = (id)=>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;

      if(code==='00'){
        if(response.data.data){
          setState({internalEscalation:response.data.data});
          if(response.data.data.status==='active')
            setState({internalEscalationEnabled:true});
        }
        setState({internalEscalationHistories:response.data.history});
      }
    };
    httpGet('escalation/internal/'+id, '', 'Oops, something went wrong and could not load the internal escalation information.', callBack);
  }

  const getAllAdmins = ()=>{
    let callBack = apiCallBack([{state:'admins',key:'data'}]);
    httpGet('admin/get/status=activated','', '', callBack);
  }

  const getExpedite = (id)=>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;

      if(code==='00'){
        if(response.data.data){
          setState({expedite:response.data.data});
          if(response.data.data.status==='active')
            setState({expediteEnabled:true});
        }

        setState({expediteHistories:response.data.history});
      }
    }
    httpGet('expedite/'+id, '', 'Oops, something went wrong and could not load the expedite information.', callBack);
  }

  const getGlobalHotfile = (id)=>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;

      if(code==='00'){
        if(response.data.data){
          console.log(response.data.data);
          setState({globalHotfile:response.data.data});
          if(response.data.data.status==='active')
            setState({globalHotfileWatched:true});
        }

        setState({globalHotfileHistories:response.data.history});
      }
    }
    httpGet('hotfile/global/'+id+'/Order','', 'Oops, something went wrong and could not load global hotfile information.', callBack);
  }

  const getHotfileSubCategory = ()=>{
    let callBack = apiCallBack([{state:'hotfileSubCategory', key:'data'}]);
    httpGet('hotfile/subCategory', '', 'Oops, something went wrong and could not retrieve hotfile category.', callBack);
  }

  const getNuclearfileSubCategory = ()=>{
    let callBack = apiCallBack([{state:'nuclearfileSubCategory', key:'data'}]);
    httpGet('nuclearfile/subCategory', '', 'Oops, something went wrong and could not retrieve nuclear file category.', callBack);
  }

  const getHotfile = (id)=>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        if(response.data.data){
          setState({hotfile:response.data.data});
          if(response.data.data.status==='active')
            setState({hotfileWatched:true});
          if(response.data.data.have_reminder==='yes')
            setState({snoozeHotfileOption:true});
        }
        setState({personalHotfileHistories:response.data.history});
      }
    }
    httpGet('hotfile/personal/'+id+'/Order', '', 'Oops, something went wrong and could not load hotfile information.', callBack);
  }

  const getGlobalSnooze = (id)=>{
    let callBack = apiCallBack([{state:'globalDateSnooze', key:'data.datetime_snooze'}]);
    httpGet('hotfile/global/snooze/'+id, '', 'Oops, something went wrong and could not load global hotfile info. Please try again later.', callBack);
  }

  const getManualReview = (id)=>{
    let callBack = (response) =>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        setState({manualReview:response.data.data});
        if(response.data.data.status==='active')
          setState({manualReviewEnabled:true});
      }
    }
    httpGet('review/manualReview/get/'+id, '', 'Oops, something went wrong and could not load the manual review information.', callBack);
  }

  const getCRM = (id)=>{
    let callBack = (response) =>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        if(response.data.data){
          setState({crm:response.data.data,crmHistories:response.data.history});
          if(response.data.data.status==='active')
            setState({crmEnabled:true});
        }
      }
    }
    httpGet('crm/'+id, '', 'Oops, something went wrong and could not load the CRM information.', callBack);
  }
  
  const getSMSNotifications = ()=>{
    let callBack = apiCallBack([{state:'smsNotifications', key:'data'}]);
    httpGet('appraisal/sms/'+props.appraisal.ID, '','Oops, something went wrong and could not load a list of phone numbers subscribed to SMS notifications.',callBack);
  }

  const addToInternalEscalation = ()=>{
    let comment = '';

    if(state.internalEscalation.comment&&state.internalEscalation.comment!=='')
      comment = state.internalEscalation.comment;

    let parameters = [
      {
        field:'appraisalFk',
        value:props.appraisal.ID
      },
      {
        field:'comment',
        value:comment
      }
    ];

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;

      if(code==='00'){
        let appraisal = Object.assign({}, props.appraisal);
        if(appraisal.priority==='P4 - Standard')
          appraisal.priority = 'P2 - Escalate';

        setState({internalEscalation:response.data.data,internalEscalationEnabled:true, internalEscalationfileSubmit:false, globalHotfileWatched:false, expediteEnabled: false});
        props.updateAppraisal(appraisal);
        toggleInternalEscalation();
      }
    };

    let errorCallBack = (error)=>{
      setState({expeditefileSubmit:false});
    };

    httpPost('escalation/internal/create', parameters, 'Order escalated.', 'Oops, something went wrong and escalate the order. Please try again later.', callBack, errorCallBack);
  }

  const addToExpedite = ()=>{
    let comment = '';

    if(state.expedite.comment&&state.expedite.comment!=='')
      comment = state.expedite.comment;

    let parameters = [
      {
        field:'appraisalFk',
        value:props.appraisal.ID
      },
      {
        field:'comment',
        value:comment
      }
    ];

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;

      if(code==='00'){
        let appraisal = Object.assign({}, props.appraisal);
        if(appraisal.priority==='P4 - Standard')
          appraisal.priority = 'P3 - Expedite';

        setState({expedite:response.data.data,expediteEnabled:true,expeditefileSubmit:false});
        props.updateAppraisal(appraisal);
        toggleExpedite();
      }
    };

    let errorCallBack = (error)=>{
      setState({expeditefileSubmit:false});
    };

    httpPost('expedite/create', parameters, 'Order added to expedite list.', 'Oops, something went wrong and add the order to expedite list. Please try again later.', callBack, errorCallBack);
  }

  const snoozeHotfile = ()=>{
    let parameters = [
      {
        field:'appraisalFk',
        value:props.appraisal.ID
      },
      {
        field:'hourToSnooze',
        value:state.hotfileReminderSnooze
      }
    ];

    let callBack = apiCallBack([{state:'snoozeHotfileDropDownOpen',value:false}]);

    httpPost('hotfile/personal/snooze', parameters,'Reminder snooze for '+state.hotfileReminderSnooze+' hour.','Oops, something went wrong and could not snooze this reminder. Please try again later.', callBack);
  }

  const updateGlobalSnooze = ()=>{
    let parameters = [
      {
        field:'appraisalFk',
        value:props.appraisal.ID
      },
      {
        field:'datetimeSnooze',
        value:state.globalDateSnooze
      }
    ];

    let callBack = apiCallBack([{state:'globalDateSnooze',key:'data.datetime_snooze'},{state:'globalHotfileWatched',value:false}]);

    httpPost('hotfile/global/snooze', parameters,'Reminder snooze until '+state.globalDateSnooze+'.','Oops, something went wrong and could not snooze this reminder. Please try again later.', callBack);
  }

  const haltAutoBid = ()=>{
    let parameters = [
      {
        field:'appraisalFk',
        value:props.appraisal.ID
      }
    ];

    let callBack = apiCallBack([{state:'autoBidHalt',value:true}]);

    httpPost('autoBid/halt/create', parameters,'Auto bid halted.','Oops, something went wrong and could not halt auto bid. Please try again later.', callBack);
  }

  const addToManualReview = ()=>{
    let message = '';

    if(state.manualReview.message&&state.manualReview.message!=='')
      message = state.manualReview.message;

    let parameters = [
      {
        field:'appraisalFk',
        value:props.appraisal.ID
      },
      {
        field:'message',
        value:message
      }
    ];

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;

      if(code==='00'){
        setState({manualReview:response.data.data,manualReviewEnabled:true});
        toggleManualReview();
      }
    }

    httpPut('review/manualReview/update', parameters, 'Manual review enabled.', 'Oops, something went wrong and enable manual review. Please try again later.', callBack);
  }

  const addToCRM = ()=>{
    let comment = '';

    if(state.crm.comment&&state.crm.comment!=='')
      comment = state.crm.comment;

    let parameters = [
      {
        field:'appraisalFk',
        value:props.appraisal.ID
      },
      {
        field:'comment',
        value:comment
      }
    ];

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;

      if(code==='00'){
        setState({crm:response.data.data,crmEnabled:true});
        toggleCRM();
      }
    }

    httpPost('crm/create', parameters, 'Added to CRM.', 'Oops, something went wrong and add order to CRM. Please try again later.', callBack);
  }

  const markModificationLogAsSeen = (id)=>{
    let parameters = [
      {
        field:'ID',
        value:id
      }
    ];

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;

      if(code==='00'){
        let appraisal = Object.assign({}, props.appraisal);
        let modificationLog = appraisal.modification_log.slice();

        for(let i=0;i<modificationLog.length;i++){
          if(modificationLog[i].ID===id){
            modificationLog[i].seen = 'yes';
            break;
          }
        }

        appraisal.modification_log = modificationLog;
        props.updateAppraisal(appraisal);
      }
    }

    httpPut('appraisal/modification/markSeen', parameters, '', '', callBack);
  }
  
  const subscribeSMSNotification = ()=>{
    let parameters = [
      {
        field:'appraisalFk',
        value:props.appraisal.ID
      },
      {
        field:'phone',
        value:state.smsNotificationPhone
      }
    ];

    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;

      if(code==='00'){
        let smsNotifications = state.smsNotifications.slice();
        smsNotifications.push(response.data.data);
        setState({smsNotifications:smsNotifications});
      }
    }

    httpPost('appraisal/sms/create',parameters,'Subscribed to SMS notifications.','Oops, something went wrong and could not subscribe the phone number to sms notification. Please try again later.', callBack);
  }

  const addToHotfile = ()=>{
    let comment = '';
    let subCategory = 'Other';

    if(state.hotfile.comment&&state.hotfile.comment!=='')
      comment = state.hotfile.comment;

    if(state.hotfile.subCategory&&state.hotfile.subCategory!=='')
      subCategory = state.hotfile.subCategory;

    let parameters = [
      {
        field:'appraisalFk',
        value:props.appraisal.ID
      },
      {
        field:'comment',
        value:comment
      },
      {
        field:'subCategory',
        value:subCategory
      },
      {
        field:'category',
        value:'Order'
      }
    ];

    if(state.hotfileSetReminder){
      let tmp = {
        field:'haveReminder',
        value:'yes'
      };

      parameters.push(tmp);

      tmp = {
        field:'remindEveryHour',
        value:state.hotfileReminderEveryHour
      };

      parameters.push(tmp);


      if(state.nextReminder!==''){
        let tmp = {
          field:'nextReminder',
          value:state.nextReminder
        };

        parameters.push(tmp);
      }

      parameters.push(tmp);
    }

    if(state.hotFileUserFk!==''){
      let tmp = {
        field:'userFk',
        value:state.hotFileUserFk
      };

      parameters.push(tmp);
    }

    let errorCallBack = (error)=>{
      setState({personalHotfileSubmit:false});
    };
    
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      setState({personalHotfileSubmit:false});
      if(code==='00'){
        getHotfile(props.appraisal.ID);
        setState({hotfileDropDownOpen:false});
      }
    };


    httpPost('hotfile/personal/create', parameters, 'Order added to personal hotfile.', 'Oops, something went wrong and could add to hotfile. Please try again later.', callBack,errorCallBack);

    if(state.hotfileSetReminderSelf){
      let tmp = [];
      for(let i=0;i<parameters.length;i++){
        if(parameters[i].field!=='userFk')
          tmp.push(parameters[i]);
      }
      parameters = tmp;
      httpPost('hotfile/personal/create', parameters, 'Order added to personal hotfile.', 'Oops, something went wrong and could add to hotfile. Please try again later.', callBack,errorCallBack);
    }
  }

  const redoOrder = ()=>{
    let parameters = [
      {
        field:'ID',
        value:props.appraisal.ID
      }
    ];
    let callBack = apiCallBack([{state:'moreActionMessage', key:'data'}]);
    httpPost('appraisal/redo', parameters, 'Redo initiated', 'Oops, something went wrong and could redo the order. Please try again later.', callBack);
  }

  const cloneOrder = ()=>{
    let parameters = [
      {
        field:'ID',
        value:props.appraisal.ID
      }
    ];
    let callBack = apiCallBack([{state:'moreActionMessage', key:'data'}]);
    httpPost('appraisal/clone', parameters, 'Order cloned', 'Oops, something went wrong and could clone the order. Please try again later.', callBack);
  }

  const transferOrder = ()=>{
    let parameters = [
      {
        field:'ID',
        value:props.appraisal.ID
      },
      {
        field:'referenceNum',
        value:state.transferOrderReference
      }
    ];
    let callBack = apiCallBack([{state:'transferOrderReference', value:''}]);
    httpPost('appraisal/clone', parameters, 'Order transfered to '+state.transferOrderReference, 'Oops, something went wrong and could transfer the order. Please try again later.', callBack);
  }

  const updateOrderStatus = ()=>{
    if(state.haltAutoBidOnUpdate){
      haltAutoBid();
      setState({haltAutoBidOnUpdate:false});
    }
    if(state.moreActionOrderStatus===''){
      setState({moreActionMessage:'Please select the order status.'});
    }
    else{
      let parameters = [
        {
          field:'ID',
          value:props.appraisal.ID
        },
        {
          field:'field',
          value:'status'
        },
        {
          field:'value',
          value:state.moreActionOrderStatus
        }
      ];

      let callBack = (response)=>{
        let code = response.data?response.data.code:undefined;

        if(code==='00'){
          let appraisal = Object.assign({}, props.appraisal, {status:state.moreActionOrderStatus});
          props.updateAppraisal(appraisal);
        }
      }
      httpPut('appraisal/update', parameters, 'Order status updated.', 'Oops, something went wrong and could not update the order status. Please try again later.', callBack);
    }
  }

  const removeHotfile = ()=>{
    let callBack = apiCallBack([{state:'hotfileWatched', value:false}]);
    httpDelete('hotfile/personal/'+state.hotfile.ID, 'Order removed from personal hotfile.', 'Oops, something went wrong and could not delete the hotfile. Please try again later.', callBack);
  }

  const removeAutoBidHalt = ()=>{
    let callBack = apiCallBack([{state:'autoBidHalt', value:false}]);
    httpDelete('autoBid/halt/'+props.appraisal.ID, 'Release auto bid from halt.', 'Oops, something went wrong and could not release auto bid from halt. Please try again later.', callBack);
  }

  const removeInternalEscalation = ()=>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let appraisal = Object.assign({}, props.appraisal);
        if(appraisal.priority==='P2 - Escalate')
          appraisal.priority = 'P4 - Standard';
        setState({internalEscalationEnabled:!state.internalEscalationEnabled});
        props.updateAppraisal(appraisal);
      }
    }
    httpDelete('escalation/internal/'+state.internalEscalation.ID, 'Order de-escalted.', '', callBack);
  }

  const removeExpedite = ()=>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let appraisal = Object.assign({}, props.appraisal);
        if(appraisal.priority==='P3 - Expedite')
          appraisal.priority = 'P4 - Standard';
        setState({expediteEnabled:!state.expediteEnabled});
        props.updateAppraisal(appraisal);
      }
    }
    httpDelete('expedite/'+state.expedite.ID, 'Order removed from expedite list.', '', callBack);
  }

  const removeManualReview = ()=>{
    let callBack = apiCallBack([{state:'manualReviewEnabled', value:false}])
    httpDelete('review/manualReview/'+props.appraisal.ID, 'Disabled manual review.','Oops, something went wrong and could not disable manual review. Please try again later.', callBack);
  }

  const removeCRM = ()=>{
    let callBack = apiCallBack([{state:'crmEnabled', value:false}])
    httpDelete('crm/'+props.appraisal.ID, 'Removed auto bid from CRM.','Oops, something went wrong and could not remove auto bid from CRM. Please try again later.', callBack);
  }

  const addToGlobalHotfile = () => {
    let comment = '';
    let subCategory = 'Other';

    if(state.globalHotfile.comment&&state.globalHotfile.comment!=='')
      comment = state.globalHotfile.comment;

    if(state.globalHotfile.sub_category&&state.globalHotfile.sub_category!=='')
      subCategory = state.globalHotfile.sub_category;

    let parameters = [
      {
        field:'appraisalFk',
        value:props.appraisal.ID
      },
      {
        field:'comment',
        value:comment
      },
      {
        field:'subCategory',
        value:subCategory
      },
      {
        field:'category',
        value:'Order'
      }
    ];

    let errorCallBack = (error)=>{
      setState({globalHotfileSubmit:false});
    };

    let callBack = (response)=>{
      setState({globalHotfileSubmit:false});
      let code = response.data?response.data.code:undefined;
      if(code!=='00'){

      }
      else{
        let appraisal = Object.assign({},props.appraisal);
        appraisal.priority = 'P1 - Hot';

        setState({globalHotfile:response.data.data, globalHotfileWatched:true, showGlobalHotfileModify:false, expediteEnabled:false});
        props.updateAppraisal(appraisal);
        toggleglobalHotfile();
      }
    };
    httpPost('hotfile/global/create', parameters, 'Order added to global hotfile.', 'Oops, something went wrong and could add to global hotfile. Please try again later.', callBack, errorCallBack);
  }

  const unsubscribeSMSNotification = (id)=>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let smsNotifications = sliceFromArray(state.smsNotifications, 'ID', id);
        setState({smsNotifications:smsNotifications});
      }
    }

    httpDelete('appraisal/sms/'+id,'Unsubscribe from SMS notifications.','Oops, something went wrong and could not unsubscribe the phone number from sms notification. Please try again later.',callBack);
  }

  const getNuclearfile = ()=>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;

      if(code==='00'){
        if(response.data.data.status==='active'){
          setState({nuclearfileWatched:true});
        }
        setState({nuclearfile:response.data.data, nuclearHistories:response.data.history});
      }
    }
    httpGet('nuclearfile/'+props.appraisal.ID+'/Order', '', '', callBack);
  }

  const getAutoBidHalt = ()=>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;

      if(code==='00'){
        setState({autoBidHalt:response.data.data?true:false});
      }
    }
    httpGet('autoBid/halt/get/'+props.appraisal.ID, '', '', callBack);
  }

  const addToNuclearfile = ()=>{
    let comment = '';
    let subCategory = 'Other';

    if(state.nuclearfile.comment&&state.nuclearfile.comment!=='')
      comment = state.nuclearfile.comment;

    if(state.nuclearfile.sub_category&&state.nuclearfile.sub_category!=='')
      subCategory = state.nuclearfile.sub_category;


    let parameters = [
      {
        field:'appraisalFk',
        value:props.appraisal.ID
      },
      {
        field:'comment',
        value:comment
      },
      {
        field:'subCategory',
        value:subCategory
      },
      {
        field:'category',
        value:'Order'
      }
    ];

    let callBack = (response)=>{
      setState({nuclearfileSubmit:false});
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        getNuclearfile(props.appraisal.ID);

        let appraisal = Object.assign({},props.appraisal);
        appraisal.priority = 'P0 - Nuclear';
        setState({nuclearfileDropDownOpen:false, showNuclearfileModify:false, expediteEnabled:false, globalHotfileWatched:false, internalEscalationEnabled:false, nuclearfileWatched:true});
        props.updateAppraisal(appraisal);
      }
    };
    httpPost('nuclearfile/create', parameters, 'Order added to nuclear','Oops, something went wrong and could add to nuclear. Please try again later.', callBack);
  }

  const getAllOrderStatuses = ()=>{
    let callBack = apiCallBack([{state:'statuses',key:'data'}]);
    httpGet('appraisal/statuses/get', '','Oops, something went wrong and could not get order statuses. Please try again later.', callBack);
  }

  const removeglobalHotfile = ()=>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;

      if(code!=='00'){

      }
      else{
        let appraisal = Object.assign({},props.appraisal);
        appraisal.priority = 'P4 - Standard';

        setState({appraisal:appraisal, globalHotfile:{sub_category:'Other', comment:''}, globalHotfileWatched:!state.globalHotfileWatched,showGlobalHotfileModify:false, globalHotfileDropDownOpen:false});
      }
    };

    httpDelete('hotfile/global/'+state.globalHotfile.ID, 'Order removed from global hotfile.','Oops, something went wrong and could not remove this order from global hotifle. Please try again later.', callBack);
  }

  const updateAccountManager = ()=>{
    if(state.moreActionAccountManager===''){
      setState({moreActionMessage:'Please select the account manager.'});
    }
    else{
      let url = 'appraisal/update';
      let parameters = [{field:'field',value:'account_manager_fk'}, {field:'value',value:state.moreActionAccountManager}, {field:'ID',value:props.appraisal.ID}];
  
      let callBack = (response)=>{
        props.updateAppraisal(Object.assign({}, props.appraisal, {account_manager_fk:state.moreActionAccountManager}));
      }
      httpPut(url, parameters, 'Account Manager assigned successfully','Oops, something went wrong and could not assign account manager. Please try again later.', callBack);
    }
  }

  const holdOrder = ()=>{
    let url = 'appraisal/update';
    let parameters = [{field:'field',value:'status'}, {field:'value',value:'Hold'}, {field:'ID',value:props.appraisal.ID}];

    let callBack = (response)=>{
      props.updateAppraisal(Object.assign({}, props.appraisal, {status:'Hold'}));
    }
    httpPut(url, parameters, 'Special instructions updated successfully','Oops, something went wrong and could not update special instructions. Please try again later.', callBack);
  }

  const cancelOrder = (integrationCancel=false)=>{
    let url = 'appraisal/update';
    let parameters = [{field:'field',value:'status'}, {field:'value',value:'Cancelled'}, {field:'ID',value:props.appraisal.ID}, {field:'integrationCancel', value:integrationCancel?"yes":"no"}];

    let callBack = (response)=>{
      props.updateAppraisal(Object.assign({}, props.appraisal, {status:'Cancelled'}));
    }
    httpPut(url, parameters, 'Special instructions updated successfully','Oops, something went wrong and could not update special instructions. Please try again later.', callBack);
  }

  const removeNuclearfile = ()=>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        setState({nuclearfile:{sub_category:'Other'}, nuclearfileWatched:false,nuclearfileDropDownOpen:false,showNuclearfileModify:false});
      }
    };
    httpDelete('nuclearfile/order/'+state.nuclearfile.ID, 'Order removed from nuclear.','Oops, something went wrong and could not remove this order from nuclear. Please try again later.', callBack);
  }

  let snoozeHotfileOption;

  if(state.snoozeHotfileOption){
    snoozeHotfileOption = <label className="no-margin my-well cursor-pointer" style={{padding:'5px'}} onClick={snoozeHotfileToggle}>
      <i style={{fontSize:'22px'}} className="fa fa-bell-slash cursor-pointer"/> Snooze Reminder
    </label>
  }

  let globalDateSnooze = state.globalDateSnooze;

  if(globalDateSnooze&&globalDateSnooze!=='0000-00-00 00:00:00'&&globalDateSnooze!==''){
    console.log(state.globalDateSnooze);
    console.log(moment(state.globalDateSnooze));
    console.log(moment(state.globalDateSnooze).toDate());
    globalDateSnooze = moment(state.globalDateSnooze).toDate();
  }
  else
    globalDateSnooze = null;

    console.log(globalDateSnooze)

    let manualReviewHistories;

    if(state.manualReviewHistories.length>0){
      manualReviewHistories = <div>
        <label>History</label><br/>
        <table className="table table-stripped">
          <thead>
            <tr><th>Date</th><th>Comment</th></tr>
          </thead>
          <tbody>
            {
              state.manualReviewHistories.map(
                (history, index)=>{
                  return <tr key={index}>
                    <td>{formatDateTime(history.datetime_created)}</td>
                    <td>{history.message}</td>
                  </tr>
                }
              )
            }
          </tbody>
        </table>
      </div>
    }

    let crmHistories;

    if(state.crmHistories.length>0){
      crmHistories = <div>
        <label>History</label><br/>
        <table className="table table-stripped">
          <thead>
            <tr><th>Date</th><th>Comment</th></tr>
          </thead>
          <tbody>
            {
              state.crmHistories.map(
                (history, index)=>{
                  return <tr key={index}>
                    <td>{formatDateTime(history.datetime_created)}</td>
                    <td>{history.comment}</td>
                  </tr>
                }
              )
            }
          </tbody>
        </table>
      </div>
    }


    let nuclearHistories;

    if(state.nuclearHistories&&state.nuclearHistories.length>0){
      nuclearHistories = <div>
        <label>History</label><br/>
        <table className="table table-stripped">
          <thead>
            <tr><th>Date</th><th>Category</th><th>Comment</th></tr>
          </thead>
          <tbody>
            {
              state.nuclearHistories.map(
                (history, index)=>{
                  return <tr key={index}>
                    <td>{formatDateTime(history.datetime_created)}</td>
                    <td>{history.sub_category}</td>
                    <td>{history.comment}</td>
                  </tr>
                }
              )
            }
          </tbody>
        </table>
      </div>
    }

    let personalHotfileHistories;

    if(state.personalHotfileHistories && state.personalHotfileHistories.length>0){
      personalHotfileHistories = <div>
        <label>History</label><br/>
        <table className="table table-stripped">
          <thead>
            <tr><th>Date</th><th>Category</th><th>Comment</th></tr>
          </thead>
          <tbody>
            {
              state.personalHotfileHistories.map(
                (history, index)=>{
                  return <tr key={index}>
                    <td>{formatDateTime(history.datetime_created)}</td>
                    <td>{history.sub_category}</td>
                    <td>{history.comment}</td>
                  </tr>
                }
              )
            }
          </tbody>
        </table>
      </div>
    }

    let globalHotfileHistories;

    if(state.globalHotfileHistories&& state.globalHotfileHistories.length>0){
      globalHotfileHistories = <div>
        <label>History</label><br/>
        <table className="table table-stripped">
          <thead>
            <tr><th>Date</th><th>Category</th><th>Comment</th></tr>
          </thead>
          <tbody>
            {
              state.globalHotfileHistories.map(
                (history, index)=>{
                  return <tr key={index}>
                    <td>{formatDateTime(history.datetime_created)}</td>
                    <td>{history.sub_category}</td>
                    <td>{history.comment}</td>
                  </tr>
                }
              )
            }
          </tbody>
        </table>
      </div>
    }

    let expediteHistories;

    if(state.expediteHistories&&state.expediteHistories.length>0){
      expediteHistories = <div>
        <label>History</label><br/>
        <table className="table table-stripped">
          <thead>
            <tr><th>Date</th><th>Comment</th></tr>
          </thead>
          <tbody>
            {
              state.expediteHistories.map(
                (history, index)=>{
                  return <tr key={index}>
                    <td>{formatDateTime(history.datetime_created)}</td>
                    <td>{history.comment}</td>
                  </tr>
                }
              )
            }
          </tbody>
        </table>
      </div>
    }

    let internalEscalationHistories;

    if(state.internalEscalationHistories && state.internalEscalationHistories.length>0){
      internalEscalationHistories = <div>
        <label>History</label><br/>
        <table className="table table-stripped">
          <thead>
            <tr><th>Date</th><th>Comment</th></tr>
          </thead>
          <tbody>
            {
              state.internalEscalationHistories.map(
                (history, index)=>{
                  return <tr key={index}>
                    <td>{formatDateTime(history.datetime_created)}</td>
                    <td>{history.comment}</td>
                  </tr>
                }
              )
            }
          </tbody>
        </table>
      </div>
    }

  let addRemoveGlobalHotfile;

  if(state.globalHotfileWatched&&!state.showGlobalHotfileModify){
    addRemoveGlobalHotfile = <div>
      {globalHotfileHistories}
      <br/><br/>
      <font color="red">*</font>The order is currently in global hotfile, click on the button below to make change on current global hotfile or to remove it from global hotfile.<br/>
      <center>
        <br/><br/>
        {state.globalHotfile.comment && state.globalHotfile.comment!==''?<div><label>Comment</label><br/><i>"{state.globalHotfile.comment}"</i><br/><br/></div>:null}
        <Button color="warning" onClick={modifyGlobalHotfile}><i className="fa fa-wrench"/>&nbsp;Modify  Hotfile</Button> &nbsp;&nbsp;&nbsp;
        <Button color="danger" onClick={removeglobalHotfile}><i className="fa fa-times"/> &nbsp;Remove Hotfile</Button>
      </center>
      <br/><br/>
    </div>
  }else if(state.showGlobalHotfileModify){
    addRemoveGlobalHotfile = <div>
      {globalHotfileHistories}
      <font color="red">*</font>The order is currently in the hotfile, change the form below to modify it.<br/>
      <label>Category</label>
      <MySelect
        modal={true}
        type="select"
        value={state.globalHotfile.sub_category}
        onChange={(v)=>{let globalHotfile = Object.assign({},state.globalHotfile);globalHotfile.sub_category = v;setState({globalHotfile:globalHotfile})}}
        options={state.hotfileSubCategory.map((category)=>{
          return {label:category.sub_category, value:category.sub_category};
        })}
        />
      <br/>
      <label>Comment</label>
      <Input type="textarea" value={state.globalHotfile.comment} onChange={(e)=>{let globalHotfile = Object.assign({},state.globalHotfile);globalHotfile.comment = e.target.value;setState({globalHotfile})}} className="new-comment-reply" rows="3"/>
      <br/>
      <center>
        <Button color="warning" style={{width:'99%'}} disabled={state.globalHotfileSubmit!==false} onClick={globalHotfileButtonHandler}>Update</Button>
      </center>
      <br/><br/>
    </div>
  }else{
    addRemoveGlobalHotfile = <div>
      {globalHotfileHistories}
      <font color="red">*</font>The order is currently not in the hotfile, complete the form below to add it.<br/>
      <label>Category</label>
      <MySelect
        modal={true}
        type="select"
        value={state.globalHotfile.sub_category}
        onChange={(v)=>{let globalHotfile = Object.assign({},state.globalHotfile);globalHotfile.sub_category = v;setState({globalHotfile:globalHotfile})}}
        options={state.hotfileSubCategory.map((category)=>{
          return {label:category.sub_category, value:category.sub_category};
        })}
        />
      <br/>
      <label>Comment</label>
      <Input type="textarea" value={state.globalHotfile.comment} onChange={(e)=>{let globalHotfile = Object.assign({},state.globalHotfile);globalHotfile.comment = e.target.value;setState({globalHotfile})}} className="new-comment-reply" rows="3"/>
      <br/>
      <center>
        <Button color="warning" style={{width:'99%'}} disabled={state.globalHotfileSubmit!==false} onClick={globalHotfileButtonHandler}>Add</Button>
      </center>
      <br/><br/>
    </div>
  }

  let addRemoveInternalEscalation;

    if(state.internalEscalationEnabled&&!state.showInternalEscalationfileModify){
      addRemoveInternalEscalation = <div>
        {internalEscalationHistories}
        <br/><br/>
        <font color="red">*</font>The order is currently in escalation, click on the button below to make change on escalation or to remove it from escalation.<br/>
        <center>
          <br/><br/>
          {state.internalEscalation.comment && state.internalEscalation.comment!==''?<div><label>Comment</label><br/><i>"{state.internalEscalation.comment}"</i><br/><br/></div>:null}
          <Button color="warning" onClick={modifyInternalEscalation}><i className="fa fa-wrench"/>&nbsp;Modify Escalation</Button> &nbsp;&nbsp;&nbsp;
          <Button color="danger" onClick={removeInternalEscalation}><i className="fa fa-times"/> &nbsp;Remove Escalation</Button>
        </center>
        <br/><br/>
      </div>
    }else if(state.showInternalEscalationfileModify){
      addRemoveInternalEscalation = <div>
        {internalEscalationHistories}
        <font color="red">*</font>The order is currently in the escalation, change the form below to modify it.<br/>
        <label>Comment</label>
        <Input type="textarea" value={state.internalEscalation.comment} onChange={(e)=>{let internalEscalation = Object.assign({},state.internalEscalation);internalEscalation.comment = e.target.value;setState({internalEscalation})}} className="new-comment-reply" rows="3"/>
        <br/>
        <center>
          <Button color="warning" style={{width:'99%'}} disabled={state.internalEscalationfileSubmit!==false} onClick={internalEscalationButtonHandler}>Update</Button>
        </center>
        <br/><br/>
      </div>
    }else{
      addRemoveInternalEscalation = <div>
        {internalEscalationHistories}
        <font color="red">*</font>The order is currently not in the escalation, complete the form below to add it.<br/>
        <label>Comment</label>
        <Input type="textarea" value={state.internalEscalation.comment} onChange={(e)=>{let internalEscalation = Object.assign({},state.internalEscalation);internalEscalation.comment = e.target.value;setState({internalEscalation})}} className="new-comment-reply" rows="3"/>
        <br/>
        <center>
          <Button color="warning" style={{width:'99%'}} disabled={state.internalEscalationfileSubmit!==false} onClick={internalEscalationButtonHandler}>Add</Button>
        </center>
        <br/><br/>
      </div>
    }

    let addRemoveExpedite;

    if(state.expediteEnabled&&!state.showExpeditefileModify){
      addRemoveExpedite = <div>
        {expediteHistories}
        <br/><br/>
        <font color="red">*</font>The order is currently in expedite, click on the button below to make change on expedite or to remove it from expedite.<br/>
        <center>
          <br/><br/>
          {state.expedite.comment && state.expedite.comment!==''?<div><label>Comment</label><br/><i>"{state.expedite.comment}"</i><br/><br/></div>:null}
          <Button color="warning" onClick={modifyExpedite}><i className="fa fa-wrench"/>&nbsp;Modify Expedite</Button> &nbsp;&nbsp;&nbsp;
          <Button color="danger" onClick={removeExpedite}><i className="fa fa-times"/> &nbsp;Remove Expedite</Button>
        </center>
        <br/><br/>
      </div>
    }else if(state.showExpeditefileModify){
      addRemoveExpedite = <div>
        {expediteHistories}
        <font color="red">*</font>The order is currently in the expedite, change the form below to modify it.<br/>
        <label>Comment</label>
        <Input type="textarea" value={state.expedite.comment} onChange={(e)=>{let expedite = Object.assign({},state.expedite);expedite.comment = e.target.value;setState({expedite})}} className="new-comment-reply" rows="3"/>
        <br/>
        <center>
          <Button color="warning" style={{width:'99%'}} disabled={state.expeditefileSubmit!==false} onClick={expediteButtonHandler}>Update</Button>
        </center>
        <br/><br/>
      </div>
    }else{
      addRemoveExpedite = <div>
        {expediteHistories}
        <font color="red">*</font>The order is currently not in the expedite, complete the form below to add it.<br/>
        <label>Comment</label>
        <Input type="textarea" value={state.expedite.comment} onChange={(e)=>{let expedite = Object.assign({},state.expedite);expedite.comment = e.target.value;setState({expedite})}} className="new-comment-reply" rows="3"/>
        <br/>
        <center>
          <Button color="warning" style={{width:'99%'}} disabled={state.expeditefileSubmit!==false} onClick={expediteButtonHandler}>Add</Button>
        </center>
        <br/><br/>
      </div>
    }

    let addRemoveManualReview;

    if(state.manualReviewEnabled&&!state.showManualReviewModify){
      addRemoveManualReview = <div>
        {manualReviewHistories}
        <br/><br/>
        <font color="red">*</font>The order is currently in manual review queue, click on the button below to make change on current manual review queue or to remove it from manual review queue.<br/>
        <center>
          <br/><br/>
          {state.manualReview.message && state.manualReview.message!==''?<div><label>Comment</label><br/><i>"{state.manualReview.message}"</i><br/><br/></div>:null}
          <Button color="warning" onClick={modifyManualReview}><i className="fa fa-wrench"/>&nbsp;Modify Manual Review</Button> &nbsp;&nbsp;&nbsp;
          <Button color="danger" onClick={removeManualReview}><i className="fa fa-times"/> &nbsp;Remove Manual Review</Button>
        </center>
        <br/><br/>
      </div>
    }else if(state.showManualReviewModify){
      addRemoveManualReview = <div>
        {manualReviewHistories}
        <font color="red">*</font>The order is currently in the manual review queue, change the form below to modify it.<br/>
        <label>Comment</label><br/>
        <Input type="textarea" value={state.manualReview.comment} onChange={(e)=>{let manualReview = Object.assign({},state.manualReview);manualReview.message = e.target.value;setState({manualReview})}} className="new-comment-reply" rows="3"/>
        <br/>
        <center>
          <Button color="warning" style={{width:'99%'}} disabled={state.manualReviewSubmit} onClick={manualReviewButtonHandler}>Update</Button>&nbsp;
        </center>
        <br/><br/>
      </div>
    }else{
      addRemoveManualReview = <div>
        {manualReviewHistories}
        <font color="red">*</font>The order is currently not in the manual review queue, complete the form below to add it.<br/>
        <label>Comment</label><br/>
        <Input type="textarea" value={state.manualReview.comment} onChange={(e)=>{let manualReview = Object.assign({},state.manualReview);manualReview.message = e.target.value;setState({manualReview})}} className="new-comment-reply" rows="3"/>
        <br/>
        <center>
          <Button color="warning" style={{width:'99%'}} disabled={state.manualReviewSubmit} onClick={manualReviewButtonHandler}>Add</Button>&nbsp;
        </center>
        <br/><br/>
      </div>
    }

  let addRemoveCRM;

  if(state.crmEnabled&&!state.showCRMModify){
    addRemoveCRM = <div>
      {crmHistories}
      <br/><br/>
      <font color="red">*</font>The order is currently in CRM, click on the button below to make change on current CRM or to remove it from CRM.<br/>
      <center>
        <br/><br/>
        {state.crm.comment && state.crm.comment!==''?<div><label>Comment</label><br/><i>"{state.crm.comment}"</i><br/><br/></div>:null}
        <Button color="warning" onClick={modifyCRM}><i className="fa fa-wrench"/>&nbsp;Modify CRM</Button> &nbsp;&nbsp;&nbsp;
        <Button color="danger" onClick={removeCRM}><i className="fa fa-times"/> &nbsp;Remove CRM</Button>
      </center>
      <br/><br/>
    </div>
  }else if(state.showCRMModify){
    addRemoveCRM = <div>
      {crmHistories}
      <font color="red">*</font>The order is currently in the CRM, change the form below to modify it.<br/>
      <label>Comment</label><br/>
      <Input type="textarea" value={state.crm.comment} onChange={(e)=>{let crm = Object.assign({},state.crm);crm.comment = e.target.value;setState({crm})}} className="new-comment-reply" rows="3"/>
      <br/>
      <center>
        <Button color="warning" style={{width:'99%'}} disabled={state.crmSubmit} onClick={crmButtonHandler}>Update</Button>&nbsp;
      </center>
      <br/><br/>
    </div>
  }else{
    addRemoveCRM = <div>
      {crmHistories}
      <font color="red">*</font>The order is currently not in the CRM, complete the form below to add it.<br/>
      <label>Comment</label><br/>
      <Input type="textarea" value={state.crm.comment} onChange={(e)=>{let crm = Object.assign({},state.crm);crm.comment = e.target.value;setState({crm})}} className="new-comment-reply" rows="3"/>
      <br/>
      <center>
        <Button color="warning" style={{width:'99%'}} disabled={state.crmSubmit} onClick={crmButtonHandler}>Add</Button>&nbsp;
      </center>
      <br/><br/>
    </div>
  }


  let addRemoveNuclearfile;

  if(state.nuclearfileWatched&&!state.showNuclearfileModify){
    addRemoveNuclearfile = <div>
      {nuclearHistories}
      <br/><br/>
      <font color="red">*</font>The order is currently in nuclear file, click on the button below to make change on current nuclear file or to remove it from nuclear file.<br/>
      <center>
        <br/><br/>
        {state.nuclearfile.comment && state.nuclearfile.comment!==''?<div><label>Comment</label><br/><i>"{state.nuclearfile.comment}"</i><br/><br/></div>:null}
        <Button color="warning" onClick={modifyNuclearfile}><i className="fa fa-wrench"/>&nbsp;Modify Nuclearfile</Button> &nbsp;&nbsp;&nbsp;
        <Button color="danger" onClick={removeNuclearfile}><i className="fa fa-times"/> &nbsp;Remove Nuclearfile</Button>
      </center>
      <br/><br/>
    </div>
  }else if(state.showNuclearfileModify){
    addRemoveNuclearfile = <div>
      {nuclearHistories}
      <font color="red">*</font>The order is currently in the nuclear file, change the form below to modify it.<br/>
      <label>Category</label><br/>
      <MySelect
        modal={true}
        type="select"
        value={state.nuclearfile.sub_category}
        onChange={(v)=>{let nuclearfile = Object.assign({},state.nuclearfile);nuclearfile.sub_category = v;setState({nuclearfile:nuclearfile})}}
        options={state.nuclearfileSubCategory.map((category)=>{
          return {label:category.sub_category, value:category.sub_category};
        })}
        />
      <br/>
      <label>Comment</label><br/>
      <Input type="textarea" value={state.nuclearfile.comment} onChange={(e)=>{let nuclearfile = Object.assign({},state.nuclearfile);nuclearfile.comment = e.target.value;setState({nuclearfile})}} className="new-comment-reply" rows="3"/>
      <br/>
      <center>
        <Button color="warning" style={{width:'99%'}} disabled={state.nuclearfileSubmit} onClick={nuclearfileButtonHandler}>Update</Button>&nbsp;
      </center>
      <br/><br/>
    </div>
  }else{
    addRemoveNuclearfile = <div>
      {nuclearHistories}
      <font color="red">*</font>The order is currently not in the nuclear file, complete the form below to add it.<br/>
      <label>Category</label><br/>
      <MySelect
        modal={true}
        type="select"
        value={state.nuclearfile.sub_category}
        onChange={(v)=>{let nuclearfile = Object.assign({},state.nuclearfile);nuclearfile.sub_category = v;setState({nuclearfile:nuclearfile})}}
        options={state.nuclearfileSubCategory.map((category)=>{
          return {label:category.sub_category, value:category.sub_category};
        })}
        />
      <br/>
      <label>Comment</label><br/>
      <Input type="textarea" value={state.nuclearfile.comment} onChange={(e)=>{let nuclearfile = Object.assign({},state.nuclearfile);nuclearfile.comment = e.target.value;setState({nuclearfile})}} className="new-comment-reply" rows="3"/>
      <br/>
      <center>
        <Button color="warning" style={{width:'99%'}} disabled={state.nuclearfileSubmit} onClick={nuclearfileButtonHandler}>Add</Button>&nbsp;
      </center>
      <br/><br/>
    </div>
  }

  let nextReminder = state.nextReminder;

  if(nextReminder&&nextReminder!=='0000-00-00 00:00:00'&&nextReminder!==''){
    console.log(state.nextReminder);
    console.log(moment(state.nextReminder));
    console.log(moment(state.nextReminder).toDate());
    nextReminder = moment(state.nextReminder).toDate();
  }
  else
    nextReminder = null;

  let hotfileReminderInterface;

  if(state.hotfileSetReminder){
    hotfileReminderInterface = <div>
      <label>Next Reminder</label><br/>
      <DatePicker
        className="xeditable-input-datetime-gray"
        showTimeSelect
        selected={nextReminder}
        timeIntervals={15}
        onChange={(text)=>{console.log(text);setState({nextReminder:text.toLocaleDateString('en-CA')+' '+text.toLocaleTimeString('en-CA',{ hour12: false })})}}
        dateFormat='MM/dd/yyyy HH:mm'
      />
      <br/>
      <label>Remind Every Hour Of</label><br/>
      <MySelect
        modal={true}
        type="select"
        value={state.hotfileReminderEveryHour}
        onChange={(v)=>{setState({hotfileReminderEveryHour:v})}}
        options={[{label:"1",value:"1"},{label:"2",value:"2"},{label:"3",value:"3"},{label:"4",value:"4"},{label:"5",value:"5"},{label:"6",value:"6"}]}
        />
      <br/>

      <br/><br/><br/><br/><br/><br/><br/><br/>
    </div>;
  }

  //render
  return <div>
    <div className="">
      <label className="my-well" style={{padding:'5px', margin:'2px'}}>
        <Toggle
          checked={state.nuclearfileWatched}
          icons={false}
          onChange={handleNuclearfileChange} />&nbsp;
        <span style={{verticalAlign:'top',fontWeight:'bold'}}>Nuclear file</span>
      </label>
      
      <label className="my-well" style={{padding:'5px', margin:'2px'}}>
        <Toggle
          checked={state.internalEscalationEnabled}
          icons={false}
          onChange={handleInternalEscalation} />&nbsp;
        <span style={{verticalAlign:'top',fontWeight:'bold'}}>Escalation</span>
      </label>
      <label className="my-well" style={{padding:'5px', margin:'2px'}}>
        <Toggle
          checked={state.globalHotfileWatched}
          icons={false}
          disable={state.nuclearfileWatched}
          onChange={handleglobalHotfileChange} />&nbsp;
        <span style={{verticalAlign:'top',fontWeight:'bold'}}>Global Hotfile</span>
      </label>
      <label className="my-well" style={{padding:'5px', margin:'2px'}}>
        <Toggle
          checked={state.hotfileWatched}
          icons={false}
          onChange={handleHotfileChange} />&nbsp;
        <span style={{verticalAlign:'top',fontWeight:'bold'}}>Personal Hotfile</span>
      </label>
      
      <label className="my-well" style={{padding:'5px', margin:'2px'}}>
        <Toggle
          checked={state.expediteEnabled}
          icons={false}
          onChange={handleExpedite} />&nbsp;
        <span style={{verticalAlign:'top',fontWeight:'bold'}}>Expedite</span>
      </label>
      
      <label className="my-well" style={{padding:'5px'}}>
        <Toggle
          checked={state.autoBidHalt}
          icons={false}
          onChange={handleAutoBidChange} />&nbsp;
        <span style={{verticalAlign:'top',fontWeight:'bold'}}>Halt Auto Bid</span>
      </label>
      <label className="my-well" style={{padding:'5px'}}>
        <Toggle
          checked={state.manualReviewEnabled}
          icons={false}
          onChange={handleManualReviewChange} />&nbsp;
        <span style={{verticalAlign:'top',fontWeight:'bold'}}>Manual Review</span>
      </label>
      
      <label className="my-well" style={{padding:'5px', margin:'2px'}}>
        <Toggle
          checked={false}
          icons={false}
          onChange={toggleSMSNotification} />&nbsp;
        <span style={{verticalAlign:'top',fontWeight:'bold'}}>SMS Notification</span>
      </label>
      
      <label className="my-well" style={{padding:'5px', margin:'2px'}}>
        <Toggle
          checked={false}
          icons={false}
          onChange={toggleModificationLog} />&nbsp;
        <span style={{verticalAlign:'top',fontWeight:'bold'}}>Client Modification Log</span>
      </label>
      <label className="my-well" style={{padding:'5px', margin:'2px'}}>
        <Toggle
          checked={false}
          icons={false}
          onChange={toggleMoreAction} />&nbsp;
        <span style={{verticalAlign:'top',fontWeight:'bold'}}>More</span>
      </label>
      <label className="my-well" style={{padding:'5px'}}>
        <Toggle
          checked={state.crmEnabled}
          icons={false}
          onChange={handleCRMChange} />&nbsp;
        <span style={{verticalAlign:'top',fontWeight:'bold'}}>CRM</span>
      </label>
      {snoozeHotfileOption}

      <Modal className="my-modal" isOpen={state.moreActionDropDownOpen} toggle={toggleMoreAction} >
        <ModalHeader hidden={true} toggle={toggleMoreAction}></ModalHeader>
        <ModalBody>
          <center>
            <h5><i className="fa fa-warning"></i> More action</h5>
          </center>
          <br/>
          <div style={{minHeight:'25px',maxHeight:'25px'}}>
            <font color="red">{state.moreActionMessage}</font>
          </div>
          <br/>
          <div className="ultra-large-scroll-container">
            <Card>
              <CardHeader className={state.moreActionChoice==='1'?"header-color  cursor-pointer":"gray-color  cursor-pointer"} onClick={(e)=>selectMoreAction('1')}>
                <i className="fa fa-plus green-color"></i> Assign account manager
              </CardHeader>
              <CardBody style={{padding:'0px'}}>
                <Collapse isOpen={state.moreActionChoice==='1'} className="padding">
                  <label><font color="red">*</font>Assigning new account manager would remove this order from the current account manager's pipeline.</label>
                  <br/>

                  <Row>
                    <Col sm="4">
                      <label>New account manager</label>
                    </Col>
                    <Col sm="4">
                      <MySelect
                        modal={true}
                        type="select"
                        value={state.moreActionAccountManager}
                        onChange={(v)=>{setState({moreActionAccountManager:v})}}
                        options={state.accountManagers.map((accountManager)=>{
                          return {label:accountManager.name, value:accountManager.ID};
                        })}
                        />
                    </Col>
                    <Col sm="4" className="align-right">
                      <Button color="warning" onClick={updateAccountManager}>Update</Button>
                    </Col>
                  </Row>
                </Collapse>
              </CardBody>
            </Card>
            <br/>
            <Card>
              <CardHeader className={state.moreActionChoice==='2'?"header-color  cursor-pointer":"gray-color  cursor-pointer"} onClick={(e)=>selectMoreAction('2')}>
                <i className="fa fa-plus green-color"></i> Redo/Clone order
              </CardHeader>
              <CardBody style={{padding:'0px'}}>
                <Collapse isOpen={state.moreActionChoice==='2'} className="padding">
                  <label><font color="red">*</font>Redo the order will clone a new order by copying every data on this order.</label><br/>
                  <label><font color="red">*</font>The redo order will have a reference # starting with "RD".</label><br/>
                  <label><font color="red">*</font>The clone order on the other hand won't have reference number starting with "RD".</label><br/>

                  <br/>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<Input type="checkbox" checked={state.redoCloneCancel} onClick={(e)=>setState({redoCloneCancel:!state.redoCloneCancel})}/> Cancel Order (Notifications when cancelling an order will be trigger)
                  <br/>
                  <center>
                    <Button color="warning" onClick={
                      ()=>{
                        confirmation(
                          ()=>{
                            redoOrder()
                            if(state.redoCloneCancel)
                              cancelOrder();
                          },
                          ()=>{},
                          'Initiate Redo?',
                          'Are you sure you want to initiate redo on this order?<br/><br/>Please note that if this is an integration order, its link will be transferred to the new order.<br/><br/>Please also make sure the billing details and purchase price are to date.');
                      }

                    }>Redo order</Button>&nbsp;&nbsp;
                    <Button color="warning" onClick={
                      ()=>{
                        confirmation(
                          ()=>{
                            cloneOrder();
                            if(state.redoCloneCancel)
                              cancelOrder();
                          },
                          ()=>{},
                          'Clone Order?',
                          'Are you sure you want to clone the order?<br/><br/>Please note that if this is an integration order, its link will be transferred to the new order.<br/><br/>Please also make sure the billing details and purchase price are to date.');
                      }
                    }>Clone order</Button>
                  </center>
                </Collapse>
              </CardBody>
            </Card>
            <br/>
            <Card>
              <CardHeader className={state.moreActionChoice==='3'?"header-color  cursor-pointer":"gray-color  cursor-pointer"} onClick={(e)=>selectMoreAction('3')}>
                <i className="fa fa-plus green-color"></i> Hold order
              </CardHeader>
              <CardBody style={{padding:'0px'}}>
                <Collapse isOpen={state.moreActionChoice==='3'} className="padding">
                  <label><font color="red">*</font>Put the order to hold will set all pending invoices & bill to "Hold" as well.</label><br/>
                  <label><font color="red">*</font>After 30 days, it will be cancelled.</label><br/>
                  <br/>

                  <center>
                    <Button color="warning" onClick={holdOrder}>Hold order</Button>
                  </center>
                </Collapse>
              </CardBody>
            </Card>
            <br/>
            <Card>
              <CardHeader className={state.moreActionChoice==='4'?"header-color  cursor-pointer":"gray-color  cursor-pointer"} onClick={(e)=>selectMoreAction('4')}>
                <i className="fa fa-plus green-color"></i> Cancel order
              </CardHeader>
              <CardBody style={{padding:'0px'}}>
                <Collapse isOpen={state.moreActionChoice==='4'} className="padding">
                  <label><font color="red">*</font>Cancel the order will set all pending invoices & bill to "Cancelled" as well.</label><br/>
                  <label><font color="red">*</font>After 30 days, the status will be permanent and you won't be able to re-activate the order.</label><br/>
                  <br/>

                  <center>
                    <Button color="warning" onClick={()=>{
                      let reggoraOrder = false;
                      state.messages.forEach(
                        (message)=>{
                          if(message.msg.indexOf('Reggora order')!==-1)
                            reggoraOrder = true;
                        }
                      )
                      if(reggoraOrder)
                        confirmation(
                          ()=>{cancelOrder(true)},
                          ()=>{cancelOrder()},
                          'Order Cancellation',
                          'This is a Reggora orders. Do you want to initiate cancellation request on Reggora?'
                        )
                      else
                        cancelOrder()
                    }}>Cancel order</Button>
                  </center>
                </Collapse>
              </CardBody>
            </Card>
            <br/>
            <Card>
              <CardHeader className={state.moreActionChoice==='6'?"header-color  cursor-pointer":"gray-color  cursor-pointer"} onClick={(e)=>selectMoreAction('6')}>
                <i className="fa fa-plus green-color"></i> Transfer Order
              </CardHeader>
              <CardBody style={{padding:'0px'}}>
                <Collapse isOpen={state.moreActionChoice==='6'} className="padding">
                  <label><font color="red">*</font>Transfering the order will copy all existing comment/contact/file into the target order of reference number.<br/>All integration link will be transfer to the target and this order will be cancelled (No cancellation email will be sent).</label><br/>
                  <br/>
                  <label>Target Reference #</label><br/>
                  <Input required={true} type="text" value={state.transferOrderReference} onChange={(e)=>setState({transferOrderReference:e.target.value})}/>
                  <br/>
                  <br/>
                  <center>
                    <Button color="warning" onClick={(e)=>{
                      if(state.transferOrderReference==='')
                        showMessage('error', 'Please fill in the reference number of the order you want to transfer to.');
                      else
                        confirmation(
                          ()=>{transferOrder()},
                          ()=>{},
                          'Initiate transfer?',
                          'Please make sure the reference number you provided is correct and want to proceed with the transfer process.'
                        );
                    }}>Transfer order</Button>
                  </center>
                </Collapse>
              </CardBody>
            </Card>
            <br/>
            <Card>
              <CardHeader className={state.moreActionChoice==='5'?"header-color  cursor-pointer":"gray-color  cursor-pointer"} onClick={(e)=>selectMoreAction('5')}>
                <i className="fa fa-plus green-color"></i> Modify order status
              </CardHeader>
              <CardBody style={{padding:'0px'}}>
                <Collapse isOpen={state.moreActionChoice==='5'} className="padding">
                  <label><font color="red">*</font>Modifying the order status will have impact on other variables like dates and assignment.</label><br/>
                  <label><font color="red">*</font>Reseting order to "Submitted" will reset the assignment and the dates will be overridden later when the order move on through the pipeline.</label><br/>

                  <br/>
                  <Row>
                    <Col sm="4">
                      <label>New order status</label>
                    </Col>
                    <Col sm="4">
                      <MySelect
                        modal={true}
                        type="select"
                        value={state.moreActionOrderStatus}
                        onChange={(v)=>{setState({moreActionOrderStatus:v})}}
                        options={
                          state.statuses.map((status)=>{
                          if(status.name!=='Cancelled'&&status.name!=='Hold')
                            return {label:status.name, value:status.name};
                          else
                            return null;
                          }).filter(options=>options)}
                        />
                    </Col>
                    <Col sm="4" className="align-right">
                      <Button color="warning" onClick={updateOrderStatus}>Update</Button>
                    </Col>
                  </Row>
                  {state.moreActionOrderStatus==='Submitted'?<div>
                    <FormGroup check className="display-inline">
                      <Label check>
                        <Input type="checkbox" checked={state.haltAutoBidOnUpdate} onClick={(e)=>setState({haltAutoBidOnUpdate:!state.haltAutoBidOnUpdate})}/>
                      </Label>
                    </FormGroup> <font color="red">**</font>Halt auto bid before updating status - This will prevent the order goes directly into auto bid if you want to bid it out manually.
                      
                    </div>:null}
                </Collapse>
              </CardBody>
            </Card>
          </div>
          <br/>
          <center>
            <Button color="info" onClick={toggleMoreAction}>Close</Button>
          </center>
        </ModalBody>
      </Modal>

      <Modal className="my-modal" isOpen={state.hotfileDropDownOpen} toggle={toggleHotfile} >
        <ModalHeader hidden={true} toggle={toggleHotfile}></ModalHeader>
        <ModalBody>
          <center>
            <h5><i className="fa fa-warning"></i> Hotfile</h5>
          </center>
          <br/>
          {personalHotfileHistories}
          <label>Category</label><br/>
          <MySelect
            modal={true}
            type="select"
            value={state.hotfile.subCategory}
            onChange={(v)=>{let hotfile = Object.assign({},state.hotfile);hotfile.subCategory = v;setState({hotfile})}}
            options={state.hotfileSubCategory.map((category)=>{
              return {label:category.sub_category, value:category.sub_category};
            })}
            />
          <br/>
          <label>Comment</label><br/>
          <Input type="textarea" value={state.hotfile.comment} onChange={(e)=>{let hotfile = Object.assign({},state.hotfile);hotfile.comment = e.target.value;setState({hotfile})}} className="new-comment-reply" rows="3"/>

          <div className="my-divider">&nbsp;</div>

          <div>
            <label>Target</label><br/>
              <MySelect
                modal={true}
                type="select"
                value={state.hotFileUserFk}
                onChange={(v)=>{setState({hotFileUserFk:v})}}
                options={state.admins.map((admin)=>{
                  return {label:admin.first_name+' '+admin.last_name, value:admin.user_fk};
                })}
                />
            <br/>

            <Row>
              <Col sm="6">
                <label>Set Reminder</label><br/>
                <Toggle
                  checked={state.hotfileSetReminder}
                  icons={false}
                  onChange={handleHotfileReminderChange} />&nbsp;
              </Col>
              <Col sm="6">
                <label>Copy for myself</label><br/>
                <Toggle
                  checked={state.hotfileSetReminderSelf}
                  icons={false}
                  onChange={handleHotfileReminderSelfChange} />&nbsp;
              </Col>
            </Row>
            <br/>
            {hotfileReminderInterface}
          </div>
          <center>
            <Button color="warning" disabled={state.personalHotfileSubmit} onClick={personalHotfileButtonHandler}>Add</Button>&nbsp;
            <Button color="info" onClick={toggleHotfile}>Close</Button>
          </center>
        </ModalBody>
      </Modal>

      <Modal className="my-modal" isOpen={state.showSmsNotifications} toggle={toggleSMSNotification} >
        <ModalHeader hidden={true} toggle={toggleSMSNotification}></ModalHeader>
        <ModalBody>
          <center>
            <h5><i className="fa fa-warning"></i> SMS Notifications</h5>
          </center>
          <br/>
          <Card>
            <CardHeader>
              Phone Number Subscribed To SMS Notification
            </CardHeader>
            <CardBody>
              {
                state.smsNotifications.map(
                  (smsSubscriber, index)=>{
                    return <div key={index}>
                      <i className="fa fa-times cursor-pointer red-color" onClick={(e)=>unsubscribeSMSNotification(smsSubscriber.ID)}/> {smsSubscriber.phone} - {formatDateTime(smsSubscriber.datetime_created)}
                    </div>
                  }
                )
              }
            </CardBody>
          </Card>
          <br/><br/>
          <div className="my-divider">&nbsp;</div>
          <Card>
            <CardHeader>
              <i className="fa fa-plus green-color"/> Register New Phone Number For SMS Notification
            </CardHeader>
            <CardBody>
              <Row>
                <Col sm="8">
                  <label>Phone Number</label><br/>
                  <Input type="phone" value={state.smsNotificationPhone} onChange={(e)=>setState({smsNotificationPhone:e.target.value})}/>
                </Col>
                <Col sm="4" className="align-right">
                  <br/>
                  <Button color="warning" onClick={subscribeSMSNotification}>Subscribe</Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <center>
            <Button color="info" onClick={toggleSMSNotification}>Close</Button>
          </center>
        </ModalBody>
      </Modal>

      <Modal className="my-modal" isOpen={state.showModificationLog} toggle={toggleModificationLog} >
        <ModalHeader hidden={true} toggle={toggleModificationLog}></ModalHeader>
        <ModalBody>
          <center>
            <h5><i className="fa fa-warning"></i> Client Order Details Modification Log</h5>
          </center>
          <br/>

          {
            props.appraisal.modification_log.map(
              (modification, index)=>{
                let markSeen;

                if(modification.seen==='no')
                  markSeen = <NavLink to="#" onClick={(e)=>markModificationLogAsSeen(modification.ID)}><i className="fa fa-check green-color"/> Mark as seen</NavLink>;
                return <div key={index}>
                  {markSeen}
                  <br/>
                  <label>Author: </label>{modification.author}<br/>
                  <label>Date: </label>{formatDateTime(modification.datetime_created)}
                  <br/>
                  <Table className="table table-striped">
                    <thead>
                      <th>Field</th>
                      <th>Old Value</th>
                      <th>New Value</th>
                    </thead>
                    <tbody>
                      {
                        modification.entries.map(
                          (entry, index2)=>{
                            return <tr key={index2}>
                              <td className={entry.field==='appraisal_type'?'red-color flashit':''}>{entry.field.split('_').join(' ')}</td>
                              <td>{entry.old_value}</td>
                              <td>{entry.value}</td>
                            </tr>
                          }
                        )
                      }
                    </tbody>
                  </Table>
                  <div className="my-divider"/>
                  <br/>
                </div>
              }
            )
          }

          <center>
            <Button color="info" onClick={toggleModificationLog}>Close</Button>
          </center>
        </ModalBody>
      </Modal>

      <Modal className="my-modal" isOpen={state.nuclearfileDropDownOpen} toggle={toggleNuclearfile} >
        <ModalHeader hidden={true} toggle={toggleNuclearfile}></ModalHeader>
        <ModalBody>
          <center>
            <h5><i className="fa fa-warning"></i>Nuclear File</h5>
          </center>
          {addRemoveNuclearfile}
          <center>
            <Button color="info" onClick={toggleNuclearfile}>Close</Button>
          </center>
        </ModalBody>
      </Modal>

      <Modal className="my-modal" isOpen={state.snoozeHotfileDropDownOpen} toggle={snoozeHotfileToggle} >
        <ModalHeader hidden={true} toggle={snoozeHotfileToggle}></ModalHeader>
        <ModalBody>
          <center>
            <h5><i className="fa fa-warning"></i> Snooze Reminder</h5>
          </center>
          <br/>
          <label>Snooze Reminder For</label><br/>
          <MySelect
            type="select"
            modal={true}
            value={state.hotfileReminderSnooze}
            onChange={(v)=>{setState({hotfileReminderSnooze:v})}}
            options={[
              {label:"1 Hour",value:1},
              {label:"2 Hour",value:2},
              {label:"3 Hour",value:3},
              {label:"4 Hour",value:4},
              {label:"5 Hour",value:5},
              {label:"6 Hour",value:6},
              {label:"7 Hour",value:7},
              {label:"8 Hour",value:8},
              {label:"9 Hour",value:9},
              {label:"10 Hour",value:10},
              {label:"11 Hour",value:11},
              {label:"12 Hour",value:12},
              {label:"13 Hour",value:13},
              {label:"14 Hour",value:14},
              {label:"15 Hour",value:15},
              {label:"16 Hour",value:16},
              {label:"17 Hour",value:17},
              {label:"18 Hour",value:18},
              {label:"19 Hour",value:19},
              {label:"20 Hour",value:20},
              {label:"21 Hour",value:21},
              {label:"22 Hour",value:22},
              {label:"23 Hour",value:23},
              {label:"24 Hour",value:24},
            ]}
            />
          <br/><br/><br/>
          <center>
            <Button color="warning" onClick={snoozeHotfile}>Snooze</Button>&nbsp;
            <Button color="info" onClick={snoozeHotfileToggle}>Close</Button>
          </center>
        </ModalBody>
      </Modal>

      <Modal className="my-modal" isOpen={state.globalHotfileDropDownOpen} toggle={toggleglobalHotfile} >
        <ModalHeader hidden={true} toggle={toggleglobalHotfile}></ModalHeader>
        <ModalBody>
          <center>
            <h5><i className="fa fa-warning"></i> Global Hotfile</h5>
          </center>
          <br/>
          <Nav tabs>
            <NavItem>
              <NavLinkL
                className="nav-link" active={state.globalHotfileActiveTab==='1'} onClick={() => { setState({globalHotfileActiveTab:'1'}) }} to="#"
              >
                Global Hotfile
              </NavLinkL>
            </NavItem>
            <NavItem>
              <NavLinkL
                className="nav-link" active={state.globalHotfileActiveTab==='2'} onClick={() => { setState({globalHotfileActiveTab:'2'}) }} to="#"
              >
                Snooze Hotfile
              </NavLinkL>
            </NavItem>
          </Nav>

          <TabContent activeTab={state.globalHotfileActiveTab}>
            <TabPane tabId="1">
              {addRemoveGlobalHotfile}
            </TabPane>
            <TabPane tabId="2">

              <font color="red">*</font> Once you snooze the hotfile, system won't be adding the order to the hotfile until the specified date.<br/>
              <br/>
              <div clasName="display-inline">
                <label>Snooze Hotfile Until</label><br/>
                <DatePicker
                  className="xeditable-input-datetime-gray"
                  showTimeSelect
                  selected={globalDateSnooze}
                  timeIntervals={15}
                  onChange={(text)=>{setState({globalDateSnooze:text.toLocaleDateString('en-CA')})}}
                  dateFormat='MM/dd/yyyy HH:mm'
                />&nbsp;&nbsp;<Button color="warning" onClick={updateGlobalSnooze}>Submit</Button>
              </div>
              <br/><br/><br/><br/><br/><br/><br/><br/>
            </TabPane>
          </TabContent>

          <center>
            <Button color="info" onClick={toggleglobalHotfile}>Close</Button>
          </center>
        </ModalBody>
      </Modal>

      <Modal className="my-modal" isOpen={state.manualReviewDropDownOpen} toggle={toggleManualReview} >
        <ModalHeader hidden={true} toggle={toggleManualReview}></ModalHeader>
        <ModalBody>
          <center>
            <h5><i className="fa fa-warning"></i> Manual review</h5>
          </center>
          <br/>
          {addRemoveManualReview}
          <center>
            <Button color="info" onClick={toggleManualReview}>Close</Button>
          </center>
        </ModalBody>
      </Modal>
      <Modal className="my-modal" isOpen={state.crmDropDownOpen} toggle={toggleCRM} >
                <ModalHeader hidden={true} toggle={toggleCRM}></ModalHeader>
                <ModalBody>
                  <center>
                    <h5><i className="fa fa-warning"></i> CRM</h5>
                  </center>
                  <br/>
                  {addRemoveCRM}
                  <center>
                    <Button color="info" onClick={toggleCRM}>Close</Button>
                  </center>
                </ModalBody>
              </Modal>
      <Modal className="my-modal" isOpen={state.internalEscalationDropDownOpen} toggle={toggleInternalEscalation} >
        <ModalHeader hidden={true} toggle={toggleInternalEscalation}></ModalHeader>
        <ModalBody>
          <center>
            <h5><i className="fa fa-warning"></i> Internal Escalation</h5>
          </center>
          <br/>
          {addRemoveInternalEscalation}

          <center>
            <Button color="info" onClick={toggleInternalEscalation}>Close</Button>
          </center>
        </ModalBody>
      </Modal>
      <Modal className="my-modal" isOpen={state.expediteDropDownOpen} toggle={toggleExpedite} >
        <ModalHeader hidden={true} toggle={toggleExpedite}></ModalHeader>
        <ModalBody>
          <center>
            <h5><i className="fa fa-warning"></i> Expedite Order</h5>
          </center>
          <br/>
          {addRemoveExpedite}
          <center>
            <Button color="info" onClick={toggleExpedite}>Close</Button>
          </center>
        </ModalBody>
      </Modal>
    </div>
  </div>;
}


export default AppraisalActions;
