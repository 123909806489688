//Author Sooyoung Kim
//Date June 2, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, deleteAPICallGenerator, callBackGenerator, confirmation, showMessage, formatDateTime, sliceFromArray} from '../../util/util';
import {Modal, ModalHeader, ModalBody, Button, Row, Col, FormGroup, Label, Input, Card, CardHeader, CardBody, Table} from 'reactstrap';
import FileSaver from 'file-saver';
import {NavLink} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';


//initialize the state
const initialState = {
  id: -1,
  appraisalFiles:[],
  fileToSend:[],
  ableButton: true,
  ableButtonSSR: true,
  fileTypes:[],
  needInvoice: false,
  popUpOpen: false,
  appraisal:{},
  entities:[],
  selectedRecipient:-1,
  targetRecipients:[],
  shippedFiles:[],
  accounts:[],
  extraRecipients:'',
  emailCert:false
};

//reducer function that perform state update
const reducer = getReducer();


const Shipping  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpDelete = deleteAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{


    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  //toggle function
  const togglePopUp = ()=>{
    if(Object.keys(state.appraisal).length===0){
      //extract the ID
      let url = window.location.href;
      let tokens = url.split('/');
      let ID = tokens[tokens.length-1];
      setState({id:ID});

      getFileTypes();
      getAppraisal(ID);
      getAppraisalFiles(ID);
      getShippedFiles(ID);
      getShippingAccounts(ID);
    }
    if(state.popUpOpen){
      setState({
        appraisal:{},
        emailCert:false,
        fileToSend:[],
        ableButton:true,
        ableButtonSSR:true,
        needInvoice:false,
        fileTypes:[],
        appraisalFiles:[],
        entities:[],
        accounts:[],
        targetRecipients:[],
        selectedRecipient:[],
        shippedFiles:[],
        extraRecipients:'',
      });
    }
    setState({popUpOpen:!state.popUpOpen});
  }

  //add all entity associated with this appraisal to target recipient on receiving file upload
  const addAllToTargetRecipients = ()=>{
    let allRecipients = state.entities.slice();
    setState({targetRecipients:allRecipients});
  }

  //remove a recipient from target recipient on receiving file upload
  const removeFromTargetRecipients = (id)=>{
    for(let i =0;i<state.targetRecipients.length;i++){
      if(state.targetRecipients[i].ID===id){
        let newTargetRecipients = state.targetRecipients.slice();
        newTargetRecipients.splice(i,1);

        setState({targetRecipients:newTargetRecipients});
      }
    }
  }

  //add a new entity associated with appraisal to target recipient on receiving file upload
  const addNewRecipients = (ID)=>{
    if(ID==='All')
      addAllToTargetRecipients();
    else{
      for(let i =0;i<state.targetRecipients.length;i++){
        if(state.targetRecipients[i].ID===ID)
          return;
      }
      let existingNewTargetRecipients = state.targetRecipients.slice();

      let targetEntity = null;
      for(let i=0;i<state.entities.length;i++){
        if(state.entities[i].ID===ID){
          targetEntity = state.entities[i];
          break;
        }
      }

      if(targetEntity){
        existingNewTargetRecipients.push(targetEntity);
        setState({targetRecipients:existingNewTargetRecipients});
      }
    }
  }

  const modifyFilesToSend = (checked,ID)=>{
    let fileToSend = state.fileToSend.slice();

    if(checked){
      let index = fileToSend.indexOf(ID);

      if(index===-1)
        fileToSend.push(ID);
    }
    else{
      let index = fileToSend.indexOf(ID);

      if(index!==-1)
        fileToSend.splice(index,1);
    }

    setState({fileToSend:fileToSend});

    if(state.appraisal.is_reggora === 'yes'){
      checkFiles(fileToSend);
    }else if(state.appraisal.client_fk === '477' && state.appraisal.loan_type === 'FHA'){
      checkSSRFiles(fileToSend);
    }else{
      if(!state.ableButton)
        setState({ableButton:true});
    }
  }

  const checkSSRFiles = (arr)=>{
    let ssr_fha = false;
    let ssr_fre = false;
    let ssr_fnm = false;
    let ableButton;

    let set = new Set();

    arr.forEach(id => {
      set.add(id);
    });

    if(state.appraisalFiles.length>0){
      let appFiles = state.appraisalFiles;


      appFiles.map(obj => {
        if (set.has(obj['ID'])) {
          let thisFileType = obj['file_type'];

          if(thisFileType==='SSR - FNM'){
            ssr_fnm = true;
          }else if(thisFileType==='SSR - FRE'){
            ssr_fre = true;
          }else if(thisFileType==='SSR - FHA'){
            ssr_fha = true;
          }
        }
        return null;
      });
    }
    if(ssr_fnm && ssr_fre && ssr_fha){
      ableButton = true;
    }else if(!ssr_fnm && !ssr_fre && !ssr_fha){
      ableButton = true;
    }else{
      ableButton = false;
    }
    setState({ableButtonSSR:ableButton});

  }

  const checkFiles = (arr)=>{
    let hasInvoice = false;
    let needInvoice = false;
    let ableButton;
    let set = new Set();

    arr.forEach(id => {
      set.add(id);
    });

    if(state.appraisalFiles.length>0){
      let appFiles = state.appraisalFiles;
      // console.log(arr);

      appFiles.map(obj => {
        if (set.has(obj['ID'])) {
          let thisFileType = obj['file_type'];

          if(thisFileType==='Invoice - Home VMS'){
            hasInvoice = true;
          }

        }
        return null;
      });
    }
    if(hasInvoice&&needInvoice){
      //console.log("it's a invoice file");
      ableButton = true;
    }else if(!needInvoice){
      ableButton = true;
    }else{
      ableButton = false;
    }
    setState({ableButton:ableButton,needInvoice:needInvoice});
  }


  //API call
  //download the file when user click on the file name using blob
  const downloadFile = (name)=>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let byteCharacters = atob(response.data.data);
  			let byteNumbers = new Array(byteCharacters.length);
  			for (let i = 0; i < byteCharacters.length; i++) {
  				byteNumbers[i] = byteCharacters.charCodeAt(i);
  			}
  			let byteArray = new Uint8Array(byteNumbers);
  			let data = new Blob([byteArray]);
  			FileSaver.saveAs(data, name);
      }
    };

    let parameters = [
      {
        field:'parentFk',
        value:state.id
      },
      {
        field:'name',
        value:name
      },
      {
        field:'directory',
        value:'Orders'
      }
    ];

    httpPost('file/download', parameters, '', 'Oops, something went wrong and could not download the file "'+name+'". Please try again later.', callBack);
  }

  //get appraisal
  const getAppraisal = (id)=>{
   let callBack = (response)=>{
     let code = response.data?response.data.code:undefined;
     if(code==='00'){
       setState({appraisal:response.data.data});
       if(response.data.data.client_shipping_note && response.data.data.client_shipping_note!=='')
         showMessage('notification', response.data.data.client_shipping_note);

       let entities = [];
       for(let i=0;i<response.data.data.entities.length;i++){
         let entity = response.data.data.entities[i];
         entities.push(entity);
       }

       //add borrower
       let tmp = {};
       tmp.ID = -1;
       tmp.entity_label = 'Borrower';
       tmp.entity_name = response.data.data.borrower_f_name+' '+response.data.data.borrower_l_name;
       tmp.entity_email = response.data.data.borrower_email;

       entities.push(tmp);

       setState({entities:entities});
     }
   };
   callBack = callBack.bind(this);

   httpGet('appraisal/'+id, '', 'Oops, something went wrong and could not load this appraisal. Please try again later.', callBack);
 }

  //delete file
  const deleteShippingFile = (ID)=>{
    let list = sliceFromArray(state.shippedFiles,'appraisal_file_fk', ID);
    let callBack = apiCallBack([{state:'appraisers', value:list}]);
    httpDelete('shipping/file/'+ID,'File deleted from shipping portal.','Oops, something went wrong and could not delete the file, please try again later.', callBack);
  }

  //delete file
  const deleteFileAccess = (ID,email)=>{
   let list = sliceFromArray(state.shippedFiles, ['appraisal_file_fk','email'],[ID, email], ['emails']);
   let callBack = apiCallBack([{state:'shippedFiles', value:list}]);
   httpDelete('shipping/file/access/'+ID+'/'+email,'File access removed from '+email+'.','Oops, something went wrong and could not remove the file access, please try again later.', callBack);
  }

  //delete file
  const deleteAccount = (ID)=>{
   let list = sliceFromArray(state.accounts,'ID', ID);
   let callBack = apiCallBack([{state:'accounts', value:list}]);
   httpDelete('shipping/account/'+ID,'Account deleted.', 'Oops, something went wrong and could not delete the account, please try again later.', callBack);
  }

  const ship = ()=>{
   let emails = [];

   for(let i=0;i<state.targetRecipients.length;i++)
     emails.push(state.targetRecipients[i].entity_email);

   let parameters = [
     {
       field:'appraisalFk',
       value:state.id
     },
     {
       field:'files',
       value:state.fileToSend
     },
     {
       field:'emails',
       value:emails
     },
     {
       field:'extraRecipients',
       value:state.extraRecipients
     }
   ];

   if(state.emailCert){
     let tmp = {
       field:'insertEmailCertificate',
       value:'yes'
     }
     parameters.push(tmp);
   }

   let callBack = (response)=>{
     let code= response.data.code;
     if(code!=='00'&&code!=='01'){

     }
     else{
       let files = response.data.data.files;
       let accounts = response.data.data.accounts;
       let shippedFiles = state.shippedFiles.slice();
       let currentAccounts = state.accounts.slice();

       for(let i=0;i<accounts.length;i++){
         currentAccounts.push(accounts[i]);
       }

       setState({accounts:currentAccounts});

       for(let i=0;i<files.length;i++){
         let fileExist = false;
         for(let j=0;j<shippedFiles.length;j++){
           if(shippedFiles[j].appraisal_file_fk===files[i].appraisal_file_fk){
             fileExist = true;

             //merge two array
             let existingEmails = shippedFiles[j].emails.slice();
             let newEmails = existingEmails.concat(files[i].emails);

             let newEmailsNoDuplicate = [];

             for(let k=0;k<newEmails.length;k++){
               let index = -1;
               for(let l=0;l<newEmailsNoDuplicate.length;l++){
                 if(newEmailsNoDuplicate[l].email===newEmails[k].email)
                   index = l;
               }
               if(index===-1)
                 newEmailsNoDuplicate.push(newEmails[k]);
             }

             //update the emails array
             shippedFiles[j].emails = newEmailsNoDuplicate;
           }
         }
         if(!fileExist)
           shippedFiles.push(files[i]);
       }

       setState({
         shippedFiles:shippedFiles,
         emailCert:false,
         fileToSend:[],
         targetRecipients:[],
         selectedRecipient:[],
         extraRecipients:'',
       });
     }
   };
   callBack = callBack.bind(this);

   httpPost('shipping/create', parameters, 'Files shipped successfully.', 'Oops, something went wrong and could not shipped files. Please try again later.', callBack);
  }

  //retrieve a list of appraisal files that ties to a specific appraisal
  const getAppraisalFiles = (id)=>{
   let callBack = apiCallBack([{state:'appraisalFiles', key:'data'}]);
   httpGet('file/get/'+id, '', 'Oops, something went wrong and could not load appraisal files for this appraisal. Please try again later.', callBack);
  }

  //retrieve a list of appraisal files that ties to a specific appraisal
  const getShippingAccounts = (id)=>{
   let callBack = apiCallBack([{state:'accounts', key:'data'}]);
   httpGet('shipping/account/'+id, '', 'Oops, something went wrong and could not load appraisal files for this appraisal. Please try again later.', callBack);
  }

  //retrieve a list of appraisal files that ties to a specific appraisal
  const getShippedFiles = (id)=>{
   //Call Back
   let callBack = (response)=>{
     let code = response.data?response.data.code:undefined;
     if(code==='00'){
       response.data.data.reverse();
       setState({shippedFiles:response.data.data});
     }
   };
   callBack = callBack.bind(this);
   httpGet('shipping/file/get/'+id, '', 'Oops, something went wrong and could not load appraisal files for this appraisal. Please try again later.', callBack);
  }

  //retrieve a list of file types
  const getFileTypes = ()=>{
   let callBack = apiCallBack([{state:'fileTypes', key:'data'}]);
   httpGet('file/fileType/get', '', 'Oops, something went wrong and could not load appraisal file types. Please try again later.', callBack);
  }


  //render
  let appraisalFiles;

  if(state.appraisalFiles.length>0){
    appraisalFiles = state.appraisalFiles.map(
      (appraisalFile,index)=>{

        return(
          <div key={index}>
            <div className="display-inline">
              <FormGroup check>
                <Label check>
                  <Input type="checkbox" checked={state.fileToSend.indexOf(appraisalFile.ID)!==-1} onClick={(e)=>modifyFilesToSend(e.target.checked,appraisalFile.ID)}/>&nbsp;
                </Label>
              </FormGroup>
            </div>
            {formatDateTime(appraisalFile.datetime_created)} - {appraisalFile.name}<br/>
          </div>
        );
      }
    );
  }

  let targetRecipients;
  if(state.targetRecipients){
    targetRecipients = state.targetRecipients.map(
      (recipient)=>{
        return(
          <div style={{display:'inline-block'}} key={recipient.ID}>
            <div className="entity-container cursor-pointer" onClick={()=>{removeFromTargetRecipients(recipient.ID)}}>
              <i className="fa fa-minus link-color"></i>&nbsp;{recipient.entity_name}
            </div>&nbsp;
          </div>
        );
      }
    );
  }

  let entities;
  if(state.entities){
    entities = state.entities.map(
      (entity, index)=>{
        return (
          <tr key={index} className="cursor-pointer" onClick={(e)=>addNewRecipients(entity.ID)}><td><b>{entity.entity_label}</b></td><td>{entity.entity_name} - {entity.entity_email}</td></tr>
        );
      }
    );
  }

  let selectedRecipientUI;
  if(state.appraisal&&state.appraisal.client_fk&&state.appraisal.client_fk!=='1'&&state.appraisal.client_fk!=='307')
    selectedRecipientUI = <Card>
      <CardHeader className="gray-color">
        Select Recipient(s)
      </CardHeader>
      <CardBody>
        <div className="small-scroll-container-ex-large">
          <Table hover className="table">
            <tbody>
              {entities}
            </tbody>
          </Table>
        </div>
        <br/>
        <label>Extra recipients</label><br/>
        <Input type="text" value={state.extraRecipients} onChange={(e)=>setState({extraRecipients:e.target.value})} placeholder="Extra recipients, separated by comma"/>

        <div className="small-scroll-container">
          {targetRecipients}
        </div>
        <div className="padding display-inline" onClick={(e)=>{setState({emailCert:e.target.checked})}}>
          <Label check>
            <Input type="checkbox"/>&nbsp;Include Email Certificate
          </Label>
        </div>
      </CardBody>
    </Card>;
  return <div>
    <div className="cursor-pointer" onClick={togglePopUp}><i className="fa fa-truck"></i> Shipping</div>
      <Modal className="my-modal-wide" isOpen={state.popUpOpen} toggle={togglePopUp} >
        <ModalHeader hidden={true} toggle={togglePopUp}></ModalHeader>
        <ModalBody>
          <center>
            <h5><i className="fa fa-search"></i> Shipping - {state.appraisal.reference_num}</h5>
          </center>
          <br/>
          <Card>
            <CardHeader className="header-color">
              New Shipping Entry
            </CardHeader>
            <CardBody>
              <Row>
                <Col sm="6">
                <Card>
                  <CardHeader className="gray-color">
                    Select Document(s) To Ship
                  </CardHeader>
                  <CardBody>
                    <div className="large-scroll-container">
                      {appraisalFiles}
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col sm="6">
                {selectedRecipientUI}
              </Col>
            </Row>
            <br/>
            <div className="align-right">
              {!state.ableButton&&<h6 style={{ color: 'red' }}>** Missing attachment: must contain INVOICE file. **</h6>}
              {!state.ableButtonSSR&&<h6 style={{ color: 'red' }}>** Missing attachment: must include SSR reports(SSR-FHA, SSR-FRE, SSR-FNM). **</h6>}
              <Button color="warning" onClick={ship} disabled={!state.ableButton || !state.ableButtonSSR}><i className="fa fa-check"></i> Ship</Button>&nbsp;
            </div>
          </CardBody>
        </Card>
        <br/>
        <Card>
          <CardHeader className="header-color">
            Current Shipping Entry
          </CardHeader>
          <CardBody>
            <Row>
              <Col sm="4">
                <Card>
                  <CardHeader className="gray-color">
                    Existing Shipping Recipient(s)
                  </CardHeader>
                  <CardBody>
                    <div className="medium-scroll-container">
                      <table className="table table-striped">
                        <tbody>
                          {
                            state.accounts.map(
                              (account,index)=>{
                                return(
                                  <tr key={index}>
                                    <td><i className="fa fa-trash red-color cursor-pointer" onClick={(e)=>{
                                      confirmation(
                                      ()=>{deleteAccount(account.ID)},
                                      ()=>{},
                                      'Delete shipping account?',
                                      'Are you sure you want to delete this shipping account?');}}></i> {account.email}</td>
                                    <td>{account.password}</td>
                                  </tr>
                                );
                              }
                            )
                          }
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col sm="8">
                <Card>
                  <CardHeader className="gray-color">
                    Existing Shipping Document(s)
                  </CardHeader>
                  <CardBody>
                    <div className="medium-scroll-container">
                      <table className="table table-striped">
                        <tbody>
                          {
                            state.shippedFiles.map(
                              (file,index)=>{
                                let fileRecipients = file.emails.map(
                                  (email,index2)=>{
                                    return(
                                      <div style={{display:'inline-block'}} key={index2}>
                                        <div className="entity-container cursor-pointer" onClick={()=>{
                                          confirmation(
                                            ()=>{deleteFileAccess(file.appraisal_file_fk, email.email)},
                                            ()=>{},
                                            'Delete file access?',
                                            'Are you sure you want to delete the file access from this user?');
                                        }}>
                                          <i className="fa fa-minus link-color"></i>&nbsp;{email.email}
                                        </div>&nbsp;
                                      </div>
                                    );
                                  }
                                );

                                return(
                                  <tr key={index}>
                                    <td>
                                      <b><i className="fa fa-trash red-color cursor-pointer" onClick={(e)=>{
                                        confirmation(
                                          ()=>{deleteShippingFile(file.appraisal_file_fk)},
                                          ()=>{},
                                          'Delete file?',
                                          'Are you sure you want to delete this file?');
                                        }}></i>{formatDateTime(file.datetime_created)} - {file.file_type}</b><br/>
                                      <NavLink to="#" onClick={(e)=>downloadFile(file.name)}><b>{file.name}</b></NavLink><br/>

                                      {fileRecipients}
                                    </td>
                                  </tr>
                                );
                              }
                            )
                          }
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <div className="my-divider"></div>
        <center>
          <Button color="info" onClick={togglePopUp}>Close</Button>
        </center>
      </ModalBody>
    </Modal>
  </div>;
}

export default Shipping;
