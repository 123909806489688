//Author Sooyoung Kim
//Date May 11th, 2022
//The component for viewing a list of procedure(S)
import {formatDate, getReducer, getSetStateFunction, getAPICallGenerator} from '../../util/util';
import InfiniteScroll from 'react-infinite-scroll-component';
import {NavLink} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';
import {Row, Col, Input, Button} from 'reactstrap';
import './procedure.css';

//initialize the state
const initialState = {
  procedures:[],
  limit: 25,
  offset: 0,
  hasMoreProcedures: true,
  loading: false,
  sort: 'title',
  order: 'ASC',
  title: '',
};

//reducer function that perform state update
const reducer = getReducer();


const Procedures  = (props)=>{
  const controller = new AbortController();
  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{

    if(state.procedures.length<=0 && state.hasMoreProcedures){
        loadMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state]);

  useEffect(()=>{

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //function that manipulate state
  //this function refresh the list of admins pipeline
  const refreshList = ()=>{
    setState({
      procedures:[],
      hasMoreProcedures :true,
      offset:0
    });
  }

  //click handler that switch the state of sorting order then call the function to refresh the list
  //to achieve the "sorting"
  const columnClickHandler = (col)=>{
    if(state.sort===col){
      if(state.order==='ASC')
        setState({order:'DESC'});
      else
        setState({order:'ASC'});
    }
    else
      setState({order:'ASC', sort:col});
    refreshList();
  }

  //non API call but simpyl manage state

  //this function render the sort icon at each column of the table
  const renderSortIcon = (col)=>{
    if(state.sort===col){
      if(state.order==='ASC')
        return <i className="red-color fa fa-arrow-down"></i>
      else
        return <i className="red-color fa fa-arrow-up"></i>
    }
  }

  //render function for infinite scroller
  const renderProcedures = ()=>{
    return state.procedures.map(
      (procedure)=>{
        return(
          <tr key={procedure.ID}>
              <td><NavLink to={'/procedure/'+procedure.ID}>{procedure.title}</NavLink></td>
              <td>{procedure.creator}</td>
              <td>{formatDate(procedure.datetime_created)}</td>
          </tr>
        );
      }
    );
  }


  //API call
  //function that fire when the infinite scroll reach bottom
  const loadMore = ()=>{
    //do not load if there is no more procedure or it's loading data
    if(state.hasMoreProcedures&&!state.loading){
      //set loading equals to true so it won't fire off before we are done
      setState({loading:true});

      let title = encodeURIComponent(state.title.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));

      let url = 'procedure/get/limit='+state.limit+'&offset='+state.offset+'&order='+state.order+'&sort='+state.sort+'&title='+title;


      //callback handler that update the state when http request return
      let callBack = (response)=>{
        let code = response.data?response.data.code:undefined;

        if(code!=='00'){
          setState({hasMoreProcedures:false});
        }
        else{
          let newprocedures = response.data.data;
          let hasMoreProcedures = true;
          let newOffset = state.offset;

          //if http request return empty then no more results, end of list
          if(newprocedures.length<=0){
            hasMoreProcedures = false;
          }
          else{
            //increment the offset
            newOffset = state.offset + 1;
          }

          //concat the current array of procedure
          if(state.procedures.length>0){
            let temp = [...state.procedures,...newprocedures];

            setState({procedures:temp});
          }
          else
            setState({procedures:newprocedures});

          setState({hasMoreProcedures:hasMoreProcedures, offset:newOffset});
        }
      };

      //error handler when the http request return with error
      let errorCallBack = (response)=>{
        //no more fetching data when error occur
        setState({hasMoreProcedures:false});
      };

      //collect the promise and wait for it to finish performing it's task
      let promises = httpGet(url, '','Oops, something went wrong and could not load procedures. Please try again later.', callBack, errorCallBack);
      promises
        .then(
          function(result){
            //set loading equals to false so the function could be fire off once again
            setState({loading:false});
          }
        );
    }
  }


  let createNewProcedureLink = <div className="align-right"><NavLink to={'/procedure/new'}><i className="fa fa-plus"></i>&nbsp;Create new procedure.</NavLink><br/><br/></div>;

  //render
  return <div>
    <InfiniteScroll
      next={loadMore}
      dataLength={state.procedures.length}
      hasMore={state.hasMoreProcedures}
      loader={<div key="nill" className="loader"><center>Loading more procedures...</center></div>}
      initialLoad = {true}
      className="my-well"
      scrollableTarget="contentContainer"
    >
      <Row>
        <Col sm="6">
          <div className="page-title">
            <i className="fa fa-reorder"></i>&nbsp;Procedures
          </div>
        </Col>
        <Col sm="6">
          {createNewProcedureLink}
        </Col>
      </Row>
      <Row>
        <Col sm="6">
          <label className="no-margin-bottom"><i className="fa fa-search"></i>&nbsp;Search by Title</label>
          <Input type="text" value={state.title} onChange={(e)=>setState({title:e.target.value})}/>
        </Col>
        <Col sm="6">
          <br/>
          <div className="align-right"><Button color="warning" onClick={()=>refreshList()} >Submit</Button></div>
        </Col>
      </Row>
      <br/>
      <table className="primary-table">
        <thead>
          <tr>
            <th className="cursor-pointer" width="60%" onClick={()=>columnClickHandler('title')}><i className="fa fa-list"></i>&nbsp;Title {renderSortIcon('title')}</th>
            <th className="cursor-pointer" width="20%" onClick={()=>columnClickHandler('creator')}><i className="fa fa-user"></i>&nbsp;Creator {renderSortIcon('creator')}</th>
            <th className="cursor-pointer" width="20%" onClick={()=>columnClickHandler('datetime_created')}><i className="fa fa-calendar-o"></i>&nbsp;Date {renderSortIcon('datetime_created')}</th>
          </tr>
        </thead>
        <tbody>
          {renderProcedures()}
        </tbody>
      </table>
    </InfiniteScroll>
  </div>;
}

export default Procedures;
