//Author Sooyoung Kim
//Date April 26, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, callBackGenerator, formatDate, formatNumber, formatDateTime, confirmation} from '../../util/util';
import {Input, Button, Row, Col, Table, Modal, ModalHeader, ModalBody, Nav, NavItem, TabPane, TabContent, NavLink as NavLinkL} from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import {NavLink} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';
import {sliceFromArray} from '../../util/util';
import MySelect from '../util/my-select';

import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

//initialize the state
const initialState = {
  appraisers:[],
  limit: 99,
  offset: 0,
  hasMoreAppraisers: true,
  loading: false,
  sort: 'first_name',
  order: 'ASC',
  status: '',
  statuses: [],
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
  totalCount:0,

  pendingRecommendations:[],
  pendingRequests:[],

  //for search
  name:'',
  company_name:'',
  email:'',
  state:'',
  county:'',
  city:'',

  searchAppraiserResults:[],
  search:'',
  modal:false,
  modal2:false,
  selectedAppraiser:{},
  selectedRequest:{},
  selectedRecommendation:{},
  comment:'',
  activeTab:'1',
  historyOrders:[]
};

//reducer function that perform state update
const reducer = getReducer();


const AppraiserPanelDiscussion  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getAllAppraiserStatuses();
    getPendingRequests();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    if(state.appraisers.length<=0 && state.hasMoreAppraisers){
        loadMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state]);

  //non API call but simpyl manage state
  const selectedRequest = (request) =>{
    setState({selectedRequest:request});
    toggleModal2();
  }

  const selectAppraiser = (appraiser) =>{
    setState({selectedAppraiser:appraiser});
    getAppraiserOrderHistories(appraiser.ID);
  }

  const toggleModal = () =>{
    setState({modal:!state.modal});
  }

  const toggleModal2 = () =>{
    setState({modal2:!state.modal2});
  }

  const activeTabToggle = (tab) =>{
    setState({activeTab:tab});
  }


  //this function render the sort icon at each column of the table
  const renderSortIcon = (col) =>{
    if(state.sort===col){
      if(state.order==='ASC')
        return <i className="red-color fa fa-arrow-down"></i>
      else
        return <i className="red-color fa fa-arrow-up"></i>
    }
  }

  const columnClickHandler = (col) =>{
    if(state.sort===col){
      if(state.order==='ASC')
        setState({order:'DESC'});
      else
        setState({order:'ASC'});
    }
    else
      setState({sort:col,order:'ASC'});
    refreshList();
  }

  //this function refresh the list of orders pipeline
  const refreshList = () =>{
    setState({
      appraisers:[],
      totalCount:0,
      offset:0,
      hasMoreAppraisers:true,
      activeTab:'2'
    });
  }

  //render function for infinite scroller
  const renderAppraisers = () =>{

    return (
      <div>
        <table className="primary-table" style={{fontSize:'11px'}}>
          <thead>
            <tr>
              <th className="cursor-pointer" style={{fontSize:'11px'}} width="10%" onClick={()=>columnClickHandler('concat(first_name," ",last_name)')}><i className="fa fa-user"></i>&nbsp;Name {renderSortIcon('concat(first_name," ",last_name)')}</th>
              <th className="cursor-pointer" style={{fontSize:'11px'}} width="8%" onClick={()=>columnClickHandler('county')}><i className="fa fa-map-marker"></i>&nbsp;County {renderSortIcon('county')}</th>
              <th className="cursor-pointer" style={{fontSize:'11px'}} width="8%" onClick={()=>columnClickHandler('state')}><i className="fa fa-map-marker"></i>&nbsp;State {renderSortIcon('state')}</th>
              <th className="cursor-pointer" style={{fontSize:'11px'}} width="10%"><i className="fa fa-info"></i>&nbsp;Active Appraiser</th>
              <th className="cursor-pointer" style={{fontSize:'11px'}} width="8%"><i className="fa fa-file"></i>&nbsp;Assignment History</th>
              <th className="cursor-pointer" style={{fontSize:'11px'}} width="8%"><i className="fa fa-file"></i>&nbsp;Service Rating</th>
              <th className="cursor-pointer" style={{fontSize:'11px'}} width="8%"><i className="fa fa-file"></i>&nbsp;Quality Rating</th>
              <th className="cursor-pointer" style={{fontSize:'11px'}} width="8%" onClick={()=>columnClickHandler('panel_status')}><i className="fa fa-info"></i>&nbsp;Approval Status {renderSortIcon('panel_status')}</th>
              <th className="cursor-pointer" style={{fontSize:'11px'}} width="9%" onClick={()=>columnClickHandler('requester')}><i className="fa fa-user"></i>&nbsp;Requester {renderSortIcon('requester')}</th>
              <th className="cursor-pointer" style={{fontSize:'11px'}} width="13%" onClick={()=>columnClickHandler('comment')}><i className="fa fa-commenting"></i>&nbsp;Comment {renderSortIcon('comment')}</th>
              <th className="cursor-pointer" style={{fontSize:'11px'}} width="10%" onClick={()=>columnClickHandler('datetime_submitted')}><i className="fa fa-calendar-o"></i>&nbsp;Date Submitted {renderSortIcon('datetime_submitted')}</th>
            </tr>
          </thead>
          <tbody>
          {
            state.appraisers.map(
              (appraiser, index)=>{
                return (
                  <tr key={index}>
                    <td><i className="fa fa-times red-color cursor-pointer" onClick={
                      ()=>{
                        confirmation(
                          ()=>{deletePanelDiscussion(appraiser.panelID)},
                          ()=>{},
                          'Delete appraiser from Panel Discussion?',
                          'Are you sure you want to delete this appraiser profile from the Panel Discussion?');
                      }
                    }></i>&nbsp;&nbsp;<NavLink target="_blank" to={"/appraiser/"+appraiser.ID}>{appraiser.first_name} {appraiser.last_name}</NavLink></td>
                    <td>{appraiser.county}</td>
                    <td>{appraiser.state}</td>
                    <td>{
                      appraiser.active_appraisers.map(
                        (row, index)=>{
                          return <div key={index}>
                            {row.type} - {row.count}
                          </div>
                        }
                      )
                    }</td>
                    <td>{appraiser.order_history}</td>
                    <td>{appraiser.avg_service_rating!=='-1'?appraiser.avg_service_rating+'/5.00':'-'}</td>
                    <td>{appraiser.avg_quality_rating!=='-1'?appraiser.avg_quality_rating+'/3.00':'-'}</td>
                    <td>{appraiser.panel_status.charAt(0).toUpperCase() + appraiser.panel_status.slice(1)}</td>
                    <td>{appraiser.requester}</td>
                    <td><NavLink to={"/appraisal/"+appraiser.appraisal_fk} target="_blank">{appraiser.reference_num}</NavLink><br/>{appraiser.comment}</td>
                    <td>{formatDate(appraiser.datetime_submitted)}</td>
                  </tr>
                );
              }
            )
          }
          </tbody>
        </table>
      </div>
    )
  }

  //API call
  const getPendingRequests = () =>{
    let url = 'appraiser/panelDiscussion/pending/get';

    let callBack = (response)=>{

      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let data = response.data.data;

        for(let i=0;i<data.length;i++){
          let str = '';
          for(let j=0;j<data[i].active_appraisers.length;j++){
            str = str + data[i].active_appraisers[j].type + ' - '+data[i].active_appraisers[j].count+' ';
          }

          data[i].active_appraisers_string = str;
        }
        setState({pendingRequests:data});
      }
    };
    callBack = callBack.bind(this);

    httpGet(url, '', 'Oops, something went wrong and could not load pending recommendations. Please try again later.', callBack);
  }

  //function that fire when the infinite scroll reach bottom
  const loadMore = () =>{
    //do not load if there is no more conditions or it's loading data
    if(state.hasMoreAppraisers&&!state.loading){
      //set loading equals to true so it won't fire off before we are done
      setState({loading:true});

      let company_name = encodeURIComponent(state.company_name.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
      let name = encodeURIComponent(state.name.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
      let email = encodeURIComponent(state.email.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
      let city = encodeURIComponent(state.city.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
      let county = encodeURIComponent(state.county.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));

      let url = 'appraiser/panelDiscussion/get/limit='+state.limit+'&offset='+state.offset+'&order='+state.order+'&sort='+state.sort+'&status='+state.status+'&name='+name+'&company_name='+company_name+'&email='+email+'&state='+state.state+'&county='+county+'&city='+city;

      //callback handler that update the state when http request return
      let callBack = (response)=>{

        console.log(response.data.data);
        let code = response.data?response.data.code:undefined;

        if(code!=='00'){
          setState({hasMoreAppraisers:false});
        }else{
          let newAppraiser = response.data.data;
          let hasMoreAppraisers = true;
          let newOffset = state.offset;
          let totalCount = response.data.count;

          //if http request return empty then no more results, end of list
          if(newAppraiser.length<=0){
            hasMoreAppraisers = false;
          }
          else{
            //increment the offset
            newOffset = state.offset + 1;
          }

          //concat the current array of announcement
          if(state.appraisers.length>0){
            let temp = [...state.appraisers,...newAppraiser];

            setState({appraisers:temp});
          }
          else
            setState({appraisers:newAppraiser});

          setState({hasMoreAppraisers:hasMoreAppraisers});
          setState({offset:newOffset});
          setState({totalCount:totalCount});
        }
      };
      callBack = callBack.bind(this);

      //error handler when the http request return with error
      let errorCallBack = apiCallBack([{state:'hasMoreAppraisers', value:false}]);

      //collect the promise and wait for it to finish performing it's task
      let promises = httpGet(url, '','Oops, something went wrong and could not load appraisers. Please try again later.', callBack, errorCallBack);
      promises
        .then(
          function(result){
            //set loading equals to false so the function could be fire off once again
            setState({loading:false});
          }
        );
    }
  }

  const approval = (approval) =>{
    let url = 'appraiser/panelDiscussion/approval';

    let callBack = (response)=>{console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let selectedRequest = Object.assign({},state.selectedRequest);
        selectedRequest.panel_status = approval;

        if(approval==='approved'||approval==='rejected'){
          let pendingRequests = state.pendingRequests.slice();
          for(let i=0;i<pendingRequests.length;i++){
            if(pendingRequests[i].panelID===state.selectedRequest.panelID){
              pendingRequests.splice(i,1);
              break;
            }
          }
          setState({pendingRequests:pendingRequests});
          setState({totalCount: parseInt(state.totalCount,10)});
        }
        else{
          let pendingRequests = state.pendingRequests.slice();
          for(let i=0;i<pendingRequests.length;i++){
            if(pendingRequests[i].ID===state.selectedRequest.ID){
              pendingRequests[i].panel_status = approval;
              break;
            }
          }
          setState({pendingRequests:pendingRequests});
        }

        setState({selectedRequest:selectedRequest});
        refreshList();
      }
    };
    callBack = callBack.bind(this);

    let parameters =[
      {
        field:'ID',
        value:state.selectedRequest.panelID
      },
      {
        field:'approval',
        value:approval
      }
    ];

    httpPost(url, parameters, 'Appraiser approval status updated.','Oops, something went wrong and could not update the approval status. Please try again later.', callBack);
  }

  const addToPanelDiscussion = (id) =>{
    let url = 'appraiser/panelDiscussion/submit';
    let successMsg = 'Appraiser recommended for Expedite Payment.';
    let errorMsg = 'Oops, something went wrong and could not recommend the appraiser. Please try again later.';

    let callBack = (response)=>{console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let pendingRequests = state.pendingRequests.slice();

        pendingRequests.push(response.data.data);

        let appraisers = state.appraisers.slice();

        appraisers.push(response.data.data);


        setState({pendingRequests:pendingRequests, appraisers:appraisers, totalCount:parseInt(state.totalCount,10)+1});
      }
    };
    callBack = callBack.bind(this);

    let parameters =[
      {
        field:'appraiserFk',
        value:state.selectedAppraiser.ID
      },
      {
        field:'comment',
        value:state.comment
      },
      {
        field:'appraisalFk',
        value:state.appraisalFk
      },
    ];

    httpPost(url,parameters,successMsg,errorMsg, callBack);
  }

  //get a list of appraiser status for the drop down
  const getAllAppraiserStatuses = () =>{
    let url = 'appraiser/statuses/get';
    let callBack = apiCallBack([{state:'statuses', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not retrieve appraiser statuses.', callBack);
  }

  //search appraiser
  const searchAppraiser = () =>{
    let keywword = encodeURIComponent(state.search.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));
    let url = 'appraiser/search/keyword='+keywword;
    let callBack = apiCallBack([{state:'searchAppraiserResults', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not locate the appraiser. Please try again later.', callBack);
  }

  const deletePanelDiscussion = (id) =>{
    let url = 'appraiser/panelDiscussion/update';
    let parameters = [
      {
        field:'field',
        value:'status'
      },
      {
        field:'value',
        value:'inactive'
      },
      {
        field:'ID',
        value:id
      }
    ];

    let appraiserList = sliceFromArray(state.appraisers,'panelID', id);
    let requestList = sliceFromArray(state.pendingRequests,'panelID', id);
    let totalCount = parseInt(state.totalCount,10)-1;
    let callBack = apiCallBack([{state:'appraisers', value:appraiserList},{state:'pendingRequests', value:requestList},{state:'totalCount', value:totalCount}]);

    httpPut(url, parameters, 'Removed from Panel Discussion.', 'Oops, something went wrong and could not remove from panel discussion. Please try again later.', callBack);
  }

  const getAppraiserOrderHistories = (appraserFk) =>{
    let url = 'appraisal/get/history=true&limit=99&offset=0&order=&sort=&appraiser_fk='+appraserFk;
    let callBack = apiCallBack([{state:'historyOrders', key:'data'}]);
    httpGet(url, '', 'Oops, something went wrong and could not load orders. Please try again later.', callBack);
  }

  //render
  let searchAppraiserRows;

  if(state.searchAppraiserResults.length>0){
    searchAppraiserRows = state.searchAppraiserResults.map(
      (appraiser,index)=>{
        return(
          <tr key={index}>
            <td><i onClick={()=>selectAppraiser(appraiser)} className="fa fa-plus link-color cursor-pointer"></i></td>
            <td>{appraiser.first_name+" "+appraiser.last_name}</td>
            <td>{appraiser.email}</td>
            <td>{appraiser.city}</td>
            <td>{appraiser.county}</td>
            <td>{appraiser.state}</td>
          </tr>
        )
      }
    );
  }

  let searchContent;

  if(Object.keys(state.selectedAppraiser).length<=0){
    searchContent =
    <div>
      <Row>
        <Col sm="10">
          <Input type="text" placeholder="Search with Name/Email/Phone/Address" value={state.search} onChange={(e)=>setState({search:e.target.value})}/>
        </Col>
        <Col sm="2" className="align-right">
          <Button color="warning" onClick={searchAppraiser}><i className="fa fa-search"></i>&nbsp;Search</Button>
        </Col>
      </Row>
      <br/>
      <div className="medium-scroll-container">
        <Table className="table table-striped">
          <thead>
            <tr>
              <th width="10%">Action</th>
              <th width="25%">Name</th>
              <th width="15%">Email</th>
              <th width="15%">City</th>
              <th width="15%">County</th>
              <th width="20%">State</th>
            </tr>
          </thead>
          <tbody>
            {searchAppraiserRows}
          </tbody>
        </Table>
      </div>
    </div>;
  }
  else{
    searchContent =
    <div>
      <NavLink to="#" onClick={(e)=>selectAppraiser({})}>Back to search result</NavLink>
      <div className="my-divider"></div>
      <Row>
        <Col sm="4">
          <label>Name</label>
        </Col>
        <Col sm="8">
          {state.selectedAppraiser.first_name+' '+state.selectedAppraiser.last_name}
        </Col>
      </Row>
      <Row>
        <Col sm="4">
          <label>Email</label>
        </Col>
        <Col sm="8">
          {state.selectedAppraiser.email}
        </Col>
      </Row>
      <Row>
        <Col sm="4">
          <label>Address</label>
        </Col>
        <Col sm="8">
          {state.selectedAppraiser.street+' '+state.selectedAppraiser.city+', '+state.selectedAppraiser.state+' '+state.selectedAppraiser.zip}
        </Col>
      </Row>
      <br/>
      <label>Related Appraisal</label>
      <br/>
      <MySelect
        type="select"
        selectIsClearable={true}
        value={state.appraisalFk}
        onChange={(v)=>setState({appraisalFk:v})}
        options={state.historyOrders.map((order)=>{
          return {label:order.reference_num+' - '+order.loan_num+' - '+order.property_street+' '+order.property_city+', '+order.property_state+' '+order.property_zip, value:order.ID};
        })}
      />
      <br/>
      <label>Comment</label>
      <br/>
      <Input type="textarea" placeholder="Please give the reason why you are requesting not to use this appraiser." style={{resize:'none'}} rows="5" value={state.comment} onChange={(e)=>setState({comment:e.target.value})}></Input>

      <br/>
      <div className="align-right">
        <Button color="warning" onClick={addToPanelDiscussion}> Submit Request</Button>
      </div>
    </div>
  }

  return <div>
    <InfiniteScroll
      className="my-well"
      dataLength={state.appraisers.length} //This is important field to render the next data
      next={loadMore}
      hasMore={state.hasMoreAppraisers}
      loader={<center>Loading more appraisers...</center>}
      scrollableTarget="contentContainer"
    >

      <Modal className="my-modal-wide" isOpen={state.modal} toggle={toggleModal} >
        <ModalHeader hidden={true} toggle={toggleModal}></ModalHeader>
        <ModalBody>
          <center>
            <h5><i className="fa fa-cogs"></i> Request Appraiser For Panel Discussion</h5>
          </center>
          <br/>
          {searchContent}
          <br/>
          <center>
            <Button color="info" onClick={toggleModal}>Close</Button>
          </center>
        </ModalBody>
      </Modal>

      <Modal className="my-modal-wide" isOpen={state.modal2} toggle={toggleModal2} >
        <ModalHeader hidden={true} toggle={toggleModal2}></ModalHeader>
        <ModalBody>
          <center>
            <h5><i className="fa fa-cogs"></i> Request approval</h5>
          </center>
          <br/>
          <Row>
            <Col sm="4">
              <label>Name</label>
            </Col>
            <Col sm="8">
              {state.selectedRequest.first_name+' '+state.selectedRequest.last_name}
            </Col>
          </Row>
          <Row>
            <Col sm="4">
              <label>Email</label>
            </Col>
            <Col sm="8">
              {state.selectedRequest.email}
            </Col>
          </Row>
          <Row>
            <Col sm="4">
              <label>Address</label>
            </Col>
            <Col sm="8">
              {state.selectedRequest.street+' '+state.selectedRequest.city+', '+state.selectedRequest.state+' '+state.selectedRequest.zip}
            </Col>
          </Row>
          <br/>
          <Row>
            <Col sm="4">
              <label>Approval Status</label>
            </Col>
            <Col sm="8">
              <MySelect
                type="select"
                modal={true}
                selectIsClearable={true}
                value={state.selectedRequest.panel_status}
                onChange={(v)=>approval(v)}
                options={[{label:"Pending",value:"pending"},{label:"Rejected",value:"rejected"},{label:"Approved",value:"approved"}]}
              />
            </Col>
          </Row>
          <br/>
          <center>
            <Button color="info" onClick={toggleModal2}>Close</Button>
          </center>
        </ModalBody>
      </Modal>

      <div className="page-title">
        <i className="fa fa-reorder"></i>&nbsp;Appraiser - Panel Discussion
      </div>

      <Row>
        <Col sm="3">
          <label className="no-margin-bottom">Status</label>
          <MySelect
            type="select"
            selectIsClearable={true}
            value={state.status}
            onChange={(v)=>setState({status:v})}
            options={[{label:"Pending",value:"pending"},{label:"Rejected",value:"rejected"},{label:"Approved",value:"approved"}]}
          />
        </Col>
        <Col sm="3">
          <label className="no-margin-bottom">Name</label>
          <Input type="text" value={state.name} onChange={(e)=>setState({name:e.target.value})}/>
        </Col>
        <Col sm="3">
          <label className="no-margin-bottom">Company Name</label>
          <Input type="text" value={state.company_name} onChange={(e)=>setState({company_name:e.target.value})}/>
        </Col>
        <Col sm="3">
          <label className="no-margin-bottom">Email</label>
          <Input type="text" value={state.email} onChange={(e)=>setState({email:e.target.value})}/>
        </Col>
      </Row>
      <Row>
        <Col sm="3">
          <label className="no-margin-bottom">State</label>
          <MySelect
            type="select"
            selectIsClearable={true}
            value={state.state}
            onChange={(v)=>setState({state:v})}
            options={state.states.map((state)=>{
              return {label:state.value, value:state.value};
            })}
          />
        </Col>
        <Col sm="3">
          <label className="no-margin-bottom">County</label>
          <Input type="text" value={state.county} onChange={(e)=>setState({county:e.target.value})}/>
        </Col>
        <Col sm="3">
          <label className="no-margin-bottom">City</label>
          <Input type="text" value={state.city} onChange={(e)=>setState({city:e.target.value})}/>
        </Col>
        <Col sm="3" className="align-right valign-bottom">
          <br/>
          <Button color="warning" onClick={()=>refreshList()} >Submit</Button>
        </Col>
      </Row>
      <br/>

      <div className="my-divider"></div>
      <Row>
        <Col sm="6">
            <div>{formatNumber(state.totalCount)} appraisers in queue<br/><br/></div>
        </Col>
        <Col sm="6" className="align-right">
          <NavLink to="#" onClick={toggleModal}><i className="fa fa-plus green-color"></i> Submit new request</NavLink>
        </Col>
      </Row>
      <Nav tabs>
        <NavItem>
          <NavLinkL
            className={"cursor-pointer nav-link "+(state.activeTab === '1'?"active":"inactive" )}
            onClick={() => { activeTabToggle('1'); }}
          >
            Pending Approval <span className="my-badge">{state.pendingRequests.length}</span>
          </NavLinkL>
        </NavItem>
        <NavItem>
          <NavLinkL
            className={"cursor-pointer nav-link "+(state.activeTab === '2'?"active":"inactive" )}
            onClick={() => { activeTabToggle('2'); }}
          >
            History <span className="my-badge">{state.totalCount}</span>
          </NavLinkL>
        </NavItem>
      </Nav>
      <TabContent activeTab={state.activeTab}>
        <TabPane tabId="1">
          <div className="align-right" style={{marginTop:'5px'}}>
            <ExcelFile element={<Button to="#" disabled={state.pendingRequests.length<=0} color="warning">Download Excel For Panel Discussion</Button>}>
              <ExcelSheet data={state.pendingRequests} name="XLSX">
                <ExcelColumn label="Name" value={(col)=>{return col.first_name+' '+col.last_name}}/>
                <ExcelColumn label="County" value="county"/>
                <ExcelColumn label="State" value="state"/>
                <ExcelColumn label="Active Appraiser In This County" value="active_appraisers_string"/>
                <ExcelColumn label="Assignment History" value="order_history"/>
                <ExcelColumn label="Service Rating" value={(col)=>col.avg_service_rating!=='-1'?col.avg_service_rating+'/5.00':'-'}/>
                <ExcelColumn label="Quality Rating" value={(col)=>col.avg_quality_rating!=='-1'?col.avg_quality_rating+'/3.00':'-'}/>
                <ExcelColumn label="Approval Status" value="panel_status"/>
                <ExcelColumn label="Requester" value="requester"/>
                <ExcelColumn label="Related Appraisal Order" value="reference_num"/>
                <ExcelColumn label="Comment" value="comment"/>
                <ExcelColumn label="Date Submitted" value={(col)=>formatDateTime(col.datetime_submitted)}/>
              </ExcelSheet>
            </ExcelFile>
          </div>
          <br/>
          <div className="large-scroll-container">
            <Table className="table table-striped">
              <thead style={{fontSize:'11px'}}>
                <tr>
                  <th width="5%">Action</th>
                  <th width="12%">Appraiser</th>
                  <th width="8%">County</th>
                  <th width="8%">State</th>
                  <th width="8%">Active Appraiser</th>
                  <th width="8%">Assignment History</th>
                  <th width="8%">Service Rating</th>
                  <th width="8%">Quality Rating</th>
                  <th width="10%">Requester</th>
                  <th width="15%">Comment</th>
                      <th width="10%">Date submitted</th>
                    </tr>
                  </thead>
                  <tbody style={{fontSize:'11px'}}>
                    {
                      state.pendingRequests.map(
                        (request,index)=>{
                          return(
                            <tr key={index}>
                              <td><i className="fa fa-question-circle link-color cursor-pointer" onClick={(e)=>selectedRequest(request)}></i></td>
                              <td><NavLink target="_blank" to={"/appraiser/"+request.ID}>{request.first_name+' '+request.last_name}</NavLink></td>
                              <td>{request.county}</td>
                              <td>{request.state}</td>
                              <td>{
                                request.active_appraisers.map(
                                  (row, index)=>{
                                    return <div>
                                      {row.type} - {row.count}
                                    </div>
                                  }
                                )
                              }</td>
                              <td>{request.order_history}</td>
                              <td>{request.avg_service_rating!=='-1'?request.avg_service_rating+'/5.00':'-'}</td>
                              <td>{request.avg_quality_rating!=='-1'?request.avg_quality_rating+'/3.00':'-'}</td>
                              <td>{request.requester}</td>
                              <td><NavLink to={"/appraisal/"+request.appraisal_fk} target="_blank">{request.reference_num}</NavLink><br/>{request.comment}</td>
                              <td>{formatDateTime(request.datetime_submitted)}</td>
                            </tr>
                          );
                        }
                      )
                    }
                  </tbody>
                </Table>
              </div>
            </TabPane>
            <TabPane tabId="2">
              {renderAppraisers()}
            </TabPane>
          </TabContent>
        </InfiniteScroll>
  </div>;
}

export default AppraiserPanelDiscussion;
