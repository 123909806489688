//Author Sooyoung Kim
//Date July 21, 2023
import React, {useEffect} from 'react';
import PlacesAutocomplete from 'react-places-autocomplete'

const MyPlacesAutocomplete  = (props)=>{
  const controller = new AbortController();

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  //render
  return <div>
    <PlacesAutocomplete
      value={props.value}
      onChange={props.onChange}
      onSelect={props.onSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <input {...getInputProps({ placeholder: "Enter address", className: 'form-control'})} />
          <div>
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion) => {
              const style = {
                backgroundColor: suggestion.active ? '#fafafa' : '#fff',
                padding: '10px',
                color: '#555555',
                cursor: 'pointer',
              };
              return (
                <div {...getSuggestionItemProps(suggestion, { style })} key={suggestion.placeId}>
                  {suggestion.description}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  </div>;
}


export default MyPlacesAutocomplete;
