//Author Sooyoung Kim
//Date June 28, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, callBackGenerator, formatDateTime} from '../../../util/util';
import {Card, CardHeader, CardBody, Table} from 'reactstrap';
import {NavLink} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';

//initialize the state
const initialState = {
  reportDetails:[],
};

//reducer function that perform state update
const reducer = getReducer();


const MissingSSR  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    getReportDetails();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //API call
  const approveMissingSSR = (id)=>{
    let parameters = [{field:'appraisalFk',value:id}];
    httpPost('report/ssr/approval', parameters, 'Added order to exclusion of this report successfully.', 'Oops, something went wrong and could not exclude this order from this report. Please try again later.');
  }

  const getReportDetails = ()=>{
    let callBack = apiCallBack([{state:'reportDetails', key:'data'}]);
    httpGet('report/ssr', '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  //render
  return <div>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;SSR missing report
      </CardHeader>
      <CardBody>

        <Table className="table table-striped">
          <thead>
            <tr>
              <th width="5%"></th>
              <th width="15%">Reference #</th>
              <th width="20%">Address</th>
              <th width="10%">Status</th>
              <th width="10%">Loan type</th>
              <th width="15%">Client</th>
              <th width="15%">Missing</th>
              <th width="10%">Ship Date</th>
            </tr>
          </thead>
          <tbody>
            {
              state.reportDetails.map(
                (row,index)=>{
                  return(
                    <tr key={index}>
                      <td><i className="fa fa-check green-color cursor-pointer" onClick={(e)=>approveMissingSSR(row.ID)}></i></td>
                      <td><NavLink target="_blank" to={"/appraisal/"+row.appraisal_fk}>{row.reference_num}</NavLink></td>
                      <td>{row.property_street+' '+row.property_city+', '+row.property_state+' '+row.property_zip}</td>
                      <td>{row.status}</td>
                      <td>{row.loan_type}</td>
                      <td>{row.company}</td>
                      <td>{row.missing}</td>
                      <td>
                        {formatDateTime(row.datetime_ship)}
                      </td>
                    </tr>
                  );
                }
              )
            }
          </tbody>
        </Table>
      </CardBody>
    </Card>
  </div>;
}


export default MissingSSR;
