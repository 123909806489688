//Author June Leow
//Date Oct 20th, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, putAPICallGenerator, callBackGenerator, generateSID,formatDate,formatDateTime, showMessage} from '../../util/util';
import {Alert, Button, Input, Card, CardHeader, CardBody,NavLink as NavLinkL, Nav, NavItem, Row, Col, TabContent, TabPane, Table} from 'reactstrap';
import {NavLink} from 'react-router-dom';
import MySelect from '../util/my-select';
import React, {useReducer, useEffect, useRef} from 'react';
import '../comment/comment.css';
//initialize the state
const initialState = {
  quoteVettingTab:'1',
  extraRecipients:'',
  extraOpsRecipients:[],
  commentForSelectedQuote:'',
  allQuotes:[],
  vettingQuotes:[],
  quoteID:[],
  templateName:'',
  commentTemplates:[],
  targetRecipients:[],
};

//reducer function that perform state update
const reducer = getReducer();


const QuoteVetting  = (props)=>{
  const controller = new AbortController();
  const newsid = useRef(generateSID());
  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});
  const httpPut = putAPICallGenerator(props, {signal:controller.signal});
  //run only once when component is loaded
  useEffect(()=>{
    getBids(props.appraisalFk);
    getVettingQuotes(props.appraisalFk);
    getCommentTemplates(props.appraisalFk);

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    let targetLabel = '';
    for(let i=0;i<props.appraisal.entities.length;i++){
      if(props.appraisal.entities[i].ID===props.appraisalFk){
        targetLabel = props.appraisal.entities[i].entity_label;
        break;
      }
    }
    let apiIntegrationID = -1;
    let extraID = [];
    for(let i=0;i<props.appraisal.entities.length;i++){
      if(props.appraisal.entities[i].entity_label==='API Integration'){
        apiIntegrationID = props.appraisal.entities[i].ID;
      }

      if(props.appraisal.entities[i].entity_label==='AE' || props.appraisal.entities[i].entity_label==='CSR'){
        if(state.targetRecipients.filter(e => e.ID === props.appraisal.entities[i].ID).length <= 0){
          extraID.push(props.appraisal.entities[i].ID);
        }
      }
    }
    
    if(targetLabel==='Loan Officer'||targetLabel==='Loan Processor'){
      if(apiIntegrationID!==-1){
        addNewCommentRecipients(apiIntegrationID, extraID);
      }
      if(state.extraRecipientsID.length > 0){
        addMultipleRecipients(state.extraRecipientsID);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[state.targetRecipients]);

  //non API call but simpyl manage state
  const quoteVettingTabToggle = (tab)=>{
    setState({quoteVettingTab:tab});
  }

  const addNewCommentRecipientsBorrower = ()=>{
    setState({extraRecipients:props.appraisal.borrower_email});
  }

  const addNewCommentRecipients = (ID, extraRecipientsID)=>{
    setState({ID:ID, extraRecipientsID:extraRecipientsID});
    if(ID==='All')
      addAllToTargetRecipients();
    else{
      for(let i =0;i<state.targetRecipients.length;i++){
        if(state.targetRecipients[i].ID===ID)
          return;
      }
      let existingNewTargetRecipients = state.targetRecipients.slice();

      let targetEntity = null;
      for(let i=0;i<props.appraisal.entities.length;i++){
        if(props.appraisal.entities[i].ID===ID){
          targetEntity = props.appraisal.entities[i];
          break;
        }
      }
    
      if(targetEntity){
        //check if entity email is empty
        if(targetEntity.entity_email==='' && targetEntity.entity_label!=='API Integration'){
          showMessage('error','Contact '+targetEntity.entity_name+' does not has email, notification wont be able to reach.');
        }
        else{
          existingNewTargetRecipients.push(targetEntity);
          setState({targetRecipients:existingNewTargetRecipients});
        }
      }
    }
  }

  const addMultipleRecipients = (extraID)=>{
    let existingNewTargetRecipients = state.targetRecipients.slice();

    for(let i=0;i<extraID.length;i++){
      for(let j=0;j<props.appraisal.entities.length;j++){
        if(props.appraisal.entities[j].ID===extraID[i]){
          existingNewTargetRecipients.push(props.appraisal.entities[j]);
        }
      }
    }
    setState({targetRecipients:existingNewTargetRecipients});
  }

  //add all associated entity in this appraisal order to the target recipients
  const addAllToTargetRecipients = ()=>{
    let allRecipients = props.appraisal.entities.slice();
    let tmp = [];
    for(let i=0;i<allRecipients.length;i++){
      if(allRecipients[i].entity_email==='' && allRecipients[i].entity_label!=='API Integration'){
        showMessage('error','Contact '+allRecipients[i].entity_name+' does not has email, notification wont be able to reach.');
      }
      else{
        tmp.push(allRecipients[i]);
      }
    }
    setState({targetRecipients:tmp});
  }

  const handleChange = (selectedOption) => {
    setState({extraOpsRecipients:selectedOption});
  }

  const commentTemplatesOnChange = (id)=>{
    let template;
    for(let i=0;i<state.commentTemplates.length;i++){
      if(state.commentTemplates[i].ID===id){
        template = state.commentTemplates[i];
        break;
      }
    }
    if(template){
      setState({commentForSelectedQuote:template.template, templateName:id});
    }
  }

  const createAllQuotes = (quotes)=>{
    let newQuotes = [];
    if(quotes){
      quotes.map(
        (quote, index)=>{
          let i = quote.email_list.length - 1;
          let tmp = {ID: quote.email_list[i].ID,
            appraiser: quote.appraiser,
            recipient_email: quote.recipient_email,
            appraiser_phone: quote.appraiser_phone,
            status:quote.status_list[i].status,
            fee:quote.fee_list[i].fee,
            turnaround:quote.turnaround_list[i].turnaround,
            datetime_respond:quote.datetime_respond_list[i].datetime_respond,
            email:quote.email_list[i].email,
            note:quote.note_list[i].note,
            datetime_created:quote.datetime_created_list[i].datetime_created,
            method:quote.method,
          }
          newQuotes.push(tmp);
          return null;
        }
      );
    }

    setState({allQuotes:newQuotes});
  }

  const bidQuoted = (ID)=>{
    let vettingQuotes = state.vettingQuotes.slice();

    for(let i=0;i<vettingQuotes.length;i++){
      for(let j=0;j<vettingQuotes[i].recipients.length;j++){
        if(vettingQuotes[i].recipients[j].ID===ID && vettingQuotes[i].recipients[j].status==='active')
          return true;
      }
    }
    return false;
  }

  const selectThisQuote = (quote)=>{
    let newArrayID = state.quoteID;
    let index = newArrayID.indexOf(quote.ID);
    if (index > -1) 
      newArrayID.splice(index, 1);
    else
      newArrayID.push(quote.ID)
    setState({quoteID:newArrayID});
  }

  //remove a specific target recipient from the list of recipients that will receive the comment
  const removeFromTargetRecipients = (id)=>{
    for(let i =0;i<state.targetRecipients.length;i++){
      if(state.targetRecipients[i].ID===id){
        let newTargetRecipients = state.targetRecipients.slice();
        newTargetRecipients.splice(i,1);
        setState({targetRecipients:newTargetRecipients});
      }
    }
  }

  //API call
  const startVetting = ()=>{
    if(state.quoteID.length>0){
      let targetRecipients =[];
      for(let i=0;i<state.targetRecipients.length;i++)
        targetRecipients.push(state.targetRecipients[i].ID);
  
      let extraRecipients = state.extraRecipients.split(',');
      for(let i=0;i<state.extraOpsRecipients.length;i++){
        extraRecipients.push(state.extraOpsRecipients[i].value);
      }
      extraRecipients = extraRecipients.join(', ');
  
      let parameters = [
        {
          field:'comment',
          value:state.commentForSelectedQuote
        },
        {
          field:'ID',
          value:props.appraisalFk
        },
        {
          field:'entities',
          value:targetRecipients
        },
        {
          field:'quotes',
          value:state.quoteID
        },
        {
          field:'extra_recipients',
          value:extraRecipients
        }
      ];
  
      let callBack = (response)=>{
        let code = response.data?response.data.code:undefined;
  
        if(code==='00'){
          //clear the text area
          let appraisal = Object.assign({}, props.appraisal);
          appraisal.status='Quote';
          let vettingQuotes = state.vettingQuotes.slice();
          vettingQuotes.push(response.data.data);
  
          let overallStatus = Object.assign({},props.overallStatus);
          overallStatus.quote_vetting_count+=1;
  
          props.updateOverallStatus(overallStatus);
          props.updateAppraisal(appraisal);
  
          setState({vettingQuotes:vettingQuotes, quoteID:[], extraRecipients:'', targetRecipients:[], commentForSelectedQuote:''});
        }
      };

      httpPost('appraisal/quote/vet', parameters,'Selected Quotes sent out successfully.','Oops, something went wrong and could not send selected quotes. Please try again later.', callBack);
    }
    else{
      showMessage('warning','Please select at least one quote.');
    }
  }

  const getVettingQuotes = (ID)=>{
    let callBack = apiCallBack([{state:'vettingQuotes', key:'data'}]);
    httpGet('appraisal/quote/'+ID,'','Oops, something went wrong and could not load vetting quotes.', callBack);
  }

  const cancelQuoteVetting = (e, ID)=>{
    e.stopPropagation();

    let parameters = [
      {
        field:'recordFk',
        value:ID
      },
      {
        field:'appraisalFk',
        value:props.appraisalFk
      }
    ];

    let callBack = (response)=>{
      let vettingQuotes = state.vettingQuotes.slice();

      for(let i=0;i<vettingQuotes.length;i++){
        for(let j=0;j<vettingQuotes[i].recipients.length;j++){
          if(vettingQuotes[i].recipients[j].ID===ID){
              vettingQuotes[i].recipients[j].status = 'inactive';
              break;
          }
        }
      }

      let overallStatus = Object.assign({},props.overallStatus);
      overallStatus.quote_vetting_count-=1;
      props.updateOverallStatus(overallStatus);

      setState({vettingQuotes:vettingQuotes});
    }

    httpPut('appraisal/cancel/quote/update', parameters, '', '', callBack);
  }

  const getAdmin = (keyword) => {
    if(keyword&&keyword!==''){
      let SID = generateSID();
      newsid.current = SID;

      keyword = encodeURIComponent(keyword.replace(/\//g, '%ForwardSlash'));

      let callBack = (response)=>{
        if(SID===newsid.current){
          let code = response.data?response.data.code:undefined;

          if(code!=='00'){
            return [{}];
          }
          else{
            let options = [];

            for(let i=0;i<response.data.data.length;i++){
              let tmp = {};
              tmp.label = response.data.data[i].email;
              tmp.value = response.data.data[i].email;

              options.push(tmp);
            }

            return options;
          }
        }
      };
      callBack = callBack.bind(this);

      let promise = httpGet('admin/search/'+keyword, '', 'Oops, something went wrong and could not search for admin. Please try again later.', callBack);

      return promise;
    }
  }

  const getCommentTemplates = (ID)=>{
    let callBack = apiCallBack([{state:'commentTemplates', key:'data'}]);
    httpGet('template/get/'+ID,'', 'Oops, something went wrong and could not load your comment template.', callBack);
  }

  const getBids = (ID)=>{
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;

      if(code==='00'){
        createAllQuotes(response.data.data);
      }
    }

    httpGet('appraisal/bid/'+ID, '', 'Oops, something went wrong and could not load list of bids sent out. Please try again later.', callBack);
  }

  let entities;
    if(props.appraisal.entities){
      entities = props.appraisal.entities.map(
        (entity, index)=>{
          if(entity.entity_label==='Broker'||entity.entity_label==='Broker Company')
            return null;
          return (
            <tr key={index} className="cursor-pointer align-left" onClick={(e)=>addNewCommentRecipients(entity.ID, [])}><td><b>{entity.entity_label}</b></td><td>{entity.entity_name} - {entity.entity_email}</td></tr>
          );
        }
      );
    }

    let targetRecipients;
    if(state.targetRecipients){
      targetRecipients = state.targetRecipients.map(
        (recipient)=>{
          return(
            <div style={{display:'inline-block'}} key={recipient.ID}>
              <div className="entity-container cursor-pointer" onClick={()=>{removeFromTargetRecipients(recipient.ID)}}>
                <i className="fa fa-minus link-color"></i>&nbsp;{recipient.entity_name}
              </div>&nbsp;
            </div>
          );
        }
      );
    }

    let targetClient
    if(state.clientClicked){
      targetClient = <div style={{display:'inline-block'}}>
        <div className="entity-container cursor-pointer" onClick={()=>setState({clientClicked:false})}>
          <i className="fa fa-minus link-color"></i>&nbsp;{state.clientName}
        </div>&nbsp;
      </div>;
    }

    let allQuotes = <div>No bid found.</div>;


    if(state.allQuotes.length > 0){
      allQuotes = <Row>
        {
          state.allQuotes.map(
            (quote, index)=>{
              let style = {height:'360px'};

              if(state.quoteID.includes(quote.ID)){
                style = {height:'360px', borderStyle: 'solid', borderWidth: 'thick', borderColor: '#2E8BC0'}
              }

              let icon;
              let iconMethod;

              if(quote.method==='phone')
                iconMethod = <i className="fa fa-phone"/>;
              else if(quote.method==='email')
                iconMethod = <i className="fa fa-address-book"/>;
              else
                iconMethod = <i className="fa fa-envelope-open"/>;

              if(quote.status==='pending'){
                icon = <i className="fa fa-circle gray-color"></i>
              }
              else if(quote.status==='completed'){
                icon = <i className="fa fa-circle green-color"></i>
              }
              else if(quote.status==='scan-failed'){
                icon = <i className="fa fa-circle orange-color"></i>
              }
              else if(quote.status==='reject'||quote.status==='no-reply'){
                icon = <i className="fa fa-circle red-color"></i>
              }

              let turnaround = formatDate(quote.turnaround);
              let created = formatDateTime(quote.datetime_created);
              let responded = formatDateTime(quote.datetime_respond);

              let flash = bidQuoted(quote.ID);

              let sentQuote = <b style={{fontSize:'27px', color:'black'}}>${quote.fee}</b>;
              if(flash){
                sentQuote = <Row>
                  <Col sm="9">
                    <b className={flash?'red-color':''} key={index} style={{fontSize:'27px'}}>${quote.fee}</b>
                  </Col>
                  <Col sm="3" className="align-right">
                    <i className="fa fa-times" onClick={(e)=>cancelQuoteVetting(e, quote.ID)}></i>
                  </Col>
                </Row>
              }

              return <Col key={index} sm="4">
                <div className={flash?'flashit':''}>
                  <div className="my-well padding" onClick={(e)=>selectThisQuote(quote)} style={style}>
                    <div className="scroll-hide">
                      <div className="large-medium-scroll-container">
                        <Row>
                          <Col sm="2" className="align-left">{icon}</Col>
                          <Col sm="8">
                            <div style={{fontSize:'15px'}}>
                              <center>
                                {quote.appraiser}
                              </center>
                            </div>
                          </Col>
                          <Col sm="2" className="align-right">{iconMethod}</Col>
                        </Row>
                        <div className="my-divider" style={{height:'15px',marginTop:'0px'}}>&nbsp;</div>
                        <div>
                          {sentQuote}
                        </div>
                        <div><i className="fa fa-envelope-square">:&nbsp;</i>{quote.recipient_email}</div>
                        <div><b><i className="fa fa-phone-square">:&nbsp;</i></b>{quote.appraiser_phone}</div>
                        <Row>
                          <Col sm="4">
                            <div style={{fontSize:'13px'}}>Turnaround:</div>
                          </Col>
                          <Col sm="8" className="align-left">
                            {turnaround}
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="4">
                            <div style={{fontSize:'13px'}}>Respond:</div>
                          </Col>
                          <Col sm="8" className="align-left">
                            {responded}
                          </Col>
                        </Row>
                        <Row>
                          <Col sm="4">
                            <div style={{fontSize:'13px'}}>Entered:</div>
                          </Col>
                          <Col sm="8" className="align-left">
                            {created}
                          </Col>
                        </Row>
                        <br/>
                        <Row>
                          <Col sm='12'>
                            <div style={{fontSize:'15px'}}><b>Email/Note:</b></div>
                            {quote.email}
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                  <br/>
                </div>
              </Col>
            }
          )
        }
      </Row>;
    }

  //render
  return <div>
    <Card  style={{borderTop:'0px'}}>
      <CardHeader className="header-color">
        <i className="fa fa-home"></i>&nbsp;{props.appraisal.reference_num} - {props.appraisal.property_street} {props.appraisal.property_city}, {props.appraisal.property_state} {props.appraisal.property_zip} - {props.appraisal.borrower_f_name} {props.appraisal.borrower_l_name}
      </CardHeader>
      <CardBody>
        {
          props.messages.map(
            (message, index)=>{
              if(message.link){
                return(<a key={index} href={'/'+message.link+'/'+message.ID} target="_blank" rel="noreferrer"><Alert key={index} color="warning" className="my-alert">
                    <i className="fa fa-information"></i> {message.msg}
                  </Alert></a>);
              }

              return(<Alert key={index} color="warning" className="my-alert">
                  <i className="fa fa-information"></i> {message.msg}
                </Alert>);
            }
          )
        }
        <Nav tabs>
          <NavItem>
            <NavLinkL className="cursor-pointer" active={state.quoteVettingTab==='1'} onClick={() => { quoteVettingTabToggle('1'); }}to="#">
              Choose/Send Bid(s)
            </NavLinkL>
          </NavItem>
          <NavItem>
            <NavLinkL className="cursor-pointer" active={state.quoteVettingTab==='2'} onClick={() => { quoteVettingTabToggle('2'); }}to="#">
              History <span className="my-badge">{state.vettingQuotes.length}</span>
            </NavLinkL>
          </NavItem>
        </Nav>

        <TabContent activeTab={state.quoteVettingTab}>
          <TabPane tabId="1">
            <i className="red-color">*flashing*</i> Bid vetted to client
            <Row>
              <Col sm="7">
                <div className="ultra-large-scroll-container">
                  {allQuotes}
                </div>
              </Col>
              <Col sm="5">
                <div className="align-right">
                  <center>
                    <h5><i className="fa fa-comments"></i> Send Selected Quote</h5>
                  </center>
                  <form onSubmit={(e)=>{e.preventDefault();startVetting();}}>
                    <div className="align-left">
                      <label>Tag Someone</label>
                    </div>
                    <div className="small-scroll-container-ex-large">
                      <Table hover className="table">
                        <tbody>
                          <tr className="cursor-pointer align-left" onClick={(e)=>addNewCommentRecipientsBorrower()}><td><b>Borrower</b></td><td>{props.appraisal.borrower_f_name+' '+props.appraisal.borrower_l_name+' - '+props.appraisal.borrower_email}</td></tr>
                          {entities}
                        </tbody>
                      </Table>
                    </div>

                    <Row>
                      <Col sm="6" className="align-left">
                        <label>Extra recipients</label><br/>
                        <Input type="text" value={state.extraRecipients} onChange={(e)=>setState({extraRecipients:e.target.value})}/>
                      </Col>
                      <Col sm="6" className="align-left">
                        <label>Tag admin</label>
                        <MySelect
                          isMulti
                          type="async-select"
                          onChange={(v)=>handleChange(v)}
                          loadOptions={getAdmin}
                        />
                      </Col>
                    </Row>
                    <div className="small-scroll-container">
                      {targetClient}
                      {targetRecipients}
                    </div>
                    <br/>
                    <div className="form-group">
                      <Row>
                        <Col sm="12" className="align-left">
                          <span><label>Comment:</label></span>
                        </Col>
                      </Row>
                      <Input type="textarea" required={true} className="form-control comment-textarea" value={state.commentForSelectedQuote} id="comment" placeholder="Say something..." style={{resize:'none'}} rows="2" onChange={(e)=>{setState({commentForSelectedQuote:e.target.value})}}></Input>
                    </div>

                    <Row>
                      <Col sm="6" className="align-left">
                        <label>Template:</label>
                      </Col>
                      <Col sm="6" className="align-right">
                        <NavLink to="/setting">Manage my template</NavLink>
                      </Col>
                    </Row>

                    <MySelect
                      type="select"
                      selectIsClearable={true}
                      value={state.templateName}
                      onChange={(v)=>{commentTemplatesOnChange(v)}}
                      options={state.commentTemplates.map((template)=>{
                        return {label:template.name, value:template.ID};
                      })}
                    />

                    <hr className="margin-bottom"/>
                    <Row>
                      <Col sm="12" className="align-right">
                        <Button color="warning"><i className="fa fa-check"></i>&nbsp;Send to Client</Button>{' '}
                      </Col>
                    </Row>
                  </form>
                </div>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <div className="ultra-large-scroll-container">
              <Table className="table table-striped">
                <thead>
                  <tr>
                    <th width="12%">Author</th>
                    <th width="25%">Comment</th>
                    <th width="30%">Bid Selected</th>
                    <th width="18%">Recipients</th>
                    <th width="15%">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    state.vettingQuotes.map(
                      (quote, index)=>{
                        return <tr key={index}>
                          <td>{quote.author}</td>
                          <td><i>{quote.comment}</i></td>
                          <td>
                            {
                              quote.recipients.map(
                                (recipient, index2)=>{
                                  return <Row key={index2}>
                                    <Col sm="5">{recipient.name}</Col>
                                    <Col sm="3">{recipient.fee!==''?recipient.fee:'-'}</Col>
                                    <Col sm="4">{recipient.turnaround}</Col>
                                  </Row>
                                }
                              )
                            }
                          </td>
                          <td>{quote.to &&
                            quote.to.split(", ").map(
                              (recipient, index3)=>{
                                return <Row key={index3}>
                                  <Col sm="12">{recipient}</Col>
                                </Row>
                              }
                            )
                          }</td>
                          <td>{formatDateTime(quote.datetime_created)}</td>
                        </tr>;
                      }
                    )
                  }
                </tbody>
              </Table>
            </div>
          </TabPane>
        </TabContent>
      </CardBody>
    </Card>
  </div>;
}


export default QuoteVetting;
