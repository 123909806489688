//Author Sooyoung Kim
//Date Aug 14, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, postAPICallGenerator, callBackGenerator, formatNumber, generateTooltip, generateSID} from '../../util/util';
import {Card, CardHeader, CardBody, Col, Row, Input, Button, Modal, ModalHeader, ModalBody} from 'reactstrap';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import MyPlacesAutocomplete from '../util/my-places-autocomplete';
import React, {useReducer, useEffect, useRef, forwardRef } from 'react';
import MyDropzone from '../util/my-dropzone';
import MyGoogleMap from '../util/my-google-map';
import MyFormStep from '../util/my-form-step';
import DatePicker from 'react-datepicker';
import {NavLink, useNavigate} from 'react-router-dom';
import MySelect from '../util/my-select';
import ReactDOM from 'react-dom';
import moment from 'moment';
import valid from "card-validator";


//initialize the state
const initialState = {
  openBy:0,
  loanTypes:[{key:'Conventional',value:'Conventional'},{key:'HELOC/2nd Mortgage',value:'HELOC/2nd Mortgage'},{key:'High Balanced',value:'High Balanced'},{key:'Jumbo <$1m',value:'Jumbo <$1m'},{key:'Jumbo $1m - $2m',value:'Jumbo $1m - $2m'},{key:'Jumbo >$2m',value:'Jumbo >$2m'},{key:'FHA',value:'FHA'},{key:'USDA',value:'USDA'},{key:'Private',value:'Private'},{key:'Other',value:'Other'}],
  loanPurposes:[{key:'Purchase',value:'Purchase'},{key:'Refinance',value:'Refinance'},{key:'R/T Refinance',value:'R/T Refinance'},{key:'C/O Refinance',value:'C/O Refinance'},{key:'Other',value:'Other'}],
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
  standAlone:[
    'Rent Survey (1007)',
    'Operating Income Statement (216)',
    'Appraisal Update / Re-Cert (1004D)',
    'Appraisal Final Inspection (1004D / HUD 92051)',
    'Appraisal Disaster Inspection In & Out (1004D)',
    'Exterior (2075)',
    'Enhanced Desk Review',
    'Field Review'
  ],
  appraisalTypeFilters:{
    '2 Units':['1004','1073','1075','2055'],
    '3 Units':['1004','1073','1075','2055'],
    '4 Units':['1004','1073','1075','2055'],
    'Low Rise Condo':['1004','1025','2055'],
    'Mid-Rise Condo':['1004','1025','2055'],
    'High Rise Condo':['1004','1025','2055'],
    'Detached Condo':['1004','1025','2055'],
    'Manufactured Housing':['1073','1075'],
    'SFR - Detached':['1073','1025','1075'],
    'SFR - Attached':['1073','1025','1075'],
    'PUD - Attached':['1073','1075'],
    'PUD - Detached':['1073','1075'],
  },
  propertyTypes:[{key:'2 Units',value:'2 Units'},{key:'3 Units',value:'3 Units'},{key:'4 Units',value:'4 Units'},{key:'Co-Operative (Co-Op)',value:'Co-Operative (Co-Op)'},{key:'Low Rise Condo',value:'Low Rise Condo'},{key:'Mid-Rise Condo',value:'Mid-Rise Condo'},{key:'High Rise Condo',value:'High Rise Condo'},{key:'Detached Condo',value:'Detached Condo'},{key:'Manufactured Housing',value:'Manufactured Housing'},{key:'SFR - Detached',value:'SFR - Detached'},{key:'SFR - Attached',value:'SFR - Attached'},{key:'PUD - Detached',value:'PUD - Detached'},{key:'PUD - Attached',value:'PUD - Attached'},{key:'Mixed Use',value:'Mixed Use'},{key:'AVM',value:'AVM'},{key:'Commercial',value:'Commercial'},{key:'Other',value:'Other'}],
  occupants:[{key:'Owner-Occupied', value:'Owner-Occupied'},{key:'Tenant-Occupied',value:'Tenant-Occupied'},{key:'Vacant',value:'Vacant'}],
  apn:'',
  availableAppraisalTypes:[],
  availableAppraisalTypesFiltered:[],
  clients:[],
  clientEntities:[{label:'Co-Borrower',name:'',email:'',phone:''},{label:'Loan Officer',name:'',email:'',phone:''},{label:'Loan Processor',name:'',email:'',phone:''}],
  appraisalType:'',
  subForms:[],
  subForm: false,
  expectedDate:'',
  borrowerFirstName:'',
  borrowerLastName:'',
  borrowerEmail:'',
  borrowerPhone:'',
  borrowerWorkPhone:'',
  borrowerMobilePhone:'',
  orderType:'Retail',

  propertyStreet:'',
  propertyCity:'',
  propertyState:'',
  propertyCounty:'',
  propertyZip:'',
  address:'',
  clientBranches:[],
  openByClientBranches:[],
  clientBranch:0,
  openByClientBranch:0,
  clientBranchLabel:'',
  openByClientBranchLabel:'',

  propertyAccessTitle:'',
  propertyAccessName:'',
  propertyAccessEmail:'',
  propertyAccessHomePhone:'',
  propertyAccessWorkPhone:'',

  client:0,
  loanNumber:'',
  loanAmount:'',
  estimatedValue:'',
  loanType:'',
  loanPurpose:'',
  salePrice:'',
  fhaNumber:'',
  specialInstructions:'',

  entities:[],
  selectedNewEntity:'',
  newEntityDropDownOpen:false,
  searchAccosiatesPopUp:false,
  toUploadFiles:[],
  isRush:'Non-rush',
  selectedFileTypes:[],

  wholesale:[],
  retail:[],
  keyword:'',
  contactID:-1,

  steps:['Property Information','Appraisal & Loan Information','Contact Information','Additional Information','Payment','Review'],
  maxStep:0,
  currentStep:0,

  ccNumberError:'',
  ccNumberValid:true,
  ccTypeError:'',
  ccTypeValid:true,

  newcc_first_name:'',
  newcc_last_name:'',
  newcc_email:'',
  newcc_card_number:'',
  newcc_cvc:'',
  newcc_expiration_year:'',
  newcc_expiration_month:'',
  newcc_street:'',
  newcc_city:'',
  newcc_state:'',
  newcc_zip:'',
  newcc_card_type:'',
  coveredBy:'',

  errorMessage:'',
  submitDisabled:false,
  fileTypesToUpload:[],
  descriptionsToUpload:[],
  fileTypes:[],
  clientContactSearchResult:[],

  searchOrder:'',
  searchOrders:[],

  resizeFilePopUp:false,
  bigFiles:[],

  pdrSellerID:'',
  pdrLPAKey:'',

  copyPropertyAccessContactInfo:false
};

//reducer function that perform state update
const reducer = getReducer();

const NewAppraisal  = forwardRef((props, ref) => {
  const controller = new AbortController();
  const divRef = useRef(null);
  const newsid = useRef(generateSID());
  const history = useNavigate();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  const refs = useRef({});

  useEffect(() => {
    state.toUploadFiles.forEach((file, i) => {
      refs.current[file.name + 'description' + i] = React.createRef();
      refs.current[file.name + i] = React.createRef();
    });
  }, [state.toUploadFiles]);

  useEffect(() => {
    validateCCType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.newcc_card_type, state.ccNumberValid]);

  useEffect(() => {
    if(state.coveredBy!==''){
      validateCCNumber();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.coveredBy, state.newcc_card_number, state.ccTypeValid]);


  //run only once when component is loaded
  useEffect(()=>{
    getAppraisalTypes();
    getAllClientProfiles();
    getAllClientEntityList();
    getFileTypes();

    if(divRef.current)
      divRef.current.innerHTML = "<img id=\"trustwaveSealImage\" src=\"https://sealserver.trustwave.com/seal_image.php?customerId=873823340ca511e0b34b005056b201e5&size=105x54&style=\" border=\"0\" style=\"cursor:pointer;\" onclick=\"javascript:window.open('https://sealserver.trustwave.com/cert.php?customerId=873823340ca511e0b34b005056b201e5&size=105x54&style=', 'c_TW', 'location=no, toolbar=no, resizable=yes, scrollbars=yes, directories=no, status=no, width=615, height=720'); return false;\" oncontextmenu=\"javascript:alert('Copying Prohibited by Law - Trusted Commerce is a Service Mark of TrustWave Holdings, Inc.'); return false;\" alt=\"This site is protected by Trustwave's Trusted Commerce program\" title=\"This site is protected by Trustwave's Trusted Commerce program\" />";
    
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state
  const onFileTypeChange = (name, fileType) => {
    for(let i=0;i<state.toUploadFiles.length;i++){
      if(state.toUploadFiles[i].name===name){
        let newToUploadFiles = [];

        for(let j=0;j<state.toUploadFiles.length;j++){
          let newFile = deepCopyFileObject(state.toUploadFiles[j]);

          if(j===i){
            newFile.fileType = fileType;
          }
          newToUploadFiles.push(newFile);
        }
        setState({toUploadFiles:newToUploadFiles});
      }
    }
  }

  const onFileDescChange = (name, e) => {
    for(let i=0;i<state.toUploadFiles.length;i++){
      if(state.toUploadFiles[i].name===name){
        let newToUploadFiles = [];

        for(let j=0;j<state.toUploadFiles.length;j++){
          let newFile = deepCopyFileObject(state.toUploadFiles[j]);

          if(j===i){
            newFile.description = e.target.value;
          }
          newToUploadFiles.push(newFile);
        }
        setState({toUploadFiles:newToUploadFiles});
        return;
      }
    }
  }

  const getValueByKey = (name, property) => {
    const file = state.toUploadFiles.find(item => item.name === name);
    return file ? file[property] : '';
  }

  const deepCopyFileObject = (file) => {
    let newFile = new File([file],file.name);
    newFile.preview = file.preview;
    newFile.fileType = file.fileType;
    newFile.description = file.description;
    newFile.status = file.status;

    return newFile
  }

  const selectClientContacts = (entry) => {
    //add all selected contacts into the client entities
    let clientEntities = state.clientEntities.slice();
    entry.contacts.map(
      (contact)=>{
        //check if it's already exist
        let duplicate = false;
        for(let i=0;i<clientEntities.length;i++){
          if(clientEntities[i].email===contact.email){
            duplicate = true;
            break;
          }
        }

        if(!duplicate){
          let tmp = {};
          tmp.label = contact.label;
          tmp.name = contact.name;
          tmp.email = contact.email;
          tmp.phone = contact.phone;
          tmp.work_phone = '';
          tmp.mobile_phone = '';

          clientEntities.push(tmp);
        }
        return null;
      }
    );

    //clear out all empty client entity that does not have name && email && phone
    let tmp = [];

    for(let i=0;i<clientEntities.length;i++){
      if(clientEntities[i].email!==''||clientEntities[i].phone!==''||clientEntities[i].name!==''||clientEntities[i].label==='Co-Borrower')
        tmp.push(clientEntities[i]);
    }

    clientEntities = tmp;
    console.log(clientEntities);
    setState({clientEntities:clientEntities, searchAccosiatesPopUp:!state.searchAccosiatesPopUp, contactID:entry.ID});
  }

  const copyPropertyAccessContactInfo = () => {
    if(!state.copyPropertyAccessContactInfo){
      let names = state.propertyAccessName.split(' ');
      let borrowerFirstName = '';
      let borrowerLastName = '';

      if(names.length>0){
        borrowerFirstName = names[0];
        borrowerLastName = state.propertyAccessName.replace(names[0]+' ','');
      }
      setState(
        {
          borrowerFirstName:borrowerFirstName,
          borrowerLastName:borrowerLastName,
          borrowerPhone:state.propertyAccessHomePhone,
          borrowerWorkPhone:state.propertyAccessWorkPhone,
          borrowerMobilePhone:state.propertyAccessMobilePhone,
          borrowerEmail:state.propertyAccessEmail
        }
      )
    }

    setState({copyPropertyAccessContactInfo:!state.copyPropertyAccessContactInfo})
  }

  const standAlone = (appraisalType) => {
    let appraisalTypes = state.standAlone.slice();

    for(let i=0;i<appraisalTypes.length;i++){
      if(appraisalTypes[i].includes(appraisalType))
        return true;
    }

    return false;
  }

  const handleAsyncSelectChange = (selected, index) => {
    let clientEntities = state.clientEntities.slice();

    let clientContactSearchResult = state.clientContactSearchResult.slice();

    for(let i=0;i<clientContactSearchResult.length;i++){

      if(clientContactSearchResult[i].name+' - '+clientContactSearchResult[i].email===selected){
        console.log(clientEntities);
        clientEntities[index].name = clientContactSearchResult[i].name;
        clientEntities[index].email = clientContactSearchResult[i].email;
        clientEntities[index].phone = clientContactSearchResult[i].phone;
        clientEntities[index].work_phone = '';
        clientEntities[index].mobile_phone = '';
      }
    }

    setState({clientEntities:clientEntities});
  }

  const updateLoanType = (loanType) => {
    let filteredAppraisalTypes = [];

    if(loanType==='FHA'){
      for(let i=0;i<state.availableAppraisalTypes.length;i++){
        if(propertyTypeCheck(state.availableAppraisalTypes[i].appraisal_type, state.propertyType)===false)
          continue;
        if(state.availableAppraisalTypes[i].appraisal_type.includes('FHA'))
          filteredAppraisalTypes.push(state.availableAppraisalTypes[i]);
      }
    }
    else if(loanType==='USDA'){
      for(let i=0;i<state.availableAppraisalTypes.length;i++){
        if(propertyTypeCheck(state.availableAppraisalTypes[i].appraisal_type, state.propertyType)===false)
          continue;
        if(state.availableAppraisalTypes[i].appraisal_type.includes('USDA'))
          filteredAppraisalTypes.push(state.availableAppraisalTypes[i]);
      }
    }
    else if(loanType==='Jumbo <$1m'){
      for(let i=0;i<state.availableAppraisalTypes.length;i++){
        if(propertyTypeCheck(state.availableAppraisalTypes[i].appraisal_type, state.propertyType)===false)
          continue;
        if(state.availableAppraisalTypes[i].appraisal_type.includes('<$1m')||standAlone(state.availableAppraisalTypes[i].appraisal_type))
          filteredAppraisalTypes.push(state.availableAppraisalTypes[i]);
      }
    }
    else if(loanType==='Jumbo $1m - $2m'){
      for(let i=0;i<state.availableAppraisalTypes.length;i++){
        if(propertyTypeCheck(state.availableAppraisalTypes[i].appraisal_type, state.propertyType)===false)
          continue;
        if(state.availableAppraisalTypes[i].appraisal_type.includes('$1m - $2m')||standAlone(state.availableAppraisalTypes[i].appraisal_type))
          filteredAppraisalTypes.push(state.availableAppraisalTypes[i]);
      }
    }
    else if(loanType==='Jumbo >$2m'){
      for(let i=0;i<state.availableAppraisalTypes.length;i++){
        if(propertyTypeCheck(state.availableAppraisalTypes[i].appraisal_type, state.property_type)===false)
          continue;
        if(state.availableAppraisalTypes[i].appraisal_type.includes('>$2m')||standAlone(state.availableAppraisalTypes[i].appraisal_type))
          filteredAppraisalTypes.push(state.availableAppraisalTypes[i]);
      }
    }
    else if(loanType==='Conventional'||loanType==='High Balanced'||loanType==='Private'){
      for(let i=0;i<state.availableAppraisalTypes.length;i++){
        if(propertyTypeCheck(state.availableAppraisalTypes[i].appraisal_type, state.propertyType)===false)
          continue;
        if(!state.availableAppraisalTypes[i].appraisal_type.includes('FHA')&&!state.availableAppraisalTypes[i].appraisal_type.includes('USDA')&&!state.availableAppraisalTypes[i].appraisal_type.includes('<$1m')&&!state.availableAppraisalTypes[i].appraisal_type.includes('$1m - $2m')&&!state.availableAppraisalTypes[i].appraisal_type.includes('>$2m'))
          filteredAppraisalTypes.push(state.availableAppraisalTypes[i]);
      }
    }
    else if(loanType==='Other'){
      let excludes = [
        '1004',
        '1073',
        '1025',
        'FHA',
        'USDA',
        'Jumbo'
      ];

      for(let i=0;i<state.availableAppraisalTypes.length;i++){
        if(propertyTypeCheck(state.availableAppraisalTypes[i].appraisal_type, state.propertyType)===false)
          continue;

        let exclude = false;
        for(let j=0;j<excludes.length;j++){
          if(state.availableAppraisalTypes[i].appraisal_type.includes(excludes[j])){
            //futher check if it's 1004D
            if(state.availableAppraisalTypes[i].appraisal_type.includes('1004D')){
              if(state.availableAppraisalTypes[i].appraisal_type.includes('FHA')||state.availableAppraisalTypes[i].appraisal_type.includes('USDA')){
                exclude = true;
                break;
              }
            }
            else{
              exclude = true;
              break;
            }
          }
        }

        if(exclude===false)
          filteredAppraisalTypes.push(state.availableAppraisalTypes[i]);
      }
    }
    else{
      filteredAppraisalTypes = state.availableAppraisalTypes.slice();
    }
    if(loanType!=='')
      setState({availableAppraisalTypesFiltered:filteredAppraisalTypes});
    else
      updatePropertyType(state.propertyType);
  }

  const propertyTypeCheck = (appraisalType,propertyType) => {
    let excludeTypes = state.appraisalTypeFilters[propertyType];
    for(let j=0;excludeTypes&&j<excludeTypes.length;j++){

      if(appraisalType.includes(excludeTypes[j])){
        if(appraisalType.includes('1004D'))
          return true;
        else
          return false;
      }
    }
    return true;
  }

  const updatePropertyType = (propertyType) => {
    console.log(propertyType)
    let filteredAppraisalTypes = [];

    for(let i=0;i<state.availableAppraisalTypes.length;i++){
      if(propertyTypeCheck(state.availableAppraisalTypes[i].appraisal_type,propertyType)===true)
        filteredAppraisalTypes.push(state.availableAppraisalTypes[i]);
    }

    setState({availableAppraisalTypesFiltered:filteredAppraisalTypes});
  }

  const buttonHandler = () => {
    if(state.submitDisabled)
      return;

    setState({submitDisabled:true},createNewAppraisal());
  }

  const updateEntityInfo = (index, field, value) => {
    let clientEntities = state.clientEntities.slice();
    let target = Object.assign({},clientEntities[index]);
    target[field] = value;

    clientEntities.splice(index, 1, target);

    setState({clientEntities:clientEntities});
  }

  const handleNewAppraisalTypeChange = (appraisalType) => {
    setState({appraisalType:appraisalType});
  }

  const addNewClientEntity = () => {
    let clientEntities = state.clientEntities.slice();
    let tmp = {};

    if(state.selectedNewEntity!=='Other')
      tmp.label = state.selectedNewEntity;
    else
      tmp.label = '';
    tmp.name = '';
    tmp.phone = '';
    tmp.work_phone = '';
    tmp.mobile_phone = '';
    tmp.email = '';

    if(state.selectedNewEntity==='Co-Borrower'||state.selectedNewEntity==='Borrower')
      clientEntities.unshift(tmp);
    else
      clientEntities.push(tmp);

    setState({clientEntities:clientEntities});
    toggleNewEntityDropDownOpen();
  }

  const toggleNewEntityDropDownOpen = () => {
    setState({newEntityDropDownOpen:!state.newEntityDropDownOpen});
  }

  const toggleSearchClientContactPopUp = () => {
    setState({searchAccosiatesPopUp:!state.searchAccosiatesPopUp});
  }

  const toggleResizeFile = () => {
    if(state.resizeFilePopUp)
      setState({bigFiles:[]});
    setState({resizeFilePopUp: !state.resizeFilePopUp});
  }

  const handleClientProfileChange = (client) => {
    setState({clientEntities:[],client:client});
    getClientBranches(client);
  }

  const handleOpenByClientProfileChange = (client) => {
    setState({openBy:client});
    getOpenByClientBranches(client);
  }

  const removeClientEntity = (index) => {
    let clientEntities = state.clientEntities.slice();
    clientEntities.splice(index,1);

    setState({clientEntities:clientEntities});
  }

  //remove a specific file from the toUpload list.
  const removeToUploadFile = (preview) => {
    let toRemoveIndex = -1;
    for(let i=0;i<state.toUploadFiles.length;i++){
      if(state.toUploadFiles[i].preview===preview){
        toRemoveIndex = i;
        break;
      }
    }

    if(toRemoveIndex!==-1){
      let newFiles = state.toUploadFiles.slice();
      newFiles.splice(toRemoveIndex,1);

      setState({toUploadFiles:newFiles});
    }
  }

  //function trigger for dropzone react.
  //this function contians two list of files, the accepted and rejected file per the configuration
  const onDrop = (acceptedFiles, rejectedFiles) => {
    let existingFiles = state.toUploadFiles.slice();
    let bigFiles = state.bigFiles;

    for(let i=0;i<acceptedFiles.length;i++){
      let file = acceptedFiles[i];
      file.status = 'Pending';
      file.fileType = '';

      let isBigFile = false;
      if(file.size > 40000000){
        isBigFile = true;
        bigFiles.push(file.name);
      }

      let duplicate = false;
      for(let j=0;j<state.toUploadFiles.length;j++){
        if(state.toUploadFiles[j].name===acceptedFiles[i].name){
          duplicate = true;
          setState({errorMessage:'Duplicate file name "'+acceptedFiles[i].name+'"'})
        }
      }
      if(!duplicate && !isBigFile)
        existingFiles.push(file);
    }
    if(bigFiles.length){
      setState({resizeFilePopUp:true, bigFiles:bigFiles});
    }
    setState({toUploadFiles: existingFiles});
  }

  //do checking on each step
  const submitForm = (e) => {
    e.preventDefault();

    switch(state.currentStep){
      case 0:

        break;
      case 1:

        break;
      case 2:

        break;
      case 3:

        break;
      default:

        break;
    }

    if(state.currentStep===3){
      let pass = true;

      let fileTypes = [];
      let descriptions = [];
      let errorMessage = '';
      let filename = '';

      for(let i =0; i<state.toUploadFiles.length;i++){
        let value = state.toUploadFiles[i].fileType;
        let description = state.toUploadFiles[i].description;


        if(!value||value===''){
          pass = false;
          fileTypes.push('');
          filename = state.toUploadFiles[i].name;
        }
        else
          fileTypes.push(value);
        descriptions.push(description);
      }

      setState({descriptionsToUpload:descriptions, fileTypesToUpload:fileTypes});
      if(filename!==''){
        errorMessage = '*Please select the file type for the file "'+filename+'".';
        setState({errorMessage:errorMessage});
      }else{
        setState({errorMessage:''});
      }

      if(pass===true){
        handleStepAdvance();
      }
    }
    else if(state.currentStep===4){
      if(state.newcc_card_number!==''){
        let pass = true;
        if(state.newcc_cvc===''){
          pass = false;
          setState({errorMessage:'Please provide the CVC number.'});
        }
        if(state.newcc_expiration_year===''){
          pass = false;
          setState({errorMessage:'Please provide the card expiration year.'});
        }
        if(state.newcc_expiration_month===''){
          pass = false;
          setState({errorMessage:'Please provide the card expiration month.'});
        }
        if(state.newcc_card_type===''){
          pass = false;
          setState({errorMessage:'Please provide the card type.'});
        }
        if(state.newcc_first_name===''){
          pass = false;
          setState({errorMessage:'Please provide the card holder first name.'});
        }
        if(state.newcc_last_name===''){
          pass = false;
          setState({errorMessage:'Please provide the card holder last name.'});
        }
        if(state.coveredBy===''){
          pass = false;
          setState({errorMessage:'Please specify the card holder.'});
        }
        if(state.newcc_street===''){
          pass = false;
          setState({errorMessage:'Please provide the card billing street.'});
        }
        if(state.newcc_city===''){
          pass = false;
          setState({errorMessage:'Please provide the card billing city.'});
        }
        if(state.newcc_state===''){
          pass = false;
          setState({errorMessage:'Please provide the card billing state.'});
        }
        if(state.newcc_zip===''){
          pass = false;
          setState({errorMessage:'Please provide the card billing zip.'});
        }

        if(pass===true)
          handleStepAdvance();
        else{
          if(ReactDOM.findDOMNode(this)&&ReactDOM.findDOMNode(this).parentElement)
            ReactDOM.findDOMNode(this).parentElement.scrollTop = 0;
        }
      }
      else
        handleStepAdvance();
    }
    else
      handleStepAdvance();
  }

  const handleStepAdvance = () => {
    if(ReactDOM.findDOMNode(this)&&ReactDOM.findDOMNode(this).parentElement)
      ReactDOM.findDOMNode(this).parentElement.scrollTop = 0;

    let step = state.currentStep+1;
    //step 3 then finish, send create appraisal request
    //if(step===6)
      //createNewAppraisal();
    //else advance the step
    if(step!==6){
      setState({currentStep:step});
      if(state.maxStep<step)
        setState({maxStep:step});
    }
  }

  const updateStep = (step) => {
    if(step<=state.maxStep)
      setState({currentStep:step});
  }

  const googlePlaceOnChange = (address) => {
    if(address!==state.address){

      if(address.indexOf(',')!==-1 && address.length>10){
        setState({lat:null, lng:null});

        geocodeByAddress(address)
          .then(
            results => {
              //always use the first returned result
              let targetResult = results[0];

              let addressComponents = targetResult.address_components;

              let streetNumber = '';
              let route = '';
              let city = '';
              let county = '';
              let stateName = '';
              let zip = '';

              //go through the address components and grab the street, city, county, state, and zip
              for(let i=0;i<addressComponents.length;i++){
                if(addressComponents[i].types[0]==='street_number')
                  streetNumber = addressComponents[i].long_name;
                else if(addressComponents[i].types[0]==='route')
                  route = addressComponents[i].long_name;
                else if(addressComponents[i].types[0]==='locality'&&addressComponents[i].types[1]==='political')
                  city = addressComponents[i].long_name;
                else if(addressComponents[i].types[0]==='administrative_area_level_2'&&addressComponents[i].types[1]==='political')
                  county = addressComponents[i].long_name;
                else if(addressComponents[i].types[0]==='administrative_area_level_1'&&addressComponents[i].types[1]==='political')
                  stateName = addressComponents[i].long_name;
                else if(addressComponents[i].types[0]==='postal_code')
                  zip = addressComponents[i].long_name;
              }


              let street = '';

              if(streetNumber!==''&&route!=='')
                street = streetNumber+' '+route;

              //get long lat
              getLatLng(targetResult)
                .then(
                  result=>{
                    if(result){
                      let lat = result.lat;
                      let lng = result.lng;
                      console.log(lng);

                      setState({lat:lat, lng:lng});
                    }
                  }
                )
                .catch(
                  error=>{
                    console.log(error);
                  }
                );

              if(street!==''){
                setState({propertyStreet:street, address:street});
              }
              if(city!=='')
                setState({propertyCity:city});
              if(county!=='')
                setState({propertyCounty:county});
              if(stateName!=='')
                setState({propertyState:stateName});
              if(zip!=='')
                setState({propertyZip:zip});
            })
          .catch(
            error => {
              console.log(error);
            }
          );
      }
      else{
        setState({address:address, propertyStreet:address});
      }
    }
  }

  // Validate the Credit Card Number
  const validateCCNumber = () => {
    // console.log(state.newcc_card_number);
    let newCCNumber = state.newcc_card_number;

    let creditCard = valid.number(newCCNumber);
    let ccNumberError = "An unknown error occured. Please try again later";
    let ccNumberValid = false;

    if ((newCCNumber === null || !newCCNumber.trim())) {
      ccNumberError = "Credit card number is not complete";
    } else if (creditCard.isValid) {
      ccNumberValid = true;
    } else {
      ccNumberError = "Credit card number is invalid";
    }

    setState({ccNumberValid: ccNumberValid, ccNumberError: ccNumberError});
  }

  // Validate the Credit Card Type
  const validateCCType = () => {
    let newCCNumber = state.newcc_card_number;
    let newCCType = state.newcc_card_type;

    let creditCard = valid.number(newCCNumber);
    let ccTypeError = "An unknown error occured. Please try again later";
    let ccTypeValid = false;

    //Mapping
    if(newCCType === "amex"){
      newCCType = "american-express";
    }else if(newCCType === "dinersclub"){
      newCCType = "diners-club";
    }

    //Card Type Verification
    if (newCCType === null || !newCCType.trim() || creditCard.card === null) {
      if(creditCard.card === null){
        ccTypeError = "";
        ccTypeValid = true;
      }else{
        ccTypeError = "Credit card type is not complete";
      }
    } else if (
      creditCard.card.type &&
      creditCard.card.type.toUpperCase() === newCCType.toUpperCase()
    ) {

      ccTypeValid = true;
    } else {
      ccTypeError = "Credit card type is invalid";
    }

    setState({ccTypeValid: ccTypeValid, ccTypeError: ccTypeError});
  }

  //API call
  const getClientBranches = (client) => {
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;

      if(code==='00'){
        setState({clientBranches:response.data.data});
        if(response.data.data.length>0){
          let branch = response.data.data[0];
          setState({clientBranch:response.data.data[0].ID, clientBranchLabel:branch.branch_name+' - '+branch.street+' '+branch.city+', '+branch.state+' '+branch.zip});
        }
      }
    };
    callBack = callBack.bind(this);

    httpGet('client/branch/get/'+client, '', 'Oops, something went wrong and could not retrieve client entities list.', callBack);
  }

  const getOpenByClientBranches = (client) => {
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        setState({openByClientBranches:response.data.data});

        if(response.data.data.length>0){
          let branch = response.data.data[0];
          setState({openByClientBranch:response.data.data[0].ID, openByClientBranchLabel:branch.branch_name+' - '+branch.street+' '+branch.city+', '+branch.state+' '+branch.zip});
        }
      }
    };
    callBack = callBack.bind(this);
	  httpGet('client/branch/get/'+client, '', 'Oops, something went wrong and could not retrieve client entities list.', callBack);
  }

  const getAppraisalTypes = () => {
    let callBack = apiCallBack([{state:'availableAppraisalTypes', key:'data'}, {state:'availableAppraisalTypesFiltered', key:'data'}]);
    httpGet('appraisalType/get', '', 'Oops, something went wrong and could not load report types. Please try again later.', callBack);
  }

  const createNewAppraisal = () => {
    //add new appraiser call back
    let errorCallBack = apiCallBack([{state:'submitDisabled', value:false}]);

    let callBack = (response)=>{console.log(response);
      let code = response.data?response.data.code:undefined;
      if(code!=='00'){
        setState({submitDisabled:false});
      }
      else{
        history('/appraisal');
      }
    };
    callBack = callBack.bind(this);

    if(state.toUploadFiles.length>0){
      let files = [];
      for(let i=0;i<state.toUploadFiles.length;i++){
        const reader = new FileReader();
        reader.onload = () => {
          const fileAsBinaryString = reader.result;
          let base64 = btoa(fileAsBinaryString);

          let tmp = {};
          tmp.base64 = base64;
          tmp.name = state.toUploadFiles[i].name;

          files.push(tmp);
          if(files.length>=state.toUploadFiles.length){

            let parameters =[
              {
                field:'openBy',
                value:state.openBy
              },
              {
                field:'orderType',
                value:state.orderType
              },
              {
                field:'specialInstructions',
                value:state.specialInstructions
              },
              {
                field:'propertyAccessName',
                value:state.propertyAccessName
              },
              {
                field:'propertyAccessTitle',
                value:state.propertyAccessTitle
              },
              {
                field:'propertyAccessEmail',
                value:state.propertyAccessEmail
              },
              {
                field:'coveredBy',
                value:state.coveredBy
              },
              {
                field:'propertyType',
                value:state.propertyType
              },
              {
                field:'appraisalType',
                value:state.appraisalType
              },
              {
                field:'entities',
                value:state.clientEntities
              },
              {
                field:'propertyAccessWorkPhone',
                value:state.propertyAccessWorkPhone
              },
              {
                field:'propertyAccessHomePhone',
                value:state.propertyAccessHomePhone
              },
              {
                field:'propertyAccessWorkPhone',
                value:state.propertyAccessWorkPhone
              },
              {
                field:'salePrice',
                value:state.salePrice
              },
              {
                field:'propertyStreet',
                value:state.propertyStreet
              },
              {
                field:'propertyCity',
                value:state.propertyCity
              },
              {
                field:'propertyCounty',
                value:state.propertyCounty
              },
              {
                field:'propertyState',
                value:state.propertyState
              },
              {
                field:'propertyZip',
                value:state.propertyZip
              },
              {
                field:'pdrSellerID',
                value:state.pdrSellerID
              },
              {
                field:'pdrLPAKey',
                value:state.pdrLPAKey
              },
              {
                field:'loanNumber',
                value:state.loanNumber
              },
              {
                field:'fhaNumber',
                value:state.fhaNumber
              },
              {
                field:'loanAmount',
                value:state.loanAmount
              },
              {
                field:'loanPurpose',
                value:state.loanPurpose
              },
              {
                field:'loanType',
                value:state.loanType
              },
              {
                field:'occupant',
                value:state.occupant
              },
              {
                field:'apn',
                value:state.apn
              },
              {
                field:'client',
                value:state.client
              },
              {
                field:'isRush',
                value:state.isRush
              },
              {
                field:'borrowerFirstName',
                value:state.borrowerFirstName
              },
              {
                field:'borrowerLastName',
                value:state.borrowerLastName
              },
              {
                field:'borrowerEmail',
                value:state.borrowerEmail
              },
              {
                field:'borrowerPhone',
                value:state.borrowerPhone
              },
              {
                field:'borrowerWorkPhone',
                value:state.borrowerWorkPhone
              },
              {
                field:'borrowerMobilePhone',
                value:state.borrowerMobilePhone
              },
              {
                field:'lat',
                value:state.lat
              },
              {
                field:'lng',
                value:state.lng
              },
              {
                field:'billing_first_name',
                value:state.newcc_first_name
              },
              {
                field:'billing_last_name',
                value:state.newcc_last_name
              },
              {
                field:'email',
                value:state.newcc_email
              },
              {
                field:'card_number',
                value:state.newcc_card_number
              },
              {
                field:'card_type',
                value:state.newcc_card_type
              },
              {
                field:'cvc',
                value:state.newcc_cvc
              },
              {
                field:'expiration_month',
                value:state.newcc_expiration_month
              },
              {
                field:'expiration_year',
                value:state.newcc_expiration_year
              },
              {
                field:'billing_street',
                value:state.newcc_street
              },
              {
                field:'billing_city',
                value:state.newcc_city
              },
              {
                field:'billing_state',
                value:state.newcc_state
              },
              {
                field:'billing_zip',
                value:state.newcc_zip
              }
            ];

            if(state.clientBranch!==0){
              let tmp = {
                field:'clientBranchFk',
                value:state.clientBranch
              };

              parameters.push(tmp);
            }

            if(state.openByClientBranch!==0){
              let tmp = {
                field:'openByClientBranch',
                value:state.openByClientBranch
              };

              parameters.push(tmp);
            }

            let tmp = {
              field:'files',
              value: files
            }

            parameters.push(tmp);

            tmp = {
              field:'fileTypes',
              value: state.fileTypesToUpload
            }

            parameters.push(tmp);

            tmp = {
              field:'descriptions',
              value: state.descriptionsToUpload
            }

            parameters.push(tmp);


            if(state.expectedDate!==''){
              let tmp = {
                field:'expectedDate',
                value:state.expectedDate
              }

              parameters.push(tmp);
            }
            console.log(parameters);
            httpPost('appraisal/create', parameters, 'Appraisal created successfully.', 'Oops, something went wrong and could not create the appraisal. Please try again later.', callBack, errorCallBack);
          }
        };

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => {
          props.showMessage('error','File upload failed, please try again later.');
        };

        reader.readAsBinaryString(state.toUploadFiles[i]);
      }
    }
    else{
      let parameters =[
        {
          field:'openBy',
          value:state.openBy
        },
        {
          field:'orderType',
          value:state.orderType
        },
        {
          field:'specialInstructions',
          value:state.specialInstructions
        },
        {
          field:'propertyAccessName',
          value:state.propertyAccessName
        },
        {
          field:'propertyAccessTitle',
          value:state.propertyAccessTitle
        },
        {
          field:'propertyAccessEmail',
          value:state.propertyAccessEmail
        },
        {
          field:'coveredBy',
          value:state.coveredBy
        },
        {
          field:'propertyType',
          value:state.propertyType
        },
        {
          field:'appraisalType',
          value:state.appraisalType
        },
        {
          field:'entities',
          value:state.clientEntities
        },
        {
          field:'propertyAccessWorkPhone',
          value:state.propertyAccessWorkPhone
        },
        {
          field:'propertyAccessHomePhone',
          value:state.propertyAccessHomePhone
        },
        {
          field:'propertyAccessMobilePhone',
          value:state.propertyAccessMobilePhone
        },
        {
          field:'salePrice',
          value:state.salePrice
        },
        {
          field:'propertyStreet',
          value:state.propertyStreet
        },
        {
          field:'propertyCity',
          value:state.propertyCity
        },
        {
          field:'propertyCounty',
          value:state.propertyCounty
        },
        {
          field:'propertyState',
          value:state.propertyState
        },
        {
          field:'propertyZip',
          value:state.propertyZip
        },
        {
          field:'pdrSellerID',
          value:state.pdrSellerID
        },
        {
          field:'pdrLPAKey',
          value:state.pdrLPAKey
        },
        {
          field:'loanNumber',
          value:state.loanNumber
        },
        {
          field:'fhaNumber',
          value:state.fhaNumber
        },
        {
          field:'loanAmount',
          value:state.loanAmount
        },
        {
          field:'loanPurpose',
          value:state.loanPurpose
        },
        {
          field:'loanType',
          value:state.loanType
        },
        {
          field:'occupant',
          value:state.occupant
        },
        {
          field:'apn',
          value:state.apn
        },
        {
          field:'client',
          value:state.client
        },
        {
          field:'isRush',
          value:state.isRush
        },
        {
          field:'borrowerFirstName',
          value:state.borrowerFirstName
        },
        {
          field:'borrowerLastName',
          value:state.borrowerLastName
        },
        {
          field:'borrowerEmail',
          value:state.borrowerEmail
        },
        {
          field:'borrowerPhone',
          value:state.borrowerPhone
        },
        {
          field:'borrowerWorkPhone',
          value:state.borrowerWorkPhone
        },
        {
          field:'borrowerMobilePhone',
          value:state.borrowerMobilePhone
        },
        {
          field:'lat',
          value:state.lat
        },
        {
          field:'lng',
          value:state.lng
        },
        {
          field:'billing_first_name',
          value:state.newcc_first_name
        },
        {
          field:'billing_last_name',
          value:state.newcc_last_name
        },
        {
          field:'email',
          value:state.newcc_email
        },
        {
          field:'card_number',
          value:state.newcc_card_number
        },
        {
          field:'card_type',
          value:state.newcc_card_type
        },
        {
          field:'cvc',
          value:state.newcc_cvc
        },
        {
          field:'expiration_month',
          value:state.newcc_expiration_month
        },
        {
          field:'expiration_year',
          value:state.newcc_expiration_year
        },
        {
          field:'billing_street',
          value:state.newcc_street
        },
        {
          field:'billing_city',
          value:state.newcc_city
        },
        {
          field:'billing_state',
          value:state.newcc_state
        },
        {
          field:'billing_zip',
          value:state.newcc_zip
        }
      ];

      if(state.clientBranch!==0){
        let tmp = {
          field:'clientBranchFk',
          value:state.clientBranch
        };

        parameters.push(tmp);
      }

      if(state.openByClientBranch!==0){
        let tmp = {
          field:'openByClientBranch',
          value:state.openByClientBranch
        };

        parameters.push(tmp);
      }

      if(state.expectedDate!==''){
        let tmp = {
          field:'expectedDate',
          value:state.expectedDate
        }

        parameters.push(tmp);
      }

      httpPost('appraisal/create', parameters, 'Appraisal created successfully.', 'Oops, something went wrong and could not create the appraisal. Please try again later.', callBack, errorCallBack);
    }
  }

  const copyOrder = (ID) => {
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        let copy = response.data.data;
        let entities = [];

        for(let i=0;i<copy.entities.length;i++){
          if(copy.entities[i].entity_label==='Broker Company'||copy.entities[i].entity_label==='Appraiser'||copy.entities[i].entity_label==='API Integration'||copy.entities[i].entity_label==='Payment Contact')
            continue;
          let tmp = {
            label: copy.entities[i].entity_label,
            name: copy.entities[i].entity_name,
            phone: copy.entities[i].entity_phone,
            work_phone: copy.entities[i].entity_work_phone,
            mobile_phone: copy.entities[i].entity_mobile_phone,
            email: copy.entities[i].entity_email
          };

          entities.push(tmp);
        }

        setState({
          openBy:copy.open_by_client,
          propertyAccessName:copy.property_access_name,
          propertyAccessTitle:copy.property_access_title,
          propertyAccessEmail:copy.property_access_email,
          propertyType:copy.property_type,
          clientEntities:entities,
          propertyAccessHomePhone:copy.property_access_home_phone,
          propertyAccessWorkPhone:copy.property_access_work_phone,
          propertyAccessMobilePhone:copy.property_access_mobile_phone,
          salePrice:copy.selling_price,
          propertyStreet:copy.property_street,
          address:copy.property_street,
          propertyCity:copy.property_city,
          propertyCounty:copy.property_county,
          propertyState:copy.property_state,
          propertyZip:copy.property_zip,
          pdrSellerID:copy.pdr_seller_id,
          pdrLPAKey:copy.pdr_lpa_key,
          loanNumber:copy.loan_num,
          fhaNumber:copy.fha_case_number,
          loanType:copy.loan_type,
          loanAmount:copy.loan_amount,
          loanPurpose:copy.loan_purpose,
          occupant:copy.property_occupancy,
          apn:copy.apn,
          client:copy.client_fk,
          borrowerFirstName:copy.borrower_f_name,
          borrowerLastName:copy.borrower_l_name,
          borrowerEmail:copy.borrower_email,
          borrowerPhone:copy.borrower_phone,
          borrowerWorkPhone:copy.borrower_work_phone,
          borrowerMobilePhone:copy.borrower_mobile_phone,
          lat:copy.property_lat,
          lng:copy.property_lng,
          clientBranchFk:copy.client_branch_fk,
          openByClientBranch:copy.open_by_client_branch_fk
        });

        updatePropertyType(copy.property_type);
        updateLoanType(copy.loan_type);
        getClientBranches(copy.client_fk);
        getOpenByClientBranches(copy.open_by_client);
      }
    };
    callBack = callBack.bind(this);

    httpGet('appraisal/'+ID, '', 'Oops, something went wrong and could not load appraisal. Please try again later.', callBack);
  }

  const searchOrder = () => {
    let parameters = [
      {
        field:'keyword',
        value:state.searchOrder
      }
    ];

    let callBack = apiCallBack([{state:'searchOrders', key:'data'}]);
    httpPost('appraisal/admin/search', parameters, '', 'Oops, something went wrong and could not search the appraisal. Please try again later.', callBack);
  }

  const getAllClientProfiles = () => {
    let callBack = apiCallBack([{state:'clients', valuekey:['ID', 'company']}]);
    httpGet('client/get/limit=-1&offset=-1', '', 'Oops, something went wrong and could not retrieve client profiles.', callBack);
  }

  const getAllClientEntityList = () => {
    let callBack = (response)=>{
      let code = response.data?response.data.code:undefined;
      if(code==='00'){
        setState({entities:response.data.data});
        if(response.data.data.length>0)
          setState({selectedNewEntity:response.data.data[0].entity});
      }
    };
    callBack = callBack.bind(this);

    httpGet('client/entity/label/get', '', 'Oops, something went wrong and could not retrieve entity list.', callBack);
  }

  const getThisClientContact = () => {
    let type = 'retail';
    let closeBy = 0;
    if(state.orderType==='Wholesale'){
      type = 'wholesale';
      closeBy = state.client;
    }

    let callBack = apiCallBack([{state:'retail', key:'data'}]);
    if(type === 'wholesale'){
      callBack = apiCallBack([{state:'wholesale', key:'data'}]);
    }

	  httpGet('client/contact/'+type+'/client_fk='+state.openBy+'&keyword='+state.keyword+'&close_by_client_fk='+closeBy, '', 'Oops, something went wrong and could not load client contacts. Please try again later.', callBack);
  }

  const getClientContacts = (keyword, label) => {
    if(keyword&&keyword!==''){
      let SID = generateSID();
      newsid.current = SID;

      let type = 'retail';

      if(state.orderType==='Wholesale')
        type = 'wholesale';

      let encodedKeyword = encodeURIComponent(keyword.replace(/\//g, '%ForwardSlash').replace(/&/g, '%Ampersand'));

      let callBack = (response)=>{
        if(SID===newsid.current){
          let code = response.data?response.data.code:undefined;

          if(code!=='00'){
            return [{}];
          }
          else{
            if(type === 'wholesale'){
              setState({wholesale:response.data.data});
            }else{
              setState({retail:response.data.data});
            }
            let options = [];

            let results = [];

            for(let i=0;i<response.data.data.length;i++){
              for(let j=0;j<response.data.data[i].contacts.length;j++){
                if(response.data.data[i].contacts[j].label===label&&response.data.data[i].contacts[j].name.toLowerCase().indexOf(keyword.toLowerCase())!==-1){
                  results.push(response.data.data[i].contacts[j]);
                }
              }
            }

            setState({clientContactSearchResult:results});

            for(let i=0;i<results.length;i++){
              let tmp = {};
              tmp.label = results[i].name +' - '+results[i].email;
              tmp.value = results[i].name +' - '+results[i].email;

              options.push(tmp);
            }
              
            return options;
          }
        }
      };
      callBack = callBack.bind(this);

      let promise = httpGet('client/contact/'+type+'/keyword='+encodedKeyword+'&client_fk='+state.openBy, '', 'Oops, something went wrong and could not search for client entity. Please try again later.', callBack);
      return promise;
    }
  }

  const getFileTypes = () => {
    let callBack = apiCallBack([{state:'fileTypes', key:'data'}]);
	  httpGet('file/fileType/get', '', 'Oops, something went wrong and could not load appraisal file types. Please try again later.', callBack);
  }

  //render

  let loan;
  if(state.orderType === 'Wholesale'){
    loan = state.wholesale;
  }else{
    loan = state.retail;
  }

  let map;
  if(state.lat&&state.lng)
    map = <MyGoogleMap lat={state.lat} lng={state.lng} markers={[]} zoom={12}/>;
  else
    map = <MyGoogleMap lat={39.8333333} lng={-98.585522} markers={[]} zoom={4}/>;

  let fhaNumber, fhaNumber2,mainForm2, salePrice, salePrice2, toUploadFiles, toUploadFiles2, clientEntities, clientEntities2;


  if(state.appraisalType!==''){

    mainForm2 = <div>{state.appraisalType}</div>;

  }

  let step1, step2, step3, step4, step5, step6;

  if(state.loanPurpose==='Purchase'){
    salePrice =
    <Row>
      <Col sm="12">
        <label>Sale price</label>
        <Input type="text" required={true} value={state.salePrice} onChange={(e)=>setState({salePrice:e.target.value})}/>
      </Col>
    </Row>;
    salePrice2 =
    <Row>
      <Col sm="12">
        <label>Sale price</label><br/>
        {formatNumber(state.salePrice)}
      </Col>
    </Row>;
  }

  if(state.loanType==='FHA'){
    fhaNumber =
    <Row>
      <Col sm="12">
        <label>FHA number</label>
        <Input type="text" value={state.fhaNumber} onChange={(e)=>setState({fhaNumber:e.target.value})}/>
      </Col>
    </Row>
    fhaNumber2 =
    <Row>
      <Col sm="12">
        <label>FHA number</label><br/>
        {state.fhaNumber}
      </Col>
    </Row>
  }

  if(state.toUploadFiles.length>0){
    toUploadFiles = state.toUploadFiles.map(
      (file,index)=>{
        return <div key={index}>
          <Row className="no-margin">
            <Col sm="5">
              <i className="fa fa-times red-color cursor-pointer" onClick={()=>removeToUploadFile(file.preview)}></i> {file.name}
            </Col>
            <Col sm="2">
              <Input type="text" value={file.description} onChange={(e)=>onFileDescChange(file.name,e)}/>
            </Col>
            <Col sm="4">
              <MySelect
                modal={true}
                type="select"
                selectIsClearable={true}
                value={getValueByKey(file.name, 'fileType')}
                onChange={(e)=>{onFileTypeChange(file.name,e)}}
                options={state.fileTypes.map((fileType)=>{
                  return {label:fileType.name, value:fileType.name};
                })}
              />
            </Col>
          </Row>
        </div>
      }
    );

    toUploadFiles2 = state.toUploadFiles.map(
      (file,index)=>{
        return <div key={index}>{file.name}</div>
      }
    );
  }

  if(state.clientEntities.length>0){
    clientEntities2 = state.clientEntities.map(
      (clientEntity, index)=>{
        let workPhone;
        let mobilePhone;

        if(clientEntity.label==='Co-Borrower'||clientEntity.label==='Borrower'){
          workPhone =
          <Col sm="3">
            <label>Work Phone</label><br/>
            {clientEntity.work_phone}
          </Col>
          mobilePhone =
          <Col sm="3">
            <label>Mobile Phone</label><br/>
            {clientEntity.mobile_phone}
          </Col>;
        }
        return(
          <div key={index}>
            <Card>
            <CardHeader className="gray-color">
              {clientEntity.label}
            </CardHeader>
            <CardBody>
              <Row>
                <Col sm="12">
                  <label>Name</label><br/>
                  {clientEntity.name}
                </Col>
              </Row>
              <Row>
                <Col sm="3">
                  <label>Phone</label><br/>
                  {clientEntity.phone}
                </Col>
                {workPhone}
                {mobilePhone}
                <Col sm="3">
                  <label>Email</label><br/>
                  {clientEntity.email}
                </Col>
              </Row>
            </CardBody>
          </Card>
          <br/>
        </div>
        );
      }
    );
    clientEntities = state.clientEntities.map(
      (clientEntity,index)=>{
        let nameInput = <MySelect
          modal={true}
          type="async-select"
          value={state.clientEntities[index].name}
          onChange={(selected)=>handleAsyncSelectChange(selected, index)}
          loadOptions={(keyword)=>getClientContacts(keyword, clientEntity.label)}
        />;

        if(clientEntity.label==='Borrower' || clientEntity.label==='Co-Borrower'||clientEntity.label==='Other'||clientEntity.label==='Payment Contact'||clientEntity.label==='Listing Agent')
          nameInput = <Input required={true} value={clientEntity.name} type="text" onChange={(e)=>updateEntityInfo(index,'name',e.target.value)}/>;
        let labelInput = <Input type="text" value={clientEntity.label} required={true} onChange={(e)=>updateEntityInfo(index,'label',e.target.value)}/>;

        if(clientEntity.predefined===true)
          labelInput = <Input type="text" value={clientEntity.label} required={true} disabled/>;
        let homeKeyword;
        let workPhone;
        let mobilePhone;

        if(clientEntity.label==='Co-Borrower'||clientEntity.label==='Borrower'){
          homeKeyword = 'Home ';

          workPhone =
          <Col sm="3">
            <label>Work Phone</label>
            <Input type="text" value={clientEntity.work_phone} onChange={(e)=>updateEntityInfo(index,'work_phone',e.target.value)}/>
          </Col>;
          mobilePhone =
          <Col sm="3">
            <label>Mobile Phone</label>
            <Input type="text" value={clientEntity.mobile_phone} onChange={(e)=>updateEntityInfo(index,'mobile_phone',e.target.value)}/>
          </Col>;
        }
        return(
          <div key={index}>
            <Card>
              <CardHeader className="gray-color">
                <Row>
                  <Col sm="8">
                    {clientEntity.label} contact
                  </Col>
                  <Col sm="4" className="align-right">
                    <i className="fa fa-times red-color cursor-pointer" onClick={()=>removeClientEntity(index)}></i>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col sm="4">
                    <label><font color="red">*</font>Title</label>
                    {labelInput}
                  </Col>
                  <Col sm="5">
                    <label><font color="red">*</font><i className="fa fa-times red-color cursor-pointer" onClick={(e)=>{
                      let clientEntities = state.clientEntities.slice();

                      clientEntities[index].name='';
                      clientEntities[index].email='';
                      clientEntities[index].phone='';
                      clientEntities[index].work_phone='';
                      clientEntities[index].mobile_phone='';

                      setState({clientEntities:clientEntities});
                    }}></i> Name</label>
                    {nameInput}
                  </Col>
                </Row>
                <Row>
                  <Col sm="3">
                    <label><font color="red">*</font>Email</label>
                    <Input required={true} value={clientEntity.email} type="email" onChange={(e)=>updateEntityInfo(index,'email',e.target.value)}/>
                  </Col>
                  <Col sm="3">
                    <label>{homeKeyword}Phone</label>
                    <Input type="text" value={clientEntity.phone} onChange={(e)=>updateEntityInfo(index,'phone',e.target.value)}/>
                  </Col>
                  {workPhone}
                  {mobilePhone}
                </Row>
              </CardBody>
            </Card>
            <br/>
          </div>
        );
      }
    );
  }

  let clientName = '';
  for(let i=0;i<state.clients.length;i++){
    if(state.clients[i].key===state.client)
      clientName = state.clients[i].value;
  }

  let expirationYear = [];

  let currentYear = new Date().getFullYear();

  for(let i=0;i<20;i++){
    expirationYear.push(currentYear);
    currentYear++;
  }

  let bigFileList = state.bigFiles.join(', ');

  /*
  STEP 1
  */
  step1 =
  <div>
    <div>
      <i>**If you are submitting this order for an order that was submitted previously, you can look it up here and fill up the information automatically**</i>
      <Row>
        <Col sm="10">
          <Input type="text" value={state.searchOrder} onChange={(e)=>setState({searchOrder:e.target.value})}/>
        </Col>
        <Col sm="2" className="align-right">
          <Button color="warning" onClick={searchOrder}>Search</Button>
        </Col>
      </Row>
      <br/>
      <div className="medium-scroll-container-no-min">
      {
        state.searchOrders.map(
          (order, index)=>{
            return <div className="my-well cursor-pointer" key={index} onClick={(e)=>copyOrder(order.ID)}>
              <NavLink to="#">{order.reference_num+' - '+order.loan_num}</NavLink><br/>
              {order.property_street+' '+order.property_city+', '+order.property_state+' '+order.property_zip}<br/>
              {order.borrower_f_name+' '+order.borrower_l_name}<br/>
              {order.appraisal_type}
            </div>
          }
        )
      }
      </div>
    </div>
    <div className="my-divider">&nbsp;</div>
    {map}
    <br/>
    <form onSubmit={submitForm}>
      <Row>
        <Col sm="7">
          <label><font color="red">*</font>Street</label>
          <MyPlacesAutocomplete value={state.address} onChange={googlePlaceOnChange} onSelect={googlePlaceOnChange}/>
        </Col>
        <Col sm="5">
          <label><font color="red">*</font>City</label>
          <Input required={true} type="text" value={state.propertyCity} onChange={(e)=>setState({propertyCity:e.target.value})}/>
        </Col>
      </Row>
      <Row>
        <Col sm="4">
          <label><font color="red">*</font>State</label>
          <MySelect
            type="select"
            value={state.propertyState}
            required={true}
            onChange={(v)=>setState({propertyState:v})}
            options={state.states.map((stateName)=>{
              return {label:stateName.value, value:stateName.value};
            })}
            />
        </Col>
        <Col sm="3">
          <label>County</label>
          <Input type="text" disabled value={state.propertyCounty} onChange={(e)=>setState({propertyCounty:e.target.value})}/>
        </Col>
        <Col sm="2">
          <label><font color="red">*</font>Zip</label>
          <Input required={true} type="text" value={state.propertyZip} onChange={(e)=>setState({propertyZip:e.target.value})}/>
        </Col>
      </Row>
      <Row>
        <Col sm="3">
          <label><font color="red">*</font>Loan</label>
          <MySelect
            type="select"
            value={state.orderType}
            required={true}
            onChange={(v)=>setState({orderType:v})}
            options={[{label:"Retail",value:"Retail"}, {label:"Wholesale", value:"Wholesale"}]}
            />
        </Col>
        <Col sm="3">
          <label><font color="red">*</font>Property type</label>
          <MySelect
            type="select"
            value={state.propertyType}
            required={true}
            onChange={(v)=>{setState({propertyType:v});updatePropertyType(v);}}
            options={state.propertyTypes.map((propertyType)=>{
              return {label:propertyType.value, value:propertyType.value};
            })}
            />
        </Col>
        <Col sm="2">
          <label><font color="red">*</font>Occupant</label>
          <MySelect
            type="select"
            value={state.occupant}
            required={true}
            onChange={(v)=>setState({occupant:v})}
            options={state.occupants.map((occupant)=>{
              return {label:occupant.value, value:occupant.value};
            })}
            />
        </Col>
        <Col sm="2">
          <label>APN</label>
          <Input type="text" value={state.apn} onChange={(e)=>setState({apn:e.target.value})}/>
        </Col>
      </Row>
      <br/>
      <div className="align-right">
        <Button color="warning">Next</Button>
      </div>
    </form>
  </div>;

  let expectedDate;

  let date;
  if(state.expectedDate!=='')
    date = moment(state.expectedDate).toDate();

  if(state.isRush==='Rush'){
    expectedDate =
    <Row>
      <Col sm="12">
        <label>Expected date</label>
        <DatePicker
          className="form-control"
          dateFormat="yyyy/MM/dd"
          selected={date}
          onChange={(text)=>{(text)&&setState({expectedDate:text.toLocaleDateString('en-CA')})}}
        />
        <br/>
        <font color="red">*</font>Additional fee will be charged.
      </Col>
    </Row>;
  }

  let pdrInfoIDs;
  if(state.appraisalType==='ACE+ PDR'){
    pdrInfoIDs =
    <div><Row>
      <Col sm="12">
        <label>Seller ID {generateTooltip('pdrSellerID', 'Seller Service ID.')}</label>
        <Input type="text" value={state.pdrSellerID} onChange={(e)=>setState({pdrSellerID:e.target.value})}/>
      </Col>
    </Row>
    <Row>
      <Col sm="12">
        <label>LPA Key {generateTooltip('pdrLPAKey', 'Loan identifier produced by Freddie Mac\'s Loan Product Advisor application.')}</label>
        <Input type="text" value={state.pdrLPAKey} onChange={(e)=>setState({pdrLPAKey:e.target.value})}/>
      </Col>
    </Row></div>;
  }

  /*
  STEP 2
  */
  step2 =
  <div>
    <form onSubmit={submitForm}>
      <Card>
        <CardHeader className="gray-color">
          Client
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="12">
              <label><font color="red">*</font>Close By Client</label>
              <MySelect
                type="select"
                value={state.client}
                required={true}
                onChange={(v)=>{handleClientProfileChange(v);setState({client:v})}}
                options={state.clients.map((client)=>{
                  return {label:client.value, value:client.key};
                })}
                />
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <label><font color="red">*</font>Close By Client Branch</label>
              <MySelect
                type="select"
                value={state.clientBranch}
                required={true}
                onChange={(v)=>setState({clientBranch:v})}
                options={state.clientBranches.map((branch)=>{
                  return {label:branch.branch_name+' - '+branch.street+' '+branch.city+', '+branch.state+' '+branch.zip, value:branch.ID};
                })}
                />
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <label><font color="red">*</font>Open By Client</label>
              <MySelect
                type="select"
                value={state.openBy}
                required={true}
                onChange={(v)=>{handleOpenByClientProfileChange(v);setState({openBy:v})}}
                options={state.clients.map((client)=>{
                  return {label:client.value, value:client.key};
                })}
                />
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <label><font color="red">*</font>Open By Client Branch</label>
              <MySelect
                type="select"
                value={state.openByClientBranch}
                required={true}
                onChange={(v)=>setState({openByClientBranch:v})}
                options={state.openByClientBranches.map((branch)=>{
                  return {label:branch.branch_name+' - '+branch.street+' '+branch.city+', '+branch.state+' '+branch.zip, value:branch.ID};
                })}
                />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <br/>
      <Card>
        <CardHeader className="gray-color">
          Report Type & loan information
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="12">
              <label><font color="red">*</font>Loan number</label>
              <Input type="text" required={true} value={state.loanNumber} onChange={(e)=>setState({loanNumber:e.target.value})}/>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <label>Loan amount</label>
              <Input type="number" value={state.loanAmount} onChange={(e)=>setState({loanAmount:e.target.value})}/>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <label><font color="red">*</font>Loan type</label>
              <MySelect
                type="select"
                value={state.loanType}
                required={true}
                onChange={(v)=>{setState({loanType:v});updateLoanType(v)}}
                options={state.loanTypes.map((loanType)=>{
                  return {label:loanType.value, value:loanType.value};
                })}
              />
            </Col>
          </Row>
          {fhaNumber}
          <Row>
            <Col sm="12">
              <label><font color="red">*</font>Loan purpose</label>
              <MySelect
                type="select"
                value={state.loanPurpose}
                required={true}
                onChange={(v)=>setState({loanPurpose:v})}
                options={state.loanPurposes.map((loanPurpose)=>{
                  return {label:loanPurpose.value, value:loanPurpose.value};
                })}
              />
            </Col>
          </Row>
          {salePrice}

          <Row>
            <Col sm="12">
              <label>Anticipated Value</label>
              <Input type="number" value={state.estimatedValue} onChange={(e)=>setState({estimatedValue:e.target.value})}/>
            </Col>
          </Row>

          <Row>
            <Col sm="12">
              <label><font color="red">*</font>Rush</label>
              <MySelect
                type="select"
                value={state.isRush}
                required={true}
                onChange={(v)=>setState({isRush:v})}
                options={[{label:"Non-rush",value:"Non-rush"},{label:"Rush",value:"Rush"}]}
              />
            </Col>
          </Row>
          {expectedDate}

          <div className="my-divider"/><br/>

          <Row>
            <Col sm="12">
              <label><font color="red">*</font>Report Type</label>
              <MySelect
                type="select"
                value={state.appraisalType}
                required={true}
                onChange={(v)=>{handleNewAppraisalTypeChange(v);setState({appraisalType:v})}}
                options={state.availableAppraisalTypesFiltered.map((appraisalType)=>{
                  return {label:appraisalType.appraisal_type, value:appraisalType.appraisal_type};
                })}
              />
            </Col>
          </Row>
          {pdrInfoIDs}
        </CardBody>
      </Card>
      <br/>
      <Row>
        <Col sm="6">
          <Button onClick={()=>updateStep(0)} color="info">Back</Button>
        </Col>
        <Col sm="6" className="align-right">
          <Button color="warning">Next</Button>
        </Col>
      </Row>
    </form>
  </div>;

  /*
  STEP 3
  */
  step3 =
  <div>
    <form onSubmit={submitForm}>
      <div className="align-right">
        <NavLink to="#" onClick={toggleNewEntityDropDownOpen}><i className="fa fa-plus green-color"></i> Add new additional contact</NavLink>
      </div>
      <Modal className="my-modal" isOpen={state.newEntityDropDownOpen} toggle={toggleNewEntityDropDownOpen} >
        <ModalHeader hidden={true} toggle={toggleNewEntityDropDownOpen}></ModalHeader>
        <ModalBody>
          <center>
            <h5><i className="fa fa-plus"></i> New contact</h5>
          </center>
          <br/><br/><br/><br/>
          <MySelect
            type="select"
            modal={true}
            value={state.selectedNewEntity}
            onChange={(v)=>setState({selectedNewEntity:v})}
            options={state.entities.map((entity)=>{
              return {label:entity.entity, value:entity.entity};
            })}
          />
          <br/><br/><br/><br/><br/><br/><br/>
          <center>
            <Button color="warning" onClick={addNewClientEntity}>Add</Button>&nbsp;
            <Button color="info" onClick={toggleNewEntityDropDownOpen}>Close</Button>
          </center>
        </ModalBody>
      </Modal>
      <Card>
        <CardHeader className="gray-color">
          Contact for property access
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="4">
              <label><font color="red">*</font>Title</label>
              <Input required={true} value={state.propertyAccessTitle} type="text" onChange={(e)=>setState({propertyAccessTitle:e.target.value})}/>
            </Col>
            <Col sm="4">
              <label><font color="red">*</font>Name</label>
              <Input required={true} value={state.propertyAccessName} type="text" onChange={(e)=>setState({propertyAccessName:e.target.value})}/>
            </Col>
          </Row>
          <Row>
            <Col sm="3">
              <label><font color="red">*</font>Work Phone #</label>
              <Input type="text" required={true} value={state.propertyAccessWorkPhone} onChange={(e)=>setState({propertyAccessWorkPhone:e.target.value})}/>
            </Col>
            <Col sm="3">
              <label>Home Phone #</label>
              <Input value={state.propertyAccessHomePhone} type="text" onChange={(e)=>setState({propertyAccessHomePhone:e.target.value})}/>
            </Col>
            <Col sm="3">
              <label>Mobile Phone #</label>
              <Input value={state.propertyAccessMobilePhone} type="text" onChange={(e)=>setState({propertyAccessMobilePhone:e.target.value})}/>
            </Col>
            <Col sm="3">
              <label><font color="red">*</font>Email</label>
              <Input required={true} type="email" value={state.propertyAccessEmail} onChange={(e)=>setState({propertyAccessEmail:e.target.value})}/>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <br/>
      <Card>
        <CardHeader className="gray-color">
          <Row>
            <Col sm="6">
              Borrower Information
            </Col>
            <Col sm="6" className="align-right">
              <Input type="checkbox" checked={state.copyPropertyAccessContactInfo} onChange={copyPropertyAccessContactInfo}/>&nbsp;Same as property access info
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="4">
              <label><font color="red">*</font>First name</label>
              <Input required={true} type="text" value={state.borrowerFirstName} onChange={(e)=>{setState({borrowerFirstName:e.target.value})}}/>
            </Col>
            <Col sm="4">
              <label><font color="red">*</font>Last name</label>
              <Input required={true} type="text" value={state.borrowerLastName} onChange={(e)=>{setState({borrowerLastName:e.target.value})}}/>
            </Col>
          </Row>
          <Row>
            <Col sm="3">
              <label>Home Phone</label>
              <Input type="text" value={state.borrowerPhone} onChange={(e)=>{setState({borrowerPhone:e.target.value})}}/>
            </Col>
            <Col sm="3">
              <label>Work Phone</label>
              <Input type="text" value={state.borrowerWorkPhone} onChange={(e)=>{setState({borrowerWorkPhone:e.target.value})}}/>
            </Col>
            <Col sm="3">
              <label>Mobile Phone</label>
              <Input type="text" value={state.borrowerMobilePhone} onChange={(e)=>{setState({borrowerMobilePhone:e.target.value})}}/>
            </Col>
            <Col sm="3">
              <label><font color="red">*</font>Email</label>
              <Input type="email" required={true} value={state.borrowerEmail} onChange={(e)=>{setState({borrowerEmail:e.target.value})}}/>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <br/>
      <div className="align-right">
        <NavLink to="#" onClick={toggleSearchClientContactPopUp}><i className="fa fa-plus green-color"></i> Search for Client Contact</NavLink>
      </div>
      <Modal className="my-modal-wide" isOpen={state.searchAccosiatesPopUp} toggle={toggleSearchClientContactPopUp} >
        <ModalHeader hidden={true} toggle={toggleSearchClientContactPopUp}></ModalHeader>
        <ModalBody>
          <center>
            <h5><i className="fa fa-search"></i> Find Client Contact </h5>
          </center>
          <br/>
          <Card>
            <CardHeader>
              Client Contact
            </CardHeader>
            <CardBody>
              <Row>
                <Col sm="8">
                  <Input type="text" placeholder="Name/Email" value={state.keyword} onChange={(e)=>setState({keyword:e.target.value})}/>&nbsp;&nbsp;
                </Col>
                <Col sm="4" className="align-right">
                  <Button color="warning" onClick={getThisClientContact}>Search</Button>
                </Col>
              </Row>
              <Row>
                {
                  loan.map(
                    (entry, index)=>{
                      let style = {height:'300px'};
                      if(entry.ID === state.contactID){
                        style = {height:'300px', borderStyle: 'solid', borderWidth: 'thick', borderColor: '#2E8BC0'}
                      }
                      return <Col key={index} sm="3">
                        <div>
                          <div className="my-well padding" onClick={(e)=>selectClientContacts(entry)} style={style}>
                            <Row>
                              <Col>{entry.company}</Col>
                            </Row>
                            <br/>
                            {
                              entry.contacts.map(
                                (contact, index2)=>{
                                  return <div style={{fontSize:'11px'}} key={index2}>
                                    <div style={{marginBottom:'5px'}}>
                                      <b><i className="fa fa-user"/> {contact.label}</b><br/>
                                      {contact.name}<br/>
                                      {contact.email}&nbsp;&nbsp;
                                      {contact.phone}
                                    </div>
                                  </div>
                                }
                              )
                            }
                          </div>
                          <br/>
                        </div>
                      </Col>
                    }
                  )
                }
              </Row>
            </CardBody>
          </Card>
          <br/>
          <center>
            <Button color="info" onClick={toggleSearchClientContactPopUp}>Close</Button>
          </center>
        </ModalBody>
      </Modal>

      {clientEntities}
      <br/>
      <Row>
        <Col sm="6">
          <Button onClick={()=>updateStep(1)} color="info">Back</Button>
        </Col>
        <Col sm="6" className="align-right">
          <Button color="warning">Next</Button>
        </Col>
      </Row>
    </form>
  </div>;

  /*
  STEP 4
  */
  step4 =
  <div>
    <form onSubmit={submitForm}>
      <Modal className="my-modal" isOpen={state.resizeFilePopUp} toggle={toggleResizeFile} >
        <ModalHeader hidden={true} toggle={toggleResizeFile}></ModalHeader>
        <ModalBody>
          <center>
            <h5><i className="fa fa-exclamation-triangle"></i> File Too Large</h5>
          </center>

          <b>Your file is larger than <font color="red">40MB</font>. Please resize your following file(s):</b>
          <div>{bigFileList}</div>

          <br/><br/>
          <center>
            <Button color="warning" onClick={toggleResizeFile}>Ok</Button>
          </center>
        </ModalBody>
      </Modal>
      <Row>
        <Col sm="12">
          <label>Special instructions</label>
          <Input type="textarea" rows="6" style={{resize:'none'}} onChange={(e)=>setState({specialInstructions:e.target.value})} value={state.specialInstructions}/>
        </Col>
      </Row>
      <br/>

      <Card>
        <CardHeader className="gray-color">
          Appraisal & loan related documents
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="12">
              <font color="red">{state.errorMessage}</font>
              <br/>
              <i>Please upload purchase contract, addendum or any other documents that related to this loan here.</i><br/>
              <div>
                {toUploadFiles}
              </div>
              <MyDropzone onDrop={onDrop}/>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <br/>
      <Row>
        <Col sm="6">
          <Button onClick={()=>updateStep(2)} color="info">Back</Button>
        </Col>
        <Col sm="6" className="align-right">
          <Button color="warning">Next</Button>
        </Col>
      </Row>
    </form>
  </div>;

  /*
  STEP 5
  */
  step5 =
  <div>
    <form onSubmit={submitForm}>
      <Row>
        <Col sm="8">
          <div style={{position:'absolute',bottom:'0'}}>
            <b>Your credit card and identity information are secure.</b>
          </div>
        </Col><Col sm="4">
          <div className="align-right" ref={divRef}/>
        </Col>
      </Row>
      <br/>
      <font color="red">{state.errorMessage}</font>
      <Card>
        <CardHeader className="gray-color">
          <i className="fa fa-user"></i> Name on card
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="6">
              <label>First name</label>
              <Input type="text" value={state.newcc_first_name} onChange={(e)=>setState({newcc_first_name:e.target.value})}/>
            </Col>
            <Col sm="6">
              <label>Last name</label>
              <Input type="text" value={state.newcc_last_name} onChange={(e)=>setState({newcc_last_name:e.target.value})}/>
            </Col>
            <Col sm="6">
              <br/>
              <Input type="text" placeholder="Email (optional, for receipt)" value={state.newcc_email} onChange={(e)=>setState({newcc_email:e.target.value})}/>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <br/>
      <Card>
        <CardHeader className="gray-color">
          <i className="fa fa-credit-card"></i> Credit card information
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="3">
              <label>Card holder</label>
              <MySelect
                type="select"
                value={state.coveredBy}
                onChange={(v)=>setState({coveredBy:v})}
                options={[{label:"",value:""},{label:"Broker",value:"Broker"},{label:"Borrower",value:"Borrower"},{label:"Lender",value:"Lender"}]}
              />
            </Col>
            <Col sm="5">
              <label>Card number</label>
              <Input type="text" value={state.newcc_card_number} onChange={(e)=>setState({newcc_card_number:e.target.value})}/>
              <div>{!state.ccNumberValid&&<h6><font color="red">{'*** '+state.ccNumberError+' ***'}</font></h6>}</div>
            </Col>
            <Col sm="4">
              <label>Verification number</label>
              <Input type="text" value={state.newcc_cvc} onChange={(e)=>setState({newcc_cvc:e.target.value})}/>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col sm="6">
              <label>Card Type</label>
              <MySelect
                type="select"
                value={state.newcc_card_type}
                onChange={(v)=>setState({newcc_card_type:v})}
                options={[{label:"",value:""},{value:"visa",label:"Visa"},{value:"mastercard",label:"Master Card"},{value:"amex",label:"American Express"},{value:"dinersclub",label:"Dinners Club"},{value:"discover",label:"Discover"}]}
              />
              <div>{!state.ccTypeValid&&<h6><font color="red">{'*** '+state.ccTypeError+' ***'}</font></h6>}</div>
            </Col>
          </Row>
          <br/>
          <label>Expiration Date</label>
          <Row>
            <Col sm="6">
              <MySelect
                type="select"
                value={state.newcc_expiration_month}
                onChange={(v)=>setState({newcc_expiration_month:v})}
                options={[
                  {label:"January",value:"01"},
                  {label:"Febuary",value:"02"},
                  {label:"March",value:"03"},
                  {label:"April",value:"04"},
                  {label:"May",value:"05"},
                  {label:"June",value:"06"},
                  {label:"July",value:"07"},
                  {label:"August",value:"08"},
                  {label:"September",value:"09"},
                  {label:"October",value:"10"},
                  {label:"November",value:"11"},
                  {label:"December",value:"12"},
                ]}
                />
            </Col>
            <Col sm="6">
              <MySelect
                type="select"
                value={state.newcc_expiration_year}
                onChange={(v)=>setState({newcc_expiration_year:v})}
                options={expirationYear.map((year)=>{
                  return {label:year, value:year};
                })}
                />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <br/>

      <Card>
        <CardHeader className="gray-color">
          <i className="fa fa-bank"></i> Billing address
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="8">
              <label>Street</label>
              <Input type="text" value={state.newcc_street} onChange={(e)=>setState({newcc_street:e.target.value})}/>
            </Col>
          </Row>
          <Row>
            <Col sm="4">
              <label>City</label>
              <Input type="text" value={state.newcc_city} onChange={(e)=>setState({newcc_city:e.target.value})}/>
            </Col>
            <Col sm="5">
              <label>State</label>
              <MySelect
                type="select"
                value={state.newcc_state}
                onChange={(v)=>setState({newcc_state:v})}
                options={state.states.map((stateName)=>{
                  return {label:stateName.value, value:stateName.value};
                })}
                />
            </Col>
            <Col sm="3">
              <label>Zip</label>
              <Input type="text" value={state.newcc_zip} onChange={(e)=>setState({newcc_zip:e.target.value})}/>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <br/>
      <Row>
        <Col sm="6">
          <Button onClick={()=>updateStep(3)} color="info">Back</Button>
        </Col>
        <Col sm="6" className="align-right">
          <Button color="warning">Next</Button>
        </Col>
      </Row>
    </form>
  </div>

  /*
  STEP 6
  */
  step6 =
  <div>
    <form onSubmit={submitForm}>
      <Card>
        <CardHeader className="gray-color">
          Property
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="12">
              <label>Address</label><br/>
              {state.propertyStreet+' '+state.propertyCity+', '+state.propertyState+' '+state.propertyZip}
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <label>Property type</label><br/>
              {state.propertyType}
            </Col>
          </Row>
        </CardBody>
      </Card>
      <br/>
      <Card>
        <CardHeader className="gray-color">
          Appraisal & loan information
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="12">
              <label>Lender</label><br/>
              {clientName}
            </Col>
          </Row>
          <br/>
          <Row>
            <Col sm="12">
              <label>Report Type</label><br/>
              {mainForm2}
            </Col>
          </Row>
          <br/>
          <Row>
            <Col sm="12">
              <label>Rush</label><br/>
              {state.isRush}
            </Col>
          </Row>
          <br/>
          <Row>
            <Col sm="12">
              <label>Loan type</label><br/>
              {state.loanType}
            </Col>
          </Row>
          {fhaNumber2}
          <Row>
            <Col sm="12">
              <label>Loan purpose</label><br/>
              {state.loanPurpose}
            </Col>
          </Row>
          {salePrice2}
        </CardBody>
      </Card>
      <br/>
      <Card>
        <CardHeader className="gray-color">
          Contact information
        </CardHeader>
        <CardBody>
          <Card>
            <CardHeader className="gray-color">
              Property access
            </CardHeader>
            <CardBody>
              <Row>
                <Col sm="12">
                  <label>Title</label><br/>
                  {state.propertyAccessTitle}
                </Col>
              </Row>

              <Row>
                <Col sm="12">
                  <label>Name</label><br/>
                  {state.propertyAccessName}
                </Col>
              </Row>

              <Row>
                <Col sm="3">
                  <label>Home phone</label><br/>
                  {state.propertyAccessHomePhone}
                </Col>
                <Col sm="3">
                  <label>Work phone</label><br/>
                  {state.propertyAccessWorkPhone}
                </Col>
                <Col sm="3">
                  <label>Work phone</label><br/>
                  {state.propertyAccessMobilePhone}
                </Col>
                <Col sm="3">
                  <label>Email</label><br/>
                  {state.propertyAccessEmail}
                </Col>
              </Row>
            </CardBody>
          </Card>

          <br/>
          <Card>
            <CardHeader className="gray-color">
              Borrower
            </CardHeader>
            <CardBody>
              <Row>
                <Col sm="3">
                  <label>First name</label><br/>
                  {state.borrowerFirstName}
                </Col>
                <Col sm="3">
                  <label>Last name</label><br/>
                  {state.borrowerLastName}
                </Col>
              </Row><Row>
                <Col sm="3">
                  <label>Home Phone</label><br/>
                  {state.borrowerPhone}
                </Col>
                <Col sm="3">
                  <label>Work Phone</label><br/>
                  {state.borrowerWorkPhone}
                </Col>
                <Col sm="3">
                  <label>Mobile Phone</label><br/>
                  {state.borrowerMobilePhone}
                </Col>
                <Col sm="3">
                  <label>Email</label><br/>
                  {state.borrowerEmail}
                </Col>
              </Row>
            </CardBody>
          </Card>
          <br/>
          {clientEntities2}
        </CardBody>
      </Card>
      <br/>
      <Card>
        <CardHeader className="gray-color">
          Additional information
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm="12">
              <label>Special insturctions</label><br/>
              <i>{state.specialInstructions}</i>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <label>Document uploaded</label><br/>
              <div className="small-scroll-container">
                {toUploadFiles2}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <br/>
      <Row>
        <Col sm="6">
          <Button onClick={()=>updateStep(4)} color="info">Back</Button>
        </Col>
        <Col sm="6" className="align-right">
          <Button color="warning" disabled={state.submitDisabled!==false} onClick={buttonHandler}>Submit</Button>
        </Col>
      </Row>
    </form>
  </div>

  let activeStep = step1;
  if(state.currentStep===0)
    activeStep = step1;
  else if(state.currentStep===1)
    activeStep = step2;
  else if(state.currentStep===2)
    activeStep = step3;
  else if(state.currentStep===3)
    activeStep = step4;
  else if(state.currentStep===4)
    activeStep = step5;
  else if(state.currentStep===5)
    activeStep = step6;

  return <div className="my-well">
    <Row>
      <Col sm="6">
        <div className="page-title">
          <i className="fa fa-reorder"></i>&nbsp;New appraisal
        </div>
        <NavLink to="/appraisal">Back to appraisal list</NavLink>
      </Col>
      <Col sm="6">

      </Col>
    </Row>
    <div className="my-divider"></div>
    <MyFormStep steps = {state.steps} currentStep = {state.currentStep} updateStep = {updateStep} maxStep={state.maxStep}/>
    <br/>
    {activeStep}
  </div>;
});


export default NewAppraisal;
