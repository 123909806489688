//Author Sooyoung Kim
//Date July 6, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, formatNumber, formatDate, generateSID, replaceRegex} from '../../../util/util';
import {Card, CardHeader, CardBody, Col, Row} from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {NavLink} from 'react-router-dom';
import ReportFilter from '../report-filter';
import MyReactTable from '../../util/my-react-table';
import React, {useReducer, useEffect} from 'react';
import '../report.css';

//initialize the state
const initialState = {
  SID: generateSID(),
  loanPurpose:'',
  loanType:'',
  isRush:'',
  isComplex:'',
  state:'',
  city:'',
  county:'',
  entity:'',
  client:'',
  openBy:'',
  filterAppraisalTypes:[],
  filterCounty:[],
  from:'',
  to:'',

  reports:[],
  avg:0,
  count:0,
  standard_deviation:0,
  appraisers:[],
  appraiser:'',

  yearBuiltFrom:'',
  yearBuiltTo:'',
  glaFrom:'',
  glaTo:'',
  lotSizeFrom:'',
  lotSizeTo:'',
  roomCountFrom:'',
  roomCountTo:'',
  bedroomCountFrom:'',
  bedroomCountTo:'',
  bathroomCountFrom:'',
  bathroomCountTo:'',
  condition:'',
};

//reducer function that perform state update
const reducer = getReducer();


const AvgSalesReport  = (props)=>{
  const controller = new AbortController();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  //API call
  const getReport = () => {
    let appraisalType = replaceRegex(state.filterAppraisalTypes, '|');
    let loanPurpose = replaceRegex(state.loanPurpose);
    let counties = replaceRegex(state.filterCounty,',');
    
    let url = 'report/avgValue/openBy='+state.openBy+'&client='+state.client+'&appraiser='+state.appraiser+'&loanPurpose='+loanPurpose+'&loanType='+state.loanType+'&complex='+state.isComplex+'&rush='+state.isRush+'&from='+state.from+'&to='+state.to+'&entity='+state.entity+'&state='+state.state+'&city='+state.city+'&county='+counties+'&appraisalType='+appraisalType;
    url += '&yearBuiltFrom='+state.yearBuiltFrom+'&yearBuiltTo='+state.yearBuiltTo+'&glaFrom='+state.glaFrom+'&glaTo='+state.glaTo+'&lotSizeFrom='+state.lotSizeFrom+'&lotSizeTo='+state.lotSizeTo+'&roomCountFrom='+state.roomCountFrom+'&roomCountTo='+state.roomCountTo+'&bedroomCountFrom='+state.bedroomCountFrom+'&bedroomCountTo='+state.bedroomCountTo+'&bathroomCountFrom='+state.bathroomCountFrom+'&bathroomCountTo='+state.bathroomCountTo;
    url += '&condition='+state.condition;

    let callBack = apiCallBack([{state:'reports', key:'data.comps'}, {state:'avg', key:'data.avg'}, {state:'count', key:'data.count'}, {state:'standard_deviation', key:'data.standard_deviation'}]);
	  httpGet(url, '', 'Oops, something went wrong and could not load the report. Please try again later.', callBack);
  }

  //render
  const columns = [
    {
      id: 'address',
      Header: 'Address',
      accessor: d => d.property_street+' '+d.property_city+', '+d.property_state+' '+d.property_zip,
      Cell: props => <NavLink target="_blank" to={"/appraisal/"+props.row.original.extracted_from_appraisal_fk}>{props.row.original.property_street+' '+props.row.original.property_city+', '+props.row.original.property_state+' '+props.row.original.property_zip}</NavLink>,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'dataSource',
      Header: 'Data Source',
      accessor: d => d.date_source,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'salePrice',
      Header: 'Sale Price',
      accessor: d => d.sales_price,
      Cell: props => '$'+formatNumber(props.row.original.sales_price),
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'dateSale',
      Header: 'Date Sale',
      accessor: d => d.date_sale,
      Cell: props => formatDate(props.row.original.date_sale),
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'pricePerSqft',
      Header: 'Price Per Sqft',
      accessor: d => d.sales_price>0?d.sales_price/d.gla:0,
      Cell: props => '$'+formatNumber(props.row.original.sales_price>0?props.row.original.sales_price/props.row.original.gla:0),
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'yearBuilt',
      Header: 'Year Built',
      accessor: d => d.year_built,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'age',
      Header: 'Age',
      accessor: d => d.age,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'gla',
      Header: 'GLA',
      accessor: d => d.gla,
      Cell: props => formatNumber(props.row.original.gla)+'sqft',
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'lotSize',
      Header: 'Lot Size',
      accessor: d => d.lot_size,
      Cell: props => formatNumber(props.row.original.lot_size)+'sqft',
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'roomCount',
      Header: 'Room Count',
      accessor: d => d.room_count,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'bedroomCount',
      Header: 'Bedroom Count',
      accessor: d => d.bedroom_count,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'bathroomCount',
      Header: 'Bathroom Count',
      accessor: d => d.bathroom_count,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'accessoryUnitExist',
      Header: 'Accessory Unit',
      accessor: d => d.accessory_unit_exist,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'neighborhood',
      Header: 'Neighborhood',
      accessor: d => d.neighborhood_characteristic,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'zoningClassification',
      Header: 'Zoning Classification',
      accessor: d => d.zoning_classification,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'zoningDescription',
      Header: 'Zoning Description',
      accessor: d => d.zoning_description,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'zoningCompliance',
      Header: 'Zoning Compliance',
      accessor: d => d.zoning_compliance,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'siteCondition',
      Header: 'Site Condition',
      accessor: d => d.site_condition,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'physicalDeficiencies',
      Header: 'Physical Deficiencies',
      accessor: d => d.physical_deficiencies,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'conformToNeighborhood',
      Header: 'Conform To Neighborhood',
      accessor: d => d.conform_to_neighborhood,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'location',
      Header: 'Location',
      accessor: d => d.location,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'view',
      Header: 'View',
      accessor: d => d.view,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'design',
      Header: 'Design',
      accessor: d => d.design,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'qualityOfConstruction',
      Header: 'Quality Of Construction',
      accessor: d => d.quality_of_construction,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'condition',
      Header: 'Condition',
      accessor: d => d.condition,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'basement',
      Header: 'Basement',
      accessor: d => d.basement,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'basementFinish',
      Header: 'Basement Finish',
      accessor: d => d.basement_finish,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'functionalUtility',
      Header: 'Functional Utility',
      accessor: d => d.functional_utility,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'heatingCooling',
      Header: 'Heating & Cooling',
      accessor: d => d.heating_cooling,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'energyEfficientItem',
      Header: 'Energy Efficient Item',
      accessor: d => d.energy_efficient_item,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'porchDeck',
      Header: 'Porch Deck',
      accessor: d => d.porch_deck,
      headerStyle: {
        textAlign:'left'
      }
    },
    {
      id: 'garageCarPort',
      Header: 'Garage/Car Port',
      accessor: d => d.garage_car_port,
      headerStyle: {
        textAlign:'left'
      }
    }
  ]

  let from, to, yearBuiltFrom, yearBuiltTo;
  if(state.from!=='')
    from = moment(state.from).toDate();
  if(state.to!=='')
    to = moment(state.to).toDate();
  if(state.yearBuiltFrom!=='')
    yearBuiltFrom = moment(state.yearBuiltFrom).toDate();
  if(state.yearBuiltTo!=='')
    yearBuiltTo = moment(state.yearBuiltTo).toDate();

  let reportFiltersConfig = [
    {id:'client',value:state.client, updateFunc:(v)=>setState({client:v}), width:'4'},
    {id:'openBy',value:state.openBy, updateFunc:(v)=>setState({openBy:v}), width:'4'},
    {id:'appraiser',value:state.appraiser, updateFunc:(v)=>setState({appraiser:v}), width:'4'},
    {id:'rush',value:state.isRush, updateFunc:(v)=>setState({isRush:v}), width:'2'},
    {id:'complex',value:state.isComplex, updateFunc:(v)=>setState({isComplex:v}), width:'2'},
    {id:'state',value:state.state, updateFunc:(v)=>setState({state:v}), width:'3'},
    {id:'city',value:state.city, updateFunc:(v)=>setState({city:v}), width:'2'},
    {id:'county',value:state.filterCounty, updateFunc:(v)=>setState({filterCounty:v}), width:'3', group:true},
    {id:'loanPurpose',value:state.loanPurpose, updateFunc:(v)=>setState({loanPurpose:v}), width:'2'},
    {id:'loanType',value:state.loanType, updateFunc:(v)=>setState({loanType:v}), width:'2'},
    {id:'entity',value:state.entity, updateFunc:(v)=>setState({entity:v}), width:'3'},
    {id:'appraisalType',value:state.filterAppraisalTypes, updateFunc:(v)=>setState({filterAppraisalTypes:v}), width:'5'},
    {id:'custom',value:state.glaFrom, updateFunc:(v)=>setState({glaFrom:v}), width:'2', label:'GLA Min'},
    {id:'custom',value:state.glaTo, updateFunc:(v)=>setState({glaTo:v}), width:'2', label:'GLA Max'},
    {id:'custom',value:state.lotSizeFrom, updateFunc:(v)=>setState({lotSizeFrom:v}), width:'2', label:'Lot Size Min'},
    {id:'custom',value:state.lotSizeTo, updateFunc:(v)=>setState({lotSizeTo:v}), width:'2', label:'Lot Size Max'},
    {id:'custom',value:state.roomCountFrom, updateFunc:(v)=>setState({roomCountFrom:v}), width:'2', label:'Room Count Min'},
    {id:'custom',value:state.roomCountTo, updateFunc:(v)=>setState({roomCountTo:v}), width:'2', label:'Room Count Max'},
    {id:'custom',value:state.bedroomCountFrom, updateFunc:(v)=>setState({bedroomCountFrom:v}), width:'2', label:'Bedroom Count Min'},
    {id:'custom',value:state.bedroomCountTo, updateFunc:(v)=>setState({bedroomCountTo:v}), width:'2', label:'Bedroom Count Max'},
    {id:'custom',value:state.bathroomCountFrom, updateFunc:(v)=>setState({bathroomCountFrom:v}), width:'2', label:'Bathroom Count Min'},
    {id:'custom',value:state.bathroomCountTo, updateFunc:(v)=>setState({bathroomCountTo:v}), width:'2', label:'Bathroom Count Max'},
    {id:'custom',value:state.condition, updateFunc:(v)=>setState({condition:v}), width:'2', label:'Condition'},
    {id:'button',value:'Submit', updateFunc:getReport, width:'2', className:"align-right", color:"warning"},
  ];
  

  return <div>
    <Card>
      <CardHeader className="header-color">
        <i className="fa fa-reorder"></i>&nbsp;Avg Sales Report
      </CardHeader>
      <CardBody>
        <Row>
          <Col sm="3">
            <i className="fa fa-times cursor-pointer red-color" onClick={(e)=>setState({from:''})}/><b>Date Sale From</b>
            <br/>
            <DatePicker
              className="form-control"
              dateFormat="yyyy/MM/dd"
              selected={from}
              onChange={(text)=>{(text)&&setState({from:text.toLocaleDateString('en-CA')})}}
            />
          </Col>
          <Col sm="3">
            <i className="fa fa-times cursor-pointer red-color" onClick={(e)=>setState({to:''})}/><b>Date Sale To</b>
            <br/>
            <DatePicker
              className="form-control"
              dateFormat="yyyy/MM/dd"
              selected={to}
              onChange={(text)=>{(text)&&setState({to:text.toLocaleDateString('en-CA')})}}
            />
          </Col>
          <Col sm="3">
            <i className="fa fa-times cursor-pointer red-color" onClick={(e)=>setState({yearBuiltFrom:''})}/><b>Year Built From</b>
            <br/>
            <DatePicker
              className="form-control"
              dateFormat="yyyy/MM/dd"
              selected={yearBuiltFrom}
              onChange={(text)=>{(text)&&setState({yearBuiltFrom:text.toLocaleDateString('en-CA')})}}
            />
          </Col>
          <Col sm="3">
            <i className="fa fa-times cursor-pointer red-color" onClick={(e)=>setState({yearBuiltTo:''})}/><b>Year Built To</b>
            <br/>
            <DatePicker
              className="form-control"
              dateFormat="yyyy/MM/dd"
              selected={yearBuiltTo}
              onChange={(text)=>{(text)&&setState({yearBuiltTo:text.toLocaleDateString('en-CA')})}}
            />
          </Col>
        </Row>
        <br/>
        <ReportFilter {...props} configs={reportFiltersConfig}/>
        <br/>
        <div className="my-divider"/>
        <label>Avg Sales</label> ${formatNumber(state.avg)}<br/>
        <label>Total Record</label> {formatNumber(state.count)}<br/>
        <label>Standard Deviation</label> {formatNumber(state.standard_deviation)}<br/>
        <label>Acceptable Range</label> ${formatNumber(state.avg-state.standard_deviation>0?state.avg-state.standard_deviation:0)} to ${formatNumber(state.avg+state.standard_deviation)}<br/>
        <MyReactTable columns={columns} data={state.reports} className="table table-striped"/>
      </CardBody>
    </Card>
    <br/>
  </div>;
}


export default AvgSalesReport;
