//Author Sooyoung Kim
//Date Sep 5, 2023
import {getReducer, getSetStateFunction, postAPICallGenerator, callBackGenerator} from '../../util/util';
import { Button, Card, CardHeader, CardBody, Input, Row, Col} from 'reactstrap';
import MySelect from '../util/my-select';
import DatePicker from 'react-datepicker';
import React, {useReducer, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import '../util/my-xeditable.css';

//initialize the state
const initialState = {
  state: 'Alabama',
  registration_number: '',
  expiration_date: moment().toDate(),
  bond_required: 'yes',
  bond_expiration_date: moment().toDate(),
  bond_amount: '',
  sos_registration_number: '',
  trade_name_expiration_date: moment().toDate(),
  registration_fee: '',
  notify_email: '',
  states:[
    {key:'Alabama',value:'Alabama'},{key:'Alaska',value:'Alaska'},{key:'Arizona',value:'Arizona'},{key:'Arkansas',value:'Arkansas'},{key:'California',value:'California'},{key:'Colorado',value:'Colorado'},{key:'Connecticut',value:'Connecticut'},{key:'Delaware',value:'Delaware'},{key:'Florida',value:'Florida'},{key:'Georgia',value:'Georgia'},{key:'Hawaii',value:'Hawaii'},{key:'Idaho',value:'Idaho'},{key:'Illinois',value:'Illinois'},{key:'Indiana',value:'Indiana'},{key:'Iowa',value:'Iowa'},{key:'Kansas',value:'Kansas'},{key:'Kentucky',value:'Kentucky'},{key:'Louisiana',value:'Louisiana'},{key:'Maine',value:'Maine'},{key:'Maryland',value:'Maryland'},{key:'Massachusetts',value:'Massachusetts'},{key:'Michigan',value:'Michigan'},{key:'Minnesota',value:'Minnesota'},{key:'Mississippi',value:'Mississippi'},{key:'Missouri',value:'Missouri'},{key:'Montana',value:'Montana'},{key:'Nebraska',value:'Nebraska'},{key:'Nevada',value:'Nevada'},{key:'New Hampshire',value:'New Hampshire'},{key:'New Jersey',value:'New Jersey'},{key:'New Mexico',value:'New Mexico'},{key:'New York',value:'New York'},{key:'North Carolina',value:'North Carolina'},{key:'North Dakota',value:'North Dakota'},{key:'Ohio',value:'Ohio'},{key:'Oklahoma',value:'Oklahoma'},{key:'Oregon',value:'Oregon'},{key:'Pennsylvania',value:'Pennsylvania'},{key:'Rhode Island',value:'Rhode Island'},{key:'South Carolina',value:'South Carolina'},{key:'South Dakota',value:'South Dakota'},{key:'Tennessee',value:'Tennessee'},{key:'Texas',value:'Texas'},{key:'Utah',value:'Utah'},{key:'Vermont',value:'Vermont'},{key:'Virgin Islands',value:'Virgin Islands'},{key:'Virginia',value:'Virginia'},{key:'Washington',value:'Washington'},{key:'Washington DC',value:'Washington DC'},{key:'West Virginia',value:'West Virginia'},{key:'Wisconsin',value:'Wisconsin'},{key:'Wyoming',value:'Wyoming'}
  ],
};

//reducer function that perform state update
const reducer = getReducer();


const NewStateRegistration  = (props)=>{
  const controller = new AbortController();
  const history = useNavigate();

  const [state, dispatch] = useReducer(reducer,initialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpPost = postAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  //non API call but simpyl manage state

  //API call
  //create new announcement
  const crateStateRegistration = () => {
    let parameters = [
      {
        field:'state',
        value:state.state
      },
      {
        field:'registrationNumber',
        value:state.registration_number
      },
      {
        field:'expirationDate',
        value:state.expiration_date
      },
      {
        field:'bondRequired',
        value:state.bond_required
      },
      {
        field:'bondExpirationDate',
        value:state.bond_expiration_date
      },
      {
        field:'bondAmount',
        value:state.bond_amount
      },
      {
        field:'sosRegistrationNumber',
        value:state.sos_registration_number
      },
      {
        field:'tradeNameExpirationDate',
        value:state.trade_name_expiration_date
      },
      {
        field:'registrationFee',
        value:state.registration_fee
      },
      {
        field:'notifyEmail',
        value:state.notify_email
      }
    ];

    let callBack = apiCallBack([], history('/state-registration'));
    httpPost('stateRegistration/create', parameters, 'State registration created successfully.', 'Oops, something went wrong and could not create this state registration. Please try again later.', callBack);
  }
  //render
  return <div className="card padding">
    <Card>
      <CardHeader className="header-color  cursor-pointer">
        <i className="fa fa-information"></i> New State Information
      </CardHeader>
      <CardBody>
        <Row>
          <Col sm="6">
            <label>State</label>
          </Col>
          <Col sm="6">
            <MySelect
              type="select"
              value={state.state}
              onChange={(v)=>{setState({state:v})}}
              options={state.states.map((state)=>{
                return {label:state.value, value:state.key};
              })}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <label>Registration Number</label>
          </Col>
          <Col sm="6">
            <Input type="text" value={state.registration_number} onChange={(e)=>{setState({registration_number:e.target.value})}}/>
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <label>Expiration Date</label>
          </Col>
          <Col sm="6">
            <DatePicker
              className="form-control"
              dateFormat="yyyy/MM/dd"
              selected={moment(state.expiration_date).toDate()}
              onChange={(text)=>{(text)&&setState({expiration_date:text.toLocaleDateString('en-CA')})}}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <label>Bond Required</label>
          </Col>
          <Col sm="6">
            <MySelect
              type="select"
              value={state.bond_required}
              onChange={(v)=>{setState({bond_required:v})}}
              options={[{label:"Yes",value:"yes"},{label:"No",value:"no"}]}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <label>Bond Amount</label>
          </Col>
          <Col sm="6">
            <Input type="text" value={state.bond_amount} onChange={(e)=>{setState({bond_amount:e.target.value})}}/>
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <label>Bond Expiration Date</label>
          </Col>
          <Col sm="6">
            <DatePicker
              className="form-control"
              dateFormat="yyyy/MM/dd"
              selected={moment(state.bond_expiration_date).toDate()}
              onChange={(text)=>{(text)&&setState({bond_expiration_date:text.toLocaleDateString('en-CA')})}}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <label>SOS Registration Number</label>
          </Col>
          <Col sm="6">
            <Input type="text" value={state.sos_registration_number} onChange={(e)=>{setState({sos_registration_number:e.target.value})}}/>
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <label>Trade Name Expiration Date</label>
          </Col>
          <Col sm="6">
            <DatePicker
              className="form-control"
              dateFormat="yyyy/MM/dd"
              selected={moment(state.trade_name_expiration_date).toDate()}
              onChange={(text)=>{(text)&&setState({trade_name_expiration_date:text.toLocaleDateString('en-CA')})}}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <label>Registration Fee</label>
          </Col>
          <Col sm="6">
            <Input type="text" value={state.registration_fee} onChange={(e)=>{setState({registration_fee:e.target.value})}}/>
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <label>Notify Email</label>
          </Col>
          <Col sm="6">
            <Input type="text" value={state.notify_email} onChange={(e)=>{setState({notify_email:e.target.value})}}/>
          </Col>
        </Row>
        <br/>
        <center>
          <Button color="warning" onClick={crateStateRegistration}>Create New State Registration</Button>
        </center>
      </CardBody>
    </Card>
  </div>;
}


export default NewStateRegistration;
