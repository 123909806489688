//Author Sooyoung Kim
//Date May 26, 2023
import {getReducer, getSetStateFunction, getAPICallGenerator, callBackGenerator, getSession, getRoles} from '../../../util/util';
import { Collapse } from 'reactstrap';
import {NavLink, useNavigate} from 'react-router-dom';
import React, {useReducer, useEffect} from 'react';
import './side-bar.css';

//initialize the state
const initialState = {
  currentUrl: window.location.href?window.location.href:"",
  policyDropdownOpen: window.location.href.includes("client")?true:false,
  pipelineDropdownOpen: true,
  dashboardDropdownOpen: true,
  clientDropdownOpen: window.location.href.includes("client")?true:false,
  appraiserDropdownOpen: (window.location.href.includes("appraiser")||window.location.href.includes("amc"))?true:false,
  newAnnouncementCount:0,
};

//reducer function that perform state update
const reducer = getReducer();


const SideBar  = (props)=>{
  const history = useNavigate();
  const controller = new AbortController();
  let pipelinePreference = localStorage.getItem('pipelinePreference');

  let globalPipeline = false;
  if(pipelinePreference&&pipelinePreference!=='')
    if(pipelinePreference==='global')
      globalPipeline = true;

  let newInitialState = Object.assign({}, initialState, {globalPipeline:globalPipeline});

  const [state, dispatch] = useReducer(reducer,newInitialState);

  //wrapper function
  const setState = getSetStateFunction(dispatch);

  const apiCallBack = callBackGenerator(setState);
  const httpGet = getAPICallGenerator(props, {signal:controller.signal});

  //run only once when component is loaded
  useEffect(()=>{
    hasNewAnnouncement();

    return ()=> controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(()=>{
    setState({currentUrl:window.location.href});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[history]);

  //non API call but simpyl manage state
  const togglePolicy = ()=>{
    setState({
      policyDropdownOpen: !state.policyDropdownOpen
    });
  }

  const togglePipeline = ()=>{
    setState({
      pipelineDropdownOpen: !state.pipelineDropdownOpen
    });
  }

  const toggleDashboard = ()=>{
    setState({
      dashboardDropdownOpen: !state.dashboardDropdownOpen
    });
  }

  const toggleClient = ()=>{
    setState({
      clientDropdownOpen: !state.clientDropdownOpen
    });
  }

  const toggleAppraiser = ()=>{
    setState({
      appraiserDropdownOpen: !state.appraiserDropdownOpen
    });
  }

  //API call
  const hasNewAnnouncement = ()=>{
    let callBack = apiCallBack([{state:'newAnnouncementCount', key:'data.new_announcement_count'}]);
    httpGet('announcement/hasNewAnnouncement', '', '', callBack);
  }

  //render
  let count = <div style={{width:'10px'}} className="display-inline"></div>;
  if(state.newAnnouncementCount>0){
    count = <span className="my-badge" style={{fontSize:'11px'}}>{state.newAnnouncementCount}</span>
  }

  let appraisalLink = 'appraisal';
  let conditionLink = 'condition';
  let rebuttalLink = 'rebuttal';
  let dashboardLink = 'dashboard';

  if(state.globalPipeline){
    appraisalLink = 'appraisal-global';
    conditionLink = 'condition-global';
    rebuttalLink = 'rebuttal-global';
    dashboardLink = 'dashboard-global';
  }

  let roles = getRoles();
  let session = getSession();
  let userName = session.userFirstName+' '+session.userLastName;

  let adminSideBar, systemSettingBar;
  if(props.isLoggedIn){
    //only accessible by admin manager and super admin
    if(roles.indexOf('admin_manager')!==-1||roles.indexOf('super_admin')!==-1||roles.indexOf('admin')!==-1){
      adminSideBar =
      <li>
        <NavLink className={state.currentUrl.includes("/admin")?"padding sidebar-active-link":"padding"} to="/admin"><i className="fa fa-group sidebar-icon"></i> Admins</NavLink>
      </li>;

      systemSettingBar =
      <li>
        <NavLink className={state.currentUrl.includes("/system-setting")?"padding sidebar-active-link":"padding"} activeClassName="sidebar-active-link" to="/system-setting"><i className="fa fa-gears sidebar-icon"></i> System Settings</NavLink>
      </li>
    }

    return (
      <div className="sidebar">
        <ul>
          <li>
            <NavLink className={window.location.href.includes("/announcement")?"padding sidebar-active-link":"padding"}  to="/announcement"><i className="fa fa-volume-up sidebar-icon"></i> Announcement {count}</NavLink>
          </li>
          <li>
            <NavLink className={state.currentUrl.includes("/global-comment-template")?"padding sidebar-active-link":"padding"}  to="/global-comment-template"><i className="fa fa-pencil sidebar-icon"></i> Global Comment Template</NavLink>
          </li>
          <li className="collapse-nav">
            <NavLink to="#" className="padding" onClick={togglePolicy}><i className="fa fa-edit sidebar-icon"></i> Policies & Procedures<i className="fa fa-angle-double-down"></i></NavLink>
            <Collapse isOpen={state.policyDropdownOpen}>
              <NavLink className={state.currentUrl.includes("/policy")?"padding sidebar-active-link":"padding"} to="/policy">Policy</NavLink>
              <NavLink className={state.currentUrl.includes("/procedure")?"padding sidebar-active-link":"padding"} to="/procedure">Procedure</NavLink>
            </Collapse>
          </li>
          <li>
            <NavLink className={state.currentUrl.includes("/file-storage")?"padding sidebar-active-link":"padding"}to="/file-storage"><i className="fa fa-folder-o sidebar-icon"></i> File Storage</NavLink>
          </li>
          <li>
            <NavLink className={state.currentUrl.includes("/article")?"padding sidebar-active-link":"padding"} to="/article"><i className="fa fa-file-text-o sidebar-icon"></i> Article</NavLink>
          </li>
          <li>
            <NavLink className={state.currentUrl.includes("/property-data-report")?"padding sidebar-active-link":"padding"} to="/property-data-report"><i className="fa fa-building-o sidebar-icon"></i> PDR</NavLink>
          </li>
          <li className="collapse-nav">
            <NavLink to="#" className="padding" onClick={togglePipeline}><i className="fa fa-tasks sidebar-icon"></i> Pipeline <i className="fa fa-angle-double-down"></i></NavLink>
            <Collapse isOpen={state.pipelineDropdownOpen}>
              <NavLink className={state.currentUrl.includes("/appraisal")?"padding sidebar-active-link":"padding"} to={"/"+appraisalLink}>Appraisals</NavLink>
              <NavLink className={(state.currentUrl.includes("/condition") && !state.currentUrl.includes("/auto-condition"))?"padding sidebar-active-link":"padding"} to={"/"+conditionLink}>Conditions</NavLink>
              <NavLink className={state.currentUrl.includes("/rebuttal")?"padding sidebar-active-link":"padding"} to={"/"+rebuttalLink}>Rebuttals</NavLink>
            </Collapse>
          </li>
          <li className="collapse-nav">
            <NavLink to="#" className="padding" onClick={toggleDashboard}><i className="fa fa-dashboard sidebar-icon"></i> Dashboard <i className="fa fa-angle-double-down"></i></NavLink>
            <Collapse isOpen={state.dashboardDropdownOpen}>
              <NavLink className={(state.currentUrl.includes("/dashboard") && !state.currentUrl.includes("tat"))?"padding sidebar-active-link":"padding"} to={"/"+dashboardLink}>{userName}'s Dashboard</NavLink>
              <NavLink className={(state.currentUrl.includes("/dashboard") && state.currentUrl.includes("tat"))?"padding sidebar-active-link":"padding"} to="/dashboard-tat-overall">Turn Around Time</NavLink>

              <NavLink className={state.currentUrl.includes("/manual-auto-assign-stats")?"padding sidebar-active-link":"padding"} to="/manual-auto-assign-stats">Auto Assign</NavLink>
              <NavLink className={state.currentUrl.includes("/auto-review-delivery")?"padding sidebar-active-link":"padding"} to="/auto-review-delivery">Auto Review & Delivery</NavLink>
            </Collapse>
          </li>
          <li className="collapse-nav">
            <NavLink className={state.currentUrl.includes("/report")?"padding sidebar-active-link":"padding"} to="/report"><i className="fa fa-line-chart sidebar-icon"></i> Report & Tools</NavLink>
          </li>
          <li className="collapse-nav">
            <NavLink className={state.currentUrl.includes("/client-report")?"padding sidebar-active-link":"padding"} to="/client-report"><i className="fa fa-line-chart sidebar-icon"></i> Client Report</NavLink>
          </li>
          <li className="collapse-nav">
            <NavLink to="#" className="padding" onClick={toggleAppraiser}><i className="fa fa-user sidebar-icon"></i> Appraisers <i className="fa fa-angle-double-down"></i></NavLink>
             <Collapse isOpen={state.appraiserDropdownOpen}>
               <NavLink className={state.currentUrl.includes("/amc")?"padding sidebar-active-link":"padding"} to="/amc">AMC</NavLink>
               <NavLink className={state.currentUrl.includes("/appraiser-firm")?"padding sidebar-active-link":"padding"} to="/appraiser-firm">Appraiser Firm</NavLink>
               <NavLink className={state.currentUrl.includes("/appraisers")?"padding sidebar-active-link":"padding"} to="/appraisers">Appraiser</NavLink>
               <NavLink className={state.currentUrl.includes("/appraiser-exclusionary-list")?"padding sidebar-active-link":"padding"} to="/appraiser-exclusionary-list">Exclusionary List</NavLink>
               <NavLink className={state.currentUrl.includes("/appraiser-president-club")?"padding sidebar-active-link":"padding"} to="/appraiser-president-club">President Club</NavLink>
               <NavLink className={state.currentUrl.includes("/appraiser-expedite-payment")?"padding sidebar-active-link":"padding"} to="/appraiser-expedite-payment">Expedite Payment</NavLink>
               <NavLink className={state.currentUrl.includes("/appraiser-panel-discussion")?"padding sidebar-active-link":"padding"} to="/appraiser-panel-discussion">Panel Discussion</NavLink>
             </Collapse>
          </li>
          {adminSideBar}
          <li className="collapse-nav">
            <NavLink to="#" className="padding" onClick={toggleClient}><i className="fa fa-bank sidebar-icon"></i> Client <i className="fa fa-angle-double-down"></i></NavLink>
            <Collapse isOpen={state.clientDropdownOpen}>
              <NavLink className={(state.currentUrl.includes("/client")&&!state.currentUrl.includes("/client-delegator"))?"padding sidebar-active-link":"padding"} to="/client">Client</NavLink>
              <NavLink className={state.currentUrl.includes("/client-delegator")?"padding sidebar-active-link":"padding"} to="/client/delegator">Delegator</NavLink>
            </Collapse>
          </li>
          <li className="collapse-nav">

          </li>
          <li>
            <NavLink className={state.currentUrl.includes("/state-registration")?"padding sidebar-active-link":"padding"} to="/state-registration"><i className="fa fa-file-text-o sidebar-icon"></i> State Registration</NavLink>
          </li>
          {systemSettingBar}
          <li className="collapse-nav">
            <NavLink className={state.currentUrl.includes("/setting")?"padding sidebar-active-link":"padding"} to="/setting"><i className="fa fa-gear sidebar-icon"></i> Settings</NavLink>
          </li>
        </ul>
      </div>
    );
  }
  else {
    return(null);
  }
}

export default SideBar;
